import { useState } from 'react'
import SearchForm from 'components/Member/MemberRx/Dialogs/Pharmacy/SearchForm'
import ResultsForm from 'components/Member/MemberRx/Dialogs/Pharmacy/ResultsForm'

export function AddPharmacy({ open, handleClose }) {
  const [searchResults, setSearchResults] = useState([])

  if (searchResults.length > 0) {
    return (
      <ResultsForm
        open={open}
        handleClose={handleClose}
        results={searchResults}
      />
    )
  }

  return (
    <SearchForm
      open={open}
      handleClose={handleClose}
      onResultsChange={setSearchResults}
    />
  )
}
