import { Field } from 'formik'
import Grid from '@material-ui/core/Grid'

import { getFormikComponent } from 'utils/formik'

const MultiFieldsSectionForm = ({ fields }) => (
  <Grid container spacing={2}>
    {fields.map(({ path, type, label, cols = 12, componentProps }) => {
      const component = getFormikComponent(type)

      return (
        <Grid key={path} item xs={cols}>
          <Field
            name={path}
            component={component}
            label={label}
            {...componentProps}
          />
        </Grid>
      )
    })}
  </Grid>
)

export default MultiFieldsSectionForm
