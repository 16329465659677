import { MemberFamilyRelationships } from 'constants/selectOptions'

import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'
import withMemberForm from 'HOCs/withMemberForm'

const DEFAULT_ROW = {
  relationship: '',
  medicalHistory: '',
}

const FIELDS = [
  {
    path: 'relationship',
    label: 'Relationship',
    headerClassName: 'first-col',
    componentProps: {
      select: true,
      options: MemberFamilyRelationships,
    },
  },
  {
    path: 'medicalHistory',
    label: 'History',
  },
]

const MedicalFamilyHistorySection = props => (
  <ArrayFieldSection
    path="medicalFamilyHistory"
    title="Family Medical History"
    rowTitle="Family Medical History"
    fields={FIELDS}
    defaultRow={DEFAULT_ROW}
    {...props}
  />
)

export default withMemberForm('MedicalFamilyHistorySectionForm', [
  'medicalFamilyHistory',
])(MedicalFamilyHistorySection)
