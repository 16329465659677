import clsx from 'clsx'
import { useLayoutStyles } from 'hooks'
import { useTheme } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function CardHeader({ title, bold = false, children }) {
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)

  return (
    <>
      <Grid item xs={6} className={clsx(layoutClasses.padding2)}>
        {!bold ? (
          <Typography variant="h5">{title}</Typography>
        ) : (
          <Typography variant="h4">{title}</Typography>
        )}
      </Grid>
      {children && (
        <Grid
          item
          xs={6}
          className={clsx(layoutClasses.padding2, layoutClasses.textAlignRight)}
        >
          {children}
        </Grid>
      )}
    </>
  )
}
