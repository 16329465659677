import { useCallback } from 'react'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import BaseSection from './BaseSection'
import MultiValueSectionForm from './SectionForms/MultiValueSectionForm'
import MultiValueGridSectionLayout from './SectionLayouts/MultiValueGridSectionLayout'
import RiskChip from 'components/Shared/Chips/RiskChip'

const MultiValueSection = ({
  title,
  fieldsList,
  emptyPlaceholder = '',
  actionText = 'Edit',
  boldAction = false,
  ...props
}) => {
  const { values } = useFormikContext()

  const renderValue = useCallback(
    field => {
      const value = get(values, field.path)

      if (field.valueFormatter) {
        return <RiskChip content={field.valueFormatter(value)} isRisk={value} />
      }

      return value
    },
    [values],
  )

  const renderForm = useCallback(
    () => <MultiValueSectionForm fieldsList={fieldsList} />,
    [fieldsList],
  )

  return (
    <BaseSection
      title={title}
      renderForm={renderForm}
      actionText={actionText}
      boldAction={boldAction}
      {...props}
    >
      <MultiValueGridSectionLayout
        fieldsList={fieldsList}
        renderValue={renderValue}
        emptyPlaceholder={emptyPlaceholder}
      />
    </BaseSection>
  )
}

export default MultiValueSection
