import { SnackbarProvider } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  variantSuccess: {
    backgroundColor: palette.primary.main,
  },

  variantWarning: {
    backgroundColor: palette.warning.main,
  },

  variantError: {
    backgroundColor: palette.accentRed.main,
  },
}))

const NotistackProvider = ({ children }) => {
  const classes = useStyles()

  return (
    <SnackbarProvider classes={classes} maxSnack={3}>
      {children}
    </SnackbarProvider>
  )
}

export default NotistackProvider
