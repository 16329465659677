import { MemberSymptomTypes } from 'constants/selectOptions'
import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const DEFAULT_ROW = {
  type: '',
  description: '',
}

const FIELDS = [
  {
    path: 'type',
    label: 'Type of Symptom',
    headerClassName: 'first-col',
    componentProps: {
      select: true,
      options: MemberSymptomTypes,
    },
  },
  {
    path: 'description',
    label: 'Notes (Location, Quality, Severity, Duration, Frequency)',
  },
]

const SymptomsSection = props => (
  <ArrayFieldSection
    path="symptomList"
    title="Symptoms"
    rowTitle="Symptom"
    fields={FIELDS}
    defaultRow={DEFAULT_ROW}
    {...props}
  />
)

export default withMemberForm('SymptomsSectionForm', ['symptomList'])(
  SymptomsSection,
)
