import { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { UploadOptions, UploadOptionToDocument } from 'constants/member'
import useFileGenerate from 'hooks/api/files/useFileGenerate'
import ImportantFilesUploaded from './ImportantFilesUploaded'
import NewDocumentUploadPopover from './NewDocumentUploadMenu'
import UploadNewDocumentDialog from './Dialogs/UploadNewDocumentDialog'
import GenerateLWCDialog from './Dialogs/GenerateLWCDialog'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: theme.spacing(2),
    border: '1px solid #E3E3E3',
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
}))

export default function MemberFilesHeader({ member }) {
  const classes = useStyles()
  const memberFileList = useSelector(({ files }) => files.files)
  const generateMemberFile = useFileGenerate()
  const [currentDialog, setCurrentDialog] = useState(null)

  const handleClickOption = option => {
    switch (option) {
      case UploadOptions.UPLOAD_NEW_DOCUMENT:
        setCurrentDialog(UploadOptions.UPLOAD_NEW_DOCUMENT)
        return
      case UploadOptions.LETTER_WITHDRAWL_CARE:
        setCurrentDialog(UploadOptions.LETTER_WITHDRAWL_CARE)
        return
      default:
        Promise.resolve(
          generateMemberFile({
            memberID: member._id,
            documentType: UploadOptionToDocument[option],
          }),
        )
        return
    }
  }

  const handleCloseDialog = () => {
    setCurrentDialog(null)
  }

  return (
    <div className={classes.container}>
      <ImportantFilesUploaded files={memberFileList} />
      <NewDocumentUploadPopover onClickOption={handleClickOption} />

      {currentDialog === UploadOptions.UPLOAD_NEW_DOCUMENT && (
        <UploadNewDocumentDialog member={member} onClose={handleCloseDialog} />
      )}

      {currentDialog === UploadOptions.LETTER_WITHDRAWL_CARE && (
        <GenerateLWCDialog member={member} onClose={handleCloseDialog} />
      )}
    </div>
  )
}
