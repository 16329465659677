import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const DEFAULT_ROW = {
  type: '',
  description: '',
}

const FIELDS = [
  {
    path: 'type',
    label: 'Type of Medication',
    headerClassName: 'first-col',
  },
  {
    path: 'description',
    label: 'Description',
  },
]

const MedicationsSection = props => (
  <ArrayFieldSection
    path="medList"
    title="Current Medication"
    rowTitle="Medication"
    fields={FIELDS}
    defaultRow={DEFAULT_ROW}
    {...props}
  />
)

export default withMemberForm('MedicationsSectionForm', ['medList'])(
  MedicationsSection,
)
