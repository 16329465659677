import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useRxStyles } from 'hooks'

const SectionField = ({ label, isSingleField, children }) => {
  const rxClasses = useRxStyles()

  return (
    <Box mb={1}>
      {!isSingleField && (
        <Typography
          variant="caption"
          className={clsx(rxClasses.fontSize12, rxClasses.fontWeight500)}
        >
          {label}
        </Typography>
      )}
      <Typography variant="body1" className={rxClasses.fontSize14}>
        {children}
      </Typography>
    </Box>
  )
}

export default SectionField
