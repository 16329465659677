import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'nutrition',
  label: 'Nutrition',
  componentProps: {
    multiline: true,
  },
}

const NutritionSection = props => (
  <SingleFieldSection {...props} title="Nutrition" field={FIELD} />
)

export default withMemberForm('NutritionSectionForm', ['nutrition'])(
  NutritionSection,
)
