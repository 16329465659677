import isValid from 'date-fns/isValid'
import format from 'date-fns/format'
import isObject from 'lodash/isObject'

export function handleEvent(e) {
  // the DatePicker passes in a date string instead of e.target
  if (!isObject(e) && isValid(e)) {
    const formattedDate = format(e, 'MM/dd/yyyy')

    return { key: 'dob', value: formattedDate }
  } else {
    const key = e.target.name
    const value = e.target.val || e.target.value

    return { key, value }
  }
}

export const deserializeMember = (member = {}) => ({
  ...member,
  heightFeet: Math.floor((member.height || 0) / 12),
  heightInch: (member.height || 0) % 12,
})

export const serializeMember = member => ({
  ...member,
  firstName: member.firstName?.trim() || '',
  lastName: member.lastName?.trim() || '',
  preferredName: member.preferredName?.trim() || '',
  height: parseInt(member.heightFeet) * 12 + parseInt(member.heightInch),
})
