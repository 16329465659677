import FileSaver from 'file-saver'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import useFileDelete from 'hooks/api/files/useFileDelete'
import { API_BASE_URL } from 'constants/api'
import usePostRequest from 'hooks/api/usePostRequest'
import { formatDateInMMMDDYYYY } from 'utils/formatters'

import IconButton from '@material-ui/core/IconButton'
import { DataGrid } from '@material-ui/data-grid'
import DownloadIcon from '@material-ui/icons/GetApp'
import DeleteIcon from '@material-ui/icons/Delete'

import { makeStyles } from '@material-ui/core/styles'

import DeleteConfirmationDialog from './Dialogs/DeleteConfirmationDialog'
import SendDocumentViaEmailDialog from './Dialogs/SendDocumentViaEmailDialog'
import ContentLoading from './components/ContentLoading'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 600,
    backgroundColor: 'white',
  },

  iconButton: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}))

export default function MemberFilesMainBody({ member }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()
  const deleteMemberFile = useFileDelete()
  const [operating, setOperating] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)
  const [fileToSendOver, setFileToSendOver] = useState(null)
  const memberFiles = useSelector(({ files }) => files)
  const memberFileList = memberFiles.files
  const memberFilesLoading = memberFiles.isLoading || memberFiles.uploading

  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'documentType', headerName: 'Type', width: 120 },
    {
      field: 'time',
      headerName: 'Uploaded at',
      width: 200,
      valueGetter: params =>
        formatDateInMMMDDYYYY(params.row.time || new Date()),
    },
    { field: 'documentTitle', headerName: 'File Name', width: 300 },
    {
      field: 'action',
      headerName: 'Action',
      width: 140,
      renderCell: params => (
        <div>
          <IconButton
            className={classes.iconButton}
            onClick={() => handleDownload(params.row)}
          >
            <DownloadIcon />
          </IconButton>

          {/* @TODO - enable once funcionality is fixed
           <IconButton
            className={classes.iconButton}
            onClick={() => handleClickSendOverEmail(params.row)}
          >
            <MailOutlineIcon />
          </IconButton> */}
          <IconButton
            className={classes.iconButton}
            onClick={() => handleClickDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ]

  const handleDownload = async file => {
    setOperating(true)

    try {
      const fileContent = await postRequest({
        url: `${API_BASE_URL}/files/download`,
        body: {
          fileID: file.id,
          memberID: member._id,
        },
        isBlob: true,
      })
      FileSaver.saveAs(
        fileContent,
        [file.documentTitle, file.documentExtension].join('.'),
      )
    } catch (error) {
      enqueueSnackbar('File download failed', {
        variant: 'error',
      })
    } finally {
      setOperating(false)
    }
  }

  const handleClickDelete = file => {
    setFileToDelete(file)
  }

  const handleCloseConfirmationDialog = () => {
    setFileToDelete(null)
  }

  const handleCloseSendOverEmailDialog = () => {
    setFileToSendOver(null)
  }

  const handleDeleteFile = async ({ fileID, memberID }) => {
    setOperating(true)
    try {
      await deleteMemberFile({
        memberID,
        fileID,
      })
    } catch (error) {
      enqueueSnackbar('File deletion failed', {
        variant: 'error',
      })
    } finally {
      setFileToDelete(null)
      setOperating(false)
    }
  }

  if (operating || memberFilesLoading) {
    return <ContentLoading />
  }

  return (
    <div className={classes.container}>
      <DataGrid rows={memberFileList} columns={columns} pageSize={10} />

      {fileToDelete && (
        <DeleteConfirmationDialog
          fileName={fileToDelete.documentTitle}
          onClose={handleCloseConfirmationDialog}
          onDelete={() => {
            handleDeleteFile({ fileID: fileToDelete.id, memberID: member._id })
          }}
        />
      )}

      {fileToSendOver && (
        <SendDocumentViaEmailDialog
          member={member}
          fileID={fileToSendOver.id}
          onClose={handleCloseSendOverEmailDialog}
        />
      )}
    </div>
  )
}
