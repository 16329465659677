import clsx from 'clsx'
import { isEmpty } from 'lodash/fp'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useLayoutStyles, useRxStyles, useMemberRx } from 'hooks'
import { API_BASE_URL } from 'constants/api'
import usePostRequest from 'hooks/api/usePostRequest'

import { useTheme } from '@material-ui/core/styles'

import { formatDateInMMMDDYYYY } from 'utils/formatters'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LoadingModal from 'components/Modals/LoadingModal'

import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'

import { AddSelfReportedMedication } from './Dialogs/SelfReportedMedications/AddSelfReportedMedication'
import { UpdateOrDeletePrompt } from './Dialogs/UpdateOrDeletePrompt'

export default function SelfReportedMedication({ selfReportedMedications }) {
  const { loading, memberId, userId, updated, setUpdated } = useMemberRx()
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const rxClasses = useRxStyles(theme)

  const Rows = ({ data }) => {
    if (isEmpty(data)) {
      return <BaseRow withDivider />
    }

    return data.map((srm, i) => {
      const daysOfSupply = srm?.DaysSupply || 'DaysSupply not provided'
      const directions = srm?.Directions || 'Directions not provided'
      return (
        <BaseRow
          withDivider
          key={`${srm.SelfReportedMedicationId}`}
          columns={
            <Grid container alignItems="center" justifyContent="space-evenly">
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.fontSize12,
                    rxClasses.typography,
                    theme.typography.fontWeightRegular,
                  )}
                >
                  {srm.DisplayName}
                </Typography>
                <br />
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(rxClasses.fontSize8, rxClasses.typography)}
                >
                  {formatDateInMMMDDYYYY(srm.DateReported)} | {daysOfSupply},{' '}
                  {directions}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                className={clsx(
                  rxClasses.columnPadding,
                  rxClasses.textAlignRight,
                )}
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.typography,
                    rxClasses.fontSize12,
                    rxClasses.buttonDelete,
                    layoutClasses.pl1,
                  )}
                  onClick={() => {
                    handleClickUpdate(srm)
                  }}
                >
                  Update
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )
    })
  }

  const [srmToUpdate, setSrmToUpdate] = useState(null)

  const [addModal, openAddModal] = useState(false)
  const [updateModal, openUpdateModal] = useState(false)

  useEffect(() => {
    if (updated) {
      openAddModal(false)
    }
  }, [updated])

  const handleClickUpdate = srm => {
    openUpdateModal(true)
    setSrmToUpdate(srm)
  }

  const onDelete = async fields => {
    try {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/medication/patient/update/${userId}&${memberId}`,
        body: {
          status: 4,
          comment: fields.comment,
          selfReportedMedicationId: srmToUpdate.SelfReportedMedicationId,
        },
      })
      setSrmToUpdate(null)
      enqueueSnackbar('Self-reported medication deleted', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Self-reported medication', { variant: 'error' })
    } finally {
      setUpdated(true)
    }
  }

  const onUpdate = async fields => {
    try {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/medication/patient/update/${userId}&${memberId}`,
        body: {
          status: fields.status,
          comment: fields.comment,
          selfReportedMedicationId: srmToUpdate.SelfReportedMedicationId,
        },
      })
      setSrmToUpdate(null)
      enqueueSnackbar('Updated self-reported medication', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Failed to update self-reported medication', {
        variant: 'error',
      })
    } finally {
      setUpdated(true)
    }
  }

  if (loading) {
    return (
      <div className={rxClasses.gridItemSpan2}>
        <LoadingModal />
      </div>
    )
  }

  const title = 'Self-reported Medications'

  return (
    <>
      <BaseCard>
        <BaseCardHeader
          title={title}
          actionOnClick={() => {
            openAddModal(true)
          }}
          actionText={'Add Medication'}
        />
        <Rows data={selfReportedMedications} />
      </BaseCard>

      {addModal && (
        <AddSelfReportedMedication
          open={addModal}
          handleClose={() => {
            openAddModal(false)
          }}
        />
      )}

      {updateModal && srmToUpdate !== null && (
        <UpdateOrDeletePrompt
          title={'Update Self-reported Medication'}
          message={`If the patient is no longer taking “${srmToUpdate.DisplayName}”, please select a reason and update the entry. You may also leave a comment. If the entry was created due to an error, please delete this entry.`}
          loading={loading}
          open={updateModal}
          setOpen={openUpdateModal}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      )}
    </>
  )
}
