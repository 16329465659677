import { ENCOUNTER_TYPE } from 'utils/encounters'
import { formatDateInMMMDDYYYY } from 'utils/formatters'
import { flattenObject, group, sortDate } from 'utils/commonHelpers'

function getKey(note) {
  return `${note.userID}_${formatDateInMMMDDYYYY(note.dateCreated)}`
}

function addPositionIndex(groupedNotes) {
  return Object.values(groupedNotes).map(group =>
    group.map((note, index) => {
      const positionIndex = group.length > 1 ? index + 1 : 0
      return { ...note, positionIndex }
    }),
  )
}

export function addIndex(notes) {
  const groupedNotes = group(notes, getKey)
  const notesWithIndex = addPositionIndex(groupedNotes)
  const sortedNotes = notesWithIndex.sort((a, b) => sortDate(a, b))
  const flatNotes = flattenObject(sortedNotes)

  return flatNotes
}

const ENCOUNTER_DURATIONS = {
  TEN_MINUTES: '10',
  TWENTY_MINUTES: '20',
  THIRTY_MINUTES: '30',
  FORTY_MINUTES: '40',
  FIFTY_MINUTES: '50',
  SIXTY_MINUTES: '60',
  SEVENTY_MINUTES: '70',
  EIGHTY_MINUTES: '80',
  NINETY_MINUTES: '90',
}

const CARE_MANAGEMENT_ENCOUNTERS = {
  DEFAULT: '99484',
}

const PSYCHIATRY_ENCOUNTERS = {
  [ENCOUNTER_DURATIONS.TEN]: '99212',
  [ENCOUNTER_DURATIONS.TWENTY_MINUTES]: '99213',
  [ENCOUNTER_DURATIONS.THIRTY_MINUTES]: '99214',
  [ENCOUNTER_DURATIONS.FORTY_MINUTES]: '99214',
  [ENCOUNTER_DURATIONS.FIFTY_MINUTES]: '99215',
  [ENCOUNTER_DURATIONS.SIXTY_MINUTES]: '99215',
  [ENCOUNTER_DURATIONS.SEVENTY_MINUTES]: '99215',
  [ENCOUNTER_DURATIONS.EIGHTY_MINUTES]: '99215',
  [ENCOUNTER_DURATIONS.NINETY_MINUTES]: '99215',
}

const THERAPY_ENCOUNTERS = {
  [ENCOUNTER_DURATIONS.TEN]: '90832',
  [ENCOUNTER_DURATIONS.TWENTY_MINUTES]: '90832',
  [ENCOUNTER_DURATIONS.THIRTY_MINUTES]: '90832',
  [ENCOUNTER_DURATIONS.FORTY_MINUTES]: '90834',
  [ENCOUNTER_DURATIONS.FIFTY_MINUTES]: '90837',
  [ENCOUNTER_DURATIONS.SIXTY_MINUTES]: '90837',
  [ENCOUNTER_DURATIONS.SEVENTY_MINUTES]: '90837',
  [ENCOUNTER_DURATIONS.EIGHTY_MINUTES]: '90837',
  [ENCOUNTER_DURATIONS.NINETY_MINUTES]: '90837',
}

export function getDefaultCptCode({ numOfEncounters, type, duration }) {
  const firstEncounter = numOfEncounters === 0
  switch (type) {
    case ENCOUNTER_TYPE.CARE_MANAGEMENT:
      return CARE_MANAGEMENT_ENCOUNTERS.DEFAULT
    case ENCOUNTER_TYPE.PSYCHIATRY:
      return firstEncounter ? '90792' : PSYCHIATRY_ENCOUNTERS[duration]
    case ENCOUNTER_TYPE.THERAPY:
      return firstEncounter ? '90791' : THERAPY_ENCOUNTERS[duration]
    default:
      return CARE_MANAGEMENT_ENCOUNTERS.DEFAULT
  }
}
