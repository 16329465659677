import Typography from '@material-ui/core/Typography'
import { useLayoutStyles } from 'hooks'

export default function InsuranceCardTitle({ side }) {
  const layoutClasses = useLayoutStyles()

  return (
    <Typography
      style={{
        fontWeight: 'bold',
        fontSize: '12px',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
      }}
      className={layoutClasses.mb1}
    >
      {side} of card
    </Typography>
  )
}
