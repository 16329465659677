import { getIn } from 'formik'
import TextField from '@material-ui/core/TextField'

import Autocomplete from 'ui/Autocomplete'

const FormikInput = ({
  field: { name, value },
  form: { errors, setFieldValue },
  label,
  InputProps = {
    variant: 'outlined',
  },
  ...props
}) => {
  const error = getIn(errors, name)

  return (
    <Autocomplete
      name={name}
      value={value}
      onChange={(e, value) => {
        setFieldValue(name, value)
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error}
          {...InputProps}
        />
      )}
      {...props}
    />
  )
}

export default FormikInput
