import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { useLayoutStyles } from 'hooks'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import TextButton from 'components/Shared/TextButton'
import BaseDialog from 'components/Shared/BaseDialog'
import BaseCard from 'components/Shared/Card/BaseCard'
import { useRxStyles } from 'hooks/useRxStyles'

import { useTheme } from '@material-ui/core/styles'

export default function ResultsForm({
  open,
  handleClose,
  results,
  onSelected,
}) {
  const theme = useTheme()
  const layoutClasses = useLayoutStyles()
  const rxClasses = useRxStyles(theme)
  const [limitedResults, setResults] = useState()
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(5)
  const step = 5

  function incrument(num, incrument) {
    return num + incrument
  }

  function getResults(arr, start, end) {
    const slicedArr = arr.slice(start, end)
    return slicedArr
  }

  const loadMore = () => {
    setResults(getResults(results, start, end))
    setStart(end)
    setEnd(incrument(end, step))
  }

  const selected = result => {
    onSelected(result)
  }

  useEffect(() => {
    if (start === 0) {
      setResults(getResults(results, start, end))
      setStart(end)
      setEnd(end + step)
    }
  }, [results, start, end])

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Add Drug Allergy"
      dialogContent={
        limitedResults &&
        limitedResults.map((result, i) => {
          return (
            <div
              key={`${result.Code}-${result.RxCUI}-${i}`}
              className={rxClasses.rowSpacer}
            >
              <BaseCard>
                <Grid item xs={8} className={layoutClasses.padding2}>
                  <Typography
                    className={clsx(
                      rxClasses.fontSize14,
                      rxClasses.typography,
                      theme.typography.fontWeightBold,
                      theme.palette.primaryNavy,
                    )}
                  >
                    {result.Name}
                  </Typography>
                  <Typography
                    className={clsx(rxClasses.fontSize12, rxClasses.typography)}
                  >
                    Code: {result.Code}, Type: {result.semanticType}, RxCui:
                    {result.RxCUI}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={clsx(
                    layoutClasses.padding2,
                    rxClasses.textAlignRight,
                  )}
                >
                  <span
                    onClick={() => {
                      selected(result)
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(
                        rxClasses.typography,
                        rxClasses.fontSize16,
                        rxClasses.buttonAction,
                        rxClasses.actionSpacerRight,
                        theme.typography.fontWeightRegular,
                      )}
                    >
                      SELECT
                    </Typography>
                  </span>
                </Grid>
              </BaseCard>
            </div>
          )
        })
      }
      dialogActions={
        end <= results.length && (
          <TextButton
            className={clsx(
              theme.typography.fontWeightBold,
              rxClasses.typography,
              rxClasses.center,
            )}
            onClick={loadMore}
          >
            LOAD 5 MORE
          </TextButton>
        )
      }
    />
  )
}
