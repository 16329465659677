import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'

import { formatFullName } from 'utils/formatters'
import { getUserTitle } from 'utils/users'

const useStyles = makeStyles(({ spacing }) => ({
  avatar: {
    width: spacing(5),
    height: spacing(5),
  },
  nameTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    color: '#183f4f',
    overflow: 'hidden',
    '& > div': {
      whiteSpace: 'nowrap',
    },
  },
}))

const UserAvatar = ({ user, showDetail }) => {
  const classes = useStyles()

  const fullName = formatFullName(user)
  const userTitle = getUserTitle(user?.userType)

  return (
    <Box
      borderRadius={8}
      display="flex"
      alignItems="center"
      p={2}
      bgcolor="backgroundGray.main"
    >
      <Avatar
        src={user?.profilePic}
        alt={fullName}
        className={classes.avatar}
      />

      {showDetail && (
        <Box className={classes.nameTitle} flexGrow={1} ml={2}>
          <Box fontWeight="fontWeightMedium">{fullName}</Box>
          <Box fontSize="body2.fontSize">{userTitle}</Box>
        </Box>
      )}
    </Box>
  )
}

export default UserAvatar
