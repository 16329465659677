import { isArray } from 'lodash'
import { CoverageTypes } from 'constants/selectOptions'
import { FILE_TYPES } from 'constants/insurance'
import { capitalizeEveryWord } from 'utils/formatters'

export const indexMap = [
  'primary',
  'secondary',
  'tertiary',
  'quaternary',
  'quinary',
  'senary',
  'septenary',
  'octonary',
  'nonary',
  'denary',
]

export const formatPayer = (payer, plan) => {
  const insurancePlan = plan ? `(${plan})` : ''
  return `${payer.type} ${insurancePlan}`
}

export const listInsurance = insurances => {
  return insurances.reduce((list, insurance, index) => {
    const payer = insurance.payer?.type ?? ''
    const payers = [...list, payer]
    return payers
  }, [])
}

export const inlineList = insurances => {
  const firstTwo = insurances.slice(0, 2)
  const insuranceList = listInsurance(firstTwo)
  return isArray(insuranceList) ? insuranceList.join(', ') : insuranceList
}

export const coverageMap = typeCode => {
  return CoverageTypes.find(coverage => {
    return coverage.code === typeCode
  })
}

export const STRIPE_LINK = id => {
  return `https://dashboard.stripe.com/customers/${id}`
}

export const getCoverageTypeLabel = coverage => {
  if (!coverage || coverage === '' || coverage === null) {
    return ''
  }

  const label = CoverageTypes.find(type => type.code === coverage)
  const capitalizedDisplay = capitalizeEveryWord(label.display)

  return `${capitalizedDisplay} ${
    label.code !== 'pay' ? `(${label.code.toUpperCase()})` : ''
  }`
}

export const allowedFileType = file => {
  return FILE_TYPES.includes(file.type)
}

export const updateInsurances = (insurances, editedInsurance) => {
  const editedInsurances = insurances.map(insurance => {
    if (insurance._id === editedInsurance._id) {
      return editedInsurance
    }
    return insurance
  })

  return editedInsurances
}
