import { NavLink } from 'react-router-dom'

import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'relative',
    margin: spacing(0, 1, 0.5, 1),
  },
  button: {
    padding: spacing(1.5, 3),
    textTransform: 'none',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    maxHeight: '48px',

    '&:hover': {
      backgroundColor: '#F8FAF8',
    },

    '&.active': {
      color: palette.secondaryGreen1.main,
      backgroundColor: 'rgba(187, 220, 156, 0.3)',
    },
  },
  childrenBox: {
    flexGrow: 1,
    marginLeft: spacing(1),
    textAlign: 'left',
  },
  notificationIcon: {
    top: 8,
    right: 20,
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: palette.accentRed.main,
    position: 'absolute',
  },
}))

const SidebarNavButton = ({
  to,
  showDetail,
  startIcon,
  children,
  hasNotification,
  ...props
}) => {
  const classes = useStyles()

  const navLinkProps = to
    ? {
        to,
        component: NavLink,
        activeClassName: 'active',
      }
    : null

  return (
    <Box className={classes.root}>
      <Button fullWidth className={classes.button} {...navLinkProps} {...props}>
        {startIcon}
        {!showDetail && hasNotification && (
          <span className={classes.notificationIcon} />
        )}
        {showDetail && <Box className={classes.childrenBox}>{children}</Box>}
      </Button>
    </Box>
  )
}

export default SidebarNavButton
