import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { isUndefined, capitalize, isEmpty } from 'lodash/fp'
import { isAdult } from 'utils/formatters'
import { validateAddress, validatePhone } from 'utils/rx'
import { useRxStyles } from 'hooks/useRxStyles'
import { MEMBER_STATUS } from 'constants/member'
import { useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'

async function validate([field, value]) {
  if (!value && isUndefined(value)) {
    return {
      field,
      isValid: false,
      message: `${capitalize(field)} is required, please update!`,
    }
  }

  if (field === 'status' && value !== MEMBER_STATUS.Active) {
    return {
      field,
      isValid: false,
      message: `Member status must be set to: Active`,
    }
  }

  if (field === 'dob' && !isAdult(new Date(), value)) {
    return {
      field,
      isValid: false,
      message: `Member must be at least 18 to qualify.`,
    }
  }

  if (field === 'phone') {
    const validationResult = await validatePhone(value)
    if (!isEmpty(validationResult.errors)) {
      return {
        field,
        isValid: false,
        message: validationResult.errors.join(),
      }
    }
  }

  if (field === 'shippingAddress') {
    const validationResult = await validateAddress(value)
    if (!isEmpty(validationResult.errors)) {
      return {
        field,
        isValid: false,
        message: capitalize(validationResult.errors.join(', ')),
      }
    }
  }

  return { field, isValid: true }
}

function getRequiredFields(member) {
  const {
    firstName,
    lastName,
    phone,
    dob,
    gender,
    height,
    weight,
    status,
    shippingAddress: { address1, address2, city, state, zip } = {},
  } = member
  return {
    firstName,
    lastName,
    phone,
    dob,
    gender,
    height,
    weight,
    status,
    shippingAddress: { address1, address2, city, state, zip },
  }
}

async function sanitize(member) {
  const requiredFields = getRequiredFields(member)
  const promises = Object.entries(requiredFields).map(validate)
  return await Promise.all(promises)
}

export default function PatientNotEligible({ member }) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const [invalidFields, setInvalidFields] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleFields = async member => {
      try {
        setLoading(true)
        const fields = await sanitize(member)
        setInvalidFields(fields)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }

    handleFields(member)
  }, [member])

  if (loading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  const Rows = ({ title, data }) => {
    return data
      .filter(property => {
        return property.isValid !== true
      })
      .map((property, i) => {
        return (
          <>
            <BaseRow
              key={`${property.field}-${i}`}
              title={title}
              rowIndex={i}
              columns={
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                  className={clsx(rxClasses.accentRed)}
                >
                  <Grid item xs className={rxClasses.columnPadding}>
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(
                        rxClasses.typography,
                        rxClasses.fontWeight500,
                        rxClasses.fontSize12,
                        theme.palette.primary,
                      )}
                    >
                      {property.message}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </>
        )
      })
  }

  return (
    <div
      className={clsx(
        rxClasses.gridItemSpan2,
        theme.palette.primary,
        rxClasses.typography,
        rxClasses.fontSize12,
        rxClasses.font500,
      )}
    >
      <BaseCard>
        <BaseCardHeader
          title={
            'Please fully onboard member in order to create DoseSpot profile'
          }
        />
        <Rows title={'onboarding'} data={invalidFields} />
      </BaseCard>
    </div>
  )
}
