import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    paddingTop: '12px',
    paddingRight: '12px',
    paddingBottom: '8px',
    paddingLeft: '12px',
    width: '184px',
    '& .MuiCardContent-root': {
      padding: '0!important',
      position: 'relative',
    },
  },
  chip: {
    backgroundColor: palette.backgroundGray.main,
    color: palette.text.primary,
    fontSize: '12px',
    marginBottom: '4px',
  },
  dot: {
    fontSize: spacing(1),
    position: 'absolute',
    top: `-${spacing(1)}px`,
    right: `-${spacing(1)}px`,
  },
  evenSpacer: { marginLeft: '-4px!important', marginRight: '4px' },
  title: {
    display: 'inline-block',
    marginBottom: '4px',
  },
  subTitle: {
    display: 'inline-block',
    marginBottom: '12px',
    opacity: '.5',
  },
}))

export default function MedicationCard({ medication, color }) {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Card
      variant="outlined"
      style={{ border: `1px solid ${color}` }}
      className={clsx(classes.card)}
    >
      <CardContent>
        <div style={{ color: color }} className={classes.dot}>
          &#11044;
        </div>
        <div>
          <span className={classes.title}>{medication.Drug.toLowerCase()}</span>
          <br />
          <span className={classes.subTitle}>{medication.BrandName} &reg;</span>
        </div>
        <div>
          {medication.Genes.map((gene, i) => {
            const isEven = i % 2 === 0
            return (
              <Chip
                key={gene.Gene}
                label={gene.Gene}
                className={clsx({
                  [classes.chip]: true,
                  [classes.evenSpacer]: isEven,
                })}
                size="small"
              />
            )
          })}
        </div>
      </CardContent>
    </Card>
  )
}
