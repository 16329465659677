import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { makeStyles, Box } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'

import { API_BASE_URL } from 'constants/api'
import usePostRequest from 'hooks/api/usePostRequest'

import PopoverChip from './PopoverChip'
import QuickMessageItem from './QuickMessageItem'

const MESSAGE_TYPES = [
  {
    title: 'Appointment reminder',
    description: 'The provider is waiting for the patient on the call.',
    message:
      "Your provider is waiting for you on the video call. Please join as soon as you're available!",
  },
  {
    title: 'Appointment delay',
    description: 'The provider is running late to the appointment.',
    message:
      'Your provider is running a little late, but will be joining the video call shortly. We apologize for the inconvenience.',
  },
  {
    title: 'Reschedule due to no-show',
    description: 'The patient no-showed to the appointment.',
    message:
      "It looks like this appointment didn't work for you. Please reach out to your Care Coordinator to reschedule another appointment.",
  },
  {
    title: 'Complete new assessment',
    description: 'The patient needs to complete an assessment',
    message: '(same text sent out in the progress page)',
  },
  {
    title: 'Schedule follow-up appointment',
    description: 'The patient has no follow-up appointment scheduled.',
    message:
      'Please schedule a follow-up appointment with your provider using the link below. https://www.prairiehealth.co/schedule',
  },
  {
    title: 'Complete intake form',
    description: 'The patient has not completed the intake form.',
    message:
      'Here’s a friendly reminder to complete the intake form. Please sign in to our member portal to access your intake form. https://member.prairiehealth.co',
  },
  {
    title: 'Prescription refill guidance',
    description: "The patient's pharmacy has submitted a refill request.",
    message:
      'We have received a prescription refill request from your pharmacy. To process your refill request, we ask that you schedule a follow-up appointment with your doctor using the following link: https://www.prairiehealth.co/schedule_f.  \n\nIf you have an appointment scheduled but require a refill before your next appointment, please reach out to your Care Coordinator.',
  },
]

const useStyles = makeStyles(({ spacing }) => ({
  chipLabel: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  messageIcon: {
    height: spacing(1.5),
    width: spacing(1.5),
  },
  messages: {
    marginLeft: spacing(1),
  },
}))

const MessagesChip = ({ member }) => {
  const classes = useStyles()
  const postRequest = usePostRequest()
  const { enqueueSnackbar } = useSnackbar()

  const generateAssesmentLink = useCallback(async () => {
    if (!member) return ''
    const response = await postRequest({
      url: `${API_BASE_URL}/cp/members/one-time-assessment/generate`,
      body: member,
    })
    return response.assessmentUrl
  }, [member, postRequest])

  const handleSendMessage = async (title, message) => {
    try {
      let updatedMessage = message
      if (title === 'Complete new assessment') {
        updatedMessage = `Your provider just sent you a mental health assessment. Please click on the following link to complete the assessment. The assessment will take approximately 3 minutes to complete. \n\n${await generateAssesmentLink()}`
      }

      const response = await postRequest({
        url: `${API_BASE_URL}/cp/sms/send`,
        body: { message: updatedMessage, phone: member.phone },
      })

      if (response.warning) {
        enqueueSnackbar(response.message, {
          variant: 'warning',
        })
      } else {
        enqueueSnackbar('Sent text message', {
          variant: 'success',
        })
      }
    } catch (error) {
      enqueueSnackbar('Text message has failed to send.', {
        variant: 'error',
      })
    }
  }

  return (
    <PopoverChip
      chipLabel={
        <span className={classes.chipLabel}>
          <MessageIcon fontSize="small" className={classes.messageIcon} />
          <span className={classes.messages}>Messages</span>
        </span>
      }
      horizontalPlacement="right"
    >
      <Box width={300} p={2}>
        {MESSAGE_TYPES.map((each, index) => (
          <QuickMessageItem
            key={`qmi-${index}`}
            title={each.title}
            description={each.description}
            message={each.message}
            onSendMessage={handleSendMessage}
          />
        ))}
      </Box>
    </PopoverChip>
  )
}

export default MessagesChip
