import clsx from 'clsx'
import { isEmpty } from 'lodash/fp'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LoadingModal from 'components/Modals/LoadingModal'

import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

import { useRxStyles, useLayoutStyles, useMemberRx } from 'hooks'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'
import { formatDateInMMMDDYYYY } from 'utils/formatters'
import { useTheme } from '@material-ui/core/styles'
import { AddDrugAllergy } from './Dialogs/DrugAllergy/AddDrugAllergy'
import { ArchiveOrDeletePrompt } from './Dialogs/ArchiveOrDeletePrompt'

import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'

export default function DrugAllergies({ allergies }) {
  const { loading, memberId, userId, updated, setUpdated } = useMemberRx()
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const rxClasses = useRxStyles(theme)

  const Rows = ({ data }) => {
    if (isEmpty(data)) {
      return <BaseRow withDivider />
    }

    return data.map((drugAllergy, i) => {
      return (
        <BaseRow
          withDivider
          key={`${drugAllergy.PatientAllergyId}`}
          columns={
            <>
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.fontSize12,
                    rxClasses.typography,
                    theme.typography.fontWeightBold,
                  )}
                >
                  {drugAllergy.Name}
                  {drugAllergy.ReactionType === 2 && (
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(
                        rxClasses.fontSize12,
                        rxClasses.typography,
                        rxClasses.accentRed,
                      )}
                    >
                      {' '}
                      (Adverse)
                    </Typography>
                  )}
                </Typography>
                <br />
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.fontSize8,
                    rxClasses.typography,
                    theme.typography.fontWeightRegular,
                  )}
                >
                  Onset Date: {formatDateInMMMDDYYYY(drugAllergy.OnsetDate)}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                className={clsx(
                  rxClasses.columnPadding,
                  rxClasses.textAlignRight,
                )}
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.typography,
                    rxClasses.buttonDelete,
                    rxClasses.fontSize12,
                    layoutClasses.pl1,
                  )}
                  onClick={() => {
                    handleClickArchive(drugAllergy)
                  }}
                >
                  Archive
                </Typography>
              </Grid>
            </>
          }
        />
      )
    })
  }

  const [allergyToUpdate, setAllergyToUpdate] = useState(null)
  const [addModal, openAddModal] = useState(false)
  const [updateModal, openUpdateModal] = useState(false)

  useEffect(() => {
    if (updated) {
      openAddModal(false)
    }
  }, [updated])

  const handleClickArchive = drugAllergy => {
    openUpdateModal(true)
    setAllergyToUpdate(drugAllergy)
  }

  const onDelete = async () => {
    try {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/drug-allergies/patient/delete/${userId}&${memberId}`,
        body: {
          reactionType: allergyToUpdate.ReactionType,
          patientAllergyId: allergyToUpdate.PatientAllergyId,
        },
      })
      setAllergyToUpdate(null)
      enqueueSnackbar('Allergy deleted', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Failed to delete allergy', { variant: 'error' })
    } finally {
      setUpdated(true)
    }
  }

  const onArchive = async () => {
    try {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/drug-allergies/patient/archive/${userId}&${memberId}`,
        body: {
          reactionType: allergyToUpdate.ReactionType,
          patientAllergyId: allergyToUpdate.PatientAllergyId,
        },
      })
      setAllergyToUpdate(null)
      enqueueSnackbar('Archived drug allergy', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Failed to archive drug allergy', { variant: 'error' })
    } finally {
      setUpdated(true)
    }
  }

  if (loading) {
    return (
      <div className={rxClasses.gridItemSpan2}>
        <LoadingModal />
      </div>
    )
  }

  const title = 'Drug Allergies'

  return (
    <BaseCard>
      <BaseCardHeader
        title={title}
        actionOnClick={() => {
          openAddModal(true)
        }}
        actionText={'Add Allergy'}
      />
      <Rows data={allergies} />

      {addModal && (
        <AddDrugAllergy
          open={addModal}
          handleClose={() => {
            openAddModal(false)
          }}
        />
      )}

      {updateModal && allergyToUpdate !== null && (
        <ArchiveOrDeletePrompt
          title={'Update Drug Allergy'}
          message={`If "${allergyToUpdate.Name}" is no longer applicable, please archive this entry. If this entry was created due to an error, please delete this entry.`}
          loading={loading}
          open={updateModal}
          setOpen={openUpdateModal}
          onDelete={onDelete}
          onArchive={onArchive}
        />
      )}
    </BaseCard>
  )
}
