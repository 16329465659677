import { isEmpty } from 'lodash'
import clsx from 'clsx'
import { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import { useCurrentUser } from 'hooks'

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'
import TextInput from 'ui/TextInput'

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    '& .ql-container.ql-snow': {
      height: 'calc(100vh - 400px)',
      minHeight: 40,
    },
  },
  delete: {
    color: palette.accentRed.main,
  },
}))

function TemplateForm({
  activeTemplate,
  onCreate,
  onSave,
  onDelete,
  onSetDefault,
}) {
  const classes = useStyles()
  const currentUser = useCurrentUser()
  const editorModules = {
    toolbar: [['bold', 'italic', 'underline']],
  }

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    if (!isEmpty(activeTemplate)) {
      setTitle(activeTemplate.title)
      setText(activeTemplate.text)
    } else {
      setTitle('')
      setText('')
    }
  }, [activeTemplate])

  return (
    <div className={classes.container}>
      <Typography style={{ fontSize: 14, marginBottom: 8 }}>
        Template Title
      </Typography>
      <TextInput
        style={{ marginBottom: 16 }}
        required
        error={!title}
        id="title"
        fullWidth
        variant="outlined"
        label="Enter Template Title"
        name="title"
        value={title}
        onChange={e => {
          setTitle(e.target.value)
        }}
      />
      <div style={{ height: '100%' }}>
        <Typography style={{ fontSize: 14, marginBottom: 8 }}>
          Template Contents
        </Typography>
        <ReactQuill
          id="text"
          name="text"
          modules={editorModules}
          value={text}
          placeholder="Enter template content"
          onChange={value => {
            setText(value)
          }}
        />
      </div>
      <div>
        <Grid container style={{ padding: '16px 0', width: '100%' }}>
          <Grid item xs={3}>
            <TextButton
              className={clsx({ [classes.delete]: activeTemplate })}
              onClick={() => {
                onDelete({
                  templateID: activeTemplate._id,
                })
              }}
              disabled={!activeTemplate}
            >
              DELETE
            </TextButton>
          </Grid>

          <Grid item xs={9} style={{ textAlign: 'right' }}>
            <TextButton
              onClick={() => {
                onSetDefault({
                  userID: currentUser._id,
                  templateID: activeTemplate._id,
                  body: { ...activeTemplate, title, text, default: true },
                })
              }}
              disabled={
                !activeTemplate ||
                (activeTemplate && activeTemplate.default === true)
              }
            >
              SET DEFAULT
            </TextButton>
            <TextButton
              onClick={() => {
                if (activeTemplate) {
                  onSave({
                    userID: currentUser._id,
                    templateID: activeTemplate._id,
                    body: { ...activeTemplate, title, text },
                  })
                } else {
                  onCreate({ userID: currentUser._id, fields: { title, text } })
                }
              }}
              disabled={isEmpty(title)}
            >
              SAVE
            </TextButton>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default TemplateForm
