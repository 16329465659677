import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function useMarkCommentAsRead() {
  const { fetchWithRefresh } = useGoogleAuth()

  const markAsRead = useCallback(
    async ({ commentID }) => {
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/comments/${commentID}/mark-read`,
        options: {
          method: HTTP_METHODS.PUT,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      })

      if (response.ok) {
        await response.json()
      } else {
        throw new Error('Failed to update comment')
      }
    },
    [fetchWithRefresh],
  )

  return markAsRead
}
