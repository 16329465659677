import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLayoutStyles, useRxStyles } from 'hooks'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BaseCard from 'components/Shared/Card/BaseCard'

const useStyles = makeStyles(({ palette }) => ({
  fontSize18: {
    fontSize: '18px',
  },
  fontSize16: {
    fontSize: '16px',
  },
  card: {
    minWidth: '200px',
    height: '100%!important',
  },
  link: {
    color: palette.primary.main,
    lineHeight: '14px',
    letterSpacing: '0.15px',
  },
}))

export default function InfoCard({ title, subTitle, link }) {
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const rxClasses = useRxStyles(theme)
  const classes = useStyles()

  return (
    <BaseCard className={classes.card}>
      <div className={clsx(layoutClasses.padding3)}>
        <Typography
          className={clsx(classes.fontSize18, layoutClasses.mb2)}
          component="p"
          variant="body1"
        >
          {title}
        </Typography>
        <Typography
          className={clsx(classes.fontSize16)}
          component="p"
          variant="body1"
        >
          {subTitle}
        </Typography>
        {link && (
          <Typography
            className={clsx(layoutClasses.mt3, rxClasses.fontSize16)}
            component="p"
            variant="body1"
          >
            <Link className={classes.link} to={link.to}>
              {link.text}
            </Link>
          </Typography>
        )}
      </div>
    </BaseCard>
  )
}
