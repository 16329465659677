import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: palette.common.white,
    borderBottom: `1px solid ${palette.distinctiveGray.main}`,
    padding: spacing(0, 2),
  },
}))

const BaseHeader = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

export default BaseHeader
