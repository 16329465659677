import { MemberFamilyRelationships } from 'constants/selectOptions'

import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const DEFAULT_ROW = {
  relationship: '',
  psychHistory: '',
}

const FIELDS = [
  {
    path: 'relationship',
    label: 'Relationship',
    headerClassName: 'first-col',
    componentProps: {
      select: true,
      options: MemberFamilyRelationships,
    },
  },
  {
    path: 'psychHistory',
    label: 'History',
  },
]

const PsychFamilyHistorySection = props => (
  <ArrayFieldSection
    path="psychFamilyHistory"
    title="Family Psychiatric History"
    rowTitle="Family Psychiatric History"
    fields={FIELDS}
    defaultRow={DEFAULT_ROW}
    {...props}
  />
)

export default withMemberForm('PsychFamilyHistorySectionForm', [
  'psychFamilyHistory',
])(PsychFamilyHistorySection)
