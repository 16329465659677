import clsx from 'clsx'
import { isEmpty, isNull } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useCurrentUser } from 'hooks'
import useGetRequest from 'hooks/api/useGetRequest'
import usePostRequest from 'hooks/api/usePostRequest'
import usePutRequest from 'hooks/api/usePutRequest'
import useDeleteRequest from 'hooks/api/useDeleteRequest'

import { API_BASE_URL } from 'constants/api'

import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import TemplateForm from 'components/AccountSettings/EncounterTemplates/TemplateForm'

const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  root: {
    height: '100%',
    overflowY: 'scroll',
    padding: spacing(0, 3),
    '& *': {
      boxSizing: 'border-box',
    },
  },
  row: {
    width: '100%',
    padding: spacing(2, 3),
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 252,
    [breakpoints.down('md')]: {
      gap: 52,
    },
    [breakpoints.down('sm')]: {
      gap: 24,
    },
  },
  lastRow: {
    borderBottom: `1px solid ${palette.distinctiveGray.main}`,
  },
  selectedRow: {
    background: palette.distinctiveGray2.main,
  },
  delete: {
    color: palette.accentRed.main,
  },
  templateList: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  defaultTemplateChip: {
    margin: spacing(0.5),
    fontSize: 12,
    color: palette.primary.main,
    backgroundColor: palette.backgroundMint.main,
    padding: spacing(1, 0.5),
    textAlign: 'right',
  },
  templateForm: {
    padding: spacing(3, 2, 2),
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
}))

function Rows({ active, data, onTemplateClick }) {
  const classes = useStyles()

  if (isEmpty(data)) {
    return <BaseRow withDivider emptyPlaceholder="No Templates Added" />
  }

  return data.map((template, index) => (
    <BaseRow
      withDivider
      columns={
        <Box
          onClick={() => {
            onTemplateClick(template)
          }}
          className={clsx(
            classes.row,
            index === data.length - 1 && classes.lastRow,
            active?._id === template._id && classes.selectedRow,
          )}
        >
          {template.default ? (
            <>
              <Typography component="span">{template.title}</Typography>
              <Chip label="Default" className={classes.defaultTemplateChip} />
            </>
          ) : (
            <Typography align="left" component="span">
              {template.title}
            </Typography>
          )}
        </Box>
      }
    />
  ))
}

function EncounterTemplates() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const getRequest = useGetRequest()
  const postRequest = usePostRequest()
  const putRequest = usePutRequest()
  const deleteRequest = useDeleteRequest()
  const currentUser = useCurrentUser()

  const [loading, setLoading] = useState(true)
  const [fetch, shouldFetch] = useState(true)
  const [templates, setTemplates] = useState(null)
  const [activeTemplate, setActiveTemplate] = useState(null)

  const fetchTemplates = async ({ userID }) => {
    try {
      setLoading(true)
      const userTemplates = await getRequest({
        url: `${API_BASE_URL}/cp/noteTemplates/${userID}`,
      })
      setTemplates(userTemplates)
      if (!isEmpty(userTemplates)) {
        const [defaultTemplate] = userTemplates.filter(
          template => template.default === true,
        )

        setActiveTemplate(defaultTemplate)
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const loadActiveTemplate = selectedTemplate => {
    setActiveTemplate(selectedTemplate)
  }

  const deleteTemplate = async ({ templateID }) => {
    try {
      setLoading(true)
      await deleteRequest({
        url: `${API_BASE_URL}/cp/noteTemplates/${templateID}`,
      })
      setActiveTemplate(null)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      shouldFetch(true)
      setLoading(false)
    }
  }

  const setDefault = async ({ userID, templateID, body }) => {
    try {
      setLoading(true)
      const updatedTemplate = await putRequest({
        url: `${API_BASE_URL}/cp/noteTemplates/${userID}&${templateID}`,
        body,
      })
      setActiveTemplate(updatedTemplate)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      shouldFetch(true)
      setLoading(false)
    }
  }

  const saveTemplate = async ({ userID, templateID, body }) => {
    try {
      setLoading(true)
      const updatedTemplate = await putRequest({
        url: `${API_BASE_URL}/cp/noteTemplates/${userID}&${templateID}`,
        body,
      })
      setActiveTemplate(updatedTemplate)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      shouldFetch(true)
      setLoading(false)
    }
  }

  const createTemplate = async ({ userID, fields }) => {
    try {
      setLoading(true)
      const template = await postRequest({
        url: `${API_BASE_URL}/cp/noteTemplates/${userID}`,
        body: {
          default: false,
          title: fields.title,
          text: fields.text,
          createdBy: userID,
          createdAt: new Date(),
        },
      })
      setActiveTemplate(template)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      shouldFetch(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (fetch === true) {
      fetchTemplates({ userID: currentUser._id })
      shouldFetch(false)
    }
  }, [fetch]) // eslint-disable-line

  if (fetch || loading || isNull(templates)) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={4}>
        <BaseCard className={classes.fullHeight}>
          <BaseCardHeader
            title="My Templates"
            actionOnClick={() => {
              setActiveTemplate(null)
            }}
            actionText={activeTemplate?.title ? 'Add New' : ''}
            className={classes.templateList}
          />
          <Rows
            data={templates}
            active={activeTemplate}
            onTemplateClick={loadActiveTemplate}
          />
        </BaseCard>
      </Grid>
      <Grid item xs={8}>
        <BaseCard className={classes.fullHeight}>
          <div className={classes.templateForm}>
            <TemplateForm
              activeTemplate={activeTemplate}
              onSave={saveTemplate}
              onCreate={createTemplate}
              onDelete={deleteTemplate}
              onSetDefault={setDefault}
            />
          </div>
        </BaseCard>
      </Grid>
    </Grid>
  )
}

export default EncounterTemplates
