import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'

import { HTTP_METHODS } from 'constants/api'

export default function useDeleteRequest() {
  const { fetchWithRefresh } = useGoogleAuth()

  const deleteRequest = useCallback(
    async ({ url }) => {
      const response = await fetchWithRefresh({
        url,
        options: {
          method: HTTP_METHODS.DELETE,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      })

      if (response.ok) {
        const result = await response.json()
        return result
      } else {
        throw new Error('Failed to delete record')
      }
    },
    [fetchWithRefresh],
  )

  return deleteRequest
}
