import { useMemo } from 'react'
import { makeStyles, Box, Chip, Tooltip, Typography } from '@material-ui/core'
import { format } from 'date-fns'

import TextButton from 'components/Shared/TextButton'
import { useLayoutStyles } from 'hooks'

import { COLOR_MAPPINGS, ASSESSMENT_TYPE_DETAILS } from 'constants/progress'
import { getProgressDetails } from 'utils/progress'

const ChipTooltip = ({
  label,
  color = '#183F4F',
  backgroundColor = '#F2F2F2',
  tooltip,
}) => {
  return (
    <Tooltip title={<Typography variant="body1">{tooltip}</Typography>}>
      <Chip label={label} style={{ color, backgroundColor }} />
    </Tooltip>
  )
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${palette.distinctiveGray.main}`,
    borderRadius: '8px',
    padding: spacing(2),
    marginBottom: spacing(1),
    backgroundColor: 'white',
  },
  date: {
    flex: 2,
    color: palette.text.primary,
    minWidth: 250,
  },
  dateLabel: {
    width: 128,
    display: 'inline-block',
  },
  actionBox: {
    flex: 1,
    textAlign: 'right',
    minWidth: 120,
  },
  progressBox: {
    display: 'flex',
    flex: 4,
    gap: spacing(1),
  },
  descriptionBox: {
    display: 'flex',
    flex: 2,
    gap: spacing(1),
  },
}))

const MemberProgressItem = ({
  progress,
  onClickReview,
  onClickViewDetails,
}) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const chips = useMemo(() => {
    return progress.type.map(type => {
      const score = progress[ASSESSMENT_TYPE_DETAILS[type].field].reduce(
        (sum, each) => sum + each,
        0,
      )
      const progressDetail = getProgressDetails(type, score)

      return {
        type,
        content: `${ASSESSMENT_TYPE_DETAILS[type].label}: ${score}`,
        ...progressDetail,
      }
    })
  }, [progress])

  const remarks = progress.comment
  const sideEffects = (progress.sideEffects ?? []).join(', ')

  const handleClickViewDetails = () => {
    onClickViewDetails(progress)
  }

  const handleClickReview = () => {
    onClickReview(progress)
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.date} variant="subtitle2">
        <Box className={classes.dateLabel}>
          {format(new Date(progress.timeStamp), 'MMMM dd, yyyy')}
        </Box>

        {!progress.reviewedBy && (
          <Chip
            className={layoutClasses.ml1}
            label="Unread"
            style={{
              ...COLOR_MAPPINGS.YELLOW,
            }}
          />
        )}
      </Typography>

      <div className={classes.progressBox}>
        {chips.map(chip => (
          <ChipTooltip
            key={chip.type}
            color={chip.color}
            backgroundColor={chip.backgroundColor}
            label={chip.content}
            tooltip={chip.tooltip}
          />
        ))}
      </div>

      <div className={classes.descriptionBox}>
        {sideEffects && (
          <ChipTooltip label="Side Effects" tooltip={sideEffects} />
        )}
        {remarks && <ChipTooltip label="Remarks" tooltip={remarks} />}
      </div>

      <div className={classes.actionBox}>
        {progress.reviewedBy ? (
          <TextButton onClick={handleClickViewDetails}>View Details</TextButton>
        ) : (
          <TextButton onClick={handleClickReview}>Review</TextButton>
        )}
      </div>
    </Box>
  )
}

export default MemberProgressItem
