import { useState } from 'react'
import {
  Box,
  makeStyles,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { useSelector } from 'react-redux'

import TabsHeader from 'components/Shared/TabsHeader'
import EncounterTemplates from 'components/AccountSettings/EncounterTemplates'

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    padding: spacing(2, 3),
    color: palette.text.primary,
    background: palette.common.white,
    fontSize: 20,
  },
  content: {
    flex: 1,
  },
}))

function AccountSettings() {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)

  const isMembersLoading = useSelector(state => state.members.isLoading)

  if (isMembersLoading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Typography className={classes.title}>Account Settings</Typography>
      <TabsHeader
        selectedTab={selectedTab}
        tabs={['Templates']}
        onChangeTab={setSelectedTab}
      />
      <Box className={classes.content}>
        {selectedTab === 0 ? <EncounterTemplates /> : null}
      </Box>
    </Box>
  )
}
export default AccountSettings
