export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const HTTP_METHODS = {
  DELETE: 'DELETE',
  GET: 'GET',
  OPTIONS: 'OPTIONS',
  POST: 'POST',
  PUT: 'PUT',
}

export const VISIBILITY_STATES = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  PRERENDER: 'prerender',
  UNLOADED: 'unloaded',
}
