import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'physicallyActive',
  label: 'Physical Activeness',
  componentProps: {
    multiline: true,
  },
}

const PhysicalActivenessSection = props => (
  <SingleFieldSection {...props} title="Physical Activeness" field={FIELD} />
)

export default withMemberForm('PhysicalActivenessSectionForm', [
  'physicallyActive',
])(PhysicalActivenessSection)
