import { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import TextButton from 'components/Shared/TextButton'
import { useRxStyles } from 'hooks'

const ProgressAssessmentMenu = ({ title, options, onClick }) => {
  const rxClasses = useRxStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleClickNewRequest = option => {
    handleMenuClose()
    onClick(option)
  }

  return (
    <>
      <TextButton
        className={rxClasses.fontSize14}
        aria-label="Send request options"
        aria-controls="send-request-coverage-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        {title}
      </TextButton>

      <Menu
        id="send-request-coverage-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleMenuClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.type}
            onClick={() => handleClickNewRequest(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ProgressAssessmentMenu
