import * as Yup from 'yup'

const digitsRegExp = /^[0-9]{10}$/

const zipRegExp = /(?!([089])\1{4})\d{5}/

export const phoneSchemaDigitsOnly = Yup.string().matches(digitsRegExp, {
  message: 'Phone number must only have 10 digits',
  excludeEmptyString: true,
})

export const zipSchema = Yup.string().matches(zipRegExp, {
  message: 'Zip code must be exactly 5 numbers',
  excludeEmptyString: true,
})

export const addressSchemaLoose = Yup.object().shape({
  address1: Yup.string(),
  address2: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: zipSchema,
})

export const addressSchema = Yup.object().shape({
  address1: Yup.string().required('Street address is required'),
  address2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: zipSchema,
})

const illnessHistorySchema = Yup.object().shape({
  type: Yup.string(),
  description: Yup.string(),
})

const illnessHistoryListSchema = Yup.array().of(illnessHistorySchema)

const psychHistorySchema = Yup.object().shape({
  date: Yup.date().required('Required'),
  description: Yup.string(),
})

const psychHistoryListSchema = Yup.array().of(psychHistorySchema)

export const memberSchema = Yup.object().shape({
  // Header
  careTeamNote: Yup.string('Invalid'),

  // Profile
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  preferredName: Yup.string('Invalid'),
  dob: Yup.date().required('Required'),
  status: Yup.string('Invalid'),
  heightFeet: Yup.number('Invalid'),
  heightInch: Yup.number('Invalid'),
  weight: Yup.number('Invalid'),
  gender: Yup.string('Invalid'),
  pronouns: Yup.string('Invalid'),
  race: Yup.string('Invalid'),
  preferredLanguage: Yup.string('Invalid'),
  source: Yup.object().shape({
    category: Yup.string('Invalid'),
    description: Yup.string('Invalid'),
  }),

  // Care Team
  carePartner: Yup.string().nullable().required('Required'),
  // psychiatrist: Yup.string().nullable().required('Required'),
  // therapist: Yup.string().nullable().required('Required'),

  // Contact Info
  email: Yup.string().email().required('Required'),
  phone: phoneSchemaDigitsOnly.required('Required'),
  shippingAddress: addressSchema,
  billingAddress: addressSchema,
  emergencyContact: Yup.object().shape({
    name: Yup.string(),
    relationship: Yup.string(),
    phone: phoneSchemaDigitsOnly,
    email: Yup.string().email(),
  }),

  // Diagnosis
  diagnosisList: Yup.array().of(
    Yup.object().shape({
      date: Yup.date(),
      status: Yup.string(),
      type: Yup.string(),
    }),
  ),

  // Present Illness History
  symptomList: illnessHistoryListSchema,
  symptomRemark: Yup.string(),
  relievedBy: Yup.string(),
  worsenedBy: Yup.string(),
  medList: illnessHistoryListSchema,
  psychTreatmentList: illnessHistoryListSchema,
  substanceList: illnessHistoryListSchema,

  // Past Psych History
  psychFamilyHistory: Yup.array().of(
    Yup.object().shape({
      relationship: Yup.string(),
      psychHistory: Yup.string(),
    }),
  ),
  psychTrauma: Yup.string(),
  psychHospitalizationHistory: psychHistoryListSchema,
})

export const externalProviderSchema = addressSchemaLoose.concat(
  Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    phone: phoneSchemaDigitsOnly,
    fax: Yup.string(),
    email: Yup.string().email(),
  }),
)

//Insurance Coverage
export const insuranceCoverageSchema = Yup.object().shape({
  payer: Yup.string().required('Required'),
  coverageType: Yup.string().required('Required'),
  idNumber: Yup.string().required('Required'),
  planName: Yup.string().required('Required'),
  subscriberRelationship: Yup.string().required('Required'),
})
