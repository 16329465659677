import { format } from 'date-fns'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles, Box, Chip, Typography } from '@material-ui/core'

import { daysBetween, formatFullName } from 'utils/formatters'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    padding: spacing(2),
    marginBottom: spacing(1),
    backgroundColor: 'white',
    border: `1px solid ${palette.accentRed.main}`,
    borderRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameLabel: {
    flex: 2,
    color: palette.text.primary,
  },
  flaggedLabel: {
    flex: 3,
    color: palette.text.primary,
  },
  list: {
    flex: 6,
    listStyleType: 'disc',
  },
  newChip: {
    background: palette.backgroundYellow2.main,
    borderRadius: 16,
    marginLeft: spacing(2),
    color: '#BA940F',
  },
  item: {
    color: palette.text.primary,
    fontSize: 14,
    fonttWeight: 400,
    lineHeight: '18px',
    '&::marker': {
      fontSize: '10px',
      lineHeight: '18px',
    },
  },
  button: {
    color: palette.primary.main,
    fontSize: 14,
    fontWeight: 400,

    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
}))

const RiskMemberCard = ({ progress }) => {
  const classes = useStyles()

  const history = useHistory()
  const byIdMembers = useSelector(({ members }) => members.byId)
  const member = useMemo(
    () => byIdMembers[progress.memberID],
    [byIdMembers, progress.memberID],
  )

  const handleClickViewProgress = () => {
    history.push(`/members/${progress.memberID}/progress`)
  }

  const isNew = daysBetween(new Date(), progress.timeStamp) <= 14

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle2" className={classes.nameLabel}>
        {formatFullName(member, true)}

        {isNew && <Chip className={classes.newChip} label="New" size="small" />}
      </Typography>

      <Typography variant="subtitle2" className={classes.flaggedLabel}>
        Flagged {format(new Date(progress.timeStamp), 'MMMM dd, yyyy')}
      </Typography>

      <ul className={classes.list}>
        {progress.totalGAD >= 15 && (
          <li className={classes.item}>High GAD-7 Score</li>
        )}
        {progress.totalPHQ >= 20 && (
          <>
            <li className={classes.item}>High PHQ-9 Score</li>
            <li className={classes.item}>
              Response suggesting suicidality (PHQ-9)
            </li>
          </>
        )}
      </ul>

      <Typography className={classes.button} onClick={handleClickViewProgress}>
        View Progress
      </Typography>
    </Box>
  )
}

export default RiskMemberCard
