import { ENCOUNTER_STATE } from 'utils/encounters'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(({ palette }) => ({
  [ENCOUNTER_STATE.DRAFT]: {
    color: palette.accentRed.main,
    backgroundColor: palette.backgroundRed.main,
  },
  [ENCOUNTER_STATE.FINALIZED]: {
    backgroundColor: palette.backgroundMint.main,
    color: palette.secondaryMint1.main,
  },
  [ENCOUNTER_STATE.NO_SHOW]: {
    color: palette.accentRed.main,
    backgroundColor: palette.backgroundRed.main,
  },
}))

export default function EncounterStateChip({ state }) {
  const classes = useStyles()
  return <Chip className={classes[state]} label={state} />
}
