import { useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'

import { MemberStatuses } from 'constants/selectOptions'
import {
  selectAllMembers,
  selectNormalizedCarePartners,
  selectNormalizedPsychiatrists,
  selectNormalizedTherapists,
} from 'redux/selectors'

import { formatFullName } from 'utils/formatters'
import { includesIgnoreCase } from 'utils/commonHelpers'
import { MEMBER_STATUS } from 'constants/member'
import { USER_TITLE } from 'constants/user'
import MembersHeader from './MembersHeader'
import MemberCard from '../Shared/MemberCard'

const useStyles = makeStyles(({ palette, spacing }) => ({
  fab: {
    position: 'fixed',
    right: spacing(2),
    bottom: spacing(2),
    color: palette.common.white,
  },
  members: {
    overflowY: 'scroll',
    padding: spacing(2),
  },
}))

const mapStateToProps = state => ({
  isLoading: state.members.isLoading,
  error: state.members.error,
  allMembers: selectAllMembers(state),
  carePartners: selectNormalizedCarePartners(state),
  psychiatrists: selectNormalizedPsychiatrists(state),
  therapists: selectNormalizedTherapists(state),
})

const Members = ({
  carePartners,
  psychiatrists,
  therapists,
  allMembers,
  isLoading,
  error,
}) => {
  const classes = useStyles()
  const [filters, setFilters] = useState({
    clear: false,
    query: '',
    carePartners: [],
    psychiatrists: [],
    therapists: [],
    statuses: [MEMBER_STATUS.Active, MEMBER_STATUS.Onboarding],
  })

  const selectFilters = useMemo(() => {
    return [
      {
        name: 'carePartners',
        label: `${USER_TITLE.CARE_COORDINATOR}`,
        options: carePartners.allIds,
        getOptionLabel: id => formatFullName(carePartners.byId[id]),
        tags: carePartners.byId,
      },
      {
        name: 'psychiatrists',
        label: `${USER_TITLE.PSYCHIATRIST}`,
        options: psychiatrists.allIds,
        getOptionLabel: id => formatFullName(psychiatrists.byId[id]),
        tags: psychiatrists.byId,
      },
      {
        name: 'therapists',
        label: `${USER_TITLE.THERAPIST}`,
        options: therapists.allIds,
        getOptionLabel: id => formatFullName(therapists.byId[id]),
        tags: therapists.byId,
      },
      {
        name: 'statuses',
        label: 'Status',
        options: MemberStatuses,
        defaultValue: [MEMBER_STATUS.Active, MEMBER_STATUS.Onboarding],
      },
    ]
  }, [carePartners, psychiatrists, therapists])

  const filteredMembers = useMemo(() => {
    const filteredMembers = allMembers.filter(member => {
      const fullName = [
        member.firstName,
        member.lastName,
        member.preferredName,
      ].join(' ')

      return (
        filters.statuses.includes(member.status) &&
        (!filters.query ||
          includesIgnoreCase(fullName, filters.query) ||
          includesIgnoreCase(member.preferredName, filters.query) ||
          includesIgnoreCase(member.phone, filters.query) ||
          includesIgnoreCase(member.email, filters.query)) &&
        (isEmpty(filters.carePartners) ||
          filters.carePartners.includes(member.carePartner)) &&
        (isEmpty(filters.psychiatrists) ||
          filters.psychiatrists.includes(member.psychiatrist)) &&
        (isEmpty(filters.therapists) ||
          filters.therapists.includes(member.therapist))
      )
    })

    return sortBy(filteredMembers, member => member.lastName?.toLowerCase())
  }, [allMembers, filters])

  const handleFilterChange = useCallback(
    newFilters =>
      setFilters(prevFilters => ({ ...prevFilters, ...newFilters })),
    [],
  )

  if (isLoading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <MembersHeader
        selectFilters={selectFilters}
        onFilterChange={handleFilterChange}
        shouldClear={filters.clear}
      />

      <Grid
        spacing={2}
        container
        direction="row"
        alignItems="stretch"
        className={classes.members}
      >
        {error ? (
          <div className="centered">
            Failed loading page, please refresh the page
          </div>
        ) : (
          filteredMembers.map(member => (
            <Grid key={member._id} item sm={12} md={6} lg={4} xl={3}>
              <MemberCard
                member={member}
                carePartnersById={carePartners.byId}
                psychiatristsById={psychiatrists.byId}
                therapistsById={therapists.byId}
              />
            </Grid>
          ))
        )}
      </Grid>

      <Fab
        component={Link}
        to="/members/new"
        color="primary"
        aria-label="add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </Box>
  )
}

export default connect(mapStateToProps)(Members)
