import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const FormikCheckbox = ({
  field: { name, value },
  form: { setFieldValue },
  checkProps,
  ...props
}) => {
  const handleChange = e => {
    setFieldValue(name, e.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={value}
          onChange={handleChange}
          {...checkProps}
        />
      }
      {...props}
    />
  )
}

export default FormikCheckbox
