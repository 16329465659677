import clsx from 'clsx'
import { isPast } from 'date-fns'
import { isEmpty } from 'lodash/fp'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles, useLayoutStyles } from 'hooks'
import { coverageMap } from 'utils/insurance'
import {
  capitalizeEveryWord,
  toTitleCase,
  formatDateInMMDDYYYYDashed,
} from 'utils/formatters'
import InsuranceCardDisplay from 'components/Member/MemberBilling/InsuranceCard/InsuranceCardDisplay'
import InsuranceCardTitle from 'components/Member/MemberBilling/InsuranceCard/InsuranceCardTitle'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(({ palette }) => ({
  title: { lineHeight: '16px', fontSize: '12px' },
  text: { lineHeight: '24px', fontSize: '16px' },
  highlightRed: {
    color: palette.accentRed.main,
  },
}))

export default function ViewInsuranceDialog({
  open,
  handleClose,
  message,
  insurance,
  title,
}) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const layoutClasses = useLayoutStyles(theme)
  const classes = useStyles()

  const payer = insurance.payer.type
  const coverage = coverageMap(insurance.coverageType)
  const planStartDate = insurance.planStartDate
    ? formatDateInMMDDYYYYDashed(insurance.planStartDate)
    : 'N/A'
  const planEndDate = insurance.planEndDate
    ? formatDateInMMDDYYYYDashed(insurance.planEndDate)
    : 'N/A'
  const planPeriod = `${planStartDate} ~ ${planEndDate}`
  const expired = insurance.planEndDate
    ? isPast(new Date(insurance.planEndDate))
    : false

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        handleClose(false)
      }}
      title={title}
      dialogContent={
        <>
          <Typography className={clsx(rxClasses.typography, layoutClasses.mb3)}>
            {message ?? ''}
          </Typography>

          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={6}>
              <InsuranceCardTitle side="front" />
              <InsuranceCardDisplay id={insurance._id} side="front" />
              <InsuranceCardTitle side="back" />
              <InsuranceCardDisplay id={insurance._id} side="back" />
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="p"
                variant="body1"
                className={classes.title}
              >
                Payer
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, layoutClasses.mb2)}
              >
                {payer}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.title}
              >
                Type of coverage
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, layoutClasses.mb2)}
              >
                {capitalizeEveryWord(coverage.display)}
                {coverage.code === 'pay'
                  ? ''
                  : ` (${coverage.code.toUpperCase()})`}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.title}
              >
                Group
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, layoutClasses.mb2)}
              >
                {isEmpty(insurance.groupNumber)
                  ? 'Group number not entered'
                  : insurance.groupNumber}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.title}
              >
                ID Number
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, layoutClasses.mb2)}
              >
                {insurance.idNumber}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.title}
              >
                Plan Name
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, layoutClasses.mb2)}
              >
                {isEmpty(insurance.planName)
                  ? 'Plan name not entered'
                  : insurance.planName}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.title}
              >
                Period
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={clsx({
                  [classes.highlightRed]: expired,
                  [classes.text]: true,
                  [layoutClasses.mb2]: true,
                })}
              >
                {planPeriod}
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.title}
              >
                Subscriber
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={clsx(classes.text, layoutClasses.mb2)}
              >
                {toTitleCase(insurance.subscriberRelationship)}
              </Typography>
            </Grid>
          </Grid>
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              handleClose(false)
            }}
          >
            CLOSE
          </TextButton>
        </>
      }
    />
  )
}
