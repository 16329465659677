import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { UPLOAD_OPTIONS_MENU } from 'constants/member'

const useStyles = makeStyles({
  popoverTrigger: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
    color: '#278C79',
  },

  menuItem: {
    '&:first-child': {
      fontWeight: 600,
    },

    '&:not(:last-child)': {
      borderBottom: '1px solid #E3E3E3',
    },
  },
})

export default function NewDocumentUploadMenu({ onClickOption }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickOption = option => {
    onClickOption(option)
    setAnchorEl(null)
  }

  return (
    <div className={classes.container}>
      <Button
        aria-describedby="upload-documents-menu"
        variant="text"
        color="primary"
        className={classes.popoverTrigger}
        onClick={handleClick}
      >
        NEW DOCUMENT
        <ExpandMoreIcon />
      </Button>

      <Menu
        id="upload-documents-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        {UPLOAD_OPTIONS_MENU.filter(option => !option.hidden).map(option => (
          <MenuItem
            key={option.key}
            className={classes.menuItem}
            onClick={() => handleClickOption(option.name)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
