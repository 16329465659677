import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { useState, useCallback, useEffect } from 'react'
import useGetRequest from 'hooks/api/useGetRequest'
import { API_BASE_URL } from 'constants/api'
import { useTheme } from '@material-ui/core/styles'
import {
  useFormFields,
  useRxStyles,
  useLayoutStyles,
  useCurrentUser,
} from 'hooks'

import { useSnackbar } from 'notistack'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'

export default function SelectTemplateDialog({
  open,
  onClose,
  message,
  title,
  onSetTemplate,
}) {
  const { enqueueSnackbar } = useSnackbar()
  const getRequest = useGetRequest()
  const currentUser = useCurrentUser()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const layoutClasses = useLayoutStyles(theme)
  const [loading, setLoading] = useState(false)
  const [userTemplates, setUserTemplates] = useState([])
  const [fields, handleFieldChange] = useFormFields({
    selectedTemplateTitle: '',
  })

  const fetchUserTemplates = useCallback(async () => {
    try {
      setLoading(true)
      const userTemplates = await getRequest({
        url: `${API_BASE_URL}/cp/noteTemplates/${currentUser._id}`,
      })

      setUserTemplates(userTemplates)
    } catch (e) {
      enqueueSnackbar('Failed to fetch user templates', {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [getRequest]) // eslint-disable-line

  useEffect(() => {
    fetchUserTemplates()
  }, [fetchUserTemplates])

  useEffect(() => {
    const defaultTemplate = userTemplates.find(template => template.default)
    if (defaultTemplate)
      handleFieldChange({
        target: {
          name: 'selectedTemplateTitle',
          value: defaultTemplate.title,
        },
      })
  }, [userTemplates, handleFieldChange])

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={title}
      dialogContent={
        <>
          <Typography className={clsx(rxClasses.typography, layoutClasses.mb3)}>
            {message ?? ''}
          </Typography>
          <Select
            fullWidth
            displayEmpty
            variant="outlined"
            name="selectedTemplateTitle"
            label="User Templates"
            value={fields.selectedTemplateTitle}
            onChange={e => {
              handleFieldChange({
                target: {
                  name: 'selectedTemplateTitle',
                  value: e.target.value,
                },
              })
            }}
          >
            <MenuItem disabled value="">
              <em>
                {isEmpty(userTemplates) ? 'No Templates' : 'Select Template'}
              </em>
            </MenuItem>
            {Object.values(userTemplates).map(template => {
              const isDefault = template?.default ?? false
              return (
                <MenuItem key={template.title} value={template.title}>
                  {template.title}
                  {isDefault ? '(Default)' : null}
                </MenuItem>
              )
            })}
          </Select>
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              const [selectedTemplate] = userTemplates.filter(
                template => template.title === fields.selectedTemplateTitle,
              )

              onSetTemplate(selectedTemplate)
              onClose()
            }}
            disabled={!fields.selectedTemplateTitle}
          >
            APPLY
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
