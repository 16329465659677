import { useMemo } from 'react'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'

import { formatDate } from 'utils/formatters'
import { useCurrentUser } from 'hooks'

export const useGetEncounters = (
  isMyPatientCheck,
  allMembers,
  encounters,
  state,
) => {
  const currentUser = useCurrentUser()

  const filteredMembers = useMemo(
    () =>
      isMyPatientCheck
        ? allMembers.filter(
            member =>
              member.carePartner === currentUser._id ||
              member.psychiatrist === currentUser._id ||
              member.therapist === currentUser._id,
          )
        : allMembers,
    [allMembers, currentUser, isMyPatientCheck],
  )

  const sortedEncounters = useMemo(
    () =>
      orderBy(
        Object.keys(encounters)
          .map(key => encounters[key])
          .filter(encounter =>
            state === 'pending'
              ? !encounter.finalized
              : state === 'finalized'
              ? encounter.finalized && !encounter.noShow
              : encounter.finalized && encounter.noShow,
          ),
        [
          item => {
            try {
              return new Date(item.dateCreated)
            } catch {
              return new Date()
            }
          },
        ],
        [state === 'pending' ? 'asc' : 'desc'],
      ),
    [encounters, state],
  )

  const filteredEncounters = useMemo(
    () =>
      isMyPatientCheck
        ? sortedEncounters.filter(
            encounter =>
              filteredMembers.findIndex(
                member => member._id === encounter.memberId,
              ) !== -1,
          )
        : sortedEncounters,
    [filteredMembers, isMyPatientCheck, sortedEncounters],
  )

  const groupedEncounters = useMemo(
    () =>
      groupBy(filteredEncounters, item => {
        try {
          return formatDate(item.dateCreated, 'yyyy/MM/dd')
        } catch {
          return formatDate(new Date(), 'yyyy/MM/dd')
        }
      }),
    [filteredEncounters],
  )

  return groupedEncounters
}
