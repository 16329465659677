import {
  Box,
  makeStyles,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import TabsHeader from 'components/Shared/TabsHeader'

import LatestPatientProgress from './LatestPatientProgress'
import OverdueMembers from './OverdueMembers'
import HighRiskMember from './HighRiskMember'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    minWidth: 1000,
    overflowY: 'scroll',
    padding: spacing(0, 3),
  },
  title: {
    padding: spacing(2, 3),
    color: palette.text.primary,
    background: palette.common.white,
    fontSize: 20,
  },
  highRiskSidebar: {
    borderColor: palette.distinctiveGray.main,
    borderStyle: 'solid',
    borderWidth: '0 0 0 1px',
    flexShrink: 0,
    width: 400,
  },
}))

const Progress = () => {
  const classes = useStyles()
  const isMembersLoading = useSelector(state => state.members.isLoading)
  const isProgressesLoading = useSelector(
    ({ progresses }) => progresses.isLoading,
  )

  const [selectedTab, setSelectedTab] = useState(0)
  const [isOnlyMyPatients, setIsOnlyMyPatients] = useState(true)

  if (isMembersLoading || isProgressesLoading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Progress</Typography>

      <TabsHeader
        selectedTab={selectedTab}
        isOnlyMyPatients={isOnlyMyPatients}
        tabs={['Latest', 'Overdue', 'High Risk']}
        onChangeTab={setSelectedTab}
        onChangeOnlyMyPatients={setIsOnlyMyPatients}
      />

      <Box className={classes.content}>
        {selectedTab === 0 ? (
          <LatestPatientProgress isMyPatientsProgress={isOnlyMyPatients} />
        ) : selectedTab === 1 ? (
          <OverdueMembers isMyPatientsProgress={isOnlyMyPatients} />
        ) : selectedTab === 2 ? (
          <HighRiskMember isMyPatientsProgress={isOnlyMyPatients} />
        ) : null}
      </Box>
    </Box>
  )
}

export default Progress
