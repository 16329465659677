import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function ArchiveModal({ open, onClose, onArchive }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Archive Confirmation</DialogTitle>
      <div className="modal">
        <DialogContent>
          <h4>Are you sure you want to archive this note?</h4>
        </DialogContent>
        <DialogActions>
          <div></div>
          <div>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onArchive} className="delete">
              Archive
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}
