import Box from '@material-ui/core/Box'

import DiagnosisCard from './DiagnosisCard'
import PresentIllnessHistoryAccordion from './PresentIllnessHistoryAccordion'
import PastPsychHistoryAccordion from './PastPsychHistoryAccordion'
import PastMedicalHistoryAccordion from './PastMedicalHistoryAccordion'
import SocialBackgroundAccordion from './SocialBackgroundAccordion'
import RiskCard from './RiskCard'

function MemberDetails(props) {
  return (
    <>
      <DiagnosisCard {...props} />

      <Box mt={2}>
        <PresentIllnessHistoryAccordion {...props} />
        <PastPsychHistoryAccordion {...props} />
        <PastMedicalHistoryAccordion {...props} />
        <SocialBackgroundAccordion {...props} />
      </Box>

      <Box mt={2}>
        <RiskCard {...props} />
      </Box>
    </>
  )
}

export default MemberDetails
