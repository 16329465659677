import { isNull, isObject } from 'lodash'
import { useState, useCallback, useEffect } from 'react'
import useGetRequest from 'hooks/api/useGetRequest'
import {
  CoverageTypes,
  SubscriberRelationshipType,
} from 'constants/selectOptions'
import { capitalizeEveryWord } from 'utils/formatters'
import { coverageMap } from 'utils/insurance'
import { API_BASE_URL } from 'constants/api'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import TextInput from 'ui/TextInput'
import DatePicker from 'components/Shared/Forms/Fields/DatePicker'

function getCoverageLabel(type) {
  const coverage = coverageMap(type)

  return coverage
    ? coverage.code.toLowerCase() === 'pay'
      ? `${capitalizeEveryWord(coverage.display)}`
      : `${capitalizeEveryWord(coverage.display)} (${coverage.code})`
    : ''
}

export default function InsuranceForm({ fields, handleFieldChange }) {
  const getRequest = useGetRequest()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payerOptions, setPayerOptions] = useState([])
  const [payerInput, setPayerInput] = useState(fields.payer.type)
  const [coverageTypeInput, setCoverageTypeInput] = useState(
    getCoverageLabel(fields.coverageType),
  )
  const filterTypesOptions = createFilterOptions({
    stringify: ({ display, code }) => `${display} (${code})`,
  })

  const fetchPayerOptions = useCallback(async () => {
    try {
      setLoading(true)
      const payerTypes = await getRequest({
        url: `${API_BASE_URL}/cp/payers/`,
      })
      setPayerOptions(payerTypes)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }, [getRequest])

  useEffect(() => {
    if (open && payerOptions.length === 0) {
      fetchPayerOptions()
    }
  }, [fetchPayerOptions, open, payerOptions])

  return (
    <div>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        name="payer"
        id="payer"
        onChange={(_, newValue) => {
          handleFieldChange({
            target: {
              name: 'payer',
              value: newValue,
            },
          })
        }}
        options={payerOptions}
        inputValue={payerInput}
        onInputChange={(event, value) => {
          if (!isNull(event)) {
            setPayerInput(value)
            return event.target
          }
        }}
        getOptionLabel={option => option.type}
        getOptionSelected={(option, value) => option.type === value.type}
        renderInput={params => (
          <TextInput
            {...params}
            style={{ fontSize: '8px' }}
            label="Payer"
            variant="outlined"
            required
            error={!fields.payer}
            helperText={!fields.payer ? 'Payer is required' : ''}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        name="coverageType"
        id="coverageType"
        onChange={(_, newValue) => {
          handleFieldChange({
            target: {
              name: 'coverageType',
              value: isObject(newValue) ? newValue.code : newValue,
            },
          })
        }}
        options={CoverageTypes}
        inputValue={coverageTypeInput}
        onInputChange={(event, value) => {
          if (!isNull(event)) {
            setCoverageTypeInput(value)
            return event.target
          }
        }}
        filterOptions={filterTypesOptions}
        getOptionLabel={({ display, code }) => {
          const option =
            code.toLowerCase() === 'pay'
              ? `${capitalizeEveryWord(display)}`
              : `${capitalizeEveryWord(display)} (${code})`
          return option
        }}
        filterSelectedOptions
        renderOption={({ display, code }) => {
          const option =
            code.toLowerCase() === 'pay'
              ? `${capitalizeEveryWord(display)}`
              : `${capitalizeEveryWord(display)} (${code})`
          return option
        }}
        renderInput={params => (
          <TextInput
            {...params}
            style={{ fontSize: '8px' }}
            label="Types of Coverage"
            variant="outlined"
            required
            error={!fields.coverageType}
            helperText={
              !fields.coverageType ? 'Type of Coverage is required' : ''
            }
            fullWidth
          />
        )}
      />

      <TextInput
        id="groupNumber"
        fullWidth
        variant="outlined"
        label="Group"
        name="groupNumber"
        value={fields.groupNumber}
        onChange={handleFieldChange}
      />

      <TextInput
        required
        helperText={!fields.idNumber ? 'ID Number is required' : ''}
        error={!fields.idNumber}
        id="idNumber"
        fullWidth
        variant="outlined"
        label="ID Number"
        name="idNumber"
        value={fields.idNumber}
        onChange={handleFieldChange}
      />

      <TextInput
        id="planName"
        fullWidth
        variant="outlined"
        label="Plan Name"
        name="planName"
        value={fields.planName}
        onChange={handleFieldChange}
      />

      <DatePicker
        id="planStartDate"
        fullWidth
        variant="outlined"
        label="Start Date"
        name="planStartDate"
        value={fields.planStartDate}
        onChange={handleFieldChange}
      />

      <DatePicker
        id="planEndDate"
        fullWidth
        variant="outlined"
        label="End Date"
        name="planEndDate"
        value={fields.planEndDate}
        onChange={handleFieldChange}
      />

      <TextInput
        select
        required
        helperText={
          !fields.subscriberRelationship
            ? 'Subscriber relationship is required'
            : ''
        }
        error={!fields.subscriberRelationship}
        id="subscriberRelationship"
        fullWidth
        variant="outlined"
        label="Subscriber"
        name="subscriberRelationship"
        value={fields.subscriberRelationship}
        onChange={event => {
          handleFieldChange({
            target: {
              name: event.target.name,
              value: event.target.value,
            },
          })
        }}
      >
        {SubscriberRelationshipType.map(option => (
          <MenuItem key={option.code} value={option.code}>
            {option.display}
          </MenuItem>
        ))}
      </TextInput>
    </div>
  )
}
