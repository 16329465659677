import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useState, useEffect } from 'react'

const getCommonButtonStyles = theme => ({
  margin: theme.spacing(1),
  marginTop: 0,
  marginLeft: 0,
  color: theme.palette.secondaryNavy2.main,
  fontWeight: 400,
  textTransform: 'capitalize',
  boxShadow: 'none',
})

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline',
  },
  selected: {
    ...getCommonButtonStyles(theme),
    backgroundColor: theme.palette.secondaryGreen1.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondaryNavy2.main,
      boxShadow: 'none',
    },
  },
  unselected: {
    ...getCommonButtonStyles(theme),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      border: `1px solid ${theme.palette.secondaryNavy2.main}`,
      backgroundColor: theme.palette.backgroundGreen.main,
      color: theme.palette.secondaryNavy2.main,
      boxShadow: 'none',
    },
  },
}))

const MultipleChoice = ({ options, value, onChange }) => {
  const deserializeValue = value => {
    if (value === '' || typeof value !== 'string') return []
    const deserialized = value.split(',').map(v => v.trim())
    return deserialized
  }

  const serializeValue = value => {
    const serialized = value.join(', ')
    return serialized
  }

  const classes = useStyles()
  const [selected, setSelected] = useState(deserializeValue(value))

  const checkIfSelected = option => {
    return selected.includes(option.display)
  }

  const selectOption = option => {
    setSelected([...selected, option.display])
  }

  const deselectOption = option => {
    setSelected(selected.filter(s => s !== option.display))
  }

  const renderOption = (option, index) => {
    if (checkIfSelected(option)) {
      return (
        <Button
          key={index}
          variant="contained"
          onClick={() => deselectOption(option)}
          className={classes.selected}
        >
          {option.display}{' '}
        </Button>
      )
    }
    return (
      <Button
        key={index}
        variant="outlined"
        onClick={() => selectOption(option)}
        className={classes.unselected}
      >
        {option.display}{' '}
      </Button>
    )
  }

  useEffect(() => {
    onChange(serializeValue(selected))
  }, [selected])

  return (
    <div className={classes.root}>
      {options.map((option, index) => {
        return renderOption(option, index)
      })}
    </div>
  )
}

export default MultipleChoice
