import clsx from 'clsx'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dateSelector: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e3e3e3',
      },
      '&:hover fieldset': {
        borderColor: '#daefed',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #36bca8',
      },
    },
    '& input': {
      fontSize: 16,
    },
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.accentRed.main,
      },
    },
  },
}))

export default function DatePicker({
  label,
  id,
  value,
  name,
  onChange,
  helperText,
  error,
  fullWidth = true,
  variant = 'inline',
  ...props
}) {
  const classes = useStyles()

  const handleDateChange = date => {
    onChange({
      target: { id, name, value: date },
    })
  }

  return (
    <div
      name={name}
      className={clsx(classes.dateSelector, {
        [classes.error]: error,
      })}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          fullWidth={fullWidth}
          disableToolbar
          autoOk
          variant={variant}
          maskChar="_"
          inputVariant="outlined"
          label={label}
          name={name}
          format="MM/dd/yyyy"
          value={value}
          onChange={handleDateChange}
          helperText={helperText}
          error={!!error}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
