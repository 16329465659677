import { Grid, Typography } from '@material-ui/core'
import BaseRow from 'components/Shared/Card/BaseRow'
import { useRxStyles } from 'hooks'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

const ArrayFieldListSectionLayout = ({
  fields,
  rows,
  renderCell,
  displayProperty,
  emptyPlaceholder = '',
}) => {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  return (
    <Grid container>
      {!rows?.length ? (
        <BaseRow emptyPlaceholder={emptyPlaceholder} />
      ) : (
        rows?.length &&
        rows.map((row, i) => {
          return (
            <BaseRow
              key={`${displayProperty}-${i}`}
              columns={
                <Grid
                  key={`${row._id}-${i}`}
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                  className={clsx(rxClasses.dividerTop)}
                >
                  <Grid item xs className={rxClasses.columnPadding}>
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(
                        rxClasses.typography,
                        rxClasses.fontSize20,
                        theme.palette.primary,
                      )}
                    >
                      {row[displayProperty]}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          )
        })
      )}
    </Grid>
  )
}

export default ArrayFieldListSectionLayout
