import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { updateExternalProvider } from 'redux/externalProviders'
import { membersFailed } from 'redux/members'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePutExternalProvider() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const putExternalProvider = useCallback(
    async externalProvider => {
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/externalProviders/${externalProvider._id}`,
        options: {
          method: HTTP_METHODS.PUT,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(externalProvider),
        },
      })

      if (response.ok) {
        const result = await response.json()
        dispatch(updateExternalProvider(result))
      } else {
        dispatch(membersFailed('Failed to update external provider'))
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return putExternalProvider
}
