import clsx from 'clsx'
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useMenuStyles = makeStyles(({ palette }) => ({
  accentRed: {
    color: palette.accentRed.main,
  },
  paper: {
    marginTop: '68px',
  },
  root: {
    minWidth: '32px',
  },
}))

const ContextMenu = ({ anchorEl, options, open, onClose }) => {
  const menuStyles = useMenuStyles()
  return (
    <Menu
      classes={{ paper: menuStyles.paper }}
      className="menu"
      keepMounted
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      {options.map((option, idx, optionsArr) => {
        const isLastMenuItem = optionsArr.length - 1 === idx
        const hasIcon = option?.icon ?? false

        return (
          <MenuItem onClick={option.onClick} key={`${idx}${option.value}`}>
            {hasIcon && (
              <ListItemIcon
                className={clsx({
                  [menuStyles.accentRed]: isLastMenuItem,
                })}
                classes={{ root: menuStyles.root }}
                fontSize="small"
              >
                {option.icon}
              </ListItemIcon>
            )}
            <ListItemText
              className={clsx({
                [menuStyles.accentRed]: isLastMenuItem,
              })}
              primary={option.label}
            />
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default ContextMenu
