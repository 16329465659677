import { useCallback } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import usePostMember from 'hooks/api/members/usePostMember'
import { serializeMember } from 'utils/memberHelpers'

import BaseHeader from '../Shared/BaseHeader'
import MemberAddForm from './MemberAddForm'

const MemberAdd = () => {
  const history = useHistory()
  const postMember = usePostMember()

  const handleSubmit = useCallback(
    async values => {
      const newMember = await postMember(serializeMember(values))

      if (newMember) {
        history.push(`/members/${newMember._id}`)
      }
    },
    [history, postMember],
  )

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <BaseHeader>
        <Box height={80} display="flex" alignItems="center">
          <IconButton component={Link} to={`/members`}>
            <ArrowBackIcon />
          </IconButton>

          <Box ml={1} fontSize="h3.fontSize">
            Add a new member
          </Box>
        </Box>
      </BaseHeader>

      <Box overflow="scroll" p={2}>
        <MemberAddForm
          successMessage="New member was successfully created"
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  )
}

export default MemberAdd
