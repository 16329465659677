import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function BaseAccordion({ title, children }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontWeight: 500, fontSize: 16 }}>
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Box flexGrow={1} display="flex" flexDirection="column">
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
