import keyBy from 'lodash/keyBy'

// Action Types
const SET_CURRENT_USER = 'users/SET_CURRENT_USER'
const SET_USERS = 'users/SET_USERS'

const initialState = {
  currentUser: {},
  byId: {},
  allIds: [],
}

// Reducer
const usersStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.payload }

    case SET_USERS:
      const users = action.payload

      return {
        ...state,
        byId: keyBy(users, '_id'),
        allIds: users.map(user => user._id),
      }

    default:
      return state
  }
}

// ActionCreators
export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: user,
})

export const setUsers = users => ({
  type: SET_USERS,
  payload: users,
})

export default usersStore
