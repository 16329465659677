import MultiFieldsSection from './MultiFieldsSection'

const SingleFieldSection = ({ field, ...props }) => (
  <MultiFieldsSection
    fields={[field]}
    contentProps={{ showTitle: true }}
    isSingleField
    {...props}
  />
)

export default SingleFieldSection
