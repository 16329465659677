import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useInterval } from 'hooks'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px 0px`,
    color: theme.palette.secondaryGreen1.main,
  },
  saved: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    height: '24px',
  },
  unsaved: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    height: '24px',
    color: theme.palette.accentRed.main,
  },
  icon: {
    display: 'flex',
    fontSize: '16px',
    height: '24px',
    alignItems: 'center',
  },
}))

const getDifferenceInMinutes = timeOfLastSave => {
  const now = Date.now()
  const difference = now - (timeOfLastSave ?? now)
  const minutes = Math.floor(difference / 1000 / 60)
  return minutes
}

const convertMinutesToString = minutes => {
  if (minutes < 1) {
    return 'just now'
  }
  if (minutes === 1) {
    return '1 minute ago'
  }
  if (minutes < 60) {
    return `${minutes} minutes ago`
  }
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${hours} hours ${remainingMinutes} minutes ago`
}

const AutoSaveDisplay = ({ timeOfLastSave }) => {
  const classes = useStyles()
  const [timeElapsed, setTimeElapsed] = useState(0)

  useInterval(() => {
    setTimeElapsed(getDifferenceInMinutes(timeOfLastSave))
  }, 10000)

  const Saved = ({ timeOfLastSave }) => {
    return (
      <div className={classes.saved}>
        <div className={classes.icon}>
          <CheckCircleOutlineIcon fontSize="inherit" />
        </div>
        <div>{` Saved ${convertMinutesToString(timeElapsed)}`}</div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {timeOfLastSave ? (
        <Saved timeOfLastSave={timeOfLastSave} />
      ) : (
        <div className={classes.unsaved}>Changes not saved</div>
      )}
    </div>
  )
}

export default AutoSaveDisplay
