import { isSameDay } from 'date-fns'

export function formRequest(body) {
  return Object.keys(body).reduce((formData, key) => {
    formData.append(key, body[key])
    return formData
  }, new FormData())
}

export function flattenObject(object) {
  return Object.values(object).flat()
}

export function sortDate(a, b) {
  return new Date(b.dateCreated) - new Date(a.dateCreated)
}

export function group(array, getKey = item => item.toString()) {
  return array.reduce((groups, item) => {
    const key = getKey(item)

    groups[key] = groups[key] ? [...groups[key], item] : [item]

    return groups
  }, {})
}

export function isOverDue(dueBy) {
  const today = new Date()
  const dueByDate = new Date(dueBy)
  return dueByDate < today && !isSameDay(dueByDate, today)
}

export function getParsedSessionStorageItem(name) {
  try {
    // It will throw an error when the JSON.parse fails so wrapping it up with null
    return JSON.parse(sessionStorage.getItem(name))
  } catch (error) {
    return null
  }
}

export function setJsonSessionStorageItem(name, json) {
  sessionStorage.setItem(name, JSON.stringify(json))
}

export const diffObjects = (object, source) =>
  Object.keys(source).reduce((acc, key) => {
    if (object[key] !== source[key]) {
      acc[key] = source[key]
    }

    return acc
  }, {})

export const includesIgnoreCase = (str, substr) => {
  const regex = new RegExp(substr, 'i')
  return regex.test(str)
}

export function removeNull(obj) {
  const tempObj = Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, v]) => {
      return v !== null
    }),
  )

  return tempObj
}

export function triggerDownload(blob, fileName) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `${fileName}.pdf`
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}
