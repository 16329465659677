import { useState } from 'react'
import { useSelector } from 'react-redux'
import useFileUpload from 'hooks/api/files/useFileUpload'
import { useSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextInput from 'ui/TextInput'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import * as yup from 'yup'

import { DOCUMENT_TYPES } from 'constants/member'
import TextButton from 'components/Shared/TextButton'

const useStyles = makeStyles(theme => ({
  container: {
    width: 480,
    height: 150,
  },

  documentTypeSelect: {
    width: '100%',
  },

  selectBox: {
    color: 'black',
  },

  fileTitle: {
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'black',
    },
  },

  uploadedFileNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  defaultColor: {
    color: '#278C79',
  },

  dialogActions: {
    padding: theme.spacing(2, 3),
  },
}))

export default function UploadNewDocumentDialog({ member, onClose }) {
  const classes = useStyles()
  const [file, setFile] = useState(null)
  const [fileTitle, setFileTitle] = useState('')
  const [documentType, setDocumentType] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const uploadMemberFile = useFileUpload()

  const uploading = useSelector(({ files }) => files.uploading)

  const handleChangeDocumentType = e => {
    setDocumentType(e.target.value)
  }

  const handleChangeFileTitle = e => {
    setFileTitle(e.target.value)
  }

  const handleChangeFile = e => {
    const files = e.target.files
    if (!files || files.length === 0) return
    const file = files[0]
    setFile(file)
  }

  const handleUpload = () => {
    yup
      .object()
      .shape({
        file: yup.mixed().required('File is required.'),
        documentType: yup.string().required('Document Type is required.'),
      })
      .validate({ file, documentType })
      .then(() => {
        Promise.resolve(
          uploadMemberFile({
            file,
            fileTitle,
            documentType,
            memberID: member._id,
            onSuccess: onClose,
          }),
        )
      })
      .catch(errors => {
        enqueueSnackbar(errors.message, { variant: 'error' })
      })
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Upload new document</DialogTitle>

      <DialogContent>
        {uploading && <LinearProgress />}
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Select
              variant="outlined"
              name="documentType"
              value={documentType}
              displayEmpty
              className={classes.documentTypeSelect}
              classes={{ selectMenu: classes.selectBox }}
              onChange={handleChangeDocumentType}
            >
              <MenuItem value="" disabled>
                Type
              </MenuItem>
              {DOCUMENT_TYPES.map(type => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              placeholder="Title of the file(Optional)"
              value={fileTitle}
              onChange={handleChangeFileTitle}
              className={classes.fileTitle}
            />
          </Grid>

          <Grid item xs={12}>
            <TextButton width="100%" component="label">
              Attach File
              <input
                name="json"
                id="json"
                accept="*"
                type="file"
                style={{ display: 'none' }}
                multiple={false}
                onChange={handleChangeFile}
              />
            </TextButton>
          </Grid>
          {file && (
            <Grid item xs={12} className={classes.uploadedFileNameContainer}>
              <CheckIcon className={classes.defaultColor} />
              {file.name}
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <TextButton disabled={uploading} onClick={onClose}>
          Cancel
        </TextButton>
        <TextButton disabled={uploading} onClick={handleUpload}>
          Upload
        </TextButton>
      </DialogActions>
    </Dialog>
  )
}
