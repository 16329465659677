import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    zIndex: 1300,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: `rgba(0, 0, 0, 0.6)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function LoadingModal() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress size={80} />
    </Box>
  );
}
