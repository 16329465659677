import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { notesLoading, addNotes, stopLoadingNotes } from 'redux/notes'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePostNoteAddendum() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const postNoteAddendum = useCallback(
    async addendum => {
      dispatch(notesLoading())
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/addendum`,
        options: {
          method: HTTP_METHODS.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(addendum),
        },
      })

      if (response.ok) {
        const result = await response.json()
        dispatch(addNotes(result))
      } else {
        throw new Error('Failed to post addendum')
      }
      dispatch(stopLoadingNotes())
    },
    [dispatch, fetchWithRefresh],
  )

  return postNoteAddendum
}
