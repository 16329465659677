import clsx from 'clsx'
import { isNull } from 'lodash'
import { useState } from 'react'
import { useRxStyles } from 'hooks'
import { allowedFileType } from 'utils/insurance'
import { FILE_TYPES, MAX_FILE_SIZE } from 'constants/insurance'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import Grid from '@material-ui/core/Grid'
import TextButton from 'components/Shared/TextButton'
import { makeStyles } from '@material-ui/core/styles'
import { useLayoutStyles } from 'hooks'

const useStyles = makeStyles(({ palette }) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '12px',
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
  },
  uploadedFileNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  defaultColor: {
    color: '#278C79',
  },
  notAllowed: {
    color: palette.accentRed.main,
  },
}))

export default function UploadInsuranceCard({ side, onFile }) {
  const classes = useStyles()
  const rxClasses = useRxStyles()
  const layoutClasses = useLayoutStyles()

  const [file, setFile] = useState(undefined)
  const [error, setError] = useState(null)

  const handleChangeFile = e => {
    const files = e.target.files
    if (!files || files.length === 0) return
    const file = files[0]
    const isAllowedFileType = allowedFileType(file)
    const isAllowedFileSize = file.size <= MAX_FILE_SIZE
    if (isAllowedFileType && isAllowedFileSize) {
      setFile(file)
      onFile(file)
    } else {
      setError({
        status: true,
        type: {
          isAllowedFileType,
          message: `File type must be ${FILE_TYPES.toString()}`,
        },
        size: { isAllowedFileSize, message: `File size too large (max: 3MB)` },
      })
      setFile(null)
    }
  }

  return (
    <div className={layoutClasses.mb3}>
      <TextButton
        width="100%"
        component="label"
        className={clsx(
          rxClasses.fontSize16,
          rxClasses.typography,
          layoutClasses.mb1,
        )}
      >
        UPLOAD IMAGE
        <input
          name={`${side}-ofCard`}
          id={`${side}-ofCard`}
          type="file"
          style={{ display: 'none' }}
          multiple={false}
          onChange={handleChangeFile}
          accept={`${FILE_TYPES.toString()}`}
        />
      </TextButton>
      {file && (
        <Grid item xs={12} className={classes.uploadedFileNameContainer}>
          <CheckIcon
            className={clsx(classes.defaultColor, layoutClasses.pr1)}
          />
          {file.name}
        </Grid>
      )}
      {isNull(file) && error.status && (
        <Grid item xs={12} className={classes.uploadedFileNameContainer}>
          <ClearIcon className={clsx(classes.notAllowed, layoutClasses.pr1)} />
          <span
            className={clsx({
              [rxClasses.notAllowed]: error.status,
            })}
          >
            {error.isAllowedFileSize ? `${error.message}` : ''}
            {error.isAllowedFileType ? `${error.message}` : ''}
          </span>
        </Grid>
      )}
    </div>
  )
}
