import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import TextButton from 'components/Shared/TextButton'

const useStyles = makeStyles(theme => ({
  container: {
    width: 480,
    height: 40,
  },

  deleteConfirm: {
    fontSize: 16,
    lineHeight: 1.5,
    color: '#7C989B',
  },

  warningButton: {
    color: '#EB5757 !important',
  },

  dialogActions: {
    padding: theme.spacing(2, 3),
  },
}))

export default function DeleteConfirmationDialog({
  fileName,
  onClose,
  onDelete,
}) {
  const classes = useStyles()

  return (
    <Dialog open={true}>
      <DialogTitle>Delete confirmation</DialogTitle>

      <DialogContent>
        <Grid container item xs={12} className={classes.container}>
          <Typography className={classes.deleteConfirm}>
            Are you sure you want to delete the file "{fileName}"?
          </Typography>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <TextButton onClick={onClose}>Cancel</TextButton>
        <TextButton className={classes.warningButton} onClick={onDelete}>
          Delete
        </TextButton>
      </DialogActions>
    </Dialog>
  )
}
