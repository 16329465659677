import { makeStyles } from '@material-ui/core/styles'

import { formatExternalProviderInitials } from 'utils/formatters'

import BaseProfile from './BaseProfile'

const useStyles = makeStyles(({ palette }) => ({
  avatar: {
    background: palette.accentYellow.main,
    color: palette.text.primary,
  },
}))

const ExternalProviderProfile = ({ externalProvider }) => {
  const classes = useStyles()

  return (
    <BaseProfile
      initials={formatExternalProviderInitials(externalProvider.name)}
      title={externalProvider.type}
      name={externalProvider.name}
      avatarClassName={classes.avatar}
    />
  )
}

export default ExternalProviderProfile
