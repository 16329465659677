import clsx from 'clsx'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  stepDetail: {
    cursor: 'pointer',
  },
  scoreBox: {
    display: 'flex',
    gap: spacing(1),
    marginTop: spacing(1),
    flexWrap: 'wrap',
  },
  scoreItem: {
    border: '1px solid #7C989B',
    borderRadius: 4,
    color: '#7C989B',
    background: 'white',
    padding: spacing(1, 1.5),
  },
  disabledScoreItem: {
    background: '#E3E3E3',
    border: '1px solid #7C989B',
  },
  selectedScoreItem: {
    background: '#6D9147',
    border: '1px solid #6D9147',
    color: 'white',
  },
}))

const AssessmentQuestion = ({
  title,
  disabled,
  score,
  possibleScores,
  onScore,
}) => {
  const classes = useStyles()

  const handleClick = index => {
    if (!disabled) {
      onScore(index)
    }
  }

  return (
    <div className={classes.stepDetail}>
      <Typography variant="subtitle2">{title}</Typography>
      <Box className={classes.scoreBox}>
        {possibleScores?.map((item, index) => (
          <Box
            key={item.label}
            className={clsx(
              classes.scoreItem,
              disabled && classes.disabledScoreItem,
              !disabled && score === index && classes.selectedScoreItem,
            )}
            onClick={() => handleClick(index)}
          >
            {item.score !== undefined
              ? `${item.label} (${item.score})`
              : item.label}
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default AssessmentQuestion
