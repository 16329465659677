import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useRxStyles } from 'hooks'

function CardSectionContent({
  title,
  children,
  onEdit,
  isList = false,
  renderCardMenu,
  actionText = 'Edit',
  boldAction = false,
  smallHeader = false,
}) {
  const rxStyles = useRxStyles()
  const renderTitle = () => (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Typography
        style={{
          alignSelf: 'center',
          fontWeight: 500,
          fontSize: smallHeader ? 14 : 16,
        }}
      >
        {title}
      </Typography>
      {onEdit &&
        (renderCardMenu ? (
          renderCardMenu({ onEdit })
        ) : (
          <Link onClick={onEdit}>
            {boldAction ? (
              <Typography className={rxStyles.buttonAction} variant="caption">
                {actionText}
              </Typography>
            ) : (
              <Typography variant="caption">{actionText}</Typography>
            )}
          </Link>
        ))}
    </Box>
  )

  return (
    <Card variant="outlined">
      <CardHeader title={renderTitle()} />
      <Divider light />
      {!isList ? <CardContent>{children}</CardContent> : children}
    </Card>
  )
}

export default CardSectionContent
