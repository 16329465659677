import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'

import usePutMember from 'hooks/api/members/usePutMember'
import { formatPhoneNumber } from 'utils/formatters'
import { useRxStyles, useMemberRx, useLayoutStyles } from 'hooks'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'

import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import TextButton from 'components/Shared/TextButton'
import BaseDialog from 'components/Shared/BaseDialog'
import BaseCard from 'components/Shared/Card/BaseCard'

export default function ResultsForm({ open, handleClose, results }) {
  const { memberId, userId, setUpdated } = useMemberRx()
  const putMember = usePutMember()
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles()
  const rxClasses = useRxStyles(theme)

  const [limitedResults, setResults] = useState([])
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(5)
  const step = 5

  function incrument(num, incrument) {
    return num + incrument
  }

  function getResults(arr, start, end) {
    const slicedArr = arr.slice(start, end)
    return slicedArr
  }

  const loadMore = () => {
    setResults(getResults(results, start, end))
    setStart(end)
    setEnd(incrument(end, step))
  }

  useEffect(() => {
    if (start === 0) {
      setResults(getResults(results, start, end))
      setStart(end)
      setEnd(end + step)
    }
  }, [results, start, end])

  async function addPharmacy({ userID, memberID, pharmacyID }) {
    try {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/pharmacies/patient/add/${userID}&${memberID}`,
        body: { pharmacyID },
      })

      await putMember({
        _id: memberID,
        hasPharmacy: true,
      })

      setUpdated(true)
      enqueueSnackbar('Added pharmacy succesfully', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Failed to add pharmacy', { variant: 'error' })
    }
  }

  async function setPreferred({ userID, memberID, pharmacyID }) {
    try {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/pharmacies/patient/preferred/${userID}&${memberID}`,
        body: { pharmacyID },
      })

      await putMember({
        _id: memberId,
        hasPharmacy: true,
      })
      setUpdated(true)
      enqueueSnackbar('Preferred pharmacy set', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Failed to set preferred pharmacy', { variant: 'error' })
    }
  }

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Add Pharmacy"
      dialogContent={
        limitedResults &&
        limitedResults.map((result, i) => {
          return (
            <div
              key={`${result.PharmacyId}-${i}`}
              className={rxClasses.rowSpacer}
            >
              <BaseCard>
                <Grid item xs={8} className={layoutClasses.padding2}>
                  <Typography
                    className={clsx(
                      rxClasses.fontSize14,
                      rxClasses.typography,
                      theme.typography.fontWeightBold,
                      theme.palette.primaryNavy,
                    )}
                  >
                    {result.StoreName}
                  </Typography>
                  <Typography
                    className={clsx(rxClasses.fontSize12, rxClasses.typography)}
                  >
                    {result.Address1}
                    {result.Address2}, {result.City} {result.State},{' '}
                    {result.ZipCode}
                  </Typography>
                  <Typography
                    className={clsx(rxClasses.fontSize12, rxClasses.typography)}
                  >
                    {formatPhoneNumber(result.PrimaryPhone)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={clsx(
                    layoutClasses.padding2,
                    rxClasses.textAlignRight,
                  )}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      rxClasses.typography,
                      rxClasses.fontSize16,
                      rxClasses.buttonAction,
                      theme.typography.fontWeightRegular,
                    )}
                    onClick={() => {
                      addPharmacy({
                        userID: userId,
                        memberID: memberId,
                        pharmacyID: result.PharmacyId,
                      })
                    }}
                  >
                    ADD
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      rxClasses.typography,
                      rxClasses.fontSize16,
                      rxClasses.buttonAction,
                      theme.typography.fontWeightRegular,
                    )}
                    onClick={() => {
                      setPreferred({
                        userID: userId,
                        memberID: memberId,
                        pharmacyID: result.PharmacyId,
                      })
                    }}
                  >
                    SET PREFERRED
                  </Typography>
                </Grid>
              </BaseCard>
            </div>
          )
        })
      }
      dialogActions={
        end <= results.length && (
          <TextButton
            className={clsx(
              theme.typography.fontWeightBold,
              rxClasses.typography,
              rxClasses.center,
            )}
            onClick={loadMore}
          >
            LOAD 5 MORE
          </TextButton>
        )
      }
    />
  )
}
