import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import get from 'lodash/get'

import BaseSection from './BaseSection'
import SectionField from './SectionField'
import MultiFieldsSectionForm from './SectionForms/MultiFieldsSectionForm'

const MultiFieldsSection = ({ fields, isSingleField, ...props }) => {
  const { values } = useFormikContext()

  const renderValue = useCallback(
    field => {
      const value = get(values, field.path)

      if (field.valueFormatter) {
        return field.valueFormatter(value)
      }

      return value
    },
    [values],
  )

  const renderForm = useCallback(
    () => <MultiFieldsSectionForm fields={fields} />,
    [fields],
  )

  return (
    <BaseSection renderForm={renderForm} {...props}>
      {fields.map(field => (
        <SectionField
          key={field.path}
          label={field.label}
          isSingleField={isSingleField}
        >
          {renderValue(field)}
        </SectionField>
      ))}
    </BaseSection>
  )
}

export default MultiFieldsSection
