import { formatDistance } from 'date-fns'
import { useMemo } from 'react'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import AvatarChip from 'components/Shared/Chips/AvatarChip'
import { formatFullName } from 'utils/formatters'
import { parseComment } from 'utils/commentHelpers'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 3),
    '&:not(:nth-last-child(2))': {
      borderBottom: `1px solid ${theme.palette.distinctiveGray.main}`,
    },
  },
  author: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  comment: {
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 8,
    fontStyle: 'italic',
  },
  unreadChip: {
    color: '#BA940F',
    background: theme.palette.backgroundYellow2.main,
    marginLeft: theme.spacing(1),
  },
  timestamp: {
    alignSelf: 'center',
    marginLeft: 'auto',
    color: theme.palette.secondaryNavy2.main,
  },
}))

export default function CommentNotification({ comment, author, member, user }) {
  const classes = useStyles()

  const isRead = useMemo(() => {
    return comment.mentions.some(
      mention => mention.readAt /*&& mention.user === user?._id*/,
    )
  }, [comment])

  const date = comment?.createdAt ? new Date(comment.createdAt) : null
  return (
    <div className={classes.container}>
      <div className={classes.author}>
        {Boolean(author) && <AvatarChip user={author} />}
        {!isRead && <Chip className={classes.unreadChip} label="Unread" />}
        <Typography variant="body2" className={classes.timestamp}>
          {date
            ? formatDistance(date, new Date(), {
                addSuffix: true,
              })
            : 'Date not available'}
        </Typography>
      </div>
      <span
        className={classes.comment}
        dangerouslySetInnerHTML={{
          __html: `In <span style="color: #278c79;">${formatFullName(
            member,
          )}</span>'s profile - "${parseComment(
            comment.comment,
            'label',
            false,
          )}"`,
        }}
      />
    </div>
  )
}
