// ActionTypes
const SET_PGXS = 'pgxs/SET_PGXS'
const PGXS_FAILED = 'pgxs/PGXS_FAILED'
const PGXS_LOADING = 'pgxs/PGXS_LOADING'

const initialState = {
  isLoading: true,
  error: null,
  allPgxs: [],
}

// Reducer
const pgxsStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_PGXS:
      const pgxs = action.payload
      return {
        ...state,
        isLoading: false,
        error: null,
        allPgxs: pgxs,
      }
    case PGXS_LOADING:
      return { ...state, isLoading: true }
    case PGXS_FAILED:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}

// ActionCreators
export const setPgxs = pgxs => ({
  type: SET_PGXS,
  payload: pgxs,
})

export const pgxsLoading = () => ({
  type: PGXS_LOADING,
})

export const pgxsFailed = error => ({
  type: PGXS_FAILED,
  payload: error,
})

export default pgxsStore
