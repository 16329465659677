import clsx from 'clsx'
import { useState } from 'react'
import { useLayoutStyles, useRxStyles, useMemberRx, useFormFields } from 'hooks'
import { useSnackbar } from 'notistack'

import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

import TextInput from 'ui/TextInput'
import TextButton from 'components/Shared/TextButton'
import DatePicker from 'components/Shared/Forms/Fields/DatePicker'
import BaseDialog from 'components/Shared/BaseDialog'

import { useTheme } from '@material-ui/core/styles'

const options = [
  { rId: 1, label: 'Allergy', value: 1 },
  { rId: 2, label: 'Adverse Reaction', value: 2 },
]

export default function SelectedForm({
  open,
  handleClose,
  selected,
  onDeSelect,
}) {
  const { userId, memberId, setUpdated } = useMemberRx()
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles()
  const rxClasses = useRxStyles(theme)
  const [loading, setIsLoading] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    reaction: '',
    reactionType: '',
    onsetDate: new Date(),
  })

  const deSelect = selected => {
    onDeSelect(selected)
  }

  const onDateChange = event => {
    handleFieldChange(event)
  }

  const addAllergy = async ({ params: { userId, memberId }, body }) => {
    try {
      setIsLoading(true)
      await postRequest({
        url: `${API_BASE_URL}/dosespot/drug-allergies/patient/add/${userId}&${memberId}`,
        body,
      })
      enqueueSnackbar('Added allergy succesfully', { variant: 'success' })
    } catch (error) {
      setIsLoading(false)
      enqueueSnackbar('Failed to add allergy', { variant: 'error' })
    } finally {
      setUpdated(true)
      setIsLoading(false)
    }
  }

  return (
    <BaseDialog
      loading={loading}
      open={open}
      onClose={handleClose}
      title="Add Drug Allergy"
      dialogContent={
        <div
          key={`${selected.Code}-${selected.RxCUI}`}
          className={rxClasses.rowSpacer}
        >
          <Card variant="outlined">
            <Grid
              container
              columns={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={8} className={layoutClasses.padding2}>
                <Typography
                  className={clsx(
                    rxClasses.fontSize14,
                    rxClasses.typography,
                    theme.typography.fontWeightBold,
                    theme.palette.primaryNavy,
                  )}
                >
                  {selected.Name}
                </Typography>
                <Typography
                  className={clsx(rxClasses.fontSize12, rxClasses.typography)}
                >
                  Code: {selected.Code}, Type: {selected.semanticType}, RxCui:
                  {selected.RxCUI}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                className={clsx(
                  layoutClasses.padding2,
                  rxClasses.textAlignRight,
                )}
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.typography,
                    rxClasses.fontSize16,
                    rxClasses.buttonAction,
                    rxClasses.actionSpacerRight,
                    theme.typography.fontWeightRegular,
                  )}
                  onClick={() => {
                    deSelect(selected)
                  }}
                >
                  DESELECT
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Grid item xs={12}>
            <TextInput
              id="reaction"
              fullWidth
              variant="outlined"
              label="Reaction"
              name="reaction"
              value={fields.reaction}
              onChange={handleFieldChange}
            />
            <TextInput
              id="reactionType"
              variant="outlined"
              fullWidth
              select
              name="reactionType"
              label="Type of reaction"
              value={fields.reactionType}
              onChange={event => {
                handleFieldChange({
                  target: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                })
              }}
              helperText="Please select the type of reaction"
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextInput>
            <DatePicker
              id="onsetDate"
              label="Onset date"
              name="onsetDate"
              value={fields.onsetDate}
              onChange={onDateChange}
            />
          </Grid>
        </div>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={handleClose}
          >
            CANCEL
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              addAllergy({
                params: { userId, memberId },
                body: {
                  name: selected.Name,
                  code: selected.Code,
                  codeType: selected.CodeType,
                  reaction: fields.reaction,
                  reactionType: fields.reactionType,
                  onsetDate: fields.onsetDate,
                },
              })
            }}
          >
            ADD
          </TextButton>
        </>
      }
    />
  )
}
