import clsx from 'clsx'
import { isEmpty, orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormFields, useRxStyles, useLayoutStyles } from 'hooks'
import { capitalizeEveryWord } from 'utils/formatters'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Chip from '@material-ui/core/Chip'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import TextButton from 'components/Shared/TextButton'
import BaseDialog from 'components/Shared/BaseDialog'
import TextInput from 'ui/TextInput'

const useStyles = makeStyles(({ palette }) => ({
  listbox: {
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      //link: https://github.com/mui/material-ui/issues/19692
      backgroundColor: palette.backgroundMint.main,
      color: palette.primaryNavy,
    },
    '& .MuiAutocomplete-option': {
      marginLeft: '-5px',
      padding: '12px',
    },
  },
  groupLabel: {
    color: `${palette.secondaryNavy2.main}!important`,
    fontSize: '14px',
    padding: '12px',
    letterSpacing: '0.15px',
    lineHeight: '21px',
  },
}))

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function EditDialog({
  onClose,
  open,
  onUpdate,
  selectedCodes,
  isNoShow,
}) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const rxClasses = useRxStyles(theme)
  const layoutClasses = useLayoutStyles(theme)
  const [fields, handleFieldChange] = useFormFields({
    procedures: [],
    noShow: isNoShow,
  })
  const [loading, setLoading] = useState(true)

  const { cptCodes } = useSelector(state => ({
    cptCodes: orderBy(state.notes.cptCodes, 'category'),
  }))

  useEffect(() => {
    setLoading(true)
    if (isEmpty(fields.procedures) && !isEmpty(selectedCodes)) {
      handleFieldChange({
        target: {
          name: 'procedures',
          value: selectedCodes,
        },
      })
    }
    setLoading(false)
    // eslint-disable-next-line
  }, [loading])

  return (
    <BaseDialog
      maxWidth="lg"
      title="Select Procedure"
      open={open}
      onClose={onClose}
      dialogContent={
        <>
          <Autocomplete
            loading={loading}
            multiple
            name="procedures"
            id="procedures"
            className={clsx({
              [layoutClasses.mb3]: true,
            })}
            classes={{
              groupLabel: classes.groupLabel,
              listbox: classes.listbox,
            }}
            options={cptCodes}
            disableCloseOnSelect
            //filterOptions={filterOptions}
            value={fields.procedures}
            groupBy={option => capitalizeEveryWord(option.category)}
            onChange={(old, newValue) => {
              handleFieldChange({
                target: {
                  name: 'procedures',
                  value: newValue,
                },
              })
            }}
            getOptionLabel={option => `${option.code} ${option.display}`}
            renderOption={(option, { selected }) => {
              return (
                <div>
                  <Checkbox
                    color="primary"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  <span>
                    {option.code} {option.display}
                  </span>
                </div>
              )
            }}
            renderTags={options => {
              return options.map((option, idx) => {
                return (
                  <Chip
                    key={idx}
                    label={`${option.code} ${option.display}`}
                    style={{
                      backgroundColor: '#f2f2f2',
                      color: '#183f4f',
                      borderColor: '#f2f2f2',
                    }}
                  />
                )
              })
            }}
            disabled={fields.noShow}
            renderInput={params => (
              <TextInput {...params} variant="outlined" label="CPT Codes" />
            )}
          />
        </>
      }
      dialogActions={
        <TextButton
          className={clsx(
            theme.typography.fontWeightMedium,
            rxClasses.fontSize16,
            rxClasses.typography,
          )}
          onClick={() => {
            onUpdate(fields)
          }}
        >
          Update
        </TextButton>
      }
    ></BaseDialog>
  )
}
