import withMemberForm from 'HOCs/withMemberForm'

import MultiValueSection from 'components/Shared/Sections/MultiValueSection'

const FIELDSLIST = [
  {
    label: 'Harm to Others',
    fields: [
      {
        path: 'dangerToOthers.danger',
        label: 'Has risk of harm to others',
        type: 'check',
        cols: 4,
        valueFormatter: value => (value ? 'Risk' : 'No Risk'),
        componentProps: {
          checkProps: {
            color: 'primary',
          },
        },
      },
      {
        path: 'dangerToOthers.description',
        label: 'Description',
        cols: 8,
        componentProps: {
          multiline: true,
        },
      },
    ],
  },
  {
    label: 'Harm to Self',
    fields: [
      {
        path: 'dangerToSelf.danger',
        label: 'Has risk of harm to self',
        type: 'check',
        cols: 4,
        valueFormatter: value => (value ? 'Risk' : 'No Risk'),
        componentProps: {
          checkProps: {
            color: 'primary',
          },
        },
      },
      {
        path: 'dangerToSelf.description',
        label: 'Description',
        cols: 8,
        componentProps: {
          multiline: true,
        },
      },
    ],
  },
  {
    label: 'Firearm Access',
    fields: [
      {
        path: 'weapon.hasAccess',
        label: 'Has access to firearm',
        type: 'check',
        cols: 4,
        valueFormatter: value => (value ? 'Access' : 'No Access'),
        componentProps: {
          checkProps: {
            color: 'primary',
          },
        },
      },
      {
        path: 'weapon.description',
        label: 'Description',
        cols: 8,
        componentProps: {
          multiline: true,
        },
      },
    ],
  },
]

const RiskCard = props => (
  <MultiValueSection
    isCard
    title="Risk"
    fieldsList={FIELDSLIST}
    actionText="Update"
    {...props}
  />
)

export default withMemberForm('RiskCardForm', [
  'dangerToOthers',
  'dangerToSelf',
  'weapon',
])(RiskCard)
