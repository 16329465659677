import keyBy from 'lodash/keyBy'

// ActionTypes
const CREATE_PROGRESS = 'progresses/CREATE_PROGRESS'
const SET_PROGRESSES = 'progresses/SET_PROGRESSES'
const ADD_PROGRESSES = 'progresses/ADD_PROGRESSES'
const UPDATE_PROGRESS = 'progresses/UPDATE_PROGRESS'
const PROGRESSES_FAILED = 'progresses/PROGRESSES_FAILED'
const PROGRESSES_LOADING = 'progresses/PROGRESSES_LOADING'

const initialState = {
  isLoading: true,
  error: null,
  progressesByID: {},
}

// Reducer
const progressesStore = (state = initialState, action) => {
  const progressesByID = state.progressesByID

  switch (action.type) {
    case SET_PROGRESSES:
      const progresses = action.payload
      return {
        ...state,
        isLoading: false,
        error: null,
        progressesByID: keyBy(progresses, '_id'),
      }
    case ADD_PROGRESSES:
      for (const id in action.payload) {
        progressesByID[id] = action.payload[id]
      }
      return {
        ...state,
        isLoading: false,
        error: null,
        progressesByID,
      }
    case UPDATE_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        progressesByID: {
          ...progressesByID,
          [action.payload._id]: action.payload,
        },
      }
    case CREATE_PROGRESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        progressesByID: {
          ...progressesByID,
          [action.payload._id]: action.payload,
        },
      }
    case PROGRESSES_LOADING:
      return { ...state, isLoading: true }
    case PROGRESSES_FAILED:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}

// ActionCreators
export const setProgresses = progresses => ({
  type: SET_PROGRESSES,
  payload: progresses,
})

export const createProgress = progress => ({
  type: CREATE_PROGRESS,
  payload: progress,
})

export const addProgresses = progresses => ({
  type: ADD_PROGRESSES,
  payload: progresses,
})

export const updateProgress = progress => ({
  type: UPDATE_PROGRESS,
  payload: progress,
})

export const progressesLoading = () => ({
  type: PROGRESSES_LOADING,
})

export const progressesFailed = error => ({
  type: PROGRESSES_FAILED,
  payload: error,
})

export default progressesStore
