import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import BaseCard from 'components/Shared/Card/BaseCard'
import TextButton from 'components/Shared/TextButton'

import { ASSESSMENT_TYPES, ASSESSMENT_TYPE_DETAILS } from 'constants/progress'

import AssessmentForm from './AssessmentForm'

const AssessmentList = ({ types, onCancel, onSave }) => {
  const [scores, setScores] = useState({})
  const [defaultDisabled, setDefaultDisabled] = useState({})
  const [answersDisabled, setAnswersDisabled] = useState({})

  useEffect(() => {
    const initialScores = types.reduce((scores, type) => {
      const assessmentDetail = ASSESSMENT_TYPE_DETAILS[type]
      const initialScores = assessmentDetail.scoreGroups.map(group =>
        group.fields.map(_ => -1),
      )
      scores[type] = initialScores
      return scores
    }, {})

    setScores(initialScores)
  }, [types])

  useEffect(() => {
    const initialDisabled = types.reduce((answersDisabled, type) => {
      const assessmentDetail = ASSESSMENT_TYPE_DETAILS[type]
      const initialDisabled = assessmentDetail.scoreGroups.map(group =>
        group.fields.map(_ => false),
      )
      answersDisabled[type] = initialDisabled
      return answersDisabled
    }, {})

    setAnswersDisabled(initialDisabled)
    setDefaultDisabled(_.cloneDeep(initialDisabled))
  }, [types])

  const saveDisabled = useMemo(() => {
    return types.some(type => {
      const assessmentDetail = ASSESSMENT_TYPE_DETAILS[type]
      const assessmentScores = scores[type]
      if (!assessmentScores) return true
      return assessmentDetail.scoreGroups.some((group, groupIndex) =>
        group.fields.some(
          (field, fieldIndex) =>
            assessmentScores[groupIndex][fieldIndex] < 0 && field.required,
        ),
      )
    })
  }, [scores, types])

  const handleUpdateScores = (type, newScores) => {
    setScores({ ...scores, [type]: newScores })

    const newDisabled = _.cloneDeep(defaultDisabled[type])

    switch (type) {
      case ASSESSMENT_TYPES.CIDI3:
        if (newScores[0][0] === 0 && newScores[0][1] === 0) {
          newDisabled[0][2] = true
          newDisabled[1].fill(true)
        } else if (newScores[0][2] === 0) {
          newDisabled[1].fill(true)
        } else if (newScores[0][0] === 0) {
          newDisabled[1][0] = true
        }
        break
      case ASSESSMENT_TYPES.AUDIT:
        if (newScores[0][0] === 0) {
          for (let i = 1; i < 8; i++) {
            newDisabled[0][i] = true
          }
        } else if (newScores[0][1] === 0 && newScores[0][2] === 0) {
          for (let i = 3; i < 8; i++) {
            newDisabled[0][i] = true
          }
        }
        break
      default:
        break
    }

    setAnswersDisabled({ ...answersDisabled, [type]: newDisabled })
  }

  const handleClickSave = () => {
    const finalScores = types.reduce(
      (finalScores, type) => {
        const assessmentDetail = ASSESSMENT_TYPE_DETAILS[type]
        const asesssmentScores = scores[type]
        const asessmentDisabled = answersDisabled[type]

        const newScores = assessmentDetail.scoreGroups
          .map((group, groupIndex) =>
            group.fields.map((field, fieldIndex) =>
              !asessmentDisabled[groupIndex][fieldIndex]
                ? field.possibleScores[asesssmentScores[groupIndex][fieldIndex]]
                : { score: 0 },
            ),
          )
          .flat()

        if (assessmentDetail.labelField) {
          finalScores[assessmentDetail.labelField] = newScores.map(
            score => score?.label,
          )
        }

        finalScores[assessmentDetail.field] = newScores.map(
          score => score?.score ?? 0,
        )

        return finalScores
      },
      { type: types },
    )

    onSave(finalScores)
  }

  return (
    <div className="member-progress-container">
      {types.map(type => (
        <AssessmentForm
          key={type}
          type={type}
          scores={scores[type] || []}
          answersDisabled={answersDisabled[type] || []}
          onUpdate={scores => handleUpdateScores(type, scores)}
        />
      ))}

      <BaseCard className="member-progress-actions-box">
        <TextButton color="secondary" onClick={onCancel}>
          Cancel
        </TextButton>
        <TextButton disabled={saveDisabled} onClick={handleClickSave}>
          Save
        </TextButton>
      </BaseCard>
    </div>
  )
}

export default AssessmentList
