import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { format } from 'date-fns'

import { ASSESSMENT_TYPE_DETAILS } from 'constants/progress'
import { useLayoutStyles } from 'hooks'
import { getProgressDetails } from 'utils/progress'
import ProgressChip from './ProgressChip'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${palette.distinctiveGray.main}`,
    borderRadius: '8px',
    padding: spacing(2),
    marginBottom: spacing(1),
    backgroundColor: 'white',
  },
  content: {
    flex: 2,
    color: palette.text.primary,
    marginRight: spacing(2),
  },
  date: {
    flex: 1,
    color: palette.text.primary,
  },
  button: {
    color: palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
  progressBox: {
    display: 'flex',
    flex: 2,
  },

  descriptionBox: {
    display: 'flex',
    flex: 2,
    gap: spacing(1),
  },
}))

const ProgressItem = ({ progress }) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const history = useHistory()

  const chips = useMemo(() => {
    return progress.type.map(type => {
      const score = progress[ASSESSMENT_TYPE_DETAILS[type].field].reduce(
        (sum, each) => sum + each,
        0,
      )
      const progressDetail = getProgressDetails(type, score)

      return {
        type,
        content: `${ASSESSMENT_TYPE_DETAILS[type].label}: ${score}`,
        ...progressDetail,
      }
    })
  }, [progress])

  const remarks = progress.comment
  const sideEffects = (progress.sideEffects ?? []).join(', ')

  const handleClickReview = () => {
    history.push(
      `/members/${progress.memberID}/Progress?progressId=${progress._id}`,
    )
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.date} variant="subtitle2">
        {format(new Date(progress.timeStamp), 'MMMM dd, yyyy')}
      </Typography>

      <Typography
        className={clsx(classes.content, layoutClasses.truncate)}
        variant="subtitle2"
      >
        {progress.fullName}
      </Typography>

      <div className={classes.progressBox}>
        {chips.map(chip => (
          <ProgressChip
            key={chip.type}
            color={chip.color}
            backgroundColor={chip.backgroundColor}
            label={chip.content}
            tooltip={chip.tooltip}
          />
        ))}
      </div>

      <div className={classes.descriptionBox}>
        {sideEffects && (
          <ProgressChip label="Side Effects" tooltip={sideEffects} />
        )}
        {remarks && <ProgressChip label="Remarks" tooltip={remarks} />}
      </div>

      <Typography className={classes.button} onClick={handleClickReview}>
        Review
      </Typography>
    </Box>
  )
}

export default ProgressItem
