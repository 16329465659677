import { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import BaseSection from './BaseSection'
import ArrayFieldSectionForm from './SectionForms/ArrayFieldSectionForm'
import ArrayFieldGridSectionLayout from './SectionLayouts/ArrayFieldGridSectionLayout'
import ArrayFieldTableSectionLayout from './SectionLayouts/ArrayFieldTableSectionLayout'
import ArrayFieldListSectionLayout from './SectionLayouts/ArrayFieldListSectionLayout'
import ArrayFieldChipSectionLayout from './SectionLayouts/ArrayFieldChipSectionLayout'
import { useRxStyles } from 'hooks'
import clsx from 'clsx'

const ArrayFieldSection = ({
  path,
  isGrid = false,
  title,
  isList = false,
  isChip = false,
  displayProperty,
  rowTitle,
  fields,
  defaultRow,
  emptyPlaceholder = '',
  actionText = 'Edit',
  boldAction = false,
  smallHeader = false,
  ...props
}) => {
  const { values } = useFormikContext()
  const rxClasses = useRxStyles()
  const rows = useMemo(() => get(values, path, []), [values, path])

  const renderCell = useCallback((row, field) => {
    const value = row[field.path]

    if (field.renderCell) {
      return field.renderCell(value, row)
    }

    if (field.valueFormatter) {
      return field.valueFormatter(value)
    }

    return value
  }, [])

  const renderForm = useCallback(
    () => (
      <ArrayFieldSectionForm
        path={path}
        rows={rows}
        fields={fields}
        rowTitle={rowTitle}
        defaultRow={defaultRow}
      />
    ),
    [defaultRow, fields, rows, path, rowTitle],
  )

  let LayoutComponent = ArrayFieldTableSectionLayout
  if (isGrid) {
    LayoutComponent = ArrayFieldGridSectionLayout
  } else if (isList) {
    LayoutComponent = ArrayFieldListSectionLayout
  } else if (isChip) {
    LayoutComponent = ArrayFieldChipSectionLayout
  }

  return (
    <BaseSection
      title={title}
      isList={isList}
      renderForm={renderForm}
      actionText={actionText}
      boldAction={boldAction}
      smallHeader={smallHeader}
      {...props}
    >
      {LayoutComponent === ArrayFieldTableSectionLayout ? (
        smallHeader ? (
          <Typography
            gutterBottom
            variant="subtitle2"
            className={clsx(rxClasses.fontSize14, rxClasses.fontWeight500)}
          >
            {title}
          </Typography>
        ) : (
          <Typography
            gutterBottom
            variant="h4"
            className={clsx(rxClasses.fontSize16, rxClasses.fontWeight500)}
          >
            {title}
          </Typography>
        )
      ) : null}

      <LayoutComponent
        displayProperty={displayProperty}
        fields={fields}
        rows={rows}
        renderCell={renderCell}
        emptyPlaceholder={emptyPlaceholder}
      />
    </BaseSection>
  )
}

export default ArrayFieldSection
