import clsx from 'clsx'
import { useState } from 'react'
import { useLayoutStyles, useRxStyles, useFormFields, useMemberRx } from 'hooks'
import { useSnackbar } from 'notistack'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import TextInput from 'ui/TextInput'
import TextButton from 'components/Shared/TextButton'
import DatePicker from 'components/Shared/Forms/Fields/DatePicker'
import BaseDialog from 'components/Shared/BaseDialog'
import { API_BASE_URL } from 'constants/api'
import usePostRequest from 'hooks/api/usePostRequest'

import { useTheme } from '@material-ui/core/styles'

export default function SelectedForm({
  open,
  handleClose,
  selected,
  onDeSelect,
}) {
  const { userId, memberId, token, setUpdated } = useMemberRx()
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles()
  const rxClasses = useRxStyles(theme)

  const [loading, setIsLoading] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    daysOfSupply: '',
    quantity: '',
    refills: '',
    directions: '',
    writtenDate: new Date(),
  })

  const deSelect = selected => {
    onDeSelect(selected)
  }

  const onDateChange = event => {
    handleFieldChange(event)
  }

  const addMedication = async ({ params: { userID, memberID }, body }) => {
    try {
      setIsLoading(true)
      await postRequest({
        url: `${API_BASE_URL}/dosespot/medication/patient/add/${userID}&${memberID}`,
        body,
      })
      enqueueSnackbar('Added medication succesfully', { variant: 'success' })
    } catch (error) {
      setIsLoading(false)
      enqueueSnackbar('Failed to add medication', { variant: 'error' })
    } finally {
      setIsLoading(false)
      setUpdated(true)
    }
  }

  return (
    <>
      <BaseDialog
        loading={loading}
        open={open}
        onClose={handleClose}
        title="Add Self-reported Medication"
        dialogContent={
          <div
            key={`${selected.Code}-${selected.RxCUI}`}
            className={rxClasses.rowSpacer}
          >
            <Card variant="outlined">
              <Grid
                container
                columns={12}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={8} className={layoutClasses.padding2}>
                  <Typography
                    className={clsx(
                      rxClasses.fontSize14,
                      rxClasses.typography,
                      theme.typography.fontWeightBold,
                      theme.palette.primaryNavy,
                    )}
                  >
                    {selected.Name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={clsx(
                    layoutClasses.padding2,
                    rxClasses.textAlignRight,
                  )}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      rxClasses.typography,
                      rxClasses.fontSize16,
                      rxClasses.buttonAction,
                      rxClasses.actionSpacerRight,
                      theme.typography.fontWeightRegular,
                    )}
                    onClick={() => {
                      deSelect(selected)
                    }}
                  >
                    DESELECT
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Grid item xs={12}>
              <TextInput
                id="daysOfSupply"
                fullWidth
                variant="outlined"
                label="Days of Supply (Required for Controlled Substances)"
                name="daysOfSupply"
                value={fields.daysOfSupply}
                onChange={handleFieldChange}
              />

              <TextInput
                id="quantity"
                variant="outlined"
                fullWidth
                name="quantity"
                label="Quantity"
                value={fields.quantity}
                onChange={handleFieldChange}
              />

              <TextInput
                id="refills"
                variant="outlined"
                fullWidth
                name="refills"
                label="Refills"
                value={fields.refills}
                onChange={handleFieldChange}
              />

              <TextInput
                id="directions"
                variant="outlined"
                fullWidth
                name="directions"
                label="Directions"
                value={fields.directions}
                onChange={handleFieldChange}
              />

              <DatePicker
                id="writtenDate"
                label="Written date"
                name="writtenDate"
                value={fields.writtenDate}
                onChange={onDateChange}
              />
            </Grid>
          </div>
        }
        dialogActions={
          <>
            <TextButton
              className={clsx(
                theme.typography.fontWeightMedium,
                rxClasses.fontSize16,
                rxClasses.typography,
              )}
              onClick={handleClose}
            >
              CANCEL
            </TextButton>
            <TextButton
              className={clsx(
                theme.typography.fontWeightMedium,
                rxClasses.fontSize16,
                rxClasses.typography,
              )}
              onClick={() => {
                addMedication({
                  token,
                  params: { userID: userId, memberID: memberId },
                  body: {
                    name: selected.Name,
                    lexiGenDrugId: selected.LexiGenDrugId,
                    lexiDrugSynId: selected.LexiDrugSynId,
                    daysOfSupply: fields.daysOfSupply,
                    quantity: fields.quantity,
                    refills: fields.refills,
                    directions: fields.directions,
                    writtenDate: fields.writtenDate,
                  },
                })
              }}
            >
              ADD
            </TextButton>
          </>
        }
      />
    </>
  )
}
