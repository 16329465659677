import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Box from '@material-ui/core/Box'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'

import BornSection from './Sections/BornSection'
import HighestDegreeSection from './Sections/HighestDegreeSection'
import MaritalStatusSection from './Sections/MaritalStatusSection'
import EconomicConditionSection from './Sections/EconomicConditionSection'
import EmploymentSection from './Sections/EmploymentSection'
import CulturalBackgroundSection from './Sections/CulturalBackgroundSection'
import LivingWithSection from './Sections/LivingWithSection'
import LivingArrangementSection from './Sections/LivingArrangementSection'
import NutritionSection from './Sections/NutritionSection'
import PhysicalActivenessSection from './Sections/PhysicalActivenessSection'
import SexualActivenessSection from './Sections/SexualActivenessSection'

export default function SocialBackgroundAccordion(props) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>
          Social Background
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Box flexGrow={1} display="flex" flexDirection="column">
          <BornSection {...props} />
          <HighestDegreeSection {...props} />
          <MaritalStatusSection {...props} />
          <EconomicConditionSection {...props} />
          <EmploymentSection {...props} />
          <CulturalBackgroundSection {...props} />
          <LivingWithSection {...props} />
          <LivingArrangementSection {...props} />
          <NutritionSection {...props} />
          <PhysicalActivenessSection {...props} />
          <SexualActivenessSection {...props} />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
