import {
  FETCH_MEMBER_FILES_LOADING,
  FETCH_MEMBER_FILES_SUCCESS,
  FETCH_MEMBER_FILES_ERROR,
  MEMBER_FILE_UPLOADING,
  MEMBER_FILE_UPLOAD_SUCCESS,
  MEMBER_FILE_UPLOAD_ERROR,
  MEMBER_FILE_DELETING,
  MEMBER_FILE_DELETE_SUCCESS,
  MEMBER_FILE_DELETE_ERROR,
} from './constants'

const initialState = {
  isLoading: false,
  error: null,
  files: [],
  uploading: false,
  deleting: false,
}

const filesStore = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MEMBER_FILES_LOADING:
      return { ...state, isLoading: true }
    case FETCH_MEMBER_FILES_SUCCESS:
      return { ...state, files: payload, isLoading: false }
    case FETCH_MEMBER_FILES_ERROR:
      return { ...state, error: payload, isLoading: false }
    case MEMBER_FILE_UPLOADING:
      return { ...state, uploading: true }
    case MEMBER_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        files: [...state.files, payload],
        uploading: false,
      }
    case MEMBER_FILE_UPLOAD_ERROR:
      return { ...state, error: payload, uploading: false }
    case MEMBER_FILE_DELETING:
      return { ...state, deleting: true }
    case MEMBER_FILE_DELETE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.id !== payload),
        deleting: false,
      }
    case MEMBER_FILE_DELETE_ERROR:
      return { ...state, error: payload, deleting: false }
    default:
      return state
  }
}

export default filesStore
