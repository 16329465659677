import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { notesFailed } from 'redux/notes'
import { useRxStyles } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import usePostNoteAddendum from 'hooks/api/encounters/usePostNoteAddendum'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import TextInput from 'ui/TextInput'
import SignatureModal from './Signature/SignatureModal'
import { makeStyles } from '@material-ui/core/styles'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    boxSizing: 'border-box',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e3e3e3',
      },
      '&:hover fieldset': {
        borderColor: '#daefed',
      },
    },
  },
  trailing: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default function AddendumForm({ note }) {
  const classes = useStyles()
  const rxClasses = useRxStyles()
  const { enqueueSnackbar } = useSnackbar()
  const postNoteAddendum = usePostNoteAddendum()
  const dispatch = useDispatch()
  const [input, setInput] = useState('')

  const [signatureModal, showSignatureModal] = useState(false)

  useEffect(() => {
    setInput('')
  }, [note])

  const userID = useSelector(state => state.users.currentUser._id)

  const newAddendum = () => {
    return {
      dateCreated: new Date(),
      description: input,
      noteID: note._id,
      userID,
    }
  }

  const addAddendum = async () => {
    try {
      await postNoteAddendum(newAddendum())
    } catch (error) {
      enqueueSnackbar('Failed to save addendum to encounter', {
        variant: 'error',
      })
      dispatch(notesFailed(error.message))
    }
  }

  return (
    <BaseCard>
      <BaseCardHeader bold title="Addendum" />
      <BaseRow
        withDivider={true}
        columns={
          <Grid container>
            <Grid className={rxClasses.columnPadding} item xs={12}>
              <div className={clsx(classes.root)}>
                <TextInput
                  multiline
                  name="addendum"
                  placeholder="Write here"
                  variant="outlined"
                  value={input}
                  onChange={e => {
                    setInput(e.target.value)
                  }}
                  fullWidth
                />

                <div className={classes.trailing}>
                  <Button
                    disabled={!input}
                    className="mint"
                    onClick={() => {
                      showSignatureModal(true)
                    }}
                  >
                    SIGN & ADD
                  </Button>
                </div>

                {signatureModal && (
                  <SignatureModal
                    note={note}
                    open={signatureModal}
                    onClose={() => showSignatureModal(false)}
                    onFinalize={addAddendum}
                    buttonText="ADD"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        }
      />
    </BaseCard>
  )
}
