import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  memberFileUploading,
  memberFileUploadSuccess,
  memberFileUploadError,
} from 'redux/files/actions'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function useFileGenerate() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const postFile = useCallback(
    async data => {
      dispatch(memberFileUploading())
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/files/generate`,
        headers: {
          'Content-Type': 'application/json',
        },
        options: {
          method: HTTP_METHODS.POST,
          body: JSON.stringify(data),
        },
      })

      if (response.ok) {
        dispatch(memberFileUploadSuccess({ ...response, id: response._id }))
      } else {
        dispatch(memberFileUploadError('Failed to generate file'))
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return postFile
}
