import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addMember, membersLoading, membersFailed } from 'redux/members'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePostMember() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const postMember = useCallback(
    async member => {
      dispatch(membersLoading())

      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/members`,
        options: {
          method: HTTP_METHODS.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(member),
        },
      })

      if (response.ok) {
        const result = await response.json()
        dispatch(addMember(result))
        return result
      } else {
        dispatch(membersFailed('Failed to post member'))
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return postMember
}
