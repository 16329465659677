import { isNumber, isString, isEmpty } from 'lodash'
import { MEMBER_STATUS } from 'constants/member'
import { isAdult } from 'utils/formatters'
import { phoneSchemaDigitsOnly, addressSchema } from 'utils/validationSchemas'

function isActive(status) {
  return status === MEMBER_STATUS.Active
}

export async function validateAddress(address, schema = addressSchema) {
  return await schema.validate(address, { abortEarly: false }).catch(error => {
    return {
      errors: error.errors,
    }
  })
}

export async function validatePhone(phone, schema = phoneSchemaDigitsOnly) {
  return await schema.validate(phone, { abortEarly: false }).catch(error => {
    return {
      errors: error.errors,
    }
  })
}

async function isValidAddress(address, schema) {
  const result = await validateAddress(address, schema)
  return isEmpty(result.errors)
}

async function isValidPhone(phone, schema) {
  const result = await validatePhone(phone, schema)
  return isEmpty(result.errors)
}

export async function isEligibleForDoseSpot(member) {
  const {
    firstName,
    lastName,
    phone,
    dob,
    gender,
    height,
    weight,
    status,
    shippingAddress,
  } = member

  return (
    isString(firstName) &&
    isString(lastName) &&
    isString(gender) &&
    isNumber(height) &&
    isNumber(weight) &&
    isActive(status) &&
    isAdult(new Date(), dob) &&
    (await isValidAddress(shippingAddress, addressSchema)) &&
    (await isValidPhone(phone, phoneSchemaDigitsOnly))
  )
}
