import { formatDateString } from 'utils/formatters'

export const DEFAULT_HISTORY_ROW = {
  date: new Date(),
  description: '',
}

export const HISTORY_ROW_FIELDS = [
  {
    path: 'date',
    label: 'Date',
    headerClassName: 'first-col',
    valueFormatter: formatDateString,
    type: 'date',
  },
  {
    path: 'description',
    label: 'Description',
  },
]
