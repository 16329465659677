import {
  DEFAULT_HISTORY_ROW,
  HISTORY_ROW_FIELDS,
} from 'constants/sectionFields'
import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const MedicalDiagnosisHistorySection = props => (
  <ArrayFieldSection
    path="medicalDiagnosisHistory"
    title="Past Medical Diagnosis"
    rowTitle="Diagnosis"
    defaultRow={DEFAULT_HISTORY_ROW}
    fields={HISTORY_ROW_FIELDS}
    {...props}
  />
)

export default withMemberForm('MedicalDiagnosisHistorySectionForm', [
  'medicalDiagnosisHistory',
])(MedicalDiagnosisHistorySection)
