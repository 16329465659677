import clsx from 'clsx'
import { useLayoutStyles, useRxStyles } from 'hooks'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function BaseCardHeader({
  title,
  actions,
  actionOnClick,
  actionText,
  bold = false,
  className,
  isNegative = false,
}) {
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const rxClasses = useRxStyles(theme)

  const shouldRenderAction = actionOnClick && actionText

  return (
    <>
      <Grid item xs={6} className={clsx(className, layoutClasses.padding2)}>
        {!bold ? (
          <Typography variant="h4Bold">{title}</Typography>
        ) : (
          <Typography variant="h4">{title}</Typography>
        )}
      </Grid>
      {shouldRenderAction && (
        <Grid
          item
          xs={6}
          className={clsx(layoutClasses.padding2, theme.layout.textAlignRight)}
        >
          <Typography
            variant="body1"
            className={clsx({
              [rxClasses.buttonDelete]: isNegative,
              [rxClasses.typography]: true,
              [rxClasses.buttonAction]: !isNegative,
              [rxClasses.fontSize12]: true,
            })}
            align="right"
            onClick={actionOnClick}
          >
            {actionText}
          </Typography>
        </Grid>
      )}
      {actions && actions.length && (
        <Grid
          container
          justifyContent="flex-end"
          item
          xs={6}
          spacing={2}
          className={clsx(layoutClasses.padding2, theme.layout.textAlignRight)}
        >
          {actions.map((action, index) => (
            <Grid key={index} item>
              {action}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}
