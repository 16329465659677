import { useCallback, useState } from 'react'
import { useFormikContext } from 'formik'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import DivSectionContent from './SectionContents/DivSectionContent'
import CardSectionContent from './SectionContents/CardSectionContent'
import LinkSectionContent from './SectionContents/LinkSectionContent'

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
  },
}))

function BaseSection({
  title,
  isCard = false,
  isList = false,
  isLink = false,
  contentProps = {},
  renderForm,
  children,
  actionText = 'Edit',
  boldAction = false,
  smallHeader = false,
}) {
  const classes = useStyles()

  const { isValid, isSubmitting, resetForm, submitForm } = useFormikContext()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = useCallback(() => {
    resetForm()

    setOpen(false)
  }, [resetForm])

  const handleSubmit = useCallback(async () => {
    try {
      if (!isValid) {
        throw new Error('Validation error')
      }

      await submitForm()

      closeModal()
    } catch (e) {}
  }, [isValid, closeModal, submitForm])

  const ContentComponent = isCard
    ? CardSectionContent
    : isLink
    ? LinkSectionContent
    : DivSectionContent

  return (
    <>
      <Dialog
        open={open}
        classes={{ paper: classes.paper }}
        onClose={closeModal}
      >
        <DialogTitle>
          <Box sx={{ fontSize: 20 }}>{title}</Box>
        </DialogTitle>

        <DialogContent>{renderForm()}</DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting && (
              <>
                <CircularProgress size={20} />
                &nbsp;
              </>
            )}
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ContentComponent
        title={title}
        isList={isList}
        onEdit={openModal}
        actionText={actionText}
        boldAction={boldAction}
        smallHeader={smallHeader}
        {...contentProps}
      >
        {children}
      </ContentComponent>
    </>
  )
}

export default BaseSection
