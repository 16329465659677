import keyBy from 'lodash/keyBy'

// ActionTypes
const SET_EXTERNAL_PROVIDERS = 'externalProviders/SET_EXTERNAL_PROVIDERS'
const ADD_EXTERNAL_PROVIDER = 'externalProviders/ADD_EXTERNAL_PROVIDER'
const UPDATE_EXTERNAL_PROVIDER = 'externalProviders/UPDATE_EXTERNAL_PROVIDER'

const initialState = {
  isLoading: true,
  error: null,
  byId: {},
  allIds: [],
}

// Reducer
const externalProvidersStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXTERNAL_PROVIDERS:
      const externalProviders = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        byId: keyBy(externalProviders, '_id'),
        allIds: externalProviders.map(externalProvider => externalProvider._id),
      }

    case ADD_EXTERNAL_PROVIDER:
      const newExternalProvider = action.payload

      return {
        ...state,
        byId: { ...state.byId, [newExternalProvider._id]: newExternalProvider },
        allIds: [...state.allIds, newExternalProvider._id],
      }

    case UPDATE_EXTERNAL_PROVIDER:
      const updatedExternalProvider = action.payload

      return {
        ...state,
        isLoading: false,
        byId: {
          ...state.byId,
          [updatedExternalProvider._id]: updatedExternalProvider,
        },
      }

    default:
      return state
  }
}

// ActionCreators
export const setExternalProviders = externalProviders => ({
  type: SET_EXTERNAL_PROVIDERS,
  payload: externalProviders,
})

export const addExternalProvider = externalProvider => ({
  type: ADD_EXTERNAL_PROVIDER,
  payload: externalProvider,
})

export const updateExternalProvider = externalProvider => ({
  type: UPDATE_EXTERNAL_PROVIDER,
  payload: externalProvider,
})

export default externalProvidersStore
