import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { memberFilesSuccess } from 'redux/files/actions'
import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

import Grid from '@material-ui/core/Grid'

import MemberFilesHeader from './MemberFilesHeader'
import MemberFilesMainBody from './MemberFilesMainBody'

export default function MemberFiles({ member }) {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const fetchMemberFiles = async memberId => {
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/${memberId}/file`,
        options: {
          method: HTTP_METHODS.GET,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      })

      if (response.ok) {
        return await response.json()
      }
    }

    Promise.resolve(fetchMemberFiles(member._id))
      .then(response => {
        if (response) {
          return response
        } else {
          throw new Error('Failed to load')
        }
      })
      .then(data => {
        dispatch(
          memberFilesSuccess(
            Object.values(data || {}).map(file => ({
              id: file._id,
              ...file,
            })),
          ),
        )
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
  }, [dispatch, enqueueSnackbar, fetchWithRefresh, member._id])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MemberFilesHeader member={member} />
      </Grid>

      <Grid item xs={12}>
        <MemberFilesMainBody member={member} />
      </Grid>
    </Grid>
  )
}
