import { useCallback, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import get from 'lodash/get'

import { ExternalProviderShareTypes } from 'constants/selectOptions'
import { USER_TITLE } from 'constants/user'
import { addTitle, formatExternalProvider } from 'utils/formatters'
import withMemberForm from 'HOCs/withMemberForm'
import UserProfile from 'components/Shared/UserProfile'
import ExternalProviderProfile from 'components/Shared/ExternalProviderProfile'
import BaseSection from 'components/Shared/Sections/BaseSection'
import MultiFieldsSectionForm from 'components/Shared/Sections/SectionForms/MultiFieldsSectionForm'

import ExternalProviderInfo from './ExternalProviderInfo'
import CareTeamCardMenu from './CareTeamCardMenu'

function CareTeamCard({
  member,
  carePartners,
  psychiatrists,
  therapists,
  externalProviders,
}) {
  const carePartner = useMemo(
    () => get(carePartners.byId, member.carePartner, null),
    [member.carePartner, carePartners],
  )
  const psychiatrist = useMemo(
    () => get(psychiatrists.byId, member.psychiatrist, null),
    [member.psychiatrist, psychiatrists],
  )
  const therapist = useMemo(
    () => get(therapists.byId, member.therapist, null),
    [member.therapist, therapists],
  )
  const extProvOne = useMemo(
    () => get(externalProviders.byId, member.extProvOne, null),
    [member.extProvOne, externalProviders],
  )
  const extProvTwo = useMemo(
    () => get(externalProviders.byId, member.extProvTwo, null),
    [member.extProvTwo, externalProviders],
  )

  const fields = useMemo(
    () => [
      {
        path: 'carePartner',
        label: `${USER_TITLE.CARE_COORDINATOR}`,
        type: 'autocomplete',
        componentProps: {
          options: carePartners.allIds,
          getOptionLabel: id => addTitle(carePartners.byId[id]),
        },
      },
      {
        path: 'psychiatrist',
        label: `${USER_TITLE.PSYCHIATRIST}`,
        type: 'autocomplete',
        componentProps: {
          options: psychiatrists.allIds,
          getOptionLabel: id => addTitle(psychiatrists.byId[id]),
        },
      },
      {
        path: 'therapist',
        label: `${USER_TITLE.THERAPIST}`,
        type: 'autocomplete',
        componentProps: {
          options: therapists.allIds,
          getOptionLabel: id => addTitle(therapists.byId[id]),
        },
      },
      {
        path: 'extProvOne',
        label: 'External Provider #1',
        type: 'autocomplete',
        componentProps: {
          options: externalProviders.allIds,
          getOptionLabel: id =>
            formatExternalProvider(get(externalProviders, ['byId', id])),
        },
      },
      {
        path: 'extProvOneShareTypes',
        label: 'Information shared for External Provider #1',
        componentProps: {
          select: true,
          options: ExternalProviderShareTypes,
          SelectProps: {
            multiple: true,
          },
        },
      },
      {
        path: 'extProvTwo',
        label: 'External Provider #2',
        type: 'autocomplete',
        componentProps: {
          options: externalProviders.allIds,
          getOptionLabel: id =>
            formatExternalProvider(get(externalProviders, ['byId', id])),
        },
      },
      {
        path: 'extProvTwoShareTypes',
        label: 'Information shared for External Provider #2',
        componentProps: {
          select: true,
          options: ExternalProviderShareTypes,
          SelectProps: {
            multiple: true,
          },
        },
      },
    ],
    [carePartners, psychiatrists, therapists, externalProviders],
  )

  const renderForm = useCallback(
    () => <MultiFieldsSectionForm fields={fields} />,
    [fields],
  )
  const renderCardMenu = useCallback(
    ({ onEdit }) => (
      <CareTeamCardMenu
        extProvOne={extProvOne}
        extProvTwo={extProvTwo}
        onEdit={onEdit}
      />
    ),
    [extProvOne, extProvTwo],
  )

  return (
    <BaseSection
      isCard
      title="Care Team"
      successMessage="Care team information was updated"
      contentProps={{ renderCardMenu }}
      renderForm={renderForm}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {carePartner && <UserProfile user={carePartner} />}
        </Grid>

        <Grid item xs={12} md={6}>
          {psychiatrist && <UserProfile user={psychiatrist} />}
        </Grid>

        {therapist && (
          <Grid item xs={12}>
            <UserProfile user={therapist} />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          {extProvOne && member.extProvOneShareTypes && (
            <>
              <ExternalProviderProfile externalProvider={extProvOne} />

              <ExternalProviderInfo
                externalProvider={extProvOne}
                shareTypes={member.extProvOneShareTypes}
              />
            </>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {extProvTwo && member.extProvTwoShareTypes && (
            <>
              <ExternalProviderProfile externalProvider={extProvTwo} />

              <ExternalProviderInfo
                externalProvider={extProvTwo}
                shareTypes={member.extProvTwoShareTypes}
              />
            </>
          )}
        </Grid>
      </Grid>
    </BaseSection>
  )
}

export default withMemberForm('CareTeamCardForm', [
  'carePartner',
  'psychiatrist',
  'therapist',
  'extProvOne',
  'extProvOneShareTypes',
  'extProvTwo',
  'extProvTwoShareTypes',
])(CareTeamCard)
