import './ProgressChart.css'
import { ResponsiveLine } from '@nivo/line'
import Tooltip from './Tooltip'

const CustomSymbol = ({ size, borderWidth, borderColor }) => (
  <g>
    <circle
      fill="#fff"
      r={size / 2}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
  </g>
)

export default function ProgressChart({ progresses }) {
  const formatData = progresses => {
    const phqs = progresses
      ?.filter(progr => !!progr.totalPHQ)
      .map((prog, index, progs) => ({
        x: new Intl.DateTimeFormat('en-US').format(new Date(prog.timeStamp)),
        y: prog.totalPHQ,
        difference:
          index === 0 || index === progs.length - 1
            ? 0
            : prog.totalPHQ - progs[index - 1].totalPHQ,
      }))

    const gads = progresses
      ?.filter(progr => !!progr.totalGAD)
      .map((prog, index, progs) => ({
        x: new Intl.DateTimeFormat('en-US').format(new Date(prog.timeStamp)),
        y: prog.totalGAD,
        difference:
          index === 0 || index === progs.length - 1
            ? 0
            : prog.totalGAD - progs[index - 1].totalGAD,
      }))

    return [
      {
        id: 'PHQ-9',
        data: phqs,
      },
      {
        id: 'GAD-7',
        data: gads,
      },
    ]
  }

  const formattedData = formatData(progresses)

  return (
    <ResponsiveLine
      data={formattedData}
      margin={{ top: 10, right: 110, bottom: 50, left: 60 }}
      xScale={{
        type: 'time',
        format: '%m/%d/%Y',
        useUTC: false,
        precision: 'day',
      }}
      xFormat="time:%m/%d/%Y"
      colors={['#DDAF0C', '#6D9147', '#3C82EB']}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        tickValues: '15',
      }}
      axisBottom={{
        format: '%m/%d/%Y',
        tickValues: 'every month',
      }}
      enableSlices="x"
      sliceTooltip={Tooltip}
      enableGridX={false}
      theme={{
        textColor: '#7C989B',
        fontSize: 12,
        axis: {
          ticks: {
            line: {
              strokeWidth: 0,
            },
          },
        },
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          translateX: 110,
          itemWidth: 80,
          itemHeight: 20,
          symbolShape: 'circle',
        },
      ]}
      pointSymbol={CustomSymbol}
      pointSize={13}
      pointBorderWidth={1}
      pointBorderColor={{
        from: 'color',
        modifiers: [['darker', 0.3]],
      }}
    />
  )
}
