import { daysBetween } from 'utils/formatters'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProgresses } from 'redux/progresses'
import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { API_BASE_URL, HTTP_METHODS } from 'constants/api'
import { useCurrentUser, useQueryParams } from 'hooks'

import AssesmentModal from 'components/Member/MemberProgress/AssessmentModal'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import {
  ASSESSMENT_TYPES,
  SEND_REQUEST_MENU_OPTIONS,
  NEW_ASSESSMENT_MENU_OPTIONS,
} from 'constants/progress'
import useCreateProgress from 'hooks/api/progresses/useCreateProgress'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import ProgressAssessmentMenu from './Menu/ProgressAssessmentMenu'
import ProgressChart from './ProgressChart/ProgressChart'
import ProgressList from './ProgressList/ProgressList'
import ProgressModal from './ProgressModal'
import AssessmentList from './Assessment/AssessmentList'

import './MemberProgress.css'

const MONTH = 30

function ProgressNotice({ progresses }) {
  const [lastProgress] = progresses.filter(
    (_, index, progs) => index === progs.length - 1,
  )

  if (!lastProgress) {
    return (
      <Typography style={{ fontSize: 14 }} component="span">
        No assessments available
      </Typography>
    )
  }
  const daysSince = daysBetween(new Date(), new Date(lastProgress.timeStamp))

  const textColor = daysSince >= MONTH ? '#eb5757' : '#36bca8'

  return (
    <Typography style={{ fontSize: 14 }} component="span">
      Most recent progress data is from{' '}
      <span style={{ color: textColor }}>
        {daysSince} day{daysSince === 1 ? '' : 's'} ago
      </span>
    </Typography>
  )
}

export default function MemberProgress({ member }) {
  const currentUser = useCurrentUser()
  const { fetchWithRefresh } = useGoogleAuth()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [assessmentModal, setAssessmentModal] = useState(false)
  const [selectedProgress, setSelectedProgress] = useState(null)
  const [initialProgressOpened, setInitialProgressOpened] = useState(false)
  const [isDirectAssessmentMode, setDirectAssessmentMode] = useState(false)
  const [assessmentTypes, setAssessmentTypes] = useState([])

  const createProgress = useCreateProgress()

  const progresses = useSelector(state => state.progresses)
  const filteredProgresses = Object.values(progresses.progressesByID)
    .filter(progress => progress.memberID === member._id)
    .filter(progress => progress !== undefined && progress.archived !== true)
  const query = useQueryParams()
  const progressId = useMemo(() => query.get('progressId') || '', [query])

  const selectProgress = progress => {
    setShowModal(true)
    setSelectedProgress(progress)
  }

  const fetchProgresses = async memberId => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/${memberId}/progress`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      return await response.json()
    }
  }

  const handleClickSendRequestOption = useCallback(option => {
    if (option.type === ASSESSMENT_TYPES.PHQ9_GAD7) {
      setAssessmentModal(true)
    }
  }, [])

  const handleNewAssessmentOption = useCallback(option => {
    setDirectAssessmentMode(true)

    if (option.type === ASSESSMENT_TYPES.PHQ9_GAD7) {
      setAssessmentTypes([ASSESSMENT_TYPES.PHQ9, ASSESSMENT_TYPES.GAD7])
    } else {
      setAssessmentTypes([option.type])
    }
  }, [])

  const handleCancelAssessments = () => {
    setDirectAssessmentMode(false)
  }

  const handleSaveAssessments = async scores => {
    try {
      await createProgress({
        ...scores,
        memberID: member._id,
        reviewedAt: new Date(),
        reviewedBy: currentUser._id,
        sideEffects: [],
      })
    } finally {
      setDirectAssessmentMode(false)
    }
  }

  useEffect(() => {
    const fetchData = () => {
      Promise.resolve(fetchProgresses(member._id))
        .then(response => {
          if (response) {
            return response
          } else {
            throw new Error('Failed to load')
          }
        })
        .then(progresses => {
          dispatch(addProgresses(progresses))
        })
        .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
    }

    fetchData()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!initialProgressOpened) {
      if (progressId) {
        if (!progresses.isLoading) {
          if (progresses.progressesByID[progressId]) {
            selectProgress(progresses.progressesByID[progressId])
          }
          setInitialProgressOpened(true)
        }
      } else {
        setInitialProgressOpened(true)
      }
    }
  }, [initialProgressOpened, progressId, progresses, showModal])

  if (
    progresses.isLoading &&
    (isEmpty(member.progress) || !filteredProgresses)
  ) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  if (progresses.error) {
    return <div className="centered">{progresses.error}</div>
  }

  return (
    <>
      {assessmentModal && (
        <AssesmentModal
          open={assessmentModal}
          onClose={() => {
            setAssessmentModal(false)
          }}
          member={member}
        />
      )}
      {showModal && (
        <ProgressModal
          open={showModal}
          close={() => {
            setShowModal(false)
          }}
          progress={selectedProgress}
        />
      )}
      {!isDirectAssessmentMode ? (
        <div className="member-progress-container">
          <div className="member-progress-header">
            <BaseCard>
              <BaseCardHeader
                title={<ProgressNotice progresses={filteredProgresses} />}
                actions={[
                  <ProgressAssessmentMenu
                    key="send-request"
                    title="SEND REQUEST"
                    options={SEND_REQUEST_MENU_OPTIONS}
                    onClick={handleClickSendRequestOption}
                  />,
                  <ProgressAssessmentMenu
                    key="new-assessment"
                    title="NEW ASSESSMENT"
                    options={NEW_ASSESSMENT_MENU_OPTIONS}
                    onClick={handleNewAssessmentOption}
                  />,
                ]}
              />
            </BaseCard>
          </div>
          <div className="member-progress-chart">
            <ProgressChart progresses={filteredProgresses} />
          </div>
          <div className="member-progress-grid">
            <ProgressList
              progresses={filteredProgresses}
              isLoading={progresses.isLoading}
              onClickViewDetails={selectProgress}
              onClickReview={selectProgress}
            />
          </div>
        </div>
      ) : (
        <AssessmentList
          types={assessmentTypes}
          onCancel={handleCancelAssessments}
          onSave={handleSaveAssessments}
        />
      )}
    </>
  )
}
