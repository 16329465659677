import { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'

import usePostExternalProvider from 'hooks/api/externalProviders/usePostExternalProvider'
import usePutExternalProvider from 'hooks/api/externalProviders/usePutExternalProvider'

import ExternalProviderModalForm from './ExternalProviderModalForm'

export default function CareTeamCardMenu({ extProvOne, extProvTwo, onEdit }) {
  const postExternalProviderAction = usePostExternalProvider()
  const putExternalProviderAction = usePutExternalProvider()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openExternalProviderForm, setOpenExternalProviderForm] =
    useState(false)
  const [externalProviderInEdit, setExternalProviderInEdit] = useState(null)

  const open = Boolean(anchorEl)

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleExternalProviderFormOpen = (externalProvider = null) => {
    setOpenExternalProviderForm(true)
    setExternalProviderInEdit(externalProvider)
  }

  const handleExternalProviderFormClose = () => {
    setExternalProviderInEdit(null)
    setOpenExternalProviderForm(false)
  }

  const handleExternalProviderFormSubmit = async values => {
    if (externalProviderInEdit) {
      await putExternalProviderAction({
        ...values,
        _id: externalProviderInEdit._id,
      })
    } else {
      await postExternalProviderAction(values)
    }
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="care-team-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="care-team-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose()
            onEdit()
          }}
        >
          Edit patient care team
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose()
            handleExternalProviderFormOpen(null)
          }}
        >
          Add new external provider profile
        </MenuItem>

        <MenuItem
          disabled={!extProvOne}
          onClick={() => {
            handleMenuClose()
            handleExternalProviderFormOpen(extProvOne)
          }}
        >
          Edit external provider profile #1
        </MenuItem>

        <MenuItem
          disabled={!extProvTwo}
          onClick={() => {
            handleMenuClose()
            handleExternalProviderFormOpen(extProvTwo)
          }}
        >
          Edit external provider profile #2
        </MenuItem>
      </Menu>

      <ExternalProviderModalForm
        open={openExternalProviderForm}
        externalProvider={externalProviderInEdit}
        onClose={handleExternalProviderFormClose}
        onSubmit={handleExternalProviderFormSubmit}
      />
    </>
  )
}
