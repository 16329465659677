import clsx from 'clsx'
import { isUndefined } from 'lodash'
import { isValid } from 'date-fns'
import { useTheme } from '@material-ui/core/styles'
import { useFormFields, useRxStyles, useLayoutStyles } from 'hooks'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ENCOUNTER_TYPE } from 'utils/encounters'
import { getDefaultCptCode } from 'utils/notesHelpers'
import { roundTime } from 'utils/formatters'
import usePostNote from 'hooks/api/encounters/usePostNote'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import DatePicker from 'components/Shared/Forms/Fields/DatePicker'
import TimePicker from 'components/Shared/Forms/Fields/TimePicker'
import DurationPicker from 'components/Shared/Forms/Fields/DurationPicker'
import SelectProvider from 'components/Shared/Forms/Fields/SelectProvider'

function isValidForm(fields) {
  return (
    fields.durationOfEncounter &&
    fields.dateOfEncounter &&
    fields.timeOfEncounter &&
    fields.typeOfEncounter &&
    fields.provider
  )
}

export default function CreateEncounterDialog({
  open,
  onClose,
  message,
  loading,
  title,
}) {
  const postNote = usePostNote()
  const { enqueueSnackbar } = useSnackbar()
  const { memberId } = useParams()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const layoutClasses = useLayoutStyles(theme)

  const [member, currentUser] = useSelector(state => {
    return [state.members.byId[memberId], state.users.currentUser]
  })

  const encounterCounts = useSelector(state => {
    const encounters = member.notes
      .map(noteID => state.notes.notes[noteID])
      .filter(Boolean)
    const counts = encounters.reduce((encounter, v) => {
      encounter[v.type] = (encounter[v.type] || 0) + 1
      return encounter
    }, {})
    return counts
  })

  const [fields, handleFieldChange] = useFormFields({
    provider: currentUser._id,
    typeOfEncounter: '',
    dateOfEncounter: new Date(),
    timeOfEncounter: roundTime(new Date(), 30),
    durationOfEncounter: '30',
  })

  const combineDateAndTime = (date, time) => {
    const dateTime = new Date(date)
    dateTime.setHours(time.getHours())
    dateTime.setMinutes(time.getMinutes())
    return dateTime
  }

  const createEncounter = () => {
    if (!isValidForm(fields)) {
      enqueueSnackbar('Please fill out all fields', { variant: 'error' })
      return
    }
    combineDateAndTime(fields.dateOfEncounter, fields.timeOfEncounter)
    const cptCode = getDefaultCptCode({
      numOfEncounters: isUndefined(encounterCounts[fields.typeOfEncounter])
        ? 0
        : encounterCounts[fields.typeOfEncounter],
      type: fields.typeOfEncounter,
      duration: fields.durationOfEncounter,
    })

    Promise.resolve(
      postNote(
        {
          memberID: memberId,
          userID: fields.provider,
          details: getDetails(fields.typeOfEncounter),
          type: fields.typeOfEncounter,
          duration: fields.durationOfEncounter,
          dateCreated: combineDateAndTime(
            fields.dateOfEncounter,
            fields.timeOfEncounter,
          ),
          cptCodes: [cptCode],
        },
        member,
      ),
    )
  }

  const handleCreateEncounter = () => {
    createEncounter()
    onClose()
  }

  const getDetails = type => {
    const types = {
      [ENCOUNTER_TYPE.CARE_MANAGEMENT]: {
        typeOfActivity: '',
        engagedWith: '',
        topic: '',
        noteAndMethod: '',
      },
      [ENCOUNTER_TYPE.PSYCHIATRY]: {
        encounter: '',
      },
      [ENCOUNTER_TYPE.THERAPY]: {
        encounter: '',
      },
    }
    return types[type]
  }

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={title}
      dialogContent={
        <>
          <Typography className={clsx(rxClasses.typography, layoutClasses.mb3)}>
            {message ?? ''}
          </Typography>

          <SelectProvider
            id="provider"
            variant="outlined"
            defaultValue={fields.provider}
            onChange={handleFieldChange}
            label="Rendering Provider"
            name="provider"
            required={true}
          />

          <Autocomplete
            id="type-select"
            options={[
              ENCOUNTER_TYPE.CARE_MANAGEMENT,
              ENCOUNTER_TYPE.PSYCHIATRY,
              ENCOUNTER_TYPE.THERAPY,
            ]}
            getOptionLabel={option => option}
            style={{ width: '100%' }}
            onChange={(event, value) => {
              handleFieldChange({ target: { name: 'typeOfEncounter', value } })
            }}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Type of Encounter"
                placeholder="Select type"
                margin="none"
                required
              />
            )}
            required
          />

          <DatePicker
            id="dateOfEncounter"
            fullWidth
            variant="outlined"
            label="Date of Encounter"
            name="dateOfEncounter"
            value={fields.dateOfEncounter}
            onChange={handleFieldChange}
          />

          <TimePicker
            id="timeOfEncounter"
            fullWidth
            variant="outlined"
            label="Time of Encounter"
            name="timeOfEncounter"
            value={fields.timeOfEncounter}
            onChange={e => {
              if (isValid(e)) {
                handleFieldChange({
                  target: {
                    name: 'timeOfEncounter',
                    value: new Date(e),
                  },
                })
              }
            }}
            margin="none"
            dialog={true}
          />
          <div style={{ height: '55px' }}>
            <DurationPicker
              id="durationOfEncounter"
              name="durationOfEncounter"
              label="Duration of Encounter"
              value={fields.durationOfEncounter}
              onChange={e => {
                handleFieldChange(e)
              }}
              margin="none"
              variant="outlined"
              fullWidth
            />
          </div>
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={onClose}
          >
            CANCEL
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              handleCreateEncounter()
              onClose()
            }}
            disabled={!isValidForm(fields)}
          >
            CREATE
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
