import { makeStyles, Box } from '@material-ui/core'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import AssessmentModal from 'components/Member/MemberProgress/AssessmentModal'
import { useOverdueMembers } from 'hooks'
import { selectAllMembers } from 'redux/selectors'

import ProgressSubheader from './ProgressSubheader'
import OverdueMember from './OverdueMember'

const useStyles = makeStyles({
  content: {
    overflowY: 'scroll',
  },
})

const OverdueMembers = ({ isMyPatientsProgress }) => {
  const classes = useStyles()
  const [requestedMember, setRequestedMember] = useState(null)

  const allProgresses = useSelector(
    ({ progresses }) => progresses.progressesByID,
  )
  const allMembers = useSelector(selectAllMembers)
  const byIdMembers = useSelector(({ members }) => members.byId)

  const overDueMembers = useOverdueMembers(
    isMyPatientsProgress,
    allMembers,
    allProgresses,
    byIdMembers,
  )

  const handleClickRequestAssessment = member => {
    setRequestedMember(member)
  }

  const handleCloseAssessmentModal = () => {
    setRequestedMember(null)
  }

  const isAssessmentModalOpen = Boolean(requestedMember)

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <AssessmentModal
        open={isAssessmentModalOpen}
        member={requestedMember}
        onClose={handleCloseAssessmentModal}
      />

      {!overDueMembers.length && (
        <ProgressSubheader content="No member overdue for an assessment" />
      )}

      <Box className={classes.content}>
        {overDueMembers.map(member => (
          <OverdueMember
            key={member._id}
            member={member}
            onRequestAssessment={handleClickRequestAssessment}
          />
        ))}
      </Box>
    </Box>
  )
}

export default OverdueMembers
