import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { IMPORTANT_FILE_TYPES } from 'constants/member'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },

  file: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },

  fileType: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 1.6,
    color: '#183F4F',
    textTransform: 'uppercase',
  },

  fileName: {
    fontSize: 20,
    lineHeight: 1.2,
  },

  uploadedFileName: {
    color: '#36BCA8',
  },

  notUploadedFileName: {
    color: '#EB5757',
  },
}))

function ImportantFileType({ fileType, uploaded }) {
  const classes = useStyles()

  return (
    <div className={classes.file}>
      <Typography className={classes.fileType}>{fileType}</Typography>
      <Typography
        className={clsx(
          classes.fileName,
          uploaded && classes.uploadedFileName,
          !uploaded && classes.notUploadedFileName,
        )}
      >
        {uploaded ? 'Uploaded' : 'N/A'}
      </Typography>
    </div>
  )
}

export default function ImportantFilesUploaded({ files }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {Object.keys(IMPORTANT_FILE_TYPES).map(fileType => (
        <ImportantFileType
          key={fileType}
          fileType={IMPORTANT_FILE_TYPES[fileType]}
          uploaded={files.find(file => file.documentType === fileType)}
        />
      ))}
    </div>
  )
}
