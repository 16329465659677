import './ProgressModal.css'

import clsx from 'clsx'
import { Fragment } from 'react'
import _ from 'lodash'
import usePutProgress from 'hooks/api/progresses/usePutProgress'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { ASSESSMENT_TYPE_DETAILS } from 'constants/progress'
import { formatDateString, formatFullName } from 'utils/formatters'
import { useRxStyles, useCurrentUser } from 'hooks'

const useStyles = makeStyles(({ spacing, palette }) => ({
  dialogActions: {
    flexDirection: 'column',
    padding: spacing(1, 3),
    '&>:not(:first-child)': {
      marginLeft: 0,
    },
  },
  button: {
    marginBottom: spacing(1),
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: palette.backgroundGray.main,
    },
  },
  red: {
    color: palette.accentRed.main,
    borderColor: palette.accentRed.main,
  },
  green: {
    color: palette.primary.main,
    borderColor: palette.primary.main,
  },
}))

export function ScoreSection({
  title,
  scores,
  scoreLabels,
  scoreGroups,
  total,
}) {
  if (!scoreGroups || !scoreGroups.length) {
    return null
  }

  const renderDisplayField = (group, field, groupIndex) => {
    const baseIndex = group.baseIndex ?? 0
    const index = baseIndex + groupIndex

    return (
      <Fragment key={groupIndex}>
        <h3 className="modal-grid-item question">{field.label}</h3>
        <h3 className="modal-grid-item answer right">
          {scoreLabels?.[index] ?? field.possibleScores[scores?.[index]]?.label}
        </h3>
      </Fragment>
    )
  }

  const renderDisplayGroup = group => {
    return (
      <div key={group.title} className="modal-grid">
        {group.fields.map((field, groupIndex) =>
          renderDisplayField(group, field, groupIndex),
        )}
      </div>
    )
  }

  const calculatedTotal =
    total ?? scores?.reduce((sum, index) => sum + index, 0)

  return (
    <>
      <h6>{title}</h6>
      {scoreGroups.map(group => renderDisplayGroup(group))}
      <h3 className="total">
        <strong>
          {title} Score: {calculatedTotal}
        </strong>
      </h3>
    </>
  )
}

export default function ProgressModal({ open, close, progress }) {
  const classes = useStyles()
  const rxClasses = useRxStyles()
  const allUsers = useSelector(({ users }) => users.byId)

  const currentUser = useCurrentUser()
  const putProgress = usePutProgress()

  const handleClickReviewProgress = () => {
    Promise.resolve(
      putProgress({
        ...progress,
        reviewedAt: new Date(),
        reviewedBy: currentUser._id,
      }),
    )
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      className="progress-modal"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{formatDateString(progress.timeStamp)}</DialogTitle>
      <div className="modal">
        <DialogContent>
          {progress.sideEffects?.length > 0 && (
            <>
              <h6>SIDE EFFECTS</h6>
              <h3>{progress.sideEffects?.join(', ')}</h3>
            </>
          )}
          {progress.comment && (
            <>
              <h6>REMARK</h6>
              <h3>{progress.comment}</h3>
            </>
          )}
          {progress.type.map(type => (
            <ScoreSection
              key={type}
              title={ASSESSMENT_TYPE_DETAILS[type].label}
              scores={progress[ASSESSMENT_TYPE_DETAILS[type].field]}
              scoreLabels={progress[ASSESSMENT_TYPE_DETAILS[type].labelField]}
              scoreGroups={ASSESSMENT_TYPE_DETAILS[type].scoreGroups}
              total={_.get(
                progress,
                ASSESSMENT_TYPE_DETAILS[type].totalField,
                null,
              )}
              deltaTotal={_.get(
                progress,
                ASSESSMENT_TYPE_DETAILS[type].deltaTotalField,
                null,
              )}
            />
          ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="outlined"
            fullWidth
            onClick={handleClickReviewProgress}
            className={clsx(
              classes.button,
              rxClasses.fontSize16,
              rxClasses.fontWeight400,
              classes.green,
            )}
            disabled={Boolean(progress.reviewedBy)}
          >
            {progress.reviewedBy
              ? `This result has been reviewed${
                  allUsers[progress.reviewedBy]
                    ? ` by ${formatFullName(allUsers[progress.reviewedBy])}`
                    : ''
                }`
              : 'I have reviewed the result'}
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              progress.archived = true
              Promise.resolve(putProgress(progress))
              close()
            }}
            className={clsx(
              classes.button,
              rxClasses.fontSize16,
              rxClasses.fontWeight400,
              classes.red,
            )}
          >
            Archive this result
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
