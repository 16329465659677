import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks/useRxStyles'

import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'

export function ArchiveOrDeletePrompt({
  title,
  open,
  setOpen,
  message,
  onArchive,
  onDelete,
  loading,
}) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      title={title}
      dialogContent={
        <Typography className={rxClasses.typography}>{message}</Typography>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
              rxClasses.buttonDelete,
            )}
            onClick={() => {
              setOpen(false)
              onDelete()
            }}
          >
            DELETE
          </TextButton>

          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              setOpen(false)
            }}
          >
            CANCEL
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              setOpen(false)
              onArchive()
            }}
          >
            ARCHIVE
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
