import clsx from 'clsx'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { toTitleCase } from 'utils/formatters'
import { makeStyles } from '@material-ui/core/styles'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(({ spacing, palette }) => ({
  medicationGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: ('4', '1fr'),
    gridTemplateRows: ('auto-fill', '191px'),
    gridGap: spacing(2),
  },
  breadcrumbs: {
    fontSize: '12px!important',
    lineHeight: '16px!important',
  },
  link: {
    color: `${palette.secondaryNavy2.main}!important`,
  },
}))

export default function PGxBreadcrumbs({ startAt = '/' }) {
  const location = useLocation()
  const classes = useStyles()
  const pathNames = location.pathname.split(startAt).filter(x => x)

  const homePath = `${pathNames[0]}${startAt}`
  const childPaths = pathNames[1].split('/').filter(x => x)

  return (
    <Breadcrumbs
      separator=">"
      aria-label="Breadcrumb"
      className={classes.breadcrumbs}
    >
      <Link className={classes.link} component={RouterLink} to={homePath}>
        Report
      </Link>
      {childPaths.map((value, index) => {
        const last = index === childPaths.length - 1
        const to = `${homePath}/${childPaths.slice(0, index + 1).join('/')}`

        return last ? (
          <Typography
            component="span"
            className={clsx(classes.breadcrumbs, classes.link)}
            key={to}
          >
            {toTitleCase(value)}
          </Typography>
        ) : (
          <Link
            className={classes.link}
            component={RouterLink}
            to={to}
            key={to}
          >
            {toTitleCase(value)}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}
