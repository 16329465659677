import { format, differenceInYears, addDays, differenceInDays } from 'date-fns'
import parsePhoneNumberFromString from 'libphonenumber-js'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'

const ADULT_YEARS = 18

export function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (s) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
  })
}

export const formatAddress = (address = {}) => {
  const { address1, address2, city, state, zip } = address

  return compact([
    compact([address1, address2]).join(' '),
    city,
    state,
    zip,
  ]).join(', ')
}

export const formatDate = (date, option = 'MMMM dd, yyyy hh:mm a') => {
  return format(new Date(date), option)
}

export const formatDateString = date => {
  if (!date) {
    return ''
  }

  try {
    const newDate = new Date(date)

    const dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    return new Intl.DateTimeFormat('en-US', dateOptions).format(newDate)
  } catch {
    return 'Invalid date'
  }
}

export const formatDateInMMMDDYYYY = date => {
  if (!date) {
    return ''
  }

  try {
    return format(new Date(date), 'MMMM dd, yyyy')
  } catch {
    return 'Invalid date'
  }
}

export const formatDateInMMDDYYYY = date => {
  if (!date) {
    return ''
  }

  try {
    return format(new Date(date), 'MMM dd, yyyy')
  } catch {
    return 'Invalid date'
  }
}

export const formatDateInMMDD = date => {
  if (!date) {
    return ''
  }

  try {
    return format(new Date(date), 'MM/dd')
  } catch {
    return 'Invalid date'
  }
}

export const formatDateInMMDDYYYYDashed = date => {
  if (!date) {
    return ''
  }

  try {
    return format(new Date(date), 'MM/dd/yyyy')
  } catch {
    return 'Invalid date'
  }
}

export const formatDateWeekday = date => {
  if (!date) {
    return ''
  }

  try {
    return format(new Date(date), 'EEEE')
  } catch {
    return 'Invalid date'
  }
}

export const roundTime = (date = new Date(), coefficient = 30) => {
  const ms = 1000 * 60 * coefficient // convert minutes to ms
  return new Date(Math.round(date.getTime() / ms) * ms)
}

export const formatPhoneNumber = string => {
  let phone

  try {
    phone = parsePhoneNumberFromString(string, 'US').formatNational()
  } catch {
    phone = string
  }

  return phone
}

export const addDaysTODate = (date, daysToAdd) => {
  return addDays(date, daysToAdd)
}

export const formatFullName = (user, reverse = false) => {
  const names = [user?.firstName, user?.lastName]
  const separator = reverse ? ', ' : ' '

  if (reverse) {
    names.reverse()
  }

  return compact(names).join(separator) || '-'
}

export const addTitle = user => {
  const formattedName = formatFullName(user)
  return !isEmpty(user.title)
    ? `${formattedName}, ${user.title}`
    : `${formattedName}`
}

export const formatUserInitials = user =>
  compact([user?.firstName, user?.lastName])
    .map(name => name.slice(0, 1).toUpperCase())
    .join('')

export const formatExternalProviderInitials = name =>
  compact(name.split(' ').map(s => s.trim()))
    .map(name => name.slice(0, 1).toUpperCase())
    .join('')

export const capitalizeEveryWord = word => {
  return word
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const wrapWithParenthesis = sentence => {
  return `(${sentence})`
}

export const formatHeight = (height, abbr = false) => {
  const feet = Math.floor(height / 12)
  const inch = height % 12
  const units = abbr ? ["'", '"'] : [' feet', ' inch']

  return compact([feet, inch])
    .map((value, index) => `${value}${units[index]}`)
    .join(' ')
}

export const formatAge = dob =>
  dob ? differenceInYears(new Date(), new Date(dob)) : 'N/A'

export const formatGender = value => value || 'Not entered'

export const formatWeight = value => `${Math.floor(value)} lbs`

export const formatPronoun = value => value || 'Not entered'

export const formatExternalProvider = externalProvider => {
  if (!externalProvider) {
    return ''
  }

  return `${externalProvider.name} (${externalProvider.type}, ${externalProvider.zip})`
}

export const daysBetween = (endDate, startDate) => {
  return differenceInDays(new Date(endDate), new Date(startDate))
}

export const isAdult = (leftDate, rightDate) =>
  differenceInYears(new Date(leftDate), new Date(rightDate)) >= ADULT_YEARS

export function getDaysSupplyDate(rx) {
  return rx.DaysSupply
    ? formatDateInMMDDYYYY(
        addDaysTODate(new Date(rx.WrittenDate), rx.DaysSupply),
      )
    : null
}
