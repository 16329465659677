import clsx from 'clsx'
import { makeStyles, Box, Typography } from '@material-ui/core'

import { useRxStyles } from 'hooks'

const useStyles = makeStyles(({ spacing, palette }) => ({
  statusWrapper: {
    display: 'flex',
    padding: spacing(0, 3, 1),
    alignItems: 'center',
  },
  status: {
    color: palette.text.primary,
    fontWeight: 400,
    flexGrow: 1,
  },
}))

const ProgressSubheader = ({ content }) => {
  const classes = useStyles()
  const rxClasses = useRxStyles()

  return (
    <Box className={classes.statusWrapper}>
      <Typography
        className={clsx(
          classes.status,
          rxClasses.fontSize16,
          rxClasses.textAlignCenter,
          rxClasses.secondaryNavy2,
        )}
      >
        {content}
      </Typography>
    </Box>
  )
}

export default ProgressSubheader
