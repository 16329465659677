import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  progressesLoading,
  progressesFailed,
  updateProgress,
} from 'redux/progresses'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePutProgress() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const putProgress = useCallback(
    async progress => {
      dispatch(progressesLoading())

      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/progress/${progress._id}`,
        options: {
          method: HTTP_METHODS.PUT,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(progress),
        },
      })

      if (response.ok) {
        const result = await response.json()
        dispatch(updateProgress(result))
      } else {
        dispatch(progressesFailed('Failed to update progress'))
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return putProgress
}
