import { MemberEmployments } from 'constants/selectOptions'
import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'employment',
  label: 'Employment',
  componentProps: {
    select: true,
    options: MemberEmployments,
  },
}

const EmploymentSection = props => (
  <SingleFieldSection {...props} title="Employment" field={FIELD} />
)

export default withMemberForm('EmploymentSectionForm', ['employment'])(
  EmploymentSection,
)
