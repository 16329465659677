import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'cultureBackground',
  label: 'Cultural Background',
  componentProps: {
    multiline: true,
  },
}

const CulturalBackgroundSection = props => (
  <SingleFieldSection {...props} title="Cultural Background" field={FIELD} />
)

export default withMemberForm('CulturalBackgroundSectionForm', [
  'cultureBackground',
])(CulturalBackgroundSection)
