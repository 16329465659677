import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { isPast } from 'date-fns'
import { isEmpty, isNil, isUndefined } from 'lodash/fp'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateMember } from 'redux/members'
import usePutMember from 'hooks/api/members/usePutMember'
import { useLayoutStyles, useRxStyles } from 'hooks'
import { formatDateInMMDDYYYYDashed, toTitleCase } from 'utils/formatters'
import {
  indexMap,
  formatPayer,
  STRIPE_LINK,
  updateInsurances,
} from 'utils/insurance'
import { API_BASE_URL } from 'constants/api'
import useDeleteRequest from 'hooks/api/useDeleteRequest'
import useGetRequest from 'hooks/api/useGetRequest'
import usePostRequest from 'hooks/api/usePostRequest'
import usePutRequest from 'hooks/api/usePutRequest'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import BaseCard from 'components/Shared/Card/BaseCard'
import CardHeader from 'components/Shared/Card/CardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import UpdateStripeIdPrompt from 'components/Member/MemberBilling/UpdateStripeIdPrompt'
import AddInsuranceDialog from 'components/Member/MemberBilling/AddInsuranceDialog'
import InsuranceCoverageMenu from 'components/Member/MemberBilling/InsuranceCoverageMenu'
import ViewInsuranceDialog from 'components/Member/MemberBilling/ViewInsuranceDialog'
import EditInsuranceDialog from 'components/Member/MemberBilling/EditInsuranceDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  button: {
    cursor: 'pointer',
  },
  columnPadding: {
    padding: '8px 16px',
  },
  chip: {
    backgroundColor: palette.distinctiveGray.main,
    padding: '8px 4px',
  },
  primaryChip: {
    color: '#fff',
    backgroundColor: palette.primary.main,
    padding: '8px 4px',
  },
  highlightRed: {
    color: palette.accentRed.main,
  },
}))

export default function MemberBilling({ member }) {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const putMember = usePutMember()
  const deleteRequest = useDeleteRequest()
  const getRequest = useGetRequest()
  const postRequest = usePostRequest()
  const putRequest = usePutRequest()
  const layoutClasses = useLayoutStyles()
  const rxClasses = useRxStyles()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [updated, setUpdated] = useState(null)

  const [stripeLink, setStripeLink] = useState(null)
  const [updateStripeModal, openUpdateStripeModal] = useState(false)
  const [addInsuranceModal, openAddInsuranceModal] = useState(false)
  const [viewInsuranceModal, openViewInsuranceModal] = useState(false)
  const [editInsuranceModal, openEditInsuranceModal] = useState(false)
  const [activeInsurance, setActiveInsurance] = useState(null)

  const fetchInsuranceData = useCallback(
    async ({ memberID }) => {
      try {
        setLoading(true)
        const insuranceData = await getRequest({
          url: `${API_BASE_URL}/cp/insurance/all/${memberID}`,
        })
        setResponse(insuranceData)
      } catch (error) {
        setResponse(undefined)
      } finally {
        setUpdated(false)
        setLoading(false)
      }
    },
    [getRequest],
  )

  useEffect(() => {
    if (updated === null || updated) {
      fetchInsuranceData({
        memberID: member._id,
      })
    }
  }, [updated, fetchInsuranceData, member._id])

  const hasStripeID = member?.stripeID.length !== 0

  const addInsurance = async data => {
    try {
      const body = {
        payerId: data.payer._id,
        ...data,
      }
      setLoading(true)
      const newInsurance = await postRequest({
        url: `${API_BASE_URL}/cp/insurance/${member._id}`,
        body,
        isFormData: true,
      })

      await dispatch(
        updateMember({
          ...member,
          insurances: [
            ...member.insurances,
            {
              payer: newInsurance.payer,
              coverageIndex: newInsurance.coverageIndex,
            },
          ],
        }),
      )

      enqueueSnackbar('Insurance coverage added', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Failed to add insurance coverage', {
        variant: 'error',
      })
    } finally {
      setUpdated(true)
    }
  }

  const changeIndex = async insurance => {
    try {
      setLoading(true)
      const updatedInsurances = await putRequest({
        url: `${API_BASE_URL}/cp/insurance/change-index/${member._id}`,
        body: insurance,
      })

      await dispatch(
        updateMember({
          ...member,
          insurances: updatedInsurances,
        }),
      )

      enqueueSnackbar('Insurance position updated', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Failed to update insurance position', {
        variant: 'error',
      })
    } finally {
      setUpdated(true)
    }
  }

  const editInsuranceCoverage = async incomingFormFields => {
    try {
      const body = {
        payerId: incomingFormFields.payer._id,
        ...incomingFormFields,
      }

      setLoading(true)
      const updatedInsurance = await putRequest({
        url: `${API_BASE_URL}/cp/insurance/update/${incomingFormFields._id}`,
        body,
        isFormData: true,
      })

      const updatedInsurances = updateInsurances(
        member.insurances,
        updatedInsurance,
      )

      await dispatch(
        updateMember({
          ...member,
          insurances: updatedInsurances,
        }),
      )

      enqueueSnackbar('Insurance coverage updated', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Failed to update insurance coverage', {
        variant: 'error',
      })
    } finally {
      setUpdated(true)
    }
  }

  const deleteInsurance = async insuranceId => {
    try {
      setLoading(true)
      const result = await deleteRequest({
        url: `${API_BASE_URL}/cp/insurance/${member._id}&${insuranceId}`,
      })

      await dispatch(
        updateMember({
          ...member,
          insurances: result,
        }),
      )

      enqueueSnackbar('Insurance coverage deleted', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Failed to delete insurance coverage', {
        variant: 'error',
      })
    } finally {
      setUpdated(true)
    }
  }

  async function updateStripeID(id) {
    try {
      setLoading(true)
      await putMember({
        _id: member._id,
        stripeID: id,
      })
    } catch (e) {
      enqueueSnackbar('Failed to update stripe id', {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  function InsuranceRows({ insurances }) {
    if (isEmpty(insurances)) {
      return <BaseRow withDivider />
    }

    return insurances
      .sort((a, b) => (a.coverageIndex > b.coverageIndex ? 1 : -1))
      .map((insurance, index, allInsurances) => {
        const insuranceId = insurance._id
        const position = indexMap[insurance.coverageIndex]
        const positionDefined = !isNil(position) || !isUndefined(position)
        const isPrimary = insurance.coverageIndex === 0 ? true : false
        const formattedPayer = formatPayer(insurance.payer, insurance.planName)
        const expired = insurance.planEndDate
          ? isPast(new Date(insurance.planEndDate))
          : false
        const planEndDate = insurance.planEndDate
          ? formatDateInMMDDYYYYDashed(insurance.planEndDate)
          : 'N/A'

        return (
          <BaseRow
            withDivider
            key={`${insurance.coverageIndex}`}
            columns={
              <Grid container alignItems="center" justifyContent="space-evenly">
                <Grid item xs={2} className={classes.columnPadding}>
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(rxClasses.typography, rxClasses.fontSize12)}
                  >
                    {positionDefined && (
                      <Chip
                        label={`${toTitleCase(position)}`}
                        className={clsx({
                          [rxClasses.typography]: true,
                          [rxClasses.fontSize10]: true,
                          [classes.primaryChip]: isPrimary,
                          [classes.chip]: !isPrimary,
                        })}
                        size="small"
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.columnPadding}>
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(rxClasses.typography, rxClasses.fontSize12)}
                  >
                    <strong>{formattedPayer}</strong>
                  </Typography>
                </Grid>
                <Grid item xs className={classes.columnPadding}>
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(rxClasses.typography, rxClasses.fontSize12)}
                  >
                    ID: {insurance.idNumber}
                  </Typography>
                </Grid>
                <Grid item xs className={classes.columnPadding}>
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx({
                      [rxClasses.typography]: true,
                      [rxClasses.fontSize12]: true,
                      [classes.highlightRed]: expired,
                    })}
                  >
                    Exp: {planEndDate}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={clsx(
                    classes.columnPadding,
                    layoutClasses.textAlignRight,
                  )}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      classes.button,
                      rxClasses.secondaryMint,
                      rxClasses.typography,
                      rxClasses.fontSize12,
                    )}
                    onClick={() => {
                      setActiveInsurance(insurance)
                      openViewInsuranceModal(true)
                    }}
                  >
                    View Detail
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={clsx(
                    classes.columnPadding,
                    layoutClasses.textAlignRight,
                  )}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(rxClasses.typography, rxClasses.fontSize12)}
                  >
                    <InsuranceCoverageMenu
                      insuranceId={insuranceId}
                      allInsurances={allInsurances}
                      currentIndex={index}
                      onIndexChange={changeIndex}
                      onEdit={() => {
                        setActiveInsurance(insurance)
                        openEditInsuranceModal(true)
                      }}
                      onDelete={deleteInsurance}
                    />
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        )
      })
  }

  const insuranceTitle = 'Insurance'
  const stripeTitle = 'Stripe ID'
  const availityUrl =
    'https://apps.availity.com/availity/web/public.elegant.login'

  useEffect(() => {
    if (hasStripeID) {
      setStripeLink(STRIPE_LINK(member.stripeID))
    }
  }, [member, hasStripeID])

  if (loading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={clsx(layoutClasses.pt1, 'member-billing-container')}>
      {response && (
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12}>
            <BaseCard>
              <CardHeader title={insuranceTitle}>
                <Link to={{ pathname: availityUrl }} target="_blank">
                  <Typography
                    variant="body1"
                    className={clsx(
                      rxClasses.typography,
                      rxClasses.buttonAction,
                      rxClasses.fontSize12,
                      layoutClasses.pr2,
                    )}
                    display="inline"
                  >
                    Open Availity
                  </Typography>
                </Link>
                <Typography
                  variant="body1"
                  className={clsx(
                    rxClasses.textAlignRight,
                    rxClasses.typography,
                    rxClasses.buttonAction,
                    rxClasses.fontSize12,
                  )}
                  onClick={() => {
                    openAddInsuranceModal(true)
                  }}
                  display="inline"
                >
                  Add Coverage
                </Typography>
              </CardHeader>
              <InsuranceRows insurances={response} />
            </BaseCard>

            {addInsuranceModal && (
              <AddInsuranceDialog
                title={'Add Insurance Coverage'}
                loading={loading}
                open={addInsuranceModal}
                setOpen={openAddInsuranceModal}
                handleClose={() => {
                  openAddInsuranceModal(false)
                }}
                onAddInsurance={addInsurance}
              />
            )}

            {viewInsuranceModal && activeInsurance && (
              <ViewInsuranceDialog
                insurance={activeInsurance}
                handleClose={() => {
                  openViewInsuranceModal(false)
                }}
                setOpen={openViewInsuranceModal}
                open={viewInsuranceModal}
                title={'View Insurance Coverage'}
              />
            )}

            {editInsuranceModal && activeInsurance && (
              <EditInsuranceDialog
                insurance={activeInsurance}
                onEditInsurance={editInsuranceCoverage}
                handleClose={() => {
                  openEditInsuranceModal(false)
                }}
                setOpen={openEditInsuranceModal}
                open={editInsuranceModal}
                title={'Edit Insurance Coverage'}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <BaseCard>
              <CardHeader title={stripeTitle}>
                {stripeLink && (
                  <Link to={{ pathname: stripeLink }} target="_blank">
                    <Typography
                      variant="body1"
                      className={clsx(
                        rxClasses.typography,
                        rxClasses.buttonAction,
                        rxClasses.fontSize12,
                        layoutClasses.pr2,
                      )}
                      display="inline"
                    >
                      Open Stripe Dashboard
                    </Typography>
                  </Link>
                )}

                <Typography
                  variant="body1"
                  className={clsx(
                    rxClasses.textAlignRight,
                    rxClasses.typography,
                    rxClasses.buttonAction,
                    rxClasses.fontSize12,
                  )}
                  display="inline"
                  onClick={() => {
                    openUpdateStripeModal(true)
                  }}
                >
                  Update Stripe ID
                </Typography>
              </CardHeader>
              <BaseRow
                withDivider
                title={stripeTitle}
                rowIndex={0}
                columns={
                  hasStripeID && (
                    <Grid item xs className={classes.columnPadding}>
                      <Typography
                        component="span"
                        variant="body1"
                        className={clsx(
                          rxClasses.typography,
                          rxClasses.fontSize12,
                        )}
                      >
                        {member.stripeID}
                      </Typography>
                    </Grid>
                  )
                }
              />
            </BaseCard>

            {updateStripeModal && (
              <UpdateStripeIdPrompt
                title={'Update Stripe ID'}
                loading={loading}
                open={updateStripeModal}
                setOpen={openUpdateStripeModal}
                handleClose={() => {
                  openUpdateStripeModal(false)
                }}
                onUpdate={updateStripeID}
              />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  )
}
