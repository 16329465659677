import { getIn } from 'formik'

import DatePicker from './DatePicker'

const FormikDatePicker = ({ field, form: { errors }, ...props }) => {
  const error = getIn(errors, field.name)

  return <DatePicker {...field} error={!!error} helperText={error} {...props} />
}

export default FormikDatePicker
