import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePostComment() {
  const { fetchWithRefresh } = useGoogleAuth()

  const postComment = useCallback(
    async ({ comment, categorization, member }) => {
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/comments`,
        options: {
          method: HTTP_METHODS.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            comment,
            categorization,
            feedMemberID: member._id,
          }),
        },
      })

      if (response.ok) {
        await response.json()
      } else {
        throw new Error('Failed to post comment')
      }
    },
    [fetchWithRefresh],
  )

  return postComment
}
