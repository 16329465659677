import { useCallback, useEffect } from 'react'
import debounce from 'lodash.debounce'

export const Autosave = ({
  data,
  handleSaveData,
  handleFailure,
  saveInProgress = false,
  setTimeOfLastSave,
}) => {
  const DEBOUNCE_SAVE_DELAY_MS = 3000

  const debouncedSave = useCallback(
    debounce(async data => {
      const autosave = true
      try {
        await handleSaveData(data, autosave)
        setTimeOfLastSave(Date.now())
      } catch (error) {
        setTimeOfLastSave(null)
        handleFailure(error.message)
      }
    }, DEBOUNCE_SAVE_DELAY_MS),
    [],
  ) // eslint-disable-line

  const cancelDebouncedSave = useCallback(debouncedSave.cancel, [debouncedSave])

  useEffect(() => {
    if (saveInProgress) {
      cancelDebouncedSave()
    }
    if (data && !saveInProgress) {
      debouncedSave(data)
    }
    return () => {
      cancelDebouncedSave()
    }
  }, [saveInProgress, data, debouncedSave]) // eslint-disable-line

  return null
}
