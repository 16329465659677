import { Field, FieldArray } from 'formik'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { getFormikComponent } from 'utils/formik'

const useStyles = makeStyles(({ palette }) => ({
  btnDelete: {
    color: palette.accentRed.main,
  },
}))

const ArrayFieldSectionForm = ({
  path,
  rows,
  fields,
  rowTitle,
  defaultRow,
}) => {
  const classes = useStyles()

  return (
    <FieldArray name={path}>
      {({ push, remove }) => (
        <>
          {rows.map((row, index) => (
            <Box key={index} mb={2}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">
                  {rowTitle} {index + 1}
                </Typography>

                <Button
                  variant="text"
                  classes={{ label: classes.btnDelete }}
                  onClick={() => {
                    remove(index)
                  }}
                >
                  Delete
                </Button>
              </Box>

              {fields.map(field => {
                const component = getFormikComponent(field.type)

                return (
                  <Box key={field.path} mb={2}>
                    <Field
                      name={`${path}.${index}.${field.path}`}
                      component={component}
                      label={field.label}
                      {...field.componentProps}
                    />
                  </Box>
                )
              })}
            </Box>
          ))}

          <Button
            variant="text"
            color="primary"
            onClick={() => {
              push(defaultRow)
            }}
          >
            Add
          </Button>
        </>
      )}
    </FieldArray>
  )
}

export default ArrayFieldSectionForm
