import { formatFullName } from 'utils/formatters'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  avatar: {
    width: spacing(3),
    height: spacing(3),
  },
}))

const SuggestedMention = ({ user }) => {
  const classes = useStyles()
  const fullName = formatFullName(user)
  const title = user.title

  const getTitles = titles => {
    return titles.join(', ')
  }

  return (
    <div className="member-feed-suggested-mention">
      <Avatar
        src={user?.profilePic}
        alt={fullName}
        className={classes.avatar}
      />
      <div className="member-feed-suggested-mention-label">
        {`${fullName}${title.length > 0 ? `, ${getTitles(title)}` : ''}`}
      </div>
    </div>
  )
}

export default SuggestedMention
