import { Link } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import { useLayoutStyles } from 'hooks'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import MedicationCard from 'components/Member/MemberPGx/MedicationCard'
import PGxSnackbar from 'components/Member/MemberPGx/PGxSnackbar'

const useStyles = makeStyles({
  border: {
    border: props => `1px solid ${props.color}`,
  },
  item: {
    flexGrow: 0,
    marginRight: '16px',
    paddingBottom: '16px',
  },
})

export default function PGxMedicationList(props) {
  const match = useRouteMatch()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const classes = useStyles(props)
  return (
    <div>
      <Grid
        container
        alignItems="stretch"
        spacing={2}
        className={layoutClasses.mb2}
      >
        <Grid item xs={12}>
          <PGxSnackbar
            color={props.color}
            backgroundColor={props.backgroundColor}
            type={props.type}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            {props.medications &&
              props.medications.map(medication => {
                return (
                  <Grid key={medication.Drug} className={classes.item} item xs>
                    <Link
                      to={{
                        pathname: `${match.url}/${medication.Drug}`,
                      }}
                    >
                      <MedicationCard
                        color={props.color}
                        medication={medication}
                        className={classes.border}
                      />
                    </Link>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
