import { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'

import {
  States,
  MemberEmergencyContactRelationships,
} from 'constants/selectOptions'
import { formatPhoneNumber, formatAddress } from 'utils/formatters'

import withMemberForm from 'HOCs/withMemberForm'

import BaseSection from 'components/Shared/Sections/BaseSection'
import SectionField from 'components/Shared/Sections/SectionField'
import MultiFieldsSectionForm from 'components/Shared/Sections/SectionForms/MultiFieldsSectionForm'

const FIELDS = [
  {
    path: 'email',
    label: 'Email',
  },
  {
    path: 'phone',
    label: 'Phone',
  },
  {
    path: 'shippingAddress.address1',
    label: 'Shipping Address 1',
  },
  {
    path: 'shippingAddress.address2',
    label: 'Shipping Address 2',
  },
  {
    path: 'shippingAddress.city',
    label: 'Shipping City',
  },
  {
    path: 'shippingAddress.state',
    label: 'Shipping State',
    componentProps: {
      select: true,
      options: States,
    },
  },
  {
    path: 'shippingAddress.zip',
    label: 'Shipping Zip',
  },
  {
    path: 'billingAddress.address1',
    label: 'Billing Address 1',
  },
  {
    path: 'billingAddress.address2',
    label: 'Billing Address 2',
  },
  {
    path: 'billingAddress.city',
    label: 'Billing City',
  },
  {
    path: 'billingAddress.state',
    label: 'Billing State',
    componentProps: {
      select: true,
      options: States,
    },
  },
  {
    path: 'billingAddress.zip',
    label: 'Billing Zip',
  },
  {
    path: 'emergencyContact.name',
    label: 'Emergency Contact Name',
  },
  {
    path: 'emergencyContact.relationship',
    label: 'Emergency Contact Relationship',
    componentProps: {
      select: true,
      options: MemberEmergencyContactRelationships,
    },
  },
  {
    path: 'emergencyContact.phone',
    label: 'Emergency Contact Phone',
  },
  {
    path: 'emergencyContact.email',
    label: 'Emergency Contact Email',
  },
]

function ContactInfoCard({ member }) {
  const renderForm = useCallback(
    () => <MultiFieldsSectionForm fields={FIELDS} />,
    [],
  )

  return (
    <BaseSection
      isCard
      title="Contact Information"
      successMessage="Contact information was updated"
      renderForm={renderForm}
    >
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <SectionField label="Mobile">
            {formatPhoneNumber(member.phone)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Shipping Address">
            {formatAddress(member.shippingAddress)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Email">{member.email}</SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Billing Address">
            {formatAddress(member.billingAddress)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Emergency Contact">
            {member.emergencyContact?.name} (
            {member.emergencyContact?.relationship})
            <br />
            {formatPhoneNumber(member.emergencyContact?.phone)}
            <br />
            {member.emergencyContact?.email}
          </SectionField>
        </Grid>
      </Grid>
    </BaseSection>
  )
}

export default withMemberForm('ContactInfoCardForm', [
  'email',
  'phone',
  'shippingAddress',
  'billingAddress',
  'emergencyContact',
])(ContactInfoCard)
