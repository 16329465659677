import { makeStyles, Chip, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { useRxStyles } from 'hooks'

const useStyles = makeStyles(({ spacing, palette }) => ({
  chip: {
    marginRight: spacing(1),
    border: 0,
  },
  increase: {
    color: palette.accentRed.main,
    backgroundColor: '#FEDEDE',
  },
  decrease: {
    color: palette.primary.main,
    backgroundColor: '#DAEFED',
  },
  same: {
    color: palette.warning.dark,
    backgroundColor: palette.backgroundYellow2.main,
  },
}))

const ProgressChip = ({
  label,
  tooltip,
  value,
  color = '#183F4F',
  backgroundColor = '#F2F2F2',
}) => {
  const classes = useStyles()
  const rxClasses = useRxStyles()

  return (
    <Tooltip title={<Typography variant="body1">{tooltip}</Typography>}>
      <Chip
        variant="outlined"
        className={clsx(
          classes.chip,
          rxClasses.fontSize12,
          rxClasses.fontWeight400,
          {
            [classes.increase]: value > 0,
            [classes.decrease]: value < 0,
            [classes.same]: value === 0,
          },
        )}
        label={label}
        style={{ color, backgroundColor }}
      />
    </Tooltip>
  )
}

export default ProgressChip
