import { useHistory } from 'react-router-dom'
import { MemberMenuItems } from 'constants/member'
import Encounter from 'components/Member/MemberEncounters/Encounter'

export default function EncountersList({ encounters, member }) {
  const history = useHistory()

  const handleEncounterCardClick = encounter => {
    const baseRoute = `/members/${encounter.memberID}`
    const encounterId = encounter._id

    const route = `${baseRoute}/${MemberMenuItems.ENCOUNTERS}/${encounterId}`
    history.push(route)
  }

  return (
    <div className="encounters-list">
      {encounters ? (
        encounters.map((encounter, index) => {
          return (
            <div
              onClick={() => handleEncounterCardClick(encounter)}
              key={index}
              style={{ paddingTop: '8px' }}
            >
              <Encounter encounter={encounter} member={member} />
            </div>
          )
        })
      ) : (
        <span>No encounters</span>
      )}
    </div>
  )
}
