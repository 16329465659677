import './MemberFeed.css'
import { usePostComment } from 'hooks/api/comments'
import MemberFeedAddComment from 'components/Member/MemberFeed/MemberFeedAddComment'
import Comment from 'components/Member/MemberFeed/Comment'
import useComments from 'components/Notifications/useComments.js'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useState, useEffect } from 'react'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    padding: 0,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 320,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.common.white,
    borderLeft: `1px solid ${palette.distinctiveGray.main}`,
  },
  feed: {
    overflowY: 'scroll',
    flexGrow: 1,
    padding: spacing(2),
  },
  inputBox: {
    marginTop: 'auto',
    flexGrow: 1,
  },
  title: { marginBottom: 24, color: palette.text.primary },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}))

const MemberFeed = ({ member }) => {
  const classes = useStyles()
  const memberID = member._id
  const snackbar = useSnackbar()
  const { comments, hasMore, loadMore, refreshFeed, refetch } = useComments({
    memberID,
    reverse: true,
  })
  const postComment = usePostComment()
  const [scrollHeight, setScrollHeight] = useState(800)
  const [windowZoom, setWindowZoom] = useState(1)

  const handleCommentSubmit = async comment => {
    try {
      await postComment({ comment, categorization: 'Encounters', member })
      refetch()
    } catch (err) {
      snackbar.enqueueSnackbar(err.message, { variant: 'error' })
    } finally {
      refreshFeed()
    }
  }

  const getScrollHeight = () => {
    const container = document.getElementById('scroll-container')
    return container.clientHeight ?? 800
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      const browserZoomLevel = (window.outerWidth - 8) / window.innerWidth
      setWindowZoom(browserZoomLevel)
    })
  }, [])

  useEffect(() => {
    setScrollHeight(getScrollHeight())
  }, [windowZoom])

  return (
    <div className={clsx(classes.root)}>
      <div id="scroll-container" className={classes.feed}>
        <div
          id="scroll-target"
          className="member-feed-comments"
          style={{
            height: scrollHeight,
            overflowY: 'scroll',
          }}
        >
          <InfiniteScroll
            dataLength={comments.length}
            next={loadMore}
            hasMore={hasMore}
            loading={true}
            loader={
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            }
            scrollableTarget="scroll-target"
          >
            <Typography className={classes.title} component="h2">
              Feed
            </Typography>
            {comments.map((comment, idx) => (
              <Comment key={`comment-feed-${idx}`} comment={comment} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <MemberFeedAddComment onSubmit={handleCommentSubmit} />
    </div>
  )
}

export default MemberFeed
