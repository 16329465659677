import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Redirect, useParams, useHistory } from 'react-router-dom'

import useGetRequest from 'hooks/api/useGetRequest'
import { API_BASE_URL } from 'constants/api'

import { useLayoutStyles, useRxStyles } from 'hooks'
import {
  getColors,
  getMedicationInformation,
  getGeneInformation,
  getLiteratureReferences,
} from 'utils/pgx'

import { useTheme } from '@material-ui/core/styles'
import { MemberMenuItems } from 'constants/member'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import PGxBreadcrumbs from 'components/Member/MemberPGx/PGxBreadcrumbs'
import PGxSnackbar from 'components/Member/MemberPGx/PGxSnackbar'
import InfoCard from 'components/Member/MemberPGx/InfoCard'

export default function PGxMedicationInfo({ member }) {
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const rxClasses = useRxStyles(theme)

  const params = useParams()
  const history = useHistory()
  const getRequest = useGetRequest()

  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState(null)

  useEffect(() => {
    async function getReport(kitNumber, memberID) {
      try {
        setLoading(true)
        const result = await getRequest({
          url: `${API_BASE_URL}/cp/pgx/${memberID}&${kitNumber}`,
        })
        setReport(result.report)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }

    if (member.kitNumber) {
      getReport(member.kitNumber, member._id)
    } // eslint-disable-next-line
  }, [])

  if (loading && !report) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  if (!member.kitNumber && !report) {
    return <Redirect to={`/members/${member._id}/${MemberMenuItems.PGX}`} />
  }

  const medicationInformation = getMedicationInformation({
    medications: report.Medications,
    lookupValue: params.medication,
  })

  const { color, backgroundColor } = getColors(medicationInformation.Section)

  const Genes = props => {
    return props.genes.map(item => {
      const geneInformation = getGeneInformation({
        genes: props.pharmacogenetics,
        lookupValue: item.Gene,
      })

      return (
        <BaseRow
          key={`${geneInformation.Gene}`}
          columns={
            <Grid container alignItems="center" justifyContent="space-evenly">
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(rxClasses.typography, rxClasses.fontSize16)}
                >
                  {geneInformation.Gene}
                </Typography>
              </Grid>
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(rxClasses.typography, rxClasses.fontSize16)}
                >
                  {geneInformation.Genotype}
                </Typography>
              </Grid>
              <Grid item xs={4} className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(rxClasses.typography, rxClasses.fontSize16)}
                >
                  {geneInformation.Phenotype}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )
    })
  }

  const References = props => {
    const { references, citations } = props

    if (!references) {
      return <BaseRow emptyPlaceholder="No references available" />
    }

    return props.references.map(item => {
      const referenceInformation = getLiteratureReferences({
        citations,
        lookupValue: item,
      })

      return (
        <BaseRow
          key={`${item}`}
          columns={
            <Grid container alignItems="center" justifyContent="space-evenly">
              <Grid item xs={11} className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(rxClasses.typography, rxClasses.fontSize12)}
                >
                  {referenceInformation.Citation}
                </Typography>
              </Grid>
              <Grid item xs className={rxClasses.columnPadding}>
                <Link href={`${referenceInformation.Link}`} target="_blank">
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      rxClasses.typography,
                      rxClasses.fontSize16,
                      rxClasses.textAlignRight,
                    )}
                  >
                    Link
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          }
        />
      )
    })
  }

  const redirectOnClick = ({ to }) => {
    history.push(to)
  }

  return (
    <div className={clsx(layoutClasses.pt1, 'member-pgx-container')}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-evenly"
        className={layoutClasses.mb4}
      >
        <Grid item xs={12}>
          <Typography
            className={layoutClasses.mb2}
            component="span"
            variant="h3"
            display="block"
          >
            Medication Analysis for {medicationInformation.Drug} (
            {medicationInformation.BrandName})
          </Typography>
          <PGxBreadcrumbs startAt={MemberMenuItems.PGX} />
        </Grid>
        <Grid item xs={12}>
          <PGxSnackbar
            color={color}
            backgroundColor={backgroundColor}
            type={medicationInformation.Section}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoCard
            title="Recommendation"
            subTitle={`${medicationInformation.Recommendation}`}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseCard>
            <BaseCardHeader
              title={<span className={rxClasses.fontSize18}>Genotype</span>}
              actionOnClick={() => {
                redirectOnClick({
                  to: `/members/${member._id}/${MemberMenuItems.PGX}/genotype`,
                })
              }}
              actionText={'See full genotype'}
            />
            <Genes
              genes={medicationInformation.Genes}
              pharmacogenetics={report.Pharmacogenetics}
            />
            <BaseRow
              columns={
                <Grid container>
                  <Grid item xs className={rxClasses.columnPadding}>
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(
                        rxClasses.typography,
                        rxClasses.fontSize16,
                      )}
                    >
                      {medicationInformation.Interpretation}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </BaseCard>
        </Grid>
        <Grid item xs={12}>
          <BaseCard>
            <BaseCardHeader title="Literature" />
            <References
              references={medicationInformation.References}
              citations={report.References}
            />
          </BaseCard>
        </Grid>
      </Grid>
    </div>
  )
}
