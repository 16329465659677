import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useFormFields, useMemberRx } from 'hooks'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'
import MenuItem from '@material-ui/core/MenuItem'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks/useRxStyles'
import BaseDialog from 'components/Shared/BaseDialog'
import TextInput from 'ui/TextInput'
import TextButton from 'components/Shared/TextButton'
import { STATE_NAMES } from 'constants/rx'

const pharmacyTypes = [
  { rId: 1, label: 'Mail', value: 1 },
  { rId: 2, label: 'Retail', value: 8 },
]

export default function SearchForm({ open, handleClose, onResultsChange }) {
  const { userId } = useMemberRx()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const postRequest = usePostRequest()
  const [searchResults, setResults] = useState(null)
  const [loading, setIsLoading] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    pharmacyName: '',
    pharmacyAddress: '',
    pharmacyCity: '',
    pharmacyState: '',
    pharmacyZip: '',
    pharmacyPhone: '',
    pharmacyType: '',
  })

  useEffect(() => {
    if (searchResults) {
      onResultsChange(searchResults)
    }
  }, [searchResults, onResultsChange])

  function validateForm() {
    return (
      fields.pharmacyName.length > 0 ||
      fields.pharmacyZip.length > 0 ||
      fields.pharmacyCity.length > 0 ||
      fields.pharmacyPhone.length > 0
    )
  }

  async function search({ body, params: { userId } }) {
    setIsLoading(true)
    const result = await postRequest({
      url: `${API_BASE_URL}/dosespot/pharmacies/search/${userId}`,
      body,
    })
    setResults(result)
    setIsLoading(false)
  }

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Add Pharmacy"
      dialogContent={
        <>
          {searchResults && searchResults.length === 0 && (
            <span>There are no results found for your search term</span>
          )}
          <TextInput
            id="pharmacyName"
            fullWidth
            variant="outlined"
            label="Pharmacy Name"
            name="pharmacyName"
            value={fields.pharmacyName}
            onChange={handleFieldChange}
          />
          <TextInput
            id="pharmacyAddress"
            fullWidth
            variant="outlined"
            label="Pharmacy Address"
            name="pharmacyAddress"
            value={fields.pharmacyAddress}
            onChange={handleFieldChange}
          />
          <TextInput
            id="pharmacyPhone"
            fullWidth
            variant="outlined"
            label="Pharmacy Phone Number"
            name="pharmacyPhone"
            value={fields.pharmacyPhone}
            onChange={handleFieldChange}
          />
          <TextInput
            id="pharmacyCity"
            fullWidth
            variant="outlined"
            label="Pharmacy City"
            name="pharmacyCity"
            value={fields.pharmacyCity}
            onChange={handleFieldChange}
          />
          <TextInput
            id="pharmacyState"
            variant="outlined"
            fullWidth
            select
            name="pharmacyState"
            label="Pharmacy State"
            value={fields.pharmacyState}
            onChange={event => {
              handleFieldChange({
                target: {
                  name: event.target.name,
                  value: event.target.value,
                },
              })
            }}
          >
            {STATE_NAMES.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextInput>

          <TextInput
            id="pharmacyZip"
            fullWidth
            variant="outlined"
            label="Pharmacy Zip Code"
            name="pharmacyZip"
            value={fields.pharmacyZip}
            onChange={handleFieldChange}
          />
          <TextInput
            id="pharmacyType"
            variant="outlined"
            fullWidth
            select
            name="pharmacyType"
            label="Pharmacy Type"
            value={fields.pharmacyType}
            onChange={event => {
              handleFieldChange({
                target: {
                  name: event.target.name,
                  value: event.target.value,
                },
              })
            }}
          >
            {pharmacyTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextInput>
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={handleClose}
          >
            CANCEL
          </TextButton>
          <TextButton
            disabled={!validateForm()}
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() =>
              search({
                body: fields,
                params: { userId },
              })
            }
          >
            SEARCH
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
