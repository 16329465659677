import clsx from 'clsx'
import { isEmpty } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useLayoutStyles } from 'hooks'
import useGetRequest from 'hooks/api/useGetRequest'
import { API_BASE_URL } from 'constants/api'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { MemberMenuItems } from 'constants/member'
import { PRESCRIBING_CONSIDERATIONS } from 'constants/pgx'

import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextInput from 'ui/TextInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import PGxBreadcrumbs from 'components/Member/MemberPGx/PGxBreadcrumbs'
import PGxMedicationList from 'components/Member/MemberPGx/PGxMedicationList'

const useStyles = makeStyles(() => ({
  title: {
    color: '#183f4f',
  },
}))

export default function PGxMedicationAnalysis({ member }) {
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const classes = useStyles(theme)

  const getRequest = useGetRequest()

  const [loading, setLoading] = useState(true)
  const [medications, setMedications] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    async function getReport(kitNumber, memberID) {
      try {
        setLoading(true)
        const result = await getRequest({
          url: `${API_BASE_URL}/cp/pgx/${memberID}&${kitNumber}`,
        })
        setMedications(result.report.Medications)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }

    if (member.kitNumber) {
      getReport(member.kitNumber, member._id)
    }
  }, [member, getRequest])

  const onFilterChange = event => {
    const query = event.target.value.toLowerCase()
    setSearchTerm(query)
  }

  useEffect(() => {
    if (!searchTerm || searchTerm === '') {
      setSearchResults(medications)
    }

    if (medications && !isEmpty(medications)) {
      const filteredMedications = medications.filter(medication => {
        const drugs = medication.Drug.toLowerCase()
        const brands = medication.BrandName.toLowerCase()

        const geneMatch = medication.Genes.filter(gene => {
          const genes = gene.Gene.toLowerCase()
          return genes.includes(searchTerm)
        })

        return (
          drugs.includes(searchTerm) ||
          brands.includes(searchTerm) ||
          !isEmpty(geneMatch)
        )
      })
      setSearchResults(filteredMedications)
    }
  }, [searchTerm, medications])

  if (loading && !medications && !searchResults) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  if (!member.kitNumber && isEmpty(medications)) {
    return <Redirect to={`/members/${member._id}/${MemberMenuItems.PGX}`} />
  }

  const strong = searchResults.filter(
    med => med.Section === PRESCRIBING_CONSIDERATIONS.STRONG,
  )
  const moderate = searchResults.filter(
    med => med.Section === PRESCRIBING_CONSIDERATIONS.MODERATE,
  )
  const usual = searchResults.filter(
    med => med.Section === PRESCRIBING_CONSIDERATIONS.USUAL,
  )

  return (
    <div className={clsx(layoutClasses.pt1, 'member-pgx-container')}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-evenly"
        className={layoutClasses.mb4}
      >
        <Grid item xs={6}>
          <Typography
            className={clsx(layoutClasses.mb1, classes.title)}
            component="span"
            variant="h3"
            display="block"
          >
            Medication Analysis
          </Typography>
          <PGxBreadcrumbs startAt={MemberMenuItems.PGX} />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            style={{ backgroundColor: '#fff' }}
            placeholder="Search medication"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={onFilterChange}
          />
        </Grid>
      </Grid>

      <PGxMedicationList
        color="#eb5757"
        backgroundColor="#FFE9E9"
        medications={strong}
        type={PRESCRIBING_CONSIDERATIONS.STRONG}
      />

      <PGxMedicationList
        color="#D39A06"
        backgroundColor="#F8F4EB"
        medications={moderate}
        type={PRESCRIBING_CONSIDERATIONS.MODERATE}
      />

      <PGxMedicationList
        color="#36bca8"
        backgroundColor="#F1F8EB"
        medications={usual}
        type={PRESCRIBING_CONSIDERATIONS.USUAL}
      />
    </div>
  )
}
