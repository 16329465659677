import { useEffect, useState } from 'react'
import { formatFullName } from 'utils/formatters'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import isEmpty from 'lodash/isEmpty'

export default function MemberFilter({
  name,
  label,
  options,
  defaultValue,
  getOptionLabel,
  tags,
  onFilterChange,
  shouldClear,
}) {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (shouldClear) {
      setValue(defaultValue)
      onFilterChange({ clear: false })
    }
  }, [defaultValue, onFilterChange, shouldClear])

  return (
    <Autocomplete
      key={name}
      multiple
      disableCloseOnSelect
      defaultValue={defaultValue ?? []}
      options={options ?? []}
      getOptionLabel={getOptionLabel}
      value={value}
      renderInput={params => (
        <TextField {...params} variant="outlined" label={label} />
      )}
      onChange={(e, value) => {
        onFilterChange({
          [name]: isEmpty(value) ? defaultValue : value,
        })
        setValue(value)
      }}
      renderTags={options => {
        const formattedOptions = options.map(option => {
          const opts = tags ? formatFullName(tags[option]) : option
          return opts
        })

        return (
          <Typography
            style={{
              paddingLeft: 8,
              fontSize: 14,
            }}
            noWrap={true}
            color="primary"
          >
            {formattedOptions.join(', ')}
          </Typography>
        )
      }}
    />
  )
}
