import { isValid } from 'date-fns'
import { useTheme } from '@material-ui/core/styles'
import TextButton from 'components/Shared/TextButton'
import { TextField } from '@material-ui/core'
import { useFormFields, useRxStyles } from 'hooks'
import { useSelector } from 'react-redux'
import usePutNote from 'hooks/api/encounters/usePutNote'
import DatePicker from 'components/Shared/Forms/Fields/DatePicker'
import TimePicker from 'components/Shared/Forms/Fields/TimePicker'
import { useDispatch } from 'react-redux'
import { notesFailed } from 'redux/notes'
import { useSnackbar } from 'notistack'
import DurationPicker from 'components/Shared/Forms/Fields/DurationPicker'
import { makeStyles } from '@material-ui/core/styles'
import SelectProvider from 'components/Shared/Forms/Fields/SelectProvider'
import BaseDialog from 'components/Shared/BaseDialog'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  disabledField: {
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
    },
  },
}))

function isValidForm(fields) {
  return (
    fields.durationOfEncounter &&
    fields.dateOfEncounter &&
    fields.timeOfEncounter &&
    fields.provider?.length
  )
}

const UpdateEncounterDialog = ({ encounter, open, onClose }) => {
  const classes = useStyles()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const putNote = usePutNote()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const [currentUser] = useSelector(state => {
    return [state.users.currentUser]
  })
  const combineDateAndTime = (date, time) => {
    const dateTime = new Date(date)
    dateTime.setHours(time.getHours())
    dateTime.setMinutes(time.getMinutes())
    return dateTime
  }

  const [fields, handleFieldChange] = useFormFields({
    provider: encounter.userID ?? currentUser._id ?? '',
    typeOfEncounter: encounter.type,
    dateOfEncounter: new Date(encounter.dateCreated),
    timeOfEncounter: new Date(encounter.dateCreated),
    durationOfEncounter: encounter.duration,
  })

  const handleClose = () => {
    onClose()
  }

  const handleSave = async () => {
    const stateNote = {
      ...encounter,
      duration: fields.durationOfEncounter,
      userID: fields.provider ?? currentUser._id,
      dateCreated: combineDateAndTime(
        fields.dateOfEncounter,
        fields.timeOfEncounter,
      ),
    }
    try {
      await putNote(stateNote)
      enqueueSnackbar('Your note draft has been saved', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('There was an error while saving your note.', {
        variant: 'error',
      })
      dispatch(notesFailed(error.message))
    }
  }

  const DialogContent = ({ fields }) => {
    return (
      <>
        <SelectProvider
          id="provider"
          variant="outlined"
          defaultValue={fields.provider}
          onChange={handleFieldChange}
          label="Rendering Provider"
          name="provider"
        />

        <TextField
          disabled
          className={classes.disabledField}
          id="outlined-disabled"
          label={fields.typeOfEncounter}
          variant="outlined"
          margin="normal"
          defaultValue={fields.typeOfEncounter}
          fullWidth
        />

        <DatePicker
          id="dateOfEncounter"
          fullWidth
          variant="outlined"
          label="Date of Encounter"
          name="dateOfEncounter"
          value={fields.dateOfEncounter}
          onChange={handleFieldChange}
        />

        <TimePicker
          id="timeOfEncounter"
          fullWidth
          variant="outlined"
          label="Time of Encounter"
          name="timeOfEncounter"
          value={fields.timeOfEncounter}
          onChange={e => {
            if (isValid(e)) {
              handleFieldChange({
                target: {
                  name: 'timeOfEncounter',
                  value: new Date(e),
                },
              })
            }
          }}
          margin="normal"
          dialog={true}
        />
        <div style={{ height: '55px' }}>
          <DurationPicker
            id="durationOfEncounter"
            name="durationOfEncounter"
            label="Duration of Encounter"
            value={fields.durationOfEncounter}
            onChange={e => {
              handleFieldChange(e)
            }}
            margin="normal"
            variant="outlined"
            defaultValue={fields?.durationOfEncounter ?? null}
            fullWidth
          />
        </div>
      </>
    )
  }

  const DialogActions = () => {
    return (
      <>
        <TextButton
          className={clsx(
            theme.typography.fontWeightMedium,
            rxClasses.fontSize16,
            rxClasses.typography,
          )}
          onClick={handleClose}
        >
          CANCEL
        </TextButton>
        <TextButton
          className={clsx(
            theme.typography.fontWeightMedium,
            rxClasses.fontSize16,
            rxClasses.typography,
          )}
          onClick={handleSave}
          disabled={!isValidForm(fields)}
        >
          UPDATE
        </TextButton>
      </>
    )
  }

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Update Encounter"
      dialogContent={<DialogContent fields={fields} />}
      dialogActions={<DialogActions />}
    />
  )
}

export default UpdateEncounterDialog
