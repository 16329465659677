import { useEffect, useRef } from 'react'

export function useInterval(callback, delay = 1000) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    const intervalDelay = setInterval(tick, delay)
    return () => {
      clearInterval(intervalDelay)
    }
  }, [delay])
}
