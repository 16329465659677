import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useRxStyles } from 'hooks/useRxStyles'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'

import { isAdult } from 'utils/formatters'

import { useDispatch } from 'react-redux'
import { updateMember } from 'redux/members'
import { useTheme } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'

function guard(member) {
  if (!member.gender) {
    throw new Error('Please update gender in Member profile')
  }

  if (!member.phone) {
    throw new Error('Please update contact phone number in Member profile')
  }

  if (!isAdult(new Date(), member.dob)) {
    throw new Error('Member must be over 18 years old')
  }
}

export default function RegisterPatient({ member, userID }) {
  const [loading, setIsLoading] = useState(false)
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const postRequest = usePostRequest()

  const register = async () => {
    try {
      //Other fields are already required for registration
      guard(member)

      setIsLoading(true)
      const patient = await postRequest({
        url: `${API_BASE_URL}/dosespot/patient/register/${userID}&${member._id}`,
        body: member,
      })

      if (!patient.success && patient.message) {
        enqueueSnackbar(patient.message, { variant: 'error' })
      }

      if (patient.doseSpotPatientID) {
        dispatch(updateMember(patient))
      }

      setIsLoading(false)
    } catch (error) {
      enqueueSnackbar(
        'Please verify member is fully onboarded before proceeding',
        { variant: 'error' },
      )
    } finally {
      setIsLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={rxClasses.gridItemSpan2}>
      <BaseCard>
        <BaseCardHeader
          title={'Dosespot Patient Profile'}
          actionOnClick={register}
          actionText={'Create Patient Profile'}
        />
      </BaseCard>
    </div>
  )
}
