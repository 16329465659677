import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import { useFormFields, useRxStyles, useLayoutStyles } from 'hooks'
import UploadInsuranceCard from 'components/Member/MemberBilling/UploadInsuranceCard'
import InsuranceCardTitle from 'components/Member/MemberBilling/InsuranceCard/InsuranceCardTitle'
import InsuranceForm from 'components/Member/MemberBilling/InsuranceForm'
import Grid from '@material-ui/core/Grid'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'

function isValid(fields) {
  return (
    fields.payer &&
    fields.coverageType &&
    fields.idNumber &&
    fields.subscriberRelationship
  )
}

export default function AddInsuranceDialog({
  open,
  handleClose,
  message,
  onAddInsurance,
  loading,
  title,
}) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const layoutClasses = useLayoutStyles(theme)
  const [fields, handleFieldChange] = useFormFields({
    payer: '',
    coverageType: '',
    groupNumber: '',
    idNumber: '',
    planName: '',
    planStartDate: null,
    planEndDate: null,
    subscriberRelationship: '',
    front: {},
    back: {},
  })

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        handleClose(false)
      }}
      title={title}
      dialogContent={
        <>
          <Typography className={clsx(rxClasses.typography, layoutClasses.mb3)}>
            {message ?? ''}
          </Typography>

          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={6}>
              <InsuranceCardTitle side="front" />
              <UploadInsuranceCard
                side="front"
                onFile={file => {
                  handleFieldChange({
                    target: {
                      name: 'front',
                      value: file,
                    },
                  })
                }}
              />
              <InsuranceCardTitle side="back" />
              <UploadInsuranceCard
                side="back"
                onFile={file => {
                  handleFieldChange({
                    target: {
                      name: 'back',
                      value: file,
                    },
                  })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InsuranceForm
                fields={fields}
                handleFieldChange={handleFieldChange}
              />
            </Grid>
          </Grid>
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              handleClose(false)
            }}
          >
            CANCEL
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              handleClose(false)
              onAddInsurance(fields)
            }}
            disabled={!isValid(fields)}
          >
            SAVE
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
