import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import { formatFullName } from 'utils/formatters'

const useStyles = makeStyles(({ palette, spacing }) => ({
  avatar: {
    width: spacing(5),
    height: spacing(5),
  },
  chip: {
    backgroundColor: palette.backgroundGray.main,
    color: palette.text.primary,
    border: 0,
  },
  cc: {
    backgroundColor: palette.backgroundGray.main,
  },
  psych: {
    backgroundColor: palette.backgroundMint2.main,
  },
  therapist: {
    backgroundColor: palette.backgroundYellow2.main,
  },
}))

export default function AvatarChip({ user, className }) {
  const classes = useStyles()
  const fullName = formatFullName(user)
  const userClass = user.userType
  const title = user.title

  const getTitles = titles => {
    return titles.join(', ')
  }
  return (
    <>
      <Chip
        className={clsx(classes.chip, classes[userClass], className)}
        avatar={
          <Avatar
            src={user?.profilePic}
            alt={fullName}
            className={classes.avatar}
          />
        }
        label={`${fullName}${title.length > 0 ? `, ${getTitles(title)}` : ''}`}
        variant="outlined"
      />
    </>
  )
}
