import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { useCurrentUser } from 'hooks'
import usePostRequest from 'hooks/api/usePostRequest'
import usePutMember from 'hooks/api/members/usePutMember'
import { API_BASE_URL } from 'constants/api'
import { MemberMenuItems } from 'constants/member'
import { isEligibleForDoseSpot } from 'utils/rx'
import { diffObjects } from 'utils/commonHelpers'
import { formatFullName } from 'utils/formatters'

import MemberHeader from './MemberHeader/MemberHeader'
import MemberOverview from './MemberOverview/MemberOverview'
import MemberDetails from './MemberDetails/MemberDetails'
import MemberFiles from './MemberFiles/MemberFiles'
import MemberRx from './MemberRx/MemberRx'
import MemberBilling from './MemberBilling/MemberBilling'
import PGxReport from './MemberPGx/PGxReport'
import PGxMedicationAnalysis from './MemberPGx/PGxMedicationAnalysis'
import PGxMedicationInfo from './MemberPGx/PGxMedicationInfo'
import PGxGenotypeData from './MemberPGx/PGxGenotypeData'
import LoadingModal from 'components/Modals/LoadingModal'
import MemberProgress from './MemberProgress/MemberProgress'
import MemberEncounters from './MemberEncounters/MemberEncounters'
import MemberEncounter from './MemberEncounters/MemberEncounter'
import MemberFeed from './MemberFeed/MemberFeed'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
  body: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: spacing(2),
    flexGrow: 1,
  },
}))

export default function Member({ match }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { memberId } = useParams()
  const currentUser = useCurrentUser()
  const putMember = usePutMember()
  const postRequest = usePostRequest()

  const { error, isLoading } = useSelector(({ members }) => members)
  const member = useSelector(({ members }) => members.byId[memberId])

  const updateDoseSpot = useCallback(
    async (body, user, member) => {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/patient/update/${user._id}&${member._id}`,
        body,
      })
    },
    [postRequest],
  )

  const registerDoseSpot = useCallback(
    async (userID, member) => {
      const patient = await postRequest({
        url: `${API_BASE_URL}/dosespot/patient/register/${userID}&${member._id}`,
        body: member,
      })

      if (!patient.success && patient.message) {
        enqueueSnackbar(patient.message, { variant: 'error' })
      } else {
        enqueueSnackbar('Member registered for DoseSpot', {
          variant: 'success',
        })
      }
    },
    [postRequest, enqueueSnackbar],
  )

  const handleUpdate = useCallback(
    async values => {
      const diff = diffObjects(member, values)
      if (!isEmpty(diff)) {
        if (diff.firstName) diff.firstName = diff.firstName.trim()
        if (diff.lastName) diff.lastName = diff.lastName.trim()
        if (diff.preferredName) diff.preferredName = diff.preferredName.trim()

        const isAlreadyRegistered = member?.doseSpotPatientID ?? false
        if (isAlreadyRegistered) {
          await updateDoseSpot(diff, currentUser, member)
        } else {
          const updatedMember = {
            ...member,
            ...diff,
          }

          const memberEligible = await isEligibleForDoseSpot(updatedMember)
          if (memberEligible) {
            await registerDoseSpot(currentUser._id, updatedMember)
          }
        }

        await putMember({
          ...diff,
          _id: member._id,
        })
      }
    },
    [member, putMember, updateDoseSpot, registerDoseSpot, currentUser],
  )

  useEffect(() => {
    document.title = `Cynomys (${formatFullName(member, true)})`

    return () => {
      document.title = 'Cynomys'
    }
  }, [member])

  if (!member || !currentUser) {
    return <div className="centered">Searching for member...</div>
  }

  if (error) {
    return <div className="centered">{error}</div>
  }

  return (
    <div className={classes.root}>
      {isLoading && <LoadingModal />}

      <div className={classes.content}>
        <MemberHeader member={member} onSubmit={handleUpdate} />

        <div className={classes.body}>
          <Switch>
            <Route exact path={`${match.path}`}>
              <Redirect to={`${match.url}/${MemberMenuItems.OVERVIEW}`} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.OVERVIEW}`}>
              <MemberOverview member={member} onSubmit={handleUpdate} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.DETAILS}`}>
              <MemberDetails member={member} onSubmit={handleUpdate} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.PROGRESS}`}>
              <MemberProgress member={member} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.FILES}`}>
              <MemberFiles member={member} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.RX}`}>
              <MemberRx member={member} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.ENCOUNTERS}`}>
              <MemberEncounters member={member} />
            </Route>
            <Route
              exact
              path={`${match.path}/${MemberMenuItems.ENCOUNTERS}/:encounterId`}
            >
              <MemberEncounter member={member} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.BILLING}`}>
              <MemberBilling member={member} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.PGX}`}>
              <PGxReport member={member} />
            </Route>
            <Route
              exact
              path={`${match.path}/${MemberMenuItems.PGX}/medication-analysis`}
            >
              <PGxMedicationAnalysis member={member} />
            </Route>
            <Route exact path={`${match.path}/${MemberMenuItems.PGX}/genotype`}>
              <PGxGenotypeData member={member} />
            </Route>
            <Route
              exact
              path={`${match.path}/${MemberMenuItems.PGX}/medication-analysis/:medication`}
            >
              <PGxMedicationInfo member={member} />
            </Route>
          </Switch>
        </div>
      </div>
      <MemberFeed member={member} />
    </div>
  )
}
