import { MemberLivingWiths } from 'constants/selectOptions'
import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'livingWith',
  label: 'Living With',
  valueFormatter: value => value.join(', '),
  componentProps: {
    select: true,
    options: MemberLivingWiths,
    SelectProps: {
      multiple: true,
    },
  },
}

const LivingWithSection = props => (
  <SingleFieldSection {...props} title="Living With" field={FIELD} />
)

export default withMemberForm('LivingWithSectionForm', ['livingWith'])(
  LivingWithSection,
)
