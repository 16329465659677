import { getIn } from 'formik'
import { isUndefined, isNull } from 'lodash'
import MenuItem from '@material-ui/core/MenuItem'

import TextInput from 'ui/TextInput'

const FormikInput = ({
  field,
  form: { errors, touched },
  select,
  options = [],
  ...rest
}) => {
  const error = getIn(errors, field.name)
  const touch = getIn(touched, field.name)

  const value =
    select && (isUndefined(field.value) || isNull(field.value))
      ? []
      : field.value

  const fieldProps = {
    ...field,
    value,
  }
  return (
    <TextInput
      {...fieldProps}
      select={select}
      error={touch && error}
      helperText={error}
      {...rest}
    >
      {select &&
        options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
    </TextInput>
  )
}

export default FormikInput
