import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import { useFormFields, useRxStyles } from 'hooks'

import TextInput from 'ui/TextInput'
import MenuItem from '@material-ui/core/MenuItem'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'

const options = [
  { rId: 1, label: 'Active', value: 1 },
  { rId: 2, label: 'Completed', value: 5 },
  { rId: 2, label: 'Discontinued', value: 3 },
]

export function UpdateOrDeletePrompt({
  open,
  setOpen,
  message,
  onUpdate,
  onDelete,
  loading,
  title,
}) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const [fields, handleFieldChange] = useFormFields({
    status: '',
    comment: '',
  })

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      title={title}
      dialogContent={
        <>
          <Typography className={rxClasses.typography}>{message}</Typography>
          <TextInput
            id="status"
            variant="outlined"
            fullWidth
            select
            name="status"
            label="Status"
            value={fields.status}
            onChange={event => {
              handleFieldChange({
                target: {
                  name: event.target.name,
                  value: event.target.value,
                },
              })
            }}
            helperText="Please select medication status"
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextInput>
          <TextInput
            id="comment"
            fullWidth
            variant="outlined"
            label="Comment"
            name="comment"
            value={fields.comment}
            onChange={handleFieldChange}
          />
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
              rxClasses.buttonDelete,
            )}
            onClick={() => {
              setOpen(false)
              onDelete(fields)
            }}
          >
            DELETE
          </TextButton>

          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              setOpen(false)
            }}
          >
            CANCEL
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              setOpen(false)
              onUpdate(fields)
            }}
          >
            UPDATE
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
