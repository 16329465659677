import {
  FETCH_MEMBER_FILES_LOADING,
  FETCH_MEMBER_FILES_SUCCESS,
  FETCH_MEMBER_FILES_ERROR,
  MEMBER_FILE_UPLOADING,
  MEMBER_FILE_UPLOAD_SUCCESS,
  MEMBER_FILE_UPLOAD_ERROR,
  MEMBER_FILE_DELETING,
  MEMBER_FILE_DELETE_SUCCESS,
  MEMBER_FILE_DELETE_ERROR,
} from './constants'

export const memberFilesLoading = () => ({
  type: FETCH_MEMBER_FILES_LOADING,
})

export const memberFilesSuccess = files => ({
  type: FETCH_MEMBER_FILES_SUCCESS,
  payload: files,
})

export const memberFilesError = error => ({
  type: FETCH_MEMBER_FILES_ERROR,
  payload: error,
})

export const memberFileUploading = () => ({
  type: MEMBER_FILE_UPLOADING,
})

export const memberFileUploadSuccess = files => ({
  type: MEMBER_FILE_UPLOAD_SUCCESS,
  payload: files,
})

export const memberFileUploadError = error => ({
  type: MEMBER_FILE_UPLOAD_ERROR,
  payload: error,
})

export const memberFileDeleting = () => ({
  type: MEMBER_FILE_DELETING,
})

export const memberFileDeleteSuccess = fileID => ({
  type: MEMBER_FILE_DELETE_SUCCESS,
  payload: fileID,
})

export const memberFileDeleteError = error => ({
  type: MEMBER_FILE_DELETE_ERROR,
  payload: error,
})
