import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles, Box, Tabs, Tab } from '@material-ui/core'

import BaseHeader from 'components/Shared/BaseHeader'
import { MEMBER_NAV_TABS, MemberMenuItems } from 'constants/member'
import withMemberForm from 'HOCs/withMemberForm'
import { formatFullName } from 'utils/formatters'

import ChipMenu from './ChipMenu'

const useStyles = makeStyles(({ spacing, palette }) => ({
  tabs: {
    color: palette.text.primary,
    fontWeight: 400,
    maxWidth: 'calc(100vw - 410px)',
  },
  title: {
    color: palette.text.primary,
  },
  tab: {
    minWidth: 96,
    padding: spacing(1, 0),
    textTransform: 'none',
  },
  tabSpan: {
    position: 'relative',
  },
  attentionBadge: {
    width: 4,
    height: 4,
    borderRadius: 4,
    position: 'absolute',
    backgroundColor: palette.accentRed.main,
    top: 10,
    right: -8,
  },
}))

function MemberHeader({ member }) {
  const classes = useStyles()
  const history = useHistory()
  const allProgresses = useSelector(
    ({ progresses }) => progresses.progressesByID,
  )
  const encounters = useSelector(({ notes }) => notes.notes)

  const hasUnreviewedProgress = useMemo(() => {
    return Object.values(allProgresses).some(
      progress => progress.memberID === member._id && !progress.reviewedBy,
    )
  }, [allProgresses, member])

  const hasDraftEncounter = useMemo(() => {
    return Object.values(encounters).some(
      encounter => encounter.memberID === member._id && !encounter.finalized,
    )
  }, [encounters, member])

  const memberNavTabs = useMemo(() => {
    return MEMBER_NAV_TABS.filter(tab => !tab.hidden).map(each => ({
      ...each,
      showNotificationBadge:
        (each.name === MemberMenuItems.PROGRESS && hasUnreviewedProgress) ||
        (each.name === MemberMenuItems.ENCOUNTERS && hasDraftEncounter),
    }))
  }, [hasUnreviewedProgress, hasDraftEncounter])

  const queries = history.location.pathname.split('/')
  const currentTab =
    queries[queries.length - 2] === 'Encounters'
      ? queries[queries.length - 2]
      : queries[queries.length - 1]

  const handleChangeTab = (_event, tab) => {
    history.push(`/members/${member._id}/${tab}`)
  }

  return (
    <BaseHeader>
      <Box height={56} display="flex" alignItems="center">
        <Box ml={1} fontSize="h3.fontSize" className={classes.title}>
          <Box component="span" fontWeight="fontWeightMedium">
            {formatFullName(member, true)}
          </Box>

          {member.preferredName && <span> ({member.preferredName})</span>}
        </Box>

        <Box flexGrow={1} />

        <ChipMenu member={member} />
      </Box>

      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        className={classes.tabs}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {memberNavTabs.map(tab => (
          <Tab
            key={tab.name}
            label={
              <span className={classes.tabSpan}>
                {tab.name}{' '}
                {tab.showNotificationBadge && (
                  <span className={classes.attentionBadge} />
                )}
              </span>
            }
            value={tab.name}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </BaseHeader>
  )
}

export default withMemberForm('MemberHeaderForm', ['careTeamNote'])(
  MemberHeader,
)
