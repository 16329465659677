import { isUndefined, isNaN } from 'lodash'
import { differenceInMinutes } from 'date-fns'
import { createContext, useState, useEffect, useContext, useMemo } from 'react'

const loadGoogleScript = () => {
  // loads the Google JavaScript Library;
  ;(function () {
    const id = 'google-js'
    const src = 'https://apis.google.com/js/platform.js'

    // we have at least one script (React)
    const firstJs = document.getElementsByTagName('script')[0]

    // prevent script from loading twice
    if (document.getElementById(id)) {
      return
    }
    const js = document.createElement('script')
    js.id = id
    js.src = src
    js.onload = window.onGoogleScriptLoad
    firstJs.parentNode.insertBefore(js, firstJs)
  })()
}

const createGoogleAuthContext = () => {
  const context = createContext()

  const useCtx = () => {
    const contextValue = useContext(context)

    if (isUndefined(contextValue)) {
      throw new Error('useCtx must be inside a Provider with a value')
    }

    return contextValue
  }

  return [useCtx, context.Provider]
}

const [useGoogleAuth, AuthProvider] = createGoogleAuthContext()

export const GoogleAuthProvider = ({ children }) => {
  const [gapi, setGapi] = useState(null)
  const [googleAuth, setGoogleAuth] = useState(null)
  const [googleUser, setGoogleUser] = useState(null)

  const tokenObj = useMemo(() => {
    return Object.values(googleUser ?? {}).filter(
      detail => detail?.access_token,
    )?.[0]
  }, [googleUser])

  const onInit = GoogleAuth => {
    setGoogleAuth(GoogleAuth)
    const user = GoogleAuth.currentUser.get()
    setGoogleUser(user)
  }

  const onError = error => {
    console.error(error)
  }

  useEffect(() => {
    // window.gapi is available at this point
    window.onGoogleScriptLoad = () => {
      const _gapi = window.gapi
      setGapi(_gapi)

      window.gapi.load('auth2', function () {
        window.gapi.auth2
          .init({ clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID, plugin_name: 'foobar' })
          .then(onInit, onError)
      })
    }

    // ensure everything is set before loading the script
    loadGoogleScript()
  }, [])

  const signIn = async () => {
    const signInResponse = await googleAuth.signIn()
    if (signInResponse) {
      return true
    } else {
      return false
    }
  }

  const signOut = async () => {
    return await googleAuth.signOut()
  }

  const refreshUser = async () => {
    return await googleUser.reloadAuthResponse()
  }

  const shouldRefreshToken = expiration => {
    const difference = differenceInMinutes(
      new Date(expiration),
      new Date(Date.now()),
    )

    return isNaN(difference) ? true : difference <= 36
  }

  const fetchWithRefresh = async ({ url, options }) => {
    if (!tokenObj) {
      return
    }

    let tokenId = tokenObj.id_token
    const expiration = tokenObj.expires_at
    const refreshing = shouldRefreshToken(expiration)

    if (refreshing) {
      const refreshedUser = await refreshUser()
      tokenId = refreshedUser.id_token ?? tokenId
    }

    const rqst = {
      ...options,
      headers: {
        ...options?.headers,
        tokenid: tokenId,
      },
    }

    return await fetch(url, rqst)
  }

  return (
    <AuthProvider
      value={{
        gapi,
        googleAuth,
        signIn,
        googleUser,
        tokenObj,
        signOut,
        fetchWithRefresh,
        refreshUser,
        shouldRefreshToken,
      }}
    >
      {children}
    </AuthProvider>
  )
}

export { useGoogleAuth }
