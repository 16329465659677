import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useArchiveEncounter } from 'hooks/api/encounters/useArchiveEncounter'
import { notesFailed } from 'redux/notes'
import { diffObjects } from 'utils/commonHelpers'
import { ENCOUNTER_TYPE } from 'utils/encounters'
import usePutMember from 'hooks/api/members/usePutMember'
import usePutNote from 'hooks/api/encounters/usePutNote'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import LoadingModal from 'components/Modals/LoadingModal'
import ArchiveModal from './ArchiveModal'
import LatestDiagnosis from './LatestDiagnosis'
import Procedures from './Procedures/Procedures'
import SignatureModal from './Signature/SignatureModal'
import { Autosave } from './Autosave'
import EncounterNote from './EncounterNote'

const useStyles = makeStyles(
  () => ({
    root: {
      marginTop: 16,
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#e3e3e3',
        },
        '&:hover fieldset': {
          borderColor: '#daefed',
        },
      },
    },
    sections: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    label: {
      margin: '0 0 7px 0',
    },
    noteInputField: {
      position: 'relative',
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
  }),
  { index: 1 },
)

const roundDate = dateString => {
  const ONE_MINUTE = 60000
  const date = new Date(dateString)
  const diff = Math.abs(Date.now() - new Date(dateString))
  if (diff < ONE_MINUTE) {
    return new Date(date.setMinutes(0))
  }
  return new Date(date)
}

export default function EncounterForm({ encounter, member }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const archiveEncounter = useArchiveEncounter()
  const putNote = usePutNote()
  const putMember = usePutMember()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(({ notes: { isLoading } }) => ({
    isLoading,
  }))
  const [stateNote, setNote] = useState(encounter)
  const [signatureModal, showSignatureModal] = useState(false)
  const [archiveModal, showArchiveModal] = useState(false)
  const [noteCptCodes, setCptCodes] = useState(encounter.cptCodes)
  const [isNoShow, setNoShow] = useState(encounter?.noShow ?? false) // eslint-disable-line
  const [timeOfLastSave, setTimeOfLastSave] = useState(Date.now())

  // eslint-disable-next-line
  const [timestamp, setTimestamp] = useState({
    date: roundDate(stateNote.dateCreated),
    duration: stateNote.duration,
  })

  const [saveInProgress, setSaveInProgress] = useState(false)

  const { notesLoading } = useSelector(state => ({
    isLoading: state.notes.isLoading,
  }))

  const handleSave = async note => {
    setSaveInProgress(true)
    try {
      await putNote(note)
      enqueueSnackbar('Your encounter draft has been saved', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('There was an error while saving your encounter.', {
        variant: 'error',
      })
      dispatch(notesFailed(error.message))
    }
    setSaveInProgress(false)
  }

  const handleTopicChange = serializedValue => {
    setNote({
      ...stateNote,
      details: {
        ...stateNote.details,
        topic: serializedValue,
      },
    })
    setTimeOfLastSave(null)
  }

  const handleTypeOfActivityChange = serializedValue => {
    setNote({
      ...stateNote,
      details: {
        ...stateNote.details,
        typeOfActivity: serializedValue,
      },
    })
    setTimeOfLastSave(null)
  }

  const handleEngagedWithChange = serializedValue => {
    setNote({
      ...stateNote,
      details: {
        ...stateNote.details,
        engagedWith: serializedValue,
      },
    })
    setTimeOfLastSave(null)
  }

  useEffect(() => {
    setNote(cur => ({
      ...cur,
      dateCreated: timestamp.date,
      duration: timestamp.duration,
    }))
  }, [timestamp])

  useEffect(() => {
    setNote(cur => ({
      ...cur,
      noShow: isNoShow,
    }))
  }, [isNoShow])

  const handleUpdateProcedure = fields => {
    const { procedures } = fields
    const codes = procedures.map(code => code.code)
    const newStateNote = {
      ...stateNote,
      cptCodes: codes,
    }
    setCptCodes(codes)
    setNote(newStateNote)
    setTimeOfLastSave(null)
  }

  const handleFinalize = async () => {
    try {
      await putNote({
        ...stateNote,
        finalized: true,
      })
      enqueueSnackbar('Your encounter has been finalized', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('There was an error while finalizing your note', {
        variant: 'error',
      })
      dispatch(notesFailed(error.message))
    }
  }

  const handleMarkNoShowAndFinalize = () => {
    stateNote.noShow = true
    handleFinalize()
  }

  const handleArchive = async () => {
    try {
      await archiveEncounter(stateNote)
      enqueueSnackbar('Your encounter has been archived', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('There was an error while archiving the encounter', {
        variant: 'error',
      })
      dispatch(notesFailed(error.message))
    }
  }

  const handleChange = (key, value, addOn = false) => {
    const newNote = {
      ...stateNote,
      details: {
        ...stateNote.details,
        [key]: addOn ? stateNote.details[key] + ' ' + value : value,
      },
    }
    setNote(newNote)
    setTimeOfLastSave(null)
  }

  const handleUpdate = useCallback(
    async values => {
      const diff = diffObjects(member, values)
      if (!isEmpty(diff)) {
        await putMember({
          ...diff,
          _id: member._id,
        })
      }
    },
    [member, putMember],
  )

  const fieldChangeHandlers = {
    handleTypeOfActivityChange,
    handleTopicChange,
    handleEngagedWithChange,
    handleChange,
  }

  return isLoading || notesLoading ? (
    <LoadingModal />
  ) : (
    <>
      {signatureModal && (
        <SignatureModal
          note={encounter}
          open={signatureModal}
          onClose={() => showSignatureModal(false)}
          onFinalize={handleFinalize}
        />
      )}
      {archiveModal && (
        <ArchiveModal
          open={archiveModal}
          onClose={() => showArchiveModal(false)}
          onArchive={handleArchive}
        />
      )}
      <div
        className={classes.sections}
        style={{
          marginTop: '16px',
        }}
      >
        <Procedures
          noteCodes={noteCptCodes}
          updateProcedure={handleUpdateProcedure}
          isNoShow={isNoShow}
          withDialog={true}
        />
        {encounter.type === ENCOUNTER_TYPE.CARE_MANAGEMENT ? null : (
          <LatestDiagnosis member={member} onSubmit={handleUpdate} />
        )}
      </div>

      <EncounterNote
        encounter={encounter}
        stateNote={stateNote}
        fieldChangeHandlers={fieldChangeHandlers}
        updateNote={setNote}
        timeOfLastSave={timeOfLastSave}
      />

      <div className={`member-note-container member-note-form ${classes.root}`}>
        <div className={classes.actionButtons}>
          <Button className="red" onClick={handleMarkNoShowAndFinalize}>
            NO-SHOW
          </Button>
          <div>
            <Button
              className="mint"
              onClick={() => {
                handleSave(stateNote)
              }}
            >
              SAVE
            </Button>
            <Button className="mint" onClick={() => showSignatureModal(true)}>
              SIGN & FINALIZE
            </Button>
            <Autosave
              data={stateNote}
              handleSaveData={putNote}
              handleFailure={message => {
                dispatch(notesFailed(message))
              }}
              setTimeOfLastSave={setTimeOfLastSave}
              saveInProgress={saveInProgress}
            />
          </div>
        </div>
      </div>
    </>
  )
}
