import { makeStyles, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'

import ProgressSubheader from './ProgressSubheader'
import RiskMemberCard from './RiskMemberCard'

import { useHighRiskProgresses } from 'hooks'
import { selectAllMembers } from 'redux/selectors'

const useStyles = makeStyles(({ spacing }) => ({
  memberBox: {
    overflowY: 'scroll',
  },
}))

const HighRiskMember = ({ isMyPatientsProgress }) => {
  const classes = useStyles()
  const allMembers = useSelector(selectAllMembers)
  const allProgresses = useSelector(
    ({ progresses }) => progresses.progressesByID,
  )

  const highRiskProgresses = useHighRiskProgresses(
    isMyPatientsProgress,
    allMembers,
    allProgresses,
  )

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {!highRiskProgresses.length && (
        <ProgressSubheader content="No high risk members" />
      )}

      <Box className={classes.memberBox}>
        {highRiskProgresses.map(progress => (
          <RiskMemberCard key={progress._id} progress={progress} />
        ))}
      </Box>
    </Box>
  )
}

export default HighRiskMember
