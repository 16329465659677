import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import auth, { getAuthorizationFromStorage } from './auth'
import members from './members'
import notes from './notes'
import users from './users'
import externalProviders from './externalProviders'
import tasks from './tasks'
import progresses from './progresses'
import files from './files'
import pgxs from './pgxs'

const configureStore = () => {
  const sessionAuthorized = getAuthorizationFromStorage()
  const intialState = sessionAuthorized
    ? { auth: { authorized: sessionAuthorized } }
    : { auth: { authorized: null } }
  const middlewares = [thunk]
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    middlewares.push(logger)
  }

  return createStore(
    combineReducers({
      auth,
      members,
      users,
      externalProviders,
      notes,
      tasks,
      progresses,
      files,
      pgxs,
    }),
    intialState,
    applyMiddleware(...middlewares),
  )
}

export default configureStore
