import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Encounter from 'components/Member/MemberEncounters/Encounter'
import { useRxStyles, useGetEncounters } from 'hooks'
import { selectAllMembers } from 'redux/selectors'
import { formatDate } from 'utils/formatters'
import { MemberMenuItems } from 'constants/member'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    overflowY: 'scroll',
    padding: spacing(0, 3),
    flexGrow: 1,
    minWidth: 1000,
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(4, 0),
  },
  button: {
    margin: spacing(0, 2),
    color: palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
  groupBox: {
    marginBottom: spacing(1.5),
  },
  date: {
    marginBottom: spacing(1),
  },
}))

const EncounterList = ({ isMyPatientCheck, state, noDataTitle }) => {
  const classes = useStyles()
  const rxClasses = useRxStyles()

  const history = useHistory()
  const [counts, setCounts] = useState(5)
  const allMembers = useSelector(selectAllMembers)
  const byIdMembers = useSelector(({ members }) => members.byId)
  const encounters = useSelector(({ notes }) => notes.notes)

  const filteredEncounters = useGetEncounters(
    isMyPatientCheck,
    allMembers,
    encounters,
    state,
  )

  const dates = useMemo(
    () =>
      Object.keys(filteredEncounters).sort((prev, next) =>
        prev < next
          ? state === 'pending'
            ? -1
            : 1
          : state === 'pending'
          ? 1
          : -1,
      ),
    [filteredEncounters, state],
  )

  const showDates = useMemo(
    () => (state === 'pending' ? dates : dates.slice(0, counts)),
    [counts, dates, state],
  )

  const handleEncounterCardClick = encounter => {
    const baseRoute = `/members/${encounter.memberID}`
    const encounterId = encounter._id

    const route = `${baseRoute}/${MemberMenuItems.ENCOUNTERS}/${encounterId}`
    history.push(route)
  }

  return (
    <Box className={classes.root}>
      {!dates.length && (
        <Typography
          className={clsx(
            rxClasses.textAlignCenter,
            rxClasses.fontSize16,
            rxClasses.secondaryNavy2,
          )}
        >
          {noDataTitle}
        </Typography>
      )}
      {showDates.map(date => (
        <Box key={date} className={classes.groupBox}>
          <Typography
            className={clsx(
              rxClasses.fontSize10,
              classes.date,
              rxClasses.secondaryNavy2,
            )}
          >
            {formatDate(date, 'MMMM dd, yyyy')}
          </Typography>
          {filteredEncounters[date].map(encounter => (
            <Box
              key={encounter._id}
              className={classes.groupBox}
              onClick={e => {
                e.stopPropagation()
                handleEncounterCardClick(encounter)
              }}
            >
              <Encounter
                encounter={encounter}
                member={byIdMembers[encounter.memberID]}
                isMemberNameShow
              />
            </Box>
          ))}
        </Box>
      ))}
      {dates.length > counts && state !== 'pending' && (
        <Box className={classes.buttonBox}>
          <Typography
            className={clsx(classes.button, rxClasses.fontSize14)}
            onClick={() => setCounts(counts + 5)}
          >
            Load More
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default EncounterList
