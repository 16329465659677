import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import withMemberForm from 'HOCs/withMemberForm'
import MultiFieldsSectionForm from 'components/Shared/Sections/SectionForms/MultiFieldsSectionForm'

const PROFILE_FIELDS = [
  {
    path: 'firstName',
    label: 'First Name',
    cols: 6,
  },
  {
    path: 'lastName',
    label: 'Last Name',
    cols: 6,
  },
  {
    path: 'dob',
    label: 'Date of Birth',
    type: 'date',
    cols: 6,
  },
]

const CONTACT_FIELDS = [
  {
    path: 'phone',
    label: 'Phone Number',
    cols: 6,
  },
  {
    path: 'email',
    label: 'Email',
    cols: 6,
  },
]

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    marginBottom: spacing(2),
  },

  cardHeader: {
    borderBottom: `1px solid ${palette.distinctiveGray.main}`,
  },

  cardActions: {
    justifyContent: 'flex-end',
  },
}))

const MemberAddForm = ({ isSubmitting, handleReset, handleSubmit }) => {
  const classes = useStyles()

  return (
    <>
      <Card variant="outlined" className={classes.card}>
        <CardHeader
          title={<Typography variant="h4">Patient Profile</Typography>}
          className={classes.cardHeader}
        />

        <CardContent>
          <MultiFieldsSectionForm fields={PROFILE_FIELDS} />
        </CardContent>
      </Card>

      <Card variant="outlined" className={classes.card}>
        <CardHeader
          title={<Typography variant="h4">Contact Information</Typography>}
          className={classes.cardHeader}
        />

        <CardContent>
          <MultiFieldsSectionForm fields={CONTACT_FIELDS} />
        </CardContent>
      </Card>

      <Card variant="outlined" className={classes.card}>
        <CardActions className={classes.cardActions}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Clear
          </Button>

          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting && (
              <>
                <CircularProgress size={20} />
                &nbsp;
              </>
            )}
            Save
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default withMemberForm(
  'MemberAddForm',
  [...PROFILE_FIELDS, ...CONTACT_FIELDS].map(field => field.path),
)(MemberAddForm)
