import clsx from 'clsx'
import { isNull, isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import usePostRequest from 'hooks/api/usePostRequest'
import usePutRequest from 'hooks/api/usePutRequest'
import useGetRequest from 'hooks/api/useGetRequest'
import { useTheme } from '@material-ui/core/styles'
import {
  useCurrentUser,
  useFormFields,
  useRxStyles,
  useLayoutStyles,
} from 'hooks'
import { API_BASE_URL } from 'constants/api'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'
import TextInput from 'ui/TextInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  signature: {
    '& .MuiFilledInput-input': {
      fontSize: '36px!important',
      fontFamily: `'Alex Brush', cursive`,
      backgroundColor: palette.backgroundMint.main,
    },
  },
}))

export default function SignatureModal({
  note,
  open,
  onClose,
  onFinalize,
  buttonText = 'FINALIZE',
}) {
  const theme = useTheme()
  const rxClasses = useRxStyles()
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const currentUser = useCurrentUser()
  const postRequest = usePostRequest()
  const putRequest = usePutRequest()
  const getRequest = useGetRequest()
  const [loading, setLoading] = useState(true)
  const [signature, setSignature] = useState(null)
  const [fields, handleFieldChange] = useFormFields({
    signatureInput: '',
  })
  const handleClick = () => {
    try {
      setLoading(true)
      if (isEmpty(signature)) {
        Promise.resolve(
          createUserSignature({
            userID: currentUser._id,
            text: fields.signatureInput,
          }),
        )
      } else {
        if (signature.text !== fields.signatureInput) {
          Promise.resolve(
            updateUserSignature(signature._id, {
              text: fields.signatureInput,
            }),
          )
        }
      }
    } catch (error) {
      enqueueSnackbar('Failed to create signature', { variant: 'error' })
    } finally {
      onFinalize()
      setLoading(false)
      onClose(false)
    }
  }

  const createUserSignature = async body => {
    await postRequest({
      url: `${API_BASE_URL}/cp/signature`,
      body,
    })
  }

  const updateUserSignature = async (id, body) => {
    await putRequest({
      url: `${API_BASE_URL}/cp/signature/${id}`,
      body,
    })
  }

  const getUserSignature = async id => {
    try {
      setLoading(true)
      const [signatureResponse] = await getRequest({
        url: `${API_BASE_URL}/cp/signature/${id}`,
      })
      setSignature(signatureResponse)
    } catch (e) {
      enqueueSnackbar('Failed to load signature', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (note && currentUser && isNull(signature)) {
      getUserSignature(currentUser._id)
    }

    if (signature) {
      handleFieldChange({
        target: {
          name: 'signatureInput',
          value: signature.text,
        },
      })
    }
  }, [signature]) // eslint-disable-line

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        onClose(false)
      }}
      title="Signature"
      dialogContent={
        <>
          <Typography className={clsx(rxClasses.typography, layoutClasses.mt2)}>
            Please e-sign using your full name.
          </Typography>
          <TextInput
            variant="filled"
            className={classes.signature}
            fullWidth
            placeholder="Type your signature here"
            name="signatureInput"
            id="signatureInput"
            value={fields.signatureInput}
            onChange={event => {
              handleFieldChange({
                target: {
                  name: event.target.name,
                  value: event.target.value,
                },
              })
            }}
          />
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              onClose(false)
            }}
          >
            CANCEL
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={handleClick}
            disabled={isEmpty(fields.signatureInput)}
          >
            {buttonText}
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
