import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  text: {
    color: palette.secondaryNavy1.main,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
}))

export default function VersionNumber({ version }) {
  const classes = useStyles()

  return (
    <p className={classes.text}>
      Version {version ?? process.env.REACT_APP_VERSION}
    </p>
  )
}
