import Typography from '@material-ui/core/Typography'

import { withStyles } from '@material-ui/core/styles'

const EncounterTitle = withStyles({
  root: {
    color: '#183f4f',
    fontSize: '14px',
  },
})(Typography)

export default EncounterTitle
