import { useState, useMemo } from 'react'
import Chip from '@material-ui/core/Chip'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(({ palette, spacing }) => ({
  chip: {
    backgroundColor: palette.backgroundGray.main,
    color: palette.text.primary,
    cursor: 'pointer',

    '&.hasMemo': {
      backgroundColor: '#fcf2bf',
      color: palette.text.primary,
    },

    '&.open': {
      backgroundColor: palette.backgroundMint.main,
      color: palette.secondaryMint1.main,
    },
  },

  label: {
    fontSize: 12,
    height: spacing(1.5),
    padding: spacing(0, 1.5),
  },

  paper: {
    width: props => props.width,
    marginTop: spacing(2),
  },
}))

export default function PopoverChip({
  chipLabel,
  hasMemo,
  children,
  horizontalPlacement,
  ...props
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const id = useMemo(() => `popover-${chipLabel}`, [chipLabel])
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Chip
        aria-describedby={id}
        label={chipLabel}
        size="small"
        classes={{
          root: clsx(classes.chip, { open, hasMemo }),
          label: classes.label,
        }}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalPlacement,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontalPlacement,
        }}
        onClose={handleClose}
        {...props}
      >
        {children}
      </Popover>
    </>
  )
}
