import { makeStyles } from '@material-ui/core/styles'

export const useLayoutStyles = makeStyles(
  theme => ({
    mr05: {
      marginRight: theme.spacing(0.5),
    },
    mr1: {
      marginRight: theme.spacing(1),
    },
    mb05: {
      marginBottom: theme.spacing(0.5),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb15: {
      marginBottom: theme.spacing(1.5),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    mb4: {
      marginBottom: theme.spacing(4),
    },
    mb6: {
      marginBottom: theme.spacing(6),
    },
    mb8: {
      marginBottom: theme.spacing(8),
    },
    ml05: {
      marginLeft: theme.spacing(0.5),
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
    ml15: {
      marginLeft: theme.spacing(1.5),
    },
    ml2: {
      marginLeft: theme.spacing(2),
    },
    ml3: {
      marginLeft: theme.spacing(3),
    },
    margin1: {
      marginBottom: theme.spacing(1),
    },
    margin2: {
      marginBottom: theme.spacing(2),
    },
    margin3: {
      marginBottom: theme.spacing(3),
    },
    mt05: {
      marginTop: theme.spacing(0.5),
    },
    mtAuto: {
      marginTop: 'auto',
    },
    mt1: {
      marginTop: theme.spacing(1),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mt3: {
      marginTop: theme.spacing(3),
    },
    mt6: {
      marginTop: theme.spacing(6),
    },
    pl1: {
      paddingLeft: theme.spacing(1),
    },
    pl2: {
      paddingLeft: theme.spacing(2),
    },
    pl3: {
      paddingLeft: theme.spacing(3),
    },
    pl4: {
      paddingLeft: theme.spacing(4),
    },
    pt1: {
      paddingTop: theme.spacing(1),
    },
    pt2: {
      paddingTop: theme.spacing(2),
    },
    pt3: {
      paddingTop: theme.spacing(3),
    },
    pb0: {
      paddingBottom: theme.spacing(0),
    },
    pb1: {
      paddingBottom: theme.spacing(1),
    },
    pb2: {
      paddingBottom: theme.spacing(2),
    },
    pb3: {
      paddingBotom: theme.spacing(3),
    },
    pr1: {
      paddingRight: theme.spacing(1),
    },
    pr2: {
      paddingRight: theme.spacing(2),
    },
    padding1: {
      padding: theme.spacing(1),
    },
    padding2: {
      padding: theme.spacing(2),
    },
    padding3: {
      padding: theme.spacing(3),
    },
    padding4: {
      padding: theme.spacing(4),
    },
    noPadding: {
      padding: 0,
    },
    noMargin: {
      padding: 0,
    },
    grid1: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(2),
    },
    grid2: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(3),
    },
    displayBlock: {
      display: 'block',
    },
    fullHeight: {
      height: '100%',
    },
    center: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    textAlignLeft: {
      textAlign: 'left',
    },
    truncate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    wrap: {
      whiteSpace: 'wrap',
      overflow: 'hidden',
      textTransform: 'ellipsis',
    },
  }),
  { index: 1 },
)
