import { useSnackbar } from 'notistack'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { usePageVisibility } from 'react-page-visibility'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PeopleIcon from '@material-ui/icons/People'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import LocalHospital from '@material-ui/icons/LocalHospital'
import BarChartIcon from '@material-ui/icons/BarChart'
import EventNoteIcon from '@material-ui/icons/EventNote'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import AccountCircle from '@material-ui/icons/AccountCircle'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useCurrentUser, useInterval } from 'hooks'
import useGetRequest from 'hooks/api/useGetRequest'
import { API_BASE_URL } from 'constants/api'
import { HAS_DOSESPOT, POLLING_DELAY } from 'constants/rx'
import { useLatestProgresses, useCompletedPGx, useGetEncounters } from 'hooks'
import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { selectAllMembers } from 'redux/selectors'
import VersionNumber from 'components/Shared/VersionNumber'
import NotificationBadge from 'components/Shared/NotificationBadge'

import UserAvatar from './Shared/UserAvatar'
import SidebarNavButton from './Shared/SidebarNavButton'
import PrairieIcon from 'assets/icon.png'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.common.white,
    borderRight: `1px solid ${palette.distinctiveGray.main}`,
    transition: 'all 0.2s ease-in-out',
    height: '100%',
    zIndex: 999,
    minHeight: 500,
    overflowY: 'auto',
  },

  collapsedRoot: {
    width: 90,
    minWidth: 90,
  },

  expandedRoot: {
    width: 240,
    minWidth: 240,
  },

  floatRoot: {
    position: 'absolute',
  },

  staticRoot: {
    position: 'static',
  },

  titleContainer: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
  },

  logoContainer: {
    flexGrow: 1,
    marginLeft: spacing(1),
  },

  logo: {
    width: spacing(3),
    height: spacing(3),
  },

  iconButton: {
    padding: 0,
  },

  avatarWrapper: {
    padding: spacing(0, 1),
  },

  menuWrapper: {
    marginTop: props => (props.smallHeight ? spacing(0.5) : spacing(3)),

    '& .MuiButton-root': {
      padding: props => (props.smallHeight ? spacing(0.5, 3) : spacing(3)),
    },
  },

  menuTitle: {
    padding: spacing(0, 2),
    marginBottom: spacing(1),
    color: palette.secondaryNavy1.main,
    fontSize: typography.caption.fontSize,
    fontWeight: typography.fontWeightBold,
    textTransform: 'uppercase',
    height: '18px',
  },

  notificationBubble: {
    backgroundColor: palette.accentRed.main,
    color: '#fff',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: 'normal',
  },

  notificationText: {
    marginRight: '8px',
  },

  notificationContainer: {
    position: 'absolute',
    marginTop: 'auto',
    bottom: spacing(6),
    left: spacing(4),
  },
  versionContainer: {
    position: 'absolute',
    bottom: spacing(2),
    left: spacing(4),
  },
  spacing: {
    justifyContent: 'center',
  },
}))

const Sidebar = ({
  isSidebarFloated,
  setIsSidebarFloated,
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  onClickUnreadNotification,
  unreadCommentCount,
}) => {
  const smallHeight = useMediaQuery('(max-height: 620px)')
  const classes = useStyles({ smallHeight })
  const { enqueueSnackbar } = useSnackbar()
  const getRequest = useGetRequest()
  const currentUser = useCurrentUser()
  const { signOut } = useGoogleAuth()
  const [notificationSsoUrl, setSSOUrl] = useState(null)
  const [notificationCounts, setCounts] = useState(null)
  const isVisible = usePageVisibility()
  const allProgresses = useSelector(
    ({ progresses }) => progresses.progressesByID,
  )
  const allMembers = useSelector(selectAllMembers)
  const byIdMembers = useSelector(({ members }) => members.byId)
  const pgxs = useSelector(({ pgxs }) => pgxs.allPgxs)
  const encounters = useSelector(({ notes }) => notes.notes)

  const isShowOnlyIcon = useMemo(
    () => isSidebarCollapsed && isSidebarFloated,
    [isSidebarCollapsed, isSidebarFloated],
  )

  const latestProgresses = useLatestProgresses(
    true,
    allMembers,
    allProgresses,
    byIdMembers,
  )

  const newCompletedPGxs = useCompletedPGx(true, allMembers, pgxs).filter(
    pgx => pgx.processTime >= 0 && pgx.processTime < 15,
  )

  const pendingEncounters = useGetEncounters(
    true,
    allMembers,
    encounters,
    'pending',
  )

  const pendingEncountersCount = Object.keys(pendingEncounters).reduce(
    (count, curr) => count + pendingEncounters[curr].length,
    0,
  )

  const shouldFetchNotification =
    currentUser && currentUser._id && HAS_DOSESPOT(currentUser) && isVisible

  const handleSignout = async () => {
    await signOut()
  }

  const fetchNotifications = useCallback(
    async userId => {
      const notifications = await getRequest({
        url: `${API_BASE_URL}/dosespot/clinician/notifications/${userId}`,
      })

      if (notifications.success) {
        const notificationSsoResponse = await getRequest({
          url: `${API_BASE_URL}/dosespot/sso/clinician/notifications/${userId}`,
        })

        setSSOUrl(notificationSsoResponse.NotificationSsoUrl)
        setCounts(notifications)
      }
    },
    [getRequest],
  )

  useEffect(() => {
    if (shouldFetchNotification) {
      fetchNotifications(currentUser._id).catch(error =>
        enqueueSnackbar(error.message, { variant: 'error' }),
      )
    }
  }, [
    currentUser,
    enqueueSnackbar,
    fetchNotifications,
    shouldFetchNotification,
  ])

  useInterval(async () => {
    if (shouldFetchNotification) {
      fetchNotifications(currentUser._id).catch(error =>
        enqueueSnackbar(error.message, { variant: 'error' }),
      )
    }
  }, POLLING_DELAY)

  return (
    <div
      className={clsx(classes.root, {
        [classes.collapsedRoot]: isShowOnlyIcon,
        [classes.expandedRoot]: !isShowOnlyIcon,
        [classes.floatRoot]: isSidebarFloated,
        [classes.staticRoot]: !isSidebarFloated,
      })}
      onMouseEnter={() => setIsSidebarCollapsed(false)}
      onMouseLeave={() => setIsSidebarCollapsed(true)}
    >
      <Box p={3} className={classes.titleContainer}>
        <Box className={classes.logoContainer}>
          <img src={PrairieIcon} alt="Prairie" className={classes.logo} />
        </Box>
        {!isShowOnlyIcon && (
          <IconButton
            className={classes.iconButton}
            onClick={() => setIsSidebarFloated(!isSidebarFloated)}
          >
            {isSidebarFloated ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        )}
      </Box>

      <div className={classes.avatarWrapper}>
        <UserAvatar user={currentUser} showDetail={!isShowOnlyIcon} />
      </div>

      <div className={classes.menuWrapper}>
        <Typography className={classes.menuTitle}>
          {!isShowOnlyIcon && 'Menu'}
        </Typography>

        <SidebarNavButton
          to="/members"
          startIcon={<PeopleIcon />}
          showDetail={!isShowOnlyIcon}
        >
          Members
        </SidebarNavButton>

        <SidebarNavButton
          startIcon={<EventNoteIcon />}
          to="/encounters"
          showDetail={!isShowOnlyIcon}
          hasNotification={!!pendingEncountersCount}
        >
          <span className={classes.notificationText}>Encounters</span>{' '}
          {!!pendingEncountersCount && (
            <span className={classes.notificationBubble}>
              {pendingEncountersCount}
            </span>
          )}
        </SidebarNavButton>

        <SidebarNavButton
          startIcon={<BarChartIcon />}
          to="/progress"
          showDetail={!isShowOnlyIcon}
          hasNotification={!!latestProgresses.length}
        >
          <span className={classes.notificationText}>Progress</span>{' '}
          {!!latestProgresses.length && (
            <span className={classes.notificationBubble}>
              {latestProgresses.length}
            </span>
          )}
        </SidebarNavButton>

        <SidebarNavButton
          to="/pgxOrders"
          startIcon={<FingerprintIcon />}
          showDetail={!isShowOnlyIcon}
          hasNotification={!!newCompletedPGxs.length}
        >
          <span className={classes.notificationText}>PGx Orders</span>{' '}
          {!!newCompletedPGxs.length && (
            <span className={classes.notificationBubble}>
              {newCompletedPGxs.length}
            </span>
          )}
        </SidebarNavButton>

        {notificationSsoUrl && notificationCounts && (
          <SidebarNavButton
            href={notificationSsoUrl}
            target="_blank"
            startIcon={<LocalHospital />}
            showDetail={!isShowOnlyIcon}
            hasNotification={!!notificationCounts.NotificationCount}
          >
            <span className={classes.notificationText}>ePrescription</span>{' '}
            <span className={classes.notificationBubble}>
              {notificationCounts.NotificationCount}
            </span>
          </SidebarNavButton>
        )}
      </div>

      <div className={classes.menuWrapper}>
        <Typography className={classes.menuTitle}>
          {!isShowOnlyIcon && 'Account'}
        </Typography>

        <SidebarNavButton
          to="/account"
          startIcon={<AccountCircle />}
          showDetail={!isShowOnlyIcon}
        >
          Settings
        </SidebarNavButton>

        <SidebarNavButton
          startIcon={<PowerSettingsNewIcon />}
          onClick={handleSignout}
          showDetail={!isShowOnlyIcon}
        >
          Sign Off
        </SidebarNavButton>
      </div>

      <div className={classes.notificationContainer}>
        <NotificationBadge
          unreadCount={unreadCommentCount}
          onClick={onClickUnreadNotification}
        />
      </div>

      {!isShowOnlyIcon && (
        <div className={classes.versionContainer}>
          <VersionNumber />
        </div>
      )}
    </div>
  )
}

export default Sidebar
