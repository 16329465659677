import { isArray } from 'lodash'
import { useRxStyles } from 'hooks'
import { ENCOUNTER_DETAIL_KEYS } from 'utils/encounters'
import { sortDate } from 'utils/commonHelpers'
import RenderHTML from 'components/Shared/RenderHTML/RenderHTML'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AddendumForm from 'components/Member/MemberEncounters/AddendumForm'
import Addendum from 'components/Member/MemberEncounters/Addendum'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import EncounterTitle from 'components/Member/MemberEncounters/EncounterTitle'
import Procedures from 'components/Member/MemberEncounters/Procedures/Procedures'

const useStyles = makeStyles(theme => ({
  spacing: {
    margin: theme.spacing(0),
    padding: '4px 0',
  },
}))

export default function EncounterDisplay({ encounter }) {
  const theme = useTheme()
  const classes = useStyles()
  const rxClasses = useRxStyles(theme)

  const hasPublicNote = Boolean(encounter.details?.publicNote ?? false)
  const isNoShow = encounter?.noShow ?? false
  const isFinalized = encounter?.finalized ?? false
  const encounterDetails = encounter.details

  const stripExternalPTags = html => {
    const pTags = html.match(/<p>/g)
    if (pTags && pTags.length === 1) {
      return html.replace(/<p>/g, '').replace(/<\/p>/g, '')
    }
    return html
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginTop: '16px' }}>
        <Procedures
          isFinalized={isFinalized}
          isNoShow={isNoShow}
          noteCodes={encounter.cptCodes}
        />
      </Grid>

      {!isNoShow && (
        <Grid item xs={12}>
          <BaseCard>
            <BaseCardHeader
              className={rxClasses.fontSize14}
              bold
              title="Encounter Note"
            />
            {Object.entries(encounterDetails)
              .filter(([title, _]) => title !== 'publicNote')
              .map(([title, value], index) => (
                <BaseRow
                  withDivider={index === 0}
                  key={title}
                  columns={
                    <Grid container>
                      <Grid className={rxClasses.columnPadding} item xs={12}>
                        <EncounterTitle
                          variant="h4"
                          className={classes.spacing}
                          dangerouslySetInnerHTML={{
                            __html: ENCOUNTER_DETAIL_KEYS[title],
                          }}
                        />
                        {isArray(value) ? (
                          value.map(({ code, display }, i) => (
                            <Typography
                              key={`${code}${i}`}
                              style={{ fontSize: '14px' }}
                              component="p"
                            >
                              {display}
                            </Typography>
                          ))
                        ) : (
                          <RenderHTML html={stripExternalPTags(value)} />
                        )}
                      </Grid>
                    </Grid>
                  }
                />
              ))}
          </BaseCard>
        </Grid>
      )}

      {encounter.addendums
        .sort((a, b) => sortDate(a, b))
        .map((addendum, i) => (
          <Grid key={i} item xs={12}>
            <Addendum addendum={addendum} />
          </Grid>
        ))}

      <Grid item xs={12}>
        <AddendumForm note={encounter} />
      </Grid>

      {hasPublicNote && (
        <Grid item xs={12}>
          <BaseCard>
            <BaseCardHeader title="Note to patient" bold />
            <BaseRow
              columns={
                <Grid container>
                  <Grid className={rxClasses.columnPadding} item xs={12}>
                    <Typography variant="caption">
                      Information entered in this section has been shared with
                      the patient through the patient portal
                    </Typography>
                  </Grid>
                  <Grid className={rxClasses.columnPadding} item xs={12}>
                    <EncounterTitle
                      variant="h4"
                      dangerouslySetInnerHTML={{
                        __html: encounter.details.publicNote,
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
          </BaseCard>
        </Grid>
      )}
    </Grid>
  )
}
