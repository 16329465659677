import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { selectAllUsers } from 'redux/selectors'
import { formatFullName } from 'utils/formatters'

import { UserTypes } from 'constants/user'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

export default function SelectProvider({
  id = 'provider',
  label,
  name = 'provider',
  onChange,
  fullWidth,
  variant,
  defaultValue,
  required = false,
}) {
  const users = useSelector(selectAllUsers)
  const renderingProviders = users.filter(
    user => user.userType !== UserTypes.STAFF,
  )

  const { usersById } = useSelector(({ users }) => ({
    usersById: users.byId,
  }))

  const providerOptions = renderingProviders.map(option => {
    return option._id
  })

  const getDefaultValue = id => {
    const user = usersById[id]
    if (!user || user.userType === UserTypes.STAFF) return null
    return usersById[id]._id
  }

  return (
    <Autocomplete
      variant={variant}
      id={id}
      fullWidth={fullWidth}
      options={providerOptions}
      name={name}
      defaultValue={getDefaultValue(defaultValue)}
      getOptionLabel={option => {
        const fullName = formatFullName(usersById[option])
        const hasTitle = usersById[option]?.title ?? false
        const title =
          hasTitle && !isEmpty(hasTitle)
            ? `, ${usersById[option].title.join(', ')}`
            : ''
        return `${fullName}${title}`
      }}
      onChange={(e, value) => {
        onChange({ target: { name, value } })
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Select provider"
          required={required}
        />
      )}
    />
  )
}
