import { makeStyles, Chip } from '@material-ui/core'
import clsx from 'clsx'

import { useRxStyles } from 'hooks'

const useStyles = makeStyles(({ spacing, palette }) => ({
  chip: {
    border: 0,
    height: '26px',
    '& span': {
      padding: spacing(0, 1),
    },
  },
  red: {
    color: palette.accentRed.main,
    backgroundColor: '#FEDEDE',
  },
  green: {
    color: palette.primary.main,
    backgroundColor: '#DAEFED',
  },
}))

const RiskChip = ({ content, isRisk }) => {
  const classes = useStyles()
  const rxClasses = useRxStyles()

  return (
    <Chip
      variant="outlined"
      className={clsx(
        classes.chip,
        rxClasses.fontSize12,
        rxClasses.fontWeight400,
        {
          [classes.red]: isRisk,
          [classes.green]: !isRisk,
        },
      )}
      label={content}
    />
  )
}

export default RiskChip
