import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',

    h1: {
      fontSize: 32,
      fontWeight: 600,
    },

    h2: {
      fontSize: 24,
      fontWeight: 600,
    },

    h3: {
      fontSize: 20,
      fontWeight: 400,
    },

    h4: {
      fontSize: 14,
      fontWeight: 500,
    },
    h4Bold: {
      fontSize: 14,
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
    },

    h6: {
      fontSize: 16,
      fontWeight: 400,
    },

    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
    },

    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
    },

    caption: {
      fontSize: 12,
      fontWeight: 400,
    },

    sectionTitle: {
      fontSize: 18,
      fontWeight: 500,
    },

    pgxSubtitle: {
      fontSize: 18,
      fontWeight: 400,
    },
  },

  palette: {
    text: {
      primary: '#183f4f',
    },
    primary: {
      main: '#36bca8',
    },
    disabled: {
      main: '#b4c2c3',
    },
    cardBackground: {
      main: '#f7f7f7',
    },
    primaryGreen: {
      main: '#92be71',
    },
    secondaryGreen1: {
      main: '#6d9147',
    },
    secondaryGreen2: {
      main: '#bbdc9c',
    },
    secondaryMint1: {
      main: '#278c79',
    },
    secondaryMint2: {
      main: '#a3d9cf',
    },
    secondaryNavy1: {
      main: '#456772',
    },
    secondaryNavy2: {
      main: '#7c989b',
    },
    backgroundMint: {
      main: '#daefed',
    },
    backgroundMint2: {
      main: '#E1F1D1',
    },
    backgroundGray: {
      main: '#f2f2f2',
    },
    backgroundRed: {
      main: '#fedede',
    },
    backgroundGreen: {
      main: '#f8faf8',
    },
    backgroundYellow: {
      main: '#fff4cc',
    },
    backgroundYellow2: {
      main: '#FFF0B9',
    },
    accentRed: {
      main: '#eb5757',
    },
    accentRed1: {
      main: '#e5652e',
    },
    accentYellow: {
      main: '#ffe68f',
    },
    distinctiveGray: {
      main: '#e3e3e3',
    },
    distinctiveGray2: {
      main: '#eaeaea',
    },
    warning: {
      main: '#FFA500',
    },
  },

  layout: {
    pl1: {
      paddingLeft: '8px',
    },
    pr1: {
      paddingRight: '8px',
    },
  },
})

theme.overrides = {
  MuiTableContainer: {
    root: {
      boxSizing: 'border-box',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.distinctiveGray.main,
      borderRadius: theme.spacing(0.5),
    },
  },

  MuiTableRow: {
    root: {
      '&:nth-child(even)': {
        backgroundColor: theme.palette.backgroundGreen.main,
      },

      '&:nth-child(odd)': {
        backgroundColor: theme.palette.common.white,
      },
    },

    head: {
      backgroundColor: `${theme.palette.distinctiveGray.main} !important`,
    },
  },

  MuiTableCell: {
    root: {
      '&.first-col': {
        width: '30%',
      },
    },
  },

  MuiLink: {
    root: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}
