import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { membersFailed } from 'redux/members'
import { addExternalProvider } from 'redux/externalProviders'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePostExternalProvider() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const postExternalProvider = useCallback(
    async externalProvider => {
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/externalProviders`,
        options: {
          method: HTTP_METHODS.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(externalProvider),
        },
      })

      if (response.ok) {
        const result = await response.json()
        dispatch(addExternalProvider(result))
      } else {
        dispatch(membersFailed('Failed to add external providers'))
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return postExternalProvider
}
