import clsx from 'clsx'
import { isEmpty } from 'lodash/fp'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'

import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks'

import { formatDateInMMDDYYYY, getDaysSupplyDate } from 'utils/formatters'

import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'

export default function PastRx({ prescriptions }) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const rowsPerPage = 5
  const [page, setPage] = useState(0)

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const paginate = (array, perPage, pageNumber) => {
    return array.slice(pageNumber * perPage, pageNumber * perPage + perPage)
  }

  const Rows = ({ data, rowsPerPage, page }) => {
    if (isEmpty(data)) {
      return <BaseRow withDivider />
    }

    return paginate(data, rowsPerPage, page).map((prx, i) => {
      const rxLastDayOfSupply = getDaysSupplyDate(prx)
      const pharmacyName = prx.pharmacy
        ? prx.pharmacy.StoreName
        : 'No associated pharmacy'
      return (
        <Grid
          key={`${prx.name}-${i}`}
          container
          alignItems="center"
          justifyContent="space-evenly"
          className={clsx(rxClasses.dividerTop)}
        >
          <Grid item xs className={rxClasses.columnPadding}>
            <Typography
              component="span"
              variant="body1"
              className={clsx(
                rxClasses.typography,
                rxClasses.font500,
                rxClasses.fontSize12,
                rxClasses.secondaryMint,
              )}
            >
              {prx.DisplayName}
            </Typography>
          </Grid>
          <Grid item xs={3} className={rxClasses.columnPadding}>
            <Typography
              component="span"
              variant="body1"
              className={clsx(rxClasses.typography, rxClasses.fontSize12)}
            >
              {rxLastDayOfSupply ? (
                <>
                  {formatDateInMMDDYYYY(prx.WrittenDate)} ~ {rxLastDayOfSupply},
                  ({prx.DaysSupply} days, {prx.Refills}
                  refill(s))
                </>
              ) : (
                <span>No DaysSupply provided</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs className={rxClasses.columnPadding}>
            <Typography
              component="span"
              variant="body1"
              className={clsx(rxClasses.typography, rxClasses.fontSize12)}
            >
              {prx.Directions}
            </Typography>
          </Grid>
          <Grid item xs className={clsx(rxClasses.columnPadding)}>
            <Typography
              component="span"
              variant="body1"
              className={clsx(rxClasses.fontSize12, rxClasses.typography)}
            >
              {pharmacyName}
            </Typography>
          </Grid>
        </Grid>
      )
    })
  }

  const Pagination = ({ data, page, rowsPerPage }) => {
    return (
      <Grid item xs={12} className={rxClasses.textAlignRight}>
        <TablePagination
          className={rxClasses.table}
          component="div"
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </Grid>
    )
  }

  const title = 'Past Rx'

  return (
    <BaseCard>
      <div
        style={{
          width: '100%',
        }}
      >
        <BaseCardHeader title={title} />
      </div>
      {prescriptions?.length ? (
        <>
          <Rows data={prescriptions} page={page} rowsPerPage={rowsPerPage} />
          <Pagination
            data={prescriptions}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </>
      ) : (
        <Grid item xs className={rxClasses.columnPadding}>
          <BaseRow emptyPlaceholder="None Added" />
        </Grid>
      )}
    </BaseCard>
  )
}
