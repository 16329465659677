import React, { useState } from 'react'
import {
  Box,
  makeStyles,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { useSelector } from 'react-redux'

import PGxHeader from './PGxHeader'
import CompletePGxs from './CompletePGxs'
import PendingPGxs from './PendingPGxs'

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    padding: spacing(2, 3),
    color: palette.text.primary,
    background: palette.common.white,
    fontSize: 20,
  },
  content: {
    flexGrow: 1,
  },
}))

const PGxs = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const [isMyPatientComplete, setIsMyPatientComplete] = useState(true)
  const [isMyPatientPending, setIsMyPatientPending] = useState(false)
  const isMembersLoading = useSelector(state => state.members.isLoading)
  const isPgxsLoading = useSelector(({ pgxs }) => pgxs.isLoading)

  if (isMembersLoading || isPgxsLoading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Typography className={classes.title}>PGx Orders</Typography>
      <PGxHeader
        selected={selectedTab}
        handleTabChange={newTab => setSelectedTab(newTab)}
        isChecked={!!selectedTab ? isMyPatientPending : isMyPatientComplete}
        handleCheckboxChange={value =>
          selectedTab
            ? setIsMyPatientPending(value)
            : setIsMyPatientComplete(value)
        }
      />
      {!!selectedTab ? (
        <PendingPGxs isMyPatientCheck={isMyPatientPending} />
      ) : (
        <CompletePGxs isMyPatientCheck={isMyPatientComplete} />
      )}
    </Box>
  )
}

export default PGxs
