import { createSelector } from 'reselect'
import keyBy from 'lodash/keyBy'

export const selectAuthorization = ({ auth }) => auth.authorized

export const selectAllMembers = createSelector(
  ({ members }) => members.byId,
  ({ members }) => members.allIds,
  (byId, allIds) => allIds.map(id => byId[id]),
)

export const selectAllUsers = createSelector(
  ({ users }) => users.byId,
  ({ users }) => users.allIds,
  (byId, allIds) => allIds.map(id => byId[id]),
)

export const selectAllCarePartners = createSelector(selectAllUsers, users =>
  users.filter(user => user.userType === 'cc'),
)

export const selectAllPsychiatrists = createSelector(selectAllUsers, users =>
  users.filter(user => user.userType === 'psych'),
)

export const selectAllTherapists = createSelector(selectAllUsers, users =>
  users.filter(user => user.userType === 'therapist'),
)

export const selectNormalizedCarePartners = createSelector(
  selectAllCarePartners,
  carePartners => ({
    byId: keyBy(carePartners, '_id'),
    allIds: carePartners.map(carePartner => carePartner._id),
  }),
)

export const selectNormalizedPsychiatrists = createSelector(
  selectAllPsychiatrists,
  psychiatrists => ({
    byId: keyBy(psychiatrists, '_id'),
    allIds: psychiatrists.map(psychiatrist => psychiatrist._id),
  }),
)

export const selectNormalizedTherapists = createSelector(
  selectAllTherapists,
  therapists => ({
    byId: keyBy(therapists, '_id'),
    allIds: therapists.map(therapist => therapist._id),
  }),
)

export const selectAllExternalProviders = createSelector(
  ({ externalProvider }) => externalProvider.byId,
  ({ externalProvider }) => externalProvider.allIds,
  (byId, allIds) => allIds.map(id => byId[id]),
)

export const selectAllTasks = createSelector(
  ({ tasks }) => tasks.byId,
  ({ tasks }) => tasks.allIds,
  (byId, allIds) => allIds.map(id => byId[id]),
)
