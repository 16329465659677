import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import { MemberMenuItems } from 'constants/member'
import { useCurrentUser } from 'hooks'
import {
  useMarkCommentAsRead,
  useMarkAllCommentsAsRead,
} from 'hooks/api/comments'
import useComments from './useComments'
import CommentNotification from './CommentNotification'

const useStyles = makeStyles(
  theme => ({
    dialog: {
      '& .MuiDialogTitle-root': {
        padding: '0px!important',
        borderBottom: `1px solid ${theme.palette.distinctiveGray.main}`,
      },
      '& .MuiDialogContent-root': {
        padding: '0px!important',
      },
      '& .MuiDialogActions-root': {
        padding: '0px!important',
        borderTop: `1px solid ${theme.palette.distinctiveGray.main}`,
      },
    },
    dialogTitle: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
    },
    unreadCount: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: theme.spacing(3),
      width: theme.spacing(3),
      borderRadius: theme.spacing(3),
      background: theme.palette.accentRed.main,
      marginLeft: theme.spacing(1.5),
      lineHeight: 1,
      color: 'white',
      fontSize: 12,
    },
    button: {
      fontSize: 16,
      padding: theme.spacing(3),
    },
    loader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    link: {
      cursor: 'pointer',
    },
  }),
  { index: 1 },
)

export default function NotificationsModal({
  isOpen,
  onClose,
  unreadCount,
  refetchUnreadCommentCount,
}) {
  const history = useHistory()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { byId: membersById } = useSelector(({ members }) => members)
  const { byId: usersById } = useSelector(({ users }) => users)
  const currentUser = useCurrentUser()
  const markAllCommentsAsRead = useMarkAllCommentsAsRead()
  const markAsRead = useMarkCommentAsRead()

  const { comments, hasMore, loading, loadMore, refetch } = useComments({
    userID: currentUser._id,
    reverse: true,
  })

  const handleClickMarkAllRead = useCallback(async () => {
    await markAllCommentsAsRead()
    await refetchUnreadCommentCount()
    await refetch()
  }, [markAllCommentsAsRead, refetchUnreadCommentCount, refetch])

  const handleCommentClick = async (currentUser, comment) => {
    try {
      const isRead = comment.mentions.some(
        mention => mention.readAt && mention.user === currentUser?._id,
      )
      if (!isRead) {
        await markAsRead({ commentID: comment._id })
        await refetchUnreadCommentCount()
        await refetch()
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    } finally {
      history.push(
        `/members/${comment.feedMemberID}/${MemberMenuItems.OVERVIEW}`,
      )
    }
  }

  return (
    <BaseDialog
      open={isOpen}
      className={classes.dialog}
      onClose={onClose}
      title={
        <div className={classes.dialogTitle}>
          Notifications{' '}
          <span className={classes.unreadCount}>{unreadCount}</span>
        </div>
      }
      dialogContent={
        <InfiniteScroll
          dataLength={comments.length}
          next={loadMore}
          hasMore={hasMore}
          height={400}
          loading={loading}
          loader={
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          }
        >
          {comments.map((comment, index) => (
            <div
              key={`comment-${index}`}
              className={classes.link}
              onClick={() => {
                handleCommentClick(currentUser, comment)
                onClose()
              }}
            >
              <CommentNotification
                key={comment._id}
                comment={comment}
                user={currentUser}
                author={usersById[comment.authoredBy]}
                member={membersById[comment.feedMemberID]}
              />
            </div>
          ))}
        </InfiniteScroll>
      }
      dialogActions={
        <Grid container justifyContent="space-between">
          <TextButton
            className={classes.button}
            onClick={handleClickMarkAllRead}
          >
            Mark All Read
          </TextButton>
          <TextButton className={classes.button} onClick={onClose}>
            CLOSE
          </TextButton>
        </Grid>
      }
    />
  )
}
