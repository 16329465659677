import { MemberTreatmentTypes } from 'constants/selectOptions'

import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const DEFAULT_ROW = {
  type: '',
  description: '',
}

const FIELDS = [
  {
    path: 'type',
    label: 'Type',
    headerClassName: 'first-col',
    componentProps: {
      select: true,
      options: MemberTreatmentTypes,
    },
  },
  {
    path: 'description',
    label: 'Description',
  },
]

const TreatmentsSection = props => (
  <ArrayFieldSection
    path="psychTreatmentList"
    title="Current Treatment"
    rowTitle="Treatment"
    fields={FIELDS}
    defaultRow={DEFAULT_ROW}
    {...props}
  />
)

export default withMemberForm('TreatmentsSectionForm', ['psychTreatmentList'])(
  TreatmentsSection,
)
