import { useLayoutStyles } from 'hooks'
import { isNull, isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { API_BASE_URL } from 'constants/api'
import useGetRequest from 'hooks/api/useGetRequest'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

function openImageSaveAs(dataUri) {
  let newTab = window.open('about:blank')
  let image = new Image()
  image.src = dataUri
  setTimeout(function () {
    newTab.document.write(image.outerHTML)
  }, 0)
}

export default function InsuranceCardDisplay({ id, side }) {
  const layoutClasses = useLayoutStyles()
  const getInsuranceImage = useGetRequest()
  const [loading, setLoading] = useState(true)
  const [image, setImage] = useState(null)

  const fetchInsuranceImage = useCallback(
    async ({ insuranceID, side }) => {
      try {
        setLoading(true)
        const result = await getInsuranceImage({
          url: `${API_BASE_URL}/cp/insurance/images/${side}/${insuranceID}`,
        })
        setImage(result)
      } catch (error) {
        setImage(undefined)
      } finally {
        setLoading(false)
      }
    },
    [getInsuranceImage],
  )

  useEffect(() => {
    fetchInsuranceImage({
      insuranceID: id,
      side,
    })
  }, [fetchInsuranceImage, id, side])

  if (loading && isNull(image)) {
    return <CircularProgress />
  }

  const imgSrc = (mimetype, buffer) => {
    return `data:${mimetype};base64, ${new Buffer.from(buffer).toString(
      'base64',
    )}`
  }

  const NoImage = () => {
    return (
      <Typography component="p" variant="body1" className={layoutClasses.mb3}>
        NO IMAGE UPLOADED
      </Typography>
    )
  }

  return (
    <div className={layoutClasses.mb3}>
      {!isEmpty(image) && !isEmpty(image[side]) ? (
        <Card
          onClick={() => {
            openImageSaveAs(
              imgSrc(image[side].mimetype, image[side].buffer.data),
            )
          }}
          className={layoutClasses.mb3}
          style={{
            cursor: 'pointer',
            backgroundImage: `url("${imgSrc(
              image[side].mimetype,
              image[side].buffer.data,
            )}")`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '240px',
            height: '160px',
          }}
        ></Card>
      ) : (
        <NoImage />
      )}
    </div>
  )
}
