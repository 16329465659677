import { useMemo } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

import PopoverChip from './PopoverChip'

const useStyles = makeStyles({
  listItemText: {
    '& span': {
      fontSize: 14,
    },
  },
})

export default function DxChip({ member }) {
  const classes = useStyles()
  const { diagnosisList } = member

  const currentDiagnoses = useMemo(
    () =>
      (diagnosisList || []).filter(diagnosis => diagnosis.status === 'Current'),
    [diagnosisList],
  )

  return (
    <PopoverChip chipLabel="Dx" horizontalPlacement="center" width={300}>
      <List>
        {currentDiagnoses.length === 0 && (
          <ListItem>
            <ListItemText className={classes.listItemText}>
              No current diagnosis entered
            </ListItemText>
          </ListItem>
        )}

        {currentDiagnoses.map((diagnosis, index) => (
          <ListItem key={index}>
            <ListItemText className={classes.listItemText}>
              {diagnosis.type}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </PopoverChip>
  )
}
