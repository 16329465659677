import { makeStyles, Box, Typography } from '@material-ui/core'

import TextButton from 'components/Shared/TextButton'

const useStyles = makeStyles(({ palette, spacing }) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',

    '&:not(:last-child)': {
      marginBottom: spacing(2),
    },
  },
  title: {
    display: 'inline-block',
    fontSize: 14,
    lineHeight: 1.2,
    marginBottom: spacing(0.5),
    color: palette.text.primary,
  },
  description: {
    fontSize: 10,
    lineHeight: 1.2,
    color: palette.secondaryNavy2.main,
  },
  sendButton: {
    textTransform: 'none',
    fontWeight: 400,
    padding: 0,
    minWidth: 0,

    '&:hover': {
      background: 'transparent',
    },
  },
}))

const QuickMessageItem = ({ description, title, message, onSendMessage }) => {
  const classes = useStyles()

  return (
    <Box key={title} className={classes.item}>
      <Box className={classes.labelBox}>
        <Typography variant="caption" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>

      <TextButton
        className={classes.sendButton}
        disableRipple
        onClick={() => onSendMessage(title, message)}
      >
        Send
      </TextButton>
    </Box>
  )
}

export default QuickMessageItem
