import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { removeNull, formRequest } from 'utils/commonHelpers'
import { HTTP_METHODS } from 'constants/api'

function getOptions(isFormData, body) {
  return isFormData
    ? {
        method: HTTP_METHODS.PUT,
        body: formRequest(removeNull(body)),
      }
    : {
        method: HTTP_METHODS.PUT,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
}

export default function usePutRequest() {
  const { fetchWithRefresh } = useGoogleAuth()

  const putRequest = useCallback(
    async ({ url, body, isFormData = false }) => {
      const response = await fetchWithRefresh({
        url,
        options: getOptions(isFormData, body),
      })

      if (response.ok) {
        const result = await response.json()
        return result
      } else {
        throw new Error('Failed to update record')
      }
    },
    [fetchWithRefresh],
  )

  return putRequest
}
