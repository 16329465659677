import { MemberMaritalStatuses } from 'constants/selectOptions'
import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'maritalStatus',
  label: 'Marital Status',
  componentProps: {
    select: true,
    options: MemberMaritalStatuses,
  },
}

const MaritalStatusSection = props => (
  <SingleFieldSection {...props} title="Marital Status" field={FIELD} />
)

export default withMemberForm('MaritalStatusSectionForm', ['maritalStatus'])(
  MaritalStatusSection,
)
