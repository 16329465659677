export const FETCH_MEMBER_FILES_LOADING = 'FETCH_MEMBER_FILES_LOADING'
export const FETCH_MEMBER_FILES_SUCCESS = 'FETCH_MEMBER_FILES_SUCCESS'
export const FETCH_MEMBER_FILES_ERROR = 'FETCH_MEMBER_FILES_ERROR'

export const MEMBER_FILE_UPLOADING = 'MEMBER_FILE_UPLOADING'
export const MEMBER_FILE_UPLOAD_SUCCESS = 'MEMBER_FILE_UPLOAD_SUCCESS'
export const MEMBER_FILE_UPLOAD_ERROR = 'MEMBER_FILE_UPLOAD_ERROR'

export const MEMBER_FILE_DELETING = 'MEMBER_FILE_DELETING'
export const MEMBER_FILE_DELETE_SUCCESS = 'MEMBER_FILE_DELETE_SUCCESS'
export const MEMBER_FILE_DELETE_ERROR = 'MEMBER_FILE_DELETE_ERROR'
