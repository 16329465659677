import { MentionsInput, Mention } from 'react-mentions'
import SuggestedMention from './SuggestedMention'
import Button from '@material-ui/core/Button'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAllUsers } from 'redux/selectors'

const MemberFeedAddComment = ({ onSubmit }) => {
  const [commentValue, setCommentValue] = useState('')
  const users = useSelector(selectAllUsers)

  const handleCommentChange = event => {
    setCommentValue(event.target.value)
  }

  return (
    <div className="member-feed-add-comment">
      <MentionsInput
        className="member-feed-add-comment-textarea"
        value={commentValue}
        onChange={handleCommentChange}
        placeholder={'Type your comment here'}
        style={{
          '&multiLine': {
            input: {
              border: '1px solid #e3e3e3',
            },
          },
        }}
      >
        <Mention
          trigger="@"
          data={users.map(user => {
            return {
              ...user,
              id: user._id,
              display: `${user.firstName} ${user.lastName}`,
            }
          })}
          renderSuggestion={user => <SuggestedMention user={user} />}
          markup={'{{{"id": "__id__", "label": "__display__"}}}'}
          allowSuggestionsAboveCursor={true}
        />
      </MentionsInput>
      <Button
        variant="contained"
        color="primary"
        className="member-feed-add-comment-button"
        fullWidth
        onClick={() => {
          onSubmit(commentValue)
          setCommentValue('')
        }}
        disabled={commentValue === ''}
      >
        Post Comment
      </Button>
    </div>
  )
}

export default MemberFeedAddComment
