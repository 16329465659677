import { useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import {
  MemberGenders,
  MemberPreferredLanguages,
  MemberPronouns,
  MemberRaces,
  MemberSourceCategories,
  MemberStatuses,
} from 'constants/selectOptions'
import withMemberForm from 'HOCs/withMemberForm'
import {
  formatDateString,
  formatAge,
  formatHeight,
  formatGender,
  formatWeight,
  formatPronoun,
} from 'utils/formatters'

import BaseSection from 'components/Shared/Sections/BaseSection'
import SectionField from 'components/Shared/Sections/SectionField'
import MultiFieldsSectionForm from 'components/Shared/Sections/SectionForms/MultiFieldsSectionForm'

const FIELDS = [
  {
    path: 'firstName',
    label: 'First Name',
  },
  {
    path: 'lastName',
    label: 'Last Name',
  },
  {
    path: 'preferredName',
    label: 'Preferred Name',
  },
  {
    path: 'dob',
    label: 'Date of Birth',
    type: 'date',
  },
  {
    path: 'status',
    label: 'Status',
    componentProps: {
      select: true,
      options: MemberStatuses,
    },
  },
  {
    path: 'heightFeet',
    label: 'Height (Feet)',
    componentProps: {
      type: 'number',
    },
  },
  {
    path: 'heightInch',
    label: 'Height (Inch)',
    componentProps: {
      type: 'number',
    },
  },
  {
    path: 'weight',
    label: 'Weight (lbs)',
    componentProps: {
      type: 'number',
    },
  },
  {
    path: 'gender',
    label: 'Biological Sex',
    componentProps: {
      select: true,
      options: MemberGenders,
    },
  },
  {
    path: 'pronouns',
    label: 'Pronouns',
    componentProps: {
      select: true,
      options: MemberPronouns,
    },
  },
  {
    path: 'race',
    label: 'Race',
    componentProps: {
      select: true,
      options: MemberRaces,
    },
  },
  {
    path: 'preferredLanguage',
    label: 'Preferred Language',
    componentProps: {
      select: true,
      options: MemberPreferredLanguages,
    },
  },
  {
    path: 'source.category',
    label: 'Source Category',
    componentProps: {
      select: true,
      options: MemberSourceCategories,
    },
  },
  {
    path: 'source.description',
    label: 'Source Description',
    componentProps: {
      multiline: true,
    },
  },
]

function ProfileCard({ member }) {
  const renderForm = useCallback(
    () => <MultiFieldsSectionForm fields={FIELDS} />,
    [],
  )

  return (
    <BaseSection
      isCard
      title="Patient Profile"
      successMessage="Patient profile was updated"
      renderForm={renderForm}
    >
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <SectionField label="Date of Birth">
            {member.dob
              ? `${formatDateString(member.dob)} (${formatAge(
                  member.dob,
                )} years old)`
              : 'No DoB saved'}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Status">
            <Typography color="primary">{member.status}</Typography>
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Biological Sex">
            {formatGender(member.gender)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Pronoun">
            {formatPronoun(member.pronouns)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Height">
            {formatHeight(member.height)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Preferred Language">
            {member.preferredLanguage}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Weight">
            {formatWeight(member.weight)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Date of Onboarding">
            {formatDateString(member.dateOfOnboarding)}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Race">{member.race}</SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Source">
            {member?.source?.category}{' '}
            {member?.source?.description && (
              <span>({member?.source?.description})</span>
            )}
          </SectionField>
        </Grid>
      </Grid>
    </BaseSection>
  )
}

export default withMemberForm('ProfileCardForm', [
  'firstName',
  'lastName',
  'preferredName',
  'dob',
  'status',
  'height',
  'heightFeet',
  'heightInch',
  'weight',
  'gender',
  'pronouns',
  'race',
  'preferredLanguage',
  'source',
])(ProfileCard)
