import _ from 'lodash'

import { PROGRESS_DETAIL_MAPPINGS } from 'constants/progress'

export const getProgressDetails = (type, score) => {
  const mapping = PROGRESS_DETAIL_MAPPINGS[type].find(
    each => each.min <= score && score <= each.max,
  )

  if (mapping) {
    return mapping
  } else if (score <= PROGRESS_DETAIL_MAPPINGS[type][0].min) {
    return PROGRESS_DETAIL_MAPPINGS[type][0]
  } else {
    return _.last(PROGRESS_DETAIL_MAPPINGS[type])
  }
}
