export const ASSESSMENT_TYPES = {
  PHQ9_GAD7: 'PHQ9_GAD7',
  PHQ9: 'PHQ9',
  GAD7: 'GAD7',
  ASRS: 'ASRS',
  CIDI3: 'CIDI3',
  PCLC: 'PCLC',
  AUDIT: 'AUDIT',
  PHQ15: 'PHQ15',
  EPDS: 'EPDS',
}

const PHQ9AndGAD7ScoreLabels = [
  { label: 'Not at all', score: 0 },
  { label: 'Several days', score: 1 },
  { label: 'More than half the days', score: 2 },
  { label: 'Nearly every day', score: 3 },
]

const ASRSPartAScoreLabels1 = [
  { label: 'Never', score: 0 },
  { label: 'Rarely', score: 0 },
  { label: 'Sometimes', score: 1 },
  { label: 'Often', score: 1 },
  { label: 'Very often', score: 1 },
]

const ASRSPartAScoreLabels2 = [
  { label: 'Never', score: 0 },
  { label: 'Rarely', score: 0 },
  { label: 'Sometimes', score: 0 },
  { label: 'Often', score: 1 },
  { label: 'Very often', score: 1 },
]

const ASRSPartBScoreLabels = [
  { label: 'Never' },
  { label: 'Rarely' },
  { label: 'Sometimes' },
  { label: 'Often' },
  { label: 'Very often' },
]

const CIDI3ScrenningScoreLabels = [{ label: 'No' }, { label: 'Yes' }]

const CIDI3ScrenningSymptomScoreLabels = [
  { label: 'No', score: 0 },
  { label: 'Yes', score: 1 },
]

const PCLCScrenningScoreLabels = [
  { label: 'Not at all', score: 1 },
  { label: 'A little bit', score: 2 },
  { label: 'Moderately', score: 3 },
  { label: 'Quite a bit', score: 4 },
  { label: 'Extremely', score: 5 },
]

const AUDITScoreLabels1 = [
  { label: 'Never', score: 0 },
  { label: 'Montly or less', score: 1 },
  { label: '2 to 4 times a month', score: 2 },
  { label: '2 to 3 times a week', score: 3 },
  { label: '4 or more times a week', score: 4 },
]

const AUDITScoreLabels2 = [
  { label: '1 or 2', score: 0 },
  { label: '3 or 4', score: 1 },
  { label: '5 to 6', score: 2 },
  { label: '7, 8, or 9', score: 3 },
  { label: '10 or more', score: 4 },
]

const AUDITScoreLabels3 = [
  { label: 'Never', score: 0 },
  { label: 'Less than monthly', score: 1 },
  { label: 'Monthly', score: 2 },
  { label: 'Weekly', score: 3 },
  { label: 'Daily or almost daily', score: 4 },
]

const AUDITScoreLabels4 = [
  { label: 'No', score: 0 },
  { label: 'Yes, but not in the last year', score: 2 },
  { label: 'Yes, during the last year', score: 4 },
]

const PHQ15ScoreLabels = [
  { label: 'Not bothered at all', score: 0 },
  { label: 'Bothered a little', score: 1 },
  { label: 'Bothered a lot', score: 2 },
]

export const ASSESSMENT_TYPE_DETAILS = {
  [ASSESSMENT_TYPES.PHQ9_GAD7]: {
    label: 'PHQ-9 + GAD-7',
    field: '',
  },
  [ASSESSMENT_TYPES.PHQ9]: {
    label: 'PHQ-9',
    field: 'PHQ9Scores',
    totalField: 'totalPHQ',
    deltaTotalField: 'deltaTotalPHQ',
    instructions:
      'Over the last 2 weeks, how often have you been bothered by the following?',
    scoreGroups: [
      {
        title: '',
        fields: [
          {
            label: 'Little interest or pleasure in doing things?',
            quesLabel: 'Little interest or pleasure in doing things. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Feeling down, depressed, or hopeless?',
            quesLabel: 'Feeling down, depressed, or hopeless. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Trouble sleeping/sleeping too much?',
            quesLabel:
              'Trouble falling or staying asleep, or sleeping too much. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Feeling tired or having little energy?',
            quesLabel: 'Feeling tired or having little energy. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Poor appetite or overeating?',
            quesLabel: 'Poor appetite or overeating. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Feeling bad about yourself',
            quesLabel:
              'Feeling bad about yourself - or that you are a failure or have let yourself or your family down. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Trouble concentrating on things',
            quesLabel:
              'Trouble concentrating on things, such as reading the newspaper or watching television. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Moving/speaking slowly or fidgety/restless',
            quesLabel:
              'Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Thinking you’d be better off dead/hurting self',
            quesLabel:
              'Thoughts that you would be better off dead, or of hurting yourself. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.GAD7]: {
    label: 'GAD-7',
    field: 'GAD7Scores',
    totalField: 'totalGAD',
    deltaTotalField: 'deltaTotalGAD',
    instructions:
      'Over the last 2 weeks, how often have you been bothered by the following?',
    scoreGroups: [
      {
        title: '',
        fields: [
          {
            label: 'Feeling nervous, anxious or on edge',
            quesLabel: 'Feeling nervous, anxious, or on edge. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Not being able to stop or control worrying',
            quesLabel: 'Not being able to stop or control worrying. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Worrying too much about different things',
            quesLabel: 'Worrying too much about different things. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Trouble relaxing',
            quesLabel: 'Trouble relaxing. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: "Being so restless that it's hard to sit still",
            quesLabel: 'Being so restless that it is hard to sit still. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Becoming easily annoyed or irritable',
            quesLabel: 'Becoming easily annoyed or irritable. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
          {
            label: 'Feeling afraid as if something awful might happen',
            quesLabel: 'Feeling afraid, as if something awful might happen. *',
            possibleScores: PHQ9AndGAD7ScoreLabels,
            required: true,
          },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.ASRS]: {
    label: 'ASRS',
    field: 'ASRSScores',
    labelField: 'ASRSLabels',
    instructions:
      'Please answer the questions below, rating yourself on each of the criteria shown. As you answer each question, select the box that best describes how you have felt and conducted yourself over the past 6 months.',
    scoreGroups: [
      {
        title: 'Part A',
        fields: [
          {
            label:
              'How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?',
            quesLabel:
              'How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done? *',
            possibleScores: ASRSPartAScoreLabels1,
            required: true,
          },
          {
            label:
              'How often do you have difficulty getting things in order when you have to do a task that requires organisation?',
            quesLabel:
              'How often do you have difficulty getting things in order when you have to do a task that requires organisation? *',
            possibleScores: ASRSPartAScoreLabels1,
            required: true,
          },
          {
            label:
              'How often do you have problems remembering appointments or obligations?',
            quesLabel:
              'How often do you have problems remembering appointments or obligations? *',
            possibleScores: ASRSPartAScoreLabels1,
            required: true,
          },
          {
            label:
              'When you have a task that requires a lot of thought, how often do you avoid or delay getting started?',
            quesLabel:
              'When you have a task that requires a lot of thought, how often do you avoid or delay getting started? *',
            possibleScores: ASRSPartAScoreLabels2,
            required: true,
          },
          {
            label:
              'How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?',
            quesLabel:
              'How often do you fidget or squirm with your hands or feet when you have to sit down for a long time? *',
            possibleScores: ASRSPartAScoreLabels2,
            required: true,
          },
          {
            label:
              'How often do you feel overly active and compelled to do things, like you were driven by a motor?',
            quesLabel:
              'How often do you feel overly active and compelled to do things, like you were driven by a motor? *',
            possibleScores: ASRSPartAScoreLabels2,
            required: true,
          },
        ],
      },
      {
        title: 'Part B (Not Scored)',
        baseIndex: 6,
        fields: [
          {
            label:
              'How often do you make careless mistakes when you have to work on a boring or difficult project?',
            quesLabel:
              'How often do you make careless mistakes when you have to work on a boring or difficult project?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often do you have difficulty keeping your attention when you are doing boring or repetitive work?',
            quesLabel:
              'How often do you have difficulty keeping your attention when you are doing boring or repetitive work?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?',
            quesLabel:
              'How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often do you misplace or have difficulty finding things at home or at work?',
            quesLabel:
              'How often do you misplace or have difficulty finding things at home or at work?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often are you distracted by activity or noise around you?',
            quesLabel:
              'How often are you distracted by activity or noise around you?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often do you leave your seat in meetings or other situations in which you are expected to remain seated?',
            quesLabel:
              'How often do you leave your seat in meetings or other situations in which you are expected to remain seated?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label: 'How often do you feel restless or fidgety?',
            quesLabel: 'How often do you feel restless or fidgety?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often do you have difficulty unwinding and relaxing when you have time to yourself?',
            quesLabel:
              'How often do you have difficulty unwinding and relaxing when you have time to yourself?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often do you find yourself talking too much when you are in social situations?',
            quesLabel:
              'How often do you find yourself talking too much when you are in social situations?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?',
            quesLabel:
              'When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label:
              'How often do you have difficulty waiting your turn in situations when turn taking is required?',
            quesLabel:
              'How often do you have difficulty waiting your turn in situations when turn taking is required?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
          {
            label: 'How often do you interrupt others when they are busy?',
            quesLabel: 'How often do you interrupt others when they are busy?',
            possibleScores: ASRSPartBScoreLabels,
            required: false,
          },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.CIDI3]: {
    label: 'CIDI-3',
    field: 'CIDI3Scores',
    instructions:
      'The stem/screening questions must be answered before the symptom questions. The patient’s response to the stem/screening questions will determine their eligibility for the symptom questions.',
    scoreGroups: [
      {
        title: 'Stem/Screening Question (Not Scored)',
        fields: [
          {
            label:
              'Some people have periods lasting several days when they feel much more excited and full of energy than usual. Their minds go too fast. They talk a lot. They are very restless or unable to sit still and they sometimes do things that are unusual for them, such as driving too fast or spending too much money. Have you ever had a period like this lasting several days or longer?',
            quesLabel:
              'Some people have periods lasting several days when they feel much more excited and full of energy than usual. Their minds go too fast. They talk a lot. They are very restless or unable to sit still and they sometimes do things that are unusual for them, such as driving too fast or spending too much money. Have you ever had a period like this lasting several days or longer? *',
            possibleScores: CIDI3ScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Have you ever had a period lasting several days or longer when most of the time you were so irritable or grouchy that you either started arguments, shouted at people or hit people?',
            quesLabel:
              'Have you ever had a period lasting several days or longer when most of the time you were so irritable or grouchy that you either started arguments, shouted at people or hit people? *',
            possibleScores: CIDI3ScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'People who have episodes like this often have changes in their thinking and behavior at the same time, like being more talkative, needing very little sleep, being very restless, going on buying sprees, and behaving in many ways they would normally think inappropriate. Did you ever had any of these changes during your episodes of being excited and full or energy or very irritable or grouchy?',
            quesLabel:
              'People who have episodes like this often have changes in their thinking and behavior at the same time, like being more talkative, needing very little sleep, being very restless, going on buying sprees, and behaving in many ways they would normally think inappropriate. Did you ever had any of these changes during your episodes of being excited and full or energy or very irritable or grouchy?',
            possibleScores: CIDI3ScrenningScoreLabels,
            required: false,
          },
        ],
      },
      {
        title: 'Symptom Question',
        baseIndex: 6,
        fields: [
          {
            label:
              'Were you so irritable that you either started arguments, shouted at people, or hit people?',
            quesLabel:
              'Were you so irritable that you either started arguments, shouted at people, or hit people?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label:
              'Did you become so restless or fidgety that you paced up and down or couldn’t stand still?',
            quesLabel:
              'Did you become so restless or fidgety that you paced up and down or couldn’t stand still?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label:
              'Did you do anything else that wasn’t usual for you - like talking about things you would normally keep private, or acting in ways that you would usually find embarrassing?',
            quesLabel:
              'Did you do anything else that wasn’t usual for you - like talking about things you would normally keep private, or acting in ways that you would usually find embarrassing?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label:
              'Did you try to do things that were impossible to do, like taking on large amounts of work?',
            quesLabel:
              'Did you try to do things that were impossible to do, like taking on large amounts of work?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label: 'Did you constantly keep changing your plans or activities?',
            quesLabel:
              'Did you constantly keep changing your plans or activities?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label:
              'Did you find it hard to keep your mind on what you were doing?',
            quesLabel:
              'Did you find it hard to keep your mind on what you were doing?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label:
              'Did your thoughts seem to jump from one thing to another or race through your head so fast you couldn’t keep track of them?',
            quesLabel:
              'Did your thoughts seem to jump from one thing to another or race through your head so fast you couldn’t keep track of them?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label:
              'Did you sleep far less than usual and still not get tired or sleepy?',
            quesLabel:
              'Did you sleep far less than usual and still not get tired or sleepy?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
          {
            label:
              'Did you spend so much more money than usual that it caused you to have financial trouble?',
            quesLabel:
              'Did you spend so much more money than usual that it caused you to have financial trouble?',
            possibleScores: CIDI3ScrenningSymptomScoreLabels,
            required: false,
          },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.PCLC]: {
    label: 'PCL-C',
    field: 'PCLCScores',
    instructions:
      'Below is a list of problems and complaints that people sometimes have in response to stressful life experiences. Please read each one carefully, pick the answer that indicates how much you have been bothered by that problem in the last month.',
    scoreGroups: [
      {
        title: '',
        fields: [
          {
            label:
              'Repeated, disturbing memories, thoughts, or images of a stressful experience from the past?',
            quesLabel:
              'Repeated, disturbing memories, thoughts, or images of a stressful experience from the past? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Repeated, disturbing dreams of a stressful experience from the past?',
            quesLabel:
              'Repeated, disturbing dreams of a stressful experience from the past? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Suddenly acting or feeling as if a stressful experience were happening again (as if you were reliving it)?',
            quesLabel:
              'Suddenly acting or feeling as if a stressful experience were happening again (as if you were reliving it)? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Feeling very upset when something reminded you of a stressful experience from the past?',
            quesLabel:
              'Feeling very upset when something reminded you of a stressful experience from the past? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Having physical reactions (e.g., heart pounding, trouble breathing, or sweating) when something reminded you of a stressful experience from the past?',
            quesLabel:
              'Having physical reactions (e.g., heart pounding, trouble breathing, or sweating) when something reminded you of a stressful experience from the past?',
            possibleScores: PCLCScrenningScoreLabels,
            required: false,
          },
          {
            label:
              'Avoid thinking about or talking about a stressful experience from the past or avoid having feelings related to it?',
            quesLabel:
              'Avoid thinking about or talking about a stressful experience from the past or avoid having feelings related to it? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Avoid activities or situations because they remind you of a stressful experience from the past?',
            quesLabel:
              'Avoid activities or situations because they remind you of a stressful experience from the past? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Trouble remembering important parts of a stressful experience from the past?',
            quesLabel:
              'Trouble remembering important parts of a stressful experience from the past? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Loss of interest in things that you used to enjoy?',
            quesLabel: 'Loss of interest in things that you used to enjoy? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Feeling distant or cut off from other people?',
            quesLabel: 'Feeling distant or cut off from other people? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label:
              'Feeling emotionally numb or being unable to have loving feelings for those close to you?',
            quesLabel:
              'Feeling emotionally numb or being unable to have loving feelings for those close to you? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Feeling as if your future will somehow be cut short?',
            quesLabel: 'Feeling as if your future will somehow be cut short? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Trouble falling or staying asleep?',
            quesLabel: 'Trouble falling or staying asleep? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Feeling irritable or having angry outbursts?',
            quesLabel: 'Feeling irritable or having angry outbursts? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Having difficulty concentrating?',
            quesLabel: 'Having difficulty concentrating? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Being "super alert" or watchful on guard?',
            quesLabel: 'Being “super alert” or watchful on guard? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
          {
            label: 'Feeling jumpy or easily startled?',
            quesLabel: 'Feeling jumpy or easily startled? *',
            possibleScores: PCLCScrenningScoreLabels,
            required: true,
          },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.AUDIT]: {
    label: 'AUDIT',
    field: 'AUDITScores',
    instructions:
      'Read questions as written. Record answers carefully. Begin the AUDIT by saying "Now I am going to ask you some questions about your use of alcoholic beverages during this past year." Explain what is meant by “alcoholic beverages” by using local examples of beer, wine, vodka, etc. Code answers in terms of "standard drinks".',
    scoreGroups: [
      {
        title: '',
        fields: [
          {
            label: 'How often do you have a drink containing alcohol?',
            quesLabel: 'How often do you have a drink containing alcohol? *',
            possibleScores: AUDITScoreLabels1,
            required: true,
          },
          {
            label:
              'How many drinks containing alcohol do you have on a typical day when you are drinking?',
            quesLabel:
              'How many drinks containing alcohol do you have on a typical day when you are drinking?',
            possibleScores: AUDITScoreLabels2,
            required: false,
          },
          {
            label: 'How often do you have six or more drinks on one occasion?',
            quesLabel:
              'How often do you have six or more drinks on one occasion?',
            possibleScores: AUDITScoreLabels3,
            required: false,
          },
          {
            label:
              'How often during the last year have you found that you were not able to stop drinking once you had started?',
            quesLabel:
              'How often during the last year have you found that you were not able to stop drinking once you had started?',
            possibleScores: AUDITScoreLabels3,
            required: false,
          },
          {
            label:
              'How often during the last year have you failed to do what was normally expected from you because of drinking?',
            quesLabel:
              'How often during the last year have you failed to do what was normally expected from you because of drinking?',
            possibleScores: AUDITScoreLabels3,
            required: false,
          },
          {
            label:
              'How often during the last year have you needed a first drink in the morning to get yourself going after a heavy drinking session?',
            quesLabel:
              'How often during the last year have you needed a first drink in the morning to get yourself going after a heavy drinking session?',
            possibleScores: AUDITScoreLabels3,
            required: false,
          },
          {
            label:
              'How often during the last year have you had a feeling of guilt or remorse after drinking?',
            quesLabel:
              'How often during the last year have you had a feeling of guilt or remorse after drinking?',
            possibleScores: AUDITScoreLabels3,
            required: false,
          },
          {
            label:
              'How often during the last year have you been unable to remember what happened the night before because you had been drinking?',
            quesLabel:
              'How often during the last year have you been unable to remember what happened the night before because you had been drinking?',
            possibleScores: AUDITScoreLabels3,
            required: false,
          },
          {
            label:
              'Have you or someone else been injured as a result of your drinking?',
            quesLabel:
              'Have you or someone else been injured as a result of your drinking? *',
            possibleScores: AUDITScoreLabels4,
            required: true,
          },
          {
            label:
              'Has a relative or friend or a doctor or another health worker been concerned about your drinking or suggested you cut down?',
            quesLabel:
              'Has a relative or friend or a doctor or another health worker been concerned about your drinking or suggested you cut down? *',
            possibleScores: AUDITScoreLabels4,
            required: true,
          },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.PHQ15]: {
    label: 'PHQ-15',
    field: 'PHQ15Scores',
    instructions:
      'The questions below ask about these feelings in more detail and especially how often you (the individual receiving care) have been bothered by a list of symptoms during the past 7 days. During the past 7 days, how much have you been bothered by any of the following problems?',
    scoreGroups: [
      {
        title: '',
        fields: [
          {
            label: 'Stomach pain',
            quesLabel: 'Stomach pain *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Back pain',
            quesLabel: 'Back pain *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Pain in your arms, legs, or joints (knees, hips, etc.)',
            quesLabel:
              'Pain in your arms, legs, or joints (knees, hips, etc.) *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label:
              'Menstrual cramps or other problems with your periods (Women only)',
            quesLabel:
              'Menstrual cramps or other problems with your periods (Women only)',
            possibleScores: PHQ15ScoreLabels,
            required: false,
          },
          {
            label: 'Headaches',
            quesLabel: 'Headaches *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Chest pain',
            quesLabel: 'Chest pain *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Dizziness',
            quesLabel: 'Dizziness *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Fainting spells',
            quesLabel: 'Fainting spells *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Feeling your heart pound or race',
            quesLabel: 'Feeling your heart pound or race *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Shortness of breath',
            quesLabel: 'Shortness of breath *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Pain or problem during sexual intercourse',
            quesLabel: 'Pain or problem during sexual intercourse *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Constipation, loose bowels, or diarrhea',
            quesLabel: 'Constipation, loose bowels, or diarrhea *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Nauesa, gas or indigestion',
            quesLabel: 'Nauesa, gas or indigestion *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Feeling tired or having low energy',
            quesLabel: 'Feeling tired or having low energy *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
          {
            label: 'Trouble sleeping',
            quesLabel: 'Trouble sleeping *',
            possibleScores: PHQ15ScoreLabels,
            required: true,
          },
        ],
      },
    ],
  },
  [ASSESSMENT_TYPES.EPDS]: {
    label: 'EPDS',
    field: 'EPDSScores',
    instructions:
      'As you are pregnant or have recently had a baby, we would like to know how you are feeling. Please check the answer that comes closest to how you have felt in the past 7 days, not just how you feel today. In the past 7 days:',
    scoreGroups: [
      {
        title: '',
        fields: [
          {
            label:
              'I have been able to laugh and see the funny side of things.',
            quesLabel:
              'I have been able to laugh and see the funny side of things. *',
            possibleScores: [
              { label: 'As much as I always could', score: 0 },
              { label: 'Not quite so much now', score: 1 },
              { label: 'Definitely not so much now', score: 2 },
              { label: 'Not at all', score: 3 },
            ],
            required: true,
          },
          {
            label: 'I have looked forward with enjoyment to things.',
            quesLabel: 'I have looked forward with enjoyment to things. *',
            possibleScores: [
              { label: 'As much as I ever did', score: 0 },
              { label: 'Rather less than I used to', score: 1 },
              { label: 'Definitely less than I used to', score: 2 },
              { label: 'Hardly at all', score: 3 },
            ],
            required: true,
          },
          {
            label: 'I have blamed myself unnecessarily when things went wrong.',
            quesLabel:
              'I have blamed myself unnecessarily when things went wrong. *',
            possibleScores: [
              { label: 'Yes, most of the time', score: 3 },
              { label: 'Yes, some of the time', score: 2 },
              { label: 'Not very often', score: 1 },
              { label: 'No, never', score: 0 },
            ],
            required: true,
          },
          {
            label: 'I have been anxious or worried for no good reason.',
            quesLabel: 'I have been anxious or worried for no good reason. *',
            possibleScores: [
              { label: 'No, not at all', score: 0 },
              { label: 'Hardly ever', score: 1 },
              { label: 'Yes, sometimes', score: 2 },
              { label: 'Yes, very often', score: 3 },
            ],
            required: false,
          },
          {
            label: 'I have felt scared or panicky for no very good reason.',
            quesLabel:
              'I have felt scared or panicky for no very good reason. *',
            possibleScores: [
              { label: 'Yes, quite a lot', score: 3 },
              { label: 'Yes, sometimes', score: 2 },
              { label: 'No, not much', score: 1 },
              { label: 'No, not at all', score: 0 },
            ],
            required: false,
          },
          {
            label: 'Things have been getting on top of me.',
            quesLabel: 'Things have been getting on top of me. *',
            possibleScores: [
              {
                label:
                  'Yes, most of the time I haven’t been able to cope at all',
                score: 3,
              },
              {
                label: 'Yes, sometimes I haven’t been coping as well as usual',
                score: 2,
              },
              {
                label: 'No, most of the time I have coped quite well',
                score: 2,
              },
              { label: 'No, I have been coping as well as ever', score: 0 },
            ],
            required: false,
          },
          {
            label:
              'I have been so unhappy that I have had difficulty sleeping.',
            quesLabel:
              'I have been so unhappy that I have had difficulty sleeping. *',
            possibleScores: [
              { label: 'Yes, most of the time', score: 3 },
              { label: 'Yes, sometimes', score: 2 },
              { label: 'Not very often', score: 1 },
              { label: 'No, not at all', score: 0 },
            ],
            required: false,
          },
          {
            label: 'I have felt sad or miserable.',
            quesLabel: 'I have felt sad or miserable. *',
            possibleScores: [
              { label: 'Yes, most of the time', score: 3 },
              { label: 'Yes, quite often', score: 2 },
              { label: 'Not very often', score: 1 },
              { label: 'No, not at all', score: 0 },
            ],
            required: false,
          },
          {
            label: 'I have been so unhappy that I have been crying.',
            quesLabel: 'I have been so unhappy that I have been crying. *',
            possibleScores: [
              { label: 'Yes, most of the time', score: 3 },
              { label: 'Yes, quite often', score: 2 },
              { label: 'Only occasionally', score: 1 },
              { label: 'No, never', score: 0 },
            ],
            required: false,
          },
          {
            label: 'The thought of harming myself has occurred to me.',
            quesLabel: 'The thought of harming myself has occurred to me. *',
            possibleScores: [
              { label: 'Yes, quite often', score: 3 },
              { label: 'Sometimes', score: 2 },
              { label: 'Hardly ever', score: 1 },
              { label: 'Never', score: 0 },
            ],
            required: false,
          },
        ],
      },
    ],
  },
}

export const SEND_REQUEST_MENU_OPTIONS = [ASSESSMENT_TYPES.PHQ9_GAD7].map(
  type => ({
    type,
    label: ASSESSMENT_TYPE_DETAILS[type].label,
  }),
)

export const NEW_ASSESSMENT_MENU_OPTIONS = [
  ASSESSMENT_TYPES.PHQ9_GAD7,
  ASSESSMENT_TYPES.PHQ9,
  ASSESSMENT_TYPES.GAD7,
  ASSESSMENT_TYPES.ASRS,
  ASSESSMENT_TYPES.CIDI3,
  ASSESSMENT_TYPES.PCLC,
  ASSESSMENT_TYPES.AUDIT,
  ASSESSMENT_TYPES.PHQ15,
  ASSESSMENT_TYPES.EPDS,
].map(type => ({
  type,
  label: ASSESSMENT_TYPE_DETAILS[type].label,
}))

export const COLOR_MAPPINGS = {
  RED: {
    color: '#EB5757',
    backgroundColor: '#FEDEDE',
  },
  YELLOW: {
    color: '#BA940F',
    backgroundColor: '#FFF0B9',
  },
  GREEN: {
    color: '#36BCA8',
    backgroundColor: '#DAEFED',
  },
}

export const PROGRESS_DETAIL_MAPPINGS = {
  [ASSESSMENT_TYPES.PHQ9]: [
    {
      min: 0,
      max: 4,
      tooltip: 'Minimal (MDD)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 5,
      max: 9,
      tooltip: 'Mild (MDD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 10,
      max: 14,
      tooltip: 'Moderate (MDD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 15,
      max: 19,
      tooltip: 'Moderately severe (MDD)',
      ...COLOR_MAPPINGS.RED,
    },
    {
      min: 20,
      max: 27,
      tooltip: 'Severe (MDD)',
      ...COLOR_MAPPINGS.RED,
    },
  ],
  [ASSESSMENT_TYPES.GAD7]: [
    {
      min: 0,
      max: 4,
      tooltip: 'Minimal (GAD)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 5,
      max: 9,
      tooltip: 'Mild (GAD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 10,
      max: 14,
      tooltip: 'Moderate (GAD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 15,
      max: 21,
      tooltip: 'Severe (GAD)',
      ...COLOR_MAPPINGS.RED,
    },
  ],
  [ASSESSMENT_TYPES.ASRS]: [
    {
      min: 0,
      max: 3,
      tooltip: 'Minimal (ADHD)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 4,
      max: 6,
      tooltip: 'Positive (ADHD)',
      ...COLOR_MAPPINGS.RED,
    },
  ],
  [ASSESSMENT_TYPES.CIDI3]: [
    {
      min: 0,
      max: 4,
      tooltip: 'Very low risk (BPD)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 5,
      max: 5,
      tooltip: 'Low risk (BPD)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 6,
      max: 6,
      tooltip: 'Moderate risk (BPD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 7,
      max: 8,
      tooltip: 'High risk (BPD)',
      ...COLOR_MAPPINGS.RED,
    },
    {
      min: 9,
      max: 9,
      tooltip: 'Very high risk (BPD)',
      ...COLOR_MAPPINGS.RED,
    },
  ],
  [ASSESSMENT_TYPES.PCLC]: [
    {
      min: 17,
      max: 27,
      tooltip: 'No risk (PTSD)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 28,
      max: 29,
      tooltip: 'Some risk (PTSD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 30,
      max: 44,
      tooltip: 'Moderately high risk (PTSD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 45,
      max: 85,
      tooltip: 'High risk (PTSD)',
      ...COLOR_MAPPINGS.RED,
    },
  ],
  [ASSESSMENT_TYPES.AUDIT]: [
    {
      min: 0,
      max: 7,
      tooltip: 'Low risk (SUD-Alcohol)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 8,
      max: 15,
      tooltip: 'Moderate risk (SUD-Alcohol)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 16,
      max: 19,
      tooltip: 'Moderately high risk (SUD-Alcohol)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 20,
      max: 40,
      tooltip: 'High risk (SUD-Alcohol)',
      ...COLOR_MAPPINGS.RED,
    },
  ],
  [ASSESSMENT_TYPES.PHQ15]: [
    {
      min: 0,
      max: 4,
      tooltip: 'Minimal risk (SSD)',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 5,
      max: 9,
      tooltip: 'Low risk (SSD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 10,
      max: 14,
      tooltip: 'Medium risk (SSD)',
      ...COLOR_MAPPINGS.YELLOW,
    },
    {
      min: 15,
      max: 19,
      tooltip: 'High risk (SSD)',
      ...COLOR_MAPPINGS.RED,
    },
  ],
  [ASSESSMENT_TYPES.EPDS]: [
    {
      min: 0,
      max: 9,
      tooltip: 'Minimal',
      ...COLOR_MAPPINGS.GREEN,
    },
    {
      min: 10,
      max: 30,
      tooltip: 'Possible',
      ...COLOR_MAPPINGS.RED,
    },
  ],
}
