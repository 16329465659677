import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import { UpdateKitNumber } from 'components/Member/MemberPGx/Dialogs/UpdateKitNumber'
import { CancelRegistration } from 'components/Member/MemberPGx/Dialogs/CancelRegistration'
import { formatDateInMMDDYYYY } from 'utils/formatters'

export default function PGxKitCard({
  kitNumber,
  handleUpdate,
  kitDate,
  fullName,
}) {
  const text = {
    title: 'Kit Number',
    update: kitNumber ? 'Cancel Registration' : 'Register',
    noResult: 'No kit number entered',
  }

  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const [openPrompt, setOpenPrompt] = useState(false)

  return (
    <>
      {openPrompt && !kitNumber && (
        <UpdateKitNumber
          title={`Register ${text.title}`}
          onUpdate={kitNumber => {
            handleUpdate(kitNumber)
          }}
          open={openPrompt}
          handleClose={() => {
            setOpenPrompt(false)
          }}
        />
      )}
      {openPrompt && !!kitNumber && (
        <CancelRegistration
          title="Cancel Registration"
          onUpdate={() => {
            handleUpdate('')
          }}
          open={openPrompt}
          handleClose={() => {
            setOpenPrompt(false)
          }}
          message={`Would you like to cancel PGx kit registration for ${fullName}? Once cancelled, please reach out to the primary POC at our laboratory to prevent the kit from being processed.`}
        />
      )}

      <BaseCard>
        <BaseCardHeader
          title={text.title}
          actionOnClick={() => {
            setOpenPrompt(true)
          }}
          actionText={text.update}
          isNegative={!!kitNumber}
        />
        <BaseRow
          withDivider
          key={`${kitNumber}`}
          columns={
            <Grid container alignItems="center" justifyContent="space-evenly">
              <Grid
                item
                xs={kitNumber ? 7 : 12}
                className={rxClasses.columnPadding}
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(rxClasses.typography, rxClasses.fontSize12)}
                >
                  {!isEmpty(kitNumber) ? kitNumber : text.noResult}
                </Typography>
              </Grid>
              {!!kitNumber && (
                <Grid
                  item
                  xs={5}
                  className={clsx(
                    rxClasses.columnPadding,
                    rxClasses.textAlignRight,
                  )}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      rxClasses.typography,
                      rxClasses.fontSize12,
                      rxClasses.textItalic,
                    )}
                    align="right"
                  >
                    {`Updated ${formatDateInMMDDYYYY(kitDate)}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          }
          title={text.title}
        />
      </BaseCard>
    </>
  )
}
