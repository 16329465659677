import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Link from '@material-ui/core/Link'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CloseIcon from '@material-ui/icons/Close'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { ExternalProviderShareTypes } from 'constants/selectOptions'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },

  yes: {
    color: palette.primary.main,
  },

  no: {
    color: palette.accentRed.main,
  },
}))

const ExternalProviderInfo = ({ externalProvider, shareTypes }) => {
  const classes = useStyles()
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Box my={1} className={classes.root}>
      <Box ml={7}>
        <Collapse in={showDetails}>
          <Box height={48}>
            {externalProvider.address1} {externalProvider.address2}
            <br />
            {externalProvider.city}, {externalProvider.state}{' '}
            {externalProvider.zip}
          </Box>

          <Box mb={1}>
            Tel: {externalProvider.phone || 'N/A'}
            <br />
            Fax: {externalProvider.fax || 'N/A'}
            <br />
            Email: {externalProvider.email || 'N/A'}
          </Box>

          {ExternalProviderShareTypes.map(shareType => (
            <Box key={shareType} display="flex" alignItems="center">
              {shareTypes.includes(shareType) ? (
                <RadioButtonUncheckedIcon
                  className={classes.yes}
                  fontSize="small"
                />
              ) : (
                <CloseIcon className={classes.no} fontSize="small" />
              )}

              <Box ml={2}>{shareType}</Box>
            </Box>
          ))}
        </Collapse>

        <Box mt={1}>
          <Link onClick={() => setShowDetails(val => !val)}>
            <Box
              component="span"
              fontSize="caption.fontSize"
              display="inline-flex"
              alignItems="center"
            >
              Show More{' '}
              {showDetails ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default ExternalProviderInfo
