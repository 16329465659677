import { makeStyles } from '@material-ui/core/styles'
import BaseRow from 'components/Shared/Card/BaseRow'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(0.0),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.backgroundGray.main,
    fontSize: '12px',
    color: theme.palette.text.primary,
  },
  container: {
    marginBottom: -8,
  },
}))

const ArrayFieldChipSectionLayout = ({
  rows,
  displayProperty,
  emptyPlaceholder = '',
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {!rows?.length ? (
        <BaseRow emptyPlaceholder={emptyPlaceholder} />
      ) : (
        rows?.length &&
        rows.map((row, i) => {
          return (
            <Chip
              key={`${displayProperty}-${i}`}
              label={row[displayProperty]}
              className={classes.chip}
            />
          )
        })
      )}
    </div>
  )
}

export default ArrayFieldChipSectionLayout
