import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'econCondition',
  label: 'Economic Condition',
  componentProps: {
    multiline: true,
  },
}

const EconomicConditionSection = props => (
  <SingleFieldSection {...props} title="Economic Condition" field={FIELD} />
)

export default withMemberForm('EconomicConditionSectionForm', [
  'econCondition',
])(EconomicConditionSection)
