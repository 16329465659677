import clsx from 'clsx'
import { orderBy } from 'lodash'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import EditDialog from './EditDialog'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  proceduresList: {
    padding: '12px',
    borderTop: '1px solid #e3e3e3',
    width: '100%',
  },
  procedureChip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.backgroundGray.main,
    fontSize: '12px',
    color: theme.palette.text.primary,
  },
  empty: {
    padding: '16px',
  },
}))

export default function Procedures({
  isNoShow,
  isFinalized,
  noteCodes,
  updateProcedure,
  withDialog = false,
}) {
  const theme = useTheme()
  const classes = useStyles()
  const rxClasses = useRxStyles(theme)
  const [open, setOpen] = useState(false)
  const { cptCodes, isLoading } = useSelector(state => ({
    isLoading: state.notes.isLoading,
    cptCodes: orderBy(state.notes.cptCodes, 'category'),
  }))

  const [selectedCodes, setSelectedCodes] = useState([])

  useEffect(() => {
    const encounterSelected = cptCodes.filter(cpt =>
      noteCodes.includes(cpt.code),
    )

    setSelectedCodes(encounterSelected)
  }, [cptCodes, noteCodes])

  const handleUpdate = fields => {
    setOpen(false)
    updateProcedure(fields)
  }

  const ProcedureList = ({ selectedCodes }) => {
    return (
      <div className={classes.proceduresList}>
        {selectedCodes.map((code, i) => {
          return (
            <Chip
              key={`${code.code}-${i}`}
              label={`${code.code} ${code.display}`}
              className={classes.procedureChip}
            />
          )
        })}
      </div>
    )
  }

  const noProceduresSelected = !noteCodes || !noteCodes.length

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <BaseCard>
      <BaseCardHeader
        bold={true}
        title={'Procedures'}
        actionOnClick={withDialog ? () => setOpen(true) : () => null}
        actionText={withDialog ? 'Select Procedure' : null}
      />

      {withDialog && (
        <EditDialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onUpdate={handleUpdate}
          selectedCodes={selectedCodes}
          isNoShow={isNoShow}
        />
      )}

      {isNoShow || noProceduresSelected ? (
        <BaseRow
          emptyPlaceholder={
            <Grid item xs={12} className={classes.empty}>
              <Typography
                component="span"
                variant="body1"
                className={clsx(
                  rxClasses.typography,
                  rxClasses.fontSize14,
                  theme.palette.primary,
                )}
              >
                {!isNoShow &&
                  !isFinalized &&
                  noProceduresSelected &&
                  'Please select appropriate CPT code(s)'}
                {isNoShow && <span style={{ color: '#eb5757' }}>No-show </span>}
              </Typography>
            </Grid>
          }
          withDivider={true}
        />
      ) : (
        <ProcedureList selectedCodes={selectedCodes} />
      )}
    </BaseCard>
  )
}
