import { withFormik } from 'formik'
import { withSnackbar } from 'notistack'
import flowRight from 'lodash/fp/flowRight'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import { States, ExternalProviderTypes } from 'constants/selectOptions'
import { externalProviderSchema } from 'utils/validationSchemas'
import MultiFieldsSectionForm from 'components/Shared/Sections/SectionForms/MultiFieldsSectionForm'

const DEFAULT_EXTERNAL_PROVIDER = {
  name: '',
  type: '',
  phone: '',
  fax: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  zip: '',
  state: '',
}

const FIELDS = [
  {
    path: 'name',
    label: 'Name',
  },
  {
    path: 'type',
    label: 'Type of provider',
    componentProps: {
      select: true,
      options: ExternalProviderTypes,
    },
  },
  {
    path: 'phone',
    label: 'Phone Number',
  },
  {
    path: 'fax',
    label: 'Fax Number',
  },
  {
    path: 'email',
    label: 'Email',
  },
  {
    path: 'address1',
    label: 'Address Line 1',
  },
  {
    path: 'address2',
    label: 'Address Line 2',
  },
  {
    path: 'city',
    label: 'City',
  },
  {
    path: 'zip',
    label: 'Zip Code',
    cols: 6,
  },
  {
    path: 'state',
    label: 'State',
    componentProps: {
      select: true,
      options: States,
    },
    cols: 6,
  },
]

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
  },
}))

function ExternalProviderModalForm({
  open,
  externalProvider,
  onClose,
  isValid,
  isSubmitting,
  resetForm,
  handleSubmit,
}) {
  const classes = useStyles()

  const handleClose = () => {
    onClose()
    resetForm()
  }

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box fontSize="h3.fontSize">
          {externalProvider ? 'Update' : 'Create new'} external provider profile
        </Box>
      </DialogTitle>

      <DialogContent>
        <MultiFieldsSectionForm fields={FIELDS} />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting && (
            <>
              <CircularProgress size={20} />
              &nbsp;
            </>
          )}
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default flowRight(
  withSnackbar,
  withFormik({
    displayName: 'ExternalProviderModalForm',
    enableReinitialize: true,

    validationSchema: externalProviderSchema,

    mapPropsToValues({ externalProvider }) {
      return externalProvider || DEFAULT_EXTERNAL_PROVIDER
    },

    async handleSubmit(
      values,
      { props: { externalProvider, enqueueSnackbar, onSubmit, onClose } },
    ) {
      try {
        await onSubmit(values)

        onClose()

        const successMessage = `External provider has been ${
          externalProvider ? 'updated' : 'created'
        } successfully`

        enqueueSnackbar(successMessage, { variant: 'success' })
      } catch (e) {
        enqueueSnackbar(e.toString() || 'Error in submitting form', {
          variant: 'error',
        })
      }
    },
  }),
)(ExternalProviderModalForm)
