import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'sexuallyActive',
  label: 'Sexual Activeness',
  componentProps: {
    multiline: true,
  },
}

const SexualActivenessSection = props => (
  <SingleFieldSection {...props} title="Sexual Activeness" field={FIELD} />
)

export default withMemberForm('SexualActivenessSectionForm', [
  'sexuallyActive',
])(SexualActivenessSection)
