import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import { useSnackbar } from 'notistack'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'
import { formatDateString } from 'utils/formatters'

import SectionField from 'components/Shared/Sections/SectionField'
import CardSectionContent from 'components/Shared/Sections/SectionContents/CardSectionContent'
import clsx from 'clsx'

export default function MemberAccountCard({ member }) {
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()

  const sendPasswordReset = async () => {
    try {
      const url = `${API_BASE_URL}/mp/password/reset/send-link`
      const response = await postRequest({ url, body: { email: member.email } })
      if (response.success) {
        enqueueSnackbar('Password reset link sent', { variant: 'success' })
      }
    } catch (error) {
      enqueueSnackbar('Failed to send password request', {
        variant: 'error',
      })
    }
  }

  return (
    <CardSectionContent title="Account Management">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <SectionField label="Last Sign In">
            {formatDateString(member.lastSignIn) || 'N/A'}
          </SectionField>
        </Grid>

        <Grid item sm={6}>
          <SectionField label="Manage Account">
            <div
              className={clsx(
                'MuiTypography-root',
                'MuiLink-root',
                'MuiLink-underlineHover',
                'MuiTypography-colorPrimary',
              )}
              onClick={sendPasswordReset}
            >
              Send Reset Password Email
            </div>
          </SectionField>
        </Grid>

        {/* Row 3, Col 1 */}
        <Grid item sm={6}>
          <SectionField label="&nbsp;">&nbsp;</SectionField>
        </Grid>

        {/* Row 4, Col 2 */}
        <Grid item sm={6}>
          <SectionField label="Manage ID">
            <Link href="#">Remove ID Verification</Link>
          </SectionField>
        </Grid>
      </Grid>
    </CardSectionContent>
  )
}
