import { useCallback, useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import _ from 'lodash'
import { useFetchComments } from 'hooks/api/comments'

const HEIGHT_OF_COMMENT_BOX = 75.5 + 32

export default function useComments({ userID, memberID, reverse }) {
  const DEFAULT_PAGE_SIZE = 9
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const [commentsLastUpdated, setCommentsLastUpdated] = useState(null)
  const fetchComments = useFetchComments()
  const { enqueueSnackbar } = useSnackbar()

  const refreshFeed = () => {
    if (comments.length < total) {
      loadMore()
    }
    setCommentsLastUpdated(new Date())
  }

  const loadComments = useCallback(async () => {
    const getInfinitescrollElement = () => {
      const element = document.getElementById('scroll-container')
      return element
    }

    const getPageSize = () => {
      const element = getInfinitescrollElement()
      if (element) {
        return Math.floor(element.clientHeight / HEIGHT_OF_COMMENT_BOX) + 1
      }
      return DEFAULT_PAGE_SIZE
    }

    setLoading(true)
    try {
      const data = await fetchComments({
        userID,
        memberID,
        page,
        reverse: Boolean(reverse),
        limit: reverse ? getPageSize() : DEFAULT_PAGE_SIZE,
      })
      setComments(comments => _.uniqBy([...comments, ...data.results], '_id'))
      setTotal(data.total)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }, [page, reverse, userID, memberID, enqueueSnackbar, fetchComments])

  useEffect(() => {
    setComments([])
    setTotal(0)
  }, [userID, memberID])

  const loadMore = useCallback(() => {
    setPage(page => page + 1)
  }, [])

  const refetch = useCallback(() => {
    setComments([])
    setPage(0)
    loadComments(0)
  }, [loadComments])

  useEffect(() => {
    if (!(memberID || userID)) return
    loadComments(page)
    setLoading(false)
    // eslint-disable-next-line
  }, [page, commentsLastUpdated, loadComments, memberID])

  return {
    comments,
    hasMore: comments.length < total,
    loading,
    total,
    refetch,
    loadMore,
    refreshFeed,
  }
}
