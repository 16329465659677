import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LoadingModal from 'components/Modals/LoadingModal'

import { useSnackbar } from 'notistack'
import { isEmpty } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useLayoutStyles, useMemberRx } from 'hooks'
import usePutMember from 'hooks/api/members/usePutMember'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'

import { useTheme } from '@material-ui/core/styles'

import { formatPhoneNumber } from 'utils/formatters'
import { AddPharmacy } from './Dialogs/Pharmacy/AddPharmacy'
import { DeletePrompt } from './Dialogs/DeletePrompt'

import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'
import { useRxStyles } from 'hooks'

export default function Pharmacies({ pharmacies }) {
  const { loading, memberId, userId, updated, setUpdated } = useMemberRx()
  const putMember = usePutMember()
  const postRequest = usePostRequest()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const rxClasses = useRxStyles(theme)

  const [pharmacyToDelete, setPharmacyToDelete] = useState(null)
  const [openSearchModal, setOpenSearchModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  useEffect(() => {
    if (updated) {
      setOpenSearchModal(false)
    }
  }, [updated])

  const Rows = ({ data }) => {
    if (isEmpty(data)) {
      return <BaseRow withDivider />
    }

    return data.map((pharmacy, i) => {
      const formattedAddress = `${pharmacy.Address1} ${pharmacy.Address2}, ${pharmacy.City}, ${pharmacy.State}, ${pharmacy.ZipCode}`
      return (
        <BaseRow
          withDivider
          key={`${pharmacy.PharmacyId}`}
          columns={
            <Grid
              key={`${pharmacy.StoreName}-${i}`}
              container
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.typography,
                    rxClasses.fontWeight500,
                    rxClasses.fontSize12,
                    theme.palette.primary,
                  )}
                >
                  {pharmacy.StoreName}
                </Typography>
                {pharmacy.IsDefault && (
                  <Chip
                    label="Preferred"
                    className={clsx(rxClasses.chip, layoutClasses.pl1)}
                    size="small"
                  />
                )}
              </Grid>
              <Grid item xs={3} className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.fontSize12,
                    rxClasses.typography,
                    theme.typography.fontWeightRegular,
                  )}
                >
                  {formattedAddress}
                </Typography>
              </Grid>
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.typography,
                    rxClasses.fontSize12,
                    theme.typography.fontWeightRegular,
                  )}
                >
                  {formatPhoneNumber(pharmacy.PrimaryPhone)}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                className={clsx(
                  rxClasses.columnPadding,
                  rxClasses.fontSize12,
                  rxClasses.textAlignRight,
                )}
              >
                {!pharmacy.IsDefault && (
                  <span
                    onClick={() => {
                      handleClickSetAsPreferred(pharmacy)
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(
                        rxClasses.fontSize12,
                        rxClasses.typography,
                        rxClasses.buttonAction,
                        rxClasses.preferred,
                        layoutClasses.pr1,
                      )}
                      align="right"
                    >
                      Set as Preferred
                    </Typography>
                  </span>
                )}
                <span
                  onClick={() => {
                    handleClickDelete(pharmacy)
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={clsx(
                      rxClasses.fontSize12,
                      rxClasses.typography,
                      rxClasses.buttonDelete,
                      layoutClasses.pl1,
                    )}
                    align="right"
                  >
                    Delete
                  </Typography>
                </span>
              </Grid>
            </Grid>
          }
        />
      )
    })
  }

  const handleClickDelete = pharmacy => {
    setOpenDeleteModal(true)
    setPharmacyToDelete(pharmacy)
  }

  const handleClickSetAsPreferred = async pharmacy => {
    try {
      await postRequest({
        url: `${API_BASE_URL}/dosespot/pharmacies/patient/preferred/${userId}&${memberId}`,
        body: { pharmacyID: pharmacy.PharmacyId },
      })

      enqueueSnackbar('Preferred pharmacy set', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Failed to set preferred pharmacy', { variant: 'error' })
    } finally {
      setUpdated(true)
    }
  }

  const deletePharmacy = async () => {
    try {
      const response = await postRequest({
        url: `${API_BASE_URL}/dosespot/pharmacies/patient/delete/${userId}&${memberId}`,
        body: { pharmacyID: pharmacyToDelete.PharmacyId },
      })

      setOpenDeleteModal(false)
      setPharmacyToDelete(null)

      await putMember({
        _id: memberId,
        hasPharmacy: response.hasPharmacy,
      })
      enqueueSnackbar('Pharmacy removed', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Failed to set preferred pharmacy', { variant: 'error' })
    } finally {
      setUpdated(true)
    }
  }

  if (loading) {
    return (
      <div className={rxClasses.gridItemSpan2}>
        <LoadingModal />
      </div>
    )
  }

  const title = 'Pharmacy'

  return (
    <BaseCard>
      <BaseCardHeader
        title={title}
        actionOnClick={() => {
          setOpenSearchModal(true)
        }}
        actionText={'Add a Pharmacy'}
      />
      <Rows data={pharmacies} />

      {openSearchModal && (
        <AddPharmacy
          open={openSearchModal}
          handleClose={() => {
            setOpenSearchModal(false)
          }}
        />
      )}

      {openDeleteModal && pharmacyToDelete !== null && (
        <DeletePrompt
          title="Delete Pharmacy"
          message={`Do you want to delete "${pharmacyToDelete.StoreName}" from the patient's profile?`}
          loading={loading}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onDelete={deletePharmacy}
        />
      )}
    </BaseCard>
  )
}
