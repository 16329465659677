import { useCallback } from 'react'

import { API_BASE_URL } from 'constants/api'
import { serializeQueryParameters } from 'utils/api'
import useGetRequest from 'hooks/api/useGetRequest'

export default function useFetchComments() {
  const getRequest = useGetRequest()

  const fetchComments = useCallback(
    async ({ memberID, userID, page, reverse, limit }) => {
      const data = await getRequest({
        url: `${API_BASE_URL}/cp/comments${serializeQueryParameters({
          memberID,
          userID,
          page,
          reverse,
          limit,
        })}`,
      })
      return data
    },
    [getRequest],
  )

  return fetchComments
}
