import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'

export default function BaseCard({ className, children }) {
  return (
    <Card className={className} variant="outlined">
      <Grid
        container
        columns={12}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {children}
      </Grid>
    </Card>
  )
}
