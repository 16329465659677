import { isEmpty } from 'lodash/fp'
import { useCallback, useEffect, useState } from 'react'
import { useRxStyles, RxContext } from 'hooks'
import { useParams } from 'react-router-dom'
import { useCurrentUser } from 'hooks'
import useGetRequest from 'hooks/api/useGetRequest'
import { API_BASE_URL } from 'constants/api'
import { useTheme } from '@material-ui/core/styles'
import LoadingModal from 'components/Modals/LoadingModal'
import Pharmacies from 'components/Member/MemberRx/Pharmacies'
import CurrentRx from 'components/Member/MemberRx/CurrentRx'
import PastRx from 'components/Member/MemberRx/PastRx'
import DrugAllergies from 'components/Member/MemberRx/DrugAllergies'
import SelfReportedMedications from 'components/Member/MemberRx/SelfReportedMedication'

export default function PatientRx() {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const getPatientDetails = useGetRequest()
  const currentUser = useCurrentUser()
  const { memberId } = useParams()

  const [response, setResponse] = useState(null)
  const [loading, setIsLoading] = useState(false)

  const [updated, setUpdated] = useState(null)

  const fetchRxData = useCallback(
    async ({ userID, memberID }) => {
      try {
        setIsLoading(true)
        const patientData = await getPatientDetails({
          url: `${API_BASE_URL}/dosespot/patient/details/${userID}&${memberID}`,
        })

        setResponse(patientData)
      } catch (error) {
        setResponse(undefined)
      } finally {
        setUpdated(false)
        setIsLoading(false)
      }
    },
    [getPatientDetails],
  )

  useEffect(() => {
    if (updated === null || updated) {
      fetchRxData({
        userID: currentUser._id,
        memberID: memberId,
      })
    }
  }, [updated, fetchRxData, currentUser._id, memberId])

  if (loading && (!response || isEmpty(response.patientData))) {
    return (
      <div className={rxClasses.gridItemSpan2}>
        <LoadingModal />
      </div>
    )
  }

  if (!response || !response.patientData) {
    return null
  }

  return (
    <RxContext.Provider
      value={{
        setIsLoading,
        loading,
        response,
        memberId,
        userId: currentUser._id,
        updated,
        setUpdated,
      }}
    >
      <div className={rxClasses.gridItemSpan2}>
        <Pharmacies pharmacies={response.patientData.pharmacies} />
      </div>
      <div className={rxClasses.gridItemSpan2}>
        <CurrentRx
          prescriptions={response.patientData.prescriptions.currentRx}
        />
      </div>
      <div className={rxClasses.gridItemSpan2}>
        <PastRx prescriptions={response.patientData.prescriptions.pastRx} />
      </div>
      <div>
        <DrugAllergies allergies={response.patientData.allergies} />
      </div>
      <div>
        <SelfReportedMedications
          selfReportedMedications={response.patientData.selfReportedMedications}
        />
      </div>
    </RxContext.Provider>
  )
}
