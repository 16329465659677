import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(({ spacing }) => ({
  tableCell: {
    padding: spacing(1),
  },
}))

const ArrayFieldTableSectionLayout = ({ fields, rows, renderCell }) => {
  const classes = useStyles()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {fields.map(field => (
              <TableCell
                key={field.path}
                className={clsx(field.headerClassName, classes.tableCell)}
              >
                {field.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {fields.map(field => (
                <TableCell
                  key={field.path}
                  className={clsx(field.className, classes.tableCell)}
                >
                  {renderCell(row, field)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ArrayFieldTableSectionLayout
