import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

import { API_BASE_URL } from 'constants/api'
import usePutRequest from 'hooks/api/usePutRequest'

export default function useMarkAllCommentsAsRead() {
  const { enqueueSnackbar } = useSnackbar()

  const putRequest = usePutRequest()

  const markAllCommentsAsRead = useCallback(async () => {
    try {
      await putRequest({
        url: `${API_BASE_URL}/cp/comments/mark-read-all`,
      })
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [putRequest, enqueueSnackbar])

  return markAllCommentsAsRead
}
