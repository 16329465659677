import {
  DEFAULT_HISTORY_ROW,
  HISTORY_ROW_FIELDS,
} from 'constants/sectionFields'
import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const PsychHospitalizationHistorySection = props => (
  <ArrayFieldSection
    path="psychHospitalizationHistory"
    title="Past Psychiatric Hospitalization"
    rowTitle="Hospitalization"
    defaultRow={DEFAULT_HISTORY_ROW}
    fields={HISTORY_ROW_FIELDS}
    {...props}
  />
)

export default withMemberForm('PsychHospitalizationHistorySectionForm', [
  'psychHospitalizationHistory',
])(PsychHospitalizationHistorySection)
