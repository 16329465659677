import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'

import PGxChip from './PGxChip'
import usePdfDownload from 'hooks/api/files/usePdfDownload'
import LoadingModal from 'components/Modals/LoadingModal'
import { CancelRegistration } from 'components/Member/MemberPGx/Dialogs/CancelRegistration'

import { API_BASE_URL } from 'constants/api'
import { useLayoutStyles, useRxStyles } from 'hooks'
import { triggerDownload } from 'utils/commonHelpers'
import { formatDateInMMDDYYYY } from 'utils/formatters'
import usePutRequest from 'hooks/api/usePutRequest'
import { updateMember } from 'redux/members'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${palette.distinctiveGray.main}`,
    borderRadius: '8px',
    padding: spacing(2),
    marginBottom: spacing(1),
    backgroundColor: 'white',
  },
  nameBox: {
    display: 'flex',
    alignItems: 'center',
    width: 180,
  },
  fullName: {
    marginRight: spacing(1.5),
  },
  period: {
    marginLeft: spacing(0.5),
    width: 80,
  },
  date: {
    width: 200,
    color: palette.text.primary,
  },
  content: {
    color: palette.text.primary,
    textAlign: 'center',
    flexGrow: 1,
  },
  actionButton: {
    margin: spacing(0, 2),
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
  viewButton: {
    color: palette.primary.main,
    margin: spacing(0, 3),
    textAlign: 'center',
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  red: {
    color: palette.accentRed.main,
  },
  green: {
    color: palette.primary.main,
  },
}))

const PGxItem = ({ pgx, member }) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const rxClasses = useRxStyles()
  const history = useHistory()
  const pdfDownload = usePdfDownload()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setLoading] = useState(false)
  const [openPrompt, setOpenPrompt] = useState(false)
  const putRequest = usePutRequest()
  const dispatch = useDispatch()

  const downloadPdf = async () => {
    try {
      setLoading(true)
      const pdfBlob = await pdfDownload({
        url: `${API_BASE_URL}/cp/pgx/${pgx._id}/download`,
      })

      triggerDownload(pdfBlob, `Report_${pgx.kitNumber}.pdf`)
    } catch (error) {
      enqueueSnackbar('Report download failed', {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCancelRegistration = async () => {
    try {
      setLoading(true)
      const result = await putRequest({
        url: `${API_BASE_URL}/cp/pgx/${pgx.memberId}`,
        body: { kitNumber: '' },
      })

      if (result.success) {
        enqueueSnackbar('Cancel kit number succesfully', {
          variant: 'success',
        })

        if (member) {
          member.kitNumber = ''
          member.kitDate = result.kitDate
          dispatch(updateMember({ member }))
        }
      } else {
        enqueueSnackbar('Failed to update kit number', {
          variant: 'error',
        })
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div>
        <LoadingModal />
      </div>
    )
  }

  return (
    <>
      {openPrompt && (
        <CancelRegistration
          title="Cancel Registration"
          onUpdate={() => {
            handleCancelRegistration()
          }}
          open={openPrompt}
          handleClose={() => {
            setOpenPrompt(false)
          }}
          message={`Would you like to cancel PGx kit registration for ${pgx.fullName}? Once cancelled, please reach out to the primary POC at our laboratory to prevent the kit from being processed.`}
        />
      )}
      <Box className={classes.root}>
        <Box className={classes.nameBox}>
          <Typography
            className={clsx(
              classes.fullName,
              rxClasses.fontSize14,
              rxClasses.fontWeight400,
              layoutClasses.truncate,
            )}
          >
            {`${pgx.fullName}`}
          </Typography>
          {pgx.isComplete &&
            pgx.daysSinceReported >= 0 &&
            pgx.daysSinceReported < 15 && (
              <PGxChip content="New" status="yellow" />
            )}
        </Box>

        <PGxChip
          content={
            pgx.isComplete
              ? 'Completed'
              : pgx.processTime > 14
              ? 'Overdue'
              : 'Pending'
          }
          status={
            pgx.isComplete ? 'green' : pgx.processTime > 14 ? 'red' : 'yellow'
          }
        />

        <Box className={classes.period}>
          <PGxChip
            content={
              pgx.processTime < 0 || !pgx.memberId
                ? 'N/A'
                : `${pgx.processTime} day${pgx.processTime > 1 ? 's' : ''}`
            }
            status="gray"
          />
        </Box>
        <Typography
          className={clsx(
            classes.date,
            rxClasses.fontSize14,
            rxClasses.fontWeight400,
            rxClasses.textItalic,
          )}
        >
          {[
            pgx.isComplete ? 'Delivered' : 'Registered',
            formatDateInMMDDYYYY(pgx.reportedAt),
          ].join(' ')}
        </Typography>
        <Typography
          className={clsx(
            classes.content,
            rxClasses.fontSize14,
            rxClasses.fontWeight400,
          )}
        >
          {pgx.kitNumber}
        </Typography>

        {pgx.isComplete && pgx.isValid ? (
          <Typography
            className={clsx(
              classes.actionButton,
              rxClasses.fontSize14,
              rxClasses.fontWeight400,
              classes.green,
              {
                [classes.hidden]: pgx.memberId === '',
              },
            )}
            onClick={() => downloadPdf()}
          >
            Download PDF
          </Typography>
        ) : pgx.isComplete && !pgx.isValid ? (
          <Typography
            className={clsx(
              rxClasses.fontSize14,
              rxClasses.fontWeight400,
              classes.red,
            )}
          >
            Verification Failed
          </Typography>
        ) : (
          <Typography
            className={clsx(
              classes.actionButton,
              rxClasses.fontSize14,
              rxClasses.fontWeight400,
              classes.red,
              {
                [classes.hidden]: pgx.memberId === '',
              },
            )}
            onClick={() => setOpenPrompt(true)}
          >
            Cancel Registration
          </Typography>
        )}

        <Typography
          className={clsx(
            classes.viewButton,
            rxClasses.fontSize14,
            rxClasses.fontWeight400,
            {
              [classes.hidden]: pgx.isComplete && !pgx.memberId,
            },
          )}
          onClick={() => history.push(`/members/${pgx.memberId}/PGx`)}
        >
          View
        </Typography>
      </Box>
    </>
  )
}

export default PGxItem
