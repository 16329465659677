import { makeStyles } from '@material-ui/core/styles'
import NotificationsIcon from '@material-ui/icons/Notifications'

const useStyles = makeStyles(({ palette }) => ({
  iconBox: {
    position: 'relative',
    cursor: 'pointer',
  },
  notificationsIcon: {
    color: palette.secondaryNavy1.main,
    '& :hover': {
      color: palette.text.primary,
    },
  },
  unreadCountIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,

    color: 'white',
    background: palette.accentRed.main,
    borderRadius: 12,
    width: 12,
    height: 12,
    fontSize: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default function NotificationBadge({ unreadCount, onClick }) {
  const classes = useStyles()

  return (
    <span
      aria-label="Notification Badge"
      role="button"
      className={classes.iconBox}
      onClick={onClick}
    >
      <NotificationsIcon className={classes.notificationsIcon} />
      {unreadCount ? (
        <span className={classes.unreadCountIndicator}>{unreadCount}</span>
      ) : null}
    </span>
  )
}
