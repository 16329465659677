import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'

import { API_BASE_URL } from 'constants/api'
import useGetRequest from 'hooks/api/useGetRequest'

export default function useUnreadCommentCount() {
  const [count, setCount] = useState(0)
  const { enqueueSnackbar } = useSnackbar()

  const getRequest = useGetRequest()

  const fetchRequest = useCallback(async () => {
    try {
      const data = await getRequest({
        url: `${API_BASE_URL}/cp/comments/unread-count`,
      })
      setCount(_.sum(Object.values(data)))
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [getRequest, enqueueSnackbar])

  useEffect(() => {
    fetchRequest()
  }, [fetchRequest])

  return { count, refetch: fetchRequest }
}
