import clsx from 'clsx'
import { isEmpty, isNil } from 'lodash/fp'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { updateMember } from 'redux/members'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import usePutRequest from 'hooks/api/usePutRequest'
import useGetRequest from 'hooks/api/useGetRequest'
import usePdfDownload from 'hooks/api/files/usePdfDownload'
import { API_BASE_URL } from 'constants/api'

import { useLayoutStyles, useRxStyles } from 'hooks'
import { formatFullName } from 'utils/formatters'
import { useTheme, makeStyles } from '@material-ui/core/styles'

import LoadingModal from 'components/Modals/LoadingModal'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import InfoCard from 'components/Member/MemberPGx/InfoCard'
import Typography from '@material-ui/core/Typography'
import PGxKitCard from './PGxKitCard'
import PGxDisclosureCard from './PGxDisclosureCard'
import TextButton from 'components/Shared/TextButton'
import { triggerDownload } from 'utils/commonHelpers'

const useStyles = makeStyles(() => ({
  title: {
    color: '#183f4f',
  },
}))

export default function PGxReport({ member }) {
  const match = useRouteMatch()
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const classes = useStyles(theme)
  const rxClasses = useRxStyles(theme)

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const getRequest = useGetRequest()
  const putRequest = usePutRequest()
  const pdfDownload = usePdfDownload()

  const [loading, setLoading] = useState(true)
  const [kitNumber, setKitNumber] = useState(member.kitNumber)
  const [kitDate, setKitDate] = useState(member.kitDate)
  const [pgxRecord, setPgxRecord] = useState(null)
  const [generatingDownload, setGeneratingDownload] = useState(false)

  const handleUpdate = async kitNumber => {
    try {
      setLoading(true)
      const result = await putRequest({
        url: `${API_BASE_URL}/cp/pgx/${member._id}`,
        body: { kitNumber },
      })

      if (result.success) {
        enqueueSnackbar('Updated kit number succesfully', {
          variant: 'success',
        })

        if (isEmpty(kitNumber)) {
          setKitNumber(null)
          setKitDate(null)
          member.kitNumber = null
        } else {
          setKitNumber(kitNumber)
          setKitDate(result.kitDate)
          member.kitNumber = kitNumber
          member.kitDate = result.kitDate
        }

        dispatch(updateMember({ member }))
      } else {
        enqueueSnackbar('Failed to update kit number', {
          variant: 'error',
        })
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const downloadPdf = async id => {
    try {
      setGeneratingDownload(true)
      const pdfBlob = await pdfDownload({
        url: `${API_BASE_URL}/cp/pgx/${id}/download`,
      })

      triggerDownload(pdfBlob, `Report_${member.kitNumber}.pdf`)
    } catch (error) {
      enqueueSnackbar('Report download failed', {
        variant: 'error',
      })
    } finally {
      setGeneratingDownload(false)
    }
  }

  useEffect(() => {
    async function getReport(kitNumber, memberID) {
      try {
        setLoading(true)
        if (isNil(kitNumber) || isEmpty(kitNumber)) {
          setPgxRecord(null)
        } else {
          const result = await getRequest({
            url: `${API_BASE_URL}/cp/pgx/${memberID}&${kitNumber}`,
          })

          if (result.success === false) {
            enqueueSnackbar(result.message, { variant: 'error' })
            setPgxRecord(null)
          }

          setPgxRecord(result)
        }
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }

    getReport(kitNumber, member._id)
  }, [kitNumber, member, getRequest, enqueueSnackbar])

  if (loading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  if (generatingDownload) {
    return (
      <div>
        <LoadingModal />
      </div>
    )
  }

  return (
    <div className={clsx(layoutClasses.pt1, 'member-pgx-container')}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={8}>
          <Typography
            className={clsx(layoutClasses.mb1, classes.title)}
            component="span"
            variant="h3"
            display="block"
          >
            PGx Report for {formatFullName(member, false)}
          </Typography>
        </Grid>
        {pgxRecord && pgxRecord?.report && (
          <Grid item xs={4} className={layoutClasses.textAlignRight}>
            <TextButton
              className={clsx(
                theme.typography.fontWeightMedium,
                rxClasses.fontSize12,
              )}
              onClick={() => {
                downloadPdf(pgxRecord._id)
              }}
            >
              Download
            </TextButton>
          </Grid>
        )}

        {pgxRecord?.report && (
          <>
            <Grid item xs={6}>
              <InfoCard
                title="Medication Analysis"
                subTitle="See a full list of medications that is informed by the patient’s
      genotype."
                link={{
                  to: {
                    pathname: `${match.url}/medication-analysis`,
                  },
                  text: 'View',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoCard
                title="Genotype Data"
                subTitle="See the full list of the patient’s genotype. "
                link={{
                  text: 'View',
                  to: `${match.url}/genotype`,
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <PGxKitCard
            key={kitNumber}
            kitNumber={kitNumber}
            kitDate={kitDate}
            handleUpdate={handleUpdate}
            fullName={formatFullName(member, true)}
          />
        </Grid>

        {pgxRecord?.report?.Disclosure && (
          <Grid item xs={12}>
            <PGxDisclosureCard disclosure={pgxRecord.report.Disclosure} />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
