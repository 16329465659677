import { MEMBER_STATUS } from 'constants/member'

export const MemberStatuses = [
  MEMBER_STATUS.Pending,
  MEMBER_STATUS.Onboarding,
  MEMBER_STATUS.Active,
  MEMBER_STATUS.Cancelled,
]

export const MemberGenders = ['Male', 'Female', 'Other']

export const MemberPronouns = [
  'He/Him/His',
  'She/Her/Her',
  'They/Them/Their',
  'Ze/Hir/Hir',
  'Ze/Zir/Zirsm',
  'Xe/Xem/Xyr',
  'She/They',
  'He/They',
]

export const MemberRaces = [
  'American Indian or Alaskan',
  'Asian or Asian American',
  'Black or African American',
  'Hispanic or Latinx',
  'Middle Eastern or North African',
  'Native Hawaiian or Pacific Islander',
  'White(Non-Hispanic)',
  'Multiple',
  'Some other race',
  'Decline to respond',
]

export const MemberPreferredLanguages = [
  'English',
  'Spanish',
  'Chinese',
  'Japanese',
  'Hindi',
  'Russian',
  'Bengali',
  'Turkish',
  'Korean',
]

export const MemberSourceCategories = [
  'Referral from provider',
  'IG/FB Ad',
  'Social media',
  'Search',
  'Online Advertisement',
  'Online Profile (Psychology Today, yelp)',
  'Word of mouth',
  'Other Social Media',
  'Referral',
  'Decline to Respond',
]

export const MemberEmergencyContactRelationships = [
  'Mother',
  'Father',
  'Daughter',
  'Son',
  'Sister',
  'Brother',
  'Aunt',
  'Uncle',
  'Friend',
  'Coworker',
  'Other Family Member',
  'Partner',
  'Husband',
  'Wife',
]

export const MemberDiagnosisStatuses = ['Current', 'Past']

export const MemberSymptomTypes = [
  'Sleep Disorder',
  'Interest Deficit',
  'Guilty',
  'Energy Deficit',
  'Concentration Deficit',
  'Appetite',
  'Psychmotor Retardation',
  'Agitation',
]

export const MemberTreatmentTypes = [
  'Individual psychotherapy/counseling',
  'Group therapy',
  'Medication',
  'Medical supervision',
  'Recreational therapies',
  'Complementary therapies (e.g., yoga or meditation)',
]

export const MemberSubstanceTypes = [
  'Alcohol',
  'Ayahuasca',
  'Caffeine',
  'Central Nervous System',
  'Cocaine',
  'DMT',
  'GHB',
  'Hallucinogens',
  'Heroin',
  'Inhalants',
  'Ketamine',
  'Khat',
  'Kratom',
  'LSD',
  'Marijuana(Cannabis)',
  'MDMA(Ecstasy/Molly)',
  'Mescaline(Peyote)',
  'Methamphetamine',
  'Over-the-Counter Medicines--Dextromethorphan(DXM)',
  'Over-the-Counter Medicines--Loperamide',
  'PCP',
  'Prescription Opioids',
  'Prescription Stimulants',
  'Psilocybin',
  'Rohyopnol(Flunitrazepam)',
  'Salvia',
  'Steroids(Anabolic)',
  'Synthetic Cathinones(Bath Salts)',
  'Tobacco/Nicotine',
]

export const MemberFamilyRelationships = [
  'Mother',
  'Father',
  'Daughter',
  'Son',
  'Sister',
  'Brother',
  'Aunt',
  'Uncle',
  'Niece',
  'Nephew',
  'Cousin',
  'Grandmother',
  'Grandfather',
]

export const MemberDegrees = [
  '12th grade or less',
  'High school diploma or equivalent',
  'Some college (no degree)',
  'Trade/Vocational school',
  "Associate's degree",
  "Bachelor's degree",
  "Master's degree",
  'Professional degree',
  'Doctoral degree',
  'Decline to respond',
]

export const MemberMaritalStatuses = [
  'Single',
  'In a relationship',
  'Engaged',
  'Married',
  'Widowed',
  'Separated',
  'Divorced',
  'Civil union',
  'Domestic Partnership',
  'Open relatinoship',
  'Decline to respond',
]

export const MemberEmployments = [
  'Employed',
  'Self-employed',
  'Out of work',
  'Homemaker',
  'Student',
  'Retired',
  'Unable to work',
]

export const MemberLivingWiths = [
  'Mother',
  'Father',
  'Daughter',
  'Son',
  'Sister',
  'Brother',
  'Aunt',
  'Uncle',
  'Friend',
  'Coworker',
  'Other Family Member',
  'Partner',
  'Husband',
  'Wife',
]

export const MemberLivingArrangements = [
  'Rent (Entire place)',
  'Rent (By room)',
  'Own',
  'Supervised apartments',
  'Does not have a stable address',
]

export const States = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const ExternalProviderShareTypes = [
  'Diagnosis',
  'Prescription',
  'Consultation',
  'Laboratory',
  'Assessment Record',
  'Discharge Summary',
]

export const ExternalProviderTypes = [
  'Anesthesiologist',
  'Cardiologist',
  'Colon And Rectal Surgeon',
  'Crictical Care Medicine Specialist',
  'Dermatologist',
  'Endocrinologist',
  'Emergency Medicine Specialist',
  'Family Physician',
  'Gastroenterologist',
  'General Surgeon',
  'Geriatric Medicine Specialist',
  'Hematologist',
  'Hospice',
  'Immunologist',
  'Infection Disease Specialist',
  'Internist',
  'Medical Geneticist',
  'Nephrologist',
  'Neurologist',
  'OB-GYN',
  'Oncologist',
  'Ophthalmolgist',
  'Osteopath',
  'Otolaryngologist',
  'Pathologist',
  'Pediatrician',
  'Physiatrist',
  'Plastic Surgeon',
  'Podiatrist',
  'Preventive Medicine Specialist',
  'Psychiatrist',
  'Pulmonologist',
  'Radiologist',
  'Rheumatologist',
  'Sleep Medicine Specialist',
  'Sports Medicine Specialist',
  'Therapist',
  'Urologist',
]

//source: http://hl7.org/fhir/R4/valueset-coverage-type.html
export const CoverageTypes = [
  {
    display: 'pay',
    code: 'pay',
  },
  {
    display: 'extended healthcare',
    code: 'EHCPOL',
  },
  {
    display: 'health spending account',
    code: 'HSAPOL',
  },
  {
    display: 'automobile',
    code: 'AUTOPOL',
  },
  {
    display: 'collision coverage policy',
    code: 'COL',
  },
  {
    display: 'uninsured motorist policy',
    code: 'UNINSMOT',
  },
  {
    display: 'public healthcare',
    code: 'PUBLICPOL',
  },
  {
    display: 'dental program',
    code: 'DENTPRG',
  },
  {
    display: 'public health program',
    code: 'DISEASEPRG',
  },
  {
    display: `women's cancer detection program`,
    code: 'CANPRG',
  },
  {
    display: 'end renal program',
    code: 'ENDRENAL',
  },
  {
    display: 'HIV-AIDS program',
    code: 'HIVAIDS',
  },
  {
    display: 'mandatory health program',
    code: 'MANDPOL',
  },
  {
    display: 'mental health program',
    code: 'MENTPRG',
  },
  {
    display: 'safety net clinic program',
    code: 'SAFNET',
  },
  {
    display: 'substance use program',
    code: 'SUBPRG',
  },
  {
    display: 'subsidized health program',
    code: 'SUBSIDIZ',
  },
  {
    display: 'subsidized managed care program',
    code: 'SUBSIDMC',
  },
  {
    display: 'subsidized supplemental health program',
    code: 'SUBSUPP',
  },
  {
    display: `worker's compensation`,
    code: 'WCBPOL',
  },
  {
    display: 'dental care policy',
    code: 'DENTAL',
  },
  {
    display: 'disease specific policy',
    code: 'DISEASE',
  },
  {
    display: 'drug policy',
    code: 'DRUGPOL',
  },
  {
    display: 'health insurance plan policy',
    code: 'HIP',
  },
  {
    display: 'long term care policy',
    code: 'LTC',
  },
  {
    display: 'managed care policy',
    code: 'MCPOL',
  },
  {
    display: 'point of service policy',
    code: 'POS',
  },
  {
    display: 'health maintenance organization policy',
    code: 'HMO',
  },
  {
    display: 'preferred provider organization policy',
    code: 'PPO',
  },
  {
    display: 'mental health policy',
    code: 'MENTPOL',
  },
  {
    display: 'substance use policy',
    code: 'SUBPOL',
  },
  {
    display: 'vision care policy',
    code: 'VISPOL',
  },
  {
    display: 'disability insurance policy',
    code: 'DIS',
  },
  {
    display: 'employee welfare benefit plan policy',
    code: 'EWB',
  },
  {
    display: 'flexible benefit plan policy',
    code: 'FLEXP',
  },
  {
    display: 'life insurance policy',
    code: 'LIFE',
  },
  {
    display: 'annuity policy',
    code: 'ANNU',
  },
  {
    display: 'term life insurance policy',
    code: 'TLIFE',
  },
  {
    display: 'universal life insurance policy',
    code: 'ULIFE',
  },
  {
    display: 'property and casualty insurance policy',
    code: 'PNC',
  },
  {
    display: 'reinsurance policy',
    code: 'REI',
  },
  {
    display: 'surplus line insurance policy',
    code: 'SURPL',
  },
  {
    display: 'umbrella liability insurance policy',
    code: 'UMBRL',
  },
  {
    display: 'charity program',
    code: 'CHAR',
  },
  {
    display: 'crime victim program',
    code: 'CRIME',
  },
  {
    display: 'employee assistance program',
    code: 'EAP',
  },
  {
    display: 'government employee health program',
    code: 'GOVEMP',
  },
  {
    display: 'high risk pool program',
    code: 'HIRISK',
  },
  {
    display: 'indigenous peoples health program',
    code: 'IND',
  },
  {
    display: 'military health program',
    code: 'MILITARY',
  },
  {
    display: 'retiree health program',
    code: 'RETIRE',
  },
  {
    display: 'social service program',
    code: 'SOCIAL',
  },
  {
    display: 'veteran health program',
    code: 'VET',
  },
]

//source: http://hl7.org/fhir/R4/valueset-subscriber-relationship.html
export const SubscriberRelationshipType = [
  { code: 'child', display: 'Child' },
  { code: 'parent', display: 'Parent' },
  { code: 'spouse', display: 'Spouse' },
  { code: 'common', display: 'Common' },
  { code: 'other', display: 'Other' },
  { code: 'self', display: 'Self' },
  { code: 'injured', display: 'Injured' },
]
