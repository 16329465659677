import { makeStyles, Box, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { useLayoutStyles, useRxStyles } from 'hooks'
import { formatFullName } from 'utils/formatters'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${palette.distinctiveGray.main}`,
    borderRadius: '8px',
    padding: spacing(2),
    marginBottom: spacing(1),
    backgroundColor: 'white',
  },
  dataWrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flex: 3,
    color: palette.text.primary,
    marginRight: spacing(2),
  },
  button: {
    color: palette.primary.main,
    fontSize: 14,
    fontWeight: 400,
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
  link: {
    flex: 1,
    color: palette.primary.main,
    lineHeight: '14px',
    letterSpacing: '0.15px',
  },
}))

const OverdueMember = ({ member, onRequestAssessment }) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const rxClasses = useRxStyles()

  const handleClickRequestAssessment = () => {
    onRequestAssessment(member)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.dataWrapper}>
        <Link className={classes.link} to={`/members/${member._id}`}>
          {formatFullName(member, true)}
        </Link>

        {member.lastProgressDaysBefore !== null ? (
          <Typography
            variant="subtitle2"
            className={clsx(classes.content, layoutClasses.truncate)}
          >
            Most recent progress data is from{' '}
            <span className={rxClasses.accentRed}>
              {member.lastProgressDaysBefore} days ago
            </span>
          </Typography>
        ) : (
          <Typography
            variant="subtitle2"
            className={clsx(classes.content, layoutClasses.truncate)}
          >
            No progress data available
          </Typography>
        )}
      </Box>

      <Typography
        className={classes.button}
        onClick={handleClickRequestAssessment}
      >
        Request assessment
      </Typography>
    </Box>
  )
}

export default OverdueMember
