import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import { DocumentTypes } from 'constants/member'

import useFileGenerate from 'hooks/api/files/useFileGenerate'
import TextInput from 'ui/TextInput'

import TextButton from 'components/Shared/TextButton'

const useStyles = makeStyles(theme => ({
  container: {
    width: 480,
    height: 100,
  },

  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'black',
    },
  },

  dialogActions: {
    padding: theme.spacing(2, 2),
  },
}))

export default function GenerateLWCDialog({ member, onClose }) {
  const classes = useStyles()
  const generateMemberFile = useFileGenerate()
  const [reason, setReason] = useState('')
  const [withdrawalDate, setWithDrawalDate] = useState('')

  const handleChangeReason = e => {
    setReason(e.target.value)
  }

  const handleChangeWithdrawalDate = e => {
    setWithDrawalDate(e.target.value)
  }

  const handleGenerate = () => {
    Promise.resolve(
      generateMemberFile({
        reason,
        withdrawalDate,
        memberID: member._id,
        documentType: DocumentTypes.LetterOfWithdrawal,
      }),
    )
    onClose()
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Generate letter of withdrawal</DialogTitle>

      <DialogContent>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              value={reason}
              className={classes.input}
              placeholder="Select reason"
              onChange={handleChangeReason}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              type="date"
              value={withdrawalDate}
              className={classes.input}
              placeholder="Select date of withdrawal"
              onChange={handleChangeWithdrawalDate}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <TextButton onClick={onClose}>Cancel</TextButton>
        <TextButton onClick={handleGenerate}>Send</TextButton>
      </DialogActions>
    </Dialog>
  )
}
