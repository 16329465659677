import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import BaseSection from 'components/Shared/Sections/BaseSection'
import MultiFieldsSectionForm from 'components/Shared/Sections/SectionForms/MultiFieldsSectionForm'
import { useRxStyles } from 'hooks'

import PopoverChip from './PopoverChip'

const FIELDS = [
  {
    path: 'careTeamNote',
    label: 'Care Team Note',
    componentProps: {
      multiline: true,
    },
  },
]

const MemoChip = ({ member }) => {
  const rxClasses = useRxStyles()

  const renderForm = () => <MultiFieldsSectionForm fields={FIELDS} />

  return (
    <PopoverChip
      chipLabel="Memo"
      hasMemo={!!member.careTeamNote}
      horizontalPlacement="right"
    >
      <Box width={300} p={2}>
        <Typography variant="body1" className={rxClasses.fontSize14}>
          {member.careTeamNote}
        </Typography>

        <Typography component="div" align="right" variant="caption">
          <BaseSection isLink title="Care Team Note" renderForm={renderForm}>
            Edit
          </BaseSection>
        </Typography>
      </Box>
    </PopoverChip>
  )
}

export default MemoChip
