import BaseAccordion from 'components/Shared/BaseAccordion'

import PsychFamilyHistorySection from './Sections/PsychFamilyHistorySection'
import PsychHospitalizationHistorySection from './Sections/PsychHospitalizationHistorySection'
import TraumaSection from './Sections/TraumaSection'

export default function PastPsychHistoryAccordion(props) {
  return (
    <BaseAccordion title="Past Psychiatric History">
      <PsychFamilyHistorySection {...props} />
      <PsychHospitalizationHistorySection {...props} />
      <TraumaSection {...props} />
    </BaseAccordion>
  )
}
