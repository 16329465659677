import clsx from 'clsx'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks'

import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: { marginTop: spacing(2), lineHeight: '24px' },
  close: {
    color: palette.text.primary,
  },
}))

export function CancelRegistration({
  open,
  handleClose,
  onUpdate,
  loading,
  title,
  message,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title={title}
      dialogContent={
        <>
          <Typography className={clsx(rxClasses.fontSize14, classes.content)}>
            {message}
          </Typography>
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
              classes.close,
            )}
            onClick={handleClose}
          >
            CLOSE
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
              rxClasses.accentRed,
            )}
            onClick={() => {
              handleClose(false)
              onUpdate()
            }}
          >
            CANCEL REGISTRATION
          </TextButton>
        </>
      }
      loading={loading}
      maxWidth="md"
    />
  )
}
