import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'worsenedBy',
  label: 'Worsened By',
  componentProps: {
    multiline: true,
  },
}

const WorsenedBySection = props => (
  <SingleFieldSection {...props} title="Worsened By" field={FIELD} />
)

export default withMemberForm('WorsenedBySectionForm', ['worsenedBy'])(
  WorsenedBySection,
)
