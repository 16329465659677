import clsx from 'clsx'
import { isUndefined, isArray, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useLayoutStyles } from 'hooks'
import { makeStyles } from '@material-ui/core/styles'
import AvatarChip from 'components/Shared/Chips/AvatarChip'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { MEMBER_STATUS } from 'constants/member'
import { formatFullName } from 'utils/formatters'
import { inlineList } from 'utils/insurance'

const useStyles = makeStyles(({ spacing, palette }) => ({
  card: {
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  borderRed: {
    border: `1px solid ${palette.accentRed.main}`,
  },
  highlightRed: {
    color: palette.accentRed.main,
  },
  highlightYellow: {
    color: '#BA8A0F',
  },
  highlightGreen: {
    color: palette.secondaryGreen1.main,
  },
  paper: {
    minHeight: 94,
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    gridGap: spacing(1),
    border: 'none',
    borderRadius: 8,
  },
  toolTip: {
    backgroundColor: palette.secondaryNavy1.main,
    color: '#fff',
  },
}))

export default function MemberCard({
  member,
  carePartnersById,
  psychiatristsById,
  therapistsById,
}) {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const carePartner = useMemo(
    () => carePartnersById[member.carePartner],
    [member, carePartnersById],
  )
  const psychiatrist = useMemo(
    () => psychiatristsById[member.psychiatrist],
    [member, psychiatristsById],
  )
  const therapist = useMemo(
    () => therapistsById[member.therapist],
    [member, therapistsById],
  )

  const shouldHighlight =
    isUndefined(carePartner) ||
    isUndefined(psychiatrist) ||
    !member.doseSpotPatientID ||
    member.hasPharmacy === false ||
    member.stripeID === '' ||
    member.status === MEMBER_STATUS.Cancelled

  function MemberTooltip({
    className,
    hasStripeID,
    hasCareTeam,
    hasDoseSpot,
    hasPharmacy,
  }) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gridGap={2}
        className={className}
      >
        {!hasStripeID && <span>Stripe ID not entered</span>}
        {!hasCareTeam && <span>Care Team not assigned properly</span>}
        {!hasDoseSpot && <span>DoseSpot profile not activated</span>}
        {!hasPharmacy && <span>Pharmacy information missing</span>}
      </Box>
    )
  }

  return (
    <div
      className={clsx({
        [classes.card]: true,
        [classes.borderRed]: shouldHighlight,
      })}
    >
      <Link to={`/members/${member._id}`}>
        <Tooltip
          placement="right"
          title={
            shouldHighlight ? (
              <MemberTooltip
                className={classes.toolTip}
                hasStripeID={!isEmpty(member.stripeID)}
                hasCareTeam={carePartner && psychiatrist}
                hasDoseSpot={!isEmpty(member.doseSpotPatientID)}
                hasPharmacy={member.hasPharmacy}
              />
            ) : (
              ''
            )
          }
        >
          <Paper variant="outlined" className={classes.paper}>
            <Box
              fontSize="16px"
              flexWrap="wrap"
              fontWeight="fontWeightRegular"
              className={layoutClasses.truncate}
            >
              {formatFullName(member, true)}{' '}
              {member.preferredName && <> ({member.preferredName})</>}
            </Box>

            <Box
              className={clsx({
                [layoutClasses.truncate]: true,
                [classes.highlightGreen]:
                  member.status === MEMBER_STATUS.Active,
                [classes.highlightYellow]: [
                  MEMBER_STATUS.Onboarding,
                  MEMBER_STATUS.Pending,
                ].includes(member.status),
                [classes.highlightRed]:
                  member.status === MEMBER_STATUS.Cancelled,
              })}
              component="span"
              fontSize="14px"
              lineHeight="20px"
            >
              {member.status}{' '}
              {isArray(member.insurances) && member.insurances.length > 0 ? (
                <span>({inlineList(member.insurances)})</span>
              ) : (
                <span>(No Insurance)</span>
              )}
            </Box>

            <Box>
              {carePartner && (
                <AvatarChip
                  user={carePartner}
                  className={clsx(
                    classes.ccBackground,
                    layoutClasses.mt1,
                    layoutClasses.mr1,
                  )}
                />
              )}
              {psychiatrist && (
                <AvatarChip
                  user={psychiatrist}
                  className={clsx(
                    classes.psychBackground,
                    layoutClasses.mt1,
                    layoutClasses.mr1,
                  )}
                />
              )}
              {therapist && (
                <AvatarChip
                  user={therapist}
                  className={clsx(
                    classes.therapistBackground,
                    layoutClasses.mt1,
                    layoutClasses.mr1,
                  )}
                />
              )}
            </Box>
          </Paper>
        </Tooltip>
      </Link>
    </div>
  )
}
