import { Chip, Grid, Typography, makeStyles } from '@material-ui/core'

import { ASSESSMENT_TYPE_DETAILS } from 'constants/progress'
import { getProgressDetails } from 'utils/progress'

import AssessmentGroup from './AssessmentGroup'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid #E3E3E3',
    borderRadius: spacing(1),
    color: '#183F4F',
    marginBottom: spacing(2),
  },
  header: {
    padding: spacing(1.5),
    borderBottom: '1px solid #E3E3E3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    padding: spacing(2),
  },
  instructions: {
    fontStyle: 'italic',
    marginBottom: spacing(2),
  },
}))

const AssessmentForm = ({ type, scores, answersDisabled, onUpdate }) => {
  const classes = useStyles()

  const assessmentDetail = ASSESSMENT_TYPE_DETAILS[type]
  const sum = scores.reduce(
    (totalSum, each, groupIndex) =>
      totalSum +
      each.reduce(
        (sum, scoreIndex, fieldIndex) =>
          sum +
          (!answersDisabled?.[groupIndex]?.[fieldIndex]
            ? assessmentDetail.scoreGroups[groupIndex].fields[fieldIndex]
                ?.possibleScores[scoreIndex]?.score ?? 0
            : 0),
        0,
      ),
    0,
  )
  const progressDetail = getProgressDetails(type, sum)
  const label = assessmentDetail.label
  const content = `${label}: ${sum}`

  const handleScore = (groupIndex, fieldIndex, scoreIndex) => {
    onUpdate([
      ...scores.slice(0, groupIndex),
      [
        ...scores[groupIndex].slice(0, fieldIndex),
        scoreIndex,
        ...scores[groupIndex].slice(fieldIndex + 1),
      ],
      ...scores.slice(groupIndex + 1),
    ])
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {label}

        <Chip
          label={content}
          style={{
            height: 24,
            color: progressDetail.color,
            backgroundColor: progressDetail.backgroundColor,
          }}
        />
      </div>

      <div className={classes.main}>
        <Typography variant="subtitle2" className={classes.instructions}>
          {assessmentDetail.instructions}
        </Typography>

        <Grid container spacing={2}>
          {assessmentDetail.scoreGroups.map((group, groupIndex) => (
            <Grid key={groupIndex} item xs={12}>
              <AssessmentGroup
                group={group}
                baseIndex={group.baseIndex ?? 0}
                scores={scores?.[groupIndex]}
                answersDisabled={answersDisabled?.[groupIndex]}
                onScore={(fieldIndex, score) =>
                  handleScore(groupIndex, fieldIndex, score)
                }
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default AssessmentForm
