import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'
import withMemberForm from 'HOCs/withMemberForm'

const FIELD = {
  path: 'psychTrauma',
  label: 'Trauma',
  componentProps: {
    multiline: true,
  },
}

const TraumaSection = props => (
  <SingleFieldSection {...props} title="Trauma" field={FIELD} />
)

export default withMemberForm('TraumaSectionForm', ['psychTrauma'])(
  TraumaSection,
)
