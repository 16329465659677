import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { API_BASE_URL, HTTP_METHODS } from 'constants/api'
import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { setMembers } from 'redux/members'
import { setCurrentUser, setUsers } from 'redux/users'
import { setExternalProviders } from 'redux/externalProviders'
import { setNotes } from 'redux/notes'
import { setProgresses } from 'redux/progresses'
import { setPgxs } from 'redux/pgxs'
import { setCptCodes } from 'redux/notes'
import { usePageVisibility } from 'react-page-visibility'
import { useInterval } from 'hooks'
import Sidebar from 'components/Sidebar'
import Members from 'components/Members/Members'
import MemberAdd from 'components/MemberAdd/MemberAdd'
import Member from 'components/Member/Member'
import Encounters from 'components/Encounters/Encounters'
import Progress from 'components/Progress/Progress'
import Pgxs from 'components/PGxs/PGxs'
import AccountSettings from 'components/AccountSettings/AccountSettings'
import NotificationsModal from 'components/Notifications/NotificationsModal'
import { useUnreadCommentCount } from 'hooks/api/comments'
import './Dashboard.css'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  rightContainer: {
    flexGrow: 1,
  },
  shrinkRightContainer: {
    marginLeft: '90px',
  },
}))

const FIVE_MINUTES = 300000 //5 minutes
const TWO_MINUTES = 120000 //2 minutes

export default function Dashboard() {
  const classes = useStyles()

  const [isSidebarFloated, setIsSidebarFloated] = useState(true)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true)
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false)
  const { count: unreadCount, refetch: refetchUnreadCommentCount } =
    useUnreadCommentCount()

  const isVisible = usePageVisibility()
  const { fetchWithRefresh } = useGoogleAuth()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handleShowNotificationsModal = () => {
    setIsNotificationsModalOpen(true)
  }

  const handleCloseNotificationsModal = () => {
    setIsNotificationsModalOpen(false)
  }

  const fetchMembers = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/members`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const members = await response.json()
      dispatch(setMembers(members))
    } else {
      throw new Error('Failed to fetch member data')
    }
  }

  const fetchUsers = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/users`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const users = await response.json()
      dispatch(setCurrentUser(users.currentUser))
      dispatch(setUsers(users.users))
    } else {
      throw new Error('Failed to fetch user data')
    }
  }

  const fetchExternalProviders = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/externalProviders`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const externalProviders = await response.json()
      dispatch(setExternalProviders(externalProviders))
    } else {
      throw new Error('Failed to fetch external provider data')
    }
  }

  const fetchNotes = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/note`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const notes = await response.json()
      dispatch(setNotes(notes))
    } else {
      throw new Error('Failed to fetch encounter data')
    }
  }

  const fetchProgresses = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/progress`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const progresses = await response.json()
      dispatch(setProgresses(progresses))
    } else {
      throw new Error('Failed to fetch progress data')
    }
  }

  const fetchPGxReports = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/pgx`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const pgxReports = await response.json()
      dispatch(setPgxs(pgxReports))
    } else {
      throw new Error('Failed to fetch pgx report data')
    }
  }

  const fetchCptCodes = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/cptCodes`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const cptCodes = await response.json()
      dispatch(setCptCodes(cptCodes))
    } else {
      throw new Error('Failed to fetch cpt codes data')
    }
  }

  const fetchData = async () => {
    try {
      await Promise.all([
        fetchMembers(),
        fetchUsers(),
        fetchExternalProviders(),
        fetchNotes(),
        fetchProgresses(),
        fetchPGxReports(),
        fetchCptCodes(),
      ])
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchData()
  }, []) // eslint-disable-line

  useInterval(async () => {
    try {
      if (isVisible) {
        fetchData()
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, FIVE_MINUTES)

  useInterval(async () => {
    try {
      await refetchUnreadCommentCount()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, TWO_MINUTES)

  return (
    <BrowserRouter>
      <div className="app">
        <Sidebar
          isSidebarFloated={isSidebarFloated}
          setIsSidebarFloated={setIsSidebarFloated}
          isSidebarCollapsed={isSidebarCollapsed}
          setIsSidebarCollapsed={setIsSidebarCollapsed}
          onClickUnreadNotification={handleShowNotificationsModal}
          unreadCommentCount={unreadCount}
        />

        <NotificationsModal
          isOpen={isNotificationsModalOpen}
          onClose={handleCloseNotificationsModal}
          unreadCount={unreadCount}
          refetchUnreadCommentCount={refetchUnreadCommentCount}
        />

        <div
          className={clsx(classes.rightContainer, {
            [classes.shrinkRightContainer]: isSidebarFloated,
          })}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/members" />
            </Route>

            <Route exact path="/members" component={Members} />
            <Route exact path="/members/new" component={MemberAdd} />
            <Route path="/members/:memberId" component={Member} />
            <Route path="/encounters" component={Encounters} />
            <Route path="/progress" component={Progress} />
            <Route path="/pgxOrders" component={Pgxs} />
            <Route path="/account" component={AccountSettings} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  )
}
