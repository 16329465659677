import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'

// ActionTypes
const SET_TASKS = 'tasks/SET_TASKS'
const ADD_TASK = 'tasks/ADD_TASK'
const UPDATE_TASK = 'tasks/UPDATE_TASK'
const REMOVE_TASK = 'tasks/REMOVE_TASK'

const initialState = {
  isLoading: true,
  error: null,
  byId: {},
  allIds: [],
}

// Reducer
const tasksStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASKS:
      const tasks = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        byId: keyBy(tasks, '_id'),
        allIds: tasks.map(task => task._id),
      }

    case ADD_TASK:
      const newTask = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        byId: { ...state.byId, [newTask._id]: newTask },
        allIds: [...state.allIds, newTask._id],
      }

    case UPDATE_TASK:
      const updatedTask = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        byId: { ...state.byId, [updatedTask._id]: updatedTask },
      }

    case REMOVE_TASK:
      const removedTask = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        byId: omit(state.byId, [removedTask._id]),
        allIds: state.allIds.filter(taskId => taskId !== removedTask._id),
      }

    default:
      return state
  }
}

// ActionCreators
export const setTasks = tasks => ({
  type: SET_TASKS,
  payload: tasks,
})

export const addTask = task => ({
  type: ADD_TASK,
  payload: task,
})

export const updateTask = task => ({
  type: UPDATE_TASK,
  payload: task,
})

export const removeTask = taskId => ({
  type: REMOVE_TASK,
  payload: taskId,
})

export default tasksStore
