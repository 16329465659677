import { makeStyles } from '@material-ui/core/styles'

export const useRxStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    accentRed: {
      color: palette.accentRed.main,
    },
    secondaryMint: {
      color: palette.secondaryMint1.main,
    },
    secondaryNavy2: {
      color: palette.secondaryNavy2.main,
    },
    fontSize20: {
      fontSize: '20px',
    },
    fontSize18: {
      fontSize: '18px',
    },
    fontSize16: {
      fontSize: '16px',
    },
    fontSize14: {
      fontSize: '14px',
    },
    fontSize12: {
      fontSize: '12px',
    },
    fontSize10: {
      fontSize: '10px',
    },
    fontSize8: { fontSize: '8px' },
    fontWeight500: {
      fontWeight: '500',
    },
    fontWeight400: {
      fontWeight: 400,
    },
    columnPadding: {
      padding: '13px 16px',
    },
    typography: { lineHeight: '18px', letterSpacing: '0.15px' },
    buttonDelete: {
      color: `${palette.accentRed.main}!important`,
      cursor: 'pointer',
      fontWeight: typography.fontWeightMedium,
    },
    buttonAction: {
      color: palette.primary.main,
      cursor: 'pointer',
      fontWeight: typography.fontWeightMedium,
    },
    preferred: {
      whiteSpace: 'pre',
      [breakpoints.down('md')]: {
        paddingRight: '0px',
      },
    },
    dividerTop: {
      borderTop: `1px solid ${palette.distinctiveGray.main}`,
    },
    chip: {
      color: '#fff',
      backgroundColor: palette.primary.main,
      fontSize: '12px',
      padding: '8px 4px',
      margin: '0px 11px',
      [breakpoints.down('1536')]: {
        margin: '0px',
      },
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: spacing(2),
    },
    gridItemSpan2: {
      gridColumn: 'span 2',
    },
    registration: {
      whiteSpace: 'nowrap',
      background: '#36bca8',
      padding: '10px 25px',
      color: '#fff',
    },
    rowSpacer: {
      marginBottom: spacing(2),
    },
    center: { margin: 'auto' },
    chipMint: {
      color: '#fff',
      backgroundColor: palette.primary.main,
      fontSize: '12px',
      padding: '8px 4px',
    },
    chipRed: {
      color: '#fff',
      backgroundColor: palette.accentRed.main,
      fontSize: '12px',
      padding: '8px 4px',
      marginLeft: '8px',
      [breakpoints.down('1536')]: {
        marginTop: '3px',
      },
    },
    actionSpacerLeft: {
      paddingLeft: '12px',
    },
    actionSpacerRight: {
      paddingRight: '12px',
    },
    textTransformCapitalize: {
      textTransform: 'capitalize',
    },
    textAlignRight: {
      textAlign: 'right',
    },
    textAlignLeft: {
      textAlign: 'left',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    dividerBottom: {
      borderBottom: `1px solid ${palette.distinctiveGray.main}`,
    },
    table: {
      '& .MuiTablePagination-actions': {
        marginLeft: '24px',
      },
      '& .MuiTablePagination-caption': {
        fontSize: '12px',
      },
    },
    textItalic: {
      fontStyle: 'italic',
    },
  }),
  { index: 1 },
)
