import { makeStyles, Chip } from '@material-ui/core'
import clsx from 'clsx'

import { useRxStyles } from 'hooks'

const useStyles = makeStyles(({ spacing, palette }) => ({
  chip: {
    marginRight: spacing(1),
    border: 0,
  },
  gray: {
    color: palette.text.primary,
    backgroundColor: palette.backgroundGray.main,
  },
  red: {
    color: palette.accentRed.main,
    backgroundColor: '#FEDEDE',
  },
  green: {
    color: palette.primary.main,
    backgroundColor: '#DAEFED',
  },
  yellow: {
    color: palette.warning.dark,
    backgroundColor: palette.backgroundYellow2.main,
  },
}))

const PGxChip = ({ content, status }) => {
  const classes = useStyles()
  const rxClasses = useRxStyles()

  return (
    <Chip
      variant="outlined"
      className={clsx(
        classes.chip,
        rxClasses.fontSize12,
        rxClasses.fontWeight400,
        {
          [classes.red]: status === 'red',
          [classes.green]: status === 'green',
          [classes.yellow]: status === 'yellow',
          [classes.gray]: status === 'gray',
        },
      )}
      label={content}
    />
  )
}

export default PGxChip
