import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'

import { HTTP_METHODS } from 'constants/api'

export default function useGetRequest() {
  const { fetchWithRefresh } = useGoogleAuth()

  const getRequest = useCallback(
    async ({ url }) => {
      const response = await fetchWithRefresh({
        url,
        options: {
          method: HTTP_METHODS.GET,
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      })

      if (response.ok) {
        return await response.blob()
      } else {
        throw new Error('Failed to download file')
      }
    },
    [fetchWithRefresh],
  )

  return getRequest
}
