import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useFormFields, useMemberRx } from 'hooks'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks/useRxStyles'
import BaseDialog from 'components/Shared/BaseDialog'
import TextInput from 'ui/TextInput'
import TextButton from 'components/Shared/TextButton'

export default function SearchForm({ open, handleClose, onResultsChange }) {
  const { userId } = useMemberRx()
  const { enqueueSnackbar } = useSnackbar()
  const searchMedication = usePostRequest()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const [searchResults, setResults] = useState(null)
  const [loading, setIsLoading] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    search: '',
  })

  useEffect(() => {
    if (searchResults) {
      onResultsChange(searchResults)
    }
  }, [searchResults, onResultsChange])

  function validateForm() {
    return fields.search.length > 0
  }

  async function search({ body, userId }) {
    setIsLoading(true)
    const result = await searchMedication({
      url: `${API_BASE_URL}/dosespot/medication/search/${userId}`,
      body,
    })
    if (result || result.length > 0) {
      setResults(result)
    } else {
      enqueueSnackbar('No results found', { variant: 'error' })
    }

    setIsLoading(false)
  }

  return (
    <>
      <BaseDialog
        open={open}
        onClose={handleClose}
        title="Add Self-reported Medication"
        dialogContent={
          <TextInput
            id="search"
            fullWidth
            variant="outlined"
            label="Search"
            name="search"
            value={fields.search}
            onChange={handleFieldChange}
          />
        }
        dialogActions={
          <>
            <TextButton
              className={clsx(
                theme.typography.fontWeightMedium,
                rxClasses.fontSize16,
                rxClasses.typography,
              )}
              onClick={handleClose}
            >
              CANCEL
            </TextButton>
            <TextButton
              disabled={!validateForm()}
              className={clsx(
                theme.typography.fontWeightMedium,
                rxClasses.fontSize16,
                rxClasses.typography,
              )}
              onClick={() => search({ body: fields, userId })}
            >
              SEARCH
            </TextButton>
          </>
        }
        loading={loading}
      />
    </>
  )
}
