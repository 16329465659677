import React, { useState } from 'react'
import {
  Box,
  makeStyles,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { useSelector } from 'react-redux'

import TabsHeader from 'components/Shared/TabsHeader'

import EncounterList from './EncounterList'

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    padding: spacing(2, 3),
    color: palette.text.primary,
    background: palette.common.white,
    fontSize: 20,
  },
  content: {
    flexGrow: 1,
  },
}))

const Encounters = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const [isMyPatientPending, setIsMyPatientPending] = useState(true)
  const [isMyPatientFinalized, setIsMyPatientFinalized] = useState(true)
  const [isMyPatientNoShow, setIsMyPatientNoShow] = useState(true)
  const isMembersLoading = useSelector(state => state.members.isLoading)
  const isNotesLoading = useSelector(({ notes }) => notes.isLoading)

  if (isMembersLoading || isNotesLoading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Typography className={classes.title}>Encounters</Typography>
      <TabsHeader
        selectedTab={selectedTab}
        isOnlyMyPatients={
          selectedTab === 0
            ? isMyPatientPending
            : selectedTab === 1
            ? isMyPatientFinalized
            : isMyPatientNoShow
        }
        tabs={['Pending', 'Finalized', 'No-Show']}
        onChangeOnlyMyPatients={value =>
          selectedTab === 0
            ? setIsMyPatientPending(value)
            : selectedTab === 1
            ? setIsMyPatientFinalized(value)
            : setIsMyPatientNoShow(value)
        }
        onChangeTab={newTab => setSelectedTab(newTab)}
      />
      {selectedTab === 0 ? (
        <EncounterList
          isMyPatientCheck={isMyPatientPending}
          state="pending"
          noDataTitle="No pending encounters"
        />
      ) : selectedTab === 1 ? (
        <EncounterList
          isMyPatientCheck={isMyPatientFinalized}
          state="finalized"
          noDataTitle="No finalized encounters"
        />
      ) : (
        <EncounterList
          isMyPatientCheck={isMyPatientNoShow}
          state="noShow"
          noDataTitle="No no-show encounters"
        />
      )}
    </Box>
  )
}

export default Encounters
