import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import MemberFilter from 'components/Members/MemberFilter'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import BaseHeader from '../Shared/BaseHeader'

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
}))

const useSearchStyles = makeStyles(() => ({
  searchField: {
    '& input::placeholder': {
      fontSize: '20px',
    },
  },
}))

const useFilterStyles = makeStyles(({ spacing }) => ({
  autocomplete: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(2),
    marginBottom: spacing(2),
    fontSize: spacing(2),
    width: '100%',
    '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
      flexWrap: 'nowrap',
      width: '200px',
      '& .MuiInputBase-input': {
        padding: 4,
        minWidth: '1%!important',
      },
      '& .MuiFormLabel-root': {
        fontSize: 14,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        marginTop: 0,
      },
    },
    '& .MuiFormLabel-root': {
      marginTop: '-6px',
      fontSize: 14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink ': {
      marginTop: 0,
      fontSize: 14,
    },
  },
}))

const MembersHeader = ({ selectFilters, onFilterChange, shouldClear }) => {
  const classes = useStyles()
  const searchClasses = useSearchStyles()
  const filterClasses = useFilterStyles()

  return (
    <div style={{ zIndex: 1 }}>
      <BaseHeader p={2}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              height={56}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                classes={{ root: searchClasses.searchField }}
                variant="outlined"
                placeholder="Search for a member"
                fullWidth
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  classes: { notchedOutline: classes.noBorder },
                }}
                onChange={e => onFilterChange({ query: e.target.value })}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className={filterClasses.autocomplete}>
              {selectFilters.map(filter => (
                <MemberFilter
                  key={filter.name}
                  name={filter.name}
                  label={filter.label}
                  options={filter.options}
                  defaultValue={filter?.defaultValue ?? []}
                  getOptionLabel={filter.getOptionLabel}
                  tags={filter?.tags}
                  onFilterChange={onFilterChange}
                  shouldClear={shouldClear}
                />
              ))}
              <span
                style={{ cursor: 'pointer', marginTop: '10px' }}
                onClick={() => {
                  onFilterChange({
                    clear: true,
                    query: '',
                    carePartners: [],
                    psychiatrists: [],
                    therapists: [],
                  })
                }}
              >
                <Typography color="primary">Clear Filters</Typography>
              </span>
            </div>
          </Grid>
        </Grid>
      </BaseHeader>
    </div>
  )
}

export default MembersHeader
