import GoogleButton from 'components/Shared/Buttons/GoogleButton'
import VersionNumber from 'components/Shared/VersionNumber'
import './Login.css'
import { ReactComponent as Logo } from 'assets/cynomys_login_logo.svg'

function LoginPage({ onSignIn, errorMessage }) {
  return (
    <div className="login-screen">
      <div className="vertical-spacer"> </div>
      <div>
        <Logo />
      </div>
      <div>
        {errorMessage && <span>{errorMessage}</span>}
        <GoogleButton onClick={onSignIn} />
      </div>
      <div className="bottom">
        <VersionNumber className="version" />
      </div>
    </div>
  )
}

export default LoginPage
