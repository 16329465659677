import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'born',
  label: 'Born at',
}

const BornSection = props => (
  <SingleFieldSection {...props} title="Born at" field={FIELD} />
)

export default withMemberForm('BornSectionForm', ['born'])(BornSection)
