import { PRESCRIBING_CONSIDERATIONS } from 'constants/pgx'

export function getColors(type) {
  if (type === PRESCRIBING_CONSIDERATIONS.STRONG) {
    return {
      color: '#eb5757',
      backgroundColor: '#FFE9E9',
    }
  }

  if (type === PRESCRIBING_CONSIDERATIONS.MODERATE) {
    return { color: '#D39A06', backgroundColor: '#F8F4EB' }
  }

  if (type === PRESCRIBING_CONSIDERATIONS.USUAL) {
    return { color: '#36bca8', backgroundColor: '#F1F8EB' }
  }
}

export function getMedicationInformation({ medications, lookupValue }) {
  return medications.find(med => med.Drug === lookupValue)
}

export function getGeneInformation({ genes, lookupValue }) {
  return genes.find(gene => gene.Gene === lookupValue)
}

export function getLiteratureReferences({ citations, lookupValue }) {
  return citations[lookupValue]
}

function dobMatches(memberDOB, reportDOB) {
  const dt1 = new Date(memberDOB).getDate()
  const dt2 = new Date(reportDOB).getDate()

  return dt1 === dt2
}

function nameMatches(memberName, reportName) {
  return memberName.toLowerCase() === reportName.toLowerCase()
}

export function isValidPGxRecord(pgxRecord, member) {
  if (!pgxRecord || !member) {
    return false
  }

  const reportMetadata = pgxRecord?.report?.Metadata

  return (
    member?.kitNumber === reportMetadata?.KitNumber &&
    dobMatches(member?.dob, reportMetadata?.Dob) &&
    nameMatches(member?.firstName, reportMetadata?.FirstName) &&
    nameMatches(member?.lastName, reportMetadata?.LastName)
  )
}
