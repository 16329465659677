import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useLayoutStyles, useRxStyles } from 'hooks'
import useGetRequest from 'hooks/api/useGetRequest'
import { API_BASE_URL } from 'constants/api'
import { MemberMenuItems } from 'constants/member'

import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseRow from 'components/Shared/Card/BaseRow'
import PGxBreadcrumbs from 'components/Member/MemberPGx/PGxBreadcrumbs'

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    color: '#183f4f',
  },
  typography: {
    lineHeight: `${spacing(3)}px!important`,
    letterSpacing: '0.15px',
  },
  columnPadding: {
    padding: '16px 24px',
  },
}))

export default function PGxGenotypeData({ member }) {
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const rxClasses = useRxStyles(theme)
  const classes = useStyles()

  const getRequest = useGetRequest()
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState(null)

  useEffect(() => {
    async function getReport(kitNumber, memberID) {
      try {
        setLoading(true)
        const result = await getRequest({
          url: `${API_BASE_URL}/cp/pgx/${memberID}&${kitNumber}`,
        })
        setReport(result.report)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }

    if (member.kitNumber) {
      getReport(member.kitNumber, member._id)
    }
  }, [getRequest, member])

  if (loading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  if (!report) {
    return <Redirect to={`/members/${member._id}/${MemberMenuItems.PGX}`} />
  }

  const Genes = props => {
    return props.pharmacogenetics.map((geneInformation, i) => {
      return (
        <BaseRow
          key={`${geneInformation.Gene}-${i}`}
          columns={
            <Grid container alignItems="center" justifyContent="space-evenly">
              <Grid item xs={3} className={classes.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(classes.typography, rxClasses.fontSize16)}
                >
                  {geneInformation.Gene}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(classes.typography, rxClasses.fontSize16)}
                >
                  {geneInformation.Genotype}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(classes.typography, rxClasses.fontSize16)}
                >
                  <strong>{geneInformation.Phenotype}</strong> -{' '}
                  {geneInformation.Interpretation}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )
    })
  }

  return (
    <div className={clsx(layoutClasses.pt1, 'member-pgx-container')}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-evenly"
        className={layoutClasses.mb4}
      >
        <Grid item xs={12}>
          <Typography
            className={clsx(layoutClasses.mb2, classes.title)}
            component="span"
            variant="h3"
            display="block"
          >
            Genotype
          </Typography>
          <PGxBreadcrumbs startAt={MemberMenuItems.PGX} />
        </Grid>

        <Grid item xs={12}>
          <BaseCard>
            <BaseRow
              columns={
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Grid item xs={3} className={classes.columnPadding}>
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(classes.typography, rxClasses.fontSize16)}
                    >
                      <strong>Gene</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.columnPadding}>
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(classes.typography, rxClasses.fontSize16)}
                    >
                      <strong>Genotype</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.columnPadding}>
                    <Typography
                      component="span"
                      variant="body1"
                      className={clsx(classes.typography, rxClasses.fontSize16)}
                    >
                      <strong>Interpretation</strong>
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
            <Genes pharmacogenetics={report.Pharmacogenetics} />
          </BaseCard>
        </Grid>
      </Grid>
    </div>
  )
}
