import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'

import {
  selectNormalizedCarePartners,
  selectNormalizedPsychiatrists,
  selectNormalizedTherapists,
} from 'redux/selectors'

import ProfileCard from './ProfileCard'
import CareTeamCard from './CareTeamCard'
import ContactInfoCard from './ContactInfoCard'
import MemberAccountCard from './MemberAccountCard'

function MemberOverview({
  carePartners,
  psychiatrists,
  therapists,
  externalProviders,
  ...props
}) {
  return (
    <Box display="flex" flexDirection="column" gridGap={16}>
      <ProfileCard {...props} />
      <CareTeamCard
        {...props}
        carePartners={carePartners}
        psychiatrists={psychiatrists}
        therapists={therapists}
        externalProviders={externalProviders}
      />
      <ContactInfoCard {...props} />
      <MemberAccountCard {...props} />
    </Box>
  )
}

const mapStateToProps = state => ({
  carePartners: selectNormalizedCarePartners(state),
  psychiatrists: selectNormalizedPsychiatrists(state),
  therapists: selectNormalizedTherapists(state),
  externalProviders: {
    byId: state.externalProviders.byId,
    allIds: state.externalProviders.allIds,
  },
})

export default connect(mapStateToProps, null)(MemberOverview)
