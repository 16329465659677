import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { API_BASE_URL, HTTP_METHODS } from 'constants/api'
import { useSelector, useDispatch } from 'react-redux'
import { startLoadingNotes, stopLoadingNotes } from 'redux/notes'
import CircularProgress from '@material-ui/core/CircularProgress'
import EncounterDisplay from 'components/Member/MemberEncounters/EncounterDisplay'
import EncounterForm from 'components/Member/MemberEncounters/EncounterForm'
import EncounterHistories from 'components/Member/MemberEncounters/EncounterHistories'
import Encounter from 'components/Member/MemberEncounters/Encounter'
import { addNotes } from 'redux/notes'
import './MemberEncounter.css'

const EncounterDetails = ({ encounter, member }) => {
  const isFinalized = encounter?.finalized ?? false
  if (isFinalized) {
    return <EncounterDisplay encounter={encounter} />
  }

  return <EncounterForm encounter={encounter} member={member} />
}

const MemberEncounter = ({ member }) => {
  const { fetchWithRefresh } = useGoogleAuth()
  const dispatch = useDispatch()
  const { memberId, encounterId } = useParams()
  const { isLoading, encounters } = useSelector(state => ({
    isLoading: state.notes.isLoading,
    encounters: state.notes.notes,
  }))

  const fetchEncounter = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/notes/${memberId}`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      const result = await response.json()
      dispatch(addNotes(result))
    }
  }

  useEffect(() => {
    dispatch(startLoadingNotes())
    if (Object.keys(encounters).length === 0) {
      ;(async () => await fetchEncounter())()
    }
    dispatch(stopLoadingNotes())
  }, [memberId, encounterId]) // eslint-disable-line

  if (isLoading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="member-notes-container">
      {encounters[encounterId] && (
        <>
          <Encounter encounter={encounters[encounterId]} member={member} />
          <EncounterDetails
            encounter={encounters[encounterId]}
            member={member}
          />
          <EncounterHistories encounter={encounters[encounterId]} />
        </>
      )}
    </div>
  )
}

export default MemberEncounter
