import { useLayoutStyles } from 'hooks'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BaseCard from 'components/Shared/Card/BaseCard'

const useStyles = makeStyles(() => ({
  title: { fontSize: '18px' },
  paragraph: { fontSize: '12px', lineHeight: '18px' },
}))

export default function PGxDisclosureCard({ disclosure }) {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const text = {
    title: 'Disclosure',
  }
  return (
    <BaseCard>
      <div className={layoutClasses.padding3}>
        <Typography className={classes.title} component="span" display="block">
          {text.title}
        </Typography>
        {Object.values(disclosure).map((el, i) => (
          <p className={classes.paragraph} key={`${i}`}>
            <strong>{el.heading}</strong>
            <br />
            {el.content}
          </p>
        ))}
      </div>
    </BaseCard>
  )
}
