export const MemberMenuItems = {
  OVERVIEW: 'Overview',
  DETAILS: 'Details',
  FILES: 'Files',
  NOTES: 'Notes',
  ENCOUNTERS: 'Encounters',
  APPT: 'Appt',
  RX: 'Rx',
  PROGRESS: 'Progress',
  BILLING: 'Billing',
  PGX: 'PGx',
}

export const MEMBER_NAV_TABS = [
  { name: MemberMenuItems.OVERVIEW, hidden: false },
  { name: MemberMenuItems.DETAILS, hidden: false },
  { name: MemberMenuItems.PROGRESS, hidden: false },
  { name: MemberMenuItems.ENCOUNTERS, hidden: false },
  { name: MemberMenuItems.FILES, hidden: false },
  { name: MemberMenuItems.RX, hidden: false },
  { name: MemberMenuItems.PGX, hidden: false },
  { name: MemberMenuItems.BILLING, hidden: false },
  { name: MemberMenuItems.APPT, hidden: true },
]

export const UploadOptions = {
  UPLOAD_NEW_DOCUMENT: 'Upload new document',
  PATIENT_HEALTH_RECORD: 'Patient health record',
  LETTER_DIAGNOSIS_PROOF_CARE: 'Letter - Diagnosis & proof of care',
  LETTER_WITHDRAWL_CARE: 'Letter - Withdrawal of care',
  FORM_RELEASE_INFORMATION: 'Form - Release of Information',
}

export const UPLOAD_OPTIONS_MENU = [
  {
    key: UploadOptions.UPLOAD_NEW_DOCUMENT,
    name: UploadOptions.UPLOAD_NEW_DOCUMENT,
    hidden: false,
  },
  {
    key: UploadOptions.PATIENT_HEALTH_RECORD,
    name: UploadOptions.PATIENT_HEALTH_RECORD,
    hidden: true,
  },
  {
    key: UploadOptions.LETTER_DIAGNOSIS_PROOF_CARE,
    name: UploadOptions.LETTER_DIAGNOSIS_PROOF_CARE,
    hidden: true,
  },
  {
    key: UploadOptions.LETTER_WITHDRAWL_CARE,
    name: UploadOptions.LETTER_WITHDRAWL_CARE,
    hidden: true,
  },
  {
    key: UploadOptions.FORM_RELEASE_INFORMATION,
    name: UploadOptions.FORM_RELEASE_INFORMATION,
    hidden: true,
  },
]

export const DocumentTypes = {
  PGxReport: 'PGxReport',
  PhotoID: 'PhotoID',
  Document: 'Document',
  Insurance: 'Insurance',
  Form: 'Form',
  DoctorLetter: 'DoctorLetter',
  Prescription: 'Prescription',
  Logistics: 'Logistics',
  Payment: 'Payment',
  PastHealthRecord: 'PastHealthRecord',
  Misc: 'Misc',
  LetterOfWithdrawal: 'LetterOfWithdrawal',
}

export const UploadOptionToDocument = {
  [UploadOptions.PATIENT_HEALTH_RECORD]: DocumentTypes.PastHealthRecord,
  [UploadOptions.LETTER_DIAGNOSIS_PROOF_CARE]: DocumentTypes.DoctorLetter,
  [UploadOptions.LETTER_WITHDRAWL_CARE]: DocumentTypes.LetterOfWithdrawal,
  [UploadOptions.FORM_RELEASE_INFORMATION]: DocumentTypes.Form,
}

export const IMPORTANT_FILE_TYPES = {
  [DocumentTypes.PGxReport]: 'PGX REPORT',
  [DocumentTypes.PhotoID]: 'PHOTO ID',
}

export const DOCUMENT_TYPES = Object.entries(DocumentTypes).map(
  ([key, value]) => ({
    label: key,
    value: value,
  }),
)

export const MemberTypes = {
  MEMBER_SELF: { label: 'Member (self)', value: 'Self' },
  PSYCHIATRIST: { label: 'Psychiatrist', value: 'Psychiatrist' },
  EXTERNAL_PROVIDER: {
    label: 'External Provider',
    value: 'ExternalProvider',
  },
  CUSTOM: { label: 'Custom Email', value: 'Custom' },
}

export const MEMBER_STATUS = {
  Active: 'Active',
  Cancelled: 'Cancelled',
  Onboarding: 'Onboarding',
  Pending: 'Pending',
}

export const DEFAULT_MEMBER = {
  // Profile
  firstName: '',
  lastName: '',
  preferredName: '',
  dob: new Date(),
  status: MEMBER_STATUS.Onboarding,
  heightFeet: 5,
  heightInch: 0,
  weight: 0,
  // gender: null,
  // pronouns: null,
  // race: null,
  preferredLanguage: 'English',
  // source: {
  //   category: null,
  //   description: '',
  // },

  // Care Team
  carePartner: null,
  psychiatrist: null,

  // Contact info
  email: '',
  phone: '',
  shippingAddress: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  billingAddress: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  pharmacy: {
    name: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  emergencyContact: {
    name: '',
    relationship: '',
    phone: '',
    email: '',
  },

  // Diagnosis
  diagnosisList: [],

  // Risk
  dangerToSelf: {
    danger: false,
    description: '',
  },

  dangerToOthers: {
    danger: false,
    description: '',
  },

  weapon: {
    hasAccess: false,
    description: '',
  },
}
