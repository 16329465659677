import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addNotes, startLoadingNotes, stopLoadingNotes } from 'redux/notes'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePutNote() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const putNote = useCallback(
    async (note, autosave = false) => {
      if (!autosave) {
        dispatch(startLoadingNotes())
      }
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/note/${note._id}`,
        options: {
          method: HTTP_METHODS.PUT,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ note, autosave }),
        },
      })

      if (response.ok) {
        const result = await response.json()
        dispatch(addNotes(result))
        dispatch(stopLoadingNotes())
      } else {
        throw new Error('Failed to update note')
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return putNote
}
