import { getUserTitle } from 'utils/users'
import { addTitle, formatUserInitials } from 'utils/formatters'

import BaseProfile from './BaseProfile'

const UserProfile = ({ user }) => (
  <BaseProfile
    avatarUrl={user.profilePic}
    initials={formatUserInitials(user)}
    title={getUserTitle(user.userType)}
    name={addTitle(user)}
  />
)

export default UserProfile
