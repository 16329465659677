import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReactQuill from 'react-quill'
import EncounterTitle from './EncounterTitle'
import AutoSaveDisplay from './AutosaveDisplay'
import {
  ENCOUNTER_DETAIL_KEYS,
  ENCOUNTER_FIELD_OPTIONS,
  ENCOUNTER_TYPE,
} from 'utils/encounters'
import MultipleChoice from 'components/Shared/MultipleChoice'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import SelectTemplateDialog from 'components/Member/MemberEncounters/Dialogs/SelectTemplateDialog'

const useStyles = makeStyles(
  () => ({
    root: {
      marginTop: 16,
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#e3e3e3',
        },
        '&:hover fieldset': {
          borderColor: '#daefed',
        },
      },
    },
    sections: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    label: {
      margin: '0 0 7px 0',
    },
    noteInputField: {
      position: 'relative',
      display: 'block',
      width: '100%',
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    content: {
      padding: '16px',
      width: '100%',
      borderTop: '1px solid #e3e3e3',
    },
  }),
  { index: 1 },
)

const TextEditorComponent = ({
  title,
  stateNote,
  fieldChangeHandlers,
  modules,
}) => {
  return (
    <ReactQuill
      id={title}
      modules={modules}
      value={stateNote.details[title]}
      placeholder="Write here"
      onChange={value => {
        fieldChangeHandlers.handleChange(title, value)
      }}
    />
  )
}

const ComponentMap = ({ title, stateNote, fieldChangeHandlers }) => {
  const defaultEditorModules = {
    toolbar: [['bold', 'italic', 'underline']],
  }

  switch (title) {
    case 'typeOfActivity':
      return (
        <MultipleChoice
          options={ENCOUNTER_FIELD_OPTIONS.typeOfActivity}
          value={stateNote.details.typeOfActivity}
          onChange={fieldChangeHandlers.handleTypeOfActivityChange}
        />
      )
    case 'engagedWith':
      return (
        <MultipleChoice
          options={ENCOUNTER_FIELD_OPTIONS.engagedWith}
          value={stateNote.details.engagedWith}
          onChange={fieldChangeHandlers.handleEngagedWithChange}
        />
      )
    case 'topic':
      return (
        <MultipleChoice
          options={ENCOUNTER_FIELD_OPTIONS.topic}
          value={stateNote.details.topic}
          onChange={fieldChangeHandlers.handleTopicChange}
        />
      )
    default:
      return (
        <TextEditorComponent
          title={title}
          stateNote={stateNote}
          fieldChangeHandlers={fieldChangeHandlers}
          modules={defaultEditorModules}
        />
      )
  }
}

const EncounterNote = ({
  encounter,
  stateNote,
  fieldChangeHandlers,
  updateNote,
  timeOfLastSave,
}) => {
  const classes = useStyles()
  const encounterDetails = Object.entries(encounter.details)
  const hasPublicNote = Boolean(encounter.details?.publicNote ?? false)
  const canSelectTemplate = [
    ENCOUNTER_TYPE.THERAPY,
    ENCOUNTER_TYPE.PSYCHIATRY,
  ].includes(encounter.type)

  const [openTemplateModal, setOpenTemplateModal] = useState(false)

  const updateEncounterDetails = template => {
    const templateText = template.text
    updateNote({
      ...stateNote,
      details: {
        ...stateNote.details,
        encounter: templateText,
      },
    })
  }

  return (
    <>
      <BaseCard className={`${classes.root}`}>
        <BaseCardHeader
          bold={true}
          title={'Encounter Note'}
          actionOnClick={
            canSelectTemplate
              ? () => {
                  setOpenTemplateModal(true)
                }
              : null
          }
          actionText={canSelectTemplate ? 'Select Template' : ''}
        />

        <div className={classes.content}>
          {encounterDetails.map(([title, content], index) => (
            <div key={index} className={classes.noteInputField}>
              {title !== 'encounter' ? (
                <EncounterTitle
                  variant="h4"
                  style={{ padding: '10px 0px 8px' }}
                >
                  {ENCOUNTER_DETAIL_KEYS[title]}
                </EncounterTitle>
              ) : null}

              {ComponentMap({
                title,
                stateNote,
                fieldChangeHandlers,
              })}
            </div>
          ))}
          {hasPublicNote && (
            <Typography variant="caption" className={classes.label}>
              Information entered in this section will be shared with the
              patient through the patient portal
            </Typography>
          )}
          <AutoSaveDisplay timeOfLastSave={timeOfLastSave} />
        </div>
      </BaseCard>

      {openTemplateModal && (
        <SelectTemplateDialog
          open={openTemplateModal}
          onClose={() => {
            setOpenTemplateModal(false)
          }}
          onSetTemplate={updateEncounterDetails}
          title="Select Template"
        />
      )}
    </>
  )
}

export default EncounterNote
