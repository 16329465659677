import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  memberFileDeleting,
  memberFileDeleteSuccess,
  memberFileDeleteError,
} from 'redux/files/actions'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function useFileDelete() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const deleteFile = useCallback(
    async data => {
      dispatch(memberFileDeleting())
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/files/delete`,
        options: {
          method: HTTP_METHODS.DELETE,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      })

      if (response.ok) {
        const result = await response.json()
        dispatch(memberFileDeleteSuccess(result._id))
      } else {
        dispatch(memberFileDeleteError('File delete failed'))
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return deleteFile
}
