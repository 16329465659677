import { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  highlightRed: {
    color: palette.accentRed.main,
  },
}))

export default function InsuranceCoverageMenu({
  currentIndex,
  insuranceId,
  allInsurances,
  onIndexChange,
  onEdit,
  onDelete,
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const numberOfInsurances = allInsurances.length
  const canMoveUp = currentIndex !== 0
  const canMoveDown = currentIndex !== numberOfInsurances - 1

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const updateCoverageIndex = async (insuranceId, newIndex) => {
    const updatedInsurance = {
      _id: insuranceId,
      coverageIndex: newIndex,
    }
    onIndexChange(updatedInsurance)
  }

  return (
    <>
      <IconButton
        aria-label="insurance options"
        aria-controls="insurance-coverage-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="insurance-coverage-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose()
            onEdit()
          }}
        >
          Edit detail
        </MenuItem>

        <MenuItem
          disabled={!canMoveUp}
          onClick={() => {
            const newIndex = currentIndex - 1
            updateCoverageIndex(insuranceId, newIndex)
            handleMenuClose()
          }}
        >
          Move up
        </MenuItem>

        <MenuItem
          disabled={!canMoveDown}
          onClick={() => {
            const newIndex = currentIndex + 1
            updateCoverageIndex(insuranceId, newIndex)
            handleMenuClose()
          }}
        >
          Move down
        </MenuItem>

        <MenuItem
          className={classes.highlightRed}
          onClick={() => {
            handleMenuClose()
            onDelete(insuranceId)
          }}
        >
          Delete coverage
        </MenuItem>
      </Menu>
    </>
  )
}
