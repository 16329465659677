import BaseAccordion from 'components/Shared/BaseAccordion'

import ChronicConditionSection from './Sections/ChronicConditionSection'
import MedicalFamilyHistorySection from './Sections/MedicalFamilyHistorySection'
import MedicalHospitalizationHistorySection from './Sections/MedicalHospitalizationHistorySection'
import MedicalSurgeryHistorySection from './Sections/MedicalSurgeryHistorySection'
import MedicalDiagnosisHistorySection from './Sections/MedicalDiagnosisHistorySection'

export default function PastMedicalHistoryAccordion(props) {
  return (
    <BaseAccordion title="Past Medical History">
      <MedicalFamilyHistorySection {...props} />
      <MedicalHospitalizationHistorySection {...props} />
      <MedicalSurgeryHistorySection {...props} />
      <MedicalDiagnosisHistorySection {...props} />
      <ChronicConditionSection {...props} />
    </BaseAccordion>
  )
}
