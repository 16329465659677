import clsx from 'clsx'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import PGxItem from './PGxItem'
import { useRxStyles, usePendingPGx } from 'hooks'
import { selectAllMembers } from 'redux/selectors'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    overflowY: 'scroll',
    padding: spacing(0, 3),
    flexGrow: 1,
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(4, 0),
  },
  button: {
    margin: spacing(0, 2),
    color: palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
}))

const PendingPGxs = ({ isMyPatientCheck }) => {
  const classes = useStyles()
  const rxClasses = useRxStyles()

  const allMembers = useSelector(selectAllMembers)
  const byIdMembers = useSelector(({ members }) => members.byId)
  const pgxs = useSelector(({ pgxs }) => pgxs.allPgxs)

  const filteredPGxs = usePendingPGx(isMyPatientCheck, allMembers, pgxs)

  return (
    <Box className={classes.root}>
      {!filteredPGxs.length && (
        <Typography
          className={clsx(
            rxClasses.textAlignCenter,
            rxClasses.fontSize16,
            rxClasses.secondaryNavy2,
          )}
        >
          No pending orders
        </Typography>
      )}
      {filteredPGxs.map(pgx => (
        <PGxItem key={pgx._id} pgx={pgx} member={byIdMembers[pgx.memberId]} />
      ))}
    </Box>
  )
}

export default PendingPGxs
