import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useFormFields, useMemberRx } from 'hooks'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles } from 'hooks/useRxStyles'
import BaseDialog from 'components/Shared/BaseDialog'
import TextInput from 'ui/TextInput'
import TextButton from 'components/Shared/TextButton'

export default function SearchForm({ open, handleClose, onResultsChange }) {
  const { userId } = useMemberRx()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const postRequest = usePostRequest()
  const [searchResults, setResults] = useState(null)
  const [loading, setIsLoading] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    search: '',
  })

  useEffect(() => {
    if (searchResults) {
      onResultsChange(searchResults)
    }
  }, [searchResults, onResultsChange])

  function validateForm() {
    return fields.search.length > 0
  }

  async function search({ body, params: { userId } }) {
    setIsLoading(true)
    const result = await postRequest({
      url: `${API_BASE_URL}/dosespot/drug-allergies/search/${userId}`,
      body,
    })
    if (result || result.length < 0) {
      setResults(result)
    } else {
      setResults([])
    }
    setIsLoading(false)
  }

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Add Drug Allergy"
      dialogContent={
        <>
          {searchResults && searchResults.length === 0 && (
            <span>There are no results found for your search term</span>
          )}
          <TextInput
            id="search"
            fullWidth
            variant="outlined"
            label="Search"
            name="search"
            value={fields.search}
            onChange={handleFieldChange}
          />
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={handleClose}
          >
            CANCEL
          </TextButton>
          <TextButton
            disabled={!validateForm()}
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() =>
              search({
                body: fields,
                params: { userId },
              })
            }
          >
            SEARCH
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
