import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'symptomRemark',
  label: 'Remark',
  componentProps: {
    multiline: true,
  },
}

const RemarkSection = props => (
  <SingleFieldSection {...props} title="Remark" field={FIELD} />
)

export default withMemberForm('RemarkSectionForm', ['symptomRemark'])(
  RemarkSection,
)
