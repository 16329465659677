import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles, useLayoutStyles } from 'hooks'
import usePostRequest from 'hooks/api/usePostRequest'
import { API_BASE_URL } from 'constants/api'

import AssignmentIcon from '@material-ui/icons/Assignment'
import BaseDialog from 'components/Shared/BaseDialog'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextButton from 'components/Shared/TextButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export default function AssessmentModal({ open, onClose, member }) {
  const theme = useTheme()
  const rxClasses = useRxStyles()
  const layoutClasses = useLayoutStyles()
  const postRequest = usePostRequest()
  const { enqueueSnackbar } = useSnackbar()

  const [assessmentLink, setAssessmentLink] = useState('')

  const generateAssesmentLink = useCallback(
    async member => {
      if (!member) return
      const response = await postRequest({
        url: `${API_BASE_URL}/cp/members/one-time-assessment/generate`,
        body: member,
      })
      setAssessmentLink(response.assessmentUrl)
    },
    [postRequest],
  )

  const handleSendSms = async () => {
    try {
      const message = `Your provider just sent you a mental health assessment. Please click on the following link to complete the assessment. The assessment will take approximately 3 minutes to complete. \n\n${assessmentLink}`
      const response = await postRequest({
        url: `${API_BASE_URL}/cp/sms/send`,
        body: { message, phone: member.phone },
      })

      if (response.warning) {
        enqueueSnackbar(response.message, {
          variant: 'warning',
        })
      } else {
        enqueueSnackbar('Sent text message', {
          variant: 'success',
        })
      }
    } catch (error) {
      enqueueSnackbar('Failed sending the text', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (assessmentLink === '') {
      generateAssesmentLink(member)
    }
  }, [assessmentLink, generateAssesmentLink, member])

  useEffect(() => {
    return () => {
      setAssessmentLink('')
    }
  }, [open])

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        onClose(false)
      }}
      title="Request assessment"
      dialogContent={
        <>
          <Typography
            style={{ lineHeight: '21px' }}
            className={clsx(
              rxClasses.typography,
              layoutClasses.mt3,
              layoutClasses.mb2,
            )}
          >
            Below is a one-time link to an assessment. You may copy the link to
            your clipboard or directly send the link to the patient via text
            message
          </Typography>
          <TextField
            style={{ fontSize: '14px!important', cursor: 'pointer!important4' }}
            className={clsx(rxClasses.fontSize14, rxClasses.typography)}
            variant="outlined"
            fullWidth
            name="assessmentLink"
            id="assessmentLink"
            value={assessmentLink}
            onClick={() => {
              navigator.clipboard.writeText(assessmentLink)
              enqueueSnackbar('Copied assessment link to clipboard', {
                variant: 'success',
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AssignmentIcon />
                </InputAdornment>
              ),
            }}
          />
        </>
      }
      dialogActions={
        <>
          <TextButton
            disabled={!member?.phone ?? false}
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={handleSendSms}
          >
            SEND VIA TEXT
          </TextButton>
          <div></div>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={onClose}
          >
            CLOSE
          </TextButton>
        </>
      }
      loading={false}
    />
  )
}
