import { useSelector } from 'react-redux'
import { selectAllUsers } from 'redux/selectors'
import { useTheme } from '@material-ui/core/styles'

import AvatarChip from 'components/Shared/Chips/AvatarChip'
import { formatDate } from 'utils/formatters'
import { parseComment } from 'utils/commentHelpers'

export default function Comment({ comment }) {
  const theme = useTheme()
  const users = useSelector(selectAllUsers)
  const [commentAuthor] = users.filter(user => user._id === comment.authoredBy)

  return (
    <div className="member-feed-comment" style={{ marginBottom: 32 }}>
      <div style={{ marginBottom: 8 }}>
        <AvatarChip user={commentAuthor} />
      </div>
      <span
        style={{
          fontSize: 14,
          lineHeight: '18px',
          marginBottom: 4,
          color: '#183f4f',
        }}
        dangerouslySetInnerHTML={{
          __html: parseComment(comment.comment),
        }}
      ></span>
      <p
        style={{
          marginTop: 4,
          fontSize: 10,
          fontStyle: 'italic',
          color: theme.palette.text.primary,
        }}
      >
        {comment.createdAt
          ? formatDate(comment.createdAt)
          : 'Date not available'}
      </p>
    </div>
  )
}
