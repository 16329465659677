import { formatDateString } from 'utils/formatters'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  addendum: {
    display: 'flex',
    padding: 0,
    border: 'none',
  },
  content: {
    background: 'white',
    border: `1px solid ${theme.palette.distinctiveGray.main}`,
    borderRadius: '4px',
    paddingLeft: theme.spacing(2),
    width: '100%',
    color: '#183f4f',
  },
  branchIndicatorTop: {
    borderLeft: `1px solid ${theme.palette.distinctiveGray.main}`,
    borderBottom: `1px solid ${theme.palette.distinctiveGray.main}`,
    width: 30,
    height: '50%',
    background: theme.palette.backgroundGreen.main,
  },
  branchIndicatorBottom: {
    width: 30,
    height: '50%',
    background: theme.palette.backgroundGreen.main,
  },
}))

export default function Addendum({ addendum }) {
  const classes = useStyles()
  const user = useSelector(state => state.users.byId[addendum.userID])

  const firstName = user?.firstName || 'First Name Missing'
  const lastName = user?.lastName || 'Last Name Missing'

  return (
    <div className={classes.addendum}>
      <div>
        <div className={classes.branchIndicatorTop} />
        <div className={classes.branchIndicatorBottom} />
      </div>
      <div className={classes.content}>
        <h3 style={{ fontSize: 14 }}>{`Addendum (${formatDateString(
          addendum.dateCreated,
        )}) by ${firstName} ${lastName}`}</h3>
        <h4>{addendum.description}</h4>
      </div>
    </div>
  )
}
