import { useMemo } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

import {
  formatAge,
  formatHeight,
  formatWeight,
  formatGender,
  formatPronoun,
} from 'utils/formatters'
import PopoverChip from './PopoverChip'

const useStyles = makeStyles(({ spacing, typography }) => ({
  listItem: {
    padding: spacing(0, 2),

    '& .value': {
      fontWeight: typography.fontWeightMedium,
    },
  },
  listItemText: {
    '& span': {
      fontSize: 14,
    },
  },
}))

export default function SnapshotChip({ member }) {
  const classes = useStyles()
  const items = useMemo(
    () => [
      {
        label: 'Sex',
        value: formatGender(member.gender),
      },
      {
        label: 'Age',
        value: formatAge(member.dob),
      },
      {
        label: 'Height',
        value: formatHeight(member.height, true),
      },
      {
        label: 'Weight',
        value: formatWeight(member.weight),
      },
      {
        label: 'Pronoun',
        value: formatPronoun(member.pronouns),
      },
    ],
    [member],
  )

  return (
    <PopoverChip chipLabel="Snapshot" horizontalPlacement="left">
      <List>
        {items.map(({ label, value }) => (
          <ListItem key={label} className={classes.listItem}>
            <ListItemText className={classes.listItemText}>
              {label}: <span className="value">{value}</span>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </PopoverChip>
  )
}
