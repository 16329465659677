import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'relievedBy',
  label: 'Relieved By',
  componentProps: {
    multiline: true,
  },
}

const RelievedBySection = props => (
  <SingleFieldSection {...props} title="Relieved By" field={FIELD} />
)

export default withMemberForm('RelievedBySectionForm', ['relievedBy'])(
  RelievedBySection,
)
