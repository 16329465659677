export const ENCOUNTER_STATE = {
  DRAFT: 'Draft',
  NO_SHOW: 'No-show',
  FINALIZED: 'Finalized',
}

export const ENCOUNTER_TYPE = {
  CARE_MANAGEMENT: 'Care Management',
  PSYCHIATRY: 'Psychiatry',
  THERAPY: 'Therapy',
}

export const mapEncounterTypes = {
  [ENCOUNTER_TYPE.CARE_MANAGEMENT]: 'care-management',
  [ENCOUNTER_TYPE.PSYCHIATRY]: 'psych',
  [ENCOUNTER_TYPE.THERAPY]: 'therapy',
}

export const ENCOUNTER_DETAIL_KEYS = {
  subjective: 'Subjective',
  objective: 'Objective',
  plan: 'Plan',
  assessment: 'Assessment',
  publicNote: 'Public Note',
  typeOfActivity: 'Type of activity',
  note: 'Note',
  problemIntervalHistory: 'Problem/Interval History',
  intervention: 'Intervention',
  response: 'Response',
  noteAndMethod: 'Additional Note',
  topic: 'Topic',
  engagedWith: 'Engaged With',
  encounter: 'Encounter',
}

export const ENCOUNTER_FIELD_OPTIONS = {
  typeOfActivity: [
    {
      _id: '1',
      display: 'Email',
    },
    {
      _id: '2',
      display: 'Phone Call',
    },
    {
      _id: '3',
      display: 'Chart Update',
    },
    {
      _id: '4',
      display: 'Video Call',
    },
    {
      _id: '5',
      display: 'Chart Review',
    },
    {
      _id: '6',
      display: 'Other',
    },
  ],
  engagedWith: [
    {
      _id: '1',
      display: 'Patient',
    },
    {
      _id: '2',
      display: "Patient's Family",
    },
    {
      _id: '3',
      display: 'Psychiatrist',
    },
    {
      _id: '4',
      display: 'Therapist',
    },
    {
      _id: '5',
      display: "Patient's PCP",
    },
    {
      _id: '6',
      display: 'Other Providers',
    },
    {
      _id: '7',
      display: 'Other',
    },
  ],
  topic: [
    {
      _id: '1',
      display: 'Prescription',
    },
    {
      _id: '2',
      display: 'Treatment Plan',
    },
    {
      _id: '3',
      display: 'Treatment Response',
    },
    {
      _id: '4',
      display: 'Side Effects',
    },
    {
      _id: '5',
      display: 'Clinical Question',
    },
    {
      _id: '6',
      display: 'Assessment',
    },
    {
      _id: '7',
      display: 'Intake Form',
    },
    {
      _id: '8',
      display: 'Referral',
    },
    {
      _id: '9',
      display: 'Other Care Options',
    },
    {
      _id: '10',
      display: 'External Provider',
    },
    {
      _id: '11',
      display: 'Wellness',
    },
    {
      _id: '12',
      display: 'Psychoeducation',
    },
    {
      _id: '13',
      display: 'Pharmacogenomics',
    },
    {
      _id: '14',
      display: 'Safety',
    },
    {
      _id: '15',
      display: 'Other',
    },
  ],
}
