import get from 'lodash/get'

import FormikAutocomplete from 'components/Shared/Forms/Fields/FormikAutocomplete'
import FormikCheckbox from 'components/Shared/Forms/Fields/FormikCheckbox'
import FormikDatePicker from 'components/Shared/Forms/Fields/FormikDatePicker'
import FormikInput from 'components/Shared/Forms/Fields/FormikInput'

const COMPONENTS = {
  autocomplete: FormikAutocomplete,
  input: FormikInput,
  select: FormikInput,
  check: FormikCheckbox,
  date: FormikDatePicker,
}

export const getFormikComponent = type => get(COMPONENTS, type, FormikInput)
