import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  textButton: {
    padding: 0,
  },
}))

export default function TextButton({
  children,
  className,
  onClick,
  disabled = false,
  style,
  ...props
}) {
  const classes = useStyles()

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant="text"
      color="primary"
      className={clsx(classes.textButton, className)}
      style={style}
      {...props}
    >
      {children}
    </Button>
  )
}
