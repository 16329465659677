import { useState } from 'react'
import SearchForm from 'components/Member/MemberRx/Dialogs/DrugAllergy/SearchForm'
import ResultsForm from 'components/Member/MemberRx/Dialogs/DrugAllergy/ResultsForm'
import SelectedForm from 'components/Member/MemberRx/Dialogs/DrugAllergy/SelectedForm'

export function AddDrugAllergy({ open, handleClose }) {
  const [searchResults, setSearchResults] = useState([])
  const [selected, setSelected] = useState(null)

  if (selected) {
    return (
      <SelectedForm
        open={open}
        handleClose={handleClose}
        selected={selected}
        onDeSelect={() => {
          setSelected(null)
        }}
      />
    )
  }

  if (searchResults.length > 0) {
    return (
      <ResultsForm
        open={open}
        handleClose={handleClose}
        results={searchResults}
        onSelected={setSelected}
      />
    )
  }

  return (
    <SearchForm
      open={open}
      handleClose={handleClose}
      onResultsChange={setSearchResults}
    />
  )
}
