import { Grid, Typography, makeStyles } from '@material-ui/core'

import AssessmentQuestion from './AssessmentQuestion'

const useStyles = makeStyles({
  groupTitle: {
    fontWeight: 700,
  },
})

const AssessmentGroup = ({
  group,
  answersDisabled,
  scores,
  baseIndex,
  onScore,
}) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      {group.title && (
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.groupTitle}>
            {group.title}
          </Typography>
        </Grid>
      )}

      {group.fields.map(({ quesLabel, possibleScores }, fieldIndex) => (
        <Grid key={quesLabel} item xs={12}>
          <AssessmentQuestion
            title={`${baseIndex + fieldIndex + 1}. ${quesLabel}`}
            disabled={answersDisabled?.[fieldIndex]}
            score={scores?.[fieldIndex]}
            possibleScores={possibleScores}
            onScore={score => onScore(fieldIndex, score)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default AssessmentGroup
