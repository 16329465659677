import { useMemo } from 'react'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import identity from 'lodash/identity'
import groupBy from 'lodash/groupBy'
import { daysBetween, formatFullName } from 'utils/formatters'
import { MEMBER_STATUS } from 'constants/member'

import { useCurrentUser } from 'hooks'

export const useFilteredMembers = (
  isMyPatientsProgress,
  currentUser,
  allMembers,
) => {
  const activeMembers = useMemo(
    () =>
      allMembers.filter(
        member =>
          member.status === MEMBER_STATUS.Active ||
          member.status === MEMBER_STATUS.Onboarding,
      ),
    [allMembers],
  )

  const filteredMembers = useMemo(() => {
    const members = isMyPatientsProgress
      ? activeMembers.filter(
          member =>
            member.carePartner === currentUser._id ||
            member.psychiatrist === currentUser._id ||
            member.therapist === currentUser._id,
        )
      : activeMembers
    return members
  }, [activeMembers, currentUser._id, isMyPatientsProgress])

  return {
    filteredMembers,
  }
}

export const useFilteredProgresses = (allProgresses, filteredMembers) => {
  const sortedProgresses = useMemo(
    () => orderBy(allProgresses, ['timeStamp'], ['desc']),
    [allProgresses],
  )
  const groupedProgresses = useMemo(
    () => groupBy(sortedProgresses, 'memberID'),
    [sortedProgresses],
  )
  const unreadProgresses = useMemo(
    () => filter(sortedProgresses, ['markAsRead', false]),
    [sortedProgresses],
  )
  const unreadFilteredProgresses = useMemo(
    () =>
      unreadProgresses.filter(
        progress =>
          filteredMembers.findIndex(
            member => member._id === progress.memberID,
          ) !== -1,
      ),
    [filteredMembers, unreadProgresses],
  )

  return {
    groupedProgresses,
    unreadFilteredProgresses,
  }
}

export const useLatestProgresses = (
  isMyPatientsProgress,
  allMembers,
  allProgresses,
  byIdMembers,
) => {
  const currentUser = useCurrentUser()

  const { filteredMembers } = useFilteredMembers(
    isMyPatientsProgress,
    currentUser,
    allMembers,
  )

  const { unreadFilteredProgresses } = useFilteredProgresses(
    allProgresses,
    filteredMembers,
  )

  const resultProgresses = useMemo(
    () =>
      unreadFilteredProgresses.map(progress => {
        return {
          ...progress,
          fullName: byIdMembers[progress.memberID]
            ? formatFullName(byIdMembers[progress.memberID], true)
            : 'Patient',
        }
      }),
    [byIdMembers, unreadFilteredProgresses],
  )

  return resultProgresses
}

export const useHighRiskProgresses = (
  isMyPatientsProgress,
  allMembers,
  allProgresses,
) => {
  const currentUser = useCurrentUser()

  const { filteredMembers } = useFilteredMembers(
    isMyPatientsProgress,
    currentUser,
    allMembers,
  )

  const { groupedProgresses } = useFilteredProgresses(
    allProgresses,
    filteredMembers,
  )

  const resultProgresses = useMemo(() => {
    return filteredMembers
      .map(member => {
        const memberProgresses = groupedProgresses[member._id]
        if (!memberProgresses || !memberProgresses.length) {
          return null
        }

        const reveresedMEmberProgresses = memberProgresses.slice().reverse()

        const lastUnreviewedHighRiskProgress = reveresedMEmberProgresses.find(
          progress =>
            (progress.totalGAD >= 15 || progress.totalPHQ >= 20) &&
            !progress.reviewedBy,
        )
        const lastHighRiskProgressIndex = reveresedMEmberProgresses.findIndex(
          progress => progress.totalGAD >= 15 || progress.totalPHQ >= 20,
        )

        if (lastUnreviewedHighRiskProgress) {
          return lastUnreviewedHighRiskProgress
        }
        if (lastHighRiskProgressIndex === 0) {
          return reveresedMEmberProgresses[lastHighRiskProgressIndex]
        }
        return null
      })
      .filter(identity)
  }, [filteredMembers, groupedProgresses])

  return orderBy(resultProgresses, ['timeStamp'], ['desc'])
}

export const useOverdueMembers = (
  isMyPatientsProgress,
  allMembers,
  allProgresses,
) => {
  const currentUser = useCurrentUser()

  const { filteredMembers } = useFilteredMembers(
    isMyPatientsProgress,
    currentUser,
    allMembers,
  )

  const { groupedProgresses } = useFilteredProgresses(
    allProgresses,
    filteredMembers,
  )

  const resultMembers = useMemo(() => {
    return filteredMembers
      .map(member => {
        const memberProgresses = groupedProgresses[member._id]
        const lastProgress = last(memberProgresses)

        const diffInDays = daysBetween(
          new Date(),
          lastProgress ? lastProgress.timeStamp : member.dateOfOnboarding,
        )

        return {
          ...member,
          fullName: formatFullName(member),
          lastProgressDaysBefore: lastProgress ? diffInDays : null,
        }
      })
      .filter(
        member =>
          member.lastProgressDaysBefore === null ||
          member.lastProgressDaysBefore > 30,
      )
      .sort((a, b) => b.lastProgressDaysBefore - a.lastProgressDaysBefore)
  }, [filteredMembers, groupedProgresses])

  return resultMembers
}
