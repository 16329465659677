import { makeStyles, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { useLatestProgresses } from 'hooks'
import { selectAllMembers } from 'redux/selectors'

import ProgressSubheader from './ProgressSubheader'
import ProgressItem from './ProgressItem'

const useStyles = makeStyles(({ spacing }) => ({
  progress: {
    overflowY: 'scroll',
  },
}))

const LatestPatientProgress = ({ isMyPatientsProgress }) => {
  const classes = useStyles()

  const allProgresses = useSelector(
    ({ progresses }) => progresses.progressesByID,
  )
  const allMembers = useSelector(selectAllMembers)
  const byIdMembers = useSelector(({ members }) => members.byId)

  const latestProgresses = useLatestProgresses(
    isMyPatientsProgress,
    allMembers,
    allProgresses,
    byIdMembers,
  )

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {!latestProgresses.length && (
        <ProgressSubheader content="No progress data pending review" />
      )}

      <Box className={classes.progress}>
        {latestProgresses.map(progress => (
          <ProgressItem key={progress._id} progress={progress} />
        ))}
      </Box>
    </Box>
  )
}

export default LatestPatientProgress
