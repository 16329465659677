import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import { useFormFields, useRxStyles } from 'hooks'

import TextInput from 'ui/TextInput'
import BaseDialog from 'components/Shared/BaseDialog'
import TextButton from 'components/Shared/TextButton'
import Typography from '@material-ui/core/Typography'

export default function UpdateStripeIdPrompt({
  open,
  handleClose,
  message,
  onUpdate,
  loading,
  title,
}) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const [fields, handleFieldChange] = useFormFields({
    stripeId: '',
  })

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        handleClose(false)
      }}
      title={title}
      dialogContent={
        <>
          <Typography className={rxClasses.typography}>
            {message ?? ''}
          </Typography>
          <TextInput
            id="stripeId"
            fullWidth
            variant="outlined"
            label="Stripe ID"
            name="stripeId"
            value={fields.stripeId}
            onChange={handleFieldChange}
          />
        </>
      }
      dialogActions={
        <>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              handleClose(false)
            }}
          >
            CANCEL
          </TextButton>
          <TextButton
            className={clsx(
              theme.typography.fontWeightMedium,
              rxClasses.fontSize16,
              rxClasses.typography,
            )}
            onClick={() => {
              handleClose(false)
              onUpdate(fields.stripeId)
            }}
          >
            UPDATE
          </TextButton>
        </>
      }
      loading={loading}
    />
  )
}
