import {
  DEFAULT_HISTORY_ROW,
  HISTORY_ROW_FIELDS,
} from 'constants/sectionFields'
import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const MedicalSurgeryHistorySection = props => (
  <ArrayFieldSection
    path="medicalSurgeryHistory"
    title="Past Surgery History"
    rowTitle="Surgery"
    defaultRow={DEFAULT_HISTORY_ROW}
    fields={HISTORY_ROW_FIELDS}
    {...props}
  />
)

export default withMemberForm('MedicalSurgeryHistorySectionForm', [
  'medicalSurgeryHistory',
])(MedicalSurgeryHistorySection)
