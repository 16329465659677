import { MemberLivingArrangements } from 'constants/selectOptions'
import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'livingArr',
  label: 'Living Arrangement',
  componentProps: {
    select: true,
    options: MemberLivingArrangements,
  },
}

const LivingArrangementSection = props => (
  <SingleFieldSection {...props} title="Living Arrangement" field={FIELD} />
)

export default withMemberForm('LivingArrangementSectionForm', ['livingArr'])(
  LivingArrangementSection,
)
