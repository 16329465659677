export const MentalDiagnosisTypes = [
  'F0150 Vascular dementia without behavioral disturbance',
  'F0151 Vascular dementia with behavioral disturbance',
  'F0280 Dementia in other diseases classified elsewhere without behavioral disturbance',
  'F0281 Dementia in other diseases classified elsewhere with behavioral disturbance',
  'F0390 Unspecified dementia without behavioral disturbance',
  'F0391 Unspecified dementia with behavioral disturbance',
  'F04 Amnestic disorder due to known physiological condition',
  'F05 Delirium due to known physiological condition',
  'F060 Psychotic disorder with hallucinations due to known physiological condition',
  'F061 Catatonic disorder due to known physiological condition',
  'F062 Psychotic disorder with delusions due to known physiological condition',
  'F0630 Mood disorder due to known physiological condition, unspecified',
  'F0631 Mood disorder due to known physiological condition with depressive features',
  'F0632 Mood disorder due to known physiological condition with major depressive-like episode',
  'F0633 Mood disorder due to known physiological condition with manic features',
  'F0634 Mood disorder due to known physiological condition with mixed features',
  'F064 Anxiety disorder due to known physiological condition',
  'F068 Other specified mental disorders due to known physiological condition',
  'F070 Personality change due to known physiological condition',
  'F0781 Postconcussional syndrome',
  'F0789 Other personality and behavioral disorders due to known physiological condition',
  'F079 Unspecified personality and behavioral disorder due to known physiological condition',
  'F09 Unspecified mental disorder due to known physiological condition',
  'F1010 Alcohol abuse, uncomplicated',
  'F10120 Alcohol abuse with intoxication, uncomplicated',
  'F10121 Alcohol abuse with intoxication delirium',
  'F10129 Alcohol abuse with intoxication, unspecified',
  'F1014 Alcohol abuse with alcohol-induced mood disorder',
  'F10150 Alcohol abuse with alcohol-induced psychotic disorder with delusions',
  'F10151 Alcohol abuse with alcohol-induced psychotic disorder with hallucinations',
  'F10159 Alcohol abuse with alcohol-induced psychotic disorder, unspecified',
  'F10180 Alcohol abuse with alcohol-induced anxiety disorder',
  'F10181 Alcohol abuse with alcohol-induced sexual dysfunction',
  'F10182 Alcohol abuse with alcohol-induced sleep disorder',
  'F10188 Alcohol abuse with other alcohol-induced disorder',
  'F1019 Alcohol abuse with unspecified alcohol-induced disorder',
  'F1020 Alcohol dependence, uncomplicated',
  'F1021 Alcohol dependence, in remission',
  'F10220 Alcohol dependence with intoxication, uncomplicated',
  'F10221 Alcohol dependence with intoxication delirium',
  'F10229 Alcohol dependence with intoxication, unspecified',
  'F10230 Alcohol dependence with withdrawal, uncomplicated',
  'F10231 Alcohol dependence with withdrawal delirium',
  'F10232 Alcohol dependence with withdrawal with perceptual disturbance',
  'F10239 Alcohol dependence with withdrawal, unspecified',
  'F1024 Alcohol dependence with alcohol-induced mood disorder',
  'F10250 Alcohol dependence with alcohol-induced psychotic disorder with delusions',
  'F10251 Alcohol dependence with alcohol-induced psychotic disorder with hallucinations',
  'F10259 Alcohol dependence with alcohol-induced psychotic disorder, unspecified',
  'F1026 Alcohol dependence with alcohol-induced persisting amnestic disorder',
  'F1027 Alcohol dependence with alcohol-induced persisting dementia',
  'F10280 Alcohol dependence with alcohol-induced anxiety disorder',
  'F10281 Alcohol dependence with alcohol-induced sexual dysfunction',
  'F10282 Alcohol dependence with alcohol-induced sleep disorder',
  'F10288 Alcohol dependence with other alcohol-induced disorder',
  'F1029 Alcohol dependence with unspecified alcohol-induced disorder',
  'F10920 Alcohol use, unspecified with intoxication, uncomplicated',
  'F10921 Alcohol use, unspecified with intoxication delirium',
  'F10929 Alcohol use, unspecified with intoxication, unspecified',
  'F1094 Alcohol use, unspecified with alcohol-induced mood disorder',
  'F10950 Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions',
  'F10951 Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations',
  'F10959 Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified',
  'F1096 Alcohol use, unspecified with alcohol-induced persisting amnestic disorder',
  'F1097 Alcohol use, unspecified with alcohol-induced persisting dementia',
  'F10980 Alcohol use, unspecified with alcohol-induced anxiety disorder',
  'F10981 Alcohol use, unspecified with alcohol-induced sexual dysfunction',
  'F10982 Alcohol use, unspecified with alcohol-induced sleep disorder',
  'F10988 Alcohol use, unspecified with other alcohol-induced disorder',
  'F1099 Alcohol use, unspecified with unspecified alcohol-induced disorder',
  'F1110 Opioid abuse, uncomplicated',
  'F11120 Opioid abuse with intoxication, uncomplicated',
  'F11121 Opioid abuse with intoxication delirium',
  'F11122 Opioid abuse with intoxication with perceptual disturbance',
  'F11129 Opioid abuse with intoxication, unspecified',
  'F1114 Opioid abuse with opioid-induced mood disorder',
  'F11150 Opioid abuse with opioid-induced psychotic disorder with delusions',
  'F11151 Opioid abuse with opioid-induced psychotic disorder with hallucinations',
  'F11159 Opioid abuse with opioid-induced psychotic disorder, unspecified',
  'F11181 Opioid abuse with opioid-induced sexual dysfunction',
  'F11182 Opioid abuse with opioid-induced sleep disorder',
  'F11188 Opioid abuse with other opioid-induced disorder',
  'F1119 Opioid abuse with unspecified opioid-induced disorder',
  'F1120 Opioid dependence, uncomplicated',
  'F1121 Opioid dependence, in remission',
  'F11220 Opioid dependence with intoxication, uncomplicated',
  'F11221 Opioid dependence with intoxication delirium',
  'F11222 Opioid dependence with intoxication with perceptual disturbance',
  'F11229 Opioid dependence with intoxication, unspecified',
  'F1123 Opioid dependence with withdrawal',
  'F1124 Opioid dependence with opioid-induced mood disorder',
  'F11250 Opioid dependence with opioid-induced psychotic disorder with delusions',
  'F11251 Opioid dependence with opioid-induced psychotic disorder with hallucinations',
  'F11259 Opioid dependence with opioid-induced psychotic disorder, unspecified',
  'F11281 Opioid dependence with opioid-induced sexual dysfunction',
  'F11282 Opioid dependence with opioid-induced sleep disorder',
  'F11288 Opioid dependence with other opioid-induced disorder',
  'F1129 Opioid dependence with unspecified opioid-induced disorder',
  'F1190 Opioid use, unspecified, uncomplicated',
  'F11920 Opioid use, unspecified with intoxication, uncomplicated',
  'F11921 Opioid use, unspecified with intoxication delirium',
  'F11922 Opioid use, unspecified with intoxication with perceptual disturbance',
  'F11929 Opioid use, unspecified with intoxication, unspecified',
  'F1193 Opioid use, unspecified with withdrawal',
  'F1194 Opioid use, unspecified with opioid-induced mood disorder',
  'F11950 Opioid use, unspecified with opioid-induced psychotic disorder with delusions',
  'F11951 Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations',
  'F11959 Opioid use, unspecified with opioid-induced psychotic disorder, unspecified',
  'F11981 Opioid use, unspecified with opioid-induced sexual dysfunction',
  'F11982 Opioid use, unspecified with opioid-induced sleep disorder',
  'F11988 Opioid use, unspecified with other opioid-induced disorder',
  'F1199 Opioid use, unspecified with unspecified opioid-induced disorder',
  'F1210 Cannabis abuse, uncomplicated',
  'F12120 Cannabis abuse with intoxication, uncomplicated',
  'F12121 Cannabis abuse with intoxication delirium',
  'F12122 Cannabis abuse with intoxication with perceptual disturbance',
  'F12129 Cannabis abuse with intoxication, unspecified',
  'F12150 Cannabis abuse with psychotic disorder with delusions',
  'F12151 Cannabis abuse with psychotic disorder with hallucinations',
  'F12159 Cannabis abuse with psychotic disorder, unspecified',
  'F12180 Cannabis abuse with cannabis-induced anxiety disorder',
  'F12188 Cannabis abuse with other cannabis-induced disorder',
  'F1219 Cannabis abuse with unspecified cannabis-induced disorder',
  'F1220 Cannabis dependence, uncomplicated',
  'F1221 Cannabis dependence, in remission',
  'F12220 Cannabis dependence with intoxication, uncomplicated',
  'F12221 Cannabis dependence with intoxication delirium',
  'F12222 Cannabis dependence with intoxication with perceptual disturbance',
  'F12229 Cannabis dependence with intoxication, unspecified',
  'F12250 Cannabis dependence with psychotic disorder with delusions',
  'F12251 Cannabis dependence with psychotic disorder with hallucinations',
  'F12259 Cannabis dependence with psychotic disorder, unspecified',
  'F12280 Cannabis dependence with cannabis-induced anxiety disorder',
  'F12288 Cannabis dependence with other cannabis-induced disorder',
  'F1229 Cannabis dependence with unspecified cannabis-induced disorder',
  'F1290 Cannabis use, unspecified, uncomplicated',
  'F12920 Cannabis use, unspecified with intoxication, uncomplicated',
  'F12921 Cannabis use, unspecified with intoxication delirium',
  'F12922 Cannabis use, unspecified with intoxication with perceptual disturbance',
  'F12929 Cannabis use, unspecified with intoxication, unspecified',
  'F12950 Cannabis use, unspecified with psychotic disorder with delusions',
  'F12951 Cannabis use, unspecified with psychotic disorder with hallucinations',
  'F12959 Cannabis use, unspecified with psychotic disorder, unspecified',
  'F12980 Cannabis use, unspecified with anxiety disorder',
  'F12988 Cannabis use, unspecified with other cannabis-induced disorder',
  'F1299 Cannabis use, unspecified with unspecified cannabis-induced disorder',
  'F1310 Sedative, hypnotic or anxiolytic abuse, uncomplicated',
  'F13120 Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated',
  'F13121 Sedative, hypnotic or anxiolytic abuse with intoxication delirium',
  'F13129 Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified',
  'F1314 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder',
  'F13150 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
  'F13151 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
  'F13159 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
  'F13180 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder',
  'F13181 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
  'F13182 Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder',
  'F13188 Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder',
  'F1319 Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder',
  'F1320 Sedative, hypnotic or anxiolytic dependence, uncomplicated',
  'F1321 Sedative, hypnotic or anxiolytic dependence, in remission',
  'F13220 Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated',
  'F13221 Sedative, hypnotic or anxiolytic dependence with intoxication delirium',
  'F13229 Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified',
  'F13230 Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated',
  'F13231 Sedative, hypnotic or anxiolytic dependence with withdrawal delirium',
  'F13232 Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance',
  'F13239 Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified',
  'F1324 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder',
  'F13250 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
  'F13251 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
  'F13259 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
  'F1326 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder',
  'F1327 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia',
  'F13280 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder',
  'F13281 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
  'F13282 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder',
  'F13288 Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder',
  'F1329 Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolyticinduced disorder',
  'F1390 Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated',
  'F13920 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated',
  'F13921 Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium',
  'F13929 Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified',
  'F13930 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated',
  'F13931 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium',
  'F13932 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances',
  'F13939 Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified',
  'F1394 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder',
  'F13950 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
  'F13951 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
  'F13959 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
  'F1396 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder',
  'F1397 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia',
  'F13980 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder',
  'F13981 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
  'F13982 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder',
  'F13988 Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolyticinduced disorder',
  'F1399 Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder',
  'F1410 Cocaine abuse, uncomplicated',
  'F14120 Cocaine abuse with intoxication, uncomplicated',
  'F14121 Cocaine abuse with intoxication with delirium',
  'F14122 Cocaine abuse with intoxication with perceptual disturbance',
  'F14129 Cocaine abuse with intoxication, unspecified',
  'F1414 Cocaine abuse with cocaine-induced mood disorder',
  'F14150 Cocaine abuse with cocaine-induced psychotic disorder with delusions',
  'F14151 Cocaine abuse with cocaine-induced psychotic disorder with hallucinations',
  'F14159 Cocaine abuse with cocaine-induced psychotic disorder, unspecified',
  'F14180 Cocaine abuse with cocaine-induced anxiety disorder',
  'F14181 Cocaine abuse with cocaine-induced sexual dysfunction',
  'F14182 Cocaine abuse with cocaine-induced sleep disorder',
  'F14188 Cocaine abuse with other cocaine-induced disorder',
  'F1419 Cocaine abuse with unspecified cocaine-induced disorder',
  'F1420 Cocaine dependence, uncomplicated',
  'F1421 Cocaine dependence, in remission',
  'F14220 Cocaine dependence with intoxication, uncomplicated',
  'F14221 Cocaine dependence with intoxication delirium',
  'F14222 Cocaine dependence with intoxication with perceptual disturbance',
  'F14229 Cocaine dependence with intoxication, unspecified',
  'F1423 Cocaine dependence with withdrawal',
  'F1424 Cocaine dependence with cocaine-induced mood disorder',
  'F14250 Cocaine dependence with cocaine-induced psychotic disorder with delusions',
  'F14251 Cocaine dependence with cocaine-induced psychotic disorder with hallucinations',
  'F14259 Cocaine dependence with cocaine-induced psychotic disorder, unspecified',
  'F14280 Cocaine dependence with cocaine-induced anxiety disorder',
  'F14281 Cocaine dependence with cocaine-induced sexual dysfunction',
  'F14282 Cocaine dependence with cocaine-induced sleep disorder',
  'F14288 Cocaine dependence with other cocaine-induced disorder',
  'F1429 Cocaine dependence with unspecified cocaine-induced disorder',
  'F1490 Cocaine use, unspecified, uncomplicated',
  'F14920 Cocaine use, unspecified with intoxication, uncomplicated',
  'F14921 Cocaine use, unspecified with intoxication delirium',
  'F14922 Cocaine use, unspecified with intoxication with perceptual disturbance',
  'F14929 Cocaine use, unspecified with intoxication, unspecified',
  'F1494 Cocaine use, unspecified with cocaine-induced mood disorder',
  'F14950 Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions',
  'F14951 Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations',
  'F14959 Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified',
  'F14980 Cocaine use, unspecified with cocaine-induced anxiety disorder',
  'F14981 Cocaine use, unspecified with cocaine-induced sexual dysfunction',
  'F14982 Cocaine use, unspecified with cocaine-induced sleep disorder',
  'F14988 Cocaine use, unspecified with other cocaine-induced disorder',
  'F1499 Cocaine use, unspecified with unspecified cocaine-induced disorder',
  'F1510 Other stimulant abuse, uncomplicated',
  'F15120 Other stimulant abuse with intoxication, uncomplicated',
  'F15121 Other stimulant abuse with intoxication delirium',
  'F15122 Other stimulant abuse with intoxication with perceptual disturbance',
  'F15129 Other stimulant abuse with intoxication, unspecified',
  'F1514 Other stimulant abuse with stimulant-induced mood disorder',
  'F15150 Other stimulant abuse with stimulant-induced psychotic disorder with delusions',
  'F15151 Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations',
  'F15159 Other stimulant abuse with stimulant-induced psychotic disorder, unspecified',
  'F15180 Other stimulant abuse with stimulant-induced anxiety disorder',
  'F15181 Other stimulant abuse with stimulant-induced sexual dysfunction',
  'F15182 Other stimulant abuse with stimulant-induced sleep disorder',
  'F15188 Other stimulant abuse with other stimulant-induced disorder',
  'F1519 Other stimulant abuse with unspecified stimulant-induced disorder',
  'F1520 Other stimulant dependence, uncomplicated',
  'F1521 Other stimulant dependence, in remission',
  'F15220 Other stimulant dependence with intoxication, uncomplicated',
  'F15221 Other stimulant dependence with intoxication delirium',
  'F15222 Other stimulant dependence with intoxication with perceptual disturbance',
  'F15229 Other stimulant dependence with intoxication, unspecified',
  'F1523 Other stimulant dependence with withdrawal',
  'F1524 Other stimulant dependence with stimulant-induced mood disorder',
  'F15250 Other stimulant dependence with stimulant-induced psychotic disorder with delusions',
  'F15251 Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations',
  'F15259 Other stimulant dependence with stimulant-induced psychotic disorder, unspecified',
  'F15280 Other stimulant dependence with stimulant-induced anxiety disorder',
  'F15281 Other stimulant dependence with stimulant-induced sexual dysfunction',
  'F15282 Other stimulant dependence with stimulant-induced sleep disorder',
  'F15288 Other stimulant dependence with other stimulant-induced disorder',
  'F1529 Other stimulant dependence with unspecified stimulant-induced disorder',
  'F1590 Other stimulant use, unspecified, uncomplicated',
  'F15920 Other stimulant use, unspecified with intoxication, uncomplicated',
  'F15921 Other stimulant use, unspecified with intoxication delirium',
  'F15922 Other stimulant use, unspecified with intoxication with perceptual disturbance',
  'F15929 Other stimulant use, unspecified with intoxication, unspecified',
  'F1593 Other stimulant use, unspecified with withdrawal',
  'F1594 Other stimulant use, unspecified with stimulant-induced mood disorder',
  'F15950 Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions',
  'F15951 Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations',
  'F15959 Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified',
  'F15980 Other stimulant use, unspecified with stimulant-induced anxiety disorder',
  'F15981 Other stimulant use, unspecified with stimulant-induced sexual dysfunction',
  'F15982 Other stimulant use, unspecified with stimulant-induced sleep disorder',
  'F15988 Other stimulant use, unspecified with other stimulant-induced disorder',
  'F1599 Other stimulant use, unspecified with unspecified stimulant-induced disorder',
  'F1610 Hallucinogen abuse, uncomplicated',
  'F16120 Hallucinogen abuse with intoxication, uncomplicated',
  'F16121 Hallucinogen abuse with intoxication with delirium',
  'F16122 Hallucinogen abuse with intoxication with perceptual disturbance',
  'F16129 Hallucinogen abuse with intoxication, unspecified',
  'F1614 Hallucinogen abuse with hallucinogen-induced mood disorder',
  'F16150 Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions',
  'F16151 Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations',
  'F16159 Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified',
  'F16180 Hallucinogen abuse with hallucinogen-induced anxiety disorder',
  'F16183 Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)',
  'F16188 Hallucinogen abuse with other hallucinogen-induced disorder',
  'F1619 Hallucinogen abuse with unspecified hallucinogen-induced disorder',
  'F1620 Hallucinogen dependence, uncomplicated',
  'F1621 Hallucinogen dependence, in remission',
  'F16220 Hallucinogen dependence with intoxication, uncomplicated',
  'F16221 Hallucinogen dependence with intoxication with delirium',
  'F16229 Hallucinogen dependence with intoxication, unspecified',
  'F1624 Hallucinogen dependence with hallucinogen-induced mood disorder',
  'F16250 Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions',
  'F16251 Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations',
  'F16259 Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified',
  'F16280 Hallucinogen dependence with hallucinogen-induced anxiety disorder',
  'F16283 Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)',
  'F16288 Hallucinogen dependence with other hallucinogen-induced disorder',
  'F1629 Hallucinogen dependence with unspecified hallucinogen-induced disorder',
  'F1690 Hallucinogen use, unspecified, uncomplicated',
  'F16920 Hallucinogen use, unspecified with intoxication, uncomplicated',
  'F16921 Hallucinogen use, unspecified with intoxication with delirium',
  'F16929 Hallucinogen use, unspecified with intoxication, unspecified',
  'F1694 Hallucinogen use, unspecified with hallucinogen-induced mood disorder',
  'F16950 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions',
  'F16951 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations',
  'F16959 Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified',
  'F16980 Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder',
  'F16983 Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)',
  'F16988 Hallucinogen use, unspecified with other hallucinogen-induced disorder',
  'F1699 Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder',
  'F17200 Nicotine dependence, unspecified, uncomplicated',
  'F17201 Nicotine dependence, unspecified, in remission',
  'F17203 Nicotine dependence unspecified, with withdrawal',
  'F17208 Nicotine dependence, unspecified, with other nicotine-induced disorders',
  'F17209 Nicotine dependence, unspecified, with unspecified nicotine-induced disorders',
  'F17210 Nicotine dependence, cigarettes, uncomplicated',
  'F17211 Nicotine dependence, cigarettes, in remission',
  'F17213 Nicotine dependence, cigarettes, with withdrawal',
  'F17218 Nicotine dependence, cigarettes, with other nicotine-induced disorders',
  'F17219 Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders',
  'F17220 Nicotine dependence, chewing tobacco, uncomplicated',
  'F17221 Nicotine dependence, chewing tobacco, in remission',
  'F17223 Nicotine dependence, chewing tobacco, with withdrawal',
  'F17228 Nicotine dependence, chewing tobacco, with other nicotine-induced disorders',
  'F17229 Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders',
  'F17290 Nicotine dependence, other tobacco product, uncomplicated',
  'F17291 Nicotine dependence, other tobacco product, in remission',
  'F17293 Nicotine dependence, other tobacco product, with withdrawal',
  'F17298 Nicotine dependence, other tobacco product, with other nicotine-induced disorders',
  'F17299 Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders',
  'F1810 Inhalant abuse, uncomplicated',
  'F18120 Inhalant abuse with intoxication, uncomplicated',
  'F18121 Inhalant abuse with intoxication delirium',
  'F18129 Inhalant abuse with intoxication, unspecified',
  'F1814 Inhalant abuse with inhalant-induced mood disorder',
  'F18150 Inhalant abuse with inhalant-induced psychotic disorder with delusions',
  'F18151 Inhalant abuse with inhalant-induced psychotic disorder with hallucinations',
  'F18159 Inhalant abuse with inhalant-induced psychotic disorder, unspecified',
  'F1817 Inhalant abuse with inhalant-induced dementia',
  'F18180 Inhalant abuse with inhalant-induced anxiety disorder',
  'F18188 Inhalant abuse with other inhalant-induced disorder',
  'F1819 Inhalant abuse with unspecified inhalant-induced disorder',
  'F1820 Inhalant dependence, uncomplicated',
  'F1821 Inhalant dependence, in remission',
  'F18220 Inhalant dependence with intoxication, uncomplicated',
  'F18221 Inhalant dependence with intoxication delirium',
  'F18229 Inhalant dependence with intoxication, unspecified',
  'F1824 Inhalant dependence with inhalant-induced mood disorder',
  'F18250 Inhalant dependence with inhalant-induced psychotic disorder with delusions',
  'F18251 Inhalant dependence with inhalant-induced psychotic disorder with hallucinations',
  'F18259 Inhalant dependence with inhalant-induced psychotic disorder, unspecified',
  'F1827 Inhalant dependence with inhalant-induced dementia',
  'F18280 Inhalant dependence with inhalant-induced anxiety disorder',
  'F18288 Inhalant dependence with other inhalant-induced disorder',
  'F1829 Inhalant dependence with unspecified inhalant-induced disorder',
  'F1890 Inhalant use, unspecified, uncomplicated',
  'F18920 Inhalant use, unspecified with intoxication, uncomplicated',
  'F18921 Inhalant use, unspecified with intoxication with delirium',
  'F18929 Inhalant use, unspecified with intoxication, unspecified',
  'F1894 Inhalant use, unspecified with inhalant-induced mood disorder',
  'F18950 Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions',
  'F18951 Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations',
  'F18959 Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified',
  'F1897 Inhalant use, unspecified with inhalant-induced persisting dementia',
  'F18980 Inhalant use, unspecified with inhalant-induced anxiety disorder',
  'F18988 Inhalant use, unspecified with other inhalant-induced disorder',
  'F1899 Inhalant use, unspecified with unspecified inhalant-induced disorder',
  'F1910 Other psychoactive substance abuse, uncomplicated',
  'F19120 Other psychoactive substance abuse with intoxication, uncomplicated',
  'F19121 Other psychoactive substance abuse with intoxication delirium',
  'F19122 Other psychoactive substance abuse with intoxication with perceptual disturbances',
  'F19129 Other psychoactive substance abuse with intoxication, unspecified',
  'F1914 Other psychoactive substance abuse with psychoactive substance-induced mood disorder',
  'F19150 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions',
  'F19151 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations',
  'F19159 Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder unspecified',
  'F1916 Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder',
  'F1917 Other psychoactive substance abuse with psychoactive substance-induced persisting dementia',
  'F19180 Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder',
  'F19181 Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction',
  'F19182 Other psychoactive substance abuse with psychoactive substance-induced sleep disorder',
  'F19188 Other psychoactive substance abuse with other psychoactive substance-induced disorder',
  'F1919 Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder',
  'F1920 Other psychoactive substance dependence, uncomplicated',
  'F1921 Other psychoactive substance dependence, in remission',
  'F19220 Other psychoactive substance dependence with intoxication, uncomplicated',
  'F19221 Other psychoactive substance dependence with intoxication delirium',
  'F19222 Other psychoactive substance dependence with intoxication with perceptual disturbance',
  'F19229 Other psychoactive substance dependence with intoxication, unspecified',
  'F19230 Other psychoactive substance dependence with withdrawal, uncomplicated',
  'F19231 Other psychoactive substance dependence with withdrawal delirium',
  'F19232 Other psychoactive substance dependence with withdrawal with perceptual disturbance',
  'F19239 Other psychoactive substance dependence with withdrawal, unspecified',
  'F1924 Other psychoactive substance dependence with psychoactive substance-induced mood disorder',
  'F19250 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions',
  'F19251 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations',
  'F19259 Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified',
  'F1926 Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder',
  'F1927 Other psychoactive substance dependence with psychoactive substance-induced persisting dementia',
  'F19280 Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder',
  'F19281 Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction',
  'F19282 Other psychoactive substance dependence with psychoactive substance-induced sleep disorder',
  'F19288 Other psychoactive substance dependence with other psychoactive substance-induced disorder',
  'F1929 Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder',
  'F1990 Other psychoactive substance use, unspecified, uncomplicated',
  'F19920 Other psychoactive substance use, unspecified with intoxication, uncomplicated',
  'F19921 Other psychoactive substance use, unspecified with intoxication with delirium',
  'F19922 Other psychoactive substance use, unspecified with intoxication with perceptual disturbance',
  'F19929 Other psychoactive substance use, unspecified with intoxication, unspecified',
  'F19930 Other psychoactive substance use, unspecified with withdrawal, uncomplicated',
  'F19931 Other psychoactive substance use, unspecified with withdrawal delirium',
  'F19932 Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance',
  'F19939 Other psychoactive substance use, unspecified with withdrawal, unspecified',
  'F1994 Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder',
  'F19950 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions',
  'F19951 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations',
  'F19959 Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified',
  'F1996 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder',
  'F1997 Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia',
  'F19980 Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder',
  'F19981 Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction',
  'F19982 Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder',
  'F19988 Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder',
  'F1999 Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder',
  'F200 Paranoid schizophrenia',
  'F201 Disorganized schizophrenia',
  'F202 Catatonic schizophrenia',
  'F203 Undifferentiated schizophrenia',
  'F205 Residual schizophrenia',
  'F2081 Schizophreniform disorder',
  'F2089 Other schizophrenia',
  'F209 Schizophrenia, unspecified',
  'F21 Schizotypal disorder',
  'F22 Delusional disorders',
  'F23 Brief psychotic disorder',
  'F24 Shared psychotic disorder',
  'F250 Schizoaffective disorder, bipolar type',
  'F251 Schizoaffective disorder, depressive type',
  'F258 Other schizoaffective disorders',
  'F259 Schizoaffective disorder, unspecified',
  'F28 Other psychotic disorder not due to a substance or known physiological condition',
  'F29 Unspecified psychosis not due to a substance or known physiological condition',
  'F3010 Manic episode without psychotic symptoms, unspecified',
  'F3011 Manic episode without psychotic symptoms, mild',
  'F3012 Manic episode without psychotic symptoms, moderate',
  'F3013 Manic episode, severe, without psychotic symptoms',
  'F302 Manic episode, severe with psychotic symptoms',
  'F303 Manic episode in partial remission',
  'F304 Manic episode in full remission',
  'F308 Other manic episodes',
  'F309 Manic episode, unspecified',
  'F310 Bipolar disorder, current episode hypomanic',
  'F3110 Bipolar disorder, current episode manic without psychotic features, unspecified',
  'F3111 Bipolar disorder, current episode manic without psychotic features, mild',
  'F3112 Bipolar disorder, current episode manic without psychotic features, moderate',
  'F3113 Bipolar disorder, current episode manic without psychotic features, severe',
  'F312 Bipolar disorder, current episode manic severe with psychotic features',
  'F3130 Bipolar disorder, current episode depressed, mild or moderate severity, unspecified',
  'F3131 Bipolar disorder, current episode depressed, mild',
  'F3132 Bipolar disorder, current episode depressed, moderate',
  'F314 Bipolar disorder, current episode depressed, severe, without psychotic features',
  'F315 Bipolar disorder, current episode depressed, severe, with psychotic features',
  'F3160 Bipolar disorder, current episode mixed, unspecified',
  'F3161 Bipolar disorder, current episode mixed, mild',
  'F3162 Bipolar disorder, current episode mixed, moderate',
  'F3163 Bipolar disorder, current episode mixed, severe, without psychotic features',
  'F3164 Bipolar disorder, current episode mixed, severe, with psychotic features',
  'F3170 Bipolar disorder, currently in remission, most recent episode unspecified',
  'F3171 Bipolar disorder, in partial remission, most recent episode hypomanic',
  'F3172 Bipolar disorder, in full remission, most recent episode hypomanic',
  'F3173 Bipolar disorder, in partial remission, most recent episode manic',
  'F3174 Bipolar disorder, in full remission, most recent episode manic',
  'F3175 Bipolar disorder, in partial remission, most recent episode depressed',
  'F3176 Bipolar disorder, in full remission, most recent episode depressed',
  'F3177 Bipolar disorder, in partial remission, most recent episode mixed',
  'F3178 Bipolar disorder, in full remission, most recent episode mixed',
  'F3181 Bipolar II disorder',
  'F3189 Other bipolar disorder',
  'F319 Bipolar disorder, unspecified',
  'F320 Major depressive disorder, single episode, mild',
  'F321 Major depressive disorder, single episode, moderate',
  'F322 Major depressive disorder, single episode, severe without psychotic features',
  'F323 Major depressive disorder, single episode, severe with psychotic features',
  'F324 Major depressive disorder, single episode, in partial remission',
  'F325 Major depressive disorder, single episode, in full remission',
  'F3281 Premenstrual dysphoric disorder',
  'F3289 Other specified depressive episodes',
  'F329 Major depressive disorder, single episode, unspecified',
  'F330 Major depressive disorder, recurrent, mild',
  'F331 Major depressive disorder, recurrent, moderate',
  'F332 Major depressive disorder, recurrent severe without psychotic features',
  'F333 Major depressive disorder, recurrent, severe with psychotic symptoms',
  'F3340 Major depressive disorder, recurrent, in remission, unspecified',
  'F3341 Major depressive disorder, recurrent, in partial remission',
  'F3342 Major depressive disorder, recurrent, in full remission',
  'F338 Other recurrent depressive disorders',
  'F339 Major depressive disorder, recurrent, unspecified',
  'F340 Cyclothymic disorder',
  'F341 Dysthymic disorder',
  'F3481 Disruptive mood dysregulation disorder',
  'F3489 Other specified persistent mood disorders',
  'F349 Persistent mood [affective] disorder, unspecified',
  'F39 Unspecified mood [affective] disorder',
  'F4000 Agoraphobia, unspecified',
  'F4001 Agoraphobia with panic disorder',
  'F4002 Agoraphobia without panic disorder',
  'F4010 Social phobia, unspecified',
  'F4011 Social phobia, generalized',
  'F40210 Arachnophobia',
  'F40218 Other animal type phobia',
  'F40220 Fear of thunderstorms',
  'F40228 Other natural environment type phobia',
  'F40230 Fear of blood',
  'F40231 Fear of injections and transfusions',
  'F40232 Fear of other medical care',
  'F40233 Fear of injury',
  'F40240 Claustrophobia',
  'F40241 Acrophobia',
  'F40242 Fear of bridges',
  'F40243 Fear of flying',
  'F40248 Other situational type phobia',
  'F40290 Androphobia',
  'F40291 Gynephobia',
  'F40298 Other specified phobia',
  'F408 Other phobic anxiety disorders',
  'F409 Phobic anxiety disorder, unspecified',
  'F410 Panic disorder [episodic paroxysmal anxiety] without agoraphobia',
  'F411 Generalized anxiety disorder',
  'F413 Other mixed anxiety disorders',
  'F418 Other specified anxiety disorders',
  'F419 Anxiety disorder, unspecified',
  'F422 Mixed obsessional thoughts and acts',
  'F423 Hoarding disorder',
  'F424 Excoriation (skin-picking) disorder',
  'F428 Other obsessive-compulsive disorder',
  'F429 Obsessive-compulsive disorder, unspecified',
  'F430 Acute stress reaction',
  'F4310 Post-traumatic stress disorder, unspecified',
  'F4311 Post-traumatic stress disorder, acute',
  'F4312 Post-traumatic stress disorder, chronic',
  'F4320 Adjustment disorder, unspecified',
  'F4321 Adjustment disorder with depressed mood',
  'F4322 Adjustment disorder with anxiety',
  'F4323 Adjustment disorder with mixed anxiety and depressed mood',
  'F4324 Adjustment disorder with disturbance of conduct',
  'F4325 Adjustment disorder with mixed disturbance of emotions and conduct',
  'F4329 Adjustment disorder with other symptoms',
  'F438 Other reactions to severe stress',
  'F439 Reaction to severe stress, unspecified',
  'F440 Dissociative amnesia',
  'F441 Dissociative fugue',
  'F442 Dissociative stupor',
  'F444 Conversion disorder with motor symptom or deficit',
  'F445 Conversion disorder with seizures or convulsions',
  'F446 Conversion disorder with sensory symptom or deficit',
  'F447 Conversion disorder with mixed symptom presentation',
  'F4481 Dissociative identity disorder',
  'F4489 Other dissociative and conversion disorders',
  'F449 Dissociative and conversion disorder, unspecified',
  'F450 Somatization disorder',
  'F451 Undifferentiated somatoform disorder',
  'F4520 Hypochondriacal disorder, unspecified',
  'F4521 Hypochondriasis',
  'F4522 Body dysmorphic disorder',
  'F4529 Other hypochondriacal disorders',
  'F4541 Pain disorder exclusively related to psychological factors',
  'F4542 Pain disorder with related psychological factors',
  'F458 Other somatoform disorders',
  'F459 Somatoform disorder, unspecified',
  'F481 Depersonalization-derealization syndrome',
  'F482 Pseudobulbar affect',
  'F488 Other specified nonpsychotic mental disorders',
  'F489 Nonpsychotic mental disorder, unspecified',
  'F5000 Anorexia nervosa, unspecified',
  'F5001 Anorexia nervosa, restricting type',
  'F5002 Anorexia nervosa, binge eating/purging type',
  'F502 Bulimia nervosa',
  'F5081 Binge eating disorder',
  'F5089 Other specified eating disorder',
  'F509 Eating disorder, unspecified',
  'F5101 Primary insomnia',
  'F5102 Adjustment insomnia',
  'F5103 Paradoxical insomnia',
  'F5104 Psychophysiologic insomnia',
  'F5105 Insomnia due to other mental disorder',
  'F5109 Other insomnia not due to a substance or known physiological condition',
  'F5111 Primary hypersomnia',
  'F5112 Insufficient sleep syndrome',
  'F5113 Hypersomnia due to other mental disorder',
  'F5119 Other hypersomnia not due to a substance or known physiological condition',
  'F513 Sleepwalking [somnambulism]',
  'F514 Sleep terrors [night terrors]',
  'F515 Nightmare disorder',
  'F518 Other sleep disorders not due to a substance or known physiological condition',
  'F519 Sleep disorder not due to a substance or known physiological condition, unspecified',
  'F520 Hypoactive sexual desire disorder',
  'F521 Sexual aversion disorder',
  'F5221 Male erectile disorder',
  'F5222 Female sexual arousal disorder',
  'F5231 Female orgasmic disorder',
  'F5232 Male orgasmic disorder',
  'F524 Premature ejaculation',
  'F525 Vaginismus not due to a substance or known physiological condition',
  'F526 Dyspareunia not due to a substance or known physiological condition',
  'F528 Other sexual dysfunction not due to a substance or known physiological condition',
  'F529 Unspecified sexual dysfunction not due to a substance or known physiological condition',
  'F53 Puerperal psychosis',
  'F54 Psychological and behavioral factors associated with disorders or diseases classified elsewhere',
  'F550 Abuse of antacids',
  'F551 Abuse of herbal or folk remedies',
  'F552 Abuse of laxatives',
  'F553 Abuse of steroids or hormones',
  'F554 Abuse of vitamins',
  'F558 Abuse of other non-psychoactive substances',
  'F59 Unspecified behavioral syndromes associated with physiological disturbances and physical factors',
  'F600 Paranoid personality disorder',
  'F601 Schizoid personality disorder',
  'F602 Antisocial personality disorder',
  'F603 Borderline personality disorder',
  'F604 Histrionic personality disorder',
  'F605 Obsessive-compulsive personality disorder',
  'F606 Avoidant personality disorder',
  'F607 Dependent personality disorder',
  'F6081 Narcissistic personality disorder',
  'F6089 Other specific personality disorders',
  'F609 Personality disorder, unspecified',
  'F630 Pathological gambling',
  'F631 Pyromania',
  'F632 Kleptomania',
  'F633 Trichotillomania',
  'F6381 Intermittent explosive disorder',
  'F6389 Other impulse disorders',
  'F639 Impulse disorder, unspecified',
  'F640 Transsexualism',
  'F641 Dual role transvestism',
  'F642 Gender identity disorder of childhood',
  'F648 Other gender identity disorders',
  'F649 Gender identity disorder, unspecified',
  'F650 Fetishism',
  'F651 Transvestic fetishism',
  'F652 Exhibitionism',
  'F653 Voyeurism',
  'F654 Pedophilia',
  'F6550 Sadomasochism, unspecified',
  'F6551 Sexual masochism',
  'F6552 Sexual sadism',
  'F6581 Frotteurism',
  'F6589 Other paraphilias',
  'F659 Paraphilia, unspecified',
  'F66 Other sexual disorders',
  'F6810 Factitious disorder, unspecified',
  'F6811 Factitious disorder with predominantly psychological signs and symptoms',
  'F6812 Factitious disorder with predominantly physical signs and symptoms',
  'F6813 Factitious disorder with combined psychological and physical signs and symptoms',
  'F688 Other specified disorders of adult personality and behavior',
  'F69 Unspecified disorder of adult personality and behavior',
  'F70 Mild intellectual disabilities',
  'F71 Moderate intellectual disabilities',
  'F72 Severe intellectual disabilities',
  'F73 Profound intellectual disabilities',
  'F78 Other intellectual disabilities',
  'F79 Unspecified intellectual disabilities',
  'F800 Phonological disorder',
  'F801 Expressive language disorder',
  'F802 Mixed receptive-expressive language disorder',
  'F804 Speech and language development delay due to hearing loss',
  'F8081 Childhood onset fluency disorder',
  'F8082 Social pragmatic communication disorder',
  'F8089 Other developmental disorders of speech and language',
  'F809 Developmental disorder of speech and language, unspecified',
  'F810 Specific reading disorder',
  'F812 Mathematics disorder',
  'F8181 Disorder of written expression',
  'F8189 Other developmental disorders of scholastic skills',
  'F819 Developmental disorder of scholastic skills, unspecified',
  'F82 Specific developmental disorder of motor function',
  'F840 Autistic disorder',
  "F842 Rett's syndrome",
  'F843 Other childhood disintegrative disorder',
  "F845 Asperger's syndrome",
  'F848 Other pervasive developmental disorders',
  'F849 Pervasive developmental disorder, unspecified',
  'F88 Other disorders of psychological development',
  'F89 Unspecified disorder of psychological development',
  'F900 Attention-deficit hyperactivity disorder, predominantly inattentive type',
  'F901 Attention-deficit hyperactivity disorder, predominantly hyperactive type',
  'F902 Attention-deficit hyperactivity disorder, combined type',
  'F908 Attention-deficit hyperactivity disorder, other type',
  'F909 Attention-deficit hyperactivity disorder, unspecified type',
  'F910 Conduct disorder confined to family context',
  'F911 Conduct disorder, childhood-onset type',
  'F912 Conduct disorder, adolescent-onset type',
  'F913 Oppositional defiant disorder',
  'F918 Other conduct disorders',
  'F919 Conduct disorder, unspecified',
  'F930 Separation anxiety disorder of childhood',
  'F938 Other childhood emotional disorders',
  'F939 Childhood emotional disorder, unspecified',
  'F940 Selective mutism',
  'F941 Reactive attachment disorder of childhood',
  'F942 Disinhibited attachment disorder of childhood',
  'F948 Other childhood disorders of social functioning',
  'F949 Childhood disorder of social functioning, unspecified',
  'F950 Transient tic disorder',
  'F951 Chronic motor or vocal tic disorder',
  "F952 Tourette's disorder",
  'F958 Other tic disorders',
  'F959 Tic disorder, unspecified',
  'F980 Enuresis not due to a substance or known physiological condition',
  'F981 Encopresis not due to a substance or known physiological condition',
  'F9821 Rumination disorder of infancy',
  'F9829 Other feeding disorders of infancy and early childhood',
  'F983 Pica of infancy and childhood',
  'F984 Stereotyped movement disorders',
  'F985 Adult onset fluency disorder',
  'F988 Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
  'F989 Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
  'F99 Mental disorder, not otherwise specified',
]

export const OtherDiagnosisTypes = [
  'A000 Cholera due to Vibrio cholerae 01, biovar cholerae',
  'A001 Cholera due to Vibrio cholerae 01, biovar eltor',
  'A009 Cholera, unspecified',
  'A0100 Typhoid fever, unspecified',
  'A0101 Typhoid meningitis',
  'A0102 Typhoid fever with heart involvement',
  'A0103 Typhoid pneumonia',
  'A0104 Typhoid arthritis',
  'A0105 Typhoid osteomyelitis',
  'A0109 Typhoid fever with other complications',
  'A011 Paratyphoid fever A',
  'A012 Paratyphoid fever B',
  'A013 Paratyphoid fever C',
  'A014 Paratyphoid fever, unspecified',
  'A020 Salmonella enteritis',
  'A021 Salmonella sepsis',
  'A0220 Localized salmonella infection, unspecified',
  'A0221 Salmonella meningitis',
  'A0222 Salmonella pneumonia',
  'A0223 Salmonella arthritis',
  'A0224 Salmonella osteomyelitis',
  'A0225 Salmonella pyelonephritis',
  'A0229 Salmonella with other localized infection',
  'A028 Other specified salmonella infections',
  'A029 Salmonella infection, unspecified',
  'A030 Shigellosis due to Shigella dysenteriae',
  'A031 Shigellosis due to Shigella flexneri',
  'A032 Shigellosis due to Shigella boydii',
  'A033 Shigellosis due to Shigella sonnei',
  'A038 Other shigellosis',
  'A039 Shigellosis, unspecified',
  'A040 Enteropathogenic Escherichia coli infection',
  'A041 Enterotoxigenic Escherichia coli infection',
  'A042 Enteroinvasive Escherichia coli infection',
  'A043 Enterohemorrhagic Escherichia coli infection',
  'A044 Other intestinal Escherichia coli infections',
  'A045 Campylobacter enteritis',
  'A046 Enteritis due to Yersinia enterocolitica',
  'A047 Enterocolitis due to Clostridium difficile',
  'A048 Other specified bacterial intestinal infections',
  'A049 Bacterial intestinal infection, unspecified',
  'A050 Foodborne staphylococcal intoxication',
  'A051 Botulism food poisoning',
  'A052 Foodborne Clostridium perfringens [Clostridium welchii] intoxication',
  'A053 Foodborne Vibrio parahaemolyticus intoxication',
  'A054 Foodborne Bacillus cereus intoxication',
  'A055 Foodborne Vibrio vulnificus intoxication',
  'A058 Other specified bacterial foodborne intoxications',
  'A059 Bacterial foodborne intoxication, unspecified',
  'A060 Acute amebic dysentery',
  'A061 Chronic intestinal amebiasis',
  'A062 Amebic nondysenteric colitis',
  'A063 Ameboma of intestine',
  'A064 Amebic liver abscess',
  'A065 Amebic lung abscess',
  'A066 Amebic brain abscess',
  'A067 Cutaneous amebiasis',
  'A0681 Amebic cystitis',
  'A0682 Other amebic genitourinary infections',
  'A0689 Other amebic infections',
  'A069 Amebiasis, unspecified',
  'A070 Balantidiasis',
  'A071 Giardiasis [lambliasis]',
  'A072 Cryptosporidiosis',
  'A073 Isosporiasis',
  'A074 Cyclosporiasis',
  'A078 Other specified protozoal intestinal diseases',
  'A079 Protozoal intestinal disease, unspecified',
  'A080 Rotaviral enteritis',
  'A0811 Acute gastroenteropathy due to Norwalk agent',
  'A0819 Acute gastroenteropathy due to other small round viruses',
  'A082 Adenoviral enteritis',
  'A0831 Calicivirus enteritis',
  'A0832 Astrovirus enteritis',
  'A0839 Other viral enteritis',
  'A084 Viral intestinal infection, unspecified',
  'A088 Other specified intestinal infections',
  'A09 Infectious gastroenteritis and colitis, unspecified',
  'A150 Tuberculosis of lung',
  'A154 Tuberculosis of intrathoracic lymph nodes',
  'A155 Tuberculosis of larynx, trachea and bronchus',
  'A156 Tuberculous pleurisy',
  'A157 Primary respiratory tuberculosis',
  'A158 Other respiratory tuberculosis',
  'A159 Respiratory tuberculosis unspecified',
  'A170 Tuberculous meningitis',
  'A171 Meningeal tuberculoma',
  'A1781 Tuberculoma of brain and spinal cord',
  'A1782 Tuberculous meningoencephalitis',
  'A1783 Tuberculous neuritis',
  'A1789 Other tuberculosis of nervous system',
  'A179 Tuberculosis of nervous system, unspecified',
  'A1801 Tuberculosis of spine',
  'A1802 Tuberculous arthritis of other joints',
  'A1803 Tuberculosis of other bones',
  'A1809 Other musculoskeletal tuberculosis',
  'A1810 Tuberculosis of genitourinary system, unspecified',
  'A1811 Tuberculosis of kidney and ureter',
  'A1812 Tuberculosis of bladder',
  'A1813 Tuberculosis of other urinary organs',
  'A1814 Tuberculosis of prostate',
  'A1815 Tuberculosis of other male genital organs',
  'A1816 Tuberculosis of cervix',
  'A1817 Tuberculous female pelvic inflammatory disease',
  'A1818 Tuberculosis of other female genital organs',
  'A182 Tuberculous peripheral lymphadenopathy',
  'A1831 Tuberculous peritonitis',
  'A1832 Tuberculous enteritis',
  'A1839 Retroperitoneal tuberculosis',
  'A184 Tuberculosis of skin and subcutaneous tissue',
  'A1850 Tuberculosis of eye, unspecified',
  'A1851 Tuberculous episcleritis',
  'A1852 Tuberculous keratitis',
  'A1853 Tuberculous chorioretinitis',
  'A1854 Tuberculous iridocyclitis',
  'A1859 Other tuberculosis of eye',
  'A186 Tuberculosis of (inner) (middle) ear',
  'A187 Tuberculosis of adrenal glands',
  'A1881 Tuberculosis of thyroid gland',
  'A1882 Tuberculosis of other endocrine glands',
  'A1883 Tuberculosis of digestive tract organs, not elsewhere classified',
  'A1884 Tuberculosis of heart',
  'A1885 Tuberculosis of spleen',
  'A1889 Tuberculosis of other sites',
  'A190 Acute miliary tuberculosis of a single specified site',
  'A191 Acute miliary tuberculosis of multiple sites',
  'A192 Acute miliary tuberculosis, unspecified',
  'A198 Other miliary tuberculosis',
  'A199 Miliary tuberculosis, unspecified',
  'A200 Bubonic plague',
  'A201 Cellulocutaneous plague',
  'A202 Pneumonic plague',
  'A203 Plague meningitis',
  'A207 Septicemic plague',
  'A208 Other forms of plague',
  'A209 Plague, unspecified',
  'A210 Ulceroglandular tularemia',
  'A211 Oculoglandular tularemia',
  'A212 Pulmonary tularemia',
  'A213 Gastrointestinal tularemia',
  'A217 Generalized tularemia',
  'A218 Other forms of tularemia',
  'A219 Tularemia, unspecified',
  'A220 Cutaneous anthrax',
  'A221 Pulmonary anthrax',
  'A222 Gastrointestinal anthrax',
  'A227 Anthrax sepsis',
  'A228 Other forms of anthrax',
  'A229 Anthrax, unspecified',
  'A230 Brucellosis due to Brucella melitensis',
  'A231 Brucellosis due to Brucella abortus',
  'A232 Brucellosis due to Brucella suis',
  'A233 Brucellosis due to Brucella canis',
  'A238 Other brucellosis',
  'A239 Brucellosis, unspecified',
  'A240 Glanders',
  'A241 Acute and fulminating melioidosis',
  'A242 Subacute and chronic melioidosis',
  'A243 Other melioidosis',
  'A249 Melioidosis, unspecified',
  'A250 Spirillosis',
  'A251 Streptobacillosis',
  'A259 Rat-bite fever, unspecified',
  'A260 Cutaneous erysipeloid',
  'A267 Erysipelothrix sepsis',
  'A268 Other forms of erysipeloid',
  'A269 Erysipeloid, unspecified',
  'A270 Leptospirosis icterohemorrhagica',
  'A2781 Aseptic meningitis in leptospirosis',
  'A2789 Other forms of leptospirosis',
  'A279 Leptospirosis, unspecified',
  'A280 Pasteurellosis',
  'A281 Cat-scratch disease',
  'A282 Extraintestinal yersiniosis',
  'A288 Other specified zoonotic bacterial diseases, not elsewhere classified',
  'A289 Zoonotic bacterial disease, unspecified',
  'A300 Indeterminate leprosy',
  'A301 Tuberculoid leprosy',
  'A302 Borderline tuberculoid leprosy',
  'A303 Borderline leprosy',
  'A304 Borderline lepromatous leprosy',
  'A305 Lepromatous leprosy',
  'A308 Other forms of leprosy',
  'A309 Leprosy, unspecified',
  'A310 Pulmonary mycobacterial infection',
  'A311 Cutaneous mycobacterial infection',
  'A312 Disseminated mycobacterium avium-intracellulare complex (DMAC)',
  'A318 Other mycobacterial infections',
  'A319 Mycobacterial infection, unspecified',
  'A320 Cutaneous listeriosis',
  'A3211 Listerial meningitis',
  'A3212 Listerial meningoencephalitis',
  'A327 Listerial sepsis',
  'A3281 Oculoglandular listeriosis',
  'A3282 Listerial endocarditis',
  'A3289 Other forms of listeriosis',
  'A329 Listeriosis, unspecified',
  'A33 Tetanus neonatorum',
  'A34 Obstetrical tetanus',
  'A35 Other tetanus',
  'A360 Pharyngeal diphtheria',
  'A361 Nasopharyngeal diphtheria',
  'A362 Laryngeal diphtheria',
  'A363 Cutaneous diphtheria',
  'A3681 Diphtheritic cardiomyopathy',
  'A3682 Diphtheritic radiculomyelitis',
  'A3683 Diphtheritic polyneuritis',
  'A3684 Diphtheritic tubulo-interstitial nephropathy',
  'A3685 Diphtheritic cystitis',
  'A3686 Diphtheritic conjunctivitis',
  'A3689 Other diphtheritic complications',
  'A369 Diphtheria, unspecified',
  'A3700 Whooping cough due to Bordetella pertussis without pneumonia',
  'A3701 Whooping cough due to Bordetella pertussis with pneumonia',
  'A3710 Whooping cough due to Bordetella parapertussis without pneumonia',
  'A3711 Whooping cough due to Bordetella parapertussis with pneumonia',
  'A3780 Whooping cough due to other Bordetella species without pneumonia',
  'A3781 Whooping cough due to other Bordetella species with pneumonia',
  'A3790 Whooping cough, unspecified species without pneumonia',
  'A3791 Whooping cough, unspecified species with pneumonia',
  'A380 Scarlet fever with otitis media',
  'A381 Scarlet fever with myocarditis',
  'A388 Scarlet fever with other complications',
  'A389 Scarlet fever, uncomplicated',
  'A390 Meningococcal meningitis',
  'A391 Waterhouse-Friderichsen syndrome',
  'A392 Acute meningococcemia',
  'A393 Chronic meningococcemia',
  'A394 Meningococcemia, unspecified',
  'A3950 Meningococcal carditis, unspecified',
  'A3951 Meningococcal endocarditis',
  'A3952 Meningococcal myocarditis',
  'A3953 Meningococcal pericarditis',
  'A3981 Meningococcal encephalitis',
  'A3982 Meningococcal retrobulbar neuritis',
  'A3983 Meningococcal arthritis',
  'A3984 Postmeningococcal arthritis',
  'A3989 Other meningococcal infections',
  'A399 Meningococcal infection, unspecified',
  'A400 Sepsis due to streptococcus, group A',
  'A401 Sepsis due to streptococcus, group B',
  'A403 Sepsis due to Streptococcus pneumoniae',
  'A408 Other streptococcal sepsis',
  'A409 Streptococcal sepsis, unspecified',
  'A4101 Sepsis due to Methicillin susceptible Staphylococcus aureus',
  'A4102 Sepsis due to Methicillin resistant Staphylococcus aureus',
  'A411 Sepsis due to other specified staphylococcus',
  'A412 Sepsis due to unspecified staphylococcus',
  'A413 Sepsis due to Hemophilus influenzae',
  'A414 Sepsis due to anaerobes',
  'A4150 Gram-negative sepsis, unspecified',
  'A4151 Sepsis due to Escherichia coli [E. coli]',
  'A4152 Sepsis due to Pseudomonas',
  'A4153 Sepsis due to Serratia',
  'A4159 Other Gram-negative sepsis',
  'A4181 Sepsis due to Enterococcus',
  'A4189 Other specified sepsis',
  'A419 Sepsis, unspecified organism',
  'A420 Pulmonary actinomycosis',
  'A421 Abdominal actinomycosis',
  'A422 Cervicofacial actinomycosis',
  'A427 Actinomycotic sepsis',
  'A4281 Actinomycotic meningitis',
  'A4282 Actinomycotic encephalitis',
  'A4289 Other forms of actinomycosis',
  'A429 Actinomycosis, unspecified',
  'A430 Pulmonary nocardiosis',
  'A431 Cutaneous nocardiosis',
  'A438 Other forms of nocardiosis',
  'A439 Nocardiosis, unspecified',
  'A440 Systemic bartonellosis',
  'A441 Cutaneous and mucocutaneous bartonellosis',
  'A448 Other forms of bartonellosis',
  'A449 Bartonellosis, unspecified',
  'A46 Erysipelas',
  'A480 Gas gangrene',
  "A481 Legionnaires' disease",
  "A482 Nonpneumonic Legionnaires' disease [Pontiac fever]",
  'A483 Toxic shock syndrome',
  'A484 Brazilian purpuric fever',
  'A4851 Infant botulism',
  'A4852 Wound botulism',
  'A488 Other specified bacterial diseases',
  'A4901 Methicillin susceptible Staphylococcus aureus infection, unspecified site',
  'A4902 Methicillin resistant Staphylococcus aureus infection, unspecified site',
  'A491 Streptococcal infection, unspecified site',
  'A492 Hemophilus influenzae infection, unspecified site',
  'A493 Mycoplasma infection, unspecified site',
  'A498 Other bacterial infections of unspecified site',
  'A499 Bacterial infection, unspecified',
  'A5001 Early congenital syphilitic oculopathy',
  'A5002 Early congenital syphilitic osteochondropathy',
  'A5003 Early congenital syphilitic pharyngitis',
  'A5004 Early congenital syphilitic pneumonia',
  'A5005 Early congenital syphilitic rhinitis',
  'A5006 Early cutaneous congenital syphilis',
  'A5007 Early mucocutaneous congenital syphilis',
  'A5008 Early visceral congenital syphilis',
  'A5009 Other early congenital syphilis, symptomatic',
  'A501 Early congenital syphilis, latent',
  'A502 Early congenital syphilis, unspecified',
  'A5030 Late congenital syphilitic oculopathy, unspecified',
  'A5031 Late congenital syphilitic interstitial keratitis',
  'A5032 Late congenital syphilitic chorioretinitis',
  'A5039 Other late congenital syphilitic oculopathy',
  'A5040 Late congenital neurosyphilis, unspecified',
  'A5041 Late congenital syphilitic meningitis',
  'A5042 Late congenital syphilitic encephalitis',
  'A5043 Late congenital syphilitic polyneuropathy',
  'A5044 Late congenital syphilitic optic nerve atrophy',
  'A5045 Juvenile general paresis',
  'A5049 Other late congenital neurosyphilis',
  "A5051 Clutton's joints",
  "A5052 Hutchinson's teeth",
  "A5053 Hutchinson's triad",
  'A5054 Late congenital cardiovascular syphilis',
  'A5055 Late congenital syphilitic arthropathy',
  'A5056 Late congenital syphilitic osteochondropathy',
  'A5057 Syphilitic saddle nose',
  'A5059 Other late congenital syphilis, symptomatic',
  'A506 Late congenital syphilis, latent',
  'A507 Late congenital syphilis, unspecified',
  'A509 Congenital syphilis, unspecified',
  'A510 Primary genital syphilis',
  'A511 Primary anal syphilis',
  'A512 Primary syphilis of other sites',
  'A5131 Condyloma latum',
  'A5132 Syphilitic alopecia',
  'A5139 Other secondary syphilis of skin',
  'A5141 Secondary syphilitic meningitis',
  'A5142 Secondary syphilitic female pelvic disease',
  'A5143 Secondary syphilitic oculopathy',
  'A5144 Secondary syphilitic nephritis',
  'A5145 Secondary syphilitic hepatitis',
  'A5146 Secondary syphilitic osteopathy',
  'A5149 Other secondary syphilitic conditions',
  'A515 Early syphilis, latent',
  'A519 Early syphilis, unspecified',
  'A5200 Cardiovascular syphilis, unspecified',
  'A5201 Syphilitic aneurysm of aorta',
  'A5202 Syphilitic aortitis',
  'A5203 Syphilitic endocarditis',
  'A5204 Syphilitic cerebral arteritis',
  'A5205 Other cerebrovascular syphilis',
  'A5206 Other syphilitic heart involvement',
  'A5209 Other cardiovascular syphilis',
  'A5210 Symptomatic neurosyphilis, unspecified',
  'A5211 Tabes dorsalis',
  'A5212 Other cerebrospinal syphilis',
  'A5213 Late syphilitic meningitis',
  'A5214 Late syphilitic encephalitis',
  'A5215 Late syphilitic neuropathy',
  "A5216 Charcot's arthropathy (tabetic)",
  'A5217 General paresis',
  'A5219 Other symptomatic neurosyphilis',
  'A522 Asymptomatic neurosyphilis',
  'A523 Neurosyphilis, unspecified',
  'A5271 Late syphilitic oculopathy',
  'A5272 Syphilis of lung and bronchus',
  'A5273 Symptomatic late syphilis of other respiratory organs',
  'A5274 Syphilis of liver and other viscera',
  'A5275 Syphilis of kidney and ureter',
  'A5276 Other genitourinary symptomatic late syphilis',
  'A5277 Syphilis of bone and joint',
  'A5278 Syphilis of other musculoskeletal tissue',
  'A5279 Other symptomatic late syphilis',
  'A528 Late syphilis, latent',
  'A529 Late syphilis, unspecified',
  'A530 Latent syphilis, unspecified as early or late',
  'A539 Syphilis, unspecified',
  'A5400 Gonococcal infection of lower genitourinary tract, unspecified',
  'A5401 Gonococcal cystitis and urethritis, unspecified',
  'A5402 Gonococcal vulvovaginitis, unspecified',
  'A5403 Gonococcal cervicitis, unspecified',
  'A5409 Other gonococcal infection of lower genitourinary tract',
  'A541 Gonococcal infection of lower genitourinary tract with periurethral and accessory gland abscess',
  'A5421 Gonococcal infection of kidney and ureter',
  'A5422 Gonococcal prostatitis',
  'A5423 Gonococcal infection of other male genital organs',
  'A5424 Gonococcal female pelvic inflammatory disease',
  'A5429 Other gonococcal genitourinary infections',
  'A5430 Gonococcal infection of eye, unspecified',
  'A5431 Gonococcal conjunctivitis',
  'A5432 Gonococcal iridocyclitis',
  'A5433 Gonococcal keratitis',
  'A5439 Other gonococcal eye infection',
  'A5440 Gonococcal infection of musculoskeletal system, unspecified',
  'A5441 Gonococcal spondylopathy',
  'A5442 Gonococcal arthritis',
  'A5443 Gonococcal osteomyelitis',
  'A5449 Gonococcal infection of other musculoskeletal tissue',
  'A545 Gonococcal pharyngitis',
  'A546 Gonococcal infection of anus and rectum',
  'A5481 Gonococcal meningitis',
  'A5482 Gonococcal brain abscess',
  'A5483 Gonococcal heart infection',
  'A5484 Gonococcal pneumonia',
  'A5485 Gonococcal peritonitis',
  'A5486 Gonococcal sepsis',
  'A5489 Other gonococcal infections',
  'A549 Gonococcal infection, unspecified',
  'A55 Chlamydial lymphogranuloma (venereum)',
  'A5600 Chlamydial infection of lower genitourinary tract, unspecified',
  'A5601 Chlamydial cystitis and urethritis',
  'A5602 Chlamydial vulvovaginitis',
  'A5609 Other chlamydial infection of lower genitourinary tract',
  'A5611 Chlamydial female pelvic inflammatory disease',
  'A5619 Other chlamydial genitourinary infection',
  'A562 Chlamydial infection of genitourinary tract, unspecified',
  'A563 Chlamydial infection of anus and rectum',
  'A564 Chlamydial infection of pharynx',
  'A568 Sexually transmitted chlamydial infection of other sites',
  'A57 Chancroid',
  'A58 Granuloma inguinale',
  'A5900 Urogenital trichomoniasis, unspecified',
  'A5901 Trichomonal vulvovaginitis',
  'A5902 Trichomonal prostatitis',
  'A5903 Trichomonal cystitis and urethritis',
  'A5909 Other urogenital trichomoniasis',
  'A598 Trichomoniasis of other sites',
  'A599 Trichomoniasis, unspecified',
  'A6000 Herpesviral infection of urogenital system, unspecified',
  'A6001 Herpesviral infection of penis',
  'A6002 Herpesviral infection of other male genital organs',
  'A6003 Herpesviral cervicitis',
  'A6004 Herpesviral vulvovaginitis',
  'A6009 Herpesviral infection of other urogenital tract',
  'A601 Herpesviral infection of perianal skin and rectum',
  'A609 Anogenital herpesviral infection, unspecified',
  'A630 Anogenital (venereal) warts',
  'A638 Other specified predominantly sexually transmitted diseases',
  'A64 Unspecified sexually transmitted disease',
  'A65 Nonvenereal syphilis',
  'A660 Initial lesions of yaws',
  'A661 Multiple papillomata and wet crab yaws',
  'A662 Other early skin lesions of yaws',
  'A663 Hyperkeratosis of yaws',
  'A664 Gummata and ulcers of yaws',
  'A665 Gangosa',
  'A666 Bone and joint lesions of yaws',
  'A667 Other manifestations of yaws',
  'A668 Latent yaws',
  'A669 Yaws, unspecified',
  'A670 Primary lesions of pinta',
  'A671 Intermediate lesions of pinta',
  'A672 Late lesions of pinta',
  'A673 Mixed lesions of pinta',
  'A679 Pinta, unspecified',
  'A680 Louse-borne relapsing fever',
  'A681 Tick-borne relapsing fever',
  'A689 Relapsing fever, unspecified',
  'A690 Necrotizing ulcerative stomatitis',
  "A691 Other Vincent's infections",
  'A6920 Lyme disease, unspecified',
  'A6921 Meningitis due to Lyme disease',
  'A6922 Other neurologic disorders in Lyme disease',
  'A6923 Arthritis due to Lyme disease',
  'A6929 Other conditions associated with Lyme disease',
  'A698 Other specified spirochetal infections',
  'A699 Spirochetal infection, unspecified',
  'A70 Chlamydia psittaci infections',
  'A710 Initial stage of trachoma',
  'A711 Active stage of trachoma',
  'A719 Trachoma, unspecified',
  'A740 Chlamydial conjunctivitis',
  'A7481 Chlamydial peritonitis',
  'A7489 Other chlamydial diseases',
  'A749 Chlamydial infection, unspecified',
  'A750 Epidemic louse-borne typhus fever due to Rickettsia prowazekii',
  "A751 Recrudescent typhus [Brill's disease]",
  'A752 Typhus fever due to Rickettsia typhi',
  'A753 Typhus fever due to Rickettsia tsutsugamushi',
  'A759 Typhus fever, unspecified',
  'A770 Spotted fever due to Rickettsia rickettsii',
  'A771 Spotted fever due to Rickettsia conorii',
  'A772 Spotted fever due to Rickettsia siberica',
  'A773 Spotted fever due to Rickettsia australis',
  'A7740 Ehrlichiosis, unspecified',
  'A7741 Ehrlichiosis chafeensis [E. chafeensis]',
  'A7749 Other ehrlichiosis',
  'A778 Other spotted fevers',
  'A779 Spotted fever, unspecified',
  'A78 Q fever',
  'A790 Trench fever',
  'A791 Rickettsialpox due to Rickettsia akari',
  'A7981 Rickettsiosis due to Ehrlichia sennetsu',
  'A7989 Other specified rickettsioses',
  'A799 Rickettsiosis, unspecified',
  'A800 Acute paralytic poliomyelitis, vaccine-associated',
  'A801 Acute paralytic poliomyelitis, wild virus, imported',
  'A802 Acute paralytic poliomyelitis, wild virus, indigenous',
  'A8030 Acute paralytic poliomyelitis, unspecified',
  'A8039 Other acute paralytic poliomyelitis',
  'A804 Acute nonparalytic poliomyelitis',
  'A809 Acute poliomyelitis, unspecified',
  'A8100 Creutzfeldt-Jakob disease, unspecified',
  'A8101 Variant Creutzfeldt-Jakob disease',
  'A8109 Other Creutzfeldt-Jakob disease',
  'A811 Subacute sclerosing panencephalitis',
  'A812 Progressive multifocal leukoencephalopathy',
  'A8181 Kuru',
  'A8182 Gerstmann-Straussler-Scheinker syndrome',
  'A8183 Fatal familial insomnia',
  'A8189 Other atypical virus infections of central nervous system',
  'A819 Atypical virus infection of central nervous system, unspecified',
  'A820 Sylvatic rabies',
  'A821 Urban rabies',
  'A829 Rabies, unspecified',
  'A830 Japanese encephalitis',
  'A831 Western equine encephalitis',
  'A832 Eastern equine encephalitis',
  'A833 St Louis encephalitis',
  'A834 Australian encephalitis',
  'A835 California encephalitis',
  'A836 Rocio virus disease',
  'A838 Other mosquito-borne viral encephalitis',
  'A839 Mosquito-borne viral encephalitis, unspecified',
  'A840 Far Eastern tick-borne encephalitis [Russian spring-summer encephalitis]',
  'A841 Central European tick-borne encephalitis',
  'A848 Other tick-borne viral encephalitis',
  'A849 Tick-borne viral encephalitis, unspecified',
  'A850 Enteroviral encephalitis',
  'A851 Adenoviral encephalitis',
  'A852 Arthropod-borne viral encephalitis, unspecified',
  'A858 Other specified viral encephalitis',
  'A86 Unspecified viral encephalitis',
  'A870 Enteroviral meningitis',
  'A871 Adenoviral meningitis',
  'A872 Lymphocytic choriomeningitis',
  'A878 Other viral meningitis',
  'A879 Viral meningitis, unspecified',
  'A880 Enteroviral exanthematous fever [Boston exanthem]',
  'A881 Epidemic vertigo',
  'A888 Other specified viral infections of central nervous system',
  'A89 Unspecified viral infection of central nervous system',
  'A90 Dengue fever [classical dengue]',
  'A91 Dengue hemorrhagic fever',
  'A920 Chikungunya virus disease',
  "A921 O'nyong-nyong fever",
  'A922 Venezuelan equine fever',
  'A9230 West Nile virus infection, unspecified',
  'A9231 West Nile virus infection with encephalitis',
  'A9232 West Nile virus infection with other neurologic manifestation',
  'A9239 West Nile virus infection with other complications',
  'A924 Rift Valley fever',
  'A925 Zika virus disease',
  'A928 Other specified mosquito-borne viral fevers',
  'A929 Mosquito-borne viral fever, unspecified',
  'A930 Oropouche virus disease',
  'A931 Sandfly fever',
  'A932 Colorado tick fever',
  'A938 Other specified arthropod-borne viral fevers',
  'A94 Unspecified arthropod-borne viral fever',
  'A950 Sylvatic yellow fever',
  'A951 Urban yellow fever',
  'A959 Yellow fever, unspecified',
  'A960 Junin hemorrhagic fever',
  'A961 Machupo hemorrhagic fever',
  'A962 Lassa fever',
  'A968 Other arenaviral hemorrhagic fevers',
  'A969 Arenaviral hemorrhagic fever, unspecified',
  'A980 Crimean-Congo hemorrhagic fever',
  'A981 Omsk hemorrhagic fever',
  'A982 Kyasanur Forest disease',
  'A983 Marburg virus disease',
  'A984 Ebola virus disease',
  'A985 Hemorrhagic fever with renal syndrome',
  'A988 Other specified viral hemorrhagic fevers',
  'A99 Unspecified viral hemorrhagic fever',
  'B000 Eczema herpeticum',
  'B001 Herpesviral vesicular dermatitis',
  'B002 Herpesviral gingivostomatitis and pharyngotonsillitis',
  'B003 Herpesviral meningitis',
  'B004 Herpesviral encephalitis',
  'B0050 Herpesviral ocular disease, unspecified',
  'B0051 Herpesviral iridocyclitis',
  'B0052 Herpesviral keratitis',
  'B0053 Herpesviral conjunctivitis',
  'B0059 Other herpesviral disease of eye',
  'B007 Disseminated herpesviral disease',
  'B0081 Herpesviral hepatitis',
  'B0082 Herpes simplex myelitis',
  'B0089 Other herpesviral infection',
  'B009 Herpesviral infection, unspecified',
  'B010 Varicella meningitis',
  'B0111 Varicella encephalitis and encephalomyelitis',
  'B0112 Varicella myelitis',
  'B012 Varicella pneumonia',
  'B0181 Varicella keratitis',
  'B0189 Other varicella complications',
  'B019 Varicella without complication',
  'B020 Zoster encephalitis',
  'B021 Zoster meningitis',
  'B0221 Postherpetic geniculate ganglionitis',
  'B0222 Postherpetic trigeminal neuralgia',
  'B0223 Postherpetic polyneuropathy',
  'B0224 Postherpetic myelitis',
  'B0229 Other postherpetic nervous system involvement',
  'B0230 Zoster ocular disease, unspecified',
  'B0231 Zoster conjunctivitis',
  'B0232 Zoster iridocyclitis',
  'B0233 Zoster keratitis',
  'B0234 Zoster scleritis',
  'B0239 Other herpes zoster eye disease',
  'B027 Disseminated zoster',
  'B028 Zoster with other complications',
  'B029 Zoster without complications',
  'B03 Smallpox',
  'B04 Monkeypox',
  'B050 Measles complicated by encephalitis',
  'B051 Measles complicated by meningitis',
  'B052 Measles complicated by pneumonia',
  'B053 Measles complicated by otitis media',
  'B054 Measles with intestinal complications',
  'B0581 Measles keratitis and keratoconjunctivitis',
  'B0589 Other measles complications',
  'B059 Measles without complication',
  'B0600 Rubella with neurological complication, unspecified',
  'B0601 Rubella encephalitis',
  'B0602 Rubella meningitis',
  'B0609 Other neurological complications of rubella',
  'B0681 Rubella pneumonia',
  'B0682 Rubella arthritis',
  'B0689 Other rubella complications',
  'B069 Rubella without complication',
  'B070 Plantar wart',
  'B078 Other viral warts',
  'B079 Viral wart, unspecified',
  'B08010 Cowpox',
  'B08011 Vaccinia not from vaccine',
  'B0802 Orf virus disease',
  "B0803 Pseudocowpox [milker's node]",
  'B0804 Paravaccinia, unspecified',
  'B0809 Other orthopoxvirus infections',
  'B081 Molluscum contagiosum',
  'B0820 Exanthema subitum [sixth disease], unspecified',
  'B0821 Exanthema subitum [sixth disease] due to human herpesvirus 6',
  'B0822 Exanthema subitum [sixth disease] due to human herpesvirus 7',
  'B083 Erythema infectiosum [fifth disease]',
  'B084 Enteroviral vesicular stomatitis with exanthem',
  'B085 Enteroviral vesicular pharyngitis',
  'B0860 Parapoxvirus infection, unspecified',
  'B0861 Bovine stomatitis',
  'B0862 Sealpox',
  'B0869 Other parapoxvirus infections',
  'B0870 Yatapoxvirus infection, unspecified',
  'B0871 Tanapox virus disease',
  'B0872 Yaba pox virus disease',
  'B0879 Other yatapoxvirus infections',
  'B088 Other specified viral infections characterized by skin and mucous membrane lesions',
  'B09 Unspecified viral infection characterized by skin and mucous membrane lesions',
  'B1001 Human herpesvirus 6 encephalitis',
  'B1009 Other human herpesvirus encephalitis',
  'B1081 Human herpesvirus 6 infection',
  'B1082 Human herpesvirus 7 infection',
  'B1089 Other human herpesvirus infection',
  'B150 Hepatitis A with hepatic coma',
  'B159 Hepatitis A without hepatic coma',
  'B160 Acute hepatitis B with delta-agent with hepatic coma',
  'B161 Acute hepatitis B with delta-agent without hepatic coma',
  'B162 Acute hepatitis B without delta-agent with hepatic coma',
  'B169 Acute hepatitis B without delta-agent and without hepatic coma',
  'B170 Acute delta-(super) infection of hepatitis B carrier',
  'B1710 Acute hepatitis C without hepatic coma',
  'B1711 Acute hepatitis C with hepatic coma',
  'B172 Acute hepatitis E',
  'B178 Other specified acute viral hepatitis',
  'B179 Acute viral hepatitis, unspecified',
  'B180 Chronic viral hepatitis B with delta-agent',
  'B181 Chronic viral hepatitis B without delta-agent',
  'B182 Chronic viral hepatitis C',
  'B188 Other chronic viral hepatitis',
  'B189 Chronic viral hepatitis, unspecified',
  'B190 Unspecified viral hepatitis with hepatic coma',
  'B1910 Unspecified viral hepatitis B without hepatic coma',
  'B1911 Unspecified viral hepatitis B with hepatic coma',
  'B1920 Unspecified viral hepatitis C without hepatic coma',
  'B1921 Unspecified viral hepatitis C with hepatic coma',
  'B199 Unspecified viral hepatitis without hepatic coma',
  'B20 Human immunodeficiency virus [HIV] disease',
  'B250 Cytomegaloviral pneumonitis',
  'B251 Cytomegaloviral hepatitis',
  'B252 Cytomegaloviral pancreatitis',
  'B258 Other cytomegaloviral diseases',
  'B259 Cytomegaloviral disease, unspecified',
  'B260 Mumps orchitis',
  'B261 Mumps meningitis',
  'B262 Mumps encephalitis',
  'B263 Mumps pancreatitis',
  'B2681 Mumps hepatitis',
  'B2682 Mumps myocarditis',
  'B2683 Mumps nephritis',
  'B2684 Mumps polyneuropathy',
  'B2685 Mumps arthritis',
  'B2689 Other mumps complications',
  'B269 Mumps without complication',
  'B2700 Gammaherpesviral mononucleosis without complication',
  'B2701 Gammaherpesviral mononucleosis with polyneuropathy',
  'B2702 Gammaherpesviral mononucleosis with meningitis',
  'B2709 Gammaherpesviral mononucleosis with other complications',
  'B2710 Cytomegaloviral mononucleosis without complications',
  'B2711 Cytomegaloviral mononucleosis with polyneuropathy',
  'B2712 Cytomegaloviral mononucleosis with meningitis',
  'B2719 Cytomegaloviral mononucleosis with other complication',
  'B2780 Other infectious mononucleosis without complication',
  'B2781 Other infectious mononucleosis with polyneuropathy',
  'B2782 Other infectious mononucleosis with meningitis',
  'B2789 Other infectious mononucleosis with other complication',
  'B2790 Infectious mononucleosis, unspecified without complication',
  'B2791 Infectious mononucleosis, unspecified with polyneuropathy',
  'B2792 Infectious mononucleosis, unspecified with meningitis',
  'B2799 Infectious mononucleosis, unspecified with other complication',
  'B300 Keratoconjunctivitis due to adenovirus',
  'B301 Conjunctivitis due to adenovirus',
  'B302 Viral pharyngoconjunctivitis',
  'B303 Acute epidemic hemorrhagic conjunctivitis (enteroviral)',
  'B308 Other viral conjunctivitis',
  'B309 Viral conjunctivitis, unspecified',
  'B330 Epidemic myalgia',
  'B331 Ross River disease',
  'B3320 Viral carditis, unspecified',
  'B3321 Viral endocarditis',
  'B3322 Viral myocarditis',
  'B3323 Viral pericarditis',
  'B3324 Viral cardiomyopathy',
  'B333 Retrovirus infections, not elsewhere classified',
  'B334 Hantavirus (cardio)-pulmonary syndrome [HPS] [HCPS]',
  'B338 Other specified viral diseases',
  'B340 Adenovirus infection, unspecified',
  'B341 Enterovirus infection, unspecified',
  'B342 Coronavirus infection, unspecified',
  'B343 Parvovirus infection, unspecified',
  'B344 Papovavirus infection, unspecified',
  'B348 Other viral infections of unspecified site',
  'B349 Viral infection, unspecified',
  'B350 Tinea barbae and tinea capitis',
  'B351 Tinea unguium',
  'B352 Tinea manuum',
  'B353 Tinea pedis',
  'B354 Tinea corporis',
  'B355 Tinea imbricata',
  'B356 Tinea cruris',
  'B358 Other dermatophytoses',
  'B359 Dermatophytosis, unspecified',
  'B360 Pityriasis versicolor',
  'B361 Tinea nigra',
  'B362 White piedra',
  'B363 Black piedra',
  'B368 Other specified superficial mycoses',
  'B369 Superficial mycosis, unspecified',
  'B370 Candidal stomatitis',
  'B371 Pulmonary candidiasis',
  'B372 Candidiasis of skin and nail',
  'B373 Candidiasis of vulva and vagina',
  'B3741 Candidal cystitis and urethritis',
  'B3742 Candidal balanitis',
  'B3749 Other urogenital candidiasis',
  'B375 Candidal meningitis',
  'B376 Candidal endocarditis',
  'B377 Candidal sepsis',
  'B3781 Candidal esophagitis',
  'B3782 Candidal enteritis',
  'B3783 Candidal cheilitis',
  'B3784 Candidal otitis externa',
  'B3789 Other sites of candidiasis',
  'B379 Candidiasis, unspecified',
  'B380 Acute pulmonary coccidioidomycosis',
  'B381 Chronic pulmonary coccidioidomycosis',
  'B382 Pulmonary coccidioidomycosis, unspecified',
  'B383 Cutaneous coccidioidomycosis',
  'B384 Coccidioidomycosis meningitis',
  'B387 Disseminated coccidioidomycosis',
  'B3881 Prostatic coccidioidomycosis',
  'B3889 Other forms of coccidioidomycosis',
  'B389 Coccidioidomycosis, unspecified',
  'B390 Acute pulmonary histoplasmosis capsulati',
  'B391 Chronic pulmonary histoplasmosis capsulati',
  'B392 Pulmonary histoplasmosis capsulati, unspecified',
  'B393 Disseminated histoplasmosis capsulati',
  'B394 Histoplasmosis capsulati, unspecified',
  'B395 Histoplasmosis duboisii',
  'B399 Histoplasmosis, unspecified',
  'B400 Acute pulmonary blastomycosis',
  'B401 Chronic pulmonary blastomycosis',
  'B402 Pulmonary blastomycosis, unspecified',
  'B403 Cutaneous blastomycosis',
  'B407 Disseminated blastomycosis',
  'B4081 Blastomycotic meningoencephalitis',
  'B4089 Other forms of blastomycosis',
  'B409 Blastomycosis, unspecified',
  'B410 Pulmonary paracoccidioidomycosis',
  'B417 Disseminated paracoccidioidomycosis',
  'B418 Other forms of paracoccidioidomycosis',
  'B419 Paracoccidioidomycosis, unspecified',
  'B420 Pulmonary sporotrichosis',
  'B421 Lymphocutaneous sporotrichosis',
  'B427 Disseminated sporotrichosis',
  'B4281 Cerebral sporotrichosis',
  'B4282 Sporotrichosis arthritis',
  'B4289 Other forms of sporotrichosis',
  'B429 Sporotrichosis, unspecified',
  'B430 Cutaneous chromomycosis',
  'B431 Pheomycotic brain abscess',
  'B432 Subcutaneous pheomycotic abscess and cyst',
  'B438 Other forms of chromomycosis',
  'B439 Chromomycosis, unspecified',
  'B440 Invasive pulmonary aspergillosis',
  'B441 Other pulmonary aspergillosis',
  'B442 Tonsillar aspergillosis',
  'B447 Disseminated aspergillosis',
  'B4481 Allergic bronchopulmonary aspergillosis',
  'B4489 Other forms of aspergillosis',
  'B449 Aspergillosis, unspecified',
  'B450 Pulmonary cryptococcosis',
  'B451 Cerebral cryptococcosis',
  'B452 Cutaneous cryptococcosis',
  'B453 Osseous cryptococcosis',
  'B457 Disseminated cryptococcosis',
  'B458 Other forms of cryptococcosis',
  'B459 Cryptococcosis, unspecified',
  'B460 Pulmonary mucormycosis',
  'B461 Rhinocerebral mucormycosis',
  'B462 Gastrointestinal mucormycosis',
  'B463 Cutaneous mucormycosis',
  'B464 Disseminated mucormycosis',
  'B465 Mucormycosis, unspecified',
  'B468 Other zygomycoses',
  'B469 Zygomycosis, unspecified',
  'B470 Eumycetoma',
  'B471 Actinomycetoma',
  'B479 Mycetoma, unspecified',
  'B480 Lobomycosis',
  'B481 Rhinosporidiosis',
  'B482 Allescheriasis',
  'B483 Geotrichosis',
  'B484 Penicillosis',
  'B488 Other specified mycoses',
  'B49 Unspecified mycosis',
  'B500 Plasmodium falciparum malaria with cerebral complications',
  'B508 Other severe and complicated Plasmodium falciparum malaria',
  'B509 Plasmodium falciparum malaria, unspecified',
  'B510 Plasmodium vivax malaria with rupture of spleen',
  'B518 Plasmodium vivax malaria with other complications',
  'B519 Plasmodium vivax malaria without complication',
  'B520 Plasmodium malariae malaria with nephropathy',
  'B528 Plasmodium malariae malaria with other complications',
  'B529 Plasmodium malariae malaria without complication',
  'B530 Plasmodium ovale malaria',
  'B531 Malaria due to simian plasmodia',
  'B538 Other malaria, not elsewhere classified',
  'B54 Unspecified malaria',
  'B550 Visceral leishmaniasis',
  'B551 Cutaneous leishmaniasis',
  'B552 Mucocutaneous leishmaniasis',
  'B559 Leishmaniasis, unspecified',
  'B560 Gambiense trypanosomiasis',
  'B561 Rhodesiense trypanosomiasis',
  'B569 African trypanosomiasis, unspecified',
  "B570 Acute Chagas' disease with heart involvement",
  "B571 Acute Chagas' disease without heart involvement",
  "B572 Chagas' disease (chronic) with heart involvement",
  "B5730 Chagas' disease with digestive system involvement, unspecified",
  "B5731 Megaesophagus in Chagas' disease",
  "B5732 Megacolon in Chagas' disease",
  "B5739 Other digestive system involvement in Chagas' disease",
  "B5740 Chagas' disease with nervous system involvement, unspecified",
  "B5741 Meningitis in Chagas' disease",
  "B5742 Meningoencephalitis in Chagas' disease",
  "B5749 Other nervous system involvement in Chagas' disease",
  "B575 Chagas' disease (chronic) with other organ involvement",
  'B5800 Toxoplasma oculopathy, unspecified',
  'B5801 Toxoplasma chorioretinitis',
  'B5809 Other toxoplasma oculopathy',
  'B581 Toxoplasma hepatitis',
  'B582 Toxoplasma meningoencephalitis',
  'B583 Pulmonary toxoplasmosis',
  'B5881 Toxoplasma myocarditis',
  'B5882 Toxoplasma myositis',
  'B5883 Toxoplasma tubulo-interstitial nephropathy',
  'B5889 Toxoplasmosis with other organ involvement',
  'B589 Toxoplasmosis, unspecified',
  'B59 Pneumocystosis',
  'B600 Babesiosis',
  'B6010 Acanthamebiasis, unspecified',
  'B6011 Meningoencephalitis due to Acanthamoeba (culbertsoni)',
  'B6012 Conjunctivitis due to Acanthamoeba',
  'B6013 Keratoconjunctivitis due to Acanthamoeba',
  'B6019 Other acanthamebic disease',
  'B602 Naegleriasis',
  'B608 Other specified protozoal diseases',
  'B64 Unspecified protozoal disease',
  'B650 Schistosomiasis due to Schistosoma haematobium [urinary schistosomiasis]',
  'B651 Schistosomiasis due to Schistosoma mansoni [intestinal schistosomiasis]',
  'B652 Schistosomiasis due to Schistosoma japonicum',
  'B653 Cercarial dermatitis',
  'B658 Other schistosomiasis',
  'B659 Schistosomiasis, unspecified',
  'B660 Opisthorchiasis',
  'B661 Clonorchiasis',
  'B662 Dicroceliasis',
  'B663 Fascioliasis',
  'B664 Paragonimiasis',
  'B665 Fasciolopsiasis',
  'B668 Other specified fluke infections',
  'B669 Fluke infection, unspecified',
  'B670 Echinococcus granulosus infection of liver',
  'B671 Echinococcus granulosus infection of lung',
  'B672 Echinococcus granulosus infection of bone',
  'B6731 Echinococcus granulosus infection, thyroid gland',
  'B6732 Echinococcus granulosus infection, multiple sites',
  'B6739 Echinococcus granulosus infection, other sites',
  'B674 Echinococcus granulosus infection, unspecified',
  'B675 Echinococcus multilocularis infection of liver',
  'B6761 Echinococcus multilocularis infection, multiple sites',
  'B6769 Echinococcus multilocularis infection, other sites',
  'B677 Echinococcus multilocularis infection, unspecified',
  'B678 Echinococcosis, unspecified, of liver',
  'B6790 Echinococcosis, unspecified',
  'B6799 Other echinococcosis',
  'B680 Taenia solium taeniasis',
  'B681 Taenia saginata taeniasis',
  'B689 Taeniasis, unspecified',
  'B690 Cysticercosis of central nervous system',
  'B691 Cysticercosis of eye',
  'B6981 Myositis in cysticercosis',
  'B6989 Cysticercosis of other sites',
  'B699 Cysticercosis, unspecified',
  'B700 Diphyllobothriasis',
  'B701 Sparganosis',
  'B710 Hymenolepiasis',
  'B711 Dipylidiasis',
  'B718 Other specified cestode infections',
  'B719 Cestode infection, unspecified',
  'B72 Dracunculiasis',
  'B7300 Onchocerciasis with eye involvement, unspecified',
  'B7301 Onchocerciasis with endophthalmitis',
  'B7302 Onchocerciasis with glaucoma',
  'B7309 Onchocerciasis with other eye involvement',
  'B731 Onchocerciasis without eye disease',
  'B740 Filariasis due to Wuchereria bancrofti',
  'B741 Filariasis due to Brugia malayi',
  'B742 Filariasis due to Brugia timori',
  'B743 Loiasis',
  'B744 Mansonelliasis',
  'B748 Other filariases',
  'B749 Filariasis, unspecified',
  'B75 Trichinellosis',
  'B760 Ancylostomiasis',
  'B761 Necatoriasis',
  'B768 Other hookworm diseases',
  'B769 Hookworm disease, unspecified',
  'B770 Ascariasis with intestinal complications',
  'B7781 Ascariasis pneumonia',
  'B7789 Ascariasis with other complications',
  'B779 Ascariasis, unspecified',
  'B780 Intestinal strongyloidiasis',
  'B781 Cutaneous strongyloidiasis',
  'B787 Disseminated strongyloidiasis',
  'B789 Strongyloidiasis, unspecified',
  'B79 Trichuriasis',
  'B80 Enterobiasis',
  'B810 Anisakiasis',
  'B811 Intestinal capillariasis',
  'B812 Trichostrongyliasis',
  'B813 Intestinal angiostrongyliasis',
  'B814 Mixed intestinal helminthiases',
  'B818 Other specified intestinal helminthiases',
  'B820 Intestinal helminthiasis, unspecified',
  'B829 Intestinal parasitism, unspecified',
  'B830 Visceral larva migrans',
  'B831 Gnathostomiasis',
  'B832 Angiostrongyliasis due to Parastrongylus cantonensis',
  'B833 Syngamiasis',
  'B834 Internal hirudiniasis',
  'B838 Other specified helminthiases',
  'B839 Helminthiasis, unspecified',
  'B850 Pediculosis due to Pediculus humanus capitis',
  'B851 Pediculosis due to Pediculus humanus corporis',
  'B852 Pediculosis, unspecified',
  'B853 Phthiriasis',
  'B854 Mixed pediculosis and phthiriasis',
  'B86 Scabies',
  'B870 Cutaneous myiasis',
  'B871 Wound myiasis',
  'B872 Ocular myiasis',
  'B873 Nasopharyngeal myiasis',
  'B874 Aural myiasis',
  'B8781 Genitourinary myiasis',
  'B8782 Intestinal myiasis',
  'B8789 Myiasis of other sites',
  'B879 Myiasis, unspecified',
  'B880 Other acariasis',
  'B881 Tungiasis [sandflea infestation]',
  'B882 Other arthropod infestations',
  'B883 External hirudiniasis',
  'B888 Other specified infestations',
  'B889 Infestation, unspecified',
  'B89 Unspecified parasitic disease',
  'B900 Sequelae of central nervous system tuberculosis',
  'B901 Sequelae of genitourinary tuberculosis',
  'B902 Sequelae of tuberculosis of bones and joints',
  'B908 Sequelae of tuberculosis of other organs',
  'B909 Sequelae of respiratory and unspecified tuberculosis',
  'B91 Sequelae of poliomyelitis',
  'B92 Sequelae of leprosy',
  'B940 Sequelae of trachoma',
  'B941 Sequelae of viral encephalitis',
  'B942 Sequelae of viral hepatitis',
  'B948 Sequelae of other specified infectious and parasitic diseases',
  'B949 Sequelae of unspecified infectious and parasitic disease',
  'B950 Streptococcus, group A, as the cause of diseases classified elsewhere',
  'B951 Streptococcus, group B, as the cause of diseases classified elsewhere',
  'B952 Enterococcus as the cause of diseases classified elsewhere',
  'B953 Streptococcus pneumoniae as the cause of diseases classified elsewhere',
  'B954 Other streptococcus as the cause of diseases classified elsewhere',
  'B955 Unspecified streptococcus as the cause of diseases classified elsewhere',
  'B9561 Methicillin susceptible Staphylococcus aureus infection as the cause of diseases classified',
  'elsewhere',
  'B9562 Methicillin resistant Staphylococcus aureus infection as the cause of diseases classified',
  'elsewhere',
  'B957 Other staphylococcus as the cause of diseases classified elsewhere',
  'B958 Unspecified staphylococcus as the cause of diseases classified elsewhere',
  'B960 Mycoplasma pneumoniae [M. pneumoniae] as the cause of diseases classified elsewhere',
  'B961 Klebsiella pneumoniae [K. pneumoniae] as the cause of diseases classified elsewhere',
  'B9620 Unspecified Escherichia coli [E. coli] as the cause of diseases classified elsewhere',
  'B9621 Shiga toxin-producing Escherichia coli [E. coli] (STEC) O157 as the cause of diseases classified',
  'elsewhere',
  'B9622 Other specified Shiga toxin-producing Escherichia coli [E. coli] (STEC) as the cause of diseases',
  'classified elsewhere',
  'B9623 Unspecified Shiga toxin-producing Escherichia coli [E. coli] (STEC) as the cause of diseases',
  'classified elsewhere',
  'B9629 Other Escherichia coli [E. coli] as the cause of diseases classified elsewhere',
  'B963 Hemophilus influenzae [H. influenzae] as the cause of diseases classified elsewhere',
  'B964 Proteus (mirabilis) (morganii) as the cause of diseases classified elsewhere',
  'B965 Pseudomonas (aeruginosa) (mallei) (pseudomallei) as the cause of diseases classified elsewhere',
  'B966 Bacteroides fragilis [B. fragilis] as the cause of diseases classified elsewhere',
  'B967 Clostridium perfringens [C. perfringens] as the cause of diseases classified elsewhere',
  'B9681 Helicobacter pylori [H. pylori] as the cause of diseases classified elsewhere',
  'B9682 Vibrio vulnificus as the cause of diseases classified elsewhere',
  'B9689 Other specified bacterial agents as the cause of diseases classified elsewhere',
  'B970 Adenovirus as the cause of diseases classified elsewhere',
  'B9710 Unspecified enterovirus as the cause of diseases classified elsewhere',
  'B9711 Coxsackievirus as the cause of diseases classified elsewhere',
  'B9712 Echovirus as the cause of diseases classified elsewhere',
  'B9719 Other enterovirus as the cause of diseases classified elsewhere',
  'B9721 SARS-associated coronavirus as the cause of diseases classified elsewhere',
  'B9729 Other coronavirus as the cause of diseases classified elsewhere',
  'B9730 Unspecified retrovirus as the cause of diseases classified elsewhere',
  'B9731 Lentivirus as the cause of diseases classified elsewhere',
  'B9732 Oncovirus as the cause of diseases classified elsewhere',
  'B9733 Human T-cell lymphotrophic virus, type I [HTLV-I] as the cause of diseases classified elsewhere',
  'B9734 Human T-cell lymphotrophic virus, type II [HTLV-II] as the cause of diseases classified elsewhere',
  'B9735 Human immunodeficiency virus, type 2 [HIV 2] as the cause of diseases classified elsewhere',
  'B9739 Other retrovirus as the cause of diseases classified elsewhere',
  'B974 Respiratory syncytial virus as the cause of diseases classified elsewhere',
  'B975 Reovirus as the cause of diseases classified elsewhere',
  'B976 Parvovirus as the cause of diseases classified elsewhere',
  'B977 Papillomavirus as the cause of diseases classified elsewhere',
  'B9781 Human metapneumovirus as the cause of diseases classified elsewhere',
  'B9789 Other viral agents as the cause of diseases classified elsewhere',
  'B998 Other infectious disease',
  'B999 Unspecified infectious disease',
  'C000 Malignant neoplasm of external upper lip',
  'C001 Malignant neoplasm of external lower lip',
  'C002 Malignant neoplasm of external lip, unspecified',
  'C003 Malignant neoplasm of upper lip, inner aspect',
  'C004 Malignant neoplasm of lower lip, inner aspect',
  'C005 Malignant neoplasm of lip, unspecified, inner aspect',
  'C006 Malignant neoplasm of commissure of lip, unspecified',
  'C008 Malignant neoplasm of overlapping sites of lip',
  'C009 Malignant neoplasm of lip, unspecified',
  'C01 Malignant neoplasm of base of tongue',
  'C020 Malignant neoplasm of dorsal surface of tongue',
  'C021 Malignant neoplasm of border of tongue',
  'C022 Malignant neoplasm of ventral surface of tongue',
  'C023 Malignant neoplasm of anterior two-thirds of tongue, part unspecified',
  'C024 Malignant neoplasm of lingual tonsil',
  'C028 Malignant neoplasm of overlapping sites of tongue',
  'C029 Malignant neoplasm of tongue, unspecified',
  'C030 Malignant neoplasm of upper gum',
  'C031 Malignant neoplasm of lower gum',
  'C039 Malignant neoplasm of gum, unspecified',
  'C040 Malignant neoplasm of anterior floor of mouth',
  'C041 Malignant neoplasm of lateral floor of mouth',
  'C048 Malignant neoplasm of overlapping sites of floor of mouth',
  'C049 Malignant neoplasm of floor of mouth, unspecified',
  'C050 Malignant neoplasm of hard palate',
  'C051 Malignant neoplasm of soft palate',
  'C052 Malignant neoplasm of uvula',
  'C058 Malignant neoplasm of overlapping sites of palate',
  'C059 Malignant neoplasm of palate, unspecified',
  'C060 Malignant neoplasm of cheek mucosa',
  'C061 Malignant neoplasm of vestibule of mouth',
  'C062 Malignant neoplasm of retromolar area',
  'C0680 Malignant neoplasm of overlapping sites of unspecified parts of mouth',
  'C0689 Malignant neoplasm of overlapping sites of other parts of mouth',
  'C069 Malignant neoplasm of mouth, unspecified',
  'C07 Malignant neoplasm of parotid gland',
  'C080 Malignant neoplasm of submandibular gland',
  'C081 Malignant neoplasm of sublingual gland',
  'C089 Malignant neoplasm of major salivary gland, unspecified',
  'C090 Malignant neoplasm of tonsillar fossa',
  'C091 Malignant neoplasm of tonsillar pillar (anterior) (posterior)',
  'C098 Malignant neoplasm of overlapping sites of tonsil',
  'C099 Malignant neoplasm of tonsil, unspecified',
  'C100 Malignant neoplasm of vallecula',
  'C101 Malignant neoplasm of anterior surface of epiglottis',
  'C102 Malignant neoplasm of lateral wall of oropharynx',
  'C103 Malignant neoplasm of posterior wall of oropharynx',
  'C104 Malignant neoplasm of branchial cleft',
  'C108 Malignant neoplasm of overlapping sites of oropharynx',
  'C109 Malignant neoplasm of oropharynx, unspecified',
  'C110 Malignant neoplasm of superior wall of nasopharynx',
  'C111 Malignant neoplasm of posterior wall of nasopharynx',
  'C112 Malignant neoplasm of lateral wall of nasopharynx',
  'C113 Malignant neoplasm of anterior wall of nasopharynx',
  'C118 Malignant neoplasm of overlapping sites of nasopharynx',
  'C119 Malignant neoplasm of nasopharynx, unspecified',
  'C12 Malignant neoplasm of pyriform sinus',
  'C130 Malignant neoplasm of postcricoid region',
  'C131 Malignant neoplasm of aryepiglottic fold, hypopharyngeal aspect',
  'C132 Malignant neoplasm of posterior wall of hypopharynx',
  'C138 Malignant neoplasm of overlapping sites of hypopharynx',
  'C139 Malignant neoplasm of hypopharynx, unspecified',
  'C140 Malignant neoplasm of pharynx, unspecified',
  "C142 Malignant neoplasm of Waldeyer's ring",
  'C148 Malignant neoplasm of overlapping sites of lip, oral cavity and pharynx',
  'C153 Malignant neoplasm of upper third of esophagus',
  'C154 Malignant neoplasm of middle third of esophagus',
  'C155 Malignant neoplasm of lower third of esophagus',
  'C158 Malignant neoplasm of overlapping sites of esophagus',
  'C159 Malignant neoplasm of esophagus, unspecified',
  'C160 Malignant neoplasm of cardia',
  'C161 Malignant neoplasm of fundus of stomach',
  'C162 Malignant neoplasm of body of stomach',
  'C163 Malignant neoplasm of pyloric antrum',
  'C164 Malignant neoplasm of pylorus',
  'C165 Malignant neoplasm of lesser curvature of stomach, unspecified',
  'C166 Malignant neoplasm of greater curvature of stomach, unspecified',
  'C168 Malignant neoplasm of overlapping sites of stomach',
  'C169 Malignant neoplasm of stomach, unspecified',
  'C170 Malignant neoplasm of duodenum',
  'C171 Malignant neoplasm of jejunum',
  'C172 Malignant neoplasm of ileum',
  "C173 Meckel's diverticulum, malignant",
  'C178 Malignant neoplasm of overlapping sites of small intestine',
  'C179 Malignant neoplasm of small intestine, unspecified',
  'C180 Malignant neoplasm of cecum',
  'C181 Malignant neoplasm of appendix',
  'C182 Malignant neoplasm of ascending colon',
  'C183 Malignant neoplasm of hepatic flexure',
  'C184 Malignant neoplasm of transverse colon',
  'C185 Malignant neoplasm of splenic flexure',
  'C186 Malignant neoplasm of descending colon',
  'C187 Malignant neoplasm of sigmoid colon',
  'C188 Malignant neoplasm of overlapping sites of colon',
  'C189 Malignant neoplasm of colon, unspecified',
  'C19 Malignant neoplasm of rectosigmoid junction',
  'C20 Malignant neoplasm of rectum',
  'C210 Malignant neoplasm of anus, unspecified',
  'C211 Malignant neoplasm of anal canal',
  'C212 Malignant neoplasm of cloacogenic zone',
  'C218 Malignant neoplasm of overlapping sites of rectum, anus and anal canal',
  'C220 Liver cell carcinoma',
  'C221 Intrahepatic bile duct carcinoma',
  'C222 Hepatoblastoma',
  'C223 Angiosarcoma of liver',
  'C224 Other sarcomas of liver',
  'C227 Other specified carcinomas of liver',
  'C228 Malignant neoplasm of liver, primary, unspecified as to type',
  'C229 Malignant neoplasm of liver, not specified as primary or secondary',
  'C23 Malignant neoplasm of gallbladder',
  'C240 Malignant neoplasm of extrahepatic bile duct',
  'C241 Malignant neoplasm of ampulla of Vater',
  'C248 Malignant neoplasm of overlapping sites of biliary tract',
  'C249 Malignant neoplasm of biliary tract, unspecified',
  'C250 Malignant neoplasm of head of pancreas',
  'C251 Malignant neoplasm of body of pancreas',
  'C252 Malignant neoplasm of tail of pancreas',
  'C253 Malignant neoplasm of pancreatic duct',
  'C254 Malignant neoplasm of endocrine pancreas',
  'C257 Malignant neoplasm of other parts of pancreas',
  'C258 Malignant neoplasm of overlapping sites of pancreas',
  'C259 Malignant neoplasm of pancreas, unspecified',
  'C260 Malignant neoplasm of intestinal tract, part unspecified',
  'C261 Malignant neoplasm of spleen',
  'C269 Malignant neoplasm of ill-defined sites within the digestive system',
  'C300 Malignant neoplasm of nasal cavity',
  'C301 Malignant neoplasm of middle ear',
  'C310 Malignant neoplasm of maxillary sinus',
  'C311 Malignant neoplasm of ethmoidal sinus',
  'C312 Malignant neoplasm of frontal sinus',
  'C313 Malignant neoplasm of sphenoid sinus',
  'C318 Malignant neoplasm of overlapping sites of accessory sinuses',
  'C319 Malignant neoplasm of accessory sinus, unspecified',
  'C320 Malignant neoplasm of glottis',
  'C321 Malignant neoplasm of supraglottis',
  'C322 Malignant neoplasm of subglottis',
  'C323 Malignant neoplasm of laryngeal cartilage',
  'C328 Malignant neoplasm of overlapping sites of larynx',
  'C329 Malignant neoplasm of larynx, unspecified',
  'C33 Malignant neoplasm of trachea',
  'C3400 Malignant neoplasm of unspecified main bronchus',
  'C3401 Malignant neoplasm of right main bronchus',
  'C3402 Malignant neoplasm of left main bronchus',
  'C3410 Malignant neoplasm of upper lobe, unspecified bronchus or lung',
  'C3411 Malignant neoplasm of upper lobe, right bronchus or lung',
  'C3412 Malignant neoplasm of upper lobe, left bronchus or lung',
  'C342 Malignant neoplasm of middle lobe, bronchus or lung',
  'C3430 Malignant neoplasm of lower lobe, unspecified bronchus or lung',
  'C3431 Malignant neoplasm of lower lobe, right bronchus or lung',
  'C3432 Malignant neoplasm of lower lobe, left bronchus or lung',
  'C3480 Malignant neoplasm of overlapping sites of unspecified bronchus and lung',
  'C3481 Malignant neoplasm of overlapping sites of right bronchus and lung',
  'C3482 Malignant neoplasm of overlapping sites of left bronchus and lung',
  'C3490 Malignant neoplasm of unspecified part of unspecified bronchus or lung',
  'C3491 Malignant neoplasm of unspecified part of right bronchus or lung',
  'C3492 Malignant neoplasm of unspecified part of left bronchus or lung',
  'C37 Malignant neoplasm of thymus',
  'C380 Malignant neoplasm of heart',
  'C381 Malignant neoplasm of anterior mediastinum',
  'C382 Malignant neoplasm of posterior mediastinum',
  'C383 Malignant neoplasm of mediastinum, part unspecified',
  'C384 Malignant neoplasm of pleura',
  'C388 Malignant neoplasm of overlapping sites of heart, mediastinum and pleura',
  'C390 Malignant neoplasm of upper respiratory tract, part unspecified',
  'C399 Malignant neoplasm of lower respiratory tract, part unspecified',
  'C4000 Malignant neoplasm of scapula and long bones of unspecified upper limb',
  'C4001 Malignant neoplasm of scapula and long bones of right upper limb',
  'C4002 Malignant neoplasm of scapula and long bones of left upper limb',
  'C4010 Malignant neoplasm of short bones of unspecified upper limb',
  'C4011 Malignant neoplasm of short bones of right upper limb',
  'C4012 Malignant neoplasm of short bones of left upper limb',
  'C4020 Malignant neoplasm of long bones of unspecified lower limb',
  'C4021 Malignant neoplasm of long bones of right lower limb',
  'C4022 Malignant neoplasm of long bones of left lower limb',
  'C4030 Malignant neoplasm of short bones of unspecified lower limb',
  'C4031 Malignant neoplasm of short bones of right lower limb',
  'C4032 Malignant neoplasm of short bones of left lower limb',
  'C4080 Malignant neoplasm of overlapping sites of bone and articular cartilage of unspecified limb',
  'C4081 Malignant neoplasm of overlapping sites of bone and articular cartilage of right limb',
  'C4082 Malignant neoplasm of overlapping sites of bone and articular cartilage of left limb',
  'C4090 Malignant neoplasm of unspecified bones and articular cartilage of unspecified limb',
  'C4091 Malignant neoplasm of unspecified bones and articular cartilage of right limb',
  'C4092 Malignant neoplasm of unspecified bones and articular cartilage of left limb',
  'C410 Malignant neoplasm of bones of skull and face',
  'C411 Malignant neoplasm of mandible',
  'C412 Malignant neoplasm of vertebral column',
  'C413 Malignant neoplasm of ribs, sternum and clavicle',
  'C414 Malignant neoplasm of pelvic bones, sacrum and coccyx',
  'C419 Malignant neoplasm of bone and articular cartilage, unspecified',
  'C430 Malignant melanoma of lip',
  'C4310 Malignant melanoma of unspecified eyelid, including canthus',
  'C4311 Malignant melanoma of right eyelid, including canthus',
  'C4312 Malignant melanoma of left eyelid, including canthus',
  'C4320 Malignant melanoma of unspecified ear and external auricular canal',
  'C4321 Malignant melanoma of right ear and external auricular canal',
  'C4322 Malignant melanoma of left ear and external auricular canal',
  'C4330 Malignant melanoma of unspecified part of face',
  'C4331 Malignant melanoma of nose',
  'C4339 Malignant melanoma of other parts of face',
  'C434 Malignant melanoma of scalp and neck',
  'C4351 Malignant melanoma of anal skin',
  'C4352 Malignant melanoma of skin of breast',
  'C4359 Malignant melanoma of other part of trunk',
  'C4360 Malignant melanoma of unspecified upper limb, including shoulder',
  'C4361 Malignant melanoma of right upper limb, including shoulder',
  'C4362 Malignant melanoma of left upper limb, including shoulder',
  'C4370 Malignant melanoma of unspecified lower limb, including hip',
  'C4371 Malignant melanoma of right lower limb, including hip',
  'C4372 Malignant melanoma of left lower limb, including hip',
  'C438 Malignant melanoma of overlapping sites of skin',
  'C439 Malignant melanoma of skin, unspecified',
  'C4A0 Merkel cell carcinoma of lip',
  'C4A10 Merkel cell carcinoma of unspecified eyelid, including canthus',
  'C4A11 Merkel cell carcinoma of right eyelid, including canthus',
  'C4A12 Merkel cell carcinoma of left eyelid, including canthus',
  'C4A20 Merkel cell carcinoma of unspecified ear and external auricular canal',
  'C4A21 Merkel cell carcinoma of right ear and external auricular canal',
  'C4A22 Merkel cell carcinoma of left ear and external auricular canal',
  'C4A30 Merkel cell carcinoma of unspecified part of face',
  'C4A31 Merkel cell carcinoma of nose',
  'C4A39 Merkel cell carcinoma of other parts of face',
  'C4A4 Merkel cell carcinoma of scalp and neck',
  'C4A51 Merkel cell carcinoma of anal skin',
  'C4A52 Merkel cell carcinoma of skin of breast',
  'C4A59 Merkel cell carcinoma of other part of trunk',
  'C4A60 Merkel cell carcinoma of unspecified upper limb, including shoulder',
  'C4A61 Merkel cell carcinoma of right upper limb, including shoulder',
  'C4A62 Merkel cell carcinoma of left upper limb, including shoulder',
  'C4A70 Merkel cell carcinoma of unspecified lower limb, including hip',
  'C4A71 Merkel cell carcinoma of right lower limb, including hip',
  'C4A72 Merkel cell carcinoma of left lower limb, including hip',
  'C4A8 Merkel cell carcinoma of overlapping sites',
  'C4A9 Merkel cell carcinoma, unspecified',
  'C4400 Unspecified malignant neoplasm of skin of lip',
  'C4401 Basal cell carcinoma of skin of lip',
  'C4402 Squamous cell carcinoma of skin of lip',
  'C4409 Other specified malignant neoplasm of skin of lip',
  'C44101 Unspecified malignant neoplasm of skin of unspecified eyelid, including canthus',
  'C44102 Unspecified malignant neoplasm of skin of right eyelid, including canthus',
  'C44109 Unspecified malignant neoplasm of skin of left eyelid, including canthus',
  'C44111 Basal cell carcinoma of skin of unspecified eyelid, including canthus',
  'C44112 Basal cell carcinoma of skin of right eyelid, including canthus',
  'C44119 Basal cell carcinoma of skin of left eyelid, including canthus',
  'C44121 Squamous cell carcinoma of skin of unspecified eyelid, including canthus',
  'C44122 Squamous cell carcinoma of skin of right eyelid, including canthus',
  'C44129 Squamous cell carcinoma of skin of left eyelid, including canthus',
  'C44191 Other specified malignant neoplasm of skin of unspecified eyelid, including canthus',
  'C44192 Other specified malignant neoplasm of skin of right eyelid, including canthus',
  'C44199 Other specified malignant neoplasm of skin of left eyelid, including canthus',
  'C44201 Unspecified malignant neoplasm of skin of unspecified ear and external auricular canal',
  'C44202 Unspecified malignant neoplasm of skin of right ear and external auricular canal',
  'C44209 Unspecified malignant neoplasm of skin of left ear and external auricular canal',
  'C44211 Basal cell carcinoma of skin of unspecified ear and external auricular canal',
  'C44212 Basal cell carcinoma of skin of right ear and external auricular canal',
  'C44219 Basal cell carcinoma of skin of left ear and external auricular canal',
  'C44221 Squamous cell carcinoma of skin of unspecified ear and external auricular canal',
  'C44222 Squamous cell carcinoma of skin of right ear and external auricular canal',
  'C44229 Squamous cell carcinoma of skin of left ear and external auricular canal',
  'C44291 Other specified malignant neoplasm of skin of unspecified ear and external auricular canal',
  'C44292 Other specified malignant neoplasm of skin of right ear and external auricular canal',
  'C44299 Other specified malignant neoplasm of skin of left ear and external auricular canal',
  'C44300 Unspecified malignant neoplasm of skin of unspecified part of face',
  'C44301 Unspecified malignant neoplasm of skin of nose',
  'C44309 Unspecified malignant neoplasm of skin of other parts of face',
  'C44310 Basal cell carcinoma of skin of unspecified parts of face',
  'C44311 Basal cell carcinoma of skin of nose',
  'C44319 Basal cell carcinoma of skin of other parts of face',
  'C44320 Squamous cell carcinoma of skin of unspecified parts of face',
  'C44321 Squamous cell carcinoma of skin of nose',
  'C44329 Squamous cell carcinoma of skin of other parts of face',
  'C44390 Other specified malignant neoplasm of skin of unspecified parts of face',
  'C44391 Other specified malignant neoplasm of skin of nose',
  'C44399 Other specified malignant neoplasm of skin of other parts of face',
  'C4440 Unspecified malignant neoplasm of skin of scalp and neck',
  'C4441 Basal cell carcinoma of skin of scalp and neck',
  'C4442 Squamous cell carcinoma of skin of scalp and neck',
  'C4449 Other specified malignant neoplasm of skin of scalp and neck',
  'C44500 Unspecified malignant neoplasm of anal skin',
  'C44501 Unspecified malignant neoplasm of skin of breast',
  'C44509 Unspecified malignant neoplasm of skin of other part of trunk',
  'C44510 Basal cell carcinoma of anal skin',
  'C44511 Basal cell carcinoma of skin of breast',
  'C44519 Basal cell carcinoma of skin of other part of trunk',
  'C44520 Squamous cell carcinoma of anal skin',
  'C44521 Squamous cell carcinoma of skin of breast',
  'C44529 Squamous cell carcinoma of skin of other part of trunk',
  'C44590 Other specified malignant neoplasm of anal skin',
  'C44591 Other specified malignant neoplasm of skin of breast',
  'C44599 Other specified malignant neoplasm of skin of other part of trunk',
  'C44601 Unspecified malignant neoplasm of skin of unspecified upper limb, including shoulder',
  'C44602 Unspecified malignant neoplasm of skin of right upper limb, including shoulder',
  'C44609 Unspecified malignant neoplasm of skin of left upper limb, including shoulder',
  'C44611 Basal cell carcinoma of skin of unspecified upper limb, including shoulder',
  'C44612 Basal cell carcinoma of skin of right upper limb, including shoulder',
  'C44619 Basal cell carcinoma of skin of left upper limb, including shoulder',
  'C44621 Squamous cell carcinoma of skin of unspecified upper limb, including shoulder',
  'C44622 Squamous cell carcinoma of skin of right upper limb, including shoulder',
  'C44629 Squamous cell carcinoma of skin of left upper limb, including shoulder',
  'C44691 Other specified malignant neoplasm of skin of unspecified upper limb, including shoulder',
  'C44692 Other specified malignant neoplasm of skin of right upper limb, including shoulder',
  'C44699 Other specified malignant neoplasm of skin of left upper limb, including shoulder',
  'C44701 Unspecified malignant neoplasm of skin of unspecified lower limb, including hip',
  'C44702 Unspecified malignant neoplasm of skin of right lower limb, including hip',
  'C44709 Unspecified malignant neoplasm of skin of left lower limb, including hip',
  'C44711 Basal cell carcinoma of skin of unspecified lower limb, including hip',
  'C44712 Basal cell carcinoma of skin of right lower limb, including hip',
  'C44719 Basal cell carcinoma of skin of left lower limb, including hip',
  'C44721 Squamous cell carcinoma of skin of unspecified lower limb, including hip',
  'C44722 Squamous cell carcinoma of skin of right lower limb, including hip',
  'C44729 Squamous cell carcinoma of skin of left lower limb, including hip',
  'C44791 Other specified malignant neoplasm of skin of unspecified lower limb, including hip',
  'C44792 Other specified malignant neoplasm of skin of right lower limb, including hip',
  'C44799 Other specified malignant neoplasm of skin of left lower limb, including hip',
  'C4480 Unspecified malignant neoplasm of overlapping sites of skin',
  'C4481 Basal cell carcinoma of overlapping sites of skin',
  'C4482 Squamous cell carcinoma of overlapping sites of skin',
  'C4489 Other specified malignant neoplasm of overlapping sites of skin',
  'C4490 Unspecified malignant neoplasm of skin, unspecified',
  'C4491 Basal cell carcinoma of skin, unspecified',
  'C4492 Squamous cell carcinoma of skin, unspecified',
  'C4499 Other specified malignant neoplasm of skin, unspecified',
  'C450 Mesothelioma of pleura',
  'C451 Mesothelioma of peritoneum',
  'C452 Mesothelioma of pericardium',
  'C457 Mesothelioma of other sites',
  'C459 Mesothelioma, unspecified',
  "C460 Kaposi's sarcoma of skin",
  "C461 Kaposi's sarcoma of soft tissue",
  "C462 Kaposi's sarcoma of palate",
  "C463 Kaposi's sarcoma of lymph nodes",
  "C464 Kaposi's sarcoma of gastrointestinal sites",
  "C4650 Kaposi's sarcoma of unspecified lung",
  "C4651 Kaposi's sarcoma of right lung",
  "C4652 Kaposi's sarcoma of left lung",
  "C467 Kaposi's sarcoma of other sites",
  "C469 Kaposi's sarcoma, unspecified",
  'C470 Malignant neoplasm of peripheral nerves of head, face and neck',
  'C4710 Malignant neoplasm of peripheral nerves of unspecified upper limb, including shoulder',
  'C4711 Malignant neoplasm of peripheral nerves of right upper limb, including shoulder',
  'C4712 Malignant neoplasm of peripheral nerves of left upper limb, including shoulder',
  'C4720 Malignant neoplasm of peripheral nerves of unspecified lower limb, including hip',
  'C4721 Malignant neoplasm of peripheral nerves of right lower limb, including hip',
  'C4722 Malignant neoplasm of peripheral nerves of left lower limb, including hip',
  'C473 Malignant neoplasm of peripheral nerves of thorax',
  'C474 Malignant neoplasm of peripheral nerves of abdomen',
  'C475 Malignant neoplasm of peripheral nerves of pelvis',
  'C476 Malignant neoplasm of peripheral nerves of trunk, unspecified',
  'C478 Malignant neoplasm of overlapping sites of peripheral nerves and autonomic nervous system',
  'C479 Malignant neoplasm of peripheral nerves and autonomic nervous system, unspecified',
  'C480 Malignant neoplasm of retroperitoneum',
  'C481 Malignant neoplasm of specified parts of peritoneum',
  'C482 Malignant neoplasm of peritoneum, unspecified',
  'C488 Malignant neoplasm of overlapping sites of retroperitoneum and peritoneum',
  'C490 Malignant neoplasm of connective and soft tissue of head, face and neck',
  'C4910 Malignant neoplasm of connective and soft tissue of unspecified upper limb, including shoulder',
  'C4911 Malignant neoplasm of connective and soft tissue of right upper limb, including shoulder',
  'C4912 Malignant neoplasm of connective and soft tissue of left upper limb, including shoulder',
  'C4920 Malignant neoplasm of connective and soft tissue of unspecified lower limb, including hip',
  'C4921 Malignant neoplasm of connective and soft tissue of right lower limb, including hip',
  'C4922 Malignant neoplasm of connective and soft tissue of left lower limb, including hip',
  'C493 Malignant neoplasm of connective and soft tissue of thorax',
  'C494 Malignant neoplasm of connective and soft tissue of abdomen',
  'C495 Malignant neoplasm of connective and soft tissue of pelvis',
  'C496 Malignant neoplasm of connective and soft tissue of trunk, unspecified',
  'C498 Malignant neoplasm of overlapping sites of connective and soft tissue',
  'C499 Malignant neoplasm of connective and soft tissue, unspecified',
  'C49A0 Gastrointestinal stromal tumor, unspecified site',
  'C49A1 Gastrointestinal stromal tumor of esophagus',
  'C49A2 Gastrointestinal stromal tumor of stomach',
  'C49A3 Gastrointestinal stromal tumor of small intestine',
  'C49A4 Gastrointestinal stromal tumor of large intestine',
  'C49A5 Gastrointestinal stromal tumor of rectum',
  'C49A9 Gastrointestinal stromal tumor of other sites',
  'C50011 Malignant neoplasm of nipple and areola, right female breast',
  'C50012 Malignant neoplasm of nipple and areola, left female breast',
  'C50019 Malignant neoplasm of nipple and areola, unspecified female breast',
  'C50021 Malignant neoplasm of nipple and areola, right male breast',
  'C50022 Malignant neoplasm of nipple and areola, left male breast',
  'C50029 Malignant neoplasm of nipple and areola, unspecified male breast',
  'C50111 Malignant neoplasm of central portion of right female breast',
  'C50112 Malignant neoplasm of central portion of left female breast',
  'C50119 Malignant neoplasm of central portion of unspecified female breast',
  'C50121 Malignant neoplasm of central portion of right male breast',
  'C50122 Malignant neoplasm of central portion of left male breast',
  'C50129 Malignant neoplasm of central portion of unspecified male breast',
  'C50211 Malignant neoplasm of upper-inner quadrant of right female breast',
  'C50212 Malignant neoplasm of upper-inner quadrant of left female breast',
  'C50219 Malignant neoplasm of upper-inner quadrant of unspecified female breast',
  'C50221 Malignant neoplasm of upper-inner quadrant of right male breast',
  'C50222 Malignant neoplasm of upper-inner quadrant of left male breast',
  'C50229 Malignant neoplasm of upper-inner quadrant of unspecified male breast',
  'C50311 Malignant neoplasm of lower-inner quadrant of right female breast',
  'C50312 Malignant neoplasm of lower-inner quadrant of left female breast',
  'C50319 Malignant neoplasm of lower-inner quadrant of unspecified female breast',
  'C50321 Malignant neoplasm of lower-inner quadrant of right male breast',
  'C50322 Malignant neoplasm of lower-inner quadrant of left male breast',
  'C50329 Malignant neoplasm of lower-inner quadrant of unspecified male breast',
  'C50411 Malignant neoplasm of upper-outer quadrant of right female breast',
  'C50412 Malignant neoplasm of upper-outer quadrant of left female breast',
  'C50419 Malignant neoplasm of upper-outer quadrant of unspecified female breast',
  'C50421 Malignant neoplasm of upper-outer quadrant of right male breast',
  'C50422 Malignant neoplasm of upper-outer quadrant of left male breast',
  'C50429 Malignant neoplasm of upper-outer quadrant of unspecified male breast',
  'C50511 Malignant neoplasm of lower-outer quadrant of right female breast',
  'C50512 Malignant neoplasm of lower-outer quadrant of left female breast',
  'C50519 Malignant neoplasm of lower-outer quadrant of unspecified female breast',
  'C50521 Malignant neoplasm of lower-outer quadrant of right male breast',
  'C50522 Malignant neoplasm of lower-outer quadrant of left male breast',
  'C50529 Malignant neoplasm of lower-outer quadrant of unspecified male breast',
  'C50611 Malignant neoplasm of axillary tail of right female breast',
  'C50612 Malignant neoplasm of axillary tail of left female breast',
  'C50619 Malignant neoplasm of axillary tail of unspecified female breast',
  'C50621 Malignant neoplasm of axillary tail of right male breast',
  'C50622 Malignant neoplasm of axillary tail of left male breast',
  'C50629 Malignant neoplasm of axillary tail of unspecified male breast',
  'C50811 Malignant neoplasm of overlapping sites of right female breast',
  'C50812 Malignant neoplasm of overlapping sites of left female breast',
  'C50819 Malignant neoplasm of overlapping sites of unspecified female breast',
  'C50821 Malignant neoplasm of overlapping sites of right male breast',
  'C50822 Malignant neoplasm of overlapping sites of left male breast',
  'C50829 Malignant neoplasm of overlapping sites of unspecified male breast',
  'C50911 Malignant neoplasm of unspecified site of right female breast',
  'C50912 Malignant neoplasm of unspecified site of left female breast',
  'C50919 Malignant neoplasm of unspecified site of unspecified female breast',
  'C50921 Malignant neoplasm of unspecified site of right male breast',
  'C50922 Malignant neoplasm of unspecified site of left male breast',
  'C50929 Malignant neoplasm of unspecified site of unspecified male breast',
  'C510 Malignant neoplasm of labium majus',
  'C511 Malignant neoplasm of labium minus',
  'C512 Malignant neoplasm of clitoris',
  'C518 Malignant neoplasm of overlapping sites of vulva',
  'C519 Malignant neoplasm of vulva, unspecified',
  'C52 Malignant neoplasm of vagina',
  'C530 Malignant neoplasm of endocervix',
  'C531 Malignant neoplasm of exocervix',
  'C538 Malignant neoplasm of overlapping sites of cervix uteri',
  'C539 Malignant neoplasm of cervix uteri, unspecified',
  'C540 Malignant neoplasm of isthmus uteri',
  'C541 Malignant neoplasm of endometrium',
  'C542 Malignant neoplasm of myometrium',
  'C543 Malignant neoplasm of fundus uteri',
  'C548 Malignant neoplasm of overlapping sites of corpus uteri',
  'C549 Malignant neoplasm of corpus uteri, unspecified',
  'C55 Malignant neoplasm of uterus, part unspecified',
  'C561 Malignant neoplasm of right ovary',
  'C562 Malignant neoplasm of left ovary',
  'C569 Malignant neoplasm of unspecified ovary',
  'C5700 Malignant neoplasm of unspecified fallopian tube',
  'C5701 Malignant neoplasm of right fallopian tube',
  'C5702 Malignant neoplasm of left fallopian tube',
  'C5710 Malignant neoplasm of unspecified broad ligament',
  'C5711 Malignant neoplasm of right broad ligament',
  'C5712 Malignant neoplasm of left broad ligament',
  'C5720 Malignant neoplasm of unspecified round ligament',
  'C5721 Malignant neoplasm of right round ligament',
  'C5722 Malignant neoplasm of left round ligament',
  'C573 Malignant neoplasm of parametrium',
  'C574 Malignant neoplasm of uterine adnexa, unspecified',
  'C577 Malignant neoplasm of other specified female genital organs',
  'C578 Malignant neoplasm of overlapping sites of female genital organs',
  'C579 Malignant neoplasm of female genital organ, unspecified',
  'C58 Malignant neoplasm of placenta',
  'C600 Malignant neoplasm of prepuce',
  'C601 Malignant neoplasm of glans penis',
  'C602 Malignant neoplasm of body of penis',
  'C608 Malignant neoplasm of overlapping sites of penis',
  'C609 Malignant neoplasm of penis, unspecified',
  'C61 Malignant neoplasm of prostate',
  'C6200 Malignant neoplasm of unspecified undescended testis',
  'C6201 Malignant neoplasm of undescended right testis',
  'C6202 Malignant neoplasm of undescended left testis',
  'C6210 Malignant neoplasm of unspecified descended testis',
  'C6211 Malignant neoplasm of descended right testis',
  'C6212 Malignant neoplasm of descended left testis',
  'C6290 Malignant neoplasm of unspecified testis, unspecified whether descended or undescended',
  'C6291 Malignant neoplasm of right testis, unspecified whether descended or undescended',
  'C6292 Malignant neoplasm of left testis, unspecified whether descended or undescended',
  'C6300 Malignant neoplasm of unspecified epididymis',
  'C6301 Malignant neoplasm of right epididymis',
  'C6302 Malignant neoplasm of left epididymis',
  'C6310 Malignant neoplasm of unspecified spermatic cord',
  'C6311 Malignant neoplasm of right spermatic cord',
  'C6312 Malignant neoplasm of left spermatic cord',
  'C632 Malignant neoplasm of scrotum',
  'C637 Malignant neoplasm of other specified male genital organs',
  'C638 Malignant neoplasm of overlapping sites of male genital organs',
  'C639 Malignant neoplasm of male genital organ, unspecified',
  'C641 Malignant neoplasm of right kidney, except renal pelvis',
  'C642 Malignant neoplasm of left kidney, except renal pelvis',
  'C649 Malignant neoplasm of unspecified kidney, except renal pelvis',
  'C651 Malignant neoplasm of right renal pelvis',
  'C652 Malignant neoplasm of left renal pelvis',
  'C659 Malignant neoplasm of unspecified renal pelvis',
  'C661 Malignant neoplasm of right ureter',
  'C662 Malignant neoplasm of left ureter',
  'C669 Malignant neoplasm of unspecified ureter',
  'C670 Malignant neoplasm of trigone of bladder',
  'C671 Malignant neoplasm of dome of bladder',
  'C672 Malignant neoplasm of lateral wall of bladder',
  'C673 Malignant neoplasm of anterior wall of bladder',
  'C674 Malignant neoplasm of posterior wall of bladder',
  'C675 Malignant neoplasm of bladder neck',
  'C676 Malignant neoplasm of ureteric orifice',
  'C677 Malignant neoplasm of urachus',
  'C678 Malignant neoplasm of overlapping sites of bladder',
  'C679 Malignant neoplasm of bladder, unspecified',
  'C680 Malignant neoplasm of urethra',
  'C681 Malignant neoplasm of paraurethral glands',
  'C688 Malignant neoplasm of overlapping sites of urinary organs',
  'C689 Malignant neoplasm of urinary organ, unspecified',
  'C6900 Malignant neoplasm of unspecified conjunctiva',
  'C6901 Malignant neoplasm of right conjunctiva',
  'C6902 Malignant neoplasm of left conjunctiva',
  'C6910 Malignant neoplasm of unspecified cornea',
  'C6911 Malignant neoplasm of right cornea',
  'C6912 Malignant neoplasm of left cornea',
  'C6920 Malignant neoplasm of unspecified retina',
  'C6921 Malignant neoplasm of right retina',
  'C6922 Malignant neoplasm of left retina',
  'C6930 Malignant neoplasm of unspecified choroid',
  'C6931 Malignant neoplasm of right choroid',
  'C6932 Malignant neoplasm of left choroid',
  'C6940 Malignant neoplasm of unspecified ciliary body',
  'C6941 Malignant neoplasm of right ciliary body',
  'C6942 Malignant neoplasm of left ciliary body',
  'C6950 Malignant neoplasm of unspecified lacrimal gland and duct',
  'C6951 Malignant neoplasm of right lacrimal gland and duct',
  'C6952 Malignant neoplasm of left lacrimal gland and duct',
  'C6960 Malignant neoplasm of unspecified orbit',
  'C6961 Malignant neoplasm of right orbit',
  'C6962 Malignant neoplasm of left orbit',
  'C6980 Malignant neoplasm of overlapping sites of unspecified eye and adnexa',
  'C6981 Malignant neoplasm of overlapping sites of right eye and adnexa',
  'C6982 Malignant neoplasm of overlapping sites of left eye and adnexa',
  'C6990 Malignant neoplasm of unspecified site of unspecified eye',
  'C6991 Malignant neoplasm of unspecified site of right eye',
  'C6992 Malignant neoplasm of unspecified site of left eye',
  'C700 Malignant neoplasm of cerebral meninges',
  'C701 Malignant neoplasm of spinal meninges',
  'C709 Malignant neoplasm of meninges, unspecified',
  'C710 Malignant neoplasm of cerebrum, except lobes and ventricles',
  'C711 Malignant neoplasm of frontal lobe',
  'C712 Malignant neoplasm of temporal lobe',
  'C713 Malignant neoplasm of parietal lobe',
  'C714 Malignant neoplasm of occipital lobe',
  'C715 Malignant neoplasm of cerebral ventricle',
  'C716 Malignant neoplasm of cerebellum',
  'C717 Malignant neoplasm of brain stem',
  'C718 Malignant neoplasm of overlapping sites of brain',
  'C719 Malignant neoplasm of brain, unspecified',
  'C720 Malignant neoplasm of spinal cord',
  'C721 Malignant neoplasm of cauda equina',
  'C7220 Malignant neoplasm of unspecified olfactory nerve',
  'C7221 Malignant neoplasm of right olfactory nerve',
  'C7222 Malignant neoplasm of left olfactory nerve',
  'C7230 Malignant neoplasm of unspecified optic nerve',
  'C7231 Malignant neoplasm of right optic nerve',
  'C7232 Malignant neoplasm of left optic nerve',
  'C7240 Malignant neoplasm of unspecified acoustic nerve',
  'C7241 Malignant neoplasm of right acoustic nerve',
  'C7242 Malignant neoplasm of left acoustic nerve',
  'C7250 Malignant neoplasm of unspecified cranial nerve',
  'C7259 Malignant neoplasm of other cranial nerves',
  'C729 Malignant neoplasm of central nervous system, unspecified',
  'C73 Malignant neoplasm of thyroid gland',
  'C7400 Malignant neoplasm of cortex of unspecified adrenal gland',
  'C7401 Malignant neoplasm of cortex of right adrenal gland',
  'C7402 Malignant neoplasm of cortex of left adrenal gland',
  'C7410 Malignant neoplasm of medulla of unspecified adrenal gland',
  'C7411 Malignant neoplasm of medulla of right adrenal gland',
  'C7412 Malignant neoplasm of medulla of left adrenal gland',
  'C7490 Malignant neoplasm of unspecified part of unspecified adrenal gland',
  'C7491 Malignant neoplasm of unspecified part of right adrenal gland',
  'C7492 Malignant neoplasm of unspecified part of left adrenal gland',
  'C750 Malignant neoplasm of parathyroid gland',
  'C751 Malignant neoplasm of pituitary gland',
  'C752 Malignant neoplasm of craniopharyngeal duct',
  'C753 Malignant neoplasm of pineal gland',
  'C754 Malignant neoplasm of carotid body',
  'C755 Malignant neoplasm of aortic body and other paraganglia',
  'C758 Malignant neoplasm with pluriglandular involvement, unspecified',
  'C759 Malignant neoplasm of endocrine gland, unspecified',
  'C7A00 Malignant carcinoid tumor of unspecified site',
  'C7A010 Malignant carcinoid tumor of the duodenum',
  'C7A011 Malignant carcinoid tumor of the jejunum',
  'C7A012 Malignant carcinoid tumor of the ileum',
  'C7A019 Malignant carcinoid tumor of the small intestine, unspecified portion',
  'C7A020 Malignant carcinoid tumor of the appendix',
  'C7A021 Malignant carcinoid tumor of the cecum',
  'C7A022 Malignant carcinoid tumor of the ascending colon',
  'C7A023 Malignant carcinoid tumor of the transverse colon',
  'C7A024 Malignant carcinoid tumor of the descending colon',
  'C7A025 Malignant carcinoid tumor of the sigmoid colon',
  'C7A026 Malignant carcinoid tumor of the rectum',
  'C7A029 Malignant carcinoid tumor of the large intestine, unspecified portion',
  'C7A090 Malignant carcinoid tumor of the bronchus and lung',
  'C7A091 Malignant carcinoid tumor of the thymus',
  'C7A092 Malignant carcinoid tumor of the stomach',
  'C7A093 Malignant carcinoid tumor of the kidney',
  'C7A094 Malignant carcinoid tumor of the foregut, unspecified',
  'C7A095 Malignant carcinoid tumor of the midgut, unspecified',
  'C7A096 Malignant carcinoid tumor of the hindgut, unspecified',
  'C7A098 Malignant carcinoid tumors of other sites',
  'C7A1 Malignant poorly differentiated neuroendocrine tumors',
  'C7A8 Other malignant neuroendocrine tumors',
  'C7B00 Secondary carcinoid tumors, unspecified site',
  'C7B01 Secondary carcinoid tumors of distant lymph nodes',
  'C7B02 Secondary carcinoid tumors of liver',
  'C7B03 Secondary carcinoid tumors of bone',
  'C7B04 Secondary carcinoid tumors of peritoneum',
  'C7B09 Secondary carcinoid tumors of other sites',
  'C7B1 Secondary Merkel cell carcinoma',
  'C7B8 Other secondary neuroendocrine tumors',
  'C760 Malignant neoplasm of head, face and neck',
  'C761 Malignant neoplasm of thorax',
  'C762 Malignant neoplasm of abdomen',
  'C763 Malignant neoplasm of pelvis',
  'C7640 Malignant neoplasm of unspecified upper limb',
  'C7641 Malignant neoplasm of right upper limb',
  'C7642 Malignant neoplasm of left upper limb',
  'C7650 Malignant neoplasm of unspecified lower limb',
  'C7651 Malignant neoplasm of right lower limb',
  'C7652 Malignant neoplasm of left lower limb',
  'C768 Malignant neoplasm of other specified ill-defined sites',
  'C770 Secondary and unspecified malignant neoplasm of lymph nodes of head, face and neck',
  'C771 Secondary and unspecified malignant neoplasm of intrathoracic lymph nodes',
  'C772 Secondary and unspecified malignant neoplasm of intra-abdominal lymph nodes',
  'C773 Secondary and unspecified malignant neoplasm of axilla and upper limb lymph nodes',
  'C774 Secondary and unspecified malignant neoplasm of inguinal and lower limb lymph nodes',
  'C775 Secondary and unspecified malignant neoplasm of intrapelvic lymph nodes',
  'C778 Secondary and unspecified malignant neoplasm of lymph nodes of multiple regions',
  'C779 Secondary and unspecified malignant neoplasm of lymph node, unspecified',
  'C7800 Secondary malignant neoplasm of unspecified lung',
  'C7801 Secondary malignant neoplasm of right lung',
  'C7802 Secondary malignant neoplasm of left lung',
  'C781 Secondary malignant neoplasm of mediastinum',
  'C782 Secondary malignant neoplasm of pleura',
  'C7830 Secondary malignant neoplasm of unspecified respiratory organ',
  'C7839 Secondary malignant neoplasm of other respiratory organs',
  'C784 Secondary malignant neoplasm of small intestine',
  'C785 Secondary malignant neoplasm of large intestine and rectum',
  'C786 Secondary malignant neoplasm of retroperitoneum and peritoneum',
  'C787 Secondary malignant neoplasm of liver and intrahepatic bile duct',
  'C7880 Secondary malignant neoplasm of unspecified digestive organ',
  'C7889 Secondary malignant neoplasm of other digestive organs',
  'C7900 Secondary malignant neoplasm of unspecified kidney and renal pelvis',
  'C7901 Secondary malignant neoplasm of right kidney and renal pelvis',
  'C7902 Secondary malignant neoplasm of left kidney and renal pelvis',
  'C7910 Secondary malignant neoplasm of unspecified urinary organs',
  'C7911 Secondary malignant neoplasm of bladder',
  'C7919 Secondary malignant neoplasm of other urinary organs',
  'C792 Secondary malignant neoplasm of skin',
  'C7931 Secondary malignant neoplasm of brain',
  'C7932 Secondary malignant neoplasm of cerebral meninges',
  'C7940 Secondary malignant neoplasm of unspecified part of nervous system',
  'C7949 Secondary malignant neoplasm of other parts of nervous system',
  'C7951 Secondary malignant neoplasm of bone',
  'C7952 Secondary malignant neoplasm of bone marrow',
  'C7960 Secondary malignant neoplasm of unspecified ovary',
  'C7961 Secondary malignant neoplasm of right ovary',
  'C7962 Secondary malignant neoplasm of left ovary',
  'C7970 Secondary malignant neoplasm of unspecified adrenal gland',
  'C7971 Secondary malignant neoplasm of right adrenal gland',
  'C7972 Secondary malignant neoplasm of left adrenal gland',
  'C7981 Secondary malignant neoplasm of breast',
  'C7982 Secondary malignant neoplasm of genital organs',
  'C7989 Secondary malignant neoplasm of other specified sites',
  'C799 Secondary malignant neoplasm of unspecified site',
  'C800 Disseminated malignant neoplasm, unspecified',
  'C801 Malignant (primary) neoplasm, unspecified',
  'C802 Malignant neoplasm associated with transplanted organ',
  'C8100 Nodular lymphocyte predominant Hodgkin lymphoma, unspecified site',
  'C8101 Nodular lymphocyte predominant Hodgkin lymphoma, lymph nodes of head, face, and neck',
  'C8102 Nodular lymphocyte predominant Hodgkin lymphoma, intrathoracic lymph nodes',
  'C8103 Nodular lymphocyte predominant Hodgkin lymphoma, intra-abdominal lymph nodes',
  'C8104 Nodular lymphocyte predominant Hodgkin lymphoma, lymph nodes of axilla and upper limb',
  'C8105 Nodular lymphocyte predominant Hodgkin lymphoma, lymph nodes of inguinal region and lower',
  'limb',
  'C8106 Nodular lymphocyte predominant Hodgkin lymphoma, intrapelvic lymph nodes',
  'C8107 Nodular lymphocyte predominant Hodgkin lymphoma, spleen',
  'C8108 Nodular lymphocyte predominant Hodgkin lymphoma, lymph nodes of multiple sites',
  'C8109 Nodular lymphocyte predominant Hodgkin lymphoma, extranodal and solid organ sites',
  'C8110 Nodular sclerosis Hodgkin lymphoma, unspecified site',
  'C8111 Nodular sclerosis Hodgkin lymphoma, lymph nodes of head, face, and neck',
  'C8112 Nodular sclerosis Hodgkin lymphoma, intrathoracic lymph nodes',
  'C8113 Nodular sclerosis Hodgkin lymphoma, intra-abdominal lymph nodes',
  'C8114 Nodular sclerosis Hodgkin lymphoma, lymph nodes of axilla and upper limb',
  'C8115 Nodular sclerosis Hodgkin lymphoma, lymph nodes of inguinal region and lower limb',
  'C8116 Nodular sclerosis Hodgkin lymphoma, intrapelvic lymph nodes',
  'C8117 Nodular sclerosis Hodgkin lymphoma, spleen',
  'C8118 Nodular sclerosis Hodgkin lymphoma, lymph nodes of multiple sites',
  'C8119 Nodular sclerosis Hodgkin lymphoma, extranodal and solid organ sites',
  'C8120 Mixed cellularity Hodgkin lymphoma, unspecified site',
  'C8121 Mixed cellularity Hodgkin lymphoma, lymph nodes of head, face, and neck',
  'C8122 Mixed cellularity Hodgkin lymphoma, intrathoracic lymph nodes',
  'C8123 Mixed cellularity Hodgkin lymphoma, intra-abdominal lymph nodes',
  'C8124 Mixed cellularity Hodgkin lymphoma, lymph nodes of axilla and upper limb',
  'C8125 Mixed cellularity Hodgkin lymphoma, lymph nodes of inguinal region and lower limb',
  'C8126 Mixed cellularity Hodgkin lymphoma, intrapelvic lymph nodes',
  'C8127 Mixed cellularity Hodgkin lymphoma, spleen',
  'C8128 Mixed cellularity Hodgkin lymphoma, lymph nodes of multiple sites',
  'C8129 Mixed cellularity Hodgkin lymphoma, extranodal and solid organ sites',
  'C8130 Lymphocyte depleted Hodgkin lymphoma, unspecified site',
  'C8131 Lymphocyte depleted Hodgkin lymphoma, lymph nodes of head, face, and neck',
  'C8132 Lymphocyte depleted Hodgkin lymphoma, intrathoracic lymph nodes',
  'C8133 Lymphocyte depleted Hodgkin lymphoma, intra-abdominal lymph nodes',
  'C8134 Lymphocyte depleted Hodgkin lymphoma, lymph nodes of axilla and upper limb',
  'C8135 Lymphocyte depleted Hodgkin lymphoma, lymph nodes of inguinal region and lower limb',
  'C8136 Lymphocyte depleted Hodgkin lymphoma, intrapelvic lymph nodes',
  'C8137 Lymphocyte depleted Hodgkin lymphoma, spleen',
  'C8138 Lymphocyte depleted Hodgkin lymphoma, lymph nodes of multiple sites',
  'C8139 Lymphocyte depleted Hodgkin lymphoma, extranodal and solid organ sites',
  'C8140 Lymphocyte-rich Hodgkin lymphoma, unspecified site',
  'C8141 Lymphocyte-rich Hodgkin lymphoma, lymph nodes of head, face, and neck',
  'C8142 Lymphocyte-rich Hodgkin lymphoma, intrathoracic lymph nodes',
  'C8143 Lymphocyte-rich Hodgkin lymphoma, intra-abdominal lymph nodes',
  'C8144 Lymphocyte-rich Hodgkin lymphoma, lymph nodes of axilla and upper limb',
  'C8145 Lymphocyte-rich Hodgkin lymphoma, lymph nodes of inguinal region and lower limb',
  'C8146 Lymphocyte-rich Hodgkin lymphoma, intrapelvic lymph nodes',
  'C8147 Lymphocyte-rich Hodgkin lymphoma, spleen',
  'C8148 Lymphocyte-rich Hodgkin lymphoma, lymph nodes of multiple sites',
  'C8149 Lymphocyte-rich Hodgkin lymphoma, extranodal and solid organ sites',
  'C8170 Other Hodgkin lymphoma, unspecified site',
  'C8171 Other Hodgkin lymphoma, lymph nodes of head, face, and neck',
  'C8172 Other Hodgkin lymphoma, intrathoracic lymph nodes',
  'C8173 Other Hodgkin lymphoma, intra-abdominal lymph nodes',
  'C8174 Other Hodgkin lymphoma, lymph nodes of axilla and upper limb',
  'C8175 Other Hodgkin lymphoma, lymph nodes of inguinal region and lower limb',
  'C8176 Other Hodgkin lymphoma, intrapelvic lymph nodes',
  'C8177 Other Hodgkin lymphoma, spleen',
  'C8178 Other Hodgkin lymphoma, lymph nodes of multiple sites',
  'C8179 Other Hodgkin lymphoma, extranodal and solid organ sites',
  'C8190 Hodgkin lymphoma, unspecified, unspecified site',
  'C8191 Hodgkin lymphoma, unspecified, lymph nodes of head, face, and neck',
  'C8192 Hodgkin lymphoma, unspecified, intrathoracic lymph nodes',
  'C8193 Hodgkin lymphoma, unspecified, intra-abdominal lymph nodes',
  'C8194 Hodgkin lymphoma, unspecified, lymph nodes of axilla and upper limb',
  'C8195 Hodgkin lymphoma, unspecified, lymph nodes of inguinal region and lower limb',
  'C8196 Hodgkin lymphoma, unspecified, intrapelvic lymph nodes',
  'C8197 Hodgkin lymphoma, unspecified, spleen',
  'C8198 Hodgkin lymphoma, unspecified, lymph nodes of multiple sites',
  'C8199 Hodgkin lymphoma, unspecified, extranodal and solid organ sites',
  'C8200 Follicular lymphoma grade I, unspecified site',
  'C8201 Follicular lymphoma grade I, lymph nodes of head, face, and neck',
  'C8202 Follicular lymphoma grade I, intrathoracic lymph nodes',
  'C8203 Follicular lymphoma grade I, intra-abdominal lymph nodes',
  'C8204 Follicular lymphoma grade I, lymph nodes of axilla and upper limb',
  'C8205 Follicular lymphoma grade I, lymph nodes of inguinal region and lower limb',
  'C8206 Follicular lymphoma grade I, intrapelvic lymph nodes',
  'C8207 Follicular lymphoma grade I, spleen',
  'C8208 Follicular lymphoma grade I, lymph nodes of multiple sites',
  'C8209 Follicular lymphoma grade I, extranodal and solid organ sites',
  'C8210 Follicular lymphoma grade II, unspecified site',
  'C8211 Follicular lymphoma grade II, lymph nodes of head, face, and neck',
  'C8212 Follicular lymphoma grade II, intrathoracic lymph nodes',
  'C8213 Follicular lymphoma grade II, intra-abdominal lymph nodes',
  'C8214 Follicular lymphoma grade II, lymph nodes of axilla and upper limb',
  'C8215 Follicular lymphoma grade II, lymph nodes of inguinal region and lower limb',
  'C8216 Follicular lymphoma grade II, intrapelvic lymph nodes',
  'C8217 Follicular lymphoma grade II, spleen',
  'C8218 Follicular lymphoma grade II, lymph nodes of multiple sites',
  'C8219 Follicular lymphoma grade II, extranodal and solid organ sites',
  'C8220 Follicular lymphoma grade III, unspecified, unspecified site',
  'C8221 Follicular lymphoma grade III, unspecified, lymph nodes of head, face, and neck',
  'C8222 Follicular lymphoma grade III, unspecified, intrathoracic lymph nodes',
  'C8223 Follicular lymphoma grade III, unspecified, intra-abdominal lymph nodes',
  'C8224 Follicular lymphoma grade III, unspecified, lymph nodes of axilla and upper limb',
  'C8225 Follicular lymphoma grade III, unspecified, lymph nodes of inguinal region and lower limb',
  'C8226 Follicular lymphoma grade III, unspecified, intrapelvic lymph nodes',
  'C8227 Follicular lymphoma grade III, unspecified, spleen',
  'C8228 Follicular lymphoma grade III, unspecified, lymph nodes of multiple sites',
  'C8229 Follicular lymphoma grade III, unspecified, extranodal and solid organ sites',
  'C8230 Follicular lymphoma grade IIIa, unspecified site',
  'C8231 Follicular lymphoma grade IIIa, lymph nodes of head, face, and neck',
  'C8232 Follicular lymphoma grade IIIa, intrathoracic lymph nodes',
  'C8233 Follicular lymphoma grade IIIa, intra-abdominal lymph nodes',
  'C8234 Follicular lymphoma grade IIIa, lymph nodes of axilla and upper limb',
  'C8235 Follicular lymphoma grade IIIa, lymph nodes of inguinal region and lower limb',
  'C8236 Follicular lymphoma grade IIIa, intrapelvic lymph nodes',
  'C8237 Follicular lymphoma grade IIIa, spleen',
  'C8238 Follicular lymphoma grade IIIa, lymph nodes of multiple sites',
  'C8239 Follicular lymphoma grade IIIa, extranodal and solid organ sites',
  'C8240 Follicular lymphoma grade IIIb, unspecified site',
  'C8241 Follicular lymphoma grade IIIb, lymph nodes of head, face, and neck',
  'C8242 Follicular lymphoma grade IIIb, intrathoracic lymph nodes',
  'C8243 Follicular lymphoma grade IIIb, intra-abdominal lymph nodes',
  'C8244 Follicular lymphoma grade IIIb, lymph nodes of axilla and upper limb',
  'C8245 Follicular lymphoma grade IIIb, lymph nodes of inguinal region and lower limb',
  'C8246 Follicular lymphoma grade IIIb, intrapelvic lymph nodes',
  'C8247 Follicular lymphoma grade IIIb, spleen',
  'C8248 Follicular lymphoma grade IIIb, lymph nodes of multiple sites',
  'C8249 Follicular lymphoma grade IIIb, extranodal and solid organ sites',
  'C8250 Diffuse follicle center lymphoma, unspecified site',
  'C8251 Diffuse follicle center lymphoma, lymph nodes of head, face, and neck',
  'C8252 Diffuse follicle center lymphoma, intrathoracic lymph nodes',
  'C8253 Diffuse follicle center lymphoma, intra-abdominal lymph nodes',
  'C8254 Diffuse follicle center lymphoma, lymph nodes of axilla and upper limb',
  'C8255 Diffuse follicle center lymphoma, lymph nodes of inguinal region and lower limb',
  'C8256 Diffuse follicle center lymphoma, intrapelvic lymph nodes',
  'C8257 Diffuse follicle center lymphoma, spleen',
  'C8258 Diffuse follicle center lymphoma, lymph nodes of multiple sites',
  'C8259 Diffuse follicle center lymphoma, extranodal and solid organ sites',
  'C8260 Cutaneous follicle center lymphoma, unspecified site',
  'C8261 Cutaneous follicle center lymphoma, lymph nodes of head, face, and neck',
  'C8262 Cutaneous follicle center lymphoma, intrathoracic lymph nodes',
  'C8263 Cutaneous follicle center lymphoma, intra-abdominal lymph nodes',
  'C8264 Cutaneous follicle center lymphoma, lymph nodes of axilla and upper limb',
  'C8265 Cutaneous follicle center lymphoma, lymph nodes of inguinal region and lower limb',
  'C8266 Cutaneous follicle center lymphoma, intrapelvic lymph nodes',
  'C8267 Cutaneous follicle center lymphoma, spleen',
  'C8268 Cutaneous follicle center lymphoma, lymph nodes of multiple sites',
  'C8269 Cutaneous follicle center lymphoma, extranodal and solid organ sites',
  'C8280 Other types of follicular lymphoma, unspecified site',
  'C8281 Other types of follicular lymphoma, lymph nodes of head, face, and neck',
  'C8282 Other types of follicular lymphoma, intrathoracic lymph nodes',
  'C8283 Other types of follicular lymphoma, intra-abdominal lymph nodes',
  'C8284 Other types of follicular lymphoma, lymph nodes of axilla and upper limb',
  'C8285 Other types of follicular lymphoma, lymph nodes of inguinal region and lower limb',
  'C8286 Other types of follicular lymphoma, intrapelvic lymph nodes',
  'C8287 Other types of follicular lymphoma, spleen',
  'C8288 Other types of follicular lymphoma, lymph nodes of multiple sites',
  'C8289 Other types of follicular lymphoma, extranodal and solid organ sites',
  'C8290 Follicular lymphoma, unspecified, unspecified site',
  'C8291 Follicular lymphoma, unspecified, lymph nodes of head, face, and neck',
  'C8292 Follicular lymphoma, unspecified, intrathoracic lymph nodes',
  'C8293 Follicular lymphoma, unspecified, intra-abdominal lymph nodes',
  'C8294 Follicular lymphoma, unspecified, lymph nodes of axilla and upper limb',
  'C8295 Follicular lymphoma, unspecified, lymph nodes of inguinal region and lower limb',
  'C8296 Follicular lymphoma, unspecified, intrapelvic lymph nodes',
  'C8297 Follicular lymphoma, unspecified, spleen',
  'C8298 Follicular lymphoma, unspecified, lymph nodes of multiple sites',
  'C8299 Follicular lymphoma, unspecified, extranodal and solid organ sites',
  'C8300 Small cell B-cell lymphoma, unspecified site',
  'C8301 Small cell B-cell lymphoma, lymph nodes of head, face, and neck',
  'C8302 Small cell B-cell lymphoma, intrathoracic lymph nodes',
  'C8303 Small cell B-cell lymphoma, intra-abdominal lymph nodes',
  'C8304 Small cell B-cell lymphoma, lymph nodes of axilla and upper limb',
  'C8305 Small cell B-cell lymphoma, lymph nodes of inguinal region and lower limb',
  'C8306 Small cell B-cell lymphoma, intrapelvic lymph nodes',
  'C8307 Small cell B-cell lymphoma, spleen',
  'C8308 Small cell B-cell lymphoma, lymph nodes of multiple sites',
  'C8309 Small cell B-cell lymphoma, extranodal and solid organ sites',
  'C8310 Mantle cell lymphoma, unspecified site',
  'C8311 Mantle cell lymphoma, lymph nodes of head, face, and neck',
  'C8312 Mantle cell lymphoma, intrathoracic lymph nodes',
  'C8313 Mantle cell lymphoma, intra-abdominal lymph nodes',
  'C8314 Mantle cell lymphoma, lymph nodes of axilla and upper limb',
  'C8315 Mantle cell lymphoma, lymph nodes of inguinal region and lower limb',
  'C8316 Mantle cell lymphoma, intrapelvic lymph nodes',
  'C8317 Mantle cell lymphoma, spleen',
  'C8318 Mantle cell lymphoma, lymph nodes of multiple sites',
  'C8319 Mantle cell lymphoma, extranodal and solid organ sites',
  'C8330 Diffuse large B-cell lymphoma, unspecified site',
  'C8331 Diffuse large B-cell lymphoma, lymph nodes of head, face, and neck',
  'C8332 Diffuse large B-cell lymphoma, intrathoracic lymph nodes',
  'C8333 Diffuse large B-cell lymphoma, intra-abdominal lymph nodes',
  'C8334 Diffuse large B-cell lymphoma, lymph nodes of axilla and upper limb',
  'C8335 Diffuse large B-cell lymphoma, lymph nodes of inguinal region and lower limb',
  'C8336 Diffuse large B-cell lymphoma, intrapelvic lymph nodes',
  'C8337 Diffuse large B-cell lymphoma, spleen',
  'C8338 Diffuse large B-cell lymphoma, lymph nodes of multiple sites',
  'C8339 Diffuse large B-cell lymphoma, extranodal and solid organ sites',
  'C8350 Lymphoblastic (diffuse) lymphoma, unspecified site',
  'C8351 Lymphoblastic (diffuse) lymphoma, lymph nodes of head, face, and neck',
  'C8352 Lymphoblastic (diffuse) lymphoma, intrathoracic lymph nodes',
  'C8353 Lymphoblastic (diffuse) lymphoma, intra-abdominal lymph nodes',
  'C8354 Lymphoblastic (diffuse) lymphoma, lymph nodes of axilla and upper limb',
  'C8355 Lymphoblastic (diffuse) lymphoma, lymph nodes of inguinal region and lower limb',
  'C8356 Lymphoblastic (diffuse) lymphoma, intrapelvic lymph nodes',
  'C8357 Lymphoblastic (diffuse) lymphoma, spleen',
  'C8358 Lymphoblastic (diffuse) lymphoma, lymph nodes of multiple sites',
  'C8359 Lymphoblastic (diffuse) lymphoma, extranodal and solid organ sites',
  'C8370 Burkitt lymphoma, unspecified site',
  'C8371 Burkitt lymphoma, lymph nodes of head, face, and neck',
  'C8372 Burkitt lymphoma, intrathoracic lymph nodes',
  'C8373 Burkitt lymphoma, intra-abdominal lymph nodes',
  'C8374 Burkitt lymphoma, lymph nodes of axilla and upper limb',
  'C8375 Burkitt lymphoma, lymph nodes of inguinal region and lower limb',
  'C8376 Burkitt lymphoma, intrapelvic lymph nodes',
  'C8377 Burkitt lymphoma, spleen',
  'C8378 Burkitt lymphoma, lymph nodes of multiple sites',
  'C8379 Burkitt lymphoma, extranodal and solid organ sites',
  'C8380 Other non-follicular lymphoma, unspecified site',
  'C8381 Other non-follicular lymphoma, lymph nodes of head, face, and neck',
  'C8382 Other non-follicular lymphoma, intrathoracic lymph nodes',
  'C8383 Other non-follicular lymphoma, intra-abdominal lymph nodes',
  'C8384 Other non-follicular lymphoma, lymph nodes of axilla and upper limb',
  'C8385 Other non-follicular lymphoma, lymph nodes of inguinal region and lower limb',
  'C8386 Other non-follicular lymphoma, intrapelvic lymph nodes',
  'C8387 Other non-follicular lymphoma, spleen',
  'C8388 Other non-follicular lymphoma, lymph nodes of multiple sites',
  'C8389 Other non-follicular lymphoma, extranodal and solid organ sites',
  'C8390 Non-follicular (diffuse) lymphoma, unspecified, unspecified site',
  'C8391 Non-follicular (diffuse) lymphoma, unspecified, lymph nodes of head, face, and neck',
  'C8392 Non-follicular (diffuse) lymphoma, unspecified, intrathoracic lymph nodes',
  'C8393 Non-follicular (diffuse) lymphoma, unspecified, intra-abdominal lymph nodes',
  'C8394 Non-follicular (diffuse) lymphoma, unspecified, lymph nodes of axilla and upper limb',
  'C8395 Non-follicular (diffuse) lymphoma, unspecified, lymph nodes of inguinal region and lower limb',
  'C8396 Non-follicular (diffuse) lymphoma, unspecified, intrapelvic lymph nodes',
  'C8397 Non-follicular (diffuse) lymphoma, unspecified, spleen',
  'C8398 Non-follicular (diffuse) lymphoma, unspecified, lymph nodes of multiple sites',
  'C8399 Non-follicular (diffuse) lymphoma, unspecified, extranodal and solid organ sites',
  'C8400 Mycosis fungoides, unspecified site',
  'C8401 Mycosis fungoides, lymph nodes of head, face, and neck',
  'C8402 Mycosis fungoides, intrathoracic lymph nodes',
  'C8403 Mycosis fungoides, intra-abdominal lymph nodes',
  'C8404 Mycosis fungoides, lymph nodes of axilla and upper limb',
  'C8405 Mycosis fungoides, lymph nodes of inguinal region and lower limb',
  'C8406 Mycosis fungoides, intrapelvic lymph nodes',
  'C8407 Mycosis fungoides, spleen',
  'C8408 Mycosis fungoides, lymph nodes of multiple sites',
  'C8409 Mycosis fungoides, extranodal and solid organ sites',
  'C8410 Sezary disease, unspecified site',
  'C8411 Sezary disease, lymph nodes of head, face, and neck',
  'C8412 Sezary disease, intrathoracic lymph nodes',
  'C8413 Sezary disease, intra-abdominal lymph nodes',
  'C8414 Sezary disease, lymph nodes of axilla and upper limb',
  'C8415 Sezary disease, lymph nodes of inguinal region and lower limb',
  'C8416 Sezary disease, intrapelvic lymph nodes',
  'C8417 Sezary disease, spleen',
  'C8418 Sezary disease, lymph nodes of multiple sites',
  'C8419 Sezary disease, extranodal and solid organ sites',
  'C8440 Peripheral T-cell lymphoma, not classified, unspecified site',
  'C8441 Peripheral T-cell lymphoma, not classified, lymph nodes of head, face, and neck',
  'C8442 Peripheral T-cell lymphoma, not classified, intrathoracic lymph nodes',
  'C8443 Peripheral T-cell lymphoma, not classified, intra-abdominal lymph nodes',
  'C8444 Peripheral T-cell lymphoma, not classified, lymph nodes of axilla and upper limb',
  'C8445 Peripheral T-cell lymphoma, not classified, lymph nodes of inguinal region and lower limb',
  'C8446 Peripheral T-cell lymphoma, not classified, intrapelvic lymph nodes',
  'C8447 Peripheral T-cell lymphoma, not classified, spleen',
  'C8448 Peripheral T-cell lymphoma, not classified, lymph nodes of multiple sites',
  'C8449 Peripheral T-cell lymphoma, not classified, extranodal and solid organ sites',
  'C8460 Anaplastic large cell lymphoma, ALK-positive, unspecified site',
  'C8461 Anaplastic large cell lymphoma, ALK-positive, lymph nodes of head, face, and neck',
  'C8462 Anaplastic large cell lymphoma, ALK-positive, intrathoracic lymph nodes',
  'C8463 Anaplastic large cell lymphoma, ALK-positive, intra-abdominal lymph nodes',
  'C8464 Anaplastic large cell lymphoma, ALK-positive, lymph nodes of axilla and upper limb',
  'C8465 Anaplastic large cell lymphoma, ALK-positive, lymph nodes of inguinal region and lower limb',
  'C8466 Anaplastic large cell lymphoma, ALK-positive, intrapelvic lymph nodes',
  'C8467 Anaplastic large cell lymphoma, ALK-positive, spleen',
  'C8468 Anaplastic large cell lymphoma, ALK-positive, lymph nodes of multiple sites',
  'C8469 Anaplastic large cell lymphoma, ALK-positive, extranodal and solid organ sites',
  'C8470 Anaplastic large cell lymphoma, ALK-negative, unspecified site',
  'C8471 Anaplastic large cell lymphoma, ALK-negative, lymph nodes of head, face, and neck',
  'C8472 Anaplastic large cell lymphoma, ALK-negative, intrathoracic lymph nodes',
  'C8473 Anaplastic large cell lymphoma, ALK-negative, intra-abdominal lymph nodes',
  'C8474 Anaplastic large cell lymphoma, ALK-negative, lymph nodes of axilla and upper limb',
  'C8475 Anaplastic large cell lymphoma, ALK-negative, lymph nodes of inguinal region and lower limb',
  'C8476 Anaplastic large cell lymphoma, ALK-negative, intrapelvic lymph nodes',
  'C8477 Anaplastic large cell lymphoma, ALK-negative, spleen',
  'C8478 Anaplastic large cell lymphoma, ALK-negative, lymph nodes of multiple sites',
  'C8479 Anaplastic large cell lymphoma, ALK-negative, extranodal and solid organ sites',
  'C84A0 Cutaneous T-cell lymphoma, unspecified, unspecified site',
  'C84A1 Cutaneous T-cell lymphoma, unspecified lymph nodes of head, face, and neck',
  'C84A2 Cutaneous T-cell lymphoma, unspecified, intrathoracic lymph nodes',
  'C84A3 Cutaneous T-cell lymphoma, unspecified, intra-abdominal lymph nodes',
  'C84A4 Cutaneous T-cell lymphoma, unspecified, lymph nodes of axilla and upper limb',
  'C84A5 Cutaneous T-cell lymphoma, unspecified, lymph nodes of inguinal region and lower limb',
  'C84A6 Cutaneous T-cell lymphoma, unspecified, intrapelvic lymph nodes',
  'C84A7 Cutaneous T-cell lymphoma, unspecified, spleen',
  'C84A8 Cutaneous T-cell lymphoma, unspecified, lymph nodes of multiple sites',
  'C84A9 Cutaneous T-cell lymphoma, unspecified, extranodal and solid organ sites',
  'C84Z0 Other mature T/NK-cell lymphomas, unspecified site',
  'C84Z1 Other mature T/NK-cell lymphomas, lymph nodes of head, face, and neck',
  'C84Z2 Other mature T/NK-cell lymphomas, intrathoracic lymph nodes',
  'C84Z3 Other mature T/NK-cell lymphomas, intra-abdominal lymph nodes',
  'C84Z4 Other mature T/NK-cell lymphomas, lymph nodes of axilla and upper limb',
  'C84Z5 Other mature T/NK-cell lymphomas, lymph nodes of inguinal region and lower limb',
  'C84Z6 Other mature T/NK-cell lymphomas, intrapelvic lymph nodes',
  'C84Z7 Other mature T/NK-cell lymphomas, spleen',
  'C84Z8 Other mature T/NK-cell lymphomas, lymph nodes of multiple sites',
  'C84Z9 Other mature T/NK-cell lymphomas, extranodal and solid organ sites',
  'C8490 Mature T/NK-cell lymphomas, unspecified, unspecified site',
  'C8491 Mature T/NK-cell lymphomas, unspecified, lymph nodes of head, face, and neck',
  'C8492 Mature T/NK-cell lymphomas, unspecified, intrathoracic lymph nodes',
  'C8493 Mature T/NK-cell lymphomas, unspecified, intra-abdominal lymph nodes',
  'C8494 Mature T/NK-cell lymphomas, unspecified, lymph nodes of axilla and upper limb',
  'C8495 Mature T/NK-cell lymphomas, unspecified, lymph nodes of inguinal region and lower limb',
  'C8496 Mature T/NK-cell lymphomas, unspecified, intrapelvic lymph nodes',
  'C8497 Mature T/NK-cell lymphomas, unspecified, spleen',
  'C8498 Mature T/NK-cell lymphomas, unspecified, lymph nodes of multiple sites',
  'C8499 Mature T/NK-cell lymphomas, unspecified, extranodal and solid organ sites',
  'C8510 Unspecified B-cell lymphoma, unspecified site',
  'C8511 Unspecified B-cell lymphoma, lymph nodes of head, face, and neck',
  'C8512 Unspecified B-cell lymphoma, intrathoracic lymph nodes',
  'C8513 Unspecified B-cell lymphoma, intra-abdominal lymph nodes',
  'C8514 Unspecified B-cell lymphoma, lymph nodes of axilla and upper limb',
  'C8515 Unspecified B-cell lymphoma, lymph nodes of inguinal region and lower limb',
  'C8516 Unspecified B-cell lymphoma, intrapelvic lymph nodes',
  'C8517 Unspecified B-cell lymphoma, spleen',
  'C8518 Unspecified B-cell lymphoma, lymph nodes of multiple sites',
  'C8519 Unspecified B-cell lymphoma, extranodal and solid organ sites',
  'C8520 Mediastinal (thymic) large B-cell lymphoma, unspecified site',
  'C8521 Mediastinal (thymic) large B-cell lymphoma, lymph nodes of head, face, and neck',
  'C8522 Mediastinal (thymic) large B-cell lymphoma, intrathoracic lymph nodes',
  'C8523 Mediastinal (thymic) large B-cell lymphoma, intra-abdominal lymph nodes',
  'C8524 Mediastinal (thymic) large B-cell lymphoma, lymph nodes of axilla and upper limb',
  'C8525 Mediastinal (thymic) large B-cell lymphoma, lymph nodes of inguinal region and lower limb',
  'C8526 Mediastinal (thymic) large B-cell lymphoma, intrapelvic lymph nodes',
  'C8527 Mediastinal (thymic) large B-cell lymphoma, spleen',
  'C8528 Mediastinal (thymic) large B-cell lymphoma, lymph nodes of multiple sites',
  'C8529 Mediastinal (thymic) large B-cell lymphoma, extranodal and solid organ sites',
  'C8580 Other specified types of non-Hodgkin lymphoma, unspecified site',
  'C8581 Other specified types of non-Hodgkin lymphoma, lymph nodes of head, face, and neck',
  'C8582 Other specified types of non-Hodgkin lymphoma, intrathoracic lymph nodes',
  'C8583 Other specified types of non-Hodgkin lymphoma, intra-abdominal lymph nodes',
  'C8584 Other specified types of non-Hodgkin lymphoma, lymph nodes of axilla and upper limb',
  'C8585 Other specified types of non-Hodgkin lymphoma, lymph nodes of inguinal region and lower limb',
  'C8586 Other specified types of non-Hodgkin lymphoma, intrapelvic lymph nodes',
  'C8587 Other specified types of non-Hodgkin lymphoma, spleen',
  'C8588 Other specified types of non-Hodgkin lymphoma, lymph nodes of multiple sites',
  'C8589 Other specified types of non-Hodgkin lymphoma, extranodal and solid organ sites',
  'C8590 Non-Hodgkin lymphoma, unspecified, unspecified site',
  'C8591 Non-Hodgkin lymphoma, unspecified, lymph nodes of head, face, and neck',
  'C8592 Non-Hodgkin lymphoma, unspecified, intrathoracic lymph nodes',
  'C8593 Non-Hodgkin lymphoma, unspecified, intra-abdominal lymph nodes',
  'C8594 Non-Hodgkin lymphoma, unspecified, lymph nodes of axilla and upper limb',
  'C8595 Non-Hodgkin lymphoma, unspecified, lymph nodes of inguinal region and lower limb',
  'C8596 Non-Hodgkin lymphoma, unspecified, intrapelvic lymph nodes',
  'C8597 Non-Hodgkin lymphoma, unspecified, spleen',
  'C8598 Non-Hodgkin lymphoma, unspecified, lymph nodes of multiple sites',
  'C8599 Non-Hodgkin lymphoma, unspecified, extranodal and solid organ sites',
  'C860 Extranodal NK/T-cell lymphoma, nasal type',
  'C861 Hepatosplenic T-cell lymphoma',
  'C862 Enteropathy-type (intestinal) T-cell lymphoma',
  'C863 Subcutaneous panniculitis-like T-cell lymphoma',
  'C864 Blastic NK-cell lymphoma',
  'C865 Angioimmunoblastic T-cell lymphoma',
  'C866 Primary cutaneous CD30-positive T-cell proliferations',
  'C880 Waldenstrom macroglobulinemia',
  'C882 Heavy chain disease',
  'C883 Immunoproliferative small intestinal disease',
  'C884 Extranodal marginal zone B-cell lymphoma of mucosa-associated lymphoid tissue [MALTlymphoma]',
  'C888 Other malignant immunoproliferative diseases',
  'C889 Malignant immunoproliferative disease, unspecified',
  'C9000 Multiple myeloma not having achieved remission',
  'C9001 Multiple myeloma in remission',
  'C9002 Multiple myeloma in relapse',
  'C9010 Plasma cell leukemia not having achieved remission',
  'C9011 Plasma cell leukemia in remission',
  'C9012 Plasma cell leukemia in relapse',
  'C9020 Extramedullary plasmacytoma not having achieved remission',
  'C9021 Extramedullary plasmacytoma in remission',
  'C9022 Extramedullary plasmacytoma in relapse',
  'C9030 Solitary plasmacytoma not having achieved remission',
  'C9031 Solitary plasmacytoma in remission',
  'C9032 Solitary plasmacytoma in relapse',
  'C9100 Acute lymphoblastic leukemia not having achieved remission',
  'C9101 Acute lymphoblastic leukemia, in remission',
  'C9102 Acute lymphoblastic leukemia, in relapse',
  'C9110 Chronic lymphocytic leukemia of B-cell type not having achieved remission',
  'C9111 Chronic lymphocytic leukemia of B-cell type in remission',
  'C9112 Chronic lymphocytic leukemia of B-cell type in relapse',
  'C9130 Prolymphocytic leukemia of B-cell type not having achieved remission',
  'C9131 Prolymphocytic leukemia of B-cell type, in remission',
  'C9132 Prolymphocytic leukemia of B-cell type, in relapse',
  'C9140 Hairy cell leukemia not having achieved remission',
  'C9141 Hairy cell leukemia, in remission',
  'C9142 Hairy cell leukemia, in relapse',
  'C9150 Adult T-cell lymphoma/leukemia (HTLV-1-associated) not having achieved remission',
  'C9151 Adult T-cell lymphoma/leukemia (HTLV-1-associated), in remission',
  'C9152 Adult T-cell lymphoma/leukemia (HTLV-1-associated), in relapse',
  'C9160 Prolymphocytic leukemia of T-cell type not having achieved remission',
  'C9161 Prolymphocytic leukemia of T-cell type, in remission',
  'C9162 Prolymphocytic leukemia of T-cell type, in relapse',
  'C91A0 Mature B-cell leukemia Burkitt-type not having achieved remission',
  'C91A1 Mature B-cell leukemia Burkitt-type, in remission',
  'C91A2 Mature B-cell leukemia Burkitt-type, in relapse',
  'C91Z0 Other lymphoid leukemia not having achieved remission',
  'C91Z1 Other lymphoid leukemia, in remission',
  'C91Z2 Other lymphoid leukemia, in relapse',
  'C9190 Lymphoid leukemia, unspecified not having achieved remission',
  'C9191 Lymphoid leukemia, unspecified, in remission',
  'C9192 Lymphoid leukemia, unspecified, in relapse',
  'C9200 Acute myeloblastic leukemia, not having achieved remission',
  'C9201 Acute myeloblastic leukemia, in remission',
  'C9202 Acute myeloblastic leukemia, in relapse',
  'C9210 Chronic myeloid leukemia, BCR/ABL-positive, not having achieved remission',
  'C9211 Chronic myeloid leukemia, BCR/ABL-positive, in remission',
  'C9212 Chronic myeloid leukemia, BCR/ABL-positive, in relapse',
  'C9220 Atypical chronic myeloid leukemia, BCR/ABL-negative, not having achieved remission',
  'C9221 Atypical chronic myeloid leukemia, BCR/ABL-negative, in remission',
  'C9222 Atypical chronic myeloid leukemia, BCR/ABL-negative, in relapse',
  'C9230 Myeloid sarcoma, not having achieved remission',
  'C9231 Myeloid sarcoma, in remission',
  'C9232 Myeloid sarcoma, in relapse',
  'C9240 Acute promyelocytic leukemia, not having achieved remission',
  'C9241 Acute promyelocytic leukemia, in remission',
  'C9242 Acute promyelocytic leukemia, in relapse',
  'C9250 Acute myelomonocytic leukemia, not having achieved remission',
  'C9251 Acute myelomonocytic leukemia, in remission',
  'C9252 Acute myelomonocytic leukemia, in relapse',
  'C9260 Acute myeloid leukemia with 11q23-abnormality not having achieved remission',
  'C9261 Acute myeloid leukemia with 11q23-abnormality in remission',
  'C9262 Acute myeloid leukemia with 11q23-abnormality in relapse',
  'C92A0 Acute myeloid leukemia with multilineage dysplasia, not having achieved remission',
  'C92A1 Acute myeloid leukemia with multilineage dysplasia, in remission',
  'C92A2 Acute myeloid leukemia with multilineage dysplasia, in relapse',
  'C92Z0 Other myeloid leukemia not having achieved remission',
  'C92Z1 Other myeloid leukemia, in remission',
  'C92Z2 Other myeloid leukemia, in relapse',
  'C9290 Myeloid leukemia, unspecified, not having achieved remission',
  'C9291 Myeloid leukemia, unspecified in remission',
  'C9292 Myeloid leukemia, unspecified in relapse',
  'C9300 Acute monoblastic/monocytic leukemia, not having achieved remission',
  'C9301 Acute monoblastic/monocytic leukemia, in remission',
  'C9302 Acute monoblastic/monocytic leukemia, in relapse',
  'C9310 Chronic myelomonocytic leukemia not having achieved remission',
  'C9311 Chronic myelomonocytic leukemia, in remission',
  'C9312 Chronic myelomonocytic leukemia, in relapse',
  'C9330 Juvenile myelomonocytic leukemia, not having achieved remission',
  'C9331 Juvenile myelomonocytic leukemia, in remission',
  'C9332 Juvenile myelomonocytic leukemia, in relapse',
  'C93Z0 Other monocytic leukemia, not having achieved remission',
  'C93Z1 Other monocytic leukemia, in remission',
  'C93Z2 Other monocytic leukemia, in relapse',
  'C9390 Monocytic leukemia, unspecified, not having achieved remission',
  'C9391 Monocytic leukemia, unspecified in remission',
  'C9392 Monocytic leukemia, unspecified in relapse',
  'C9400 Acute erythroid leukemia, not having achieved remission',
  'C9401 Acute erythroid leukemia, in remission',
  'C9402 Acute erythroid leukemia, in relapse',
  'C9420 Acute megakaryoblastic leukemia not having achieved remission',
  'C9421 Acute megakaryoblastic leukemia, in remission',
  'C9422 Acute megakaryoblastic leukemia, in relapse',
  'C9430 Mast cell leukemia not having achieved remission',
  'C9431 Mast cell leukemia, in remission',
  'C9432 Mast cell leukemia, in relapse',
  'C9440 Acute panmyelosis with myelofibrosis not having achieved remission',
  'C9441 Acute panmyelosis with myelofibrosis, in remission',
  'C9442 Acute panmyelosis with myelofibrosis, in relapse',
  'C946 Myelodysplastic disease, not classified',
  'C9480 Other specified leukemias not having achieved remission',
  'C9481 Other specified leukemias, in remission',
  'C9482 Other specified leukemias, in relapse',
  'C9500 Acute leukemia of unspecified cell type not having achieved remission',
  'C9501 Acute leukemia of unspecified cell type, in remission',
  'C9502 Acute leukemia of unspecified cell type, in relapse',
  'C9510 Chronic leukemia of unspecified cell type not having achieved remission',
  'C9511 Chronic leukemia of unspecified cell type, in remission',
  'C9512 Chronic leukemia of unspecified cell type, in relapse',
  'C9590 Leukemia, unspecified not having achieved remission',
  'C9591 Leukemia, unspecified, in remission',
  'C9592 Leukemia, unspecified, in relapse',
  'C960 Multifocal and multisystemic (disseminated) Langerhans-cell histiocytosis',
  'C962 Malignant mast cell tumor',
  'C964 Sarcoma of dendritic cells (accessory cells)',
  'C965 Multifocal and unisystemic Langerhans-cell histiocytosis',
  'C966 Unifocal Langerhans-cell histiocytosis',
  'C96A Histiocytic sarcoma',
  'C96Z Other specified malignant neoplasms of lymphoid, hematopoietic and related tissue',
  'C969 Malignant neoplasm of lymphoid, hematopoietic and related tissue, unspecified',
  'D0000 Carcinoma in situ of oral cavity, unspecified site',
  'D0001 Carcinoma in situ of labial mucosa and vermilion border',
  'D0002 Carcinoma in situ of buccal mucosa',
  'D0003 Carcinoma in situ of gingiva and edentulous alveolar ridge',
  'D0004 Carcinoma in situ of soft palate',
  'D0005 Carcinoma in situ of hard palate',
  'D0006 Carcinoma in situ of floor of mouth',
  'D0007 Carcinoma in situ of tongue',
  'D0008 Carcinoma in situ of pharynx',
  'D001 Carcinoma in situ of esophagus',
  'D002 Carcinoma in situ of stomach',
  'D010 Carcinoma in situ of colon',
  'D011 Carcinoma in situ of rectosigmoid junction',
  'D012 Carcinoma in situ of rectum',
  'D013 Carcinoma in situ of anus and anal canal',
  'D0140 Carcinoma in situ of unspecified part of intestine',
  'D0149 Carcinoma in situ of other parts of intestine',
  'D015 Carcinoma in situ of liver, gallbladder and bile ducts',
  'D017 Carcinoma in situ of other specified digestive organs',
  'D019 Carcinoma in situ of digestive organ, unspecified',
  'D020 Carcinoma in situ of larynx',
  'D021 Carcinoma in situ of trachea',
  'D0220 Carcinoma in situ of unspecified bronchus and lung',
  'D0221 Carcinoma in situ of right bronchus and lung',
  'D0222 Carcinoma in situ of left bronchus and lung',
  'D023 Carcinoma in situ of other parts of respiratory system',
  'D024 Carcinoma in situ of respiratory system, unspecified',
  'D030 Melanoma in situ of lip',
  'D0310 Melanoma in situ of unspecified eyelid, including canthus',
  'D0311 Melanoma in situ of right eyelid, including canthus',
  'D0312 Melanoma in situ of left eyelid, including canthus',
  'D0320 Melanoma in situ of unspecified ear and external auricular canal',
  'D0321 Melanoma in situ of right ear and external auricular canal',
  'D0322 Melanoma in situ of left ear and external auricular canal',
  'D0330 Melanoma in situ of unspecified part of face',
  'D0339 Melanoma in situ of other parts of face',
  'D034 Melanoma in situ of scalp and neck',
  'D0351 Melanoma in situ of anal skin',
  'D0352 Melanoma in situ of breast (skin) (soft tissue)',
  'D0359 Melanoma in situ of other part of trunk',
  'D0360 Melanoma in situ of unspecified upper limb, including shoulder',
  'D0361 Melanoma in situ of right upper limb, including shoulder',
  'D0362 Melanoma in situ of left upper limb, including shoulder',
  'D0370 Melanoma in situ of unspecified lower limb, including hip',
  'D0371 Melanoma in situ of right lower limb, including hip',
  'D0372 Melanoma in situ of left lower limb, including hip',
  'D038 Melanoma in situ of other sites',
  'D039 Melanoma in situ, unspecified',
  'D040 Carcinoma in situ of skin of lip',
  'D0410 Carcinoma in situ of skin of unspecified eyelid, including canthus',
  'D0411 Carcinoma in situ of skin of right eyelid, including canthus',
  'D0412 Carcinoma in situ of skin of left eyelid, including canthus',
  'D0420 Carcinoma in situ of skin of unspecified ear and external auricular canal',
  'D0421 Carcinoma in situ of skin of right ear and external auricular canal',
  'D0422 Carcinoma in situ of skin of left ear and external auricular canal',
  'D0430 Carcinoma in situ of skin of unspecified part of face',
  'D0439 Carcinoma in situ of skin of other parts of face',
  'D044 Carcinoma in situ of skin of scalp and neck',
  'D045 Carcinoma in situ of skin of trunk',
  'D0460 Carcinoma in situ of skin of unspecified upper limb, including shoulder',
  'D0461 Carcinoma in situ of skin of right upper limb, including shoulder',
  'D0462 Carcinoma in situ of skin of left upper limb, including shoulder',
  'D0470 Carcinoma in situ of skin of unspecified lower limb, including hip',
  'D0471 Carcinoma in situ of skin of right lower limb, including hip',
  'D0472 Carcinoma in situ of skin of left lower limb, including hip',
  'D048 Carcinoma in situ of skin of other sites',
  'D049 Carcinoma in situ of skin, unspecified',
  'D0500 Lobular carcinoma in situ of unspecified breast',
  'D0501 Lobular carcinoma in situ of right breast',
  'D0502 Lobular carcinoma in situ of left breast',
  'D0510 Intraductal carcinoma in situ of unspecified breast',
  'D0511 Intraductal carcinoma in situ of right breast',
  'D0512 Intraductal carcinoma in situ of left breast',
  'D0580 Other specified type of carcinoma in situ of unspecified breast',
  'D0581 Other specified type of carcinoma in situ of right breast',
  'D0582 Other specified type of carcinoma in situ of left breast',
  'D0590 Unspecified type of carcinoma in situ of unspecified breast',
  'D0591 Unspecified type of carcinoma in situ of right breast',
  'D0592 Unspecified type of carcinoma in situ of left breast',
  'D060 Carcinoma in situ of endocervix',
  'D061 Carcinoma in situ of exocervix',
  'D067 Carcinoma in situ of other parts of cervix',
  'D069 Carcinoma in situ of cervix, unspecified',
  'D070 Carcinoma in situ of endometrium',
  'D071 Carcinoma in situ of vulva',
  'D072 Carcinoma in situ of vagina',
  'D0730 Carcinoma in situ of unspecified female genital organs',
  'D0739 Carcinoma in situ of other female genital organs',
  'D074 Carcinoma in situ of penis',
  'D075 Carcinoma in situ of prostate',
  'D0760 Carcinoma in situ of unspecified male genital organs',
  'D0761 Carcinoma in situ of scrotum',
  'D0769 Carcinoma in situ of other male genital organs',
  'D090 Carcinoma in situ of bladder',
  'D0910 Carcinoma in situ of unspecified urinary organ',
  'D0919 Carcinoma in situ of other urinary organs',
  'D0920 Carcinoma in situ of unspecified eye',
  'D0921 Carcinoma in situ of right eye',
  'D0922 Carcinoma in situ of left eye',
  'D093 Carcinoma in situ of thyroid and other endocrine glands',
  'D098 Carcinoma in situ of other specified sites',
  'D099 Carcinoma in situ, unspecified',
  'D100 Benign neoplasm of lip',
  'D101 Benign neoplasm of tongue',
  'D102 Benign neoplasm of floor of mouth',
  'D1030 Benign neoplasm of unspecified part of mouth',
  'D1039 Benign neoplasm of other parts of mouth',
  'D104 Benign neoplasm of tonsil',
  'D105 Benign neoplasm of other parts of oropharynx',
  'D106 Benign neoplasm of nasopharynx',
  'D107 Benign neoplasm of hypopharynx',
  'D109 Benign neoplasm of pharynx, unspecified',
  'D110 Benign neoplasm of parotid gland',
  'D117 Benign neoplasm of other major salivary glands',
  'D119 Benign neoplasm of major salivary gland, unspecified',
  'D120 Benign neoplasm of cecum',
  'D121 Benign neoplasm of appendix',
  'D122 Benign neoplasm of ascending colon',
  'D123 Benign neoplasm of transverse colon',
  'D124 Benign neoplasm of descending colon',
  'D125 Benign neoplasm of sigmoid colon',
  'D126 Benign neoplasm of colon, unspecified',
  'D127 Benign neoplasm of rectosigmoid junction',
  'D128 Benign neoplasm of rectum',
  'D129 Benign neoplasm of anus and anal canal',
  'D130 Benign neoplasm of esophagus',
  'D131 Benign neoplasm of stomach',
  'D132 Benign neoplasm of duodenum',
  'D1330 Benign neoplasm of unspecified part of small intestine',
  'D1339 Benign neoplasm of other parts of small intestine',
  'D134 Benign neoplasm of liver',
  'D135 Benign neoplasm of extrahepatic bile ducts',
  'D136 Benign neoplasm of pancreas',
  'D137 Benign neoplasm of endocrine pancreas',
  'D139 Benign neoplasm of ill-defined sites within the digestive system',
  'D140 Benign neoplasm of middle ear, nasal cavity and accessory sinuses',
  'D141 Benign neoplasm of larynx',
  'D142 Benign neoplasm of trachea',
  'D1430 Benign neoplasm of unspecified bronchus and lung',
  'D1431 Benign neoplasm of right bronchus and lung',
  'D1432 Benign neoplasm of left bronchus and lung',
  'D144 Benign neoplasm of respiratory system, unspecified',
  'D150 Benign neoplasm of thymus',
  'D151 Benign neoplasm of heart',
  'D152 Benign neoplasm of mediastinum',
  'D157 Benign neoplasm of other specified intrathoracic organs',
  'D159 Benign neoplasm of intrathoracic organ, unspecified',
  'D1600 Benign neoplasm of scapula and long bones of unspecified upper limb',
  'D1601 Benign neoplasm of scapula and long bones of right upper limb',
  'D1602 Benign neoplasm of scapula and long bones of left upper limb',
  'D1610 Benign neoplasm of short bones of unspecified upper limb',
  'D1611 Benign neoplasm of short bones of right upper limb',
  'D1612 Benign neoplasm of short bones of left upper limb',
  'D1620 Benign neoplasm of long bones of unspecified lower limb',
  'D1621 Benign neoplasm of long bones of right lower limb',
  'D1622 Benign neoplasm of long bones of left lower limb',
  'D1630 Benign neoplasm of short bones of unspecified lower limb',
  'D1631 Benign neoplasm of short bones of right lower limb',
  'D1632 Benign neoplasm of short bones of left lower limb',
  'D164 Benign neoplasm of bones of skull and face',
  'D165 Benign neoplasm of lower jaw bone',
  'D166 Benign neoplasm of vertebral column',
  'D167 Benign neoplasm of ribs, sternum and clavicle',
  'D168 Benign neoplasm of pelvic bones, sacrum and coccyx',
  'D169 Benign neoplasm of bone and articular cartilage, unspecified',
  'D170 Benign lipomatous neoplasm of skin and subcutaneous tissue of head, face and neck',
  'D171 Benign lipomatous neoplasm of skin and subcutaneous tissue of trunk',
  'D1720 Benign lipomatous neoplasm of skin and subcutaneous tissue of unspecified limb',
  'D1721 Benign lipomatous neoplasm of skin and subcutaneous tissue of right arm',
  'D1722 Benign lipomatous neoplasm of skin and subcutaneous tissue of left arm',
  'D1723 Benign lipomatous neoplasm of skin and subcutaneous tissue of right leg',
  'D1724 Benign lipomatous neoplasm of skin and subcutaneous tissue of left leg',
  'D1730 Benign lipomatous neoplasm of skin and subcutaneous tissue of unspecified sites',
  'D1739 Benign lipomatous neoplasm of skin and subcutaneous tissue of other sites',
  'D174 Benign lipomatous neoplasm of intrathoracic organs',
  'D175 Benign lipomatous neoplasm of intra-abdominal organs',
  'D176 Benign lipomatous neoplasm of spermatic cord',
  'D1771 Benign lipomatous neoplasm of kidney',
  'D1772 Benign lipomatous neoplasm of other genitourinary organ',
  'D1779 Benign lipomatous neoplasm of other sites',
  'D179 Benign lipomatous neoplasm, unspecified',
  'D1800 Hemangioma unspecified site',
  'D1801 Hemangioma of skin and subcutaneous tissue',
  'D1802 Hemangioma of intracranial structures',
  'D1803 Hemangioma of intra-abdominal structures',
  'D1809 Hemangioma of other sites',
  'D181 Lymphangioma, any site',
  'D190 Benign neoplasm of mesothelial tissue of pleura',
  'D191 Benign neoplasm of mesothelial tissue of peritoneum',
  'D197 Benign neoplasm of mesothelial tissue of other sites',
  'D199 Benign neoplasm of mesothelial tissue, unspecified',
  'D200 Benign neoplasm of soft tissue of retroperitoneum',
  'D201 Benign neoplasm of soft tissue of peritoneum',
  'D210 Benign neoplasm of connective and other soft tissue of head, face and neck',
  'D2110 Benign neoplasm of connective and other soft tissue of unspecified upper limb, including',
  'shoulder',
  'D2111 Benign neoplasm of connective and other soft tissue of right upper limb, including shoulder',
  'D2112 Benign neoplasm of connective and other soft tissue of left upper limb, including shoulder',
  'D2120 Benign neoplasm of connective and other soft tissue of unspecified lower limb, including hip',
  'D2121 Benign neoplasm of connective and other soft tissue of right lower limb, including hip',
  'D2122 Benign neoplasm of connective and other soft tissue of left lower limb, including hip',
  'D213 Benign neoplasm of connective and other soft tissue of thorax',
  'D214 Benign neoplasm of connective and other soft tissue of abdomen',
  'D215 Benign neoplasm of connective and other soft tissue of pelvis',
  'D216 Benign neoplasm of connective and other soft tissue of trunk, unspecified',
  'D219 Benign neoplasm of connective and other soft tissue, unspecified',
  'D220 Melanocytic nevi of lip',
  'D2210 Melanocytic nevi of unspecified eyelid, including canthus',
  'D2211 Melanocytic nevi of right eyelid, including canthus',
  'D2212 Melanocytic nevi of left eyelid, including canthus',
  'D2220 Melanocytic nevi of unspecified ear and external auricular canal',
  'D2221 Melanocytic nevi of right ear and external auricular canal',
  'D2222 Melanocytic nevi of left ear and external auricular canal',
  'D2230 Melanocytic nevi of unspecified part of face',
  'D2239 Melanocytic nevi of other parts of face',
  'D224 Melanocytic nevi of scalp and neck',
  'D225 Melanocytic nevi of trunk',
  'D2260 Melanocytic nevi of unspecified upper limb, including shoulder',
  'D2261 Melanocytic nevi of right upper limb, including shoulder',
  'D2262 Melanocytic nevi of left upper limb, including shoulder',
  'D2270 Melanocytic nevi of unspecified lower limb, including hip',
  'D2271 Melanocytic nevi of right lower limb, including hip',
  'D2272 Melanocytic nevi of left lower limb, including hip',
  'D229 Melanocytic nevi, unspecified',
  'D230 Other benign neoplasm of skin of lip',
  'D2310 Other benign neoplasm of skin of unspecified eyelid, including canthus',
  'D2311 Other benign neoplasm of skin of right eyelid, including canthus',
  'D2312 Other benign neoplasm of skin of left eyelid, including canthus',
  'D2320 Other benign neoplasm of skin of unspecified ear and external auricular canal',
  'D2321 Other benign neoplasm of skin of right ear and external auricular canal',
  'D2322 Other benign neoplasm of skin of left ear and external auricular canal',
  'D2330 Other benign neoplasm of skin of unspecified part of face',
  'D2339 Other benign neoplasm of skin of other parts of face',
  'D234 Other benign neoplasm of skin of scalp and neck',
  'D235 Other benign neoplasm of skin of trunk',
  'D2360 Other benign neoplasm of skin of unspecified upper limb, including shoulder',
  'D2361 Other benign neoplasm of skin of right upper limb, including shoulder',
  'D2362 Other benign neoplasm of skin of left upper limb, including shoulder',
  'D2370 Other benign neoplasm of skin of unspecified lower limb, including hip',
  'D2371 Other benign neoplasm of skin of right lower limb, including hip',
  'D2372 Other benign neoplasm of skin of left lower limb, including hip',
  'D239 Other benign neoplasm of skin, unspecified',
  'D241 Benign neoplasm of right breast',
  'D242 Benign neoplasm of left breast',
  'D249 Benign neoplasm of unspecified breast',
  'D250 Submucous leiomyoma of uterus',
  'D251 Intramural leiomyoma of uterus',
  'D252 Subserosal leiomyoma of uterus',
  'D259 Leiomyoma of uterus, unspecified',
  'D260 Other benign neoplasm of cervix uteri',
  'D261 Other benign neoplasm of corpus uteri',
  'D267 Other benign neoplasm of other parts of uterus',
  'D269 Other benign neoplasm of uterus, unspecified',
  'D270 Benign neoplasm of right ovary',
  'D271 Benign neoplasm of left ovary',
  'D279 Benign neoplasm of unspecified ovary',
  'D280 Benign neoplasm of vulva',
  'D281 Benign neoplasm of vagina',
  'D282 Benign neoplasm of uterine tubes and ligaments',
  'D287 Benign neoplasm of other specified female genital organs',
  'D289 Benign neoplasm of female genital organ, unspecified',
  'D290 Benign neoplasm of penis',
  'D291 Benign neoplasm of prostate',
  'D2920 Benign neoplasm of unspecified testis',
  'D2921 Benign neoplasm of right testis',
  'D2922 Benign neoplasm of left testis',
  'D2930 Benign neoplasm of unspecified epididymis',
  'D2931 Benign neoplasm of right epididymis',
  'D2932 Benign neoplasm of left epididymis',
  'D294 Benign neoplasm of scrotum',
  'D298 Benign neoplasm of other specified male genital organs',
  'D299 Benign neoplasm of male genital organ, unspecified',
  'D3000 Benign neoplasm of unspecified kidney',
  'D3001 Benign neoplasm of right kidney',
  'D3002 Benign neoplasm of left kidney',
  'D3010 Benign neoplasm of unspecified renal pelvis',
  'D3011 Benign neoplasm of right renal pelvis',
  'D3012 Benign neoplasm of left renal pelvis',
  'D3020 Benign neoplasm of unspecified ureter',
  'D3021 Benign neoplasm of right ureter',
  'D3022 Benign neoplasm of left ureter',
  'D303 Benign neoplasm of bladder',
  'D304 Benign neoplasm of urethra',
  'D308 Benign neoplasm of other specified urinary organs',
  'D309 Benign neoplasm of urinary organ, unspecified',
  'D3100 Benign neoplasm of unspecified conjunctiva',
  'D3101 Benign neoplasm of right conjunctiva',
  'D3102 Benign neoplasm of left conjunctiva',
  'D3110 Benign neoplasm of unspecified cornea',
  'D3111 Benign neoplasm of right cornea',
  'D3112 Benign neoplasm of left cornea',
  'D3120 Benign neoplasm of unspecified retina',
  'D3121 Benign neoplasm of right retina',
  'D3122 Benign neoplasm of left retina',
  'D3130 Benign neoplasm of unspecified choroid',
  'D3131 Benign neoplasm of right choroid',
  'D3132 Benign neoplasm of left choroid',
  'D3140 Benign neoplasm of unspecified ciliary body',
  'D3141 Benign neoplasm of right ciliary body',
  'D3142 Benign neoplasm of left ciliary body',
  'D3150 Benign neoplasm of unspecified lacrimal gland and duct',
  'D3151 Benign neoplasm of right lacrimal gland and duct',
  'D3152 Benign neoplasm of left lacrimal gland and duct',
  'D3160 Benign neoplasm of unspecified site of unspecified orbit',
  'D3161 Benign neoplasm of unspecified site of right orbit',
  'D3162 Benign neoplasm of unspecified site of left orbit',
  'D3190 Benign neoplasm of unspecified part of unspecified eye',
  'D3191 Benign neoplasm of unspecified part of right eye',
  'D3192 Benign neoplasm of unspecified part of left eye',
  'D320 Benign neoplasm of cerebral meninges',
  'D321 Benign neoplasm of spinal meninges',
  'D329 Benign neoplasm of meninges, unspecified',
  'D330 Benign neoplasm of brain, supratentorial',
  'D331 Benign neoplasm of brain, infratentorial',
  'D332 Benign neoplasm of brain, unspecified',
  'D333 Benign neoplasm of cranial nerves',
  'D334 Benign neoplasm of spinal cord',
  'D337 Benign neoplasm of other specified parts of central nervous system',
  'D339 Benign neoplasm of central nervous system, unspecified',
  'D34 Benign neoplasm of thyroid gland',
  'D3500 Benign neoplasm of unspecified adrenal gland',
  'D3501 Benign neoplasm of right adrenal gland',
  'D3502 Benign neoplasm of left adrenal gland',
  'D351 Benign neoplasm of parathyroid gland',
  'D352 Benign neoplasm of pituitary gland',
  'D353 Benign neoplasm of craniopharyngeal duct',
  'D354 Benign neoplasm of pineal gland',
  'D355 Benign neoplasm of carotid body',
  'D356 Benign neoplasm of aortic body and other paraganglia',
  'D357 Benign neoplasm of other specified endocrine glands',
  'D359 Benign neoplasm of endocrine gland, unspecified',
  'D360 Benign neoplasm of lymph nodes',
  'D3610 Benign neoplasm of peripheral nerves and autonomic nervous system, unspecified',
  'D3611 Benign neoplasm of peripheral nerves and autonomic nervous system of face, head, and neck',
  'D3612 Benign neoplasm of peripheral nerves and autonomic nervous system, upper limb, including',
  'shoulder',
  'D3613 Benign neoplasm of peripheral nerves and autonomic nervous system of lower limb, including',
  'hip',
  'D3614 Benign neoplasm of peripheral nerves and autonomic nervous system of thorax',
  'D3615 Benign neoplasm of peripheral nerves and autonomic nervous system of abdomen',
  'D3616 Benign neoplasm of peripheral nerves and autonomic nervous system of pelvis',
  'D3617 Benign neoplasm of peripheral nerves and autonomic nervous system of trunk, unspecified',
  'D367 Benign neoplasm of other specified sites',
  'D369 Benign neoplasm, unspecified site',
  'D3A00 Benign carcinoid tumor of unspecified site',
  'D3A010 Benign carcinoid tumor of the duodenum',
  'D3A011 Benign carcinoid tumor of the jejunum',
  'D3A012 Benign carcinoid tumor of the ileum',
  'D3A019 Benign carcinoid tumor of the small intestine, unspecified portion',
  'D3A020 Benign carcinoid tumor of the appendix',
  'D3A021 Benign carcinoid tumor of the cecum',
  'D3A022 Benign carcinoid tumor of the ascending colon',
  'D3A023 Benign carcinoid tumor of the transverse colon',
  'D3A024 Benign carcinoid tumor of the descending colon',
  'D3A025 Benign carcinoid tumor of the sigmoid colon',
  'D3A026 Benign carcinoid tumor of the rectum',
  'D3A029 Benign carcinoid tumor of the large intestine, unspecified portion',
  'D3A090 Benign carcinoid tumor of the bronchus and lung',
  'D3A091 Benign carcinoid tumor of the thymus',
  'D3A092 Benign carcinoid tumor of the stomach',
  'D3A093 Benign carcinoid tumor of the kidney',
  'D3A094 Benign carcinoid tumor of the foregut, unspecified',
  'D3A095 Benign carcinoid tumor of the midgut, unspecified',
  'D3A096 Benign carcinoid tumor of the hindgut, unspecified',
  'D3A098 Benign carcinoid tumors of other sites',
  'D3A8 Other benign neuroendocrine tumors',
  'D3701 Neoplasm of uncertain behavior of lip',
  'D3702 Neoplasm of uncertain behavior of tongue',
  'D37030 Neoplasm of uncertain behavior of the parotid salivary glands',
  'D37031 Neoplasm of uncertain behavior of the sublingual salivary glands',
  'D37032 Neoplasm of uncertain behavior of the submandibular salivary glands',
  'D37039 Neoplasm of uncertain behavior of the major salivary glands, unspecified',
  'D3704 Neoplasm of uncertain behavior of the minor salivary glands',
  'D3705 Neoplasm of uncertain behavior of pharynx',
  'D3709 Neoplasm of uncertain behavior of other specified sites of the oral cavity',
  'D371 Neoplasm of uncertain behavior of stomach',
  'D372 Neoplasm of uncertain behavior of small intestine',
  'D373 Neoplasm of uncertain behavior of appendix',
  'D374 Neoplasm of uncertain behavior of colon',
  'D375 Neoplasm of uncertain behavior of rectum',
  'D376 Neoplasm of uncertain behavior of liver, gallbladder and bile ducts',
  'D378 Neoplasm of uncertain behavior of other specified digestive organs',
  'D379 Neoplasm of uncertain behavior of digestive organ, unspecified',
  'D380 Neoplasm of uncertain behavior of larynx',
  'D381 Neoplasm of uncertain behavior of trachea, bronchus and lung',
  'D382 Neoplasm of uncertain behavior of pleura',
  'D383 Neoplasm of uncertain behavior of mediastinum',
  'D384 Neoplasm of uncertain behavior of thymus',
  'D385 Neoplasm of uncertain behavior of other respiratory organs',
  'D386 Neoplasm of uncertain behavior of respiratory organ, unspecified',
  'D390 Neoplasm of uncertain behavior of uterus',
  'D3910 Neoplasm of uncertain behavior of unspecified ovary',
  'D3911 Neoplasm of uncertain behavior of right ovary',
  'D3912 Neoplasm of uncertain behavior of left ovary',
  'D392 Neoplasm of uncertain behavior of placenta',
  'D398 Neoplasm of uncertain behavior of other specified female genital organs',
  'D399 Neoplasm of uncertain behavior of female genital organ, unspecified',
  'D400 Neoplasm of uncertain behavior of prostate',
  'D4010 Neoplasm of uncertain behavior of unspecified testis',
  'D4011 Neoplasm of uncertain behavior of right testis',
  'D4012 Neoplasm of uncertain behavior of left testis',
  'D408 Neoplasm of uncertain behavior of other specified male genital organs',
  'D409 Neoplasm of uncertain behavior of male genital organ, unspecified',
  'D4100 Neoplasm of uncertain behavior of unspecified kidney',
  'D4101 Neoplasm of uncertain behavior of right kidney',
  'D4102 Neoplasm of uncertain behavior of left kidney',
  'D4110 Neoplasm of uncertain behavior of unspecified renal pelvis',
  'D4111 Neoplasm of uncertain behavior of right renal pelvis',
  'D4112 Neoplasm of uncertain behavior of left renal pelvis',
  'D4120 Neoplasm of uncertain behavior of unspecified ureter',
  'D4121 Neoplasm of uncertain behavior of right ureter',
  'D4122 Neoplasm of uncertain behavior of left ureter',
  'D413 Neoplasm of uncertain behavior of urethra',
  'D414 Neoplasm of uncertain behavior of bladder',
  'D418 Neoplasm of uncertain behavior of other specified urinary organs',
  'D419 Neoplasm of uncertain behavior of unspecified urinary organ',
  'D420 Neoplasm of uncertain behavior of cerebral meninges',
  'D421 Neoplasm of uncertain behavior of spinal meninges',
  'D429 Neoplasm of uncertain behavior of meninges, unspecified',
  'D430 Neoplasm of uncertain behavior of brain, supratentorial',
  'D431 Neoplasm of uncertain behavior of brain, infratentorial',
  'D432 Neoplasm of uncertain behavior of brain, unspecified',
  'D433 Neoplasm of uncertain behavior of cranial nerves',
  'D434 Neoplasm of uncertain behavior of spinal cord',
  'D438 Neoplasm of uncertain behavior of other specified parts of central nervous system',
  'D439 Neoplasm of uncertain behavior of central nervous system, unspecified',
  'D440 Neoplasm of uncertain behavior of thyroid gland',
  'D4410 Neoplasm of uncertain behavior of unspecified adrenal gland',
  'D4411 Neoplasm of uncertain behavior of right adrenal gland',
  'D4412 Neoplasm of uncertain behavior of left adrenal gland',
  'D442 Neoplasm of uncertain behavior of parathyroid gland',
  'D443 Neoplasm of uncertain behavior of pituitary gland',
  'D444 Neoplasm of uncertain behavior of craniopharyngeal duct',
  'D445 Neoplasm of uncertain behavior of pineal gland',
  'D446 Neoplasm of uncertain behavior of carotid body',
  'D447 Neoplasm of uncertain behavior of aortic body and other paraganglia',
  'D449 Neoplasm of uncertain behavior of unspecified endocrine gland',
  'D45 Polycythemia vera',
  'D460 Refractory anemia without ring sideroblasts, so stated',
  'D461 Refractory anemia with ring sideroblasts',
  'D4620 Refractory anemia with excess of blasts, unspecified',
  'D4621 Refractory anemia with excess of blasts 1',
  'D4622 Refractory anemia with excess of blasts 2',
  'D46A Refractory cytopenia with multilineage dysplasia',
  'D46B Refractory cytopenia with multilineage dysplasia and ring sideroblasts',
  'D46C Myelodysplastic syndrome with isolated del(5q) chromosomal abnormality',
  'D464 Refractory anemia, unspecified',
  'D46Z Other myelodysplastic syndromes',
  'D469 Myelodysplastic syndrome, unspecified',
  'D470 Histiocytic and mast cell tumors of uncertain behavior',
  'D471 Chronic myeloproliferative disease',
  'D472 Monoclonal gammopathy',
  'D473 Essential (hemorrhagic) thrombocythemia',
  'D474 Osteomyelofibrosis',
  'D47Z1 Post-transplant lymphoproliferative disorder (PTLD)',
  'D47Z2 Castleman disease',
  'D47Z9 Other specified neoplasms of uncertain behavior of lymphoid, hematopoietic and related tissue',
  'D479 Neoplasm of uncertain behavior of lymphoid, hematopoietic and related tissue, unspecified',
  'D480 Neoplasm of uncertain behavior of bone and articular cartilage',
  'D481 Neoplasm of uncertain behavior of connective and other soft tissue',
  'D482 Neoplasm of uncertain behavior of peripheral nerves and autonomic nervous system',
  'D483 Neoplasm of uncertain behavior of retroperitoneum',
  'D484 Neoplasm of uncertain behavior of peritoneum',
  'D485 Neoplasm of uncertain behavior of skin',
  'D4860 Neoplasm of uncertain behavior of unspecified breast',
  'D4861 Neoplasm of uncertain behavior of right breast',
  'D4862 Neoplasm of uncertain behavior of left breast',
  'D487 Neoplasm of uncertain behavior of other specified sites',
  'D489 Neoplasm of uncertain behavior, unspecified',
  'D490 Neoplasm of unspecified behavior of digestive system',
  'D491 Neoplasm of unspecified behavior of respiratory system',
  'D492 Neoplasm of unspecified behavior of bone, soft tissue, and skin',
  'D493 Neoplasm of unspecified behavior of breast',
  'D494 Neoplasm of unspecified behavior of bladder',
  'D49511 Neoplasm of unspecified behavior of right kidney',
  'D49512 Neoplasm of unspecified behavior of left kidney',
  'D49519 Neoplasm of unspecified behavior of unspecified kidney',
  'D4959 Neoplasm of unspecified behavior of other genitourinary organ',
  'D496 Neoplasm of unspecified behavior of brain',
  'D497 Neoplasm of unspecified behavior of endocrine glands and other parts of nervous system',
  'D4981 Neoplasm of unspecified behavior of retina and choroid',
  'D4989 Neoplasm of unspecified behavior of other specified sites',
  'D499 Neoplasm of unspecified behavior of unspecified site',
  'D500 Iron deficiency anemia secondary to blood loss (chronic)',
  'D501 Sideropenic dysphagia',
  'D508 Other iron deficiency anemias',
  'D509 Iron deficiency anemia, unspecified',
  'D510 Vitamin B12 deficiency anemia due to intrinsic factor deficiency',
  'D511 Vitamin B12 deficiency anemia due to selective vitamin B12 malabsorption with proteinuria',
  'D512 Transcobalamin II deficiency',
  'D513 Other dietary vitamin B12 deficiency anemia',
  'D518 Other vitamin B12 deficiency anemias',
  'D519 Vitamin B12 deficiency anemia, unspecified',
  'D520 Dietary folate deficiency anemia',
  'D521 Drug-induced folate deficiency anemia',
  'D528 Other folate deficiency anemias',
  'D529 Folate deficiency anemia, unspecified',
  'D530 Protein deficiency anemia',
  'D531 Other megaloblastic anemias, not elsewhere classified',
  'D532 Scorbutic anemia',
  'D538 Other specified nutritional anemias',
  'D539 Nutritional anemia, unspecified',
  'D550 Anemia due to glucose-6-phosphate dehydrogenase [G6PD] deficiency',
  'D551 Anemia due to other disorders of glutathione metabolism',
  'D552 Anemia due to disorders of glycolytic enzymes',
  'D553 Anemia due to disorders of nucleotide metabolism',
  'D558 Other anemias due to enzyme disorders',
  'D559 Anemia due to enzyme disorder, unspecified',
  'D560 Alpha thalassemia',
  'D561 Beta thalassemia',
  'D562 Delta-beta thalassemia',
  'D563 Thalassemia minor',
  'D564 Hereditary persistence of fetal hemoglobin [HPFH]',
  'D565 Hemoglobin E-beta thalassemia',
  'D568 Other thalassemias',
  'D569 Thalassemia, unspecified',
  'D5700 Hb-SS disease with crisis, unspecified',
  'D5701 Hb-SS disease with acute chest syndrome',
  'D5702 Hb-SS disease with splenic sequestration',
  'D571 Sickle-cell disease without crisis',
  'D5720 Sickle-cell/Hb-C disease without crisis',
  'D57211 Sickle-cell/Hb-C disease with acute chest syndrome',
  'D57212 Sickle-cell/Hb-C disease with splenic sequestration',
  'D57219 Sickle-cell/Hb-C disease with crisis, unspecified',
  'D573 Sickle-cell trait',
  'D5740 Sickle-cell thalassemia without crisis',
  'D57411 Sickle-cell thalassemia with acute chest syndrome',
  'D57412 Sickle-cell thalassemia with splenic sequestration',
  'D57419 Sickle-cell thalassemia with crisis, unspecified',
  'D5780 Other sickle-cell disorders without crisis',
  'D57811 Other sickle-cell disorders with acute chest syndrome',
  'D57812 Other sickle-cell disorders with splenic sequestration',
  'D57819 Other sickle-cell disorders with crisis, unspecified',
  'D580 Hereditary spherocytosis',
  'D581 Hereditary elliptocytosis',
  'D582 Other hemoglobinopathies',
  'D588 Other specified hereditary hemolytic anemias',
  'D589 Hereditary hemolytic anemia, unspecified',
  'D590 Drug-induced autoimmune hemolytic anemia',
  'D591 Other autoimmune hemolytic anemias',
  'D592 Drug-induced nonautoimmune hemolytic anemia',
  'D593 Hemolytic-uremic syndrome',
  'D594 Other nonautoimmune hemolytic anemias',
  'D595 Paroxysmal nocturnal hemoglobinuria [Marchiafava-Micheli]',
  'D596 Hemoglobinuria due to hemolysis from other external causes',
  'D598 Other acquired hemolytic anemias',
  'D599 Acquired hemolytic anemia, unspecified',
  'D600 Chronic acquired pure red cell aplasia',
  'D601 Transient acquired pure red cell aplasia',
  'D608 Other acquired pure red cell aplasias',
  'D609 Acquired pure red cell aplasia, unspecified',
  'D6101 Constitutional (pure) red blood cell aplasia',
  'D6109 Other constitutional aplastic anemia',
  'D611 Drug-induced aplastic anemia',
  'D612 Aplastic anemia due to other external agents',
  'D613 Idiopathic aplastic anemia',
  'D61810 Antineoplastic chemotherapy induced pancytopenia',
  'D61811 Other drug-induced pancytopenia',
  'D61818 Other pancytopenia',
  'D6182 Myelophthisis',
  'D6189 Other specified aplastic anemias and other bone marrow failure syndromes',
  'D619 Aplastic anemia, unspecified',
  'D62 Acute posthemorrhagic anemia',
  'D630 Anemia in neoplastic disease',
  'D631 Anemia in chronic kidney disease',
  'D638 Anemia in other chronic diseases classified elsewhere',
  'D640 Hereditary sideroblastic anemia',
  'D641 Secondary sideroblastic anemia due to disease',
  'D642 Secondary sideroblastic anemia due to drugs and toxins',
  'D643 Other sideroblastic anemias',
  'D644 Congenital dyserythropoietic anemia',
  'D6481 Anemia due to antineoplastic chemotherapy',
  'D6489 Other specified anemias',
  'D649 Anemia, unspecified',
  'D65 Disseminated intravascular coagulation [defibrination syndrome]',
  'D66 Hereditary factor VIII deficiency',
  'D67 Hereditary factor IX deficiency',
  "D680 Von Willebrand's disease",
  'D681 Hereditary factor XI deficiency',
  'D682 Hereditary deficiency of other clotting factors',
  'D68311 Acquired hemophilia',
  'D68312 Antiphospholipid antibody with hemorrhagic disorder',
  'D68318 Other hemorrhagic disorder due to intrinsic circulating anticoagulants, antibodies, or inhibitors',
  'D6832 Hemorrhagic disorder due to extrinsic circulating anticoagulants',
  'D684 Acquired coagulation factor deficiency',
  'D6851 Activated protein C resistance',
  'D6852 Prothrombin gene mutation',
  'D6859 Other primary thrombophilia',
  'D6861 Antiphospholipid syndrome',
  'D6862 Lupus anticoagulant syndrome',
  'D6869 Other thrombophilia',
  'D688 Other specified coagulation defects',
  'D689 Coagulation defect, unspecified',
  'D690 Allergic purpura',
  'D691 Qualitative platelet defects',
  'D692 Other nonthrombocytopenic purpura',
  'D693 Immune thrombocytopenic purpura',
  'D6941 Evans syndrome',
  'D6942 Congenital and hereditary thrombocytopenia purpura',
  'D6949 Other primary thrombocytopenia',
  'D6951 Posttransfusion purpura',
  'D6959 Other secondary thrombocytopenia',
  'D696 Thrombocytopenia, unspecified',
  'D698 Other specified hemorrhagic conditions',
  'D699 Hemorrhagic condition, unspecified',
  'D700 Congenital agranulocytosis',
  'D701 Agranulocytosis secondary to cancer chemotherapy',
  'D702 Other drug-induced agranulocytosis',
  'D703 Neutropenia due to infection',
  'D704 Cyclic neutropenia',
  'D708 Other neutropenia',
  'D709 Neutropenia, unspecified',
  'D71 Functional disorders of polymorphonuclear neutrophils',
  'D720 Genetic anomalies of leukocytes',
  'D721 Eosinophilia',
  'D72810 Lymphocytopenia',
  'D72818 Other decreased white blood cell count',
  'D72819 Decreased white blood cell count, unspecified',
  'D72820 Lymphocytosis (symptomatic)',
  'D72821 Monocytosis (symptomatic)',
  'D72822 Plasmacytosis',
  'D72823 Leukemoid reaction',
  'D72824 Basophilia',
  'D72825 Bandemia',
  'D72828 Other elevated white blood cell count',
  'D72829 Elevated white blood cell count, unspecified',
  'D7289 Other specified disorders of white blood cells',
  'D729 Disorder of white blood cells, unspecified',
  'D730 Hyposplenism',
  'D731 Hypersplenism',
  'D732 Chronic congestive splenomegaly',
  'D733 Abscess of spleen',
  'D734 Cyst of spleen',
  'D735 Infarction of spleen',
  'D7381 Neutropenic splenomegaly',
  'D7389 Other diseases of spleen',
  'D739 Disease of spleen, unspecified',
  'D740 Congenital methemoglobinemia',
  'D748 Other methemoglobinemias',
  'D749 Methemoglobinemia, unspecified',
  'D750 Familial erythrocytosis',
  'D751 Secondary polycythemia',
  'D7581 Myelofibrosis',
  'D7582 Heparin induced thrombocytopenia (HIT)',
  'D7589 Other specified diseases of blood and blood-forming organs',
  'D759 Disease of blood and blood-forming organs, unspecified',
  'D761 Hemophagocytic lymphohistiocytosis',
  'D762 Hemophagocytic syndrome, infection-associated',
  'D763 Other histiocytosis syndromes',
  'D77 Other disorders of blood and blood-forming organs in diseases classified elsewhere',
  'D7801 Intraoperative hemorrhage and hematoma of the spleen complicating a procedure on the',
  'spleen',
  'D7802 Intraoperative hemorrhage and hematoma of the spleen complicating other procedure',
  'D7811 Accidental puncture and laceration of the spleen during a procedure on the spleen',
  'D7812 Accidental puncture and laceration of the spleen during other procedure',
  'D7821 Postprocedural hemorrhage of the spleen following a procedure on the spleen',
  'D7822 Postprocedural hemorrhage of the spleen following other procedure',
  'D7831 Postprocedural hematoma of the spleen following a procedure on the spleen',
  'D7832 Postprocedural hematoma of the spleen following other procedure',
  'D7833 Postprocedural seroma of the spleen following a procedure on the spleen',
  'D7834 Postprocedural seroma of the spleen following other procedure',
  'D7881 Other intraoperative complications of the spleen',
  'D7889 Other postprocedural complications of the spleen',
  'D800 Hereditary hypogammaglobulinemia',
  'D801 Nonfamilial hypogammaglobulinemia',
  'D802 Selective deficiency of immunoglobulin A [IgA]',
  'D803 Selective deficiency of immunoglobulin G [IgG] subclasses',
  'D804 Selective deficiency of immunoglobulin M [IgM]',
  'D805 Immunodeficiency with increased immunoglobulin M [IgM]',
  'D806 Antibody deficiency with near-normal immunoglobulins or with hyperimmunoglobulinemia',
  'D807 Transient hypogammaglobulinemia of infancy',
  'D808 Other immunodeficiencies with predominantly antibody defects',
  'D809 Immunodeficiency with predominantly antibody defects, unspecified',
  'D810 Severe combined immunodeficiency [SCID] with reticular dysgenesis',
  'D811 Severe combined immunodeficiency [SCID] with low T- and B-cell numbers',
  'D812 Severe combined immunodeficiency [SCID] with low or normal B-cell numbers',
  'D813 Adenosine deaminase [ADA] deficiency',
  "D814 Nezelof's syndrome",
  'D815 Purine nucleoside phosphorylase [PNP] deficiency',
  'D816 Major histocompatibility complex class I deficiency',
  'D817 Major histocompatibility complex class II deficiency',
  'D81810 Biotinidase deficiency',
  'D81818 Other biotin-dependent carboxylase deficiency',
  'D81819 Biotin-dependent carboxylase deficiency, unspecified',
  'D8189 Other combined immunodeficiencies',
  'D819 Combined immunodeficiency, unspecified',
  'D820 Wiskott-Aldrich syndrome',
  "D821 Di George's syndrome",
  'D822 Immunodeficiency with short-limbed stature',
  'D823 Immunodeficiency following hereditary defective response to Epstein-Barr virus',
  'D824 Hyperimmunoglobulin E [IgE] syndrome',
  'D828 Immunodeficiency associated with other specified major defects',
  'D829 Immunodeficiency associated with major defect, unspecified',
  'D830 Common variable immunodeficiency with predominant abnormalities of B-cell numbers and',
  'function',
  'D831 Common variable immunodeficiency with predominant immunoregulatory T-cell disorders',
  'D832 Common variable immunodeficiency with autoantibodies to B- or T-cells',
  'D838 Other common variable immunodeficiencies',
  'D839 Common variable immunodeficiency, unspecified',
  'D840 Lymphocyte function antigen-1 [LFA-1] defect',
  'D841 Defects in the complement system',
  'D848 Other specified immunodeficiencies',
  'D849 Immunodeficiency, unspecified',
  'D860 Sarcoidosis of lung',
  'D861 Sarcoidosis of lymph nodes',
  'D862 Sarcoidosis of lung with sarcoidosis of lymph nodes',
  'D863 Sarcoidosis of skin',
  'D8681 Sarcoid meningitis',
  'D8682 Multiple cranial nerve palsies in sarcoidosis',
  'D8683 Sarcoid iridocyclitis',
  'D8684 Sarcoid pyelonephritis',
  'D8685 Sarcoid myocarditis',
  'D8686 Sarcoid arthropathy',
  'D8687 Sarcoid myositis',
  'D8689 Sarcoidosis of other sites',
  'D869 Sarcoidosis, unspecified',
  'D890 Polyclonal hypergammaglobulinemia',
  'D891 Cryoglobulinemia',
  'D892 Hypergammaglobulinemia, unspecified',
  'D893 Immune reconstitution syndrome',
  'D8940 Mast cell activation, unspecified',
  'D8941 Monoclonal mast cell activation syndrome',
  'D8942 Idiopathic mast cell activation syndrome',
  'D8943 Secondary mast cell activation',
  'D8949 Other mast cell activation disorder',
  'D89810 Acute graft-versus-host disease',
  'D89811 Chronic graft-versus-host disease',
  'D89812 Acute on chronic graft-versus-host disease',
  'D89813 Graft-versus-host disease, unspecified',
  'D8982 Autoimmune lymphoproliferative syndrome [ALPS]',
  'D8989 Other specified disorders involving the immune mechanism, not elsewhere classified',
  'D899 Disorder involving the immune mechanism, unspecified',
  'E000 Congenital iodine-deficiency syndrome, neurological type',
  'E001 Congenital iodine-deficiency syndrome, myxedematous type',
  'E002 Congenital iodine-deficiency syndrome, mixed type',
  'E009 Congenital iodine-deficiency syndrome, unspecified',
  'E010 Iodine-deficiency related diffuse (endemic) goiter',
  'E011 Iodine-deficiency related multinodular (endemic) goiter',
  'E012 Iodine-deficiency related (endemic) goiter, unspecified',
  'E018 Other iodine-deficiency related thyroid disorders and allied conditions',
  'E02 Subclinical iodine-deficiency hypothyroidism',
  'E030 Congenital hypothyroidism with diffuse goiter',
  'E031 Congenital hypothyroidism without goiter',
  'E032 Hypothyroidism due to medicaments and other exogenous substances',
  'E033 Postinfectious hypothyroidism',
  'E034 Atrophy of thyroid (acquired)',
  'E035 Myxedema coma',
  'E038 Other specified hypothyroidism',
  'E039 Hypothyroidism, unspecified',
  'E040 Nontoxic diffuse goiter',
  'E041 Nontoxic single thyroid nodule',
  'E042 Nontoxic multinodular goiter',
  'E048 Other specified nontoxic goiter',
  'E049 Nontoxic goiter, unspecified',
  'E0500 Thyrotoxicosis with diffuse goiter without thyrotoxic crisis or storm',
  'E0501 Thyrotoxicosis with diffuse goiter with thyrotoxic crisis or storm',
  'E0510 Thyrotoxicosis with toxic single thyroid nodule without thyrotoxic crisis or storm',
  'E0511 Thyrotoxicosis with toxic single thyroid nodule with thyrotoxic crisis or storm',
  'E0520 Thyrotoxicosis with toxic multinodular goiter without thyrotoxic crisis or storm',
  'E0521 Thyrotoxicosis with toxic multinodular goiter with thyrotoxic crisis or storm',
  'E0530 Thyrotoxicosis from ectopic thyroid tissue without thyrotoxic crisis or storm',
  'E0531 Thyrotoxicosis from ectopic thyroid tissue with thyrotoxic crisis or storm',
  'E0540 Thyrotoxicosis factitia without thyrotoxic crisis or storm',
  'E0541 Thyrotoxicosis factitia with thyrotoxic crisis or storm',
  'E0580 Other thyrotoxicosis without thyrotoxic crisis or storm',
  'E0581 Other thyrotoxicosis with thyrotoxic crisis or storm',
  'E0590 Thyrotoxicosis, unspecified without thyrotoxic crisis or storm',
  'E0591 Thyrotoxicosis, unspecified with thyrotoxic crisis or storm',
  'E060 Acute thyroiditis',
  'E061 Subacute thyroiditis',
  'E062 Chronic thyroiditis with transient thyrotoxicosis',
  'E063 Autoimmune thyroiditis',
  'E064 Drug-induced thyroiditis',
  'E065 Other chronic thyroiditis',
  'E069 Thyroiditis, unspecified',
  'E070 Hypersecretion of calcitonin',
  'E071 Dyshormogenetic goiter',
  'E0781 Sick-euthyroid syndrome',
  'E0789 Other specified disorders of thyroid',
  'E079 Disorder of thyroid, unspecified',
  'E0800 Diabetes mellitus due to underlying condition with hyperosmolarity without nonketotic',
  'hyperglycemic-hyperosmolar coma (NKHHC)',
  'E0801 Diabetes mellitus due to underlying condition with hyperosmolarity with coma',
  'E0810 Diabetes mellitus due to underlying condition with ketoacidosis without coma',
  'E0811 Diabetes mellitus due to underlying condition with ketoacidosis with coma',
  'E0821 Diabetes mellitus due to underlying condition with diabetic nephropathy',
  'E0822 Diabetes mellitus due to underlying condition with diabetic chronic kidney disease',
  'E0829 Diabetes mellitus due to underlying condition with other diabetic kidney complication',
  'E08311 Diabetes mellitus due to underlying condition with unspecified diabetic retinopathy with',
  'macular edema',
  'E08319 Diabetes mellitus due to underlying condition with unspecified diabetic retinopathy without',
  'macular edema',
  'E083211 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'with macular edema, right eye',
  'E083212 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'with macular edema, left eye',
  'E083213 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'with macular edema, bilateral',
  'E083219 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'with macular edema, unspecified eye',
  'E083291 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'without macular edema, right eye',
  'E083292 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'without macular edema, left eye',
  'E083293 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'without macular edema, bilateral',
  'E083299 Diabetes mellitus due to underlying condition with mild nonproliferative diabetic retinopathy',
  'without macular edema, unspecified eye',
  'E083311 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy with macular edema, right eye',
  'E083312 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy with macular edema, left eye',
  'E083313 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy with macular edema, bilateral',
  'E083319 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy with macular edema, unspecified eye',
  'E083391 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy without macular edema, right eye',
  'E083392 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy without macular edema, left eye',
  'E083393 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy without macular edema, bilateral',
  'E083399 Diabetes mellitus due to underlying condition with moderate nonproliferative diabetic',
  'retinopathy without macular edema, unspecified eye',
  'E083411 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'with macular edema, right eye',
  'E083412 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'with macular edema, left eye',
  'E083413 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'with macular edema, bilateral',
  'E083419 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'with macular edema, unspecified eye',
  'E083491 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'without macular edema, right eye',
  'E083492 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'without macular edema, left eye',
  'E083493 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'without macular edema, bilateral',
  'E083499 Diabetes mellitus due to underlying condition with severe nonproliferative diabetic retinopathy',
  'without macular edema, unspecified eye',
  'E083511 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'macular edema, right eye',
  'E083512 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'macular edema, left eye',
  'E083513 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'macular edema, bilateral',
  'E083519 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'macular edema, unspecified eye',
  'E083521 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment involving the macula, right eye',
  'E083522 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment involving the macula, left eye',
  'E083523 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment involving the macula, bilateral',
  'E083529 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment involving the macula, unspecified eye',
  'E083531 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment not involving the macula, right eye',
  'E083532 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment not involving the macula, left eye',
  'E083533 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment not involving the macula, bilateral',
  'E083539 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'traction retinal detachment not involving the macula, unspecified eye',
  'E083541 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, right eye',
  'E083542 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, left eye',
  'E083543 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral',
  'E083549 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, unspecified eye',
  'E083551 Diabetes mellitus due to underlying condition with stable proliferative diabetic retinopathy,',
  'right eye',
  'E083552 Diabetes mellitus due to underlying condition with stable proliferative diabetic retinopathy, left',
  'eye',
  'E083553 Diabetes mellitus due to underlying condition with stable proliferative diabetic retinopathy,',
  'bilateral',
  'E083559 Diabetes mellitus due to underlying condition with stable proliferative diabetic retinopathy,',
  'unspecified eye',
  'E083591 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy without',
  'macular edema, right eye',
  'E083592 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy without',
  'macular edema, left eye',
  'E083593 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy without',
  'macular edema, bilateral',
  'E083599 Diabetes mellitus due to underlying condition with proliferative diabetic retinopathy without',
  'macular edema, unspecified eye',
  'E0836 Diabetes mellitus due to underlying condition with diabetic cataract',
  'E0837X1 Diabetes mellitus due to underlying condition with diabetic macular edema, resolved following',
  'treatment, right eye',
  'E0837X2 Diabetes mellitus due to underlying condition with diabetic macular edema, resolved following',
  'treatment, left eye',
  'E0837X3 Diabetes mellitus due to underlying condition with diabetic macular edema, resolved following',
  'treatment, bilateral',
  'E0837X9 Diabetes mellitus due to underlying condition with diabetic macular edema, resolved following',
  'treatment, unspecified eye',
  'E0839 Diabetes mellitus due to underlying condition with other diabetic ophthalmic complication',
  'E0840 Diabetes mellitus due to underlying condition with diabetic neuropathy, unspecified',
  'E0841 Diabetes mellitus due to underlying condition with diabetic mononeuropathy',
  'E0842 Diabetes mellitus due to underlying condition with diabetic polyneuropathy',
  'E0843 Diabetes mellitus due to underlying condition with diabetic autonomic (poly)neuropathy',
  'E0844 Diabetes mellitus due to underlying condition with diabetic amyotrophy',
  'E0849 Diabetes mellitus due to underlying condition with other diabetic neurological complication',
  'E0851 Diabetes mellitus due to underlying condition with diabetic peripheral angiopathy without',
  'gangrene',
  'E0852 Diabetes mellitus due to underlying condition with diabetic peripheral angiopathy with gangrene',
  'E0859 Diabetes mellitus due to underlying condition with other circulatory complications',
  'E08610 Diabetes mellitus due to underlying condition with diabetic neuropathic arthropathy',
  'E08618 Diabetes mellitus due to underlying condition with other diabetic arthropathy',
  'E08620 Diabetes mellitus due to underlying condition with diabetic dermatitis',
  'E08621 Diabetes mellitus due to underlying condition with foot ulcer',
  'E08622 Diabetes mellitus due to underlying condition with other skin ulcer',
  'E08628 Diabetes mellitus due to underlying condition with other skin complications',
  'E08630 Diabetes mellitus due to underlying condition with periodontal disease',
  'E08638 Diabetes mellitus due to underlying condition with other oral complications',
  'E08641 Diabetes mellitus due to underlying condition with hypoglycemia with coma',
  'E08649 Diabetes mellitus due to underlying condition with hypoglycemia without coma',
  'E0865 Diabetes mellitus due to underlying condition with hyperglycemia',
  'E0869 Diabetes mellitus due to underlying condition with other specified complication',
  'E088 Diabetes mellitus due to underlying condition with unspecified complications',
  'E089 Diabetes mellitus due to underlying condition without complications',
  'E0900 Drug or chemical induced diabetes mellitus with hyperosmolarity without nonketotic',
  'hyperglycemic-hyperosmolar coma (NKHHC)',
  'E0901 Drug or chemical induced diabetes mellitus with hyperosmolarity with coma',
  'E0910 Drug or chemical induced diabetes mellitus with ketoacidosis without coma',
  'E0911 Drug or chemical induced diabetes mellitus with ketoacidosis with coma',
  'E0921 Drug or chemical induced diabetes mellitus with diabetic nephropathy',
  'E0922 Drug or chemical induced diabetes mellitus with diabetic chronic kidney disease',
  'E0929 Drug or chemical induced diabetes mellitus with other diabetic kidney complication',
  'E09311 Drug or chemical induced diabetes mellitus with unspecified diabetic retinopathy with macular',
  'edema',
  'E09319 Drug or chemical induced diabetes mellitus with unspecified diabetic retinopathy without',
  'macular edema',
  'E093211 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'with macular edema, right eye',
  'E093212 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'with macular edema, left eye',
  'E093213 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'with macular edema, bilateral',
  'E093219 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'with macular edema, unspecified eye',
  'E093291 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'without macular edema, right eye',
  'E093292 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'without macular edema, left eye',
  'E093293 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'without macular edema, bilateral',
  'E093299 Drug or chemical induced diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'without macular edema, unspecified eye',
  'E093311 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy with macular edema, right eye',
  'E093312 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy with macular edema, left eye',
  'E093313 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy with macular edema, bilateral',
  'E093319 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy with macular edema, unspecified eye',
  'E093391 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy without macular edema, right eye',
  'E093392 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy without macular edema, left eye',
  'E093393 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy without macular edema, bilateral',
  'E093399 Drug or chemical induced diabetes mellitus with moderate nonproliferative diabetic',
  'retinopathy without macular edema, unspecified eye',
  'E093411 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'with macular edema, right eye',
  'E093412 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'with macular edema, left eye',
  'E093413 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'with macular edema, bilateral',
  'E093419 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'with macular edema, unspecified eye',
  'E093491 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'without macular edema, right eye',
  'E093492 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'without macular edema, left eye',
  'E093493 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'without macular edema, bilateral',
  'E093499 Drug or chemical induced diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'without macular edema, unspecified eye',
  'E093511 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'macular edema, right eye',
  'E093512 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'macular edema, left eye',
  'E093513 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'macular edema, bilateral',
  'E093519 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'macular edema, unspecified eye',
  'E093521 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment involving the macula, right eye',
  'E093522 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment involving the macula, left eye',
  'E093523 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment involving the macula, bilateral',
  'E093529 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment involving the macula, unspecified eye',
  'E093531 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment not involving the macula, right eye',
  'E093532 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment not involving the macula, left eye',
  'E093533 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment not involving the macula, bilateral',
  'E093539 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with traction',
  'retinal detachment not involving the macula, unspecified eye',
  'E093541 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, right eye',
  'E093542 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, left eye',
  'E093543 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, bilateral',
  'E093549 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy with',
  'combined traction retinal detachment and rhegmatogenous retinal detachment, unspecified eye',
  'E093551 Drug or chemical induced diabetes mellitus with stable proliferative diabetic retinopathy, right',
  'eye',
  'E093552 Drug or chemical induced diabetes mellitus with stable proliferative diabetic retinopathy, left',
  'eye',
  'E093553 Drug or chemical induced diabetes mellitus with stable proliferative diabetic retinopathy,',
  'bilateral',
  'E093559 Drug or chemical induced diabetes mellitus with stable proliferative diabetic retinopathy,',
  'unspecified eye',
  'E093591 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy without',
  'macular edema, right eye',
  'E093592 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy without',
  'macular edema, left eye',
  'E093593 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy without',
  'macular edema, bilateral',
  'E093599 Drug or chemical induced diabetes mellitus with proliferative diabetic retinopathy without',
  'macular edema, unspecified eye',
  'E0936 Drug or chemical induced diabetes mellitus with diabetic cataract',
  'E0937X1 Drug or chemical induced diabetes mellitus with diabetic macular edema, resolved following',
  'treatment, right eye',
  'E0937X2 Drug or chemical induced diabetes mellitus with diabetic macular edema, resolved following',
  'treatment, left eye',
  'E0937X3 Drug or chemical induced diabetes mellitus with diabetic macular edema, resolved following',
  'treatment, bilateral',
  'E0937X9 Drug or chemical induced diabetes mellitus with diabetic macular edema, resolved following',
  'treatment, unspecified eye',
  'E0939 Drug or chemical induced diabetes mellitus with other diabetic ophthalmic complication',
  'E0940 Drug or chemical induced diabetes mellitus with neurological complications with diabetic',
  'neuropathy, unspecified',
  'E0941 Drug or chemical induced diabetes mellitus with neurological complications with diabetic',
  'mononeuropathy',
  'E0942 Drug or chemical induced diabetes mellitus with neurological complications with diabetic',
  'polyneuropathy',
  'E0943 Drug or chemical induced diabetes mellitus with neurological complications with diabetic',
  'autonomic (poly)neuropathy',
  'E0944 Drug or chemical induced diabetes mellitus with neurological complications with diabetic',
  'amyotrophy',
  'E0949 Drug or chemical induced diabetes mellitus with neurological complications with other diabetic',
  'neurological complication',
  'E0951 Drug or chemical induced diabetes mellitus with diabetic peripheral angiopathy without',
  'gangrene',
  'E0952 Drug or chemical induced diabetes mellitus with diabetic peripheral angiopathy with gangrene',
  'E0959 Drug or chemical induced diabetes mellitus with other circulatory complications',
  'E09610 Drug or chemical induced diabetes mellitus with diabetic neuropathic arthropathy',
  'E09618 Drug or chemical induced diabetes mellitus with other diabetic arthropathy',
  'E09620 Drug or chemical induced diabetes mellitus with diabetic dermatitis',
  'E09621 Drug or chemical induced diabetes mellitus with foot ulcer',
  'E09622 Drug or chemical induced diabetes mellitus with other skin ulcer',
  'E09628 Drug or chemical induced diabetes mellitus with other skin complications',
  'E09630 Drug or chemical induced diabetes mellitus with periodontal disease',
  'E09638 Drug or chemical induced diabetes mellitus with other oral complications',
  'E09641 Drug or chemical induced diabetes mellitus with hypoglycemia with coma',
  'E09649 Drug or chemical induced diabetes mellitus with hypoglycemia without coma',
  'E0965 Drug or chemical induced diabetes mellitus with hyperglycemia',
  'E0969 Drug or chemical induced diabetes mellitus with other specified complication',
  'E098 Drug or chemical induced diabetes mellitus with unspecified complications',
  'E099 Drug or chemical induced diabetes mellitus without complications',
  'E1010 Type 1 diabetes mellitus with ketoacidosis without coma',
  'E1011 Type 1 diabetes mellitus with ketoacidosis with coma',
  'E1021 Type 1 diabetes mellitus with diabetic nephropathy',
  'E1022 Type 1 diabetes mellitus with diabetic chronic kidney disease',
  'E1029 Type 1 diabetes mellitus with other diabetic kidney complication',
  'E10311 Type 1 diabetes mellitus with unspecified diabetic retinopathy with macular edema',
  'E10319 Type 1 diabetes mellitus with unspecified diabetic retinopathy without macular edema',
  'E103211 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'right eye',
  'E103212 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'left eye',
  'E103213 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'bilateral',
  'E103219 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'unspecified eye',
  'E103291 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, right eye',
  'E103292 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, left eye',
  'E103293 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, bilateral',
  'E103299 Type 1 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, unspecified eye',
  'E103311 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, right eye',
  'E103312 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, left eye',
  'E103313 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, bilateral',
  'E103319 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, unspecified eye',
  'E103391 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, right eye',
  'E103392 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, left eye',
  'E103393 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, bilateral',
  'E103399 Type 1 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, unspecified eye',
  'E103411 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, right eye',
  'E103412 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, left eye',
  'E103413 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, bilateral',
  'E103419 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, unspecified eye',
  'E103491 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, right eye',
  'E103492 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, left eye',
  'E103493 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, bilateral',
  'E103499 Type 1 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, unspecified eye',
  'E103511 Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye',
  'E103512 Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye',
  'E103513 Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral',
  'E103519 Type 1 diabetes mellitus with proliferative diabetic retinopathy with macular edema,',
  'unspecified eye',
  'E103521 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, right eye',
  'E103522 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, left eye',
  'E103523 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, bilateral',
  'E103529 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, unspecified eye',
  'E103531 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, right eye',
  'E103532 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, left eye',
  'E103533 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, bilateral',
  'E103539 Type 1 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, unspecified eye',
  'E103541 Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, right eye',
  'E103542 Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, left eye',
  'E103543 Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, bilateral',
  'E103549 Type 1 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, unspecified eye',
  'E103551 Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, right eye',
  'E103552 Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, left eye',
  'E103553 Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, bilateral',
  'E103559 Type 1 diabetes mellitus with stable proliferative diabetic retinopathy, unspecified eye',
  'E103591 Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right',
  'eye',
  'E103592 Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left',
  'eye',
  'E103593 Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema,',
  'bilateral',
  'E103599 Type 1 diabetes mellitus with proliferative diabetic retinopathy without macular edema,',
  'unspecified eye',
  'E1036 Type 1 diabetes mellitus with diabetic cataract',
  'E1037X1 Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye',
  'E1037X2 Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye',
  'E1037X3 Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral',
  'E1037X9 Type 1 diabetes mellitus with diabetic macular edema, resolved following treatment,',
  'unspecified eye',
  'E1039 Type 1 diabetes mellitus with other diabetic ophthalmic complication',
  'E1040 Type 1 diabetes mellitus with diabetic neuropathy, unspecified',
  'E1041 Type 1 diabetes mellitus with diabetic mononeuropathy',
  'E1042 Type 1 diabetes mellitus with diabetic polyneuropathy',
  'E1043 Type 1 diabetes mellitus with diabetic autonomic (poly)neuropathy',
  'E1044 Type 1 diabetes mellitus with diabetic amyotrophy',
  'E1049 Type 1 diabetes mellitus with other diabetic neurological complication',
  'E1051 Type 1 diabetes mellitus with diabetic peripheral angiopathy without gangrene',
  'E1052 Type 1 diabetes mellitus with diabetic peripheral angiopathy with gangrene',
  'E1059 Type 1 diabetes mellitus with other circulatory complications',
  'E10610 Type 1 diabetes mellitus with diabetic neuropathic arthropathy',
  'E10618 Type 1 diabetes mellitus with other diabetic arthropathy',
  'E10620 Type 1 diabetes mellitus with diabetic dermatitis',
  'E10621 Type 1 diabetes mellitus with foot ulcer',
  'E10622 Type 1 diabetes mellitus with other skin ulcer',
  'E10628 Type 1 diabetes mellitus with other skin complications',
  'E10630 Type 1 diabetes mellitus with periodontal disease',
  'E10638 Type 1 diabetes mellitus with other oral complications',
  'E10641 Type 1 diabetes mellitus with hypoglycemia with coma',
  'E10649 Type 1 diabetes mellitus with hypoglycemia without coma',
  'E1065 Type 1 diabetes mellitus with hyperglycemia',
  'E1069 Type 1 diabetes mellitus with other specified complication',
  'E108 Type 1 diabetes mellitus with unspecified complications',
  'E109 Type 1 diabetes mellitus without complications',
  'E1100 Type 2 diabetes mellitus with hyperosmolarity without nonketotic hyperglycemic-hyperosmolar',
  'coma (NKHHC)',
  'E1101 Type 2 diabetes mellitus with hyperosmolarity with coma',
  'E1121 Type 2 diabetes mellitus with diabetic nephropathy',
  'E1122 Type 2 diabetes mellitus with diabetic chronic kidney disease',
  'E1129 Type 2 diabetes mellitus with other diabetic kidney complication',
  'E11311 Type 2 diabetes mellitus with unspecified diabetic retinopathy with macular edema',
  'E11319 Type 2 diabetes mellitus with unspecified diabetic retinopathy without macular edema',
  'E113211 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'right eye',
  'E113212 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'left eye',
  'E113213 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'bilateral',
  'E113219 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy with macular edema,',
  'unspecified eye',
  'E113291 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, right eye',
  'E113292 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, left eye',
  'E113293 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, bilateral',
  'E113299 Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy without macular',
  'edema, unspecified eye',
  'E113311 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, right eye',
  'E113312 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, left eye',
  'E113313 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, bilateral',
  'E113319 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy with macular',
  'edema, unspecified eye',
  'E113391 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, right eye',
  'E113392 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, left eye',
  'E113393 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, bilateral',
  'E113399 Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy without macular',
  'edema, unspecified eye',
  'E113411 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, right eye',
  'E113412 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, left eye',
  'E113413 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, bilateral',
  'E113419 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy with macular',
  'edema, unspecified eye',
  'E113491 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, right eye',
  'E113492 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, left eye',
  'E113493 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, bilateral',
  'E113499 Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy without macular',
  'edema, unspecified eye',
  'E113511 Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, right eye',
  'E113512 Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, left eye',
  'E113513 Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema, bilateral',
  'E113519 Type 2 diabetes mellitus with proliferative diabetic retinopathy with macular edema,',
  'unspecified eye',
  'E113521 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, right eye',
  'E113522 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, left eye',
  'E113523 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, bilateral',
  'E113529 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, unspecified eye',
  'E113531 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, right eye',
  'E113532 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, left eye',
  'E113533 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, bilateral',
  'E113539 Type 2 diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, unspecified eye',
  'E113541 Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, right eye',
  'E113542 Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, left eye',
  'E113543 Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, bilateral',
  'E113549 Type 2 diabetes mellitus with proliferative diabetic retinopathy with combined traction retinal',
  'detachment and rhegmatogenous retinal detachment, unspecified eye',
  'E113551 Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, right eye',
  'E113552 Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, left eye',
  'E113553 Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, bilateral',
  'E113559 Type 2 diabetes mellitus with stable proliferative diabetic retinopathy, unspecified eye',
  'E113591 Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, right',
  'eye',
  'E113592 Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema, left',
  'eye',
  'E113593 Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema,',
  'bilateral',
  'E113599 Type 2 diabetes mellitus with proliferative diabetic retinopathy without macular edema,',
  'unspecified eye',
  'E1136 Type 2 diabetes mellitus with diabetic cataract',
  'E1137X1 Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, right eye',
  'E1137X2 Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, left eye',
  'E1137X3 Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment, bilateral',
  'E1137X9 Type 2 diabetes mellitus with diabetic macular edema, resolved following treatment,',
  'unspecified eye',
  'E1139 Type 2 diabetes mellitus with other diabetic ophthalmic complication',
  'E1140 Type 2 diabetes mellitus with diabetic neuropathy, unspecified',
  'E1141 Type 2 diabetes mellitus with diabetic mononeuropathy',
  'E1142 Type 2 diabetes mellitus with diabetic polyneuropathy',
  'E1143 Type 2 diabetes mellitus with diabetic autonomic (poly)neuropathy',
  'E1144 Type 2 diabetes mellitus with diabetic amyotrophy',
  'E1149 Type 2 diabetes mellitus with other diabetic neurological complication',
  'E1151 Type 2 diabetes mellitus with diabetic peripheral angiopathy without gangrene',
  'E1152 Type 2 diabetes mellitus with diabetic peripheral angiopathy with gangrene',
  'E1159 Type 2 diabetes mellitus with other circulatory complications',
  'E11610 Type 2 diabetes mellitus with diabetic neuropathic arthropathy',
  'E11618 Type 2 diabetes mellitus with other diabetic arthropathy',
  'E11620 Type 2 diabetes mellitus with diabetic dermatitis',
  'E11621 Type 2 diabetes mellitus with foot ulcer',
  'E11622 Type 2 diabetes mellitus with other skin ulcer',
  'E11628 Type 2 diabetes mellitus with other skin complications',
  'E11630 Type 2 diabetes mellitus with periodontal disease',
  'E11638 Type 2 diabetes mellitus with other oral complications',
  'E11641 Type 2 diabetes mellitus with hypoglycemia with coma',
  'E11649 Type 2 diabetes mellitus with hypoglycemia without coma',
  'E1165 Type 2 diabetes mellitus with hyperglycemia',
  'E1169 Type 2 diabetes mellitus with other specified complication',
  'E118 Type 2 diabetes mellitus with unspecified complications',
  'E119 Type 2 diabetes mellitus without complications',
  'E1300 Other specified diabetes mellitus with hyperosmolarity without nonketotic hyperglycemichyperosmolar coma (NKHHC)',
  'E1301 Other specified diabetes mellitus with hyperosmolarity with coma',
  'E1310 Other specified diabetes mellitus with ketoacidosis without coma',
  'E1311 Other specified diabetes mellitus with ketoacidosis with coma',
  'E1321 Other specified diabetes mellitus with diabetic nephropathy',
  'E1322 Other specified diabetes mellitus with diabetic chronic kidney disease',
  'E1329 Other specified diabetes mellitus with other diabetic kidney complication',
  'E13311 Other specified diabetes mellitus with unspecified diabetic retinopathy with macular edema',
  'E13319 Other specified diabetes mellitus with unspecified diabetic retinopathy without macular edema',
  'E133211 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular',
  'edema, right eye',
  'E133212 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular',
  'edema, left eye',
  'E133213 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular',
  'edema, bilateral',
  'E133219 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy with macular',
  'edema, unspecified eye',
  'E133291 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without',
  'macular edema, right eye',
  'E133292 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without',
  'macular edema, left eye',
  'E133293 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without',
  'macular edema, bilateral',
  'E133299 Other specified diabetes mellitus with mild nonproliferative diabetic retinopathy without',
  'macular edema, unspecified eye',
  'E133311 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with',
  'macular edema, right eye',
  'E133312 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with',
  'macular edema, left eye',
  'E133313 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with',
  'macular edema, bilateral',
  'E133319 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy with',
  'macular edema, unspecified eye',
  'E133391 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without',
  'macular edema, right eye',
  'E133392 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without',
  'macular edema, left eye',
  'E133393 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without',
  'macular edema, bilateral',
  'E133399 Other specified diabetes mellitus with moderate nonproliferative diabetic retinopathy without',
  'macular edema, unspecified eye',
  'E133411 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with',
  'macular edema, right eye',
  'E133412 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with',
  'macular edema, left eye',
  'E133413 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with',
  'macular edema, bilateral',
  'E133419 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy with',
  'macular edema, unspecified eye',
  'E133491 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without',
  'macular edema, right eye',
  'E133492 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without',
  'macular edema, left eye',
  'E133493 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without',
  'macular edema, bilateral',
  'E133499 Other specified diabetes mellitus with severe nonproliferative diabetic retinopathy without',
  'macular edema, unspecified eye',
  'E133511 Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema,',
  'right eye',
  'E133512 Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema,',
  'left eye',
  'E133513 Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema,',
  'bilateral',
  'E133519 Other specified diabetes mellitus with proliferative diabetic retinopathy with macular edema,',
  'unspecified eye',
  'E133521 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, right eye',
  'E133522 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, left eye',
  'E133523 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, bilateral',
  'E133529 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment involving the macula, unspecified eye',
  'E133531 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, right eye',
  'E133532 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, left eye',
  'E133533 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, bilateral',
  'E133539 Other specified diabetes mellitus with proliferative diabetic retinopathy with traction retinal',
  'detachment not involving the macula, unspecified eye',
  'E133541 Other specified diabetes mellitus with proliferative diabetic retinopathy with combined',
  'traction retinal detachment and rhegmatogenous retinal detachment, right eye',
  'E133542 Other specified diabetes mellitus with proliferative diabetic retinopathy with combined',
  'traction retinal detachment and rhegmatogenous retinal detachment, left eye',
  'E133543 Other specified diabetes mellitus with proliferative diabetic retinopathy with combined',
  'traction retinal detachment and rhegmatogenous retinal detachment, bilateral',
  'E133549 Other specified diabetes mellitus with proliferative diabetic retinopathy with combined',
  'traction retinal detachment and rhegmatogenous retinal detachment, unspecified eye',
  'E133551 Other specified diabetes mellitus with stable proliferative diabetic retinopathy, right eye',
  'E133552 Other specified diabetes mellitus with stable proliferative diabetic retinopathy, left eye',
  'E133553 Other specified diabetes mellitus with stable proliferative diabetic retinopathy, bilateral',
  'E133559 Other specified diabetes mellitus with stable proliferative diabetic retinopathy, unspecified eye',
  'E133591 Other specified diabetes mellitus with proliferative diabetic retinopathy without macular',
  'edema, right eye',
  'E133592 Other specified diabetes mellitus with proliferative diabetic retinopathy without macular',
  'edema, left eye',
  'E133593 Other specified diabetes mellitus with proliferative diabetic retinopathy without macular',
  'edema, bilateral',
  'E133599 Other specified diabetes mellitus with proliferative diabetic retinopathy without macular',
  'edema, unspecified eye',
  'E1336 Other specified diabetes mellitus with diabetic cataract',
  'E1337X1 Other specified diabetes mellitus with diabetic macular edema, resolved following treatment,',
  'right eye',
  'E1337X2 Other specified diabetes mellitus with diabetic macular edema, resolved following treatment,',
  'left eye',
  'E1337X3 Other specified diabetes mellitus with diabetic macular edema, resolved following treatment,',
  'bilateral',
  'E1337X9 Other specified diabetes mellitus with diabetic macular edema, resolved following treatment,',
  'unspecified eye',
  'E1339 Other specified diabetes mellitus with other diabetic ophthalmic complication',
  'E1340 Other specified diabetes mellitus with diabetic neuropathy, unspecified',
  'E1341 Other specified diabetes mellitus with diabetic mononeuropathy',
  'E1342 Other specified diabetes mellitus with diabetic polyneuropathy',
  'E1343 Other specified diabetes mellitus with diabetic autonomic (poly)neuropathy',
  'E1344 Other specified diabetes mellitus with diabetic amyotrophy',
  'E1349 Other specified diabetes mellitus with other diabetic neurological complication',
  'E1351 Other specified diabetes mellitus with diabetic peripheral angiopathy without gangrene',
  'E1352 Other specified diabetes mellitus with diabetic peripheral angiopathy with gangrene',
  'E1359 Other specified diabetes mellitus with other circulatory complications',
  'E13610 Other specified diabetes mellitus with diabetic neuropathic arthropathy',
  'E13618 Other specified diabetes mellitus with other diabetic arthropathy',
  'E13620 Other specified diabetes mellitus with diabetic dermatitis',
  'E13621 Other specified diabetes mellitus with foot ulcer',
  'E13622 Other specified diabetes mellitus with other skin ulcer',
  'E13628 Other specified diabetes mellitus with other skin complications',
  'E13630 Other specified diabetes mellitus with periodontal disease',
  'E13638 Other specified diabetes mellitus with other oral complications',
  'E13641 Other specified diabetes mellitus with hypoglycemia with coma',
  'E13649 Other specified diabetes mellitus with hypoglycemia without coma',
  'E1365 Other specified diabetes mellitus with hyperglycemia',
  'E1369 Other specified diabetes mellitus with other specified complication',
  'E138 Other specified diabetes mellitus with unspecified complications',
  'E139 Other specified diabetes mellitus without complications',
  'E15 Nondiabetic hypoglycemic coma',
  'E160 Drug-induced hypoglycemia without coma',
  'E161 Other hypoglycemia',
  'E162 Hypoglycemia, unspecified',
  'E163 Increased secretion of glucagon',
  'E164 Increased secretion of gastrin',
  'E168 Other specified disorders of pancreatic internal secretion',
  'E169 Disorder of pancreatic internal secretion, unspecified',
  'E200 Idiopathic hypoparathyroidism',
  'E201 Pseudohypoparathyroidism',
  'E208 Other hypoparathyroidism',
  'E209 Hypoparathyroidism, unspecified',
  'E210 Primary hyperparathyroidism',
  'E211 Secondary hyperparathyroidism, not elsewhere classified',
  'E212 Other hyperparathyroidism',
  'E213 Hyperparathyroidism, unspecified',
  'E214 Other specified disorders of parathyroid gland',
  'E215 Disorder of parathyroid gland, unspecified',
  'E220 Acromegaly and pituitary gigantism',
  'E221 Hyperprolactinemia',
  'E222 Syndrome of inappropriate secretion of antidiuretic hormone',
  'E228 Other hyperfunction of pituitary gland',
  'E229 Hyperfunction of pituitary gland, unspecified',
  'E230 Hypopituitarism',
  'E231 Drug-induced hypopituitarism',
  'E232 Diabetes insipidus',
  'E233 Hypothalamic dysfunction, not elsewhere classified',
  'E236 Other disorders of pituitary gland',
  'E237 Disorder of pituitary gland, unspecified',
  "E240 Pituitary-dependent Cushing's disease",
  "E241 Nelson's syndrome",
  "E242 Drug-induced Cushing's syndrome",
  'E243 Ectopic ACTH syndrome',
  "E244 Alcohol-induced pseudo-Cushing's syndrome",
  "E248 Other Cushing's syndrome",
  "E249 Cushing's syndrome, unspecified",
  'E250 Congenital adrenogenital disorders associated with enzyme deficiency',
  'E258 Other adrenogenital disorders',
  'E259 Adrenogenital disorder, unspecified',
  "E2601 Conn's syndrome",
  'E2602 Glucocorticoid-remediable aldosteronism',
  'E2609 Other primary hyperaldosteronism',
  'E261 Secondary hyperaldosteronism',
  "E2681 Bartter's syndrome",
  'E2689 Other hyperaldosteronism',
  'E269 Hyperaldosteronism, unspecified',
  'E270 Other adrenocortical overactivity',
  'E271 Primary adrenocortical insufficiency',
  'E272 Addisonian crisis',
  'E273 Drug-induced adrenocortical insufficiency',
  'E2740 Unspecified adrenocortical insufficiency',
  'E2749 Other adrenocortical insufficiency',
  'E275 Adrenomedullary hyperfunction',
  'E278 Other specified disorders of adrenal gland',
  'E279 Disorder of adrenal gland, unspecified',
  'E280 Estrogen excess',
  'E281 Androgen excess',
  'E282 Polycystic ovarian syndrome',
  'E28310 Symptomatic premature menopause',
  'E28319 Asymptomatic premature menopause',
  'E2839 Other primary ovarian failure',
  'E288 Other ovarian dysfunction',
  'E289 Ovarian dysfunction, unspecified',
  'E290 Testicular hyperfunction',
  'E291 Testicular hypofunction',
  'E298 Other testicular dysfunction',
  'E299 Testicular dysfunction, unspecified',
  'E300 Delayed puberty',
  'E301 Precocious puberty',
  'E308 Other disorders of puberty',
  'E309 Disorder of puberty, unspecified',
  'E310 Autoimmune polyglandular failure',
  'E311 Polyglandular hyperfunction',
  'E3120 Multiple endocrine neoplasia [MEN] syndrome, unspecified',
  'E3121 Multiple endocrine neoplasia [MEN] type I',
  'E3122 Multiple endocrine neoplasia [MEN] type IIA',
  'E3123 Multiple endocrine neoplasia [MEN] type IIB',
  'E318 Other polyglandular dysfunction',
  'E319 Polyglandular dysfunction, unspecified',
  'E320 Persistent hyperplasia of thymus',
  'E321 Abscess of thymus',
  'E328 Other diseases of thymus',
  'E329 Disease of thymus, unspecified',
  'E340 Carcinoid syndrome',
  'E341 Other hypersecretion of intestinal hormones',
  'E342 Ectopic hormone secretion, not elsewhere classified',
  'E343 Short stature due to endocrine disorder',
  'E344 Constitutional tall stature',
  'E3450 Androgen insensitivity syndrome, unspecified',
  'E3451 Complete androgen insensitivity syndrome',
  'E3452 Partial androgen insensitivity syndrome',
  'E348 Other specified endocrine disorders',
  'E349 Endocrine disorder, unspecified',
  'E35 Disorders of endocrine glands in diseases classified elsewhere',
  'E3601 Intraoperative hemorrhage and hematoma of an endocrine system organ or structure',
  'complicating an endocrine system procedure',
  'E3602 Intraoperative hemorrhage and hematoma of an endocrine system organ or structure',
  'complicating other procedure',
  'E3611 Accidental puncture and laceration of an endocrine system organ or structure during an',
  'endocrine system procedure',
  'E3612 Accidental puncture and laceration of an endocrine system organ or structure during other',
  'procedure',
  'E368 Other intraoperative complications of endocrine system',
  'E40 Kwashiorkor',
  'E41 Nutritional marasmus',
  'E42 Marasmic kwashiorkor',
  'E43 Unspecified severe protein-calorie malnutrition',
  'E440 Moderate protein-calorie malnutrition',
  'E441 Mild protein-calorie malnutrition',
  'E45 Retarded development following protein-calorie malnutrition',
  'E46 Unspecified protein-calorie malnutrition',
  'E500 Vitamin A deficiency with conjunctival xerosis',
  "E501 Vitamin A deficiency with Bitot's spot and conjunctival xerosis",
  'E502 Vitamin A deficiency with corneal xerosis',
  'E503 Vitamin A deficiency with corneal ulceration and xerosis',
  'E504 Vitamin A deficiency with keratomalacia',
  'E505 Vitamin A deficiency with night blindness',
  'E506 Vitamin A deficiency with xerophthalmic scars of cornea',
  'E507 Other ocular manifestations of vitamin A deficiency',
  'E508 Other manifestations of vitamin A deficiency',
  'E509 Vitamin A deficiency, unspecified',
  'E5111 Dry beriberi',
  'E5112 Wet beriberi',
  "E512 Wernicke's encephalopathy",
  'E518 Other manifestations of thiamine deficiency',
  'E519 Thiamine deficiency, unspecified',
  'E52 Niacin deficiency [pellagra]',
  'E530 Riboflavin deficiency',
  'E531 Pyridoxine deficiency',
  'E538 Deficiency of other specified B group vitamins',
  'E539 Vitamin B deficiency, unspecified',
  'E54 Ascorbic acid deficiency',
  'E550 Rickets, active',
  'E559 Vitamin D deficiency, unspecified',
  'E560 Deficiency of vitamin E',
  'E561 Deficiency of vitamin K',
  'E568 Deficiency of other vitamins',
  'E569 Vitamin deficiency, unspecified',
  'E58 Dietary calcium deficiency',
  'E59 Dietary selenium deficiency',
  'E60 Dietary zinc deficiency',
  'E610 Copper deficiency',
  'E611 Iron deficiency',
  'E612 Magnesium deficiency',
  'E613 Manganese deficiency',
  'E614 Chromium deficiency',
  'E615 Molybdenum deficiency',
  'E616 Vanadium deficiency',
  'E617 Deficiency of multiple nutrient elements',
  'E618 Deficiency of other specified nutrient elements',
  'E619 Deficiency of nutrient element, unspecified',
  'E630 Essential fatty acid [EFA] deficiency',
  'E631 Imbalance of constituents of food intake',
  'E638 Other specified nutritional deficiencies',
  'E639 Nutritional deficiency, unspecified',
  'E640 Sequelae of protein-calorie malnutrition',
  'E641 Sequelae of vitamin A deficiency',
  'E642 Sequelae of vitamin C deficiency',
  'E643 Sequelae of rickets',
  'E648 Sequelae of other nutritional deficiencies',
  'E649 Sequelae of unspecified nutritional deficiency',
  'E65 Localized adiposity',
  'E6601 Morbid (severe) obesity due to excess calories',
  'E6609 Other obesity due to excess calories',
  'E661 Drug-induced obesity',
  'E662 Morbid (severe) obesity with alveolar hypoventilation',
  'E663 Overweight',
  'E668 Other obesity',
  'E669 Obesity, unspecified',
  'E670 Hypervitaminosis A',
  'E671 Hypercarotinemia',
  'E672 Megavitamin-B6 syndrome',
  'E673 Hypervitaminosis D',
  'E678 Other specified hyperalimentation',
  'E68 Sequelae of hyperalimentation',
  'E700 Classical phenylketonuria',
  'E701 Other hyperphenylalaninemias',
  'E7020 Disorder of tyrosine metabolism, unspecified',
  'E7021 Tyrosinemia',
  'E7029 Other disorders of tyrosine metabolism',
  'E7030 Albinism, unspecified',
  'E70310 X-linked ocular albinism',
  'E70311 Autosomal recessive ocular albinism',
  'E70318 Other ocular albinism',
  'E70319 Ocular albinism, unspecified',
  'E70320 Tyrosinase negative oculocutaneous albinism',
  'E70321 Tyrosinase positive oculocutaneous albinism',
  'E70328 Other oculocutaneous albinism',
  'E70329 Oculocutaneous albinism, unspecified',
  'E70330 Chediak-Higashi syndrome',
  'E70331 Hermansky-Pudlak syndrome',
  'E70338 Other albinism with hematologic abnormality',
  'E70339 Albinism with hematologic abnormality, unspecified',
  'E7039 Other specified albinism',
  'E7040 Disorders of histidine metabolism, unspecified',
  'E7041 Histidinemia',
  'E7049 Other disorders of histidine metabolism',
  'E705 Disorders of tryptophan metabolism',
  'E708 Other disorders of aromatic amino-acid metabolism',
  'E709 Disorder of aromatic amino-acid metabolism, unspecified',
  'E710 Maple-syrup-urine disease',
  'E71110 Isovaleric acidemia',
  'E71111 3-methylglutaconic aciduria',
  'E71118 Other branched-chain organic acidurias',
  'E71120 Methylmalonic acidemia',
  'E71121 Propionic acidemia',
  'E71128 Other disorders of propionate metabolism',
  'E7119 Other disorders of branched-chain amino-acid metabolism',
  'E712 Disorder of branched-chain amino-acid metabolism, unspecified',
  'E7130 Disorder of fatty-acid metabolism, unspecified',
  'E71310 Long chain/very long chain acyl CoA dehydrogenase deficiency',
  'E71311 Medium chain acyl CoA dehydrogenase deficiency',
  'E71312 Short chain acyl CoA dehydrogenase deficiency',
  'E71313 Glutaric aciduria type II',
  'E71314 Muscle carnitine palmitoyltransferase deficiency',
  'E71318 Other disorders of fatty-acid oxidation',
  'E7132 Disorders of ketone metabolism',
  'E7139 Other disorders of fatty-acid metabolism',
  'E7140 Disorder of carnitine metabolism, unspecified',
  'E7141 Primary carnitine deficiency',
  'E7142 Carnitine deficiency due to inborn errors of metabolism',
  'E7143 Iatrogenic carnitine deficiency',
  'E71440 Ruvalcaba-Myhre-Smith syndrome',
  'E71448 Other secondary carnitine deficiency',
  'E7150 Peroxisomal disorder, unspecified',
  'E71510 Zellweger syndrome',
  'E71511 Neonatal adrenoleukodystrophy',
  'E71518 Other disorders of peroxisome biogenesis',
  'E71520 Childhood cerebral X-linked adrenoleukodystrophy',
  'E71521 Adolescent X-linked adrenoleukodystrophy',
  'E71522 Adrenomyeloneuropathy',
  'E71528 Other X-linked adrenoleukodystrophy',
  'E71529 X-linked adrenoleukodystrophy, unspecified type',
  'E7153 Other group 2 peroxisomal disorders',
  'E71540 Rhizomelic chondrodysplasia punctata',
  'E71541 Zellweger-like syndrome',
  'E71542 Other group 3 peroxisomal disorders',
  'E71548 Other peroxisomal disorders',
  'E7200 Disorders of amino-acid transport, unspecified',
  'E7201 Cystinuria',
  "E7202 Hartnup's disease",
  "E7203 Lowe's syndrome",
  'E7204 Cystinosis',
  'E7209 Other disorders of amino-acid transport',
  'E7210 Disorders of sulfur-bearing amino-acid metabolism, unspecified',
  'E7211 Homocystinuria',
  'E7212 Methylenetetrahydrofolate reductase deficiency',
  'E7219 Other disorders of sulfur-bearing amino-acid metabolism',
  'E7220 Disorder of urea cycle metabolism, unspecified',
  'E7221 Argininemia',
  'E7222 Arginosuccinic aciduria',
  'E7223 Citrullinemia',
  'E7229 Other disorders of urea cycle metabolism',
  'E723 Disorders of lysine and hydroxylysine metabolism',
  'E724 Disorders of ornithine metabolism',
  'E7250 Disorder of glycine metabolism, unspecified',
  'E7251 Non-ketotic hyperglycinemia',
  'E7252 Trimethylaminuria',
  'E7253 Hyperoxaluria',
  'E7259 Other disorders of glycine metabolism',
  'E728 Other specified disorders of amino-acid metabolism',
  'E729 Disorder of amino-acid metabolism, unspecified',
  'E730 Congenital lactase deficiency',
  'E731 Secondary lactase deficiency',
  'E738 Other lactose intolerance',
  'E739 Lactose intolerance, unspecified',
  'E7400 Glycogen storage disease, unspecified',
  'E7401 von Gierke disease',
  'E7402 Pompe disease',
  'E7403 Cori disease',
  'E7404 McArdle disease',
  'E7409 Other glycogen storage disease',
  'E7410 Disorder of fructose metabolism, unspecified',
  'E7411 Essential fructosuria',
  'E7412 Hereditary fructose intolerance',
  'E7419 Other disorders of fructose metabolism',
  'E7420 Disorders of galactose metabolism, unspecified',
  'E7421 Galactosemia',
  'E7429 Other disorders of galactose metabolism',
  'E7431 Sucrase-isomaltase deficiency',
  'E7439 Other disorders of intestinal carbohydrate absorption',
  'E744 Disorders of pyruvate metabolism and gluconeogenesis',
  'E748 Other specified disorders of carbohydrate metabolism',
  'E749 Disorder of carbohydrate metabolism, unspecified',
  'E7500 GM2 gangliosidosis, unspecified',
  'E7501 Sandhoff disease',
  'E7502 Tay-Sachs disease',
  'E7509 Other GM2 gangliosidosis',
  'E7510 Unspecified gangliosidosis',
  'E7511 Mucolipidosis IV',
  'E7519 Other gangliosidosis',
  'E7521 Fabry (-Anderson) disease',
  'E7522 Gaucher disease',
  'E7523 Krabbe disease',
  'E75240 Niemann-Pick disease type A',
  'E75241 Niemann-Pick disease type B',
  'E75242 Niemann-Pick disease type C',
  'E75243 Niemann-Pick disease type D',
  'E75248 Other Niemann-Pick disease',
  'E75249 Niemann-Pick disease, unspecified',
  'E7525 Metachromatic leukodystrophy',
  'E7529 Other sphingolipidosis',
  'E753 Sphingolipidosis, unspecified',
  'E754 Neuronal ceroid lipofuscinosis',
  'E755 Other lipid storage disorders',
  'E756 Lipid storage disorder, unspecified',
  "E7601 Hurler's syndrome",
  'E7602 Hurler-Scheie syndrome',
  "E7603 Scheie's syndrome",
  'E761 Mucopolysaccharidosis, type II',
  'E76210 Morquio A mucopolysaccharidoses',
  'E76211 Morquio B mucopolysaccharidoses',
  'E76219 Morquio mucopolysaccharidoses, unspecified',
  'E7622 Sanfilippo mucopolysaccharidoses',
  'E7629 Other mucopolysaccharidoses',
  'E763 Mucopolysaccharidosis, unspecified',
  'E768 Other disorders of glucosaminoglycan metabolism',
  'E769 Glucosaminoglycan metabolism disorder, unspecified',
  'E770 Defects in post-translational modification of lysosomal enzymes',
  'E771 Defects in glycoprotein degradation',
  'E778 Other disorders of glycoprotein metabolism',
  'E779 Disorder of glycoprotein metabolism, unspecified',
  'E7800 Pure hypercholesterolemia, unspecified',
  'E7801 Familial hypercholesterolemia',
  'E781 Pure hyperglyceridemia',
  'E782 Mixed hyperlipidemia',
  'E783 Hyperchylomicronemia',
  'E784 Other hyperlipidemia',
  'E785 Hyperlipidemia, unspecified',
  'E786 Lipoprotein deficiency',
  'E7870 Disorder of bile acid and cholesterol metabolism, unspecified',
  'E7871 Barth syndrome',
  'E7872 Smith-Lemli-Opitz syndrome',
  'E7879 Other disorders of bile acid and cholesterol metabolism',
  'E7881 Lipoid dermatoarthritis',
  'E7889 Other lipoprotein metabolism disorders',
  'E789 Disorder of lipoprotein metabolism, unspecified',
  'E790 Hyperuricemia without signs of inflammatory arthritis and tophaceous disease',
  'E791 Lesch-Nyhan syndrome',
  'E792 Myoadenylate deaminase deficiency',
  'E798 Other disorders of purine and pyrimidine metabolism',
  'E799 Disorder of purine and pyrimidine metabolism, unspecified',
  'E800 Hereditary erythropoietic porphyria',
  'E801 Porphyria cutanea tarda',
  'E8020 Unspecified porphyria',
  'E8021 Acute intermittent (hepatic) porphyria',
  'E8029 Other porphyria',
  'E803 Defects of catalase and peroxidase',
  'E804 Gilbert syndrome',
  'E805 Crigler-Najjar syndrome',
  'E806 Other disorders of bilirubin metabolism',
  'E807 Disorder of bilirubin metabolism, unspecified',
  'E8300 Disorder of copper metabolism, unspecified',
  "E8301 Wilson's disease",
  'E8309 Other disorders of copper metabolism',
  'E8310 Disorder of iron metabolism, unspecified',
  'E83110 Hereditary hemochromatosis',
  'E83111 Hemochromatosis due to repeated red blood cell transfusions',
  'E83118 Other hemochromatosis',
  'E83119 Hemochromatosis, unspecified',
  'E8319 Other disorders of iron metabolism',
  'E832 Disorders of zinc metabolism',
  'E8330 Disorder of phosphorus metabolism, unspecified',
  'E8331 Familial hypophosphatemia',
  'E8332 Hereditary vitamin D-dependent rickets (type 1) (type 2)',
  'E8339 Other disorders of phosphorus metabolism',
  'E8340 Disorders of magnesium metabolism, unspecified',
  'E8341 Hypermagnesemia',
  'E8342 Hypomagnesemia',
  'E8349 Other disorders of magnesium metabolism',
  'E8350 Unspecified disorder of calcium metabolism',
  'E8351 Hypocalcemia',
  'E8352 Hypercalcemia',
  'E8359 Other disorders of calcium metabolism',
  'E8381 Hungry bone syndrome',
  'E8389 Other disorders of mineral metabolism',
  'E839 Disorder of mineral metabolism, unspecified',
  'E840 Cystic fibrosis with pulmonary manifestations',
  'E8411 Meconium ileus in cystic fibrosis',
  'E8419 Cystic fibrosis with other intestinal manifestations',
  'E848 Cystic fibrosis with other manifestations',
  'E849 Cystic fibrosis, unspecified',
  'E850 Non-neuropathic heredofamilial amyloidosis',
  'E851 Neuropathic heredofamilial amyloidosis',
  'E852 Heredofamilial amyloidosis, unspecified',
  'E853 Secondary systemic amyloidosis',
  'E854 Organ-limited amyloidosis',
  'E858 Other amyloidosis',
  'E859 Amyloidosis, unspecified',
  'E860 Dehydration',
  'E861 Hypovolemia',
  'E869 Volume depletion, unspecified',
  'E870 Hyperosmolality and hypernatremia',
  'E871 Hypo-osmolality and hyponatremia',
  'E872 Acidosis',
  'E873 Alkalosis',
  'E874 Mixed disorder of acid-base balance',
  'E875 Hyperkalemia',
  'E876 Hypokalemia',
  'E8770 Fluid overload, unspecified',
  'E8771 Transfusion associated circulatory overload',
  'E8779 Other fluid overload',
  'E878 Other disorders of electrolyte and fluid balance, not elsewhere classified',
  'E8801 Alpha-1-antitrypsin deficiency',
  'E8809 Other disorders of plasma-protein metabolism, not elsewhere classified',
  'E881 Lipodystrophy, not elsewhere classified',
  'E882 Lipomatosis, not elsewhere classified',
  'E883 Tumor lysis syndrome',
  'E8840 Mitochondrial metabolism disorder, unspecified',
  'E8841 MELAS syndrome',
  'E8842 MERRF syndrome',
  'E8849 Other mitochondrial metabolism disorders',
  'E8881 Metabolic syndrome',
  'E8889 Other specified metabolic disorders',
  'E889 Metabolic disorder, unspecified',
  'E890 Postprocedural hypothyroidism',
  'E891 Postprocedural hypoinsulinemia',
  'E892 Postprocedural hypoparathyroidism',
  'E893 Postprocedural hypopituitarism',
  'E8940 Asymptomatic postprocedural ovarian failure',
  'E8941 Symptomatic postprocedural ovarian failure',
  'E895 Postprocedural testicular hypofunction',
  'E896 Postprocedural adrenocortical (-medullary) hypofunction',
  'E89810 Postprocedural hemorrhage of an endocrine system organ or structure following an endocrine',
  'system procedure',
  'E89811 Postprocedural hemorrhage of an endocrine system organ or structure following other',
  'procedure',
  'E89820 Postprocedural hematoma of an endocrine system organ or structure following an endocrine',
  'system procedure',
  'E89821 Postprocedural hematoma of an endocrine system organ or structure following other',
  'procedure',
  'E89822 Postprocedural seroma of an endocrine system organ or structure following an endocrine system procedure',
  'E89823 Postprocedural seroma of an endocrine system organ or structure following other procedure',
  'E8989 Other postprocedural endocrine and metabolic complications and disorders',
  'G000 Hemophilus meningitis',
  'G001 Pneumococcal meningitis',
  'G002 Streptococcal meningitis',
  'G003 Staphylococcal meningitis',
  'G008 Other bacterial meningitis',
  'G009 Bacterial meningitis, unspecified',
  'G01 Meningitis in bacterial diseases classified elsewhere',
  'G02 Meningitis in other infectious and parasitic diseases classified elsewhere',
  'G030 Nonpyogenic meningitis',
  'G031 Chronic meningitis',
  'G032 Benign recurrent meningitis [Mollaret]',
  'G038 Meningitis due to other specified causes',
  'G039 Meningitis, unspecified',
  'G0400 Acute disseminated encephalitis and encephalomyelitis, unspecified',
  'G0401 Postinfectious acute disseminated encephalitis and encephalomyelitis (postinfectious ADEM)',
  'G0402 Postimmunization acute disseminated encephalitis, myelitis and encephalomyelitis',
  'G041 Tropical spastic paraplegia',
  'G042 Bacterial meningoencephalitis and meningomyelitis, not elsewhere classified',
  'G0430 Acute necrotizing hemorrhagic encephalopathy, unspecified',
  'G0431 Postinfectious acute necrotizing hemorrhagic encephalopathy',
  'G0432 Postimmunization acute necrotizing hemorrhagic encephalopathy',
  'G0439 Other acute necrotizing hemorrhagic encephalopathy',
  'G0481 Other encephalitis and encephalomyelitis',
  'G0489 Other myelitis',
  'G0490 Encephalitis and encephalomyelitis, unspecified',
  'G0491 Myelitis, unspecified',
  'G053 Encephalitis and encephalomyelitis in diseases classified elsewhere',
  'G054 Myelitis in diseases classified elsewhere',
  'G060 Intracranial abscess and granuloma',
  'G061 Intraspinal abscess and granuloma',
  'G062 Extradural and subdural abscess, unspecified',
  'G07 Intracranial and intraspinal abscess and granuloma in diseases classified elsewhere',
  'G08 Intracranial and intraspinal phlebitis and thrombophlebitis',
  'G09 Sequelae of inflammatory diseases of central nervous system',
  "G10 Huntington's disease",
  'G110 Congenital nonprogressive ataxia',
  'G111 Early-onset cerebellar ataxia',
  'G112 Late-onset cerebellar ataxia',
  'G113 Cerebellar ataxia with defective DNA repair',
  'G114 Hereditary spastic paraplegia',
  'G118 Other hereditary ataxias',
  'G119 Hereditary ataxia, unspecified',
  'G120 Infantile spinal muscular atrophy, type I [Werdnig-Hoffman]',
  'G121 Other inherited spinal muscular atrophy',
  'G1220 Motor neuron disease, unspecified',
  'G1221 Amyotrophic lateral sclerosis',
  'G1222 Progressive bulbar palsy',
  'G1229 Other motor neuron disease',
  'G128 Other spinal muscular atrophies and related syndromes',
  'G129 Spinal muscular atrophy, unspecified',
  'G130 Paraneoplastic neuromyopathy and neuropathy',
  'G131 Other systemic atrophy primarily affecting central nervous system in neoplastic disease',
  'G132 Systemic atrophy primarily affecting the central nervous system in myxedema',
  'G138 Systemic atrophy primarily affecting central nervous system in other diseases classified',
  'elsewhere',
  'G14 Postpolio syndrome',
  "G20 Parkinson's disease",
  'G210 Malignant neuroleptic syndrome',
  'G2111 Neuroleptic induced parkinsonism',
  'G2119 Other drug induced secondary parkinsonism',
  'G212 Secondary parkinsonism due to other external agents',
  'G213 Postencephalitic parkinsonism',
  'G214 Vascular parkinsonism',
  'G218 Other secondary parkinsonism',
  'G219 Secondary parkinsonism, unspecified',
  'G230 Hallervorden-Spatz disease',
  'G231 Progressive supranuclear ophthalmoplegia [Steele-Richardson-Olszewski]',
  'G232 Striatonigral degeneration',
  'G238 Other specified degenerative diseases of basal ganglia',
  'G239 Degenerative disease of basal ganglia, unspecified',
  'G2401 Drug induced subacute dyskinesia',
  'G2402 Drug induced acute dystonia',
  'G2409 Other drug induced dystonia',
  'G241 Genetic torsion dystonia',
  'G242 Idiopathic nonfamilial dystonia',
  'G243 Spasmodic torticollis',
  'G244 Idiopathic orofacial dystonia',
  'G245 Blepharospasm',
  'G248 Other dystonia',
  'G249 Dystonia, unspecified',
  'G250 Essential tremor',
  'G251 Drug-induced tremor',
  'G252 Other specified forms of tremor',
  'G253 Myoclonus',
  'G254 Drug-induced chorea',
  'G255 Other chorea',
  'G2561 Drug induced tics',
  'G2569 Other tics of organic origin',
  'G2570 Drug induced movement disorder, unspecified',
  'G2571 Drug induced akathisia',
  'G2579 Other drug induced movement disorders',
  'G2581 Restless legs syndrome',
  'G2582 Stiff-man syndrome',
  'G2583 Benign shuddering attacks',
  'G2589 Other specified extrapyramidal and movement disorders',
  'G259 Extrapyramidal and movement disorder, unspecified',
  'G26 Extrapyramidal and movement disorders in diseases classified elsewhere',
  "G300 Alzheimer's disease with early onset",
  "G301 Alzheimer's disease with late onset",
  "G308 Other Alzheimer's disease",
  "G309 Alzheimer's disease, unspecified",
  "G3101 Pick's disease",
  'G3109 Other frontotemporal dementia',
  'G311 Senile degeneration of brain, not elsewhere classified',
  'G312 Degeneration of nervous system due to alcohol',
  'G3181 Alpers disease',
  "G3182 Leigh's disease",
  'G3183 Dementia with Lewy bodies',
  'G3184 Mild cognitive impairment, so stated',
  'G3185 Corticobasal degeneration',
  'G3189 Other specified degenerative diseases of nervous system',
  'G319 Degenerative disease of nervous system, unspecified',
  'G320 Subacute combined degeneration of spinal cord in diseases classified elsewhere',
  'G3281 Cerebellar ataxia in diseases classified elsewhere',
  'G3289 Other specified degenerative disorders of nervous system in diseases classified elsewhere',
  'G35 Multiple sclerosis',
  'G360 Neuromyelitis optica [Devic]',
  'G361 Acute and subacute hemorrhagic leukoencephalitis [Hurst]',
  'G368 Other specified acute disseminated demyelination',
  'G369 Acute disseminated demyelination, unspecified',
  'G370 Diffuse sclerosis of central nervous system',
  'G371 Central demyelination of corpus callosum',
  'G372 Central pontine myelinolysis',
  'G373 Acute transverse myelitis in demyelinating disease of central nervous system',
  'G374 Subacute necrotizing myelitis of central nervous system',
  'G375 Concentric sclerosis [Balo] of central nervous system',
  'G378 Other specified demyelinating diseases of central nervous system',
  'G379 Demyelinating disease of central nervous system, unspecified',
  'G40001 Localization-related (focal) (partial) idiopathic epilepsy and epileptic syndromes with seizures of',
  'localized onset, not intractable, with status epilepticus',
  'G40009 Localization-related (focal) (partial) idiopathic epilepsy and epileptic syndromes with seizures of',
  'localized onset, not intractable, without status epilepticus',
  'G40011 Localization-related (focal) (partial) idiopathic epilepsy and epileptic syndromes with seizures of',
  'localized onset, intractable, with status epilepticus',
  'G40019 Localization-related (focal) (partial) idiopathic epilepsy and epileptic syndromes with seizures of',
  'localized onset, intractable, without status epilepticus',
  'G40101 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with simple',
  'partial seizures, not intractable, with status epilepticus',
  'G40109 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with simple',
  'partial seizures, not intractable, without status epilepticus',
  'G40111 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with simple',
  'partial seizures, intractable, with status epilepticus',
  'G40119 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with simple',
  'partial seizures, intractable, without status epilepticus',
  'G40201 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with',
  'complex partial seizures, not intractable, with status epilepticus',
  'G40209 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with',
  'complex partial seizures, not intractable, without status epilepticus',
  'G40211 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with',
  'complex partial seizures, intractable, with status epilepticus',
  'G40219 Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with',
  'complex partial seizures, intractable, without status epilepticus',
  'G40301 Generalized idiopathic epilepsy and epileptic syndromes, not intractable, with status',
  'epilepticus',
  'G40309 Generalized idiopathic epilepsy and epileptic syndromes, not intractable, without status',
  'epilepticus',
  'G40311 Generalized idiopathic epilepsy and epileptic syndromes, intractable, with status epilepticus',
  'G40319 Generalized idiopathic epilepsy and epileptic syndromes, intractable, without status epilepticus',
  'G40A01 Absence epileptic syndrome, not intractable, with status epilepticus',
  'G40A09 Absence epileptic syndrome, not intractable, without status epilepticus',
  'G40A11 Absence epileptic syndrome, intractable, with status epilepticus',
  'G40A19 Absence epileptic syndrome, intractable, without status epilepticus',
  'G40B01 Juvenile myoclonic epilepsy, not intractable, with status epilepticus',
  'G40B09 Juvenile myoclonic epilepsy, not intractable, without status epilepticus',
  'G40B11 Juvenile myoclonic epilepsy, intractable, with status epilepticus',
  'G40B19 Juvenile myoclonic epilepsy, intractable, without status epilepticus',
  'G40401 Other generalized epilepsy and epileptic syndromes, not intractable, with status epilepticus',
  'G40409 Other generalized epilepsy and epileptic syndromes, not intractable, without status epilepticus',
  'G40411 Other generalized epilepsy and epileptic syndromes, intractable, with status epilepticus',
  'G40419 Other generalized epilepsy and epileptic syndromes, intractable, without status epilepticus',
  'G40501 Epileptic seizures related to external causes, not intractable, with status epilepticus',
  'G40509 Epileptic seizures related to external causes, not intractable, without status epilepticus',
  'G40801 Other epilepsy, not intractable, with status epilepticus',
  'G40802 Other epilepsy, not intractable, without status epilepticus',
  'G40803 Other epilepsy, intractable, with status epilepticus',
  'G40804 Other epilepsy, intractable, without status epilepticus',
  'G40811 Lennox-Gastaut syndrome, not intractable, with status epilepticus',
  'G40812 Lennox-Gastaut syndrome, not intractable, without status epilepticus',
  'G40813 Lennox-Gastaut syndrome, intractable, with status epilepticus',
  'G40814 Lennox-Gastaut syndrome, intractable, without status epilepticus',
  'G40821 Epileptic spasms, not intractable, with status epilepticus',
  'G40822 Epileptic spasms, not intractable, without status epilepticus',
  'G40823 Epileptic spasms, intractable, with status epilepticus',
  'G40824 Epileptic spasms, intractable, without status epilepticus',
  'G4089 Other seizures',
  'G40901 Epilepsy, unspecified, not intractable, with status epilepticus',
  'G40909 Epilepsy, unspecified, not intractable, without status epilepticus',
  'G40911 Epilepsy, unspecified, intractable, with status epilepticus',
  'G40919 Epilepsy, unspecified, intractable, without status epilepticus',
  'G43001 Migraine without aura, not intractable, with status migrainosus',
  'G43009 Migraine without aura, not intractable, without status migrainosus',
  'G43011 Migraine without aura, intractable, with status migrainosus',
  'G43019 Migraine without aura, intractable, without status migrainosus',
  'G43101 Migraine with aura, not intractable, with status migrainosus',
  'G43109 Migraine with aura, not intractable, without status migrainosus',
  'G43111 Migraine with aura, intractable, with status migrainosus',
  'G43119 Migraine with aura, intractable, without status migrainosus',
  'G43401 Hemiplegic migraine, not intractable, with status migrainosus',
  'G43409 Hemiplegic migraine, not intractable, without status migrainosus',
  'G43411 Hemiplegic migraine, intractable, with status migrainosus',
  'G43419 Hemiplegic migraine, intractable, without status migrainosus',
  'G43501 Persistent migraine aura without cerebral infarction, not intractable, with status migrainosus',
  'G43509 Persistent migraine aura without cerebral infarction, not intractable, without status',
  'migrainosus',
  'G43511 Persistent migraine aura without cerebral infarction, intractable, with status migrainosus',
  'G43519 Persistent migraine aura without cerebral infarction, intractable, without status migrainosus',
  'G43601 Persistent migraine aura with cerebral infarction, not intractable, with status migrainosus',
  'G43609 Persistent migraine aura with cerebral infarction, not intractable, without status migrainosus',
  'G43611 Persistent migraine aura with cerebral infarction, intractable, with status migrainosus',
  'G43619 Persistent migraine aura with cerebral infarction, intractable, without status migrainosus',
  'G43701 Chronic migraine without aura, not intractable, with status migrainosus',
  'G43709 Chronic migraine without aura, not intractable, without status migrainosus',
  'G43711 Chronic migraine without aura, intractable, with status migrainosus',
  'G43719 Chronic migraine without aura, intractable, without status migrainosus',
  'G43A0 Cyclical vomiting, not intractable',
  'G43A1 Cyclical vomiting, intractable',
  'G43B0 Ophthalmoplegic migraine, not intractable',
  'G43B1 Ophthalmoplegic migraine, intractable',
  'G43C0 Periodic headache syndromes in child or adult, not intractable',
  'G43C1 Periodic headache syndromes in child or adult, intractable',
  'G43D0 Abdominal migraine, not intractable',
  'G43D1 Abdominal migraine, intractable',
  'G43801 Other migraine, not intractable, with status migrainosus',
  'G43809 Other migraine, not intractable, without status migrainosus',
  'G43811 Other migraine, intractable, with status migrainosus',
  'G43819 Other migraine, intractable, without status migrainosus',
  'G43821 Menstrual migraine, not intractable, with status migrainosus',
  'G43829 Menstrual migraine, not intractable, without status migrainosus',
  'G43831 Menstrual migraine, intractable, with status migrainosus',
  'G43839 Menstrual migraine, intractable, without status migrainosus',
  'G43901 Migraine, unspecified, not intractable, with status migrainosus',
  'G43909 Migraine, unspecified, not intractable, without status migrainosus',
  'G43911 Migraine, unspecified, intractable, with status migrainosus',
  'G43919 Migraine, unspecified, intractable, without status migrainosus',
  'G44001 Cluster headache syndrome, unspecified, intractable',
  'G44009 Cluster headache syndrome, unspecified, not intractable',
  'G44011 Episodic cluster headache, intractable',
  'G44019 Episodic cluster headache, not intractable',
  'G44021 Chronic cluster headache, intractable',
  'G44029 Chronic cluster headache, not intractable',
  'G44031 Episodic paroxysmal hemicrania, intractable',
  'G44039 Episodic paroxysmal hemicrania, not intractable',
  'G44041 Chronic paroxysmal hemicrania, intractable',
  'G44049 Chronic paroxysmal hemicrania, not intractable',
  'G44051 Short lasting unilateral neuralgiform headache with conjunctival injection and tearing (SUNCT),',
  'intractable',
  'G44059 Short lasting unilateral neuralgiform headache with conjunctival injection and tearing (SUNCT),',
  'not intractable',
  'G44091 Other trigeminal autonomic cephalgias (TAC), intractable',
  'G44099 Other trigeminal autonomic cephalgias (TAC), not intractable',
  'G441 Vascular headache, not elsewhere classified',
  'G44201 Tension-type headache, unspecified, intractable',
  'G44209 Tension-type headache, unspecified, not intractable',
  'G44211 Episodic tension-type headache, intractable',
  'G44219 Episodic tension-type headache, not intractable',
  'G44221 Chronic tension-type headache, intractable',
  'G44229 Chronic tension-type headache, not intractable',
  'G44301 Post-traumatic headache, unspecified, intractable',
  'G44309 Post-traumatic headache, unspecified, not intractable',
  'G44311 Acute post-traumatic headache, intractable',
  'G44319 Acute post-traumatic headache, not intractable',
  'G44321 Chronic post-traumatic headache, intractable',
  'G44329 Chronic post-traumatic headache, not intractable',
  'G4440 Drug-induced headache, not elsewhere classified, not intractable',
  'G4441 Drug-induced headache, not elsewhere classified, intractable',
  'G4451 Hemicrania continua',
  'G4452 New daily persistent headache (NDPH)',
  'G4453 Primary thunderclap headache',
  'G4459 Other complicated headache syndrome',
  'G4481 Hypnic headache',
  'G4482 Headache associated with sexual activity',
  'G4483 Primary cough headache',
  'G4484 Primary exertional headache',
  'G4485 Primary stabbing headache',
  'G4489 Other headache syndrome',
  'G450 Vertebro-basilar artery syndrome',
  'G451 Carotid artery syndrome (hemispheric)',
  'G452 Multiple and bilateral precerebral artery syndromes',
  'G453 Amaurosis fugax',
  'G454 Transient global amnesia',
  'G458 Other transient cerebral ischemic attacks and related syndromes',
  'G459 Transient cerebral ischemic attack, unspecified',
  'G460 Middle cerebral artery syndrome',
  'G461 Anterior cerebral artery syndrome',
  'G462 Posterior cerebral artery syndrome',
  'G463 Brain stem stroke syndrome',
  'G464 Cerebellar stroke syndrome',
  'G465 Pure motor lacunar syndrome',
  'G466 Pure sensory lacunar syndrome',
  'G467 Other lacunar syndromes',
  'G468 Other vascular syndromes of brain in cerebrovascular diseases',
  'G4700 Insomnia, unspecified',
  'G4701 Insomnia due to medical condition',
  'G4709 Other insomnia',
  'G4710 Hypersomnia, unspecified',
  'G4711 Idiopathic hypersomnia with long sleep time',
  'G4712 Idiopathic hypersomnia without long sleep time',
  'G4713 Recurrent hypersomnia',
  'G4714 Hypersomnia due to medical condition',
  'G4719 Other hypersomnia',
  'G4720 Circadian rhythm sleep disorder, unspecified type',
  'G4721 Circadian rhythm sleep disorder, delayed sleep phase type',
  'G4722 Circadian rhythm sleep disorder, advanced sleep phase type',
  'G4723 Circadian rhythm sleep disorder, irregular sleep wake type',
  'G4724 Circadian rhythm sleep disorder, free running type',
  'G4725 Circadian rhythm sleep disorder, jet lag type',
  'G4726 Circadian rhythm sleep disorder, shift work type',
  'G4727 Circadian rhythm sleep disorder in conditions classified elsewhere',
  'G4729 Other circadian rhythm sleep disorder',
  'G4730 Sleep apnea, unspecified',
  'G4731 Primary central sleep apnea',
  'G4732 High altitude periodic breathing',
  'G4733 Obstructive sleep apnea (adult) (pediatric)',
  'G4734 Idiopathic sleep related nonobstructive alveolar hypoventilation',
  'G4735 Congenital central alveolar hypoventilation syndrome',
  'G4736 Sleep related hypoventilation in conditions classified elsewhere',
  'G4737 Central sleep apnea in conditions classified elsewhere',
  'G4739 Other sleep apnea',
  'G47411 Narcolepsy with cataplexy',
  'G47419 Narcolepsy without cataplexy',
  'G47421 Narcolepsy in conditions classified elsewhere with cataplexy',
  'G47429 Narcolepsy in conditions classified elsewhere without cataplexy',
  'G4750 Parasomnia, unspecified',
  'G4751 Confusional arousals',
  'G4752 REM sleep behavior disorder',
  'G4753 Recurrent isolated sleep paralysis',
  'G4754 Parasomnia in conditions classified elsewhere',
  'G4759 Other parasomnia',
  'G4761 Periodic limb movement disorder',
  'G4762 Sleep related leg cramps',
  'G4763 Sleep related bruxism',
  'G4769 Other sleep related movement disorders',
  'G478 Other sleep disorders',
  'G479 Sleep disorder, unspecified',
  'G500 Trigeminal neuralgia',
  'G501 Atypical facial pain',
  'G508 Other disorders of trigeminal nerve',
  'G509 Disorder of trigeminal nerve, unspecified',
  "G510 Bell's palsy",
  'G511 Geniculate ganglionitis',
  "G512 Melkersson's syndrome",
  'G513 Clonic hemifacial spasm',
  'G514 Facial myokymia',
  'G518 Other disorders of facial nerve',
  'G519 Disorder of facial nerve, unspecified',
  'G520 Disorders of olfactory nerve',
  'G521 Disorders of glossopharyngeal nerve',
  'G522 Disorders of vagus nerve',
  'G523 Disorders of hypoglossal nerve',
  'G527 Disorders of multiple cranial nerves',
  'G528 Disorders of other specified cranial nerves',
  'G529 Cranial nerve disorder, unspecified',
  'G53 Cranial nerve disorders in diseases classified elsewhere',
  'G540 Brachial plexus disorders',
  'G541 Lumbosacral plexus disorders',
  'G542 Cervical root disorders, not elsewhere classified',
  'G543 Thoracic root disorders, not elsewhere classified',
  'G544 Lumbosacral root disorders, not elsewhere classified',
  'G545 Neuralgic amyotrophy',
  'G546 Phantom limb syndrome with pain',
  'G547 Phantom limb syndrome without pain',
  'G548 Other nerve root and plexus disorders',
  'G549 Nerve root and plexus disorder, unspecified',
  'G55 Nerve root and plexus compressions in diseases classified elsewhere',
  'G5600 Carpal tunnel syndrome, unspecified upper limb',
  'G5601 Carpal tunnel syndrome, right upper limb',
  'G5602 Carpal tunnel syndrome, left upper limb',
  'G5603 Carpal tunnel syndrome, bilateral upper limbs',
  'G5610 Other lesions of median nerve, unspecified upper limb',
  'G5611 Other lesions of median nerve, right upper limb',
  'G5612 Other lesions of median nerve, left upper limb',
  'G5613 Other lesions of median nerve, bilateral upper limbs',
  'G5620 Lesion of ulnar nerve, unspecified upper limb',
  'G5621 Lesion of ulnar nerve, right upper limb',
  'G5622 Lesion of ulnar nerve, left upper limb',
  'G5623 Lesion of ulnar nerve, bilateral upper limbs',
  'G5630 Lesion of radial nerve, unspecified upper limb',
  'G5631 Lesion of radial nerve, right upper limb',
  'G5632 Lesion of radial nerve, left upper limb',
  'G5633 Lesion of radial nerve, bilateral upper limbs',
  'G5640 Causalgia of unspecified upper limb',
  'G5641 Causalgia of right upper limb',
  'G5642 Causalgia of left upper limb',
  'G5643 Causalgia of bilateral upper limbs',
  'G5680 Other specified mononeuropathies of unspecified upper limb',
  'G5681 Other specified mononeuropathies of right upper limb',
  'G5682 Other specified mononeuropathies of left upper limb',
  'G5683 Other specified mononeuropathies of bilateral upper limbs',
  'G5690 Unspecified mononeuropathy of unspecified upper limb',
  'G5691 Unspecified mononeuropathy of right upper limb',
  'G5692 Unspecified mononeuropathy of left upper limb',
  'G5693 Unspecified mononeuropathy of bilateral upper limbs',
  'G5700 Lesion of sciatic nerve, unspecified lower limb',
  'G5701 Lesion of sciatic nerve, right lower limb',
  'G5702 Lesion of sciatic nerve, left lower limb',
  'G5703 Lesion of sciatic nerve, bilateral lower limbs',
  'G5710 Meralgia paresthetica, unspecified lower limb',
  'G5711 Meralgia paresthetica, right lower limb',
  'G5712 Meralgia paresthetica, left lower limb',
  'G5713 Meralgia paresthetica, bilateral lower limbs',
  'G5720 Lesion of femoral nerve, unspecified lower limb',
  'G5721 Lesion of femoral nerve, right lower limb',
  'G5722 Lesion of femoral nerve, left lower limb',
  'G5723 Lesion of femoral nerve, bilateral lower limbs',
  'G5730 Lesion of lateral popliteal nerve, unspecified lower limb',
  'G5731 Lesion of lateral popliteal nerve, right lower limb',
  'G5732 Lesion of lateral popliteal nerve, left lower limb',
  'G5733 Lesion of lateral popliteal nerve, bilateral lower limbs',
  'G5740 Lesion of medial popliteal nerve, unspecified lower limb',
  'G5741 Lesion of medial popliteal nerve, right lower limb',
  'G5742 Lesion of medial popliteal nerve, left lower limb',
  'G5743 Lesion of medial popliteal nerve, bilateral lower limbs',
  'G5750 Tarsal tunnel syndrome, unspecified lower limb',
  'G5751 Tarsal tunnel syndrome, right lower limb',
  'G5752 Tarsal tunnel syndrome, left lower limb',
  'G5753 Tarsal tunnel syndrome, bilateral lower limbs',
  'G5760 Lesion of plantar nerve, unspecified lower limb',
  'G5761 Lesion of plantar nerve, right lower limb',
  'G5762 Lesion of plantar nerve, left lower limb',
  'G5763 Lesion of plantar nerve, bilateral lower limbs',
  'G5770 Causalgia of unspecified lower limb',
  'G5771 Causalgia of right lower limb',
  'G5772 Causalgia of left lower limb',
  'G5773 Causalgia of bilateral lower limbs',
  'G5780 Other specified mononeuropathies of unspecified lower limb',
  'G5781 Other specified mononeuropathies of right lower limb',
  'G5782 Other specified mononeuropathies of left lower limb',
  'G5783 Other specified mononeuropathies of bilateral lower limbs',
  'G5790 Unspecified mononeuropathy of unspecified lower limb',
  'G5791 Unspecified mononeuropathy of right lower limb',
  'G5792 Unspecified mononeuropathy of left lower limb',
  'G5793 Unspecified mononeuropathy of bilateral lower limbs',
  'G580 Intercostal neuropathy',
  'G587 Mononeuritis multiplex',
  'G588 Other specified mononeuropathies',
  'G589 Mononeuropathy, unspecified',
  'G59 Mononeuropathy in diseases classified elsewhere',
  'G600 Hereditary motor and sensory neuropathy',
  "G601 Refsum's disease",
  'G602 Neuropathy in association with hereditary ataxia',
  'G603 Idiopathic progressive neuropathy',
  'G608 Other hereditary and idiopathic neuropathies',
  'G609 Hereditary and idiopathic neuropathy, unspecified',
  'G610 Guillain-Barre syndrome',
  'G611 Serum neuropathy',
  'G6181 Chronic inflammatory demyelinating polyneuritis',
  'G6182 Multifocal motor neuropathy',
  'G6189 Other inflammatory polyneuropathies',
  'G619 Inflammatory polyneuropathy, unspecified',
  'G620 Drug-induced polyneuropathy',
  'G621 Alcoholic polyneuropathy',
  'G622 Polyneuropathy due to other toxic agents',
  'G6281 Critical illness polyneuropathy',
  'G6282 Radiation-induced polyneuropathy',
  'G6289 Other specified polyneuropathies',
  'G629 Polyneuropathy, unspecified',
  'G63 Polyneuropathy in diseases classified elsewhere',
  'G64 Other disorders of peripheral nervous system',
  'G650 Sequelae of Guillain-Barre syndrome',
  'G651 Sequelae of other inflammatory polyneuropathy',
  'G652 Sequelae of toxic polyneuropathy',
  'G7000 Myasthenia gravis without (acute) exacerbation',
  'G7001 Myasthenia gravis with (acute) exacerbation',
  'G701 Toxic myoneural disorders',
  'G702 Congenital and developmental myasthenia',
  'G7080 Lambert-Eaton syndrome, unspecified',
  'G7081 Lambert-Eaton syndrome in disease classified elsewhere',
  'G7089 Other specified myoneural disorders',
  'G709 Myoneural disorder, unspecified',
  'G710 Muscular dystrophy',
  'G7111 Myotonic muscular dystrophy',
  'G7112 Myotonia congenita',
  'G7113 Myotonic chondrodystrophy',
  'G7114 Drug induced myotonia',
  'G7119 Other specified myotonic disorders',
  'G712 Congenital myopathies',
  'G713 Mitochondrial myopathy, not elsewhere classified',
  'G718 Other primary disorders of muscles',
  'G719 Primary disorder of muscle, unspecified',
  'G720 Drug-induced myopathy',
  'G721 Alcoholic myopathy',
  'G722 Myopathy due to other toxic agents',
  'G723 Periodic paralysis',
  'G7241 Inclusion body myositis [IBM]',
  'G7249 Other inflammatory and immune myopathies, not elsewhere classified',
  'G7281 Critical illness myopathy',
  'G7289 Other specified myopathies',
  'G729 Myopathy, unspecified',
  'G731 Lambert-Eaton syndrome in neoplastic disease',
  'G733 Myasthenic syndromes in other diseases classified elsewhere',
  'G737 Myopathy in diseases classified elsewhere',
  'G800 Spastic quadriplegic cerebral palsy',
  'G801 Spastic diplegic cerebral palsy',
  'G802 Spastic hemiplegic cerebral palsy',
  'G803 Athetoid cerebral palsy',
  'G804 Ataxic cerebral palsy',
  'G808 Other cerebral palsy',
  'G809 Cerebral palsy, unspecified',
  'G8100 Flaccid hemiplegia affecting unspecified side',
  'G8101 Flaccid hemiplegia affecting right dominant side',
  'G8102 Flaccid hemiplegia affecting left dominant side',
  'G8103 Flaccid hemiplegia affecting right nondominant side',
  'G8104 Flaccid hemiplegia affecting left nondominant side',
  'G8110 Spastic hemiplegia affecting unspecified side',
  'G8111 Spastic hemiplegia affecting right dominant side',
  'G8112 Spastic hemiplegia affecting left dominant side',
  'G8113 Spastic hemiplegia affecting right nondominant side',
  'G8114 Spastic hemiplegia affecting left nondominant side',
  'G8190 Hemiplegia, unspecified affecting unspecified side',
  'G8191 Hemiplegia, unspecified affecting right dominant side',
  'G8192 Hemiplegia, unspecified affecting left dominant side',
  'G8193 Hemiplegia, unspecified affecting right nondominant side',
  'G8194 Hemiplegia, unspecified affecting left nondominant side',
  'G8220 Paraplegia, unspecified',
  'G8221 Paraplegia, complete',
  'G8222 Paraplegia, incomplete',
  'G8250 Quadriplegia, unspecified',
  'G8251 Quadriplegia, C1-C4 complete',
  'G8252 Quadriplegia, C1-C4 incomplete',
  'G8253 Quadriplegia, C5-C7 complete',
  'G8254 Quadriplegia, C5-C7 incomplete',
  'G830 Diplegia of upper limbs',
  'G8310 Monoplegia of lower limb affecting unspecified side',
  'G8311 Monoplegia of lower limb affecting right dominant side',
  'G8312 Monoplegia of lower limb affecting left dominant side',
  'G8313 Monoplegia of lower limb affecting right nondominant side',
  'G8314 Monoplegia of lower limb affecting left nondominant side',
  'G8320 Monoplegia of upper limb affecting unspecified side',
  'G8321 Monoplegia of upper limb affecting right dominant side',
  'G8322 Monoplegia of upper limb affecting left dominant side',
  'G8323 Monoplegia of upper limb affecting right nondominant side',
  'G8324 Monoplegia of upper limb affecting left nondominant side',
  'G8330 Monoplegia, unspecified affecting unspecified side',
  'G8331 Monoplegia, unspecified affecting right dominant side',
  'G8332 Monoplegia, unspecified affecting left dominant side',
  'G8333 Monoplegia, unspecified affecting right nondominant side',
  'G8334 Monoplegia, unspecified affecting left nondominant side',
  'G834 Cauda equina syndrome',
  'G835 Locked-in state',
  'G8381 Brown-Sequard syndrome',
  'G8382 Anterior cord syndrome',
  'G8383 Posterior cord syndrome',
  "G8384 Todd's paralysis (postepileptic)",
  'G8389 Other specified paralytic syndromes',
  'G839 Paralytic syndrome, unspecified',
  'G890 Central pain syndrome',
  'G8911 Acute pain due to trauma',
  'G8912 Acute post-thoracotomy pain',
  'G8918 Other acute postprocedural pain',
  'G8921 Chronic pain due to trauma',
  'G8922 Chronic post-thoracotomy pain',
  'G8928 Other chronic postprocedural pain',
  'G8929 Other chronic pain',
  'G893 Neoplasm related pain (acute) (chronic)',
  'G894 Chronic pain syndrome',
  'G9001 Carotid sinus syncope',
  'G9009 Other idiopathic peripheral autonomic neuropathy',
  'G901 Familial dysautonomia [Riley-Day]',
  "G902 Horner's syndrome",
  'G903 Multi-system degeneration of the autonomic nervous system',
  'G904 Autonomic dysreflexia',
  'G9050 Complex regional pain syndrome I, unspecified',
  'G90511 Complex regional pain syndrome I of right upper limb',
  'G90512 Complex regional pain syndrome I of left upper limb',
  'G90513 Complex regional pain syndrome I of upper limb, bilateral',
  'G90519 Complex regional pain syndrome I of unspecified upper limb',
  'G90521 Complex regional pain syndrome I of right lower limb',
  'G90522 Complex regional pain syndrome I of left lower limb',
  'G90523 Complex regional pain syndrome I of lower limb, bilateral',
  'G90529 Complex regional pain syndrome I of unspecified lower limb',
  'G9059 Complex regional pain syndrome I of other specified site',
  'G908 Other disorders of autonomic nervous system',
  'G909 Disorder of the autonomic nervous system, unspecified',
  'G910 Communicating hydrocephalus',
  'G911 Obstructive hydrocephalus',
  'G912 (Idiopathic) normal pressure hydrocephalus',
  'G913 Post-traumatic hydrocephalus, unspecified',
  'G914 Hydrocephalus in diseases classified elsewhere',
  'G918 Other hydrocephalus',
  'G919 Hydrocephalus, unspecified',
  'G92 Toxic encephalopathy',
  'G930 Cerebral cysts',
  'G931 Anoxic brain damage, not elsewhere classified',
  'G932 Benign intracranial hypertension',
  'G933 Postviral fatigue syndrome',
  'G9340 Encephalopathy, unspecified',
  'G9341 Metabolic encephalopathy',
  'G9349 Other encephalopathy',
  'G935 Compression of brain',
  'G936 Cerebral edema',
  "G937 Reye's syndrome",
  'G9381 Temporal sclerosis',
  'G9382 Brain death',
  'G9389 Other specified disorders of brain',
  'G939 Disorder of brain, unspecified',
  'G94 Other disorders of brain in diseases classified elsewhere',
  'G950 Syringomyelia and syringobulbia',
  'G9511 Acute infarction of spinal cord (embolic) (nonembolic)',
  'G9519 Other vascular myelopathies',
  'G9520 Unspecified cord compression',
  'G9529 Other cord compression',
  'G9581 Conus medullaris syndrome',
  'G9589 Other specified diseases of spinal cord',
  'G959 Disease of spinal cord, unspecified',
  'G960 Cerebrospinal fluid leak',
  'G9611 Dural tear',
  'G9612 Meningeal adhesions (cerebral) (spinal)',
  'G9619 Other disorders of meninges, not elsewhere classified',
  'G968 Other specified disorders of central nervous system',
  'G969 Disorder of central nervous system, unspecified',
  'G970 Cerebrospinal fluid leak from spinal puncture',
  'G971 Other reaction to spinal and lumbar puncture',
  'G972 Intracranial hypotension following ventricular shunting',
  'G9731 Intraoperative hemorrhage and hematoma of a nervous system organ or structure complicating',
  'a nervous system procedure',
  'G9732 Intraoperative hemorrhage and hematoma of a nervous system organ or structure complicating',
  'other procedure',
  'G9741 Accidental puncture or laceration of dura during a procedure',
  'G9748 Accidental puncture and laceration of other nervous system organ or structure during a nervous',
  'system procedure',
  'G9749 Accidental puncture and laceration of other nervous system organ or structure during other',
  'procedure',
  'G9751 Postprocedural hemorrhage of a nervous system organ or structure following a nervous system',
  'procedure',
  'G9752 Postprocedural hemorrhage of a nervous system organ or structure following other procedure',
  'G9761 Postprocedural hematoma of a nervous system organ or structure following a nervous system',
  'procedure',
  'G9762 Postprocedural hematoma of a nervous system organ or structure following other procedure',
  'G9763 Postprocedural seroma of a nervous system organ or structure following a nervous system',
  'procedure',
  'G9764 Postprocedural seroma of a nervous system organ or structure following other procedure',
  'G9781 Other intraoperative complications of nervous system',
  'G9782 Other postprocedural complications and disorders of nervous system',
  'G980 Neurogenic arthritis, not elsewhere classified',
  'G988 Other disorders of nervous system',
  'G990 Autonomic neuropathy in diseases classified elsewhere',
  'G992 Myelopathy in diseases classified elsewhere',
  'G998 Other specified disorders of nervous system in diseases classified elsewhere',
  'H00011 Hordeolum externum right upper eyelid',
  'H00012 Hordeolum externum right lower eyelid',
  'H00013 Hordeolum externum right eye, unspecified eyelid',
  'H00014 Hordeolum externum left upper eyelid',
  'H00015 Hordeolum externum left lower eyelid',
  'H00016 Hordeolum externum left eye, unspecified eyelid',
  'H00019 Hordeolum externum unspecified eye, unspecified eyelid',
  'H00021 Hordeolum internum right upper eyelid',
  'H00022 Hordeolum internum right lower eyelid',
  'H00023 Hordeolum internum right eye, unspecified eyelid',
  'H00024 Hordeolum internum left upper eyelid',
  'H00025 Hordeolum internum left lower eyelid',
  'H00026 Hordeolum internum left eye, unspecified eyelid',
  'H00029 Hordeolum internum unspecified eye, unspecified eyelid',
  'H00031 Abscess of right upper eyelid',
  'H00032 Abscess of right lower eyelid',
  'H00033 Abscess of eyelid right eye, unspecified eyelid',
  'H00034 Abscess of left upper eyelid',
  'H00035 Abscess of left lower eyelid',
  'H00036 Abscess of eyelid left eye, unspecified eyelid',
  'H00039 Abscess of eyelid unspecified eye, unspecified eyelid',
  'H0011 Chalazion right upper eyelid',
  'H0012 Chalazion right lower eyelid',
  'H0013 Chalazion right eye, unspecified eyelid',
  'H0014 Chalazion left upper eyelid',
  'H0015 Chalazion left lower eyelid',
  'H0016 Chalazion left eye, unspecified eyelid',
  'H0019 Chalazion unspecified eye, unspecified eyelid',
  'H01001 Unspecified blepharitis right upper eyelid',
  'H01002 Unspecified blepharitis right lower eyelid',
  'H01003 Unspecified blepharitis right eye, unspecified eyelid',
  'H01004 Unspecified blepharitis left upper eyelid',
  'H01005 Unspecified blepharitis left lower eyelid',
  'H01006 Unspecified blepharitis left eye, unspecified eyelid',
  'H01009 Unspecified blepharitis unspecified eye, unspecified eyelid',
  'H01011 Ulcerative blepharitis right upper eyelid',
  'H01012 Ulcerative blepharitis right lower eyelid',
  'H01013 Ulcerative blepharitis right eye, unspecified eyelid',
  'H01014 Ulcerative blepharitis left upper eyelid',
  'H01015 Ulcerative blepharitis left lower eyelid',
  'H01016 Ulcerative blepharitis left eye, unspecified eyelid',
  'H01019 Ulcerative blepharitis unspecified eye, unspecified eyelid',
  'H01021 Squamous blepharitis right upper eyelid',
  'H01022 Squamous blepharitis right lower eyelid',
  'H01023 Squamous blepharitis right eye, unspecified eyelid',
  'H01024 Squamous blepharitis left upper eyelid',
  'H01025 Squamous blepharitis left lower eyelid',
  'H01026 Squamous blepharitis left eye, unspecified eyelid',
  'H01029 Squamous blepharitis unspecified eye, unspecified eyelid',
  'H01111 Allergic dermatitis of right upper eyelid',
  'H01112 Allergic dermatitis of right lower eyelid',
  'H01113 Allergic dermatitis of right eye, unspecified eyelid',
  'H01114 Allergic dermatitis of left upper eyelid',
  'H01115 Allergic dermatitis of left lower eyelid',
  'H01116 Allergic dermatitis of left eye, unspecified eyelid',
  'H01119 Allergic dermatitis of unspecified eye, unspecified eyelid',
  'H01121 Discoid lupus erythematosus of right upper eyelid',
  'H01122 Discoid lupus erythematosus of right lower eyelid',
  'H01123 Discoid lupus erythematosus of right eye, unspecified eyelid',
  'H01124 Discoid lupus erythematosus of left upper eyelid',
  'H01125 Discoid lupus erythematosus of left lower eyelid',
  'H01126 Discoid lupus erythematosus of left eye, unspecified eyelid',
  'H01129 Discoid lupus erythematosus of unspecified eye, unspecified eyelid',
  'H01131 Eczematous dermatitis of right upper eyelid',
  'H01132 Eczematous dermatitis of right lower eyelid',
  'H01133 Eczematous dermatitis of right eye, unspecified eyelid',
  'H01134 Eczematous dermatitis of left upper eyelid',
  'H01135 Eczematous dermatitis of left lower eyelid',
  'H01136 Eczematous dermatitis of left eye, unspecified eyelid',
  'H01139 Eczematous dermatitis of unspecified eye, unspecified eyelid',
  'H01141 Xeroderma of right upper eyelid',
  'H01142 Xeroderma of right lower eyelid',
  'H01143 Xeroderma of right eye, unspecified eyelid',
  'H01144 Xeroderma of left upper eyelid',
  'H01145 Xeroderma of left lower eyelid',
  'H01146 Xeroderma of left eye, unspecified eyelid',
  'H01149 Xeroderma of unspecified eye, unspecified eyelid',
  'H018 Other specified inflammations of eyelid',
  'H019 Unspecified inflammation of eyelid',
  'H02001 Unspecified entropion of right upper eyelid',
  'H02002 Unspecified entropion of right lower eyelid',
  'H02003 Unspecified entropion of right eye, unspecified eyelid',
  'H02004 Unspecified entropion of left upper eyelid',
  'H02005 Unspecified entropion of left lower eyelid',
  'H02006 Unspecified entropion of left eye, unspecified eyelid',
  'H02009 Unspecified entropion of unspecified eye, unspecified eyelid',
  'H02011 Cicatricial entropion of right upper eyelid',
  'H02012 Cicatricial entropion of right lower eyelid',
  'H02013 Cicatricial entropion of right eye, unspecified eyelid',
  'H02014 Cicatricial entropion of left upper eyelid',
  'H02015 Cicatricial entropion of left lower eyelid',
  'H02016 Cicatricial entropion of left eye, unspecified eyelid',
  'H02019 Cicatricial entropion of unspecified eye, unspecified eyelid',
  'H02021 Mechanical entropion of right upper eyelid',
  'H02022 Mechanical entropion of right lower eyelid',
  'H02023 Mechanical entropion of right eye, unspecified eyelid',
  'H02024 Mechanical entropion of left upper eyelid',
  'H02025 Mechanical entropion of left lower eyelid',
  'H02026 Mechanical entropion of left eye, unspecified eyelid',
  'H02029 Mechanical entropion of unspecified eye, unspecified eyelid',
  'H02031 Senile entropion of right upper eyelid',
  'H02032 Senile entropion of right lower eyelid',
  'H02033 Senile entropion of right eye, unspecified eyelid',
  'H02034 Senile entropion of left upper eyelid',
  'H02035 Senile entropion of left lower eyelid',
  'H02036 Senile entropion of left eye, unspecified eyelid',
  'H02039 Senile entropion of unspecified eye, unspecified eyelid',
  'H02041 Spastic entropion of right upper eyelid',
  'H02042 Spastic entropion of right lower eyelid',
  'H02043 Spastic entropion of right eye, unspecified eyelid',
  'H02044 Spastic entropion of left upper eyelid',
  'H02045 Spastic entropion of left lower eyelid',
  'H02046 Spastic entropion of left eye, unspecified eyelid',
  'H02049 Spastic entropion of unspecified eye, unspecified eyelid',
  'H02051 Trichiasis without entropian right upper eyelid',
  'H02052 Trichiasis without entropian right lower eyelid',
  'H02053 Trichiasis without entropian right eye, unspecified eyelid',
  'H02054 Trichiasis without entropian left upper eyelid',
  'H02055 Trichiasis without entropian left lower eyelid',
  'H02056 Trichiasis without entropian left eye, unspecified eyelid',
  'H02059 Trichiasis without entropian unspecified eye, unspecified eyelid',
  'H02101 Unspecified ectropion of right upper eyelid',
  'H02102 Unspecified ectropion of right lower eyelid',
  'H02103 Unspecified ectropion of right eye, unspecified eyelid',
  'H02104 Unspecified ectropion of left upper eyelid',
  'H02105 Unspecified ectropion of left lower eyelid',
  'H02106 Unspecified ectropion of left eye, unspecified eyelid',
  'H02109 Unspecified ectropion of unspecified eye, unspecified eyelid',
  'H02111 Cicatricial ectropion of right upper eyelid',
  'H02112 Cicatricial ectropion of right lower eyelid',
  'H02113 Cicatricial ectropion of right eye, unspecified eyelid',
  'H02114 Cicatricial ectropion of left upper eyelid',
  'H02115 Cicatricial ectropion of left lower eyelid',
  'H02116 Cicatricial ectropion of left eye, unspecified eyelid',
  'H02119 Cicatricial ectropion of unspecified eye, unspecified eyelid',
  'H02121 Mechanical ectropion of right upper eyelid',
  'H02122 Mechanical ectropion of right lower eyelid',
  'H02123 Mechanical ectropion of right eye, unspecified eyelid',
  'H02124 Mechanical ectropion of left upper eyelid',
  'H02125 Mechanical ectropion of left lower eyelid',
  'H02126 Mechanical ectropion of left eye, unspecified eyelid',
  'H02129 Mechanical ectropion of unspecified eye, unspecified eyelid',
  'H02131 Senile ectropion of right upper eyelid',
  'H02132 Senile ectropion of right lower eyelid',
  'H02133 Senile ectropion of right eye, unspecified eyelid',
  'H02134 Senile ectropion of left upper eyelid',
  'H02135 Senile ectropion of left lower eyelid',
  'H02136 Senile ectropion of left eye, unspecified eyelid',
  'H02139 Senile ectropion of unspecified eye, unspecified eyelid',
  'H02141 Spastic ectropion of right upper eyelid',
  'H02142 Spastic ectropion of right lower eyelid',
  'H02143 Spastic ectropion of right eye, unspecified eyelid',
  'H02144 Spastic ectropion of left upper eyelid',
  'H02145 Spastic ectropion of left lower eyelid',
  'H02146 Spastic ectropion of left eye, unspecified eyelid',
  'H02149 Spastic ectropion of unspecified eye, unspecified eyelid',
  'H02201 Unspecified lagophthalmos right upper eyelid',
  'H02202 Unspecified lagophthalmos right lower eyelid',
  'H02203 Unspecified lagophthalmos right eye, unspecified eyelid',
  'H02204 Unspecified lagophthalmos left upper eyelid',
  'H02205 Unspecified lagophthalmos left lower eyelid',
  'H02206 Unspecified lagophthalmos left eye, unspecified eyelid',
  'H02209 Unspecified lagophthalmos unspecified eye, unspecified eyelid',
  'H02211 Cicatricial lagophthalmos right upper eyelid',
  'H02212 Cicatricial lagophthalmos right lower eyelid',
  'H02213 Cicatricial lagophthalmos right eye, unspecified eyelid',
  'H02214 Cicatricial lagophthalmos left upper eyelid',
  'H02215 Cicatricial lagophthalmos left lower eyelid',
  'H02216 Cicatricial lagophthalmos left eye, unspecified eyelid',
  'H02219 Cicatricial lagophthalmos unspecified eye, unspecified eyelid',
  'H02221 Mechanical lagophthalmos right upper eyelid',
  'H02222 Mechanical lagophthalmos right lower eyelid',
  'H02223 Mechanical lagophthalmos right eye, unspecified eyelid',
  'H02224 Mechanical lagophthalmos left upper eyelid',
  'H02225 Mechanical lagophthalmos left lower eyelid',
  'H02226 Mechanical lagophthalmos left eye, unspecified eyelid',
  'H02229 Mechanical lagophthalmos unspecified eye, unspecified eyelid',
  'H02231 Paralytic lagophthalmos right upper eyelid',
  'H02232 Paralytic lagophthalmos right lower eyelid',
  'H02233 Paralytic lagophthalmos right eye, unspecified eyelid',
  'H02234 Paralytic lagophthalmos left upper eyelid',
  'H02235 Paralytic lagophthalmos left lower eyelid',
  'H02236 Paralytic lagophthalmos left eye, unspecified eyelid',
  'H02239 Paralytic lagophthalmos unspecified eye, unspecified eyelid',
  'H0230 Blepharochalasis unspecified eye, unspecified eyelid',
  'H0231 Blepharochalasis right upper eyelid',
  'H0232 Blepharochalasis right lower eyelid',
  'H0233 Blepharochalasis right eye, unspecified eyelid',
  'H0234 Blepharochalasis left upper eyelid',
  'H0235 Blepharochalasis left lower eyelid',
  'H0236 Blepharochalasis left eye, unspecified eyelid',
  'H02401 Unspecified ptosis of right eyelid',
  'H02402 Unspecified ptosis of left eyelid',
  'H02403 Unspecified ptosis of bilateral eyelids',
  'H02409 Unspecified ptosis of unspecified eyelid',
  'H02411 Mechanical ptosis of right eyelid',
  'H02412 Mechanical ptosis of left eyelid',
  'H02413 Mechanical ptosis of bilateral eyelids',
  'H02419 Mechanical ptosis of unspecified eyelid',
  'H02421 Myogenic ptosis of right eyelid',
  'H02422 Myogenic ptosis of left eyelid',
  'H02423 Myogenic ptosis of bilateral eyelids',
  'H02429 Myogenic ptosis of unspecified eyelid',
  'H02431 Paralytic ptosis of right eyelid',
  'H02432 Paralytic ptosis of left eyelid',
  'H02433 Paralytic ptosis of bilateral eyelids',
  'H02439 Paralytic ptosis unspecified eyelid',
  'H02511 Abnormal innervation syndrome right upper eyelid',
  'H02512 Abnormal innervation syndrome right lower eyelid',
  'H02513 Abnormal innervation syndrome right eye, unspecified eyelid',
  'H02514 Abnormal innervation syndrome left upper eyelid',
  'H02515 Abnormal innervation syndrome left lower eyelid',
  'H02516 Abnormal innervation syndrome left eye, unspecified eyelid',
  'H02519 Abnormal innervation syndrome unspecified eye, unspecified eyelid',
  'H02521 Blepharophimosis right upper eyelid',
  'H02522 Blepharophimosis right lower eyelid',
  'H02523 Blepharophimosis right eye, unspecified eyelid',
  'H02524 Blepharophimosis left upper eyelid',
  'H02525 Blepharophimosis left lower eyelid',
  'H02526 Blepharophimosis left eye, unspecified eyelid',
  'H02529 Blepharophimosis unspecified eye, unspecified lid',
  'H02531 Eyelid retraction right upper eyelid',
  'H02532 Eyelid retraction right lower eyelid',
  'H02533 Eyelid retraction right eye, unspecified eyelid',
  'H02534 Eyelid retraction left upper eyelid',
  'H02535 Eyelid retraction left lower eyelid',
  'H02536 Eyelid retraction left eye, unspecified eyelid',
  'H02539 Eyelid retraction unspecified eye, unspecified lid',
  'H0259 Other disorders affecting eyelid function',
  'H0260 Xanthelasma of unspecified eye, unspecified eyelid',
  'H0261 Xanthelasma of right upper eyelid',
  'H0262 Xanthelasma of right lower eyelid',
  'H0263 Xanthelasma of right eye, unspecified eyelid',
  'H0264 Xanthelasma of left upper eyelid',
  'H0265 Xanthelasma of left lower eyelid',
  'H0266 Xanthelasma of left eye, unspecified eyelid',
  'H0270 Unspecified degenerative disorders of eyelid and periocular area',
  'H02711 Chloasma of right upper eyelid and periocular area',
  'H02712 Chloasma of right lower eyelid and periocular area',
  'H02713 Chloasma of right eye, unspecified eyelid and periocular area',
  'H02714 Chloasma of left upper eyelid and periocular area',
  'H02715 Chloasma of left lower eyelid and periocular area',
  'H02716 Chloasma of left eye, unspecified eyelid and periocular area',
  'H02719 Chloasma of unspecified eye, unspecified eyelid and periocular area',
  'H02721 Madarosis of right upper eyelid and periocular area',
  'H02722 Madarosis of right lower eyelid and periocular area',
  'H02723 Madarosis of right eye, unspecified eyelid and periocular area',
  'H02724 Madarosis of left upper eyelid and periocular area',
  'H02725 Madarosis of left lower eyelid and periocular area',
  'H02726 Madarosis of left eye, unspecified eyelid and periocular area',
  'H02729 Madarosis of unspecified eye, unspecified eyelid and periocular area',
  'H02731 Vitiligo of right upper eyelid and periocular area',
  'H02732 Vitiligo of right lower eyelid and periocular area',
  'H02733 Vitiligo of right eye, unspecified eyelid and periocular area',
  'H02734 Vitiligo of left upper eyelid and periocular area',
  'H02735 Vitiligo of left lower eyelid and periocular area',
  'H02736 Vitiligo of left eye, unspecified eyelid and periocular area',
  'H02739 Vitiligo of unspecified eye, unspecified eyelid and periocular area',
  'H0279 Other degenerative disorders of eyelid and periocular area',
  'H02811 Retained foreign body in right upper eyelid',
  'H02812 Retained foreign body in right lower eyelid',
  'H02813 Retained foreign body in right eye, unspecified eyelid',
  'H02814 Retained foreign body in left upper eyelid',
  'H02815 Retained foreign body in left lower eyelid',
  'H02816 Retained foreign body in left eye, unspecified eyelid',
  'H02819 Retained foreign body in unspecified eye, unspecified eyelid',
  'H02821 Cysts of right upper eyelid',
  'H02822 Cysts of right lower eyelid',
  'H02823 Cysts of right eye, unspecified eyelid',
  'H02824 Cysts of left upper eyelid',
  'H02825 Cysts of left lower eyelid',
  'H02826 Cysts of left eye, unspecified eyelid',
  'H02829 Cysts of unspecified eye, unspecified eyelid',
  'H02831 Dermatochalasis of right upper eyelid',
  'H02832 Dermatochalasis of right lower eyelid',
  'H02833 Dermatochalasis of right eye, unspecified eyelid',
  'H02834 Dermatochalasis of left upper eyelid',
  'H02835 Dermatochalasis of left lower eyelid',
  'H02836 Dermatochalasis of left eye, unspecified eyelid',
  'H02839 Dermatochalasis of unspecified eye, unspecified eyelid',
  'H02841 Edema of right upper eyelid',
  'H02842 Edema of right lower eyelid',
  'H02843 Edema of right eye, unspecified eyelid',
  'H02844 Edema of left upper eyelid',
  'H02845 Edema of left lower eyelid',
  'H02846 Edema of left eye, unspecified eyelid',
  'H02849 Edema of unspecified eye, unspecified eyelid',
  'H02851 Elephantiasis of right upper eyelid',
  'H02852 Elephantiasis of right lower eyelid',
  'H02853 Elephantiasis of right eye, unspecified eyelid',
  'H02854 Elephantiasis of left upper eyelid',
  'H02855 Elephantiasis of left lower eyelid',
  'H02856 Elephantiasis of left eye, unspecified eyelid',
  'H02859 Elephantiasis of unspecified eye, unspecified eyelid',
  'H02861 Hypertrichosis of right upper eyelid',
  'H02862 Hypertrichosis of right lower eyelid',
  'H02863 Hypertrichosis of right eye, unspecified eyelid',
  'H02864 Hypertrichosis of left upper eyelid',
  'H02865 Hypertrichosis of left lower eyelid',
  'H02866 Hypertrichosis of left eye, unspecified eyelid',
  'H02869 Hypertrichosis of unspecified eye, unspecified eyelid',
  'H02871 Vascular anomalies of right upper eyelid',
  'H02872 Vascular anomalies of right lower eyelid',
  'H02873 Vascular anomalies of right eye, unspecified eyelid',
  'H02874 Vascular anomalies of left upper eyelid',
  'H02875 Vascular anomalies of left lower eyelid',
  'H02876 Vascular anomalies of left eye, unspecified eyelid',
  'H02879 Vascular anomalies of unspecified eye, unspecified eyelid',
  'H0289 Other specified disorders of eyelid',
  'H029 Unspecified disorder of eyelid',
  'H04001 Unspecified dacryoadenitis, right lacrimal gland',
  'H04002 Unspecified dacryoadenitis, left lacrimal gland',
  'H04003 Unspecified dacryoadenitis, bilateral lacrimal glands',
  'H04009 Unspecified dacryoadenitis, unspecified lacrimal gland',
  'H04011 Acute dacryoadenitis, right lacrimal gland',
  'H04012 Acute dacryoadenitis, left lacrimal gland',
  'H04013 Acute dacryoadenitis, bilateral lacrimal glands',
  'H04019 Acute dacryoadenitis, unspecified lacrimal gland',
  'H04021 Chronic dacryoadenitis, right lacrimal gland',
  'H04022 Chronic dacryoadenitis, left lacrimal gland',
  'H04023 Chronic dacryoadenitis, bilateral lacrimal gland',
  'H04029 Chronic dacryoadenitis, unspecified lacrimal gland',
  'H04031 Chronic enlargement of right lacrimal gland',
  'H04032 Chronic enlargement of left lacrimal gland',
  'H04033 Chronic enlargement of bilateral lacrimal glands',
  'H04039 Chronic enlargement of unspecified lacrimal gland',
  'H04111 Dacryops of right lacrimal gland',
  'H04112 Dacryops of left lacrimal gland',
  'H04113 Dacryops of bilateral lacrimal glands',
  'H04119 Dacryops of unspecified lacrimal gland',
  'H04121 Dry eye syndrome of right lacrimal gland',
  'H04122 Dry eye syndrome of left lacrimal gland',
  'H04123 Dry eye syndrome of bilateral lacrimal glands',
  'H04129 Dry eye syndrome of unspecified lacrimal gland',
  'H04131 Lacrimal cyst, right lacrimal gland',
  'H04132 Lacrimal cyst, left lacrimal gland',
  'H04133 Lacrimal cyst, bilateral lacrimal glands',
  'H04139 Lacrimal cyst, unspecified lacrimal gland',
  'H04141 Primary lacrimal gland atrophy, right lacrimal gland',
  'H04142 Primary lacrimal gland atrophy, left lacrimal gland',
  'H04143 Primary lacrimal gland atrophy, bilateral lacrimal glands',
  'H04149 Primary lacrimal gland atrophy, unspecified lacrimal gland',
  'H04151 Secondary lacrimal gland atrophy, right lacrimal gland',
  'H04152 Secondary lacrimal gland atrophy, left lacrimal gland',
  'H04153 Secondary lacrimal gland atrophy, bilateral lacrimal glands',
  'H04159 Secondary lacrimal gland atrophy, unspecified lacrimal gland',
  'H04161 Lacrimal gland dislocation, right lacrimal gland',
  'H04162 Lacrimal gland dislocation, left lacrimal gland',
  'H04163 Lacrimal gland dislocation, bilateral lacrimal glands',
  'H04169 Lacrimal gland dislocation, unspecified lacrimal gland',
  'H0419 Other specified disorders of lacrimal gland',
  'H04201 Unspecified epiphora, right lacrimal gland',
  'H04202 Unspecified epiphora, left lacrimal gland',
  'H04203 Unspecified epiphora, bilateral lacrimal glands',
  'H04209 Unspecified epiphora, unspecified lacrimal gland',
  'H04211 Epiphora due to excess lacrimation, right lacrimal gland',
  'H04212 Epiphora due to excess lacrimation, left lacrimal gland',
  'H04213 Epiphora due to excess lacrimation, bilateral lacrimal glands',
  'H04219 Epiphora due to excess lacrimation, unspecified lacrimal gland',
  'H04221 Epiphora due to insufficient drainage, right lacrimal gland',
  'H04222 Epiphora due to insufficient drainage, left lacrimal gland',
  'H04223 Epiphora due to insufficient drainage, bilateral lacrimal glands',
  'H04229 Epiphora due to insufficient drainage, unspecified lacrimal gland',
  'H04301 Unspecified dacryocystitis of right lacrimal passage',
  'H04302 Unspecified dacryocystitis of left lacrimal passage',
  'H04303 Unspecified dacryocystitis of bilateral lacrimal passages',
  'H04309 Unspecified dacryocystitis of unspecified lacrimal passage',
  'H04311 Phlegmonous dacryocystitis of right lacrimal passage',
  'H04312 Phlegmonous dacryocystitis of left lacrimal passage',
  'H04313 Phlegmonous dacryocystitis of bilateral lacrimal passages',
  'H04319 Phlegmonous dacryocystitis of unspecified lacrimal passage',
  'H04321 Acute dacryocystitis of right lacrimal passage',
  'H04322 Acute dacryocystitis of left lacrimal passage',
  'H04323 Acute dacryocystitis of bilateral lacrimal passages',
  'H04329 Acute dacryocystitis of unspecified lacrimal passage',
  'H04331 Acute lacrimal canaliculitis of right lacrimal passage',
  'H04332 Acute lacrimal canaliculitis of left lacrimal passage',
  'H04333 Acute lacrimal canaliculitis of bilateral lacrimal passages',
  'H04339 Acute lacrimal canaliculitis of unspecified lacrimal passage',
  'H04411 Chronic dacryocystitis of right lacrimal passage',
  'H04412 Chronic dacryocystitis of left lacrimal passage',
  'H04413 Chronic dacryocystitis of bilateral lacrimal passages',
  'H04419 Chronic dacryocystitis of unspecified lacrimal passage',
  'H04421 Chronic lacrimal canaliculitis of right lacrimal passage',
  'H04422 Chronic lacrimal canaliculitis of left lacrimal passage',
  'H04423 Chronic lacrimal canaliculitis of bilateral lacrimal passages',
  'H04429 Chronic lacrimal canaliculitis of unspecified lacrimal passage',
  'H04431 Chronic lacrimal mucocele of right lacrimal passage',
  'H04432 Chronic lacrimal mucocele of left lacrimal passage',
  'H04433 Chronic lacrimal mucocele of bilateral lacrimal passages',
  'H04439 Chronic lacrimal mucocele of unspecified lacrimal passage',
  'H04511 Dacryolith of right lacrimal passage',
  'H04512 Dacryolith of left lacrimal passage',
  'H04513 Dacryolith of bilateral lacrimal passages',
  'H04519 Dacryolith of unspecified lacrimal passage',
  'H04521 Eversion of right lacrimal punctum',
  'H04522 Eversion of left lacrimal punctum',
  'H04523 Eversion of bilateral lacrimal punctum',
  'H04529 Eversion of unspecified lacrimal punctum',
  'H04531 Neonatal obstruction of right nasolacrimal duct',
  'H04532 Neonatal obstruction of left nasolacrimal duct',
  'H04533 Neonatal obstruction of bilateral nasolacrimal duct',
  'H04539 Neonatal obstruction of unspecified nasolacrimal duct',
  'H04541 Stenosis of right lacrimal canaliculi',
  'H04542 Stenosis of left lacrimal canaliculi',
  'H04543 Stenosis of bilateral lacrimal canaliculi',
  'H04549 Stenosis of unspecified lacrimal canaliculi',
  'H04551 Acquired stenosis of right nasolacrimal duct',
  'H04552 Acquired stenosis of left nasolacrimal duct',
  'H04553 Acquired stenosis of bilateral nasolacrimal duct',
  'H04559 Acquired stenosis of unspecified nasolacrimal duct',
  'H04561 Stenosis of right lacrimal punctum',
  'H04562 Stenosis of left lacrimal punctum',
  'H04563 Stenosis of bilateral lacrimal punctum',
  'H04569 Stenosis of unspecified lacrimal punctum',
  'H04571 Stenosis of right lacrimal sac',
  'H04572 Stenosis of left lacrimal sac',
  'H04573 Stenosis of bilateral lacrimal sac',
  'H04579 Stenosis of unspecified lacrimal sac',
  'H04611 Lacrimal fistula right lacrimal passage',
  'H04612 Lacrimal fistula left lacrimal passage',
  'H04613 Lacrimal fistula bilateral lacrimal passages',
  'H04619 Lacrimal fistula unspecified lacrimal passage',
  'H0469 Other changes of lacrimal passages',
  'H04811 Granuloma of right lacrimal passage',
  'H04812 Granuloma of left lacrimal passage',
  'H04813 Granuloma of bilateral lacrimal passages',
  'H04819 Granuloma of unspecified lacrimal passage',
  'H0489 Other disorders of lacrimal system',
  'H049 Disorder of lacrimal system, unspecified',
  'H0500 Unspecified acute inflammation of orbit',
  'H05011 Cellulitis of right orbit',
  'H05012 Cellulitis of left orbit',
  'H05013 Cellulitis of bilateral orbits',
  'H05019 Cellulitis of unspecified orbit',
  'H05021 Osteomyelitis of right orbit',
  'H05022 Osteomyelitis of left orbit',
  'H05023 Osteomyelitis of bilateral orbits',
  'H05029 Osteomyelitis of unspecified orbit',
  'H05031 Periostitis of right orbit',
  'H05032 Periostitis of left orbit',
  'H05033 Periostitis of bilateral orbits',
  'H05039 Periostitis of unspecified orbit',
  'H05041 Tenonitis of right orbit',
  'H05042 Tenonitis of left orbit',
  'H05043 Tenonitis of bilateral orbits',
  'H05049 Tenonitis of unspecified orbit',
  'H0510 Unspecified chronic inflammatory disorders of orbit',
  'H05111 Granuloma of right orbit',
  'H05112 Granuloma of left orbit',
  'H05113 Granuloma of bilateral orbits',
  'H05119 Granuloma of unspecified orbit',
  'H05121 Orbital myositis, right orbit',
  'H05122 Orbital myositis, left orbit',
  'H05123 Orbital myositis, bilateral',
  'H05129 Orbital myositis, unspecified orbit',
  'H0520 Unspecified exophthalmos',
  'H05211 Displacement (lateral) of globe, right eye',
  'H05212 Displacement (lateral) of globe, left eye',
  'H05213 Displacement (lateral) of globe, bilateral',
  'H05219 Displacement (lateral) of globe, unspecified eye',
  'H05221 Edema of right orbit',
  'H05222 Edema of left orbit',
  'H05223 Edema of bilateral orbit',
  'H05229 Edema of unspecified orbit',
  'H05231 Hemorrhage of right orbit',
  'H05232 Hemorrhage of left orbit',
  'H05233 Hemorrhage of bilateral orbit',
  'H05239 Hemorrhage of unspecified orbit',
  'H05241 Constant exophthalmos, right eye',
  'H05242 Constant exophthalmos, left eye',
  'H05243 Constant exophthalmos, bilateral',
  'H05249 Constant exophthalmos, unspecified eye',
  'H05251 Intermittent exophthalmos, right eye',
  'H05252 Intermittent exophthalmos, left eye',
  'H05253 Intermittent exophthalmos, bilateral',
  'H05259 Intermittent exophthalmos, unspecified eye',
  'H05261 Pulsating exophthalmos, right eye',
  'H05262 Pulsating exophthalmos, left eye',
  'H05263 Pulsating exophthalmos, bilateral',
  'H05269 Pulsating exophthalmos, unspecified eye',
  'H0530 Unspecified deformity of orbit',
  'H05311 Atrophy of right orbit',
  'H05312 Atrophy of left orbit',
  'H05313 Atrophy of bilateral orbit',
  'H05319 Atrophy of unspecified orbit',
  'H05321 Deformity of right orbit due to bone disease',
  'H05322 Deformity of left orbit due to bone disease',
  'H05323 Deformity of bilateral orbits due to bone disease',
  'H05329 Deformity of unspecified orbit due to bone disease',
  'H05331 Deformity of right orbit due to trauma or surgery',
  'H05332 Deformity of left orbit due to trauma or surgery',
  'H05333 Deformity of bilateral orbits due to trauma or surgery',
  'H05339 Deformity of unspecified orbit due to trauma or surgery',
  'H05341 Enlargement of right orbit',
  'H05342 Enlargement of left orbit',
  'H05343 Enlargement of bilateral orbits',
  'H05349 Enlargement of unspecified orbit',
  'H05351 Exostosis of right orbit',
  'H05352 Exostosis of left orbit',
  'H05353 Exostosis of bilateral orbits',
  'H05359 Exostosis of unspecified orbit',
  'H05401 Unspecified enophthalmos, right eye',
  'H05402 Unspecified enophthalmos, left eye',
  'H05403 Unspecified enophthalmos, bilateral',
  'H05409 Unspecified enophthalmos, unspecified eye',
  'H05411 Enophthalmos due to atrophy of orbital tissue, right eye',
  'H05412 Enophthalmos due to atrophy of orbital tissue, left eye',
  'H05413 Enophthalmos due to atrophy of orbital tissue, bilateral',
  'H05419 Enophthalmos due to atrophy of orbital tissue, unspecified eye',
  'H05421 Enophthalmos due to trauma or surgery, right eye',
  'H05422 Enophthalmos due to trauma or surgery, left eye',
  'H05423 Enophthalmos due to trauma or surgery, bilateral',
  'H05429 Enophthalmos due to trauma or surgery, unspecified eye',
  'H0550 Retained (old) foreign body following penetrating wound of unspecified orbit',
  'H0551 Retained (old) foreign body following penetrating wound of right orbit',
  'H0552 Retained (old) foreign body following penetrating wound of left orbit',
  'H0553 Retained (old) foreign body following penetrating wound of bilateral orbits',
  'H05811 Cyst of right orbit',
  'H05812 Cyst of left orbit',
  'H05813 Cyst of bilateral orbits',
  'H05819 Cyst of unspecified orbit',
  'H05821 Myopathy of extraocular muscles, right orbit',
  'H05822 Myopathy of extraocular muscles, left orbit',
  'H05823 Myopathy of extraocular muscles, bilateral',
  'H05829 Myopathy of extraocular muscles, unspecified orbit',
  'H0589 Other disorders of orbit',
  'H059 Unspecified disorder of orbit',
  'H10011 Acute follicular conjunctivitis, right eye',
  'H10012 Acute follicular conjunctivitis, left eye',
  'H10013 Acute follicular conjunctivitis, bilateral',
  'H10019 Acute follicular conjunctivitis, unspecified eye',
  'H10021 Other mucopurulent conjunctivitis, right eye',
  'H10022 Other mucopurulent conjunctivitis, left eye',
  'H10023 Other mucopurulent conjunctivitis, bilateral',
  'H10029 Other mucopurulent conjunctivitis, unspecified eye',
  'H1010 Acute atopic conjunctivitis, unspecified eye',
  'H1011 Acute atopic conjunctivitis, right eye',
  'H1012 Acute atopic conjunctivitis, left eye',
  'H1013 Acute atopic conjunctivitis, bilateral',
  'H10211 Acute toxic conjunctivitis, right eye',
  'H10212 Acute toxic conjunctivitis, left eye',
  'H10213 Acute toxic conjunctivitis, bilateral',
  'H10219 Acute toxic conjunctivitis, unspecified eye',
  'H10221 Pseudomembranous conjunctivitis, right eye',
  'H10222 Pseudomembranous conjunctivitis, left eye',
  'H10223 Pseudomembranous conjunctivitis, bilateral',
  'H10229 Pseudomembranous conjunctivitis, unspecified eye',
  'H10231 Serous conjunctivitis, except viral, right eye',
  'H10232 Serous conjunctivitis, except viral, left eye',
  'H10233 Serous conjunctivitis, except viral, bilateral',
  'H10239 Serous conjunctivitis, except viral, unspecified eye',
  'H1030 Unspecified acute conjunctivitis, unspecified eye',
  'H1031 Unspecified acute conjunctivitis, right eye',
  'H1032 Unspecified acute conjunctivitis, left eye',
  'H1033 Unspecified acute conjunctivitis, bilateral',
  'H10401 Unspecified chronic conjunctivitis, right eye',
  'H10402 Unspecified chronic conjunctivitis, left eye',
  'H10403 Unspecified chronic conjunctivitis, bilateral',
  'H10409 Unspecified chronic conjunctivitis, unspecified eye',
  'H10411 Chronic giant papillary conjunctivitis, right eye',
  'H10412 Chronic giant papillary conjunctivitis, left eye',
  'H10413 Chronic giant papillary conjunctivitis, bilateral',
  'H10419 Chronic giant papillary conjunctivitis, unspecified eye',
  'H10421 Simple chronic conjunctivitis, right eye',
  'H10422 Simple chronic conjunctivitis, left eye',
  'H10423 Simple chronic conjunctivitis, bilateral',
  'H10429 Simple chronic conjunctivitis, unspecified eye',
  'H10431 Chronic follicular conjunctivitis, right eye',
  'H10432 Chronic follicular conjunctivitis, left eye',
  'H10433 Chronic follicular conjunctivitis, bilateral',
  'H10439 Chronic follicular conjunctivitis, unspecified eye',
  'H1044 Vernal conjunctivitis',
  'H1045 Other chronic allergic conjunctivitis',
  'H10501 Unspecified blepharoconjunctivitis, right eye',
  'H10502 Unspecified blepharoconjunctivitis, left eye',
  'H10503 Unspecified blepharoconjunctivitis, bilateral',
  'H10509 Unspecified blepharoconjunctivitis, unspecified eye',
  'H10511 Ligneous conjunctivitis, right eye',
  'H10512 Ligneous conjunctivitis, left eye',
  'H10513 Ligneous conjunctivitis, bilateral',
  'H10519 Ligneous conjunctivitis, unspecified eye',
  'H10521 Angular blepharoconjunctivitis, right eye',
  'H10522 Angular blepharoconjunctivitis, left eye',
  'H10523 Angular blepharoconjunctivitis, bilateral',
  'H10529 Angular blepharoconjunctivitis, unspecified eye',
  'H10531 Contact blepharoconjunctivitis, right eye',
  'H10532 Contact blepharoconjunctivitis, left eye',
  'H10533 Contact blepharoconjunctivitis, bilateral',
  'H10539 Contact blepharoconjunctivitis, unspecified eye',
  'H10811 Pingueculitis, right eye',
  'H10812 Pingueculitis, left eye',
  'H10813 Pingueculitis, bilateral',
  'H10819 Pingueculitis, unspecified eye',
  'H1089 Other conjunctivitis',
  'H109 Unspecified conjunctivitis',
  'H11001 Unspecified pterygium of right eye',
  'H11002 Unspecified pterygium of left eye',
  'H11003 Unspecified pterygium of eye, bilateral',
  'H11009 Unspecified pterygium of unspecified eye',
  'H11011 Amyloid pterygium of right eye',
  'H11012 Amyloid pterygium of left eye',
  'H11013 Amyloid pterygium of eye, bilateral',
  'H11019 Amyloid pterygium of unspecified eye',
  'H11021 Central pterygium of right eye',
  'H11022 Central pterygium of left eye',
  'H11023 Central pterygium of eye, bilateral',
  'H11029 Central pterygium of unspecified eye',
  'H11031 Double pterygium of right eye',
  'H11032 Double pterygium of left eye',
  'H11033 Double pterygium of eye, bilateral',
  'H11039 Double pterygium of unspecified eye',
  'H11041 Peripheral pterygium, stationary, right eye',
  'H11042 Peripheral pterygium, stationary, left eye',
  'H11043 Peripheral pterygium, stationary, bilateral',
  'H11049 Peripheral pterygium, stationary, unspecified eye',
  'H11051 Peripheral pterygium, progressive, right eye',
  'H11052 Peripheral pterygium, progressive, left eye',
  'H11053 Peripheral pterygium, progressive, bilateral',
  'H11059 Peripheral pterygium, progressive, unspecified eye',
  'H11061 Recurrent pterygium of right eye',
  'H11062 Recurrent pterygium of left eye',
  'H11063 Recurrent pterygium of eye, bilateral',
  'H11069 Recurrent pterygium of unspecified eye',
  'H1110 Unspecified conjunctival degenerations',
  'H11111 Conjunctival deposits, right eye',
  'H11112 Conjunctival deposits, left eye',
  'H11113 Conjunctival deposits, bilateral',
  'H11119 Conjunctival deposits, unspecified eye',
  'H11121 Conjunctival concretions, right eye',
  'H11122 Conjunctival concretions, left eye',
  'H11123 Conjunctival concretions, bilateral',
  'H11129 Conjunctival concretions, unspecified eye',
  'H11131 Conjunctival pigmentations, right eye',
  'H11132 Conjunctival pigmentations, left eye',
  'H11133 Conjunctival pigmentations, bilateral',
  'H11139 Conjunctival pigmentations, unspecified eye',
  'H11141 Conjunctival xerosis, unspecified, right eye',
  'H11142 Conjunctival xerosis, unspecified, left eye',
  'H11143 Conjunctival xerosis, unspecified, bilateral',
  'H11149 Conjunctival xerosis, unspecified, unspecified eye',
  'H11151 Pinguecula, right eye',
  'H11152 Pinguecula, left eye',
  'H11153 Pinguecula, bilateral',
  'H11159 Pinguecula, unspecified eye',
  'H11211 Conjunctival adhesions and strands (localized), right eye',
  'H11212 Conjunctival adhesions and strands (localized), left eye',
  'H11213 Conjunctival adhesions and strands (localized), bilateral',
  'H11219 Conjunctival adhesions and strands (localized), unspecified eye',
  'H11221 Conjunctival granuloma, right eye',
  'H11222 Conjunctival granuloma, left eye',
  'H11223 Conjunctival granuloma, bilateral',
  'H11229 Conjunctival granuloma, unspecified',
  'H11231 Symblepharon, right eye',
  'H11232 Symblepharon, left eye',
  'H11233 Symblepharon, bilateral',
  'H11239 Symblepharon, unspecified eye',
  'H11241 Scarring of conjunctiva, right eye',
  'H11242 Scarring of conjunctiva, left eye',
  'H11243 Scarring of conjunctiva, bilateral',
  'H11249 Scarring of conjunctiva, unspecified eye',
  'H1130 Conjunctival hemorrhage, unspecified eye',
  'H1131 Conjunctival hemorrhage, right eye',
  'H1132 Conjunctival hemorrhage, left eye',
  'H1133 Conjunctival hemorrhage, bilateral',
  'H11411 Vascular abnormalities of conjunctiva, right eye',
  'H11412 Vascular abnormalities of conjunctiva, left eye',
  'H11413 Vascular abnormalities of conjunctiva, bilateral',
  'H11419 Vascular abnormalities of conjunctiva, unspecified eye',
  'H11421 Conjunctival edema, right eye',
  'H11422 Conjunctival edema, left eye',
  'H11423 Conjunctival edema, bilateral',
  'H11429 Conjunctival edema, unspecified eye',
  'H11431 Conjunctival hyperemia, right eye',
  'H11432 Conjunctival hyperemia, left eye',
  'H11433 Conjunctival hyperemia, bilateral',
  'H11439 Conjunctival hyperemia, unspecified eye',
  'H11441 Conjunctival cysts, right eye',
  'H11442 Conjunctival cysts, left eye',
  'H11443 Conjunctival cysts, bilateral',
  'H11449 Conjunctival cysts, unspecified eye',
  'H11811 Pseudopterygium of conjunctiva, right eye',
  'H11812 Pseudopterygium of conjunctiva, left eye',
  'H11813 Pseudopterygium of conjunctiva, bilateral',
  'H11819 Pseudopterygium of conjunctiva, unspecified eye',
  'H11821 Conjunctivochalasis, right eye',
  'H11822 Conjunctivochalasis, left eye',
  'H11823 Conjunctivochalasis, bilateral',
  'H11829 Conjunctivochalasis, unspecified eye',
  'H1189 Other specified disorders of conjunctiva',
  'H119 Unspecified disorder of conjunctiva',
  'H15001 Unspecified scleritis, right eye',
  'H15002 Unspecified scleritis, left eye',
  'H15003 Unspecified scleritis, bilateral',
  'H15009 Unspecified scleritis, unspecified eye',
  'H15011 Anterior scleritis, right eye',
  'H15012 Anterior scleritis, left eye',
  'H15013 Anterior scleritis, bilateral',
  'H15019 Anterior scleritis, unspecified eye',
  'H15021 Brawny scleritis, right eye',
  'H15022 Brawny scleritis, left eye',
  'H15023 Brawny scleritis, bilateral',
  'H15029 Brawny scleritis, unspecified eye',
  'H15031 Posterior scleritis, right eye',
  'H15032 Posterior scleritis, left eye',
  'H15033 Posterior scleritis, bilateral',
  'H15039 Posterior scleritis, unspecified eye',
  'H15041 Scleritis with corneal involvement, right eye',
  'H15042 Scleritis with corneal involvement, left eye',
  'H15043 Scleritis with corneal involvement, bilateral',
  'H15049 Scleritis with corneal involvement, unspecified eye',
  'H15051 Scleromalacia perforans, right eye',
  'H15052 Scleromalacia perforans, left eye',
  'H15053 Scleromalacia perforans, bilateral',
  'H15059 Scleromalacia perforans, unspecified eye',
  'H15091 Other scleritis, right eye',
  'H15092 Other scleritis, left eye',
  'H15093 Other scleritis, bilateral',
  'H15099 Other scleritis, unspecified eye',
  'H15101 Unspecified episcleritis, right eye',
  'H15102 Unspecified episcleritis, left eye',
  'H15103 Unspecified episcleritis, bilateral',
  'H15109 Unspecified episcleritis, unspecified eye',
  'H15111 Episcleritis periodica fugax, right eye',
  'H15112 Episcleritis periodica fugax, left eye',
  'H15113 Episcleritis periodica fugax, bilateral',
  'H15119 Episcleritis periodica fugax, unspecified eye',
  'H15121 Nodular episcleritis, right eye',
  'H15122 Nodular episcleritis, left eye',
  'H15123 Nodular episcleritis, bilateral',
  'H15129 Nodular episcleritis, unspecified eye',
  'H15811 Equatorial staphyloma, right eye',
  'H15812 Equatorial staphyloma, left eye',
  'H15813 Equatorial staphyloma, bilateral',
  'H15819 Equatorial staphyloma, unspecified eye',
  'H15821 Localized anterior staphyloma, right eye',
  'H15822 Localized anterior staphyloma, left eye',
  'H15823 Localized anterior staphyloma, bilateral',
  'H15829 Localized anterior staphyloma, unspecified eye',
  'H15831 Staphyloma posticum, right eye',
  'H15832 Staphyloma posticum, left eye',
  'H15833 Staphyloma posticum, bilateral',
  'H15839 Staphyloma posticum, unspecified eye',
  'H15841 Scleral ectasia, right eye',
  'H15842 Scleral ectasia, left eye',
  'H15843 Scleral ectasia, bilateral',
  'H15849 Scleral ectasia, unspecified eye',
  'H15851 Ring staphyloma, right eye',
  'H15852 Ring staphyloma, left eye',
  'H15853 Ring staphyloma, bilateral',
  'H15859 Ring staphyloma, unspecified eye',
  'H1589 Other disorders of sclera',
  'H159 Unspecified disorder of sclera',
  'H16001 Unspecified corneal ulcer, right eye',
  'H16002 Unspecified corneal ulcer, left eye',
  'H16003 Unspecified corneal ulcer, bilateral',
  'H16009 Unspecified corneal ulcer, unspecified eye',
  'H16011 Central corneal ulcer, right eye',
  'H16012 Central corneal ulcer, left eye',
  'H16013 Central corneal ulcer, bilateral',
  'H16019 Central corneal ulcer, unspecified eye',
  'H16021 Ring corneal ulcer, right eye',
  'H16022 Ring corneal ulcer, left eye',
  'H16023 Ring corneal ulcer, bilateral',
  'H16029 Ring corneal ulcer, unspecified eye',
  'H16031 Corneal ulcer with hypopyon, right eye',
  'H16032 Corneal ulcer with hypopyon, left eye',
  'H16033 Corneal ulcer with hypopyon, bilateral',
  'H16039 Corneal ulcer with hypopyon, unspecified eye',
  'H16041 Marginal corneal ulcer, right eye',
  'H16042 Marginal corneal ulcer, left eye',
  'H16043 Marginal corneal ulcer, bilateral',
  'H16049 Marginal corneal ulcer, unspecified eye',
  "H16051 Mooren's corneal ulcer, right eye",
  "H16052 Mooren's corneal ulcer, left eye",
  "H16053 Mooren's corneal ulcer, bilateral",
  "H16059 Mooren's corneal ulcer, unspecified eye",
  'H16061 Mycotic corneal ulcer, right eye',
  'H16062 Mycotic corneal ulcer, left eye',
  'H16063 Mycotic corneal ulcer, bilateral',
  'H16069 Mycotic corneal ulcer, unspecified eye',
  'H16071 Perforated corneal ulcer, right eye',
  'H16072 Perforated corneal ulcer, left eye',
  'H16073 Perforated corneal ulcer, bilateral',
  'H16079 Perforated corneal ulcer, unspecified eye',
  'H16101 Unspecified superficial keratitis, right eye',
  'H16102 Unspecified superficial keratitis, left eye',
  'H16103 Unspecified superficial keratitis, bilateral',
  'H16109 Unspecified superficial keratitis, unspecified eye',
  'H16111 Macular keratitis, right eye',
  'H16112 Macular keratitis, left eye',
  'H16113 Macular keratitis, bilateral',
  'H16119 Macular keratitis, unspecified eye',
  'H16121 Filamentary keratitis, right eye',
  'H16122 Filamentary keratitis, left eye',
  'H16123 Filamentary keratitis, bilateral',
  'H16129 Filamentary keratitis, unspecified eye',
  'H16131 Photokeratitis, right eye',
  'H16132 Photokeratitis, left eye',
  'H16133 Photokeratitis, bilateral',
  'H16139 Photokeratitis, unspecified eye',
  'H16141 Punctate keratitis, right eye',
  'H16142 Punctate keratitis, left eye',
  'H16143 Punctate keratitis, bilateral',
  'H16149 Punctate keratitis, unspecified eye',
  'H16201 Unspecified keratoconjunctivitis, right eye',
  'H16202 Unspecified keratoconjunctivitis, left eye',
  'H16203 Unspecified keratoconjunctivitis, bilateral',
  'H16209 Unspecified keratoconjunctivitis, unspecified eye',
  'H16211 Exposure keratoconjunctivitis, right eye',
  'H16212 Exposure keratoconjunctivitis, left eye',
  'H16213 Exposure keratoconjunctivitis, bilateral',
  'H16219 Exposure keratoconjunctivitis, unspecified eye',
  "H16221 Keratoconjunctivitis sicca, not specified as Sjogren's, right eye",
  "H16222 Keratoconjunctivitis sicca, not specified as Sjogren's, left eye",
  "H16223 Keratoconjunctivitis sicca, not specified as Sjogren's, bilateral",
  "H16229 Keratoconjunctivitis sicca, not specified as Sjogren's, unspecified eye",
  'H16231 Neurotrophic keratoconjunctivitis, right eye',
  'H16232 Neurotrophic keratoconjunctivitis, left eye',
  'H16233 Neurotrophic keratoconjunctivitis, bilateral',
  'H16239 Neurotrophic keratoconjunctivitis, unspecified eye',
  'H16241 Ophthalmia nodosa, right eye',
  'H16242 Ophthalmia nodosa, left eye',
  'H16243 Ophthalmia nodosa, bilateral',
  'H16249 Ophthalmia nodosa, unspecified eye',
  'H16251 Phlyctenular keratoconjunctivitis, right eye',
  'H16252 Phlyctenular keratoconjunctivitis, left eye',
  'H16253 Phlyctenular keratoconjunctivitis, bilateral',
  'H16259 Phlyctenular keratoconjunctivitis, unspecified eye',
  'H16261 Vernal keratoconjunctivitis, with limbar and corneal involvement, right eye',
  'H16262 Vernal keratoconjunctivitis, with limbar and corneal involvement, left eye',
  'H16263 Vernal keratoconjunctivitis, with limbar and corneal involvement, bilateral',
  'H16269 Vernal keratoconjunctivitis, with limbar and corneal involvement, unspecified eye',
  'H16291 Other keratoconjunctivitis, right eye',
  'H16292 Other keratoconjunctivitis, left eye',
  'H16293 Other keratoconjunctivitis, bilateral',
  'H16299 Other keratoconjunctivitis, unspecified eye',
  'H16301 Unspecified interstitial keratitis, right eye',
  'H16302 Unspecified interstitial keratitis, left eye',
  'H16303 Unspecified interstitial keratitis, bilateral',
  'H16309 Unspecified interstitial keratitis, unspecified eye',
  'H16311 Corneal abscess, right eye',
  'H16312 Corneal abscess, left eye',
  'H16313 Corneal abscess, bilateral',
  'H16319 Corneal abscess, unspecified eye',
  'H16321 Diffuse interstitial keratitis, right eye',
  'H16322 Diffuse interstitial keratitis, left eye',
  'H16323 Diffuse interstitial keratitis, bilateral',
  'H16329 Diffuse interstitial keratitis, unspecified eye',
  'H16331 Sclerosing keratitis, right eye',
  'H16332 Sclerosing keratitis, left eye',
  'H16333 Sclerosing keratitis, bilateral',
  'H16339 Sclerosing keratitis, unspecified eye',
  'H16391 Other interstitial and deep keratitis, right eye',
  'H16392 Other interstitial and deep keratitis, left eye',
  'H16393 Other interstitial and deep keratitis, bilateral',
  'H16399 Other interstitial and deep keratitis, unspecified eye',
  'H16401 Unspecified corneal neovascularization, right eye',
  'H16402 Unspecified corneal neovascularization, left eye',
  'H16403 Unspecified corneal neovascularization, bilateral',
  'H16409 Unspecified corneal neovascularization, unspecified eye',
  'H16411 Ghost vessels (corneal), right eye',
  'H16412 Ghost vessels (corneal), left eye',
  'H16413 Ghost vessels (corneal), bilateral',
  'H16419 Ghost vessels (corneal), unspecified eye',
  'H16421 Pannus (corneal), right eye',
  'H16422 Pannus (corneal), left eye',
  'H16423 Pannus (corneal), bilateral',
  'H16429 Pannus (corneal), unspecified eye',
  'H16431 Localized vascularization of cornea, right eye',
  'H16432 Localized vascularization of cornea, left eye',
  'H16433 Localized vascularization of cornea, bilateral',
  'H16439 Localized vascularization of cornea, unspecified eye',
  'H16441 Deep vascularization of cornea, right eye',
  'H16442 Deep vascularization of cornea, left eye',
  'H16443 Deep vascularization of cornea, bilateral',
  'H16449 Deep vascularization of cornea, unspecified eye',
  'H168 Other keratitis',
  'H169 Unspecified keratitis',
  'H1700 Adherent leukoma, unspecified eye',
  'H1701 Adherent leukoma, right eye',
  'H1702 Adherent leukoma, left eye',
  'H1703 Adherent leukoma, bilateral',
  'H1710 Central corneal opacity, unspecified eye',
  'H1711 Central corneal opacity, right eye',
  'H1712 Central corneal opacity, left eye',
  'H1713 Central corneal opacity, bilateral',
  'H17811 Minor opacity of cornea, right eye',
  'H17812 Minor opacity of cornea, left eye',
  'H17813 Minor opacity of cornea, bilateral',
  'H17819 Minor opacity of cornea, unspecified eye',
  'H17821 Peripheral opacity of cornea, right eye',
  'H17822 Peripheral opacity of cornea, left eye',
  'H17823 Peripheral opacity of cornea, bilateral',
  'H17829 Peripheral opacity of cornea, unspecified eye',
  'H1789 Other corneal scars and opacities',
  'H179 Unspecified corneal scar and opacity',
  'H18001 Unspecified corneal deposit, right eye',
  'H18002 Unspecified corneal deposit, left eye',
  'H18003 Unspecified corneal deposit, bilateral',
  'H18009 Unspecified corneal deposit, unspecified eye',
  'H18011 Anterior corneal pigmentations, right eye',
  'H18012 Anterior corneal pigmentations, left eye',
  'H18013 Anterior corneal pigmentations, bilateral',
  'H18019 Anterior corneal pigmentations, unspecified eye',
  'H18021 Argentous corneal deposits, right eye',
  'H18022 Argentous corneal deposits, left eye',
  'H18023 Argentous corneal deposits, bilateral',
  'H18029 Argentous corneal deposits, unspecified eye',
  'H18031 Corneal deposits in metabolic disorders, right eye',
  'H18032 Corneal deposits in metabolic disorders, left eye',
  'H18033 Corneal deposits in metabolic disorders, bilateral',
  'H18039 Corneal deposits in metabolic disorders, unspecified eye',
  'H18041 Kayser-Fleischer ring, right eye',
  'H18042 Kayser-Fleischer ring, left eye',
  'H18043 Kayser-Fleischer ring, bilateral',
  'H18049 Kayser-Fleischer ring, unspecified eye',
  'H18051 Posterior corneal pigmentations, right eye',
  'H18052 Posterior corneal pigmentations, left eye',
  'H18053 Posterior corneal pigmentations, bilateral',
  'H18059 Posterior corneal pigmentations, unspecified eye',
  'H18061 Stromal corneal pigmentations, right eye',
  'H18062 Stromal corneal pigmentations, left eye',
  'H18063 Stromal corneal pigmentations, bilateral',
  'H18069 Stromal corneal pigmentations, unspecified eye',
  'H1810 Bullous keratopathy, unspecified eye',
  'H1811 Bullous keratopathy, right eye',
  'H1812 Bullous keratopathy, left eye',
  'H1813 Bullous keratopathy, bilateral',
  'H1820 Unspecified corneal edema',
  'H18211 Corneal edema secondary to contact lens, right eye',
  'H18212 Corneal edema secondary to contact lens, left eye',
  'H18213 Corneal edema secondary to contact lens, bilateral',
  'H18219 Corneal edema secondary to contact lens, unspecified eye',
  'H18221 Idiopathic corneal edema, right eye',
  'H18222 Idiopathic corneal edema, left eye',
  'H18223 Idiopathic corneal edema, bilateral',
  'H18229 Idiopathic corneal edema, unspecified eye',
  'H18231 Secondary corneal edema, right eye',
  'H18232 Secondary corneal edema, left eye',
  'H18233 Secondary corneal edema, bilateral',
  'H18239 Secondary corneal edema, unspecified eye',
  'H1830 Unspecified corneal membrane change',
  "H18311 Folds and rupture in Bowman's membrane, right eye",
  "H18312 Folds and rupture in Bowman's membrane, left eye",
  "H18313 Folds and rupture in Bowman's membrane, bilateral",
  "H18319 Folds and rupture in Bowman's membrane, unspecified eye",
  "H18321 Folds in Descemet's membrane, right eye",
  "H18322 Folds in Descemet's membrane, left eye",
  "H18323 Folds in Descemet's membrane, bilateral",
  "H18329 Folds in Descemet's membrane, unspecified eye",
  "H18331 Rupture in Descemet's membrane, right eye",
  "H18332 Rupture in Descemet's membrane, left eye",
  "H18333 Rupture in Descemet's membrane, bilateral",
  "H18339 Rupture in Descemet's membrane, unspecified eye",
  'H1840 Unspecified corneal degeneration',
  'H18411 Arcus senilis, right eye',
  'H18412 Arcus senilis, left eye',
  'H18413 Arcus senilis, bilateral',
  'H18419 Arcus senilis, unspecified eye',
  'H18421 Band keratopathy, right eye',
  'H18422 Band keratopathy, left eye',
  'H18423 Band keratopathy, bilateral',
  'H18429 Band keratopathy, unspecified eye',
  'H1843 Other calcerous corneal degeneration',
  'H18441 Keratomalacia, right eye',
  'H18442 Keratomalacia, left eye',
  'H18443 Keratomalacia, bilateral',
  'H18449 Keratomalacia, unspecified eye',
  'H18451 Nodular corneal degeneration, right eye',
  'H18452 Nodular corneal degeneration, left eye',
  'H18453 Nodular corneal degeneration, bilateral',
  'H18459 Nodular corneal degeneration, unspecified eye',
  'H18461 Peripheral corneal degeneration, right eye',
  'H18462 Peripheral corneal degeneration, left eye',
  'H18463 Peripheral corneal degeneration, bilateral',
  'H18469 Peripheral corneal degeneration, unspecified eye',
  'H1849 Other corneal degeneration',
  'H1850 Unspecified hereditary corneal dystrophies',
  'H1851 Endothelial corneal dystrophy',
  'H1852 Epithelial (juvenile) corneal dystrophy',
  'H1853 Granular corneal dystrophy',
  'H1854 Lattice corneal dystrophy',
  'H1855 Macular corneal dystrophy',
  'H1859 Other hereditary corneal dystrophies',
  'H18601 Keratoconus, unspecified, right eye',
  'H18602 Keratoconus, unspecified, left eye',
  'H18603 Keratoconus, unspecified, bilateral',
  'H18609 Keratoconus, unspecified, unspecified eye',
  'H18611 Keratoconus, stable, right eye',
  'H18612 Keratoconus, stable, left eye',
  'H18613 Keratoconus, stable, bilateral',
  'H18619 Keratoconus, stable, unspecified eye',
  'H18621 Keratoconus, unstable, right eye',
  'H18622 Keratoconus, unstable, left eye',
  'H18623 Keratoconus, unstable, bilateral',
  'H18629 Keratoconus, unstable, unspecified eye',
  'H1870 Unspecified corneal deformity',
  'H18711 Corneal ectasia, right eye',
  'H18712 Corneal ectasia, left eye',
  'H18713 Corneal ectasia, bilateral',
  'H18719 Corneal ectasia, unspecified eye',
  'H18721 Corneal staphyloma, right eye',
  'H18722 Corneal staphyloma, left eye',
  'H18723 Corneal staphyloma, bilateral',
  'H18729 Corneal staphyloma, unspecified eye',
  'H18731 Descemetocele, right eye',
  'H18732 Descemetocele, left eye',
  'H18733 Descemetocele, bilateral',
  'H18739 Descemetocele, unspecified eye',
  'H18791 Other corneal deformities, right eye',
  'H18792 Other corneal deformities, left eye',
  'H18793 Other corneal deformities, bilateral',
  'H18799 Other corneal deformities, unspecified eye',
  'H18811 Anesthesia and hypoesthesia of cornea, right eye',
  'H18812 Anesthesia and hypoesthesia of cornea, left eye',
  'H18813 Anesthesia and hypoesthesia of cornea, bilateral',
  'H18819 Anesthesia and hypoesthesia of cornea, unspecified eye',
  'H18821 Corneal disorder due to contact lens, right eye',
  'H18822 Corneal disorder due to contact lens, left eye',
  'H18823 Corneal disorder due to contact lens, bilateral',
  'H18829 Corneal disorder due to contact lens, unspecified eye',
  'H18831 Recurrent erosion of cornea, right eye',
  'H18832 Recurrent erosion of cornea, left eye',
  'H18833 Recurrent erosion of cornea, bilateral',
  'H18839 Recurrent erosion of cornea, unspecified eye',
  'H18891 Other specified disorders of cornea, right eye',
  'H18892 Other specified disorders of cornea, left eye',
  'H18893 Other specified disorders of cornea, bilateral',
  'H18899 Other specified disorders of cornea, unspecified eye',
  'H189 Unspecified disorder of cornea',
  'H2000 Unspecified acute and subacute iridocyclitis',
  'H20011 Primary iridocyclitis, right eye',
  'H20012 Primary iridocyclitis, left eye',
  'H20013 Primary iridocyclitis, bilateral',
  'H20019 Primary iridocyclitis, unspecified eye',
  'H20021 Recurrent acute iridocyclitis, right eye',
  'H20022 Recurrent acute iridocyclitis, left eye',
  'H20023 Recurrent acute iridocyclitis, bilateral',
  'H20029 Recurrent acute iridocyclitis, unspecified eye',
  'H20031 Secondary infectious iridocyclitis, right eye',
  'H20032 Secondary infectious iridocyclitis, left eye',
  'H20033 Secondary infectious iridocyclitis, bilateral',
  'H20039 Secondary infectious iridocyclitis, unspecified eye',
  'H20041 Secondary noninfectious iridocyclitis, right eye',
  'H20042 Secondary noninfectious iridocyclitis, left eye',
  'H20043 Secondary noninfectious iridocyclitis, bilateral',
  'H20049 Secondary noninfectious iridocyclitis, unspecified eye',
  'H20051 Hypopyon, right eye',
  'H20052 Hypopyon, left eye',
  'H20053 Hypopyon, bilateral',
  'H20059 Hypopyon, unspecified eye',
  'H2010 Chronic iridocyclitis, unspecified eye',
  'H2011 Chronic iridocyclitis, right eye',
  'H2012 Chronic iridocyclitis, left eye',
  'H2013 Chronic iridocyclitis, bilateral',
  'H2020 Lens-induced iridocyclitis, unspecified eye',
  'H2021 Lens-induced iridocyclitis, right eye',
  'H2022 Lens-induced iridocyclitis, left eye',
  'H2023 Lens-induced iridocyclitis, bilateral',
  "H20811 Fuchs' heterochromic cyclitis, right eye",
  "H20812 Fuchs' heterochromic cyclitis, left eye",
  "H20813 Fuchs' heterochromic cyclitis, bilateral",
  "H20819 Fuchs' heterochromic cyclitis, unspecified eye",
  'H20821 Vogt-Koyanagi syndrome, right eye',
  'H20822 Vogt-Koyanagi syndrome, left eye',
  'H20823 Vogt-Koyanagi syndrome, bilateral',
  'H20829 Vogt-Koyanagi syndrome, unspecified eye',
  'H209 Unspecified iridocyclitis',
  'H2100 Hyphema, unspecified eye',
  'H2101 Hyphema, right eye',
  'H2102 Hyphema, left eye',
  'H2103 Hyphema, bilateral',
  'H211X1 Other vascular disorders of iris and ciliary body, right eye',
  'H211X2 Other vascular disorders of iris and ciliary body, left eye',
  'H211X3 Other vascular disorders of iris and ciliary body, bilateral',
  'H211X9 Other vascular disorders of iris and ciliary body, unspecified eye',
  'H21211 Degeneration of chamber angle, right eye',
  'H21212 Degeneration of chamber angle, left eye',
  'H21213 Degeneration of chamber angle, bilateral',
  'H21219 Degeneration of chamber angle, unspecified eye',
  'H21221 Degeneration of ciliary body, right eye',
  'H21222 Degeneration of ciliary body, left eye',
  'H21223 Degeneration of ciliary body, bilateral',
  'H21229 Degeneration of ciliary body, unspecified eye',
  'H21231 Degeneration of iris (pigmentary), right eye',
  'H21232 Degeneration of iris (pigmentary), left eye',
  'H21233 Degeneration of iris (pigmentary), bilateral',
  'H21239 Degeneration of iris (pigmentary), unspecified eye',
  'H21241 Degeneration of pupillary margin, right eye',
  'H21242 Degeneration of pupillary margin, left eye',
  'H21243 Degeneration of pupillary margin, bilateral',
  'H21249 Degeneration of pupillary margin, unspecified eye',
  'H21251 Iridoschisis, right eye',
  'H21252 Iridoschisis, left eye',
  'H21253 Iridoschisis, bilateral',
  'H21259 Iridoschisis, unspecified eye',
  'H21261 Iris atrophy (essential) (progressive), right eye',
  'H21262 Iris atrophy (essential) (progressive), left eye',
  'H21263 Iris atrophy (essential) (progressive), bilateral',
  'H21269 Iris atrophy (essential) (progressive), unspecified eye',
  'H21271 Miotic pupillary cyst, right eye',
  'H21272 Miotic pupillary cyst, left eye',
  'H21273 Miotic pupillary cyst, bilateral',
  'H21279 Miotic pupillary cyst, unspecified eye',
  'H2129 Other iris atrophy',
  'H21301 Idiopathic cysts of iris, ciliary body or anterior chamber, right eye',
  'H21302 Idiopathic cysts of iris, ciliary body or anterior chamber, left eye',
  'H21303 Idiopathic cysts of iris, ciliary body or anterior chamber, bilateral',
  'H21309 Idiopathic cysts of iris, ciliary body or anterior chamber, unspecified eye',
  'H21311 Exudative cysts of iris or anterior chamber, right eye',
  'H21312 Exudative cysts of iris or anterior chamber, left eye',
  'H21313 Exudative cysts of iris or anterior chamber, bilateral',
  'H21319 Exudative cysts of iris or anterior chamber, unspecified eye',
  'H21321 Implantation cysts of iris, ciliary body or anterior chamber, right eye',
  'H21322 Implantation cysts of iris, ciliary body or anterior chamber, left eye',
  'H21323 Implantation cysts of iris, ciliary body or anterior chamber, bilateral',
  'H21329 Implantation cysts of iris, ciliary body or anterior chamber, unspecified eye',
  'H21331 Parasitic cyst of iris, ciliary body or anterior chamber, right eye',
  'H21332 Parasitic cyst of iris, ciliary body or anterior chamber, left eye',
  'H21333 Parasitic cyst of iris, ciliary body or anterior chamber, bilateral',
  'H21339 Parasitic cyst of iris, ciliary body or anterior chamber, unspecified eye',
  'H21341 Primary cyst of pars plana, right eye',
  'H21342 Primary cyst of pars plana, left eye',
  'H21343 Primary cyst of pars plana, bilateral',
  'H21349 Primary cyst of pars plana, unspecified eye',
  'H21351 Exudative cyst of pars plana, right eye',
  'H21352 Exudative cyst of pars plana, left eye',
  'H21353 Exudative cyst of pars plana, bilateral',
  'H21359 Exudative cyst of pars plana, unspecified eye',
  'H2140 Pupillary membranes, unspecified eye',
  'H2141 Pupillary membranes, right eye',
  'H2142 Pupillary membranes, left eye',
  'H2143 Pupillary membranes, bilateral',
  'H21501 Unspecified adhesions of iris, right eye',
  'H21502 Unspecified adhesions of iris, left eye',
  'H21503 Unspecified adhesions of iris, bilateral',
  'H21509 Unspecified adhesions of iris and ciliary body, unspecified eye',
  'H21511 Anterior synechiae (iris), right eye',
  'H21512 Anterior synechiae (iris), left eye',
  'H21513 Anterior synechiae (iris), bilateral',
  'H21519 Anterior synechiae (iris), unspecified eye',
  'H21521 Goniosynechiae, right eye',
  'H21522 Goniosynechiae, left eye',
  'H21523 Goniosynechiae, bilateral',
  'H21529 Goniosynechiae, unspecified eye',
  'H21531 Iridodialysis, right eye',
  'H21532 Iridodialysis, left eye',
  'H21533 Iridodialysis, bilateral',
  'H21539 Iridodialysis, unspecified eye',
  'H21541 Posterior synechiae (iris), right eye',
  'H21542 Posterior synechiae (iris), left eye',
  'H21543 Posterior synechiae (iris), bilateral',
  'H21549 Posterior synechiae (iris), unspecified eye',
  'H21551 Recession of chamber angle, right eye',
  'H21552 Recession of chamber angle, left eye',
  'H21553 Recession of chamber angle, bilateral',
  'H21559 Recession of chamber angle, unspecified eye',
  'H21561 Pupillary abnormality, right eye',
  'H21562 Pupillary abnormality, left eye',
  'H21563 Pupillary abnormality, bilateral',
  'H21569 Pupillary abnormality, unspecified eye',
  'H2181 Floppy iris syndrome',
  'H2182 Plateau iris syndrome (post-iridectomy) (postprocedural)',
  'H2189 Other specified disorders of iris and ciliary body',
  'H219 Unspecified disorder of iris and ciliary body',
  'H22 Disorders of iris and ciliary body in diseases classified elsewhere',
  'H25011 Cortical age-related cataract, right eye',
  'H25012 Cortical age-related cataract, left eye',
  'H25013 Cortical age-related cataract, bilateral',
  'H25019 Cortical age-related cataract, unspecified eye',
  'H25031 Anterior subcapsular polar age-related cataract, right eye',
  'H25032 Anterior subcapsular polar age-related cataract, left eye',
  'H25033 Anterior subcapsular polar age-related cataract, bilateral',
  'H25039 Anterior subcapsular polar age-related cataract, unspecified eye',
  'H25041 Posterior subcapsular polar age-related cataract, right eye',
  'H25042 Posterior subcapsular polar age-related cataract, left eye',
  'H25043 Posterior subcapsular polar age-related cataract, bilateral',
  'H25049 Posterior subcapsular polar age-related cataract, unspecified eye',
  'H25091 Other age-related incipient cataract, right eye',
  'H25092 Other age-related incipient cataract, left eye',
  'H25093 Other age-related incipient cataract, bilateral',
  'H25099 Other age-related incipient cataract, unspecified eye',
  'H2510 Age-related nuclear cataract, unspecified eye',
  'H2511 Age-related nuclear cataract, right eye',
  'H2512 Age-related nuclear cataract, left eye',
  'H2513 Age-related nuclear cataract, bilateral',
  'H2520 Age-related cataract, morgagnian type, unspecified eye',
  'H2521 Age-related cataract, morgagnian type, right eye',
  'H2522 Age-related cataract, morgagnian type, left eye',
  'H2523 Age-related cataract, morgagnian type, bilateral',
  'H25811 Combined forms of age-related cataract, right eye',
  'H25812 Combined forms of age-related cataract, left eye',
  'H25813 Combined forms of age-related cataract, bilateral',
  'H25819 Combined forms of age-related cataract, unspecified eye',
  'H2589 Other age-related cataract',
  'H259 Unspecified age-related cataract',
  'H26001 Unspecified infantile and juvenile cataract, right eye',
  'H26002 Unspecified infantile and juvenile cataract, left eye',
  'H26003 Unspecified infantile and juvenile cataract, bilateral',
  'H26009 Unspecified infantile and juvenile cataract, unspecified eye',
  'H26011 Infantile and juvenile cortical, lamellar, or zonular cataract, right eye',
  'H26012 Infantile and juvenile cortical, lamellar, or zonular cataract, left eye',
  'H26013 Infantile and juvenile cortical, lamellar, or zonular cataract, bilateral',
  'H26019 Infantile and juvenile cortical, lamellar, or zonular cataract, unspecified eye',
  'H26031 Infantile and juvenile nuclear cataract, right eye',
  'H26032 Infantile and juvenile nuclear cataract, left eye',
  'H26033 Infantile and juvenile nuclear cataract, bilateral',
  'H26039 Infantile and juvenile nuclear cataract, unspecified eye',
  'H26041 Anterior subcapsular polar infantile and juvenile cataract, right eye',
  'H26042 Anterior subcapsular polar infantile and juvenile cataract, left eye',
  'H26043 Anterior subcapsular polar infantile and juvenile cataract, bilateral',
  'H26049 Anterior subcapsular polar infantile and juvenile cataract, unspecified eye',
  'H26051 Posterior subcapsular polar infantile and juvenile cataract, right eye',
  'H26052 Posterior subcapsular polar infantile and juvenile cataract, left eye',
  'H26053 Posterior subcapsular polar infantile and juvenile cataract, bilateral',
  'H26059 Posterior subcapsular polar infantile and juvenile cataract, unspecified eye',
  'H26061 Combined forms of infantile and juvenile cataract, right eye',
  'H26062 Combined forms of infantile and juvenile cataract, left eye',
  'H26063 Combined forms of infantile and juvenile cataract, bilateral',
  'H26069 Combined forms of infantile and juvenile cataract, unspecified eye',
  'H2609 Other infantile and juvenile cataract',
  'H26101 Unspecified traumatic cataract, right eye',
  'H26102 Unspecified traumatic cataract, left eye',
  'H26103 Unspecified traumatic cataract, bilateral',
  'H26109 Unspecified traumatic cataract, unspecified eye',
  'H26111 Localized traumatic opacities, right eye',
  'H26112 Localized traumatic opacities, left eye',
  'H26113 Localized traumatic opacities, bilateral',
  'H26119 Localized traumatic opacities, unspecified eye',
  'H26121 Partially resolved traumatic cataract, right eye',
  'H26122 Partially resolved traumatic cataract, left eye',
  'H26123 Partially resolved traumatic cataract, bilateral',
  'H26129 Partially resolved traumatic cataract, unspecified eye',
  'H26131 Total traumatic cataract, right eye',
  'H26132 Total traumatic cataract, left eye',
  'H26133 Total traumatic cataract, bilateral',
  'H26139 Total traumatic cataract, unspecified eye',
  'H2620 Unspecified complicated cataract',
  'H26211 Cataract with neovascularization, right eye',
  'H26212 Cataract with neovascularization, left eye',
  'H26213 Cataract with neovascularization, bilateral',
  'H26219 Cataract with neovascularization, unspecified eye',
  'H26221 Cataract secondary to ocular disorders (degenerative) (inflammatory), right eye',
  'H26222 Cataract secondary to ocular disorders (degenerative) (inflammatory), left eye',
  'H26223 Cataract secondary to ocular disorders (degenerative) (inflammatory), bilateral',
  'H26229 Cataract secondary to ocular disorders (degenerative) (inflammatory), unspecified eye',
  'H26231 Glaucomatous flecks (subcapsular), right eye',
  'H26232 Glaucomatous flecks (subcapsular), left eye',
  'H26233 Glaucomatous flecks (subcapsular), bilateral',
  'H26239 Glaucomatous flecks (subcapsular), unspecified eye',
  'H2630 Drug-induced cataract, unspecified eye',
  'H2631 Drug-induced cataract, right eye',
  'H2632 Drug-induced cataract, left eye',
  'H2633 Drug-induced cataract, bilateral',
  'H2640 Unspecified secondary cataract',
  "H26411 Soemmering's ring, right eye",
  "H26412 Soemmering's ring, left eye",
  "H26413 Soemmering's ring, bilateral",
  "H26419 Soemmering's ring, unspecified eye",
  'H26491 Other secondary cataract, right eye',
  'H26492 Other secondary cataract, left eye',
  'H26493 Other secondary cataract, bilateral',
  'H26499 Other secondary cataract, unspecified eye',
  'H268 Other specified cataract',
  'H269 Unspecified cataract',
  'H2700 Aphakia, unspecified eye',
  'H2701 Aphakia, right eye',
  'H2702 Aphakia, left eye',
  'H2703 Aphakia, bilateral',
  'H2710 Unspecified dislocation of lens',
  'H27111 Subluxation of lens, right eye',
  'H27112 Subluxation of lens, left eye',
  'H27113 Subluxation of lens, bilateral',
  'H27119 Subluxation of lens, unspecified eye',
  'H27121 Anterior dislocation of lens, right eye',
  'H27122 Anterior dislocation of lens, left eye',
  'H27123 Anterior dislocation of lens, bilateral',
  'H27129 Anterior dislocation of lens, unspecified eye',
  'H27131 Posterior dislocation of lens, right eye',
  'H27132 Posterior dislocation of lens, left eye',
  'H27133 Posterior dislocation of lens, bilateral',
  'H27139 Posterior dislocation of lens, unspecified eye',
  'H278 Other specified disorders of lens',
  'H279 Unspecified disorder of lens',
  'H28 Cataract in diseases classified elsewhere',
  'H30001 Unspecified focal chorioretinal inflammation, right eye',
  'H30002 Unspecified focal chorioretinal inflammation, left eye',
  'H30003 Unspecified focal chorioretinal inflammation, bilateral',
  'H30009 Unspecified focal chorioretinal inflammation, unspecified eye',
  'H30011 Focal chorioretinal inflammation, juxtapapillary, right eye',
  'H30012 Focal chorioretinal inflammation, juxtapapillary, left eye',
  'H30013 Focal chorioretinal inflammation, juxtapapillary, bilateral',
  'H30019 Focal chorioretinal inflammation, juxtapapillary, unspecified eye',
  'H30021 Focal chorioretinal inflammation of posterior pole, right eye',
  'H30022 Focal chorioretinal inflammation of posterior pole, left eye',
  'H30023 Focal chorioretinal inflammation of posterior pole, bilateral',
  'H30029 Focal chorioretinal inflammation of posterior pole, unspecified eye',
  'H30031 Focal chorioretinal inflammation, peripheral, right eye',
  'H30032 Focal chorioretinal inflammation, peripheral, left eye',
  'H30033 Focal chorioretinal inflammation, peripheral, bilateral',
  'H30039 Focal chorioretinal inflammation, peripheral, unspecified eye',
  'H30041 Focal chorioretinal inflammation, macular or paramacular, right eye',
  'H30042 Focal chorioretinal inflammation, macular or paramacular, left eye',
  'H30043 Focal chorioretinal inflammation, macular or paramacular, bilateral',
  'H30049 Focal chorioretinal inflammation, macular or paramacular, unspecified eye',
  'H30101 Unspecified disseminated chorioretinal inflammation, right eye',
  'H30102 Unspecified disseminated chorioretinal inflammation, left eye',
  'H30103 Unspecified disseminated chorioretinal inflammation, bilateral',
  'H30109 Unspecified disseminated chorioretinal inflammation, unspecified eye',
  'H30111 Disseminated chorioretinal inflammation of posterior pole, right eye',
  'H30112 Disseminated chorioretinal inflammation of posterior pole, left eye',
  'H30113 Disseminated chorioretinal inflammation of posterior pole, bilateral',
  'H30119 Disseminated chorioretinal inflammation of posterior pole, unspecified eye',
  'H30121 Disseminated chorioretinal inflammation, peripheral right eye',
  'H30122 Disseminated chorioretinal inflammation, peripheral, left eye',
  'H30123 Disseminated chorioretinal inflammation, peripheral, bilateral',
  'H30129 Disseminated chorioretinal inflammation, peripheral, unspecified eye',
  'H30131 Disseminated chorioretinal inflammation, generalized, right eye',
  'H30132 Disseminated chorioretinal inflammation, generalized, left eye',
  'H30133 Disseminated chorioretinal inflammation, generalized, bilateral',
  'H30139 Disseminated chorioretinal inflammation, generalized, unspecified eye',
  'H30141 Acute posterior multifocal placoid pigment epitheliopathy, right eye',
  'H30142 Acute posterior multifocal placoid pigment epitheliopathy, left eye',
  'H30143 Acute posterior multifocal placoid pigment epitheliopathy, bilateral',
  'H30149 Acute posterior multifocal placoid pigment epitheliopathy, unspecified eye',
  'H3020 Posterior cyclitis, unspecified eye',
  'H3021 Posterior cyclitis, right eye',
  'H3022 Posterior cyclitis, left eye',
  'H3023 Posterior cyclitis, bilateral',
  "H30811 Harada's disease, right eye",
  "H30812 Harada's disease, left eye",
  "H30813 Harada's disease, bilateral",
  "H30819 Harada's disease, unspecified eye",
  'H30891 Other chorioretinal inflammations, right eye',
  'H30892 Other chorioretinal inflammations, left eye',
  'H30893 Other chorioretinal inflammations, bilateral',
  'H30899 Other chorioretinal inflammations, unspecified eye',
  'H3090 Unspecified chorioretinal inflammation, unspecified eye',
  'H3091 Unspecified chorioretinal inflammation, right eye',
  'H3092 Unspecified chorioretinal inflammation, left eye',
  'H3093 Unspecified chorioretinal inflammation, bilateral',
  'H31001 Unspecified chorioretinal scars, right eye',
  'H31002 Unspecified chorioretinal scars, left eye',
  'H31003 Unspecified chorioretinal scars, bilateral',
  'H31009 Unspecified chorioretinal scars, unspecified eye',
  'H31011 Macula scars of posterior pole (postinflammatory) (post-traumatic), right eye',
  'H31012 Macula scars of posterior pole (postinflammatory) (post-traumatic), left eye',
  'H31013 Macula scars of posterior pole (postinflammatory) (post-traumatic), bilateral',
  'H31019 Macula scars of posterior pole (postinflammatory) (post-traumatic), unspecified eye',
  'H31021 Solar retinopathy, right eye',
  'H31022 Solar retinopathy, left eye',
  'H31023 Solar retinopathy, bilateral',
  'H31029 Solar retinopathy, unspecified eye',
  'H31091 Other chorioretinal scars, right eye',
  'H31092 Other chorioretinal scars, left eye',
  'H31093 Other chorioretinal scars, bilateral',
  'H31099 Other chorioretinal scars, unspecified eye',
  'H31101 Choroidal degeneration, unspecified, right eye',
  'H31102 Choroidal degeneration, unspecified, left eye',
  'H31103 Choroidal degeneration, unspecified, bilateral',
  'H31109 Choroidal degeneration, unspecified, unspecified eye',
  'H31111 Age-related choroidal atrophy, right eye',
  'H31112 Age-related choroidal atrophy, left eye',
  'H31113 Age-related choroidal atrophy, bilateral',
  'H31119 Age-related choroidal atrophy, unspecified eye',
  'H31121 Diffuse secondary atrophy of choroid, right eye',
  'H31122 Diffuse secondary atrophy of choroid, left eye',
  'H31123 Diffuse secondary atrophy of choroid, bilateral',
  'H31129 Diffuse secondary atrophy of choroid, unspecified eye',
  'H3120 Hereditary choroidal dystrophy, unspecified',
  'H3121 Choroideremia',
  'H3122 Choroidal dystrophy (central areolar) (generalized) (peripapillary)',
  'H3123 Gyrate atrophy, choroid',
  'H3129 Other hereditary choroidal dystrophy',
  'H31301 Unspecified choroidal hemorrhage, right eye',
  'H31302 Unspecified choroidal hemorrhage, left eye',
  'H31303 Unspecified choroidal hemorrhage, bilateral',
  'H31309 Unspecified choroidal hemorrhage, unspecified eye',
  'H31311 Expulsive choroidal hemorrhage, right eye',
  'H31312 Expulsive choroidal hemorrhage, left eye',
  'H31313 Expulsive choroidal hemorrhage, bilateral',
  'H31319 Expulsive choroidal hemorrhage, unspecified eye',
  'H31321 Choroidal rupture, right eye',
  'H31322 Choroidal rupture, left eye',
  'H31323 Choroidal rupture, bilateral',
  'H31329 Choroidal rupture, unspecified eye',
  'H31401 Unspecified choroidal detachment, right eye',
  'H31402 Unspecified choroidal detachment, left eye',
  'H31403 Unspecified choroidal detachment, bilateral',
  'H31409 Unspecified choroidal detachment, unspecified eye',
  'H31411 Hemorrhagic choroidal detachment, right eye',
  'H31412 Hemorrhagic choroidal detachment, left eye',
  'H31413 Hemorrhagic choroidal detachment, bilateral',
  'H31419 Hemorrhagic choroidal detachment, unspecified eye',
  'H31421 Serous choroidal detachment, right eye',
  'H31422 Serous choroidal detachment, left eye',
  'H31423 Serous choroidal detachment, bilateral',
  'H31429 Serous choroidal detachment, unspecified eye',
  'H318 Other specified disorders of choroid',
  'H319 Unspecified disorder of choroid',
  'H32 Chorioretinal disorders in diseases classified elsewhere',
  'H33001 Unspecified retinal detachment with retinal break, right eye',
  'H33002 Unspecified retinal detachment with retinal break, left eye',
  'H33003 Unspecified retinal detachment with retinal break, bilateral',
  'H33009 Unspecified retinal detachment with retinal break, unspecified eye',
  'H33011 Retinal detachment with single break, right eye',
  'H33012 Retinal detachment with single break, left eye',
  'H33013 Retinal detachment with single break, bilateral',
  'H33019 Retinal detachment with single break, unspecified eye',
  'H33021 Retinal detachment with multiple breaks, right eye',
  'H33022 Retinal detachment with multiple breaks, left eye',
  'H33023 Retinal detachment with multiple breaks, bilateral',
  'H33029 Retinal detachment with multiple breaks, unspecified eye',
  'H33031 Retinal detachment with giant retinal tear, right eye',
  'H33032 Retinal detachment with giant retinal tear, left eye',
  'H33033 Retinal detachment with giant retinal tear, bilateral',
  'H33039 Retinal detachment with giant retinal tear, unspecified eye',
  'H33041 Retinal detachment with retinal dialysis, right eye',
  'H33042 Retinal detachment with retinal dialysis, left eye',
  'H33043 Retinal detachment with retinal dialysis, bilateral',
  'H33049 Retinal detachment with retinal dialysis, unspecified eye',
  'H33051 Total retinal detachment, right eye',
  'H33052 Total retinal detachment, left eye',
  'H33053 Total retinal detachment, bilateral',
  'H33059 Total retinal detachment, unspecified eye',
  'H33101 Unspecified retinoschisis, right eye',
  'H33102 Unspecified retinoschisis, left eye',
  'H33103 Unspecified retinoschisis, bilateral',
  'H33109 Unspecified retinoschisis, unspecified eye',
  'H33111 Cyst of ora serrata, right eye',
  'H33112 Cyst of ora serrata, left eye',
  'H33113 Cyst of ora serrata, bilateral',
  'H33119 Cyst of ora serrata, unspecified eye',
  'H33121 Parasitic cyst of retina, right eye',
  'H33122 Parasitic cyst of retina, left eye',
  'H33123 Parasitic cyst of retina, bilateral',
  'H33129 Parasitic cyst of retina, unspecified eye',
  'H33191 Other retinoschisis and retinal cysts, right eye',
  'H33192 Other retinoschisis and retinal cysts, left eye',
  'H33193 Other retinoschisis and retinal cysts, bilateral',
  'H33199 Other retinoschisis and retinal cysts, unspecified eye',
  'H3320 Serous retinal detachment, unspecified eye',
  'H3321 Serous retinal detachment, right eye',
  'H3322 Serous retinal detachment, left eye',
  'H3323 Serous retinal detachment, bilateral',
  'H33301 Unspecified retinal break, right eye',
  'H33302 Unspecified retinal break, left eye',
  'H33303 Unspecified retinal break, bilateral',
  'H33309 Unspecified retinal break, unspecified eye',
  'H33311 Horseshoe tear of retina without detachment, right eye',
  'H33312 Horseshoe tear of retina without detachment, left eye',
  'H33313 Horseshoe tear of retina without detachment, bilateral',
  'H33319 Horseshoe tear of retina without detachment, unspecified eye',
  'H33321 Round hole, right eye',
  'H33322 Round hole, left eye',
  'H33323 Round hole, bilateral',
  'H33329 Round hole, unspecified eye',
  'H33331 Multiple defects of retina without detachment, right eye',
  'H33332 Multiple defects of retina without detachment, left eye',
  'H33333 Multiple defects of retina without detachment, bilateral',
  'H33339 Multiple defects of retina without detachment, unspecified eye',
  'H3340 Traction detachment of retina, unspecified eye',
  'H3341 Traction detachment of retina, right eye',
  'H3342 Traction detachment of retina, left eye',
  'H3343 Traction detachment of retina, bilateral',
  'H338 Other retinal detachments',
  'H3400 Transient retinal artery occlusion, unspecified eye',
  'H3401 Transient retinal artery occlusion, right eye',
  'H3402 Transient retinal artery occlusion, left eye',
  'H3403 Transient retinal artery occlusion, bilateral',
  'H3410 Central retinal artery occlusion, unspecified eye',
  'H3411 Central retinal artery occlusion, right eye',
  'H3412 Central retinal artery occlusion, left eye',
  'H3413 Central retinal artery occlusion, bilateral',
  'H34211 Partial retinal artery occlusion, right eye',
  'H34212 Partial retinal artery occlusion, left eye',
  'H34213 Partial retinal artery occlusion, bilateral',
  'H34219 Partial retinal artery occlusion, unspecified eye',
  'H34231 Retinal artery branch occlusion, right eye',
  'H34232 Retinal artery branch occlusion, left eye',
  'H34233 Retinal artery branch occlusion, bilateral',
  'H34239 Retinal artery branch occlusion, unspecified eye',
  'H348110 Central retinal vein occlusion, right eye, with macular edema',
  'H348111 Central retinal vein occlusion, right eye, with retinal neovascularization',
  'H348112 Central retinal vein occlusion, right eye, stable',
  'H348120 Central retinal vein occlusion, left eye, with macular edema',
  'H348121 Central retinal vein occlusion, left eye, with retinal neovascularization',
  'H348122 Central retinal vein occlusion, left eye, stable',
  'H348130 Central retinal vein occlusion, bilateral, with macular edema',
  'H348131 Central retinal vein occlusion, bilateral, with retinal neovascularization',
  'H348132 Central retinal vein occlusion, bilateral, stable',
  'H348190 Central retinal vein occlusion, unspecified eye, with macular edema',
  'H348191 Central retinal vein occlusion, unspecified eye, with retinal neovascularization',
  'H348192 Central retinal vein occlusion, unspecified eye, stable',
  'H34821 Venous engorgement, right eye',
  'H34822 Venous engorgement, left eye',
  'H34823 Venous engorgement, bilateral',
  'H34829 Venous engorgement, unspecified eye',
  'H348310 Tributary (branch) retinal vein occlusion, right eye, with macular edema',
  'H348311 Tributary (branch) retinal vein occlusion, right eye, with retinal neovascularization',
  'H348312 Tributary (branch) retinal vein occlusion, right eye, stable',
  'H348320 Tributary (branch) retinal vein occlusion, left eye, with macular edema',
  'H348321 Tributary (branch) retinal vein occlusion, left eye, with retinal neovascularization',
  'H348322 Tributary (branch) retinal vein occlusion, left eye, stable',
  'H348330 Tributary (branch) retinal vein occlusion, bilateral, with macular edema',
  'H348331 Tributary (branch) retinal vein occlusion, bilateral, with retinal neovascularization',
  'H348332 Tributary (branch) retinal vein occlusion, bilateral, stable',
  'H348390 Tributary (branch) retinal vein occlusion, unspecified eye, with macular edema',
  'H348391 Tributary (branch) retinal vein occlusion, unspecified eye, with retinal neovascularization',
  'H348392 Tributary (branch) retinal vein occlusion, unspecified eye, stable',
  'H349 Unspecified retinal vascular occlusion',
  'H3500 Unspecified background retinopathy',
  'H35011 Changes in retinal vascular appearance, right eye',
  'H35012 Changes in retinal vascular appearance, left eye',
  'H35013 Changes in retinal vascular appearance, bilateral',
  'H35019 Changes in retinal vascular appearance, unspecified eye',
  'H35021 Exudative retinopathy, right eye',
  'H35022 Exudative retinopathy, left eye',
  'H35023 Exudative retinopathy, bilateral',
  'H35029 Exudative retinopathy, unspecified eye',
  'H35031 Hypertensive retinopathy, right eye',
  'H35032 Hypertensive retinopathy, left eye',
  'H35033 Hypertensive retinopathy, bilateral',
  'H35039 Hypertensive retinopathy, unspecified eye',
  'H35041 Retinal micro-aneurysms, unspecified, right eye',
  'H35042 Retinal micro-aneurysms, unspecified, left eye',
  'H35043 Retinal micro-aneurysms, unspecified, bilateral',
  'H35049 Retinal micro-aneurysms, unspecified, unspecified eye',
  'H35051 Retinal neovascularization, unspecified, right eye',
  'H35052 Retinal neovascularization, unspecified, left eye',
  'H35053 Retinal neovascularization, unspecified, bilateral',
  'H35059 Retinal neovascularization, unspecified, unspecified eye',
  'H35061 Retinal vasculitis, right eye',
  'H35062 Retinal vasculitis, left eye',
  'H35063 Retinal vasculitis, bilateral',
  'H35069 Retinal vasculitis, unspecified eye',
  'H35071 Retinal telangiectasis, right eye',
  'H35072 Retinal telangiectasis, left eye',
  'H35073 Retinal telangiectasis, bilateral',
  'H35079 Retinal telangiectasis, unspecified eye',
  'H3509 Other intraretinal microvascular abnormalities',
  'H35101 Retinopathy of prematurity, unspecified, right eye',
  'H35102 Retinopathy of prematurity, unspecified, left eye',
  'H35103 Retinopathy of prematurity, unspecified, bilateral',
  'H35109 Retinopathy of prematurity, unspecified, unspecified eye',
  'H35111 Retinopathy of prematurity, stage 0, right eye',
  'H35112 Retinopathy of prematurity, stage 0, left eye',
  'H35113 Retinopathy of prematurity, stage 0, bilateral',
  'H35119 Retinopathy of prematurity, stage 0, unspecified eye',
  'H35121 Retinopathy of prematurity, stage 1, right eye',
  'H35122 Retinopathy of prematurity, stage 1, left eye',
  'H35123 Retinopathy of prematurity, stage 1, bilateral',
  'H35129 Retinopathy of prematurity, stage 1, unspecified eye',
  'H35131 Retinopathy of prematurity, stage 2, right eye',
  'H35132 Retinopathy of prematurity, stage 2, left eye',
  'H35133 Retinopathy of prematurity, stage 2, bilateral',
  'H35139 Retinopathy of prematurity, stage 2, unspecified eye',
  'H35141 Retinopathy of prematurity, stage 3, right eye',
  'H35142 Retinopathy of prematurity, stage 3, left eye',
  'H35143 Retinopathy of prematurity, stage 3, bilateral',
  'H35149 Retinopathy of prematurity, stage 3, unspecified eye',
  'H35151 Retinopathy of prematurity, stage 4, right eye',
  'H35152 Retinopathy of prematurity, stage 4, left eye',
  'H35153 Retinopathy of prematurity, stage 4, bilateral',
  'H35159 Retinopathy of prematurity, stage 4, unspecified eye',
  'H35161 Retinopathy of prematurity, stage 5, right eye',
  'H35162 Retinopathy of prematurity, stage 5, left eye',
  'H35163 Retinopathy of prematurity, stage 5, bilateral',
  'H35169 Retinopathy of prematurity, stage 5, unspecified eye',
  'H35171 Retrolental fibroplasia, right eye',
  'H35172 Retrolental fibroplasia, left eye',
  'H35173 Retrolental fibroplasia, bilateral',
  'H35179 Retrolental fibroplasia, unspecified eye',
  'H3520 Other non-diabetic proliferative retinopathy, unspecified eye',
  'H3521 Other non-diabetic proliferative retinopathy, right eye',
  'H3522 Other non-diabetic proliferative retinopathy, left eye',
  'H3523 Other non-diabetic proliferative retinopathy, bilateral',
  'H3530 Unspecified macular degeneration',
  'H353110 Nonexudative age-related macular degeneration, right eye, stage unspecified',
  'H353111 Nonexudative age-related macular degeneration, right eye, early dry stage',
  'H353112 Nonexudative age-related macular degeneration, right eye, intermediate dry stage',
  'H353113 Nonexudative age-related macular degeneration, right eye, advanced atrophic without',
  'subfoveal involvement',
  'H353114 Nonexudative age-related macular degeneration, right eye, advanced atrophic with subfoveal',
  'involvement',
  'H353120 Nonexudative age-related macular degeneration, left eye, stage unspecified',
  'H353121 Nonexudative age-related macular degeneration, left eye, early dry stage',
  'H353122 Nonexudative age-related macular degeneration, left eye, intermediate dry stage',
  'H353123 Nonexudative age-related macular degeneration, left eye, advanced atrophic without',
  'subfoveal involvement',
  'H353124 Nonexudative age-related macular degeneration, left eye, advanced atrophic with subfoveal',
  'involvement',
  'H353130 Nonexudative age-related macular degeneration, bilateral, stage unspecified',
  'H353131 Nonexudative age-related macular degeneration, bilateral, early dry stage',
  'H353132 Nonexudative age-related macular degeneration, bilateral, intermediate dry stage',
  'H353133 Nonexudative age-related macular degeneration, bilateral, advanced atrophic without',
  'subfoveal involvement',
  'H353134 Nonexudative age-related macular degeneration, bilateral, advanced atrophic with subfoveal',
  'involvement',
  'H353190 Nonexudative age-related macular degeneration, unspecified eye, stage unspecified',
  'H353191 Nonexudative age-related macular degeneration, unspecified eye, early dry stage',
  'H353192 Nonexudative age-related macular degeneration, unspecified eye, intermediate dry stage',
  'H353193 Nonexudative age-related macular degeneration, unspecified eye, advanced atrophic without',
  'subfoveal involvement',
  'H353194 Nonexudative age-related macular degeneration, unspecified eye, advanced atrophic with',
  'subfoveal involvement',
  'H353210 Exudative age-related macular degeneration, right eye, stage unspecified',
  'H353211 Exudative age-related macular degeneration, right eye, with active choroidal',
  'neovascularization',
  'H353212 Exudative age-related macular degeneration, right eye, with inactive choroidal',
  'neovascularization',
  'H353213 Exudative age-related macular degeneration, right eye, with inactive scar',
  'H353220 Exudative age-related macular degeneration, left eye, stage unspecified',
  'H353221 Exudative age-related macular degeneration, left eye, with active choroidal neovascularization',
  'H353222 Exudative age-related macular degeneration, left eye, with inactive choroidal',
  'neovascularization',
  'H353223 Exudative age-related macular degeneration, left eye, with inactive scar',
  'H353230 Exudative age-related macular degeneration, bilateral, stage unspecified',
  'H353231 Exudative age-related macular degeneration, bilateral, with active choroidal',
  'neovascularization',
  'H353232 Exudative age-related macular degeneration, bilateral, with inactive choroidal',
  'neovascularization',
  'H353233 Exudative age-related macular degeneration, bilateral, with inactive scar',
  'H353290 Exudative age-related macular degeneration, unspecified eye, stage unspecified',
  'H353291 Exudative age-related macular degeneration, unspecified eye, with active choroidal',
  'neovascularization',
  'H353292 Exudative age-related macular degeneration, unspecified eye, with inactive choroidal',
  'neovascularization',
  'H353293 Exudative age-related macular degeneration, unspecified eye, with inactive scar',
  'H3533 Angioid streaks of macula',
  'H35341 Macular cyst, hole, or pseudohole, right eye',
  'H35342 Macular cyst, hole, or pseudohole, left eye',
  'H35343 Macular cyst, hole, or pseudohole, bilateral',
  'H35349 Macular cyst, hole, or pseudohole, unspecified eye',
  'H35351 Cystoid macular degeneration, right eye',
  'H35352 Cystoid macular degeneration, left eye',
  'H35353 Cystoid macular degeneration, bilateral',
  'H35359 Cystoid macular degeneration, unspecified eye',
  'H35361 Drusen (degenerative) of macula, right eye',
  'H35362 Drusen (degenerative) of macula, left eye',
  'H35363 Drusen (degenerative) of macula, bilateral',
  'H35369 Drusen (degenerative) of macula, unspecified eye',
  'H35371 Puckering of macula, right eye',
  'H35372 Puckering of macula, left eye',
  'H35373 Puckering of macula, bilateral',
  'H35379 Puckering of macula, unspecified eye',
  'H35381 Toxic maculopathy, right eye',
  'H35382 Toxic maculopathy, left eye',
  'H35383 Toxic maculopathy, bilateral',
  'H35389 Toxic maculopathy, unspecified eye',
  'H3540 Unspecified peripheral retinal degeneration',
  'H35411 Lattice degeneration of retina, right eye',
  'H35412 Lattice degeneration of retina, left eye',
  'H35413 Lattice degeneration of retina, bilateral',
  'H35419 Lattice degeneration of retina, unspecified eye',
  'H35421 Microcystoid degeneration of retina, right eye',
  'H35422 Microcystoid degeneration of retina, left eye',
  'H35423 Microcystoid degeneration of retina, bilateral',
  'H35429 Microcystoid degeneration of retina, unspecified eye',
  'H35431 Paving stone degeneration of retina, right eye',
  'H35432 Paving stone degeneration of retina, left eye',
  'H35433 Paving stone degeneration of retina, bilateral',
  'H35439 Paving stone degeneration of retina, unspecified eye',
  'H35441 Age-related reticular degeneration of retina, right eye',
  'H35442 Age-related reticular degeneration of retina, left eye',
  'H35443 Age-related reticular degeneration of retina, bilateral',
  'H35449 Age-related reticular degeneration of retina, unspecified eye',
  'H35451 Secondary pigmentary degeneration, right eye',
  'H35452 Secondary pigmentary degeneration, left eye',
  'H35453 Secondary pigmentary degeneration, bilateral',
  'H35459 Secondary pigmentary degeneration, unspecified eye',
  'H35461 Secondary vitreoretinal degeneration, right eye',
  'H35462 Secondary vitreoretinal degeneration, left eye',
  'H35463 Secondary vitreoretinal degeneration, bilateral',
  'H35469 Secondary vitreoretinal degeneration, unspecified eye',
  'H3550 Unspecified hereditary retinal dystrophy',
  'H3551 Vitreoretinal dystrophy',
  'H3552 Pigmentary retinal dystrophy',
  'H3553 Other dystrophies primarily involving the sensory retina',
  'H3554 Dystrophies primarily involving the retinal pigment epithelium',
  'H3560 Retinal hemorrhage, unspecified eye',
  'H3561 Retinal hemorrhage, right eye',
  'H3562 Retinal hemorrhage, left eye',
  'H3563 Retinal hemorrhage, bilateral',
  'H3570 Unspecified separation of retinal layers',
  'H35711 Central serous chorioretinopathy, right eye',
  'H35712 Central serous chorioretinopathy, left eye',
  'H35713 Central serous chorioretinopathy, bilateral',
  'H35719 Central serous chorioretinopathy, unspecified eye',
  'H35721 Serous detachment of retinal pigment epithelium, right eye',
  'H35722 Serous detachment of retinal pigment epithelium, left eye',
  'H35723 Serous detachment of retinal pigment epithelium, bilateral',
  'H35729 Serous detachment of retinal pigment epithelium, unspecified eye',
  'H35731 Hemorrhagic detachment of retinal pigment epithelium, right eye',
  'H35732 Hemorrhagic detachment of retinal pigment epithelium, left eye',
  'H35733 Hemorrhagic detachment of retinal pigment epithelium, bilateral',
  'H35739 Hemorrhagic detachment of retinal pigment epithelium, unspecified eye',
  'H3581 Retinal edema',
  'H3582 Retinal ischemia',
  'H3589 Other specified retinal disorders',
  'H359 Unspecified retinal disorder',
  'H36 Retinal disorders in diseases classified elsewhere',
  'H40001 Preglaucoma, unspecified, right eye',
  'H40002 Preglaucoma, unspecified, left eye',
  'H40003 Preglaucoma, unspecified, bilateral',
  'H40009 Preglaucoma, unspecified, unspecified eye',
  'H40011 Open angle with borderline findings, low risk, right eye',
  'H40012 Open angle with borderline findings, low risk, left eye',
  'H40013 Open angle with borderline findings, low risk, bilateral',
  'H40019 Open angle with borderline findings, low risk, unspecified eye',
  'H40021 Open angle with borderline findings, high risk, right eye',
  'H40022 Open angle with borderline findings, high risk, left eye',
  'H40023 Open angle with borderline findings, high risk, bilateral',
  'H40029 Open angle with borderline findings, high risk, unspecified eye',
  'H40031 Anatomical narrow angle, right eye',
  'H40032 Anatomical narrow angle, left eye',
  'H40033 Anatomical narrow angle, bilateral',
  'H40039 Anatomical narrow angle, unspecified eye',
  'H40041 Steroid responder, right eye',
  'H40042 Steroid responder, left eye',
  'H40043 Steroid responder, bilateral',
  'H40049 Steroid responder, unspecified eye',
  'H40051 Ocular hypertension, right eye',
  'H40052 Ocular hypertension, left eye',
  'H40053 Ocular hypertension, bilateral',
  'H40059 Ocular hypertension, unspecified eye',
  'H40061 Primary angle closure without glaucoma damage, right eye',
  'H40062 Primary angle closure without glaucoma damage, left eye',
  'H40063 Primary angle closure without glaucoma damage, bilateral',
  'H40069 Primary angle closure without glaucoma damage, unspecified eye',
  'H4010X0 Unspecified open-angle glaucoma, stage unspecified',
  'H4010X1 Unspecified open-angle glaucoma, mild stage',
  'H4010X2 Unspecified open-angle glaucoma, moderate stage',
  'H4010X3 Unspecified open-angle glaucoma, severe stage',
  'H4010X4 Unspecified open-angle glaucoma, indeterminate stage',
  'H401110 Primary open-angle glaucoma, right eye, stage unspecified',
  'H401111 Primary open-angle glaucoma, right eye, mild stage',
  'H401112 Primary open-angle glaucoma, right eye, moderate stage',
  'H401113 Primary open-angle glaucoma, right eye, severe stage',
  'H401114 Primary open-angle glaucoma, right eye, indeterminate stage',
  'H401120 Primary open-angle glaucoma, left eye, stage unspecified',
  'H401121 Primary open-angle glaucoma, left eye, mild stage',
  'H401122 Primary open-angle glaucoma, left eye, moderate stage',
  'H401123 Primary open-angle glaucoma, left eye, severe stage',
  'H401124 Primary open-angle glaucoma, left eye, indeterminate stage',
  'H401130 Primary open-angle glaucoma, bilateral, stage unspecified',
  'H401131 Primary open-angle glaucoma, bilateral, mild stage',
  'H401132 Primary open-angle glaucoma, bilateral, moderate stage',
  'H401133 Primary open-angle glaucoma, bilateral, severe stage',
  'H401134 Primary open-angle glaucoma, bilateral, indeterminate stage',
  'H401190 Primary open-angle glaucoma, unspecified eye, stage unspecified',
  'H401191 Primary open-angle glaucoma, unspecified eye, mild stage',
  'H401192 Primary open-angle glaucoma, unspecified eye, moderate stage',
  'H401193 Primary open-angle glaucoma, unspecified eye, severe stage',
  'H401194 Primary open-angle glaucoma, unspecified eye, indeterminate stage',
  'H401210 Low-tension glaucoma, right eye, stage unspecified',
  'H401211 Low-tension glaucoma, right eye, mild stage',
  'H401212 Low-tension glaucoma, right eye, moderate stage',
  'H401213 Low-tension glaucoma, right eye, severe stage',
  'H401214 Low-tension glaucoma, right eye, indeterminate stage',
  'H401220 Low-tension glaucoma, left eye, stage unspecified',
  'H401221 Low-tension glaucoma, left eye, mild stage',
  'H401222 Low-tension glaucoma, left eye, moderate stage',
  'H401223 Low-tension glaucoma, left eye, severe stage',
  'H401224 Low-tension glaucoma, left eye, indeterminate stage',
  'H401230 Low-tension glaucoma, bilateral, stage unspecified',
  'H401231 Low-tension glaucoma, bilateral, mild stage',
  'H401232 Low-tension glaucoma, bilateral, moderate stage',
  'H401233 Low-tension glaucoma, bilateral, severe stage',
  'H401234 Low-tension glaucoma, bilateral, indeterminate stage',
  'H401290 Low-tension glaucoma, unspecified eye, stage unspecified',
  'H401291 Low-tension glaucoma, unspecified eye, mild stage',
  'H401292 Low-tension glaucoma, unspecified eye, moderate stage',
  'H401293 Low-tension glaucoma, unspecified eye, severe stage',
  'H401294 Low-tension glaucoma, unspecified eye, indeterminate stage',
  'H401310 Pigmentary glaucoma, right eye, stage unspecified',
  'H401311 Pigmentary glaucoma, right eye, mild stage',
  'H401312 Pigmentary glaucoma, right eye, moderate stage',
  'H401313 Pigmentary glaucoma, right eye, severe stage',
  'H401314 Pigmentary glaucoma, right eye, indeterminate stage',
  'H401320 Pigmentary glaucoma, left eye, stage unspecified',
  'H401321 Pigmentary glaucoma, left eye, mild stage',
  'H401322 Pigmentary glaucoma, left eye, moderate stage',
  'H401323 Pigmentary glaucoma, left eye, severe stage',
  'H401324 Pigmentary glaucoma, left eye, indeterminate stage',
  'H401330 Pigmentary glaucoma, bilateral, stage unspecified',
  'H401331 Pigmentary glaucoma, bilateral, mild stage',
  'H401332 Pigmentary glaucoma, bilateral, moderate stage',
  'H401333 Pigmentary glaucoma, bilateral, severe stage',
  'H401334 Pigmentary glaucoma, bilateral, indeterminate stage',
  'H401390 Pigmentary glaucoma, unspecified eye, stage unspecified',
  'H401391 Pigmentary glaucoma, unspecified eye, mild stage',
  'H401392 Pigmentary glaucoma, unspecified eye, moderate stage',
  'H401393 Pigmentary glaucoma, unspecified eye, severe stage',
  'H401394 Pigmentary glaucoma, unspecified eye, indeterminate stage',
  'H401410 Capsular glaucoma with pseudoexfoliation of lens, right eye, stage unspecified',
  'H401411 Capsular glaucoma with pseudoexfoliation of lens, right eye, mild stage',
  'H401412 Capsular glaucoma with pseudoexfoliation of lens, right eye, moderate stage',
  'H401413 Capsular glaucoma with pseudoexfoliation of lens, right eye, severe stage',
  'H401414 Capsular glaucoma with pseudoexfoliation of lens, right eye, indeterminate stage',
  'H401420 Capsular glaucoma with pseudoexfoliation of lens, left eye, stage unspecified',
  'H401421 Capsular glaucoma with pseudoexfoliation of lens, left eye, mild stage',
  'H401422 Capsular glaucoma with pseudoexfoliation of lens, left eye, moderate stage',
  'H401423 Capsular glaucoma with pseudoexfoliation of lens, left eye, severe stage',
  'H401424 Capsular glaucoma with pseudoexfoliation of lens, left eye, indeterminate stage',
  'H401430 Capsular glaucoma with pseudoexfoliation of lens, bilateral, stage unspecified',
  'H401431 Capsular glaucoma with pseudoexfoliation of lens, bilateral, mild stage',
  'H401432 Capsular glaucoma with pseudoexfoliation of lens, bilateral, moderate stage',
  'H401433 Capsular glaucoma with pseudoexfoliation of lens, bilateral, severe stage',
  'H401434 Capsular glaucoma with pseudoexfoliation of lens, bilateral, indeterminate stage',
  'H401490 Capsular glaucoma with pseudoexfoliation of lens, unspecified eye, stage unspecified',
  'H401491 Capsular glaucoma with pseudoexfoliation of lens, unspecified eye, mild stage',
  'H401492 Capsular glaucoma with pseudoexfoliation of lens, unspecified eye, moderate stage',
  'H401493 Capsular glaucoma with pseudoexfoliation of lens, unspecified eye, severe stage',
  'H401494 Capsular glaucoma with pseudoexfoliation of lens, unspecified eye, indeterminate stage',
  'H40151 Residual stage of open-angle glaucoma, right eye',
  'H40152 Residual stage of open-angle glaucoma, left eye',
  'H40153 Residual stage of open-angle glaucoma, bilateral',
  'H40159 Residual stage of open-angle glaucoma, unspecified eye',
  'H4020X0 Unspecified primary angle-closure glaucoma, stage unspecified',
  'H4020X1 Unspecified primary angle-closure glaucoma, mild stage',
  'H4020X2 Unspecified primary angle-closure glaucoma, moderate stage',
  'H4020X3 Unspecified primary angle-closure glaucoma, severe stage',
  'H4020X4 Unspecified primary angle-closure glaucoma, indeterminate stage',
  'H40211 Acute angle-closure glaucoma, right eye',
  'H40212 Acute angle-closure glaucoma, left eye',
  'H40213 Acute angle-closure glaucoma, bilateral',
  'H40219 Acute angle-closure glaucoma, unspecified eye',
  'H402210 Chronic angle-closure glaucoma, right eye, stage unspecified',
  'H402211 Chronic angle-closure glaucoma, right eye, mild stage',
  'H402212 Chronic angle-closure glaucoma, right eye, moderate stage',
  'H402213 Chronic angle-closure glaucoma, right eye, severe stage',
  'H402214 Chronic angle-closure glaucoma, right eye, indeterminate stage',
  'H402220 Chronic angle-closure glaucoma, left eye, stage unspecified',
  'H402221 Chronic angle-closure glaucoma, left eye, mild stage',
  'H402222 Chronic angle-closure glaucoma, left eye, moderate stage',
  'H402223 Chronic angle-closure glaucoma, left eye, severe stage',
  'H402224 Chronic angle-closure glaucoma, left eye, indeterminate stage',
  'H402230 Chronic angle-closure glaucoma, bilateral, stage unspecified',
  'H402231 Chronic angle-closure glaucoma, bilateral, mild stage',
  'H402232 Chronic angle-closure glaucoma, bilateral, moderate stage',
  'H402233 Chronic angle-closure glaucoma, bilateral, severe stage',
  'H402234 Chronic angle-closure glaucoma, bilateral, indeterminate stage',
  'H402290 Chronic angle-closure glaucoma, unspecified eye, stage unspecified',
  'H402291 Chronic angle-closure glaucoma, unspecified eye, mild stage',
  'H402292 Chronic angle-closure glaucoma, unspecified eye, moderate stage',
  'H402293 Chronic angle-closure glaucoma, unspecified eye, severe stage',
  'H402294 Chronic angle-closure glaucoma, unspecified eye, indeterminate stage',
  'H40231 Intermittent angle-closure glaucoma, right eye',
  'H40232 Intermittent angle-closure glaucoma, left eye',
  'H40233 Intermittent angle-closure glaucoma, bilateral',
  'H40239 Intermittent angle-closure glaucoma, unspecified eye',
  'H40241 Residual stage of angle-closure glaucoma, right eye',
  'H40242 Residual stage of angle-closure glaucoma, left eye',
  'H40243 Residual stage of angle-closure glaucoma, bilateral',
  'H40249 Residual stage of angle-closure glaucoma, unspecified eye',
  'H4030X0 Glaucoma secondary to eye trauma, unspecified eye, stage unspecified',
  'H4030X1 Glaucoma secondary to eye trauma, unspecified eye, mild stage',
  'H4030X2 Glaucoma secondary to eye trauma, unspecified eye, moderate stage',
  'H4030X3 Glaucoma secondary to eye trauma, unspecified eye, severe stage',
  'H4030X4 Glaucoma secondary to eye trauma, unspecified eye, indeterminate stage',
  'H4031X0 Glaucoma secondary to eye trauma, right eye, stage unspecified',
  'H4031X1 Glaucoma secondary to eye trauma, right eye, mild stage',
  'H4031X2 Glaucoma secondary to eye trauma, right eye, moderate stage',
  'H4031X3 Glaucoma secondary to eye trauma, right eye, severe stage',
  'H4031X4 Glaucoma secondary to eye trauma, right eye, indeterminate stage',
  'H4032X0 Glaucoma secondary to eye trauma, left eye, stage unspecified',
  'H4032X1 Glaucoma secondary to eye trauma, left eye, mild stage',
  'H4032X2 Glaucoma secondary to eye trauma, left eye, moderate stage',
  'H4032X3 Glaucoma secondary to eye trauma, left eye, severe stage',
  'H4032X4 Glaucoma secondary to eye trauma, left eye, indeterminate stage',
  'H4033X0 Glaucoma secondary to eye trauma, bilateral, stage unspecified',
  'H4033X1 Glaucoma secondary to eye trauma, bilateral, mild stage',
  'H4033X2 Glaucoma secondary to eye trauma, bilateral, moderate stage',
  'H4033X3 Glaucoma secondary to eye trauma, bilateral, severe stage',
  'H4033X4 Glaucoma secondary to eye trauma, bilateral, indeterminate stage',
  'H4040X0 Glaucoma secondary to eye inflammation, unspecified eye, stage unspecified',
  'H4040X1 Glaucoma secondary to eye inflammation, unspecified eye, mild stage',
  'H4040X2 Glaucoma secondary to eye inflammation, unspecified eye, moderate stage',
  'H4040X3 Glaucoma secondary to eye inflammation, unspecified eye, severe stage',
  'H4040X4 Glaucoma secondary to eye inflammation, unspecified eye, indeterminate stage',
  'H4041X0 Glaucoma secondary to eye inflammation, right eye, stage unspecified',
  'H4041X1 Glaucoma secondary to eye inflammation, right eye, mild stage',
  'H4041X2 Glaucoma secondary to eye inflammation, right eye, moderate stage',
  'H4041X3 Glaucoma secondary to eye inflammation, right eye, severe stage',
  'H4041X4 Glaucoma secondary to eye inflammation, right eye, indeterminate stage',
  'H4042X0 Glaucoma secondary to eye inflammation, left eye, stage unspecified',
  'H4042X1 Glaucoma secondary to eye inflammation, left eye, mild stage',
  'H4042X2 Glaucoma secondary to eye inflammation, left eye, moderate stage',
  'H4042X3 Glaucoma secondary to eye inflammation, left eye, severe stage',
  'H4042X4 Glaucoma secondary to eye inflammation, left eye, indeterminate stage',
  'H4043X0 Glaucoma secondary to eye inflammation, bilateral, stage unspecified',
  'H4043X1 Glaucoma secondary to eye inflammation, bilateral, mild stage',
  'H4043X2 Glaucoma secondary to eye inflammation, bilateral, moderate stage',
  'H4043X3 Glaucoma secondary to eye inflammation, bilateral, severe stage',
  'H4043X4 Glaucoma secondary to eye inflammation, bilateral, indeterminate stage',
  'H4050X0 Glaucoma secondary to other eye disorders, unspecified eye, stage unspecified',
  'H4050X1 Glaucoma secondary to other eye disorders, unspecified eye, mild stage',
  'H4050X2 Glaucoma secondary to other eye disorders, unspecified eye, moderate stage',
  'H4050X3 Glaucoma secondary to other eye disorders, unspecified eye, severe stage',
  'H4050X4 Glaucoma secondary to other eye disorders, unspecified eye, indeterminate stage',
  'H4051X0 Glaucoma secondary to other eye disorders, right eye, stage unspecified',
  'H4051X1 Glaucoma secondary to other eye disorders, right eye, mild stage',
  'H4051X2 Glaucoma secondary to other eye disorders, right eye, moderate stage',
  'H4051X3 Glaucoma secondary to other eye disorders, right eye, severe stage',
  'H4051X4 Glaucoma secondary to other eye disorders, right eye, indeterminate stage',
  'H4052X0 Glaucoma secondary to other eye disorders, left eye, stage unspecified',
  'H4052X1 Glaucoma secondary to other eye disorders, left eye, mild stage',
  'H4052X2 Glaucoma secondary to other eye disorders, left eye, moderate stage',
  'H4052X3 Glaucoma secondary to other eye disorders, left eye, severe stage',
  'H4052X4 Glaucoma secondary to other eye disorders, left eye, indeterminate stage',
  'H4053X0 Glaucoma secondary to other eye disorders, bilateral, stage unspecified',
  'H4053X1 Glaucoma secondary to other eye disorders, bilateral, mild stage',
  'H4053X2 Glaucoma secondary to other eye disorders, bilateral, moderate stage',
  'H4053X3 Glaucoma secondary to other eye disorders, bilateral, severe stage',
  'H4053X4 Glaucoma secondary to other eye disorders, bilateral, indeterminate stage',
  'H4060X0 Glaucoma secondary to drugs, unspecified eye, stage unspecified',
  'H4060X1 Glaucoma secondary to drugs, unspecified eye, mild stage',
  'H4060X2 Glaucoma secondary to drugs, unspecified eye, moderate stage',
  'H4060X3 Glaucoma secondary to drugs, unspecified eye, severe stage',
  'H4060X4 Glaucoma secondary to drugs, unspecified eye, indeterminate stage',
  'H4061X0 Glaucoma secondary to drugs, right eye, stage unspecified',
  'H4061X1 Glaucoma secondary to drugs, right eye, mild stage',
  'H4061X2 Glaucoma secondary to drugs, right eye, moderate stage',
  'H4061X3 Glaucoma secondary to drugs, right eye, severe stage',
  'H4061X4 Glaucoma secondary to drugs, right eye, indeterminate stage',
  'H4062X0 Glaucoma secondary to drugs, left eye, stage unspecified',
  'H4062X1 Glaucoma secondary to drugs, left eye, mild stage',
  'H4062X2 Glaucoma secondary to drugs, left eye, moderate stage',
  'H4062X3 Glaucoma secondary to drugs, left eye, severe stage',
  'H4062X4 Glaucoma secondary to drugs, left eye, indeterminate stage',
  'H4063X0 Glaucoma secondary to drugs, bilateral, stage unspecified',
  'H4063X1 Glaucoma secondary to drugs, bilateral, mild stage',
  'H4063X2 Glaucoma secondary to drugs, bilateral, moderate stage',
  'H4063X3 Glaucoma secondary to drugs, bilateral, severe stage',
  'H4063X4 Glaucoma secondary to drugs, bilateral, indeterminate stage',
  'H40811 Glaucoma with increased episcleral venous pressure, right eye',
  'H40812 Glaucoma with increased episcleral venous pressure, left eye',
  'H40813 Glaucoma with increased episcleral venous pressure, bilateral',
  'H40819 Glaucoma with increased episcleral venous pressure, unspecified eye',
  'H40821 Hypersecretion glaucoma, right eye',
  'H40822 Hypersecretion glaucoma, left eye',
  'H40823 Hypersecretion glaucoma, bilateral',
  'H40829 Hypersecretion glaucoma, unspecified eye',
  'H40831 Aqueous misdirection, right eye',
  'H40832 Aqueous misdirection, left eye',
  'H40833 Aqueous misdirection, bilateral',
  'H40839 Aqueous misdirection, unspecified eye',
  'H4089 Other specified glaucoma',
  'H409 Unspecified glaucoma',
  'H42 Glaucoma in diseases classified elsewhere',
  'H4300 Vitreous prolapse, unspecified eye',
  'H4301 Vitreous prolapse, right eye',
  'H4302 Vitreous prolapse, left eye',
  'H4303 Vitreous prolapse, bilateral',
  'H4310 Vitreous hemorrhage, unspecified eye',
  'H4311 Vitreous hemorrhage, right eye',
  'H4312 Vitreous hemorrhage, left eye',
  'H4313 Vitreous hemorrhage, bilateral',
  'H4320 Crystalline deposits in vitreous body, unspecified eye',
  'H4321 Crystalline deposits in vitreous body, right eye',
  'H4322 Crystalline deposits in vitreous body, left eye',
  'H4323 Crystalline deposits in vitreous body, bilateral',
  'H43311 Vitreous membranes and strands, right eye',
  'H43312 Vitreous membranes and strands, left eye',
  'H43313 Vitreous membranes and strands, bilateral',
  'H43319 Vitreous membranes and strands, unspecified eye',
  'H43391 Other vitreous opacities, right eye',
  'H43392 Other vitreous opacities, left eye',
  'H43393 Other vitreous opacities, bilateral',
  'H43399 Other vitreous opacities, unspecified eye',
  'H43811 Vitreous degeneration, right eye',
  'H43812 Vitreous degeneration, left eye',
  'H43813 Vitreous degeneration, bilateral',
  'H43819 Vitreous degeneration, unspecified eye',
  'H43821 Vitreomacular adhesion, right eye',
  'H43822 Vitreomacular adhesion, left eye',
  'H43823 Vitreomacular adhesion, bilateral',
  'H43829 Vitreomacular adhesion, unspecified eye',
  'H4389 Other disorders of vitreous body',
  'H439 Unspecified disorder of vitreous body',
  'H44001 Unspecified purulent endophthalmitis, right eye',
  'H44002 Unspecified purulent endophthalmitis, left eye',
  'H44003 Unspecified purulent endophthalmitis, bilateral',
  'H44009 Unspecified purulent endophthalmitis, unspecified eye',
  'H44011 Panophthalmitis (acute), right eye',
  'H44012 Panophthalmitis (acute), left eye',
  'H44013 Panophthalmitis (acute), bilateral',
  'H44019 Panophthalmitis (acute), unspecified eye',
  'H44021 Vitreous abscess (chronic), right eye',
  'H44022 Vitreous abscess (chronic), left eye',
  'H44023 Vitreous abscess (chronic), bilateral',
  'H44029 Vitreous abscess (chronic), unspecified eye',
  'H44111 Panuveitis, right eye',
  'H44112 Panuveitis, left eye',
  'H44113 Panuveitis, bilateral',
  'H44119 Panuveitis, unspecified eye',
  'H44121 Parasitic endophthalmitis, unspecified, right eye',
  'H44122 Parasitic endophthalmitis, unspecified, left eye',
  'H44123 Parasitic endophthalmitis, unspecified, bilateral',
  'H44129 Parasitic endophthalmitis, unspecified, unspecified eye',
  'H44131 Sympathetic uveitis, right eye',
  'H44132 Sympathetic uveitis, left eye',
  'H44133 Sympathetic uveitis, bilateral',
  'H44139 Sympathetic uveitis, unspecified eye',
  'H4419 Other endophthalmitis',
  'H4420 Degenerative myopia, unspecified eye',
  'H4421 Degenerative myopia, right eye',
  'H4422 Degenerative myopia, left eye',
  'H4423 Degenerative myopia, bilateral',
  'H4430 Unspecified degenerative disorder of globe',
  'H44311 Chalcosis, right eye',
  'H44312 Chalcosis, left eye',
  'H44313 Chalcosis, bilateral',
  'H44319 Chalcosis, unspecified eye',
  'H44321 Siderosis of eye, right eye',
  'H44322 Siderosis of eye, left eye',
  'H44323 Siderosis of eye, bilateral',
  'H44329 Siderosis of eye, unspecified eye',
  'H44391 Other degenerative disorders of globe, right eye',
  'H44392 Other degenerative disorders of globe, left eye',
  'H44393 Other degenerative disorders of globe, bilateral',
  'H44399 Other degenerative disorders of globe, unspecified eye',
  'H4440 Unspecified hypotony of eye',
  'H44411 Flat anterior chamber hypotony of right eye',
  'H44412 Flat anterior chamber hypotony of left eye',
  'H44413 Flat anterior chamber hypotony of eye, bilateral',
  'H44419 Flat anterior chamber hypotony of unspecified eye',
  'H44421 Hypotony of right eye due to ocular fistula',
  'H44422 Hypotony of left eye due to ocular fistula',
  'H44423 Hypotony of eye due to ocular fistula, bilateral',
  'H44429 Hypotony of unspecified eye due to ocular fistula',
  'H44431 Hypotony of eye due to other ocular disorders, right eye',
  'H44432 Hypotony of eye due to other ocular disorders, left eye',
  'H44433 Hypotony of eye due to other ocular disorders, bilateral',
  'H44439 Hypotony of eye due to other ocular disorders, unspecified eye',
  'H44441 Primary hypotony of right eye',
  'H44442 Primary hypotony of left eye',
  'H44443 Primary hypotony of eye, bilateral',
  'H44449 Primary hypotony of unspecified eye',
  'H4450 Unspecified degenerated conditions of globe',
  'H44511 Absolute glaucoma, right eye',
  'H44512 Absolute glaucoma, left eye',
  'H44513 Absolute glaucoma, bilateral',
  'H44519 Absolute glaucoma, unspecified eye',
  'H44521 Atrophy of globe, right eye',
  'H44522 Atrophy of globe, left eye',
  'H44523 Atrophy of globe, bilateral',
  'H44529 Atrophy of globe, unspecified eye',
  'H44531 Leucocoria, right eye',
  'H44532 Leucocoria, left eye',
  'H44533 Leucocoria, bilateral',
  'H44539 Leucocoria, unspecified eye',
  'H44601 Unspecified retained (old) intraocular foreign body, magnetic, right eye',
  'H44602 Unspecified retained (old) intraocular foreign body, magnetic, left eye',
  'H44603 Unspecified retained (old) intraocular foreign body, magnetic, bilateral',
  'H44609 Unspecified retained (old) intraocular foreign body, magnetic, unspecified eye',
  'H44611 Retained (old) magnetic foreign body in anterior chamber, right eye',
  'H44612 Retained (old) magnetic foreign body in anterior chamber, left eye',
  'H44613 Retained (old) magnetic foreign body in anterior chamber, bilateral',
  'H44619 Retained (old) magnetic foreign body in anterior chamber, unspecified eye',
  'H44621 Retained (old) magnetic foreign body in iris or ciliary body, right eye',
  'H44622 Retained (old) magnetic foreign body in iris or ciliary body, left eye',
  'H44623 Retained (old) magnetic foreign body in iris or ciliary body, bilateral',
  'H44629 Retained (old) magnetic foreign body in iris or ciliary body, unspecified eye',
  'H44631 Retained (old) magnetic foreign body in lens, right eye',
  'H44632 Retained (old) magnetic foreign body in lens, left eye',
  'H44633 Retained (old) magnetic foreign body in lens, bilateral',
  'H44639 Retained (old) magnetic foreign body in lens, unspecified eye',
  'H44641 Retained (old) magnetic foreign body in posterior wall of globe, right eye',
  'H44642 Retained (old) magnetic foreign body in posterior wall of globe, left eye',
  'H44643 Retained (old) magnetic foreign body in posterior wall of globe, bilateral',
  'H44649 Retained (old) magnetic foreign body in posterior wall of globe, unspecified eye',
  'H44651 Retained (old) magnetic foreign body in vitreous body, right eye',
  'H44652 Retained (old) magnetic foreign body in vitreous body, left eye',
  'H44653 Retained (old) magnetic foreign body in vitreous body, bilateral',
  'H44659 Retained (old) magnetic foreign body in vitreous body, unspecified eye',
  'H44691 Retained (old) intraocular foreign body, magnetic, in other or multiple sites, right eye',
  'H44692 Retained (old) intraocular foreign body, magnetic, in other or multiple sites, left eye',
  'H44693 Retained (old) intraocular foreign body, magnetic, in other or multiple sites, bilateral',
  'H44699 Retained (old) intraocular foreign body, magnetic, in other or multiple sites, unspecified eye',
  'H44701 Unspecified retained (old) intraocular foreign body, nonmagnetic, right eye',
  'H44702 Unspecified retained (old) intraocular foreign body, nonmagnetic, left eye',
  'H44703 Unspecified retained (old) intraocular foreign body, nonmagnetic, bilateral',
  'H44709 Unspecified retained (old) intraocular foreign body, nonmagnetic, unspecified eye',
  'H44711 Retained (nonmagnetic) (old) foreign body in anterior chamber, right eye',
  'H44712 Retained (nonmagnetic) (old) foreign body in anterior chamber, left eye',
  'H44713 Retained (nonmagnetic) (old) foreign body in anterior chamber, bilateral',
  'H44719 Retained (nonmagnetic) (old) foreign body in anterior chamber, unspecified eye',
  'H44721 Retained (nonmagnetic) (old) foreign body in iris or ciliary body, right eye',
  'H44722 Retained (nonmagnetic) (old) foreign body in iris or ciliary body, left eye',
  'H44723 Retained (nonmagnetic) (old) foreign body in iris or ciliary body, bilateral',
  'H44729 Retained (nonmagnetic) (old) foreign body in iris or ciliary body, unspecified eye',
  'H44731 Retained (nonmagnetic) (old) foreign body in lens, right eye',
  'H44732 Retained (nonmagnetic) (old) foreign body in lens, left eye',
  'H44733 Retained (nonmagnetic) (old) foreign body in lens, bilateral',
  'H44739 Retained (nonmagnetic) (old) foreign body in lens, unspecified eye',
  'H44741 Retained (nonmagnetic) (old) foreign body in posterior wall of globe, right eye',
  'H44742 Retained (nonmagnetic) (old) foreign body in posterior wall of globe, left eye',
  'H44743 Retained (nonmagnetic) (old) foreign body in posterior wall of globe, bilateral',
  'H44749 Retained (nonmagnetic) (old) foreign body in posterior wall of globe, unspecified eye',
  'H44751 Retained (nonmagnetic) (old) foreign body in vitreous body, right eye',
  'H44752 Retained (nonmagnetic) (old) foreign body in vitreous body, left eye',
  'H44753 Retained (nonmagnetic) (old) foreign body in vitreous body, bilateral',
  'H44759 Retained (nonmagnetic) (old) foreign body in vitreous body, unspecified eye',
  'H44791 Retained (old) intraocular foreign body, nonmagnetic, in other or multiple sites, right eye',
  'H44792 Retained (old) intraocular foreign body, nonmagnetic, in other or multiple sites, left eye',
  'H44793 Retained (old) intraocular foreign body, nonmagnetic, in other or multiple sites, bilateral',
  'H44799 Retained (old) intraocular foreign body, nonmagnetic, in other or multiple sites, unspecified eye',
  'H44811 Hemophthalmos, right eye',
  'H44812 Hemophthalmos, left eye',
  'H44813 Hemophthalmos, bilateral',
  'H44819 Hemophthalmos, unspecified eye',
  'H44821 Luxation of globe, right eye',
  'H44822 Luxation of globe, left eye',
  'H44823 Luxation of globe, bilateral',
  'H44829 Luxation of globe, unspecified eye',
  'H4489 Other disorders of globe',
  'H449 Unspecified disorder of globe',
  'H4600 Optic papillitis, unspecified eye',
  'H4601 Optic papillitis, right eye',
  'H4602 Optic papillitis, left eye',
  'H4603 Optic papillitis, bilateral',
  'H4610 Retrobulbar neuritis, unspecified eye',
  'H4611 Retrobulbar neuritis, right eye',
  'H4612 Retrobulbar neuritis, left eye',
  'H4613 Retrobulbar neuritis, bilateral',
  'H462 Nutritional optic neuropathy',
  'H463 Toxic optic neuropathy',
  'H468 Other optic neuritis',
  'H469 Unspecified optic neuritis',
  'H47011 Ischemic optic neuropathy, right eye',
  'H47012 Ischemic optic neuropathy, left eye',
  'H47013 Ischemic optic neuropathy, bilateral',
  'H47019 Ischemic optic neuropathy, unspecified eye',
  'H47021 Hemorrhage in optic nerve sheath, right eye',
  'H47022 Hemorrhage in optic nerve sheath, left eye',
  'H47023 Hemorrhage in optic nerve sheath, bilateral',
  'H47029 Hemorrhage in optic nerve sheath, unspecified eye',
  'H47031 Optic nerve hypoplasia, right eye',
  'H47032 Optic nerve hypoplasia, left eye',
  'H47033 Optic nerve hypoplasia, bilateral',
  'H47039 Optic nerve hypoplasia, unspecified eye',
  'H47091 Other disorders of optic nerve, not elsewhere classified, right eye',
  'H47092 Other disorders of optic nerve, not elsewhere classified, left eye',
  'H47093 Other disorders of optic nerve, not elsewhere classified, bilateral',
  'H47099 Other disorders of optic nerve, not elsewhere classified, unspecified eye',
  'H4710 Unspecified papilledema',
  'H4711 Papilledema associated with increased intracranial pressure',
  'H4712 Papilledema associated with decreased ocular pressure',
  'H4713 Papilledema associated with retinal disorder',
  'H47141 Foster-Kennedy syndrome, right eye',
  'H47142 Foster-Kennedy syndrome, left eye',
  'H47143 Foster-Kennedy syndrome, bilateral',
  'H47149 Foster-Kennedy syndrome, unspecified eye',
  'H4720 Unspecified optic atrophy',
  'H47211 Primary optic atrophy, right eye',
  'H47212 Primary optic atrophy, left eye',
  'H47213 Primary optic atrophy, bilateral',
  'H47219 Primary optic atrophy, unspecified eye',
  'H4722 Hereditary optic atrophy',
  'H47231 Glaucomatous optic atrophy, right eye',
  'H47232 Glaucomatous optic atrophy, left eye',
  'H47233 Glaucomatous optic atrophy, bilateral',
  'H47239 Glaucomatous optic atrophy, unspecified eye',
  'H47291 Other optic atrophy, right eye',
  'H47292 Other optic atrophy, left eye',
  'H47293 Other optic atrophy, bilateral',
  'H47299 Other optic atrophy, unspecified eye',
  'H47311 Coloboma of optic disc, right eye',
  'H47312 Coloboma of optic disc, left eye',
  'H47313 Coloboma of optic disc, bilateral',
  'H47319 Coloboma of optic disc, unspecified eye',
  'H47321 Drusen of optic disc, right eye',
  'H47322 Drusen of optic disc, left eye',
  'H47323 Drusen of optic disc, bilateral',
  'H47329 Drusen of optic disc, unspecified eye',
  'H47331 Pseudopapilledema of optic disc, right eye',
  'H47332 Pseudopapilledema of optic disc, left eye',
  'H47333 Pseudopapilledema of optic disc, bilateral',
  'H47339 Pseudopapilledema of optic disc, unspecified eye',
  'H47391 Other disorders of optic disc, right eye',
  'H47392 Other disorders of optic disc, left eye',
  'H47393 Other disorders of optic disc, bilateral',
  'H47399 Other disorders of optic disc, unspecified eye',
  'H4741 Disorders of optic chiasm in (due to) inflammatory disorders',
  'H4742 Disorders of optic chiasm in (due to) neoplasm',
  'H4743 Disorders of optic chiasm in (due to) vascular disorders',
  'H4749 Disorders of optic chiasm in (due to) other disorders',
  'H47511 Disorders of visual pathways in (due to) inflammatory disorders, right side',
  'H47512 Disorders of visual pathways in (due to) inflammatory disorders, left side',
  'H47519 Disorders of visual pathways in (due to) inflammatory disorders, unspecified side',
  'H47521 Disorders of visual pathways in (due to) neoplasm, right side',
  'H47522 Disorders of visual pathways in (due to) neoplasm, left side',
  'H47529 Disorders of visual pathways in (due to) neoplasm, unspecified side',
  'H47531 Disorders of visual pathways in (due to) vascular disorders, right side',
  'H47532 Disorders of visual pathways in (due to) vascular disorders, left side',
  'H47539 Disorders of visual pathways in (due to) vascular disorders, unspecified side',
  'H47611 Cortical blindness, right side of brain',
  'H47612 Cortical blindness, left side of brain',
  'H47619 Cortical blindness, unspecified side of brain',
  'H47621 Disorders of visual cortex in (due to) inflammatory disorders, right side of brain',
  'H47622 Disorders of visual cortex in (due to) inflammatory disorders, left side of brain',
  'H47629 Disorders of visual cortex in (due to) inflammatory disorders, unspecified side of brain',
  'H47631 Disorders of visual cortex in (due to) neoplasm, right side of brain',
  'H47632 Disorders of visual cortex in (due to) neoplasm, left side of brain',
  'H47639 Disorders of visual cortex in (due to) neoplasm, unspecified side of brain',
  'H47641 Disorders of visual cortex in (due to) vascular disorders, right side of brain',
  'H47642 Disorders of visual cortex in (due to) vascular disorders, left side of brain',
  'H47649 Disorders of visual cortex in (due to) vascular disorders, unspecified side of brain',
  'H479 Unspecified disorder of visual pathways',
  'H4900 Third [oculomotor] nerve palsy, unspecified eye',
  'H4901 Third [oculomotor] nerve palsy, right eye',
  'H4902 Third [oculomotor] nerve palsy, left eye',
  'H4903 Third [oculomotor] nerve palsy, bilateral',
  'H4910 Fourth [trochlear] nerve palsy, unspecified eye',
  'H4911 Fourth [trochlear] nerve palsy, right eye',
  'H4912 Fourth [trochlear] nerve palsy, left eye',
  'H4913 Fourth [trochlear] nerve palsy, bilateral',
  'H4920 Sixth [abducent] nerve palsy, unspecified eye',
  'H4921 Sixth [abducent] nerve palsy, right eye',
  'H4922 Sixth [abducent] nerve palsy, left eye',
  'H4923 Sixth [abducent] nerve palsy, bilateral',
  'H4930 Total (external) ophthalmoplegia, unspecified eye',
  'H4931 Total (external) ophthalmoplegia, right eye',
  'H4932 Total (external) ophthalmoplegia, left eye',
  'H4933 Total (external) ophthalmoplegia, bilateral',
  'H4940 Progressive external ophthalmoplegia, unspecified eye',
  'H4941 Progressive external ophthalmoplegia, right eye',
  'H4942 Progressive external ophthalmoplegia, left eye',
  'H4943 Progressive external ophthalmoplegia, bilateral',
  'H49811 Kearns-Sayre syndrome, right eye',
  'H49812 Kearns-Sayre syndrome, left eye',
  'H49813 Kearns-Sayre syndrome, bilateral',
  'H49819 Kearns-Sayre syndrome, unspecified eye',
  'H49881 Other paralytic strabismus, right eye',
  'H49882 Other paralytic strabismus, left eye',
  'H49883 Other paralytic strabismus, bilateral',
  'H49889 Other paralytic strabismus, unspecified eye',
  'H499 Unspecified paralytic strabismus',
  'H5000 Unspecified esotropia',
  'H50011 Monocular esotropia, right eye',
  'H50012 Monocular esotropia, left eye',
  'H50021 Monocular esotropia with A pattern, right eye',
  'H50022 Monocular esotropia with A pattern, left eye',
  'H50031 Monocular esotropia with V pattern, right eye',
  'H50032 Monocular esotropia with V pattern, left eye',
  'H50041 Monocular esotropia with other noncomitancies, right eye',
  'H50042 Monocular esotropia with other noncomitancies, left eye',
  'H5005 Alternating esotropia',
  'H5006 Alternating esotropia with A pattern',
  'H5007 Alternating esotropia with V pattern',
  'H5008 Alternating esotropia with other noncomitancies',
  'H5010 Unspecified exotropia',
  'H50111 Monocular exotropia, right eye',
  'H50112 Monocular exotropia, left eye',
  'H50121 Monocular exotropia with A pattern, right eye',
  'H50122 Monocular exotropia with A pattern, left eye',
  'H50131 Monocular exotropia with V pattern, right eye',
  'H50132 Monocular exotropia with V pattern, left eye',
  'H50141 Monocular exotropia with other noncomitancies, right eye',
  'H50142 Monocular exotropia with other noncomitancies, left eye',
  'H5015 Alternating exotropia',
  'H5016 Alternating exotropia with A pattern',
  'H5017 Alternating exotropia with V pattern',
  'H5018 Alternating exotropia with other noncomitancies',
  'H5021 Vertical strabismus, right eye',
  'H5022 Vertical strabismus, left eye',
  'H5030 Unspecified intermittent heterotropia',
  'H50311 Intermittent monocular esotropia, right eye',
  'H50312 Intermittent monocular esotropia, left eye',
  'H5032 Intermittent alternating esotropia',
  'H50331 Intermittent monocular exotropia, right eye',
  'H50332 Intermittent monocular exotropia, left eye',
  'H5034 Intermittent alternating exotropia',
  'H5040 Unspecified heterotropia',
  'H50411 Cyclotropia, right eye',
  'H50412 Cyclotropia, left eye',
  'H5042 Monofixation syndrome',
  'H5043 Accommodative component in esotropia',
  'H5050 Unspecified heterophoria',
  'H5051 Esophoria',
  'H5052 Exophoria',
  'H5053 Vertical heterophoria',
  'H5054 Cyclophoria',
  'H5055 Alternating heterophoria',
  'H5060 Mechanical strabismus, unspecified',
  "H50611 Brown's sheath syndrome, right eye",
  "H50612 Brown's sheath syndrome, left eye",
  'H5069 Other mechanical strabismus',
  "H50811 Duane's syndrome, right eye",
  "H50812 Duane's syndrome, left eye",
  'H5089 Other specified strabismus',
  'H509 Unspecified strabismus',
  'H510 Palsy (spasm) of conjugate gaze',
  'H5111 Convergence insufficiency',
  'H5112 Convergence excess',
  'H5120 Internuclear ophthalmoplegia, unspecified eye',
  'H5121 Internuclear ophthalmoplegia, right eye',
  'H5122 Internuclear ophthalmoplegia, left eye',
  'H5123 Internuclear ophthalmoplegia, bilateral',
  'H518 Other specified disorders of binocular movement',
  'H519 Unspecified disorder of binocular movement',
  'H5200 Hypermetropia, unspecified eye',
  'H5201 Hypermetropia, right eye',
  'H5202 Hypermetropia, left eye',
  'H5203 Hypermetropia, bilateral',
  'H5210 Myopia, unspecified eye',
  'H5211 Myopia, right eye',
  'H5212 Myopia, left eye',
  'H5213 Myopia, bilateral',
  'H52201 Unspecified astigmatism, right eye',
  'H52202 Unspecified astigmatism, left eye',
  'H52203 Unspecified astigmatism, bilateral',
  'H52209 Unspecified astigmatism, unspecified eye',
  'H52211 Irregular astigmatism, right eye',
  'H52212 Irregular astigmatism, left eye',
  'H52213 Irregular astigmatism, bilateral',
  'H52219 Irregular astigmatism, unspecified eye',
  'H52221 Regular astigmatism, right eye',
  'H52222 Regular astigmatism, left eye',
  'H52223 Regular astigmatism, bilateral',
  'H52229 Regular astigmatism, unspecified eye',
  'H5231 Anisometropia',
  'H5232 Aniseikonia',
  'H524 Presbyopia',
  'H52511 Internal ophthalmoplegia (complete) (total), right eye',
  'H52512 Internal ophthalmoplegia (complete) (total), left eye',
  'H52513 Internal ophthalmoplegia (complete) (total), bilateral',
  'H52519 Internal ophthalmoplegia (complete) (total), unspecified eye',
  'H52521 Paresis of accommodation, right eye',
  'H52522 Paresis of accommodation, left eye',
  'H52523 Paresis of accommodation, bilateral',
  'H52529 Paresis of accommodation, unspecified eye',
  'H52531 Spasm of accommodation, right eye',
  'H52532 Spasm of accommodation, left eye',
  'H52533 Spasm of accommodation, bilateral',
  'H52539 Spasm of accommodation, unspecified eye',
  'H526 Other disorders of refraction',
  'H527 Unspecified disorder of refraction',
  'H53001 Unspecified amblyopia, right eye',
  'H53002 Unspecified amblyopia, left eye',
  'H53003 Unspecified amblyopia, bilateral',
  'H53009 Unspecified amblyopia, unspecified eye',
  'H53011 Deprivation amblyopia, right eye',
  'H53012 Deprivation amblyopia, left eye',
  'H53013 Deprivation amblyopia, bilateral',
  'H53019 Deprivation amblyopia, unspecified eye',
  'H53021 Refractive amblyopia, right eye',
  'H53022 Refractive amblyopia, left eye',
  'H53023 Refractive amblyopia, bilateral',
  'H53029 Refractive amblyopia, unspecified eye',
  'H53031 Strabismic amblyopia, right eye',
  'H53032 Strabismic amblyopia, left eye',
  'H53033 Strabismic amblyopia, bilateral',
  'H53039 Strabismic amblyopia, unspecified eye',
  'H53041 Amblyopia suspect, right eye',
  'H53042 Amblyopia suspect, left eye',
  'H53043 Amblyopia suspect, bilateral',
  'H53049 Amblyopia suspect, unspecified eye',
  'H5310 Unspecified subjective visual disturbances',
  'H5311 Day blindness',
  'H53121 Transient visual loss, right eye',
  'H53122 Transient visual loss, left eye',
  'H53123 Transient visual loss, bilateral',
  'H53129 Transient visual loss, unspecified eye',
  'H53131 Sudden visual loss, right eye',
  'H53132 Sudden visual loss, left eye',
  'H53133 Sudden visual loss, bilateral',
  'H53139 Sudden visual loss, unspecified eye',
  'H53141 Visual discomfort, right eye',
  'H53142 Visual discomfort, left eye',
  'H53143 Visual discomfort, bilateral',
  'H53149 Visual discomfort, unspecified',
  'H5315 Visual distortions of shape and size',
  'H5316 Psychophysical visual disturbances',
  'H5319 Other subjective visual disturbances',
  'H532 Diplopia',
  'H5330 Unspecified disorder of binocular vision',
  'H5331 Abnormal retinal correspondence',
  'H5332 Fusion with defective stereopsis',
  'H5333 Simultaneous visual perception without fusion',
  'H5334 Suppression of binocular vision',
  'H5340 Unspecified visual field defects',
  'H53411 Scotoma involving central area, right eye',
  'H53412 Scotoma involving central area, left eye',
  'H53413 Scotoma involving central area, bilateral',
  'H53419 Scotoma involving central area, unspecified eye',
  'H53421 Scotoma of blind spot area, right eye',
  'H53422 Scotoma of blind spot area, left eye',
  'H53423 Scotoma of blind spot area, bilateral',
  'H53429 Scotoma of blind spot area, unspecified eye',
  'H53431 Sector or arcuate defects, right eye',
  'H53432 Sector or arcuate defects, left eye',
  'H53433 Sector or arcuate defects, bilateral',
  'H53439 Sector or arcuate defects, unspecified eye',
  'H53451 Other localized visual field defect, right eye',
  'H53452 Other localized visual field defect, left eye',
  'H53453 Other localized visual field defect, bilateral',
  'H53459 Other localized visual field defect, unspecified eye',
  'H53461 Homonymous bilateral field defects, right side',
  'H53462 Homonymous bilateral field defects, left side',
  'H53469 Homonymous bilateral field defects, unspecified side',
  'H5347 Heteronymous bilateral field defects',
  'H53481 Generalized contraction of visual field, right eye',
  'H53482 Generalized contraction of visual field, left eye',
  'H53483 Generalized contraction of visual field, bilateral',
  'H53489 Generalized contraction of visual field, unspecified eye',
  'H5350 Unspecified color vision deficiencies',
  'H5351 Achromatopsia',
  'H5352 Acquired color vision deficiency',
  'H5353 Deuteranomaly',
  'H5354 Protanomaly',
  'H5355 Tritanomaly',
  'H5359 Other color vision deficiencies',
  'H5360 Unspecified night blindness',
  'H5361 Abnormal dark adaptation curve',
  'H5362 Acquired night blindness',
  'H5363 Congenital night blindness',
  'H5369 Other night blindness',
  'H5371 Glare sensitivity',
  'H5372 Impaired contrast sensitivity',
  'H538 Other visual disturbances',
  'H539 Unspecified visual disturbance',
  'H540 Blindness, both eyes',
  'H5410 Blindness, one eye, low vision other eye, unspecified eyes',
  'H5411 Blindness, right eye, low vision left eye',
  'H5412 Blindness, left eye, low vision right eye',
  'H542 Low vision, both eyes',
  'H543 Unqualified visual loss, both eyes',
  'H5440 Blindness, one eye, unspecified eye',
  'H5441 Blindness, right eye, normal vision left eye',
  'H5442 Blindness, left eye, normal vision right eye',
  'H5450 Low vision, one eye, unspecified eye',
  'H5451 Low vision, right eye, normal vision left eye',
  'H5452 Low vision, left eye, normal vision right eye',
  'H5460 Unqualified visual loss, one eye, unspecified',
  'H5461 Unqualified visual loss, right eye, normal vision left eye',
  'H5462 Unqualified visual loss, left eye, normal vision right eye',
  'H547 Unspecified visual loss',
  'H548 Legal blindness, as defined in USA',
  'H5500 Unspecified nystagmus',
  'H5501 Congenital nystagmus',
  'H5502 Latent nystagmus',
  'H5503 Visual deprivation nystagmus',
  'H5504 Dissociated nystagmus',
  'H5509 Other forms of nystagmus',
  'H5581 Saccadic eye movements',
  'H5589 Other irregular eye movements',
  'H5700 Unspecified anomaly of pupillary function',
  'H5701 Argyll Robertson pupil, atypical',
  'H5702 Anisocoria',
  'H5703 Miosis',
  'H5704 Mydriasis',
  'H57051 Tonic pupil, right eye',
  'H57052 Tonic pupil, left eye',
  'H57053 Tonic pupil, bilateral',
  'H57059 Tonic pupil, unspecified eye',
  'H5709 Other anomalies of pupillary function',
  'H5710 Ocular pain, unspecified eye',
  'H5711 Ocular pain, right eye',
  'H5712 Ocular pain, left eye',
  'H5713 Ocular pain, bilateral',
  'H578 Other specified disorders of eye and adnexa',
  'H579 Unspecified disorder of eye and adnexa',
  'H59011 Keratopathy (bullous aphakic) following cataract surgery, right eye',
  'H59012 Keratopathy (bullous aphakic) following cataract surgery, left eye',
  'H59013 Keratopathy (bullous aphakic) following cataract surgery, bilateral',
  'H59019 Keratopathy (bullous aphakic) following cataract surgery, unspecified eye',
  'H59021 Cataract (lens) fragments in eye following cataract surgery, right eye',
  'H59022 Cataract (lens) fragments in eye following cataract surgery, left eye',
  'H59023 Cataract (lens) fragments in eye following cataract surgery, bilateral',
  'H59029 Cataract (lens) fragments in eye following cataract surgery, unspecified eye',
  'H59031 Cystoid macular edema following cataract surgery, right eye',
  'H59032 Cystoid macular edema following cataract surgery, left eye',
  'H59033 Cystoid macular edema following cataract surgery, bilateral',
  'H59039 Cystoid macular edema following cataract surgery, unspecified eye',
  'H59091 Other disorders of the right eye following cataract surgery',
  'H59092 Other disorders of the left eye following cataract surgery',
  'H59093 Other disorders of the eye following cataract surgery, bilateral',
  'H59099 Other disorders of unspecified eye following cataract surgery',
  'H59111 Intraoperative hemorrhage and hematoma of right eye and adnexa complicating an ophthalmic',
  'procedure',
  'H59112 Intraoperative hemorrhage and hematoma of left eye and adnexa complicating an ophthalmic',
  'procedure',
  'H59113 Intraoperative hemorrhage and hematoma of eye and adnexa complicating an ophthalmic',
  'procedure, bilateral',
  'H59119 Intraoperative hemorrhage and hematoma of unspecified eye and adnexa complicating an',
  'ophthalmic procedure',
  'H59121 Intraoperative hemorrhage and hematoma of right eye and adnexa complicating other',
  'procedure',
  'H59122 Intraoperative hemorrhage and hematoma of left eye and adnexa complicating other',
  'procedure',
  'H59123 Intraoperative hemorrhage and hematoma of eye and adnexa complicating other procedure,',
  'bilateral',
  'H59129 Intraoperative hemorrhage and hematoma of unspecified eye and adnexa complicating other',
  'procedure',
  'H59211 Accidental puncture and laceration of right eye and adnexa during an ophthalmic procedure',
  'H59212 Accidental puncture and laceration of left eye and adnexa during an ophthalmic procedure',
  'H59213 Accidental puncture and laceration of eye and adnexa during an ophthalmic procedure,',
  'bilateral',
  'H59219 Accidental puncture and laceration of unspecified eye and adnexa during an ophthalmic',
  'procedure',
  'H59221 Accidental puncture and laceration of right eye and adnexa during other procedure',
  'H59222 Accidental puncture and laceration of left eye and adnexa during other procedure',
  'H59223 Accidental puncture and laceration of eye and adnexa during other procedure, bilateral',
  'H59229 Accidental puncture and laceration of unspecified eye and adnexa during other procedure',
  'H59311 Postprocedural hemorrhage of right eye and adnexa following an ophthalmic procedure',
  'H59312 Postprocedural hemorrhage of left eye and adnexa following an ophthalmic procedure',
  'H59313 Postprocedural hemorrhage of eye and adnexa following an ophthalmic procedure, bilateral',
  'H59319 Postprocedural hemorrhage of unspecified eye and adnexa following an ophthalmic procedure',
  'H59321 Postprocedural hemorrhage of right eye and adnexa following other procedure',
  'H59322 Postprocedural hemorrhage of left eye and adnexa following other procedure',
  'H59323 Postprocedural hemorrhage of eye and adnexa following other procedure, bilateral',
  'H59329 Postprocedural hemorrhage of unspecified eye and adnexa following other procedure',
  'H59331 Postprocedural hematoma of right eye and adnexa following an ophthalmic procedure',
  'H59332 Postprocedural hematoma of left eye and adnexa following an ophthalmic procedure',
  'H59333 Postprocedural hematoma of eye and adnexa following an ophthalmic procedure, bilateral',
  'H59339 Postprocedural hematoma of unspecified eye and adnexa following an ophthalmic procedure',
  'H59341 Postprocedural hematoma of right eye and adnexa following other procedure',
  'H59342 Postprocedural hematoma of left eye and adnexa following other procedure',
  'H59343 Postprocedural hematoma of eye and adnexa following other procedure, bilateral',
  'H59349 Postprocedural hematoma of unspecified eye and adnexa following other procedure',
  'H59351 Postprocedural seroma of right eye and adnexa following an ophthalmic procedure',
  'H59352 Postprocedural seroma of left eye and adnexa following an ophthalmic procedure',
  'H59353 Postprocedural seroma of eye and adnexa following an ophthalmic procedure, bilateral',
  'H59359 Postprocedural seroma of unspecified eye and adnexa following an ophthalmic procedure',
  'H59361 Postprocedural seroma of right eye and adnexa following other procedure',
  'H59362 Postprocedural seroma of left eye and adnexa following other procedure',
  'H59363 Postprocedural seroma of eye and adnexa following other procedure, bilateral',
  'H59369 Postprocedural seroma of unspecified eye and adnexa following other procedure',
  'H5940 Inflammation (infection) of postprocedural bleb, unspecified',
  'H5941 Inflammation (infection) of postprocedural bleb, stage 1',
  'H5942 Inflammation (infection) of postprocedural bleb, stage 2',
  'H5943 Inflammation (infection) of postprocedural bleb, stage 3',
  'H59811 Chorioretinal scars after surgery for detachment, right eye',
  'H59812 Chorioretinal scars after surgery for detachment, left eye',
  'H59813 Chorioretinal scars after surgery for detachment, bilateral',
  'H59819 Chorioretinal scars after surgery for detachment, unspecified eye',
  'H5988 Other intraoperative complications of eye and adnexa, not elsewhere classified',
  'H5989 Other postprocedural complications and disorders of eye and adnexa, not elsewhere classified',
  'H6000 Abscess of external ear, unspecified ear',
  'H6001 Abscess of right external ear',
  'H6002 Abscess of left external ear',
  'H6003 Abscess of external ear, bilateral',
  'H6010 Cellulitis of external ear, unspecified ear',
  'H6011 Cellulitis of right external ear',
  'H6012 Cellulitis of left external ear',
  'H6013 Cellulitis of external ear, bilateral',
  'H6020 Malignant otitis externa, unspecified ear',
  'H6021 Malignant otitis externa, right ear',
  'H6022 Malignant otitis externa, left ear',
  'H6023 Malignant otitis externa, bilateral',
  'H60311 Diffuse otitis externa, right ear',
  'H60312 Diffuse otitis externa, left ear',
  'H60313 Diffuse otitis externa, bilateral',
  'H60319 Diffuse otitis externa, unspecified ear',
  'H60321 Hemorrhagic otitis externa, right ear',
  'H60322 Hemorrhagic otitis externa, left ear',
  'H60323 Hemorrhagic otitis externa, bilateral',
  'H60329 Hemorrhagic otitis externa, unspecified ear',
  "H60331 Swimmer's ear, right ear",
  "H60332 Swimmer's ear, left ear",
  "H60333 Swimmer's ear, bilateral",
  "H60339 Swimmer's ear, unspecified ear",
  'H60391 Other infective otitis externa, right ear',
  'H60392 Other infective otitis externa, left ear',
  'H60393 Other infective otitis externa, bilateral',
  'H60399 Other infective otitis externa, unspecified ear',
  'H6040 Cholesteatoma of external ear, unspecified ear',
  'H6041 Cholesteatoma of right external ear',
  'H6042 Cholesteatoma of left external ear',
  'H6043 Cholesteatoma of external ear, bilateral',
  'H60501 Unspecified acute noninfective otitis externa, right ear',
  'H60502 Unspecified acute noninfective otitis externa, left ear',
  'H60503 Unspecified acute noninfective otitis externa, bilateral',
  'H60509 Unspecified acute noninfective otitis externa, unspecified ear',
  'H60511 Acute actinic otitis externa, right ear',
  'H60512 Acute actinic otitis externa, left ear',
  'H60513 Acute actinic otitis externa, bilateral',
  'H60519 Acute actinic otitis externa, unspecified ear',
  'H60521 Acute chemical otitis externa, right ear',
  'H60522 Acute chemical otitis externa, left ear',
  'H60523 Acute chemical otitis externa, bilateral',
  'H60529 Acute chemical otitis externa, unspecified ear',
  'H60531 Acute contact otitis externa, right ear',
  'H60532 Acute contact otitis externa, left ear',
  'H60533 Acute contact otitis externa, bilateral',
  'H60539 Acute contact otitis externa, unspecified ear',
  'H60541 Acute eczematoid otitis externa, right ear',
  'H60542 Acute eczematoid otitis externa, left ear',
  'H60543 Acute eczematoid otitis externa, bilateral',
  'H60549 Acute eczematoid otitis externa, unspecified ear',
  'H60551 Acute reactive otitis externa, right ear',
  'H60552 Acute reactive otitis externa, left ear',
  'H60553 Acute reactive otitis externa, bilateral',
  'H60559 Acute reactive otitis externa, unspecified ear',
  'H60591 Other noninfective acute otitis externa, right ear',
  'H60592 Other noninfective acute otitis externa, left ear',
  'H60593 Other noninfective acute otitis externa, bilateral',
  'H60599 Other noninfective acute otitis externa, unspecified ear',
  'H6060 Unspecified chronic otitis externa, unspecified ear',
  'H6061 Unspecified chronic otitis externa, right ear',
  'H6062 Unspecified chronic otitis externa, left ear',
  'H6063 Unspecified chronic otitis externa, bilateral',
  'H608X1 Other otitis externa, right ear',
  'H608X2 Other otitis externa, left ear',
  'H608X3 Other otitis externa, bilateral',
  'H608X9 Other otitis externa, unspecified ear',
  'H6090 Unspecified otitis externa, unspecified ear',
  'H6091 Unspecified otitis externa, right ear',
  'H6092 Unspecified otitis externa, left ear',
  'H6093 Unspecified otitis externa, bilateral',
  'H61001 Unspecified perichondritis of right external ear',
  'H61002 Unspecified perichondritis of left external ear',
  'H61003 Unspecified perichondritis of external ear, bilateral',
  'H61009 Unspecified perichondritis of external ear, unspecified ear',
  'H61011 Acute perichondritis of right external ear',
  'H61012 Acute perichondritis of left external ear',
  'H61013 Acute perichondritis of external ear, bilateral',
  'H61019 Acute perichondritis of external ear, unspecified ear',
  'H61021 Chronic perichondritis of right external ear',
  'H61022 Chronic perichondritis of left external ear',
  'H61023 Chronic perichondritis of external ear, bilateral',
  'H61029 Chronic perichondritis of external ear, unspecified ear',
  'H61031 Chondritis of right external ear',
  'H61032 Chondritis of left external ear',
  'H61033 Chondritis of external ear, bilateral',
  'H61039 Chondritis of external ear, unspecified ear',
  'H61101 Unspecified noninfective disorders of pinna, right ear',
  'H61102 Unspecified noninfective disorders of pinna, left ear',
  'H61103 Unspecified noninfective disorders of pinna, bilateral',
  'H61109 Unspecified noninfective disorders of pinna, unspecified ear',
  'H61111 Acquired deformity of pinna, right ear',
  'H61112 Acquired deformity of pinna, left ear',
  'H61113 Acquired deformity of pinna, bilateral',
  'H61119 Acquired deformity of pinna, unspecified ear',
  'H61121 Hematoma of pinna, right ear',
  'H61122 Hematoma of pinna, left ear',
  'H61123 Hematoma of pinna, bilateral',
  'H61129 Hematoma of pinna, unspecified ear',
  'H61191 Noninfective disorders of pinna, right ear',
  'H61192 Noninfective disorders of pinna, left ear',
  'H61193 Noninfective disorders of pinna, bilateral',
  'H61199 Noninfective disorders of pinna, unspecified ear',
  'H6120 Impacted cerumen, unspecified ear',
  'H6121 Impacted cerumen, right ear',
  'H6122 Impacted cerumen, left ear',
  'H6123 Impacted cerumen, bilateral',
  'H61301 Acquired stenosis of right external ear canal, unspecified',
  'H61302 Acquired stenosis of left external ear canal, unspecified',
  'H61303 Acquired stenosis of external ear canal, unspecified, bilateral',
  'H61309 Acquired stenosis of external ear canal, unspecified, unspecified ear',
  'H61311 Acquired stenosis of right external ear canal secondary to trauma',
  'H61312 Acquired stenosis of left external ear canal secondary to trauma',
  'H61313 Acquired stenosis of external ear canal secondary to trauma, bilateral',
  'H61319 Acquired stenosis of external ear canal secondary to trauma, unspecified ear',
  'H61321 Acquired stenosis of right external ear canal secondary to inflammation and infection',
  'H61322 Acquired stenosis of left external ear canal secondary to inflammation and infection',
  'H61323 Acquired stenosis of external ear canal secondary to inflammation and infection, bilateral',
  'H61329 Acquired stenosis of external ear canal secondary to inflammation and infection, unspecified',
  'ear',
  'H61391 Other acquired stenosis of right external ear canal',
  'H61392 Other acquired stenosis of left external ear canal',
  'H61393 Other acquired stenosis of external ear canal, bilateral',
  'H61399 Other acquired stenosis of external ear canal, unspecified ear',
  'H61811 Exostosis of right external canal',
  'H61812 Exostosis of left external canal',
  'H61813 Exostosis of external canal, bilateral',
  'H61819 Exostosis of external canal, unspecified ear',
  'H61891 Other specified disorders of right external ear',
  'H61892 Other specified disorders of left external ear',
  'H61893 Other specified disorders of external ear, bilateral',
  'H61899 Other specified disorders of external ear, unspecified ear',
  'H6190 Disorder of external ear, unspecified, unspecified ear',
  'H6191 Disorder of right external ear, unspecified',
  'H6192 Disorder of left external ear, unspecified',
  'H6193 Disorder of external ear, unspecified, bilateral',
  'H6240 Otitis externa in other diseases classified elsewhere, unspecified ear',
  'H6241 Otitis externa in other diseases classified elsewhere, right ear',
  'H6242 Otitis externa in other diseases classified elsewhere, left ear',
  'H6243 Otitis externa in other diseases classified elsewhere, bilateral',
  'H628X1 Other disorders of right external ear in diseases classified elsewhere',
  'H628X2 Other disorders of left external ear in diseases classified elsewhere',
  'H628X3 Other disorders of external ear in diseases classified elsewhere, bilateral',
  'H628X9 Other disorders of external ear in diseases classified elsewhere, unspecified ear',
  'H6500 Acute serous otitis media, unspecified ear',
  'H6501 Acute serous otitis media, right ear',
  'H6502 Acute serous otitis media, left ear',
  'H6503 Acute serous otitis media, bilateral',
  'H6504 Acute serous otitis media, recurrent, right ear',
  'H6505 Acute serous otitis media, recurrent, left ear',
  'H6506 Acute serous otitis media, recurrent, bilateral',
  'H6507 Acute serous otitis media, recurrent, unspecified ear',
  'H65111 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), right ear',
  'H65112 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), left ear',
  'H65113 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), bilateral',
  'H65114 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), recurrent, right ear',
  'H65115 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), recurrent, left ear',
  'H65116 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), recurrent, bilateral',
  'H65117 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), recurrent, unspecified',
  'ear',
  'H65119 Acute and subacute allergic otitis media (mucoid) (sanguinous) (serous), unspecified ear',
  'H65191 Other acute nonsuppurative otitis media, right ear',
  'H65192 Other acute nonsuppurative otitis media, left ear',
  'H65193 Other acute nonsuppurative otitis media, bilateral',
  'H65194 Other acute nonsuppurative otitis media, recurrent, right ear',
  'H65195 Other acute nonsuppurative otitis media, recurrent, left ear',
  'H65196 Other acute nonsuppurative otitis media, recurrent, bilateral',
  'H65197 Other acute nonsuppurative otitis media recurrent, unspecified ear',
  'H65199 Other acute nonsuppurative otitis media, unspecified ear',
  'H6520 Chronic serous otitis media, unspecified ear',
  'H6521 Chronic serous otitis media, right ear',
  'H6522 Chronic serous otitis media, left ear',
  'H6523 Chronic serous otitis media, bilateral',
  'H6530 Chronic mucoid otitis media, unspecified ear',
  'H6531 Chronic mucoid otitis media, right ear',
  'H6532 Chronic mucoid otitis media, left ear',
  'H6533 Chronic mucoid otitis media, bilateral',
  'H65411 Chronic allergic otitis media, right ear',
  'H65412 Chronic allergic otitis media, left ear',
  'H65413 Chronic allergic otitis media, bilateral',
  'H65419 Chronic allergic otitis media, unspecified ear',
  'H65491 Other chronic nonsuppurative otitis media, right ear',
  'H65492 Other chronic nonsuppurative otitis media, left ear',
  'H65493 Other chronic nonsuppurative otitis media, bilateral',
  'H65499 Other chronic nonsuppurative otitis media, unspecified ear',
  'H6590 Unspecified nonsuppurative otitis media, unspecified ear',
  'H6591 Unspecified nonsuppurative otitis media, right ear',
  'H6592 Unspecified nonsuppurative otitis media, left ear',
  'H6593 Unspecified nonsuppurative otitis media, bilateral',
  'H66001 Acute suppurative otitis media without spontaneous rupture of ear drum, right ear',
  'H66002 Acute suppurative otitis media without spontaneous rupture of ear drum, left ear',
  'H66003 Acute suppurative otitis media without spontaneous rupture of ear drum, bilateral',
  'H66004 Acute suppurative otitis media without spontaneous rupture of ear drum, recurrent, right ear',
  'H66005 Acute suppurative otitis media without spontaneous rupture of ear drum, recurrent, left ear',
  'H66006 Acute suppurative otitis media without spontaneous rupture of ear drum, recurrent, bilateral',
  'H66007 Acute suppurative otitis media without spontaneous rupture of ear drum, recurrent,',
  'unspecified ear',
  'H66009 Acute suppurative otitis media without spontaneous rupture of ear drum, unspecified ear',
  'H66011 Acute suppurative otitis media with spontaneous rupture of ear drum, right ear',
  'H66012 Acute suppurative otitis media with spontaneous rupture of ear drum, left ear',
  'H66013 Acute suppurative otitis media with spontaneous rupture of ear drum, bilateral',
  'H66014 Acute suppurative otitis media with spontaneous rupture of ear drum, recurrent, right ear',
  'H66015 Acute suppurative otitis media with spontaneous rupture of ear drum, recurrent, left ear',
  'H66016 Acute suppurative otitis media with spontaneous rupture of ear drum, recurrent, bilateral',
  'H66017 Acute suppurative otitis media with spontaneous rupture of ear drum, recurrent, unspecified',
  'ear',
  'H66019 Acute suppurative otitis media with spontaneous rupture of ear drum, unspecified ear',
  'H6610 Chronic tubotympanic suppurative otitis media, unspecified',
  'H6611 Chronic tubotympanic suppurative otitis media, right ear',
  'H6612 Chronic tubotympanic suppurative otitis media, left ear',
  'H6613 Chronic tubotympanic suppurative otitis media, bilateral',
  'H6620 Chronic atticoantral suppurative otitis media, unspecified ear',
  'H6621 Chronic atticoantral suppurative otitis media, right ear',
  'H6622 Chronic atticoantral suppurative otitis media, left ear',
  'H6623 Chronic atticoantral suppurative otitis media, bilateral',
  'H663X1 Other chronic suppurative otitis media, right ear',
  'H663X2 Other chronic suppurative otitis media, left ear',
  'H663X3 Other chronic suppurative otitis media, bilateral',
  'H663X9 Other chronic suppurative otitis media, unspecified ear',
  'H6640 Suppurative otitis media, unspecified, unspecified ear',
  'H6641 Suppurative otitis media, unspecified, right ear',
  'H6642 Suppurative otitis media, unspecified, left ear',
  'H6643 Suppurative otitis media, unspecified, bilateral',
  'H6690 Otitis media, unspecified, unspecified ear',
  'H6691 Otitis media, unspecified, right ear',
  'H6692 Otitis media, unspecified, left ear',
  'H6693 Otitis media, unspecified, bilateral',
  'H671 Otitis media in diseases classified elsewhere, right ear',
  'H672 Otitis media in diseases classified elsewhere, left ear',
  'H673 Otitis media in diseases classified elsewhere, bilateral',
  'H679 Otitis media in diseases classified elsewhere, unspecified ear',
  'H68001 Unspecified Eustachian salpingitis, right ear',
  'H68002 Unspecified Eustachian salpingitis, left ear',
  'H68003 Unspecified Eustachian salpingitis, bilateral',
  'H68009 Unspecified Eustachian salpingitis, unspecified ear',
  'H68011 Acute Eustachian salpingitis, right ear',
  'H68012 Acute Eustachian salpingitis, left ear',
  'H68013 Acute Eustachian salpingitis, bilateral',
  'H68019 Acute Eustachian salpingitis, unspecified ear',
  'H68021 Chronic Eustachian salpingitis, right ear',
  'H68022 Chronic Eustachian salpingitis, left ear',
  'H68023 Chronic Eustachian salpingitis, bilateral',
  'H68029 Chronic Eustachian salpingitis, unspecified ear',
  'H68101 Unspecified obstruction of Eustachian tube, right ear',
  'H68102 Unspecified obstruction of Eustachian tube, left ear',
  'H68103 Unspecified obstruction of Eustachian tube, bilateral',
  'H68109 Unspecified obstruction of Eustachian tube, unspecified ear',
  'H68111 Osseous obstruction of Eustachian tube, right ear',
  'H68112 Osseous obstruction of Eustachian tube, left ear',
  'H68113 Osseous obstruction of Eustachian tube, bilateral',
  'H68119 Osseous obstruction of Eustachian tube, unspecified ear',
  'H68121 Intrinsic cartilagenous obstruction of Eustachian tube, right ear',
  'H68122 Intrinsic cartilagenous obstruction of Eustachian tube, left ear',
  'H68123 Intrinsic cartilagenous obstruction of Eustachian tube, bilateral',
  'H68129 Intrinsic cartilagenous obstruction of Eustachian tube, unspecified ear',
  'H68131 Extrinsic cartilagenous obstruction of Eustachian tube, right ear',
  'H68132 Extrinsic cartilagenous obstruction of Eustachian tube, left ear',
  'H68133 Extrinsic cartilagenous obstruction of Eustachian tube, bilateral',
  'H68139 Extrinsic cartilagenous obstruction of Eustachian tube, unspecified ear',
  'H6900 Patulous Eustachian tube, unspecified ear',
  'H6901 Patulous Eustachian tube, right ear',
  'H6902 Patulous Eustachian tube, left ear',
  'H6903 Patulous Eustachian tube, bilateral',
  'H6980 Other specified disorders of Eustachian tube, unspecified ear',
  'H6981 Other specified disorders of Eustachian tube, right ear',
  'H6982 Other specified disorders of Eustachian tube, left ear',
  'H6983 Other specified disorders of Eustachian tube, bilateral',
  'H6990 Unspecified Eustachian tube disorder, unspecified ear',
  'H6991 Unspecified Eustachian tube disorder, right ear',
  'H6992 Unspecified Eustachian tube disorder, left ear',
  'H6993 Unspecified Eustachian tube disorder, bilateral',
  'H70001 Acute mastoiditis without complications, right ear',
  'H70002 Acute mastoiditis without complications, left ear',
  'H70003 Acute mastoiditis without complications, bilateral',
  'H70009 Acute mastoiditis without complications, unspecified ear',
  'H70011 Subperiosteal abscess of mastoid, right ear',
  'H70012 Subperiosteal abscess of mastoid, left ear',
  'H70013 Subperiosteal abscess of mastoid, bilateral',
  'H70019 Subperiosteal abscess of mastoid, unspecified ear',
  'H70091 Acute mastoiditis with other complications, right ear',
  'H70092 Acute mastoiditis with other complications, left ear',
  'H70093 Acute mastoiditis with other complications, bilateral',
  'H70099 Acute mastoiditis with other complications, unspecified ear',
  'H7010 Chronic mastoiditis, unspecified ear',
  'H7011 Chronic mastoiditis, right ear',
  'H7012 Chronic mastoiditis, left ear',
  'H7013 Chronic mastoiditis, bilateral',
  'H70201 Unspecified petrositis, right ear',
  'H70202 Unspecified petrositis, left ear',
  'H70203 Unspecified petrositis, bilateral',
  'H70209 Unspecified petrositis, unspecified ear',
  'H70211 Acute petrositis, right ear',
  'H70212 Acute petrositis, left ear',
  'H70213 Acute petrositis, bilateral',
  'H70219 Acute petrositis, unspecified ear',
  'H70221 Chronic petrositis, right ear',
  'H70222 Chronic petrositis, left ear',
  'H70223 Chronic petrositis, bilateral',
  'H70229 Chronic petrositis, unspecified ear',
  'H70811 Postauricular fistula, right ear',
  'H70812 Postauricular fistula, left ear',
  'H70813 Postauricular fistula, bilateral',
  'H70819 Postauricular fistula, unspecified ear',
  'H70891 Other mastoiditis and related conditions, right ear',
  'H70892 Other mastoiditis and related conditions, left ear',
  'H70893 Other mastoiditis and related conditions, bilateral',
  'H70899 Other mastoiditis and related conditions, unspecified ear',
  'H7090 Unspecified mastoiditis, unspecified ear',
  'H7091 Unspecified mastoiditis, right ear',
  'H7092 Unspecified mastoiditis, left ear',
  'H7093 Unspecified mastoiditis, bilateral',
  'H7100 Cholesteatoma of attic, unspecified ear',
  'H7101 Cholesteatoma of attic, right ear',
  'H7102 Cholesteatoma of attic, left ear',
  'H7103 Cholesteatoma of attic, bilateral',
  'H7110 Cholesteatoma of tympanum, unspecified ear',
  'H7111 Cholesteatoma of tympanum, right ear',
  'H7112 Cholesteatoma of tympanum, left ear',
  'H7113 Cholesteatoma of tympanum, bilateral',
  'H7120 Cholesteatoma of mastoid, unspecified ear',
  'H7121 Cholesteatoma of mastoid, right ear',
  'H7122 Cholesteatoma of mastoid, left ear',
  'H7123 Cholesteatoma of mastoid, bilateral',
  'H7130 Diffuse cholesteatosis, unspecified ear',
  'H7131 Diffuse cholesteatosis, right ear',
  'H7132 Diffuse cholesteatosis, left ear',
  'H7133 Diffuse cholesteatosis, bilateral',
  'H7190 Unspecified cholesteatoma, unspecified ear',
  'H7191 Unspecified cholesteatoma, right ear',
  'H7192 Unspecified cholesteatoma, left ear',
  'H7193 Unspecified cholesteatoma, bilateral',
  'H7200 Central perforation of tympanic membrane, unspecified ear',
  'H7201 Central perforation of tympanic membrane, right ear',
  'H7202 Central perforation of tympanic membrane, left ear',
  'H7203 Central perforation of tympanic membrane, bilateral',
  'H7210 Attic perforation of tympanic membrane, unspecified ear',
  'H7211 Attic perforation of tympanic membrane, right ear',
  'H7212 Attic perforation of tympanic membrane, left ear',
  'H7213 Attic perforation of tympanic membrane, bilateral',
  'H722X1 Other marginal perforations of tympanic membrane, right ear',
  'H722X2 Other marginal perforations of tympanic membrane, left ear',
  'H722X3 Other marginal perforations of tympanic membrane, bilateral',
  'H722X9 Other marginal perforations of tympanic membrane, unspecified ear',
  'H72811 Multiple perforations of tympanic membrane, right ear',
  'H72812 Multiple perforations of tympanic membrane, left ear',
  'H72813 Multiple perforations of tympanic membrane, bilateral',
  'H72819 Multiple perforations of tympanic membrane, unspecified ear',
  'H72821 Total perforations of tympanic membrane, right ear',
  'H72822 Total perforations of tympanic membrane, left ear',
  'H72823 Total perforations of tympanic membrane, bilateral',
  'H72829 Total perforations of tympanic membrane, unspecified ear',
  'H7290 Unspecified perforation of tympanic membrane, unspecified ear',
  'H7291 Unspecified perforation of tympanic membrane, right ear',
  'H7292 Unspecified perforation of tympanic membrane, left ear',
  'H7293 Unspecified perforation of tympanic membrane, bilateral',
  'H73001 Acute myringitis, right ear',
  'H73002 Acute myringitis, left ear',
  'H73003 Acute myringitis, bilateral',
  'H73009 Acute myringitis, unspecified ear',
  'H73011 Bullous myringitis, right ear',
  'H73012 Bullous myringitis, left ear',
  'H73013 Bullous myringitis, bilateral',
  'H73019 Bullous myringitis, unspecified ear',
  'H73091 Other acute myringitis, right ear',
  'H73092 Other acute myringitis, left ear',
  'H73093 Other acute myringitis, bilateral',
  'H73099 Other acute myringitis, unspecified ear',
  'H7310 Chronic myringitis, unspecified ear',
  'H7311 Chronic myringitis, right ear',
  'H7312 Chronic myringitis, left ear',
  'H7313 Chronic myringitis, bilateral',
  'H7320 Unspecified myringitis, unspecified ear',
  'H7321 Unspecified myringitis, right ear',
  'H7322 Unspecified myringitis, left ear',
  'H7323 Unspecified myringitis, bilateral',
  'H73811 Atrophic flaccid tympanic membrane, right ear',
  'H73812 Atrophic flaccid tympanic membrane, left ear',
  'H73813 Atrophic flaccid tympanic membrane, bilateral',
  'H73819 Atrophic flaccid tympanic membrane, unspecified ear',
  'H73821 Atrophic nonflaccid tympanic membrane, right ear',
  'H73822 Atrophic nonflaccid tympanic membrane, left ear',
  'H73823 Atrophic nonflaccid tympanic membrane, bilateral',
  'H73829 Atrophic nonflaccid tympanic membrane, unspecified ear',
  'H73891 Other specified disorders of tympanic membrane, right ear',
  'H73892 Other specified disorders of tympanic membrane, left ear',
  'H73893 Other specified disorders of tympanic membrane, bilateral',
  'H73899 Other specified disorders of tympanic membrane, unspecified ear',
  'H7390 Unspecified disorder of tympanic membrane, unspecified ear',
  'H7391 Unspecified disorder of tympanic membrane, right ear',
  'H7392 Unspecified disorder of tympanic membrane, left ear',
  'H7393 Unspecified disorder of tympanic membrane, bilateral',
  'H7401 Tympanosclerosis, right ear',
  'H7402 Tympanosclerosis, left ear',
  'H7403 Tympanosclerosis, bilateral',
  'H7409 Tympanosclerosis, unspecified ear',
  'H7411 Adhesive right middle ear disease',
  'H7412 Adhesive left middle ear disease',
  'H7413 Adhesive middle ear disease, bilateral',
  'H7419 Adhesive middle ear disease, unspecified ear',
  'H7420 Discontinuity and dislocation of ear ossicles, unspecified ear',
  'H7421 Discontinuity and dislocation of right ear ossicles',
  'H7422 Discontinuity and dislocation of left ear ossicles',
  'H7423 Discontinuity and dislocation of ear ossicles, bilateral',
  'H74311 Ankylosis of ear ossicles, right ear',
  'H74312 Ankylosis of ear ossicles, left ear',
  'H74313 Ankylosis of ear ossicles, bilateral',
  'H74319 Ankylosis of ear ossicles, unspecified ear',
  'H74321 Partial loss of ear ossicles, right ear',
  'H74322 Partial loss of ear ossicles, left ear',
  'H74323 Partial loss of ear ossicles, bilateral',
  'H74329 Partial loss of ear ossicles, unspecified ear',
  'H74391 Other acquired abnormalities of right ear ossicles',
  'H74392 Other acquired abnormalities of left ear ossicles',
  'H74393 Other acquired abnormalities of ear ossicles, bilateral',
  'H74399 Other acquired abnormalities of ear ossicles, unspecified ear',
  'H7440 Polyp of middle ear, unspecified ear',
  'H7441 Polyp of right middle ear',
  'H7442 Polyp of left middle ear',
  'H7443 Polyp of middle ear, bilateral',
  'H748X1 Other specified disorders of right middle ear and mastoid',
  'H748X2 Other specified disorders of left middle ear and mastoid',
  'H748X3 Other specified disorders of middle ear and mastoid, bilateral',
  'H748X9 Other specified disorders of middle ear and mastoid, unspecified ear',
  'H7490 Unspecified disorder of middle ear and mastoid, unspecified ear',
  'H7491 Unspecified disorder of right middle ear and mastoid',
  'H7492 Unspecified disorder of left middle ear and mastoid',
  'H7493 Unspecified disorder of middle ear and mastoid, bilateral',
  'H7500 Mastoiditis in infectious and parasitic diseases classified elsewhere, unspecified ear',
  'H7501 Mastoiditis in infectious and parasitic diseases classified elsewhere, right ear',
  'H7502 Mastoiditis in infectious and parasitic diseases classified elsewhere, left ear',
  'H7503 Mastoiditis in infectious and parasitic diseases classified elsewhere, bilateral',
  'H7580 Other specified disorders of middle ear and mastoid in diseases classified elsewhere,',
  'unspecified ear',
  'H7581 Other specified disorders of right middle ear and mastoid in diseases classified elsewhere',
  'H7582 Other specified disorders of left middle ear and mastoid in diseases classified elsewhere',
  'H7583 Other specified disorders of middle ear and mastoid in diseases classified elsewhere, bilateral',
  'H8000 Otosclerosis involving oval window, nonobliterative, unspecified ear',
  'H8001 Otosclerosis involving oval window, nonobliterative, right ear',
  'H8002 Otosclerosis involving oval window, nonobliterative, left ear',
  'H8003 Otosclerosis involving oval window, nonobliterative, bilateral',
  'H8010 Otosclerosis involving oval window, obliterative, unspecified ear',
  'H8011 Otosclerosis involving oval window, obliterative, right ear',
  'H8012 Otosclerosis involving oval window, obliterative, left ear',
  'H8013 Otosclerosis involving oval window, obliterative, bilateral',
  'H8020 Cochlear otosclerosis, unspecified ear',
  'H8021 Cochlear otosclerosis, right ear',
  'H8022 Cochlear otosclerosis, left ear',
  'H8023 Cochlear otosclerosis, bilateral',
  'H8080 Other otosclerosis, unspecified ear',
  'H8081 Other otosclerosis, right ear',
  'H8082 Other otosclerosis, left ear',
  'H8083 Other otosclerosis, bilateral',
  'H8090 Unspecified otosclerosis, unspecified ear',
  'H8091 Unspecified otosclerosis, right ear',
  'H8092 Unspecified otosclerosis, left ear',
  'H8093 Unspecified otosclerosis, bilateral',
  "H8101 Meniere's disease, right ear",
  "H8102 Meniere's disease, left ear",
  "H8103 Meniere's disease, bilateral",
  "H8109 Meniere's disease, unspecified ear",
  'H8110 Benign paroxysmal vertigo, unspecified ear',
  'H8111 Benign paroxysmal vertigo, right ear',
  'H8112 Benign paroxysmal vertigo, left ear',
  'H8113 Benign paroxysmal vertigo, bilateral',
  'H8120 Vestibular neuronitis, unspecified ear',
  'H8121 Vestibular neuronitis, right ear',
  'H8122 Vestibular neuronitis, left ear',
  'H8123 Vestibular neuronitis, bilateral',
  'H81311 Aural vertigo, right ear',
  'H81312 Aural vertigo, left ear',
  'H81313 Aural vertigo, bilateral',
  'H81319 Aural vertigo, unspecified ear',
  'H81391 Other peripheral vertigo, right ear',
  'H81392 Other peripheral vertigo, left ear',
  'H81393 Other peripheral vertigo, bilateral',
  'H81399 Other peripheral vertigo, unspecified ear',
  'H8141 Vertigo of central origin, right ear',
  'H8142 Vertigo of central origin, left ear',
  'H8143 Vertigo of central origin, bilateral',
  'H8149 Vertigo of central origin, unspecified ear',
  'H818X1 Other disorders of vestibular function, right ear',
  'H818X2 Other disorders of vestibular function, left ear',
  'H818X3 Other disorders of vestibular function, bilateral',
  'H818X9 Other disorders of vestibular function, unspecified ear',
  'H8190 Unspecified disorder of vestibular function, unspecified ear',
  'H8191 Unspecified disorder of vestibular function, right ear',
  'H8192 Unspecified disorder of vestibular function, left ear',
  'H8193 Unspecified disorder of vestibular function, bilateral',
  'H821 Vertiginous syndromes in diseases classified elsewhere, right ear',
  'H822 Vertiginous syndromes in diseases classified elsewhere, left ear',
  'H823 Vertiginous syndromes in diseases classified elsewhere, bilateral',
  'H829 Vertiginous syndromes in diseases classified elsewhere, unspecified ear',
  'H8301 Labyrinthitis, right ear',
  'H8302 Labyrinthitis, left ear',
  'H8303 Labyrinthitis, bilateral',
  'H8309 Labyrinthitis, unspecified ear',
  'H8311 Labyrinthine fistula, right ear',
  'H8312 Labyrinthine fistula, left ear',
  'H8313 Labyrinthine fistula, bilateral',
  'H8319 Labyrinthine fistula, unspecified ear',
  'H832X1 Labyrinthine dysfunction, right ear',
  'H832X2 Labyrinthine dysfunction, left ear',
  'H832X3 Labyrinthine dysfunction, bilateral',
  'H832X9 Labyrinthine dysfunction, unspecified ear',
  'H833X1 Noise effects on right inner ear',
  'H833X2 Noise effects on left inner ear',
  'H833X3 Noise effects on inner ear, bilateral',
  'H833X9 Noise effects on inner ear, unspecified ear',
  'H838X1 Other specified diseases of right inner ear',
  'H838X2 Other specified diseases of left inner ear',
  'H838X3 Other specified diseases of inner ear, bilateral',
  'H838X9 Other specified diseases of inner ear, unspecified ear',
  'H8390 Unspecified disease of inner ear, unspecified ear',
  'H8391 Unspecified disease of right inner ear',
  'H8392 Unspecified disease of left inner ear',
  'H8393 Unspecified disease of inner ear, bilateral',
  'H900 Conductive hearing loss, bilateral',
  'H9011 Conductive hearing loss, unilateral, right ear, with unrestricted hearing on the contralateral side',
  'H9012 Conductive hearing loss, unilateral, left ear, with unrestricted hearing on the contralateral side',
  'H902 Conductive hearing loss, unspecified',
  'H903 Sensorineural hearing loss, bilateral',
  'H9041 Sensorineural hearing loss, unilateral, right ear, with unrestricted hearing on the contralateral',
  'side',
  'H9042 Sensorineural hearing loss, unilateral, left ear, with unrestricted hearing on the contralateral',
  'side',
  'H905 Unspecified sensorineural hearing loss',
  'H906 Mixed conductive and sensorineural hearing loss, bilateral',
  'H9071 Mixed conductive and sensorineural hearing loss, unilateral, right ear, with unrestricted hearing',
  'on the contralateral side',
  'H9072 Mixed conductive and sensorineural hearing loss, unilateral, left ear, with unrestricted hearing',
  'on the contralateral side',
  'H908 Mixed conductive and sensorineural hearing loss, unspecified',
  'H90A11 Conductive hearing loss, unilateral, right ear with restricted hearing on the contralateral side',
  'H90A12 Conductive hearing loss, unilateral, left ear with restricted hearing on the contralateral side',
  'H90A21 Sensorineural hearing loss, unilateral, right ear, with restricted hearing on the contralateral',
  'side',
  'H90A22 Sensorineural hearing loss, unilateral, left ear, with restricted hearing on the contralateral side',
  'H90A31 Mixed conductive and sensorineural hearing loss, unilateral, right ear with restricted hearing on',
  'the contralateral side',
  'H90A32 Mixed conductive and sensorineural hearing loss, unilateral, left ear with restricted hearing on',
  'the contralateral side',
  'H9101 Ototoxic hearing loss, right ear',
  'H9102 Ototoxic hearing loss, left ear',
  'H9103 Ototoxic hearing loss, bilateral',
  'H9109 Ototoxic hearing loss, unspecified ear',
  'H9110 Presbycusis, unspecified ear',
  'H9111 Presbycusis, right ear',
  'H9112 Presbycusis, left ear',
  'H9113 Presbycusis, bilateral',
  'H9120 Sudden idiopathic hearing loss, unspecified ear',
  'H9121 Sudden idiopathic hearing loss, right ear',
  'H9122 Sudden idiopathic hearing loss, left ear',
  'H9123 Sudden idiopathic hearing loss, bilateral',
  'H913 Deaf nonspeaking, not elsewhere classified',
  'H918X1 Other specified hearing loss, right ear',
  'H918X2 Other specified hearing loss, left ear',
  'H918X3 Other specified hearing loss, bilateral',
  'H918X9 Other specified hearing loss, unspecified ear',
  'H9190 Unspecified hearing loss, unspecified ear',
  'H9191 Unspecified hearing loss, right ear',
  'H9192 Unspecified hearing loss, left ear',
  'H9193 Unspecified hearing loss, bilateral',
  'H9201 Otalgia, right ear',
  'H9202 Otalgia, left ear',
  'H9203 Otalgia, bilateral',
  'H9209 Otalgia, unspecified ear',
  'H9210 Otorrhea, unspecified ear',
  'H9211 Otorrhea, right ear',
  'H9212 Otorrhea, left ear',
  'H9213 Otorrhea, bilateral',
  'H9220 Otorrhagia, unspecified ear',
  'H9221 Otorrhagia, right ear',
  'H9222 Otorrhagia, left ear',
  'H9223 Otorrhagia, bilateral',
  'H93011 Transient ischemic deafness, right ear',
  'H93012 Transient ischemic deafness, left ear',
  'H93013 Transient ischemic deafness, bilateral',
  'H93019 Transient ischemic deafness, unspecified ear',
  'H93091 Unspecified degenerative and vascular disorders of right ear',
  'H93092 Unspecified degenerative and vascular disorders of left ear',
  'H93093 Unspecified degenerative and vascular disorders of ear, bilateral',
  'H93099 Unspecified degenerative and vascular disorders of unspecified ear',
  'H9311 Tinnitus, right ear',
  'H9312 Tinnitus, left ear',
  'H9313 Tinnitus, bilateral',
  'H9319 Tinnitus, unspecified ear',
  'H93A1 Pulsatile tinnitus, right ear',
  'H93A2 Pulsatile tinnitus, left ear',
  'H93A3 Pulsatile tinnitus, bilateral',
  'H93A9 Pulsatile tinnitus, unspecified ear',
  'H93211 Auditory recruitment, right ear',
  'H93212 Auditory recruitment, left ear',
  'H93213 Auditory recruitment, bilateral',
  'H93219 Auditory recruitment, unspecified ear',
  'H93221 Diplacusis, right ear',
  'H93222 Diplacusis, left ear',
  'H93223 Diplacusis, bilateral',
  'H93229 Diplacusis, unspecified ear',
  'H93231 Hyperacusis, right ear',
  'H93232 Hyperacusis, left ear',
  'H93233 Hyperacusis, bilateral',
  'H93239 Hyperacusis, unspecified ear',
  'H93241 Temporary auditory threshold shift, right ear',
  'H93242 Temporary auditory threshold shift, left ear',
  'H93243 Temporary auditory threshold shift, bilateral',
  'H93249 Temporary auditory threshold shift, unspecified ear',
  'H9325 Central auditory processing disorder',
  'H93291 Other abnormal auditory perceptions, right ear',
  'H93292 Other abnormal auditory perceptions, left ear',
  'H93293 Other abnormal auditory perceptions, bilateral',
  'H93299 Other abnormal auditory perceptions, unspecified ear',
  'H933X1 Disorders of right acoustic nerve',
  'H933X2 Disorders of left acoustic nerve',
  'H933X3 Disorders of bilateral acoustic nerves',
  'H933X9 Disorders of unspecified acoustic nerve',
  'H938X1 Other specified disorders of right ear',
  'H938X2 Other specified disorders of left ear',
  'H938X3 Other specified disorders of ear, bilateral',
  'H938X9 Other specified disorders of ear, unspecified ear',
  'H9390 Unspecified disorder of ear, unspecified ear',
  'H9391 Unspecified disorder of right ear',
  'H9392 Unspecified disorder of left ear',
  'H9393 Unspecified disorder of ear, bilateral',
  'H9400 Acoustic neuritis in infectious and parasitic diseases classified elsewhere, unspecified ear',
  'H9401 Acoustic neuritis in infectious and parasitic diseases classified elsewhere, right ear',
  'H9402 Acoustic neuritis in infectious and parasitic diseases classified elsewhere, left ear',
  'H9403 Acoustic neuritis in infectious and parasitic diseases classified elsewhere, bilateral',
  'H9480 Other specified disorders of ear in diseases classified elsewhere, unspecified ear',
  'H9481 Other specified disorders of right ear in diseases classified elsewhere',
  'H9482 Other specified disorders of left ear in diseases classified elsewhere',
  'H9483 Other specified disorders of ear in diseases classified elsewhere, bilateral',
  'H9500 Recurrent cholesteatoma of postmastoidectomy cavity, unspecified ear',
  'H9501 Recurrent cholesteatoma of postmastoidectomy cavity, right ear',
  'H9502 Recurrent cholesteatoma of postmastoidectomy cavity, left ear',
  'H9503 Recurrent cholesteatoma of postmastoidectomy cavity, bilateral ears',
  'H95111 Chronic inflammation of postmastoidectomy cavity, right ear',
  'H95112 Chronic inflammation of postmastoidectomy cavity, left ear',
  'H95113 Chronic inflammation of postmastoidectomy cavity, bilateral ears',
  'H95119 Chronic inflammation of postmastoidectomy cavity, unspecified ear',
  'H95121 Granulation of postmastoidectomy cavity, right ear',
  'H95122 Granulation of postmastoidectomy cavity, left ear',
  'H95123 Granulation of postmastoidectomy cavity, bilateral ears',
  'H95129 Granulation of postmastoidectomy cavity, unspecified ear',
  'H95131 Mucosal cyst of postmastoidectomy cavity, right ear',
  'H95132 Mucosal cyst of postmastoidectomy cavity, left ear',
  'H95133 Mucosal cyst of postmastoidectomy cavity, bilateral ears',
  'H95139 Mucosal cyst of postmastoidectomy cavity, unspecified ear',
  'H95191 Other disorders following mastoidectomy, right ear',
  'H95192 Other disorders following mastoidectomy, left ear',
  'H95193 Other disorders following mastoidectomy, bilateral ears',
  'H95199 Other disorders following mastoidectomy, unspecified ear',
  'H9521 Intraoperative hemorrhage and hematoma of ear and mastoid process complicating a',
  'procedure on the ear and mastoid process',
  'H9522 Intraoperative hemorrhage and hematoma of ear and mastoid process complicating other',
  'procedure',
  'H9531 Accidental puncture and laceration of the ear and mastoid process during a procedure on the',
  'ear and mastoid process',
  'H9532 Accidental puncture and laceration of the ear and mastoid process during other procedure',
  'H9541 Postprocedural hemorrhage of ear and mastoid process following a procedure on the ear and',
  'mastoid process',
  'H9542 Postprocedural hemorrhage of ear and mastoid process following other procedure',
  'H9551 Postprocedural hematoma of ear and mastoid process following a procedure on the ear and',
  'mastoid process',
  'H9552 Postprocedural hematoma of ear and mastoid process following other procedure',
  'H9553 Postprocedural seroma of ear and mastoid process following a procedure on the ear and',
  'mastoid process',
  'H9554 Postprocedural seroma of ear and mastoid process following other procedure',
  'H95811 Postprocedural stenosis of right external ear canal',
  'H95812 Postprocedural stenosis of left external ear canal',
  'H95813 Postprocedural stenosis of external ear canal, bilateral',
  'H95819 Postprocedural stenosis of unspecified external ear canal',
  'H9588 Other intraoperative complications and disorders of the ear and mastoid process, not elsewhere',
  'classified',
  'H9589 Other postprocedural complications and disorders of the ear and mastoid process, not',
  'elsewhere classified',
  'I00 Rheumatic fever without heart involvement',
  'I010 Acute rheumatic pericarditis',
  'I011 Acute rheumatic endocarditis',
  'I012 Acute rheumatic myocarditis',
  'I018 Other acute rheumatic heart disease',
  'I019 Acute rheumatic heart disease, unspecified',
  'I020 Rheumatic chorea with heart involvement',
  'I029 Rheumatic chorea without heart involvement',
  'I050 Rheumatic mitral stenosis',
  'I051 Rheumatic mitral insufficiency',
  'I052 Rheumatic mitral stenosis with insufficiency',
  'I058 Other rheumatic mitral valve diseases',
  'I059 Rheumatic mitral valve disease, unspecified',
  'I060 Rheumatic aortic stenosis',
  'I061 Rheumatic aortic insufficiency',
  'I062 Rheumatic aortic stenosis with insufficiency',
  'I068 Other rheumatic aortic valve diseases',
  'I069 Rheumatic aortic valve disease, unspecified',
  'I070 Rheumatic tricuspid stenosis',
  'I071 Rheumatic tricuspid insufficiency',
  'I072 Rheumatic tricuspid stenosis and insufficiency',
  'I078 Other rheumatic tricuspid valve diseases',
  'I079 Rheumatic tricuspid valve disease, unspecified',
  'I080 Rheumatic disorders of both mitral and aortic valves',
  'I081 Rheumatic disorders of both mitral and tricuspid valves',
  'I082 Rheumatic disorders of both aortic and tricuspid valves',
  'I083 Combined rheumatic disorders of mitral, aortic and tricuspid valves',
  'I088 Other rheumatic multiple valve diseases',
  'I089 Rheumatic multiple valve disease, unspecified',
  'I090 Rheumatic myocarditis',
  'I091 Rheumatic diseases of endocardium, valve unspecified',
  'I092 Chronic rheumatic pericarditis',
  'I0981 Rheumatic heart failure',
  'I0989 Other specified rheumatic heart diseases',
  'I099 Rheumatic heart disease, unspecified',
  'I10 Essential (primary) hypertension',
  'I110 Hypertensive heart disease with heart failure',
  'I119 Hypertensive heart disease without heart failure',
  'I120 Hypertensive chronic kidney disease with stage 5 chronic kidney disease or end stage renal',
  'disease',
  'I129 Hypertensive chronic kidney disease with stage 1 through stage 4 chronic kidney disease, or',
  'unspecified chronic kidney disease',
  'I130 Hypertensive heart and chronic kidney disease with heart failure and stage 1 through stage 4',
  'chronic kidney disease, or unspecified chronic kidney disease',
  'I1310 Hypertensive heart and chronic kidney disease without heart failure, with stage 1 through stage',
  '4 chronic kidney disease, or unspecified chronic kidney disease',
  'I1311 Hypertensive heart and chronic kidney disease without heart failure, with stage 5 chronic kidney',
  'disease, or end stage renal disease',
  'I132 Hypertensive heart and chronic kidney disease with heart failure and with stage 5 chronic kidney',
  'disease, or end stage renal disease',
  'I150 Renovascular hypertension',
  'I151 Hypertension secondary to other renal disorders',
  'I152 Hypertension secondary to endocrine disorders',
  'I158 Other secondary hypertension',
  'I159 Secondary hypertension, unspecified',
  'I160 Hypertensive urgency',
  'I161 Hypertensive emergency',
  'I169 Hypertensive crisis, unspecified',
  'I200 Unstable angina',
  'I201 Angina pectoris with documented spasm',
  'I208 Other forms of angina pectoris',
  'I209 Angina pectoris, unspecified',
  'I2101 ST elevation (STEMI) myocardial infarction involving left main coronary artery',
  'I2102 ST elevation (STEMI) myocardial infarction involving left anterior descending coronary artery',
  'I2109 ST elevation (STEMI) myocardial infarction involving other coronary artery of anterior wall',
  'I2111 ST elevation (STEMI) myocardial infarction involving right coronary artery',
  'I2119 ST elevation (STEMI) myocardial infarction involving other coronary artery of inferior wall',
  'I2121 ST elevation (STEMI) myocardial infarction involving left circumflex coronary artery',
  'I2129 ST elevation (STEMI) myocardial infarction involving other sites',
  'I213 ST elevation (STEMI) myocardial infarction of unspecified site',
  'I214 Non-ST elevation (NSTEMI) myocardial infarction',
  'I220 Subsequent ST elevation (STEMI) myocardial infarction of anterior wall',
  'I221 Subsequent ST elevation (STEMI) myocardial infarction of inferior wall',
  'I222 Subsequent non-ST elevation (NSTEMI) myocardial infarction',
  'I228 Subsequent ST elevation (STEMI) myocardial infarction of other sites',
  'I229 Subsequent ST elevation (STEMI) myocardial infarction of unspecified site',
  'I230 Hemopericardium as current complication following acute myocardial infarction',
  'I231 Atrial septal defect as current complication following acute myocardial infarction',
  'I232 Ventricular septal defect as current complication following acute myocardial infarction',
  'I233 Rupture of cardiac wall without hemopericardium as current complication following acute',
  'myocardial infarction',
  'I234 Rupture of chordae tendineae as current complication following acute myocardial infarction',
  'I235 Rupture of papillary muscle as current complication following acute myocardial infarction',
  'I236 Thrombosis of atrium, auricular appendage, and ventricle as current complications following',
  'acute myocardial infarction',
  'I237 Postinfarction angina',
  'I238 Other current complications following acute myocardial infarction',
  'I240 Acute coronary thrombosis not resulting in myocardial infarction',
  "I241 Dressler's syndrome",
  'I248 Other forms of acute ischemic heart disease',
  'I249 Acute ischemic heart disease, unspecified',
  'I2510 Atherosclerotic heart disease of native coronary artery without angina pectoris',
  'I25110 Atherosclerotic heart disease of native coronary artery with unstable angina pectoris',
  'I25111 Atherosclerotic heart disease of native coronary artery with angina pectoris with documented',
  'spasm',
  'I25118 Atherosclerotic heart disease of native coronary artery with other forms of angina pectoris',
  'I25119 Atherosclerotic heart disease of native coronary artery with unspecified angina pectoris',
  'I252 Old myocardial infarction',
  'I253 Aneurysm of heart',
  'I2541 Coronary artery aneurysm',
  'I2542 Coronary artery dissection',
  'I255 Ischemic cardiomyopathy',
  'I256 Silent myocardial ischemia',
  'I25700 Atherosclerosis of coronary artery bypass graft(s), unspecified, with unstable angina pectoris',
  'I25701 Atherosclerosis of coronary artery bypass graft(s), unspecified, with angina pectoris with',
  'documented spasm',
  'I25708 Atherosclerosis of coronary artery bypass graft(s), unspecified, with other forms of angina',
  'pectoris',
  'I25709 Atherosclerosis of coronary artery bypass graft(s), unspecified, with unspecified angina pectoris',
  'I25710 Atherosclerosis of autologous vein coronary artery bypass graft(s) with unstable angina pectoris',
  'I25711 Atherosclerosis of autologous vein coronary artery bypass graft(s) with angina pectoris with',
  'documented spasm',
  'I25718 Atherosclerosis of autologous vein coronary artery bypass graft(s) with other forms of angina',
  'pectoris',
  'I25719 Atherosclerosis of autologous vein coronary artery bypass graft(s) with unspecified angina',
  'pectoris',
  'I25720 Atherosclerosis of autologous artery coronary artery bypass graft(s) with unstable angina',
  'pectoris',
  'I25721 Atherosclerosis of autologous artery coronary artery bypass graft(s) with angina pectoris with',
  'documented spasm',
  'I25728 Atherosclerosis of autologous artery coronary artery bypass graft(s) with other forms of angina',
  'pectoris',
  'I25729 Atherosclerosis of autologous artery coronary artery bypass graft(s) with unspecified angina',
  'pectoris',
  'I25730 Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with unstable angina',
  'pectoris',
  'I25731 Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with angina pectoris',
  'with documented spasm',
  'I25738 Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with other forms of',
  'angina pectoris',
  'I25739 Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with unspecified',
  'angina pectoris',
  'I25750 Atherosclerosis of native coronary artery of transplanted heart with unstable angina',
  'I25751 Atherosclerosis of native coronary artery of transplanted heart with angina pectoris with',
  'documented spasm',
  'I25758 Atherosclerosis of native coronary artery of transplanted heart with other forms of angina',
  'pectoris',
  'I25759 Atherosclerosis of native coronary artery of transplanted heart with unspecified angina pectoris',
  'I25760 Atherosclerosis of bypass graft of coronary artery of transplanted heart with unstable angina',
  'I25761 Atherosclerosis of bypass graft of coronary artery of transplanted heart with angina pectoris',
  'with documented spasm',
  'I25768 Atherosclerosis of bypass graft of coronary artery of transplanted heart with other forms of',
  'angina pectoris',
  'I25769 Atherosclerosis of bypass graft of coronary artery of transplanted heart with unspecified angina',
  'pectoris',
  'I25790 Atherosclerosis of other coronary artery bypass graft(s) with unstable angina pectoris',
  'I25791 Atherosclerosis of other coronary artery bypass graft(s) with angina pectoris with documented',
  'spasm',
  'I25798 Atherosclerosis of other coronary artery bypass graft(s) with other forms of angina pectoris',
  'I25799 Atherosclerosis of other coronary artery bypass graft(s) with unspecified angina pectoris',
  'I25810 Atherosclerosis of coronary artery bypass graft(s) without angina pectoris',
  'I25811 Atherosclerosis of native coronary artery of transplanted heart without angina pectoris',
  'I25812 Atherosclerosis of bypass graft of coronary artery of transplanted heart without angina pectoris',
  'I2582 Chronic total occlusion of coronary artery',
  'I2583 Coronary atherosclerosis due to lipid rich plaque',
  'I2584 Coronary atherosclerosis due to calcified coronary lesion',
  'I2589 Other forms of chronic ischemic heart disease',
  'I259 Chronic ischemic heart disease, unspecified',
  'I2601 Septic pulmonary embolism with acute cor pulmonale',
  'I2602 Saddle embolus of pulmonary artery with acute cor pulmonale',
  'I2609 Other pulmonary embolism with acute cor pulmonale',
  'I2690 Septic pulmonary embolism without acute cor pulmonale',
  'I2692 Saddle embolus of pulmonary artery without acute cor pulmonale',
  'I2699 Other pulmonary embolism without acute cor pulmonale',
  'I270 Primary pulmonary hypertension',
  'I271 Kyphoscoliotic heart disease',
  'I272 Other secondary pulmonary hypertension',
  'I2781 Cor pulmonale (chronic)',
  'I2782 Chronic pulmonary embolism',
  'I2789 Other specified pulmonary heart diseases',
  'I279 Pulmonary heart disease, unspecified',
  'I280 Arteriovenous fistula of pulmonary vessels',
  'I281 Aneurysm of pulmonary artery',
  'I288 Other diseases of pulmonary vessels',
  'I289 Disease of pulmonary vessels, unspecified',
  'I300 Acute nonspecific idiopathic pericarditis',
  'I301 Infective pericarditis',
  'I308 Other forms of acute pericarditis',
  'I309 Acute pericarditis, unspecified',
  'I310 Chronic adhesive pericarditis',
  'I311 Chronic constrictive pericarditis',
  'I312 Hemopericardium, not elsewhere classified',
  'I313 Pericardial effusion (noninflammatory)',
  'I314 Cardiac tamponade',
  'I318 Other specified diseases of pericardium',
  'I319 Disease of pericardium, unspecified',
  'I32 Pericarditis in diseases classified elsewhere',
  'I330 Acute and subacute infective endocarditis',
  'I339 Acute and subacute endocarditis, unspecified',
  'I340 Nonrheumatic mitral (valve) insufficiency',
  'I341 Nonrheumatic mitral (valve) prolapse',
  'I342 Nonrheumatic mitral (valve) stenosis',
  'I348 Other nonrheumatic mitral valve disorders',
  'I349 Nonrheumatic mitral valve disorder, unspecified',
  'I350 Nonrheumatic aortic (valve) stenosis',
  'I351 Nonrheumatic aortic (valve) insufficiency',
  'I352 Nonrheumatic aortic (valve) stenosis with insufficiency',
  'I358 Other nonrheumatic aortic valve disorders',
  'I359 Nonrheumatic aortic valve disorder, unspecified',
  'I360 Nonrheumatic tricuspid (valve) stenosis',
  'I361 Nonrheumatic tricuspid (valve) insufficiency',
  'I362 Nonrheumatic tricuspid (valve) stenosis with insufficiency',
  'I368 Other nonrheumatic tricuspid valve disorders',
  'I369 Nonrheumatic tricuspid valve disorder, unspecified',
  'I370 Nonrheumatic pulmonary valve stenosis',
  'I371 Nonrheumatic pulmonary valve insufficiency',
  'I372 Nonrheumatic pulmonary valve stenosis with insufficiency',
  'I378 Other nonrheumatic pulmonary valve disorders',
  'I379 Nonrheumatic pulmonary valve disorder, unspecified',
  'I38 Endocarditis, valve unspecified',
  'I39 Endocarditis and heart valve disorders in diseases classified elsewhere',
  'I400 Infective myocarditis',
  'I401 Isolated myocarditis',
  'I408 Other acute myocarditis',
  'I409 Acute myocarditis, unspecified',
  'I41 Myocarditis in diseases classified elsewhere',
  'I420 Dilated cardiomyopathy',
  'I421 Obstructive hypertrophic cardiomyopathy',
  'I422 Other hypertrophic cardiomyopathy',
  'I423 Endomyocardial (eosinophilic) disease',
  'I424 Endocardial fibroelastosis',
  'I425 Other restrictive cardiomyopathy',
  'I426 Alcoholic cardiomyopathy',
  'I427 Cardiomyopathy due to drug and external agent',
  'I428 Other cardiomyopathies',
  'I429 Cardiomyopathy, unspecified',
  'I43 Cardiomyopathy in diseases classified elsewhere',
  'I440 Atrioventricular block, first degree',
  'I441 Atrioventricular block, second degree',
  'I442 Atrioventricular block, complete',
  'I4430 Unspecified atrioventricular block',
  'I4439 Other atrioventricular block',
  'I444 Left anterior fascicular block',
  'I445 Left posterior fascicular block',
  'I4460 Unspecified fascicular block',
  'I4469 Other fascicular block',
  'I447 Left bundle-branch block, unspecified',
  'I450 Right fascicular block',
  'I4510 Unspecified right bundle-branch block',
  'I4519 Other right bundle-branch block',
  'I452 Bifascicular block',
  'I453 Trifascicular block',
  'I454 Nonspecific intraventricular block',
  'I455 Other specified heart block',
  'I456 Pre-excitation syndrome',
  'I4581 Long QT syndrome',
  'I4589 Other specified conduction disorders',
  'I459 Conduction disorder, unspecified',
  'I462 Cardiac arrest due to underlying cardiac condition',
  'I468 Cardiac arrest due to other underlying condition',
  'I469 Cardiac arrest, cause unspecified',
  'I470 Re-entry ventricular arrhythmia',
  'I471 Supraventricular tachycardia',
  'I472 Ventricular tachycardia',
  'I479 Paroxysmal tachycardia, unspecified',
  'I480 Paroxysmal atrial fibrillation',
  'I481 Persistent atrial fibrillation',
  'I482 Chronic atrial fibrillation',
  'I483 Typical atrial flutter',
  'I484 Atypical atrial flutter',
  'I4891 Unspecified atrial fibrillation',
  'I4892 Unspecified atrial flutter',
  'I4901 Ventricular fibrillation',
  'I4902 Ventricular flutter',
  'I491 Atrial premature depolarization',
  'I492 Junctional premature depolarization',
  'I493 Ventricular premature depolarization',
  'I4940 Unspecified premature depolarization',
  'I4949 Other premature depolarization',
  'I495 Sick sinus syndrome',
  'I498 Other specified cardiac arrhythmias',
  'I499 Cardiac arrhythmia, unspecified',
  'I501 Left ventricular failure',
  'I5020 Unspecified systolic (congestive) heart failure',
  'I5021 Acute systolic (congestive) heart failure',
  'I5022 Chronic systolic (congestive) heart failure',
  'I5023 Acute on chronic systolic (congestive) heart failure',
  'I5030 Unspecified diastolic (congestive) heart failure',
  'I5031 Acute diastolic (congestive) heart failure',
  'I5032 Chronic diastolic (congestive) heart failure',
  'I5033 Acute on chronic diastolic (congestive) heart failure',
  'I5040 Unspecified combined systolic (congestive) and diastolic (congestive) heart failure',
  'I5041 Acute combined systolic (congestive) and diastolic (congestive) heart failure',
  'I5042 Chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  'I5043 Acute on chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  'I509 Heart failure, unspecified',
  'I510 Cardiac septal defect, acquired',
  'I511 Rupture of chordae tendineae, not elsewhere classified',
  'I512 Rupture of papillary muscle, not elsewhere classified',
  'I513 Intracardiac thrombosis, not elsewhere classified',
  'I514 Myocarditis, unspecified',
  'I515 Myocardial degeneration',
  'I517 Cardiomegaly',
  'I5181 Takotsubo syndrome',
  'I5189 Other ill-defined heart diseases',
  'I519 Heart disease, unspecified',
  'I52 Other heart disorders in diseases classified elsewhere',
  'I6000 Nontraumatic subarachnoid hemorrhage from unspecified carotid siphon and bifurcation',
  'I6001 Nontraumatic subarachnoid hemorrhage from right carotid siphon and bifurcation',
  'I6002 Nontraumatic subarachnoid hemorrhage from left carotid siphon and bifurcation',
  'I6010 Nontraumatic subarachnoid hemorrhage from unspecified middle cerebral artery',
  'I6011 Nontraumatic subarachnoid hemorrhage from right middle cerebral artery',
  'I6012 Nontraumatic subarachnoid hemorrhage from left middle cerebral artery',
  'I602 Nontraumatic subarachnoid hemorrhage from anterior communicating artery',
  'I6030 Nontraumatic subarachnoid hemorrhage from unspecified posterior communicating artery',
  'I6031 Nontraumatic subarachnoid hemorrhage from right posterior communicating artery',
  'I6032 Nontraumatic subarachnoid hemorrhage from left posterior communicating artery',
  'I604 Nontraumatic subarachnoid hemorrhage from basilar artery',
  'I6050 Nontraumatic subarachnoid hemorrhage from unspecified vertebral artery',
  'I6051 Nontraumatic subarachnoid hemorrhage from right vertebral artery',
  'I6052 Nontraumatic subarachnoid hemorrhage from left vertebral artery',
  'I606 Nontraumatic subarachnoid hemorrhage from other intracranial arteries',
  'I607 Nontraumatic subarachnoid hemorrhage from unspecified intracranial artery',
  'I608 Other nontraumatic subarachnoid hemorrhage',
  'I609 Nontraumatic subarachnoid hemorrhage, unspecified',
  'I610 Nontraumatic intracerebral hemorrhage in hemisphere, subcortical',
  'I611 Nontraumatic intracerebral hemorrhage in hemisphere, cortical',
  'I612 Nontraumatic intracerebral hemorrhage in hemisphere, unspecified',
  'I613 Nontraumatic intracerebral hemorrhage in brain stem',
  'I614 Nontraumatic intracerebral hemorrhage in cerebellum',
  'I615 Nontraumatic intracerebral hemorrhage, intraventricular',
  'I616 Nontraumatic intracerebral hemorrhage, multiple localized',
  'I618 Other nontraumatic intracerebral hemorrhage',
  'I619 Nontraumatic intracerebral hemorrhage, unspecified',
  'I6200 Nontraumatic subdural hemorrhage, unspecified',
  'I6201 Nontraumatic acute subdural hemorrhage',
  'I6202 Nontraumatic subacute subdural hemorrhage',
  'I6203 Nontraumatic chronic subdural hemorrhage',
  'I621 Nontraumatic extradural hemorrhage',
  'I629 Nontraumatic intracranial hemorrhage, unspecified',
  'I6300 Cerebral infarction due to thrombosis of unspecified precerebral artery',
  'I63011 Cerebral infarction due to thrombosis of right vertebral artery',
  'I63012 Cerebral infarction due to thrombosis of left vertebral artery',
  'I63013 Cerebral infarction due to thrombosis of bilateral vertebral arteries',
  'I63019 Cerebral infarction due to thrombosis of unspecified vertebral artery',
  'I6302 Cerebral infarction due to thrombosis of basilar artery',
  'I63031 Cerebral infarction due to thrombosis of right carotid artery',
  'I63032 Cerebral infarction due to thrombosis of left carotid artery',
  'I63033 Cerebral infarction due to thrombosis of bilateral carotid arteries',
  'I63039 Cerebral infarction due to thrombosis of unspecified carotid artery',
  'I6309 Cerebral infarction due to thrombosis of other precerebral artery',
  'I6310 Cerebral infarction due to embolism of unspecified precerebral artery',
  'I63111 Cerebral infarction due to embolism of right vertebral artery',
  'I63112 Cerebral infarction due to embolism of left vertebral artery',
  'I63113 Cerebral infarction due to embolism of bilateral vertebral arteries',
  'I63119 Cerebral infarction due to embolism of unspecified vertebral artery',
  'I6312 Cerebral infarction due to embolism of basilar artery',
  'I63131 Cerebral infarction due to embolism of right carotid artery',
  'I63132 Cerebral infarction due to embolism of left carotid artery',
  'I63133 Cerebral infarction due to embolism of bilateral carotid arteries',
  'I63139 Cerebral infarction due to embolism of unspecified carotid artery',
  'I6319 Cerebral infarction due to embolism of other precerebral artery',
  'I6320 Cerebral infarction due to unspecified occlusion or stenosis of unspecified precerebral arteries',
  'I63211 Cerebral infarction due to unspecified occlusion or stenosis of right vertebral arteries',
  'I63212 Cerebral infarction due to unspecified occlusion or stenosis of left vertebral arteries',
  'I63213 Cerebral infarction due to unspecified occlusion or stenosis of bilateral vertebral arteries',
  'I63219 Cerebral infarction due to unspecified occlusion or stenosis of unspecified vertebral arteries',
  'I6322 Cerebral infarction due to unspecified occlusion or stenosis of basilar arteries',
  'I63231 Cerebral infarction due to unspecified occlusion or stenosis of right carotid arteries',
  'I63232 Cerebral infarction due to unspecified occlusion or stenosis of left carotid arteries',
  'I63233 Cerebral infarction due to unspecified occlusion or stenosis of bilateral carotid arteries',
  'I63239 Cerebral infarction due to unspecified occlusion or stenosis of unspecified carotid arteries',
  'I6329 Cerebral infarction due to unspecified occlusion or stenosis of other precerebral arteries',
  'I6330 Cerebral infarction due to thrombosis of unspecified cerebral artery',
  'I63311 Cerebral infarction due to thrombosis of right middle cerebral artery',
  'I63312 Cerebral infarction due to thrombosis of left middle cerebral artery',
  'I63313 Cerebral infarction due to thrombosis of bilateral middle cerebral arteries',
  'I63319 Cerebral infarction due to thrombosis of unspecified middle cerebral artery',
  'I63321 Cerebral infarction due to thrombosis of right anterior cerebral artery',
  'I63322 Cerebral infarction due to thrombosis of left anterior cerebral artery',
  'I63323 Cerebral infarction due to thrombosis of bilateral anterior arteries',
  'I63329 Cerebral infarction due to thrombosis of unspecified anterior cerebral artery',
  'I63331 Cerebral infarction due to thrombosis of right posterior cerebral artery',
  'I63332 Cerebral infarction due to thrombosis of left posterior cerebral artery',
  'I63333 Cerebral infarction to thrombosis of bilateral posterior arteries',
  'I63339 Cerebral infarction due to thrombosis of unspecified posterior cerebral artery',
  'I63341 Cerebral infarction due to thrombosis of right cerebellar artery',
  'I63342 Cerebral infarction due to thrombosis of left cerebellar artery',
  'I63343 Cerebral infarction to thrombosis of bilateral cerebellar arteries',
  'I63349 Cerebral infarction due to thrombosis of unspecified cerebellar artery',
  'I6339 Cerebral infarction due to thrombosis of other cerebral artery',
  'I6340 Cerebral infarction due to embolism of unspecified cerebral artery',
  'I63411 Cerebral infarction due to embolism of right middle cerebral artery',
  'I63412 Cerebral infarction due to embolism of left middle cerebral artery',
  'I63413 Cerebral infarction due to embolism of bilateral middle cerebral arteries',
  'I63419 Cerebral infarction due to embolism of unspecified middle cerebral artery',
  'I63421 Cerebral infarction due to embolism of right anterior cerebral artery',
  'I63422 Cerebral infarction due to embolism of left anterior cerebral artery',
  'I63423 Cerebral infarction due to embolism of bilateral anterior cerebral arteries',
  'I63429 Cerebral infarction due to embolism of unspecified anterior cerebral artery',
  'I63431 Cerebral infarction due to embolism of right posterior cerebral artery',
  'I63432 Cerebral infarction due to embolism of left posterior cerebral artery',
  'I63433 Cerebral infarction due to embolism of bilateral posterior cerebral arteries',
  'I63439 Cerebral infarction due to embolism of unspecified posterior cerebral artery',
  'I63441 Cerebral infarction due to embolism of right cerebellar artery',
  'I63442 Cerebral infarction due to embolism of left cerebellar artery',
  'I63443 Cerebral infarction due to embolism of bilateral cerebellar arteries',
  'I63449 Cerebral infarction due to embolism of unspecified cerebellar artery',
  'I6349 Cerebral infarction due to embolism of other cerebral artery',
  'I6350 Cerebral infarction due to unspecified occlusion or stenosis of unspecified cerebral artery',
  'I63511 Cerebral infarction due to unspecified occlusion or stenosis of right middle cerebral artery',
  'I63512 Cerebral infarction due to unspecified occlusion or stenosis of left middle cerebral artery',
  'I63513 Cerebral infarction due to unspecified occlusion or stenosis of bilateral middle arteries',
  'I63519 Cerebral infarction due to unspecified occlusion or stenosis of unspecified middle cerebral artery',
  'I63521 Cerebral infarction due to unspecified occlusion or stenosis of right anterior cerebral artery',
  'I63522 Cerebral infarction due to unspecified occlusion or stenosis of left anterior cerebral artery',
  'I63523 Cerebral infarction due to unspecified occlusion or stenosis of bilateral anterior arteries',
  'I63529 Cerebral infarction due to unspecified occlusion or stenosis of unspecified anterior cerebral',
  'artery',
  'I63531 Cerebral infarction due to unspecified occlusion or stenosis of right posterior cerebral artery',
  'I63532 Cerebral infarction due to unspecified occlusion or stenosis of left posterior cerebral artery',
  'I63533 Cerebral infarction due to unspecified occlusion or stenosis of bilateral posterior arteries',
  'I63539 Cerebral infarction due to unspecified occlusion or stenosis of unspecified posterior cerebral',
  'artery',
  'I63541 Cerebral infarction due to unspecified occlusion or stenosis of right cerebellar artery',
  'I63542 Cerebral infarction due to unspecified occlusion or stenosis of left cerebellar artery',
  'I63543 Cerebral infarction due to unspecified occlusion or stenosis of bilateral cerebellar arteries',
  'I63549 Cerebral infarction due to unspecified occlusion or stenosis of unspecified cerebellar artery',
  'I6359 Cerebral infarction due to unspecified occlusion or stenosis of other cerebral artery',
  'I636 Cerebral infarction due to cerebral venous thrombosis, nonpyogenic',
  'I638 Other cerebral infarction',
  'I639 Cerebral infarction, unspecified',
  'I6501 Occlusion and stenosis of right vertebral artery',
  'I6502 Occlusion and stenosis of left vertebral artery',
  'I6503 Occlusion and stenosis of bilateral vertebral arteries',
  'I6509 Occlusion and stenosis of unspecified vertebral artery',
  'I651 Occlusion and stenosis of basilar artery',
  'I6521 Occlusion and stenosis of right carotid artery',
  'I6522 Occlusion and stenosis of left carotid artery',
  'I6523 Occlusion and stenosis of bilateral carotid arteries',
  'I6529 Occlusion and stenosis of unspecified carotid artery',
  'I658 Occlusion and stenosis of other precerebral arteries',
  'I659 Occlusion and stenosis of unspecified precerebral artery',
  'I6601 Occlusion and stenosis of right middle cerebral artery',
  'I6602 Occlusion and stenosis of left middle cerebral artery',
  'I6603 Occlusion and stenosis of bilateral middle cerebral arteries',
  'I6609 Occlusion and stenosis of unspecified middle cerebral artery',
  'I6611 Occlusion and stenosis of right anterior cerebral artery',
  'I6612 Occlusion and stenosis of left anterior cerebral artery',
  'I6613 Occlusion and stenosis of bilateral anterior cerebral arteries',
  'I6619 Occlusion and stenosis of unspecified anterior cerebral artery',
  'I6621 Occlusion and stenosis of right posterior cerebral artery',
  'I6622 Occlusion and stenosis of left posterior cerebral artery',
  'I6623 Occlusion and stenosis of bilateral posterior cerebral arteries',
  'I6629 Occlusion and stenosis of unspecified posterior cerebral artery',
  'I663 Occlusion and stenosis of cerebellar arteries',
  'I668 Occlusion and stenosis of other cerebral arteries',
  'I669 Occlusion and stenosis of unspecified cerebral artery',
  'I670 Dissection of cerebral arteries, nonruptured',
  'I671 Cerebral aneurysm, nonruptured',
  'I672 Cerebral atherosclerosis',
  'I673 Progressive vascular leukoencephalopathy',
  'I674 Hypertensive encephalopathy',
  'I675 Moyamoya disease',
  'I676 Nonpyogenic thrombosis of intracranial venous system',
  'I677 Cerebral arteritis, not elsewhere classified',
  'I6781 Acute cerebrovascular insufficiency',
  'I6782 Cerebral ischemia',
  'I6783 Posterior reversible encephalopathy syndrome',
  'I67841 Reversible cerebrovascular vasoconstriction syndrome',
  'I67848 Other cerebrovascular vasospasm and vasoconstriction',
  'I6789 Other cerebrovascular disease',
  'I679 Cerebrovascular disease, unspecified',
  'I680 Cerebral amyloid angiopathy',
  'I682 Cerebral arteritis in other diseases classified elsewhere',
  'I688 Other cerebrovascular disorders in diseases classified elsewhere',
  'I6900 Unspecified sequelae of nontraumatic subarachnoid hemorrhage',
  'I69010 Attention and concentration deficit following nontraumatic subarachnoid hemorrhage',
  'I69011 Memory deficit following nontraumatic subarachnoid hemorrhage',
  'I69012 Visuospatial deficit and spatial neglect following nontraumatic subarachnoid hemorrhage',
  'I69013 Psychomotor deficit following nontraumatic subarachnoid hemorrhage',
  'I69014 Frontal lobe and executive function deficit following nontraumatic subarachnoid hemorrhage',
  'I69015 Cognitive social or emotional deficit following nontraumatic subarachnoid hemorrhage',
  'I69018 Other symptoms and signs involving cognitive functions following nontraumatic subarachnoid',
  'hemorrhage',
  'I69019 Unspecified symptoms and signs involving cognitive functions following nontraumatic',
  'subarachnoid hemorrhage',
  'I69020 Aphasia following nontraumatic subarachnoid hemorrhage',
  'I69021 Dysphasia following nontraumatic subarachnoid hemorrhage',
  'I69022 Dysarthria following nontraumatic subarachnoid hemorrhage',
  'I69023 Fluency disorder following nontraumatic subarachnoid hemorrhage',
  'I69028 Other speech and language deficits following nontraumatic subarachnoid hemorrhage',
  'I69031 Monoplegia of upper limb following nontraumatic subarachnoid hemorrhage affecting right',
  'dominant side',
  'I69032 Monoplegia of upper limb following nontraumatic subarachnoid hemorrhage affecting left',
  'dominant side',
  'I69033 Monoplegia of upper limb following nontraumatic subarachnoid hemorrhage affecting right',
  'non-dominant side',
  'I69034 Monoplegia of upper limb following nontraumatic subarachnoid hemorrhage affecting left nondominant side',
  'I69039 Monoplegia of upper limb following nontraumatic subarachnoid hemorrhage affecting',
  'unspecified side',
  'I69041 Monoplegia of lower limb following nontraumatic subarachnoid hemorrhage affecting right',
  'dominant side',
  'I69042 Monoplegia of lower limb following nontraumatic subarachnoid hemorrhage affecting left',
  'dominant side',
  'I69043 Monoplegia of lower limb following nontraumatic subarachnoid hemorrhage affecting right nondominant side',
  'I69044 Monoplegia of lower limb following nontraumatic subarachnoid hemorrhage affecting left nondominant side',
  'I69049 Monoplegia of lower limb following nontraumatic subarachnoid hemorrhage affecting',
  'unspecified side',
  'I69051 Hemiplegia and hemiparesis following nontraumatic subarachnoid hemorrhage affecting right',
  'dominant side',
  'I69052 Hemiplegia and hemiparesis following nontraumatic subarachnoid hemorrhage affecting left',
  'dominant side',
  'I69053 Hemiplegia and hemiparesis following nontraumatic subarachnoid hemorrhage affecting right',
  'non-dominant side',
  'I69054 Hemiplegia and hemiparesis following nontraumatic subarachnoid hemorrhage affecting left',
  'non-dominant side',
  'I69059 Hemiplegia and hemiparesis following nontraumatic subarachnoid hemorrhage affecting',
  'unspecified side',
  'I69061 Other paralytic syndrome following nontraumatic subarachnoid hemorrhage affecting right',
  'dominant side',
  'I69062 Other paralytic syndrome following nontraumatic subarachnoid hemorrhage affecting left',
  'dominant side',
  'I69063 Other paralytic syndrome following nontraumatic subarachnoid hemorrhage affecting right nondominant side',
  'I69064 Other paralytic syndrome following nontraumatic subarachnoid hemorrhage affecting left nondominant side',
  'I69065 Other paralytic syndrome following nontraumatic subarachnoid hemorrhage, bilateral',
  'I69069 Other paralytic syndrome following nontraumatic subarachnoid hemorrhage affecting',
  'unspecified side',
  'I69090 Apraxia following nontraumatic subarachnoid hemorrhage',
  'I69091 Dysphagia following nontraumatic subarachnoid hemorrhage',
  'I69092 Facial weakness following nontraumatic subarachnoid hemorrhage',
  'I69093 Ataxia following nontraumatic subarachnoid hemorrhage',
  'I69098 Other sequelae following nontraumatic subarachnoid hemorrhage',
  'I6910 Unspecified sequelae of nontraumatic intracerebral hemorrhage',
  'I69110 Attention and concentration deficit following nontraumatic intracerebral hemorrhage',
  'I69111 Memory deficit following nontraumatic intracerebral hemorrhage',
  'I69112 Visuospatial deficit and spatial neglect following nontraumatic intracerebral hemorrhage',
  'I69113 Psychomotor deficit following nontraumatic intracerebral hemorrhage',
  'I69114 Frontal lobe and executive function deficit following nontraumatic intracerebral hemorrhage',
  'I69115 Cognitive social or emotional deficit following nontraumatic intracerebral hemorrhage',
  'I69118 Other symptoms and signs involving cognitive functions following nontraumatic intracerebral',
  'hemorrhage',
  'I69119 Unspecified symptoms and signs involving cognitive functions following nontraumatic',
  'intracerebral hemorrhage',
  'I69120 Aphasia following nontraumatic intracerebral hemorrhage',
  'I69121 Dysphasia following nontraumatic intracerebral hemorrhage',
  'I69122 Dysarthria following nontraumatic intracerebral hemorrhage',
  'I69123 Fluency disorder following nontraumatic intracerebral hemorrhage',
  'I69128 Other speech and language deficits following nontraumatic intracerebral hemorrhage',
  'I69131 Monoplegia of upper limb following nontraumatic intracerebral hemorrhage affecting right',
  'dominant side',
  'I69132 Monoplegia of upper limb following nontraumatic intracerebral hemorrhage affecting left',
  'dominant side',
  'I69133 Monoplegia of upper limb following nontraumatic intracerebral hemorrhage affecting right nondominant side',
  'I69134 Monoplegia of upper limb following nontraumatic intracerebral hemorrhage affecting left nondominant side',
  'I69139 Monoplegia of upper limb following nontraumatic intracerebral hemorrhage affecting',
  'unspecified side',
  'I69141 Monoplegia of lower limb following nontraumatic intracerebral hemorrhage affecting right',
  'dominant side',
  'I69142 Monoplegia of lower limb following nontraumatic intracerebral hemorrhage affecting left',
  'dominant side',
  'I69143 Monoplegia of lower limb following nontraumatic intracerebral hemorrhage affecting right nondominant side',
  'I69144 Monoplegia of lower limb following nontraumatic intracerebral hemorrhage affecting left nondominant side',
  'I69149 Monoplegia of lower limb following nontraumatic intracerebral hemorrhage affecting',
  'unspecified side',
  'I69151 Hemiplegia and hemiparesis following nontraumatic intracerebral hemorrhage affecting right',
  'dominant side',
  'I69152 Hemiplegia and hemiparesis following nontraumatic intracerebral hemorrhage affecting left',
  'dominant side',
  'I69153 Hemiplegia and hemiparesis following nontraumatic intracerebral hemorrhage affecting right',
  'non-dominant side',
  'I69154 Hemiplegia and hemiparesis following nontraumatic intracerebral hemorrhage affecting left',
  'non-dominant side',
  'I69159 Hemiplegia and hemiparesis following nontraumatic intracerebral hemorrhage affecting',
  'unspecified side',
  'I69161 Other paralytic syndrome following nontraumatic intracerebral hemorrhage affecting right',
  'dominant side',
  'I69162 Other paralytic syndrome following nontraumatic intracerebral hemorrhage affecting left',
  'dominant side',
  'I69163 Other paralytic syndrome following nontraumatic intracerebral hemorrhage affecting right nondominant side',
  'I69164 Other paralytic syndrome following nontraumatic intracerebral hemorrhage affecting left nondominant side',
  'I69165 Other paralytic syndrome following nontraumatic intracerebral hemorrhage, bilateral',
  'I69169 Other paralytic syndrome following nontraumatic intracerebral hemorrhage affecting',
  'unspecified side',
  'I69190 Apraxia following nontraumatic intracerebral hemorrhage',
  'I69191 Dysphagia following nontraumatic intracerebral hemorrhage',
  'I69192 Facial weakness following nontraumatic intracerebral hemorrhage',
  'I69193 Ataxia following nontraumatic intracerebral hemorrhage',
  'I69198 Other sequelae of nontraumatic intracerebral hemorrhage',
  'I6920 Unspecified sequelae of other nontraumatic intracranial hemorrhage',
  'I69210 Attention and concentration deficit following other nontraumatic intracranial hemorrhage',
  'I69211 Memory deficit following other nontraumatic intracranial hemorrhage',
  'I69212 Visuospatial deficit and spatial neglect following other nontraumatic intracranial hemorrhage',
  'I69213 Psychomotor deficit following other nontraumatic intracranial hemorrhage',
  'I69214 Frontal lobe and executive function deficit following other nontraumatic intracranial',
  'hemorrhage',
  'I69215 Cognitive social or emotional deficit following other nontraumatic intracranial hemorrhage',
  'I69218 Other symptoms and signs involving cognitive functions following other nontraumatic',
  'intracranial hemorrhage',
  'I69219 Unspecified symptoms and signs involving cognitive functions following other nontraumatic',
  'intracranial hemorrhage',
  'I69220 Aphasia following other nontraumatic intracranial hemorrhage',
  'I69221 Dysphasia following other nontraumatic intracranial hemorrhage',
  'I69222 Dysarthria following other nontraumatic intracranial hemorrhage',
  'I69223 Fluency disorder following other nontraumatic intracranial hemorrhage',
  'I69228 Other speech and language deficits following other nontraumatic intracranial hemorrhage',
  'I69231 Monoplegia of upper limb following other nontraumatic intracranial hemorrhage affecting right',
  'dominant side',
  'I69232 Monoplegia of upper limb following other nontraumatic intracranial hemorrhage affecting left',
  'dominant side',
  'I69233 Monoplegia of upper limb following other nontraumatic intracranial hemorrhage affecting right',
  'non-dominant side',
  'I69234 Monoplegia of upper limb following other nontraumatic intracranial hemorrhage affecting left',
  'non-dominant side',
  'I69239 Monoplegia of upper limb following other nontraumatic intracranial hemorrhage affecting',
  'unspecified side',
  'I69241 Monoplegia of lower limb following other nontraumatic intracranial hemorrhage affecting right',
  'dominant side',
  'I69242 Monoplegia of lower limb following other nontraumatic intracranial hemorrhage affecting left',
  'dominant side',
  'I69243 Monoplegia of lower limb following other nontraumatic intracranial hemorrhage affecting right',
  'non-dominant side',
  'I69244 Monoplegia of lower limb following other nontraumatic intracranial hemorrhage affecting left',
  'non-dominant side',
  'I69249 Monoplegia of lower limb following other nontraumatic intracranial hemorrhage affecting',
  'unspecified side',
  'I69251 Hemiplegia and hemiparesis following other nontraumatic intracranial hemorrhage affecting',
  'right dominant side',
  'I69252 Hemiplegia and hemiparesis following other nontraumatic intracranial hemorrhage affecting left',
  'dominant side',
  'I69253 Hemiplegia and hemiparesis following other nontraumatic intracranial hemorrhage affecting',
  'right non-dominant side',
  'I69254 Hemiplegia and hemiparesis following other nontraumatic intracranial hemorrhage affecting left',
  'non-dominant side',
  'I69259 Hemiplegia and hemiparesis following other nontraumatic intracranial hemorrhage affecting',
  'unspecified side',
  'I69261 Other paralytic syndrome following other nontraumatic intracranial hemorrhage affecting right',
  'dominant side',
  'I69262 Other paralytic syndrome following other nontraumatic intracranial hemorrhage affecting left',
  'dominant side',
  'I69263 Other paralytic syndrome following other nontraumatic intracranial hemorrhage affecting right',
  'non-dominant side',
  'I69264 Other paralytic syndrome following other nontraumatic intracranial hemorrhage affecting left',
  'non-dominant side',
  'I69265 Other paralytic syndrome following other nontraumatic intracranial hemorrhage, bilateral',
  'I69269 Other paralytic syndrome following other nontraumatic intracranial hemorrhage affecting',
  'unspecified side',
  'I69290 Apraxia following other nontraumatic intracranial hemorrhage',
  'I69291 Dysphagia following other nontraumatic intracranial hemorrhage',
  'I69292 Facial weakness following other nontraumatic intracranial hemorrhage',
  'I69293 Ataxia following other nontraumatic intracranial hemorrhage',
  'I69298 Other sequelae of other nontraumatic intracranial hemorrhage',
  'I6930 Unspecified sequelae of cerebral infarction',
  'I69310 Attention and concentration deficit following cerebral infarction',
  'I69311 Memory deficit following cerebral infarction',
  'I69312 Visuospatial deficit and spatial neglect following cerebral infarction',
  'I69313 Psychomotor deficit following cerebral infarction',
  'I69314 Frontal lobe and executive function deficit following cerebral infarction',
  'I69315 Cognitive social or emotional deficit following cerebral infarction',
  'I69318 Other symptoms and signs involving cognitive functions following cerebral infarction',
  'I69319 Unspecified symptoms and signs involving cognitive functions following cerebral infarction',
  'I69320 Aphasia following cerebral infarction',
  'I69321 Dysphasia following cerebral infarction',
  'I69322 Dysarthria following cerebral infarction',
  'I69323 Fluency disorder following cerebral infarction',
  'I69328 Other speech and language deficits following cerebral infarction',
  'I69331 Monoplegia of upper limb following cerebral infarction affecting right dominant side',
  'I69332 Monoplegia of upper limb following cerebral infarction affecting left dominant side',
  'I69333 Monoplegia of upper limb following cerebral infarction affecting right non-dominant side',
  'I69334 Monoplegia of upper limb following cerebral infarction affecting left non-dominant side',
  'I69339 Monoplegia of upper limb following cerebral infarction affecting unspecified side',
  'I69341 Monoplegia of lower limb following cerebral infarction affecting right dominant side',
  'I69342 Monoplegia of lower limb following cerebral infarction affecting left dominant side',
  'I69343 Monoplegia of lower limb following cerebral infarction affecting right non-dominant side',
  'I69344 Monoplegia of lower limb following cerebral infarction affecting left non-dominant side',
  'I69349 Monoplegia of lower limb following cerebral infarction affecting unspecified side',
  'I69351 Hemiplegia and hemiparesis following cerebral infarction affecting right dominant side',
  'I69352 Hemiplegia and hemiparesis following cerebral infarction affecting left dominant side',
  'I69353 Hemiplegia and hemiparesis following cerebral infarction affecting right non-dominant side',
  'I69354 Hemiplegia and hemiparesis following cerebral infarction affecting left non-dominant side',
  'I69359 Hemiplegia and hemiparesis following cerebral infarction affecting unspecified side',
  'I69361 Other paralytic syndrome following cerebral infarction affecting right dominant side',
  'I69362 Other paralytic syndrome following cerebral infarction affecting left dominant side',
  'I69363 Other paralytic syndrome following cerebral infarction affecting right non-dominant side',
  'I69364 Other paralytic syndrome following cerebral infarction affecting left non-dominant side',
  'I69365 Other paralytic syndrome following cerebral infarction, bilateral',
  'I69369 Other paralytic syndrome following cerebral infarction affecting unspecified side',
  'I69390 Apraxia following cerebral infarction',
  'I69391 Dysphagia following cerebral infarction',
  'I69392 Facial weakness following cerebral infarction',
  'I69393 Ataxia following cerebral infarction',
  'I69398 Other sequelae of cerebral infarction',
  'I6980 Unspecified sequelae of other cerebrovascular disease',
  'I69810 Attention and concentration deficit following other cerebrovascular disease',
  'I69811 Memory deficit following other cerebrovascular disease',
  'I69812 Visuospatial deficit and spatial neglect following other cerebrovascular disease',
  'I69813 Psychomotor deficit following other cerebrovascular disease',
  'I69814 Frontal lobe and executive function deficit following other cerebrovascular disease',
  'I69815 Cognitive social or emotional deficit following other cerebrovascular disease',
  'I69818 Other symptoms and signs involving cognitive functions following other cerebrovascular disease',
  'I69819 Unspecified symptoms and signs involving cognitive functions following other cerebrovascular',
  'disease',
  'I69820 Aphasia following other cerebrovascular disease',
  'I69821 Dysphasia following other cerebrovascular disease',
  'I69822 Dysarthria following other cerebrovascular disease',
  'I69823 Fluency disorder following other cerebrovascular disease',
  'I69828 Other speech and language deficits following other cerebrovascular disease',
  'I69831 Monoplegia of upper limb following other cerebrovascular disease affecting right dominant side',
  'I69832 Monoplegia of upper limb following other cerebrovascular disease affecting left dominant side',
  'I69833 Monoplegia of upper limb following other cerebrovascular disease affecting right non-dominant',
  'side',
  'I69834 Monoplegia of upper limb following other cerebrovascular disease affecting left non-dominant',
  'side',
  'I69839 Monoplegia of upper limb following other cerebrovascular disease affecting unspecified side',
  'I69841 Monoplegia of lower limb following other cerebrovascular disease affecting right dominant side',
  'I69842 Monoplegia of lower limb following other cerebrovascular disease affecting left dominant side',
  'I69843 Monoplegia of lower limb following other cerebrovascular disease affecting right non-dominant',
  'side',
  'I69844 Monoplegia of lower limb following other cerebrovascular disease affecting left non-dominant',
  'side',
  'I69849 Monoplegia of lower limb following other cerebrovascular disease affecting unspecified side',
  'I69851 Hemiplegia and hemiparesis following other cerebrovascular disease affecting right dominant',
  'side',
  'I69852 Hemiplegia and hemiparesis following other cerebrovascular disease affecting left dominant',
  'side',
  'I69853 Hemiplegia and hemiparesis following other cerebrovascular disease affecting right nondominant side',
  'I69854 Hemiplegia and hemiparesis following other cerebrovascular disease affecting left nondominant side',
  'I69859 Hemiplegia and hemiparesis following other cerebrovascular disease affecting unspecified side',
  'I69861 Other paralytic syndrome following other cerebrovascular disease affecting right dominant side',
  'I69862 Other paralytic syndrome following other cerebrovascular disease affecting left dominant side',
  'I69863 Other paralytic syndrome following other cerebrovascular disease affecting right non-dominant',
  'side',
  'I69864 Other paralytic syndrome following other cerebrovascular disease affecting left non-dominant',
  'side',
  'I69865 Other paralytic syndrome following other cerebrovascular disease, bilateral',
  'I69869 Other paralytic syndrome following other cerebrovascular disease affecting unspecified side',
  'I69890 Apraxia following other cerebrovascular disease',
  'I69891 Dysphagia following other cerebrovascular disease',
  'I69892 Facial weakness following other cerebrovascular disease',
  'I69893 Ataxia following other cerebrovascular disease',
  'I69898 Other sequelae of other cerebrovascular disease',
  'I6990 Unspecified sequelae of unspecified cerebrovascular disease',
  'I69910 Attention and concentration deficit following unspecified cerebrovascular disease',
  'I69911 Memory deficit following unspecified cerebrovascular disease',
  'I69912 Visuospatial deficit and spatial neglect following unspecified cerebrovascular disease',
  'I69913 Psychomotor deficit following unspecified cerebrovascular disease',
  'I69914 Frontal lobe and executive function deficit following unspecified cerebrovascular disease',
  'I69915 Cognitive social or emotional deficit following unspecified cerebrovascular disease',
  'I69918 Other symptoms and signs involving cognitive functions following unspecified cerebrovascular',
  'disease',
  'I69919 Unspecified symptoms and signs involving cognitive functions following unspecified',
  'cerebrovascular disease',
  'I69920 Aphasia following unspecified cerebrovascular disease',
  'I69921 Dysphasia following unspecified cerebrovascular disease',
  'I69922 Dysarthria following unspecified cerebrovascular disease',
  'I69923 Fluency disorder following unspecified cerebrovascular disease',
  'I69928 Other speech and language deficits following unspecified cerebrovascular disease',
  'I69931 Monoplegia of upper limb following unspecified cerebrovascular disease affecting right',
  'dominant side',
  'I69932 Monoplegia of upper limb following unspecified cerebrovascular disease affecting left dominant',
  'side',
  'I69933 Monoplegia of upper limb following unspecified cerebrovascular disease affecting right nondominant side',
  'I69934 Monoplegia of upper limb following unspecified cerebrovascular disease affecting left nondominant side',
  'I69939 Monoplegia of upper limb following unspecified cerebrovascular disease affecting unspecified',
  'side',
  'I69941 Monoplegia of lower limb following unspecified cerebrovascular disease affecting right',
  'dominant side',
  'I69942 Monoplegia of lower limb following unspecified cerebrovascular disease affecting left dominant',
  'side',
  'I69943 Monoplegia of lower limb following unspecified cerebrovascular disease affecting right nondominant side',
  'I69944 Monoplegia of lower limb following unspecified cerebrovascular disease affecting left nondominant side',
  'I69949 Monoplegia of lower limb following unspecified cerebrovascular disease affecting unspecified',
  'side',
  'I69951 Hemiplegia and hemiparesis following unspecified cerebrovascular disease affecting right',
  'dominant side',
  'I69952 Hemiplegia and hemiparesis following unspecified cerebrovascular disease affecting left',
  'dominant side',
  'I69953 Hemiplegia and hemiparesis following unspecified cerebrovascular disease affecting right nondominant side',
  'I69954 Hemiplegia and hemiparesis following unspecified cerebrovascular disease affecting left nondominant side',
  'I69959 Hemiplegia and hemiparesis following unspecified cerebrovascular disease affecting unspecified',
  'side',
  'I69961 Other paralytic syndrome following unspecified cerebrovascular disease affecting right',
  'dominant side',
  'I69962 Other paralytic syndrome following unspecified cerebrovascular disease affecting left dominant',
  'side',
  'I69963 Other paralytic syndrome following unspecified cerebrovascular disease affecting right nondominant side',
  'I69964 Other paralytic syndrome following unspecified cerebrovascular disease affecting left nondominant side',
  'I69965 Other paralytic syndrome following unspecified cerebrovascular disease, bilateral',
  'I69969 Other paralytic syndrome following unspecified cerebrovascular disease affecting unspecified',
  'side',
  'I69990 Apraxia following unspecified cerebrovascular disease',
  'I69991 Dysphagia following unspecified cerebrovascular disease',
  'I69992 Facial weakness following unspecified cerebrovascular disease',
  'I69993 Ataxia following unspecified cerebrovascular disease',
  'I69998 Other sequelae following unspecified cerebrovascular disease',
  'I700 Atherosclerosis of aorta',
  'I701 Atherosclerosis of renal artery',
  'I70201 Unspecified atherosclerosis of native arteries of extremities, right leg',
  'I70202 Unspecified atherosclerosis of native arteries of extremities, left leg',
  'I70203 Unspecified atherosclerosis of native arteries of extremities, bilateral legs',
  'I70208 Unspecified atherosclerosis of native arteries of extremities, other extremity',
  'I70209 Unspecified atherosclerosis of native arteries of extremities, unspecified extremity',
  'I70211 Atherosclerosis of native arteries of extremities with intermittent claudication, right leg',
  'I70212 Atherosclerosis of native arteries of extremities with intermittent claudication, left leg',
  'I70213 Atherosclerosis of native arteries of extremities with intermittent claudication, bilateral legs',
  'I70218 Atherosclerosis of native arteries of extremities with intermittent claudication, other extremity',
  'I70219 Atherosclerosis of native arteries of extremities with intermittent claudication, unspecified',
  'extremity',
  'I70221 Atherosclerosis of native arteries of extremities with rest pain, right leg',
  'I70222 Atherosclerosis of native arteries of extremities with rest pain, left leg',
  'I70223 Atherosclerosis of native arteries of extremities with rest pain, bilateral legs',
  'I70228 Atherosclerosis of native arteries of extremities with rest pain, other extremity',
  'I70229 Atherosclerosis of native arteries of extremities with rest pain, unspecified extremity',
  'I70231 Atherosclerosis of native arteries of right leg with ulceration of thigh',
  'I70232 Atherosclerosis of native arteries of right leg with ulceration of calf',
  'I70233 Atherosclerosis of native arteries of right leg with ulceration of ankle',
  'I70234 Atherosclerosis of native arteries of right leg with ulceration of heel and midfoot',
  'I70235 Atherosclerosis of native arteries of right leg with ulceration of other part of foot',
  'I70238 Atherosclerosis of native arteries of right leg with ulceration of other part of lower right leg',
  'I70239 Atherosclerosis of native arteries of right leg with ulceration of unspecified site',
  'I70241 Atherosclerosis of native arteries of left leg with ulceration of thigh',
  'I70242 Atherosclerosis of native arteries of left leg with ulceration of calf',
  'I70243 Atherosclerosis of native arteries of left leg with ulceration of ankle',
  'I70244 Atherosclerosis of native arteries of left leg with ulceration of heel and midfoot',
  'I70245 Atherosclerosis of native arteries of left leg with ulceration of other part of foot',
  'I70248 Atherosclerosis of native arteries of left leg with ulceration of other part of lower left leg',
  'I70249 Atherosclerosis of native arteries of left leg with ulceration of unspecified site',
  'I7025 Atherosclerosis of native arteries of other extremities with ulceration',
  'I70261 Atherosclerosis of native arteries of extremities with gangrene, right leg',
  'I70262 Atherosclerosis of native arteries of extremities with gangrene, left leg',
  'I70263 Atherosclerosis of native arteries of extremities with gangrene, bilateral legs',
  'I70268 Atherosclerosis of native arteries of extremities with gangrene, other extremity',
  'I70269 Atherosclerosis of native arteries of extremities with gangrene, unspecified extremity',
  'I70291 Other atherosclerosis of native arteries of extremities, right leg',
  'I70292 Other atherosclerosis of native arteries of extremities, left leg',
  'I70293 Other atherosclerosis of native arteries of extremities, bilateral legs',
  'I70298 Other atherosclerosis of native arteries of extremities, other extremity',
  'I70299 Other atherosclerosis of native arteries of extremities, unspecified extremity',
  'I70301 Unspecified atherosclerosis of unspecified type of bypass graft(s) of the extremities, right leg',
  'I70302 Unspecified atherosclerosis of unspecified type of bypass graft(s) of the extremities, left leg',
  'I70303 Unspecified atherosclerosis of unspecified type of bypass graft(s) of the extremities, bilateral',
  'legs',
  'I70308 Unspecified atherosclerosis of unspecified type of bypass graft(s) of the extremities, other',
  'extremity',
  'I70309 Unspecified atherosclerosis of unspecified type of bypass graft(s) of the extremities, unspecified',
  'extremity',
  'I70311 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with intermittent',
  'claudication, right leg',
  'I70312 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with intermittent',
  'claudication, left leg',
  'I70313 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with intermittent',
  'claudication, bilateral legs',
  'I70318 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with intermittent',
  'claudication, other extremity',
  'I70319 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with intermittent',
  'claudication, unspecified extremity',
  'I70321 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with rest pain, right leg',
  'I70322 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with rest pain, left leg',
  'I70323 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with rest pain, bilateral',
  'legs',
  'I70328 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with rest pain, other',
  'extremity',
  'I70329 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with rest pain,',
  'unspecified extremity',
  'I70331 Atherosclerosis of unspecified type of bypass graft(s) of the right leg with ulceration of thigh',
  'I70332 Atherosclerosis of unspecified type of bypass graft(s) of the right leg with ulceration of calf',
  'I70333 Atherosclerosis of unspecified type of bypass graft(s) of the right leg with ulceration of ankle',
  'I70334 Atherosclerosis of unspecified type of bypass graft(s) of the right leg with ulceration of heel and',
  'midfoot',
  'I70335 Atherosclerosis of unspecified type of bypass graft(s) of the right leg with ulceration of other',
  'part of foot',
  'I70338 Atherosclerosis of unspecified type of bypass graft(s) of the right leg with ulceration of other',
  'part of lower leg',
  'I70339 Atherosclerosis of unspecified type of bypass graft(s) of the right leg with ulceration of',
  'unspecified site',
  'I70341 Atherosclerosis of unspecified type of bypass graft(s) of the left leg with ulceration of thigh',
  'I70342 Atherosclerosis of unspecified type of bypass graft(s) of the left leg with ulceration of calf',
  'I70343 Atherosclerosis of unspecified type of bypass graft(s) of the left leg with ulceration of ankle',
  'I70344 Atherosclerosis of unspecified type of bypass graft(s) of the left leg with ulceration of heel and',
  'midfoot',
  'I70345 Atherosclerosis of unspecified type of bypass graft(s) of the left leg with ulceration of other part',
  'of foot',
  'I70348 Atherosclerosis of unspecified type of bypass graft(s) of the left leg with ulceration of other part',
  'of lower leg',
  'I70349 Atherosclerosis of unspecified type of bypass graft(s) of the left leg with ulceration of',
  'unspecified site',
  'I7035 Atherosclerosis of unspecified type of bypass graft(s) of other extremity with ulceration',
  'I70361 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with gangrene, right leg',
  'I70362 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with gangrene, left leg',
  'I70363 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with gangrene, bilateral',
  'legs',
  'I70368 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with gangrene, other',
  'extremity',
  'I70369 Atherosclerosis of unspecified type of bypass graft(s) of the extremities with gangrene,',
  'unspecified extremity',
  'I70391 Other atherosclerosis of unspecified type of bypass graft(s) of the extremities, right leg',
  'I70392 Other atherosclerosis of unspecified type of bypass graft(s) of the extremities, left leg',
  'I70393 Other atherosclerosis of unspecified type of bypass graft(s) of the extremities, bilateral legs',
  'I70398 Other atherosclerosis of unspecified type of bypass graft(s) of the extremities, other extremity',
  'I70399 Other atherosclerosis of unspecified type of bypass graft(s) of the extremities, unspecified',
  'extremity',
  'I70401 Unspecified atherosclerosis of autologous vein bypass graft(s) of the extremities, right leg',
  'I70402 Unspecified atherosclerosis of autologous vein bypass graft(s) of the extremities, left leg',
  'I70403 Unspecified atherosclerosis of autologous vein bypass graft(s) of the extremities, bilateral legs',
  'I70408 Unspecified atherosclerosis of autologous vein bypass graft(s) of the extremities, other',
  'extremity',
  'I70409 Unspecified atherosclerosis of autologous vein bypass graft(s) of the extremities, unspecified',
  'extremity',
  'I70411 Atherosclerosis of autologous vein bypass graft(s) of the extremities with intermittent',
  'claudication, right leg',
  'I70412 Atherosclerosis of autologous vein bypass graft(s) of the extremities with intermittent',
  'claudication, left leg',
  'I70413 Atherosclerosis of autologous vein bypass graft(s) of the extremities with intermittent',
  'claudication, bilateral legs',
  'I70418 Atherosclerosis of autologous vein bypass graft(s) of the extremities with intermittent',
  'claudication, other extremity',
  'I70419 Atherosclerosis of autologous vein bypass graft(s) of the extremities with intermittent',
  'claudication, unspecified extremity',
  'I70421 Atherosclerosis of autologous vein bypass graft(s) of the extremities with rest pain, right leg',
  'I70422 Atherosclerosis of autologous vein bypass graft(s) of the extremities with rest pain, left leg',
  'I70423 Atherosclerosis of autologous vein bypass graft(s) of the extremities with rest pain, bilateral legs',
  'I70428 Atherosclerosis of autologous vein bypass graft(s) of the extremities with rest pain, other',
  'extremity',
  'I70429 Atherosclerosis of autologous vein bypass graft(s) of the extremities with rest pain, unspecified',
  'extremity',
  'I70431 Atherosclerosis of autologous vein bypass graft(s) of the right leg with ulceration of thigh',
  'I70432 Atherosclerosis of autologous vein bypass graft(s) of the right leg with ulceration of calf',
  'I70433 Atherosclerosis of autologous vein bypass graft(s) of the right leg with ulceration of ankle',
  'I70434 Atherosclerosis of autologous vein bypass graft(s) of the right leg with ulceration of heel and',
  'midfoot',
  'I70435 Atherosclerosis of autologous vein bypass graft(s) of the right leg with ulceration of other part of',
  'foot',
  'I70438 Atherosclerosis of autologous vein bypass graft(s) of the right leg with ulceration of other part of',
  'lower leg',
  'I70439 Atherosclerosis of autologous vein bypass graft(s) of the right leg with ulceration of unspecified',
  'site',
  'I70441 Atherosclerosis of autologous vein bypass graft(s) of the left leg with ulceration of thigh',
  'I70442 Atherosclerosis of autologous vein bypass graft(s) of the left leg with ulceration of calf',
  'I70443 Atherosclerosis of autologous vein bypass graft(s) of the left leg with ulceration of ankle',
  'I70444 Atherosclerosis of autologous vein bypass graft(s) of the left leg with ulceration of heel and',
  'midfoot',
  'I70445 Atherosclerosis of autologous vein bypass graft(s) of the left leg with ulceration of other part of',
  'foot',
  'I70448 Atherosclerosis of autologous vein bypass graft(s) of the left leg with ulceration of other part of',
  'lower leg',
  'I70449 Atherosclerosis of autologous vein bypass graft(s) of the left leg with ulceration of unspecified',
  'site',
  'I7045 Atherosclerosis of autologous vein bypass graft(s) of other extremity with ulceration',
  'I70461 Atherosclerosis of autologous vein bypass graft(s) of the extremities with gangrene, right leg',
  'I70462 Atherosclerosis of autologous vein bypass graft(s) of the extremities with gangrene, left leg',
  'I70463 Atherosclerosis of autologous vein bypass graft(s) of the extremities with gangrene, bilateral',
  'legs',
  'I70468 Atherosclerosis of autologous vein bypass graft(s) of the extremities with gangrene, other',
  'extremity',
  'I70469 Atherosclerosis of autologous vein bypass graft(s) of the extremities with gangrene, unspecified',
  'extremity',
  'I70491 Other atherosclerosis of autologous vein bypass graft(s) of the extremities, right leg',
  'I70492 Other atherosclerosis of autologous vein bypass graft(s) of the extremities, left leg',
  'I70493 Other atherosclerosis of autologous vein bypass graft(s) of the extremities, bilateral legs',
  'I70498 Other atherosclerosis of autologous vein bypass graft(s) of the extremities, other extremity',
  'I70499 Other atherosclerosis of autologous vein bypass graft(s) of the extremities, unspecified',
  'extremity',
  'I70501 Unspecified atherosclerosis of nonautologous biological bypass graft(s) of the extremities, right',
  'leg',
  'I70502 Unspecified atherosclerosis of nonautologous biological bypass graft(s) of the extremities, left',
  'leg',
  'I70503 Unspecified atherosclerosis of nonautologous biological bypass graft(s) of the extremities,',
  'bilateral legs',
  'I70508 Unspecified atherosclerosis of nonautologous biological bypass graft(s) of the extremities, other',
  'extremity',
  'I70509 Unspecified atherosclerosis of nonautologous biological bypass graft(s) of the extremities,',
  'unspecified extremity',
  'I70511 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with intermittent',
  'claudication, right leg',
  'I70512 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with intermittent',
  'claudication, left leg',
  'I70513 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with intermittent',
  'claudication, bilateral legs',
  'I70518 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with intermittent',
  'claudication, other extremity',
  'I70519 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with intermittent',
  'claudication, unspecified extremity',
  'I70521 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with rest pain,',
  'right leg',
  'I70522 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with rest pain, left',
  'leg',
  'I70523 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with rest pain,',
  'bilateral legs',
  'I70528 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with rest pain,',
  'other extremity',
  'I70529 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with rest pain,',
  'unspecified extremity',
  'I70531 Atherosclerosis of nonautologous biological bypass graft(s) of the right leg with ulceration of',
  'thigh',
  'I70532 Atherosclerosis of nonautologous biological bypass graft(s) of the right leg with ulceration of calf',
  'I70533 Atherosclerosis of nonautologous biological bypass graft(s) of the right leg with ulceration of',
  'ankle',
  'I70534 Atherosclerosis of nonautologous biological bypass graft(s) of the right leg with ulceration of',
  'heel and midfoot',
  'I70535 Atherosclerosis of nonautologous biological bypass graft(s) of the right leg with ulceration of',
  'other part of foot',
  'I70538 Atherosclerosis of nonautologous biological bypass graft(s) of the right leg with ulceration of',
  'other part of lower leg',
  'I70539 Atherosclerosis of nonautologous biological bypass graft(s) of the right leg with ulceration of',
  'unspecified site',
  'I70541 Atherosclerosis of nonautologous biological bypass graft(s) of the left leg with ulceration of thigh',
  'I70542 Atherosclerosis of nonautologous biological bypass graft(s) of the left leg with ulceration of calf',
  'I70543 Atherosclerosis of nonautologous biological bypass graft(s) of the left leg with ulceration of',
  'ankle',
  'I70544 Atherosclerosis of nonautologous biological bypass graft(s) of the left leg with ulceration of heel',
  'and midfoot',
  'I70545 Atherosclerosis of nonautologous biological bypass graft(s) of the left leg with ulceration of',
  'other part of foot',
  'I70548 Atherosclerosis of nonautologous biological bypass graft(s) of the left leg with ulceration of',
  'other part of lower leg',
  'I70549 Atherosclerosis of nonautologous biological bypass graft(s) of the left leg with ulceration of',
  'unspecified site',
  'I7055 Atherosclerosis of nonautologous biological bypass graft(s) of other extremity with ulceration',
  'I70561 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with gangrene,',
  'right leg',
  'I70562 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with gangrene, left',
  'leg',
  'I70563 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with gangrene,',
  'bilateral legs',
  'I70568 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with gangrene,',
  'other extremity',
  'I70569 Atherosclerosis of nonautologous biological bypass graft(s) of the extremities with gangrene,',
  'unspecified extremity',
  'I70591 Other atherosclerosis of nonautologous biological bypass graft(s) of the extremities, right leg',
  'I70592 Other atherosclerosis of nonautologous biological bypass graft(s) of the extremities, left leg',
  'I70593 Other atherosclerosis of nonautologous biological bypass graft(s) of the extremities, bilateral',
  'legs',
  'I70598 Other atherosclerosis of nonautologous biological bypass graft(s) of the extremities, other',
  'extremity',
  'I70599 Other atherosclerosis of nonautologous biological bypass graft(s) of the extremities, unspecified',
  'extremity',
  'I70601 Unspecified atherosclerosis of nonbiological bypass graft(s) of the extremities, right leg',
  'I70602 Unspecified atherosclerosis of nonbiological bypass graft(s) of the extremities, left leg',
  'I70603 Unspecified atherosclerosis of nonbiological bypass graft(s) of the extremities, bilateral legs',
  'I70608 Unspecified atherosclerosis of nonbiological bypass graft(s) of the extremities, other extremity',
  'I70609 Unspecified atherosclerosis of nonbiological bypass graft(s) of the extremities, unspecified',
  'extremity',
  'I70611 Atherosclerosis of nonbiological bypass graft(s) of the extremities with intermittent claudication,',
  'right leg',
  'I70612 Atherosclerosis of nonbiological bypass graft(s) of the extremities with intermittent claudication,',
  'left leg',
  'I70613 Atherosclerosis of nonbiological bypass graft(s) of the extremities with intermittent claudication,',
  'bilateral legs',
  'I70618 Atherosclerosis of nonbiological bypass graft(s) of the extremities with intermittent claudication,',
  'other extremity',
  'I70619 Atherosclerosis of nonbiological bypass graft(s) of the extremities with intermittent claudication,',
  'unspecified extremity',
  'I70621 Atherosclerosis of nonbiological bypass graft(s) of the extremities with rest pain, right leg',
  'I70622 Atherosclerosis of nonbiological bypass graft(s) of the extremities with rest pain, left leg',
  'I70623 Atherosclerosis of nonbiological bypass graft(s) of the extremities with rest pain, bilateral legs',
  'I70628 Atherosclerosis of nonbiological bypass graft(s) of the extremities with rest pain, other extremity',
  'I70629 Atherosclerosis of nonbiological bypass graft(s) of the extremities with rest pain, unspecified',
  'extremity',
  'I70631 Atherosclerosis of nonbiological bypass graft(s) of the right leg with ulceration of thigh',
  'I70632 Atherosclerosis of nonbiological bypass graft(s) of the right leg with ulceration of calf',
  'I70633 Atherosclerosis of nonbiological bypass graft(s) of the right leg with ulceration of ankle',
  'I70634 Atherosclerosis of nonbiological bypass graft(s) of the right leg with ulceration of heel and',
  'midfoot',
  'I70635 Atherosclerosis of nonbiological bypass graft(s) of the right leg with ulceration of other part of',
  'foot',
  'I70638 Atherosclerosis of nonbiological bypass graft(s) of the right leg with ulceration of other part of',
  'lower leg',
  'I70639 Atherosclerosis of nonbiological bypass graft(s) of the right leg with ulceration of unspecified',
  'site',
  'I70641 Atherosclerosis of nonbiological bypass graft(s) of the left leg with ulceration of thigh',
  'I70642 Atherosclerosis of nonbiological bypass graft(s) of the left leg with ulceration of calf',
  'I70643 Atherosclerosis of nonbiological bypass graft(s) of the left leg with ulceration of ankle',
  'I70644 Atherosclerosis of nonbiological bypass graft(s) of the left leg with ulceration of heel and',
  'midfoot',
  'I70645 Atherosclerosis of nonbiological bypass graft(s) of the left leg with ulceration of other part of',
  'foot',
  'I70648 Atherosclerosis of nonbiological bypass graft(s) of the left leg with ulceration of other part of',
  'lower leg',
  'I70649 Atherosclerosis of nonbiological bypass graft(s) of the left leg with ulceration of unspecified site',
  'I7065 Atherosclerosis of nonbiological bypass graft(s) of other extremity with ulceration',
  'I70661 Atherosclerosis of nonbiological bypass graft(s) of the extremities with gangrene, right leg',
  'I70662 Atherosclerosis of nonbiological bypass graft(s) of the extremities with gangrene, left leg',
  'I70663 Atherosclerosis of nonbiological bypass graft(s) of the extremities with gangrene, bilateral legs',
  'I70668 Atherosclerosis of nonbiological bypass graft(s) of the extremities with gangrene, other',
  'extremity',
  'I70669 Atherosclerosis of nonbiological bypass graft(s) of the extremities with gangrene, unspecified',
  'extremity',
  'I70691 Other atherosclerosis of nonbiological bypass graft(s) of the extremities, right leg',
  'I70692 Other atherosclerosis of nonbiological bypass graft(s) of the extremities, left leg',
  'I70693 Other atherosclerosis of nonbiological bypass graft(s) of the extremities, bilateral legs',
  'I70698 Other atherosclerosis of nonbiological bypass graft(s) of the extremities, other extremity',
  'I70699 Other atherosclerosis of nonbiological bypass graft(s) of the extremities, unspecified extremity',
  'I70701 Unspecified atherosclerosis of other type of bypass graft(s) of the extremities, right leg',
  'I70702 Unspecified atherosclerosis of other type of bypass graft(s) of the extremities, left leg',
  'I70703 Unspecified atherosclerosis of other type of bypass graft(s) of the extremities, bilateral legs',
  'I70708 Unspecified atherosclerosis of other type of bypass graft(s) of the extremities, other extremity',
  'I70709 Unspecified atherosclerosis of other type of bypass graft(s) of the extremities, unspecified',
  'extremity',
  'I70711 Atherosclerosis of other type of bypass graft(s) of the extremities with intermittent claudication,',
  'right leg',
  'I70712 Atherosclerosis of other type of bypass graft(s) of the extremities with intermittent claudication,',
  'left leg',
  'I70713 Atherosclerosis of other type of bypass graft(s) of the extremities with intermittent claudication,',
  'bilateral legs',
  'I70718 Atherosclerosis of other type of bypass graft(s) of the extremities with intermittent claudication,',
  'other extremity',
  'I70719 Atherosclerosis of other type of bypass graft(s) of the extremities with intermittent claudication,',
  'unspecified extremity',
  'I70721 Atherosclerosis of other type of bypass graft(s) of the extremities with rest pain, right leg',
  'I70722 Atherosclerosis of other type of bypass graft(s) of the extremities with rest pain, left leg',
  'I70723 Atherosclerosis of other type of bypass graft(s) of the extremities with rest pain, bilateral legs',
  'I70728 Atherosclerosis of other type of bypass graft(s) of the extremities with rest pain, other extremity',
  'I70729 Atherosclerosis of other type of bypass graft(s) of the extremities with rest pain, unspecified',
  'extremity',
  'I70731 Atherosclerosis of other type of bypass graft(s) of the right leg with ulceration of thigh',
  'I70732 Atherosclerosis of other type of bypass graft(s) of the right leg with ulceration of calf',
  'I70733 Atherosclerosis of other type of bypass graft(s) of the right leg with ulceration of ankle',
  'I70734 Atherosclerosis of other type of bypass graft(s) of the right leg with ulceration of heel and',
  'midfoot',
  'I70735 Atherosclerosis of other type of bypass graft(s) of the right leg with ulceration of other part of',
  'foot',
  'I70738 Atherosclerosis of other type of bypass graft(s) of the right leg with ulceration of other part of',
  'lower leg',
  'I70739 Atherosclerosis of other type of bypass graft(s) of the right leg with ulceration of unspecified site',
  'I70741 Atherosclerosis of other type of bypass graft(s) of the left leg with ulceration of thigh',
  'I70742 Atherosclerosis of other type of bypass graft(s) of the left leg with ulceration of calf',
  'I70743 Atherosclerosis of other type of bypass graft(s) of the left leg with ulceration of ankle',
  'I70744 Atherosclerosis of other type of bypass graft(s) of the left leg with ulceration of heel and',
  'midfoot',
  'I70745 Atherosclerosis of other type of bypass graft(s) of the left leg with ulceration of other part of',
  'foot',
  'I70748 Atherosclerosis of other type of bypass graft(s) of the left leg with ulceration of other part of',
  'lower leg',
  'I70749 Atherosclerosis of other type of bypass graft(s) of the left leg with ulceration of unspecified site',
  'I7075 Atherosclerosis of other type of bypass graft(s) of other extremity with ulceration',
  'I70761 Atherosclerosis of other type of bypass graft(s) of the extremities with gangrene, right leg',
  'I70762 Atherosclerosis of other type of bypass graft(s) of the extremities with gangrene, left leg',
  'I70763 Atherosclerosis of other type of bypass graft(s) of the extremities with gangrene, bilateral legs',
  'I70768 Atherosclerosis of other type of bypass graft(s) of the extremities with gangrene, other',
  'extremity',
  'I70769 Atherosclerosis of other type of bypass graft(s) of the extremities with gangrene, unspecified',
  'extremity',
  'I70791 Other atherosclerosis of other type of bypass graft(s) of the extremities, right leg',
  'I70792 Other atherosclerosis of other type of bypass graft(s) of the extremities, left leg',
  'I70793 Other atherosclerosis of other type of bypass graft(s) of the extremities, bilateral legs',
  'I70798 Other atherosclerosis of other type of bypass graft(s) of the extremities, other extremity',
  'I70799 Other atherosclerosis of other type of bypass graft(s) of the extremities, unspecified extremity',
  'I708 Atherosclerosis of other arteries',
  'I7090 Unspecified atherosclerosis',
  'I7091 Generalized atherosclerosis',
  'I7092 Chronic total occlusion of artery of the extremities',
  'I7100 Dissection of unspecified site of aorta',
  'I7101 Dissection of thoracic aorta',
  'I7102 Dissection of abdominal aorta',
  'I7103 Dissection of thoracoabdominal aorta',
  'I711 Thoracic aortic aneurysm, ruptured',
  'I712 Thoracic aortic aneurysm, without rupture',
  'I713 Abdominal aortic aneurysm, ruptured',
  'I714 Abdominal aortic aneurysm, without rupture',
  'I715 Thoracoabdominal aortic aneurysm, ruptured',
  'I716 Thoracoabdominal aortic aneurysm, without rupture',
  'I718 Aortic aneurysm of unspecified site, ruptured',
  'I719 Aortic aneurysm of unspecified site, without rupture',
  'I720 Aneurysm of carotid artery',
  'I721 Aneurysm of artery of upper extremity',
  'I722 Aneurysm of renal artery',
  'I723 Aneurysm of iliac artery',
  'I724 Aneurysm of artery of lower extremity',
  'I725 Aneurysm of other precerebral arteries',
  'I726 Aneurysm of vertebral artery',
  'I728 Aneurysm of other specified arteries',
  'I729 Aneurysm of unspecified site',
  "I7300 Raynaud's syndrome without gangrene",
  "I7301 Raynaud's syndrome with gangrene",
  "I731 Thromboangiitis obliterans [Buerger's disease]",
  'I7381 Erythromelalgia',
  'I7389 Other specified peripheral vascular diseases',
  'I739 Peripheral vascular disease, unspecified',
  'I7401 Saddle embolus of abdominal aorta',
  'I7409 Other arterial embolism and thrombosis of abdominal aorta',
  'I7410 Embolism and thrombosis of unspecified parts of aorta',
  'I7411 Embolism and thrombosis of thoracic aorta',
  'I7419 Embolism and thrombosis of other parts of aorta',
  'I742 Embolism and thrombosis of arteries of the upper extremities',
  'I743 Embolism and thrombosis of arteries of the lower extremities',
  'I744 Embolism and thrombosis of arteries of extremities, unspecified',
  'I745 Embolism and thrombosis of iliac artery',
  'I748 Embolism and thrombosis of other arteries',
  'I749 Embolism and thrombosis of unspecified artery',
  'I75011 Atheroembolism of right upper extremity',
  'I75012 Atheroembolism of left upper extremity',
  'I75013 Atheroembolism of bilateral upper extremities',
  'I75019 Atheroembolism of unspecified upper extremity',
  'I75021 Atheroembolism of right lower extremity',
  'I75022 Atheroembolism of left lower extremity',
  'I75023 Atheroembolism of bilateral lower extremities',
  'I75029 Atheroembolism of unspecified lower extremity',
  'I7581 Atheroembolism of kidney',
  'I7589 Atheroembolism of other site',
  'I76 Septic arterial embolism',
  'I770 Arteriovenous fistula, acquired',
  'I771 Stricture of artery',
  'I772 Rupture of artery',
  'I773 Arterial fibromuscular dysplasia',
  'I774 Celiac artery compression syndrome',
  'I775 Necrosis of artery',
  'I776 Arteritis, unspecified',
  'I7770 Dissection of unspecified artery',
  'I7771 Dissection of carotid artery',
  'I7772 Dissection of iliac artery',
  'I7773 Dissection of renal artery',
  'I7774 Dissection of vertebral artery',
  'I7775 Dissection of other precerebral arteries',
  'I7776 Dissection of artery of upper extremity',
  'I7777 Dissection of artery of lower extremity',
  'I7779 Dissection of other specified artery',
  'I77810 Thoracic aortic ectasia',
  'I77811 Abdominal aortic ectasia',
  'I77812 Thoracoabdominal aortic ectasia',
  'I77819 Aortic ectasia, unspecified site',
  'I7789 Other specified disorders of arteries and arterioles',
  'I779 Disorder of arteries and arterioles, unspecified',
  'I780 Hereditary hemorrhagic telangiectasia',
  'I781 Nevus, non-neoplastic',
  'I788 Other diseases of capillaries',
  'I789 Disease of capillaries, unspecified',
  'I790 Aneurysm of aorta in diseases classified elsewhere',
  'I791 Aortitis in diseases classified elsewhere',
  'I798 Other disorders of arteries, arterioles and capillaries in diseases classified elsewhere',
  'I8000 Phlebitis and thrombophlebitis of superficial vessels of unspecified lower extremity',
  'I8001 Phlebitis and thrombophlebitis of superficial vessels of right lower extremity',
  'I8002 Phlebitis and thrombophlebitis of superficial vessels of left lower extremity',
  'I8003 Phlebitis and thrombophlebitis of superficial vessels of lower extremities, bilateral',
  'I8010 Phlebitis and thrombophlebitis of unspecified femoral vein',
  'I8011 Phlebitis and thrombophlebitis of right femoral vein',
  'I8012 Phlebitis and thrombophlebitis of left femoral vein',
  'I8013 Phlebitis and thrombophlebitis of femoral vein, bilateral',
  'I80201 Phlebitis and thrombophlebitis of unspecified deep vessels of right lower extremity',
  'I80202 Phlebitis and thrombophlebitis of unspecified deep vessels of left lower extremity',
  'I80203 Phlebitis and thrombophlebitis of unspecified deep vessels of lower extremities, bilateral',
  'I80209 Phlebitis and thrombophlebitis of unspecified deep vessels of unspecified lower extremity',
  'I80211 Phlebitis and thrombophlebitis of right iliac vein',
  'I80212 Phlebitis and thrombophlebitis of left iliac vein',
  'I80213 Phlebitis and thrombophlebitis of iliac vein, bilateral',
  'I80219 Phlebitis and thrombophlebitis of unspecified iliac vein',
  'I80221 Phlebitis and thrombophlebitis of right popliteal vein',
  'I80222 Phlebitis and thrombophlebitis of left popliteal vein',
  'I80223 Phlebitis and thrombophlebitis of popliteal vein, bilateral',
  'I80229 Phlebitis and thrombophlebitis of unspecified popliteal vein',
  'I80231 Phlebitis and thrombophlebitis of right tibial vein',
  'I80232 Phlebitis and thrombophlebitis of left tibial vein',
  'I80233 Phlebitis and thrombophlebitis of tibial vein, bilateral',
  'I80239 Phlebitis and thrombophlebitis of unspecified tibial vein',
  'I80291 Phlebitis and thrombophlebitis of other deep vessels of right lower extremity',
  'I80292 Phlebitis and thrombophlebitis of other deep vessels of left lower extremity',
  'I80293 Phlebitis and thrombophlebitis of other deep vessels of lower extremity, bilateral',
  'I80299 Phlebitis and thrombophlebitis of other deep vessels of unspecified lower extremity',
  'I803 Phlebitis and thrombophlebitis of lower extremities, unspecified',
  'I808 Phlebitis and thrombophlebitis of other sites',
  'I809 Phlebitis and thrombophlebitis of unspecified site',
  'I81 Portal vein thrombosis',
  'I820 Budd-Chiari syndrome',
  'I821 Thrombophlebitis migrans',
  'I82210 Acute embolism and thrombosis of superior vena cava',
  'I82211 Chronic embolism and thrombosis of superior vena cava',
  'I82220 Acute embolism and thrombosis of inferior vena cava',
  'I82221 Chronic embolism and thrombosis of inferior vena cava',
  'I82290 Acute embolism and thrombosis of other thoracic veins',
  'I82291 Chronic embolism and thrombosis of other thoracic veins',
  'I823 Embolism and thrombosis of renal vein',
  'I82401 Acute embolism and thrombosis of unspecified deep veins of right lower extremity',
  'I82402 Acute embolism and thrombosis of unspecified deep veins of left lower extremity',
  'I82403 Acute embolism and thrombosis of unspecified deep veins of lower extremity, bilateral',
  'I82409 Acute embolism and thrombosis of unspecified deep veins of unspecified lower extremity',
  'I82411 Acute embolism and thrombosis of right femoral vein',
  'I82412 Acute embolism and thrombosis of left femoral vein',
  'I82413 Acute embolism and thrombosis of femoral vein, bilateral',
  'I82419 Acute embolism and thrombosis of unspecified femoral vein',
  'I82421 Acute embolism and thrombosis of right iliac vein',
  'I82422 Acute embolism and thrombosis of left iliac vein',
  'I82423 Acute embolism and thrombosis of iliac vein, bilateral',
  'I82429 Acute embolism and thrombosis of unspecified iliac vein',
  'I82431 Acute embolism and thrombosis of right popliteal vein',
  'I82432 Acute embolism and thrombosis of left popliteal vein',
  'I82433 Acute embolism and thrombosis of popliteal vein, bilateral',
  'I82439 Acute embolism and thrombosis of unspecified popliteal vein',
  'I82441 Acute embolism and thrombosis of right tibial vein',
  'I82442 Acute embolism and thrombosis of left tibial vein',
  'I82443 Acute embolism and thrombosis of tibial vein, bilateral',
  'I82449 Acute embolism and thrombosis of unspecified tibial vein',
  'I82491 Acute embolism and thrombosis of other specified deep vein of right lower extremity',
  'I82492 Acute embolism and thrombosis of other specified deep vein of left lower extremity',
  'I82493 Acute embolism and thrombosis of other specified deep vein of lower extremity, bilateral',
  'I82499 Acute embolism and thrombosis of other specified deep vein of unspecified lower extremity',
  'I824Y1 Acute embolism and thrombosis of unspecified deep veins of right proximal lower extremity',
  'I824Y2 Acute embolism and thrombosis of unspecified deep veins of left proximal lower extremity',
  'I824Y3 Acute embolism and thrombosis of unspecified deep veins of proximal lower extremity, bilateral',
  'I824Y9 Acute embolism and thrombosis of unspecified deep veins of unspecified proximal lower',
  'extremity',
  'I824Z1 Acute embolism and thrombosis of unspecified deep veins of right distal lower extremity',
  'I824Z2 Acute embolism and thrombosis of unspecified deep veins of left distal lower extremity',
  'I824Z3 Acute embolism and thrombosis of unspecified deep veins of distal lower extremity, bilateral',
  'I824Z9 Acute embolism and thrombosis of unspecified deep veins of unspecified distal lower extremity',
  'I82501 Chronic embolism and thrombosis of unspecified deep veins of right lower extremity',
  'I82502 Chronic embolism and thrombosis of unspecified deep veins of left lower extremity',
  'I82503 Chronic embolism and thrombosis of unspecified deep veins of lower extremity, bilateral',
  'I82509 Chronic embolism and thrombosis of unspecified deep veins of unspecified lower extremity',
  'I82511 Chronic embolism and thrombosis of right femoral vein',
  'I82512 Chronic embolism and thrombosis of left femoral vein',
  'I82513 Chronic embolism and thrombosis of femoral vein, bilateral',
  'I82519 Chronic embolism and thrombosis of unspecified femoral vein',
  'I82521 Chronic embolism and thrombosis of right iliac vein',
  'I82522 Chronic embolism and thrombosis of left iliac vein',
  'I82523 Chronic embolism and thrombosis of iliac vein, bilateral',
  'I82529 Chronic embolism and thrombosis of unspecified iliac vein',
  'I82531 Chronic embolism and thrombosis of right popliteal vein',
  'I82532 Chronic embolism and thrombosis of left popliteal vein',
  'I82533 Chronic embolism and thrombosis of popliteal vein, bilateral',
  'I82539 Chronic embolism and thrombosis of unspecified popliteal vein',
  'I82541 Chronic embolism and thrombosis of right tibial vein',
  'I82542 Chronic embolism and thrombosis of left tibial vein',
  'I82543 Chronic embolism and thrombosis of tibial vein, bilateral',
  'I82549 Chronic embolism and thrombosis of unspecified tibial vein',
  'I82591 Chronic embolism and thrombosis of other specified deep vein of right lower extremity',
  'I82592 Chronic embolism and thrombosis of other specified deep vein of left lower extremity',
  'I82593 Chronic embolism and thrombosis of other specified deep vein of lower extremity, bilateral',
  'I82599 Chronic embolism and thrombosis of other specified deep vein of unspecified lower extremity',
  'I825Y1 Chronic embolism and thrombosis of unspecified deep veins of right proximal lower extremity',
  'I825Y2 Chronic embolism and thrombosis of unspecified deep veins of left proximal lower extremity',
  'I825Y3 Chronic embolism and thrombosis of unspecified deep veins of proximal lower extremity,',
  'bilateral',
  'I825Y9 Chronic embolism and thrombosis of unspecified deep veins of unspecified proximal lower',
  'extremity',
  'I825Z1 Chronic embolism and thrombosis of unspecified deep veins of right distal lower extremity',
  'I825Z2 Chronic embolism and thrombosis of unspecified deep veins of left distal lower extremity',
  'I825Z3 Chronic embolism and thrombosis of unspecified deep veins of distal lower extremity, bilateral',
  'I825Z9 Chronic embolism and thrombosis of unspecified deep veins of unspecified distal lower',
  'extremity',
  'I82601 Acute embolism and thrombosis of unspecified veins of right upper extremity',
  'I82602 Acute embolism and thrombosis of unspecified veins of left upper extremity',
  'I82603 Acute embolism and thrombosis of unspecified veins of upper extremity, bilateral',
  'I82609 Acute embolism and thrombosis of unspecified veins of unspecified upper extremity',
  'I82611 Acute embolism and thrombosis of superficial veins of right upper extremity',
  'I82612 Acute embolism and thrombosis of superficial veins of left upper extremity',
  'I82613 Acute embolism and thrombosis of superficial veins of upper extremity, bilateral',
  'I82619 Acute embolism and thrombosis of superficial veins of unspecified upper extremity',
  'I82621 Acute embolism and thrombosis of deep veins of right upper extremity',
  'I82622 Acute embolism and thrombosis of deep veins of left upper extremity',
  'I82623 Acute embolism and thrombosis of deep veins of upper extremity, bilateral',
  'I82629 Acute embolism and thrombosis of deep veins of unspecified upper extremity',
  'I82701 Chronic embolism and thrombosis of unspecified veins of right upper extremity',
  'I82702 Chronic embolism and thrombosis of unspecified veins of left upper extremity',
  'I82703 Chronic embolism and thrombosis of unspecified veins of upper extremity, bilateral',
  'I82709 Chronic embolism and thrombosis of unspecified veins of unspecified upper extremity',
  'I82711 Chronic embolism and thrombosis of superficial veins of right upper extremity',
  'I82712 Chronic embolism and thrombosis of superficial veins of left upper extremity',
  'I82713 Chronic embolism and thrombosis of superficial veins of upper extremity, bilateral',
  'I82719 Chronic embolism and thrombosis of superficial veins of unspecified upper extremity',
  'I82721 Chronic embolism and thrombosis of deep veins of right upper extremity',
  'I82722 Chronic embolism and thrombosis of deep veins of left upper extremity',
  'I82723 Chronic embolism and thrombosis of deep veins of upper extremity, bilateral',
  'I82729 Chronic embolism and thrombosis of deep veins of unspecified upper extremity',
  'I82A11 Acute embolism and thrombosis of right axillary vein',
  'I82A12 Acute embolism and thrombosis of left axillary vein',
  'I82A13 Acute embolism and thrombosis of axillary vein, bilateral',
  'I82A19 Acute embolism and thrombosis of unspecified axillary vein',
  'I82A21 Chronic embolism and thrombosis of right axillary vein',
  'I82A22 Chronic embolism and thrombosis of left axillary vein',
  'I82A23 Chronic embolism and thrombosis of axillary vein, bilateral',
  'I82A29 Chronic embolism and thrombosis of unspecified axillary vein',
  'I82B11 Acute embolism and thrombosis of right subclavian vein',
  'I82B12 Acute embolism and thrombosis of left subclavian vein',
  'I82B13 Acute embolism and thrombosis of subclavian vein, bilateral',
  'I82B19 Acute embolism and thrombosis of unspecified subclavian vein',
  'I82B21 Chronic embolism and thrombosis of right subclavian vein',
  'I82B22 Chronic embolism and thrombosis of left subclavian vein',
  'I82B23 Chronic embolism and thrombosis of subclavian vein, bilateral',
  'I82B29 Chronic embolism and thrombosis of unspecified subclavian vein',
  'I82C11 Acute embolism and thrombosis of right internal jugular vein',
  'I82C12 Acute embolism and thrombosis of left internal jugular vein',
  'I82C13 Acute embolism and thrombosis of internal jugular vein, bilateral',
  'I82C19 Acute embolism and thrombosis of unspecified internal jugular vein',
  'I82C21 Chronic embolism and thrombosis of right internal jugular vein',
  'I82C22 Chronic embolism and thrombosis of left internal jugular vein',
  'I82C23 Chronic embolism and thrombosis of internal jugular vein, bilateral',
  'I82C29 Chronic embolism and thrombosis of unspecified internal jugular vein',
  'I82811 Embolism and thrombosis of superficial veins of right lower extremities',
  'I82812 Embolism and thrombosis of superficial veins of left lower extremities',
  'I82813 Embolism and thrombosis of superficial veins of lower extremities, bilateral',
  'I82819 Embolism and thrombosis of superficial veins of unspecified lower extremities',
  'I82890 Acute embolism and thrombosis of other specified veins',
  'I82891 Chronic embolism and thrombosis of other specified veins',
  'I8290 Acute embolism and thrombosis of unspecified vein',
  'I8291 Chronic embolism and thrombosis of unspecified vein',
  'I83001 Varicose veins of unspecified lower extremity with ulcer of thigh',
  'I83002 Varicose veins of unspecified lower extremity with ulcer of calf',
  'I83003 Varicose veins of unspecified lower extremity with ulcer of ankle',
  'I83004 Varicose veins of unspecified lower extremity with ulcer of heel and midfoot',
  'I83005 Varicose veins of unspecified lower extremity with ulcer other part of foot',
  'I83008 Varicose veins of unspecified lower extremity with ulcer other part of lower leg',
  'I83009 Varicose veins of unspecified lower extremity with ulcer of unspecified site',
  'I83011 Varicose veins of right lower extremity with ulcer of thigh',
  'I83012 Varicose veins of right lower extremity with ulcer of calf',
  'I83013 Varicose veins of right lower extremity with ulcer of ankle',
  'I83014 Varicose veins of right lower extremity with ulcer of heel and midfoot',
  'I83015 Varicose veins of right lower extremity with ulcer other part of foot',
  'I83018 Varicose veins of right lower extremity with ulcer other part of lower leg',
  'I83019 Varicose veins of right lower extremity with ulcer of unspecified site',
  'I83021 Varicose veins of left lower extremity with ulcer of thigh',
  'I83022 Varicose veins of left lower extremity with ulcer of calf',
  'I83023 Varicose veins of left lower extremity with ulcer of ankle',
  'I83024 Varicose veins of left lower extremity with ulcer of heel and midfoot',
  'I83025 Varicose veins of left lower extremity with ulcer other part of foot',
  'I83028 Varicose veins of left lower extremity with ulcer other part of lower leg',
  'I83029 Varicose veins of left lower extremity with ulcer of unspecified site',
  'I8310 Varicose veins of unspecified lower extremity with inflammation',
  'I8311 Varicose veins of right lower extremity with inflammation',
  'I8312 Varicose veins of left lower extremity with inflammation',
  'I83201 Varicose veins of unspecified lower extremity with both ulcer of thigh and inflammation',
  'I83202 Varicose veins of unspecified lower extremity with both ulcer of calf and inflammation',
  'I83203 Varicose veins of unspecified lower extremity with both ulcer of ankle and inflammation',
  'I83204 Varicose veins of unspecified lower extremity with both ulcer of heel and midfoot and',
  'inflammation',
  'I83205 Varicose veins of unspecified lower extremity with both ulcer other part of foot and',
  'inflammation',
  'I83208 Varicose veins of unspecified lower extremity with both ulcer of other part of lower extremity',
  'and inflammation',
  'I83209 Varicose veins of unspecified lower extremity with both ulcer of unspecified site and',
  'inflammation',
  'I83211 Varicose veins of right lower extremity with both ulcer of thigh and inflammation',
  'I83212 Varicose veins of right lower extremity with both ulcer of calf and inflammation',
  'I83213 Varicose veins of right lower extremity with both ulcer of ankle and inflammation',
  'I83214 Varicose veins of right lower extremity with both ulcer of heel and midfoot and inflammation',
  'I83215 Varicose veins of right lower extremity with both ulcer other part of foot and inflammation',
  'I83218 Varicose veins of right lower extremity with both ulcer of other part of lower extremity and',
  'inflammation',
  'I83219 Varicose veins of right lower extremity with both ulcer of unspecified site and inflammation',
  'I83221 Varicose veins of left lower extremity with both ulcer of thigh and inflammation',
  'I83222 Varicose veins of left lower extremity with both ulcer of calf and inflammation',
  'I83223 Varicose veins of left lower extremity with both ulcer of ankle and inflammation',
  'I83224 Varicose veins of left lower extremity with both ulcer of heel and midfoot and inflammation',
  'I83225 Varicose veins of left lower extremity with both ulcer other part of foot and inflammation',
  'I83228 Varicose veins of left lower extremity with both ulcer of other part of lower extremity and',
  'inflammation',
  'I83229 Varicose veins of left lower extremity with both ulcer of unspecified site and inflammation',
  'I83811 Varicose veins of right lower extremities with pain',
  'I83812 Varicose veins of left lower extremities with pain',
  'I83813 Varicose veins of bilateral lower extremities with pain',
  'I83819 Varicose veins of unspecified lower extremities with pain',
  'I83891 Varicose veins of right lower extremities with other complications',
  'I83892 Varicose veins of left lower extremities with other complications',
  'I83893 Varicose veins of bilateral lower extremities with other complications',
  'I83899 Varicose veins of unspecified lower extremities with other complications',
  'I8390 Asymptomatic varicose veins of unspecified lower extremity',
  'I8391 Asymptomatic varicose veins of right lower extremity',
  'I8392 Asymptomatic varicose veins of left lower extremity',
  'I8393 Asymptomatic varicose veins of bilateral lower extremities',
  'I8500 Esophageal varices without bleeding',
  'I8501 Esophageal varices with bleeding',
  'I8510 Secondary esophageal varices without bleeding',
  'I8511 Secondary esophageal varices with bleeding',
  'I860 Sublingual varices',
  'I861 Scrotal varices',
  'I862 Pelvic varices',
  'I863 Vulval varices',
  'I864 Gastric varices',
  'I868 Varicose veins of other specified sites',
  'I87001 Postthrombotic syndrome without complications of right lower extremity',
  'I87002 Postthrombotic syndrome without complications of left lower extremity',
  'I87003 Postthrombotic syndrome without complications of bilateral lower extremity',
  'I87009 Postthrombotic syndrome without complications of unspecified extremity',
  'I87011 Postthrombotic syndrome with ulcer of right lower extremity',
  'I87012 Postthrombotic syndrome with ulcer of left lower extremity',
  'I87013 Postthrombotic syndrome with ulcer of bilateral lower extremity',
  'I87019 Postthrombotic syndrome with ulcer of unspecified lower extremity',
  'I87021 Postthrombotic syndrome with inflammation of right lower extremity',
  'I87022 Postthrombotic syndrome with inflammation of left lower extremity',
  'I87023 Postthrombotic syndrome with inflammation of bilateral lower extremity',
  'I87029 Postthrombotic syndrome with inflammation of unspecified lower extremity',
  'I87031 Postthrombotic syndrome with ulcer and inflammation of right lower extremity',
  'I87032 Postthrombotic syndrome with ulcer and inflammation of left lower extremity',
  'I87033 Postthrombotic syndrome with ulcer and inflammation of bilateral lower extremity',
  'I87039 Postthrombotic syndrome with ulcer and inflammation of unspecified lower extremity',
  'I87091 Postthrombotic syndrome with other complications of right lower extremity',
  'I87092 Postthrombotic syndrome with other complications of left lower extremity',
  'I87093 Postthrombotic syndrome with other complications of bilateral lower extremity',
  'I87099 Postthrombotic syndrome with other complications of unspecified lower extremity',
  'I871 Compression of vein',
  'I872 Venous insufficiency (chronic) (peripheral)',
  'I87301 Chronic venous hypertension (idiopathic) without complications of right lower extremity',
  'I87302 Chronic venous hypertension (idiopathic) without complications of left lower extremity',
  'I87303 Chronic venous hypertension (idiopathic) without complications of bilateral lower extremity',
  'I87309 Chronic venous hypertension (idiopathic) without complications of unspecified lower extremity',
  'I87311 Chronic venous hypertension (idiopathic) with ulcer of right lower extremity',
  'I87312 Chronic venous hypertension (idiopathic) with ulcer of left lower extremity',
  'I87313 Chronic venous hypertension (idiopathic) with ulcer of bilateral lower extremity',
  'I87319 Chronic venous hypertension (idiopathic) with ulcer of unspecified lower extremity',
  'I87321 Chronic venous hypertension (idiopathic) with inflammation of right lower extremity',
  'I87322 Chronic venous hypertension (idiopathic) with inflammation of left lower extremity',
  'I87323 Chronic venous hypertension (idiopathic) with inflammation of bilateral lower extremity',
  'I87329 Chronic venous hypertension (idiopathic) with inflammation of unspecified lower extremity',
  'I87331 Chronic venous hypertension (idiopathic) with ulcer and inflammation of right lower extremity',
  'I87332 Chronic venous hypertension (idiopathic) with ulcer and inflammation of left lower extremity',
  'I87333 Chronic venous hypertension (idiopathic) with ulcer and inflammation of bilateral lower',
  'extremity',
  'I87339 Chronic venous hypertension (idiopathic) with ulcer and inflammation of unspecified lower',
  'extremity',
  'I87391 Chronic venous hypertension (idiopathic) with other complications of right lower extremity',
  'I87392 Chronic venous hypertension (idiopathic) with other complications of left lower extremity',
  'I87393 Chronic venous hypertension (idiopathic) with other complications of bilateral lower extremity',
  'I87399 Chronic venous hypertension (idiopathic) with other complications of unspecified lower',
  'extremity',
  'I878 Other specified disorders of veins',
  'I879 Disorder of vein, unspecified',
  'I880 Nonspecific mesenteric lymphadenitis',
  'I881 Chronic lymphadenitis, except mesenteric',
  'I888 Other nonspecific lymphadenitis',
  'I889 Nonspecific lymphadenitis, unspecified',
  'I890 Lymphedema, not elsewhere classified',
  'I891 Lymphangitis',
  'I898 Other specified noninfective disorders of lymphatic vessels and lymph nodes',
  'I899 Noninfective disorder of lymphatic vessels and lymph nodes, unspecified',
  'I950 Idiopathic hypotension',
  'I951 Orthostatic hypotension',
  'I952 Hypotension due to drugs',
  'I953 Hypotension of hemodialysis',
  'I9581 Postprocedural hypotension',
  'I9589 Other hypotension',
  'I959 Hypotension, unspecified',
  'I96 Gangrene, not elsewhere classified',
  'I970 Postcardiotomy syndrome',
  'I97110 Postprocedural cardiac insufficiency following cardiac surgery',
  'I97111 Postprocedural cardiac insufficiency following other surgery',
  'I97120 Postprocedural cardiac arrest following cardiac surgery',
  'I97121 Postprocedural cardiac arrest following other surgery',
  'I97130 Postprocedural heart failure following cardiac surgery',
  'I97131 Postprocedural heart failure following other surgery',
  'I97190 Other postprocedural cardiac functional disturbances following cardiac surgery',
  'I97191 Other postprocedural cardiac functional disturbances following other surgery',
  'I972 Postmastectomy lymphedema syndrome',
  'I973 Postprocedural hypertension',
  'I97410 Intraoperative hemorrhage and hematoma of a circulatory system organ or structure',
  'complicating a cardiac catheterization',
  'I97411 Intraoperative hemorrhage and hematoma of a circulatory system organ or structure',
  'complicating a cardiac bypass',
  'I97418 Intraoperative hemorrhage and hematoma of a circulatory system organ or structure',
  'complicating other circulatory system procedure',
  'I9742 Intraoperative hemorrhage and hematoma of a circulatory system organ or structure',
  'complicating other procedure',
  'I9751 Accidental puncture and laceration of a circulatory system organ or structure during a circulatory',
  'system procedure',
  'I9752 Accidental puncture and laceration of a circulatory system organ or structure during other',
  'procedure',
  'I97610 Postprocedural hemorrhage of a circulatory system organ or structure following a cardiac',
  'catheterization',
  'I97611 Postprocedural hemorrhage of a circulatory system organ or structure following cardiac bypass',
  'I97618 Postprocedural hemorrhage of a circulatory system organ or structure following other',
  'circulatory system procedure',
  'I97620 Postprocedural hemorrhage of a circulatory system organ or structure following other',
  'procedure',
  'I97621 Postprocedural hematoma of a circulatory system organ or structure following other procedure',
  'I97622 Postprocedural seroma of a circulatory system organ or structure following other procedure',
  'I97630 Postprocedural hematoma of a circulatory system organ or structure following a cardiac',
  'catheterization',
  'I97631 Postprocedural hematoma of a circulatory system organ or structure following cardiac bypass',
  'I97638 Postprocedural hematoma of a circulatory system organ or structure following other circulatory',
  'system procedure',
  'I97640 Postprocedural seroma of a circulatory system organ or structure following a cardiac',
  'catheterization',
  'I97641 Postprocedural seroma of a circulatory system organ or structure following cardiac bypass',
  'I97648 Postprocedural seroma of a circulatory system organ or structure following other circulatory',
  'system procedure',
  'I97710 Intraoperative cardiac arrest during cardiac surgery',
  'I97711 Intraoperative cardiac arrest during other surgery',
  'I97790 Other intraoperative cardiac functional disturbances during cardiac surgery',
  'I97791 Other intraoperative cardiac functional disturbances during other surgery',
  'I97810 Intraoperative cerebrovascular infarction during cardiac surgery',
  'I97811 Intraoperative cerebrovascular infarction during other surgery',
  'I97820 Postprocedural cerebrovascular infarction following cardiac surgery',
  'I97821 Postprocedural cerebrovascular infarction following other surgery',
  'I9788 Other intraoperative complications of the circulatory system, not elsewhere classified',
  'I9789 Other postprocedural complications and disorders of the circulatory system, not elsewhere',
  'classified',
  'I998 Other disorder of circulatory system',
  'I999 Unspecified disorder of circulatory system',
  'J00 Acute nasopharyngitis [common cold]',
  'J0100 Acute maxillary sinusitis, unspecified',
  'J0101 Acute recurrent maxillary sinusitis',
  'J0110 Acute frontal sinusitis, unspecified',
  'J0111 Acute recurrent frontal sinusitis',
  'J0120 Acute ethmoidal sinusitis, unspecified',
  'J0121 Acute recurrent ethmoidal sinusitis',
  'J0130 Acute sphenoidal sinusitis, unspecified',
  'J0131 Acute recurrent sphenoidal sinusitis',
  'J0140 Acute pansinusitis, unspecified',
  'J0141 Acute recurrent pansinusitis',
  'J0180 Other acute sinusitis',
  'J0181 Other acute recurrent sinusitis',
  'J0190 Acute sinusitis, unspecified',
  'J0191 Acute recurrent sinusitis, unspecified',
  'J020 Streptococcal pharyngitis',
  'J028 Acute pharyngitis due to other specified organisms',
  'J029 Acute pharyngitis, unspecified',
  'J0300 Acute streptococcal tonsillitis, unspecified',
  'J0301 Acute recurrent streptococcal tonsillitis',
  'J0380 Acute tonsillitis due to other specified organisms',
  'J0381 Acute recurrent tonsillitis due to other specified organisms',
  'J0390 Acute tonsillitis, unspecified',
  'J0391 Acute recurrent tonsillitis, unspecified',
  'J040 Acute laryngitis',
  'J0410 Acute tracheitis without obstruction',
  'J0411 Acute tracheitis with obstruction',
  'J042 Acute laryngotracheitis',
  'J0430 Supraglottitis, unspecified, without obstruction',
  'J0431 Supraglottitis, unspecified, with obstruction',
  'J050 Acute obstructive laryngitis [croup]',
  'J0510 Acute epiglottitis without obstruction',
  'J0511 Acute epiglottitis with obstruction',
  'J060 Acute laryngopharyngitis',
  'J069 Acute upper respiratory infection, unspecified',
  'J09X1 Influenza due to identified novel influenza A virus with pneumonia',
  'J09X2 Influenza due to identified novel influenza A virus with other respiratory manifestations',
  'J09X3 Influenza due to identified novel influenza A virus with gastrointestinal manifestations',
  'J09X9 Influenza due to identified novel influenza A virus with other manifestations',
  'J1000 Influenza due to other identified influenza virus with unspecified type of pneumonia',
  'J1001 Influenza due to other identified influenza virus with the same other identified influenza virus',
  'pneumonia',
  'J1008 Influenza due to other identified influenza virus with other specified pneumonia',
  'J101 Influenza due to other identified influenza virus with other respiratory manifestations',
  'J102 Influenza due to other identified influenza virus with gastrointestinal manifestations',
  'J1081 Influenza due to other identified influenza virus with encephalopathy',
  'J1082 Influenza due to other identified influenza virus with myocarditis',
  'J1083 Influenza due to other identified influenza virus with otitis media',
  'J1089 Influenza due to other identified influenza virus with other manifestations',
  'J1100 Influenza due to unidentified influenza virus with unspecified type of pneumonia',
  'J1108 Influenza due to unidentified influenza virus with specified pneumonia',
  'J111 Influenza due to unidentified influenza virus with other respiratory manifestations',
  'J112 Influenza due to unidentified influenza virus with gastrointestinal manifestations',
  'J1181 Influenza due to unidentified influenza virus with encephalopathy',
  'J1182 Influenza due to unidentified influenza virus with myocarditis',
  'J1183 Influenza due to unidentified influenza virus with otitis media',
  'J1189 Influenza due to unidentified influenza virus with other manifestations',
  'J120 Adenoviral pneumonia',
  'J121 Respiratory syncytial virus pneumonia',
  'J122 Parainfluenza virus pneumonia',
  'J123 Human metapneumovirus pneumonia',
  'J1281 Pneumonia due to SARS-associated coronavirus',
  'J1289 Other viral pneumonia',
  'J129 Viral pneumonia, unspecified',
  'J13 Pneumonia due to Streptococcus pneumoniae',
  'J14 Pneumonia due to Hemophilus influenzae',
  'J150 Pneumonia due to Klebsiella pneumoniae',
  'J151 Pneumonia due to Pseudomonas',
  'J1520 Pneumonia due to staphylococcus, unspecified',
  'J15211 Pneumonia due to Methicillin susceptible Staphylococcus aureus',
  'J15212 Pneumonia due to Methicillin resistant Staphylococcus aureus',
  'J1529 Pneumonia due to other staphylococcus',
  'J153 Pneumonia due to streptococcus, group B',
  'J154 Pneumonia due to other streptococci',
  'J155 Pneumonia due to Escherichia coli',
  'J156 Pneumonia due to other aerobic Gram-negative bacteria',
  'J157 Pneumonia due to Mycoplasma pneumoniae',
  'J158 Pneumonia due to other specified bacteria',
  'J159 Unspecified bacterial pneumonia',
  'J160 Chlamydial pneumonia',
  'J168 Pneumonia due to other specified infectious organisms',
  'J17 Pneumonia in diseases classified elsewhere',
  'J180 Bronchopneumonia, unspecified organism',
  'J181 Lobar pneumonia, unspecified organism',
  'J182 Hypostatic pneumonia, unspecified organism',
  'J188 Other pneumonia, unspecified organism',
  'J189 Pneumonia, unspecified organism',
  'J200 Acute bronchitis due to Mycoplasma pneumoniae',
  'J201 Acute bronchitis due to Hemophilus influenzae',
  'J202 Acute bronchitis due to streptococcus',
  'J203 Acute bronchitis due to coxsackievirus',
  'J204 Acute bronchitis due to parainfluenza virus',
  'J205 Acute bronchitis due to respiratory syncytial virus',
  'J206 Acute bronchitis due to rhinovirus',
  'J207 Acute bronchitis due to echovirus',
  'J208 Acute bronchitis due to other specified organisms',
  'J209 Acute bronchitis, unspecified',
  'J210 Acute bronchiolitis due to respiratory syncytial virus',
  'J211 Acute bronchiolitis due to human metapneumovirus',
  'J218 Acute bronchiolitis due to other specified organisms',
  'J219 Acute bronchiolitis, unspecified',
  'J22 Unspecified acute lower respiratory infection',
  'J300 Vasomotor rhinitis',
  'J301 Allergic rhinitis due to pollen',
  'J302 Other seasonal allergic rhinitis',
  'J305 Allergic rhinitis due to food',
  'J3081 Allergic rhinitis due to animal (cat) (dog) hair and dander',
  'J3089 Other allergic rhinitis',
  'J309 Allergic rhinitis, unspecified',
  'J310 Chronic rhinitis',
  'J311 Chronic nasopharyngitis',
  'J312 Chronic pharyngitis',
  'J320 Chronic maxillary sinusitis',
  'J321 Chronic frontal sinusitis',
  'J322 Chronic ethmoidal sinusitis',
  'J323 Chronic sphenoidal sinusitis',
  'J324 Chronic pansinusitis',
  'J328 Other chronic sinusitis',
  'J329 Chronic sinusitis, unspecified',
  'J330 Polyp of nasal cavity',
  'J331 Polypoid sinus degeneration',
  'J338 Other polyp of sinus',
  'J339 Nasal polyp, unspecified',
  'J340 Abscess, furuncle and carbuncle of nose',
  'J341 Cyst and mucocele of nose and nasal sinus',
  'J342 Deviated nasal septum',
  'J343 Hypertrophy of nasal turbinates',
  'J3481 Nasal mucositis (ulcerative)',
  'J3489 Other specified disorders of nose and nasal sinuses',
  'J349 Unspecified disorder of nose and nasal sinuses',
  'J3501 Chronic tonsillitis',
  'J3502 Chronic adenoiditis',
  'J3503 Chronic tonsillitis and adenoiditis',
  'J351 Hypertrophy of tonsils',
  'J352 Hypertrophy of adenoids',
  'J353 Hypertrophy of tonsils with hypertrophy of adenoids',
  'J358 Other chronic diseases of tonsils and adenoids',
  'J359 Chronic disease of tonsils and adenoids, unspecified',
  'J36 Peritonsillar abscess',
  'J370 Chronic laryngitis',
  'J371 Chronic laryngotracheitis',
  'J3800 Paralysis of vocal cords and larynx, unspecified',
  'J3801 Paralysis of vocal cords and larynx, unilateral',
  'J3802 Paralysis of vocal cords and larynx, bilateral',
  'J381 Polyp of vocal cord and larynx',
  'J382 Nodules of vocal cords',
  'J383 Other diseases of vocal cords',
  'J384 Edema of larynx',
  'J385 Laryngeal spasm',
  'J386 Stenosis of larynx',
  'J387 Other diseases of larynx',
  'J390 Retropharyngeal and parapharyngeal abscess',
  'J391 Other abscess of pharynx',
  'J392 Other diseases of pharynx',
  'J393 Upper respiratory tract hypersensitivity reaction, site unspecified',
  'J398 Other specified diseases of upper respiratory tract',
  'J399 Disease of upper respiratory tract, unspecified',
  'J40 Bronchitis, not specified as acute or chronic',
  'J410 Simple chronic bronchitis',
  'J411 Mucopurulent chronic bronchitis',
  'J418 Mixed simple and mucopurulent chronic bronchitis',
  'J42 Unspecified chronic bronchitis',
  "J430 Unilateral pulmonary emphysema [MacLeod's syndrome]",
  'J431 Panlobular emphysema',
  'J432 Centrilobular emphysema',
  'J438 Other emphysema',
  'J439 Emphysema, unspecified',
  'J440 Chronic obstructive pulmonary disease with acute lower respiratory infection',
  'J441 Chronic obstructive pulmonary disease with (acute) exacerbation',
  'J449 Chronic obstructive pulmonary disease, unspecified',
  'J4520 Mild intermittent asthma, uncomplicated',
  'J4521 Mild intermittent asthma with (acute) exacerbation',
  'J4522 Mild intermittent asthma with status asthmaticus',
  'J4530 Mild persistent asthma, uncomplicated',
  'J4531 Mild persistent asthma with (acute) exacerbation',
  'J4532 Mild persistent asthma with status asthmaticus',
  'J4540 Moderate persistent asthma, uncomplicated',
  'J4541 Moderate persistent asthma with (acute) exacerbation',
  'J4542 Moderate persistent asthma with status asthmaticus',
  'J4550 Severe persistent asthma, uncomplicated',
  'J4551 Severe persistent asthma with (acute) exacerbation',
  'J4552 Severe persistent asthma with status asthmaticus',
  'J45901 Unspecified asthma with (acute) exacerbation',
  'J45902 Unspecified asthma with status asthmaticus',
  'J45909 Unspecified asthma, uncomplicated',
  'J45990 Exercise induced bronchospasm',
  'J45991 Cough variant asthma',
  'J45998 Other asthma',
  'J470 Bronchiectasis with acute lower respiratory infection',
  'J471 Bronchiectasis with (acute) exacerbation',
  'J479 Bronchiectasis, uncomplicated',
  "J60 Coalworker's pneumoconiosis",
  'J61 Pneumoconiosis due to asbestos and other mineral fibers',
  'J620 Pneumoconiosis due to talc dust',
  'J628 Pneumoconiosis due to other dust containing silica',
  'J630 Aluminosis (of lung)',
  'J631 Bauxite fibrosis (of lung)',
  'J632 Berylliosis',
  'J633 Graphite fibrosis (of lung)',
  'J634 Siderosis',
  'J635 Stannosis',
  'J636 Pneumoconiosis due to other specified inorganic dusts',
  'J64 Unspecified pneumoconiosis',
  'J65 Pneumoconiosis associated with tuberculosis',
  'J660 Byssinosis',
  "J661 Flax-dressers' disease",
  'J662 Cannabinosis',
  'J668 Airway disease due to other specific organic dusts',
  "J670 Farmer's lung",
  'J671 Bagassosis',
  "J672 Bird fancier's lung",
  'J673 Suberosis',
  "J674 Maltworker's lung",
  "J675 Mushroom-worker's lung",
  "J676 Maple-bark-stripper's lung",
  'J677 Air conditioner and humidifier lung',
  'J678 Hypersensitivity pneumonitis due to other organic dusts',
  'J679 Hypersensitivity pneumonitis due to unspecified organic dust',
  'J680 Bronchitis and pneumonitis due to chemicals, gases, fumes and vapors',
  'J681 Pulmonary edema due to chemicals, gases, fumes and vapors',
  'J682 Upper respiratory inflammation due to chemicals, gases, fumes and vapors, not elsewhere',
  'classified',
  'J683 Other acute and subacute respiratory conditions due to chemicals, gases, fumes and vapors',
  'J684 Chronic respiratory conditions due to chemicals, gases, fumes and vapors',
  'J688 Other respiratory conditions due to chemicals, gases, fumes and vapors',
  'J689 Unspecified respiratory condition due to chemicals, gases, fumes and vapors',
  'J690 Pneumonitis due to inhalation of food and vomit',
  'J691 Pneumonitis due to inhalation of oils and essences',
  'J698 Pneumonitis due to inhalation of other solids and liquids',
  'J700 Acute pulmonary manifestations due to radiation',
  'J701 Chronic and other pulmonary manifestations due to radiation',
  'J702 Acute drug-induced interstitial lung disorders',
  'J703 Chronic drug-induced interstitial lung disorders',
  'J704 Drug-induced interstitial lung disorders, unspecified',
  'J705 Respiratory conditions due to smoke inhalation',
  'J708 Respiratory conditions due to other specified external agents',
  'J709 Respiratory conditions due to unspecified external agent',
  'J80 Acute respiratory distress syndrome',
  'J810 Acute pulmonary edema',
  'J811 Chronic pulmonary edema',
  'J82 Pulmonary eosinophilia, not elsewhere classified',
  'J8401 Alveolar proteinosis',
  'J8402 Pulmonary alveolar microlithiasis',
  'J8403 Idiopathic pulmonary hemosiderosis',
  'J8409 Other alveolar and parieto-alveolar conditions',
  'J8410 Pulmonary fibrosis, unspecified',
  'J84111 Idiopathic interstitial pneumonia, not otherwise specified',
  'J84112 Idiopathic pulmonary fibrosis',
  'J84113 Idiopathic non-specific interstitial pneumonitis',
  'J84114 Acute interstitial pneumonitis',
  'J84115 Respiratory bronchiolitis interstitial lung disease',
  'J84116 Cryptogenic organizing pneumonia',
  'J84117 Desquamative interstitial pneumonia',
  'J8417 Other interstitial pulmonary diseases with fibrosis in diseases classified elsewhere',
  'J842 Lymphoid interstitial pneumonia',
  'J8481 Lymphangioleiomyomatosis',
  'J8482 Adult pulmonary Langerhans cell histiocytosis',
  'J8483 Surfactant mutations of the lung',
  'J84841 Neuroendocrine cell hyperplasia of infancy',
  'J84842 Pulmonary interstitial glycogenosis',
  'J84843 Alveolar capillary dysplasia with vein misalignment',
  'J84848 Other interstitial lung diseases of childhood',
  'J8489 Other specified interstitial pulmonary diseases',
  'J849 Interstitial pulmonary disease, unspecified',
  'J850 Gangrene and necrosis of lung',
  'J851 Abscess of lung with pneumonia',
  'J852 Abscess of lung without pneumonia',
  'J853 Abscess of mediastinum',
  'J860 Pyothorax with fistula',
  'J869 Pyothorax without fistula',
  'J90 Pleural effusion, not elsewhere classified',
  'J910 Malignant pleural effusion',
  'J918 Pleural effusion in other conditions classified elsewhere',
  'J920 Pleural plaque with presence of asbestos',
  'J929 Pleural plaque without asbestos',
  'J930 Spontaneous tension pneumothorax',
  'J9311 Primary spontaneous pneumothorax',
  'J9312 Secondary spontaneous pneumothorax',
  'J9381 Chronic pneumothorax',
  'J9382 Other air leak',
  'J9383 Other pneumothorax',
  'J939 Pneumothorax, unspecified',
  'J940 Chylous effusion',
  'J941 Fibrothorax',
  'J942 Hemothorax',
  'J948 Other specified pleural conditions',
  'J949 Pleural condition, unspecified',
  'J9500 Unspecified tracheostomy complication',
  'J9501 Hemorrhage from tracheostomy stoma',
  'J9502 Infection of tracheostomy stoma',
  'J9503 Malfunction of tracheostomy stoma',
  'J9504 Tracheo-esophageal fistula following tracheostomy',
  'J9509 Other tracheostomy complication',
  'J951 Acute pulmonary insufficiency following thoracic surgery',
  'J952 Acute pulmonary insufficiency following nonthoracic surgery',
  'J953 Chronic pulmonary insufficiency following surgery',
  'J954 Chemical pneumonitis due to anesthesia',
  'J955 Postprocedural subglottic stenosis',
  'J9561 Intraoperative hemorrhage and hematoma of a respiratory system organ or structure',
  'complicating a respiratory system procedure',
  'J9562 Intraoperative hemorrhage and hematoma of a respiratory system organ or structure',
  'complicating other procedure',
  'J9571 Accidental puncture and laceration of a respiratory system organ or structure during a',
  'respiratory system procedure',
  'J9572 Accidental puncture and laceration of a respiratory system organ or structure during other',
  'procedure',
  'J95811 Postprocedural pneumothorax',
  'J95812 Postprocedural air leak',
  'J95821 Acute postprocedural respiratory failure',
  'J95822 Acute and chronic postprocedural respiratory failure',
  'J95830 Postprocedural hemorrhage of a respiratory system organ or structure following a respiratory',
  'system procedure',
  'J95831 Postprocedural hemorrhage of a respiratory system organ or structure following other',
  'procedure',
  'J9584 Transfusion-related acute lung injury (TRALI)',
  'J95850 Mechanical complication of respirator',
  'J95851 Ventilator associated pneumonia',
  'J95859 Other complication of respirator [ventilator]',
  'J95860 Postprocedural hematoma of a respiratory system organ or structure following a respiratory',
  'system procedure',
  'J95861 Postprocedural hematoma of a respiratory system organ or structure following other procedure',
  'J95862 Postprocedural seroma of a respiratory system organ or structure following a respiratory system',
  'procedure',
  'J95863 Postprocedural seroma of a respiratory system organ or structure following other procedure',
  'J9588 Other intraoperative complications of respiratory system, not elsewhere classified',
  'J9589 Other postprocedural complications and disorders of respiratory system, not elsewhere classified',
  'J9600 Acute respiratory failure, unspecified whether with hypoxia or hypercapnia',
  'J9601 Acute respiratory failure with hypoxia',
  'J9602 Acute respiratory failure with hypercapnia',
  'J9610 Chronic respiratory failure, unspecified whether with hypoxia or hypercapnia',
  'J9611 Chronic respiratory failure with hypoxia',
  'J9612 Chronic respiratory failure with hypercapnia',
  'J9620 Acute and chronic respiratory failure, unspecified whether with hypoxia or hypercapnia',
  'J9621 Acute and chronic respiratory failure with hypoxia',
  'J9622 Acute and chronic respiratory failure with hypercapnia',
  'J9690 Respiratory failure, unspecified, unspecified whether with hypoxia or hypercapnia',
  'J9691 Respiratory failure, unspecified with hypoxia',
  'J9692 Respiratory failure, unspecified with hypercapnia',
  'J9801 Acute bronchospasm',
  'J9809 Other diseases of bronchus, not elsewhere classified',
  'J9811 Atelectasis',
  'J9819 Other pulmonary collapse',
  'J982 Interstitial emphysema',
  'J983 Compensatory emphysema',
  'J984 Other disorders of lung',
  'J9851 Mediastinitis',
  'J9859 Other diseases of mediastinum, not elsewhere classified',
  'J986 Disorders of diaphragm',
  'J988 Other specified respiratory disorders',
  'J989 Respiratory disorder, unspecified',
  'J99 Respiratory disorders in diseases classified elsewhere',
  'K000 Anodontia',
  'K001 Supernumerary teeth',
  'K002 Abnormalities of size and form of teeth',
  'K003 Mottled teeth',
  'K004 Disturbances in tooth formation',
  'K005 Hereditary disturbances in tooth structure, not elsewhere classified',
  'K006 Disturbances in tooth eruption',
  'K007 Teething syndrome',
  'K008 Other disorders of tooth development',
  'K009 Disorder of tooth development, unspecified',
  'K010 Embedded teeth',
  'K011 Impacted teeth',
  'K023 Arrested dental caries',
  'K0251 Dental caries on pit and fissure surface limited to enamel',
  'K0252 Dental caries on pit and fissure surface penetrating into dentin',
  'K0253 Dental caries on pit and fissure surface penetrating into pulp',
  'K0261 Dental caries on smooth surface limited to enamel',
  'K0262 Dental caries on smooth surface penetrating into dentin',
  'K0263 Dental caries on smooth surface penetrating into pulp',
  'K027 Dental root caries',
  'K029 Dental caries, unspecified',
  'K030 Excessive attrition of teeth',
  'K031 Abrasion of teeth',
  'K032 Erosion of teeth',
  'K033 Pathological resorption of teeth',
  'K034 Hypercementosis',
  'K035 Ankylosis of teeth',
  'K036 Deposits [accretions] on teeth',
  'K037 Posteruptive color changes of dental hard tissues',
  'K0381 Cracked tooth',
  'K0389 Other specified diseases of hard tissues of teeth',
  'K039 Disease of hard tissues of teeth, unspecified',
  'K0401 Reversible pulpitis',
  'K0402 Irreversible pulpitis',
  'K041 Necrosis of pulp',
  'K042 Pulp degeneration',
  'K043 Abnormal hard tissue formation in pulp',
  'K044 Acute apical periodontitis of pulpal origin',
  'K045 Chronic apical periodontitis',
  'K046 Periapical abscess with sinus',
  'K047 Periapical abscess without sinus',
  'K048 Radicular cyst',
  'K0490 Unspecified diseases of pulp and periapical tissues',
  'K0499 Other diseases of pulp and periapical tissues',
  'K0500 Acute gingivitis, plaque induced',
  'K0501 Acute gingivitis, non-plaque induced',
  'K0510 Chronic gingivitis, plaque induced',
  'K0511 Chronic gingivitis, non-plaque induced',
  'K0520 Aggressive periodontitis, unspecified',
  'K05211 Aggressive periodontitis, localized, slight',
  'K05212 Aggressive periodontitis, localized, moderate',
  'K05213 Aggressive periodontitis, localized, severe',
  'K05219 Aggressive periodontitis, localized, unspecified severity',
  'K05221 Aggressive periodontitis, generalized, slight',
  'K05222 Aggressive periodontitis, generalized, moderate',
  'K05223 Aggressive periodontitis, generalized, severe',
  'K05229 Aggressive periodontitis, generalized, unspecified severity',
  'K0530 Chronic periodontitis, unspecified',
  'K05311 Chronic periodontitis, localized, slight',
  'K05312 Chronic periodontitis, localized, moderate',
  'K05313 Chronic periodontitis, localized, severe',
  'K05319 Chronic periodontitis, localized, unspecified severity',
  'K05321 Chronic periodontitis, generalized, slight',
  'K05322 Chronic periodontitis, generalized, moderate',
  'K05323 Chronic periodontitis, generalized, severe',
  'K05329 Chronic periodontitis, generalized, unspecified severity',
  'K054 Periodontosis',
  'K055 Other periodontal diseases',
  'K056 Periodontal disease, unspecified',
  'K060 Gingival recession',
  'K061 Gingival enlargement',
  'K062 Gingival and edentulous alveolar ridge lesions associated with trauma',
  'K063 Horizontal alveolar bone loss',
  'K068 Other specified disorders of gingiva and edentulous alveolar ridge',
  'K069 Disorder of gingiva and edentulous alveolar ridge, unspecified',
  'K080 Exfoliation of teeth due to systemic causes',
  'K08101 Complete loss of teeth, unspecified cause, class I',
  'K08102 Complete loss of teeth, unspecified cause, class II',
  'K08103 Complete loss of teeth, unspecified cause, class III',
  'K08104 Complete loss of teeth, unspecified cause, class IV',
  'K08109 Complete loss of teeth, unspecified cause, unspecified class',
  'K08111 Complete loss of teeth due to trauma, class I',
  'K08112 Complete loss of teeth due to trauma, class II',
  'K08113 Complete loss of teeth due to trauma, class III',
  'K08114 Complete loss of teeth due to trauma, class IV',
  'K08119 Complete loss of teeth due to trauma, unspecified class',
  'K08121 Complete loss of teeth due to periodontal diseases, class I',
  'K08122 Complete loss of teeth due to periodontal diseases, class II',
  'K08123 Complete loss of teeth due to periodontal diseases, class III',
  'K08124 Complete loss of teeth due to periodontal diseases, class IV',
  'K08129 Complete loss of teeth due to periodontal diseases, unspecified class',
  'K08131 Complete loss of teeth due to caries, class I',
  'K08132 Complete loss of teeth due to caries, class II',
  'K08133 Complete loss of teeth due to caries, class III',
  'K08134 Complete loss of teeth due to caries, class IV',
  'K08139 Complete loss of teeth due to caries, unspecified class',
  'K08191 Complete loss of teeth due to other specified cause, class I',
  'K08192 Complete loss of teeth due to other specified cause, class II',
  'K08193 Complete loss of teeth due to other specified cause, class III',
  'K08194 Complete loss of teeth due to other specified cause, class IV',
  'K08199 Complete loss of teeth due to other specified cause, unspecified class',
  'K0820 Unspecified atrophy of edentulous alveolar ridge',
  'K0821 Minimal atrophy of the mandible',
  'K0822 Moderate atrophy of the mandible',
  'K0823 Severe atrophy of the mandible',
  'K0824 Minimal atrophy of maxilla',
  'K0825 Moderate atrophy of the maxilla',
  'K0826 Severe atrophy of the maxilla',
  'K083 Retained dental root',
  'K08401 Partial loss of teeth, unspecified cause, class I',
  'K08402 Partial loss of teeth, unspecified cause, class II',
  'K08403 Partial loss of teeth, unspecified cause, class III',
  'K08404 Partial loss of teeth, unspecified cause, class IV',
  'K08409 Partial loss of teeth, unspecified cause, unspecified class',
  'K08411 Partial loss of teeth due to trauma, class I',
  'K08412 Partial loss of teeth due to trauma, class II',
  'K08413 Partial loss of teeth due to trauma, class III',
  'K08414 Partial loss of teeth due to trauma, class IV',
  'K08419 Partial loss of teeth due to trauma, unspecified class',
  'K08421 Partial loss of teeth due to periodontal diseases, class I',
  'K08422 Partial loss of teeth due to periodontal diseases, class II',
  'K08423 Partial loss of teeth due to periodontal diseases, class III',
  'K08424 Partial loss of teeth due to periodontal diseases, class IV',
  'K08429 Partial loss of teeth due to periodontal diseases, unspecified class',
  'K08431 Partial loss of teeth due to caries, class I',
  'K08432 Partial loss of teeth due to caries, class II',
  'K08433 Partial loss of teeth due to caries, class III',
  'K08434 Partial loss of teeth due to caries, class IV',
  'K08439 Partial loss of teeth due to caries, unspecified class',
  'K08491 Partial loss of teeth due to other specified cause, class I',
  'K08492 Partial loss of teeth due to other specified cause, class II',
  'K08493 Partial loss of teeth due to other specified cause, class III',
  'K08494 Partial loss of teeth due to other specified cause, class IV',
  'K08499 Partial loss of teeth due to other specified cause, unspecified class',
  'K0850 Unsatisfactory restoration of tooth, unspecified',
  'K0851 Open restoration margins of tooth',
  'K0852 Unrepairable overhanging of dental restorative materials',
  'K08530 Fractured dental restorative material without loss of material',
  'K08531 Fractured dental restorative material with loss of material',
  'K08539 Fractured dental restorative material, unspecified',
  'K0854 Contour of existing restoration of tooth biologically incompatible with oral health',
  'K0855 Allergy to existing dental restorative material',
  'K0856 Poor aesthetic of existing restoration of tooth',
  'K0859 Other unsatisfactory restoration of tooth',
  'K0881 Primary occlusal trauma',
  'K0882 Secondary occlusal trauma',
  'K0889 Other specified disorders of teeth and supporting structures',
  'K089 Disorder of teeth and supporting structures, unspecified',
  'K090 Developmental odontogenic cysts',
  'K091 Developmental (nonodontogenic) cysts of oral region',
  'K098 Other cysts of oral region, not elsewhere classified',
  'K099 Cyst of oral region, unspecified',
  'K110 Atrophy of salivary gland',
  'K111 Hypertrophy of salivary gland',
  'K1120 Sialoadenitis, unspecified',
  'K1121 Acute sialoadenitis',
  'K1122 Acute recurrent sialoadenitis',
  'K1123 Chronic sialoadenitis',
  'K113 Abscess of salivary gland',
  'K114 Fistula of salivary gland',
  'K115 Sialolithiasis',
  'K116 Mucocele of salivary gland',
  'K117 Disturbances of salivary secretion',
  'K118 Other diseases of salivary glands',
  'K119 Disease of salivary gland, unspecified',
  'K120 Recurrent oral aphthae',
  'K121 Other forms of stomatitis',
  'K122 Cellulitis and abscess of mouth',
  'K1230 Oral mucositis (ulcerative), unspecified',
  'K1231 Oral mucositis (ulcerative) due to antineoplastic therapy',
  'K1232 Oral mucositis (ulcerative) due to other drugs',
  'K1233 Oral mucositis (ulcerative) due to radiation',
  'K1239 Other oral mucositis (ulcerative)',
  'K130 Diseases of lips',
  'K131 Cheek and lip biting',
  'K1321 Leukoplakia of oral mucosa, including tongue',
  'K1322 Minimal keratinized residual ridge mucosa',
  'K1323 Excessive keratinized residual ridge mucosa',
  'K1324 Leukokeratosis nicotina palati',
  'K1329 Other disturbances of oral epithelium, including tongue',
  'K133 Hairy leukoplakia',
  'K134 Granuloma and granuloma-like lesions of oral mucosa',
  'K135 Oral submucous fibrosis',
  'K136 Irritative hyperplasia of oral mucosa',
  'K1370 Unspecified lesions of oral mucosa',
  'K1379 Other lesions of oral mucosa',
  'K140 Glossitis',
  'K141 Geographic tongue',
  'K142 Median rhomboid glossitis',
  'K143 Hypertrophy of tongue papillae',
  'K144 Atrophy of tongue papillae',
  'K145 Plicated tongue',
  'K146 Glossodynia',
  'K148 Other diseases of tongue',
  'K149 Disease of tongue, unspecified',
  'K200 Eosinophilic esophagitis',
  'K208 Other esophagitis',
  'K209 Esophagitis, unspecified',
  'K210 Gastro-esophageal reflux disease with esophagitis',
  'K219 Gastro-esophageal reflux disease without esophagitis',
  'K220 Achalasia of cardia',
  'K2210 Ulcer of esophagus without bleeding',
  'K2211 Ulcer of esophagus with bleeding',
  'K222 Esophageal obstruction',
  'K223 Perforation of esophagus',
  'K224 Dyskinesia of esophagus',
  'K225 Diverticulum of esophagus, acquired',
  'K226 Gastro-esophageal laceration-hemorrhage syndrome',
  "K2270 Barrett's esophagus without dysplasia",
  "K22710 Barrett's esophagus with low grade dysplasia",
  "K22711 Barrett's esophagus with high grade dysplasia",
  "K22719 Barrett's esophagus with dysplasia, unspecified",
  'K228 Other specified diseases of esophagus',
  'K229 Disease of esophagus, unspecified',
  'K23 Disorders of esophagus in diseases classified elsewhere',
  'K250 Acute gastric ulcer with hemorrhage',
  'K251 Acute gastric ulcer with perforation',
  'K252 Acute gastric ulcer with both hemorrhage and perforation',
  'K253 Acute gastric ulcer without hemorrhage or perforation',
  'K254 Chronic or unspecified gastric ulcer with hemorrhage',
  'K255 Chronic or unspecified gastric ulcer with perforation',
  'K256 Chronic or unspecified gastric ulcer with both hemorrhage and perforation',
  'K257 Chronic gastric ulcer without hemorrhage or perforation',
  'K259 Gastric ulcer, unspecified as acute or chronic, without hemorrhage or perforation',
  'K260 Acute duodenal ulcer with hemorrhage',
  'K261 Acute duodenal ulcer with perforation',
  'K262 Acute duodenal ulcer with both hemorrhage and perforation',
  'K263 Acute duodenal ulcer without hemorrhage or perforation',
  'K264 Chronic or unspecified duodenal ulcer with hemorrhage',
  'K265 Chronic or unspecified duodenal ulcer with perforation',
  'K266 Chronic or unspecified duodenal ulcer with both hemorrhage and perforation',
  'K267 Chronic duodenal ulcer without hemorrhage or perforation',
  'K269 Duodenal ulcer, unspecified as acute or chronic, without hemorrhage or perforation',
  'K270 Acute peptic ulcer, site unspecified, with hemorrhage',
  'K271 Acute peptic ulcer, site unspecified, with perforation',
  'K272 Acute peptic ulcer, site unspecified, with both hemorrhage and perforation',
  'K273 Acute peptic ulcer, site unspecified, without hemorrhage or perforation',
  'K274 Chronic or unspecified peptic ulcer, site unspecified, with hemorrhage',
  'K275 Chronic or unspecified peptic ulcer, site unspecified, with perforation',
  'K276 Chronic or unspecified peptic ulcer, site unspecified, with both hemorrhage and perforation',
  'K277 Chronic peptic ulcer, site unspecified, without hemorrhage or perforation',
  'K279 Peptic ulcer, site unspecified, unspecified as acute or chronic, without hemorrhage or perforation',
  'K280 Acute gastrojejunal ulcer with hemorrhage',
  'K281 Acute gastrojejunal ulcer with perforation',
  'K282 Acute gastrojejunal ulcer with both hemorrhage and perforation',
  'K283 Acute gastrojejunal ulcer without hemorrhage or perforation',
  'K284 Chronic or unspecified gastrojejunal ulcer with hemorrhage',
  'K285 Chronic or unspecified gastrojejunal ulcer with perforation',
  'K286 Chronic or unspecified gastrojejunal ulcer with both hemorrhage and perforation',
  'K287 Chronic gastrojejunal ulcer without hemorrhage or perforation',
  'K289 Gastrojejunal ulcer, unspecified as acute or chronic, without hemorrhage or perforation',
  'K2900 Acute gastritis without bleeding',
  'K2901 Acute gastritis with bleeding',
  'K2920 Alcoholic gastritis without bleeding',
  'K2921 Alcoholic gastritis with bleeding',
  'K2930 Chronic superficial gastritis without bleeding',
  'K2931 Chronic superficial gastritis with bleeding',
  'K2940 Chronic atrophic gastritis without bleeding',
  'K2941 Chronic atrophic gastritis with bleeding',
  'K2950 Unspecified chronic gastritis without bleeding',
  'K2951 Unspecified chronic gastritis with bleeding',
  'K2960 Other gastritis without bleeding',
  'K2961 Other gastritis with bleeding',
  'K2970 Gastritis, unspecified, without bleeding',
  'K2971 Gastritis, unspecified, with bleeding',
  'K2980 Duodenitis without bleeding',
  'K2981 Duodenitis with bleeding',
  'K2990 Gastroduodenitis, unspecified, without bleeding',
  'K2991 Gastroduodenitis, unspecified, with bleeding',
  'K30 Functional dyspepsia',
  'K310 Acute dilatation of stomach',
  'K311 Adult hypertrophic pyloric stenosis',
  'K312 Hourglass stricture and stenosis of stomach',
  'K313 Pylorospasm, not elsewhere classified',
  'K314 Gastric diverticulum',
  'K315 Obstruction of duodenum',
  'K316 Fistula of stomach and duodenum',
  'K317 Polyp of stomach and duodenum',
  'K31811 Angiodysplasia of stomach and duodenum with bleeding',
  'K31819 Angiodysplasia of stomach and duodenum without bleeding',
  'K3182 Dieulafoy lesion (hemorrhagic) of stomach and duodenum',
  'K3183 Achlorhydria',
  'K3184 Gastroparesis',
  'K3189 Other diseases of stomach and duodenum',
  'K319 Disease of stomach and duodenum, unspecified',
  'K352 Acute appendicitis with generalized peritonitis',
  'K353 Acute appendicitis with localized peritonitis',
  'K3580 Unspecified acute appendicitis',
  'K3589 Other acute appendicitis',
  'K36 Other appendicitis',
  'K37 Unspecified appendicitis',
  'K380 Hyperplasia of appendix',
  'K381 Appendicular concretions',
  'K382 Diverticulum of appendix',
  'K383 Fistula of appendix',
  'K388 Other specified diseases of appendix',
  'K389 Disease of appendix, unspecified',
  'K4000 Bilateral inguinal hernia, with obstruction, without gangrene, not specified as recurrent',
  'K4001 Bilateral inguinal hernia, with obstruction, without gangrene, recurrent',
  'K4010 Bilateral inguinal hernia, with gangrene, not specified as recurrent',
  'K4011 Bilateral inguinal hernia, with gangrene, recurrent',
  'K4020 Bilateral inguinal hernia, without obstruction or gangrene, not specified as recurrent',
  'K4021 Bilateral inguinal hernia, without obstruction or gangrene, recurrent',
  'K4030 Unilateral inguinal hernia, with obstruction, without gangrene, not specified as recurrent',
  'K4031 Unilateral inguinal hernia, with obstruction, without gangrene, recurrent',
  'K4040 Unilateral inguinal hernia, with gangrene, not specified as recurrent',
  'K4041 Unilateral inguinal hernia, with gangrene, recurrent',
  'K4090 Unilateral inguinal hernia, without obstruction or gangrene, not specified as recurrent',
  'K4091 Unilateral inguinal hernia, without obstruction or gangrene, recurrent',
  'K4100 Bilateral femoral hernia, with obstruction, without gangrene, not specified as recurrent',
  'K4101 Bilateral femoral hernia, with obstruction, without gangrene, recurrent',
  'K4110 Bilateral femoral hernia, with gangrene, not specified as recurrent',
  'K4111 Bilateral femoral hernia, with gangrene, recurrent',
  'K4120 Bilateral femoral hernia, without obstruction or gangrene, not specified as recurrent',
  'K4121 Bilateral femoral hernia, without obstruction or gangrene, recurrent',
  'K4130 Unilateral femoral hernia, with obstruction, without gangrene, not specified as recurrent',
  'K4131 Unilateral femoral hernia, with obstruction, without gangrene, recurrent',
  'K4140 Unilateral femoral hernia, with gangrene, not specified as recurrent',
  'K4141 Unilateral femoral hernia, with gangrene, recurrent',
  'K4190 Unilateral femoral hernia, without obstruction or gangrene, not specified as recurrent',
  'K4191 Unilateral femoral hernia, without obstruction or gangrene, recurrent',
  'K420 Umbilical hernia with obstruction, without gangrene',
  'K421 Umbilical hernia with gangrene',
  'K429 Umbilical hernia without obstruction or gangrene',
  'K430 Incisional hernia with obstruction, without gangrene',
  'K431 Incisional hernia with gangrene',
  'K432 Incisional hernia without obstruction or gangrene',
  'K433 Parastomal hernia with obstruction, without gangrene',
  'K434 Parastomal hernia with gangrene',
  'K435 Parastomal hernia without obstruction or gangrene',
  'K436 Other and unspecified ventral hernia with obstruction, without gangrene',
  'K437 Other and unspecified ventral hernia with gangrene',
  'K439 Ventral hernia without obstruction or gangrene',
  'K440 Diaphragmatic hernia with obstruction, without gangrene',
  'K441 Diaphragmatic hernia with gangrene',
  'K449 Diaphragmatic hernia without obstruction or gangrene',
  'K450 Other specified abdominal hernia with obstruction, without gangrene',
  'K451 Other specified abdominal hernia with gangrene',
  'K458 Other specified abdominal hernia without obstruction or gangrene',
  'K460 Unspecified abdominal hernia with obstruction, without gangrene',
  'K461 Unspecified abdominal hernia with gangrene',
  'K469 Unspecified abdominal hernia without obstruction or gangrene',
  "K5000 Crohn's disease of small intestine without complications",
  "K50011 Crohn's disease of small intestine with rectal bleeding",
  "K50012 Crohn's disease of small intestine with intestinal obstruction",
  "K50013 Crohn's disease of small intestine with fistula",
  "K50014 Crohn's disease of small intestine with abscess",
  "K50018 Crohn's disease of small intestine with other complication",
  "K50019 Crohn's disease of small intestine with unspecified complications",
  "K5010 Crohn's disease of large intestine without complications",
  "K50111 Crohn's disease of large intestine with rectal bleeding",
  "K50112 Crohn's disease of large intestine with intestinal obstruction",
  "K50113 Crohn's disease of large intestine with fistula",
  "K50114 Crohn's disease of large intestine with abscess",
  "K50118 Crohn's disease of large intestine with other complication",
  "K50119 Crohn's disease of large intestine with unspecified complications",
  "K5080 Crohn's disease of both small and large intestine without complications",
  "K50811 Crohn's disease of both small and large intestine with rectal bleeding",
  "K50812 Crohn's disease of both small and large intestine with intestinal obstruction",
  "K50813 Crohn's disease of both small and large intestine with fistula",
  "K50814 Crohn's disease of both small and large intestine with abscess",
  "K50818 Crohn's disease of both small and large intestine with other complication",
  "K50819 Crohn's disease of both small and large intestine with unspecified complications",
  "K5090 Crohn's disease, unspecified, without complications",
  "K50911 Crohn's disease, unspecified, with rectal bleeding",
  "K50912 Crohn's disease, unspecified, with intestinal obstruction",
  "K50913 Crohn's disease, unspecified, with fistula",
  "K50914 Crohn's disease, unspecified, with abscess",
  "K50918 Crohn's disease, unspecified, with other complication",
  "K50919 Crohn's disease, unspecified, with unspecified complications",
  'K5100 Ulcerative (chronic) pancolitis without complications',
  'K51011 Ulcerative (chronic) pancolitis with rectal bleeding',
  'K51012 Ulcerative (chronic) pancolitis with intestinal obstruction',
  'K51013 Ulcerative (chronic) pancolitis with fistula',
  'K51014 Ulcerative (chronic) pancolitis with abscess',
  'K51018 Ulcerative (chronic) pancolitis with other complication',
  'K51019 Ulcerative (chronic) pancolitis with unspecified complications',
  'K5120 Ulcerative (chronic) proctitis without complications',
  'K51211 Ulcerative (chronic) proctitis with rectal bleeding',
  'K51212 Ulcerative (chronic) proctitis with intestinal obstruction',
  'K51213 Ulcerative (chronic) proctitis with fistula',
  'K51214 Ulcerative (chronic) proctitis with abscess',
  'K51218 Ulcerative (chronic) proctitis with other complication',
  'K51219 Ulcerative (chronic) proctitis with unspecified complications',
  'K5130 Ulcerative (chronic) rectosigmoiditis without complications',
  'K51311 Ulcerative (chronic) rectosigmoiditis with rectal bleeding',
  'K51312 Ulcerative (chronic) rectosigmoiditis with intestinal obstruction',
  'K51313 Ulcerative (chronic) rectosigmoiditis with fistula',
  'K51314 Ulcerative (chronic) rectosigmoiditis with abscess',
  'K51318 Ulcerative (chronic) rectosigmoiditis with other complication',
  'K51319 Ulcerative (chronic) rectosigmoiditis with unspecified complications',
  'K5140 Inflammatory polyps of colon without complications',
  'K51411 Inflammatory polyps of colon with rectal bleeding',
  'K51412 Inflammatory polyps of colon with intestinal obstruction',
  'K51413 Inflammatory polyps of colon with fistula',
  'K51414 Inflammatory polyps of colon with abscess',
  'K51418 Inflammatory polyps of colon with other complication',
  'K51419 Inflammatory polyps of colon with unspecified complications',
  'K5150 Left sided colitis without complications',
  'K51511 Left sided colitis with rectal bleeding',
  'K51512 Left sided colitis with intestinal obstruction',
  'K51513 Left sided colitis with fistula',
  'K51514 Left sided colitis with abscess',
  'K51518 Left sided colitis with other complication',
  'K51519 Left sided colitis with unspecified complications',
  'K5180 Other ulcerative colitis without complications',
  'K51811 Other ulcerative colitis with rectal bleeding',
  'K51812 Other ulcerative colitis with intestinal obstruction',
  'K51813 Other ulcerative colitis with fistula',
  'K51814 Other ulcerative colitis with abscess',
  'K51818 Other ulcerative colitis with other complication',
  'K51819 Other ulcerative colitis with unspecified complications',
  'K5190 Ulcerative colitis, unspecified, without complications',
  'K51911 Ulcerative colitis, unspecified with rectal bleeding',
  'K51912 Ulcerative colitis, unspecified with intestinal obstruction',
  'K51913 Ulcerative colitis, unspecified with fistula',
  'K51914 Ulcerative colitis, unspecified with abscess',
  'K51918 Ulcerative colitis, unspecified with other complication',
  'K51919 Ulcerative colitis, unspecified with unspecified complications',
  'K520 Gastroenteritis and colitis due to radiation',
  'K521 Toxic gastroenteritis and colitis',
  'K5221 Food protein-induced enterocolitis syndrome',
  'K5222 Food protein-induced enteropathy',
  'K5229 Other allergic and dietetic gastroenteritis and colitis',
  'K523 Indeterminate colitis',
  'K5281 Eosinophilic gastritis or gastroenteritis',
  'K5282 Eosinophilic colitis',
  'K52831 Collagenous colitis',
  'K52832 Lymphocytic colitis',
  'K52838 Other microscopic colitis',
  'K52839 Microscopic colitis, unspecified',
  'K5289 Other specified noninfective gastroenteritis and colitis',
  'K529 Noninfective gastroenteritis and colitis, unspecified',
  'K55011 Focal (segmental) acute (reversible) ischemia of small intestine',
  'K55012 Diffuse acute (reversible) ischemia of small intestine',
  'K55019 Acute (reversible) ischemia of small intestine, extent unspecified',
  'K55021 Focal (segmental) acute infarction of small intestine',
  'K55022 Diffuse acute infarction of small intestine',
  'K55029 Acute infarction of small intestine, extent unspecified',
  'K55031 Focal (segmental) acute (reversible) ischemia of large intestine',
  'K55032 Diffuse acute (reversible) ischemia of large intestine',
  'K55039 Acute (reversible) ischemia of large intestine, extent unspecified',
  'K55041 Focal (segmental) acute infarction of large intestine',
  'K55042 Diffuse acute infarction of large intestine',
  'K55049 Acute infarction of large intestine, extent unspecified',
  'K55051 Focal (segmental) acute (reversible) ischemia of intestine, part unspecified',
  'K55052 Diffuse acute (reversible) ischemia of intestine, part unspecified',
  'K55059 Acute (reversible) ischemia of intestine, part and extent unspecified',
  'K55061 Focal (segmental) acute infarction of intestine, part unspecified',
  'K55062 Diffuse acute infarction of intestine, part unspecified',
  'K55069 Acute infarction of intestine, part and extent unspecified',
  'K551 Chronic vascular disorders of intestine',
  'K5520 Angiodysplasia of colon without hemorrhage',
  'K5521 Angiodysplasia of colon with hemorrhage',
  'K5530 Necrotizing enterocolitis, unspecified',
  'K5531 Stage 1 necrotizing enterocolitis',
  'K5532 Stage 2 necrotizing enterocolitis',
  'K5533 Stage 3 necrotizing enterocolitis',
  'K558 Other vascular disorders of intestine',
  'K559 Vascular disorder of intestine, unspecified',
  'K560 Paralytic ileus',
  'K561 Intussusception',
  'K562 Volvulus',
  'K563 Gallstone ileus',
  'K5641 Fecal impaction',
  'K5649 Other impaction of intestine',
  'K565 Intestinal adhesions [bands] with obstruction (postprocedural) (postinfection)',
  'K5660 Unspecified intestinal obstruction',
  'K5669 Other intestinal obstruction',
  'K567 Ileus, unspecified',
  'K5700 Diverticulitis of small intestine with perforation and abscess without bleeding',
  'K5701 Diverticulitis of small intestine with perforation and abscess with bleeding',
  'K5710 Diverticulosis of small intestine without perforation or abscess without bleeding',
  'K5711 Diverticulosis of small intestine without perforation or abscess with bleeding',
  'K5712 Diverticulitis of small intestine without perforation or abscess without bleeding',
  'K5713 Diverticulitis of small intestine without perforation or abscess with bleeding',
  'K5720 Diverticulitis of large intestine with perforation and abscess without bleeding',
  'K5721 Diverticulitis of large intestine with perforation and abscess with bleeding',
  'K5730 Diverticulosis of large intestine without perforation or abscess without bleeding',
  'K5731 Diverticulosis of large intestine without perforation or abscess with bleeding',
  'K5732 Diverticulitis of large intestine without perforation or abscess without bleeding',
  'K5733 Diverticulitis of large intestine without perforation or abscess with bleeding',
  'K5740 Diverticulitis of both small and large intestine with perforation and abscess without bleeding',
  'K5741 Diverticulitis of both small and large intestine with perforation and abscess with bleeding',
  'K5750 Diverticulosis of both small and large intestine without perforation or abscess without bleeding',
  'K5751 Diverticulosis of both small and large intestine without perforation or abscess with bleeding',
  'K5752 Diverticulitis of both small and large intestine without perforation or abscess without bleeding',
  'K5753 Diverticulitis of both small and large intestine without perforation or abscess with bleeding',
  'K5780 Diverticulitis of intestine, part unspecified, with perforation and abscess without bleeding',
  'K5781 Diverticulitis of intestine, part unspecified, with perforation and abscess with bleeding',
  'K5790 Diverticulosis of intestine, part unspecified, without perforation or abscess without bleeding',
  'K5791 Diverticulosis of intestine, part unspecified, without perforation or abscess with bleeding',
  'K5792 Diverticulitis of intestine, part unspecified, without perforation or abscess without bleeding',
  'K5793 Diverticulitis of intestine, part unspecified, without perforation or abscess with bleeding',
  'K580 Irritable bowel syndrome with diarrhea',
  'K581 Irritable bowel syndrome with constipation',
  'K582 Mixed irritable bowel syndrome',
  'K588 Other irritable bowel syndrome',
  'K589 Irritable bowel syndrome without diarrhea',
  'K5900 Constipation, unspecified',
  'K5901 Slow transit constipation',
  'K5902 Outlet dysfunction constipation',
  'K5903 Drug induced constipation',
  'K5904 Chronic idiopathic constipation',
  'K5909 Other constipation',
  'K591 Functional diarrhea',
  'K592 Neurogenic bowel, not elsewhere classified',
  'K5931 Toxic megacolon',
  'K5939 Other megacolon',
  'K594 Anal spasm',
  'K598 Other specified functional intestinal disorders',
  'K599 Functional intestinal disorder, unspecified',
  'K600 Acute anal fissure',
  'K601 Chronic anal fissure',
  'K602 Anal fissure, unspecified',
  'K603 Anal fistula',
  'K604 Rectal fistula',
  'K605 Anorectal fistula',
  'K610 Anal abscess',
  'K611 Rectal abscess',
  'K612 Anorectal abscess',
  'K613 Ischiorectal abscess',
  'K614 Intrasphincteric abscess',
  'K620 Anal polyp',
  'K621 Rectal polyp',
  'K622 Anal prolapse',
  'K623 Rectal prolapse',
  'K624 Stenosis of anus and rectum',
  'K625 Hemorrhage of anus and rectum',
  'K626 Ulcer of anus and rectum',
  'K627 Radiation proctitis',
  'K6281 Anal sphincter tear (healed) (nontraumatic) (old)',
  'K6282 Dysplasia of anus',
  'K6289 Other specified diseases of anus and rectum',
  'K629 Disease of anus and rectum, unspecified',
  'K630 Abscess of intestine',
  'K631 Perforation of intestine (nontraumatic)',
  'K632 Fistula of intestine',
  'K633 Ulcer of intestine',
  'K634 Enteroptosis',
  'K635 Polyp of colon',
  'K6381 Dieulafoy lesion of intestine',
  'K6389 Other specified diseases of intestine',
  'K639 Disease of intestine, unspecified',
  'K640 First degree hemorrhoids',
  'K641 Second degree hemorrhoids',
  'K642 Third degree hemorrhoids',
  'K643 Fourth degree hemorrhoids',
  'K644 Residual hemorrhoidal skin tags',
  'K645 Perianal venous thrombosis',
  'K648 Other hemorrhoids',
  'K649 Unspecified hemorrhoids',
  'K650 Generalized (acute) peritonitis',
  'K651 Peritoneal abscess',
  'K652 Spontaneous bacterial peritonitis',
  'K653 Choleperitonitis',
  'K654 Sclerosing mesenteritis',
  'K658 Other peritonitis',
  'K659 Peritonitis, unspecified',
  'K660 Peritoneal adhesions (postprocedural) (postinfection)',
  'K661 Hemoperitoneum',
  'K668 Other specified disorders of peritoneum',
  'K669 Disorder of peritoneum, unspecified',
  'K67 Disorders of peritoneum in infectious diseases classified elsewhere',
  'K6811 Postprocedural retroperitoneal abscess',
  'K6812 Psoas muscle abscess',
  'K6819 Other retroperitoneal abscess',
  'K689 Other disorders of retroperitoneum',
  'K700 Alcoholic fatty liver',
  'K7010 Alcoholic hepatitis without ascites',
  'K7011 Alcoholic hepatitis with ascites',
  'K702 Alcoholic fibrosis and sclerosis of liver',
  'K7030 Alcoholic cirrhosis of liver without ascites',
  'K7031 Alcoholic cirrhosis of liver with ascites',
  'K7040 Alcoholic hepatic failure without coma',
  'K7041 Alcoholic hepatic failure with coma',
  'K709 Alcoholic liver disease, unspecified',
  'K710 Toxic liver disease with cholestasis',
  'K7110 Toxic liver disease with hepatic necrosis, without coma',
  'K7111 Toxic liver disease with hepatic necrosis, with coma',
  'K712 Toxic liver disease with acute hepatitis',
  'K713 Toxic liver disease with chronic persistent hepatitis',
  'K714 Toxic liver disease with chronic lobular hepatitis',
  'K7150 Toxic liver disease with chronic active hepatitis without ascites',
  'K7151 Toxic liver disease with chronic active hepatitis with ascites',
  'K716 Toxic liver disease with hepatitis, not elsewhere classified',
  'K717 Toxic liver disease with fibrosis and cirrhosis of liver',
  'K718 Toxic liver disease with other disorders of liver',
  'K719 Toxic liver disease, unspecified',
  'K7200 Acute and subacute hepatic failure without coma',
  'K7201 Acute and subacute hepatic failure with coma',
  'K7210 Chronic hepatic failure without coma',
  'K7211 Chronic hepatic failure with coma',
  'K7290 Hepatic failure, unspecified without coma',
  'K7291 Hepatic failure, unspecified with coma',
  'K730 Chronic persistent hepatitis, not elsewhere classified',
  'K731 Chronic lobular hepatitis, not elsewhere classified',
  'K732 Chronic active hepatitis, not elsewhere classified',
  'K738 Other chronic hepatitis, not elsewhere classified',
  'K739 Chronic hepatitis, unspecified',
  'K740 Hepatic fibrosis',
  'K741 Hepatic sclerosis',
  'K742 Hepatic fibrosis with hepatic sclerosis',
  'K743 Primary biliary cirrhosis',
  'K744 Secondary biliary cirrhosis',
  'K745 Biliary cirrhosis, unspecified',
  'K7460 Unspecified cirrhosis of liver',
  'K7469 Other cirrhosis of liver',
  'K750 Abscess of liver',
  'K751 Phlebitis of portal vein',
  'K752 Nonspecific reactive hepatitis',
  'K753 Granulomatous hepatitis, not elsewhere classified',
  'K754 Autoimmune hepatitis',
  'K7581 Nonalcoholic steatohepatitis (NASH)',
  'K7589 Other specified inflammatory liver diseases',
  'K759 Inflammatory liver disease, unspecified',
  'K760 Fatty (change of) liver, not elsewhere classified',
  'K761 Chronic passive congestion of liver',
  'K762 Central hemorrhagic necrosis of liver',
  'K763 Infarction of liver',
  'K764 Peliosis hepatis',
  'K765 Hepatic veno-occlusive disease',
  'K766 Portal hypertension',
  'K767 Hepatorenal syndrome',
  'K7681 Hepatopulmonary syndrome',
  'K7689 Other specified diseases of liver',
  'K769 Liver disease, unspecified',
  'K77 Liver disorders in diseases classified elsewhere',
  'K8000 Calculus of gallbladder with acute cholecystitis without obstruction',
  'K8001 Calculus of gallbladder with acute cholecystitis with obstruction',
  'K8010 Calculus of gallbladder with chronic cholecystitis without obstruction',
  'K8011 Calculus of gallbladder with chronic cholecystitis with obstruction',
  'K8012 Calculus of gallbladder with acute and chronic cholecystitis without obstruction',
  'K8013 Calculus of gallbladder with acute and chronic cholecystitis with obstruction',
  'K8018 Calculus of gallbladder with other cholecystitis without obstruction',
  'K8019 Calculus of gallbladder with other cholecystitis with obstruction',
  'K8020 Calculus of gallbladder without cholecystitis without obstruction',
  'K8021 Calculus of gallbladder without cholecystitis with obstruction',
  'K8030 Calculus of bile duct with cholangitis, unspecified, without obstruction',
  'K8031 Calculus of bile duct with cholangitis, unspecified, with obstruction',
  'K8032 Calculus of bile duct with acute cholangitis without obstruction',
  'K8033 Calculus of bile duct with acute cholangitis with obstruction',
  'K8034 Calculus of bile duct with chronic cholangitis without obstruction',
  'K8035 Calculus of bile duct with chronic cholangitis with obstruction',
  'K8036 Calculus of bile duct with acute and chronic cholangitis without obstruction',
  'K8037 Calculus of bile duct with acute and chronic cholangitis with obstruction',
  'K8040 Calculus of bile duct with cholecystitis, unspecified, without obstruction',
  'K8041 Calculus of bile duct with cholecystitis, unspecified, with obstruction',
  'K8042 Calculus of bile duct with acute cholecystitis without obstruction',
  'K8043 Calculus of bile duct with acute cholecystitis with obstruction',
  'K8044 Calculus of bile duct with chronic cholecystitis without obstruction',
  'K8045 Calculus of bile duct with chronic cholecystitis with obstruction',
  'K8046 Calculus of bile duct with acute and chronic cholecystitis without obstruction',
  'K8047 Calculus of bile duct with acute and chronic cholecystitis with obstruction',
  'K8050 Calculus of bile duct without cholangitis or cholecystitis without obstruction',
  'K8051 Calculus of bile duct without cholangitis or cholecystitis with obstruction',
  'K8060 Calculus of gallbladder and bile duct with cholecystitis, unspecified, without obstruction',
  'K8061 Calculus of gallbladder and bile duct with cholecystitis, unspecified, with obstruction',
  'K8062 Calculus of gallbladder and bile duct with acute cholecystitis without obstruction',
  'K8063 Calculus of gallbladder and bile duct with acute cholecystitis with obstruction',
  'K8064 Calculus of gallbladder and bile duct with chronic cholecystitis without obstruction',
  'K8065 Calculus of gallbladder and bile duct with chronic cholecystitis with obstruction',
  'K8066 Calculus of gallbladder and bile duct with acute and chronic cholecystitis without obstruction',
  'K8067 Calculus of gallbladder and bile duct with acute and chronic cholecystitis with obstruction',
  'K8070 Calculus of gallbladder and bile duct without cholecystitis without obstruction',
  'K8071 Calculus of gallbladder and bile duct without cholecystitis with obstruction',
  'K8080 Other cholelithiasis without obstruction',
  'K8081 Other cholelithiasis with obstruction',
  'K810 Acute cholecystitis',
  'K811 Chronic cholecystitis',
  'K812 Acute cholecystitis with chronic cholecystitis',
  'K819 Cholecystitis, unspecified',
  'K820 Obstruction of gallbladder',
  'K821 Hydrops of gallbladder',
  'K822 Perforation of gallbladder',
  'K823 Fistula of gallbladder',
  'K824 Cholesterolosis of gallbladder',
  'K828 Other specified diseases of gallbladder',
  'K829 Disease of gallbladder, unspecified',
  'K830 Cholangitis',
  'K831 Obstruction of bile duct',
  'K832 Perforation of bile duct',
  'K833 Fistula of bile duct',
  'K834 Spasm of sphincter of Oddi',
  'K835 Biliary cyst',
  'K838 Other specified diseases of biliary tract',
  'K839 Disease of biliary tract, unspecified',
  'K8500 Idiopathic acute pancreatitis without necrosis or infection',
  'K8501 Idiopathic acute pancreatitis with uninfected necrosis',
  'K8502 Idiopathic acute pancreatitis with infected necrosis',
  'K8510 Biliary acute pancreatitis without necrosis or infection',
  'K8511 Biliary acute pancreatitis with uninfected necrosis',
  'K8512 Biliary acute pancreatitis with infected necrosis',
  'K8520 Alcohol induced acute pancreatitis without necrosis or infection',
  'K8521 Alcohol induced acute pancreatitis with uninfected necrosis',
  'K8522 Alcohol induced acute pancreatitis with infected necrosis',
  'K8530 Drug induced acute pancreatitis without necrosis or infection',
  'K8531 Drug induced acute pancreatitis with uninfected necrosis',
  'K8532 Drug induced acute pancreatitis with infected necrosis',
  'K8580 Other acute pancreatitis without necrosis or infection',
  'K8581 Other acute pancreatitis with uninfected necrosis',
  'K8582 Other acute pancreatitis with infected necrosis',
  'K8590 Acute pancreatitis without necrosis or infection, unspecified',
  'K8591 Acute pancreatitis with uninfected necrosis, unspecified',
  'K8592 Acute pancreatitis with infected necrosis, unspecified',
  'K860 Alcohol-induced chronic pancreatitis',
  'K861 Other chronic pancreatitis',
  'K862 Cyst of pancreas',
  'K863 Pseudocyst of pancreas',
  'K8681 Exocrine pancreatic insufficiency',
  'K8689 Other specified diseases of pancreas',
  'K869 Disease of pancreas, unspecified',
  'K87 Disorders of gallbladder, biliary tract and pancreas in diseases classified elsewhere',
  'K900 Celiac disease',
  'K901 Tropical sprue',
  'K902 Blind loop syndrome, not elsewhere classified',
  'K903 Pancreatic steatorrhea',
  'K9041 Non-celiac gluten sensitivity',
  'K9049 Malabsorption due to intolerance, not elsewhere classified',
  "K9081 Whipple's disease",
  'K9089 Other intestinal malabsorption',
  'K909 Intestinal malabsorption, unspecified',
  'K910 Vomiting following gastrointestinal surgery',
  'K911 Postgastric surgery syndromes',
  'K912 Postsurgical malabsorption, not elsewhere classified',
  'K913 Postprocedural intestinal obstruction',
  'K915 Postcholecystectomy syndrome',
  'K9161 Intraoperative hemorrhage and hematoma of a digestive system organ or structure complicating',
  'a digestive system procedure',
  'K9162 Intraoperative hemorrhage and hematoma of a digestive system organ or structure complicating',
  'other procedure',
  'K9171 Accidental puncture and laceration of a digestive system organ or structure during a digestive',
  'system procedure',
  'K9172 Accidental puncture and laceration of a digestive system organ or structure during other',
  'procedure',
  'K9181 Other intraoperative complications of digestive system',
  'K9182 Postprocedural hepatic failure',
  'K9183 Postprocedural hepatorenal syndrome',
  'K91840 Postprocedural hemorrhage of a digestive system organ or structure following a digestive',
  'system procedure',
  'K91841 Postprocedural hemorrhage of a digestive system organ or structure following other procedure',
  'K91850 Pouchitis',
  'K91858 Other complications of intestinal pouch',
  'K9186 Retained cholelithiasis following cholecystectomy',
  'K91870 Postprocedural hematoma of a digestive system organ or structure following a digestive system',
  'procedure',
  'K91871 Postprocedural hematoma of a digestive system organ or structure following other procedure',
  'K91872 Postprocedural seroma of a digestive system organ or structure following a digestive system',
  'procedure',
  'K91873 Postprocedural seroma of a digestive system organ or structure following other procedure',
  'K9189 Other postprocedural complications and disorders of digestive system',
  'K920 Hematemesis',
  'K921 Melena',
  'K922 Gastrointestinal hemorrhage, unspecified',
  'K9281 Gastrointestinal mucositis (ulcerative)',
  'K9289 Other specified diseases of the digestive system',
  'K929 Disease of digestive system, unspecified',
  'K9400 Colostomy complication, unspecified',
  'K9401 Colostomy hemorrhage',
  'K9402 Colostomy infection',
  'K9403 Colostomy malfunction',
  'K9409 Other complications of colostomy',
  'K9410 Enterostomy complication, unspecified',
  'K9411 Enterostomy hemorrhage',
  'K9412 Enterostomy infection',
  'K9413 Enterostomy malfunction',
  'K9419 Other complications of enterostomy',
  'K9420 Gastrostomy complication, unspecified',
  'K9421 Gastrostomy hemorrhage',
  'K9422 Gastrostomy infection',
  'K9423 Gastrostomy malfunction',
  'K9429 Other complications of gastrostomy',
  'K9430 Esophagostomy complications, unspecified',
  'K9431 Esophagostomy hemorrhage',
  'K9432 Esophagostomy infection',
  'K9433 Esophagostomy malfunction',
  'K9439 Other complications of esophagostomy',
  'K9501 Infection due to gastric band procedure',
  'K9509 Other complications of gastric band procedure',
  'K9581 Infection due to other bariatric procedure',
  'K9589 Other complications of other bariatric procedure',
  'L00 Staphylococcal scalded skin syndrome',
  'L0100 Impetigo, unspecified',
  'L0101 Non-bullous impetigo',
  "L0102 Bockhart's impetigo",
  'L0103 Bullous impetigo',
  'L0109 Other impetigo',
  'L011 Impetiginization of other dermatoses',
  'L0201 Cutaneous abscess of face',
  'L0202 Furuncle of face',
  'L0203 Carbuncle of face',
  'L0211 Cutaneous abscess of neck',
  'L0212 Furuncle of neck',
  'L0213 Carbuncle of neck',
  'L02211 Cutaneous abscess of abdominal wall',
  'L02212 Cutaneous abscess of back [any part, except buttock]',
  'L02213 Cutaneous abscess of chest wall',
  'L02214 Cutaneous abscess of groin',
  'L02215 Cutaneous abscess of perineum',
  'L02216 Cutaneous abscess of umbilicus',
  'L02219 Cutaneous abscess of trunk, unspecified',
  'L02221 Furuncle of abdominal wall',
  'L02222 Furuncle of back [any part, except buttock]',
  'L02223 Furuncle of chest wall',
  'L02224 Furuncle of groin',
  'L02225 Furuncle of perineum',
  'L02226 Furuncle of umbilicus',
  'L02229 Furuncle of trunk, unspecified',
  'L02231 Carbuncle of abdominal wall',
  'L02232 Carbuncle of back [any part, except buttock]',
  'L02233 Carbuncle of chest wall',
  'L02234 Carbuncle of groin',
  'L02235 Carbuncle of perineum',
  'L02236 Carbuncle of umbilicus',
  'L02239 Carbuncle of trunk, unspecified',
  'L0231 Cutaneous abscess of buttock',
  'L0232 Furuncle of buttock',
  'L0233 Carbuncle of buttock',
  'L02411 Cutaneous abscess of right axilla',
  'L02412 Cutaneous abscess of left axilla',
  'L02413 Cutaneous abscess of right upper limb',
  'L02414 Cutaneous abscess of left upper limb',
  'L02415 Cutaneous abscess of right lower limb',
  'L02416 Cutaneous abscess of left lower limb',
  'L02419 Cutaneous abscess of limb, unspecified',
  'L02421 Furuncle of right axilla',
  'L02422 Furuncle of left axilla',
  'L02423 Furuncle of right upper limb',
  'L02424 Furuncle of left upper limb',
  'L02425 Furuncle of right lower limb',
  'L02426 Furuncle of left lower limb',
  'L02429 Furuncle of limb, unspecified',
  'L02431 Carbuncle of right axilla',
  'L02432 Carbuncle of left axilla',
  'L02433 Carbuncle of right upper limb',
  'L02434 Carbuncle of left upper limb',
  'L02435 Carbuncle of right lower limb',
  'L02436 Carbuncle of left lower limb',
  'L02439 Carbuncle of limb, unspecified',
  'L02511 Cutaneous abscess of right hand',
  'L02512 Cutaneous abscess of left hand',
  'L02519 Cutaneous abscess of unspecified hand',
  'L02521 Furuncle right hand',
  'L02522 Furuncle left hand',
  'L02529 Furuncle unspecified hand',
  'L02531 Carbuncle of right hand',
  'L02532 Carbuncle of left hand',
  'L02539 Carbuncle of unspecified hand',
  'L02611 Cutaneous abscess of right foot',
  'L02612 Cutaneous abscess of left foot',
  'L02619 Cutaneous abscess of unspecified foot',
  'L02621 Furuncle of right foot',
  'L02622 Furuncle of left foot',
  'L02629 Furuncle of unspecified foot',
  'L02631 Carbuncle of right foot',
  'L02632 Carbuncle of left foot',
  'L02639 Carbuncle of unspecified foot',
  'L02811 Cutaneous abscess of head [any part, except face]',
  'L02818 Cutaneous abscess of other sites',
  'L02821 Furuncle of head [any part, except face]',
  'L02828 Furuncle of other sites',
  'L02831 Carbuncle of head [any part, except face]',
  'L02838 Carbuncle of other sites',
  'L0291 Cutaneous abscess, unspecified',
  'L0292 Furuncle, unspecified',
  'L0293 Carbuncle, unspecified',
  'L03011 Cellulitis of right finger',
  'L03012 Cellulitis of left finger',
  'L03019 Cellulitis of unspecified finger',
  'L03021 Acute lymphangitis of right finger',
  'L03022 Acute lymphangitis of left finger',
  'L03029 Acute lymphangitis of unspecified finger',
  'L03031 Cellulitis of right toe',
  'L03032 Cellulitis of left toe',
  'L03039 Cellulitis of unspecified toe',
  'L03041 Acute lymphangitis of right toe',
  'L03042 Acute lymphangitis of left toe',
  'L03049 Acute lymphangitis of unspecified toe',
  'L03111 Cellulitis of right axilla',
  'L03112 Cellulitis of left axilla',
  'L03113 Cellulitis of right upper limb',
  'L03114 Cellulitis of left upper limb',
  'L03115 Cellulitis of right lower limb',
  'L03116 Cellulitis of left lower limb',
  'L03119 Cellulitis of unspecified part of limb',
  'L03121 Acute lymphangitis of right axilla',
  'L03122 Acute lymphangitis of left axilla',
  'L03123 Acute lymphangitis of right upper limb',
  'L03124 Acute lymphangitis of left upper limb',
  'L03125 Acute lymphangitis of right lower limb',
  'L03126 Acute lymphangitis of left lower limb',
  'L03129 Acute lymphangitis of unspecified part of limb',
  'L03211 Cellulitis of face',
  'L03212 Acute lymphangitis of face',
  'L03213 Periorbital cellulitis',
  'L03221 Cellulitis of neck',
  'L03222 Acute lymphangitis of neck',
  'L03311 Cellulitis of abdominal wall',
  'L03312 Cellulitis of back [any part except buttock]',
  'L03313 Cellulitis of chest wall',
  'L03314 Cellulitis of groin',
  'L03315 Cellulitis of perineum',
  'L03316 Cellulitis of umbilicus',
  'L03317 Cellulitis of buttock',
  'L03319 Cellulitis of trunk, unspecified',
  'L03321 Acute lymphangitis of abdominal wall',
  'L03322 Acute lymphangitis of back [any part except buttock]',
  'L03323 Acute lymphangitis of chest wall',
  'L03324 Acute lymphangitis of groin',
  'L03325 Acute lymphangitis of perineum',
  'L03326 Acute lymphangitis of umbilicus',
  'L03327 Acute lymphangitis of buttock',
  'L03329 Acute lymphangitis of trunk, unspecified',
  'L03811 Cellulitis of head [any part, except face]',
  'L03818 Cellulitis of other sites',
  'L03891 Acute lymphangitis of head [any part, except face]',
  'L03898 Acute lymphangitis of other sites',
  'L0390 Cellulitis, unspecified',
  'L0391 Acute lymphangitis, unspecified',
  'L040 Acute lymphadenitis of face, head and neck',
  'L041 Acute lymphadenitis of trunk',
  'L042 Acute lymphadenitis of upper limb',
  'L043 Acute lymphadenitis of lower limb',
  'L048 Acute lymphadenitis of other sites',
  'L049 Acute lymphadenitis, unspecified',
  'L0501 Pilonidal cyst with abscess',
  'L0502 Pilonidal sinus with abscess',
  'L0591 Pilonidal cyst without abscess',
  'L0592 Pilonidal sinus without abscess',
  'L080 Pyoderma',
  'L081 Erythrasma',
  'L0881 Pyoderma vegetans',
  'L0882 Omphalitis not of newborn',
  'L0889 Other specified local infections of the skin and subcutaneous tissue',
  'L089 Local infection of the skin and subcutaneous tissue, unspecified',
  'L100 Pemphigus vulgaris',
  'L101 Pemphigus vegetans',
  'L102 Pemphigus foliaceous',
  'L103 Brazilian pemphigus [fogo selvagem]',
  'L104 Pemphigus erythematosus',
  'L105 Drug-induced pemphigus',
  'L1081 Paraneoplastic pemphigus',
  'L1089 Other pemphigus',
  'L109 Pemphigus, unspecified',
  'L110 Acquired keratosis follicularis',
  'L111 Transient acantholytic dermatosis [Grover]',
  'L118 Other specified acantholytic disorders',
  'L119 Acantholytic disorder, unspecified',
  'L120 Bullous pemphigoid',
  'L121 Cicatricial pemphigoid',
  'L122 Chronic bullous disease of childhood',
  'L1230 Acquired epidermolysis bullosa, unspecified',
  'L1231 Epidermolysis bullosa due to drug',
  'L1235 Other acquired epidermolysis bullosa',
  'L128 Other pemphigoid',
  'L129 Pemphigoid, unspecified',
  'L130 Dermatitis herpetiformis',
  'L131 Subcorneal pustular dermatitis',
  'L138 Other specified bullous disorders',
  'L139 Bullous disorder, unspecified',
  'L14 Bullous disorders in diseases classified elsewhere',
  "L200 Besnier's prurigo",
  'L2081 Atopic neurodermatitis',
  'L2082 Flexural eczema',
  'L2083 Infantile (acute) (chronic) eczema',
  'L2084 Intrinsic (allergic) eczema',
  'L2089 Other atopic dermatitis',
  'L209 Atopic dermatitis, unspecified',
  'L210 Seborrhea capitis',
  'L211 Seborrheic infantile dermatitis',
  'L218 Other seborrheic dermatitis',
  'L219 Seborrheic dermatitis, unspecified',
  'L22 Diaper dermatitis',
  'L230 Allergic contact dermatitis due to metals',
  'L231 Allergic contact dermatitis due to adhesives',
  'L232 Allergic contact dermatitis due to cosmetics',
  'L233 Allergic contact dermatitis due to drugs in contact with skin',
  'L234 Allergic contact dermatitis due to dyes',
  'L235 Allergic contact dermatitis due to other chemical products',
  'L236 Allergic contact dermatitis due to food in contact with the skin',
  'L237 Allergic contact dermatitis due to plants, except food',
  'L2381 Allergic contact dermatitis due to animal (cat) (dog) dander',
  'L2389 Allergic contact dermatitis due to other agents',
  'L239 Allergic contact dermatitis, unspecified cause',
  'L240 Irritant contact dermatitis due to detergents',
  'L241 Irritant contact dermatitis due to oils and greases',
  'L242 Irritant contact dermatitis due to solvents',
  'L243 Irritant contact dermatitis due to cosmetics',
  'L244 Irritant contact dermatitis due to drugs in contact with skin',
  'L245 Irritant contact dermatitis due to other chemical products',
  'L246 Irritant contact dermatitis due to food in contact with skin',
  'L247 Irritant contact dermatitis due to plants, except food',
  'L2481 Irritant contact dermatitis due to metals',
  'L2489 Irritant contact dermatitis due to other agents',
  'L249 Irritant contact dermatitis, unspecified cause',
  'L250 Unspecified contact dermatitis due to cosmetics',
  'L251 Unspecified contact dermatitis due to drugs in contact with skin',
  'L252 Unspecified contact dermatitis due to dyes',
  'L253 Unspecified contact dermatitis due to other chemical products',
  'L254 Unspecified contact dermatitis due to food in contact with skin',
  'L255 Unspecified contact dermatitis due to plants, except food',
  'L258 Unspecified contact dermatitis due to other agents',
  'L259 Unspecified contact dermatitis, unspecified cause',
  'L26 Exfoliative dermatitis',
  'L270 Generalized skin eruption due to drugs and medicaments taken internally',
  'L271 Localized skin eruption due to drugs and medicaments taken internally',
  'L272 Dermatitis due to ingested food',
  'L278 Dermatitis due to other substances taken internally',
  'L279 Dermatitis due to unspecified substance taken internally',
  'L280 Lichen simplex chronicus',
  'L281 Prurigo nodularis',
  'L282 Other prurigo',
  'L290 Pruritus ani',
  'L291 Pruritus scroti',
  'L292 Pruritus vulvae',
  'L293 Anogenital pruritus, unspecified',
  'L298 Other pruritus',
  'L299 Pruritus, unspecified',
  'L300 Nummular dermatitis',
  'L301 Dyshidrosis [pompholyx]',
  'L302 Cutaneous autosensitization',
  'L303 Infective dermatitis',
  'L304 Erythema intertrigo',
  'L305 Pityriasis alba',
  'L308 Other specified dermatitis',
  'L309 Dermatitis, unspecified',
  'L400 Psoriasis vulgaris',
  'L401 Generalized pustular psoriasis',
  'L402 Acrodermatitis continua',
  'L403 Pustulosis palmaris et plantaris',
  'L404 Guttate psoriasis',
  'L4050 Arthropathic psoriasis, unspecified',
  'L4051 Distal interphalangeal psoriatic arthropathy',
  'L4052 Psoriatic arthritis mutilans',
  'L4053 Psoriatic spondylitis',
  'L4054 Psoriatic juvenile arthropathy',
  'L4059 Other psoriatic arthropathy',
  'L408 Other psoriasis',
  'L409 Psoriasis, unspecified',
  'L410 Pityriasis lichenoides et varioliformis acuta',
  'L411 Pityriasis lichenoides chronica',
  'L413 Small plaque parapsoriasis',
  'L414 Large plaque parapsoriasis',
  'L415 Retiform parapsoriasis',
  'L418 Other parapsoriasis',
  'L419 Parapsoriasis, unspecified',
  'L42 Pityriasis rosea',
  'L430 Hypertrophic lichen planus',
  'L431 Bullous lichen planus',
  'L432 Lichenoid drug reaction',
  'L433 Subacute (active) lichen planus',
  'L438 Other lichen planus',
  'L439 Lichen planus, unspecified',
  'L440 Pityriasis rubra pilaris',
  'L441 Lichen nitidus',
  'L442 Lichen striatus',
  'L443 Lichen ruber moniliformis',
  'L444 Infantile papular acrodermatitis [Gianotti-Crosti]',
  'L448 Other specified papulosquamous disorders',
  'L449 Papulosquamous disorder, unspecified',
  'L45 Papulosquamous disorders in diseases classified elsewhere',
  'L490 Exfoliation due to erythematous condition involving less than 10 percent of body surface',
  'L491 Exfoliation due to erythematous condition involving 10-19 percent of body surface',
  'L492 Exfoliation due to erythematous condition involving 20-29 percent of body surface',
  'L493 Exfoliation due to erythematous condition involving 30-39 percent of body surface',
  'L494 Exfoliation due to erythematous condition involving 40-49 percent of body surface',
  'L495 Exfoliation due to erythematous condition involving 50-59 percent of body surface',
  'L496 Exfoliation due to erythematous condition involving 60-69 percent of body surface',
  'L497 Exfoliation due to erythematous condition involving 70-79 percent of body surface',
  'L498 Exfoliation due to erythematous condition involving 80-89 percent of body surface',
  'L499 Exfoliation due to erythematous condition involving 90 or more percent of body surface',
  'L500 Allergic urticaria',
  'L501 Idiopathic urticaria',
  'L502 Urticaria due to cold and heat',
  'L503 Dermatographic urticaria',
  'L504 Vibratory urticaria',
  'L505 Cholinergic urticaria',
  'L506 Contact urticaria',
  'L508 Other urticaria',
  'L509 Urticaria, unspecified',
  'L510 Nonbullous erythema multiforme',
  'L511 Stevens-Johnson syndrome',
  'L512 Toxic epidermal necrolysis [Lyell]',
  'L513 Stevens-Johnson syndrome-toxic epidermal necrolysis overlap syndrome',
  'L518 Other erythema multiforme',
  'L519 Erythema multiforme, unspecified',
  'L52 Erythema nodosum',
  'L530 Toxic erythema',
  'L531 Erythema annulare centrifugum',
  'L532 Erythema marginatum',
  'L533 Other chronic figurate erythema',
  'L538 Other specified erythematous conditions',
  'L539 Erythematous condition, unspecified',
  'L54 Erythema in diseases classified elsewhere',
  'L550 Sunburn of first degree',
  'L551 Sunburn of second degree',
  'L552 Sunburn of third degree',
  'L559 Sunburn, unspecified',
  'L560 Drug phototoxic response',
  'L561 Drug photoallergic response',
  'L562 Photocontact dermatitis [berloque dermatitis]',
  'L563 Solar urticaria',
  'L564 Polymorphous light eruption',
  'L565 Disseminated superficial actinic porokeratosis (DSAP)',
  'L568 Other specified acute skin changes due to ultraviolet radiation',
  'L569 Acute skin change due to ultraviolet radiation, unspecified',
  'L570 Actinic keratosis',
  'L571 Actinic reticuloid',
  'L572 Cutis rhomboidalis nuchae',
  'L573 Poikiloderma of Civatte',
  'L574 Cutis laxa senilis',
  'L575 Actinic granuloma',
  'L578 Other skin changes due to chronic exposure to nonionizing radiation',
  'L579 Skin changes due to chronic exposure to nonionizing radiation, unspecified',
  'L580 Acute radiodermatitis',
  'L581 Chronic radiodermatitis',
  'L589 Radiodermatitis, unspecified',
  'L590 Erythema ab igne [dermatitis ab igne]',
  'L598 Other specified disorders of the skin and subcutaneous tissue related to radiation',
  'L599 Disorder of the skin and subcutaneous tissue related to radiation, unspecified',
  'L600 Ingrowing nail',
  'L601 Onycholysis',
  'L602 Onychogryphosis',
  'L603 Nail dystrophy',
  "L604 Beau's lines",
  'L605 Yellow nail syndrome',
  'L608 Other nail disorders',
  'L609 Nail disorder, unspecified',
  'L62 Nail disorders in diseases classified elsewhere',
  'L630 Alopecia (capitis) totalis',
  'L631 Alopecia universalis',
  'L632 Ophiasis',
  'L638 Other alopecia areata',
  'L639 Alopecia areata, unspecified',
  'L640 Drug-induced androgenic alopecia',
  'L648 Other androgenic alopecia',
  'L649 Androgenic alopecia, unspecified',
  'L650 Telogen effluvium',
  'L651 Anagen effluvium',
  'L652 Alopecia mucinosa',
  'L658 Other specified nonscarring hair loss',
  'L659 Nonscarring hair loss, unspecified',
  'L660 Pseudopelade',
  'L661 Lichen planopilaris',
  'L662 Folliculitis decalvans',
  'L663 Perifolliculitis capitis abscedens',
  'L664 Folliculitis ulerythematosa reticulata',
  'L668 Other cicatricial alopecia',
  'L669 Cicatricial alopecia, unspecified',
  'L670 Trichorrhexis nodosa',
  'L671 Variations in hair color',
  'L678 Other hair color and hair shaft abnormalities',
  'L679 Hair color and hair shaft abnormality, unspecified',
  'L680 Hirsutism',
  'L681 Acquired hypertrichosis lanuginosa',
  'L682 Localized hypertrichosis',
  'L683 Polytrichia',
  'L688 Other hypertrichosis',
  'L689 Hypertrichosis, unspecified',
  'L700 Acne vulgaris',
  'L701 Acne conglobata',
  'L702 Acne varioliformis',
  'L703 Acne tropica',
  'L704 Infantile acne',
  'L705 Acne excoriee',
  'L708 Other acne',
  'L709 Acne, unspecified',
  'L710 Perioral dermatitis',
  'L711 Rhinophyma',
  'L718 Other rosacea',
  'L719 Rosacea, unspecified',
  'L720 Epidermal cyst',
  'L7211 Pilar cyst',
  'L7212 Trichodermal cyst',
  'L722 Steatocystoma multiplex',
  'L723 Sebaceous cyst',
  'L728 Other follicular cysts of the skin and subcutaneous tissue',
  'L729 Follicular cyst of the skin and subcutaneous tissue, unspecified',
  'L730 Acne keloid',
  'L731 Pseudofolliculitis barbae',
  'L732 Hidradenitis suppurativa',
  'L738 Other specified follicular disorders',
  'L739 Follicular disorder, unspecified',
  'L740 Miliaria rubra',
  'L741 Miliaria crystallina',
  'L742 Miliaria profunda',
  'L743 Miliaria, unspecified',
  'L744 Anhidrosis',
  'L74510 Primary focal hyperhidrosis, axilla',
  'L74511 Primary focal hyperhidrosis, face',
  'L74512 Primary focal hyperhidrosis, palms',
  'L74513 Primary focal hyperhidrosis, soles',
  'L74519 Primary focal hyperhidrosis, unspecified',
  'L7452 Secondary focal hyperhidrosis',
  'L748 Other eccrine sweat disorders',
  'L749 Eccrine sweat disorder, unspecified',
  'L750 Bromhidrosis',
  'L751 Chromhidrosis',
  'L752 Apocrine miliaria',
  'L758 Other apocrine sweat disorders',
  'L759 Apocrine sweat disorder, unspecified',
  'L7601 Intraoperative hemorrhage and hematoma of skin and subcutaneous tissue complicating a',
  'dermatologic procedure',
  'L7602 Intraoperative hemorrhage and hematoma of skin and subcutaneous tissue complicating other',
  'procedure',
  'L7611 Accidental puncture and laceration of skin and subcutaneous tissue during a dermatologic',
  'procedure',
  'L7612 Accidental puncture and laceration of skin and subcutaneous tissue during other procedure',
  'L7621 Postprocedural hemorrhage of skin and subcutaneous tissue following a dermatologic procedure',
  'L7622 Postprocedural hemorrhage of skin and subcutaneous tissue following other procedure',
  'L7631 Postprocedural hematoma of skin and subcutaneous tissue following a dermatologic procedure',
  'L7632 Postprocedural hematoma of skin and subcutaneous tissue following other procedure',
  'L7633 Postprocedural seroma of skin and subcutaneous tissue following a dermatologic procedure',
  'L7634 Postprocedural seroma of skin and subcutaneous tissue following other procedure',
  'L7681 Other intraoperative complications of skin and subcutaneous tissue',
  'L7682 Other postprocedural complications of skin and subcutaneous tissue',
  'L80 Vitiligo',
  'L810 Postinflammatory hyperpigmentation',
  'L811 Chloasma',
  'L812 Freckles',
  'L813 Cafe au lait spots',
  'L814 Other melanin hyperpigmentation',
  'L815 Leukoderma, not elsewhere classified',
  'L816 Other disorders of diminished melanin formation',
  'L817 Pigmented purpuric dermatosis',
  'L818 Other specified disorders of pigmentation',
  'L819 Disorder of pigmentation, unspecified',
  'L820 Inflamed seborrheic keratosis',
  'L821 Other seborrheic keratosis',
  'L83 Acanthosis nigricans',
  'L84 Corns and callosities',
  'L850 Acquired ichthyosis',
  'L851 Acquired keratosis [keratoderma] palmaris et plantaris',
  'L852 Keratosis punctata (palmaris et plantaris)',
  'L853 Xerosis cutis',
  'L858 Other specified epidermal thickening',
  'L859 Epidermal thickening, unspecified',
  'L86 Keratoderma in diseases classified elsewhere',
  'L870 Keratosis follicularis et parafollicularis in cutem penetrans',
  'L871 Reactive perforating collagenosis',
  'L872 Elastosis perforans serpiginosa',
  'L878 Other transepidermal elimination disorders',
  'L879 Transepidermal elimination disorder, unspecified',
  'L88 Pyoderma gangrenosum',
  'L89000 Pressure ulcer of unspecified elbow, unstageable',
  'L89001 Pressure ulcer of unspecified elbow, stage 1',
  'L89002 Pressure ulcer of unspecified elbow, stage 2',
  'L89003 Pressure ulcer of unspecified elbow, stage 3',
  'L89004 Pressure ulcer of unspecified elbow, stage 4',
  'L89009 Pressure ulcer of unspecified elbow, unspecified stage',
  'L89010 Pressure ulcer of right elbow, unstageable',
  'L89011 Pressure ulcer of right elbow, stage 1',
  'L89012 Pressure ulcer of right elbow, stage 2',
  'L89013 Pressure ulcer of right elbow, stage 3',
  'L89014 Pressure ulcer of right elbow, stage 4',
  'L89019 Pressure ulcer of right elbow, unspecified stage',
  'L89020 Pressure ulcer of left elbow, unstageable',
  'L89021 Pressure ulcer of left elbow, stage 1',
  'L89022 Pressure ulcer of left elbow, stage 2',
  'L89023 Pressure ulcer of left elbow, stage 3',
  'L89024 Pressure ulcer of left elbow, stage 4',
  'L89029 Pressure ulcer of left elbow, unspecified stage',
  'L89100 Pressure ulcer of unspecified part of back, unstageable',
  'L89101 Pressure ulcer of unspecified part of back, stage 1',
  'L89102 Pressure ulcer of unspecified part of back, stage 2',
  'L89103 Pressure ulcer of unspecified part of back, stage 3',
  'L89104 Pressure ulcer of unspecified part of back, stage 4',
  'L89109 Pressure ulcer of unspecified part of back, unspecified stage',
  'L89110 Pressure ulcer of right upper back, unstageable',
  'L89111 Pressure ulcer of right upper back, stage 1',
  'L89112 Pressure ulcer of right upper back, stage 2',
  'L89113 Pressure ulcer of right upper back, stage 3',
  'L89114 Pressure ulcer of right upper back, stage 4',
  'L89119 Pressure ulcer of right upper back, unspecified stage',
  'L89120 Pressure ulcer of left upper back, unstageable',
  'L89121 Pressure ulcer of left upper back, stage 1',
  'L89122 Pressure ulcer of left upper back, stage 2',
  'L89123 Pressure ulcer of left upper back, stage 3',
  'L89124 Pressure ulcer of left upper back, stage 4',
  'L89129 Pressure ulcer of left upper back, unspecified stage',
  'L89130 Pressure ulcer of right lower back, unstageable',
  'L89131 Pressure ulcer of right lower back, stage 1',
  'L89132 Pressure ulcer of right lower back, stage 2',
  'L89133 Pressure ulcer of right lower back, stage 3',
  'L89134 Pressure ulcer of right lower back, stage 4',
  'L89139 Pressure ulcer of right lower back, unspecified stage',
  'L89140 Pressure ulcer of left lower back, unstageable',
  'L89141 Pressure ulcer of left lower back, stage 1',
  'L89142 Pressure ulcer of left lower back, stage 2',
  'L89143 Pressure ulcer of left lower back, stage 3',
  'L89144 Pressure ulcer of left lower back, stage 4',
  'L89149 Pressure ulcer of left lower back, unspecified stage',
  'L89150 Pressure ulcer of sacral region, unstageable',
  'L89151 Pressure ulcer of sacral region, stage 1',
  'L89152 Pressure ulcer of sacral region, stage 2',
  'L89153 Pressure ulcer of sacral region, stage 3',
  'L89154 Pressure ulcer of sacral region, stage 4',
  'L89159 Pressure ulcer of sacral region, unspecified stage',
  'L89200 Pressure ulcer of unspecified hip, unstageable',
  'L89201 Pressure ulcer of unspecified hip, stage 1',
  'L89202 Pressure ulcer of unspecified hip, stage 2',
  'L89203 Pressure ulcer of unspecified hip, stage 3',
  'L89204 Pressure ulcer of unspecified hip, stage 4',
  'L89209 Pressure ulcer of unspecified hip, unspecified stage',
  'L89210 Pressure ulcer of right hip, unstageable',
  'L89211 Pressure ulcer of right hip, stage 1',
  'L89212 Pressure ulcer of right hip, stage 2',
  'L89213 Pressure ulcer of right hip, stage 3',
  'L89214 Pressure ulcer of right hip, stage 4',
  'L89219 Pressure ulcer of right hip, unspecified stage',
  'L89220 Pressure ulcer of left hip, unstageable',
  'L89221 Pressure ulcer of left hip, stage 1',
  'L89222 Pressure ulcer of left hip, stage 2',
  'L89223 Pressure ulcer of left hip, stage 3',
  'L89224 Pressure ulcer of left hip, stage 4',
  'L89229 Pressure ulcer of left hip, unspecified stage',
  'L89300 Pressure ulcer of unspecified buttock, unstageable',
  'L89301 Pressure ulcer of unspecified buttock, stage 1',
  'L89302 Pressure ulcer of unspecified buttock, stage 2',
  'L89303 Pressure ulcer of unspecified buttock, stage 3',
  'L89304 Pressure ulcer of unspecified buttock, stage 4',
  'L89309 Pressure ulcer of unspecified buttock, unspecified stage',
  'L89310 Pressure ulcer of right buttock, unstageable',
  'L89311 Pressure ulcer of right buttock, stage 1',
  'L89312 Pressure ulcer of right buttock, stage 2',
  'L89313 Pressure ulcer of right buttock, stage 3',
  'L89314 Pressure ulcer of right buttock, stage 4',
  'L89319 Pressure ulcer of right buttock, unspecified stage',
  'L89320 Pressure ulcer of left buttock, unstageable',
  'L89321 Pressure ulcer of left buttock, stage 1',
  'L89322 Pressure ulcer of left buttock, stage 2',
  'L89323 Pressure ulcer of left buttock, stage 3',
  'L89324 Pressure ulcer of left buttock, stage 4',
  'L89329 Pressure ulcer of left buttock, unspecified stage',
  'L8940 Pressure ulcer of contiguous site of back, buttock and hip, unspecified stage',
  'L8941 Pressure ulcer of contiguous site of back, buttock and hip, stage 1',
  'L8942 Pressure ulcer of contiguous site of back, buttock and hip, stage 2',
  'L8943 Pressure ulcer of contiguous site of back, buttock and hip, stage 3',
  'L8944 Pressure ulcer of contiguous site of back, buttock and hip, stage 4',
  'L8945 Pressure ulcer of contiguous site of back, buttock and hip, unstageable',
  'L89500 Pressure ulcer of unspecified ankle, unstageable',
  'L89501 Pressure ulcer of unspecified ankle, stage 1',
  'L89502 Pressure ulcer of unspecified ankle, stage 2',
  'L89503 Pressure ulcer of unspecified ankle, stage 3',
  'L89504 Pressure ulcer of unspecified ankle, stage 4',
  'L89509 Pressure ulcer of unspecified ankle, unspecified stage',
  'L89510 Pressure ulcer of right ankle, unstageable',
  'L89511 Pressure ulcer of right ankle, stage 1',
  'L89512 Pressure ulcer of right ankle, stage 2',
  'L89513 Pressure ulcer of right ankle, stage 3',
  'L89514 Pressure ulcer of right ankle, stage 4',
  'L89519 Pressure ulcer of right ankle, unspecified stage',
  'L89520 Pressure ulcer of left ankle, unstageable',
  'L89521 Pressure ulcer of left ankle, stage 1',
  'L89522 Pressure ulcer of left ankle, stage 2',
  'L89523 Pressure ulcer of left ankle, stage 3',
  'L89524 Pressure ulcer of left ankle, stage 4',
  'L89529 Pressure ulcer of left ankle, unspecified stage',
  'L89600 Pressure ulcer of unspecified heel, unstageable',
  'L89601 Pressure ulcer of unspecified heel, stage 1',
  'L89602 Pressure ulcer of unspecified heel, stage 2',
  'L89603 Pressure ulcer of unspecified heel, stage 3',
  'L89604 Pressure ulcer of unspecified heel, stage 4',
  'L89609 Pressure ulcer of unspecified heel, unspecified stage',
  'L89610 Pressure ulcer of right heel, unstageable',
  'L89611 Pressure ulcer of right heel, stage 1',
  'L89612 Pressure ulcer of right heel, stage 2',
  'L89613 Pressure ulcer of right heel, stage 3',
  'L89614 Pressure ulcer of right heel, stage 4',
  'L89619 Pressure ulcer of right heel, unspecified stage',
  'L89620 Pressure ulcer of left heel, unstageable',
  'L89621 Pressure ulcer of left heel, stage 1',
  'L89622 Pressure ulcer of left heel, stage 2',
  'L89623 Pressure ulcer of left heel, stage 3',
  'L89624 Pressure ulcer of left heel, stage 4',
  'L89629 Pressure ulcer of left heel, unspecified stage',
  'L89810 Pressure ulcer of head, unstageable',
  'L89811 Pressure ulcer of head, stage 1',
  'L89812 Pressure ulcer of head, stage 2',
  'L89813 Pressure ulcer of head, stage 3',
  'L89814 Pressure ulcer of head, stage 4',
  'L89819 Pressure ulcer of head, unspecified stage',
  'L89890 Pressure ulcer of other site, unstageable',
  'L89891 Pressure ulcer of other site, stage 1',
  'L89892 Pressure ulcer of other site, stage 2',
  'L89893 Pressure ulcer of other site, stage 3',
  'L89894 Pressure ulcer of other site, stage 4',
  'L89899 Pressure ulcer of other site, unspecified stage',
  'L8990 Pressure ulcer of unspecified site, unspecified stage',
  'L8991 Pressure ulcer of unspecified site, stage 1',
  'L8992 Pressure ulcer of unspecified site, stage 2',
  'L8993 Pressure ulcer of unspecified site, stage 3',
  'L8994 Pressure ulcer of unspecified site, stage 4',
  'L8995 Pressure ulcer of unspecified site, unstageable',
  'L900 Lichen sclerosus et atrophicus',
  'L901 Anetoderma of Schweninger-Buzzi',
  'L902 Anetoderma of Jadassohn-Pellizzari',
  'L903 Atrophoderma of Pasini and Pierini',
  'L904 Acrodermatitis chronica atrophicans',
  'L905 Scar conditions and fibrosis of skin',
  'L906 Striae atrophicae',
  'L908 Other atrophic disorders of skin',
  'L909 Atrophic disorder of skin, unspecified',
  'L910 Hypertrophic scar',
  'L918 Other hypertrophic disorders of the skin',
  'L919 Hypertrophic disorder of the skin, unspecified',
  'L920 Granuloma annulare',
  'L921 Necrobiosis lipoidica, not elsewhere classified',
  'L922 Granuloma faciale [eosinophilic granuloma of skin]',
  'L923 Foreign body granuloma of the skin and subcutaneous tissue',
  'L928 Other granulomatous disorders of the skin and subcutaneous tissue',
  'L929 Granulomatous disorder of the skin and subcutaneous tissue, unspecified',
  'L930 Discoid lupus erythematosus',
  'L931 Subacute cutaneous lupus erythematosus',
  'L932 Other local lupus erythematosus',
  'L940 Localized scleroderma [morphea]',
  'L941 Linear scleroderma',
  'L942 Calcinosis cutis',
  'L943 Sclerodactyly',
  "L944 Gottron's papules",
  'L945 Poikiloderma vasculare atrophicans',
  'L946 Ainhum',
  'L948 Other specified localized connective tissue disorders',
  'L949 Localized connective tissue disorder, unspecified',
  'L950 Livedoid vasculitis',
  'L951 Erythema elevatum diutinum',
  'L958 Other vasculitis limited to the skin',
  'L959 Vasculitis limited to the skin, unspecified',
  'L97101 Non-pressure chronic ulcer of unspecified thigh limited to breakdown of skin',
  'L97102 Non-pressure chronic ulcer of unspecified thigh with fat layer exposed',
  'L97103 Non-pressure chronic ulcer of unspecified thigh with necrosis of muscle',
  'L97104 Non-pressure chronic ulcer of unspecified thigh with necrosis of bone',
  'L97109 Non-pressure chronic ulcer of unspecified thigh with unspecified severity',
  'L97111 Non-pressure chronic ulcer of right thigh limited to breakdown of skin',
  'L97112 Non-pressure chronic ulcer of right thigh with fat layer exposed',
  'L97113 Non-pressure chronic ulcer of right thigh with necrosis of muscle',
  'L97114 Non-pressure chronic ulcer of right thigh with necrosis of bone',
  'L97119 Non-pressure chronic ulcer of right thigh with unspecified severity',
  'L97121 Non-pressure chronic ulcer of left thigh limited to breakdown of skin',
  'L97122 Non-pressure chronic ulcer of left thigh with fat layer exposed',
  'L97123 Non-pressure chronic ulcer of left thigh with necrosis of muscle',
  'L97124 Non-pressure chronic ulcer of left thigh with necrosis of bone',
  'L97129 Non-pressure chronic ulcer of left thigh with unspecified severity',
  'L97201 Non-pressure chronic ulcer of unspecified calf limited to breakdown of skin',
  'L97202 Non-pressure chronic ulcer of unspecified calf with fat layer exposed',
  'L97203 Non-pressure chronic ulcer of unspecified calf with necrosis of muscle',
  'L97204 Non-pressure chronic ulcer of unspecified calf with necrosis of bone',
  'L97209 Non-pressure chronic ulcer of unspecified calf with unspecified severity',
  'L97211 Non-pressure chronic ulcer of right calf limited to breakdown of skin',
  'L97212 Non-pressure chronic ulcer of right calf with fat layer exposed',
  'L97213 Non-pressure chronic ulcer of right calf with necrosis of muscle',
  'L97214 Non-pressure chronic ulcer of right calf with necrosis of bone',
  'L97219 Non-pressure chronic ulcer of right calf with unspecified severity',
  'L97221 Non-pressure chronic ulcer of left calf limited to breakdown of skin',
  'L97222 Non-pressure chronic ulcer of left calf with fat layer exposed',
  'L97223 Non-pressure chronic ulcer of left calf with necrosis of muscle',
  'L97224 Non-pressure chronic ulcer of left calf with necrosis of bone',
  'L97229 Non-pressure chronic ulcer of left calf with unspecified severity',
  'L97301 Non-pressure chronic ulcer of unspecified ankle limited to breakdown of skin',
  'L97302 Non-pressure chronic ulcer of unspecified ankle with fat layer exposed',
  'L97303 Non-pressure chronic ulcer of unspecified ankle with necrosis of muscle',
  'L97304 Non-pressure chronic ulcer of unspecified ankle with necrosis of bone',
  'L97309 Non-pressure chronic ulcer of unspecified ankle with unspecified severity',
  'L97311 Non-pressure chronic ulcer of right ankle limited to breakdown of skin',
  'L97312 Non-pressure chronic ulcer of right ankle with fat layer exposed',
  'L97313 Non-pressure chronic ulcer of right ankle with necrosis of muscle',
  'L97314 Non-pressure chronic ulcer of right ankle with necrosis of bone',
  'L97319 Non-pressure chronic ulcer of right ankle with unspecified severity',
  'L97321 Non-pressure chronic ulcer of left ankle limited to breakdown of skin',
  'L97322 Non-pressure chronic ulcer of left ankle with fat layer exposed',
  'L97323 Non-pressure chronic ulcer of left ankle with necrosis of muscle',
  'L97324 Non-pressure chronic ulcer of left ankle with necrosis of bone',
  'L97329 Non-pressure chronic ulcer of left ankle with unspecified severity',
  'L97401 Non-pressure chronic ulcer of unspecified heel and midfoot limited to breakdown of skin',
  'L97402 Non-pressure chronic ulcer of unspecified heel and midfoot with fat layer exposed',
  'L97403 Non-pressure chronic ulcer of unspecified heel and midfoot with necrosis of muscle',
  'L97404 Non-pressure chronic ulcer of unspecified heel and midfoot with necrosis of bone',
  'L97409 Non-pressure chronic ulcer of unspecified heel and midfoot with unspecified severity',
  'L97411 Non-pressure chronic ulcer of right heel and midfoot limited to breakdown of skin',
  'L97412 Non-pressure chronic ulcer of right heel and midfoot with fat layer exposed',
  'L97413 Non-pressure chronic ulcer of right heel and midfoot with necrosis of muscle',
  'L97414 Non-pressure chronic ulcer of right heel and midfoot with necrosis of bone',
  'L97419 Non-pressure chronic ulcer of right heel and midfoot with unspecified severity',
  'L97421 Non-pressure chronic ulcer of left heel and midfoot limited to breakdown of skin',
  'L97422 Non-pressure chronic ulcer of left heel and midfoot with fat layer exposed',
  'L97423 Non-pressure chronic ulcer of left heel and midfoot with necrosis of muscle',
  'L97424 Non-pressure chronic ulcer of left heel and midfoot with necrosis of bone',
  'L97429 Non-pressure chronic ulcer of left heel and midfoot with unspecified severity',
  'L97501 Non-pressure chronic ulcer of other part of unspecified foot limited to breakdown of skin',
  'L97502 Non-pressure chronic ulcer of other part of unspecified foot with fat layer exposed',
  'L97503 Non-pressure chronic ulcer of other part of unspecified foot with necrosis of muscle',
  'L97504 Non-pressure chronic ulcer of other part of unspecified foot with necrosis of bone',
  'L97509 Non-pressure chronic ulcer of other part of unspecified foot with unspecified severity',
  'L97511 Non-pressure chronic ulcer of other part of right foot limited to breakdown of skin',
  'L97512 Non-pressure chronic ulcer of other part of right foot with fat layer exposed',
  'L97513 Non-pressure chronic ulcer of other part of right foot with necrosis of muscle',
  'L97514 Non-pressure chronic ulcer of other part of right foot with necrosis of bone',
  'L97519 Non-pressure chronic ulcer of other part of right foot with unspecified severity',
  'L97521 Non-pressure chronic ulcer of other part of left foot limited to breakdown of skin',
  'L97522 Non-pressure chronic ulcer of other part of left foot with fat layer exposed',
  'L97523 Non-pressure chronic ulcer of other part of left foot with necrosis of muscle',
  'L97524 Non-pressure chronic ulcer of other part of left foot with necrosis of bone',
  'L97529 Non-pressure chronic ulcer of other part of left foot with unspecified severity',
  'L97801 Non-pressure chronic ulcer of other part of unspecified lower leg limited to breakdown of skin',
  'L97802 Non-pressure chronic ulcer of other part of unspecified lower leg with fat layer exposed',
  'L97803 Non-pressure chronic ulcer of other part of unspecified lower leg with necrosis of muscle',
  'L97804 Non-pressure chronic ulcer of other part of unspecified lower leg with necrosis of bone',
  'L97809 Non-pressure chronic ulcer of other part of unspecified lower leg with unspecified severity',
  'L97811 Non-pressure chronic ulcer of other part of right lower leg limited to breakdown of skin',
  'L97812 Non-pressure chronic ulcer of other part of right lower leg with fat layer exposed',
  'L97813 Non-pressure chronic ulcer of other part of right lower leg with necrosis of muscle',
  'L97814 Non-pressure chronic ulcer of other part of right lower leg with necrosis of bone',
  'L97819 Non-pressure chronic ulcer of other part of right lower leg with unspecified severity',
  'L97821 Non-pressure chronic ulcer of other part of left lower leg limited to breakdown of skin',
  'L97822 Non-pressure chronic ulcer of other part of left lower leg with fat layer exposed',
  'L97823 Non-pressure chronic ulcer of other part of left lower leg with necrosis of muscle',
  'L97824 Non-pressure chronic ulcer of other part of left lower leg with necrosis of bone',
  'L97829 Non-pressure chronic ulcer of other part of left lower leg with unspecified severity',
  'L97901 Non-pressure chronic ulcer of unspecified part of unspecified lower leg limited to breakdown of',
  'skin',
  'L97902 Non-pressure chronic ulcer of unspecified part of unspecified lower leg with fat layer exposed',
  'L97903 Non-pressure chronic ulcer of unspecified part of unspecified lower leg with necrosis of muscle',
  'L97904 Non-pressure chronic ulcer of unspecified part of unspecified lower leg with necrosis of bone',
  'L97909 Non-pressure chronic ulcer of unspecified part of unspecified lower leg with unspecified',
  'severity',
  'L97911 Non-pressure chronic ulcer of unspecified part of right lower leg limited to breakdown of skin',
  'L97912 Non-pressure chronic ulcer of unspecified part of right lower leg with fat layer exposed',
  'L97913 Non-pressure chronic ulcer of unspecified part of right lower leg with necrosis of muscle',
  'L97914 Non-pressure chronic ulcer of unspecified part of right lower leg with necrosis of bone',
  'L97919 Non-pressure chronic ulcer of unspecified part of right lower leg with unspecified severity',
  'L97921 Non-pressure chronic ulcer of unspecified part of left lower leg limited to breakdown of skin',
  'L97922 Non-pressure chronic ulcer of unspecified part of left lower leg with fat layer exposed',
  'L97923 Non-pressure chronic ulcer of unspecified part of left lower leg with necrosis of muscle',
  'L97924 Non-pressure chronic ulcer of unspecified part of left lower leg with necrosis of bone',
  'L97929 Non-pressure chronic ulcer of unspecified part of left lower leg with unspecified severity',
  'L980 Pyogenic granuloma',
  'L981 Factitial dermatitis',
  'L982 Febrile neutrophilic dermatosis [Sweet]',
  'L983 Eosinophilic cellulitis [Wells]',
  'L98411 Non-pressure chronic ulcer of buttock limited to breakdown of skin',
  'L98412 Non-pressure chronic ulcer of buttock with fat layer exposed',
  'L98413 Non-pressure chronic ulcer of buttock with necrosis of muscle',
  'L98414 Non-pressure chronic ulcer of buttock with necrosis of bone',
  'L98419 Non-pressure chronic ulcer of buttock with unspecified severity',
  'L98421 Non-pressure chronic ulcer of back limited to breakdown of skin',
  'L98422 Non-pressure chronic ulcer of back with fat layer exposed',
  'L98423 Non-pressure chronic ulcer of back with necrosis of muscle',
  'L98424 Non-pressure chronic ulcer of back with necrosis of bone',
  'L98429 Non-pressure chronic ulcer of back with unspecified severity',
  'L98491 Non-pressure chronic ulcer of skin of other sites limited to breakdown of skin',
  'L98492 Non-pressure chronic ulcer of skin of other sites with fat layer exposed',
  'L98493 Non-pressure chronic ulcer of skin of other sites with necrosis of muscle',
  'L98494 Non-pressure chronic ulcer of skin of other sites with necrosis of bone',
  'L98499 Non-pressure chronic ulcer of skin of other sites with unspecified severity',
  'L985 Mucinosis of the skin',
  'L986 Other infiltrative disorders of the skin and subcutaneous tissue',
  'L987 Excessive and redundant skin and subcutaneous tissue',
  'L988 Other specified disorders of the skin and subcutaneous tissue',
  'L989 Disorder of the skin and subcutaneous tissue, unspecified',
  'L99 Other disorders of skin and subcutaneous tissue in diseases classified elsewhere',
  'M0000 Staphylococcal arthritis, unspecified joint',
  'M00011 Staphylococcal arthritis, right shoulder',
  'M00012 Staphylococcal arthritis, left shoulder',
  'M00019 Staphylococcal arthritis, unspecified shoulder',
  'M00021 Staphylococcal arthritis, right elbow',
  'M00022 Staphylococcal arthritis, left elbow',
  'M00029 Staphylococcal arthritis, unspecified elbow',
  'M00031 Staphylococcal arthritis, right wrist',
  'M00032 Staphylococcal arthritis, left wrist',
  'M00039 Staphylococcal arthritis, unspecified wrist',
  'M00041 Staphylococcal arthritis, right hand',
  'M00042 Staphylococcal arthritis, left hand',
  'M00049 Staphylococcal arthritis, unspecified hand',
  'M00051 Staphylococcal arthritis, right hip',
  'M00052 Staphylococcal arthritis, left hip',
  'M00059 Staphylococcal arthritis, unspecified hip',
  'M00061 Staphylococcal arthritis, right knee',
  'M00062 Staphylococcal arthritis, left knee',
  'M00069 Staphylococcal arthritis, unspecified knee',
  'M00071 Staphylococcal arthritis, right ankle and foot',
  'M00072 Staphylococcal arthritis, left ankle and foot',
  'M00079 Staphylococcal arthritis, unspecified ankle and foot',
  'M0008 Staphylococcal arthritis, vertebrae',
  'M0009 Staphylococcal polyarthritis',
  'M0010 Pneumococcal arthritis, unspecified joint',
  'M00111 Pneumococcal arthritis, right shoulder',
  'M00112 Pneumococcal arthritis, left shoulder',
  'M00119 Pneumococcal arthritis, unspecified shoulder',
  'M00121 Pneumococcal arthritis, right elbow',
  'M00122 Pneumococcal arthritis, left elbow',
  'M00129 Pneumococcal arthritis, unspecified elbow',
  'M00131 Pneumococcal arthritis, right wrist',
  'M00132 Pneumococcal arthritis, left wrist',
  'M00139 Pneumococcal arthritis, unspecified wrist',
  'M00141 Pneumococcal arthritis, right hand',
  'M00142 Pneumococcal arthritis, left hand',
  'M00149 Pneumococcal arthritis, unspecified hand',
  'M00151 Pneumococcal arthritis, right hip',
  'M00152 Pneumococcal arthritis, left hip',
  'M00159 Pneumococcal arthritis, unspecified hip',
  'M00161 Pneumococcal arthritis, right knee',
  'M00162 Pneumococcal arthritis, left knee',
  'M00169 Pneumococcal arthritis, unspecified knee',
  'M00171 Pneumococcal arthritis, right ankle and foot',
  'M00172 Pneumococcal arthritis, left ankle and foot',
  'M00179 Pneumococcal arthritis, unspecified ankle and foot',
  'M0018 Pneumococcal arthritis, vertebrae',
  'M0019 Pneumococcal polyarthritis',
  'M0020 Other streptococcal arthritis, unspecified joint',
  'M00211 Other streptococcal arthritis, right shoulder',
  'M00212 Other streptococcal arthritis, left shoulder',
  'M00219 Other streptococcal arthritis, unspecified shoulder',
  'M00221 Other streptococcal arthritis, right elbow',
  'M00222 Other streptococcal arthritis, left elbow',
  'M00229 Other streptococcal arthritis, unspecified elbow',
  'M00231 Other streptococcal arthritis, right wrist',
  'M00232 Other streptococcal arthritis, left wrist',
  'M00239 Other streptococcal arthritis, unspecified wrist',
  'M00241 Other streptococcal arthritis, right hand',
  'M00242 Other streptococcal arthritis, left hand',
  'M00249 Other streptococcal arthritis, unspecified hand',
  'M00251 Other streptococcal arthritis, right hip',
  'M00252 Other streptococcal arthritis, left hip',
  'M00259 Other streptococcal arthritis, unspecified hip',
  'M00261 Other streptococcal arthritis, right knee',
  'M00262 Other streptococcal arthritis, left knee',
  'M00269 Other streptococcal arthritis, unspecified knee',
  'M00271 Other streptococcal arthritis, right ankle and foot',
  'M00272 Other streptococcal arthritis, left ankle and foot',
  'M00279 Other streptococcal arthritis, unspecified ankle and foot',
  'M0028 Other streptococcal arthritis, vertebrae',
  'M0029 Other streptococcal polyarthritis',
  'M0080 Arthritis due to other bacteria, unspecified joint',
  'M00811 Arthritis due to other bacteria, right shoulder',
  'M00812 Arthritis due to other bacteria, left shoulder',
  'M00819 Arthritis due to other bacteria, unspecified shoulder',
  'M00821 Arthritis due to other bacteria, right elbow',
  'M00822 Arthritis due to other bacteria, left elbow',
  'M00829 Arthritis due to other bacteria, unspecified elbow',
  'M00831 Arthritis due to other bacteria, right wrist',
  'M00832 Arthritis due to other bacteria, left wrist',
  'M00839 Arthritis due to other bacteria, unspecified wrist',
  'M00841 Arthritis due to other bacteria, right hand',
  'M00842 Arthritis due to other bacteria, left hand',
  'M00849 Arthritis due to other bacteria, unspecified hand',
  'M00851 Arthritis due to other bacteria, right hip',
  'M00852 Arthritis due to other bacteria, left hip',
  'M00859 Arthritis due to other bacteria, unspecified hip',
  'M00861 Arthritis due to other bacteria, right knee',
  'M00862 Arthritis due to other bacteria, left knee',
  'M00869 Arthritis due to other bacteria, unspecified knee',
  'M00871 Arthritis due to other bacteria, right ankle and foot',
  'M00872 Arthritis due to other bacteria, left ankle and foot',
  'M00879 Arthritis due to other bacteria, unspecified ankle and foot',
  'M0088 Arthritis due to other bacteria, vertebrae',
  'M0089 Polyarthritis due to other bacteria',
  'M009 Pyogenic arthritis, unspecified',
  'M01X0 Direct infection of unspecified joint in infectious and parasitic diseases classified elsewhere',
  'M01X11 Direct infection of right shoulder in infectious and parasitic diseases classified elsewhere',
  'M01X12 Direct infection of left shoulder in infectious and parasitic diseases classified elsewhere',
  'M01X19 Direct infection of unspecified shoulder in infectious and parasitic diseases classified elsewhere',
  'M01X21 Direct infection of right elbow in infectious and parasitic diseases classified elsewhere',
  'M01X22 Direct infection of left elbow in infectious and parasitic diseases classified elsewhere',
  'M01X29 Direct infection of unspecified elbow in infectious and parasitic diseases classified elsewhere',
  'M01X31 Direct infection of right wrist in infectious and parasitic diseases classified elsewhere',
  'M01X32 Direct infection of left wrist in infectious and parasitic diseases classified elsewhere',
  'M01X39 Direct infection of unspecified wrist in infectious and parasitic diseases classified elsewhere',
  'M01X41 Direct infection of right hand in infectious and parasitic diseases classified elsewhere',
  'M01X42 Direct infection of left hand in infectious and parasitic diseases classified elsewhere',
  'M01X49 Direct infection of unspecified hand in infectious and parasitic diseases classified elsewhere',
  'M01X51 Direct infection of right hip in infectious and parasitic diseases classified elsewhere',
  'M01X52 Direct infection of left hip in infectious and parasitic diseases classified elsewhere',
  'M01X59 Direct infection of unspecified hip in infectious and parasitic diseases classified elsewhere',
  'M01X61 Direct infection of right knee in infectious and parasitic diseases classified elsewhere',
  'M01X62 Direct infection of left knee in infectious and parasitic diseases classified elsewhere',
  'M01X69 Direct infection of unspecified knee in infectious and parasitic diseases classified elsewhere',
  'M01X71 Direct infection of right ankle and foot in infectious and parasitic diseases classified elsewhere',
  'M01X72 Direct infection of left ankle and foot in infectious and parasitic diseases classified elsewhere',
  'M01X79 Direct infection of unspecified ankle and foot in infectious and parasitic diseases classified',
  'elsewhere',
  'M01X8 Direct infection of vertebrae in infectious and parasitic diseases classified elsewhere',
  'M01X9 Direct infection of multiple joints in infectious and parasitic diseases classified elsewhere',
  'M0200 Arthropathy following intestinal bypass, unspecified site',
  'M02011 Arthropathy following intestinal bypass, right shoulder',
  'M02012 Arthropathy following intestinal bypass, left shoulder',
  'M02019 Arthropathy following intestinal bypass, unspecified shoulder',
  'M02021 Arthropathy following intestinal bypass, right elbow',
  'M02022 Arthropathy following intestinal bypass, left elbow',
  'M02029 Arthropathy following intestinal bypass, unspecified elbow',
  'M02031 Arthropathy following intestinal bypass, right wrist',
  'M02032 Arthropathy following intestinal bypass, left wrist',
  'M02039 Arthropathy following intestinal bypass, unspecified wrist',
  'M02041 Arthropathy following intestinal bypass, right hand',
  'M02042 Arthropathy following intestinal bypass, left hand',
  'M02049 Arthropathy following intestinal bypass, unspecified hand',
  'M02051 Arthropathy following intestinal bypass, right hip',
  'M02052 Arthropathy following intestinal bypass, left hip',
  'M02059 Arthropathy following intestinal bypass, unspecified hip',
  'M02061 Arthropathy following intestinal bypass, right knee',
  'M02062 Arthropathy following intestinal bypass, left knee',
  'M02069 Arthropathy following intestinal bypass, unspecified knee',
  'M02071 Arthropathy following intestinal bypass, right ankle and foot',
  'M02072 Arthropathy following intestinal bypass, left ankle and foot',
  'M02079 Arthropathy following intestinal bypass, unspecified ankle and foot',
  'M0208 Arthropathy following intestinal bypass, vertebrae',
  'M0209 Arthropathy following intestinal bypass, multiple sites',
  'M0210 Postdysenteric arthropathy, unspecified site',
  'M02111 Postdysenteric arthropathy, right shoulder',
  'M02112 Postdysenteric arthropathy, left shoulder',
  'M02119 Postdysenteric arthropathy, unspecified shoulder',
  'M02121 Postdysenteric arthropathy, right elbow',
  'M02122 Postdysenteric arthropathy, left elbow',
  'M02129 Postdysenteric arthropathy, unspecified elbow',
  'M02131 Postdysenteric arthropathy, right wrist',
  'M02132 Postdysenteric arthropathy, left wrist',
  'M02139 Postdysenteric arthropathy, unspecified wrist',
  'M02141 Postdysenteric arthropathy, right hand',
  'M02142 Postdysenteric arthropathy, left hand',
  'M02149 Postdysenteric arthropathy, unspecified hand',
  'M02151 Postdysenteric arthropathy, right hip',
  'M02152 Postdysenteric arthropathy, left hip',
  'M02159 Postdysenteric arthropathy, unspecified hip',
  'M02161 Postdysenteric arthropathy, right knee',
  'M02162 Postdysenteric arthropathy, left knee',
  'M02169 Postdysenteric arthropathy, unspecified knee',
  'M02171 Postdysenteric arthropathy, right ankle and foot',
  'M02172 Postdysenteric arthropathy, left ankle and foot',
  'M02179 Postdysenteric arthropathy, unspecified ankle and foot',
  'M0218 Postdysenteric arthropathy, vertebrae',
  'M0219 Postdysenteric arthropathy, multiple sites',
  'M0220 Postimmunization arthropathy, unspecified site',
  'M02211 Postimmunization arthropathy, right shoulder',
  'M02212 Postimmunization arthropathy, left shoulder',
  'M02219 Postimmunization arthropathy, unspecified shoulder',
  'M02221 Postimmunization arthropathy, right elbow',
  'M02222 Postimmunization arthropathy, left elbow',
  'M02229 Postimmunization arthropathy, unspecified elbow',
  'M02231 Postimmunization arthropathy, right wrist',
  'M02232 Postimmunization arthropathy, left wrist',
  'M02239 Postimmunization arthropathy, unspecified wrist',
  'M02241 Postimmunization arthropathy, right hand',
  'M02242 Postimmunization arthropathy, left hand',
  'M02249 Postimmunization arthropathy, unspecified hand',
  'M02251 Postimmunization arthropathy, right hip',
  'M02252 Postimmunization arthropathy, left hip',
  'M02259 Postimmunization arthropathy, unspecified hip',
  'M02261 Postimmunization arthropathy, right knee',
  'M02262 Postimmunization arthropathy, left knee',
  'M02269 Postimmunization arthropathy, unspecified knee',
  'M02271 Postimmunization arthropathy, right ankle and foot',
  'M02272 Postimmunization arthropathy, left ankle and foot',
  'M02279 Postimmunization arthropathy, unspecified ankle and foot',
  'M0228 Postimmunization arthropathy, vertebrae',
  'M0229 Postimmunization arthropathy, multiple sites',
  "M0230 Reiter's disease, unspecified site",
  "M02311 Reiter's disease, right shoulder",
  "M02312 Reiter's disease, left shoulder",
  "M02319 Reiter's disease, unspecified shoulder",
  "M02321 Reiter's disease, right elbow",
  "M02322 Reiter's disease, left elbow",
  "M02329 Reiter's disease, unspecified elbow",
  "M02331 Reiter's disease, right wrist",
  "M02332 Reiter's disease, left wrist",
  "M02339 Reiter's disease, unspecified wrist",
  "M02341 Reiter's disease, right hand",
  "M02342 Reiter's disease, left hand",
  "M02349 Reiter's disease, unspecified hand",
  "M02351 Reiter's disease, right hip",
  "M02352 Reiter's disease, left hip",
  "M02359 Reiter's disease, unspecified hip",
  "M02361 Reiter's disease, right knee",
  "M02362 Reiter's disease, left knee",
  "M02369 Reiter's disease, unspecified knee",
  "M02371 Reiter's disease, right ankle and foot",
  "M02372 Reiter's disease, left ankle and foot",
  "M02379 Reiter's disease, unspecified ankle and foot",
  "M0238 Reiter's disease, vertebrae",
  "M0239 Reiter's disease, multiple sites",
  'M0280 Other reactive arthropathies, unspecified site',
  'M02811 Other reactive arthropathies, right shoulder',
  'M02812 Other reactive arthropathies, left shoulder',
  'M02819 Other reactive arthropathies, unspecified shoulder',
  'M02821 Other reactive arthropathies, right elbow',
  'M02822 Other reactive arthropathies, left elbow',
  'M02829 Other reactive arthropathies, unspecified elbow',
  'M02831 Other reactive arthropathies, right wrist',
  'M02832 Other reactive arthropathies, left wrist',
  'M02839 Other reactive arthropathies, unspecified wrist',
  'M02841 Other reactive arthropathies, right hand',
  'M02842 Other reactive arthropathies, left hand',
  'M02849 Other reactive arthropathies, unspecified hand',
  'M02851 Other reactive arthropathies, right hip',
  'M02852 Other reactive arthropathies, left hip',
  'M02859 Other reactive arthropathies, unspecified hip',
  'M02861 Other reactive arthropathies, right knee',
  'M02862 Other reactive arthropathies, left knee',
  'M02869 Other reactive arthropathies, unspecified knee',
  'M02871 Other reactive arthropathies, right ankle and foot',
  'M02872 Other reactive arthropathies, left ankle and foot',
  'M02879 Other reactive arthropathies, unspecified ankle and foot',
  'M0288 Other reactive arthropathies, vertebrae',
  'M0289 Other reactive arthropathies, multiple sites',
  'M029 Reactive arthropathy, unspecified',
  'M041 Periodic fever syndromes',
  'M042 Cryopyrin-associated periodic syndromes',
  'M048 Other autoinflammatory syndromes',
  'M049 Autoinflammatory syndrome, unspecified',
  "M0500 Felty's syndrome, unspecified site",
  "M05011 Felty's syndrome, right shoulder",
  "M05012 Felty's syndrome, left shoulder",
  "M05019 Felty's syndrome, unspecified shoulder",
  "M05021 Felty's syndrome, right elbow",
  "M05022 Felty's syndrome, left elbow",
  "M05029 Felty's syndrome, unspecified elbow",
  "M05031 Felty's syndrome, right wrist",
  "M05032 Felty's syndrome, left wrist",
  "M05039 Felty's syndrome, unspecified wrist",
  "M05041 Felty's syndrome, right hand",
  "M05042 Felty's syndrome, left hand",
  "M05049 Felty's syndrome, unspecified hand",
  "M05051 Felty's syndrome, right hip",
  "M05052 Felty's syndrome, left hip",
  "M05059 Felty's syndrome, unspecified hip",
  "M05061 Felty's syndrome, right knee",
  "M05062 Felty's syndrome, left knee",
  "M05069 Felty's syndrome, unspecified knee",
  "M05071 Felty's syndrome, right ankle and foot",
  "M05072 Felty's syndrome, left ankle and foot",
  "M05079 Felty's syndrome, unspecified ankle and foot",
  "M0509 Felty's syndrome, multiple sites",
  'M0510 Rheumatoid lung disease with rheumatoid arthritis of unspecified site',
  'M05111 Rheumatoid lung disease with rheumatoid arthritis of right shoulder',
  'M05112 Rheumatoid lung disease with rheumatoid arthritis of left shoulder',
  'M05119 Rheumatoid lung disease with rheumatoid arthritis of unspecified shoulder',
  'M05121 Rheumatoid lung disease with rheumatoid arthritis of right elbow',
  'M05122 Rheumatoid lung disease with rheumatoid arthritis of left elbow',
  'M05129 Rheumatoid lung disease with rheumatoid arthritis of unspecified elbow',
  'M05131 Rheumatoid lung disease with rheumatoid arthritis of right wrist',
  'M05132 Rheumatoid lung disease with rheumatoid arthritis of left wrist',
  'M05139 Rheumatoid lung disease with rheumatoid arthritis of unspecified wrist',
  'M05141 Rheumatoid lung disease with rheumatoid arthritis of right hand',
  'M05142 Rheumatoid lung disease with rheumatoid arthritis of left hand',
  'M05149 Rheumatoid lung disease with rheumatoid arthritis of unspecified hand',
  'M05151 Rheumatoid lung disease with rheumatoid arthritis of right hip',
  'M05152 Rheumatoid lung disease with rheumatoid arthritis of left hip',
  'M05159 Rheumatoid lung disease with rheumatoid arthritis of unspecified hip',
  'M05161 Rheumatoid lung disease with rheumatoid arthritis of right knee',
  'M05162 Rheumatoid lung disease with rheumatoid arthritis of left knee',
  'M05169 Rheumatoid lung disease with rheumatoid arthritis of unspecified knee',
  'M05171 Rheumatoid lung disease with rheumatoid arthritis of right ankle and foot',
  'M05172 Rheumatoid lung disease with rheumatoid arthritis of left ankle and foot',
  'M05179 Rheumatoid lung disease with rheumatoid arthritis of unspecified ankle and foot',
  'M0519 Rheumatoid lung disease with rheumatoid arthritis of multiple sites',
  'M0520 Rheumatoid vasculitis with rheumatoid arthritis of unspecified site',
  'M05211 Rheumatoid vasculitis with rheumatoid arthritis of right shoulder',
  'M05212 Rheumatoid vasculitis with rheumatoid arthritis of left shoulder',
  'M05219 Rheumatoid vasculitis with rheumatoid arthritis of unspecified shoulder',
  'M05221 Rheumatoid vasculitis with rheumatoid arthritis of right elbow',
  'M05222 Rheumatoid vasculitis with rheumatoid arthritis of left elbow',
  'M05229 Rheumatoid vasculitis with rheumatoid arthritis of unspecified elbow',
  'M05231 Rheumatoid vasculitis with rheumatoid arthritis of right wrist',
  'M05232 Rheumatoid vasculitis with rheumatoid arthritis of left wrist',
  'M05239 Rheumatoid vasculitis with rheumatoid arthritis of unspecified wrist',
  'M05241 Rheumatoid vasculitis with rheumatoid arthritis of right hand',
  'M05242 Rheumatoid vasculitis with rheumatoid arthritis of left hand',
  'M05249 Rheumatoid vasculitis with rheumatoid arthritis of unspecified hand',
  'M05251 Rheumatoid vasculitis with rheumatoid arthritis of right hip',
  'M05252 Rheumatoid vasculitis with rheumatoid arthritis of left hip',
  'M05259 Rheumatoid vasculitis with rheumatoid arthritis of unspecified hip',
  'M05261 Rheumatoid vasculitis with rheumatoid arthritis of right knee',
  'M05262 Rheumatoid vasculitis with rheumatoid arthritis of left knee',
  'M05269 Rheumatoid vasculitis with rheumatoid arthritis of unspecified knee',
  'M05271 Rheumatoid vasculitis with rheumatoid arthritis of right ankle and foot',
  'M05272 Rheumatoid vasculitis with rheumatoid arthritis of left ankle and foot',
  'M05279 Rheumatoid vasculitis with rheumatoid arthritis of unspecified ankle and foot',
  'M0529 Rheumatoid vasculitis with rheumatoid arthritis of multiple sites',
  'M0530 Rheumatoid heart disease with rheumatoid arthritis of unspecified site',
  'M05311 Rheumatoid heart disease with rheumatoid arthritis of right shoulder',
  'M05312 Rheumatoid heart disease with rheumatoid arthritis of left shoulder',
  'M05319 Rheumatoid heart disease with rheumatoid arthritis of unspecified shoulder',
  'M05321 Rheumatoid heart disease with rheumatoid arthritis of right elbow',
  'M05322 Rheumatoid heart disease with rheumatoid arthritis of left elbow',
  'M05329 Rheumatoid heart disease with rheumatoid arthritis of unspecified elbow',
  'M05331 Rheumatoid heart disease with rheumatoid arthritis of right wrist',
  'M05332 Rheumatoid heart disease with rheumatoid arthritis of left wrist',
  'M05339 Rheumatoid heart disease with rheumatoid arthritis of unspecified wrist',
  'M05341 Rheumatoid heart disease with rheumatoid arthritis of right hand',
  'M05342 Rheumatoid heart disease with rheumatoid arthritis of left hand',
  'M05349 Rheumatoid heart disease with rheumatoid arthritis of unspecified hand',
  'M05351 Rheumatoid heart disease with rheumatoid arthritis of right hip',
  'M05352 Rheumatoid heart disease with rheumatoid arthritis of left hip',
  'M05359 Rheumatoid heart disease with rheumatoid arthritis of unspecified hip',
  'M05361 Rheumatoid heart disease with rheumatoid arthritis of right knee',
  'M05362 Rheumatoid heart disease with rheumatoid arthritis of left knee',
  'M05369 Rheumatoid heart disease with rheumatoid arthritis of unspecified knee',
  'M05371 Rheumatoid heart disease with rheumatoid arthritis of right ankle and foot',
  'M05372 Rheumatoid heart disease with rheumatoid arthritis of left ankle and foot',
  'M05379 Rheumatoid heart disease with rheumatoid arthritis of unspecified ankle and foot',
  'M0539 Rheumatoid heart disease with rheumatoid arthritis of multiple sites',
  'M0540 Rheumatoid myopathy with rheumatoid arthritis of unspecified site',
  'M05411 Rheumatoid myopathy with rheumatoid arthritis of right shoulder',
  'M05412 Rheumatoid myopathy with rheumatoid arthritis of left shoulder',
  'M05419 Rheumatoid myopathy with rheumatoid arthritis of unspecified shoulder',
  'M05421 Rheumatoid myopathy with rheumatoid arthritis of right elbow',
  'M05422 Rheumatoid myopathy with rheumatoid arthritis of left elbow',
  'M05429 Rheumatoid myopathy with rheumatoid arthritis of unspecified elbow',
  'M05431 Rheumatoid myopathy with rheumatoid arthritis of right wrist',
  'M05432 Rheumatoid myopathy with rheumatoid arthritis of left wrist',
  'M05439 Rheumatoid myopathy with rheumatoid arthritis of unspecified wrist',
  'M05441 Rheumatoid myopathy with rheumatoid arthritis of right hand',
  'M05442 Rheumatoid myopathy with rheumatoid arthritis of left hand',
  'M05449 Rheumatoid myopathy with rheumatoid arthritis of unspecified hand',
  'M05451 Rheumatoid myopathy with rheumatoid arthritis of right hip',
  'M05452 Rheumatoid myopathy with rheumatoid arthritis of left hip',
  'M05459 Rheumatoid myopathy with rheumatoid arthritis of unspecified hip',
  'M05461 Rheumatoid myopathy with rheumatoid arthritis of right knee',
  'M05462 Rheumatoid myopathy with rheumatoid arthritis of left knee',
  'M05469 Rheumatoid myopathy with rheumatoid arthritis of unspecified knee',
  'M05471 Rheumatoid myopathy with rheumatoid arthritis of right ankle and foot',
  'M05472 Rheumatoid myopathy with rheumatoid arthritis of left ankle and foot',
  'M05479 Rheumatoid myopathy with rheumatoid arthritis of unspecified ankle and foot',
  'M0549 Rheumatoid myopathy with rheumatoid arthritis of multiple sites',
  'M0550 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified site',
  'M05511 Rheumatoid polyneuropathy with rheumatoid arthritis of right shoulder',
  'M05512 Rheumatoid polyneuropathy with rheumatoid arthritis of left shoulder',
  'M05519 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified shoulder',
  'M05521 Rheumatoid polyneuropathy with rheumatoid arthritis of right elbow',
  'M05522 Rheumatoid polyneuropathy with rheumatoid arthritis of left elbow',
  'M05529 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified elbow',
  'M05531 Rheumatoid polyneuropathy with rheumatoid arthritis of right wrist',
  'M05532 Rheumatoid polyneuropathy with rheumatoid arthritis of left wrist',
  'M05539 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified wrist',
  'M05541 Rheumatoid polyneuropathy with rheumatoid arthritis of right hand',
  'M05542 Rheumatoid polyneuropathy with rheumatoid arthritis of left hand',
  'M05549 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified hand',
  'M05551 Rheumatoid polyneuropathy with rheumatoid arthritis of right hip',
  'M05552 Rheumatoid polyneuropathy with rheumatoid arthritis of left hip',
  'M05559 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified hip',
  'M05561 Rheumatoid polyneuropathy with rheumatoid arthritis of right knee',
  'M05562 Rheumatoid polyneuropathy with rheumatoid arthritis of left knee',
  'M05569 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified knee',
  'M05571 Rheumatoid polyneuropathy with rheumatoid arthritis of right ankle and foot',
  'M05572 Rheumatoid polyneuropathy with rheumatoid arthritis of left ankle and foot',
  'M05579 Rheumatoid polyneuropathy with rheumatoid arthritis of unspecified ankle and foot',
  'M0559 Rheumatoid polyneuropathy with rheumatoid arthritis of multiple sites',
  'M0560 Rheumatoid arthritis of unspecified site with involvement of other organs and systems',
  'M05611 Rheumatoid arthritis of right shoulder with involvement of other organs and systems',
  'M05612 Rheumatoid arthritis of left shoulder with involvement of other organs and systems',
  'M05619 Rheumatoid arthritis of unspecified shoulder with involvement of other organs and systems',
  'M05621 Rheumatoid arthritis of right elbow with involvement of other organs and systems',
  'M05622 Rheumatoid arthritis of left elbow with involvement of other organs and systems',
  'M05629 Rheumatoid arthritis of unspecified elbow with involvement of other organs and systems',
  'M05631 Rheumatoid arthritis of right wrist with involvement of other organs and systems',
  'M05632 Rheumatoid arthritis of left wrist with involvement of other organs and systems',
  'M05639 Rheumatoid arthritis of unspecified wrist with involvement of other organs and systems',
  'M05641 Rheumatoid arthritis of right hand with involvement of other organs and systems',
  'M05642 Rheumatoid arthritis of left hand with involvement of other organs and systems',
  'M05649 Rheumatoid arthritis of unspecified hand with involvement of other organs and systems',
  'M05651 Rheumatoid arthritis of right hip with involvement of other organs and systems',
  'M05652 Rheumatoid arthritis of left hip with involvement of other organs and systems',
  'M05659 Rheumatoid arthritis of unspecified hip with involvement of other organs and systems',
  'M05661 Rheumatoid arthritis of right knee with involvement of other organs and systems',
  'M05662 Rheumatoid arthritis of left knee with involvement of other organs and systems',
  'M05669 Rheumatoid arthritis of unspecified knee with involvement of other organs and systems',
  'M05671 Rheumatoid arthritis of right ankle and foot with involvement of other organs and systems',
  'M05672 Rheumatoid arthritis of left ankle and foot with involvement of other organs and systems',
  'M05679 Rheumatoid arthritis of unspecified ankle and foot with involvement of other organs and',
  'systems',
  'M0569 Rheumatoid arthritis of multiple sites with involvement of other organs and systems',
  'M0570 Rheumatoid arthritis with rheumatoid factor of unspecified site without organ or systems',
  'involvement',
  'M05711 Rheumatoid arthritis with rheumatoid factor of right shoulder without organ or systems',
  'involvement',
  'M05712 Rheumatoid arthritis with rheumatoid factor of left shoulder without organ or systems',
  'involvement',
  'M05719 Rheumatoid arthritis with rheumatoid factor of unspecified shoulder without organ or systems',
  'involvement',
  'M05721 Rheumatoid arthritis with rheumatoid factor of right elbow without organ or systems',
  'involvement',
  'M05722 Rheumatoid arthritis with rheumatoid factor of left elbow without organ or systems',
  'involvement',
  'M05729 Rheumatoid arthritis with rheumatoid factor of unspecified elbow without organ or systems',
  'involvement',
  'M05731 Rheumatoid arthritis with rheumatoid factor of right wrist without organ or systems',
  'involvement',
  'M05732 Rheumatoid arthritis with rheumatoid factor of left wrist without organ or systems',
  'involvement',
  'M05739 Rheumatoid arthritis with rheumatoid factor of unspecified wrist without organ or systems',
  'involvement',
  'M05741 Rheumatoid arthritis with rheumatoid factor of right hand without organ or systems',
  'involvement',
  'M05742 Rheumatoid arthritis with rheumatoid factor of left hand without organ or systems',
  'involvement',
  'M05749 Rheumatoid arthritis with rheumatoid factor of unspecified hand without organ or systems',
  'involvement',
  'M05751 Rheumatoid arthritis with rheumatoid factor of right hip without organ or systems involvement',
  'M05752 Rheumatoid arthritis with rheumatoid factor of left hip without organ or systems involvement',
  'M05759 Rheumatoid arthritis with rheumatoid factor of unspecified hip without organ or systems',
  'involvement',
  'M05761 Rheumatoid arthritis with rheumatoid factor of right knee without organ or systems',
  'involvement',
  'M05762 Rheumatoid arthritis with rheumatoid factor of left knee without organ or systems',
  'involvement',
  'M05769 Rheumatoid arthritis with rheumatoid factor of unspecified knee without organ or systems',
  'involvement',
  'M05771 Rheumatoid arthritis with rheumatoid factor of right ankle and foot without organ or systems',
  'involvement',
  'M05772 Rheumatoid arthritis with rheumatoid factor of left ankle and foot without organ or systems',
  'involvement',
  'M05779 Rheumatoid arthritis with rheumatoid factor of unspecified ankle and foot without organ or',
  'systems involvement',
  'M0579 Rheumatoid arthritis with rheumatoid factor of multiple sites without organ or systems',
  'involvement',
  'M0580 Other rheumatoid arthritis with rheumatoid factor of unspecified site',
  'M05811 Other rheumatoid arthritis with rheumatoid factor of right shoulder',
  'M05812 Other rheumatoid arthritis with rheumatoid factor of left shoulder',
  'M05819 Other rheumatoid arthritis with rheumatoid factor of unspecified shoulder',
  'M05821 Other rheumatoid arthritis with rheumatoid factor of right elbow',
  'M05822 Other rheumatoid arthritis with rheumatoid factor of left elbow',
  'M05829 Other rheumatoid arthritis with rheumatoid factor of unspecified elbow',
  'M05831 Other rheumatoid arthritis with rheumatoid factor of right wrist',
  'M05832 Other rheumatoid arthritis with rheumatoid factor of left wrist',
  'M05839 Other rheumatoid arthritis with rheumatoid factor of unspecified wrist',
  'M05841 Other rheumatoid arthritis with rheumatoid factor of right hand',
  'M05842 Other rheumatoid arthritis with rheumatoid factor of left hand',
  'M05849 Other rheumatoid arthritis with rheumatoid factor of unspecified hand',
  'M05851 Other rheumatoid arthritis with rheumatoid factor of right hip',
  'M05852 Other rheumatoid arthritis with rheumatoid factor of left hip',
  'M05859 Other rheumatoid arthritis with rheumatoid factor of unspecified hip',
  'M05861 Other rheumatoid arthritis with rheumatoid factor of right knee',
  'M05862 Other rheumatoid arthritis with rheumatoid factor of left knee',
  'M05869 Other rheumatoid arthritis with rheumatoid factor of unspecified knee',
  'M05871 Other rheumatoid arthritis with rheumatoid factor of right ankle and foot',
  'M05872 Other rheumatoid arthritis with rheumatoid factor of left ankle and foot',
  'M05879 Other rheumatoid arthritis with rheumatoid factor of unspecified ankle and foot',
  'M0589 Other rheumatoid arthritis with rheumatoid factor of multiple sites',
  'M059 Rheumatoid arthritis with rheumatoid factor, unspecified',
  'M0600 Rheumatoid arthritis without rheumatoid factor, unspecified site',
  'M06011 Rheumatoid arthritis without rheumatoid factor, right shoulder',
  'M06012 Rheumatoid arthritis without rheumatoid factor, left shoulder',
  'M06019 Rheumatoid arthritis without rheumatoid factor, unspecified shoulder',
  'M06021 Rheumatoid arthritis without rheumatoid factor, right elbow',
  'M06022 Rheumatoid arthritis without rheumatoid factor, left elbow',
  'M06029 Rheumatoid arthritis without rheumatoid factor, unspecified elbow',
  'M06031 Rheumatoid arthritis without rheumatoid factor, right wrist',
  'M06032 Rheumatoid arthritis without rheumatoid factor, left wrist',
  'M06039 Rheumatoid arthritis without rheumatoid factor, unspecified wrist',
  'M06041 Rheumatoid arthritis without rheumatoid factor, right hand',
  'M06042 Rheumatoid arthritis without rheumatoid factor, left hand',
  'M06049 Rheumatoid arthritis without rheumatoid factor, unspecified hand',
  'M06051 Rheumatoid arthritis without rheumatoid factor, right hip',
  'M06052 Rheumatoid arthritis without rheumatoid factor, left hip',
  'M06059 Rheumatoid arthritis without rheumatoid factor, unspecified hip',
  'M06061 Rheumatoid arthritis without rheumatoid factor, right knee',
  'M06062 Rheumatoid arthritis without rheumatoid factor, left knee',
  'M06069 Rheumatoid arthritis without rheumatoid factor, unspecified knee',
  'M06071 Rheumatoid arthritis without rheumatoid factor, right ankle and foot',
  'M06072 Rheumatoid arthritis without rheumatoid factor, left ankle and foot',
  'M06079 Rheumatoid arthritis without rheumatoid factor, unspecified ankle and foot',
  'M0608 Rheumatoid arthritis without rheumatoid factor, vertebrae',
  'M0609 Rheumatoid arthritis without rheumatoid factor, multiple sites',
  "M061 Adult-onset Still's disease",
  'M0620 Rheumatoid bursitis, unspecified site',
  'M06211 Rheumatoid bursitis, right shoulder',
  'M06212 Rheumatoid bursitis, left shoulder',
  'M06219 Rheumatoid bursitis, unspecified shoulder',
  'M06221 Rheumatoid bursitis, right elbow',
  'M06222 Rheumatoid bursitis, left elbow',
  'M06229 Rheumatoid bursitis, unspecified elbow',
  'M06231 Rheumatoid bursitis, right wrist',
  'M06232 Rheumatoid bursitis, left wrist',
  'M06239 Rheumatoid bursitis, unspecified wrist',
  'M06241 Rheumatoid bursitis, right hand',
  'M06242 Rheumatoid bursitis, left hand',
  'M06249 Rheumatoid bursitis, unspecified hand',
  'M06251 Rheumatoid bursitis, right hip',
  'M06252 Rheumatoid bursitis, left hip',
  'M06259 Rheumatoid bursitis, unspecified hip',
  'M06261 Rheumatoid bursitis, right knee',
  'M06262 Rheumatoid bursitis, left knee',
  'M06269 Rheumatoid bursitis, unspecified knee',
  'M06271 Rheumatoid bursitis, right ankle and foot',
  'M06272 Rheumatoid bursitis, left ankle and foot',
  'M06279 Rheumatoid bursitis, unspecified ankle and foot',
  'M0628 Rheumatoid bursitis, vertebrae',
  'M0629 Rheumatoid bursitis, multiple sites',
  'M0630 Rheumatoid nodule, unspecified site',
  'M06311 Rheumatoid nodule, right shoulder',
  'M06312 Rheumatoid nodule, left shoulder',
  'M06319 Rheumatoid nodule, unspecified shoulder',
  'M06321 Rheumatoid nodule, right elbow',
  'M06322 Rheumatoid nodule, left elbow',
  'M06329 Rheumatoid nodule, unspecified elbow',
  'M06331 Rheumatoid nodule, right wrist',
  'M06332 Rheumatoid nodule, left wrist',
  'M06339 Rheumatoid nodule, unspecified wrist',
  'M06341 Rheumatoid nodule, right hand',
  'M06342 Rheumatoid nodule, left hand',
  'M06349 Rheumatoid nodule, unspecified hand',
  'M06351 Rheumatoid nodule, right hip',
  'M06352 Rheumatoid nodule, left hip',
  'M06359 Rheumatoid nodule, unspecified hip',
  'M06361 Rheumatoid nodule, right knee',
  'M06362 Rheumatoid nodule, left knee',
  'M06369 Rheumatoid nodule, unspecified knee',
  'M06371 Rheumatoid nodule, right ankle and foot',
  'M06372 Rheumatoid nodule, left ankle and foot',
  'M06379 Rheumatoid nodule, unspecified ankle and foot',
  'M0638 Rheumatoid nodule, vertebrae',
  'M0639 Rheumatoid nodule, multiple sites',
  'M064 Inflammatory polyarthropathy',
  'M0680 Other specified rheumatoid arthritis, unspecified site',
  'M06811 Other specified rheumatoid arthritis, right shoulder',
  'M06812 Other specified rheumatoid arthritis, left shoulder',
  'M06819 Other specified rheumatoid arthritis, unspecified shoulder',
  'M06821 Other specified rheumatoid arthritis, right elbow',
  'M06822 Other specified rheumatoid arthritis, left elbow',
  'M06829 Other specified rheumatoid arthritis, unspecified elbow',
  'M06831 Other specified rheumatoid arthritis, right wrist',
  'M06832 Other specified rheumatoid arthritis, left wrist',
  'M06839 Other specified rheumatoid arthritis, unspecified wrist',
  'M06841 Other specified rheumatoid arthritis, right hand',
  'M06842 Other specified rheumatoid arthritis, left hand',
  'M06849 Other specified rheumatoid arthritis, unspecified hand',
  'M06851 Other specified rheumatoid arthritis, right hip',
  'M06852 Other specified rheumatoid arthritis, left hip',
  'M06859 Other specified rheumatoid arthritis, unspecified hip',
  'M06861 Other specified rheumatoid arthritis, right knee',
  'M06862 Other specified rheumatoid arthritis, left knee',
  'M06869 Other specified rheumatoid arthritis, unspecified knee',
  'M06871 Other specified rheumatoid arthritis, right ankle and foot',
  'M06872 Other specified rheumatoid arthritis, left ankle and foot',
  'M06879 Other specified rheumatoid arthritis, unspecified ankle and foot',
  'M0688 Other specified rheumatoid arthritis, vertebrae',
  'M0689 Other specified rheumatoid arthritis, multiple sites',
  'M069 Rheumatoid arthritis, unspecified',
  'M0760 Enteropathic arthropathies, unspecified site',
  'M07611 Enteropathic arthropathies, right shoulder',
  'M07612 Enteropathic arthropathies, left shoulder',
  'M07619 Enteropathic arthropathies, unspecified shoulder',
  'M07621 Enteropathic arthropathies, right elbow',
  'M07622 Enteropathic arthropathies, left elbow',
  'M07629 Enteropathic arthropathies, unspecified elbow',
  'M07631 Enteropathic arthropathies, right wrist',
  'M07632 Enteropathic arthropathies, left wrist',
  'M07639 Enteropathic arthropathies, unspecified wrist',
  'M07641 Enteropathic arthropathies, right hand',
  'M07642 Enteropathic arthropathies, left hand',
  'M07649 Enteropathic arthropathies, unspecified hand',
  'M07651 Enteropathic arthropathies, right hip',
  'M07652 Enteropathic arthropathies, left hip',
  'M07659 Enteropathic arthropathies, unspecified hip',
  'M07661 Enteropathic arthropathies, right knee',
  'M07662 Enteropathic arthropathies, left knee',
  'M07669 Enteropathic arthropathies, unspecified knee',
  'M07671 Enteropathic arthropathies, right ankle and foot',
  'M07672 Enteropathic arthropathies, left ankle and foot',
  'M07679 Enteropathic arthropathies, unspecified ankle and foot',
  'M0768 Enteropathic arthropathies, vertebrae',
  'M0769 Enteropathic arthropathies, multiple sites',
  'M0800 Unspecified juvenile rheumatoid arthritis of unspecified site',
  'M08011 Unspecified juvenile rheumatoid arthritis, right shoulder',
  'M08012 Unspecified juvenile rheumatoid arthritis, left shoulder',
  'M08019 Unspecified juvenile rheumatoid arthritis, unspecified shoulder',
  'M08021 Unspecified juvenile rheumatoid arthritis, right elbow',
  'M08022 Unspecified juvenile rheumatoid arthritis, left elbow',
  'M08029 Unspecified juvenile rheumatoid arthritis, unspecified elbow',
  'M08031 Unspecified juvenile rheumatoid arthritis, right wrist',
  'M08032 Unspecified juvenile rheumatoid arthritis, left wrist',
  'M08039 Unspecified juvenile rheumatoid arthritis, unspecified wrist',
  'M08041 Unspecified juvenile rheumatoid arthritis, right hand',
  'M08042 Unspecified juvenile rheumatoid arthritis, left hand',
  'M08049 Unspecified juvenile rheumatoid arthritis, unspecified hand',
  'M08051 Unspecified juvenile rheumatoid arthritis, right hip',
  'M08052 Unspecified juvenile rheumatoid arthritis, left hip',
  'M08059 Unspecified juvenile rheumatoid arthritis, unspecified hip',
  'M08061 Unspecified juvenile rheumatoid arthritis, right knee',
  'M08062 Unspecified juvenile rheumatoid arthritis, left knee',
  'M08069 Unspecified juvenile rheumatoid arthritis, unspecified knee',
  'M08071 Unspecified juvenile rheumatoid arthritis, right ankle and foot',
  'M08072 Unspecified juvenile rheumatoid arthritis, left ankle and foot',
  'M08079 Unspecified juvenile rheumatoid arthritis, unspecified ankle and foot',
  'M0808 Unspecified juvenile rheumatoid arthritis, vertebrae',
  'M0809 Unspecified juvenile rheumatoid arthritis, multiple sites',
  'M081 Juvenile ankylosing spondylitis',
  'M0820 Juvenile rheumatoid arthritis with systemic onset, unspecified site',
  'M08211 Juvenile rheumatoid arthritis with systemic onset, right shoulder',
  'M08212 Juvenile rheumatoid arthritis with systemic onset, left shoulder',
  'M08219 Juvenile rheumatoid arthritis with systemic onset, unspecified shoulder',
  'M08221 Juvenile rheumatoid arthritis with systemic onset, right elbow',
  'M08222 Juvenile rheumatoid arthritis with systemic onset, left elbow',
  'M08229 Juvenile rheumatoid arthritis with systemic onset, unspecified elbow',
  'M08231 Juvenile rheumatoid arthritis with systemic onset, right wrist',
  'M08232 Juvenile rheumatoid arthritis with systemic onset, left wrist',
  'M08239 Juvenile rheumatoid arthritis with systemic onset, unspecified wrist',
  'M08241 Juvenile rheumatoid arthritis with systemic onset, right hand',
  'M08242 Juvenile rheumatoid arthritis with systemic onset, left hand',
  'M08249 Juvenile rheumatoid arthritis with systemic onset, unspecified hand',
  'M08251 Juvenile rheumatoid arthritis with systemic onset, right hip',
  'M08252 Juvenile rheumatoid arthritis with systemic onset, left hip',
  'M08259 Juvenile rheumatoid arthritis with systemic onset, unspecified hip',
  'M08261 Juvenile rheumatoid arthritis with systemic onset, right knee',
  'M08262 Juvenile rheumatoid arthritis with systemic onset, left knee',
  'M08269 Juvenile rheumatoid arthritis with systemic onset, unspecified knee',
  'M08271 Juvenile rheumatoid arthritis with systemic onset, right ankle and foot',
  'M08272 Juvenile rheumatoid arthritis with systemic onset, left ankle and foot',
  'M08279 Juvenile rheumatoid arthritis with systemic onset, unspecified ankle and foot',
  'M0828 Juvenile rheumatoid arthritis with systemic onset, vertebrae',
  'M0829 Juvenile rheumatoid arthritis with systemic onset, multiple sites',
  'M083 Juvenile rheumatoid polyarthritis (seronegative)',
  'M0840 Pauciarticular juvenile rheumatoid arthritis, unspecified site',
  'M08411 Pauciarticular juvenile rheumatoid arthritis, right shoulder',
  'M08412 Pauciarticular juvenile rheumatoid arthritis, left shoulder',
  'M08419 Pauciarticular juvenile rheumatoid arthritis, unspecified shoulder',
  'M08421 Pauciarticular juvenile rheumatoid arthritis, right elbow',
  'M08422 Pauciarticular juvenile rheumatoid arthritis, left elbow',
  'M08429 Pauciarticular juvenile rheumatoid arthritis, unspecified elbow',
  'M08431 Pauciarticular juvenile rheumatoid arthritis, right wrist',
  'M08432 Pauciarticular juvenile rheumatoid arthritis, left wrist',
  'M08439 Pauciarticular juvenile rheumatoid arthritis, unspecified wrist',
  'M08441 Pauciarticular juvenile rheumatoid arthritis, right hand',
  'M08442 Pauciarticular juvenile rheumatoid arthritis, left hand',
  'M08449 Pauciarticular juvenile rheumatoid arthritis, unspecified hand',
  'M08451 Pauciarticular juvenile rheumatoid arthritis, right hip',
  'M08452 Pauciarticular juvenile rheumatoid arthritis, left hip',
  'M08459 Pauciarticular juvenile rheumatoid arthritis, unspecified hip',
  'M08461 Pauciarticular juvenile rheumatoid arthritis, right knee',
  'M08462 Pauciarticular juvenile rheumatoid arthritis, left knee',
  'M08469 Pauciarticular juvenile rheumatoid arthritis, unspecified knee',
  'M08471 Pauciarticular juvenile rheumatoid arthritis, right ankle and foot',
  'M08472 Pauciarticular juvenile rheumatoid arthritis, left ankle and foot',
  'M08479 Pauciarticular juvenile rheumatoid arthritis, unspecified ankle and foot',
  'M0848 Pauciarticular juvenile rheumatoid arthritis, vertebrae',
  'M0880 Other juvenile arthritis, unspecified site',
  'M08811 Other juvenile arthritis, right shoulder',
  'M08812 Other juvenile arthritis, left shoulder',
  'M08819 Other juvenile arthritis, unspecified shoulder',
  'M08821 Other juvenile arthritis, right elbow',
  'M08822 Other juvenile arthritis, left elbow',
  'M08829 Other juvenile arthritis, unspecified elbow',
  'M08831 Other juvenile arthritis, right wrist',
  'M08832 Other juvenile arthritis, left wrist',
  'M08839 Other juvenile arthritis, unspecified wrist',
  'M08841 Other juvenile arthritis, right hand',
  'M08842 Other juvenile arthritis, left hand',
  'M08849 Other juvenile arthritis, unspecified hand',
  'M08851 Other juvenile arthritis, right hip',
  'M08852 Other juvenile arthritis, left hip',
  'M08859 Other juvenile arthritis, unspecified hip',
  'M08861 Other juvenile arthritis, right knee',
  'M08862 Other juvenile arthritis, left knee',
  'M08869 Other juvenile arthritis, unspecified knee',
  'M08871 Other juvenile arthritis, right ankle and foot',
  'M08872 Other juvenile arthritis, left ankle and foot',
  'M08879 Other juvenile arthritis, unspecified ankle and foot',
  'M0888 Other juvenile arthritis, other specified site',
  'M0889 Other juvenile arthritis, multiple sites',
  'M0890 Juvenile arthritis, unspecified, unspecified site',
  'M08911 Juvenile arthritis, unspecified, right shoulder',
  'M08912 Juvenile arthritis, unspecified, left shoulder',
  'M08919 Juvenile arthritis, unspecified, unspecified shoulder',
  'M08921 Juvenile arthritis, unspecified, right elbow',
  'M08922 Juvenile arthritis, unspecified, left elbow',
  'M08929 Juvenile arthritis, unspecified, unspecified elbow',
  'M08931 Juvenile arthritis, unspecified, right wrist',
  'M08932 Juvenile arthritis, unspecified, left wrist',
  'M08939 Juvenile arthritis, unspecified, unspecified wrist',
  'M08941 Juvenile arthritis, unspecified, right hand',
  'M08942 Juvenile arthritis, unspecified, left hand',
  'M08949 Juvenile arthritis, unspecified, unspecified hand',
  'M08951 Juvenile arthritis, unspecified, right hip',
  'M08952 Juvenile arthritis, unspecified, left hip',
  'M08959 Juvenile arthritis, unspecified, unspecified hip',
  'M08961 Juvenile arthritis, unspecified, right knee',
  'M08962 Juvenile arthritis, unspecified, left knee',
  'M08969 Juvenile arthritis, unspecified, unspecified knee',
  'M08971 Juvenile arthritis, unspecified, right ankle and foot',
  'M08972 Juvenile arthritis, unspecified, left ankle and foot',
  'M08979 Juvenile arthritis, unspecified, unspecified ankle and foot',
  'M0898 Juvenile arthritis, unspecified, vertebrae',
  'M0899 Juvenile arthritis, unspecified, multiple sites',
  'M1A00X0 Idiopathic chronic gout, unspecified site, without tophus (tophi)',
  'M1A00X1 Idiopathic chronic gout, unspecified site, with tophus (tophi)',
  'M1A0110 Idiopathic chronic gout, right shoulder, without tophus (tophi)',
  'M1A0111 Idiopathic chronic gout, right shoulder, with tophus (tophi)',
  'M1A0120 Idiopathic chronic gout, left shoulder, without tophus (tophi)',
  'M1A0121 Idiopathic chronic gout, left shoulder, with tophus (tophi)',
  'M1A0190 Idiopathic chronic gout, unspecified shoulder, without tophus (tophi)',
  'M1A0191 Idiopathic chronic gout, unspecified shoulder, with tophus (tophi)',
  'M1A0210 Idiopathic chronic gout, right elbow, without tophus (tophi)',
  'M1A0211 Idiopathic chronic gout, right elbow, with tophus (tophi)',
  'M1A0220 Idiopathic chronic gout, left elbow, without tophus (tophi)',
  'M1A0221 Idiopathic chronic gout, left elbow, with tophus (tophi)',
  'M1A0290 Idiopathic chronic gout, unspecified elbow, without tophus (tophi)',
  'M1A0291 Idiopathic chronic gout, unspecified elbow, with tophus (tophi)',
  'M1A0310 Idiopathic chronic gout, right wrist, without tophus (tophi)',
  'M1A0311 Idiopathic chronic gout, right wrist, with tophus (tophi)',
  'M1A0320 Idiopathic chronic gout, left wrist, without tophus (tophi)',
  'M1A0321 Idiopathic chronic gout, left wrist, with tophus (tophi)',
  'M1A0390 Idiopathic chronic gout, unspecified wrist, without tophus (tophi)',
  'M1A0391 Idiopathic chronic gout, unspecified wrist, with tophus (tophi)',
  'M1A0410 Idiopathic chronic gout, right hand, without tophus (tophi)',
  'M1A0411 Idiopathic chronic gout, right hand, with tophus (tophi)',
  'M1A0420 Idiopathic chronic gout, left hand, without tophus (tophi)',
  'M1A0421 Idiopathic chronic gout, left hand, with tophus (tophi)',
  'M1A0490 Idiopathic chronic gout, unspecified hand, without tophus (tophi)',
  'M1A0491 Idiopathic chronic gout, unspecified hand, with tophus (tophi)',
  'M1A0510 Idiopathic chronic gout, right hip, without tophus (tophi)',
  'M1A0511 Idiopathic chronic gout, right hip, with tophus (tophi)',
  'M1A0520 Idiopathic chronic gout, left hip, without tophus (tophi)',
  'M1A0521 Idiopathic chronic gout, left hip, with tophus (tophi)',
  'M1A0590 Idiopathic chronic gout, unspecified hip, without tophus (tophi)',
  'M1A0591 Idiopathic chronic gout, unspecified hip, with tophus (tophi)',
  'M1A0610 Idiopathic chronic gout, right knee, without tophus (tophi)',
  'M1A0611 Idiopathic chronic gout, right knee, with tophus (tophi)',
  'M1A0620 Idiopathic chronic gout, left knee, without tophus (tophi)',
  'M1A0621 Idiopathic chronic gout, left knee, with tophus (tophi)',
  'M1A0690 Idiopathic chronic gout, unspecified knee, without tophus (tophi)',
  'M1A0691 Idiopathic chronic gout, unspecified knee, with tophus (tophi)',
  'M1A0710 Idiopathic chronic gout, right ankle and foot, without tophus (tophi)',
  'M1A0711 Idiopathic chronic gout, right ankle and foot, with tophus (tophi)',
  'M1A0720 Idiopathic chronic gout, left ankle and foot, without tophus (tophi)',
  'M1A0721 Idiopathic chronic gout, left ankle and foot, with tophus (tophi)',
  'M1A0790 Idiopathic chronic gout, unspecified ankle and foot, without tophus (tophi)',
  'M1A0791 Idiopathic chronic gout, unspecified ankle and foot, with tophus (tophi)',
  'M1A08X0 Idiopathic chronic gout, vertebrae, without tophus (tophi)',
  'M1A08X1 Idiopathic chronic gout, vertebrae, with tophus (tophi)',
  'M1A09X0 Idiopathic chronic gout, multiple sites, without tophus (tophi)',
  'M1A09X1 Idiopathic chronic gout, multiple sites, with tophus (tophi)',
  'M1A10X0 Lead-induced chronic gout, unspecified site, without tophus (tophi)',
  'M1A10X1 Lead-induced chronic gout, unspecified site, with tophus (tophi)',
  'M1A1110 Lead-induced chronic gout, right shoulder, without tophus (tophi)',
  'M1A1111 Lead-induced chronic gout, right shoulder, with tophus (tophi)',
  'M1A1120 Lead-induced chronic gout, left shoulder, without tophus (tophi)',
  'M1A1121 Lead-induced chronic gout, left shoulder, with tophus (tophi)',
  'M1A1190 Lead-induced chronic gout, unspecified shoulder, without tophus (tophi)',
  'M1A1191 Lead-induced chronic gout, unspecified shoulder, with tophus (tophi)',
  'M1A1210 Lead-induced chronic gout, right elbow, without tophus (tophi)',
  'M1A1211 Lead-induced chronic gout, right elbow, with tophus (tophi)',
  'M1A1220 Lead-induced chronic gout, left elbow, without tophus (tophi)',
  'M1A1221 Lead-induced chronic gout, left elbow, with tophus (tophi)',
  'M1A1290 Lead-induced chronic gout, unspecified elbow, without tophus (tophi)',
  'M1A1291 Lead-induced chronic gout, unspecified elbow, with tophus (tophi)',
  'M1A1310 Lead-induced chronic gout, right wrist, without tophus (tophi)',
  'M1A1311 Lead-induced chronic gout, right wrist, with tophus (tophi)',
  'M1A1320 Lead-induced chronic gout, left wrist, without tophus (tophi)',
  'M1A1321 Lead-induced chronic gout, left wrist, with tophus (tophi)',
  'M1A1390 Lead-induced chronic gout, unspecified wrist, without tophus (tophi)',
  'M1A1391 Lead-induced chronic gout, unspecified wrist, with tophus (tophi)',
  'M1A1410 Lead-induced chronic gout, right hand, without tophus (tophi)',
  'M1A1411 Lead-induced chronic gout, right hand, with tophus (tophi)',
  'M1A1420 Lead-induced chronic gout, left hand, without tophus (tophi)',
  'M1A1421 Lead-induced chronic gout, left hand, with tophus (tophi)',
  'M1A1490 Lead-induced chronic gout, unspecified hand, without tophus (tophi)',
  'M1A1491 Lead-induced chronic gout, unspecified hand, with tophus (tophi)',
  'M1A1510 Lead-induced chronic gout, right hip, without tophus (tophi)',
  'M1A1511 Lead-induced chronic gout, right hip, with tophus (tophi)',
  'M1A1520 Lead-induced chronic gout, left hip, without tophus (tophi)',
  'M1A1521 Lead-induced chronic gout, left hip, with tophus (tophi)',
  'M1A1590 Lead-induced chronic gout, unspecified hip, without tophus (tophi)',
  'M1A1591 Lead-induced chronic gout, unspecified hip, with tophus (tophi)',
  'M1A1610 Lead-induced chronic gout, right knee, without tophus (tophi)',
  'M1A1611 Lead-induced chronic gout, right knee, with tophus (tophi)',
  'M1A1620 Lead-induced chronic gout, left knee, without tophus (tophi)',
  'M1A1621 Lead-induced chronic gout, left knee, with tophus (tophi)',
  'M1A1690 Lead-induced chronic gout, unspecified knee, without tophus (tophi)',
  'M1A1691 Lead-induced chronic gout, unspecified knee, with tophus (tophi)',
  'M1A1710 Lead-induced chronic gout, right ankle and foot, without tophus (tophi)',
  'M1A1711 Lead-induced chronic gout, right ankle and foot, with tophus (tophi)',
  'M1A1720 Lead-induced chronic gout, left ankle and foot, without tophus (tophi)',
  'M1A1721 Lead-induced chronic gout, left ankle and foot, with tophus (tophi)',
  'M1A1790 Lead-induced chronic gout, unspecified ankle and foot, without tophus (tophi)',
  'M1A1791 Lead-induced chronic gout, unspecified ankle and foot, with tophus (tophi)',
  'M1A18X0 Lead-induced chronic gout, vertebrae, without tophus (tophi)',
  'M1A18X1 Lead-induced chronic gout, vertebrae, with tophus (tophi)',
  'M1A19X0 Lead-induced chronic gout, multiple sites, without tophus (tophi)',
  'M1A19X1 Lead-induced chronic gout, multiple sites, with tophus (tophi)',
  'M1A20X0 Drug-induced chronic gout, unspecified site, without tophus (tophi)',
  'M1A20X1 Drug-induced chronic gout, unspecified site, with tophus (tophi)',
  'M1A2110 Drug-induced chronic gout, right shoulder, without tophus (tophi)',
  'M1A2111 Drug-induced chronic gout, right shoulder, with tophus (tophi)',
  'M1A2120 Drug-induced chronic gout, left shoulder, without tophus (tophi)',
  'M1A2121 Drug-induced chronic gout, left shoulder, with tophus (tophi)',
  'M1A2190 Drug-induced chronic gout, unspecified shoulder, without tophus (tophi)',
  'M1A2191 Drug-induced chronic gout, unspecified shoulder, with tophus (tophi)',
  'M1A2210 Drug-induced chronic gout, right elbow, without tophus (tophi)',
  'M1A2211 Drug-induced chronic gout, right elbow, with tophus (tophi)',
  'M1A2220 Drug-induced chronic gout, left elbow, without tophus (tophi)',
  'M1A2221 Drug-induced chronic gout, left elbow, with tophus (tophi)',
  'M1A2290 Drug-induced chronic gout, unspecified elbow, without tophus (tophi)',
  'M1A2291 Drug-induced chronic gout, unspecified elbow, with tophus (tophi)',
  'M1A2310 Drug-induced chronic gout, right wrist, without tophus (tophi)',
  'M1A2311 Drug-induced chronic gout, right wrist, with tophus (tophi)',
  'M1A2320 Drug-induced chronic gout, left wrist, without tophus (tophi)',
  'M1A2321 Drug-induced chronic gout, left wrist, with tophus (tophi)',
  'M1A2390 Drug-induced chronic gout, unspecified wrist, without tophus (tophi)',
  'M1A2391 Drug-induced chronic gout, unspecified wrist, with tophus (tophi)',
  'M1A2410 Drug-induced chronic gout, right hand, without tophus (tophi)',
  'M1A2411 Drug-induced chronic gout, right hand, with tophus (tophi)',
  'M1A2420 Drug-induced chronic gout, left hand, without tophus (tophi)',
  'M1A2421 Drug-induced chronic gout, left hand, with tophus (tophi)',
  'M1A2490 Drug-induced chronic gout, unspecified hand, without tophus (tophi)',
  'M1A2491 Drug-induced chronic gout, unspecified hand, with tophus (tophi)',
  'M1A2510 Drug-induced chronic gout, right hip, without tophus (tophi)',
  'M1A2511 Drug-induced chronic gout, right hip, with tophus (tophi)',
  'M1A2520 Drug-induced chronic gout, left hip, without tophus (tophi)',
  'M1A2521 Drug-induced chronic gout, left hip, with tophus (tophi)',
  'M1A2590 Drug-induced chronic gout, unspecified hip, without tophus (tophi)',
  'M1A2591 Drug-induced chronic gout, unspecified hip, with tophus (tophi)',
  'M1A2610 Drug-induced chronic gout, right knee, without tophus (tophi)',
  'M1A2611 Drug-induced chronic gout, right knee, with tophus (tophi)',
  'M1A2620 Drug-induced chronic gout, left knee, without tophus (tophi)',
  'M1A2621 Drug-induced chronic gout, left knee, with tophus (tophi)',
  'M1A2690 Drug-induced chronic gout, unspecified knee, without tophus (tophi)',
  'M1A2691 Drug-induced chronic gout, unspecified knee, with tophus (tophi)',
  'M1A2710 Drug-induced chronic gout, right ankle and foot, without tophus (tophi)',
  'M1A2711 Drug-induced chronic gout, right ankle and foot, with tophus (tophi)',
  'M1A2720 Drug-induced chronic gout, left ankle and foot, without tophus (tophi)',
  'M1A2721 Drug-induced chronic gout, left ankle and foot, with tophus (tophi)',
  'M1A2790 Drug-induced chronic gout, unspecified ankle and foot, without tophus (tophi)',
  'M1A2791 Drug-induced chronic gout, unspecified ankle and foot, with tophus (tophi)',
  'M1A28X0 Drug-induced chronic gout, vertebrae, without tophus (tophi)',
  'M1A28X1 Drug-induced chronic gout, vertebrae, with tophus (tophi)',
  'M1A29X0 Drug-induced chronic gout, multiple sites, without tophus (tophi)',
  'M1A29X1 Drug-induced chronic gout, multiple sites, with tophus (tophi)',
  'M1A30X0 Chronic gout due to renal impairment, unspecified site, without tophus (tophi)',
  'M1A30X1 Chronic gout due to renal impairment, unspecified site, with tophus (tophi)',
  'M1A3110 Chronic gout due to renal impairment, right shoulder, without tophus (tophi)',
  'M1A3111 Chronic gout due to renal impairment, right shoulder, with tophus (tophi)',
  'M1A3120 Chronic gout due to renal impairment, left shoulder, without tophus (tophi)',
  'M1A3121 Chronic gout due to renal impairment, left shoulder, with tophus (tophi)',
  'M1A3190 Chronic gout due to renal impairment, unspecified shoulder, without tophus (tophi)',
  'M1A3191 Chronic gout due to renal impairment, unspecified shoulder, with tophus (tophi)',
  'M1A3210 Chronic gout due to renal impairment, right elbow, without tophus (tophi)',
  'M1A3211 Chronic gout due to renal impairment, right elbow, with tophus (tophi)',
  'M1A3220 Chronic gout due to renal impairment, left elbow, without tophus (tophi)',
  'M1A3221 Chronic gout due to renal impairment, left elbow, with tophus (tophi)',
  'M1A3290 Chronic gout due to renal impairment, unspecified elbow, without tophus (tophi)',
  'M1A3291 Chronic gout due to renal impairment, unspecified elbow, with tophus (tophi)',
  'M1A3310 Chronic gout due to renal impairment, right wrist, without tophus (tophi)',
  'M1A3311 Chronic gout due to renal impairment, right wrist, with tophus (tophi)',
  'M1A3320 Chronic gout due to renal impairment, left wrist, without tophus (tophi)',
  'M1A3321 Chronic gout due to renal impairment, left wrist, with tophus (tophi)',
  'M1A3390 Chronic gout due to renal impairment, unspecified wrist, without tophus (tophi)',
  'M1A3391 Chronic gout due to renal impairment, unspecified wrist, with tophus (tophi)',
  'M1A3410 Chronic gout due to renal impairment, right hand, without tophus (tophi)',
  'M1A3411 Chronic gout due to renal impairment, right hand, with tophus (tophi)',
  'M1A3420 Chronic gout due to renal impairment, left hand, without tophus (tophi)',
  'M1A3421 Chronic gout due to renal impairment, left hand, with tophus (tophi)',
  'M1A3490 Chronic gout due to renal impairment, unspecified hand, without tophus (tophi)',
  'M1A3491 Chronic gout due to renal impairment, unspecified hand, with tophus (tophi)',
  'M1A3510 Chronic gout due to renal impairment, right hip, without tophus (tophi)',
  'M1A3511 Chronic gout due to renal impairment, right hip, with tophus (tophi)',
  'M1A3520 Chronic gout due to renal impairment, left hip, without tophus (tophi)',
  'M1A3521 Chronic gout due to renal impairment, left hip, with tophus (tophi)',
  'M1A3590 Chronic gout due to renal impairment, unspecified hip, without tophus (tophi)',
  'M1A3591 Chronic gout due to renal impairment, unspecified hip, with tophus (tophi)',
  'M1A3610 Chronic gout due to renal impairment, right knee, without tophus (tophi)',
  'M1A3611 Chronic gout due to renal impairment, right knee, with tophus (tophi)',
  'M1A3620 Chronic gout due to renal impairment, left knee, without tophus (tophi)',
  'M1A3621 Chronic gout due to renal impairment, left knee, with tophus (tophi)',
  'M1A3690 Chronic gout due to renal impairment, unspecified knee, without tophus (tophi)',
  'M1A3691 Chronic gout due to renal impairment, unspecified knee, with tophus (tophi)',
  'M1A3710 Chronic gout due to renal impairment, right ankle and foot, without tophus (tophi)',
  'M1A3711 Chronic gout due to renal impairment, right ankle and foot, with tophus (tophi)',
  'M1A3720 Chronic gout due to renal impairment, left ankle and foot, without tophus (tophi)',
  'M1A3721 Chronic gout due to renal impairment, left ankle and foot, with tophus (tophi)',
  'M1A3790 Chronic gout due to renal impairment, unspecified ankle and foot, without tophus (tophi)',
  'M1A3791 Chronic gout due to renal impairment, unspecified ankle and foot, with tophus (tophi)',
  'M1A38X0 Chronic gout due to renal impairment, vertebrae, without tophus (tophi)',
  'M1A38X1 Chronic gout due to renal impairment, vertebrae, with tophus (tophi)',
  'M1A39X0 Chronic gout due to renal impairment, multiple sites, without tophus (tophi)',
  'M1A39X1 Chronic gout due to renal impairment, multiple sites, with tophus (tophi)',
  'M1A40X0 Other secondary chronic gout, unspecified site, without tophus (tophi)',
  'M1A40X1 Other secondary chronic gout, unspecified site, with tophus (tophi)',
  'M1A4110 Other secondary chronic gout, right shoulder, without tophus (tophi)',
  'M1A4111 Other secondary chronic gout, right shoulder, with tophus (tophi)',
  'M1A4120 Other secondary chronic gout, left shoulder, without tophus (tophi)',
  'M1A4121 Other secondary chronic gout, left shoulder, with tophus (tophi)',
  'M1A4190 Other secondary chronic gout, unspecified shoulder, without tophus (tophi)',
  'M1A4191 Other secondary chronic gout, unspecified shoulder, with tophus (tophi)',
  'M1A4210 Other secondary chronic gout, right elbow, without tophus (tophi)',
  'M1A4211 Other secondary chronic gout, right elbow, with tophus (tophi)',
  'M1A4220 Other secondary chronic gout, left elbow, without tophus (tophi)',
  'M1A4221 Other secondary chronic gout, left elbow, with tophus (tophi)',
  'M1A4290 Other secondary chronic gout, unspecified elbow, without tophus (tophi)',
  'M1A4291 Other secondary chronic gout, unspecified elbow, with tophus (tophi)',
  'M1A4310 Other secondary chronic gout, right wrist, without tophus (tophi)',
  'M1A4311 Other secondary chronic gout, right wrist, with tophus (tophi)',
  'M1A4320 Other secondary chronic gout, left wrist, without tophus (tophi)',
  'M1A4321 Other secondary chronic gout, left wrist, with tophus (tophi)',
  'M1A4390 Other secondary chronic gout, unspecified wrist, without tophus (tophi)',
  'M1A4391 Other secondary chronic gout, unspecified wrist, with tophus (tophi)',
  'M1A4410 Other secondary chronic gout, right hand, without tophus (tophi)',
  'M1A4411 Other secondary chronic gout, right hand, with tophus (tophi)',
  'M1A4420 Other secondary chronic gout, left hand, without tophus (tophi)',
  'M1A4421 Other secondary chronic gout, left hand, with tophus (tophi)',
  'M1A4490 Other secondary chronic gout, unspecified hand, without tophus (tophi)',
  'M1A4491 Other secondary chronic gout, unspecified hand, with tophus (tophi)',
  'M1A4510 Other secondary chronic gout, right hip, without tophus (tophi)',
  'M1A4511 Other secondary chronic gout, right hip, with tophus (tophi)',
  'M1A4520 Other secondary chronic gout, left hip, without tophus (tophi)',
  'M1A4521 Other secondary chronic gout, left hip, with tophus (tophi)',
  'M1A4590 Other secondary chronic gout, unspecified hip, without tophus (tophi)',
  'M1A4591 Other secondary chronic gout, unspecified hip, with tophus (tophi)',
  'M1A4610 Other secondary chronic gout, right knee, without tophus (tophi)',
  'M1A4611 Other secondary chronic gout, right knee, with tophus (tophi)',
  'M1A4620 Other secondary chronic gout, left knee, without tophus (tophi)',
  'M1A4621 Other secondary chronic gout, left knee, with tophus (tophi)',
  'M1A4690 Other secondary chronic gout, unspecified knee, without tophus (tophi)',
  'M1A4691 Other secondary chronic gout, unspecified knee, with tophus (tophi)',
  'M1A4710 Other secondary chronic gout, right ankle and foot, without tophus (tophi)',
  'M1A4711 Other secondary chronic gout, right ankle and foot, with tophus (tophi)',
  'M1A4720 Other secondary chronic gout, left ankle and foot, without tophus (tophi)',
  'M1A4721 Other secondary chronic gout, left ankle and foot, with tophus (tophi)',
  'M1A4790 Other secondary chronic gout, unspecified ankle and foot, without tophus (tophi)',
  'M1A4791 Other secondary chronic gout, unspecified ankle and foot, with tophus (tophi)',
  'M1A48X0 Other secondary chronic gout, vertebrae, without tophus (tophi)',
  'M1A48X1 Other secondary chronic gout, vertebrae, with tophus (tophi)',
  'M1A49X0 Other secondary chronic gout, multiple sites, without tophus (tophi)',
  'M1A49X1 Other secondary chronic gout, multiple sites, with tophus (tophi)',
  'M1A9XX0 Chronic gout, unspecified, without tophus (tophi)',
  'M1A9XX1 Chronic gout, unspecified, with tophus (tophi)',
  'M1000 Idiopathic gout, unspecified site',
  'M10011 Idiopathic gout, right shoulder',
  'M10012 Idiopathic gout, left shoulder',
  'M10019 Idiopathic gout, unspecified shoulder',
  'M10021 Idiopathic gout, right elbow',
  'M10022 Idiopathic gout, left elbow',
  'M10029 Idiopathic gout, unspecified elbow',
  'M10031 Idiopathic gout, right wrist',
  'M10032 Idiopathic gout, left wrist',
  'M10039 Idiopathic gout, unspecified wrist',
  'M10041 Idiopathic gout, right hand',
  'M10042 Idiopathic gout, left hand',
  'M10049 Idiopathic gout, unspecified hand',
  'M10051 Idiopathic gout, right hip',
  'M10052 Idiopathic gout, left hip',
  'M10059 Idiopathic gout, unspecified hip',
  'M10061 Idiopathic gout, right knee',
  'M10062 Idiopathic gout, left knee',
  'M10069 Idiopathic gout, unspecified knee',
  'M10071 Idiopathic gout, right ankle and foot',
  'M10072 Idiopathic gout, left ankle and foot',
  'M10079 Idiopathic gout, unspecified ankle and foot',
  'M1008 Idiopathic gout, vertebrae',
  'M1009 Idiopathic gout, multiple sites',
  'M1010 Lead-induced gout, unspecified site',
  'M10111 Lead-induced gout, right shoulder',
  'M10112 Lead-induced gout, left shoulder',
  'M10119 Lead-induced gout, unspecified shoulder',
  'M10121 Lead-induced gout, right elbow',
  'M10122 Lead-induced gout, left elbow',
  'M10129 Lead-induced gout, unspecified elbow',
  'M10131 Lead-induced gout, right wrist',
  'M10132 Lead-induced gout, left wrist',
  'M10139 Lead-induced gout, unspecified wrist',
  'M10141 Lead-induced gout, right hand',
  'M10142 Lead-induced gout, left hand',
  'M10149 Lead-induced gout, unspecified hand',
  'M10151 Lead-induced gout, right hip',
  'M10152 Lead-induced gout, left hip',
  'M10159 Lead-induced gout, unspecified hip',
  'M10161 Lead-induced gout, right knee',
  'M10162 Lead-induced gout, left knee',
  'M10169 Lead-induced gout, unspecified knee',
  'M10171 Lead-induced gout, right ankle and foot',
  'M10172 Lead-induced gout, left ankle and foot',
  'M10179 Lead-induced gout, unspecified ankle and foot',
  'M1018 Lead-induced gout, vertebrae',
  'M1019 Lead-induced gout, multiple sites',
  'M1020 Drug-induced gout, unspecified site',
  'M10211 Drug-induced gout, right shoulder',
  'M10212 Drug-induced gout, left shoulder',
  'M10219 Drug-induced gout, unspecified shoulder',
  'M10221 Drug-induced gout, right elbow',
  'M10222 Drug-induced gout, left elbow',
  'M10229 Drug-induced gout, unspecified elbow',
  'M10231 Drug-induced gout, right wrist',
  'M10232 Drug-induced gout, left wrist',
  'M10239 Drug-induced gout, unspecified wrist',
  'M10241 Drug-induced gout, right hand',
  'M10242 Drug-induced gout, left hand',
  'M10249 Drug-induced gout, unspecified hand',
  'M10251 Drug-induced gout, right hip',
  'M10252 Drug-induced gout, left hip',
  'M10259 Drug-induced gout, unspecified hip',
  'M10261 Drug-induced gout, right knee',
  'M10262 Drug-induced gout, left knee',
  'M10269 Drug-induced gout, unspecified knee',
  'M10271 Drug-induced gout, right ankle and foot',
  'M10272 Drug-induced gout, left ankle and foot',
  'M10279 Drug-induced gout, unspecified ankle and foot',
  'M1028 Drug-induced gout, vertebrae',
  'M1029 Drug-induced gout, multiple sites',
  'M1030 Gout due to renal impairment, unspecified site',
  'M10311 Gout due to renal impairment, right shoulder',
  'M10312 Gout due to renal impairment, left shoulder',
  'M10319 Gout due to renal impairment, unspecified shoulder',
  'M10321 Gout due to renal impairment, right elbow',
  'M10322 Gout due to renal impairment, left elbow',
  'M10329 Gout due to renal impairment, unspecified elbow',
  'M10331 Gout due to renal impairment, right wrist',
  'M10332 Gout due to renal impairment, left wrist',
  'M10339 Gout due to renal impairment, unspecified wrist',
  'M10341 Gout due to renal impairment, right hand',
  'M10342 Gout due to renal impairment, left hand',
  'M10349 Gout due to renal impairment, unspecified hand',
  'M10351 Gout due to renal impairment, right hip',
  'M10352 Gout due to renal impairment, left hip',
  'M10359 Gout due to renal impairment, unspecified hip',
  'M10361 Gout due to renal impairment, right knee',
  'M10362 Gout due to renal impairment, left knee',
  'M10369 Gout due to renal impairment, unspecified knee',
  'M10371 Gout due to renal impairment, right ankle and foot',
  'M10372 Gout due to renal impairment, left ankle and foot',
  'M10379 Gout due to renal impairment, unspecified ankle and foot',
  'M1038 Gout due to renal impairment, vertebrae',
  'M1039 Gout due to renal impairment, multiple sites',
  'M1040 Other secondary gout, unspecified site',
  'M10411 Other secondary gout, right shoulder',
  'M10412 Other secondary gout, left shoulder',
  'M10419 Other secondary gout, unspecified shoulder',
  'M10421 Other secondary gout, right elbow',
  'M10422 Other secondary gout, left elbow',
  'M10429 Other secondary gout, unspecified elbow',
  'M10431 Other secondary gout, right wrist',
  'M10432 Other secondary gout, left wrist',
  'M10439 Other secondary gout, unspecified wrist',
  'M10441 Other secondary gout, right hand',
  'M10442 Other secondary gout, left hand',
  'M10449 Other secondary gout, unspecified hand',
  'M10451 Other secondary gout, right hip',
  'M10452 Other secondary gout, left hip',
  'M10459 Other secondary gout, unspecified hip',
  'M10461 Other secondary gout, right knee',
  'M10462 Other secondary gout, left knee',
  'M10469 Other secondary gout, unspecified knee',
  'M10471 Other secondary gout, right ankle and foot',
  'M10472 Other secondary gout, left ankle and foot',
  'M10479 Other secondary gout, unspecified ankle and foot',
  'M1048 Other secondary gout, vertebrae',
  'M1049 Other secondary gout, multiple sites',
  'M109 Gout, unspecified',
  'M1100 Hydroxyapatite deposition disease, unspecified site',
  'M11011 Hydroxyapatite deposition disease, right shoulder',
  'M11012 Hydroxyapatite deposition disease, left shoulder',
  'M11019 Hydroxyapatite deposition disease, unspecified shoulder',
  'M11021 Hydroxyapatite deposition disease, right elbow',
  'M11022 Hydroxyapatite deposition disease, left elbow',
  'M11029 Hydroxyapatite deposition disease, unspecified elbow',
  'M11031 Hydroxyapatite deposition disease, right wrist',
  'M11032 Hydroxyapatite deposition disease, left wrist',
  'M11039 Hydroxyapatite deposition disease, unspecified wrist',
  'M11041 Hydroxyapatite deposition disease, right hand',
  'M11042 Hydroxyapatite deposition disease, left hand',
  'M11049 Hydroxyapatite deposition disease, unspecified hand',
  'M11051 Hydroxyapatite deposition disease, right hip',
  'M11052 Hydroxyapatite deposition disease, left hip',
  'M11059 Hydroxyapatite deposition disease, unspecified hip',
  'M11061 Hydroxyapatite deposition disease, right knee',
  'M11062 Hydroxyapatite deposition disease, left knee',
  'M11069 Hydroxyapatite deposition disease, unspecified knee',
  'M11071 Hydroxyapatite deposition disease, right ankle and foot',
  'M11072 Hydroxyapatite deposition disease, left ankle and foot',
  'M11079 Hydroxyapatite deposition disease, unspecified ankle and foot',
  'M1108 Hydroxyapatite deposition disease, vertebrae',
  'M1109 Hydroxyapatite deposition disease, multiple sites',
  'M1110 Familial chondrocalcinosis, unspecified site',
  'M11111 Familial chondrocalcinosis, right shoulder',
  'M11112 Familial chondrocalcinosis, left shoulder',
  'M11119 Familial chondrocalcinosis, unspecified shoulder',
  'M11121 Familial chondrocalcinosis, right elbow',
  'M11122 Familial chondrocalcinosis, left elbow',
  'M11129 Familial chondrocalcinosis, unspecified elbow',
  'M11131 Familial chondrocalcinosis, right wrist',
  'M11132 Familial chondrocalcinosis, left wrist',
  'M11139 Familial chondrocalcinosis, unspecified wrist',
  'M11141 Familial chondrocalcinosis, right hand',
  'M11142 Familial chondrocalcinosis, left hand',
  'M11149 Familial chondrocalcinosis, unspecified hand',
  'M11151 Familial chondrocalcinosis, right hip',
  'M11152 Familial chondrocalcinosis, left hip',
  'M11159 Familial chondrocalcinosis, unspecified hip',
  'M11161 Familial chondrocalcinosis, right knee',
  'M11162 Familial chondrocalcinosis, left knee',
  'M11169 Familial chondrocalcinosis, unspecified knee',
  'M11171 Familial chondrocalcinosis, right ankle and foot',
  'M11172 Familial chondrocalcinosis, left ankle and foot',
  'M11179 Familial chondrocalcinosis, unspecified ankle and foot',
  'M1118 Familial chondrocalcinosis, vertebrae',
  'M1119 Familial chondrocalcinosis, multiple sites',
  'M1120 Other chondrocalcinosis, unspecified site',
  'M11211 Other chondrocalcinosis, right shoulder',
  'M11212 Other chondrocalcinosis, left shoulder',
  'M11219 Other chondrocalcinosis, unspecified shoulder',
  'M11221 Other chondrocalcinosis, right elbow',
  'M11222 Other chondrocalcinosis, left elbow',
  'M11229 Other chondrocalcinosis, unspecified elbow',
  'M11231 Other chondrocalcinosis, right wrist',
  'M11232 Other chondrocalcinosis, left wrist',
  'M11239 Other chondrocalcinosis, unspecified wrist',
  'M11241 Other chondrocalcinosis, right hand',
  'M11242 Other chondrocalcinosis, left hand',
  'M11249 Other chondrocalcinosis, unspecified hand',
  'M11251 Other chondrocalcinosis, right hip',
  'M11252 Other chondrocalcinosis, left hip',
  'M11259 Other chondrocalcinosis, unspecified hip',
  'M11261 Other chondrocalcinosis, right knee',
  'M11262 Other chondrocalcinosis, left knee',
  'M11269 Other chondrocalcinosis, unspecified knee',
  'M11271 Other chondrocalcinosis, right ankle and foot',
  'M11272 Other chondrocalcinosis, left ankle and foot',
  'M11279 Other chondrocalcinosis, unspecified ankle and foot',
  'M1128 Other chondrocalcinosis, vertebrae',
  'M1129 Other chondrocalcinosis, multiple sites',
  'M1180 Other specified crystal arthropathies, unspecified site',
  'M11811 Other specified crystal arthropathies, right shoulder',
  'M11812 Other specified crystal arthropathies, left shoulder',
  'M11819 Other specified crystal arthropathies, unspecified shoulder',
  'M11821 Other specified crystal arthropathies, right elbow',
  'M11822 Other specified crystal arthropathies, left elbow',
  'M11829 Other specified crystal arthropathies, unspecified elbow',
  'M11831 Other specified crystal arthropathies, right wrist',
  'M11832 Other specified crystal arthropathies, left wrist',
  'M11839 Other specified crystal arthropathies, unspecified wrist',
  'M11841 Other specified crystal arthropathies, right hand',
  'M11842 Other specified crystal arthropathies, left hand',
  'M11849 Other specified crystal arthropathies, unspecified hand',
  'M11851 Other specified crystal arthropathies, right hip',
  'M11852 Other specified crystal arthropathies, left hip',
  'M11859 Other specified crystal arthropathies, unspecified hip',
  'M11861 Other specified crystal arthropathies, right knee',
  'M11862 Other specified crystal arthropathies, left knee',
  'M11869 Other specified crystal arthropathies, unspecified knee',
  'M11871 Other specified crystal arthropathies, right ankle and foot',
  'M11872 Other specified crystal arthropathies, left ankle and foot',
  'M11879 Other specified crystal arthropathies, unspecified ankle and foot',
  'M1188 Other specified crystal arthropathies, vertebrae',
  'M1189 Other specified crystal arthropathies, multiple sites',
  'M119 Crystal arthropathy, unspecified',
  'M1200 Chronic postrheumatic arthropathy [Jaccoud], unspecified site',
  'M12011 Chronic postrheumatic arthropathy [Jaccoud], right shoulder',
  'M12012 Chronic postrheumatic arthropathy [Jaccoud], left shoulder',
  'M12019 Chronic postrheumatic arthropathy [Jaccoud], unspecified shoulder',
  'M12021 Chronic postrheumatic arthropathy [Jaccoud], right elbow',
  'M12022 Chronic postrheumatic arthropathy [Jaccoud], left elbow',
  'M12029 Chronic postrheumatic arthropathy [Jaccoud], unspecified elbow',
  'M12031 Chronic postrheumatic arthropathy [Jaccoud], right wrist',
  'M12032 Chronic postrheumatic arthropathy [Jaccoud], left wrist',
  'M12039 Chronic postrheumatic arthropathy [Jaccoud], unspecified wrist',
  'M12041 Chronic postrheumatic arthropathy [Jaccoud], right hand',
  'M12042 Chronic postrheumatic arthropathy [Jaccoud], left hand',
  'M12049 Chronic postrheumatic arthropathy [Jaccoud], unspecified hand',
  'M12051 Chronic postrheumatic arthropathy [Jaccoud], right hip',
  'M12052 Chronic postrheumatic arthropathy [Jaccoud], left hip',
  'M12059 Chronic postrheumatic arthropathy [Jaccoud], unspecified hip',
  'M12061 Chronic postrheumatic arthropathy [Jaccoud], right knee',
  'M12062 Chronic postrheumatic arthropathy [Jaccoud], left knee',
  'M12069 Chronic postrheumatic arthropathy [Jaccoud], unspecified knee',
  'M12071 Chronic postrheumatic arthropathy [Jaccoud], right ankle and foot',
  'M12072 Chronic postrheumatic arthropathy [Jaccoud], left ankle and foot',
  'M12079 Chronic postrheumatic arthropathy [Jaccoud], unspecified ankle and foot',
  'M1208 Chronic postrheumatic arthropathy [Jaccoud], other specified site',
  'M1209 Chronic postrheumatic arthropathy [Jaccoud], multiple sites',
  'M1210 Kaschin-Beck disease, unspecified site',
  'M12111 Kaschin-Beck disease, right shoulder',
  'M12112 Kaschin-Beck disease, left shoulder',
  'M12119 Kaschin-Beck disease, unspecified shoulder',
  'M12121 Kaschin-Beck disease, right elbow',
  'M12122 Kaschin-Beck disease, left elbow',
  'M12129 Kaschin-Beck disease, unspecified elbow',
  'M12131 Kaschin-Beck disease, right wrist',
  'M12132 Kaschin-Beck disease, left wrist',
  'M12139 Kaschin-Beck disease, unspecified wrist',
  'M12141 Kaschin-Beck disease, right hand',
  'M12142 Kaschin-Beck disease, left hand',
  'M12149 Kaschin-Beck disease, unspecified hand',
  'M12151 Kaschin-Beck disease, right hip',
  'M12152 Kaschin-Beck disease, left hip',
  'M12159 Kaschin-Beck disease, unspecified hip',
  'M12161 Kaschin-Beck disease, right knee',
  'M12162 Kaschin-Beck disease, left knee',
  'M12169 Kaschin-Beck disease, unspecified knee',
  'M12171 Kaschin-Beck disease, right ankle and foot',
  'M12172 Kaschin-Beck disease, left ankle and foot',
  'M12179 Kaschin-Beck disease, unspecified ankle and foot',
  'M1218 Kaschin-Beck disease, vertebrae',
  'M1219 Kaschin-Beck disease, multiple sites',
  'M1220 Villonodular synovitis (pigmented), unspecified site',
  'M12211 Villonodular synovitis (pigmented), right shoulder',
  'M12212 Villonodular synovitis (pigmented), left shoulder',
  'M12219 Villonodular synovitis (pigmented), unspecified shoulder',
  'M12221 Villonodular synovitis (pigmented), right elbow',
  'M12222 Villonodular synovitis (pigmented), left elbow',
  'M12229 Villonodular synovitis (pigmented), unspecified elbow',
  'M12231 Villonodular synovitis (pigmented), right wrist',
  'M12232 Villonodular synovitis (pigmented), left wrist',
  'M12239 Villonodular synovitis (pigmented), unspecified wrist',
  'M12241 Villonodular synovitis (pigmented), right hand',
  'M12242 Villonodular synovitis (pigmented), left hand',
  'M12249 Villonodular synovitis (pigmented), unspecified hand',
  'M12251 Villonodular synovitis (pigmented), right hip',
  'M12252 Villonodular synovitis (pigmented), left hip',
  'M12259 Villonodular synovitis (pigmented), unspecified hip',
  'M12261 Villonodular synovitis (pigmented), right knee',
  'M12262 Villonodular synovitis (pigmented), left knee',
  'M12269 Villonodular synovitis (pigmented), unspecified knee',
  'M12271 Villonodular synovitis (pigmented), right ankle and foot',
  'M12272 Villonodular synovitis (pigmented), left ankle and foot',
  'M12279 Villonodular synovitis (pigmented), unspecified ankle and foot',
  'M1228 Villonodular synovitis (pigmented), other specified site',
  'M1229 Villonodular synovitis (pigmented), multiple sites',
  'M1230 Palindromic rheumatism, unspecified site',
  'M12311 Palindromic rheumatism, right shoulder',
  'M12312 Palindromic rheumatism, left shoulder',
  'M12319 Palindromic rheumatism, unspecified shoulder',
  'M12321 Palindromic rheumatism, right elbow',
  'M12322 Palindromic rheumatism, left elbow',
  'M12329 Palindromic rheumatism, unspecified elbow',
  'M12331 Palindromic rheumatism, right wrist',
  'M12332 Palindromic rheumatism, left wrist',
  'M12339 Palindromic rheumatism, unspecified wrist',
  'M12341 Palindromic rheumatism, right hand',
  'M12342 Palindromic rheumatism, left hand',
  'M12349 Palindromic rheumatism, unspecified hand',
  'M12351 Palindromic rheumatism, right hip',
  'M12352 Palindromic rheumatism, left hip',
  'M12359 Palindromic rheumatism, unspecified hip',
  'M12361 Palindromic rheumatism, right knee',
  'M12362 Palindromic rheumatism, left knee',
  'M12369 Palindromic rheumatism, unspecified knee',
  'M12371 Palindromic rheumatism, right ankle and foot',
  'M12372 Palindromic rheumatism, left ankle and foot',
  'M12379 Palindromic rheumatism, unspecified ankle and foot',
  'M1238 Palindromic rheumatism, other specified site',
  'M1239 Palindromic rheumatism, multiple sites',
  'M1240 Intermittent hydrarthrosis, unspecified site',
  'M12411 Intermittent hydrarthrosis, right shoulder',
  'M12412 Intermittent hydrarthrosis, left shoulder',
  'M12419 Intermittent hydrarthrosis, unspecified shoulder',
  'M12421 Intermittent hydrarthrosis, right elbow',
  'M12422 Intermittent hydrarthrosis, left elbow',
  'M12429 Intermittent hydrarthrosis, unspecified elbow',
  'M12431 Intermittent hydrarthrosis, right wrist',
  'M12432 Intermittent hydrarthrosis, left wrist',
  'M12439 Intermittent hydrarthrosis, unspecified wrist',
  'M12441 Intermittent hydrarthrosis, right hand',
  'M12442 Intermittent hydrarthrosis, left hand',
  'M12449 Intermittent hydrarthrosis, unspecified hand',
  'M12451 Intermittent hydrarthrosis, right hip',
  'M12452 Intermittent hydrarthrosis, left hip',
  'M12459 Intermittent hydrarthrosis, unspecified hip',
  'M12461 Intermittent hydrarthrosis, right knee',
  'M12462 Intermittent hydrarthrosis, left knee',
  'M12469 Intermittent hydrarthrosis, unspecified knee',
  'M12471 Intermittent hydrarthrosis, right ankle and foot',
  'M12472 Intermittent hydrarthrosis, left ankle and foot',
  'M12479 Intermittent hydrarthrosis, unspecified ankle and foot',
  'M1248 Intermittent hydrarthrosis, other site',
  'M1249 Intermittent hydrarthrosis, multiple sites',
  'M1250 Traumatic arthropathy, unspecified site',
  'M12511 Traumatic arthropathy, right shoulder',
  'M12512 Traumatic arthropathy, left shoulder',
  'M12519 Traumatic arthropathy, unspecified shoulder',
  'M12521 Traumatic arthropathy, right elbow',
  'M12522 Traumatic arthropathy, left elbow',
  'M12529 Traumatic arthropathy, unspecified elbow',
  'M12531 Traumatic arthropathy, right wrist',
  'M12532 Traumatic arthropathy, left wrist',
  'M12539 Traumatic arthropathy, unspecified wrist',
  'M12541 Traumatic arthropathy, right hand',
  'M12542 Traumatic arthropathy, left hand',
  'M12549 Traumatic arthropathy, unspecified hand',
  'M12551 Traumatic arthropathy, right hip',
  'M12552 Traumatic arthropathy, left hip',
  'M12559 Traumatic arthropathy, unspecified hip',
  'M12561 Traumatic arthropathy, right knee',
  'M12562 Traumatic arthropathy, left knee',
  'M12569 Traumatic arthropathy, unspecified knee',
  'M12571 Traumatic arthropathy, right ankle and foot',
  'M12572 Traumatic arthropathy, left ankle and foot',
  'M12579 Traumatic arthropathy, unspecified ankle and foot',
  'M1258 Traumatic arthropathy, other specified site',
  'M1259 Traumatic arthropathy, multiple sites',
  'M1280 Other specific arthropathies, not elsewhere classified, unspecified site',
  'M12811 Other specific arthropathies, not elsewhere classified, right shoulder',
  'M12812 Other specific arthropathies, not elsewhere classified, left shoulder',
  'M12819 Other specific arthropathies, not elsewhere classified, unspecified shoulder',
  'M12821 Other specific arthropathies, not elsewhere classified, right elbow',
  'M12822 Other specific arthropathies, not elsewhere classified, left elbow',
  'M12829 Other specific arthropathies, not elsewhere classified, unspecified elbow',
  'M12831 Other specific arthropathies, not elsewhere classified, right wrist',
  'M12832 Other specific arthropathies, not elsewhere classified, left wrist',
  'M12839 Other specific arthropathies, not elsewhere classified, unspecified wrist',
  'M12841 Other specific arthropathies, not elsewhere classified, right hand',
  'M12842 Other specific arthropathies, not elsewhere classified, left hand',
  'M12849 Other specific arthropathies, not elsewhere classified, unspecified hand',
  'M12851 Other specific arthropathies, not elsewhere classified, right hip',
  'M12852 Other specific arthropathies, not elsewhere classified, left hip',
  'M12859 Other specific arthropathies, not elsewhere classified, unspecified hip',
  'M12861 Other specific arthropathies, not elsewhere classified, right knee',
  'M12862 Other specific arthropathies, not elsewhere classified, left knee',
  'M12869 Other specific arthropathies, not elsewhere classified, unspecified knee',
  'M12871 Other specific arthropathies, not elsewhere classified, right ankle and foot',
  'M12872 Other specific arthropathies, not elsewhere classified, left ankle and foot',
  'M12879 Other specific arthropathies, not elsewhere classified, unspecified ankle and foot',
  'M1288 Other specific arthropathies, not elsewhere classified, other specified site',
  'M1289 Other specific arthropathies, not elsewhere classified, multiple sites',
  'M129 Arthropathy, unspecified',
  'M130 Polyarthritis, unspecified',
  'M1310 Monoarthritis, not elsewhere classified, unspecified site',
  'M13111 Monoarthritis, not elsewhere classified, right shoulder',
  'M13112 Monoarthritis, not elsewhere classified, left shoulder',
  'M13119 Monoarthritis, not elsewhere classified, unspecified shoulder',
  'M13121 Monoarthritis, not elsewhere classified, right elbow',
  'M13122 Monoarthritis, not elsewhere classified, left elbow',
  'M13129 Monoarthritis, not elsewhere classified, unspecified elbow',
  'M13131 Monoarthritis, not elsewhere classified, right wrist',
  'M13132 Monoarthritis, not elsewhere classified, left wrist',
  'M13139 Monoarthritis, not elsewhere classified, unspecified wrist',
  'M13141 Monoarthritis, not elsewhere classified, right hand',
  'M13142 Monoarthritis, not elsewhere classified, left hand',
  'M13149 Monoarthritis, not elsewhere classified, unspecified hand',
  'M13151 Monoarthritis, not elsewhere classified, right hip',
  'M13152 Monoarthritis, not elsewhere classified, left hip',
  'M13159 Monoarthritis, not elsewhere classified, unspecified hip',
  'M13161 Monoarthritis, not elsewhere classified, right knee',
  'M13162 Monoarthritis, not elsewhere classified, left knee',
  'M13169 Monoarthritis, not elsewhere classified, unspecified knee',
  'M13171 Monoarthritis, not elsewhere classified, right ankle and foot',
  'M13172 Monoarthritis, not elsewhere classified, left ankle and foot',
  'M13179 Monoarthritis, not elsewhere classified, unspecified ankle and foot',
  'M1380 Other specified arthritis, unspecified site',
  'M13811 Other specified arthritis, right shoulder',
  'M13812 Other specified arthritis, left shoulder',
  'M13819 Other specified arthritis, unspecified shoulder',
  'M13821 Other specified arthritis, right elbow',
  'M13822 Other specified arthritis, left elbow',
  'M13829 Other specified arthritis, unspecified elbow',
  'M13831 Other specified arthritis, right wrist',
  'M13832 Other specified arthritis, left wrist',
  'M13839 Other specified arthritis, unspecified wrist',
  'M13841 Other specified arthritis, right hand',
  'M13842 Other specified arthritis, left hand',
  'M13849 Other specified arthritis, unspecified hand',
  'M13851 Other specified arthritis, right hip',
  'M13852 Other specified arthritis, left hip',
  'M13859 Other specified arthritis, unspecified hip',
  'M13861 Other specified arthritis, right knee',
  'M13862 Other specified arthritis, left knee',
  'M13869 Other specified arthritis, unspecified knee',
  'M13871 Other specified arthritis, right ankle and foot',
  'M13872 Other specified arthritis, left ankle and foot',
  'M13879 Other specified arthritis, unspecified ankle and foot',
  'M1388 Other specified arthritis, other site',
  'M1389 Other specified arthritis, multiple sites',
  "M1460 Charcot's joint, unspecified site",
  "M14611 Charcot's joint, right shoulder",
  "M14612 Charcot's joint, left shoulder",
  "M14619 Charcot's joint, unspecified shoulder",
  "M14621 Charcot's joint, right elbow",
  "M14622 Charcot's joint, left elbow",
  "M14629 Charcot's joint, unspecified elbow",
  "M14631 Charcot's joint, right wrist",
  "M14632 Charcot's joint, left wrist",
  "M14639 Charcot's joint, unspecified wrist",
  "M14641 Charcot's joint, right hand",
  "M14642 Charcot's joint, left hand",
  "M14649 Charcot's joint, unspecified hand",
  "M14651 Charcot's joint, right hip",
  "M14652 Charcot's joint, left hip",
  "M14659 Charcot's joint, unspecified hip",
  "M14661 Charcot's joint, right knee",
  "M14662 Charcot's joint, left knee",
  "M14669 Charcot's joint, unspecified knee",
  "M14671 Charcot's joint, right ankle and foot",
  "M14672 Charcot's joint, left ankle and foot",
  "M14679 Charcot's joint, unspecified ankle and foot",
  "M1468 Charcot's joint, vertebrae",
  "M1469 Charcot's joint, multiple sites",
  'M1480 Arthropathies in other specified diseases classified elsewhere, unspecified site',
  'M14811 Arthropathies in other specified diseases classified elsewhere, right shoulder',
  'M14812 Arthropathies in other specified diseases classified elsewhere, left shoulder',
  'M14819 Arthropathies in other specified diseases classified elsewhere, unspecified shoulder',
  'M14821 Arthropathies in other specified diseases classified elsewhere, right elbow',
  'M14822 Arthropathies in other specified diseases classified elsewhere, left elbow',
  'M14829 Arthropathies in other specified diseases classified elsewhere, unspecified elbow',
  'M14831 Arthropathies in other specified diseases classified elsewhere, right wrist',
  'M14832 Arthropathies in other specified diseases classified elsewhere, left wrist',
  'M14839 Arthropathies in other specified diseases classified elsewhere, unspecified wrist',
  'M14841 Arthropathies in other specified diseases classified elsewhere, right hand',
  'M14842 Arthropathies in other specified diseases classified elsewhere, left hand',
  'M14849 Arthropathies in other specified diseases classified elsewhere, unspecified hand',
  'M14851 Arthropathies in other specified diseases classified elsewhere, right hip',
  'M14852 Arthropathies in other specified diseases classified elsewhere, left hip',
  'M14859 Arthropathies in other specified diseases classified elsewhere, unspecified hip',
  'M14861 Arthropathies in other specified diseases classified elsewhere, right knee',
  'M14862 Arthropathies in other specified diseases classified elsewhere, left knee',
  'M14869 Arthropathies in other specified diseases classified elsewhere, unspecified knee',
  'M14871 Arthropathies in other specified diseases classified elsewhere, right ankle and foot',
  'M14872 Arthropathies in other specified diseases classified elsewhere, left ankle and foot',
  'M14879 Arthropathies in other specified diseases classified elsewhere, unspecified ankle and foot',
  'M1488 Arthropathies in other specified diseases classified elsewhere, vertebrae',
  'M1489 Arthropathies in other specified diseases classified elsewhere, multiple sites',
  'M150 Primary generalized (osteo)arthritis',
  "M151 Heberden's nodes (with arthropathy)",
  "M152 Bouchard's nodes (with arthropathy)",
  'M153 Secondary multiple arthritis',
  'M154 Erosive (osteo)arthritis',
  'M158 Other polyosteoarthritis',
  'M159 Polyosteoarthritis, unspecified',
  'M160 Bilateral primary osteoarthritis of hip',
  'M1610 Unilateral primary osteoarthritis, unspecified hip',
  'M1611 Unilateral primary osteoarthritis, right hip',
  'M1612 Unilateral primary osteoarthritis, left hip',
  'M162 Bilateral osteoarthritis resulting from hip dysplasia',
  'M1630 Unilateral osteoarthritis resulting from hip dysplasia, unspecified hip',
  'M1631 Unilateral osteoarthritis resulting from hip dysplasia, right hip',
  'M1632 Unilateral osteoarthritis resulting from hip dysplasia, left hip',
  'M164 Bilateral post-traumatic osteoarthritis of hip',
  'M1650 Unilateral post-traumatic osteoarthritis, unspecified hip',
  'M1651 Unilateral post-traumatic osteoarthritis, right hip',
  'M1652 Unilateral post-traumatic osteoarthritis, left hip',
  'M166 Other bilateral secondary osteoarthritis of hip',
  'M167 Other unilateral secondary osteoarthritis of hip',
  'M169 Osteoarthritis of hip, unspecified',
  'M170 Bilateral primary osteoarthritis of knee',
  'M1710 Unilateral primary osteoarthritis, unspecified knee',
  'M1711 Unilateral primary osteoarthritis, right knee',
  'M1712 Unilateral primary osteoarthritis, left knee',
  'M172 Bilateral post-traumatic osteoarthritis of knee',
  'M1730 Unilateral post-traumatic osteoarthritis, unspecified knee',
  'M1731 Unilateral post-traumatic osteoarthritis, right knee',
  'M1732 Unilateral post-traumatic osteoarthritis, left knee',
  'M174 Other bilateral secondary osteoarthritis of knee',
  'M175 Other unilateral secondary osteoarthritis of knee',
  'M179 Osteoarthritis of knee, unspecified',
  'M180 Bilateral primary osteoarthritis of first carpometacarpal joints',
  'M1810 Unilateral primary osteoarthritis of first carpometacarpal joint, unspecified hand',
  'M1811 Unilateral primary osteoarthritis of first carpometacarpal joint, right hand',
  'M1812 Unilateral primary osteoarthritis of first carpometacarpal joint, left hand',
  'M182 Bilateral post-traumatic osteoarthritis of first carpometacarpal joints',
  'M1830 Unilateral post-traumatic osteoarthritis of first carpometacarpal joint, unspecified hand',
  'M1831 Unilateral post-traumatic osteoarthritis of first carpometacarpal joint, right hand',
  'M1832 Unilateral post-traumatic osteoarthritis of first carpometacarpal joint, left hand',
  'M184 Other bilateral secondary osteoarthritis of first carpometacarpal joints',
  'M1850 Other unilateral secondary osteoarthritis of first carpometacarpal joint, unspecified hand',
  'M1851 Other unilateral secondary osteoarthritis of first carpometacarpal joint, right hand',
  'M1852 Other unilateral secondary osteoarthritis of first carpometacarpal joint, left hand',
  'M189 Osteoarthritis of first carpometacarpal joint, unspecified',
  'M19011 Primary osteoarthritis, right shoulder',
  'M19012 Primary osteoarthritis, left shoulder',
  'M19019 Primary osteoarthritis, unspecified shoulder',
  'M19021 Primary osteoarthritis, right elbow',
  'M19022 Primary osteoarthritis, left elbow',
  'M19029 Primary osteoarthritis, unspecified elbow',
  'M19031 Primary osteoarthritis, right wrist',
  'M19032 Primary osteoarthritis, left wrist',
  'M19039 Primary osteoarthritis, unspecified wrist',
  'M19041 Primary osteoarthritis, right hand',
  'M19042 Primary osteoarthritis, left hand',
  'M19049 Primary osteoarthritis, unspecified hand',
  'M19071 Primary osteoarthritis, right ankle and foot',
  'M19072 Primary osteoarthritis, left ankle and foot',
  'M19079 Primary osteoarthritis, unspecified ankle and foot',
  'M19111 Post-traumatic osteoarthritis, right shoulder',
  'M19112 Post-traumatic osteoarthritis, left shoulder',
  'M19119 Post-traumatic osteoarthritis, unspecified shoulder',
  'M19121 Post-traumatic osteoarthritis, right elbow',
  'M19122 Post-traumatic osteoarthritis, left elbow',
  'M19129 Post-traumatic osteoarthritis, unspecified elbow',
  'M19131 Post-traumatic osteoarthritis, right wrist',
  'M19132 Post-traumatic osteoarthritis, left wrist',
  'M19139 Post-traumatic osteoarthritis, unspecified wrist',
  'M19141 Post-traumatic osteoarthritis, right hand',
  'M19142 Post-traumatic osteoarthritis, left hand',
  'M19149 Post-traumatic osteoarthritis, unspecified hand',
  'M19171 Post-traumatic osteoarthritis, right ankle and foot',
  'M19172 Post-traumatic osteoarthritis, left ankle and foot',
  'M19179 Post-traumatic osteoarthritis, unspecified ankle and foot',
  'M19211 Secondary osteoarthritis, right shoulder',
  'M19212 Secondary osteoarthritis, left shoulder',
  'M19219 Secondary osteoarthritis, unspecified shoulder',
  'M19221 Secondary osteoarthritis, right elbow',
  'M19222 Secondary osteoarthritis, left elbow',
  'M19229 Secondary osteoarthritis, unspecified elbow',
  'M19231 Secondary osteoarthritis, right wrist',
  'M19232 Secondary osteoarthritis, left wrist',
  'M19239 Secondary osteoarthritis, unspecified wrist',
  'M19241 Secondary osteoarthritis, right hand',
  'M19242 Secondary osteoarthritis, left hand',
  'M19249 Secondary osteoarthritis, unspecified hand',
  'M19271 Secondary osteoarthritis, right ankle and foot',
  'M19272 Secondary osteoarthritis, left ankle and foot',
  'M19279 Secondary osteoarthritis, unspecified ankle and foot',
  'M1990 Unspecified osteoarthritis, unspecified site',
  'M1991 Primary osteoarthritis, unspecified site',
  'M1992 Post-traumatic osteoarthritis, unspecified site',
  'M1993 Secondary osteoarthritis, unspecified site',
  'M20001 Unspecified deformity of right finger(s)',
  'M20002 Unspecified deformity of left finger(s)',
  'M20009 Unspecified deformity of unspecified finger(s)',
  'M20011 Mallet finger of right finger(s)',
  'M20012 Mallet finger of left finger(s)',
  'M20019 Mallet finger of unspecified finger(s)',
  'M20021 Boutonniere deformity of right finger(s)',
  'M20022 Boutonniere deformity of left finger(s)',
  'M20029 Boutonniere deformity of unspecified finger(s)',
  'M20031 Swan-neck deformity of right finger(s)',
  'M20032 Swan-neck deformity of left finger(s)',
  'M20039 Swan-neck deformity of unspecified finger(s)',
  'M20091 Other deformity of right finger(s)',
  'M20092 Other deformity of left finger(s)',
  'M20099 Other deformity of finger(s), unspecified finger(s)',
  'M2010 Hallux valgus (acquired), unspecified foot',
  'M2011 Hallux valgus (acquired), right foot',
  'M2012 Hallux valgus (acquired), left foot',
  'M2020 Hallux rigidus, unspecified foot',
  'M2021 Hallux rigidus, right foot',
  'M2022 Hallux rigidus, left foot',
  'M2030 Hallux varus (acquired), unspecified foot',
  'M2031 Hallux varus (acquired), right foot',
  'M2032 Hallux varus (acquired), left foot',
  'M2040 Other hammer toe(s) (acquired), unspecified foot',
  'M2041 Other hammer toe(s) (acquired), right foot',
  'M2042 Other hammer toe(s) (acquired), left foot',
  'M205X1 Other deformities of toe(s) (acquired), right foot',
  'M205X2 Other deformities of toe(s) (acquired), left foot',
  'M205X9 Other deformities of toe(s) (acquired), unspecified foot',
  'M2060 Acquired deformities of toe(s), unspecified, unspecified foot',
  'M2061 Acquired deformities of toe(s), unspecified, right foot',
  'M2062 Acquired deformities of toe(s), unspecified, left foot',
  'M2100 Valgus deformity, not elsewhere classified, unspecified site',
  'M21021 Valgus deformity, not elsewhere classified, right elbow',
  'M21022 Valgus deformity, not elsewhere classified, left elbow',
  'M21029 Valgus deformity, not elsewhere classified, unspecified elbow',
  'M21051 Valgus deformity, not elsewhere classified, right hip',
  'M21052 Valgus deformity, not elsewhere classified, left hip',
  'M21059 Valgus deformity, not elsewhere classified, unspecified hip',
  'M21061 Valgus deformity, not elsewhere classified, right knee',
  'M21062 Valgus deformity, not elsewhere classified, left knee',
  'M21069 Valgus deformity, not elsewhere classified, unspecified knee',
  'M21071 Valgus deformity, not elsewhere classified, right ankle',
  'M21072 Valgus deformity, not elsewhere classified, left ankle',
  'M21079 Valgus deformity, not elsewhere classified, unspecified ankle',
  'M2110 Varus deformity, not elsewhere classified, unspecified site',
  'M21121 Varus deformity, not elsewhere classified, right elbow',
  'M21122 Varus deformity, not elsewhere classified, left elbow',
  'M21129 Varus deformity, not elsewhere classified, unspecified elbow',
  'M21151 Varus deformity, not elsewhere classified, right hip',
  'M21152 Varus deformity, not elsewhere classified, left hip',
  'M21159 Varus deformity, not elsewhere classified, unspecified',
  'M21161 Varus deformity, not elsewhere classified, right knee',
  'M21162 Varus deformity, not elsewhere classified, left knee',
  'M21169 Varus deformity, not elsewhere classified, unspecified knee',
  'M21171 Varus deformity, not elsewhere classified, right ankle',
  'M21172 Varus deformity, not elsewhere classified, left ankle',
  'M21179 Varus deformity, not elsewhere classified, unspecified ankle',
  'M2120 Flexion deformity, unspecified site',
  'M21211 Flexion deformity, right shoulder',
  'M21212 Flexion deformity, left shoulder',
  'M21219 Flexion deformity, unspecified shoulder',
  'M21221 Flexion deformity, right elbow',
  'M21222 Flexion deformity, left elbow',
  'M21229 Flexion deformity, unspecified elbow',
  'M21231 Flexion deformity, right wrist',
  'M21232 Flexion deformity, left wrist',
  'M21239 Flexion deformity, unspecified wrist',
  'M21241 Flexion deformity, right finger joints',
  'M21242 Flexion deformity, left finger joints',
  'M21249 Flexion deformity, unspecified finger joints',
  'M21251 Flexion deformity, right hip',
  'M21252 Flexion deformity, left hip',
  'M21259 Flexion deformity, unspecified hip',
  'M21261 Flexion deformity, right knee',
  'M21262 Flexion deformity, left knee',
  'M21269 Flexion deformity, unspecified knee',
  'M21271 Flexion deformity, right ankle and toes',
  'M21272 Flexion deformity, left ankle and toes',
  'M21279 Flexion deformity, unspecified ankle and toes',
  'M21331 Wrist drop, right wrist',
  'M21332 Wrist drop, left wrist',
  'M21339 Wrist drop, unspecified wrist',
  'M21371 Foot drop, right foot',
  'M21372 Foot drop, left foot',
  'M21379 Foot drop, unspecified foot',
  'M2140 Flat foot [pes planus] (acquired), unspecified foot',
  'M2141 Flat foot [pes planus] (acquired), right foot',
  'M2142 Flat foot [pes planus] (acquired), left foot',
  'M21511 Acquired clawhand, right hand',
  'M21512 Acquired clawhand, left hand',
  'M21519 Acquired clawhand, unspecified hand',
  'M21521 Acquired clubhand, right hand',
  'M21522 Acquired clubhand, left hand',
  'M21529 Acquired clubhand, unspecified hand',
  'M21531 Acquired clawfoot, right foot',
  'M21532 Acquired clawfoot, left foot',
  'M21539 Acquired clawfoot, unspecified foot',
  'M21541 Acquired clubfoot, right foot',
  'M21542 Acquired clubfoot, left foot',
  'M21549 Acquired clubfoot, unspecified foot',
  'M21611 Bunion of right foot',
  'M21612 Bunion of left foot',
  'M21619 Bunion of unspecified foot',
  'M21621 Bunionette of right foot',
  'M21622 Bunionette of left foot',
  'M21629 Bunionette of unspecified foot',
  'M216X1 Other acquired deformities of right foot',
  'M216X2 Other acquired deformities of left foot',
  'M216X9 Other acquired deformities of unspecified foot',
  'M2170 Unequal limb length (acquired), unspecified site',
  'M21721 Unequal limb length (acquired), right humerus',
  'M21722 Unequal limb length (acquired), left humerus',
  'M21729 Unequal limb length (acquired), unspecified humerus',
  'M21731 Unequal limb length (acquired), right ulna',
  'M21732 Unequal limb length (acquired), left ulna',
  'M21733 Unequal limb length (acquired), right radius',
  'M21734 Unequal limb length (acquired), left radius',
  'M21739 Unequal limb length (acquired), unspecified ulna and radius',
  'M21751 Unequal limb length (acquired), right femur',
  'M21752 Unequal limb length (acquired), left femur',
  'M21759 Unequal limb length (acquired), unspecified femur',
  'M21761 Unequal limb length (acquired), right tibia',
  'M21762 Unequal limb length (acquired), left tibia',
  'M21763 Unequal limb length (acquired), right fibula',
  'M21764 Unequal limb length (acquired), left fibula',
  'M21769 Unequal limb length (acquired), unspecified tibia and fibula',
  'M2180 Other specified acquired deformities of unspecified limb',
  'M21821 Other specified acquired deformities of right upper arm',
  'M21822 Other specified acquired deformities of left upper arm',
  'M21829 Other specified acquired deformities of unspecified upper arm',
  'M21831 Other specified acquired deformities of right forearm',
  'M21832 Other specified acquired deformities of left forearm',
  'M21839 Other specified acquired deformities of unspecified forearm',
  'M21851 Other specified acquired deformities of right thigh',
  'M21852 Other specified acquired deformities of left thigh',
  'M21859 Other specified acquired deformities of unspecified thigh',
  'M21861 Other specified acquired deformities of right lower leg',
  'M21862 Other specified acquired deformities of left lower leg',
  'M21869 Other specified acquired deformities of unspecified lower leg',
  'M2190 Unspecified acquired deformity of unspecified limb',
  'M21921 Unspecified acquired deformity of right upper arm',
  'M21922 Unspecified acquired deformity of left upper arm',
  'M21929 Unspecified acquired deformity of unspecified upper arm',
  'M21931 Unspecified acquired deformity of right forearm',
  'M21932 Unspecified acquired deformity of left forearm',
  'M21939 Unspecified acquired deformity of unspecified forearm',
  'M21941 Unspecified acquired deformity of hand, right hand',
  'M21942 Unspecified acquired deformity of hand, left hand',
  'M21949 Unspecified acquired deformity of hand, unspecified hand',
  'M21951 Unspecified acquired deformity of right thigh',
  'M21952 Unspecified acquired deformity of left thigh',
  'M21959 Unspecified acquired deformity of unspecified thigh',
  'M21961 Unspecified acquired deformity of right lower leg',
  'M21962 Unspecified acquired deformity of left lower leg',
  'M21969 Unspecified acquired deformity of unspecified lower leg',
  'M2200 Recurrent dislocation of patella, unspecified knee',
  'M2201 Recurrent dislocation of patella, right knee',
  'M2202 Recurrent dislocation of patella, left knee',
  'M2210 Recurrent subluxation of patella, unspecified knee',
  'M2211 Recurrent subluxation of patella, right knee',
  'M2212 Recurrent subluxation of patella, left knee',
  'M222X1 Patellofemoral disorders, right knee',
  'M222X2 Patellofemoral disorders, left knee',
  'M222X9 Patellofemoral disorders, unspecified knee',
  'M223X1 Other derangements of patella, right knee',
  'M223X2 Other derangements of patella, left knee',
  'M223X9 Other derangements of patella, unspecified knee',
  'M2240 Chondromalacia patellae, unspecified knee',
  'M2241 Chondromalacia patellae, right knee',
  'M2242 Chondromalacia patellae, left knee',
  'M228X1 Other disorders of patella, right knee',
  'M228X2 Other disorders of patella, left knee',
  'M228X9 Other disorders of patella, unspecified knee',
  'M2290 Unspecified disorder of patella, unspecified knee',
  'M2291 Unspecified disorder of patella, right knee',
  'M2292 Unspecified disorder of patella, left knee',
  'M23000 Cystic meniscus, unspecified lateral meniscus, right knee',
  'M23001 Cystic meniscus, unspecified lateral meniscus, left knee',
  'M23002 Cystic meniscus, unspecified lateral meniscus, unspecified knee',
  'M23003 Cystic meniscus, unspecified medial meniscus, right knee',
  'M23004 Cystic meniscus, unspecified medial meniscus, left knee',
  'M23005 Cystic meniscus, unspecified medial meniscus, unspecified knee',
  'M23006 Cystic meniscus, unspecified meniscus, right knee',
  'M23007 Cystic meniscus, unspecified meniscus, left knee',
  'M23009 Cystic meniscus, unspecified meniscus, unspecified knee',
  'M23011 Cystic meniscus, anterior horn of medial meniscus, right knee',
  'M23012 Cystic meniscus, anterior horn of medial meniscus, left knee',
  'M23019 Cystic meniscus, anterior horn of medial meniscus, unspecified knee',
  'M23021 Cystic meniscus, posterior horn of medial meniscus, right knee',
  'M23022 Cystic meniscus, posterior horn of medial meniscus, left knee',
  'M23029 Cystic meniscus, posterior horn of medial meniscus, unspecified knee',
  'M23031 Cystic meniscus, other medial meniscus, right knee',
  'M23032 Cystic meniscus, other medial meniscus, left knee',
  'M23039 Cystic meniscus, other medial meniscus, unspecified knee',
  'M23041 Cystic meniscus, anterior horn of lateral meniscus, right knee',
  'M23042 Cystic meniscus, anterior horn of lateral meniscus, left knee',
  'M23049 Cystic meniscus, anterior horn of lateral meniscus, unspecified knee',
  'M23051 Cystic meniscus, posterior horn of lateral meniscus, right knee',
  'M23052 Cystic meniscus, posterior horn of lateral meniscus, left knee',
  'M23059 Cystic meniscus, posterior horn of lateral meniscus, unspecified knee',
  'M23061 Cystic meniscus, other lateral meniscus, right knee',
  'M23062 Cystic meniscus, other lateral meniscus, left knee',
  'M23069 Cystic meniscus, other lateral meniscus, unspecified knee',
  'M23200 Derangement of unspecified lateral meniscus due to old tear or injury, right knee',
  'M23201 Derangement of unspecified lateral meniscus due to old tear or injury, left knee',
  'M23202 Derangement of unspecified lateral meniscus due to old tear or injury, unspecified knee',
  'M23203 Derangement of unspecified medial meniscus due to old tear or injury, right knee',
  'M23204 Derangement of unspecified medial meniscus due to old tear or injury, left knee',
  'M23205 Derangement of unspecified medial meniscus due to old tear or injury, unspecified knee',
  'M23206 Derangement of unspecified meniscus due to old tear or injury, right knee',
  'M23207 Derangement of unspecified meniscus due to old tear or injury, left knee',
  'M23209 Derangement of unspecified meniscus due to old tear or injury, unspecified knee',
  'M23211 Derangement of anterior horn of medial meniscus due to old tear or injury, right knee',
  'M23212 Derangement of anterior horn of medial meniscus due to old tear or injury, left knee',
  'M23219 Derangement of anterior horn of medial meniscus due to old tear or injury, unspecified knee',
  'M23221 Derangement of posterior horn of medial meniscus due to old tear or injury, right knee',
  'M23222 Derangement of posterior horn of medial meniscus due to old tear or injury, left knee',
  'M23229 Derangement of posterior horn of medial meniscus due to old tear or injury, unspecified knee',
  'M23231 Derangement of other medial meniscus due to old tear or injury, right knee',
  'M23232 Derangement of other medial meniscus due to old tear or injury, left knee',
  'M23239 Derangement of other medial meniscus due to old tear or injury, unspecified knee',
  'M23241 Derangement of anterior horn of lateral meniscus due to old tear or injury, right knee',
  'M23242 Derangement of anterior horn of lateral meniscus due to old tear or injury, left knee',
  'M23249 Derangement of anterior horn of lateral meniscus due to old tear or injury, unspecified knee',
  'M23251 Derangement of posterior horn of lateral meniscus due to old tear or injury, right knee',
  'M23252 Derangement of posterior horn of lateral meniscus due to old tear or injury, left knee',
  'M23259 Derangement of posterior horn of lateral meniscus due to old tear or injury, unspecified knee',
  'M23261 Derangement of other lateral meniscus due to old tear or injury, right knee',
  'M23262 Derangement of other lateral meniscus due to old tear or injury, left knee',
  'M23269 Derangement of other lateral meniscus due to old tear or injury, unspecified knee',
  'M23300 Other meniscus derangements, unspecified lateral meniscus, right knee',
  'M23301 Other meniscus derangements, unspecified lateral meniscus, left knee',
  'M23302 Other meniscus derangements, unspecified lateral meniscus, unspecified knee',
  'M23303 Other meniscus derangements, unspecified medial meniscus, right knee',
  'M23304 Other meniscus derangements, unspecified medial meniscus, left knee',
  'M23305 Other meniscus derangements, unspecified medial meniscus, unspecified knee',
  'M23306 Other meniscus derangements, unspecified meniscus, right knee',
  'M23307 Other meniscus derangements, unspecified meniscus, left knee',
  'M23309 Other meniscus derangements, unspecified meniscus, unspecified knee',
  'M23311 Other meniscus derangements, anterior horn of medial meniscus, right knee',
  'M23312 Other meniscus derangements, anterior horn of medial meniscus, left knee',
  'M23319 Other meniscus derangements, anterior horn of medial meniscus, unspecified knee',
  'M23321 Other meniscus derangements, posterior horn of medial meniscus, right knee',
  'M23322 Other meniscus derangements, posterior horn of medial meniscus, left knee',
  'M23329 Other meniscus derangements, posterior horn of medial meniscus, unspecified knee',
  'M23331 Other meniscus derangements, other medial meniscus, right knee',
  'M23332 Other meniscus derangements, other medial meniscus, left knee',
  'M23339 Other meniscus derangements, other medial meniscus, unspecified knee',
  'M23341 Other meniscus derangements, anterior horn of lateral meniscus, right knee',
  'M23342 Other meniscus derangements, anterior horn of lateral meniscus, left knee',
  'M23349 Other meniscus derangements, anterior horn of lateral meniscus, unspecified knee',
  'M23351 Other meniscus derangements, posterior horn of lateral meniscus, right knee',
  'M23352 Other meniscus derangements, posterior horn of lateral meniscus, left knee',
  'M23359 Other meniscus derangements, posterior horn of lateral meniscus, unspecified knee',
  'M23361 Other meniscus derangements, other lateral meniscus, right knee',
  'M23362 Other meniscus derangements, other lateral meniscus, left knee',
  'M23369 Other meniscus derangements, other lateral meniscus, unspecified knee',
  'M2340 Loose body in knee, unspecified knee',
  'M2341 Loose body in knee, right knee',
  'M2342 Loose body in knee, left knee',
  'M2350 Chronic instability of knee, unspecified knee',
  'M2351 Chronic instability of knee, right knee',
  'M2352 Chronic instability of knee, left knee',
  'M23601 Other spontaneous disruption of unspecified ligament of right knee',
  'M23602 Other spontaneous disruption of unspecified ligament of left knee',
  'M23609 Other spontaneous disruption of unspecified ligament of unspecified knee',
  'M23611 Other spontaneous disruption of anterior cruciate ligament of right knee',
  'M23612 Other spontaneous disruption of anterior cruciate ligament of left knee',
  'M23619 Other spontaneous disruption of anterior cruciate ligament of unspecified knee',
  'M23621 Other spontaneous disruption of posterior cruciate ligament of right knee',
  'M23622 Other spontaneous disruption of posterior cruciate ligament of left knee',
  'M23629 Other spontaneous disruption of posterior cruciate ligament of unspecified knee',
  'M23631 Other spontaneous disruption of medial collateral ligament of right knee',
  'M23632 Other spontaneous disruption of medial collateral ligament of left knee',
  'M23639 Other spontaneous disruption of medial collateral ligament of unspecified knee',
  'M23641 Other spontaneous disruption of lateral collateral ligament of right knee',
  'M23642 Other spontaneous disruption of lateral collateral ligament of left knee',
  'M23649 Other spontaneous disruption of lateral collateral ligament of unspecified knee',
  'M23671 Other spontaneous disruption of capsular ligament of right knee',
  'M23672 Other spontaneous disruption of capsular ligament of left knee',
  'M23679 Other spontaneous disruption of capsular ligament of unspecified knee',
  'M238X1 Other internal derangements of right knee',
  'M238X2 Other internal derangements of left knee',
  'M238X9 Other internal derangements of unspecified knee',
  'M2390 Unspecified internal derangement of unspecified knee',
  'M2391 Unspecified internal derangement of right knee',
  'M2392 Unspecified internal derangement of left knee',
  'M2400 Loose body in unspecified joint',
  'M24011 Loose body in right shoulder',
  'M24012 Loose body in left shoulder',
  'M24019 Loose body in unspecified shoulder',
  'M24021 Loose body in right elbow',
  'M24022 Loose body in left elbow',
  'M24029 Loose body in unspecified elbow',
  'M24031 Loose body in right wrist',
  'M24032 Loose body in left wrist',
  'M24039 Loose body in unspecified wrist',
  'M24041 Loose body in right finger joint(s)',
  'M24042 Loose body in left finger joint(s)',
  'M24049 Loose body in unspecified finger joint(s)',
  'M24051 Loose body in right hip',
  'M24052 Loose body in left hip',
  'M24059 Loose body in unspecified hip',
  'M24071 Loose body in right ankle',
  'M24072 Loose body in left ankle',
  'M24073 Loose body in unspecified ankle',
  'M24074 Loose body in right toe joint(s)',
  'M24075 Loose body in left toe joint(s)',
  'M24076 Loose body in unspecified toe joints',
  'M2408 Loose body, other site',
  'M2410 Other articular cartilage disorders, unspecified site',
  'M24111 Other articular cartilage disorders, right shoulder',
  'M24112 Other articular cartilage disorders, left shoulder',
  'M24119 Other articular cartilage disorders, unspecified shoulder',
  'M24121 Other articular cartilage disorders, right elbow',
  'M24122 Other articular cartilage disorders, left elbow',
  'M24129 Other articular cartilage disorders, unspecified elbow',
  'M24131 Other articular cartilage disorders, right wrist',
  'M24132 Other articular cartilage disorders, left wrist',
  'M24139 Other articular cartilage disorders, unspecified wrist',
  'M24141 Other articular cartilage disorders, right hand',
  'M24142 Other articular cartilage disorders, left hand',
  'M24149 Other articular cartilage disorders, unspecified hand',
  'M24151 Other articular cartilage disorders, right hip',
  'M24152 Other articular cartilage disorders, left hip',
  'M24159 Other articular cartilage disorders, unspecified hip',
  'M24171 Other articular cartilage disorders, right ankle',
  'M24172 Other articular cartilage disorders, left ankle',
  'M24173 Other articular cartilage disorders, unspecified ankle',
  'M24174 Other articular cartilage disorders, right foot',
  'M24175 Other articular cartilage disorders, left foot',
  'M24176 Other articular cartilage disorders, unspecified foot',
  'M2420 Disorder of ligament, unspecified site',
  'M24211 Disorder of ligament, right shoulder',
  'M24212 Disorder of ligament, left shoulder',
  'M24219 Disorder of ligament, unspecified shoulder',
  'M24221 Disorder of ligament, right elbow',
  'M24222 Disorder of ligament, left elbow',
  'M24229 Disorder of ligament, unspecified elbow',
  'M24231 Disorder of ligament, right wrist',
  'M24232 Disorder of ligament, left wrist',
  'M24239 Disorder of ligament, unspecified wrist',
  'M24241 Disorder of ligament, right hand',
  'M24242 Disorder of ligament, left hand',
  'M24249 Disorder of ligament, unspecified hand',
  'M24251 Disorder of ligament, right hip',
  'M24252 Disorder of ligament, left hip',
  'M24259 Disorder of ligament, unspecified hip',
  'M24271 Disorder of ligament, right ankle',
  'M24272 Disorder of ligament, left ankle',
  'M24273 Disorder of ligament, unspecified ankle',
  'M24274 Disorder of ligament, right foot',
  'M24275 Disorder of ligament, left foot',
  'M24276 Disorder of ligament, unspecified foot',
  'M2428 Disorder of ligament, vertebrae',
  'M2430 Pathological dislocation of unspecified joint, not elsewhere classified',
  'M24311 Pathological dislocation of right shoulder, not elsewhere classified',
  'M24312 Pathological dislocation of left shoulder, not elsewhere classified',
  'M24319 Pathological dislocation of unspecified shoulder, not elsewhere classified',
  'M24321 Pathological dislocation of right elbow, not elsewhere classified',
  'M24322 Pathological dislocation of left elbow, not elsewhere classified',
  'M24329 Pathological dislocation of unspecified elbow, not elsewhere classified',
  'M24331 Pathological dislocation of right wrist, not elsewhere classified',
  'M24332 Pathological dislocation of left wrist, not elsewhere classified',
  'M24339 Pathological dislocation of unspecified wrist, not elsewhere classified',
  'M24341 Pathological dislocation of right hand, not elsewhere classified',
  'M24342 Pathological dislocation of left hand, not elsewhere classified',
  'M24349 Pathological dislocation of unspecified hand, not elsewhere classified',
  'M24351 Pathological dislocation of right hip, not elsewhere classified',
  'M24352 Pathological dislocation of left hip, not elsewhere classified',
  'M24359 Pathological dislocation of unspecified hip, not elsewhere classified',
  'M24361 Pathological dislocation of right knee, not elsewhere classified',
  'M24362 Pathological dislocation of left knee, not elsewhere classified',
  'M24369 Pathological dislocation of unspecified knee, not elsewhere classified',
  'M24371 Pathological dislocation of right ankle, not elsewhere classified',
  'M24372 Pathological dislocation of left ankle, not elsewhere classified',
  'M24373 Pathological dislocation of unspecified ankle, not elsewhere classified',
  'M24374 Pathological dislocation of right foot, not elsewhere classified',
  'M24375 Pathological dislocation of left foot, not elsewhere classified',
  'M24376 Pathological dislocation of unspecified foot, not elsewhere classified',
  'M2440 Recurrent dislocation, unspecified joint',
  'M24411 Recurrent dislocation, right shoulder',
  'M24412 Recurrent dislocation, left shoulder',
  'M24419 Recurrent dislocation, unspecified shoulder',
  'M24421 Recurrent dislocation, right elbow',
  'M24422 Recurrent dislocation, left elbow',
  'M24429 Recurrent dislocation, unspecified elbow',
  'M24431 Recurrent dislocation, right wrist',
  'M24432 Recurrent dislocation, left wrist',
  'M24439 Recurrent dislocation, unspecified wrist',
  'M24441 Recurrent dislocation, right hand',
  'M24442 Recurrent dislocation, left hand',
  'M24443 Recurrent dislocation, unspecified hand',
  'M24444 Recurrent dislocation, right finger',
  'M24445 Recurrent dislocation, left finger',
  'M24446 Recurrent dislocation, unspecified finger',
  'M24451 Recurrent dislocation, right hip',
  'M24452 Recurrent dislocation, left hip',
  'M24459 Recurrent dislocation, unspecified hip',
  'M24461 Recurrent dislocation, right knee',
  'M24462 Recurrent dislocation, left knee',
  'M24469 Recurrent dislocation, unspecified knee',
  'M24471 Recurrent dislocation, right ankle',
  'M24472 Recurrent dislocation, left ankle',
  'M24473 Recurrent dislocation, unspecified ankle',
  'M24474 Recurrent dislocation, right foot',
  'M24475 Recurrent dislocation, left foot',
  'M24476 Recurrent dislocation, unspecified foot',
  'M24477 Recurrent dislocation, right toe(s)',
  'M24478 Recurrent dislocation, left toe(s)',
  'M24479 Recurrent dislocation, unspecified toe(s)',
  'M2450 Contracture, unspecified joint',
  'M24511 Contracture, right shoulder',
  'M24512 Contracture, left shoulder',
  'M24519 Contracture, unspecified shoulder',
  'M24521 Contracture, right elbow',
  'M24522 Contracture, left elbow',
  'M24529 Contracture, unspecified elbow',
  'M24531 Contracture, right wrist',
  'M24532 Contracture, left wrist',
  'M24539 Contracture, unspecified wrist',
  'M24541 Contracture, right hand',
  'M24542 Contracture, left hand',
  'M24549 Contracture, unspecified hand',
  'M24551 Contracture, right hip',
  'M24552 Contracture, left hip',
  'M24559 Contracture, unspecified hip',
  'M24561 Contracture, right knee',
  'M24562 Contracture, left knee',
  'M24569 Contracture, unspecified knee',
  'M24571 Contracture, right ankle',
  'M24572 Contracture, left ankle',
  'M24573 Contracture, unspecified ankle',
  'M24574 Contracture, right foot',
  'M24575 Contracture, left foot',
  'M24576 Contracture, unspecified foot',
  'M2460 Ankylosis, unspecified joint',
  'M24611 Ankylosis, right shoulder',
  'M24612 Ankylosis, left shoulder',
  'M24619 Ankylosis, unspecified shoulder',
  'M24621 Ankylosis, right elbow',
  'M24622 Ankylosis, left elbow',
  'M24629 Ankylosis, unspecified elbow',
  'M24631 Ankylosis, right wrist',
  'M24632 Ankylosis, left wrist',
  'M24639 Ankylosis, unspecified wrist',
  'M24641 Ankylosis, right hand',
  'M24642 Ankylosis, left hand',
  'M24649 Ankylosis, unspecified hand',
  'M24651 Ankylosis, right hip',
  'M24652 Ankylosis, left hip',
  'M24659 Ankylosis, unspecified hip',
  'M24661 Ankylosis, right knee',
  'M24662 Ankylosis, left knee',
  'M24669 Ankylosis, unspecified knee',
  'M24671 Ankylosis, right ankle',
  'M24672 Ankylosis, left ankle',
  'M24673 Ankylosis, unspecified ankle',
  'M24674 Ankylosis, right foot',
  'M24675 Ankylosis, left foot',
  'M24676 Ankylosis, unspecified foot',
  'M247 Protrusio acetabuli',
  'M2480 Other specific joint derangements of unspecified joint, not elsewhere classified',
  'M24811 Other specific joint derangements of right shoulder, not elsewhere classified',
  'M24812 Other specific joint derangements of left shoulder, not elsewhere classified',
  'M24819 Other specific joint derangements of unspecified shoulder, not elsewhere classified',
  'M24821 Other specific joint derangements of right elbow, not elsewhere classified',
  'M24822 Other specific joint derangements of left elbow, not elsewhere classified',
  'M24829 Other specific joint derangements of unspecified elbow, not elsewhere classified',
  'M24831 Other specific joint derangements of right wrist, not elsewhere classified',
  'M24832 Other specific joint derangements of left wrist, not elsewhere classified',
  'M24839 Other specific joint derangements of unspecified wrist, not elsewhere classified',
  'M24841 Other specific joint derangements of right hand, not elsewhere classified',
  'M24842 Other specific joint derangements of left hand, not elsewhere classified',
  'M24849 Other specific joint derangements of unspecified hand, not elsewhere classified',
  'M24851 Other specific joint derangements of right hip, not elsewhere classified',
  'M24852 Other specific joint derangements of left hip, not elsewhere classified',
  'M24859 Other specific joint derangements of unspecified hip, not elsewhere classified',
  'M24871 Other specific joint derangements of right ankle, not elsewhere classified',
  'M24872 Other specific joint derangements of left ankle, not elsewhere classified',
  'M24873 Other specific joint derangements of unspecified ankle, not elsewhere classified',
  'M24874 Other specific joint derangements of right foot, not elsewhere classified',
  'M24875 Other specific joint derangements left foot, not elsewhere classified',
  'M24876 Other specific joint derangements of unspecified foot, not elsewhere classified',
  'M249 Joint derangement, unspecified',
  'M2500 Hemarthrosis, unspecified joint',
  'M25011 Hemarthrosis, right shoulder',
  'M25012 Hemarthrosis, left shoulder',
  'M25019 Hemarthrosis, unspecified shoulder',
  'M25021 Hemarthrosis, right elbow',
  'M25022 Hemarthrosis, left elbow',
  'M25029 Hemarthrosis, unspecified elbow',
  'M25031 Hemarthrosis, right wrist',
  'M25032 Hemarthrosis, left wrist',
  'M25039 Hemarthrosis, unspecified wrist',
  'M25041 Hemarthrosis, right hand',
  'M25042 Hemarthrosis, left hand',
  'M25049 Hemarthrosis, unspecified hand',
  'M25051 Hemarthrosis, right hip',
  'M25052 Hemarthrosis, left hip',
  'M25059 Hemarthrosis, unspecified hip',
  'M25061 Hemarthrosis, right knee',
  'M25062 Hemarthrosis, left knee',
  'M25069 Hemarthrosis, unspecified knee',
  'M25071 Hemarthrosis, right ankle',
  'M25072 Hemarthrosis, left ankle',
  'M25073 Hemarthrosis, unspecified ankle',
  'M25074 Hemarthrosis, right foot',
  'M25075 Hemarthrosis, left foot',
  'M25076 Hemarthrosis, unspecified foot',
  'M2508 Hemarthrosis, other specified site',
  'M2510 Fistula, unspecified joint',
  'M25111 Fistula, right shoulder',
  'M25112 Fistula, left shoulder',
  'M25119 Fistula, unspecified shoulder',
  'M25121 Fistula, right elbow',
  'M25122 Fistula, left elbow',
  'M25129 Fistula, unspecified elbow',
  'M25131 Fistula, right wrist',
  'M25132 Fistula, left wrist',
  'M25139 Fistula, unspecified wrist',
  'M25141 Fistula, right hand',
  'M25142 Fistula, left hand',
  'M25149 Fistula, unspecified hand',
  'M25151 Fistula, right hip',
  'M25152 Fistula, left hip',
  'M25159 Fistula, unspecified hip',
  'M25161 Fistula, right knee',
  'M25162 Fistula, left knee',
  'M25169 Fistula, unspecified knee',
  'M25171 Fistula, right ankle',
  'M25172 Fistula, left ankle',
  'M25173 Fistula, unspecified ankle',
  'M25174 Fistula, right foot',
  'M25175 Fistula, left foot',
  'M25176 Fistula, unspecified foot',
  'M2518 Fistula, other specified site',
  'M2520 Flail joint, unspecified joint',
  'M25211 Flail joint, right shoulder',
  'M25212 Flail joint, left shoulder',
  'M25219 Flail joint, unspecified shoulder',
  'M25221 Flail joint, right elbow',
  'M25222 Flail joint, left elbow',
  'M25229 Flail joint, unspecified elbow',
  'M25231 Flail joint, right wrist',
  'M25232 Flail joint, left wrist',
  'M25239 Flail joint, unspecified wrist',
  'M25241 Flail joint, right hand',
  'M25242 Flail joint, left hand',
  'M25249 Flail joint, unspecified hand',
  'M25251 Flail joint, right hip',
  'M25252 Flail joint, left hip',
  'M25259 Flail joint, unspecified hip',
  'M25261 Flail joint, right knee',
  'M25262 Flail joint, left knee',
  'M25269 Flail joint, unspecified knee',
  'M25271 Flail joint, right ankle and foot',
  'M25272 Flail joint, left ankle and foot',
  'M25279 Flail joint, unspecified ankle and foot',
  'M2528 Flail joint, other site',
  'M2530 Other instability, unspecified joint',
  'M25311 Other instability, right shoulder',
  'M25312 Other instability, left shoulder',
  'M25319 Other instability, unspecified shoulder',
  'M25321 Other instability, right elbow',
  'M25322 Other instability, left elbow',
  'M25329 Other instability, unspecified elbow',
  'M25331 Other instability, right wrist',
  'M25332 Other instability, left wrist',
  'M25339 Other instability, unspecified wrist',
  'M25341 Other instability, right hand',
  'M25342 Other instability, left hand',
  'M25349 Other instability, unspecified hand',
  'M25351 Other instability, right hip',
  'M25352 Other instability, left hip',
  'M25359 Other instability, unspecified hip',
  'M25361 Other instability, right knee',
  'M25362 Other instability, left knee',
  'M25369 Other instability, unspecified knee',
  'M25371 Other instability, right ankle',
  'M25372 Other instability, left ankle',
  'M25373 Other instability, unspecified ankle',
  'M25374 Other instability, right foot',
  'M25375 Other instability, left foot',
  'M25376 Other instability, unspecified foot',
  'M2540 Effusion, unspecified joint',
  'M25411 Effusion, right shoulder',
  'M25412 Effusion, left shoulder',
  'M25419 Effusion, unspecified shoulder',
  'M25421 Effusion, right elbow',
  'M25422 Effusion, left elbow',
  'M25429 Effusion, unspecified elbow',
  'M25431 Effusion, right wrist',
  'M25432 Effusion, left wrist',
  'M25439 Effusion, unspecified wrist',
  'M25441 Effusion, right hand',
  'M25442 Effusion, left hand',
  'M25449 Effusion, unspecified hand',
  'M25451 Effusion, right hip',
  'M25452 Effusion, left hip',
  'M25459 Effusion, unspecified hip',
  'M25461 Effusion, right knee',
  'M25462 Effusion, left knee',
  'M25469 Effusion, unspecified knee',
  'M25471 Effusion, right ankle',
  'M25472 Effusion, left ankle',
  'M25473 Effusion, unspecified ankle',
  'M25474 Effusion, right foot',
  'M25475 Effusion, left foot',
  'M25476 Effusion, unspecified foot',
  'M2548 Effusion, other site',
  'M2550 Pain in unspecified joint',
  'M25511 Pain in right shoulder',
  'M25512 Pain in left shoulder',
  'M25519 Pain in unspecified shoulder',
  'M25521 Pain in right elbow',
  'M25522 Pain in left elbow',
  'M25529 Pain in unspecified elbow',
  'M25531 Pain in right wrist',
  'M25532 Pain in left wrist',
  'M25539 Pain in unspecified wrist',
  'M25541 Pain in joints of right hand',
  'M25542 Pain in joints of left hand',
  'M25549 Pain in joints of unspecified hand',
  'M25551 Pain in right hip',
  'M25552 Pain in left hip',
  'M25559 Pain in unspecified hip',
  'M25561 Pain in right knee',
  'M25562 Pain in left knee',
  'M25569 Pain in unspecified knee',
  'M25571 Pain in right ankle and joints of right foot',
  'M25572 Pain in left ankle and joints of left foot',
  'M25579 Pain in unspecified ankle and joints of unspecified foot',
  'M2560 Stiffness of unspecified joint, not elsewhere classified',
  'M25611 Stiffness of right shoulder, not elsewhere classified',
  'M25612 Stiffness of left shoulder, not elsewhere classified',
  'M25619 Stiffness of unspecified shoulder, not elsewhere classified',
  'M25621 Stiffness of right elbow, not elsewhere classified',
  'M25622 Stiffness of left elbow, not elsewhere classified',
  'M25629 Stiffness of unspecified elbow, not elsewhere classified',
  'M25631 Stiffness of right wrist, not elsewhere classified',
  'M25632 Stiffness of left wrist, not elsewhere classified',
  'M25639 Stiffness of unspecified wrist, not elsewhere classified',
  'M25641 Stiffness of right hand, not elsewhere classified',
  'M25642 Stiffness of left hand, not elsewhere classified',
  'M25649 Stiffness of unspecified hand, not elsewhere classified',
  'M25651 Stiffness of right hip, not elsewhere classified',
  'M25652 Stiffness of left hip, not elsewhere classified',
  'M25659 Stiffness of unspecified hip, not elsewhere classified',
  'M25661 Stiffness of right knee, not elsewhere classified',
  'M25662 Stiffness of left knee, not elsewhere classified',
  'M25669 Stiffness of unspecified knee, not elsewhere classified',
  'M25671 Stiffness of right ankle, not elsewhere classified',
  'M25672 Stiffness of left ankle, not elsewhere classified',
  'M25673 Stiffness of unspecified ankle, not elsewhere classified',
  'M25674 Stiffness of right foot, not elsewhere classified',
  'M25675 Stiffness of left foot, not elsewhere classified',
  'M25676 Stiffness of unspecified foot, not elsewhere classified',
  'M2570 Osteophyte, unspecified joint',
  'M25711 Osteophyte, right shoulder',
  'M25712 Osteophyte, left shoulder',
  'M25719 Osteophyte, unspecified shoulder',
  'M25721 Osteophyte, right elbow',
  'M25722 Osteophyte, left elbow',
  'M25729 Osteophyte, unspecified elbow',
  'M25731 Osteophyte, right wrist',
  'M25732 Osteophyte, left wrist',
  'M25739 Osteophyte, unspecified wrist',
  'M25741 Osteophyte, right hand',
  'M25742 Osteophyte, left hand',
  'M25749 Osteophyte, unspecified hand',
  'M25751 Osteophyte, right hip',
  'M25752 Osteophyte, left hip',
  'M25759 Osteophyte, unspecified hip',
  'M25761 Osteophyte, right knee',
  'M25762 Osteophyte, left knee',
  'M25769 Osteophyte, unspecified knee',
  'M25771 Osteophyte, right ankle',
  'M25772 Osteophyte, left ankle',
  'M25773 Osteophyte, unspecified ankle',
  'M25774 Osteophyte, right foot',
  'M25775 Osteophyte, left foot',
  'M25776 Osteophyte, unspecified foot',
  'M2578 Osteophyte, vertebrae',
  'M2580 Other specified joint disorders, unspecified joint',
  'M25811 Other specified joint disorders, right shoulder',
  'M25812 Other specified joint disorders, left shoulder',
  'M25819 Other specified joint disorders, unspecified shoulder',
  'M25821 Other specified joint disorders, right elbow',
  'M25822 Other specified joint disorders, left elbow',
  'M25829 Other specified joint disorders, unspecified elbow',
  'M25831 Other specified joint disorders, right wrist',
  'M25832 Other specified joint disorders, left wrist',
  'M25839 Other specified joint disorders, unspecified wrist',
  'M25841 Other specified joint disorders, right hand',
  'M25842 Other specified joint disorders, left hand',
  'M25849 Other specified joint disorders, unspecified hand',
  'M25851 Other specified joint disorders, right hip',
  'M25852 Other specified joint disorders, left hip',
  'M25859 Other specified joint disorders, unspecified hip',
  'M25861 Other specified joint disorders, right knee',
  'M25862 Other specified joint disorders, left knee',
  'M25869 Other specified joint disorders, unspecified knee',
  'M25871 Other specified joint disorders, right ankle and foot',
  'M25872 Other specified joint disorders, left ankle and foot',
  'M25879 Other specified joint disorders, unspecified ankle and foot',
  'M259 Joint disorder, unspecified',
  'M2600 Unspecified anomaly of jaw size',
  'M2601 Maxillary hyperplasia',
  'M2602 Maxillary hypoplasia',
  'M2603 Mandibular hyperplasia',
  'M2604 Mandibular hypoplasia',
  'M2605 Macrogenia',
  'M2606 Microgenia',
  'M2607 Excessive tuberosity of jaw',
  'M2609 Other specified anomalies of jaw size',
  'M2610 Unspecified anomaly of jaw-cranial base relationship',
  'M2611 Maxillary asymmetry',
  'M2612 Other jaw asymmetry',
  'M2619 Other specified anomalies of jaw-cranial base relationship',
  'M2620 Unspecified anomaly of dental arch relationship',
  "M26211 Malocclusion, Angle's class I",
  "M26212 Malocclusion, Angle's class II",
  "M26213 Malocclusion, Angle's class III",
  "M26219 Malocclusion, Angle's class, unspecified",
  'M26220 Open anterior occlusal relationship',
  'M26221 Open posterior occlusal relationship',
  'M2623 Excessive horizontal overlap',
  'M2624 Reverse articulation',
  'M2625 Anomalies of interarch distance',
  'M2629 Other anomalies of dental arch relationship',
  'M2630 Unspecified anomaly of tooth position of fully erupted tooth or teeth',
  'M2631 Crowding of fully erupted teeth',
  'M2632 Excessive spacing of fully erupted teeth',
  'M2633 Horizontal displacement of fully erupted tooth or teeth',
  'M2634 Vertical displacement of fully erupted tooth or teeth',
  'M2635 Rotation of fully erupted tooth or teeth',
  'M2636 Insufficient interocclusal distance of fully erupted teeth (ridge)',
  'M2637 Excessive interocclusal distance of fully erupted teeth',
  'M2639 Other anomalies of tooth position of fully erupted tooth or teeth',
  'M264 Malocclusion, unspecified',
  'M2650 Dentofacial functional abnormalities, unspecified',
  'M2651 Abnormal jaw closure',
  'M2652 Limited mandibular range of motion',
  'M2653 Deviation in opening and closing of the mandible',
  'M2654 Insufficient anterior guidance',
  'M2655 Centric occlusion maximum intercuspation discrepancy',
  'M2656 Non-working side interference',
  'M2657 Lack of posterior occlusal support',
  'M2659 Other dentofacial functional abnormalities',
  'M26601 Right temporomandibular joint disorder, unspecified',
  'M26602 Left temporomandibular joint disorder, unspecified',
  'M26603 Bilateral temporomandibular joint disorder, unspecified',
  'M26609 Unspecified temporomandibular joint disorder, unspecified side',
  'M26611 Adhesions and ankylosis of right temporomandibular joint',
  'M26612 Adhesions and ankylosis of left temporomandibular joint',
  'M26613 Adhesions and ankylosis of bilateral temporomandibular joint',
  'M26619 Adhesions and ankylosis of temporomandibular joint, unspecified side',
  'M26621 Arthralgia of right temporomandibular joint',
  'M26622 Arthralgia of left temporomandibular joint',
  'M26623 Arthralgia of bilateral temporomandibular joint',
  'M26629 Arthralgia of temporomandibular joint, unspecified side',
  'M26631 Articular disc disorder of right temporomandibular joint',
  'M26632 Articular disc disorder of left temporomandibular joint',
  'M26633 Articular disc disorder of bilateral temporomandibular joint',
  'M26639 Articular disc disorder of temporomandibular joint, unspecified side',
  'M2669 Other specified disorders of temporomandibular joint',
  'M2670 Unspecified alveolar anomaly',
  'M2671 Alveolar maxillary hyperplasia',
  'M2672 Alveolar mandibular hyperplasia',
  'M2673 Alveolar maxillary hypoplasia',
  'M2674 Alveolar mandibular hypoplasia',
  'M2679 Other specified alveolar anomalies',
  'M2681 Anterior soft tissue impingement',
  'M2682 Posterior soft tissue impingement',
  'M2689 Other dentofacial anomalies',
  'M269 Dentofacial anomaly, unspecified',
  'M270 Developmental disorders of jaws',
  'M271 Giant cell granuloma, central',
  'M272 Inflammatory conditions of jaws',
  'M273 Alveolitis of jaws',
  'M2740 Unspecified cyst of jaw',
  'M2749 Other cysts of jaw',
  'M2751 Perforation of root canal space due to endodontic treatment',
  'M2752 Endodontic overfill',
  'M2753 Endodontic underfill',
  'M2759 Other periradicular pathology associated with previous endodontic treatment',
  'M2761 Osseointegration failure of dental implant',
  'M2762 Post-osseointegration biological failure of dental implant',
  'M2763 Post-osseointegration mechanical failure of dental implant',
  'M2769 Other endosseous dental implant failure',
  'M278 Other specified diseases of jaws',
  'M279 Disease of jaws, unspecified',
  'M300 Polyarteritis nodosa',
  'M301 Polyarteritis with lung involvement [Churg-Strauss]',
  'M302 Juvenile polyarteritis',
  'M303 Mucocutaneous lymph node syndrome [Kawasaki]',
  'M308 Other conditions related to polyarteritis nodosa',
  'M310 Hypersensitivity angiitis',
  'M311 Thrombotic microangiopathy',
  'M312 Lethal midline granuloma',
  "M3130 Wegener's granulomatosis without renal involvement",
  "M3131 Wegener's granulomatosis with renal involvement",
  'M314 Aortic arch syndrome [Takayasu]',
  'M315 Giant cell arteritis with polymyalgia rheumatica',
  'M316 Other giant cell arteritis',
  'M317 Microscopic polyangiitis',
  'M318 Other specified necrotizing vasculopathies',
  'M319 Necrotizing vasculopathy, unspecified',
  'M320 Drug-induced systemic lupus erythematosus',
  'M3210 Systemic lupus erythematosus, organ or system involvement unspecified',
  'M3211 Endocarditis in systemic lupus erythematosus',
  'M3212 Pericarditis in systemic lupus erythematosus',
  'M3213 Lung involvement in systemic lupus erythematosus',
  'M3214 Glomerular disease in systemic lupus erythematosus',
  'M3215 Tubulo-interstitial nephropathy in systemic lupus erythematosus',
  'M3219 Other organ or system involvement in systemic lupus erythematosus',
  'M328 Other forms of systemic lupus erythematosus',
  'M329 Systemic lupus erythematosus, unspecified',
  'M3300 Juvenile dermatopolymyositis, organ involvement unspecified',
  'M3301 Juvenile dermatopolymyositis with respiratory involvement',
  'M3302 Juvenile dermatopolymyositis with myopathy',
  'M3309 Juvenile dermatopolymyositis with other organ involvement',
  'M3310 Other dermatopolymyositis, organ involvement unspecified',
  'M3311 Other dermatopolymyositis with respiratory involvement',
  'M3312 Other dermatopolymyositis with myopathy',
  'M3319 Other dermatopolymyositis with other organ involvement',
  'M3320 Polymyositis, organ involvement unspecified',
  'M3321 Polymyositis with respiratory involvement',
  'M3322 Polymyositis with myopathy',
  'M3329 Polymyositis with other organ involvement',
  'M3390 Dermatopolymyositis, unspecified, organ involvement unspecified',
  'M3391 Dermatopolymyositis, unspecified with respiratory involvement',
  'M3392 Dermatopolymyositis, unspecified with myopathy',
  'M3399 Dermatopolymyositis, unspecified with other organ involvement',
  'M340 Progressive systemic sclerosis',
  'M341 CR(E)ST syndrome',
  'M342 Systemic sclerosis induced by drug and chemical',
  'M3481 Systemic sclerosis with lung involvement',
  'M3482 Systemic sclerosis with myopathy',
  'M3483 Systemic sclerosis with polyneuropathy',
  'M3489 Other systemic sclerosis',
  'M349 Systemic sclerosis, unspecified',
  'M3500 Sicca syndrome, unspecified',
  'M3501 Sicca syndrome with keratoconjunctivitis',
  'M3502 Sicca syndrome with lung involvement',
  'M3503 Sicca syndrome with myopathy',
  'M3504 Sicca syndrome with tubulo-interstitial nephropathy',
  'M3509 Sicca syndrome with other organ involvement',
  'M351 Other overlap syndromes',
  "M352 Behcet's disease",
  'M353 Polymyalgia rheumatica',
  'M354 Diffuse (eosinophilic) fasciitis',
  'M355 Multifocal fibrosclerosis',
  'M356 Relapsing panniculitis [Weber-Christian]',
  'M357 Hypermobility syndrome',
  'M358 Other specified systemic involvement of connective tissue',
  'M359 Systemic involvement of connective tissue, unspecified',
  'M360 Dermato(poly)myositis in neoplastic disease',
  'M361 Arthropathy in neoplastic disease',
  'M362 Hemophilic arthropathy',
  'M363 Arthropathy in other blood disorders',
  'M364 Arthropathy in hypersensitivity reactions classified elsewhere',
  'M368 Systemic disorders of connective tissue in other diseases classified elsewhere',
  'M4000 Postural kyphosis, site unspecified',
  'M4003 Postural kyphosis, cervicothoracic region',
  'M4004 Postural kyphosis, thoracic region',
  'M4005 Postural kyphosis, thoracolumbar region',
  'M4010 Other secondary kyphosis, site unspecified',
  'M4012 Other secondary kyphosis, cervical region',
  'M4013 Other secondary kyphosis, cervicothoracic region',
  'M4014 Other secondary kyphosis, thoracic region',
  'M4015 Other secondary kyphosis, thoracolumbar region',
  'M40202 Unspecified kyphosis, cervical region',
  'M40203 Unspecified kyphosis, cervicothoracic region',
  'M40204 Unspecified kyphosis, thoracic region',
  'M40205 Unspecified kyphosis, thoracolumbar region',
  'M40209 Unspecified kyphosis, site unspecified',
  'M40292 Other kyphosis, cervical region',
  'M40293 Other kyphosis, cervicothoracic region',
  'M40294 Other kyphosis, thoracic region',
  'M40295 Other kyphosis, thoracolumbar region',
  'M40299 Other kyphosis, site unspecified',
  'M4030 Flatback syndrome, site unspecified',
  'M4035 Flatback syndrome, thoracolumbar region',
  'M4036 Flatback syndrome, lumbar region',
  'M4037 Flatback syndrome, lumbosacral region',
  'M4040 Postural lordosis, site unspecified',
  'M4045 Postural lordosis, thoracolumbar region',
  'M4046 Postural lordosis, lumbar region',
  'M4047 Postural lordosis, lumbosacral region',
  'M4050 Lordosis, unspecified, site unspecified',
  'M4055 Lordosis, unspecified, thoracolumbar region',
  'M4056 Lordosis, unspecified, lumbar region',
  'M4057 Lordosis, unspecified, lumbosacral region',
  'M4100 Infantile idiopathic scoliosis, site unspecified',
  'M4102 Infantile idiopathic scoliosis, cervical region',
  'M4103 Infantile idiopathic scoliosis, cervicothoracic region',
  'M4104 Infantile idiopathic scoliosis, thoracic region',
  'M4105 Infantile idiopathic scoliosis, thoracolumbar region',
  'M4106 Infantile idiopathic scoliosis, lumbar region',
  'M4107 Infantile idiopathic scoliosis, lumbosacral region',
  'M4108 Infantile idiopathic scoliosis, sacral and sacrococcygeal region',
  'M41112 Juvenile idiopathic scoliosis, cervical region',
  'M41113 Juvenile idiopathic scoliosis, cervicothoracic region',
  'M41114 Juvenile idiopathic scoliosis, thoracic region',
  'M41115 Juvenile idiopathic scoliosis, thoracolumbar region',
  'M41116 Juvenile idiopathic scoliosis, lumbar region',
  'M41117 Juvenile idiopathic scoliosis, lumbosacral region',
  'M41119 Juvenile idiopathic scoliosis, site unspecified',
  'M41122 Adolescent idiopathic scoliosis, cervical region',
  'M41123 Adolescent idiopathic scoliosis, cervicothoracic region',
  'M41124 Adolescent idiopathic scoliosis, thoracic region',
  'M41125 Adolescent idiopathic scoliosis, thoracolumbar region',
  'M41126 Adolescent idiopathic scoliosis, lumbar region',
  'M41127 Adolescent idiopathic scoliosis, lumbosacral region',
  'M41129 Adolescent idiopathic scoliosis, site unspecified',
  'M4120 Other idiopathic scoliosis, site unspecified',
  'M4122 Other idiopathic scoliosis, cervical region',
  'M4123 Other idiopathic scoliosis, cervicothoracic region',
  'M4124 Other idiopathic scoliosis, thoracic region',
  'M4125 Other idiopathic scoliosis, thoracolumbar region',
  'M4126 Other idiopathic scoliosis, lumbar region',
  'M4127 Other idiopathic scoliosis, lumbosacral region',
  'M4130 Thoracogenic scoliosis, site unspecified',
  'M4134 Thoracogenic scoliosis, thoracic region',
  'M4135 Thoracogenic scoliosis, thoracolumbar region',
  'M4140 Neuromuscular scoliosis, site unspecified',
  'M4141 Neuromuscular scoliosis, occipito-atlanto-axial region',
  'M4142 Neuromuscular scoliosis, cervical region',
  'M4143 Neuromuscular scoliosis, cervicothoracic region',
  'M4144 Neuromuscular scoliosis, thoracic region',
  'M4145 Neuromuscular scoliosis, thoracolumbar region',
  'M4146 Neuromuscular scoliosis, lumbar region',
  'M4147 Neuromuscular scoliosis, lumbosacral region',
  'M4150 Other secondary scoliosis, site unspecified',
  'M4152 Other secondary scoliosis, cervical region',
  'M4153 Other secondary scoliosis, cervicothoracic region',
  'M4154 Other secondary scoliosis, thoracic region',
  'M4155 Other secondary scoliosis, thoracolumbar region',
  'M4156 Other secondary scoliosis, lumbar region',
  'M4157 Other secondary scoliosis, lumbosacral region',
  'M4180 Other forms of scoliosis, site unspecified',
  'M4182 Other forms of scoliosis, cervical region',
  'M4183 Other forms of scoliosis, cervicothoracic region',
  'M4184 Other forms of scoliosis, thoracic region',
  'M4185 Other forms of scoliosis, thoracolumbar region',
  'M4186 Other forms of scoliosis, lumbar region',
  'M4187 Other forms of scoliosis, lumbosacral region',
  'M419 Scoliosis, unspecified',
  'M4200 Juvenile osteochondrosis of spine, site unspecified',
  'M4201 Juvenile osteochondrosis of spine, occipito-atlanto-axial region',
  'M4202 Juvenile osteochondrosis of spine, cervical region',
  'M4203 Juvenile osteochondrosis of spine, cervicothoracic region',
  'M4204 Juvenile osteochondrosis of spine, thoracic region',
  'M4205 Juvenile osteochondrosis of spine, thoracolumbar region',
  'M4206 Juvenile osteochondrosis of spine, lumbar region',
  'M4207 Juvenile osteochondrosis of spine, lumbosacral region',
  'M4208 Juvenile osteochondrosis of spine, sacral and sacrococcygeal region',
  'M4209 Juvenile osteochondrosis of spine, multiple sites in spine',
  'M4210 Adult osteochondrosis of spine, site unspecified',
  'M4211 Adult osteochondrosis of spine, occipito-atlanto-axial region',
  'M4212 Adult osteochondrosis of spine, cervical region',
  'M4213 Adult osteochondrosis of spine, cervicothoracic region',
  'M4214 Adult osteochondrosis of spine, thoracic region',
  'M4215 Adult osteochondrosis of spine, thoracolumbar region',
  'M4216 Adult osteochondrosis of spine, lumbar region',
  'M4217 Adult osteochondrosis of spine, lumbosacral region',
  'M4218 Adult osteochondrosis of spine, sacral and sacrococcygeal region',
  'M4219 Adult osteochondrosis of spine, multiple sites in spine',
  'M429 Spinal osteochondrosis, unspecified',
  'M4300 Spondylolysis, site unspecified',
  'M4301 Spondylolysis, occipito-atlanto-axial region',
  'M4302 Spondylolysis, cervical region',
  'M4303 Spondylolysis, cervicothoracic region',
  'M4304 Spondylolysis, thoracic region',
  'M4305 Spondylolysis, thoracolumbar region',
  'M4306 Spondylolysis, lumbar region',
  'M4307 Spondylolysis, lumbosacral region',
  'M4308 Spondylolysis, sacral and sacrococcygeal region',
  'M4309 Spondylolysis, multiple sites in spine',
  'M4310 Spondylolisthesis, site unspecified',
  'M4311 Spondylolisthesis, occipito-atlanto-axial region',
  'M4312 Spondylolisthesis, cervical region',
  'M4313 Spondylolisthesis, cervicothoracic region',
  'M4314 Spondylolisthesis, thoracic region',
  'M4315 Spondylolisthesis, thoracolumbar region',
  'M4316 Spondylolisthesis, lumbar region',
  'M4317 Spondylolisthesis, lumbosacral region',
  'M4318 Spondylolisthesis, sacral and sacrococcygeal region',
  'M4319 Spondylolisthesis, multiple sites in spine',
  'M4320 Fusion of spine, site unspecified',
  'M4321 Fusion of spine, occipito-atlanto-axial region',
  'M4322 Fusion of spine, cervical region',
  'M4323 Fusion of spine, cervicothoracic region',
  'M4324 Fusion of spine, thoracic region',
  'M4325 Fusion of spine, thoracolumbar region',
  'M4326 Fusion of spine, lumbar region',
  'M4327 Fusion of spine, lumbosacral region',
  'M4328 Fusion of spine, sacral and sacrococcygeal region',
  'M433 Recurrent atlantoaxial dislocation with myelopathy',
  'M434 Other recurrent atlantoaxial dislocation',
  'M435X2 Other recurrent vertebral dislocation, cervical region',
  'M435X3 Other recurrent vertebral dislocation, cervicothoracic region',
  'M435X4 Other recurrent vertebral dislocation, thoracic region',
  'M435X5 Other recurrent vertebral dislocation, thoracolumbar region',
  'M435X6 Other recurrent vertebral dislocation, lumbar region',
  'M435X7 Other recurrent vertebral dislocation, lumbosacral region',
  'M435X8 Other recurrent vertebral dislocation, sacral and sacrococcygeal region',
  'M435X9 Other recurrent vertebral dislocation, site unspecified',
  'M436 Torticollis',
  'M438X1 Other specified deforming dorsopathies, occipito-atlanto-axial region',
  'M438X2 Other specified deforming dorsopathies, cervical region',
  'M438X3 Other specified deforming dorsopathies, cervicothoracic region',
  'M438X4 Other specified deforming dorsopathies, thoracic region',
  'M438X5 Other specified deforming dorsopathies, thoracolumbar region',
  'M438X6 Other specified deforming dorsopathies, lumbar region',
  'M438X7 Other specified deforming dorsopathies, lumbosacral region',
  'M438X8 Other specified deforming dorsopathies, sacral and sacrococcygeal region',
  'M438X9 Other specified deforming dorsopathies, site unspecified',
  'M439 Deforming dorsopathy, unspecified',
  'M450 Ankylosing spondylitis of multiple sites in spine',
  'M451 Ankylosing spondylitis of occipito-atlanto-axial region',
  'M452 Ankylosing spondylitis of cervical region',
  'M453 Ankylosing spondylitis of cervicothoracic region',
  'M454 Ankylosing spondylitis of thoracic region',
  'M455 Ankylosing spondylitis of thoracolumbar region',
  'M456 Ankylosing spondylitis lumbar region',
  'M457 Ankylosing spondylitis of lumbosacral region',
  'M458 Ankylosing spondylitis sacral and sacrococcygeal region',
  'M459 Ankylosing spondylitis of unspecified sites in spine',
  'M4600 Spinal enthesopathy, site unspecified',
  'M4601 Spinal enthesopathy, occipito-atlanto-axial region',
  'M4602 Spinal enthesopathy, cervical region',
  'M4603 Spinal enthesopathy, cervicothoracic region',
  'M4604 Spinal enthesopathy, thoracic region',
  'M4605 Spinal enthesopathy, thoracolumbar region',
  'M4606 Spinal enthesopathy, lumbar region',
  'M4607 Spinal enthesopathy, lumbosacral region',
  'M4608 Spinal enthesopathy, sacral and sacrococcygeal region',
  'M4609 Spinal enthesopathy, multiple sites in spine',
  'M461 Sacroiliitis, not elsewhere classified',
  'M4620 Osteomyelitis of vertebra, site unspecified',
  'M4621 Osteomyelitis of vertebra, occipito-atlanto-axial region',
  'M4622 Osteomyelitis of vertebra, cervical region',
  'M4623 Osteomyelitis of vertebra, cervicothoracic region',
  'M4624 Osteomyelitis of vertebra, thoracic region',
  'M4625 Osteomyelitis of vertebra, thoracolumbar region',
  'M4626 Osteomyelitis of vertebra, lumbar region',
  'M4627 Osteomyelitis of vertebra, lumbosacral region',
  'M4628 Osteomyelitis of vertebra, sacral and sacrococcygeal region',
  'M4630 Infection of intervertebral disc (pyogenic), site unspecified',
  'M4631 Infection of intervertebral disc (pyogenic), occipito-atlanto-axial region',
  'M4632 Infection of intervertebral disc (pyogenic), cervical region',
  'M4633 Infection of intervertebral disc (pyogenic), cervicothoracic region',
  'M4634 Infection of intervertebral disc (pyogenic), thoracic region',
  'M4635 Infection of intervertebral disc (pyogenic), thoracolumbar region',
  'M4636 Infection of intervertebral disc (pyogenic), lumbar region',
  'M4637 Infection of intervertebral disc (pyogenic), lumbosacral region',
  'M4638 Infection of intervertebral disc (pyogenic), sacral and sacrococcygeal region',
  'M4639 Infection of intervertebral disc (pyogenic), multiple sites in spine',
  'M4640 Discitis, unspecified, site unspecified',
  'M4641 Discitis, unspecified, occipito-atlanto-axial region',
  'M4642 Discitis, unspecified, cervical region',
  'M4643 Discitis, unspecified, cervicothoracic region',
  'M4644 Discitis, unspecified, thoracic region',
  'M4645 Discitis, unspecified, thoracolumbar region',
  'M4646 Discitis, unspecified, lumbar region',
  'M4647 Discitis, unspecified, lumbosacral region',
  'M4648 Discitis, unspecified, sacral and sacrococcygeal region',
  'M4649 Discitis, unspecified, multiple sites in spine',
  'M4650 Other infective spondylopathies, site unspecified',
  'M4651 Other infective spondylopathies, occipito-atlanto-axial region',
  'M4652 Other infective spondylopathies, cervical region',
  'M4653 Other infective spondylopathies, cervicothoracic region',
  'M4654 Other infective spondylopathies, thoracic region',
  'M4655 Other infective spondylopathies, thoracolumbar region',
  'M4656 Other infective spondylopathies, lumbar region',
  'M4657 Other infective spondylopathies, lumbosacral region',
  'M4658 Other infective spondylopathies, sacral and sacrococcygeal region',
  'M4659 Other infective spondylopathies, multiple sites in spine',
  'M4680 Other specified inflammatory spondylopathies, site unspecified',
  'M4681 Other specified inflammatory spondylopathies, occipito-atlanto-axial region',
  'M4682 Other specified inflammatory spondylopathies, cervical region',
  'M4683 Other specified inflammatory spondylopathies, cervicothoracic region',
  'M4684 Other specified inflammatory spondylopathies, thoracic region',
  'M4685 Other specified inflammatory spondylopathies, thoracolumbar region',
  'M4686 Other specified inflammatory spondylopathies, lumbar region',
  'M4687 Other specified inflammatory spondylopathies, lumbosacral region',
  'M4688 Other specified inflammatory spondylopathies, sacral and sacrococcygeal region',
  'M4689 Other specified inflammatory spondylopathies, multiple sites in spine',
  'M4690 Unspecified inflammatory spondylopathy, site unspecified',
  'M4691 Unspecified inflammatory spondylopathy, occipito-atlanto-axial region',
  'M4692 Unspecified inflammatory spondylopathy, cervical region',
  'M4693 Unspecified inflammatory spondylopathy, cervicothoracic region',
  'M4694 Unspecified inflammatory spondylopathy, thoracic region',
  'M4695 Unspecified inflammatory spondylopathy, thoracolumbar region',
  'M4696 Unspecified inflammatory spondylopathy, lumbar region',
  'M4697 Unspecified inflammatory spondylopathy, lumbosacral region',
  'M4698 Unspecified inflammatory spondylopathy, sacral and sacrococcygeal region',
  'M4699 Unspecified inflammatory spondylopathy, multiple sites in spine',
  'M47011 Anterior spinal artery compression syndromes, occipito-atlanto-axial region',
  'M47012 Anterior spinal artery compression syndromes, cervical region',
  'M47013 Anterior spinal artery compression syndromes, cervicothoracic region',
  'M47014 Anterior spinal artery compression syndromes, thoracic region',
  'M47015 Anterior spinal artery compression syndromes, thoracolumbar region',
  'M47016 Anterior spinal artery compression syndromes, lumbar region',
  'M47019 Anterior spinal artery compression syndromes, site unspecified',
  'M47021 Vertebral artery compression syndromes, occipito-atlanto-axial region',
  'M47022 Vertebral artery compression syndromes, cervical region',
  'M47029 Vertebral artery compression syndromes, site unspecified',
  'M4710 Other spondylosis with myelopathy, site unspecified',
  'M4711 Other spondylosis with myelopathy, occipito-atlanto-axial region',
  'M4712 Other spondylosis with myelopathy, cervical region',
  'M4713 Other spondylosis with myelopathy, cervicothoracic region',
  'M4714 Other spondylosis with myelopathy, thoracic region',
  'M4715 Other spondylosis with myelopathy, thoracolumbar region',
  'M4716 Other spondylosis with myelopathy, lumbar region',
  'M4720 Other spondylosis with radiculopathy, site unspecified',
  'M4721 Other spondylosis with radiculopathy, occipito-atlanto-axial region',
  'M4722 Other spondylosis with radiculopathy, cervical region',
  'M4723 Other spondylosis with radiculopathy, cervicothoracic region',
  'M4724 Other spondylosis with radiculopathy, thoracic region',
  'M4725 Other spondylosis with radiculopathy, thoracolumbar region',
  'M4726 Other spondylosis with radiculopathy, lumbar region',
  'M4727 Other spondylosis with radiculopathy, lumbosacral region',
  'M4728 Other spondylosis with radiculopathy, sacral and sacrococcygeal region',
  'M47811 Spondylosis without myelopathy or radiculopathy, occipito-atlanto-axial region',
  'M47812 Spondylosis without myelopathy or radiculopathy, cervical region',
  'M47813 Spondylosis without myelopathy or radiculopathy, cervicothoracic region',
  'M47814 Spondylosis without myelopathy or radiculopathy, thoracic region',
  'M47815 Spondylosis without myelopathy or radiculopathy, thoracolumbar region',
  'M47816 Spondylosis without myelopathy or radiculopathy, lumbar region',
  'M47817 Spondylosis without myelopathy or radiculopathy, lumbosacral region',
  'M47818 Spondylosis without myelopathy or radiculopathy, sacral and sacrococcygeal region',
  'M47819 Spondylosis without myelopathy or radiculopathy, site unspecified',
  'M47891 Other spondylosis, occipito-atlanto-axial region',
  'M47892 Other spondylosis, cervical region',
  'M47893 Other spondylosis, cervicothoracic region',
  'M47894 Other spondylosis, thoracic region',
  'M47895 Other spondylosis, thoracolumbar region',
  'M47896 Other spondylosis, lumbar region',
  'M47897 Other spondylosis, lumbosacral region',
  'M47898 Other spondylosis, sacral and sacrococcygeal region',
  'M47899 Other spondylosis, site unspecified',
  'M479 Spondylosis, unspecified',
  'M4800 Spinal stenosis, site unspecified',
  'M4801 Spinal stenosis, occipito-atlanto-axial region',
  'M4802 Spinal stenosis, cervical region',
  'M4803 Spinal stenosis, cervicothoracic region',
  'M4804 Spinal stenosis, thoracic region',
  'M4805 Spinal stenosis, thoracolumbar region',
  'M4806 Spinal stenosis, lumbar region',
  'M4807 Spinal stenosis, lumbosacral region',
  'M4808 Spinal stenosis, sacral and sacrococcygeal region',
  'M4810 Ankylosing hyperostosis [Forestier], site unspecified',
  'M4811 Ankylosing hyperostosis [Forestier], occipito-atlanto-axial region',
  'M4812 Ankylosing hyperostosis [Forestier], cervical region',
  'M4813 Ankylosing hyperostosis [Forestier], cervicothoracic region',
  'M4814 Ankylosing hyperostosis [Forestier], thoracic region',
  'M4815 Ankylosing hyperostosis [Forestier], thoracolumbar region',
  'M4816 Ankylosing hyperostosis [Forestier], lumbar region',
  'M4817 Ankylosing hyperostosis [Forestier], lumbosacral region',
  'M4818 Ankylosing hyperostosis [Forestier], sacral and sacrococcygeal region',
  'M4819 Ankylosing hyperostosis [Forestier], multiple sites in spine',
  'M4820 Kissing spine, site unspecified',
  'M4821 Kissing spine, occipito-atlanto-axial region',
  'M4822 Kissing spine, cervical region',
  'M4823 Kissing spine, cervicothoracic region',
  'M4824 Kissing spine, thoracic region',
  'M4825 Kissing spine, thoracolumbar region',
  'M4826 Kissing spine, lumbar region',
  'M4827 Kissing spine, lumbosacral region',
  'M4830 Traumatic spondylopathy, site unspecified',
  'M4831 Traumatic spondylopathy, occipito-atlanto-axial region',
  'M4832 Traumatic spondylopathy, cervical region',
  'M4833 Traumatic spondylopathy, cervicothoracic region',
  'M4834 Traumatic spondylopathy, thoracic region',
  'M4835 Traumatic spondylopathy, thoracolumbar region',
  'M4836 Traumatic spondylopathy, lumbar region',
  'M4837 Traumatic spondylopathy, lumbosacral region',
  'M4838 Traumatic spondylopathy, sacral and sacrococcygeal region',
  'M4840XA Fatigue fracture of vertebra, site unspecified, initial encounter for fracture',
  'M4840XD Fatigue fracture of vertebra, site unspecified, subsequent encounter for fracture with routine',
  'healing',
  'M4840XG Fatigue fracture of vertebra, site unspecified, subsequent encounter for fracture with delayed',
  'healing',
  'M4840XS Fatigue fracture of vertebra, site unspecified, sequela of fracture',
  'M4841XA Fatigue fracture of vertebra, occipito-atlanto-axial region, initial encounter for fracture',
  'M4841XD Fatigue fracture of vertebra, occipito-atlanto-axial region, subsequent encounter for fracture',
  'with routine healing',
  'M4841XG Fatigue fracture of vertebra, occipito-atlanto-axial region, subsequent encounter for fracture',
  'with delayed healing',
  'M4841XS Fatigue fracture of vertebra, occipito-atlanto-axial region, sequela of fracture',
  'M4842XA Fatigue fracture of vertebra, cervical region, initial encounter for fracture',
  'M4842XD Fatigue fracture of vertebra, cervical region, subsequent encounter for fracture with routine',
  'healing',
  'M4842XG Fatigue fracture of vertebra, cervical region, subsequent encounter for fracture with delayed',
  'healing',
  'M4842XS Fatigue fracture of vertebra, cervical region, sequela of fracture',
  'M4843XA Fatigue fracture of vertebra, cervicothoracic region, initial encounter for fracture',
  'M4843XD Fatigue fracture of vertebra, cervicothoracic region, subsequent encounter for fracture with',
  'routine healing',
  'M4843XG Fatigue fracture of vertebra, cervicothoracic region, subsequent encounter for fracture with',
  'delayed healing',
  'M4843XS Fatigue fracture of vertebra, cervicothoracic region, sequela of fracture',
  'M4844XA Fatigue fracture of vertebra, thoracic region, initial encounter for fracture',
  'M4844XD Fatigue fracture of vertebra, thoracic region, subsequent encounter for fracture with routine',
  'healing',
  'M4844XG Fatigue fracture of vertebra, thoracic region, subsequent encounter for fracture with delayed',
  'healing',
  'M4844XS Fatigue fracture of vertebra, thoracic region, sequela of fracture',
  'M4845XA Fatigue fracture of vertebra, thoracolumbar region, initial encounter for fracture',
  'M4845XD Fatigue fracture of vertebra, thoracolumbar region, subsequent encounter for fracture with',
  'routine healing',
  'M4845XG Fatigue fracture of vertebra, thoracolumbar region, subsequent encounter for fracture with',
  'delayed healing',
  'M4845XS Fatigue fracture of vertebra, thoracolumbar region, sequela of fracture',
  'M4846XA Fatigue fracture of vertebra, lumbar region, initial encounter for fracture',
  'M4846XD Fatigue fracture of vertebra, lumbar region, subsequent encounter for fracture with routine',
  'healing',
  'M4846XG Fatigue fracture of vertebra, lumbar region, subsequent encounter for fracture with delayed',
  'healing',
  'M4846XS Fatigue fracture of vertebra, lumbar region, sequela of fracture',
  'M4847XA Fatigue fracture of vertebra, lumbosacral region, initial encounter for fracture',
  'M4847XD Fatigue fracture of vertebra, lumbosacral region, subsequent encounter for fracture with',
  'routine healing',
  'M4847XG Fatigue fracture of vertebra, lumbosacral region, subsequent encounter for fracture with',
  'delayed healing',
  'M4847XS Fatigue fracture of vertebra, lumbosacral region, sequela of fracture',
  'M4848XA Fatigue fracture of vertebra, sacral and sacrococcygeal region, initial encounter for fracture',
  'M4848XD Fatigue fracture of vertebra, sacral and sacrococcygeal region, subsequent encounter for',
  'fracture with routine healing',
  'M4848XG Fatigue fracture of vertebra, sacral and sacrococcygeal region, subsequent encounter for',
  'fracture with delayed healing',
  'M4848XS Fatigue fracture of vertebra, sacral and sacrococcygeal region, sequela of fracture',
  'M4850XA Collapsed vertebra, not elsewhere classified, site unspecified, initial encounter for fracture',
  'M4850XD Collapsed vertebra, not elsewhere classified, site unspecified, subsequent encounter for',
  'fracture with routine healing',
  'M4850XG Collapsed vertebra, not elsewhere classified, site unspecified, subsequent encounter for',
  'fracture with delayed healing',
  'M4850XS Collapsed vertebra, not elsewhere classified, site unspecified, sequela of fracture',
  'M4851XA Collapsed vertebra, not elsewhere classified, occipito-atlanto-axial region, initial encounter for',
  'fracture',
  'M4851XD Collapsed vertebra, not elsewhere classified, occipito-atlanto-axial region, subsequent',
  'encounter for fracture with routine healing',
  'M4851XG Collapsed vertebra, not elsewhere classified, occipito-atlanto-axial region, subsequent',
  'encounter for fracture with delayed healing',
  'M4851XS Collapsed vertebra, not elsewhere classified, occipito-atlanto-axial region, sequela of fracture',
  'M4852XA Collapsed vertebra, not elsewhere classified, cervical region, initial encounter for fracture',
  'M4852XD Collapsed vertebra, not elsewhere classified, cervical region, subsequent encounter for',
  'fracture with routine healing',
  'M4852XG Collapsed vertebra, not elsewhere classified, cervical region, subsequent encounter for',
  'fracture with delayed healing',
  'M4852XS Collapsed vertebra, not elsewhere classified, cervical region, sequela of fracture',
  'M4853XA Collapsed vertebra, not elsewhere classified, cervicothoracic region, initial encounter for',
  'fracture',
  'M4853XD Collapsed vertebra, not elsewhere classified, cervicothoracic region, subsequent encounter',
  'for fracture with routine healing',
  'M4853XG Collapsed vertebra, not elsewhere classified, cervicothoracic region, subsequent encounter',
  'for fracture with delayed healing',
  'M4853XS Collapsed vertebra, not elsewhere classified, cervicothoracic region, sequela of fracture',
  'M4854XA Collapsed vertebra, not elsewhere classified, thoracic region, initial encounter for fracture',
  'M4854XD Collapsed vertebra, not elsewhere classified, thoracic region, subsequent encounter for',
  'fracture with routine healing',
  'M4854XG Collapsed vertebra, not elsewhere classified, thoracic region, subsequent encounter for',
  'fracture with delayed healing',
  'M4854XS Collapsed vertebra, not elsewhere classified, thoracic region, sequela of fracture',
  'M4855XA Collapsed vertebra, not elsewhere classified, thoracolumbar region, initial encounter for',
  'fracture',
  'M4855XD Collapsed vertebra, not elsewhere classified, thoracolumbar region, subsequent encounter for',
  'fracture with routine healing',
  'M4855XG Collapsed vertebra, not elsewhere classified, thoracolumbar region, subsequent encounter',
  'for fracture with delayed healing',
  'M4855XS Collapsed vertebra, not elsewhere classified, thoracolumbar region, sequela of fracture',
  'M4856XA Collapsed vertebra, not elsewhere classified, lumbar region, initial encounter for fracture',
  'M4856XD Collapsed vertebra, not elsewhere classified, lumbar region, subsequent encounter for',
  'fracture with routine healing',
  'M4856XG Collapsed vertebra, not elsewhere classified, lumbar region, subsequent encounter for',
  'fracture with delayed healing',
  'M4856XS Collapsed vertebra, not elsewhere classified, lumbar region, sequela of fracture',
  'M4857XA Collapsed vertebra, not elsewhere classified, lumbosacral region, initial encounter for fracture',
  'M4857XD Collapsed vertebra, not elsewhere classified, lumbosacral region, subsequent encounter for',
  'fracture with routine healing',
  'M4857XG Collapsed vertebra, not elsewhere classified, lumbosacral region, subsequent encounter for',
  'fracture with delayed healing',
  'M4857XS Collapsed vertebra, not elsewhere classified, lumbosacral region, sequela of fracture',
  'M4858XA Collapsed vertebra, not elsewhere classified, sacral and sacrococcygeal region, initial',
  'encounter for fracture',
  'M4858XD Collapsed vertebra, not elsewhere classified, sacral and sacrococcygeal region, subsequent',
  'encounter for fracture with routine healing',
  'M4858XG Collapsed vertebra, not elsewhere classified, sacral and sacrococcygeal region, subsequent',
  'encounter for fracture with delayed healing',
  'M4858XS Collapsed vertebra, not elsewhere classified, sacral and sacrococcygeal region, sequela of',
  'fracture',
  'M488X1 Other specified spondylopathies, occipito-atlanto-axial region',
  'M488X2 Other specified spondylopathies, cervical region',
  'M488X3 Other specified spondylopathies, cervicothoracic region',
  'M488X4 Other specified spondylopathies, thoracic region',
  'M488X5 Other specified spondylopathies, thoracolumbar region',
  'M488X6 Other specified spondylopathies, lumbar region',
  'M488X7 Other specified spondylopathies, lumbosacral region',
  'M488X8 Other specified spondylopathies, sacral and sacrococcygeal region',
  'M488X9 Other specified spondylopathies, site unspecified',
  'M489 Spondylopathy, unspecified',
  'M4980 Spondylopathy in diseases classified elsewhere, site unspecified',
  'M4981 Spondylopathy in diseases classified elsewhere, occipito-atlanto-axial region',
  'M4982 Spondylopathy in diseases classified elsewhere, cervical region',
  'M4983 Spondylopathy in diseases classified elsewhere, cervicothoracic region',
  'M4984 Spondylopathy in diseases classified elsewhere, thoracic region',
  'M4985 Spondylopathy in diseases classified elsewhere, thoracolumbar region',
  'M4986 Spondylopathy in diseases classified elsewhere, lumbar region',
  'M4987 Spondylopathy in diseases classified elsewhere, lumbosacral region',
  'M4988 Spondylopathy in diseases classified elsewhere, sacral and sacrococcygeal region',
  'M4989 Spondylopathy in diseases classified elsewhere, multiple sites in spine',
  'M5000 Cervical disc disorder with myelopathy, unspecified cervical region',
  'M5001 Cervical disc disorder with myelopathy, high cervical region',
  'M50020 Cervical disc disorder with myelopathy, mid-cervical region, unspecified level',
  'M50021 Cervical disc disorder at C4-C5 level with myelopathy',
  'M50022 Cervical disc disorder at C5-C6 level with myelopathy',
  'M50023 Cervical disc disorder at C6-C7 level with myelopathy',
  'M5003 Cervical disc disorder with myelopathy, cervicothoracic region',
  'M5010 Cervical disc disorder with radiculopathy, unspecified cervical region',
  'M5011 Cervical disc disorder with radiculopathy, high cervical region',
  'M50120 Mid-cervical disc disorder, unspecified',
  'M50121 Cervical disc disorder at C4-C5 level with radiculopathy',
  'M50122 Cervical disc disorder at C5-C6 level with radiculopathy',
  'M50123 Cervical disc disorder at C6-C7 level with radiculopathy',
  'M5013 Cervical disc disorder with radiculopathy, cervicothoracic region',
  'M5020 Other cervical disc displacement, unspecified cervical region',
  'M5021 Other cervical disc displacement, high cervical region',
  'M50220 Other cervical disc displacement, mid-cervical region, unspecified level',
  'M50221 Other cervical disc displacement at C4-C5 level',
  'M50222 Other cervical disc displacement at C5-C6 level',
  'M50223 Other cervical disc displacement at C6-C7 level',
  'M5023 Other cervical disc displacement, cervicothoracic region',
  'M5030 Other cervical disc degeneration, unspecified cervical region',
  'M5031 Other cervical disc degeneration, high cervical region',
  'M50320 Other cervical disc degeneration, mid-cervical region, unspecified level',
  'M50321 Other cervical disc degeneration at C4-C5 level',
  'M50322 Other cervical disc degeneration at C5-C6 level',
  'M50323 Other cervical disc degeneration at C6-C7 level',
  'M5033 Other cervical disc degeneration, cervicothoracic region',
  'M5080 Other cervical disc disorders, unspecified cervical region',
  'M5081 Other cervical disc disorders, high cervical region',
  'M50820 Other cervical disc disorders, mid-cervical region, unspecified level',
  'M50821 Other cervical disc disorders at C4-C5 level',
  'M50822 Other cervical disc disorders at C5-C6 level',
  'M50823 Other cervical disc disorders at C6-C7 level',
  'M5083 Other cervical disc disorders, cervicothoracic region',
  'M5090 Cervical disc disorder, unspecified, unspecified cervical region',
  'M5091 Cervical disc disorder, unspecified, high cervical region',
  'M50920 Unspecified cervical disc disorder, mid-cervical region, unspecified level',
  'M50921 Unspecified cervical disc disorder at C4-C5 level',
  'M50922 Unspecified cervical disc disorder at C5-C6 level',
  'M50923 Unspecified cervical disc disorder at C6-C7 level',
  'M5093 Cervical disc disorder, unspecified, cervicothoracic region',
  'M5104 Intervertebral disc disorders with myelopathy, thoracic region',
  'M5105 Intervertebral disc disorders with myelopathy, thoracolumbar region',
  'M5106 Intervertebral disc disorders with myelopathy, lumbar region',
  'M5114 Intervertebral disc disorders with radiculopathy, thoracic region',
  'M5115 Intervertebral disc disorders with radiculopathy, thoracolumbar region',
  'M5116 Intervertebral disc disorders with radiculopathy, lumbar region',
  'M5117 Intervertebral disc disorders with radiculopathy, lumbosacral region',
  'M5124 Other intervertebral disc displacement, thoracic region',
  'M5125 Other intervertebral disc displacement, thoracolumbar region',
  'M5126 Other intervertebral disc displacement, lumbar region',
  'M5127 Other intervertebral disc displacement, lumbosacral region',
  'M5134 Other intervertebral disc degeneration, thoracic region',
  'M5135 Other intervertebral disc degeneration, thoracolumbar region',
  'M5136 Other intervertebral disc degeneration, lumbar region',
  'M5137 Other intervertebral disc degeneration, lumbosacral region',
  "M5144 Schmorl's nodes, thoracic region",
  "M5145 Schmorl's nodes, thoracolumbar region",
  "M5146 Schmorl's nodes, lumbar region",
  "M5147 Schmorl's nodes, lumbosacral region",
  'M5184 Other intervertebral disc disorders, thoracic region',
  'M5185 Other intervertebral disc disorders, thoracolumbar region',
  'M5186 Other intervertebral disc disorders, lumbar region',
  'M5187 Other intervertebral disc disorders, lumbosacral region',
  'M519 Unspecified thoracic, thoracolumbar and lumbosacral intervertebral disc disorder',
  'M530 Cervicocranial syndrome',
  'M531 Cervicobrachial syndrome',
  'M532X1 Spinal instabilities, occipito-atlanto-axial region',
  'M532X2 Spinal instabilities, cervical region',
  'M532X3 Spinal instabilities, cervicothoracic region',
  'M532X4 Spinal instabilities, thoracic region',
  'M532X5 Spinal instabilities, thoracolumbar region',
  'M532X6 Spinal instabilities, lumbar region',
  'M532X7 Spinal instabilities, lumbosacral region',
  'M532X8 Spinal instabilities, sacral and sacrococcygeal region',
  'M532X9 Spinal instabilities, site unspecified',
  'M533 Sacrococcygeal disorders, not elsewhere classified',
  'M5380 Other specified dorsopathies, site unspecified',
  'M5381 Other specified dorsopathies, occipito-atlanto-axial region',
  'M5382 Other specified dorsopathies, cervical region',
  'M5383 Other specified dorsopathies, cervicothoracic region',
  'M5384 Other specified dorsopathies, thoracic region',
  'M5385 Other specified dorsopathies, thoracolumbar region',
  'M5386 Other specified dorsopathies, lumbar region',
  'M5387 Other specified dorsopathies, lumbosacral region',
  'M5388 Other specified dorsopathies, sacral and sacrococcygeal region',
  'M539 Dorsopathy, unspecified',
  'M5400 Panniculitis affecting regions of neck and back, site unspecified',
  'M5401 Panniculitis affecting regions of neck and back, occipito-atlanto-axial region',
  'M5402 Panniculitis affecting regions of neck and back, cervical region',
  'M5403 Panniculitis affecting regions of neck and back, cervicothoracic region',
  'M5404 Panniculitis affecting regions of neck and back, thoracic region',
  'M5405 Panniculitis affecting regions of neck and back, thoracolumbar region',
  'M5406 Panniculitis affecting regions of neck and back, lumbar region',
  'M5407 Panniculitis affecting regions of neck and back, lumbosacral region',
  'M5408 Panniculitis affecting regions of neck and back, sacral and sacrococcygeal region',
  'M5409 Panniculitis affecting regions, neck and back, multiple sites in spine',
  'M5410 Radiculopathy, site unspecified',
  'M5411 Radiculopathy, occipito-atlanto-axial region',
  'M5412 Radiculopathy, cervical region',
  'M5413 Radiculopathy, cervicothoracic region',
  'M5414 Radiculopathy, thoracic region',
  'M5415 Radiculopathy, thoracolumbar region',
  'M5416 Radiculopathy, lumbar region',
  'M5417 Radiculopathy, lumbosacral region',
  'M5418 Radiculopathy, sacral and sacrococcygeal region',
  'M542 Cervicalgia',
  'M5430 Sciatica, unspecified side',
  'M5431 Sciatica, right side',
  'M5432 Sciatica, left side',
  'M5440 Lumbago with sciatica, unspecified side',
  'M5441 Lumbago with sciatica, right side',
  'M5442 Lumbago with sciatica, left side',
  'M545 Low back pain',
  'M546 Pain in thoracic spine',
  'M5481 Occipital neuralgia',
  'M5489 Other dorsalgia',
  'M549 Dorsalgia, unspecified',
  'M60000 Infective myositis, unspecified right arm',
  'M60001 Infective myositis, unspecified left arm',
  'M60002 Infective myositis, unspecified arm',
  'M60003 Infective myositis, unspecified right leg',
  'M60004 Infective myositis, unspecified left leg',
  'M60005 Infective myositis, unspecified leg',
  'M60009 Infective myositis, unspecified site',
  'M60011 Infective myositis, right shoulder',
  'M60012 Infective myositis, left shoulder',
  'M60019 Infective myositis, unspecified shoulder',
  'M60021 Infective myositis, right upper arm',
  'M60022 Infective myositis, left upper arm',
  'M60029 Infective myositis, unspecified upper arm',
  'M60031 Infective myositis, right forearm',
  'M60032 Infective myositis, left forearm',
  'M60039 Infective myositis, unspecified forearm',
  'M60041 Infective myositis, right hand',
  'M60042 Infective myositis, left hand',
  'M60043 Infective myositis, unspecified hand',
  'M60044 Infective myositis, right finger(s)',
  'M60045 Infective myositis, left finger(s)',
  'M60046 Infective myositis, unspecified finger(s)',
  'M60051 Infective myositis, right thigh',
  'M60052 Infective myositis, left thigh',
  'M60059 Infective myositis, unspecified thigh',
  'M60061 Infective myositis, right lower leg',
  'M60062 Infective myositis, left lower leg',
  'M60069 Infective myositis, unspecified lower leg',
  'M60070 Infective myositis, right ankle',
  'M60071 Infective myositis, left ankle',
  'M60072 Infective myositis, unspecified ankle',
  'M60073 Infective myositis, right foot',
  'M60074 Infective myositis, left foot',
  'M60075 Infective myositis, unspecified foot',
  'M60076 Infective myositis, right toe(s)',
  'M60077 Infective myositis, left toe(s)',
  'M60078 Infective myositis, unspecified toe(s)',
  'M6008 Infective myositis, other site',
  'M6009 Infective myositis, multiple sites',
  'M6010 Interstitial myositis of unspecified site',
  'M60111 Interstitial myositis, right shoulder',
  'M60112 Interstitial myositis, left shoulder',
  'M60119 Interstitial myositis, unspecified shoulder',
  'M60121 Interstitial myositis, right upper arm',
  'M60122 Interstitial myositis, left upper arm',
  'M60129 Interstitial myositis, unspecified upper arm',
  'M60131 Interstitial myositis, right forearm',
  'M60132 Interstitial myositis, left forearm',
  'M60139 Interstitial myositis, unspecified forearm',
  'M60141 Interstitial myositis, right hand',
  'M60142 Interstitial myositis, left hand',
  'M60149 Interstitial myositis, unspecified hand',
  'M60151 Interstitial myositis, right thigh',
  'M60152 Interstitial myositis, left thigh',
  'M60159 Interstitial myositis, unspecified thigh',
  'M60161 Interstitial myositis, right lower leg',
  'M60162 Interstitial myositis, left lower leg',
  'M60169 Interstitial myositis, unspecified lower leg',
  'M60171 Interstitial myositis, right ankle and foot',
  'M60172 Interstitial myositis, left ankle and foot',
  'M60179 Interstitial myositis, unspecified ankle and foot',
  'M6018 Interstitial myositis, other site',
  'M6019 Interstitial myositis, multiple sites',
  'M6020 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified site',
  'M60211 Foreign body granuloma of soft tissue, not elsewhere classified, right shoulder',
  'M60212 Foreign body granuloma of soft tissue, not elsewhere classified, left shoulder',
  'M60219 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified shoulder',
  'M60221 Foreign body granuloma of soft tissue, not elsewhere classified, right upper arm',
  'M60222 Foreign body granuloma of soft tissue, not elsewhere classified, left upper arm',
  'M60229 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified upper arm',
  'M60231 Foreign body granuloma of soft tissue, not elsewhere classified, right forearm',
  'M60232 Foreign body granuloma of soft tissue, not elsewhere classified, left forearm',
  'M60239 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified forearm',
  'M60241 Foreign body granuloma of soft tissue, not elsewhere classified, right hand',
  'M60242 Foreign body granuloma of soft tissue, not elsewhere classified, left hand',
  'M60249 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified hand',
  'M60251 Foreign body granuloma of soft tissue, not elsewhere classified, right thigh',
  'M60252 Foreign body granuloma of soft tissue, not elsewhere classified, left thigh',
  'M60259 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified thigh',
  'M60261 Foreign body granuloma of soft tissue, not elsewhere classified, right lower leg',
  'M60262 Foreign body granuloma of soft tissue, not elsewhere classified, left lower leg',
  'M60269 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified lower leg',
  'M60271 Foreign body granuloma of soft tissue, not elsewhere classified, right ankle and foot',
  'M60272 Foreign body granuloma of soft tissue, not elsewhere classified, left ankle and foot',
  'M60279 Foreign body granuloma of soft tissue, not elsewhere classified, unspecified ankle and foot',
  'M6028 Foreign body granuloma of soft tissue, not elsewhere classified, other site',
  'M6080 Other myositis, unspecified site',
  'M60811 Other myositis, right shoulder',
  'M60812 Other myositis, left shoulder',
  'M60819 Other myositis, unspecified shoulder',
  'M60821 Other myositis, right upper arm',
  'M60822 Other myositis, left upper arm',
  'M60829 Other myositis, unspecified upper arm',
  'M60831 Other myositis, right forearm',
  'M60832 Other myositis, left forearm',
  'M60839 Other myositis, unspecified forearm',
  'M60841 Other myositis, right hand',
  'M60842 Other myositis, left hand',
  'M60849 Other myositis, unspecified hand',
  'M60851 Other myositis, right thigh',
  'M60852 Other myositis, left thigh',
  'M60859 Other myositis, unspecified thigh',
  'M60861 Other myositis, right lower leg',
  'M60862 Other myositis, left lower leg',
  'M60869 Other myositis, unspecified lower leg',
  'M60871 Other myositis, right ankle and foot',
  'M60872 Other myositis, left ankle and foot',
  'M60879 Other myositis, unspecified ankle and foot',
  'M6088 Other myositis, other site',
  'M6089 Other myositis, multiple sites',
  'M609 Myositis, unspecified',
  'M6100 Myositis ossificans traumatica, unspecified site',
  'M61011 Myositis ossificans traumatica, right shoulder',
  'M61012 Myositis ossificans traumatica, left shoulder',
  'M61019 Myositis ossificans traumatica, unspecified shoulder',
  'M61021 Myositis ossificans traumatica, right upper arm',
  'M61022 Myositis ossificans traumatica, left upper arm',
  'M61029 Myositis ossificans traumatica, unspecified upper arm',
  'M61031 Myositis ossificans traumatica, right forearm',
  'M61032 Myositis ossificans traumatica, left forearm',
  'M61039 Myositis ossificans traumatica, unspecified forearm',
  'M61041 Myositis ossificans traumatica, right hand',
  'M61042 Myositis ossificans traumatica, left hand',
  'M61049 Myositis ossificans traumatica, unspecified hand',
  'M61051 Myositis ossificans traumatica, right thigh',
  'M61052 Myositis ossificans traumatica, left thigh',
  'M61059 Myositis ossificans traumatica, unspecified thigh',
  'M61061 Myositis ossificans traumatica, right lower leg',
  'M61062 Myositis ossificans traumatica, left lower leg',
  'M61069 Myositis ossificans traumatica, unspecified lower leg',
  'M61071 Myositis ossificans traumatica, right ankle and foot',
  'M61072 Myositis ossificans traumatica, left ankle and foot',
  'M61079 Myositis ossificans traumatica, unspecified ankle and foot',
  'M6108 Myositis ossificans traumatica, other site',
  'M6109 Myositis ossificans traumatica, multiple sites',
  'M6110 Myositis ossificans progressiva, unspecified site',
  'M61111 Myositis ossificans progressiva, right shoulder',
  'M61112 Myositis ossificans progressiva, left shoulder',
  'M61119 Myositis ossificans progressiva, unspecified shoulder',
  'M61121 Myositis ossificans progressiva, right upper arm',
  'M61122 Myositis ossificans progressiva, left upper arm',
  'M61129 Myositis ossificans progressiva, unspecified arm',
  'M61131 Myositis ossificans progressiva, right forearm',
  'M61132 Myositis ossificans progressiva, left forearm',
  'M61139 Myositis ossificans progressiva, unspecified forearm',
  'M61141 Myositis ossificans progressiva, right hand',
  'M61142 Myositis ossificans progressiva, left hand',
  'M61143 Myositis ossificans progressiva, unspecified hand',
  'M61144 Myositis ossificans progressiva, right finger(s)',
  'M61145 Myositis ossificans progressiva, left finger(s)',
  'M61146 Myositis ossificans progressiva, unspecified finger(s)',
  'M61151 Myositis ossificans progressiva, right thigh',
  'M61152 Myositis ossificans progressiva, left thigh',
  'M61159 Myositis ossificans progressiva, unspecified thigh',
  'M61161 Myositis ossificans progressiva, right lower leg',
  'M61162 Myositis ossificans progressiva, left lower leg',
  'M61169 Myositis ossificans progressiva, unspecified lower leg',
  'M61171 Myositis ossificans progressiva, right ankle',
  'M61172 Myositis ossificans progressiva, left ankle',
  'M61173 Myositis ossificans progressiva, unspecified ankle',
  'M61174 Myositis ossificans progressiva, right foot',
  'M61175 Myositis ossificans progressiva, left foot',
  'M61176 Myositis ossificans progressiva, unspecified foot',
  'M61177 Myositis ossificans progressiva, right toe(s)',
  'M61178 Myositis ossificans progressiva, left toe(s)',
  'M61179 Myositis ossificans progressiva, unspecified toe(s)',
  'M6118 Myositis ossificans progressiva, other site',
  'M6119 Myositis ossificans progressiva, multiple sites',
  'M6120 Paralytic calcification and ossification of muscle, unspecified site',
  'M61211 Paralytic calcification and ossification of muscle, right shoulder',
  'M61212 Paralytic calcification and ossification of muscle, left shoulder',
  'M61219 Paralytic calcification and ossification of muscle, unspecified shoulder',
  'M61221 Paralytic calcification and ossification of muscle, right upper arm',
  'M61222 Paralytic calcification and ossification of muscle, left upper arm',
  'M61229 Paralytic calcification and ossification of muscle, unspecified upper arm',
  'M61231 Paralytic calcification and ossification of muscle, right forearm',
  'M61232 Paralytic calcification and ossification of muscle, left forearm',
  'M61239 Paralytic calcification and ossification of muscle, unspecified forearm',
  'M61241 Paralytic calcification and ossification of muscle, right hand',
  'M61242 Paralytic calcification and ossification of muscle, left hand',
  'M61249 Paralytic calcification and ossification of muscle, unspecified hand',
  'M61251 Paralytic calcification and ossification of muscle, right thigh',
  'M61252 Paralytic calcification and ossification of muscle, left thigh',
  'M61259 Paralytic calcification and ossification of muscle, unspecified thigh',
  'M61261 Paralytic calcification and ossification of muscle, right lower leg',
  'M61262 Paralytic calcification and ossification of muscle, left lower leg',
  'M61269 Paralytic calcification and ossification of muscle, unspecified lower leg',
  'M61271 Paralytic calcification and ossification of muscle, right ankle and foot',
  'M61272 Paralytic calcification and ossification of muscle, left ankle and foot',
  'M61279 Paralytic calcification and ossification of muscle, unspecified ankle and foot',
  'M6128 Paralytic calcification and ossification of muscle, other site',
  'M6129 Paralytic calcification and ossification of muscle, multiple sites',
  'M6130 Calcification and ossification of muscles associated with burns, unspecified site',
  'M61311 Calcification and ossification of muscles associated with burns, right shoulder',
  'M61312 Calcification and ossification of muscles associated with burns, left shoulder',
  'M61319 Calcification and ossification of muscles associated with burns, unspecified shoulder',
  'M61321 Calcification and ossification of muscles associated with burns, right upper arm',
  'M61322 Calcification and ossification of muscles associated with burns, left upper arm',
  'M61329 Calcification and ossification of muscles associated with burns, unspecified upper arm',
  'M61331 Calcification and ossification of muscles associated with burns, right forearm',
  'M61332 Calcification and ossification of muscles associated with burns, left forearm',
  'M61339 Calcification and ossification of muscles associated with burns, unspecified forearm',
  'M61341 Calcification and ossification of muscles associated with burns, right hand',
  'M61342 Calcification and ossification of muscles associated with burns, left hand',
  'M61349 Calcification and ossification of muscles associated with burns, unspecified hand',
  'M61351 Calcification and ossification of muscles associated with burns, right thigh',
  'M61352 Calcification and ossification of muscles associated with burns, left thigh',
  'M61359 Calcification and ossification of muscles associated with burns, unspecified thigh',
  'M61361 Calcification and ossification of muscles associated with burns, right lower leg',
  'M61362 Calcification and ossification of muscles associated with burns, left lower leg',
  'M61369 Calcification and ossification of muscles associated with burns, unspecified lower leg',
  'M61371 Calcification and ossification of muscles associated with burns, right ankle and foot',
  'M61372 Calcification and ossification of muscles associated with burns, left ankle and foot',
  'M61379 Calcification and ossification of muscles associated with burns, unspecified ankle and foot',
  'M6138 Calcification and ossification of muscles associated with burns, other site',
  'M6139 Calcification and ossification of muscles associated with burns, multiple sites',
  'M6140 Other calcification of muscle, unspecified site',
  'M61411 Other calcification of muscle, right shoulder',
  'M61412 Other calcification of muscle, left shoulder',
  'M61419 Other calcification of muscle, unspecified shoulder',
  'M61421 Other calcification of muscle, right upper arm',
  'M61422 Other calcification of muscle, left upper arm',
  'M61429 Other calcification of muscle, unspecified upper arm',
  'M61431 Other calcification of muscle, right forearm',
  'M61432 Other calcification of muscle, left forearm',
  'M61439 Other calcification of muscle, unspecified forearm',
  'M61441 Other calcification of muscle, right hand',
  'M61442 Other calcification of muscle, left hand',
  'M61449 Other calcification of muscle, unspecified hand',
  'M61451 Other calcification of muscle, right thigh',
  'M61452 Other calcification of muscle, left thigh',
  'M61459 Other calcification of muscle, unspecified thigh',
  'M61461 Other calcification of muscle, right lower leg',
  'M61462 Other calcification of muscle, left lower leg',
  'M61469 Other calcification of muscle, unspecified lower leg',
  'M61471 Other calcification of muscle, right ankle and foot',
  'M61472 Other calcification of muscle, left ankle and foot',
  'M61479 Other calcification of muscle, unspecified ankle and foot',
  'M6148 Other calcification of muscle, other site',
  'M6149 Other calcification of muscle, multiple sites',
  'M6150 Other ossification of muscle, unspecified site',
  'M61511 Other ossification of muscle, right shoulder',
  'M61512 Other ossification of muscle, left shoulder',
  'M61519 Other ossification of muscle, unspecified shoulder',
  'M61521 Other ossification of muscle, right upper arm',
  'M61522 Other ossification of muscle, left upper arm',
  'M61529 Other ossification of muscle, unspecified upper arm',
  'M61531 Other ossification of muscle, right forearm',
  'M61532 Other ossification of muscle, left forearm',
  'M61539 Other ossification of muscle, unspecified forearm',
  'M61541 Other ossification of muscle, right hand',
  'M61542 Other ossification of muscle, left hand',
  'M61549 Other ossification of muscle, unspecified hand',
  'M61551 Other ossification of muscle, right thigh',
  'M61552 Other ossification of muscle, left thigh',
  'M61559 Other ossification of muscle, unspecified thigh',
  'M61561 Other ossification of muscle, right lower leg',
  'M61562 Other ossification of muscle, left lower leg',
  'M61569 Other ossification of muscle, unspecified lower leg',
  'M61571 Other ossification of muscle, right ankle and foot',
  'M61572 Other ossification of muscle, left ankle and foot',
  'M61579 Other ossification of muscle, unspecified ankle and foot',
  'M6158 Other ossification of muscle, other site',
  'M6159 Other ossification of muscle, multiple sites',
  'M619 Calcification and ossification of muscle, unspecified',
  'M6200 Separation of muscle (nontraumatic), unspecified site',
  'M62011 Separation of muscle (nontraumatic), right shoulder',
  'M62012 Separation of muscle (nontraumatic), left shoulder',
  'M62019 Separation of muscle (nontraumatic), unspecified shoulder',
  'M62021 Separation of muscle (nontraumatic), right upper arm',
  'M62022 Separation of muscle (nontraumatic), left upper arm',
  'M62029 Separation of muscle (nontraumatic), unspecified upper arm',
  'M62031 Separation of muscle (nontraumatic), right forearm',
  'M62032 Separation of muscle (nontraumatic), left forearm',
  'M62039 Separation of muscle (nontraumatic), unspecified forearm',
  'M62041 Separation of muscle (nontraumatic), right hand',
  'M62042 Separation of muscle (nontraumatic), left hand',
  'M62049 Separation of muscle (nontraumatic), unspecified hand',
  'M62051 Separation of muscle (nontraumatic), right thigh',
  'M62052 Separation of muscle (nontraumatic), left thigh',
  'M62059 Separation of muscle (nontraumatic), unspecified thigh',
  'M62061 Separation of muscle (nontraumatic), right lower leg',
  'M62062 Separation of muscle (nontraumatic), left lower leg',
  'M62069 Separation of muscle (nontraumatic), unspecified lower leg',
  'M62071 Separation of muscle (nontraumatic), right ankle and foot',
  'M62072 Separation of muscle (nontraumatic), left ankle and foot',
  'M62079 Separation of muscle (nontraumatic), unspecified ankle and foot',
  'M6208 Separation of muscle (nontraumatic), other site',
  'M6210 Other rupture of muscle (nontraumatic), unspecified site',
  'M62111 Other rupture of muscle (nontraumatic), right shoulder',
  'M62112 Other rupture of muscle (nontraumatic), left shoulder',
  'M62119 Other rupture of muscle (nontraumatic), unspecified shoulder',
  'M62121 Other rupture of muscle (nontraumatic), right upper arm',
  'M62122 Other rupture of muscle (nontraumatic), left upper arm',
  'M62129 Other rupture of muscle (nontraumatic), unspecified upper arm',
  'M62131 Other rupture of muscle (nontraumatic), right forearm',
  'M62132 Other rupture of muscle (nontraumatic), left forearm',
  'M62139 Other rupture of muscle (nontraumatic), unspecified forearm',
  'M62141 Other rupture of muscle (nontraumatic), right hand',
  'M62142 Other rupture of muscle (nontraumatic), left hand',
  'M62149 Other rupture of muscle (nontraumatic), unspecified hand',
  'M62151 Other rupture of muscle (nontraumatic), right thigh',
  'M62152 Other rupture of muscle (nontraumatic), left thigh',
  'M62159 Other rupture of muscle (nontraumatic), unspecified thigh',
  'M62161 Other rupture of muscle (nontraumatic), right lower leg',
  'M62162 Other rupture of muscle (nontraumatic), left lower leg',
  'M62169 Other rupture of muscle (nontraumatic), unspecified lower leg',
  'M62171 Other rupture of muscle (nontraumatic), right ankle and foot',
  'M62172 Other rupture of muscle (nontraumatic), left ankle and foot',
  'M62179 Other rupture of muscle (nontraumatic), unspecified ankle and foot',
  'M6218 Other rupture of muscle (nontraumatic), other site',
  'M6220 Nontraumatic ischemic infarction of muscle, unspecified site',
  'M62211 Nontraumatic ischemic infarction of muscle, right shoulder',
  'M62212 Nontraumatic ischemic infarction of muscle, left shoulder',
  'M62219 Nontraumatic ischemic infarction of muscle, unspecified shoulder',
  'M62221 Nontraumatic ischemic infarction of muscle, right upper arm',
  'M62222 Nontraumatic ischemic infarction of muscle, left upper arm',
  'M62229 Nontraumatic ischemic infarction of muscle, unspecified upper arm',
  'M62231 Nontraumatic ischemic infarction of muscle, right forearm',
  'M62232 Nontraumatic ischemic infarction of muscle, left forearm',
  'M62239 Nontraumatic ischemic infarction of muscle, unspecified forearm',
  'M62241 Nontraumatic ischemic infarction of muscle, right hand',
  'M62242 Nontraumatic ischemic infarction of muscle, left hand',
  'M62249 Nontraumatic ischemic infarction of muscle, unspecified hand',
  'M62251 Nontraumatic ischemic infarction of muscle, right thigh',
  'M62252 Nontraumatic ischemic infarction of muscle, left thigh',
  'M62259 Nontraumatic ischemic infarction of muscle, unspecified thigh',
  'M62261 Nontraumatic ischemic infarction of muscle, right lower leg',
  'M62262 Nontraumatic ischemic infarction of muscle, left lower leg',
  'M62269 Nontraumatic ischemic infarction of muscle, unspecified lower leg',
  'M62271 Nontraumatic ischemic infarction of muscle, right ankle and foot',
  'M62272 Nontraumatic ischemic infarction of muscle, left ankle and foot',
  'M62279 Nontraumatic ischemic infarction of muscle, unspecified ankle and foot',
  'M6228 Nontraumatic ischemic infarction of muscle, other site',
  'M623 Immobility syndrome (paraplegic)',
  'M6240 Contracture of muscle, unspecified site',
  'M62411 Contracture of muscle, right shoulder',
  'M62412 Contracture of muscle, left shoulder',
  'M62419 Contracture of muscle, unspecified shoulder',
  'M62421 Contracture of muscle, right upper arm',
  'M62422 Contracture of muscle, left upper arm',
  'M62429 Contracture of muscle, unspecified upper arm',
  'M62431 Contracture of muscle, right forearm',
  'M62432 Contracture of muscle, left forearm',
  'M62439 Contracture of muscle, unspecified forearm',
  'M62441 Contracture of muscle, right hand',
  'M62442 Contracture of muscle, left hand',
  'M62449 Contracture of muscle, unspecified hand',
  'M62451 Contracture of muscle, right thigh',
  'M62452 Contracture of muscle, left thigh',
  'M62459 Contracture of muscle, unspecified thigh',
  'M62461 Contracture of muscle, right lower leg',
  'M62462 Contracture of muscle, left lower leg',
  'M62469 Contracture of muscle, unspecified lower leg',
  'M62471 Contracture of muscle, right ankle and foot',
  'M62472 Contracture of muscle, left ankle and foot',
  'M62479 Contracture of muscle, unspecified ankle and foot',
  'M6248 Contracture of muscle, other site',
  'M6249 Contracture of muscle, multiple sites',
  'M6250 Muscle wasting and atrophy, not elsewhere classified, unspecified site',
  'M62511 Muscle wasting and atrophy, not elsewhere classified, right shoulder',
  'M62512 Muscle wasting and atrophy, not elsewhere classified, left shoulder',
  'M62519 Muscle wasting and atrophy, not elsewhere classified, unspecified shoulder',
  'M62521 Muscle wasting and atrophy, not elsewhere classified, right upper arm',
  'M62522 Muscle wasting and atrophy, not elsewhere classified, left upper arm',
  'M62529 Muscle wasting and atrophy, not elsewhere classified, unspecified upper arm',
  'M62531 Muscle wasting and atrophy, not elsewhere classified, right forearm',
  'M62532 Muscle wasting and atrophy, not elsewhere classified, left forearm',
  'M62539 Muscle wasting and atrophy, not elsewhere classified, unspecified forearm',
  'M62541 Muscle wasting and atrophy, not elsewhere classified, right hand',
  'M62542 Muscle wasting and atrophy, not elsewhere classified, left hand',
  'M62549 Muscle wasting and atrophy, not elsewhere classified, unspecified hand',
  'M62551 Muscle wasting and atrophy, not elsewhere classified, right thigh',
  'M62552 Muscle wasting and atrophy, not elsewhere classified, left thigh',
  'M62559 Muscle wasting and atrophy, not elsewhere classified, unspecified thigh',
  'M62561 Muscle wasting and atrophy, not elsewhere classified, right lower leg',
  'M62562 Muscle wasting and atrophy, not elsewhere classified, left lower leg',
  'M62569 Muscle wasting and atrophy, not elsewhere classified, unspecified lower leg',
  'M62571 Muscle wasting and atrophy, not elsewhere classified, right ankle and foot',
  'M62572 Muscle wasting and atrophy, not elsewhere classified, left ankle and foot',
  'M62579 Muscle wasting and atrophy, not elsewhere classified, unspecified ankle and foot',
  'M6258 Muscle wasting and atrophy, not elsewhere classified, other site',
  'M6259 Muscle wasting and atrophy, not elsewhere classified, multiple sites',
  'M6281 Muscle weakness (generalized)',
  'M6282 Rhabdomyolysis',
  'M62830 Muscle spasm of back',
  'M62831 Muscle spasm of calf',
  'M62838 Other muscle spasm',
  'M6284 Sarcopenia',
  'M6289 Other specified disorders of muscle',
  'M629 Disorder of muscle, unspecified',
  'M6380 Disorders of muscle in diseases classified elsewhere, unspecified site',
  'M63811 Disorders of muscle in diseases classified elsewhere, right shoulder',
  'M63812 Disorders of muscle in diseases classified elsewhere, left shoulder',
  'M63819 Disorders of muscle in diseases classified elsewhere, unspecified shoulder',
  'M63821 Disorders of muscle in diseases classified elsewhere, right upper arm',
  'M63822 Disorders of muscle in diseases classified elsewhere, left upper arm',
  'M63829 Disorders of muscle in diseases classified elsewhere, unspecified upper arm',
  'M63831 Disorders of muscle in diseases classified elsewhere, right forearm',
  'M63832 Disorders of muscle in diseases classified elsewhere, left forearm',
  'M63839 Disorders of muscle in diseases classified elsewhere, unspecified forearm',
  'M63841 Disorders of muscle in diseases classified elsewhere, right hand',
  'M63842 Disorders of muscle in diseases classified elsewhere, left hand',
  'M63849 Disorders of muscle in diseases classified elsewhere, unspecified hand',
  'M63851 Disorders of muscle in diseases classified elsewhere, right thigh',
  'M63852 Disorders of muscle in diseases classified elsewhere, left thigh',
  'M63859 Disorders of muscle in diseases classified elsewhere, unspecified thigh',
  'M63861 Disorders of muscle in diseases classified elsewhere, right lower leg',
  'M63862 Disorders of muscle in diseases classified elsewhere, left lower leg',
  'M63869 Disorders of muscle in diseases classified elsewhere, unspecified lower leg',
  'M63871 Disorders of muscle in diseases classified elsewhere, right ankle and foot',
  'M63872 Disorders of muscle in diseases classified elsewhere, left ankle and foot',
  'M63879 Disorders of muscle in diseases classified elsewhere, unspecified ankle and foot',
  'M6388 Disorders of muscle in diseases classified elsewhere, other site',
  'M6389 Disorders of muscle in diseases classified elsewhere, multiple sites',
  'M6500 Abscess of tendon sheath, unspecified site',
  'M65011 Abscess of tendon sheath, right shoulder',
  'M65012 Abscess of tendon sheath, left shoulder',
  'M65019 Abscess of tendon sheath, unspecified shoulder',
  'M65021 Abscess of tendon sheath, right upper arm',
  'M65022 Abscess of tendon sheath, left upper arm',
  'M65029 Abscess of tendon sheath, unspecified upper arm',
  'M65031 Abscess of tendon sheath, right forearm',
  'M65032 Abscess of tendon sheath, left forearm',
  'M65039 Abscess of tendon sheath, unspecified forearm',
  'M65041 Abscess of tendon sheath, right hand',
  'M65042 Abscess of tendon sheath, left hand',
  'M65049 Abscess of tendon sheath, unspecified hand',
  'M65051 Abscess of tendon sheath, right thigh',
  'M65052 Abscess of tendon sheath, left thigh',
  'M65059 Abscess of tendon sheath, unspecified thigh',
  'M65061 Abscess of tendon sheath, right lower leg',
  'M65062 Abscess of tendon sheath, left lower leg',
  'M65069 Abscess of tendon sheath, unspecified lower leg',
  'M65071 Abscess of tendon sheath, right ankle and foot',
  'M65072 Abscess of tendon sheath, left ankle and foot',
  'M65079 Abscess of tendon sheath, unspecified ankle and foot',
  'M6508 Abscess of tendon sheath, other site',
  'M6510 Other infective (teno)synovitis, unspecified site',
  'M65111 Other infective (teno)synovitis, right shoulder',
  'M65112 Other infective (teno)synovitis, left shoulder',
  'M65119 Other infective (teno)synovitis, unspecified shoulder',
  'M65121 Other infective (teno)synovitis, right elbow',
  'M65122 Other infective (teno)synovitis, left elbow',
  'M65129 Other infective (teno)synovitis, unspecified elbow',
  'M65131 Other infective (teno)synovitis, right wrist',
  'M65132 Other infective (teno)synovitis, left wrist',
  'M65139 Other infective (teno)synovitis, unspecified wrist',
  'M65141 Other infective (teno)synovitis, right hand',
  'M65142 Other infective (teno)synovitis, left hand',
  'M65149 Other infective (teno)synovitis, unspecified hand',
  'M65151 Other infective (teno)synovitis, right hip',
  'M65152 Other infective (teno)synovitis, left hip',
  'M65159 Other infective (teno)synovitis, unspecified hip',
  'M65161 Other infective (teno)synovitis, right knee',
  'M65162 Other infective (teno)synovitis, left knee',
  'M65169 Other infective (teno)synovitis, unspecified knee',
  'M65171 Other infective (teno)synovitis, right ankle and foot',
  'M65172 Other infective (teno)synovitis, left ankle and foot',
  'M65179 Other infective (teno)synovitis, unspecified ankle and foot',
  'M6518 Other infective (teno)synovitis, other site',
  'M6519 Other infective (teno)synovitis, multiple sites',
  'M6520 Calcific tendinitis, unspecified site',
  'M65221 Calcific tendinitis, right upper arm',
  'M65222 Calcific tendinitis, left upper arm',
  'M65229 Calcific tendinitis, unspecified upper arm',
  'M65231 Calcific tendinitis, right forearm',
  'M65232 Calcific tendinitis, left forearm',
  'M65239 Calcific tendinitis, unspecified forearm',
  'M65241 Calcific tendinitis, right hand',
  'M65242 Calcific tendinitis, left hand',
  'M65249 Calcific tendinitis, unspecified hand',
  'M65251 Calcific tendinitis, right thigh',
  'M65252 Calcific tendinitis, left thigh',
  'M65259 Calcific tendinitis, unspecified thigh',
  'M65261 Calcific tendinitis, right lower leg',
  'M65262 Calcific tendinitis, left lower leg',
  'M65269 Calcific tendinitis, unspecified lower leg',
  'M65271 Calcific tendinitis, right ankle and foot',
  'M65272 Calcific tendinitis, left ankle and foot',
  'M65279 Calcific tendinitis, unspecified ankle and foot',
  'M6528 Calcific tendinitis, other site',
  'M6529 Calcific tendinitis, multiple sites',
  'M6530 Trigger finger, unspecified finger',
  'M65311 Trigger thumb, right thumb',
  'M65312 Trigger thumb, left thumb',
  'M65319 Trigger thumb, unspecified thumb',
  'M65321 Trigger finger, right index finger',
  'M65322 Trigger finger, left index finger',
  'M65329 Trigger finger, unspecified index finger',
  'M65331 Trigger finger, right middle finger',
  'M65332 Trigger finger, left middle finger',
  'M65339 Trigger finger, unspecified middle finger',
  'M65341 Trigger finger, right ring finger',
  'M65342 Trigger finger, left ring finger',
  'M65349 Trigger finger, unspecified ring finger',
  'M65351 Trigger finger, right little finger',
  'M65352 Trigger finger, left little finger',
  'M65359 Trigger finger, unspecified little finger',
  'M654 Radial styloid tenosynovitis [de Quervain]',
  'M6580 Other synovitis and tenosynovitis, unspecified site',
  'M65811 Other synovitis and tenosynovitis, right shoulder',
  'M65812 Other synovitis and tenosynovitis, left shoulder',
  'M65819 Other synovitis and tenosynovitis, unspecified shoulder',
  'M65821 Other synovitis and tenosynovitis, right upper arm',
  'M65822 Other synovitis and tenosynovitis, left upper arm',
  'M65829 Other synovitis and tenosynovitis, unspecified upper arm',
  'M65831 Other synovitis and tenosynovitis, right forearm',
  'M65832 Other synovitis and tenosynovitis, left forearm',
  'M65839 Other synovitis and tenosynovitis, unspecified forearm',
  'M65841 Other synovitis and tenosynovitis, right hand',
  'M65842 Other synovitis and tenosynovitis, left hand',
  'M65849 Other synovitis and tenosynovitis, unspecified hand',
  'M65851 Other synovitis and tenosynovitis, right thigh',
  'M65852 Other synovitis and tenosynovitis, left thigh',
  'M65859 Other synovitis and tenosynovitis, unspecified thigh',
  'M65861 Other synovitis and tenosynovitis, right lower leg',
  'M65862 Other synovitis and tenosynovitis, left lower leg',
  'M65869 Other synovitis and tenosynovitis, unspecified lower leg',
  'M65871 Other synovitis and tenosynovitis, right ankle and foot',
  'M65872 Other synovitis and tenosynovitis, left ankle and foot',
  'M65879 Other synovitis and tenosynovitis, unspecified ankle and foot',
  'M6588 Other synovitis and tenosynovitis, other site',
  'M6589 Other synovitis and tenosynovitis, multiple sites',
  'M659 Synovitis and tenosynovitis, unspecified',
  'M660 Rupture of popliteal cyst',
  'M6610 Rupture of synovium, unspecified joint',
  'M66111 Rupture of synovium, right shoulder',
  'M66112 Rupture of synovium, left shoulder',
  'M66119 Rupture of synovium, unspecified shoulder',
  'M66121 Rupture of synovium, right elbow',
  'M66122 Rupture of synovium, left elbow',
  'M66129 Rupture of synovium, unspecified elbow',
  'M66131 Rupture of synovium, right wrist',
  'M66132 Rupture of synovium, left wrist',
  'M66139 Rupture of synovium, unspecified wrist',
  'M66141 Rupture of synovium, right hand',
  'M66142 Rupture of synovium, left hand',
  'M66143 Rupture of synovium, unspecified hand',
  'M66144 Rupture of synovium, right finger(s)',
  'M66145 Rupture of synovium, left finger(s)',
  'M66146 Rupture of synovium, unspecified finger(s)',
  'M66151 Rupture of synovium, right hip',
  'M66152 Rupture of synovium, left hip',
  'M66159 Rupture of synovium, unspecified hip',
  'M66171 Rupture of synovium, right ankle',
  'M66172 Rupture of synovium, left ankle',
  'M66173 Rupture of synovium, unspecified ankle',
  'M66174 Rupture of synovium, right foot',
  'M66175 Rupture of synovium, left foot',
  'M66176 Rupture of synovium, unspecified foot',
  'M66177 Rupture of synovium, right toe(s)',
  'M66178 Rupture of synovium, left toe(s)',
  'M66179 Rupture of synovium, unspecified toe(s)',
  'M6618 Rupture of synovium, other site',
  'M6620 Spontaneous rupture of extensor tendons, unspecified site',
  'M66211 Spontaneous rupture of extensor tendons, right shoulder',
  'M66212 Spontaneous rupture of extensor tendons, left shoulder',
  'M66219 Spontaneous rupture of extensor tendons, unspecified shoulder',
  'M66221 Spontaneous rupture of extensor tendons, right upper arm',
  'M66222 Spontaneous rupture of extensor tendons, left upper arm',
  'M66229 Spontaneous rupture of extensor tendons, unspecified upper arm',
  'M66231 Spontaneous rupture of extensor tendons, right forearm',
  'M66232 Spontaneous rupture of extensor tendons, left forearm',
  'M66239 Spontaneous rupture of extensor tendons, unspecified forearm',
  'M66241 Spontaneous rupture of extensor tendons, right hand',
  'M66242 Spontaneous rupture of extensor tendons, left hand',
  'M66249 Spontaneous rupture of extensor tendons, unspecified hand',
  'M66251 Spontaneous rupture of extensor tendons, right thigh',
  'M66252 Spontaneous rupture of extensor tendons, left thigh',
  'M66259 Spontaneous rupture of extensor tendons, unspecified thigh',
  'M66261 Spontaneous rupture of extensor tendons, right lower leg',
  'M66262 Spontaneous rupture of extensor tendons, left lower leg',
  'M66269 Spontaneous rupture of extensor tendons, unspecified lower leg',
  'M66271 Spontaneous rupture of extensor tendons, right ankle and foot',
  'M66272 Spontaneous rupture of extensor tendons, left ankle and foot',
  'M66279 Spontaneous rupture of extensor tendons, unspecified ankle and foot',
  'M6628 Spontaneous rupture of extensor tendons, other site',
  'M6629 Spontaneous rupture of extensor tendons, multiple sites',
  'M6630 Spontaneous rupture of flexor tendons, unspecified site',
  'M66311 Spontaneous rupture of flexor tendons, right shoulder',
  'M66312 Spontaneous rupture of flexor tendons, left shoulder',
  'M66319 Spontaneous rupture of flexor tendons, unspecified shoulder',
  'M66321 Spontaneous rupture of flexor tendons, right upper arm',
  'M66322 Spontaneous rupture of flexor tendons, left upper arm',
  'M66329 Spontaneous rupture of flexor tendons, unspecified upper arm',
  'M66331 Spontaneous rupture of flexor tendons, right forearm',
  'M66332 Spontaneous rupture of flexor tendons, left forearm',
  'M66339 Spontaneous rupture of flexor tendons, unspecified forearm',
  'M66341 Spontaneous rupture of flexor tendons, right hand',
  'M66342 Spontaneous rupture of flexor tendons, left hand',
  'M66349 Spontaneous rupture of flexor tendons, unspecified hand',
  'M66351 Spontaneous rupture of flexor tendons, right thigh',
  'M66352 Spontaneous rupture of flexor tendons, left thigh',
  'M66359 Spontaneous rupture of flexor tendons, unspecified thigh',
  'M66361 Spontaneous rupture of flexor tendons, right lower leg',
  'M66362 Spontaneous rupture of flexor tendons, left lower leg',
  'M66369 Spontaneous rupture of flexor tendons, unspecified lower leg',
  'M66371 Spontaneous rupture of flexor tendons, right ankle and foot',
  'M66372 Spontaneous rupture of flexor tendons, left ankle and foot',
  'M66379 Spontaneous rupture of flexor tendons, unspecified ankle and foot',
  'M6638 Spontaneous rupture of flexor tendons, other site',
  'M6639 Spontaneous rupture of flexor tendons, multiple sites',
  'M6680 Spontaneous rupture of other tendons, unspecified site',
  'M66811 Spontaneous rupture of other tendons, right shoulder',
  'M66812 Spontaneous rupture of other tendons, left shoulder',
  'M66819 Spontaneous rupture of other tendons, unspecified shoulder',
  'M66821 Spontaneous rupture of other tendons, right upper arm',
  'M66822 Spontaneous rupture of other tendons, left upper arm',
  'M66829 Spontaneous rupture of other tendons, unspecified upper arm',
  'M66831 Spontaneous rupture of other tendons, right forearm',
  'M66832 Spontaneous rupture of other tendons, left forearm',
  'M66839 Spontaneous rupture of other tendons, unspecified forearm',
  'M66841 Spontaneous rupture of other tendons, right hand',
  'M66842 Spontaneous rupture of other tendons, left hand',
  'M66849 Spontaneous rupture of other tendons, unspecified hand',
  'M66851 Spontaneous rupture of other tendons, right thigh',
  'M66852 Spontaneous rupture of other tendons, left thigh',
  'M66859 Spontaneous rupture of other tendons, unspecified thigh',
  'M66861 Spontaneous rupture of other tendons, right lower leg',
  'M66862 Spontaneous rupture of other tendons, left lower leg',
  'M66869 Spontaneous rupture of other tendons, unspecified lower leg',
  'M66871 Spontaneous rupture of other tendons, right ankle and foot',
  'M66872 Spontaneous rupture of other tendons, left ankle and foot',
  'M66879 Spontaneous rupture of other tendons, unspecified ankle and foot',
  'M6688 Spontaneous rupture of other tendons, other',
  'M6689 Spontaneous rupture of other tendons, multiple sites',
  'M669 Spontaneous rupture of unspecified tendon',
  'M6700 Short Achilles tendon (acquired), unspecified ankle',
  'M6701 Short Achilles tendon (acquired), right ankle',
  'M6702 Short Achilles tendon (acquired), left ankle',
  'M6720 Synovial hypertrophy, not elsewhere classified, unspecified site',
  'M67211 Synovial hypertrophy, not elsewhere classified, right shoulder',
  'M67212 Synovial hypertrophy, not elsewhere classified, left shoulder',
  'M67219 Synovial hypertrophy, not elsewhere classified, unspecified shoulder',
  'M67221 Synovial hypertrophy, not elsewhere classified, right upper arm',
  'M67222 Synovial hypertrophy, not elsewhere classified, left upper arm',
  'M67229 Synovial hypertrophy, not elsewhere classified, unspecified upper arm',
  'M67231 Synovial hypertrophy, not elsewhere classified, right forearm',
  'M67232 Synovial hypertrophy, not elsewhere classified, left forearm',
  'M67239 Synovial hypertrophy, not elsewhere classified, unspecified forearm',
  'M67241 Synovial hypertrophy, not elsewhere classified, right hand',
  'M67242 Synovial hypertrophy, not elsewhere classified, left hand',
  'M67249 Synovial hypertrophy, not elsewhere classified, unspecified hand',
  'M67251 Synovial hypertrophy, not elsewhere classified, right thigh',
  'M67252 Synovial hypertrophy, not elsewhere classified, left thigh',
  'M67259 Synovial hypertrophy, not elsewhere classified, unspecified thigh',
  'M67261 Synovial hypertrophy, not elsewhere classified, right lower leg',
  'M67262 Synovial hypertrophy, not elsewhere classified, left lower leg',
  'M67269 Synovial hypertrophy, not elsewhere classified, unspecified lower leg',
  'M67271 Synovial hypertrophy, not elsewhere classified, right ankle and foot',
  'M67272 Synovial hypertrophy, not elsewhere classified, left ankle and foot',
  'M67279 Synovial hypertrophy, not elsewhere classified, unspecified ankle and foot',
  'M6728 Synovial hypertrophy, not elsewhere classified, other site',
  'M6729 Synovial hypertrophy, not elsewhere classified, multiple sites',
  'M6730 Transient synovitis, unspecified site',
  'M67311 Transient synovitis, right shoulder',
  'M67312 Transient synovitis, left shoulder',
  'M67319 Transient synovitis, unspecified shoulder',
  'M67321 Transient synovitis, right elbow',
  'M67322 Transient synovitis, left elbow',
  'M67329 Transient synovitis, unspecified elbow',
  'M67331 Transient synovitis, right wrist',
  'M67332 Transient synovitis, left wrist',
  'M67339 Transient synovitis, unspecified wrist',
  'M67341 Transient synovitis, right hand',
  'M67342 Transient synovitis, left hand',
  'M67349 Transient synovitis, unspecified hand',
  'M67351 Transient synovitis, right hip',
  'M67352 Transient synovitis, left hip',
  'M67359 Transient synovitis, unspecified hip',
  'M67361 Transient synovitis, right knee',
  'M67362 Transient synovitis, left knee',
  'M67369 Transient synovitis, unspecified knee',
  'M67371 Transient synovitis, right ankle and foot',
  'M67372 Transient synovitis, left ankle and foot',
  'M67379 Transient synovitis, unspecified ankle and foot',
  'M6738 Transient synovitis, other site',
  'M6739 Transient synovitis, multiple sites',
  'M6740 Ganglion, unspecified site',
  'M67411 Ganglion, right shoulder',
  'M67412 Ganglion, left shoulder',
  'M67419 Ganglion, unspecified shoulder',
  'M67421 Ganglion, right elbow',
  'M67422 Ganglion, left elbow',
  'M67429 Ganglion, unspecified elbow',
  'M67431 Ganglion, right wrist',
  'M67432 Ganglion, left wrist',
  'M67439 Ganglion, unspecified wrist',
  'M67441 Ganglion, right hand',
  'M67442 Ganglion, left hand',
  'M67449 Ganglion, unspecified hand',
  'M67451 Ganglion, right hip',
  'M67452 Ganglion, left hip',
  'M67459 Ganglion, unspecified hip',
  'M67461 Ganglion, right knee',
  'M67462 Ganglion, left knee',
  'M67469 Ganglion, unspecified knee',
  'M67471 Ganglion, right ankle and foot',
  'M67472 Ganglion, left ankle and foot',
  'M67479 Ganglion, unspecified ankle and foot',
  'M6748 Ganglion, other site',
  'M6749 Ganglion, multiple sites',
  'M6750 Plica syndrome, unspecified knee',
  'M6751 Plica syndrome, right knee',
  'M6752 Plica syndrome, left knee',
  'M6780 Other specified disorders of synovium and tendon, unspecified site',
  'M67811 Other specified disorders of synovium, right shoulder',
  'M67812 Other specified disorders of synovium, left shoulder',
  'M67813 Other specified disorders of tendon, right shoulder',
  'M67814 Other specified disorders of tendon, left shoulder',
  'M67819 Other specified disorders of synovium and tendon, unspecified shoulder',
  'M67821 Other specified disorders of synovium, right elbow',
  'M67822 Other specified disorders of synovium, left elbow',
  'M67823 Other specified disorders of tendon, right elbow',
  'M67824 Other specified disorders of tendon, left elbow',
  'M67829 Other specified disorders of synovium and tendon, unspecified elbow',
  'M67831 Other specified disorders of synovium, right wrist',
  'M67832 Other specified disorders of synovium, left wrist',
  'M67833 Other specified disorders of tendon, right wrist',
  'M67834 Other specified disorders of tendon, left wrist',
  'M67839 Other specified disorders of synovium and tendon, unspecified forearm',
  'M67841 Other specified disorders of synovium, right hand',
  'M67842 Other specified disorders of synovium, left hand',
  'M67843 Other specified disorders of tendon, right hand',
  'M67844 Other specified disorders of tendon, left hand',
  'M67849 Other specified disorders of synovium and tendon, unspecified hand',
  'M67851 Other specified disorders of synovium, right hip',
  'M67852 Other specified disorders of synovium, left hip',
  'M67853 Other specified disorders of tendon, right hip',
  'M67854 Other specified disorders of tendon, left hip',
  'M67859 Other specified disorders of synovium and tendon, unspecified hip',
  'M67861 Other specified disorders of synovium, right knee',
  'M67862 Other specified disorders of synovium, left knee',
  'M67863 Other specified disorders of tendon, right knee',
  'M67864 Other specified disorders of tendon, left knee',
  'M67869 Other specified disorders of synovium and tendon, unspecified knee',
  'M67871 Other specified disorders of synovium, right ankle and foot',
  'M67872 Other specified disorders of synovium, left ankle and foot',
  'M67873 Other specified disorders of tendon, right ankle and foot',
  'M67874 Other specified disorders of tendon, left ankle and foot',
  'M67879 Other specified disorders of synovium and tendon, unspecified ankle and foot',
  'M6788 Other specified disorders of synovium and tendon, other site',
  'M6789 Other specified disorders of synovium and tendon, multiple sites',
  'M6790 Unspecified disorder of synovium and tendon, unspecified site',
  'M67911 Unspecified disorder of synovium and tendon, right shoulder',
  'M67912 Unspecified disorder of synovium and tendon, left shoulder',
  'M67919 Unspecified disorder of synovium and tendon, unspecified shoulder',
  'M67921 Unspecified disorder of synovium and tendon, right upper arm',
  'M67922 Unspecified disorder of synovium and tendon, left upper arm',
  'M67929 Unspecified disorder of synovium and tendon, unspecified upper arm',
  'M67931 Unspecified disorder of synovium and tendon, right forearm',
  'M67932 Unspecified disorder of synovium and tendon, left forearm',
  'M67939 Unspecified disorder of synovium and tendon, unspecified forearm',
  'M67941 Unspecified disorder of synovium and tendon, right hand',
  'M67942 Unspecified disorder of synovium and tendon, left hand',
  'M67949 Unspecified disorder of synovium and tendon, unspecified hand',
  'M67951 Unspecified disorder of synovium and tendon, right thigh',
  'M67952 Unspecified disorder of synovium and tendon, left thigh',
  'M67959 Unspecified disorder of synovium and tendon, unspecified thigh',
  'M67961 Unspecified disorder of synovium and tendon, right lower leg',
  'M67962 Unspecified disorder of synovium and tendon, left lower leg',
  'M67969 Unspecified disorder of synovium and tendon, unspecified lower leg',
  'M67971 Unspecified disorder of synovium and tendon, right ankle and foot',
  'M67972 Unspecified disorder of synovium and tendon, left ankle and foot',
  'M67979 Unspecified disorder of synovium and tendon, unspecified ankle and foot',
  'M6798 Unspecified disorder of synovium and tendon, other site',
  'M6799 Unspecified disorder of synovium and tendon, multiple sites',
  'M70031 Crepitant synovitis (acute) (chronic), right wrist',
  'M70032 Crepitant synovitis (acute) (chronic), left wrist',
  'M70039 Crepitant synovitis (acute) (chronic), unspecified wrist',
  'M70041 Crepitant synovitis (acute) (chronic), right hand',
  'M70042 Crepitant synovitis (acute) (chronic), left hand',
  'M70049 Crepitant synovitis (acute) (chronic), unspecified hand',
  'M7010 Bursitis, unspecified hand',
  'M7011 Bursitis, right hand',
  'M7012 Bursitis, left hand',
  'M7020 Olecranon bursitis, unspecified elbow',
  'M7021 Olecranon bursitis, right elbow',
  'M7022 Olecranon bursitis, left elbow',
  'M7030 Other bursitis of elbow, unspecified elbow',
  'M7031 Other bursitis of elbow, right elbow',
  'M7032 Other bursitis of elbow, left elbow',
  'M7040 Prepatellar bursitis, unspecified knee',
  'M7041 Prepatellar bursitis, right knee',
  'M7042 Prepatellar bursitis, left knee',
  'M7050 Other bursitis of knee, unspecified knee',
  'M7051 Other bursitis of knee, right knee',
  'M7052 Other bursitis of knee, left knee',
  'M7060 Trochanteric bursitis, unspecified hip',
  'M7061 Trochanteric bursitis, right hip',
  'M7062 Trochanteric bursitis, left hip',
  'M7070 Other bursitis of hip, unspecified hip',
  'M7071 Other bursitis of hip, right hip',
  'M7072 Other bursitis of hip, left hip',
  'M7080 Other soft tissue disorders related to use, overuse and pressure of unspecified site',
  'M70811 Other soft tissue disorders related to use, overuse and pressure, right shoulder',
  'M70812 Other soft tissue disorders related to use, overuse and pressure, left shoulder',
  'M70819 Other soft tissue disorders related to use, overuse and pressure, unspecified shoulder',
  'M70821 Other soft tissue disorders related to use, overuse and pressure, right upper arm',
  'M70822 Other soft tissue disorders related to use, overuse and pressure, left upper arm',
  'M70829 Other soft tissue disorders related to use, overuse and pressure, unspecified upper arms',
  'M70831 Other soft tissue disorders related to use, overuse and pressure, right forearm',
  'M70832 Other soft tissue disorders related to use, overuse and pressure, left forearm',
  'M70839 Other soft tissue disorders related to use, overuse and pressure, unspecified forearm',
  'M70841 Other soft tissue disorders related to use, overuse and pressure, right hand',
  'M70842 Other soft tissue disorders related to use, overuse and pressure, left hand',
  'M70849 Other soft tissue disorders related to use, overuse and pressure, unspecified hand',
  'M70851 Other soft tissue disorders related to use, overuse and pressure, right thigh',
  'M70852 Other soft tissue disorders related to use, overuse and pressure, left thigh',
  'M70859 Other soft tissue disorders related to use, overuse and pressure, unspecified thigh',
  'M70861 Other soft tissue disorders related to use, overuse and pressure, right lower leg',
  'M70862 Other soft tissue disorders related to use, overuse and pressure, left lower leg',
  'M70869 Other soft tissue disorders related to use, overuse and pressure, unspecified leg',
  'M70871 Other soft tissue disorders related to use, overuse and pressure, right ankle and foot',
  'M70872 Other soft tissue disorders related to use, overuse and pressure, left ankle and foot',
  'M70879 Other soft tissue disorders related to use, overuse and pressure, unspecified ankle and foot',
  'M7088 Other soft tissue disorders related to use, overuse and pressure other site',
  'M7089 Other soft tissue disorders related to use, overuse and pressure multiple sites',
  'M7090 Unspecified soft tissue disorder related to use, overuse and pressure of unspecified site',
  'M70911 Unspecified soft tissue disorder related to use, overuse and pressure, right shoulder',
  'M70912 Unspecified soft tissue disorder related to use, overuse and pressure, left shoulder',
  'M70919 Unspecified soft tissue disorder related to use, overuse and pressure, unspecified shoulder',
  'M70921 Unspecified soft tissue disorder related to use, overuse and pressure, right upper arm',
  'M70922 Unspecified soft tissue disorder related to use, overuse and pressure, left upper arm',
  'M70929 Unspecified soft tissue disorder related to use, overuse and pressure, unspecified upper arm',
  'M70931 Unspecified soft tissue disorder related to use, overuse and pressure, right forearm',
  'M70932 Unspecified soft tissue disorder related to use, overuse and pressure, left forearm',
  'M70939 Unspecified soft tissue disorder related to use, overuse and pressure, unspecified forearm',
  'M70941 Unspecified soft tissue disorder related to use, overuse and pressure, right hand',
  'M70942 Unspecified soft tissue disorder related to use, overuse and pressure, left hand',
  'M70949 Unspecified soft tissue disorder related to use, overuse and pressure, unspecified hand',
  'M70951 Unspecified soft tissue disorder related to use, overuse and pressure, right thigh',
  'M70952 Unspecified soft tissue disorder related to use, overuse and pressure, left thigh',
  'M70959 Unspecified soft tissue disorder related to use, overuse and pressure, unspecified thigh',
  'M70961 Unspecified soft tissue disorder related to use, overuse and pressure, right lower leg',
  'M70962 Unspecified soft tissue disorder related to use, overuse and pressure, left lower leg',
  'M70969 Unspecified soft tissue disorder related to use, overuse and pressure, unspecified lower leg',
  'M70971 Unspecified soft tissue disorder related to use, overuse and pressure, right ankle and foot',
  'M70972 Unspecified soft tissue disorder related to use, overuse and pressure, left ankle and foot',
  'M70979 Unspecified soft tissue disorder related to use, overuse and pressure, unspecified ankle and',
  'foot',
  'M7098 Unspecified soft tissue disorder related to use, overuse and pressure other',
  'M7099 Unspecified soft tissue disorder related to use, overuse and pressure multiple sites',
  'M7100 Abscess of bursa, unspecified site',
  'M71011 Abscess of bursa, right shoulder',
  'M71012 Abscess of bursa, left shoulder',
  'M71019 Abscess of bursa, unspecified shoulder',
  'M71021 Abscess of bursa, right elbow',
  'M71022 Abscess of bursa, left elbow',
  'M71029 Abscess of bursa, unspecified elbow',
  'M71031 Abscess of bursa, right wrist',
  'M71032 Abscess of bursa, left wrist',
  'M71039 Abscess of bursa, unspecified wrist',
  'M71041 Abscess of bursa, right hand',
  'M71042 Abscess of bursa, left hand',
  'M71049 Abscess of bursa, unspecified hand',
  'M71051 Abscess of bursa, right hip',
  'M71052 Abscess of bursa, left hip',
  'M71059 Abscess of bursa, unspecified hip',
  'M71061 Abscess of bursa, right knee',
  'M71062 Abscess of bursa, left knee',
  'M71069 Abscess of bursa, unspecified knee',
  'M71071 Abscess of bursa, right ankle and foot',
  'M71072 Abscess of bursa, left ankle and foot',
  'M71079 Abscess of bursa, unspecified ankle and foot',
  'M7108 Abscess of bursa, other site',
  'M7109 Abscess of bursa, multiple sites',
  'M7110 Other infective bursitis, unspecified site',
  'M71111 Other infective bursitis, right shoulder',
  'M71112 Other infective bursitis, left shoulder',
  'M71119 Other infective bursitis, unspecified shoulder',
  'M71121 Other infective bursitis, right elbow',
  'M71122 Other infective bursitis, left elbow',
  'M71129 Other infective bursitis, unspecified elbow',
  'M71131 Other infective bursitis, right wrist',
  'M71132 Other infective bursitis, left wrist',
  'M71139 Other infective bursitis, unspecified wrist',
  'M71141 Other infective bursitis, right hand',
  'M71142 Other infective bursitis, left hand',
  'M71149 Other infective bursitis, unspecified hand',
  'M71151 Other infective bursitis, right hip',
  'M71152 Other infective bursitis, left hip',
  'M71159 Other infective bursitis, unspecified hip',
  'M71161 Other infective bursitis, right knee',
  'M71162 Other infective bursitis, left knee',
  'M71169 Other infective bursitis, unspecified knee',
  'M71171 Other infective bursitis, right ankle and foot',
  'M71172 Other infective bursitis, left ankle and foot',
  'M71179 Other infective bursitis, unspecified ankle and foot',
  'M7118 Other infective bursitis, other site',
  'M7119 Other infective bursitis, multiple sites',
  'M7120 Synovial cyst of popliteal space [Baker], unspecified knee',
  'M7121 Synovial cyst of popliteal space [Baker], right knee',
  'M7122 Synovial cyst of popliteal space [Baker], left knee',
  'M7130 Other bursal cyst, unspecified site',
  'M71311 Other bursal cyst, right shoulder',
  'M71312 Other bursal cyst, left shoulder',
  'M71319 Other bursal cyst, unspecified shoulder',
  'M71321 Other bursal cyst, right elbow',
  'M71322 Other bursal cyst, left elbow',
  'M71329 Other bursal cyst, unspecified elbow',
  'M71331 Other bursal cyst, right wrist',
  'M71332 Other bursal cyst, left wrist',
  'M71339 Other bursal cyst, unspecified wrist',
  'M71341 Other bursal cyst, right hand',
  'M71342 Other bursal cyst, left hand',
  'M71349 Other bursal cyst, unspecified hand',
  'M71351 Other bursal cyst, right hip',
  'M71352 Other bursal cyst, left hip',
  'M71359 Other bursal cyst, unspecified hip',
  'M71371 Other bursal cyst, right ankle and foot',
  'M71372 Other bursal cyst, left ankle and foot',
  'M71379 Other bursal cyst, unspecified ankle and foot',
  'M7138 Other bursal cyst, other site',
  'M7139 Other bursal cyst, multiple sites',
  'M7140 Calcium deposit in bursa, unspecified site',
  'M71421 Calcium deposit in bursa, right elbow',
  'M71422 Calcium deposit in bursa, left elbow',
  'M71429 Calcium deposit in bursa, unspecified elbow',
  'M71431 Calcium deposit in bursa, right wrist',
  'M71432 Calcium deposit in bursa, left wrist',
  'M71439 Calcium deposit in bursa, unspecified wrist',
  'M71441 Calcium deposit in bursa, right hand',
  'M71442 Calcium deposit in bursa, left hand',
  'M71449 Calcium deposit in bursa, unspecified hand',
  'M71451 Calcium deposit in bursa, right hip',
  'M71452 Calcium deposit in bursa, left hip',
  'M71459 Calcium deposit in bursa, unspecified hip',
  'M71461 Calcium deposit in bursa, right knee',
  'M71462 Calcium deposit in bursa, left knee',
  'M71469 Calcium deposit in bursa, unspecified knee',
  'M71471 Calcium deposit in bursa, right ankle and foot',
  'M71472 Calcium deposit in bursa, left ankle and foot',
  'M71479 Calcium deposit in bursa, unspecified ankle and foot',
  'M7148 Calcium deposit in bursa, other site',
  'M7149 Calcium deposit in bursa, multiple sites',
  'M7150 Other bursitis, not elsewhere classified, unspecified site',
  'M71521 Other bursitis, not elsewhere classified, right elbow',
  'M71522 Other bursitis, not elsewhere classified, left elbow',
  'M71529 Other bursitis, not elsewhere classified, unspecified elbow',
  'M71531 Other bursitis, not elsewhere classified, right wrist',
  'M71532 Other bursitis, not elsewhere classified, left wrist',
  'M71539 Other bursitis, not elsewhere classified, unspecified wrist',
  'M71541 Other bursitis, not elsewhere classified, right hand',
  'M71542 Other bursitis, not elsewhere classified, left hand',
  'M71549 Other bursitis, not elsewhere classified, unspecified hand',
  'M71551 Other bursitis, not elsewhere classified, right hip',
  'M71552 Other bursitis, not elsewhere classified, left hip',
  'M71559 Other bursitis, not elsewhere classified, unspecified hip',
  'M71561 Other bursitis, not elsewhere classified, right knee',
  'M71562 Other bursitis, not elsewhere classified, left knee',
  'M71569 Other bursitis, not elsewhere classified, unspecified knee',
  'M71571 Other bursitis, not elsewhere classified, right ankle and foot',
  'M71572 Other bursitis, not elsewhere classified, left ankle and foot',
  'M71579 Other bursitis, not elsewhere classified, unspecified ankle and foot',
  'M7158 Other bursitis, not elsewhere classified, other site',
  'M7180 Other specified bursopathies, unspecified site',
  'M71811 Other specified bursopathies, right shoulder',
  'M71812 Other specified bursopathies, left shoulder',
  'M71819 Other specified bursopathies, unspecified shoulder',
  'M71821 Other specified bursopathies, right elbow',
  'M71822 Other specified bursopathies, left elbow',
  'M71829 Other specified bursopathies, unspecified elbow',
  'M71831 Other specified bursopathies, right wrist',
  'M71832 Other specified bursopathies, left wrist',
  'M71839 Other specified bursopathies, unspecified wrist',
  'M71841 Other specified bursopathies, right hand',
  'M71842 Other specified bursopathies, left hand',
  'M71849 Other specified bursopathies, unspecified hand',
  'M71851 Other specified bursopathies, right hip',
  'M71852 Other specified bursopathies, left hip',
  'M71859 Other specified bursopathies, unspecified hip',
  'M71861 Other specified bursopathies, right knee',
  'M71862 Other specified bursopathies, left knee',
  'M71869 Other specified bursopathies, unspecified knee',
  'M71871 Other specified bursopathies, right ankle and foot',
  'M71872 Other specified bursopathies, left ankle and foot',
  'M71879 Other specified bursopathies, unspecified ankle and foot',
  'M7188 Other specified bursopathies, other site',
  'M7189 Other specified bursopathies, multiple sites',
  'M719 Bursopathy, unspecified',
  'M720 Palmar fascial fibromatosis [Dupuytren]',
  'M721 Knuckle pads',
  'M722 Plantar fascial fibromatosis',
  'M724 Pseudosarcomatous fibromatosis',
  'M726 Necrotizing fasciitis',
  'M728 Other fibroblastic disorders',
  'M729 Fibroblastic disorder, unspecified',
  'M7500 Adhesive capsulitis of unspecified shoulder',
  'M7501 Adhesive capsulitis of right shoulder',
  'M7502 Adhesive capsulitis of left shoulder',
  'M75100 Unspecified rotator cuff tear or rupture of unspecified shoulder, not specified as traumatic',
  'M75101 Unspecified rotator cuff tear or rupture of right shoulder, not specified as traumatic',
  'M75102 Unspecified rotator cuff tear or rupture of left shoulder, not specified as traumatic',
  'M75110 Incomplete rotator cuff tear or rupture of unspecified shoulder, not specified as traumatic',
  'M75111 Incomplete rotator cuff tear or rupture of right shoulder, not specified as traumatic',
  'M75112 Incomplete rotator cuff tear or rupture of left shoulder, not specified as traumatic',
  'M75120 Complete rotator cuff tear or rupture of unspecified shoulder, not specified as traumatic',
  'M75121 Complete rotator cuff tear or rupture of right shoulder, not specified as traumatic',
  'M75122 Complete rotator cuff tear or rupture of left shoulder, not specified as traumatic',
  'M7520 Bicipital tendinitis, unspecified shoulder',
  'M7521 Bicipital tendinitis, right shoulder',
  'M7522 Bicipital tendinitis, left shoulder',
  'M7530 Calcific tendinitis of unspecified shoulder',
  'M7531 Calcific tendinitis of right shoulder',
  'M7532 Calcific tendinitis of left shoulder',
  'M7540 Impingement syndrome of unspecified shoulder',
  'M7541 Impingement syndrome of right shoulder',
  'M7542 Impingement syndrome of left shoulder',
  'M7550 Bursitis of unspecified shoulder',
  'M7551 Bursitis of right shoulder',
  'M7552 Bursitis of left shoulder',
  'M7580 Other shoulder lesions, unspecified shoulder',
  'M7581 Other shoulder lesions, right shoulder',
  'M7582 Other shoulder lesions, left shoulder',
  'M7590 Shoulder lesion, unspecified, unspecified shoulder',
  'M7591 Shoulder lesion, unspecified, right shoulder',
  'M7592 Shoulder lesion, unspecified, left shoulder',
  'M7600 Gluteal tendinitis, unspecified hip',
  'M7601 Gluteal tendinitis, right hip',
  'M7602 Gluteal tendinitis, left hip',
  'M7610 Psoas tendinitis, unspecified hip',
  'M7611 Psoas tendinitis, right hip',
  'M7612 Psoas tendinitis, left hip',
  'M7620 Iliac crest spur, unspecified hip',
  'M7621 Iliac crest spur, right hip',
  'M7622 Iliac crest spur, left hip',
  'M7630 Iliotibial band syndrome, unspecified leg',
  'M7631 Iliotibial band syndrome, right leg',
  'M7632 Iliotibial band syndrome, left leg',
  'M7640 Tibial collateral bursitis [Pellegrini-Stieda], unspecified leg',
  'M7641 Tibial collateral bursitis [Pellegrini-Stieda], right leg',
  'M7642 Tibial collateral bursitis [Pellegrini-Stieda], left leg',
  'M7650 Patellar tendinitis, unspecified knee',
  'M7651 Patellar tendinitis, right knee',
  'M7652 Patellar tendinitis, left knee',
  'M7660 Achilles tendinitis, unspecified leg',
  'M7661 Achilles tendinitis, right leg',
  'M7662 Achilles tendinitis, left leg',
  'M7670 Peroneal tendinitis, unspecified leg',
  'M7671 Peroneal tendinitis, right leg',
  'M7672 Peroneal tendinitis, left leg',
  'M76811 Anterior tibial syndrome, right leg',
  'M76812 Anterior tibial syndrome, left leg',
  'M76819 Anterior tibial syndrome, unspecified leg',
  'M76821 Posterior tibial tendinitis, right leg',
  'M76822 Posterior tibial tendinitis, left leg',
  'M76829 Posterior tibial tendinitis, unspecified leg',
  'M76891 Other specified enthesopathies of right lower limb, excluding foot',
  'M76892 Other specified enthesopathies of left lower limb, excluding foot',
  'M76899 Other specified enthesopathies of unspecified lower limb, excluding foot',
  'M769 Unspecified enthesopathy, lower limb, excluding foot',
  'M7700 Medial epicondylitis, unspecified elbow',
  'M7701 Medial epicondylitis, right elbow',
  'M7702 Medial epicondylitis, left elbow',
  'M7710 Lateral epicondylitis, unspecified elbow',
  'M7711 Lateral epicondylitis, right elbow',
  'M7712 Lateral epicondylitis, left elbow',
  'M7720 Periarthritis, unspecified wrist',
  'M7721 Periarthritis, right wrist',
  'M7722 Periarthritis, left wrist',
  'M7730 Calcaneal spur, unspecified foot',
  'M7731 Calcaneal spur, right foot',
  'M7732 Calcaneal spur, left foot',
  'M7740 Metatarsalgia, unspecified foot',
  'M7741 Metatarsalgia, right foot',
  'M7742 Metatarsalgia, left foot',
  'M7750 Other enthesopathy of unspecified foot',
  'M7751 Other enthesopathy of right foot',
  'M7752 Other enthesopathy of left foot',
  'M778 Other enthesopathies, not elsewhere classified',
  'M779 Enthesopathy, unspecified',
  'M790 Rheumatism, unspecified',
  'M791 Myalgia',
  'M792 Neuralgia and neuritis, unspecified',
  'M793 Panniculitis, unspecified',
  'M794 Hypertrophy of (infrapatellar) fat pad',
  'M795 Residual foreign body in soft tissue',
  'M79601 Pain in right arm',
  'M79602 Pain in left arm',
  'M79603 Pain in arm, unspecified',
  'M79604 Pain in right leg',
  'M79605 Pain in left leg',
  'M79606 Pain in leg, unspecified',
  'M79609 Pain in unspecified limb',
  'M79621 Pain in right upper arm',
  'M79622 Pain in left upper arm',
  'M79629 Pain in unspecified upper arm',
  'M79631 Pain in right forearm',
  'M79632 Pain in left forearm',
  'M79639 Pain in unspecified forearm',
  'M79641 Pain in right hand',
  'M79642 Pain in left hand',
  'M79643 Pain in unspecified hand',
  'M79644 Pain in right finger(s)',
  'M79645 Pain in left finger(s)',
  'M79646 Pain in unspecified finger(s)',
  'M79651 Pain in right thigh',
  'M79652 Pain in left thigh',
  'M79659 Pain in unspecified thigh',
  'M79661 Pain in right lower leg',
  'M79662 Pain in left lower leg',
  'M79669 Pain in unspecified lower leg',
  'M79671 Pain in right foot',
  'M79672 Pain in left foot',
  'M79673 Pain in unspecified foot',
  'M79674 Pain in right toe(s)',
  'M79675 Pain in left toe(s)',
  'M79676 Pain in unspecified toe(s)',
  'M797 Fibromyalgia',
  'M79A11 Nontraumatic compartment syndrome of right upper extremity',
  'M79A12 Nontraumatic compartment syndrome of left upper extremity',
  'M79A19 Nontraumatic compartment syndrome of unspecified upper extremity',
  'M79A21 Nontraumatic compartment syndrome of right lower extremity',
  'M79A22 Nontraumatic compartment syndrome of left lower extremity',
  'M79A29 Nontraumatic compartment syndrome of unspecified lower extremity',
  'M79A3 Nontraumatic compartment syndrome of abdomen',
  'M79A9 Nontraumatic compartment syndrome of other sites',
  'M7981 Nontraumatic hematoma of soft tissue',
  'M7989 Other specified soft tissue disorders',
  'M799 Soft tissue disorder, unspecified',
  'M8000XA Age-related osteoporosis with current pathological fracture, unspecified site, initial encounter',
  'for fracture',
  'M8000XD Age-related osteoporosis with current pathological fracture, unspecified site, subsequent',
  'encounter for fracture with routine healing',
  'M8000XG Age-related osteoporosis with current pathological fracture, unspecified site, subsequent',
  'encounter for fracture with delayed healing',
  'M8000XK Age-related osteoporosis with current pathological fracture, unspecified site, subsequent',
  'encounter for fracture with nonunion',
  'M8000XP Age-related osteoporosis with current pathological fracture, unspecified site, subsequent',
  'encounter for fracture with malunion',
  'M8000XS Age-related osteoporosis with current pathological fracture, unspecified site, sequela',
  'M80011A Age-related osteoporosis with current pathological fracture, right shoulder, initial encounter',
  'for fracture',
  'M80011D Age-related osteoporosis with current pathological fracture, right shoulder, subsequent',
  'encounter for fracture with routine healing',
  'M80011G Age-related osteoporosis with current pathological fracture, right shoulder, subsequent',
  'encounter for fracture with delayed healing',
  'M80011K Age-related osteoporosis with current pathological fracture, right shoulder, subsequent',
  'encounter for fracture with nonunion',
  'M80011P Age-related osteoporosis with current pathological fracture, right shoulder, subsequent',
  'encounter for fracture with malunion',
  'M80011S Age-related osteoporosis with current pathological fracture, right shoulder, sequela',
  'M80012A Age-related osteoporosis with current pathological fracture, left shoulder, initial encounter',
  'for fracture',
  'M80012D Age-related osteoporosis with current pathological fracture, left shoulder, subsequent',
  'encounter for fracture with routine healing',
  'M80012G Age-related osteoporosis with current pathological fracture, left shoulder, subsequent',
  'encounter for fracture with delayed healing',
  'M80012K Age-related osteoporosis with current pathological fracture, left shoulder, subsequent',
  'encounter for fracture with nonunion',
  'M80012P Age-related osteoporosis with current pathological fracture, left shoulder, subsequent',
  'encounter for fracture with malunion',
  'M80012S Age-related osteoporosis with current pathological fracture, left shoulder, sequela',
  'M80019A Age-related osteoporosis with current pathological fracture, unspecified shoulder, initial',
  'encounter for fracture',
  'M80019D Age-related osteoporosis with current pathological fracture, unspecified shoulder,',
  'subsequent encounter for fracture with routine healing',
  'M80019G Age-related osteoporosis with current pathological fracture, unspecified shoulder,',
  'subsequent encounter for fracture with delayed healing',
  'M80019K Age-related osteoporosis with current pathological fracture, unspecified shoulder, subsequent',
  'encounter for fracture with nonunion',
  'M80019P Age-related osteoporosis with current pathological fracture, unspecified shoulder, subsequent',
  'encounter for fracture with malunion',
  'M80019S Age-related osteoporosis with current pathological fracture, unspecified shoulder, sequela',
  'M80021A Age-related osteoporosis with current pathological fracture, right humerus, initial encounter',
  'for fracture',
  'M80021D Age-related osteoporosis with current pathological fracture, right humerus, subsequent',
  'encounter for fracture with routine healing',
  'M80021G Age-related osteoporosis with current pathological fracture, right humerus, subsequent',
  'encounter for fracture with delayed healing',
  'M80021K Age-related osteoporosis with current pathological fracture, right humerus, subsequent',
  'encounter for fracture with nonunion',
  'M80021P Age-related osteoporosis with current pathological fracture, right humerus, subsequent',
  'encounter for fracture with malunion',
  'M80021S Age-related osteoporosis with current pathological fracture, right humerus, sequela',
  'M80022A Age-related osteoporosis with current pathological fracture, left humerus, initial encounter',
  'for fracture',
  'M80022D Age-related osteoporosis with current pathological fracture, left humerus, subsequent',
  'encounter for fracture with routine healing',
  'M80022G Age-related osteoporosis with current pathological fracture, left humerus, subsequent',
  'encounter for fracture with delayed healing',
  'M80022K Age-related osteoporosis with current pathological fracture, left humerus, subsequent',
  'encounter for fracture with nonunion',
  'M80022P Age-related osteoporosis with current pathological fracture, left humerus, subsequent',
  'encounter for fracture with malunion',
  'M80022S Age-related osteoporosis with current pathological fracture, left humerus, sequela',
  'M80029A Age-related osteoporosis with current pathological fracture, unspecified humerus, initial',
  'encounter for fracture',
  'M80029D Age-related osteoporosis with current pathological fracture, unspecified humerus,',
  'subsequent encounter for fracture with routine healing',
  'M80029G Age-related osteoporosis with current pathological fracture, unspecified humerus,',
  'subsequent encounter for fracture with delayed healing',
  'M80029K Age-related osteoporosis with current pathological fracture, unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'M80029P Age-related osteoporosis with current pathological fracture, unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'M80029S Age-related osteoporosis with current pathological fracture, unspecified humerus, sequela',
  'M80031A Age-related osteoporosis with current pathological fracture, right forearm, initial encounter',
  'for fracture',
  'M80031D Age-related osteoporosis with current pathological fracture, right forearm, subsequent',
  'encounter for fracture with routine healing',
  'M80031G Age-related osteoporosis with current pathological fracture, right forearm, subsequent',
  'encounter for fracture with delayed healing',
  'M80031K Age-related osteoporosis with current pathological fracture, right forearm, subsequent',
  'encounter for fracture with nonunion',
  'M80031P Age-related osteoporosis with current pathological fracture, right forearm, subsequent',
  'encounter for fracture with malunion',
  'M80031S Age-related osteoporosis with current pathological fracture, right forearm, sequela',
  'M80032A Age-related osteoporosis with current pathological fracture, left forearm, initial encounter for',
  'fracture',
  'M80032D Age-related osteoporosis with current pathological fracture, left forearm, subsequent',
  'encounter for fracture with routine healing',
  'M80032G Age-related osteoporosis with current pathological fracture, left forearm, subsequent',
  'encounter for fracture with delayed healing',
  'M80032K Age-related osteoporosis with current pathological fracture, left forearm, subsequent',
  'encounter for fracture with nonunion',
  'M80032P Age-related osteoporosis with current pathological fracture, left forearm, subsequent',
  'encounter for fracture with malunion',
  'M80032S Age-related osteoporosis with current pathological fracture, left forearm, sequela',
  'M80039A Age-related osteoporosis with current pathological fracture, unspecified forearm, initial',
  'encounter for fracture',
  'M80039D Age-related osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with routine healing',
  'M80039G Age-related osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with delayed healing',
  'M80039K Age-related osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with nonunion',
  'M80039P Age-related osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with malunion',
  'M80039S Age-related osteoporosis with current pathological fracture, unspecified forearm, sequela',
  'M80041A Age-related osteoporosis with current pathological fracture, right hand, initial encounter for',
  'fracture',
  'M80041D Age-related osteoporosis with current pathological fracture, right hand, subsequent',
  'encounter for fracture with routine healing',
  'M80041G Age-related osteoporosis with current pathological fracture, right hand, subsequent',
  'encounter for fracture with delayed healing',
  'M80041K Age-related osteoporosis with current pathological fracture, right hand, subsequent',
  'encounter for fracture with nonunion',
  'M80041P Age-related osteoporosis with current pathological fracture, right hand, subsequent',
  'encounter for fracture with malunion',
  'M80041S Age-related osteoporosis with current pathological fracture, right hand, sequela',
  'M80042A Age-related osteoporosis with current pathological fracture, left hand, initial encounter for',
  'fracture',
  'M80042D Age-related osteoporosis with current pathological fracture, left hand, subsequent encounter',
  'for fracture with routine healing',
  'M80042G Age-related osteoporosis with current pathological fracture, left hand, subsequent encounter',
  'for fracture with delayed healing',
  'M80042K Age-related osteoporosis with current pathological fracture, left hand, subsequent encounter',
  'for fracture with nonunion',
  'M80042P Age-related osteoporosis with current pathological fracture, left hand, subsequent encounter',
  'for fracture with malunion',
  'M80042S Age-related osteoporosis with current pathological fracture, left hand, sequela',
  'M80049A Age-related osteoporosis with current pathological fracture, unspecified hand, initial',
  'encounter for fracture',
  'M80049D Age-related osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with routine healing',
  'M80049G Age-related osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with delayed healing',
  'M80049K Age-related osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with nonunion',
  'M80049P Age-related osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with malunion',
  'M80049S Age-related osteoporosis with current pathological fracture, unspecified hand, sequela',
  'M80051A Age-related osteoporosis with current pathological fracture, right femur, initial encounter for',
  'fracture',
  'M80051D Age-related osteoporosis with current pathological fracture, right femur, subsequent',
  'encounter for fracture with routine healing',
  'M80051G Age-related osteoporosis with current pathological fracture, right femur, subsequent',
  'encounter for fracture with delayed healing',
  'M80051K Age-related osteoporosis with current pathological fracture, right femur, subsequent',
  'encounter for fracture with nonunion',
  'M80051P Age-related osteoporosis with current pathological fracture, right femur, subsequent',
  'encounter for fracture with malunion',
  'M80051S Age-related osteoporosis with current pathological fracture, right femur, sequela',
  'M80052A Age-related osteoporosis with current pathological fracture, left femur, initial encounter for',
  'fracture',
  'M80052D Age-related osteoporosis with current pathological fracture, left femur, subsequent',
  'encounter for fracture with routine healing',
  'M80052G Age-related osteoporosis with current pathological fracture, left femur, subsequent',
  'encounter for fracture with delayed healing',
  'M80052K Age-related osteoporosis with current pathological fracture, left femur, subsequent encounter',
  'for fracture with nonunion',
  'M80052P Age-related osteoporosis with current pathological fracture, left femur, subsequent encounter',
  'for fracture with malunion',
  'M80052S Age-related osteoporosis with current pathological fracture, left femur, sequela',
  'M80059A Age-related osteoporosis with current pathological fracture, unspecified femur, initial',
  'encounter for fracture',
  'M80059D Age-related osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with routine healing',
  'M80059G Age-related osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with delayed healing',
  'M80059K Age-related osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with nonunion',
  'M80059P Age-related osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with malunion',
  'M80059S Age-related osteoporosis with current pathological fracture, unspecified femur, sequela',
  'M80061A Age-related osteoporosis with current pathological fracture, right lower leg, initial encounter',
  'for fracture',
  'M80061D Age-related osteoporosis with current pathological fracture, right lower leg, subsequent',
  'encounter for fracture with routine healing',
  'M80061G Age-related osteoporosis with current pathological fracture, right lower leg, subsequent',
  'encounter for fracture with delayed healing',
  'M80061K Age-related osteoporosis with current pathological fracture, right lower leg, subsequent',
  'encounter for fracture with nonunion',
  'M80061P Age-related osteoporosis with current pathological fracture, right lower leg, subsequent',
  'encounter for fracture with malunion',
  'M80061S Age-related osteoporosis with current pathological fracture, right lower leg, sequela',
  'M80062A Age-related osteoporosis with current pathological fracture, left lower leg, initial encounter',
  'for fracture',
  'M80062D Age-related osteoporosis with current pathological fracture, left lower leg, subsequent',
  'encounter for fracture with routine healing',
  'M80062G Age-related osteoporosis with current pathological fracture, left lower leg, subsequent',
  'encounter for fracture with delayed healing',
  'M80062K Age-related osteoporosis with current pathological fracture, left lower leg, subsequent',
  'encounter for fracture with nonunion',
  'M80062P Age-related osteoporosis with current pathological fracture, left lower leg, subsequent',
  'encounter for fracture with malunion',
  'M80062S Age-related osteoporosis with current pathological fracture, left lower leg, sequela',
  'M80069A Age-related osteoporosis with current pathological fracture, unspecified lower leg, initial',
  'encounter for fracture',
  'M80069D Age-related osteoporosis with current pathological fracture, unspecified lower leg,',
  'subsequent encounter for fracture with routine healing',
  'M80069G Age-related osteoporosis with current pathological fracture, unspecified lower leg,',
  'subsequent encounter for fracture with delayed healing',
  'M80069K Age-related osteoporosis with current pathological fracture, unspecified lower leg,',
  'subsequent encounter for fracture with nonunion',
  'M80069P Age-related osteoporosis with current pathological fracture, unspecified lower leg,',
  'subsequent encounter for fracture with malunion',
  'M80069S Age-related osteoporosis with current pathological fracture, unspecified lower leg, sequela',
  'M80071A Age-related osteoporosis with current pathological fracture, right ankle and foot, initial',
  'encounter for fracture',
  'M80071D Age-related osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with routine healing',
  'M80071G Age-related osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with delayed healing',
  'M80071K Age-related osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with nonunion',
  'M80071P Age-related osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with malunion',
  'M80071S Age-related osteoporosis with current pathological fracture, right ankle and foot, sequela',
  'M80072A Age-related osteoporosis with current pathological fracture, left ankle and foot, initial',
  'encounter for fracture',
  'M80072D Age-related osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with routine healing',
  'M80072G Age-related osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with delayed healing',
  'M80072K Age-related osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with nonunion',
  'M80072P Age-related osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with malunion',
  'M80072S Age-related osteoporosis with current pathological fracture, left ankle and foot, sequela',
  'M80079A Age-related osteoporosis with current pathological fracture, unspecified ankle and foot, initial',
  'encounter for fracture',
  'M80079D Age-related osteoporosis with current pathological fracture, unspecified ankle and foot,',
  'subsequent encounter for fracture with routine healing',
  'M80079G Age-related osteoporosis with current pathological fracture, unspecified ankle and foot,',
  'subsequent encounter for fracture with delayed healing',
  'M80079K Age-related osteoporosis with current pathological fracture, unspecified ankle and foot,',
  'subsequent encounter for fracture with nonunion',
  'M80079P Age-related osteoporosis with current pathological fracture, unspecified ankle and foot,',
  'subsequent encounter for fracture with malunion',
  'M80079S Age-related osteoporosis with current pathological fracture, unspecified ankle and foot,',
  'sequela',
  'M8008XA Age-related osteoporosis with current pathological fracture, vertebra(e), initial encounter for',
  'fracture',
  'M8008XD Age-related osteoporosis with current pathological fracture, vertebra(e), subsequent',
  'encounter for fracture with routine healing',
  'M8008XG Age-related osteoporosis with current pathological fracture, vertebra(e), subsequent',
  'encounter for fracture with delayed healing',
  'M8008XK Age-related osteoporosis with current pathological fracture, vertebra(e), subsequent',
  'encounter for fracture with nonunion',
  'M8008XP Age-related osteoporosis with current pathological fracture, vertebra(e), subsequent',
  'encounter for fracture with malunion',
  'M8008XS Age-related osteoporosis with current pathological fracture, vertebra(e), sequela',
  'M8080XA Other osteoporosis with current pathological fracture, unspecified site, initial encounter for',
  'fracture',
  'M8080XD Other osteoporosis with current pathological fracture, unspecified site, subsequent encounter',
  'for fracture with routine healing',
  'M8080XG Other osteoporosis with current pathological fracture, unspecified site, subsequent encounter',
  'for fracture with delayed healing',
  'M8080XK Other osteoporosis with current pathological fracture, unspecified site, subsequent encounter',
  'for fracture with nonunion',
  'M8080XP Other osteoporosis with current pathological fracture, unspecified site, subsequent encounter',
  'for fracture with malunion',
  'M8080XS Other osteoporosis with current pathological fracture, unspecified site, sequela',
  'M80811A Other osteoporosis with current pathological fracture, right shoulder, initial encounter for',
  'fracture',
  'M80811D Other osteoporosis with current pathological fracture, right shoulder, subsequent encounter',
  'for fracture with routine healing',
  'M80811G Other osteoporosis with current pathological fracture, right shoulder, subsequent encounter',
  'for fracture with delayed healing',
  'M80811K Other osteoporosis with current pathological fracture, right shoulder, subsequent encounter',
  'for fracture with nonunion',
  'M80811P Other osteoporosis with current pathological fracture, right shoulder, subsequent encounter',
  'for fracture with malunion',
  'M80811S Other osteoporosis with current pathological fracture, right shoulder, sequela',
  'M80812A Other osteoporosis with current pathological fracture, left shoulder, initial encounter for',
  'fracture',
  'M80812D Other osteoporosis with current pathological fracture, left shoulder, subsequent encounter',
  'for fracture with routine healing',
  'M80812G Other osteoporosis with current pathological fracture, left shoulder, subsequent encounter',
  'for fracture with delayed healing',
  'M80812K Other osteoporosis with current pathological fracture, left shoulder, subsequent encounter',
  'for fracture with nonunion',
  'M80812P Other osteoporosis with current pathological fracture, left shoulder, subsequent encounter',
  'for fracture with malunion',
  'M80812S Other osteoporosis with current pathological fracture, left shoulder, sequela',
  'M80819A Other osteoporosis with current pathological fracture, unspecified shoulder, initial encounter',
  'for fracture',
  'M80819D Other osteoporosis with current pathological fracture, unspecified shoulder, subsequent',
  'encounter for fracture with routine healing',
  'M80819G Other osteoporosis with current pathological fracture, unspecified shoulder, subsequent',
  'encounter for fracture with delayed healing',
  'M80819K Other osteoporosis with current pathological fracture, unspecified shoulder, subsequent',
  'encounter for fracture with nonunion',
  'M80819P Other osteoporosis with current pathological fracture, unspecified shoulder, subsequent',
  'encounter for fracture with malunion',
  'M80819S Other osteoporosis with current pathological fracture, unspecified shoulder, sequela',
  'M80821A Other osteoporosis with current pathological fracture, right humerus, initial encounter for',
  'fracture',
  'M80821D Other osteoporosis with current pathological fracture, right humerus, subsequent encounter',
  'for fracture with routine healing',
  'M80821G Other osteoporosis with current pathological fracture, right humerus, subsequent encounter',
  'for fracture with delayed healing',
  'M80821K Other osteoporosis with current pathological fracture, right humerus, subsequent encounter',
  'for fracture with nonunion',
  'M80821P Other osteoporosis with current pathological fracture, right humerus, subsequent encounter',
  'for fracture with malunion',
  'M80821S Other osteoporosis with current pathological fracture, right humerus, sequela',
  'M80822A Other osteoporosis with current pathological fracture, left humerus, initial encounter for',
  'fracture',
  'M80822D Other osteoporosis with current pathological fracture, left humerus, subsequent encounter',
  'for fracture with routine healing',
  'M80822G Other osteoporosis with current pathological fracture, left humerus, subsequent encounter',
  'for fracture with delayed healing',
  'M80822K Other osteoporosis with current pathological fracture, left humerus, subsequent encounter',
  'for fracture with nonunion',
  'M80822P Other osteoporosis with current pathological fracture, left humerus, subsequent encounter',
  'for fracture with malunion',
  'M80822S Other osteoporosis with current pathological fracture, left humerus, sequela',
  'M80829A Other osteoporosis with current pathological fracture, unspecified humerus, initial encounter',
  'for fracture',
  'M80829D Other osteoporosis with current pathological fracture, unspecified humerus, subsequent',
  'encounter for fracture with routine healing',
  'M80829G Other osteoporosis with current pathological fracture, unspecified humerus, subsequent',
  'encounter for fracture with delayed healing',
  'M80829K Other osteoporosis with current pathological fracture, unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'M80829P Other osteoporosis with current pathological fracture, unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'M80829S Other osteoporosis with current pathological fracture, unspecified humerus, sequela',
  'M80831A Other osteoporosis with current pathological fracture, right forearm, initial encounter for',
  'fracture',
  'M80831D Other osteoporosis with current pathological fracture, right forearm, subsequent encounter',
  'for fracture with routine healing',
  'M80831G Other osteoporosis with current pathological fracture, right forearm, subsequent encounter',
  'for fracture with delayed healing',
  'M80831K Other osteoporosis with current pathological fracture, right forearm, subsequent encounter',
  'for fracture with nonunion',
  'M80831P Other osteoporosis with current pathological fracture, right forearm, subsequent encounter',
  'for fracture with malunion',
  'M80831S Other osteoporosis with current pathological fracture, right forearm, sequela',
  'M80832A Other osteoporosis with current pathological fracture, left forearm, initial encounter for',
  'fracture',
  'M80832D Other osteoporosis with current pathological fracture, left forearm, subsequent encounter for',
  'fracture with routine healing',
  'M80832G Other osteoporosis with current pathological fracture, left forearm, subsequent encounter for',
  'fracture with delayed healing',
  'M80832K Other osteoporosis with current pathological fracture, left forearm, subsequent encounter for',
  'fracture with nonunion',
  'M80832P Other osteoporosis with current pathological fracture, left forearm, subsequent encounter for',
  'fracture with malunion',
  'M80832S Other osteoporosis with current pathological fracture, left forearm, sequela',
  'M80839A Other osteoporosis with current pathological fracture, unspecified forearm, initial encounter',
  'for fracture',
  'M80839D Other osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with routine healing',
  'M80839G Other osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with delayed healing',
  'M80839K Other osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with nonunion',
  'M80839P Other osteoporosis with current pathological fracture, unspecified forearm, subsequent',
  'encounter for fracture with malunion',
  'M80839S Other osteoporosis with current pathological fracture, unspecified forearm, sequela',
  'M80841A Other osteoporosis with current pathological fracture, right hand, initial encounter for',
  'fracture',
  'M80841D Other osteoporosis with current pathological fracture, right hand, subsequent encounter for',
  'fracture with routine healing',
  'M80841G Other osteoporosis with current pathological fracture, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'M80841K Other osteoporosis with current pathological fracture, right hand, subsequent encounter for',
  'fracture with nonunion',
  'M80841P Other osteoporosis with current pathological fracture, right hand, subsequent encounter for',
  'fracture with malunion',
  'M80841S Other osteoporosis with current pathological fracture, right hand, sequela',
  'M80842A Other osteoporosis with current pathological fracture, left hand, initial encounter for fracture',
  'M80842D Other osteoporosis with current pathological fracture, left hand, subsequent encounter for',
  'fracture with routine healing',
  'M80842G Other osteoporosis with current pathological fracture, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'M80842K Other osteoporosis with current pathological fracture, left hand, subsequent encounter for',
  'fracture with nonunion',
  'M80842P Other osteoporosis with current pathological fracture, left hand, subsequent encounter for',
  'fracture with malunion',
  'M80842S Other osteoporosis with current pathological fracture, left hand, sequela',
  'M80849A Other osteoporosis with current pathological fracture, unspecified hand, initial encounter for',
  'fracture',
  'M80849D Other osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with routine healing',
  'M80849G Other osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with delayed healing',
  'M80849K Other osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with nonunion',
  'M80849P Other osteoporosis with current pathological fracture, unspecified hand, subsequent',
  'encounter for fracture with malunion',
  'M80849S Other osteoporosis with current pathological fracture, unspecified hand, sequela',
  'M80851A Other osteoporosis with current pathological fracture, right femur, initial encounter for',
  'fracture',
  'M80851D Other osteoporosis with current pathological fracture, right femur, subsequent encounter for',
  'fracture with routine healing',
  'M80851G Other osteoporosis with current pathological fracture, right femur, subsequent encounter for',
  'fracture with delayed healing',
  'M80851K Other osteoporosis with current pathological fracture, right femur, subsequent encounter for',
  'fracture with nonunion',
  'M80851P Other osteoporosis with current pathological fracture, right femur, subsequent encounter for',
  'fracture with malunion',
  'M80851S Other osteoporosis with current pathological fracture, right femur, sequela',
  'M80852A Other osteoporosis with current pathological fracture, left femur, initial encounter for',
  'fracture',
  'M80852D Other osteoporosis with current pathological fracture, left femur, subsequent encounter for',
  'fracture with routine healing',
  'M80852G Other osteoporosis with current pathological fracture, left femur, subsequent encounter for',
  'fracture with delayed healing',
  'M80852K Other osteoporosis with current pathological fracture, left femur, subsequent encounter for',
  'fracture with nonunion',
  'M80852P Other osteoporosis with current pathological fracture, left femur, subsequent encounter for',
  'fracture with malunion',
  'M80852S Other osteoporosis with current pathological fracture, left femur, sequela',
  'M80859A Other osteoporosis with current pathological fracture, unspecified femur, initial encounter for',
  'fracture',
  'M80859D Other osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with routine healing',
  'M80859G Other osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with delayed healing',
  'M80859K Other osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with nonunion',
  'M80859P Other osteoporosis with current pathological fracture, unspecified femur, subsequent',
  'encounter for fracture with malunion',
  'M80859S Other osteoporosis with current pathological fracture, unspecified femur, sequela',
  'M80861A Other osteoporosis with current pathological fracture, right lower leg, initial encounter for',
  'fracture',
  'M80861D Other osteoporosis with current pathological fracture, right lower leg, subsequent encounter',
  'for fracture with routine healing',
  'M80861G Other osteoporosis with current pathological fracture, right lower leg, subsequent encounter',
  'for fracture with delayed healing',
  'M80861K Other osteoporosis with current pathological fracture, right lower leg, subsequent encounter',
  'for fracture with nonunion',
  'M80861P Other osteoporosis with current pathological fracture, right lower leg, subsequent encounter',
  'for fracture with malunion',
  'M80861S Other osteoporosis with current pathological fracture, right lower leg, sequela',
  'M80862A Other osteoporosis with current pathological fracture, left lower leg, initial encounter for',
  'fracture',
  'M80862D Other osteoporosis with current pathological fracture, left lower leg, subsequent encounter',
  'for fracture with routine healing',
  'M80862G Other osteoporosis with current pathological fracture, left lower leg, subsequent encounter',
  'for fracture with delayed healing',
  'M80862K Other osteoporosis with current pathological fracture, left lower leg, subsequent encounter',
  'for fracture with nonunion',
  'M80862P Other osteoporosis with current pathological fracture, left lower leg, subsequent encounter',
  'for fracture with malunion',
  'M80862S Other osteoporosis with current pathological fracture, left lower leg, sequela',
  'M80869A Other osteoporosis with current pathological fracture, unspecified lower leg, initial encounter',
  'for fracture',
  'M80869D Other osteoporosis with current pathological fracture, unspecified lower leg, subsequent',
  'encounter for fracture with routine healing',
  'M80869G Other osteoporosis with current pathological fracture, unspecified lower leg, subsequent',
  'encounter for fracture with delayed healing',
  'M80869K Other osteoporosis with current pathological fracture, unspecified lower leg, subsequent',
  'encounter for fracture with nonunion',
  'M80869P Other osteoporosis with current pathological fracture, unspecified lower leg, subsequent',
  'encounter for fracture with malunion',
  'M80869S Other osteoporosis with current pathological fracture, unspecified lower leg, sequela',
  'M80871A Other osteoporosis with current pathological fracture, right ankle and foot, initial encounter',
  'for fracture',
  'M80871D Other osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with routine healing',
  'M80871G Other osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with delayed healing',
  'M80871K Other osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with nonunion',
  'M80871P Other osteoporosis with current pathological fracture, right ankle and foot, subsequent',
  'encounter for fracture with malunion',
  'M80871S Other osteoporosis with current pathological fracture, right ankle and foot, sequela',
  'M80872A Other osteoporosis with current pathological fracture, left ankle and foot, initial encounter for',
  'fracture',
  'M80872D Other osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with routine healing',
  'M80872G Other osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with delayed healing',
  'M80872K Other osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with nonunion',
  'M80872P Other osteoporosis with current pathological fracture, left ankle and foot, subsequent',
  'encounter for fracture with malunion',
  'M80872S Other osteoporosis with current pathological fracture, left ankle and foot, sequela',
  'M80879A Other osteoporosis with current pathological fracture, unspecified ankle and foot, initial',
  'encounter for fracture',
  'M80879D Other osteoporosis with current pathological fracture, unspecified ankle and foot, subsequent',
  'encounter for fracture with routine healing',
  'M80879G Other osteoporosis with current pathological fracture, unspecified ankle and foot, subsequent',
  'encounter for fracture with delayed healing',
  'M80879K Other osteoporosis with current pathological fracture, unspecified ankle and foot, subsequent',
  'encounter for fracture with nonunion',
  'M80879P Other osteoporosis with current pathological fracture, unspecified ankle and foot, subsequent',
  'encounter for fracture with malunion',
  'M80879S Other osteoporosis with current pathological fracture, unspecified ankle and foot, sequela',
  'M8088XA Other osteoporosis with current pathological fracture, vertebra(e), initial encounter for',
  'fracture',
  'M8088XD Other osteoporosis with current pathological fracture, vertebra(e), subsequent encounter for',
  'fracture with routine healing',
  'M8088XG Other osteoporosis with current pathological fracture, vertebra(e), subsequent encounter for',
  'fracture with delayed healing',
  'M8088XK Other osteoporosis with current pathological fracture, vertebra(e), subsequent encounter for',
  'fracture with nonunion',
  'M8088XP Other osteoporosis with current pathological fracture, vertebra(e), subsequent encounter for',
  'fracture with malunion',
  'M8088XS Other osteoporosis with current pathological fracture, vertebra(e), sequela',
  'M810 Age-related osteoporosis without current pathological fracture',
  'M816 Localized osteoporosis [Lequesne]',
  'M818 Other osteoporosis without current pathological fracture',
  'M830 Puerperal osteomalacia',
  'M831 Senile osteomalacia',
  'M832 Adult osteomalacia due to malabsorption',
  'M833 Adult osteomalacia due to malnutrition',
  'M834 Aluminum bone disease',
  'M835 Other drug-induced osteomalacia in adults',
  'M838 Other adult osteomalacia',
  'M839 Adult osteomalacia, unspecified',
  'M8430XA Stress fracture, unspecified site, initial encounter for fracture',
  'M8430XD Stress fracture, unspecified site, subsequent encounter for fracture with routine healing',
  'M8430XG Stress fracture, unspecified site, subsequent encounter for fracture with delayed healing',
  'M8430XK Stress fracture, unspecified site, subsequent encounter for fracture with nonunion',
  'M8430XP Stress fracture, unspecified site, subsequent encounter for fracture with malunion',
  'M8430XS Stress fracture, unspecified site, sequela',
  'M84311A Stress fracture, right shoulder, initial encounter for fracture',
  'M84311D Stress fracture, right shoulder, subsequent encounter for fracture with routine healing',
  'M84311G Stress fracture, right shoulder, subsequent encounter for fracture with delayed healing',
  'M84311K Stress fracture, right shoulder, subsequent encounter for fracture with nonunion',
  'M84311P Stress fracture, right shoulder, subsequent encounter for fracture with malunion',
  'M84311S Stress fracture, right shoulder, sequela',
  'M84312A Stress fracture, left shoulder, initial encounter for fracture',
  'M84312D Stress fracture, left shoulder, subsequent encounter for fracture with routine healing',
  'M84312G Stress fracture, left shoulder, subsequent encounter for fracture with delayed healing',
  'M84312K Stress fracture, left shoulder, subsequent encounter for fracture with nonunion',
  'M84312P Stress fracture, left shoulder, subsequent encounter for fracture with malunion',
  'M84312S Stress fracture, left shoulder, sequela',
  'M84319A Stress fracture, unspecified shoulder, initial encounter for fracture',
  'M84319D Stress fracture, unspecified shoulder, subsequent encounter for fracture with routine healing',
  'M84319G Stress fracture, unspecified shoulder, subsequent encounter for fracture with delayed healing',
  'M84319K Stress fracture, unspecified shoulder, subsequent encounter for fracture with nonunion',
  'M84319P Stress fracture, unspecified shoulder, subsequent encounter for fracture with malunion',
  'M84319S Stress fracture, unspecified shoulder, sequela',
  'M84321A Stress fracture, right humerus, initial encounter for fracture',
  'M84321D Stress fracture, right humerus, subsequent encounter for fracture with routine healing',
  'M84321G Stress fracture, right humerus, subsequent encounter for fracture with delayed healing',
  'M84321K Stress fracture, right humerus, subsequent encounter for fracture with nonunion',
  'M84321P Stress fracture, right humerus, subsequent encounter for fracture with malunion',
  'M84321S Stress fracture, right humerus, sequela',
  'M84322A Stress fracture, left humerus, initial encounter for fracture',
  'M84322D Stress fracture, left humerus, subsequent encounter for fracture with routine healing',
  'M84322G Stress fracture, left humerus, subsequent encounter for fracture with delayed healing',
  'M84322K Stress fracture, left humerus, subsequent encounter for fracture with nonunion',
  'M84322P Stress fracture, left humerus, subsequent encounter for fracture with malunion',
  'M84322S Stress fracture, left humerus, sequela',
  'M84329A Stress fracture, unspecified humerus, initial encounter for fracture',
  'M84329D Stress fracture, unspecified humerus, subsequent encounter for fracture with routine healing',
  'M84329G Stress fracture, unspecified humerus, subsequent encounter for fracture with delayed healing',
  'M84329K Stress fracture, unspecified humerus, subsequent encounter for fracture with nonunion',
  'M84329P Stress fracture, unspecified humerus, subsequent encounter for fracture with malunion',
  'M84329S Stress fracture, unspecified humerus, sequela',
  'M84331A Stress fracture, right ulna, initial encounter for fracture',
  'M84331D Stress fracture, right ulna, subsequent encounter for fracture with routine healing',
  'M84331G Stress fracture, right ulna, subsequent encounter for fracture with delayed healing',
  'M84331K Stress fracture, right ulna, subsequent encounter for fracture with nonunion',
  'M84331P Stress fracture, right ulna, subsequent encounter for fracture with malunion',
  'M84331S Stress fracture, right ulna, sequela',
  'M84332A Stress fracture, left ulna, initial encounter for fracture',
  'M84332D Stress fracture, left ulna, subsequent encounter for fracture with routine healing',
  'M84332G Stress fracture, left ulna, subsequent encounter for fracture with delayed healing',
  'M84332K Stress fracture, left ulna, subsequent encounter for fracture with nonunion',
  'M84332P Stress fracture, left ulna, subsequent encounter for fracture with malunion',
  'M84332S Stress fracture, left ulna, sequela',
  'M84333A Stress fracture, right radius, initial encounter for fracture',
  'M84333D Stress fracture, right radius, subsequent encounter for fracture with routine healing',
  'M84333G Stress fracture, right radius, subsequent encounter for fracture with delayed healing',
  'M84333K Stress fracture, right radius, subsequent encounter for fracture with nonunion',
  'M84333P Stress fracture, right radius, subsequent encounter for fracture with malunion',
  'M84333S Stress fracture, right radius, sequela',
  'M84334A Stress fracture, left radius, initial encounter for fracture',
  'M84334D Stress fracture, left radius, subsequent encounter for fracture with routine healing',
  'M84334G Stress fracture, left radius, subsequent encounter for fracture with delayed healing',
  'M84334K Stress fracture, left radius, subsequent encounter for fracture with nonunion',
  'M84334P Stress fracture, left radius, subsequent encounter for fracture with malunion',
  'M84334S Stress fracture, left radius, sequela',
  'M84339A Stress fracture, unspecified ulna and radius, initial encounter for fracture',
  'M84339D Stress fracture, unspecified ulna and radius, subsequent encounter for fracture with routine',
  'healing',
  'M84339G Stress fracture, unspecified ulna and radius, subsequent encounter for fracture with delayed',
  'healing',
  'M84339K Stress fracture, unspecified ulna and radius, subsequent encounter for fracture with nonunion',
  'M84339P Stress fracture, unspecified ulna and radius, subsequent encounter for fracture with malunion',
  'M84339S Stress fracture, unspecified ulna and radius, sequela',
  'M84341A Stress fracture, right hand, initial encounter for fracture',
  'M84341D Stress fracture, right hand, subsequent encounter for fracture with routine healing',
  'M84341G Stress fracture, right hand, subsequent encounter for fracture with delayed healing',
  'M84341K Stress fracture, right hand, subsequent encounter for fracture with nonunion',
  'M84341P Stress fracture, right hand, subsequent encounter for fracture with malunion',
  'M84341S Stress fracture, right hand, sequela',
  'M84342A Stress fracture, left hand, initial encounter for fracture',
  'M84342D Stress fracture, left hand, subsequent encounter for fracture with routine healing',
  'M84342G Stress fracture, left hand, subsequent encounter for fracture with delayed healing',
  'M84342K Stress fracture, left hand, subsequent encounter for fracture with nonunion',
  'M84342P Stress fracture, left hand, subsequent encounter for fracture with malunion',
  'M84342S Stress fracture, left hand, sequela',
  'M84343A Stress fracture, unspecified hand, initial encounter for fracture',
  'M84343D Stress fracture, unspecified hand, subsequent encounter for fracture with routine healing',
  'M84343G Stress fracture, unspecified hand, subsequent encounter for fracture with delayed healing',
  'M84343K Stress fracture, unspecified hand, subsequent encounter for fracture with nonunion',
  'M84343P Stress fracture, unspecified hand, subsequent encounter for fracture with malunion',
  'M84343S Stress fracture, unspecified hand, sequela',
  'M84344A Stress fracture, right finger(s), initial encounter for fracture',
  'M84344D Stress fracture, right finger(s), subsequent encounter for fracture with routine healing',
  'M84344G Stress fracture, right finger(s), subsequent encounter for fracture with delayed healing',
  'M84344K Stress fracture, right finger(s), subsequent encounter for fracture with nonunion',
  'M84344P Stress fracture, right finger(s), subsequent encounter for fracture with malunion',
  'M84344S Stress fracture, right finger(s), sequela',
  'M84345A Stress fracture, left finger(s), initial encounter for fracture',
  'M84345D Stress fracture, left finger(s), subsequent encounter for fracture with routine healing',
  'M84345G Stress fracture, left finger(s), subsequent encounter for fracture with delayed healing',
  'M84345K Stress fracture, left finger(s), subsequent encounter for fracture with nonunion',
  'M84345P Stress fracture, left finger(s), subsequent encounter for fracture with malunion',
  'M84345S Stress fracture, left finger(s), sequela',
  'M84346A Stress fracture, unspecified finger(s), initial encounter for fracture',
  'M84346D Stress fracture, unspecified finger(s), subsequent encounter for fracture with routine healing',
  'M84346G Stress fracture, unspecified finger(s), subsequent encounter for fracture with delayed healing',
  'M84346K Stress fracture, unspecified finger(s), subsequent encounter for fracture with nonunion',
  'M84346P Stress fracture, unspecified finger(s), subsequent encounter for fracture with malunion',
  'M84346S Stress fracture, unspecified finger(s), sequela',
  'M84350A Stress fracture, pelvis, initial encounter for fracture',
  'M84350D Stress fracture, pelvis, subsequent encounter for fracture with routine healing',
  'M84350G Stress fracture, pelvis, subsequent encounter for fracture with delayed healing',
  'M84350K Stress fracture, pelvis, subsequent encounter for fracture with nonunion',
  'M84350P Stress fracture, pelvis, subsequent encounter for fracture with malunion',
  'M84350S Stress fracture, pelvis, sequela',
  'M84351A Stress fracture, right femur, initial encounter for fracture',
  'M84351D Stress fracture, right femur, subsequent encounter for fracture with routine healing',
  'M84351G Stress fracture, right femur, subsequent encounter for fracture with delayed healing',
  'M84351K Stress fracture, right femur, subsequent encounter for fracture with nonunion',
  'M84351P Stress fracture, right femur, subsequent encounter for fracture with malunion',
  'M84351S Stress fracture, right femur, sequela',
  'M84352A Stress fracture, left femur, initial encounter for fracture',
  'M84352D Stress fracture, left femur, subsequent encounter for fracture with routine healing',
  'M84352G Stress fracture, left femur, subsequent encounter for fracture with delayed healing',
  'M84352K Stress fracture, left femur, subsequent encounter for fracture with nonunion',
  'M84352P Stress fracture, left femur, subsequent encounter for fracture with malunion',
  'M84352S Stress fracture, left femur, sequela',
  'M84353A Stress fracture, unspecified femur, initial encounter for fracture',
  'M84353D Stress fracture, unspecified femur, subsequent encounter for fracture with routine healing',
  'M84353G Stress fracture, unspecified femur, subsequent encounter for fracture with delayed healing',
  'M84353K Stress fracture, unspecified femur, subsequent encounter for fracture with nonunion',
  'M84353P Stress fracture, unspecified femur, subsequent encounter for fracture with malunion',
  'M84353S Stress fracture, unspecified femur, sequela',
  'M84359A Stress fracture, hip, unspecified, initial encounter for fracture',
  'M84359D Stress fracture, hip, unspecified, subsequent encounter for fracture with routine healing',
  'M84359G Stress fracture, hip, unspecified, subsequent encounter for fracture with delayed healing',
  'M84359K Stress fracture, hip, unspecified, subsequent encounter for fracture with nonunion',
  'M84359P Stress fracture, hip, unspecified, subsequent encounter for fracture with malunion',
  'M84359S Stress fracture, hip, unspecified, sequela',
  'M84361A Stress fracture, right tibia, initial encounter for fracture',
  'M84361D Stress fracture, right tibia, subsequent encounter for fracture with routine healing',
  'M84361G Stress fracture, right tibia, subsequent encounter for fracture with delayed healing',
  'M84361K Stress fracture, right tibia, subsequent encounter for fracture with nonunion',
  'M84361P Stress fracture, right tibia, subsequent encounter for fracture with malunion',
  'M84361S Stress fracture, right tibia, sequela',
  'M84362A Stress fracture, left tibia, initial encounter for fracture',
  'M84362D Stress fracture, left tibia, subsequent encounter for fracture with routine healing',
  'M84362G Stress fracture, left tibia, subsequent encounter for fracture with delayed healing',
  'M84362K Stress fracture, left tibia, subsequent encounter for fracture with nonunion',
  'M84362P Stress fracture, left tibia, subsequent encounter for fracture with malunion',
  'M84362S Stress fracture, left tibia, sequela',
  'M84363A Stress fracture, right fibula, initial encounter for fracture',
  'M84363D Stress fracture, right fibula, subsequent encounter for fracture with routine healing',
  'M84363G Stress fracture, right fibula, subsequent encounter for fracture with delayed healing',
  'M84363K Stress fracture, right fibula, subsequent encounter for fracture with nonunion',
  'M84363P Stress fracture, right fibula, subsequent encounter for fracture with malunion',
  'M84363S Stress fracture, right fibula, sequela',
  'M84364A Stress fracture, left fibula, initial encounter for fracture',
  'M84364D Stress fracture, left fibula, subsequent encounter for fracture with routine healing',
  'M84364G Stress fracture, left fibula, subsequent encounter for fracture with delayed healing',
  'M84364K Stress fracture, left fibula, subsequent encounter for fracture with nonunion',
  'M84364P Stress fracture, left fibula, subsequent encounter for fracture with malunion',
  'M84364S Stress fracture, left fibula, sequela',
  'M84369A Stress fracture, unspecified tibia and fibula, initial encounter for fracture',
  'M84369D Stress fracture, unspecified tibia and fibula, subsequent encounter for fracture with routine',
  'healing',
  'M84369G Stress fracture, unspecified tibia and fibula, subsequent encounter for fracture with delayed',
  'healing',
  'M84369K Stress fracture, unspecified tibia and fibula, subsequent encounter for fracture with nonunion',
  'M84369P Stress fracture, unspecified tibia and fibula, subsequent encounter for fracture with malunion',
  'M84369S Stress fracture, unspecified tibia and fibula, sequela',
  'M84371A Stress fracture, right ankle, initial encounter for fracture',
  'M84371D Stress fracture, right ankle, subsequent encounter for fracture with routine healing',
  'M84371G Stress fracture, right ankle, subsequent encounter for fracture with delayed healing',
  'M84371K Stress fracture, right ankle, subsequent encounter for fracture with nonunion',
  'M84371P Stress fracture, right ankle, subsequent encounter for fracture with malunion',
  'M84371S Stress fracture, right ankle, sequela',
  'M84372A Stress fracture, left ankle, initial encounter for fracture',
  'M84372D Stress fracture, left ankle, subsequent encounter for fracture with routine healing',
  'M84372G Stress fracture, left ankle, subsequent encounter for fracture with delayed healing',
  'M84372K Stress fracture, left ankle, subsequent encounter for fracture with nonunion',
  'M84372P Stress fracture, left ankle, subsequent encounter for fracture with malunion',
  'M84372S Stress fracture, left ankle, sequela',
  'M84373A Stress fracture, unspecified ankle, initial encounter for fracture',
  'M84373D Stress fracture, unspecified ankle, subsequent encounter for fracture with routine healing',
  'M84373G Stress fracture, unspecified ankle, subsequent encounter for fracture with delayed healing',
  'M84373K Stress fracture, unspecified ankle, subsequent encounter for fracture with nonunion',
  'M84373P Stress fracture, unspecified ankle, subsequent encounter for fracture with malunion',
  'M84373S Stress fracture, unspecified ankle, sequela',
  'M84374A Stress fracture, right foot, initial encounter for fracture',
  'M84374D Stress fracture, right foot, subsequent encounter for fracture with routine healing',
  'M84374G Stress fracture, right foot, subsequent encounter for fracture with delayed healing',
  'M84374K Stress fracture, right foot, subsequent encounter for fracture with nonunion',
  'M84374P Stress fracture, right foot, subsequent encounter for fracture with malunion',
  'M84374S Stress fracture, right foot, sequela',
  'M84375A Stress fracture, left foot, initial encounter for fracture',
  'M84375D Stress fracture, left foot, subsequent encounter for fracture with routine healing',
  'M84375G Stress fracture, left foot, subsequent encounter for fracture with delayed healing',
  'M84375K Stress fracture, left foot, subsequent encounter for fracture with nonunion',
  'M84375P Stress fracture, left foot, subsequent encounter for fracture with malunion',
  'M84375S Stress fracture, left foot, sequela',
  'M84376A Stress fracture, unspecified foot, initial encounter for fracture',
  'M84376D Stress fracture, unspecified foot, subsequent encounter for fracture with routine healing',
  'M84376G Stress fracture, unspecified foot, subsequent encounter for fracture with delayed healing',
  'M84376K Stress fracture, unspecified foot, subsequent encounter for fracture with nonunion',
  'M84376P Stress fracture, unspecified foot, subsequent encounter for fracture with malunion',
  'M84376S Stress fracture, unspecified foot, sequela',
  'M84377A Stress fracture, right toe(s), initial encounter for fracture',
  'M84377D Stress fracture, right toe(s), subsequent encounter for fracture with routine healing',
  'M84377G Stress fracture, right toe(s), subsequent encounter for fracture with delayed healing',
  'M84377K Stress fracture, right toe(s), subsequent encounter for fracture with nonunion',
  'M84377P Stress fracture, right toe(s), subsequent encounter for fracture with malunion',
  'M84377S Stress fracture, right toe(s), sequela',
  'M84378A Stress fracture, left toe(s), initial encounter for fracture',
  'M84378D Stress fracture, left toe(s), subsequent encounter for fracture with routine healing',
  'M84378G Stress fracture, left toe(s), subsequent encounter for fracture with delayed healing',
  'M84378K Stress fracture, left toe(s), subsequent encounter for fracture with nonunion',
  'M84378P Stress fracture, left toe(s), subsequent encounter for fracture with malunion',
  'M84378S Stress fracture, left toe(s), sequela',
  'M84379A Stress fracture, unspecified toe(s), initial encounter for fracture',
  'M84379D Stress fracture, unspecified toe(s), subsequent encounter for fracture with routine healing',
  'M84379G Stress fracture, unspecified toe(s), subsequent encounter for fracture with delayed healing',
  'M84379K Stress fracture, unspecified toe(s), subsequent encounter for fracture with nonunion',
  'M84379P Stress fracture, unspecified toe(s), subsequent encounter for fracture with malunion',
  'M84379S Stress fracture, unspecified toe(s), sequela',
  'M8438XA Stress fracture, other site, initial encounter for fracture',
  'M8438XD Stress fracture, other site, subsequent encounter for fracture with routine healing',
  'M8438XG Stress fracture, other site, subsequent encounter for fracture with delayed healing',
  'M8438XK Stress fracture, other site, subsequent encounter for fracture with nonunion',
  'M8438XP Stress fracture, other site, subsequent encounter for fracture with malunion',
  'M8438XS Stress fracture, other site, sequela',
  'M8440XA Pathological fracture, unspecified site, initial encounter for fracture',
  'M8440XD Pathological fracture, unspecified site, subsequent encounter for fracture with routine healing',
  'M8440XG Pathological fracture, unspecified site, subsequent encounter for fracture with delayed',
  'healing',
  'M8440XK Pathological fracture, unspecified site, subsequent encounter for fracture with nonunion',
  'M8440XP Pathological fracture, unspecified site, subsequent encounter for fracture with malunion',
  'M8440XS Pathological fracture, unspecified site, sequela',
  'M84411A Pathological fracture, right shoulder, initial encounter for fracture',
  'M84411D Pathological fracture, right shoulder, subsequent encounter for fracture with routine healing',
  'M84411G Pathological fracture, right shoulder, subsequent encounter for fracture with delayed healing',
  'M84411K Pathological fracture, right shoulder, subsequent encounter for fracture with nonunion',
  'M84411P Pathological fracture, right shoulder, subsequent encounter for fracture with malunion',
  'M84411S Pathological fracture, right shoulder, sequela',
  'M84412A Pathological fracture, left shoulder, initial encounter for fracture',
  'M84412D Pathological fracture, left shoulder, subsequent encounter for fracture with routine healing',
  'M84412G Pathological fracture, left shoulder, subsequent encounter for fracture with delayed healing',
  'M84412K Pathological fracture, left shoulder, subsequent encounter for fracture with nonunion',
  'M84412P Pathological fracture, left shoulder, subsequent encounter for fracture with malunion',
  'M84412S Pathological fracture, left shoulder, sequela',
  'M84419A Pathological fracture, unspecified shoulder, initial encounter for fracture',
  'M84419D Pathological fracture, unspecified shoulder, subsequent encounter for fracture with routine',
  'healing',
  'M84419G Pathological fracture, unspecified shoulder, subsequent encounter for fracture with delayed',
  'healing',
  'M84419K Pathological fracture, unspecified shoulder, subsequent encounter for fracture with nonunion',
  'M84419P Pathological fracture, unspecified shoulder, subsequent encounter for fracture with malunion',
  'M84419S Pathological fracture, unspecified shoulder, sequela',
  'M84421A Pathological fracture, right humerus, initial encounter for fracture',
  'M84421D Pathological fracture, right humerus, subsequent encounter for fracture with routine healing',
  'M84421G Pathological fracture, right humerus, subsequent encounter for fracture with delayed healing',
  'M84421K Pathological fracture, right humerus, subsequent encounter for fracture with nonunion',
  'M84421P Pathological fracture, right humerus, subsequent encounter for fracture with malunion',
  'M84421S Pathological fracture, right humerus, sequela',
  'M84422A Pathological fracture, left humerus, initial encounter for fracture',
  'M84422D Pathological fracture, left humerus, subsequent encounter for fracture with routine healing',
  'M84422G Pathological fracture, left humerus, subsequent encounter for fracture with delayed healing',
  'M84422K Pathological fracture, left humerus, subsequent encounter for fracture with nonunion',
  'M84422P Pathological fracture, left humerus, subsequent encounter for fracture with malunion',
  'M84422S Pathological fracture, left humerus, sequela',
  'M84429A Pathological fracture, unspecified humerus, initial encounter for fracture',
  'M84429D Pathological fracture, unspecified humerus, subsequent encounter for fracture with routine',
  'healing',
  'M84429G Pathological fracture, unspecified humerus, subsequent encounter for fracture with delayed',
  'healing',
  'M84429K Pathological fracture, unspecified humerus, subsequent encounter for fracture with nonunion',
  'M84429P Pathological fracture, unspecified humerus, subsequent encounter for fracture with malunion',
  'M84429S Pathological fracture, unspecified humerus, sequela',
  'M84431A Pathological fracture, right ulna, initial encounter for fracture',
  'M84431D Pathological fracture, right ulna, subsequent encounter for fracture with routine healing',
  'M84431G Pathological fracture, right ulna, subsequent encounter for fracture with delayed healing',
  'M84431K Pathological fracture, right ulna, subsequent encounter for fracture with nonunion',
  'M84431P Pathological fracture, right ulna, subsequent encounter for fracture with malunion',
  'M84431S Pathological fracture, right ulna, sequela',
  'M84432A Pathological fracture, left ulna, initial encounter for fracture',
  'M84432D Pathological fracture, left ulna, subsequent encounter for fracture with routine healing',
  'M84432G Pathological fracture, left ulna, subsequent encounter for fracture with delayed healing',
  'M84432K Pathological fracture, left ulna, subsequent encounter for fracture with nonunion',
  'M84432P Pathological fracture, left ulna, subsequent encounter for fracture with malunion',
  'M84432S Pathological fracture, left ulna, sequela',
  'M84433A Pathological fracture, right radius, initial encounter for fracture',
  'M84433D Pathological fracture, right radius, subsequent encounter for fracture with routine healing',
  'M84433G Pathological fracture, right radius, subsequent encounter for fracture with delayed healing',
  'M84433K Pathological fracture, right radius, subsequent encounter for fracture with nonunion',
  'M84433P Pathological fracture, right radius, subsequent encounter for fracture with malunion',
  'M84433S Pathological fracture, right radius, sequela',
  'M84434A Pathological fracture, left radius, initial encounter for fracture',
  'M84434D Pathological fracture, left radius, subsequent encounter for fracture with routine healing',
  'M84434G Pathological fracture, left radius, subsequent encounter for fracture with delayed healing',
  'M84434K Pathological fracture, left radius, subsequent encounter for fracture with nonunion',
  'M84434P Pathological fracture, left radius, subsequent encounter for fracture with malunion',
  'M84434S Pathological fracture, left radius, sequela',
  'M84439A Pathological fracture, unspecified ulna and radius, initial encounter for fracture',
  'M84439D Pathological fracture, unspecified ulna and radius, subsequent encounter for fracture with',
  'routine healing',
  'M84439G Pathological fracture, unspecified ulna and radius, subsequent encounter for fracture with',
  'delayed healing',
  'M84439K Pathological fracture, unspecified ulna and radius, subsequent encounter for fracture with',
  'nonunion',
  'M84439P Pathological fracture, unspecified ulna and radius, subsequent encounter for fracture with',
  'malunion',
  'M84439S Pathological fracture, unspecified ulna and radius, sequela',
  'M84441A Pathological fracture, right hand, initial encounter for fracture',
  'M84441D Pathological fracture, right hand, subsequent encounter for fracture with routine healing',
  'M84441G Pathological fracture, right hand, subsequent encounter for fracture with delayed healing',
  'M84441K Pathological fracture, right hand, subsequent encounter for fracture with nonunion',
  'M84441P Pathological fracture, right hand, subsequent encounter for fracture with malunion',
  'M84441S Pathological fracture, right hand, sequela',
  'M84442A Pathological fracture, left hand, initial encounter for fracture',
  'M84442D Pathological fracture, left hand, subsequent encounter for fracture with routine healing',
  'M84442G Pathological fracture, left hand, subsequent encounter for fracture with delayed healing',
  'M84442K Pathological fracture, left hand, subsequent encounter for fracture with nonunion',
  'M84442P Pathological fracture, left hand, subsequent encounter for fracture with malunion',
  'M84442S Pathological fracture, left hand, sequela',
  'M84443A Pathological fracture, unspecified hand, initial encounter for fracture',
  'M84443D Pathological fracture, unspecified hand, subsequent encounter for fracture with routine',
  'healing',
  'M84443G Pathological fracture, unspecified hand, subsequent encounter for fracture with delayed',
  'healing',
  'M84443K Pathological fracture, unspecified hand, subsequent encounter for fracture with nonunion',
  'M84443P Pathological fracture, unspecified hand, subsequent encounter for fracture with malunion',
  'M84443S Pathological fracture, unspecified hand, sequela',
  'M84444A Pathological fracture, right finger(s), initial encounter for fracture',
  'M84444D Pathological fracture, right finger(s), subsequent encounter for fracture with routine healing',
  'M84444G Pathological fracture, right finger(s), subsequent encounter for fracture with delayed healing',
  'M84444K Pathological fracture, right finger(s), subsequent encounter for fracture with nonunion',
  'M84444P Pathological fracture, right finger(s), subsequent encounter for fracture with malunion',
  'M84444S Pathological fracture, right finger(s), sequela',
  'M84445A Pathological fracture, left finger(s), initial encounter for fracture',
  'M84445D Pathological fracture, left finger(s), subsequent encounter for fracture with routine healing',
  'M84445G Pathological fracture, left finger(s), subsequent encounter for fracture with delayed healing',
  'M84445K Pathological fracture, left finger(s), subsequent encounter for fracture with nonunion',
  'M84445P Pathological fracture, left finger(s), subsequent encounter for fracture with malunion',
  'M84445S Pathological fracture, left finger(s), sequela',
  'M84446A Pathological fracture, unspecified finger(s), initial encounter for fracture',
  'M84446D Pathological fracture, unspecified finger(s), subsequent encounter for fracture with routine',
  'healing',
  'M84446G Pathological fracture, unspecified finger(s), subsequent encounter for fracture with delayed',
  'healing',
  'M84446K Pathological fracture, unspecified finger(s), subsequent encounter for fracture with nonunion',
  'M84446P Pathological fracture, unspecified finger(s), subsequent encounter for fracture with malunion',
  'M84446S Pathological fracture, unspecified finger(s), sequela',
  'M84451A Pathological fracture, right femur, initial encounter for fracture',
  'M84451D Pathological fracture, right femur, subsequent encounter for fracture with routine healing',
  'M84451G Pathological fracture, right femur, subsequent encounter for fracture with delayed healing',
  'M84451K Pathological fracture, right femur, subsequent encounter for fracture with nonunion',
  'M84451P Pathological fracture, right femur, subsequent encounter for fracture with malunion',
  'M84451S Pathological fracture, right femur, sequela',
  'M84452A Pathological fracture, left femur, initial encounter for fracture',
  'M84452D Pathological fracture, left femur, subsequent encounter for fracture with routine healing',
  'M84452G Pathological fracture, left femur, subsequent encounter for fracture with delayed healing',
  'M84452K Pathological fracture, left femur, subsequent encounter for fracture with nonunion',
  'M84452P Pathological fracture, left femur, subsequent encounter for fracture with malunion',
  'M84452S Pathological fracture, left femur, sequela',
  'M84453A Pathological fracture, unspecified femur, initial encounter for fracture',
  'M84453D Pathological fracture, unspecified femur, subsequent encounter for fracture with routine',
  'healing',
  'M84453G Pathological fracture, unspecified femur, subsequent encounter for fracture with delayed',
  'healing',
  'M84453K Pathological fracture, unspecified femur, subsequent encounter for fracture with nonunion',
  'M84453P Pathological fracture, unspecified femur, subsequent encounter for fracture with malunion',
  'M84453S Pathological fracture, unspecified femur, sequela',
  'M84454A Pathological fracture, pelvis, initial encounter for fracture',
  'M84454D Pathological fracture, pelvis, subsequent encounter for fracture with routine healing',
  'M84454G Pathological fracture, pelvis, subsequent encounter for fracture with delayed healing',
  'M84454K Pathological fracture, pelvis, subsequent encounter for fracture with nonunion',
  'M84454P Pathological fracture, pelvis, subsequent encounter for fracture with malunion',
  'M84454S Pathological fracture, pelvis, sequela',
  'M84459A Pathological fracture, hip, unspecified, initial encounter for fracture',
  'M84459D Pathological fracture, hip, unspecified, subsequent encounter for fracture with routine',
  'healing',
  'M84459G Pathological fracture, hip, unspecified, subsequent encounter for fracture with delayed',
  'healing',
  'M84459K Pathological fracture, hip, unspecified, subsequent encounter for fracture with nonunion',
  'M84459P Pathological fracture, hip, unspecified, subsequent encounter for fracture with malunion',
  'M84459S Pathological fracture, hip, unspecified, sequela',
  'M84461A Pathological fracture, right tibia, initial encounter for fracture',
  'M84461D Pathological fracture, right tibia, subsequent encounter for fracture with routine healing',
  'M84461G Pathological fracture, right tibia, subsequent encounter for fracture with delayed healing',
  'M84461K Pathological fracture, right tibia, subsequent encounter for fracture with nonunion',
  'M84461P Pathological fracture, right tibia, subsequent encounter for fracture with malunion',
  'M84461S Pathological fracture, right tibia, sequela',
  'M84462A Pathological fracture, left tibia, initial encounter for fracture',
  'M84462D Pathological fracture, left tibia, subsequent encounter for fracture with routine healing',
  'M84462G Pathological fracture, left tibia, subsequent encounter for fracture with delayed healing',
  'M84462K Pathological fracture, left tibia, subsequent encounter for fracture with nonunion',
  'M84462P Pathological fracture, left tibia, subsequent encounter for fracture with malunion',
  'M84462S Pathological fracture, left tibia, sequela',
  'M84463A Pathological fracture, right fibula, initial encounter for fracture',
  'M84463D Pathological fracture, right fibula, subsequent encounter for fracture with routine healing',
  'M84463G Pathological fracture, right fibula, subsequent encounter for fracture with delayed healing',
  'M84463K Pathological fracture, right fibula, subsequent encounter for fracture with nonunion',
  'M84463P Pathological fracture, right fibula, subsequent encounter for fracture with malunion',
  'M84463S Pathological fracture, right fibula, sequela',
  'M84464A Pathological fracture, left fibula, initial encounter for fracture',
  'M84464D Pathological fracture, left fibula, subsequent encounter for fracture with routine healing',
  'M84464G Pathological fracture, left fibula, subsequent encounter for fracture with delayed healing',
  'M84464K Pathological fracture, left fibula, subsequent encounter for fracture with nonunion',
  'M84464P Pathological fracture, left fibula, subsequent encounter for fracture with malunion',
  'M84464S Pathological fracture, left fibula, sequela',
  'M84469A Pathological fracture, unspecified tibia and fibula, initial encounter for fracture',
  'M84469D Pathological fracture, unspecified tibia and fibula, subsequent encounter for fracture with',
  'routine healing',
  'M84469G Pathological fracture, unspecified tibia and fibula, subsequent encounter for fracture with',
  'delayed healing',
  'M84469K Pathological fracture, unspecified tibia and fibula, subsequent encounter for fracture with',
  'nonunion',
  'M84469P Pathological fracture, unspecified tibia and fibula, subsequent encounter for fracture with',
  'malunion',
  'M84469S Pathological fracture, unspecified tibia and fibula, sequela',
  'M84471A Pathological fracture, right ankle, initial encounter for fracture',
  'M84471D Pathological fracture, right ankle, subsequent encounter for fracture with routine healing',
  'M84471G Pathological fracture, right ankle, subsequent encounter for fracture with delayed healing',
  'M84471K Pathological fracture, right ankle, subsequent encounter for fracture with nonunion',
  'M84471P Pathological fracture, right ankle, subsequent encounter for fracture with malunion',
  'M84471S Pathological fracture, right ankle, sequela',
  'M84472A Pathological fracture, left ankle, initial encounter for fracture',
  'M84472D Pathological fracture, left ankle, subsequent encounter for fracture with routine healing',
  'M84472G Pathological fracture, left ankle, subsequent encounter for fracture with delayed healing',
  'M84472K Pathological fracture, left ankle, subsequent encounter for fracture with nonunion',
  'M84472P Pathological fracture, left ankle, subsequent encounter for fracture with malunion',
  'M84472S Pathological fracture, left ankle, sequela',
  'M84473A Pathological fracture, unspecified ankle, initial encounter for fracture',
  'M84473D Pathological fracture, unspecified ankle, subsequent encounter for fracture with routine',
  'healing',
  'M84473G Pathological fracture, unspecified ankle, subsequent encounter for fracture with delayed',
  'healing',
  'M84473K Pathological fracture, unspecified ankle, subsequent encounter for fracture with nonunion',
  'M84473P Pathological fracture, unspecified ankle, subsequent encounter for fracture with malunion',
  'M84473S Pathological fracture, unspecified ankle, sequela',
  'M84474A Pathological fracture, right foot, initial encounter for fracture',
  'M84474D Pathological fracture, right foot, subsequent encounter for fracture with routine healing',
  'M84474G Pathological fracture, right foot, subsequent encounter for fracture with delayed healing',
  'M84474K Pathological fracture, right foot, subsequent encounter for fracture with nonunion',
  'M84474P Pathological fracture, right foot, subsequent encounter for fracture with malunion',
  'M84474S Pathological fracture, right foot, sequela',
  'M84475A Pathological fracture, left foot, initial encounter for fracture',
  'M84475D Pathological fracture, left foot, subsequent encounter for fracture with routine healing',
  'M84475G Pathological fracture, left foot, subsequent encounter for fracture with delayed healing',
  'M84475K Pathological fracture, left foot, subsequent encounter for fracture with nonunion',
  'M84475P Pathological fracture, left foot, subsequent encounter for fracture with malunion',
  'M84475S Pathological fracture, left foot, sequela',
  'M84476A Pathological fracture, unspecified foot, initial encounter for fracture',
  'M84476D Pathological fracture, unspecified foot, subsequent encounter for fracture with routine',
  'healing',
  'M84476G Pathological fracture, unspecified foot, subsequent encounter for fracture with delayed',
  'healing',
  'M84476K Pathological fracture, unspecified foot, subsequent encounter for fracture with nonunion',
  'M84476P Pathological fracture, unspecified foot, subsequent encounter for fracture with malunion',
  'M84476S Pathological fracture, unspecified foot, sequela',
  'M84477A Pathological fracture, right toe(s), initial encounter for fracture',
  'M84477D Pathological fracture, right toe(s), subsequent encounter for fracture with routine healing',
  'M84477G Pathological fracture, right toe(s), subsequent encounter for fracture with delayed healing',
  'M84477K Pathological fracture, right toe(s), subsequent encounter for fracture with nonunion',
  'M84477P Pathological fracture, right toe(s), subsequent encounter for fracture with malunion',
  'M84477S Pathological fracture, right toe(s), sequela',
  'M84478A Pathological fracture, left toe(s), initial encounter for fracture',
  'M84478D Pathological fracture, left toe(s), subsequent encounter for fracture with routine healing',
  'M84478G Pathological fracture, left toe(s), subsequent encounter for fracture with delayed healing',
  'M84478K Pathological fracture, left toe(s), subsequent encounter for fracture with nonunion',
  'M84478P Pathological fracture, left toe(s), subsequent encounter for fracture with malunion',
  'M84478S Pathological fracture, left toe(s), sequela',
  'M84479A Pathological fracture, unspecified toe(s), initial encounter for fracture',
  'M84479D Pathological fracture, unspecified toe(s), subsequent encounter for fracture with routine',
  'healing',
  'M84479G Pathological fracture, unspecified toe(s), subsequent encounter for fracture with delayed',
  'healing',
  'M84479K Pathological fracture, unspecified toe(s), subsequent encounter for fracture with nonunion',
  'M84479P Pathological fracture, unspecified toe(s), subsequent encounter for fracture with malunion',
  'M84479S Pathological fracture, unspecified toe(s), sequela',
  'M8448XA Pathological fracture, other site, initial encounter for fracture',
  'M8448XD Pathological fracture, other site, subsequent encounter for fracture with routine healing',
  'M8448XG Pathological fracture, other site, subsequent encounter for fracture with delayed healing',
  'M8448XK Pathological fracture, other site, subsequent encounter for fracture with nonunion',
  'M8448XP Pathological fracture, other site, subsequent encounter for fracture with malunion',
  'M8448XS Pathological fracture, other site, sequela',
  'M8450XA Pathological fracture in neoplastic disease, unspecified site, initial encounter for fracture',
  'M8450XD Pathological fracture in neoplastic disease, unspecified site, subsequent encounter for',
  'fracture with routine healing',
  'M8450XG Pathological fracture in neoplastic disease, unspecified site, subsequent encounter for',
  'fracture with delayed healing',
  'M8450XK Pathological fracture in neoplastic disease, unspecified site, subsequent encounter for',
  'fracture with nonunion',
  'M8450XP Pathological fracture in neoplastic disease, unspecified site, subsequent encounter for',
  'fracture with malunion',
  'M8450XS Pathological fracture in neoplastic disease, unspecified site, sequela',
  'M84511A Pathological fracture in neoplastic disease, right shoulder, initial encounter for fracture',
  'M84511D Pathological fracture in neoplastic disease, right shoulder, subsequent encounter for fracture',
  'with routine healing',
  'M84511G Pathological fracture in neoplastic disease, right shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'M84511K Pathological fracture in neoplastic disease, right shoulder, subsequent encounter for fracture',
  'with nonunion',
  'M84511P Pathological fracture in neoplastic disease, right shoulder, subsequent encounter for fracture',
  'with malunion',
  'M84511S Pathological fracture in neoplastic disease, right shoulder, sequela',
  'M84512A Pathological fracture in neoplastic disease, left shoulder, initial encounter for fracture',
  'M84512D Pathological fracture in neoplastic disease, left shoulder, subsequent encounter for fracture',
  'with routine healing',
  'M84512G Pathological fracture in neoplastic disease, left shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'M84512K Pathological fracture in neoplastic disease, left shoulder, subsequent encounter for fracture',
  'with nonunion',
  'M84512P Pathological fracture in neoplastic disease, left shoulder, subsequent encounter for fracture',
  'with malunion',
  'M84512S Pathological fracture in neoplastic disease, left shoulder, sequela',
  'M84519A Pathological fracture in neoplastic disease, unspecified shoulder, initial encounter for fracture',
  'M84519D Pathological fracture in neoplastic disease, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'M84519G Pathological fracture in neoplastic disease, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'M84519K Pathological fracture in neoplastic disease, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'M84519P Pathological fracture in neoplastic disease, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'M84519S Pathological fracture in neoplastic disease, unspecified shoulder, sequela',
  'M84521A Pathological fracture in neoplastic disease, right humerus, initial encounter for fracture',
  'M84521D Pathological fracture in neoplastic disease, right humerus, subsequent encounter for fracture',
  'with routine healing',
  'M84521G Pathological fracture in neoplastic disease, right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'M84521K Pathological fracture in neoplastic disease, right humerus, subsequent encounter for fracture',
  'with nonunion',
  'M84521P Pathological fracture in neoplastic disease, right humerus, subsequent encounter for fracture',
  'with malunion',
  'M84521S Pathological fracture in neoplastic disease, right humerus, sequela',
  'M84522A Pathological fracture in neoplastic disease, left humerus, initial encounter for fracture',
  'M84522D Pathological fracture in neoplastic disease, left humerus, subsequent encounter for fracture',
  'with routine healing',
  'M84522G Pathological fracture in neoplastic disease, left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'M84522K Pathological fracture in neoplastic disease, left humerus, subsequent encounter for fracture',
  'with nonunion',
  'M84522P Pathological fracture in neoplastic disease, left humerus, subsequent encounter for fracture',
  'with malunion',
  'M84522S Pathological fracture in neoplastic disease, left humerus, sequela',
  'M84529A Pathological fracture in neoplastic disease, unspecified humerus, initial encounter for fracture',
  'M84529D Pathological fracture in neoplastic disease, unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'M84529G Pathological fracture in neoplastic disease, unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'M84529K Pathological fracture in neoplastic disease, unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'M84529P Pathological fracture in neoplastic disease, unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'M84529S Pathological fracture in neoplastic disease, unspecified humerus, sequela',
  'M84531A Pathological fracture in neoplastic disease, right ulna, initial encounter for fracture',
  'M84531D Pathological fracture in neoplastic disease, right ulna, subsequent encounter for fracture with',
  'routine healing',
  'M84531G Pathological fracture in neoplastic disease, right ulna, subsequent encounter for fracture with',
  'delayed healing',
  'M84531K Pathological fracture in neoplastic disease, right ulna, subsequent encounter for fracture with',
  'nonunion',
  'M84531P Pathological fracture in neoplastic disease, right ulna, subsequent encounter for fracture with',
  'malunion',
  'M84531S Pathological fracture in neoplastic disease, right ulna, sequela',
  'M84532A Pathological fracture in neoplastic disease, left ulna, initial encounter for fracture',
  'M84532D Pathological fracture in neoplastic disease, left ulna, subsequent encounter for fracture with',
  'routine healing',
  'M84532G Pathological fracture in neoplastic disease, left ulna, subsequent encounter for fracture with',
  'delayed healing',
  'M84532K Pathological fracture in neoplastic disease, left ulna, subsequent encounter for fracture with',
  'nonunion',
  'M84532P Pathological fracture in neoplastic disease, left ulna, subsequent encounter for fracture with',
  'malunion',
  'M84532S Pathological fracture in neoplastic disease, left ulna, sequela',
  'M84533A Pathological fracture in neoplastic disease, right radius, initial encounter for fracture',
  'M84533D Pathological fracture in neoplastic disease, right radius, subsequent encounter for fracture',
  'with routine healing',
  'M84533G Pathological fracture in neoplastic disease, right radius, subsequent encounter for fracture',
  'with delayed healing',
  'M84533K Pathological fracture in neoplastic disease, right radius, subsequent encounter for fracture',
  'with nonunion',
  'M84533P Pathological fracture in neoplastic disease, right radius, subsequent encounter for fracture',
  'with malunion',
  'M84533S Pathological fracture in neoplastic disease, right radius, sequela',
  'M84534A Pathological fracture in neoplastic disease, left radius, initial encounter for fracture',
  'M84534D Pathological fracture in neoplastic disease, left radius, subsequent encounter for fracture with',
  'routine healing',
  'M84534G Pathological fracture in neoplastic disease, left radius, subsequent encounter for fracture with',
  'delayed healing',
  'M84534K Pathological fracture in neoplastic disease, left radius, subsequent encounter for fracture with',
  'nonunion',
  'M84534P Pathological fracture in neoplastic disease, left radius, subsequent encounter for fracture with',
  'malunion',
  'M84534S Pathological fracture in neoplastic disease, left radius, sequela',
  'M84539A Pathological fracture in neoplastic disease, unspecified ulna and radius, initial encounter for',
  'fracture',
  'M84539D Pathological fracture in neoplastic disease, unspecified ulna and radius, subsequent',
  'encounter for fracture with routine healing',
  'M84539G Pathological fracture in neoplastic disease, unspecified ulna and radius, subsequent',
  'encounter for fracture with delayed healing',
  'M84539K Pathological fracture in neoplastic disease, unspecified ulna and radius, subsequent encounter',
  'for fracture with nonunion',
  'M84539P Pathological fracture in neoplastic disease, unspecified ulna and radius, subsequent encounter',
  'for fracture with malunion',
  'M84539S Pathological fracture in neoplastic disease, unspecified ulna and radius, sequela',
  'M84541A Pathological fracture in neoplastic disease, right hand, initial encounter for fracture',
  'M84541D Pathological fracture in neoplastic disease, right hand, subsequent encounter for fracture with',
  'routine healing',
  'M84541G Pathological fracture in neoplastic disease, right hand, subsequent encounter for fracture',
  'with delayed healing',
  'M84541K Pathological fracture in neoplastic disease, right hand, subsequent encounter for fracture with',
  'nonunion',
  'M84541P Pathological fracture in neoplastic disease, right hand, subsequent encounter for fracture with',
  'malunion',
  'M84541S Pathological fracture in neoplastic disease, right hand, sequela',
  'M84542A Pathological fracture in neoplastic disease, left hand, initial encounter for fracture',
  'M84542D Pathological fracture in neoplastic disease, left hand, subsequent encounter for fracture with',
  'routine healing',
  'M84542G Pathological fracture in neoplastic disease, left hand, subsequent encounter for fracture with',
  'delayed healing',
  'M84542K Pathological fracture in neoplastic disease, left hand, subsequent encounter for fracture with',
  'nonunion',
  'M84542P Pathological fracture in neoplastic disease, left hand, subsequent encounter for fracture with',
  'malunion',
  'M84542S Pathological fracture in neoplastic disease, left hand, sequela',
  'M84549A Pathological fracture in neoplastic disease, unspecified hand, initial encounter for fracture',
  'M84549D Pathological fracture in neoplastic disease, unspecified hand, subsequent encounter for',
  'fracture with routine healing',
  'M84549G Pathological fracture in neoplastic disease, unspecified hand, subsequent encounter for',
  'fracture with delayed healing',
  'M84549K Pathological fracture in neoplastic disease, unspecified hand, subsequent encounter for',
  'fracture with nonunion',
  'M84549P Pathological fracture in neoplastic disease, unspecified hand, subsequent encounter for',
  'fracture with malunion',
  'M84549S Pathological fracture in neoplastic disease, unspecified hand, sequela',
  'M84550A Pathological fracture in neoplastic disease, pelvis, initial encounter for fracture',
  'M84550D Pathological fracture in neoplastic disease, pelvis, subsequent encounter for fracture with',
  'routine healing',
  'M84550G Pathological fracture in neoplastic disease, pelvis, subsequent encounter for fracture with',
  'delayed healing',
  'M84550K Pathological fracture in neoplastic disease, pelvis, subsequent encounter for fracture with',
  'nonunion',
  'M84550P Pathological fracture in neoplastic disease, pelvis, subsequent encounter for fracture with',
  'malunion',
  'M84550S Pathological fracture in neoplastic disease, pelvis, sequela',
  'M84551A Pathological fracture in neoplastic disease, right femur, initial encounter for fracture',
  'M84551D Pathological fracture in neoplastic disease, right femur, subsequent encounter for fracture',
  'with routine healing',
  'M84551G Pathological fracture in neoplastic disease, right femur, subsequent encounter for fracture',
  'with delayed healing',
  'M84551K Pathological fracture in neoplastic disease, right femur, subsequent encounter for fracture',
  'with nonunion',
  'M84551P Pathological fracture in neoplastic disease, right femur, subsequent encounter for fracture',
  'with malunion',
  'M84551S Pathological fracture in neoplastic disease, right femur, sequela',
  'M84552A Pathological fracture in neoplastic disease, left femur, initial encounter for fracture',
  'M84552D Pathological fracture in neoplastic disease, left femur, subsequent encounter for fracture with',
  'routine healing',
  'M84552G Pathological fracture in neoplastic disease, left femur, subsequent encounter for fracture with',
  'delayed healing',
  'M84552K Pathological fracture in neoplastic disease, left femur, subsequent encounter for fracture with',
  'nonunion',
  'M84552P Pathological fracture in neoplastic disease, left femur, subsequent encounter for fracture with',
  'malunion',
  'M84552S Pathological fracture in neoplastic disease, left femur, sequela',
  'M84553A Pathological fracture in neoplastic disease, unspecified femur, initial encounter for fracture',
  'M84553D Pathological fracture in neoplastic disease, unspecified femur, subsequent encounter for',
  'fracture with routine healing',
  'M84553G Pathological fracture in neoplastic disease, unspecified femur, subsequent encounter for',
  'fracture with delayed healing',
  'M84553K Pathological fracture in neoplastic disease, unspecified femur, subsequent encounter for',
  'fracture with nonunion',
  'M84553P Pathological fracture in neoplastic disease, unspecified femur, subsequent encounter for',
  'fracture with malunion',
  'M84553S Pathological fracture in neoplastic disease, unspecified femur, sequela',
  'M84559A Pathological fracture in neoplastic disease, hip, unspecified, initial encounter for fracture',
  'M84559D Pathological fracture in neoplastic disease, hip, unspecified, subsequent encounter for',
  'fracture with routine healing',
  'M84559G Pathological fracture in neoplastic disease, hip, unspecified, subsequent encounter for',
  'fracture with delayed healing',
  'M84559K Pathological fracture in neoplastic disease, hip, unspecified, subsequent encounter for',
  'fracture with nonunion',
  'M84559P Pathological fracture in neoplastic disease, hip, unspecified, subsequent encounter for',
  'fracture with malunion',
  'M84559S Pathological fracture in neoplastic disease, hip, unspecified, sequela',
  'M84561A Pathological fracture in neoplastic disease, right tibia, initial encounter for fracture',
  'M84561D Pathological fracture in neoplastic disease, right tibia, subsequent encounter for fracture with',
  'routine healing',
  'M84561G Pathological fracture in neoplastic disease, right tibia, subsequent encounter for fracture with',
  'delayed healing',
  'M84561K Pathological fracture in neoplastic disease, right tibia, subsequent encounter for fracture with',
  'nonunion',
  'M84561P Pathological fracture in neoplastic disease, right tibia, subsequent encounter for fracture with',
  'malunion',
  'M84561S Pathological fracture in neoplastic disease, right tibia, sequela',
  'M84562A Pathological fracture in neoplastic disease, left tibia, initial encounter for fracture',
  'M84562D Pathological fracture in neoplastic disease, left tibia, subsequent encounter for fracture with',
  'routine healing',
  'M84562G Pathological fracture in neoplastic disease, left tibia, subsequent encounter for fracture with',
  'delayed healing',
  'M84562K Pathological fracture in neoplastic disease, left tibia, subsequent encounter for fracture with',
  'nonunion',
  'M84562P Pathological fracture in neoplastic disease, left tibia, subsequent encounter for fracture with',
  'malunion',
  'M84562S Pathological fracture in neoplastic disease, left tibia, sequela',
  'M84563A Pathological fracture in neoplastic disease, right fibula, initial encounter for fracture',
  'M84563D Pathological fracture in neoplastic disease, right fibula, subsequent encounter for fracture',
  'with routine healing',
  'M84563G Pathological fracture in neoplastic disease, right fibula, subsequent encounter for fracture',
  'with delayed healing',
  'M84563K Pathological fracture in neoplastic disease, right fibula, subsequent encounter for fracture',
  'with nonunion',
  'M84563P Pathological fracture in neoplastic disease, right fibula, subsequent encounter for fracture',
  'with malunion',
  'M84563S Pathological fracture in neoplastic disease, right fibula, sequela',
  'M84564A Pathological fracture in neoplastic disease, left fibula, initial encounter for fracture',
  'M84564D Pathological fracture in neoplastic disease, left fibula, subsequent encounter for fracture with',
  'routine healing',
  'M84564G Pathological fracture in neoplastic disease, left fibula, subsequent encounter for fracture with',
  'delayed healing',
  'M84564K Pathological fracture in neoplastic disease, left fibula, subsequent encounter for fracture with',
  'nonunion',
  'M84564P Pathological fracture in neoplastic disease, left fibula, subsequent encounter for fracture with',
  'malunion',
  'M84564S Pathological fracture in neoplastic disease, left fibula, sequela',
  'M84569A Pathological fracture in neoplastic disease, unspecified tibia and fibula, initial encounter for',
  'fracture',
  'M84569D Pathological fracture in neoplastic disease, unspecified tibia and fibula, subsequent encounter',
  'for fracture with routine healing',
  'M84569G Pathological fracture in neoplastic disease, unspecified tibia and fibula, subsequent encounter',
  'for fracture with delayed healing',
  'M84569K Pathological fracture in neoplastic disease, unspecified tibia and fibula, subsequent encounter',
  'for fracture with nonunion',
  'M84569P Pathological fracture in neoplastic disease, unspecified tibia and fibula, subsequent encounter',
  'for fracture with malunion',
  'M84569S Pathological fracture in neoplastic disease, unspecified tibia and fibula, sequela',
  'M84571A Pathological fracture in neoplastic disease, right ankle, initial encounter for fracture',
  'M84571D Pathological fracture in neoplastic disease, right ankle, subsequent encounter for fracture',
  'with routine healing',
  'M84571G Pathological fracture in neoplastic disease, right ankle, subsequent encounter for fracture',
  'with delayed healing',
  'M84571K Pathological fracture in neoplastic disease, right ankle, subsequent encounter for fracture',
  'with nonunion',
  'M84571P Pathological fracture in neoplastic disease, right ankle, subsequent encounter for fracture',
  'with malunion',
  'M84571S Pathological fracture in neoplastic disease, right ankle, sequela',
  'M84572A Pathological fracture in neoplastic disease, left ankle, initial encounter for fracture',
  'M84572D Pathological fracture in neoplastic disease, left ankle, subsequent encounter for fracture with',
  'routine healing',
  'M84572G Pathological fracture in neoplastic disease, left ankle, subsequent encounter for fracture with',
  'delayed healing',
  'M84572K Pathological fracture in neoplastic disease, left ankle, subsequent encounter for fracture with',
  'nonunion',
  'M84572P Pathological fracture in neoplastic disease, left ankle, subsequent encounter for fracture with',
  'malunion',
  'M84572S Pathological fracture in neoplastic disease, left ankle, sequela',
  'M84573A Pathological fracture in neoplastic disease, unspecified ankle, initial encounter for fracture',
  'M84573D Pathological fracture in neoplastic disease, unspecified ankle, subsequent encounter for',
  'fracture with routine healing',
  'M84573G Pathological fracture in neoplastic disease, unspecified ankle, subsequent encounter for',
  'fracture with delayed healing',
  'M84573K Pathological fracture in neoplastic disease, unspecified ankle, subsequent encounter for',
  'fracture with nonunion',
  'M84573P Pathological fracture in neoplastic disease, unspecified ankle, subsequent encounter for',
  'fracture with malunion',
  'M84573S Pathological fracture in neoplastic disease, unspecified ankle, sequela',
  'M84574A Pathological fracture in neoplastic disease, right foot, initial encounter for fracture',
  'M84574D Pathological fracture in neoplastic disease, right foot, subsequent encounter for fracture with',
  'routine healing',
  'M84574G Pathological fracture in neoplastic disease, right foot, subsequent encounter for fracture with',
  'delayed healing',
  'M84574K Pathological fracture in neoplastic disease, right foot, subsequent encounter for fracture with',
  'nonunion',
  'M84574P Pathological fracture in neoplastic disease, right foot, subsequent encounter for fracture with',
  'malunion',
  'M84574S Pathological fracture in neoplastic disease, right foot, sequela',
  'M84575A Pathological fracture in neoplastic disease, left foot, initial encounter for fracture',
  'M84575D Pathological fracture in neoplastic disease, left foot, subsequent encounter for fracture with',
  'routine healing',
  'M84575G Pathological fracture in neoplastic disease, left foot, subsequent encounter for fracture with',
  'delayed healing',
  'M84575K Pathological fracture in neoplastic disease, left foot, subsequent encounter for fracture with',
  'nonunion',
  'M84575P Pathological fracture in neoplastic disease, left foot, subsequent encounter for fracture with',
  'malunion',
  'M84575S Pathological fracture in neoplastic disease, left foot, sequela',
  'M84576A Pathological fracture in neoplastic disease, unspecified foot, initial encounter for fracture',
  'M84576D Pathological fracture in neoplastic disease, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'M84576G Pathological fracture in neoplastic disease, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'M84576K Pathological fracture in neoplastic disease, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'M84576P Pathological fracture in neoplastic disease, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'M84576S Pathological fracture in neoplastic disease, unspecified foot, sequela',
  'M8458XA Pathological fracture in neoplastic disease, other specified site, initial encounter for fracture',
  'M8458XD Pathological fracture in neoplastic disease, other specified site, subsequent encounter for',
  'fracture with routine healing',
  'M8458XG Pathological fracture in neoplastic disease, other specified site, subsequent encounter for',
  'fracture with delayed healing',
  'M8458XK Pathological fracture in neoplastic disease, other specified site, subsequent encounter for',
  'fracture with nonunion',
  'M8458XP Pathological fracture in neoplastic disease, other specified site, subsequent encounter for',
  'fracture with malunion',
  'M8458XS Pathological fracture in neoplastic disease, other specified site, sequela',
  'M8460XA Pathological fracture in other disease, unspecified site, initial encounter for fracture',
  'M8460XD Pathological fracture in other disease, unspecified site, subsequent encounter for fracture',
  'with routine healing',
  'M8460XG Pathological fracture in other disease, unspecified site, subsequent encounter for fracture',
  'with delayed healing',
  'M8460XK Pathological fracture in other disease, unspecified site, subsequent encounter for fracture',
  'with nonunion',
  'M8460XP Pathological fracture in other disease, unspecified site, subsequent encounter for fracture',
  'with malunion',
  'M8460XS Pathological fracture in other disease, unspecified site, sequela',
  'M84611A Pathological fracture in other disease, right shoulder, initial encounter for fracture',
  'M84611D Pathological fracture in other disease, right shoulder, subsequent encounter for fracture with',
  'routine healing',
  'M84611G Pathological fracture in other disease, right shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'M84611K Pathological fracture in other disease, right shoulder, subsequent encounter for fracture with',
  'nonunion',
  'M84611P Pathological fracture in other disease, right shoulder, subsequent encounter for fracture with',
  'malunion',
  'M84611S Pathological fracture in other disease, right shoulder, sequela',
  'M84612A Pathological fracture in other disease, left shoulder, initial encounter for fracture',
  'M84612D Pathological fracture in other disease, left shoulder, subsequent encounter for fracture with',
  'routine healing',
  'M84612G Pathological fracture in other disease, left shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'M84612K Pathological fracture in other disease, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'M84612P Pathological fracture in other disease, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'M84612S Pathological fracture in other disease, left shoulder, sequela',
  'M84619A Pathological fracture in other disease, unspecified shoulder, initial encounter for fracture',
  'M84619D Pathological fracture in other disease, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'M84619G Pathological fracture in other disease, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'M84619K Pathological fracture in other disease, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'M84619P Pathological fracture in other disease, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'M84619S Pathological fracture in other disease, unspecified shoulder, sequela',
  'M84621A Pathological fracture in other disease, right humerus, initial encounter for fracture',
  'M84621D Pathological fracture in other disease, right humerus, subsequent encounter for fracture with',
  'routine healing',
  'M84621G Pathological fracture in other disease, right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'M84621K Pathological fracture in other disease, right humerus, subsequent encounter for fracture with',
  'nonunion',
  'M84621P Pathological fracture in other disease, right humerus, subsequent encounter for fracture with',
  'malunion',
  'M84621S Pathological fracture in other disease, right humerus, sequela',
  'M84622A Pathological fracture in other disease, left humerus, initial encounter for fracture',
  'M84622D Pathological fracture in other disease, left humerus, subsequent encounter for fracture with',
  'routine healing',
  'M84622G Pathological fracture in other disease, left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'M84622K Pathological fracture in other disease, left humerus, subsequent encounter for fracture with',
  'nonunion',
  'M84622P Pathological fracture in other disease, left humerus, subsequent encounter for fracture with',
  'malunion',
  'M84622S Pathological fracture in other disease, left humerus, sequela',
  'M84629A Pathological fracture in other disease, unspecified humerus, initial encounter for fracture',
  'M84629D Pathological fracture in other disease, unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'M84629G Pathological fracture in other disease, unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'M84629K Pathological fracture in other disease, unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'M84629P Pathological fracture in other disease, unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'M84629S Pathological fracture in other disease, unspecified humerus, sequela',
  'M84631A Pathological fracture in other disease, right ulna, initial encounter for fracture',
  'M84631D Pathological fracture in other disease, right ulna, subsequent encounter for fracture with',
  'routine healing',
  'M84631G Pathological fracture in other disease, right ulna, subsequent encounter for fracture with',
  'delayed healing',
  'M84631K Pathological fracture in other disease, right ulna, subsequent encounter for fracture with',
  'nonunion',
  'M84631P Pathological fracture in other disease, right ulna, subsequent encounter for fracture with',
  'malunion',
  'M84631S Pathological fracture in other disease, right ulna, sequela',
  'M84632A Pathological fracture in other disease, left ulna, initial encounter for fracture',
  'M84632D Pathological fracture in other disease, left ulna, subsequent encounter for fracture with',
  'routine healing',
  'M84632G Pathological fracture in other disease, left ulna, subsequent encounter for fracture with',
  'delayed healing',
  'M84632K Pathological fracture in other disease, left ulna, subsequent encounter for fracture with',
  'nonunion',
  'M84632P Pathological fracture in other disease, left ulna, subsequent encounter for fracture with',
  'malunion',
  'M84632S Pathological fracture in other disease, left ulna, sequela',
  'M84633A Pathological fracture in other disease, right radius, initial encounter for fracture',
  'M84633D Pathological fracture in other disease, right radius, subsequent encounter for fracture with',
  'routine healing',
  'M84633G Pathological fracture in other disease, right radius, subsequent encounter for fracture with',
  'delayed healing',
  'M84633K Pathological fracture in other disease, right radius, subsequent encounter for fracture with',
  'nonunion',
  'M84633P Pathological fracture in other disease, right radius, subsequent encounter for fracture with',
  'malunion',
  'M84633S Pathological fracture in other disease, right radius, sequela',
  'M84634A Pathological fracture in other disease, left radius, initial encounter for fracture',
  'M84634D Pathological fracture in other disease, left radius, subsequent encounter for fracture with',
  'routine healing',
  'M84634G Pathological fracture in other disease, left radius, subsequent encounter for fracture with',
  'delayed healing',
  'M84634K Pathological fracture in other disease, left radius, subsequent encounter for fracture with',
  'nonunion',
  'M84634P Pathological fracture in other disease, left radius, subsequent encounter for fracture with',
  'malunion',
  'M84634S Pathological fracture in other disease, left radius, sequela',
  'M84639A Pathological fracture in other disease, unspecified ulna and radius, initial encounter for',
  'fracture',
  'M84639D Pathological fracture in other disease, unspecified ulna and radius, subsequent encounter for',
  'fracture with routine healing',
  'M84639G Pathological fracture in other disease, unspecified ulna and radius, subsequent encounter for',
  'fracture with delayed healing',
  'M84639K Pathological fracture in other disease, unspecified ulna and radius, subsequent encounter for',
  'fracture with nonunion',
  'M84639P Pathological fracture in other disease, unspecified ulna and radius, subsequent encounter for',
  'fracture with malunion',
  'M84639S Pathological fracture in other disease, unspecified ulna and radius, sequela',
  'M84641A Pathological fracture in other disease, right hand, initial encounter for fracture',
  'M84641D Pathological fracture in other disease, right hand, subsequent encounter for fracture with',
  'routine healing',
  'M84641G Pathological fracture in other disease, right hand, subsequent encounter for fracture with',
  'delayed healing',
  'M84641K Pathological fracture in other disease, right hand, subsequent encounter for fracture with',
  'nonunion',
  'M84641P Pathological fracture in other disease, right hand, subsequent encounter for fracture with',
  'malunion',
  'M84641S Pathological fracture in other disease, right hand, sequela',
  'M84642A Pathological fracture in other disease, left hand, initial encounter for fracture',
  'M84642D Pathological fracture in other disease, left hand, subsequent encounter for fracture with',
  'routine healing',
  'M84642G Pathological fracture in other disease, left hand, subsequent encounter for fracture with',
  'delayed healing',
  'M84642K Pathological fracture in other disease, left hand, subsequent encounter for fracture with',
  'nonunion',
  'M84642P Pathological fracture in other disease, left hand, subsequent encounter for fracture with',
  'malunion',
  'M84642S Pathological fracture in other disease, left hand, sequela',
  'M84649A Pathological fracture in other disease, unspecified hand, initial encounter for fracture',
  'M84649D Pathological fracture in other disease, unspecified hand, subsequent encounter for fracture',
  'with routine healing',
  'M84649G Pathological fracture in other disease, unspecified hand, subsequent encounter for fracture',
  'with delayed healing',
  'M84649K Pathological fracture in other disease, unspecified hand, subsequent encounter for fracture',
  'with nonunion',
  'M84649P Pathological fracture in other disease, unspecified hand, subsequent encounter for fracture',
  'with malunion',
  'M84649S Pathological fracture in other disease, unspecified hand, sequela',
  'M84650A Pathological fracture in other disease, pelvis, initial encounter for fracture',
  'M84650D Pathological fracture in other disease, pelvis, subsequent encounter for fracture with routine',
  'healing',
  'M84650G Pathological fracture in other disease, pelvis, subsequent encounter for fracture with delayed',
  'healing',
  'M84650K Pathological fracture in other disease, pelvis, subsequent encounter for fracture with',
  'nonunion',
  'M84650P Pathological fracture in other disease, pelvis, subsequent encounter for fracture with',
  'malunion',
  'M84650S Pathological fracture in other disease, pelvis, sequela',
  'M84651A Pathological fracture in other disease, right femur, initial encounter for fracture',
  'M84651D Pathological fracture in other disease, right femur, subsequent encounter for fracture with',
  'routine healing',
  'M84651G Pathological fracture in other disease, right femur, subsequent encounter for fracture with',
  'delayed healing',
  'M84651K Pathological fracture in other disease, right femur, subsequent encounter for fracture with',
  'nonunion',
  'M84651P Pathological fracture in other disease, right femur, subsequent encounter for fracture with',
  'malunion',
  'M84651S Pathological fracture in other disease, right femur, sequela',
  'M84652A Pathological fracture in other disease, left femur, initial encounter for fracture',
  'M84652D Pathological fracture in other disease, left femur, subsequent encounter for fracture with',
  'routine healing',
  'M84652G Pathological fracture in other disease, left femur, subsequent encounter for fracture with',
  'delayed healing',
  'M84652K Pathological fracture in other disease, left femur, subsequent encounter for fracture with',
  'nonunion',
  'M84652P Pathological fracture in other disease, left femur, subsequent encounter for fracture with',
  'malunion',
  'M84652S Pathological fracture in other disease, left femur, sequela',
  'M84653A Pathological fracture in other disease, unspecified femur, initial encounter for fracture',
  'M84653D Pathological fracture in other disease, unspecified femur, subsequent encounter for fracture',
  'with routine healing',
  'M84653G Pathological fracture in other disease, unspecified femur, subsequent encounter for fracture',
  'with delayed healing',
  'M84653K Pathological fracture in other disease, unspecified femur, subsequent encounter for fracture',
  'with nonunion',
  'M84653P Pathological fracture in other disease, unspecified femur, subsequent encounter for fracture',
  'with malunion',
  'M84653S Pathological fracture in other disease, unspecified femur, sequela',
  'M84659A Pathological fracture in other disease, hip, unspecified, initial encounter for fracture',
  'M84659D Pathological fracture in other disease, hip, unspecified, subsequent encounter for fracture',
  'with routine healing',
  'M84659G Pathological fracture in other disease, hip, unspecified, subsequent encounter for fracture',
  'with delayed healing',
  'M84659K Pathological fracture in other disease, hip, unspecified, subsequent encounter for fracture',
  'with nonunion',
  'M84659P Pathological fracture in other disease, hip, unspecified, subsequent encounter for fracture',
  'with malunion',
  'M84659S Pathological fracture in other disease, hip, unspecified, sequela',
  'M84661A Pathological fracture in other disease, right tibia, initial encounter for fracture',
  'M84661D Pathological fracture in other disease, right tibia, subsequent encounter for fracture with',
  'routine healing',
  'M84661G Pathological fracture in other disease, right tibia, subsequent encounter for fracture with',
  'delayed healing',
  'M84661K Pathological fracture in other disease, right tibia, subsequent encounter for fracture with',
  'nonunion',
  'M84661P Pathological fracture in other disease, right tibia, subsequent encounter for fracture with',
  'malunion',
  'M84661S Pathological fracture in other disease, right tibia, sequela',
  'M84662A Pathological fracture in other disease, left tibia, initial encounter for fracture',
  'M84662D Pathological fracture in other disease, left tibia, subsequent encounter for fracture with',
  'routine healing',
  'M84662G Pathological fracture in other disease, left tibia, subsequent encounter for fracture with',
  'delayed healing',
  'M84662K Pathological fracture in other disease, left tibia, subsequent encounter for fracture with',
  'nonunion',
  'M84662P Pathological fracture in other disease, left tibia, subsequent encounter for fracture with',
  'malunion',
  'M84662S Pathological fracture in other disease, left tibia, sequela',
  'M84663A Pathological fracture in other disease, right fibula, initial encounter for fracture',
  'M84663D Pathological fracture in other disease, right fibula, subsequent encounter for fracture with',
  'routine healing',
  'M84663G Pathological fracture in other disease, right fibula, subsequent encounter for fracture with',
  'delayed healing',
  'M84663K Pathological fracture in other disease, right fibula, subsequent encounter for fracture with',
  'nonunion',
  'M84663P Pathological fracture in other disease, right fibula, subsequent encounter for fracture with',
  'malunion',
  'M84663S Pathological fracture in other disease, right fibula, sequela',
  'M84664A Pathological fracture in other disease, left fibula, initial encounter for fracture',
  'M84664D Pathological fracture in other disease, left fibula, subsequent encounter for fracture with',
  'routine healing',
  'M84664G Pathological fracture in other disease, left fibula, subsequent encounter for fracture with',
  'delayed healing',
  'M84664K Pathological fracture in other disease, left fibula, subsequent encounter for fracture with',
  'nonunion',
  'M84664P Pathological fracture in other disease, left fibula, subsequent encounter for fracture with',
  'malunion',
  'M84664S Pathological fracture in other disease, left fibula, sequela',
  'M84669A Pathological fracture in other disease, unspecified tibia and fibula, initial encounter for',
  'fracture',
  'M84669D Pathological fracture in other disease, unspecified tibia and fibula, subsequent encounter for',
  'fracture with routine healing',
  'M84669G Pathological fracture in other disease, unspecified tibia and fibula, subsequent encounter for',
  'fracture with delayed healing',
  'M84669K Pathological fracture in other disease, unspecified tibia and fibula, subsequent encounter for',
  'fracture with nonunion',
  'M84669P Pathological fracture in other disease, unspecified tibia and fibula, subsequent encounter for',
  'fracture with malunion',
  'M84669S Pathological fracture in other disease, unspecified tibia and fibula, sequela',
  'M84671A Pathological fracture in other disease, right ankle, initial encounter for fracture',
  'M84671D Pathological fracture in other disease, right ankle, subsequent encounter for fracture with',
  'routine healing',
  'M84671G Pathological fracture in other disease, right ankle, subsequent encounter for fracture with',
  'delayed healing',
  'M84671K Pathological fracture in other disease, right ankle, subsequent encounter for fracture with',
  'nonunion',
  'M84671P Pathological fracture in other disease, right ankle, subsequent encounter for fracture with',
  'malunion',
  'M84671S Pathological fracture in other disease, right ankle, sequela',
  'M84672A Pathological fracture in other disease, left ankle, initial encounter for fracture',
  'M84672D Pathological fracture in other disease, left ankle, subsequent encounter for fracture with',
  'routine healing',
  'M84672G Pathological fracture in other disease, left ankle, subsequent encounter for fracture with',
  'delayed healing',
  'M84672K Pathological fracture in other disease, left ankle, subsequent encounter for fracture with',
  'nonunion',
  'M84672P Pathological fracture in other disease, left ankle, subsequent encounter for fracture with',
  'malunion',
  'M84672S Pathological fracture in other disease, left ankle, sequela',
  'M84673A Pathological fracture in other disease, unspecified ankle, initial encounter for fracture',
  'M84673D Pathological fracture in other disease, unspecified ankle, subsequent encounter for fracture',
  'with routine healing',
  'M84673G Pathological fracture in other disease, unspecified ankle, subsequent encounter for fracture',
  'with delayed healing',
  'M84673K Pathological fracture in other disease, unspecified ankle, subsequent encounter for fracture',
  'with nonunion',
  'M84673P Pathological fracture in other disease, unspecified ankle, subsequent encounter for fracture',
  'with malunion',
  'M84673S Pathological fracture in other disease, unspecified ankle, sequela',
  'M84674A Pathological fracture in other disease, right foot, initial encounter for fracture',
  'M84674D Pathological fracture in other disease, right foot, subsequent encounter for fracture with',
  'routine healing',
  'M84674G Pathological fracture in other disease, right foot, subsequent encounter for fracture with',
  'delayed healing',
  'M84674K Pathological fracture in other disease, right foot, subsequent encounter for fracture with',
  'nonunion',
  'M84674P Pathological fracture in other disease, right foot, subsequent encounter for fracture with',
  'malunion',
  'M84674S Pathological fracture in other disease, right foot, sequela',
  'M84675A Pathological fracture in other disease, left foot, initial encounter for fracture',
  'M84675D Pathological fracture in other disease, left foot, subsequent encounter for fracture with',
  'routine healing',
  'M84675G Pathological fracture in other disease, left foot, subsequent encounter for fracture with',
  'delayed healing',
  'M84675K Pathological fracture in other disease, left foot, subsequent encounter for fracture with',
  'nonunion',
  'M84675P Pathological fracture in other disease, left foot, subsequent encounter for fracture with',
  'malunion',
  'M84675S Pathological fracture in other disease, left foot, sequela',
  'M84676A Pathological fracture in other disease, unspecified foot, initial encounter for fracture',
  'M84676D Pathological fracture in other disease, unspecified foot, subsequent encounter for fracture',
  'with routine healing',
  'M84676G Pathological fracture in other disease, unspecified foot, subsequent encounter for fracture',
  'with delayed healing',
  'M84676K Pathological fracture in other disease, unspecified foot, subsequent encounter for fracture',
  'with nonunion',
  'M84676P Pathological fracture in other disease, unspecified foot, subsequent encounter for fracture',
  'with malunion',
  'M84676S Pathological fracture in other disease, unspecified foot, sequela',
  'M8468XA Pathological fracture in other disease, other site, initial encounter for fracture',
  'M8468XD Pathological fracture in other disease, other site, subsequent encounter for fracture with',
  'routine healing',
  'M8468XG Pathological fracture in other disease, other site, subsequent encounter for fracture with',
  'delayed healing',
  'M8468XK Pathological fracture in other disease, other site, subsequent encounter for fracture with',
  'nonunion',
  'M8468XP Pathological fracture in other disease, other site, subsequent encounter for fracture with',
  'malunion',
  'M8468XS Pathological fracture in other disease, other site, sequela',
  'M84750A Atypical femoral fracture, unspecified, initial encounter for fracture',
  'M84750D Atypical femoral fracture, unspecified, subsequent encounter for fracture with routine healing',
  'M84750G Atypical femoral fracture, unspecified, subsequent encounter for fracture with delayed',
  'healing',
  'M84750K Atypical femoral fracture, unspecified, subsequent encounter for fracture with nonunion',
  'M84750P Atypical femoral fracture, unspecified, subsequent encounter for fracture with malunion',
  'M84750S Atypical femoral fracture, unspecified, sequela',
  'M84751A Incomplete atypical femoral fracture, right leg, initial encounter for fracture',
  'M84751D Incomplete atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'routine healing',
  'M84751G Incomplete atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'delayed healing',
  'M84751K Incomplete atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'nonunion',
  'M84751P Incomplete atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'malunion',
  'M84751S Incomplete atypical femoral fracture, right leg, sequela',
  'M84752A Incomplete atypical femoral fracture, left leg, initial encounter for fracture',
  'M84752D Incomplete atypical femoral fracture, left leg, subsequent encounter for fracture with routine',
  'healing',
  'M84752G Incomplete atypical femoral fracture, left leg, subsequent encounter for fracture with delayed',
  'healing',
  'M84752K Incomplete atypical femoral fracture, left leg, subsequent encounter for fracture with',
  'nonunion',
  'M84752P Incomplete atypical femoral fracture, left leg, subsequent encounter for fracture with',
  'malunion',
  'M84752S Incomplete atypical femoral fracture, left leg, sequela',
  'M84753A Incomplete atypical femoral fracture, unspecified leg, initial encounter for fracture',
  'M84753D Incomplete atypical femoral fracture, unspecified leg, subsequent encounter for fracture with',
  'routine healing',
  'M84753G Incomplete atypical femoral fracture, unspecified leg, subsequent encounter for fracture with',
  'delayed healing',
  'M84753K Incomplete atypical femoral fracture, unspecified leg, subsequent encounter for fracture with',
  'nonunion',
  'M84753P Incomplete atypical femoral fracture, unspecified leg, subsequent encounter for fracture with',
  'malunion',
  'M84753S Incomplete atypical femoral fracture, unspecified leg, sequela',
  'M84754A Complete transverse atypical femoral fracture, right leg, initial encounter for fracture',
  'M84754D Complete transverse atypical femoral fracture, right leg, subsequent encounter for fracture',
  'with routine healing',
  'M84754G Complete transverse atypical femoral fracture, right leg, subsequent encounter for fracture',
  'with delayed healing',
  'M84754K Complete transverse atypical femoral fracture, right leg, subsequent encounter for fracture',
  'with nonunion',
  'M84754P Complete transverse atypical femoral fracture, right leg, subsequent encounter for fracture',
  'with malunion',
  'M84754S Complete transverse atypical femoral fracture, right leg, sequela',
  'M84755A Complete transverse atypical femoral fracture, left leg, initial encounter for fracture',
  'M84755D Complete transverse atypical femoral fracture, left leg, subsequent encounter for fracture',
  'with routine healing',
  'M84755G Complete transverse atypical femoral fracture, left leg, subsequent encounter for fracture',
  'with delayed healing',
  'M84755K Complete transverse atypical femoral fracture, left leg, subsequent encounter for fracture',
  'with nonunion',
  'M84755P Complete transverse atypical femoral fracture, left leg, subsequent encounter for fracture',
  'with malunion',
  'M84755S Complete transverse atypical femoral fracture, left leg, sequela',
  'M84756A Complete transverse atypical femoral fracture, unspecified leg, initial encounter for fracture',
  'M84756D Complete transverse atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with routine healing',
  'M84756G Complete transverse atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with delayed healing',
  'M84756K Complete transverse atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with nonunion',
  'M84756P Complete transverse atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with malunion',
  'M84756S Complete transverse atypical femoral fracture, unspecified leg, sequela',
  'M84757A Complete oblique atypical femoral fracture, right leg, initial encounter for fracture',
  'M84757D Complete oblique atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'routine healing',
  'M84757G Complete oblique atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'delayed healing',
  'M84757K Complete oblique atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'nonunion',
  'M84757P Complete oblique atypical femoral fracture, right leg, subsequent encounter for fracture with',
  'malunion',
  'M84757S Complete oblique atypical femoral fracture, right leg, sequela',
  'M84758A Complete oblique atypical femoral fracture, left leg, initial encounter for fracture',
  'M84758D Complete oblique atypical femoral fracture, left leg, subsequent encounter for fracture with',
  'routine healing',
  'M84758G Complete oblique atypical femoral fracture, left leg, subsequent encounter for fracture with',
  'delayed healing',
  'M84758K Complete oblique atypical femoral fracture, left leg, subsequent encounter for fracture with',
  'nonunion',
  'M84758P Complete oblique atypical femoral fracture, left leg, subsequent encounter for fracture with',
  'malunion',
  'M84758S Complete oblique atypical femoral fracture, left leg, sequela',
  'M84759A Complete oblique atypical femoral fracture, unspecified leg, initial encounter for fracture',
  'M84759D Complete oblique atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with routine healing',
  'M84759G Complete oblique atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with delayed healing',
  'M84759K Complete oblique atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with nonunion',
  'M84759P Complete oblique atypical femoral fracture, unspecified leg, subsequent encounter for',
  'fracture with malunion',
  'M84759S Complete oblique atypical femoral fracture, unspecified leg, sequela',
  'M8480 Other disorders of continuity of bone, unspecified site',
  'M84811 Other disorders of continuity of bone, right shoulder',
  'M84812 Other disorders of continuity of bone, left shoulder',
  'M84819 Other disorders of continuity of bone, unspecified shoulder',
  'M84821 Other disorders of continuity of bone, right humerus',
  'M84822 Other disorders of continuity of bone, left humerus',
  'M84829 Other disorders of continuity of bone, unspecified humerus',
  'M84831 Other disorders of continuity of bone, right ulna',
  'M84832 Other disorders of continuity of bone, left ulna',
  'M84833 Other disorders of continuity of bone, right radius',
  'M84834 Other disorders of continuity of bone, left radius',
  'M84839 Other disorders of continuity of bone, unspecified ulna and radius',
  'M84841 Other disorders of continuity of bone, right hand',
  'M84842 Other disorders of continuity of bone, left hand',
  'M84849 Other disorders of continuity of bone, unspecified hand',
  'M84851 Other disorders of continuity of bone, right pelvic region and thigh',
  'M84852 Other disorders of continuity of bone, left pelvic region and thigh',
  'M84859 Other disorders of continuity of bone, unspecified pelvic region and thigh',
  'M84861 Other disorders of continuity of bone, right tibia',
  'M84862 Other disorders of continuity of bone, left tibia',
  'M84863 Other disorders of continuity of bone, right fibula',
  'M84864 Other disorders of continuity of bone, left fibula',
  'M84869 Other disorders of continuity of bone, unspecified tibia and fibula',
  'M84871 Other disorders of continuity of bone, right ankle and foot',
  'M84872 Other disorders of continuity of bone, left ankle and foot',
  'M84879 Other disorders of continuity of bone, unspecified ankle and foot',
  'M8488 Other disorders of continuity of bone, other site',
  'M849 Disorder of continuity of bone, unspecified',
  'M8500 Fibrous dysplasia (monostotic), unspecified site',
  'M85011 Fibrous dysplasia (monostotic), right shoulder',
  'M85012 Fibrous dysplasia (monostotic), left shoulder',
  'M85019 Fibrous dysplasia (monostotic), unspecified shoulder',
  'M85021 Fibrous dysplasia (monostotic), right upper arm',
  'M85022 Fibrous dysplasia (monostotic), left upper arm',
  'M85029 Fibrous dysplasia (monostotic), unspecified upper arm',
  'M85031 Fibrous dysplasia (monostotic), right forearm',
  'M85032 Fibrous dysplasia (monostotic), left forearm',
  'M85039 Fibrous dysplasia (monostotic), unspecified forearm',
  'M85041 Fibrous dysplasia (monostotic), right hand',
  'M85042 Fibrous dysplasia (monostotic), left hand',
  'M85049 Fibrous dysplasia (monostotic), unspecified hand',
  'M85051 Fibrous dysplasia (monostotic), right thigh',
  'M85052 Fibrous dysplasia (monostotic), left thigh',
  'M85059 Fibrous dysplasia (monostotic), unspecified thigh',
  'M85061 Fibrous dysplasia (monostotic), right lower leg',
  'M85062 Fibrous dysplasia (monostotic), left lower leg',
  'M85069 Fibrous dysplasia (monostotic), unspecified lower leg',
  'M85071 Fibrous dysplasia (monostotic), right ankle and foot',
  'M85072 Fibrous dysplasia (monostotic), left ankle and foot',
  'M85079 Fibrous dysplasia (monostotic), unspecified ankle and foot',
  'M8508 Fibrous dysplasia (monostotic), other site',
  'M8509 Fibrous dysplasia (monostotic), multiple sites',
  'M8510 Skeletal fluorosis, unspecified site',
  'M85111 Skeletal fluorosis, right shoulder',
  'M85112 Skeletal fluorosis, left shoulder',
  'M85119 Skeletal fluorosis, unspecified shoulder',
  'M85121 Skeletal fluorosis, right upper arm',
  'M85122 Skeletal fluorosis, left upper arm',
  'M85129 Skeletal fluorosis, unspecified upper arm',
  'M85131 Skeletal fluorosis, right forearm',
  'M85132 Skeletal fluorosis, left forearm',
  'M85139 Skeletal fluorosis, unspecified forearm',
  'M85141 Skeletal fluorosis, right hand',
  'M85142 Skeletal fluorosis, left hand',
  'M85149 Skeletal fluorosis, unspecified hand',
  'M85151 Skeletal fluorosis, right thigh',
  'M85152 Skeletal fluorosis, left thigh',
  'M85159 Skeletal fluorosis, unspecified thigh',
  'M85161 Skeletal fluorosis, right lower leg',
  'M85162 Skeletal fluorosis, left lower leg',
  'M85169 Skeletal fluorosis, unspecified lower leg',
  'M85171 Skeletal fluorosis, right ankle and foot',
  'M85172 Skeletal fluorosis, left ankle and foot',
  'M85179 Skeletal fluorosis, unspecified ankle and foot',
  'M8518 Skeletal fluorosis, other site',
  'M8519 Skeletal fluorosis, multiple sites',
  'M852 Hyperostosis of skull',
  'M8530 Osteitis condensans, unspecified site',
  'M85311 Osteitis condensans, right shoulder',
  'M85312 Osteitis condensans, left shoulder',
  'M85319 Osteitis condensans, unspecified shoulder',
  'M85321 Osteitis condensans, right upper arm',
  'M85322 Osteitis condensans, left upper arm',
  'M85329 Osteitis condensans, unspecified upper arm',
  'M85331 Osteitis condensans, right forearm',
  'M85332 Osteitis condensans, left forearm',
  'M85339 Osteitis condensans, unspecified forearm',
  'M85341 Osteitis condensans, right hand',
  'M85342 Osteitis condensans, left hand',
  'M85349 Osteitis condensans, unspecified hand',
  'M85351 Osteitis condensans, right thigh',
  'M85352 Osteitis condensans, left thigh',
  'M85359 Osteitis condensans, unspecified thigh',
  'M85361 Osteitis condensans, right lower leg',
  'M85362 Osteitis condensans, left lower leg',
  'M85369 Osteitis condensans, unspecified lower leg',
  'M85371 Osteitis condensans, right ankle and foot',
  'M85372 Osteitis condensans, left ankle and foot',
  'M85379 Osteitis condensans, unspecified ankle and foot',
  'M8538 Osteitis condensans, other site',
  'M8539 Osteitis condensans, multiple sites',
  'M8540 Solitary bone cyst, unspecified site',
  'M85411 Solitary bone cyst, right shoulder',
  'M85412 Solitary bone cyst, left shoulder',
  'M85419 Solitary bone cyst, unspecified shoulder',
  'M85421 Solitary bone cyst, right humerus',
  'M85422 Solitary bone cyst, left humerus',
  'M85429 Solitary bone cyst, unspecified humerus',
  'M85431 Solitary bone cyst, right ulna and radius',
  'M85432 Solitary bone cyst, left ulna and radius',
  'M85439 Solitary bone cyst, unspecified ulna and radius',
  'M85441 Solitary bone cyst, right hand',
  'M85442 Solitary bone cyst, left hand',
  'M85449 Solitary bone cyst, unspecified hand',
  'M85451 Solitary bone cyst, right pelvis',
  'M85452 Solitary bone cyst, left pelvis',
  'M85459 Solitary bone cyst, unspecified pelvis',
  'M85461 Solitary bone cyst, right tibia and fibula',
  'M85462 Solitary bone cyst, left tibia and fibula',
  'M85469 Solitary bone cyst, unspecified tibia and fibula',
  'M85471 Solitary bone cyst, right ankle and foot',
  'M85472 Solitary bone cyst, left ankle and foot',
  'M85479 Solitary bone cyst, unspecified ankle and foot',
  'M8548 Solitary bone cyst, other site',
  'M8550 Aneurysmal bone cyst, unspecified site',
  'M85511 Aneurysmal bone cyst, right shoulder',
  'M85512 Aneurysmal bone cyst, left shoulder',
  'M85519 Aneurysmal bone cyst, unspecified shoulder',
  'M85521 Aneurysmal bone cyst, right upper arm',
  'M85522 Aneurysmal bone cyst, left upper arm',
  'M85529 Aneurysmal bone cyst, unspecified upper arm',
  'M85531 Aneurysmal bone cyst, right forearm',
  'M85532 Aneurysmal bone cyst, left forearm',
  'M85539 Aneurysmal bone cyst, unspecified forearm',
  'M85541 Aneurysmal bone cyst, right hand',
  'M85542 Aneurysmal bone cyst, left hand',
  'M85549 Aneurysmal bone cyst, unspecified hand',
  'M85551 Aneurysmal bone cyst, right thigh',
  'M85552 Aneurysmal bone cyst, left thigh',
  'M85559 Aneurysmal bone cyst, unspecified thigh',
  'M85561 Aneurysmal bone cyst, right lower leg',
  'M85562 Aneurysmal bone cyst, left lower leg',
  'M85569 Aneurysmal bone cyst, unspecified lower leg',
  'M85571 Aneurysmal bone cyst, right ankle and foot',
  'M85572 Aneurysmal bone cyst, left ankle and foot',
  'M85579 Aneurysmal bone cyst, unspecified ankle and foot',
  'M8558 Aneurysmal bone cyst, other site',
  'M8559 Aneurysmal bone cyst, multiple sites',
  'M8560 Other cyst of bone, unspecified site',
  'M85611 Other cyst of bone, right shoulder',
  'M85612 Other cyst of bone, left shoulder',
  'M85619 Other cyst of bone, unspecified shoulder',
  'M85621 Other cyst of bone, right upper arm',
  'M85622 Other cyst of bone, left upper arm',
  'M85629 Other cyst of bone, unspecified upper arm',
  'M85631 Other cyst of bone, right forearm',
  'M85632 Other cyst of bone, left forearm',
  'M85639 Other cyst of bone, unspecified forearm',
  'M85641 Other cyst of bone, right hand',
  'M85642 Other cyst of bone, left hand',
  'M85649 Other cyst of bone, unspecified hand',
  'M85651 Other cyst of bone, right thigh',
  'M85652 Other cyst of bone, left thigh',
  'M85659 Other cyst of bone, unspecified thigh',
  'M85661 Other cyst of bone, right lower leg',
  'M85662 Other cyst of bone, left lower leg',
  'M85669 Other cyst of bone, unspecified lower leg',
  'M85671 Other cyst of bone, right ankle and foot',
  'M85672 Other cyst of bone, left ankle and foot',
  'M85679 Other cyst of bone, unspecified ankle and foot',
  'M8568 Other cyst of bone, other site',
  'M8569 Other cyst of bone, multiple sites',
  'M8580 Other specified disorders of bone density and structure, unspecified site',
  'M85811 Other specified disorders of bone density and structure, right shoulder',
  'M85812 Other specified disorders of bone density and structure, left shoulder',
  'M85819 Other specified disorders of bone density and structure, unspecified shoulder',
  'M85821 Other specified disorders of bone density and structure, right upper arm',
  'M85822 Other specified disorders of bone density and structure, left upper arm',
  'M85829 Other specified disorders of bone density and structure, unspecified upper arm',
  'M85831 Other specified disorders of bone density and structure, right forearm',
  'M85832 Other specified disorders of bone density and structure, left forearm',
  'M85839 Other specified disorders of bone density and structure, unspecified forearm',
  'M85841 Other specified disorders of bone density and structure, right hand',
  'M85842 Other specified disorders of bone density and structure, left hand',
  'M85849 Other specified disorders of bone density and structure, unspecified hand',
  'M85851 Other specified disorders of bone density and structure, right thigh',
  'M85852 Other specified disorders of bone density and structure, left thigh',
  'M85859 Other specified disorders of bone density and structure, unspecified thigh',
  'M85861 Other specified disorders of bone density and structure, right lower leg',
  'M85862 Other specified disorders of bone density and structure, left lower leg',
  'M85869 Other specified disorders of bone density and structure, unspecified lower leg',
  'M85871 Other specified disorders of bone density and structure, right ankle and foot',
  'M85872 Other specified disorders of bone density and structure, left ankle and foot',
  'M85879 Other specified disorders of bone density and structure, unspecified ankle and foot',
  'M8588 Other specified disorders of bone density and structure, other site',
  'M8589 Other specified disorders of bone density and structure, multiple sites',
  'M859 Disorder of bone density and structure, unspecified',
  'M8600 Acute hematogenous osteomyelitis, unspecified site',
  'M86011 Acute hematogenous osteomyelitis, right shoulder',
  'M86012 Acute hematogenous osteomyelitis, left shoulder',
  'M86019 Acute hematogenous osteomyelitis, unspecified shoulder',
  'M86021 Acute hematogenous osteomyelitis, right humerus',
  'M86022 Acute hematogenous osteomyelitis, left humerus',
  'M86029 Acute hematogenous osteomyelitis, unspecified humerus',
  'M86031 Acute hematogenous osteomyelitis, right radius and ulna',
  'M86032 Acute hematogenous osteomyelitis, left radius and ulna',
  'M86039 Acute hematogenous osteomyelitis, unspecified radius and ulna',
  'M86041 Acute hematogenous osteomyelitis, right hand',
  'M86042 Acute hematogenous osteomyelitis, left hand',
  'M86049 Acute hematogenous osteomyelitis, unspecified hand',
  'M86051 Acute hematogenous osteomyelitis, right femur',
  'M86052 Acute hematogenous osteomyelitis, left femur',
  'M86059 Acute hematogenous osteomyelitis, unspecified femur',
  'M86061 Acute hematogenous osteomyelitis, right tibia and fibula',
  'M86062 Acute hematogenous osteomyelitis, left tibia and fibula',
  'M86069 Acute hematogenous osteomyelitis, unspecified tibia and fibula',
  'M86071 Acute hematogenous osteomyelitis, right ankle and foot',
  'M86072 Acute hematogenous osteomyelitis, left ankle and foot',
  'M86079 Acute hematogenous osteomyelitis, unspecified ankle and foot',
  'M8608 Acute hematogenous osteomyelitis, other sites',
  'M8609 Acute hematogenous osteomyelitis, multiple sites',
  'M8610 Other acute osteomyelitis, unspecified site',
  'M86111 Other acute osteomyelitis, right shoulder',
  'M86112 Other acute osteomyelitis, left shoulder',
  'M86119 Other acute osteomyelitis, unspecified shoulder',
  'M86121 Other acute osteomyelitis, right humerus',
  'M86122 Other acute osteomyelitis, left humerus',
  'M86129 Other acute osteomyelitis, unspecified humerus',
  'M86131 Other acute osteomyelitis, right radius and ulna',
  'M86132 Other acute osteomyelitis, left radius and ulna',
  'M86139 Other acute osteomyelitis, unspecified radius and ulna',
  'M86141 Other acute osteomyelitis, right hand',
  'M86142 Other acute osteomyelitis, left hand',
  'M86149 Other acute osteomyelitis, unspecified hand',
  'M86151 Other acute osteomyelitis, right femur',
  'M86152 Other acute osteomyelitis, left femur',
  'M86159 Other acute osteomyelitis, unspecified femur',
  'M86161 Other acute osteomyelitis, right tibia and fibula',
  'M86162 Other acute osteomyelitis, left tibia and fibula',
  'M86169 Other acute osteomyelitis, unspecified tibia and fibula',
  'M86171 Other acute osteomyelitis, right ankle and foot',
  'M86172 Other acute osteomyelitis, left ankle and foot',
  'M86179 Other acute osteomyelitis, unspecified ankle and foot',
  'M8618 Other acute osteomyelitis, other site',
  'M8619 Other acute osteomyelitis, multiple sites',
  'M8620 Subacute osteomyelitis, unspecified site',
  'M86211 Subacute osteomyelitis, right shoulder',
  'M86212 Subacute osteomyelitis, left shoulder',
  'M86219 Subacute osteomyelitis, unspecified shoulder',
  'M86221 Subacute osteomyelitis, right humerus',
  'M86222 Subacute osteomyelitis, left humerus',
  'M86229 Subacute osteomyelitis, unspecified humerus',
  'M86231 Subacute osteomyelitis, right radius and ulna',
  'M86232 Subacute osteomyelitis, left radius and ulna',
  'M86239 Subacute osteomyelitis, unspecified radius and ulna',
  'M86241 Subacute osteomyelitis, right hand',
  'M86242 Subacute osteomyelitis, left hand',
  'M86249 Subacute osteomyelitis, unspecified hand',
  'M86251 Subacute osteomyelitis, right femur',
  'M86252 Subacute osteomyelitis, left femur',
  'M86259 Subacute osteomyelitis, unspecified femur',
  'M86261 Subacute osteomyelitis, right tibia and fibula',
  'M86262 Subacute osteomyelitis, left tibia and fibula',
  'M86269 Subacute osteomyelitis, unspecified tibia and fibula',
  'M86271 Subacute osteomyelitis, right ankle and foot',
  'M86272 Subacute osteomyelitis, left ankle and foot',
  'M86279 Subacute osteomyelitis, unspecified ankle and foot',
  'M8628 Subacute osteomyelitis, other site',
  'M8629 Subacute osteomyelitis, multiple sites',
  'M8630 Chronic multifocal osteomyelitis, unspecified site',
  'M86311 Chronic multifocal osteomyelitis, right shoulder',
  'M86312 Chronic multifocal osteomyelitis, left shoulder',
  'M86319 Chronic multifocal osteomyelitis, unspecified shoulder',
  'M86321 Chronic multifocal osteomyelitis, right humerus',
  'M86322 Chronic multifocal osteomyelitis, left humerus',
  'M86329 Chronic multifocal osteomyelitis, unspecified humerus',
  'M86331 Chronic multifocal osteomyelitis, right radius and ulna',
  'M86332 Chronic multifocal osteomyelitis, left radius and ulna',
  'M86339 Chronic multifocal osteomyelitis, unspecified radius and ulna',
  'M86341 Chronic multifocal osteomyelitis, right hand',
  'M86342 Chronic multifocal osteomyelitis, left hand',
  'M86349 Chronic multifocal osteomyelitis, unspecified hand',
  'M86351 Chronic multifocal osteomyelitis, right femur',
  'M86352 Chronic multifocal osteomyelitis, left femur',
  'M86359 Chronic multifocal osteomyelitis, unspecified femur',
  'M86361 Chronic multifocal osteomyelitis, right tibia and fibula',
  'M86362 Chronic multifocal osteomyelitis, left tibia and fibula',
  'M86369 Chronic multifocal osteomyelitis, unspecified tibia and fibula',
  'M86371 Chronic multifocal osteomyelitis, right ankle and foot',
  'M86372 Chronic multifocal osteomyelitis, left ankle and foot',
  'M86379 Chronic multifocal osteomyelitis, unspecified ankle and foot',
  'M8638 Chronic multifocal osteomyelitis, other site',
  'M8639 Chronic multifocal osteomyelitis, multiple sites',
  'M8640 Chronic osteomyelitis with draining sinus, unspecified site',
  'M86411 Chronic osteomyelitis with draining sinus, right shoulder',
  'M86412 Chronic osteomyelitis with draining sinus, left shoulder',
  'M86419 Chronic osteomyelitis with draining sinus, unspecified shoulder',
  'M86421 Chronic osteomyelitis with draining sinus, right humerus',
  'M86422 Chronic osteomyelitis with draining sinus, left humerus',
  'M86429 Chronic osteomyelitis with draining sinus, unspecified humerus',
  'M86431 Chronic osteomyelitis with draining sinus, right radius and ulna',
  'M86432 Chronic osteomyelitis with draining sinus, left radius and ulna',
  'M86439 Chronic osteomyelitis with draining sinus, unspecified radius and ulna',
  'M86441 Chronic osteomyelitis with draining sinus, right hand',
  'M86442 Chronic osteomyelitis with draining sinus, left hand',
  'M86449 Chronic osteomyelitis with draining sinus, unspecified hand',
  'M86451 Chronic osteomyelitis with draining sinus, right femur',
  'M86452 Chronic osteomyelitis with draining sinus, left femur',
  'M86459 Chronic osteomyelitis with draining sinus, unspecified femur',
  'M86461 Chronic osteomyelitis with draining sinus, right tibia and fibula',
  'M86462 Chronic osteomyelitis with draining sinus, left tibia and fibula',
  'M86469 Chronic osteomyelitis with draining sinus, unspecified tibia and fibula',
  'M86471 Chronic osteomyelitis with draining sinus, right ankle and foot',
  'M86472 Chronic osteomyelitis with draining sinus, left ankle and foot',
  'M86479 Chronic osteomyelitis with draining sinus, unspecified ankle and foot',
  'M8648 Chronic osteomyelitis with draining sinus, other site',
  'M8649 Chronic osteomyelitis with draining sinus, multiple sites',
  'M8650 Other chronic hematogenous osteomyelitis, unspecified site',
  'M86511 Other chronic hematogenous osteomyelitis, right shoulder',
  'M86512 Other chronic hematogenous osteomyelitis, left shoulder',
  'M86519 Other chronic hematogenous osteomyelitis, unspecified shoulder',
  'M86521 Other chronic hematogenous osteomyelitis, right humerus',
  'M86522 Other chronic hematogenous osteomyelitis, left humerus',
  'M86529 Other chronic hematogenous osteomyelitis, unspecified humerus',
  'M86531 Other chronic hematogenous osteomyelitis, right radius and ulna',
  'M86532 Other chronic hematogenous osteomyelitis, left radius and ulna',
  'M86539 Other chronic hematogenous osteomyelitis, unspecified radius and ulna',
  'M86541 Other chronic hematogenous osteomyelitis, right hand',
  'M86542 Other chronic hematogenous osteomyelitis, left hand',
  'M86549 Other chronic hematogenous osteomyelitis, unspecified hand',
  'M86551 Other chronic hematogenous osteomyelitis, right femur',
  'M86552 Other chronic hematogenous osteomyelitis, left femur',
  'M86559 Other chronic hematogenous osteomyelitis, unspecified femur',
  'M86561 Other chronic hematogenous osteomyelitis, right tibia and fibula',
  'M86562 Other chronic hematogenous osteomyelitis, left tibia and fibula',
  'M86569 Other chronic hematogenous osteomyelitis, unspecified tibia and fibula',
  'M86571 Other chronic hematogenous osteomyelitis, right ankle and foot',
  'M86572 Other chronic hematogenous osteomyelitis, left ankle and foot',
  'M86579 Other chronic hematogenous osteomyelitis, unspecified ankle and foot',
  'M8658 Other chronic hematogenous osteomyelitis, other site',
  'M8659 Other chronic hematogenous osteomyelitis, multiple sites',
  'M8660 Other chronic osteomyelitis, unspecified site',
  'M86611 Other chronic osteomyelitis, right shoulder',
  'M86612 Other chronic osteomyelitis, left shoulder',
  'M86619 Other chronic osteomyelitis, unspecified shoulder',
  'M86621 Other chronic osteomyelitis, right humerus',
  'M86622 Other chronic osteomyelitis, left humerus',
  'M86629 Other chronic osteomyelitis, unspecified humerus',
  'M86631 Other chronic osteomyelitis, right radius and ulna',
  'M86632 Other chronic osteomyelitis, left radius and ulna',
  'M86639 Other chronic osteomyelitis, unspecified radius and ulna',
  'M86641 Other chronic osteomyelitis, right hand',
  'M86642 Other chronic osteomyelitis, left hand',
  'M86649 Other chronic osteomyelitis, unspecified hand',
  'M86651 Other chronic osteomyelitis, right thigh',
  'M86652 Other chronic osteomyelitis, left thigh',
  'M86659 Other chronic osteomyelitis, unspecified thigh',
  'M86661 Other chronic osteomyelitis, right tibia and fibula',
  'M86662 Other chronic osteomyelitis, left tibia and fibula',
  'M86669 Other chronic osteomyelitis, unspecified tibia and fibula',
  'M86671 Other chronic osteomyelitis, right ankle and foot',
  'M86672 Other chronic osteomyelitis, left ankle and foot',
  'M86679 Other chronic osteomyelitis, unspecified ankle and foot',
  'M8668 Other chronic osteomyelitis, other site',
  'M8669 Other chronic osteomyelitis, multiple sites',
  'M868X0 Other osteomyelitis, multiple sites',
  'M868X1 Other osteomyelitis, shoulder',
  'M868X2 Other osteomyelitis, upper arm',
  'M868X3 Other osteomyelitis, forearm',
  'M868X4 Other osteomyelitis, hand',
  'M868X5 Other osteomyelitis, thigh',
  'M868X6 Other osteomyelitis, lower leg',
  'M868X7 Other osteomyelitis, ankle and foot',
  'M868X8 Other osteomyelitis, other site',
  'M868X9 Other osteomyelitis, unspecified sites',
  'M869 Osteomyelitis, unspecified',
  'M8700 Idiopathic aseptic necrosis of unspecified bone',
  'M87011 Idiopathic aseptic necrosis of right shoulder',
  'M87012 Idiopathic aseptic necrosis of left shoulder',
  'M87019 Idiopathic aseptic necrosis of unspecified shoulder',
  'M87021 Idiopathic aseptic necrosis of right humerus',
  'M87022 Idiopathic aseptic necrosis of left humerus',
  'M87029 Idiopathic aseptic necrosis of unspecified humerus',
  'M87031 Idiopathic aseptic necrosis of right radius',
  'M87032 Idiopathic aseptic necrosis of left radius',
  'M87033 Idiopathic aseptic necrosis of unspecified radius',
  'M87034 Idiopathic aseptic necrosis of right ulna',
  'M87035 Idiopathic aseptic necrosis of left ulna',
  'M87036 Idiopathic aseptic necrosis of unspecified ulna',
  'M87037 Idiopathic aseptic necrosis of right carpus',
  'M87038 Idiopathic aseptic necrosis of left carpus',
  'M87039 Idiopathic aseptic necrosis of unspecified carpus',
  'M87041 Idiopathic aseptic necrosis of right hand',
  'M87042 Idiopathic aseptic necrosis of left hand',
  'M87043 Idiopathic aseptic necrosis of unspecified hand',
  'M87044 Idiopathic aseptic necrosis of right finger(s)',
  'M87045 Idiopathic aseptic necrosis of left finger(s)',
  'M87046 Idiopathic aseptic necrosis of unspecified finger(s)',
  'M87050 Idiopathic aseptic necrosis of pelvis',
  'M87051 Idiopathic aseptic necrosis of right femur',
  'M87052 Idiopathic aseptic necrosis of left femur',
  'M87059 Idiopathic aseptic necrosis of unspecified femur',
  'M87061 Idiopathic aseptic necrosis of right tibia',
  'M87062 Idiopathic aseptic necrosis of left tibia',
  'M87063 Idiopathic aseptic necrosis of unspecified tibia',
  'M87064 Idiopathic aseptic necrosis of right fibula',
  'M87065 Idiopathic aseptic necrosis of left fibula',
  'M87066 Idiopathic aseptic necrosis of unspecified fibula',
  'M87071 Idiopathic aseptic necrosis of right ankle',
  'M87072 Idiopathic aseptic necrosis of left ankle',
  'M87073 Idiopathic aseptic necrosis of unspecified ankle',
  'M87074 Idiopathic aseptic necrosis of right foot',
  'M87075 Idiopathic aseptic necrosis of left foot',
  'M87076 Idiopathic aseptic necrosis of unspecified foot',
  'M87077 Idiopathic aseptic necrosis of right toe(s)',
  'M87078 Idiopathic aseptic necrosis of left toe(s)',
  'M87079 Idiopathic aseptic necrosis of unspecified toe(s)',
  'M8708 Idiopathic aseptic necrosis of bone, other site',
  'M8709 Idiopathic aseptic necrosis of bone, multiple sites',
  'M8710 Osteonecrosis due to drugs, unspecified bone',
  'M87111 Osteonecrosis due to drugs, right shoulder',
  'M87112 Osteonecrosis due to drugs, left shoulder',
  'M87119 Osteonecrosis due to drugs, unspecified shoulder',
  'M87121 Osteonecrosis due to drugs, right humerus',
  'M87122 Osteonecrosis due to drugs, left humerus',
  'M87129 Osteonecrosis due to drugs, unspecified humerus',
  'M87131 Osteonecrosis due to drugs of right radius',
  'M87132 Osteonecrosis due to drugs of left radius',
  'M87133 Osteonecrosis due to drugs of unspecified radius',
  'M87134 Osteonecrosis due to drugs of right ulna',
  'M87135 Osteonecrosis due to drugs of left ulna',
  'M87136 Osteonecrosis due to drugs of unspecified ulna',
  'M87137 Osteonecrosis due to drugs of right carpus',
  'M87138 Osteonecrosis due to drugs of left carpus',
  'M87139 Osteonecrosis due to drugs of unspecified carpus',
  'M87141 Osteonecrosis due to drugs, right hand',
  'M87142 Osteonecrosis due to drugs, left hand',
  'M87143 Osteonecrosis due to drugs, unspecified hand',
  'M87144 Osteonecrosis due to drugs, right finger(s)',
  'M87145 Osteonecrosis due to drugs, left finger(s)',
  'M87146 Osteonecrosis due to drugs, unspecified finger(s)',
  'M87150 Osteonecrosis due to drugs, pelvis',
  'M87151 Osteonecrosis due to drugs, right femur',
  'M87152 Osteonecrosis due to drugs, left femur',
  'M87159 Osteonecrosis due to drugs, unspecified femur',
  'M87161 Osteonecrosis due to drugs, right tibia',
  'M87162 Osteonecrosis due to drugs, left tibia',
  'M87163 Osteonecrosis due to drugs, unspecified tibia',
  'M87164 Osteonecrosis due to drugs, right fibula',
  'M87165 Osteonecrosis due to drugs, left fibula',
  'M87166 Osteonecrosis due to drugs, unspecified fibula',
  'M87171 Osteonecrosis due to drugs, right ankle',
  'M87172 Osteonecrosis due to drugs, left ankle',
  'M87173 Osteonecrosis due to drugs, unspecified ankle',
  'M87174 Osteonecrosis due to drugs, right foot',
  'M87175 Osteonecrosis due to drugs, left foot',
  'M87176 Osteonecrosis due to drugs, unspecified foot',
  'M87177 Osteonecrosis due to drugs, right toe(s)',
  'M87178 Osteonecrosis due to drugs, left toe(s)',
  'M87179 Osteonecrosis due to drugs, unspecified toe(s)',
  'M87180 Osteonecrosis due to drugs, jaw',
  'M87188 Osteonecrosis due to drugs, other site',
  'M8719 Osteonecrosis due to drugs, multiple sites',
  'M8720 Osteonecrosis due to previous trauma, unspecified bone',
  'M87211 Osteonecrosis due to previous trauma, right shoulder',
  'M87212 Osteonecrosis due to previous trauma, left shoulder',
  'M87219 Osteonecrosis due to previous trauma, unspecified shoulder',
  'M87221 Osteonecrosis due to previous trauma, right humerus',
  'M87222 Osteonecrosis due to previous trauma, left humerus',
  'M87229 Osteonecrosis due to previous trauma, unspecified humerus',
  'M87231 Osteonecrosis due to previous trauma of right radius',
  'M87232 Osteonecrosis due to previous trauma of left radius',
  'M87233 Osteonecrosis due to previous trauma of unspecified radius',
  'M87234 Osteonecrosis due to previous trauma of right ulna',
  'M87235 Osteonecrosis due to previous trauma of left ulna',
  'M87236 Osteonecrosis due to previous trauma of unspecified ulna',
  'M87237 Osteonecrosis due to previous trauma of right carpus',
  'M87238 Osteonecrosis due to previous trauma of left carpus',
  'M87239 Osteonecrosis due to previous trauma of unspecified carpus',
  'M87241 Osteonecrosis due to previous trauma, right hand',
  'M87242 Osteonecrosis due to previous trauma, left hand',
  'M87243 Osteonecrosis due to previous trauma, unspecified hand',
  'M87244 Osteonecrosis due to previous trauma, right finger(s)',
  'M87245 Osteonecrosis due to previous trauma, left finger(s)',
  'M87246 Osteonecrosis due to previous trauma, unspecified finger(s)',
  'M87250 Osteonecrosis due to previous trauma, pelvis',
  'M87251 Osteonecrosis due to previous trauma, right femur',
  'M87252 Osteonecrosis due to previous trauma, left femur',
  'M87256 Osteonecrosis due to previous trauma, unspecified femur',
  'M87261 Osteonecrosis due to previous trauma, right tibia',
  'M87262 Osteonecrosis due to previous trauma, left tibia',
  'M87263 Osteonecrosis due to previous trauma, unspecified tibia',
  'M87264 Osteonecrosis due to previous trauma, right fibula',
  'M87265 Osteonecrosis due to previous trauma, left fibula',
  'M87266 Osteonecrosis due to previous trauma, unspecified fibula',
  'M87271 Osteonecrosis due to previous trauma, right ankle',
  'M87272 Osteonecrosis due to previous trauma, left ankle',
  'M87273 Osteonecrosis due to previous trauma, unspecified ankle',
  'M87274 Osteonecrosis due to previous trauma, right foot',
  'M87275 Osteonecrosis due to previous trauma, left foot',
  'M87276 Osteonecrosis due to previous trauma, unspecified foot',
  'M87277 Osteonecrosis due to previous trauma, right toe(s)',
  'M87278 Osteonecrosis due to previous trauma, left toe(s)',
  'M87279 Osteonecrosis due to previous trauma, unspecified toe(s)',
  'M8728 Osteonecrosis due to previous trauma, other site',
  'M8729 Osteonecrosis due to previous trauma, multiple sites',
  'M8730 Other secondary osteonecrosis, unspecified bone',
  'M87311 Other secondary osteonecrosis, right shoulder',
  'M87312 Other secondary osteonecrosis, left shoulder',
  'M87319 Other secondary osteonecrosis, unspecified shoulder',
  'M87321 Other secondary osteonecrosis, right humerus',
  'M87322 Other secondary osteonecrosis, left humerus',
  'M87329 Other secondary osteonecrosis, unspecified humerus',
  'M87331 Other secondary osteonecrosis of right radius',
  'M87332 Other secondary osteonecrosis of left radius',
  'M87333 Other secondary osteonecrosis of unspecified radius',
  'M87334 Other secondary osteonecrosis of right ulna',
  'M87335 Other secondary osteonecrosis of left ulna',
  'M87336 Other secondary osteonecrosis of unspecified ulna',
  'M87337 Other secondary osteonecrosis of right carpus',
  'M87338 Other secondary osteonecrosis of left carpus',
  'M87339 Other secondary osteonecrosis of unspecified carpus',
  'M87341 Other secondary osteonecrosis, right hand',
  'M87342 Other secondary osteonecrosis, left hand',
  'M87343 Other secondary osteonecrosis, unspecified hand',
  'M87344 Other secondary osteonecrosis, right finger(s)',
  'M87345 Other secondary osteonecrosis, left finger(s)',
  'M87346 Other secondary osteonecrosis, unspecified finger(s)',
  'M87350 Other secondary osteonecrosis, pelvis',
  'M87351 Other secondary osteonecrosis, right femur',
  'M87352 Other secondary osteonecrosis, left femur',
  'M87353 Other secondary osteonecrosis, unspecified femur',
  'M87361 Other secondary osteonecrosis, right tibia',
  'M87362 Other secondary osteonecrosis, left tibia',
  'M87363 Other secondary osteonecrosis, unspecified tibia',
  'M87364 Other secondary osteonecrosis, right fibula',
  'M87365 Other secondary osteonecrosis, left fibula',
  'M87366 Other secondary osteonecrosis, unspecified fibula',
  'M87371 Other secondary osteonecrosis, right ankle',
  'M87372 Other secondary osteonecrosis, left ankle',
  'M87373 Other secondary osteonecrosis, unspecified ankle',
  'M87374 Other secondary osteonecrosis, right foot',
  'M87375 Other secondary osteonecrosis, left foot',
  'M87376 Other secondary osteonecrosis, unspecified foot',
  'M87377 Other secondary osteonecrosis, right toe(s)',
  'M87378 Other secondary osteonecrosis, left toe(s)',
  'M87379 Other secondary osteonecrosis, unspecified toe(s)',
  'M8738 Other secondary osteonecrosis, other site',
  'M8739 Other secondary osteonecrosis, multiple sites',
  'M8780 Other osteonecrosis, unspecified bone',
  'M87811 Other osteonecrosis, right shoulder',
  'M87812 Other osteonecrosis, left shoulder',
  'M87819 Other osteonecrosis, unspecified shoulder',
  'M87821 Other osteonecrosis, right humerus',
  'M87822 Other osteonecrosis, left humerus',
  'M87829 Other osteonecrosis, unspecified humerus',
  'M87831 Other osteonecrosis of right radius',
  'M87832 Other osteonecrosis of left radius',
  'M87833 Other osteonecrosis of unspecified radius',
  'M87834 Other osteonecrosis of right ulna',
  'M87835 Other osteonecrosis of left ulna',
  'M87836 Other osteonecrosis of unspecified ulna',
  'M87837 Other osteonecrosis of right carpus',
  'M87838 Other osteonecrosis of left carpus',
  'M87839 Other osteonecrosis of unspecified carpus',
  'M87841 Other osteonecrosis, right hand',
  'M87842 Other osteonecrosis, left hand',
  'M87843 Other osteonecrosis, unspecified hand',
  'M87844 Other osteonecrosis, right finger(s)',
  'M87845 Other osteonecrosis, left finger(s)',
  'M87849 Other osteonecrosis, unspecified finger(s)',
  'M87850 Other osteonecrosis, pelvis',
  'M87851 Other osteonecrosis, right femur',
  'M87852 Other osteonecrosis, left femur',
  'M87859 Other osteonecrosis, unspecified femur',
  'M87861 Other osteonecrosis, right tibia',
  'M87862 Other osteonecrosis, left tibia',
  'M87863 Other osteonecrosis, unspecified tibia',
  'M87864 Other osteonecrosis, right fibula',
  'M87865 Other osteonecrosis, left fibula',
  'M87869 Other osteonecrosis, unspecified fibula',
  'M87871 Other osteonecrosis, right ankle',
  'M87872 Other osteonecrosis, left ankle',
  'M87873 Other osteonecrosis, unspecified ankle',
  'M87874 Other osteonecrosis, right foot',
  'M87875 Other osteonecrosis, left foot',
  'M87876 Other osteonecrosis, unspecified foot',
  'M87877 Other osteonecrosis, right toe(s)',
  'M87878 Other osteonecrosis, left toe(s)',
  'M87879 Other osteonecrosis, unspecified toe(s)',
  'M8788 Other osteonecrosis, other site',
  'M8789 Other osteonecrosis, multiple sites',
  'M879 Osteonecrosis, unspecified',
  'M880 Osteitis deformans of skull',
  'M881 Osteitis deformans of vertebrae',
  'M88811 Osteitis deformans of right shoulder',
  'M88812 Osteitis deformans of left shoulder',
  'M88819 Osteitis deformans of unspecified shoulder',
  'M88821 Osteitis deformans of right upper arm',
  'M88822 Osteitis deformans of left upper arm',
  'M88829 Osteitis deformans of unspecified upper arm',
  'M88831 Osteitis deformans of right forearm',
  'M88832 Osteitis deformans of left forearm',
  'M88839 Osteitis deformans of unspecified forearm',
  'M88841 Osteitis deformans of right hand',
  'M88842 Osteitis deformans of left hand',
  'M88849 Osteitis deformans of unspecified hand',
  'M88851 Osteitis deformans of right thigh',
  'M88852 Osteitis deformans of left thigh',
  'M88859 Osteitis deformans of unspecified thigh',
  'M88861 Osteitis deformans of right lower leg',
  'M88862 Osteitis deformans of left lower leg',
  'M88869 Osteitis deformans of unspecified lower leg',
  'M88871 Osteitis deformans of right ankle and foot',
  'M88872 Osteitis deformans of left ankle and foot',
  'M88879 Osteitis deformans of unspecified ankle and foot',
  'M8888 Osteitis deformans of other bones',
  'M8889 Osteitis deformans of multiple sites',
  'M889 Osteitis deformans of unspecified bone',
  'M8900 Algoneurodystrophy, unspecified site',
  'M89011 Algoneurodystrophy, right shoulder',
  'M89012 Algoneurodystrophy, left shoulder',
  'M89019 Algoneurodystrophy, unspecified shoulder',
  'M89021 Algoneurodystrophy, right upper arm',
  'M89022 Algoneurodystrophy, left upper arm',
  'M89029 Algoneurodystrophy, unspecified upper arm',
  'M89031 Algoneurodystrophy, right forearm',
  'M89032 Algoneurodystrophy, left forearm',
  'M89039 Algoneurodystrophy, unspecified forearm',
  'M89041 Algoneurodystrophy, right hand',
  'M89042 Algoneurodystrophy, left hand',
  'M89049 Algoneurodystrophy, unspecified hand',
  'M89051 Algoneurodystrophy, right thigh',
  'M89052 Algoneurodystrophy, left thigh',
  'M89059 Algoneurodystrophy, unspecified thigh',
  'M89061 Algoneurodystrophy, right lower leg',
  'M89062 Algoneurodystrophy, left lower leg',
  'M89069 Algoneurodystrophy, unspecified lower leg',
  'M89071 Algoneurodystrophy, right ankle and foot',
  'M89072 Algoneurodystrophy, left ankle and foot',
  'M89079 Algoneurodystrophy, unspecified ankle and foot',
  'M8908 Algoneurodystrophy, other site',
  'M8909 Algoneurodystrophy, multiple sites',
  'M89121 Complete physeal arrest, right proximal humerus',
  'M89122 Complete physeal arrest, left proximal humerus',
  'M89123 Partial physeal arrest, right proximal humerus',
  'M89124 Partial physeal arrest, left proximal humerus',
  'M89125 Complete physeal arrest, right distal humerus',
  'M89126 Complete physeal arrest, left distal humerus',
  'M89127 Partial physeal arrest, right distal humerus',
  'M89128 Partial physeal arrest, left distal humerus',
  'M89129 Physeal arrest, humerus, unspecified',
  'M89131 Complete physeal arrest, right distal radius',
  'M89132 Complete physeal arrest, left distal radius',
  'M89133 Partial physeal arrest, right distal radius',
  'M89134 Partial physeal arrest, left distal radius',
  'M89138 Other physeal arrest of forearm',
  'M89139 Physeal arrest, forearm, unspecified',
  'M89151 Complete physeal arrest, right proximal femur',
  'M89152 Complete physeal arrest, left proximal femur',
  'M89153 Partial physeal arrest, right proximal femur',
  'M89154 Partial physeal arrest, left proximal femur',
  'M89155 Complete physeal arrest, right distal femur',
  'M89156 Complete physeal arrest, left distal femur',
  'M89157 Partial physeal arrest, right distal femur',
  'M89158 Partial physeal arrest, left distal femur',
  'M89159 Physeal arrest, femur, unspecified',
  'M89160 Complete physeal arrest, right proximal tibia',
  'M89161 Complete physeal arrest, left proximal tibia',
  'M89162 Partial physeal arrest, right proximal tibia',
  'M89163 Partial physeal arrest, left proximal tibia',
  'M89164 Complete physeal arrest, right distal tibia',
  'M89165 Complete physeal arrest, left distal tibia',
  'M89166 Partial physeal arrest, right distal tibia',
  'M89167 Partial physeal arrest, left distal tibia',
  'M89168 Other physeal arrest of lower leg',
  'M89169 Physeal arrest, lower leg, unspecified',
  'M8918 Physeal arrest, other site',
  'M8920 Other disorders of bone development and growth, unspecified site',
  'M89211 Other disorders of bone development and growth, right shoulder',
  'M89212 Other disorders of bone development and growth, left shoulder',
  'M89219 Other disorders of bone development and growth, unspecified shoulder',
  'M89221 Other disorders of bone development and growth, right humerus',
  'M89222 Other disorders of bone development and growth, left humerus',
  'M89229 Other disorders of bone development and growth, unspecified humerus',
  'M89231 Other disorders of bone development and growth, right ulna',
  'M89232 Other disorders of bone development and growth, left ulna',
  'M89233 Other disorders of bone development and growth, right radius',
  'M89234 Other disorders of bone development and growth, left radius',
  'M89239 Other disorders of bone development and growth, unspecified ulna and radius',
  'M89241 Other disorders of bone development and growth, right hand',
  'M89242 Other disorders of bone development and growth, left hand',
  'M89249 Other disorders of bone development and growth, unspecified hand',
  'M89251 Other disorders of bone development and growth, right femur',
  'M89252 Other disorders of bone development and growth, left femur',
  'M89259 Other disorders of bone development and growth, unspecified femur',
  'M89261 Other disorders of bone development and growth, right tibia',
  'M89262 Other disorders of bone development and growth, left tibia',
  'M89263 Other disorders of bone development and growth, right fibula',
  'M89264 Other disorders of bone development and growth, left fibula',
  'M89269 Other disorders of bone development and growth, unspecified lower leg',
  'M89271 Other disorders of bone development and growth, right ankle and foot',
  'M89272 Other disorders of bone development and growth, left ankle and foot',
  'M89279 Other disorders of bone development and growth, unspecified ankle and foot',
  'M8928 Other disorders of bone development and growth, other site',
  'M8929 Other disorders of bone development and growth, multiple sites',
  'M8930 Hypertrophy of bone, unspecified site',
  'M89311 Hypertrophy of bone, right shoulder',
  'M89312 Hypertrophy of bone, left shoulder',
  'M89319 Hypertrophy of bone, unspecified shoulder',
  'M89321 Hypertrophy of bone, right humerus',
  'M89322 Hypertrophy of bone, left humerus',
  'M89329 Hypertrophy of bone, unspecified humerus',
  'M89331 Hypertrophy of bone, right ulna',
  'M89332 Hypertrophy of bone, left ulna',
  'M89333 Hypertrophy of bone, right radius',
  'M89334 Hypertrophy of bone, left radius',
  'M89339 Hypertrophy of bone, unspecified ulna and radius',
  'M89341 Hypertrophy of bone, right hand',
  'M89342 Hypertrophy of bone, left hand',
  'M89349 Hypertrophy of bone, unspecified hand',
  'M89351 Hypertrophy of bone, right femur',
  'M89352 Hypertrophy of bone, left femur',
  'M89359 Hypertrophy of bone, unspecified femur',
  'M89361 Hypertrophy of bone, right tibia',
  'M89362 Hypertrophy of bone, left tibia',
  'M89363 Hypertrophy of bone, right fibula',
  'M89364 Hypertrophy of bone, left fibula',
  'M89369 Hypertrophy of bone, unspecified tibia and fibula',
  'M89371 Hypertrophy of bone, right ankle and foot',
  'M89372 Hypertrophy of bone, left ankle and foot',
  'M89379 Hypertrophy of bone, unspecified ankle and foot',
  'M8938 Hypertrophy of bone, other site',
  'M8939 Hypertrophy of bone, multiple sites',
  'M8940 Other hypertrophic osteoarthropathy, unspecified site',
  'M89411 Other hypertrophic osteoarthropathy, right shoulder',
  'M89412 Other hypertrophic osteoarthropathy, left shoulder',
  'M89419 Other hypertrophic osteoarthropathy, unspecified shoulder',
  'M89421 Other hypertrophic osteoarthropathy, right upper arm',
  'M89422 Other hypertrophic osteoarthropathy, left upper arm',
  'M89429 Other hypertrophic osteoarthropathy, unspecified upper arm',
  'M89431 Other hypertrophic osteoarthropathy, right forearm',
  'M89432 Other hypertrophic osteoarthropathy, left forearm',
  'M89439 Other hypertrophic osteoarthropathy, unspecified forearm',
  'M89441 Other hypertrophic osteoarthropathy, right hand',
  'M89442 Other hypertrophic osteoarthropathy, left hand',
  'M89449 Other hypertrophic osteoarthropathy, unspecified hand',
  'M89451 Other hypertrophic osteoarthropathy, right thigh',
  'M89452 Other hypertrophic osteoarthropathy, left thigh',
  'M89459 Other hypertrophic osteoarthropathy, unspecified thigh',
  'M89461 Other hypertrophic osteoarthropathy, right lower leg',
  'M89462 Other hypertrophic osteoarthropathy, left lower leg',
  'M89469 Other hypertrophic osteoarthropathy, unspecified lower leg',
  'M89471 Other hypertrophic osteoarthropathy, right ankle and foot',
  'M89472 Other hypertrophic osteoarthropathy, left ankle and foot',
  'M89479 Other hypertrophic osteoarthropathy, unspecified ankle and foot',
  'M8948 Other hypertrophic osteoarthropathy, other site',
  'M8949 Other hypertrophic osteoarthropathy, multiple sites',
  'M8950 Osteolysis, unspecified site',
  'M89511 Osteolysis, right shoulder',
  'M89512 Osteolysis, left shoulder',
  'M89519 Osteolysis, unspecified shoulder',
  'M89521 Osteolysis, right upper arm',
  'M89522 Osteolysis, left upper arm',
  'M89529 Osteolysis, unspecified upper arm',
  'M89531 Osteolysis, right forearm',
  'M89532 Osteolysis, left forearm',
  'M89539 Osteolysis, unspecified forearm',
  'M89541 Osteolysis, right hand',
  'M89542 Osteolysis, left hand',
  'M89549 Osteolysis, unspecified hand',
  'M89551 Osteolysis, right thigh',
  'M89552 Osteolysis, left thigh',
  'M89559 Osteolysis, unspecified thigh',
  'M89561 Osteolysis, right lower leg',
  'M89562 Osteolysis, left lower leg',
  'M89569 Osteolysis, unspecified lower leg',
  'M89571 Osteolysis, right ankle and foot',
  'M89572 Osteolysis, left ankle and foot',
  'M89579 Osteolysis, unspecified ankle and foot',
  'M8958 Osteolysis, other site',
  'M8959 Osteolysis, multiple sites',
  'M8960 Osteopathy after poliomyelitis, unspecified site',
  'M89611 Osteopathy after poliomyelitis, right shoulder',
  'M89612 Osteopathy after poliomyelitis, left shoulder',
  'M89619 Osteopathy after poliomyelitis, unspecified shoulder',
  'M89621 Osteopathy after poliomyelitis, right upper arm',
  'M89622 Osteopathy after poliomyelitis, left upper arm',
  'M89629 Osteopathy after poliomyelitis, unspecified upper arm',
  'M89631 Osteopathy after poliomyelitis, right forearm',
  'M89632 Osteopathy after poliomyelitis, left forearm',
  'M89639 Osteopathy after poliomyelitis, unspecified forearm',
  'M89641 Osteopathy after poliomyelitis, right hand',
  'M89642 Osteopathy after poliomyelitis, left hand',
  'M89649 Osteopathy after poliomyelitis, unspecified hand',
  'M89651 Osteopathy after poliomyelitis, right thigh',
  'M89652 Osteopathy after poliomyelitis, left thigh',
  'M89659 Osteopathy after poliomyelitis, unspecified thigh',
  'M89661 Osteopathy after poliomyelitis, right lower leg',
  'M89662 Osteopathy after poliomyelitis, left lower leg',
  'M89669 Osteopathy after poliomyelitis, unspecified lower leg',
  'M89671 Osteopathy after poliomyelitis, right ankle and foot',
  'M89672 Osteopathy after poliomyelitis, left ankle and foot',
  'M89679 Osteopathy after poliomyelitis, unspecified ankle and foot',
  'M8968 Osteopathy after poliomyelitis, other site',
  'M8969 Osteopathy after poliomyelitis, multiple sites',
  'M8970 Major osseous defect, unspecified site',
  'M89711 Major osseous defect, right shoulder region',
  'M89712 Major osseous defect, left shoulder region',
  'M89719 Major osseous defect, unspecified shoulder region',
  'M89721 Major osseous defect, right humerus',
  'M89722 Major osseous defect, left humerus',
  'M89729 Major osseous defect, unspecified humerus',
  'M89731 Major osseous defect, right forearm',
  'M89732 Major osseous defect, left forearm',
  'M89739 Major osseous defect, unspecified forearm',
  'M89741 Major osseous defect, right hand',
  'M89742 Major osseous defect, left hand',
  'M89749 Major osseous defect, unspecified hand',
  'M89751 Major osseous defect, right pelvic region and thigh',
  'M89752 Major osseous defect, left pelvic region and thigh',
  'M89759 Major osseous defect, unspecified pelvic region and thigh',
  'M89761 Major osseous defect, right lower leg',
  'M89762 Major osseous defect, left lower leg',
  'M89769 Major osseous defect, unspecified lower leg',
  'M89771 Major osseous defect, right ankle and foot',
  'M89772 Major osseous defect, left ankle and foot',
  'M89779 Major osseous defect, unspecified ankle and foot',
  'M8978 Major osseous defect, other site',
  'M8979 Major osseous defect, multiple sites',
  'M898X0 Other specified disorders of bone, multiple sites',
  'M898X1 Other specified disorders of bone, shoulder',
  'M898X2 Other specified disorders of bone, upper arm',
  'M898X3 Other specified disorders of bone, forearm',
  'M898X4 Other specified disorders of bone, hand',
  'M898X5 Other specified disorders of bone, thigh',
  'M898X6 Other specified disorders of bone, lower leg',
  'M898X7 Other specified disorders of bone, ankle and foot',
  'M898X8 Other specified disorders of bone, other site',
  'M898X9 Other specified disorders of bone, unspecified site',
  'M899 Disorder of bone, unspecified',
  'M9050 Osteonecrosis in diseases classified elsewhere, unspecified site',
  'M90511 Osteonecrosis in diseases classified elsewhere, right shoulder',
  'M90512 Osteonecrosis in diseases classified elsewhere, left shoulder',
  'M90519 Osteonecrosis in diseases classified elsewhere, unspecified shoulder',
  'M90521 Osteonecrosis in diseases classified elsewhere, right upper arm',
  'M90522 Osteonecrosis in diseases classified elsewhere, left upper arm',
  'M90529 Osteonecrosis in diseases classified elsewhere, unspecified upper arm',
  'M90531 Osteonecrosis in diseases classified elsewhere, right forearm',
  'M90532 Osteonecrosis in diseases classified elsewhere, left forearm',
  'M90539 Osteonecrosis in diseases classified elsewhere, unspecified forearm',
  'M90541 Osteonecrosis in diseases classified elsewhere, right hand',
  'M90542 Osteonecrosis in diseases classified elsewhere, left hand',
  'M90549 Osteonecrosis in diseases classified elsewhere, unspecified hand',
  'M90551 Osteonecrosis in diseases classified elsewhere, right thigh',
  'M90552 Osteonecrosis in diseases classified elsewhere, left thigh',
  'M90559 Osteonecrosis in diseases classified elsewhere, unspecified thigh',
  'M90561 Osteonecrosis in diseases classified elsewhere, right lower leg',
  'M90562 Osteonecrosis in diseases classified elsewhere, left lower leg',
  'M90569 Osteonecrosis in diseases classified elsewhere, unspecified lower leg',
  'M90571 Osteonecrosis in diseases classified elsewhere, right ankle and foot',
  'M90572 Osteonecrosis in diseases classified elsewhere, left ankle and foot',
  'M90579 Osteonecrosis in diseases classified elsewhere, unspecified ankle and foot',
  'M9058 Osteonecrosis in diseases classified elsewhere, other site',
  'M9059 Osteonecrosis in diseases classified elsewhere, multiple sites',
  'M9060 Osteitis deformans in neoplastic diseases, unspecified site',
  'M90611 Osteitis deformans in neoplastic diseases, right shoulder',
  'M90612 Osteitis deformans in neoplastic diseases, left shoulder',
  'M90619 Osteitis deformans in neoplastic diseases, unspecified shoulder',
  'M90621 Osteitis deformans in neoplastic diseases, right upper arm',
  'M90622 Osteitis deformans in neoplastic diseases, left upper arm',
  'M90629 Osteitis deformans in neoplastic diseases, unspecified upper arm',
  'M90631 Osteitis deformans in neoplastic diseases, right forearm',
  'M90632 Osteitis deformans in neoplastic diseases, left forearm',
  'M90639 Osteitis deformans in neoplastic diseases, unspecified forearm',
  'M90641 Osteitis deformans in neoplastic diseases, right hand',
  'M90642 Osteitis deformans in neoplastic diseases, left hand',
  'M90649 Osteitis deformans in neoplastic diseases, unspecified hand',
  'M90651 Osteitis deformans in neoplastic diseases, right thigh',
  'M90652 Osteitis deformans in neoplastic diseases, left thigh',
  'M90659 Osteitis deformans in neoplastic diseases, unspecified thigh',
  'M90661 Osteitis deformans in neoplastic diseases, right lower leg',
  'M90662 Osteitis deformans in neoplastic diseases, left lower leg',
  'M90669 Osteitis deformans in neoplastic diseases, unspecified lower leg',
  'M90671 Osteitis deformans in neoplastic diseases, right ankle and foot',
  'M90672 Osteitis deformans in neoplastic diseases, left ankle and foot',
  'M90679 Osteitis deformans in neoplastic diseases, unspecified ankle and foot',
  'M9068 Osteitis deformans in neoplastic diseases, other site',
  'M9069 Osteitis deformans in neoplastic diseases, multiple sites',
  'M9080 Osteopathy in diseases classified elsewhere, unspecified site',
  'M90811 Osteopathy in diseases classified elsewhere, right shoulder',
  'M90812 Osteopathy in diseases classified elsewhere, left shoulder',
  'M90819 Osteopathy in diseases classified elsewhere, unspecified shoulder',
  'M90821 Osteopathy in diseases classified elsewhere, right upper arm',
  'M90822 Osteopathy in diseases classified elsewhere, left upper arm',
  'M90829 Osteopathy in diseases classified elsewhere, unspecified upper arm',
  'M90831 Osteopathy in diseases classified elsewhere, right forearm',
  'M90832 Osteopathy in diseases classified elsewhere, left forearm',
  'M90839 Osteopathy in diseases classified elsewhere, unspecified forearm',
  'M90841 Osteopathy in diseases classified elsewhere, right hand',
  'M90842 Osteopathy in diseases classified elsewhere, left hand',
  'M90849 Osteopathy in diseases classified elsewhere, unspecified hand',
  'M90851 Osteopathy in diseases classified elsewhere, right thigh',
  'M90852 Osteopathy in diseases classified elsewhere, left thigh',
  'M90859 Osteopathy in diseases classified elsewhere, unspecified thigh',
  'M90861 Osteopathy in diseases classified elsewhere, right lower leg',
  'M90862 Osteopathy in diseases classified elsewhere, left lower leg',
  'M90869 Osteopathy in diseases classified elsewhere, unspecified lower leg',
  'M90871 Osteopathy in diseases classified elsewhere, right ankle and foot',
  'M90872 Osteopathy in diseases classified elsewhere, left ankle and foot',
  'M90879 Osteopathy in diseases classified elsewhere, unspecified ankle and foot',
  'M9088 Osteopathy in diseases classified elsewhere, other site',
  'M9089 Osteopathy in diseases classified elsewhere, multiple sites',
  'M910 Juvenile osteochondrosis of pelvis',
  'M9110 Juvenile osteochondrosis of head of femur [Legg-Calve-Perthes], unspecified leg',
  'M9111 Juvenile osteochondrosis of head of femur [Legg-Calve-Perthes], right leg',
  'M9112 Juvenile osteochondrosis of head of femur [Legg-Calve-Perthes], left leg',
  'M9120 Coxa plana, unspecified hip',
  'M9121 Coxa plana, right hip',
  'M9122 Coxa plana, left hip',
  'M9130 Pseudocoxalgia, unspecified hip',
  'M9131 Pseudocoxalgia, right hip',
  'M9132 Pseudocoxalgia, left hip',
  'M9140 Coxa magna, unspecified hip',
  'M9141 Coxa magna, right hip',
  'M9142 Coxa magna, left hip',
  'M9180 Other juvenile osteochondrosis of hip and pelvis, unspecified leg',
  'M9181 Other juvenile osteochondrosis of hip and pelvis, right leg',
  'M9182 Other juvenile osteochondrosis of hip and pelvis, left leg',
  'M9190 Juvenile osteochondrosis of hip and pelvis, unspecified, unspecified leg',
  'M9191 Juvenile osteochondrosis of hip and pelvis, unspecified, right leg',
  'M9192 Juvenile osteochondrosis of hip and pelvis, unspecified, left leg',
  'M9200 Juvenile osteochondrosis of humerus, unspecified arm',
  'M9201 Juvenile osteochondrosis of humerus, right arm',
  'M9202 Juvenile osteochondrosis of humerus, left arm',
  'M9210 Juvenile osteochondrosis of radius and ulna, unspecified arm',
  'M9211 Juvenile osteochondrosis of radius and ulna, right arm',
  'M9212 Juvenile osteochondrosis of radius and ulna, left arm',
  'M92201 Unspecified juvenile osteochondrosis, right hand',
  'M92202 Unspecified juvenile osteochondrosis, left hand',
  'M92209 Unspecified juvenile osteochondrosis, unspecified hand',
  'M92211 Osteochondrosis (juvenile) of carpal lunate [Kienbock], right hand',
  'M92212 Osteochondrosis (juvenile) of carpal lunate [Kienbock], left hand',
  'M92219 Osteochondrosis (juvenile) of carpal lunate [Kienbock], unspecified hand',
  'M92221 Osteochondrosis (juvenile) of metacarpal heads [Mauclaire], right hand',
  'M92222 Osteochondrosis (juvenile) of metacarpal heads [Mauclaire], left hand',
  'M92229 Osteochondrosis (juvenile) of metacarpal heads [Mauclaire], unspecified hand',
  'M92291 Other juvenile osteochondrosis, right hand',
  'M92292 Other juvenile osteochondrosis, left hand',
  'M92299 Other juvenile osteochondrosis, unspecified hand',
  'M9230 Other juvenile osteochondrosis, unspecified upper limb',
  'M9231 Other juvenile osteochondrosis, right upper limb',
  'M9232 Other juvenile osteochondrosis, left upper limb',
  'M9240 Juvenile osteochondrosis of patella, unspecified knee',
  'M9241 Juvenile osteochondrosis of patella, right knee',
  'M9242 Juvenile osteochondrosis of patella, left knee',
  'M9250 Juvenile osteochondrosis of tibia and fibula, unspecified leg',
  'M9251 Juvenile osteochondrosis of tibia and fibula, right leg',
  'M9252 Juvenile osteochondrosis of tibia and fibula, left leg',
  'M9260 Juvenile osteochondrosis of tarsus, unspecified ankle',
  'M9261 Juvenile osteochondrosis of tarsus, right ankle',
  'M9262 Juvenile osteochondrosis of tarsus, left ankle',
  'M9270 Juvenile osteochondrosis of metatarsus, unspecified foot',
  'M9271 Juvenile osteochondrosis of metatarsus, right foot',
  'M9272 Juvenile osteochondrosis of metatarsus, left foot',
  'M928 Other specified juvenile osteochondrosis',
  'M929 Juvenile osteochondrosis, unspecified',
  'M93001 Unspecified slipped upper femoral epiphysis (nontraumatic), right hip',
  'M93002 Unspecified slipped upper femoral epiphysis (nontraumatic), left hip',
  'M93003 Unspecified slipped upper femoral epiphysis (nontraumatic), unspecified hip',
  'M93011 Acute slipped upper femoral epiphysis (nontraumatic), right hip',
  'M93012 Acute slipped upper femoral epiphysis (nontraumatic), left hip',
  'M93013 Acute slipped upper femoral epiphysis (nontraumatic), unspecified hip',
  'M93021 Chronic slipped upper femoral epiphysis (nontraumatic), right hip',
  'M93022 Chronic slipped upper femoral epiphysis (nontraumatic), left hip',
  'M93023 Chronic slipped upper femoral epiphysis (nontraumatic), unspecified hip',
  'M93031 Acute on chronic slipped upper femoral epiphysis (nontraumatic), right hip',
  'M93032 Acute on chronic slipped upper femoral epiphysis (nontraumatic), left hip',
  'M93033 Acute on chronic slipped upper femoral epiphysis (nontraumatic), unspecified hip',
  "M931 Kienbock's disease of adults",
  'M9320 Osteochondritis dissecans of unspecified site',
  'M93211 Osteochondritis dissecans, right shoulder',
  'M93212 Osteochondritis dissecans, left shoulder',
  'M93219 Osteochondritis dissecans, unspecified shoulder',
  'M93221 Osteochondritis dissecans, right elbow',
  'M93222 Osteochondritis dissecans, left elbow',
  'M93229 Osteochondritis dissecans, unspecified elbow',
  'M93231 Osteochondritis dissecans, right wrist',
  'M93232 Osteochondritis dissecans, left wrist',
  'M93239 Osteochondritis dissecans, unspecified wrist',
  'M93241 Osteochondritis dissecans, joints of right hand',
  'M93242 Osteochondritis dissecans, joints of left hand',
  'M93249 Osteochondritis dissecans, joints of unspecified hand',
  'M93251 Osteochondritis dissecans, right hip',
  'M93252 Osteochondritis dissecans, left hip',
  'M93259 Osteochondritis dissecans, unspecified hip',
  'M93261 Osteochondritis dissecans, right knee',
  'M93262 Osteochondritis dissecans, left knee',
  'M93269 Osteochondritis dissecans, unspecified knee',
  'M93271 Osteochondritis dissecans, right ankle and joints of right foot',
  'M93272 Osteochondritis dissecans, left ankle and joints of left foot',
  'M93279 Osteochondritis dissecans, unspecified ankle and joints of foot',
  'M9328 Osteochondritis dissecans other site',
  'M9329 Osteochondritis dissecans multiple sites',
  'M9380 Other specified osteochondropathies of unspecified site',
  'M93811 Other specified osteochondropathies, right shoulder',
  'M93812 Other specified osteochondropathies, left shoulder',
  'M93819 Other specified osteochondropathies, unspecified shoulder',
  'M93821 Other specified osteochondropathies, right upper arm',
  'M93822 Other specified osteochondropathies, left upper arm',
  'M93829 Other specified osteochondropathies, unspecified upper arm',
  'M93831 Other specified osteochondropathies, right forearm',
  'M93832 Other specified osteochondropathies, left forearm',
  'M93839 Other specified osteochondropathies, unspecified forearm',
  'M93841 Other specified osteochondropathies, right hand',
  'M93842 Other specified osteochondropathies, left hand',
  'M93849 Other specified osteochondropathies, unspecified hand',
  'M93851 Other specified osteochondropathies, right thigh',
  'M93852 Other specified osteochondropathies, left thigh',
  'M93859 Other specified osteochondropathies, unspecified thigh',
  'M93861 Other specified osteochondropathies, right lower leg',
  'M93862 Other specified osteochondropathies, left lower leg',
  'M93869 Other specified osteochondropathies, unspecified lower leg',
  'M93871 Other specified osteochondropathies, right ankle and foot',
  'M93872 Other specified osteochondropathies, left ankle and foot',
  'M93879 Other specified osteochondropathies, unspecified ankle and foot',
  'M9388 Other specified osteochondropathies other',
  'M9389 Other specified osteochondropathies multiple sites',
  'M9390 Osteochondropathy, unspecified of unspecified site',
  'M93911 Osteochondropathy, unspecified, right shoulder',
  'M93912 Osteochondropathy, unspecified, left shoulder',
  'M93919 Osteochondropathy, unspecified, unspecified shoulder',
  'M93921 Osteochondropathy, unspecified, right upper arm',
  'M93922 Osteochondropathy, unspecified, left upper arm',
  'M93929 Osteochondropathy, unspecified, unspecified upper arm',
  'M93931 Osteochondropathy, unspecified, right forearm',
  'M93932 Osteochondropathy, unspecified, left forearm',
  'M93939 Osteochondropathy, unspecified, unspecified forearm',
  'M93941 Osteochondropathy, unspecified, right hand',
  'M93942 Osteochondropathy, unspecified, left hand',
  'M93949 Osteochondropathy, unspecified, unspecified hand',
  'M93951 Osteochondropathy, unspecified, right thigh',
  'M93952 Osteochondropathy, unspecified, left thigh',
  'M93959 Osteochondropathy, unspecified, unspecified thigh',
  'M93961 Osteochondropathy, unspecified, right lower leg',
  'M93962 Osteochondropathy, unspecified, left lower leg',
  'M93969 Osteochondropathy, unspecified, unspecified lower leg',
  'M93971 Osteochondropathy, unspecified, right ankle and foot',
  'M93972 Osteochondropathy, unspecified, left ankle and foot',
  'M93979 Osteochondropathy, unspecified, unspecified ankle and foot',
  'M9398 Osteochondropathy, unspecified other',
  'M9399 Osteochondropathy, unspecified multiple sites',
  'M940 Chondrocostal junction syndrome [Tietze]',
  'M941 Relapsing polychondritis',
  'M9420 Chondromalacia, unspecified site',
  'M94211 Chondromalacia, right shoulder',
  'M94212 Chondromalacia, left shoulder',
  'M94219 Chondromalacia, unspecified shoulder',
  'M94221 Chondromalacia, right elbow',
  'M94222 Chondromalacia, left elbow',
  'M94229 Chondromalacia, unspecified elbow',
  'M94231 Chondromalacia, right wrist',
  'M94232 Chondromalacia, left wrist',
  'M94239 Chondromalacia, unspecified wrist',
  'M94241 Chondromalacia, joints of right hand',
  'M94242 Chondromalacia, joints of left hand',
  'M94249 Chondromalacia, joints of unspecified hand',
  'M94251 Chondromalacia, right hip',
  'M94252 Chondromalacia, left hip',
  'M94259 Chondromalacia, unspecified hip',
  'M94261 Chondromalacia, right knee',
  'M94262 Chondromalacia, left knee',
  'M94269 Chondromalacia, unspecified knee',
  'M94271 Chondromalacia, right ankle and joints of right foot',
  'M94272 Chondromalacia, left ankle and joints of left foot',
  'M94279 Chondromalacia, unspecified ankle and joints of foot',
  'M9428 Chondromalacia, other site',
  'M9429 Chondromalacia, multiple sites',
  'M94351 Chondrolysis, right hip',
  'M94352 Chondrolysis, left hip',
  'M94359 Chondrolysis, unspecified hip',
  'M948X0 Other specified disorders of cartilage, multiple sites',
  'M948X1 Other specified disorders of cartilage, shoulder',
  'M948X2 Other specified disorders of cartilage, upper arm',
  'M948X3 Other specified disorders of cartilage, forearm',
  'M948X4 Other specified disorders of cartilage, hand',
  'M948X5 Other specified disorders of cartilage, thigh',
  'M948X6 Other specified disorders of cartilage, lower leg',
  'M948X7 Other specified disorders of cartilage, ankle and foot',
  'M948X8 Other specified disorders of cartilage, other site',
  'M948X9 Other specified disorders of cartilage, unspecified sites',
  'M949 Disorder of cartilage, unspecified',
  'M950 Acquired deformity of nose',
  'M9510 Cauliflower ear, unspecified ear',
  'M9511 Cauliflower ear, right ear',
  'M9512 Cauliflower ear, left ear',
  'M952 Other acquired deformity of head',
  'M953 Acquired deformity of neck',
  'M954 Acquired deformity of chest and rib',
  'M955 Acquired deformity of pelvis',
  'M958 Other specified acquired deformities of musculoskeletal system',
  'M959 Acquired deformity of musculoskeletal system, unspecified',
  'M960 Pseudarthrosis after fusion or arthrodesis',
  'M961 Postlaminectomy syndrome, not elsewhere classified',
  'M962 Postradiation kyphosis',
  'M963 Postlaminectomy kyphosis',
  'M964 Postsurgical lordosis',
  'M965 Postradiation scoliosis',
  'M96621 Fracture of humerus following insertion of orthopedic implant, joint prosthesis, or bone plate,',
  'right arm',
  'M96622 Fracture of humerus following insertion of orthopedic implant, joint prosthesis, or bone plate,',
  'left arm',
  'M96629 Fracture of humerus following insertion of orthopedic implant, joint prosthesis, or bone plate,',
  'unspecified arm',
  'M96631 Fracture of radius or ulna following insertion of orthopedic implant, joint prosthesis, or bone',
  'plate, right arm',
  'M96632 Fracture of radius or ulna following insertion of orthopedic implant, joint prosthesis, or bone',
  'plate, left arm',
  'M96639 Fracture of radius or ulna following insertion of orthopedic implant, joint prosthesis, or bone',
  'plate, unspecified arm',
  'M9665 Fracture of pelvis following insertion of orthopedic implant, joint prosthesis, or bone plate',
  'M96661 Fracture of femur following insertion of orthopedic implant, joint prosthesis, or bone plate,',
  'right leg',
  'M96662 Fracture of femur following insertion of orthopedic implant, joint prosthesis, or bone plate, left',
  'leg',
  'M96669 Fracture of femur following insertion of orthopedic implant, joint prosthesis, or bone plate,',
  'unspecified leg',
  'M96671 Fracture of tibia or fibula following insertion of orthopedic implant, joint prosthesis, or bone',
  'plate, right leg',
  'M96672 Fracture of tibia or fibula following insertion of orthopedic implant, joint prosthesis, or bone',
  'plate, left leg',
  'M96679 Fracture of tibia or fibula following insertion of orthopedic implant, joint prosthesis, or bone',
  'plate, unspecified leg',
  'M9669 Fracture of other bone following insertion of orthopedic implant, joint prosthesis, or bone plate',
  'M96810 Intraoperative hemorrhage and hematoma of a musculoskeletal structure complicating a',
  'musculoskeletal system procedure',
  'M96811 Intraoperative hemorrhage and hematoma of a musculoskeletal structure complicating other',
  'procedure',
  'M96820 Accidental puncture and laceration of a musculoskeletal structure during a musculoskeletal',
  'system procedure',
  'M96821 Accidental puncture and laceration of a musculoskeletal structure during other procedure',
  'M96830 Postprocedural hemorrhage of a musculoskeletal structure following a musculoskeletal system',
  'procedure',
  'M96831 Postprocedural hemorrhage of a musculoskeletal structure following other procedure',
  'M96840 Postprocedural hematoma of a musculoskeletal structure following a musculoskeletal system',
  'procedure',
  'M96841 Postprocedural hematoma of a musculoskeletal structure following other procedure',
  'M96842 Postprocedural seroma of a musculoskeletal structure following a musculoskeletal system',
  'procedure',
  'M96843 Postprocedural seroma of a musculoskeletal structure following other procedure',
  'M9689 Other intraoperative and postprocedural complications and disorders of the musculoskeletal',
  'system',
  'M9701XA Periprosthetic fracture around internal prosthetic right hip joint, initial encounter',
  'M9701XD Periprosthetic fracture around internal prosthetic right hip joint, subsequent encounter',
  'M9701XS Periprosthetic fracture around internal prosthetic right hip joint, sequela',
  'M9702XA Periprosthetic fracture around internal prosthetic left hip joint, initial encounter',
  'M9702XD Periprosthetic fracture around internal prosthetic left hip joint, subsequent encounter',
  'M9702XS Periprosthetic fracture around internal prosthetic left hip joint, sequela',
  'M9711XA Periprosthetic fracture around internal prosthetic right knee joint, initial encounter',
  'M9711XD Periprosthetic fracture around internal prosthetic right knee joint, subsequent encounter',
  'M9711XS Periprosthetic fracture around internal prosthetic right knee joint, sequela',
  'M9712XA Periprosthetic fracture around internal prosthetic left knee joint, initial encounter',
  'M9712XD Periprosthetic fracture around internal prosthetic left knee joint, subsequent encounter',
  'M9712XS Periprosthetic fracture around internal prosthetic left knee joint, sequela',
  'M9721XA Periprosthetic fracture around internal prosthetic right ankle joint, initial encounter',
  'M9721XD Periprosthetic fracture around internal prosthetic right ankle joint, subsequent encounter',
  'M9721XS Periprosthetic fracture around internal prosthetic right ankle joint, sequela',
  'M9722XA Periprosthetic fracture around internal prosthetic left ankle joint, initial encounter',
  'M9722XD Periprosthetic fracture around internal prosthetic left ankle joint, subsequent encounter',
  'M9722XS Periprosthetic fracture around internal prosthetic left ankle joint, sequela',
  'M9731XA Periprosthetic fracture around internal prosthetic right shoulder joint, initial encounter',
  'M9731XD Periprosthetic fracture around internal prosthetic right shoulder joint, subsequent encounter',
  'M9731XS Periprosthetic fracture around internal prosthetic right shoulder joint, sequela',
  'M9732XA Periprosthetic fracture around internal prosthetic left shoulder joint, initial encounter',
  'M9732XD Periprosthetic fracture around internal prosthetic left shoulder joint, subsequent encounter',
  'M9732XS Periprosthetic fracture around internal prosthetic left shoulder joint, sequela',
  'M9741XA Periprosthetic fracture around internal prosthetic right elbow joint, initial encounter',
  'M9741XD Periprosthetic fracture around internal prosthetic right elbow joint, subsequent encounter',
  'M9741XS Periprosthetic fracture around internal prosthetic right elbow joint, sequela',
  'M9742XA Periprosthetic fracture around internal prosthetic left elbow joint, initial encounter',
  'M9742XD Periprosthetic fracture around internal prosthetic left elbow joint, subsequent encounter',
  'M9742XS Periprosthetic fracture around internal prosthetic left elbow joint, sequela',
  'M978XXA Periprosthetic fracture around other internal prosthetic joint, initial encounter',
  'M978XXD Periprosthetic fracture around other internal prosthetic joint, subsequent encounter',
  'M978XXS Periprosthetic fracture around other internal prosthetic joint, sequela',
  'M979XXA Periprosthetic fracture around unspecified internal prosthetic joint, initial encounter',
  'M979XXD Periprosthetic fracture around unspecified internal prosthetic joint, subsequent encounter',
  'M979XXS Periprosthetic fracture around unspecified internal prosthetic joint, sequela',
  'M9900 Segmental and somatic dysfunction of head region',
  'M9901 Segmental and somatic dysfunction of cervical region',
  'M9902 Segmental and somatic dysfunction of thoracic region',
  'M9903 Segmental and somatic dysfunction of lumbar region',
  'M9904 Segmental and somatic dysfunction of sacral region',
  'M9905 Segmental and somatic dysfunction of pelvic region',
  'M9906 Segmental and somatic dysfunction of lower extremity',
  'M9907 Segmental and somatic dysfunction of upper extremity',
  'M9908 Segmental and somatic dysfunction of rib cage',
  'M9909 Segmental and somatic dysfunction of abdomen and other regions',
  'M9910 Subluxation complex (vertebral) of head region',
  'M9911 Subluxation complex (vertebral) of cervical region',
  'M9912 Subluxation complex (vertebral) of thoracic region',
  'M9913 Subluxation complex (vertebral) of lumbar region',
  'M9914 Subluxation complex (vertebral) of sacral region',
  'M9915 Subluxation complex (vertebral) of pelvic region',
  'M9916 Subluxation complex (vertebral) of lower extremity',
  'M9917 Subluxation complex (vertebral) of upper extremity',
  'M9918 Subluxation complex (vertebral) of rib cage',
  'M9919 Subluxation complex (vertebral) of abdomen and other regions',
  'M9920 Subluxation stenosis of neural canal of head region',
  'M9921 Subluxation stenosis of neural canal of cervical region',
  'M9922 Subluxation stenosis of neural canal of thoracic region',
  'M9923 Subluxation stenosis of neural canal of lumbar region',
  'M9924 Subluxation stenosis of neural canal of sacral region',
  'M9925 Subluxation stenosis of neural canal of pelvic region',
  'M9926 Subluxation stenosis of neural canal of lower extremity',
  'M9927 Subluxation stenosis of neural canal of upper extremity',
  'M9928 Subluxation stenosis of neural canal of rib cage',
  'M9929 Subluxation stenosis of neural canal of abdomen and other regions',
  'M9930 Osseous stenosis of neural canal of head region',
  'M9931 Osseous stenosis of neural canal of cervical region',
  'M9932 Osseous stenosis of neural canal of thoracic region',
  'M9933 Osseous stenosis of neural canal of lumbar region',
  'M9934 Osseous stenosis of neural canal of sacral region',
  'M9935 Osseous stenosis of neural canal of pelvic region',
  'M9936 Osseous stenosis of neural canal of lower extremity',
  'M9937 Osseous stenosis of neural canal of upper extremity',
  'M9938 Osseous stenosis of neural canal of rib cage',
  'M9939 Osseous stenosis of neural canal of abdomen and other regions',
  'M9940 Connective tissue stenosis of neural canal of head region',
  'M9941 Connective tissue stenosis of neural canal of cervical region',
  'M9942 Connective tissue stenosis of neural canal of thoracic region',
  'M9943 Connective tissue stenosis of neural canal of lumbar region',
  'M9944 Connective tissue stenosis of neural canal of sacral region',
  'M9945 Connective tissue stenosis of neural canal of pelvic region',
  'M9946 Connective tissue stenosis of neural canal of lower extremity',
  'M9947 Connective tissue stenosis of neural canal of upper extremity',
  'M9948 Connective tissue stenosis of neural canal of rib cage',
  'M9949 Connective tissue stenosis of neural canal of abdomen and other regions',
  'M9950 Intervertebral disc stenosis of neural canal of head region',
  'M9951 Intervertebral disc stenosis of neural canal of cervical region',
  'M9952 Intervertebral disc stenosis of neural canal of thoracic region',
  'M9953 Intervertebral disc stenosis of neural canal of lumbar region',
  'M9954 Intervertebral disc stenosis of neural canal of sacral region',
  'M9955 Intervertebral disc stenosis of neural canal of pelvic region',
  'M9956 Intervertebral disc stenosis of neural canal of lower extremity',
  'M9957 Intervertebral disc stenosis of neural canal of upper extremity',
  'M9958 Intervertebral disc stenosis of neural canal of rib cage',
  'M9959 Intervertebral disc stenosis of neural canal of abdomen and other regions',
  'M9960 Osseous and subluxation stenosis of intervertebral foramina of head region',
  'M9961 Osseous and subluxation stenosis of intervertebral foramina of cervical region',
  'M9962 Osseous and subluxation stenosis of intervertebral foramina of thoracic region',
  'M9963 Osseous and subluxation stenosis of intervertebral foramina of lumbar region',
  'M9964 Osseous and subluxation stenosis of intervertebral foramina of sacral region',
  'M9965 Osseous and subluxation stenosis of intervertebral foramina of pelvic region',
  'M9966 Osseous and subluxation stenosis of intervertebral foramina of lower extremity',
  'M9967 Osseous and subluxation stenosis of intervertebral foramina of upper extremity',
  'M9968 Osseous and subluxation stenosis of intervertebral foramina of rib cage',
  'M9969 Osseous and subluxation stenosis of intervertebral foramina of abdomen and other regions',
  'M9970 Connective tissue and disc stenosis of intervertebral foramina of head region',
  'M9971 Connective tissue and disc stenosis of intervertebral foramina of cervical region',
  'M9972 Connective tissue and disc stenosis of intervertebral foramina of thoracic region',
  'M9973 Connective tissue and disc stenosis of intervertebral foramina of lumbar region',
  'M9974 Connective tissue and disc stenosis of intervertebral foramina of sacral region',
  'M9975 Connective tissue and disc stenosis of intervertebral foramina of pelvic region',
  'M9976 Connective tissue and disc stenosis of intervertebral foramina of lower extremity',
  'M9977 Connective tissue and disc stenosis of intervertebral foramina of upper extremity',
  'M9978 Connective tissue and disc stenosis of intervertebral foramina of rib cage',
  'M9979 Connective tissue and disc stenosis of intervertebral foramina of abdomen and other regions',
  'M9980 Other biomechanical lesions of head region',
  'M9981 Other biomechanical lesions of cervical region',
  'M9982 Other biomechanical lesions of thoracic region',
  'M9983 Other biomechanical lesions of lumbar region',
  'M9984 Other biomechanical lesions of sacral region',
  'M9985 Other biomechanical lesions of pelvic region',
  'M9986 Other biomechanical lesions of lower extremity',
  'M9987 Other biomechanical lesions of upper extremity',
  'M9988 Other biomechanical lesions of rib cage',
  'M9989 Other biomechanical lesions of abdomen and other regions',
  'M999 Biomechanical lesion, unspecified',
  'N000 Acute nephritic syndrome with minor glomerular abnormality',
  'N001 Acute nephritic syndrome with focal and segmental glomerular lesions',
  'N002 Acute nephritic syndrome with diffuse membranous glomerulonephritis',
  'N003 Acute nephritic syndrome with diffuse mesangial proliferative glomerulonephritis',
  'N004 Acute nephritic syndrome with diffuse endocapillary proliferative glomerulonephritis',
  'N005 Acute nephritic syndrome with diffuse mesangiocapillary glomerulonephritis',
  'N006 Acute nephritic syndrome with dense deposit disease',
  'N007 Acute nephritic syndrome with diffuse crescentic glomerulonephritis',
  'N008 Acute nephritic syndrome with other morphologic changes',
  'N009 Acute nephritic syndrome with unspecified morphologic changes',
  'N010 Rapidly progressive nephritic syndrome with minor glomerular abnormality',
  'N011 Rapidly progressive nephritic syndrome with focal and segmental glomerular lesions',
  'N012 Rapidly progressive nephritic syndrome with diffuse membranous glomerulonephritis',
  'N013 Rapidly progressive nephritic syndrome with diffuse mesangial proliferative glomerulonephritis',
  'N014 Rapidly progressive nephritic syndrome with diffuse endocapillary proliferative',
  'glomerulonephritis',
  'N015 Rapidly progressive nephritic syndrome with diffuse mesangiocapillary glomerulonephritis',
  'N016 Rapidly progressive nephritic syndrome with dense deposit disease',
  'N017 Rapidly progressive nephritic syndrome with diffuse crescentic glomerulonephritis',
  'N018 Rapidly progressive nephritic syndrome with other morphologic changes',
  'N019 Rapidly progressive nephritic syndrome with unspecified morphologic changes',
  'N020 Recurrent and persistent hematuria with minor glomerular abnormality',
  'N021 Recurrent and persistent hematuria with focal and segmental glomerular lesions',
  'N022 Recurrent and persistent hematuria with diffuse membranous glomerulonephritis',
  'N023 Recurrent and persistent hematuria with diffuse mesangial proliferative glomerulonephritis',
  'N024 Recurrent and persistent hematuria with diffuse endocapillary proliferative glomerulonephritis',
  'N025 Recurrent and persistent hematuria with diffuse mesangiocapillary glomerulonephritis',
  'N026 Recurrent and persistent hematuria with dense deposit disease',
  'N027 Recurrent and persistent hematuria with diffuse crescentic glomerulonephritis',
  'N028 Recurrent and persistent hematuria with other morphologic changes',
  'N029 Recurrent and persistent hematuria with unspecified morphologic changes',
  'N030 Chronic nephritic syndrome with minor glomerular abnormality',
  'N031 Chronic nephritic syndrome with focal and segmental glomerular lesions',
  'N032 Chronic nephritic syndrome with diffuse membranous glomerulonephritis',
  'N033 Chronic nephritic syndrome with diffuse mesangial proliferative glomerulonephritis',
  'N034 Chronic nephritic syndrome with diffuse endocapillary proliferative glomerulonephritis',
  'N035 Chronic nephritic syndrome with diffuse mesangiocapillary glomerulonephritis',
  'N036 Chronic nephritic syndrome with dense deposit disease',
  'N037 Chronic nephritic syndrome with diffuse crescentic glomerulonephritis',
  'N038 Chronic nephritic syndrome with other morphologic changes',
  'N039 Chronic nephritic syndrome with unspecified morphologic changes',
  'N040 Nephrotic syndrome with minor glomerular abnormality',
  'N041 Nephrotic syndrome with focal and segmental glomerular lesions',
  'N042 Nephrotic syndrome with diffuse membranous glomerulonephritis',
  'N043 Nephrotic syndrome with diffuse mesangial proliferative glomerulonephritis',
  'N044 Nephrotic syndrome with diffuse endocapillary proliferative glomerulonephritis',
  'N045 Nephrotic syndrome with diffuse mesangiocapillary glomerulonephritis',
  'N046 Nephrotic syndrome with dense deposit disease',
  'N047 Nephrotic syndrome with diffuse crescentic glomerulonephritis',
  'N048 Nephrotic syndrome with other morphologic changes',
  'N049 Nephrotic syndrome with unspecified morphologic changes',
  'N050 Unspecified nephritic syndrome with minor glomerular abnormality',
  'N051 Unspecified nephritic syndrome with focal and segmental glomerular lesions',
  'N052 Unspecified nephritic syndrome with diffuse membranous glomerulonephritis',
  'N053 Unspecified nephritic syndrome with diffuse mesangial proliferative glomerulonephritis',
  'N054 Unspecified nephritic syndrome with diffuse endocapillary proliferative glomerulonephritis',
  'N055 Unspecified nephritic syndrome with diffuse mesangiocapillary glomerulonephritis',
  'N056 Unspecified nephritic syndrome with dense deposit disease',
  'N057 Unspecified nephritic syndrome with diffuse crescentic glomerulonephritis',
  'N058 Unspecified nephritic syndrome with other morphologic changes',
  'N059 Unspecified nephritic syndrome with unspecified morphologic changes',
  'N060 Isolated proteinuria with minor glomerular abnormality',
  'N061 Isolated proteinuria with focal and segmental glomerular lesions',
  'N062 Isolated proteinuria with diffuse membranous glomerulonephritis',
  'N063 Isolated proteinuria with diffuse mesangial proliferative glomerulonephritis',
  'N064 Isolated proteinuria with diffuse endocapillary proliferative glomerulonephritis',
  'N065 Isolated proteinuria with diffuse mesangiocapillary glomerulonephritis',
  'N066 Isolated proteinuria with dense deposit disease',
  'N067 Isolated proteinuria with diffuse crescentic glomerulonephritis',
  'N068 Isolated proteinuria with other morphologic lesion',
  'N069 Isolated proteinuria with unspecified morphologic lesion',
  'N070 Hereditary nephropathy, not elsewhere classified with minor glomerular abnormality',
  'N071 Hereditary nephropathy, not elsewhere classified with focal and segmental glomerular lesions',
  'N072 Hereditary nephropathy, not elsewhere classified with diffuse membranous glomerulonephritis',
  'N073 Hereditary nephropathy, not elsewhere classified with diffuse mesangial proliferative',
  'glomerulonephritis',
  'N074 Hereditary nephropathy, not elsewhere classified with diffuse endocapillary proliferative',
  'glomerulonephritis',
  'N075 Hereditary nephropathy, not elsewhere classified with diffuse mesangiocapillary',
  'glomerulonephritis',
  'N076 Hereditary nephropathy, not elsewhere classified with dense deposit disease',
  'N077 Hereditary nephropathy, not elsewhere classified with diffuse crescentic glomerulonephritis',
  'N078 Hereditary nephropathy, not elsewhere classified with other morphologic lesions',
  'N079 Hereditary nephropathy, not elsewhere classified with unspecified morphologic lesions',
  'N08 Glomerular disorders in diseases classified elsewhere',
  'N10 Acute pyelonephritis',
  'N110 Nonobstructive reflux-associated chronic pyelonephritis',
  'N111 Chronic obstructive pyelonephritis',
  'N118 Other chronic tubulo-interstitial nephritis',
  'N119 Chronic tubulo-interstitial nephritis, unspecified',
  'N12 Tubulo-interstitial nephritis, not specified as acute or chronic',
  'N130 Hydronephrosis with ureteropelvic junction obstruction',
  'N131 Hydronephrosis with ureteral stricture, not elsewhere classified',
  'N132 Hydronephrosis with renal and ureteral calculous obstruction',
  'N1330 Unspecified hydronephrosis',
  'N1339 Other hydronephrosis',
  'N134 Hydroureter',
  'N135 Crossing vessel and stricture of ureter without hydronephrosis',
  'N136 Pyonephrosis',
  'N1370 Vesicoureteral-reflux, unspecified',
  'N1371 Vesicoureteral-reflux without reflux nephropathy',
  'N13721 Vesicoureteral-reflux with reflux nephropathy without hydroureter, unilateral',
  'N13722 Vesicoureteral-reflux with reflux nephropathy without hydroureter, bilateral',
  'N13729 Vesicoureteral-reflux with reflux nephropathy without hydroureter, unspecified',
  'N13731 Vesicoureteral-reflux with reflux nephropathy with hydroureter, unilateral',
  'N13732 Vesicoureteral-reflux with reflux nephropathy with hydroureter, bilateral',
  'N13739 Vesicoureteral-reflux with reflux nephropathy with hydroureter, unspecified',
  'N138 Other obstructive and reflux uropathy',
  'N139 Obstructive and reflux uropathy, unspecified',
  'N140 Analgesic nephropathy',
  'N141 Nephropathy induced by other drugs, medicaments and biological substances',
  'N142 Nephropathy induced by unspecified drug, medicament or biological substance',
  'N143 Nephropathy induced by heavy metals',
  'N144 Toxic nephropathy, not elsewhere classified',
  'N150 Balkan nephropathy',
  'N151 Renal and perinephric abscess',
  'N158 Other specified renal tubulo-interstitial diseases',
  'N159 Renal tubulo-interstitial disease, unspecified',
  'N16 Renal tubulo-interstitial disorders in diseases classified elsewhere',
  'N170 Acute kidney failure with tubular necrosis',
  'N171 Acute kidney failure with acute cortical necrosis',
  'N172 Acute kidney failure with medullary necrosis',
  'N178 Other acute kidney failure',
  'N179 Acute kidney failure, unspecified',
  'N181 Chronic kidney disease, stage 1',
  'N182 Chronic kidney disease, stage 2 (mild)',
  'N183 Chronic kidney disease, stage 3 (moderate)',
  'N184 Chronic kidney disease, stage 4 (severe)',
  'N185 Chronic kidney disease, stage 5',
  'N186 End stage renal disease',
  'N189 Chronic kidney disease, unspecified',
  'N19 Unspecified kidney failure',
  'N200 Calculus of kidney',
  'N201 Calculus of ureter',
  'N202 Calculus of kidney with calculus of ureter',
  'N209 Urinary calculus, unspecified',
  'N210 Calculus in bladder',
  'N211 Calculus in urethra',
  'N218 Other lower urinary tract calculus',
  'N219 Calculus of lower urinary tract, unspecified',
  'N22 Calculus of urinary tract in diseases classified elsewhere',
  'N23 Unspecified renal colic',
  'N250 Renal osteodystrophy',
  'N251 Nephrogenic diabetes insipidus',
  'N2581 Secondary hyperparathyroidism of renal origin',
  'N2589 Other disorders resulting from impaired renal tubular function',
  'N259 Disorder resulting from impaired renal tubular function, unspecified',
  'N261 Atrophy of kidney (terminal)',
  'N262 Page kidney',
  'N269 Renal sclerosis, unspecified',
  'N270 Small kidney, unilateral',
  'N271 Small kidney, bilateral',
  'N279 Small kidney, unspecified',
  'N280 Ischemia and infarction of kidney',
  'N281 Cyst of kidney, acquired',
  'N2881 Hypertrophy of kidney',
  'N2882 Megaloureter',
  'N2883 Nephroptosis',
  'N2884 Pyelitis cystica',
  'N2885 Pyeloureteritis cystica',
  'N2886 Ureteritis cystica',
  'N2889 Other specified disorders of kidney and ureter',
  'N289 Disorder of kidney and ureter, unspecified',
  'N29 Other disorders of kidney and ureter in diseases classified elsewhere',
  'N3000 Acute cystitis without hematuria',
  'N3001 Acute cystitis with hematuria',
  'N3010 Interstitial cystitis (chronic) without hematuria',
  'N3011 Interstitial cystitis (chronic) with hematuria',
  'N3020 Other chronic cystitis without hematuria',
  'N3021 Other chronic cystitis with hematuria',
  'N3030 Trigonitis without hematuria',
  'N3031 Trigonitis with hematuria',
  'N3040 Irradiation cystitis without hematuria',
  'N3041 Irradiation cystitis with hematuria',
  'N3080 Other cystitis without hematuria',
  'N3081 Other cystitis with hematuria',
  'N3090 Cystitis, unspecified without hematuria',
  'N3091 Cystitis, unspecified with hematuria',
  'N310 Uninhibited neuropathic bladder, not elsewhere classified',
  'N311 Reflex neuropathic bladder, not elsewhere classified',
  'N312 Flaccid neuropathic bladder, not elsewhere classified',
  'N318 Other neuromuscular dysfunction of bladder',
  'N319 Neuromuscular dysfunction of bladder, unspecified',
  'N320 Bladder-neck obstruction',
  'N321 Vesicointestinal fistula',
  'N322 Vesical fistula, not elsewhere classified',
  'N323 Diverticulum of bladder',
  'N3281 Overactive bladder',
  'N3289 Other specified disorders of bladder',
  'N329 Bladder disorder, unspecified',
  'N33 Bladder disorders in diseases classified elsewhere',
  'N340 Urethral abscess',
  'N341 Nonspecific urethritis',
  'N342 Other urethritis',
  'N343 Urethral syndrome, unspecified',
  'N35010 Post-traumatic urethral stricture, male, meatal',
  'N35011 Post-traumatic bulbous urethral stricture',
  'N35012 Post-traumatic membranous urethral stricture',
  'N35013 Post-traumatic anterior urethral stricture',
  'N35014 Post-traumatic urethral stricture, male, unspecified',
  'N35021 Urethral stricture due to childbirth',
  'N35028 Other post-traumatic urethral stricture, female',
  'N35111 Postinfective urethral stricture, not elsewhere classified, male, meatal',
  'N35112 Postinfective bulbous urethral stricture, not elsewhere classified',
  'N35113 Postinfective membranous urethral stricture, not elsewhere classified',
  'N35114 Postinfective anterior urethral stricture, not elsewhere classified',
  'N35119 Postinfective urethral stricture, not elsewhere classified, male, unspecified',
  'N3512 Postinfective urethral stricture, not elsewhere classified, female',
  'N358 Other urethral stricture',
  'N359 Urethral stricture, unspecified',
  'N360 Urethral fistula',
  'N361 Urethral diverticulum',
  'N362 Urethral caruncle',
  'N3641 Hypermobility of urethra',
  'N3642 Intrinsic sphincter deficiency (ISD)',
  'N3643 Combined hypermobility of urethra and intrinsic sphincter deficiency',
  'N3644 Muscular disorders of urethra',
  'N365 Urethral false passage',
  'N368 Other specified disorders of urethra',
  'N369 Urethral disorder, unspecified',
  'N37 Urethral disorders in diseases classified elsewhere',
  'N390 Urinary tract infection, site not specified',
  'N393 Stress incontinence (female) (male)',
  'N3941 Urge incontinence',
  'N3942 Incontinence without sensory awareness',
  'N3943 Post-void dribbling',
  'N3944 Nocturnal enuresis',
  'N3945 Continuous leakage',
  'N3946 Mixed incontinence',
  'N39490 Overflow incontinence',
  'N39491 Coital incontinence',
  'N39492 Postural (urinary) incontinence',
  'N39498 Other specified urinary incontinence',
  'N398 Other specified disorders of urinary system',
  'N399 Disorder of urinary system, unspecified',
  'N400 Benign prostatic hyperplasia without lower urinary tract symptoms',
  'N401 Benign prostatic hyperplasia with lower urinary tract symptoms',
  'N402 Nodular prostate without lower urinary tract symptoms',
  'N403 Nodular prostate with lower urinary tract symptoms',
  'N410 Acute prostatitis',
  'N411 Chronic prostatitis',
  'N412 Abscess of prostate',
  'N413 Prostatocystitis',
  'N414 Granulomatous prostatitis',
  'N418 Other inflammatory diseases of prostate',
  'N419 Inflammatory disease of prostate, unspecified',
  'N420 Calculus of prostate',
  'N421 Congestion and hemorrhage of prostate',
  'N4230 Unspecified dysplasia of prostate',
  'N4231 Prostatic intraepithelial neoplasia',
  'N4232 Atypical small acinar proliferation of prostate',
  'N4239 Other dysplasia of prostate',
  'N4281 Prostatodynia syndrome',
  'N4282 Prostatosis syndrome',
  'N4283 Cyst of prostate',
  'N4289 Other specified disorders of prostate',
  'N429 Disorder of prostate, unspecified',
  'N430 Encysted hydrocele',
  'N431 Infected hydrocele',
  'N432 Other hydrocele',
  'N433 Hydrocele, unspecified',
  'N4340 Spermatocele of epididymis, unspecified',
  'N4341 Spermatocele of epididymis, single',
  'N4342 Spermatocele of epididymis, multiple',
  'N4400 Torsion of testis, unspecified',
  'N4401 Extravaginal torsion of spermatic cord',
  'N4402 Intravaginal torsion of spermatic cord',
  'N4403 Torsion of appendix testis',
  'N4404 Torsion of appendix epididymis',
  'N441 Cyst of tunica albuginea testis',
  'N442 Benign cyst of testis',
  'N448 Other noninflammatory disorders of the testis',
  'N451 Epididymitis',
  'N452 Orchitis',
  'N453 Epididymo-orchitis',
  'N454 Abscess of epididymis or testis',
  'N4601 Organic azoospermia',
  'N46021 Azoospermia due to drug therapy',
  'N46022 Azoospermia due to infection',
  'N46023 Azoospermia due to obstruction of efferent ducts',
  'N46024 Azoospermia due to radiation',
  'N46025 Azoospermia due to systemic disease',
  'N46029 Azoospermia due to other extratesticular causes',
  'N4611 Organic oligospermia',
  'N46121 Oligospermia due to drug therapy',
  'N46122 Oligospermia due to infection',
  'N46123 Oligospermia due to obstruction of efferent ducts',
  'N46124 Oligospermia due to radiation',
  'N46125 Oligospermia due to systemic disease',
  'N46129 Oligospermia due to other extratesticular causes',
  'N468 Other male infertility',
  'N469 Male infertility, unspecified',
  'N470 Adherent prepuce, newborn',
  'N471 Phimosis',
  'N472 Paraphimosis',
  'N473 Deficient foreskin',
  'N474 Benign cyst of prepuce',
  'N475 Adhesions of prepuce and glans penis',
  'N476 Balanoposthitis',
  'N477 Other inflammatory diseases of prepuce',
  'N478 Other disorders of prepuce',
  'N480 Leukoplakia of penis',
  'N481 Balanitis',
  'N4821 Abscess of corpus cavernosum and penis',
  'N4822 Cellulitis of corpus cavernosum and penis',
  'N4829 Other inflammatory disorders of penis',
  'N4830 Priapism, unspecified',
  'N4831 Priapism due to trauma',
  'N4832 Priapism due to disease classified elsewhere',
  'N4833 Priapism, drug-induced',
  'N4839 Other priapism',
  'N485 Ulcer of penis',
  'N486 Induration penis plastica',
  'N4881 Thrombosis of superficial vein of penis',
  'N4882 Acquired torsion of penis',
  'N4883 Acquired buried penis',
  'N4889 Other specified disorders of penis',
  'N489 Disorder of penis, unspecified',
  'N490 Inflammatory disorders of seminal vesicle',
  'N491 Inflammatory disorders of spermatic cord, tunica vaginalis and vas deferens',
  'N492 Inflammatory disorders of scrotum',
  'N493 Fournier gangrene',
  'N498 Inflammatory disorders of other specified male genital organs',
  'N499 Inflammatory disorder of unspecified male genital organ',
  'N500 Atrophy of testis',
  'N501 Vascular disorders of male genital organs',
  'N503 Cyst of epididymis',
  'N50811 Right testicular pain',
  'N50812 Left testicular pain',
  'N50819 Testicular pain, unspecified',
  'N5082 Scrotal pain',
  'N5089 Other specified disorders of the male genital organs',
  'N509 Disorder of male genital organs, unspecified',
  'N51 Disorders of male genital organs in diseases classified elsewhere',
  'N5201 Erectile dysfunction due to arterial insufficiency',
  'N5202 Corporo-venous occlusive erectile dysfunction',
  'N5203 Combined arterial insufficiency and corporo-venous occlusive erectile dysfunction',
  'N521 Erectile dysfunction due to diseases classified elsewhere',
  'N522 Drug-induced erectile dysfunction',
  'N5231 Erectile dysfunction following radical prostatectomy',
  'N5232 Erectile dysfunction following radical cystectomy',
  'N5233 Erectile dysfunction following urethral surgery',
  'N5234 Erectile dysfunction following simple prostatectomy',
  'N5235 Erectile dysfunction following radiation therapy',
  'N5236 Erectile dysfunction following interstitial seed therapy',
  'N5237 Erectile dysfunction following prostate ablative therapy',
  'N5239 Other and unspecified postprocedural erectile dysfunction',
  'N528 Other male erectile dysfunction',
  'N529 Male erectile dysfunction, unspecified',
  'N5311 Retarded ejaculation',
  'N5312 Painful ejaculation',
  'N5313 Anejaculatory orgasm',
  'N5314 Retrograde ejaculation',
  'N5319 Other ejaculatory dysfunction',
  'N538 Other male sexual dysfunction',
  'N539 Unspecified male sexual dysfunction',
  'N6001 Solitary cyst of right breast',
  'N6002 Solitary cyst of left breast',
  'N6009 Solitary cyst of unspecified breast',
  'N6011 Diffuse cystic mastopathy of right breast',
  'N6012 Diffuse cystic mastopathy of left breast',
  'N6019 Diffuse cystic mastopathy of unspecified breast',
  'N6021 Fibroadenosis of right breast',
  'N6022 Fibroadenosis of left breast',
  'N6029 Fibroadenosis of unspecified breast',
  'N6031 Fibrosclerosis of right breast',
  'N6032 Fibrosclerosis of left breast',
  'N6039 Fibrosclerosis of unspecified breast',
  'N6041 Mammary duct ectasia of right breast',
  'N6042 Mammary duct ectasia of left breast',
  'N6049 Mammary duct ectasia of unspecified breast',
  'N6081 Other benign mammary dysplasias of right breast',
  'N6082 Other benign mammary dysplasias of left breast',
  'N6089 Other benign mammary dysplasias of unspecified breast',
  'N6091 Unspecified benign mammary dysplasia of right breast',
  'N6092 Unspecified benign mammary dysplasia of left breast',
  'N6099 Unspecified benign mammary dysplasia of unspecified breast',
  'N610 Mastitis without abscess',
  'N611 Abscess of the breast and nipple',
  'N62 Hypertrophy of breast',
  'N63 Unspecified lump in breast',
  'N640 Fissure and fistula of nipple',
  'N641 Fat necrosis of breast',
  'N642 Atrophy of breast',
  'N643 Galactorrhea not associated with childbirth',
  'N644 Mastodynia',
  'N6451 Induration of breast',
  'N6452 Nipple discharge',
  'N6453 Retraction of nipple',
  'N6459 Other signs and symptoms in breast',
  'N6481 Ptosis of breast',
  'N6482 Hypoplasia of breast',
  'N6489 Other specified disorders of breast',
  'N649 Disorder of breast, unspecified',
  'N650 Deformity of reconstructed breast',
  'N651 Disproportion of reconstructed breast',
  'N7001 Acute salpingitis',
  'N7002 Acute oophoritis',
  'N7003 Acute salpingitis and oophoritis',
  'N7011 Chronic salpingitis',
  'N7012 Chronic oophoritis',
  'N7013 Chronic salpingitis and oophoritis',
  'N7091 Salpingitis, unspecified',
  'N7092 Oophoritis, unspecified',
  'N7093 Salpingitis and oophoritis, unspecified',
  'N710 Acute inflammatory disease of uterus',
  'N711 Chronic inflammatory disease of uterus',
  'N719 Inflammatory disease of uterus, unspecified',
  'N72 Inflammatory disease of cervix uteri',
  'N730 Acute parametritis and pelvic cellulitis',
  'N731 Chronic parametritis and pelvic cellulitis',
  'N732 Unspecified parametritis and pelvic cellulitis',
  'N733 Female acute pelvic peritonitis',
  'N734 Female chronic pelvic peritonitis',
  'N735 Female pelvic peritonitis, unspecified',
  'N736 Female pelvic peritoneal adhesions (postinfective)',
  'N738 Other specified female pelvic inflammatory diseases',
  'N739 Female pelvic inflammatory disease, unspecified',
  'N74 Female pelvic inflammatory disorders in diseases classified elsewhere',
  "N750 Cyst of Bartholin's gland",
  "N751 Abscess of Bartholin's gland",
  "N758 Other diseases of Bartholin's gland",
  "N759 Disease of Bartholin's gland, unspecified",
  'N760 Acute vaginitis',
  'N761 Subacute and chronic vaginitis',
  'N762 Acute vulvitis',
  'N763 Subacute and chronic vulvitis',
  'N764 Abscess of vulva',
  'N765 Ulceration of vagina',
  'N766 Ulceration of vulva',
  'N7681 Mucositis (ulcerative) of vagina and vulva',
  'N7689 Other specified inflammation of vagina and vulva',
  'N770 Ulceration of vulva in diseases classified elsewhere',
  'N771 Vaginitis, vulvitis and vulvovaginitis in diseases classified elsewhere',
  'N800 Endometriosis of uterus',
  'N801 Endometriosis of ovary',
  'N802 Endometriosis of fallopian tube',
  'N803 Endometriosis of pelvic peritoneum',
  'N804 Endometriosis of rectovaginal septum and vagina',
  'N805 Endometriosis of intestine',
  'N806 Endometriosis in cutaneous scar',
  'N808 Other endometriosis',
  'N809 Endometriosis, unspecified',
  'N810 Urethrocele',
  'N8110 Cystocele, unspecified',
  'N8111 Cystocele, midline',
  'N8112 Cystocele, lateral',
  'N812 Incomplete uterovaginal prolapse',
  'N813 Complete uterovaginal prolapse',
  'N814 Uterovaginal prolapse, unspecified',
  'N815 Vaginal enterocele',
  'N816 Rectocele',
  'N8181 Perineocele',
  'N8182 Incompetence or weakening of pubocervical tissue',
  'N8183 Incompetence or weakening of rectovaginal tissue',
  'N8184 Pelvic muscle wasting',
  'N8185 Cervical stump prolapse',
  'N8189 Other female genital prolapse',
  'N819 Female genital prolapse, unspecified',
  'N820 Vesicovaginal fistula',
  'N821 Other female urinary-genital tract fistulae',
  'N822 Fistula of vagina to small intestine',
  'N823 Fistula of vagina to large intestine',
  'N824 Other female intestinal-genital tract fistulae',
  'N825 Female genital tract-skin fistulae',
  'N828 Other female genital tract fistulae',
  'N829 Female genital tract fistula, unspecified',
  'N8300 Follicular cyst of ovary, unspecified side',
  'N8301 Follicular cyst of right ovary',
  'N8302 Follicular cyst of left ovary',
  'N8310 Corpus luteum cyst of ovary, unspecified side',
  'N8311 Corpus luteum cyst of right ovary',
  'N8312 Corpus luteum cyst of left ovary',
  'N83201 Unspecified ovarian cyst, right side',
  'N83202 Unspecified ovarian cyst, left side',
  'N83209 Unspecified ovarian cyst, unspecified side',
  'N83291 Other ovarian cyst, right side',
  'N83292 Other ovarian cyst, left side',
  'N83299 Other ovarian cyst, unspecified side',
  'N83311 Acquired atrophy of right ovary',
  'N83312 Acquired atrophy of left ovary',
  'N83319 Acquired atrophy of ovary, unspecified side',
  'N83321 Acquired atrophy of right fallopian tube',
  'N83322 Acquired atrophy of left fallopian tube',
  'N83329 Acquired atrophy of fallopian tube, unspecified side',
  'N83331 Acquired atrophy of right ovary and fallopian tube',
  'N83332 Acquired atrophy of left ovary and fallopian tube',
  'N83339 Acquired atrophy of ovary and fallopian tube, unspecified side',
  'N8340 Prolapse and hernia of ovary and fallopian tube, unspecified side',
  'N8341 Prolapse and hernia of right ovary and fallopian tube',
  'N8342 Prolapse and hernia of left ovary and fallopian tube',
  'N83511 Torsion of right ovary and ovarian pedicle',
  'N83512 Torsion of left ovary and ovarian pedicle',
  'N83519 Torsion of ovary and ovarian pedicle, unspecified side',
  'N83521 Torsion of right fallopian tube',
  'N83522 Torsion of left fallopian tube',
  'N83529 Torsion of fallopian tube, unspecified side',
  'N8353 Torsion of ovary, ovarian pedicle and fallopian tube',
  'N836 Hematosalpinx',
  'N837 Hematoma of broad ligament',
  'N838 Other noninflammatory disorders of ovary, fallopian tube and broad ligament',
  'N839 Noninflammatory disorder of ovary, fallopian tube and broad ligament, unspecified',
  'N840 Polyp of corpus uteri',
  'N841 Polyp of cervix uteri',
  'N842 Polyp of vagina',
  'N843 Polyp of vulva',
  'N848 Polyp of other parts of female genital tract',
  'N849 Polyp of female genital tract, unspecified',
  'N8500 Endometrial hyperplasia, unspecified',
  'N8501 Benign endometrial hyperplasia',
  'N8502 Endometrial intraepithelial neoplasia [EIN]',
  'N852 Hypertrophy of uterus',
  'N853 Subinvolution of uterus',
  'N854 Malposition of uterus',
  'N855 Inversion of uterus',
  'N856 Intrauterine synechiae',
  'N857 Hematometra',
  'N858 Other specified noninflammatory disorders of uterus',
  'N859 Noninflammatory disorder of uterus, unspecified',
  'N86 Erosion and ectropion of cervix uteri',
  'N870 Mild cervical dysplasia',
  'N871 Moderate cervical dysplasia',
  'N879 Dysplasia of cervix uteri, unspecified',
  'N880 Leukoplakia of cervix uteri',
  'N881 Old laceration of cervix uteri',
  'N882 Stricture and stenosis of cervix uteri',
  'N883 Incompetence of cervix uteri',
  'N884 Hypertrophic elongation of cervix uteri',
  'N888 Other specified noninflammatory disorders of cervix uteri',
  'N889 Noninflammatory disorder of cervix uteri, unspecified',
  'N890 Mild vaginal dysplasia',
  'N891 Moderate vaginal dysplasia',
  'N893 Dysplasia of vagina, unspecified',
  'N894 Leukoplakia of vagina',
  'N895 Stricture and atresia of vagina',
  'N896 Tight hymenal ring',
  'N897 Hematocolpos',
  'N898 Other specified noninflammatory disorders of vagina',
  'N899 Noninflammatory disorder of vagina, unspecified',
  'N900 Mild vulvar dysplasia',
  'N901 Moderate vulvar dysplasia',
  'N903 Dysplasia of vulva, unspecified',
  'N904 Leukoplakia of vulva',
  'N905 Atrophy of vulva',
  'N9060 Unspecified hypertrophy of vulva',
  'N9061 Childhood asymmetric labium majus enlargement',
  'N9069 Other specified hypertrophy of vulva',
  'N907 Vulvar cyst',
  'N90810 Female genital mutilation status, unspecified',
  'N90811 Female genital mutilation Type I status',
  'N90812 Female genital mutilation Type II status',
  'N90813 Female genital mutilation Type III status',
  'N90818 Other female genital mutilation status',
  'N9089 Other specified noninflammatory disorders of vulva and perineum',
  'N909 Noninflammatory disorder of vulva and perineum, unspecified',
  'N910 Primary amenorrhea',
  'N911 Secondary amenorrhea',
  'N912 Amenorrhea, unspecified',
  'N913 Primary oligomenorrhea',
  'N914 Secondary oligomenorrhea',
  'N915 Oligomenorrhea, unspecified',
  'N920 Excessive and frequent menstruation with regular cycle',
  'N921 Excessive and frequent menstruation with irregular cycle',
  'N922 Excessive menstruation at puberty',
  'N923 Ovulation bleeding',
  'N924 Excessive bleeding in the premenopausal period',
  'N925 Other specified irregular menstruation',
  'N926 Irregular menstruation, unspecified',
  'N930 Postcoital and contact bleeding',
  'N931 Pre-pubertal vaginal bleeding',
  'N938 Other specified abnormal uterine and vaginal bleeding',
  'N939 Abnormal uterine and vaginal bleeding, unspecified',
  'N940 Mittelschmerz',
  'N9410 Unspecified dyspareunia',
  'N9411 Superficial (introital) dyspareunia',
  'N9412 Deep dyspareunia',
  'N9419 Other specified dyspareunia',
  'N942 Vaginismus',
  'N943 Premenstrual tension syndrome',
  'N944 Primary dysmenorrhea',
  'N945 Secondary dysmenorrhea',
  'N946 Dysmenorrhea, unspecified',
  'N94810 Vulvar vestibulitis',
  'N94818 Other vulvodynia',
  'N94819 Vulvodynia, unspecified',
  'N9489 Other specified conditions associated with female genital organs and menstrual cycle',
  'N949 Unspecified condition associated with female genital organs and menstrual cycle',
  'N950 Postmenopausal bleeding',
  'N951 Menopausal and female climacteric states',
  'N952 Postmenopausal atrophic vaginitis',
  'N958 Other specified menopausal and perimenopausal disorders',
  'N959 Unspecified menopausal and perimenopausal disorder',
  'N96 Recurrent pregnancy loss',
  'N970 Female infertility associated with anovulation',
  'N971 Female infertility of tubal origin',
  'N972 Female infertility of uterine origin',
  'N978 Female infertility of other origin',
  'N979 Female infertility, unspecified',
  'N980 Infection associated with artificial insemination',
  'N981 Hyperstimulation of ovaries',
  'N982 Complications of attempted introduction of fertilized ovum following in vitro fertilization',
  'N983 Complications of attempted introduction of embryo in embryo transfer',
  'N988 Other complications associated with artificial fertilization',
  'N989 Complication associated with artificial fertilization, unspecified',
  'N990 Postprocedural (acute) (chronic) kidney failure',
  'N99110 Postprocedural urethral stricture, male, meatal',
  'N99111 Postprocedural bulbous urethral stricture',
  'N99112 Postprocedural membranous urethral stricture',
  'N99113 Postprocedural anterior bulbous urethral stricture',
  'N99114 Postprocedural urethral stricture, male, unspecified',
  'N99115 Postprocedural fossa navicularis urethral stricture',
  'N9912 Postprocedural urethral stricture, female',
  'N992 Postprocedural adhesions of vagina',
  'N993 Prolapse of vaginal vault after hysterectomy',
  'N994 Postprocedural pelvic peritoneal adhesions',
  'N99510 Cystostomy hemorrhage',
  'N99511 Cystostomy infection',
  'N99512 Cystostomy malfunction',
  'N99518 Other cystostomy complication',
  'N99520 Hemorrhage of incontinent external stoma of urinary tract',
  'N99521 Infection of incontinent external stoma of urinary tract',
  'N99522 Malfunction of incontinent external stoma of urinary tract',
  'N99523 Herniation of incontinent stoma of urinary tract',
  'N99524 Stenosis of incontinent stoma of urinary tract',
  'N99528 Other complication of incontinent external stoma of urinary tract',
  'N99530 Hemorrhage of continent stoma of urinary tract',
  'N99531 Infection of continent stoma of urinary tract',
  'N99532 Malfunction of continent stoma of urinary tract',
  'N99533 Herniation of continent stoma of urinary tract',
  'N99534 Stenosis of continent stoma of urinary tract',
  'N99538 Other complication of continent stoma of urinary tract',
  'N9961 Intraoperative hemorrhage and hematoma of a genitourinary system organ or structure',
  'complicating a genitourinary system procedure',
  'N9962 Intraoperative hemorrhage and hematoma of a genitourinary system organ or structure',
  'complicating other procedure',
  'N9971 Accidental puncture and laceration of a genitourinary system organ or structure during a',
  'genitourinary system procedure',
  'N9972 Accidental puncture and laceration of a genitourinary system organ or structure during other',
  'procedure',
  'N9981 Other intraoperative complications of genitourinary system',
  'N99820 Postprocedural hemorrhage of a genitourinary system organ or structure following a',
  'genitourinary system procedure',
  'N99821 Postprocedural hemorrhage of a genitourinary system organ or structure following other',
  'procedure',
  'N9983 Residual ovary syndrome',
  'N99840 Postprocedural hematoma of a genitourinary system organ or structure following a',
  'genitourinary system procedure',
  'N99841 Postprocedural hematoma of a genitourinary system organ or structure following other',
  'procedure',
  'N99842 Postprocedural seroma of a genitourinary system organ or structure following a genitourinary',
  'system procedure',
  'N99843 Postprocedural seroma of a genitourinary system organ or structure following other procedure',
  'N9989 Other postprocedural complications and disorders of genitourinary system',
  'O0000 Abdominal pregnancy without intrauterine pregnancy',
  'O0001 Abdominal pregnancy with intrauterine pregnancy',
  'O0010 Tubal pregnancy without intrauterine pregnancy',
  'O0011 Tubal pregnancy with intrauterine pregnancy',
  'O0020 Ovarian pregnancy without intrauterine pregnancy',
  'O0021 Ovarian pregnancy with intrauterine pregnancy',
  'O0080 Other ectopic pregnancy without intrauterine pregnancy',
  'O0081 Other ectopic pregnancy with intrauterine pregnancy',
  'O0090 Unspecified ectopic pregnancy without intrauterine pregnancy',
  'O0091 Unspecified ectopic pregnancy with intrauterine pregnancy',
  'O010 Classical hydatidiform mole',
  'O011 Incomplete and partial hydatidiform mole',
  'O019 Hydatidiform mole, unspecified',
  'O020 Blighted ovum and nonhydatidiform mole',
  'O021 Missed abortion',
  'O0281 Inappropriate change in quantitative human chorionic gonadotropin (hCG) in early pregnancy',
  'O0289 Other abnormal products of conception',
  'O029 Abnormal product of conception, unspecified',
  'O030 Genital tract and pelvic infection following incomplete spontaneous abortion',
  'O031 Delayed or excessive hemorrhage following incomplete spontaneous abortion',
  'O032 Embolism following incomplete spontaneous abortion',
  'O0330 Unspecified complication following incomplete spontaneous abortion',
  'O0331 Shock following incomplete spontaneous abortion',
  'O0332 Renal failure following incomplete spontaneous abortion',
  'O0333 Metabolic disorder following incomplete spontaneous abortion',
  'O0334 Damage to pelvic organs following incomplete spontaneous abortion',
  'O0335 Other venous complications following incomplete spontaneous abortion',
  'O0336 Cardiac arrest following incomplete spontaneous abortion',
  'O0337 Sepsis following incomplete spontaneous abortion',
  'O0338 Urinary tract infection following incomplete spontaneous abortion',
  'O0339 Incomplete spontaneous abortion with other complications',
  'O034 Incomplete spontaneous abortion without complication',
  'O035 Genital tract and pelvic infection following complete or unspecified spontaneous abortion',
  'O036 Delayed or excessive hemorrhage following complete or unspecified spontaneous abortion',
  'O037 Embolism following complete or unspecified spontaneous abortion',
  'O0380 Unspecified complication following complete or unspecified spontaneous abortion',
  'O0381 Shock following complete or unspecified spontaneous abortion',
  'O0382 Renal failure following complete or unspecified spontaneous abortion',
  'O0383 Metabolic disorder following complete or unspecified spontaneous abortion',
  'O0384 Damage to pelvic organs following complete or unspecified spontaneous abortion',
  'O0385 Other venous complications following complete or unspecified spontaneous abortion',
  'O0386 Cardiac arrest following complete or unspecified spontaneous abortion',
  'O0387 Sepsis following complete or unspecified spontaneous abortion',
  'O0388 Urinary tract infection following complete or unspecified spontaneous abortion',
  'O0389 Complete or unspecified spontaneous abortion with other complications',
  'O039 Complete or unspecified spontaneous abortion without complication',
  'O045 Genital tract and pelvic infection following (induced) termination of pregnancy',
  'O046 Delayed or excessive hemorrhage following (induced) termination of pregnancy',
  'O047 Embolism following (induced) termination of pregnancy',
  'O0480 (Induced) termination of pregnancy with unspecified complications',
  'O0481 Shock following (induced) termination of pregnancy',
  'O0482 Renal failure following (induced) termination of pregnancy',
  'O0483 Metabolic disorder following (induced) termination of pregnancy',
  'O0484 Damage to pelvic organs following (induced) termination of pregnancy',
  'O0485 Other venous complications following (induced) termination of pregnancy',
  'O0486 Cardiac arrest following (induced) termination of pregnancy',
  'O0487 Sepsis following (induced) termination of pregnancy',
  'O0488 Urinary tract infection following (induced) termination of pregnancy',
  'O0489 (Induced) termination of pregnancy with other complications',
  'O070 Genital tract and pelvic infection following failed attempted termination of pregnancy',
  'O071 Delayed or excessive hemorrhage following failed attempted termination of pregnancy',
  'O072 Embolism following failed attempted termination of pregnancy',
  'O0730 Failed attempted termination of pregnancy with unspecified complications',
  'O0731 Shock following failed attempted termination of pregnancy',
  'O0732 Renal failure following failed attempted termination of pregnancy',
  'O0733 Metabolic disorder following failed attempted termination of pregnancy',
  'O0734 Damage to pelvic organs following failed attempted termination of pregnancy',
  'O0735 Other venous complications following failed attempted termination of pregnancy',
  'O0736 Cardiac arrest following failed attempted termination of pregnancy',
  'O0737 Sepsis following failed attempted termination of pregnancy',
  'O0738 Urinary tract infection following failed attempted termination of pregnancy',
  'O0739 Failed attempted termination of pregnancy with other complications',
  'O074 Failed attempted termination of pregnancy without complication',
  'O080 Genital tract and pelvic infection following ectopic and molar pregnancy',
  'O081 Delayed or excessive hemorrhage following ectopic and molar pregnancy',
  'O082 Embolism following ectopic and molar pregnancy',
  'O083 Shock following ectopic and molar pregnancy',
  'O084 Renal failure following ectopic and molar pregnancy',
  'O085 Metabolic disorders following an ectopic and molar pregnancy',
  'O086 Damage to pelvic organs and tissues following an ectopic and molar pregnancy',
  'O087 Other venous complications following an ectopic and molar pregnancy',
  'O0881 Cardiac arrest following an ectopic and molar pregnancy',
  'O0882 Sepsis following ectopic and molar pregnancy',
  'O0883 Urinary tract infection following an ectopic and molar pregnancy',
  'O0889 Other complications following an ectopic and molar pregnancy',
  'O089 Unspecified complication following an ectopic and molar pregnancy',
  'O0900 Supervision of pregnancy with history of infertility, unspecified trimester',
  'O0901 Supervision of pregnancy with history of infertility, first trimester',
  'O0902 Supervision of pregnancy with history of infertility, second trimester',
  'O0903 Supervision of pregnancy with history of infertility, third trimester',
  'O0910 Supervision of pregnancy with history of ectopic pregnancy, unspecified trimester',
  'O0911 Supervision of pregnancy with history of ectopic pregnancy, first trimester',
  'O0912 Supervision of pregnancy with history of ectopic pregnancy, second trimester',
  'O0913 Supervision of pregnancy with history of ectopic pregnancy, third trimester',
  'O09A0 Supervision of pregnancy with history of molar pregnancy, unspecified trimester',
  'O09A1 Supervision of pregnancy with history of molar pregnancy, first trimester',
  'O09A2 Supervision of pregnancy with history of molar pregnancy, second trimester',
  'O09A3 Supervision of pregnancy with history of molar pregnancy, third trimester',
  'O09211 Supervision of pregnancy with history of pre-term labor, first trimester',
  'O09212 Supervision of pregnancy with history of pre-term labor, second trimester',
  'O09213 Supervision of pregnancy with history of pre-term labor, third trimester',
  'O09219 Supervision of pregnancy with history of pre-term labor, unspecified trimester',
  'O09291 Supervision of pregnancy with other poor reproductive or obstetric history, first trimester',
  'O09292 Supervision of pregnancy with other poor reproductive or obstetric history, second trimester',
  'O09293 Supervision of pregnancy with other poor reproductive or obstetric history, third trimester',
  'O09299 Supervision of pregnancy with other poor reproductive or obstetric history, unspecified',
  'trimester',
  'O0930 Supervision of pregnancy with insufficient antenatal care, unspecified trimester',
  'O0931 Supervision of pregnancy with insufficient antenatal care, first trimester',
  'O0932 Supervision of pregnancy with insufficient antenatal care, second trimester',
  'O0933 Supervision of pregnancy with insufficient antenatal care, third trimester',
  'O0940 Supervision of pregnancy with grand multiparity, unspecified trimester',
  'O0941 Supervision of pregnancy with grand multiparity, first trimester',
  'O0942 Supervision of pregnancy with grand multiparity, second trimester',
  'O0943 Supervision of pregnancy with grand multiparity, third trimester',
  'O09511 Supervision of elderly primigravida, first trimester',
  'O09512 Supervision of elderly primigravida, second trimester',
  'O09513 Supervision of elderly primigravida, third trimester',
  'O09519 Supervision of elderly primigravida, unspecified trimester',
  'O09521 Supervision of elderly multigravida, first trimester',
  'O09522 Supervision of elderly multigravida, second trimester',
  'O09523 Supervision of elderly multigravida, third trimester',
  'O09529 Supervision of elderly multigravida, unspecified trimester',
  'O09611 Supervision of young primigravida, first trimester',
  'O09612 Supervision of young primigravida, second trimester',
  'O09613 Supervision of young primigravida, third trimester',
  'O09619 Supervision of young primigravida, unspecified trimester',
  'O09621 Supervision of young multigravida, first trimester',
  'O09622 Supervision of young multigravida, second trimester',
  'O09623 Supervision of young multigravida, third trimester',
  'O09629 Supervision of young multigravida, unspecified trimester',
  'O0970 Supervision of high risk pregnancy due to social problems, unspecified trimester',
  'O0971 Supervision of high risk pregnancy due to social problems, first trimester',
  'O0972 Supervision of high risk pregnancy due to social problems, second trimester',
  'O0973 Supervision of high risk pregnancy due to social problems, third trimester',
  'O09811 Supervision of pregnancy resulting from assisted reproductive technology, first trimester',
  'O09812 Supervision of pregnancy resulting from assisted reproductive technology, second trimester',
  'O09813 Supervision of pregnancy resulting from assisted reproductive technology, third trimester',
  'O09819 Supervision of pregnancy resulting from assisted reproductive technology, unspecified',
  'trimester',
  'O09821 Supervision of pregnancy with history of in utero procedure during previous pregnancy, first',
  'trimester',
  'O09822 Supervision of pregnancy with history of in utero procedure during previous pregnancy, second',
  'trimester',
  'O09823 Supervision of pregnancy with history of in utero procedure during previous pregnancy, third',
  'trimester',
  'O09829 Supervision of pregnancy with history of in utero procedure during previous pregnancy,',
  'unspecified trimester',
  'O09891 Supervision of other high risk pregnancies, first trimester',
  'O09892 Supervision of other high risk pregnancies, second trimester',
  'O09893 Supervision of other high risk pregnancies, third trimester',
  'O09899 Supervision of other high risk pregnancies, unspecified trimester',
  'O0990 Supervision of high risk pregnancy, unspecified, unspecified trimester',
  'O0991 Supervision of high risk pregnancy, unspecified, first trimester',
  'O0992 Supervision of high risk pregnancy, unspecified, second trimester',
  'O0993 Supervision of high risk pregnancy, unspecified, third trimester',
  'O10011 Pre-existing essential hypertension complicating pregnancy, first trimester',
  'O10012 Pre-existing essential hypertension complicating pregnancy, second trimester',
  'O10013 Pre-existing essential hypertension complicating pregnancy, third trimester',
  'O10019 Pre-existing essential hypertension complicating pregnancy, unspecified trimester',
  'O1002 Pre-existing essential hypertension complicating childbirth',
  'O1003 Pre-existing essential hypertension complicating the puerperium',
  'O10111 Pre-existing hypertensive heart disease complicating pregnancy, first trimester',
  'O10112 Pre-existing hypertensive heart disease complicating pregnancy, second trimester',
  'O10113 Pre-existing hypertensive heart disease complicating pregnancy, third trimester',
  'O10119 Pre-existing hypertensive heart disease complicating pregnancy, unspecified trimester',
  'O1012 Pre-existing hypertensive heart disease complicating childbirth',
  'O1013 Pre-existing hypertensive heart disease complicating the puerperium',
  'O10211 Pre-existing hypertensive chronic kidney disease complicating pregnancy, first trimester',
  'O10212 Pre-existing hypertensive chronic kidney disease complicating pregnancy, second trimester',
  'O10213 Pre-existing hypertensive chronic kidney disease complicating pregnancy, third trimester',
  'O10219 Pre-existing hypertensive chronic kidney disease complicating pregnancy, unspecified trimester',
  'O1022 Pre-existing hypertensive chronic kidney disease complicating childbirth',
  'O1023 Pre-existing hypertensive chronic kidney disease complicating the puerperium',
  'O10311 Pre-existing hypertensive heart and chronic kidney disease complicating pregnancy, first',
  'trimester',
  'O10312 Pre-existing hypertensive heart and chronic kidney disease complicating pregnancy, second',
  'trimester',
  'O10313 Pre-existing hypertensive heart and chronic kidney disease complicating pregnancy, third',
  'trimester',
  'O10319 Pre-existing hypertensive heart and chronic kidney disease complicating pregnancy, unspecified',
  'trimester',
  'O1032 Pre-existing hypertensive heart and chronic kidney disease complicating childbirth',
  'O1033 Pre-existing hypertensive heart and chronic kidney disease complicating the puerperium',
  'O10411 Pre-existing secondary hypertension complicating pregnancy, first trimester',
  'O10412 Pre-existing secondary hypertension complicating pregnancy, second trimester',
  'O10413 Pre-existing secondary hypertension complicating pregnancy, third trimester',
  'O10419 Pre-existing secondary hypertension complicating pregnancy, unspecified trimester',
  'O1042 Pre-existing secondary hypertension complicating childbirth',
  'O1043 Pre-existing secondary hypertension complicating the puerperium',
  'O10911 Unspecified pre-existing hypertension complicating pregnancy, first trimester',
  'O10912 Unspecified pre-existing hypertension complicating pregnancy, second trimester',
  'O10913 Unspecified pre-existing hypertension complicating pregnancy, third trimester',
  'O10919 Unspecified pre-existing hypertension complicating pregnancy, unspecified trimester',
  'O1092 Unspecified pre-existing hypertension complicating childbirth',
  'O1093 Unspecified pre-existing hypertension complicating the puerperium',
  'O111 Pre-existing hypertension with pre-eclampsia, first trimester',
  'O112 Pre-existing hypertension with pre-eclampsia, second trimester',
  'O113 Pre-existing hypertension with pre-eclampsia, third trimester',
  'O114 Pre-existing hypertension with pre-eclampsia, complicating childbirth',
  'O115 Pre-existing hypertension with pre-eclampsia, complicating the puerperium',
  'O119 Pre-existing hypertension with pre-eclampsia, unspecified trimester',
  'O1200 Gestational edema, unspecified trimester',
  'O1201 Gestational edema, first trimester',
  'O1202 Gestational edema, second trimester',
  'O1203 Gestational edema, third trimester',
  'O1204 Gestational edema, complicating childbirth',
  'O1205 Gestational edema, complicating the puerperium',
  'O1210 Gestational proteinuria, unspecified trimester',
  'O1211 Gestational proteinuria, first trimester',
  'O1212 Gestational proteinuria, second trimester',
  'O1213 Gestational proteinuria, third trimester',
  'O1214 Gestational proteinuria, complicating childbirth',
  'O1215 Gestational proteinuria, complicating the puerperium',
  'O1220 Gestational edema with proteinuria, unspecified trimester',
  'O1221 Gestational edema with proteinuria, first trimester',
  'O1222 Gestational edema with proteinuria, second trimester',
  'O1223 Gestational edema with proteinuria, third trimester',
  'O1224 Gestational edema with proteinuria, complicating childbirth',
  'O1225 Gestational edema with proteinuria, complicating the puerperium',
  'O131 Gestational [pregnancy-induced] hypertension without significant proteinuria, first trimester',
  'O132 Gestational [pregnancy-induced] hypertension without significant proteinuria, second trimester',
  'O133 Gestational [pregnancy-induced] hypertension without significant proteinuria, third trimester',
  'O134 Gestational [pregnancy-induced] hypertension without significant proteinuria, complicating',
  'childbirth',
  'O135 Gestational [pregnancy-induced] hypertension without significant proteinuria, complicating the',
  'puerperium',
  'O139 Gestational [pregnancy-induced] hypertension without significant proteinuria, unspecified',
  'trimester',
  'O1400 Mild to moderate pre-eclampsia, unspecified trimester',
  'O1402 Mild to moderate pre-eclampsia, second trimester',
  'O1403 Mild to moderate pre-eclampsia, third trimester',
  'O1404 Mild to moderate pre-eclampsia, complicating childbirth',
  'O1405 Mild to moderate pre-eclampsia, complicating the puerperium',
  'O1410 Severe pre-eclampsia, unspecified trimester',
  'O1412 Severe pre-eclampsia, second trimester',
  'O1413 Severe pre-eclampsia, third trimester',
  'O1414 Severe pre-eclampsia complicating childbirth',
  'O1415 Severe pre-eclampsia, complicating the puerperium',
  'O1420 HELLP syndrome (HELLP), unspecified trimester',
  'O1422 HELLP syndrome (HELLP), second trimester',
  'O1423 HELLP syndrome (HELLP), third trimester',
  'O1424 HELLP syndrome, complicating childbirth',
  'O1425 HELLP syndrome, complicating the puerperium',
  'O1490 Unspecified pre-eclampsia, unspecified trimester',
  'O1492 Unspecified pre-eclampsia, second trimester',
  'O1493 Unspecified pre-eclampsia, third trimester',
  'O1494 Unspecified pre-eclampsia, complicating childbirth',
  'O1495 Unspecified pre-eclampsia, complicating the puerperium',
  'O1500 Eclampsia complicating pregnancy, unspecified trimester',
  'O1502 Eclampsia complicating pregnancy, second trimester',
  'O1503 Eclampsia complicating pregnancy, third trimester',
  'O151 Eclampsia complicating labor',
  'O152 Eclampsia complicating the puerperium',
  'O159 Eclampsia, unspecified as to time period',
  'O161 Unspecified maternal hypertension, first trimester',
  'O162 Unspecified maternal hypertension, second trimester',
  'O163 Unspecified maternal hypertension, third trimester',
  'O164 Unspecified maternal hypertension, complicating childbirth',
  'O165 Unspecified maternal hypertension, complicating the puerperium',
  'O169 Unspecified maternal hypertension, unspecified trimester',
  'O200 Threatened abortion',
  'O208 Other hemorrhage in early pregnancy',
  'O209 Hemorrhage in early pregnancy, unspecified',
  'O210 Mild hyperemesis gravidarum',
  'O211 Hyperemesis gravidarum with metabolic disturbance',
  'O212 Late vomiting of pregnancy',
  'O218 Other vomiting complicating pregnancy',
  'O219 Vomiting of pregnancy, unspecified',
  'O2200 Varicose veins of lower extremity in pregnancy, unspecified trimester',
  'O2201 Varicose veins of lower extremity in pregnancy, first trimester',
  'O2202 Varicose veins of lower extremity in pregnancy, second trimester',
  'O2203 Varicose veins of lower extremity in pregnancy, third trimester',
  'O2210 Genital varices in pregnancy, unspecified trimester',
  'O2211 Genital varices in pregnancy, first trimester',
  'O2212 Genital varices in pregnancy, second trimester',
  'O2213 Genital varices in pregnancy, third trimester',
  'O2220 Superficial thrombophlebitis in pregnancy, unspecified trimester',
  'O2221 Superficial thrombophlebitis in pregnancy, first trimester',
  'O2222 Superficial thrombophlebitis in pregnancy, second trimester',
  'O2223 Superficial thrombophlebitis in pregnancy, third trimester',
  'O2230 Deep phlebothrombosis in pregnancy, unspecified trimester',
  'O2231 Deep phlebothrombosis in pregnancy, first trimester',
  'O2232 Deep phlebothrombosis in pregnancy, second trimester',
  'O2233 Deep phlebothrombosis in pregnancy, third trimester',
  'O2240 Hemorrhoids in pregnancy, unspecified trimester',
  'O2241 Hemorrhoids in pregnancy, first trimester',
  'O2242 Hemorrhoids in pregnancy, second trimester',
  'O2243 Hemorrhoids in pregnancy, third trimester',
  'O2250 Cerebral venous thrombosis in pregnancy, unspecified trimester',
  'O2251 Cerebral venous thrombosis in pregnancy, first trimester',
  'O2252 Cerebral venous thrombosis in pregnancy, second trimester',
  'O2253 Cerebral venous thrombosis in pregnancy, third trimester',
  'O228X1 Other venous complications in pregnancy, first trimester',
  'O228X2 Other venous complications in pregnancy, second trimester',
  'O228X3 Other venous complications in pregnancy, third trimester',
  'O228X9 Other venous complications in pregnancy, unspecified trimester',
  'O2290 Venous complication in pregnancy, unspecified, unspecified trimester',
  'O2291 Venous complication in pregnancy, unspecified, first trimester',
  'O2292 Venous complication in pregnancy, unspecified, second trimester',
  'O2293 Venous complication in pregnancy, unspecified, third trimester',
  'O2300 Infections of kidney in pregnancy, unspecified trimester',
  'O2301 Infections of kidney in pregnancy, first trimester',
  'O2302 Infections of kidney in pregnancy, second trimester',
  'O2303 Infections of kidney in pregnancy, third trimester',
  'O2310 Infections of bladder in pregnancy, unspecified trimester',
  'O2311 Infections of bladder in pregnancy, first trimester',
  'O2312 Infections of bladder in pregnancy, second trimester',
  'O2313 Infections of bladder in pregnancy, third trimester',
  'O2320 Infections of urethra in pregnancy, unspecified trimester',
  'O2321 Infections of urethra in pregnancy, first trimester',
  'O2322 Infections of urethra in pregnancy, second trimester',
  'O2323 Infections of urethra in pregnancy, third trimester',
  'O2330 Infections of other parts of urinary tract in pregnancy, unspecified trimester',
  'O2331 Infections of other parts of urinary tract in pregnancy, first trimester',
  'O2332 Infections of other parts of urinary tract in pregnancy, second trimester',
  'O2333 Infections of other parts of urinary tract in pregnancy, third trimester',
  'O2340 Unspecified infection of urinary tract in pregnancy, unspecified trimester',
  'O2341 Unspecified infection of urinary tract in pregnancy, first trimester',
  'O2342 Unspecified infection of urinary tract in pregnancy, second trimester',
  'O2343 Unspecified infection of urinary tract in pregnancy, third trimester',
  'O23511 Infections of cervix in pregnancy, first trimester',
  'O23512 Infections of cervix in pregnancy, second trimester',
  'O23513 Infections of cervix in pregnancy, third trimester',
  'O23519 Infections of cervix in pregnancy, unspecified trimester',
  'O23521 Salpingo-oophoritis in pregnancy, first trimester',
  'O23522 Salpingo-oophoritis in pregnancy, second trimester',
  'O23523 Salpingo-oophoritis in pregnancy, third trimester',
  'O23529 Salpingo-oophoritis in pregnancy, unspecified trimester',
  'O23591 Infection of other part of genital tract in pregnancy, first trimester',
  'O23592 Infection of other part of genital tract in pregnancy, second trimester',
  'O23593 Infection of other part of genital tract in pregnancy, third trimester',
  'O23599 Infection of other part of genital tract in pregnancy, unspecified trimester',
  'O2390 Unspecified genitourinary tract infection in pregnancy, unspecified trimester',
  'O2391 Unspecified genitourinary tract infection in pregnancy, first trimester',
  'O2392 Unspecified genitourinary tract infection in pregnancy, second trimester',
  'O2393 Unspecified genitourinary tract infection in pregnancy, third trimester',
  'O24011 Pre-existing type 1 diabetes mellitus, in pregnancy, first trimester',
  'O24012 Pre-existing type 1 diabetes mellitus, in pregnancy, second trimester',
  'O24013 Pre-existing type 1 diabetes mellitus, in pregnancy, third trimester',
  'O24019 Pre-existing type 1 diabetes mellitus, in pregnancy, unspecified trimester',
  'O2402 Pre-existing type 1 diabetes mellitus, in childbirth',
  'O2403 Pre-existing type 1 diabetes mellitus, in the puerperium',
  'O24111 Pre-existing type 2 diabetes mellitus, in pregnancy, first trimester',
  'O24112 Pre-existing type 2 diabetes mellitus, in pregnancy, second trimester',
  'O24113 Pre-existing type 2 diabetes mellitus, in pregnancy, third trimester',
  'O24119 Pre-existing type 2 diabetes mellitus, in pregnancy, unspecified trimester',
  'O2412 Pre-existing type 2 diabetes mellitus, in childbirth',
  'O2413 Pre-existing type 2 diabetes mellitus, in the puerperium',
  'O24311 Unspecified pre-existing diabetes mellitus in pregnancy, first trimester',
  'O24312 Unspecified pre-existing diabetes mellitus in pregnancy, second trimester',
  'O24313 Unspecified pre-existing diabetes mellitus in pregnancy, third trimester',
  'O24319 Unspecified pre-existing diabetes mellitus in pregnancy, unspecified trimester',
  'O2432 Unspecified pre-existing diabetes mellitus in childbirth',
  'O2433 Unspecified pre-existing diabetes mellitus in the puerperium',
  'O24410 Gestational diabetes mellitus in pregnancy, diet controlled',
  'O24414 Gestational diabetes mellitus in pregnancy, insulin controlled',
  'O24415 Gestational diabetes mellitus in pregnancy, controlled by oral hypoglycemic drugs',
  'O24419 Gestational diabetes mellitus in pregnancy, unspecified control',
  'O24420 Gestational diabetes mellitus in childbirth, diet controlled',
  'O24424 Gestational diabetes mellitus in childbirth, insulin controlled',
  'O24425 Gestational diabetes mellitus in childbirth, controlled by oral hypoglycemic drugs',
  'O24429 Gestational diabetes mellitus in childbirth, unspecified control',
  'O24430 Gestational diabetes mellitus in the puerperium, diet controlled',
  'O24434 Gestational diabetes mellitus in the puerperium, insulin controlled',
  'O24435 Gestational diabetes mellitus in puerperium, controlled by oral hypoglycemic drugs',
  'O24439 Gestational diabetes mellitus in the puerperium, unspecified control',
  'O24811 Other pre-existing diabetes mellitus in pregnancy, first trimester',
  'O24812 Other pre-existing diabetes mellitus in pregnancy, second trimester',
  'O24813 Other pre-existing diabetes mellitus in pregnancy, third trimester',
  'O24819 Other pre-existing diabetes mellitus in pregnancy, unspecified trimester',
  'O2482 Other pre-existing diabetes mellitus in childbirth',
  'O2483 Other pre-existing diabetes mellitus in the puerperium',
  'O24911 Unspecified diabetes mellitus in pregnancy, first trimester',
  'O24912 Unspecified diabetes mellitus in pregnancy, second trimester',
  'O24913 Unspecified diabetes mellitus in pregnancy, third trimester',
  'O24919 Unspecified diabetes mellitus in pregnancy, unspecified trimester',
  'O2492 Unspecified diabetes mellitus in childbirth',
  'O2493 Unspecified diabetes mellitus in the puerperium',
  'O2510 Malnutrition in pregnancy, unspecified trimester',
  'O2511 Malnutrition in pregnancy, first trimester',
  'O2512 Malnutrition in pregnancy, second trimester',
  'O2513 Malnutrition in pregnancy, third trimester',
  'O252 Malnutrition in childbirth',
  'O253 Malnutrition in the puerperium',
  'O2600 Excessive weight gain in pregnancy, unspecified trimester',
  'O2601 Excessive weight gain in pregnancy, first trimester',
  'O2602 Excessive weight gain in pregnancy, second trimester',
  'O2603 Excessive weight gain in pregnancy, third trimester',
  'O2610 Low weight gain in pregnancy, unspecified trimester',
  'O2611 Low weight gain in pregnancy, first trimester',
  'O2612 Low weight gain in pregnancy, second trimester',
  'O2613 Low weight gain in pregnancy, third trimester',
  'O2620 Pregnancy care for patient with recurrent pregnancy loss, unspecified trimester',
  'O2621 Pregnancy care for patient with recurrent pregnancy loss, first trimester',
  'O2622 Pregnancy care for patient with recurrent pregnancy loss, second trimester',
  'O2623 Pregnancy care for patient with recurrent pregnancy loss, third trimester',
  'O2630 Retained intrauterine contraceptive device in pregnancy, unspecified trimester',
  'O2631 Retained intrauterine contraceptive device in pregnancy, first trimester',
  'O2632 Retained intrauterine contraceptive device in pregnancy, second trimester',
  'O2633 Retained intrauterine contraceptive device in pregnancy, third trimester',
  'O2640 Herpes gestationis, unspecified trimester',
  'O2641 Herpes gestationis, first trimester',
  'O2642 Herpes gestationis, second trimester',
  'O2643 Herpes gestationis, third trimester',
  'O2650 Maternal hypotension syndrome, unspecified trimester',
  'O2651 Maternal hypotension syndrome, first trimester',
  'O2652 Maternal hypotension syndrome, second trimester',
  'O2653 Maternal hypotension syndrome, third trimester',
  'O26611 Liver and biliary tract disorders in pregnancy, first trimester',
  'O26612 Liver and biliary tract disorders in pregnancy, second trimester',
  'O26613 Liver and biliary tract disorders in pregnancy, third trimester',
  'O26619 Liver and biliary tract disorders in pregnancy, unspecified trimester',
  'O2662 Liver and biliary tract disorders in childbirth',
  'O2663 Liver and biliary tract disorders in the puerperium',
  'O26711 Subluxation of symphysis (pubis) in pregnancy, first trimester',
  'O26712 Subluxation of symphysis (pubis) in pregnancy, second trimester',
  'O26713 Subluxation of symphysis (pubis) in pregnancy, third trimester',
  'O26719 Subluxation of symphysis (pubis) in pregnancy, unspecified trimester',
  'O2672 Subluxation of symphysis (pubis) in childbirth',
  'O2673 Subluxation of symphysis (pubis) in the puerperium',
  'O26811 Pregnancy related exhaustion and fatigue, first trimester',
  'O26812 Pregnancy related exhaustion and fatigue, second trimester',
  'O26813 Pregnancy related exhaustion and fatigue, third trimester',
  'O26819 Pregnancy related exhaustion and fatigue, unspecified trimester',
  'O26821 Pregnancy related peripheral neuritis, first trimester',
  'O26822 Pregnancy related peripheral neuritis, second trimester',
  'O26823 Pregnancy related peripheral neuritis, third trimester',
  'O26829 Pregnancy related peripheral neuritis, unspecified trimester',
  'O26831 Pregnancy related renal disease, first trimester',
  'O26832 Pregnancy related renal disease, second trimester',
  'O26833 Pregnancy related renal disease, third trimester',
  'O26839 Pregnancy related renal disease, unspecified trimester',
  'O26841 Uterine size-date discrepancy, first trimester',
  'O26842 Uterine size-date discrepancy, second trimester',
  'O26843 Uterine size-date discrepancy, third trimester',
  'O26849 Uterine size-date discrepancy, unspecified trimester',
  'O26851 Spotting complicating pregnancy, first trimester',
  'O26852 Spotting complicating pregnancy, second trimester',
  'O26853 Spotting complicating pregnancy, third trimester',
  'O26859 Spotting complicating pregnancy, unspecified trimester',
  'O2686 Pruritic urticarial papules and plaques of pregnancy (PUPPP)',
  'O26872 Cervical shortening, second trimester',
  'O26873 Cervical shortening, third trimester',
  'O26879 Cervical shortening, unspecified trimester',
  'O26891 Other specified pregnancy related conditions, first trimester',
  'O26892 Other specified pregnancy related conditions, second trimester',
  'O26893 Other specified pregnancy related conditions, third trimester',
  'O26899 Other specified pregnancy related conditions, unspecified trimester',
  'O2690 Pregnancy related conditions, unspecified, unspecified trimester',
  'O2691 Pregnancy related conditions, unspecified, first trimester',
  'O2692 Pregnancy related conditions, unspecified, second trimester',
  'O2693 Pregnancy related conditions, unspecified, third trimester',
  'O280 Abnormal hematological finding on antenatal screening of mother',
  'O281 Abnormal biochemical finding on antenatal screening of mother',
  'O282 Abnormal cytological finding on antenatal screening of mother',
  'O283 Abnormal ultrasonic finding on antenatal screening of mother',
  'O284 Abnormal radiological finding on antenatal screening of mother',
  'O285 Abnormal chromosomal and genetic finding on antenatal screening of mother',
  'O288 Other abnormal findings on antenatal screening of mother',
  'O289 Unspecified abnormal findings on antenatal screening of mother',
  'O29011 Aspiration pneumonitis due to anesthesia during pregnancy, first trimester',
  'O29012 Aspiration pneumonitis due to anesthesia during pregnancy, second trimester',
  'O29013 Aspiration pneumonitis due to anesthesia during pregnancy, third trimester',
  'O29019 Aspiration pneumonitis due to anesthesia during pregnancy, unspecified trimester',
  'O29021 Pressure collapse of lung due to anesthesia during pregnancy, first trimester',
  'O29022 Pressure collapse of lung due to anesthesia during pregnancy, second trimester',
  'O29023 Pressure collapse of lung due to anesthesia during pregnancy, third trimester',
  'O29029 Pressure collapse of lung due to anesthesia during pregnancy, unspecified trimester',
  'O29091 Other pulmonary complications of anesthesia during pregnancy, first trimester',
  'O29092 Other pulmonary complications of anesthesia during pregnancy, second trimester',
  'O29093 Other pulmonary complications of anesthesia during pregnancy, third trimester',
  'O29099 Other pulmonary complications of anesthesia during pregnancy, unspecified trimester',
  'O29111 Cardiac arrest due to anesthesia during pregnancy, first trimester',
  'O29112 Cardiac arrest due to anesthesia during pregnancy, second trimester',
  'O29113 Cardiac arrest due to anesthesia during pregnancy, third trimester',
  'O29119 Cardiac arrest due to anesthesia during pregnancy, unspecified trimester',
  'O29121 Cardiac failure due to anesthesia during pregnancy, first trimester',
  'O29122 Cardiac failure due to anesthesia during pregnancy, second trimester',
  'O29123 Cardiac failure due to anesthesia during pregnancy, third trimester',
  'O29129 Cardiac failure due to anesthesia during pregnancy, unspecified trimester',
  'O29191 Other cardiac complications of anesthesia during pregnancy, first trimester',
  'O29192 Other cardiac complications of anesthesia during pregnancy, second trimester',
  'O29193 Other cardiac complications of anesthesia during pregnancy, third trimester',
  'O29199 Other cardiac complications of anesthesia during pregnancy, unspecified trimester',
  'O29211 Cerebral anoxia due to anesthesia during pregnancy, first trimester',
  'O29212 Cerebral anoxia due to anesthesia during pregnancy, second trimester',
  'O29213 Cerebral anoxia due to anesthesia during pregnancy, third trimester',
  'O29219 Cerebral anoxia due to anesthesia during pregnancy, unspecified trimester',
  'O29291 Other central nervous system complications of anesthesia during pregnancy, first trimester',
  'O29292 Other central nervous system complications of anesthesia during pregnancy, second trimester',
  'O29293 Other central nervous system complications of anesthesia during pregnancy, third trimester',
  'O29299 Other central nervous system complications of anesthesia during pregnancy, unspecified',
  'trimester',
  'O293X1 Toxic reaction to local anesthesia during pregnancy, first trimester',
  'O293X2 Toxic reaction to local anesthesia during pregnancy, second trimester',
  'O293X3 Toxic reaction to local anesthesia during pregnancy, third trimester',
  'O293X9 Toxic reaction to local anesthesia during pregnancy, unspecified trimester',
  'O2940 Spinal and epidural anesthesia induced headache during pregnancy, unspecified trimester',
  'O2941 Spinal and epidural anesthesia induced headache during pregnancy, first trimester',
  'O2942 Spinal and epidural anesthesia induced headache during pregnancy, second trimester',
  'O2943 Spinal and epidural anesthesia induced headache during pregnancy, third trimester',
  'O295X1 Other complications of spinal and epidural anesthesia during pregnancy, first trimester',
  'O295X2 Other complications of spinal and epidural anesthesia during pregnancy, second trimester',
  'O295X3 Other complications of spinal and epidural anesthesia during pregnancy, third trimester',
  'O295X9 Other complications of spinal and epidural anesthesia during pregnancy, unspecified trimester',
  'O2960 Failed or difficult intubation for anesthesia during pregnancy, unspecified trimester',
  'O2961 Failed or difficult intubation for anesthesia during pregnancy, first trimester',
  'O2962 Failed or difficult intubation for anesthesia during pregnancy, second trimester',
  'O2963 Failed or difficult intubation for anesthesia during pregnancy, third trimester',
  'O298X1 Other complications of anesthesia during pregnancy, first trimester',
  'O298X2 Other complications of anesthesia during pregnancy, second trimester',
  'O298X3 Other complications of anesthesia during pregnancy, third trimester',
  'O298X9 Other complications of anesthesia during pregnancy, unspecified trimester',
  'O2990 Unspecified complication of anesthesia during pregnancy, unspecified trimester',
  'O2991 Unspecified complication of anesthesia during pregnancy, first trimester',
  'O2992 Unspecified complication of anesthesia during pregnancy, second trimester',
  'O2993 Unspecified complication of anesthesia during pregnancy, third trimester',
  'O30001 Twin pregnancy, unspecified number of placenta and unspecified number of amniotic sacs, first',
  'trimester',
  'O30002 Twin pregnancy, unspecified number of placenta and unspecified number of amniotic sacs,',
  'second trimester',
  'O30003 Twin pregnancy, unspecified number of placenta and unspecified number of amniotic sacs,',
  'third trimester',
  'O30009 Twin pregnancy, unspecified number of placenta and unspecified number of amniotic sacs,',
  'unspecified trimester',
  'O30011 Twin pregnancy, monochorionic/monoamniotic, first trimester',
  'O30012 Twin pregnancy, monochorionic/monoamniotic, second trimester',
  'O30013 Twin pregnancy, monochorionic/monoamniotic, third trimester',
  'O30019 Twin pregnancy, monochorionic/monoamniotic, unspecified trimester',
  'O30021 Conjoined twin pregnancy, first trimester',
  'O30022 Conjoined twin pregnancy, second trimester',
  'O30023 Conjoined twin pregnancy, third trimester',
  'O30029 Conjoined twin pregnancy, unspecified trimester',
  'O30031 Twin pregnancy, monochorionic/diamniotic, first trimester',
  'O30032 Twin pregnancy, monochorionic/diamniotic, second trimester',
  'O30033 Twin pregnancy, monochorionic/diamniotic, third trimester',
  'O30039 Twin pregnancy, monochorionic/diamniotic, unspecified trimester',
  'O30041 Twin pregnancy, dichorionic/diamniotic, first trimester',
  'O30042 Twin pregnancy, dichorionic/diamniotic, second trimester',
  'O30043 Twin pregnancy, dichorionic/diamniotic, third trimester',
  'O30049 Twin pregnancy, dichorionic/diamniotic, unspecified trimester',
  'O30091 Twin pregnancy, unable to determine number of placenta and number of amniotic sacs, first',
  'trimester',
  'O30092 Twin pregnancy, unable to determine number of placenta and number of amniotic sacs, second',
  'trimester',
  'O30093 Twin pregnancy, unable to determine number of placenta and number of amniotic sacs, third',
  'trimester',
  'O30099 Twin pregnancy, unable to determine number of placenta and number of amniotic sacs,',
  'unspecified trimester',
  'O30101 Triplet pregnancy, unspecified number of placenta and unspecified number of amniotic sacs,',
  'first trimester',
  'O30102 Triplet pregnancy, unspecified number of placenta and unspecified number of amniotic sacs,',
  'second trimester',
  'O30103 Triplet pregnancy, unspecified number of placenta and unspecified number of amniotic sacs,',
  'third trimester',
  'O30109 Triplet pregnancy, unspecified number of placenta and unspecified number of amniotic sacs,',
  'unspecified trimester',
  'O30111 Triplet pregnancy with two or more monochorionic fetuses, first trimester',
  'O30112 Triplet pregnancy with two or more monochorionic fetuses, second trimester',
  'O30113 Triplet pregnancy with two or more monochorionic fetuses, third trimester',
  'O30119 Triplet pregnancy with two or more monochorionic fetuses, unspecified trimester',
  'O30121 Triplet pregnancy with two or more monoamniotic fetuses, first trimester',
  'O30122 Triplet pregnancy with two or more monoamniotic fetuses, second trimester',
  'O30123 Triplet pregnancy with two or more monoamniotic fetuses, third trimester',
  'O30129 Triplet pregnancy with two or more monoamniotic fetuses, unspecified trimester',
  'O30191 Triplet pregnancy, unable to determine number of placenta and number of amniotic sacs, first',
  'trimester',
  'O30192 Triplet pregnancy, unable to determine number of placenta and number of amniotic sacs,',
  'second trimester',
  'O30193 Triplet pregnancy, unable to determine number of placenta and number of amniotic sacs, third',
  'trimester',
  'O30199 Triplet pregnancy, unable to determine number of placenta and number of amniotic sacs,',
  'unspecified trimester',
  'O30201 Quadruplet pregnancy, unspecified number of placenta and unspecified number of amniotic',
  'sacs, first trimester',
  'O30202 Quadruplet pregnancy, unspecified number of placenta and unspecified number of amniotic',
  'sacs, second trimester',
  'O30203 Quadruplet pregnancy, unspecified number of placenta and unspecified number of amniotic',
  'sacs, third trimester',
  'O30209 Quadruplet pregnancy, unspecified number of placenta and unspecified number of amniotic',
  'sacs, unspecified trimester',
  'O30211 Quadruplet pregnancy with two or more monochorionic fetuses, first trimester',
  'O30212 Quadruplet pregnancy with two or more monochorionic fetuses, second trimester',
  'O30213 Quadruplet pregnancy with two or more monochorionic fetuses, third trimester',
  'O30219 Quadruplet pregnancy with two or more monochorionic fetuses, unspecified trimester',
  'O30221 Quadruplet pregnancy with two or more monoamniotic fetuses, first trimester',
  'O30222 Quadruplet pregnancy with two or more monoamniotic fetuses, second trimester',
  'O30223 Quadruplet pregnancy with two or more monoamniotic fetuses, third trimester',
  'O30229 Quadruplet pregnancy with two or more monoamniotic fetuses, unspecified trimester',
  'O30291 Quadruplet pregnancy, unable to determine number of placenta and number of amniotic sacs,',
  'first trimester',
  'O30292 Quadruplet pregnancy, unable to determine number of placenta and number of amniotic sacs,',
  'second trimester',
  'O30293 Quadruplet pregnancy, unable to determine number of placenta and number of amniotic sacs,',
  'third trimester',
  'O30299 Quadruplet pregnancy, unable to determine number of placenta and number of amniotic sacs,',
  'unspecified trimester',
  'O30801 Other specified multiple gestation, unspecified number of placenta and unspecified number of',
  'amniotic sacs, first trimester',
  'O30802 Other specified multiple gestation, unspecified number of placenta and unspecified number of',
  'amniotic sacs, second trimester',
  'O30803 Other specified multiple gestation, unspecified number of placenta and unspecified number of',
  'amniotic sacs, third trimester',
  'O30809 Other specified multiple gestation, unspecified number of placenta and unspecified number of',
  'amniotic sacs, unspecified trimester',
  'O30811 Other specified multiple gestation with two or more monochorionic fetuses, first trimester',
  'O30812 Other specified multiple gestation with two or more monochorionic fetuses, second trimester',
  'O30813 Other specified multiple gestation with two or more monochorionic fetuses, third trimester',
  'O30819 Other specified multiple gestation with two or more monochorionic fetuses, unspecified',
  'trimester',
  'O30821 Other specified multiple gestation with two or more monoamniotic fetuses, first trimester',
  'O30822 Other specified multiple gestation with two or more monoamniotic fetuses, second trimester',
  'O30823 Other specified multiple gestation with two or more monoamniotic fetuses, third trimester',
  'O30829 Other specified multiple gestation with two or more monoamniotic fetuses, unspecified',
  'trimester',
  'O30891 Other specified multiple gestation, unable to determine number of placenta and number of',
  'amniotic sacs, first trimester',
  'O30892 Other specified multiple gestation, unable to determine number of placenta and number of',
  'amniotic sacs, second trimester',
  'O30893 Other specified multiple gestation, unable to determine number of placenta and number of',
  'amniotic sacs, third trimester',
  'O30899 Other specified multiple gestation, unable to determine number of placenta and number of',
  'amniotic sacs, unspecified trimester',
  'O3090 Multiple gestation, unspecified, unspecified trimester',
  'O3091 Multiple gestation, unspecified, first trimester',
  'O3092 Multiple gestation, unspecified, second trimester',
  'O3093 Multiple gestation, unspecified, third trimester',
  'O3100X0 Papyraceous fetus, unspecified trimester, not applicable or unspecified',
  'O3100X1 Papyraceous fetus, unspecified trimester, fetus 1',
  'O3100X2 Papyraceous fetus, unspecified trimester, fetus 2',
  'O3100X3 Papyraceous fetus, unspecified trimester, fetus 3',
  'O3100X4 Papyraceous fetus, unspecified trimester, fetus 4',
  'O3100X5 Papyraceous fetus, unspecified trimester, fetus 5',
  'O3100X9 Papyraceous fetus, unspecified trimester, other fetus',
  'O3101X0 Papyraceous fetus, first trimester, not applicable or unspecified',
  'O3101X1 Papyraceous fetus, first trimester, fetus 1',
  'O3101X2 Papyraceous fetus, first trimester, fetus 2',
  'O3101X3 Papyraceous fetus, first trimester, fetus 3',
  'O3101X4 Papyraceous fetus, first trimester, fetus 4',
  'O3101X5 Papyraceous fetus, first trimester, fetus 5',
  'O3101X9 Papyraceous fetus, first trimester, other fetus',
  'O3102X0 Papyraceous fetus, second trimester, not applicable or unspecified',
  'O3102X1 Papyraceous fetus, second trimester, fetus 1',
  'O3102X2 Papyraceous fetus, second trimester, fetus 2',
  'O3102X3 Papyraceous fetus, second trimester, fetus 3',
  'O3102X4 Papyraceous fetus, second trimester, fetus 4',
  'O3102X5 Papyraceous fetus, second trimester, fetus 5',
  'O3102X9 Papyraceous fetus, second trimester, other fetus',
  'O3103X0 Papyraceous fetus, third trimester, not applicable or unspecified',
  'O3103X1 Papyraceous fetus, third trimester, fetus 1',
  'O3103X2 Papyraceous fetus, third trimester, fetus 2',
  'O3103X3 Papyraceous fetus, third trimester, fetus 3',
  'O3103X4 Papyraceous fetus, third trimester, fetus 4',
  'O3103X5 Papyraceous fetus, third trimester, fetus 5',
  'O3103X9 Papyraceous fetus, third trimester, other fetus',
  'O3110X0 Continuing pregnancy after spontaneous abortion of one fetus or more, unspecified trimester,',
  'not applicable or unspecified',
  'O3110X1 Continuing pregnancy after spontaneous abortion of one fetus or more, unspecified trimester,',
  'fetus 1',
  'O3110X2 Continuing pregnancy after spontaneous abortion of one fetus or more, unspecified trimester,',
  'fetus 2',
  'O3110X3 Continuing pregnancy after spontaneous abortion of one fetus or more, unspecified trimester,',
  'fetus 3',
  'O3110X4 Continuing pregnancy after spontaneous abortion of one fetus or more, unspecified trimester,',
  'fetus 4',
  'O3110X5 Continuing pregnancy after spontaneous abortion of one fetus or more, unspecified trimester,',
  'fetus 5',
  'O3110X9 Continuing pregnancy after spontaneous abortion of one fetus or more, unspecified trimester,',
  'other fetus',
  'O3111X0 Continuing pregnancy after spontaneous abortion of one fetus or more, first trimester, not',
  'applicable or unspecified',
  'O3111X1 Continuing pregnancy after spontaneous abortion of one fetus or more, first trimester, fetus 1',
  'O3111X2 Continuing pregnancy after spontaneous abortion of one fetus or more, first trimester, fetus 2',
  'O3111X3 Continuing pregnancy after spontaneous abortion of one fetus or more, first trimester, fetus 3',
  'O3111X4 Continuing pregnancy after spontaneous abortion of one fetus or more, first trimester, fetus 4',
  'O3111X5 Continuing pregnancy after spontaneous abortion of one fetus or more, first trimester, fetus 5',
  'O3111X9 Continuing pregnancy after spontaneous abortion of one fetus or more, first trimester, other',
  'fetus',
  'O3112X0 Continuing pregnancy after spontaneous abortion of one fetus or more, second trimester, not',
  'applicable or unspecified',
  'O3112X1 Continuing pregnancy after spontaneous abortion of one fetus or more, second trimester,',
  'fetus 1',
  'O3112X2 Continuing pregnancy after spontaneous abortion of one fetus or more, second trimester,',
  'fetus 2',
  'O3112X3 Continuing pregnancy after spontaneous abortion of one fetus or more, second trimester,',
  'fetus 3',
  'O3112X4 Continuing pregnancy after spontaneous abortion of one fetus or more, second trimester,',
  'fetus 4',
  'O3112X5 Continuing pregnancy after spontaneous abortion of one fetus or more, second trimester,',
  'fetus 5',
  'O3112X9 Continuing pregnancy after spontaneous abortion of one fetus or more, second trimester,',
  'other fetus',
  'O3113X0 Continuing pregnancy after spontaneous abortion of one fetus or more, third trimester, not',
  'applicable or unspecified',
  'O3113X1 Continuing pregnancy after spontaneous abortion of one fetus or more, third trimester, fetus',
  '1',
  'O3113X2 Continuing pregnancy after spontaneous abortion of one fetus or more, third trimester, fetus',
  '2',
  'O3113X3 Continuing pregnancy after spontaneous abortion of one fetus or more, third trimester, fetus',
  '3',
  'O3113X4 Continuing pregnancy after spontaneous abortion of one fetus or more, third trimester, fetus',
  '4',
  'O3113X5 Continuing pregnancy after spontaneous abortion of one fetus or more, third trimester, fetus',
  '5',
  'O3113X9 Continuing pregnancy after spontaneous abortion of one fetus or more, third trimester, other',
  'fetus',
  'O3120X0 Continuing pregnancy after intrauterine death of one fetus or more, unspecified trimester, not',
  'applicable or unspecified',
  'O3120X1 Continuing pregnancy after intrauterine death of one fetus or more, unspecified trimester,',
  'fetus 1',
  'O3120X2 Continuing pregnancy after intrauterine death of one fetus or more, unspecified trimester,',
  'fetus 2',
  'O3120X3 Continuing pregnancy after intrauterine death of one fetus or more, unspecified trimester,',
  'fetus 3',
  'O3120X4 Continuing pregnancy after intrauterine death of one fetus or more, unspecified trimester,',
  'fetus 4',
  'O3120X5 Continuing pregnancy after intrauterine death of one fetus or more, unspecified trimester,',
  'fetus 5',
  'O3120X9 Continuing pregnancy after intrauterine death of one fetus or more, unspecified trimester,',
  'other fetus',
  'O3121X0 Continuing pregnancy after intrauterine death of one fetus or more, first trimester, not',
  'applicable or unspecified',
  'O3121X1 Continuing pregnancy after intrauterine death of one fetus or more, first trimester, fetus 1',
  'O3121X2 Continuing pregnancy after intrauterine death of one fetus or more, first trimester, fetus 2',
  'O3121X3 Continuing pregnancy after intrauterine death of one fetus or more, first trimester, fetus 3',
  'O3121X4 Continuing pregnancy after intrauterine death of one fetus or more, first trimester, fetus 4',
  'O3121X5 Continuing pregnancy after intrauterine death of one fetus or more, first trimester, fetus 5',
  'O3121X9 Continuing pregnancy after intrauterine death of one fetus or more, first trimester, other fetus',
  'O3122X0 Continuing pregnancy after intrauterine death of one fetus or more, second trimester, not',
  'applicable or unspecified',
  'O3122X1 Continuing pregnancy after intrauterine death of one fetus or more, second trimester, fetus 1',
  'O3122X2 Continuing pregnancy after intrauterine death of one fetus or more, second trimester, fetus 2',
  'O3122X3 Continuing pregnancy after intrauterine death of one fetus or more, second trimester, fetus 3',
  'O3122X4 Continuing pregnancy after intrauterine death of one fetus or more, second trimester, fetus 4',
  'O3122X5 Continuing pregnancy after intrauterine death of one fetus or more, second trimester, fetus 5',
  'O3122X9 Continuing pregnancy after intrauterine death of one fetus or more, second trimester, other',
  'fetus',
  'O3123X0 Continuing pregnancy after intrauterine death of one fetus or more, third trimester, not',
  'applicable or unspecified',
  'O3123X1 Continuing pregnancy after intrauterine death of one fetus or more, third trimester, fetus 1',
  'O3123X2 Continuing pregnancy after intrauterine death of one fetus or more, third trimester, fetus 2',
  'O3123X3 Continuing pregnancy after intrauterine death of one fetus or more, third trimester, fetus 3',
  'O3123X4 Continuing pregnancy after intrauterine death of one fetus or more, third trimester, fetus 4',
  'O3123X5 Continuing pregnancy after intrauterine death of one fetus or more, third trimester, fetus 5',
  'O3123X9 Continuing pregnancy after intrauterine death of one fetus or more, third trimester, other',
  'fetus',
  'O3130X0 Continuing pregnancy after elective fetal reduction of one fetus or more, unspecified',
  'trimester, not applicable or unspecified',
  'O3130X1 Continuing pregnancy after elective fetal reduction of one fetus or more, unspecified',
  'trimester, fetus 1',
  'O3130X2 Continuing pregnancy after elective fetal reduction of one fetus or more, unspecified',
  'trimester, fetus 2',
  'O3130X3 Continuing pregnancy after elective fetal reduction of one fetus or more, unspecified',
  'trimester, fetus 3',
  'O3130X4 Continuing pregnancy after elective fetal reduction of one fetus or more, unspecified',
  'trimester, fetus 4',
  'O3130X5 Continuing pregnancy after elective fetal reduction of one fetus or more, unspecified',
  'trimester, fetus 5',
  'O3130X9 Continuing pregnancy after elective fetal reduction of one fetus or more, unspecified',
  'trimester, other fetus',
  'O3131X0 Continuing pregnancy after elective fetal reduction of one fetus or more, first trimester, not',
  'applicable or unspecified',
  'O3131X1 Continuing pregnancy after elective fetal reduction of one fetus or more, first trimester, fetus',
  '1',
  'O3131X2 Continuing pregnancy after elective fetal reduction of one fetus or more, first trimester, fetus',
  '2',
  'O3131X3 Continuing pregnancy after elective fetal reduction of one fetus or more, first trimester, fetus',
  '3',
  'O3131X4 Continuing pregnancy after elective fetal reduction of one fetus or more, first trimester, fetus',
  '4',
  'O3131X5 Continuing pregnancy after elective fetal reduction of one fetus or more, first trimester, fetus',
  '5',
  'O3131X9 Continuing pregnancy after elective fetal reduction of one fetus or more, first trimester, other',
  'fetus',
  'O3132X0 Continuing pregnancy after elective fetal reduction of one fetus or more, second trimester, not',
  'applicable or unspecified',
  'O3132X1 Continuing pregnancy after elective fetal reduction of one fetus or more, second trimester,',
  'fetus 1',
  'O3132X2 Continuing pregnancy after elective fetal reduction of one fetus or more, second trimester,',
  'fetus 2',
  'O3132X3 Continuing pregnancy after elective fetal reduction of one fetus or more, second trimester,',
  'fetus 3',
  'O3132X4 Continuing pregnancy after elective fetal reduction of one fetus or more, second trimester,',
  'fetus 4',
  'O3132X5 Continuing pregnancy after elective fetal reduction of one fetus or more, second trimester,',
  'fetus 5',
  'O3132X9 Continuing pregnancy after elective fetal reduction of one fetus or more, second trimester,',
  'other fetus',
  'O3133X0 Continuing pregnancy after elective fetal reduction of one fetus or more, third trimester, not',
  'applicable or unspecified',
  'O3133X1 Continuing pregnancy after elective fetal reduction of one fetus or more, third trimester, fetus',
  '1',
  'O3133X2 Continuing pregnancy after elective fetal reduction of one fetus or more, third trimester, fetus',
  '2',
  'O3133X3 Continuing pregnancy after elective fetal reduction of one fetus or more, third trimester, fetus',
  '3',
  'O3133X4 Continuing pregnancy after elective fetal reduction of one fetus or more, third trimester, fetus',
  '4',
  'O3133X5 Continuing pregnancy after elective fetal reduction of one fetus or more, third trimester, fetus',
  '5',
  'O3133X9 Continuing pregnancy after elective fetal reduction of one fetus or more, third trimester, other',
  'fetus',
  'O318X10 Other complications specific to multiple gestation, first trimester, not applicable or unspecified',
  'O318X11 Other complications specific to multiple gestation, first trimester, fetus 1',
  'O318X12 Other complications specific to multiple gestation, first trimester, fetus 2',
  'O318X13 Other complications specific to multiple gestation, first trimester, fetus 3',
  'O318X14 Other complications specific to multiple gestation, first trimester, fetus 4',
  'O318X15 Other complications specific to multiple gestation, first trimester, fetus 5',
  'O318X19 Other complications specific to multiple gestation, first trimester, other fetus',
  'O318X20 Other complications specific to multiple gestation, second trimester, not applicable or',
  'unspecified',
  'O318X21 Other complications specific to multiple gestation, second trimester, fetus 1',
  'O318X22 Other complications specific to multiple gestation, second trimester, fetus 2',
  'O318X23 Other complications specific to multiple gestation, second trimester, fetus 3',
  'O318X24 Other complications specific to multiple gestation, second trimester, fetus 4',
  'O318X25 Other complications specific to multiple gestation, second trimester, fetus 5',
  'O318X29 Other complications specific to multiple gestation, second trimester, other fetus',
  'O318X30 Other complications specific to multiple gestation, third trimester, not applicable or',
  'unspecified',
  'O318X31 Other complications specific to multiple gestation, third trimester, fetus 1',
  'O318X32 Other complications specific to multiple gestation, third trimester, fetus 2',
  'O318X33 Other complications specific to multiple gestation, third trimester, fetus 3',
  'O318X34 Other complications specific to multiple gestation, third trimester, fetus 4',
  'O318X35 Other complications specific to multiple gestation, third trimester, fetus 5',
  'O318X39 Other complications specific to multiple gestation, third trimester, other fetus',
  'O318X90 Other complications specific to multiple gestation, unspecified trimester, not applicable or',
  'unspecified',
  'O318X91 Other complications specific to multiple gestation, unspecified trimester, fetus 1',
  'O318X92 Other complications specific to multiple gestation, unspecified trimester, fetus 2',
  'O318X93 Other complications specific to multiple gestation, unspecified trimester, fetus 3',
  'O318X94 Other complications specific to multiple gestation, unspecified trimester, fetus 4',
  'O318X95 Other complications specific to multiple gestation, unspecified trimester, fetus 5',
  'O318X99 Other complications specific to multiple gestation, unspecified trimester, other fetus',
  'O320XX0 Maternal care for unstable lie, not applicable or unspecified',
  'O320XX1 Maternal care for unstable lie, fetus 1',
  'O320XX2 Maternal care for unstable lie, fetus 2',
  'O320XX3 Maternal care for unstable lie, fetus 3',
  'O320XX4 Maternal care for unstable lie, fetus 4',
  'O320XX5 Maternal care for unstable lie, fetus 5',
  'O320XX9 Maternal care for unstable lie, other fetus',
  'O321XX0 Maternal care for breech presentation, not applicable or unspecified',
  'O321XX1 Maternal care for breech presentation, fetus 1',
  'O321XX2 Maternal care for breech presentation, fetus 2',
  'O321XX3 Maternal care for breech presentation, fetus 3',
  'O321XX4 Maternal care for breech presentation, fetus 4',
  'O321XX5 Maternal care for breech presentation, fetus 5',
  'O321XX9 Maternal care for breech presentation, other fetus',
  'O322XX0 Maternal care for transverse and oblique lie, not applicable or unspecified',
  'O322XX1 Maternal care for transverse and oblique lie, fetus 1',
  'O322XX2 Maternal care for transverse and oblique lie, fetus 2',
  'O322XX3 Maternal care for transverse and oblique lie, fetus 3',
  'O322XX4 Maternal care for transverse and oblique lie, fetus 4',
  'O322XX5 Maternal care for transverse and oblique lie, fetus 5',
  'O322XX9 Maternal care for transverse and oblique lie, other fetus',
  'O323XX0 Maternal care for face, brow and chin presentation, not applicable or unspecified',
  'O323XX1 Maternal care for face, brow and chin presentation, fetus 1',
  'O323XX2 Maternal care for face, brow and chin presentation, fetus 2',
  'O323XX3 Maternal care for face, brow and chin presentation, fetus 3',
  'O323XX4 Maternal care for face, brow and chin presentation, fetus 4',
  'O323XX5 Maternal care for face, brow and chin presentation, fetus 5',
  'O323XX9 Maternal care for face, brow and chin presentation, other fetus',
  'O324XX0 Maternal care for high head at term, not applicable or unspecified',
  'O324XX1 Maternal care for high head at term, fetus 1',
  'O324XX2 Maternal care for high head at term, fetus 2',
  'O324XX3 Maternal care for high head at term, fetus 3',
  'O324XX4 Maternal care for high head at term, fetus 4',
  'O324XX5 Maternal care for high head at term, fetus 5',
  'O324XX9 Maternal care for high head at term, other fetus',
  'O326XX0 Maternal care for compound presentation, not applicable or unspecified',
  'O326XX1 Maternal care for compound presentation, fetus 1',
  'O326XX2 Maternal care for compound presentation, fetus 2',
  'O326XX3 Maternal care for compound presentation, fetus 3',
  'O326XX4 Maternal care for compound presentation, fetus 4',
  'O326XX5 Maternal care for compound presentation, fetus 5',
  'O326XX9 Maternal care for compound presentation, other fetus',
  'O328XX0 Maternal care for other malpresentation of fetus, not applicable or unspecified',
  'O328XX1 Maternal care for other malpresentation of fetus, fetus 1',
  'O328XX2 Maternal care for other malpresentation of fetus, fetus 2',
  'O328XX3 Maternal care for other malpresentation of fetus, fetus 3',
  'O328XX4 Maternal care for other malpresentation of fetus, fetus 4',
  'O328XX5 Maternal care for other malpresentation of fetus, fetus 5',
  'O328XX9 Maternal care for other malpresentation of fetus, other fetus',
  'O329XX0 Maternal care for malpresentation of fetus, unspecified, not applicable or unspecified',
  'O329XX1 Maternal care for malpresentation of fetus, unspecified, fetus 1',
  'O329XX2 Maternal care for malpresentation of fetus, unspecified, fetus 2',
  'O329XX3 Maternal care for malpresentation of fetus, unspecified, fetus 3',
  'O329XX4 Maternal care for malpresentation of fetus, unspecified, fetus 4',
  'O329XX5 Maternal care for malpresentation of fetus, unspecified, fetus 5',
  'O329XX9 Maternal care for malpresentation of fetus, unspecified, other fetus',
  'O330 Maternal care for disproportion due to deformity of maternal pelvic bones',
  'O331 Maternal care for disproportion due to generally contracted pelvis',
  'O332 Maternal care for disproportion due to inlet contraction of pelvis',
  'O333XX0 Maternal care for disproportion due to outlet contraction of pelvis, not applicable or',
  'unspecified',
  'O333XX1 Maternal care for disproportion due to outlet contraction of pelvis, fetus 1',
  'O333XX2 Maternal care for disproportion due to outlet contraction of pelvis, fetus 2',
  'O333XX3 Maternal care for disproportion due to outlet contraction of pelvis, fetus 3',
  'O333XX4 Maternal care for disproportion due to outlet contraction of pelvis, fetus 4',
  'O333XX5 Maternal care for disproportion due to outlet contraction of pelvis, fetus 5',
  'O333XX9 Maternal care for disproportion due to outlet contraction of pelvis, other fetus',
  'O334XX0 Maternal care for disproportion of mixed maternal and fetal origin, not applicable or',
  'unspecified',
  'O334XX1 Maternal care for disproportion of mixed maternal and fetal origin, fetus 1',
  'O334XX2 Maternal care for disproportion of mixed maternal and fetal origin, fetus 2',
  'O334XX3 Maternal care for disproportion of mixed maternal and fetal origin, fetus 3',
  'O334XX4 Maternal care for disproportion of mixed maternal and fetal origin, fetus 4',
  'O334XX5 Maternal care for disproportion of mixed maternal and fetal origin, fetus 5',
  'O334XX9 Maternal care for disproportion of mixed maternal and fetal origin, other fetus',
  'O335XX0 Maternal care for disproportion due to unusually large fetus, not applicable or unspecified',
  'O335XX1 Maternal care for disproportion due to unusually large fetus, fetus 1',
  'O335XX2 Maternal care for disproportion due to unusually large fetus, fetus 2',
  'O335XX3 Maternal care for disproportion due to unusually large fetus, fetus 3',
  'O335XX4 Maternal care for disproportion due to unusually large fetus, fetus 4',
  'O335XX5 Maternal care for disproportion due to unusually large fetus, fetus 5',
  'O335XX9 Maternal care for disproportion due to unusually large fetus, other fetus',
  'O336XX0 Maternal care for disproportion due to hydrocephalic fetus, not applicable or unspecified',
  'O336XX1 Maternal care for disproportion due to hydrocephalic fetus, fetus 1',
  'O336XX2 Maternal care for disproportion due to hydrocephalic fetus, fetus 2',
  'O336XX3 Maternal care for disproportion due to hydrocephalic fetus, fetus 3',
  'O336XX4 Maternal care for disproportion due to hydrocephalic fetus, fetus 4',
  'O336XX5 Maternal care for disproportion due to hydrocephalic fetus, fetus 5',
  'O336XX9 Maternal care for disproportion due to hydrocephalic fetus, other fetus',
  'O337XX0 Maternal care for disproportion due to other fetal deformities, not applicable or unspecified',
  'O337XX1 Maternal care for disproportion due to other fetal deformities, fetus 1',
  'O337XX2 Maternal care for disproportion due to other fetal deformities, fetus 2',
  'O337XX3 Maternal care for disproportion due to other fetal deformities, fetus 3',
  'O337XX4 Maternal care for disproportion due to other fetal deformities, fetus 4',
  'O337XX5 Maternal care for disproportion due to other fetal deformities, fetus 5',
  'O337XX9 Maternal care for disproportion due to other fetal deformities, other fetus',
  'O338 Maternal care for disproportion of other origin',
  'O339 Maternal care for disproportion, unspecified',
  'O3400 Maternal care for unspecified congenital malformation of uterus, unspecified trimester',
  'O3401 Maternal care for unspecified congenital malformation of uterus, first trimester',
  'O3402 Maternal care for unspecified congenital malformation of uterus, second trimester',
  'O3403 Maternal care for unspecified congenital malformation of uterus, third trimester',
  'O3410 Maternal care for benign tumor of corpus uteri, unspecified trimester',
  'O3411 Maternal care for benign tumor of corpus uteri, first trimester',
  'O3412 Maternal care for benign tumor of corpus uteri, second trimester',
  'O3413 Maternal care for benign tumor of corpus uteri, third trimester',
  'O34211 Maternal care for low transverse scar from previous cesarean delivery',
  'O34212 Maternal care for vertical scar from previous cesarean delivery',
  'O34219 Maternal care for unspecified type scar from previous cesarean delivery',
  'O3429 Maternal care due to uterine scar from other previous surgery',
  'O3430 Maternal care for cervical incompetence, unspecified trimester',
  'O3431 Maternal care for cervical incompetence, first trimester',
  'O3432 Maternal care for cervical incompetence, second trimester',
  'O3433 Maternal care for cervical incompetence, third trimester',
  'O3440 Maternal care for other abnormalities of cervix, unspecified trimester',
  'O3441 Maternal care for other abnormalities of cervix, first trimester',
  'O3442 Maternal care for other abnormalities of cervix, second trimester',
  'O3443 Maternal care for other abnormalities of cervix, third trimester',
  'O34511 Maternal care for incarceration of gravid uterus, first trimester',
  'O34512 Maternal care for incarceration of gravid uterus, second trimester',
  'O34513 Maternal care for incarceration of gravid uterus, third trimester',
  'O34519 Maternal care for incarceration of gravid uterus, unspecified trimester',
  'O34521 Maternal care for prolapse of gravid uterus, first trimester',
  'O34522 Maternal care for prolapse of gravid uterus, second trimester',
  'O34523 Maternal care for prolapse of gravid uterus, third trimester',
  'O34529 Maternal care for prolapse of gravid uterus, unspecified trimester',
  'O34531 Maternal care for retroversion of gravid uterus, first trimester',
  'O34532 Maternal care for retroversion of gravid uterus, second trimester',
  'O34533 Maternal care for retroversion of gravid uterus, third trimester',
  'O34539 Maternal care for retroversion of gravid uterus, unspecified trimester',
  'O34591 Maternal care for other abnormalities of gravid uterus, first trimester',
  'O34592 Maternal care for other abnormalities of gravid uterus, second trimester',
  'O34593 Maternal care for other abnormalities of gravid uterus, third trimester',
  'O34599 Maternal care for other abnormalities of gravid uterus, unspecified trimester',
  'O3460 Maternal care for abnormality of vagina, unspecified trimester',
  'O3461 Maternal care for abnormality of vagina, first trimester',
  'O3462 Maternal care for abnormality of vagina, second trimester',
  'O3463 Maternal care for abnormality of vagina, third trimester',
  'O3470 Maternal care for abnormality of vulva and perineum, unspecified trimester',
  'O3471 Maternal care for abnormality of vulva and perineum, first trimester',
  'O3472 Maternal care for abnormality of vulva and perineum, second trimester',
  'O3473 Maternal care for abnormality of vulva and perineum, third trimester',
  'O3480 Maternal care for other abnormalities of pelvic organs, unspecified trimester',
  'O3481 Maternal care for other abnormalities of pelvic organs, first trimester',
  'O3482 Maternal care for other abnormalities of pelvic organs, second trimester',
  'O3483 Maternal care for other abnormalities of pelvic organs, third trimester',
  'O3490 Maternal care for abnormality of pelvic organ, unspecified, unspecified trimester',
  'O3491 Maternal care for abnormality of pelvic organ, unspecified, first trimester',
  'O3492 Maternal care for abnormality of pelvic organ, unspecified, second trimester',
  'O3493 Maternal care for abnormality of pelvic organ, unspecified, third trimester',
  'O350XX0 Maternal care for (suspected) central nervous system malformation in fetus, not applicable or',
  'unspecified',
  'O350XX1 Maternal care for (suspected) central nervous system malformation in fetus, fetus 1',
  'O350XX2 Maternal care for (suspected) central nervous system malformation in fetus, fetus 2',
  'O350XX3 Maternal care for (suspected) central nervous system malformation in fetus, fetus 3',
  'O350XX4 Maternal care for (suspected) central nervous system malformation in fetus, fetus 4',
  'O350XX5 Maternal care for (suspected) central nervous system malformation in fetus, fetus 5',
  'O350XX9 Maternal care for (suspected) central nervous system malformation in fetus, other fetus',
  'O351XX0 Maternal care for (suspected) chromosomal abnormality in fetus, not applicable or unspecified',
  'O351XX1 Maternal care for (suspected) chromosomal abnormality in fetus, fetus 1',
  'O351XX2 Maternal care for (suspected) chromosomal abnormality in fetus, fetus 2',
  'O351XX3 Maternal care for (suspected) chromosomal abnormality in fetus, fetus 3',
  'O351XX4 Maternal care for (suspected) chromosomal abnormality in fetus, fetus 4',
  'O351XX5 Maternal care for (suspected) chromosomal abnormality in fetus, fetus 5',
  'O351XX9 Maternal care for (suspected) chromosomal abnormality in fetus, other fetus',
  'O352XX0 Maternal care for (suspected) hereditary disease in fetus, not applicable or unspecified',
  'O352XX1 Maternal care for (suspected) hereditary disease in fetus, fetus 1',
  'O352XX2 Maternal care for (suspected) hereditary disease in fetus, fetus 2',
  'O352XX3 Maternal care for (suspected) hereditary disease in fetus, fetus 3',
  'O352XX4 Maternal care for (suspected) hereditary disease in fetus, fetus 4',
  'O352XX5 Maternal care for (suspected) hereditary disease in fetus, fetus 5',
  'O352XX9 Maternal care for (suspected) hereditary disease in fetus, other fetus',
  'O353XX0 Maternal care for (suspected) damage to fetus from viral disease in mother, not applicable or',
  'unspecified',
  'O353XX1 Maternal care for (suspected) damage to fetus from viral disease in mother, fetus 1',
  'O353XX2 Maternal care for (suspected) damage to fetus from viral disease in mother, fetus 2',
  'O353XX3 Maternal care for (suspected) damage to fetus from viral disease in mother, fetus 3',
  'O353XX4 Maternal care for (suspected) damage to fetus from viral disease in mother, fetus 4',
  'O353XX5 Maternal care for (suspected) damage to fetus from viral disease in mother, fetus 5',
  'O353XX9 Maternal care for (suspected) damage to fetus from viral disease in mother, other fetus',
  'O354XX0 Maternal care for (suspected) damage to fetus from alcohol, not applicable or unspecified',
  'O354XX1 Maternal care for (suspected) damage to fetus from alcohol, fetus 1',
  'O354XX2 Maternal care for (suspected) damage to fetus from alcohol, fetus 2',
  'O354XX3 Maternal care for (suspected) damage to fetus from alcohol, fetus 3',
  'O354XX4 Maternal care for (suspected) damage to fetus from alcohol, fetus 4',
  'O354XX5 Maternal care for (suspected) damage to fetus from alcohol, fetus 5',
  'O354XX9 Maternal care for (suspected) damage to fetus from alcohol, other fetus',
  'O355XX0 Maternal care for (suspected) damage to fetus by drugs, not applicable or unspecified',
  'O355XX1 Maternal care for (suspected) damage to fetus by drugs, fetus 1',
  'O355XX2 Maternal care for (suspected) damage to fetus by drugs, fetus 2',
  'O355XX3 Maternal care for (suspected) damage to fetus by drugs, fetus 3',
  'O355XX4 Maternal care for (suspected) damage to fetus by drugs, fetus 4',
  'O355XX5 Maternal care for (suspected) damage to fetus by drugs, fetus 5',
  'O355XX9 Maternal care for (suspected) damage to fetus by drugs, other fetus',
  'O356XX0 Maternal care for (suspected) damage to fetus by radiation, not applicable or unspecified',
  'O356XX1 Maternal care for (suspected) damage to fetus by radiation, fetus 1',
  'O356XX2 Maternal care for (suspected) damage to fetus by radiation, fetus 2',
  'O356XX3 Maternal care for (suspected) damage to fetus by radiation, fetus 3',
  'O356XX4 Maternal care for (suspected) damage to fetus by radiation, fetus 4',
  'O356XX5 Maternal care for (suspected) damage to fetus by radiation, fetus 5',
  'O356XX9 Maternal care for (suspected) damage to fetus by radiation, other fetus',
  'O357XX0 Maternal care for (suspected) damage to fetus by other medical procedures, not applicable or',
  'unspecified',
  'O357XX1 Maternal care for (suspected) damage to fetus by other medical procedures, fetus 1',
  'O357XX2 Maternal care for (suspected) damage to fetus by other medical procedures, fetus 2',
  'O357XX3 Maternal care for (suspected) damage to fetus by other medical procedures, fetus 3',
  'O357XX4 Maternal care for (suspected) damage to fetus by other medical procedures, fetus 4',
  'O357XX5 Maternal care for (suspected) damage to fetus by other medical procedures, fetus 5',
  'O357XX9 Maternal care for (suspected) damage to fetus by other medical procedures, other fetus',
  'O358XX0 Maternal care for other (suspected) fetal abnormality and damage, not applicable or',
  'unspecified',
  'O358XX1 Maternal care for other (suspected) fetal abnormality and damage, fetus 1',
  'O358XX2 Maternal care for other (suspected) fetal abnormality and damage, fetus 2',
  'O358XX3 Maternal care for other (suspected) fetal abnormality and damage, fetus 3',
  'O358XX4 Maternal care for other (suspected) fetal abnormality and damage, fetus 4',
  'O358XX5 Maternal care for other (suspected) fetal abnormality and damage, fetus 5',
  'O358XX9 Maternal care for other (suspected) fetal abnormality and damage, other fetus',
  'O359XX0 Maternal care for (suspected) fetal abnormality and damage, unspecified, not applicable or',
  'unspecified',
  'O359XX1 Maternal care for (suspected) fetal abnormality and damage, unspecified, fetus 1',
  'O359XX2 Maternal care for (suspected) fetal abnormality and damage, unspecified, fetus 2',
  'O359XX3 Maternal care for (suspected) fetal abnormality and damage, unspecified, fetus 3',
  'O359XX4 Maternal care for (suspected) fetal abnormality and damage, unspecified, fetus 4',
  'O359XX5 Maternal care for (suspected) fetal abnormality and damage, unspecified, fetus 5',
  'O359XX9 Maternal care for (suspected) fetal abnormality and damage, unspecified, other fetus',
  'O360110 Maternal care for anti-D [Rh] antibodies, first trimester, not applicable or unspecified',
  'O360111 Maternal care for anti-D [Rh] antibodies, first trimester, fetus 1',
  'O360112 Maternal care for anti-D [Rh] antibodies, first trimester, fetus 2',
  'O360113 Maternal care for anti-D [Rh] antibodies, first trimester, fetus 3',
  'O360114 Maternal care for anti-D [Rh] antibodies, first trimester, fetus 4',
  'O360115 Maternal care for anti-D [Rh] antibodies, first trimester, fetus 5',
  'O360119 Maternal care for anti-D [Rh] antibodies, first trimester, other fetus',
  'O360120 Maternal care for anti-D [Rh] antibodies, second trimester, not applicable or unspecified',
  'O360121 Maternal care for anti-D [Rh] antibodies, second trimester, fetus 1',
  'O360122 Maternal care for anti-D [Rh] antibodies, second trimester, fetus 2',
  'O360123 Maternal care for anti-D [Rh] antibodies, second trimester, fetus 3',
  'O360124 Maternal care for anti-D [Rh] antibodies, second trimester, fetus 4',
  'O360125 Maternal care for anti-D [Rh] antibodies, second trimester, fetus 5',
  'O360129 Maternal care for anti-D [Rh] antibodies, second trimester, other fetus',
  'O360130 Maternal care for anti-D [Rh] antibodies, third trimester, not applicable or unspecified',
  'O360131 Maternal care for anti-D [Rh] antibodies, third trimester, fetus 1',
  'O360132 Maternal care for anti-D [Rh] antibodies, third trimester, fetus 2',
  'O360133 Maternal care for anti-D [Rh] antibodies, third trimester, fetus 3',
  'O360134 Maternal care for anti-D [Rh] antibodies, third trimester, fetus 4',
  'O360135 Maternal care for anti-D [Rh] antibodies, third trimester, fetus 5',
  'O360139 Maternal care for anti-D [Rh] antibodies, third trimester, other fetus',
  'O360190 Maternal care for anti-D [Rh] antibodies, unspecified trimester, not applicable or unspecified',
  'O360191 Maternal care for anti-D [Rh] antibodies, unspecified trimester, fetus 1',
  'O360192 Maternal care for anti-D [Rh] antibodies, unspecified trimester, fetus 2',
  'O360193 Maternal care for anti-D [Rh] antibodies, unspecified trimester, fetus 3',
  'O360194 Maternal care for anti-D [Rh] antibodies, unspecified trimester, fetus 4',
  'O360195 Maternal care for anti-D [Rh] antibodies, unspecified trimester, fetus 5',
  'O360199 Maternal care for anti-D [Rh] antibodies, unspecified trimester, other fetus',
  'O360910 Maternal care for other rhesus isoimmunization, first trimester, not applicable or unspecified',
  'O360911 Maternal care for other rhesus isoimmunization, first trimester, fetus 1',
  'O360912 Maternal care for other rhesus isoimmunization, first trimester, fetus 2',
  'O360913 Maternal care for other rhesus isoimmunization, first trimester, fetus 3',
  'O360914 Maternal care for other rhesus isoimmunization, first trimester, fetus 4',
  'O360915 Maternal care for other rhesus isoimmunization, first trimester, fetus 5',
  'O360919 Maternal care for other rhesus isoimmunization, first trimester, other fetus',
  'O360920 Maternal care for other rhesus isoimmunization, second trimester, not applicable or',
  'unspecified',
  'O360921 Maternal care for other rhesus isoimmunization, second trimester, fetus 1',
  'O360922 Maternal care for other rhesus isoimmunization, second trimester, fetus 2',
  'O360923 Maternal care for other rhesus isoimmunization, second trimester, fetus 3',
  'O360924 Maternal care for other rhesus isoimmunization, second trimester, fetus 4',
  'O360925 Maternal care for other rhesus isoimmunization, second trimester, fetus 5',
  'O360929 Maternal care for other rhesus isoimmunization, second trimester, other fetus',
  'O360930 Maternal care for other rhesus isoimmunization, third trimester, not applicable or unspecified',
  'O360931 Maternal care for other rhesus isoimmunization, third trimester, fetus 1',
  'O360932 Maternal care for other rhesus isoimmunization, third trimester, fetus 2',
  'O360933 Maternal care for other rhesus isoimmunization, third trimester, fetus 3',
  'O360934 Maternal care for other rhesus isoimmunization, third trimester, fetus 4',
  'O360935 Maternal care for other rhesus isoimmunization, third trimester, fetus 5',
  'O360939 Maternal care for other rhesus isoimmunization, third trimester, other fetus',
  'O360990 Maternal care for other rhesus isoimmunization, unspecified trimester, not applicable or',
  'unspecified',
  'O360991 Maternal care for other rhesus isoimmunization, unspecified trimester, fetus 1',
  'O360992 Maternal care for other rhesus isoimmunization, unspecified trimester, fetus 2',
  'O360993 Maternal care for other rhesus isoimmunization, unspecified trimester, fetus 3',
  'O360994 Maternal care for other rhesus isoimmunization, unspecified trimester, fetus 4',
  'O360995 Maternal care for other rhesus isoimmunization, unspecified trimester, fetus 5',
  'O360999 Maternal care for other rhesus isoimmunization, unspecified trimester, other fetus',
  'O361110 Maternal care for Anti-A sensitization, first trimester, not applicable or unspecified',
  'O361111 Maternal care for Anti-A sensitization, first trimester, fetus 1',
  'O361112 Maternal care for Anti-A sensitization, first trimester, fetus 2',
  'O361113 Maternal care for Anti-A sensitization, first trimester, fetus 3',
  'O361114 Maternal care for Anti-A sensitization, first trimester, fetus 4',
  'O361115 Maternal care for Anti-A sensitization, first trimester, fetus 5',
  'O361119 Maternal care for Anti-A sensitization, first trimester, other fetus',
  'O361120 Maternal care for Anti-A sensitization, second trimester, not applicable or unspecified',
  'O361121 Maternal care for Anti-A sensitization, second trimester, fetus 1',
  'O361122 Maternal care for Anti-A sensitization, second trimester, fetus 2',
  'O361123 Maternal care for Anti-A sensitization, second trimester, fetus 3',
  'O361124 Maternal care for Anti-A sensitization, second trimester, fetus 4',
  'O361125 Maternal care for Anti-A sensitization, second trimester, fetus 5',
  'O361129 Maternal care for Anti-A sensitization, second trimester, other fetus',
  'O361130 Maternal care for Anti-A sensitization, third trimester, not applicable or unspecified',
  'O361131 Maternal care for Anti-A sensitization, third trimester, fetus 1',
  'O361132 Maternal care for Anti-A sensitization, third trimester, fetus 2',
  'O361133 Maternal care for Anti-A sensitization, third trimester, fetus 3',
  'O361134 Maternal care for Anti-A sensitization, third trimester, fetus 4',
  'O361135 Maternal care for Anti-A sensitization, third trimester, fetus 5',
  'O361139 Maternal care for Anti-A sensitization, third trimester, other fetus',
  'O361190 Maternal care for Anti-A sensitization, unspecified trimester, not applicable or unspecified',
  'O361191 Maternal care for Anti-A sensitization, unspecified trimester, fetus 1',
  'O361192 Maternal care for Anti-A sensitization, unspecified trimester, fetus 2',
  'O361193 Maternal care for Anti-A sensitization, unspecified trimester, fetus 3',
  'O361194 Maternal care for Anti-A sensitization, unspecified trimester, fetus 4',
  'O361195 Maternal care for Anti-A sensitization, unspecified trimester, fetus 5',
  'O361199 Maternal care for Anti-A sensitization, unspecified trimester, other fetus',
  'O361910 Maternal care for other isoimmunization, first trimester, not applicable or unspecified',
  'O361911 Maternal care for other isoimmunization, first trimester, fetus 1',
  'O361912 Maternal care for other isoimmunization, first trimester, fetus 2',
  'O361913 Maternal care for other isoimmunization, first trimester, fetus 3',
  'O361914 Maternal care for other isoimmunization, first trimester, fetus 4',
  'O361915 Maternal care for other isoimmunization, first trimester, fetus 5',
  'O361919 Maternal care for other isoimmunization, first trimester, other fetus',
  'O361920 Maternal care for other isoimmunization, second trimester, not applicable or unspecified',
  'O361921 Maternal care for other isoimmunization, second trimester, fetus 1',
  'O361922 Maternal care for other isoimmunization, second trimester, fetus 2',
  'O361923 Maternal care for other isoimmunization, second trimester, fetus 3',
  'O361924 Maternal care for other isoimmunization, second trimester, fetus 4',
  'O361925 Maternal care for other isoimmunization, second trimester, fetus 5',
  'O361929 Maternal care for other isoimmunization, second trimester, other fetus',
  'O361930 Maternal care for other isoimmunization, third trimester, not applicable or unspecified',
  'O361931 Maternal care for other isoimmunization, third trimester, fetus 1',
  'O361932 Maternal care for other isoimmunization, third trimester, fetus 2',
  'O361933 Maternal care for other isoimmunization, third trimester, fetus 3',
  'O361934 Maternal care for other isoimmunization, third trimester, fetus 4',
  'O361935 Maternal care for other isoimmunization, third trimester, fetus 5',
  'O361939 Maternal care for other isoimmunization, third trimester, other fetus',
  'O361990 Maternal care for other isoimmunization, unspecified trimester, not applicable or unspecified',
  'O361991 Maternal care for other isoimmunization, unspecified trimester, fetus 1',
  'O361992 Maternal care for other isoimmunization, unspecified trimester, fetus 2',
  'O361993 Maternal care for other isoimmunization, unspecified trimester, fetus 3',
  'O361994 Maternal care for other isoimmunization, unspecified trimester, fetus 4',
  'O361995 Maternal care for other isoimmunization, unspecified trimester, fetus 5',
  'O361999 Maternal care for other isoimmunization, unspecified trimester, other fetus',
  'O3620X0 Maternal care for hydrops fetalis, unspecified trimester, not applicable or unspecified',
  'O3620X1 Maternal care for hydrops fetalis, unspecified trimester, fetus 1',
  'O3620X2 Maternal care for hydrops fetalis, unspecified trimester, fetus 2',
  'O3620X3 Maternal care for hydrops fetalis, unspecified trimester, fetus 3',
  'O3620X4 Maternal care for hydrops fetalis, unspecified trimester, fetus 4',
  'O3620X5 Maternal care for hydrops fetalis, unspecified trimester, fetus 5',
  'O3620X9 Maternal care for hydrops fetalis, unspecified trimester, other fetus',
  'O3621X0 Maternal care for hydrops fetalis, first trimester, not applicable or unspecified',
  'O3621X1 Maternal care for hydrops fetalis, first trimester, fetus 1',
  'O3621X2 Maternal care for hydrops fetalis, first trimester, fetus 2',
  'O3621X3 Maternal care for hydrops fetalis, first trimester, fetus 3',
  'O3621X4 Maternal care for hydrops fetalis, first trimester, fetus 4',
  'O3621X5 Maternal care for hydrops fetalis, first trimester, fetus 5',
  'O3621X9 Maternal care for hydrops fetalis, first trimester, other fetus',
  'O3622X0 Maternal care for hydrops fetalis, second trimester, not applicable or unspecified',
  'O3622X1 Maternal care for hydrops fetalis, second trimester, fetus 1',
  'O3622X2 Maternal care for hydrops fetalis, second trimester, fetus 2',
  'O3622X3 Maternal care for hydrops fetalis, second trimester, fetus 3',
  'O3622X4 Maternal care for hydrops fetalis, second trimester, fetus 4',
  'O3622X5 Maternal care for hydrops fetalis, second trimester, fetus 5',
  'O3622X9 Maternal care for hydrops fetalis, second trimester, other fetus',
  'O3623X0 Maternal care for hydrops fetalis, third trimester, not applicable or unspecified',
  'O3623X1 Maternal care for hydrops fetalis, third trimester, fetus 1',
  'O3623X2 Maternal care for hydrops fetalis, third trimester, fetus 2',
  'O3623X3 Maternal care for hydrops fetalis, third trimester, fetus 3',
  'O3623X4 Maternal care for hydrops fetalis, third trimester, fetus 4',
  'O3623X5 Maternal care for hydrops fetalis, third trimester, fetus 5',
  'O3623X9 Maternal care for hydrops fetalis, third trimester, other fetus',
  'O364XX0 Maternal care for intrauterine death, not applicable or unspecified',
  'O364XX1 Maternal care for intrauterine death, fetus 1',
  'O364XX2 Maternal care for intrauterine death, fetus 2',
  'O364XX3 Maternal care for intrauterine death, fetus 3',
  'O364XX4 Maternal care for intrauterine death, fetus 4',
  'O364XX5 Maternal care for intrauterine death, fetus 5',
  'O364XX9 Maternal care for intrauterine death, other fetus',
  'O365110 Maternal care for known or suspected placental insufficiency, first trimester, not applicable or',
  'unspecified',
  'O365111 Maternal care for known or suspected placental insufficiency, first trimester, fetus 1',
  'O365112 Maternal care for known or suspected placental insufficiency, first trimester, fetus 2',
  'O365113 Maternal care for known or suspected placental insufficiency, first trimester, fetus 3',
  'O365114 Maternal care for known or suspected placental insufficiency, first trimester, fetus 4',
  'O365115 Maternal care for known or suspected placental insufficiency, first trimester, fetus 5',
  'O365119 Maternal care for known or suspected placental insufficiency, first trimester, other fetus',
  'O365120 Maternal care for known or suspected placental insufficiency, second trimester, not applicable',
  'or unspecified',
  'O365121 Maternal care for known or suspected placental insufficiency, second trimester, fetus 1',
  'O365122 Maternal care for known or suspected placental insufficiency, second trimester, fetus 2',
  'O365123 Maternal care for known or suspected placental insufficiency, second trimester, fetus 3',
  'O365124 Maternal care for known or suspected placental insufficiency, second trimester, fetus 4',
  'O365125 Maternal care for known or suspected placental insufficiency, second trimester, fetus 5',
  'O365129 Maternal care for known or suspected placental insufficiency, second trimester, other fetus',
  'O365130 Maternal care for known or suspected placental insufficiency, third trimester, not applicable or',
  'unspecified',
  'O365131 Maternal care for known or suspected placental insufficiency, third trimester, fetus 1',
  'O365132 Maternal care for known or suspected placental insufficiency, third trimester, fetus 2',
  'O365133 Maternal care for known or suspected placental insufficiency, third trimester, fetus 3',
  'O365134 Maternal care for known or suspected placental insufficiency, third trimester, fetus 4',
  'O365135 Maternal care for known or suspected placental insufficiency, third trimester, fetus 5',
  'O365139 Maternal care for known or suspected placental insufficiency, third trimester, other fetus',
  'O365190 Maternal care for known or suspected placental insufficiency, unspecified trimester, not',
  'applicable or unspecified',
  'O365191 Maternal care for known or suspected placental insufficiency, unspecified trimester, fetus 1',
  'O365192 Maternal care for known or suspected placental insufficiency, unspecified trimester, fetus 2',
  'O365193 Maternal care for known or suspected placental insufficiency, unspecified trimester, fetus 3',
  'O365194 Maternal care for known or suspected placental insufficiency, unspecified trimester, fetus 4',
  'O365195 Maternal care for known or suspected placental insufficiency, unspecified trimester, fetus 5',
  'O365199 Maternal care for known or suspected placental insufficiency, unspecified trimester, other',
  'fetus',
  'O365910 Maternal care for other known or suspected poor fetal growth, first trimester, not applicable',
  'or unspecified',
  'O365911 Maternal care for other known or suspected poor fetal growth, first trimester, fetus 1',
  'O365912 Maternal care for other known or suspected poor fetal growth, first trimester, fetus 2',
  'O365913 Maternal care for other known or suspected poor fetal growth, first trimester, fetus 3',
  'O365914 Maternal care for other known or suspected poor fetal growth, first trimester, fetus 4',
  'O365915 Maternal care for other known or suspected poor fetal growth, first trimester, fetus 5',
  'O365919 Maternal care for other known or suspected poor fetal growth, first trimester, other fetus',
  'O365920 Maternal care for other known or suspected poor fetal growth, second trimester, not',
  'applicable or unspecified',
  'O365921 Maternal care for other known or suspected poor fetal growth, second trimester, fetus 1',
  'O365922 Maternal care for other known or suspected poor fetal growth, second trimester, fetus 2',
  'O365923 Maternal care for other known or suspected poor fetal growth, second trimester, fetus 3',
  'O365924 Maternal care for other known or suspected poor fetal growth, second trimester, fetus 4',
  'O365925 Maternal care for other known or suspected poor fetal growth, second trimester, fetus 5',
  'O365929 Maternal care for other known or suspected poor fetal growth, second trimester, other fetus',
  'O365930 Maternal care for other known or suspected poor fetal growth, third trimester, not applicable',
  'or unspecified',
  'O365931 Maternal care for other known or suspected poor fetal growth, third trimester, fetus 1',
  'O365932 Maternal care for other known or suspected poor fetal growth, third trimester, fetus 2',
  'O365933 Maternal care for other known or suspected poor fetal growth, third trimester, fetus 3',
  'O365934 Maternal care for other known or suspected poor fetal growth, third trimester, fetus 4',
  'O365935 Maternal care for other known or suspected poor fetal growth, third trimester, fetus 5',
  'O365939 Maternal care for other known or suspected poor fetal growth, third trimester, other fetus',
  'O365990 Maternal care for other known or suspected poor fetal growth, unspecified trimester, not',
  'applicable or unspecified',
  'O365991 Maternal care for other known or suspected poor fetal growth, unspecified trimester, fetus 1',
  'O365992 Maternal care for other known or suspected poor fetal growth, unspecified trimester, fetus 2',
  'O365993 Maternal care for other known or suspected poor fetal growth, unspecified trimester, fetus 3',
  'O365994 Maternal care for other known or suspected poor fetal growth, unspecified trimester, fetus 4',
  'O365995 Maternal care for other known or suspected poor fetal growth, unspecified trimester, fetus 5',
  'O365999 Maternal care for other known or suspected poor fetal growth, unspecified trimester, other',
  'fetus',
  'O3660X0 Maternal care for excessive fetal growth, unspecified trimester, not applicable or unspecified',
  'O3660X1 Maternal care for excessive fetal growth, unspecified trimester, fetus 1',
  'O3660X2 Maternal care for excessive fetal growth, unspecified trimester, fetus 2',
  'O3660X3 Maternal care for excessive fetal growth, unspecified trimester, fetus 3',
  'O3660X4 Maternal care for excessive fetal growth, unspecified trimester, fetus 4',
  'O3660X5 Maternal care for excessive fetal growth, unspecified trimester, fetus 5',
  'O3660X9 Maternal care for excessive fetal growth, unspecified trimester, other fetus',
  'O3661X0 Maternal care for excessive fetal growth, first trimester, not applicable or unspecified',
  'O3661X1 Maternal care for excessive fetal growth, first trimester, fetus 1',
  'O3661X2 Maternal care for excessive fetal growth, first trimester, fetus 2',
  'O3661X3 Maternal care for excessive fetal growth, first trimester, fetus 3',
  'O3661X4 Maternal care for excessive fetal growth, first trimester, fetus 4',
  'O3661X5 Maternal care for excessive fetal growth, first trimester, fetus 5',
  'O3661X9 Maternal care for excessive fetal growth, first trimester, other fetus',
  'O3662X0 Maternal care for excessive fetal growth, second trimester, not applicable or unspecified',
  'O3662X1 Maternal care for excessive fetal growth, second trimester, fetus 1',
  'O3662X2 Maternal care for excessive fetal growth, second trimester, fetus 2',
  'O3662X3 Maternal care for excessive fetal growth, second trimester, fetus 3',
  'O3662X4 Maternal care for excessive fetal growth, second trimester, fetus 4',
  'O3662X5 Maternal care for excessive fetal growth, second trimester, fetus 5',
  'O3662X9 Maternal care for excessive fetal growth, second trimester, other fetus',
  'O3663X0 Maternal care for excessive fetal growth, third trimester, not applicable or unspecified',
  'O3663X1 Maternal care for excessive fetal growth, third trimester, fetus 1',
  'O3663X2 Maternal care for excessive fetal growth, third trimester, fetus 2',
  'O3663X3 Maternal care for excessive fetal growth, third trimester, fetus 3',
  'O3663X4 Maternal care for excessive fetal growth, third trimester, fetus 4',
  'O3663X5 Maternal care for excessive fetal growth, third trimester, fetus 5',
  'O3663X9 Maternal care for excessive fetal growth, third trimester, other fetus',
  'O3670X0 Maternal care for viable fetus in abdominal pregnancy, unspecified trimester, not applicable or',
  'unspecified',
  'O3670X1 Maternal care for viable fetus in abdominal pregnancy, unspecified trimester, fetus 1',
  'O3670X2 Maternal care for viable fetus in abdominal pregnancy, unspecified trimester, fetus 2',
  'O3670X3 Maternal care for viable fetus in abdominal pregnancy, unspecified trimester, fetus 3',
  'O3670X4 Maternal care for viable fetus in abdominal pregnancy, unspecified trimester, fetus 4',
  'O3670X5 Maternal care for viable fetus in abdominal pregnancy, unspecified trimester, fetus 5',
  'O3670X9 Maternal care for viable fetus in abdominal pregnancy, unspecified trimester, other fetus',
  'O3671X0 Maternal care for viable fetus in abdominal pregnancy, first trimester, not applicable or',
  'unspecified',
  'O3671X1 Maternal care for viable fetus in abdominal pregnancy, first trimester, fetus 1',
  'O3671X2 Maternal care for viable fetus in abdominal pregnancy, first trimester, fetus 2',
  'O3671X3 Maternal care for viable fetus in abdominal pregnancy, first trimester, fetus 3',
  'O3671X4 Maternal care for viable fetus in abdominal pregnancy, first trimester, fetus 4',
  'O3671X5 Maternal care for viable fetus in abdominal pregnancy, first trimester, fetus 5',
  'O3671X9 Maternal care for viable fetus in abdominal pregnancy, first trimester, other fetus',
  'O3672X0 Maternal care for viable fetus in abdominal pregnancy, second trimester, not applicable or',
  'unspecified',
  'O3672X1 Maternal care for viable fetus in abdominal pregnancy, second trimester, fetus 1',
  'O3672X2 Maternal care for viable fetus in abdominal pregnancy, second trimester, fetus 2',
  'O3672X3 Maternal care for viable fetus in abdominal pregnancy, second trimester, fetus 3',
  'O3672X4 Maternal care for viable fetus in abdominal pregnancy, second trimester, fetus 4',
  'O3672X5 Maternal care for viable fetus in abdominal pregnancy, second trimester, fetus 5',
  'O3672X9 Maternal care for viable fetus in abdominal pregnancy, second trimester, other fetus',
  'O3673X0 Maternal care for viable fetus in abdominal pregnancy, third trimester, not applicable or',
  'unspecified',
  'O3673X1 Maternal care for viable fetus in abdominal pregnancy, third trimester, fetus 1',
  'O3673X2 Maternal care for viable fetus in abdominal pregnancy, third trimester, fetus 2',
  'O3673X3 Maternal care for viable fetus in abdominal pregnancy, third trimester, fetus 3',
  'O3673X4 Maternal care for viable fetus in abdominal pregnancy, third trimester, fetus 4',
  'O3673X5 Maternal care for viable fetus in abdominal pregnancy, third trimester, fetus 5',
  'O3673X9 Maternal care for viable fetus in abdominal pregnancy, third trimester, other fetus',
  'O3680X0 Pregnancy with inconclusive fetal viability, not applicable or unspecified',
  'O3680X1 Pregnancy with inconclusive fetal viability, fetus 1',
  'O3680X2 Pregnancy with inconclusive fetal viability, fetus 2',
  'O3680X3 Pregnancy with inconclusive fetal viability, fetus 3',
  'O3680X4 Pregnancy with inconclusive fetal viability, fetus 4',
  'O3680X5 Pregnancy with inconclusive fetal viability, fetus 5',
  'O3680X9 Pregnancy with inconclusive fetal viability, other fetus',
  'O368120 Decreased fetal movements, second trimester, not applicable or unspecified',
  'O368121 Decreased fetal movements, second trimester, fetus 1',
  'O368122 Decreased fetal movements, second trimester, fetus 2',
  'O368123 Decreased fetal movements, second trimester, fetus 3',
  'O368124 Decreased fetal movements, second trimester, fetus 4',
  'O368125 Decreased fetal movements, second trimester, fetus 5',
  'O368129 Decreased fetal movements, second trimester, other fetus',
  'O368130 Decreased fetal movements, third trimester, not applicable or unspecified',
  'O368131 Decreased fetal movements, third trimester, fetus 1',
  'O368132 Decreased fetal movements, third trimester, fetus 2',
  'O368133 Decreased fetal movements, third trimester, fetus 3',
  'O368134 Decreased fetal movements, third trimester, fetus 4',
  'O368135 Decreased fetal movements, third trimester, fetus 5',
  'O368139 Decreased fetal movements, third trimester, other fetus',
  'O368190 Decreased fetal movements, unspecified trimester, not applicable or unspecified',
  'O368191 Decreased fetal movements, unspecified trimester, fetus 1',
  'O368192 Decreased fetal movements, unspecified trimester, fetus 2',
  'O368193 Decreased fetal movements, unspecified trimester, fetus 3',
  'O368194 Decreased fetal movements, unspecified trimester, fetus 4',
  'O368195 Decreased fetal movements, unspecified trimester, fetus 5',
  'O368199 Decreased fetal movements, unspecified trimester, other fetus',
  'O368210 Fetal anemia and thrombocytopenia, first trimester, not applicable or unspecified',
  'O368211 Fetal anemia and thrombocytopenia, first trimester, fetus 1',
  'O368212 Fetal anemia and thrombocytopenia, first trimester, fetus 2',
  'O368213 Fetal anemia and thrombocytopenia, first trimester, fetus 3',
  'O368214 Fetal anemia and thrombocytopenia, first trimester, fetus 4',
  'O368215 Fetal anemia and thrombocytopenia, first trimester, fetus 5',
  'O368219 Fetal anemia and thrombocytopenia, first trimester, other fetus',
  'O368220 Fetal anemia and thrombocytopenia, second trimester, not applicable or unspecified',
  'O368221 Fetal anemia and thrombocytopenia, second trimester, fetus 1',
  'O368222 Fetal anemia and thrombocytopenia, second trimester, fetus 2',
  'O368223 Fetal anemia and thrombocytopenia, second trimester, fetus 3',
  'O368224 Fetal anemia and thrombocytopenia, second trimester, fetus 4',
  'O368225 Fetal anemia and thrombocytopenia, second trimester, fetus 5',
  'O368229 Fetal anemia and thrombocytopenia, second trimester, other fetus',
  'O368230 Fetal anemia and thrombocytopenia, third trimester, not applicable or unspecified',
  'O368231 Fetal anemia and thrombocytopenia, third trimester, fetus 1',
  'O368232 Fetal anemia and thrombocytopenia, third trimester, fetus 2',
  'O368233 Fetal anemia and thrombocytopenia, third trimester, fetus 3',
  'O368234 Fetal anemia and thrombocytopenia, third trimester, fetus 4',
  'O368235 Fetal anemia and thrombocytopenia, third trimester, fetus 5',
  'O368239 Fetal anemia and thrombocytopenia, third trimester, other fetus',
  'O368290 Fetal anemia and thrombocytopenia, unspecified trimester, not applicable or unspecified',
  'O368291 Fetal anemia and thrombocytopenia, unspecified trimester, fetus 1',
  'O368292 Fetal anemia and thrombocytopenia, unspecified trimester, fetus 2',
  'O368293 Fetal anemia and thrombocytopenia, unspecified trimester, fetus 3',
  'O368294 Fetal anemia and thrombocytopenia, unspecified trimester, fetus 4',
  'O368295 Fetal anemia and thrombocytopenia, unspecified trimester, fetus 5',
  'O368299 Fetal anemia and thrombocytopenia, unspecified trimester, other fetus',
  'O368910 Maternal care for other specified fetal problems, first trimester, not applicable or unspecified',
  'O368911 Maternal care for other specified fetal problems, first trimester, fetus 1',
  'O368912 Maternal care for other specified fetal problems, first trimester, fetus 2',
  'O368913 Maternal care for other specified fetal problems, first trimester, fetus 3',
  'O368914 Maternal care for other specified fetal problems, first trimester, fetus 4',
  'O368915 Maternal care for other specified fetal problems, first trimester, fetus 5',
  'O368919 Maternal care for other specified fetal problems, first trimester, other fetus',
  'O368920 Maternal care for other specified fetal problems, second trimester, not applicable or',
  'unspecified',
  'O368921 Maternal care for other specified fetal problems, second trimester, fetus 1',
  'O368922 Maternal care for other specified fetal problems, second trimester, fetus 2',
  'O368923 Maternal care for other specified fetal problems, second trimester, fetus 3',
  'O368924 Maternal care for other specified fetal problems, second trimester, fetus 4',
  'O368925 Maternal care for other specified fetal problems, second trimester, fetus 5',
  'O368929 Maternal care for other specified fetal problems, second trimester, other fetus',
  'O368930 Maternal care for other specified fetal problems, third trimester, not applicable or unspecified',
  'O368931 Maternal care for other specified fetal problems, third trimester, fetus 1',
  'O368932 Maternal care for other specified fetal problems, third trimester, fetus 2',
  'O368933 Maternal care for other specified fetal problems, third trimester, fetus 3',
  'O368934 Maternal care for other specified fetal problems, third trimester, fetus 4',
  'O368935 Maternal care for other specified fetal problems, third trimester, fetus 5',
  'O368939 Maternal care for other specified fetal problems, third trimester, other fetus',
  'O368990 Maternal care for other specified fetal problems, unspecified trimester, not applicable or',
  'unspecified',
  'O368991 Maternal care for other specified fetal problems, unspecified trimester, fetus 1',
  'O368992 Maternal care for other specified fetal problems, unspecified trimester, fetus 2',
  'O368993 Maternal care for other specified fetal problems, unspecified trimester, fetus 3',
  'O368994 Maternal care for other specified fetal problems, unspecified trimester, fetus 4',
  'O368995 Maternal care for other specified fetal problems, unspecified trimester, fetus 5',
  'O368999 Maternal care for other specified fetal problems, unspecified trimester, other fetus',
  'O3690X0 Maternal care for fetal problem, unspecified, unspecified trimester, not applicable or',
  'unspecified',
  'O3690X1 Maternal care for fetal problem, unspecified, unspecified trimester, fetus 1',
  'O3690X2 Maternal care for fetal problem, unspecified, unspecified trimester, fetus 2',
  'O3690X3 Maternal care for fetal problem, unspecified, unspecified trimester, fetus 3',
  'O3690X4 Maternal care for fetal problem, unspecified, unspecified trimester, fetus 4',
  'O3690X5 Maternal care for fetal problem, unspecified, unspecified trimester, fetus 5',
  'O3690X9 Maternal care for fetal problem, unspecified, unspecified trimester, other fetus',
  'O3691X0 Maternal care for fetal problem, unspecified, first trimester, not applicable or unspecified',
  'O3691X1 Maternal care for fetal problem, unspecified, first trimester, fetus 1',
  'O3691X2 Maternal care for fetal problem, unspecified, first trimester, fetus 2',
  'O3691X3 Maternal care for fetal problem, unspecified, first trimester, fetus 3',
  'O3691X4 Maternal care for fetal problem, unspecified, first trimester, fetus 4',
  'O3691X5 Maternal care for fetal problem, unspecified, first trimester, fetus 5',
  'O3691X9 Maternal care for fetal problem, unspecified, first trimester, other fetus',
  'O3692X0 Maternal care for fetal problem, unspecified, second trimester, not applicable or unspecified',
  'O3692X1 Maternal care for fetal problem, unspecified, second trimester, fetus 1',
  'O3692X2 Maternal care for fetal problem, unspecified, second trimester, fetus 2',
  'O3692X3 Maternal care for fetal problem, unspecified, second trimester, fetus 3',
  'O3692X4 Maternal care for fetal problem, unspecified, second trimester, fetus 4',
  'O3692X5 Maternal care for fetal problem, unspecified, second trimester, fetus 5',
  'O3692X9 Maternal care for fetal problem, unspecified, second trimester, other fetus',
  'O3693X0 Maternal care for fetal problem, unspecified, third trimester, not applicable or unspecified',
  'O3693X1 Maternal care for fetal problem, unspecified, third trimester, fetus 1',
  'O3693X2 Maternal care for fetal problem, unspecified, third trimester, fetus 2',
  'O3693X3 Maternal care for fetal problem, unspecified, third trimester, fetus 3',
  'O3693X4 Maternal care for fetal problem, unspecified, third trimester, fetus 4',
  'O3693X5 Maternal care for fetal problem, unspecified, third trimester, fetus 5',
  'O3693X9 Maternal care for fetal problem, unspecified, third trimester, other fetus',
  'O401XX0 Polyhydramnios, first trimester, not applicable or unspecified',
  'O401XX1 Polyhydramnios, first trimester, fetus 1',
  'O401XX2 Polyhydramnios, first trimester, fetus 2',
  'O401XX3 Polyhydramnios, first trimester, fetus 3',
  'O401XX4 Polyhydramnios, first trimester, fetus 4',
  'O401XX5 Polyhydramnios, first trimester, fetus 5',
  'O401XX9 Polyhydramnios, first trimester, other fetus',
  'O402XX0 Polyhydramnios, second trimester, not applicable or unspecified',
  'O402XX1 Polyhydramnios, second trimester, fetus 1',
  'O402XX2 Polyhydramnios, second trimester, fetus 2',
  'O402XX3 Polyhydramnios, second trimester, fetus 3',
  'O402XX4 Polyhydramnios, second trimester, fetus 4',
  'O402XX5 Polyhydramnios, second trimester, fetus 5',
  'O402XX9 Polyhydramnios, second trimester, other fetus',
  'O403XX0 Polyhydramnios, third trimester, not applicable or unspecified',
  'O403XX1 Polyhydramnios, third trimester, fetus 1',
  'O403XX2 Polyhydramnios, third trimester, fetus 2',
  'O403XX3 Polyhydramnios, third trimester, fetus 3',
  'O403XX4 Polyhydramnios, third trimester, fetus 4',
  'O403XX5 Polyhydramnios, third trimester, fetus 5',
  'O403XX9 Polyhydramnios, third trimester, other fetus',
  'O409XX0 Polyhydramnios, unspecified trimester, not applicable or unspecified',
  'O409XX1 Polyhydramnios, unspecified trimester, fetus 1',
  'O409XX2 Polyhydramnios, unspecified trimester, fetus 2',
  'O409XX3 Polyhydramnios, unspecified trimester, fetus 3',
  'O409XX4 Polyhydramnios, unspecified trimester, fetus 4',
  'O409XX5 Polyhydramnios, unspecified trimester, fetus 5',
  'O409XX9 Polyhydramnios, unspecified trimester, other fetus',
  'O4100X0 Oligohydramnios, unspecified trimester, not applicable or unspecified',
  'O4100X1 Oligohydramnios, unspecified trimester, fetus 1',
  'O4100X2 Oligohydramnios, unspecified trimester, fetus 2',
  'O4100X3 Oligohydramnios, unspecified trimester, fetus 3',
  'O4100X4 Oligohydramnios, unspecified trimester, fetus 4',
  'O4100X5 Oligohydramnios, unspecified trimester, fetus 5',
  'O4100X9 Oligohydramnios, unspecified trimester, other fetus',
  'O4101X0 Oligohydramnios, first trimester, not applicable or unspecified',
  'O4101X1 Oligohydramnios, first trimester, fetus 1',
  'O4101X2 Oligohydramnios, first trimester, fetus 2',
  'O4101X3 Oligohydramnios, first trimester, fetus 3',
  'O4101X4 Oligohydramnios, first trimester, fetus 4',
  'O4101X5 Oligohydramnios, first trimester, fetus 5',
  'O4101X9 Oligohydramnios, first trimester, other fetus',
  'O4102X0 Oligohydramnios, second trimester, not applicable or unspecified',
  'O4102X1 Oligohydramnios, second trimester, fetus 1',
  'O4102X2 Oligohydramnios, second trimester, fetus 2',
  'O4102X3 Oligohydramnios, second trimester, fetus 3',
  'O4102X4 Oligohydramnios, second trimester, fetus 4',
  'O4102X5 Oligohydramnios, second trimester, fetus 5',
  'O4102X9 Oligohydramnios, second trimester, other fetus',
  'O4103X0 Oligohydramnios, third trimester, not applicable or unspecified',
  'O4103X1 Oligohydramnios, third trimester, fetus 1',
  'O4103X2 Oligohydramnios, third trimester, fetus 2',
  'O4103X3 Oligohydramnios, third trimester, fetus 3',
  'O4103X4 Oligohydramnios, third trimester, fetus 4',
  'O4103X5 Oligohydramnios, third trimester, fetus 5',
  'O4103X9 Oligohydramnios, third trimester, other fetus',
  'O411010 Infection of amniotic sac and membranes, unspecified, first trimester, not applicable or',
  'unspecified',
  'O411011 Infection of amniotic sac and membranes, unspecified, first trimester, fetus 1',
  'O411012 Infection of amniotic sac and membranes, unspecified, first trimester, fetus 2',
  'O411013 Infection of amniotic sac and membranes, unspecified, first trimester, fetus 3',
  'O411014 Infection of amniotic sac and membranes, unspecified, first trimester, fetus 4',
  'O411015 Infection of amniotic sac and membranes, unspecified, first trimester, fetus 5',
  'O411019 Infection of amniotic sac and membranes, unspecified, first trimester, other fetus',
  'O411020 Infection of amniotic sac and membranes, unspecified, second trimester, not applicable or',
  'unspecified',
  'O411021 Infection of amniotic sac and membranes, unspecified, second trimester, fetus 1',
  'O411022 Infection of amniotic sac and membranes, unspecified, second trimester, fetus 2',
  'O411023 Infection of amniotic sac and membranes, unspecified, second trimester, fetus 3',
  'O411024 Infection of amniotic sac and membranes, unspecified, second trimester, fetus 4',
  'O411025 Infection of amniotic sac and membranes, unspecified, second trimester, fetus 5',
  'O411029 Infection of amniotic sac and membranes, unspecified, second trimester, other fetus',
  'O411030 Infection of amniotic sac and membranes, unspecified, third trimester, not applicable or',
  'unspecified',
  'O411031 Infection of amniotic sac and membranes, unspecified, third trimester, fetus 1',
  'O411032 Infection of amniotic sac and membranes, unspecified, third trimester, fetus 2',
  'O411033 Infection of amniotic sac and membranes, unspecified, third trimester, fetus 3',
  'O411034 Infection of amniotic sac and membranes, unspecified, third trimester, fetus 4',
  'O411035 Infection of amniotic sac and membranes, unspecified, third trimester, fetus 5',
  'O411039 Infection of amniotic sac and membranes, unspecified, third trimester, other fetus',
  'O411090 Infection of amniotic sac and membranes, unspecified, unspecified trimester, not applicable or',
  'unspecified',
  'O411091 Infection of amniotic sac and membranes, unspecified, unspecified trimester, fetus 1',
  'O411092 Infection of amniotic sac and membranes, unspecified, unspecified trimester, fetus 2',
  'O411093 Infection of amniotic sac and membranes, unspecified, unspecified trimester, fetus 3',
  'O411094 Infection of amniotic sac and membranes, unspecified, unspecified trimester, fetus 4',
  'O411095 Infection of amniotic sac and membranes, unspecified, unspecified trimester, fetus 5',
  'O411099 Infection of amniotic sac and membranes, unspecified, unspecified trimester, other fetus',
  'O411210 Chorioamnionitis, first trimester, not applicable or unspecified',
  'O411211 Chorioamnionitis, first trimester, fetus 1',
  'O411212 Chorioamnionitis, first trimester, fetus 2',
  'O411213 Chorioamnionitis, first trimester, fetus 3',
  'O411214 Chorioamnionitis, first trimester, fetus 4',
  'O411215 Chorioamnionitis, first trimester, fetus 5',
  'O411219 Chorioamnionitis, first trimester, other fetus',
  'O411220 Chorioamnionitis, second trimester, not applicable or unspecified',
  'O411221 Chorioamnionitis, second trimester, fetus 1',
  'O411222 Chorioamnionitis, second trimester, fetus 2',
  'O411223 Chorioamnionitis, second trimester, fetus 3',
  'O411224 Chorioamnionitis, second trimester, fetus 4',
  'O411225 Chorioamnionitis, second trimester, fetus 5',
  'O411229 Chorioamnionitis, second trimester, other fetus',
  'O411230 Chorioamnionitis, third trimester, not applicable or unspecified',
  'O411231 Chorioamnionitis, third trimester, fetus 1',
  'O411232 Chorioamnionitis, third trimester, fetus 2',
  'O411233 Chorioamnionitis, third trimester, fetus 3',
  'O411234 Chorioamnionitis, third trimester, fetus 4',
  'O411235 Chorioamnionitis, third trimester, fetus 5',
  'O411239 Chorioamnionitis, third trimester, other fetus',
  'O411290 Chorioamnionitis, unspecified trimester, not applicable or unspecified',
  'O411291 Chorioamnionitis, unspecified trimester, fetus 1',
  'O411292 Chorioamnionitis, unspecified trimester, fetus 2',
  'O411293 Chorioamnionitis, unspecified trimester, fetus 3',
  'O411294 Chorioamnionitis, unspecified trimester, fetus 4',
  'O411295 Chorioamnionitis, unspecified trimester, fetus 5',
  'O411299 Chorioamnionitis, unspecified trimester, other fetus',
  'O411410 Placentitis, first trimester, not applicable or unspecified',
  'O411411 Placentitis, first trimester, fetus 1',
  'O411412 Placentitis, first trimester, fetus 2',
  'O411413 Placentitis, first trimester, fetus 3',
  'O411414 Placentitis, first trimester, fetus 4',
  'O411415 Placentitis, first trimester, fetus 5',
  'O411419 Placentitis, first trimester, other fetus',
  'O411420 Placentitis, second trimester, not applicable or unspecified',
  'O411421 Placentitis, second trimester, fetus 1',
  'O411422 Placentitis, second trimester, fetus 2',
  'O411423 Placentitis, second trimester, fetus 3',
  'O411424 Placentitis, second trimester, fetus 4',
  'O411425 Placentitis, second trimester, fetus 5',
  'O411429 Placentitis, second trimester, other fetus',
  'O411430 Placentitis, third trimester, not applicable or unspecified',
  'O411431 Placentitis, third trimester, fetus 1',
  'O411432 Placentitis, third trimester, fetus 2',
  'O411433 Placentitis, third trimester, fetus 3',
  'O411434 Placentitis, third trimester, fetus 4',
  'O411435 Placentitis, third trimester, fetus 5',
  'O411439 Placentitis, third trimester, other fetus',
  'O411490 Placentitis, unspecified trimester, not applicable or unspecified',
  'O411491 Placentitis, unspecified trimester, fetus 1',
  'O411492 Placentitis, unspecified trimester, fetus 2',
  'O411493 Placentitis, unspecified trimester, fetus 3',
  'O411494 Placentitis, unspecified trimester, fetus 4',
  'O411495 Placentitis, unspecified trimester, fetus 5',
  'O411499 Placentitis, unspecified trimester, other fetus',
  'O418X10 Other specified disorders of amniotic fluid and membranes, first trimester, not applicable or',
  'unspecified',
  'O418X11 Other specified disorders of amniotic fluid and membranes, first trimester, fetus 1',
  'O418X12 Other specified disorders of amniotic fluid and membranes, first trimester, fetus 2',
  'O418X13 Other specified disorders of amniotic fluid and membranes, first trimester, fetus 3',
  'O418X14 Other specified disorders of amniotic fluid and membranes, first trimester, fetus 4',
  'O418X15 Other specified disorders of amniotic fluid and membranes, first trimester, fetus 5',
  'O418X19 Other specified disorders of amniotic fluid and membranes, first trimester, other fetus',
  'O418X20 Other specified disorders of amniotic fluid and membranes, second trimester, not applicable',
  'or unspecified',
  'O418X21 Other specified disorders of amniotic fluid and membranes, second trimester, fetus 1',
  'O418X22 Other specified disorders of amniotic fluid and membranes, second trimester, fetus 2',
  'O418X23 Other specified disorders of amniotic fluid and membranes, second trimester, fetus 3',
  'O418X24 Other specified disorders of amniotic fluid and membranes, second trimester, fetus 4',
  'O418X25 Other specified disorders of amniotic fluid and membranes, second trimester, fetus 5',
  'O418X29 Other specified disorders of amniotic fluid and membranes, second trimester, other fetus',
  'O418X30 Other specified disorders of amniotic fluid and membranes, third trimester, not applicable or',
  'unspecified',
  'O418X31 Other specified disorders of amniotic fluid and membranes, third trimester, fetus 1',
  'O418X32 Other specified disorders of amniotic fluid and membranes, third trimester, fetus 2',
  'O418X33 Other specified disorders of amniotic fluid and membranes, third trimester, fetus 3',
  'O418X34 Other specified disorders of amniotic fluid and membranes, third trimester, fetus 4',
  'O418X35 Other specified disorders of amniotic fluid and membranes, third trimester, fetus 5',
  'O418X39 Other specified disorders of amniotic fluid and membranes, third trimester, other fetus',
  'O418X90 Other specified disorders of amniotic fluid and membranes, unspecified trimester, not',
  'applicable or unspecified',
  'O418X91 Other specified disorders of amniotic fluid and membranes, unspecified trimester, fetus 1',
  'O418X92 Other specified disorders of amniotic fluid and membranes, unspecified trimester, fetus 2',
  'O418X93 Other specified disorders of amniotic fluid and membranes, unspecified trimester, fetus 3',
  'O418X94 Other specified disorders of amniotic fluid and membranes, unspecified trimester, fetus 4',
  'O418X95 Other specified disorders of amniotic fluid and membranes, unspecified trimester, fetus 5',
  'O418X99 Other specified disorders of amniotic fluid and membranes, unspecified trimester, other fetus',
  'O4190X0 Disorder of amniotic fluid and membranes, unspecified, unspecified trimester, not applicable',
  'or unspecified',
  'O4190X1 Disorder of amniotic fluid and membranes, unspecified, unspecified trimester, fetus 1',
  'O4190X2 Disorder of amniotic fluid and membranes, unspecified, unspecified trimester, fetus 2',
  'O4190X3 Disorder of amniotic fluid and membranes, unspecified, unspecified trimester, fetus 3',
  'O4190X4 Disorder of amniotic fluid and membranes, unspecified, unspecified trimester, fetus 4',
  'O4190X5 Disorder of amniotic fluid and membranes, unspecified, unspecified trimester, fetus 5',
  'O4190X9 Disorder of amniotic fluid and membranes, unspecified, unspecified trimester, other fetus',
  'O4191X0 Disorder of amniotic fluid and membranes, unspecified, first trimester, not applicable or',
  'unspecified',
  'O4191X1 Disorder of amniotic fluid and membranes, unspecified, first trimester, fetus 1',
  'O4191X2 Disorder of amniotic fluid and membranes, unspecified, first trimester, fetus 2',
  'O4191X3 Disorder of amniotic fluid and membranes, unspecified, first trimester, fetus 3',
  'O4191X4 Disorder of amniotic fluid and membranes, unspecified, first trimester, fetus 4',
  'O4191X5 Disorder of amniotic fluid and membranes, unspecified, first trimester, fetus 5',
  'O4191X9 Disorder of amniotic fluid and membranes, unspecified, first trimester, other fetus',
  'O4192X0 Disorder of amniotic fluid and membranes, unspecified, second trimester, not applicable or',
  'unspecified',
  'O4192X1 Disorder of amniotic fluid and membranes, unspecified, second trimester, fetus 1',
  'O4192X2 Disorder of amniotic fluid and membranes, unspecified, second trimester, fetus 2',
  'O4192X3 Disorder of amniotic fluid and membranes, unspecified, second trimester, fetus 3',
  'O4192X4 Disorder of amniotic fluid and membranes, unspecified, second trimester, fetus 4',
  'O4192X5 Disorder of amniotic fluid and membranes, unspecified, second trimester, fetus 5',
  'O4192X9 Disorder of amniotic fluid and membranes, unspecified, second trimester, other fetus',
  'O4193X0 Disorder of amniotic fluid and membranes, unspecified, third trimester, not applicable or',
  'unspecified',
  'O4193X1 Disorder of amniotic fluid and membranes, unspecified, third trimester, fetus 1',
  'O4193X2 Disorder of amniotic fluid and membranes, unspecified, third trimester, fetus 2',
  'O4193X3 Disorder of amniotic fluid and membranes, unspecified, third trimester, fetus 3',
  'O4193X4 Disorder of amniotic fluid and membranes, unspecified, third trimester, fetus 4',
  'O4193X5 Disorder of amniotic fluid and membranes, unspecified, third trimester, fetus 5',
  'O4193X9 Disorder of amniotic fluid and membranes, unspecified, third trimester, other fetus',
  'O4200 Premature rupture of membranes, onset of labor within 24 hours of rupture, unspecified weeks',
  'of gestation',
  'O42011 Preterm premature rupture of membranes, onset of labor within 24 hours of rupture, first',
  'trimester',
  'O42012 Preterm premature rupture of membranes, onset of labor within 24 hours of rupture, second',
  'trimester',
  'O42013 Preterm premature rupture of membranes, onset of labor within 24 hours of rupture, third',
  'trimester',
  'O42019 Preterm premature rupture of membranes, onset of labor within 24 hours of rupture,',
  'unspecified trimester',
  'O4202 Full-term premature rupture of membranes, onset of labor within 24 hours of rupture',
  'O4210 Premature rupture of membranes, onset of labor more than 24 hours following rupture,',
  'unspecified weeks of gestation',
  'O42111 Preterm premature rupture of membranes, onset of labor more than 24 hours following',
  'rupture, first trimester',
  'O42112 Preterm premature rupture of membranes, onset of labor more than 24 hours following',
  'rupture, second trimester',
  'O42113 Preterm premature rupture of membranes, onset of labor more than 24 hours following',
  'rupture, third trimester',
  'O42119 Preterm premature rupture of membranes, onset of labor more than 24 hours following',
  'rupture, unspecified trimester',
  'O4212 Full-term premature rupture of membranes, onset of labor more than 24 hours following',
  'rupture',
  'O4290 Premature rupture of membranes, unspecified as to length of time between rupture and onset',
  'of labor, unspecified weeks of gestation',
  'O42911 Preterm premature rupture of membranes, unspecified as to length of time between rupture',
  'and onset of labor, first trimester',
  'O42912 Preterm premature rupture of membranes, unspecified as to length of time between rupture',
  'and onset of labor, second trimester',
  'O42913 Preterm premature rupture of membranes, unspecified as to length of time between rupture',
  'and onset of labor, third trimester',
  'O42919 Preterm premature rupture of membranes, unspecified as to length of time between rupture',
  'and onset of labor, unspecified trimester',
  'O4292 Full-term premature rupture of membranes, unspecified as to length of time between rupture',
  'and onset of labor',
  'O43011 Fetomaternal placental transfusion syndrome, first trimester',
  'O43012 Fetomaternal placental transfusion syndrome, second trimester',
  'O43013 Fetomaternal placental transfusion syndrome, third trimester',
  'O43019 Fetomaternal placental transfusion syndrome, unspecified trimester',
  'O43021 Fetus-to-fetus placental transfusion syndrome, first trimester',
  'O43022 Fetus-to-fetus placental transfusion syndrome, second trimester',
  'O43023 Fetus-to-fetus placental transfusion syndrome, third trimester',
  'O43029 Fetus-to-fetus placental transfusion syndrome, unspecified trimester',
  'O43101 Malformation of placenta, unspecified, first trimester',
  'O43102 Malformation of placenta, unspecified, second trimester',
  'O43103 Malformation of placenta, unspecified, third trimester',
  'O43109 Malformation of placenta, unspecified, unspecified trimester',
  'O43111 Circumvallate placenta, first trimester',
  'O43112 Circumvallate placenta, second trimester',
  'O43113 Circumvallate placenta, third trimester',
  'O43119 Circumvallate placenta, unspecified trimester',
  'O43121 Velamentous insertion of umbilical cord, first trimester',
  'O43122 Velamentous insertion of umbilical cord, second trimester',
  'O43123 Velamentous insertion of umbilical cord, third trimester',
  'O43129 Velamentous insertion of umbilical cord, unspecified trimester',
  'O43191 Other malformation of placenta, first trimester',
  'O43192 Other malformation of placenta, second trimester',
  'O43193 Other malformation of placenta, third trimester',
  'O43199 Other malformation of placenta, unspecified trimester',
  'O43211 Placenta accreta, first trimester',
  'O43212 Placenta accreta, second trimester',
  'O43213 Placenta accreta, third trimester',
  'O43219 Placenta accreta, unspecified trimester',
  'O43221 Placenta increta, first trimester',
  'O43222 Placenta increta, second trimester',
  'O43223 Placenta increta, third trimester',
  'O43229 Placenta increta, unspecified trimester',
  'O43231 Placenta percreta, first trimester',
  'O43232 Placenta percreta, second trimester',
  'O43233 Placenta percreta, third trimester',
  'O43239 Placenta percreta, unspecified trimester',
  'O43811 Placental infarction, first trimester',
  'O43812 Placental infarction, second trimester',
  'O43813 Placental infarction, third trimester',
  'O43819 Placental infarction, unspecified trimester',
  'O43891 Other placental disorders, first trimester',
  'O43892 Other placental disorders, second trimester',
  'O43893 Other placental disorders, third trimester',
  'O43899 Other placental disorders, unspecified trimester',
  'O4390 Unspecified placental disorder, unspecified trimester',
  'O4391 Unspecified placental disorder, first trimester',
  'O4392 Unspecified placental disorder, second trimester',
  'O4393 Unspecified placental disorder, third trimester',
  'O4400 Complete placenta previa NOS or without hemorrhage, unspecified trimester',
  'O4401 Complete placenta previa NOS or without hemorrhage, first trimester',
  'O4402 Complete placenta previa NOS or without hemorrhage, second trimester',
  'O4403 Complete placenta previa NOS or without hemorrhage, third trimester',
  'O4410 Complete placenta previa with hemorrhage, unspecified trimester',
  'O4411 Complete placenta previa with hemorrhage, first trimester',
  'O4412 Complete placenta previa with hemorrhage, second trimester',
  'O4413 Complete placenta previa with hemorrhage, third trimester',
  'O4420 Partial placenta previa NOS or without hemorrhage, unspecified trimester',
  'O4421 Partial placenta previa NOS or without hemorrhage, first trimester',
  'O4422 Partial placenta previa NOS or without hemorrhage, second trimester',
  'O4423 Partial placenta previa NOS or without hemorrhage, third trimester',
  'O4430 Partial placenta previa with hemorrhage, unspecified trimester',
  'O4431 Partial placenta previa with hemorrhage, first trimester',
  'O4432 Partial placenta previa with hemorrhage, second trimester',
  'O4433 Partial placenta previa with hemorrhage, third trimester',
  'O4440 Low lying placenta NOS or without hemorrhage, unspecified trimester',
  'O4441 Low lying placenta NOS or without hemorrhage, first trimester',
  'O4442 Low lying placenta NOS or without hemorrhage, second trimester',
  'O4443 Low lying placenta NOS or without hemorrhage, third trimester',
  'O4450 Low lying placenta with hemorrhage, unspecified trimester',
  'O4451 Low lying placenta with hemorrhage, first trimester',
  'O4452 Low lying placenta with hemorrhage, second trimester',
  'O4453 Low lying placenta with hemorrhage, third trimester',
  'O45001 Premature separation of placenta with coagulation defect, unspecified, first trimester',
  'O45002 Premature separation of placenta with coagulation defect, unspecified, second trimester',
  'O45003 Premature separation of placenta with coagulation defect, unspecified, third trimester',
  'O45009 Premature separation of placenta with coagulation defect, unspecified, unspecified trimester',
  'O45011 Premature separation of placenta with afibrinogenemia, first trimester',
  'O45012 Premature separation of placenta with afibrinogenemia, second trimester',
  'O45013 Premature separation of placenta with afibrinogenemia, third trimester',
  'O45019 Premature separation of placenta with afibrinogenemia, unspecified trimester',
  'O45021 Premature separation of placenta with disseminated intravascular coagulation, first trimester',
  'O45022 Premature separation of placenta with disseminated intravascular coagulation, second',
  'trimester',
  'O45023 Premature separation of placenta with disseminated intravascular coagulation, third trimester',
  'O45029 Premature separation of placenta with disseminated intravascular coagulation, unspecified',
  'trimester',
  'O45091 Premature separation of placenta with other coagulation defect, first trimester',
  'O45092 Premature separation of placenta with other coagulation defect, second trimester',
  'O45093 Premature separation of placenta with other coagulation defect, third trimester',
  'O45099 Premature separation of placenta with other coagulation defect, unspecified trimester',
  'O458X1 Other premature separation of placenta, first trimester',
  'O458X2 Other premature separation of placenta, second trimester',
  'O458X3 Other premature separation of placenta, third trimester',
  'O458X9 Other premature separation of placenta, unspecified trimester',
  'O4590 Premature separation of placenta, unspecified, unspecified trimester',
  'O4591 Premature separation of placenta, unspecified, first trimester',
  'O4592 Premature separation of placenta, unspecified, second trimester',
  'O4593 Premature separation of placenta, unspecified, third trimester',
  'O46001 Antepartum hemorrhage with coagulation defect, unspecified, first trimester',
  'O46002 Antepartum hemorrhage with coagulation defect, unspecified, second trimester',
  'O46003 Antepartum hemorrhage with coagulation defect, unspecified, third trimester',
  'O46009 Antepartum hemorrhage with coagulation defect, unspecified, unspecified trimester',
  'O46011 Antepartum hemorrhage with afibrinogenemia, first trimester',
  'O46012 Antepartum hemorrhage with afibrinogenemia, second trimester',
  'O46013 Antepartum hemorrhage with afibrinogenemia, third trimester',
  'O46019 Antepartum hemorrhage with afibrinogenemia, unspecified trimester',
  'O46021 Antepartum hemorrhage with disseminated intravascular coagulation, first trimester',
  'O46022 Antepartum hemorrhage with disseminated intravascular coagulation, second trimester',
  'O46023 Antepartum hemorrhage with disseminated intravascular coagulation, third trimester',
  'O46029 Antepartum hemorrhage with disseminated intravascular coagulation, unspecified trimester',
  'O46091 Antepartum hemorrhage with other coagulation defect, first trimester',
  'O46092 Antepartum hemorrhage with other coagulation defect, second trimester',
  'O46093 Antepartum hemorrhage with other coagulation defect, third trimester',
  'O46099 Antepartum hemorrhage with other coagulation defect, unspecified trimester',
  'O468X1 Other antepartum hemorrhage, first trimester',
  'O468X2 Other antepartum hemorrhage, second trimester',
  'O468X3 Other antepartum hemorrhage, third trimester',
  'O468X9 Other antepartum hemorrhage, unspecified trimester',
  'O4690 Antepartum hemorrhage, unspecified, unspecified trimester',
  'O4691 Antepartum hemorrhage, unspecified, first trimester',
  'O4692 Antepartum hemorrhage, unspecified, second trimester',
  'O4693 Antepartum hemorrhage, unspecified, third trimester',
  'O4700 False labor before 37 completed weeks of gestation, unspecified trimester',
  'O4702 False labor before 37 completed weeks of gestation, second trimester',
  'O4703 False labor before 37 completed weeks of gestation, third trimester',
  'O471 False labor at or after 37 completed weeks of gestation',
  'O479 False labor, unspecified',
  'O480 Post-term pregnancy',
  'O481 Prolonged pregnancy',
  'O6000 Preterm labor without delivery, unspecified trimester',
  'O6002 Preterm labor without delivery, second trimester',
  'O6003 Preterm labor without delivery, third trimester',
  'O6010X0 Preterm labor with preterm delivery, unspecified trimester, not applicable or unspecified',
  'O6010X1 Preterm labor with preterm delivery, unspecified trimester, fetus 1',
  'O6010X2 Preterm labor with preterm delivery, unspecified trimester, fetus 2',
  'O6010X3 Preterm labor with preterm delivery, unspecified trimester, fetus 3',
  'O6010X4 Preterm labor with preterm delivery, unspecified trimester, fetus 4',
  'O6010X5 Preterm labor with preterm delivery, unspecified trimester, fetus 5',
  'O6010X9 Preterm labor with preterm delivery, unspecified trimester, other fetus',
  'O6012X0 Preterm labor second trimester with preterm delivery second trimester, not applicable or',
  'unspecified',
  'O6012X1 Preterm labor second trimester with preterm delivery second trimester, fetus 1',
  'O6012X2 Preterm labor second trimester with preterm delivery second trimester, fetus 2',
  'O6012X3 Preterm labor second trimester with preterm delivery second trimester, fetus 3',
  'O6012X4 Preterm labor second trimester with preterm delivery second trimester, fetus 4',
  'O6012X5 Preterm labor second trimester with preterm delivery second trimester, fetus 5',
  'O6012X9 Preterm labor second trimester with preterm delivery second trimester, other fetus',
  'O6013X0 Preterm labor second trimester with preterm delivery third trimester, not applicable or',
  'unspecified',
  'O6013X1 Preterm labor second trimester with preterm delivery third trimester, fetus 1',
  'O6013X2 Preterm labor second trimester with preterm delivery third trimester, fetus 2',
  'O6013X3 Preterm labor second trimester with preterm delivery third trimester, fetus 3',
  'O6013X4 Preterm labor second trimester with preterm delivery third trimester, fetus 4',
  'O6013X5 Preterm labor second trimester with preterm delivery third trimester, fetus 5',
  'O6013X9 Preterm labor second trimester with preterm delivery third trimester, other fetus',
  'O6014X0 Preterm labor third trimester with preterm delivery third trimester, not applicable or',
  'unspecified',
  'O6014X1 Preterm labor third trimester with preterm delivery third trimester, fetus 1',
  'O6014X2 Preterm labor third trimester with preterm delivery third trimester, fetus 2',
  'O6014X3 Preterm labor third trimester with preterm delivery third trimester, fetus 3',
  'O6014X4 Preterm labor third trimester with preterm delivery third trimester, fetus 4',
  'O6014X5 Preterm labor third trimester with preterm delivery third trimester, fetus 5',
  'O6014X9 Preterm labor third trimester with preterm delivery third trimester, other fetus',
  'O6020X0 Term delivery with preterm labor, unspecified trimester, not applicable or unspecified',
  'O6020X1 Term delivery with preterm labor, unspecified trimester, fetus 1',
  'O6020X2 Term delivery with preterm labor, unspecified trimester, fetus 2',
  'O6020X3 Term delivery with preterm labor, unspecified trimester, fetus 3',
  'O6020X4 Term delivery with preterm labor, unspecified trimester, fetus 4',
  'O6020X5 Term delivery with preterm labor, unspecified trimester, fetus 5',
  'O6020X9 Term delivery with preterm labor, unspecified trimester, other fetus',
  'O6022X0 Term delivery with preterm labor, second trimester, not applicable or unspecified',
  'O6022X1 Term delivery with preterm labor, second trimester, fetus 1',
  'O6022X2 Term delivery with preterm labor, second trimester, fetus 2',
  'O6022X3 Term delivery with preterm labor, second trimester, fetus 3',
  'O6022X4 Term delivery with preterm labor, second trimester, fetus 4',
  'O6022X5 Term delivery with preterm labor, second trimester, fetus 5',
  'O6022X9 Term delivery with preterm labor, second trimester, other fetus',
  'O6023X0 Term delivery with preterm labor, third trimester, not applicable or unspecified',
  'O6023X1 Term delivery with preterm labor, third trimester, fetus 1',
  'O6023X2 Term delivery with preterm labor, third trimester, fetus 2',
  'O6023X3 Term delivery with preterm labor, third trimester, fetus 3',
  'O6023X4 Term delivery with preterm labor, third trimester, fetus 4',
  'O6023X5 Term delivery with preterm labor, third trimester, fetus 5',
  'O6023X9 Term delivery with preterm labor, third trimester, other fetus',
  'O610 Failed medical induction of labor',
  'O611 Failed instrumental induction of labor',
  'O618 Other failed induction of labor',
  'O619 Failed induction of labor, unspecified',
  'O620 Primary inadequate contractions',
  'O621 Secondary uterine inertia',
  'O622 Other uterine inertia',
  'O623 Precipitate labor',
  'O624 Hypertonic, incoordinate, and prolonged uterine contractions',
  'O628 Other abnormalities of forces of labor',
  'O629 Abnormality of forces of labor, unspecified',
  'O630 Prolonged first stage (of labor)',
  'O631 Prolonged second stage (of labor)',
  'O632 Delayed delivery of second twin, triplet, etc.',
  'O639 Long labor, unspecified',
  'O640XX0 Obstructed labor due to incomplete rotation of fetal head, not applicable or unspecified',
  'O640XX1 Obstructed labor due to incomplete rotation of fetal head, fetus 1',
  'O640XX2 Obstructed labor due to incomplete rotation of fetal head, fetus 2',
  'O640XX3 Obstructed labor due to incomplete rotation of fetal head, fetus 3',
  'O640XX4 Obstructed labor due to incomplete rotation of fetal head, fetus 4',
  'O640XX5 Obstructed labor due to incomplete rotation of fetal head, fetus 5',
  'O640XX9 Obstructed labor due to incomplete rotation of fetal head, other fetus',
  'O641XX0 Obstructed labor due to breech presentation, not applicable or unspecified',
  'O641XX1 Obstructed labor due to breech presentation, fetus 1',
  'O641XX2 Obstructed labor due to breech presentation, fetus 2',
  'O641XX3 Obstructed labor due to breech presentation, fetus 3',
  'O641XX4 Obstructed labor due to breech presentation, fetus 4',
  'O641XX5 Obstructed labor due to breech presentation, fetus 5',
  'O641XX9 Obstructed labor due to breech presentation, other fetus',
  'O642XX0 Obstructed labor due to face presentation, not applicable or unspecified',
  'O642XX1 Obstructed labor due to face presentation, fetus 1',
  'O642XX2 Obstructed labor due to face presentation, fetus 2',
  'O642XX3 Obstructed labor due to face presentation, fetus 3',
  'O642XX4 Obstructed labor due to face presentation, fetus 4',
  'O642XX5 Obstructed labor due to face presentation, fetus 5',
  'O642XX9 Obstructed labor due to face presentation, other fetus',
  'O643XX0 Obstructed labor due to brow presentation, not applicable or unspecified',
  'O643XX1 Obstructed labor due to brow presentation, fetus 1',
  'O643XX2 Obstructed labor due to brow presentation, fetus 2',
  'O643XX3 Obstructed labor due to brow presentation, fetus 3',
  'O643XX4 Obstructed labor due to brow presentation, fetus 4',
  'O643XX5 Obstructed labor due to brow presentation, fetus 5',
  'O643XX9 Obstructed labor due to brow presentation, other fetus',
  'O644XX0 Obstructed labor due to shoulder presentation, not applicable or unspecified',
  'O644XX1 Obstructed labor due to shoulder presentation, fetus 1',
  'O644XX2 Obstructed labor due to shoulder presentation, fetus 2',
  'O644XX3 Obstructed labor due to shoulder presentation, fetus 3',
  'O644XX4 Obstructed labor due to shoulder presentation, fetus 4',
  'O644XX5 Obstructed labor due to shoulder presentation, fetus 5',
  'O644XX9 Obstructed labor due to shoulder presentation, other fetus',
  'O645XX0 Obstructed labor due to compound presentation, not applicable or unspecified',
  'O645XX1 Obstructed labor due to compound presentation, fetus 1',
  'O645XX2 Obstructed labor due to compound presentation, fetus 2',
  'O645XX3 Obstructed labor due to compound presentation, fetus 3',
  'O645XX4 Obstructed labor due to compound presentation, fetus 4',
  'O645XX5 Obstructed labor due to compound presentation, fetus 5',
  'O645XX9 Obstructed labor due to compound presentation, other fetus',
  'O648XX0 Obstructed labor due to other malposition and malpresentation, not applicable or unspecified',
  'O648XX1 Obstructed labor due to other malposition and malpresentation, fetus 1',
  'O648XX2 Obstructed labor due to other malposition and malpresentation, fetus 2',
  'O648XX3 Obstructed labor due to other malposition and malpresentation, fetus 3',
  'O648XX4 Obstructed labor due to other malposition and malpresentation, fetus 4',
  'O648XX5 Obstructed labor due to other malposition and malpresentation, fetus 5',
  'O648XX9 Obstructed labor due to other malposition and malpresentation, other fetus',
  'O649XX0 Obstructed labor due to malposition and malpresentation, unspecified, not applicable or',
  'unspecified',
  'O649XX1 Obstructed labor due to malposition and malpresentation, unspecified, fetus 1',
  'O649XX2 Obstructed labor due to malposition and malpresentation, unspecified, fetus 2',
  'O649XX3 Obstructed labor due to malposition and malpresentation, unspecified, fetus 3',
  'O649XX4 Obstructed labor due to malposition and malpresentation, unspecified, fetus 4',
  'O649XX5 Obstructed labor due to malposition and malpresentation, unspecified, fetus 5',
  'O649XX9 Obstructed labor due to malposition and malpresentation, unspecified, other fetus',
  'O650 Obstructed labor due to deformed pelvis',
  'O651 Obstructed labor due to generally contracted pelvis',
  'O652 Obstructed labor due to pelvic inlet contraction',
  'O653 Obstructed labor due to pelvic outlet and mid-cavity contraction',
  'O654 Obstructed labor due to fetopelvic disproportion, unspecified',
  'O655 Obstructed labor due to abnormality of maternal pelvic organs',
  'O658 Obstructed labor due to other maternal pelvic abnormalities',
  'O659 Obstructed labor due to maternal pelvic abnormality, unspecified',
  'O660 Obstructed labor due to shoulder dystocia',
  'O661 Obstructed labor due to locked twins',
  'O662 Obstructed labor due to unusually large fetus',
  'O663 Obstructed labor due to other abnormalities of fetus',
  'O6640 Failed trial of labor, unspecified',
  'O6641 Failed attempted vaginal birth after previous cesarean delivery',
  'O665 Attempted application of vacuum extractor and forceps',
  'O666 Obstructed labor due to other multiple fetuses',
  'O668 Other specified obstructed labor',
  'O669 Obstructed labor, unspecified',
  'O670 Intrapartum hemorrhage with coagulation defect',
  'O678 Other intrapartum hemorrhage',
  'O679 Intrapartum hemorrhage, unspecified',
  'O68 Labor and delivery complicated by abnormality of fetal acid-base balance',
  'O690XX0 Labor and delivery complicated by prolapse of cord, not applicable or unspecified',
  'O690XX1 Labor and delivery complicated by prolapse of cord, fetus 1',
  'O690XX2 Labor and delivery complicated by prolapse of cord, fetus 2',
  'O690XX3 Labor and delivery complicated by prolapse of cord, fetus 3',
  'O690XX4 Labor and delivery complicated by prolapse of cord, fetus 4',
  'O690XX5 Labor and delivery complicated by prolapse of cord, fetus 5',
  'O690XX9 Labor and delivery complicated by prolapse of cord, other fetus',
  'O691XX0 Labor and delivery complicated by cord around neck, with compression, not applicable or',
  'unspecified',
  'O691XX1 Labor and delivery complicated by cord around neck, with compression, fetus 1',
  'O691XX2 Labor and delivery complicated by cord around neck, with compression, fetus 2',
  'O691XX3 Labor and delivery complicated by cord around neck, with compression, fetus 3',
  'O691XX4 Labor and delivery complicated by cord around neck, with compression, fetus 4',
  'O691XX5 Labor and delivery complicated by cord around neck, with compression, fetus 5',
  'O691XX9 Labor and delivery complicated by cord around neck, with compression, other fetus',
  'O692XX0 Labor and delivery complicated by other cord entanglement, with compression, not applicable',
  'or unspecified',
  'O692XX1 Labor and delivery complicated by other cord entanglement, with compression, fetus 1',
  'O692XX2 Labor and delivery complicated by other cord entanglement, with compression, fetus 2',
  'O692XX3 Labor and delivery complicated by other cord entanglement, with compression, fetus 3',
  'O692XX4 Labor and delivery complicated by other cord entanglement, with compression, fetus 4',
  'O692XX5 Labor and delivery complicated by other cord entanglement, with compression, fetus 5',
  'O692XX9 Labor and delivery complicated by other cord entanglement, with compression, other fetus',
  'O693XX0 Labor and delivery complicated by short cord, not applicable or unspecified',
  'O693XX1 Labor and delivery complicated by short cord, fetus 1',
  'O693XX2 Labor and delivery complicated by short cord, fetus 2',
  'O693XX3 Labor and delivery complicated by short cord, fetus 3',
  'O693XX4 Labor and delivery complicated by short cord, fetus 4',
  'O693XX5 Labor and delivery complicated by short cord, fetus 5',
  'O693XX9 Labor and delivery complicated by short cord, other fetus',
  'O694XX0 Labor and delivery complicated by vasa previa, not applicable or unspecified',
  'O694XX1 Labor and delivery complicated by vasa previa, fetus 1',
  'O694XX2 Labor and delivery complicated by vasa previa, fetus 2',
  'O694XX3 Labor and delivery complicated by vasa previa, fetus 3',
  'O694XX4 Labor and delivery complicated by vasa previa, fetus 4',
  'O694XX5 Labor and delivery complicated by vasa previa, fetus 5',
  'O694XX9 Labor and delivery complicated by vasa previa, other fetus',
  'O695XX0 Labor and delivery complicated by vascular lesion of cord, not applicable or unspecified',
  'O695XX1 Labor and delivery complicated by vascular lesion of cord, fetus 1',
  'O695XX2 Labor and delivery complicated by vascular lesion of cord, fetus 2',
  'O695XX3 Labor and delivery complicated by vascular lesion of cord, fetus 3',
  'O695XX4 Labor and delivery complicated by vascular lesion of cord, fetus 4',
  'O695XX5 Labor and delivery complicated by vascular lesion of cord, fetus 5',
  'O695XX9 Labor and delivery complicated by vascular lesion of cord, other fetus',
  'O6981X0 Labor and delivery complicated by cord around neck, without compression, not applicable or',
  'unspecified',
  'O6981X1 Labor and delivery complicated by cord around neck, without compression, fetus 1',
  'O6981X2 Labor and delivery complicated by cord around neck, without compression, fetus 2',
  'O6981X3 Labor and delivery complicated by cord around neck, without compression, fetus 3',
  'O6981X4 Labor and delivery complicated by cord around neck, without compression, fetus 4',
  'O6981X5 Labor and delivery complicated by cord around neck, without compression, fetus 5',
  'O6981X9 Labor and delivery complicated by cord around neck, without compression, other fetus',
  'O6982X0 Labor and delivery complicated by other cord entanglement, without compression, not',
  'applicable or unspecified',
  'O6982X1 Labor and delivery complicated by other cord entanglement, without compression, fetus 1',
  'O6982X2 Labor and delivery complicated by other cord entanglement, without compression, fetus 2',
  'O6982X3 Labor and delivery complicated by other cord entanglement, without compression, fetus 3',
  'O6982X4 Labor and delivery complicated by other cord entanglement, without compression, fetus 4',
  'O6982X5 Labor and delivery complicated by other cord entanglement, without compression, fetus 5',
  'O6982X9 Labor and delivery complicated by other cord entanglement, without compression, other fetus',
  'O6989X0 Labor and delivery complicated by other cord complications, not applicable or unspecified',
  'O6989X1 Labor and delivery complicated by other cord complications, fetus 1',
  'O6989X2 Labor and delivery complicated by other cord complications, fetus 2',
  'O6989X3 Labor and delivery complicated by other cord complications, fetus 3',
  'O6989X4 Labor and delivery complicated by other cord complications, fetus 4',
  'O6989X5 Labor and delivery complicated by other cord complications, fetus 5',
  'O6989X9 Labor and delivery complicated by other cord complications, other fetus',
  'O699XX0 Labor and delivery complicated by cord complication, unspecified, not applicable or',
  'unspecified',
  'O699XX1 Labor and delivery complicated by cord complication, unspecified, fetus 1',
  'O699XX2 Labor and delivery complicated by cord complication, unspecified, fetus 2',
  'O699XX3 Labor and delivery complicated by cord complication, unspecified, fetus 3',
  'O699XX4 Labor and delivery complicated by cord complication, unspecified, fetus 4',
  'O699XX5 Labor and delivery complicated by cord complication, unspecified, fetus 5',
  'O699XX9 Labor and delivery complicated by cord complication, unspecified, other fetus',
  'O700 First degree perineal laceration during delivery',
  'O701 Second degree perineal laceration during delivery',
  'O7020 Third degree perineal laceration during delivery, unspecified',
  'O7021 Third degree perineal laceration during delivery, IIIa',
  'O7022 Third degree perineal laceration during delivery, IIIb',
  'O7023 Third degree perineal laceration during delivery, IIIc',
  'O703 Fourth degree perineal laceration during delivery',
  'O704 Anal sphincter tear complicating delivery, not associated with third degree laceration',
  'O709 Perineal laceration during delivery, unspecified',
  'O7100 Rupture of uterus before onset of labor, unspecified trimester',
  'O7102 Rupture of uterus before onset of labor, second trimester',
  'O7103 Rupture of uterus before onset of labor, third trimester',
  'O711 Rupture of uterus during labor',
  'O712 Postpartum inversion of uterus',
  'O713 Obstetric laceration of cervix',
  'O714 Obstetric high vaginal laceration alone',
  'O715 Other obstetric injury to pelvic organs',
  'O716 Obstetric damage to pelvic joints and ligaments',
  'O717 Obstetric hematoma of pelvis',
  'O7181 Laceration of uterus, not elsewhere classified',
  'O7182 Other specified trauma to perineum and vulva',
  'O7189 Other specified obstetric trauma',
  'O719 Obstetric trauma, unspecified',
  'O720 Third-stage hemorrhage',
  'O721 Other immediate postpartum hemorrhage',
  'O722 Delayed and secondary postpartum hemorrhage',
  'O723 Postpartum coagulation defects',
  'O730 Retained placenta without hemorrhage',
  'O731 Retained portions of placenta and membranes, without hemorrhage',
  'O740 Aspiration pneumonitis due to anesthesia during labor and delivery',
  'O741 Other pulmonary complications of anesthesia during labor and delivery',
  'O742 Cardiac complications of anesthesia during labor and delivery',
  'O743 Central nervous system complications of anesthesia during labor and delivery',
  'O744 Toxic reaction to local anesthesia during labor and delivery',
  'O745 Spinal and epidural anesthesia-induced headache during labor and delivery',
  'O746 Other complications of spinal and epidural anesthesia during labor and delivery',
  'O747 Failed or difficult intubation for anesthesia during labor and delivery',
  'O748 Other complications of anesthesia during labor and delivery',
  'O749 Complication of anesthesia during labor and delivery, unspecified',
  'O750 Maternal distress during labor and delivery',
  'O751 Shock during or following labor and delivery',
  'O752 Pyrexia during labor, not elsewhere classified',
  'O753 Other infection during labor',
  'O754 Other complications of obstetric surgery and procedures',
  'O755 Delayed delivery after artificial rupture of membranes',
  'O7581 Maternal exhaustion complicating labor and delivery',
  'O7582 Onset (spontaneous) of labor after 37 completed weeks of gestation but before 39 completed',
  'weeks gestation, with delivery by (planned) cesarean section',
  'O7589 Other specified complications of labor and delivery',
  'O759 Complication of labor and delivery, unspecified',
  'O76 Abnormality in fetal heart rate and rhythm complicating labor and delivery',
  'O770 Labor and delivery complicated by meconium in amniotic fluid',
  'O771 Fetal stress in labor or delivery due to drug administration',
  'O778 Labor and delivery complicated by other evidence of fetal stress',
  'O779 Labor and delivery complicated by fetal stress, unspecified',
  'O80 Encounter for full-term uncomplicated delivery',
  'O82 Encounter for cesarean delivery without indication',
  'O85 Puerperal sepsis',
  'O860 Infection of obstetric surgical wound',
  'O8611 Cervicitis following delivery',
  'O8612 Endometritis following delivery',
  'O8613 Vaginitis following delivery',
  'O8619 Other infection of genital tract following delivery',
  'O8620 Urinary tract infection following delivery, unspecified',
  'O8621 Infection of kidney following delivery',
  'O8622 Infection of bladder following delivery',
  'O8629 Other urinary tract infection following delivery',
  'O864 Pyrexia of unknown origin following delivery',
  'O8681 Puerperal septic thrombophlebitis',
  'O8689 Other specified puerperal infections',
  'O870 Superficial thrombophlebitis in the puerperium',
  'O871 Deep phlebothrombosis in the puerperium',
  'O872 Hemorrhoids in the puerperium',
  'O873 Cerebral venous thrombosis in the puerperium',
  'O874 Varicose veins of lower extremity in the puerperium',
  'O878 Other venous complications in the puerperium',
  'O879 Venous complication in the puerperium, unspecified',
  'O88011 Air embolism in pregnancy, first trimester',
  'O88012 Air embolism in pregnancy, second trimester',
  'O88013 Air embolism in pregnancy, third trimester',
  'O88019 Air embolism in pregnancy, unspecified trimester',
  'O8802 Air embolism in childbirth',
  'O8803 Air embolism in the puerperium',
  'O88111 Amniotic fluid embolism in pregnancy, first trimester',
  'O88112 Amniotic fluid embolism in pregnancy, second trimester',
  'O88113 Amniotic fluid embolism in pregnancy, third trimester',
  'O88119 Amniotic fluid embolism in pregnancy, unspecified trimester',
  'O8812 Amniotic fluid embolism in childbirth',
  'O8813 Amniotic fluid embolism in the puerperium',
  'O88211 Thromboembolism in pregnancy, first trimester',
  'O88212 Thromboembolism in pregnancy, second trimester',
  'O88213 Thromboembolism in pregnancy, third trimester',
  'O88219 Thromboembolism in pregnancy, unspecified trimester',
  'O8822 Thromboembolism in childbirth',
  'O8823 Thromboembolism in the puerperium',
  'O88311 Pyemic and septic embolism in pregnancy, first trimester',
  'O88312 Pyemic and septic embolism in pregnancy, second trimester',
  'O88313 Pyemic and septic embolism in pregnancy, third trimester',
  'O88319 Pyemic and septic embolism in pregnancy, unspecified trimester',
  'O8832 Pyemic and septic embolism in childbirth',
  'O8833 Pyemic and septic embolism in the puerperium',
  'O88811 Other embolism in pregnancy, first trimester',
  'O88812 Other embolism in pregnancy, second trimester',
  'O88813 Other embolism in pregnancy, third trimester',
  'O88819 Other embolism in pregnancy, unspecified trimester',
  'O8882 Other embolism in childbirth',
  'O8883 Other embolism in the puerperium',
  'O8901 Aspiration pneumonitis due to anesthesia during the puerperium',
  'O8909 Other pulmonary complications of anesthesia during the puerperium',
  'O891 Cardiac complications of anesthesia during the puerperium',
  'O892 Central nervous system complications of anesthesia during the puerperium',
  'O893 Toxic reaction to local anesthesia during the puerperium',
  'O894 Spinal and epidural anesthesia-induced headache during the puerperium',
  'O895 Other complications of spinal and epidural anesthesia during the puerperium',
  'O896 Failed or difficult intubation for anesthesia during the puerperium',
  'O898 Other complications of anesthesia during the puerperium',
  'O899 Complication of anesthesia during the puerperium, unspecified',
  'O900 Disruption of cesarean delivery wound',
  'O901 Disruption of perineal obstetric wound',
  'O902 Hematoma of obstetric wound',
  'O903 Peripartum cardiomyopathy',
  'O904 Postpartum acute kidney failure',
  'O905 Postpartum thyroiditis',
  'O906 Postpartum mood disturbance',
  'O9081 Anemia of the puerperium',
  'O9089 Other complications of the puerperium, not elsewhere classified',
  'O909 Complication of the puerperium, unspecified',
  'O91011 Infection of nipple associated with pregnancy, first trimester',
  'O91012 Infection of nipple associated with pregnancy, second trimester',
  'O91013 Infection of nipple associated with pregnancy, third trimester',
  'O91019 Infection of nipple associated with pregnancy, unspecified trimester',
  'O9102 Infection of nipple associated with the puerperium',
  'O9103 Infection of nipple associated with lactation',
  'O91111 Abscess of breast associated with pregnancy, first trimester',
  'O91112 Abscess of breast associated with pregnancy, second trimester',
  'O91113 Abscess of breast associated with pregnancy, third trimester',
  'O91119 Abscess of breast associated with pregnancy, unspecified trimester',
  'O9112 Abscess of breast associated with the puerperium',
  'O9113 Abscess of breast associated with lactation',
  'O91211 Nonpurulent mastitis associated with pregnancy, first trimester',
  'O91212 Nonpurulent mastitis associated with pregnancy, second trimester',
  'O91213 Nonpurulent mastitis associated with pregnancy, third trimester',
  'O91219 Nonpurulent mastitis associated with pregnancy, unspecified trimester',
  'O9122 Nonpurulent mastitis associated with the puerperium',
  'O9123 Nonpurulent mastitis associated with lactation',
  'O92011 Retracted nipple associated with pregnancy, first trimester',
  'O92012 Retracted nipple associated with pregnancy, second trimester',
  'O92013 Retracted nipple associated with pregnancy, third trimester',
  'O92019 Retracted nipple associated with pregnancy, unspecified trimester',
  'O9202 Retracted nipple associated with the puerperium',
  'O9203 Retracted nipple associated with lactation',
  'O92111 Cracked nipple associated with pregnancy, first trimester',
  'O92112 Cracked nipple associated with pregnancy, second trimester',
  'O92113 Cracked nipple associated with pregnancy, third trimester',
  'O92119 Cracked nipple associated with pregnancy, unspecified trimester',
  'O9212 Cracked nipple associated with the puerperium',
  'O9213 Cracked nipple associated with lactation',
  'O9220 Unspecified disorder of breast associated with pregnancy and the puerperium',
  'O9229 Other disorders of breast associated with pregnancy and the puerperium',
  'O923 Agalactia',
  'O924 Hypogalactia',
  'O925 Suppressed lactation',
  'O926 Galactorrhea',
  'O9270 Unspecified disorders of lactation',
  'O9279 Other disorders of lactation',
  'O94 Sequelae of complication of pregnancy, childbirth, and the puerperium',
  'O98011 Tuberculosis complicating pregnancy, first trimester',
  'O98012 Tuberculosis complicating pregnancy, second trimester',
  'O98013 Tuberculosis complicating pregnancy, third trimester',
  'O98019 Tuberculosis complicating pregnancy, unspecified trimester',
  'O9802 Tuberculosis complicating childbirth',
  'O9803 Tuberculosis complicating the puerperium',
  'O98111 Syphilis complicating pregnancy, first trimester',
  'O98112 Syphilis complicating pregnancy, second trimester',
  'O98113 Syphilis complicating pregnancy, third trimester',
  'O98119 Syphilis complicating pregnancy, unspecified trimester',
  'O9812 Syphilis complicating childbirth',
  'O9813 Syphilis complicating the puerperium',
  'O98211 Gonorrhea complicating pregnancy, first trimester',
  'O98212 Gonorrhea complicating pregnancy, second trimester',
  'O98213 Gonorrhea complicating pregnancy, third trimester',
  'O98219 Gonorrhea complicating pregnancy, unspecified trimester',
  'O9822 Gonorrhea complicating childbirth',
  'O9823 Gonorrhea complicating the puerperium',
  'O98311 Other infections with a predominantly sexual mode of transmission complicating pregnancy,',
  'first trimester',
  'O98312 Other infections with a predominantly sexual mode of transmission complicating pregnancy,',
  'second trimester',
  'O98313 Other infections with a predominantly sexual mode of transmission complicating pregnancy,',
  'third trimester',
  'O98319 Other infections with a predominantly sexual mode of transmission complicating pregnancy,',
  'unspecified trimester',
  'O9832 Other infections with a predominantly sexual mode of transmission complicating childbirth',
  'O9833 Other infections with a predominantly sexual mode of transmission complicating the',
  'puerperium',
  'O98411 Viral hepatitis complicating pregnancy, first trimester',
  'O98412 Viral hepatitis complicating pregnancy, second trimester',
  'O98413 Viral hepatitis complicating pregnancy, third trimester',
  'O98419 Viral hepatitis complicating pregnancy, unspecified trimester',
  'O9842 Viral hepatitis complicating childbirth',
  'O9843 Viral hepatitis complicating the puerperium',
  'O98511 Other viral diseases complicating pregnancy, first trimester',
  'O98512 Other viral diseases complicating pregnancy, second trimester',
  'O98513 Other viral diseases complicating pregnancy, third trimester',
  'O98519 Other viral diseases complicating pregnancy, unspecified trimester',
  'O9852 Other viral diseases complicating childbirth',
  'O9853 Other viral diseases complicating the puerperium',
  'O98611 Protozoal diseases complicating pregnancy, first trimester',
  'O98612 Protozoal diseases complicating pregnancy, second trimester',
  'O98613 Protozoal diseases complicating pregnancy, third trimester',
  'O98619 Protozoal diseases complicating pregnancy, unspecified trimester',
  'O9862 Protozoal diseases complicating childbirth',
  'O9863 Protozoal diseases complicating the puerperium',
  'O98711 Human immunodeficiency virus [HIV] disease complicating pregnancy, first trimester',
  'O98712 Human immunodeficiency virus [HIV] disease complicating pregnancy, second trimester',
  'O98713 Human immunodeficiency virus [HIV] disease complicating pregnancy, third trimester',
  'O98719 Human immunodeficiency virus [HIV] disease complicating pregnancy, unspecified trimester',
  'O9872 Human immunodeficiency virus [HIV] disease complicating childbirth',
  'O9873 Human immunodeficiency virus [HIV] disease complicating the puerperium',
  'O98811 Other maternal infectious and parasitic diseases complicating pregnancy, first trimester',
  'O98812 Other maternal infectious and parasitic diseases complicating pregnancy, second trimester',
  'O98813 Other maternal infectious and parasitic diseases complicating pregnancy, third trimester',
  'O98819 Other maternal infectious and parasitic diseases complicating pregnancy, unspecified trimester',
  'O9882 Other maternal infectious and parasitic diseases complicating childbirth',
  'O9883 Other maternal infectious and parasitic diseases complicating the puerperium',
  'O98911 Unspecified maternal infectious and parasitic disease complicating pregnancy, first trimester',
  'O98912 Unspecified maternal infectious and parasitic disease complicating pregnancy, second trimester',
  'O98913 Unspecified maternal infectious and parasitic disease complicating pregnancy, third trimester',
  'O98919 Unspecified maternal infectious and parasitic disease complicating pregnancy, unspecified',
  'trimester',
  'O9892 Unspecified maternal infectious and parasitic disease complicating childbirth',
  'O9893 Unspecified maternal infectious and parasitic disease complicating the puerperium',
  'O99011 Anemia complicating pregnancy, first trimester',
  'O99012 Anemia complicating pregnancy, second trimester',
  'O99013 Anemia complicating pregnancy, third trimester',
  'O99019 Anemia complicating pregnancy, unspecified trimester',
  'O9902 Anemia complicating childbirth',
  'O9903 Anemia complicating the puerperium',
  'O99111 Other diseases of the blood and blood-forming organs and certain disorders involving the',
  'immune mechanism complicating pregnancy, first trimester',
  'O99112 Other diseases of the blood and blood-forming organs and certain disorders involving the',
  'immune mechanism complicating pregnancy, second trimester',
  'O99113 Other diseases of the blood and blood-forming organs and certain disorders involving the',
  'immune mechanism complicating pregnancy, third trimester',
  'O99119 Other diseases of the blood and blood-forming organs and certain disorders involving the',
  'immune mechanism complicating pregnancy, unspecified trimester',
  'O9912 Other diseases of the blood and blood-forming organs and certain disorders involving the',
  'immune mechanism complicating childbirth',
  'O9913 Other diseases of the blood and blood-forming organs and certain disorders involving the',
  'immune mechanism complicating the puerperium',
  'O99210 Obesity complicating pregnancy, unspecified trimester',
  'O99211 Obesity complicating pregnancy, first trimester',
  'O99212 Obesity complicating pregnancy, second trimester',
  'O99213 Obesity complicating pregnancy, third trimester',
  'O99214 Obesity complicating childbirth',
  'O99215 Obesity complicating the puerperium',
  'O99280 Endocrine, nutritional and metabolic diseases complicating pregnancy, unspecified trimester',
  'O99281 Endocrine, nutritional and metabolic diseases complicating pregnancy, first trimester',
  'O99282 Endocrine, nutritional and metabolic diseases complicating pregnancy, second trimester',
  'O99283 Endocrine, nutritional and metabolic diseases complicating pregnancy, third trimester',
  'O99284 Endocrine, nutritional and metabolic diseases complicating childbirth',
  'O99285 Endocrine, nutritional and metabolic diseases complicating the puerperium',
  'O99310 Alcohol use complicating pregnancy, unspecified trimester',
  'O99311 Alcohol use complicating pregnancy, first trimester',
  'O99312 Alcohol use complicating pregnancy, second trimester',
  'O99313 Alcohol use complicating pregnancy, third trimester',
  'O99314 Alcohol use complicating childbirth',
  'O99315 Alcohol use complicating the puerperium',
  'O99320 Drug use complicating pregnancy, unspecified trimester',
  'O99321 Drug use complicating pregnancy, first trimester',
  'O99322 Drug use complicating pregnancy, second trimester',
  'O99323 Drug use complicating pregnancy, third trimester',
  'O99324 Drug use complicating childbirth',
  'O99325 Drug use complicating the puerperium',
  'O99330 Smoking (tobacco) complicating pregnancy, unspecified trimester',
  'O99331 Smoking (tobacco) complicating pregnancy, first trimester',
  'O99332 Smoking (tobacco) complicating pregnancy, second trimester',
  'O99333 Smoking (tobacco) complicating pregnancy, third trimester',
  'O99334 Smoking (tobacco) complicating childbirth',
  'O99335 Smoking (tobacco) complicating the puerperium',
  'O99340 Other mental disorders complicating pregnancy, unspecified trimester',
  'O99341 Other mental disorders complicating pregnancy, first trimester',
  'O99342 Other mental disorders complicating pregnancy, second trimester',
  'O99343 Other mental disorders complicating pregnancy, third trimester',
  'O99344 Other mental disorders complicating childbirth',
  'O99345 Other mental disorders complicating the puerperium',
  'O99350 Diseases of the nervous system complicating pregnancy, unspecified trimester',
  'O99351 Diseases of the nervous system complicating pregnancy, first trimester',
  'O99352 Diseases of the nervous system complicating pregnancy, second trimester',
  'O99353 Diseases of the nervous system complicating pregnancy, third trimester',
  'O99354 Diseases of the nervous system complicating childbirth',
  'O99355 Diseases of the nervous system complicating the puerperium',
  'O99411 Diseases of the circulatory system complicating pregnancy, first trimester',
  'O99412 Diseases of the circulatory system complicating pregnancy, second trimester',
  'O99413 Diseases of the circulatory system complicating pregnancy, third trimester',
  'O99419 Diseases of the circulatory system complicating pregnancy, unspecified trimester',
  'O9942 Diseases of the circulatory system complicating childbirth',
  'O9943 Diseases of the circulatory system complicating the puerperium',
  'O99511 Diseases of the respiratory system complicating pregnancy, first trimester',
  'O99512 Diseases of the respiratory system complicating pregnancy, second trimester',
  'O99513 Diseases of the respiratory system complicating pregnancy, third trimester',
  'O99519 Diseases of the respiratory system complicating pregnancy, unspecified trimester',
  'O9952 Diseases of the respiratory system complicating childbirth',
  'O9953 Diseases of the respiratory system complicating the puerperium',
  'O99611 Diseases of the digestive system complicating pregnancy, first trimester',
  'O99612 Diseases of the digestive system complicating pregnancy, second trimester',
  'O99613 Diseases of the digestive system complicating pregnancy, third trimester',
  'O99619 Diseases of the digestive system complicating pregnancy, unspecified trimester',
  'O9962 Diseases of the digestive system complicating childbirth',
  'O9963 Diseases of the digestive system complicating the puerperium',
  'O99711 Diseases of the skin and subcutaneous tissue complicating pregnancy, first trimester',
  'O99712 Diseases of the skin and subcutaneous tissue complicating pregnancy, second trimester',
  'O99713 Diseases of the skin and subcutaneous tissue complicating pregnancy, third trimester',
  'O99719 Diseases of the skin and subcutaneous tissue complicating pregnancy, unspecified trimester',
  'O9972 Diseases of the skin and subcutaneous tissue complicating childbirth',
  'O9973 Diseases of the skin and subcutaneous tissue complicating the puerperium',
  'O99810 Abnormal glucose complicating pregnancy',
  'O99814 Abnormal glucose complicating childbirth',
  'O99815 Abnormal glucose complicating the puerperium',
  'O99820 Streptococcus B carrier state complicating pregnancy',
  'O99824 Streptococcus B carrier state complicating childbirth',
  'O99825 Streptococcus B carrier state complicating the puerperium',
  'O99830 Other infection carrier state complicating pregnancy',
  'O99834 Other infection carrier state complicating childbirth',
  'O99835 Other infection carrier state complicating the puerperium',
  'O99840 Bariatric surgery status complicating pregnancy, unspecified trimester',
  'O99841 Bariatric surgery status complicating pregnancy, first trimester',
  'O99842 Bariatric surgery status complicating pregnancy, second trimester',
  'O99843 Bariatric surgery status complicating pregnancy, third trimester',
  'O99844 Bariatric surgery status complicating childbirth',
  'O99845 Bariatric surgery status complicating the puerperium',
  'O9989 Other specified diseases and conditions complicating pregnancy, childbirth and the puerperium',
  'O9A111 Malignant neoplasm complicating pregnancy, first trimester',
  'O9A112 Malignant neoplasm complicating pregnancy, second trimester',
  'O9A113 Malignant neoplasm complicating pregnancy, third trimester',
  'O9A119 Malignant neoplasm complicating pregnancy, unspecified trimester',
  'O9A12 Malignant neoplasm complicating childbirth',
  'O9A13 Malignant neoplasm complicating the puerperium',
  'O9A211 Injury, poisoning and certain other consequences of external causes complicating pregnancy,',
  'first trimester',
  'O9A212 Injury, poisoning and certain other consequences of external causes complicating pregnancy,',
  'second trimester',
  'O9A213 Injury, poisoning and certain other consequences of external causes complicating pregnancy,',
  'third trimester',
  'O9A219 Injury, poisoning and certain other consequences of external causes complicating pregnancy,',
  'unspecified trimester',
  'O9A22 Injury, poisoning and certain other consequences of external causes complicating childbirth',
  'O9A23 Injury, poisoning and certain other consequences of external causes complicating the',
  'puerperium',
  'O9A311 Physical abuse complicating pregnancy, first trimester',
  'O9A312 Physical abuse complicating pregnancy, second trimester',
  'O9A313 Physical abuse complicating pregnancy, third trimester',
  'O9A319 Physical abuse complicating pregnancy, unspecified trimester',
  'O9A32 Physical abuse complicating childbirth',
  'O9A33 Physical abuse complicating the puerperium',
  'O9A411 Sexual abuse complicating pregnancy, first trimester',
  'O9A412 Sexual abuse complicating pregnancy, second trimester',
  'O9A413 Sexual abuse complicating pregnancy, third trimester',
  'O9A419 Sexual abuse complicating pregnancy, unspecified trimester',
  'O9A42 Sexual abuse complicating childbirth',
  'O9A43 Sexual abuse complicating the puerperium',
  'O9A511 Psychological abuse complicating pregnancy, first trimester',
  'O9A512 Psychological abuse complicating pregnancy, second trimester',
  'O9A513 Psychological abuse complicating pregnancy, third trimester',
  'O9A519 Psychological abuse complicating pregnancy, unspecified trimester',
  'O9A52 Psychological abuse complicating childbirth',
  'O9A53 Psychological abuse complicating the puerperium',
  'P000 Newborn affected by maternal hypertensive disorders',
  'P001 Newborn affected by maternal renal and urinary tract diseases',
  'P002 Newborn affected by maternal infectious and parasitic diseases',
  'P003 Newborn affected by other maternal circulatory and respiratory diseases',
  'P004 Newborn affected by maternal nutritional disorders',
  'P005 Newborn affected by maternal injury',
  'P006 Newborn affected by surgical procedure on mother',
  'P007 Newborn affected by other medical procedures on mother, not elsewhere classified',
  'P0081 Newborn affected by periodontal disease in mother',
  'P0089 Newborn affected by other maternal conditions',
  'P009 Newborn affected by unspecified maternal condition',
  'P010 Newborn affected by incompetent cervix',
  'P011 Newborn affected by premature rupture of membranes',
  'P012 Newborn affected by oligohydramnios',
  'P013 Newborn affected by polyhydramnios',
  'P014 Newborn affected by ectopic pregnancy',
  'P015 Newborn affected by multiple pregnancy',
  'P016 Newborn affected by maternal death',
  'P017 Newborn affected by malpresentation before labor',
  'P018 Newborn affected by other maternal complications of pregnancy',
  'P019 Newborn affected by maternal complication of pregnancy, unspecified',
  'P020 Newborn affected by placenta previa',
  'P021 Newborn affected by other forms of placental separation and hemorrhage',
  'P0220 Newborn affected by unspecified morphological and functional abnormalities of placenta',
  'P0229 Newborn affected by other morphological and functional abnormalities of placenta',
  'P023 Newborn affected by placental transfusion syndromes',
  'P024 Newborn affected by prolapsed cord',
  'P025 Newborn affected by other compression of umbilical cord',
  'P0260 Newborn affected by unspecified conditions of umbilical cord',
  'P0269 Newborn affected by other conditions of umbilical cord',
  'P027 Newborn affected by chorioamnionitis',
  'P028 Newborn affected by other abnormalities of membranes',
  'P029 Newborn affected by abnormality of membranes, unspecified',
  'P030 Newborn affected by breech delivery and extraction',
  'P031 Newborn affected by other malpresentation, malposition and disproportion during labor and',
  'delivery',
  'P032 Newborn affected by forceps delivery',
  'P033 Newborn affected by delivery by vacuum extractor [ventouse]',
  'P034 Newborn affected by Cesarean delivery',
  'P035 Newborn affected by precipitate delivery',
  'P036 Newborn affected by abnormal uterine contractions',
  'P03810 Newborn affected by abnormality in fetal (intrauterine) heart rate or rhythm before the onset',
  'of labor',
  'P03811 Newborn affected by abnormality in fetal (intrauterine) heart rate or rhythm during labor',
  'P03819 Newborn affected by abnormality in fetal (intrauterine) heart rate or rhythm, unspecified as to',
  'time of onset',
  'P0382 Meconium passage during delivery',
  'P0389 Newborn affected by other specified complications of labor and delivery',
  'P039 Newborn affected by complication of labor and delivery, unspecified',
  'P040 Newborn affected by maternal anesthesia and analgesia in pregnancy, labor and delivery',
  'P041 Newborn affected by other maternal medication',
  'P042 Newborn affected by maternal use of tobacco',
  'P043 Newborn affected by maternal use of alcohol',
  'P0441 Newborn affected by maternal use of cocaine',
  'P0449 Newborn affected by maternal use of other drugs of addiction',
  'P045 Newborn affected by maternal use of nutritional chemical substances',
  'P046 Newborn affected by maternal exposure to environmental chemical substances',
  'P048 Newborn affected by other maternal noxious substances',
  'P049 Newborn affected by maternal noxious substance, unspecified',
  'P0500 Newborn light for gestational age, unspecified weight',
  'P0501 Newborn light for gestational age, less than 500 grams',
  'P0502 Newborn light for gestational age, 500-749 grams',
  'P0503 Newborn light for gestational age, 750-999 grams',
  'P0504 Newborn light for gestational age, 1000-1249 grams',
  'P0505 Newborn light for gestational age, 1250-1499 grams',
  'P0506 Newborn light for gestational age, 1500-1749 grams',
  'P0507 Newborn light for gestational age, 1750-1999 grams',
  'P0508 Newborn light for gestational age, 2000-2499 grams',
  'P0509 Newborn light for gestational age, 2500 grams and over',
  'P0510 Newborn small for gestational age, unspecified weight',
  'P0511 Newborn small for gestational age, less than 500 grams',
  'P0512 Newborn small for gestational age, 500-749 grams',
  'P0513 Newborn small for gestational age, 750-999 grams',
  'P0514 Newborn small for gestational age, 1000-1249 grams',
  'P0515 Newborn small for gestational age, 1250-1499 grams',
  'P0516 Newborn small for gestational age, 1500-1749 grams',
  'P0517 Newborn small for gestational age, 1750-1999 grams',
  'P0518 Newborn small for gestational age, 2000-2499 grams',
  'P0519 Newborn small for gestational age, other',
  'P052 Newborn affected by fetal (intrauterine) malnutrition not light or small for gestational age',
  'P059 Newborn affected by slow intrauterine growth, unspecified',
  'P0700 Extremely low birth weight newborn, unspecified weight',
  'P0701 Extremely low birth weight newborn, less than 500 grams',
  'P0702 Extremely low birth weight newborn, 500-749 grams',
  'P0703 Extremely low birth weight newborn, 750-999 grams',
  'P0710 Other low birth weight newborn, unspecified weight',
  'P0714 Other low birth weight newborn, 1000-1249 grams',
  'P0715 Other low birth weight newborn, 1250-1499 grams',
  'P0716 Other low birth weight newborn, 1500-1749 grams',
  'P0717 Other low birth weight newborn, 1750-1999 grams',
  'P0718 Other low birth weight newborn, 2000-2499 grams',
  'P0720 Extreme immaturity of newborn, unspecified weeks of gestation',
  'P0721 Extreme immaturity of newborn, gestational age less than 23 completed weeks',
  'P0722 Extreme immaturity of newborn, gestational age 23 completed weeks',
  'P0723 Extreme immaturity of newborn, gestational age 24 completed weeks',
  'P0724 Extreme immaturity of newborn, gestational age 25 completed weeks',
  'P0725 Extreme immaturity of newborn, gestational age 26 completed weeks',
  'P0726 Extreme immaturity of newborn, gestational age 27 completed weeks',
  'P0730 Preterm newborn, unspecified weeks of gestation',
  'P0731 Preterm newborn, gestational age 28 completed weeks',
  'P0732 Preterm newborn, gestational age 29 completed weeks',
  'P0733 Preterm newborn, gestational age 30 completed weeks',
  'P0734 Preterm newborn, gestational age 31 completed weeks',
  'P0735 Preterm newborn, gestational age 32 completed weeks',
  'P0736 Preterm newborn, gestational age 33 completed weeks',
  'P0737 Preterm newborn, gestational age 34 completed weeks',
  'P0738 Preterm newborn, gestational age 35 completed weeks',
  'P0739 Preterm newborn, gestational age 36 completed weeks',
  'P080 Exceptionally large newborn baby',
  'P081 Other heavy for gestational age newborn',
  'P0821 Post-term newborn',
  'P0822 Prolonged gestation of newborn',
  'P09 Abnormal findings on neonatal screening',
  'P100 Subdural hemorrhage due to birth injury',
  'P101 Cerebral hemorrhage due to birth injury',
  'P102 Intraventricular hemorrhage due to birth injury',
  'P103 Subarachnoid hemorrhage due to birth injury',
  'P104 Tentorial tear due to birth injury',
  'P108 Other intracranial lacerations and hemorrhages due to birth injury',
  'P109 Unspecified intracranial laceration and hemorrhage due to birth injury',
  'P110 Cerebral edema due to birth injury',
  'P111 Other specified brain damage due to birth injury',
  'P112 Unspecified brain damage due to birth injury',
  'P113 Birth injury to facial nerve',
  'P114 Birth injury to other cranial nerves',
  'P115 Birth injury to spine and spinal cord',
  'P119 Birth injury to central nervous system, unspecified',
  'P120 Cephalhematoma due to birth injury',
  'P121 Chignon (from vacuum extraction) due to birth injury',
  'P122 Epicranial subaponeurotic hemorrhage due to birth injury',
  'P123 Bruising of scalp due to birth injury',
  'P124 Injury of scalp of newborn due to monitoring equipment',
  'P1281 Caput succedaneum',
  'P1289 Other birth injuries to scalp',
  'P129 Birth injury to scalp, unspecified',
  'P130 Fracture of skull due to birth injury',
  'P131 Other birth injuries to skull',
  'P132 Birth injury to femur',
  'P133 Birth injury to other long bones',
  'P134 Fracture of clavicle due to birth injury',
  'P138 Birth injuries to other parts of skeleton',
  'P139 Birth injury to skeleton, unspecified',
  "P140 Erb's paralysis due to birth injury",
  "P141 Klumpke's paralysis due to birth injury",
  'P142 Phrenic nerve paralysis due to birth injury',
  'P143 Other brachial plexus birth injuries',
  'P148 Birth injuries to other parts of peripheral nervous system',
  'P149 Birth injury to peripheral nervous system, unspecified',
  'P150 Birth injury to liver',
  'P151 Birth injury to spleen',
  'P152 Sternomastoid injury due to birth injury',
  'P153 Birth injury to eye',
  'P154 Birth injury to face',
  'P155 Birth injury to external genitalia',
  'P156 Subcutaneous fat necrosis due to birth injury',
  'P158 Other specified birth injuries',
  'P159 Birth injury, unspecified',
  'P190 Metabolic acidemia in newborn first noted before onset of labor',
  'P191 Metabolic acidemia in newborn first noted during labor',
  'P192 Metabolic acidemia noted at birth',
  'P199 Metabolic acidemia, unspecified',
  'P220 Respiratory distress syndrome of newborn',
  'P221 Transient tachypnea of newborn',
  'P228 Other respiratory distress of newborn',
  'P229 Respiratory distress of newborn, unspecified',
  'P230 Congenital pneumonia due to viral agent',
  'P231 Congenital pneumonia due to Chlamydia',
  'P232 Congenital pneumonia due to staphylococcus',
  'P233 Congenital pneumonia due to streptococcus, group B',
  'P234 Congenital pneumonia due to Escherichia coli',
  'P235 Congenital pneumonia due to Pseudomonas',
  'P236 Congenital pneumonia due to other bacterial agents',
  'P238 Congenital pneumonia due to other organisms',
  'P239 Congenital pneumonia, unspecified',
  'P2400 Meconium aspiration without respiratory symptoms',
  'P2401 Meconium aspiration with respiratory symptoms',
  'P2410 Neonatal aspiration of (clear) amniotic fluid and mucus without respiratory symptoms',
  'P2411 Neonatal aspiration of (clear) amniotic fluid and mucus with respiratory symptoms',
  'P2420 Neonatal aspiration of blood without respiratory symptoms',
  'P2421 Neonatal aspiration of blood with respiratory symptoms',
  'P2430 Neonatal aspiration of milk and regurgitated food without respiratory symptoms',
  'P2431 Neonatal aspiration of milk and regurgitated food with respiratory symptoms',
  'P2480 Other neonatal aspiration without respiratory symptoms',
  'P2481 Other neonatal aspiration with respiratory symptoms',
  'P249 Neonatal aspiration, unspecified',
  'P250 Interstitial emphysema originating in the perinatal period',
  'P251 Pneumothorax originating in the perinatal period',
  'P252 Pneumomediastinum originating in the perinatal period',
  'P253 Pneumopericardium originating in the perinatal period',
  'P258 Other conditions related to interstitial emphysema originating in the perinatal period',
  'P260 Tracheobronchial hemorrhage originating in the perinatal period',
  'P261 Massive pulmonary hemorrhage originating in the perinatal period',
  'P268 Other pulmonary hemorrhages originating in the perinatal period',
  'P269 Unspecified pulmonary hemorrhage originating in the perinatal period',
  'P270 Wilson-Mikity syndrome',
  'P271 Bronchopulmonary dysplasia originating in the perinatal period',
  'P278 Other chronic respiratory diseases originating in the perinatal period',
  'P279 Unspecified chronic respiratory disease originating in the perinatal period',
  'P280 Primary atelectasis of newborn',
  'P2810 Unspecified atelectasis of newborn',
  'P2811 Resorption atelectasis without respiratory distress syndrome',
  'P2819 Other atelectasis of newborn',
  'P282 Cyanotic attacks of newborn',
  'P283 Primary sleep apnea of newborn',
  'P284 Other apnea of newborn',
  'P285 Respiratory failure of newborn',
  'P2881 Respiratory arrest of newborn',
  'P2889 Other specified respiratory conditions of newborn',
  'P289 Respiratory condition of newborn, unspecified',
  'P290 Neonatal cardiac failure',
  'P2911 Neonatal tachycardia',
  'P2912 Neonatal bradycardia',
  'P292 Neonatal hypertension',
  'P293 Persistent fetal circulation',
  'P294 Transient myocardial ischemia in newborn',
  'P2981 Cardiac arrest of newborn',
  'P2989 Other cardiovascular disorders originating in the perinatal period',
  'P299 Cardiovascular disorder originating in the perinatal period, unspecified',
  'P350 Congenital rubella syndrome',
  'P351 Congenital cytomegalovirus infection',
  'P352 Congenital herpesviral [herpes simplex] infection',
  'P353 Congenital viral hepatitis',
  'P358 Other congenital viral diseases',
  'P359 Congenital viral disease, unspecified',
  'P360 Sepsis of newborn due to streptococcus, group B',
  'P3610 Sepsis of newborn due to unspecified streptococci',
  'P3619 Sepsis of newborn due to other streptococci',
  'P362 Sepsis of newborn due to Staphylococcus aureus',
  'P3630 Sepsis of newborn due to unspecified staphylococci',
  'P3639 Sepsis of newborn due to other staphylococci',
  'P364 Sepsis of newborn due to Escherichia coli',
  'P365 Sepsis of newborn due to anaerobes',
  'P368 Other bacterial sepsis of newborn',
  'P369 Bacterial sepsis of newborn, unspecified',
  'P370 Congenital tuberculosis',
  'P371 Congenital toxoplasmosis',
  'P372 Neonatal (disseminated) listeriosis',
  'P373 Congenital falciparum malaria',
  'P374 Other congenital malaria',
  'P375 Neonatal candidiasis',
  'P378 Other specified congenital infectious and parasitic diseases',
  'P379 Congenital infectious or parasitic disease, unspecified',
  'P381 Omphalitis with mild hemorrhage',
  'P389 Omphalitis without hemorrhage',
  'P390 Neonatal infective mastitis',
  'P391 Neonatal conjunctivitis and dacryocystitis',
  'P392 Intra-amniotic infection affecting newborn, not elsewhere classified',
  'P393 Neonatal urinary tract infection',
  'P394 Neonatal skin infection',
  'P398 Other specified infections specific to the perinatal period',
  'P399 Infection specific to the perinatal period, unspecified',
  'P500 Newborn affected by intrauterine (fetal) blood loss from vasa previa',
  'P501 Newborn affected by intrauterine (fetal) blood loss from ruptured cord',
  'P502 Newborn affected by intrauterine (fetal) blood loss from placenta',
  'P503 Newborn affected by hemorrhage into co-twin',
  'P504 Newborn affected by hemorrhage into maternal circulation',
  "P505 Newborn affected by intrauterine (fetal) blood loss from cut end of co-twin's cord",
  'P508 Newborn affected by other intrauterine (fetal) blood loss',
  'P509 Newborn affected by intrauterine (fetal) blood loss, unspecified',
  'P510 Massive umbilical hemorrhage of newborn',
  'P518 Other umbilical hemorrhages of newborn',
  'P519 Umbilical hemorrhage of newborn, unspecified',
  'P520 Intraventricular (nontraumatic) hemorrhage, grade 1, of newborn',
  'P521 Intraventricular (nontraumatic) hemorrhage, grade 2, of newborn',
  'P5221 Intraventricular (nontraumatic) hemorrhage, grade 3, of newborn',
  'P5222 Intraventricular (nontraumatic) hemorrhage, grade 4, of newborn',
  'P523 Unspecified intraventricular (nontraumatic) hemorrhage of newborn',
  'P524 Intracerebral (nontraumatic) hemorrhage of newborn',
  'P525 Subarachnoid (nontraumatic) hemorrhage of newborn',
  'P526 Cerebellar (nontraumatic) and posterior fossa hemorrhage of newborn',
  'P528 Other intracranial (nontraumatic) hemorrhages of newborn',
  'P529 Intracranial (nontraumatic) hemorrhage of newborn, unspecified',
  'P53 Hemorrhagic disease of newborn',
  'P540 Neonatal hematemesis',
  'P541 Neonatal melena',
  'P542 Neonatal rectal hemorrhage',
  'P543 Other neonatal gastrointestinal hemorrhage',
  'P544 Neonatal adrenal hemorrhage',
  'P545 Neonatal cutaneous hemorrhage',
  'P546 Neonatal vaginal hemorrhage',
  'P548 Other specified neonatal hemorrhages',
  'P549 Neonatal hemorrhage, unspecified',
  'P550 Rh isoimmunization of newborn',
  'P551 ABO isoimmunization of newborn',
  'P558 Other hemolytic diseases of newborn',
  'P559 Hemolytic disease of newborn, unspecified',
  'P560 Hydrops fetalis due to isoimmunization',
  'P5690 Hydrops fetalis due to unspecified hemolytic disease',
  'P5699 Hydrops fetalis due to other hemolytic disease',
  'P570 Kernicterus due to isoimmunization',
  'P578 Other specified kernicterus',
  'P579 Kernicterus, unspecified',
  'P580 Neonatal jaundice due to bruising',
  'P581 Neonatal jaundice due to bleeding',
  'P582 Neonatal jaundice due to infection',
  'P583 Neonatal jaundice due to polycythemia',
  'P5841 Neonatal jaundice due to drugs or toxins transmitted from mother',
  'P5842 Neonatal jaundice due to drugs or toxins given to newborn',
  'P585 Neonatal jaundice due to swallowed maternal blood',
  'P588 Neonatal jaundice due to other specified excessive hemolysis',
  'P589 Neonatal jaundice due to excessive hemolysis, unspecified',
  'P590 Neonatal jaundice associated with preterm delivery',
  'P591 Inspissated bile syndrome',
  'P5920 Neonatal jaundice from unspecified hepatocellular damage',
  'P5929 Neonatal jaundice from other hepatocellular damage',
  'P593 Neonatal jaundice from breast milk inhibitor',
  'P598 Neonatal jaundice from other specified causes',
  'P599 Neonatal jaundice, unspecified',
  'P60 Disseminated intravascular coagulation of newborn',
  'P610 Transient neonatal thrombocytopenia',
  'P611 Polycythemia neonatorum',
  'P612 Anemia of prematurity',
  'P613 Congenital anemia from fetal blood loss',
  'P614 Other congenital anemias, not elsewhere classified',
  'P615 Transient neonatal neutropenia',
  'P616 Other transient neonatal disorders of coagulation',
  'P618 Other specified perinatal hematological disorders',
  'P619 Perinatal hematological disorder, unspecified',
  'P700 Syndrome of infant of mother with gestational diabetes',
  'P701 Syndrome of infant of a diabetic mother',
  'P702 Neonatal diabetes mellitus',
  'P703 Iatrogenic neonatal hypoglycemia',
  'P704 Other neonatal hypoglycemia',
  'P708 Other transitory disorders of carbohydrate metabolism of newborn',
  'P709 Transitory disorder of carbohydrate metabolism of newborn, unspecified',
  "P710 Cow's milk hypocalcemia in newborn",
  'P711 Other neonatal hypocalcemia',
  'P712 Neonatal hypomagnesemia',
  'P713 Neonatal tetany without calcium or magnesium deficiency',
  'P714 Transitory neonatal hypoparathyroidism',
  'P718 Other transitory neonatal disorders of calcium and magnesium metabolism',
  'P719 Transitory neonatal disorder of calcium and magnesium metabolism, unspecified',
  'P720 Neonatal goiter, not elsewhere classified',
  'P721 Transitory neonatal hyperthyroidism',
  'P722 Other transitory neonatal disorders of thyroid function, not elsewhere classified',
  'P728 Other specified transitory neonatal endocrine disorders',
  'P729 Transitory neonatal endocrine disorder, unspecified',
  'P740 Late metabolic acidosis of newborn',
  'P741 Dehydration of newborn',
  'P742 Disturbances of sodium balance of newborn',
  'P743 Disturbances of potassium balance of newborn',
  'P744 Other transitory electrolyte disturbances of newborn',
  'P745 Transitory tyrosinemia of newborn',
  'P746 Transitory hyperammonemia of newborn',
  'P748 Other transitory metabolic disturbances of newborn',
  'P749 Transitory metabolic disturbance of newborn, unspecified',
  'P760 Meconium plug syndrome',
  'P761 Transitory ileus of newborn',
  'P762 Intestinal obstruction due to inspissated milk',
  'P768 Other specified intestinal obstruction of newborn',
  'P769 Intestinal obstruction of newborn, unspecified',
  'P771 Stage 1 necrotizing enterocolitis in newborn',
  'P772 Stage 2 necrotizing enterocolitis in newborn',
  'P773 Stage 3 necrotizing enterocolitis in newborn',
  'P779 Necrotizing enterocolitis in newborn, unspecified',
  'P780 Perinatal intestinal perforation',
  'P781 Other neonatal peritonitis',
  'P782 Neonatal hematemesis and melena due to swallowed maternal blood',
  'P783 Noninfective neonatal diarrhea',
  'P7881 Congenital cirrhosis (of liver)',
  'P7882 Peptic ulcer of newborn',
  'P7883 Newborn esophageal reflux',
  'P7889 Other specified perinatal digestive system disorders',
  'P789 Perinatal digestive system disorder, unspecified',
  'P800 Cold injury syndrome',
  'P808 Other hypothermia of newborn',
  'P809 Hypothermia of newborn, unspecified',
  'P810 Environmental hyperthermia of newborn',
  'P818 Other specified disturbances of temperature regulation of newborn',
  'P819 Disturbance of temperature regulation of newborn, unspecified',
  'P830 Sclerema neonatorum',
  'P831 Neonatal erythema toxicum',
  'P832 Hydrops fetalis not due to hemolytic disease',
  'P8330 Unspecified edema specific to newborn',
  'P8339 Other edema specific to newborn',
  'P834 Breast engorgement of newborn',
  'P835 Congenital hydrocele',
  'P836 Umbilical polyp of newborn',
  'P838 Other specified conditions of integument specific to newborn',
  'P839 Condition of the integument specific to newborn, unspecified',
  'P84 Other problems with newborn',
  'P90 Convulsions of newborn',
  'P910 Neonatal cerebral ischemia',
  'P911 Acquired periventricular cysts of newborn',
  'P912 Neonatal cerebral leukomalacia',
  'P913 Neonatal cerebral irritability',
  'P914 Neonatal cerebral depression',
  'P915 Neonatal coma',
  'P9160 Hypoxic ischemic encephalopathy [HIE], unspecified',
  'P9161 Mild hypoxic ischemic encephalopathy [HIE]',
  'P9162 Moderate hypoxic ischemic encephalopathy [HIE]',
  'P9163 Severe hypoxic ischemic encephalopathy [HIE]',
  'P918 Other specified disturbances of cerebral status of newborn',
  'P919 Disturbance of cerebral status of newborn, unspecified',
  'P9201 Bilious vomiting of newborn',
  'P9209 Other vomiting of newborn',
  'P921 Regurgitation and rumination of newborn',
  'P922 Slow feeding of newborn',
  'P923 Underfeeding of newborn',
  'P924 Overfeeding of newborn',
  'P925 Neonatal difficulty in feeding at breast',
  'P926 Failure to thrive in newborn',
  'P928 Other feeding problems of newborn',
  'P929 Feeding problem of newborn, unspecified',
  'P930 Grey baby syndrome',
  'P938 Other reactions and intoxications due to drugs administered to newborn',
  'P940 Transient neonatal myasthenia gravis',
  'P941 Congenital hypertonia',
  'P942 Congenital hypotonia',
  'P948 Other disorders of muscle tone of newborn',
  'P949 Disorder of muscle tone of newborn, unspecified',
  'P95 Stillbirth',
  'P960 Congenital renal failure',
  'P961 Neonatal withdrawal symptoms from maternal use of drugs of addiction',
  'P962 Withdrawal symptoms from therapeutic use of drugs in newborn',
  'P963 Wide cranial sutures of newborn',
  'P965 Complication to newborn due to (fetal) intrauterine procedure',
  'P9681 Exposure to (parental) (environmental) tobacco smoke in the perinatal period',
  'P9682 Delayed separation of umbilical cord',
  'P9683 Meconium staining',
  'P9689 Other specified conditions originating in the perinatal period',
  'P969 Condition originating in the perinatal period, unspecified',
  'Q000 Anencephaly',
  'Q001 Craniorachischisis',
  'Q002 Iniencephaly',
  'Q010 Frontal encephalocele',
  'Q011 Nasofrontal encephalocele',
  'Q012 Occipital encephalocele',
  'Q018 Encephalocele of other sites',
  'Q019 Encephalocele, unspecified',
  'Q02 Microcephaly',
  'Q030 Malformations of aqueduct of Sylvius',
  'Q031 Atresia of foramina of Magendie and Luschka',
  'Q038 Other congenital hydrocephalus',
  'Q039 Congenital hydrocephalus, unspecified',
  'Q040 Congenital malformations of corpus callosum',
  'Q041 Arhinencephaly',
  'Q042 Holoprosencephaly',
  'Q043 Other reduction deformities of brain',
  'Q044 Septo-optic dysplasia of brain',
  'Q045 Megalencephaly',
  'Q046 Congenital cerebral cysts',
  'Q048 Other specified congenital malformations of brain',
  'Q049 Congenital malformation of brain, unspecified',
  'Q050 Cervical spina bifida with hydrocephalus',
  'Q051 Thoracic spina bifida with hydrocephalus',
  'Q052 Lumbar spina bifida with hydrocephalus',
  'Q053 Sacral spina bifida with hydrocephalus',
  'Q054 Unspecified spina bifida with hydrocephalus',
  'Q055 Cervical spina bifida without hydrocephalus',
  'Q056 Thoracic spina bifida without hydrocephalus',
  'Q057 Lumbar spina bifida without hydrocephalus',
  'Q058 Sacral spina bifida without hydrocephalus',
  'Q059 Spina bifida, unspecified',
  'Q060 Amyelia',
  'Q061 Hypoplasia and dysplasia of spinal cord',
  'Q062 Diastematomyelia',
  'Q063 Other congenital cauda equina malformations',
  'Q064 Hydromyelia',
  'Q068 Other specified congenital malformations of spinal cord',
  'Q069 Congenital malformation of spinal cord, unspecified',
  'Q0700 Arnold-Chiari syndrome without spina bifida or hydrocephalus',
  'Q0701 Arnold-Chiari syndrome with spina bifida',
  'Q0702 Arnold-Chiari syndrome with hydrocephalus',
  'Q0703 Arnold-Chiari syndrome with spina bifida and hydrocephalus',
  'Q078 Other specified congenital malformations of nervous system',
  'Q079 Congenital malformation of nervous system, unspecified',
  'Q100 Congenital ptosis',
  'Q101 Congenital ectropion',
  'Q102 Congenital entropion',
  'Q103 Other congenital malformations of eyelid',
  'Q104 Absence and agenesis of lacrimal apparatus',
  'Q105 Congenital stenosis and stricture of lacrimal duct',
  'Q106 Other congenital malformations of lacrimal apparatus',
  'Q107 Congenital malformation of orbit',
  'Q110 Cystic eyeball',
  'Q111 Other anophthalmos',
  'Q112 Microphthalmos',
  'Q113 Macrophthalmos',
  'Q120 Congenital cataract',
  'Q121 Congenital displaced lens',
  'Q122 Coloboma of lens',
  'Q123 Congenital aphakia',
  'Q124 Spherophakia',
  'Q128 Other congenital lens malformations',
  'Q129 Congenital lens malformation, unspecified',
  'Q130 Coloboma of iris',
  'Q131 Absence of iris',
  'Q132 Other congenital malformations of iris',
  'Q133 Congenital corneal opacity',
  'Q134 Other congenital corneal malformations',
  'Q135 Blue sclera',
  "Q1381 Rieger's anomaly",
  'Q1389 Other congenital malformations of anterior segment of eye',
  'Q139 Congenital malformation of anterior segment of eye, unspecified',
  'Q140 Congenital malformation of vitreous humor',
  'Q141 Congenital malformation of retina',
  'Q142 Congenital malformation of optic disc',
  'Q143 Congenital malformation of choroid',
  'Q148 Other congenital malformations of posterior segment of eye',
  'Q149 Congenital malformation of posterior segment of eye, unspecified',
  'Q150 Congenital glaucoma',
  'Q158 Other specified congenital malformations of eye',
  'Q159 Congenital malformation of eye, unspecified',
  'Q160 Congenital absence of (ear) auricle',
  'Q161 Congenital absence, atresia and stricture of auditory canal (external)',
  'Q162 Absence of eustachian tube',
  'Q163 Congenital malformation of ear ossicles',
  'Q164 Other congenital malformations of middle ear',
  'Q165 Congenital malformation of inner ear',
  'Q169 Congenital malformation of ear causing impairment of hearing, unspecified',
  'Q170 Accessory auricle',
  'Q171 Macrotia',
  'Q172 Microtia',
  'Q173 Other misshapen ear',
  'Q174 Misplaced ear',
  'Q175 Prominent ear',
  'Q178 Other specified congenital malformations of ear',
  'Q179 Congenital malformation of ear, unspecified',
  'Q180 Sinus, fistula and cyst of branchial cleft',
  'Q181 Preauricular sinus and cyst',
  'Q182 Other branchial cleft malformations',
  'Q183 Webbing of neck',
  'Q184 Macrostomia',
  'Q185 Microstomia',
  'Q186 Macrocheilia',
  'Q187 Microcheilia',
  'Q188 Other specified congenital malformations of face and neck',
  'Q189 Congenital malformation of face and neck, unspecified',
  'Q200 Common arterial trunk',
  'Q201 Double outlet right ventricle',
  'Q202 Double outlet left ventricle',
  'Q203 Discordant ventriculoarterial connection',
  'Q204 Double inlet ventricle',
  'Q205 Discordant atrioventricular connection',
  'Q206 Isomerism of atrial appendages',
  'Q208 Other congenital malformations of cardiac chambers and connections',
  'Q209 Congenital malformation of cardiac chambers and connections, unspecified',
  'Q210 Ventricular septal defect',
  'Q211 Atrial septal defect',
  'Q212 Atrioventricular septal defect',
  'Q213 Tetralogy of Fallot',
  'Q214 Aortopulmonary septal defect',
  'Q218 Other congenital malformations of cardiac septa',
  'Q219 Congenital malformation of cardiac septum, unspecified',
  'Q220 Pulmonary valve atresia',
  'Q221 Congenital pulmonary valve stenosis',
  'Q222 Congenital pulmonary valve insufficiency',
  'Q223 Other congenital malformations of pulmonary valve',
  'Q224 Congenital tricuspid stenosis',
  "Q225 Ebstein's anomaly",
  'Q226 Hypoplastic right heart syndrome',
  'Q228 Other congenital malformations of tricuspid valve',
  'Q229 Congenital malformation of tricuspid valve, unspecified',
  'Q230 Congenital stenosis of aortic valve',
  'Q231 Congenital insufficiency of aortic valve',
  'Q232 Congenital mitral stenosis',
  'Q233 Congenital mitral insufficiency',
  'Q234 Hypoplastic left heart syndrome',
  'Q238 Other congenital malformations of aortic and mitral valves',
  'Q239 Congenital malformation of aortic and mitral valves, unspecified',
  'Q240 Dextrocardia',
  'Q241 Levocardia',
  'Q242 Cor triatriatum',
  'Q243 Pulmonary infundibular stenosis',
  'Q244 Congenital subaortic stenosis',
  'Q245 Malformation of coronary vessels',
  'Q246 Congenital heart block',
  'Q248 Other specified congenital malformations of heart',
  'Q249 Congenital malformation of heart, unspecified',
  'Q250 Patent ductus arteriosus',
  'Q251 Coarctation of aorta',
  'Q2521 Interruption of aortic arch',
  'Q2529 Other atresia of aorta',
  'Q253 Supravalvular aortic stenosis',
  'Q2540 Congenital malformation of aorta unspecified',
  'Q2541 Absence and aplasia of aorta',
  'Q2542 Hypoplasia of aorta',
  'Q2543 Congenital aneurysm of aorta',
  'Q2544 Congenital dilation of aorta',
  'Q2545 Double aortic arch',
  'Q2546 Tortuous aortic arch',
  'Q2547 Right aortic arch',
  'Q2548 Anomalous origin of subclavian artery',
  'Q2549 Other congenital malformations of aorta',
  'Q255 Atresia of pulmonary artery',
  'Q256 Stenosis of pulmonary artery',
  'Q2571 Coarctation of pulmonary artery',
  'Q2572 Congenital pulmonary arteriovenous malformation',
  'Q2579 Other congenital malformations of pulmonary artery',
  'Q258 Other congenital malformations of other great arteries',
  'Q259 Congenital malformation of great arteries, unspecified',
  'Q260 Congenital stenosis of vena cava',
  'Q261 Persistent left superior vena cava',
  'Q262 Total anomalous pulmonary venous connection',
  'Q263 Partial anomalous pulmonary venous connection',
  'Q264 Anomalous pulmonary venous connection, unspecified',
  'Q265 Anomalous portal venous connection',
  'Q266 Portal vein-hepatic artery fistula',
  'Q268 Other congenital malformations of great veins',
  'Q269 Congenital malformation of great vein, unspecified',
  'Q270 Congenital absence and hypoplasia of umbilical artery',
  'Q271 Congenital renal artery stenosis',
  'Q272 Other congenital malformations of renal artery',
  'Q2730 Arteriovenous malformation, site unspecified',
  'Q2731 Arteriovenous malformation of vessel of upper limb',
  'Q2732 Arteriovenous malformation of vessel of lower limb',
  'Q2733 Arteriovenous malformation of digestive system vessel',
  'Q2734 Arteriovenous malformation of renal vessel',
  'Q2739 Arteriovenous malformation, other site',
  'Q274 Congenital phlebectasia',
  'Q278 Other specified congenital malformations of peripheral vascular system',
  'Q279 Congenital malformation of peripheral vascular system, unspecified',
  'Q280 Arteriovenous malformation of precerebral vessels',
  'Q281 Other malformations of precerebral vessels',
  'Q282 Arteriovenous malformation of cerebral vessels',
  'Q283 Other malformations of cerebral vessels',
  'Q288 Other specified congenital malformations of circulatory system',
  'Q289 Congenital malformation of circulatory system, unspecified',
  'Q300 Choanal atresia',
  'Q301 Agenesis and underdevelopment of nose',
  'Q302 Fissured, notched and cleft nose',
  'Q303 Congenital perforated nasal septum',
  'Q308 Other congenital malformations of nose',
  'Q309 Congenital malformation of nose, unspecified',
  'Q310 Web of larynx',
  'Q311 Congenital subglottic stenosis',
  'Q312 Laryngeal hypoplasia',
  'Q313 Laryngocele',
  'Q315 Congenital laryngomalacia',
  'Q318 Other congenital malformations of larynx',
  'Q319 Congenital malformation of larynx, unspecified',
  'Q320 Congenital tracheomalacia',
  'Q321 Other congenital malformations of trachea',
  'Q322 Congenital bronchomalacia',
  'Q323 Congenital stenosis of bronchus',
  'Q324 Other congenital malformations of bronchus',
  'Q330 Congenital cystic lung',
  'Q331 Accessory lobe of lung',
  'Q332 Sequestration of lung',
  'Q333 Agenesis of lung',
  'Q334 Congenital bronchiectasis',
  'Q335 Ectopic tissue in lung',
  'Q336 Congenital hypoplasia and dysplasia of lung',
  'Q338 Other congenital malformations of lung',
  'Q339 Congenital malformation of lung, unspecified',
  'Q340 Anomaly of pleura',
  'Q341 Congenital cyst of mediastinum',
  'Q348 Other specified congenital malformations of respiratory system',
  'Q349 Congenital malformation of respiratory system, unspecified',
  'Q351 Cleft hard palate',
  'Q353 Cleft soft palate',
  'Q355 Cleft hard palate with cleft soft palate',
  'Q357 Cleft uvula',
  'Q359 Cleft palate, unspecified',
  'Q360 Cleft lip, bilateral',
  'Q361 Cleft lip, median',
  'Q369 Cleft lip, unilateral',
  'Q370 Cleft hard palate with bilateral cleft lip',
  'Q371 Cleft hard palate with unilateral cleft lip',
  'Q372 Cleft soft palate with bilateral cleft lip',
  'Q373 Cleft soft palate with unilateral cleft lip',
  'Q374 Cleft hard and soft palate with bilateral cleft lip',
  'Q375 Cleft hard and soft palate with unilateral cleft lip',
  'Q378 Unspecified cleft palate with bilateral cleft lip',
  'Q379 Unspecified cleft palate with unilateral cleft lip',
  'Q380 Congenital malformations of lips, not elsewhere classified',
  'Q381 Ankyloglossia',
  'Q382 Macroglossia',
  'Q383 Other congenital malformations of tongue',
  'Q384 Congenital malformations of salivary glands and ducts',
  'Q385 Congenital malformations of palate, not elsewhere classified',
  'Q386 Other congenital malformations of mouth',
  'Q387 Congenital pharyngeal pouch',
  'Q388 Other congenital malformations of pharynx',
  'Q390 Atresia of esophagus without fistula',
  'Q391 Atresia of esophagus with tracheo-esophageal fistula',
  'Q392 Congenital tracheo-esophageal fistula without atresia',
  'Q393 Congenital stenosis and stricture of esophagus',
  'Q394 Esophageal web',
  'Q395 Congenital dilatation of esophagus',
  'Q396 Congenital diverticulum of esophagus',
  'Q398 Other congenital malformations of esophagus',
  'Q399 Congenital malformation of esophagus, unspecified',
  'Q400 Congenital hypertrophic pyloric stenosis',
  'Q401 Congenital hiatus hernia',
  'Q402 Other specified congenital malformations of stomach',
  'Q403 Congenital malformation of stomach, unspecified',
  'Q408 Other specified congenital malformations of upper alimentary tract',
  'Q409 Congenital malformation of upper alimentary tract, unspecified',
  'Q410 Congenital absence, atresia and stenosis of duodenum',
  'Q411 Congenital absence, atresia and stenosis of jejunum',
  'Q412 Congenital absence, atresia and stenosis of ileum',
  'Q418 Congenital absence, atresia and stenosis of other specified parts of small intestine',
  'Q419 Congenital absence, atresia and stenosis of small intestine, part unspecified',
  'Q420 Congenital absence, atresia and stenosis of rectum with fistula',
  'Q421 Congenital absence, atresia and stenosis of rectum without fistula',
  'Q422 Congenital absence, atresia and stenosis of anus with fistula',
  'Q423 Congenital absence, atresia and stenosis of anus without fistula',
  'Q428 Congenital absence, atresia and stenosis of other parts of large intestine',
  'Q429 Congenital absence, atresia and stenosis of large intestine, part unspecified',
  "Q430 Meckel's diverticulum (displaced) (hypertrophic)",
  "Q431 Hirschsprung's disease",
  'Q432 Other congenital functional disorders of colon',
  'Q433 Congenital malformations of intestinal fixation',
  'Q434 Duplication of intestine',
  'Q435 Ectopic anus',
  'Q436 Congenital fistula of rectum and anus',
  'Q437 Persistent cloaca',
  'Q438 Other specified congenital malformations of intestine',
  'Q439 Congenital malformation of intestine, unspecified',
  'Q440 Agenesis, aplasia and hypoplasia of gallbladder',
  'Q441 Other congenital malformations of gallbladder',
  'Q442 Atresia of bile ducts',
  'Q443 Congenital stenosis and stricture of bile ducts',
  'Q444 Choledochal cyst',
  'Q445 Other congenital malformations of bile ducts',
  'Q446 Cystic disease of liver',
  'Q447 Other congenital malformations of liver',
  'Q450 Agenesis, aplasia and hypoplasia of pancreas',
  'Q451 Annular pancreas',
  'Q452 Congenital pancreatic cyst',
  'Q453 Other congenital malformations of pancreas and pancreatic duct',
  'Q458 Other specified congenital malformations of digestive system',
  'Q459 Congenital malformation of digestive system, unspecified',
  'Q5001 Congenital absence of ovary, unilateral',
  'Q5002 Congenital absence of ovary, bilateral',
  'Q501 Developmental ovarian cyst',
  'Q502 Congenital torsion of ovary',
  'Q5031 Accessory ovary',
  'Q5032 Ovarian streak',
  'Q5039 Other congenital malformation of ovary',
  'Q504 Embryonic cyst of fallopian tube',
  'Q505 Embryonic cyst of broad ligament',
  'Q506 Other congenital malformations of fallopian tube and broad ligament',
  'Q510 Agenesis and aplasia of uterus',
  'Q5110 Doubling of uterus with doubling of cervix and vagina without obstruction',
  'Q5111 Doubling of uterus with doubling of cervix and vagina with obstruction',
  'Q512 Other doubling of uterus',
  'Q513 Bicornate uterus',
  'Q514 Unicornate uterus',
  'Q515 Agenesis and aplasia of cervix',
  'Q516 Embryonic cyst of cervix',
  'Q517 Congenital fistulae between uterus and digestive and urinary tracts',
  'Q51810 Arcuate uterus',
  'Q51811 Hypoplasia of uterus',
  'Q51818 Other congenital malformations of uterus',
  'Q51820 Cervical duplication',
  'Q51821 Hypoplasia of cervix',
  'Q51828 Other congenital malformations of cervix',
  'Q519 Congenital malformation of uterus and cervix, unspecified',
  'Q520 Congenital absence of vagina',
  'Q5210 Doubling of vagina, unspecified',
  'Q5211 Transverse vaginal septum',
  'Q52120 Longitudinal vaginal septum, nonobstructing',
  'Q52121 Longitudinal vaginal septum, obstructing, right side',
  'Q52122 Longitudinal vaginal septum, obstructing, left side',
  'Q52123 Longitudinal vaginal septum, microperforate, right side',
  'Q52124 Longitudinal vaginal septum, microperforate, left side',
  'Q52129 Other and unspecified longitudinal vaginal septum',
  'Q522 Congenital rectovaginal fistula',
  'Q523 Imperforate hymen',
  'Q524 Other congenital malformations of vagina',
  'Q525 Fusion of labia',
  'Q526 Congenital malformation of clitoris',
  'Q5270 Unspecified congenital malformations of vulva',
  'Q5271 Congenital absence of vulva',
  'Q5279 Other congenital malformations of vulva',
  'Q528 Other specified congenital malformations of female genitalia',
  'Q529 Congenital malformation of female genitalia, unspecified',
  'Q5300 Ectopic testis, unspecified',
  'Q5301 Ectopic testis, unilateral',
  'Q5302 Ectopic testes, bilateral',
  'Q5310 Unspecified undescended testicle, unilateral',
  'Q5311 Abdominal testis, unilateral',
  'Q5312 Ectopic perineal testis, unilateral',
  'Q5320 Undescended testicle, unspecified, bilateral',
  'Q5321 Abdominal testis, bilateral',
  'Q5322 Ectopic perineal testis, bilateral',
  'Q539 Undescended testicle, unspecified',
  'Q540 Hypospadias, balanic',
  'Q541 Hypospadias, penile',
  'Q542 Hypospadias, penoscrotal',
  'Q543 Hypospadias, perineal',
  'Q544 Congenital chordee',
  'Q548 Other hypospadias',
  'Q549 Hypospadias, unspecified',
  'Q550 Absence and aplasia of testis',
  'Q551 Hypoplasia of testis and scrotum',
  'Q5520 Unspecified congenital malformations of testis and scrotum',
  'Q5521 Polyorchism',
  'Q5522 Retractile testis',
  'Q5523 Scrotal transposition',
  'Q5529 Other congenital malformations of testis and scrotum',
  'Q553 Atresia of vas deferens',
  'Q554 Other congenital malformations of vas deferens, epididymis, seminal vesicles and prostate',
  'Q555 Congenital absence and aplasia of penis',
  'Q5561 Curvature of penis (lateral)',
  'Q5562 Hypoplasia of penis',
  'Q5563 Congenital torsion of penis',
  'Q5564 Hidden penis',
  'Q5569 Other congenital malformation of penis',
  'Q557 Congenital vasocutaneous fistula',
  'Q558 Other specified congenital malformations of male genital organs',
  'Q559 Congenital malformation of male genital organ, unspecified',
  'Q560 Hermaphroditism, not elsewhere classified',
  'Q561 Male pseudohermaphroditism, not elsewhere classified',
  'Q562 Female pseudohermaphroditism, not elsewhere classified',
  'Q563 Pseudohermaphroditism, unspecified',
  'Q564 Indeterminate sex, unspecified',
  'Q600 Renal agenesis, unilateral',
  'Q601 Renal agenesis, bilateral',
  'Q602 Renal agenesis, unspecified',
  'Q603 Renal hypoplasia, unilateral',
  'Q604 Renal hypoplasia, bilateral',
  'Q605 Renal hypoplasia, unspecified',
  "Q606 Potter's syndrome",
  'Q6100 Congenital renal cyst, unspecified',
  'Q6101 Congenital single renal cyst',
  'Q6102 Congenital multiple renal cysts',
  'Q6111 Cystic dilatation of collecting ducts',
  'Q6119 Other polycystic kidney, infantile type',
  'Q612 Polycystic kidney, adult type',
  'Q613 Polycystic kidney, unspecified',
  'Q614 Renal dysplasia',
  'Q615 Medullary cystic kidney',
  'Q618 Other cystic kidney diseases',
  'Q619 Cystic kidney disease, unspecified',
  'Q620 Congenital hydronephrosis',
  'Q6210 Congenital occlusion of ureter, unspecified',
  'Q6211 Congenital occlusion of ureteropelvic junction',
  'Q6212 Congenital occlusion of ureterovesical orifice',
  'Q622 Congenital megaureter',
  'Q6231 Congenital ureterocele, orthotopic',
  'Q6232 Cecoureterocele',
  'Q6239 Other obstructive defects of renal pelvis and ureter',
  'Q624 Agenesis of ureter',
  'Q625 Duplication of ureter',
  'Q6260 Malposition of ureter, unspecified',
  'Q6261 Deviation of ureter',
  'Q6262 Displacement of ureter',
  'Q6263 Anomalous implantation of ureter',
  'Q6269 Other malposition of ureter',
  'Q627 Congenital vesico-uretero-renal reflux',
  'Q628 Other congenital malformations of ureter',
  'Q630 Accessory kidney',
  'Q631 Lobulated, fused and horseshoe kidney',
  'Q632 Ectopic kidney',
  'Q633 Hyperplastic and giant kidney',
  'Q638 Other specified congenital malformations of kidney',
  'Q639 Congenital malformation of kidney, unspecified',
  'Q640 Epispadias',
  'Q6410 Exstrophy of urinary bladder, unspecified',
  'Q6411 Supravesical fissure of urinary bladder',
  'Q6412 Cloacal extrophy of urinary bladder',
  'Q6419 Other exstrophy of urinary bladder',
  'Q642 Congenital posterior urethral valves',
  'Q6431 Congenital bladder neck obstruction',
  'Q6432 Congenital stricture of urethra',
  'Q6433 Congenital stricture of urinary meatus',
  'Q6439 Other atresia and stenosis of urethra and bladder neck',
  'Q644 Malformation of urachus',
  'Q645 Congenital absence of bladder and urethra',
  'Q646 Congenital diverticulum of bladder',
  'Q6470 Unspecified congenital malformation of bladder and urethra',
  'Q6471 Congenital prolapse of urethra',
  'Q6472 Congenital prolapse of urinary meatus',
  'Q6473 Congenital urethrorectal fistula',
  'Q6474 Double urethra',
  'Q6475 Double urinary meatus',
  'Q6479 Other congenital malformations of bladder and urethra',
  'Q648 Other specified congenital malformations of urinary system',
  'Q649 Congenital malformation of urinary system, unspecified',
  'Q6500 Congenital dislocation of unspecified hip, unilateral',
  'Q6501 Congenital dislocation of right hip, unilateral',
  'Q6502 Congenital dislocation of left hip, unilateral',
  'Q651 Congenital dislocation of hip, bilateral',
  'Q652 Congenital dislocation of hip, unspecified',
  'Q6530 Congenital partial dislocation of unspecified hip, unilateral',
  'Q6531 Congenital partial dislocation of right hip, unilateral',
  'Q6532 Congenital partial dislocation of left hip, unilateral',
  'Q654 Congenital partial dislocation of hip, bilateral',
  'Q655 Congenital partial dislocation of hip, unspecified',
  'Q656 Congenital unstable hip',
  'Q6581 Congenital coxa valga',
  'Q6582 Congenital coxa vara',
  'Q6589 Other specified congenital deformities of hip',
  'Q659 Congenital deformity of hip, unspecified',
  'Q660 Congenital talipes equinovarus',
  'Q661 Congenital talipes calcaneovarus',
  'Q6621 Congenital metatarsus primus varus',
  'Q6622 Congenital metatarsus adductus',
  'Q663 Other congenital varus deformities of feet',
  'Q664 Congenital talipes calcaneovalgus',
  'Q6650 Congenital pes planus, unspecified foot',
  'Q6651 Congenital pes planus, right foot',
  'Q6652 Congenital pes planus, left foot',
  'Q666 Other congenital valgus deformities of feet',
  'Q667 Congenital pes cavus',
  'Q6680 Congenital vertical talus deformity, unspecified foot',
  'Q6681 Congenital vertical talus deformity, right foot',
  'Q6682 Congenital vertical talus deformity, left foot',
  'Q6689 Other specified congenital deformities of feet',
  'Q669 Congenital deformity of feet, unspecified',
  'Q670 Congenital facial asymmetry',
  'Q671 Congenital compression facies',
  'Q672 Dolichocephaly',
  'Q673 Plagiocephaly',
  'Q674 Other congenital deformities of skull, face and jaw',
  'Q675 Congenital deformity of spine',
  'Q676 Pectus excavatum',
  'Q677 Pectus carinatum',
  'Q678 Other congenital deformities of chest',
  'Q680 Congenital deformity of sternocleidomastoid muscle',
  'Q681 Congenital deformity of finger(s) and hand',
  'Q682 Congenital deformity of knee',
  'Q683 Congenital bowing of femur',
  'Q684 Congenital bowing of tibia and fibula',
  'Q685 Congenital bowing of long bones of leg, unspecified',
  'Q686 Discoid meniscus',
  'Q688 Other specified congenital musculoskeletal deformities',
  'Q690 Accessory finger(s)',
  'Q691 Accessory thumb(s)',
  'Q692 Accessory toe(s)',
  'Q699 Polydactyly, unspecified',
  'Q7000 Fused fingers, unspecified hand',
  'Q7001 Fused fingers, right hand',
  'Q7002 Fused fingers, left hand',
  'Q7003 Fused fingers, bilateral',
  'Q7010 Webbed fingers, unspecified hand',
  'Q7011 Webbed fingers, right hand',
  'Q7012 Webbed fingers, left hand',
  'Q7013 Webbed fingers, bilateral',
  'Q7020 Fused toes, unspecified foot',
  'Q7021 Fused toes, right foot',
  'Q7022 Fused toes, left foot',
  'Q7023 Fused toes, bilateral',
  'Q7030 Webbed toes, unspecified foot',
  'Q7031 Webbed toes, right foot',
  'Q7032 Webbed toes, left foot',
  'Q7033 Webbed toes, bilateral',
  'Q704 Polysyndactyly, unspecified',
  'Q709 Syndactyly, unspecified',
  'Q7100 Congenital complete absence of unspecified upper limb',
  'Q7101 Congenital complete absence of right upper limb',
  'Q7102 Congenital complete absence of left upper limb',
  'Q7103 Congenital complete absence of upper limb, bilateral',
  'Q7110 Congenital absence of unspecified upper arm and forearm with hand present',
  'Q7111 Congenital absence of right upper arm and forearm with hand present',
  'Q7112 Congenital absence of left upper arm and forearm with hand present',
  'Q7113 Congenital absence of upper arm and forearm with hand present, bilateral',
  'Q7120 Congenital absence of both forearm and hand, unspecified upper limb',
  'Q7121 Congenital absence of both forearm and hand, right upper limb',
  'Q7122 Congenital absence of both forearm and hand, left upper limb',
  'Q7123 Congenital absence of both forearm and hand, bilateral',
  'Q7130 Congenital absence of unspecified hand and finger',
  'Q7131 Congenital absence of right hand and finger',
  'Q7132 Congenital absence of left hand and finger',
  'Q7133 Congenital absence of hand and finger, bilateral',
  'Q7140 Longitudinal reduction defect of unspecified radius',
  'Q7141 Longitudinal reduction defect of right radius',
  'Q7142 Longitudinal reduction defect of left radius',
  'Q7143 Longitudinal reduction defect of radius, bilateral',
  'Q7150 Longitudinal reduction defect of unspecified ulna',
  'Q7151 Longitudinal reduction defect of right ulna',
  'Q7152 Longitudinal reduction defect of left ulna',
  'Q7153 Longitudinal reduction defect of ulna, bilateral',
  'Q7160 Lobster-claw hand, unspecified hand',
  'Q7161 Lobster-claw right hand',
  'Q7162 Lobster-claw left hand',
  'Q7163 Lobster-claw hand, bilateral',
  'Q71811 Congenital shortening of right upper limb',
  'Q71812 Congenital shortening of left upper limb',
  'Q71813 Congenital shortening of upper limb, bilateral',
  'Q71819 Congenital shortening of unspecified upper limb',
  'Q71891 Other reduction defects of right upper limb',
  'Q71892 Other reduction defects of left upper limb',
  'Q71893 Other reduction defects of upper limb, bilateral',
  'Q71899 Other reduction defects of unspecified upper limb',
  'Q7190 Unspecified reduction defect of unspecified upper limb',
  'Q7191 Unspecified reduction defect of right upper limb',
  'Q7192 Unspecified reduction defect of left upper limb',
  'Q7193 Unspecified reduction defect of upper limb, bilateral',
  'Q7200 Congenital complete absence of unspecified lower limb',
  'Q7201 Congenital complete absence of right lower limb',
  'Q7202 Congenital complete absence of left lower limb',
  'Q7203 Congenital complete absence of lower limb, bilateral',
  'Q7210 Congenital absence of unspecified thigh and lower leg with foot present',
  'Q7211 Congenital absence of right thigh and lower leg with foot present',
  'Q7212 Congenital absence of left thigh and lower leg with foot present',
  'Q7213 Congenital absence of thigh and lower leg with foot present, bilateral',
  'Q7220 Congenital absence of both lower leg and foot, unspecified lower limb',
  'Q7221 Congenital absence of both lower leg and foot, right lower limb',
  'Q7222 Congenital absence of both lower leg and foot, left lower limb',
  'Q7223 Congenital absence of both lower leg and foot, bilateral',
  'Q7230 Congenital absence of unspecified foot and toe(s)',
  'Q7231 Congenital absence of right foot and toe(s)',
  'Q7232 Congenital absence of left foot and toe(s)',
  'Q7233 Congenital absence of foot and toe(s), bilateral',
  'Q7240 Longitudinal reduction defect of unspecified femur',
  'Q7241 Longitudinal reduction defect of right femur',
  'Q7242 Longitudinal reduction defect of left femur',
  'Q7243 Longitudinal reduction defect of femur, bilateral',
  'Q7250 Longitudinal reduction defect of unspecified tibia',
  'Q7251 Longitudinal reduction defect of right tibia',
  'Q7252 Longitudinal reduction defect of left tibia',
  'Q7253 Longitudinal reduction defect of tibia, bilateral',
  'Q7260 Longitudinal reduction defect of unspecified fibula',
  'Q7261 Longitudinal reduction defect of right fibula',
  'Q7262 Longitudinal reduction defect of left fibula',
  'Q7263 Longitudinal reduction defect of fibula, bilateral',
  'Q7270 Split foot, unspecified lower limb',
  'Q7271 Split foot, right lower limb',
  'Q7272 Split foot, left lower limb',
  'Q7273 Split foot, bilateral',
  'Q72811 Congenital shortening of right lower limb',
  'Q72812 Congenital shortening of left lower limb',
  'Q72813 Congenital shortening of lower limb, bilateral',
  'Q72819 Congenital shortening of unspecified lower limb',
  'Q72891 Other reduction defects of right lower limb',
  'Q72892 Other reduction defects of left lower limb',
  'Q72893 Other reduction defects of lower limb, bilateral',
  'Q72899 Other reduction defects of unspecified lower limb',
  'Q7290 Unspecified reduction defect of unspecified lower limb',
  'Q7291 Unspecified reduction defect of right lower limb',
  'Q7292 Unspecified reduction defect of left lower limb',
  'Q7293 Unspecified reduction defect of lower limb, bilateral',
  'Q730 Congenital absence of unspecified limb(s)',
  'Q731 Phocomelia, unspecified limb(s)',
  'Q738 Other reduction defects of unspecified limb(s)',
  'Q740 Other congenital malformations of upper limb(s), including shoulder girdle',
  'Q741 Congenital malformation of knee',
  'Q742 Other congenital malformations of lower limb(s), including pelvic girdle',
  'Q743 Arthrogryposis multiplex congenita',
  'Q748 Other specified congenital malformations of limb(s)',
  'Q749 Unspecified congenital malformation of limb(s)',
  'Q750 Craniosynostosis',
  'Q751 Craniofacial dysostosis',
  'Q752 Hypertelorism',
  'Q753 Macrocephaly',
  'Q754 Mandibulofacial dysostosis',
  'Q755 Oculomandibular dysostosis',
  'Q758 Other specified congenital malformations of skull and face bones',
  'Q759 Congenital malformation of skull and face bones, unspecified',
  'Q760 Spina bifida occulta',
  'Q761 Klippel-Feil syndrome',
  'Q762 Congenital spondylolisthesis',
  'Q763 Congenital scoliosis due to congenital bony malformation',
  'Q76411 Congenital kyphosis, occipito-atlanto-axial region',
  'Q76412 Congenital kyphosis, cervical region',
  'Q76413 Congenital kyphosis, cervicothoracic region',
  'Q76414 Congenital kyphosis, thoracic region',
  'Q76415 Congenital kyphosis, thoracolumbar region',
  'Q76419 Congenital kyphosis, unspecified region',
  'Q76425 Congenital lordosis, thoracolumbar region',
  'Q76426 Congenital lordosis, lumbar region',
  'Q76427 Congenital lordosis, lumbosacral region',
  'Q76428 Congenital lordosis, sacral and sacrococcygeal region',
  'Q76429 Congenital lordosis, unspecified region',
  'Q7649 Other congenital malformations of spine, not associated with scoliosis',
  'Q765 Cervical rib',
  'Q766 Other congenital malformations of ribs',
  'Q767 Congenital malformation of sternum',
  'Q768 Other congenital malformations of bony thorax',
  'Q769 Congenital malformation of bony thorax, unspecified',
  'Q770 Achondrogenesis',
  'Q771 Thanatophoric short stature',
  'Q772 Short rib syndrome',
  'Q773 Chondrodysplasia punctata',
  'Q774 Achondroplasia',
  'Q775 Diastrophic dysplasia',
  'Q776 Chondroectodermal dysplasia',
  'Q777 Spondyloepiphyseal dysplasia',
  'Q778 Other osteochondrodysplasia with defects of growth of tubular bones and spine',
  'Q779 Osteochondrodysplasia with defects of growth of tubular bones and spine, unspecified',
  'Q780 Osteogenesis imperfecta',
  'Q781 Polyostotic fibrous dysplasia',
  'Q782 Osteopetrosis',
  'Q783 Progressive diaphyseal dysplasia',
  'Q784 Enchondromatosis',
  'Q785 Metaphyseal dysplasia',
  'Q786 Multiple congenital exostoses',
  'Q788 Other specified osteochondrodysplasias',
  'Q789 Osteochondrodysplasia, unspecified',
  'Q790 Congenital diaphragmatic hernia',
  'Q791 Other congenital malformations of diaphragm',
  'Q792 Exomphalos',
  'Q793 Gastroschisis',
  'Q794 Prune belly syndrome',
  'Q7951 Congenital hernia of bladder',
  'Q7959 Other congenital malformations of abdominal wall',
  'Q796 Ehlers-Danlos syndrome',
  'Q798 Other congenital malformations of musculoskeletal system',
  'Q799 Congenital malformation of musculoskeletal system, unspecified',
  'Q800 Ichthyosis vulgaris',
  'Q801 X-linked ichthyosis',
  'Q802 Lamellar ichthyosis',
  'Q803 Congenital bullous ichthyosiform erythroderma',
  'Q804 Harlequin fetus',
  'Q808 Other congenital ichthyosis',
  'Q809 Congenital ichthyosis, unspecified',
  'Q810 Epidermolysis bullosa simplex',
  'Q811 Epidermolysis bullosa letalis',
  'Q812 Epidermolysis bullosa dystrophica',
  'Q818 Other epidermolysis bullosa',
  'Q819 Epidermolysis bullosa, unspecified',
  'Q820 Hereditary lymphedema',
  'Q821 Xeroderma pigmentosum',
  'Q822 Mastocytosis',
  'Q823 Incontinentia pigmenti',
  'Q824 Ectodermal dysplasia (anhidrotic)',
  'Q825 Congenital non-neoplastic nevus',
  'Q826 Congenital sacral dimple',
  'Q828 Other specified congenital malformations of skin',
  'Q829 Congenital malformation of skin, unspecified',
  'Q830 Congenital absence of breast with absent nipple',
  'Q831 Accessory breast',
  'Q832 Absent nipple',
  'Q833 Accessory nipple',
  'Q838 Other congenital malformations of breast',
  'Q839 Congenital malformation of breast, unspecified',
  'Q840 Congenital alopecia',
  'Q841 Congenital morphological disturbances of hair, not elsewhere classified',
  'Q842 Other congenital malformations of hair',
  'Q843 Anonychia',
  'Q844 Congenital leukonychia',
  'Q845 Enlarged and hypertrophic nails',
  'Q846 Other congenital malformations of nails',
  'Q848 Other specified congenital malformations of integument',
  'Q849 Congenital malformation of integument, unspecified',
  'Q8500 Neurofibromatosis, unspecified',
  'Q8501 Neurofibromatosis, type 1',
  'Q8502 Neurofibromatosis, type 2',
  'Q8503 Schwannomatosis',
  'Q8509 Other neurofibromatosis',
  'Q851 Tuberous sclerosis',
  'Q858 Other phakomatoses, not elsewhere classified',
  'Q859 Phakomatosis, unspecified',
  'Q860 Fetal alcohol syndrome (dysmorphic)',
  'Q861 Fetal hydantoin syndrome',
  'Q862 Dysmorphism due to warfarin',
  'Q868 Other congenital malformation syndromes due to known exogenous causes',
  'Q870 Congenital malformation syndromes predominantly affecting facial appearance',
  'Q871 Congenital malformation syndromes predominantly associated with short stature',
  'Q872 Congenital malformation syndromes predominantly involving limbs',
  'Q873 Congenital malformation syndromes involving early overgrowth',
  "Q8740 Marfan's syndrome, unspecified",
  "Q87410 Marfan's syndrome with aortic dilation",
  "Q87418 Marfan's syndrome with other cardiovascular manifestations",
  "Q8742 Marfan's syndrome with ocular manifestations",
  "Q8743 Marfan's syndrome with skeletal manifestation",
  'Q875 Other congenital malformation syndromes with other skeletal changes',
  'Q8781 Alport syndrome',
  'Q8782 Arterial tortuosity syndrome',
  'Q8789 Other specified congenital malformation syndromes, not elsewhere classified',
  'Q8901 Asplenia (congenital)',
  'Q8909 Congenital malformations of spleen',
  'Q891 Congenital malformations of adrenal gland',
  'Q892 Congenital malformations of other endocrine glands',
  'Q893 Situs inversus',
  'Q894 Conjoined twins',
  'Q897 Multiple congenital malformations, not elsewhere classified',
  'Q898 Other specified congenital malformations',
  'Q899 Congenital malformation, unspecified',
  'Q900 Trisomy 21, nonmosaicism (meiotic nondisjunction)',
  'Q901 Trisomy 21, mosaicism (mitotic nondisjunction)',
  'Q902 Trisomy 21, translocation',
  'Q909 Down syndrome, unspecified',
  'Q910 Trisomy 18, nonmosaicism (meiotic nondisjunction)',
  'Q911 Trisomy 18, mosaicism (mitotic nondisjunction)',
  'Q912 Trisomy 18, translocation',
  'Q913 Trisomy 18, unspecified',
  'Q914 Trisomy 13, nonmosaicism (meiotic nondisjunction)',
  'Q915 Trisomy 13, mosaicism (mitotic nondisjunction)',
  'Q916 Trisomy 13, translocation',
  'Q917 Trisomy 13, unspecified',
  'Q920 Whole chromosome trisomy, nonmosaicism (meiotic nondisjunction)',
  'Q921 Whole chromosome trisomy, mosaicism (mitotic nondisjunction)',
  'Q922 Partial trisomy',
  'Q925 Duplications with other complex rearrangements',
  'Q9261 Marker chromosomes in normal individual',
  'Q9262 Marker chromosomes in abnormal individual',
  'Q927 Triploidy and polyploidy',
  'Q928 Other specified trisomies and partial trisomies of autosomes',
  'Q929 Trisomy and partial trisomy of autosomes, unspecified',
  'Q930 Whole chromosome monosomy, nonmosaicism (meiotic nondisjunction)',
  'Q931 Whole chromosome monosomy, mosaicism (mitotic nondisjunction)',
  'Q932 Chromosome replaced with ring, dicentric or isochromosome',
  'Q933 Deletion of short arm of chromosome 4',
  'Q934 Deletion of short arm of chromosome 5',
  'Q935 Other deletions of part of a chromosome',
  'Q937 Deletions with other complex rearrangements',
  'Q9381 Velo-cardio-facial syndrome',
  'Q9388 Other microdeletions',
  'Q9389 Other deletions from the autosomes',
  'Q939 Deletion from autosomes, unspecified',
  'Q950 Balanced translocation and insertion in normal individual',
  'Q951 Chromosome inversion in normal individual',
  'Q952 Balanced autosomal rearrangement in abnormal individual',
  'Q953 Balanced sex/autosomal rearrangement in abnormal individual',
  'Q955 Individual with autosomal fragile site',
  'Q958 Other balanced rearrangements and structural markers',
  'Q959 Balanced rearrangement and structural marker, unspecified',
  'Q960 Karyotype 45, X',
  'Q961 Karyotype 46, X iso (Xq)',
  'Q962 Karyotype 46, X with abnormal sex chromosome, except iso (Xq)',
  'Q963 Mosaicism, 45, X/46, XX or XY',
  'Q964 Mosaicism, 45, X/other cell line(s) with abnormal sex chromosome',
  "Q968 Other variants of Turner's syndrome",
  "Q969 Turner's syndrome, unspecified",
  'Q970 Karyotype 47, XXX',
  'Q971 Female with more than three X chromosomes',
  'Q972 Mosaicism, lines with various numbers of X chromosomes',
  'Q973 Female with 46, XY karyotype',
  'Q978 Other specified sex chromosome abnormalities, female phenotype',
  'Q979 Sex chromosome abnormality, female phenotype, unspecified',
  'Q980 Klinefelter syndrome karyotype 47, XXY',
  'Q981 Klinefelter syndrome, male with more than two X chromosomes',
  'Q983 Other male with 46, XX karyotype',
  'Q984 Klinefelter syndrome, unspecified',
  'Q985 Karyotype 47, XYY',
  'Q986 Male with structurally abnormal sex chromosome',
  'Q987 Male with sex chromosome mosaicism',
  'Q988 Other specified sex chromosome abnormalities, male phenotype',
  'Q989 Sex chromosome abnormality, male phenotype, unspecified',
  'Q990 Chimera 46, XX/46, XY',
  'Q991 46, XX true hermaphrodite',
  'Q992 Fragile X chromosome',
  'Q998 Other specified chromosome abnormalities',
  'Q999 Chromosomal abnormality, unspecified',
  'R000 Tachycardia, unspecified',
  'R001 Bradycardia, unspecified',
  'R002 Palpitations',
  'R008 Other abnormalities of heart beat',
  'R009 Unspecified abnormalities of heart beat',
  'R010 Benign and innocent cardiac murmurs',
  'R011 Cardiac murmur, unspecified',
  'R012 Other cardiac sounds',
  'R030 Elevated blood-pressure reading, without diagnosis of hypertension',
  'R031 Nonspecific low blood-pressure reading',
  'R040 Epistaxis',
  'R041 Hemorrhage from throat',
  'R042 Hemoptysis',
  'R0481 Acute idiopathic pulmonary hemorrhage in infants',
  'R0489 Hemorrhage from other sites in respiratory passages',
  'R049 Hemorrhage from respiratory passages, unspecified',
  'R05 Cough',
  'R0600 Dyspnea, unspecified',
  'R0601 Orthopnea',
  'R0602 Shortness of breath',
  'R0609 Other forms of dyspnea',
  'R061 Stridor',
  'R062 Wheezing',
  'R063 Periodic breathing',
  'R064 Hyperventilation',
  'R065 Mouth breathing',
  'R066 Hiccough',
  'R067 Sneezing',
  'R0681 Apnea, not elsewhere classified',
  'R0682 Tachypnea, not elsewhere classified',
  'R0683 Snoring',
  'R0689 Other abnormalities of breathing',
  'R069 Unspecified abnormalities of breathing',
  'R070 Pain in throat',
  'R071 Chest pain on breathing',
  'R072 Precordial pain',
  'R0781 Pleurodynia',
  'R0782 Intercostal pain',
  'R0789 Other chest pain',
  'R079 Chest pain, unspecified',
  'R0901 Asphyxia',
  'R0902 Hypoxemia',
  'R091 Pleurisy',
  'R092 Respiratory arrest',
  'R093 Abnormal sputum',
  'R0981 Nasal congestion',
  'R0982 Postnasal drip',
  'R0989 Other specified symptoms and signs involving the circulatory and respiratory systems',
  'R100 Acute abdomen',
  'R1010 Upper abdominal pain, unspecified',
  'R1011 Right upper quadrant pain',
  'R1012 Left upper quadrant pain',
  'R1013 Epigastric pain',
  'R102 Pelvic and perineal pain',
  'R1030 Lower abdominal pain, unspecified',
  'R1031 Right lower quadrant pain',
  'R1032 Left lower quadrant pain',
  'R1033 Periumbilical pain',
  'R10811 Right upper quadrant abdominal tenderness',
  'R10812 Left upper quadrant abdominal tenderness',
  'R10813 Right lower quadrant abdominal tenderness',
  'R10814 Left lower quadrant abdominal tenderness',
  'R10815 Periumbilic abdominal tenderness',
  'R10816 Epigastric abdominal tenderness',
  'R10817 Generalized abdominal tenderness',
  'R10819 Abdominal tenderness, unspecified site',
  'R10821 Right upper quadrant rebound abdominal tenderness',
  'R10822 Left upper quadrant rebound abdominal tenderness',
  'R10823 Right lower quadrant rebound abdominal tenderness',
  'R10824 Left lower quadrant rebound abdominal tenderness',
  'R10825 Periumbilic rebound abdominal tenderness',
  'R10826 Epigastric rebound abdominal tenderness',
  'R10827 Generalized rebound abdominal tenderness',
  'R10829 Rebound abdominal tenderness, unspecified site',
  'R1083 Colic',
  'R1084 Generalized abdominal pain',
  'R109 Unspecified abdominal pain',
  'R110 Nausea',
  'R1110 Vomiting, unspecified',
  'R1111 Vomiting without nausea',
  'R1112 Projectile vomiting',
  'R1113 Vomiting of fecal matter',
  'R1114 Bilious vomiting',
  'R112 Nausea with vomiting, unspecified',
  'R12 Heartburn',
  'R130 Aphagia',
  'R1310 Dysphagia, unspecified',
  'R1311 Dysphagia, oral phase',
  'R1312 Dysphagia, oropharyngeal phase',
  'R1313 Dysphagia, pharyngeal phase',
  'R1314 Dysphagia, pharyngoesophageal phase',
  'R1319 Other dysphagia',
  'R140 Abdominal distension (gaseous)',
  'R141 Gas pain',
  'R142 Eructation',
  'R143 Flatulence',
  'R150 Incomplete defecation',
  'R151 Fecal smearing',
  'R152 Fecal urgency',
  'R159 Full incontinence of feces',
  'R160 Hepatomegaly, not elsewhere classified',
  'R161 Splenomegaly, not elsewhere classified',
  'R162 Hepatomegaly with splenomegaly, not elsewhere classified',
  'R17 Unspecified jaundice',
  'R180 Malignant ascites',
  'R188 Other ascites',
  'R1900 Intra-abdominal and pelvic swelling, mass and lump, unspecified site',
  'R1901 Right upper quadrant abdominal swelling, mass and lump',
  'R1902 Left upper quadrant abdominal swelling, mass and lump',
  'R1903 Right lower quadrant abdominal swelling, mass and lump',
  'R1904 Left lower quadrant abdominal swelling, mass and lump',
  'R1905 Periumbilic swelling, mass or lump',
  'R1906 Epigastric swelling, mass or lump',
  'R1907 Generalized intra-abdominal and pelvic swelling, mass and lump',
  'R1909 Other intra-abdominal and pelvic swelling, mass and lump',
  'R1911 Absent bowel sounds',
  'R1912 Hyperactive bowel sounds',
  'R1915 Other abnormal bowel sounds',
  'R192 Visible peristalsis',
  'R1930 Abdominal rigidity, unspecified site',
  'R1931 Right upper quadrant abdominal rigidity',
  'R1932 Left upper quadrant abdominal rigidity',
  'R1933 Right lower quadrant abdominal rigidity',
  'R1934 Left lower quadrant abdominal rigidity',
  'R1935 Periumbilic abdominal rigidity',
  'R1936 Epigastric abdominal rigidity',
  'R1937 Generalized abdominal rigidity',
  'R194 Change in bowel habit',
  'R195 Other fecal abnormalities',
  'R196 Halitosis',
  'R197 Diarrhea, unspecified',
  'R198 Other specified symptoms and signs involving the digestive system and abdomen',
  'R200 Anesthesia of skin',
  'R201 Hypoesthesia of skin',
  'R202 Paresthesia of skin',
  'R203 Hyperesthesia',
  'R208 Other disturbances of skin sensation',
  'R209 Unspecified disturbances of skin sensation',
  'R21 Rash and other nonspecific skin eruption',
  'R220 Localized swelling, mass and lump, head',
  'R221 Localized swelling, mass and lump, neck',
  'R222 Localized swelling, mass and lump, trunk',
  'R2230 Localized swelling, mass and lump, unspecified upper limb',
  'R2231 Localized swelling, mass and lump, right upper limb',
  'R2232 Localized swelling, mass and lump, left upper limb',
  'R2233 Localized swelling, mass and lump, upper limb, bilateral',
  'R2240 Localized swelling, mass and lump, unspecified lower limb',
  'R2241 Localized swelling, mass and lump, right lower limb',
  'R2242 Localized swelling, mass and lump, left lower limb',
  'R2243 Localized swelling, mass and lump, lower limb, bilateral',
  'R229 Localized swelling, mass and lump, unspecified',
  'R230 Cyanosis',
  'R231 Pallor',
  'R232 Flushing',
  'R233 Spontaneous ecchymoses',
  'R234 Changes in skin texture',
  'R238 Other skin changes',
  'R239 Unspecified skin changes',
  'R250 Abnormal head movements',
  'R251 Tremor, unspecified',
  'R252 Cramp and spasm',
  'R253 Fasciculation',
  'R258 Other abnormal involuntary movements',
  'R259 Unspecified abnormal involuntary movements',
  'R260 Ataxic gait',
  'R261 Paralytic gait',
  'R262 Difficulty in walking, not elsewhere classified',
  'R2681 Unsteadiness on feet',
  'R2689 Other abnormalities of gait and mobility',
  'R269 Unspecified abnormalities of gait and mobility',
  'R270 Ataxia, unspecified',
  'R278 Other lack of coordination',
  'R279 Unspecified lack of coordination',
  'R290 Tetany',
  'R291 Meningismus',
  'R292 Abnormal reflex',
  'R293 Abnormal posture',
  'R294 Clicking hip',
  'R295 Transient paralysis',
  'R296 Repeated falls',
  'R29700 NIHSS score 0',
  'R29701 NIHSS score 1',
  'R29702 NIHSS score 2',
  'R29703 NIHSS score 3',
  'R29704 NIHSS score 4',
  'R29705 NIHSS score 5',
  'R29706 NIHSS score 6',
  'R29707 NIHSS score 7',
  'R29708 NIHSS score 8',
  'R29709 NIHSS score 9',
  'R29710 NIHSS score 10',
  'R29711 NIHSS score 11',
  'R29712 NIHSS score 12',
  'R29713 NIHSS score 13',
  'R29714 NIHSS score 14',
  'R29715 NIHSS score 15',
  'R29716 NIHSS score 16',
  'R29717 NIHSS score 17',
  'R29718 NIHSS score 18',
  'R29719 NIHSS score 19',
  'R29720 NIHSS score 20',
  'R29721 NIHSS score 21',
  'R29722 NIHSS score 22',
  'R29723 NIHSS score 23',
  'R29724 NIHSS score 24',
  'R29725 NIHSS score 25',
  'R29726 NIHSS score 26',
  'R29727 NIHSS score 27',
  'R29728 NIHSS score 28',
  'R29729 NIHSS score 29',
  'R29730 NIHSS score 30',
  'R29731 NIHSS score 31',
  'R29732 NIHSS score 32',
  'R29733 NIHSS score 33',
  'R29734 NIHSS score 34',
  'R29735 NIHSS score 35',
  'R29736 NIHSS score 36',
  'R29737 NIHSS score 37',
  'R29738 NIHSS score 38',
  'R29739 NIHSS score 39',
  'R29740 NIHSS score 40',
  'R29741 NIHSS score 41',
  'R29742 NIHSS score 42',
  'R29810 Facial weakness',
  'R29818 Other symptoms and signs involving the nervous system',
  'R29890 Loss of height',
  'R29891 Ocular torticollis',
  'R29898 Other symptoms and signs involving the musculoskeletal system',
  'R2990 Unspecified symptoms and signs involving the nervous system',
  'R2991 Unspecified symptoms and signs involving the musculoskeletal system',
  'R300 Dysuria',
  'R301 Vesical tenesmus',
  'R309 Painful micturition, unspecified',
  'R310 Gross hematuria',
  'R311 Benign essential microscopic hematuria',
  'R3121 Asymptomatic microscopic hematuria',
  'R3129 Other microscopic hematuria',
  'R319 Hematuria, unspecified',
  'R32 Unspecified urinary incontinence',
  'R330 Drug induced retention of urine',
  'R338 Other retention of urine',
  'R339 Retention of urine, unspecified',
  'R34 Anuria and oliguria',
  'R350 Frequency of micturition',
  'R351 Nocturia',
  'R358 Other polyuria',
  'R360 Urethral discharge without blood',
  'R361 Hematospermia',
  'R369 Urethral discharge, unspecified',
  'R37 Sexual dysfunction, unspecified',
  'R390 Extravasation of urine',
  'R3911 Hesitancy of micturition',
  'R3912 Poor urinary stream',
  'R3913 Splitting of urinary stream',
  'R3914 Feeling of incomplete bladder emptying',
  'R3915 Urgency of urination',
  'R3916 Straining to void',
  'R39191 Need to immediately re-void',
  'R39192 Position dependent micturition',
  'R39198 Other difficulties with micturition',
  'R392 Extrarenal uremia',
  'R3981 Functional urinary incontinence',
  'R3982 Chronic bladder pain',
  'R3989 Other symptoms and signs involving the genitourinary system',
  'R399 Unspecified symptoms and signs involving the genitourinary system',
  'R400 Somnolence',
  'R401 Stupor',
  'R4020 Unspecified coma',
  'R402110 Coma scale, eyes open, never, unspecified time',
  'R402111 Coma scale, eyes open, never, in the field [EMT or ambulance]',
  'R402112 Coma scale, eyes open, never, at arrival to emergency department',
  'R402113 Coma scale, eyes open, never, at hospital admission',
  'R402114 Coma scale, eyes open, never, 24 hours or more after hospital admission',
  'R402120 Coma scale, eyes open, to pain, unspecified time',
  'R402121 Coma scale, eyes open, to pain, in the field [EMT or ambulance]',
  'R402122 Coma scale, eyes open, to pain, at arrival to emergency department',
  'R402123 Coma scale, eyes open, to pain, at hospital admission',
  'R402124 Coma scale, eyes open, to pain, 24 hours or more after hospital admission',
  'R402130 Coma scale, eyes open, to sound, unspecified time',
  'R402131 Coma scale, eyes open, to sound, in the field [EMT or ambulance]',
  'R402132 Coma scale, eyes open, to sound, at arrival to emergency department',
  'R402133 Coma scale, eyes open, to sound, at hospital admission',
  'R402134 Coma scale, eyes open, to sound, 24 hours or more after hospital admission',
  'R402140 Coma scale, eyes open, spontaneous, unspecified time',
  'R402141 Coma scale, eyes open, spontaneous, in the field [EMT or ambulance]',
  'R402142 Coma scale, eyes open, spontaneous, at arrival to emergency department',
  'R402143 Coma scale, eyes open, spontaneous, at hospital admission',
  'R402144 Coma scale, eyes open, spontaneous, 24 hours or more after hospital admission',
  'R402210 Coma scale, best verbal response, none, unspecified time',
  'R402211 Coma scale, best verbal response, none, in the field [EMT or ambulance]',
  'R402212 Coma scale, best verbal response, none, at arrival to emergency department',
  'R402213 Coma scale, best verbal response, none, at hospital admission',
  'R402214 Coma scale, best verbal response, none, 24 hours or more after hospital admission',
  'R402220 Coma scale, best verbal response, incomprehensible words, unspecified time',
  'R402221 Coma scale, best verbal response, incomprehensible words, in the field [EMT or ambulance]',
  'R402222 Coma scale, best verbal response, incomprehensible words, at arrival to emergency',
  'department',
  'R402223 Coma scale, best verbal response, incomprehensible words, at hospital admission',
  'R402224 Coma scale, best verbal response, incomprehensible words, 24 hours or more after hospital',
  'admission',
  'R402230 Coma scale, best verbal response, inappropriate words, unspecified time',
  'R402231 Coma scale, best verbal response, inappropriate words, in the field [EMT or ambulance]',
  'R402232 Coma scale, best verbal response, inappropriate words, at arrival to emergency department',
  'R402233 Coma scale, best verbal response, inappropriate words, at hospital admission',
  'R402234 Coma scale, best verbal response, inappropriate words, 24 hours or more after hospital',
  'admission',
  'R402240 Coma scale, best verbal response, confused conversation, unspecified time',
  'R402241 Coma scale, best verbal response, confused conversation, in the field [EMT or ambulance]',
  'R402242 Coma scale, best verbal response, confused conversation, at arrival to emergency department',
  'R402243 Coma scale, best verbal response, confused conversation, at hospital admission',
  'R402244 Coma scale, best verbal response, confused conversation, 24 hours or more after hospital',
  'admission',
  'R402250 Coma scale, best verbal response, oriented, unspecified time',
  'R402251 Coma scale, best verbal response, oriented, in the field [EMT or ambulance]',
  'R402252 Coma scale, best verbal response, oriented, at arrival to emergency department',
  'R402253 Coma scale, best verbal response, oriented, at hospital admission',
  'R402254 Coma scale, best verbal response, oriented, 24 hours or more after hospital admission',
  'R402310 Coma scale, best motor response, none, unspecified time',
  'R402311 Coma scale, best motor response, none, in the field [EMT or ambulance]',
  'R402312 Coma scale, best motor response, none, at arrival to emergency department',
  'R402313 Coma scale, best motor response, none, at hospital admission',
  'R402314 Coma scale, best motor response, none, 24 hours or more after hospital admission',
  'R402320 Coma scale, best motor response, extension, unspecified time',
  'R402321 Coma scale, best motor response, extension, in the field [EMT or ambulance]',
  'R402322 Coma scale, best motor response, extension, at arrival to emergency department',
  'R402323 Coma scale, best motor response, extension, at hospital admission',
  'R402324 Coma scale, best motor response, extension, 24 hours or more after hospital admission',
  'R402330 Coma scale, best motor response, abnormal, unspecified time',
  'R402331 Coma scale, best motor response, abnormal, in the field [EMT or ambulance]',
  'R402332 Coma scale, best motor response, abnormal, at arrival to emergency department',
  'R402333 Coma scale, best motor response, abnormal, at hospital admission',
  'R402334 Coma scale, best motor response, abnormal, 24 hours or more after hospital admission',
  'R402340 Coma scale, best motor response, flexion withdrawal, unspecified time',
  'R402341 Coma scale, best motor response, flexion withdrawal, in the field [EMT or ambulance]',
  'R402342 Coma scale, best motor response, flexion withdrawal, at arrival to emergency department',
  'R402343 Coma scale, best motor response, flexion withdrawal, at hospital admission',
  'R402344 Coma scale, best motor response, flexion withdrawal, 24 hours or more after hospital',
  'admission',
  'R402350 Coma scale, best motor response, localizes pain, unspecified time',
  'R402351 Coma scale, best motor response, localizes pain, in the field [EMT or ambulance]',
  'R402352 Coma scale, best motor response, localizes pain, at arrival to emergency department',
  'R402353 Coma scale, best motor response, localizes pain, at hospital admission',
  'R402354 Coma scale, best motor response, localizes pain, 24 hours or more after hospital admission',
  'R402360 Coma scale, best motor response, obeys commands, unspecified time',
  'R402361 Coma scale, best motor response, obeys commands, in the field [EMT or ambulance]',
  'R402362 Coma scale, best motor response, obeys commands, at arrival to emergency department',
  'R402363 Coma scale, best motor response, obeys commands, at hospital admission',
  'R402364 Coma scale, best motor response, obeys commands, 24 hours or more after hospital admission',
  'R402410 Glasgow coma scale score 13-15, unspecified time',
  'R402411 Glasgow coma scale score 13-15, in the field [EMT or ambulance]',
  'R402412 Glasgow coma scale score 13-15, at arrival to emergency department',
  'R402413 Glasgow coma scale score 13-15, at hospital admission',
  'R402414 Glasgow coma scale score 13-15, 24 hours or more after hospital admission',
  'R402420 Glasgow coma scale score 9-12, unspecified time',
  'R402421 Glasgow coma scale score 9-12, in the field [EMT or ambulance]',
  'R402422 Glasgow coma scale score 9-12, at arrival to emergency department',
  'R402423 Glasgow coma scale score 9-12, at hospital admission',
  'R402424 Glasgow coma scale score 9-12, 24 hours or more after hospital admission',
  'R402430 Glasgow coma scale score 3-8, unspecified time',
  'R402431 Glasgow coma scale score 3-8, in the field [EMT or ambulance]',
  'R402432 Glasgow coma scale score 3-8, at arrival to emergency department',
  'R402433 Glasgow coma scale score 3-8, at hospital admission',
  'R402434 Glasgow coma scale score 3-8, 24 hours or more after hospital admission',
  'R402440 Other coma, without documented Glasgow coma scale score, or with partial score reported,',
  'unspecified time',
  'R402441 Other coma, without documented Glasgow coma scale score, or with partial score reported, in',
  'the field [EMT or ambulance]',
  'R402442 Other coma, without documented Glasgow coma scale score, or with partial score reported, at',
  'arrival to emergency department',
  'R402443 Other coma, without documented Glasgow coma scale score, or with partial score reported, at',
  'hospital admission',
  'R402444 Other coma, without documented Glasgow coma scale score, or with partial score reported, 24',
  'hours or more after hospital admission',
  'R403 Persistent vegetative state',
  'R404 Transient alteration of awareness',
  'R410 Disorientation, unspecified',
  'R411 Anterograde amnesia',
  'R412 Retrograde amnesia',
  'R413 Other amnesia',
  'R414 Neurologic neglect syndrome',
  'R4181 Age-related cognitive decline',
  'R4182 Altered mental status, unspecified',
  'R4183 Borderline intellectual functioning',
  'R41840 Attention and concentration deficit',
  'R41841 Cognitive communication deficit',
  'R41842 Visuospatial deficit',
  'R41843 Psychomotor deficit',
  'R41844 Frontal lobe and executive function deficit',
  'R4189 Other symptoms and signs involving cognitive functions and awareness',
  'R419 Unspecified symptoms and signs involving cognitive functions and awareness',
  'R42 Dizziness and giddiness',
  'R430 Anosmia',
  'R431 Parosmia',
  'R432 Parageusia',
  'R438 Other disturbances of smell and taste',
  'R439 Unspecified disturbances of smell and taste',
  'R440 Auditory hallucinations',
  'R441 Visual hallucinations',
  'R442 Other hallucinations',
  'R443 Hallucinations, unspecified',
  'R448 Other symptoms and signs involving general sensations and perceptions',
  'R449 Unspecified symptoms and signs involving general sensations and perceptions',
  'R450 Nervousness',
  'R451 Restlessness and agitation',
  'R452 Unhappiness',
  'R453 Demoralization and apathy',
  'R454 Irritability and anger',
  'R455 Hostility',
  'R456 Violent behavior',
  'R457 State of emotional shock and stress, unspecified',
  'R4581 Low self-esteem',
  'R4582 Worries',
  'R4583 Excessive crying of child, adolescent or adult',
  'R4584 Anhedonia',
  'R45850 Homicidal ideations',
  'R45851 Suicidal ideations',
  'R4586 Emotional lability',
  'R4587 Impulsiveness',
  'R4589 Other symptoms and signs involving emotional state',
  'R460 Very low level of personal hygiene',
  'R461 Bizarre personal appearance',
  'R462 Strange and inexplicable behavior',
  'R463 Overactivity',
  'R464 Slowness and poor responsiveness',
  'R465 Suspiciousness and marked evasiveness',
  'R466 Undue concern and preoccupation with stressful events',
  'R467 Verbosity and circumstantial detail obscuring reason for contact',
  'R4681 Obsessive-compulsive behavior',
  'R4689 Other symptoms and signs involving appearance and behavior',
  'R4701 Aphasia',
  'R4702 Dysphasia',
  'R471 Dysarthria and anarthria',
  'R4781 Slurred speech',
  'R4782 Fluency disorder in conditions classified elsewhere',
  'R4789 Other speech disturbances',
  'R479 Unspecified speech disturbances',
  'R480 Dyslexia and alexia',
  'R481 Agnosia',
  'R482 Apraxia',
  'R483 Visual agnosia',
  'R488 Other symbolic dysfunctions',
  'R489 Unspecified symbolic dysfunctions',
  'R490 Dysphonia',
  'R491 Aphonia',
  'R4921 Hypernasality',
  'R4922 Hyponasality',
  'R498 Other voice and resonance disorders',
  'R499 Unspecified voice and resonance disorder',
  'R502 Drug induced fever',
  'R5081 Fever presenting with conditions classified elsewhere',
  'R5082 Postprocedural fever',
  'R5083 Postvaccination fever',
  'R5084 Febrile nonhemolytic transfusion reaction',
  'R509 Fever, unspecified',
  'R51 Headache',
  'R52 Pain, unspecified',
  'R530 Neoplastic (malignant) related fatigue',
  'R531 Weakness',
  'R532 Functional quadriplegia',
  'R5381 Other malaise',
  'R5382 Chronic fatigue, unspecified',
  'R5383 Other fatigue',
  'R54 Age-related physical debility',
  'R55 Syncope and collapse',
  'R5600 Simple febrile convulsions',
  'R5601 Complex febrile convulsions',
  'R561 Post traumatic seizures',
  'R569 Unspecified convulsions',
  'R570 Cardiogenic shock',
  'R571 Hypovolemic shock',
  'R578 Other shock',
  'R579 Shock, unspecified',
  'R58 Hemorrhage, not elsewhere classified',
  'R590 Localized enlarged lymph nodes',
  'R591 Generalized enlarged lymph nodes',
  'R599 Enlarged lymph nodes, unspecified',
  'R600 Localized edema',
  'R601 Generalized edema',
  'R609 Edema, unspecified',
  'R61 Generalized hyperhidrosis',
  'R620 Delayed milestone in childhood',
  'R6250 Unspecified lack of expected normal physiological development in childhood',
  'R6251 Failure to thrive (child)',
  'R6252 Short stature (child)',
  'R6259 Other lack of expected normal physiological development in childhood',
  'R627 Adult failure to thrive',
  'R630 Anorexia',
  'R631 Polydipsia',
  'R632 Polyphagia',
  'R633 Feeding difficulties',
  'R634 Abnormal weight loss',
  'R635 Abnormal weight gain',
  'R636 Underweight',
  'R638 Other symptoms and signs concerning food and fluid intake',
  'R64 Cachexia',
  'R6510 Systemic inflammatory response syndrome (SIRS) of non-infectious origin without acute organ',
  'dysfunction',
  'R6511 Systemic inflammatory response syndrome (SIRS) of non-infectious origin with acute organ',
  'dysfunction',
  'R6520 Severe sepsis without septic shock',
  'R6521 Severe sepsis with septic shock',
  'R680 Hypothermia, not associated with low environmental temperature',
  'R6811 Excessive crying of infant (baby)',
  'R6812 Fussy infant (baby)',
  'R6813 Apparent life threatening event in infant (ALTE)',
  'R6819 Other nonspecific symptoms peculiar to infancy',
  'R682 Dry mouth, unspecified',
  'R683 Clubbing of fingers',
  'R6881 Early satiety',
  'R6882 Decreased libido',
  'R6883 Chills (without fever)',
  'R6884 Jaw pain',
  'R6889 Other general symptoms and signs',
  'R69 Illness, unspecified',
  'R700 Elevated erythrocyte sedimentation rate',
  'R701 Abnormal plasma viscosity',
  'R710 Precipitous drop in hematocrit',
  'R718 Other abnormality of red blood cells',
  'R7301 Impaired fasting glucose',
  'R7302 Impaired glucose tolerance (oral)',
  'R7303 Prediabetes',
  'R7309 Other abnormal glucose',
  'R739 Hyperglycemia, unspecified',
  'R740 Nonspecific elevation of levels of transaminase and lactic acid dehydrogenase [LDH]',
  'R748 Abnormal levels of other serum enzymes',
  'R749 Abnormal serum enzyme level, unspecified',
  'R75 Inconclusive laboratory evidence of human immunodeficiency virus [HIV]',
  'R760 Raised antibody titer',
  'R7611 Nonspecific reaction to tuberculin skin test without active tuberculosis',
  'R7612 Nonspecific reaction to cell mediated immunity measurement of gamma interferon antigen',
  'response without active tuberculosis',
  'R768 Other specified abnormal immunological findings in serum',
  'R769 Abnormal immunological finding in serum, unspecified',
  'R770 Abnormality of albumin',
  'R771 Abnormality of globulin',
  'R772 Abnormality of alphafetoprotein',
  'R778 Other specified abnormalities of plasma proteins',
  'R779 Abnormality of plasma protein, unspecified',
  'R780 Finding of alcohol in blood',
  'R781 Finding of opiate drug in blood',
  'R782 Finding of cocaine in blood',
  'R783 Finding of hallucinogen in blood',
  'R784 Finding of other drugs of addictive potential in blood',
  'R785 Finding of other psychotropic drug in blood',
  'R786 Finding of steroid agent in blood',
  'R7871 Abnormal lead level in blood',
  'R7879 Finding of abnormal level of heavy metals in blood',
  'R7881 Bacteremia',
  'R7889 Finding of other specified substances, not normally found in blood',
  'R789 Finding of unspecified substance, not normally found in blood',
  'R790 Abnormal level of blood mineral',
  'R791 Abnormal coagulation profile',
  'R7981 Abnormal blood-gas level',
  'R7982 Elevated C-reactive protein (CRP)',
  'R7989 Other specified abnormal findings of blood chemistry',
  'R799 Abnormal finding of blood chemistry, unspecified',
  'R800 Isolated proteinuria',
  'R801 Persistent proteinuria, unspecified',
  'R802 Orthostatic proteinuria, unspecified',
  'R803 Bence Jones proteinuria',
  'R808 Other proteinuria',
  'R809 Proteinuria, unspecified',
  'R81 Glycosuria',
  'R820 Chyluria',
  'R821 Myoglobinuria',
  'R822 Biliuria',
  'R823 Hemoglobinuria',
  'R824 Acetonuria',
  'R825 Elevated urine levels of drugs, medicaments and biological substances',
  'R826 Abnormal urine levels of substances chiefly nonmedicinal as to source',
  'R8271 Bacteriuria',
  'R8279 Other abnormal findings on microbiological examination of urine',
  'R828 Abnormal findings on cytological and histological examination of urine',
  'R8290 Unspecified abnormal findings in urine',
  'R8291 Other chromoabnormalities of urine',
  'R8299 Other abnormal findings in urine',
  'R830 Abnormal level of enzymes in cerebrospinal fluid',
  'R831 Abnormal level of hormones in cerebrospinal fluid',
  'R832 Abnormal level of other drugs, medicaments and biological substances in cerebrospinal fluid',
  'R833 Abnormal level of substances chiefly nonmedicinal as to source in cerebrospinal fluid',
  'R834 Abnormal immunological findings in cerebrospinal fluid',
  'R835 Abnormal microbiological findings in cerebrospinal fluid',
  'R836 Abnormal cytological findings in cerebrospinal fluid',
  'R838 Other abnormal findings in cerebrospinal fluid',
  'R839 Unspecified abnormal finding in cerebrospinal fluid',
  'R840 Abnormal level of enzymes in specimens from respiratory organs and thorax',
  'R841 Abnormal level of hormones in specimens from respiratory organs and thorax',
  'R842 Abnormal level of other drugs, medicaments and biological substances in specimens from',
  'respiratory organs and thorax',
  'R843 Abnormal level of substances chiefly nonmedicinal as to source in specimens from respiratory',
  'organs and thorax',
  'R844 Abnormal immunological findings in specimens from respiratory organs and thorax',
  'R845 Abnormal microbiological findings in specimens from respiratory organs and thorax',
  'R846 Abnormal cytological findings in specimens from respiratory organs and thorax',
  'R847 Abnormal histological findings in specimens from respiratory organs and thorax',
  'R848 Other abnormal findings in specimens from respiratory organs and thorax',
  'R849 Unspecified abnormal finding in specimens from respiratory organs and thorax',
  'R850 Abnormal level of enzymes in specimens from digestive organs and abdominal cavity',
  'R851 Abnormal level of hormones in specimens from digestive organs and abdominal cavity',
  'R852 Abnormal level of other drugs, medicaments and biological substances in specimens from',
  'digestive organs and abdominal cavity',
  'R853 Abnormal level of substances chiefly nonmedicinal as to source in specimens from digestive',
  'organs and abdominal cavity',
  'R854 Abnormal immunological findings in specimens from digestive organs and abdominal cavity',
  'R855 Abnormal microbiological findings in specimens from digestive organs and abdominal cavity',
  'R85610 Atypical squamous cells of undetermined significance on cytologic smear of anus (ASC-US)',
  'R85611 Atypical squamous cells cannot exclude high grade squamous intraepithelial lesion on cytologic',
  'smear of anus (ASC-H)',
  'R85612 Low grade squamous intraepithelial lesion on cytologic smear of anus (LGSIL)',
  'R85613 High grade squamous intraepithelial lesion on cytologic smear of anus (HGSIL)',
  'R85614 Cytologic evidence of malignancy on smear of anus',
  'R85615 Unsatisfactory cytologic smear of anus',
  'R85616 Satisfactory anal smear but lacking transformation zone',
  'R85618 Other abnormal cytological findings on specimens from anus',
  'R85619 Unspecified abnormal cytological findings in specimens from anus',
  'R8569 Abnormal cytological findings in specimens from other digestive organs and abdominal cavity',
  'R857 Abnormal histological findings in specimens from digestive organs and abdominal cavity',
  'R8581 Anal high risk human papillomavirus (HPV) DNA test positive',
  'R8582 Anal low risk human papillomavirus (HPV) DNA test positive',
  'R8589 Other abnormal findings in specimens from digestive organs and abdominal cavity',
  'R859 Unspecified abnormal finding in specimens from digestive organs and abdominal cavity',
  'R860 Abnormal level of enzymes in specimens from male genital organs',
  'R861 Abnormal level of hormones in specimens from male genital organs',
  'R862 Abnormal level of other drugs, medicaments and biological substances in specimens from male',
  'genital organs',
  'R863 Abnormal level of substances chiefly nonmedicinal as to source in specimens from male genital',
  'organs',
  'R864 Abnormal immunological findings in specimens from male genital organs',
  'R865 Abnormal microbiological findings in specimens from male genital organs',
  'R866 Abnormal cytological findings in specimens from male genital organs',
  'R867 Abnormal histological findings in specimens from male genital organs',
  'R868 Other abnormal findings in specimens from male genital organs',
  'R869 Unspecified abnormal finding in specimens from male genital organs',
  'R870 Abnormal level of enzymes in specimens from female genital organs',
  'R871 Abnormal level of hormones in specimens from female genital organs',
  'R872 Abnormal level of other drugs, medicaments and biological substances in specimens from female',
  'genital organs',
  'R873 Abnormal level of substances chiefly nonmedicinal as to source in specimens from female genital',
  'organs',
  'R874 Abnormal immunological findings in specimens from female genital organs',
  'R875 Abnormal microbiological findings in specimens from female genital organs',
  'R87610 Atypical squamous cells of undetermined significance on cytologic smear of cervix (ASC-US)',
  'R87611 Atypical squamous cells cannot exclude high grade squamous intraepithelial lesion on cytologic',
  'smear of cervix (ASC-H)',
  'R87612 Low grade squamous intraepithelial lesion on cytologic smear of cervix (LGSIL)',
  'R87613 High grade squamous intraepithelial lesion on cytologic smear of cervix (HGSIL)',
  'R87614 Cytologic evidence of malignancy on smear of cervix',
  'R87615 Unsatisfactory cytologic smear of cervix',
  'R87616 Satisfactory cervical smear but lacking transformation zone',
  'R87618 Other abnormal cytological findings on specimens from cervix uteri',
  'R87619 Unspecified abnormal cytological findings in specimens from cervix uteri',
  'R87620 Atypical squamous cells of undetermined significance on cytologic smear of vagina (ASC-US)',
  'R87621 Atypical squamous cells cannot exclude high grade squamous intraepithelial lesion on cytologic',
  'smear of vagina (ASC-H)',
  'R87622 Low grade squamous intraepithelial lesion on cytologic smear of vagina (LGSIL)',
  'R87623 High grade squamous intraepithelial lesion on cytologic smear of vagina (HGSIL)',
  'R87624 Cytologic evidence of malignancy on smear of vagina',
  'R87625 Unsatisfactory cytologic smear of vagina',
  'R87628 Other abnormal cytological findings on specimens from vagina',
  'R87629 Unspecified abnormal cytological findings in specimens from vagina',
  'R8769 Abnormal cytological findings in specimens from other female genital organs',
  'R877 Abnormal histological findings in specimens from female genital organs',
  'R87810 Cervical high risk human papillomavirus (HPV) DNA test positive',
  'R87811 Vaginal high risk human papillomavirus (HPV) DNA test positive',
  'R87820 Cervical low risk human papillomavirus (HPV) DNA test positive',
  'R87821 Vaginal low risk human papillomavirus (HPV) DNA test positive',
  'R8789 Other abnormal findings in specimens from female genital organs',
  'R879 Unspecified abnormal finding in specimens from female genital organs',
  'R880 Cloudy (hemodialysis) (peritoneal) dialysis effluent',
  'R888 Abnormal findings in other body fluids and substances',
  'R890 Abnormal level of enzymes in specimens from other organs, systems and tissues',
  'R891 Abnormal level of hormones in specimens from other organs, systems and tissues',
  'R892 Abnormal level of other drugs, medicaments and biological substances in specimens from other',
  'organs, systems and tissues',
  'R893 Abnormal level of substances chiefly nonmedicinal as to source in specimens from other organs,',
  'systems and tissues',
  'R894 Abnormal immunological findings in specimens from other organs, systems and tissues',
  'R895 Abnormal microbiological findings in specimens from other organs, systems and tissues',
  'R896 Abnormal cytological findings in specimens from other organs, systems and tissues',
  'R897 Abnormal histological findings in specimens from other organs, systems and tissues',
  'R898 Other abnormal findings in specimens from other organs, systems and tissues',
  'R899 Unspecified abnormal finding in specimens from other organs, systems and tissues',
  'R900 Intracranial space-occupying lesion found on diagnostic imaging of central nervous system',
  'R9081 Abnormal echoencephalogram',
  'R9082 White matter disease, unspecified',
  'R9089 Other abnormal findings on diagnostic imaging of central nervous system',
  'R911 Solitary pulmonary nodule',
  'R918 Other nonspecific abnormal finding of lung field',
  'R920 Mammographic microcalcification found on diagnostic imaging of breast',
  'R921 Mammographic calcification found on diagnostic imaging of breast',
  'R922 Inconclusive mammogram',
  'R928 Other abnormal and inconclusive findings on diagnostic imaging of breast',
  'R930 Abnormal findings on diagnostic imaging of skull and head, not elsewhere classified',
  'R931 Abnormal findings on diagnostic imaging of heart and coronary circulation',
  'R932 Abnormal findings on diagnostic imaging of liver and biliary tract',
  'R933 Abnormal findings on diagnostic imaging of other parts of digestive tract',
  'R9341 Abnormal radiologic findings on diagnostic imaging of renal pelvis, ureter, or bladder',
  'R93421 Abnormal radiologic findings on diagnostic imaging of right kidney',
  'R93422 Abnormal radiologic findings on diagnostic imaging of left kidney',
  'R93429 Abnormal radiologic findings on diagnostic imaging of unspecified kidney',
  'R9349 Abnormal radiologic findings on diagnostic imaging of other urinary organs',
  'R935 Abnormal findings on diagnostic imaging of other abdominal regions, including retroperitoneum',
  'R936 Abnormal findings on diagnostic imaging of limbs',
  'R937 Abnormal findings on diagnostic imaging of other parts of musculoskeletal system',
  'R938 Abnormal findings on diagnostic imaging of other specified body structures',
  'R939 Diagnostic imaging inconclusive due to excess body fat of patient',
  'R9401 Abnormal electroencephalogram [EEG]',
  'R9402 Abnormal brain scan',
  'R9409 Abnormal results of other function studies of central nervous system',
  'R94110 Abnormal electro-oculogram [EOG]',
  'R94111 Abnormal electroretinogram [ERG]',
  'R94112 Abnormal visually evoked potential [VEP]',
  'R94113 Abnormal oculomotor study',
  'R94118 Abnormal results of other function studies of eye',
  'R94120 Abnormal auditory function study',
  'R94121 Abnormal vestibular function study',
  'R94128 Abnormal results of other function studies of ear and other special senses',
  'R94130 Abnormal response to nerve stimulation, unspecified',
  'R94131 Abnormal electromyogram [EMG]',
  'R94138 Abnormal results of other function studies of peripheral nervous system',
  'R942 Abnormal results of pulmonary function studies',
  'R9430 Abnormal result of cardiovascular function study, unspecified',
  'R9431 Abnormal electrocardiogram [ECG] [EKG]',
  'R9439 Abnormal result of other cardiovascular function study',
  'R944 Abnormal results of kidney function studies',
  'R945 Abnormal results of liver function studies',
  'R946 Abnormal results of thyroid function studies',
  'R947 Abnormal results of other endocrine function studies',
  'R948 Abnormal results of function studies of other organs and systems',
  'R970 Elevated carcinoembryonic antigen [CEA]',
  'R971 Elevated cancer antigen 125 [CA 125]',
  'R9720 Elevated prostate specific antigen [PSA]',
  'R9721 Rising PSA following treatment for malignant neoplasm of prostate',
  'R978 Other abnormal tumor markers',
  'R99 Ill-defined and unknown cause of mortality',
  'S0000XA Unspecified superficial injury of scalp, initial encounter',
  'S0000XD Unspecified superficial injury of scalp, subsequent encounter',
  'S0000XS Unspecified superficial injury of scalp, sequela',
  'S0001XA Abrasion of scalp, initial encounter',
  'S0001XD Abrasion of scalp, subsequent encounter',
  'S0001XS Abrasion of scalp, sequela',
  'S0002XA Blister (nonthermal) of scalp, initial encounter',
  'S0002XD Blister (nonthermal) of scalp, subsequent encounter',
  'S0002XS Blister (nonthermal) of scalp, sequela',
  'S0003XA Contusion of scalp, initial encounter',
  'S0003XD Contusion of scalp, subsequent encounter',
  'S0003XS Contusion of scalp, sequela',
  'S0004XA External constriction of part of scalp, initial encounter',
  'S0004XD External constriction of part of scalp, subsequent encounter',
  'S0004XS External constriction of part of scalp, sequela',
  'S0005XA Superficial foreign body of scalp, initial encounter',
  'S0005XD Superficial foreign body of scalp, subsequent encounter',
  'S0005XS Superficial foreign body of scalp, sequela',
  'S0006XA Insect bite (nonvenomous) of scalp, initial encounter',
  'S0006XD Insect bite (nonvenomous) of scalp, subsequent encounter',
  'S0006XS Insect bite (nonvenomous) of scalp, sequela',
  'S0007XA Other superficial bite of scalp, initial encounter',
  'S0007XD Other superficial bite of scalp, subsequent encounter',
  'S0007XS Other superficial bite of scalp, sequela',
  'S0010XA Contusion of unspecified eyelid and periocular area, initial encounter',
  'S0010XD Contusion of unspecified eyelid and periocular area, subsequent encounter',
  'S0010XS Contusion of unspecified eyelid and periocular area, sequela',
  'S0011XA Contusion of right eyelid and periocular area, initial encounter',
  'S0011XD Contusion of right eyelid and periocular area, subsequent encounter',
  'S0011XS Contusion of right eyelid and periocular area, sequela',
  'S0012XA Contusion of left eyelid and periocular area, initial encounter',
  'S0012XD Contusion of left eyelid and periocular area, subsequent encounter',
  'S0012XS Contusion of left eyelid and periocular area, sequela',
  'S00201A Unspecified superficial injury of right eyelid and periocular area, initial encounter',
  'S00201D Unspecified superficial injury of right eyelid and periocular area, subsequent encounter',
  'S00201S Unspecified superficial injury of right eyelid and periocular area, sequela',
  'S00202A Unspecified superficial injury of left eyelid and periocular area, initial encounter',
  'S00202D Unspecified superficial injury of left eyelid and periocular area, subsequent encounter',
  'S00202S Unspecified superficial injury of left eyelid and periocular area, sequela',
  'S00209A Unspecified superficial injury of unspecified eyelid and periocular area, initial encounter',
  'S00209D Unspecified superficial injury of unspecified eyelid and periocular area, subsequent encounter',
  'S00209S Unspecified superficial injury of unspecified eyelid and periocular area, sequela',
  'S00211A Abrasion of right eyelid and periocular area, initial encounter',
  'S00211D Abrasion of right eyelid and periocular area, subsequent encounter',
  'S00211S Abrasion of right eyelid and periocular area, sequela',
  'S00212A Abrasion of left eyelid and periocular area, initial encounter',
  'S00212D Abrasion of left eyelid and periocular area, subsequent encounter',
  'S00212S Abrasion of left eyelid and periocular area, sequela',
  'S00219A Abrasion of unspecified eyelid and periocular area, initial encounter',
  'S00219D Abrasion of unspecified eyelid and periocular area, subsequent encounter',
  'S00219S Abrasion of unspecified eyelid and periocular area, sequela',
  'S00221A Blister (nonthermal) of right eyelid and periocular area, initial encounter',
  'S00221D Blister (nonthermal) of right eyelid and periocular area, subsequent encounter',
  'S00221S Blister (nonthermal) of right eyelid and periocular area, sequela',
  'S00222A Blister (nonthermal) of left eyelid and periocular area, initial encounter',
  'S00222D Blister (nonthermal) of left eyelid and periocular area, subsequent encounter',
  'S00222S Blister (nonthermal) of left eyelid and periocular area, sequela',
  'S00229A Blister (nonthermal) of unspecified eyelid and periocular area, initial encounter',
  'S00229D Blister (nonthermal) of unspecified eyelid and periocular area, subsequent encounter',
  'S00229S Blister (nonthermal) of unspecified eyelid and periocular area, sequela',
  'S00241A External constriction of right eyelid and periocular area, initial encounter',
  'S00241D External constriction of right eyelid and periocular area, subsequent encounter',
  'S00241S External constriction of right eyelid and periocular area, sequela',
  'S00242A External constriction of left eyelid and periocular area, initial encounter',
  'S00242D External constriction of left eyelid and periocular area, subsequent encounter',
  'S00242S External constriction of left eyelid and periocular area, sequela',
  'S00249A External constriction of unspecified eyelid and periocular area, initial encounter',
  'S00249D External constriction of unspecified eyelid and periocular area, subsequent encounter',
  'S00249S External constriction of unspecified eyelid and periocular area, sequela',
  'S00251A Superficial foreign body of right eyelid and periocular area, initial encounter',
  'S00251D Superficial foreign body of right eyelid and periocular area, subsequent encounter',
  'S00251S Superficial foreign body of right eyelid and periocular area, sequela',
  'S00252A Superficial foreign body of left eyelid and periocular area, initial encounter',
  'S00252D Superficial foreign body of left eyelid and periocular area, subsequent encounter',
  'S00252S Superficial foreign body of left eyelid and periocular area, sequela',
  'S00259A Superficial foreign body of unspecified eyelid and periocular area, initial encounter',
  'S00259D Superficial foreign body of unspecified eyelid and periocular area, subsequent encounter',
  'S00259S Superficial foreign body of unspecified eyelid and periocular area, sequela',
  'S00261A Insect bite (nonvenomous) of right eyelid and periocular area, initial encounter',
  'S00261D Insect bite (nonvenomous) of right eyelid and periocular area, subsequent encounter',
  'S00261S Insect bite (nonvenomous) of right eyelid and periocular area, sequela',
  'S00262A Insect bite (nonvenomous) of left eyelid and periocular area, initial encounter',
  'S00262D Insect bite (nonvenomous) of left eyelid and periocular area, subsequent encounter',
  'S00262S Insect bite (nonvenomous) of left eyelid and periocular area, sequela',
  'S00269A Insect bite (nonvenomous) of unspecified eyelid and periocular area, initial encounter',
  'S00269D Insect bite (nonvenomous) of unspecified eyelid and periocular area, subsequent encounter',
  'S00269S Insect bite (nonvenomous) of unspecified eyelid and periocular area, sequela',
  'S00271A Other superficial bite of right eyelid and periocular area, initial encounter',
  'S00271D Other superficial bite of right eyelid and periocular area, subsequent encounter',
  'S00271S Other superficial bite of right eyelid and periocular area, sequela',
  'S00272A Other superficial bite of left eyelid and periocular area, initial encounter',
  'S00272D Other superficial bite of left eyelid and periocular area, subsequent encounter',
  'S00272S Other superficial bite of left eyelid and periocular area, sequela',
  'S00279A Other superficial bite of unspecified eyelid and periocular area, initial encounter',
  'S00279D Other superficial bite of unspecified eyelid and periocular area, subsequent encounter',
  'S00279S Other superficial bite of unspecified eyelid and periocular area, sequela',
  'S0030XA Unspecified superficial injury of nose, initial encounter',
  'S0030XD Unspecified superficial injury of nose, subsequent encounter',
  'S0030XS Unspecified superficial injury of nose, sequela',
  'S0031XA Abrasion of nose, initial encounter',
  'S0031XD Abrasion of nose, subsequent encounter',
  'S0031XS Abrasion of nose, sequela',
  'S0032XA Blister (nonthermal) of nose, initial encounter',
  'S0032XD Blister (nonthermal) of nose, subsequent encounter',
  'S0032XS Blister (nonthermal) of nose, sequela',
  'S0033XA Contusion of nose, initial encounter',
  'S0033XD Contusion of nose, subsequent encounter',
  'S0033XS Contusion of nose, sequela',
  'S0034XA External constriction of nose, initial encounter',
  'S0034XD External constriction of nose, subsequent encounter',
  'S0034XS External constriction of nose, sequela',
  'S0035XA Superficial foreign body of nose, initial encounter',
  'S0035XD Superficial foreign body of nose, subsequent encounter',
  'S0035XS Superficial foreign body of nose, sequela',
  'S0036XA Insect bite (nonvenomous) of nose, initial encounter',
  'S0036XD Insect bite (nonvenomous) of nose, subsequent encounter',
  'S0036XS Insect bite (nonvenomous) of nose, sequela',
  'S0037XA Other superficial bite of nose, initial encounter',
  'S0037XD Other superficial bite of nose, subsequent encounter',
  'S0037XS Other superficial bite of nose, sequela',
  'S00401A Unspecified superficial injury of right ear, initial encounter',
  'S00401D Unspecified superficial injury of right ear, subsequent encounter',
  'S00401S Unspecified superficial injury of right ear, sequela',
  'S00402A Unspecified superficial injury of left ear, initial encounter',
  'S00402D Unspecified superficial injury of left ear, subsequent encounter',
  'S00402S Unspecified superficial injury of left ear, sequela',
  'S00409A Unspecified superficial injury of unspecified ear, initial encounter',
  'S00409D Unspecified superficial injury of unspecified ear, subsequent encounter',
  'S00409S Unspecified superficial injury of unspecified ear, sequela',
  'S00411A Abrasion of right ear, initial encounter',
  'S00411D Abrasion of right ear, subsequent encounter',
  'S00411S Abrasion of right ear, sequela',
  'S00412A Abrasion of left ear, initial encounter',
  'S00412D Abrasion of left ear, subsequent encounter',
  'S00412S Abrasion of left ear, sequela',
  'S00419A Abrasion of unspecified ear, initial encounter',
  'S00419D Abrasion of unspecified ear, subsequent encounter',
  'S00419S Abrasion of unspecified ear, sequela',
  'S00421A Blister (nonthermal) of right ear, initial encounter',
  'S00421D Blister (nonthermal) of right ear, subsequent encounter',
  'S00421S Blister (nonthermal) of right ear, sequela',
  'S00422A Blister (nonthermal) of left ear, initial encounter',
  'S00422D Blister (nonthermal) of left ear, subsequent encounter',
  'S00422S Blister (nonthermal) of left ear, sequela',
  'S00429A Blister (nonthermal) of unspecified ear, initial encounter',
  'S00429D Blister (nonthermal) of unspecified ear, subsequent encounter',
  'S00429S Blister (nonthermal) of unspecified ear, sequela',
  'S00431A Contusion of right ear, initial encounter',
  'S00431D Contusion of right ear, subsequent encounter',
  'S00431S Contusion of right ear, sequela',
  'S00432A Contusion of left ear, initial encounter',
  'S00432D Contusion of left ear, subsequent encounter',
  'S00432S Contusion of left ear, sequela',
  'S00439A Contusion of unspecified ear, initial encounter',
  'S00439D Contusion of unspecified ear, subsequent encounter',
  'S00439S Contusion of unspecified ear, sequela',
  'S00441A External constriction of right ear, initial encounter',
  'S00441D External constriction of right ear, subsequent encounter',
  'S00441S External constriction of right ear, sequela',
  'S00442A External constriction of left ear, initial encounter',
  'S00442D External constriction of left ear, subsequent encounter',
  'S00442S External constriction of left ear, sequela',
  'S00449A External constriction of unspecified ear, initial encounter',
  'S00449D External constriction of unspecified ear, subsequent encounter',
  'S00449S External constriction of unspecified ear, sequela',
  'S00451A Superficial foreign body of right ear, initial encounter',
  'S00451D Superficial foreign body of right ear, subsequent encounter',
  'S00451S Superficial foreign body of right ear, sequela',
  'S00452A Superficial foreign body of left ear, initial encounter',
  'S00452D Superficial foreign body of left ear, subsequent encounter',
  'S00452S Superficial foreign body of left ear, sequela',
  'S00459A Superficial foreign body of unspecified ear, initial encounter',
  'S00459D Superficial foreign body of unspecified ear, subsequent encounter',
  'S00459S Superficial foreign body of unspecified ear, sequela',
  'S00461A Insect bite (nonvenomous) of right ear, initial encounter',
  'S00461D Insect bite (nonvenomous) of right ear, subsequent encounter',
  'S00461S Insect bite (nonvenomous) of right ear, sequela',
  'S00462A Insect bite (nonvenomous) of left ear, initial encounter',
  'S00462D Insect bite (nonvenomous) of left ear, subsequent encounter',
  'S00462S Insect bite (nonvenomous) of left ear, sequela',
  'S00469A Insect bite (nonvenomous) of unspecified ear, initial encounter',
  'S00469D Insect bite (nonvenomous) of unspecified ear, subsequent encounter',
  'S00469S Insect bite (nonvenomous) of unspecified ear, sequela',
  'S00471A Other superficial bite of right ear, initial encounter',
  'S00471D Other superficial bite of right ear, subsequent encounter',
  'S00471S Other superficial bite of right ear, sequela',
  'S00472A Other superficial bite of left ear, initial encounter',
  'S00472D Other superficial bite of left ear, subsequent encounter',
  'S00472S Other superficial bite of left ear, sequela',
  'S00479A Other superficial bite of unspecified ear, initial encounter',
  'S00479D Other superficial bite of unspecified ear, subsequent encounter',
  'S00479S Other superficial bite of unspecified ear, sequela',
  'S00501A Unspecified superficial injury of lip, initial encounter',
  'S00501D Unspecified superficial injury of lip, subsequent encounter',
  'S00501S Unspecified superficial injury of lip, sequela',
  'S00502A Unspecified superficial injury of oral cavity, initial encounter',
  'S00502D Unspecified superficial injury of oral cavity, subsequent encounter',
  'S00502S Unspecified superficial injury of oral cavity, sequela',
  'S00511A Abrasion of lip, initial encounter',
  'S00511D Abrasion of lip, subsequent encounter',
  'S00511S Abrasion of lip, sequela',
  'S00512A Abrasion of oral cavity, initial encounter',
  'S00512D Abrasion of oral cavity, subsequent encounter',
  'S00512S Abrasion of oral cavity, sequela',
  'S00521A Blister (nonthermal) of lip, initial encounter',
  'S00521D Blister (nonthermal) of lip, subsequent encounter',
  'S00521S Blister (nonthermal) of lip, sequela',
  'S00522A Blister (nonthermal) of oral cavity, initial encounter',
  'S00522D Blister (nonthermal) of oral cavity, subsequent encounter',
  'S00522S Blister (nonthermal) of oral cavity, sequela',
  'S00531A Contusion of lip, initial encounter',
  'S00531D Contusion of lip, subsequent encounter',
  'S00531S Contusion of lip, sequela',
  'S00532A Contusion of oral cavity, initial encounter',
  'S00532D Contusion of oral cavity, subsequent encounter',
  'S00532S Contusion of oral cavity, sequela',
  'S00541A External constriction of lip, initial encounter',
  'S00541D External constriction of lip, subsequent encounter',
  'S00541S External constriction of lip, sequela',
  'S00542A External constriction of oral cavity, initial encounter',
  'S00542D External constriction of oral cavity, subsequent encounter',
  'S00542S External constriction of oral cavity, sequela',
  'S00551A Superficial foreign body of lip, initial encounter',
  'S00551D Superficial foreign body of lip, subsequent encounter',
  'S00551S Superficial foreign body of lip, sequela',
  'S00552A Superficial foreign body of oral cavity, initial encounter',
  'S00552D Superficial foreign body of oral cavity, subsequent encounter',
  'S00552S Superficial foreign body of oral cavity, sequela',
  'S00561A Insect bite (nonvenomous) of lip, initial encounter',
  'S00561D Insect bite (nonvenomous) of lip, subsequent encounter',
  'S00561S Insect bite (nonvenomous) of lip, sequela',
  'S00562A Insect bite (nonvenomous) of oral cavity, initial encounter',
  'S00562D Insect bite (nonvenomous) of oral cavity, subsequent encounter',
  'S00562S Insect bite (nonvenomous) of oral cavity, sequela',
  'S00571A Other superficial bite of lip, initial encounter',
  'S00571D Other superficial bite of lip, subsequent encounter',
  'S00571S Other superficial bite of lip, sequela',
  'S00572A Other superficial bite of oral cavity, initial encounter',
  'S00572D Other superficial bite of oral cavity, subsequent encounter',
  'S00572S Other superficial bite of oral cavity, sequela',
  'S0080XA Unspecified superficial injury of other part of head, initial encounter',
  'S0080XD Unspecified superficial injury of other part of head, subsequent encounter',
  'S0080XS Unspecified superficial injury of other part of head, sequela',
  'S0081XA Abrasion of other part of head, initial encounter',
  'S0081XD Abrasion of other part of head, subsequent encounter',
  'S0081XS Abrasion of other part of head, sequela',
  'S0082XA Blister (nonthermal) of other part of head, initial encounter',
  'S0082XD Blister (nonthermal) of other part of head, subsequent encounter',
  'S0082XS Blister (nonthermal) of other part of head, sequela',
  'S0083XA Contusion of other part of head, initial encounter',
  'S0083XD Contusion of other part of head, subsequent encounter',
  'S0083XS Contusion of other part of head, sequela',
  'S0084XA External constriction of other part of head, initial encounter',
  'S0084XD External constriction of other part of head, subsequent encounter',
  'S0084XS External constriction of other part of head, sequela',
  'S0085XA Superficial foreign body of other part of head, initial encounter',
  'S0085XD Superficial foreign body of other part of head, subsequent encounter',
  'S0085XS Superficial foreign body of other part of head, sequela',
  'S0086XA Insect bite (nonvenomous) of other part of head, initial encounter',
  'S0086XD Insect bite (nonvenomous) of other part of head, subsequent encounter',
  'S0086XS Insect bite (nonvenomous) of other part of head, sequela',
  'S0087XA Other superficial bite of other part of head, initial encounter',
  'S0087XD Other superficial bite of other part of head, subsequent encounter',
  'S0087XS Other superficial bite of other part of head, sequela',
  'S0090XA Unspecified superficial injury of unspecified part of head, initial encounter',
  'S0090XD Unspecified superficial injury of unspecified part of head, subsequent encounter',
  'S0090XS Unspecified superficial injury of unspecified part of head, sequela',
  'S0091XA Abrasion of unspecified part of head, initial encounter',
  'S0091XD Abrasion of unspecified part of head, subsequent encounter',
  'S0091XS Abrasion of unspecified part of head, sequela',
  'S0092XA Blister (nonthermal) of unspecified part of head, initial encounter',
  'S0092XD Blister (nonthermal) of unspecified part of head, subsequent encounter',
  'S0092XS Blister (nonthermal) of unspecified part of head, sequela',
  'S0093XA Contusion of unspecified part of head, initial encounter',
  'S0093XD Contusion of unspecified part of head, subsequent encounter',
  'S0093XS Contusion of unspecified part of head, sequela',
  'S0094XA External constriction of unspecified part of head, initial encounter',
  'S0094XD External constriction of unspecified part of head, subsequent encounter',
  'S0094XS External constriction of unspecified part of head, sequela',
  'S0095XA Superficial foreign body of unspecified part of head, initial encounter',
  'S0095XD Superficial foreign body of unspecified part of head, subsequent encounter',
  'S0095XS Superficial foreign body of unspecified part of head, sequela',
  'S0096XA Insect bite (nonvenomous) of unspecified part of head, initial encounter',
  'S0096XD Insect bite (nonvenomous) of unspecified part of head, subsequent encounter',
  'S0096XS Insect bite (nonvenomous) of unspecified part of head, sequela',
  'S0097XA Other superficial bite of unspecified part of head, initial encounter',
  'S0097XD Other superficial bite of unspecified part of head, subsequent encounter',
  'S0097XS Other superficial bite of unspecified part of head, sequela',
  'S0100XA Unspecified open wound of scalp, initial encounter',
  'S0100XD Unspecified open wound of scalp, subsequent encounter',
  'S0100XS Unspecified open wound of scalp, sequela',
  'S0101XA Laceration without foreign body of scalp, initial encounter',
  'S0101XD Laceration without foreign body of scalp, subsequent encounter',
  'S0101XS Laceration without foreign body of scalp, sequela',
  'S0102XA Laceration with foreign body of scalp, initial encounter',
  'S0102XD Laceration with foreign body of scalp, subsequent encounter',
  'S0102XS Laceration with foreign body of scalp, sequela',
  'S0103XA Puncture wound without foreign body of scalp, initial encounter',
  'S0103XD Puncture wound without foreign body of scalp, subsequent encounter',
  'S0103XS Puncture wound without foreign body of scalp, sequela',
  'S0104XA Puncture wound with foreign body of scalp, initial encounter',
  'S0104XD Puncture wound with foreign body of scalp, subsequent encounter',
  'S0104XS Puncture wound with foreign body of scalp, sequela',
  'S0105XA Open bite of scalp, initial encounter',
  'S0105XD Open bite of scalp, subsequent encounter',
  'S0105XS Open bite of scalp, sequela',
  'S01101A Unspecified open wound of right eyelid and periocular area, initial encounter',
  'S01101D Unspecified open wound of right eyelid and periocular area, subsequent encounter',
  'S01101S Unspecified open wound of right eyelid and periocular area, sequela',
  'S01102A Unspecified open wound of left eyelid and periocular area, initial encounter',
  'S01102D Unspecified open wound of left eyelid and periocular area, subsequent encounter',
  'S01102S Unspecified open wound of left eyelid and periocular area, sequela',
  'S01109A Unspecified open wound of unspecified eyelid and periocular area, initial encounter',
  'S01109D Unspecified open wound of unspecified eyelid and periocular area, subsequent encounter',
  'S01109S Unspecified open wound of unspecified eyelid and periocular area, sequela',
  'S01111A Laceration without foreign body of right eyelid and periocular area, initial encounter',
  'S01111D Laceration without foreign body of right eyelid and periocular area, subsequent encounter',
  'S01111S Laceration without foreign body of right eyelid and periocular area, sequela',
  'S01112A Laceration without foreign body of left eyelid and periocular area, initial encounter',
  'S01112D Laceration without foreign body of left eyelid and periocular area, subsequent encounter',
  'S01112S Laceration without foreign body of left eyelid and periocular area, sequela',
  'S01119A Laceration without foreign body of unspecified eyelid and periocular area, initial encounter',
  'S01119D Laceration without foreign body of unspecified eyelid and periocular area, subsequent',
  'encounter',
  'S01119S Laceration without foreign body of unspecified eyelid and periocular area, sequela',
  'S01121A Laceration with foreign body of right eyelid and periocular area, initial encounter',
  'S01121D Laceration with foreign body of right eyelid and periocular area, subsequent encounter',
  'S01121S Laceration with foreign body of right eyelid and periocular area, sequela',
  'S01122A Laceration with foreign body of left eyelid and periocular area, initial encounter',
  'S01122D Laceration with foreign body of left eyelid and periocular area, subsequent encounter',
  'S01122S Laceration with foreign body of left eyelid and periocular area, sequela',
  'S01129A Laceration with foreign body of unspecified eyelid and periocular area, initial encounter',
  'S01129D Laceration with foreign body of unspecified eyelid and periocular area, subsequent encounter',
  'S01129S Laceration with foreign body of unspecified eyelid and periocular area, sequela',
  'S01131A Puncture wound without foreign body of right eyelid and periocular area, initial encounter',
  'S01131D Puncture wound without foreign body of right eyelid and periocular area, subsequent',
  'encounter',
  'S01131S Puncture wound without foreign body of right eyelid and periocular area, sequela',
  'S01132A Puncture wound without foreign body of left eyelid and periocular area, initial encounter',
  'S01132D Puncture wound without foreign body of left eyelid and periocular area, subsequent encounter',
  'S01132S Puncture wound without foreign body of left eyelid and periocular area, sequela',
  'S01139A Puncture wound without foreign body of unspecified eyelid and periocular area, initial',
  'encounter',
  'S01139D Puncture wound without foreign body of unspecified eyelid and periocular area, subsequent',
  'encounter',
  'S01139S Puncture wound without foreign body of unspecified eyelid and periocular area, sequela',
  'S01141A Puncture wound with foreign body of right eyelid and periocular area, initial encounter',
  'S01141D Puncture wound with foreign body of right eyelid and periocular area, subsequent encounter',
  'S01141S Puncture wound with foreign body of right eyelid and periocular area, sequela',
  'S01142A Puncture wound with foreign body of left eyelid and periocular area, initial encounter',
  'S01142D Puncture wound with foreign body of left eyelid and periocular area, subsequent encounter',
  'S01142S Puncture wound with foreign body of left eyelid and periocular area, sequela',
  'S01149A Puncture wound with foreign body of unspecified eyelid and periocular area, initial encounter',
  'S01149D Puncture wound with foreign body of unspecified eyelid and periocular area, subsequent',
  'encounter',
  'S01149S Puncture wound with foreign body of unspecified eyelid and periocular area, sequela',
  'S01151A Open bite of right eyelid and periocular area, initial encounter',
  'S01151D Open bite of right eyelid and periocular area, subsequent encounter',
  'S01151S Open bite of right eyelid and periocular area, sequela',
  'S01152A Open bite of left eyelid and periocular area, initial encounter',
  'S01152D Open bite of left eyelid and periocular area, subsequent encounter',
  'S01152S Open bite of left eyelid and periocular area, sequela',
  'S01159A Open bite of unspecified eyelid and periocular area, initial encounter',
  'S01159D Open bite of unspecified eyelid and periocular area, subsequent encounter',
  'S01159S Open bite of unspecified eyelid and periocular area, sequela',
  'S0120XA Unspecified open wound of nose, initial encounter',
  'S0120XD Unspecified open wound of nose, subsequent encounter',
  'S0120XS Unspecified open wound of nose, sequela',
  'S0121XA Laceration without foreign body of nose, initial encounter',
  'S0121XD Laceration without foreign body of nose, subsequent encounter',
  'S0121XS Laceration without foreign body of nose, sequela',
  'S0122XA Laceration with foreign body of nose, initial encounter',
  'S0122XD Laceration with foreign body of nose, subsequent encounter',
  'S0122XS Laceration with foreign body of nose, sequela',
  'S0123XA Puncture wound without foreign body of nose, initial encounter',
  'S0123XD Puncture wound without foreign body of nose, subsequent encounter',
  'S0123XS Puncture wound without foreign body of nose, sequela',
  'S0124XA Puncture wound with foreign body of nose, initial encounter',
  'S0124XD Puncture wound with foreign body of nose, subsequent encounter',
  'S0124XS Puncture wound with foreign body of nose, sequela',
  'S0125XA Open bite of nose, initial encounter',
  'S0125XD Open bite of nose, subsequent encounter',
  'S0125XS Open bite of nose, sequela',
  'S01301A Unspecified open wound of right ear, initial encounter',
  'S01301D Unspecified open wound of right ear, subsequent encounter',
  'S01301S Unspecified open wound of right ear, sequela',
  'S01302A Unspecified open wound of left ear, initial encounter',
  'S01302D Unspecified open wound of left ear, subsequent encounter',
  'S01302S Unspecified open wound of left ear, sequela',
  'S01309A Unspecified open wound of unspecified ear, initial encounter',
  'S01309D Unspecified open wound of unspecified ear, subsequent encounter',
  'S01309S Unspecified open wound of unspecified ear, sequela',
  'S01311A Laceration without foreign body of right ear, initial encounter',
  'S01311D Laceration without foreign body of right ear, subsequent encounter',
  'S01311S Laceration without foreign body of right ear, sequela',
  'S01312A Laceration without foreign body of left ear, initial encounter',
  'S01312D Laceration without foreign body of left ear, subsequent encounter',
  'S01312S Laceration without foreign body of left ear, sequela',
  'S01319A Laceration without foreign body of unspecified ear, initial encounter',
  'S01319D Laceration without foreign body of unspecified ear, subsequent encounter',
  'S01319S Laceration without foreign body of unspecified ear, sequela',
  'S01321A Laceration with foreign body of right ear, initial encounter',
  'S01321D Laceration with foreign body of right ear, subsequent encounter',
  'S01321S Laceration with foreign body of right ear, sequela',
  'S01322A Laceration with foreign body of left ear, initial encounter',
  'S01322D Laceration with foreign body of left ear, subsequent encounter',
  'S01322S Laceration with foreign body of left ear, sequela',
  'S01329A Laceration with foreign body of unspecified ear, initial encounter',
  'S01329D Laceration with foreign body of unspecified ear, subsequent encounter',
  'S01329S Laceration with foreign body of unspecified ear, sequela',
  'S01331A Puncture wound without foreign body of right ear, initial encounter',
  'S01331D Puncture wound without foreign body of right ear, subsequent encounter',
  'S01331S Puncture wound without foreign body of right ear, sequela',
  'S01332A Puncture wound without foreign body of left ear, initial encounter',
  'S01332D Puncture wound without foreign body of left ear, subsequent encounter',
  'S01332S Puncture wound without foreign body of left ear, sequela',
  'S01339A Puncture wound without foreign body of unspecified ear, initial encounter',
  'S01339D Puncture wound without foreign body of unspecified ear, subsequent encounter',
  'S01339S Puncture wound without foreign body of unspecified ear, sequela',
  'S01341A Puncture wound with foreign body of right ear, initial encounter',
  'S01341D Puncture wound with foreign body of right ear, subsequent encounter',
  'S01341S Puncture wound with foreign body of right ear, sequela',
  'S01342A Puncture wound with foreign body of left ear, initial encounter',
  'S01342D Puncture wound with foreign body of left ear, subsequent encounter',
  'S01342S Puncture wound with foreign body of left ear, sequela',
  'S01349A Puncture wound with foreign body of unspecified ear, initial encounter',
  'S01349D Puncture wound with foreign body of unspecified ear, subsequent encounter',
  'S01349S Puncture wound with foreign body of unspecified ear, sequela',
  'S01351A Open bite of right ear, initial encounter',
  'S01351D Open bite of right ear, subsequent encounter',
  'S01351S Open bite of right ear, sequela',
  'S01352A Open bite of left ear, initial encounter',
  'S01352D Open bite of left ear, subsequent encounter',
  'S01352S Open bite of left ear, sequela',
  'S01359A Open bite of unspecified ear, initial encounter',
  'S01359D Open bite of unspecified ear, subsequent encounter',
  'S01359S Open bite of unspecified ear, sequela',
  'S01401A Unspecified open wound of right cheek and temporomandibular area, initial encounter',
  'S01401D Unspecified open wound of right cheek and temporomandibular area, subsequent encounter',
  'S01401S Unspecified open wound of right cheek and temporomandibular area, sequela',
  'S01402A Unspecified open wound of left cheek and temporomandibular area, initial encounter',
  'S01402D Unspecified open wound of left cheek and temporomandibular area, subsequent encounter',
  'S01402S Unspecified open wound of left cheek and temporomandibular area, sequela',
  'S01409A Unspecified open wound of unspecified cheek and temporomandibular area, initial encounter',
  'S01409D Unspecified open wound of unspecified cheek and temporomandibular area, subsequent',
  'encounter',
  'S01409S Unspecified open wound of unspecified cheek and temporomandibular area, sequela',
  'S01411A Laceration without foreign body of right cheek and temporomandibular area, initial encounter',
  'S01411D Laceration without foreign body of right cheek and temporomandibular area, subsequent',
  'encounter',
  'S01411S Laceration without foreign body of right cheek and temporomandibular area, sequela',
  'S01412A Laceration without foreign body of left cheek and temporomandibular area, initial encounter',
  'S01412D Laceration without foreign body of left cheek and temporomandibular area, subsequent',
  'encounter',
  'S01412S Laceration without foreign body of left cheek and temporomandibular area, sequela',
  'S01419A Laceration without foreign body of unspecified cheek and temporomandibular area, initial',
  'encounter',
  'S01419D Laceration without foreign body of unspecified cheek and temporomandibular area,',
  'subsequent encounter',
  'S01419S Laceration without foreign body of unspecified cheek and temporomandibular area, sequela',
  'S01421A Laceration with foreign body of right cheek and temporomandibular area, initial encounter',
  'S01421D Laceration with foreign body of right cheek and temporomandibular area, subsequent',
  'encounter',
  'S01421S Laceration with foreign body of right cheek and temporomandibular area, sequela',
  'S01422A Laceration with foreign body of left cheek and temporomandibular area, initial encounter',
  'S01422D Laceration with foreign body of left cheek and temporomandibular area, subsequent',
  'encounter',
  'S01422S Laceration with foreign body of left cheek and temporomandibular area, sequela',
  'S01429A Laceration with foreign body of unspecified cheek and temporomandibular area, initial',
  'encounter',
  'S01429D Laceration with foreign body of unspecified cheek and temporomandibular area, subsequent',
  'encounter',
  'S01429S Laceration with foreign body of unspecified cheek and temporomandibular area, sequela',
  'S01431A Puncture wound without foreign body of right cheek and temporomandibular area, initial',
  'encounter',
  'S01431D Puncture wound without foreign body of right cheek and temporomandibular area,',
  'subsequent encounter',
  'S01431S Puncture wound without foreign body of right cheek and temporomandibular area, sequela',
  'S01432A Puncture wound without foreign body of left cheek and temporomandibular area, initial',
  'encounter',
  'S01432D Puncture wound without foreign body of left cheek and temporomandibular area, subsequent',
  'encounter',
  'S01432S Puncture wound without foreign body of left cheek and temporomandibular area, sequela',
  'S01439A Puncture wound without foreign body of unspecified cheek and temporomandibular area,',
  'initial encounter',
  'S01439D Puncture wound without foreign body of unspecified cheek and temporomandibular area,',
  'subsequent encounter',
  'S01439S Puncture wound without foreign body of unspecified cheek and temporomandibular area,',
  'sequela',
  'S01441A Puncture wound with foreign body of right cheek and temporomandibular area, initial',
  'encounter',
  'S01441D Puncture wound with foreign body of right cheek and temporomandibular area, subsequent',
  'encounter',
  'S01441S Puncture wound with foreign body of right cheek and temporomandibular area, sequela',
  'S01442A Puncture wound with foreign body of left cheek and temporomandibular area, initial',
  'encounter',
  'S01442D Puncture wound with foreign body of left cheek and temporomandibular area, subsequent',
  'encounter',
  'S01442S Puncture wound with foreign body of left cheek and temporomandibular area, sequela',
  'S01449A Puncture wound with foreign body of unspecified cheek and temporomandibular area, initial',
  'encounter',
  'S01449D Puncture wound with foreign body of unspecified cheek and temporomandibular area,',
  'subsequent encounter',
  'S01449S Puncture wound with foreign body of unspecified cheek and temporomandibular area, sequela',
  'S01451A Open bite of right cheek and temporomandibular area, initial encounter',
  'S01451D Open bite of right cheek and temporomandibular area, subsequent encounter',
  'S01451S Open bite of right cheek and temporomandibular area, sequela',
  'S01452A Open bite of left cheek and temporomandibular area, initial encounter',
  'S01452D Open bite of left cheek and temporomandibular area, subsequent encounter',
  'S01452S Open bite of left cheek and temporomandibular area, sequela',
  'S01459A Open bite of unspecified cheek and temporomandibular area, initial encounter',
  'S01459D Open bite of unspecified cheek and temporomandibular area, subsequent encounter',
  'S01459S Open bite of unspecified cheek and temporomandibular area, sequela',
  'S01501A Unspecified open wound of lip, initial encounter',
  'S01501D Unspecified open wound of lip, subsequent encounter',
  'S01501S Unspecified open wound of lip, sequela',
  'S01502A Unspecified open wound of oral cavity, initial encounter',
  'S01502D Unspecified open wound of oral cavity, subsequent encounter',
  'S01502S Unspecified open wound of oral cavity, sequela',
  'S01511A Laceration without foreign body of lip, initial encounter',
  'S01511D Laceration without foreign body of lip, subsequent encounter',
  'S01511S Laceration without foreign body of lip, sequela',
  'S01512A Laceration without foreign body of oral cavity, initial encounter',
  'S01512D Laceration without foreign body of oral cavity, subsequent encounter',
  'S01512S Laceration without foreign body of oral cavity, sequela',
  'S01521A Laceration with foreign body of lip, initial encounter',
  'S01521D Laceration with foreign body of lip, subsequent encounter',
  'S01521S Laceration with foreign body of lip, sequela',
  'S01522A Laceration with foreign body of oral cavity, initial encounter',
  'S01522D Laceration with foreign body of oral cavity, subsequent encounter',
  'S01522S Laceration with foreign body of oral cavity, sequela',
  'S01531A Puncture wound without foreign body of lip, initial encounter',
  'S01531D Puncture wound without foreign body of lip, subsequent encounter',
  'S01531S Puncture wound without foreign body of lip, sequela',
  'S01532A Puncture wound without foreign body of oral cavity, initial encounter',
  'S01532D Puncture wound without foreign body of oral cavity, subsequent encounter',
  'S01532S Puncture wound without foreign body of oral cavity, sequela',
  'S01541A Puncture wound with foreign body of lip, initial encounter',
  'S01541D Puncture wound with foreign body of lip, subsequent encounter',
  'S01541S Puncture wound with foreign body of lip, sequela',
  'S01542A Puncture wound with foreign body of oral cavity, initial encounter',
  'S01542D Puncture wound with foreign body of oral cavity, subsequent encounter',
  'S01542S Puncture wound with foreign body of oral cavity, sequela',
  'S01551A Open bite of lip, initial encounter',
  'S01551D Open bite of lip, subsequent encounter',
  'S01551S Open bite of lip, sequela',
  'S01552A Open bite of oral cavity, initial encounter',
  'S01552D Open bite of oral cavity, subsequent encounter',
  'S01552S Open bite of oral cavity, sequela',
  'S0180XA Unspecified open wound of other part of head, initial encounter',
  'S0180XD Unspecified open wound of other part of head, subsequent encounter',
  'S0180XS Unspecified open wound of other part of head, sequela',
  'S0181XA Laceration without foreign body of other part of head, initial encounter',
  'S0181XD Laceration without foreign body of other part of head, subsequent encounter',
  'S0181XS Laceration without foreign body of other part of head, sequela',
  'S0182XA Laceration with foreign body of other part of head, initial encounter',
  'S0182XD Laceration with foreign body of other part of head, subsequent encounter',
  'S0182XS Laceration with foreign body of other part of head, sequela',
  'S0183XA Puncture wound without foreign body of other part of head, initial encounter',
  'S0183XD Puncture wound without foreign body of other part of head, subsequent encounter',
  'S0183XS Puncture wound without foreign body of other part of head, sequela',
  'S0184XA Puncture wound with foreign body of other part of head, initial encounter',
  'S0184XD Puncture wound with foreign body of other part of head, subsequent encounter',
  'S0184XS Puncture wound with foreign body of other part of head, sequela',
  'S0185XA Open bite of other part of head, initial encounter',
  'S0185XD Open bite of other part of head, subsequent encounter',
  'S0185XS Open bite of other part of head, sequela',
  'S0190XA Unspecified open wound of unspecified part of head, initial encounter',
  'S0190XD Unspecified open wound of unspecified part of head, subsequent encounter',
  'S0190XS Unspecified open wound of unspecified part of head, sequela',
  'S0191XA Laceration without foreign body of unspecified part of head, initial encounter',
  'S0191XD Laceration without foreign body of unspecified part of head, subsequent encounter',
  'S0191XS Laceration without foreign body of unspecified part of head, sequela',
  'S0192XA Laceration with foreign body of unspecified part of head, initial encounter',
  'S0192XD Laceration with foreign body of unspecified part of head, subsequent encounter',
  'S0192XS Laceration with foreign body of unspecified part of head, sequela',
  'S0193XA Puncture wound without foreign body of unspecified part of head, initial encounter',
  'S0193XD Puncture wound without foreign body of unspecified part of head, subsequent encounter',
  'S0193XS Puncture wound without foreign body of unspecified part of head, sequela',
  'S0194XA Puncture wound with foreign body of unspecified part of head, initial encounter',
  'S0194XD Puncture wound with foreign body of unspecified part of head, subsequent encounter',
  'S0194XS Puncture wound with foreign body of unspecified part of head, sequela',
  'S0195XA Open bite of unspecified part of head, initial encounter',
  'S0195XD Open bite of unspecified part of head, subsequent encounter',
  'S0195XS Open bite of unspecified part of head, sequela',
  'S020XXA Fracture of vault of skull, initial encounter for closed fracture',
  'S020XXB Fracture of vault of skull, initial encounter for open fracture',
  'S020XXD Fracture of vault of skull, subsequent encounter for fracture with routine healing',
  'S020XXG Fracture of vault of skull, subsequent encounter for fracture with delayed healing',
  'S020XXK Fracture of vault of skull, subsequent encounter for fracture with nonunion',
  'S020XXS Fracture of vault of skull, sequela',
  'S02101A Fracture of base of skull, right side, initial encounter for closed fracture',
  'S02101B Fracture of base of skull, right side, initial encounter for open fracture',
  'S02101D Fracture of base of skull, right side, subsequent encounter for fracture with routine healing',
  'S02101G Fracture of base of skull, right side, subsequent encounter for fracture with delayed healing',
  'S02101K Fracture of base of skull, right side, subsequent encounter for fracture with nonunion',
  'S02101S Fracture of base of skull, right side, sequela',
  'S02102A Fracture of base of skull, left side, initial encounter for closed fracture',
  'S02102B Fracture of base of skull, left side, initial encounter for open fracture',
  'S02102D Fracture of base of skull, left side, subsequent encounter for fracture with routine healing',
  'S02102G Fracture of base of skull, left side, subsequent encounter for fracture with delayed healing',
  'S02102K Fracture of base of skull, left side, subsequent encounter for fracture with nonunion',
  'S02102S Fracture of base of skull, left side, sequela',
  'S02109A Fracture of base of skull, unspecified side, initial encounter for closed fracture',
  'S02109B Fracture of base of skull, unspecified side, initial encounter for open fracture',
  'S02109D Fracture of base of skull, unspecified side, subsequent encounter for fracture with routine',
  'healing',
  'S02109G Fracture of base of skull, unspecified side, subsequent encounter for fracture with delayed',
  'healing',
  'S02109K Fracture of base of skull, unspecified side, subsequent encounter for fracture with nonunion',
  'S02109S Fracture of base of skull, unspecified side, sequela',
  'S02110A Type I occipital condyle fracture, unspecified side, initial encounter for closed fracture',
  'S02110B Type I occipital condyle fracture, unspecified side, initial encounter for open fracture',
  'S02110D Type I occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'routine healing',
  'S02110G Type I occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'delayed healing',
  'S02110K Type I occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'nonunion',
  'S02110S Type I occipital condyle fracture, unspecified side, sequela',
  'S02111A Type II occipital condyle fracture, unspecified side, initial encounter for closed fracture',
  'S02111B Type II occipital condyle fracture, unspecified side, initial encounter for open fracture',
  'S02111D Type II occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'routine healing',
  'S02111G Type II occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'delayed healing',
  'S02111K Type II occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'nonunion',
  'S02111S Type II occipital condyle fracture, unspecified side, sequela',
  'S02112A Type III occipital condyle fracture, unspecified side, initial encounter for closed fracture',
  'S02112B Type III occipital condyle fracture, unspecified side, initial encounter for open fracture',
  'S02112D Type III occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'routine healing',
  'S02112G Type III occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'delayed healing',
  'S02112K Type III occipital condyle fracture, unspecified side, subsequent encounter for fracture with',
  'nonunion',
  'S02112S Type III occipital condyle fracture, unspecified side, sequela',
  'S02113A Unspecified occipital condyle fracture, initial encounter for closed fracture',
  'S02113B Unspecified occipital condyle fracture, initial encounter for open fracture',
  'S02113D Unspecified occipital condyle fracture, subsequent encounter for fracture with routine healing',
  'S02113G Unspecified occipital condyle fracture, subsequent encounter for fracture with delayed healing',
  'S02113K Unspecified occipital condyle fracture, subsequent encounter for fracture with nonunion',
  'S02113S Unspecified occipital condyle fracture, sequela',
  'S02118A Other fracture of occiput, unspecified side, initial encounter for closed fracture',
  'S02118B Other fracture of occiput, unspecified side, initial encounter for open fracture',
  'S02118D Other fracture of occiput, unspecified side, subsequent encounter for fracture with routine',
  'healing',
  'S02118G Other fracture of occiput, unspecified side, subsequent encounter for fracture with delayed',
  'healing',
  'S02118K Other fracture of occiput, unspecified side, subsequent encounter for fracture with nonunion',
  'S02118S Other fracture of occiput, unspecified side, sequela',
  'S02119A Unspecified fracture of occiput, initial encounter for closed fracture',
  'S02119B Unspecified fracture of occiput, initial encounter for open fracture',
  'S02119D Unspecified fracture of occiput, subsequent encounter for fracture with routine healing',
  'S02119G Unspecified fracture of occiput, subsequent encounter for fracture with delayed healing',
  'S02119K Unspecified fracture of occiput, subsequent encounter for fracture with nonunion',
  'S02119S Unspecified fracture of occiput, sequela',
  'S0211AA Type I occipital condyle fracture, right side, initial encounter for closed fracture',
  'S0211AB Type I occipital condyle fracture, right side, initial encounter for open fracture',
  'S0211AD Type I occipital condyle fracture, right side, subsequent encounter for fracture with routine',
  'healing',
  'S0211AG Type I occipital condyle fracture, right side, subsequent encounter for fracture with delayed',
  'healing',
  'S0211AK Type I occipital condyle fracture, right side, subsequent encounter for fracture with nonunion',
  'S0211AS Type I occipital condyle fracture, right side, sequela',
  'S0211BA Type I occipital condyle fracture, left side, initial encounter for closed fracture',
  'S0211BB Type I occipital condyle fracture, left side, initial encounter for open fracture',
  'S0211BD Type I occipital condyle fracture, left side, subsequent encounter for fracture with routine',
  'healing',
  'S0211BG Type I occipital condyle fracture, left side, subsequent encounter for fracture with delayed',
  'healing',
  'S0211BK Type I occipital condyle fracture, left side, subsequent encounter for fracture with nonunion',
  'S0211BS Type I occipital condyle fracture, left side, sequela',
  'S0211CA Type II occipital condyle fracture, right side, initial encounter for closed fracture',
  'S0211CB Type II occipital condyle fracture, right side, initial encounter for open fracture',
  'S0211CD Type II occipital condyle fracture, right side, subsequent encounter for fracture with routine',
  'healing',
  'S0211CG Type II occipital condyle fracture, right side, subsequent encounter for fracture with delayed',
  'healing',
  'S0211CK Type II occipital condyle fracture, right side, subsequent encounter for fracture with nonunion',
  'S0211CS Type II occipital condyle fracture, right side, sequela',
  'S0211DA Type II occipital condyle fracture, left side, initial encounter for closed fracture',
  'S0211DB Type II occipital condyle fracture, left side, initial encounter for open fracture',
  'S0211DD Type II occipital condyle fracture, left side, subsequent encounter for fracture with routine',
  'healing',
  'S0211DG Type II occipital condyle fracture, left side, subsequent encounter for fracture with delayed',
  'healing',
  'S0211DK Type II occipital condyle fracture, left side, subsequent encounter for fracture with nonunion',
  'S0211DS Type II occipital condyle fracture, left side, sequela',
  'S0211EA Type III occipital condyle fracture, right side, initial encounter for closed fracture',
  'S0211EB Type III occipital condyle fracture, right side, initial encounter for open fracture',
  'S0211ED Type III occipital condyle fracture, right side, subsequent encounter for fracture with routine',
  'healing',
  'S0211EG Type III occipital condyle fracture, right side, subsequent encounter for fracture with delayed',
  'healing',
  'S0211EK Type III occipital condyle fracture, right side, subsequent encounter for fracture with nonunion',
  'S0211ES Type III occipital condyle fracture, right side, sequela',
  'S0211FA Type III occipital condyle fracture, left side, initial encounter for closed fracture',
  'S0211FB Type III occipital condyle fracture, left side, initial encounter for open fracture',
  'S0211FD Type III occipital condyle fracture, left side, subsequent encounter for fracture with routine',
  'healing',
  'S0211FG Type III occipital condyle fracture, left side, subsequent encounter for fracture with delayed',
  'healing',
  'S0211FK Type III occipital condyle fracture, left side, subsequent encounter for fracture with nonunion',
  'S0211FS Type III occipital condyle fracture, left side, sequela',
  'S0211GA Other fracture of occiput, right side, initial encounter for closed fracture',
  'S0211GB Other fracture of occiput, right side, initial encounter for open fracture',
  'S0211GD Other fracture of occiput, right side, subsequent encounter for fracture with routine healing',
  'S0211GG Other fracture of occiput, right side, subsequent encounter for fracture with delayed healing',
  'S0211GK Other fracture of occiput, right side, subsequent encounter for fracture with nonunion',
  'S0211GS Other fracture of occiput, right side, sequela',
  'S0211HA Other fracture of occiput, left side, initial encounter for closed fracture',
  'S0211HB Other fracture of occiput, left side, initial encounter for open fracture',
  'S0211HD Other fracture of occiput, left side, subsequent encounter for fracture with routine healing',
  'S0211HG Other fracture of occiput, left side, subsequent encounter for fracture with delayed healing',
  'S0211HK Other fracture of occiput, left side, subsequent encounter for fracture with nonunion',
  'S0211HS Other fracture of occiput, left side, sequela',
  'S0219XA Other fracture of base of skull, initial encounter for closed fracture',
  'S0219XB Other fracture of base of skull, initial encounter for open fracture',
  'S0219XD Other fracture of base of skull, subsequent encounter for fracture with routine healing',
  'S0219XG Other fracture of base of skull, subsequent encounter for fracture with delayed healing',
  'S0219XK Other fracture of base of skull, subsequent encounter for fracture with nonunion',
  'S0219XS Other fracture of base of skull, sequela',
  'S022XXA Fracture of nasal bones, initial encounter for closed fracture',
  'S022XXB Fracture of nasal bones, initial encounter for open fracture',
  'S022XXD Fracture of nasal bones, subsequent encounter for fracture with routine healing',
  'S022XXG Fracture of nasal bones, subsequent encounter for fracture with delayed healing',
  'S022XXK Fracture of nasal bones, subsequent encounter for fracture with nonunion',
  'S022XXS Fracture of nasal bones, sequela',
  'S0230XA Fracture of orbital floor, unspecified side, initial encounter for closed fracture',
  'S0230XB Fracture of orbital floor, unspecified side, initial encounter for open fracture',
  'S0230XD Fracture of orbital floor, unspecified side, subsequent encounter for fracture with routine',
  'healing',
  'S0230XG Fracture of orbital floor, unspecified side, subsequent encounter for fracture with delayed',
  'healing',
  'S0230XK Fracture of orbital floor, unspecified side, subsequent encounter for fracture with nonunion',
  'S0230XS Fracture of orbital floor, unspecified side, sequela',
  'S0231XA Fracture of orbital floor, right side, initial encounter for closed fracture',
  'S0231XB Fracture of orbital floor, right side, initial encounter for open fracture',
  'S0231XD Fracture of orbital floor, right side, subsequent encounter for fracture with routine healing',
  'S0231XG Fracture of orbital floor, right side, subsequent encounter for fracture with delayed healing',
  'S0231XK Fracture of orbital floor, right side, subsequent encounter for fracture with nonunion',
  'S0231XS Fracture of orbital floor, right side, sequela',
  'S0232XA Fracture of orbital floor, left side, initial encounter for closed fracture',
  'S0232XB Fracture of orbital floor, left side, initial encounter for open fracture',
  'S0232XD Fracture of orbital floor, left side, subsequent encounter for fracture with routine healing',
  'S0232XG Fracture of orbital floor, left side, subsequent encounter for fracture with delayed healing',
  'S0232XK Fracture of orbital floor, left side, subsequent encounter for fracture with nonunion',
  'S0232XS Fracture of orbital floor, left side, sequela',
  'S02400A Malar fracture, unspecified side, initial encounter for closed fracture',
  'S02400B Malar fracture, unspecified side, initial encounter for open fracture',
  'S02400D Malar fracture, unspecified side, subsequent encounter for fracture with routine healing',
  'S02400G Malar fracture, unspecified side, subsequent encounter for fracture with delayed healing',
  'S02400K Malar fracture, unspecified side, subsequent encounter for fracture with nonunion',
  'S02400S Malar fracture, unspecified side, sequela',
  'S02401A Maxillary fracture, unspecified side, initial encounter for closed fracture',
  'S02401B Maxillary fracture, unspecified side, initial encounter for open fracture',
  'S02401D Maxillary fracture, unspecified side, subsequent encounter for fracture with routine healing',
  'S02401G Maxillary fracture, unspecified side, subsequent encounter for fracture with delayed healing',
  'S02401K Maxillary fracture, unspecified side, subsequent encounter for fracture with nonunion',
  'S02401S Maxillary fracture, unspecified side, sequela',
  'S02402A Zygomatic fracture, unspecified side, initial encounter for closed fracture',
  'S02402B Zygomatic fracture, unspecified side, initial encounter for open fracture',
  'S02402D Zygomatic fracture, unspecified side, subsequent encounter for fracture with routine healing',
  'S02402G Zygomatic fracture, unspecified side, subsequent encounter for fracture with delayed healing',
  'S02402K Zygomatic fracture, unspecified side, subsequent encounter for fracture with nonunion',
  'S02402S Zygomatic fracture, unspecified side, sequela',
  'S0240AA Malar fracture, right side, initial encounter for closed fracture',
  'S0240AB Malar fracture, right side, initial encounter for open fracture',
  'S0240AD Malar fracture, right side, subsequent encounter for fracture with routine healing',
  'S0240AG Malar fracture, right side, subsequent encounter for fracture with delayed healing',
  'S0240AK Malar fracture, right side, subsequent encounter for fracture with nonunion',
  'S0240AS Malar fracture, right side, sequela',
  'S0240BA Malar fracture, left side, initial encounter for closed fracture',
  'S0240BB Malar fracture, left side, initial encounter for open fracture',
  'S0240BD Malar fracture, left side, subsequent encounter for fracture with routine healing',
  'S0240BG Malar fracture, left side, subsequent encounter for fracture with delayed healing',
  'S0240BK Malar fracture, left side, subsequent encounter for fracture with nonunion',
  'S0240BS Malar fracture, left side, sequela',
  'S0240CA Maxillary fracture, right side, initial encounter for closed fracture',
  'S0240CB Maxillary fracture, right side, initial encounter for open fracture',
  'S0240CD Maxillary fracture, right side, subsequent encounter for fracture with routine healing',
  'S0240CG Maxillary fracture, right side, subsequent encounter for fracture with delayed healing',
  'S0240CK Maxillary fracture, right side, subsequent encounter for fracture with nonunion',
  'S0240CS Maxillary fracture, right side, sequela',
  'S0240DA Maxillary fracture, left side, initial encounter for closed fracture',
  'S0240DB Maxillary fracture, left side, initial encounter for open fracture',
  'S0240DD Maxillary fracture, left side, subsequent encounter for fracture with routine healing',
  'S0240DG Maxillary fracture, left side, subsequent encounter for fracture with delayed healing',
  'S0240DK Maxillary fracture, left side, subsequent encounter for fracture with nonunion',
  'S0240DS Maxillary fracture, left side, sequela',
  'S0240EA Zygomatic fracture, right side, initial encounter for closed fracture',
  'S0240EB Zygomatic fracture, right side, initial encounter for open fracture',
  'S0240ED Zygomatic fracture, right side, subsequent encounter for fracture with routine healing',
  'S0240EG Zygomatic fracture, right side, subsequent encounter for fracture with delayed healing',
  'S0240EK Zygomatic fracture, right side, subsequent encounter for fracture with nonunion',
  'S0240ES Zygomatic fracture, right side, sequela',
  'S0240FA Zygomatic fracture, left side, initial encounter for closed fracture',
  'S0240FB Zygomatic fracture, left side, initial encounter for open fracture',
  'S0240FD Zygomatic fracture, left side, subsequent encounter for fracture with routine healing',
  'S0240FG Zygomatic fracture, left side, subsequent encounter for fracture with delayed healing',
  'S0240FK Zygomatic fracture, left side, subsequent encounter for fracture with nonunion',
  'S0240FS Zygomatic fracture, left side, sequela',
  'S02411A LeFort I fracture, initial encounter for closed fracture',
  'S02411B LeFort I fracture, initial encounter for open fracture',
  'S02411D LeFort I fracture, subsequent encounter for fracture with routine healing',
  'S02411G LeFort I fracture, subsequent encounter for fracture with delayed healing',
  'S02411K LeFort I fracture, subsequent encounter for fracture with nonunion',
  'S02411S LeFort I fracture, sequela',
  'S02412A LeFort II fracture, initial encounter for closed fracture',
  'S02412B LeFort II fracture, initial encounter for open fracture',
  'S02412D LeFort II fracture, subsequent encounter for fracture with routine healing',
  'S02412G LeFort II fracture, subsequent encounter for fracture with delayed healing',
  'S02412K LeFort II fracture, subsequent encounter for fracture with nonunion',
  'S02412S LeFort II fracture, sequela',
  'S02413A LeFort III fracture, initial encounter for closed fracture',
  'S02413B LeFort III fracture, initial encounter for open fracture',
  'S02413D LeFort III fracture, subsequent encounter for fracture with routine healing',
  'S02413G LeFort III fracture, subsequent encounter for fracture with delayed healing',
  'S02413K LeFort III fracture, subsequent encounter for fracture with nonunion',
  'S02413S LeFort III fracture, sequela',
  'S0242XA Fracture of alveolus of maxilla, initial encounter for closed fracture',
  'S0242XB Fracture of alveolus of maxilla, initial encounter for open fracture',
  'S0242XD Fracture of alveolus of maxilla, subsequent encounter for fracture with routine healing',
  'S0242XG Fracture of alveolus of maxilla, subsequent encounter for fracture with delayed healing',
  'S0242XK Fracture of alveolus of maxilla, subsequent encounter for fracture with nonunion',
  'S0242XS Fracture of alveolus of maxilla, sequela',
  'S025XXA Fracture of tooth (traumatic), initial encounter for closed fracture',
  'S025XXB Fracture of tooth (traumatic), initial encounter for open fracture',
  'S025XXD Fracture of tooth (traumatic), subsequent encounter for fracture with routine healing',
  'S025XXG Fracture of tooth (traumatic), subsequent encounter for fracture with delayed healing',
  'S025XXK Fracture of tooth (traumatic), subsequent encounter for fracture with nonunion',
  'S025XXS Fracture of tooth (traumatic), sequela',
  'S02600A Fracture of unspecified part of body of mandible, unspecified side, initial encounter for closed',
  'fracture',
  'S02600B Fracture of unspecified part of body of mandible, unspecified side, initial encounter for open',
  'fracture',
  'S02600D Fracture of unspecified part of body of mandible, unspecified side, subsequent encounter for',
  'fracture with routine healing',
  'S02600G Fracture of unspecified part of body of mandible, unspecified side, subsequent encounter for',
  'fracture with delayed healing',
  'S02600K Fracture of unspecified part of body of mandible, unspecified side, subsequent encounter for',
  'fracture with nonunion',
  'S02600S Fracture of unspecified part of body of mandible, unspecified side, sequela',
  'S02601A Fracture of unspecified part of body of right mandible, initial encounter for closed fracture',
  'S02601B Fracture of unspecified part of body of right mandible, initial encounter for open fracture',
  'S02601D Fracture of unspecified part of body of right mandible, subsequent encounter for fracture with',
  'routine healing',
  'S02601G Fracture of unspecified part of body of right mandible, subsequent encounter for fracture with',
  'delayed healing',
  'S02601K Fracture of unspecified part of body of right mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02601S Fracture of unspecified part of body of right mandible, sequela',
  'S02602A Fracture of unspecified part of body of left mandible, initial encounter for closed fracture',
  'S02602B Fracture of unspecified part of body of left mandible, initial encounter for open fracture',
  'S02602D Fracture of unspecified part of body of left mandible, subsequent encounter for fracture with',
  'routine healing',
  'S02602G Fracture of unspecified part of body of left mandible, subsequent encounter for fracture with',
  'delayed healing',
  'S02602K Fracture of unspecified part of body of left mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02602S Fracture of unspecified part of body of left mandible, sequela',
  'S02609A Fracture of mandible, unspecified, initial encounter for closed fracture',
  'S02609B Fracture of mandible, unspecified, initial encounter for open fracture',
  'S02609D Fracture of mandible, unspecified, subsequent encounter for fracture with routine healing',
  'S02609G Fracture of mandible, unspecified, subsequent encounter for fracture with delayed healing',
  'S02609K Fracture of mandible, unspecified, subsequent encounter for fracture with nonunion',
  'S02609S Fracture of mandible, unspecified, sequela',
  'S02610A Fracture of condylar process of mandible, unspecified side, initial encounter for closed fracture',
  'S02610B Fracture of condylar process of mandible, unspecified side, initial encounter for open fracture',
  'S02610D Fracture of condylar process of mandible, unspecified side, subsequent encounter for fracture',
  'with routine healing',
  'S02610G Fracture of condylar process of mandible, unspecified side, subsequent encounter for fracture',
  'with delayed healing',
  'S02610K Fracture of condylar process of mandible, unspecified side, subsequent encounter for fracture',
  'with nonunion',
  'S02610S Fracture of condylar process of mandible, unspecified side, sequela',
  'S02611A Fracture of condylar process of right mandible, initial encounter for closed fracture',
  'S02611B Fracture of condylar process of right mandible, initial encounter for open fracture',
  'S02611D Fracture of condylar process of right mandible, subsequent encounter for fracture with routine',
  'healing',
  'S02611G Fracture of condylar process of right mandible, subsequent encounter for fracture with',
  'delayed healing',
  'S02611K Fracture of condylar process of right mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02611S Fracture of condylar process of right mandible, sequela',
  'S02612A Fracture of condylar process of left mandible, initial encounter for closed fracture',
  'S02612B Fracture of condylar process of left mandible, initial encounter for open fracture',
  'S02612D Fracture of condylar process of left mandible, subsequent encounter for fracture with routine',
  'healing',
  'S02612G Fracture of condylar process of left mandible, subsequent encounter for fracture with delayed',
  'healing',
  'S02612K Fracture of condylar process of left mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02612S Fracture of condylar process of left mandible, sequela',
  'S02620A Fracture of subcondylar process of mandible, unspecified side, initial encounter for closed',
  'fracture',
  'S02620B Fracture of subcondylar process of mandible, unspecified side, initial encounter for open',
  'fracture',
  'S02620D Fracture of subcondylar process of mandible, unspecified side, subsequent encounter for',
  'fracture with routine healing',
  'S02620G Fracture of subcondylar process of mandible, unspecified side, subsequent encounter for',
  'fracture with delayed healing',
  'S02620K Fracture of subcondylar process of mandible, unspecified side, subsequent encounter for',
  'fracture with nonunion',
  'S02620S Fracture of subcondylar process of mandible, unspecified side, sequela',
  'S02621A Fracture of subcondylar process of right mandible, initial encounter for closed fracture',
  'S02621B Fracture of subcondylar process of right mandible, initial encounter for open fracture',
  'S02621D Fracture of subcondylar process of right mandible, subsequent encounter for fracture with',
  'routine healing',
  'S02621G Fracture of subcondylar process of right mandible, subsequent encounter for fracture with',
  'delayed healing',
  'S02621K Fracture of subcondylar process of right mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02621S Fracture of subcondylar process of right mandible, sequela',
  'S02622A Fracture of subcondylar process of left mandible, initial encounter for closed fracture',
  'S02622B Fracture of subcondylar process of left mandible, initial encounter for open fracture',
  'S02622D Fracture of subcondylar process of left mandible, subsequent encounter for fracture with',
  'routine healing',
  'S02622G Fracture of subcondylar process of left mandible, subsequent encounter for fracture with',
  'delayed healing',
  'S02622K Fracture of subcondylar process of left mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02622S Fracture of subcondylar process of left mandible, sequela',
  'S02630A Fracture of coronoid process of mandible, unspecified side, initial encounter for closed fracture',
  'S02630B Fracture of coronoid process of mandible, unspecified side, initial encounter for open fracture',
  'S02630D Fracture of coronoid process of mandible, unspecified side, subsequent encounter for fracture',
  'with routine healing',
  'S02630G Fracture of coronoid process of mandible, unspecified side, subsequent encounter for fracture',
  'with delayed healing',
  'S02630K Fracture of coronoid process of mandible, unspecified side, subsequent encounter for fracture',
  'with nonunion',
  'S02630S Fracture of coronoid process of mandible, unspecified side, sequela',
  'S02631A Fracture of coronoid process of right mandible, initial encounter for closed fracture',
  'S02631B Fracture of coronoid process of right mandible, initial encounter for open fracture',
  'S02631D Fracture of coronoid process of right mandible, subsequent encounter for fracture with routine',
  'healing',
  'S02631G Fracture of coronoid process of right mandible, subsequent encounter for fracture with',
  'delayed healing',
  'S02631K Fracture of coronoid process of right mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02631S Fracture of coronoid process of right mandible, sequela',
  'S02632A Fracture of coronoid process of left mandible, initial encounter for closed fracture',
  'S02632B Fracture of coronoid process of left mandible, initial encounter for open fracture',
  'S02632D Fracture of coronoid process of left mandible, subsequent encounter for fracture with routine',
  'healing',
  'S02632G Fracture of coronoid process of left mandible, subsequent encounter for fracture with delayed',
  'healing',
  'S02632K Fracture of coronoid process of left mandible, subsequent encounter for fracture with',
  'nonunion',
  'S02632S Fracture of coronoid process of left mandible, sequela',
  'S02640A Fracture of ramus of mandible, unspecified side, initial encounter for closed fracture',
  'S02640B Fracture of ramus of mandible, unspecified side, initial encounter for open fracture',
  'S02640D Fracture of ramus of mandible, unspecified side, subsequent encounter for fracture with',
  'routine healing',
  'S02640G Fracture of ramus of mandible, unspecified side, subsequent encounter for fracture with',
  'delayed healing',
  'S02640K Fracture of ramus of mandible, unspecified side, subsequent encounter for fracture with',
  'nonunion',
  'S02640S Fracture of ramus of mandible, unspecified side, sequela',
  'S02641A Fracture of ramus of right mandible, initial encounter for closed fracture',
  'S02641B Fracture of ramus of right mandible, initial encounter for open fracture',
  'S02641D Fracture of ramus of right mandible, subsequent encounter for fracture with routine healing',
  'S02641G Fracture of ramus of right mandible, subsequent encounter for fracture with delayed healing',
  'S02641K Fracture of ramus of right mandible, subsequent encounter for fracture with nonunion',
  'S02641S Fracture of ramus of right mandible, sequela',
  'S02642A Fracture of ramus of left mandible, initial encounter for closed fracture',
  'S02642B Fracture of ramus of left mandible, initial encounter for open fracture',
  'S02642D Fracture of ramus of left mandible, subsequent encounter for fracture with routine healing',
  'S02642G Fracture of ramus of left mandible, subsequent encounter for fracture with delayed healing',
  'S02642K Fracture of ramus of left mandible, subsequent encounter for fracture with nonunion',
  'S02642S Fracture of ramus of left mandible, sequela',
  'S02650A Fracture of angle of mandible, unspecified side, initial encounter for closed fracture',
  'S02650B Fracture of angle of mandible, unspecified side, initial encounter for open fracture',
  'S02650D Fracture of angle of mandible, unspecified side, subsequent encounter for fracture with',
  'routine healing',
  'S02650G Fracture of angle of mandible, unspecified side, subsequent encounter for fracture with',
  'delayed healing',
  'S02650K Fracture of angle of mandible, unspecified side, subsequent encounter for fracture with',
  'nonunion',
  'S02650S Fracture of angle of mandible, unspecified side, sequela',
  'S02651A Fracture of angle of right mandible, initial encounter for closed fracture',
  'S02651B Fracture of angle of right mandible, initial encounter for open fracture',
  'S02651D Fracture of angle of right mandible, subsequent encounter for fracture with routine healing',
  'S02651G Fracture of angle of right mandible, subsequent encounter for fracture with delayed healing',
  'S02651K Fracture of angle of right mandible, subsequent encounter for fracture with nonunion',
  'S02651S Fracture of angle of right mandible, sequela',
  'S02652A Fracture of angle of left mandible, initial encounter for closed fracture',
  'S02652B Fracture of angle of left mandible, initial encounter for open fracture',
  'S02652D Fracture of angle of left mandible, subsequent encounter for fracture with routine healing',
  'S02652G Fracture of angle of left mandible, subsequent encounter for fracture with delayed healing',
  'S02652K Fracture of angle of left mandible, subsequent encounter for fracture with nonunion',
  'S02652S Fracture of angle of left mandible, sequela',
  'S0266XA Fracture of symphysis of mandible, initial encounter for closed fracture',
  'S0266XB Fracture of symphysis of mandible, initial encounter for open fracture',
  'S0266XD Fracture of symphysis of mandible, subsequent encounter for fracture with routine healing',
  'S0266XG Fracture of symphysis of mandible, subsequent encounter for fracture with delayed healing',
  'S0266XK Fracture of symphysis of mandible, subsequent encounter for fracture with nonunion',
  'S0266XS Fracture of symphysis of mandible, sequela',
  'S02670A Fracture of alveolus of mandible, unspecified side, initial encounter for closed fracture',
  'S02670B Fracture of alveolus of mandible, unspecified side, initial encounter for open fracture',
  'S02670D Fracture of alveolus of mandible, unspecified side, subsequent encounter for fracture with',
  'routine healing',
  'S02670G Fracture of alveolus of mandible, unspecified side, subsequent encounter for fracture with',
  'delayed healing',
  'S02670K Fracture of alveolus of mandible, unspecified side, subsequent encounter for fracture with',
  'nonunion',
  'S02670S Fracture of alveolus of mandible, unspecified side, sequela',
  'S02671A Fracture of alveolus of right mandible, initial encounter for closed fracture',
  'S02671B Fracture of alveolus of right mandible, initial encounter for open fracture',
  'S02671D Fracture of alveolus of right mandible, subsequent encounter for fracture with routine healing',
  'S02671G Fracture of alveolus of right mandible, subsequent encounter for fracture with delayed healing',
  'S02671K Fracture of alveolus of right mandible, subsequent encounter for fracture with nonunion',
  'S02671S Fracture of alveolus of right mandible, sequela',
  'S02672A Fracture of alveolus of left mandible, initial encounter for closed fracture',
  'S02672B Fracture of alveolus of left mandible, initial encounter for open fracture',
  'S02672D Fracture of alveolus of left mandible, subsequent encounter for fracture with routine healing',
  'S02672G Fracture of alveolus of left mandible, subsequent encounter for fracture with delayed healing',
  'S02672K Fracture of alveolus of left mandible, subsequent encounter for fracture with nonunion',
  'S02672S Fracture of alveolus of left mandible, sequela',
  'S0269XA Fracture of mandible of other specified site, initial encounter for closed fracture',
  'S0269XB Fracture of mandible of other specified site, initial encounter for open fracture',
  'S0269XD Fracture of mandible of other specified site, subsequent encounter for fracture with routine',
  'healing',
  'S0269XG Fracture of mandible of other specified site, subsequent encounter for fracture with delayed',
  'healing',
  'S0269XK Fracture of mandible of other specified site, subsequent encounter for fracture with nonunion',
  'S0269XS Fracture of mandible of other specified site, sequela',
  'S0280XA Fracture of other specified skull and facial bones, unspecified side, initial encounter for closed',
  'fracture',
  'S0280XB Fracture of other specified skull and facial bones, unspecified side, initial encounter for open',
  'fracture',
  'S0280XD Fracture of other specified skull and facial bones, unspecified side, subsequent encounter for',
  'fracture with routine healing',
  'S0280XG Fracture of other specified skull and facial bones, unspecified side, subsequent encounter for',
  'fracture with delayed healing',
  'S0280XK Fracture of other specified skull and facial bones, unspecified side, subsequent encounter for',
  'fracture with nonunion',
  'S0280XS Fracture of other specified skull and facial bones, unspecified side, sequela',
  'S0281XA Fracture of other specified skull and facial bones, right side, initial encounter for closed',
  'fracture',
  'S0281XB Fracture of other specified skull and facial bones, right side, initial encounter for open fracture',
  'S0281XD Fracture of other specified skull and facial bones, right side, subsequent encounter for fracture',
  'with routine healing',
  'S0281XG Fracture of other specified skull and facial bones, right side, subsequent encounter for fracture',
  'with delayed healing',
  'S0281XK Fracture of other specified skull and facial bones, right side, subsequent encounter for fracture',
  'with nonunion',
  'S0281XS Fracture of other specified skull and facial bones, right side, sequela',
  'S0282XA Fracture of other specified skull and facial bones, left side, initial encounter for closed fracture',
  'S0282XB Fracture of other specified skull and facial bones, left side, initial encounter for open fracture',
  'S0282XD Fracture of other specified skull and facial bones, left side, subsequent encounter for fracture',
  'with routine healing',
  'S0282XG Fracture of other specified skull and facial bones, left side, subsequent encounter for fracture',
  'with delayed healing',
  'S0282XK Fracture of other specified skull and facial bones, left side, subsequent encounter for fracture',
  'with nonunion',
  'S0282XS Fracture of other specified skull and facial bones, left side, sequela',
  'S0291XA Unspecified fracture of skull, initial encounter for closed fracture',
  'S0291XB Unspecified fracture of skull, initial encounter for open fracture',
  'S0291XD Unspecified fracture of skull, subsequent encounter for fracture with routine healing',
  'S0291XG Unspecified fracture of skull, subsequent encounter for fracture with delayed healing',
  'S0291XK Unspecified fracture of skull, subsequent encounter for fracture with nonunion',
  'S0291XS Unspecified fracture of skull, sequela',
  'S0292XA Unspecified fracture of facial bones, initial encounter for closed fracture',
  'S0292XB Unspecified fracture of facial bones, initial encounter for open fracture',
  'S0292XD Unspecified fracture of facial bones, subsequent encounter for fracture with routine healing',
  'S0292XG Unspecified fracture of facial bones, subsequent encounter for fracture with delayed healing',
  'S0292XK Unspecified fracture of facial bones, subsequent encounter for fracture with nonunion',
  'S0292XS Unspecified fracture of facial bones, sequela',
  'S0300XA Dislocation of jaw, unspecified side, initial encounter',
  'S0300XD Dislocation of jaw, unspecified side, subsequent encounter',
  'S0300XS Dislocation of jaw, unspecified side, sequela',
  'S0301XA Dislocation of jaw, right side, initial encounter',
  'S0301XD Dislocation of jaw, right side, subsequent encounter',
  'S0301XS Dislocation of jaw, right side, sequela',
  'S0302XA Dislocation of jaw, left side, initial encounter',
  'S0302XD Dislocation of jaw, left side, subsequent encounter',
  'S0302XS Dislocation of jaw, left side, sequela',
  'S0303XA Dislocation of jaw, bilateral, initial encounter',
  'S0303XD Dislocation of jaw, bilateral, subsequent encounter',
  'S0303XS Dislocation of jaw, bilateral, sequela',
  'S031XXA Dislocation of septal cartilage of nose, initial encounter',
  'S031XXD Dislocation of septal cartilage of nose, subsequent encounter',
  'S031XXS Dislocation of septal cartilage of nose, sequela',
  'S032XXA Dislocation of tooth, initial encounter',
  'S032XXD Dislocation of tooth, subsequent encounter',
  'S032XXS Dislocation of tooth, sequela',
  'S0340XA Sprain of jaw, unspecified side, initial encounter',
  'S0340XD Sprain of jaw, unspecified side, subsequent encounter',
  'S0340XS Sprain of jaw, unspecified side, sequela',
  'S0341XA Sprain of jaw, right side, initial encounter',
  'S0341XD Sprain of jaw, right side, subsequent encounter',
  'S0341XS Sprain of jaw, right side, sequela',
  'S0342XA Sprain of jaw, left side, initial encounter',
  'S0342XD Sprain of jaw, left side, subsequent encounter',
  'S0342XS Sprain of jaw, left side, sequela',
  'S0343XA Sprain of jaw, bilateral, initial encounter',
  'S0343XD Sprain of jaw, bilateral, subsequent encounter',
  'S0343XS Sprain of jaw, bilateral, sequela',
  'S038XXA Sprain of joints and ligaments of other parts of head, initial encounter',
  'S038XXD Sprain of joints and ligaments of other parts of head, subsequent encounter',
  'S038XXS Sprain of joints and ligaments of other parts of head, sequela',
  'S039XXA Sprain of joints and ligaments of unspecified parts of head, initial encounter',
  'S039XXD Sprain of joints and ligaments of unspecified parts of head, subsequent encounter',
  'S039XXS Sprain of joints and ligaments of unspecified parts of head, sequela',
  'S04011A Injury of optic nerve, right eye, initial encounter',
  'S04011D Injury of optic nerve, right eye, subsequent encounter',
  'S04011S Injury of optic nerve, right eye, sequela',
  'S04012A Injury of optic nerve, left eye, initial encounter',
  'S04012D Injury of optic nerve, left eye, subsequent encounter',
  'S04012S Injury of optic nerve, left eye, sequela',
  'S04019A Injury of optic nerve, unspecified eye, initial encounter',
  'S04019D Injury of optic nerve, unspecified eye, subsequent encounter',
  'S04019S Injury of optic nerve, unspecified eye, sequela',
  'S0402XA Injury of optic chiasm, initial encounter',
  'S0402XD Injury of optic chiasm, subsequent encounter',
  'S0402XS Injury of optic chiasm, sequela',
  'S04031A Injury of optic tract and pathways, right eye, initial encounter',
  'S04031D Injury of optic tract and pathways, right eye, subsequent encounter',
  'S04031S Injury of optic tract and pathways, right eye, sequela',
  'S04032A Injury of optic tract and pathways, left eye, initial encounter',
  'S04032D Injury of optic tract and pathways, left eye, subsequent encounter',
  'S04032S Injury of optic tract and pathways, left eye, sequela',
  'S04039A Injury of optic tract and pathways, unspecified eye, initial encounter',
  'S04039D Injury of optic tract and pathways, unspecified eye, subsequent encounter',
  'S04039S Injury of optic tract and pathways, unspecified eye, sequela',
  'S04041A Injury of visual cortex, right eye, initial encounter',
  'S04041D Injury of visual cortex, right eye, subsequent encounter',
  'S04041S Injury of visual cortex, right eye, sequela',
  'S04042A Injury of visual cortex, left eye, initial encounter',
  'S04042D Injury of visual cortex, left eye, subsequent encounter',
  'S04042S Injury of visual cortex, left eye, sequela',
  'S04049A Injury of visual cortex, unspecified eye, initial encounter',
  'S04049D Injury of visual cortex, unspecified eye, subsequent encounter',
  'S04049S Injury of visual cortex, unspecified eye, sequela',
  'S0410XA Injury of oculomotor nerve, unspecified side, initial encounter',
  'S0410XD Injury of oculomotor nerve, unspecified side, subsequent encounter',
  'S0410XS Injury of oculomotor nerve, unspecified side, sequela',
  'S0411XA Injury of oculomotor nerve, right side, initial encounter',
  'S0411XD Injury of oculomotor nerve, right side, subsequent encounter',
  'S0411XS Injury of oculomotor nerve, right side, sequela',
  'S0412XA Injury of oculomotor nerve, left side, initial encounter',
  'S0412XD Injury of oculomotor nerve, left side, subsequent encounter',
  'S0412XS Injury of oculomotor nerve, left side, sequela',
  'S0420XA Injury of trochlear nerve, unspecified side, initial encounter',
  'S0420XD Injury of trochlear nerve, unspecified side, subsequent encounter',
  'S0420XS Injury of trochlear nerve, unspecified side, sequela',
  'S0421XA Injury of trochlear nerve, right side, initial encounter',
  'S0421XD Injury of trochlear nerve, right side, subsequent encounter',
  'S0421XS Injury of trochlear nerve, right side, sequela',
  'S0422XA Injury of trochlear nerve, left side, initial encounter',
  'S0422XD Injury of trochlear nerve, left side, subsequent encounter',
  'S0422XS Injury of trochlear nerve, left side, sequela',
  'S0430XA Injury of trigeminal nerve, unspecified side, initial encounter',
  'S0430XD Injury of trigeminal nerve, unspecified side, subsequent encounter',
  'S0430XS Injury of trigeminal nerve, unspecified side, sequela',
  'S0431XA Injury of trigeminal nerve, right side, initial encounter',
  'S0431XD Injury of trigeminal nerve, right side, subsequent encounter',
  'S0431XS Injury of trigeminal nerve, right side, sequela',
  'S0432XA Injury of trigeminal nerve, left side, initial encounter',
  'S0432XD Injury of trigeminal nerve, left side, subsequent encounter',
  'S0432XS Injury of trigeminal nerve, left side, sequela',
  'S0440XA Injury of abducent nerve, unspecified side, initial encounter',
  'S0440XD Injury of abducent nerve, unspecified side, subsequent encounter',
  'S0440XS Injury of abducent nerve, unspecified side, sequela',
  'S0441XA Injury of abducent nerve, right side, initial encounter',
  'S0441XD Injury of abducent nerve, right side, subsequent encounter',
  'S0441XS Injury of abducent nerve, right side, sequela',
  'S0442XA Injury of abducent nerve, left side, initial encounter',
  'S0442XD Injury of abducent nerve, left side, subsequent encounter',
  'S0442XS Injury of abducent nerve, left side, sequela',
  'S0450XA Injury of facial nerve, unspecified side, initial encounter',
  'S0450XD Injury of facial nerve, unspecified side, subsequent encounter',
  'S0450XS Injury of facial nerve, unspecified side, sequela',
  'S0451XA Injury of facial nerve, right side, initial encounter',
  'S0451XD Injury of facial nerve, right side, subsequent encounter',
  'S0451XS Injury of facial nerve, right side, sequela',
  'S0452XA Injury of facial nerve, left side, initial encounter',
  'S0452XD Injury of facial nerve, left side, subsequent encounter',
  'S0452XS Injury of facial nerve, left side, sequela',
  'S0460XA Injury of acoustic nerve, unspecified side, initial encounter',
  'S0460XD Injury of acoustic nerve, unspecified side, subsequent encounter',
  'S0460XS Injury of acoustic nerve, unspecified side, sequela',
  'S0461XA Injury of acoustic nerve, right side, initial encounter',
  'S0461XD Injury of acoustic nerve, right side, subsequent encounter',
  'S0461XS Injury of acoustic nerve, right side, sequela',
  'S0462XA Injury of acoustic nerve, left side, initial encounter',
  'S0462XD Injury of acoustic nerve, left side, subsequent encounter',
  'S0462XS Injury of acoustic nerve, left side, sequela',
  'S0470XA Injury of accessory nerve, unspecified side, initial encounter',
  'S0470XD Injury of accessory nerve, unspecified side, subsequent encounter',
  'S0470XS Injury of accessory nerve, unspecified side, sequela',
  'S0471XA Injury of accessory nerve, right side, initial encounter',
  'S0471XD Injury of accessory nerve, right side, subsequent encounter',
  'S0471XS Injury of accessory nerve, right side, sequela',
  'S0472XA Injury of accessory nerve, left side, initial encounter',
  'S0472XD Injury of accessory nerve, left side, subsequent encounter',
  'S0472XS Injury of accessory nerve, left side, sequela',
  'S04811A Injury of olfactory [1st ] nerve, right side, initial encounter',
  'S04811D Injury of olfactory [1st ] nerve, right side, subsequent encounter',
  'S04811S Injury of olfactory [1st ] nerve, right side, sequela',
  'S04812A Injury of olfactory [1st ] nerve, left side, initial encounter',
  'S04812D Injury of olfactory [1st ] nerve, left side, subsequent encounter',
  'S04812S Injury of olfactory [1st ] nerve, left side, sequela',
  'S04819A Injury of olfactory [1st ] nerve, unspecified side, initial encounter',
  'S04819D Injury of olfactory [1st ] nerve, unspecified side, subsequent encounter',
  'S04819S Injury of olfactory [1st ] nerve, unspecified side, sequela',
  'S04891A Injury of other cranial nerves, right side, initial encounter',
  'S04891D Injury of other cranial nerves, right side, subsequent encounter',
  'S04891S Injury of other cranial nerves, right side, sequela',
  'S04892A Injury of other cranial nerves, left side, initial encounter',
  'S04892D Injury of other cranial nerves, left side, subsequent encounter',
  'S04892S Injury of other cranial nerves, left side, sequela',
  'S04899A Injury of other cranial nerves, unspecified side, initial encounter',
  'S04899D Injury of other cranial nerves, unspecified side, subsequent encounter',
  'S04899S Injury of other cranial nerves, unspecified side, sequela',
  'S049XXA Injury of unspecified cranial nerve, initial encounter',
  'S049XXD Injury of unspecified cranial nerve, subsequent encounter',
  'S049XXS Injury of unspecified cranial nerve, sequela',
  'S0500XA Injury of conjunctiva and corneal abrasion without foreign body, unspecified eye, initial',
  'encounter',
  'S0500XD Injury of conjunctiva and corneal abrasion without foreign body, unspecified eye, subsequent',
  'encounter',
  'S0500XS Injury of conjunctiva and corneal abrasion without foreign body, unspecified eye, sequela',
  'S0501XA Injury of conjunctiva and corneal abrasion without foreign body, right eye, initial encounter',
  'S0501XD Injury of conjunctiva and corneal abrasion without foreign body, right eye, subsequent',
  'encounter',
  'S0501XS Injury of conjunctiva and corneal abrasion without foreign body, right eye, sequela',
  'S0502XA Injury of conjunctiva and corneal abrasion without foreign body, left eye, initial encounter',
  'S0502XD Injury of conjunctiva and corneal abrasion without foreign body, left eye, subsequent',
  'encounter',
  'S0502XS Injury of conjunctiva and corneal abrasion without foreign body, left eye, sequela',
  'S0510XA Contusion of eyeball and orbital tissues, unspecified eye, initial encounter',
  'S0510XD Contusion of eyeball and orbital tissues, unspecified eye, subsequent encounter',
  'S0510XS Contusion of eyeball and orbital tissues, unspecified eye, sequela',
  'S0511XA Contusion of eyeball and orbital tissues, right eye, initial encounter',
  'S0511XD Contusion of eyeball and orbital tissues, right eye, subsequent encounter',
  'S0511XS Contusion of eyeball and orbital tissues, right eye, sequela',
  'S0512XA Contusion of eyeball and orbital tissues, left eye, initial encounter',
  'S0512XD Contusion of eyeball and orbital tissues, left eye, subsequent encounter',
  'S0512XS Contusion of eyeball and orbital tissues, left eye, sequela',
  'S0520XA Ocular laceration and rupture with prolapse or loss of intraocular tissue, unspecified eye, initial',
  'encounter',
  'S0520XD Ocular laceration and rupture with prolapse or loss of intraocular tissue, unspecified eye,',
  'subsequent encounter',
  'S0520XS Ocular laceration and rupture with prolapse or loss of intraocular tissue, unspecified eye,',
  'sequela',
  'S0521XA Ocular laceration and rupture with prolapse or loss of intraocular tissue, right eye, initial',
  'encounter',
  'S0521XD Ocular laceration and rupture with prolapse or loss of intraocular tissue, right eye, subsequent',
  'encounter',
  'S0521XS Ocular laceration and rupture with prolapse or loss of intraocular tissue, right eye, sequela',
  'S0522XA Ocular laceration and rupture with prolapse or loss of intraocular tissue, left eye, initial',
  'encounter',
  'S0522XD Ocular laceration and rupture with prolapse or loss of intraocular tissue, left eye, subsequent',
  'encounter',
  'S0522XS Ocular laceration and rupture with prolapse or loss of intraocular tissue, left eye, sequela',
  'S0530XA Ocular laceration without prolapse or loss of intraocular tissue, unspecified eye, initial',
  'encounter',
  'S0530XD Ocular laceration without prolapse or loss of intraocular tissue, unspecified eye, subsequent',
  'encounter',
  'S0530XS Ocular laceration without prolapse or loss of intraocular tissue, unspecified eye, sequela',
  'S0531XA Ocular laceration without prolapse or loss of intraocular tissue, right eye, initial encounter',
  'S0531XD Ocular laceration without prolapse or loss of intraocular tissue, right eye, subsequent',
  'encounter',
  'S0531XS Ocular laceration without prolapse or loss of intraocular tissue, right eye, sequela',
  'S0532XA Ocular laceration without prolapse or loss of intraocular tissue, left eye, initial encounter',
  'S0532XD Ocular laceration without prolapse or loss of intraocular tissue, left eye, subsequent encounter',
  'S0532XS Ocular laceration without prolapse or loss of intraocular tissue, left eye, sequela',
  'S0540XA Penetrating wound of orbit with or without foreign body, unspecified eye, initial encounter',
  'S0540XD Penetrating wound of orbit with or without foreign body, unspecified eye, subsequent',
  'encounter',
  'S0540XS Penetrating wound of orbit with or without foreign body, unspecified eye, sequela',
  'S0541XA Penetrating wound of orbit with or without foreign body, right eye, initial encounter',
  'S0541XD Penetrating wound of orbit with or without foreign body, right eye, subsequent encounter',
  'S0541XS Penetrating wound of orbit with or without foreign body, right eye, sequela',
  'S0542XA Penetrating wound of orbit with or without foreign body, left eye, initial encounter',
  'S0542XD Penetrating wound of orbit with or without foreign body, left eye, subsequent encounter',
  'S0542XS Penetrating wound of orbit with or without foreign body, left eye, sequela',
  'S0550XA Penetrating wound with foreign body of unspecified eyeball, initial encounter',
  'S0550XD Penetrating wound with foreign body of unspecified eyeball, subsequent encounter',
  'S0550XS Penetrating wound with foreign body of unspecified eyeball, sequela',
  'S0551XA Penetrating wound with foreign body of right eyeball, initial encounter',
  'S0551XD Penetrating wound with foreign body of right eyeball, subsequent encounter',
  'S0551XS Penetrating wound with foreign body of right eyeball, sequela',
  'S0552XA Penetrating wound with foreign body of left eyeball, initial encounter',
  'S0552XD Penetrating wound with foreign body of left eyeball, subsequent encounter',
  'S0552XS Penetrating wound with foreign body of left eyeball, sequela',
  'S0560XA Penetrating wound without foreign body of unspecified eyeball, initial encounter',
  'S0560XD Penetrating wound without foreign body of unspecified eyeball, subsequent encounter',
  'S0560XS Penetrating wound without foreign body of unspecified eyeball, sequela',
  'S0561XA Penetrating wound without foreign body of right eyeball, initial encounter',
  'S0561XD Penetrating wound without foreign body of right eyeball, subsequent encounter',
  'S0561XS Penetrating wound without foreign body of right eyeball, sequela',
  'S0562XA Penetrating wound without foreign body of left eyeball, initial encounter',
  'S0562XD Penetrating wound without foreign body of left eyeball, subsequent encounter',
  'S0562XS Penetrating wound without foreign body of left eyeball, sequela',
  'S0570XA Avulsion of unspecified eye, initial encounter',
  'S0570XD Avulsion of unspecified eye, subsequent encounter',
  'S0570XS Avulsion of unspecified eye, sequela',
  'S0571XA Avulsion of right eye, initial encounter',
  'S0571XD Avulsion of right eye, subsequent encounter',
  'S0571XS Avulsion of right eye, sequela',
  'S0572XA Avulsion of left eye, initial encounter',
  'S0572XD Avulsion of left eye, subsequent encounter',
  'S0572XS Avulsion of left eye, sequela',
  'S058X1A Other injuries of right eye and orbit, initial encounter',
  'S058X1D Other injuries of right eye and orbit, subsequent encounter',
  'S058X1S Other injuries of right eye and orbit, sequela',
  'S058X2A Other injuries of left eye and orbit, initial encounter',
  'S058X2D Other injuries of left eye and orbit, subsequent encounter',
  'S058X2S Other injuries of left eye and orbit, sequela',
  'S058X9A Other injuries of unspecified eye and orbit, initial encounter',
  'S058X9D Other injuries of unspecified eye and orbit, subsequent encounter',
  'S058X9S Other injuries of unspecified eye and orbit, sequela',
  'S0590XA Unspecified injury of unspecified eye and orbit, initial encounter',
  'S0590XD Unspecified injury of unspecified eye and orbit, subsequent encounter',
  'S0590XS Unspecified injury of unspecified eye and orbit, sequela',
  'S0591XA Unspecified injury of right eye and orbit, initial encounter',
  'S0591XD Unspecified injury of right eye and orbit, subsequent encounter',
  'S0591XS Unspecified injury of right eye and orbit, sequela',
  'S0592XA Unspecified injury of left eye and orbit, initial encounter',
  'S0592XD Unspecified injury of left eye and orbit, subsequent encounter',
  'S0592XS Unspecified injury of left eye and orbit, sequela',
  'S060X0A Concussion without loss of consciousness, initial encounter',
  'S060X0D Concussion without loss of consciousness, subsequent encounter',
  'S060X0S Concussion without loss of consciousness, sequela',
  'S060X1A Concussion with loss of consciousness of 30 minutes or less, initial encounter',
  'S060X1D Concussion with loss of consciousness of 30 minutes or less, subsequent encounter',
  'S060X1S Concussion with loss of consciousness of 30 minutes or less, sequela',
  'S060X9A Concussion with loss of consciousness of unspecified duration, initial encounter',
  'S060X9D Concussion with loss of consciousness of unspecified duration, subsequent encounter',
  'S060X9S Concussion with loss of consciousness of unspecified duration, sequela',
  'S061X0A Traumatic cerebral edema without loss of consciousness, initial encounter',
  'S061X0D Traumatic cerebral edema without loss of consciousness, subsequent encounter',
  'S061X0S Traumatic cerebral edema without loss of consciousness, sequela',
  'S061X1A Traumatic cerebral edema with loss of consciousness of 30 minutes or less, initial encounter',
  'S061X1D Traumatic cerebral edema with loss of consciousness of 30 minutes or less, subsequent',
  'encounter',
  'S061X1S Traumatic cerebral edema with loss of consciousness of 30 minutes or less, sequela',
  'S061X2A Traumatic cerebral edema with loss of consciousness of 31 minutes to 59 minutes, initial',
  'encounter',
  'S061X2D Traumatic cerebral edema with loss of consciousness of 31 minutes to 59 minutes, subsequent',
  'encounter',
  'S061X2S Traumatic cerebral edema with loss of consciousness of 31 minutes to 59 minutes, sequela',
  'S061X3A Traumatic cerebral edema with loss of consciousness of 1 hour to 5 hours 59 minutes, initial',
  'encounter',
  'S061X3D Traumatic cerebral edema with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'subsequent encounter',
  'S061X3S Traumatic cerebral edema with loss of consciousness of 1 hour to 5 hours 59 minutes, sequela',
  'S061X4A Traumatic cerebral edema with loss of consciousness of 6 hours to 24 hours, initial encounter',
  'S061X4D Traumatic cerebral edema with loss of consciousness of 6 hours to 24 hours, subsequent',
  'encounter',
  'S061X4S Traumatic cerebral edema with loss of consciousness of 6 hours to 24 hours, sequela',
  'S061X5A Traumatic cerebral edema with loss of consciousness greater than 24 hours with return to preexisting conscious level, initial encounter',
  'S061X5D Traumatic cerebral edema with loss of consciousness greater than 24 hours with return to preexisting conscious level, subsequent encounter',
  'S061X5S Traumatic cerebral edema with loss of consciousness greater than 24 hours with return to preexisting conscious level, sequela',
  'S061X6A Traumatic cerebral edema with loss of consciousness greater than 24 hours without return to',
  'pre-existing conscious level with patient surviving, initial encounter',
  'S061X6D Traumatic cerebral edema with loss of consciousness greater than 24 hours without return to',
  'pre-existing conscious level with patient surviving, subsequent encounter',
  'S061X6S Traumatic cerebral edema with loss of consciousness greater than 24 hours without return to',
  'pre-existing conscious level with patient surviving, sequela',
  'S061X7A Traumatic cerebral edema with loss of consciousness of any duration with death due to brain',
  'injury prior to regaining consciousness, initial encounter',
  'S061X7D Traumatic cerebral edema with loss of consciousness of any duration with death due to brain',
  'injury prior to regaining consciousness, subsequent encounter',
  'S061X7S Traumatic cerebral edema with loss of consciousness of any duration with death due to brain',
  'injury prior to regaining consciousness, sequela',
  'S061X8A Traumatic cerebral edema with loss of consciousness of any duration with death due to other',
  'cause prior to regaining consciousness, initial encounter',
  'S061X8D Traumatic cerebral edema with loss of consciousness of any duration with death due to other',
  'cause prior to regaining consciousness, subsequent encounter',
  'S061X8S Traumatic cerebral edema with loss of consciousness of any duration with death due to other',
  'cause prior to regaining consciousness, sequela',
  'S061X9A Traumatic cerebral edema with loss of consciousness of unspecified duration, initial encounter',
  'S061X9D Traumatic cerebral edema with loss of consciousness of unspecified duration, subsequent',
  'encounter',
  'S061X9S Traumatic cerebral edema with loss of consciousness of unspecified duration, sequela',
  'S062X0A Diffuse traumatic brain injury without loss of consciousness, initial encounter',
  'S062X0D Diffuse traumatic brain injury without loss of consciousness, subsequent encounter',
  'S062X0S Diffuse traumatic brain injury without loss of consciousness, sequela',
  'S062X1A Diffuse traumatic brain injury with loss of consciousness of 30 minutes or less, initial encounter',
  'S062X1D Diffuse traumatic brain injury with loss of consciousness of 30 minutes or less, subsequent',
  'encounter',
  'S062X1S Diffuse traumatic brain injury with loss of consciousness of 30 minutes or less, sequela',
  'S062X2A Diffuse traumatic brain injury with loss of consciousness of 31 minutes to 59 minutes, initial',
  'encounter',
  'S062X2D Diffuse traumatic brain injury with loss of consciousness of 31 minutes to 59 minutes,',
  'subsequent encounter',
  'S062X2S Diffuse traumatic brain injury with loss of consciousness of 31 minutes to 59 minutes, sequela',
  'S062X3A Diffuse traumatic brain injury with loss of consciousness of 1 hour to 5 hours 59 minutes, initial',
  'encounter',
  'S062X3D Diffuse traumatic brain injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'subsequent encounter',
  'S062X3S Diffuse traumatic brain injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'sequela',
  'S062X4A Diffuse traumatic brain injury with loss of consciousness of 6 hours to 24 hours, initial',
  'encounter',
  'S062X4D Diffuse traumatic brain injury with loss of consciousness of 6 hours to 24 hours, subsequent',
  'encounter',
  'S062X4S Diffuse traumatic brain injury with loss of consciousness of 6 hours to 24 hours, sequela',
  'S062X5A Diffuse traumatic brain injury with loss of consciousness greater than 24 hours with return to',
  'pre-existing conscious levels, initial encounter',
  'S062X5D Diffuse traumatic brain injury with loss of consciousness greater than 24 hours with return to',
  'pre-existing conscious levels, subsequent encounter',
  'S062X5S Diffuse traumatic brain injury with loss of consciousness greater than 24 hours with return to',
  'pre-existing conscious levels, sequela',
  'S062X6A Diffuse traumatic brain injury with loss of consciousness greater than 24 hours without return',
  'to pre-existing conscious level with patient surviving, initial encounter',
  'S062X6D Diffuse traumatic brain injury with loss of consciousness greater than 24 hours without return',
  'to pre-existing conscious level with patient surviving, subsequent encounter',
  'S062X6S Diffuse traumatic brain injury with loss of consciousness greater than 24 hours without return',
  'to pre-existing conscious level with patient surviving, sequela',
  'S062X7A Diffuse traumatic brain injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, initial encounter',
  'S062X7D Diffuse traumatic brain injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, subsequent encounter',
  'S062X7S Diffuse traumatic brain injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, sequela',
  'S062X8A Diffuse traumatic brain injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, initial encounter',
  'S062X8D Diffuse traumatic brain injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, subsequent encounter',
  'S062X8S Diffuse traumatic brain injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, sequela',
  'S062X9A Diffuse traumatic brain injury with loss of consciousness of unspecified duration, initial',
  'encounter',
  'S062X9D Diffuse traumatic brain injury with loss of consciousness of unspecified duration, subsequent',
  'encounter',
  'S062X9S Diffuse traumatic brain injury with loss of consciousness of unspecified duration, sequela',
  'S06300A Unspecified focal traumatic brain injury without loss of consciousness, initial encounter',
  'S06300D Unspecified focal traumatic brain injury without loss of consciousness, subsequent encounter',
  'S06300S Unspecified focal traumatic brain injury without loss of consciousness, sequela',
  'S06301A Unspecified focal traumatic brain injury with loss of consciousness of 30 minutes or less, initial',
  'encounter',
  'S06301D Unspecified focal traumatic brain injury with loss of consciousness of 30 minutes or less,',
  'subsequent encounter',
  'S06301S Unspecified focal traumatic brain injury with loss of consciousness of 30 minutes or less,',
  'sequela',
  'S06302A Unspecified focal traumatic brain injury with loss of consciousness of 31 minutes to 59',
  'minutes, initial encounter',
  'S06302D Unspecified focal traumatic brain injury with loss of consciousness of 31 minutes to 59',
  'minutes, subsequent encounter',
  'S06302S Unspecified focal traumatic brain injury with loss of consciousness of 31 minutes to 59 minutes,',
  'sequela',
  'S06303A Unspecified focal traumatic brain injury with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, initial encounter',
  'S06303D Unspecified focal traumatic brain injury with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, subsequent encounter',
  'S06303S Unspecified focal traumatic brain injury with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, sequela',
  'S06304A Unspecified focal traumatic brain injury with loss of consciousness of 6 hours to 24 hours,',
  'initial encounter',
  'S06304D Unspecified focal traumatic brain injury with loss of consciousness of 6 hours to 24 hours,',
  'subsequent encounter',
  'S06304S Unspecified focal traumatic brain injury with loss of consciousness of 6 hours to 24 hours,',
  'sequela',
  'S06305A Unspecified focal traumatic brain injury with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, initial encounter',
  'S06305D Unspecified focal traumatic brain injury with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, subsequent encounter',
  'S06305S Unspecified focal traumatic brain injury with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, sequela',
  'S06306A Unspecified focal traumatic brain injury with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06306D Unspecified focal traumatic brain injury with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06306S Unspecified focal traumatic brain injury with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, sequela',
  'S06307A Unspecified focal traumatic brain injury with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, initial encounter',
  'S06307D Unspecified focal traumatic brain injury with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06307S Unspecified focal traumatic brain injury with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, sequela',
  'S06308A Unspecified focal traumatic brain injury with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, initial encounter',
  'S06308D Unspecified focal traumatic brain injury with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, subsequent encounter',
  'S06308S Unspecified focal traumatic brain injury with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, sequela',
  'S06309A Unspecified focal traumatic brain injury with loss of consciousness of unspecified duration,',
  'initial encounter',
  'S06309D Unspecified focal traumatic brain injury with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S06309S Unspecified focal traumatic brain injury with loss of consciousness of unspecified duration,',
  'sequela',
  'S06310A Contusion and laceration of right cerebrum without loss of consciousness, initial encounter',
  'S06310D Contusion and laceration of right cerebrum without loss of consciousness, subsequent',
  'encounter',
  'S06310S Contusion and laceration of right cerebrum without loss of consciousness, sequela',
  'S06311A Contusion and laceration of right cerebrum with loss of consciousness of 30 minutes or less,',
  'initial encounter',
  'S06311D Contusion and laceration of right cerebrum with loss of consciousness of 30 minutes or less,',
  'subsequent encounter',
  'S06311S Contusion and laceration of right cerebrum with loss of consciousness of 30 minutes or less,',
  'sequela',
  'S06312A Contusion and laceration of right cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, initial encounter',
  'S06312D Contusion and laceration of right cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, subsequent encounter',
  'S06312S Contusion and laceration of right cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, sequela',
  'S06313A Contusion and laceration of right cerebrum with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, initial encounter',
  'S06313D Contusion and laceration of right cerebrum with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, subsequent encounter',
  'S06313S Contusion and laceration of right cerebrum with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, sequela',
  'S06314A Contusion and laceration of right cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'initial encounter',
  'S06314D Contusion and laceration of right cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'subsequent encounter',
  'S06314S Contusion and laceration of right cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'sequela',
  'S06315A Contusion and laceration of right cerebrum with loss of consciousness greater than 24 hours',
  'with return to pre-existing conscious level, initial encounter',
  'S06315D Contusion and laceration of right cerebrum with loss of consciousness greater than 24 hours',
  'with return to pre-existing conscious level, subsequent encounter',
  'S06315S Contusion and laceration of right cerebrum with loss of consciousness greater than 24 hours',
  'with return to pre-existing conscious level, sequela',
  'S06316A Contusion and laceration of right cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06316D Contusion and laceration of right cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06316S Contusion and laceration of right cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, sequela',
  'S06317A Contusion and laceration of right cerebrum with loss of consciousness of any duration with',
  'death due to brain injury prior to regaining consciousness, initial encounter',
  'S06317D Contusion and laceration of right cerebrum with loss of consciousness of any duration with',
  'death due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06317S Contusion and laceration of right cerebrum with loss of consciousness of any duration with',
  'death due to brain injury prior to regaining consciousness, sequela',
  'S06318A Contusion and laceration of right cerebrum with loss of consciousness of any duration with',
  'death due to other cause prior to regaining consciousness, initial encounter',
  'S06318D Contusion and laceration of right cerebrum with loss of consciousness of any duration with',
  'death due to other cause prior to regaining consciousness, subsequent encounter',
  'S06318S Contusion and laceration of right cerebrum with loss of consciousness of any duration with',
  'death due to other cause prior to regaining consciousness, sequela',
  'S06319A Contusion and laceration of right cerebrum with loss of consciousness of unspecified duration,',
  'initial encounter',
  'S06319D Contusion and laceration of right cerebrum with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S06319S Contusion and laceration of right cerebrum with loss of consciousness of unspecified duration,',
  'sequela',
  'S06320A Contusion and laceration of left cerebrum without loss of consciousness, initial encounter',
  'S06320D Contusion and laceration of left cerebrum without loss of consciousness, subsequent',
  'encounter',
  'S06320S Contusion and laceration of left cerebrum without loss of consciousness, sequela',
  'S06321A Contusion and laceration of left cerebrum with loss of consciousness of 30 minutes or less,',
  'initial encounter',
  'S06321D Contusion and laceration of left cerebrum with loss of consciousness of 30 minutes or less,',
  'subsequent encounter',
  'S06321S Contusion and laceration of left cerebrum with loss of consciousness of 30 minutes or less,',
  'sequela',
  'S06322A Contusion and laceration of left cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, initial encounter',
  'S06322D Contusion and laceration of left cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, subsequent encounter',
  'S06322S Contusion and laceration of left cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, sequela',
  'S06323A Contusion and laceration of left cerebrum with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, initial encounter',
  'S06323D Contusion and laceration of left cerebrum with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, subsequent encounter',
  'S06323S Contusion and laceration of left cerebrum with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, sequela',
  'S06324A Contusion and laceration of left cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'initial encounter',
  'S06324D Contusion and laceration of left cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'subsequent encounter',
  'S06324S Contusion and laceration of left cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'sequela',
  'S06325A Contusion and laceration of left cerebrum with loss of consciousness greater than 24 hours',
  'with return to pre-existing conscious level, initial encounter',
  'S06325D Contusion and laceration of left cerebrum with loss of consciousness greater than 24 hours',
  'with return to pre-existing conscious level, subsequent encounter',
  'S06325S Contusion and laceration of left cerebrum with loss of consciousness greater than 24 hours',
  'with return to pre-existing conscious level, sequela',
  'S06326A Contusion and laceration of left cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06326D Contusion and laceration of left cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06326S Contusion and laceration of left cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, sequela',
  'S06327A Contusion and laceration of left cerebrum with loss of consciousness of any duration with',
  'death due to brain injury prior to regaining consciousness, initial encounter',
  'S06327D Contusion and laceration of left cerebrum with loss of consciousness of any duration with',
  'death due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06327S Contusion and laceration of left cerebrum with loss of consciousness of any duration with',
  'death due to brain injury prior to regaining consciousness, sequela',
  'S06328A Contusion and laceration of left cerebrum with loss of consciousness of any duration with',
  'death due to other cause prior to regaining consciousness, initial encounter',
  'S06328D Contusion and laceration of left cerebrum with loss of consciousness of any duration with',
  'death due to other cause prior to regaining consciousness, subsequent encounter',
  'S06328S Contusion and laceration of left cerebrum with loss of consciousness of any duration with',
  'death due to other cause prior to regaining consciousness, sequela',
  'S06329A Contusion and laceration of left cerebrum with loss of consciousness of unspecified duration,',
  'initial encounter',
  'S06329D Contusion and laceration of left cerebrum with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S06329S Contusion and laceration of left cerebrum with loss of consciousness of unspecified duration,',
  'sequela',
  'S06330A Contusion and laceration of cerebrum, unspecified, without loss of consciousness, initial',
  'encounter',
  'S06330D Contusion and laceration of cerebrum, unspecified, without loss of consciousness, subsequent',
  'encounter',
  'S06330S Contusion and laceration of cerebrum, unspecified, without loss of consciousness, sequela',
  'S06331A Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 30 minutes or',
  'less, initial encounter',
  'S06331D Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 30 minutes or',
  'less, subsequent encounter',
  'S06331S Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 30 minutes or',
  'less, sequela',
  'S06332A Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 31 minutes to',
  '59 minutes, initial encounter',
  'S06332D Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 31 minutes to',
  '59 minutes, subsequent encounter',
  'S06332S Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 31 minutes to',
  '59 minutes, sequela',
  'S06333A Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, initial encounter',
  'S06333D Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, subsequent encounter',
  'S06333S Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, sequela',
  'S06334A Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 6 hours to 24',
  'hours, initial encounter',
  'S06334D Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 6 hours to 24',
  'hours, subsequent encounter',
  'S06334S Contusion and laceration of cerebrum, unspecified, with loss of consciousness of 6 hours to 24',
  'hours, sequela',
  'S06335A Contusion and laceration of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, initial encounter',
  'S06335D Contusion and laceration of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, subsequent encounter',
  'S06335S Contusion and laceration of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, sequela',
  'S06336A Contusion and laceration of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06336D Contusion and laceration of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06336S Contusion and laceration of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, sequela',
  'S06337A Contusion and laceration of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, initial encounter',
  'S06337D Contusion and laceration of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06337S Contusion and laceration of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, sequela',
  'S06338A Contusion and laceration of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, initial encounter',
  'S06338D Contusion and laceration of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, subsequent encounter',
  'S06338S Contusion and laceration of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, sequela',
  'S06339A Contusion and laceration of cerebrum, unspecified, with loss of consciousness of unspecified',
  'duration, initial encounter',
  'S06339D Contusion and laceration of cerebrum, unspecified, with loss of consciousness of unspecified',
  'duration, subsequent encounter',
  'S06339S Contusion and laceration of cerebrum, unspecified, with loss of consciousness of unspecified',
  'duration, sequela',
  'S06340A Traumatic hemorrhage of right cerebrum without loss of consciousness, initial encounter',
  'S06340D Traumatic hemorrhage of right cerebrum without loss of consciousness, subsequent encounter',
  'S06340S Traumatic hemorrhage of right cerebrum without loss of consciousness, sequela',
  'S06341A Traumatic hemorrhage of right cerebrum with loss of consciousness of 30 minutes or less,',
  'initial encounter',
  'S06341D Traumatic hemorrhage of right cerebrum with loss of consciousness of 30 minutes or less,',
  'subsequent encounter',
  'S06341S Traumatic hemorrhage of right cerebrum with loss of consciousness of 30 minutes or less,',
  'sequela',
  'S06342A Traumatic hemorrhage of right cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, initial encounter',
  'S06342D Traumatic hemorrhage of right cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, subsequent encounter',
  'S06342S Traumatic hemorrhage of right cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, sequela',
  'S06343A Traumatic hemorrhage of right cerebrum with loss of consciousness of 1 hours to 5 hours 59',
  'minutes, initial encounter',
  'S06343D Traumatic hemorrhage of right cerebrum with loss of consciousness of 1 hours to 5 hours 59',
  'minutes, subsequent encounter',
  'S06343S Traumatic hemorrhage of right cerebrum with loss of consciousness of 1 hours to 5 hours 59',
  'minutes, sequela',
  'S06344A Traumatic hemorrhage of right cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'initial encounter',
  'S06344D Traumatic hemorrhage of right cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'subsequent encounter',
  'S06344S Traumatic hemorrhage of right cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'sequela',
  'S06345A Traumatic hemorrhage of right cerebrum with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, initial encounter',
  'S06345D Traumatic hemorrhage of right cerebrum with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, subsequent encounter',
  'S06345S Traumatic hemorrhage of right cerebrum with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, sequela',
  'S06346A Traumatic hemorrhage of right cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06346D Traumatic hemorrhage of right cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06346S Traumatic hemorrhage of right cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, sequela',
  'S06347A Traumatic hemorrhage of right cerebrum with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, initial encounter',
  'S06347D Traumatic hemorrhage of right cerebrum with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06347S Traumatic hemorrhage of right cerebrum with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, sequela',
  'S06348A Traumatic hemorrhage of right cerebrum with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, initial encounter',
  'S06348D Traumatic hemorrhage of right cerebrum with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, subsequent encounter',
  'S06348S Traumatic hemorrhage of right cerebrum with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, sequela',
  'S06349A Traumatic hemorrhage of right cerebrum with loss of consciousness of unspecified duration,',
  'initial encounter',
  'S06349D Traumatic hemorrhage of right cerebrum with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S06349S Traumatic hemorrhage of right cerebrum with loss of consciousness of unspecified duration,',
  'sequela',
  'S06350A Traumatic hemorrhage of left cerebrum without loss of consciousness, initial encounter',
  'S06350D Traumatic hemorrhage of left cerebrum without loss of consciousness, subsequent encounter',
  'S06350S Traumatic hemorrhage of left cerebrum without loss of consciousness, sequela',
  'S06351A Traumatic hemorrhage of left cerebrum with loss of consciousness of 30 minutes or less, initial',
  'encounter',
  'S06351D Traumatic hemorrhage of left cerebrum with loss of consciousness of 30 minutes or less,',
  'subsequent encounter',
  'S06351S Traumatic hemorrhage of left cerebrum with loss of consciousness of 30 minutes or less,',
  'sequela',
  'S06352A Traumatic hemorrhage of left cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, initial encounter',
  'S06352D Traumatic hemorrhage of left cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, subsequent encounter',
  'S06352S Traumatic hemorrhage of left cerebrum with loss of consciousness of 31 minutes to 59',
  'minutes, sequela',
  'S06353A Traumatic hemorrhage of left cerebrum with loss of consciousness of 1 hours to 5 hours 59',
  'minutes, initial encounter',
  'S06353D Traumatic hemorrhage of left cerebrum with loss of consciousness of 1 hours to 5 hours 59',
  'minutes, subsequent encounter',
  'S06353S Traumatic hemorrhage of left cerebrum with loss of consciousness of 1 hours to 5 hours 59',
  'minutes, sequela',
  'S06354A Traumatic hemorrhage of left cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'initial encounter',
  'S06354D Traumatic hemorrhage of left cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'subsequent encounter',
  'S06354S Traumatic hemorrhage of left cerebrum with loss of consciousness of 6 hours to 24 hours,',
  'sequela',
  'S06355A Traumatic hemorrhage of left cerebrum with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, initial encounter',
  'S06355D Traumatic hemorrhage of left cerebrum with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, subsequent encounter',
  'S06355S Traumatic hemorrhage of left cerebrum with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, sequela',
  'S06356A Traumatic hemorrhage of left cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06356D Traumatic hemorrhage of left cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06356S Traumatic hemorrhage of left cerebrum with loss of consciousness greater than 24 hours',
  'without return to pre-existing conscious level with patient surviving, sequela',
  'S06357A Traumatic hemorrhage of left cerebrum with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, initial encounter',
  'S06357D Traumatic hemorrhage of left cerebrum with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06357S Traumatic hemorrhage of left cerebrum with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, sequela',
  'S06358A Traumatic hemorrhage of left cerebrum with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, initial encounter',
  'S06358D Traumatic hemorrhage of left cerebrum with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, subsequent encounter',
  'S06358S Traumatic hemorrhage of left cerebrum with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, sequela',
  'S06359A Traumatic hemorrhage of left cerebrum with loss of consciousness of unspecified duration,',
  'initial encounter',
  'S06359D Traumatic hemorrhage of left cerebrum with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S06359S Traumatic hemorrhage of left cerebrum with loss of consciousness of unspecified duration,',
  'sequela',
  'S06360A Traumatic hemorrhage of cerebrum, unspecified, without loss of consciousness, initial',
  'encounter',
  'S06360D Traumatic hemorrhage of cerebrum, unspecified, without loss of consciousness, subsequent',
  'encounter',
  'S06360S Traumatic hemorrhage of cerebrum, unspecified, without loss of consciousness, sequela',
  'S06361A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 30 minutes or',
  'less, initial encounter',
  'S06361D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 30 minutes or',
  'less, subsequent encounter',
  'S06361S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 30 minutes or',
  'less, sequela',
  'S06362A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 31 minutes to',
  '59 minutes, initial encounter',
  'S06362D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 31 minutes to',
  '59 minutes, subsequent encounter',
  'S06362S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 31 minutes to',
  '59 minutes, sequela',
  'S06363A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 1 hours to 5',
  'hours 59 minutes, initial encounter',
  'S06363D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 1 hours to 5',
  'hours 59 minutes, subsequent encounter',
  'S06363S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 1 hours to 5',
  'hours 59 minutes, sequela',
  'S06364A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 6 hours to 24',
  'hours, initial encounter',
  'S06364D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 6 hours to 24',
  'hours, subsequent encounter',
  'S06364S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of 6 hours to 24',
  'hours, sequela',
  'S06365A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, initial encounter',
  'S06365D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, subsequent encounter',
  'S06365S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, sequela',
  'S06366A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06366D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06366S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, sequela',
  'S06367A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, initial encounter',
  'S06367D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06367S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, sequela',
  'S06368A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, initial encounter',
  'S06368D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, subsequent encounter',
  'S06368S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, sequela',
  'S06369A Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of unspecified',
  'duration, initial encounter',
  'S06369D Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of unspecified',
  'duration, subsequent encounter',
  'S06369S Traumatic hemorrhage of cerebrum, unspecified, with loss of consciousness of unspecified',
  'duration, sequela',
  'S06370A Contusion, laceration, and hemorrhage of cerebellum without loss of consciousness, initial',
  'encounter',
  'S06370D Contusion, laceration, and hemorrhage of cerebellum without loss of consciousness,',
  'subsequent encounter',
  'S06370S Contusion, laceration, and hemorrhage of cerebellum without loss of consciousness, sequela',
  'S06371A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 30 minutes',
  'or less, initial encounter',
  'S06371D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 30 minutes',
  'or less, subsequent encounter',
  'S06371S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 30 minutes',
  'or less, sequela',
  'S06372A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 31 minutes',
  'to 59 minutes, initial encounter',
  'S06372D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 31 minutes',
  'to 59 minutes, subsequent encounter',
  'S06372S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 31 minutes',
  'to 59 minutes, sequela',
  'S06373A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, initial encounter',
  'S06373D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, subsequent encounter',
  'S06373S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, sequela',
  'S06374A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 6 hours to',
  '24 hours, initial encounter',
  'S06374D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 6 hours to',
  '24 hours, subsequent encounter',
  'S06374S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of 6 hours to',
  '24 hours, sequela',
  'S06375A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness greater than',
  '24 hours with return to pre-existing conscious level, initial encounter',
  'S06375D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness greater than',
  '24 hours with return to pre-existing conscious level, subsequent encounter',
  'S06375S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness greater than',
  '24 hours with return to pre-existing conscious level, sequela',
  'S06376A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness greater than',
  '24 hours without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06376D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness greater than',
  '24 hours without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06376S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness greater than',
  '24 hours without return to pre-existing conscious level with patient surviving, sequela',
  'S06377A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of any',
  'duration with death due to brain injury prior to regaining consciousness, initial encounter',
  'S06377D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of any',
  'duration with death due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06377S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of any',
  'duration with death due to brain injury prior to regaining consciousness, sequela',
  'S06378A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of any',
  'duration with death due to other cause prior to regaining consciousness, initial encounter',
  'S06378D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of any',
  'duration with death due to other cause prior to regaining consciousness, subsequent encounter',
  'S06378S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of any',
  'duration with death due to other cause prior to regaining consciousness, sequela',
  'S06379A Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of unspecified',
  'duration, initial encounter',
  'S06379D Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of unspecified',
  'duration, subsequent encounter',
  'S06379S Contusion, laceration, and hemorrhage of cerebellum with loss of consciousness of unspecified',
  'duration, sequela',
  'S06380A Contusion, laceration, and hemorrhage of brainstem without loss of consciousness, initial',
  'encounter',
  'S06380D Contusion, laceration, and hemorrhage of brainstem without loss of consciousness,',
  'subsequent encounter',
  'S06380S Contusion, laceration, and hemorrhage of brainstem without loss of consciousness, sequela',
  'S06381A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 30 minutes',
  'or less, initial encounter',
  'S06381D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 30 minutes',
  'or less, subsequent encounter',
  'S06381S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 30 minutes',
  'or less, sequela',
  'S06382A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 31 minutes',
  'to 59 minutes, initial encounter',
  'S06382D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 31 minutes',
  'to 59 minutes, subsequent encounter',
  'S06382S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 31 minutes',
  'to 59 minutes, sequela',
  'S06383A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, initial encounter',
  'S06383D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, subsequent encounter',
  'S06383S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 1 hour to 5',
  'hours 59 minutes, sequela',
  'S06384A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 6 hours to',
  '24 hours, initial encounter',
  'S06384D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 6 hours to',
  '24 hours, subsequent encounter',
  'S06384S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of 6 hours to 24',
  'hours, sequela',
  'S06385A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, initial encounter',
  'S06385D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness greater than',
  '24 hours with return to pre-existing conscious level, subsequent encounter',
  'S06385S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness greater than 24',
  'hours with return to pre-existing conscious level, sequela',
  'S06386A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, initial encounter',
  'S06386D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness greater than',
  '24 hours without return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06386S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness greater than 24',
  'hours without return to pre-existing conscious level with patient surviving, sequela',
  'S06387A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, initial encounter',
  'S06387D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, subsequent encounter',
  'S06387S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of any duration',
  'with death due to brain injury prior to regaining consciousness, sequela',
  'S06388A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, initial encounter',
  'S06388D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, subsequent encounter',
  'S06388S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of any duration',
  'with death due to other cause prior to regaining consciousness, sequela',
  'S06389A Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of unspecified',
  'duration, initial encounter',
  'S06389D Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of unspecified',
  'duration, subsequent encounter',
  'S06389S Contusion, laceration, and hemorrhage of brainstem with loss of consciousness of unspecified',
  'duration, sequela',
  'S064X0A Epidural hemorrhage without loss of consciousness, initial encounter',
  'S064X0D Epidural hemorrhage without loss of consciousness, subsequent encounter',
  'S064X0S Epidural hemorrhage without loss of consciousness, sequela',
  'S064X1A Epidural hemorrhage with loss of consciousness of 30 minutes or less, initial encounter',
  'S064X1D Epidural hemorrhage with loss of consciousness of 30 minutes or less, subsequent encounter',
  'S064X1S Epidural hemorrhage with loss of consciousness of 30 minutes or less, sequela',
  'S064X2A Epidural hemorrhage with loss of consciousness of 31 minutes to 59 minutes, initial encounter',
  'S064X2D Epidural hemorrhage with loss of consciousness of 31 minutes to 59 minutes, subsequent',
  'encounter',
  'S064X2S Epidural hemorrhage with loss of consciousness of 31 minutes to 59 minutes, sequela',
  'S064X3A Epidural hemorrhage with loss of consciousness of 1 hour to 5 hours 59 minutes, initial',
  'encounter',
  'S064X3D Epidural hemorrhage with loss of consciousness of 1 hour to 5 hours 59 minutes, subsequent',
  'encounter',
  'S064X3S Epidural hemorrhage with loss of consciousness of 1 hour to 5 hours 59 minutes, sequela',
  'S064X4A Epidural hemorrhage with loss of consciousness of 6 hours to 24 hours, initial encounter',
  'S064X4D Epidural hemorrhage with loss of consciousness of 6 hours to 24 hours, subsequent encounter',
  'S064X4S Epidural hemorrhage with loss of consciousness of 6 hours to 24 hours, sequela',
  'S064X5A Epidural hemorrhage with loss of consciousness greater than 24 hours with return to preexisting conscious level, initial encounter',
  'S064X5D Epidural hemorrhage with loss of consciousness greater than 24 hours with return to preexisting conscious level, subsequent encounter',
  'S064X5S Epidural hemorrhage with loss of consciousness greater than 24 hours with return to preexisting conscious level, sequela',
  'S064X6A Epidural hemorrhage with loss of consciousness greater than 24 hours without return to preexisting conscious level with patient surviving, initial encounter',
  'S064X6D Epidural hemorrhage with loss of consciousness greater than 24 hours without return to preexisting conscious level with patient surviving, subsequent encounter',
  'S064X6S Epidural hemorrhage with loss of consciousness greater than 24 hours without return to preexisting conscious level with patient surviving, sequela',
  'S064X7A Epidural hemorrhage with loss of consciousness of any duration with death due to brain injury',
  'prior to regaining consciousness, initial encounter',
  'S064X7D Epidural hemorrhage with loss of consciousness of any duration with death due to brain injury',
  'prior to regaining consciousness, subsequent encounter',
  'S064X7S Epidural hemorrhage with loss of consciousness of any duration with death due to brain injury',
  'prior to regaining consciousness, sequela',
  'S064X8A Epidural hemorrhage with loss of consciousness of any duration with death due to other',
  'causes prior to regaining consciousness, initial encounter',
  'S064X8D Epidural hemorrhage with loss of consciousness of any duration with death due to other',
  'causes prior to regaining consciousness, subsequent encounter',
  'S064X8S Epidural hemorrhage with loss of consciousness of any duration with death due to other causes',
  'prior to regaining consciousness, sequela',
  'S064X9A Epidural hemorrhage with loss of consciousness of unspecified duration, initial encounter',
  'S064X9D Epidural hemorrhage with loss of consciousness of unspecified duration, subsequent',
  'encounter',
  'S064X9S Epidural hemorrhage with loss of consciousness of unspecified duration, sequela',
  'S065X0A Traumatic subdural hemorrhage without loss of consciousness, initial encounter',
  'S065X0D Traumatic subdural hemorrhage without loss of consciousness, subsequent encounter',
  'S065X0S Traumatic subdural hemorrhage without loss of consciousness, sequela',
  'S065X1A Traumatic subdural hemorrhage with loss of consciousness of 30 minutes or less, initial',
  'encounter',
  'S065X1D Traumatic subdural hemorrhage with loss of consciousness of 30 minutes or less, subsequent',
  'encounter',
  'S065X1S Traumatic subdural hemorrhage with loss of consciousness of 30 minutes or less, sequela',
  'S065X2A Traumatic subdural hemorrhage with loss of consciousness of 31 minutes to 59 minutes, initial',
  'encounter',
  'S065X2D Traumatic subdural hemorrhage with loss of consciousness of 31 minutes to 59 minutes,',
  'subsequent encounter',
  'S065X2S Traumatic subdural hemorrhage with loss of consciousness of 31 minutes to 59 minutes,',
  'sequela',
  'S065X3A Traumatic subdural hemorrhage with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'initial encounter',
  'S065X3D Traumatic subdural hemorrhage with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'subsequent encounter',
  'S065X3S Traumatic subdural hemorrhage with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'sequela',
  'S065X4A Traumatic subdural hemorrhage with loss of consciousness of 6 hours to 24 hours, initial',
  'encounter',
  'S065X4D Traumatic subdural hemorrhage with loss of consciousness of 6 hours to 24 hours, subsequent',
  'encounter',
  'S065X4S Traumatic subdural hemorrhage with loss of consciousness of 6 hours to 24 hours, sequela',
  'S065X5A Traumatic subdural hemorrhage with loss of consciousness greater than 24 hours with return',
  'to pre-existing conscious level, initial encounter',
  'S065X5D Traumatic subdural hemorrhage with loss of consciousness greater than 24 hours with return',
  'to pre-existing conscious level, subsequent encounter',
  'S065X5S Traumatic subdural hemorrhage with loss of consciousness greater than 24 hours with return',
  'to pre-existing conscious level, sequela',
  'S065X6A Traumatic subdural hemorrhage with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, initial encounter',
  'S065X6D Traumatic subdural hemorrhage with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S065X6S Traumatic subdural hemorrhage with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, sequela',
  'S065X7A Traumatic subdural hemorrhage with loss of consciousness of any duration with death due to',
  'brain injury before regaining consciousness, initial encounter',
  'S065X7D Traumatic subdural hemorrhage with loss of consciousness of any duration with death due to',
  'brain injury before regaining consciousness, subsequent encounter',
  'S065X7S Traumatic subdural hemorrhage with loss of consciousness of any duration with death due to',
  'brain injury before regaining consciousness, sequela',
  'S065X8A Traumatic subdural hemorrhage with loss of consciousness of any duration with death due to',
  'other cause before regaining consciousness, initial encounter',
  'S065X8D Traumatic subdural hemorrhage with loss of consciousness of any duration with death due to',
  'other cause before regaining consciousness, subsequent encounter',
  'S065X8S Traumatic subdural hemorrhage with loss of consciousness of any duration with death due to',
  'other cause before regaining consciousness, sequela',
  'S065X9A Traumatic subdural hemorrhage with loss of consciousness of unspecified duration, initial',
  'encounter',
  'S065X9D Traumatic subdural hemorrhage with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S065X9S Traumatic subdural hemorrhage with loss of consciousness of unspecified duration, sequela',
  'S066X0A Traumatic subarachnoid hemorrhage without loss of consciousness, initial encounter',
  'S066X0D Traumatic subarachnoid hemorrhage without loss of consciousness, subsequent encounter',
  'S066X0S Traumatic subarachnoid hemorrhage without loss of consciousness, sequela',
  'S066X1A Traumatic subarachnoid hemorrhage with loss of consciousness of 30 minutes or less, initial',
  'encounter',
  'S066X1D Traumatic subarachnoid hemorrhage with loss of consciousness of 30 minutes or less,',
  'subsequent encounter',
  'S066X1S Traumatic subarachnoid hemorrhage with loss of consciousness of 30 minutes or less, sequela',
  'S066X2A Traumatic subarachnoid hemorrhage with loss of consciousness of 31 minutes to 59 minutes,',
  'initial encounter',
  'S066X2D Traumatic subarachnoid hemorrhage with loss of consciousness of 31 minutes to 59 minutes,',
  'subsequent encounter',
  'S066X2S Traumatic subarachnoid hemorrhage with loss of consciousness of 31 minutes to 59 minutes,',
  'sequela',
  'S066X3A Traumatic subarachnoid hemorrhage with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, initial encounter',
  'S066X3D Traumatic subarachnoid hemorrhage with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, subsequent encounter',
  'S066X3S Traumatic subarachnoid hemorrhage with loss of consciousness of 1 hour to 5 hours 59',
  'minutes, sequela',
  'S066X4A Traumatic subarachnoid hemorrhage with loss of consciousness of 6 hours to 24 hours, initial',
  'encounter',
  'S066X4D Traumatic subarachnoid hemorrhage with loss of consciousness of 6 hours to 24 hours,',
  'subsequent encounter',
  'S066X4S Traumatic subarachnoid hemorrhage with loss of consciousness of 6 hours to 24 hours, sequela',
  'S066X5A Traumatic subarachnoid hemorrhage with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, initial encounter',
  'S066X5D Traumatic subarachnoid hemorrhage with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, subsequent encounter',
  'S066X5S Traumatic subarachnoid hemorrhage with loss of consciousness greater than 24 hours with',
  'return to pre-existing conscious level, sequela',
  'S066X6A Traumatic subarachnoid hemorrhage with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, initial encounter',
  'S066X6D Traumatic subarachnoid hemorrhage with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S066X6S Traumatic subarachnoid hemorrhage with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, sequela',
  'S066X7A Traumatic subarachnoid hemorrhage with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, initial encounter',
  'S066X7D Traumatic subarachnoid hemorrhage with loss of consciousness of any duration with death',
  'due to brain injury prior to regaining consciousness, subsequent encounter',
  'S066X7S Traumatic subarachnoid hemorrhage with loss of consciousness of any duration with death due',
  'to brain injury prior to regaining consciousness, sequela',
  'S066X8A Traumatic subarachnoid hemorrhage with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, initial encounter',
  'S066X8D Traumatic subarachnoid hemorrhage with loss of consciousness of any duration with death',
  'due to other cause prior to regaining consciousness, subsequent encounter',
  'S066X8S Traumatic subarachnoid hemorrhage with loss of consciousness of any duration with death due',
  'to other cause prior to regaining consciousness, sequela',
  'S066X9A Traumatic subarachnoid hemorrhage with loss of consciousness of unspecified duration, initial',
  'encounter',
  'S066X9D Traumatic subarachnoid hemorrhage with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S066X9S Traumatic subarachnoid hemorrhage with loss of consciousness of unspecified duration,',
  'sequela',
  'S06810A Injury of right internal carotid artery, intracranial portion, not elsewhere classified without loss',
  'of consciousness, initial encounter',
  'S06810D Injury of right internal carotid artery, intracranial portion, not elsewhere classified without loss',
  'of consciousness, subsequent encounter',
  'S06810S Injury of right internal carotid artery, intracranial portion, not elsewhere classified without loss',
  'of consciousness, sequela',
  'S06811A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 30 minutes or less, initial encounter',
  'S06811D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 30 minutes or less, subsequent encounter',
  'S06811S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 30 minutes or less, sequela',
  'S06812A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 31 minutes to 59 minutes, initial encounter',
  'S06812D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 31 minutes to 59 minutes, subsequent encounter',
  'S06812S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 31 minutes to 59 minutes, sequela',
  'S06813A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 1 hour to 5 hours 59 minutes, initial encounter',
  'S06813D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 1 hour to 5 hours 59 minutes, subsequent encounter',
  'S06813S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 1 hour to 5 hours 59 minutes, sequela',
  'S06814A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 6 hours to 24 hours, initial encounter',
  'S06814D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 6 hours to 24 hours, subsequent encounter',
  'S06814S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 6 hours to 24 hours, sequela',
  'S06815A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours with return to pre-existing conscious level, initial encounter',
  'S06815D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours with return to pre-existing conscious level, subsequent encounter',
  'S06815S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours with return to pre-existing conscious level, sequela',
  'S06816A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours without return to pre-existing conscious level with patient',
  'surviving, initial encounter',
  'S06816D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours without return to pre-existing conscious level with patient',
  'surviving, subsequent encounter',
  'S06816S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours without return to pre-existing conscious level with patient',
  'surviving, sequela',
  'S06817A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to brain injury prior to regaining consciousness, initial',
  'encounter',
  'S06817D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to brain injury prior to regaining consciousness,',
  'subsequent encounter',
  'S06817S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to brain injury prior to regaining consciousness, sequela',
  'S06818A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to other cause prior to regaining consciousness, initial',
  'encounter',
  'S06818D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to other cause prior to regaining consciousness,',
  'subsequent encounter',
  'S06818S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to other cause prior to regaining consciousness, sequela',
  'S06819A Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of unspecified duration, initial encounter',
  'S06819D Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of unspecified duration, subsequent encounter',
  'S06819S Injury of right internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of unspecified duration, sequela',
  'S06820A Injury of left internal carotid artery, intracranial portion, not elsewhere classified without loss',
  'of consciousness, initial encounter',
  'S06820D Injury of left internal carotid artery, intracranial portion, not elsewhere classified without loss',
  'of consciousness, subsequent encounter',
  'S06820S Injury of left internal carotid artery, intracranial portion, not elsewhere classified without loss',
  'of consciousness, sequela',
  'S06821A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 30 minutes or less, initial encounter',
  'S06821D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 30 minutes or less, subsequent encounter',
  'S06821S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 30 minutes or less, sequela',
  'S06822A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 31 minutes to 59 minutes, initial encounter',
  'S06822D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 31 minutes to 59 minutes, subsequent encounter',
  'S06822S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 31 minutes to 59 minutes, sequela',
  'S06823A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 1 hour to 5 hours 59 minutes, initial encounter',
  'S06823D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 1 hour to 5 hours 59 minutes, subsequent encounter',
  'S06823S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 1 hour to 5 hours 59 minutes, sequela',
  'S06824A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 6 hours to 24 hours, initial encounter',
  'S06824D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 6 hours to 24 hours, subsequent encounter',
  'S06824S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of 6 hours to 24 hours, sequela',
  'S06825A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours with return to pre-existing conscious level, initial encounter',
  'S06825D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours with return to pre-existing conscious level, subsequent encounter',
  'S06825S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours with return to pre-existing conscious level, sequela',
  'S06826A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours without return to pre-existing conscious level with patient',
  'surviving, initial encounter',
  'S06826D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours without return to pre-existing conscious level with patient',
  'surviving, subsequent encounter',
  'S06826S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness greater than 24 hours without return to pre-existing conscious level with patient',
  'surviving, sequela',
  'S06827A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to brain injury prior to regaining consciousness, initial',
  'encounter',
  'S06827D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to brain injury prior to regaining consciousness,',
  'subsequent encounter',
  'S06827S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to brain injury prior to regaining consciousness, sequela',
  'S06828A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to other cause prior to regaining consciousness, initial',
  'encounter',
  'S06828D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to other cause prior to regaining consciousness,',
  'subsequent encounter',
  'S06828S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of any duration with death due to other cause prior to regaining consciousness, sequela',
  'S06829A Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of unspecified duration, initial encounter',
  'S06829D Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of unspecified duration, subsequent encounter',
  'S06829S Injury of left internal carotid artery, intracranial portion, not elsewhere classified with loss of',
  'consciousness of unspecified duration, sequela',
  'S06890A Other specified intracranial injury without loss of consciousness, initial encounter',
  'S06890D Other specified intracranial injury without loss of consciousness, subsequent encounter',
  'S06890S Other specified intracranial injury without loss of consciousness, sequela',
  'S06891A Other specified intracranial injury with loss of consciousness of 30 minutes or less, initial',
  'encounter',
  'S06891D Other specified intracranial injury with loss of consciousness of 30 minutes or less, subsequent',
  'encounter',
  'S06891S Other specified intracranial injury with loss of consciousness of 30 minutes or less, sequela',
  'S06892A Other specified intracranial injury with loss of consciousness of 31 minutes to 59 minutes,',
  'initial encounter',
  'S06892D Other specified intracranial injury with loss of consciousness of 31 minutes to 59 minutes,',
  'subsequent encounter',
  'S06892S Other specified intracranial injury with loss of consciousness of 31 minutes to 59 minutes,',
  'sequela',
  'S06893A Other specified intracranial injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'initial encounter',
  'S06893D Other specified intracranial injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'subsequent encounter',
  'S06893S Other specified intracranial injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'sequela',
  'S06894A Other specified intracranial injury with loss of consciousness of 6 hours to 24 hours, initial',
  'encounter',
  'S06894D Other specified intracranial injury with loss of consciousness of 6 hours to 24 hours,',
  'subsequent encounter',
  'S06894S Other specified intracranial injury with loss of consciousness of 6 hours to 24 hours, sequela',
  'S06895A Other specified intracranial injury with loss of consciousness greater than 24 hours with return',
  'to pre-existing conscious level, initial encounter',
  'S06895D Other specified intracranial injury with loss of consciousness greater than 24 hours with return',
  'to pre-existing conscious level, subsequent encounter',
  'S06895S Other specified intracranial injury with loss of consciousness greater than 24 hours with return',
  'to pre-existing conscious level, sequela',
  'S06896A Other specified intracranial injury with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, initial encounter',
  'S06896D Other specified intracranial injury with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, subsequent encounter',
  'S06896S Other specified intracranial injury with loss of consciousness greater than 24 hours without',
  'return to pre-existing conscious level with patient surviving, sequela',
  'S06897A Other specified intracranial injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, initial encounter',
  'S06897D Other specified intracranial injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, subsequent encounter',
  'S06897S Other specified intracranial injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, sequela',
  'S06898A Other specified intracranial injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, initial encounter',
  'S06898D Other specified intracranial injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, subsequent encounter',
  'S06898S Other specified intracranial injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, sequela',
  'S06899A Other specified intracranial injury with loss of consciousness of unspecified duration, initial',
  'encounter',
  'S06899D Other specified intracranial injury with loss of consciousness of unspecified duration,',
  'subsequent encounter',
  'S06899S Other specified intracranial injury with loss of consciousness of unspecified duration, sequela',
  'S069X0A Unspecified intracranial injury without loss of consciousness, initial encounter',
  'S069X0D Unspecified intracranial injury without loss of consciousness, subsequent encounter',
  'S069X0S Unspecified intracranial injury without loss of consciousness, sequela',
  'S069X1A Unspecified intracranial injury with loss of consciousness of 30 minutes or less, initial',
  'encounter',
  'S069X1D Unspecified intracranial injury with loss of consciousness of 30 minutes or less, subsequent',
  'encounter',
  'S069X1S Unspecified intracranial injury with loss of consciousness of 30 minutes or less, sequela',
  'S069X2A Unspecified intracranial injury with loss of consciousness of 31 minutes to 59 minutes, initial',
  'encounter',
  'S069X2D Unspecified intracranial injury with loss of consciousness of 31 minutes to 59 minutes,',
  'subsequent encounter',
  'S069X2S Unspecified intracranial injury with loss of consciousness of 31 minutes to 59 minutes, sequela',
  'S069X3A Unspecified intracranial injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'initial encounter',
  'S069X3D Unspecified intracranial injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'subsequent encounter',
  'S069X3S Unspecified intracranial injury with loss of consciousness of 1 hour to 5 hours 59 minutes,',
  'sequela',
  'S069X4A Unspecified intracranial injury with loss of consciousness of 6 hours to 24 hours, initial',
  'encounter',
  'S069X4D Unspecified intracranial injury with loss of consciousness of 6 hours to 24 hours, subsequent',
  'encounter',
  'S069X4S Unspecified intracranial injury with loss of consciousness of 6 hours to 24 hours, sequela',
  'S069X5A Unspecified intracranial injury with loss of consciousness greater than 24 hours with return to',
  'pre-existing conscious level, initial encounter',
  'S069X5D Unspecified intracranial injury with loss of consciousness greater than 24 hours with return to',
  'pre-existing conscious level, subsequent encounter',
  'S069X5S Unspecified intracranial injury with loss of consciousness greater than 24 hours with return to',
  'pre-existing conscious level, sequela',
  'S069X6A Unspecified intracranial injury with loss of consciousness greater than 24 hours without return',
  'to pre-existing conscious level with patient surviving, initial encounter',
  'S069X6D Unspecified intracranial injury with loss of consciousness greater than 24 hours without return',
  'to pre-existing conscious level with patient surviving, subsequent encounter',
  'S069X6S Unspecified intracranial injury with loss of consciousness greater than 24 hours without return',
  'to pre-existing conscious level with patient surviving, sequela',
  'S069X7A Unspecified intracranial injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, initial encounter',
  'S069X7D Unspecified intracranial injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, subsequent encounter',
  'S069X7S Unspecified intracranial injury with loss of consciousness of any duration with death due to',
  'brain injury prior to regaining consciousness, sequela',
  'S069X8A Unspecified intracranial injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, initial encounter',
  'S069X8D Unspecified intracranial injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, subsequent encounter',
  'S069X8S Unspecified intracranial injury with loss of consciousness of any duration with death due to',
  'other cause prior to regaining consciousness, sequela',
  'S069X9A Unspecified intracranial injury with loss of consciousness of unspecified duration, initial',
  'encounter',
  'S069X9D Unspecified intracranial injury with loss of consciousness of unspecified duration, subsequent',
  'encounter',
  'S069X9S Unspecified intracranial injury with loss of consciousness of unspecified duration, sequela',
  'S070XXA Crushing injury of face, initial encounter',
  'S070XXD Crushing injury of face, subsequent encounter',
  'S070XXS Crushing injury of face, sequela',
  'S071XXA Crushing injury of skull, initial encounter',
  'S071XXD Crushing injury of skull, subsequent encounter',
  'S071XXS Crushing injury of skull, sequela',
  'S078XXA Crushing injury of other parts of head, initial encounter',
  'S078XXD Crushing injury of other parts of head, subsequent encounter',
  'S078XXS Crushing injury of other parts of head, sequela',
  'S079XXA Crushing injury of head, part unspecified, initial encounter',
  'S079XXD Crushing injury of head, part unspecified, subsequent encounter',
  'S079XXS Crushing injury of head, part unspecified, sequela',
  'S080XXA Avulsion of scalp, initial encounter',
  'S080XXD Avulsion of scalp, subsequent encounter',
  'S080XXS Avulsion of scalp, sequela',
  'S08111A Complete traumatic amputation of right ear, initial encounter',
  'S08111D Complete traumatic amputation of right ear, subsequent encounter',
  'S08111S Complete traumatic amputation of right ear, sequela',
  'S08112A Complete traumatic amputation of left ear, initial encounter',
  'S08112D Complete traumatic amputation of left ear, subsequent encounter',
  'S08112S Complete traumatic amputation of left ear, sequela',
  'S08119A Complete traumatic amputation of unspecified ear, initial encounter',
  'S08119D Complete traumatic amputation of unspecified ear, subsequent encounter',
  'S08119S Complete traumatic amputation of unspecified ear, sequela',
  'S08121A Partial traumatic amputation of right ear, initial encounter',
  'S08121D Partial traumatic amputation of right ear, subsequent encounter',
  'S08121S Partial traumatic amputation of right ear, sequela',
  'S08122A Partial traumatic amputation of left ear, initial encounter',
  'S08122D Partial traumatic amputation of left ear, subsequent encounter',
  'S08122S Partial traumatic amputation of left ear, sequela',
  'S08129A Partial traumatic amputation of unspecified ear, initial encounter',
  'S08129D Partial traumatic amputation of unspecified ear, subsequent encounter',
  'S08129S Partial traumatic amputation of unspecified ear, sequela',
  'S08811A Complete traumatic amputation of nose, initial encounter',
  'S08811D Complete traumatic amputation of nose, subsequent encounter',
  'S08811S Complete traumatic amputation of nose, sequela',
  'S08812A Partial traumatic amputation of nose, initial encounter',
  'S08812D Partial traumatic amputation of nose, subsequent encounter',
  'S08812S Partial traumatic amputation of nose, sequela',
  'S0889XA Traumatic amputation of other parts of head, initial encounter',
  'S0889XD Traumatic amputation of other parts of head, subsequent encounter',
  'S0889XS Traumatic amputation of other parts of head, sequela',
  'S090XXA Injury of blood vessels of head, not elsewhere classified, initial encounter',
  'S090XXD Injury of blood vessels of head, not elsewhere classified, subsequent encounter',
  'S090XXS Injury of blood vessels of head, not elsewhere classified, sequela',
  'S0910XA Unspecified injury of muscle and tendon of head, initial encounter',
  'S0910XD Unspecified injury of muscle and tendon of head, subsequent encounter',
  'S0910XS Unspecified injury of muscle and tendon of head, sequela',
  'S0911XA Strain of muscle and tendon of head, initial encounter',
  'S0911XD Strain of muscle and tendon of head, subsequent encounter',
  'S0911XS Strain of muscle and tendon of head, sequela',
  'S0912XA Laceration of muscle and tendon of head, initial encounter',
  'S0912XD Laceration of muscle and tendon of head, subsequent encounter',
  'S0912XS Laceration of muscle and tendon of head, sequela',
  'S0919XA Other specified injury of muscle and tendon of head, initial encounter',
  'S0919XD Other specified injury of muscle and tendon of head, subsequent encounter',
  'S0919XS Other specified injury of muscle and tendon of head, sequela',
  'S0920XA Traumatic rupture of unspecified ear drum, initial encounter',
  'S0920XD Traumatic rupture of unspecified ear drum, subsequent encounter',
  'S0920XS Traumatic rupture of unspecified ear drum, sequela',
  'S0921XA Traumatic rupture of right ear drum, initial encounter',
  'S0921XD Traumatic rupture of right ear drum, subsequent encounter',
  'S0921XS Traumatic rupture of right ear drum, sequela',
  'S0922XA Traumatic rupture of left ear drum, initial encounter',
  'S0922XD Traumatic rupture of left ear drum, subsequent encounter',
  'S0922XS Traumatic rupture of left ear drum, sequela',
  'S09301A Unspecified injury of right middle and inner ear, initial encounter',
  'S09301D Unspecified injury of right middle and inner ear, subsequent encounter',
  'S09301S Unspecified injury of right middle and inner ear, sequela',
  'S09302A Unspecified injury of left middle and inner ear, initial encounter',
  'S09302D Unspecified injury of left middle and inner ear, subsequent encounter',
  'S09302S Unspecified injury of left middle and inner ear, sequela',
  'S09309A Unspecified injury of unspecified middle and inner ear, initial encounter',
  'S09309D Unspecified injury of unspecified middle and inner ear, subsequent encounter',
  'S09309S Unspecified injury of unspecified middle and inner ear, sequela',
  'S09311A Primary blast injury of right ear, initial encounter',
  'S09311D Primary blast injury of right ear, subsequent encounter',
  'S09311S Primary blast injury of right ear, sequela',
  'S09312A Primary blast injury of left ear, initial encounter',
  'S09312D Primary blast injury of left ear, subsequent encounter',
  'S09312S Primary blast injury of left ear, sequela',
  'S09313A Primary blast injury of ear, bilateral, initial encounter',
  'S09313D Primary blast injury of ear, bilateral, subsequent encounter',
  'S09313S Primary blast injury of ear, bilateral, sequela',
  'S09319A Primary blast injury of unspecified ear, initial encounter',
  'S09319D Primary blast injury of unspecified ear, subsequent encounter',
  'S09319S Primary blast injury of unspecified ear, sequela',
  'S09391A Other specified injury of right middle and inner ear, initial encounter',
  'S09391D Other specified injury of right middle and inner ear, subsequent encounter',
  'S09391S Other specified injury of right middle and inner ear, sequela',
  'S09392A Other specified injury of left middle and inner ear, initial encounter',
  'S09392D Other specified injury of left middle and inner ear, subsequent encounter',
  'S09392S Other specified injury of left middle and inner ear, sequela',
  'S09399A Other specified injury of unspecified middle and inner ear, initial encounter',
  'S09399D Other specified injury of unspecified middle and inner ear, subsequent encounter',
  'S09399S Other specified injury of unspecified middle and inner ear, sequela',
  'S098XXA Other specified injuries of head, initial encounter',
  'S098XXD Other specified injuries of head, subsequent encounter',
  'S098XXS Other specified injuries of head, sequela',
  'S0990XA Unspecified injury of head, initial encounter',
  'S0990XD Unspecified injury of head, subsequent encounter',
  'S0990XS Unspecified injury of head, sequela',
  'S0991XA Unspecified injury of ear, initial encounter',
  'S0991XD Unspecified injury of ear, subsequent encounter',
  'S0991XS Unspecified injury of ear, sequela',
  'S0992XA Unspecified injury of nose, initial encounter',
  'S0992XD Unspecified injury of nose, subsequent encounter',
  'S0992XS Unspecified injury of nose, sequela',
  'S0993XA Unspecified injury of face, initial encounter',
  'S0993XD Unspecified injury of face, subsequent encounter',
  'S0993XS Unspecified injury of face, sequela',
  'S100XXA Contusion of throat, initial encounter',
  'S100XXD Contusion of throat, subsequent encounter',
  'S100XXS Contusion of throat, sequela',
  'S1010XA Unspecified superficial injuries of throat, initial encounter',
  'S1010XD Unspecified superficial injuries of throat, subsequent encounter',
  'S1010XS Unspecified superficial injuries of throat, sequela',
  'S1011XA Abrasion of throat, initial encounter',
  'S1011XD Abrasion of throat, subsequent encounter',
  'S1011XS Abrasion of throat, sequela',
  'S1012XA Blister (nonthermal) of throat, initial encounter',
  'S1012XD Blister (nonthermal) of throat, subsequent encounter',
  'S1012XS Blister (nonthermal) of throat, sequela',
  'S1014XA External constriction of part of throat, initial encounter',
  'S1014XD External constriction of part of throat, subsequent encounter',
  'S1014XS External constriction of part of throat, sequela',
  'S1015XA Superficial foreign body of throat, initial encounter',
  'S1015XD Superficial foreign body of throat, subsequent encounter',
  'S1015XS Superficial foreign body of throat, sequela',
  'S1016XA Insect bite (nonvenomous) of throat, initial encounter',
  'S1016XD Insect bite (nonvenomous) of throat, subsequent encounter',
  'S1016XS Insect bite (nonvenomous) of throat, sequela',
  'S1017XA Other superficial bite of throat, initial encounter',
  'S1017XD Other superficial bite of throat, subsequent encounter',
  'S1017XS Other superficial bite of throat, sequela',
  'S1080XA Unspecified superficial injury of other specified part of neck, initial encounter',
  'S1080XD Unspecified superficial injury of other specified part of neck, subsequent encounter',
  'S1080XS Unspecified superficial injury of other specified part of neck, sequela',
  'S1081XA Abrasion of other specified part of neck, initial encounter',
  'S1081XD Abrasion of other specified part of neck, subsequent encounter',
  'S1081XS Abrasion of other specified part of neck, sequela',
  'S1082XA Blister (nonthermal) of other specified part of neck, initial encounter',
  'S1082XD Blister (nonthermal) of other specified part of neck, subsequent encounter',
  'S1082XS Blister (nonthermal) of other specified part of neck, sequela',
  'S1083XA Contusion of other specified part of neck, initial encounter',
  'S1083XD Contusion of other specified part of neck, subsequent encounter',
  'S1083XS Contusion of other specified part of neck, sequela',
  'S1084XA External constriction of other specified part of neck, initial encounter',
  'S1084XD External constriction of other specified part of neck, subsequent encounter',
  'S1084XS External constriction of other specified part of neck, sequela',
  'S1085XA Superficial foreign body of other specified part of neck, initial encounter',
  'S1085XD Superficial foreign body of other specified part of neck, subsequent encounter',
  'S1085XS Superficial foreign body of other specified part of neck, sequela',
  'S1086XA Insect bite of other specified part of neck, initial encounter',
  'S1086XD Insect bite of other specified part of neck, subsequent encounter',
  'S1086XS Insect bite of other specified part of neck, sequela',
  'S1087XA Other superficial bite of other specified part of neck, initial encounter',
  'S1087XD Other superficial bite of other specified part of neck, subsequent encounter',
  'S1087XS Other superficial bite of other specified part of neck, sequela',
  'S1090XA Unspecified superficial injury of unspecified part of neck, initial encounter',
  'S1090XD Unspecified superficial injury of unspecified part of neck, subsequent encounter',
  'S1090XS Unspecified superficial injury of unspecified part of neck, sequela',
  'S1091XA Abrasion of unspecified part of neck, initial encounter',
  'S1091XD Abrasion of unspecified part of neck, subsequent encounter',
  'S1091XS Abrasion of unspecified part of neck, sequela',
  'S1092XA Blister (nonthermal) of unspecified part of neck, initial encounter',
  'S1092XD Blister (nonthermal) of unspecified part of neck, subsequent encounter',
  'S1092XS Blister (nonthermal) of unspecified part of neck, sequela',
  'S1093XA Contusion of unspecified part of neck, initial encounter',
  'S1093XD Contusion of unspecified part of neck, subsequent encounter',
  'S1093XS Contusion of unspecified part of neck, sequela',
  'S1094XA External constriction of unspecified part of neck, initial encounter',
  'S1094XD External constriction of unspecified part of neck, subsequent encounter',
  'S1094XS External constriction of unspecified part of neck, sequela',
  'S1095XA Superficial foreign body of unspecified part of neck, initial encounter',
  'S1095XD Superficial foreign body of unspecified part of neck, subsequent encounter',
  'S1095XS Superficial foreign body of unspecified part of neck, sequela',
  'S1096XA Insect bite of unspecified part of neck, initial encounter',
  'S1096XD Insect bite of unspecified part of neck, subsequent encounter',
  'S1096XS Insect bite of unspecified part of neck, sequela',
  'S1097XA Other superficial bite of unspecified part of neck, initial encounter',
  'S1097XD Other superficial bite of unspecified part of neck, subsequent encounter',
  'S1097XS Other superficial bite of unspecified part of neck, sequela',
  'S11011A Laceration without foreign body of larynx, initial encounter',
  'S11011D Laceration without foreign body of larynx, subsequent encounter',
  'S11011S Laceration without foreign body of larynx, sequela',
  'S11012A Laceration with foreign body of larynx, initial encounter',
  'S11012D Laceration with foreign body of larynx, subsequent encounter',
  'S11012S Laceration with foreign body of larynx, sequela',
  'S11013A Puncture wound without foreign body of larynx, initial encounter',
  'S11013D Puncture wound without foreign body of larynx, subsequent encounter',
  'S11013S Puncture wound without foreign body of larynx, sequela',
  'S11014A Puncture wound with foreign body of larynx, initial encounter',
  'S11014D Puncture wound with foreign body of larynx, subsequent encounter',
  'S11014S Puncture wound with foreign body of larynx, sequela',
  'S11015A Open bite of larynx, initial encounter',
  'S11015D Open bite of larynx, subsequent encounter',
  'S11015S Open bite of larynx, sequela',
  'S11019A Unspecified open wound of larynx, initial encounter',
  'S11019D Unspecified open wound of larynx, subsequent encounter',
  'S11019S Unspecified open wound of larynx, sequela',
  'S11021A Laceration without foreign body of trachea, initial encounter',
  'S11021D Laceration without foreign body of trachea, subsequent encounter',
  'S11021S Laceration without foreign body of trachea, sequela',
  'S11022A Laceration with foreign body of trachea, initial encounter',
  'S11022D Laceration with foreign body of trachea, subsequent encounter',
  'S11022S Laceration with foreign body of trachea, sequela',
  'S11023A Puncture wound without foreign body of trachea, initial encounter',
  'S11023D Puncture wound without foreign body of trachea, subsequent encounter',
  'S11023S Puncture wound without foreign body of trachea, sequela',
  'S11024A Puncture wound with foreign body of trachea, initial encounter',
  'S11024D Puncture wound with foreign body of trachea, subsequent encounter',
  'S11024S Puncture wound with foreign body of trachea, sequela',
  'S11025A Open bite of trachea, initial encounter',
  'S11025D Open bite of trachea, subsequent encounter',
  'S11025S Open bite of trachea, sequela',
  'S11029A Unspecified open wound of trachea, initial encounter',
  'S11029D Unspecified open wound of trachea, subsequent encounter',
  'S11029S Unspecified open wound of trachea, sequela',
  'S11031A Laceration without foreign body of vocal cord, initial encounter',
  'S11031D Laceration without foreign body of vocal cord, subsequent encounter',
  'S11031S Laceration without foreign body of vocal cord, sequela',
  'S11032A Laceration with foreign body of vocal cord, initial encounter',
  'S11032D Laceration with foreign body of vocal cord, subsequent encounter',
  'S11032S Laceration with foreign body of vocal cord, sequela',
  'S11033A Puncture wound without foreign body of vocal cord, initial encounter',
  'S11033D Puncture wound without foreign body of vocal cord, subsequent encounter',
  'S11033S Puncture wound without foreign body of vocal cord, sequela',
  'S11034A Puncture wound with foreign body of vocal cord, initial encounter',
  'S11034D Puncture wound with foreign body of vocal cord, subsequent encounter',
  'S11034S Puncture wound with foreign body of vocal cord, sequela',
  'S11035A Open bite of vocal cord, initial encounter',
  'S11035D Open bite of vocal cord, subsequent encounter',
  'S11035S Open bite of vocal cord, sequela',
  'S11039A Unspecified open wound of vocal cord, initial encounter',
  'S11039D Unspecified open wound of vocal cord, subsequent encounter',
  'S11039S Unspecified open wound of vocal cord, sequela',
  'S1110XA Unspecified open wound of thyroid gland, initial encounter',
  'S1110XD Unspecified open wound of thyroid gland, subsequent encounter',
  'S1110XS Unspecified open wound of thyroid gland, sequela',
  'S1111XA Laceration without foreign body of thyroid gland, initial encounter',
  'S1111XD Laceration without foreign body of thyroid gland, subsequent encounter',
  'S1111XS Laceration without foreign body of thyroid gland, sequela',
  'S1112XA Laceration with foreign body of thyroid gland, initial encounter',
  'S1112XD Laceration with foreign body of thyroid gland, subsequent encounter',
  'S1112XS Laceration with foreign body of thyroid gland, sequela',
  'S1113XA Puncture wound without foreign body of thyroid gland, initial encounter',
  'S1113XD Puncture wound without foreign body of thyroid gland, subsequent encounter',
  'S1113XS Puncture wound without foreign body of thyroid gland, sequela',
  'S1114XA Puncture wound with foreign body of thyroid gland, initial encounter',
  'S1114XD Puncture wound with foreign body of thyroid gland, subsequent encounter',
  'S1114XS Puncture wound with foreign body of thyroid gland, sequela',
  'S1115XA Open bite of thyroid gland, initial encounter',
  'S1115XD Open bite of thyroid gland, subsequent encounter',
  'S1115XS Open bite of thyroid gland, sequela',
  'S1120XA Unspecified open wound of pharynx and cervical esophagus, initial encounter',
  'S1120XD Unspecified open wound of pharynx and cervical esophagus, subsequent encounter',
  'S1120XS Unspecified open wound of pharynx and cervical esophagus, sequela',
  'S1121XA Laceration without foreign body of pharynx and cervical esophagus, initial encounter',
  'S1121XD Laceration without foreign body of pharynx and cervical esophagus, subsequent encounter',
  'S1121XS Laceration without foreign body of pharynx and cervical esophagus, sequela',
  'S1122XA Laceration with foreign body of pharynx and cervical esophagus, initial encounter',
  'S1122XD Laceration with foreign body of pharynx and cervical esophagus, subsequent encounter',
  'S1122XS Laceration with foreign body of pharynx and cervical esophagus, sequela',
  'S1123XA Puncture wound without foreign body of pharynx and cervical esophagus, initial encounter',
  'S1123XD Puncture wound without foreign body of pharynx and cervical esophagus, subsequent',
  'encounter',
  'S1123XS Puncture wound without foreign body of pharynx and cervical esophagus, sequela',
  'S1124XA Puncture wound with foreign body of pharynx and cervical esophagus, initial encounter',
  'S1124XD Puncture wound with foreign body of pharynx and cervical esophagus, subsequent encounter',
  'S1124XS Puncture wound with foreign body of pharynx and cervical esophagus, sequela',
  'S1125XA Open bite of pharynx and cervical esophagus, initial encounter',
  'S1125XD Open bite of pharynx and cervical esophagus, subsequent encounter',
  'S1125XS Open bite of pharynx and cervical esophagus, sequela',
  'S1180XA Unspecified open wound of other specified part of neck, initial encounter',
  'S1180XD Unspecified open wound of other specified part of neck, subsequent encounter',
  'S1180XS Unspecified open wound of other specified part of neck, sequela',
  'S1181XA Laceration without foreign body of other specified part of neck, initial encounter',
  'S1181XD Laceration without foreign body of other specified part of neck, subsequent encounter',
  'S1181XS Laceration without foreign body of other specified part of neck, sequela',
  'S1182XA Laceration with foreign body of other specified part of neck, initial encounter',
  'S1182XD Laceration with foreign body of other specified part of neck, subsequent encounter',
  'S1182XS Laceration with foreign body of other specified part of neck, sequela',
  'S1183XA Puncture wound without foreign body of other specified part of neck, initial encounter',
  'S1183XD Puncture wound without foreign body of other specified part of neck, subsequent encounter',
  'S1183XS Puncture wound without foreign body of other specified part of neck, sequela',
  'S1184XA Puncture wound with foreign body of other specified part of neck, initial encounter',
  'S1184XD Puncture wound with foreign body of other specified part of neck, subsequent encounter',
  'S1184XS Puncture wound with foreign body of other specified part of neck, sequela',
  'S1185XA Open bite of other specified part of neck, initial encounter',
  'S1185XD Open bite of other specified part of neck, subsequent encounter',
  'S1185XS Open bite of other specified part of neck, sequela',
  'S1189XA Other open wound of other specified part of neck, initial encounter',
  'S1189XD Other open wound of other specified part of neck, subsequent encounter',
  'S1189XS Other open wound of other specified part of neck, sequela',
  'S1190XA Unspecified open wound of unspecified part of neck, initial encounter',
  'S1190XD Unspecified open wound of unspecified part of neck, subsequent encounter',
  'S1190XS Unspecified open wound of unspecified part of neck, sequela',
  'S1191XA Laceration without foreign body of unspecified part of neck, initial encounter',
  'S1191XD Laceration without foreign body of unspecified part of neck, subsequent encounter',
  'S1191XS Laceration without foreign body of unspecified part of neck, sequela',
  'S1192XA Laceration with foreign body of unspecified part of neck, initial encounter',
  'S1192XD Laceration with foreign body of unspecified part of neck, subsequent encounter',
  'S1192XS Laceration with foreign body of unspecified part of neck, sequela',
  'S1193XA Puncture wound without foreign body of unspecified part of neck, initial encounter',
  'S1193XD Puncture wound without foreign body of unspecified part of neck, subsequent encounter',
  'S1193XS Puncture wound without foreign body of unspecified part of neck, sequela',
  'S1194XA Puncture wound with foreign body of unspecified part of neck, initial encounter',
  'S1194XD Puncture wound with foreign body of unspecified part of neck, subsequent encounter',
  'S1194XS Puncture wound with foreign body of unspecified part of neck, sequela',
  'S1195XA Open bite of unspecified part of neck, initial encounter',
  'S1195XD Open bite of unspecified part of neck, subsequent encounter',
  'S1195XS Open bite of unspecified part of neck, sequela',
  'S12000A Unspecified displaced fracture of first cervical vertebra, initial encounter for closed fracture',
  'S12000B Unspecified displaced fracture of first cervical vertebra, initial encounter for open fracture',
  'S12000D Unspecified displaced fracture of first cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12000G Unspecified displaced fracture of first cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12000K Unspecified displaced fracture of first cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12000S Unspecified displaced fracture of first cervical vertebra, sequela',
  'S12001A Unspecified nondisplaced fracture of first cervical vertebra, initial encounter for closed fracture',
  'S12001B Unspecified nondisplaced fracture of first cervical vertebra, initial encounter for open fracture',
  'S12001D Unspecified nondisplaced fracture of first cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12001G Unspecified nondisplaced fracture of first cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12001K Unspecified nondisplaced fracture of first cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12001S Unspecified nondisplaced fracture of first cervical vertebra, sequela',
  'S1201XA Stable burst fracture of first cervical vertebra, initial encounter for closed fracture',
  'S1201XB Stable burst fracture of first cervical vertebra, initial encounter for open fracture',
  'S1201XD Stable burst fracture of first cervical vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S1201XG Stable burst fracture of first cervical vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S1201XK Stable burst fracture of first cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S1201XS Stable burst fracture of first cervical vertebra, sequela',
  'S1202XA Unstable burst fracture of first cervical vertebra, initial encounter for closed fracture',
  'S1202XB Unstable burst fracture of first cervical vertebra, initial encounter for open fracture',
  'S1202XD Unstable burst fracture of first cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S1202XG Unstable burst fracture of first cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S1202XK Unstable burst fracture of first cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S1202XS Unstable burst fracture of first cervical vertebra, sequela',
  'S12030A Displaced posterior arch fracture of first cervical vertebra, initial encounter for closed fracture',
  'S12030B Displaced posterior arch fracture of first cervical vertebra, initial encounter for open fracture',
  'S12030D Displaced posterior arch fracture of first cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12030G Displaced posterior arch fracture of first cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12030K Displaced posterior arch fracture of first cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12030S Displaced posterior arch fracture of first cervical vertebra, sequela',
  'S12031A Nondisplaced posterior arch fracture of first cervical vertebra, initial encounter for closed',
  'fracture',
  'S12031B Nondisplaced posterior arch fracture of first cervical vertebra, initial encounter for open',
  'fracture',
  'S12031D Nondisplaced posterior arch fracture of first cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S12031G Nondisplaced posterior arch fracture of first cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S12031K Nondisplaced posterior arch fracture of first cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S12031S Nondisplaced posterior arch fracture of first cervical vertebra, sequela',
  'S12040A Displaced lateral mass fracture of first cervical vertebra, initial encounter for closed fracture',
  'S12040B Displaced lateral mass fracture of first cervical vertebra, initial encounter for open fracture',
  'S12040D Displaced lateral mass fracture of first cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12040G Displaced lateral mass fracture of first cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12040K Displaced lateral mass fracture of first cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12040S Displaced lateral mass fracture of first cervical vertebra, sequela',
  'S12041A Nondisplaced lateral mass fracture of first cervical vertebra, initial encounter for closed',
  'fracture',
  'S12041B Nondisplaced lateral mass fracture of first cervical vertebra, initial encounter for open fracture',
  'S12041D Nondisplaced lateral mass fracture of first cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12041G Nondisplaced lateral mass fracture of first cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12041K Nondisplaced lateral mass fracture of first cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12041S Nondisplaced lateral mass fracture of first cervical vertebra, sequela',
  'S12090A Other displaced fracture of first cervical vertebra, initial encounter for closed fracture',
  'S12090B Other displaced fracture of first cervical vertebra, initial encounter for open fracture',
  'S12090D Other displaced fracture of first cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12090G Other displaced fracture of first cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12090K Other displaced fracture of first cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12090S Other displaced fracture of first cervical vertebra, sequela',
  'S12091A Other nondisplaced fracture of first cervical vertebra, initial encounter for closed fracture',
  'S12091B Other nondisplaced fracture of first cervical vertebra, initial encounter for open fracture',
  'S12091D Other nondisplaced fracture of first cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12091G Other nondisplaced fracture of first cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12091K Other nondisplaced fracture of first cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12091S Other nondisplaced fracture of first cervical vertebra, sequela',
  'S12100A Unspecified displaced fracture of second cervical vertebra, initial encounter for closed fracture',
  'S12100B Unspecified displaced fracture of second cervical vertebra, initial encounter for open fracture',
  'S12100D Unspecified displaced fracture of second cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12100G Unspecified displaced fracture of second cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12100K Unspecified displaced fracture of second cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12100S Unspecified displaced fracture of second cervical vertebra, sequela',
  'S12101A Unspecified nondisplaced fracture of second cervical vertebra, initial encounter for closed',
  'fracture',
  'S12101B Unspecified nondisplaced fracture of second cervical vertebra, initial encounter for open',
  'fracture',
  'S12101D Unspecified nondisplaced fracture of second cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S12101G Unspecified nondisplaced fracture of second cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S12101K Unspecified nondisplaced fracture of second cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S12101S Unspecified nondisplaced fracture of second cervical vertebra, sequela',
  'S12110A Anterior displaced Type II dens fracture, initial encounter for closed fracture',
  'S12110B Anterior displaced Type II dens fracture, initial encounter for open fracture',
  'S12110D Anterior displaced Type II dens fracture, subsequent encounter for fracture with routine',
  'healing',
  'S12110G Anterior displaced Type II dens fracture, subsequent encounter for fracture with delayed',
  'healing',
  'S12110K Anterior displaced Type II dens fracture, subsequent encounter for fracture with nonunion',
  'S12110S Anterior displaced Type II dens fracture, sequela',
  'S12111A Posterior displaced Type II dens fracture, initial encounter for closed fracture',
  'S12111B Posterior displaced Type II dens fracture, initial encounter for open fracture',
  'S12111D Posterior displaced Type II dens fracture, subsequent encounter for fracture with routine',
  'healing',
  'S12111G Posterior displaced Type II dens fracture, subsequent encounter for fracture with delayed',
  'healing',
  'S12111K Posterior displaced Type II dens fracture, subsequent encounter for fracture with nonunion',
  'S12111S Posterior displaced Type II dens fracture, sequela',
  'S12112A Nondisplaced Type II dens fracture, initial encounter for closed fracture',
  'S12112B Nondisplaced Type II dens fracture, initial encounter for open fracture',
  'S12112D Nondisplaced Type II dens fracture, subsequent encounter for fracture with routine healing',
  'S12112G Nondisplaced Type II dens fracture, subsequent encounter for fracture with delayed healing',
  'S12112K Nondisplaced Type II dens fracture, subsequent encounter for fracture with nonunion',
  'S12112S Nondisplaced Type II dens fracture, sequela',
  'S12120A Other displaced dens fracture, initial encounter for closed fracture',
  'S12120B Other displaced dens fracture, initial encounter for open fracture',
  'S12120D Other displaced dens fracture, subsequent encounter for fracture with routine healing',
  'S12120G Other displaced dens fracture, subsequent encounter for fracture with delayed healing',
  'S12120K Other displaced dens fracture, subsequent encounter for fracture with nonunion',
  'S12120S Other displaced dens fracture, sequela',
  'S12121A Other nondisplaced dens fracture, initial encounter for closed fracture',
  'S12121B Other nondisplaced dens fracture, initial encounter for open fracture',
  'S12121D Other nondisplaced dens fracture, subsequent encounter for fracture with routine healing',
  'S12121G Other nondisplaced dens fracture, subsequent encounter for fracture with delayed healing',
  'S12121K Other nondisplaced dens fracture, subsequent encounter for fracture with nonunion',
  'S12121S Other nondisplaced dens fracture, sequela',
  'S12130A Unspecified traumatic displaced spondylolisthesis of second cervical vertebra, initial encounter',
  'for closed fracture',
  'S12130B Unspecified traumatic displaced spondylolisthesis of second cervical vertebra, initial encounter',
  'for open fracture',
  'S12130D Unspecified traumatic displaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12130G Unspecified traumatic displaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12130K Unspecified traumatic displaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12130S Unspecified traumatic displaced spondylolisthesis of second cervical vertebra, sequela',
  'S12131A Unspecified traumatic nondisplaced spondylolisthesis of second cervical vertebra, initial',
  'encounter for closed fracture',
  'S12131B Unspecified traumatic nondisplaced spondylolisthesis of second cervical vertebra, initial',
  'encounter for open fracture',
  'S12131D Unspecified traumatic nondisplaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12131G Unspecified traumatic nondisplaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12131K Unspecified traumatic nondisplaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12131S Unspecified traumatic nondisplaced spondylolisthesis of second cervical vertebra, sequela',
  'S1214XA Type III traumatic spondylolisthesis of second cervical vertebra, initial encounter for closed',
  'fracture',
  'S1214XB Type III traumatic spondylolisthesis of second cervical vertebra, initial encounter for open',
  'fracture',
  'S1214XD Type III traumatic spondylolisthesis of second cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S1214XG Type III traumatic spondylolisthesis of second cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S1214XK Type III traumatic spondylolisthesis of second cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S1214XS Type III traumatic spondylolisthesis of second cervical vertebra, sequela',
  'S12150A Other traumatic displaced spondylolisthesis of second cervical vertebra, initial encounter for',
  'closed fracture',
  'S12150B Other traumatic displaced spondylolisthesis of second cervical vertebra, initial encounter for',
  'open fracture',
  'S12150D Other traumatic displaced spondylolisthesis of second cervical vertebra, subsequent encounter',
  'for fracture with routine healing',
  'S12150G Other traumatic displaced spondylolisthesis of second cervical vertebra, subsequent encounter',
  'for fracture with delayed healing',
  'S12150K Other traumatic displaced spondylolisthesis of second cervical vertebra, subsequent encounter',
  'for fracture with nonunion',
  'S12150S Other traumatic displaced spondylolisthesis of second cervical vertebra, sequela',
  'S12151A Other traumatic nondisplaced spondylolisthesis of second cervical vertebra, initial encounter',
  'for closed fracture',
  'S12151B Other traumatic nondisplaced spondylolisthesis of second cervical vertebra, initial encounter',
  'for open fracture',
  'S12151D Other traumatic nondisplaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12151G Other traumatic nondisplaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12151K Other traumatic nondisplaced spondylolisthesis of second cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12151S Other traumatic nondisplaced spondylolisthesis of second cervical vertebra, sequela',
  'S12190A Other displaced fracture of second cervical vertebra, initial encounter for closed fracture',
  'S12190B Other displaced fracture of second cervical vertebra, initial encounter for open fracture',
  'S12190D Other displaced fracture of second cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12190G Other displaced fracture of second cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12190K Other displaced fracture of second cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12190S Other displaced fracture of second cervical vertebra, sequela',
  'S12191A Other nondisplaced fracture of second cervical vertebra, initial encounter for closed fracture',
  'S12191B Other nondisplaced fracture of second cervical vertebra, initial encounter for open fracture',
  'S12191D Other nondisplaced fracture of second cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12191G Other nondisplaced fracture of second cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12191K Other nondisplaced fracture of second cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12191S Other nondisplaced fracture of second cervical vertebra, sequela',
  'S12200A Unspecified displaced fracture of third cervical vertebra, initial encounter for closed fracture',
  'S12200B Unspecified displaced fracture of third cervical vertebra, initial encounter for open fracture',
  'S12200D Unspecified displaced fracture of third cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12200G Unspecified displaced fracture of third cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12200K Unspecified displaced fracture of third cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12200S Unspecified displaced fracture of third cervical vertebra, sequela',
  'S12201A Unspecified nondisplaced fracture of third cervical vertebra, initial encounter for closed',
  'fracture',
  'S12201B Unspecified nondisplaced fracture of third cervical vertebra, initial encounter for open fracture',
  'S12201D Unspecified nondisplaced fracture of third cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S12201G Unspecified nondisplaced fracture of third cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S12201K Unspecified nondisplaced fracture of third cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12201S Unspecified nondisplaced fracture of third cervical vertebra, sequela',
  'S12230A Unspecified traumatic displaced spondylolisthesis of third cervical vertebra, initial encounter',
  'for closed fracture',
  'S12230B Unspecified traumatic displaced spondylolisthesis of third cervical vertebra, initial encounter',
  'for open fracture',
  'S12230D Unspecified traumatic displaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12230G Unspecified traumatic displaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12230K Unspecified traumatic displaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12230S Unspecified traumatic displaced spondylolisthesis of third cervical vertebra, sequela',
  'S12231A Unspecified traumatic nondisplaced spondylolisthesis of third cervical vertebra, initial',
  'encounter for closed fracture',
  'S12231B Unspecified traumatic nondisplaced spondylolisthesis of third cervical vertebra, initial',
  'encounter for open fracture',
  'S12231D Unspecified traumatic nondisplaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12231G Unspecified traumatic nondisplaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12231K Unspecified traumatic nondisplaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12231S Unspecified traumatic nondisplaced spondylolisthesis of third cervical vertebra, sequela',
  'S1224XA Type III traumatic spondylolisthesis of third cervical vertebra, initial encounter for closed',
  'fracture',
  'S1224XB Type III traumatic spondylolisthesis of third cervical vertebra, initial encounter for open',
  'fracture',
  'S1224XD Type III traumatic spondylolisthesis of third cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S1224XG Type III traumatic spondylolisthesis of third cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S1224XK Type III traumatic spondylolisthesis of third cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S1224XS Type III traumatic spondylolisthesis of third cervical vertebra, sequela',
  'S12250A Other traumatic displaced spondylolisthesis of third cervical vertebra, initial encounter for',
  'closed fracture',
  'S12250B Other traumatic displaced spondylolisthesis of third cervical vertebra, initial encounter for',
  'open fracture',
  'S12250D Other traumatic displaced spondylolisthesis of third cervical vertebra, subsequent encounter',
  'for fracture with routine healing',
  'S12250G Other traumatic displaced spondylolisthesis of third cervical vertebra, subsequent encounter',
  'for fracture with delayed healing',
  'S12250K Other traumatic displaced spondylolisthesis of third cervical vertebra, subsequent encounter',
  'for fracture with nonunion',
  'S12250S Other traumatic displaced spondylolisthesis of third cervical vertebra, sequela',
  'S12251A Other traumatic nondisplaced spondylolisthesis of third cervical vertebra, initial encounter for',
  'closed fracture',
  'S12251B Other traumatic nondisplaced spondylolisthesis of third cervical vertebra, initial encounter for',
  'open fracture',
  'S12251D Other traumatic nondisplaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12251G Other traumatic nondisplaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12251K Other traumatic nondisplaced spondylolisthesis of third cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12251S Other traumatic nondisplaced spondylolisthesis of third cervical vertebra, sequela',
  'S12290A Other displaced fracture of third cervical vertebra, initial encounter for closed fracture',
  'S12290B Other displaced fracture of third cervical vertebra, initial encounter for open fracture',
  'S12290D Other displaced fracture of third cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12290G Other displaced fracture of third cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12290K Other displaced fracture of third cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12290S Other displaced fracture of third cervical vertebra, sequela',
  'S12291A Other nondisplaced fracture of third cervical vertebra, initial encounter for closed fracture',
  'S12291B Other nondisplaced fracture of third cervical vertebra, initial encounter for open fracture',
  'S12291D Other nondisplaced fracture of third cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12291G Other nondisplaced fracture of third cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12291K Other nondisplaced fracture of third cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12291S Other nondisplaced fracture of third cervical vertebra, sequela',
  'S12300A Unspecified displaced fracture of fourth cervical vertebra, initial encounter for closed fracture',
  'S12300B Unspecified displaced fracture of fourth cervical vertebra, initial encounter for open fracture',
  'S12300D Unspecified displaced fracture of fourth cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12300G Unspecified displaced fracture of fourth cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12300K Unspecified displaced fracture of fourth cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12300S Unspecified displaced fracture of fourth cervical vertebra, sequela',
  'S12301A Unspecified nondisplaced fracture of fourth cervical vertebra, initial encounter for closed',
  'fracture',
  'S12301B Unspecified nondisplaced fracture of fourth cervical vertebra, initial encounter for open',
  'fracture',
  'S12301D Unspecified nondisplaced fracture of fourth cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S12301G Unspecified nondisplaced fracture of fourth cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S12301K Unspecified nondisplaced fracture of fourth cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S12301S Unspecified nondisplaced fracture of fourth cervical vertebra, sequela',
  'S12330A Unspecified traumatic displaced spondylolisthesis of fourth cervical vertebra, initial encounter',
  'for closed fracture',
  'S12330B Unspecified traumatic displaced spondylolisthesis of fourth cervical vertebra, initial encounter',
  'for open fracture',
  'S12330D Unspecified traumatic displaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12330G Unspecified traumatic displaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12330K Unspecified traumatic displaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12330S Unspecified traumatic displaced spondylolisthesis of fourth cervical vertebra, sequela',
  'S12331A Unspecified traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, initial',
  'encounter for closed fracture',
  'S12331B Unspecified traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, initial',
  'encounter for open fracture',
  'S12331D Unspecified traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12331G Unspecified traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12331K Unspecified traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12331S Unspecified traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, sequela',
  'S1234XA Type III traumatic spondylolisthesis of fourth cervical vertebra, initial encounter for closed',
  'fracture',
  'S1234XB Type III traumatic spondylolisthesis of fourth cervical vertebra, initial encounter for open',
  'fracture',
  'S1234XD Type III traumatic spondylolisthesis of fourth cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S1234XG Type III traumatic spondylolisthesis of fourth cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S1234XK Type III traumatic spondylolisthesis of fourth cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S1234XS Type III traumatic spondylolisthesis of fourth cervical vertebra, sequela',
  'S12350A Other traumatic displaced spondylolisthesis of fourth cervical vertebra, initial encounter for',
  'closed fracture',
  'S12350B Other traumatic displaced spondylolisthesis of fourth cervical vertebra, initial encounter for',
  'open fracture',
  'S12350D Other traumatic displaced spondylolisthesis of fourth cervical vertebra, subsequent encounter',
  'for fracture with routine healing',
  'S12350G Other traumatic displaced spondylolisthesis of fourth cervical vertebra, subsequent encounter',
  'for fracture with delayed healing',
  'S12350K Other traumatic displaced spondylolisthesis of fourth cervical vertebra, subsequent encounter',
  'for fracture with nonunion',
  'S12350S Other traumatic displaced spondylolisthesis of fourth cervical vertebra, sequela',
  'S12351A Other traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, initial encounter',
  'for closed fracture',
  'S12351B Other traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, initial encounter for',
  'open fracture',
  'S12351D Other traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12351G Other traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12351K Other traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12351S Other traumatic nondisplaced spondylolisthesis of fourth cervical vertebra, sequela',
  'S12390A Other displaced fracture of fourth cervical vertebra, initial encounter for closed fracture',
  'S12390B Other displaced fracture of fourth cervical vertebra, initial encounter for open fracture',
  'S12390D Other displaced fracture of fourth cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12390G Other displaced fracture of fourth cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12390K Other displaced fracture of fourth cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12390S Other displaced fracture of fourth cervical vertebra, sequela',
  'S12391A Other nondisplaced fracture of fourth cervical vertebra, initial encounter for closed fracture',
  'S12391B Other nondisplaced fracture of fourth cervical vertebra, initial encounter for open fracture',
  'S12391D Other nondisplaced fracture of fourth cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12391G Other nondisplaced fracture of fourth cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12391K Other nondisplaced fracture of fourth cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12391S Other nondisplaced fracture of fourth cervical vertebra, sequela',
  'S12400A Unspecified displaced fracture of fifth cervical vertebra, initial encounter for closed fracture',
  'S12400B Unspecified displaced fracture of fifth cervical vertebra, initial encounter for open fracture',
  'S12400D Unspecified displaced fracture of fifth cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12400G Unspecified displaced fracture of fifth cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12400K Unspecified displaced fracture of fifth cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12400S Unspecified displaced fracture of fifth cervical vertebra, sequela',
  'S12401A Unspecified nondisplaced fracture of fifth cervical vertebra, initial encounter for closed',
  'fracture',
  'S12401B Unspecified nondisplaced fracture of fifth cervical vertebra, initial encounter for open fracture',
  'S12401D Unspecified nondisplaced fracture of fifth cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12401G Unspecified nondisplaced fracture of fifth cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12401K Unspecified nondisplaced fracture of fifth cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12401S Unspecified nondisplaced fracture of fifth cervical vertebra, sequela',
  'S12430A Unspecified traumatic displaced spondylolisthesis of fifth cervical vertebra, initial encounter for',
  'closed fracture',
  'S12430B Unspecified traumatic displaced spondylolisthesis of fifth cervical vertebra, initial encounter for',
  'open fracture',
  'S12430D Unspecified traumatic displaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12430G Unspecified traumatic displaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12430K Unspecified traumatic displaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12430S Unspecified traumatic displaced spondylolisthesis of fifth cervical vertebra, sequela',
  'S12431A Unspecified traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, initial',
  'encounter for closed fracture',
  'S12431B Unspecified traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, initial',
  'encounter for open fracture',
  'S12431D Unspecified traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12431G Unspecified traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12431K Unspecified traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12431S Unspecified traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, sequela',
  'S1244XA Type III traumatic spondylolisthesis of fifth cervical vertebra, initial encounter for closed',
  'fracture',
  'S1244XB Type III traumatic spondylolisthesis of fifth cervical vertebra, initial encounter for open fracture',
  'S1244XD Type III traumatic spondylolisthesis of fifth cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S1244XG Type III traumatic spondylolisthesis of fifth cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S1244XK Type III traumatic spondylolisthesis of fifth cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S1244XS Type III traumatic spondylolisthesis of fifth cervical vertebra, sequela',
  'S12450A Other traumatic displaced spondylolisthesis of fifth cervical vertebra, initial encounter for',
  'closed fracture',
  'S12450B Other traumatic displaced spondylolisthesis of fifth cervical vertebra, initial encounter for open',
  'fracture',
  'S12450D Other traumatic displaced spondylolisthesis of fifth cervical vertebra, subsequent encounter',
  'for fracture with routine healing',
  'S12450G Other traumatic displaced spondylolisthesis of fifth cervical vertebra, subsequent encounter',
  'for fracture with delayed healing',
  'S12450K Other traumatic displaced spondylolisthesis of fifth cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S12450S Other traumatic displaced spondylolisthesis of fifth cervical vertebra, sequela',
  'S12451A Other traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, initial encounter for',
  'closed fracture',
  'S12451B Other traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, initial encounter for',
  'open fracture',
  'S12451D Other traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12451G Other traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12451K Other traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12451S Other traumatic nondisplaced spondylolisthesis of fifth cervical vertebra, sequela',
  'S12490A Other displaced fracture of fifth cervical vertebra, initial encounter for closed fracture',
  'S12490B Other displaced fracture of fifth cervical vertebra, initial encounter for open fracture',
  'S12490D Other displaced fracture of fifth cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12490G Other displaced fracture of fifth cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12490K Other displaced fracture of fifth cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12490S Other displaced fracture of fifth cervical vertebra, sequela',
  'S12491A Other nondisplaced fracture of fifth cervical vertebra, initial encounter for closed fracture',
  'S12491B Other nondisplaced fracture of fifth cervical vertebra, initial encounter for open fracture',
  'S12491D Other nondisplaced fracture of fifth cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12491G Other nondisplaced fracture of fifth cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12491K Other nondisplaced fracture of fifth cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12491S Other nondisplaced fracture of fifth cervical vertebra, sequela',
  'S12500A Unspecified displaced fracture of sixth cervical vertebra, initial encounter for closed fracture',
  'S12500B Unspecified displaced fracture of sixth cervical vertebra, initial encounter for open fracture',
  'S12500D Unspecified displaced fracture of sixth cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12500G Unspecified displaced fracture of sixth cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12500K Unspecified displaced fracture of sixth cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12500S Unspecified displaced fracture of sixth cervical vertebra, sequela',
  'S12501A Unspecified nondisplaced fracture of sixth cervical vertebra, initial encounter for closed',
  'fracture',
  'S12501B Unspecified nondisplaced fracture of sixth cervical vertebra, initial encounter for open fracture',
  'S12501D Unspecified nondisplaced fracture of sixth cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12501G Unspecified nondisplaced fracture of sixth cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12501K Unspecified nondisplaced fracture of sixth cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12501S Unspecified nondisplaced fracture of sixth cervical vertebra, sequela',
  'S12530A Unspecified traumatic displaced spondylolisthesis of sixth cervical vertebra, initial encounter',
  'for closed fracture',
  'S12530B Unspecified traumatic displaced spondylolisthesis of sixth cervical vertebra, initial encounter',
  'for open fracture',
  'S12530D Unspecified traumatic displaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12530G Unspecified traumatic displaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12530K Unspecified traumatic displaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12530S Unspecified traumatic displaced spondylolisthesis of sixth cervical vertebra, sequela',
  'S12531A Unspecified traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, initial',
  'encounter for closed fracture',
  'S12531B Unspecified traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, initial',
  'encounter for open fracture',
  'S12531D Unspecified traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12531G Unspecified traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12531K Unspecified traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12531S Unspecified traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, sequela',
  'S1254XA Type III traumatic spondylolisthesis of sixth cervical vertebra, initial encounter for closed',
  'fracture',
  'S1254XB Type III traumatic spondylolisthesis of sixth cervical vertebra, initial encounter for open',
  'fracture',
  'S1254XD Type III traumatic spondylolisthesis of sixth cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S1254XG Type III traumatic spondylolisthesis of sixth cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S1254XK Type III traumatic spondylolisthesis of sixth cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S1254XS Type III traumatic spondylolisthesis of sixth cervical vertebra, sequela',
  'S12550A Other traumatic displaced spondylolisthesis of sixth cervical vertebra, initial encounter for',
  'closed fracture',
  'S12550B Other traumatic displaced spondylolisthesis of sixth cervical vertebra, initial encounter for',
  'open fracture',
  'S12550D Other traumatic displaced spondylolisthesis of sixth cervical vertebra, subsequent encounter',
  'for fracture with routine healing',
  'S12550G Other traumatic displaced spondylolisthesis of sixth cervical vertebra, subsequent encounter',
  'for fracture with delayed healing',
  'S12550K Other traumatic displaced spondylolisthesis of sixth cervical vertebra, subsequent encounter',
  'for fracture with nonunion',
  'S12550S Other traumatic displaced spondylolisthesis of sixth cervical vertebra, sequela',
  'S12551A Other traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, initial encounter for',
  'closed fracture',
  'S12551B Other traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, initial encounter for',
  'open fracture',
  'S12551D Other traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12551G Other traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12551K Other traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12551S Other traumatic nondisplaced spondylolisthesis of sixth cervical vertebra, sequela',
  'S12590A Other displaced fracture of sixth cervical vertebra, initial encounter for closed fracture',
  'S12590B Other displaced fracture of sixth cervical vertebra, initial encounter for open fracture',
  'S12590D Other displaced fracture of sixth cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12590G Other displaced fracture of sixth cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12590K Other displaced fracture of sixth cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12590S Other displaced fracture of sixth cervical vertebra, sequela',
  'S12591A Other nondisplaced fracture of sixth cervical vertebra, initial encounter for closed fracture',
  'S12591B Other nondisplaced fracture of sixth cervical vertebra, initial encounter for open fracture',
  'S12591D Other nondisplaced fracture of sixth cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12591G Other nondisplaced fracture of sixth cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12591K Other nondisplaced fracture of sixth cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12591S Other nondisplaced fracture of sixth cervical vertebra, sequela',
  'S12600A Unspecified displaced fracture of seventh cervical vertebra, initial encounter for closed fracture',
  'S12600B Unspecified displaced fracture of seventh cervical vertebra, initial encounter for open fracture',
  'S12600D Unspecified displaced fracture of seventh cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12600G Unspecified displaced fracture of seventh cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12600K Unspecified displaced fracture of seventh cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12600S Unspecified displaced fracture of seventh cervical vertebra, sequela',
  'S12601A Unspecified nondisplaced fracture of seventh cervical vertebra, initial encounter for closed',
  'fracture',
  'S12601B Unspecified nondisplaced fracture of seventh cervical vertebra, initial encounter for open',
  'fracture',
  'S12601D Unspecified nondisplaced fracture of seventh cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S12601G Unspecified nondisplaced fracture of seventh cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S12601K Unspecified nondisplaced fracture of seventh cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S12601S Unspecified nondisplaced fracture of seventh cervical vertebra, sequela',
  'S12630A Unspecified traumatic displaced spondylolisthesis of seventh cervical vertebra, initial',
  'encounter for closed fracture',
  'S12630B Unspecified traumatic displaced spondylolisthesis of seventh cervical vertebra, initial',
  'encounter for open fracture',
  'S12630D Unspecified traumatic displaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12630G Unspecified traumatic displaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12630K Unspecified traumatic displaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12630S Unspecified traumatic displaced spondylolisthesis of seventh cervical vertebra, sequela',
  'S12631A Unspecified traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, initial',
  'encounter for closed fracture',
  'S12631B Unspecified traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, initial',
  'encounter for open fracture',
  'S12631D Unspecified traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12631G Unspecified traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12631K Unspecified traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12631S Unspecified traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, sequela',
  'S1264XA Type III traumatic spondylolisthesis of seventh cervical vertebra, initial encounter for closed',
  'fracture',
  'S1264XB Type III traumatic spondylolisthesis of seventh cervical vertebra, initial encounter for open',
  'fracture',
  'S1264XD Type III traumatic spondylolisthesis of seventh cervical vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S1264XG Type III traumatic spondylolisthesis of seventh cervical vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S1264XK Type III traumatic spondylolisthesis of seventh cervical vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S1264XS Type III traumatic spondylolisthesis of seventh cervical vertebra, sequela',
  'S12650A Other traumatic displaced spondylolisthesis of seventh cervical vertebra, initial encounter for',
  'closed fracture',
  'S12650B Other traumatic displaced spondylolisthesis of seventh cervical vertebra, initial encounter for',
  'open fracture',
  'S12650D Other traumatic displaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12650G Other traumatic displaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12650K Other traumatic displaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12650S Other traumatic displaced spondylolisthesis of seventh cervical vertebra, sequela',
  'S12651A Other traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, initial encounter',
  'for closed fracture',
  'S12651B Other traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, initial encounter',
  'for open fracture',
  'S12651D Other traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with routine healing',
  'S12651G Other traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with delayed healing',
  'S12651K Other traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, subsequent',
  'encounter for fracture with nonunion',
  'S12651S Other traumatic nondisplaced spondylolisthesis of seventh cervical vertebra, sequela',
  'S12690A Other displaced fracture of seventh cervical vertebra, initial encounter for closed fracture',
  'S12690B Other displaced fracture of seventh cervical vertebra, initial encounter for open fracture',
  'S12690D Other displaced fracture of seventh cervical vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S12690G Other displaced fracture of seventh cervical vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S12690K Other displaced fracture of seventh cervical vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S12690S Other displaced fracture of seventh cervical vertebra, sequela',
  'S12691A Other nondisplaced fracture of seventh cervical vertebra, initial encounter for closed fracture',
  'S12691B Other nondisplaced fracture of seventh cervical vertebra, initial encounter for open fracture',
  'S12691D Other nondisplaced fracture of seventh cervical vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S12691G Other nondisplaced fracture of seventh cervical vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S12691K Other nondisplaced fracture of seventh cervical vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S12691S Other nondisplaced fracture of seventh cervical vertebra, sequela',
  'S128XXA Fracture of other parts of neck, initial encounter',
  'S128XXD Fracture of other parts of neck, subsequent encounter',
  'S128XXS Fracture of other parts of neck, sequela',
  'S129XXA Fracture of neck, unspecified, initial encounter',
  'S129XXD Fracture of neck, unspecified, subsequent encounter',
  'S129XXS Fracture of neck, unspecified, sequela',
  'S130XXA Traumatic rupture of cervical intervertebral disc, initial encounter',
  'S130XXD Traumatic rupture of cervical intervertebral disc, subsequent encounter',
  'S130XXS Traumatic rupture of cervical intervertebral disc, sequela',
  'S13100A Subluxation of unspecified cervical vertebrae, initial encounter',
  'S13100D Subluxation of unspecified cervical vertebrae, subsequent encounter',
  'S13100S Subluxation of unspecified cervical vertebrae, sequela',
  'S13101A Dislocation of unspecified cervical vertebrae, initial encounter',
  'S13101D Dislocation of unspecified cervical vertebrae, subsequent encounter',
  'S13101S Dislocation of unspecified cervical vertebrae, sequela',
  'S13110A Subluxation of C0/C1 cervical vertebrae, initial encounter',
  'S13110D Subluxation of C0/C1 cervical vertebrae, subsequent encounter',
  'S13110S Subluxation of C0/C1 cervical vertebrae, sequela',
  'S13111A Dislocation of C0/C1 cervical vertebrae, initial encounter',
  'S13111D Dislocation of C0/C1 cervical vertebrae, subsequent encounter',
  'S13111S Dislocation of C0/C1 cervical vertebrae, sequela',
  'S13120A Subluxation of C1/C2 cervical vertebrae, initial encounter',
  'S13120D Subluxation of C1/C2 cervical vertebrae, subsequent encounter',
  'S13120S Subluxation of C1/C2 cervical vertebrae, sequela',
  'S13121A Dislocation of C1/C2 cervical vertebrae, initial encounter',
  'S13121D Dislocation of C1/C2 cervical vertebrae, subsequent encounter',
  'S13121S Dislocation of C1/C2 cervical vertebrae, sequela',
  'S13130A Subluxation of C2/C3 cervical vertebrae, initial encounter',
  'S13130D Subluxation of C2/C3 cervical vertebrae, subsequent encounter',
  'S13130S Subluxation of C2/C3 cervical vertebrae, sequela',
  'S13131A Dislocation of C2/C3 cervical vertebrae, initial encounter',
  'S13131D Dislocation of C2/C3 cervical vertebrae, subsequent encounter',
  'S13131S Dislocation of C2/C3 cervical vertebrae, sequela',
  'S13140A Subluxation of C3/C4 cervical vertebrae, initial encounter',
  'S13140D Subluxation of C3/C4 cervical vertebrae, subsequent encounter',
  'S13140S Subluxation of C3/C4 cervical vertebrae, sequela',
  'S13141A Dislocation of C3/C4 cervical vertebrae, initial encounter',
  'S13141D Dislocation of C3/C4 cervical vertebrae, subsequent encounter',
  'S13141S Dislocation of C3/C4 cervical vertebrae, sequela',
  'S13150A Subluxation of C4/C5 cervical vertebrae, initial encounter',
  'S13150D Subluxation of C4/C5 cervical vertebrae, subsequent encounter',
  'S13150S Subluxation of C4/C5 cervical vertebrae, sequela',
  'S13151A Dislocation of C4/C5 cervical vertebrae, initial encounter',
  'S13151D Dislocation of C4/C5 cervical vertebrae, subsequent encounter',
  'S13151S Dislocation of C4/C5 cervical vertebrae, sequela',
  'S13160A Subluxation of C5/C6 cervical vertebrae, initial encounter',
  'S13160D Subluxation of C5/C6 cervical vertebrae, subsequent encounter',
  'S13160S Subluxation of C5/C6 cervical vertebrae, sequela',
  'S13161A Dislocation of C5/C6 cervical vertebrae, initial encounter',
  'S13161D Dislocation of C5/C6 cervical vertebrae, subsequent encounter',
  'S13161S Dislocation of C5/C6 cervical vertebrae, sequela',
  'S13170A Subluxation of C6/C7 cervical vertebrae, initial encounter',
  'S13170D Subluxation of C6/C7 cervical vertebrae, subsequent encounter',
  'S13170S Subluxation of C6/C7 cervical vertebrae, sequela',
  'S13171A Dislocation of C6/C7 cervical vertebrae, initial encounter',
  'S13171D Dislocation of C6/C7 cervical vertebrae, subsequent encounter',
  'S13171S Dislocation of C6/C7 cervical vertebrae, sequela',
  'S13180A Subluxation of C7/T1 cervical vertebrae, initial encounter',
  'S13180D Subluxation of C7/T1 cervical vertebrae, subsequent encounter',
  'S13180S Subluxation of C7/T1 cervical vertebrae, sequela',
  'S13181A Dislocation of C7/T1 cervical vertebrae, initial encounter',
  'S13181D Dislocation of C7/T1 cervical vertebrae, subsequent encounter',
  'S13181S Dislocation of C7/T1 cervical vertebrae, sequela',
  'S1320XA Dislocation of unspecified parts of neck, initial encounter',
  'S1320XD Dislocation of unspecified parts of neck, subsequent encounter',
  'S1320XS Dislocation of unspecified parts of neck, sequela',
  'S1329XA Dislocation of other parts of neck, initial encounter',
  'S1329XD Dislocation of other parts of neck, subsequent encounter',
  'S1329XS Dislocation of other parts of neck, sequela',
  'S134XXA Sprain of ligaments of cervical spine, initial encounter',
  'S134XXD Sprain of ligaments of cervical spine, subsequent encounter',
  'S134XXS Sprain of ligaments of cervical spine, sequela',
  'S135XXA Sprain of thyroid region, initial encounter',
  'S135XXD Sprain of thyroid region, subsequent encounter',
  'S135XXS Sprain of thyroid region, sequela',
  'S138XXA Sprain of joints and ligaments of other parts of neck, initial encounter',
  'S138XXD Sprain of joints and ligaments of other parts of neck, subsequent encounter',
  'S138XXS Sprain of joints and ligaments of other parts of neck, sequela',
  'S139XXA Sprain of joints and ligaments of unspecified parts of neck, initial encounter',
  'S139XXD Sprain of joints and ligaments of unspecified parts of neck, subsequent encounter',
  'S139XXS Sprain of joints and ligaments of unspecified parts of neck, sequela',
  'S140XXA Concussion and edema of cervical spinal cord, initial encounter',
  'S140XXD Concussion and edema of cervical spinal cord, subsequent encounter',
  'S140XXS Concussion and edema of cervical spinal cord, sequela',
  'S14101A Unspecified injury at C1 level of cervical spinal cord, initial encounter',
  'S14101D Unspecified injury at C1 level of cervical spinal cord, subsequent encounter',
  'S14101S Unspecified injury at C1 level of cervical spinal cord, sequela',
  'S14102A Unspecified injury at C2 level of cervical spinal cord, initial encounter',
  'S14102D Unspecified injury at C2 level of cervical spinal cord, subsequent encounter',
  'S14102S Unspecified injury at C2 level of cervical spinal cord, sequela',
  'S14103A Unspecified injury at C3 level of cervical spinal cord, initial encounter',
  'S14103D Unspecified injury at C3 level of cervical spinal cord, subsequent encounter',
  'S14103S Unspecified injury at C3 level of cervical spinal cord, sequela',
  'S14104A Unspecified injury at C4 level of cervical spinal cord, initial encounter',
  'S14104D Unspecified injury at C4 level of cervical spinal cord, subsequent encounter',
  'S14104S Unspecified injury at C4 level of cervical spinal cord, sequela',
  'S14105A Unspecified injury at C5 level of cervical spinal cord, initial encounter',
  'S14105D Unspecified injury at C5 level of cervical spinal cord, subsequent encounter',
  'S14105S Unspecified injury at C5 level of cervical spinal cord, sequela',
  'S14106A Unspecified injury at C6 level of cervical spinal cord, initial encounter',
  'S14106D Unspecified injury at C6 level of cervical spinal cord, subsequent encounter',
  'S14106S Unspecified injury at C6 level of cervical spinal cord, sequela',
  'S14107A Unspecified injury at C7 level of cervical spinal cord, initial encounter',
  'S14107D Unspecified injury at C7 level of cervical spinal cord, subsequent encounter',
  'S14107S Unspecified injury at C7 level of cervical spinal cord, sequela',
  'S14108A Unspecified injury at C8 level of cervical spinal cord, initial encounter',
  'S14108D Unspecified injury at C8 level of cervical spinal cord, subsequent encounter',
  'S14108S Unspecified injury at C8 level of cervical spinal cord, sequela',
  'S14109A Unspecified injury at unspecified level of cervical spinal cord, initial encounter',
  'S14109D Unspecified injury at unspecified level of cervical spinal cord, subsequent encounter',
  'S14109S Unspecified injury at unspecified level of cervical spinal cord, sequela',
  'S14111A Complete lesion at C1 level of cervical spinal cord, initial encounter',
  'S14111D Complete lesion at C1 level of cervical spinal cord, subsequent encounter',
  'S14111S Complete lesion at C1 level of cervical spinal cord, sequela',
  'S14112A Complete lesion at C2 level of cervical spinal cord, initial encounter',
  'S14112D Complete lesion at C2 level of cervical spinal cord, subsequent encounter',
  'S14112S Complete lesion at C2 level of cervical spinal cord, sequela',
  'S14113A Complete lesion at C3 level of cervical spinal cord, initial encounter',
  'S14113D Complete lesion at C3 level of cervical spinal cord, subsequent encounter',
  'S14113S Complete lesion at C3 level of cervical spinal cord, sequela',
  'S14114A Complete lesion at C4 level of cervical spinal cord, initial encounter',
  'S14114D Complete lesion at C4 level of cervical spinal cord, subsequent encounter',
  'S14114S Complete lesion at C4 level of cervical spinal cord, sequela',
  'S14115A Complete lesion at C5 level of cervical spinal cord, initial encounter',
  'S14115D Complete lesion at C5 level of cervical spinal cord, subsequent encounter',
  'S14115S Complete lesion at C5 level of cervical spinal cord, sequela',
  'S14116A Complete lesion at C6 level of cervical spinal cord, initial encounter',
  'S14116D Complete lesion at C6 level of cervical spinal cord, subsequent encounter',
  'S14116S Complete lesion at C6 level of cervical spinal cord, sequela',
  'S14117A Complete lesion at C7 level of cervical spinal cord, initial encounter',
  'S14117D Complete lesion at C7 level of cervical spinal cord, subsequent encounter',
  'S14117S Complete lesion at C7 level of cervical spinal cord, sequela',
  'S14118A Complete lesion at C8 level of cervical spinal cord, initial encounter',
  'S14118D Complete lesion at C8 level of cervical spinal cord, subsequent encounter',
  'S14118S Complete lesion at C8 level of cervical spinal cord, sequela',
  'S14119A Complete lesion at unspecified level of cervical spinal cord, initial encounter',
  'S14119D Complete lesion at unspecified level of cervical spinal cord, subsequent encounter',
  'S14119S Complete lesion at unspecified level of cervical spinal cord, sequela',
  'S14121A Central cord syndrome at C1 level of cervical spinal cord, initial encounter',
  'S14121D Central cord syndrome at C1 level of cervical spinal cord, subsequent encounter',
  'S14121S Central cord syndrome at C1 level of cervical spinal cord, sequela',
  'S14122A Central cord syndrome at C2 level of cervical spinal cord, initial encounter',
  'S14122D Central cord syndrome at C2 level of cervical spinal cord, subsequent encounter',
  'S14122S Central cord syndrome at C2 level of cervical spinal cord, sequela',
  'S14123A Central cord syndrome at C3 level of cervical spinal cord, initial encounter',
  'S14123D Central cord syndrome at C3 level of cervical spinal cord, subsequent encounter',
  'S14123S Central cord syndrome at C3 level of cervical spinal cord, sequela',
  'S14124A Central cord syndrome at C4 level of cervical spinal cord, initial encounter',
  'S14124D Central cord syndrome at C4 level of cervical spinal cord, subsequent encounter',
  'S14124S Central cord syndrome at C4 level of cervical spinal cord, sequela',
  'S14125A Central cord syndrome at C5 level of cervical spinal cord, initial encounter',
  'S14125D Central cord syndrome at C5 level of cervical spinal cord, subsequent encounter',
  'S14125S Central cord syndrome at C5 level of cervical spinal cord, sequela',
  'S14126A Central cord syndrome at C6 level of cervical spinal cord, initial encounter',
  'S14126D Central cord syndrome at C6 level of cervical spinal cord, subsequent encounter',
  'S14126S Central cord syndrome at C6 level of cervical spinal cord, sequela',
  'S14127A Central cord syndrome at C7 level of cervical spinal cord, initial encounter',
  'S14127D Central cord syndrome at C7 level of cervical spinal cord, subsequent encounter',
  'S14127S Central cord syndrome at C7 level of cervical spinal cord, sequela',
  'S14128A Central cord syndrome at C8 level of cervical spinal cord, initial encounter',
  'S14128D Central cord syndrome at C8 level of cervical spinal cord, subsequent encounter',
  'S14128S Central cord syndrome at C8 level of cervical spinal cord, sequela',
  'S14129A Central cord syndrome at unspecified level of cervical spinal cord, initial encounter',
  'S14129D Central cord syndrome at unspecified level of cervical spinal cord, subsequent encounter',
  'S14129S Central cord syndrome at unspecified level of cervical spinal cord, sequela',
  'S14131A Anterior cord syndrome at C1 level of cervical spinal cord, initial encounter',
  'S14131D Anterior cord syndrome at C1 level of cervical spinal cord, subsequent encounter',
  'S14131S Anterior cord syndrome at C1 level of cervical spinal cord, sequela',
  'S14132A Anterior cord syndrome at C2 level of cervical spinal cord, initial encounter',
  'S14132D Anterior cord syndrome at C2 level of cervical spinal cord, subsequent encounter',
  'S14132S Anterior cord syndrome at C2 level of cervical spinal cord, sequela',
  'S14133A Anterior cord syndrome at C3 level of cervical spinal cord, initial encounter',
  'S14133D Anterior cord syndrome at C3 level of cervical spinal cord, subsequent encounter',
  'S14133S Anterior cord syndrome at C3 level of cervical spinal cord, sequela',
  'S14134A Anterior cord syndrome at C4 level of cervical spinal cord, initial encounter',
  'S14134D Anterior cord syndrome at C4 level of cervical spinal cord, subsequent encounter',
  'S14134S Anterior cord syndrome at C4 level of cervical spinal cord, sequela',
  'S14135A Anterior cord syndrome at C5 level of cervical spinal cord, initial encounter',
  'S14135D Anterior cord syndrome at C5 level of cervical spinal cord, subsequent encounter',
  'S14135S Anterior cord syndrome at C5 level of cervical spinal cord, sequela',
  'S14136A Anterior cord syndrome at C6 level of cervical spinal cord, initial encounter',
  'S14136D Anterior cord syndrome at C6 level of cervical spinal cord, subsequent encounter',
  'S14136S Anterior cord syndrome at C6 level of cervical spinal cord, sequela',
  'S14137A Anterior cord syndrome at C7 level of cervical spinal cord, initial encounter',
  'S14137D Anterior cord syndrome at C7 level of cervical spinal cord, subsequent encounter',
  'S14137S Anterior cord syndrome at C7 level of cervical spinal cord, sequela',
  'S14138A Anterior cord syndrome at C8 level of cervical spinal cord, initial encounter',
  'S14138D Anterior cord syndrome at C8 level of cervical spinal cord, subsequent encounter',
  'S14138S Anterior cord syndrome at C8 level of cervical spinal cord, sequela',
  'S14139A Anterior cord syndrome at unspecified level of cervical spinal cord, initial encounter',
  'S14139D Anterior cord syndrome at unspecified level of cervical spinal cord, subsequent encounter',
  'S14139S Anterior cord syndrome at unspecified level of cervical spinal cord, sequela',
  'S14141A Brown-Sequard syndrome at C1 level of cervical spinal cord, initial encounter',
  'S14141D Brown-Sequard syndrome at C1 level of cervical spinal cord, subsequent encounter',
  'S14141S Brown-Sequard syndrome at C1 level of cervical spinal cord, sequela',
  'S14142A Brown-Sequard syndrome at C2 level of cervical spinal cord, initial encounter',
  'S14142D Brown-Sequard syndrome at C2 level of cervical spinal cord, subsequent encounter',
  'S14142S Brown-Sequard syndrome at C2 level of cervical spinal cord, sequela',
  'S14143A Brown-Sequard syndrome at C3 level of cervical spinal cord, initial encounter',
  'S14143D Brown-Sequard syndrome at C3 level of cervical spinal cord, subsequent encounter',
  'S14143S Brown-Sequard syndrome at C3 level of cervical spinal cord, sequela',
  'S14144A Brown-Sequard syndrome at C4 level of cervical spinal cord, initial encounter',
  'S14144D Brown-Sequard syndrome at C4 level of cervical spinal cord, subsequent encounter',
  'S14144S Brown-Sequard syndrome at C4 level of cervical spinal cord, sequela',
  'S14145A Brown-Sequard syndrome at C5 level of cervical spinal cord, initial encounter',
  'S14145D Brown-Sequard syndrome at C5 level of cervical spinal cord, subsequent encounter',
  'S14145S Brown-Sequard syndrome at C5 level of cervical spinal cord, sequela',
  'S14146A Brown-Sequard syndrome at C6 level of cervical spinal cord, initial encounter',
  'S14146D Brown-Sequard syndrome at C6 level of cervical spinal cord, subsequent encounter',
  'S14146S Brown-Sequard syndrome at C6 level of cervical spinal cord, sequela',
  'S14147A Brown-Sequard syndrome at C7 level of cervical spinal cord, initial encounter',
  'S14147D Brown-Sequard syndrome at C7 level of cervical spinal cord, subsequent encounter',
  'S14147S Brown-Sequard syndrome at C7 level of cervical spinal cord, sequela',
  'S14148A Brown-Sequard syndrome at C8 level of cervical spinal cord, initial encounter',
  'S14148D Brown-Sequard syndrome at C8 level of cervical spinal cord, subsequent encounter',
  'S14148S Brown-Sequard syndrome at C8 level of cervical spinal cord, sequela',
  'S14149A Brown-Sequard syndrome at unspecified level of cervical spinal cord, initial encounter',
  'S14149D Brown-Sequard syndrome at unspecified level of cervical spinal cord, subsequent encounter',
  'S14149S Brown-Sequard syndrome at unspecified level of cervical spinal cord, sequela',
  'S14151A Other incomplete lesion at C1 level of cervical spinal cord, initial encounter',
  'S14151D Other incomplete lesion at C1 level of cervical spinal cord, subsequent encounter',
  'S14151S Other incomplete lesion at C1 level of cervical spinal cord, sequela',
  'S14152A Other incomplete lesion at C2 level of cervical spinal cord, initial encounter',
  'S14152D Other incomplete lesion at C2 level of cervical spinal cord, subsequent encounter',
  'S14152S Other incomplete lesion at C2 level of cervical spinal cord, sequela',
  'S14153A Other incomplete lesion at C3 level of cervical spinal cord, initial encounter',
  'S14153D Other incomplete lesion at C3 level of cervical spinal cord, subsequent encounter',
  'S14153S Other incomplete lesion at C3 level of cervical spinal cord, sequela',
  'S14154A Other incomplete lesion at C4 level of cervical spinal cord, initial encounter',
  'S14154D Other incomplete lesion at C4 level of cervical spinal cord, subsequent encounter',
  'S14154S Other incomplete lesion at C4 level of cervical spinal cord, sequela',
  'S14155A Other incomplete lesion at C5 level of cervical spinal cord, initial encounter',
  'S14155D Other incomplete lesion at C5 level of cervical spinal cord, subsequent encounter',
  'S14155S Other incomplete lesion at C5 level of cervical spinal cord, sequela',
  'S14156A Other incomplete lesion at C6 level of cervical spinal cord, initial encounter',
  'S14156D Other incomplete lesion at C6 level of cervical spinal cord, subsequent encounter',
  'S14156S Other incomplete lesion at C6 level of cervical spinal cord, sequela',
  'S14157A Other incomplete lesion at C7 level of cervical spinal cord, initial encounter',
  'S14157D Other incomplete lesion at C7 level of cervical spinal cord, subsequent encounter',
  'S14157S Other incomplete lesion at C7 level of cervical spinal cord, sequela',
  'S14158A Other incomplete lesion at C8 level of cervical spinal cord, initial encounter',
  'S14158D Other incomplete lesion at C8 level of cervical spinal cord, subsequent encounter',
  'S14158S Other incomplete lesion at C8 level of cervical spinal cord, sequela',
  'S14159A Other incomplete lesion at unspecified level of cervical spinal cord, initial encounter',
  'S14159D Other incomplete lesion at unspecified level of cervical spinal cord, subsequent encounter',
  'S14159S Other incomplete lesion at unspecified level of cervical spinal cord, sequela',
  'S142XXA Injury of nerve root of cervical spine, initial encounter',
  'S142XXD Injury of nerve root of cervical spine, subsequent encounter',
  'S142XXS Injury of nerve root of cervical spine, sequela',
  'S143XXA Injury of brachial plexus, initial encounter',
  'S143XXD Injury of brachial plexus, subsequent encounter',
  'S143XXS Injury of brachial plexus, sequela',
  'S144XXA Injury of peripheral nerves of neck, initial encounter',
  'S144XXD Injury of peripheral nerves of neck, subsequent encounter',
  'S144XXS Injury of peripheral nerves of neck, sequela',
  'S145XXA Injury of cervical sympathetic nerves, initial encounter',
  'S145XXD Injury of cervical sympathetic nerves, subsequent encounter',
  'S145XXS Injury of cervical sympathetic nerves, sequela',
  'S148XXA Injury of other specified nerves of neck, initial encounter',
  'S148XXD Injury of other specified nerves of neck, subsequent encounter',
  'S148XXS Injury of other specified nerves of neck, sequela',
  'S149XXA Injury of unspecified nerves of neck, initial encounter',
  'S149XXD Injury of unspecified nerves of neck, subsequent encounter',
  'S149XXS Injury of unspecified nerves of neck, sequela',
  'S15001A Unspecified injury of right carotid artery, initial encounter',
  'S15001D Unspecified injury of right carotid artery, subsequent encounter',
  'S15001S Unspecified injury of right carotid artery, sequela',
  'S15002A Unspecified injury of left carotid artery, initial encounter',
  'S15002D Unspecified injury of left carotid artery, subsequent encounter',
  'S15002S Unspecified injury of left carotid artery, sequela',
  'S15009A Unspecified injury of unspecified carotid artery, initial encounter',
  'S15009D Unspecified injury of unspecified carotid artery, subsequent encounter',
  'S15009S Unspecified injury of unspecified carotid artery, sequela',
  'S15011A Minor laceration of right carotid artery, initial encounter',
  'S15011D Minor laceration of right carotid artery, subsequent encounter',
  'S15011S Minor laceration of right carotid artery, sequela',
  'S15012A Minor laceration of left carotid artery, initial encounter',
  'S15012D Minor laceration of left carotid artery, subsequent encounter',
  'S15012S Minor laceration of left carotid artery, sequela',
  'S15019A Minor laceration of unspecified carotid artery, initial encounter',
  'S15019D Minor laceration of unspecified carotid artery, subsequent encounter',
  'S15019S Minor laceration of unspecified carotid artery, sequela',
  'S15021A Major laceration of right carotid artery, initial encounter',
  'S15021D Major laceration of right carotid artery, subsequent encounter',
  'S15021S Major laceration of right carotid artery, sequela',
  'S15022A Major laceration of left carotid artery, initial encounter',
  'S15022D Major laceration of left carotid artery, subsequent encounter',
  'S15022S Major laceration of left carotid artery, sequela',
  'S15029A Major laceration of unspecified carotid artery, initial encounter',
  'S15029D Major laceration of unspecified carotid artery, subsequent encounter',
  'S15029S Major laceration of unspecified carotid artery, sequela',
  'S15091A Other specified injury of right carotid artery, initial encounter',
  'S15091D Other specified injury of right carotid artery, subsequent encounter',
  'S15091S Other specified injury of right carotid artery, sequela',
  'S15092A Other specified injury of left carotid artery, initial encounter',
  'S15092D Other specified injury of left carotid artery, subsequent encounter',
  'S15092S Other specified injury of left carotid artery, sequela',
  'S15099A Other specified injury of unspecified carotid artery, initial encounter',
  'S15099D Other specified injury of unspecified carotid artery, subsequent encounter',
  'S15099S Other specified injury of unspecified carotid artery, sequela',
  'S15101A Unspecified injury of right vertebral artery, initial encounter',
  'S15101D Unspecified injury of right vertebral artery, subsequent encounter',
  'S15101S Unspecified injury of right vertebral artery, sequela',
  'S15102A Unspecified injury of left vertebral artery, initial encounter',
  'S15102D Unspecified injury of left vertebral artery, subsequent encounter',
  'S15102S Unspecified injury of left vertebral artery, sequela',
  'S15109A Unspecified injury of unspecified vertebral artery, initial encounter',
  'S15109D Unspecified injury of unspecified vertebral artery, subsequent encounter',
  'S15109S Unspecified injury of unspecified vertebral artery, sequela',
  'S15111A Minor laceration of right vertebral artery, initial encounter',
  'S15111D Minor laceration of right vertebral artery, subsequent encounter',
  'S15111S Minor laceration of right vertebral artery, sequela',
  'S15112A Minor laceration of left vertebral artery, initial encounter',
  'S15112D Minor laceration of left vertebral artery, subsequent encounter',
  'S15112S Minor laceration of left vertebral artery, sequela',
  'S15119A Minor laceration of unspecified vertebral artery, initial encounter',
  'S15119D Minor laceration of unspecified vertebral artery, subsequent encounter',
  'S15119S Minor laceration of unspecified vertebral artery, sequela',
  'S15121A Major laceration of right vertebral artery, initial encounter',
  'S15121D Major laceration of right vertebral artery, subsequent encounter',
  'S15121S Major laceration of right vertebral artery, sequela',
  'S15122A Major laceration of left vertebral artery, initial encounter',
  'S15122D Major laceration of left vertebral artery, subsequent encounter',
  'S15122S Major laceration of left vertebral artery, sequela',
  'S15129A Major laceration of unspecified vertebral artery, initial encounter',
  'S15129D Major laceration of unspecified vertebral artery, subsequent encounter',
  'S15129S Major laceration of unspecified vertebral artery, sequela',
  'S15191A Other specified injury of right vertebral artery, initial encounter',
  'S15191D Other specified injury of right vertebral artery, subsequent encounter',
  'S15191S Other specified injury of right vertebral artery, sequela',
  'S15192A Other specified injury of left vertebral artery, initial encounter',
  'S15192D Other specified injury of left vertebral artery, subsequent encounter',
  'S15192S Other specified injury of left vertebral artery, sequela',
  'S15199A Other specified injury of unspecified vertebral artery, initial encounter',
  'S15199D Other specified injury of unspecified vertebral artery, subsequent encounter',
  'S15199S Other specified injury of unspecified vertebral artery, sequela',
  'S15201A Unspecified injury of right external jugular vein, initial encounter',
  'S15201D Unspecified injury of right external jugular vein, subsequent encounter',
  'S15201S Unspecified injury of right external jugular vein, sequela',
  'S15202A Unspecified injury of left external jugular vein, initial encounter',
  'S15202D Unspecified injury of left external jugular vein, subsequent encounter',
  'S15202S Unspecified injury of left external jugular vein, sequela',
  'S15209A Unspecified injury of unspecified external jugular vein, initial encounter',
  'S15209D Unspecified injury of unspecified external jugular vein, subsequent encounter',
  'S15209S Unspecified injury of unspecified external jugular vein, sequela',
  'S15211A Minor laceration of right external jugular vein, initial encounter',
  'S15211D Minor laceration of right external jugular vein, subsequent encounter',
  'S15211S Minor laceration of right external jugular vein, sequela',
  'S15212A Minor laceration of left external jugular vein, initial encounter',
  'S15212D Minor laceration of left external jugular vein, subsequent encounter',
  'S15212S Minor laceration of left external jugular vein, sequela',
  'S15219A Minor laceration of unspecified external jugular vein, initial encounter',
  'S15219D Minor laceration of unspecified external jugular vein, subsequent encounter',
  'S15219S Minor laceration of unspecified external jugular vein, sequela',
  'S15221A Major laceration of right external jugular vein, initial encounter',
  'S15221D Major laceration of right external jugular vein, subsequent encounter',
  'S15221S Major laceration of right external jugular vein, sequela',
  'S15222A Major laceration of left external jugular vein, initial encounter',
  'S15222D Major laceration of left external jugular vein, subsequent encounter',
  'S15222S Major laceration of left external jugular vein, sequela',
  'S15229A Major laceration of unspecified external jugular vein, initial encounter',
  'S15229D Major laceration of unspecified external jugular vein, subsequent encounter',
  'S15229S Major laceration of unspecified external jugular vein, sequela',
  'S15291A Other specified injury of right external jugular vein, initial encounter',
  'S15291D Other specified injury of right external jugular vein, subsequent encounter',
  'S15291S Other specified injury of right external jugular vein, sequela',
  'S15292A Other specified injury of left external jugular vein, initial encounter',
  'S15292D Other specified injury of left external jugular vein, subsequent encounter',
  'S15292S Other specified injury of left external jugular vein, sequela',
  'S15299A Other specified injury of unspecified external jugular vein, initial encounter',
  'S15299D Other specified injury of unspecified external jugular vein, subsequent encounter',
  'S15299S Other specified injury of unspecified external jugular vein, sequela',
  'S15301A Unspecified injury of right internal jugular vein, initial encounter',
  'S15301D Unspecified injury of right internal jugular vein, subsequent encounter',
  'S15301S Unspecified injury of right internal jugular vein, sequela',
  'S15302A Unspecified injury of left internal jugular vein, initial encounter',
  'S15302D Unspecified injury of left internal jugular vein, subsequent encounter',
  'S15302S Unspecified injury of left internal jugular vein, sequela',
  'S15309A Unspecified injury of unspecified internal jugular vein, initial encounter',
  'S15309D Unspecified injury of unspecified internal jugular vein, subsequent encounter',
  'S15309S Unspecified injury of unspecified internal jugular vein, sequela',
  'S15311A Minor laceration of right internal jugular vein, initial encounter',
  'S15311D Minor laceration of right internal jugular vein, subsequent encounter',
  'S15311S Minor laceration of right internal jugular vein, sequela',
  'S15312A Minor laceration of left internal jugular vein, initial encounter',
  'S15312D Minor laceration of left internal jugular vein, subsequent encounter',
  'S15312S Minor laceration of left internal jugular vein, sequela',
  'S15319A Minor laceration of unspecified internal jugular vein, initial encounter',
  'S15319D Minor laceration of unspecified internal jugular vein, subsequent encounter',
  'S15319S Minor laceration of unspecified internal jugular vein, sequela',
  'S15321A Major laceration of right internal jugular vein, initial encounter',
  'S15321D Major laceration of right internal jugular vein, subsequent encounter',
  'S15321S Major laceration of right internal jugular vein, sequela',
  'S15322A Major laceration of left internal jugular vein, initial encounter',
  'S15322D Major laceration of left internal jugular vein, subsequent encounter',
  'S15322S Major laceration of left internal jugular vein, sequela',
  'S15329A Major laceration of unspecified internal jugular vein, initial encounter',
  'S15329D Major laceration of unspecified internal jugular vein, subsequent encounter',
  'S15329S Major laceration of unspecified internal jugular vein, sequela',
  'S15391A Other specified injury of right internal jugular vein, initial encounter',
  'S15391D Other specified injury of right internal jugular vein, subsequent encounter',
  'S15391S Other specified injury of right internal jugular vein, sequela',
  'S15392A Other specified injury of left internal jugular vein, initial encounter',
  'S15392D Other specified injury of left internal jugular vein, subsequent encounter',
  'S15392S Other specified injury of left internal jugular vein, sequela',
  'S15399A Other specified injury of unspecified internal jugular vein, initial encounter',
  'S15399D Other specified injury of unspecified internal jugular vein, subsequent encounter',
  'S15399S Other specified injury of unspecified internal jugular vein, sequela',
  'S158XXA Injury of other specified blood vessels at neck level, initial encounter',
  'S158XXD Injury of other specified blood vessels at neck level, subsequent encounter',
  'S158XXS Injury of other specified blood vessels at neck level, sequela',
  'S159XXA Injury of unspecified blood vessel at neck level, initial encounter',
  'S159XXD Injury of unspecified blood vessel at neck level, subsequent encounter',
  'S159XXS Injury of unspecified blood vessel at neck level, sequela',
  'S161XXA Strain of muscle, fascia and tendon at neck level, initial encounter',
  'S161XXD Strain of muscle, fascia and tendon at neck level, subsequent encounter',
  'S161XXS Strain of muscle, fascia and tendon at neck level, sequela',
  'S162XXA Laceration of muscle, fascia and tendon at neck level, initial encounter',
  'S162XXD Laceration of muscle, fascia and tendon at neck level, subsequent encounter',
  'S162XXS Laceration of muscle, fascia and tendon at neck level, sequela',
  'S168XXA Other specified injury of muscle, fascia and tendon at neck level, initial encounter',
  'S168XXD Other specified injury of muscle, fascia and tendon at neck level, subsequent encounter',
  'S168XXS Other specified injury of muscle, fascia and tendon at neck level, sequela',
  'S169XXA Unspecified injury of muscle, fascia and tendon at neck level, initial encounter',
  'S169XXD Unspecified injury of muscle, fascia and tendon at neck level, subsequent encounter',
  'S169XXS Unspecified injury of muscle, fascia and tendon at neck level, sequela',
  'S170XXA Crushing injury of larynx and trachea, initial encounter',
  'S170XXD Crushing injury of larynx and trachea, subsequent encounter',
  'S170XXS Crushing injury of larynx and trachea, sequela',
  'S178XXA Crushing injury of other specified parts of neck, initial encounter',
  'S178XXD Crushing injury of other specified parts of neck, subsequent encounter',
  'S178XXS Crushing injury of other specified parts of neck, sequela',
  'S179XXA Crushing injury of neck, part unspecified, initial encounter',
  'S179XXD Crushing injury of neck, part unspecified, subsequent encounter',
  'S179XXS Crushing injury of neck, part unspecified, sequela',
  'S1980XA Other specified injuries of unspecified part of neck, initial encounter',
  'S1980XD Other specified injuries of unspecified part of neck, subsequent encounter',
  'S1980XS Other specified injuries of unspecified part of neck, sequela',
  'S1981XA Other specified injuries of larynx, initial encounter',
  'S1981XD Other specified injuries of larynx, subsequent encounter',
  'S1981XS Other specified injuries of larynx, sequela',
  'S1982XA Other specified injuries of cervical trachea, initial encounter',
  'S1982XD Other specified injuries of cervical trachea, subsequent encounter',
  'S1982XS Other specified injuries of cervical trachea, sequela',
  'S1983XA Other specified injuries of vocal cord, initial encounter',
  'S1983XD Other specified injuries of vocal cord, subsequent encounter',
  'S1983XS Other specified injuries of vocal cord, sequela',
  'S1984XA Other specified injuries of thyroid gland, initial encounter',
  'S1984XD Other specified injuries of thyroid gland, subsequent encounter',
  'S1984XS Other specified injuries of thyroid gland, sequela',
  'S1985XA Other specified injuries of pharynx and cervical esophagus, initial encounter',
  'S1985XD Other specified injuries of pharynx and cervical esophagus, subsequent encounter',
  'S1985XS Other specified injuries of pharynx and cervical esophagus, sequela',
  'S1989XA Other specified injuries of other specified part of neck, initial encounter',
  'S1989XD Other specified injuries of other specified part of neck, subsequent encounter',
  'S1989XS Other specified injuries of other specified part of neck, sequela',
  'S199XXA Unspecified injury of neck, initial encounter',
  'S199XXD Unspecified injury of neck, subsequent encounter',
  'S199XXS Unspecified injury of neck, sequela',
  'S2000XA Contusion of breast, unspecified breast, initial encounter',
  'S2000XD Contusion of breast, unspecified breast, subsequent encounter',
  'S2000XS Contusion of breast, unspecified breast, sequela',
  'S2001XA Contusion of right breast, initial encounter',
  'S2001XD Contusion of right breast, subsequent encounter',
  'S2001XS Contusion of right breast, sequela',
  'S2002XA Contusion of left breast, initial encounter',
  'S2002XD Contusion of left breast, subsequent encounter',
  'S2002XS Contusion of left breast, sequela',
  'S20101A Unspecified superficial injuries of breast, right breast, initial encounter',
  'S20101D Unspecified superficial injuries of breast, right breast, subsequent encounter',
  'S20101S Unspecified superficial injuries of breast, right breast, sequela',
  'S20102A Unspecified superficial injuries of breast, left breast, initial encounter',
  'S20102D Unspecified superficial injuries of breast, left breast, subsequent encounter',
  'S20102S Unspecified superficial injuries of breast, left breast, sequela',
  'S20109A Unspecified superficial injuries of breast, unspecified breast, initial encounter',
  'S20109D Unspecified superficial injuries of breast, unspecified breast, subsequent encounter',
  'S20109S Unspecified superficial injuries of breast, unspecified breast, sequela',
  'S20111A Abrasion of breast, right breast, initial encounter',
  'S20111D Abrasion of breast, right breast, subsequent encounter',
  'S20111S Abrasion of breast, right breast, sequela',
  'S20112A Abrasion of breast, left breast, initial encounter',
  'S20112D Abrasion of breast, left breast, subsequent encounter',
  'S20112S Abrasion of breast, left breast, sequela',
  'S20119A Abrasion of breast, unspecified breast, initial encounter',
  'S20119D Abrasion of breast, unspecified breast, subsequent encounter',
  'S20119S Abrasion of breast, unspecified breast, sequela',
  'S20121A Blister (nonthermal) of breast, right breast, initial encounter',
  'S20121D Blister (nonthermal) of breast, right breast, subsequent encounter',
  'S20121S Blister (nonthermal) of breast, right breast, sequela',
  'S20122A Blister (nonthermal) of breast, left breast, initial encounter',
  'S20122D Blister (nonthermal) of breast, left breast, subsequent encounter',
  'S20122S Blister (nonthermal) of breast, left breast, sequela',
  'S20129A Blister (nonthermal) of breast, unspecified breast, initial encounter',
  'S20129D Blister (nonthermal) of breast, unspecified breast, subsequent encounter',
  'S20129S Blister (nonthermal) of breast, unspecified breast, sequela',
  'S20141A External constriction of part of breast, right breast, initial encounter',
  'S20141D External constriction of part of breast, right breast, subsequent encounter',
  'S20141S External constriction of part of breast, right breast, sequela',
  'S20142A External constriction of part of breast, left breast, initial encounter',
  'S20142D External constriction of part of breast, left breast, subsequent encounter',
  'S20142S External constriction of part of breast, left breast, sequela',
  'S20149A External constriction of part of breast, unspecified breast, initial encounter',
  'S20149D External constriction of part of breast, unspecified breast, subsequent encounter',
  'S20149S External constriction of part of breast, unspecified breast, sequela',
  'S20151A Superficial foreign body of breast, right breast, initial encounter',
  'S20151D Superficial foreign body of breast, right breast, subsequent encounter',
  'S20151S Superficial foreign body of breast, right breast, sequela',
  'S20152A Superficial foreign body of breast, left breast, initial encounter',
  'S20152D Superficial foreign body of breast, left breast, subsequent encounter',
  'S20152S Superficial foreign body of breast, left breast, sequela',
  'S20159A Superficial foreign body of breast, unspecified breast, initial encounter',
  'S20159D Superficial foreign body of breast, unspecified breast, subsequent encounter',
  'S20159S Superficial foreign body of breast, unspecified breast, sequela',
  'S20161A Insect bite (nonvenomous) of breast, right breast, initial encounter',
  'S20161D Insect bite (nonvenomous) of breast, right breast, subsequent encounter',
  'S20161S Insect bite (nonvenomous) of breast, right breast, sequela',
  'S20162A Insect bite (nonvenomous) of breast, left breast, initial encounter',
  'S20162D Insect bite (nonvenomous) of breast, left breast, subsequent encounter',
  'S20162S Insect bite (nonvenomous) of breast, left breast, sequela',
  'S20169A Insect bite (nonvenomous) of breast, unspecified breast, initial encounter',
  'S20169D Insect bite (nonvenomous) of breast, unspecified breast, subsequent encounter',
  'S20169S Insect bite (nonvenomous) of breast, unspecified breast, sequela',
  'S20171A Other superficial bite of breast, right breast, initial encounter',
  'S20171D Other superficial bite of breast, right breast, subsequent encounter',
  'S20171S Other superficial bite of breast, right breast, sequela',
  'S20172A Other superficial bite of breast, left breast, initial encounter',
  'S20172D Other superficial bite of breast, left breast, subsequent encounter',
  'S20172S Other superficial bite of breast, left breast, sequela',
  'S20179A Other superficial bite of breast, unspecified breast, initial encounter',
  'S20179D Other superficial bite of breast, unspecified breast, subsequent encounter',
  'S20179S Other superficial bite of breast, unspecified breast, sequela',
  'S2020XA Contusion of thorax, unspecified, initial encounter',
  'S2020XD Contusion of thorax, unspecified, subsequent encounter',
  'S2020XS Contusion of thorax, unspecified, sequela',
  'S20211A Contusion of right front wall of thorax, initial encounter',
  'S20211D Contusion of right front wall of thorax, subsequent encounter',
  'S20211S Contusion of right front wall of thorax, sequela',
  'S20212A Contusion of left front wall of thorax, initial encounter',
  'S20212D Contusion of left front wall of thorax, subsequent encounter',
  'S20212S Contusion of left front wall of thorax, sequela',
  'S20219A Contusion of unspecified front wall of thorax, initial encounter',
  'S20219D Contusion of unspecified front wall of thorax, subsequent encounter',
  'S20219S Contusion of unspecified front wall of thorax, sequela',
  'S20221A Contusion of right back wall of thorax, initial encounter',
  'S20221D Contusion of right back wall of thorax, subsequent encounter',
  'S20221S Contusion of right back wall of thorax, sequela',
  'S20222A Contusion of left back wall of thorax, initial encounter',
  'S20222D Contusion of left back wall of thorax, subsequent encounter',
  'S20222S Contusion of left back wall of thorax, sequela',
  'S20229A Contusion of unspecified back wall of thorax, initial encounter',
  'S20229D Contusion of unspecified back wall of thorax, subsequent encounter',
  'S20229S Contusion of unspecified back wall of thorax, sequela',
  'S20301A Unspecified superficial injuries of right front wall of thorax, initial encounter',
  'S20301D Unspecified superficial injuries of right front wall of thorax, subsequent encounter',
  'S20301S Unspecified superficial injuries of right front wall of thorax, sequela',
  'S20302A Unspecified superficial injuries of left front wall of thorax, initial encounter',
  'S20302D Unspecified superficial injuries of left front wall of thorax, subsequent encounter',
  'S20302S Unspecified superficial injuries of left front wall of thorax, sequela',
  'S20309A Unspecified superficial injuries of unspecified front wall of thorax, initial encounter',
  'S20309D Unspecified superficial injuries of unspecified front wall of thorax, subsequent encounter',
  'S20309S Unspecified superficial injuries of unspecified front wall of thorax, sequela',
  'S20311A Abrasion of right front wall of thorax, initial encounter',
  'S20311D Abrasion of right front wall of thorax, subsequent encounter',
  'S20311S Abrasion of right front wall of thorax, sequela',
  'S20312A Abrasion of left front wall of thorax, initial encounter',
  'S20312D Abrasion of left front wall of thorax, subsequent encounter',
  'S20312S Abrasion of left front wall of thorax, sequela',
  'S20319A Abrasion of unspecified front wall of thorax, initial encounter',
  'S20319D Abrasion of unspecified front wall of thorax, subsequent encounter',
  'S20319S Abrasion of unspecified front wall of thorax, sequela',
  'S20321A Blister (nonthermal) of right front wall of thorax, initial encounter',
  'S20321D Blister (nonthermal) of right front wall of thorax, subsequent encounter',
  'S20321S Blister (nonthermal) of right front wall of thorax, sequela',
  'S20322A Blister (nonthermal) of left front wall of thorax, initial encounter',
  'S20322D Blister (nonthermal) of left front wall of thorax, subsequent encounter',
  'S20322S Blister (nonthermal) of left front wall of thorax, sequela',
  'S20329A Blister (nonthermal) of unspecified front wall of thorax, initial encounter',
  'S20329D Blister (nonthermal) of unspecified front wall of thorax, subsequent encounter',
  'S20329S Blister (nonthermal) of unspecified front wall of thorax, sequela',
  'S20341A External constriction of right front wall of thorax, initial encounter',
  'S20341D External constriction of right front wall of thorax, subsequent encounter',
  'S20341S External constriction of right front wall of thorax, sequela',
  'S20342A External constriction of left front wall of thorax, initial encounter',
  'S20342D External constriction of left front wall of thorax, subsequent encounter',
  'S20342S External constriction of left front wall of thorax, sequela',
  'S20349A External constriction of unspecified front wall of thorax, initial encounter',
  'S20349D External constriction of unspecified front wall of thorax, subsequent encounter',
  'S20349S External constriction of unspecified front wall of thorax, sequela',
  'S20351A Superficial foreign body of right front wall of thorax, initial encounter',
  'S20351D Superficial foreign body of right front wall of thorax, subsequent encounter',
  'S20351S Superficial foreign body of right front wall of thorax, sequela',
  'S20352A Superficial foreign body of left front wall of thorax, initial encounter',
  'S20352D Superficial foreign body of left front wall of thorax, subsequent encounter',
  'S20352S Superficial foreign body of left front wall of thorax, sequela',
  'S20359A Superficial foreign body of unspecified front wall of thorax, initial encounter',
  'S20359D Superficial foreign body of unspecified front wall of thorax, subsequent encounter',
  'S20359S Superficial foreign body of unspecified front wall of thorax, sequela',
  'S20361A Insect bite (nonvenomous) of right front wall of thorax, initial encounter',
  'S20361D Insect bite (nonvenomous) of right front wall of thorax, subsequent encounter',
  'S20361S Insect bite (nonvenomous) of right front wall of thorax, sequela',
  'S20362A Insect bite (nonvenomous) of left front wall of thorax, initial encounter',
  'S20362D Insect bite (nonvenomous) of left front wall of thorax, subsequent encounter',
  'S20362S Insect bite (nonvenomous) of left front wall of thorax, sequela',
  'S20369A Insect bite (nonvenomous) of unspecified front wall of thorax, initial encounter',
  'S20369D Insect bite (nonvenomous) of unspecified front wall of thorax, subsequent encounter',
  'S20369S Insect bite (nonvenomous) of unspecified front wall of thorax, sequela',
  'S20371A Other superficial bite of right front wall of thorax, initial encounter',
  'S20371D Other superficial bite of right front wall of thorax, subsequent encounter',
  'S20371S Other superficial bite of right front wall of thorax, sequela',
  'S20372A Other superficial bite of left front wall of thorax, initial encounter',
  'S20372D Other superficial bite of left front wall of thorax, subsequent encounter',
  'S20372S Other superficial bite of left front wall of thorax, sequela',
  'S20379A Other superficial bite of unspecified front wall of thorax, initial encounter',
  'S20379D Other superficial bite of unspecified front wall of thorax, subsequent encounter',
  'S20379S Other superficial bite of unspecified front wall of thorax, sequela',
  'S20401A Unspecified superficial injuries of right back wall of thorax, initial encounter',
  'S20401D Unspecified superficial injuries of right back wall of thorax, subsequent encounter',
  'S20401S Unspecified superficial injuries of right back wall of thorax, sequela',
  'S20402A Unspecified superficial injuries of left back wall of thorax, initial encounter',
  'S20402D Unspecified superficial injuries of left back wall of thorax, subsequent encounter',
  'S20402S Unspecified superficial injuries of left back wall of thorax, sequela',
  'S20409A Unspecified superficial injuries of unspecified back wall of thorax, initial encounter',
  'S20409D Unspecified superficial injuries of unspecified back wall of thorax, subsequent encounter',
  'S20409S Unspecified superficial injuries of unspecified back wall of thorax, sequela',
  'S20411A Abrasion of right back wall of thorax, initial encounter',
  'S20411D Abrasion of right back wall of thorax, subsequent encounter',
  'S20411S Abrasion of right back wall of thorax, sequela',
  'S20412A Abrasion of left back wall of thorax, initial encounter',
  'S20412D Abrasion of left back wall of thorax, subsequent encounter',
  'S20412S Abrasion of left back wall of thorax, sequela',
  'S20419A Abrasion of unspecified back wall of thorax, initial encounter',
  'S20419D Abrasion of unspecified back wall of thorax, subsequent encounter',
  'S20419S Abrasion of unspecified back wall of thorax, sequela',
  'S20421A Blister (nonthermal) of right back wall of thorax, initial encounter',
  'S20421D Blister (nonthermal) of right back wall of thorax, subsequent encounter',
  'S20421S Blister (nonthermal) of right back wall of thorax, sequela',
  'S20422A Blister (nonthermal) of left back wall of thorax, initial encounter',
  'S20422D Blister (nonthermal) of left back wall of thorax, subsequent encounter',
  'S20422S Blister (nonthermal) of left back wall of thorax, sequela',
  'S20429A Blister (nonthermal) of unspecified back wall of thorax, initial encounter',
  'S20429D Blister (nonthermal) of unspecified back wall of thorax, subsequent encounter',
  'S20429S Blister (nonthermal) of unspecified back wall of thorax, sequela',
  'S20441A External constriction of right back wall of thorax, initial encounter',
  'S20441D External constriction of right back wall of thorax, subsequent encounter',
  'S20441S External constriction of right back wall of thorax, sequela',
  'S20442A External constriction of left back wall of thorax, initial encounter',
  'S20442D External constriction of left back wall of thorax, subsequent encounter',
  'S20442S External constriction of left back wall of thorax, sequela',
  'S20449A External constriction of unspecified back wall of thorax, initial encounter',
  'S20449D External constriction of unspecified back wall of thorax, subsequent encounter',
  'S20449S External constriction of unspecified back wall of thorax, sequela',
  'S20451A Superficial foreign body of right back wall of thorax, initial encounter',
  'S20451D Superficial foreign body of right back wall of thorax, subsequent encounter',
  'S20451S Superficial foreign body of right back wall of thorax, sequela',
  'S20452A Superficial foreign body of left back wall of thorax, initial encounter',
  'S20452D Superficial foreign body of left back wall of thorax, subsequent encounter',
  'S20452S Superficial foreign body of left back wall of thorax, sequela',
  'S20459A Superficial foreign body of unspecified back wall of thorax, initial encounter',
  'S20459D Superficial foreign body of unspecified back wall of thorax, subsequent encounter',
  'S20459S Superficial foreign body of unspecified back wall of thorax, sequela',
  'S20461A Insect bite (nonvenomous) of right back wall of thorax, initial encounter',
  'S20461D Insect bite (nonvenomous) of right back wall of thorax, subsequent encounter',
  'S20461S Insect bite (nonvenomous) of right back wall of thorax, sequela',
  'S20462A Insect bite (nonvenomous) of left back wall of thorax, initial encounter',
  'S20462D Insect bite (nonvenomous) of left back wall of thorax, subsequent encounter',
  'S20462S Insect bite (nonvenomous) of left back wall of thorax, sequela',
  'S20469A Insect bite (nonvenomous) of unspecified back wall of thorax, initial encounter',
  'S20469D Insect bite (nonvenomous) of unspecified back wall of thorax, subsequent encounter',
  'S20469S Insect bite (nonvenomous) of unspecified back wall of thorax, sequela',
  'S20471A Other superficial bite of right back wall of thorax, initial encounter',
  'S20471D Other superficial bite of right back wall of thorax, subsequent encounter',
  'S20471S Other superficial bite of right back wall of thorax, sequela',
  'S20472A Other superficial bite of left back wall of thorax, initial encounter',
  'S20472D Other superficial bite of left back wall of thorax, subsequent encounter',
  'S20472S Other superficial bite of left back wall of thorax, sequela',
  'S20479A Other superficial bite of unspecified back wall of thorax, initial encounter',
  'S20479D Other superficial bite of unspecified back wall of thorax, subsequent encounter',
  'S20479S Other superficial bite of unspecified back wall of thorax, sequela',
  'S2090XA Unspecified superficial injury of unspecified parts of thorax, initial encounter',
  'S2090XD Unspecified superficial injury of unspecified parts of thorax, subsequent encounter',
  'S2090XS Unspecified superficial injury of unspecified parts of thorax, sequela',
  'S2091XA Abrasion of unspecified parts of thorax, initial encounter',
  'S2091XD Abrasion of unspecified parts of thorax, subsequent encounter',
  'S2091XS Abrasion of unspecified parts of thorax, sequela',
  'S2092XA Blister (nonthermal) of unspecified parts of thorax, initial encounter',
  'S2092XD Blister (nonthermal) of unspecified parts of thorax, subsequent encounter',
  'S2092XS Blister (nonthermal) of unspecified parts of thorax, sequela',
  'S2094XA External constriction of unspecified parts of thorax, initial encounter',
  'S2094XD External constriction of unspecified parts of thorax, subsequent encounter',
  'S2094XS External constriction of unspecified parts of thorax, sequela',
  'S2095XA Superficial foreign body of unspecified parts of thorax, initial encounter',
  'S2095XD Superficial foreign body of unspecified parts of thorax, subsequent encounter',
  'S2095XS Superficial foreign body of unspecified parts of thorax, sequela',
  'S2096XA Insect bite (nonvenomous) of unspecified parts of thorax, initial encounter',
  'S2096XD Insect bite (nonvenomous) of unspecified parts of thorax, subsequent encounter',
  'S2096XS Insect bite (nonvenomous) of unspecified parts of thorax, sequela',
  'S2097XA Other superficial bite of unspecified parts of thorax, initial encounter',
  'S2097XD Other superficial bite of unspecified parts of thorax, subsequent encounter',
  'S2097XS Other superficial bite of unspecified parts of thorax, sequela',
  'S21001A Unspecified open wound of right breast, initial encounter',
  'S21001D Unspecified open wound of right breast, subsequent encounter',
  'S21001S Unspecified open wound of right breast, sequela',
  'S21002A Unspecified open wound of left breast, initial encounter',
  'S21002D Unspecified open wound of left breast, subsequent encounter',
  'S21002S Unspecified open wound of left breast, sequela',
  'S21009A Unspecified open wound of unspecified breast, initial encounter',
  'S21009D Unspecified open wound of unspecified breast, subsequent encounter',
  'S21009S Unspecified open wound of unspecified breast, sequela',
  'S21011A Laceration without foreign body of right breast, initial encounter',
  'S21011D Laceration without foreign body of right breast, subsequent encounter',
  'S21011S Laceration without foreign body of right breast, sequela',
  'S21012A Laceration without foreign body of left breast, initial encounter',
  'S21012D Laceration without foreign body of left breast, subsequent encounter',
  'S21012S Laceration without foreign body of left breast, sequela',
  'S21019A Laceration without foreign body of unspecified breast, initial encounter',
  'S21019D Laceration without foreign body of unspecified breast, subsequent encounter',
  'S21019S Laceration without foreign body of unspecified breast, sequela',
  'S21021A Laceration with foreign body of right breast, initial encounter',
  'S21021D Laceration with foreign body of right breast, subsequent encounter',
  'S21021S Laceration with foreign body of right breast, sequela',
  'S21022A Laceration with foreign body of left breast, initial encounter',
  'S21022D Laceration with foreign body of left breast, subsequent encounter',
  'S21022S Laceration with foreign body of left breast, sequela',
  'S21029A Laceration with foreign body of unspecified breast, initial encounter',
  'S21029D Laceration with foreign body of unspecified breast, subsequent encounter',
  'S21029S Laceration with foreign body of unspecified breast, sequela',
  'S21031A Puncture wound without foreign body of right breast, initial encounter',
  'S21031D Puncture wound without foreign body of right breast, subsequent encounter',
  'S21031S Puncture wound without foreign body of right breast, sequela',
  'S21032A Puncture wound without foreign body of left breast, initial encounter',
  'S21032D Puncture wound without foreign body of left breast, subsequent encounter',
  'S21032S Puncture wound without foreign body of left breast, sequela',
  'S21039A Puncture wound without foreign body of unspecified breast, initial encounter',
  'S21039D Puncture wound without foreign body of unspecified breast, subsequent encounter',
  'S21039S Puncture wound without foreign body of unspecified breast, sequela',
  'S21041A Puncture wound with foreign body of right breast, initial encounter',
  'S21041D Puncture wound with foreign body of right breast, subsequent encounter',
  'S21041S Puncture wound with foreign body of right breast, sequela',
  'S21042A Puncture wound with foreign body of left breast, initial encounter',
  'S21042D Puncture wound with foreign body of left breast, subsequent encounter',
  'S21042S Puncture wound with foreign body of left breast, sequela',
  'S21049A Puncture wound with foreign body of unspecified breast, initial encounter',
  'S21049D Puncture wound with foreign body of unspecified breast, subsequent encounter',
  'S21049S Puncture wound with foreign body of unspecified breast, sequela',
  'S21051A Open bite of right breast, initial encounter',
  'S21051D Open bite of right breast, subsequent encounter',
  'S21051S Open bite of right breast, sequela',
  'S21052A Open bite of left breast, initial encounter',
  'S21052D Open bite of left breast, subsequent encounter',
  'S21052S Open bite of left breast, sequela',
  'S21059A Open bite of unspecified breast, initial encounter',
  'S21059D Open bite of unspecified breast, subsequent encounter',
  'S21059S Open bite of unspecified breast, sequela',
  'S21101A Unspecified open wound of right front wall of thorax without penetration into thoracic cavity,',
  'initial encounter',
  'S21101D Unspecified open wound of right front wall of thorax without penetration into thoracic cavity,',
  'subsequent encounter',
  'S21101S Unspecified open wound of right front wall of thorax without penetration into thoracic cavity,',
  'sequela',
  'S21102A Unspecified open wound of left front wall of thorax without penetration into thoracic cavity,',
  'initial encounter',
  'S21102D Unspecified open wound of left front wall of thorax without penetration into thoracic cavity,',
  'subsequent encounter',
  'S21102S Unspecified open wound of left front wall of thorax without penetration into thoracic cavity,',
  'sequela',
  'S21109A Unspecified open wound of unspecified front wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21109D Unspecified open wound of unspecified front wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21109S Unspecified open wound of unspecified front wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21111A Laceration without foreign body of right front wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21111D Laceration without foreign body of right front wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21111S Laceration without foreign body of right front wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21112A Laceration without foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21112D Laceration without foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21112S Laceration without foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21119A Laceration without foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21119D Laceration without foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21119S Laceration without foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21121A Laceration with foreign body of right front wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21121D Laceration with foreign body of right front wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21121S Laceration with foreign body of right front wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21122A Laceration with foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21122D Laceration with foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21122S Laceration with foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21129A Laceration with foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21129D Laceration with foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21129S Laceration with foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21131A Puncture wound without foreign body of right front wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21131D Puncture wound without foreign body of right front wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21131S Puncture wound without foreign body of right front wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21132A Puncture wound without foreign body of left front wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21132D Puncture wound without foreign body of left front wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21132S Puncture wound without foreign body of left front wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21139A Puncture wound without foreign body of unspecified front wall of thorax without penetration',
  'into thoracic cavity, initial encounter',
  'S21139D Puncture wound without foreign body of unspecified front wall of thorax without penetration',
  'into thoracic cavity, subsequent encounter',
  'S21139S Puncture wound without foreign body of unspecified front wall of thorax without penetration',
  'into thoracic cavity, sequela',
  'S21141A Puncture wound with foreign body of right front wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21141D Puncture wound with foreign body of right front wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21141S Puncture wound with foreign body of right front wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21142A Puncture wound with foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21142D Puncture wound with foreign body of left front wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21142S Puncture wound with foreign body of left front wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21149A Puncture wound with foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21149D Puncture wound with foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21149S Puncture wound with foreign body of unspecified front wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21151A Open bite of right front wall of thorax without penetration into thoracic cavity, initial',
  'encounter',
  'S21151D Open bite of right front wall of thorax without penetration into thoracic cavity, subsequent',
  'encounter',
  'S21151S Open bite of right front wall of thorax without penetration into thoracic cavity, sequela',
  'S21152A Open bite of left front wall of thorax without penetration into thoracic cavity, initial encounter',
  'S21152D Open bite of left front wall of thorax without penetration into thoracic cavity, subsequent',
  'encounter',
  'S21152S Open bite of left front wall of thorax without penetration into thoracic cavity, sequela',
  'S21159A Open bite of unspecified front wall of thorax without penetration into thoracic cavity, initial',
  'encounter',
  'S21159D Open bite of unspecified front wall of thorax without penetration into thoracic cavity,',
  'subsequent encounter',
  'S21159S Open bite of unspecified front wall of thorax without penetration into thoracic cavity, sequela',
  'S21201A Unspecified open wound of right back wall of thorax without penetration into thoracic cavity,',
  'initial encounter',
  'S21201D Unspecified open wound of right back wall of thorax without penetration into thoracic cavity,',
  'subsequent encounter',
  'S21201S Unspecified open wound of right back wall of thorax without penetration into thoracic cavity,',
  'sequela',
  'S21202A Unspecified open wound of left back wall of thorax without penetration into thoracic cavity,',
  'initial encounter',
  'S21202D Unspecified open wound of left back wall of thorax without penetration into thoracic cavity,',
  'subsequent encounter',
  'S21202S Unspecified open wound of left back wall of thorax without penetration into thoracic cavity,',
  'sequela',
  'S21209A Unspecified open wound of unspecified back wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21209D Unspecified open wound of unspecified back wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21209S Unspecified open wound of unspecified back wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21211A Laceration without foreign body of right back wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21211D Laceration without foreign body of right back wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21211S Laceration without foreign body of right back wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21212A Laceration without foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21212D Laceration without foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21212S Laceration without foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21219A Laceration without foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21219D Laceration without foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21219S Laceration without foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21221A Laceration with foreign body of right back wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21221D Laceration with foreign body of right back wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21221S Laceration with foreign body of right back wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21222A Laceration with foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21222D Laceration with foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21222S Laceration with foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21229A Laceration with foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21229D Laceration with foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21229S Laceration with foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21231A Puncture wound without foreign body of right back wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21231D Puncture wound without foreign body of right back wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21231S Puncture wound without foreign body of right back wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21232A Puncture wound without foreign body of left back wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21232D Puncture wound without foreign body of left back wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21232S Puncture wound without foreign body of left back wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21239A Puncture wound without foreign body of unspecified back wall of thorax without penetration',
  'into thoracic cavity, initial encounter',
  'S21239D Puncture wound without foreign body of unspecified back wall of thorax without penetration',
  'into thoracic cavity, subsequent encounter',
  'S21239S Puncture wound without foreign body of unspecified back wall of thorax without penetration',
  'into thoracic cavity, sequela',
  'S21241A Puncture wound with foreign body of right back wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21241D Puncture wound with foreign body of right back wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21241S Puncture wound with foreign body of right back wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21242A Puncture wound with foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, initial encounter',
  'S21242D Puncture wound with foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, subsequent encounter',
  'S21242S Puncture wound with foreign body of left back wall of thorax without penetration into thoracic',
  'cavity, sequela',
  'S21249A Puncture wound with foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, initial encounter',
  'S21249D Puncture wound with foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, subsequent encounter',
  'S21249S Puncture wound with foreign body of unspecified back wall of thorax without penetration into',
  'thoracic cavity, sequela',
  'S21251A Open bite of right back wall of thorax without penetration into thoracic cavity, initial encounter',
  'S21251D Open bite of right back wall of thorax without penetration into thoracic cavity, subsequent',
  'encounter',
  'S21251S Open bite of right back wall of thorax without penetration into thoracic cavity, sequela',
  'S21252A Open bite of left back wall of thorax without penetration into thoracic cavity, initial encounter',
  'S21252D Open bite of left back wall of thorax without penetration into thoracic cavity, subsequent',
  'encounter',
  'S21252S Open bite of left back wall of thorax without penetration into thoracic cavity, sequela',
  'S21259A Open bite of unspecified back wall of thorax without penetration into thoracic cavity, initial',
  'encounter',
  'S21259D Open bite of unspecified back wall of thorax without penetration into thoracic cavity,',
  'subsequent encounter',
  'S21259S Open bite of unspecified back wall of thorax without penetration into thoracic cavity, sequela',
  'S21301A Unspecified open wound of right front wall of thorax with penetration into thoracic cavity,',
  'initial encounter',
  'S21301D Unspecified open wound of right front wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21301S Unspecified open wound of right front wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21302A Unspecified open wound of left front wall of thorax with penetration into thoracic cavity, initial',
  'encounter',
  'S21302D Unspecified open wound of left front wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21302S Unspecified open wound of left front wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21309A Unspecified open wound of unspecified front wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21309D Unspecified open wound of unspecified front wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21309S Unspecified open wound of unspecified front wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21311A Laceration without foreign body of right front wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21311D Laceration without foreign body of right front wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21311S Laceration without foreign body of right front wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21312A Laceration without foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21312D Laceration without foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21312S Laceration without foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21319A Laceration without foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21319D Laceration without foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21319S Laceration without foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21321A Laceration with foreign body of right front wall of thorax with penetration into thoracic cavity,',
  'initial encounter',
  'S21321D Laceration with foreign body of right front wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21321S Laceration with foreign body of right front wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21322A Laceration with foreign body of left front wall of thorax with penetration into thoracic cavity,',
  'initial encounter',
  'S21322D Laceration with foreign body of left front wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21322S Laceration with foreign body of left front wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21329A Laceration with foreign body of unspecified front wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21329D Laceration with foreign body of unspecified front wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21329S Laceration with foreign body of unspecified front wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21331A Puncture wound without foreign body of right front wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21331D Puncture wound without foreign body of right front wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21331S Puncture wound without foreign body of right front wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21332A Puncture wound without foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21332D Puncture wound without foreign body of left front wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21332S Puncture wound without foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21339A Puncture wound without foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21339D Puncture wound without foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21339S Puncture wound without foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21341A Puncture wound with foreign body of right front wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21341D Puncture wound with foreign body of right front wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21341S Puncture wound with foreign body of right front wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21342A Puncture wound with foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21342D Puncture wound with foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21342S Puncture wound with foreign body of left front wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21349A Puncture wound with foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21349D Puncture wound with foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21349S Puncture wound with foreign body of unspecified front wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21351A Open bite of right front wall of thorax with penetration into thoracic cavity, initial encounter',
  'S21351D Open bite of right front wall of thorax with penetration into thoracic cavity, subsequent',
  'encounter',
  'S21351S Open bite of right front wall of thorax with penetration into thoracic cavity, sequela',
  'S21352A Open bite of left front wall of thorax with penetration into thoracic cavity, initial encounter',
  'S21352D Open bite of left front wall of thorax with penetration into thoracic cavity, subsequent',
  'encounter',
  'S21352S Open bite of left front wall of thorax with penetration into thoracic cavity, sequela',
  'S21359A Open bite of unspecified front wall of thorax with penetration into thoracic cavity, initial',
  'encounter',
  'S21359D Open bite of unspecified front wall of thorax with penetration into thoracic cavity, subsequent',
  'encounter',
  'S21359S Open bite of unspecified front wall of thorax with penetration into thoracic cavity, sequela',
  'S21401A Unspecified open wound of right back wall of thorax with penetration into thoracic cavity,',
  'initial encounter',
  'S21401D Unspecified open wound of right back wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21401S Unspecified open wound of right back wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21402A Unspecified open wound of left back wall of thorax with penetration into thoracic cavity, initial',
  'encounter',
  'S21402D Unspecified open wound of left back wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21402S Unspecified open wound of left back wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21409A Unspecified open wound of unspecified back wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21409D Unspecified open wound of unspecified back wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21409S Unspecified open wound of unspecified back wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21411A Laceration without foreign body of right back wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21411D Laceration without foreign body of right back wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21411S Laceration without foreign body of right back wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21412A Laceration without foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21412D Laceration without foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21412S Laceration without foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21419A Laceration without foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21419D Laceration without foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21419S Laceration without foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21421A Laceration with foreign body of right back wall of thorax with penetration into thoracic cavity,',
  'initial encounter',
  'S21421D Laceration with foreign body of right back wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21421S Laceration with foreign body of right back wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21422A Laceration with foreign body of left back wall of thorax with penetration into thoracic cavity,',
  'initial encounter',
  'S21422D Laceration with foreign body of left back wall of thorax with penetration into thoracic cavity,',
  'subsequent encounter',
  'S21422S Laceration with foreign body of left back wall of thorax with penetration into thoracic cavity,',
  'sequela',
  'S21429A Laceration with foreign body of unspecified back wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21429D Laceration with foreign body of unspecified back wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21429S Laceration with foreign body of unspecified back wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21431A Puncture wound without foreign body of right back wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21431D Puncture wound without foreign body of right back wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21431S Puncture wound without foreign body of right back wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21432A Puncture wound without foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21432D Puncture wound without foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21432S Puncture wound without foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21439A Puncture wound without foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21439D Puncture wound without foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21439S Puncture wound without foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21441A Puncture wound with foreign body of right back wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21441D Puncture wound with foreign body of right back wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21441S Puncture wound with foreign body of right back wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21442A Puncture wound with foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, initial encounter',
  'S21442D Puncture wound with foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, subsequent encounter',
  'S21442S Puncture wound with foreign body of left back wall of thorax with penetration into thoracic',
  'cavity, sequela',
  'S21449A Puncture wound with foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, initial encounter',
  'S21449D Puncture wound with foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, subsequent encounter',
  'S21449S Puncture wound with foreign body of unspecified back wall of thorax with penetration into',
  'thoracic cavity, sequela',
  'S21451A Open bite of right back wall of thorax with penetration into thoracic cavity, initial encounter',
  'S21451D Open bite of right back wall of thorax with penetration into thoracic cavity, subsequent',
  'encounter',
  'S21451S Open bite of right back wall of thorax with penetration into thoracic cavity, sequela',
  'S21452A Open bite of left back wall of thorax with penetration into thoracic cavity, initial encounter',
  'S21452D Open bite of left back wall of thorax with penetration into thoracic cavity, subsequent',
  'encounter',
  'S21452S Open bite of left back wall of thorax with penetration into thoracic cavity, sequela',
  'S21459A Open bite of unspecified back wall of thorax with penetration into thoracic cavity, initial',
  'encounter',
  'S21459D Open bite of unspecified back wall of thorax with penetration into thoracic cavity, subsequent',
  'encounter',
  'S21459S Open bite of unspecified back wall of thorax with penetration into thoracic cavity, sequela',
  'S2190XA Unspecified open wound of unspecified part of thorax, initial encounter',
  'S2190XD Unspecified open wound of unspecified part of thorax, subsequent encounter',
  'S2190XS Unspecified open wound of unspecified part of thorax, sequela',
  'S2191XA Laceration without foreign body of unspecified part of thorax, initial encounter',
  'S2191XD Laceration without foreign body of unspecified part of thorax, subsequent encounter',
  'S2191XS Laceration without foreign body of unspecified part of thorax, sequela',
  'S2192XA Laceration with foreign body of unspecified part of thorax, initial encounter',
  'S2192XD Laceration with foreign body of unspecified part of thorax, subsequent encounter',
  'S2192XS Laceration with foreign body of unspecified part of thorax, sequela',
  'S2193XA Puncture wound without foreign body of unspecified part of thorax, initial encounter',
  'S2193XD Puncture wound without foreign body of unspecified part of thorax, subsequent encounter',
  'S2193XS Puncture wound without foreign body of unspecified part of thorax, sequela',
  'S2194XA Puncture wound with foreign body of unspecified part of thorax, initial encounter',
  'S2194XD Puncture wound with foreign body of unspecified part of thorax, subsequent encounter',
  'S2194XS Puncture wound with foreign body of unspecified part of thorax, sequela',
  'S2195XA Open bite of unspecified part of thorax, initial encounter',
  'S2195XD Open bite of unspecified part of thorax, subsequent encounter',
  'S2195XS Open bite of unspecified part of thorax, sequela',
  'S22000A Wedge compression fracture of unspecified thoracic vertebra, initial encounter for closed',
  'fracture',
  'S22000B Wedge compression fracture of unspecified thoracic vertebra, initial encounter for open',
  'fracture',
  'S22000D Wedge compression fracture of unspecified thoracic vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S22000G Wedge compression fracture of unspecified thoracic vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S22000K Wedge compression fracture of unspecified thoracic vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S22000S Wedge compression fracture of unspecified thoracic vertebra, sequela',
  'S22001A Stable burst fracture of unspecified thoracic vertebra, initial encounter for closed fracture',
  'S22001B Stable burst fracture of unspecified thoracic vertebra, initial encounter for open fracture',
  'S22001D Stable burst fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22001G Stable burst fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22001K Stable burst fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22001S Stable burst fracture of unspecified thoracic vertebra, sequela',
  'S22002A Unstable burst fracture of unspecified thoracic vertebra, initial encounter for closed fracture',
  'S22002B Unstable burst fracture of unspecified thoracic vertebra, initial encounter for open fracture',
  'S22002D Unstable burst fracture of unspecified thoracic vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S22002G Unstable burst fracture of unspecified thoracic vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S22002K Unstable burst fracture of unspecified thoracic vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S22002S Unstable burst fracture of unspecified thoracic vertebra, sequela',
  'S22008A Other fracture of unspecified thoracic vertebra, initial encounter for closed fracture',
  'S22008B Other fracture of unspecified thoracic vertebra, initial encounter for open fracture',
  'S22008D Other fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22008G Other fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22008K Other fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22008S Other fracture of unspecified thoracic vertebra, sequela',
  'S22009A Unspecified fracture of unspecified thoracic vertebra, initial encounter for closed fracture',
  'S22009B Unspecified fracture of unspecified thoracic vertebra, initial encounter for open fracture',
  'S22009D Unspecified fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22009G Unspecified fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22009K Unspecified fracture of unspecified thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22009S Unspecified fracture of unspecified thoracic vertebra, sequela',
  'S22010A Wedge compression fracture of first thoracic vertebra, initial encounter for closed fracture',
  'S22010B Wedge compression fracture of first thoracic vertebra, initial encounter for open fracture',
  'S22010D Wedge compression fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22010G Wedge compression fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22010K Wedge compression fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22010S Wedge compression fracture of first thoracic vertebra, sequela',
  'S22011A Stable burst fracture of first thoracic vertebra, initial encounter for closed fracture',
  'S22011B Stable burst fracture of first thoracic vertebra, initial encounter for open fracture',
  'S22011D Stable burst fracture of first thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22011G Stable burst fracture of first thoracic vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22011K Stable burst fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22011S Stable burst fracture of first thoracic vertebra, sequela',
  'S22012A Unstable burst fracture of first thoracic vertebra, initial encounter for closed fracture',
  'S22012B Unstable burst fracture of first thoracic vertebra, initial encounter for open fracture',
  'S22012D Unstable burst fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22012G Unstable burst fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22012K Unstable burst fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22012S Unstable burst fracture of first thoracic vertebra, sequela',
  'S22018A Other fracture of first thoracic vertebra, initial encounter for closed fracture',
  'S22018B Other fracture of first thoracic vertebra, initial encounter for open fracture',
  'S22018D Other fracture of first thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22018G Other fracture of first thoracic vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22018K Other fracture of first thoracic vertebra, subsequent encounter for fracture with nonunion',
  'S22018S Other fracture of first thoracic vertebra, sequela',
  'S22019A Unspecified fracture of first thoracic vertebra, initial encounter for closed fracture',
  'S22019B Unspecified fracture of first thoracic vertebra, initial encounter for open fracture',
  'S22019D Unspecified fracture of first thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22019G Unspecified fracture of first thoracic vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22019K Unspecified fracture of first thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22019S Unspecified fracture of first thoracic vertebra, sequela',
  'S22020A Wedge compression fracture of second thoracic vertebra, initial encounter for closed fracture',
  'S22020B Wedge compression fracture of second thoracic vertebra, initial encounter for open fracture',
  'S22020D Wedge compression fracture of second thoracic vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S22020G Wedge compression fracture of second thoracic vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S22020K Wedge compression fracture of second thoracic vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S22020S Wedge compression fracture of second thoracic vertebra, sequela',
  'S22021A Stable burst fracture of second thoracic vertebra, initial encounter for closed fracture',
  'S22021B Stable burst fracture of second thoracic vertebra, initial encounter for open fracture',
  'S22021D Stable burst fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22021G Stable burst fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22021K Stable burst fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22021S Stable burst fracture of second thoracic vertebra, sequela',
  'S22022A Unstable burst fracture of second thoracic vertebra, initial encounter for closed fracture',
  'S22022B Unstable burst fracture of second thoracic vertebra, initial encounter for open fracture',
  'S22022D Unstable burst fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22022G Unstable burst fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22022K Unstable burst fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22022S Unstable burst fracture of second thoracic vertebra, sequela',
  'S22028A Other fracture of second thoracic vertebra, initial encounter for closed fracture',
  'S22028B Other fracture of second thoracic vertebra, initial encounter for open fracture',
  'S22028D Other fracture of second thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22028G Other fracture of second thoracic vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22028K Other fracture of second thoracic vertebra, subsequent encounter for fracture with nonunion',
  'S22028S Other fracture of second thoracic vertebra, sequela',
  'S22029A Unspecified fracture of second thoracic vertebra, initial encounter for closed fracture',
  'S22029B Unspecified fracture of second thoracic vertebra, initial encounter for open fracture',
  'S22029D Unspecified fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22029G Unspecified fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22029K Unspecified fracture of second thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22029S Unspecified fracture of second thoracic vertebra, sequela',
  'S22030A Wedge compression fracture of third thoracic vertebra, initial encounter for closed fracture',
  'S22030B Wedge compression fracture of third thoracic vertebra, initial encounter for open fracture',
  'S22030D Wedge compression fracture of third thoracic vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S22030G Wedge compression fracture of third thoracic vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S22030K Wedge compression fracture of third thoracic vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S22030S Wedge compression fracture of third thoracic vertebra, sequela',
  'S22031A Stable burst fracture of third thoracic vertebra, initial encounter for closed fracture',
  'S22031B Stable burst fracture of third thoracic vertebra, initial encounter for open fracture',
  'S22031D Stable burst fracture of third thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22031G Stable burst fracture of third thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22031K Stable burst fracture of third thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22031S Stable burst fracture of third thoracic vertebra, sequela',
  'S22032A Unstable burst fracture of third thoracic vertebra, initial encounter for closed fracture',
  'S22032B Unstable burst fracture of third thoracic vertebra, initial encounter for open fracture',
  'S22032D Unstable burst fracture of third thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22032G Unstable burst fracture of third thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22032K Unstable burst fracture of third thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22032S Unstable burst fracture of third thoracic vertebra, sequela',
  'S22038A Other fracture of third thoracic vertebra, initial encounter for closed fracture',
  'S22038B Other fracture of third thoracic vertebra, initial encounter for open fracture',
  'S22038D Other fracture of third thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22038G Other fracture of third thoracic vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22038K Other fracture of third thoracic vertebra, subsequent encounter for fracture with nonunion',
  'S22038S Other fracture of third thoracic vertebra, sequela',
  'S22039A Unspecified fracture of third thoracic vertebra, initial encounter for closed fracture',
  'S22039B Unspecified fracture of third thoracic vertebra, initial encounter for open fracture',
  'S22039D Unspecified fracture of third thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22039G Unspecified fracture of third thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22039K Unspecified fracture of third thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22039S Unspecified fracture of third thoracic vertebra, sequela',
  'S22040A Wedge compression fracture of fourth thoracic vertebra, initial encounter for closed fracture',
  'S22040B Wedge compression fracture of fourth thoracic vertebra, initial encounter for open fracture',
  'S22040D Wedge compression fracture of fourth thoracic vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S22040G Wedge compression fracture of fourth thoracic vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S22040K Wedge compression fracture of fourth thoracic vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S22040S Wedge compression fracture of fourth thoracic vertebra, sequela',
  'S22041A Stable burst fracture of fourth thoracic vertebra, initial encounter for closed fracture',
  'S22041B Stable burst fracture of fourth thoracic vertebra, initial encounter for open fracture',
  'S22041D Stable burst fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22041G Stable burst fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22041K Stable burst fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22041S Stable burst fracture of fourth thoracic vertebra, sequela',
  'S22042A Unstable burst fracture of fourth thoracic vertebra, initial encounter for closed fracture',
  'S22042B Unstable burst fracture of fourth thoracic vertebra, initial encounter for open fracture',
  'S22042D Unstable burst fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22042G Unstable burst fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22042K Unstable burst fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22042S Unstable burst fracture of fourth thoracic vertebra, sequela',
  'S22048A Other fracture of fourth thoracic vertebra, initial encounter for closed fracture',
  'S22048B Other fracture of fourth thoracic vertebra, initial encounter for open fracture',
  'S22048D Other fracture of fourth thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22048G Other fracture of fourth thoracic vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22048K Other fracture of fourth thoracic vertebra, subsequent encounter for fracture with nonunion',
  'S22048S Other fracture of fourth thoracic vertebra, sequela',
  'S22049A Unspecified fracture of fourth thoracic vertebra, initial encounter for closed fracture',
  'S22049B Unspecified fracture of fourth thoracic vertebra, initial encounter for open fracture',
  'S22049D Unspecified fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22049G Unspecified fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22049K Unspecified fracture of fourth thoracic vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22049S Unspecified fracture of fourth thoracic vertebra, sequela',
  'S22050A Wedge compression fracture of T5-T6 vertebra, initial encounter for closed fracture',
  'S22050B Wedge compression fracture of T5-T6 vertebra, initial encounter for open fracture',
  'S22050D Wedge compression fracture of T5-T6 vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22050G Wedge compression fracture of T5-T6 vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22050K Wedge compression fracture of T5-T6 vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22050S Wedge compression fracture of T5-T6 vertebra, sequela',
  'S22051A Stable burst fracture of T5-T6 vertebra, initial encounter for closed fracture',
  'S22051B Stable burst fracture of T5-T6 vertebra, initial encounter for open fracture',
  'S22051D Stable burst fracture of T5-T6 vertebra, subsequent encounter for fracture with routine healing',
  'S22051G Stable burst fracture of T5-T6 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22051K Stable burst fracture of T5-T6 vertebra, subsequent encounter for fracture with nonunion',
  'S22051S Stable burst fracture of T5-T6 vertebra, sequela',
  'S22052A Unstable burst fracture of T5-T6 vertebra, initial encounter for closed fracture',
  'S22052B Unstable burst fracture of T5-T6 vertebra, initial encounter for open fracture',
  'S22052D Unstable burst fracture of T5-T6 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22052G Unstable burst fracture of T5-T6 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22052K Unstable burst fracture of T5-T6 vertebra, subsequent encounter for fracture with nonunion',
  'S22052S Unstable burst fracture of T5-T6 vertebra, sequela',
  'S22058A Other fracture of T5-T6 vertebra, initial encounter for closed fracture',
  'S22058B Other fracture of T5-T6 vertebra, initial encounter for open fracture',
  'S22058D Other fracture of T5-T6 vertebra, subsequent encounter for fracture with routine healing',
  'S22058G Other fracture of T5-T6 vertebra, subsequent encounter for fracture with delayed healing',
  'S22058K Other fracture of T5-T6 vertebra, subsequent encounter for fracture with nonunion',
  'S22058S Other fracture of T5-T6 vertebra, sequela',
  'S22059A Unspecified fracture of T5-T6 vertebra, initial encounter for closed fracture',
  'S22059B Unspecified fracture of T5-T6 vertebra, initial encounter for open fracture',
  'S22059D Unspecified fracture of T5-T6 vertebra, subsequent encounter for fracture with routine healing',
  'S22059G Unspecified fracture of T5-T6 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22059K Unspecified fracture of T5-T6 vertebra, subsequent encounter for fracture with nonunion',
  'S22059S Unspecified fracture of T5-T6 vertebra, sequela',
  'S22060A Wedge compression fracture of T7-T8 vertebra, initial encounter for closed fracture',
  'S22060B Wedge compression fracture of T7-T8 vertebra, initial encounter for open fracture',
  'S22060D Wedge compression fracture of T7-T8 vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22060G Wedge compression fracture of T7-T8 vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22060K Wedge compression fracture of T7-T8 vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22060S Wedge compression fracture of T7-T8 vertebra, sequela',
  'S22061A Stable burst fracture of T7-T8 vertebra, initial encounter for closed fracture',
  'S22061B Stable burst fracture of T7-T8 vertebra, initial encounter for open fracture',
  'S22061D Stable burst fracture of T7-T8 vertebra, subsequent encounter for fracture with routine healing',
  'S22061G Stable burst fracture of T7-T8 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22061K Stable burst fracture of T7-T8 vertebra, subsequent encounter for fracture with nonunion',
  'S22061S Stable burst fracture of T7-T8 vertebra, sequela',
  'S22062A Unstable burst fracture of T7-T8 vertebra, initial encounter for closed fracture',
  'S22062B Unstable burst fracture of T7-T8 vertebra, initial encounter for open fracture',
  'S22062D Unstable burst fracture of T7-T8 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22062G Unstable burst fracture of T7-T8 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22062K Unstable burst fracture of T7-T8 vertebra, subsequent encounter for fracture with nonunion',
  'S22062S Unstable burst fracture of T7-T8 vertebra, sequela',
  'S22068A Other fracture of T7-T8 thoracic vertebra, initial encounter for closed fracture',
  'S22068B Other fracture of T7-T8 thoracic vertebra, initial encounter for open fracture',
  'S22068D Other fracture of T7-T8 thoracic vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22068G Other fracture of T7-T8 thoracic vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22068K Other fracture of T7-T8 thoracic vertebra, subsequent encounter for fracture with nonunion',
  'S22068S Other fracture of T7-T8 thoracic vertebra, sequela',
  'S22069A Unspecified fracture of T7-T8 vertebra, initial encounter for closed fracture',
  'S22069B Unspecified fracture of T7-T8 vertebra, initial encounter for open fracture',
  'S22069D Unspecified fracture of T7-T8 vertebra, subsequent encounter for fracture with routine healing',
  'S22069G Unspecified fracture of T7-T8 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22069K Unspecified fracture of T7-T8 vertebra, subsequent encounter for fracture with nonunion',
  'S22069S Unspecified fracture of T7-T8 vertebra, sequela',
  'S22070A Wedge compression fracture of T9-T10 vertebra, initial encounter for closed fracture',
  'S22070B Wedge compression fracture of T9-T10 vertebra, initial encounter for open fracture',
  'S22070D Wedge compression fracture of T9-T10 vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22070G Wedge compression fracture of T9-T10 vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22070K Wedge compression fracture of T9-T10 vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22070S Wedge compression fracture of T9-T10 vertebra, sequela',
  'S22071A Stable burst fracture of T9-T10 vertebra, initial encounter for closed fracture',
  'S22071B Stable burst fracture of T9-T10 vertebra, initial encounter for open fracture',
  'S22071D Stable burst fracture of T9-T10 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22071G Stable burst fracture of T9-T10 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22071K Stable burst fracture of T9-T10 vertebra, subsequent encounter for fracture with nonunion',
  'S22071S Stable burst fracture of T9-T10 vertebra, sequela',
  'S22072A Unstable burst fracture of T9-T10 vertebra, initial encounter for closed fracture',
  'S22072B Unstable burst fracture of T9-T10 vertebra, initial encounter for open fracture',
  'S22072D Unstable burst fracture of T9-T10 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22072G Unstable burst fracture of T9-T10 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22072K Unstable burst fracture of T9-T10 vertebra, subsequent encounter for fracture with nonunion',
  'S22072S Unstable burst fracture of T9-T10 vertebra, sequela',
  'S22078A Other fracture of T9-T10 vertebra, initial encounter for closed fracture',
  'S22078B Other fracture of T9-T10 vertebra, initial encounter for open fracture',
  'S22078D Other fracture of T9-T10 vertebra, subsequent encounter for fracture with routine healing',
  'S22078G Other fracture of T9-T10 vertebra, subsequent encounter for fracture with delayed healing',
  'S22078K Other fracture of T9-T10 vertebra, subsequent encounter for fracture with nonunion',
  'S22078S Other fracture of T9-T10 vertebra, sequela',
  'S22079A Unspecified fracture of T9-T10 vertebra, initial encounter for closed fracture',
  'S22079B Unspecified fracture of T9-T10 vertebra, initial encounter for open fracture',
  'S22079D Unspecified fracture of T9-T10 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22079G Unspecified fracture of T9-T10 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22079K Unspecified fracture of T9-T10 vertebra, subsequent encounter for fracture with nonunion',
  'S22079S Unspecified fracture of T9-T10 vertebra, sequela',
  'S22080A Wedge compression fracture of T11-T12 vertebra, initial encounter for closed fracture',
  'S22080B Wedge compression fracture of T11-T12 vertebra, initial encounter for open fracture',
  'S22080D Wedge compression fracture of T11-T12 vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S22080G Wedge compression fracture of T11-T12 vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S22080K Wedge compression fracture of T11-T12 vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S22080S Wedge compression fracture of T11-T12 vertebra, sequela',
  'S22081A Stable burst fracture of T11-T12 vertebra, initial encounter for closed fracture',
  'S22081B Stable burst fracture of T11-T12 vertebra, initial encounter for open fracture',
  'S22081D Stable burst fracture of T11-T12 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22081G Stable burst fracture of T11-T12 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22081K Stable burst fracture of T11-T12 vertebra, subsequent encounter for fracture with nonunion',
  'S22081S Stable burst fracture of T11-T12 vertebra, sequela',
  'S22082A Unstable burst fracture of T11-T12 vertebra, initial encounter for closed fracture',
  'S22082B Unstable burst fracture of T11-T12 vertebra, initial encounter for open fracture',
  'S22082D Unstable burst fracture of T11-T12 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22082G Unstable burst fracture of T11-T12 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22082K Unstable burst fracture of T11-T12 vertebra, subsequent encounter for fracture with nonunion',
  'S22082S Unstable burst fracture of T11-T12 vertebra, sequela',
  'S22088A Other fracture of T11-T12 vertebra, initial encounter for closed fracture',
  'S22088B Other fracture of T11-T12 vertebra, initial encounter for open fracture',
  'S22088D Other fracture of T11-T12 vertebra, subsequent encounter for fracture with routine healing',
  'S22088G Other fracture of T11-T12 vertebra, subsequent encounter for fracture with delayed healing',
  'S22088K Other fracture of T11-T12 vertebra, subsequent encounter for fracture with nonunion',
  'S22088S Other fracture of T11-T12 vertebra, sequela',
  'S22089A Unspecified fracture of T11-T12 vertebra, initial encounter for closed fracture',
  'S22089B Unspecified fracture of T11-T12 vertebra, initial encounter for open fracture',
  'S22089D Unspecified fracture of T11-T12 vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S22089G Unspecified fracture of T11-T12 vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S22089K Unspecified fracture of T11-T12 vertebra, subsequent encounter for fracture with nonunion',
  'S22089S Unspecified fracture of T11-T12 vertebra, sequela',
  'S2220XA Unspecified fracture of sternum, initial encounter for closed fracture',
  'S2220XB Unspecified fracture of sternum, initial encounter for open fracture',
  'S2220XD Unspecified fracture of sternum, subsequent encounter for fracture with routine healing',
  'S2220XG Unspecified fracture of sternum, subsequent encounter for fracture with delayed healing',
  'S2220XK Unspecified fracture of sternum, subsequent encounter for fracture with nonunion',
  'S2220XS Unspecified fracture of sternum, sequela',
  'S2221XA Fracture of manubrium, initial encounter for closed fracture',
  'S2221XB Fracture of manubrium, initial encounter for open fracture',
  'S2221XD Fracture of manubrium, subsequent encounter for fracture with routine healing',
  'S2221XG Fracture of manubrium, subsequent encounter for fracture with delayed healing',
  'S2221XK Fracture of manubrium, subsequent encounter for fracture with nonunion',
  'S2221XS Fracture of manubrium, sequela',
  'S2222XA Fracture of body of sternum, initial encounter for closed fracture',
  'S2222XB Fracture of body of sternum, initial encounter for open fracture',
  'S2222XD Fracture of body of sternum, subsequent encounter for fracture with routine healing',
  'S2222XG Fracture of body of sternum, subsequent encounter for fracture with delayed healing',
  'S2222XK Fracture of body of sternum, subsequent encounter for fracture with nonunion',
  'S2222XS Fracture of body of sternum, sequela',
  'S2223XA Sternal manubrial dissociation, initial encounter for closed fracture',
  'S2223XB Sternal manubrial dissociation, initial encounter for open fracture',
  'S2223XD Sternal manubrial dissociation, subsequent encounter for fracture with routine healing',
  'S2223XG Sternal manubrial dissociation, subsequent encounter for fracture with delayed healing',
  'S2223XK Sternal manubrial dissociation, subsequent encounter for fracture with nonunion',
  'S2223XS Sternal manubrial dissociation, sequela',
  'S2224XA Fracture of xiphoid process, initial encounter for closed fracture',
  'S2224XB Fracture of xiphoid process, initial encounter for open fracture',
  'S2224XD Fracture of xiphoid process, subsequent encounter for fracture with routine healing',
  'S2224XG Fracture of xiphoid process, subsequent encounter for fracture with delayed healing',
  'S2224XK Fracture of xiphoid process, subsequent encounter for fracture with nonunion',
  'S2224XS Fracture of xiphoid process, sequela',
  'S2231XA Fracture of one rib, right side, initial encounter for closed fracture',
  'S2231XB Fracture of one rib, right side, initial encounter for open fracture',
  'S2231XD Fracture of one rib, right side, subsequent encounter for fracture with routine healing',
  'S2231XG Fracture of one rib, right side, subsequent encounter for fracture with delayed healing',
  'S2231XK Fracture of one rib, right side, subsequent encounter for fracture with nonunion',
  'S2231XS Fracture of one rib, right side, sequela',
  'S2232XA Fracture of one rib, left side, initial encounter for closed fracture',
  'S2232XB Fracture of one rib, left side, initial encounter for open fracture',
  'S2232XD Fracture of one rib, left side, subsequent encounter for fracture with routine healing',
  'S2232XG Fracture of one rib, left side, subsequent encounter for fracture with delayed healing',
  'S2232XK Fracture of one rib, left side, subsequent encounter for fracture with nonunion',
  'S2232XS Fracture of one rib, left side, sequela',
  'S2239XA Fracture of one rib, unspecified side, initial encounter for closed fracture',
  'S2239XB Fracture of one rib, unspecified side, initial encounter for open fracture',
  'S2239XD Fracture of one rib, unspecified side, subsequent encounter for fracture with routine healing',
  'S2239XG Fracture of one rib, unspecified side, subsequent encounter for fracture with delayed healing',
  'S2239XK Fracture of one rib, unspecified side, subsequent encounter for fracture with nonunion',
  'S2239XS Fracture of one rib, unspecified side, sequela',
  'S2241XA Multiple fractures of ribs, right side, initial encounter for closed fracture',
  'S2241XB Multiple fractures of ribs, right side, initial encounter for open fracture',
  'S2241XD Multiple fractures of ribs, right side, subsequent encounter for fracture with routine healing',
  'S2241XG Multiple fractures of ribs, right side, subsequent encounter for fracture with delayed healing',
  'S2241XK Multiple fractures of ribs, right side, subsequent encounter for fracture with nonunion',
  'S2241XS Multiple fractures of ribs, right side, sequela',
  'S2242XA Multiple fractures of ribs, left side, initial encounter for closed fracture',
  'S2242XB Multiple fractures of ribs, left side, initial encounter for open fracture',
  'S2242XD Multiple fractures of ribs, left side, subsequent encounter for fracture with routine healing',
  'S2242XG Multiple fractures of ribs, left side, subsequent encounter for fracture with delayed healing',
  'S2242XK Multiple fractures of ribs, left side, subsequent encounter for fracture with nonunion',
  'S2242XS Multiple fractures of ribs, left side, sequela',
  'S2243XA Multiple fractures of ribs, bilateral, initial encounter for closed fracture',
  'S2243XB Multiple fractures of ribs, bilateral, initial encounter for open fracture',
  'S2243XD Multiple fractures of ribs, bilateral, subsequent encounter for fracture with routine healing',
  'S2243XG Multiple fractures of ribs, bilateral, subsequent encounter for fracture with delayed healing',
  'S2243XK Multiple fractures of ribs, bilateral, subsequent encounter for fracture with nonunion',
  'S2243XS Multiple fractures of ribs, bilateral, sequela',
  'S2249XA Multiple fractures of ribs, unspecified side, initial encounter for closed fracture',
  'S2249XB Multiple fractures of ribs, unspecified side, initial encounter for open fracture',
  'S2249XD Multiple fractures of ribs, unspecified side, subsequent encounter for fracture with routine',
  'healing',
  'S2249XG Multiple fractures of ribs, unspecified side, subsequent encounter for fracture with delayed',
  'healing',
  'S2249XK Multiple fractures of ribs, unspecified side, subsequent encounter for fracture with nonunion',
  'S2249XS Multiple fractures of ribs, unspecified side, sequela',
  'S225XXA Flail chest, initial encounter for closed fracture',
  'S225XXB Flail chest, initial encounter for open fracture',
  'S225XXD Flail chest, subsequent encounter for fracture with routine healing',
  'S225XXG Flail chest, subsequent encounter for fracture with delayed healing',
  'S225XXK Flail chest, subsequent encounter for fracture with nonunion',
  'S225XXS Flail chest, sequela',
  'S229XXA Fracture of bony thorax, part unspecified, initial encounter for closed fracture',
  'S229XXB Fracture of bony thorax, part unspecified, initial encounter for open fracture',
  'S229XXD Fracture of bony thorax, part unspecified, subsequent encounter for fracture with routine',
  'healing',
  'S229XXG Fracture of bony thorax, part unspecified, subsequent encounter for fracture with delayed',
  'healing',
  'S229XXK Fracture of bony thorax, part unspecified, subsequent encounter for fracture with nonunion',
  'S229XXS Fracture of bony thorax, part unspecified, sequela',
  'S230XXA Traumatic rupture of thoracic intervertebral disc, initial encounter',
  'S230XXD Traumatic rupture of thoracic intervertebral disc, subsequent encounter',
  'S230XXS Traumatic rupture of thoracic intervertebral disc, sequela',
  'S23100A Subluxation of unspecified thoracic vertebra, initial encounter',
  'S23100D Subluxation of unspecified thoracic vertebra, subsequent encounter',
  'S23100S Subluxation of unspecified thoracic vertebra, sequela',
  'S23101A Dislocation of unspecified thoracic vertebra, initial encounter',
  'S23101D Dislocation of unspecified thoracic vertebra, subsequent encounter',
  'S23101S Dislocation of unspecified thoracic vertebra, sequela',
  'S23110A Subluxation of T1/T2 thoracic vertebra, initial encounter',
  'S23110D Subluxation of T1/T2 thoracic vertebra, subsequent encounter',
  'S23110S Subluxation of T1/T2 thoracic vertebra, sequela',
  'S23111A Dislocation of T1/T2 thoracic vertebra, initial encounter',
  'S23111D Dislocation of T1/T2 thoracic vertebra, subsequent encounter',
  'S23111S Dislocation of T1/T2 thoracic vertebra, sequela',
  'S23120A Subluxation of T2/T3 thoracic vertebra, initial encounter',
  'S23120D Subluxation of T2/T3 thoracic vertebra, subsequent encounter',
  'S23120S Subluxation of T2/T3 thoracic vertebra, sequela',
  'S23121A Dislocation of T2/T3 thoracic vertebra, initial encounter',
  'S23121D Dislocation of T2/T3 thoracic vertebra, subsequent encounter',
  'S23121S Dislocation of T2/T3 thoracic vertebra, sequela',
  'S23122A Subluxation of T3/T4 thoracic vertebra, initial encounter',
  'S23122D Subluxation of T3/T4 thoracic vertebra, subsequent encounter',
  'S23122S Subluxation of T3/T4 thoracic vertebra, sequela',
  'S23123A Dislocation of T3/T4 thoracic vertebra, initial encounter',
  'S23123D Dislocation of T3/T4 thoracic vertebra, subsequent encounter',
  'S23123S Dislocation of T3/T4 thoracic vertebra, sequela',
  'S23130A Subluxation of T4/T5 thoracic vertebra, initial encounter',
  'S23130D Subluxation of T4/T5 thoracic vertebra, subsequent encounter',
  'S23130S Subluxation of T4/T5 thoracic vertebra, sequela',
  'S23131A Dislocation of T4/T5 thoracic vertebra, initial encounter',
  'S23131D Dislocation of T4/T5 thoracic vertebra, subsequent encounter',
  'S23131S Dislocation of T4/T5 thoracic vertebra, sequela',
  'S23132A Subluxation of T5/T6 thoracic vertebra, initial encounter',
  'S23132D Subluxation of T5/T6 thoracic vertebra, subsequent encounter',
  'S23132S Subluxation of T5/T6 thoracic vertebra, sequela',
  'S23133A Dislocation of T5/T6 thoracic vertebra, initial encounter',
  'S23133D Dislocation of T5/T6 thoracic vertebra, subsequent encounter',
  'S23133S Dislocation of T5/T6 thoracic vertebra, sequela',
  'S23140A Subluxation of T6/T7 thoracic vertebra, initial encounter',
  'S23140D Subluxation of T6/T7 thoracic vertebra, subsequent encounter',
  'S23140S Subluxation of T6/T7 thoracic vertebra, sequela',
  'S23141A Dislocation of T6/T7 thoracic vertebra, initial encounter',
  'S23141D Dislocation of T6/T7 thoracic vertebra, subsequent encounter',
  'S23141S Dislocation of T6/T7 thoracic vertebra, sequela',
  'S23142A Subluxation of T7/T8 thoracic vertebra, initial encounter',
  'S23142D Subluxation of T7/T8 thoracic vertebra, subsequent encounter',
  'S23142S Subluxation of T7/T8 thoracic vertebra, sequela',
  'S23143A Dislocation of T7/T8 thoracic vertebra, initial encounter',
  'S23143D Dislocation of T7/T8 thoracic vertebra, subsequent encounter',
  'S23143S Dislocation of T7/T8 thoracic vertebra, sequela',
  'S23150A Subluxation of T8/T9 thoracic vertebra, initial encounter',
  'S23150D Subluxation of T8/T9 thoracic vertebra, subsequent encounter',
  'S23150S Subluxation of T8/T9 thoracic vertebra, sequela',
  'S23151A Dislocation of T8/T9 thoracic vertebra, initial encounter',
  'S23151D Dislocation of T8/T9 thoracic vertebra, subsequent encounter',
  'S23151S Dislocation of T8/T9 thoracic vertebra, sequela',
  'S23152A Subluxation of T9/T10 thoracic vertebra, initial encounter',
  'S23152D Subluxation of T9/T10 thoracic vertebra, subsequent encounter',
  'S23152S Subluxation of T9/T10 thoracic vertebra, sequela',
  'S23153A Dislocation of T9/T10 thoracic vertebra, initial encounter',
  'S23153D Dislocation of T9/T10 thoracic vertebra, subsequent encounter',
  'S23153S Dislocation of T9/T10 thoracic vertebra, sequela',
  'S23160A Subluxation of T10/T11 thoracic vertebra, initial encounter',
  'S23160D Subluxation of T10/T11 thoracic vertebra, subsequent encounter',
  'S23160S Subluxation of T10/T11 thoracic vertebra, sequela',
  'S23161A Dislocation of T10/T11 thoracic vertebra, initial encounter',
  'S23161D Dislocation of T10/T11 thoracic vertebra, subsequent encounter',
  'S23161S Dislocation of T10/T11 thoracic vertebra, sequela',
  'S23162A Subluxation of T11/T12 thoracic vertebra, initial encounter',
  'S23162D Subluxation of T11/T12 thoracic vertebra, subsequent encounter',
  'S23162S Subluxation of T11/T12 thoracic vertebra, sequela',
  'S23163A Dislocation of T11/T12 thoracic vertebra, initial encounter',
  'S23163D Dislocation of T11/T12 thoracic vertebra, subsequent encounter',
  'S23163S Dislocation of T11/T12 thoracic vertebra, sequela',
  'S23170A Subluxation of T12/L1 thoracic vertebra, initial encounter',
  'S23170D Subluxation of T12/L1 thoracic vertebra, subsequent encounter',
  'S23170S Subluxation of T12/L1 thoracic vertebra, sequela',
  'S23171A Dislocation of T12/L1 thoracic vertebra, initial encounter',
  'S23171D Dislocation of T12/L1 thoracic vertebra, subsequent encounter',
  'S23171S Dislocation of T12/L1 thoracic vertebra, sequela',
  'S2320XA Dislocation of unspecified part of thorax, initial encounter',
  'S2320XD Dislocation of unspecified part of thorax, subsequent encounter',
  'S2320XS Dislocation of unspecified part of thorax, sequela',
  'S2329XA Dislocation of other parts of thorax, initial encounter',
  'S2329XD Dislocation of other parts of thorax, subsequent encounter',
  'S2329XS Dislocation of other parts of thorax, sequela',
  'S233XXA Sprain of ligaments of thoracic spine, initial encounter',
  'S233XXD Sprain of ligaments of thoracic spine, subsequent encounter',
  'S233XXS Sprain of ligaments of thoracic spine, sequela',
  'S2341XA Sprain of ribs, initial encounter',
  'S2341XD Sprain of ribs, subsequent encounter',
  'S2341XS Sprain of ribs, sequela',
  'S23420A Sprain of sternoclavicular (joint) (ligament), initial encounter',
  'S23420D Sprain of sternoclavicular (joint) (ligament), subsequent encounter',
  'S23420S Sprain of sternoclavicular (joint) (ligament), sequela',
  'S23421A Sprain of chondrosternal joint, initial encounter',
  'S23421D Sprain of chondrosternal joint, subsequent encounter',
  'S23421S Sprain of chondrosternal joint, sequela',
  'S23428A Other sprain of sternum, initial encounter',
  'S23428D Other sprain of sternum, subsequent encounter',
  'S23428S Other sprain of sternum, sequela',
  'S23429A Unspecified sprain of sternum, initial encounter',
  'S23429D Unspecified sprain of sternum, subsequent encounter',
  'S23429S Unspecified sprain of sternum, sequela',
  'S238XXA Sprain of other specified parts of thorax, initial encounter',
  'S238XXD Sprain of other specified parts of thorax, subsequent encounter',
  'S238XXS Sprain of other specified parts of thorax, sequela',
  'S239XXA Sprain of unspecified parts of thorax, initial encounter',
  'S239XXD Sprain of unspecified parts of thorax, subsequent encounter',
  'S239XXS Sprain of unspecified parts of thorax, sequela',
  'S240XXA Concussion and edema of thoracic spinal cord, initial encounter',
  'S240XXD Concussion and edema of thoracic spinal cord, subsequent encounter',
  'S240XXS Concussion and edema of thoracic spinal cord, sequela',
  'S24101A Unspecified injury at T1 level of thoracic spinal cord, initial encounter',
  'S24101D Unspecified injury at T1 level of thoracic spinal cord, subsequent encounter',
  'S24101S Unspecified injury at T1 level of thoracic spinal cord, sequela',
  'S24102A Unspecified injury at T2-T6 level of thoracic spinal cord, initial encounter',
  'S24102D Unspecified injury at T2-T6 level of thoracic spinal cord, subsequent encounter',
  'S24102S Unspecified injury at T2-T6 level of thoracic spinal cord, sequela',
  'S24103A Unspecified injury at T7-T10 level of thoracic spinal cord, initial encounter',
  'S24103D Unspecified injury at T7-T10 level of thoracic spinal cord, subsequent encounter',
  'S24103S Unspecified injury at T7-T10 level of thoracic spinal cord, sequela',
  'S24104A Unspecified injury at T11-T12 level of thoracic spinal cord, initial encounter',
  'S24104D Unspecified injury at T11-T12 level of thoracic spinal cord, subsequent encounter',
  'S24104S Unspecified injury at T11-T12 level of thoracic spinal cord, sequela',
  'S24109A Unspecified injury at unspecified level of thoracic spinal cord, initial encounter',
  'S24109D Unspecified injury at unspecified level of thoracic spinal cord, subsequent encounter',
  'S24109S Unspecified injury at unspecified level of thoracic spinal cord, sequela',
  'S24111A Complete lesion at T1 level of thoracic spinal cord, initial encounter',
  'S24111D Complete lesion at T1 level of thoracic spinal cord, subsequent encounter',
  'S24111S Complete lesion at T1 level of thoracic spinal cord, sequela',
  'S24112A Complete lesion at T2-T6 level of thoracic spinal cord, initial encounter',
  'S24112D Complete lesion at T2-T6 level of thoracic spinal cord, subsequent encounter',
  'S24112S Complete lesion at T2-T6 level of thoracic spinal cord, sequela',
  'S24113A Complete lesion at T7-T10 level of thoracic spinal cord, initial encounter',
  'S24113D Complete lesion at T7-T10 level of thoracic spinal cord, subsequent encounter',
  'S24113S Complete lesion at T7-T10 level of thoracic spinal cord, sequela',
  'S24114A Complete lesion at T11-T12 level of thoracic spinal cord, initial encounter',
  'S24114D Complete lesion at T11-T12 level of thoracic spinal cord, subsequent encounter',
  'S24114S Complete lesion at T11-T12 level of thoracic spinal cord, sequela',
  'S24119A Complete lesion at unspecified level of thoracic spinal cord, initial encounter',
  'S24119D Complete lesion at unspecified level of thoracic spinal cord, subsequent encounter',
  'S24119S Complete lesion at unspecified level of thoracic spinal cord, sequela',
  'S24131A Anterior cord syndrome at T1 level of thoracic spinal cord, initial encounter',
  'S24131D Anterior cord syndrome at T1 level of thoracic spinal cord, subsequent encounter',
  'S24131S Anterior cord syndrome at T1 level of thoracic spinal cord, sequela',
  'S24132A Anterior cord syndrome at T2-T6 level of thoracic spinal cord, initial encounter',
  'S24132D Anterior cord syndrome at T2-T6 level of thoracic spinal cord, subsequent encounter',
  'S24132S Anterior cord syndrome at T2-T6 level of thoracic spinal cord, sequela',
  'S24133A Anterior cord syndrome at T7-T10 level of thoracic spinal cord, initial encounter',
  'S24133D Anterior cord syndrome at T7-T10 level of thoracic spinal cord, subsequent encounter',
  'S24133S Anterior cord syndrome at T7-T10 level of thoracic spinal cord, sequela',
  'S24134A Anterior cord syndrome at T11-T12 level of thoracic spinal cord, initial encounter',
  'S24134D Anterior cord syndrome at T11-T12 level of thoracic spinal cord, subsequent encounter',
  'S24134S Anterior cord syndrome at T11-T12 level of thoracic spinal cord, sequela',
  'S24139A Anterior cord syndrome at unspecified level of thoracic spinal cord, initial encounter',
  'S24139D Anterior cord syndrome at unspecified level of thoracic spinal cord, subsequent encounter',
  'S24139S Anterior cord syndrome at unspecified level of thoracic spinal cord, sequela',
  'S24141A Brown-Sequard syndrome at T1 level of thoracic spinal cord, initial encounter',
  'S24141D Brown-Sequard syndrome at T1 level of thoracic spinal cord, subsequent encounter',
  'S24141S Brown-Sequard syndrome at T1 level of thoracic spinal cord, sequela',
  'S24142A Brown-Sequard syndrome at T2-T6 level of thoracic spinal cord, initial encounter',
  'S24142D Brown-Sequard syndrome at T2-T6 level of thoracic spinal cord, subsequent encounter',
  'S24142S Brown-Sequard syndrome at T2-T6 level of thoracic spinal cord, sequela',
  'S24143A Brown-Sequard syndrome at T7-T10 level of thoracic spinal cord, initial encounter',
  'S24143D Brown-Sequard syndrome at T7-T10 level of thoracic spinal cord, subsequent encounter',
  'S24143S Brown-Sequard syndrome at T7-T10 level of thoracic spinal cord, sequela',
  'S24144A Brown-Sequard syndrome at T11-T12 level of thoracic spinal cord, initial encounter',
  'S24144D Brown-Sequard syndrome at T11-T12 level of thoracic spinal cord, subsequent encounter',
  'S24144S Brown-Sequard syndrome at T11-T12 level of thoracic spinal cord, sequela',
  'S24149A Brown-Sequard syndrome at unspecified level of thoracic spinal cord, initial encounter',
  'S24149D Brown-Sequard syndrome at unspecified level of thoracic spinal cord, subsequent encounter',
  'S24149S Brown-Sequard syndrome at unspecified level of thoracic spinal cord, sequela',
  'S24151A Other incomplete lesion at T1 level of thoracic spinal cord, initial encounter',
  'S24151D Other incomplete lesion at T1 level of thoracic spinal cord, subsequent encounter',
  'S24151S Other incomplete lesion at T1 level of thoracic spinal cord, sequela',
  'S24152A Other incomplete lesion at T2-T6 level of thoracic spinal cord, initial encounter',
  'S24152D Other incomplete lesion at T2-T6 level of thoracic spinal cord, subsequent encounter',
  'S24152S Other incomplete lesion at T2-T6 level of thoracic spinal cord, sequela',
  'S24153A Other incomplete lesion at T7-T10 level of thoracic spinal cord, initial encounter',
  'S24153D Other incomplete lesion at T7-T10 level of thoracic spinal cord, subsequent encounter',
  'S24153S Other incomplete lesion at T7-T10 level of thoracic spinal cord, sequela',
  'S24154A Other incomplete lesion at T11-T12 level of thoracic spinal cord, initial encounter',
  'S24154D Other incomplete lesion at T11-T12 level of thoracic spinal cord, subsequent encounter',
  'S24154S Other incomplete lesion at T11-T12 level of thoracic spinal cord, sequela',
  'S24159A Other incomplete lesion at unspecified level of thoracic spinal cord, initial encounter',
  'S24159D Other incomplete lesion at unspecified level of thoracic spinal cord, subsequent encounter',
  'S24159S Other incomplete lesion at unspecified level of thoracic spinal cord, sequela',
  'S242XXA Injury of nerve root of thoracic spine, initial encounter',
  'S242XXD Injury of nerve root of thoracic spine, subsequent encounter',
  'S242XXS Injury of nerve root of thoracic spine, sequela',
  'S243XXA Injury of peripheral nerves of thorax, initial encounter',
  'S243XXD Injury of peripheral nerves of thorax, subsequent encounter',
  'S243XXS Injury of peripheral nerves of thorax, sequela',
  'S244XXA Injury of thoracic sympathetic nervous system, initial encounter',
  'S244XXD Injury of thoracic sympathetic nervous system, subsequent encounter',
  'S244XXS Injury of thoracic sympathetic nervous system, sequela',
  'S248XXA Injury of other specified nerves of thorax, initial encounter',
  'S248XXD Injury of other specified nerves of thorax, subsequent encounter',
  'S248XXS Injury of other specified nerves of thorax, sequela',
  'S249XXA Injury of unspecified nerve of thorax, initial encounter',
  'S249XXD Injury of unspecified nerve of thorax, subsequent encounter',
  'S249XXS Injury of unspecified nerve of thorax, sequela',
  'S2500XA Unspecified injury of thoracic aorta, initial encounter',
  'S2500XD Unspecified injury of thoracic aorta, subsequent encounter',
  'S2500XS Unspecified injury of thoracic aorta, sequela',
  'S2501XA Minor laceration of thoracic aorta, initial encounter',
  'S2501XD Minor laceration of thoracic aorta, subsequent encounter',
  'S2501XS Minor laceration of thoracic aorta, sequela',
  'S2502XA Major laceration of thoracic aorta, initial encounter',
  'S2502XD Major laceration of thoracic aorta, subsequent encounter',
  'S2502XS Major laceration of thoracic aorta, sequela',
  'S2509XA Other specified injury of thoracic aorta, initial encounter',
  'S2509XD Other specified injury of thoracic aorta, subsequent encounter',
  'S2509XS Other specified injury of thoracic aorta, sequela',
  'S25101A Unspecified injury of right innominate or subclavian artery, initial encounter',
  'S25101D Unspecified injury of right innominate or subclavian artery, subsequent encounter',
  'S25101S Unspecified injury of right innominate or subclavian artery, sequela',
  'S25102A Unspecified injury of left innominate or subclavian artery, initial encounter',
  'S25102D Unspecified injury of left innominate or subclavian artery, subsequent encounter',
  'S25102S Unspecified injury of left innominate or subclavian artery, sequela',
  'S25109A Unspecified injury of unspecified innominate or subclavian artery, initial encounter',
  'S25109D Unspecified injury of unspecified innominate or subclavian artery, subsequent encounter',
  'S25109S Unspecified injury of unspecified innominate or subclavian artery, sequela',
  'S25111A Minor laceration of right innominate or subclavian artery, initial encounter',
  'S25111D Minor laceration of right innominate or subclavian artery, subsequent encounter',
  'S25111S Minor laceration of right innominate or subclavian artery, sequela',
  'S25112A Minor laceration of left innominate or subclavian artery, initial encounter',
  'S25112D Minor laceration of left innominate or subclavian artery, subsequent encounter',
  'S25112S Minor laceration of left innominate or subclavian artery, sequela',
  'S25119A Minor laceration of unspecified innominate or subclavian artery, initial encounter',
  'S25119D Minor laceration of unspecified innominate or subclavian artery, subsequent encounter',
  'S25119S Minor laceration of unspecified innominate or subclavian artery, sequela',
  'S25121A Major laceration of right innominate or subclavian artery, initial encounter',
  'S25121D Major laceration of right innominate or subclavian artery, subsequent encounter',
  'S25121S Major laceration of right innominate or subclavian artery, sequela',
  'S25122A Major laceration of left innominate or subclavian artery, initial encounter',
  'S25122D Major laceration of left innominate or subclavian artery, subsequent encounter',
  'S25122S Major laceration of left innominate or subclavian artery, sequela',
  'S25129A Major laceration of unspecified innominate or subclavian artery, initial encounter',
  'S25129D Major laceration of unspecified innominate or subclavian artery, subsequent encounter',
  'S25129S Major laceration of unspecified innominate or subclavian artery, sequela',
  'S25191A Other specified injury of right innominate or subclavian artery, initial encounter',
  'S25191D Other specified injury of right innominate or subclavian artery, subsequent encounter',
  'S25191S Other specified injury of right innominate or subclavian artery, sequela',
  'S25192A Other specified injury of left innominate or subclavian artery, initial encounter',
  'S25192D Other specified injury of left innominate or subclavian artery, subsequent encounter',
  'S25192S Other specified injury of left innominate or subclavian artery, sequela',
  'S25199A Other specified injury of unspecified innominate or subclavian artery, initial encounter',
  'S25199D Other specified injury of unspecified innominate or subclavian artery, subsequent encounter',
  'S25199S Other specified injury of unspecified innominate or subclavian artery, sequela',
  'S2520XA Unspecified injury of superior vena cava, initial encounter',
  'S2520XD Unspecified injury of superior vena cava, subsequent encounter',
  'S2520XS Unspecified injury of superior vena cava, sequela',
  'S2521XA Minor laceration of superior vena cava, initial encounter',
  'S2521XD Minor laceration of superior vena cava, subsequent encounter',
  'S2521XS Minor laceration of superior vena cava, sequela',
  'S2522XA Major laceration of superior vena cava, initial encounter',
  'S2522XD Major laceration of superior vena cava, subsequent encounter',
  'S2522XS Major laceration of superior vena cava, sequela',
  'S2529XA Other specified injury of superior vena cava, initial encounter',
  'S2529XD Other specified injury of superior vena cava, subsequent encounter',
  'S2529XS Other specified injury of superior vena cava, sequela',
  'S25301A Unspecified injury of right innominate or subclavian vein, initial encounter',
  'S25301D Unspecified injury of right innominate or subclavian vein, subsequent encounter',
  'S25301S Unspecified injury of right innominate or subclavian vein, sequela',
  'S25302A Unspecified injury of left innominate or subclavian vein, initial encounter',
  'S25302D Unspecified injury of left innominate or subclavian vein, subsequent encounter',
  'S25302S Unspecified injury of left innominate or subclavian vein, sequela',
  'S25309A Unspecified injury of unspecified innominate or subclavian vein, initial encounter',
  'S25309D Unspecified injury of unspecified innominate or subclavian vein, subsequent encounter',
  'S25309S Unspecified injury of unspecified innominate or subclavian vein, sequela',
  'S25311A Minor laceration of right innominate or subclavian vein, initial encounter',
  'S25311D Minor laceration of right innominate or subclavian vein, subsequent encounter',
  'S25311S Minor laceration of right innominate or subclavian vein, sequela',
  'S25312A Minor laceration of left innominate or subclavian vein, initial encounter',
  'S25312D Minor laceration of left innominate or subclavian vein, subsequent encounter',
  'S25312S Minor laceration of left innominate or subclavian vein, sequela',
  'S25319A Minor laceration of unspecified innominate or subclavian vein, initial encounter',
  'S25319D Minor laceration of unspecified innominate or subclavian vein, subsequent encounter',
  'S25319S Minor laceration of unspecified innominate or subclavian vein, sequela',
  'S25321A Major laceration of right innominate or subclavian vein, initial encounter',
  'S25321D Major laceration of right innominate or subclavian vein, subsequent encounter',
  'S25321S Major laceration of right innominate or subclavian vein, sequela',
  'S25322A Major laceration of left innominate or subclavian vein, initial encounter',
  'S25322D Major laceration of left innominate or subclavian vein, subsequent encounter',
  'S25322S Major laceration of left innominate or subclavian vein, sequela',
  'S25329A Major laceration of unspecified innominate or subclavian vein, initial encounter',
  'S25329D Major laceration of unspecified innominate or subclavian vein, subsequent encounter',
  'S25329S Major laceration of unspecified innominate or subclavian vein, sequela',
  'S25391A Other specified injury of right innominate or subclavian vein, initial encounter',
  'S25391D Other specified injury of right innominate or subclavian vein, subsequent encounter',
  'S25391S Other specified injury of right innominate or subclavian vein, sequela',
  'S25392A Other specified injury of left innominate or subclavian vein, initial encounter',
  'S25392D Other specified injury of left innominate or subclavian vein, subsequent encounter',
  'S25392S Other specified injury of left innominate or subclavian vein, sequela',
  'S25399A Other specified injury of unspecified innominate or subclavian vein, initial encounter',
  'S25399D Other specified injury of unspecified innominate or subclavian vein, subsequent encounter',
  'S25399S Other specified injury of unspecified innominate or subclavian vein, sequela',
  'S25401A Unspecified injury of right pulmonary blood vessels, initial encounter',
  'S25401D Unspecified injury of right pulmonary blood vessels, subsequent encounter',
  'S25401S Unspecified injury of right pulmonary blood vessels, sequela',
  'S25402A Unspecified injury of left pulmonary blood vessels, initial encounter',
  'S25402D Unspecified injury of left pulmonary blood vessels, subsequent encounter',
  'S25402S Unspecified injury of left pulmonary blood vessels, sequela',
  'S25409A Unspecified injury of unspecified pulmonary blood vessels, initial encounter',
  'S25409D Unspecified injury of unspecified pulmonary blood vessels, subsequent encounter',
  'S25409S Unspecified injury of unspecified pulmonary blood vessels, sequela',
  'S25411A Minor laceration of right pulmonary blood vessels, initial encounter',
  'S25411D Minor laceration of right pulmonary blood vessels, subsequent encounter',
  'S25411S Minor laceration of right pulmonary blood vessels, sequela',
  'S25412A Minor laceration of left pulmonary blood vessels, initial encounter',
  'S25412D Minor laceration of left pulmonary blood vessels, subsequent encounter',
  'S25412S Minor laceration of left pulmonary blood vessels, sequela',
  'S25419A Minor laceration of unspecified pulmonary blood vessels, initial encounter',
  'S25419D Minor laceration of unspecified pulmonary blood vessels, subsequent encounter',
  'S25419S Minor laceration of unspecified pulmonary blood vessels, sequela',
  'S25421A Major laceration of right pulmonary blood vessels, initial encounter',
  'S25421D Major laceration of right pulmonary blood vessels, subsequent encounter',
  'S25421S Major laceration of right pulmonary blood vessels, sequela',
  'S25422A Major laceration of left pulmonary blood vessels, initial encounter',
  'S25422D Major laceration of left pulmonary blood vessels, subsequent encounter',
  'S25422S Major laceration of left pulmonary blood vessels, sequela',
  'S25429A Major laceration of unspecified pulmonary blood vessels, initial encounter',
  'S25429D Major laceration of unspecified pulmonary blood vessels, subsequent encounter',
  'S25429S Major laceration of unspecified pulmonary blood vessels, sequela',
  'S25491A Other specified injury of right pulmonary blood vessels, initial encounter',
  'S25491D Other specified injury of right pulmonary blood vessels, subsequent encounter',
  'S25491S Other specified injury of right pulmonary blood vessels, sequela',
  'S25492A Other specified injury of left pulmonary blood vessels, initial encounter',
  'S25492D Other specified injury of left pulmonary blood vessels, subsequent encounter',
  'S25492S Other specified injury of left pulmonary blood vessels, sequela',
  'S25499A Other specified injury of unspecified pulmonary blood vessels, initial encounter',
  'S25499D Other specified injury of unspecified pulmonary blood vessels, subsequent encounter',
  'S25499S Other specified injury of unspecified pulmonary blood vessels, sequela',
  'S25501A Unspecified injury of intercostal blood vessels, right side, initial encounter',
  'S25501D Unspecified injury of intercostal blood vessels, right side, subsequent encounter',
  'S25501S Unspecified injury of intercostal blood vessels, right side, sequela',
  'S25502A Unspecified injury of intercostal blood vessels, left side, initial encounter',
  'S25502D Unspecified injury of intercostal blood vessels, left side, subsequent encounter',
  'S25502S Unspecified injury of intercostal blood vessels, left side, sequela',
  'S25509A Unspecified injury of intercostal blood vessels, unspecified side, initial encounter',
  'S25509D Unspecified injury of intercostal blood vessels, unspecified side, subsequent encounter',
  'S25509S Unspecified injury of intercostal blood vessels, unspecified side, sequela',
  'S25511A Laceration of intercostal blood vessels, right side, initial encounter',
  'S25511D Laceration of intercostal blood vessels, right side, subsequent encounter',
  'S25511S Laceration of intercostal blood vessels, right side, sequela',
  'S25512A Laceration of intercostal blood vessels, left side, initial encounter',
  'S25512D Laceration of intercostal blood vessels, left side, subsequent encounter',
  'S25512S Laceration of intercostal blood vessels, left side, sequela',
  'S25519A Laceration of intercostal blood vessels, unspecified side, initial encounter',
  'S25519D Laceration of intercostal blood vessels, unspecified side, subsequent encounter',
  'S25519S Laceration of intercostal blood vessels, unspecified side, sequela',
  'S25591A Other specified injury of intercostal blood vessels, right side, initial encounter',
  'S25591D Other specified injury of intercostal blood vessels, right side, subsequent encounter',
  'S25591S Other specified injury of intercostal blood vessels, right side, sequela',
  'S25592A Other specified injury of intercostal blood vessels, left side, initial encounter',
  'S25592D Other specified injury of intercostal blood vessels, left side, subsequent encounter',
  'S25592S Other specified injury of intercostal blood vessels, left side, sequela',
  'S25599A Other specified injury of intercostal blood vessels, unspecified side, initial encounter',
  'S25599D Other specified injury of intercostal blood vessels, unspecified side, subsequent encounter',
  'S25599S Other specified injury of intercostal blood vessels, unspecified side, sequela',
  'S25801A Unspecified injury of other blood vessels of thorax, right side, initial encounter',
  'S25801D Unspecified injury of other blood vessels of thorax, right side, subsequent encounter',
  'S25801S Unspecified injury of other blood vessels of thorax, right side, sequela',
  'S25802A Unspecified injury of other blood vessels of thorax, left side, initial encounter',
  'S25802D Unspecified injury of other blood vessels of thorax, left side, subsequent encounter',
  'S25802S Unspecified injury of other blood vessels of thorax, left side, sequela',
  'S25809A Unspecified injury of other blood vessels of thorax, unspecified side, initial encounter',
  'S25809D Unspecified injury of other blood vessels of thorax, unspecified side, subsequent encounter',
  'S25809S Unspecified injury of other blood vessels of thorax, unspecified side, sequela',
  'S25811A Laceration of other blood vessels of thorax, right side, initial encounter',
  'S25811D Laceration of other blood vessels of thorax, right side, subsequent encounter',
  'S25811S Laceration of other blood vessels of thorax, right side, sequela',
  'S25812A Laceration of other blood vessels of thorax, left side, initial encounter',
  'S25812D Laceration of other blood vessels of thorax, left side, subsequent encounter',
  'S25812S Laceration of other blood vessels of thorax, left side, sequela',
  'S25819A Laceration of other blood vessels of thorax, unspecified side, initial encounter',
  'S25819D Laceration of other blood vessels of thorax, unspecified side, subsequent encounter',
  'S25819S Laceration of other blood vessels of thorax, unspecified side, sequela',
  'S25891A Other specified injury of other blood vessels of thorax, right side, initial encounter',
  'S25891D Other specified injury of other blood vessels of thorax, right side, subsequent encounter',
  'S25891S Other specified injury of other blood vessels of thorax, right side, sequela',
  'S25892A Other specified injury of other blood vessels of thorax, left side, initial encounter',
  'S25892D Other specified injury of other blood vessels of thorax, left side, subsequent encounter',
  'S25892S Other specified injury of other blood vessels of thorax, left side, sequela',
  'S25899A Other specified injury of other blood vessels of thorax, unspecified side, initial encounter',
  'S25899D Other specified injury of other blood vessels of thorax, unspecified side, subsequent encounter',
  'S25899S Other specified injury of other blood vessels of thorax, unspecified side, sequela',
  'S2590XA Unspecified injury of unspecified blood vessel of thorax, initial encounter',
  'S2590XD Unspecified injury of unspecified blood vessel of thorax, subsequent encounter',
  'S2590XS Unspecified injury of unspecified blood vessel of thorax, sequela',
  'S2591XA Laceration of unspecified blood vessel of thorax, initial encounter',
  'S2591XD Laceration of unspecified blood vessel of thorax, subsequent encounter',
  'S2591XS Laceration of unspecified blood vessel of thorax, sequela',
  'S2599XA Other specified injury of unspecified blood vessel of thorax, initial encounter',
  'S2599XD Other specified injury of unspecified blood vessel of thorax, subsequent encounter',
  'S2599XS Other specified injury of unspecified blood vessel of thorax, sequela',
  'S2600XA Unspecified injury of heart with hemopericardium, initial encounter',
  'S2600XD Unspecified injury of heart with hemopericardium, subsequent encounter',
  'S2600XS Unspecified injury of heart with hemopericardium, sequela',
  'S2601XA Contusion of heart with hemopericardium, initial encounter',
  'S2601XD Contusion of heart with hemopericardium, subsequent encounter',
  'S2601XS Contusion of heart with hemopericardium, sequela',
  'S26020A Mild laceration of heart with hemopericardium, initial encounter',
  'S26020D Mild laceration of heart with hemopericardium, subsequent encounter',
  'S26020S Mild laceration of heart with hemopericardium, sequela',
  'S26021A Moderate laceration of heart with hemopericardium, initial encounter',
  'S26021D Moderate laceration of heart with hemopericardium, subsequent encounter',
  'S26021S Moderate laceration of heart with hemopericardium, sequela',
  'S26022A Major laceration of heart with hemopericardium, initial encounter',
  'S26022D Major laceration of heart with hemopericardium, subsequent encounter',
  'S26022S Major laceration of heart with hemopericardium, sequela',
  'S2609XA Other injury of heart with hemopericardium, initial encounter',
  'S2609XD Other injury of heart with hemopericardium, subsequent encounter',
  'S2609XS Other injury of heart with hemopericardium, sequela',
  'S2610XA Unspecified injury of heart without hemopericardium, initial encounter',
  'S2610XD Unspecified injury of heart without hemopericardium, subsequent encounter',
  'S2610XS Unspecified injury of heart without hemopericardium, sequela',
  'S2611XA Contusion of heart without hemopericardium, initial encounter',
  'S2611XD Contusion of heart without hemopericardium, subsequent encounter',
  'S2611XS Contusion of heart without hemopericardium, sequela',
  'S2612XA Laceration of heart without hemopericardium, initial encounter',
  'S2612XD Laceration of heart without hemopericardium, subsequent encounter',
  'S2612XS Laceration of heart without hemopericardium, sequela',
  'S2619XA Other injury of heart without hemopericardium, initial encounter',
  'S2619XD Other injury of heart without hemopericardium, subsequent encounter',
  'S2619XS Other injury of heart without hemopericardium, sequela',
  'S2690XA Unspecified injury of heart, unspecified with or without hemopericardium, initial encounter',
  'S2690XD Unspecified injury of heart, unspecified with or without hemopericardium, subsequent',
  'encounter',
  'S2690XS Unspecified injury of heart, unspecified with or without hemopericardium, sequela',
  'S2691XA Contusion of heart, unspecified with or without hemopericardium, initial encounter',
  'S2691XD Contusion of heart, unspecified with or without hemopericardium, subsequent encounter',
  'S2691XS Contusion of heart, unspecified with or without hemopericardium, sequela',
  'S2692XA Laceration of heart, unspecified with or without hemopericardium, initial encounter',
  'S2692XD Laceration of heart, unspecified with or without hemopericardium, subsequent encounter',
  'S2692XS Laceration of heart, unspecified with or without hemopericardium, sequela',
  'S2699XA Other injury of heart, unspecified with or without hemopericardium, initial encounter',
  'S2699XD Other injury of heart, unspecified with or without hemopericardium, subsequent encounter',
  'S2699XS Other injury of heart, unspecified with or without hemopericardium, sequela',
  'S270XXA Traumatic pneumothorax, initial encounter',
  'S270XXD Traumatic pneumothorax, subsequent encounter',
  'S270XXS Traumatic pneumothorax, sequela',
  'S271XXA Traumatic hemothorax, initial encounter',
  'S271XXD Traumatic hemothorax, subsequent encounter',
  'S271XXS Traumatic hemothorax, sequela',
  'S272XXA Traumatic hemopneumothorax, initial encounter',
  'S272XXD Traumatic hemopneumothorax, subsequent encounter',
  'S272XXS Traumatic hemopneumothorax, sequela',
  'S27301A Unspecified injury of lung, unilateral, initial encounter',
  'S27301D Unspecified injury of lung, unilateral, subsequent encounter',
  'S27301S Unspecified injury of lung, unilateral, sequela',
  'S27302A Unspecified injury of lung, bilateral, initial encounter',
  'S27302D Unspecified injury of lung, bilateral, subsequent encounter',
  'S27302S Unspecified injury of lung, bilateral, sequela',
  'S27309A Unspecified injury of lung, unspecified, initial encounter',
  'S27309D Unspecified injury of lung, unspecified, subsequent encounter',
  'S27309S Unspecified injury of lung, unspecified, sequela',
  'S27311A Primary blast injury of lung, unilateral, initial encounter',
  'S27311D Primary blast injury of lung, unilateral, subsequent encounter',
  'S27311S Primary blast injury of lung, unilateral, sequela',
  'S27312A Primary blast injury of lung, bilateral, initial encounter',
  'S27312D Primary blast injury of lung, bilateral, subsequent encounter',
  'S27312S Primary blast injury of lung, bilateral, sequela',
  'S27319A Primary blast injury of lung, unspecified, initial encounter',
  'S27319D Primary blast injury of lung, unspecified, subsequent encounter',
  'S27319S Primary blast injury of lung, unspecified, sequela',
  'S27321A Contusion of lung, unilateral, initial encounter',
  'S27321D Contusion of lung, unilateral, subsequent encounter',
  'S27321S Contusion of lung, unilateral, sequela',
  'S27322A Contusion of lung, bilateral, initial encounter',
  'S27322D Contusion of lung, bilateral, subsequent encounter',
  'S27322S Contusion of lung, bilateral, sequela',
  'S27329A Contusion of lung, unspecified, initial encounter',
  'S27329D Contusion of lung, unspecified, subsequent encounter',
  'S27329S Contusion of lung, unspecified, sequela',
  'S27331A Laceration of lung, unilateral, initial encounter',
  'S27331D Laceration of lung, unilateral, subsequent encounter',
  'S27331S Laceration of lung, unilateral, sequela',
  'S27332A Laceration of lung, bilateral, initial encounter',
  'S27332D Laceration of lung, bilateral, subsequent encounter',
  'S27332S Laceration of lung, bilateral, sequela',
  'S27339A Laceration of lung, unspecified, initial encounter',
  'S27339D Laceration of lung, unspecified, subsequent encounter',
  'S27339S Laceration of lung, unspecified, sequela',
  'S27391A Other injuries of lung, unilateral, initial encounter',
  'S27391D Other injuries of lung, unilateral, subsequent encounter',
  'S27391S Other injuries of lung, unilateral, sequela',
  'S27392A Other injuries of lung, bilateral, initial encounter',
  'S27392D Other injuries of lung, bilateral, subsequent encounter',
  'S27392S Other injuries of lung, bilateral, sequela',
  'S27399A Other injuries of lung, unspecified, initial encounter',
  'S27399D Other injuries of lung, unspecified, subsequent encounter',
  'S27399S Other injuries of lung, unspecified, sequela',
  'S27401A Unspecified injury of bronchus, unilateral, initial encounter',
  'S27401D Unspecified injury of bronchus, unilateral, subsequent encounter',
  'S27401S Unspecified injury of bronchus, unilateral, sequela',
  'S27402A Unspecified injury of bronchus, bilateral, initial encounter',
  'S27402D Unspecified injury of bronchus, bilateral, subsequent encounter',
  'S27402S Unspecified injury of bronchus, bilateral, sequela',
  'S27409A Unspecified injury of bronchus, unspecified, initial encounter',
  'S27409D Unspecified injury of bronchus, unspecified, subsequent encounter',
  'S27409S Unspecified injury of bronchus, unspecified, sequela',
  'S27411A Primary blast injury of bronchus, unilateral, initial encounter',
  'S27411D Primary blast injury of bronchus, unilateral, subsequent encounter',
  'S27411S Primary blast injury of bronchus, unilateral, sequela',
  'S27412A Primary blast injury of bronchus, bilateral, initial encounter',
  'S27412D Primary blast injury of bronchus, bilateral, subsequent encounter',
  'S27412S Primary blast injury of bronchus, bilateral, sequela',
  'S27419A Primary blast injury of bronchus, unspecified, initial encounter',
  'S27419D Primary blast injury of bronchus, unspecified, subsequent encounter',
  'S27419S Primary blast injury of bronchus, unspecified, sequela',
  'S27421A Contusion of bronchus, unilateral, initial encounter',
  'S27421D Contusion of bronchus, unilateral, subsequent encounter',
  'S27421S Contusion of bronchus, unilateral, sequela',
  'S27422A Contusion of bronchus, bilateral, initial encounter',
  'S27422D Contusion of bronchus, bilateral, subsequent encounter',
  'S27422S Contusion of bronchus, bilateral, sequela',
  'S27429A Contusion of bronchus, unspecified, initial encounter',
  'S27429D Contusion of bronchus, unspecified, subsequent encounter',
  'S27429S Contusion of bronchus, unspecified, sequela',
  'S27431A Laceration of bronchus, unilateral, initial encounter',
  'S27431D Laceration of bronchus, unilateral, subsequent encounter',
  'S27431S Laceration of bronchus, unilateral, sequela',
  'S27432A Laceration of bronchus, bilateral, initial encounter',
  'S27432D Laceration of bronchus, bilateral, subsequent encounter',
  'S27432S Laceration of bronchus, bilateral, sequela',
  'S27439A Laceration of bronchus, unspecified, initial encounter',
  'S27439D Laceration of bronchus, unspecified, subsequent encounter',
  'S27439S Laceration of bronchus, unspecified, sequela',
  'S27491A Other injury of bronchus, unilateral, initial encounter',
  'S27491D Other injury of bronchus, unilateral, subsequent encounter',
  'S27491S Other injury of bronchus, unilateral, sequela',
  'S27492A Other injury of bronchus, bilateral, initial encounter',
  'S27492D Other injury of bronchus, bilateral, subsequent encounter',
  'S27492S Other injury of bronchus, bilateral, sequela',
  'S27499A Other injury of bronchus, unspecified, initial encounter',
  'S27499D Other injury of bronchus, unspecified, subsequent encounter',
  'S27499S Other injury of bronchus, unspecified, sequela',
  'S2750XA Unspecified injury of thoracic trachea, initial encounter',
  'S2750XD Unspecified injury of thoracic trachea, subsequent encounter',
  'S2750XS Unspecified injury of thoracic trachea, sequela',
  'S2751XA Primary blast injury of thoracic trachea, initial encounter',
  'S2751XD Primary blast injury of thoracic trachea, subsequent encounter',
  'S2751XS Primary blast injury of thoracic trachea, sequela',
  'S2752XA Contusion of thoracic trachea, initial encounter',
  'S2752XD Contusion of thoracic trachea, subsequent encounter',
  'S2752XS Contusion of thoracic trachea, sequela',
  'S2753XA Laceration of thoracic trachea, initial encounter',
  'S2753XD Laceration of thoracic trachea, subsequent encounter',
  'S2753XS Laceration of thoracic trachea, sequela',
  'S2759XA Other injury of thoracic trachea, initial encounter',
  'S2759XD Other injury of thoracic trachea, subsequent encounter',
  'S2759XS Other injury of thoracic trachea, sequela',
  'S2760XA Unspecified injury of pleura, initial encounter',
  'S2760XD Unspecified injury of pleura, subsequent encounter',
  'S2760XS Unspecified injury of pleura, sequela',
  'S2763XA Laceration of pleura, initial encounter',
  'S2763XD Laceration of pleura, subsequent encounter',
  'S2763XS Laceration of pleura, sequela',
  'S2769XA Other injury of pleura, initial encounter',
  'S2769XD Other injury of pleura, subsequent encounter',
  'S2769XS Other injury of pleura, sequela',
  'S27802A Contusion of diaphragm, initial encounter',
  'S27802D Contusion of diaphragm, subsequent encounter',
  'S27802S Contusion of diaphragm, sequela',
  'S27803A Laceration of diaphragm, initial encounter',
  'S27803D Laceration of diaphragm, subsequent encounter',
  'S27803S Laceration of diaphragm, sequela',
  'S27808A Other injury of diaphragm, initial encounter',
  'S27808D Other injury of diaphragm, subsequent encounter',
  'S27808S Other injury of diaphragm, sequela',
  'S27809A Unspecified injury of diaphragm, initial encounter',
  'S27809D Unspecified injury of diaphragm, subsequent encounter',
  'S27809S Unspecified injury of diaphragm, sequela',
  'S27812A Contusion of esophagus (thoracic part), initial encounter',
  'S27812D Contusion of esophagus (thoracic part), subsequent encounter',
  'S27812S Contusion of esophagus (thoracic part), sequela',
  'S27813A Laceration of esophagus (thoracic part), initial encounter',
  'S27813D Laceration of esophagus (thoracic part), subsequent encounter',
  'S27813S Laceration of esophagus (thoracic part), sequela',
  'S27818A Other injury of esophagus (thoracic part), initial encounter',
  'S27818D Other injury of esophagus (thoracic part), subsequent encounter',
  'S27818S Other injury of esophagus (thoracic part), sequela',
  'S27819A Unspecified injury of esophagus (thoracic part), initial encounter',
  'S27819D Unspecified injury of esophagus (thoracic part), subsequent encounter',
  'S27819S Unspecified injury of esophagus (thoracic part), sequela',
  'S27892A Contusion of other specified intrathoracic organs, initial encounter',
  'S27892D Contusion of other specified intrathoracic organs, subsequent encounter',
  'S27892S Contusion of other specified intrathoracic organs, sequela',
  'S27893A Laceration of other specified intrathoracic organs, initial encounter',
  'S27893D Laceration of other specified intrathoracic organs, subsequent encounter',
  'S27893S Laceration of other specified intrathoracic organs, sequela',
  'S27898A Other injury of other specified intrathoracic organs, initial encounter',
  'S27898D Other injury of other specified intrathoracic organs, subsequent encounter',
  'S27898S Other injury of other specified intrathoracic organs, sequela',
  'S27899A Unspecified injury of other specified intrathoracic organs, initial encounter',
  'S27899D Unspecified injury of other specified intrathoracic organs, subsequent encounter',
  'S27899S Unspecified injury of other specified intrathoracic organs, sequela',
  'S279XXA Injury of unspecified intrathoracic organ, initial encounter',
  'S279XXD Injury of unspecified intrathoracic organ, subsequent encounter',
  'S279XXS Injury of unspecified intrathoracic organ, sequela',
  'S280XXA Crushed chest, initial encounter',
  'S280XXD Crushed chest, subsequent encounter',
  'S280XXS Crushed chest, sequela',
  'S281XXA Traumatic amputation (partial) of part of thorax, except breast, initial encounter',
  'S281XXD Traumatic amputation (partial) of part of thorax, except breast, subsequent encounter',
  'S281XXS Traumatic amputation (partial) of part of thorax, except breast, sequela',
  'S28211A Complete traumatic amputation of right breast, initial encounter',
  'S28211D Complete traumatic amputation of right breast, subsequent encounter',
  'S28211S Complete traumatic amputation of right breast, sequela',
  'S28212A Complete traumatic amputation of left breast, initial encounter',
  'S28212D Complete traumatic amputation of left breast, subsequent encounter',
  'S28212S Complete traumatic amputation of left breast, sequela',
  'S28219A Complete traumatic amputation of unspecified breast, initial encounter',
  'S28219D Complete traumatic amputation of unspecified breast, subsequent encounter',
  'S28219S Complete traumatic amputation of unspecified breast, sequela',
  'S28221A Partial traumatic amputation of right breast, initial encounter',
  'S28221D Partial traumatic amputation of right breast, subsequent encounter',
  'S28221S Partial traumatic amputation of right breast, sequela',
  'S28222A Partial traumatic amputation of left breast, initial encounter',
  'S28222D Partial traumatic amputation of left breast, subsequent encounter',
  'S28222S Partial traumatic amputation of left breast, sequela',
  'S28229A Partial traumatic amputation of unspecified breast, initial encounter',
  'S28229D Partial traumatic amputation of unspecified breast, subsequent encounter',
  'S28229S Partial traumatic amputation of unspecified breast, sequela',
  'S29001A Unspecified injury of muscle and tendon of front wall of thorax, initial encounter',
  'S29001D Unspecified injury of muscle and tendon of front wall of thorax, subsequent encounter',
  'S29001S Unspecified injury of muscle and tendon of front wall of thorax, sequela',
  'S29002A Unspecified injury of muscle and tendon of back wall of thorax, initial encounter',
  'S29002D Unspecified injury of muscle and tendon of back wall of thorax, subsequent encounter',
  'S29002S Unspecified injury of muscle and tendon of back wall of thorax, sequela',
  'S29009A Unspecified injury of muscle and tendon of unspecified wall of thorax, initial encounter',
  'S29009D Unspecified injury of muscle and tendon of unspecified wall of thorax, subsequent encounter',
  'S29009S Unspecified injury of muscle and tendon of unspecified wall of thorax, sequela',
  'S29011A Strain of muscle and tendon of front wall of thorax, initial encounter',
  'S29011D Strain of muscle and tendon of front wall of thorax, subsequent encounter',
  'S29011S Strain of muscle and tendon of front wall of thorax, sequela',
  'S29012A Strain of muscle and tendon of back wall of thorax, initial encounter',
  'S29012D Strain of muscle and tendon of back wall of thorax, subsequent encounter',
  'S29012S Strain of muscle and tendon of back wall of thorax, sequela',
  'S29019A Strain of muscle and tendon of unspecified wall of thorax, initial encounter',
  'S29019D Strain of muscle and tendon of unspecified wall of thorax, subsequent encounter',
  'S29019S Strain of muscle and tendon of unspecified wall of thorax, sequela',
  'S29021A Laceration of muscle and tendon of front wall of thorax, initial encounter',
  'S29021D Laceration of muscle and tendon of front wall of thorax, subsequent encounter',
  'S29021S Laceration of muscle and tendon of front wall of thorax, sequela',
  'S29022A Laceration of muscle and tendon of back wall of thorax, initial encounter',
  'S29022D Laceration of muscle and tendon of back wall of thorax, subsequent encounter',
  'S29022S Laceration of muscle and tendon of back wall of thorax, sequela',
  'S29029A Laceration of muscle and tendon of unspecified wall of thorax, initial encounter',
  'S29029D Laceration of muscle and tendon of unspecified wall of thorax, subsequent encounter',
  'S29029S Laceration of muscle and tendon of unspecified wall of thorax, sequela',
  'S29091A Other injury of muscle and tendon of front wall of thorax, initial encounter',
  'S29091D Other injury of muscle and tendon of front wall of thorax, subsequent encounter',
  'S29091S Other injury of muscle and tendon of front wall of thorax, sequela',
  'S29092A Other injury of muscle and tendon of back wall of thorax, initial encounter',
  'S29092D Other injury of muscle and tendon of back wall of thorax, subsequent encounter',
  'S29092S Other injury of muscle and tendon of back wall of thorax, sequela',
  'S29099A Other injury of muscle and tendon of unspecified wall of thorax, initial encounter',
  'S29099D Other injury of muscle and tendon of unspecified wall of thorax, subsequent encounter',
  'S29099S Other injury of muscle and tendon of unspecified wall of thorax, sequela',
  'S298XXA Other specified injuries of thorax, initial encounter',
  'S298XXD Other specified injuries of thorax, subsequent encounter',
  'S298XXS Other specified injuries of thorax, sequela',
  'S299XXA Unspecified injury of thorax, initial encounter',
  'S299XXD Unspecified injury of thorax, subsequent encounter',
  'S299XXS Unspecified injury of thorax, sequela',
  'S300XXA Contusion of lower back and pelvis, initial encounter',
  'S300XXD Contusion of lower back and pelvis, subsequent encounter',
  'S300XXS Contusion of lower back and pelvis, sequela',
  'S301XXA Contusion of abdominal wall, initial encounter',
  'S301XXD Contusion of abdominal wall, subsequent encounter',
  'S301XXS Contusion of abdominal wall, sequela',
  'S30201A Contusion of unspecified external genital organ, male, initial encounter',
  'S30201D Contusion of unspecified external genital organ, male, subsequent encounter',
  'S30201S Contusion of unspecified external genital organ, male, sequela',
  'S30202A Contusion of unspecified external genital organ, female, initial encounter',
  'S30202D Contusion of unspecified external genital organ, female, subsequent encounter',
  'S30202S Contusion of unspecified external genital organ, female, sequela',
  'S3021XA Contusion of penis, initial encounter',
  'S3021XD Contusion of penis, subsequent encounter',
  'S3021XS Contusion of penis, sequela',
  'S3022XA Contusion of scrotum and testes, initial encounter',
  'S3022XD Contusion of scrotum and testes, subsequent encounter',
  'S3022XS Contusion of scrotum and testes, sequela',
  'S3023XA Contusion of vagina and vulva, initial encounter',
  'S3023XD Contusion of vagina and vulva, subsequent encounter',
  'S3023XS Contusion of vagina and vulva, sequela',
  'S303XXA Contusion of anus, initial encounter',
  'S303XXD Contusion of anus, subsequent encounter',
  'S303XXS Contusion of anus, sequela',
  'S30810A Abrasion of lower back and pelvis, initial encounter',
  'S30810D Abrasion of lower back and pelvis, subsequent encounter',
  'S30810S Abrasion of lower back and pelvis, sequela',
  'S30811A Abrasion of abdominal wall, initial encounter',
  'S30811D Abrasion of abdominal wall, subsequent encounter',
  'S30811S Abrasion of abdominal wall, sequela',
  'S30812A Abrasion of penis, initial encounter',
  'S30812D Abrasion of penis, subsequent encounter',
  'S30812S Abrasion of penis, sequela',
  'S30813A Abrasion of scrotum and testes, initial encounter',
  'S30813D Abrasion of scrotum and testes, subsequent encounter',
  'S30813S Abrasion of scrotum and testes, sequela',
  'S30814A Abrasion of vagina and vulva, initial encounter',
  'S30814D Abrasion of vagina and vulva, subsequent encounter',
  'S30814S Abrasion of vagina and vulva, sequela',
  'S30815A Abrasion of unspecified external genital organs, male, initial encounter',
  'S30815D Abrasion of unspecified external genital organs, male, subsequent encounter',
  'S30815S Abrasion of unspecified external genital organs, male, sequela',
  'S30816A Abrasion of unspecified external genital organs, female, initial encounter',
  'S30816D Abrasion of unspecified external genital organs, female, subsequent encounter',
  'S30816S Abrasion of unspecified external genital organs, female, sequela',
  'S30817A Abrasion of anus, initial encounter',
  'S30817D Abrasion of anus, subsequent encounter',
  'S30817S Abrasion of anus, sequela',
  'S30820A Blister (nonthermal) of lower back and pelvis, initial encounter',
  'S30820D Blister (nonthermal) of lower back and pelvis, subsequent encounter',
  'S30820S Blister (nonthermal) of lower back and pelvis, sequela',
  'S30821A Blister (nonthermal) of abdominal wall, initial encounter',
  'S30821D Blister (nonthermal) of abdominal wall, subsequent encounter',
  'S30821S Blister (nonthermal) of abdominal wall, sequela',
  'S30822A Blister (nonthermal) of penis, initial encounter',
  'S30822D Blister (nonthermal) of penis, subsequent encounter',
  'S30822S Blister (nonthermal) of penis, sequela',
  'S30823A Blister (nonthermal) of scrotum and testes, initial encounter',
  'S30823D Blister (nonthermal) of scrotum and testes, subsequent encounter',
  'S30823S Blister (nonthermal) of scrotum and testes, sequela',
  'S30824A Blister (nonthermal) of vagina and vulva, initial encounter',
  'S30824D Blister (nonthermal) of vagina and vulva, subsequent encounter',
  'S30824S Blister (nonthermal) of vagina and vulva, sequela',
  'S30825A Blister (nonthermal) of unspecified external genital organs, male, initial encounter',
  'S30825D Blister (nonthermal) of unspecified external genital organs, male, subsequent encounter',
  'S30825S Blister (nonthermal) of unspecified external genital organs, male, sequela',
  'S30826A Blister (nonthermal) of unspecified external genital organs, female, initial encounter',
  'S30826D Blister (nonthermal) of unspecified external genital organs, female, subsequent encounter',
  'S30826S Blister (nonthermal) of unspecified external genital organs, female, sequela',
  'S30827A Blister (nonthermal) of anus, initial encounter',
  'S30827D Blister (nonthermal) of anus, subsequent encounter',
  'S30827S Blister (nonthermal) of anus, sequela',
  'S30840A External constriction of lower back and pelvis, initial encounter',
  'S30840D External constriction of lower back and pelvis, subsequent encounter',
  'S30840S External constriction of lower back and pelvis, sequela',
  'S30841A External constriction of abdominal wall, initial encounter',
  'S30841D External constriction of abdominal wall, subsequent encounter',
  'S30841S External constriction of abdominal wall, sequela',
  'S30842A External constriction of penis, initial encounter',
  'S30842D External constriction of penis, subsequent encounter',
  'S30842S External constriction of penis, sequela',
  'S30843A External constriction of scrotum and testes, initial encounter',
  'S30843D External constriction of scrotum and testes, subsequent encounter',
  'S30843S External constriction of scrotum and testes, sequela',
  'S30844A External constriction of vagina and vulva, initial encounter',
  'S30844D External constriction of vagina and vulva, subsequent encounter',
  'S30844S External constriction of vagina and vulva, sequela',
  'S30845A External constriction of unspecified external genital organs, male, initial encounter',
  'S30845D External constriction of unspecified external genital organs, male, subsequent encounter',
  'S30845S External constriction of unspecified external genital organs, male, sequela',
  'S30846A External constriction of unspecified external genital organs, female, initial encounter',
  'S30846D External constriction of unspecified external genital organs, female, subsequent encounter',
  'S30846S External constriction of unspecified external genital organs, female, sequela',
  'S30850A Superficial foreign body of lower back and pelvis, initial encounter',
  'S30850D Superficial foreign body of lower back and pelvis, subsequent encounter',
  'S30850S Superficial foreign body of lower back and pelvis, sequela',
  'S30851A Superficial foreign body of abdominal wall, initial encounter',
  'S30851D Superficial foreign body of abdominal wall, subsequent encounter',
  'S30851S Superficial foreign body of abdominal wall, sequela',
  'S30852A Superficial foreign body of penis, initial encounter',
  'S30852D Superficial foreign body of penis, subsequent encounter',
  'S30852S Superficial foreign body of penis, sequela',
  'S30853A Superficial foreign body of scrotum and testes, initial encounter',
  'S30853D Superficial foreign body of scrotum and testes, subsequent encounter',
  'S30853S Superficial foreign body of scrotum and testes, sequela',
  'S30854A Superficial foreign body of vagina and vulva, initial encounter',
  'S30854D Superficial foreign body of vagina and vulva, subsequent encounter',
  'S30854S Superficial foreign body of vagina and vulva, sequela',
  'S30855A Superficial foreign body of unspecified external genital organs, male, initial encounter',
  'S30855D Superficial foreign body of unspecified external genital organs, male, subsequent encounter',
  'S30855S Superficial foreign body of unspecified external genital organs, male, sequela',
  'S30856A Superficial foreign body of unspecified external genital organs, female, initial encounter',
  'S30856D Superficial foreign body of unspecified external genital organs, female, subsequent encounter',
  'S30856S Superficial foreign body of unspecified external genital organs, female, sequela',
  'S30857A Superficial foreign body of anus, initial encounter',
  'S30857D Superficial foreign body of anus, subsequent encounter',
  'S30857S Superficial foreign body of anus, sequela',
  'S30860A Insect bite (nonvenomous) of lower back and pelvis, initial encounter',
  'S30860D Insect bite (nonvenomous) of lower back and pelvis, subsequent encounter',
  'S30860S Insect bite (nonvenomous) of lower back and pelvis, sequela',
  'S30861A Insect bite (nonvenomous) of abdominal wall, initial encounter',
  'S30861D Insect bite (nonvenomous) of abdominal wall, subsequent encounter',
  'S30861S Insect bite (nonvenomous) of abdominal wall, sequela',
  'S30862A Insect bite (nonvenomous) of penis, initial encounter',
  'S30862D Insect bite (nonvenomous) of penis, subsequent encounter',
  'S30862S Insect bite (nonvenomous) of penis, sequela',
  'S30863A Insect bite (nonvenomous) of scrotum and testes, initial encounter',
  'S30863D Insect bite (nonvenomous) of scrotum and testes, subsequent encounter',
  'S30863S Insect bite (nonvenomous) of scrotum and testes, sequela',
  'S30864A Insect bite (nonvenomous) of vagina and vulva, initial encounter',
  'S30864D Insect bite (nonvenomous) of vagina and vulva, subsequent encounter',
  'S30864S Insect bite (nonvenomous) of vagina and vulva, sequela',
  'S30865A Insect bite (nonvenomous) of unspecified external genital organs, male, initial encounter',
  'S30865D Insect bite (nonvenomous) of unspecified external genital organs, male, subsequent encounter',
  'S30865S Insect bite (nonvenomous) of unspecified external genital organs, male, sequela',
  'S30866A Insect bite (nonvenomous) of unspecified external genital organs, female, initial encounter',
  'S30866D Insect bite (nonvenomous) of unspecified external genital organs, female, subsequent',
  'encounter',
  'S30866S Insect bite (nonvenomous) of unspecified external genital organs, female, sequela',
  'S30867A Insect bite (nonvenomous) of anus, initial encounter',
  'S30867D Insect bite (nonvenomous) of anus, subsequent encounter',
  'S30867S Insect bite (nonvenomous) of anus, sequela',
  'S30870A Other superficial bite of lower back and pelvis, initial encounter',
  'S30870D Other superficial bite of lower back and pelvis, subsequent encounter',
  'S30870S Other superficial bite of lower back and pelvis, sequela',
  'S30871A Other superficial bite of abdominal wall, initial encounter',
  'S30871D Other superficial bite of abdominal wall, subsequent encounter',
  'S30871S Other superficial bite of abdominal wall, sequela',
  'S30872A Other superficial bite of penis, initial encounter',
  'S30872D Other superficial bite of penis, subsequent encounter',
  'S30872S Other superficial bite of penis, sequela',
  'S30873A Other superficial bite of scrotum and testes, initial encounter',
  'S30873D Other superficial bite of scrotum and testes, subsequent encounter',
  'S30873S Other superficial bite of scrotum and testes, sequela',
  'S30874A Other superficial bite of vagina and vulva, initial encounter',
  'S30874D Other superficial bite of vagina and vulva, subsequent encounter',
  'S30874S Other superficial bite of vagina and vulva, sequela',
  'S30875A Other superficial bite of unspecified external genital organs, male, initial encounter',
  'S30875D Other superficial bite of unspecified external genital organs, male, subsequent encounter',
  'S30875S Other superficial bite of unspecified external genital organs, male, sequela',
  'S30876A Other superficial bite of unspecified external genital organs, female, initial encounter',
  'S30876D Other superficial bite of unspecified external genital organs, female, subsequent encounter',
  'S30876S Other superficial bite of unspecified external genital organs, female, sequela',
  'S30877A Other superficial bite of anus, initial encounter',
  'S30877D Other superficial bite of anus, subsequent encounter',
  'S30877S Other superficial bite of anus, sequela',
  'S3091XA Unspecified superficial injury of lower back and pelvis, initial encounter',
  'S3091XD Unspecified superficial injury of lower back and pelvis, subsequent encounter',
  'S3091XS Unspecified superficial injury of lower back and pelvis, sequela',
  'S3092XA Unspecified superficial injury of abdominal wall, initial encounter',
  'S3092XD Unspecified superficial injury of abdominal wall, subsequent encounter',
  'S3092XS Unspecified superficial injury of abdominal wall, sequela',
  'S3093XA Unspecified superficial injury of penis, initial encounter',
  'S3093XD Unspecified superficial injury of penis, subsequent encounter',
  'S3093XS Unspecified superficial injury of penis, sequela',
  'S3094XA Unspecified superficial injury of scrotum and testes, initial encounter',
  'S3094XD Unspecified superficial injury of scrotum and testes, subsequent encounter',
  'S3094XS Unspecified superficial injury of scrotum and testes, sequela',
  'S3095XA Unspecified superficial injury of vagina and vulva, initial encounter',
  'S3095XD Unspecified superficial injury of vagina and vulva, subsequent encounter',
  'S3095XS Unspecified superficial injury of vagina and vulva, sequela',
  'S3096XA Unspecified superficial injury of unspecified external genital organs, male, initial encounter',
  'S3096XD Unspecified superficial injury of unspecified external genital organs, male, subsequent',
  'encounter',
  'S3096XS Unspecified superficial injury of unspecified external genital organs, male, sequela',
  'S3097XA Unspecified superficial injury of unspecified external genital organs, female, initial encounter',
  'S3097XD Unspecified superficial injury of unspecified external genital organs, female, subsequent',
  'encounter',
  'S3097XS Unspecified superficial injury of unspecified external genital organs, female, sequela',
  'S3098XA Unspecified superficial injury of anus, initial encounter',
  'S3098XD Unspecified superficial injury of anus, subsequent encounter',
  'S3098XS Unspecified superficial injury of anus, sequela',
  'S31000A Unspecified open wound of lower back and pelvis without penetration into retroperitoneum,',
  'initial encounter',
  'S31000D Unspecified open wound of lower back and pelvis without penetration into retroperitoneum,',
  'subsequent encounter',
  'S31000S Unspecified open wound of lower back and pelvis without penetration into retroperitoneum,',
  'sequela',
  'S31001A Unspecified open wound of lower back and pelvis with penetration into retroperitoneum,',
  'initial encounter',
  'S31001D Unspecified open wound of lower back and pelvis with penetration into retroperitoneum,',
  'subsequent encounter',
  'S31001S Unspecified open wound of lower back and pelvis with penetration into retroperitoneum,',
  'sequela',
  'S31010A Laceration without foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, initial encounter',
  'S31010D Laceration without foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, subsequent encounter',
  'S31010S Laceration without foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, sequela',
  'S31011A Laceration without foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, initial encounter',
  'S31011D Laceration without foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, subsequent encounter',
  'S31011S Laceration without foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, sequela',
  'S31020A Laceration with foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, initial encounter',
  'S31020D Laceration with foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, subsequent encounter',
  'S31020S Laceration with foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, sequela',
  'S31021A Laceration with foreign body of lower back and pelvis with penetration into retroperitoneum,',
  'initial encounter',
  'S31021D Laceration with foreign body of lower back and pelvis with penetration into retroperitoneum,',
  'subsequent encounter',
  'S31021S Laceration with foreign body of lower back and pelvis with penetration into retroperitoneum,',
  'sequela',
  'S31030A Puncture wound without foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, initial encounter',
  'S31030D Puncture wound without foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, subsequent encounter',
  'S31030S Puncture wound without foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, sequela',
  'S31031A Puncture wound without foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, initial encounter',
  'S31031D Puncture wound without foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, subsequent encounter',
  'S31031S Puncture wound without foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, sequela',
  'S31040A Puncture wound with foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, initial encounter',
  'S31040D Puncture wound with foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, subsequent encounter',
  'S31040S Puncture wound with foreign body of lower back and pelvis without penetration into',
  'retroperitoneum, sequela',
  'S31041A Puncture wound with foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, initial encounter',
  'S31041D Puncture wound with foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, subsequent encounter',
  'S31041S Puncture wound with foreign body of lower back and pelvis with penetration into',
  'retroperitoneum, sequela',
  'S31050A Open bite of lower back and pelvis without penetration into retroperitoneum, initial encounter',
  'S31050D Open bite of lower back and pelvis without penetration into retroperitoneum, subsequent',
  'encounter',
  'S31050S Open bite of lower back and pelvis without penetration into retroperitoneum, sequela',
  'S31051A Open bite of lower back and pelvis with penetration into retroperitoneum, initial encounter',
  'S31051D Open bite of lower back and pelvis with penetration into retroperitoneum, subsequent',
  'encounter',
  'S31051S Open bite of lower back and pelvis with penetration into retroperitoneum, sequela',
  'S31100A Unspecified open wound of abdominal wall, right upper quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31100D Unspecified open wound of abdominal wall, right upper quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31100S Unspecified open wound of abdominal wall, right upper quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31101A Unspecified open wound of abdominal wall, left upper quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31101D Unspecified open wound of abdominal wall, left upper quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31101S Unspecified open wound of abdominal wall, left upper quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31102A Unspecified open wound of abdominal wall, epigastric region without penetration into',
  'peritoneal cavity, initial encounter',
  'S31102D Unspecified open wound of abdominal wall, epigastric region without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31102S Unspecified open wound of abdominal wall, epigastric region without penetration into',
  'peritoneal cavity, sequela',
  'S31103A Unspecified open wound of abdominal wall, right lower quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31103D Unspecified open wound of abdominal wall, right lower quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31103S Unspecified open wound of abdominal wall, right lower quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31104A Unspecified open wound of abdominal wall, left lower quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31104D Unspecified open wound of abdominal wall, left lower quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31104S Unspecified open wound of abdominal wall, left lower quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31105A Unspecified open wound of abdominal wall, periumbilic region without penetration into',
  'peritoneal cavity, initial encounter',
  'S31105D Unspecified open wound of abdominal wall, periumbilic region without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31105S Unspecified open wound of abdominal wall, periumbilic region without penetration into',
  'peritoneal cavity, sequela',
  'S31109A Unspecified open wound of abdominal wall, unspecified quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31109D Unspecified open wound of abdominal wall, unspecified quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31109S Unspecified open wound of abdominal wall, unspecified quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31110A Laceration without foreign body of abdominal wall, right upper quadrant without penetration',
  'into peritoneal cavity, initial encounter',
  'S31110D Laceration without foreign body of abdominal wall, right upper quadrant without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31110S Laceration without foreign body of abdominal wall, right upper quadrant without penetration',
  'into peritoneal cavity, sequela',
  'S31111A Laceration without foreign body of abdominal wall, left upper quadrant without penetration',
  'into peritoneal cavity, initial encounter',
  'S31111D Laceration without foreign body of abdominal wall, left upper quadrant without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31111S Laceration without foreign body of abdominal wall, left upper quadrant without penetration',
  'into peritoneal cavity, sequela',
  'S31112A Laceration without foreign body of abdominal wall, epigastric region without penetration into',
  'peritoneal cavity, initial encounter',
  'S31112D Laceration without foreign body of abdominal wall, epigastric region without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31112S Laceration without foreign body of abdominal wall, epigastric region without penetration into',
  'peritoneal cavity, sequela',
  'S31113A Laceration without foreign body of abdominal wall, right lower quadrant without penetration',
  'into peritoneal cavity, initial encounter',
  'S31113D Laceration without foreign body of abdominal wall, right lower quadrant without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31113S Laceration without foreign body of abdominal wall, right lower quadrant without penetration',
  'into peritoneal cavity, sequela',
  'S31114A Laceration without foreign body of abdominal wall, left lower quadrant without penetration',
  'into peritoneal cavity, initial encounter',
  'S31114D Laceration without foreign body of abdominal wall, left lower quadrant without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31114S Laceration without foreign body of abdominal wall, left lower quadrant without penetration',
  'into peritoneal cavity, sequela',
  'S31115A Laceration without foreign body of abdominal wall, periumbilic region without penetration into',
  'peritoneal cavity, initial encounter',
  'S31115D Laceration without foreign body of abdominal wall, periumbilic region without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31115S Laceration without foreign body of abdominal wall, periumbilic region without penetration into',
  'peritoneal cavity, sequela',
  'S31119A Laceration without foreign body of abdominal wall, unspecified quadrant without penetration',
  'into peritoneal cavity, initial encounter',
  'S31119D Laceration without foreign body of abdominal wall, unspecified quadrant without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31119S Laceration without foreign body of abdominal wall, unspecified quadrant without penetration',
  'into peritoneal cavity, sequela',
  'S31120A Laceration of abdominal wall with foreign body, right upper quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31120D Laceration of abdominal wall with foreign body, right upper quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31120S Laceration of abdominal wall with foreign body, right upper quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31121A Laceration of abdominal wall with foreign body, left upper quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31121D Laceration of abdominal wall with foreign body, left upper quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31121S Laceration of abdominal wall with foreign body, left upper quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31122A Laceration of abdominal wall with foreign body, epigastric region without penetration into',
  'peritoneal cavity, initial encounter',
  'S31122D Laceration of abdominal wall with foreign body, epigastric region without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31122S Laceration of abdominal wall with foreign body, epigastric region without penetration into',
  'peritoneal cavity, sequela',
  'S31123A Laceration of abdominal wall with foreign body, right lower quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31123D Laceration of abdominal wall with foreign body, right lower quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31123S Laceration of abdominal wall with foreign body, right lower quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31124A Laceration of abdominal wall with foreign body, left lower quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31124D Laceration of abdominal wall with foreign body, left lower quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31124S Laceration of abdominal wall with foreign body, left lower quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31125A Laceration of abdominal wall with foreign body, periumbilic region without penetration into',
  'peritoneal cavity, initial encounter',
  'S31125D Laceration of abdominal wall with foreign body, periumbilic region without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31125S Laceration of abdominal wall with foreign body, periumbilic region without penetration into',
  'peritoneal cavity, sequela',
  'S31129A Laceration of abdominal wall with foreign body, unspecified quadrant without penetration into',
  'peritoneal cavity, initial encounter',
  'S31129D Laceration of abdominal wall with foreign body, unspecified quadrant without penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31129S Laceration of abdominal wall with foreign body, unspecified quadrant without penetration into',
  'peritoneal cavity, sequela',
  'S31130A Puncture wound of abdominal wall without foreign body, right upper quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31130D Puncture wound of abdominal wall without foreign body, right upper quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31130S Puncture wound of abdominal wall without foreign body, right upper quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31131A Puncture wound of abdominal wall without foreign body, left upper quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31131D Puncture wound of abdominal wall without foreign body, left upper quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31131S Puncture wound of abdominal wall without foreign body, left upper quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31132A Puncture wound of abdominal wall without foreign body, epigastric region without penetration',
  'into peritoneal cavity, initial encounter',
  'S31132D Puncture wound of abdominal wall without foreign body, epigastric region without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31132S Puncture wound of abdominal wall without foreign body, epigastric region without penetration',
  'into peritoneal cavity, sequela',
  'S31133A Puncture wound of abdominal wall without foreign body, right lower quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31133D Puncture wound of abdominal wall without foreign body, right lower quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31133S Puncture wound of abdominal wall without foreign body, right lower quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31134A Puncture wound of abdominal wall without foreign body, left lower quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31134D Puncture wound of abdominal wall without foreign body, left lower quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31134S Puncture wound of abdominal wall without foreign body, left lower quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31135A Puncture wound of abdominal wall without foreign body, periumbilic region without',
  'penetration into peritoneal cavity, initial encounter',
  'S31135D Puncture wound of abdominal wall without foreign body, periumbilic region without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31135S Puncture wound of abdominal wall without foreign body, periumbilic region without',
  'penetration into peritoneal cavity, sequela',
  'S31139A Puncture wound of abdominal wall without foreign body, unspecified quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31139D Puncture wound of abdominal wall without foreign body, unspecified quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31139S Puncture wound of abdominal wall without foreign body, unspecified quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31140A Puncture wound of abdominal wall with foreign body, right upper quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31140D Puncture wound of abdominal wall with foreign body, right upper quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31140S Puncture wound of abdominal wall with foreign body, right upper quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31141A Puncture wound of abdominal wall with foreign body, left upper quadrant without penetration',
  'into peritoneal cavity, initial encounter',
  'S31141D Puncture wound of abdominal wall with foreign body, left upper quadrant without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31141S Puncture wound of abdominal wall with foreign body, left upper quadrant without penetration',
  'into peritoneal cavity, sequela',
  'S31142A Puncture wound of abdominal wall with foreign body, epigastric region without penetration',
  'into peritoneal cavity, initial encounter',
  'S31142D Puncture wound of abdominal wall with foreign body, epigastric region without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31142S Puncture wound of abdominal wall with foreign body, epigastric region without penetration',
  'into peritoneal cavity, sequela',
  'S31143A Puncture wound of abdominal wall with foreign body, right lower quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31143D Puncture wound of abdominal wall with foreign body, right lower quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31143S Puncture wound of abdominal wall with foreign body, right lower quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31144A Puncture wound of abdominal wall with foreign body, left lower quadrant without penetration',
  'into peritoneal cavity, initial encounter',
  'S31144D Puncture wound of abdominal wall with foreign body, left lower quadrant without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31144S Puncture wound of abdominal wall with foreign body, left lower quadrant without penetration',
  'into peritoneal cavity, sequela',
  'S31145A Puncture wound of abdominal wall with foreign body, periumbilic region without penetration',
  'into peritoneal cavity, initial encounter',
  'S31145D Puncture wound of abdominal wall with foreign body, periumbilic region without penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31145S Puncture wound of abdominal wall with foreign body, periumbilic region without penetration',
  'into peritoneal cavity, sequela',
  'S31149A Puncture wound of abdominal wall with foreign body, unspecified quadrant without',
  'penetration into peritoneal cavity, initial encounter',
  'S31149D Puncture wound of abdominal wall with foreign body, unspecified quadrant without',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31149S Puncture wound of abdominal wall with foreign body, unspecified quadrant without',
  'penetration into peritoneal cavity, sequela',
  'S31150A Open bite of abdominal wall, right upper quadrant without penetration into peritoneal cavity,',
  'initial encounter',
  'S31150D Open bite of abdominal wall, right upper quadrant without penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31150S Open bite of abdominal wall, right upper quadrant without penetration into peritoneal cavity,',
  'sequela',
  'S31151A Open bite of abdominal wall, left upper quadrant without penetration into peritoneal cavity,',
  'initial encounter',
  'S31151D Open bite of abdominal wall, left upper quadrant without penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31151S Open bite of abdominal wall, left upper quadrant without penetration into peritoneal cavity,',
  'sequela',
  'S31152A Open bite of abdominal wall, epigastric region without penetration into peritoneal cavity,',
  'initial encounter',
  'S31152D Open bite of abdominal wall, epigastric region without penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31152S Open bite of abdominal wall, epigastric region without penetration into peritoneal cavity,',
  'sequela',
  'S31153A Open bite of abdominal wall, right lower quadrant without penetration into peritoneal cavity,',
  'initial encounter',
  'S31153D Open bite of abdominal wall, right lower quadrant without penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31153S Open bite of abdominal wall, right lower quadrant without penetration into peritoneal cavity,',
  'sequela',
  'S31154A Open bite of abdominal wall, left lower quadrant without penetration into peritoneal cavity,',
  'initial encounter',
  'S31154D Open bite of abdominal wall, left lower quadrant without penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31154S Open bite of abdominal wall, left lower quadrant without penetration into peritoneal cavity,',
  'sequela',
  'S31155A Open bite of abdominal wall, periumbilic region without penetration into peritoneal cavity,',
  'initial encounter',
  'S31155D Open bite of abdominal wall, periumbilic region without penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31155S Open bite of abdominal wall, periumbilic region without penetration into peritoneal cavity,',
  'sequela',
  'S31159A Open bite of abdominal wall, unspecified quadrant without penetration into peritoneal cavity,',
  'initial encounter',
  'S31159D Open bite of abdominal wall, unspecified quadrant without penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31159S Open bite of abdominal wall, unspecified quadrant without penetration into peritoneal cavity,',
  'sequela',
  'S3120XA Unspecified open wound of penis, initial encounter',
  'S3120XD Unspecified open wound of penis, subsequent encounter',
  'S3120XS Unspecified open wound of penis, sequela',
  'S3121XA Laceration without foreign body of penis, initial encounter',
  'S3121XD Laceration without foreign body of penis, subsequent encounter',
  'S3121XS Laceration without foreign body of penis, sequela',
  'S3122XA Laceration with foreign body of penis, initial encounter',
  'S3122XD Laceration with foreign body of penis, subsequent encounter',
  'S3122XS Laceration with foreign body of penis, sequela',
  'S3123XA Puncture wound without foreign body of penis, initial encounter',
  'S3123XD Puncture wound without foreign body of penis, subsequent encounter',
  'S3123XS Puncture wound without foreign body of penis, sequela',
  'S3124XA Puncture wound with foreign body of penis, initial encounter',
  'S3124XD Puncture wound with foreign body of penis, subsequent encounter',
  'S3124XS Puncture wound with foreign body of penis, sequela',
  'S3125XA Open bite of penis, initial encounter',
  'S3125XD Open bite of penis, subsequent encounter',
  'S3125XS Open bite of penis, sequela',
  'S3130XA Unspecified open wound of scrotum and testes, initial encounter',
  'S3130XD Unspecified open wound of scrotum and testes, subsequent encounter',
  'S3130XS Unspecified open wound of scrotum and testes, sequela',
  'S3131XA Laceration without foreign body of scrotum and testes, initial encounter',
  'S3131XD Laceration without foreign body of scrotum and testes, subsequent encounter',
  'S3131XS Laceration without foreign body of scrotum and testes, sequela',
  'S3132XA Laceration with foreign body of scrotum and testes, initial encounter',
  'S3132XD Laceration with foreign body of scrotum and testes, subsequent encounter',
  'S3132XS Laceration with foreign body of scrotum and testes, sequela',
  'S3133XA Puncture wound without foreign body of scrotum and testes, initial encounter',
  'S3133XD Puncture wound without foreign body of scrotum and testes, subsequent encounter',
  'S3133XS Puncture wound without foreign body of scrotum and testes, sequela',
  'S3134XA Puncture wound with foreign body of scrotum and testes, initial encounter',
  'S3134XD Puncture wound with foreign body of scrotum and testes, subsequent encounter',
  'S3134XS Puncture wound with foreign body of scrotum and testes, sequela',
  'S3135XA Open bite of scrotum and testes, initial encounter',
  'S3135XD Open bite of scrotum and testes, subsequent encounter',
  'S3135XS Open bite of scrotum and testes, sequela',
  'S3140XA Unspecified open wound of vagina and vulva, initial encounter',
  'S3140XD Unspecified open wound of vagina and vulva, subsequent encounter',
  'S3140XS Unspecified open wound of vagina and vulva, sequela',
  'S3141XA Laceration without foreign body of vagina and vulva, initial encounter',
  'S3141XD Laceration without foreign body of vagina and vulva, subsequent encounter',
  'S3141XS Laceration without foreign body of vagina and vulva, sequela',
  'S3142XA Laceration with foreign body of vagina and vulva, initial encounter',
  'S3142XD Laceration with foreign body of vagina and vulva, subsequent encounter',
  'S3142XS Laceration with foreign body of vagina and vulva, sequela',
  'S3143XA Puncture wound without foreign body of vagina and vulva, initial encounter',
  'S3143XD Puncture wound without foreign body of vagina and vulva, subsequent encounter',
  'S3143XS Puncture wound without foreign body of vagina and vulva, sequela',
  'S3144XA Puncture wound with foreign body of vagina and vulva, initial encounter',
  'S3144XD Puncture wound with foreign body of vagina and vulva, subsequent encounter',
  'S3144XS Puncture wound with foreign body of vagina and vulva, sequela',
  'S3145XA Open bite of vagina and vulva, initial encounter',
  'S3145XD Open bite of vagina and vulva, subsequent encounter',
  'S3145XS Open bite of vagina and vulva, sequela',
  'S31501A Unspecified open wound of unspecified external genital organs, male, initial encounter',
  'S31501D Unspecified open wound of unspecified external genital organs, male, subsequent encounter',
  'S31501S Unspecified open wound of unspecified external genital organs, male, sequela',
  'S31502A Unspecified open wound of unspecified external genital organs, female, initial encounter',
  'S31502D Unspecified open wound of unspecified external genital organs, female, subsequent encounter',
  'S31502S Unspecified open wound of unspecified external genital organs, female, sequela',
  'S31511A Laceration without foreign body of unspecified external genital organs, male, initial encounter',
  'S31511D Laceration without foreign body of unspecified external genital organs, male, subsequent',
  'encounter',
  'S31511S Laceration without foreign body of unspecified external genital organs, male, sequela',
  'S31512A Laceration without foreign body of unspecified external genital organs, female, initial',
  'encounter',
  'S31512D Laceration without foreign body of unspecified external genital organs, female, subsequent',
  'encounter',
  'S31512S Laceration without foreign body of unspecified external genital organs, female, sequela',
  'S31521A Laceration with foreign body of unspecified external genital organs, male, initial encounter',
  'S31521D Laceration with foreign body of unspecified external genital organs, male, subsequent',
  'encounter',
  'S31521S Laceration with foreign body of unspecified external genital organs, male, sequela',
  'S31522A Laceration with foreign body of unspecified external genital organs, female, initial encounter',
  'S31522D Laceration with foreign body of unspecified external genital organs, female, subsequent',
  'encounter',
  'S31522S Laceration with foreign body of unspecified external genital organs, female, sequela',
  'S31531A Puncture wound without foreign body of unspecified external genital organs, male, initial',
  'encounter',
  'S31531D Puncture wound without foreign body of unspecified external genital organs, male,',
  'subsequent encounter',
  'S31531S Puncture wound without foreign body of unspecified external genital organs, male, sequela',
  'S31532A Puncture wound without foreign body of unspecified external genital organs, female, initial',
  'encounter',
  'S31532D Puncture wound without foreign body of unspecified external genital organs, female,',
  'subsequent encounter',
  'S31532S Puncture wound without foreign body of unspecified external genital organs, female, sequela',
  'S31541A Puncture wound with foreign body of unspecified external genital organs, male, initial',
  'encounter',
  'S31541D Puncture wound with foreign body of unspecified external genital organs, male, subsequent',
  'encounter',
  'S31541S Puncture wound with foreign body of unspecified external genital organs, male, sequela',
  'S31542A Puncture wound with foreign body of unspecified external genital organs, female, initial',
  'encounter',
  'S31542D Puncture wound with foreign body of unspecified external genital organs, female, subsequent',
  'encounter',
  'S31542S Puncture wound with foreign body of unspecified external genital organs, female, sequela',
  'S31551A Open bite of unspecified external genital organs, male, initial encounter',
  'S31551D Open bite of unspecified external genital organs, male, subsequent encounter',
  'S31551S Open bite of unspecified external genital organs, male, sequela',
  'S31552A Open bite of unspecified external genital organs, female, initial encounter',
  'S31552D Open bite of unspecified external genital organs, female, subsequent encounter',
  'S31552S Open bite of unspecified external genital organs, female, sequela',
  'S31600A Unspecified open wound of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31600D Unspecified open wound of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31600S Unspecified open wound of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31601A Unspecified open wound of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31601D Unspecified open wound of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31601S Unspecified open wound of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31602A Unspecified open wound of abdominal wall, epigastric region with penetration into peritoneal',
  'cavity, initial encounter',
  'S31602D Unspecified open wound of abdominal wall, epigastric region with penetration into peritoneal',
  'cavity, subsequent encounter',
  'S31602S Unspecified open wound of abdominal wall, epigastric region with penetration into peritoneal',
  'cavity, sequela',
  'S31603A Unspecified open wound of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31603D Unspecified open wound of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31603S Unspecified open wound of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31604A Unspecified open wound of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31604D Unspecified open wound of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31604S Unspecified open wound of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31605A Unspecified open wound of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, initial encounter',
  'S31605D Unspecified open wound of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31605S Unspecified open wound of abdominal wall, periumbilic region with penetration into peritoneal',
  'cavity, sequela',
  'S31609A Unspecified open wound of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31609D Unspecified open wound of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31609S Unspecified open wound of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31610A Laceration without foreign body of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31610D Laceration without foreign body of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31610S Laceration without foreign body of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31611A Laceration without foreign body of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31611D Laceration without foreign body of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31611S Laceration without foreign body of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31612A Laceration without foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, initial encounter',
  'S31612D Laceration without foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31612S Laceration without foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, sequela',
  'S31613A Laceration without foreign body of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31613D Laceration without foreign body of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31613S Laceration without foreign body of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31614A Laceration without foreign body of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31614D Laceration without foreign body of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31614S Laceration without foreign body of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31615A Laceration without foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, initial encounter',
  'S31615D Laceration without foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31615S Laceration without foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, sequela',
  'S31619A Laceration without foreign body of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31619D Laceration without foreign body of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31619S Laceration without foreign body of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31620A Laceration with foreign body of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31620D Laceration with foreign body of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31620S Laceration with foreign body of abdominal wall, right upper quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31621A Laceration with foreign body of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31621D Laceration with foreign body of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31621S Laceration with foreign body of abdominal wall, left upper quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31622A Laceration with foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, initial encounter',
  'S31622D Laceration with foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31622S Laceration with foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, sequela',
  'S31623A Laceration with foreign body of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31623D Laceration with foreign body of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31623S Laceration with foreign body of abdominal wall, right lower quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31624A Laceration with foreign body of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31624D Laceration with foreign body of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31624S Laceration with foreign body of abdominal wall, left lower quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31625A Laceration with foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, initial encounter',
  'S31625D Laceration with foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31625S Laceration with foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, sequela',
  'S31629A Laceration with foreign body of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, initial encounter',
  'S31629D Laceration with foreign body of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31629S Laceration with foreign body of abdominal wall, unspecified quadrant with penetration into',
  'peritoneal cavity, sequela',
  'S31630A Puncture wound without foreign body of abdominal wall, right upper quadrant with',
  'penetration into peritoneal cavity, initial encounter',
  'S31630D Puncture wound without foreign body of abdominal wall, right upper quadrant with',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31630S Puncture wound without foreign body of abdominal wall, right upper quadrant with',
  'penetration into peritoneal cavity, sequela',
  'S31631A Puncture wound without foreign body of abdominal wall, left upper quadrant with penetration',
  'into peritoneal cavity, initial encounter',
  'S31631D Puncture wound without foreign body of abdominal wall, left upper quadrant with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31631S Puncture wound without foreign body of abdominal wall, left upper quadrant with penetration',
  'into peritoneal cavity, sequela',
  'S31632A Puncture wound without foreign body of abdominal wall, epigastric region with penetration',
  'into peritoneal cavity, initial encounter',
  'S31632D Puncture wound without foreign body of abdominal wall, epigastric region with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31632S Puncture wound without foreign body of abdominal wall, epigastric region with penetration',
  'into peritoneal cavity, sequela',
  'S31633A Puncture wound without foreign body of abdominal wall, right lower quadrant with',
  'penetration into peritoneal cavity, initial encounter',
  'S31633D Puncture wound without foreign body of abdominal wall, right lower quadrant with',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31633S Puncture wound without foreign body of abdominal wall, right lower quadrant with',
  'penetration into peritoneal cavity, sequela',
  'S31634A Puncture wound without foreign body of abdominal wall, left lower quadrant with penetration',
  'into peritoneal cavity, initial encounter',
  'S31634D Puncture wound without foreign body of abdominal wall, left lower quadrant with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31634S Puncture wound without foreign body of abdominal wall, left lower quadrant with penetration',
  'into peritoneal cavity, sequela',
  'S31635A Puncture wound without foreign body of abdominal wall, periumbilic region with penetration',
  'into peritoneal cavity, initial encounter',
  'S31635D Puncture wound without foreign body of abdominal wall, periumbilic region with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31635S Puncture wound without foreign body of abdominal wall, periumbilic region with penetration',
  'into peritoneal cavity, sequela',
  'S31639A Puncture wound without foreign body of abdominal wall, unspecified quadrant with',
  'penetration into peritoneal cavity, initial encounter',
  'S31639D Puncture wound without foreign body of abdominal wall, unspecified quadrant with',
  'penetration into peritoneal cavity, subsequent encounter',
  'S31639S Puncture wound without foreign body of abdominal wall, unspecified quadrant with',
  'penetration into peritoneal cavity, sequela',
  'S31640A Puncture wound with foreign body of abdominal wall, right upper quadrant with penetration',
  'into peritoneal cavity, initial encounter',
  'S31640D Puncture wound with foreign body of abdominal wall, right upper quadrant with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31640S Puncture wound with foreign body of abdominal wall, right upper quadrant with penetration',
  'into peritoneal cavity, sequela',
  'S31641A Puncture wound with foreign body of abdominal wall, left upper quadrant with penetration',
  'into peritoneal cavity, initial encounter',
  'S31641D Puncture wound with foreign body of abdominal wall, left upper quadrant with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31641S Puncture wound with foreign body of abdominal wall, left upper quadrant with penetration',
  'into peritoneal cavity, sequela',
  'S31642A Puncture wound with foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, initial encounter',
  'S31642D Puncture wound with foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31642S Puncture wound with foreign body of abdominal wall, epigastric region with penetration into',
  'peritoneal cavity, sequela',
  'S31643A Puncture wound with foreign body of abdominal wall, right lower quadrant with penetration',
  'into peritoneal cavity, initial encounter',
  'S31643D Puncture wound with foreign body of abdominal wall, right lower quadrant with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31643S Puncture wound with foreign body of abdominal wall, right lower quadrant with penetration',
  'into peritoneal cavity, sequela',
  'S31644A Puncture wound with foreign body of abdominal wall, left lower quadrant with penetration',
  'into peritoneal cavity, initial encounter',
  'S31644D Puncture wound with foreign body of abdominal wall, left lower quadrant with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31644S Puncture wound with foreign body of abdominal wall, left lower quadrant with penetration',
  'into peritoneal cavity, sequela',
  'S31645A Puncture wound with foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, initial encounter',
  'S31645D Puncture wound with foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, subsequent encounter',
  'S31645S Puncture wound with foreign body of abdominal wall, periumbilic region with penetration into',
  'peritoneal cavity, sequela',
  'S31649A Puncture wound with foreign body of abdominal wall, unspecified quadrant with penetration',
  'into peritoneal cavity, initial encounter',
  'S31649D Puncture wound with foreign body of abdominal wall, unspecified quadrant with penetration',
  'into peritoneal cavity, subsequent encounter',
  'S31649S Puncture wound with foreign body of abdominal wall, unspecified quadrant with penetration',
  'into peritoneal cavity, sequela',
  'S31650A Open bite of abdominal wall, right upper quadrant with penetration into peritoneal cavity,',
  'initial encounter',
  'S31650D Open bite of abdominal wall, right upper quadrant with penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31650S Open bite of abdominal wall, right upper quadrant with penetration into peritoneal cavity,',
  'sequela',
  'S31651A Open bite of abdominal wall, left upper quadrant with penetration into peritoneal cavity, initial',
  'encounter',
  'S31651D Open bite of abdominal wall, left upper quadrant with penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31651S Open bite of abdominal wall, left upper quadrant with penetration into peritoneal cavity,',
  'sequela',
  'S31652A Open bite of abdominal wall, epigastric region with penetration into peritoneal cavity, initial',
  'encounter',
  'S31652D Open bite of abdominal wall, epigastric region with penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31652S Open bite of abdominal wall, epigastric region with penetration into peritoneal cavity, sequela',
  'S31653A Open bite of abdominal wall, right lower quadrant with penetration into peritoneal cavity,',
  'initial encounter',
  'S31653D Open bite of abdominal wall, right lower quadrant with penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31653S Open bite of abdominal wall, right lower quadrant with penetration into peritoneal cavity,',
  'sequela',
  'S31654A Open bite of abdominal wall, left lower quadrant with penetration into peritoneal cavity, initial',
  'encounter',
  'S31654D Open bite of abdominal wall, left lower quadrant with penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31654S Open bite of abdominal wall, left lower quadrant with penetration into peritoneal cavity,',
  'sequela',
  'S31655A Open bite of abdominal wall, periumbilic region with penetration into peritoneal cavity, initial',
  'encounter',
  'S31655D Open bite of abdominal wall, periumbilic region with penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31655S Open bite of abdominal wall, periumbilic region with penetration into peritoneal cavity,',
  'sequela',
  'S31659A Open bite of abdominal wall, unspecified quadrant with penetration into peritoneal cavity,',
  'initial encounter',
  'S31659D Open bite of abdominal wall, unspecified quadrant with penetration into peritoneal cavity,',
  'subsequent encounter',
  'S31659S Open bite of abdominal wall, unspecified quadrant with penetration into peritoneal cavity,',
  'sequela',
  'S31801A Laceration without foreign body of unspecified buttock, initial encounter',
  'S31801D Laceration without foreign body of unspecified buttock, subsequent encounter',
  'S31801S Laceration without foreign body of unspecified buttock, sequela',
  'S31802A Laceration with foreign body of unspecified buttock, initial encounter',
  'S31802D Laceration with foreign body of unspecified buttock, subsequent encounter',
  'S31802S Laceration with foreign body of unspecified buttock, sequela',
  'S31803A Puncture wound without foreign body of unspecified buttock, initial encounter',
  'S31803D Puncture wound without foreign body of unspecified buttock, subsequent encounter',
  'S31803S Puncture wound without foreign body of unspecified buttock, sequela',
  'S31804A Puncture wound with foreign body of unspecified buttock, initial encounter',
  'S31804D Puncture wound with foreign body of unspecified buttock, subsequent encounter',
  'S31804S Puncture wound with foreign body of unspecified buttock, sequela',
  'S31805A Open bite of unspecified buttock, initial encounter',
  'S31805D Open bite of unspecified buttock, subsequent encounter',
  'S31805S Open bite of unspecified buttock, sequela',
  'S31809A Unspecified open wound of unspecified buttock, initial encounter',
  'S31809D Unspecified open wound of unspecified buttock, subsequent encounter',
  'S31809S Unspecified open wound of unspecified buttock, sequela',
  'S31811A Laceration without foreign body of right buttock, initial encounter',
  'S31811D Laceration without foreign body of right buttock, subsequent encounter',
  'S31811S Laceration without foreign body of right buttock, sequela',
  'S31812A Laceration with foreign body of right buttock, initial encounter',
  'S31812D Laceration with foreign body of right buttock, subsequent encounter',
  'S31812S Laceration with foreign body of right buttock, sequela',
  'S31813A Puncture wound without foreign body of right buttock, initial encounter',
  'S31813D Puncture wound without foreign body of right buttock, subsequent encounter',
  'S31813S Puncture wound without foreign body of right buttock, sequela',
  'S31814A Puncture wound with foreign body of right buttock, initial encounter',
  'S31814D Puncture wound with foreign body of right buttock, subsequent encounter',
  'S31814S Puncture wound with foreign body of right buttock, sequela',
  'S31815A Open bite of right buttock, initial encounter',
  'S31815D Open bite of right buttock, subsequent encounter',
  'S31815S Open bite of right buttock, sequela',
  'S31819A Unspecified open wound of right buttock, initial encounter',
  'S31819D Unspecified open wound of right buttock, subsequent encounter',
  'S31819S Unspecified open wound of right buttock, sequela',
  'S31821A Laceration without foreign body of left buttock, initial encounter',
  'S31821D Laceration without foreign body of left buttock, subsequent encounter',
  'S31821S Laceration without foreign body of left buttock, sequela',
  'S31822A Laceration with foreign body of left buttock, initial encounter',
  'S31822D Laceration with foreign body of left buttock, subsequent encounter',
  'S31822S Laceration with foreign body of left buttock, sequela',
  'S31823A Puncture wound without foreign body of left buttock, initial encounter',
  'S31823D Puncture wound without foreign body of left buttock, subsequent encounter',
  'S31823S Puncture wound without foreign body of left buttock, sequela',
  'S31824A Puncture wound with foreign body of left buttock, initial encounter',
  'S31824D Puncture wound with foreign body of left buttock, subsequent encounter',
  'S31824S Puncture wound with foreign body of left buttock, sequela',
  'S31825A Open bite of left buttock, initial encounter',
  'S31825D Open bite of left buttock, subsequent encounter',
  'S31825S Open bite of left buttock, sequela',
  'S31829A Unspecified open wound of left buttock, initial encounter',
  'S31829D Unspecified open wound of left buttock, subsequent encounter',
  'S31829S Unspecified open wound of left buttock, sequela',
  'S31831A Laceration without foreign body of anus, initial encounter',
  'S31831D Laceration without foreign body of anus, subsequent encounter',
  'S31831S Laceration without foreign body of anus, sequela',
  'S31832A Laceration with foreign body of anus, initial encounter',
  'S31832D Laceration with foreign body of anus, subsequent encounter',
  'S31832S Laceration with foreign body of anus, sequela',
  'S31833A Puncture wound without foreign body of anus, initial encounter',
  'S31833D Puncture wound without foreign body of anus, subsequent encounter',
  'S31833S Puncture wound without foreign body of anus, sequela',
  'S31834A Puncture wound with foreign body of anus, initial encounter',
  'S31834D Puncture wound with foreign body of anus, subsequent encounter',
  'S31834S Puncture wound with foreign body of anus, sequela',
  'S31835A Open bite of anus, initial encounter',
  'S31835D Open bite of anus, subsequent encounter',
  'S31835S Open bite of anus, sequela',
  'S31839A Unspecified open wound of anus, initial encounter',
  'S31839D Unspecified open wound of anus, subsequent encounter',
  'S31839S Unspecified open wound of anus, sequela',
  'S32000A Wedge compression fracture of unspecified lumbar vertebra, initial encounter for closed',
  'fracture',
  'S32000B Wedge compression fracture of unspecified lumbar vertebra, initial encounter for open',
  'fracture',
  'S32000D Wedge compression fracture of unspecified lumbar vertebra, subsequent encounter for',
  'fracture with routine healing',
  'S32000G Wedge compression fracture of unspecified lumbar vertebra, subsequent encounter for',
  'fracture with delayed healing',
  'S32000K Wedge compression fracture of unspecified lumbar vertebra, subsequent encounter for',
  'fracture with nonunion',
  'S32000S Wedge compression fracture of unspecified lumbar vertebra, sequela',
  'S32001A Stable burst fracture of unspecified lumbar vertebra, initial encounter for closed fracture',
  'S32001B Stable burst fracture of unspecified lumbar vertebra, initial encounter for open fracture',
  'S32001D Stable burst fracture of unspecified lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32001G Stable burst fracture of unspecified lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32001K Stable burst fracture of unspecified lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32001S Stable burst fracture of unspecified lumbar vertebra, sequela',
  'S32002A Unstable burst fracture of unspecified lumbar vertebra, initial encounter for closed fracture',
  'S32002B Unstable burst fracture of unspecified lumbar vertebra, initial encounter for open fracture',
  'S32002D Unstable burst fracture of unspecified lumbar vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S32002G Unstable burst fracture of unspecified lumbar vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S32002K Unstable burst fracture of unspecified lumbar vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S32002S Unstable burst fracture of unspecified lumbar vertebra, sequela',
  'S32008A Other fracture of unspecified lumbar vertebra, initial encounter for closed fracture',
  'S32008B Other fracture of unspecified lumbar vertebra, initial encounter for open fracture',
  'S32008D Other fracture of unspecified lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32008G Other fracture of unspecified lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32008K Other fracture of unspecified lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32008S Other fracture of unspecified lumbar vertebra, sequela',
  'S32009A Unspecified fracture of unspecified lumbar vertebra, initial encounter for closed fracture',
  'S32009B Unspecified fracture of unspecified lumbar vertebra, initial encounter for open fracture',
  'S32009D Unspecified fracture of unspecified lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32009G Unspecified fracture of unspecified lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32009K Unspecified fracture of unspecified lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32009S Unspecified fracture of unspecified lumbar vertebra, sequela',
  'S32010A Wedge compression fracture of first lumbar vertebra, initial encounter for closed fracture',
  'S32010B Wedge compression fracture of first lumbar vertebra, initial encounter for open fracture',
  'S32010D Wedge compression fracture of first lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32010G Wedge compression fracture of first lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32010K Wedge compression fracture of first lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32010S Wedge compression fracture of first lumbar vertebra, sequela',
  'S32011A Stable burst fracture of first lumbar vertebra, initial encounter for closed fracture',
  'S32011B Stable burst fracture of first lumbar vertebra, initial encounter for open fracture',
  'S32011D Stable burst fracture of first lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32011G Stable burst fracture of first lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32011K Stable burst fracture of first lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32011S Stable burst fracture of first lumbar vertebra, sequela',
  'S32012A Unstable burst fracture of first lumbar vertebra, initial encounter for closed fracture',
  'S32012B Unstable burst fracture of first lumbar vertebra, initial encounter for open fracture',
  'S32012D Unstable burst fracture of first lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32012G Unstable burst fracture of first lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32012K Unstable burst fracture of first lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32012S Unstable burst fracture of first lumbar vertebra, sequela',
  'S32018A Other fracture of first lumbar vertebra, initial encounter for closed fracture',
  'S32018B Other fracture of first lumbar vertebra, initial encounter for open fracture',
  'S32018D Other fracture of first lumbar vertebra, subsequent encounter for fracture with routine healing',
  'S32018G Other fracture of first lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32018K Other fracture of first lumbar vertebra, subsequent encounter for fracture with nonunion',
  'S32018S Other fracture of first lumbar vertebra, sequela',
  'S32019A Unspecified fracture of first lumbar vertebra, initial encounter for closed fracture',
  'S32019B Unspecified fracture of first lumbar vertebra, initial encounter for open fracture',
  'S32019D Unspecified fracture of first lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32019G Unspecified fracture of first lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32019K Unspecified fracture of first lumbar vertebra, subsequent encounter for fracture with nonunion',
  'S32019S Unspecified fracture of first lumbar vertebra, sequela',
  'S32020A Wedge compression fracture of second lumbar vertebra, initial encounter for closed fracture',
  'S32020B Wedge compression fracture of second lumbar vertebra, initial encounter for open fracture',
  'S32020D Wedge compression fracture of second lumbar vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S32020G Wedge compression fracture of second lumbar vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S32020K Wedge compression fracture of second lumbar vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S32020S Wedge compression fracture of second lumbar vertebra, sequela',
  'S32021A Stable burst fracture of second lumbar vertebra, initial encounter for closed fracture',
  'S32021B Stable burst fracture of second lumbar vertebra, initial encounter for open fracture',
  'S32021D Stable burst fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32021G Stable burst fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32021K Stable burst fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32021S Stable burst fracture of second lumbar vertebra, sequela',
  'S32022A Unstable burst fracture of second lumbar vertebra, initial encounter for closed fracture',
  'S32022B Unstable burst fracture of second lumbar vertebra, initial encounter for open fracture',
  'S32022D Unstable burst fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32022G Unstable burst fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32022K Unstable burst fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32022S Unstable burst fracture of second lumbar vertebra, sequela',
  'S32028A Other fracture of second lumbar vertebra, initial encounter for closed fracture',
  'S32028B Other fracture of second lumbar vertebra, initial encounter for open fracture',
  'S32028D Other fracture of second lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32028G Other fracture of second lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32028K Other fracture of second lumbar vertebra, subsequent encounter for fracture with nonunion',
  'S32028S Other fracture of second lumbar vertebra, sequela',
  'S32029A Unspecified fracture of second lumbar vertebra, initial encounter for closed fracture',
  'S32029B Unspecified fracture of second lumbar vertebra, initial encounter for open fracture',
  'S32029D Unspecified fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32029G Unspecified fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32029K Unspecified fracture of second lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32029S Unspecified fracture of second lumbar vertebra, sequela',
  'S32030A Wedge compression fracture of third lumbar vertebra, initial encounter for closed fracture',
  'S32030B Wedge compression fracture of third lumbar vertebra, initial encounter for open fracture',
  'S32030D Wedge compression fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32030G Wedge compression fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32030K Wedge compression fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32030S Wedge compression fracture of third lumbar vertebra, sequela',
  'S32031A Stable burst fracture of third lumbar vertebra, initial encounter for closed fracture',
  'S32031B Stable burst fracture of third lumbar vertebra, initial encounter for open fracture',
  'S32031D Stable burst fracture of third lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32031G Stable burst fracture of third lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32031K Stable burst fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32031S Stable burst fracture of third lumbar vertebra, sequela',
  'S32032A Unstable burst fracture of third lumbar vertebra, initial encounter for closed fracture',
  'S32032B Unstable burst fracture of third lumbar vertebra, initial encounter for open fracture',
  'S32032D Unstable burst fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32032G Unstable burst fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32032K Unstable burst fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32032S Unstable burst fracture of third lumbar vertebra, sequela',
  'S32038A Other fracture of third lumbar vertebra, initial encounter for closed fracture',
  'S32038B Other fracture of third lumbar vertebra, initial encounter for open fracture',
  'S32038D Other fracture of third lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32038G Other fracture of third lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32038K Other fracture of third lumbar vertebra, subsequent encounter for fracture with nonunion',
  'S32038S Other fracture of third lumbar vertebra, sequela',
  'S32039A Unspecified fracture of third lumbar vertebra, initial encounter for closed fracture',
  'S32039B Unspecified fracture of third lumbar vertebra, initial encounter for open fracture',
  'S32039D Unspecified fracture of third lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32039G Unspecified fracture of third lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32039K Unspecified fracture of third lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32039S Unspecified fracture of third lumbar vertebra, sequela',
  'S32040A Wedge compression fracture of fourth lumbar vertebra, initial encounter for closed fracture',
  'S32040B Wedge compression fracture of fourth lumbar vertebra, initial encounter for open fracture',
  'S32040D Wedge compression fracture of fourth lumbar vertebra, subsequent encounter for fracture',
  'with routine healing',
  'S32040G Wedge compression fracture of fourth lumbar vertebra, subsequent encounter for fracture',
  'with delayed healing',
  'S32040K Wedge compression fracture of fourth lumbar vertebra, subsequent encounter for fracture',
  'with nonunion',
  'S32040S Wedge compression fracture of fourth lumbar vertebra, sequela',
  'S32041A Stable burst fracture of fourth lumbar vertebra, initial encounter for closed fracture',
  'S32041B Stable burst fracture of fourth lumbar vertebra, initial encounter for open fracture',
  'S32041D Stable burst fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32041G Stable burst fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32041K Stable burst fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32041S Stable burst fracture of fourth lumbar vertebra, sequela',
  'S32042A Unstable burst fracture of fourth lumbar vertebra, initial encounter for closed fracture',
  'S32042B Unstable burst fracture of fourth lumbar vertebra, initial encounter for open fracture',
  'S32042D Unstable burst fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32042G Unstable burst fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32042K Unstable burst fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32042S Unstable burst fracture of fourth lumbar vertebra, sequela',
  'S32048A Other fracture of fourth lumbar vertebra, initial encounter for closed fracture',
  'S32048B Other fracture of fourth lumbar vertebra, initial encounter for open fracture',
  'S32048D Other fracture of fourth lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32048G Other fracture of fourth lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32048K Other fracture of fourth lumbar vertebra, subsequent encounter for fracture with nonunion',
  'S32048S Other fracture of fourth lumbar vertebra, sequela',
  'S32049A Unspecified fracture of fourth lumbar vertebra, initial encounter for closed fracture',
  'S32049B Unspecified fracture of fourth lumbar vertebra, initial encounter for open fracture',
  'S32049D Unspecified fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32049G Unspecified fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32049K Unspecified fracture of fourth lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32049S Unspecified fracture of fourth lumbar vertebra, sequela',
  'S32050A Wedge compression fracture of fifth lumbar vertebra, initial encounter for closed fracture',
  'S32050B Wedge compression fracture of fifth lumbar vertebra, initial encounter for open fracture',
  'S32050D Wedge compression fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32050G Wedge compression fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32050K Wedge compression fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32050S Wedge compression fracture of fifth lumbar vertebra, sequela',
  'S32051A Stable burst fracture of fifth lumbar vertebra, initial encounter for closed fracture',
  'S32051B Stable burst fracture of fifth lumbar vertebra, initial encounter for open fracture',
  'S32051D Stable burst fracture of fifth lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32051G Stable burst fracture of fifth lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32051K Stable burst fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32051S Stable burst fracture of fifth lumbar vertebra, sequela',
  'S32052A Unstable burst fracture of fifth lumbar vertebra, initial encounter for closed fracture',
  'S32052B Unstable burst fracture of fifth lumbar vertebra, initial encounter for open fracture',
  'S32052D Unstable burst fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'routine healing',
  'S32052G Unstable burst fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'delayed healing',
  'S32052K Unstable burst fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32052S Unstable burst fracture of fifth lumbar vertebra, sequela',
  'S32058A Other fracture of fifth lumbar vertebra, initial encounter for closed fracture',
  'S32058B Other fracture of fifth lumbar vertebra, initial encounter for open fracture',
  'S32058D Other fracture of fifth lumbar vertebra, subsequent encounter for fracture with routine healing',
  'S32058G Other fracture of fifth lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32058K Other fracture of fifth lumbar vertebra, subsequent encounter for fracture with nonunion',
  'S32058S Other fracture of fifth lumbar vertebra, sequela',
  'S32059A Unspecified fracture of fifth lumbar vertebra, initial encounter for closed fracture',
  'S32059B Unspecified fracture of fifth lumbar vertebra, initial encounter for open fracture',
  'S32059D Unspecified fracture of fifth lumbar vertebra, subsequent encounter for fracture with routine',
  'healing',
  'S32059G Unspecified fracture of fifth lumbar vertebra, subsequent encounter for fracture with delayed',
  'healing',
  'S32059K Unspecified fracture of fifth lumbar vertebra, subsequent encounter for fracture with',
  'nonunion',
  'S32059S Unspecified fracture of fifth lumbar vertebra, sequela',
  'S3210XA Unspecified fracture of sacrum, initial encounter for closed fracture',
  'S3210XB Unspecified fracture of sacrum, initial encounter for open fracture',
  'S3210XD Unspecified fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S3210XG Unspecified fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S3210XK Unspecified fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S3210XS Unspecified fracture of sacrum, sequela',
  'S32110A Nondisplaced Zone I fracture of sacrum, initial encounter for closed fracture',
  'S32110B Nondisplaced Zone I fracture of sacrum, initial encounter for open fracture',
  'S32110D Nondisplaced Zone I fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32110G Nondisplaced Zone I fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32110K Nondisplaced Zone I fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S32110S Nondisplaced Zone I fracture of sacrum, sequela',
  'S32111A Minimally displaced Zone I fracture of sacrum, initial encounter for closed fracture',
  'S32111B Minimally displaced Zone I fracture of sacrum, initial encounter for open fracture',
  'S32111D Minimally displaced Zone I fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32111G Minimally displaced Zone I fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32111K Minimally displaced Zone I fracture of sacrum, subsequent encounter for fracture with',
  'nonunion',
  'S32111S Minimally displaced Zone I fracture of sacrum, sequela',
  'S32112A Severely displaced Zone I fracture of sacrum, initial encounter for closed fracture',
  'S32112B Severely displaced Zone I fracture of sacrum, initial encounter for open fracture',
  'S32112D Severely displaced Zone I fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32112G Severely displaced Zone I fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32112K Severely displaced Zone I fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S32112S Severely displaced Zone I fracture of sacrum, sequela',
  'S32119A Unspecified Zone I fracture of sacrum, initial encounter for closed fracture',
  'S32119B Unspecified Zone I fracture of sacrum, initial encounter for open fracture',
  'S32119D Unspecified Zone I fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S32119G Unspecified Zone I fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S32119K Unspecified Zone I fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S32119S Unspecified Zone I fracture of sacrum, sequela',
  'S32120A Nondisplaced Zone II fracture of sacrum, initial encounter for closed fracture',
  'S32120B Nondisplaced Zone II fracture of sacrum, initial encounter for open fracture',
  'S32120D Nondisplaced Zone II fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32120G Nondisplaced Zone II fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32120K Nondisplaced Zone II fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S32120S Nondisplaced Zone II fracture of sacrum, sequela',
  'S32121A Minimally displaced Zone II fracture of sacrum, initial encounter for closed fracture',
  'S32121B Minimally displaced Zone II fracture of sacrum, initial encounter for open fracture',
  'S32121D Minimally displaced Zone II fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32121G Minimally displaced Zone II fracture of sacrum, subsequent encounter for fracture with',
  'delayed healing',
  'S32121K Minimally displaced Zone II fracture of sacrum, subsequent encounter for fracture with',
  'nonunion',
  'S32121S Minimally displaced Zone II fracture of sacrum, sequela',
  'S32122A Severely displaced Zone II fracture of sacrum, initial encounter for closed fracture',
  'S32122B Severely displaced Zone II fracture of sacrum, initial encounter for open fracture',
  'S32122D Severely displaced Zone II fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32122G Severely displaced Zone II fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32122K Severely displaced Zone II fracture of sacrum, subsequent encounter for fracture with',
  'nonunion',
  'S32122S Severely displaced Zone II fracture of sacrum, sequela',
  'S32129A Unspecified Zone II fracture of sacrum, initial encounter for closed fracture',
  'S32129B Unspecified Zone II fracture of sacrum, initial encounter for open fracture',
  'S32129D Unspecified Zone II fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S32129G Unspecified Zone II fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S32129K Unspecified Zone II fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S32129S Unspecified Zone II fracture of sacrum, sequela',
  'S32130A Nondisplaced Zone III fracture of sacrum, initial encounter for closed fracture',
  'S32130B Nondisplaced Zone III fracture of sacrum, initial encounter for open fracture',
  'S32130D Nondisplaced Zone III fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32130G Nondisplaced Zone III fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32130K Nondisplaced Zone III fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S32130S Nondisplaced Zone III fracture of sacrum, sequela',
  'S32131A Minimally displaced Zone III fracture of sacrum, initial encounter for closed fracture',
  'S32131B Minimally displaced Zone III fracture of sacrum, initial encounter for open fracture',
  'S32131D Minimally displaced Zone III fracture of sacrum, subsequent encounter for fracture with',
  'routine healing',
  'S32131G Minimally displaced Zone III fracture of sacrum, subsequent encounter for fracture with',
  'delayed healing',
  'S32131K Minimally displaced Zone III fracture of sacrum, subsequent encounter for fracture with',
  'nonunion',
  'S32131S Minimally displaced Zone III fracture of sacrum, sequela',
  'S32132A Severely displaced Zone III fracture of sacrum, initial encounter for closed fracture',
  'S32132B Severely displaced Zone III fracture of sacrum, initial encounter for open fracture',
  'S32132D Severely displaced Zone III fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32132G Severely displaced Zone III fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32132K Severely displaced Zone III fracture of sacrum, subsequent encounter for fracture with',
  'nonunion',
  'S32132S Severely displaced Zone III fracture of sacrum, sequela',
  'S32139A Unspecified Zone III fracture of sacrum, initial encounter for closed fracture',
  'S32139B Unspecified Zone III fracture of sacrum, initial encounter for open fracture',
  'S32139D Unspecified Zone III fracture of sacrum, subsequent encounter for fracture with routine',
  'healing',
  'S32139G Unspecified Zone III fracture of sacrum, subsequent encounter for fracture with delayed',
  'healing',
  'S32139K Unspecified Zone III fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S32139S Unspecified Zone III fracture of sacrum, sequela',
  'S3214XA Type 1 fracture of sacrum, initial encounter for closed fracture',
  'S3214XB Type 1 fracture of sacrum, initial encounter for open fracture',
  'S3214XD Type 1 fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S3214XG Type 1 fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S3214XK Type 1 fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S3214XS Type 1 fracture of sacrum, sequela',
  'S3215XA Type 2 fracture of sacrum, initial encounter for closed fracture',
  'S3215XB Type 2 fracture of sacrum, initial encounter for open fracture',
  'S3215XD Type 2 fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S3215XG Type 2 fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S3215XK Type 2 fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S3215XS Type 2 fracture of sacrum, sequela',
  'S3216XA Type 3 fracture of sacrum, initial encounter for closed fracture',
  'S3216XB Type 3 fracture of sacrum, initial encounter for open fracture',
  'S3216XD Type 3 fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S3216XG Type 3 fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S3216XK Type 3 fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S3216XS Type 3 fracture of sacrum, sequela',
  'S3217XA Type 4 fracture of sacrum, initial encounter for closed fracture',
  'S3217XB Type 4 fracture of sacrum, initial encounter for open fracture',
  'S3217XD Type 4 fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S3217XG Type 4 fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S3217XK Type 4 fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S3217XS Type 4 fracture of sacrum, sequela',
  'S3219XA Other fracture of sacrum, initial encounter for closed fracture',
  'S3219XB Other fracture of sacrum, initial encounter for open fracture',
  'S3219XD Other fracture of sacrum, subsequent encounter for fracture with routine healing',
  'S3219XG Other fracture of sacrum, subsequent encounter for fracture with delayed healing',
  'S3219XK Other fracture of sacrum, subsequent encounter for fracture with nonunion',
  'S3219XS Other fracture of sacrum, sequela',
  'S322XXA Fracture of coccyx, initial encounter for closed fracture',
  'S322XXB Fracture of coccyx, initial encounter for open fracture',
  'S322XXD Fracture of coccyx, subsequent encounter for fracture with routine healing',
  'S322XXG Fracture of coccyx, subsequent encounter for fracture with delayed healing',
  'S322XXK Fracture of coccyx, subsequent encounter for fracture with nonunion',
  'S322XXS Fracture of coccyx, sequela',
  'S32301A Unspecified fracture of right ilium, initial encounter for closed fracture',
  'S32301B Unspecified fracture of right ilium, initial encounter for open fracture',
  'S32301D Unspecified fracture of right ilium, subsequent encounter for fracture with routine healing',
  'S32301G Unspecified fracture of right ilium, subsequent encounter for fracture with delayed healing',
  'S32301K Unspecified fracture of right ilium, subsequent encounter for fracture with nonunion',
  'S32301S Unspecified fracture of right ilium, sequela',
  'S32302A Unspecified fracture of left ilium, initial encounter for closed fracture',
  'S32302B Unspecified fracture of left ilium, initial encounter for open fracture',
  'S32302D Unspecified fracture of left ilium, subsequent encounter for fracture with routine healing',
  'S32302G Unspecified fracture of left ilium, subsequent encounter for fracture with delayed healing',
  'S32302K Unspecified fracture of left ilium, subsequent encounter for fracture with nonunion',
  'S32302S Unspecified fracture of left ilium, sequela',
  'S32309A Unspecified fracture of unspecified ilium, initial encounter for closed fracture',
  'S32309B Unspecified fracture of unspecified ilium, initial encounter for open fracture',
  'S32309D Unspecified fracture of unspecified ilium, subsequent encounter for fracture with routine',
  'healing',
  'S32309G Unspecified fracture of unspecified ilium, subsequent encounter for fracture with delayed',
  'healing',
  'S32309K Unspecified fracture of unspecified ilium, subsequent encounter for fracture with nonunion',
  'S32309S Unspecified fracture of unspecified ilium, sequela',
  'S32311A Displaced avulsion fracture of right ilium, initial encounter for closed fracture',
  'S32311B Displaced avulsion fracture of right ilium, initial encounter for open fracture',
  'S32311D Displaced avulsion fracture of right ilium, subsequent encounter for fracture with routine',
  'healing',
  'S32311G Displaced avulsion fracture of right ilium, subsequent encounter for fracture with delayed',
  'healing',
  'S32311K Displaced avulsion fracture of right ilium, subsequent encounter for fracture with nonunion',
  'S32311S Displaced avulsion fracture of right ilium, sequela',
  'S32312A Displaced avulsion fracture of left ilium, initial encounter for closed fracture',
  'S32312B Displaced avulsion fracture of left ilium, initial encounter for open fracture',
  'S32312D Displaced avulsion fracture of left ilium, subsequent encounter for fracture with routine',
  'healing',
  'S32312G Displaced avulsion fracture of left ilium, subsequent encounter for fracture with delayed',
  'healing',
  'S32312K Displaced avulsion fracture of left ilium, subsequent encounter for fracture with nonunion',
  'S32312S Displaced avulsion fracture of left ilium, sequela',
  'S32313A Displaced avulsion fracture of unspecified ilium, initial encounter for closed fracture',
  'S32313B Displaced avulsion fracture of unspecified ilium, initial encounter for open fracture',
  'S32313D Displaced avulsion fracture of unspecified ilium, subsequent encounter for fracture with',
  'routine healing',
  'S32313G Displaced avulsion fracture of unspecified ilium, subsequent encounter for fracture with',
  'delayed healing',
  'S32313K Displaced avulsion fracture of unspecified ilium, subsequent encounter for fracture with',
  'nonunion',
  'S32313S Displaced avulsion fracture of unspecified ilium, sequela',
  'S32314A Nondisplaced avulsion fracture of right ilium, initial encounter for closed fracture',
  'S32314B Nondisplaced avulsion fracture of right ilium, initial encounter for open fracture',
  'S32314D Nondisplaced avulsion fracture of right ilium, subsequent encounter for fracture with routine',
  'healing',
  'S32314G Nondisplaced avulsion fracture of right ilium, subsequent encounter for fracture with delayed',
  'healing',
  'S32314K Nondisplaced avulsion fracture of right ilium, subsequent encounter for fracture with nonunion',
  'S32314S Nondisplaced avulsion fracture of right ilium, sequela',
  'S32315A Nondisplaced avulsion fracture of left ilium, initial encounter for closed fracture',
  'S32315B Nondisplaced avulsion fracture of left ilium, initial encounter for open fracture',
  'S32315D Nondisplaced avulsion fracture of left ilium, subsequent encounter for fracture with routine',
  'healing',
  'S32315G Nondisplaced avulsion fracture of left ilium, subsequent encounter for fracture with delayed',
  'healing',
  'S32315K Nondisplaced avulsion fracture of left ilium, subsequent encounter for fracture with nonunion',
  'S32315S Nondisplaced avulsion fracture of left ilium, sequela',
  'S32316A Nondisplaced avulsion fracture of unspecified ilium, initial encounter for closed fracture',
  'S32316B Nondisplaced avulsion fracture of unspecified ilium, initial encounter for open fracture',
  'S32316D Nondisplaced avulsion fracture of unspecified ilium, subsequent encounter for fracture with',
  'routine healing',
  'S32316G Nondisplaced avulsion fracture of unspecified ilium, subsequent encounter for fracture with',
  'delayed healing',
  'S32316K Nondisplaced avulsion fracture of unspecified ilium, subsequent encounter for fracture with',
  'nonunion',
  'S32316S Nondisplaced avulsion fracture of unspecified ilium, sequela',
  'S32391A Other fracture of right ilium, initial encounter for closed fracture',
  'S32391B Other fracture of right ilium, initial encounter for open fracture',
  'S32391D Other fracture of right ilium, subsequent encounter for fracture with routine healing',
  'S32391G Other fracture of right ilium, subsequent encounter for fracture with delayed healing',
  'S32391K Other fracture of right ilium, subsequent encounter for fracture with nonunion',
  'S32391S Other fracture of right ilium, sequela',
  'S32392A Other fracture of left ilium, initial encounter for closed fracture',
  'S32392B Other fracture of left ilium, initial encounter for open fracture',
  'S32392D Other fracture of left ilium, subsequent encounter for fracture with routine healing',
  'S32392G Other fracture of left ilium, subsequent encounter for fracture with delayed healing',
  'S32392K Other fracture of left ilium, subsequent encounter for fracture with nonunion',
  'S32392S Other fracture of left ilium, sequela',
  'S32399A Other fracture of unspecified ilium, initial encounter for closed fracture',
  'S32399B Other fracture of unspecified ilium, initial encounter for open fracture',
  'S32399D Other fracture of unspecified ilium, subsequent encounter for fracture with routine healing',
  'S32399G Other fracture of unspecified ilium, subsequent encounter for fracture with delayed healing',
  'S32399K Other fracture of unspecified ilium, subsequent encounter for fracture with nonunion',
  'S32399S Other fracture of unspecified ilium, sequela',
  'S32401A Unspecified fracture of right acetabulum, initial encounter for closed fracture',
  'S32401B Unspecified fracture of right acetabulum, initial encounter for open fracture',
  'S32401D Unspecified fracture of right acetabulum, subsequent encounter for fracture with routine',
  'healing',
  'S32401G Unspecified fracture of right acetabulum, subsequent encounter for fracture with delayed',
  'healing',
  'S32401K Unspecified fracture of right acetabulum, subsequent encounter for fracture with nonunion',
  'S32401S Unspecified fracture of right acetabulum, sequela',
  'S32402A Unspecified fracture of left acetabulum, initial encounter for closed fracture',
  'S32402B Unspecified fracture of left acetabulum, initial encounter for open fracture',
  'S32402D Unspecified fracture of left acetabulum, subsequent encounter for fracture with routine',
  'healing',
  'S32402G Unspecified fracture of left acetabulum, subsequent encounter for fracture with delayed',
  'healing',
  'S32402K Unspecified fracture of left acetabulum, subsequent encounter for fracture with nonunion',
  'S32402S Unspecified fracture of left acetabulum, sequela',
  'S32409A Unspecified fracture of unspecified acetabulum, initial encounter for closed fracture',
  'S32409B Unspecified fracture of unspecified acetabulum, initial encounter for open fracture',
  'S32409D Unspecified fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32409G Unspecified fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32409K Unspecified fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32409S Unspecified fracture of unspecified acetabulum, sequela',
  'S32411A Displaced fracture of anterior wall of right acetabulum, initial encounter for closed fracture',
  'S32411B Displaced fracture of anterior wall of right acetabulum, initial encounter for open fracture',
  'S32411D Displaced fracture of anterior wall of right acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32411G Displaced fracture of anterior wall of right acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32411K Displaced fracture of anterior wall of right acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32411S Displaced fracture of anterior wall of right acetabulum, sequela',
  'S32412A Displaced fracture of anterior wall of left acetabulum, initial encounter for closed fracture',
  'S32412B Displaced fracture of anterior wall of left acetabulum, initial encounter for open fracture',
  'S32412D Displaced fracture of anterior wall of left acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32412G Displaced fracture of anterior wall of left acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32412K Displaced fracture of anterior wall of left acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32412S Displaced fracture of anterior wall of left acetabulum, sequela',
  'S32413A Displaced fracture of anterior wall of unspecified acetabulum, initial encounter for closed',
  'fracture',
  'S32413B Displaced fracture of anterior wall of unspecified acetabulum, initial encounter for open',
  'fracture',
  'S32413D Displaced fracture of anterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32413G Displaced fracture of anterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32413K Displaced fracture of anterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32413S Displaced fracture of anterior wall of unspecified acetabulum, sequela',
  'S32414A Nondisplaced fracture of anterior wall of right acetabulum, initial encounter for closed fracture',
  'S32414B Nondisplaced fracture of anterior wall of right acetabulum, initial encounter for open fracture',
  'S32414D Nondisplaced fracture of anterior wall of right acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32414G Nondisplaced fracture of anterior wall of right acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32414K Nondisplaced fracture of anterior wall of right acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32414S Nondisplaced fracture of anterior wall of right acetabulum, sequela',
  'S32415A Nondisplaced fracture of anterior wall of left acetabulum, initial encounter for closed fracture',
  'S32415B Nondisplaced fracture of anterior wall of left acetabulum, initial encounter for open fracture',
  'S32415D Nondisplaced fracture of anterior wall of left acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32415G Nondisplaced fracture of anterior wall of left acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32415K Nondisplaced fracture of anterior wall of left acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32415S Nondisplaced fracture of anterior wall of left acetabulum, sequela',
  'S32416A Nondisplaced fracture of anterior wall of unspecified acetabulum, initial encounter for closed',
  'fracture',
  'S32416B Nondisplaced fracture of anterior wall of unspecified acetabulum, initial encounter for open',
  'fracture',
  'S32416D Nondisplaced fracture of anterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32416G Nondisplaced fracture of anterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32416K Nondisplaced fracture of anterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32416S Nondisplaced fracture of anterior wall of unspecified acetabulum, sequela',
  'S32421A Displaced fracture of posterior wall of right acetabulum, initial encounter for closed fracture',
  'S32421B Displaced fracture of posterior wall of right acetabulum, initial encounter for open fracture',
  'S32421D Displaced fracture of posterior wall of right acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32421G Displaced fracture of posterior wall of right acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32421K Displaced fracture of posterior wall of right acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32421S Displaced fracture of posterior wall of right acetabulum, sequela',
  'S32422A Displaced fracture of posterior wall of left acetabulum, initial encounter for closed fracture',
  'S32422B Displaced fracture of posterior wall of left acetabulum, initial encounter for open fracture',
  'S32422D Displaced fracture of posterior wall of left acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32422G Displaced fracture of posterior wall of left acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32422K Displaced fracture of posterior wall of left acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32422S Displaced fracture of posterior wall of left acetabulum, sequela',
  'S32423A Displaced fracture of posterior wall of unspecified acetabulum, initial encounter for closed',
  'fracture',
  'S32423B Displaced fracture of posterior wall of unspecified acetabulum, initial encounter for open',
  'fracture',
  'S32423D Displaced fracture of posterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32423G Displaced fracture of posterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32423K Displaced fracture of posterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32423S Displaced fracture of posterior wall of unspecified acetabulum, sequela',
  'S32424A Nondisplaced fracture of posterior wall of right acetabulum, initial encounter for closed',
  'fracture',
  'S32424B Nondisplaced fracture of posterior wall of right acetabulum, initial encounter for open fracture',
  'S32424D Nondisplaced fracture of posterior wall of right acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32424G Nondisplaced fracture of posterior wall of right acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32424K Nondisplaced fracture of posterior wall of right acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32424S Nondisplaced fracture of posterior wall of right acetabulum, sequela',
  'S32425A Nondisplaced fracture of posterior wall of left acetabulum, initial encounter for closed fracture',
  'S32425B Nondisplaced fracture of posterior wall of left acetabulum, initial encounter for open fracture',
  'S32425D Nondisplaced fracture of posterior wall of left acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32425G Nondisplaced fracture of posterior wall of left acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32425K Nondisplaced fracture of posterior wall of left acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32425S Nondisplaced fracture of posterior wall of left acetabulum, sequela',
  'S32426A Nondisplaced fracture of posterior wall of unspecified acetabulum, initial encounter for closed',
  'fracture',
  'S32426B Nondisplaced fracture of posterior wall of unspecified acetabulum, initial encounter for open',
  'fracture',
  'S32426D Nondisplaced fracture of posterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32426G Nondisplaced fracture of posterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32426K Nondisplaced fracture of posterior wall of unspecified acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32426S Nondisplaced fracture of posterior wall of unspecified acetabulum, sequela',
  'S32431A Displaced fracture of anterior column [iliopubic] of right acetabulum, initial encounter for',
  'closed fracture',
  'S32431B Displaced fracture of anterior column [iliopubic] of right acetabulum, initial encounter for open',
  'fracture',
  'S32431D Displaced fracture of anterior column [iliopubic] of right acetabulum, subsequent encounter',
  'for fracture with routine healing',
  'S32431G Displaced fracture of anterior column [iliopubic] of right acetabulum, subsequent encounter',
  'for fracture with delayed healing',
  'S32431K Displaced fracture of anterior column [iliopubic] of right acetabulum, subsequent encounter',
  'for fracture with nonunion',
  'S32431S Displaced fracture of anterior column [iliopubic] of right acetabulum, sequela',
  'S32432A Displaced fracture of anterior column [iliopubic] of left acetabulum, initial encounter for closed',
  'fracture',
  'S32432B Displaced fracture of anterior column [iliopubic] of left acetabulum, initial encounter for open',
  'fracture',
  'S32432D Displaced fracture of anterior column [iliopubic] of left acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32432G Displaced fracture of anterior column [iliopubic] of left acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32432K Displaced fracture of anterior column [iliopubic] of left acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32432S Displaced fracture of anterior column [iliopubic] of left acetabulum, sequela',
  'S32433A Displaced fracture of anterior column [iliopubic] of unspecified acetabulum, initial encounter',
  'for closed fracture',
  'S32433B Displaced fracture of anterior column [iliopubic] of unspecified acetabulum, initial encounter',
  'for open fracture',
  'S32433D Displaced fracture of anterior column [iliopubic] of unspecified acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32433G Displaced fracture of anterior column [iliopubic] of unspecified acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32433K Displaced fracture of anterior column [iliopubic] of unspecified acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32433S Displaced fracture of anterior column [iliopubic] of unspecified acetabulum, sequela',
  'S32434A Nondisplaced fracture of anterior column [iliopubic] of right acetabulum, initial encounter for',
  'closed fracture',
  'S32434B Nondisplaced fracture of anterior column [iliopubic] of right acetabulum, initial encounter for',
  'open fracture',
  'S32434D Nondisplaced fracture of anterior column [iliopubic] of right acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32434G Nondisplaced fracture of anterior column [iliopubic] of right acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32434K Nondisplaced fracture of anterior column [iliopubic] of right acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32434S Nondisplaced fracture of anterior column [iliopubic] of right acetabulum, sequela',
  'S32435A Nondisplaced fracture of anterior column [iliopubic] of left acetabulum, initial encounter for',
  'closed fracture',
  'S32435B Nondisplaced fracture of anterior column [iliopubic] of left acetabulum, initial encounter for',
  'open fracture',
  'S32435D Nondisplaced fracture of anterior column [iliopubic] of left acetabulum, subsequent encounter',
  'for fracture with routine healing',
  'S32435G Nondisplaced fracture of anterior column [iliopubic] of left acetabulum, subsequent encounter',
  'for fracture with delayed healing',
  'S32435K Nondisplaced fracture of anterior column [iliopubic] of left acetabulum, subsequent encounter',
  'for fracture with nonunion',
  'S32435S Nondisplaced fracture of anterior column [iliopubic] of left acetabulum, sequela',
  'S32436A Nondisplaced fracture of anterior column [iliopubic] of unspecified acetabulum, initial',
  'encounter for closed fracture',
  'S32436B Nondisplaced fracture of anterior column [iliopubic] of unspecified acetabulum, initial',
  'encounter for open fracture',
  'S32436D Nondisplaced fracture of anterior column [iliopubic] of unspecified acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32436G Nondisplaced fracture of anterior column [iliopubic] of unspecified acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32436K Nondisplaced fracture of anterior column [iliopubic] of unspecified acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32436S Nondisplaced fracture of anterior column [iliopubic] of unspecified acetabulum, sequela',
  'S32441A Displaced fracture of posterior column [ilioischial] of right acetabulum, initial encounter for',
  'closed fracture',
  'S32441B Displaced fracture of posterior column [ilioischial] of right acetabulum, initial encounter for',
  'open fracture',
  'S32441D Displaced fracture of posterior column [ilioischial] of right acetabulum, subsequent encounter',
  'for fracture with routine healing',
  'S32441G Displaced fracture of posterior column [ilioischial] of right acetabulum, subsequent encounter',
  'for fracture with delayed healing',
  'S32441K Displaced fracture of posterior column [ilioischial] of right acetabulum, subsequent encounter',
  'for fracture with nonunion',
  'S32441S Displaced fracture of posterior column [ilioischial] of right acetabulum, sequela',
  'S32442A Displaced fracture of posterior column [ilioischial] of left acetabulum, initial encounter for',
  'closed fracture',
  'S32442B Displaced fracture of posterior column [ilioischial] of left acetabulum, initial encounter for',
  'open fracture',
  'S32442D Displaced fracture of posterior column [ilioischial] of left acetabulum, subsequent encounter',
  'for fracture with routine healing',
  'S32442G Displaced fracture of posterior column [ilioischial] of left acetabulum, subsequent encounter',
  'for fracture with delayed healing',
  'S32442K Displaced fracture of posterior column [ilioischial] of left acetabulum, subsequent encounter',
  'for fracture with nonunion',
  'S32442S Displaced fracture of posterior column [ilioischial] of left acetabulum, sequela',
  'S32443A Displaced fracture of posterior column [ilioischial] of unspecified acetabulum, initial encounter',
  'for closed fracture',
  'S32443B Displaced fracture of posterior column [ilioischial] of unspecified acetabulum, initial encounter',
  'for open fracture',
  'S32443D Displaced fracture of posterior column [ilioischial] of unspecified acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32443G Displaced fracture of posterior column [ilioischial] of unspecified acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32443K Displaced fracture of posterior column [ilioischial] of unspecified acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32443S Displaced fracture of posterior column [ilioischial] of unspecified acetabulum, sequela',
  'S32444A Nondisplaced fracture of posterior column [ilioischial] of right acetabulum, initial encounter for',
  'closed fracture',
  'S32444B Nondisplaced fracture of posterior column [ilioischial] of right acetabulum, initial encounter for',
  'open fracture',
  'S32444D Nondisplaced fracture of posterior column [ilioischial] of right acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32444G Nondisplaced fracture of posterior column [ilioischial] of right acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32444K Nondisplaced fracture of posterior column [ilioischial] of right acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32444S Nondisplaced fracture of posterior column [ilioischial] of right acetabulum, sequela',
  'S32445A Nondisplaced fracture of posterior column [ilioischial] of left acetabulum, initial encounter for',
  'closed fracture',
  'S32445B Nondisplaced fracture of posterior column [ilioischial] of left acetabulum, initial encounter for',
  'open fracture',
  'S32445D Nondisplaced fracture of posterior column [ilioischial] of left acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32445G Nondisplaced fracture of posterior column [ilioischial] of left acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32445K Nondisplaced fracture of posterior column [ilioischial] of left acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32445S Nondisplaced fracture of posterior column [ilioischial] of left acetabulum, sequela',
  'S32446A Nondisplaced fracture of posterior column [ilioischial] of unspecified acetabulum, initial',
  'encounter for closed fracture',
  'S32446B Nondisplaced fracture of posterior column [ilioischial] of unspecified acetabulum, initial',
  'encounter for open fracture',
  'S32446D Nondisplaced fracture of posterior column [ilioischial] of unspecified acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32446G Nondisplaced fracture of posterior column [ilioischial] of unspecified acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32446K Nondisplaced fracture of posterior column [ilioischial] of unspecified acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32446S Nondisplaced fracture of posterior column [ilioischial] of unspecified acetabulum, sequela',
  'S32451A Displaced transverse fracture of right acetabulum, initial encounter for closed fracture',
  'S32451B Displaced transverse fracture of right acetabulum, initial encounter for open fracture',
  'S32451D Displaced transverse fracture of right acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32451G Displaced transverse fracture of right acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32451K Displaced transverse fracture of right acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32451S Displaced transverse fracture of right acetabulum, sequela',
  'S32452A Displaced transverse fracture of left acetabulum, initial encounter for closed fracture',
  'S32452B Displaced transverse fracture of left acetabulum, initial encounter for open fracture',
  'S32452D Displaced transverse fracture of left acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32452G Displaced transverse fracture of left acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32452K Displaced transverse fracture of left acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32452S Displaced transverse fracture of left acetabulum, sequela',
  'S32453A Displaced transverse fracture of unspecified acetabulum, initial encounter for closed fracture',
  'S32453B Displaced transverse fracture of unspecified acetabulum, initial encounter for open fracture',
  'S32453D Displaced transverse fracture of unspecified acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32453G Displaced transverse fracture of unspecified acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32453K Displaced transverse fracture of unspecified acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32453S Displaced transverse fracture of unspecified acetabulum, sequela',
  'S32454A Nondisplaced transverse fracture of right acetabulum, initial encounter for closed fracture',
  'S32454B Nondisplaced transverse fracture of right acetabulum, initial encounter for open fracture',
  'S32454D Nondisplaced transverse fracture of right acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32454G Nondisplaced transverse fracture of right acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32454K Nondisplaced transverse fracture of right acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32454S Nondisplaced transverse fracture of right acetabulum, sequela',
  'S32455A Nondisplaced transverse fracture of left acetabulum, initial encounter for closed fracture',
  'S32455B Nondisplaced transverse fracture of left acetabulum, initial encounter for open fracture',
  'S32455D Nondisplaced transverse fracture of left acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32455G Nondisplaced transverse fracture of left acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32455K Nondisplaced transverse fracture of left acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32455S Nondisplaced transverse fracture of left acetabulum, sequela',
  'S32456A Nondisplaced transverse fracture of unspecified acetabulum, initial encounter for closed',
  'fracture',
  'S32456B Nondisplaced transverse fracture of unspecified acetabulum, initial encounter for open',
  'fracture',
  'S32456D Nondisplaced transverse fracture of unspecified acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32456G Nondisplaced transverse fracture of unspecified acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32456K Nondisplaced transverse fracture of unspecified acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32456S Nondisplaced transverse fracture of unspecified acetabulum, sequela',
  'S32461A Displaced associated transverse-posterior fracture of right acetabulum, initial encounter for',
  'closed fracture',
  'S32461B Displaced associated transverse-posterior fracture of right acetabulum, initial encounter for',
  'open fracture',
  'S32461D Displaced associated transverse-posterior fracture of right acetabulum, subsequent encounter',
  'for fracture with routine healing',
  'S32461G Displaced associated transverse-posterior fracture of right acetabulum, subsequent encounter',
  'for fracture with delayed healing',
  'S32461K Displaced associated transverse-posterior fracture of right acetabulum, subsequent encounter',
  'for fracture with nonunion',
  'S32461S Displaced associated transverse-posterior fracture of right acetabulum, sequela',
  'S32462A Displaced associated transverse-posterior fracture of left acetabulum, initial encounter for',
  'closed fracture',
  'S32462B Displaced associated transverse-posterior fracture of left acetabulum, initial encounter for',
  'open fracture',
  'S32462D Displaced associated transverse-posterior fracture of left acetabulum, subsequent encounter',
  'for fracture with routine healing',
  'S32462G Displaced associated transverse-posterior fracture of left acetabulum, subsequent encounter',
  'for fracture with delayed healing',
  'S32462K Displaced associated transverse-posterior fracture of left acetabulum, subsequent encounter',
  'for fracture with nonunion',
  'S32462S Displaced associated transverse-posterior fracture of left acetabulum, sequela',
  'S32463A Displaced associated transverse-posterior fracture of unspecified acetabulum, initial encounter',
  'for closed fracture',
  'S32463B Displaced associated transverse-posterior fracture of unspecified acetabulum, initial encounter',
  'for open fracture',
  'S32463D Displaced associated transverse-posterior fracture of unspecified acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32463G Displaced associated transverse-posterior fracture of unspecified acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32463K Displaced associated transverse-posterior fracture of unspecified acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32463S Displaced associated transverse-posterior fracture of unspecified acetabulum, sequela',
  'S32464A Nondisplaced associated transverse-posterior fracture of right acetabulum, initial encounter',
  'for closed fracture',
  'S32464B Nondisplaced associated transverse-posterior fracture of right acetabulum, initial encounter',
  'for open fracture',
  'S32464D Nondisplaced associated transverse-posterior fracture of right acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32464G Nondisplaced associated transverse-posterior fracture of right acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32464K Nondisplaced associated transverse-posterior fracture of right acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32464S Nondisplaced associated transverse-posterior fracture of right acetabulum, sequela',
  'S32465A Nondisplaced associated transverse-posterior fracture of left acetabulum, initial encounter for',
  'closed fracture',
  'S32465B Nondisplaced associated transverse-posterior fracture of left acetabulum, initial encounter for',
  'open fracture',
  'S32465D Nondisplaced associated transverse-posterior fracture of left acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32465G Nondisplaced associated transverse-posterior fracture of left acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32465K Nondisplaced associated transverse-posterior fracture of left acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32465S Nondisplaced associated transverse-posterior fracture of left acetabulum, sequela',
  'S32466A Nondisplaced associated transverse-posterior fracture of unspecified acetabulum, initial',
  'encounter for closed fracture',
  'S32466B Nondisplaced associated transverse-posterior fracture of unspecified acetabulum, initial',
  'encounter for open fracture',
  'S32466D Nondisplaced associated transverse-posterior fracture of unspecified acetabulum, subsequent',
  'encounter for fracture with routine healing',
  'S32466G Nondisplaced associated transverse-posterior fracture of unspecified acetabulum, subsequent',
  'encounter for fracture with delayed healing',
  'S32466K Nondisplaced associated transverse-posterior fracture of unspecified acetabulum, subsequent',
  'encounter for fracture with nonunion',
  'S32466S Nondisplaced associated transverse-posterior fracture of unspecified acetabulum, sequela',
  'S32471A Displaced fracture of medial wall of right acetabulum, initial encounter for closed fracture',
  'S32471B Displaced fracture of medial wall of right acetabulum, initial encounter for open fracture',
  'S32471D Displaced fracture of medial wall of right acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32471G Displaced fracture of medial wall of right acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32471K Displaced fracture of medial wall of right acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32471S Displaced fracture of medial wall of right acetabulum, sequela',
  'S32472A Displaced fracture of medial wall of left acetabulum, initial encounter for closed fracture',
  'S32472B Displaced fracture of medial wall of left acetabulum, initial encounter for open fracture',
  'S32472D Displaced fracture of medial wall of left acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32472G Displaced fracture of medial wall of left acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32472K Displaced fracture of medial wall of left acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32472S Displaced fracture of medial wall of left acetabulum, sequela',
  'S32473A Displaced fracture of medial wall of unspecified acetabulum, initial encounter for closed',
  'fracture',
  'S32473B Displaced fracture of medial wall of unspecified acetabulum, initial encounter for open fracture',
  'S32473D Displaced fracture of medial wall of unspecified acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32473G Displaced fracture of medial wall of unspecified acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32473K Displaced fracture of medial wall of unspecified acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32473S Displaced fracture of medial wall of unspecified acetabulum, sequela',
  'S32474A Nondisplaced fracture of medial wall of right acetabulum, initial encounter for closed fracture',
  'S32474B Nondisplaced fracture of medial wall of right acetabulum, initial encounter for open fracture',
  'S32474D Nondisplaced fracture of medial wall of right acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32474G Nondisplaced fracture of medial wall of right acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32474K Nondisplaced fracture of medial wall of right acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32474S Nondisplaced fracture of medial wall of right acetabulum, sequela',
  'S32475A Nondisplaced fracture of medial wall of left acetabulum, initial encounter for closed fracture',
  'S32475B Nondisplaced fracture of medial wall of left acetabulum, initial encounter for open fracture',
  'S32475D Nondisplaced fracture of medial wall of left acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32475G Nondisplaced fracture of medial wall of left acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32475K Nondisplaced fracture of medial wall of left acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32475S Nondisplaced fracture of medial wall of left acetabulum, sequela',
  'S32476A Nondisplaced fracture of medial wall of unspecified acetabulum, initial encounter for closed',
  'fracture',
  'S32476B Nondisplaced fracture of medial wall of unspecified acetabulum, initial encounter for open',
  'fracture',
  'S32476D Nondisplaced fracture of medial wall of unspecified acetabulum, subsequent encounter for',
  'fracture with routine healing',
  'S32476G Nondisplaced fracture of medial wall of unspecified acetabulum, subsequent encounter for',
  'fracture with delayed healing',
  'S32476K Nondisplaced fracture of medial wall of unspecified acetabulum, subsequent encounter for',
  'fracture with nonunion',
  'S32476S Nondisplaced fracture of medial wall of unspecified acetabulum, sequela',
  'S32481A Displaced dome fracture of right acetabulum, initial encounter for closed fracture',
  'S32481B Displaced dome fracture of right acetabulum, initial encounter for open fracture',
  'S32481D Displaced dome fracture of right acetabulum, subsequent encounter for fracture with routine',
  'healing',
  'S32481G Displaced dome fracture of right acetabulum, subsequent encounter for fracture with delayed',
  'healing',
  'S32481K Displaced dome fracture of right acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32481S Displaced dome fracture of right acetabulum, sequela',
  'S32482A Displaced dome fracture of left acetabulum, initial encounter for closed fracture',
  'S32482B Displaced dome fracture of left acetabulum, initial encounter for open fracture',
  'S32482D Displaced dome fracture of left acetabulum, subsequent encounter for fracture with routine',
  'healing',
  'S32482G Displaced dome fracture of left acetabulum, subsequent encounter for fracture with delayed',
  'healing',
  'S32482K Displaced dome fracture of left acetabulum, subsequent encounter for fracture with nonunion',
  'S32482S Displaced dome fracture of left acetabulum, sequela',
  'S32483A Displaced dome fracture of unspecified acetabulum, initial encounter for closed fracture',
  'S32483B Displaced dome fracture of unspecified acetabulum, initial encounter for open fracture',
  'S32483D Displaced dome fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32483G Displaced dome fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32483K Displaced dome fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32483S Displaced dome fracture of unspecified acetabulum, sequela',
  'S32484A Nondisplaced dome fracture of right acetabulum, initial encounter for closed fracture',
  'S32484B Nondisplaced dome fracture of right acetabulum, initial encounter for open fracture',
  'S32484D Nondisplaced dome fracture of right acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32484G Nondisplaced dome fracture of right acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32484K Nondisplaced dome fracture of right acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32484S Nondisplaced dome fracture of right acetabulum, sequela',
  'S32485A Nondisplaced dome fracture of left acetabulum, initial encounter for closed fracture',
  'S32485B Nondisplaced dome fracture of left acetabulum, initial encounter for open fracture',
  'S32485D Nondisplaced dome fracture of left acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32485G Nondisplaced dome fracture of left acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32485K Nondisplaced dome fracture of left acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32485S Nondisplaced dome fracture of left acetabulum, sequela',
  'S32486A Nondisplaced dome fracture of unspecified acetabulum, initial encounter for closed fracture',
  'S32486B Nondisplaced dome fracture of unspecified acetabulum, initial encounter for open fracture',
  'S32486D Nondisplaced dome fracture of unspecified acetabulum, subsequent encounter for fracture',
  'with routine healing',
  'S32486G Nondisplaced dome fracture of unspecified acetabulum, subsequent encounter for fracture',
  'with delayed healing',
  'S32486K Nondisplaced dome fracture of unspecified acetabulum, subsequent encounter for fracture',
  'with nonunion',
  'S32486S Nondisplaced dome fracture of unspecified acetabulum, sequela',
  'S32491A Other specified fracture of right acetabulum, initial encounter for closed fracture',
  'S32491B Other specified fracture of right acetabulum, initial encounter for open fracture',
  'S32491D Other specified fracture of right acetabulum, subsequent encounter for fracture with routine',
  'healing',
  'S32491G Other specified fracture of right acetabulum, subsequent encounter for fracture with delayed',
  'healing',
  'S32491K Other specified fracture of right acetabulum, subsequent encounter for fracture with nonunion',
  'S32491S Other specified fracture of right acetabulum, sequela',
  'S32492A Other specified fracture of left acetabulum, initial encounter for closed fracture',
  'S32492B Other specified fracture of left acetabulum, initial encounter for open fracture',
  'S32492D Other specified fracture of left acetabulum, subsequent encounter for fracture with routine',
  'healing',
  'S32492G Other specified fracture of left acetabulum, subsequent encounter for fracture with delayed',
  'healing',
  'S32492K Other specified fracture of left acetabulum, subsequent encounter for fracture with nonunion',
  'S32492S Other specified fracture of left acetabulum, sequela',
  'S32499A Other specified fracture of unspecified acetabulum, initial encounter for closed fracture',
  'S32499B Other specified fracture of unspecified acetabulum, initial encounter for open fracture',
  'S32499D Other specified fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'routine healing',
  'S32499G Other specified fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'delayed healing',
  'S32499K Other specified fracture of unspecified acetabulum, subsequent encounter for fracture with',
  'nonunion',
  'S32499S Other specified fracture of unspecified acetabulum, sequela',
  'S32501A Unspecified fracture of right pubis, initial encounter for closed fracture',
  'S32501B Unspecified fracture of right pubis, initial encounter for open fracture',
  'S32501D Unspecified fracture of right pubis, subsequent encounter for fracture with routine healing',
  'S32501G Unspecified fracture of right pubis, subsequent encounter for fracture with delayed healing',
  'S32501K Unspecified fracture of right pubis, subsequent encounter for fracture with nonunion',
  'S32501S Unspecified fracture of right pubis, sequela',
  'S32502A Unspecified fracture of left pubis, initial encounter for closed fracture',
  'S32502B Unspecified fracture of left pubis, initial encounter for open fracture',
  'S32502D Unspecified fracture of left pubis, subsequent encounter for fracture with routine healing',
  'S32502G Unspecified fracture of left pubis, subsequent encounter for fracture with delayed healing',
  'S32502K Unspecified fracture of left pubis, subsequent encounter for fracture with nonunion',
  'S32502S Unspecified fracture of left pubis, sequela',
  'S32509A Unspecified fracture of unspecified pubis, initial encounter for closed fracture',
  'S32509B Unspecified fracture of unspecified pubis, initial encounter for open fracture',
  'S32509D Unspecified fracture of unspecified pubis, subsequent encounter for fracture with routine',
  'healing',
  'S32509G Unspecified fracture of unspecified pubis, subsequent encounter for fracture with delayed',
  'healing',
  'S32509K Unspecified fracture of unspecified pubis, subsequent encounter for fracture with nonunion',
  'S32509S Unspecified fracture of unspecified pubis, sequela',
  'S32511A Fracture of superior rim of right pubis, initial encounter for closed fracture',
  'S32511B Fracture of superior rim of right pubis, initial encounter for open fracture',
  'S32511D Fracture of superior rim of right pubis, subsequent encounter for fracture with routine healing',
  'S32511G Fracture of superior rim of right pubis, subsequent encounter for fracture with delayed healing',
  'S32511K Fracture of superior rim of right pubis, subsequent encounter for fracture with nonunion',
  'S32511S Fracture of superior rim of right pubis, sequela',
  'S32512A Fracture of superior rim of left pubis, initial encounter for closed fracture',
  'S32512B Fracture of superior rim of left pubis, initial encounter for open fracture',
  'S32512D Fracture of superior rim of left pubis, subsequent encounter for fracture with routine healing',
  'S32512G Fracture of superior rim of left pubis, subsequent encounter for fracture with delayed healing',
  'S32512K Fracture of superior rim of left pubis, subsequent encounter for fracture with nonunion',
  'S32512S Fracture of superior rim of left pubis, sequela',
  'S32519A Fracture of superior rim of unspecified pubis, initial encounter for closed fracture',
  'S32519B Fracture of superior rim of unspecified pubis, initial encounter for open fracture',
  'S32519D Fracture of superior rim of unspecified pubis, subsequent encounter for fracture with routine',
  'healing',
  'S32519G Fracture of superior rim of unspecified pubis, subsequent encounter for fracture with delayed',
  'healing',
  'S32519K Fracture of superior rim of unspecified pubis, subsequent encounter for fracture with nonunion',
  'S32519S Fracture of superior rim of unspecified pubis, sequela',
  'S32591A Other specified fracture of right pubis, initial encounter for closed fracture',
  'S32591B Other specified fracture of right pubis, initial encounter for open fracture',
  'S32591D Other specified fracture of right pubis, subsequent encounter for fracture with routine healing',
  'S32591G Other specified fracture of right pubis, subsequent encounter for fracture with delayed healing',
  'S32591K Other specified fracture of right pubis, subsequent encounter for fracture with nonunion',
  'S32591S Other specified fracture of right pubis, sequela',
  'S32592A Other specified fracture of left pubis, initial encounter for closed fracture',
  'S32592B Other specified fracture of left pubis, initial encounter for open fracture',
  'S32592D Other specified fracture of left pubis, subsequent encounter for fracture with routine healing',
  'S32592G Other specified fracture of left pubis, subsequent encounter for fracture with delayed healing',
  'S32592K Other specified fracture of left pubis, subsequent encounter for fracture with nonunion',
  'S32592S Other specified fracture of left pubis, sequela',
  'S32599A Other specified fracture of unspecified pubis, initial encounter for closed fracture',
  'S32599B Other specified fracture of unspecified pubis, initial encounter for open fracture',
  'S32599D Other specified fracture of unspecified pubis, subsequent encounter for fracture with routine',
  'healing',
  'S32599G Other specified fracture of unspecified pubis, subsequent encounter for fracture with delayed',
  'healing',
  'S32599K Other specified fracture of unspecified pubis, subsequent encounter for fracture with nonunion',
  'S32599S Other specified fracture of unspecified pubis, sequela',
  'S32601A Unspecified fracture of right ischium, initial encounter for closed fracture',
  'S32601B Unspecified fracture of right ischium, initial encounter for open fracture',
  'S32601D Unspecified fracture of right ischium, subsequent encounter for fracture with routine healing',
  'S32601G Unspecified fracture of right ischium, subsequent encounter for fracture with delayed healing',
  'S32601K Unspecified fracture of right ischium, subsequent encounter for fracture with nonunion',
  'S32601S Unspecified fracture of right ischium, sequela',
  'S32602A Unspecified fracture of left ischium, initial encounter for closed fracture',
  'S32602B Unspecified fracture of left ischium, initial encounter for open fracture',
  'S32602D Unspecified fracture of left ischium, subsequent encounter for fracture with routine healing',
  'S32602G Unspecified fracture of left ischium, subsequent encounter for fracture with delayed healing',
  'S32602K Unspecified fracture of left ischium, subsequent encounter for fracture with nonunion',
  'S32602S Unspecified fracture of left ischium, sequela',
  'S32609A Unspecified fracture of unspecified ischium, initial encounter for closed fracture',
  'S32609B Unspecified fracture of unspecified ischium, initial encounter for open fracture',
  'S32609D Unspecified fracture of unspecified ischium, subsequent encounter for fracture with routine',
  'healing',
  'S32609G Unspecified fracture of unspecified ischium, subsequent encounter for fracture with delayed',
  'healing',
  'S32609K Unspecified fracture of unspecified ischium, subsequent encounter for fracture with nonunion',
  'S32609S Unspecified fracture of unspecified ischium, sequela',
  'S32611A Displaced avulsion fracture of right ischium, initial encounter for closed fracture',
  'S32611B Displaced avulsion fracture of right ischium, initial encounter for open fracture',
  'S32611D Displaced avulsion fracture of right ischium, subsequent encounter for fracture with routine',
  'healing',
  'S32611G Displaced avulsion fracture of right ischium, subsequent encounter for fracture with delayed',
  'healing',
  'S32611K Displaced avulsion fracture of right ischium, subsequent encounter for fracture with nonunion',
  'S32611S Displaced avulsion fracture of right ischium, sequela',
  'S32612A Displaced avulsion fracture of left ischium, initial encounter for closed fracture',
  'S32612B Displaced avulsion fracture of left ischium, initial encounter for open fracture',
  'S32612D Displaced avulsion fracture of left ischium, subsequent encounter for fracture with routine',
  'healing',
  'S32612G Displaced avulsion fracture of left ischium, subsequent encounter for fracture with delayed',
  'healing',
  'S32612K Displaced avulsion fracture of left ischium, subsequent encounter for fracture with nonunion',
  'S32612S Displaced avulsion fracture of left ischium, sequela',
  'S32613A Displaced avulsion fracture of unspecified ischium, initial encounter for closed fracture',
  'S32613B Displaced avulsion fracture of unspecified ischium, initial encounter for open fracture',
  'S32613D Displaced avulsion fracture of unspecified ischium, subsequent encounter for fracture with',
  'routine healing',
  'S32613G Displaced avulsion fracture of unspecified ischium, subsequent encounter for fracture with',
  'delayed healing',
  'S32613K Displaced avulsion fracture of unspecified ischium, subsequent encounter for fracture with',
  'nonunion',
  'S32613S Displaced avulsion fracture of unspecified ischium, sequela',
  'S32614A Nondisplaced avulsion fracture of right ischium, initial encounter for closed fracture',
  'S32614B Nondisplaced avulsion fracture of right ischium, initial encounter for open fracture',
  'S32614D Nondisplaced avulsion fracture of right ischium, subsequent encounter for fracture with',
  'routine healing',
  'S32614G Nondisplaced avulsion fracture of right ischium, subsequent encounter for fracture with',
  'delayed healing',
  'S32614K Nondisplaced avulsion fracture of right ischium, subsequent encounter for fracture with',
  'nonunion',
  'S32614S Nondisplaced avulsion fracture of right ischium, sequela',
  'S32615A Nondisplaced avulsion fracture of left ischium, initial encounter for closed fracture',
  'S32615B Nondisplaced avulsion fracture of left ischium, initial encounter for open fracture',
  'S32615D Nondisplaced avulsion fracture of left ischium, subsequent encounter for fracture with routine',
  'healing',
  'S32615G Nondisplaced avulsion fracture of left ischium, subsequent encounter for fracture with delayed',
  'healing',
  'S32615K Nondisplaced avulsion fracture of left ischium, subsequent encounter for fracture with',
  'nonunion',
  'S32615S Nondisplaced avulsion fracture of left ischium, sequela',
  'S32616A Nondisplaced avulsion fracture of unspecified ischium, initial encounter for closed fracture',
  'S32616B Nondisplaced avulsion fracture of unspecified ischium, initial encounter for open fracture',
  'S32616D Nondisplaced avulsion fracture of unspecified ischium, subsequent encounter for fracture with',
  'routine healing',
  'S32616G Nondisplaced avulsion fracture of unspecified ischium, subsequent encounter for fracture with',
  'delayed healing',
  'S32616K Nondisplaced avulsion fracture of unspecified ischium, subsequent encounter for fracture with',
  'nonunion',
  'S32616S Nondisplaced avulsion fracture of unspecified ischium, sequela',
  'S32691A Other specified fracture of right ischium, initial encounter for closed fracture',
  'S32691B Other specified fracture of right ischium, initial encounter for open fracture',
  'S32691D Other specified fracture of right ischium, subsequent encounter for fracture with routine',
  'healing',
  'S32691G Other specified fracture of right ischium, subsequent encounter for fracture with delayed',
  'healing',
  'S32691K Other specified fracture of right ischium, subsequent encounter for fracture with nonunion',
  'S32691S Other specified fracture of right ischium, sequela',
  'S32692A Other specified fracture of left ischium, initial encounter for closed fracture',
  'S32692B Other specified fracture of left ischium, initial encounter for open fracture',
  'S32692D Other specified fracture of left ischium, subsequent encounter for fracture with routine healing',
  'S32692G Other specified fracture of left ischium, subsequent encounter for fracture with delayed',
  'healing',
  'S32692K Other specified fracture of left ischium, subsequent encounter for fracture with nonunion',
  'S32692S Other specified fracture of left ischium, sequela',
  'S32699A Other specified fracture of unspecified ischium, initial encounter for closed fracture',
  'S32699B Other specified fracture of unspecified ischium, initial encounter for open fracture',
  'S32699D Other specified fracture of unspecified ischium, subsequent encounter for fracture with',
  'routine healing',
  'S32699G Other specified fracture of unspecified ischium, subsequent encounter for fracture with',
  'delayed healing',
  'S32699K Other specified fracture of unspecified ischium, subsequent encounter for fracture with',
  'nonunion',
  'S32699S Other specified fracture of unspecified ischium, sequela',
  'S32810A Multiple fractures of pelvis with stable disruption of pelvic ring, initial encounter for closed',
  'fracture',
  'S32810B Multiple fractures of pelvis with stable disruption of pelvic ring, initial encounter for open',
  'fracture',
  'S32810D Multiple fractures of pelvis with stable disruption of pelvic ring, subsequent encounter for',
  'fracture with routine healing',
  'S32810G Multiple fractures of pelvis with stable disruption of pelvic ring, subsequent encounter for',
  'fracture with delayed healing',
  'S32810K Multiple fractures of pelvis with stable disruption of pelvic ring, subsequent encounter for',
  'fracture with nonunion',
  'S32810S Multiple fractures of pelvis with stable disruption of pelvic ring, sequela',
  'S32811A Multiple fractures of pelvis with unstable disruption of pelvic ring, initial encounter for closed',
  'fracture',
  'S32811B Multiple fractures of pelvis with unstable disruption of pelvic ring, initial encounter for open',
  'fracture',
  'S32811D Multiple fractures of pelvis with unstable disruption of pelvic ring, subsequent encounter for',
  'fracture with routine healing',
  'S32811G Multiple fractures of pelvis with unstable disruption of pelvic ring, subsequent encounter for',
  'fracture with delayed healing',
  'S32811K Multiple fractures of pelvis with unstable disruption of pelvic ring, subsequent encounter for',
  'fracture with nonunion',
  'S32811S Multiple fractures of pelvis with unstable disruption of pelvic ring, sequela',
  'S3282XA Multiple fractures of pelvis without disruption of pelvic ring, initial encounter for closed',
  'fracture',
  'S3282XB Multiple fractures of pelvis without disruption of pelvic ring, initial encounter for open fracture',
  'S3282XD Multiple fractures of pelvis without disruption of pelvic ring, subsequent encounter for',
  'fracture with routine healing',
  'S3282XG Multiple fractures of pelvis without disruption of pelvic ring, subsequent encounter for',
  'fracture with delayed healing',
  'S3282XK Multiple fractures of pelvis without disruption of pelvic ring, subsequent encounter for fracture',
  'with nonunion',
  'S3282XS Multiple fractures of pelvis without disruption of pelvic ring, sequela',
  'S3289XA Fracture of other parts of pelvis, initial encounter for closed fracture',
  'S3289XB Fracture of other parts of pelvis, initial encounter for open fracture',
  'S3289XD Fracture of other parts of pelvis, subsequent encounter for fracture with routine healing',
  'S3289XG Fracture of other parts of pelvis, subsequent encounter for fracture with delayed healing',
  'S3289XK Fracture of other parts of pelvis, subsequent encounter for fracture with nonunion',
  'S3289XS Fracture of other parts of pelvis, sequela',
  'S329XXA Fracture of unspecified parts of lumbosacral spine and pelvis, initial encounter for closed',
  'fracture',
  'S329XXB Fracture of unspecified parts of lumbosacral spine and pelvis, initial encounter for open',
  'fracture',
  'S329XXD Fracture of unspecified parts of lumbosacral spine and pelvis, subsequent encounter for',
  'fracture with routine healing',
  'S329XXG Fracture of unspecified parts of lumbosacral spine and pelvis, subsequent encounter for',
  'fracture with delayed healing',
  'S329XXK Fracture of unspecified parts of lumbosacral spine and pelvis, subsequent encounter for',
  'fracture with nonunion',
  'S329XXS Fracture of unspecified parts of lumbosacral spine and pelvis, sequela',
  'S330XXA Traumatic rupture of lumbar intervertebral disc, initial encounter',
  'S330XXD Traumatic rupture of lumbar intervertebral disc, subsequent encounter',
  'S330XXS Traumatic rupture of lumbar intervertebral disc, sequela',
  'S33100A Subluxation of unspecified lumbar vertebra, initial encounter',
  'S33100D Subluxation of unspecified lumbar vertebra, subsequent encounter',
  'S33100S Subluxation of unspecified lumbar vertebra, sequela',
  'S33101A Dislocation of unspecified lumbar vertebra, initial encounter',
  'S33101D Dislocation of unspecified lumbar vertebra, subsequent encounter',
  'S33101S Dislocation of unspecified lumbar vertebra, sequela',
  'S33110A Subluxation of L1/L2 lumbar vertebra, initial encounter',
  'S33110D Subluxation of L1/L2 lumbar vertebra, subsequent encounter',
  'S33110S Subluxation of L1/L2 lumbar vertebra, sequela',
  'S33111A Dislocation of L1/L2 lumbar vertebra, initial encounter',
  'S33111D Dislocation of L1/L2 lumbar vertebra, subsequent encounter',
  'S33111S Dislocation of L1/L2 lumbar vertebra, sequela',
  'S33120A Subluxation of L2/L3 lumbar vertebra, initial encounter',
  'S33120D Subluxation of L2/L3 lumbar vertebra, subsequent encounter',
  'S33120S Subluxation of L2/L3 lumbar vertebra, sequela',
  'S33121A Dislocation of L2/L3 lumbar vertebra, initial encounter',
  'S33121D Dislocation of L2/L3 lumbar vertebra, subsequent encounter',
  'S33121S Dislocation of L2/L3 lumbar vertebra, sequela',
  'S33130A Subluxation of L3/L4 lumbar vertebra, initial encounter',
  'S33130D Subluxation of L3/L4 lumbar vertebra, subsequent encounter',
  'S33130S Subluxation of L3/L4 lumbar vertebra, sequela',
  'S33131A Dislocation of L3/L4 lumbar vertebra, initial encounter',
  'S33131D Dislocation of L3/L4 lumbar vertebra, subsequent encounter',
  'S33131S Dislocation of L3/L4 lumbar vertebra, sequela',
  'S33140A Subluxation of L4/L5 lumbar vertebra, initial encounter',
  'S33140D Subluxation of L4/L5 lumbar vertebra, subsequent encounter',
  'S33140S Subluxation of L4/L5 lumbar vertebra, sequela',
  'S33141A Dislocation of L4/L5 lumbar vertebra, initial encounter',
  'S33141D Dislocation of L4/L5 lumbar vertebra, subsequent encounter',
  'S33141S Dislocation of L4/L5 lumbar vertebra, sequela',
  'S332XXA Dislocation of sacroiliac and sacrococcygeal joint, initial encounter',
  'S332XXD Dislocation of sacroiliac and sacrococcygeal joint, subsequent encounter',
  'S332XXS Dislocation of sacroiliac and sacrococcygeal joint, sequela',
  'S3330XA Dislocation of unspecified parts of lumbar spine and pelvis, initial encounter',
  'S3330XD Dislocation of unspecified parts of lumbar spine and pelvis, subsequent encounter',
  'S3330XS Dislocation of unspecified parts of lumbar spine and pelvis, sequela',
  'S3339XA Dislocation of other parts of lumbar spine and pelvis, initial encounter',
  'S3339XD Dislocation of other parts of lumbar spine and pelvis, subsequent encounter',
  'S3339XS Dislocation of other parts of lumbar spine and pelvis, sequela',
  'S334XXA Traumatic rupture of symphysis pubis, initial encounter',
  'S334XXD Traumatic rupture of symphysis pubis, subsequent encounter',
  'S334XXS Traumatic rupture of symphysis pubis, sequela',
  'S335XXA Sprain of ligaments of lumbar spine, initial encounter',
  'S335XXD Sprain of ligaments of lumbar spine, subsequent encounter',
  'S335XXS Sprain of ligaments of lumbar spine, sequela',
  'S336XXA Sprain of sacroiliac joint, initial encounter',
  'S336XXD Sprain of sacroiliac joint, subsequent encounter',
  'S336XXS Sprain of sacroiliac joint, sequela',
  'S338XXA Sprain of other parts of lumbar spine and pelvis, initial encounter',
  'S338XXD Sprain of other parts of lumbar spine and pelvis, subsequent encounter',
  'S338XXS Sprain of other parts of lumbar spine and pelvis, sequela',
  'S339XXA Sprain of unspecified parts of lumbar spine and pelvis, initial encounter',
  'S339XXD Sprain of unspecified parts of lumbar spine and pelvis, subsequent encounter',
  'S339XXS Sprain of unspecified parts of lumbar spine and pelvis, sequela',
  'S3401XA Concussion and edema of lumbar spinal cord, initial encounter',
  'S3401XD Concussion and edema of lumbar spinal cord, subsequent encounter',
  'S3401XS Concussion and edema of lumbar spinal cord, sequela',
  'S3402XA Concussion and edema of sacral spinal cord, initial encounter',
  'S3402XD Concussion and edema of sacral spinal cord, subsequent encounter',
  'S3402XS Concussion and edema of sacral spinal cord, sequela',
  'S34101A Unspecified injury to L1 level of lumbar spinal cord, initial encounter',
  'S34101D Unspecified injury to L1 level of lumbar spinal cord, subsequent encounter',
  'S34101S Unspecified injury to L1 level of lumbar spinal cord, sequela',
  'S34102A Unspecified injury to L2 level of lumbar spinal cord, initial encounter',
  'S34102D Unspecified injury to L2 level of lumbar spinal cord, subsequent encounter',
  'S34102S Unspecified injury to L2 level of lumbar spinal cord, sequela',
  'S34103A Unspecified injury to L3 level of lumbar spinal cord, initial encounter',
  'S34103D Unspecified injury to L3 level of lumbar spinal cord, subsequent encounter',
  'S34103S Unspecified injury to L3 level of lumbar spinal cord, sequela',
  'S34104A Unspecified injury to L4 level of lumbar spinal cord, initial encounter',
  'S34104D Unspecified injury to L4 level of lumbar spinal cord, subsequent encounter',
  'S34104S Unspecified injury to L4 level of lumbar spinal cord, sequela',
  'S34105A Unspecified injury to L5 level of lumbar spinal cord, initial encounter',
  'S34105D Unspecified injury to L5 level of lumbar spinal cord, subsequent encounter',
  'S34105S Unspecified injury to L5 level of lumbar spinal cord, sequela',
  'S34109A Unspecified injury to unspecified level of lumbar spinal cord, initial encounter',
  'S34109D Unspecified injury to unspecified level of lumbar spinal cord, subsequent encounter',
  'S34109S Unspecified injury to unspecified level of lumbar spinal cord, sequela',
  'S34111A Complete lesion of L1 level of lumbar spinal cord, initial encounter',
  'S34111D Complete lesion of L1 level of lumbar spinal cord, subsequent encounter',
  'S34111S Complete lesion of L1 level of lumbar spinal cord, sequela',
  'S34112A Complete lesion of L2 level of lumbar spinal cord, initial encounter',
  'S34112D Complete lesion of L2 level of lumbar spinal cord, subsequent encounter',
  'S34112S Complete lesion of L2 level of lumbar spinal cord, sequela',
  'S34113A Complete lesion of L3 level of lumbar spinal cord, initial encounter',
  'S34113D Complete lesion of L3 level of lumbar spinal cord, subsequent encounter',
  'S34113S Complete lesion of L3 level of lumbar spinal cord, sequela',
  'S34114A Complete lesion of L4 level of lumbar spinal cord, initial encounter',
  'S34114D Complete lesion of L4 level of lumbar spinal cord, subsequent encounter',
  'S34114S Complete lesion of L4 level of lumbar spinal cord, sequela',
  'S34115A Complete lesion of L5 level of lumbar spinal cord, initial encounter',
  'S34115D Complete lesion of L5 level of lumbar spinal cord, subsequent encounter',
  'S34115S Complete lesion of L5 level of lumbar spinal cord, sequela',
  'S34119A Complete lesion of unspecified level of lumbar spinal cord, initial encounter',
  'S34119D Complete lesion of unspecified level of lumbar spinal cord, subsequent encounter',
  'S34119S Complete lesion of unspecified level of lumbar spinal cord, sequela',
  'S34121A Incomplete lesion of L1 level of lumbar spinal cord, initial encounter',
  'S34121D Incomplete lesion of L1 level of lumbar spinal cord, subsequent encounter',
  'S34121S Incomplete lesion of L1 level of lumbar spinal cord, sequela',
  'S34122A Incomplete lesion of L2 level of lumbar spinal cord, initial encounter',
  'S34122D Incomplete lesion of L2 level of lumbar spinal cord, subsequent encounter',
  'S34122S Incomplete lesion of L2 level of lumbar spinal cord, sequela',
  'S34123A Incomplete lesion of L3 level of lumbar spinal cord, initial encounter',
  'S34123D Incomplete lesion of L3 level of lumbar spinal cord, subsequent encounter',
  'S34123S Incomplete lesion of L3 level of lumbar spinal cord, sequela',
  'S34124A Incomplete lesion of L4 level of lumbar spinal cord, initial encounter',
  'S34124D Incomplete lesion of L4 level of lumbar spinal cord, subsequent encounter',
  'S34124S Incomplete lesion of L4 level of lumbar spinal cord, sequela',
  'S34125A Incomplete lesion of L5 level of lumbar spinal cord, initial encounter',
  'S34125D Incomplete lesion of L5 level of lumbar spinal cord, subsequent encounter',
  'S34125S Incomplete lesion of L5 level of lumbar spinal cord, sequela',
  'S34129A Incomplete lesion of unspecified level of lumbar spinal cord, initial encounter',
  'S34129D Incomplete lesion of unspecified level of lumbar spinal cord, subsequent encounter',
  'S34129S Incomplete lesion of unspecified level of lumbar spinal cord, sequela',
  'S34131A Complete lesion of sacral spinal cord, initial encounter',
  'S34131D Complete lesion of sacral spinal cord, subsequent encounter',
  'S34131S Complete lesion of sacral spinal cord, sequela',
  'S34132A Incomplete lesion of sacral spinal cord, initial encounter',
  'S34132D Incomplete lesion of sacral spinal cord, subsequent encounter',
  'S34132S Incomplete lesion of sacral spinal cord, sequela',
  'S34139A Unspecified injury to sacral spinal cord, initial encounter',
  'S34139D Unspecified injury to sacral spinal cord, subsequent encounter',
  'S34139S Unspecified injury to sacral spinal cord, sequela',
  'S3421XA Injury of nerve root of lumbar spine, initial encounter',
  'S3421XD Injury of nerve root of lumbar spine, subsequent encounter',
  'S3421XS Injury of nerve root of lumbar spine, sequela',
  'S3422XA Injury of nerve root of sacral spine, initial encounter',
  'S3422XD Injury of nerve root of sacral spine, subsequent encounter',
  'S3422XS Injury of nerve root of sacral spine, sequela',
  'S343XXA Injury of cauda equina, initial encounter',
  'S343XXD Injury of cauda equina, subsequent encounter',
  'S343XXS Injury of cauda equina, sequela',
  'S344XXA Injury of lumbosacral plexus, initial encounter',
  'S344XXD Injury of lumbosacral plexus, subsequent encounter',
  'S344XXS Injury of lumbosacral plexus, sequela',
  'S345XXA Injury of lumbar, sacral and pelvic sympathetic nerves, initial encounter',
  'S345XXD Injury of lumbar, sacral and pelvic sympathetic nerves, subsequent encounter',
  'S345XXS Injury of lumbar, sacral and pelvic sympathetic nerves, sequela',
  'S346XXA Injury of peripheral nerve(s) at abdomen, lower back and pelvis level, initial encounter',
  'S346XXD Injury of peripheral nerve(s) at abdomen, lower back and pelvis level, subsequent encounter',
  'S346XXS Injury of peripheral nerve(s) at abdomen, lower back and pelvis level, sequela',
  'S348XXA Injury of other nerves at abdomen, lower back and pelvis level, initial encounter',
  'S348XXD Injury of other nerves at abdomen, lower back and pelvis level, subsequent encounter',
  'S348XXS Injury of other nerves at abdomen, lower back and pelvis level, sequela',
  'S349XXA Injury of unspecified nerves at abdomen, lower back and pelvis level, initial encounter',
  'S349XXD Injury of unspecified nerves at abdomen, lower back and pelvis level, subsequent encounter',
  'S349XXS Injury of unspecified nerves at abdomen, lower back and pelvis level, sequela',
  'S3500XA Unspecified injury of abdominal aorta, initial encounter',
  'S3500XD Unspecified injury of abdominal aorta, subsequent encounter',
  'S3500XS Unspecified injury of abdominal aorta, sequela',
  'S3501XA Minor laceration of abdominal aorta, initial encounter',
  'S3501XD Minor laceration of abdominal aorta, subsequent encounter',
  'S3501XS Minor laceration of abdominal aorta, sequela',
  'S3502XA Major laceration of abdominal aorta, initial encounter',
  'S3502XD Major laceration of abdominal aorta, subsequent encounter',
  'S3502XS Major laceration of abdominal aorta, sequela',
  'S3509XA Other injury of abdominal aorta, initial encounter',
  'S3509XD Other injury of abdominal aorta, subsequent encounter',
  'S3509XS Other injury of abdominal aorta, sequela',
  'S3510XA Unspecified injury of inferior vena cava, initial encounter',
  'S3510XD Unspecified injury of inferior vena cava, subsequent encounter',
  'S3510XS Unspecified injury of inferior vena cava, sequela',
  'S3511XA Minor laceration of inferior vena cava, initial encounter',
  'S3511XD Minor laceration of inferior vena cava, subsequent encounter',
  'S3511XS Minor laceration of inferior vena cava, sequela',
  'S3512XA Major laceration of inferior vena cava, initial encounter',
  'S3512XD Major laceration of inferior vena cava, subsequent encounter',
  'S3512XS Major laceration of inferior vena cava, sequela',
  'S3519XA Other injury of inferior vena cava, initial encounter',
  'S3519XD Other injury of inferior vena cava, subsequent encounter',
  'S3519XS Other injury of inferior vena cava, sequela',
  'S35211A Minor laceration of celiac artery, initial encounter',
  'S35211D Minor laceration of celiac artery, subsequent encounter',
  'S35211S Minor laceration of celiac artery, sequela',
  'S35212A Major laceration of celiac artery, initial encounter',
  'S35212D Major laceration of celiac artery, subsequent encounter',
  'S35212S Major laceration of celiac artery, sequela',
  'S35218A Other injury of celiac artery, initial encounter',
  'S35218D Other injury of celiac artery, subsequent encounter',
  'S35218S Other injury of celiac artery, sequela',
  'S35219A Unspecified injury of celiac artery, initial encounter',
  'S35219D Unspecified injury of celiac artery, subsequent encounter',
  'S35219S Unspecified injury of celiac artery, sequela',
  'S35221A Minor laceration of superior mesenteric artery, initial encounter',
  'S35221D Minor laceration of superior mesenteric artery, subsequent encounter',
  'S35221S Minor laceration of superior mesenteric artery, sequela',
  'S35222A Major laceration of superior mesenteric artery, initial encounter',
  'S35222D Major laceration of superior mesenteric artery, subsequent encounter',
  'S35222S Major laceration of superior mesenteric artery, sequela',
  'S35228A Other injury of superior mesenteric artery, initial encounter',
  'S35228D Other injury of superior mesenteric artery, subsequent encounter',
  'S35228S Other injury of superior mesenteric artery, sequela',
  'S35229A Unspecified injury of superior mesenteric artery, initial encounter',
  'S35229D Unspecified injury of superior mesenteric artery, subsequent encounter',
  'S35229S Unspecified injury of superior mesenteric artery, sequela',
  'S35231A Minor laceration of inferior mesenteric artery, initial encounter',
  'S35231D Minor laceration of inferior mesenteric artery, subsequent encounter',
  'S35231S Minor laceration of inferior mesenteric artery, sequela',
  'S35232A Major laceration of inferior mesenteric artery, initial encounter',
  'S35232D Major laceration of inferior mesenteric artery, subsequent encounter',
  'S35232S Major laceration of inferior mesenteric artery, sequela',
  'S35238A Other injury of inferior mesenteric artery, initial encounter',
  'S35238D Other injury of inferior mesenteric artery, subsequent encounter',
  'S35238S Other injury of inferior mesenteric artery, sequela',
  'S35239A Unspecified injury of inferior mesenteric artery, initial encounter',
  'S35239D Unspecified injury of inferior mesenteric artery, subsequent encounter',
  'S35239S Unspecified injury of inferior mesenteric artery, sequela',
  'S35291A Minor laceration of branches of celiac and mesenteric artery, initial encounter',
  'S35291D Minor laceration of branches of celiac and mesenteric artery, subsequent encounter',
  'S35291S Minor laceration of branches of celiac and mesenteric artery, sequela',
  'S35292A Major laceration of branches of celiac and mesenteric artery, initial encounter',
  'S35292D Major laceration of branches of celiac and mesenteric artery, subsequent encounter',
  'S35292S Major laceration of branches of celiac and mesenteric artery, sequela',
  'S35298A Other injury of branches of celiac and mesenteric artery, initial encounter',
  'S35298D Other injury of branches of celiac and mesenteric artery, subsequent encounter',
  'S35298S Other injury of branches of celiac and mesenteric artery, sequela',
  'S35299A Unspecified injury of branches of celiac and mesenteric artery, initial encounter',
  'S35299D Unspecified injury of branches of celiac and mesenteric artery, subsequent encounter',
  'S35299S Unspecified injury of branches of celiac and mesenteric artery, sequela',
  'S35311A Laceration of portal vein, initial encounter',
  'S35311D Laceration of portal vein, subsequent encounter',
  'S35311S Laceration of portal vein, sequela',
  'S35318A Other specified injury of portal vein, initial encounter',
  'S35318D Other specified injury of portal vein, subsequent encounter',
  'S35318S Other specified injury of portal vein, sequela',
  'S35319A Unspecified injury of portal vein, initial encounter',
  'S35319D Unspecified injury of portal vein, subsequent encounter',
  'S35319S Unspecified injury of portal vein, sequela',
  'S35321A Laceration of splenic vein, initial encounter',
  'S35321D Laceration of splenic vein, subsequent encounter',
  'S35321S Laceration of splenic vein, sequela',
  'S35328A Other specified injury of splenic vein, initial encounter',
  'S35328D Other specified injury of splenic vein, subsequent encounter',
  'S35328S Other specified injury of splenic vein, sequela',
  'S35329A Unspecified injury of splenic vein, initial encounter',
  'S35329D Unspecified injury of splenic vein, subsequent encounter',
  'S35329S Unspecified injury of splenic vein, sequela',
  'S35331A Laceration of superior mesenteric vein, initial encounter',
  'S35331D Laceration of superior mesenteric vein, subsequent encounter',
  'S35331S Laceration of superior mesenteric vein, sequela',
  'S35338A Other specified injury of superior mesenteric vein, initial encounter',
  'S35338D Other specified injury of superior mesenteric vein, subsequent encounter',
  'S35338S Other specified injury of superior mesenteric vein, sequela',
  'S35339A Unspecified injury of superior mesenteric vein, initial encounter',
  'S35339D Unspecified injury of superior mesenteric vein, subsequent encounter',
  'S35339S Unspecified injury of superior mesenteric vein, sequela',
  'S35341A Laceration of inferior mesenteric vein, initial encounter',
  'S35341D Laceration of inferior mesenteric vein, subsequent encounter',
  'S35341S Laceration of inferior mesenteric vein, sequela',
  'S35348A Other specified injury of inferior mesenteric vein, initial encounter',
  'S35348D Other specified injury of inferior mesenteric vein, subsequent encounter',
  'S35348S Other specified injury of inferior mesenteric vein, sequela',
  'S35349A Unspecified injury of inferior mesenteric vein, initial encounter',
  'S35349D Unspecified injury of inferior mesenteric vein, subsequent encounter',
  'S35349S Unspecified injury of inferior mesenteric vein, sequela',
  'S35401A Unspecified injury of right renal artery, initial encounter',
  'S35401D Unspecified injury of right renal artery, subsequent encounter',
  'S35401S Unspecified injury of right renal artery, sequela',
  'S35402A Unspecified injury of left renal artery, initial encounter',
  'S35402D Unspecified injury of left renal artery, subsequent encounter',
  'S35402S Unspecified injury of left renal artery, sequela',
  'S35403A Unspecified injury of unspecified renal artery, initial encounter',
  'S35403D Unspecified injury of unspecified renal artery, subsequent encounter',
  'S35403S Unspecified injury of unspecified renal artery, sequela',
  'S35404A Unspecified injury of right renal vein, initial encounter',
  'S35404D Unspecified injury of right renal vein, subsequent encounter',
  'S35404S Unspecified injury of right renal vein, sequela',
  'S35405A Unspecified injury of left renal vein, initial encounter',
  'S35405D Unspecified injury of left renal vein, subsequent encounter',
  'S35405S Unspecified injury of left renal vein, sequela',
  'S35406A Unspecified injury of unspecified renal vein, initial encounter',
  'S35406D Unspecified injury of unspecified renal vein, subsequent encounter',
  'S35406S Unspecified injury of unspecified renal vein, sequela',
  'S35411A Laceration of right renal artery, initial encounter',
  'S35411D Laceration of right renal artery, subsequent encounter',
  'S35411S Laceration of right renal artery, sequela',
  'S35412A Laceration of left renal artery, initial encounter',
  'S35412D Laceration of left renal artery, subsequent encounter',
  'S35412S Laceration of left renal artery, sequela',
  'S35413A Laceration of unspecified renal artery, initial encounter',
  'S35413D Laceration of unspecified renal artery, subsequent encounter',
  'S35413S Laceration of unspecified renal artery, sequela',
  'S35414A Laceration of right renal vein, initial encounter',
  'S35414D Laceration of right renal vein, subsequent encounter',
  'S35414S Laceration of right renal vein, sequela',
  'S35415A Laceration of left renal vein, initial encounter',
  'S35415D Laceration of left renal vein, subsequent encounter',
  'S35415S Laceration of left renal vein, sequela',
  'S35416A Laceration of unspecified renal vein, initial encounter',
  'S35416D Laceration of unspecified renal vein, subsequent encounter',
  'S35416S Laceration of unspecified renal vein, sequela',
  'S35491A Other specified injury of right renal artery, initial encounter',
  'S35491D Other specified injury of right renal artery, subsequent encounter',
  'S35491S Other specified injury of right renal artery, sequela',
  'S35492A Other specified injury of left renal artery, initial encounter',
  'S35492D Other specified injury of left renal artery, subsequent encounter',
  'S35492S Other specified injury of left renal artery, sequela',
  'S35493A Other specified injury of unspecified renal artery, initial encounter',
  'S35493D Other specified injury of unspecified renal artery, subsequent encounter',
  'S35493S Other specified injury of unspecified renal artery, sequela',
  'S35494A Other specified injury of right renal vein, initial encounter',
  'S35494D Other specified injury of right renal vein, subsequent encounter',
  'S35494S Other specified injury of right renal vein, sequela',
  'S35495A Other specified injury of left renal vein, initial encounter',
  'S35495D Other specified injury of left renal vein, subsequent encounter',
  'S35495S Other specified injury of left renal vein, sequela',
  'S35496A Other specified injury of unspecified renal vein, initial encounter',
  'S35496D Other specified injury of unspecified renal vein, subsequent encounter',
  'S35496S Other specified injury of unspecified renal vein, sequela',
  'S3550XA Injury of unspecified iliac blood vessel(s), initial encounter',
  'S3550XD Injury of unspecified iliac blood vessel(s), subsequent encounter',
  'S3550XS Injury of unspecified iliac blood vessel(s), sequela',
  'S35511A Injury of right iliac artery, initial encounter',
  'S35511D Injury of right iliac artery, subsequent encounter',
  'S35511S Injury of right iliac artery, sequela',
  'S35512A Injury of left iliac artery, initial encounter',
  'S35512D Injury of left iliac artery, subsequent encounter',
  'S35512S Injury of left iliac artery, sequela',
  'S35513A Injury of unspecified iliac artery, initial encounter',
  'S35513D Injury of unspecified iliac artery, subsequent encounter',
  'S35513S Injury of unspecified iliac artery, sequela',
  'S35514A Injury of right iliac vein, initial encounter',
  'S35514D Injury of right iliac vein, subsequent encounter',
  'S35514S Injury of right iliac vein, sequela',
  'S35515A Injury of left iliac vein, initial encounter',
  'S35515D Injury of left iliac vein, subsequent encounter',
  'S35515S Injury of left iliac vein, sequela',
  'S35516A Injury of unspecified iliac vein, initial encounter',
  'S35516D Injury of unspecified iliac vein, subsequent encounter',
  'S35516S Injury of unspecified iliac vein, sequela',
  'S35531A Injury of right uterine artery, initial encounter',
  'S35531D Injury of right uterine artery, subsequent encounter',
  'S35531S Injury of right uterine artery, sequela',
  'S35532A Injury of left uterine artery, initial encounter',
  'S35532D Injury of left uterine artery, subsequent encounter',
  'S35532S Injury of left uterine artery, sequela',
  'S35533A Injury of unspecified uterine artery, initial encounter',
  'S35533D Injury of unspecified uterine artery, subsequent encounter',
  'S35533S Injury of unspecified uterine artery, sequela',
  'S35534A Injury of right uterine vein, initial encounter',
  'S35534D Injury of right uterine vein, subsequent encounter',
  'S35534S Injury of right uterine vein, sequela',
  'S35535A Injury of left uterine vein, initial encounter',
  'S35535D Injury of left uterine vein, subsequent encounter',
  'S35535S Injury of left uterine vein, sequela',
  'S35536A Injury of unspecified uterine vein, initial encounter',
  'S35536D Injury of unspecified uterine vein, subsequent encounter',
  'S35536S Injury of unspecified uterine vein, sequela',
  'S3559XA Injury of other iliac blood vessels, initial encounter',
  'S3559XD Injury of other iliac blood vessels, subsequent encounter',
  'S3559XS Injury of other iliac blood vessels, sequela',
  'S358X1A Laceration of other blood vessels at abdomen, lower back and pelvis level, initial encounter',
  'S358X1D Laceration of other blood vessels at abdomen, lower back and pelvis level, subsequent',
  'encounter',
  'S358X1S Laceration of other blood vessels at abdomen, lower back and pelvis level, sequela',
  'S358X8A Other specified injury of other blood vessels at abdomen, lower back and pelvis level, initial',
  'encounter',
  'S358X8D Other specified injury of other blood vessels at abdomen, lower back and pelvis level,',
  'subsequent encounter',
  'S358X8S Other specified injury of other blood vessels at abdomen, lower back and pelvis level, sequela',
  'S358X9A Unspecified injury of other blood vessels at abdomen, lower back and pelvis level, initial',
  'encounter',
  'S358X9D Unspecified injury of other blood vessels at abdomen, lower back and pelvis level, subsequent',
  'encounter',
  'S358X9S Unspecified injury of other blood vessels at abdomen, lower back and pelvis level, sequela',
  'S3590XA Unspecified injury of unspecified blood vessel at abdomen, lower back and pelvis level, initial',
  'encounter',
  'S3590XD Unspecified injury of unspecified blood vessel at abdomen, lower back and pelvis level,',
  'subsequent encounter',
  'S3590XS Unspecified injury of unspecified blood vessel at abdomen, lower back and pelvis level, sequela',
  'S3591XA Laceration of unspecified blood vessel at abdomen, lower back and pelvis level, initial',
  'encounter',
  'S3591XD Laceration of unspecified blood vessel at abdomen, lower back and pelvis level, subsequent',
  'encounter',
  'S3591XS Laceration of unspecified blood vessel at abdomen, lower back and pelvis level, sequela',
  'S3599XA Other specified injury of unspecified blood vessel at abdomen, lower back and pelvis level,',
  'initial encounter',
  'S3599XD Other specified injury of unspecified blood vessel at abdomen, lower back and pelvis level,',
  'subsequent encounter',
  'S3599XS Other specified injury of unspecified blood vessel at abdomen, lower back and pelvis level,',
  'sequela',
  'S3600XA Unspecified injury of spleen, initial encounter',
  'S3600XD Unspecified injury of spleen, subsequent encounter',
  'S3600XS Unspecified injury of spleen, sequela',
  'S36020A Minor contusion of spleen, initial encounter',
  'S36020D Minor contusion of spleen, subsequent encounter',
  'S36020S Minor contusion of spleen, sequela',
  'S36021A Major contusion of spleen, initial encounter',
  'S36021D Major contusion of spleen, subsequent encounter',
  'S36021S Major contusion of spleen, sequela',
  'S36029A Unspecified contusion of spleen, initial encounter',
  'S36029D Unspecified contusion of spleen, subsequent encounter',
  'S36029S Unspecified contusion of spleen, sequela',
  'S36030A Superficial (capsular) laceration of spleen, initial encounter',
  'S36030D Superficial (capsular) laceration of spleen, subsequent encounter',
  'S36030S Superficial (capsular) laceration of spleen, sequela',
  'S36031A Moderate laceration of spleen, initial encounter',
  'S36031D Moderate laceration of spleen, subsequent encounter',
  'S36031S Moderate laceration of spleen, sequela',
  'S36032A Major laceration of spleen, initial encounter',
  'S36032D Major laceration of spleen, subsequent encounter',
  'S36032S Major laceration of spleen, sequela',
  'S36039A Unspecified laceration of spleen, initial encounter',
  'S36039D Unspecified laceration of spleen, subsequent encounter',
  'S36039S Unspecified laceration of spleen, sequela',
  'S3609XA Other injury of spleen, initial encounter',
  'S3609XD Other injury of spleen, subsequent encounter',
  'S3609XS Other injury of spleen, sequela',
  'S36112A Contusion of liver, initial encounter',
  'S36112D Contusion of liver, subsequent encounter',
  'S36112S Contusion of liver, sequela',
  'S36113A Laceration of liver, unspecified degree, initial encounter',
  'S36113D Laceration of liver, unspecified degree, subsequent encounter',
  'S36113S Laceration of liver, unspecified degree, sequela',
  'S36114A Minor laceration of liver, initial encounter',
  'S36114D Minor laceration of liver, subsequent encounter',
  'S36114S Minor laceration of liver, sequela',
  'S36115A Moderate laceration of liver, initial encounter',
  'S36115D Moderate laceration of liver, subsequent encounter',
  'S36115S Moderate laceration of liver, sequela',
  'S36116A Major laceration of liver, initial encounter',
  'S36116D Major laceration of liver, subsequent encounter',
  'S36116S Major laceration of liver, sequela',
  'S36118A Other injury of liver, initial encounter',
  'S36118D Other injury of liver, subsequent encounter',
  'S36118S Other injury of liver, sequela',
  'S36119A Unspecified injury of liver, initial encounter',
  'S36119D Unspecified injury of liver, subsequent encounter',
  'S36119S Unspecified injury of liver, sequela',
  'S36122A Contusion of gallbladder, initial encounter',
  'S36122D Contusion of gallbladder, subsequent encounter',
  'S36122S Contusion of gallbladder, sequela',
  'S36123A Laceration of gallbladder, initial encounter',
  'S36123D Laceration of gallbladder, subsequent encounter',
  'S36123S Laceration of gallbladder, sequela',
  'S36128A Other injury of gallbladder, initial encounter',
  'S36128D Other injury of gallbladder, subsequent encounter',
  'S36128S Other injury of gallbladder, sequela',
  'S36129A Unspecified injury of gallbladder, initial encounter',
  'S36129D Unspecified injury of gallbladder, subsequent encounter',
  'S36129S Unspecified injury of gallbladder, sequela',
  'S3613XA Injury of bile duct, initial encounter',
  'S3613XD Injury of bile duct, subsequent encounter',
  'S3613XS Injury of bile duct, sequela',
  'S36200A Unspecified injury of head of pancreas, initial encounter',
  'S36200D Unspecified injury of head of pancreas, subsequent encounter',
  'S36200S Unspecified injury of head of pancreas, sequela',
  'S36201A Unspecified injury of body of pancreas, initial encounter',
  'S36201D Unspecified injury of body of pancreas, subsequent encounter',
  'S36201S Unspecified injury of body of pancreas, sequela',
  'S36202A Unspecified injury of tail of pancreas, initial encounter',
  'S36202D Unspecified injury of tail of pancreas, subsequent encounter',
  'S36202S Unspecified injury of tail of pancreas, sequela',
  'S36209A Unspecified injury of unspecified part of pancreas, initial encounter',
  'S36209D Unspecified injury of unspecified part of pancreas, subsequent encounter',
  'S36209S Unspecified injury of unspecified part of pancreas, sequela',
  'S36220A Contusion of head of pancreas, initial encounter',
  'S36220D Contusion of head of pancreas, subsequent encounter',
  'S36220S Contusion of head of pancreas, sequela',
  'S36221A Contusion of body of pancreas, initial encounter',
  'S36221D Contusion of body of pancreas, subsequent encounter',
  'S36221S Contusion of body of pancreas, sequela',
  'S36222A Contusion of tail of pancreas, initial encounter',
  'S36222D Contusion of tail of pancreas, subsequent encounter',
  'S36222S Contusion of tail of pancreas, sequela',
  'S36229A Contusion of unspecified part of pancreas, initial encounter',
  'S36229D Contusion of unspecified part of pancreas, subsequent encounter',
  'S36229S Contusion of unspecified part of pancreas, sequela',
  'S36230A Laceration of head of pancreas, unspecified degree, initial encounter',
  'S36230D Laceration of head of pancreas, unspecified degree, subsequent encounter',
  'S36230S Laceration of head of pancreas, unspecified degree, sequela',
  'S36231A Laceration of body of pancreas, unspecified degree, initial encounter',
  'S36231D Laceration of body of pancreas, unspecified degree, subsequent encounter',
  'S36231S Laceration of body of pancreas, unspecified degree, sequela',
  'S36232A Laceration of tail of pancreas, unspecified degree, initial encounter',
  'S36232D Laceration of tail of pancreas, unspecified degree, subsequent encounter',
  'S36232S Laceration of tail of pancreas, unspecified degree, sequela',
  'S36239A Laceration of unspecified part of pancreas, unspecified degree, initial encounter',
  'S36239D Laceration of unspecified part of pancreas, unspecified degree, subsequent encounter',
  'S36239S Laceration of unspecified part of pancreas, unspecified degree, sequela',
  'S36240A Minor laceration of head of pancreas, initial encounter',
  'S36240D Minor laceration of head of pancreas, subsequent encounter',
  'S36240S Minor laceration of head of pancreas, sequela',
  'S36241A Minor laceration of body of pancreas, initial encounter',
  'S36241D Minor laceration of body of pancreas, subsequent encounter',
  'S36241S Minor laceration of body of pancreas, sequela',
  'S36242A Minor laceration of tail of pancreas, initial encounter',
  'S36242D Minor laceration of tail of pancreas, subsequent encounter',
  'S36242S Minor laceration of tail of pancreas, sequela',
  'S36249A Minor laceration of unspecified part of pancreas, initial encounter',
  'S36249D Minor laceration of unspecified part of pancreas, subsequent encounter',
  'S36249S Minor laceration of unspecified part of pancreas, sequela',
  'S36250A Moderate laceration of head of pancreas, initial encounter',
  'S36250D Moderate laceration of head of pancreas, subsequent encounter',
  'S36250S Moderate laceration of head of pancreas, sequela',
  'S36251A Moderate laceration of body of pancreas, initial encounter',
  'S36251D Moderate laceration of body of pancreas, subsequent encounter',
  'S36251S Moderate laceration of body of pancreas, sequela',
  'S36252A Moderate laceration of tail of pancreas, initial encounter',
  'S36252D Moderate laceration of tail of pancreas, subsequent encounter',
  'S36252S Moderate laceration of tail of pancreas, sequela',
  'S36259A Moderate laceration of unspecified part of pancreas, initial encounter',
  'S36259D Moderate laceration of unspecified part of pancreas, subsequent encounter',
  'S36259S Moderate laceration of unspecified part of pancreas, sequela',
  'S36260A Major laceration of head of pancreas, initial encounter',
  'S36260D Major laceration of head of pancreas, subsequent encounter',
  'S36260S Major laceration of head of pancreas, sequela',
  'S36261A Major laceration of body of pancreas, initial encounter',
  'S36261D Major laceration of body of pancreas, subsequent encounter',
  'S36261S Major laceration of body of pancreas, sequela',
  'S36262A Major laceration of tail of pancreas, initial encounter',
  'S36262D Major laceration of tail of pancreas, subsequent encounter',
  'S36262S Major laceration of tail of pancreas, sequela',
  'S36269A Major laceration of unspecified part of pancreas, initial encounter',
  'S36269D Major laceration of unspecified part of pancreas, subsequent encounter',
  'S36269S Major laceration of unspecified part of pancreas, sequela',
  'S36290A Other injury of head of pancreas, initial encounter',
  'S36290D Other injury of head of pancreas, subsequent encounter',
  'S36290S Other injury of head of pancreas, sequela',
  'S36291A Other injury of body of pancreas, initial encounter',
  'S36291D Other injury of body of pancreas, subsequent encounter',
  'S36291S Other injury of body of pancreas, sequela',
  'S36292A Other injury of tail of pancreas, initial encounter',
  'S36292D Other injury of tail of pancreas, subsequent encounter',
  'S36292S Other injury of tail of pancreas, sequela',
  'S36299A Other injury of unspecified part of pancreas, initial encounter',
  'S36299D Other injury of unspecified part of pancreas, subsequent encounter',
  'S36299S Other injury of unspecified part of pancreas, sequela',
  'S3630XA Unspecified injury of stomach, initial encounter',
  'S3630XD Unspecified injury of stomach, subsequent encounter',
  'S3630XS Unspecified injury of stomach, sequela',
  'S3632XA Contusion of stomach, initial encounter',
  'S3632XD Contusion of stomach, subsequent encounter',
  'S3632XS Contusion of stomach, sequela',
  'S3633XA Laceration of stomach, initial encounter',
  'S3633XD Laceration of stomach, subsequent encounter',
  'S3633XS Laceration of stomach, sequela',
  'S3639XA Other injury of stomach, initial encounter',
  'S3639XD Other injury of stomach, subsequent encounter',
  'S3639XS Other injury of stomach, sequela',
  'S36400A Unspecified injury of duodenum, initial encounter',
  'S36400D Unspecified injury of duodenum, subsequent encounter',
  'S36400S Unspecified injury of duodenum, sequela',
  'S36408A Unspecified injury of other part of small intestine, initial encounter',
  'S36408D Unspecified injury of other part of small intestine, subsequent encounter',
  'S36408S Unspecified injury of other part of small intestine, sequela',
  'S36409A Unspecified injury of unspecified part of small intestine, initial encounter',
  'S36409D Unspecified injury of unspecified part of small intestine, subsequent encounter',
  'S36409S Unspecified injury of unspecified part of small intestine, sequela',
  'S36410A Primary blast injury of duodenum, initial encounter',
  'S36410D Primary blast injury of duodenum, subsequent encounter',
  'S36410S Primary blast injury of duodenum, sequela',
  'S36418A Primary blast injury of other part of small intestine, initial encounter',
  'S36418D Primary blast injury of other part of small intestine, subsequent encounter',
  'S36418S Primary blast injury of other part of small intestine, sequela',
  'S36419A Primary blast injury of unspecified part of small intestine, initial encounter',
  'S36419D Primary blast injury of unspecified part of small intestine, subsequent encounter',
  'S36419S Primary blast injury of unspecified part of small intestine, sequela',
  'S36420A Contusion of duodenum, initial encounter',
  'S36420D Contusion of duodenum, subsequent encounter',
  'S36420S Contusion of duodenum, sequela',
  'S36428A Contusion of other part of small intestine, initial encounter',
  'S36428D Contusion of other part of small intestine, subsequent encounter',
  'S36428S Contusion of other part of small intestine, sequela',
  'S36429A Contusion of unspecified part of small intestine, initial encounter',
  'S36429D Contusion of unspecified part of small intestine, subsequent encounter',
  'S36429S Contusion of unspecified part of small intestine, sequela',
  'S36430A Laceration of duodenum, initial encounter',
  'S36430D Laceration of duodenum, subsequent encounter',
  'S36430S Laceration of duodenum, sequela',
  'S36438A Laceration of other part of small intestine, initial encounter',
  'S36438D Laceration of other part of small intestine, subsequent encounter',
  'S36438S Laceration of other part of small intestine, sequela',
  'S36439A Laceration of unspecified part of small intestine, initial encounter',
  'S36439D Laceration of unspecified part of small intestine, subsequent encounter',
  'S36439S Laceration of unspecified part of small intestine, sequela',
  'S36490A Other injury of duodenum, initial encounter',
  'S36490D Other injury of duodenum, subsequent encounter',
  'S36490S Other injury of duodenum, sequela',
  'S36498A Other injury of other part of small intestine, initial encounter',
  'S36498D Other injury of other part of small intestine, subsequent encounter',
  'S36498S Other injury of other part of small intestine, sequela',
  'S36499A Other injury of unspecified part of small intestine, initial encounter',
  'S36499D Other injury of unspecified part of small intestine, subsequent encounter',
  'S36499S Other injury of unspecified part of small intestine, sequela',
  'S36500A Unspecified injury of ascending [right] colon, initial encounter',
  'S36500D Unspecified injury of ascending [right] colon, subsequent encounter',
  'S36500S Unspecified injury of ascending [right] colon, sequela',
  'S36501A Unspecified injury of transverse colon, initial encounter',
  'S36501D Unspecified injury of transverse colon, subsequent encounter',
  'S36501S Unspecified injury of transverse colon, sequela',
  'S36502A Unspecified injury of descending [left] colon, initial encounter',
  'S36502D Unspecified injury of descending [left] colon, subsequent encounter',
  'S36502S Unspecified injury of descending [left] colon, sequela',
  'S36503A Unspecified injury of sigmoid colon, initial encounter',
  'S36503D Unspecified injury of sigmoid colon, subsequent encounter',
  'S36503S Unspecified injury of sigmoid colon, sequela',
  'S36508A Unspecified injury of other part of colon, initial encounter',
  'S36508D Unspecified injury of other part of colon, subsequent encounter',
  'S36508S Unspecified injury of other part of colon, sequela',
  'S36509A Unspecified injury of unspecified part of colon, initial encounter',
  'S36509D Unspecified injury of unspecified part of colon, subsequent encounter',
  'S36509S Unspecified injury of unspecified part of colon, sequela',
  'S36510A Primary blast injury of ascending [right] colon, initial encounter',
  'S36510D Primary blast injury of ascending [right] colon, subsequent encounter',
  'S36510S Primary blast injury of ascending [right] colon, sequela',
  'S36511A Primary blast injury of transverse colon, initial encounter',
  'S36511D Primary blast injury of transverse colon, subsequent encounter',
  'S36511S Primary blast injury of transverse colon, sequela',
  'S36512A Primary blast injury of descending [left] colon, initial encounter',
  'S36512D Primary blast injury of descending [left] colon, subsequent encounter',
  'S36512S Primary blast injury of descending [left] colon, sequela',
  'S36513A Primary blast injury of sigmoid colon, initial encounter',
  'S36513D Primary blast injury of sigmoid colon, subsequent encounter',
  'S36513S Primary blast injury of sigmoid colon, sequela',
  'S36518A Primary blast injury of other part of colon, initial encounter',
  'S36518D Primary blast injury of other part of colon, subsequent encounter',
  'S36518S Primary blast injury of other part of colon, sequela',
  'S36519A Primary blast injury of unspecified part of colon, initial encounter',
  'S36519D Primary blast injury of unspecified part of colon, subsequent encounter',
  'S36519S Primary blast injury of unspecified part of colon, sequela',
  'S36520A Contusion of ascending [right] colon, initial encounter',
  'S36520D Contusion of ascending [right] colon, subsequent encounter',
  'S36520S Contusion of ascending [right] colon, sequela',
  'S36521A Contusion of transverse colon, initial encounter',
  'S36521D Contusion of transverse colon, subsequent encounter',
  'S36521S Contusion of transverse colon, sequela',
  'S36522A Contusion of descending [left] colon, initial encounter',
  'S36522D Contusion of descending [left] colon, subsequent encounter',
  'S36522S Contusion of descending [left] colon, sequela',
  'S36523A Contusion of sigmoid colon, initial encounter',
  'S36523D Contusion of sigmoid colon, subsequent encounter',
  'S36523S Contusion of sigmoid colon, sequela',
  'S36528A Contusion of other part of colon, initial encounter',
  'S36528D Contusion of other part of colon, subsequent encounter',
  'S36528S Contusion of other part of colon, sequela',
  'S36529A Contusion of unspecified part of colon, initial encounter',
  'S36529D Contusion of unspecified part of colon, subsequent encounter',
  'S36529S Contusion of unspecified part of colon, sequela',
  'S36530A Laceration of ascending [right] colon, initial encounter',
  'S36530D Laceration of ascending [right] colon, subsequent encounter',
  'S36530S Laceration of ascending [right] colon, sequela',
  'S36531A Laceration of transverse colon, initial encounter',
  'S36531D Laceration of transverse colon, subsequent encounter',
  'S36531S Laceration of transverse colon, sequela',
  'S36532A Laceration of descending [left] colon, initial encounter',
  'S36532D Laceration of descending [left] colon, subsequent encounter',
  'S36532S Laceration of descending [left] colon, sequela',
  'S36533A Laceration of sigmoid colon, initial encounter',
  'S36533D Laceration of sigmoid colon, subsequent encounter',
  'S36533S Laceration of sigmoid colon, sequela',
  'S36538A Laceration of other part of colon, initial encounter',
  'S36538D Laceration of other part of colon, subsequent encounter',
  'S36538S Laceration of other part of colon, sequela',
  'S36539A Laceration of unspecified part of colon, initial encounter',
  'S36539D Laceration of unspecified part of colon, subsequent encounter',
  'S36539S Laceration of unspecified part of colon, sequela',
  'S36590A Other injury of ascending [right] colon, initial encounter',
  'S36590D Other injury of ascending [right] colon, subsequent encounter',
  'S36590S Other injury of ascending [right] colon, sequela',
  'S36591A Other injury of transverse colon, initial encounter',
  'S36591D Other injury of transverse colon, subsequent encounter',
  'S36591S Other injury of transverse colon, sequela',
  'S36592A Other injury of descending [left] colon, initial encounter',
  'S36592D Other injury of descending [left] colon, subsequent encounter',
  'S36592S Other injury of descending [left] colon, sequela',
  'S36593A Other injury of sigmoid colon, initial encounter',
  'S36593D Other injury of sigmoid colon, subsequent encounter',
  'S36593S Other injury of sigmoid colon, sequela',
  'S36598A Other injury of other part of colon, initial encounter',
  'S36598D Other injury of other part of colon, subsequent encounter',
  'S36598S Other injury of other part of colon, sequela',
  'S36599A Other injury of unspecified part of colon, initial encounter',
  'S36599D Other injury of unspecified part of colon, subsequent encounter',
  'S36599S Other injury of unspecified part of colon, sequela',
  'S3660XA Unspecified injury of rectum, initial encounter',
  'S3660XD Unspecified injury of rectum, subsequent encounter',
  'S3660XS Unspecified injury of rectum, sequela',
  'S3661XA Primary blast injury of rectum, initial encounter',
  'S3661XD Primary blast injury of rectum, subsequent encounter',
  'S3661XS Primary blast injury of rectum, sequela',
  'S3662XA Contusion of rectum, initial encounter',
  'S3662XD Contusion of rectum, subsequent encounter',
  'S3662XS Contusion of rectum, sequela',
  'S3663XA Laceration of rectum, initial encounter',
  'S3663XD Laceration of rectum, subsequent encounter',
  'S3663XS Laceration of rectum, sequela',
  'S3669XA Other injury of rectum, initial encounter',
  'S3669XD Other injury of rectum, subsequent encounter',
  'S3669XS Other injury of rectum, sequela',
  'S3681XA Injury of peritoneum, initial encounter',
  'S3681XD Injury of peritoneum, subsequent encounter',
  'S3681XS Injury of peritoneum, sequela',
  'S36892A Contusion of other intra-abdominal organs, initial encounter',
  'S36892D Contusion of other intra-abdominal organs, subsequent encounter',
  'S36892S Contusion of other intra-abdominal organs, sequela',
  'S36893A Laceration of other intra-abdominal organs, initial encounter',
  'S36893D Laceration of other intra-abdominal organs, subsequent encounter',
  'S36893S Laceration of other intra-abdominal organs, sequela',
  'S36898A Other injury of other intra-abdominal organs, initial encounter',
  'S36898D Other injury of other intra-abdominal organs, subsequent encounter',
  'S36898S Other injury of other intra-abdominal organs, sequela',
  'S36899A Unspecified injury of other intra-abdominal organs, initial encounter',
  'S36899D Unspecified injury of other intra-abdominal organs, subsequent encounter',
  'S36899S Unspecified injury of other intra-abdominal organs, sequela',
  'S3690XA Unspecified injury of unspecified intra-abdominal organ, initial encounter',
  'S3690XD Unspecified injury of unspecified intra-abdominal organ, subsequent encounter',
  'S3690XS Unspecified injury of unspecified intra-abdominal organ, sequela',
  'S3692XA Contusion of unspecified intra-abdominal organ, initial encounter',
  'S3692XD Contusion of unspecified intra-abdominal organ, subsequent encounter',
  'S3692XS Contusion of unspecified intra-abdominal organ, sequela',
  'S3693XA Laceration of unspecified intra-abdominal organ, initial encounter',
  'S3693XD Laceration of unspecified intra-abdominal organ, subsequent encounter',
  'S3693XS Laceration of unspecified intra-abdominal organ, sequela',
  'S3699XA Other injury of unspecified intra-abdominal organ, initial encounter',
  'S3699XD Other injury of unspecified intra-abdominal organ, subsequent encounter',
  'S3699XS Other injury of unspecified intra-abdominal organ, sequela',
  'S37001A Unspecified injury of right kidney, initial encounter',
  'S37001D Unspecified injury of right kidney, subsequent encounter',
  'S37001S Unspecified injury of right kidney, sequela',
  'S37002A Unspecified injury of left kidney, initial encounter',
  'S37002D Unspecified injury of left kidney, subsequent encounter',
  'S37002S Unspecified injury of left kidney, sequela',
  'S37009A Unspecified injury of unspecified kidney, initial encounter',
  'S37009D Unspecified injury of unspecified kidney, subsequent encounter',
  'S37009S Unspecified injury of unspecified kidney, sequela',
  'S37011A Minor contusion of right kidney, initial encounter',
  'S37011D Minor contusion of right kidney, subsequent encounter',
  'S37011S Minor contusion of right kidney, sequela',
  'S37012A Minor contusion of left kidney, initial encounter',
  'S37012D Minor contusion of left kidney, subsequent encounter',
  'S37012S Minor contusion of left kidney, sequela',
  'S37019A Minor contusion of unspecified kidney, initial encounter',
  'S37019D Minor contusion of unspecified kidney, subsequent encounter',
  'S37019S Minor contusion of unspecified kidney, sequela',
  'S37021A Major contusion of right kidney, initial encounter',
  'S37021D Major contusion of right kidney, subsequent encounter',
  'S37021S Major contusion of right kidney, sequela',
  'S37022A Major contusion of left kidney, initial encounter',
  'S37022D Major contusion of left kidney, subsequent encounter',
  'S37022S Major contusion of left kidney, sequela',
  'S37029A Major contusion of unspecified kidney, initial encounter',
  'S37029D Major contusion of unspecified kidney, subsequent encounter',
  'S37029S Major contusion of unspecified kidney, sequela',
  'S37031A Laceration of right kidney, unspecified degree, initial encounter',
  'S37031D Laceration of right kidney, unspecified degree, subsequent encounter',
  'S37031S Laceration of right kidney, unspecified degree, sequela',
  'S37032A Laceration of left kidney, unspecified degree, initial encounter',
  'S37032D Laceration of left kidney, unspecified degree, subsequent encounter',
  'S37032S Laceration of left kidney, unspecified degree, sequela',
  'S37039A Laceration of unspecified kidney, unspecified degree, initial encounter',
  'S37039D Laceration of unspecified kidney, unspecified degree, subsequent encounter',
  'S37039S Laceration of unspecified kidney, unspecified degree, sequela',
  'S37041A Minor laceration of right kidney, initial encounter',
  'S37041D Minor laceration of right kidney, subsequent encounter',
  'S37041S Minor laceration of right kidney, sequela',
  'S37042A Minor laceration of left kidney, initial encounter',
  'S37042D Minor laceration of left kidney, subsequent encounter',
  'S37042S Minor laceration of left kidney, sequela',
  'S37049A Minor laceration of unspecified kidney, initial encounter',
  'S37049D Minor laceration of unspecified kidney, subsequent encounter',
  'S37049S Minor laceration of unspecified kidney, sequela',
  'S37051A Moderate laceration of right kidney, initial encounter',
  'S37051D Moderate laceration of right kidney, subsequent encounter',
  'S37051S Moderate laceration of right kidney, sequela',
  'S37052A Moderate laceration of left kidney, initial encounter',
  'S37052D Moderate laceration of left kidney, subsequent encounter',
  'S37052S Moderate laceration of left kidney, sequela',
  'S37059A Moderate laceration of unspecified kidney, initial encounter',
  'S37059D Moderate laceration of unspecified kidney, subsequent encounter',
  'S37059S Moderate laceration of unspecified kidney, sequela',
  'S37061A Major laceration of right kidney, initial encounter',
  'S37061D Major laceration of right kidney, subsequent encounter',
  'S37061S Major laceration of right kidney, sequela',
  'S37062A Major laceration of left kidney, initial encounter',
  'S37062D Major laceration of left kidney, subsequent encounter',
  'S37062S Major laceration of left kidney, sequela',
  'S37069A Major laceration of unspecified kidney, initial encounter',
  'S37069D Major laceration of unspecified kidney, subsequent encounter',
  'S37069S Major laceration of unspecified kidney, sequela',
  'S37091A Other injury of right kidney, initial encounter',
  'S37091D Other injury of right kidney, subsequent encounter',
  'S37091S Other injury of right kidney, sequela',
  'S37092A Other injury of left kidney, initial encounter',
  'S37092D Other injury of left kidney, subsequent encounter',
  'S37092S Other injury of left kidney, sequela',
  'S37099A Other injury of unspecified kidney, initial encounter',
  'S37099D Other injury of unspecified kidney, subsequent encounter',
  'S37099S Other injury of unspecified kidney, sequela',
  'S3710XA Unspecified injury of ureter, initial encounter',
  'S3710XD Unspecified injury of ureter, subsequent encounter',
  'S3710XS Unspecified injury of ureter, sequela',
  'S3712XA Contusion of ureter, initial encounter',
  'S3712XD Contusion of ureter, subsequent encounter',
  'S3712XS Contusion of ureter, sequela',
  'S3713XA Laceration of ureter, initial encounter',
  'S3713XD Laceration of ureter, subsequent encounter',
  'S3713XS Laceration of ureter, sequela',
  'S3719XA Other injury of ureter, initial encounter',
  'S3719XD Other injury of ureter, subsequent encounter',
  'S3719XS Other injury of ureter, sequela',
  'S3720XA Unspecified injury of bladder, initial encounter',
  'S3720XD Unspecified injury of bladder, subsequent encounter',
  'S3720XS Unspecified injury of bladder, sequela',
  'S3722XA Contusion of bladder, initial encounter',
  'S3722XD Contusion of bladder, subsequent encounter',
  'S3722XS Contusion of bladder, sequela',
  'S3723XA Laceration of bladder, initial encounter',
  'S3723XD Laceration of bladder, subsequent encounter',
  'S3723XS Laceration of bladder, sequela',
  'S3729XA Other injury of bladder, initial encounter',
  'S3729XD Other injury of bladder, subsequent encounter',
  'S3729XS Other injury of bladder, sequela',
  'S3730XA Unspecified injury of urethra, initial encounter',
  'S3730XD Unspecified injury of urethra, subsequent encounter',
  'S3730XS Unspecified injury of urethra, sequela',
  'S3732XA Contusion of urethra, initial encounter',
  'S3732XD Contusion of urethra, subsequent encounter',
  'S3732XS Contusion of urethra, sequela',
  'S3733XA Laceration of urethra, initial encounter',
  'S3733XD Laceration of urethra, subsequent encounter',
  'S3733XS Laceration of urethra, sequela',
  'S3739XA Other injury of urethra, initial encounter',
  'S3739XD Other injury of urethra, subsequent encounter',
  'S3739XS Other injury of urethra, sequela',
  'S37401A Unspecified injury of ovary, unilateral, initial encounter',
  'S37401D Unspecified injury of ovary, unilateral, subsequent encounter',
  'S37401S Unspecified injury of ovary, unilateral, sequela',
  'S37402A Unspecified injury of ovary, bilateral, initial encounter',
  'S37402D Unspecified injury of ovary, bilateral, subsequent encounter',
  'S37402S Unspecified injury of ovary, bilateral, sequela',
  'S37409A Unspecified injury of ovary, unspecified, initial encounter',
  'S37409D Unspecified injury of ovary, unspecified, subsequent encounter',
  'S37409S Unspecified injury of ovary, unspecified, sequela',
  'S37421A Contusion of ovary, unilateral, initial encounter',
  'S37421D Contusion of ovary, unilateral, subsequent encounter',
  'S37421S Contusion of ovary, unilateral, sequela',
  'S37422A Contusion of ovary, bilateral, initial encounter',
  'S37422D Contusion of ovary, bilateral, subsequent encounter',
  'S37422S Contusion of ovary, bilateral, sequela',
  'S37429A Contusion of ovary, unspecified, initial encounter',
  'S37429D Contusion of ovary, unspecified, subsequent encounter',
  'S37429S Contusion of ovary, unspecified, sequela',
  'S37431A Laceration of ovary, unilateral, initial encounter',
  'S37431D Laceration of ovary, unilateral, subsequent encounter',
  'S37431S Laceration of ovary, unilateral, sequela',
  'S37432A Laceration of ovary, bilateral, initial encounter',
  'S37432D Laceration of ovary, bilateral, subsequent encounter',
  'S37432S Laceration of ovary, bilateral, sequela',
  'S37439A Laceration of ovary, unspecified, initial encounter',
  'S37439D Laceration of ovary, unspecified, subsequent encounter',
  'S37439S Laceration of ovary, unspecified, sequela',
  'S37491A Other injury of ovary, unilateral, initial encounter',
  'S37491D Other injury of ovary, unilateral, subsequent encounter',
  'S37491S Other injury of ovary, unilateral, sequela',
  'S37492A Other injury of ovary, bilateral, initial encounter',
  'S37492D Other injury of ovary, bilateral, subsequent encounter',
  'S37492S Other injury of ovary, bilateral, sequela',
  'S37499A Other injury of ovary, unspecified, initial encounter',
  'S37499D Other injury of ovary, unspecified, subsequent encounter',
  'S37499S Other injury of ovary, unspecified, sequela',
  'S37501A Unspecified injury of fallopian tube, unilateral, initial encounter',
  'S37501D Unspecified injury of fallopian tube, unilateral, subsequent encounter',
  'S37501S Unspecified injury of fallopian tube, unilateral, sequela',
  'S37502A Unspecified injury of fallopian tube, bilateral, initial encounter',
  'S37502D Unspecified injury of fallopian tube, bilateral, subsequent encounter',
  'S37502S Unspecified injury of fallopian tube, bilateral, sequela',
  'S37509A Unspecified injury of fallopian tube, unspecified, initial encounter',
  'S37509D Unspecified injury of fallopian tube, unspecified, subsequent encounter',
  'S37509S Unspecified injury of fallopian tube, unspecified, sequela',
  'S37511A Primary blast injury of fallopian tube, unilateral, initial encounter',
  'S37511D Primary blast injury of fallopian tube, unilateral, subsequent encounter',
  'S37511S Primary blast injury of fallopian tube, unilateral, sequela',
  'S37512A Primary blast injury of fallopian tube, bilateral, initial encounter',
  'S37512D Primary blast injury of fallopian tube, bilateral, subsequent encounter',
  'S37512S Primary blast injury of fallopian tube, bilateral, sequela',
  'S37519A Primary blast injury of fallopian tube, unspecified, initial encounter',
  'S37519D Primary blast injury of fallopian tube, unspecified, subsequent encounter',
  'S37519S Primary blast injury of fallopian tube, unspecified, sequela',
  'S37521A Contusion of fallopian tube, unilateral, initial encounter',
  'S37521D Contusion of fallopian tube, unilateral, subsequent encounter',
  'S37521S Contusion of fallopian tube, unilateral, sequela',
  'S37522A Contusion of fallopian tube, bilateral, initial encounter',
  'S37522D Contusion of fallopian tube, bilateral, subsequent encounter',
  'S37522S Contusion of fallopian tube, bilateral, sequela',
  'S37529A Contusion of fallopian tube, unspecified, initial encounter',
  'S37529D Contusion of fallopian tube, unspecified, subsequent encounter',
  'S37529S Contusion of fallopian tube, unspecified, sequela',
  'S37531A Laceration of fallopian tube, unilateral, initial encounter',
  'S37531D Laceration of fallopian tube, unilateral, subsequent encounter',
  'S37531S Laceration of fallopian tube, unilateral, sequela',
  'S37532A Laceration of fallopian tube, bilateral, initial encounter',
  'S37532D Laceration of fallopian tube, bilateral, subsequent encounter',
  'S37532S Laceration of fallopian tube, bilateral, sequela',
  'S37539A Laceration of fallopian tube, unspecified, initial encounter',
  'S37539D Laceration of fallopian tube, unspecified, subsequent encounter',
  'S37539S Laceration of fallopian tube, unspecified, sequela',
  'S37591A Other injury of fallopian tube, unilateral, initial encounter',
  'S37591D Other injury of fallopian tube, unilateral, subsequent encounter',
  'S37591S Other injury of fallopian tube, unilateral, sequela',
  'S37592A Other injury of fallopian tube, bilateral, initial encounter',
  'S37592D Other injury of fallopian tube, bilateral, subsequent encounter',
  'S37592S Other injury of fallopian tube, bilateral, sequela',
  'S37599A Other injury of fallopian tube, unspecified, initial encounter',
  'S37599D Other injury of fallopian tube, unspecified, subsequent encounter',
  'S37599S Other injury of fallopian tube, unspecified, sequela',
  'S3760XA Unspecified injury of uterus, initial encounter',
  'S3760XD Unspecified injury of uterus, subsequent encounter',
  'S3760XS Unspecified injury of uterus, sequela',
  'S3762XA Contusion of uterus, initial encounter',
  'S3762XD Contusion of uterus, subsequent encounter',
  'S3762XS Contusion of uterus, sequela',
  'S3763XA Laceration of uterus, initial encounter',
  'S3763XD Laceration of uterus, subsequent encounter',
  'S3763XS Laceration of uterus, sequela',
  'S3769XA Other injury of uterus, initial encounter',
  'S3769XD Other injury of uterus, subsequent encounter',
  'S3769XS Other injury of uterus, sequela',
  'S37812A Contusion of adrenal gland, initial encounter',
  'S37812D Contusion of adrenal gland, subsequent encounter',
  'S37812S Contusion of adrenal gland, sequela',
  'S37813A Laceration of adrenal gland, initial encounter',
  'S37813D Laceration of adrenal gland, subsequent encounter',
  'S37813S Laceration of adrenal gland, sequela',
  'S37818A Other injury of adrenal gland, initial encounter',
  'S37818D Other injury of adrenal gland, subsequent encounter',
  'S37818S Other injury of adrenal gland, sequela',
  'S37819A Unspecified injury of adrenal gland, initial encounter',
  'S37819D Unspecified injury of adrenal gland, subsequent encounter',
  'S37819S Unspecified injury of adrenal gland, sequela',
  'S37822A Contusion of prostate, initial encounter',
  'S37822D Contusion of prostate, subsequent encounter',
  'S37822S Contusion of prostate, sequela',
  'S37823A Laceration of prostate, initial encounter',
  'S37823D Laceration of prostate, subsequent encounter',
  'S37823S Laceration of prostate, sequela',
  'S37828A Other injury of prostate, initial encounter',
  'S37828D Other injury of prostate, subsequent encounter',
  'S37828S Other injury of prostate, sequela',
  'S37829A Unspecified injury of prostate, initial encounter',
  'S37829D Unspecified injury of prostate, subsequent encounter',
  'S37829S Unspecified injury of prostate, sequela',
  'S37892A Contusion of other urinary and pelvic organ, initial encounter',
  'S37892D Contusion of other urinary and pelvic organ, subsequent encounter',
  'S37892S Contusion of other urinary and pelvic organ, sequela',
  'S37893A Laceration of other urinary and pelvic organ, initial encounter',
  'S37893D Laceration of other urinary and pelvic organ, subsequent encounter',
  'S37893S Laceration of other urinary and pelvic organ, sequela',
  'S37898A Other injury of other urinary and pelvic organ, initial encounter',
  'S37898D Other injury of other urinary and pelvic organ, subsequent encounter',
  'S37898S Other injury of other urinary and pelvic organ, sequela',
  'S37899A Unspecified injury of other urinary and pelvic organ, initial encounter',
  'S37899D Unspecified injury of other urinary and pelvic organ, subsequent encounter',
  'S37899S Unspecified injury of other urinary and pelvic organ, sequela',
  'S3790XA Unspecified injury of unspecified urinary and pelvic organ, initial encounter',
  'S3790XD Unspecified injury of unspecified urinary and pelvic organ, subsequent encounter',
  'S3790XS Unspecified injury of unspecified urinary and pelvic organ, sequela',
  'S3792XA Contusion of unspecified urinary and pelvic organ, initial encounter',
  'S3792XD Contusion of unspecified urinary and pelvic organ, subsequent encounter',
  'S3792XS Contusion of unspecified urinary and pelvic organ, sequela',
  'S3793XA Laceration of unspecified urinary and pelvic organ, initial encounter',
  'S3793XD Laceration of unspecified urinary and pelvic organ, subsequent encounter',
  'S3793XS Laceration of unspecified urinary and pelvic organ, sequela',
  'S3799XA Other injury of unspecified urinary and pelvic organ, initial encounter',
  'S3799XD Other injury of unspecified urinary and pelvic organ, subsequent encounter',
  'S3799XS Other injury of unspecified urinary and pelvic organ, sequela',
  'S38001A Crushing injury of unspecified external genital organs, male, initial encounter',
  'S38001D Crushing injury of unspecified external genital organs, male, subsequent encounter',
  'S38001S Crushing injury of unspecified external genital organs, male, sequela',
  'S38002A Crushing injury of unspecified external genital organs, female, initial encounter',
  'S38002D Crushing injury of unspecified external genital organs, female, subsequent encounter',
  'S38002S Crushing injury of unspecified external genital organs, female, sequela',
  'S3801XA Crushing injury of penis, initial encounter',
  'S3801XD Crushing injury of penis, subsequent encounter',
  'S3801XS Crushing injury of penis, sequela',
  'S3802XA Crushing injury of scrotum and testis, initial encounter',
  'S3802XD Crushing injury of scrotum and testis, subsequent encounter',
  'S3802XS Crushing injury of scrotum and testis, sequela',
  'S3803XA Crushing injury of vulva, initial encounter',
  'S3803XD Crushing injury of vulva, subsequent encounter',
  'S3803XS Crushing injury of vulva, sequela',
  'S381XXA Crushing injury of abdomen, lower back, and pelvis, initial encounter',
  'S381XXD Crushing injury of abdomen, lower back, and pelvis, subsequent encounter',
  'S381XXS Crushing injury of abdomen, lower back, and pelvis, sequela',
  'S38211A Complete traumatic amputation of female external genital organs, initial encounter',
  'S38211D Complete traumatic amputation of female external genital organs, subsequent encounter',
  'S38211S Complete traumatic amputation of female external genital organs, sequela',
  'S38212A Partial traumatic amputation of female external genital organs, initial encounter',
  'S38212D Partial traumatic amputation of female external genital organs, subsequent encounter',
  'S38212S Partial traumatic amputation of female external genital organs, sequela',
  'S38221A Complete traumatic amputation of penis, initial encounter',
  'S38221D Complete traumatic amputation of penis, subsequent encounter',
  'S38221S Complete traumatic amputation of penis, sequela',
  'S38222A Partial traumatic amputation of penis, initial encounter',
  'S38222D Partial traumatic amputation of penis, subsequent encounter',
  'S38222S Partial traumatic amputation of penis, sequela',
  'S38231A Complete traumatic amputation of scrotum and testis, initial encounter',
  'S38231D Complete traumatic amputation of scrotum and testis, subsequent encounter',
  'S38231S Complete traumatic amputation of scrotum and testis, sequela',
  'S38232A Partial traumatic amputation of scrotum and testis, initial encounter',
  'S38232D Partial traumatic amputation of scrotum and testis, subsequent encounter',
  'S38232S Partial traumatic amputation of scrotum and testis, sequela',
  'S383XXA Transection (partial) of abdomen, initial encounter',
  'S383XXD Transection (partial) of abdomen, subsequent encounter',
  'S383XXS Transection (partial) of abdomen, sequela',
  'S39001A Unspecified injury of muscle, fascia and tendon of abdomen, initial encounter',
  'S39001D Unspecified injury of muscle, fascia and tendon of abdomen, subsequent encounter',
  'S39001S Unspecified injury of muscle, fascia and tendon of abdomen, sequela',
  'S39002A Unspecified injury of muscle, fascia and tendon of lower back, initial encounter',
  'S39002D Unspecified injury of muscle, fascia and tendon of lower back, subsequent encounter',
  'S39002S Unspecified injury of muscle, fascia and tendon of lower back, sequela',
  'S39003A Unspecified injury of muscle, fascia and tendon of pelvis, initial encounter',
  'S39003D Unspecified injury of muscle, fascia and tendon of pelvis, subsequent encounter',
  'S39003S Unspecified injury of muscle, fascia and tendon of pelvis, sequela',
  'S39011A Strain of muscle, fascia and tendon of abdomen, initial encounter',
  'S39011D Strain of muscle, fascia and tendon of abdomen, subsequent encounter',
  'S39011S Strain of muscle, fascia and tendon of abdomen, sequela',
  'S39012A Strain of muscle, fascia and tendon of lower back, initial encounter',
  'S39012D Strain of muscle, fascia and tendon of lower back, subsequent encounter',
  'S39012S Strain of muscle, fascia and tendon of lower back, sequela',
  'S39013A Strain of muscle, fascia and tendon of pelvis, initial encounter',
  'S39013D Strain of muscle, fascia and tendon of pelvis, subsequent encounter',
  'S39013S Strain of muscle, fascia and tendon of pelvis, sequela',
  'S39021A Laceration of muscle, fascia and tendon of abdomen, initial encounter',
  'S39021D Laceration of muscle, fascia and tendon of abdomen, subsequent encounter',
  'S39021S Laceration of muscle, fascia and tendon of abdomen, sequela',
  'S39022A Laceration of muscle, fascia and tendon of lower back, initial encounter',
  'S39022D Laceration of muscle, fascia and tendon of lower back, subsequent encounter',
  'S39022S Laceration of muscle, fascia and tendon of lower back, sequela',
  'S39023A Laceration of muscle, fascia and tendon of pelvis, initial encounter',
  'S39023D Laceration of muscle, fascia and tendon of pelvis, subsequent encounter',
  'S39023S Laceration of muscle, fascia and tendon of pelvis, sequela',
  'S39091A Other injury of muscle, fascia and tendon of abdomen, initial encounter',
  'S39091D Other injury of muscle, fascia and tendon of abdomen, subsequent encounter',
  'S39091S Other injury of muscle, fascia and tendon of abdomen, sequela',
  'S39092A Other injury of muscle, fascia and tendon of lower back, initial encounter',
  'S39092D Other injury of muscle, fascia and tendon of lower back, subsequent encounter',
  'S39092S Other injury of muscle, fascia and tendon of lower back, sequela',
  'S39093A Other injury of muscle, fascia and tendon of pelvis, initial encounter',
  'S39093D Other injury of muscle, fascia and tendon of pelvis, subsequent encounter',
  'S39093S Other injury of muscle, fascia and tendon of pelvis, sequela',
  'S3981XA Other specified injuries of abdomen, initial encounter',
  'S3981XD Other specified injuries of abdomen, subsequent encounter',
  'S3981XS Other specified injuries of abdomen, sequela',
  'S3982XA Other specified injuries of lower back, initial encounter',
  'S3982XD Other specified injuries of lower back, subsequent encounter',
  'S3982XS Other specified injuries of lower back, sequela',
  'S3983XA Other specified injuries of pelvis, initial encounter',
  'S3983XD Other specified injuries of pelvis, subsequent encounter',
  'S3983XS Other specified injuries of pelvis, sequela',
  'S39840A Fracture of corpus cavernosum penis, initial encounter',
  'S39840D Fracture of corpus cavernosum penis, subsequent encounter',
  'S39840S Fracture of corpus cavernosum penis, sequela',
  'S39848A Other specified injuries of external genitals, initial encounter',
  'S39848D Other specified injuries of external genitals, subsequent encounter',
  'S39848S Other specified injuries of external genitals, sequela',
  'S3991XA Unspecified injury of abdomen, initial encounter',
  'S3991XD Unspecified injury of abdomen, subsequent encounter',
  'S3991XS Unspecified injury of abdomen, sequela',
  'S3992XA Unspecified injury of lower back, initial encounter',
  'S3992XD Unspecified injury of lower back, subsequent encounter',
  'S3992XS Unspecified injury of lower back, sequela',
  'S3993XA Unspecified injury of pelvis, initial encounter',
  'S3993XD Unspecified injury of pelvis, subsequent encounter',
  'S3993XS Unspecified injury of pelvis, sequela',
  'S3994XA Unspecified injury of external genitals, initial encounter',
  'S3994XD Unspecified injury of external genitals, subsequent encounter',
  'S3994XS Unspecified injury of external genitals, sequela',
  'S40011A Contusion of right shoulder, initial encounter',
  'S40011D Contusion of right shoulder, subsequent encounter',
  'S40011S Contusion of right shoulder, sequela',
  'S40012A Contusion of left shoulder, initial encounter',
  'S40012D Contusion of left shoulder, subsequent encounter',
  'S40012S Contusion of left shoulder, sequela',
  'S40019A Contusion of unspecified shoulder, initial encounter',
  'S40019D Contusion of unspecified shoulder, subsequent encounter',
  'S40019S Contusion of unspecified shoulder, sequela',
  'S40021A Contusion of right upper arm, initial encounter',
  'S40021D Contusion of right upper arm, subsequent encounter',
  'S40021S Contusion of right upper arm, sequela',
  'S40022A Contusion of left upper arm, initial encounter',
  'S40022D Contusion of left upper arm, subsequent encounter',
  'S40022S Contusion of left upper arm, sequela',
  'S40029A Contusion of unspecified upper arm, initial encounter',
  'S40029D Contusion of unspecified upper arm, subsequent encounter',
  'S40029S Contusion of unspecified upper arm, sequela',
  'S40211A Abrasion of right shoulder, initial encounter',
  'S40211D Abrasion of right shoulder, subsequent encounter',
  'S40211S Abrasion of right shoulder, sequela',
  'S40212A Abrasion of left shoulder, initial encounter',
  'S40212D Abrasion of left shoulder, subsequent encounter',
  'S40212S Abrasion of left shoulder, sequela',
  'S40219A Abrasion of unspecified shoulder, initial encounter',
  'S40219D Abrasion of unspecified shoulder, subsequent encounter',
  'S40219S Abrasion of unspecified shoulder, sequela',
  'S40221A Blister (nonthermal) of right shoulder, initial encounter',
  'S40221D Blister (nonthermal) of right shoulder, subsequent encounter',
  'S40221S Blister (nonthermal) of right shoulder, sequela',
  'S40222A Blister (nonthermal) of left shoulder, initial encounter',
  'S40222D Blister (nonthermal) of left shoulder, subsequent encounter',
  'S40222S Blister (nonthermal) of left shoulder, sequela',
  'S40229A Blister (nonthermal) of unspecified shoulder, initial encounter',
  'S40229D Blister (nonthermal) of unspecified shoulder, subsequent encounter',
  'S40229S Blister (nonthermal) of unspecified shoulder, sequela',
  'S40241A External constriction of right shoulder, initial encounter',
  'S40241D External constriction of right shoulder, subsequent encounter',
  'S40241S External constriction of right shoulder, sequela',
  'S40242A External constriction of left shoulder, initial encounter',
  'S40242D External constriction of left shoulder, subsequent encounter',
  'S40242S External constriction of left shoulder, sequela',
  'S40249A External constriction of unspecified shoulder, initial encounter',
  'S40249D External constriction of unspecified shoulder, subsequent encounter',
  'S40249S External constriction of unspecified shoulder, sequela',
  'S40251A Superficial foreign body of right shoulder, initial encounter',
  'S40251D Superficial foreign body of right shoulder, subsequent encounter',
  'S40251S Superficial foreign body of right shoulder, sequela',
  'S40252A Superficial foreign body of left shoulder, initial encounter',
  'S40252D Superficial foreign body of left shoulder, subsequent encounter',
  'S40252S Superficial foreign body of left shoulder, sequela',
  'S40259A Superficial foreign body of unspecified shoulder, initial encounter',
  'S40259D Superficial foreign body of unspecified shoulder, subsequent encounter',
  'S40259S Superficial foreign body of unspecified shoulder, sequela',
  'S40261A Insect bite (nonvenomous) of right shoulder, initial encounter',
  'S40261D Insect bite (nonvenomous) of right shoulder, subsequent encounter',
  'S40261S Insect bite (nonvenomous) of right shoulder, sequela',
  'S40262A Insect bite (nonvenomous) of left shoulder, initial encounter',
  'S40262D Insect bite (nonvenomous) of left shoulder, subsequent encounter',
  'S40262S Insect bite (nonvenomous) of left shoulder, sequela',
  'S40269A Insect bite (nonvenomous) of unspecified shoulder, initial encounter',
  'S40269D Insect bite (nonvenomous) of unspecified shoulder, subsequent encounter',
  'S40269S Insect bite (nonvenomous) of unspecified shoulder, sequela',
  'S40271A Other superficial bite of right shoulder, initial encounter',
  'S40271D Other superficial bite of right shoulder, subsequent encounter',
  'S40271S Other superficial bite of right shoulder, sequela',
  'S40272A Other superficial bite of left shoulder, initial encounter',
  'S40272D Other superficial bite of left shoulder, subsequent encounter',
  'S40272S Other superficial bite of left shoulder, sequela',
  'S40279A Other superficial bite of unspecified shoulder, initial encounter',
  'S40279D Other superficial bite of unspecified shoulder, subsequent encounter',
  'S40279S Other superficial bite of unspecified shoulder, sequela',
  'S40811A Abrasion of right upper arm, initial encounter',
  'S40811D Abrasion of right upper arm, subsequent encounter',
  'S40811S Abrasion of right upper arm, sequela',
  'S40812A Abrasion of left upper arm, initial encounter',
  'S40812D Abrasion of left upper arm, subsequent encounter',
  'S40812S Abrasion of left upper arm, sequela',
  'S40819A Abrasion of unspecified upper arm, initial encounter',
  'S40819D Abrasion of unspecified upper arm, subsequent encounter',
  'S40819S Abrasion of unspecified upper arm, sequela',
  'S40821A Blister (nonthermal) of right upper arm, initial encounter',
  'S40821D Blister (nonthermal) of right upper arm, subsequent encounter',
  'S40821S Blister (nonthermal) of right upper arm, sequela',
  'S40822A Blister (nonthermal) of left upper arm, initial encounter',
  'S40822D Blister (nonthermal) of left upper arm, subsequent encounter',
  'S40822S Blister (nonthermal) of left upper arm, sequela',
  'S40829A Blister (nonthermal) of unspecified upper arm, initial encounter',
  'S40829D Blister (nonthermal) of unspecified upper arm, subsequent encounter',
  'S40829S Blister (nonthermal) of unspecified upper arm, sequela',
  'S40841A External constriction of right upper arm, initial encounter',
  'S40841D External constriction of right upper arm, subsequent encounter',
  'S40841S External constriction of right upper arm, sequela',
  'S40842A External constriction of left upper arm, initial encounter',
  'S40842D External constriction of left upper arm, subsequent encounter',
  'S40842S External constriction of left upper arm, sequela',
  'S40849A External constriction of unspecified upper arm, initial encounter',
  'S40849D External constriction of unspecified upper arm, subsequent encounter',
  'S40849S External constriction of unspecified upper arm, sequela',
  'S40851A Superficial foreign body of right upper arm, initial encounter',
  'S40851D Superficial foreign body of right upper arm, subsequent encounter',
  'S40851S Superficial foreign body of right upper arm, sequela',
  'S40852A Superficial foreign body of left upper arm, initial encounter',
  'S40852D Superficial foreign body of left upper arm, subsequent encounter',
  'S40852S Superficial foreign body of left upper arm, sequela',
  'S40859A Superficial foreign body of unspecified upper arm, initial encounter',
  'S40859D Superficial foreign body of unspecified upper arm, subsequent encounter',
  'S40859S Superficial foreign body of unspecified upper arm, sequela',
  'S40861A Insect bite (nonvenomous) of right upper arm, initial encounter',
  'S40861D Insect bite (nonvenomous) of right upper arm, subsequent encounter',
  'S40861S Insect bite (nonvenomous) of right upper arm, sequela',
  'S40862A Insect bite (nonvenomous) of left upper arm, initial encounter',
  'S40862D Insect bite (nonvenomous) of left upper arm, subsequent encounter',
  'S40862S Insect bite (nonvenomous) of left upper arm, sequela',
  'S40869A Insect bite (nonvenomous) of unspecified upper arm, initial encounter',
  'S40869D Insect bite (nonvenomous) of unspecified upper arm, subsequent encounter',
  'S40869S Insect bite (nonvenomous) of unspecified upper arm, sequela',
  'S40871A Other superficial bite of right upper arm, initial encounter',
  'S40871D Other superficial bite of right upper arm, subsequent encounter',
  'S40871S Other superficial bite of right upper arm, sequela',
  'S40872A Other superficial bite of left upper arm, initial encounter',
  'S40872D Other superficial bite of left upper arm, subsequent encounter',
  'S40872S Other superficial bite of left upper arm, sequela',
  'S40879A Other superficial bite of unspecified upper arm, initial encounter',
  'S40879D Other superficial bite of unspecified upper arm, subsequent encounter',
  'S40879S Other superficial bite of unspecified upper arm, sequela',
  'S40911A Unspecified superficial injury of right shoulder, initial encounter',
  'S40911D Unspecified superficial injury of right shoulder, subsequent encounter',
  'S40911S Unspecified superficial injury of right shoulder, sequela',
  'S40912A Unspecified superficial injury of left shoulder, initial encounter',
  'S40912D Unspecified superficial injury of left shoulder, subsequent encounter',
  'S40912S Unspecified superficial injury of left shoulder, sequela',
  'S40919A Unspecified superficial injury of unspecified shoulder, initial encounter',
  'S40919D Unspecified superficial injury of unspecified shoulder, subsequent encounter',
  'S40919S Unspecified superficial injury of unspecified shoulder, sequela',
  'S40921A Unspecified superficial injury of right upper arm, initial encounter',
  'S40921D Unspecified superficial injury of right upper arm, subsequent encounter',
  'S40921S Unspecified superficial injury of right upper arm, sequela',
  'S40922A Unspecified superficial injury of left upper arm, initial encounter',
  'S40922D Unspecified superficial injury of left upper arm, subsequent encounter',
  'S40922S Unspecified superficial injury of left upper arm, sequela',
  'S40929A Unspecified superficial injury of unspecified upper arm, initial encounter',
  'S40929D Unspecified superficial injury of unspecified upper arm, subsequent encounter',
  'S40929S Unspecified superficial injury of unspecified upper arm, sequela',
  'S41001A Unspecified open wound of right shoulder, initial encounter',
  'S41001D Unspecified open wound of right shoulder, subsequent encounter',
  'S41001S Unspecified open wound of right shoulder, sequela',
  'S41002A Unspecified open wound of left shoulder, initial encounter',
  'S41002D Unspecified open wound of left shoulder, subsequent encounter',
  'S41002S Unspecified open wound of left shoulder, sequela',
  'S41009A Unspecified open wound of unspecified shoulder, initial encounter',
  'S41009D Unspecified open wound of unspecified shoulder, subsequent encounter',
  'S41009S Unspecified open wound of unspecified shoulder, sequela',
  'S41011A Laceration without foreign body of right shoulder, initial encounter',
  'S41011D Laceration without foreign body of right shoulder, subsequent encounter',
  'S41011S Laceration without foreign body of right shoulder, sequela',
  'S41012A Laceration without foreign body of left shoulder, initial encounter',
  'S41012D Laceration without foreign body of left shoulder, subsequent encounter',
  'S41012S Laceration without foreign body of left shoulder, sequela',
  'S41019A Laceration without foreign body of unspecified shoulder, initial encounter',
  'S41019D Laceration without foreign body of unspecified shoulder, subsequent encounter',
  'S41019S Laceration without foreign body of unspecified shoulder, sequela',
  'S41021A Laceration with foreign body of right shoulder, initial encounter',
  'S41021D Laceration with foreign body of right shoulder, subsequent encounter',
  'S41021S Laceration with foreign body of right shoulder, sequela',
  'S41022A Laceration with foreign body of left shoulder, initial encounter',
  'S41022D Laceration with foreign body of left shoulder, subsequent encounter',
  'S41022S Laceration with foreign body of left shoulder, sequela',
  'S41029A Laceration with foreign body of unspecified shoulder, initial encounter',
  'S41029D Laceration with foreign body of unspecified shoulder, subsequent encounter',
  'S41029S Laceration with foreign body of unspecified shoulder, sequela',
  'S41031A Puncture wound without foreign body of right shoulder, initial encounter',
  'S41031D Puncture wound without foreign body of right shoulder, subsequent encounter',
  'S41031S Puncture wound without foreign body of right shoulder, sequela',
  'S41032A Puncture wound without foreign body of left shoulder, initial encounter',
  'S41032D Puncture wound without foreign body of left shoulder, subsequent encounter',
  'S41032S Puncture wound without foreign body of left shoulder, sequela',
  'S41039A Puncture wound without foreign body of unspecified shoulder, initial encounter',
  'S41039D Puncture wound without foreign body of unspecified shoulder, subsequent encounter',
  'S41039S Puncture wound without foreign body of unspecified shoulder, sequela',
  'S41041A Puncture wound with foreign body of right shoulder, initial encounter',
  'S41041D Puncture wound with foreign body of right shoulder, subsequent encounter',
  'S41041S Puncture wound with foreign body of right shoulder, sequela',
  'S41042A Puncture wound with foreign body of left shoulder, initial encounter',
  'S41042D Puncture wound with foreign body of left shoulder, subsequent encounter',
  'S41042S Puncture wound with foreign body of left shoulder, sequela',
  'S41049A Puncture wound with foreign body of unspecified shoulder, initial encounter',
  'S41049D Puncture wound with foreign body of unspecified shoulder, subsequent encounter',
  'S41049S Puncture wound with foreign body of unspecified shoulder, sequela',
  'S41051A Open bite of right shoulder, initial encounter',
  'S41051D Open bite of right shoulder, subsequent encounter',
  'S41051S Open bite of right shoulder, sequela',
  'S41052A Open bite of left shoulder, initial encounter',
  'S41052D Open bite of left shoulder, subsequent encounter',
  'S41052S Open bite of left shoulder, sequela',
  'S41059A Open bite of unspecified shoulder, initial encounter',
  'S41059D Open bite of unspecified shoulder, subsequent encounter',
  'S41059S Open bite of unspecified shoulder, sequela',
  'S41101A Unspecified open wound of right upper arm, initial encounter',
  'S41101D Unspecified open wound of right upper arm, subsequent encounter',
  'S41101S Unspecified open wound of right upper arm, sequela',
  'S41102A Unspecified open wound of left upper arm, initial encounter',
  'S41102D Unspecified open wound of left upper arm, subsequent encounter',
  'S41102S Unspecified open wound of left upper arm, sequela',
  'S41109A Unspecified open wound of unspecified upper arm, initial encounter',
  'S41109D Unspecified open wound of unspecified upper arm, subsequent encounter',
  'S41109S Unspecified open wound of unspecified upper arm, sequela',
  'S41111A Laceration without foreign body of right upper arm, initial encounter',
  'S41111D Laceration without foreign body of right upper arm, subsequent encounter',
  'S41111S Laceration without foreign body of right upper arm, sequela',
  'S41112A Laceration without foreign body of left upper arm, initial encounter',
  'S41112D Laceration without foreign body of left upper arm, subsequent encounter',
  'S41112S Laceration without foreign body of left upper arm, sequela',
  'S41119A Laceration without foreign body of unspecified upper arm, initial encounter',
  'S41119D Laceration without foreign body of unspecified upper arm, subsequent encounter',
  'S41119S Laceration without foreign body of unspecified upper arm, sequela',
  'S41121A Laceration with foreign body of right upper arm, initial encounter',
  'S41121D Laceration with foreign body of right upper arm, subsequent encounter',
  'S41121S Laceration with foreign body of right upper arm, sequela',
  'S41122A Laceration with foreign body of left upper arm, initial encounter',
  'S41122D Laceration with foreign body of left upper arm, subsequent encounter',
  'S41122S Laceration with foreign body of left upper arm, sequela',
  'S41129A Laceration with foreign body of unspecified upper arm, initial encounter',
  'S41129D Laceration with foreign body of unspecified upper arm, subsequent encounter',
  'S41129S Laceration with foreign body of unspecified upper arm, sequela',
  'S41131A Puncture wound without foreign body of right upper arm, initial encounter',
  'S41131D Puncture wound without foreign body of right upper arm, subsequent encounter',
  'S41131S Puncture wound without foreign body of right upper arm, sequela',
  'S41132A Puncture wound without foreign body of left upper arm, initial encounter',
  'S41132D Puncture wound without foreign body of left upper arm, subsequent encounter',
  'S41132S Puncture wound without foreign body of left upper arm, sequela',
  'S41139A Puncture wound without foreign body of unspecified upper arm, initial encounter',
  'S41139D Puncture wound without foreign body of unspecified upper arm, subsequent encounter',
  'S41139S Puncture wound without foreign body of unspecified upper arm, sequela',
  'S41141A Puncture wound with foreign body of right upper arm, initial encounter',
  'S41141D Puncture wound with foreign body of right upper arm, subsequent encounter',
  'S41141S Puncture wound with foreign body of right upper arm, sequela',
  'S41142A Puncture wound with foreign body of left upper arm, initial encounter',
  'S41142D Puncture wound with foreign body of left upper arm, subsequent encounter',
  'S41142S Puncture wound with foreign body of left upper arm, sequela',
  'S41149A Puncture wound with foreign body of unspecified upper arm, initial encounter',
  'S41149D Puncture wound with foreign body of unspecified upper arm, subsequent encounter',
  'S41149S Puncture wound with foreign body of unspecified upper arm, sequela',
  'S41151A Open bite of right upper arm, initial encounter',
  'S41151D Open bite of right upper arm, subsequent encounter',
  'S41151S Open bite of right upper arm, sequela',
  'S41152A Open bite of left upper arm, initial encounter',
  'S41152D Open bite of left upper arm, subsequent encounter',
  'S41152S Open bite of left upper arm, sequela',
  'S41159A Open bite of unspecified upper arm, initial encounter',
  'S41159D Open bite of unspecified upper arm, subsequent encounter',
  'S41159S Open bite of unspecified upper arm, sequela',
  'S42001A Fracture of unspecified part of right clavicle, initial encounter for closed fracture',
  'S42001B Fracture of unspecified part of right clavicle, initial encounter for open fracture',
  'S42001D Fracture of unspecified part of right clavicle, subsequent encounter for fracture with routine',
  'healing',
  'S42001G Fracture of unspecified part of right clavicle, subsequent encounter for fracture with delayed',
  'healing',
  'S42001K Fracture of unspecified part of right clavicle, subsequent encounter for fracture with nonunion',
  'S42001P Fracture of unspecified part of right clavicle, subsequent encounter for fracture with malunion',
  'S42001S Fracture of unspecified part of right clavicle, sequela',
  'S42002A Fracture of unspecified part of left clavicle, initial encounter for closed fracture',
  'S42002B Fracture of unspecified part of left clavicle, initial encounter for open fracture',
  'S42002D Fracture of unspecified part of left clavicle, subsequent encounter for fracture with routine',
  'healing',
  'S42002G Fracture of unspecified part of left clavicle, subsequent encounter for fracture with delayed',
  'healing',
  'S42002K Fracture of unspecified part of left clavicle, subsequent encounter for fracture with nonunion',
  'S42002P Fracture of unspecified part of left clavicle, subsequent encounter for fracture with malunion',
  'S42002S Fracture of unspecified part of left clavicle, sequela',
  'S42009A Fracture of unspecified part of unspecified clavicle, initial encounter for closed fracture',
  'S42009B Fracture of unspecified part of unspecified clavicle, initial encounter for open fracture',
  'S42009D Fracture of unspecified part of unspecified clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42009G Fracture of unspecified part of unspecified clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42009K Fracture of unspecified part of unspecified clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42009P Fracture of unspecified part of unspecified clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42009S Fracture of unspecified part of unspecified clavicle, sequela',
  'S42011A Anterior displaced fracture of sternal end of right clavicle, initial encounter for closed fracture',
  'S42011B Anterior displaced fracture of sternal end of right clavicle, initial encounter for open fracture',
  'S42011D Anterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with routine healing',
  'S42011G Anterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with delayed healing',
  'S42011K Anterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with nonunion',
  'S42011P Anterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with malunion',
  'S42011S Anterior displaced fracture of sternal end of right clavicle, sequela',
  'S42012A Anterior displaced fracture of sternal end of left clavicle, initial encounter for closed fracture',
  'S42012B Anterior displaced fracture of sternal end of left clavicle, initial encounter for open fracture',
  'S42012D Anterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with routine healing',
  'S42012G Anterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with delayed healing',
  'S42012K Anterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with nonunion',
  'S42012P Anterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with malunion',
  'S42012S Anterior displaced fracture of sternal end of left clavicle, sequela',
  'S42013A Anterior displaced fracture of sternal end of unspecified clavicle, initial encounter for closed',
  'fracture',
  'S42013B Anterior displaced fracture of sternal end of unspecified clavicle, initial encounter for open',
  'fracture',
  'S42013D Anterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with routine healing',
  'S42013G Anterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with delayed healing',
  'S42013K Anterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with nonunion',
  'S42013P Anterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with malunion',
  'S42013S Anterior displaced fracture of sternal end of unspecified clavicle, sequela',
  'S42014A Posterior displaced fracture of sternal end of right clavicle, initial encounter for closed fracture',
  'S42014B Posterior displaced fracture of sternal end of right clavicle, initial encounter for open fracture',
  'S42014D Posterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with routine healing',
  'S42014G Posterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with delayed healing',
  'S42014K Posterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with nonunion',
  'S42014P Posterior displaced fracture of sternal end of right clavicle, subsequent encounter for fracture',
  'with malunion',
  'S42014S Posterior displaced fracture of sternal end of right clavicle, sequela',
  'S42015A Posterior displaced fracture of sternal end of left clavicle, initial encounter for closed fracture',
  'S42015B Posterior displaced fracture of sternal end of left clavicle, initial encounter for open fracture',
  'S42015D Posterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with routine healing',
  'S42015G Posterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with delayed healing',
  'S42015K Posterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with nonunion',
  'S42015P Posterior displaced fracture of sternal end of left clavicle, subsequent encounter for fracture',
  'with malunion',
  'S42015S Posterior displaced fracture of sternal end of left clavicle, sequela',
  'S42016A Posterior displaced fracture of sternal end of unspecified clavicle, initial encounter for closed',
  'fracture',
  'S42016B Posterior displaced fracture of sternal end of unspecified clavicle, initial encounter for open',
  'fracture',
  'S42016D Posterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with routine healing',
  'S42016G Posterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with delayed healing',
  'S42016K Posterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with nonunion',
  'S42016P Posterior displaced fracture of sternal end of unspecified clavicle, subsequent encounter for',
  'fracture with malunion',
  'S42016S Posterior displaced fracture of sternal end of unspecified clavicle, sequela',
  'S42017A Nondisplaced fracture of sternal end of right clavicle, initial encounter for closed fracture',
  'S42017B Nondisplaced fracture of sternal end of right clavicle, initial encounter for open fracture',
  'S42017D Nondisplaced fracture of sternal end of right clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42017G Nondisplaced fracture of sternal end of right clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42017K Nondisplaced fracture of sternal end of right clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42017P Nondisplaced fracture of sternal end of right clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42017S Nondisplaced fracture of sternal end of right clavicle, sequela',
  'S42018A Nondisplaced fracture of sternal end of left clavicle, initial encounter for closed fracture',
  'S42018B Nondisplaced fracture of sternal end of left clavicle, initial encounter for open fracture',
  'S42018D Nondisplaced fracture of sternal end of left clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42018G Nondisplaced fracture of sternal end of left clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42018K Nondisplaced fracture of sternal end of left clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42018P Nondisplaced fracture of sternal end of left clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42018S Nondisplaced fracture of sternal end of left clavicle, sequela',
  'S42019A Nondisplaced fracture of sternal end of unspecified clavicle, initial encounter for closed',
  'fracture',
  'S42019B Nondisplaced fracture of sternal end of unspecified clavicle, initial encounter for open fracture',
  'S42019D Nondisplaced fracture of sternal end of unspecified clavicle, subsequent encounter for fracture',
  'with routine healing',
  'S42019G Nondisplaced fracture of sternal end of unspecified clavicle, subsequent encounter for fracture',
  'with delayed healing',
  'S42019K Nondisplaced fracture of sternal end of unspecified clavicle, subsequent encounter for fracture',
  'with nonunion',
  'S42019P Nondisplaced fracture of sternal end of unspecified clavicle, subsequent encounter for fracture',
  'with malunion',
  'S42019S Nondisplaced fracture of sternal end of unspecified clavicle, sequela',
  'S42021A Displaced fracture of shaft of right clavicle, initial encounter for closed fracture',
  'S42021B Displaced fracture of shaft of right clavicle, initial encounter for open fracture',
  'S42021D Displaced fracture of shaft of right clavicle, subsequent encounter for fracture with routine',
  'healing',
  'S42021G Displaced fracture of shaft of right clavicle, subsequent encounter for fracture with delayed',
  'healing',
  'S42021K Displaced fracture of shaft of right clavicle, subsequent encounter for fracture with nonunion',
  'S42021P Displaced fracture of shaft of right clavicle, subsequent encounter for fracture with malunion',
  'S42021S Displaced fracture of shaft of right clavicle, sequela',
  'S42022A Displaced fracture of shaft of left clavicle, initial encounter for closed fracture',
  'S42022B Displaced fracture of shaft of left clavicle, initial encounter for open fracture',
  'S42022D Displaced fracture of shaft of left clavicle, subsequent encounter for fracture with routine',
  'healing',
  'S42022G Displaced fracture of shaft of left clavicle, subsequent encounter for fracture with delayed',
  'healing',
  'S42022K Displaced fracture of shaft of left clavicle, subsequent encounter for fracture with nonunion',
  'S42022P Displaced fracture of shaft of left clavicle, subsequent encounter for fracture with malunion',
  'S42022S Displaced fracture of shaft of left clavicle, sequela',
  'S42023A Displaced fracture of shaft of unspecified clavicle, initial encounter for closed fracture',
  'S42023B Displaced fracture of shaft of unspecified clavicle, initial encounter for open fracture',
  'S42023D Displaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42023G Displaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42023K Displaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42023P Displaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42023S Displaced fracture of shaft of unspecified clavicle, sequela',
  'S42024A Nondisplaced fracture of shaft of right clavicle, initial encounter for closed fracture',
  'S42024B Nondisplaced fracture of shaft of right clavicle, initial encounter for open fracture',
  'S42024D Nondisplaced fracture of shaft of right clavicle, subsequent encounter for fracture with routine',
  'healing',
  'S42024G Nondisplaced fracture of shaft of right clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42024K Nondisplaced fracture of shaft of right clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42024P Nondisplaced fracture of shaft of right clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42024S Nondisplaced fracture of shaft of right clavicle, sequela',
  'S42025A Nondisplaced fracture of shaft of left clavicle, initial encounter for closed fracture',
  'S42025B Nondisplaced fracture of shaft of left clavicle, initial encounter for open fracture',
  'S42025D Nondisplaced fracture of shaft of left clavicle, subsequent encounter for fracture with routine',
  'healing',
  'S42025G Nondisplaced fracture of shaft of left clavicle, subsequent encounter for fracture with delayed',
  'healing',
  'S42025K Nondisplaced fracture of shaft of left clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42025P Nondisplaced fracture of shaft of left clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42025S Nondisplaced fracture of shaft of left clavicle, sequela',
  'S42026A Nondisplaced fracture of shaft of unspecified clavicle, initial encounter for closed fracture',
  'S42026B Nondisplaced fracture of shaft of unspecified clavicle, initial encounter for open fracture',
  'S42026D Nondisplaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42026G Nondisplaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42026K Nondisplaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42026P Nondisplaced fracture of shaft of unspecified clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42026S Nondisplaced fracture of shaft of unspecified clavicle, sequela',
  'S42031A Displaced fracture of lateral end of right clavicle, initial encounter for closed fracture',
  'S42031B Displaced fracture of lateral end of right clavicle, initial encounter for open fracture',
  'S42031D Displaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42031G Displaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42031K Displaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42031P Displaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42031S Displaced fracture of lateral end of right clavicle, sequela',
  'S42032A Displaced fracture of lateral end of left clavicle, initial encounter for closed fracture',
  'S42032B Displaced fracture of lateral end of left clavicle, initial encounter for open fracture',
  'S42032D Displaced fracture of lateral end of left clavicle, subsequent encounter for fracture with routine',
  'healing',
  'S42032G Displaced fracture of lateral end of left clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42032K Displaced fracture of lateral end of left clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42032P Displaced fracture of lateral end of left clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42032S Displaced fracture of lateral end of left clavicle, sequela',
  'S42033A Displaced fracture of lateral end of unspecified clavicle, initial encounter for closed fracture',
  'S42033B Displaced fracture of lateral end of unspecified clavicle, initial encounter for open fracture',
  'S42033D Displaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture',
  'with routine healing',
  'S42033G Displaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture',
  'with delayed healing',
  'S42033K Displaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42033P Displaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42033S Displaced fracture of lateral end of unspecified clavicle, sequela',
  'S42034A Nondisplaced fracture of lateral end of right clavicle, initial encounter for closed fracture',
  'S42034B Nondisplaced fracture of lateral end of right clavicle, initial encounter for open fracture',
  'S42034D Nondisplaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42034G Nondisplaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42034K Nondisplaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42034P Nondisplaced fracture of lateral end of right clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42034S Nondisplaced fracture of lateral end of right clavicle, sequela',
  'S42035A Nondisplaced fracture of lateral end of left clavicle, initial encounter for closed fracture',
  'S42035B Nondisplaced fracture of lateral end of left clavicle, initial encounter for open fracture',
  'S42035D Nondisplaced fracture of lateral end of left clavicle, subsequent encounter for fracture with',
  'routine healing',
  'S42035G Nondisplaced fracture of lateral end of left clavicle, subsequent encounter for fracture with',
  'delayed healing',
  'S42035K Nondisplaced fracture of lateral end of left clavicle, subsequent encounter for fracture with',
  'nonunion',
  'S42035P Nondisplaced fracture of lateral end of left clavicle, subsequent encounter for fracture with',
  'malunion',
  'S42035S Nondisplaced fracture of lateral end of left clavicle, sequela',
  'S42036A Nondisplaced fracture of lateral end of unspecified clavicle, initial encounter for closed fracture',
  'S42036B Nondisplaced fracture of lateral end of unspecified clavicle, initial encounter for open fracture',
  'S42036D Nondisplaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture',
  'with routine healing',
  'S42036G Nondisplaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture',
  'with delayed healing',
  'S42036K Nondisplaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture',
  'with nonunion',
  'S42036P Nondisplaced fracture of lateral end of unspecified clavicle, subsequent encounter for fracture',
  'with malunion',
  'S42036S Nondisplaced fracture of lateral end of unspecified clavicle, sequela',
  'S42101A Fracture of unspecified part of scapula, right shoulder, initial encounter for closed fracture',
  'S42101B Fracture of unspecified part of scapula, right shoulder, initial encounter for open fracture',
  'S42101D Fracture of unspecified part of scapula, right shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42101G Fracture of unspecified part of scapula, right shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42101K Fracture of unspecified part of scapula, right shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42101P Fracture of unspecified part of scapula, right shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42101S Fracture of unspecified part of scapula, right shoulder, sequela',
  'S42102A Fracture of unspecified part of scapula, left shoulder, initial encounter for closed fracture',
  'S42102B Fracture of unspecified part of scapula, left shoulder, initial encounter for open fracture',
  'S42102D Fracture of unspecified part of scapula, left shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42102G Fracture of unspecified part of scapula, left shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42102K Fracture of unspecified part of scapula, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42102P Fracture of unspecified part of scapula, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42102S Fracture of unspecified part of scapula, left shoulder, sequela',
  'S42109A Fracture of unspecified part of scapula, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42109B Fracture of unspecified part of scapula, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42109D Fracture of unspecified part of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42109G Fracture of unspecified part of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42109K Fracture of unspecified part of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42109P Fracture of unspecified part of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42109S Fracture of unspecified part of scapula, unspecified shoulder, sequela',
  'S42111A Displaced fracture of body of scapula, right shoulder, initial encounter for closed fracture',
  'S42111B Displaced fracture of body of scapula, right shoulder, initial encounter for open fracture',
  'S42111D Displaced fracture of body of scapula, right shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42111G Displaced fracture of body of scapula, right shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42111K Displaced fracture of body of scapula, right shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42111P Displaced fracture of body of scapula, right shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42111S Displaced fracture of body of scapula, right shoulder, sequela',
  'S42112A Displaced fracture of body of scapula, left shoulder, initial encounter for closed fracture',
  'S42112B Displaced fracture of body of scapula, left shoulder, initial encounter for open fracture',
  'S42112D Displaced fracture of body of scapula, left shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42112G Displaced fracture of body of scapula, left shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42112K Displaced fracture of body of scapula, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42112P Displaced fracture of body of scapula, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42112S Displaced fracture of body of scapula, left shoulder, sequela',
  'S42113A Displaced fracture of body of scapula, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42113B Displaced fracture of body of scapula, unspecified shoulder, initial encounter for open fracture',
  'S42113D Displaced fracture of body of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42113G Displaced fracture of body of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42113K Displaced fracture of body of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42113P Displaced fracture of body of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42113S Displaced fracture of body of scapula, unspecified shoulder, sequela',
  'S42114A Nondisplaced fracture of body of scapula, right shoulder, initial encounter for closed fracture',
  'S42114B Nondisplaced fracture of body of scapula, right shoulder, initial encounter for open fracture',
  'S42114D Nondisplaced fracture of body of scapula, right shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42114G Nondisplaced fracture of body of scapula, right shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42114K Nondisplaced fracture of body of scapula, right shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42114P Nondisplaced fracture of body of scapula, right shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42114S Nondisplaced fracture of body of scapula, right shoulder, sequela',
  'S42115A Nondisplaced fracture of body of scapula, left shoulder, initial encounter for closed fracture',
  'S42115B Nondisplaced fracture of body of scapula, left shoulder, initial encounter for open fracture',
  'S42115D Nondisplaced fracture of body of scapula, left shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42115G Nondisplaced fracture of body of scapula, left shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42115K Nondisplaced fracture of body of scapula, left shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42115P Nondisplaced fracture of body of scapula, left shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42115S Nondisplaced fracture of body of scapula, left shoulder, sequela',
  'S42116A Nondisplaced fracture of body of scapula, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42116B Nondisplaced fracture of body of scapula, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42116D Nondisplaced fracture of body of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42116G Nondisplaced fracture of body of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42116K Nondisplaced fracture of body of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42116P Nondisplaced fracture of body of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42116S Nondisplaced fracture of body of scapula, unspecified shoulder, sequela',
  'S42121A Displaced fracture of acromial process, right shoulder, initial encounter for closed fracture',
  'S42121B Displaced fracture of acromial process, right shoulder, initial encounter for open fracture',
  'S42121D Displaced fracture of acromial process, right shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42121G Displaced fracture of acromial process, right shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42121K Displaced fracture of acromial process, right shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42121P Displaced fracture of acromial process, right shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42121S Displaced fracture of acromial process, right shoulder, sequela',
  'S42122A Displaced fracture of acromial process, left shoulder, initial encounter for closed fracture',
  'S42122B Displaced fracture of acromial process, left shoulder, initial encounter for open fracture',
  'S42122D Displaced fracture of acromial process, left shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42122G Displaced fracture of acromial process, left shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42122K Displaced fracture of acromial process, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42122P Displaced fracture of acromial process, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42122S Displaced fracture of acromial process, left shoulder, sequela',
  'S42123A Displaced fracture of acromial process, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42123B Displaced fracture of acromial process, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42123D Displaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42123G Displaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42123K Displaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42123P Displaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42123S Displaced fracture of acromial process, unspecified shoulder, sequela',
  'S42124A Nondisplaced fracture of acromial process, right shoulder, initial encounter for closed fracture',
  'S42124B Nondisplaced fracture of acromial process, right shoulder, initial encounter for open fracture',
  'S42124D Nondisplaced fracture of acromial process, right shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42124G Nondisplaced fracture of acromial process, right shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42124K Nondisplaced fracture of acromial process, right shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42124P Nondisplaced fracture of acromial process, right shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42124S Nondisplaced fracture of acromial process, right shoulder, sequela',
  'S42125A Nondisplaced fracture of acromial process, left shoulder, initial encounter for closed fracture',
  'S42125B Nondisplaced fracture of acromial process, left shoulder, initial encounter for open fracture',
  'S42125D Nondisplaced fracture of acromial process, left shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42125G Nondisplaced fracture of acromial process, left shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42125K Nondisplaced fracture of acromial process, left shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42125P Nondisplaced fracture of acromial process, left shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42125S Nondisplaced fracture of acromial process, left shoulder, sequela',
  'S42126A Nondisplaced fracture of acromial process, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42126B Nondisplaced fracture of acromial process, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42126D Nondisplaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42126G Nondisplaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42126K Nondisplaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42126P Nondisplaced fracture of acromial process, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42126S Nondisplaced fracture of acromial process, unspecified shoulder, sequela',
  'S42131A Displaced fracture of coracoid process, right shoulder, initial encounter for closed fracture',
  'S42131B Displaced fracture of coracoid process, right shoulder, initial encounter for open fracture',
  'S42131D Displaced fracture of coracoid process, right shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42131G Displaced fracture of coracoid process, right shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42131K Displaced fracture of coracoid process, right shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42131P Displaced fracture of coracoid process, right shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42131S Displaced fracture of coracoid process, right shoulder, sequela',
  'S42132A Displaced fracture of coracoid process, left shoulder, initial encounter for closed fracture',
  'S42132B Displaced fracture of coracoid process, left shoulder, initial encounter for open fracture',
  'S42132D Displaced fracture of coracoid process, left shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42132G Displaced fracture of coracoid process, left shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42132K Displaced fracture of coracoid process, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42132P Displaced fracture of coracoid process, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42132S Displaced fracture of coracoid process, left shoulder, sequela',
  'S42133A Displaced fracture of coracoid process, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42133B Displaced fracture of coracoid process, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42133D Displaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42133G Displaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42133K Displaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42133P Displaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42133S Displaced fracture of coracoid process, unspecified shoulder, sequela',
  'S42134A Nondisplaced fracture of coracoid process, right shoulder, initial encounter for closed fracture',
  'S42134B Nondisplaced fracture of coracoid process, right shoulder, initial encounter for open fracture',
  'S42134D Nondisplaced fracture of coracoid process, right shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42134G Nondisplaced fracture of coracoid process, right shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42134K Nondisplaced fracture of coracoid process, right shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42134P Nondisplaced fracture of coracoid process, right shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42134S Nondisplaced fracture of coracoid process, right shoulder, sequela',
  'S42135A Nondisplaced fracture of coracoid process, left shoulder, initial encounter for closed fracture',
  'S42135B Nondisplaced fracture of coracoid process, left shoulder, initial encounter for open fracture',
  'S42135D Nondisplaced fracture of coracoid process, left shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42135G Nondisplaced fracture of coracoid process, left shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42135K Nondisplaced fracture of coracoid process, left shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42135P Nondisplaced fracture of coracoid process, left shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42135S Nondisplaced fracture of coracoid process, left shoulder, sequela',
  'S42136A Nondisplaced fracture of coracoid process, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42136B Nondisplaced fracture of coracoid process, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42136D Nondisplaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42136G Nondisplaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42136K Nondisplaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42136P Nondisplaced fracture of coracoid process, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42136S Nondisplaced fracture of coracoid process, unspecified shoulder, sequela',
  'S42141A Displaced fracture of glenoid cavity of scapula, right shoulder, initial encounter for closed',
  'fracture',
  'S42141B Displaced fracture of glenoid cavity of scapula, right shoulder, initial encounter for open',
  'fracture',
  'S42141D Displaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42141G Displaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42141K Displaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42141P Displaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42141S Displaced fracture of glenoid cavity of scapula, right shoulder, sequela',
  'S42142A Displaced fracture of glenoid cavity of scapula, left shoulder, initial encounter for closed',
  'fracture',
  'S42142B Displaced fracture of glenoid cavity of scapula, left shoulder, initial encounter for open fracture',
  'S42142D Displaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42142G Displaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42142K Displaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42142P Displaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42142S Displaced fracture of glenoid cavity of scapula, left shoulder, sequela',
  'S42143A Displaced fracture of glenoid cavity of scapula, unspecified shoulder, initial encounter for',
  'closed fracture',
  'S42143B Displaced fracture of glenoid cavity of scapula, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42143D Displaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42143G Displaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42143K Displaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42143P Displaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42143S Displaced fracture of glenoid cavity of scapula, unspecified shoulder, sequela',
  'S42144A Nondisplaced fracture of glenoid cavity of scapula, right shoulder, initial encounter for closed',
  'fracture',
  'S42144B Nondisplaced fracture of glenoid cavity of scapula, right shoulder, initial encounter for open',
  'fracture',
  'S42144D Nondisplaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42144G Nondisplaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42144K Nondisplaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42144P Nondisplaced fracture of glenoid cavity of scapula, right shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42144S Nondisplaced fracture of glenoid cavity of scapula, right shoulder, sequela',
  'S42145A Nondisplaced fracture of glenoid cavity of scapula, left shoulder, initial encounter for closed',
  'fracture',
  'S42145B Nondisplaced fracture of glenoid cavity of scapula, left shoulder, initial encounter for open',
  'fracture',
  'S42145D Nondisplaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42145G Nondisplaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42145K Nondisplaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42145P Nondisplaced fracture of glenoid cavity of scapula, left shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42145S Nondisplaced fracture of glenoid cavity of scapula, left shoulder, sequela',
  'S42146A Nondisplaced fracture of glenoid cavity of scapula, unspecified shoulder, initial encounter for',
  'closed fracture',
  'S42146B Nondisplaced fracture of glenoid cavity of scapula, unspecified shoulder, initial encounter for',
  'open fracture',
  'S42146D Nondisplaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent',
  'encounter for fracture with routine healing',
  'S42146G Nondisplaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent',
  'encounter for fracture with delayed healing',
  'S42146K Nondisplaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent',
  'encounter for fracture with nonunion',
  'S42146P Nondisplaced fracture of glenoid cavity of scapula, unspecified shoulder, subsequent',
  'encounter for fracture with malunion',
  'S42146S Nondisplaced fracture of glenoid cavity of scapula, unspecified shoulder, sequela',
  'S42151A Displaced fracture of neck of scapula, right shoulder, initial encounter for closed fracture',
  'S42151B Displaced fracture of neck of scapula, right shoulder, initial encounter for open fracture',
  'S42151D Displaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42151G Displaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42151K Displaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42151P Displaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42151S Displaced fracture of neck of scapula, right shoulder, sequela',
  'S42152A Displaced fracture of neck of scapula, left shoulder, initial encounter for closed fracture',
  'S42152B Displaced fracture of neck of scapula, left shoulder, initial encounter for open fracture',
  'S42152D Displaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42152G Displaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42152K Displaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42152P Displaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42152S Displaced fracture of neck of scapula, left shoulder, sequela',
  'S42153A Displaced fracture of neck of scapula, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42153B Displaced fracture of neck of scapula, unspecified shoulder, initial encounter for open fracture',
  'S42153D Displaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42153G Displaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42153K Displaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42153P Displaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42153S Displaced fracture of neck of scapula, unspecified shoulder, sequela',
  'S42154A Nondisplaced fracture of neck of scapula, right shoulder, initial encounter for closed fracture',
  'S42154B Nondisplaced fracture of neck of scapula, right shoulder, initial encounter for open fracture',
  'S42154D Nondisplaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42154G Nondisplaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42154K Nondisplaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture',
  'with nonunion',
  'S42154P Nondisplaced fracture of neck of scapula, right shoulder, subsequent encounter for fracture',
  'with malunion',
  'S42154S Nondisplaced fracture of neck of scapula, right shoulder, sequela',
  'S42155A Nondisplaced fracture of neck of scapula, left shoulder, initial encounter for closed fracture',
  'S42155B Nondisplaced fracture of neck of scapula, left shoulder, initial encounter for open fracture',
  'S42155D Nondisplaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture',
  'with routine healing',
  'S42155G Nondisplaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42155K Nondisplaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42155P Nondisplaced fracture of neck of scapula, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42155S Nondisplaced fracture of neck of scapula, left shoulder, sequela',
  'S42156A Nondisplaced fracture of neck of scapula, unspecified shoulder, initial encounter for closed',
  'fracture',
  'S42156B Nondisplaced fracture of neck of scapula, unspecified shoulder, initial encounter for open',
  'fracture',
  'S42156D Nondisplaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with routine healing',
  'S42156G Nondisplaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with delayed healing',
  'S42156K Nondisplaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with nonunion',
  'S42156P Nondisplaced fracture of neck of scapula, unspecified shoulder, subsequent encounter for',
  'fracture with malunion',
  'S42156S Nondisplaced fracture of neck of scapula, unspecified shoulder, sequela',
  'S42191A Fracture of other part of scapula, right shoulder, initial encounter for closed fracture',
  'S42191B Fracture of other part of scapula, right shoulder, initial encounter for open fracture',
  'S42191D Fracture of other part of scapula, right shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42191G Fracture of other part of scapula, right shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42191K Fracture of other part of scapula, right shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42191P Fracture of other part of scapula, right shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42191S Fracture of other part of scapula, right shoulder, sequela',
  'S42192A Fracture of other part of scapula, left shoulder, initial encounter for closed fracture',
  'S42192B Fracture of other part of scapula, left shoulder, initial encounter for open fracture',
  'S42192D Fracture of other part of scapula, left shoulder, subsequent encounter for fracture with routine',
  'healing',
  'S42192G Fracture of other part of scapula, left shoulder, subsequent encounter for fracture with',
  'delayed healing',
  'S42192K Fracture of other part of scapula, left shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42192P Fracture of other part of scapula, left shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42192S Fracture of other part of scapula, left shoulder, sequela',
  'S42199A Fracture of other part of scapula, unspecified shoulder, initial encounter for closed fracture',
  'S42199B Fracture of other part of scapula, unspecified shoulder, initial encounter for open fracture',
  'S42199D Fracture of other part of scapula, unspecified shoulder, subsequent encounter for fracture with',
  'routine healing',
  'S42199G Fracture of other part of scapula, unspecified shoulder, subsequent encounter for fracture',
  'with delayed healing',
  'S42199K Fracture of other part of scapula, unspecified shoulder, subsequent encounter for fracture with',
  'nonunion',
  'S42199P Fracture of other part of scapula, unspecified shoulder, subsequent encounter for fracture with',
  'malunion',
  'S42199S Fracture of other part of scapula, unspecified shoulder, sequela',
  'S42201A Unspecified fracture of upper end of right humerus, initial encounter for closed fracture',
  'S42201B Unspecified fracture of upper end of right humerus, initial encounter for open fracture',
  'S42201D Unspecified fracture of upper end of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42201G Unspecified fracture of upper end of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42201K Unspecified fracture of upper end of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42201P Unspecified fracture of upper end of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42201S Unspecified fracture of upper end of right humerus, sequela',
  'S42202A Unspecified fracture of upper end of left humerus, initial encounter for closed fracture',
  'S42202B Unspecified fracture of upper end of left humerus, initial encounter for open fracture',
  'S42202D Unspecified fracture of upper end of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42202G Unspecified fracture of upper end of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42202K Unspecified fracture of upper end of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42202P Unspecified fracture of upper end of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42202S Unspecified fracture of upper end of left humerus, sequela',
  'S42209A Unspecified fracture of upper end of unspecified humerus, initial encounter for closed fracture',
  'S42209B Unspecified fracture of upper end of unspecified humerus, initial encounter for open fracture',
  'S42209D Unspecified fracture of upper end of unspecified humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42209G Unspecified fracture of upper end of unspecified humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42209K Unspecified fracture of upper end of unspecified humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42209P Unspecified fracture of upper end of unspecified humerus, subsequent encounter for fracture',
  'with malunion',
  'S42209S Unspecified fracture of upper end of unspecified humerus, sequela',
  'S42211A Unspecified displaced fracture of surgical neck of right humerus, initial encounter for closed',
  'fracture',
  'S42211B Unspecified displaced fracture of surgical neck of right humerus, initial encounter for open',
  'fracture',
  'S42211D Unspecified displaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42211G Unspecified displaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42211K Unspecified displaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42211P Unspecified displaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with malunion',
  'S42211S Unspecified displaced fracture of surgical neck of right humerus, sequela',
  'S42212A Unspecified displaced fracture of surgical neck of left humerus, initial encounter for closed',
  'fracture',
  'S42212B Unspecified displaced fracture of surgical neck of left humerus, initial encounter for open',
  'fracture',
  'S42212D Unspecified displaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42212G Unspecified displaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42212K Unspecified displaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42212P Unspecified displaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with malunion',
  'S42212S Unspecified displaced fracture of surgical neck of left humerus, sequela',
  'S42213A Unspecified displaced fracture of surgical neck of unspecified humerus, initial encounter for',
  'closed fracture',
  'S42213B Unspecified displaced fracture of surgical neck of unspecified humerus, initial encounter for',
  'open fracture',
  'S42213D Unspecified displaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42213G Unspecified displaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42213K Unspecified displaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42213P Unspecified displaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with malunion',
  'S42213S Unspecified displaced fracture of surgical neck of unspecified humerus, sequela',
  'S42214A Unspecified nondisplaced fracture of surgical neck of right humerus, initial encounter for',
  'closed fracture',
  'S42214B Unspecified nondisplaced fracture of surgical neck of right humerus, initial encounter for open',
  'fracture',
  'S42214D Unspecified nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42214G Unspecified nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42214K Unspecified nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42214P Unspecified nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with malunion',
  'S42214S Unspecified nondisplaced fracture of surgical neck of right humerus, sequela',
  'S42215A Unspecified nondisplaced fracture of surgical neck of left humerus, initial encounter for closed',
  'fracture',
  'S42215B Unspecified nondisplaced fracture of surgical neck of left humerus, initial encounter for open',
  'fracture',
  'S42215D Unspecified nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42215G Unspecified nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42215K Unspecified nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42215P Unspecified nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with malunion',
  'S42215S Unspecified nondisplaced fracture of surgical neck of left humerus, sequela',
  'S42216A Unspecified nondisplaced fracture of surgical neck of unspecified humerus, initial encounter',
  'for closed fracture',
  'S42216B Unspecified nondisplaced fracture of surgical neck of unspecified humerus, initial encounter',
  'for open fracture',
  'S42216D Unspecified nondisplaced fracture of surgical neck of unspecified humerus, subsequent',
  'encounter for fracture with routine healing',
  'S42216G Unspecified nondisplaced fracture of surgical neck of unspecified humerus, subsequent',
  'encounter for fracture with delayed healing',
  'S42216K Unspecified nondisplaced fracture of surgical neck of unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'S42216P Unspecified nondisplaced fracture of surgical neck of unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'S42216S Unspecified nondisplaced fracture of surgical neck of unspecified humerus, sequela',
  'S42221A 2-part displaced fracture of surgical neck of right humerus, initial encounter for closed fracture',
  'S42221B 2-part displaced fracture of surgical neck of right humerus, initial encounter for open fracture',
  'S42221D 2-part displaced fracture of surgical neck of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42221G 2-part displaced fracture of surgical neck of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42221K 2-part displaced fracture of surgical neck of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42221P 2-part displaced fracture of surgical neck of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42221S 2-part displaced fracture of surgical neck of right humerus, sequela',
  'S42222A 2-part displaced fracture of surgical neck of left humerus, initial encounter for closed fracture',
  'S42222B 2-part displaced fracture of surgical neck of left humerus, initial encounter for open fracture',
  'S42222D 2-part displaced fracture of surgical neck of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42222G 2-part displaced fracture of surgical neck of left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42222K 2-part displaced fracture of surgical neck of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42222P 2-part displaced fracture of surgical neck of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42222S 2-part displaced fracture of surgical neck of left humerus, sequela',
  'S42223A 2-part displaced fracture of surgical neck of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42223B 2-part displaced fracture of surgical neck of unspecified humerus, initial encounter for open',
  'fracture',
  'S42223D 2-part displaced fracture of surgical neck of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42223G 2-part displaced fracture of surgical neck of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42223K 2-part displaced fracture of surgical neck of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42223P 2-part displaced fracture of surgical neck of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42223S 2-part displaced fracture of surgical neck of unspecified humerus, sequela',
  'S42224A 2-part nondisplaced fracture of surgical neck of right humerus, initial encounter for closed',
  'fracture',
  'S42224B 2-part nondisplaced fracture of surgical neck of right humerus, initial encounter for open',
  'fracture',
  'S42224D 2-part nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42224G 2-part nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42224K 2-part nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42224P 2-part nondisplaced fracture of surgical neck of right humerus, subsequent encounter for',
  'fracture with malunion',
  'S42224S 2-part nondisplaced fracture of surgical neck of right humerus, sequela',
  'S42225A 2-part nondisplaced fracture of surgical neck of left humerus, initial encounter for closed',
  'fracture',
  'S42225B 2-part nondisplaced fracture of surgical neck of left humerus, initial encounter for open',
  'fracture',
  'S42225D 2-part nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42225G 2-part nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42225K 2-part nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42225P 2-part nondisplaced fracture of surgical neck of left humerus, subsequent encounter for',
  'fracture with malunion',
  'S42225S 2-part nondisplaced fracture of surgical neck of left humerus, sequela',
  'S42226A 2-part nondisplaced fracture of surgical neck of unspecified humerus, initial encounter for',
  'closed fracture',
  'S42226B 2-part nondisplaced fracture of surgical neck of unspecified humerus, initial encounter for',
  'open fracture',
  'S42226D 2-part nondisplaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42226G 2-part nondisplaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42226K 2-part nondisplaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42226P 2-part nondisplaced fracture of surgical neck of unspecified humerus, subsequent encounter',
  'for fracture with malunion',
  'S42226S 2-part nondisplaced fracture of surgical neck of unspecified humerus, sequela',
  'S42231A 3-part fracture of surgical neck of right humerus, initial encounter for closed fracture',
  'S42231B 3-part fracture of surgical neck of right humerus, initial encounter for open fracture',
  'S42231D 3-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42231G 3-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42231K 3-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42231P 3-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42231S 3-part fracture of surgical neck of right humerus, sequela',
  'S42232A 3-part fracture of surgical neck of left humerus, initial encounter for closed fracture',
  'S42232B 3-part fracture of surgical neck of left humerus, initial encounter for open fracture',
  'S42232D 3-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42232G 3-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42232K 3-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42232P 3-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42232S 3-part fracture of surgical neck of left humerus, sequela',
  'S42239A 3-part fracture of surgical neck of unspecified humerus, initial encounter for closed fracture',
  'S42239B 3-part fracture of surgical neck of unspecified humerus, initial encounter for open fracture',
  'S42239D 3-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42239G 3-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42239K 3-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42239P 3-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with malunion',
  'S42239S 3-part fracture of surgical neck of unspecified humerus, sequela',
  'S42241A 4-part fracture of surgical neck of right humerus, initial encounter for closed fracture',
  'S42241B 4-part fracture of surgical neck of right humerus, initial encounter for open fracture',
  'S42241D 4-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42241G 4-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42241K 4-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42241P 4-part fracture of surgical neck of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42241S 4-part fracture of surgical neck of right humerus, sequela',
  'S42242A 4-part fracture of surgical neck of left humerus, initial encounter for closed fracture',
  'S42242B 4-part fracture of surgical neck of left humerus, initial encounter for open fracture',
  'S42242D 4-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42242G 4-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42242K 4-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42242P 4-part fracture of surgical neck of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42242S 4-part fracture of surgical neck of left humerus, sequela',
  'S42249A 4-part fracture of surgical neck of unspecified humerus, initial encounter for closed fracture',
  'S42249B 4-part fracture of surgical neck of unspecified humerus, initial encounter for open fracture',
  'S42249D 4-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42249G 4-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42249K 4-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42249P 4-part fracture of surgical neck of unspecified humerus, subsequent encounter for fracture',
  'with malunion',
  'S42249S 4-part fracture of surgical neck of unspecified humerus, sequela',
  'S42251A Displaced fracture of greater tuberosity of right humerus, initial encounter for closed fracture',
  'S42251B Displaced fracture of greater tuberosity of right humerus, initial encounter for open fracture',
  'S42251D Displaced fracture of greater tuberosity of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42251G Displaced fracture of greater tuberosity of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42251K Displaced fracture of greater tuberosity of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42251P Displaced fracture of greater tuberosity of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42251S Displaced fracture of greater tuberosity of right humerus, sequela',
  'S42252A Displaced fracture of greater tuberosity of left humerus, initial encounter for closed fracture',
  'S42252B Displaced fracture of greater tuberosity of left humerus, initial encounter for open fracture',
  'S42252D Displaced fracture of greater tuberosity of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42252G Displaced fracture of greater tuberosity of left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42252K Displaced fracture of greater tuberosity of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42252P Displaced fracture of greater tuberosity of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42252S Displaced fracture of greater tuberosity of left humerus, sequela',
  'S42253A Displaced fracture of greater tuberosity of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42253B Displaced fracture of greater tuberosity of unspecified humerus, initial encounter for open',
  'fracture',
  'S42253D Displaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42253G Displaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42253K Displaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42253P Displaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42253S Displaced fracture of greater tuberosity of unspecified humerus, sequela',
  'S42254A Nondisplaced fracture of greater tuberosity of right humerus, initial encounter for closed',
  'fracture',
  'S42254B Nondisplaced fracture of greater tuberosity of right humerus, initial encounter for open',
  'fracture',
  'S42254D Nondisplaced fracture of greater tuberosity of right humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42254G Nondisplaced fracture of greater tuberosity of right humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42254K Nondisplaced fracture of greater tuberosity of right humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42254P Nondisplaced fracture of greater tuberosity of right humerus, subsequent encounter for',
  'fracture with malunion',
  'S42254S Nondisplaced fracture of greater tuberosity of right humerus, sequela',
  'S42255A Nondisplaced fracture of greater tuberosity of left humerus, initial encounter for closed',
  'fracture',
  'S42255B Nondisplaced fracture of greater tuberosity of left humerus, initial encounter for open fracture',
  'S42255D Nondisplaced fracture of greater tuberosity of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42255G Nondisplaced fracture of greater tuberosity of left humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42255K Nondisplaced fracture of greater tuberosity of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42255P Nondisplaced fracture of greater tuberosity of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42255S Nondisplaced fracture of greater tuberosity of left humerus, sequela',
  'S42256A Nondisplaced fracture of greater tuberosity of unspecified humerus, initial encounter for',
  'closed fracture',
  'S42256B Nondisplaced fracture of greater tuberosity of unspecified humerus, initial encounter for open',
  'fracture',
  'S42256D Nondisplaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42256G Nondisplaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42256K Nondisplaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42256P Nondisplaced fracture of greater tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42256S Nondisplaced fracture of greater tuberosity of unspecified humerus, sequela',
  'S42261A Displaced fracture of lesser tuberosity of right humerus, initial encounter for closed fracture',
  'S42261B Displaced fracture of lesser tuberosity of right humerus, initial encounter for open fracture',
  'S42261D Displaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42261G Displaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42261K Displaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42261P Displaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42261S Displaced fracture of lesser tuberosity of right humerus, sequela',
  'S42262A Displaced fracture of lesser tuberosity of left humerus, initial encounter for closed fracture',
  'S42262B Displaced fracture of lesser tuberosity of left humerus, initial encounter for open fracture',
  'S42262D Displaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42262G Displaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42262K Displaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42262P Displaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42262S Displaced fracture of lesser tuberosity of left humerus, sequela',
  'S42263A Displaced fracture of lesser tuberosity of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42263B Displaced fracture of lesser tuberosity of unspecified humerus, initial encounter for open',
  'fracture',
  'S42263D Displaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42263G Displaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42263K Displaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42263P Displaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42263S Displaced fracture of lesser tuberosity of unspecified humerus, sequela',
  'S42264A Nondisplaced fracture of lesser tuberosity of right humerus, initial encounter for closed',
  'fracture',
  'S42264B Nondisplaced fracture of lesser tuberosity of right humerus, initial encounter for open fracture',
  'S42264D Nondisplaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42264G Nondisplaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42264K Nondisplaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42264P Nondisplaced fracture of lesser tuberosity of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42264S Nondisplaced fracture of lesser tuberosity of right humerus, sequela',
  'S42265A Nondisplaced fracture of lesser tuberosity of left humerus, initial encounter for closed fracture',
  'S42265B Nondisplaced fracture of lesser tuberosity of left humerus, initial encounter for open fracture',
  'S42265D Nondisplaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42265G Nondisplaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42265K Nondisplaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42265P Nondisplaced fracture of lesser tuberosity of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42265S Nondisplaced fracture of lesser tuberosity of left humerus, sequela',
  'S42266A Nondisplaced fracture of lesser tuberosity of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42266B Nondisplaced fracture of lesser tuberosity of unspecified humerus, initial encounter for open',
  'fracture',
  'S42266D Nondisplaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42266G Nondisplaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42266K Nondisplaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42266P Nondisplaced fracture of lesser tuberosity of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42266S Nondisplaced fracture of lesser tuberosity of unspecified humerus, sequela',
  'S42271A Torus fracture of upper end of right humerus, initial encounter for closed fracture',
  'S42271D Torus fracture of upper end of right humerus, subsequent encounter for fracture with routine',
  'healing',
  'S42271G Torus fracture of upper end of right humerus, subsequent encounter for fracture with delayed',
  'healing',
  'S42271K Torus fracture of upper end of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42271P Torus fracture of upper end of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42271S Torus fracture of upper end of right humerus, sequela',
  'S42272A Torus fracture of upper end of left humerus, initial encounter for closed fracture',
  'S42272D Torus fracture of upper end of left humerus, subsequent encounter for fracture with routine',
  'healing',
  'S42272G Torus fracture of upper end of left humerus, subsequent encounter for fracture with delayed',
  'healing',
  'S42272K Torus fracture of upper end of left humerus, subsequent encounter for fracture with nonunion',
  'S42272P Torus fracture of upper end of left humerus, subsequent encounter for fracture with malunion',
  'S42272S Torus fracture of upper end of left humerus, sequela',
  'S42279A Torus fracture of upper end of unspecified humerus, initial encounter for closed fracture',
  'S42279D Torus fracture of upper end of unspecified humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42279G Torus fracture of upper end of unspecified humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42279K Torus fracture of upper end of unspecified humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42279P Torus fracture of upper end of unspecified humerus, subsequent encounter for fracture with',
  'malunion',
  'S42279S Torus fracture of upper end of unspecified humerus, sequela',
  'S42291A Other displaced fracture of upper end of right humerus, initial encounter for closed fracture',
  'S42291B Other displaced fracture of upper end of right humerus, initial encounter for open fracture',
  'S42291D Other displaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42291G Other displaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42291K Other displaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42291P Other displaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42291S Other displaced fracture of upper end of right humerus, sequela',
  'S42292A Other displaced fracture of upper end of left humerus, initial encounter for closed fracture',
  'S42292B Other displaced fracture of upper end of left humerus, initial encounter for open fracture',
  'S42292D Other displaced fracture of upper end of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42292G Other displaced fracture of upper end of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42292K Other displaced fracture of upper end of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42292P Other displaced fracture of upper end of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42292S Other displaced fracture of upper end of left humerus, sequela',
  'S42293A Other displaced fracture of upper end of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42293B Other displaced fracture of upper end of unspecified humerus, initial encounter for open',
  'fracture',
  'S42293D Other displaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42293G Other displaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42293K Other displaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42293P Other displaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42293S Other displaced fracture of upper end of unspecified humerus, sequela',
  'S42294A Other nondisplaced fracture of upper end of right humerus, initial encounter for closed',
  'fracture',
  'S42294B Other nondisplaced fracture of upper end of right humerus, initial encounter for open fracture',
  'S42294D Other nondisplaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42294G Other nondisplaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42294K Other nondisplaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42294P Other nondisplaced fracture of upper end of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42294S Other nondisplaced fracture of upper end of right humerus, sequela',
  'S42295A Other nondisplaced fracture of upper end of left humerus, initial encounter for closed fracture',
  'S42295B Other nondisplaced fracture of upper end of left humerus, initial encounter for open fracture',
  'S42295D Other nondisplaced fracture of upper end of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42295G Other nondisplaced fracture of upper end of left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42295K Other nondisplaced fracture of upper end of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42295P Other nondisplaced fracture of upper end of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42295S Other nondisplaced fracture of upper end of left humerus, sequela',
  'S42296A Other nondisplaced fracture of upper end of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42296B Other nondisplaced fracture of upper end of unspecified humerus, initial encounter for open',
  'fracture',
  'S42296D Other nondisplaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42296G Other nondisplaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42296K Other nondisplaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42296P Other nondisplaced fracture of upper end of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42296S Other nondisplaced fracture of upper end of unspecified humerus, sequela',
  'S42301A Unspecified fracture of shaft of humerus, right arm, initial encounter for closed fracture',
  'S42301B Unspecified fracture of shaft of humerus, right arm, initial encounter for open fracture',
  'S42301D Unspecified fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'routine healing',
  'S42301G Unspecified fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'delayed healing',
  'S42301K Unspecified fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'nonunion',
  'S42301P Unspecified fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'malunion',
  'S42301S Unspecified fracture of shaft of humerus, right arm, sequela',
  'S42302A Unspecified fracture of shaft of humerus, left arm, initial encounter for closed fracture',
  'S42302B Unspecified fracture of shaft of humerus, left arm, initial encounter for open fracture',
  'S42302D Unspecified fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'routine healing',
  'S42302G Unspecified fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'delayed healing',
  'S42302K Unspecified fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'nonunion',
  'S42302P Unspecified fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'malunion',
  'S42302S Unspecified fracture of shaft of humerus, left arm, sequela',
  'S42309A Unspecified fracture of shaft of humerus, unspecified arm, initial encounter for closed fracture',
  'S42309B Unspecified fracture of shaft of humerus, unspecified arm, initial encounter for open fracture',
  'S42309D Unspecified fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with routine healing',
  'S42309G Unspecified fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42309K Unspecified fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with nonunion',
  'S42309P Unspecified fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with malunion',
  'S42309S Unspecified fracture of shaft of humerus, unspecified arm, sequela',
  'S42311A Greenstick fracture of shaft of humerus, right arm, initial encounter for closed fracture',
  'S42311D Greenstick fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'routine healing',
  'S42311G Greenstick fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'delayed healing',
  'S42311K Greenstick fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'nonunion',
  'S42311P Greenstick fracture of shaft of humerus, right arm, subsequent encounter for fracture with',
  'malunion',
  'S42311S Greenstick fracture of shaft of humerus, right arm, sequela',
  'S42312A Greenstick fracture of shaft of humerus, left arm, initial encounter for closed fracture',
  'S42312D Greenstick fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'routine healing',
  'S42312G Greenstick fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'delayed healing',
  'S42312K Greenstick fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'nonunion',
  'S42312P Greenstick fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'malunion',
  'S42312S Greenstick fracture of shaft of humerus, left arm, sequela',
  'S42319A Greenstick fracture of shaft of humerus, unspecified arm, initial encounter for closed fracture',
  'S42319D Greenstick fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with routine healing',
  'S42319G Greenstick fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42319K Greenstick fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with nonunion',
  'S42319P Greenstick fracture of shaft of humerus, unspecified arm, subsequent encounter for fracture',
  'with malunion',
  'S42319S Greenstick fracture of shaft of humerus, unspecified arm, sequela',
  'S42321A Displaced transverse fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42321B Displaced transverse fracture of shaft of humerus, right arm, initial encounter for open fracture',
  'S42321D Displaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S42321G Displaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42321K Displaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S42321P Displaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S42321S Displaced transverse fracture of shaft of humerus, right arm, sequela',
  'S42322A Displaced transverse fracture of shaft of humerus, left arm, initial encounter for closed fracture',
  'S42322B Displaced transverse fracture of shaft of humerus, left arm, initial encounter for open fracture',
  'S42322D Displaced transverse fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S42322G Displaced transverse fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42322K Displaced transverse fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S42322P Displaced transverse fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with malunion',
  'S42322S Displaced transverse fracture of shaft of humerus, left arm, sequela',
  'S42323A Displaced transverse fracture of shaft of humerus, unspecified arm, initial encounter for closed',
  'fracture',
  'S42323B Displaced transverse fracture of shaft of humerus, unspecified arm, initial encounter for open',
  'fracture',
  'S42323D Displaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S42323G Displaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42323K Displaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S42323P Displaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S42323S Displaced transverse fracture of shaft of humerus, unspecified arm, sequela',
  'S42324A Nondisplaced transverse fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42324B Nondisplaced transverse fracture of shaft of humerus, right arm, initial encounter for open',
  'fracture',
  'S42324D Nondisplaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S42324G Nondisplaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42324K Nondisplaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S42324P Nondisplaced transverse fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S42324S Nondisplaced transverse fracture of shaft of humerus, right arm, sequela',
  'S42325A Nondisplaced transverse fracture of shaft of humerus, left arm, initial encounter for closed',
  'fracture',
  'S42325B Nondisplaced transverse fracture of shaft of humerus, left arm, initial encounter for open',
  'fracture',
  'S42325D Nondisplaced transverse fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S42325G Nondisplaced transverse fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42325K Nondisplaced transverse fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S42325P Nondisplaced transverse fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with malunion',
  'S42325S Nondisplaced transverse fracture of shaft of humerus, left arm, sequela',
  'S42326A Nondisplaced transverse fracture of shaft of humerus, unspecified arm, initial encounter for',
  'closed fracture',
  'S42326B Nondisplaced transverse fracture of shaft of humerus, unspecified arm, initial encounter for',
  'open fracture',
  'S42326D Nondisplaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with routine healing',
  'S42326G Nondisplaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with delayed healing',
  'S42326K Nondisplaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with nonunion',
  'S42326P Nondisplaced transverse fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with malunion',
  'S42326S Nondisplaced transverse fracture of shaft of humerus, unspecified arm, sequela',
  'S42331A Displaced oblique fracture of shaft of humerus, right arm, initial encounter for closed fracture',
  'S42331B Displaced oblique fracture of shaft of humerus, right arm, initial encounter for open fracture',
  'S42331D Displaced oblique fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with routine healing',
  'S42331G Displaced oblique fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42331K Displaced oblique fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S42331P Displaced oblique fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with malunion',
  'S42331S Displaced oblique fracture of shaft of humerus, right arm, sequela',
  'S42332A Displaced oblique fracture of shaft of humerus, left arm, initial encounter for closed fracture',
  'S42332B Displaced oblique fracture of shaft of humerus, left arm, initial encounter for open fracture',
  'S42332D Displaced oblique fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S42332G Displaced oblique fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42332K Displaced oblique fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S42332P Displaced oblique fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with malunion',
  'S42332S Displaced oblique fracture of shaft of humerus, left arm, sequela',
  'S42333A Displaced oblique fracture of shaft of humerus, unspecified arm, initial encounter for closed',
  'fracture',
  'S42333B Displaced oblique fracture of shaft of humerus, unspecified arm, initial encounter for open',
  'fracture',
  'S42333D Displaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S42333G Displaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42333K Displaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S42333P Displaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S42333S Displaced oblique fracture of shaft of humerus, unspecified arm, sequela',
  'S42334A Nondisplaced oblique fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42334B Nondisplaced oblique fracture of shaft of humerus, right arm, initial encounter for open',
  'fracture',
  'S42334D Nondisplaced oblique fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S42334G Nondisplaced oblique fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42334K Nondisplaced oblique fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S42334P Nondisplaced oblique fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S42334S Nondisplaced oblique fracture of shaft of humerus, right arm, sequela',
  'S42335A Nondisplaced oblique fracture of shaft of humerus, left arm, initial encounter for closed',
  'fracture',
  'S42335B Nondisplaced oblique fracture of shaft of humerus, left arm, initial encounter for open fracture',
  'S42335D Nondisplaced oblique fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S42335G Nondisplaced oblique fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42335K Nondisplaced oblique fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S42335P Nondisplaced oblique fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with malunion',
  'S42335S Nondisplaced oblique fracture of shaft of humerus, left arm, sequela',
  'S42336A Nondisplaced oblique fracture of shaft of humerus, unspecified arm, initial encounter for',
  'closed fracture',
  'S42336B Nondisplaced oblique fracture of shaft of humerus, unspecified arm, initial encounter for open',
  'fracture',
  'S42336D Nondisplaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S42336G Nondisplaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42336K Nondisplaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S42336P Nondisplaced oblique fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S42336S Nondisplaced oblique fracture of shaft of humerus, unspecified arm, sequela',
  'S42341A Displaced spiral fracture of shaft of humerus, right arm, initial encounter for closed fracture',
  'S42341B Displaced spiral fracture of shaft of humerus, right arm, initial encounter for open fracture',
  'S42341D Displaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with routine healing',
  'S42341G Displaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42341K Displaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S42341P Displaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with malunion',
  'S42341S Displaced spiral fracture of shaft of humerus, right arm, sequela',
  'S42342A Displaced spiral fracture of shaft of humerus, left arm, initial encounter for closed fracture',
  'S42342B Displaced spiral fracture of shaft of humerus, left arm, initial encounter for open fracture',
  'S42342D Displaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'routine healing',
  'S42342G Displaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'delayed healing',
  'S42342K Displaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'nonunion',
  'S42342P Displaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture with',
  'malunion',
  'S42342S Displaced spiral fracture of shaft of humerus, left arm, sequela',
  'S42343A Displaced spiral fracture of shaft of humerus, unspecified arm, initial encounter for closed',
  'fracture',
  'S42343B Displaced spiral fracture of shaft of humerus, unspecified arm, initial encounter for open',
  'fracture',
  'S42343D Displaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S42343G Displaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42343K Displaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S42343P Displaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S42343S Displaced spiral fracture of shaft of humerus, unspecified arm, sequela',
  'S42344A Nondisplaced spiral fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42344B Nondisplaced spiral fracture of shaft of humerus, right arm, initial encounter for open fracture',
  'S42344D Nondisplaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with routine healing',
  'S42344G Nondisplaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42344K Nondisplaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S42344P Nondisplaced spiral fracture of shaft of humerus, right arm, subsequent encounter for fracture',
  'with malunion',
  'S42344S Nondisplaced spiral fracture of shaft of humerus, right arm, sequela',
  'S42345A Nondisplaced spiral fracture of shaft of humerus, left arm, initial encounter for closed fracture',
  'S42345B Nondisplaced spiral fracture of shaft of humerus, left arm, initial encounter for open fracture',
  'S42345D Nondisplaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S42345G Nondisplaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42345K Nondisplaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S42345P Nondisplaced spiral fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with malunion',
  'S42345S Nondisplaced spiral fracture of shaft of humerus, left arm, sequela',
  'S42346A Nondisplaced spiral fracture of shaft of humerus, unspecified arm, initial encounter for closed',
  'fracture',
  'S42346B Nondisplaced spiral fracture of shaft of humerus, unspecified arm, initial encounter for open',
  'fracture',
  'S42346D Nondisplaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S42346G Nondisplaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42346K Nondisplaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S42346P Nondisplaced spiral fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S42346S Nondisplaced spiral fracture of shaft of humerus, unspecified arm, sequela',
  'S42351A Displaced comminuted fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42351B Displaced comminuted fracture of shaft of humerus, right arm, initial encounter for open',
  'fracture',
  'S42351D Displaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S42351G Displaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42351K Displaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S42351P Displaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S42351S Displaced comminuted fracture of shaft of humerus, right arm, sequela',
  'S42352A Displaced comminuted fracture of shaft of humerus, left arm, initial encounter for closed',
  'fracture',
  'S42352B Displaced comminuted fracture of shaft of humerus, left arm, initial encounter for open',
  'fracture',
  'S42352D Displaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S42352G Displaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42352K Displaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S42352P Displaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with malunion',
  'S42352S Displaced comminuted fracture of shaft of humerus, left arm, sequela',
  'S42353A Displaced comminuted fracture of shaft of humerus, unspecified arm, initial encounter for',
  'closed fracture',
  'S42353B Displaced comminuted fracture of shaft of humerus, unspecified arm, initial encounter for',
  'open fracture',
  'S42353D Displaced comminuted fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with routine healing',
  'S42353G Displaced comminuted fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with delayed healing',
  'S42353K Displaced comminuted fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with nonunion',
  'S42353P Displaced comminuted fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with malunion',
  'S42353S Displaced comminuted fracture of shaft of humerus, unspecified arm, sequela',
  'S42354A Nondisplaced comminuted fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42354B Nondisplaced comminuted fracture of shaft of humerus, right arm, initial encounter for open',
  'fracture',
  'S42354D Nondisplaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S42354G Nondisplaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42354K Nondisplaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S42354P Nondisplaced comminuted fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S42354S Nondisplaced comminuted fracture of shaft of humerus, right arm, sequela',
  'S42355A Nondisplaced comminuted fracture of shaft of humerus, left arm, initial encounter for closed',
  'fracture',
  'S42355B Nondisplaced comminuted fracture of shaft of humerus, left arm, initial encounter for open',
  'fracture',
  'S42355D Nondisplaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S42355G Nondisplaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42355K Nondisplaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S42355P Nondisplaced comminuted fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with malunion',
  'S42355S Nondisplaced comminuted fracture of shaft of humerus, left arm, sequela',
  'S42356A Nondisplaced comminuted fracture of shaft of humerus, unspecified arm, initial encounter for',
  'closed fracture',
  'S42356B Nondisplaced comminuted fracture of shaft of humerus, unspecified arm, initial encounter for',
  'open fracture',
  'S42356D Nondisplaced comminuted fracture of shaft of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S42356G Nondisplaced comminuted fracture of shaft of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S42356K Nondisplaced comminuted fracture of shaft of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S42356P Nondisplaced comminuted fracture of shaft of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S42356S Nondisplaced comminuted fracture of shaft of humerus, unspecified arm, sequela',
  'S42361A Displaced segmental fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42361B Displaced segmental fracture of shaft of humerus, right arm, initial encounter for open fracture',
  'S42361D Displaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S42361G Displaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42361K Displaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S42361P Displaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S42361S Displaced segmental fracture of shaft of humerus, right arm, sequela',
  'S42362A Displaced segmental fracture of shaft of humerus, left arm, initial encounter for closed fracture',
  'S42362B Displaced segmental fracture of shaft of humerus, left arm, initial encounter for open fracture',
  'S42362D Displaced segmental fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S42362G Displaced segmental fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S42362K Displaced segmental fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S42362P Displaced segmental fracture of shaft of humerus, left arm, subsequent encounter for fracture',
  'with malunion',
  'S42362S Displaced segmental fracture of shaft of humerus, left arm, sequela',
  'S42363A Displaced segmental fracture of shaft of humerus, unspecified arm, initial encounter for closed',
  'fracture',
  'S42363B Displaced segmental fracture of shaft of humerus, unspecified arm, initial encounter for open',
  'fracture',
  'S42363D Displaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S42363G Displaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42363K Displaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S42363P Displaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S42363S Displaced segmental fracture of shaft of humerus, unspecified arm, sequela',
  'S42364A Nondisplaced segmental fracture of shaft of humerus, right arm, initial encounter for closed',
  'fracture',
  'S42364B Nondisplaced segmental fracture of shaft of humerus, right arm, initial encounter for open',
  'fracture',
  'S42364D Nondisplaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S42364G Nondisplaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42364K Nondisplaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S42364P Nondisplaced segmental fracture of shaft of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S42364S Nondisplaced segmental fracture of shaft of humerus, right arm, sequela',
  'S42365A Nondisplaced segmental fracture of shaft of humerus, left arm, initial encounter for closed',
  'fracture',
  'S42365B Nondisplaced segmental fracture of shaft of humerus, left arm, initial encounter for open',
  'fracture',
  'S42365D Nondisplaced segmental fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S42365G Nondisplaced segmental fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S42365K Nondisplaced segmental fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S42365P Nondisplaced segmental fracture of shaft of humerus, left arm, subsequent encounter for',
  'fracture with malunion',
  'S42365S Nondisplaced segmental fracture of shaft of humerus, left arm, sequela',
  'S42366A Nondisplaced segmental fracture of shaft of humerus, unspecified arm, initial encounter for',
  'closed fracture',
  'S42366B Nondisplaced segmental fracture of shaft of humerus, unspecified arm, initial encounter for',
  'open fracture',
  'S42366D Nondisplaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with routine healing',
  'S42366G Nondisplaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with delayed healing',
  'S42366K Nondisplaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with nonunion',
  'S42366P Nondisplaced segmental fracture of shaft of humerus, unspecified arm, subsequent encounter',
  'for fracture with malunion',
  'S42366S Nondisplaced segmental fracture of shaft of humerus, unspecified arm, sequela',
  'S42391A Other fracture of shaft of right humerus, initial encounter for closed fracture',
  'S42391B Other fracture of shaft of right humerus, initial encounter for open fracture',
  'S42391D Other fracture of shaft of right humerus, subsequent encounter for fracture with routine',
  'healing',
  'S42391G Other fracture of shaft of right humerus, subsequent encounter for fracture with delayed',
  'healing',
  'S42391K Other fracture of shaft of right humerus, subsequent encounter for fracture with nonunion',
  'S42391P Other fracture of shaft of right humerus, subsequent encounter for fracture with malunion',
  'S42391S Other fracture of shaft of right humerus, sequela',
  'S42392A Other fracture of shaft of left humerus, initial encounter for closed fracture',
  'S42392B Other fracture of shaft of left humerus, initial encounter for open fracture',
  'S42392D Other fracture of shaft of left humerus, subsequent encounter for fracture with routine healing',
  'S42392G Other fracture of shaft of left humerus, subsequent encounter for fracture with delayed',
  'healing',
  'S42392K Other fracture of shaft of left humerus, subsequent encounter for fracture with nonunion',
  'S42392P Other fracture of shaft of left humerus, subsequent encounter for fracture with malunion',
  'S42392S Other fracture of shaft of left humerus, sequela',
  'S42399A Other fracture of shaft of unspecified humerus, initial encounter for closed fracture',
  'S42399B Other fracture of shaft of unspecified humerus, initial encounter for open fracture',
  'S42399D Other fracture of shaft of unspecified humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42399G Other fracture of shaft of unspecified humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42399K Other fracture of shaft of unspecified humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42399P Other fracture of shaft of unspecified humerus, subsequent encounter for fracture with',
  'malunion',
  'S42399S Other fracture of shaft of unspecified humerus, sequela',
  'S42401A Unspecified fracture of lower end of right humerus, initial encounter for closed fracture',
  'S42401B Unspecified fracture of lower end of right humerus, initial encounter for open fracture',
  'S42401D Unspecified fracture of lower end of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42401G Unspecified fracture of lower end of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42401K Unspecified fracture of lower end of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42401P Unspecified fracture of lower end of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42401S Unspecified fracture of lower end of right humerus, sequela',
  'S42402A Unspecified fracture of lower end of left humerus, initial encounter for closed fracture',
  'S42402B Unspecified fracture of lower end of left humerus, initial encounter for open fracture',
  'S42402D Unspecified fracture of lower end of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42402G Unspecified fracture of lower end of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42402K Unspecified fracture of lower end of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42402P Unspecified fracture of lower end of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42402S Unspecified fracture of lower end of left humerus, sequela',
  'S42409A Unspecified fracture of lower end of unspecified humerus, initial encounter for closed fracture',
  'S42409B Unspecified fracture of lower end of unspecified humerus, initial encounter for open fracture',
  'S42409D Unspecified fracture of lower end of unspecified humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42409G Unspecified fracture of lower end of unspecified humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42409K Unspecified fracture of lower end of unspecified humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42409P Unspecified fracture of lower end of unspecified humerus, subsequent encounter for fracture',
  'with malunion',
  'S42409S Unspecified fracture of lower end of unspecified humerus, sequela',
  'S42411A Displaced simple supracondylar fracture without intercondylar fracture of right humerus, initial',
  'encounter for closed fracture',
  'S42411B Displaced simple supracondylar fracture without intercondylar fracture of right humerus, initial',
  'encounter for open fracture',
  'S42411D Displaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with routine healing',
  'S42411G Displaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with delayed healing',
  'S42411K Displaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with nonunion',
  'S42411P Displaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with malunion',
  'S42411S Displaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'sequela',
  'S42412A Displaced simple supracondylar fracture without intercondylar fracture of left humerus, initial',
  'encounter for closed fracture',
  'S42412B Displaced simple supracondylar fracture without intercondylar fracture of left humerus, initial',
  'encounter for open fracture',
  'S42412D Displaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with routine healing',
  'S42412G Displaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with delayed healing',
  'S42412K Displaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with nonunion',
  'S42412P Displaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with malunion',
  'S42412S Displaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'sequela',
  'S42413A Displaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, initial encounter for closed fracture',
  'S42413B Displaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, initial encounter for open fracture',
  'S42413D Displaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with routine healing',
  'S42413G Displaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with delayed healing',
  'S42413K Displaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with nonunion',
  'S42413P Displaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with malunion',
  'S42413S Displaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, sequela',
  'S42414A Nondisplaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'initial encounter for closed fracture',
  'S42414B Nondisplaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'initial encounter for open fracture',
  'S42414D Nondisplaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with routine healing',
  'S42414G Nondisplaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with delayed healing',
  'S42414K Nondisplaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with nonunion',
  'S42414P Nondisplaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with malunion',
  'S42414S Nondisplaced simple supracondylar fracture without intercondylar fracture of right humerus,',
  'sequela',
  'S42415A Nondisplaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'initial encounter for closed fracture',
  'S42415B Nondisplaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'initial encounter for open fracture',
  'S42415D Nondisplaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with routine healing',
  'S42415G Nondisplaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with delayed healing',
  'S42415K Nondisplaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with nonunion',
  'S42415P Nondisplaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with malunion',
  'S42415S Nondisplaced simple supracondylar fracture without intercondylar fracture of left humerus,',
  'sequela',
  'S42416A Nondisplaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, initial encounter for closed fracture',
  'S42416B Nondisplaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, initial encounter for open fracture',
  'S42416D Nondisplaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with routine healing',
  'S42416G Nondisplaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with delayed healing',
  'S42416K Nondisplaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with nonunion',
  'S42416P Nondisplaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with malunion',
  'S42416S Nondisplaced simple supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, sequela',
  'S42421A Displaced comminuted supracondylar fracture without intercondylar fracture of right humerus,',
  'initial encounter for closed fracture',
  'S42421B Displaced comminuted supracondylar fracture without intercondylar fracture of right humerus,',
  'initial encounter for open fracture',
  'S42421D Displaced comminuted supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with routine healing',
  'S42421G Displaced comminuted supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with delayed healing',
  'S42421K Displaced comminuted supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with nonunion',
  'S42421P Displaced comminuted supracondylar fracture without intercondylar fracture of right humerus,',
  'subsequent encounter for fracture with malunion',
  'S42421S Displaced comminuted supracondylar fracture without intercondylar fracture of right humerus,',
  'sequela',
  'S42422A Displaced comminuted supracondylar fracture without intercondylar fracture of left humerus,',
  'initial encounter for closed fracture',
  'S42422B Displaced comminuted supracondylar fracture without intercondylar fracture of left humerus,',
  'initial encounter for open fracture',
  'S42422D Displaced comminuted supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with routine healing',
  'S42422G Displaced comminuted supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with delayed healing',
  'S42422K Displaced comminuted supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with nonunion',
  'S42422P Displaced comminuted supracondylar fracture without intercondylar fracture of left humerus,',
  'subsequent encounter for fracture with malunion',
  'S42422S Displaced comminuted supracondylar fracture without intercondylar fracture of left humerus,',
  'sequela',
  'S42423A Displaced comminuted supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, initial encounter for closed fracture',
  'S42423B Displaced comminuted supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, initial encounter for open fracture',
  'S42423D Displaced comminuted supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with routine healing',
  'S42423G Displaced comminuted supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with delayed healing',
  'S42423K Displaced comminuted supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with nonunion',
  'S42423P Displaced comminuted supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, subsequent encounter for fracture with malunion',
  'S42423S Displaced comminuted supracondylar fracture without intercondylar fracture of unspecified',
  'humerus, sequela',
  'S42424A Nondisplaced comminuted supracondylar fracture without intercondylar fracture of right',
  'humerus, initial encounter for closed fracture',
  'S42424B Nondisplaced comminuted supracondylar fracture without intercondylar fracture of right',
  'humerus, initial encounter for open fracture',
  'S42424D Nondisplaced comminuted supracondylar fracture without intercondylar fracture of right',
  'humerus, subsequent encounter for fracture with routine healing',
  'S42424G Nondisplaced comminuted supracondylar fracture without intercondylar fracture of right',
  'humerus, subsequent encounter for fracture with delayed healing',
  'S42424K Nondisplaced comminuted supracondylar fracture without intercondylar fracture of right',
  'humerus, subsequent encounter for fracture with nonunion',
  'S42424P Nondisplaced comminuted supracondylar fracture without intercondylar fracture of right',
  'humerus, subsequent encounter for fracture with malunion',
  'S42424S Nondisplaced comminuted supracondylar fracture without intercondylar fracture of right',
  'humerus, sequela',
  'S42425A Nondisplaced comminuted supracondylar fracture without intercondylar fracture of left',
  'humerus, initial encounter for closed fracture',
  'S42425B Nondisplaced comminuted supracondylar fracture without intercondylar fracture of left',
  'humerus, initial encounter for open fracture',
  'S42425D Nondisplaced comminuted supracondylar fracture without intercondylar fracture of left',
  'humerus, subsequent encounter for fracture with routine healing',
  'S42425G Nondisplaced comminuted supracondylar fracture without intercondylar fracture of left',
  'humerus, subsequent encounter for fracture with delayed healing',
  'S42425K Nondisplaced comminuted supracondylar fracture without intercondylar fracture of left',
  'humerus, subsequent encounter for fracture with nonunion',
  'S42425P Nondisplaced comminuted supracondylar fracture without intercondylar fracture of left',
  'humerus, subsequent encounter for fracture with malunion',
  'S42425S Nondisplaced comminuted supracondylar fracture without intercondylar fracture of left',
  'humerus, sequela',
  'S42426A Nondisplaced comminuted supracondylar fracture without intercondylar fracture of',
  'unspecified humerus, initial encounter for closed fracture',
  'S42426B Nondisplaced comminuted supracondylar fracture without intercondylar fracture of',
  'unspecified humerus, initial encounter for open fracture',
  'S42426D Nondisplaced comminuted supracondylar fracture without intercondylar fracture of',
  'unspecified humerus, subsequent encounter for fracture with routine healing',
  'S42426G Nondisplaced comminuted supracondylar fracture without intercondylar fracture of',
  'unspecified humerus, subsequent encounter for fracture with delayed healing',
  'S42426K Nondisplaced comminuted supracondylar fracture without intercondylar fracture of',
  'unspecified humerus, subsequent encounter for fracture with nonunion',
  'S42426P Nondisplaced comminuted supracondylar fracture without intercondylar fracture of',
  'unspecified humerus, subsequent encounter for fracture with malunion',
  'S42426S Nondisplaced comminuted supracondylar fracture without intercondylar fracture of',
  'unspecified humerus, sequela',
  'S42431A Displaced fracture (avulsion) of lateral epicondyle of right humerus, initial encounter for closed',
  'fracture',
  'S42431B Displaced fracture (avulsion) of lateral epicondyle of right humerus, initial encounter for open',
  'fracture',
  'S42431D Displaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42431G Displaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42431K Displaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42431P Displaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter for',
  'fracture with malunion',
  'S42431S Displaced fracture (avulsion) of lateral epicondyle of right humerus, sequela',
  'S42432A Displaced fracture (avulsion) of lateral epicondyle of left humerus, initial encounter for closed',
  'fracture',
  'S42432B Displaced fracture (avulsion) of lateral epicondyle of left humerus, initial encounter for open',
  'fracture',
  'S42432D Displaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42432G Displaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42432K Displaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42432P Displaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter for',
  'fracture with malunion',
  'S42432S Displaced fracture (avulsion) of lateral epicondyle of left humerus, sequela',
  'S42433A Displaced fracture (avulsion) of lateral epicondyle of unspecified humerus, initial encounter for',
  'closed fracture',
  'S42433B Displaced fracture (avulsion) of lateral epicondyle of unspecified humerus, initial encounter for',
  'open fracture',
  'S42433D Displaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with routine healing',
  'S42433G Displaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with delayed healing',
  'S42433K Displaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'S42433P Displaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'S42433S Displaced fracture (avulsion) of lateral epicondyle of unspecified humerus, sequela',
  'S42434A Nondisplaced fracture (avulsion) of lateral epicondyle of right humerus, initial encounter for',
  'closed fracture',
  'S42434B Nondisplaced fracture (avulsion) of lateral epicondyle of right humerus, initial encounter for',
  'open fracture',
  'S42434D Nondisplaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42434G Nondisplaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42434K Nondisplaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42434P Nondisplaced fracture (avulsion) of lateral epicondyle of right humerus, subsequent encounter',
  'for fracture with malunion',
  'S42434S Nondisplaced fracture (avulsion) of lateral epicondyle of right humerus, sequela',
  'S42435A Nondisplaced fracture (avulsion) of lateral epicondyle of left humerus, initial encounter for',
  'closed fracture',
  'S42435B Nondisplaced fracture (avulsion) of lateral epicondyle of left humerus, initial encounter for',
  'open fracture',
  'S42435D Nondisplaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42435G Nondisplaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42435K Nondisplaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42435P Nondisplaced fracture (avulsion) of lateral epicondyle of left humerus, subsequent encounter',
  'for fracture with malunion',
  'S42435S Nondisplaced fracture (avulsion) of lateral epicondyle of left humerus, sequela',
  'S42436A Nondisplaced fracture (avulsion) of lateral epicondyle of unspecified humerus, initial encounter',
  'for closed fracture',
  'S42436B Nondisplaced fracture (avulsion) of lateral epicondyle of unspecified humerus, initial encounter',
  'for open fracture',
  'S42436D Nondisplaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with routine healing',
  'S42436G Nondisplaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with delayed healing',
  'S42436K Nondisplaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'S42436P Nondisplaced fracture (avulsion) of lateral epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'S42436S Nondisplaced fracture (avulsion) of lateral epicondyle of unspecified humerus, sequela',
  'S42441A Displaced fracture (avulsion) of medial epicondyle of right humerus, initial encounter for closed',
  'fracture',
  'S42441B Displaced fracture (avulsion) of medial epicondyle of right humerus, initial encounter for open',
  'fracture',
  'S42441D Displaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42441G Displaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42441K Displaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42441P Displaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter for',
  'fracture with malunion',
  'S42441S Displaced fracture (avulsion) of medial epicondyle of right humerus, sequela',
  'S42442A Displaced fracture (avulsion) of medial epicondyle of left humerus, initial encounter for closed',
  'fracture',
  'S42442B Displaced fracture (avulsion) of medial epicondyle of left humerus, initial encounter for open',
  'fracture',
  'S42442D Displaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42442G Displaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42442K Displaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42442P Displaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter for',
  'fracture with malunion',
  'S42442S Displaced fracture (avulsion) of medial epicondyle of left humerus, sequela',
  'S42443A Displaced fracture (avulsion) of medial epicondyle of unspecified humerus, initial encounter for',
  'closed fracture',
  'S42443B Displaced fracture (avulsion) of medial epicondyle of unspecified humerus, initial encounter for',
  'open fracture',
  'S42443D Displaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with routine healing',
  'S42443G Displaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with delayed healing',
  'S42443K Displaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'S42443P Displaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'S42443S Displaced fracture (avulsion) of medial epicondyle of unspecified humerus, sequela',
  'S42444A Nondisplaced fracture (avulsion) of medial epicondyle of right humerus, initial encounter for',
  'closed fracture',
  'S42444B Nondisplaced fracture (avulsion) of medial epicondyle of right humerus, initial encounter for',
  'open fracture',
  'S42444D Nondisplaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42444G Nondisplaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42444K Nondisplaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42444P Nondisplaced fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with malunion',
  'S42444S Nondisplaced fracture (avulsion) of medial epicondyle of right humerus, sequela',
  'S42445A Nondisplaced fracture (avulsion) of medial epicondyle of left humerus, initial encounter for',
  'closed fracture',
  'S42445B Nondisplaced fracture (avulsion) of medial epicondyle of left humerus, initial encounter for',
  'open fracture',
  'S42445D Nondisplaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42445G Nondisplaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42445K Nondisplaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42445P Nondisplaced fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with malunion',
  'S42445S Nondisplaced fracture (avulsion) of medial epicondyle of left humerus, sequela',
  'S42446A Nondisplaced fracture (avulsion) of medial epicondyle of unspecified humerus, initial',
  'encounter for closed fracture',
  'S42446B Nondisplaced fracture (avulsion) of medial epicondyle of unspecified humerus, initial',
  'encounter for open fracture',
  'S42446D Nondisplaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with routine healing',
  'S42446G Nondisplaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with delayed healing',
  'S42446K Nondisplaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'S42446P Nondisplaced fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'S42446S Nondisplaced fracture (avulsion) of medial epicondyle of unspecified humerus, sequela',
  'S42447A Incarcerated fracture (avulsion) of medial epicondyle of right humerus, initial encounter for',
  'closed fracture',
  'S42447B Incarcerated fracture (avulsion) of medial epicondyle of right humerus, initial encounter for',
  'open fracture',
  'S42447D Incarcerated fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42447G Incarcerated fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42447K Incarcerated fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42447P Incarcerated fracture (avulsion) of medial epicondyle of right humerus, subsequent encounter',
  'for fracture with malunion',
  'S42447S Incarcerated fracture (avulsion) of medial epicondyle of right humerus, sequela',
  'S42448A Incarcerated fracture (avulsion) of medial epicondyle of left humerus, initial encounter for',
  'closed fracture',
  'S42448B Incarcerated fracture (avulsion) of medial epicondyle of left humerus, initial encounter for',
  'open fracture',
  'S42448D Incarcerated fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with routine healing',
  'S42448G Incarcerated fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with delayed healing',
  'S42448K Incarcerated fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with nonunion',
  'S42448P Incarcerated fracture (avulsion) of medial epicondyle of left humerus, subsequent encounter',
  'for fracture with malunion',
  'S42448S Incarcerated fracture (avulsion) of medial epicondyle of left humerus, sequela',
  'S42449A Incarcerated fracture (avulsion) of medial epicondyle of unspecified humerus, initial encounter',
  'for closed fracture',
  'S42449B Incarcerated fracture (avulsion) of medial epicondyle of unspecified humerus, initial encounter',
  'for open fracture',
  'S42449D Incarcerated fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with routine healing',
  'S42449G Incarcerated fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with delayed healing',
  'S42449K Incarcerated fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with nonunion',
  'S42449P Incarcerated fracture (avulsion) of medial epicondyle of unspecified humerus, subsequent',
  'encounter for fracture with malunion',
  'S42449S Incarcerated fracture (avulsion) of medial epicondyle of unspecified humerus, sequela',
  'S42451A Displaced fracture of lateral condyle of right humerus, initial encounter for closed fracture',
  'S42451B Displaced fracture of lateral condyle of right humerus, initial encounter for open fracture',
  'S42451D Displaced fracture of lateral condyle of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42451G Displaced fracture of lateral condyle of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42451K Displaced fracture of lateral condyle of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42451P Displaced fracture of lateral condyle of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42451S Displaced fracture of lateral condyle of right humerus, sequela',
  'S42452A Displaced fracture of lateral condyle of left humerus, initial encounter for closed fracture',
  'S42452B Displaced fracture of lateral condyle of left humerus, initial encounter for open fracture',
  'S42452D Displaced fracture of lateral condyle of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42452G Displaced fracture of lateral condyle of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42452K Displaced fracture of lateral condyle of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42452P Displaced fracture of lateral condyle of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42452S Displaced fracture of lateral condyle of left humerus, sequela',
  'S42453A Displaced fracture of lateral condyle of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42453B Displaced fracture of lateral condyle of unspecified humerus, initial encounter for open',
  'fracture',
  'S42453D Displaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42453G Displaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42453K Displaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42453P Displaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42453S Displaced fracture of lateral condyle of unspecified humerus, sequela',
  'S42454A Nondisplaced fracture of lateral condyle of right humerus, initial encounter for closed fracture',
  'S42454B Nondisplaced fracture of lateral condyle of right humerus, initial encounter for open fracture',
  'S42454D Nondisplaced fracture of lateral condyle of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42454G Nondisplaced fracture of lateral condyle of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42454K Nondisplaced fracture of lateral condyle of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42454P Nondisplaced fracture of lateral condyle of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42454S Nondisplaced fracture of lateral condyle of right humerus, sequela',
  'S42455A Nondisplaced fracture of lateral condyle of left humerus, initial encounter for closed fracture',
  'S42455B Nondisplaced fracture of lateral condyle of left humerus, initial encounter for open fracture',
  'S42455D Nondisplaced fracture of lateral condyle of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42455G Nondisplaced fracture of lateral condyle of left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42455K Nondisplaced fracture of lateral condyle of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42455P Nondisplaced fracture of lateral condyle of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42455S Nondisplaced fracture of lateral condyle of left humerus, sequela',
  'S42456A Nondisplaced fracture of lateral condyle of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42456B Nondisplaced fracture of lateral condyle of unspecified humerus, initial encounter for open',
  'fracture',
  'S42456D Nondisplaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42456G Nondisplaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42456K Nondisplaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42456P Nondisplaced fracture of lateral condyle of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42456S Nondisplaced fracture of lateral condyle of unspecified humerus, sequela',
  'S42461A Displaced fracture of medial condyle of right humerus, initial encounter for closed fracture',
  'S42461B Displaced fracture of medial condyle of right humerus, initial encounter for open fracture',
  'S42461D Displaced fracture of medial condyle of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42461G Displaced fracture of medial condyle of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42461K Displaced fracture of medial condyle of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42461P Displaced fracture of medial condyle of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42461S Displaced fracture of medial condyle of right humerus, sequela',
  'S42462A Displaced fracture of medial condyle of left humerus, initial encounter for closed fracture',
  'S42462B Displaced fracture of medial condyle of left humerus, initial encounter for open fracture',
  'S42462D Displaced fracture of medial condyle of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42462G Displaced fracture of medial condyle of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42462K Displaced fracture of medial condyle of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42462P Displaced fracture of medial condyle of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42462S Displaced fracture of medial condyle of left humerus, sequela',
  'S42463A Displaced fracture of medial condyle of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42463B Displaced fracture of medial condyle of unspecified humerus, initial encounter for open',
  'fracture',
  'S42463D Displaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42463G Displaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42463K Displaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42463P Displaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42463S Displaced fracture of medial condyle of unspecified humerus, sequela',
  'S42464A Nondisplaced fracture of medial condyle of right humerus, initial encounter for closed fracture',
  'S42464B Nondisplaced fracture of medial condyle of right humerus, initial encounter for open fracture',
  'S42464D Nondisplaced fracture of medial condyle of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42464G Nondisplaced fracture of medial condyle of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42464K Nondisplaced fracture of medial condyle of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42464P Nondisplaced fracture of medial condyle of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42464S Nondisplaced fracture of medial condyle of right humerus, sequela',
  'S42465A Nondisplaced fracture of medial condyle of left humerus, initial encounter for closed fracture',
  'S42465B Nondisplaced fracture of medial condyle of left humerus, initial encounter for open fracture',
  'S42465D Nondisplaced fracture of medial condyle of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42465G Nondisplaced fracture of medial condyle of left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42465K Nondisplaced fracture of medial condyle of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42465P Nondisplaced fracture of medial condyle of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42465S Nondisplaced fracture of medial condyle of left humerus, sequela',
  'S42466A Nondisplaced fracture of medial condyle of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42466B Nondisplaced fracture of medial condyle of unspecified humerus, initial encounter for open',
  'fracture',
  'S42466D Nondisplaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42466G Nondisplaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42466K Nondisplaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42466P Nondisplaced fracture of medial condyle of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42466S Nondisplaced fracture of medial condyle of unspecified humerus, sequela',
  'S42471A Displaced transcondylar fracture of right humerus, initial encounter for closed fracture',
  'S42471B Displaced transcondylar fracture of right humerus, initial encounter for open fracture',
  'S42471D Displaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42471G Displaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42471K Displaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42471P Displaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42471S Displaced transcondylar fracture of right humerus, sequela',
  'S42472A Displaced transcondylar fracture of left humerus, initial encounter for closed fracture',
  'S42472B Displaced transcondylar fracture of left humerus, initial encounter for open fracture',
  'S42472D Displaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42472G Displaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42472K Displaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42472P Displaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42472S Displaced transcondylar fracture of left humerus, sequela',
  'S42473A Displaced transcondylar fracture of unspecified humerus, initial encounter for closed fracture',
  'S42473B Displaced transcondylar fracture of unspecified humerus, initial encounter for open fracture',
  'S42473D Displaced transcondylar fracture of unspecified humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42473G Displaced transcondylar fracture of unspecified humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42473K Displaced transcondylar fracture of unspecified humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42473P Displaced transcondylar fracture of unspecified humerus, subsequent encounter for fracture',
  'with malunion',
  'S42473S Displaced transcondylar fracture of unspecified humerus, sequela',
  'S42474A Nondisplaced transcondylar fracture of right humerus, initial encounter for closed fracture',
  'S42474B Nondisplaced transcondylar fracture of right humerus, initial encounter for open fracture',
  'S42474D Nondisplaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42474G Nondisplaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42474K Nondisplaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42474P Nondisplaced transcondylar fracture of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42474S Nondisplaced transcondylar fracture of right humerus, sequela',
  'S42475A Nondisplaced transcondylar fracture of left humerus, initial encounter for closed fracture',
  'S42475B Nondisplaced transcondylar fracture of left humerus, initial encounter for open fracture',
  'S42475D Nondisplaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42475G Nondisplaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42475K Nondisplaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42475P Nondisplaced transcondylar fracture of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42475S Nondisplaced transcondylar fracture of left humerus, sequela',
  'S42476A Nondisplaced transcondylar fracture of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42476B Nondisplaced transcondylar fracture of unspecified humerus, initial encounter for open',
  'fracture',
  'S42476D Nondisplaced transcondylar fracture of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42476G Nondisplaced transcondylar fracture of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42476K Nondisplaced transcondylar fracture of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42476P Nondisplaced transcondylar fracture of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42476S Nondisplaced transcondylar fracture of unspecified humerus, sequela',
  'S42481A Torus fracture of lower end of right humerus, initial encounter for closed fracture',
  'S42481D Torus fracture of lower end of right humerus, subsequent encounter for fracture with routine',
  'healing',
  'S42481G Torus fracture of lower end of right humerus, subsequent encounter for fracture with delayed',
  'healing',
  'S42481K Torus fracture of lower end of right humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42481P Torus fracture of lower end of right humerus, subsequent encounter for fracture with',
  'malunion',
  'S42481S Torus fracture of lower end of right humerus, sequela',
  'S42482A Torus fracture of lower end of left humerus, initial encounter for closed fracture',
  'S42482D Torus fracture of lower end of left humerus, subsequent encounter for fracture with routine',
  'healing',
  'S42482G Torus fracture of lower end of left humerus, subsequent encounter for fracture with delayed',
  'healing',
  'S42482K Torus fracture of lower end of left humerus, subsequent encounter for fracture with nonunion',
  'S42482P Torus fracture of lower end of left humerus, subsequent encounter for fracture with malunion',
  'S42482S Torus fracture of lower end of left humerus, sequela',
  'S42489A Torus fracture of lower end of unspecified humerus, initial encounter for closed fracture',
  'S42489D Torus fracture of lower end of unspecified humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42489G Torus fracture of lower end of unspecified humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42489K Torus fracture of lower end of unspecified humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42489P Torus fracture of lower end of unspecified humerus, subsequent encounter for fracture with',
  'malunion',
  'S42489S Torus fracture of lower end of unspecified humerus, sequela',
  'S42491A Other displaced fracture of lower end of right humerus, initial encounter for closed fracture',
  'S42491B Other displaced fracture of lower end of right humerus, initial encounter for open fracture',
  'S42491D Other displaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42491G Other displaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42491K Other displaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42491P Other displaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42491S Other displaced fracture of lower end of right humerus, sequela',
  'S42492A Other displaced fracture of lower end of left humerus, initial encounter for closed fracture',
  'S42492B Other displaced fracture of lower end of left humerus, initial encounter for open fracture',
  'S42492D Other displaced fracture of lower end of left humerus, subsequent encounter for fracture with',
  'routine healing',
  'S42492G Other displaced fracture of lower end of left humerus, subsequent encounter for fracture with',
  'delayed healing',
  'S42492K Other displaced fracture of lower end of left humerus, subsequent encounter for fracture with',
  'nonunion',
  'S42492P Other displaced fracture of lower end of left humerus, subsequent encounter for fracture with',
  'malunion',
  'S42492S Other displaced fracture of lower end of left humerus, sequela',
  'S42493A Other displaced fracture of lower end of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42493B Other displaced fracture of lower end of unspecified humerus, initial encounter for open',
  'fracture',
  'S42493D Other displaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42493G Other displaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42493K Other displaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42493P Other displaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42493S Other displaced fracture of lower end of unspecified humerus, sequela',
  'S42494A Other nondisplaced fracture of lower end of right humerus, initial encounter for closed',
  'fracture',
  'S42494B Other nondisplaced fracture of lower end of right humerus, initial encounter for open fracture',
  'S42494D Other nondisplaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42494G Other nondisplaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42494K Other nondisplaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42494P Other nondisplaced fracture of lower end of right humerus, subsequent encounter for fracture',
  'with malunion',
  'S42494S Other nondisplaced fracture of lower end of right humerus, sequela',
  'S42495A Other nondisplaced fracture of lower end of left humerus, initial encounter for closed fracture',
  'S42495B Other nondisplaced fracture of lower end of left humerus, initial encounter for open fracture',
  'S42495D Other nondisplaced fracture of lower end of left humerus, subsequent encounter for fracture',
  'with routine healing',
  'S42495G Other nondisplaced fracture of lower end of left humerus, subsequent encounter for fracture',
  'with delayed healing',
  'S42495K Other nondisplaced fracture of lower end of left humerus, subsequent encounter for fracture',
  'with nonunion',
  'S42495P Other nondisplaced fracture of lower end of left humerus, subsequent encounter for fracture',
  'with malunion',
  'S42495S Other nondisplaced fracture of lower end of left humerus, sequela',
  'S42496A Other nondisplaced fracture of lower end of unspecified humerus, initial encounter for closed',
  'fracture',
  'S42496B Other nondisplaced fracture of lower end of unspecified humerus, initial encounter for open',
  'fracture',
  'S42496D Other nondisplaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with routine healing',
  'S42496G Other nondisplaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with delayed healing',
  'S42496K Other nondisplaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with nonunion',
  'S42496P Other nondisplaced fracture of lower end of unspecified humerus, subsequent encounter for',
  'fracture with malunion',
  'S42496S Other nondisplaced fracture of lower end of unspecified humerus, sequela',
  'S4290XA Fracture of unspecified shoulder girdle, part unspecified, initial encounter for closed fracture',
  'S4290XB Fracture of unspecified shoulder girdle, part unspecified, initial encounter for open fracture',
  'S4290XD Fracture of unspecified shoulder girdle, part unspecified, subsequent encounter for fracture',
  'with routine healing',
  'S4290XG Fracture of unspecified shoulder girdle, part unspecified, subsequent encounter for fracture',
  'with delayed healing',
  'S4290XK Fracture of unspecified shoulder girdle, part unspecified, subsequent encounter for fracture',
  'with nonunion',
  'S4290XP Fracture of unspecified shoulder girdle, part unspecified, subsequent encounter for fracture',
  'with malunion',
  'S4290XS Fracture of unspecified shoulder girdle, part unspecified, sequela',
  'S4291XA Fracture of right shoulder girdle, part unspecified, initial encounter for closed fracture',
  'S4291XB Fracture of right shoulder girdle, part unspecified, initial encounter for open fracture',
  'S4291XD Fracture of right shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'routine healing',
  'S4291XG Fracture of right shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'delayed healing',
  'S4291XK Fracture of right shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'nonunion',
  'S4291XP Fracture of right shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'malunion',
  'S4291XS Fracture of right shoulder girdle, part unspecified, sequela',
  'S4292XA Fracture of left shoulder girdle, part unspecified, initial encounter for closed fracture',
  'S4292XB Fracture of left shoulder girdle, part unspecified, initial encounter for open fracture',
  'S4292XD Fracture of left shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'routine healing',
  'S4292XG Fracture of left shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'delayed healing',
  'S4292XK Fracture of left shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'nonunion',
  'S4292XP Fracture of left shoulder girdle, part unspecified, subsequent encounter for fracture with',
  'malunion',
  'S4292XS Fracture of left shoulder girdle, part unspecified, sequela',
  'S43001A Unspecified subluxation of right shoulder joint, initial encounter',
  'S43001D Unspecified subluxation of right shoulder joint, subsequent encounter',
  'S43001S Unspecified subluxation of right shoulder joint, sequela',
  'S43002A Unspecified subluxation of left shoulder joint, initial encounter',
  'S43002D Unspecified subluxation of left shoulder joint, subsequent encounter',
  'S43002S Unspecified subluxation of left shoulder joint, sequela',
  'S43003A Unspecified subluxation of unspecified shoulder joint, initial encounter',
  'S43003D Unspecified subluxation of unspecified shoulder joint, subsequent encounter',
  'S43003S Unspecified subluxation of unspecified shoulder joint, sequela',
  'S43004A Unspecified dislocation of right shoulder joint, initial encounter',
  'S43004D Unspecified dislocation of right shoulder joint, subsequent encounter',
  'S43004S Unspecified dislocation of right shoulder joint, sequela',
  'S43005A Unspecified dislocation of left shoulder joint, initial encounter',
  'S43005D Unspecified dislocation of left shoulder joint, subsequent encounter',
  'S43005S Unspecified dislocation of left shoulder joint, sequela',
  'S43006A Unspecified dislocation of unspecified shoulder joint, initial encounter',
  'S43006D Unspecified dislocation of unspecified shoulder joint, subsequent encounter',
  'S43006S Unspecified dislocation of unspecified shoulder joint, sequela',
  'S43011A Anterior subluxation of right humerus, initial encounter',
  'S43011D Anterior subluxation of right humerus, subsequent encounter',
  'S43011S Anterior subluxation of right humerus, sequela',
  'S43012A Anterior subluxation of left humerus, initial encounter',
  'S43012D Anterior subluxation of left humerus, subsequent encounter',
  'S43012S Anterior subluxation of left humerus, sequela',
  'S43013A Anterior subluxation of unspecified humerus, initial encounter',
  'S43013D Anterior subluxation of unspecified humerus, subsequent encounter',
  'S43013S Anterior subluxation of unspecified humerus, sequela',
  'S43014A Anterior dislocation of right humerus, initial encounter',
  'S43014D Anterior dislocation of right humerus, subsequent encounter',
  'S43014S Anterior dislocation of right humerus, sequela',
  'S43015A Anterior dislocation of left humerus, initial encounter',
  'S43015D Anterior dislocation of left humerus, subsequent encounter',
  'S43015S Anterior dislocation of left humerus, sequela',
  'S43016A Anterior dislocation of unspecified humerus, initial encounter',
  'S43016D Anterior dislocation of unspecified humerus, subsequent encounter',
  'S43016S Anterior dislocation of unspecified humerus, sequela',
  'S43021A Posterior subluxation of right humerus, initial encounter',
  'S43021D Posterior subluxation of right humerus, subsequent encounter',
  'S43021S Posterior subluxation of right humerus, sequela',
  'S43022A Posterior subluxation of left humerus, initial encounter',
  'S43022D Posterior subluxation of left humerus, subsequent encounter',
  'S43022S Posterior subluxation of left humerus, sequela',
  'S43023A Posterior subluxation of unspecified humerus, initial encounter',
  'S43023D Posterior subluxation of unspecified humerus, subsequent encounter',
  'S43023S Posterior subluxation of unspecified humerus, sequela',
  'S43024A Posterior dislocation of right humerus, initial encounter',
  'S43024D Posterior dislocation of right humerus, subsequent encounter',
  'S43024S Posterior dislocation of right humerus, sequela',
  'S43025A Posterior dislocation of left humerus, initial encounter',
  'S43025D Posterior dislocation of left humerus, subsequent encounter',
  'S43025S Posterior dislocation of left humerus, sequela',
  'S43026A Posterior dislocation of unspecified humerus, initial encounter',
  'S43026D Posterior dislocation of unspecified humerus, subsequent encounter',
  'S43026S Posterior dislocation of unspecified humerus, sequela',
  'S43031A Inferior subluxation of right humerus, initial encounter',
  'S43031D Inferior subluxation of right humerus, subsequent encounter',
  'S43031S Inferior subluxation of right humerus, sequela',
  'S43032A Inferior subluxation of left humerus, initial encounter',
  'S43032D Inferior subluxation of left humerus, subsequent encounter',
  'S43032S Inferior subluxation of left humerus, sequela',
  'S43033A Inferior subluxation of unspecified humerus, initial encounter',
  'S43033D Inferior subluxation of unspecified humerus, subsequent encounter',
  'S43033S Inferior subluxation of unspecified humerus, sequela',
  'S43034A Inferior dislocation of right humerus, initial encounter',
  'S43034D Inferior dislocation of right humerus, subsequent encounter',
  'S43034S Inferior dislocation of right humerus, sequela',
  'S43035A Inferior dislocation of left humerus, initial encounter',
  'S43035D Inferior dislocation of left humerus, subsequent encounter',
  'S43035S Inferior dislocation of left humerus, sequela',
  'S43036A Inferior dislocation of unspecified humerus, initial encounter',
  'S43036D Inferior dislocation of unspecified humerus, subsequent encounter',
  'S43036S Inferior dislocation of unspecified humerus, sequela',
  'S43081A Other subluxation of right shoulder joint, initial encounter',
  'S43081D Other subluxation of right shoulder joint, subsequent encounter',
  'S43081S Other subluxation of right shoulder joint, sequela',
  'S43082A Other subluxation of left shoulder joint, initial encounter',
  'S43082D Other subluxation of left shoulder joint, subsequent encounter',
  'S43082S Other subluxation of left shoulder joint, sequela',
  'S43083A Other subluxation of unspecified shoulder joint, initial encounter',
  'S43083D Other subluxation of unspecified shoulder joint, subsequent encounter',
  'S43083S Other subluxation of unspecified shoulder joint, sequela',
  'S43084A Other dislocation of right shoulder joint, initial encounter',
  'S43084D Other dislocation of right shoulder joint, subsequent encounter',
  'S43084S Other dislocation of right shoulder joint, sequela',
  'S43085A Other dislocation of left shoulder joint, initial encounter',
  'S43085D Other dislocation of left shoulder joint, subsequent encounter',
  'S43085S Other dislocation of left shoulder joint, sequela',
  'S43086A Other dislocation of unspecified shoulder joint, initial encounter',
  'S43086D Other dislocation of unspecified shoulder joint, subsequent encounter',
  'S43086S Other dislocation of unspecified shoulder joint, sequela',
  'S43101A Unspecified dislocation of right acromioclavicular joint, initial encounter',
  'S43101D Unspecified dislocation of right acromioclavicular joint, subsequent encounter',
  'S43101S Unspecified dislocation of right acromioclavicular joint, sequela',
  'S43102A Unspecified dislocation of left acromioclavicular joint, initial encounter',
  'S43102D Unspecified dislocation of left acromioclavicular joint, subsequent encounter',
  'S43102S Unspecified dislocation of left acromioclavicular joint, sequela',
  'S43109A Unspecified dislocation of unspecified acromioclavicular joint, initial encounter',
  'S43109D Unspecified dislocation of unspecified acromioclavicular joint, subsequent encounter',
  'S43109S Unspecified dislocation of unspecified acromioclavicular joint, sequela',
  'S43111A Subluxation of right acromioclavicular joint, initial encounter',
  'S43111D Subluxation of right acromioclavicular joint, subsequent encounter',
  'S43111S Subluxation of right acromioclavicular joint, sequela',
  'S43112A Subluxation of left acromioclavicular joint, initial encounter',
  'S43112D Subluxation of left acromioclavicular joint, subsequent encounter',
  'S43112S Subluxation of left acromioclavicular joint, sequela',
  'S43119A Subluxation of unspecified acromioclavicular joint, initial encounter',
  'S43119D Subluxation of unspecified acromioclavicular joint, subsequent encounter',
  'S43119S Subluxation of unspecified acromioclavicular joint, sequela',
  'S43121A Dislocation of right acromioclavicular joint, 100%-200% displacement, initial encounter',
  'S43121D Dislocation of right acromioclavicular joint, 100%-200% displacement, subsequent encounter',
  'S43121S Dislocation of right acromioclavicular joint, 100%-200% displacement, sequela',
  'S43122A Dislocation of left acromioclavicular joint, 100%-200% displacement, initial encounter',
  'S43122D Dislocation of left acromioclavicular joint, 100%-200% displacement, subsequent encounter',
  'S43122S Dislocation of left acromioclavicular joint, 100%-200% displacement, sequela',
  'S43129A Dislocation of unspecified acromioclavicular joint, 100%-200% displacement, initial encounter',
  'S43129D Dislocation of unspecified acromioclavicular joint, 100%-200% displacement, subsequent',
  'encounter',
  'S43129S Dislocation of unspecified acromioclavicular joint, 100%-200% displacement, sequela',
  'S43131A Dislocation of right acromioclavicular joint, greater than 200% displacement, initial encounter',
  'S43131D Dislocation of right acromioclavicular joint, greater than 200% displacement, subsequent',
  'encounter',
  'S43131S Dislocation of right acromioclavicular joint, greater than 200% displacement, sequela',
  'S43132A Dislocation of left acromioclavicular joint, greater than 200% displacement, initial encounter',
  'S43132D Dislocation of left acromioclavicular joint, greater than 200% displacement, subsequent',
  'encounter',
  'S43132S Dislocation of left acromioclavicular joint, greater than 200% displacement, sequela',
  'S43139A Dislocation of unspecified acromioclavicular joint, greater than 200% displacement, initial',
  'encounter',
  'S43139D Dislocation of unspecified acromioclavicular joint, greater than 200% displacement,',
  'subsequent encounter',
  'S43139S Dislocation of unspecified acromioclavicular joint, greater than 200% displacement, sequela',
  'S43141A Inferior dislocation of right acromioclavicular joint, initial encounter',
  'S43141D Inferior dislocation of right acromioclavicular joint, subsequent encounter',
  'S43141S Inferior dislocation of right acromioclavicular joint, sequela',
  'S43142A Inferior dislocation of left acromioclavicular joint, initial encounter',
  'S43142D Inferior dislocation of left acromioclavicular joint, subsequent encounter',
  'S43142S Inferior dislocation of left acromioclavicular joint, sequela',
  'S43149A Inferior dislocation of unspecified acromioclavicular joint, initial encounter',
  'S43149D Inferior dislocation of unspecified acromioclavicular joint, subsequent encounter',
  'S43149S Inferior dislocation of unspecified acromioclavicular joint, sequela',
  'S43151A Posterior dislocation of right acromioclavicular joint, initial encounter',
  'S43151D Posterior dislocation of right acromioclavicular joint, subsequent encounter',
  'S43151S Posterior dislocation of right acromioclavicular joint, sequela',
  'S43152A Posterior dislocation of left acromioclavicular joint, initial encounter',
  'S43152D Posterior dislocation of left acromioclavicular joint, subsequent encounter',
  'S43152S Posterior dislocation of left acromioclavicular joint, sequela',
  'S43159A Posterior dislocation of unspecified acromioclavicular joint, initial encounter',
  'S43159D Posterior dislocation of unspecified acromioclavicular joint, subsequent encounter',
  'S43159S Posterior dislocation of unspecified acromioclavicular joint, sequela',
  'S43201A Unspecified subluxation of right sternoclavicular joint, initial encounter',
  'S43201D Unspecified subluxation of right sternoclavicular joint, subsequent encounter',
  'S43201S Unspecified subluxation of right sternoclavicular joint, sequela',
  'S43202A Unspecified subluxation of left sternoclavicular joint, initial encounter',
  'S43202D Unspecified subluxation of left sternoclavicular joint, subsequent encounter',
  'S43202S Unspecified subluxation of left sternoclavicular joint, sequela',
  'S43203A Unspecified subluxation of unspecified sternoclavicular joint, initial encounter',
  'S43203D Unspecified subluxation of unspecified sternoclavicular joint, subsequent encounter',
  'S43203S Unspecified subluxation of unspecified sternoclavicular joint, sequela',
  'S43204A Unspecified dislocation of right sternoclavicular joint, initial encounter',
  'S43204D Unspecified dislocation of right sternoclavicular joint, subsequent encounter',
  'S43204S Unspecified dislocation of right sternoclavicular joint, sequela',
  'S43205A Unspecified dislocation of left sternoclavicular joint, initial encounter',
  'S43205D Unspecified dislocation of left sternoclavicular joint, subsequent encounter',
  'S43205S Unspecified dislocation of left sternoclavicular joint, sequela',
  'S43206A Unspecified dislocation of unspecified sternoclavicular joint, initial encounter',
  'S43206D Unspecified dislocation of unspecified sternoclavicular joint, subsequent encounter',
  'S43206S Unspecified dislocation of unspecified sternoclavicular joint, sequela',
  'S43211A Anterior subluxation of right sternoclavicular joint, initial encounter',
  'S43211D Anterior subluxation of right sternoclavicular joint, subsequent encounter',
  'S43211S Anterior subluxation of right sternoclavicular joint, sequela',
  'S43212A Anterior subluxation of left sternoclavicular joint, initial encounter',
  'S43212D Anterior subluxation of left sternoclavicular joint, subsequent encounter',
  'S43212S Anterior subluxation of left sternoclavicular joint, sequela',
  'S43213A Anterior subluxation of unspecified sternoclavicular joint, initial encounter',
  'S43213D Anterior subluxation of unspecified sternoclavicular joint, subsequent encounter',
  'S43213S Anterior subluxation of unspecified sternoclavicular joint, sequela',
  'S43214A Anterior dislocation of right sternoclavicular joint, initial encounter',
  'S43214D Anterior dislocation of right sternoclavicular joint, subsequent encounter',
  'S43214S Anterior dislocation of right sternoclavicular joint, sequela',
  'S43215A Anterior dislocation of left sternoclavicular joint, initial encounter',
  'S43215D Anterior dislocation of left sternoclavicular joint, subsequent encounter',
  'S43215S Anterior dislocation of left sternoclavicular joint, sequela',
  'S43216A Anterior dislocation of unspecified sternoclavicular joint, initial encounter',
  'S43216D Anterior dislocation of unspecified sternoclavicular joint, subsequent encounter',
  'S43216S Anterior dislocation of unspecified sternoclavicular joint, sequela',
  'S43221A Posterior subluxation of right sternoclavicular joint, initial encounter',
  'S43221D Posterior subluxation of right sternoclavicular joint, subsequent encounter',
  'S43221S Posterior subluxation of right sternoclavicular joint, sequela',
  'S43222A Posterior subluxation of left sternoclavicular joint, initial encounter',
  'S43222D Posterior subluxation of left sternoclavicular joint, subsequent encounter',
  'S43222S Posterior subluxation of left sternoclavicular joint, sequela',
  'S43223A Posterior subluxation of unspecified sternoclavicular joint, initial encounter',
  'S43223D Posterior subluxation of unspecified sternoclavicular joint, subsequent encounter',
  'S43223S Posterior subluxation of unspecified sternoclavicular joint, sequela',
  'S43224A Posterior dislocation of right sternoclavicular joint, initial encounter',
  'S43224D Posterior dislocation of right sternoclavicular joint, subsequent encounter',
  'S43224S Posterior dislocation of right sternoclavicular joint, sequela',
  'S43225A Posterior dislocation of left sternoclavicular joint, initial encounter',
  'S43225D Posterior dislocation of left sternoclavicular joint, subsequent encounter',
  'S43225S Posterior dislocation of left sternoclavicular joint, sequela',
  'S43226A Posterior dislocation of unspecified sternoclavicular joint, initial encounter',
  'S43226D Posterior dislocation of unspecified sternoclavicular joint, subsequent encounter',
  'S43226S Posterior dislocation of unspecified sternoclavicular joint, sequela',
  'S43301A Subluxation of unspecified parts of right shoulder girdle, initial encounter',
  'S43301D Subluxation of unspecified parts of right shoulder girdle, subsequent encounter',
  'S43301S Subluxation of unspecified parts of right shoulder girdle, sequela',
  'S43302A Subluxation of unspecified parts of left shoulder girdle, initial encounter',
  'S43302D Subluxation of unspecified parts of left shoulder girdle, subsequent encounter',
  'S43302S Subluxation of unspecified parts of left shoulder girdle, sequela',
  'S43303A Subluxation of unspecified parts of unspecified shoulder girdle, initial encounter',
  'S43303D Subluxation of unspecified parts of unspecified shoulder girdle, subsequent encounter',
  'S43303S Subluxation of unspecified parts of unspecified shoulder girdle, sequela',
  'S43304A Dislocation of unspecified parts of right shoulder girdle, initial encounter',
  'S43304D Dislocation of unspecified parts of right shoulder girdle, subsequent encounter',
  'S43304S Dislocation of unspecified parts of right shoulder girdle, sequela',
  'S43305A Dislocation of unspecified parts of left shoulder girdle, initial encounter',
  'S43305D Dislocation of unspecified parts of left shoulder girdle, subsequent encounter',
  'S43305S Dislocation of unspecified parts of left shoulder girdle, sequela',
  'S43306A Dislocation of unspecified parts of unspecified shoulder girdle, initial encounter',
  'S43306D Dislocation of unspecified parts of unspecified shoulder girdle, subsequent encounter',
  'S43306S Dislocation of unspecified parts of unspecified shoulder girdle, sequela',
  'S43311A Subluxation of right scapula, initial encounter',
  'S43311D Subluxation of right scapula, subsequent encounter',
  'S43311S Subluxation of right scapula, sequela',
  'S43312A Subluxation of left scapula, initial encounter',
  'S43312D Subluxation of left scapula, subsequent encounter',
  'S43312S Subluxation of left scapula, sequela',
  'S43313A Subluxation of unspecified scapula, initial encounter',
  'S43313D Subluxation of unspecified scapula, subsequent encounter',
  'S43313S Subluxation of unspecified scapula, sequela',
  'S43314A Dislocation of right scapula, initial encounter',
  'S43314D Dislocation of right scapula, subsequent encounter',
  'S43314S Dislocation of right scapula, sequela',
  'S43315A Dislocation of left scapula, initial encounter',
  'S43315D Dislocation of left scapula, subsequent encounter',
  'S43315S Dislocation of left scapula, sequela',
  'S43316A Dislocation of unspecified scapula, initial encounter',
  'S43316D Dislocation of unspecified scapula, subsequent encounter',
  'S43316S Dislocation of unspecified scapula, sequela',
  'S43391A Subluxation of other parts of right shoulder girdle, initial encounter',
  'S43391D Subluxation of other parts of right shoulder girdle, subsequent encounter',
  'S43391S Subluxation of other parts of right shoulder girdle, sequela',
  'S43392A Subluxation of other parts of left shoulder girdle, initial encounter',
  'S43392D Subluxation of other parts of left shoulder girdle, subsequent encounter',
  'S43392S Subluxation of other parts of left shoulder girdle, sequela',
  'S43393A Subluxation of other parts of unspecified shoulder girdle, initial encounter',
  'S43393D Subluxation of other parts of unspecified shoulder girdle, subsequent encounter',
  'S43393S Subluxation of other parts of unspecified shoulder girdle, sequela',
  'S43394A Dislocation of other parts of right shoulder girdle, initial encounter',
  'S43394D Dislocation of other parts of right shoulder girdle, subsequent encounter',
  'S43394S Dislocation of other parts of right shoulder girdle, sequela',
  'S43395A Dislocation of other parts of left shoulder girdle, initial encounter',
  'S43395D Dislocation of other parts of left shoulder girdle, subsequent encounter',
  'S43395S Dislocation of other parts of left shoulder girdle, sequela',
  'S43396A Dislocation of other parts of unspecified shoulder girdle, initial encounter',
  'S43396D Dislocation of other parts of unspecified shoulder girdle, subsequent encounter',
  'S43396S Dislocation of other parts of unspecified shoulder girdle, sequela',
  'S43401A Unspecified sprain of right shoulder joint, initial encounter',
  'S43401D Unspecified sprain of right shoulder joint, subsequent encounter',
  'S43401S Unspecified sprain of right shoulder joint, sequela',
  'S43402A Unspecified sprain of left shoulder joint, initial encounter',
  'S43402D Unspecified sprain of left shoulder joint, subsequent encounter',
  'S43402S Unspecified sprain of left shoulder joint, sequela',
  'S43409A Unspecified sprain of unspecified shoulder joint, initial encounter',
  'S43409D Unspecified sprain of unspecified shoulder joint, subsequent encounter',
  'S43409S Unspecified sprain of unspecified shoulder joint, sequela',
  'S43411A Sprain of right coracohumeral (ligament), initial encounter',
  'S43411D Sprain of right coracohumeral (ligament), subsequent encounter',
  'S43411S Sprain of right coracohumeral (ligament), sequela',
  'S43412A Sprain of left coracohumeral (ligament), initial encounter',
  'S43412D Sprain of left coracohumeral (ligament), subsequent encounter',
  'S43412S Sprain of left coracohumeral (ligament), sequela',
  'S43419A Sprain of unspecified coracohumeral (ligament), initial encounter',
  'S43419D Sprain of unspecified coracohumeral (ligament), subsequent encounter',
  'S43419S Sprain of unspecified coracohumeral (ligament), sequela',
  'S43421A Sprain of right rotator cuff capsule, initial encounter',
  'S43421D Sprain of right rotator cuff capsule, subsequent encounter',
  'S43421S Sprain of right rotator cuff capsule, sequela',
  'S43422A Sprain of left rotator cuff capsule, initial encounter',
  'S43422D Sprain of left rotator cuff capsule, subsequent encounter',
  'S43422S Sprain of left rotator cuff capsule, sequela',
  'S43429A Sprain of unspecified rotator cuff capsule, initial encounter',
  'S43429D Sprain of unspecified rotator cuff capsule, subsequent encounter',
  'S43429S Sprain of unspecified rotator cuff capsule, sequela',
  'S43431A Superior glenoid labrum lesion of right shoulder, initial encounter',
  'S43431D Superior glenoid labrum lesion of right shoulder, subsequent encounter',
  'S43431S Superior glenoid labrum lesion of right shoulder, sequela',
  'S43432A Superior glenoid labrum lesion of left shoulder, initial encounter',
  'S43432D Superior glenoid labrum lesion of left shoulder, subsequent encounter',
  'S43432S Superior glenoid labrum lesion of left shoulder, sequela',
  'S43439A Superior glenoid labrum lesion of unspecified shoulder, initial encounter',
  'S43439D Superior glenoid labrum lesion of unspecified shoulder, subsequent encounter',
  'S43439S Superior glenoid labrum lesion of unspecified shoulder, sequela',
  'S43491A Other sprain of right shoulder joint, initial encounter',
  'S43491D Other sprain of right shoulder joint, subsequent encounter',
  'S43491S Other sprain of right shoulder joint, sequela',
  'S43492A Other sprain of left shoulder joint, initial encounter',
  'S43492D Other sprain of left shoulder joint, subsequent encounter',
  'S43492S Other sprain of left shoulder joint, sequela',
  'S43499A Other sprain of unspecified shoulder joint, initial encounter',
  'S43499D Other sprain of unspecified shoulder joint, subsequent encounter',
  'S43499S Other sprain of unspecified shoulder joint, sequela',
  'S4350XA Sprain of unspecified acromioclavicular joint, initial encounter',
  'S4350XD Sprain of unspecified acromioclavicular joint, subsequent encounter',
  'S4350XS Sprain of unspecified acromioclavicular joint, sequela',
  'S4351XA Sprain of right acromioclavicular joint, initial encounter',
  'S4351XD Sprain of right acromioclavicular joint, subsequent encounter',
  'S4351XS Sprain of right acromioclavicular joint, sequela',
  'S4352XA Sprain of left acromioclavicular joint, initial encounter',
  'S4352XD Sprain of left acromioclavicular joint, subsequent encounter',
  'S4352XS Sprain of left acromioclavicular joint, sequela',
  'S4360XA Sprain of unspecified sternoclavicular joint, initial encounter',
  'S4360XD Sprain of unspecified sternoclavicular joint, subsequent encounter',
  'S4360XS Sprain of unspecified sternoclavicular joint, sequela',
  'S4361XA Sprain of right sternoclavicular joint, initial encounter',
  'S4361XD Sprain of right sternoclavicular joint, subsequent encounter',
  'S4361XS Sprain of right sternoclavicular joint, sequela',
  'S4362XA Sprain of left sternoclavicular joint, initial encounter',
  'S4362XD Sprain of left sternoclavicular joint, subsequent encounter',
  'S4362XS Sprain of left sternoclavicular joint, sequela',
  'S4380XA Sprain of other specified parts of unspecified shoulder girdle, initial encounter',
  'S4380XD Sprain of other specified parts of unspecified shoulder girdle, subsequent encounter',
  'S4380XS Sprain of other specified parts of unspecified shoulder girdle, sequela',
  'S4381XA Sprain of other specified parts of right shoulder girdle, initial encounter',
  'S4381XD Sprain of other specified parts of right shoulder girdle, subsequent encounter',
  'S4381XS Sprain of other specified parts of right shoulder girdle, sequela',
  'S4382XA Sprain of other specified parts of left shoulder girdle, initial encounter',
  'S4382XD Sprain of other specified parts of left shoulder girdle, subsequent encounter',
  'S4382XS Sprain of other specified parts of left shoulder girdle, sequela',
  'S4390XA Sprain of unspecified parts of unspecified shoulder girdle, initial encounter',
  'S4390XD Sprain of unspecified parts of unspecified shoulder girdle, subsequent encounter',
  'S4390XS Sprain of unspecified parts of unspecified shoulder girdle, sequela',
  'S4391XA Sprain of unspecified parts of right shoulder girdle, initial encounter',
  'S4391XD Sprain of unspecified parts of right shoulder girdle, subsequent encounter',
  'S4391XS Sprain of unspecified parts of right shoulder girdle, sequela',
  'S4392XA Sprain of unspecified parts of left shoulder girdle, initial encounter',
  'S4392XD Sprain of unspecified parts of left shoulder girdle, subsequent encounter',
  'S4392XS Sprain of unspecified parts of left shoulder girdle, sequela',
  'S4400XA Injury of ulnar nerve at upper arm level, unspecified arm, initial encounter',
  'S4400XD Injury of ulnar nerve at upper arm level, unspecified arm, subsequent encounter',
  'S4400XS Injury of ulnar nerve at upper arm level, unspecified arm, sequela',
  'S4401XA Injury of ulnar nerve at upper arm level, right arm, initial encounter',
  'S4401XD Injury of ulnar nerve at upper arm level, right arm, subsequent encounter',
  'S4401XS Injury of ulnar nerve at upper arm level, right arm, sequela',
  'S4402XA Injury of ulnar nerve at upper arm level, left arm, initial encounter',
  'S4402XD Injury of ulnar nerve at upper arm level, left arm, subsequent encounter',
  'S4402XS Injury of ulnar nerve at upper arm level, left arm, sequela',
  'S4410XA Injury of median nerve at upper arm level, unspecified arm, initial encounter',
  'S4410XD Injury of median nerve at upper arm level, unspecified arm, subsequent encounter',
  'S4410XS Injury of median nerve at upper arm level, unspecified arm, sequela',
  'S4411XA Injury of median nerve at upper arm level, right arm, initial encounter',
  'S4411XD Injury of median nerve at upper arm level, right arm, subsequent encounter',
  'S4411XS Injury of median nerve at upper arm level, right arm, sequela',
  'S4412XA Injury of median nerve at upper arm level, left arm, initial encounter',
  'S4412XD Injury of median nerve at upper arm level, left arm, subsequent encounter',
  'S4412XS Injury of median nerve at upper arm level, left arm, sequela',
  'S4420XA Injury of radial nerve at upper arm level, unspecified arm, initial encounter',
  'S4420XD Injury of radial nerve at upper arm level, unspecified arm, subsequent encounter',
  'S4420XS Injury of radial nerve at upper arm level, unspecified arm, sequela',
  'S4421XA Injury of radial nerve at upper arm level, right arm, initial encounter',
  'S4421XD Injury of radial nerve at upper arm level, right arm, subsequent encounter',
  'S4421XS Injury of radial nerve at upper arm level, right arm, sequela',
  'S4422XA Injury of radial nerve at upper arm level, left arm, initial encounter',
  'S4422XD Injury of radial nerve at upper arm level, left arm, subsequent encounter',
  'S4422XS Injury of radial nerve at upper arm level, left arm, sequela',
  'S4430XA Injury of axillary nerve, unspecified arm, initial encounter',
  'S4430XD Injury of axillary nerve, unspecified arm, subsequent encounter',
  'S4430XS Injury of axillary nerve, unspecified arm, sequela',
  'S4431XA Injury of axillary nerve, right arm, initial encounter',
  'S4431XD Injury of axillary nerve, right arm, subsequent encounter',
  'S4431XS Injury of axillary nerve, right arm, sequela',
  'S4432XA Injury of axillary nerve, left arm, initial encounter',
  'S4432XD Injury of axillary nerve, left arm, subsequent encounter',
  'S4432XS Injury of axillary nerve, left arm, sequela',
  'S4440XA Injury of musculocutaneous nerve, unspecified arm, initial encounter',
  'S4440XD Injury of musculocutaneous nerve, unspecified arm, subsequent encounter',
  'S4440XS Injury of musculocutaneous nerve, unspecified arm, sequela',
  'S4441XA Injury of musculocutaneous nerve, right arm, initial encounter',
  'S4441XD Injury of musculocutaneous nerve, right arm, subsequent encounter',
  'S4441XS Injury of musculocutaneous nerve, right arm, sequela',
  'S4442XA Injury of musculocutaneous nerve, left arm, initial encounter',
  'S4442XD Injury of musculocutaneous nerve, left arm, subsequent encounter',
  'S4442XS Injury of musculocutaneous nerve, left arm, sequela',
  'S4450XA Injury of cutaneous sensory nerve at shoulder and upper arm level, unspecified arm, initial',
  'encounter',
  'S4450XD Injury of cutaneous sensory nerve at shoulder and upper arm level, unspecified arm,',
  'subsequent encounter',
  'S4450XS Injury of cutaneous sensory nerve at shoulder and upper arm level, unspecified arm, sequela',
  'S4451XA Injury of cutaneous sensory nerve at shoulder and upper arm level, right arm, initial encounter',
  'S4451XD Injury of cutaneous sensory nerve at shoulder and upper arm level, right arm, subsequent',
  'encounter',
  'S4451XS Injury of cutaneous sensory nerve at shoulder and upper arm level, right arm, sequela',
  'S4452XA Injury of cutaneous sensory nerve at shoulder and upper arm level, left arm, initial encounter',
  'S4452XD Injury of cutaneous sensory nerve at shoulder and upper arm level, left arm, subsequent',
  'encounter',
  'S4452XS Injury of cutaneous sensory nerve at shoulder and upper arm level, left arm, sequela',
  'S448X1A Injury of other nerves at shoulder and upper arm level, right arm, initial encounter',
  'S448X1D Injury of other nerves at shoulder and upper arm level, right arm, subsequent encounter',
  'S448X1S Injury of other nerves at shoulder and upper arm level, right arm, sequela',
  'S448X2A Injury of other nerves at shoulder and upper arm level, left arm, initial encounter',
  'S448X2D Injury of other nerves at shoulder and upper arm level, left arm, subsequent encounter',
  'S448X2S Injury of other nerves at shoulder and upper arm level, left arm, sequela',
  'S448X9A Injury of other nerves at shoulder and upper arm level, unspecified arm, initial encounter',
  'S448X9D Injury of other nerves at shoulder and upper arm level, unspecified arm, subsequent encounter',
  'S448X9S Injury of other nerves at shoulder and upper arm level, unspecified arm, sequela',
  'S4490XA Injury of unspecified nerve at shoulder and upper arm level, unspecified arm, initial encounter',
  'S4490XD Injury of unspecified nerve at shoulder and upper arm level, unspecified arm, subsequent',
  'encounter',
  'S4490XS Injury of unspecified nerve at shoulder and upper arm level, unspecified arm, sequela',
  'S4491XA Injury of unspecified nerve at shoulder and upper arm level, right arm, initial encounter',
  'S4491XD Injury of unspecified nerve at shoulder and upper arm level, right arm, subsequent encounter',
  'S4491XS Injury of unspecified nerve at shoulder and upper arm level, right arm, sequela',
  'S4492XA Injury of unspecified nerve at shoulder and upper arm level, left arm, initial encounter',
  'S4492XD Injury of unspecified nerve at shoulder and upper arm level, left arm, subsequent encounter',
  'S4492XS Injury of unspecified nerve at shoulder and upper arm level, left arm, sequela',
  'S45001A Unspecified injury of axillary artery, right side, initial encounter',
  'S45001D Unspecified injury of axillary artery, right side, subsequent encounter',
  'S45001S Unspecified injury of axillary artery, right side, sequela',
  'S45002A Unspecified injury of axillary artery, left side, initial encounter',
  'S45002D Unspecified injury of axillary artery, left side, subsequent encounter',
  'S45002S Unspecified injury of axillary artery, left side, sequela',
  'S45009A Unspecified injury of axillary artery, unspecified side, initial encounter',
  'S45009D Unspecified injury of axillary artery, unspecified side, subsequent encounter',
  'S45009S Unspecified injury of axillary artery, unspecified side, sequela',
  'S45011A Laceration of axillary artery, right side, initial encounter',
  'S45011D Laceration of axillary artery, right side, subsequent encounter',
  'S45011S Laceration of axillary artery, right side, sequela',
  'S45012A Laceration of axillary artery, left side, initial encounter',
  'S45012D Laceration of axillary artery, left side, subsequent encounter',
  'S45012S Laceration of axillary artery, left side, sequela',
  'S45019A Laceration of axillary artery, unspecified side, initial encounter',
  'S45019D Laceration of axillary artery, unspecified side, subsequent encounter',
  'S45019S Laceration of axillary artery, unspecified side, sequela',
  'S45091A Other specified injury of axillary artery, right side, initial encounter',
  'S45091D Other specified injury of axillary artery, right side, subsequent encounter',
  'S45091S Other specified injury of axillary artery, right side, sequela',
  'S45092A Other specified injury of axillary artery, left side, initial encounter',
  'S45092D Other specified injury of axillary artery, left side, subsequent encounter',
  'S45092S Other specified injury of axillary artery, left side, sequela',
  'S45099A Other specified injury of axillary artery, unspecified side, initial encounter',
  'S45099D Other specified injury of axillary artery, unspecified side, subsequent encounter',
  'S45099S Other specified injury of axillary artery, unspecified side, sequela',
  'S45101A Unspecified injury of brachial artery, right side, initial encounter',
  'S45101D Unspecified injury of brachial artery, right side, subsequent encounter',
  'S45101S Unspecified injury of brachial artery, right side, sequela',
  'S45102A Unspecified injury of brachial artery, left side, initial encounter',
  'S45102D Unspecified injury of brachial artery, left side, subsequent encounter',
  'S45102S Unspecified injury of brachial artery, left side, sequela',
  'S45109A Unspecified injury of brachial artery, unspecified side, initial encounter',
  'S45109D Unspecified injury of brachial artery, unspecified side, subsequent encounter',
  'S45109S Unspecified injury of brachial artery, unspecified side, sequela',
  'S45111A Laceration of brachial artery, right side, initial encounter',
  'S45111D Laceration of brachial artery, right side, subsequent encounter',
  'S45111S Laceration of brachial artery, right side, sequela',
  'S45112A Laceration of brachial artery, left side, initial encounter',
  'S45112D Laceration of brachial artery, left side, subsequent encounter',
  'S45112S Laceration of brachial artery, left side, sequela',
  'S45119A Laceration of brachial artery, unspecified side, initial encounter',
  'S45119D Laceration of brachial artery, unspecified side, subsequent encounter',
  'S45119S Laceration of brachial artery, unspecified side, sequela',
  'S45191A Other specified injury of brachial artery, right side, initial encounter',
  'S45191D Other specified injury of brachial artery, right side, subsequent encounter',
  'S45191S Other specified injury of brachial artery, right side, sequela',
  'S45192A Other specified injury of brachial artery, left side, initial encounter',
  'S45192D Other specified injury of brachial artery, left side, subsequent encounter',
  'S45192S Other specified injury of brachial artery, left side, sequela',
  'S45199A Other specified injury of brachial artery, unspecified side, initial encounter',
  'S45199D Other specified injury of brachial artery, unspecified side, subsequent encounter',
  'S45199S Other specified injury of brachial artery, unspecified side, sequela',
  'S45201A Unspecified injury of axillary or brachial vein, right side, initial encounter',
  'S45201D Unspecified injury of axillary or brachial vein, right side, subsequent encounter',
  'S45201S Unspecified injury of axillary or brachial vein, right side, sequela',
  'S45202A Unspecified injury of axillary or brachial vein, left side, initial encounter',
  'S45202D Unspecified injury of axillary or brachial vein, left side, subsequent encounter',
  'S45202S Unspecified injury of axillary or brachial vein, left side, sequela',
  'S45209A Unspecified injury of axillary or brachial vein, unspecified side, initial encounter',
  'S45209D Unspecified injury of axillary or brachial vein, unspecified side, subsequent encounter',
  'S45209S Unspecified injury of axillary or brachial vein, unspecified side, sequela',
  'S45211A Laceration of axillary or brachial vein, right side, initial encounter',
  'S45211D Laceration of axillary or brachial vein, right side, subsequent encounter',
  'S45211S Laceration of axillary or brachial vein, right side, sequela',
  'S45212A Laceration of axillary or brachial vein, left side, initial encounter',
  'S45212D Laceration of axillary or brachial vein, left side, subsequent encounter',
  'S45212S Laceration of axillary or brachial vein, left side, sequela',
  'S45219A Laceration of axillary or brachial vein, unspecified side, initial encounter',
  'S45219D Laceration of axillary or brachial vein, unspecified side, subsequent encounter',
  'S45219S Laceration of axillary or brachial vein, unspecified side, sequela',
  'S45291A Other specified injury of axillary or brachial vein, right side, initial encounter',
  'S45291D Other specified injury of axillary or brachial vein, right side, subsequent encounter',
  'S45291S Other specified injury of axillary or brachial vein, right side, sequela',
  'S45292A Other specified injury of axillary or brachial vein, left side, initial encounter',
  'S45292D Other specified injury of axillary or brachial vein, left side, subsequent encounter',
  'S45292S Other specified injury of axillary or brachial vein, left side, sequela',
  'S45299A Other specified injury of axillary or brachial vein, unspecified side, initial encounter',
  'S45299D Other specified injury of axillary or brachial vein, unspecified side, subsequent encounter',
  'S45299S Other specified injury of axillary or brachial vein, unspecified side, sequela',
  'S45301A Unspecified injury of superficial vein at shoulder and upper arm level, right arm, initial',
  'encounter',
  'S45301D Unspecified injury of superficial vein at shoulder and upper arm level, right arm, subsequent',
  'encounter',
  'S45301S Unspecified injury of superficial vein at shoulder and upper arm level, right arm, sequela',
  'S45302A Unspecified injury of superficial vein at shoulder and upper arm level, left arm, initial',
  'encounter',
  'S45302D Unspecified injury of superficial vein at shoulder and upper arm level, left arm, subsequent',
  'encounter',
  'S45302S Unspecified injury of superficial vein at shoulder and upper arm level, left arm, sequela',
  'S45309A Unspecified injury of superficial vein at shoulder and upper arm level, unspecified arm, initial',
  'encounter',
  'S45309D Unspecified injury of superficial vein at shoulder and upper arm level, unspecified arm,',
  'subsequent encounter',
  'S45309S Unspecified injury of superficial vein at shoulder and upper arm level, unspecified arm, sequela',
  'S45311A Laceration of superficial vein at shoulder and upper arm level, right arm, initial encounter',
  'S45311D Laceration of superficial vein at shoulder and upper arm level, right arm, subsequent',
  'encounter',
  'S45311S Laceration of superficial vein at shoulder and upper arm level, right arm, sequela',
  'S45312A Laceration of superficial vein at shoulder and upper arm level, left arm, initial encounter',
  'S45312D Laceration of superficial vein at shoulder and upper arm level, left arm, subsequent encounter',
  'S45312S Laceration of superficial vein at shoulder and upper arm level, left arm, sequela',
  'S45319A Laceration of superficial vein at shoulder and upper arm level, unspecified arm, initial',
  'encounter',
  'S45319D Laceration of superficial vein at shoulder and upper arm level, unspecified arm, subsequent',
  'encounter',
  'S45319S Laceration of superficial vein at shoulder and upper arm level, unspecified arm, sequela',
  'S45391A Other specified injury of superficial vein at shoulder and upper arm level, right arm, initial',
  'encounter',
  'S45391D Other specified injury of superficial vein at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S45391S Other specified injury of superficial vein at shoulder and upper arm level, right arm, sequela',
  'S45392A Other specified injury of superficial vein at shoulder and upper arm level, left arm, initial',
  'encounter',
  'S45392D Other specified injury of superficial vein at shoulder and upper arm level, left arm, subsequent',
  'encounter',
  'S45392S Other specified injury of superficial vein at shoulder and upper arm level, left arm, sequela',
  'S45399A Other specified injury of superficial vein at shoulder and upper arm level, unspecified arm,',
  'initial encounter',
  'S45399D Other specified injury of superficial vein at shoulder and upper arm level, unspecified arm,',
  'subsequent encounter',
  'S45399S Other specified injury of superficial vein at shoulder and upper arm level, unspecified arm,',
  'sequela',
  'S45801A Unspecified injury of other specified blood vessels at shoulder and upper arm level, right arm,',
  'initial encounter',
  'S45801D Unspecified injury of other specified blood vessels at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S45801S Unspecified injury of other specified blood vessels at shoulder and upper arm level, right arm,',
  'sequela',
  'S45802A Unspecified injury of other specified blood vessels at shoulder and upper arm level, left arm,',
  'initial encounter',
  'S45802D Unspecified injury of other specified blood vessels at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S45802S Unspecified injury of other specified blood vessels at shoulder and upper arm level, left arm,',
  'sequela',
  'S45809A Unspecified injury of other specified blood vessels at shoulder and upper arm level, unspecified',
  'arm, initial encounter',
  'S45809D Unspecified injury of other specified blood vessels at shoulder and upper arm level,',
  'unspecified arm, subsequent encounter',
  'S45809S Unspecified injury of other specified blood vessels at shoulder and upper arm level, unspecified',
  'arm, sequela',
  'S45811A Laceration of other specified blood vessels at shoulder and upper arm level, right arm, initial',
  'encounter',
  'S45811D Laceration of other specified blood vessels at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S45811S Laceration of other specified blood vessels at shoulder and upper arm level, right arm, sequela',
  'S45812A Laceration of other specified blood vessels at shoulder and upper arm level, left arm, initial',
  'encounter',
  'S45812D Laceration of other specified blood vessels at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S45812S Laceration of other specified blood vessels at shoulder and upper arm level, left arm, sequela',
  'S45819A Laceration of other specified blood vessels at shoulder and upper arm level, unspecified arm,',
  'initial encounter',
  'S45819D Laceration of other specified blood vessels at shoulder and upper arm level, unspecified arm,',
  'subsequent encounter',
  'S45819S Laceration of other specified blood vessels at shoulder and upper arm level, unspecified arm,',
  'sequela',
  'S45891A Other specified injury of other specified blood vessels at shoulder and upper arm level, right',
  'arm, initial encounter',
  'S45891D Other specified injury of other specified blood vessels at shoulder and upper arm level, right',
  'arm, subsequent encounter',
  'S45891S Other specified injury of other specified blood vessels at shoulder and upper arm level, right',
  'arm, sequela',
  'S45892A Other specified injury of other specified blood vessels at shoulder and upper arm level, left',
  'arm, initial encounter',
  'S45892D Other specified injury of other specified blood vessels at shoulder and upper arm level, left',
  'arm, subsequent encounter',
  'S45892S Other specified injury of other specified blood vessels at shoulder and upper arm level, left',
  'arm, sequela',
  'S45899A Other specified injury of other specified blood vessels at shoulder and upper arm level,',
  'unspecified arm, initial encounter',
  'S45899D Other specified injury of other specified blood vessels at shoulder and upper arm level,',
  'unspecified arm, subsequent encounter',
  'S45899S Other specified injury of other specified blood vessels at shoulder and upper arm level,',
  'unspecified arm, sequela',
  'S45901A Unspecified injury of unspecified blood vessel at shoulder and upper arm level, right arm, initial',
  'encounter',
  'S45901D Unspecified injury of unspecified blood vessel at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S45901S Unspecified injury of unspecified blood vessel at shoulder and upper arm level, right arm,',
  'sequela',
  'S45902A Unspecified injury of unspecified blood vessel at shoulder and upper arm level, left arm, initial',
  'encounter',
  'S45902D Unspecified injury of unspecified blood vessel at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S45902S Unspecified injury of unspecified blood vessel at shoulder and upper arm level, left arm,',
  'sequela',
  'S45909A Unspecified injury of unspecified blood vessel at shoulder and upper arm level, unspecified',
  'arm, initial encounter',
  'S45909D Unspecified injury of unspecified blood vessel at shoulder and upper arm level, unspecified',
  'arm, subsequent encounter',
  'S45909S Unspecified injury of unspecified blood vessel at shoulder and upper arm level, unspecified',
  'arm, sequela',
  'S45911A Laceration of unspecified blood vessel at shoulder and upper arm level, right arm, initial',
  'encounter',
  'S45911D Laceration of unspecified blood vessel at shoulder and upper arm level, right arm, subsequent',
  'encounter',
  'S45911S Laceration of unspecified blood vessel at shoulder and upper arm level, right arm, sequela',
  'S45912A Laceration of unspecified blood vessel at shoulder and upper arm level, left arm, initial',
  'encounter',
  'S45912D Laceration of unspecified blood vessel at shoulder and upper arm level, left arm, subsequent',
  'encounter',
  'S45912S Laceration of unspecified blood vessel at shoulder and upper arm level, left arm, sequela',
  'S45919A Laceration of unspecified blood vessel at shoulder and upper arm level, unspecified arm, initial',
  'encounter',
  'S45919D Laceration of unspecified blood vessel at shoulder and upper arm level, unspecified arm,',
  'subsequent encounter',
  'S45919S Laceration of unspecified blood vessel at shoulder and upper arm level, unspecified arm,',
  'sequela',
  'S45991A Other specified injury of unspecified blood vessel at shoulder and upper arm level, right arm,',
  'initial encounter',
  'S45991D Other specified injury of unspecified blood vessel at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S45991S Other specified injury of unspecified blood vessel at shoulder and upper arm level, right arm,',
  'sequela',
  'S45992A Other specified injury of unspecified blood vessel at shoulder and upper arm level, left arm,',
  'initial encounter',
  'S45992D Other specified injury of unspecified blood vessel at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S45992S Other specified injury of unspecified blood vessel at shoulder and upper arm level, left arm,',
  'sequela',
  'S45999A Other specified injury of unspecified blood vessel at shoulder and upper arm level, unspecified',
  'arm, initial encounter',
  'S45999D Other specified injury of unspecified blood vessel at shoulder and upper arm level, unspecified',
  'arm, subsequent encounter',
  'S45999S Other specified injury of unspecified blood vessel at shoulder and upper arm level, unspecified',
  'arm, sequela',
  'S46001A Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of right shoulder, initial',
  'encounter',
  'S46001D Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of right shoulder, subsequent',
  'encounter',
  'S46001S Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of right shoulder, sequela',
  'S46002A Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of left shoulder, initial',
  'encounter',
  'S46002D Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of left shoulder, subsequent',
  'encounter',
  'S46002S Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of left shoulder, sequela',
  'S46009A Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, initial',
  'encounter',
  'S46009D Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder,',
  'subsequent encounter',
  'S46009S Unspecified injury of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder,',
  'sequela',
  'S46011A Strain of muscle(s) and tendon(s) of the rotator cuff of right shoulder, initial encounter',
  'S46011D Strain of muscle(s) and tendon(s) of the rotator cuff of right shoulder, subsequent encounter',
  'S46011S Strain of muscle(s) and tendon(s) of the rotator cuff of right shoulder, sequela',
  'S46012A Strain of muscle(s) and tendon(s) of the rotator cuff of left shoulder, initial encounter',
  'S46012D Strain of muscle(s) and tendon(s) of the rotator cuff of left shoulder, subsequent encounter',
  'S46012S Strain of muscle(s) and tendon(s) of the rotator cuff of left shoulder, sequela',
  'S46019A Strain of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, initial encounter',
  'S46019D Strain of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, subsequent',
  'encounter',
  'S46019S Strain of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, sequela',
  'S46021A Laceration of muscle(s) and tendon(s) of the rotator cuff of right shoulder, initial encounter',
  'S46021D Laceration of muscle(s) and tendon(s) of the rotator cuff of right shoulder, subsequent',
  'encounter',
  'S46021S Laceration of muscle(s) and tendon(s) of the rotator cuff of right shoulder, sequela',
  'S46022A Laceration of muscle(s) and tendon(s) of the rotator cuff of left shoulder, initial encounter',
  'S46022D Laceration of muscle(s) and tendon(s) of the rotator cuff of left shoulder, subsequent',
  'encounter',
  'S46022S Laceration of muscle(s) and tendon(s) of the rotator cuff of left shoulder, sequela',
  'S46029A Laceration of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, initial',
  'encounter',
  'S46029D Laceration of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, subsequent',
  'encounter',
  'S46029S Laceration of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, sequela',
  'S46091A Other injury of muscle(s) and tendon(s) of the rotator cuff of right shoulder, initial encounter',
  'S46091D Other injury of muscle(s) and tendon(s) of the rotator cuff of right shoulder, subsequent',
  'encounter',
  'S46091S Other injury of muscle(s) and tendon(s) of the rotator cuff of right shoulder, sequela',
  'S46092A Other injury of muscle(s) and tendon(s) of the rotator cuff of left shoulder, initial encounter',
  'S46092D Other injury of muscle(s) and tendon(s) of the rotator cuff of left shoulder, subsequent',
  'encounter',
  'S46092S Other injury of muscle(s) and tendon(s) of the rotator cuff of left shoulder, sequela',
  'S46099A Other injury of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, initial',
  'encounter',
  'S46099D Other injury of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, subsequent',
  'encounter',
  'S46099S Other injury of muscle(s) and tendon(s) of the rotator cuff of unspecified shoulder, sequela',
  'S46101A Unspecified injury of muscle, fascia and tendon of long head of biceps, right arm, initial',
  'encounter',
  'S46101D Unspecified injury of muscle, fascia and tendon of long head of biceps, right arm, subsequent',
  'encounter',
  'S46101S Unspecified injury of muscle, fascia and tendon of long head of biceps, right arm, sequela',
  'S46102A Unspecified injury of muscle, fascia and tendon of long head of biceps, left arm, initial',
  'encounter',
  'S46102D Unspecified injury of muscle, fascia and tendon of long head of biceps, left arm, subsequent',
  'encounter',
  'S46102S Unspecified injury of muscle, fascia and tendon of long head of biceps, left arm, sequela',
  'S46109A Unspecified injury of muscle, fascia and tendon of long head of biceps, unspecified arm, initial',
  'encounter',
  'S46109D Unspecified injury of muscle, fascia and tendon of long head of biceps, unspecified arm,',
  'subsequent encounter',
  'S46109S Unspecified injury of muscle, fascia and tendon of long head of biceps, unspecified arm,',
  'sequela',
  'S46111A Strain of muscle, fascia and tendon of long head of biceps, right arm, initial encounter',
  'S46111D Strain of muscle, fascia and tendon of long head of biceps, right arm, subsequent encounter',
  'S46111S Strain of muscle, fascia and tendon of long head of biceps, right arm, sequela',
  'S46112A Strain of muscle, fascia and tendon of long head of biceps, left arm, initial encounter',
  'S46112D Strain of muscle, fascia and tendon of long head of biceps, left arm, subsequent encounter',
  'S46112S Strain of muscle, fascia and tendon of long head of biceps, left arm, sequela',
  'S46119A Strain of muscle, fascia and tendon of long head of biceps, unspecified arm, initial encounter',
  'S46119D Strain of muscle, fascia and tendon of long head of biceps, unspecified arm, subsequent',
  'encounter',
  'S46119S Strain of muscle, fascia and tendon of long head of biceps, unspecified arm, sequela',
  'S46121A Laceration of muscle, fascia and tendon of long head of biceps, right arm, initial encounter',
  'S46121D Laceration of muscle, fascia and tendon of long head of biceps, right arm, subsequent',
  'encounter',
  'S46121S Laceration of muscle, fascia and tendon of long head of biceps, right arm, sequela',
  'S46122A Laceration of muscle, fascia and tendon of long head of biceps, left arm, initial encounter',
  'S46122D Laceration of muscle, fascia and tendon of long head of biceps, left arm, subsequent encounter',
  'S46122S Laceration of muscle, fascia and tendon of long head of biceps, left arm, sequela',
  'S46129A Laceration of muscle, fascia and tendon of long head of biceps, unspecified arm, initial',
  'encounter',
  'S46129D Laceration of muscle, fascia and tendon of long head of biceps, unspecified arm, subsequent',
  'encounter',
  'S46129S Laceration of muscle, fascia and tendon of long head of biceps, unspecified arm, sequela',
  'S46191A Other injury of muscle, fascia and tendon of long head of biceps, right arm, initial encounter',
  'S46191D Other injury of muscle, fascia and tendon of long head of biceps, right arm, subsequent',
  'encounter',
  'S46191S Other injury of muscle, fascia and tendon of long head of biceps, right arm, sequela',
  'S46192A Other injury of muscle, fascia and tendon of long head of biceps, left arm, initial encounter',
  'S46192D Other injury of muscle, fascia and tendon of long head of biceps, left arm, subsequent',
  'encounter',
  'S46192S Other injury of muscle, fascia and tendon of long head of biceps, left arm, sequela',
  'S46199A Other injury of muscle, fascia and tendon of long head of biceps, unspecified arm, initial',
  'encounter',
  'S46199D Other injury of muscle, fascia and tendon of long head of biceps, unspecified arm, subsequent',
  'encounter',
  'S46199S Other injury of muscle, fascia and tendon of long head of biceps, unspecified arm, sequela',
  'S46201A Unspecified injury of muscle, fascia and tendon of other parts of biceps, right arm, initial',
  'encounter',
  'S46201D Unspecified injury of muscle, fascia and tendon of other parts of biceps, right arm, subsequent',
  'encounter',
  'S46201S Unspecified injury of muscle, fascia and tendon of other parts of biceps, right arm, sequela',
  'S46202A Unspecified injury of muscle, fascia and tendon of other parts of biceps, left arm, initial',
  'encounter',
  'S46202D Unspecified injury of muscle, fascia and tendon of other parts of biceps, left arm, subsequent',
  'encounter',
  'S46202S Unspecified injury of muscle, fascia and tendon of other parts of biceps, left arm, sequela',
  'S46209A Unspecified injury of muscle, fascia and tendon of other parts of biceps, unspecified arm, initial',
  'encounter',
  'S46209D Unspecified injury of muscle, fascia and tendon of other parts of biceps, unspecified arm,',
  'subsequent encounter',
  'S46209S Unspecified injury of muscle, fascia and tendon of other parts of biceps, unspecified arm,',
  'sequela',
  'S46211A Strain of muscle, fascia and tendon of other parts of biceps, right arm, initial encounter',
  'S46211D Strain of muscle, fascia and tendon of other parts of biceps, right arm, subsequent encounter',
  'S46211S Strain of muscle, fascia and tendon of other parts of biceps, right arm, sequela',
  'S46212A Strain of muscle, fascia and tendon of other parts of biceps, left arm, initial encounter',
  'S46212D Strain of muscle, fascia and tendon of other parts of biceps, left arm, subsequent encounter',
  'S46212S Strain of muscle, fascia and tendon of other parts of biceps, left arm, sequela',
  'S46219A Strain of muscle, fascia and tendon of other parts of biceps, unspecified arm, initial encounter',
  'S46219D Strain of muscle, fascia and tendon of other parts of biceps, unspecified arm, subsequent',
  'encounter',
  'S46219S Strain of muscle, fascia and tendon of other parts of biceps, unspecified arm, sequela',
  'S46221A Laceration of muscle, fascia and tendon of other parts of biceps, right arm, initial encounter',
  'S46221D Laceration of muscle, fascia and tendon of other parts of biceps, right arm, subsequent',
  'encounter',
  'S46221S Laceration of muscle, fascia and tendon of other parts of biceps, right arm, sequela',
  'S46222A Laceration of muscle, fascia and tendon of other parts of biceps, left arm, initial encounter',
  'S46222D Laceration of muscle, fascia and tendon of other parts of biceps, left arm, subsequent',
  'encounter',
  'S46222S Laceration of muscle, fascia and tendon of other parts of biceps, left arm, sequela',
  'S46229A Laceration of muscle, fascia and tendon of other parts of biceps, unspecified arm, initial',
  'encounter',
  'S46229D Laceration of muscle, fascia and tendon of other parts of biceps, unspecified arm, subsequent',
  'encounter',
  'S46229S Laceration of muscle, fascia and tendon of other parts of biceps, unspecified arm, sequela',
  'S46291A Other injury of muscle, fascia and tendon of other parts of biceps, right arm, initial encounter',
  'S46291D Other injury of muscle, fascia and tendon of other parts of biceps, right arm, subsequent',
  'encounter',
  'S46291S Other injury of muscle, fascia and tendon of other parts of biceps, right arm, sequela',
  'S46292A Other injury of muscle, fascia and tendon of other parts of biceps, left arm, initial encounter',
  'S46292D Other injury of muscle, fascia and tendon of other parts of biceps, left arm, subsequent',
  'encounter',
  'S46292S Other injury of muscle, fascia and tendon of other parts of biceps, left arm, sequela',
  'S46299A Other injury of muscle, fascia and tendon of other parts of biceps, unspecified arm, initial',
  'encounter',
  'S46299D Other injury of muscle, fascia and tendon of other parts of biceps, unspecified arm, subsequent',
  'encounter',
  'S46299S Other injury of muscle, fascia and tendon of other parts of biceps, unspecified arm, sequela',
  'S46301A Unspecified injury of muscle, fascia and tendon of triceps, right arm, initial encounter',
  'S46301D Unspecified injury of muscle, fascia and tendon of triceps, right arm, subsequent encounter',
  'S46301S Unspecified injury of muscle, fascia and tendon of triceps, right arm, sequela',
  'S46302A Unspecified injury of muscle, fascia and tendon of triceps, left arm, initial encounter',
  'S46302D Unspecified injury of muscle, fascia and tendon of triceps, left arm, subsequent encounter',
  'S46302S Unspecified injury of muscle, fascia and tendon of triceps, left arm, sequela',
  'S46309A Unspecified injury of muscle, fascia and tendon of triceps, unspecified arm, initial encounter',
  'S46309D Unspecified injury of muscle, fascia and tendon of triceps, unspecified arm, subsequent',
  'encounter',
  'S46309S Unspecified injury of muscle, fascia and tendon of triceps, unspecified arm, sequela',
  'S46311A Strain of muscle, fascia and tendon of triceps, right arm, initial encounter',
  'S46311D Strain of muscle, fascia and tendon of triceps, right arm, subsequent encounter',
  'S46311S Strain of muscle, fascia and tendon of triceps, right arm, sequela',
  'S46312A Strain of muscle, fascia and tendon of triceps, left arm, initial encounter',
  'S46312D Strain of muscle, fascia and tendon of triceps, left arm, subsequent encounter',
  'S46312S Strain of muscle, fascia and tendon of triceps, left arm, sequela',
  'S46319A Strain of muscle, fascia and tendon of triceps, unspecified arm, initial encounter',
  'S46319D Strain of muscle, fascia and tendon of triceps, unspecified arm, subsequent encounter',
  'S46319S Strain of muscle, fascia and tendon of triceps, unspecified arm, sequela',
  'S46321A Laceration of muscle, fascia and tendon of triceps, right arm, initial encounter',
  'S46321D Laceration of muscle, fascia and tendon of triceps, right arm, subsequent encounter',
  'S46321S Laceration of muscle, fascia and tendon of triceps, right arm, sequela',
  'S46322A Laceration of muscle, fascia and tendon of triceps, left arm, initial encounter',
  'S46322D Laceration of muscle, fascia and tendon of triceps, left arm, subsequent encounter',
  'S46322S Laceration of muscle, fascia and tendon of triceps, left arm, sequela',
  'S46329A Laceration of muscle, fascia and tendon of triceps, unspecified arm, initial encounter',
  'S46329D Laceration of muscle, fascia and tendon of triceps, unspecified arm, subsequent encounter',
  'S46329S Laceration of muscle, fascia and tendon of triceps, unspecified arm, sequela',
  'S46391A Other injury of muscle, fascia and tendon of triceps, right arm, initial encounter',
  'S46391D Other injury of muscle, fascia and tendon of triceps, right arm, subsequent encounter',
  'S46391S Other injury of muscle, fascia and tendon of triceps, right arm, sequela',
  'S46392A Other injury of muscle, fascia and tendon of triceps, left arm, initial encounter',
  'S46392D Other injury of muscle, fascia and tendon of triceps, left arm, subsequent encounter',
  'S46392S Other injury of muscle, fascia and tendon of triceps, left arm, sequela',
  'S46399A Other injury of muscle, fascia and tendon of triceps, unspecified arm, initial encounter',
  'S46399D Other injury of muscle, fascia and tendon of triceps, unspecified arm, subsequent encounter',
  'S46399S Other injury of muscle, fascia and tendon of triceps, unspecified arm, sequela',
  'S46801A Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level, right',
  'arm, initial encounter',
  'S46801D Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level, right',
  'arm, subsequent encounter',
  'S46801S Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level, right',
  'arm, sequela',
  'S46802A Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level, left',
  'arm, initial encounter',
  'S46802D Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level, left',
  'arm, subsequent encounter',
  'S46802S Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level, left',
  'arm, sequela',
  'S46809A Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level,',
  'unspecified arm, initial encounter',
  'S46809D Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level,',
  'unspecified arm, subsequent encounter',
  'S46809S Unspecified injury of other muscles, fascia and tendons at shoulder and upper arm level,',
  'unspecified arm, sequela',
  'S46811A Strain of other muscles, fascia and tendons at shoulder and upper arm level, right arm, initial',
  'encounter',
  'S46811D Strain of other muscles, fascia and tendons at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S46811S Strain of other muscles, fascia and tendons at shoulder and upper arm level, right arm, sequela',
  'S46812A Strain of other muscles, fascia and tendons at shoulder and upper arm level, left arm, initial',
  'encounter',
  'S46812D Strain of other muscles, fascia and tendons at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S46812S Strain of other muscles, fascia and tendons at shoulder and upper arm level, left arm, sequela',
  'S46819A Strain of other muscles, fascia and tendons at shoulder and upper arm level, unspecified arm,',
  'initial encounter',
  'S46819D Strain of other muscles, fascia and tendons at shoulder and upper arm level, unspecified arm,',
  'subsequent encounter',
  'S46819S Strain of other muscles, fascia and tendons at shoulder and upper arm level, unspecified arm,',
  'sequela',
  'S46821A Laceration of other muscles, fascia and tendons at shoulder and upper arm level, right arm,',
  'initial encounter',
  'S46821D Laceration of other muscles, fascia and tendons at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S46821S Laceration of other muscles, fascia and tendons at shoulder and upper arm level, right arm,',
  'sequela',
  'S46822A Laceration of other muscles, fascia and tendons at shoulder and upper arm level, left arm,',
  'initial encounter',
  'S46822D Laceration of other muscles, fascia and tendons at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S46822S Laceration of other muscles, fascia and tendons at shoulder and upper arm level, left arm,',
  'sequela',
  'S46829A Laceration of other muscles, fascia and tendons at shoulder and upper arm level, unspecified',
  'arm, initial encounter',
  'S46829D Laceration of other muscles, fascia and tendons at shoulder and upper arm level, unspecified',
  'arm, subsequent encounter',
  'S46829S Laceration of other muscles, fascia and tendons at shoulder and upper arm level, unspecified',
  'arm, sequela',
  'S46891A Other injury of other muscles, fascia and tendons at shoulder and upper arm level, right arm,',
  'initial encounter',
  'S46891D Other injury of other muscles, fascia and tendons at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S46891S Other injury of other muscles, fascia and tendons at shoulder and upper arm level, right arm,',
  'sequela',
  'S46892A Other injury of other muscles, fascia and tendons at shoulder and upper arm level, left arm,',
  'initial encounter',
  'S46892D Other injury of other muscles, fascia and tendons at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S46892S Other injury of other muscles, fascia and tendons at shoulder and upper arm level, left arm,',
  'sequela',
  'S46899A Other injury of other muscles, fascia and tendons at shoulder and upper arm level, unspecified',
  'arm, initial encounter',
  'S46899D Other injury of other muscles, fascia and tendons at shoulder and upper arm level, unspecified',
  'arm, subsequent encounter',
  'S46899S Other injury of other muscles, fascia and tendons at shoulder and upper arm level, unspecified',
  'arm, sequela',
  'S46901A Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'right arm, initial encounter',
  'S46901D Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'right arm, subsequent encounter',
  'S46901S Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'right arm, sequela',
  'S46902A Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'left arm, initial encounter',
  'S46902D Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'left arm, subsequent encounter',
  'S46902S Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'left arm, sequela',
  'S46909A Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, initial encounter',
  'S46909D Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, subsequent encounter',
  'S46909S Unspecified injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, sequela',
  'S46911A Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, right arm,',
  'initial encounter',
  'S46911D Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, right arm,',
  'subsequent encounter',
  'S46911S Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, right arm,',
  'sequela',
  'S46912A Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm,',
  'initial encounter',
  'S46912D Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S46912S Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm,',
  'sequela',
  'S46919A Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, unspecified',
  'arm, initial encounter',
  'S46919D Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, unspecified',
  'arm, subsequent encounter',
  'S46919S Strain of unspecified muscle, fascia and tendon at shoulder and upper arm level, unspecified',
  'arm, sequela',
  'S46921A Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level, right arm,',
  'initial encounter',
  'S46921D Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level, right',
  'arm, subsequent encounter',
  'S46921S Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level, right arm,',
  'sequela',
  'S46922A Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm,',
  'initial encounter',
  'S46922D Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm,',
  'subsequent encounter',
  'S46922S Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm,',
  'sequela',
  'S46929A Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, initial encounter',
  'S46929D Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, subsequent encounter',
  'S46929S Laceration of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, sequela',
  'S46991A Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level, right',
  'arm, initial encounter',
  'S46991D Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level, right',
  'arm, subsequent encounter',
  'S46991S Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level, right',
  'arm, sequela',
  'S46992A Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level, left',
  'arm, initial encounter',
  'S46992D Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level, left',
  'arm, subsequent encounter',
  'S46992S Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level, left arm,',
  'sequela',
  'S46999A Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, initial encounter',
  'S46999D Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, subsequent encounter',
  'S46999S Other injury of unspecified muscle, fascia and tendon at shoulder and upper arm level,',
  'unspecified arm, sequela',
  'S471XXA Crushing injury of right shoulder and upper arm, initial encounter',
  'S471XXD Crushing injury of right shoulder and upper arm, subsequent encounter',
  'S471XXS Crushing injury of right shoulder and upper arm, sequela',
  'S472XXA Crushing injury of left shoulder and upper arm, initial encounter',
  'S472XXD Crushing injury of left shoulder and upper arm, subsequent encounter',
  'S472XXS Crushing injury of left shoulder and upper arm, sequela',
  'S479XXA Crushing injury of shoulder and upper arm, unspecified arm, initial encounter',
  'S479XXD Crushing injury of shoulder and upper arm, unspecified arm, subsequent encounter',
  'S479XXS Crushing injury of shoulder and upper arm, unspecified arm, sequela',
  'S48011A Complete traumatic amputation at right shoulder joint, initial encounter',
  'S48011D Complete traumatic amputation at right shoulder joint, subsequent encounter',
  'S48011S Complete traumatic amputation at right shoulder joint, sequela',
  'S48012A Complete traumatic amputation at left shoulder joint, initial encounter',
  'S48012D Complete traumatic amputation at left shoulder joint, subsequent encounter',
  'S48012S Complete traumatic amputation at left shoulder joint, sequela',
  'S48019A Complete traumatic amputation at unspecified shoulder joint, initial encounter',
  'S48019D Complete traumatic amputation at unspecified shoulder joint, subsequent encounter',
  'S48019S Complete traumatic amputation at unspecified shoulder joint, sequela',
  'S48021A Partial traumatic amputation at right shoulder joint, initial encounter',
  'S48021D Partial traumatic amputation at right shoulder joint, subsequent encounter',
  'S48021S Partial traumatic amputation at right shoulder joint, sequela',
  'S48022A Partial traumatic amputation at left shoulder joint, initial encounter',
  'S48022D Partial traumatic amputation at left shoulder joint, subsequent encounter',
  'S48022S Partial traumatic amputation at left shoulder joint, sequela',
  'S48029A Partial traumatic amputation at unspecified shoulder joint, initial encounter',
  'S48029D Partial traumatic amputation at unspecified shoulder joint, subsequent encounter',
  'S48029S Partial traumatic amputation at unspecified shoulder joint, sequela',
  'S48111A Complete traumatic amputation at level between right shoulder and elbow, initial encounter',
  'S48111D Complete traumatic amputation at level between right shoulder and elbow, subsequent',
  'encounter',
  'S48111S Complete traumatic amputation at level between right shoulder and elbow, sequela',
  'S48112A Complete traumatic amputation at level between left shoulder and elbow, initial encounter',
  'S48112D Complete traumatic amputation at level between left shoulder and elbow, subsequent',
  'encounter',
  'S48112S Complete traumatic amputation at level between left shoulder and elbow, sequela',
  'S48119A Complete traumatic amputation at level between unspecified shoulder and elbow, initial',
  'encounter',
  'S48119D Complete traumatic amputation at level between unspecified shoulder and elbow, subsequent',
  'encounter',
  'S48119S Complete traumatic amputation at level between unspecified shoulder and elbow, sequela',
  'S48121A Partial traumatic amputation at level between right shoulder and elbow, initial encounter',
  'S48121D Partial traumatic amputation at level between right shoulder and elbow, subsequent',
  'encounter',
  'S48121S Partial traumatic amputation at level between right shoulder and elbow, sequela',
  'S48122A Partial traumatic amputation at level between left shoulder and elbow, initial encounter',
  'S48122D Partial traumatic amputation at level between left shoulder and elbow, subsequent encounter',
  'S48122S Partial traumatic amputation at level between left shoulder and elbow, sequela',
  'S48129A Partial traumatic amputation at level between unspecified shoulder and elbow, initial',
  'encounter',
  'S48129D Partial traumatic amputation at level between unspecified shoulder and elbow, subsequent',
  'encounter',
  'S48129S Partial traumatic amputation at level between unspecified shoulder and elbow, sequela',
  'S48911A Complete traumatic amputation of right shoulder and upper arm, level unspecified, initial',
  'encounter',
  'S48911D Complete traumatic amputation of right shoulder and upper arm, level unspecified,',
  'subsequent encounter',
  'S48911S Complete traumatic amputation of right shoulder and upper arm, level unspecified, sequela',
  'S48912A Complete traumatic amputation of left shoulder and upper arm, level unspecified, initial',
  'encounter',
  'S48912D Complete traumatic amputation of left shoulder and upper arm, level unspecified, subsequent',
  'encounter',
  'S48912S Complete traumatic amputation of left shoulder and upper arm, level unspecified, sequela',
  'S48919A Complete traumatic amputation of unspecified shoulder and upper arm, level unspecified,',
  'initial encounter',
  'S48919D Complete traumatic amputation of unspecified shoulder and upper arm, level unspecified,',
  'subsequent encounter',
  'S48919S Complete traumatic amputation of unspecified shoulder and upper arm, level unspecified,',
  'sequela',
  'S48921A Partial traumatic amputation of right shoulder and upper arm, level unspecified, initial',
  'encounter',
  'S48921D Partial traumatic amputation of right shoulder and upper arm, level unspecified, subsequent',
  'encounter',
  'S48921S Partial traumatic amputation of right shoulder and upper arm, level unspecified, sequela',
  'S48922A Partial traumatic amputation of left shoulder and upper arm, level unspecified, initial',
  'encounter',
  'S48922D Partial traumatic amputation of left shoulder and upper arm, level unspecified, subsequent',
  'encounter',
  'S48922S Partial traumatic amputation of left shoulder and upper arm, level unspecified, sequela',
  'S48929A Partial traumatic amputation of unspecified shoulder and upper arm, level unspecified, initial',
  'encounter',
  'S48929D Partial traumatic amputation of unspecified shoulder and upper arm, level unspecified,',
  'subsequent encounter',
  'S48929S Partial traumatic amputation of unspecified shoulder and upper arm, level unspecified, sequela',
  'S49001A Unspecified physeal fracture of upper end of humerus, right arm, initial encounter for closed',
  'fracture',
  'S49001D Unspecified physeal fracture of upper end of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S49001G Unspecified physeal fracture of upper end of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S49001K Unspecified physeal fracture of upper end of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S49001P Unspecified physeal fracture of upper end of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S49001S Unspecified physeal fracture of upper end of humerus, right arm, sequela',
  'S49002A Unspecified physeal fracture of upper end of humerus, left arm, initial encounter for closed',
  'fracture',
  'S49002D Unspecified physeal fracture of upper end of humerus, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S49002G Unspecified physeal fracture of upper end of humerus, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S49002K Unspecified physeal fracture of upper end of humerus, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S49002P Unspecified physeal fracture of upper end of humerus, left arm, subsequent encounter for',
  'fracture with malunion',
  'S49002S Unspecified physeal fracture of upper end of humerus, left arm, sequela',
  'S49009A Unspecified physeal fracture of upper end of humerus, unspecified arm, initial encounter for',
  'closed fracture',
  'S49009D Unspecified physeal fracture of upper end of humerus, unspecified arm, subsequent encounter',
  'for fracture with routine healing',
  'S49009G Unspecified physeal fracture of upper end of humerus, unspecified arm, subsequent encounter',
  'for fracture with delayed healing',
  'S49009K Unspecified physeal fracture of upper end of humerus, unspecified arm, subsequent encounter',
  'for fracture with nonunion',
  'S49009P Unspecified physeal fracture of upper end of humerus, unspecified arm, subsequent encounter',
  'for fracture with malunion',
  'S49009S Unspecified physeal fracture of upper end of humerus, unspecified arm, sequela',
  'S49011A Salter-Harris Type I physeal fracture of upper end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49011D Salter-Harris Type I physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49011G Salter-Harris Type I physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49011K Salter-Harris Type I physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with nonunion',
  'S49011P Salter-Harris Type I physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with malunion',
  'S49011S Salter-Harris Type I physeal fracture of upper end of humerus, right arm, sequela',
  'S49012A Salter-Harris Type I physeal fracture of upper end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49012D Salter-Harris Type I physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S49012G Salter-Harris Type I physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S49012K Salter-Harris Type I physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S49012P Salter-Harris Type I physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with malunion',
  'S49012S Salter-Harris Type I physeal fracture of upper end of humerus, left arm, sequela',
  'S49019A Salter-Harris Type I physeal fracture of upper end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49019D Salter-Harris Type I physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49019G Salter-Harris Type I physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49019K Salter-Harris Type I physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49019P Salter-Harris Type I physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49019S Salter-Harris Type I physeal fracture of upper end of humerus, unspecified arm, sequela',
  'S49021A Salter-Harris Type II physeal fracture of upper end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49021D Salter-Harris Type II physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49021G Salter-Harris Type II physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49021K Salter-Harris Type II physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with nonunion',
  'S49021P Salter-Harris Type II physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with malunion',
  'S49021S Salter-Harris Type II physeal fracture of upper end of humerus, right arm, sequela',
  'S49022A Salter-Harris Type II physeal fracture of upper end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49022D Salter-Harris Type II physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S49022G Salter-Harris Type II physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S49022K Salter-Harris Type II physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S49022P Salter-Harris Type II physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with malunion',
  'S49022S Salter-Harris Type II physeal fracture of upper end of humerus, left arm, sequela',
  'S49029A Salter-Harris Type II physeal fracture of upper end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49029D Salter-Harris Type II physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49029G Salter-Harris Type II physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49029K Salter-Harris Type II physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49029P Salter-Harris Type II physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49029S Salter-Harris Type II physeal fracture of upper end of humerus, unspecified arm, sequela',
  'S49031A Salter-Harris Type III physeal fracture of upper end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49031D Salter-Harris Type III physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49031G Salter-Harris Type III physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49031K Salter-Harris Type III physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with nonunion',
  'S49031P Salter-Harris Type III physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with malunion',
  'S49031S Salter-Harris Type III physeal fracture of upper end of humerus, right arm, sequela',
  'S49032A Salter-Harris Type III physeal fracture of upper end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49032D Salter-Harris Type III physeal fracture of upper end of humerus, left arm, subsequent',
  'encounter for fracture with routine healing',
  'S49032G Salter-Harris Type III physeal fracture of upper end of humerus, left arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49032K Salter-Harris Type III physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S49032P Salter-Harris Type III physeal fracture of upper end of humerus, left arm, subsequent encounter',
  'for fracture with malunion',
  'S49032S Salter-Harris Type III physeal fracture of upper end of humerus, left arm, sequela',
  'S49039A Salter-Harris Type III physeal fracture of upper end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49039D Salter-Harris Type III physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49039G Salter-Harris Type III physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49039K Salter-Harris Type III physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49039P Salter-Harris Type III physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49039S Salter-Harris Type III physeal fracture of upper end of humerus, unspecified arm, sequela',
  'S49041A Salter-Harris Type IV physeal fracture of upper end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49041D Salter-Harris Type IV physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49041G Salter-Harris Type IV physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49041K Salter-Harris Type IV physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with nonunion',
  'S49041P Salter-Harris Type IV physeal fracture of upper end of humerus, right arm, subsequent',
  'encounter for fracture with malunion',
  'S49041S Salter-Harris Type IV physeal fracture of upper end of humerus, right arm, sequela',
  'S49042A Salter-Harris Type IV physeal fracture of upper end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49042D Salter-Harris Type IV physeal fracture of upper end of humerus, left arm, subsequent',
  'encounter for fracture with routine healing',
  'S49042G Salter-Harris Type IV physeal fracture of upper end of humerus, left arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49042K Salter-Harris Type IV physeal fracture of upper end of humerus, left arm, subsequent',
  'encounter for fracture with nonunion',
  'S49042P Salter-Harris Type IV physeal fracture of upper end of humerus, left arm, subsequent',
  'encounter for fracture with malunion',
  'S49042S Salter-Harris Type IV physeal fracture of upper end of humerus, left arm, sequela',
  'S49049A Salter-Harris Type IV physeal fracture of upper end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49049D Salter-Harris Type IV physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49049G Salter-Harris Type IV physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49049K Salter-Harris Type IV physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49049P Salter-Harris Type IV physeal fracture of upper end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49049S Salter-Harris Type IV physeal fracture of upper end of humerus, unspecified arm, sequela',
  'S49091A Other physeal fracture of upper end of humerus, right arm, initial encounter for closed fracture',
  'S49091D Other physeal fracture of upper end of humerus, right arm, subsequent encounter for fracture',
  'with routine healing',
  'S49091G Other physeal fracture of upper end of humerus, right arm, subsequent encounter for fracture',
  'with delayed healing',
  'S49091K Other physeal fracture of upper end of humerus, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S49091P Other physeal fracture of upper end of humerus, right arm, subsequent encounter for fracture',
  'with malunion',
  'S49091S Other physeal fracture of upper end of humerus, right arm, sequela',
  'S49092A Other physeal fracture of upper end of humerus, left arm, initial encounter for closed fracture',
  'S49092D Other physeal fracture of upper end of humerus, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S49092G Other physeal fracture of upper end of humerus, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S49092K Other physeal fracture of upper end of humerus, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S49092P Other physeal fracture of upper end of humerus, left arm, subsequent encounter for fracture',
  'with malunion',
  'S49092S Other physeal fracture of upper end of humerus, left arm, sequela',
  'S49099A Other physeal fracture of upper end of humerus, unspecified arm, initial encounter for closed',
  'fracture',
  'S49099D Other physeal fracture of upper end of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S49099G Other physeal fracture of upper end of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S49099K Other physeal fracture of upper end of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S49099P Other physeal fracture of upper end of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S49099S Other physeal fracture of upper end of humerus, unspecified arm, sequela',
  'S49101A Unspecified physeal fracture of lower end of humerus, right arm, initial encounter for closed',
  'fracture',
  'S49101D Unspecified physeal fracture of lower end of humerus, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S49101G Unspecified physeal fracture of lower end of humerus, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S49101K Unspecified physeal fracture of lower end of humerus, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S49101P Unspecified physeal fracture of lower end of humerus, right arm, subsequent encounter for',
  'fracture with malunion',
  'S49101S Unspecified physeal fracture of lower end of humerus, right arm, sequela',
  'S49102A Unspecified physeal fracture of lower end of humerus, left arm, initial encounter for closed',
  'fracture',
  'S49102D Unspecified physeal fracture of lower end of humerus, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S49102G Unspecified physeal fracture of lower end of humerus, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S49102K Unspecified physeal fracture of lower end of humerus, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S49102P Unspecified physeal fracture of lower end of humerus, left arm, subsequent encounter for',
  'fracture with malunion',
  'S49102S Unspecified physeal fracture of lower end of humerus, left arm, sequela',
  'S49109A Unspecified physeal fracture of lower end of humerus, unspecified arm, initial encounter for',
  'closed fracture',
  'S49109D Unspecified physeal fracture of lower end of humerus, unspecified arm, subsequent encounter',
  'for fracture with routine healing',
  'S49109G Unspecified physeal fracture of lower end of humerus, unspecified arm, subsequent encounter',
  'for fracture with delayed healing',
  'S49109K Unspecified physeal fracture of lower end of humerus, unspecified arm, subsequent encounter',
  'for fracture with nonunion',
  'S49109P Unspecified physeal fracture of lower end of humerus, unspecified arm, subsequent encounter',
  'for fracture with malunion',
  'S49109S Unspecified physeal fracture of lower end of humerus, unspecified arm, sequela',
  'S49111A Salter-Harris Type I physeal fracture of lower end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49111D Salter-Harris Type I physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49111G Salter-Harris Type I physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49111K Salter-Harris Type I physeal fracture of lower end of humerus, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S49111P Salter-Harris Type I physeal fracture of lower end of humerus, right arm, subsequent encounter',
  'for fracture with malunion',
  'S49111S Salter-Harris Type I physeal fracture of lower end of humerus, right arm, sequela',
  'S49112A Salter-Harris Type I physeal fracture of lower end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49112D Salter-Harris Type I physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S49112G Salter-Harris Type I physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S49112K Salter-Harris Type I physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S49112P Salter-Harris Type I physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with malunion',
  'S49112S Salter-Harris Type I physeal fracture of lower end of humerus, left arm, sequela',
  'S49119A Salter-Harris Type I physeal fracture of lower end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49119D Salter-Harris Type I physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49119G Salter-Harris Type I physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49119K Salter-Harris Type I physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49119P Salter-Harris Type I physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49119S Salter-Harris Type I physeal fracture of lower end of humerus, unspecified arm, sequela',
  'S49121A Salter-Harris Type II physeal fracture of lower end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49121D Salter-Harris Type II physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49121G Salter-Harris Type II physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49121K Salter-Harris Type II physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with nonunion',
  'S49121P Salter-Harris Type II physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with malunion',
  'S49121S Salter-Harris Type II physeal fracture of lower end of humerus, right arm, sequela',
  'S49122A Salter-Harris Type II physeal fracture of lower end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49122D Salter-Harris Type II physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S49122G Salter-Harris Type II physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S49122K Salter-Harris Type II physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S49122P Salter-Harris Type II physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with malunion',
  'S49122S Salter-Harris Type II physeal fracture of lower end of humerus, left arm, sequela',
  'S49129A Salter-Harris Type II physeal fracture of lower end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49129D Salter-Harris Type II physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49129G Salter-Harris Type II physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49129K Salter-Harris Type II physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49129P Salter-Harris Type II physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49129S Salter-Harris Type II physeal fracture of lower end of humerus, unspecified arm, sequela',
  'S49131A Salter-Harris Type III physeal fracture of lower end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49131D Salter-Harris Type III physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49131G Salter-Harris Type III physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49131K Salter-Harris Type III physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with nonunion',
  'S49131P Salter-Harris Type III physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with malunion',
  'S49131S Salter-Harris Type III physeal fracture of lower end of humerus, right arm, sequela',
  'S49132A Salter-Harris Type III physeal fracture of lower end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49132D Salter-Harris Type III physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S49132G Salter-Harris Type III physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S49132K Salter-Harris Type III physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S49132P Salter-Harris Type III physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with malunion',
  'S49132S Salter-Harris Type III physeal fracture of lower end of humerus, left arm, sequela',
  'S49139A Salter-Harris Type III physeal fracture of lower end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49139D Salter-Harris Type III physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49139G Salter-Harris Type III physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49139K Salter-Harris Type III physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49139P Salter-Harris Type III physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49139S Salter-Harris Type III physeal fracture of lower end of humerus, unspecified arm, sequela',
  'S49141A Salter-Harris Type IV physeal fracture of lower end of humerus, right arm, initial encounter for',
  'closed fracture',
  'S49141D Salter-Harris Type IV physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with routine healing',
  'S49141G Salter-Harris Type IV physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49141K Salter-Harris Type IV physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with nonunion',
  'S49141P Salter-Harris Type IV physeal fracture of lower end of humerus, right arm, subsequent',
  'encounter for fracture with malunion',
  'S49141S Salter-Harris Type IV physeal fracture of lower end of humerus, right arm, sequela',
  'S49142A Salter-Harris Type IV physeal fracture of lower end of humerus, left arm, initial encounter for',
  'closed fracture',
  'S49142D Salter-Harris Type IV physeal fracture of lower end of humerus, left arm, subsequent',
  'encounter for fracture with routine healing',
  'S49142G Salter-Harris Type IV physeal fracture of lower end of humerus, left arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49142K Salter-Harris Type IV physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S49142P Salter-Harris Type IV physeal fracture of lower end of humerus, left arm, subsequent encounter',
  'for fracture with malunion',
  'S49142S Salter-Harris Type IV physeal fracture of lower end of humerus, left arm, sequela',
  'S49149A Salter-Harris Type IV physeal fracture of lower end of humerus, unspecified arm, initial',
  'encounter for closed fracture',
  'S49149D Salter-Harris Type IV physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S49149G Salter-Harris Type IV physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S49149K Salter-Harris Type IV physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S49149P Salter-Harris Type IV physeal fracture of lower end of humerus, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S49149S Salter-Harris Type IV physeal fracture of lower end of humerus, unspecified arm, sequela',
  'S49191A Other physeal fracture of lower end of humerus, right arm, initial encounter for closed fracture',
  'S49191D Other physeal fracture of lower end of humerus, right arm, subsequent encounter for fracture',
  'with routine healing',
  'S49191G Other physeal fracture of lower end of humerus, right arm, subsequent encounter for fracture',
  'with delayed healing',
  'S49191K Other physeal fracture of lower end of humerus, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S49191P Other physeal fracture of lower end of humerus, right arm, subsequent encounter for fracture',
  'with malunion',
  'S49191S Other physeal fracture of lower end of humerus, right arm, sequela',
  'S49192A Other physeal fracture of lower end of humerus, left arm, initial encounter for closed fracture',
  'S49192D Other physeal fracture of lower end of humerus, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S49192G Other physeal fracture of lower end of humerus, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S49192K Other physeal fracture of lower end of humerus, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S49192P Other physeal fracture of lower end of humerus, left arm, subsequent encounter for fracture',
  'with malunion',
  'S49192S Other physeal fracture of lower end of humerus, left arm, sequela',
  'S49199A Other physeal fracture of lower end of humerus, unspecified arm, initial encounter for closed',
  'fracture',
  'S49199D Other physeal fracture of lower end of humerus, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S49199G Other physeal fracture of lower end of humerus, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S49199K Other physeal fracture of lower end of humerus, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S49199P Other physeal fracture of lower end of humerus, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S49199S Other physeal fracture of lower end of humerus, unspecified arm, sequela',
  'S4980XA Other specified injuries of shoulder and upper arm, unspecified arm, initial encounter',
  'S4980XD Other specified injuries of shoulder and upper arm, unspecified arm, subsequent encounter',
  'S4980XS Other specified injuries of shoulder and upper arm, unspecified arm, sequela',
  'S4981XA Other specified injuries of right shoulder and upper arm, initial encounter',
  'S4981XD Other specified injuries of right shoulder and upper arm, subsequent encounter',
  'S4981XS Other specified injuries of right shoulder and upper arm, sequela',
  'S4982XA Other specified injuries of left shoulder and upper arm, initial encounter',
  'S4982XD Other specified injuries of left shoulder and upper arm, subsequent encounter',
  'S4982XS Other specified injuries of left shoulder and upper arm, sequela',
  'S4990XA Unspecified injury of shoulder and upper arm, unspecified arm, initial encounter',
  'S4990XD Unspecified injury of shoulder and upper arm, unspecified arm, subsequent encounter',
  'S4990XS Unspecified injury of shoulder and upper arm, unspecified arm, sequela',
  'S4991XA Unspecified injury of right shoulder and upper arm, initial encounter',
  'S4991XD Unspecified injury of right shoulder and upper arm, subsequent encounter',
  'S4991XS Unspecified injury of right shoulder and upper arm, sequela',
  'S4992XA Unspecified injury of left shoulder and upper arm, initial encounter',
  'S4992XD Unspecified injury of left shoulder and upper arm, subsequent encounter',
  'S4992XS Unspecified injury of left shoulder and upper arm, sequela',
  'S5000XA Contusion of unspecified elbow, initial encounter',
  'S5000XD Contusion of unspecified elbow, subsequent encounter',
  'S5000XS Contusion of unspecified elbow, sequela',
  'S5001XA Contusion of right elbow, initial encounter',
  'S5001XD Contusion of right elbow, subsequent encounter',
  'S5001XS Contusion of right elbow, sequela',
  'S5002XA Contusion of left elbow, initial encounter',
  'S5002XD Contusion of left elbow, subsequent encounter',
  'S5002XS Contusion of left elbow, sequela',
  'S5010XA Contusion of unspecified forearm, initial encounter',
  'S5010XD Contusion of unspecified forearm, subsequent encounter',
  'S5010XS Contusion of unspecified forearm, sequela',
  'S5011XA Contusion of right forearm, initial encounter',
  'S5011XD Contusion of right forearm, subsequent encounter',
  'S5011XS Contusion of right forearm, sequela',
  'S5012XA Contusion of left forearm, initial encounter',
  'S5012XD Contusion of left forearm, subsequent encounter',
  'S5012XS Contusion of left forearm, sequela',
  'S50311A Abrasion of right elbow, initial encounter',
  'S50311D Abrasion of right elbow, subsequent encounter',
  'S50311S Abrasion of right elbow, sequela',
  'S50312A Abrasion of left elbow, initial encounter',
  'S50312D Abrasion of left elbow, subsequent encounter',
  'S50312S Abrasion of left elbow, sequela',
  'S50319A Abrasion of unspecified elbow, initial encounter',
  'S50319D Abrasion of unspecified elbow, subsequent encounter',
  'S50319S Abrasion of unspecified elbow, sequela',
  'S50321A Blister (nonthermal) of right elbow, initial encounter',
  'S50321D Blister (nonthermal) of right elbow, subsequent encounter',
  'S50321S Blister (nonthermal) of right elbow, sequela',
  'S50322A Blister (nonthermal) of left elbow, initial encounter',
  'S50322D Blister (nonthermal) of left elbow, subsequent encounter',
  'S50322S Blister (nonthermal) of left elbow, sequela',
  'S50329A Blister (nonthermal) of unspecified elbow, initial encounter',
  'S50329D Blister (nonthermal) of unspecified elbow, subsequent encounter',
  'S50329S Blister (nonthermal) of unspecified elbow, sequela',
  'S50341A External constriction of right elbow, initial encounter',
  'S50341D External constriction of right elbow, subsequent encounter',
  'S50341S External constriction of right elbow, sequela',
  'S50342A External constriction of left elbow, initial encounter',
  'S50342D External constriction of left elbow, subsequent encounter',
  'S50342S External constriction of left elbow, sequela',
  'S50349A External constriction of unspecified elbow, initial encounter',
  'S50349D External constriction of unspecified elbow, subsequent encounter',
  'S50349S External constriction of unspecified elbow, sequela',
  'S50351A Superficial foreign body of right elbow, initial encounter',
  'S50351D Superficial foreign body of right elbow, subsequent encounter',
  'S50351S Superficial foreign body of right elbow, sequela',
  'S50352A Superficial foreign body of left elbow, initial encounter',
  'S50352D Superficial foreign body of left elbow, subsequent encounter',
  'S50352S Superficial foreign body of left elbow, sequela',
  'S50359A Superficial foreign body of unspecified elbow, initial encounter',
  'S50359D Superficial foreign body of unspecified elbow, subsequent encounter',
  'S50359S Superficial foreign body of unspecified elbow, sequela',
  'S50361A Insect bite (nonvenomous) of right elbow, initial encounter',
  'S50361D Insect bite (nonvenomous) of right elbow, subsequent encounter',
  'S50361S Insect bite (nonvenomous) of right elbow, sequela',
  'S50362A Insect bite (nonvenomous) of left elbow, initial encounter',
  'S50362D Insect bite (nonvenomous) of left elbow, subsequent encounter',
  'S50362S Insect bite (nonvenomous) of left elbow, sequela',
  'S50369A Insect bite (nonvenomous) of unspecified elbow, initial encounter',
  'S50369D Insect bite (nonvenomous) of unspecified elbow, subsequent encounter',
  'S50369S Insect bite (nonvenomous) of unspecified elbow, sequela',
  'S50371A Other superficial bite of right elbow, initial encounter',
  'S50371D Other superficial bite of right elbow, subsequent encounter',
  'S50371S Other superficial bite of right elbow, sequela',
  'S50372A Other superficial bite of left elbow, initial encounter',
  'S50372D Other superficial bite of left elbow, subsequent encounter',
  'S50372S Other superficial bite of left elbow, sequela',
  'S50379A Other superficial bite of unspecified elbow, initial encounter',
  'S50379D Other superficial bite of unspecified elbow, subsequent encounter',
  'S50379S Other superficial bite of unspecified elbow, sequela',
  'S50811A Abrasion of right forearm, initial encounter',
  'S50811D Abrasion of right forearm, subsequent encounter',
  'S50811S Abrasion of right forearm, sequela',
  'S50812A Abrasion of left forearm, initial encounter',
  'S50812D Abrasion of left forearm, subsequent encounter',
  'S50812S Abrasion of left forearm, sequela',
  'S50819A Abrasion of unspecified forearm, initial encounter',
  'S50819D Abrasion of unspecified forearm, subsequent encounter',
  'S50819S Abrasion of unspecified forearm, sequela',
  'S50821A Blister (nonthermal) of right forearm, initial encounter',
  'S50821D Blister (nonthermal) of right forearm, subsequent encounter',
  'S50821S Blister (nonthermal) of right forearm, sequela',
  'S50822A Blister (nonthermal) of left forearm, initial encounter',
  'S50822D Blister (nonthermal) of left forearm, subsequent encounter',
  'S50822S Blister (nonthermal) of left forearm, sequela',
  'S50829A Blister (nonthermal) of unspecified forearm, initial encounter',
  'S50829D Blister (nonthermal) of unspecified forearm, subsequent encounter',
  'S50829S Blister (nonthermal) of unspecified forearm, sequela',
  'S50841A External constriction of right forearm, initial encounter',
  'S50841D External constriction of right forearm, subsequent encounter',
  'S50841S External constriction of right forearm, sequela',
  'S50842A External constriction of left forearm, initial encounter',
  'S50842D External constriction of left forearm, subsequent encounter',
  'S50842S External constriction of left forearm, sequela',
  'S50849A External constriction of unspecified forearm, initial encounter',
  'S50849D External constriction of unspecified forearm, subsequent encounter',
  'S50849S External constriction of unspecified forearm, sequela',
  'S50851A Superficial foreign body of right forearm, initial encounter',
  'S50851D Superficial foreign body of right forearm, subsequent encounter',
  'S50851S Superficial foreign body of right forearm, sequela',
  'S50852A Superficial foreign body of left forearm, initial encounter',
  'S50852D Superficial foreign body of left forearm, subsequent encounter',
  'S50852S Superficial foreign body of left forearm, sequela',
  'S50859A Superficial foreign body of unspecified forearm, initial encounter',
  'S50859D Superficial foreign body of unspecified forearm, subsequent encounter',
  'S50859S Superficial foreign body of unspecified forearm, sequela',
  'S50861A Insect bite (nonvenomous) of right forearm, initial encounter',
  'S50861D Insect bite (nonvenomous) of right forearm, subsequent encounter',
  'S50861S Insect bite (nonvenomous) of right forearm, sequela',
  'S50862A Insect bite (nonvenomous) of left forearm, initial encounter',
  'S50862D Insect bite (nonvenomous) of left forearm, subsequent encounter',
  'S50862S Insect bite (nonvenomous) of left forearm, sequela',
  'S50869A Insect bite (nonvenomous) of unspecified forearm, initial encounter',
  'S50869D Insect bite (nonvenomous) of unspecified forearm, subsequent encounter',
  'S50869S Insect bite (nonvenomous) of unspecified forearm, sequela',
  'S50871A Other superficial bite of right forearm, initial encounter',
  'S50871D Other superficial bite of right forearm, subsequent encounter',
  'S50871S Other superficial bite of right forearm, sequela',
  'S50872A Other superficial bite of left forearm, initial encounter',
  'S50872D Other superficial bite of left forearm, subsequent encounter',
  'S50872S Other superficial bite of left forearm, sequela',
  'S50879A Other superficial bite of unspecified forearm, initial encounter',
  'S50879D Other superficial bite of unspecified forearm, subsequent encounter',
  'S50879S Other superficial bite of unspecified forearm, sequela',
  'S50901A Unspecified superficial injury of right elbow, initial encounter',
  'S50901D Unspecified superficial injury of right elbow, subsequent encounter',
  'S50901S Unspecified superficial injury of right elbow, sequela',
  'S50902A Unspecified superficial injury of left elbow, initial encounter',
  'S50902D Unspecified superficial injury of left elbow, subsequent encounter',
  'S50902S Unspecified superficial injury of left elbow, sequela',
  'S50909A Unspecified superficial injury of unspecified elbow, initial encounter',
  'S50909D Unspecified superficial injury of unspecified elbow, subsequent encounter',
  'S50909S Unspecified superficial injury of unspecified elbow, sequela',
  'S50911A Unspecified superficial injury of right forearm, initial encounter',
  'S50911D Unspecified superficial injury of right forearm, subsequent encounter',
  'S50911S Unspecified superficial injury of right forearm, sequela',
  'S50912A Unspecified superficial injury of left forearm, initial encounter',
  'S50912D Unspecified superficial injury of left forearm, subsequent encounter',
  'S50912S Unspecified superficial injury of left forearm, sequela',
  'S50919A Unspecified superficial injury of unspecified forearm, initial encounter',
  'S50919D Unspecified superficial injury of unspecified forearm, subsequent encounter',
  'S50919S Unspecified superficial injury of unspecified forearm, sequela',
  'S51001A Unspecified open wound of right elbow, initial encounter',
  'S51001D Unspecified open wound of right elbow, subsequent encounter',
  'S51001S Unspecified open wound of right elbow, sequela',
  'S51002A Unspecified open wound of left elbow, initial encounter',
  'S51002D Unspecified open wound of left elbow, subsequent encounter',
  'S51002S Unspecified open wound of left elbow, sequela',
  'S51009A Unspecified open wound of unspecified elbow, initial encounter',
  'S51009D Unspecified open wound of unspecified elbow, subsequent encounter',
  'S51009S Unspecified open wound of unspecified elbow, sequela',
  'S51011A Laceration without foreign body of right elbow, initial encounter',
  'S51011D Laceration without foreign body of right elbow, subsequent encounter',
  'S51011S Laceration without foreign body of right elbow, sequela',
  'S51012A Laceration without foreign body of left elbow, initial encounter',
  'S51012D Laceration without foreign body of left elbow, subsequent encounter',
  'S51012S Laceration without foreign body of left elbow, sequela',
  'S51019A Laceration without foreign body of unspecified elbow, initial encounter',
  'S51019D Laceration without foreign body of unspecified elbow, subsequent encounter',
  'S51019S Laceration without foreign body of unspecified elbow, sequela',
  'S51021A Laceration with foreign body of right elbow, initial encounter',
  'S51021D Laceration with foreign body of right elbow, subsequent encounter',
  'S51021S Laceration with foreign body of right elbow, sequela',
  'S51022A Laceration with foreign body of left elbow, initial encounter',
  'S51022D Laceration with foreign body of left elbow, subsequent encounter',
  'S51022S Laceration with foreign body of left elbow, sequela',
  'S51029A Laceration with foreign body of unspecified elbow, initial encounter',
  'S51029D Laceration with foreign body of unspecified elbow, subsequent encounter',
  'S51029S Laceration with foreign body of unspecified elbow, sequela',
  'S51031A Puncture wound without foreign body of right elbow, initial encounter',
  'S51031D Puncture wound without foreign body of right elbow, subsequent encounter',
  'S51031S Puncture wound without foreign body of right elbow, sequela',
  'S51032A Puncture wound without foreign body of left elbow, initial encounter',
  'S51032D Puncture wound without foreign body of left elbow, subsequent encounter',
  'S51032S Puncture wound without foreign body of left elbow, sequela',
  'S51039A Puncture wound without foreign body of unspecified elbow, initial encounter',
  'S51039D Puncture wound without foreign body of unspecified elbow, subsequent encounter',
  'S51039S Puncture wound without foreign body of unspecified elbow, sequela',
  'S51041A Puncture wound with foreign body of right elbow, initial encounter',
  'S51041D Puncture wound with foreign body of right elbow, subsequent encounter',
  'S51041S Puncture wound with foreign body of right elbow, sequela',
  'S51042A Puncture wound with foreign body of left elbow, initial encounter',
  'S51042D Puncture wound with foreign body of left elbow, subsequent encounter',
  'S51042S Puncture wound with foreign body of left elbow, sequela',
  'S51049A Puncture wound with foreign body of unspecified elbow, initial encounter',
  'S51049D Puncture wound with foreign body of unspecified elbow, subsequent encounter',
  'S51049S Puncture wound with foreign body of unspecified elbow, sequela',
  'S51051A Open bite, right elbow, initial encounter',
  'S51051D Open bite, right elbow, subsequent encounter',
  'S51051S Open bite, right elbow, sequela',
  'S51052A Open bite, left elbow, initial encounter',
  'S51052D Open bite, left elbow, subsequent encounter',
  'S51052S Open bite, left elbow, sequela',
  'S51059A Open bite, unspecified elbow, initial encounter',
  'S51059D Open bite, unspecified elbow, subsequent encounter',
  'S51059S Open bite, unspecified elbow, sequela',
  'S51801A Unspecified open wound of right forearm, initial encounter',
  'S51801D Unspecified open wound of right forearm, subsequent encounter',
  'S51801S Unspecified open wound of right forearm, sequela',
  'S51802A Unspecified open wound of left forearm, initial encounter',
  'S51802D Unspecified open wound of left forearm, subsequent encounter',
  'S51802S Unspecified open wound of left forearm, sequela',
  'S51809A Unspecified open wound of unspecified forearm, initial encounter',
  'S51809D Unspecified open wound of unspecified forearm, subsequent encounter',
  'S51809S Unspecified open wound of unspecified forearm, sequela',
  'S51811A Laceration without foreign body of right forearm, initial encounter',
  'S51811D Laceration without foreign body of right forearm, subsequent encounter',
  'S51811S Laceration without foreign body of right forearm, sequela',
  'S51812A Laceration without foreign body of left forearm, initial encounter',
  'S51812D Laceration without foreign body of left forearm, subsequent encounter',
  'S51812S Laceration without foreign body of left forearm, sequela',
  'S51819A Laceration without foreign body of unspecified forearm, initial encounter',
  'S51819D Laceration without foreign body of unspecified forearm, subsequent encounter',
  'S51819S Laceration without foreign body of unspecified forearm, sequela',
  'S51821A Laceration with foreign body of right forearm, initial encounter',
  'S51821D Laceration with foreign body of right forearm, subsequent encounter',
  'S51821S Laceration with foreign body of right forearm, sequela',
  'S51822A Laceration with foreign body of left forearm, initial encounter',
  'S51822D Laceration with foreign body of left forearm, subsequent encounter',
  'S51822S Laceration with foreign body of left forearm, sequela',
  'S51829A Laceration with foreign body of unspecified forearm, initial encounter',
  'S51829D Laceration with foreign body of unspecified forearm, subsequent encounter',
  'S51829S Laceration with foreign body of unspecified forearm, sequela',
  'S51831A Puncture wound without foreign body of right forearm, initial encounter',
  'S51831D Puncture wound without foreign body of right forearm, subsequent encounter',
  'S51831S Puncture wound without foreign body of right forearm, sequela',
  'S51832A Puncture wound without foreign body of left forearm, initial encounter',
  'S51832D Puncture wound without foreign body of left forearm, subsequent encounter',
  'S51832S Puncture wound without foreign body of left forearm, sequela',
  'S51839A Puncture wound without foreign body of unspecified forearm, initial encounter',
  'S51839D Puncture wound without foreign body of unspecified forearm, subsequent encounter',
  'S51839S Puncture wound without foreign body of unspecified forearm, sequela',
  'S51841A Puncture wound with foreign body of right forearm, initial encounter',
  'S51841D Puncture wound with foreign body of right forearm, subsequent encounter',
  'S51841S Puncture wound with foreign body of right forearm, sequela',
  'S51842A Puncture wound with foreign body of left forearm, initial encounter',
  'S51842D Puncture wound with foreign body of left forearm, subsequent encounter',
  'S51842S Puncture wound with foreign body of left forearm, sequela',
  'S51849A Puncture wound with foreign body of unspecified forearm, initial encounter',
  'S51849D Puncture wound with foreign body of unspecified forearm, subsequent encounter',
  'S51849S Puncture wound with foreign body of unspecified forearm, sequela',
  'S51851A Open bite of right forearm, initial encounter',
  'S51851D Open bite of right forearm, subsequent encounter',
  'S51851S Open bite of right forearm, sequela',
  'S51852A Open bite of left forearm, initial encounter',
  'S51852D Open bite of left forearm, subsequent encounter',
  'S51852S Open bite of left forearm, sequela',
  'S51859A Open bite of unspecified forearm, initial encounter',
  'S51859D Open bite of unspecified forearm, subsequent encounter',
  'S51859S Open bite of unspecified forearm, sequela',
  'S52001A Unspecified fracture of upper end of right ulna, initial encounter for closed fracture',
  'S52001B Unspecified fracture of upper end of right ulna, initial encounter for open fracture type I or II',
  'S52001C Unspecified fracture of upper end of right ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52001D Unspecified fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52001E Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52001F Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52001G Unspecified fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52001H Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52001J Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52001K Unspecified fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52001M Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52001N Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52001P Unspecified fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52001Q Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52001R Unspecified fracture of upper end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52001S Unspecified fracture of upper end of right ulna, sequela',
  'S52002A Unspecified fracture of upper end of left ulna, initial encounter for closed fracture',
  'S52002B Unspecified fracture of upper end of left ulna, initial encounter for open fracture type I or II',
  'S52002C Unspecified fracture of upper end of left ulna, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S52002D Unspecified fracture of upper end of left ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52002E Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52002F Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52002G Unspecified fracture of upper end of left ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52002H Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52002J Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52002K Unspecified fracture of upper end of left ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52002M Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52002N Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52002P Unspecified fracture of upper end of left ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52002Q Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52002R Unspecified fracture of upper end of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52002S Unspecified fracture of upper end of left ulna, sequela',
  'S52009A Unspecified fracture of upper end of unspecified ulna, initial encounter for closed fracture',
  'S52009B Unspecified fracture of upper end of unspecified ulna, initial encounter for open fracture type I',
  'or II',
  'S52009C Unspecified fracture of upper end of unspecified ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52009D Unspecified fracture of upper end of unspecified ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52009E Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52009F Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52009G Unspecified fracture of upper end of unspecified ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52009H Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52009J Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52009K Unspecified fracture of upper end of unspecified ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52009M Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52009N Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52009P Unspecified fracture of upper end of unspecified ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52009Q Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52009R Unspecified fracture of upper end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52009S Unspecified fracture of upper end of unspecified ulna, sequela',
  'S52011A Torus fracture of upper end of right ulna, initial encounter for closed fracture',
  'S52011D Torus fracture of upper end of right ulna, subsequent encounter for fracture with routine',
  'healing',
  'S52011G Torus fracture of upper end of right ulna, subsequent encounter for fracture with delayed',
  'healing',
  'S52011K Torus fracture of upper end of right ulna, subsequent encounter for fracture with nonunion',
  'S52011P Torus fracture of upper end of right ulna, subsequent encounter for fracture with malunion',
  'S52011S Torus fracture of upper end of right ulna, sequela',
  'S52012A Torus fracture of upper end of left ulna, initial encounter for closed fracture',
  'S52012D Torus fracture of upper end of left ulna, subsequent encounter for fracture with routine',
  'healing',
  'S52012G Torus fracture of upper end of left ulna, subsequent encounter for fracture with delayed',
  'healing',
  'S52012K Torus fracture of upper end of left ulna, subsequent encounter for fracture with nonunion',
  'S52012P Torus fracture of upper end of left ulna, subsequent encounter for fracture with malunion',
  'S52012S Torus fracture of upper end of left ulna, sequela',
  'S52019A Torus fracture of upper end of unspecified ulna, initial encounter for closed fracture',
  'S52019D Torus fracture of upper end of unspecified ulna, subsequent encounter for fracture with',
  'routine healing',
  'S52019G Torus fracture of upper end of unspecified ulna, subsequent encounter for fracture with',
  'delayed healing',
  'S52019K Torus fracture of upper end of unspecified ulna, subsequent encounter for fracture with',
  'nonunion',
  'S52019P Torus fracture of upper end of unspecified ulna, subsequent encounter for fracture with',
  'malunion',
  'S52019S Torus fracture of upper end of unspecified ulna, sequela',
  'S52021A Displaced fracture of olecranon process without intraarticular extension of right ulna, initial',
  'encounter for closed fracture',
  'S52021B Displaced fracture of olecranon process without intraarticular extension of right ulna, initial',
  'encounter for open fracture type I or II',
  'S52021C Displaced fracture of olecranon process without intraarticular extension of right ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52021D Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52021E Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52021F Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52021G Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52021H Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52021J Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52021K Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52021M Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52021N Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52021P Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52021Q Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52021R Displaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52021S Displaced fracture of olecranon process without intraarticular extension of right ulna, sequela',
  'S52022A Displaced fracture of olecranon process without intraarticular extension of left ulna, initial',
  'encounter for closed fracture',
  'S52022B Displaced fracture of olecranon process without intraarticular extension of left ulna, initial',
  'encounter for open fracture type I or II',
  'S52022C Displaced fracture of olecranon process without intraarticular extension of left ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52022D Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52022E Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52022F Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52022G Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52022H Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52022J Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52022K Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52022M Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52022N Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52022P Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52022Q Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52022R Displaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52022S Displaced fracture of olecranon process without intraarticular extension of left ulna, sequela',
  'S52023A Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'initial encounter for closed fracture',
  'S52023B Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'initial encounter for open fracture type I or II',
  'S52023C Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52023D Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52023E Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52023F Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52023G Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52023H Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52023J Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52023K Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52023M Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52023N Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52023P Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52023Q Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52023R Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52023S Displaced fracture of olecranon process without intraarticular extension of unspecified ulna,',
  'sequela',
  'S52024A Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'initial encounter for closed fracture',
  'S52024B Nondisplaced fracture of olecranon process without intraarticular extension of right ulna, initial',
  'encounter for open fracture type I or II',
  'S52024C Nondisplaced fracture of olecranon process without intraarticular extension of right ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52024D Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52024E Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52024F Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52024G Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52024H Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52024J Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52024K Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52024M Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52024N Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52024P Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52024Q Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52024R Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52024S Nondisplaced fracture of olecranon process without intraarticular extension of right ulna,',
  'sequela',
  'S52025A Nondisplaced fracture of olecranon process without intraarticular extension of left ulna, initial',
  'encounter for closed fracture',
  'S52025B Nondisplaced fracture of olecranon process without intraarticular extension of left ulna, initial',
  'encounter for open fracture type I or II',
  'S52025C Nondisplaced fracture of olecranon process without intraarticular extension of left ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52025D Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52025E Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52025F Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52025G Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52025H Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52025J Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52025K Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52025M Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52025N Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52025P Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52025Q Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52025R Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52025S Nondisplaced fracture of olecranon process without intraarticular extension of left ulna,',
  'sequela',
  'S52026A Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, initial encounter for closed fracture',
  'S52026B Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, initial encounter for open fracture type I or II',
  'S52026C Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52026D Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for closed fracture with routine healing',
  'S52026E Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type I or II with routine healing',
  'S52026F Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52026G Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for closed fracture with delayed healing',
  'S52026H Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type I or II with delayed healing',
  'S52026J Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52026K Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for closed fracture with nonunion',
  'S52026M Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type I or II with nonunion',
  'S52026N Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52026P Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for closed fracture with malunion',
  'S52026Q Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type I or II with malunion',
  'S52026R Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52026S Nondisplaced fracture of olecranon process without intraarticular extension of unspecified',
  'ulna, sequela',
  'S52031A Displaced fracture of olecranon process with intraarticular extension of right ulna, initial',
  'encounter for closed fracture',
  'S52031B Displaced fracture of olecranon process with intraarticular extension of right ulna, initial',
  'encounter for open fracture type I or II',
  'S52031C Displaced fracture of olecranon process with intraarticular extension of right ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52031D Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for closed fracture with routine healing',
  'S52031E Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type I or II with routine healing',
  'S52031F Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52031G Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for closed fracture with delayed healing',
  'S52031H Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type I or II with delayed healing',
  'S52031J Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52031K Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for closed fracture with nonunion',
  'S52031M Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S52031N Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52031P Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for closed fracture with malunion',
  'S52031Q Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type I or II with malunion',
  'S52031R Displaced fracture of olecranon process with intraarticular extension of right ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52031S Displaced fracture of olecranon process with intraarticular extension of right ulna, sequela',
  'S52032A Displaced fracture of olecranon process with intraarticular extension of left ulna, initial',
  'encounter for closed fracture',
  'S52032B Displaced fracture of olecranon process with intraarticular extension of left ulna, initial',
  'encounter for open fracture type I or II',
  'S52032C Displaced fracture of olecranon process with intraarticular extension of left ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52032D Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for closed fracture with routine healing',
  'S52032E Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type I or II with routine healing',
  'S52032F Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52032G Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for closed fracture with delayed healing',
  'S52032H Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type I or II with delayed healing',
  'S52032J Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52032K Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for closed fracture with nonunion',
  'S52032M Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S52032N Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52032P Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for closed fracture with malunion',
  'S52032Q Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type I or II with malunion',
  'S52032R Displaced fracture of olecranon process with intraarticular extension of left ulna, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52032S Displaced fracture of olecranon process with intraarticular extension of left ulna, sequela',
  'S52033A Displaced fracture of olecranon process with intraarticular extension of unspecified ulna, initial',
  'encounter for closed fracture',
  'S52033B Displaced fracture of olecranon process with intraarticular extension of unspecified ulna, initial',
  'encounter for open fracture type I or II',
  'S52033C Displaced fracture of olecranon process with intraarticular extension of unspecified ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52033D Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52033E Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52033F Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52033G Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52033H Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52033J Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52033K Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52033M Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52033N Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52033P Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52033Q Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52033R Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52033S Displaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'sequela',
  'S52034A Nondisplaced fracture of olecranon process with intraarticular extension of right ulna, initial',
  'encounter for closed fracture',
  'S52034B Nondisplaced fracture of olecranon process with intraarticular extension of right ulna, initial',
  'encounter for open fracture type I or II',
  'S52034C Nondisplaced fracture of olecranon process with intraarticular extension of right ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52034D Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52034E Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52034F Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52034G Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52034H Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52034J Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52034K Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52034M Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52034N Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52034P Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52034Q Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52034R Nondisplaced fracture of olecranon process with intraarticular extension of right ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52034S Nondisplaced fracture of olecranon process with intraarticular extension of right ulna, sequela',
  'S52035A Nondisplaced fracture of olecranon process with intraarticular extension of left ulna, initial',
  'encounter for closed fracture',
  'S52035B Nondisplaced fracture of olecranon process with intraarticular extension of left ulna, initial',
  'encounter for open fracture type I or II',
  'S52035C Nondisplaced fracture of olecranon process with intraarticular extension of left ulna, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52035D Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52035E Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52035F Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52035G Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52035H Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52035J Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52035K Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52035M Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52035N Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52035P Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52035Q Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52035R Nondisplaced fracture of olecranon process with intraarticular extension of left ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52035S Nondisplaced fracture of olecranon process with intraarticular extension of left ulna, sequela',
  'S52036A Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'initial encounter for closed fracture',
  'S52036B Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'initial encounter for open fracture type I or II',
  'S52036C Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52036D Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with routine healing',
  'S52036E Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S52036F Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52036G Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with delayed healing',
  'S52036H Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S52036J Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52036K Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with nonunion',
  'S52036M Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S52036N Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52036P Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for closed fracture with malunion',
  'S52036Q Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S52036R Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52036S Nondisplaced fracture of olecranon process with intraarticular extension of unspecified ulna,',
  'sequela',
  'S52041A Displaced fracture of coronoid process of right ulna, initial encounter for closed fracture',
  'S52041B Displaced fracture of coronoid process of right ulna, initial encounter for open fracture type I',
  'or II',
  'S52041C Displaced fracture of coronoid process of right ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52041D Displaced fracture of coronoid process of right ulna, subsequent encounter for closed fracture',
  'with routine healing',
  'S52041E Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52041F Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52041G Displaced fracture of coronoid process of right ulna, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52041H Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52041J Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52041K Displaced fracture of coronoid process of right ulna, subsequent encounter for closed fracture',
  'with nonunion',
  'S52041M Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52041N Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52041P Displaced fracture of coronoid process of right ulna, subsequent encounter for closed fracture',
  'with malunion',
  'S52041Q Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52041R Displaced fracture of coronoid process of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52041S Displaced fracture of coronoid process of right ulna, sequela',
  'S52042A Displaced fracture of coronoid process of left ulna, initial encounter for closed fracture',
  'S52042B Displaced fracture of coronoid process of left ulna, initial encounter for open fracture type I or',
  'II',
  'S52042C Displaced fracture of coronoid process of left ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52042D Displaced fracture of coronoid process of left ulna, subsequent encounter for closed fracture',
  'with routine healing',
  'S52042E Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52042F Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52042G Displaced fracture of coronoid process of left ulna, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52042H Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52042J Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52042K Displaced fracture of coronoid process of left ulna, subsequent encounter for closed fracture',
  'with nonunion',
  'S52042M Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52042N Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52042P Displaced fracture of coronoid process of left ulna, subsequent encounter for closed fracture',
  'with malunion',
  'S52042Q Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52042R Displaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52042S Displaced fracture of coronoid process of left ulna, sequela',
  'S52043A Displaced fracture of coronoid process of unspecified ulna, initial encounter for closed fracture',
  'S52043B Displaced fracture of coronoid process of unspecified ulna, initial encounter for open fracture',
  'type I or II',
  'S52043C Displaced fracture of coronoid process of unspecified ulna, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52043D Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52043E Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52043F Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52043G Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52043H Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52043J Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52043K Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52043M Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52043N Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52043P Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52043Q Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52043R Displaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52043S Displaced fracture of coronoid process of unspecified ulna, sequela',
  'S52044A Nondisplaced fracture of coronoid process of right ulna, initial encounter for closed fracture',
  'S52044B Nondisplaced fracture of coronoid process of right ulna, initial encounter for open fracture',
  'type I or II',
  'S52044C Nondisplaced fracture of coronoid process of right ulna, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52044D Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52044E Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52044F Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52044G Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52044H Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52044J Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52044K Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52044M Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52044N Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52044P Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52044Q Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52044R Nondisplaced fracture of coronoid process of right ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52044S Nondisplaced fracture of coronoid process of right ulna, sequela',
  'S52045A Nondisplaced fracture of coronoid process of left ulna, initial encounter for closed fracture',
  'S52045B Nondisplaced fracture of coronoid process of left ulna, initial encounter for open fracture type I',
  'or II',
  'S52045C Nondisplaced fracture of coronoid process of left ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52045D Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52045E Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52045F Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52045G Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52045H Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52045J Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52045K Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52045M Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52045N Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52045P Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52045Q Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52045R Nondisplaced fracture of coronoid process of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52045S Nondisplaced fracture of coronoid process of left ulna, sequela',
  'S52046A Nondisplaced fracture of coronoid process of unspecified ulna, initial encounter for closed',
  'fracture',
  'S52046B Nondisplaced fracture of coronoid process of unspecified ulna, initial encounter for open',
  'fracture type I or II',
  'S52046C Nondisplaced fracture of coronoid process of unspecified ulna, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52046D Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for',
  'closed fracture with routine healing',
  'S52046E Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52046F Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52046G Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52046H Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52046J Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52046K Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for',
  'closed fracture with nonunion',
  'S52046M Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52046N Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52046P Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for',
  'closed fracture with malunion',
  'S52046Q Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52046R Nondisplaced fracture of coronoid process of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52046S Nondisplaced fracture of coronoid process of unspecified ulna, sequela',
  'S52091A Other fracture of upper end of right ulna, initial encounter for closed fracture',
  'S52091B Other fracture of upper end of right ulna, initial encounter for open fracture type I or II',
  'S52091C Other fracture of upper end of right ulna, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52091D Other fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52091E Other fracture of upper end of right ulna, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52091F Other fracture of upper end of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52091G Other fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52091H Other fracture of upper end of right ulna, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52091J Other fracture of upper end of right ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52091K Other fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52091M Other fracture of upper end of right ulna, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52091N Other fracture of upper end of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52091P Other fracture of upper end of right ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52091Q Other fracture of upper end of right ulna, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52091R Other fracture of upper end of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52091S Other fracture of upper end of right ulna, sequela',
  'S52092A Other fracture of upper end of left ulna, initial encounter for closed fracture',
  'S52092B Other fracture of upper end of left ulna, initial encounter for open fracture type I or II',
  'S52092C Other fracture of upper end of left ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52092D Other fracture of upper end of left ulna, subsequent encounter for closed fracture with routine',
  'healing',
  'S52092E Other fracture of upper end of left ulna, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52092F Other fracture of upper end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S52092G Other fracture of upper end of left ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52092H Other fracture of upper end of left ulna, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52092J Other fracture of upper end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52092K Other fracture of upper end of left ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52092M Other fracture of upper end of left ulna, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52092N Other fracture of upper end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S52092P Other fracture of upper end of left ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52092Q Other fracture of upper end of left ulna, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52092R Other fracture of upper end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S52092S Other fracture of upper end of left ulna, sequela',
  'S52099A Other fracture of upper end of unspecified ulna, initial encounter for closed fracture',
  'S52099B Other fracture of upper end of unspecified ulna, initial encounter for open fracture type I or II',
  'S52099C Other fracture of upper end of unspecified ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52099D Other fracture of upper end of unspecified ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52099E Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52099F Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52099G Other fracture of upper end of unspecified ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52099H Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52099J Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52099K Other fracture of upper end of unspecified ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52099M Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52099N Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52099P Other fracture of upper end of unspecified ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52099Q Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52099R Other fracture of upper end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52099S Other fracture of upper end of unspecified ulna, sequela',
  'S52101A Unspecified fracture of upper end of right radius, initial encounter for closed fracture',
  'S52101B Unspecified fracture of upper end of right radius, initial encounter for open fracture type I or II',
  'S52101C Unspecified fracture of upper end of right radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52101D Unspecified fracture of upper end of right radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52101E Unspecified fracture of upper end of right radius, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S52101F Unspecified fracture of upper end of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52101G Unspecified fracture of upper end of right radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52101H Unspecified fracture of upper end of right radius, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S52101J Unspecified fracture of upper end of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52101K Unspecified fracture of upper end of right radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52101M Unspecified fracture of upper end of right radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52101N Unspecified fracture of upper end of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52101P Unspecified fracture of upper end of right radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52101Q Unspecified fracture of upper end of right radius, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S52101R Unspecified fracture of upper end of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52101S Unspecified fracture of upper end of right radius, sequela',
  'S52102A Unspecified fracture of upper end of left radius, initial encounter for closed fracture',
  'S52102B Unspecified fracture of upper end of left radius, initial encounter for open fracture type I or II',
  'S52102C Unspecified fracture of upper end of left radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52102D Unspecified fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52102E Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52102F Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52102G Unspecified fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52102H Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52102J Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52102K Unspecified fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52102M Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52102N Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52102P Unspecified fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52102Q Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52102R Unspecified fracture of upper end of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52102S Unspecified fracture of upper end of left radius, sequela',
  'S52109A Unspecified fracture of upper end of unspecified radius, initial encounter for closed fracture',
  'S52109B Unspecified fracture of upper end of unspecified radius, initial encounter for open fracture',
  'type I or II',
  'S52109C Unspecified fracture of upper end of unspecified radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52109D Unspecified fracture of upper end of unspecified radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52109E Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52109F Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52109G Unspecified fracture of upper end of unspecified radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52109H Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52109J Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52109K Unspecified fracture of upper end of unspecified radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52109M Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52109N Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52109P Unspecified fracture of upper end of unspecified radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52109Q Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52109R Unspecified fracture of upper end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52109S Unspecified fracture of upper end of unspecified radius, sequela',
  'S52111A Torus fracture of upper end of right radius, initial encounter for closed fracture',
  'S52111D Torus fracture of upper end of right radius, subsequent encounter for fracture with routine',
  'healing',
  'S52111G Torus fracture of upper end of right radius, subsequent encounter for fracture with delayed',
  'healing',
  'S52111K Torus fracture of upper end of right radius, subsequent encounter for fracture with nonunion',
  'S52111P Torus fracture of upper end of right radius, subsequent encounter for fracture with malunion',
  'S52111S Torus fracture of upper end of right radius, sequela',
  'S52112A Torus fracture of upper end of left radius, initial encounter for closed fracture',
  'S52112D Torus fracture of upper end of left radius, subsequent encounter for fracture with routine',
  'healing',
  'S52112G Torus fracture of upper end of left radius, subsequent encounter for fracture with delayed',
  'healing',
  'S52112K Torus fracture of upper end of left radius, subsequent encounter for fracture with nonunion',
  'S52112P Torus fracture of upper end of left radius, subsequent encounter for fracture with malunion',
  'S52112S Torus fracture of upper end of left radius, sequela',
  'S52119A Torus fracture of upper end of unspecified radius, initial encounter for closed fracture',
  'S52119D Torus fracture of upper end of unspecified radius, subsequent encounter for fracture with',
  'routine healing',
  'S52119G Torus fracture of upper end of unspecified radius, subsequent encounter for fracture with',
  'delayed healing',
  'S52119K Torus fracture of upper end of unspecified radius, subsequent encounter for fracture with',
  'nonunion',
  'S52119P Torus fracture of upper end of unspecified radius, subsequent encounter for fracture with',
  'malunion',
  'S52119S Torus fracture of upper end of unspecified radius, sequela',
  'S52121A Displaced fracture of head of right radius, initial encounter for closed fracture',
  'S52121B Displaced fracture of head of right radius, initial encounter for open fracture type I or II',
  'S52121C Displaced fracture of head of right radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52121D Displaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52121E Displaced fracture of head of right radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52121F Displaced fracture of head of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52121G Displaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52121H Displaced fracture of head of right radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52121J Displaced fracture of head of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52121K Displaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52121M Displaced fracture of head of right radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52121N Displaced fracture of head of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52121P Displaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52121Q Displaced fracture of head of right radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52121R Displaced fracture of head of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52121S Displaced fracture of head of right radius, sequela',
  'S52122A Displaced fracture of head of left radius, initial encounter for closed fracture',
  'S52122B Displaced fracture of head of left radius, initial encounter for open fracture type I or II',
  'S52122C Displaced fracture of head of left radius, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52122D Displaced fracture of head of left radius, subsequent encounter for closed fracture with routine',
  'healing',
  'S52122E Displaced fracture of head of left radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52122F Displaced fracture of head of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S52122G Displaced fracture of head of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52122H Displaced fracture of head of left radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52122J Displaced fracture of head of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52122K Displaced fracture of head of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52122M Displaced fracture of head of left radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52122N Displaced fracture of head of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S52122P Displaced fracture of head of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52122Q Displaced fracture of head of left radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52122R Displaced fracture of head of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S52122S Displaced fracture of head of left radius, sequela',
  'S52123A Displaced fracture of head of unspecified radius, initial encounter for closed fracture',
  'S52123B Displaced fracture of head of unspecified radius, initial encounter for open fracture type I or II',
  'S52123C Displaced fracture of head of unspecified radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52123D Displaced fracture of head of unspecified radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52123E Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52123F Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52123G Displaced fracture of head of unspecified radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52123H Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S52123J Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52123K Displaced fracture of head of unspecified radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52123M Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52123N Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52123P Displaced fracture of head of unspecified radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52123Q Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S52123R Displaced fracture of head of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52123S Displaced fracture of head of unspecified radius, sequela',
  'S52124A Nondisplaced fracture of head of right radius, initial encounter for closed fracture',
  'S52124B Nondisplaced fracture of head of right radius, initial encounter for open fracture type I or II',
  'S52124C Nondisplaced fracture of head of right radius, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S52124D Nondisplaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52124E Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S52124F Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52124G Nondisplaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52124H Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52124J Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52124K Nondisplaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52124M Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52124N Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52124P Nondisplaced fracture of head of right radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52124Q Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52124R Nondisplaced fracture of head of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52124S Nondisplaced fracture of head of right radius, sequela',
  'S52125A Nondisplaced fracture of head of left radius, initial encounter for closed fracture',
  'S52125B Nondisplaced fracture of head of left radius, initial encounter for open fracture type I or II',
  'S52125C Nondisplaced fracture of head of left radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52125D Nondisplaced fracture of head of left radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52125E Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S52125F Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52125G Nondisplaced fracture of head of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52125H Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S52125J Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52125K Nondisplaced fracture of head of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52125M Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S52125N Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52125P Nondisplaced fracture of head of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52125Q Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S52125R Nondisplaced fracture of head of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52125S Nondisplaced fracture of head of left radius, sequela',
  'S52126A Nondisplaced fracture of head of unspecified radius, initial encounter for closed fracture',
  'S52126B Nondisplaced fracture of head of unspecified radius, initial encounter for open fracture type I',
  'or II',
  'S52126C Nondisplaced fracture of head of unspecified radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52126D Nondisplaced fracture of head of unspecified radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52126E Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52126F Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52126G Nondisplaced fracture of head of unspecified radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52126H Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52126J Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52126K Nondisplaced fracture of head of unspecified radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52126M Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52126N Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52126P Nondisplaced fracture of head of unspecified radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52126Q Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52126R Nondisplaced fracture of head of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52126S Nondisplaced fracture of head of unspecified radius, sequela',
  'S52131A Displaced fracture of neck of right radius, initial encounter for closed fracture',
  'S52131B Displaced fracture of neck of right radius, initial encounter for open fracture type I or II',
  'S52131C Displaced fracture of neck of right radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52131D Displaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52131E Displaced fracture of neck of right radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52131F Displaced fracture of neck of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52131G Displaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52131H Displaced fracture of neck of right radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52131J Displaced fracture of neck of right radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52131K Displaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52131M Displaced fracture of neck of right radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52131N Displaced fracture of neck of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52131P Displaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52131Q Displaced fracture of neck of right radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52131R Displaced fracture of neck of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52131S Displaced fracture of neck of right radius, sequela',
  'S52132A Displaced fracture of neck of left radius, initial encounter for closed fracture',
  'S52132B Displaced fracture of neck of left radius, initial encounter for open fracture type I or II',
  'S52132C Displaced fracture of neck of left radius, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52132D Displaced fracture of neck of left radius, subsequent encounter for closed fracture with routine',
  'healing',
  'S52132E Displaced fracture of neck of left radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52132F Displaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S52132G Displaced fracture of neck of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52132H Displaced fracture of neck of left radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52132J Displaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52132K Displaced fracture of neck of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52132M Displaced fracture of neck of left radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52132N Displaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S52132P Displaced fracture of neck of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52132Q Displaced fracture of neck of left radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52132R Displaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S52132S Displaced fracture of neck of left radius, sequela',
  'S52133A Displaced fracture of neck of unspecified radius, initial encounter for closed fracture',
  'S52133B Displaced fracture of neck of unspecified radius, initial encounter for open fracture type I or II',
  'S52133C Displaced fracture of neck of unspecified radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52133D Displaced fracture of neck of unspecified radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52133E Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52133F Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52133G Displaced fracture of neck of unspecified radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52133H Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52133J Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52133K Displaced fracture of neck of unspecified radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52133M Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52133N Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52133P Displaced fracture of neck of unspecified radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52133Q Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52133R Displaced fracture of neck of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52133S Displaced fracture of neck of unspecified radius, sequela',
  'S52134A Nondisplaced fracture of neck of right radius, initial encounter for closed fracture',
  'S52134B Nondisplaced fracture of neck of right radius, initial encounter for open fracture type I or II',
  'S52134C Nondisplaced fracture of neck of right radius, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S52134D Nondisplaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52134E Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S52134F Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52134G Nondisplaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52134H Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S52134J Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52134K Nondisplaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52134M Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52134N Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52134P Nondisplaced fracture of neck of right radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52134Q Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S52134R Nondisplaced fracture of neck of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52134S Nondisplaced fracture of neck of right radius, sequela',
  'S52135A Nondisplaced fracture of neck of left radius, initial encounter for closed fracture',
  'S52135B Nondisplaced fracture of neck of left radius, initial encounter for open fracture type I or II',
  'S52135C Nondisplaced fracture of neck of left radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52135D Nondisplaced fracture of neck of left radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52135E Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52135F Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52135G Nondisplaced fracture of neck of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52135H Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S52135J Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52135K Nondisplaced fracture of neck of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52135M Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S52135N Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52135P Nondisplaced fracture of neck of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52135Q Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S52135R Nondisplaced fracture of neck of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52135S Nondisplaced fracture of neck of left radius, sequela',
  'S52136A Nondisplaced fracture of neck of unspecified radius, initial encounter for closed fracture',
  'S52136B Nondisplaced fracture of neck of unspecified radius, initial encounter for open fracture type I',
  'or II',
  'S52136C Nondisplaced fracture of neck of unspecified radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52136D Nondisplaced fracture of neck of unspecified radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52136E Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52136F Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52136G Nondisplaced fracture of neck of unspecified radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52136H Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52136J Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52136K Nondisplaced fracture of neck of unspecified radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52136M Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52136N Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52136P Nondisplaced fracture of neck of unspecified radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52136Q Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52136R Nondisplaced fracture of neck of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52136S Nondisplaced fracture of neck of unspecified radius, sequela',
  'S52181A Other fracture of upper end of right radius, initial encounter for closed fracture',
  'S52181B Other fracture of upper end of right radius, initial encounter for open fracture type I or II',
  'S52181C Other fracture of upper end of right radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52181D Other fracture of upper end of right radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52181E Other fracture of upper end of right radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52181F Other fracture of upper end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52181G Other fracture of upper end of right radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52181H Other fracture of upper end of right radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52181J Other fracture of upper end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52181K Other fracture of upper end of right radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52181M Other fracture of upper end of right radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52181N Other fracture of upper end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52181P Other fracture of upper end of right radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52181Q Other fracture of upper end of right radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52181R Other fracture of upper end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52181S Other fracture of upper end of right radius, sequela',
  'S52182A Other fracture of upper end of left radius, initial encounter for closed fracture',
  'S52182B Other fracture of upper end of left radius, initial encounter for open fracture type I or II',
  'S52182C Other fracture of upper end of left radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52182D Other fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52182E Other fracture of upper end of left radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52182F Other fracture of upper end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52182G Other fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52182H Other fracture of upper end of left radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52182J Other fracture of upper end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52182K Other fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52182M Other fracture of upper end of left radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52182N Other fracture of upper end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52182P Other fracture of upper end of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52182Q Other fracture of upper end of left radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52182R Other fracture of upper end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52182S Other fracture of upper end of left radius, sequela',
  'S52189A Other fracture of upper end of unspecified radius, initial encounter for closed fracture',
  'S52189B Other fracture of upper end of unspecified radius, initial encounter for open fracture type I or II',
  'S52189C Other fracture of upper end of unspecified radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52189D Other fracture of upper end of unspecified radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52189E Other fracture of upper end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52189F Other fracture of upper end of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52189G Other fracture of upper end of unspecified radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52189H Other fracture of upper end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52189J Other fracture of upper end of unspecified radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52189K Other fracture of upper end of unspecified radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52189M Other fracture of upper end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52189N Other fracture of upper end of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52189P Other fracture of upper end of unspecified radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52189Q Other fracture of upper end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52189R Other fracture of upper end of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52189S Other fracture of upper end of unspecified radius, sequela',
  'S52201A Unspecified fracture of shaft of right ulna, initial encounter for closed fracture',
  'S52201B Unspecified fracture of shaft of right ulna, initial encounter for open fracture type I or II',
  'S52201C Unspecified fracture of shaft of right ulna, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52201D Unspecified fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52201E Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52201F Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52201G Unspecified fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52201H Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52201J Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52201K Unspecified fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52201M Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52201N Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52201P Unspecified fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52201Q Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52201R Unspecified fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52201S Unspecified fracture of shaft of right ulna, sequela',
  'S52202A Unspecified fracture of shaft of left ulna, initial encounter for closed fracture',
  'S52202B Unspecified fracture of shaft of left ulna, initial encounter for open fracture type I or II',
  'S52202C Unspecified fracture of shaft of left ulna, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52202D Unspecified fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52202E Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52202F Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S52202G Unspecified fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52202H Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52202J Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52202K Unspecified fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52202M Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52202N Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52202P Unspecified fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52202Q Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52202R Unspecified fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S52202S Unspecified fracture of shaft of left ulna, sequela',
  'S52209A Unspecified fracture of shaft of unspecified ulna, initial encounter for closed fracture',
  'S52209B Unspecified fracture of shaft of unspecified ulna, initial encounter for open fracture type I or II',
  'S52209C Unspecified fracture of shaft of unspecified ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52209D Unspecified fracture of shaft of unspecified ulna, subsequent encounter for closed fracture',
  'with routine healing',
  'S52209E Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S52209F Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52209G Unspecified fracture of shaft of unspecified ulna, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52209H Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S52209J Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52209K Unspecified fracture of shaft of unspecified ulna, subsequent encounter for closed fracture',
  'with nonunion',
  'S52209M Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52209N Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52209P Unspecified fracture of shaft of unspecified ulna, subsequent encounter for closed fracture',
  'with malunion',
  'S52209Q Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S52209R Unspecified fracture of shaft of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52209S Unspecified fracture of shaft of unspecified ulna, sequela',
  'S52211A Greenstick fracture of shaft of right ulna, initial encounter for closed fracture',
  'S52211D Greenstick fracture of shaft of right ulna, subsequent encounter for fracture with routine',
  'healing',
  'S52211G Greenstick fracture of shaft of right ulna, subsequent encounter for fracture with delayed',
  'healing',
  'S52211K Greenstick fracture of shaft of right ulna, subsequent encounter for fracture with nonunion',
  'S52211P Greenstick fracture of shaft of right ulna, subsequent encounter for fracture with malunion',
  'S52211S Greenstick fracture of shaft of right ulna, sequela',
  'S52212A Greenstick fracture of shaft of left ulna, initial encounter for closed fracture',
  'S52212D Greenstick fracture of shaft of left ulna, subsequent encounter for fracture with routine',
  'healing',
  'S52212G Greenstick fracture of shaft of left ulna, subsequent encounter for fracture with delayed',
  'healing',
  'S52212K Greenstick fracture of shaft of left ulna, subsequent encounter for fracture with nonunion',
  'S52212P Greenstick fracture of shaft of left ulna, subsequent encounter for fracture with malunion',
  'S52212S Greenstick fracture of shaft of left ulna, sequela',
  'S52219A Greenstick fracture of shaft of unspecified ulna, initial encounter for closed fracture',
  'S52219D Greenstick fracture of shaft of unspecified ulna, subsequent encounter for fracture with',
  'routine healing',
  'S52219G Greenstick fracture of shaft of unspecified ulna, subsequent encounter for fracture with',
  'delayed healing',
  'S52219K Greenstick fracture of shaft of unspecified ulna, subsequent encounter for fracture with',
  'nonunion',
  'S52219P Greenstick fracture of shaft of unspecified ulna, subsequent encounter for fracture with',
  'malunion',
  'S52219S Greenstick fracture of shaft of unspecified ulna, sequela',
  'S52221A Displaced transverse fracture of shaft of right ulna, initial encounter for closed fracture',
  'S52221B Displaced transverse fracture of shaft of right ulna, initial encounter for open fracture type I or',
  'II',
  'S52221C Displaced transverse fracture of shaft of right ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52221D Displaced transverse fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with routine healing',
  'S52221E Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52221F Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52221G Displaced transverse fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52221H Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52221J Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52221K Displaced transverse fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with nonunion',
  'S52221M Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52221N Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52221P Displaced transverse fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with malunion',
  'S52221Q Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52221R Displaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52221S Displaced transverse fracture of shaft of right ulna, sequela',
  'S52222A Displaced transverse fracture of shaft of left ulna, initial encounter for closed fracture',
  'S52222B Displaced transverse fracture of shaft of left ulna, initial encounter for open fracture type I or II',
  'S52222C Displaced transverse fracture of shaft of left ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52222D Displaced transverse fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with routine healing',
  'S52222E Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S52222F Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52222G Displaced transverse fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52222H Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52222J Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52222K Displaced transverse fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with nonunion',
  'S52222M Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52222N Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52222P Displaced transverse fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with malunion',
  'S52222Q Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52222R Displaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52222S Displaced transverse fracture of shaft of left ulna, sequela',
  'S52223A Displaced transverse fracture of shaft of unspecified ulna, initial encounter for closed fracture',
  'S52223B Displaced transverse fracture of shaft of unspecified ulna, initial encounter for open fracture',
  'type I or II',
  'S52223C Displaced transverse fracture of shaft of unspecified ulna, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52223D Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52223E Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52223F Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52223G Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52223H Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52223J Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52223K Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52223M Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52223N Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52223P Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52223Q Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52223R Displaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52223S Displaced transverse fracture of shaft of unspecified ulna, sequela',
  'S52224A Nondisplaced transverse fracture of shaft of right ulna, initial encounter for closed fracture',
  'S52224B Nondisplaced transverse fracture of shaft of right ulna, initial encounter for open fracture type',
  'I or II',
  'S52224C Nondisplaced transverse fracture of shaft of right ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52224D Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52224E Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52224F Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52224G Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52224H Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52224J Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52224K Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52224M Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52224N Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52224P Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52224Q Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52224R Nondisplaced transverse fracture of shaft of right ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52224S Nondisplaced transverse fracture of shaft of right ulna, sequela',
  'S52225A Nondisplaced transverse fracture of shaft of left ulna, initial encounter for closed fracture',
  'S52225B Nondisplaced transverse fracture of shaft of left ulna, initial encounter for open fracture type I',
  'or II',
  'S52225C Nondisplaced transverse fracture of shaft of left ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52225D Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52225E Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52225F Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52225G Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52225H Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52225J Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52225K Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52225M Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52225N Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52225P Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52225Q Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52225R Nondisplaced transverse fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52225S Nondisplaced transverse fracture of shaft of left ulna, sequela',
  'S52226A Nondisplaced transverse fracture of shaft of unspecified ulna, initial encounter for closed',
  'fracture',
  'S52226B Nondisplaced transverse fracture of shaft of unspecified ulna, initial encounter for open',
  'fracture type I or II',
  'S52226C Nondisplaced transverse fracture of shaft of unspecified ulna, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52226D Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52226E Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52226F Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52226G Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52226H Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52226J Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52226K Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52226M Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52226N Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52226P Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52226Q Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52226R Nondisplaced transverse fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52226S Nondisplaced transverse fracture of shaft of unspecified ulna, sequela',
  'S52231A Displaced oblique fracture of shaft of right ulna, initial encounter for closed fracture',
  'S52231B Displaced oblique fracture of shaft of right ulna, initial encounter for open fracture type I or II',
  'S52231C Displaced oblique fracture of shaft of right ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52231D Displaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52231E Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52231F Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52231G Displaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52231H Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52231J Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52231K Displaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52231M Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52231N Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52231P Displaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52231Q Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52231R Displaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52231S Displaced oblique fracture of shaft of right ulna, sequela',
  'S52232A Displaced oblique fracture of shaft of left ulna, initial encounter for closed fracture',
  'S52232B Displaced oblique fracture of shaft of left ulna, initial encounter for open fracture type I or II',
  'S52232C Displaced oblique fracture of shaft of left ulna, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S52232D Displaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52232E Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52232F Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52232G Displaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52232H Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52232J Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52232K Displaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52232M Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52232N Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52232P Displaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52232Q Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52232R Displaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52232S Displaced oblique fracture of shaft of left ulna, sequela',
  'S52233A Displaced oblique fracture of shaft of unspecified ulna, initial encounter for closed fracture',
  'S52233B Displaced oblique fracture of shaft of unspecified ulna, initial encounter for open fracture type',
  'I or II',
  'S52233C Displaced oblique fracture of shaft of unspecified ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52233D Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52233E Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52233F Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52233G Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52233H Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52233J Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52233K Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52233M Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52233N Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52233P Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52233Q Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52233R Displaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52233S Displaced oblique fracture of shaft of unspecified ulna, sequela',
  'S52234A Nondisplaced oblique fracture of shaft of right ulna, initial encounter for closed fracture',
  'S52234B Nondisplaced oblique fracture of shaft of right ulna, initial encounter for open fracture type I or',
  'II',
  'S52234C Nondisplaced oblique fracture of shaft of right ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52234D Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with routine healing',
  'S52234E Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52234F Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52234G Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52234H Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52234J Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52234K Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with nonunion',
  'S52234M Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52234N Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52234P Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for closed fracture',
  'with malunion',
  'S52234Q Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52234R Nondisplaced oblique fracture of shaft of right ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52234S Nondisplaced oblique fracture of shaft of right ulna, sequela',
  'S52235A Nondisplaced oblique fracture of shaft of left ulna, initial encounter for closed fracture',
  'S52235B Nondisplaced oblique fracture of shaft of left ulna, initial encounter for open fracture type I or',
  'II',
  'S52235C Nondisplaced oblique fracture of shaft of left ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52235D Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with routine healing',
  'S52235E Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52235F Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52235G Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52235H Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52235J Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52235K Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with nonunion',
  'S52235M Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52235N Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52235P Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for closed fracture',
  'with malunion',
  'S52235Q Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52235R Nondisplaced oblique fracture of shaft of left ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52235S Nondisplaced oblique fracture of shaft of left ulna, sequela',
  'S52236A Nondisplaced oblique fracture of shaft of unspecified ulna, initial encounter for closed fracture',
  'S52236B Nondisplaced oblique fracture of shaft of unspecified ulna, initial encounter for open fracture',
  'type I or II',
  'S52236C Nondisplaced oblique fracture of shaft of unspecified ulna, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52236D Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52236E Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52236F Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52236G Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52236H Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52236J Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52236K Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52236M Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52236N Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52236P Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52236Q Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52236R Nondisplaced oblique fracture of shaft of unspecified ulna, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52236S Nondisplaced oblique fracture of shaft of unspecified ulna, sequela',
  'S52241A Displaced spiral fracture of shaft of ulna, right arm, initial encounter for closed fracture',
  'S52241B Displaced spiral fracture of shaft of ulna, right arm, initial encounter for open fracture type I or',
  'II',
  'S52241C Displaced spiral fracture of shaft of ulna, right arm, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52241D Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed fracture',
  'with routine healing',
  'S52241E Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52241F Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52241G Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52241H Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52241J Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52241K Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed fracture',
  'with nonunion',
  'S52241M Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52241N Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52241P Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed fracture',
  'with malunion',
  'S52241Q Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52241R Displaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52241S Displaced spiral fracture of shaft of ulna, right arm, sequela',
  'S52242A Displaced spiral fracture of shaft of ulna, left arm, initial encounter for closed fracture',
  'S52242B Displaced spiral fracture of shaft of ulna, left arm, initial encounter for open fracture type I or II',
  'S52242C Displaced spiral fracture of shaft of ulna, left arm, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52242D Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed fracture',
  'with routine healing',
  'S52242E Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S52242F Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52242G Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52242H Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52242J Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52242K Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed fracture',
  'with nonunion',
  'S52242M Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52242N Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52242P Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed fracture',
  'with malunion',
  'S52242Q Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52242R Displaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52242S Displaced spiral fracture of shaft of ulna, left arm, sequela',
  'S52243A Displaced spiral fracture of shaft of ulna, unspecified arm, initial encounter for closed fracture',
  'S52243B Displaced spiral fracture of shaft of ulna, unspecified arm, initial encounter for open fracture',
  'type I or II',
  'S52243C Displaced spiral fracture of shaft of ulna, unspecified arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52243D Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52243E Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52243F Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52243G Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52243H Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52243J Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52243K Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52243M Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52243N Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52243P Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52243Q Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52243R Displaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52243S Displaced spiral fracture of shaft of ulna, unspecified arm, sequela',
  'S52244A Nondisplaced spiral fracture of shaft of ulna, right arm, initial encounter for closed fracture',
  'S52244B Nondisplaced spiral fracture of shaft of ulna, right arm, initial encounter for open fracture type',
  'I or II',
  'S52244C Nondisplaced spiral fracture of shaft of ulna, right arm, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52244D Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52244E Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52244F Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52244G Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52244H Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52244J Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52244K Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52244M Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52244N Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52244P Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52244Q Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52244R Nondisplaced spiral fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52244S Nondisplaced spiral fracture of shaft of ulna, right arm, sequela',
  'S52245A Nondisplaced spiral fracture of shaft of ulna, left arm, initial encounter for closed fracture',
  'S52245B Nondisplaced spiral fracture of shaft of ulna, left arm, initial encounter for open fracture type I',
  'or II',
  'S52245C Nondisplaced spiral fracture of shaft of ulna, left arm, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52245D Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52245E Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52245F Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52245G Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52245H Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52245J Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52245K Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52245M Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52245N Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52245P Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52245Q Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52245R Nondisplaced spiral fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52245S Nondisplaced spiral fracture of shaft of ulna, left arm, sequela',
  'S52246A Nondisplaced spiral fracture of shaft of ulna, unspecified arm, initial encounter for closed',
  'fracture',
  'S52246B Nondisplaced spiral fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52246C Nondisplaced spiral fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52246D Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52246E Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52246F Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52246G Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52246H Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52246J Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52246K Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52246M Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52246N Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52246P Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52246Q Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52246R Nondisplaced spiral fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52246S Nondisplaced spiral fracture of shaft of ulna, unspecified arm, sequela',
  'S52251A Displaced comminuted fracture of shaft of ulna, right arm, initial encounter for closed fracture',
  'S52251B Displaced comminuted fracture of shaft of ulna, right arm, initial encounter for open fracture',
  'type I or II',
  'S52251C Displaced comminuted fracture of shaft of ulna, right arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52251D Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52251E Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52251F Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52251G Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52251H Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52251J Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52251K Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52251M Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52251N Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52251P Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52251Q Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52251R Displaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52251S Displaced comminuted fracture of shaft of ulna, right arm, sequela',
  'S52252A Displaced comminuted fracture of shaft of ulna, left arm, initial encounter for closed fracture',
  'S52252B Displaced comminuted fracture of shaft of ulna, left arm, initial encounter for open fracture',
  'type I or II',
  'S52252C Displaced comminuted fracture of shaft of ulna, left arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52252D Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52252E Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52252F Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52252G Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52252H Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52252J Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52252K Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52252M Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52252N Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52252P Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52252Q Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52252R Displaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52252S Displaced comminuted fracture of shaft of ulna, left arm, sequela',
  'S52253A Displaced comminuted fracture of shaft of ulna, unspecified arm, initial encounter for closed',
  'fracture',
  'S52253B Displaced comminuted fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52253C Displaced comminuted fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52253D Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52253E Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52253F Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52253G Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52253H Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52253J Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52253K Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52253M Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52253N Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52253P Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52253Q Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52253R Displaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52253S Displaced comminuted fracture of shaft of ulna, unspecified arm, sequela',
  'S52254A Nondisplaced comminuted fracture of shaft of ulna, right arm, initial encounter for closed',
  'fracture',
  'S52254B Nondisplaced comminuted fracture of shaft of ulna, right arm, initial encounter for open',
  'fracture type I or II',
  'S52254C Nondisplaced comminuted fracture of shaft of ulna, right arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52254D Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52254E Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52254F Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52254G Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52254H Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52254J Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52254K Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52254M Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52254N Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52254P Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52254Q Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52254R Nondisplaced comminuted fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52254S Nondisplaced comminuted fracture of shaft of ulna, right arm, sequela',
  'S52255A Nondisplaced comminuted fracture of shaft of ulna, left arm, initial encounter for closed',
  'fracture',
  'S52255B Nondisplaced comminuted fracture of shaft of ulna, left arm, initial encounter for open',
  'fracture type I or II',
  'S52255C Nondisplaced comminuted fracture of shaft of ulna, left arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52255D Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52255E Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52255F Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52255G Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52255H Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52255J Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52255K Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52255M Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52255N Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52255P Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52255Q Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52255R Nondisplaced comminuted fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52255S Nondisplaced comminuted fracture of shaft of ulna, left arm, sequela',
  'S52256A Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, initial encounter for',
  'closed fracture',
  'S52256B Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52256C Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52256D Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for closed fracture with routine healing',
  'S52256E Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for open fracture type I or II with routine healing',
  'S52256F Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52256G Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for closed fracture with delayed healing',
  'S52256H Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S52256J Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52256K Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for closed fracture with nonunion',
  'S52256M Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S52256N Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52256P Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for closed fracture with malunion',
  'S52256Q Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S52256R Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52256S Nondisplaced comminuted fracture of shaft of ulna, unspecified arm, sequela',
  'S52261A Displaced segmental fracture of shaft of ulna, right arm, initial encounter for closed fracture',
  'S52261B Displaced segmental fracture of shaft of ulna, right arm, initial encounter for open fracture',
  'type I or II',
  'S52261C Displaced segmental fracture of shaft of ulna, right arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52261D Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52261E Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52261F Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52261G Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52261H Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52261J Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52261K Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52261M Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52261N Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52261P Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52261Q Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52261R Displaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52261S Displaced segmental fracture of shaft of ulna, right arm, sequela',
  'S52262A Displaced segmental fracture of shaft of ulna, left arm, initial encounter for closed fracture',
  'S52262B Displaced segmental fracture of shaft of ulna, left arm, initial encounter for open fracture type',
  'I or II',
  'S52262C Displaced segmental fracture of shaft of ulna, left arm, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52262D Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52262E Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52262F Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52262G Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52262H Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52262J Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52262K Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52262M Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52262N Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52262P Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52262Q Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52262R Displaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52262S Displaced segmental fracture of shaft of ulna, left arm, sequela',
  'S52263A Displaced segmental fracture of shaft of ulna, unspecified arm, initial encounter for closed',
  'fracture',
  'S52263B Displaced segmental fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52263C Displaced segmental fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52263D Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52263E Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52263F Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52263G Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52263H Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52263J Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52263K Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52263M Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52263N Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52263P Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52263Q Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52263R Displaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52263S Displaced segmental fracture of shaft of ulna, unspecified arm, sequela',
  'S52264A Nondisplaced segmental fracture of shaft of ulna, right arm, initial encounter for closed',
  'fracture',
  'S52264B Nondisplaced segmental fracture of shaft of ulna, right arm, initial encounter for open fracture',
  'type I or II',
  'S52264C Nondisplaced segmental fracture of shaft of ulna, right arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52264D Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52264E Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52264F Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52264G Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52264H Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52264J Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52264K Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52264M Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52264N Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52264P Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52264Q Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52264R Nondisplaced segmental fracture of shaft of ulna, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52264S Nondisplaced segmental fracture of shaft of ulna, right arm, sequela',
  'S52265A Nondisplaced segmental fracture of shaft of ulna, left arm, initial encounter for closed fracture',
  'S52265B Nondisplaced segmental fracture of shaft of ulna, left arm, initial encounter for open fracture',
  'type I or II',
  'S52265C Nondisplaced segmental fracture of shaft of ulna, left arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52265D Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52265E Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52265F Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52265G Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52265H Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52265J Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52265K Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52265M Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52265N Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52265P Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52265Q Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52265R Nondisplaced segmental fracture of shaft of ulna, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52265S Nondisplaced segmental fracture of shaft of ulna, left arm, sequela',
  'S52266A Nondisplaced segmental fracture of shaft of ulna, unspecified arm, initial encounter for closed',
  'fracture',
  'S52266B Nondisplaced segmental fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52266C Nondisplaced segmental fracture of shaft of ulna, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52266D Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52266E Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52266F Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52266G Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52266H Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52266J Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52266K Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52266M Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52266N Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52266P Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52266Q Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52266R Nondisplaced segmental fracture of shaft of ulna, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52266S Nondisplaced segmental fracture of shaft of ulna, unspecified arm, sequela',
  "S52271A Monteggia's fracture of right ulna, initial encounter for closed fracture",
  "S52271B Monteggia's fracture of right ulna, initial encounter for open fracture type I or II",
  "S52271C Monteggia's fracture of right ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52271D Monteggia's fracture of right ulna, subsequent encounter for closed fracture with routine",
  'healing',
  "S52271E Monteggia's fracture of right ulna, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52271F Monteggia's fracture of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52271G Monteggia's fracture of right ulna, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52271H Monteggia's fracture of right ulna, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52271J Monteggia's fracture of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52271K Monteggia's fracture of right ulna, subsequent encounter for closed fracture with nonunion",
  "S52271M Monteggia's fracture of right ulna, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52271N Monteggia's fracture of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with nonunion',
  "S52271P Monteggia's fracture of right ulna, subsequent encounter for closed fracture with malunion",
  "S52271Q Monteggia's fracture of right ulna, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52271R Monteggia's fracture of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52271S Monteggia's fracture of right ulna, sequela",
  "S52272A Monteggia's fracture of left ulna, initial encounter for closed fracture",
  "S52272B Monteggia's fracture of left ulna, initial encounter for open fracture type I or II",
  "S52272C Monteggia's fracture of left ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52272D Monteggia's fracture of left ulna, subsequent encounter for closed fracture with routine",
  'healing',
  "S52272E Monteggia's fracture of left ulna, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52272F Monteggia's fracture of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52272G Monteggia's fracture of left ulna, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52272H Monteggia's fracture of left ulna, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52272J Monteggia's fracture of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52272K Monteggia's fracture of left ulna, subsequent encounter for closed fracture with nonunion",
  "S52272M Monteggia's fracture of left ulna, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52272N Monteggia's fracture of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52272P Monteggia's fracture of left ulna, subsequent encounter for closed fracture with malunion",
  "S52272Q Monteggia's fracture of left ulna, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52272R Monteggia's fracture of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52272S Monteggia's fracture of left ulna, sequela",
  "S52279A Monteggia's fracture of unspecified ulna, initial encounter for closed fracture",
  "S52279B Monteggia's fracture of unspecified ulna, initial encounter for open fracture type I or II",
  "S52279C Monteggia's fracture of unspecified ulna, initial encounter for open fracture type IIIA, IIIB, or",
  'IIIC',
  "S52279D Monteggia's fracture of unspecified ulna, subsequent encounter for closed fracture with",
  'routine healing',
  "S52279E Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type I or II",
  'with routine healing',
  "S52279F Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type IIIA,",
  'IIIB, or IIIC with routine healing',
  "S52279G Monteggia's fracture of unspecified ulna, subsequent encounter for closed fracture with",
  'delayed healing',
  "S52279H Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type I or II",
  'with delayed healing',
  "S52279J Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with delayed healing',
  "S52279K Monteggia's fracture of unspecified ulna, subsequent encounter for closed fracture with",
  'nonunion',
  "S52279M Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type I or II",
  'with nonunion',
  "S52279N Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type IIIA,",
  'IIIB, or IIIC with nonunion',
  "S52279P Monteggia's fracture of unspecified ulna, subsequent encounter for closed fracture with",
  'malunion',
  "S52279Q Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type I or II",
  'with malunion',
  "S52279R Monteggia's fracture of unspecified ulna, subsequent encounter for open fracture type IIIA,",
  'IIIB, or IIIC with malunion',
  "S52279S Monteggia's fracture of unspecified ulna, sequela",
  'S52281A Bent bone of right ulna, initial encounter for closed fracture',
  'S52281B Bent bone of right ulna, initial encounter for open fracture type I or II',
  'S52281C Bent bone of right ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52281D Bent bone of right ulna, subsequent encounter for closed fracture with routine healing',
  'S52281E Bent bone of right ulna, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S52281F Bent bone of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'routine healing',
  'S52281G Bent bone of right ulna, subsequent encounter for closed fracture with delayed healing',
  'S52281H Bent bone of right ulna, subsequent encounter for open fracture type I or II with delayed',
  'healing',
  'S52281J Bent bone of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'delayed healing',
  'S52281K Bent bone of right ulna, subsequent encounter for closed fracture with nonunion',
  'S52281M Bent bone of right ulna, subsequent encounter for open fracture type I or II with nonunion',
  'S52281N Bent bone of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'nonunion',
  'S52281P Bent bone of right ulna, subsequent encounter for closed fracture with malunion',
  'S52281Q Bent bone of right ulna, subsequent encounter for open fracture type I or II with malunion',
  'S52281R Bent bone of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'malunion',
  'S52281S Bent bone of right ulna, sequela',
  'S52282A Bent bone of left ulna, initial encounter for closed fracture',
  'S52282B Bent bone of left ulna, initial encounter for open fracture type I or II',
  'S52282C Bent bone of left ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52282D Bent bone of left ulna, subsequent encounter for closed fracture with routine healing',
  'S52282E Bent bone of left ulna, subsequent encounter for open fracture type I or II with routine healing',
  'S52282F Bent bone of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'routine healing',
  'S52282G Bent bone of left ulna, subsequent encounter for closed fracture with delayed healing',
  'S52282H Bent bone of left ulna, subsequent encounter for open fracture type I or II with delayed healing',
  'S52282J Bent bone of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'delayed healing',
  'S52282K Bent bone of left ulna, subsequent encounter for closed fracture with nonunion',
  'S52282M Bent bone of left ulna, subsequent encounter for open fracture type I or II with nonunion',
  'S52282N Bent bone of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'nonunion',
  'S52282P Bent bone of left ulna, subsequent encounter for closed fracture with malunion',
  'S52282Q Bent bone of left ulna, subsequent encounter for open fracture type I or II with malunion',
  'S52282R Bent bone of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'malunion',
  'S52282S Bent bone of left ulna, sequela',
  'S52283A Bent bone of unspecified ulna, initial encounter for closed fracture',
  'S52283B Bent bone of unspecified ulna, initial encounter for open fracture type I or II',
  'S52283C Bent bone of unspecified ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52283D Bent bone of unspecified ulna, subsequent encounter for closed fracture with routine healing',
  'S52283E Bent bone of unspecified ulna, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S52283F Bent bone of unspecified ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with routine healing',
  'S52283G Bent bone of unspecified ulna, subsequent encounter for closed fracture with delayed healing',
  'S52283H Bent bone of unspecified ulna, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S52283J Bent bone of unspecified ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S52283K Bent bone of unspecified ulna, subsequent encounter for closed fracture with nonunion',
  'S52283M Bent bone of unspecified ulna, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S52283N Bent bone of unspecified ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with nonunion',
  'S52283P Bent bone of unspecified ulna, subsequent encounter for closed fracture with malunion',
  'S52283Q Bent bone of unspecified ulna, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S52283R Bent bone of unspecified ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with malunion',
  'S52283S Bent bone of unspecified ulna, sequela',
  'S52291A Other fracture of shaft of right ulna, initial encounter for closed fracture',
  'S52291B Other fracture of shaft of right ulna, initial encounter for open fracture type I or II',
  'S52291C Other fracture of shaft of right ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52291D Other fracture of shaft of right ulna, subsequent encounter for closed fracture with routine',
  'healing',
  'S52291E Other fracture of shaft of right ulna, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S52291F Other fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S52291G Other fracture of shaft of right ulna, subsequent encounter for closed fracture with delayed',
  'healing',
  'S52291H Other fracture of shaft of right ulna, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S52291J Other fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S52291K Other fracture of shaft of right ulna, subsequent encounter for closed fracture with nonunion',
  'S52291M Other fracture of shaft of right ulna, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S52291N Other fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S52291P Other fracture of shaft of right ulna, subsequent encounter for closed fracture with malunion',
  'S52291Q Other fracture of shaft of right ulna, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S52291R Other fracture of shaft of right ulna, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S52291S Other fracture of shaft of right ulna, sequela',
  'S52292A Other fracture of shaft of left ulna, initial encounter for closed fracture',
  'S52292B Other fracture of shaft of left ulna, initial encounter for open fracture type I or II',
  'S52292C Other fracture of shaft of left ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52292D Other fracture of shaft of left ulna, subsequent encounter for closed fracture with routine',
  'healing',
  'S52292E Other fracture of shaft of left ulna, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S52292F Other fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S52292G Other fracture of shaft of left ulna, subsequent encounter for closed fracture with delayed',
  'healing',
  'S52292H Other fracture of shaft of left ulna, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S52292J Other fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S52292K Other fracture of shaft of left ulna, subsequent encounter for closed fracture with nonunion',
  'S52292M Other fracture of shaft of left ulna, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S52292N Other fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S52292P Other fracture of shaft of left ulna, subsequent encounter for closed fracture with malunion',
  'S52292Q Other fracture of shaft of left ulna, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S52292R Other fracture of shaft of left ulna, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S52292S Other fracture of shaft of left ulna, sequela',
  'S52299A Other fracture of shaft of unspecified ulna, initial encounter for closed fracture',
  'S52299B Other fracture of shaft of unspecified ulna, initial encounter for open fracture type I or II',
  'S52299C Other fracture of shaft of unspecified ulna, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52299D Other fracture of shaft of unspecified ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52299E Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52299F Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52299G Other fracture of shaft of unspecified ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52299H Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52299J Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52299K Other fracture of shaft of unspecified ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52299M Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52299N Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52299P Other fracture of shaft of unspecified ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52299Q Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52299R Other fracture of shaft of unspecified ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52299S Other fracture of shaft of unspecified ulna, sequela',
  'S52301A Unspecified fracture of shaft of right radius, initial encounter for closed fracture',
  'S52301B Unspecified fracture of shaft of right radius, initial encounter for open fracture type I or II',
  'S52301C Unspecified fracture of shaft of right radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52301D Unspecified fracture of shaft of right radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52301E Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52301F Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52301G Unspecified fracture of shaft of right radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52301H Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S52301J Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52301K Unspecified fracture of shaft of right radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52301M Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S52301N Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52301P Unspecified fracture of shaft of right radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52301Q Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S52301R Unspecified fracture of shaft of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52301S Unspecified fracture of shaft of right radius, sequela',
  'S52302A Unspecified fracture of shaft of left radius, initial encounter for closed fracture',
  'S52302B Unspecified fracture of shaft of left radius, initial encounter for open fracture type I or II',
  'S52302C Unspecified fracture of shaft of left radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52302D Unspecified fracture of shaft of left radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52302E Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52302F Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52302G Unspecified fracture of shaft of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52302H Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52302J Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52302K Unspecified fracture of shaft of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52302M Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52302N Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52302P Unspecified fracture of shaft of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52302Q Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52302R Unspecified fracture of shaft of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52302S Unspecified fracture of shaft of left radius, sequela',
  'S52309A Unspecified fracture of shaft of unspecified radius, initial encounter for closed fracture',
  'S52309B Unspecified fracture of shaft of unspecified radius, initial encounter for open fracture type I or',
  'II',
  'S52309C Unspecified fracture of shaft of unspecified radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52309D Unspecified fracture of shaft of unspecified radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52309E Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52309F Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52309G Unspecified fracture of shaft of unspecified radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52309H Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52309J Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52309K Unspecified fracture of shaft of unspecified radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52309M Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52309N Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52309P Unspecified fracture of shaft of unspecified radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52309Q Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52309R Unspecified fracture of shaft of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52309S Unspecified fracture of shaft of unspecified radius, sequela',
  'S52311A Greenstick fracture of shaft of radius, right arm, initial encounter for closed fracture',
  'S52311D Greenstick fracture of shaft of radius, right arm, subsequent encounter for fracture with',
  'routine healing',
  'S52311G Greenstick fracture of shaft of radius, right arm, subsequent encounter for fracture with',
  'delayed healing',
  'S52311K Greenstick fracture of shaft of radius, right arm, subsequent encounter for fracture with',
  'nonunion',
  'S52311P Greenstick fracture of shaft of radius, right arm, subsequent encounter for fracture with',
  'malunion',
  'S52311S Greenstick fracture of shaft of radius, right arm, sequela',
  'S52312A Greenstick fracture of shaft of radius, left arm, initial encounter for closed fracture',
  'S52312D Greenstick fracture of shaft of radius, left arm, subsequent encounter for fracture with routine',
  'healing',
  'S52312G Greenstick fracture of shaft of radius, left arm, subsequent encounter for fracture with delayed',
  'healing',
  'S52312K Greenstick fracture of shaft of radius, left arm, subsequent encounter for fracture with',
  'nonunion',
  'S52312P Greenstick fracture of shaft of radius, left arm, subsequent encounter for fracture with',
  'malunion',
  'S52312S Greenstick fracture of shaft of radius, left arm, sequela',
  'S52319A Greenstick fracture of shaft of radius, unspecified arm, initial encounter for closed fracture',
  'S52319D Greenstick fracture of shaft of radius, unspecified arm, subsequent encounter for fracture with',
  'routine healing',
  'S52319G Greenstick fracture of shaft of radius, unspecified arm, subsequent encounter for fracture with',
  'delayed healing',
  'S52319K Greenstick fracture of shaft of radius, unspecified arm, subsequent encounter for fracture with',
  'nonunion',
  'S52319P Greenstick fracture of shaft of radius, unspecified arm, subsequent encounter for fracture with',
  'malunion',
  'S52319S Greenstick fracture of shaft of radius, unspecified arm, sequela',
  'S52321A Displaced transverse fracture of shaft of right radius, initial encounter for closed fracture',
  'S52321B Displaced transverse fracture of shaft of right radius, initial encounter for open fracture type I',
  'or II',
  'S52321C Displaced transverse fracture of shaft of right radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52321D Displaced transverse fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52321E Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52321F Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52321G Displaced transverse fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52321H Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52321J Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52321K Displaced transverse fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52321M Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52321N Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52321P Displaced transverse fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52321Q Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52321R Displaced transverse fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52321S Displaced transverse fracture of shaft of right radius, sequela',
  'S52322A Displaced transverse fracture of shaft of left radius, initial encounter for closed fracture',
  'S52322B Displaced transverse fracture of shaft of left radius, initial encounter for open fracture type I or',
  'II',
  'S52322C Displaced transverse fracture of shaft of left radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52322D Displaced transverse fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52322E Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52322F Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52322G Displaced transverse fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52322H Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52322J Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52322K Displaced transverse fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52322M Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52322N Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52322P Displaced transverse fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52322Q Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52322R Displaced transverse fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52322S Displaced transverse fracture of shaft of left radius, sequela',
  'S52323A Displaced transverse fracture of shaft of unspecified radius, initial encounter for closed',
  'fracture',
  'S52323B Displaced transverse fracture of shaft of unspecified radius, initial encounter for open fracture',
  'type I or II',
  'S52323C Displaced transverse fracture of shaft of unspecified radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52323D Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52323E Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52323F Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52323G Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52323H Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52323J Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52323K Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52323M Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52323N Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52323P Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52323Q Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52323R Displaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52323S Displaced transverse fracture of shaft of unspecified radius, sequela',
  'S52324A Nondisplaced transverse fracture of shaft of right radius, initial encounter for closed fracture',
  'S52324B Nondisplaced transverse fracture of shaft of right radius, initial encounter for open fracture',
  'type I or II',
  'S52324C Nondisplaced transverse fracture of shaft of right radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52324D Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52324E Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52324F Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52324G Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52324H Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52324J Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52324K Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52324M Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52324N Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52324P Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52324Q Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52324R Nondisplaced transverse fracture of shaft of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52324S Nondisplaced transverse fracture of shaft of right radius, sequela',
  'S52325A Nondisplaced transverse fracture of shaft of left radius, initial encounter for closed fracture',
  'S52325B Nondisplaced transverse fracture of shaft of left radius, initial encounter for open fracture type',
  'I or II',
  'S52325C Nondisplaced transverse fracture of shaft of left radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52325D Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52325E Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52325F Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52325G Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52325H Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52325J Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52325K Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52325M Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52325N Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52325P Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52325Q Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52325R Nondisplaced transverse fracture of shaft of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52325S Nondisplaced transverse fracture of shaft of left radius, sequela',
  'S52326A Nondisplaced transverse fracture of shaft of unspecified radius, initial encounter for closed',
  'fracture',
  'S52326B Nondisplaced transverse fracture of shaft of unspecified radius, initial encounter for open',
  'fracture type I or II',
  'S52326C Nondisplaced transverse fracture of shaft of unspecified radius, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52326D Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'closed fracture with routine healing',
  'S52326E Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52326F Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52326G Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52326H Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52326J Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52326K Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'closed fracture with nonunion',
  'S52326M Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52326N Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52326P Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'closed fracture with malunion',
  'S52326Q Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52326R Nondisplaced transverse fracture of shaft of unspecified radius, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52326S Nondisplaced transverse fracture of shaft of unspecified radius, sequela',
  'S52331A Displaced oblique fracture of shaft of right radius, initial encounter for closed fracture',
  'S52331B Displaced oblique fracture of shaft of right radius, initial encounter for open fracture type I or II',
  'S52331C Displaced oblique fracture of shaft of right radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52331D Displaced oblique fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52331E Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S52331F Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52331G Displaced oblique fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52331H Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52331J Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52331K Displaced oblique fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52331M Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52331N Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52331P Displaced oblique fracture of shaft of right radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52331Q Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52331R Displaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52331S Displaced oblique fracture of shaft of right radius, sequela',
  'S52332A Displaced oblique fracture of shaft of left radius, initial encounter for closed fracture',
  'S52332B Displaced oblique fracture of shaft of left radius, initial encounter for open fracture type I or II',
  'S52332C Displaced oblique fracture of shaft of left radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52332D Displaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52332E Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52332F Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52332G Displaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52332H Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S52332J Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52332K Displaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52332M Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52332N Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52332P Displaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52332Q Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S52332R Displaced oblique fracture of shaft of left radius, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52332S Displaced oblique fracture of shaft of left radius, sequela',
  'S52333A Displaced oblique fracture of shaft of unspecified radius, initial encounter for closed fracture',
  'S52333B Displaced oblique fracture of shaft of unspecified radius, initial encounter for open fracture',
  'type I or II',
  'S52333C Displaced oblique fracture of shaft of unspecified radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52333D Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52333E Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52333F Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52333G Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52333H Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52333J Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52333K Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52333M Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52333N Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52333P Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52333Q Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52333R Displaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52333S Displaced oblique fracture of shaft of unspecified radius, sequela',
  'S52334A Nondisplaced oblique fracture of shaft of right radius, initial encounter for closed fracture',
  'S52334B Nondisplaced oblique fracture of shaft of right radius, initial encounter for open fracture type I',
  'or II',
  'S52334C Nondisplaced oblique fracture of shaft of right radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52334D Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52334E Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52334F Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52334G Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52334H Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52334J Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52334K Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52334M Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52334N Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52334P Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52334Q Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52334R Nondisplaced oblique fracture of shaft of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52334S Nondisplaced oblique fracture of shaft of right radius, sequela',
  'S52335A Nondisplaced oblique fracture of shaft of left radius, initial encounter for closed fracture',
  'S52335B Nondisplaced oblique fracture of shaft of left radius, initial encounter for open fracture type I',
  'or II',
  'S52335C Nondisplaced oblique fracture of shaft of left radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52335D Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52335E Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52335F Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52335G Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52335H Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52335J Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52335K Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52335M Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52335N Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52335P Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52335Q Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52335R Nondisplaced oblique fracture of shaft of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52335S Nondisplaced oblique fracture of shaft of left radius, sequela',
  'S52336A Nondisplaced oblique fracture of shaft of unspecified radius, initial encounter for closed',
  'fracture',
  'S52336B Nondisplaced oblique fracture of shaft of unspecified radius, initial encounter for open fracture',
  'type I or II',
  'S52336C Nondisplaced oblique fracture of shaft of unspecified radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52336D Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52336E Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52336F Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52336G Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52336H Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52336J Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52336K Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52336M Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52336N Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52336P Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52336Q Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52336R Nondisplaced oblique fracture of shaft of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52336S Nondisplaced oblique fracture of shaft of unspecified radius, sequela',
  'S52341A Displaced spiral fracture of shaft of radius, right arm, initial encounter for closed fracture',
  'S52341B Displaced spiral fracture of shaft of radius, right arm, initial encounter for open fracture type I',
  'or II',
  'S52341C Displaced spiral fracture of shaft of radius, right arm, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52341D Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed fracture',
  'with routine healing',
  'S52341E Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52341F Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52341G Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52341H Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52341J Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52341K Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed fracture',
  'with nonunion',
  'S52341M Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52341N Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52341P Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed fracture',
  'with malunion',
  'S52341Q Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52341R Displaced spiral fracture of shaft of radius, right arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52341S Displaced spiral fracture of shaft of radius, right arm, sequela',
  'S52342A Displaced spiral fracture of shaft of radius, left arm, initial encounter for closed fracture',
  'S52342B Displaced spiral fracture of shaft of radius, left arm, initial encounter for open fracture type I or',
  'II',
  'S52342C Displaced spiral fracture of shaft of radius, left arm, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52342D Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed fracture',
  'with routine healing',
  'S52342E Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52342F Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52342G Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52342H Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52342J Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52342K Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed fracture',
  'with nonunion',
  'S52342M Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52342N Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52342P Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed fracture',
  'with malunion',
  'S52342Q Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52342R Displaced spiral fracture of shaft of radius, left arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52342S Displaced spiral fracture of shaft of radius, left arm, sequela',
  'S52343A Displaced spiral fracture of shaft of radius, unspecified arm, initial encounter for closed',
  'fracture',
  'S52343B Displaced spiral fracture of shaft of radius, unspecified arm, initial encounter for open fracture',
  'type I or II',
  'S52343C Displaced spiral fracture of shaft of radius, unspecified arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52343D Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52343E Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52343F Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52343G Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52343H Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52343J Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52343K Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52343M Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52343N Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52343P Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52343Q Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52343R Displaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52343S Displaced spiral fracture of shaft of radius, unspecified arm, sequela',
  'S52344A Nondisplaced spiral fracture of shaft of radius, right arm, initial encounter for closed fracture',
  'S52344B Nondisplaced spiral fracture of shaft of radius, right arm, initial encounter for open fracture',
  'type I or II',
  'S52344C Nondisplaced spiral fracture of shaft of radius, right arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52344D Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52344E Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52344F Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52344G Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52344H Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52344J Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52344K Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52344M Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52344N Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52344P Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52344Q Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52344R Nondisplaced spiral fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52344S Nondisplaced spiral fracture of shaft of radius, right arm, sequela',
  'S52345A Nondisplaced spiral fracture of shaft of radius, left arm, initial encounter for closed fracture',
  'S52345B Nondisplaced spiral fracture of shaft of radius, left arm, initial encounter for open fracture type',
  'I or II',
  'S52345C Nondisplaced spiral fracture of shaft of radius, left arm, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52345D Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52345E Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52345F Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52345G Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52345H Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52345J Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52345K Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52345M Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52345N Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52345P Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52345Q Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52345R Nondisplaced spiral fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52345S Nondisplaced spiral fracture of shaft of radius, left arm, sequela',
  'S52346A Nondisplaced spiral fracture of shaft of radius, unspecified arm, initial encounter for closed',
  'fracture',
  'S52346B Nondisplaced spiral fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52346C Nondisplaced spiral fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52346D Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52346E Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52346F Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52346G Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52346H Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52346J Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52346K Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52346M Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52346N Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52346P Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52346Q Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52346R Nondisplaced spiral fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52346S Nondisplaced spiral fracture of shaft of radius, unspecified arm, sequela',
  'S52351A Displaced comminuted fracture of shaft of radius, right arm, initial encounter for closed',
  'fracture',
  'S52351B Displaced comminuted fracture of shaft of radius, right arm, initial encounter for open fracture',
  'type I or II',
  'S52351C Displaced comminuted fracture of shaft of radius, right arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52351D Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52351E Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52351F Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52351G Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52351H Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52351J Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52351K Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52351M Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52351N Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52351P Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52351Q Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52351R Displaced comminuted fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52351S Displaced comminuted fracture of shaft of radius, right arm, sequela',
  'S52352A Displaced comminuted fracture of shaft of radius, left arm, initial encounter for closed fracture',
  'S52352B Displaced comminuted fracture of shaft of radius, left arm, initial encounter for open fracture',
  'type I or II',
  'S52352C Displaced comminuted fracture of shaft of radius, left arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52352D Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52352E Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52352F Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52352G Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52352H Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52352J Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52352K Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52352M Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52352N Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52352P Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52352Q Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52352R Displaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52352S Displaced comminuted fracture of shaft of radius, left arm, sequela',
  'S52353A Displaced comminuted fracture of shaft of radius, unspecified arm, initial encounter for closed',
  'fracture',
  'S52353B Displaced comminuted fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52353C Displaced comminuted fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52353D Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52353E Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52353F Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52353G Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52353H Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52353J Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52353K Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52353M Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52353N Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52353P Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52353Q Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52353R Displaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52353S Displaced comminuted fracture of shaft of radius, unspecified arm, sequela',
  'S52354A Nondisplaced comminuted fracture of shaft of radius, right arm, initial encounter for closed',
  'fracture',
  'S52354B Nondisplaced comminuted fracture of shaft of radius, right arm, initial encounter for open',
  'fracture type I or II',
  'S52354C Nondisplaced comminuted fracture of shaft of radius, right arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52354D Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52354E Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52354F Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52354G Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52354H Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52354J Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52354K Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52354M Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52354N Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52354P Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52354Q Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52354R Nondisplaced comminuted fracture of shaft of radius, right arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52354S Nondisplaced comminuted fracture of shaft of radius, right arm, sequela',
  'S52355A Nondisplaced comminuted fracture of shaft of radius, left arm, initial encounter for closed',
  'fracture',
  'S52355B Nondisplaced comminuted fracture of shaft of radius, left arm, initial encounter for open',
  'fracture type I or II',
  'S52355C Nondisplaced comminuted fracture of shaft of radius, left arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52355D Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52355E Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52355F Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52355G Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52355H Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52355J Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52355K Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52355M Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52355N Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52355P Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52355Q Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52355R Nondisplaced comminuted fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52355S Nondisplaced comminuted fracture of shaft of radius, left arm, sequela',
  'S52356A Nondisplaced comminuted fracture of shaft of radius, unspecified arm, initial encounter for',
  'closed fracture',
  'S52356B Nondisplaced comminuted fracture of shaft of radius, unspecified arm, initial encounter for',
  'open fracture type I or II',
  'S52356C Nondisplaced comminuted fracture of shaft of radius, unspecified arm, initial encounter for',
  'open fracture type IIIA, IIIB, or IIIC',
  'S52356D Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for closed fracture with routine healing',
  'S52356E Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type I or II with routine healing',
  'S52356F Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52356G Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for closed fracture with delayed healing',
  'S52356H Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S52356J Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52356K Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for closed fracture with nonunion',
  'S52356M Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S52356N Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52356P Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for closed fracture with malunion',
  'S52356Q Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S52356R Nondisplaced comminuted fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52356S Nondisplaced comminuted fracture of shaft of radius, unspecified arm, sequela',
  'S52361A Displaced segmental fracture of shaft of radius, right arm, initial encounter for closed fracture',
  'S52361B Displaced segmental fracture of shaft of radius, right arm, initial encounter for open fracture',
  'type I or II',
  'S52361C Displaced segmental fracture of shaft of radius, right arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52361D Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52361E Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52361F Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52361G Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52361H Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52361J Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52361K Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52361M Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52361N Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52361P Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52361Q Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52361R Displaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52361S Displaced segmental fracture of shaft of radius, right arm, sequela',
  'S52362A Displaced segmental fracture of shaft of radius, left arm, initial encounter for closed fracture',
  'S52362B Displaced segmental fracture of shaft of radius, left arm, initial encounter for open fracture',
  'type I or II',
  'S52362C Displaced segmental fracture of shaft of radius, left arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52362D Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52362E Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52362F Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52362G Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52362H Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52362J Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52362K Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52362M Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52362N Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52362P Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52362Q Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52362R Displaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52362S Displaced segmental fracture of shaft of radius, left arm, sequela',
  'S52363A Displaced segmental fracture of shaft of radius, unspecified arm, initial encounter for closed',
  'fracture',
  'S52363B Displaced segmental fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52363C Displaced segmental fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52363D Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52363E Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52363F Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52363G Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52363H Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52363J Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52363K Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52363M Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52363N Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52363P Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52363Q Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52363R Displaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52363S Displaced segmental fracture of shaft of radius, unspecified arm, sequela',
  'S52364A Nondisplaced segmental fracture of shaft of radius, right arm, initial encounter for closed',
  'fracture',
  'S52364B Nondisplaced segmental fracture of shaft of radius, right arm, initial encounter for open',
  'fracture type I or II',
  'S52364C Nondisplaced segmental fracture of shaft of radius, right arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52364D Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52364E Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52364F Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52364G Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52364H Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52364J Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52364K Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52364M Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52364N Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52364P Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52364Q Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52364R Nondisplaced segmental fracture of shaft of radius, right arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52364S Nondisplaced segmental fracture of shaft of radius, right arm, sequela',
  'S52365A Nondisplaced segmental fracture of shaft of radius, left arm, initial encounter for closed',
  'fracture',
  'S52365B Nondisplaced segmental fracture of shaft of radius, left arm, initial encounter for open fracture',
  'type I or II',
  'S52365C Nondisplaced segmental fracture of shaft of radius, left arm, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52365D Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with routine healing',
  'S52365E Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52365F Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52365G Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52365H Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52365J Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52365K Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with nonunion',
  'S52365M Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52365N Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52365P Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for closed',
  'fracture with malunion',
  'S52365Q Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52365R Nondisplaced segmental fracture of shaft of radius, left arm, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52365S Nondisplaced segmental fracture of shaft of radius, left arm, sequela',
  'S52366A Nondisplaced segmental fracture of shaft of radius, unspecified arm, initial encounter for',
  'closed fracture',
  'S52366B Nondisplaced segmental fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type I or II',
  'S52366C Nondisplaced segmental fracture of shaft of radius, unspecified arm, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52366D Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with routine healing',
  'S52366E Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52366F Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52366G Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52366H Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52366J Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52366K Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with nonunion',
  'S52366M Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S52366N Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52366P Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'closed fracture with malunion',
  'S52366Q Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52366R Nondisplaced segmental fracture of shaft of radius, unspecified arm, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52366S Nondisplaced segmental fracture of shaft of radius, unspecified arm, sequela',
  "S52371A Galeazzi's fracture of right radius, initial encounter for closed fracture",
  "S52371B Galeazzi's fracture of right radius, initial encounter for open fracture type I or II",
  "S52371C Galeazzi's fracture of right radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52371D Galeazzi's fracture of right radius, subsequent encounter for closed fracture with routine",
  'healing',
  "S52371E Galeazzi's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52371F Galeazzi's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52371G Galeazzi's fracture of right radius, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52371H Galeazzi's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52371J Galeazzi's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52371K Galeazzi's fracture of right radius, subsequent encounter for closed fracture with nonunion",
  "S52371M Galeazzi's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52371N Galeazzi's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52371P Galeazzi's fracture of right radius, subsequent encounter for closed fracture with malunion",
  "S52371Q Galeazzi's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52371R Galeazzi's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52371S Galeazzi's fracture of right radius, sequela",
  "S52372A Galeazzi's fracture of left radius, initial encounter for closed fracture",
  "S52372B Galeazzi's fracture of left radius, initial encounter for open fracture type I or II",
  "S52372C Galeazzi's fracture of left radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52372D Galeazzi's fracture of left radius, subsequent encounter for closed fracture with routine healing",
  "S52372E Galeazzi's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52372F Galeazzi's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52372G Galeazzi's fracture of left radius, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52372H Galeazzi's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52372J Galeazzi's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52372K Galeazzi's fracture of left radius, subsequent encounter for closed fracture with nonunion",
  "S52372M Galeazzi's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52372N Galeazzi's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52372P Galeazzi's fracture of left radius, subsequent encounter for closed fracture with malunion",
  "S52372Q Galeazzi's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52372R Galeazzi's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52372S Galeazzi's fracture of left radius, sequela",
  "S52379A Galeazzi's fracture of unspecified radius, initial encounter for closed fracture",
  "S52379B Galeazzi's fracture of unspecified radius, initial encounter for open fracture type I or II",
  "S52379C Galeazzi's fracture of unspecified radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52379D Galeazzi's fracture of unspecified radius, subsequent encounter for closed fracture with",
  'routine healing',
  "S52379E Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type I or II",
  'with routine healing',
  "S52379F Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with routine healing',
  "S52379G Galeazzi's fracture of unspecified radius, subsequent encounter for closed fracture with",
  'delayed healing',
  "S52379H Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type I or II",
  'with delayed healing',
  "S52379J Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with delayed healing',
  "S52379K Galeazzi's fracture of unspecified radius, subsequent encounter for closed fracture with",
  'nonunion',
  "S52379M Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type I or II",
  'with nonunion',
  "S52379N Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with nonunion',
  "S52379P Galeazzi's fracture of unspecified radius, subsequent encounter for closed fracture with",
  'malunion',
  "S52379Q Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type I or II",
  'with malunion',
  "S52379R Galeazzi's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with malunion',
  "S52379S Galeazzi's fracture of unspecified radius, sequela",
  'S52381A Bent bone of right radius, initial encounter for closed fracture',
  'S52381B Bent bone of right radius, initial encounter for open fracture type I or II',
  'S52381C Bent bone of right radius, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52381D Bent bone of right radius, subsequent encounter for closed fracture with routine healing',
  'S52381E Bent bone of right radius, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S52381F Bent bone of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'routine healing',
  'S52381G Bent bone of right radius, subsequent encounter for closed fracture with delayed healing',
  'S52381H Bent bone of right radius, subsequent encounter for open fracture type I or II with delayed',
  'healing',
  'S52381J Bent bone of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'delayed healing',
  'S52381K Bent bone of right radius, subsequent encounter for closed fracture with nonunion',
  'S52381M Bent bone of right radius, subsequent encounter for open fracture type I or II with nonunion',
  'S52381N Bent bone of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'nonunion',
  'S52381P Bent bone of right radius, subsequent encounter for closed fracture with malunion',
  'S52381Q Bent bone of right radius, subsequent encounter for open fracture type I or II with malunion',
  'S52381R Bent bone of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'malunion',
  'S52381S Bent bone of right radius, sequela',
  'S52382A Bent bone of left radius, initial encounter for closed fracture',
  'S52382B Bent bone of left radius, initial encounter for open fracture type I or II',
  'S52382C Bent bone of left radius, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52382D Bent bone of left radius, subsequent encounter for closed fracture with routine healing',
  'S52382E Bent bone of left radius, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S52382F Bent bone of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'routine healing',
  'S52382G Bent bone of left radius, subsequent encounter for closed fracture with delayed healing',
  'S52382H Bent bone of left radius, subsequent encounter for open fracture type I or II with delayed',
  'healing',
  'S52382J Bent bone of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'delayed healing',
  'S52382K Bent bone of left radius, subsequent encounter for closed fracture with nonunion',
  'S52382M Bent bone of left radius, subsequent encounter for open fracture type I or II with nonunion',
  'S52382N Bent bone of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'nonunion',
  'S52382P Bent bone of left radius, subsequent encounter for closed fracture with malunion',
  'S52382Q Bent bone of left radius, subsequent encounter for open fracture type I or II with malunion',
  'S52382R Bent bone of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'malunion',
  'S52382S Bent bone of left radius, sequela',
  'S52389A Bent bone of unspecified radius, initial encounter for closed fracture',
  'S52389B Bent bone of unspecified radius, initial encounter for open fracture type I or II',
  'S52389C Bent bone of unspecified radius, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52389D Bent bone of unspecified radius, subsequent encounter for closed fracture with routine healing',
  'S52389E Bent bone of unspecified radius, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S52389F Bent bone of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with routine healing',
  'S52389G Bent bone of unspecified radius, subsequent encounter for closed fracture with delayed',
  'healing',
  'S52389H Bent bone of unspecified radius, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S52389J Bent bone of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S52389K Bent bone of unspecified radius, subsequent encounter for closed fracture with nonunion',
  'S52389M Bent bone of unspecified radius, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S52389N Bent bone of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with nonunion',
  'S52389P Bent bone of unspecified radius, subsequent encounter for closed fracture with malunion',
  'S52389Q Bent bone of unspecified radius, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S52389R Bent bone of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with malunion',
  'S52389S Bent bone of unspecified radius, sequela',
  'S52391A Other fracture of shaft of radius, right arm, initial encounter for closed fracture',
  'S52391B Other fracture of shaft of radius, right arm, initial encounter for open fracture type I or II',
  'S52391C Other fracture of shaft of radius, right arm, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52391D Other fracture of shaft of radius, right arm, subsequent encounter for closed fracture with',
  'routine healing',
  'S52391E Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52391F Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52391G Other fracture of shaft of radius, right arm, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52391H Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52391J Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52391K Other fracture of shaft of radius, right arm, subsequent encounter for closed fracture with',
  'nonunion',
  'S52391M Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52391N Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52391P Other fracture of shaft of radius, right arm, subsequent encounter for closed fracture with',
  'malunion',
  'S52391Q Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52391R Other fracture of shaft of radius, right arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52391S Other fracture of shaft of radius, right arm, sequela',
  'S52392A Other fracture of shaft of radius, left arm, initial encounter for closed fracture',
  'S52392B Other fracture of shaft of radius, left arm, initial encounter for open fracture type I or II',
  'S52392C Other fracture of shaft of radius, left arm, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52392D Other fracture of shaft of radius, left arm, subsequent encounter for closed fracture with',
  'routine healing',
  'S52392E Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52392F Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52392G Other fracture of shaft of radius, left arm, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52392H Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52392J Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52392K Other fracture of shaft of radius, left arm, subsequent encounter for closed fracture with',
  'nonunion',
  'S52392M Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52392N Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52392P Other fracture of shaft of radius, left arm, subsequent encounter for closed fracture with',
  'malunion',
  'S52392Q Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52392R Other fracture of shaft of radius, left arm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52392S Other fracture of shaft of radius, left arm, sequela',
  'S52399A Other fracture of shaft of radius, unspecified arm, initial encounter for closed fracture',
  'S52399B Other fracture of shaft of radius, unspecified arm, initial encounter for open fracture type I or II',
  'S52399C Other fracture of shaft of radius, unspecified arm, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52399D Other fracture of shaft of radius, unspecified arm, subsequent encounter for closed fracture',
  'with routine healing',
  'S52399E Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52399F Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52399G Other fracture of shaft of radius, unspecified arm, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52399H Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52399J Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52399K Other fracture of shaft of radius, unspecified arm, subsequent encounter for closed fracture',
  'with nonunion',
  'S52399M Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52399N Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52399P Other fracture of shaft of radius, unspecified arm, subsequent encounter for closed fracture',
  'with malunion',
  'S52399Q Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52399R Other fracture of shaft of radius, unspecified arm, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52399S Other fracture of shaft of radius, unspecified arm, sequela',
  'S52501A Unspecified fracture of the lower end of right radius, initial encounter for closed fracture',
  'S52501B Unspecified fracture of the lower end of right radius, initial encounter for open fracture type I',
  'or II',
  'S52501C Unspecified fracture of the lower end of right radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52501D Unspecified fracture of the lower end of right radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52501E Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52501F Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52501G Unspecified fracture of the lower end of right radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52501H Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52501J Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52501K Unspecified fracture of the lower end of right radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52501M Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52501N Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52501P Unspecified fracture of the lower end of right radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52501Q Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52501R Unspecified fracture of the lower end of right radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52501S Unspecified fracture of the lower end of right radius, sequela',
  'S52502A Unspecified fracture of the lower end of left radius, initial encounter for closed fracture',
  'S52502B Unspecified fracture of the lower end of left radius, initial encounter for open fracture type I or',
  'II',
  'S52502C Unspecified fracture of the lower end of left radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52502D Unspecified fracture of the lower end of left radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52502E Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52502F Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52502G Unspecified fracture of the lower end of left radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52502H Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52502J Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52502K Unspecified fracture of the lower end of left radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52502M Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52502N Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52502P Unspecified fracture of the lower end of left radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52502Q Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52502R Unspecified fracture of the lower end of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52502S Unspecified fracture of the lower end of left radius, sequela',
  'S52509A Unspecified fracture of the lower end of unspecified radius, initial encounter for closed',
  'fracture',
  'S52509B Unspecified fracture of the lower end of unspecified radius, initial encounter for open fracture',
  'type I or II',
  'S52509C Unspecified fracture of the lower end of unspecified radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52509D Unspecified fracture of the lower end of unspecified radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52509E Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52509F Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52509G Unspecified fracture of the lower end of unspecified radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52509H Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52509J Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52509K Unspecified fracture of the lower end of unspecified radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52509M Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52509N Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52509P Unspecified fracture of the lower end of unspecified radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52509Q Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52509R Unspecified fracture of the lower end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52509S Unspecified fracture of the lower end of unspecified radius, sequela',
  'S52511A Displaced fracture of right radial styloid process, initial encounter for closed fracture',
  'S52511B Displaced fracture of right radial styloid process, initial encounter for open fracture type I or II',
  'S52511C Displaced fracture of right radial styloid process, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52511D Displaced fracture of right radial styloid process, subsequent encounter for closed fracture',
  'with routine healing',
  'S52511E Displaced fracture of right radial styloid process, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52511F Displaced fracture of right radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52511G Displaced fracture of right radial styloid process, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52511H Displaced fracture of right radial styloid process, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S52511J Displaced fracture of right radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52511K Displaced fracture of right radial styloid process, subsequent encounter for closed fracture with',
  'nonunion',
  'S52511M Displaced fracture of right radial styloid process, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52511N Displaced fracture of right radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52511P Displaced fracture of right radial styloid process, subsequent encounter for closed fracture with',
  'malunion',
  'S52511Q Displaced fracture of right radial styloid process, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S52511R Displaced fracture of right radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52511S Displaced fracture of right radial styloid process, sequela',
  'S52512A Displaced fracture of left radial styloid process, initial encounter for closed fracture',
  'S52512B Displaced fracture of left radial styloid process, initial encounter for open fracture type I or II',
  'S52512C Displaced fracture of left radial styloid process, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52512D Displaced fracture of left radial styloid process, subsequent encounter for closed fracture with',
  'routine healing',
  'S52512E Displaced fracture of left radial styloid process, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52512F Displaced fracture of left radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52512G Displaced fracture of left radial styloid process, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52512H Displaced fracture of left radial styloid process, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52512J Displaced fracture of left radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52512K Displaced fracture of left radial styloid process, subsequent encounter for closed fracture with',
  'nonunion',
  'S52512M Displaced fracture of left radial styloid process, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52512N Displaced fracture of left radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52512P Displaced fracture of left radial styloid process, subsequent encounter for closed fracture with',
  'malunion',
  'S52512Q Displaced fracture of left radial styloid process, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52512R Displaced fracture of left radial styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52512S Displaced fracture of left radial styloid process, sequela',
  'S52513A Displaced fracture of unspecified radial styloid process, initial encounter for closed fracture',
  'S52513B Displaced fracture of unspecified radial styloid process, initial encounter for open fracture type',
  'I or II',
  'S52513C Displaced fracture of unspecified radial styloid process, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52513D Displaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with routine healing',
  'S52513E Displaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52513F Displaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52513G Displaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52513H Displaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52513J Displaced fracture of unspecified radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52513K Displaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with nonunion',
  'S52513M Displaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52513N Displaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52513P Displaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with malunion',
  'S52513Q Displaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52513R Displaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52513S Displaced fracture of unspecified radial styloid process, sequela',
  'S52514A Nondisplaced fracture of right radial styloid process, initial encounter for closed fracture',
  'S52514B Nondisplaced fracture of right radial styloid process, initial encounter for open fracture type I',
  'or II',
  'S52514C Nondisplaced fracture of right radial styloid process, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52514D Nondisplaced fracture of right radial styloid process, subsequent encounter for closed fracture',
  'with routine healing',
  'S52514E Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52514F Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52514G Nondisplaced fracture of right radial styloid process, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52514H Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52514J Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52514K Nondisplaced fracture of right radial styloid process, subsequent encounter for closed fracture',
  'with nonunion',
  'S52514M Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52514N Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52514P Nondisplaced fracture of right radial styloid process, subsequent encounter for closed fracture',
  'with malunion',
  'S52514Q Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52514R Nondisplaced fracture of right radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52514S Nondisplaced fracture of right radial styloid process, sequela',
  'S52515A Nondisplaced fracture of left radial styloid process, initial encounter for closed fracture',
  'S52515B Nondisplaced fracture of left radial styloid process, initial encounter for open fracture type I or',
  'II',
  'S52515C Nondisplaced fracture of left radial styloid process, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52515D Nondisplaced fracture of left radial styloid process, subsequent encounter for closed fracture',
  'with routine healing',
  'S52515E Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52515F Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52515G Nondisplaced fracture of left radial styloid process, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52515H Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52515J Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52515K Nondisplaced fracture of left radial styloid process, subsequent encounter for closed fracture',
  'with nonunion',
  'S52515M Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52515N Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52515P Nondisplaced fracture of left radial styloid process, subsequent encounter for closed fracture',
  'with malunion',
  'S52515Q Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52515R Nondisplaced fracture of left radial styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52515S Nondisplaced fracture of left radial styloid process, sequela',
  'S52516A Nondisplaced fracture of unspecified radial styloid process, initial encounter for closed fracture',
  'S52516B Nondisplaced fracture of unspecified radial styloid process, initial encounter for open fracture',
  'type I or II',
  'S52516C Nondisplaced fracture of unspecified radial styloid process, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52516D Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with routine healing',
  'S52516E Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52516F Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52516G Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52516H Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52516J Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52516K Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with nonunion',
  'S52516M Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52516N Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52516P Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for closed',
  'fracture with malunion',
  'S52516Q Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52516R Nondisplaced fracture of unspecified radial styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52516S Nondisplaced fracture of unspecified radial styloid process, sequela',
  'S52521A Torus fracture of lower end of right radius, initial encounter for closed fracture',
  'S52521D Torus fracture of lower end of right radius, subsequent encounter for fracture with routine',
  'healing',
  'S52521G Torus fracture of lower end of right radius, subsequent encounter for fracture with delayed',
  'healing',
  'S52521K Torus fracture of lower end of right radius, subsequent encounter for fracture with nonunion',
  'S52521P Torus fracture of lower end of right radius, subsequent encounter for fracture with malunion',
  'S52521S Torus fracture of lower end of right radius, sequela',
  'S52522A Torus fracture of lower end of left radius, initial encounter for closed fracture',
  'S52522D Torus fracture of lower end of left radius, subsequent encounter for fracture with routine',
  'healing',
  'S52522G Torus fracture of lower end of left radius, subsequent encounter for fracture with delayed',
  'healing',
  'S52522K Torus fracture of lower end of left radius, subsequent encounter for fracture with nonunion',
  'S52522P Torus fracture of lower end of left radius, subsequent encounter for fracture with malunion',
  'S52522S Torus fracture of lower end of left radius, sequela',
  'S52529A Torus fracture of lower end of unspecified radius, initial encounter for closed fracture',
  'S52529D Torus fracture of lower end of unspecified radius, subsequent encounter for fracture with',
  'routine healing',
  'S52529G Torus fracture of lower end of unspecified radius, subsequent encounter for fracture with',
  'delayed healing',
  'S52529K Torus fracture of lower end of unspecified radius, subsequent encounter for fracture with',
  'nonunion',
  'S52529P Torus fracture of lower end of unspecified radius, subsequent encounter for fracture with',
  'malunion',
  'S52529S Torus fracture of lower end of unspecified radius, sequela',
  "S52531A Colles' fracture of right radius, initial encounter for closed fracture",
  "S52531B Colles' fracture of right radius, initial encounter for open fracture type I or II",
  "S52531C Colles' fracture of right radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52531D Colles' fracture of right radius, subsequent encounter for closed fracture with routine healing",
  "S52531E Colles' fracture of right radius, subsequent encounter for open fracture type I or II with routine",
  'healing',
  "S52531F Colles' fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52531G Colles' fracture of right radius, subsequent encounter for closed fracture with delayed healing",
  "S52531H Colles' fracture of right radius, subsequent encounter for open fracture type I or II with delayed",
  'healing',
  "S52531J Colles' fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52531K Colles' fracture of right radius, subsequent encounter for closed fracture with nonunion",
  "S52531M Colles' fracture of right radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52531N Colles' fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52531P Colles' fracture of right radius, subsequent encounter for closed fracture with malunion",
  "S52531Q Colles' fracture of right radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52531R Colles' fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52531S Colles' fracture of right radius, sequela",
  "S52532A Colles' fracture of left radius, initial encounter for closed fracture",
  "S52532B Colles' fracture of left radius, initial encounter for open fracture type I or II",
  "S52532C Colles' fracture of left radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52532D Colles' fracture of left radius, subsequent encounter for closed fracture with routine healing",
  "S52532E Colles' fracture of left radius, subsequent encounter for open fracture type I or II with routine",
  'healing',
  "S52532F Colles' fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with",
  'routine healing',
  "S52532G Colles' fracture of left radius, subsequent encounter for closed fracture with delayed healing",
  "S52532H Colles' fracture of left radius, subsequent encounter for open fracture type I or II with delayed",
  'healing',
  "S52532J Colles' fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with",
  'delayed healing',
  "S52532K Colles' fracture of left radius, subsequent encounter for closed fracture with nonunion",
  "S52532M Colles' fracture of left radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52532N Colles' fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with",
  'nonunion',
  "S52532P Colles' fracture of left radius, subsequent encounter for closed fracture with malunion",
  "S52532Q Colles' fracture of left radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52532R Colles' fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with",
  'malunion',
  "S52532S Colles' fracture of left radius, sequela",
  "S52539A Colles' fracture of unspecified radius, initial encounter for closed fracture",
  "S52539B Colles' fracture of unspecified radius, initial encounter for open fracture type I or II",
  "S52539C Colles' fracture of unspecified radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52539D Colles' fracture of unspecified radius, subsequent encounter for closed fracture with routine",
  'healing',
  "S52539E Colles' fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52539F Colles' fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with routine healing',
  "S52539G Colles' fracture of unspecified radius, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52539H Colles' fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52539J Colles' fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with delayed healing',
  "S52539K Colles' fracture of unspecified radius, subsequent encounter for closed fracture with nonunion",
  "S52539M Colles' fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52539N Colles' fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with nonunion',
  "S52539P Colles' fracture of unspecified radius, subsequent encounter for closed fracture with malunion",
  "S52539Q Colles' fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52539R Colles' fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with malunion',
  "S52539S Colles' fracture of unspecified radius, sequela",
  "S52541A Smith's fracture of right radius, initial encounter for closed fracture",
  "S52541B Smith's fracture of right radius, initial encounter for open fracture type I or II",
  "S52541C Smith's fracture of right radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52541D Smith's fracture of right radius, subsequent encounter for closed fracture with routine healing",
  "S52541E Smith's fracture of right radius, subsequent encounter for open fracture type I or II with routine",
  'healing',
  "S52541F Smith's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52541G Smith's fracture of right radius, subsequent encounter for closed fracture with delayed healing",
  "S52541H Smith's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52541J Smith's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52541K Smith's fracture of right radius, subsequent encounter for closed fracture with nonunion",
  "S52541M Smith's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52541N Smith's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52541P Smith's fracture of right radius, subsequent encounter for closed fracture with malunion",
  "S52541Q Smith's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52541R Smith's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52541S Smith's fracture of right radius, sequela",
  "S52542A Smith's fracture of left radius, initial encounter for closed fracture",
  "S52542B Smith's fracture of left radius, initial encounter for open fracture type I or II",
  "S52542C Smith's fracture of left radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52542D Smith's fracture of left radius, subsequent encounter for closed fracture with routine healing",
  "S52542E Smith's fracture of left radius, subsequent encounter for open fracture type I or II with routine",
  'healing',
  "S52542F Smith's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52542G Smith's fracture of left radius, subsequent encounter for closed fracture with delayed healing",
  "S52542H Smith's fracture of left radius, subsequent encounter for open fracture type I or II with delayed",
  'healing',
  "S52542J Smith's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with",
  'delayed healing',
  "S52542K Smith's fracture of left radius, subsequent encounter for closed fracture with nonunion",
  "S52542M Smith's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52542N Smith's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52542P Smith's fracture of left radius, subsequent encounter for closed fracture with malunion",
  "S52542Q Smith's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52542R Smith's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52542S Smith's fracture of left radius, sequela",
  "S52549A Smith's fracture of unspecified radius, initial encounter for closed fracture",
  "S52549B Smith's fracture of unspecified radius, initial encounter for open fracture type I or II",
  "S52549C Smith's fracture of unspecified radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52549D Smith's fracture of unspecified radius, subsequent encounter for closed fracture with routine",
  'healing',
  "S52549E Smith's fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52549F Smith's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with routine healing',
  "S52549G Smith's fracture of unspecified radius, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52549H Smith's fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52549J Smith's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with delayed healing',
  "S52549K Smith's fracture of unspecified radius, subsequent encounter for closed fracture with nonunion",
  "S52549M Smith's fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52549N Smith's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with nonunion',
  "S52549P Smith's fracture of unspecified radius, subsequent encounter for closed fracture with malunion",
  "S52549Q Smith's fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52549R Smith's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB, or",
  'IIIC with malunion',
  "S52549S Smith's fracture of unspecified radius, sequela",
  'S52551A Other extraarticular fracture of lower end of right radius, initial encounter for closed fracture',
  'S52551B Other extraarticular fracture of lower end of right radius, initial encounter for open fracture',
  'type I or II',
  'S52551C Other extraarticular fracture of lower end of right radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52551D Other extraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52551E Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52551F Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52551G Other extraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52551H Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52551J Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52551K Other extraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52551M Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52551N Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52551P Other extraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52551Q Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52551R Other extraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52551S Other extraarticular fracture of lower end of right radius, sequela',
  'S52552A Other extraarticular fracture of lower end of left radius, initial encounter for closed fracture',
  'S52552B Other extraarticular fracture of lower end of left radius, initial encounter for open fracture type',
  'I or II',
  'S52552C Other extraarticular fracture of lower end of left radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52552D Other extraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52552E Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52552F Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52552G Other extraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52552H Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52552J Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52552K Other extraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52552M Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52552N Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52552P Other extraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52552Q Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52552R Other extraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52552S Other extraarticular fracture of lower end of left radius, sequela',
  'S52559A Other extraarticular fracture of lower end of unspecified radius, initial encounter for closed',
  'fracture',
  'S52559B Other extraarticular fracture of lower end of unspecified radius, initial encounter for open',
  'fracture type I or II',
  'S52559C Other extraarticular fracture of lower end of unspecified radius, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52559D Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with routine healing',
  'S52559E Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S52559F Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52559G Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52559H Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52559J Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52559K Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with nonunion',
  'S52559M Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52559N Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52559P Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with malunion',
  'S52559Q Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52559R Other extraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52559S Other extraarticular fracture of lower end of unspecified radius, sequela',
  "S52561A Barton's fracture of right radius, initial encounter for closed fracture",
  "S52561B Barton's fracture of right radius, initial encounter for open fracture type I or II",
  "S52561C Barton's fracture of right radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52561D Barton's fracture of right radius, subsequent encounter for closed fracture with routine healing",
  "S52561E Barton's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52561F Barton's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52561G Barton's fracture of right radius, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52561H Barton's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52561J Barton's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52561K Barton's fracture of right radius, subsequent encounter for closed fracture with nonunion",
  "S52561M Barton's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52561N Barton's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52561P Barton's fracture of right radius, subsequent encounter for closed fracture with malunion",
  "S52561Q Barton's fracture of right radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52561R Barton's fracture of right radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52561S Barton's fracture of right radius, sequela",
  "S52562A Barton's fracture of left radius, initial encounter for closed fracture",
  "S52562B Barton's fracture of left radius, initial encounter for open fracture type I or II",
  "S52562C Barton's fracture of left radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52562D Barton's fracture of left radius, subsequent encounter for closed fracture with routine healing",
  "S52562E Barton's fracture of left radius, subsequent encounter for open fracture type I or II with routine",
  'healing',
  "S52562F Barton's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with routine healing',
  "S52562G Barton's fracture of left radius, subsequent encounter for closed fracture with delayed healing",
  "S52562H Barton's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'delayed healing',
  "S52562J Barton's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with delayed healing',
  "S52562K Barton's fracture of left radius, subsequent encounter for closed fracture with nonunion",
  "S52562M Barton's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'nonunion',
  "S52562N Barton's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with nonunion',
  "S52562P Barton's fracture of left radius, subsequent encounter for closed fracture with malunion",
  "S52562Q Barton's fracture of left radius, subsequent encounter for open fracture type I or II with",
  'malunion',
  "S52562R Barton's fracture of left radius, subsequent encounter for open fracture type IIIA, IIIB, or IIIC",
  'with malunion',
  "S52562S Barton's fracture of left radius, sequela",
  "S52569A Barton's fracture of unspecified radius, initial encounter for closed fracture",
  "S52569B Barton's fracture of unspecified radius, initial encounter for open fracture type I or II",
  "S52569C Barton's fracture of unspecified radius, initial encounter for open fracture type IIIA, IIIB, or IIIC",
  "S52569D Barton's fracture of unspecified radius, subsequent encounter for closed fracture with routine",
  'healing',
  "S52569E Barton's fracture of unspecified radius, subsequent encounter for open fracture type I or II with",
  'routine healing',
  "S52569F Barton's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with routine healing',
  "S52569G Barton's fracture of unspecified radius, subsequent encounter for closed fracture with delayed",
  'healing',
  "S52569H Barton's fracture of unspecified radius, subsequent encounter for open fracture type I or II",
  'with delayed healing',
  "S52569J Barton's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with delayed healing',
  "S52569K Barton's fracture of unspecified radius, subsequent encounter for closed fracture with",
  'nonunion',
  "S52569M Barton's fracture of unspecified radius, subsequent encounter for open fracture type I or II",
  'with nonunion',
  "S52569N Barton's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with nonunion',
  "S52569P Barton's fracture of unspecified radius, subsequent encounter for closed fracture with",
  'malunion',
  "S52569Q Barton's fracture of unspecified radius, subsequent encounter for open fracture type I or II",
  'with malunion',
  "S52569R Barton's fracture of unspecified radius, subsequent encounter for open fracture type IIIA, IIIB,",
  'or IIIC with malunion',
  "S52569S Barton's fracture of unspecified radius, sequela",
  'S52571A Other intraarticular fracture of lower end of right radius, initial encounter for closed fracture',
  'S52571B Other intraarticular fracture of lower end of right radius, initial encounter for open fracture',
  'type I or II',
  'S52571C Other intraarticular fracture of lower end of right radius, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52571D Other intraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52571E Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52571F Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52571G Other intraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52571H Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52571J Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52571K Other intraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52571M Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52571N Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52571P Other intraarticular fracture of lower end of right radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52571Q Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52571R Other intraarticular fracture of lower end of right radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52571S Other intraarticular fracture of lower end of right radius, sequela',
  'S52572A Other intraarticular fracture of lower end of left radius, initial encounter for closed fracture',
  'S52572B Other intraarticular fracture of lower end of left radius, initial encounter for open fracture type',
  'I or II',
  'S52572C Other intraarticular fracture of lower end of left radius, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52572D Other intraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with routine healing',
  'S52572E Other intraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52572F Other intraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52572G Other intraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52572H Other intraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52572J Other intraarticular fracture of lower end of left radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52572K Other intraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with nonunion',
  'S52572M Other intraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52572N Other intraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52572P Other intraarticular fracture of lower end of left radius, subsequent encounter for closed',
  'fracture with malunion',
  'S52572Q Other intraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52572R Other intraarticular fracture of lower end of left radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52572S Other intraarticular fracture of lower end of left radius, sequela',
  'S52579A Other intraarticular fracture of lower end of unspecified radius, initial encounter for closed',
  'fracture',
  'S52579B Other intraarticular fracture of lower end of unspecified radius, initial encounter for open',
  'fracture type I or II',
  'S52579C Other intraarticular fracture of lower end of unspecified radius, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S52579D Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with routine healing',
  'S52579E Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52579F Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52579G Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with delayed healing',
  'S52579H Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S52579J Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52579K Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with nonunion',
  'S52579M Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S52579N Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52579P Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'closed fracture with malunion',
  'S52579Q Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S52579R Other intraarticular fracture of lower end of unspecified radius, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S52579S Other intraarticular fracture of lower end of unspecified radius, sequela',
  'S52591A Other fractures of lower end of right radius, initial encounter for closed fracture',
  'S52591B Other fractures of lower end of right radius, initial encounter for open fracture type I or II',
  'S52591C Other fractures of lower end of right radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52591D Other fractures of lower end of right radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52591E Other fractures of lower end of right radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52591F Other fractures of lower end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52591G Other fractures of lower end of right radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52591H Other fractures of lower end of right radius, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S52591J Other fractures of lower end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52591K Other fractures of lower end of right radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52591M Other fractures of lower end of right radius, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S52591N Other fractures of lower end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52591P Other fractures of lower end of right radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52591Q Other fractures of lower end of right radius, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S52591R Other fractures of lower end of right radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52591S Other fractures of lower end of right radius, sequela',
  'S52592A Other fractures of lower end of left radius, initial encounter for closed fracture',
  'S52592B Other fractures of lower end of left radius, initial encounter for open fracture type I or II',
  'S52592C Other fractures of lower end of left radius, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52592D Other fractures of lower end of left radius, subsequent encounter for closed fracture with',
  'routine healing',
  'S52592E Other fractures of lower end of left radius, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52592F Other fractures of lower end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52592G Other fractures of lower end of left radius, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52592H Other fractures of lower end of left radius, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52592J Other fractures of lower end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52592K Other fractures of lower end of left radius, subsequent encounter for closed fracture with',
  'nonunion',
  'S52592M Other fractures of lower end of left radius, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52592N Other fractures of lower end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52592P Other fractures of lower end of left radius, subsequent encounter for closed fracture with',
  'malunion',
  'S52592Q Other fractures of lower end of left radius, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52592R Other fractures of lower end of left radius, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52592S Other fractures of lower end of left radius, sequela',
  'S52599A Other fractures of lower end of unspecified radius, initial encounter for closed fracture',
  'S52599B Other fractures of lower end of unspecified radius, initial encounter for open fracture type I or',
  'II',
  'S52599C Other fractures of lower end of unspecified radius, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52599D Other fractures of lower end of unspecified radius, subsequent encounter for closed fracture',
  'with routine healing',
  'S52599E Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52599F Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52599G Other fractures of lower end of unspecified radius, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52599H Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52599J Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52599K Other fractures of lower end of unspecified radius, subsequent encounter for closed fracture',
  'with nonunion',
  'S52599M Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52599N Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52599P Other fractures of lower end of unspecified radius, subsequent encounter for closed fracture',
  'with malunion',
  'S52599Q Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52599R Other fractures of lower end of unspecified radius, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52599S Other fractures of lower end of unspecified radius, sequela',
  'S52601A Unspecified fracture of lower end of right ulna, initial encounter for closed fracture',
  'S52601B Unspecified fracture of lower end of right ulna, initial encounter for open fracture type I or II',
  'S52601C Unspecified fracture of lower end of right ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52601D Unspecified fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52601E Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52601F Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52601G Unspecified fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52601H Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52601J Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52601K Unspecified fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52601M Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52601N Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52601P Unspecified fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52601Q Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52601R Unspecified fracture of lower end of right ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52601S Unspecified fracture of lower end of right ulna, sequela',
  'S52602A Unspecified fracture of lower end of left ulna, initial encounter for closed fracture',
  'S52602B Unspecified fracture of lower end of left ulna, initial encounter for open fracture type I or II',
  'S52602C Unspecified fracture of lower end of left ulna, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S52602D Unspecified fracture of lower end of left ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52602E Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S52602F Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52602G Unspecified fracture of lower end of left ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52602H Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52602J Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52602K Unspecified fracture of lower end of left ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52602M Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52602N Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52602P Unspecified fracture of lower end of left ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52602Q Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52602R Unspecified fracture of lower end of left ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52602S Unspecified fracture of lower end of left ulna, sequela',
  'S52609A Unspecified fracture of lower end of unspecified ulna, initial encounter for closed fracture',
  'S52609B Unspecified fracture of lower end of unspecified ulna, initial encounter for open fracture type I',
  'or II',
  'S52609C Unspecified fracture of lower end of unspecified ulna, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52609D Unspecified fracture of lower end of unspecified ulna, subsequent encounter for closed',
  'fracture with routine healing',
  'S52609E Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52609F Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52609G Unspecified fracture of lower end of unspecified ulna, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52609H Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52609J Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52609K Unspecified fracture of lower end of unspecified ulna, subsequent encounter for closed',
  'fracture with nonunion',
  'S52609M Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52609N Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52609P Unspecified fracture of lower end of unspecified ulna, subsequent encounter for closed',
  'fracture with malunion',
  'S52609Q Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52609R Unspecified fracture of lower end of unspecified ulna, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52609S Unspecified fracture of lower end of unspecified ulna, sequela',
  'S52611A Displaced fracture of right ulna styloid process, initial encounter for closed fracture',
  'S52611B Displaced fracture of right ulna styloid process, initial encounter for open fracture type I or II',
  'S52611C Displaced fracture of right ulna styloid process, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52611D Displaced fracture of right ulna styloid process, subsequent encounter for closed fracture with',
  'routine healing',
  'S52611E Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52611F Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52611G Displaced fracture of right ulna styloid process, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52611H Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52611J Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52611K Displaced fracture of right ulna styloid process, subsequent encounter for closed fracture with',
  'nonunion',
  'S52611M Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52611N Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52611P Displaced fracture of right ulna styloid process, subsequent encounter for closed fracture with',
  'malunion',
  'S52611Q Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52611R Displaced fracture of right ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52611S Displaced fracture of right ulna styloid process, sequela',
  'S52612A Displaced fracture of left ulna styloid process, initial encounter for closed fracture',
  'S52612B Displaced fracture of left ulna styloid process, initial encounter for open fracture type I or II',
  'S52612C Displaced fracture of left ulna styloid process, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S52612D Displaced fracture of left ulna styloid process, subsequent encounter for closed fracture with',
  'routine healing',
  'S52612E Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S52612F Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52612G Displaced fracture of left ulna styloid process, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52612H Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52612J Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S52612K Displaced fracture of left ulna styloid process, subsequent encounter for closed fracture with',
  'nonunion',
  'S52612M Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S52612N Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52612P Displaced fracture of left ulna styloid process, subsequent encounter for closed fracture with',
  'malunion',
  'S52612Q Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52612R Displaced fracture of left ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52612S Displaced fracture of left ulna styloid process, sequela',
  'S52613A Displaced fracture of unspecified ulna styloid process, initial encounter for closed fracture',
  'S52613B Displaced fracture of unspecified ulna styloid process, initial encounter for open fracture type I',
  'or II',
  'S52613C Displaced fracture of unspecified ulna styloid process, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S52613D Displaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with routine healing',
  'S52613E Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52613F Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52613G Displaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52613H Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52613J Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52613K Displaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with nonunion',
  'S52613M Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52613N Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52613P Displaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with malunion',
  'S52613Q Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52613R Displaced fracture of unspecified ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52613S Displaced fracture of unspecified ulna styloid process, sequela',
  'S52614A Nondisplaced fracture of right ulna styloid process, initial encounter for closed fracture',
  'S52614B Nondisplaced fracture of right ulna styloid process, initial encounter for open fracture type I or',
  'II',
  'S52614C Nondisplaced fracture of right ulna styloid process, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52614D Nondisplaced fracture of right ulna styloid process, subsequent encounter for closed fracture',
  'with routine healing',
  'S52614E Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S52614F Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S52614G Nondisplaced fracture of right ulna styloid process, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52614H Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52614J Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S52614K Nondisplaced fracture of right ulna styloid process, subsequent encounter for closed fracture',
  'with nonunion',
  'S52614M Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52614N Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52614P Nondisplaced fracture of right ulna styloid process, subsequent encounter for closed fracture',
  'with malunion',
  'S52614Q Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52614R Nondisplaced fracture of right ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S52614S Nondisplaced fracture of right ulna styloid process, sequela',
  'S52615A Nondisplaced fracture of left ulna styloid process, initial encounter for closed fracture',
  'S52615B Nondisplaced fracture of left ulna styloid process, initial encounter for open fracture type I or II',
  'S52615C Nondisplaced fracture of left ulna styloid process, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52615D Nondisplaced fracture of left ulna styloid process, subsequent encounter for closed fracture',
  'with routine healing',
  'S52615E Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S52615F Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52615G Nondisplaced fracture of left ulna styloid process, subsequent encounter for closed fracture',
  'with delayed healing',
  'S52615H Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S52615J Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52615K Nondisplaced fracture of left ulna styloid process, subsequent encounter for closed fracture',
  'with nonunion',
  'S52615M Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S52615N Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S52615P Nondisplaced fracture of left ulna styloid process, subsequent encounter for closed fracture',
  'with malunion',
  'S52615Q Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S52615R Nondisplaced fracture of left ulna styloid process, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52615S Nondisplaced fracture of left ulna styloid process, sequela',
  'S52616A Nondisplaced fracture of unspecified ulna styloid process, initial encounter for closed fracture',
  'S52616B Nondisplaced fracture of unspecified ulna styloid process, initial encounter for open fracture',
  'type I or II',
  'S52616C Nondisplaced fracture of unspecified ulna styloid process, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S52616D Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with routine healing',
  'S52616E Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S52616F Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S52616G Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with delayed healing',
  'S52616H Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S52616J Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S52616K Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with nonunion',
  'S52616M Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S52616N Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S52616P Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for closed',
  'fracture with malunion',
  'S52616Q Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S52616R Nondisplaced fracture of unspecified ulna styloid process, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S52616S Nondisplaced fracture of unspecified ulna styloid process, sequela',
  'S52621A Torus fracture of lower end of right ulna, initial encounter for closed fracture',
  'S52621D Torus fracture of lower end of right ulna, subsequent encounter for fracture with routine',
  'healing',
  'S52621G Torus fracture of lower end of right ulna, subsequent encounter for fracture with delayed',
  'healing',
  'S52621K Torus fracture of lower end of right ulna, subsequent encounter for fracture with nonunion',
  'S52621P Torus fracture of lower end of right ulna, subsequent encounter for fracture with malunion',
  'S52621S Torus fracture of lower end of right ulna, sequela',
  'S52622A Torus fracture of lower end of left ulna, initial encounter for closed fracture',
  'S52622D Torus fracture of lower end of left ulna, subsequent encounter for fracture with routine',
  'healing',
  'S52622G Torus fracture of lower end of left ulna, subsequent encounter for fracture with delayed',
  'healing',
  'S52622K Torus fracture of lower end of left ulna, subsequent encounter for fracture with nonunion',
  'S52622P Torus fracture of lower end of left ulna, subsequent encounter for fracture with malunion',
  'S52622S Torus fracture of lower end of left ulna, sequela',
  'S52629A Torus fracture of lower end of unspecified ulna, initial encounter for closed fracture',
  'S52629D Torus fracture of lower end of unspecified ulna, subsequent encounter for fracture with',
  'routine healing',
  'S52629G Torus fracture of lower end of unspecified ulna, subsequent encounter for fracture with',
  'delayed healing',
  'S52629K Torus fracture of lower end of unspecified ulna, subsequent encounter for fracture with',
  'nonunion',
  'S52629P Torus fracture of lower end of unspecified ulna, subsequent encounter for fracture with',
  'malunion',
  'S52629S Torus fracture of lower end of unspecified ulna, sequela',
  'S52691A Other fracture of lower end of right ulna, initial encounter for closed fracture',
  'S52691B Other fracture of lower end of right ulna, initial encounter for open fracture type I or II',
  'S52691C Other fracture of lower end of right ulna, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S52691D Other fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52691E Other fracture of lower end of right ulna, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52691F Other fracture of lower end of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S52691G Other fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52691H Other fracture of lower end of right ulna, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52691J Other fracture of lower end of right ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52691K Other fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52691M Other fracture of lower end of right ulna, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52691N Other fracture of lower end of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S52691P Other fracture of lower end of right ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52691Q Other fracture of lower end of right ulna, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52691R Other fracture of lower end of right ulna, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S52691S Other fracture of lower end of right ulna, sequela',
  'S52692A Other fracture of lower end of left ulna, initial encounter for closed fracture',
  'S52692B Other fracture of lower end of left ulna, initial encounter for open fracture type I or II',
  'S52692C Other fracture of lower end of left ulna, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S52692D Other fracture of lower end of left ulna, subsequent encounter for closed fracture with routine',
  'healing',
  'S52692E Other fracture of lower end of left ulna, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S52692F Other fracture of lower end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S52692G Other fracture of lower end of left ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52692H Other fracture of lower end of left ulna, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S52692J Other fracture of lower end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S52692K Other fracture of lower end of left ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52692M Other fracture of lower end of left ulna, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S52692N Other fracture of lower end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S52692P Other fracture of lower end of left ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52692Q Other fracture of lower end of left ulna, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S52692R Other fracture of lower end of left ulna, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S52692S Other fracture of lower end of left ulna, sequela',
  'S52699A Other fracture of lower end of unspecified ulna, initial encounter for closed fracture',
  'S52699B Other fracture of lower end of unspecified ulna, initial encounter for open fracture type I or II',
  'S52699C Other fracture of lower end of unspecified ulna, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S52699D Other fracture of lower end of unspecified ulna, subsequent encounter for closed fracture with',
  'routine healing',
  'S52699E Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S52699F Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S52699G Other fracture of lower end of unspecified ulna, subsequent encounter for closed fracture with',
  'delayed healing',
  'S52699H Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S52699J Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S52699K Other fracture of lower end of unspecified ulna, subsequent encounter for closed fracture with',
  'nonunion',
  'S52699M Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S52699N Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S52699P Other fracture of lower end of unspecified ulna, subsequent encounter for closed fracture with',
  'malunion',
  'S52699Q Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S52699R Other fracture of lower end of unspecified ulna, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S52699S Other fracture of lower end of unspecified ulna, sequela',
  'S5290XA Unspecified fracture of unspecified forearm, initial encounter for closed fracture',
  'S5290XB Unspecified fracture of unspecified forearm, initial encounter for open fracture type I or II',
  'S5290XC Unspecified fracture of unspecified forearm, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S5290XD Unspecified fracture of unspecified forearm, subsequent encounter for closed fracture with',
  'routine healing',
  'S5290XE Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S5290XF Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S5290XG Unspecified fracture of unspecified forearm, subsequent encounter for closed fracture with',
  'delayed healing',
  'S5290XH Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S5290XJ Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S5290XK Unspecified fracture of unspecified forearm, subsequent encounter for closed fracture with',
  'nonunion',
  'S5290XM Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S5290XN Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S5290XP Unspecified fracture of unspecified forearm, subsequent encounter for closed fracture with',
  'malunion',
  'S5290XQ Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S5290XR Unspecified fracture of unspecified forearm, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S5290XS Unspecified fracture of unspecified forearm, sequela',
  'S5291XA Unspecified fracture of right forearm, initial encounter for closed fracture',
  'S5291XB Unspecified fracture of right forearm, initial encounter for open fracture type I or II',
  'S5291XC Unspecified fracture of right forearm, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S5291XD Unspecified fracture of right forearm, subsequent encounter for closed fracture with routine',
  'healing',
  'S5291XE Unspecified fracture of right forearm, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S5291XF Unspecified fracture of right forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S5291XG Unspecified fracture of right forearm, subsequent encounter for closed fracture with delayed',
  'healing',
  'S5291XH Unspecified fracture of right forearm, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S5291XJ Unspecified fracture of right forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S5291XK Unspecified fracture of right forearm, subsequent encounter for closed fracture with nonunion',
  'S5291XM Unspecified fracture of right forearm, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S5291XN Unspecified fracture of right forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S5291XP Unspecified fracture of right forearm, subsequent encounter for closed fracture with malunion',
  'S5291XQ Unspecified fracture of right forearm, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S5291XR Unspecified fracture of right forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S5291XS Unspecified fracture of right forearm, sequela',
  'S5292XA Unspecified fracture of left forearm, initial encounter for closed fracture',
  'S5292XB Unspecified fracture of left forearm, initial encounter for open fracture type I or II',
  'S5292XC Unspecified fracture of left forearm, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S5292XD Unspecified fracture of left forearm, subsequent encounter for closed fracture with routine',
  'healing',
  'S5292XE Unspecified fracture of left forearm, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S5292XF Unspecified fracture of left forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S5292XG Unspecified fracture of left forearm, subsequent encounter for closed fracture with delayed',
  'healing',
  'S5292XH Unspecified fracture of left forearm, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S5292XJ Unspecified fracture of left forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S5292XK Unspecified fracture of left forearm, subsequent encounter for closed fracture with nonunion',
  'S5292XM Unspecified fracture of left forearm, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S5292XN Unspecified fracture of left forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S5292XP Unspecified fracture of left forearm, subsequent encounter for closed fracture with malunion',
  'S5292XQ Unspecified fracture of left forearm, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S5292XR Unspecified fracture of left forearm, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S5292XS Unspecified fracture of left forearm, sequela',
  'S53001A Unspecified subluxation of right radial head, initial encounter',
  'S53001D Unspecified subluxation of right radial head, subsequent encounter',
  'S53001S Unspecified subluxation of right radial head, sequela',
  'S53002A Unspecified subluxation of left radial head, initial encounter',
  'S53002D Unspecified subluxation of left radial head, subsequent encounter',
  'S53002S Unspecified subluxation of left radial head, sequela',
  'S53003A Unspecified subluxation of unspecified radial head, initial encounter',
  'S53003D Unspecified subluxation of unspecified radial head, subsequent encounter',
  'S53003S Unspecified subluxation of unspecified radial head, sequela',
  'S53004A Unspecified dislocation of right radial head, initial encounter',
  'S53004D Unspecified dislocation of right radial head, subsequent encounter',
  'S53004S Unspecified dislocation of right radial head, sequela',
  'S53005A Unspecified dislocation of left radial head, initial encounter',
  'S53005D Unspecified dislocation of left radial head, subsequent encounter',
  'S53005S Unspecified dislocation of left radial head, sequela',
  'S53006A Unspecified dislocation of unspecified radial head, initial encounter',
  'S53006D Unspecified dislocation of unspecified radial head, subsequent encounter',
  'S53006S Unspecified dislocation of unspecified radial head, sequela',
  'S53011A Anterior subluxation of right radial head, initial encounter',
  'S53011D Anterior subluxation of right radial head, subsequent encounter',
  'S53011S Anterior subluxation of right radial head, sequela',
  'S53012A Anterior subluxation of left radial head, initial encounter',
  'S53012D Anterior subluxation of left radial head, subsequent encounter',
  'S53012S Anterior subluxation of left radial head, sequela',
  'S53013A Anterior subluxation of unspecified radial head, initial encounter',
  'S53013D Anterior subluxation of unspecified radial head, subsequent encounter',
  'S53013S Anterior subluxation of unspecified radial head, sequela',
  'S53014A Anterior dislocation of right radial head, initial encounter',
  'S53014D Anterior dislocation of right radial head, subsequent encounter',
  'S53014S Anterior dislocation of right radial head, sequela',
  'S53015A Anterior dislocation of left radial head, initial encounter',
  'S53015D Anterior dislocation of left radial head, subsequent encounter',
  'S53015S Anterior dislocation of left radial head, sequela',
  'S53016A Anterior dislocation of unspecified radial head, initial encounter',
  'S53016D Anterior dislocation of unspecified radial head, subsequent encounter',
  'S53016S Anterior dislocation of unspecified radial head, sequela',
  'S53021A Posterior subluxation of right radial head, initial encounter',
  'S53021D Posterior subluxation of right radial head, subsequent encounter',
  'S53021S Posterior subluxation of right radial head, sequela',
  'S53022A Posterior subluxation of left radial head, initial encounter',
  'S53022D Posterior subluxation of left radial head, subsequent encounter',
  'S53022S Posterior subluxation of left radial head, sequela',
  'S53023A Posterior subluxation of unspecified radial head, initial encounter',
  'S53023D Posterior subluxation of unspecified radial head, subsequent encounter',
  'S53023S Posterior subluxation of unspecified radial head, sequela',
  'S53024A Posterior dislocation of right radial head, initial encounter',
  'S53024D Posterior dislocation of right radial head, subsequent encounter',
  'S53024S Posterior dislocation of right radial head, sequela',
  'S53025A Posterior dislocation of left radial head, initial encounter',
  'S53025D Posterior dislocation of left radial head, subsequent encounter',
  'S53025S Posterior dislocation of left radial head, sequela',
  'S53026A Posterior dislocation of unspecified radial head, initial encounter',
  'S53026D Posterior dislocation of unspecified radial head, subsequent encounter',
  'S53026S Posterior dislocation of unspecified radial head, sequela',
  "S53031A Nursemaid's elbow, right elbow, initial encounter",
  "S53031D Nursemaid's elbow, right elbow, subsequent encounter",
  "S53031S Nursemaid's elbow, right elbow, sequela",
  "S53032A Nursemaid's elbow, left elbow, initial encounter",
  "S53032D Nursemaid's elbow, left elbow, subsequent encounter",
  "S53032S Nursemaid's elbow, left elbow, sequela",
  "S53033A Nursemaid's elbow, unspecified elbow, initial encounter",
  "S53033D Nursemaid's elbow, unspecified elbow, subsequent encounter",
  "S53033S Nursemaid's elbow, unspecified elbow, sequela",
  'S53091A Other subluxation of right radial head, initial encounter',
  'S53091D Other subluxation of right radial head, subsequent encounter',
  'S53091S Other subluxation of right radial head, sequela',
  'S53092A Other subluxation of left radial head, initial encounter',
  'S53092D Other subluxation of left radial head, subsequent encounter',
  'S53092S Other subluxation of left radial head, sequela',
  'S53093A Other subluxation of unspecified radial head, initial encounter',
  'S53093D Other subluxation of unspecified radial head, subsequent encounter',
  'S53093S Other subluxation of unspecified radial head, sequela',
  'S53094A Other dislocation of right radial head, initial encounter',
  'S53094D Other dislocation of right radial head, subsequent encounter',
  'S53094S Other dislocation of right radial head, sequela',
  'S53095A Other dislocation of left radial head, initial encounter',
  'S53095D Other dislocation of left radial head, subsequent encounter',
  'S53095S Other dislocation of left radial head, sequela',
  'S53096A Other dislocation of unspecified radial head, initial encounter',
  'S53096D Other dislocation of unspecified radial head, subsequent encounter',
  'S53096S Other dislocation of unspecified radial head, sequela',
  'S53101A Unspecified subluxation of right ulnohumeral joint, initial encounter',
  'S53101D Unspecified subluxation of right ulnohumeral joint, subsequent encounter',
  'S53101S Unspecified subluxation of right ulnohumeral joint, sequela',
  'S53102A Unspecified subluxation of left ulnohumeral joint, initial encounter',
  'S53102D Unspecified subluxation of left ulnohumeral joint, subsequent encounter',
  'S53102S Unspecified subluxation of left ulnohumeral joint, sequela',
  'S53103A Unspecified subluxation of unspecified ulnohumeral joint, initial encounter',
  'S53103D Unspecified subluxation of unspecified ulnohumeral joint, subsequent encounter',
  'S53103S Unspecified subluxation of unspecified ulnohumeral joint, sequela',
  'S53104A Unspecified dislocation of right ulnohumeral joint, initial encounter',
  'S53104D Unspecified dislocation of right ulnohumeral joint, subsequent encounter',
  'S53104S Unspecified dislocation of right ulnohumeral joint, sequela',
  'S53105A Unspecified dislocation of left ulnohumeral joint, initial encounter',
  'S53105D Unspecified dislocation of left ulnohumeral joint, subsequent encounter',
  'S53105S Unspecified dislocation of left ulnohumeral joint, sequela',
  'S53106A Unspecified dislocation of unspecified ulnohumeral joint, initial encounter',
  'S53106D Unspecified dislocation of unspecified ulnohumeral joint, subsequent encounter',
  'S53106S Unspecified dislocation of unspecified ulnohumeral joint, sequela',
  'S53111A Anterior subluxation of right ulnohumeral joint, initial encounter',
  'S53111D Anterior subluxation of right ulnohumeral joint, subsequent encounter',
  'S53111S Anterior subluxation of right ulnohumeral joint, sequela',
  'S53112A Anterior subluxation of left ulnohumeral joint, initial encounter',
  'S53112D Anterior subluxation of left ulnohumeral joint, subsequent encounter',
  'S53112S Anterior subluxation of left ulnohumeral joint, sequela',
  'S53113A Anterior subluxation of unspecified ulnohumeral joint, initial encounter',
  'S53113D Anterior subluxation of unspecified ulnohumeral joint, subsequent encounter',
  'S53113S Anterior subluxation of unspecified ulnohumeral joint, sequela',
  'S53114A Anterior dislocation of right ulnohumeral joint, initial encounter',
  'S53114D Anterior dislocation of right ulnohumeral joint, subsequent encounter',
  'S53114S Anterior dislocation of right ulnohumeral joint, sequela',
  'S53115A Anterior dislocation of left ulnohumeral joint, initial encounter',
  'S53115D Anterior dislocation of left ulnohumeral joint, subsequent encounter',
  'S53115S Anterior dislocation of left ulnohumeral joint, sequela',
  'S53116A Anterior dislocation of unspecified ulnohumeral joint, initial encounter',
  'S53116D Anterior dislocation of unspecified ulnohumeral joint, subsequent encounter',
  'S53116S Anterior dislocation of unspecified ulnohumeral joint, sequela',
  'S53121A Posterior subluxation of right ulnohumeral joint, initial encounter',
  'S53121D Posterior subluxation of right ulnohumeral joint, subsequent encounter',
  'S53121S Posterior subluxation of right ulnohumeral joint, sequela',
  'S53122A Posterior subluxation of left ulnohumeral joint, initial encounter',
  'S53122D Posterior subluxation of left ulnohumeral joint, subsequent encounter',
  'S53122S Posterior subluxation of left ulnohumeral joint, sequela',
  'S53123A Posterior subluxation of unspecified ulnohumeral joint, initial encounter',
  'S53123D Posterior subluxation of unspecified ulnohumeral joint, subsequent encounter',
  'S53123S Posterior subluxation of unspecified ulnohumeral joint, sequela',
  'S53124A Posterior dislocation of right ulnohumeral joint, initial encounter',
  'S53124D Posterior dislocation of right ulnohumeral joint, subsequent encounter',
  'S53124S Posterior dislocation of right ulnohumeral joint, sequela',
  'S53125A Posterior dislocation of left ulnohumeral joint, initial encounter',
  'S53125D Posterior dislocation of left ulnohumeral joint, subsequent encounter',
  'S53125S Posterior dislocation of left ulnohumeral joint, sequela',
  'S53126A Posterior dislocation of unspecified ulnohumeral joint, initial encounter',
  'S53126D Posterior dislocation of unspecified ulnohumeral joint, subsequent encounter',
  'S53126S Posterior dislocation of unspecified ulnohumeral joint, sequela',
  'S53131A Medial subluxation of right ulnohumeral joint, initial encounter',
  'S53131D Medial subluxation of right ulnohumeral joint, subsequent encounter',
  'S53131S Medial subluxation of right ulnohumeral joint, sequela',
  'S53132A Medial subluxation of left ulnohumeral joint, initial encounter',
  'S53132D Medial subluxation of left ulnohumeral joint, subsequent encounter',
  'S53132S Medial subluxation of left ulnohumeral joint, sequela',
  'S53133A Medial subluxation of unspecified ulnohumeral joint, initial encounter',
  'S53133D Medial subluxation of unspecified ulnohumeral joint, subsequent encounter',
  'S53133S Medial subluxation of unspecified ulnohumeral joint, sequela',
  'S53134A Medial dislocation of right ulnohumeral joint, initial encounter',
  'S53134D Medial dislocation of right ulnohumeral joint, subsequent encounter',
  'S53134S Medial dislocation of right ulnohumeral joint, sequela',
  'S53135A Medial dislocation of left ulnohumeral joint, initial encounter',
  'S53135D Medial dislocation of left ulnohumeral joint, subsequent encounter',
  'S53135S Medial dislocation of left ulnohumeral joint, sequela',
  'S53136A Medial dislocation of unspecified ulnohumeral joint, initial encounter',
  'S53136D Medial dislocation of unspecified ulnohumeral joint, subsequent encounter',
  'S53136S Medial dislocation of unspecified ulnohumeral joint, sequela',
  'S53141A Lateral subluxation of right ulnohumeral joint, initial encounter',
  'S53141D Lateral subluxation of right ulnohumeral joint, subsequent encounter',
  'S53141S Lateral subluxation of right ulnohumeral joint, sequela',
  'S53142A Lateral subluxation of left ulnohumeral joint, initial encounter',
  'S53142D Lateral subluxation of left ulnohumeral joint, subsequent encounter',
  'S53142S Lateral subluxation of left ulnohumeral joint, sequela',
  'S53143A Lateral subluxation of unspecified ulnohumeral joint, initial encounter',
  'S53143D Lateral subluxation of unspecified ulnohumeral joint, subsequent encounter',
  'S53143S Lateral subluxation of unspecified ulnohumeral joint, sequela',
  'S53144A Lateral dislocation of right ulnohumeral joint, initial encounter',
  'S53144D Lateral dislocation of right ulnohumeral joint, subsequent encounter',
  'S53144S Lateral dislocation of right ulnohumeral joint, sequela',
  'S53145A Lateral dislocation of left ulnohumeral joint, initial encounter',
  'S53145D Lateral dislocation of left ulnohumeral joint, subsequent encounter',
  'S53145S Lateral dislocation of left ulnohumeral joint, sequela',
  'S53146A Lateral dislocation of unspecified ulnohumeral joint, initial encounter',
  'S53146D Lateral dislocation of unspecified ulnohumeral joint, subsequent encounter',
  'S53146S Lateral dislocation of unspecified ulnohumeral joint, sequela',
  'S53191A Other subluxation of right ulnohumeral joint, initial encounter',
  'S53191D Other subluxation of right ulnohumeral joint, subsequent encounter',
  'S53191S Other subluxation of right ulnohumeral joint, sequela',
  'S53192A Other subluxation of left ulnohumeral joint, initial encounter',
  'S53192D Other subluxation of left ulnohumeral joint, subsequent encounter',
  'S53192S Other subluxation of left ulnohumeral joint, sequela',
  'S53193A Other subluxation of unspecified ulnohumeral joint, initial encounter',
  'S53193D Other subluxation of unspecified ulnohumeral joint, subsequent encounter',
  'S53193S Other subluxation of unspecified ulnohumeral joint, sequela',
  'S53194A Other dislocation of right ulnohumeral joint, initial encounter',
  'S53194D Other dislocation of right ulnohumeral joint, subsequent encounter',
  'S53194S Other dislocation of right ulnohumeral joint, sequela',
  'S53195A Other dislocation of left ulnohumeral joint, initial encounter',
  'S53195D Other dislocation of left ulnohumeral joint, subsequent encounter',
  'S53195S Other dislocation of left ulnohumeral joint, sequela',
  'S53196A Other dislocation of unspecified ulnohumeral joint, initial encounter',
  'S53196D Other dislocation of unspecified ulnohumeral joint, subsequent encounter',
  'S53196S Other dislocation of unspecified ulnohumeral joint, sequela',
  'S5320XA Traumatic rupture of unspecified radial collateral ligament, initial encounter',
  'S5320XD Traumatic rupture of unspecified radial collateral ligament, subsequent encounter',
  'S5320XS Traumatic rupture of unspecified radial collateral ligament, sequela',
  'S5321XA Traumatic rupture of right radial collateral ligament, initial encounter',
  'S5321XD Traumatic rupture of right radial collateral ligament, subsequent encounter',
  'S5321XS Traumatic rupture of right radial collateral ligament, sequela',
  'S5322XA Traumatic rupture of left radial collateral ligament, initial encounter',
  'S5322XD Traumatic rupture of left radial collateral ligament, subsequent encounter',
  'S5322XS Traumatic rupture of left radial collateral ligament, sequela',
  'S5330XA Traumatic rupture of unspecified ulnar collateral ligament, initial encounter',
  'S5330XD Traumatic rupture of unspecified ulnar collateral ligament, subsequent encounter',
  'S5330XS Traumatic rupture of unspecified ulnar collateral ligament, sequela',
  'S5331XA Traumatic rupture of right ulnar collateral ligament, initial encounter',
  'S5331XD Traumatic rupture of right ulnar collateral ligament, subsequent encounter',
  'S5331XS Traumatic rupture of right ulnar collateral ligament, sequela',
  'S5332XA Traumatic rupture of left ulnar collateral ligament, initial encounter',
  'S5332XD Traumatic rupture of left ulnar collateral ligament, subsequent encounter',
  'S5332XS Traumatic rupture of left ulnar collateral ligament, sequela',
  'S53401A Unspecified sprain of right elbow, initial encounter',
  'S53401D Unspecified sprain of right elbow, subsequent encounter',
  'S53401S Unspecified sprain of right elbow, sequela',
  'S53402A Unspecified sprain of left elbow, initial encounter',
  'S53402D Unspecified sprain of left elbow, subsequent encounter',
  'S53402S Unspecified sprain of left elbow, sequela',
  'S53409A Unspecified sprain of unspecified elbow, initial encounter',
  'S53409D Unspecified sprain of unspecified elbow, subsequent encounter',
  'S53409S Unspecified sprain of unspecified elbow, sequela',
  'S53411A Radiohumeral (joint) sprain of right elbow, initial encounter',
  'S53411D Radiohumeral (joint) sprain of right elbow, subsequent encounter',
  'S53411S Radiohumeral (joint) sprain of right elbow, sequela',
  'S53412A Radiohumeral (joint) sprain of left elbow, initial encounter',
  'S53412D Radiohumeral (joint) sprain of left elbow, subsequent encounter',
  'S53412S Radiohumeral (joint) sprain of left elbow, sequela',
  'S53419A Radiohumeral (joint) sprain of unspecified elbow, initial encounter',
  'S53419D Radiohumeral (joint) sprain of unspecified elbow, subsequent encounter',
  'S53419S Radiohumeral (joint) sprain of unspecified elbow, sequela',
  'S53421A Ulnohumeral (joint) sprain of right elbow, initial encounter',
  'S53421D Ulnohumeral (joint) sprain of right elbow, subsequent encounter',
  'S53421S Ulnohumeral (joint) sprain of right elbow, sequela',
  'S53422A Ulnohumeral (joint) sprain of left elbow, initial encounter',
  'S53422D Ulnohumeral (joint) sprain of left elbow, subsequent encounter',
  'S53422S Ulnohumeral (joint) sprain of left elbow, sequela',
  'S53429A Ulnohumeral (joint) sprain of unspecified elbow, initial encounter',
  'S53429D Ulnohumeral (joint) sprain of unspecified elbow, subsequent encounter',
  'S53429S Ulnohumeral (joint) sprain of unspecified elbow, sequela',
  'S53431A Radial collateral ligament sprain of right elbow, initial encounter',
  'S53431D Radial collateral ligament sprain of right elbow, subsequent encounter',
  'S53431S Radial collateral ligament sprain of right elbow, sequela',
  'S53432A Radial collateral ligament sprain of left elbow, initial encounter',
  'S53432D Radial collateral ligament sprain of left elbow, subsequent encounter',
  'S53432S Radial collateral ligament sprain of left elbow, sequela',
  'S53439A Radial collateral ligament sprain of unspecified elbow, initial encounter',
  'S53439D Radial collateral ligament sprain of unspecified elbow, subsequent encounter',
  'S53439S Radial collateral ligament sprain of unspecified elbow, sequela',
  'S53441A Ulnar collateral ligament sprain of right elbow, initial encounter',
  'S53441D Ulnar collateral ligament sprain of right elbow, subsequent encounter',
  'S53441S Ulnar collateral ligament sprain of right elbow, sequela',
  'S53442A Ulnar collateral ligament sprain of left elbow, initial encounter',
  'S53442D Ulnar collateral ligament sprain of left elbow, subsequent encounter',
  'S53442S Ulnar collateral ligament sprain of left elbow, sequela',
  'S53449A Ulnar collateral ligament sprain of unspecified elbow, initial encounter',
  'S53449D Ulnar collateral ligament sprain of unspecified elbow, subsequent encounter',
  'S53449S Ulnar collateral ligament sprain of unspecified elbow, sequela',
  'S53491A Other sprain of right elbow, initial encounter',
  'S53491D Other sprain of right elbow, subsequent encounter',
  'S53491S Other sprain of right elbow, sequela',
  'S53492A Other sprain of left elbow, initial encounter',
  'S53492D Other sprain of left elbow, subsequent encounter',
  'S53492S Other sprain of left elbow, sequela',
  'S53499A Other sprain of unspecified elbow, initial encounter',
  'S53499D Other sprain of unspecified elbow, subsequent encounter',
  'S53499S Other sprain of unspecified elbow, sequela',
  'S5400XA Injury of ulnar nerve at forearm level, unspecified arm, initial encounter',
  'S5400XD Injury of ulnar nerve at forearm level, unspecified arm, subsequent encounter',
  'S5400XS Injury of ulnar nerve at forearm level, unspecified arm, sequela',
  'S5401XA Injury of ulnar nerve at forearm level, right arm, initial encounter',
  'S5401XD Injury of ulnar nerve at forearm level, right arm, subsequent encounter',
  'S5401XS Injury of ulnar nerve at forearm level, right arm, sequela',
  'S5402XA Injury of ulnar nerve at forearm level, left arm, initial encounter',
  'S5402XD Injury of ulnar nerve at forearm level, left arm, subsequent encounter',
  'S5402XS Injury of ulnar nerve at forearm level, left arm, sequela',
  'S5410XA Injury of median nerve at forearm level, unspecified arm, initial encounter',
  'S5410XD Injury of median nerve at forearm level, unspecified arm, subsequent encounter',
  'S5410XS Injury of median nerve at forearm level, unspecified arm, sequela',
  'S5411XA Injury of median nerve at forearm level, right arm, initial encounter',
  'S5411XD Injury of median nerve at forearm level, right arm, subsequent encounter',
  'S5411XS Injury of median nerve at forearm level, right arm, sequela',
  'S5412XA Injury of median nerve at forearm level, left arm, initial encounter',
  'S5412XD Injury of median nerve at forearm level, left arm, subsequent encounter',
  'S5412XS Injury of median nerve at forearm level, left arm, sequela',
  'S5420XA Injury of radial nerve at forearm level, unspecified arm, initial encounter',
  'S5420XD Injury of radial nerve at forearm level, unspecified arm, subsequent encounter',
  'S5420XS Injury of radial nerve at forearm level, unspecified arm, sequela',
  'S5421XA Injury of radial nerve at forearm level, right arm, initial encounter',
  'S5421XD Injury of radial nerve at forearm level, right arm, subsequent encounter',
  'S5421XS Injury of radial nerve at forearm level, right arm, sequela',
  'S5422XA Injury of radial nerve at forearm level, left arm, initial encounter',
  'S5422XD Injury of radial nerve at forearm level, left arm, subsequent encounter',
  'S5422XS Injury of radial nerve at forearm level, left arm, sequela',
  'S5430XA Injury of cutaneous sensory nerve at forearm level, unspecified arm, initial encounter',
  'S5430XD Injury of cutaneous sensory nerve at forearm level, unspecified arm, subsequent encounter',
  'S5430XS Injury of cutaneous sensory nerve at forearm level, unspecified arm, sequela',
  'S5431XA Injury of cutaneous sensory nerve at forearm level, right arm, initial encounter',
  'S5431XD Injury of cutaneous sensory nerve at forearm level, right arm, subsequent encounter',
  'S5431XS Injury of cutaneous sensory nerve at forearm level, right arm, sequela',
  'S5432XA Injury of cutaneous sensory nerve at forearm level, left arm, initial encounter',
  'S5432XD Injury of cutaneous sensory nerve at forearm level, left arm, subsequent encounter',
  'S5432XS Injury of cutaneous sensory nerve at forearm level, left arm, sequela',
  'S548X1A Injury of other nerves at forearm level, right arm, initial encounter',
  'S548X1D Injury of other nerves at forearm level, right arm, subsequent encounter',
  'S548X1S Injury of other nerves at forearm level, right arm, sequela',
  'S548X2A Injury of other nerves at forearm level, left arm, initial encounter',
  'S548X2D Injury of other nerves at forearm level, left arm, subsequent encounter',
  'S548X2S Injury of other nerves at forearm level, left arm, sequela',
  'S548X9A Injury of other nerves at forearm level, unspecified arm, initial encounter',
  'S548X9D Injury of other nerves at forearm level, unspecified arm, subsequent encounter',
  'S548X9S Injury of other nerves at forearm level, unspecified arm, sequela',
  'S5490XA Injury of unspecified nerve at forearm level, unspecified arm, initial encounter',
  'S5490XD Injury of unspecified nerve at forearm level, unspecified arm, subsequent encounter',
  'S5490XS Injury of unspecified nerve at forearm level, unspecified arm, sequela',
  'S5491XA Injury of unspecified nerve at forearm level, right arm, initial encounter',
  'S5491XD Injury of unspecified nerve at forearm level, right arm, subsequent encounter',
  'S5491XS Injury of unspecified nerve at forearm level, right arm, sequela',
  'S5492XA Injury of unspecified nerve at forearm level, left arm, initial encounter',
  'S5492XD Injury of unspecified nerve at forearm level, left arm, subsequent encounter',
  'S5492XS Injury of unspecified nerve at forearm level, left arm, sequela',
  'S55001A Unspecified injury of ulnar artery at forearm level, right arm, initial encounter',
  'S55001D Unspecified injury of ulnar artery at forearm level, right arm, subsequent encounter',
  'S55001S Unspecified injury of ulnar artery at forearm level, right arm, sequela',
  'S55002A Unspecified injury of ulnar artery at forearm level, left arm, initial encounter',
  'S55002D Unspecified injury of ulnar artery at forearm level, left arm, subsequent encounter',
  'S55002S Unspecified injury of ulnar artery at forearm level, left arm, sequela',
  'S55009A Unspecified injury of ulnar artery at forearm level, unspecified arm, initial encounter',
  'S55009D Unspecified injury of ulnar artery at forearm level, unspecified arm, subsequent encounter',
  'S55009S Unspecified injury of ulnar artery at forearm level, unspecified arm, sequela',
  'S55011A Laceration of ulnar artery at forearm level, right arm, initial encounter',
  'S55011D Laceration of ulnar artery at forearm level, right arm, subsequent encounter',
  'S55011S Laceration of ulnar artery at forearm level, right arm, sequela',
  'S55012A Laceration of ulnar artery at forearm level, left arm, initial encounter',
  'S55012D Laceration of ulnar artery at forearm level, left arm, subsequent encounter',
  'S55012S Laceration of ulnar artery at forearm level, left arm, sequela',
  'S55019A Laceration of ulnar artery at forearm level, unspecified arm, initial encounter',
  'S55019D Laceration of ulnar artery at forearm level, unspecified arm, subsequent encounter',
  'S55019S Laceration of ulnar artery at forearm level, unspecified arm, sequela',
  'S55091A Other specified injury of ulnar artery at forearm level, right arm, initial encounter',
  'S55091D Other specified injury of ulnar artery at forearm level, right arm, subsequent encounter',
  'S55091S Other specified injury of ulnar artery at forearm level, right arm, sequela',
  'S55092A Other specified injury of ulnar artery at forearm level, left arm, initial encounter',
  'S55092D Other specified injury of ulnar artery at forearm level, left arm, subsequent encounter',
  'S55092S Other specified injury of ulnar artery at forearm level, left arm, sequela',
  'S55099A Other specified injury of ulnar artery at forearm level, unspecified arm, initial encounter',
  'S55099D Other specified injury of ulnar artery at forearm level, unspecified arm, subsequent encounter',
  'S55099S Other specified injury of ulnar artery at forearm level, unspecified arm, sequela',
  'S55101A Unspecified injury of radial artery at forearm level, right arm, initial encounter',
  'S55101D Unspecified injury of radial artery at forearm level, right arm, subsequent encounter',
  'S55101S Unspecified injury of radial artery at forearm level, right arm, sequela',
  'S55102A Unspecified injury of radial artery at forearm level, left arm, initial encounter',
  'S55102D Unspecified injury of radial artery at forearm level, left arm, subsequent encounter',
  'S55102S Unspecified injury of radial artery at forearm level, left arm, sequela',
  'S55109A Unspecified injury of radial artery at forearm level, unspecified arm, initial encounter',
  'S55109D Unspecified injury of radial artery at forearm level, unspecified arm, subsequent encounter',
  'S55109S Unspecified injury of radial artery at forearm level, unspecified arm, sequela',
  'S55111A Laceration of radial artery at forearm level, right arm, initial encounter',
  'S55111D Laceration of radial artery at forearm level, right arm, subsequent encounter',
  'S55111S Laceration of radial artery at forearm level, right arm, sequela',
  'S55112A Laceration of radial artery at forearm level, left arm, initial encounter',
  'S55112D Laceration of radial artery at forearm level, left arm, subsequent encounter',
  'S55112S Laceration of radial artery at forearm level, left arm, sequela',
  'S55119A Laceration of radial artery at forearm level, unspecified arm, initial encounter',
  'S55119D Laceration of radial artery at forearm level, unspecified arm, subsequent encounter',
  'S55119S Laceration of radial artery at forearm level, unspecified arm, sequela',
  'S55191A Other specified injury of radial artery at forearm level, right arm, initial encounter',
  'S55191D Other specified injury of radial artery at forearm level, right arm, subsequent encounter',
  'S55191S Other specified injury of radial artery at forearm level, right arm, sequela',
  'S55192A Other specified injury of radial artery at forearm level, left arm, initial encounter',
  'S55192D Other specified injury of radial artery at forearm level, left arm, subsequent encounter',
  'S55192S Other specified injury of radial artery at forearm level, left arm, sequela',
  'S55199A Other specified injury of radial artery at forearm level, unspecified arm, initial encounter',
  'S55199D Other specified injury of radial artery at forearm level, unspecified arm, subsequent encounter',
  'S55199S Other specified injury of radial artery at forearm level, unspecified arm, sequela',
  'S55201A Unspecified injury of vein at forearm level, right arm, initial encounter',
  'S55201D Unspecified injury of vein at forearm level, right arm, subsequent encounter',
  'S55201S Unspecified injury of vein at forearm level, right arm, sequela',
  'S55202A Unspecified injury of vein at forearm level, left arm, initial encounter',
  'S55202D Unspecified injury of vein at forearm level, left arm, subsequent encounter',
  'S55202S Unspecified injury of vein at forearm level, left arm, sequela',
  'S55209A Unspecified injury of vein at forearm level, unspecified arm, initial encounter',
  'S55209D Unspecified injury of vein at forearm level, unspecified arm, subsequent encounter',
  'S55209S Unspecified injury of vein at forearm level, unspecified arm, sequela',
  'S55211A Laceration of vein at forearm level, right arm, initial encounter',
  'S55211D Laceration of vein at forearm level, right arm, subsequent encounter',
  'S55211S Laceration of vein at forearm level, right arm, sequela',
  'S55212A Laceration of vein at forearm level, left arm, initial encounter',
  'S55212D Laceration of vein at forearm level, left arm, subsequent encounter',
  'S55212S Laceration of vein at forearm level, left arm, sequela',
  'S55219A Laceration of vein at forearm level, unspecified arm, initial encounter',
  'S55219D Laceration of vein at forearm level, unspecified arm, subsequent encounter',
  'S55219S Laceration of vein at forearm level, unspecified arm, sequela',
  'S55291A Other specified injury of vein at forearm level, right arm, initial encounter',
  'S55291D Other specified injury of vein at forearm level, right arm, subsequent encounter',
  'S55291S Other specified injury of vein at forearm level, right arm, sequela',
  'S55292A Other specified injury of vein at forearm level, left arm, initial encounter',
  'S55292D Other specified injury of vein at forearm level, left arm, subsequent encounter',
  'S55292S Other specified injury of vein at forearm level, left arm, sequela',
  'S55299A Other specified injury of vein at forearm level, unspecified arm, initial encounter',
  'S55299D Other specified injury of vein at forearm level, unspecified arm, subsequent encounter',
  'S55299S Other specified injury of vein at forearm level, unspecified arm, sequela',
  'S55801A Unspecified injury of other blood vessels at forearm level, right arm, initial encounter',
  'S55801D Unspecified injury of other blood vessels at forearm level, right arm, subsequent encounter',
  'S55801S Unspecified injury of other blood vessels at forearm level, right arm, sequela',
  'S55802A Unspecified injury of other blood vessels at forearm level, left arm, initial encounter',
  'S55802D Unspecified injury of other blood vessels at forearm level, left arm, subsequent encounter',
  'S55802S Unspecified injury of other blood vessels at forearm level, left arm, sequela',
  'S55809A Unspecified injury of other blood vessels at forearm level, unspecified arm, initial encounter',
  'S55809D Unspecified injury of other blood vessels at forearm level, unspecified arm, subsequent',
  'encounter',
  'S55809S Unspecified injury of other blood vessels at forearm level, unspecified arm, sequela',
  'S55811A Laceration of other blood vessels at forearm level, right arm, initial encounter',
  'S55811D Laceration of other blood vessels at forearm level, right arm, subsequent encounter',
  'S55811S Laceration of other blood vessels at forearm level, right arm, sequela',
  'S55812A Laceration of other blood vessels at forearm level, left arm, initial encounter',
  'S55812D Laceration of other blood vessels at forearm level, left arm, subsequent encounter',
  'S55812S Laceration of other blood vessels at forearm level, left arm, sequela',
  'S55819A Laceration of other blood vessels at forearm level, unspecified arm, initial encounter',
  'S55819D Laceration of other blood vessels at forearm level, unspecified arm, subsequent encounter',
  'S55819S Laceration of other blood vessels at forearm level, unspecified arm, sequela',
  'S55891A Other specified injury of other blood vessels at forearm level, right arm, initial encounter',
  'S55891D Other specified injury of other blood vessels at forearm level, right arm, subsequent encounter',
  'S55891S Other specified injury of other blood vessels at forearm level, right arm, sequela',
  'S55892A Other specified injury of other blood vessels at forearm level, left arm, initial encounter',
  'S55892D Other specified injury of other blood vessels at forearm level, left arm, subsequent encounter',
  'S55892S Other specified injury of other blood vessels at forearm level, left arm, sequela',
  'S55899A Other specified injury of other blood vessels at forearm level, unspecified arm, initial',
  'encounter',
  'S55899D Other specified injury of other blood vessels at forearm level, unspecified arm, subsequent',
  'encounter',
  'S55899S Other specified injury of other blood vessels at forearm level, unspecified arm, sequela',
  'S55901A Unspecified injury of unspecified blood vessel at forearm level, right arm, initial encounter',
  'S55901D Unspecified injury of unspecified blood vessel at forearm level, right arm, subsequent',
  'encounter',
  'S55901S Unspecified injury of unspecified blood vessel at forearm level, right arm, sequela',
  'S55902A Unspecified injury of unspecified blood vessel at forearm level, left arm, initial encounter',
  'S55902D Unspecified injury of unspecified blood vessel at forearm level, left arm, subsequent encounter',
  'S55902S Unspecified injury of unspecified blood vessel at forearm level, left arm, sequela',
  'S55909A Unspecified injury of unspecified blood vessel at forearm level, unspecified arm, initial',
  'encounter',
  'S55909D Unspecified injury of unspecified blood vessel at forearm level, unspecified arm, subsequent',
  'encounter',
  'S55909S Unspecified injury of unspecified blood vessel at forearm level, unspecified arm, sequela',
  'S55911A Laceration of unspecified blood vessel at forearm level, right arm, initial encounter',
  'S55911D Laceration of unspecified blood vessel at forearm level, right arm, subsequent encounter',
  'S55911S Laceration of unspecified blood vessel at forearm level, right arm, sequela',
  'S55912A Laceration of unspecified blood vessel at forearm level, left arm, initial encounter',
  'S55912D Laceration of unspecified blood vessel at forearm level, left arm, subsequent encounter',
  'S55912S Laceration of unspecified blood vessel at forearm level, left arm, sequela',
  'S55919A Laceration of unspecified blood vessel at forearm level, unspecified arm, initial encounter',
  'S55919D Laceration of unspecified blood vessel at forearm level, unspecified arm, subsequent',
  'encounter',
  'S55919S Laceration of unspecified blood vessel at forearm level, unspecified arm, sequela',
  'S55991A Other specified injury of unspecified blood vessel at forearm level, right arm, initial encounter',
  'S55991D Other specified injury of unspecified blood vessel at forearm level, right arm, subsequent',
  'encounter',
  'S55991S Other specified injury of unspecified blood vessel at forearm level, right arm, sequela',
  'S55992A Other specified injury of unspecified blood vessel at forearm level, left arm, initial encounter',
  'S55992D Other specified injury of unspecified blood vessel at forearm level, left arm, subsequent',
  'encounter',
  'S55992S Other specified injury of unspecified blood vessel at forearm level, left arm, sequela',
  'S55999A Other specified injury of unspecified blood vessel at forearm level, unspecified arm, initial',
  'encounter',
  'S55999D Other specified injury of unspecified blood vessel at forearm level, unspecified arm,',
  'subsequent encounter',
  'S55999S Other specified injury of unspecified blood vessel at forearm level, unspecified arm, sequela',
  'S56001A Unspecified injury of flexor muscle, fascia and tendon of right thumb at forearm level, initial',
  'encounter',
  'S56001D Unspecified injury of flexor muscle, fascia and tendon of right thumb at forearm level,',
  'subsequent encounter',
  'S56001S Unspecified injury of flexor muscle, fascia and tendon of right thumb at forearm level, sequela',
  'S56002A Unspecified injury of flexor muscle, fascia and tendon of left thumb at forearm level, initial',
  'encounter',
  'S56002D Unspecified injury of flexor muscle, fascia and tendon of left thumb at forearm level,',
  'subsequent encounter',
  'S56002S Unspecified injury of flexor muscle, fascia and tendon of left thumb at forearm level, sequela',
  'S56009A Unspecified injury of flexor muscle, fascia and tendon of unspecified thumb at forearm level,',
  'initial encounter',
  'S56009D Unspecified injury of flexor muscle, fascia and tendon of unspecified thumb at forearm level,',
  'subsequent encounter',
  'S56009S Unspecified injury of flexor muscle, fascia and tendon of unspecified thumb at forearm level,',
  'sequela',
  'S56011A Strain of flexor muscle, fascia and tendon of right thumb at forearm level, initial encounter',
  'S56011D Strain of flexor muscle, fascia and tendon of right thumb at forearm level, subsequent',
  'encounter',
  'S56011S Strain of flexor muscle, fascia and tendon of right thumb at forearm level, sequela',
  'S56012A Strain of flexor muscle, fascia and tendon of left thumb at forearm level, initial encounter',
  'S56012D Strain of flexor muscle, fascia and tendon of left thumb at forearm level, subsequent',
  'encounter',
  'S56012S Strain of flexor muscle, fascia and tendon of left thumb at forearm level, sequela',
  'S56019A Strain of flexor muscle, fascia and tendon of unspecified thumb at forearm level, initial',
  'encounter',
  'S56019D Strain of flexor muscle, fascia and tendon of unspecified thumb at forearm level, subsequent',
  'encounter',
  'S56019S Strain of flexor muscle, fascia and tendon of unspecified thumb at forearm level, sequela',
  'S56021A Laceration of flexor muscle, fascia and tendon of right thumb at forearm level, initial encounter',
  'S56021D Laceration of flexor muscle, fascia and tendon of right thumb at forearm level, subsequent',
  'encounter',
  'S56021S Laceration of flexor muscle, fascia and tendon of right thumb at forearm level, sequela',
  'S56022A Laceration of flexor muscle, fascia and tendon of left thumb at forearm level, initial encounter',
  'S56022D Laceration of flexor muscle, fascia and tendon of left thumb at forearm level, subsequent',
  'encounter',
  'S56022S Laceration of flexor muscle, fascia and tendon of left thumb at forearm level, sequela',
  'S56029A Laceration of flexor muscle, fascia and tendon of unspecified thumb at forearm level, initial',
  'encounter',
  'S56029D Laceration of flexor muscle, fascia and tendon of unspecified thumb at forearm level,',
  'subsequent encounter',
  'S56029S Laceration of flexor muscle, fascia and tendon of unspecified thumb at forearm level, sequela',
  'S56091A Other injury of flexor muscle, fascia and tendon of right thumb at forearm level, initial',
  'encounter',
  'S56091D Other injury of flexor muscle, fascia and tendon of right thumb at forearm level, subsequent',
  'encounter',
  'S56091S Other injury of flexor muscle, fascia and tendon of right thumb at forearm level, sequela',
  'S56092A Other injury of flexor muscle, fascia and tendon of left thumb at forearm level, initial',
  'encounter',
  'S56092D Other injury of flexor muscle, fascia and tendon of left thumb at forearm level, subsequent',
  'encounter',
  'S56092S Other injury of flexor muscle, fascia and tendon of left thumb at forearm level, sequela',
  'S56099A Other injury of flexor muscle, fascia and tendon of unspecified thumb at forearm level, initial',
  'encounter',
  'S56099D Other injury of flexor muscle, fascia and tendon of unspecified thumb at forearm level,',
  'subsequent encounter',
  'S56099S Other injury of flexor muscle, fascia and tendon of unspecified thumb at forearm level, sequela',
  'S56101A Unspecified injury of flexor muscle, fascia and tendon of right index finger at forearm level,',
  'initial encounter',
  'S56101D Unspecified injury of flexor muscle, fascia and tendon of right index finger at forearm level,',
  'subsequent encounter',
  'S56101S Unspecified injury of flexor muscle, fascia and tendon of right index finger at forearm level,',
  'sequela',
  'S56102A Unspecified injury of flexor muscle, fascia and tendon of left index finger at forearm level,',
  'initial encounter',
  'S56102D Unspecified injury of flexor muscle, fascia and tendon of left index finger at forearm level,',
  'subsequent encounter',
  'S56102S Unspecified injury of flexor muscle, fascia and tendon of left index finger at forearm level,',
  'sequela',
  'S56103A Unspecified injury of flexor muscle, fascia and tendon of right middle finger at forearm level,',
  'initial encounter',
  'S56103D Unspecified injury of flexor muscle, fascia and tendon of right middle finger at forearm level,',
  'subsequent encounter',
  'S56103S Unspecified injury of flexor muscle, fascia and tendon of right middle finger at forearm level,',
  'sequela',
  'S56104A Unspecified injury of flexor muscle, fascia and tendon of left middle finger at forearm level,',
  'initial encounter',
  'S56104D Unspecified injury of flexor muscle, fascia and tendon of left middle finger at forearm level,',
  'subsequent encounter',
  'S56104S Unspecified injury of flexor muscle, fascia and tendon of left middle finger at forearm level,',
  'sequela',
  'S56105A Unspecified injury of flexor muscle, fascia and tendon of right ring finger at forearm level,',
  'initial encounter',
  'S56105D Unspecified injury of flexor muscle, fascia and tendon of right ring finger at forearm level,',
  'subsequent encounter',
  'S56105S Unspecified injury of flexor muscle, fascia and tendon of right ring finger at forearm level,',
  'sequela',
  'S56106A Unspecified injury of flexor muscle, fascia and tendon of left ring finger at forearm level, initial',
  'encounter',
  'S56106D Unspecified injury of flexor muscle, fascia and tendon of left ring finger at forearm level,',
  'subsequent encounter',
  'S56106S Unspecified injury of flexor muscle, fascia and tendon of left ring finger at forearm level,',
  'sequela',
  'S56107A Unspecified injury of flexor muscle, fascia and tendon of right little finger at forearm level,',
  'initial encounter',
  'S56107D Unspecified injury of flexor muscle, fascia and tendon of right little finger at forearm level,',
  'subsequent encounter',
  'S56107S Unspecified injury of flexor muscle, fascia and tendon of right little finger at forearm level,',
  'sequela',
  'S56108A Unspecified injury of flexor muscle, fascia and tendon of left little finger at forearm level, initial',
  'encounter',
  'S56108D Unspecified injury of flexor muscle, fascia and tendon of left little finger at forearm level,',
  'subsequent encounter',
  'S56108S Unspecified injury of flexor muscle, fascia and tendon of left little finger at forearm level,',
  'sequela',
  'S56109A Unspecified injury of flexor muscle, fascia and tendon of unspecified finger at forearm level,',
  'initial encounter',
  'S56109D Unspecified injury of flexor muscle, fascia and tendon of unspecified finger at forearm level,',
  'subsequent encounter',
  'S56109S Unspecified injury of flexor muscle, fascia and tendon of unspecified finger at forearm level,',
  'sequela',
  'S56111A Strain of flexor muscle, fascia and tendon of right index finger at forearm level, initial',
  'encounter',
  'S56111D Strain of flexor muscle, fascia and tendon of right index finger at forearm level, subsequent',
  'encounter',
  'S56111S Strain of flexor muscle, fascia and tendon of right index finger at forearm level, sequela',
  'S56112A Strain of flexor muscle, fascia and tendon of left index finger at forearm level, initial encounter',
  'S56112D Strain of flexor muscle, fascia and tendon of left index finger at forearm level, subsequent',
  'encounter',
  'S56112S Strain of flexor muscle, fascia and tendon of left index finger at forearm level, sequela',
  'S56113A Strain of flexor muscle, fascia and tendon of right middle finger at forearm level, initial',
  'encounter',
  'S56113D Strain of flexor muscle, fascia and tendon of right middle finger at forearm level, subsequent',
  'encounter',
  'S56113S Strain of flexor muscle, fascia and tendon of right middle finger at forearm level, sequela',
  'S56114A Strain of flexor muscle, fascia and tendon of left middle finger at forearm level, initial',
  'encounter',
  'S56114D Strain of flexor muscle, fascia and tendon of left middle finger at forearm level, subsequent',
  'encounter',
  'S56114S Strain of flexor muscle, fascia and tendon of left middle finger at forearm level, sequela',
  'S56115A Strain of flexor muscle, fascia and tendon of right ring finger at forearm level, initial encounter',
  'S56115D Strain of flexor muscle, fascia and tendon of right ring finger at forearm level, subsequent',
  'encounter',
  'S56115S Strain of flexor muscle, fascia and tendon of right ring finger at forearm level, sequela',
  'S56116A Strain of flexor muscle, fascia and tendon of left ring finger at forearm level, initial encounter',
  'S56116D Strain of flexor muscle, fascia and tendon of left ring finger at forearm level, subsequent',
  'encounter',
  'S56116S Strain of flexor muscle, fascia and tendon of left ring finger at forearm level, sequela',
  'S56117A Strain of flexor muscle, fascia and tendon of right little finger at forearm level, initial encounter',
  'S56117D Strain of flexor muscle, fascia and tendon of right little finger at forearm level, subsequent',
  'encounter',
  'S56117S Strain of flexor muscle, fascia and tendon of right little finger at forearm level, sequela',
  'S56118A Strain of flexor muscle, fascia and tendon of left little finger at forearm level, initial encounter',
  'S56118D Strain of flexor muscle, fascia and tendon of left little finger at forearm level, subsequent',
  'encounter',
  'S56118S Strain of flexor muscle, fascia and tendon of left little finger at forearm level, sequela',
  'S56119A Strain of flexor muscle, fascia and tendon of finger of unspecified finger at forearm level, initial',
  'encounter',
  'S56119D Strain of flexor muscle, fascia and tendon of finger of unspecified finger at forearm level,',
  'subsequent encounter',
  'S56119S Strain of flexor muscle, fascia and tendon of finger of unspecified finger at forearm level,',
  'sequela',
  'S56121A Laceration of flexor muscle, fascia and tendon of right index finger at forearm level, initial',
  'encounter',
  'S56121D Laceration of flexor muscle, fascia and tendon of right index finger at forearm level,',
  'subsequent encounter',
  'S56121S Laceration of flexor muscle, fascia and tendon of right index finger at forearm level, sequela',
  'S56122A Laceration of flexor muscle, fascia and tendon of left index finger at forearm level, initial',
  'encounter',
  'S56122D Laceration of flexor muscle, fascia and tendon of left index finger at forearm level, subsequent',
  'encounter',
  'S56122S Laceration of flexor muscle, fascia and tendon of left index finger at forearm level, sequela',
  'S56123A Laceration of flexor muscle, fascia and tendon of right middle finger at forearm level, initial',
  'encounter',
  'S56123D Laceration of flexor muscle, fascia and tendon of right middle finger at forearm level,',
  'subsequent encounter',
  'S56123S Laceration of flexor muscle, fascia and tendon of right middle finger at forearm level, sequela',
  'S56124A Laceration of flexor muscle, fascia and tendon of left middle finger at forearm level, initial',
  'encounter',
  'S56124D Laceration of flexor muscle, fascia and tendon of left middle finger at forearm level,',
  'subsequent encounter',
  'S56124S Laceration of flexor muscle, fascia and tendon of left middle finger at forearm level, sequela',
  'S56125A Laceration of flexor muscle, fascia and tendon of right ring finger at forearm level, initial',
  'encounter',
  'S56125D Laceration of flexor muscle, fascia and tendon of right ring finger at forearm level, subsequent',
  'encounter',
  'S56125S Laceration of flexor muscle, fascia and tendon of right ring finger at forearm level, sequela',
  'S56126A Laceration of flexor muscle, fascia and tendon of left ring finger at forearm level, initial',
  'encounter',
  'S56126D Laceration of flexor muscle, fascia and tendon of left ring finger at forearm level, subsequent',
  'encounter',
  'S56126S Laceration of flexor muscle, fascia and tendon of left ring finger at forearm level, sequela',
  'S56127A Laceration of flexor muscle, fascia and tendon of right little finger at forearm level, initial',
  'encounter',
  'S56127D Laceration of flexor muscle, fascia and tendon of right little finger at forearm level, subsequent',
  'encounter',
  'S56127S Laceration of flexor muscle, fascia and tendon of right little finger at forearm level, sequela',
  'S56128A Laceration of flexor muscle, fascia and tendon of left little finger at forearm level, initial',
  'encounter',
  'S56128D Laceration of flexor muscle, fascia and tendon of left little finger at forearm level, subsequent',
  'encounter',
  'S56128S Laceration of flexor muscle, fascia and tendon of left little finger at forearm level, sequela',
  'S56129A Laceration of flexor muscle, fascia and tendon of unspecified finger at forearm level, initial',
  'encounter',
  'S56129D Laceration of flexor muscle, fascia and tendon of unspecified finger at forearm level,',
  'subsequent encounter',
  'S56129S Laceration of flexor muscle, fascia and tendon of unspecified finger at forearm level, sequela',
  'S56191A Other injury of flexor muscle, fascia and tendon of right index finger at forearm level, initial',
  'encounter',
  'S56191D Other injury of flexor muscle, fascia and tendon of right index finger at forearm level,',
  'subsequent encounter',
  'S56191S Other injury of flexor muscle, fascia and tendon of right index finger at forearm level, sequela',
  'S56192A Other injury of flexor muscle, fascia and tendon of left index finger at forearm level, initial',
  'encounter',
  'S56192D Other injury of flexor muscle, fascia and tendon of left index finger at forearm level,',
  'subsequent encounter',
  'S56192S Other injury of flexor muscle, fascia and tendon of left index finger at forearm level, sequela',
  'S56193A Other injury of flexor muscle, fascia and tendon of right middle finger at forearm level, initial',
  'encounter',
  'S56193D Other injury of flexor muscle, fascia and tendon of right middle finger at forearm level,',
  'subsequent encounter',
  'S56193S Other injury of flexor muscle, fascia and tendon of right middle finger at forearm level, sequela',
  'S56194A Other injury of flexor muscle, fascia and tendon of left middle finger at forearm level, initial',
  'encounter',
  'S56194D Other injury of flexor muscle, fascia and tendon of left middle finger at forearm level,',
  'subsequent encounter',
  'S56194S Other injury of flexor muscle, fascia and tendon of left middle finger at forearm level, sequela',
  'S56195A Other injury of flexor muscle, fascia and tendon of right ring finger at forearm level, initial',
  'encounter',
  'S56195D Other injury of flexor muscle, fascia and tendon of right ring finger at forearm level,',
  'subsequent encounter',
  'S56195S Other injury of flexor muscle, fascia and tendon of right ring finger at forearm level, sequela',
  'S56196A Other injury of flexor muscle, fascia and tendon of left ring finger at forearm level, initial',
  'encounter',
  'S56196D Other injury of flexor muscle, fascia and tendon of left ring finger at forearm level, subsequent',
  'encounter',
  'S56196S Other injury of flexor muscle, fascia and tendon of left ring finger at forearm level, sequela',
  'S56197A Other injury of flexor muscle, fascia and tendon of right little finger at forearm level, initial',
  'encounter',
  'S56197D Other injury of flexor muscle, fascia and tendon of right little finger at forearm level,',
  'subsequent encounter',
  'S56197S Other injury of flexor muscle, fascia and tendon of right little finger at forearm level, sequela',
  'S56198A Other injury of flexor muscle, fascia and tendon of left little finger at forearm level, initial',
  'encounter',
  'S56198D Other injury of flexor muscle, fascia and tendon of left little finger at forearm level, subsequent',
  'encounter',
  'S56198S Other injury of flexor muscle, fascia and tendon of left little finger at forearm level, sequela',
  'S56199A Other injury of flexor muscle, fascia and tendon of unspecified finger at forearm level, initial',
  'encounter',
  'S56199D Other injury of flexor muscle, fascia and tendon of unspecified finger at forearm level,',
  'subsequent encounter',
  'S56199S Other injury of flexor muscle, fascia and tendon of unspecified finger at forearm level, sequela',
  'S56201A Unspecified injury of other flexor muscle, fascia and tendon at forearm level, right arm, initial',
  'encounter',
  'S56201D Unspecified injury of other flexor muscle, fascia and tendon at forearm level, right arm,',
  'subsequent encounter',
  'S56201S Unspecified injury of other flexor muscle, fascia and tendon at forearm level, right arm, sequela',
  'S56202A Unspecified injury of other flexor muscle, fascia and tendon at forearm level, left arm, initial',
  'encounter',
  'S56202D Unspecified injury of other flexor muscle, fascia and tendon at forearm level, left arm,',
  'subsequent encounter',
  'S56202S Unspecified injury of other flexor muscle, fascia and tendon at forearm level, left arm, sequela',
  'S56209A Unspecified injury of other flexor muscle, fascia and tendon at forearm level, unspecified arm,',
  'initial encounter',
  'S56209D Unspecified injury of other flexor muscle, fascia and tendon at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56209S Unspecified injury of other flexor muscle, fascia and tendon at forearm level, unspecified arm,',
  'sequela',
  'S56211A Strain of other flexor muscle, fascia and tendon at forearm level, right arm, initial encounter',
  'S56211D Strain of other flexor muscle, fascia and tendon at forearm level, right arm, subsequent',
  'encounter',
  'S56211S Strain of other flexor muscle, fascia and tendon at forearm level, right arm, sequela',
  'S56212A Strain of other flexor muscle, fascia and tendon at forearm level, left arm, initial encounter',
  'S56212D Strain of other flexor muscle, fascia and tendon at forearm level, left arm, subsequent',
  'encounter',
  'S56212S Strain of other flexor muscle, fascia and tendon at forearm level, left arm, sequela',
  'S56219A Strain of other flexor muscle, fascia and tendon at forearm level, unspecified arm, initial',
  'encounter',
  'S56219D Strain of other flexor muscle, fascia and tendon at forearm level, unspecified arm, subsequent',
  'encounter',
  'S56219S Strain of other flexor muscle, fascia and tendon at forearm level, unspecified arm, sequela',
  'S56221A Laceration of other flexor muscle, fascia and tendon at forearm level, right arm, initial',
  'encounter',
  'S56221D Laceration of other flexor muscle, fascia and tendon at forearm level, right arm, subsequent',
  'encounter',
  'S56221S Laceration of other flexor muscle, fascia and tendon at forearm level, right arm, sequela',
  'S56222A Laceration of other flexor muscle, fascia and tendon at forearm level, left arm, initial encounter',
  'S56222D Laceration of other flexor muscle, fascia and tendon at forearm level, left arm, subsequent',
  'encounter',
  'S56222S Laceration of other flexor muscle, fascia and tendon at forearm level, left arm, sequela',
  'S56229A Laceration of other flexor muscle, fascia and tendon at forearm level, unspecified arm, initial',
  'encounter',
  'S56229D Laceration of other flexor muscle, fascia and tendon at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56229S Laceration of other flexor muscle, fascia and tendon at forearm level, unspecified arm, sequela',
  'S56291A Other injury of other flexor muscle, fascia and tendon at forearm level, right arm, initial',
  'encounter',
  'S56291D Other injury of other flexor muscle, fascia and tendon at forearm level, right arm, subsequent',
  'encounter',
  'S56291S Other injury of other flexor muscle, fascia and tendon at forearm level, right arm, sequela',
  'S56292A Other injury of other flexor muscle, fascia and tendon at forearm level, left arm, initial',
  'encounter',
  'S56292D Other injury of other flexor muscle, fascia and tendon at forearm level, left arm, subsequent',
  'encounter',
  'S56292S Other injury of other flexor muscle, fascia and tendon at forearm level, left arm, sequela',
  'S56299A Other injury of other flexor muscle, fascia and tendon at forearm level, unspecified arm, initial',
  'encounter',
  'S56299D Other injury of other flexor muscle, fascia and tendon at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56299S Other injury of other flexor muscle, fascia and tendon at forearm level, unspecified arm,',
  'sequela',
  'S56301A Unspecified injury of extensor or abductor muscles, fascia and tendons of right thumb at',
  'forearm level, initial encounter',
  'S56301D Unspecified injury of extensor or abductor muscles, fascia and tendons of right thumb at',
  'forearm level, subsequent encounter',
  'S56301S Unspecified injury of extensor or abductor muscles, fascia and tendons of right thumb at',
  'forearm level, sequela',
  'S56302A Unspecified injury of extensor or abductor muscles, fascia and tendons of left thumb at',
  'forearm level, initial encounter',
  'S56302D Unspecified injury of extensor or abductor muscles, fascia and tendons of left thumb at',
  'forearm level, subsequent encounter',
  'S56302S Unspecified injury of extensor or abductor muscles, fascia and tendons of left thumb at',
  'forearm level, sequela',
  'S56309A Unspecified injury of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, initial encounter',
  'S56309D Unspecified injury of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, subsequent encounter',
  'S56309S Unspecified injury of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, sequela',
  'S56311A Strain of extensor or abductor muscles, fascia and tendons of right thumb at forearm level,',
  'initial encounter',
  'S56311D Strain of extensor or abductor muscles, fascia and tendons of right thumb at forearm level,',
  'subsequent encounter',
  'S56311S Strain of extensor or abductor muscles, fascia and tendons of right thumb at forearm level,',
  'sequela',
  'S56312A Strain of extensor or abductor muscles, fascia and tendons of left thumb at forearm level,',
  'initial encounter',
  'S56312D Strain of extensor or abductor muscles, fascia and tendons of left thumb at forearm level,',
  'subsequent encounter',
  'S56312S Strain of extensor or abductor muscles, fascia and tendons of left thumb at forearm level,',
  'sequela',
  'S56319A Strain of extensor or abductor muscles, fascia and tendons of unspecified thumb at forearm',
  'level, initial encounter',
  'S56319D Strain of extensor or abductor muscles, fascia and tendons of unspecified thumb at forearm',
  'level, subsequent encounter',
  'S56319S Strain of extensor or abductor muscles, fascia and tendons of unspecified thumb at forearm',
  'level, sequela',
  'S56321A Laceration of extensor or abductor muscles, fascia and tendons of right thumb at forearm',
  'level, initial encounter',
  'S56321D Laceration of extensor or abductor muscles, fascia and tendons of right thumb at forearm',
  'level, subsequent encounter',
  'S56321S Laceration of extensor or abductor muscles, fascia and tendons of right thumb at forearm level,',
  'sequela',
  'S56322A Laceration of extensor or abductor muscles, fascia and tendons of left thumb at forearm level,',
  'initial encounter',
  'S56322D Laceration of extensor or abductor muscles, fascia and tendons of left thumb at forearm level,',
  'subsequent encounter',
  'S56322S Laceration of extensor or abductor muscles, fascia and tendons of left thumb at forearm level,',
  'sequela',
  'S56329A Laceration of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, initial encounter',
  'S56329D Laceration of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, subsequent encounter',
  'S56329S Laceration of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, sequela',
  'S56391A Other injury of extensor or abductor muscles, fascia and tendons of right thumb at forearm',
  'level, initial encounter',
  'S56391D Other injury of extensor or abductor muscles, fascia and tendons of right thumb at forearm',
  'level, subsequent encounter',
  'S56391S Other injury of extensor or abductor muscles, fascia and tendons of right thumb at forearm',
  'level, sequela',
  'S56392A Other injury of extensor or abductor muscles, fascia and tendons of left thumb at forearm',
  'level, initial encounter',
  'S56392D Other injury of extensor or abductor muscles, fascia and tendons of left thumb at forearm',
  'level, subsequent encounter',
  'S56392S Other injury of extensor or abductor muscles, fascia and tendons of left thumb at forearm',
  'level, sequela',
  'S56399A Other injury of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, initial encounter',
  'S56399D Other injury of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, subsequent encounter',
  'S56399S Other injury of extensor or abductor muscles, fascia and tendons of unspecified thumb at',
  'forearm level, sequela',
  'S56401A Unspecified injury of extensor muscle, fascia and tendon of right index finger at forearm level,',
  'initial encounter',
  'S56401D Unspecified injury of extensor muscle, fascia and tendon of right index finger at forearm level,',
  'subsequent encounter',
  'S56401S Unspecified injury of extensor muscle, fascia and tendon of right index finger at forearm level,',
  'sequela',
  'S56402A Unspecified injury of extensor muscle, fascia and tendon of left index finger at forearm level,',
  'initial encounter',
  'S56402D Unspecified injury of extensor muscle, fascia and tendon of left index finger at forearm level,',
  'subsequent encounter',
  'S56402S Unspecified injury of extensor muscle, fascia and tendon of left index finger at forearm level,',
  'sequela',
  'S56403A Unspecified injury of extensor muscle, fascia and tendon of right middle finger at forearm',
  'level, initial encounter',
  'S56403D Unspecified injury of extensor muscle, fascia and tendon of right middle finger at forearm',
  'level, subsequent encounter',
  'S56403S Unspecified injury of extensor muscle, fascia and tendon of right middle finger at forearm level,',
  'sequela',
  'S56404A Unspecified injury of extensor muscle, fascia and tendon of left middle finger at forearm level,',
  'initial encounter',
  'S56404D Unspecified injury of extensor muscle, fascia and tendon of left middle finger at forearm level,',
  'subsequent encounter',
  'S56404S Unspecified injury of extensor muscle, fascia and tendon of left middle finger at forearm level,',
  'sequela',
  'S56405A Unspecified injury of extensor muscle, fascia and tendon of right ring finger at forearm level,',
  'initial encounter',
  'S56405D Unspecified injury of extensor muscle, fascia and tendon of right ring finger at forearm level,',
  'subsequent encounter',
  'S56405S Unspecified injury of extensor muscle, fascia and tendon of right ring finger at forearm level,',
  'sequela',
  'S56406A Unspecified injury of extensor muscle, fascia and tendon of left ring finger at forearm level,',
  'initial encounter',
  'S56406D Unspecified injury of extensor muscle, fascia and tendon of left ring finger at forearm level,',
  'subsequent encounter',
  'S56406S Unspecified injury of extensor muscle, fascia and tendon of left ring finger at forearm level,',
  'sequela',
  'S56407A Unspecified injury of extensor muscle, fascia and tendon of right little finger at forearm level,',
  'initial encounter',
  'S56407D Unspecified injury of extensor muscle, fascia and tendon of right little finger at forearm level,',
  'subsequent encounter',
  'S56407S Unspecified injury of extensor muscle, fascia and tendon of right little finger at forearm level,',
  'sequela',
  'S56408A Unspecified injury of extensor muscle, fascia and tendon of left little finger at forearm level,',
  'initial encounter',
  'S56408D Unspecified injury of extensor muscle, fascia and tendon of left little finger at forearm level,',
  'subsequent encounter',
  'S56408S Unspecified injury of extensor muscle, fascia and tendon of left little finger at forearm level,',
  'sequela',
  'S56409A Unspecified injury of extensor muscle, fascia and tendon of unspecified finger at forearm level,',
  'initial encounter',
  'S56409D Unspecified injury of extensor muscle, fascia and tendon of unspecified finger at forearm level,',
  'subsequent encounter',
  'S56409S Unspecified injury of extensor muscle, fascia and tendon of unspecified finger at forearm level,',
  'sequela',
  'S56411A Strain of extensor muscle, fascia and tendon of right index finger at forearm level, initial',
  'encounter',
  'S56411D Strain of extensor muscle, fascia and tendon of right index finger at forearm level, subsequent',
  'encounter',
  'S56411S Strain of extensor muscle, fascia and tendon of right index finger at forearm level, sequela',
  'S56412A Strain of extensor muscle, fascia and tendon of left index finger at forearm level, initial',
  'encounter',
  'S56412D Strain of extensor muscle, fascia and tendon of left index finger at forearm level, subsequent',
  'encounter',
  'S56412S Strain of extensor muscle, fascia and tendon of left index finger at forearm level, sequela',
  'S56413A Strain of extensor muscle, fascia and tendon of right middle finger at forearm level, initial',
  'encounter',
  'S56413D Strain of extensor muscle, fascia and tendon of right middle finger at forearm level,',
  'subsequent encounter',
  'S56413S Strain of extensor muscle, fascia and tendon of right middle finger at forearm level, sequela',
  'S56414A Strain of extensor muscle, fascia and tendon of left middle finger at forearm level, initial',
  'encounter',
  'S56414D Strain of extensor muscle, fascia and tendon of left middle finger at forearm level, subsequent',
  'encounter',
  'S56414S Strain of extensor muscle, fascia and tendon of left middle finger at forearm level, sequela',
  'S56415A Strain of extensor muscle, fascia and tendon of right ring finger at forearm level, initial',
  'encounter',
  'S56415D Strain of extensor muscle, fascia and tendon of right ring finger at forearm level, subsequent',
  'encounter',
  'S56415S Strain of extensor muscle, fascia and tendon of right ring finger at forearm level, sequela',
  'S56416A Strain of extensor muscle, fascia and tendon of left ring finger at forearm level, initial',
  'encounter',
  'S56416D Strain of extensor muscle, fascia and tendon of left ring finger at forearm level, subsequent',
  'encounter',
  'S56416S Strain of extensor muscle, fascia and tendon of left ring finger at forearm level, sequela',
  'S56417A Strain of extensor muscle, fascia and tendon of right little finger at forearm level, initial',
  'encounter',
  'S56417D Strain of extensor muscle, fascia and tendon of right little finger at forearm level, subsequent',
  'encounter',
  'S56417S Strain of extensor muscle, fascia and tendon of right little finger at forearm level, sequela',
  'S56418A Strain of extensor muscle, fascia and tendon of left little finger at forearm level, initial',
  'encounter',
  'S56418D Strain of extensor muscle, fascia and tendon of left little finger at forearm level, subsequent',
  'encounter',
  'S56418S Strain of extensor muscle, fascia and tendon of left little finger at forearm level, sequela',
  'S56419A Strain of extensor muscle, fascia and tendon of finger, unspecified finger at forearm level,',
  'initial encounter',
  'S56419D Strain of extensor muscle, fascia and tendon of finger, unspecified finger at forearm level,',
  'subsequent encounter',
  'S56419S Strain of extensor muscle, fascia and tendon of finger, unspecified finger at forearm level,',
  'sequela',
  'S56421A Laceration of extensor muscle, fascia and tendon of right index finger at forearm level, initial',
  'encounter',
  'S56421D Laceration of extensor muscle, fascia and tendon of right index finger at forearm level,',
  'subsequent encounter',
  'S56421S Laceration of extensor muscle, fascia and tendon of right index finger at forearm level, sequela',
  'S56422A Laceration of extensor muscle, fascia and tendon of left index finger at forearm level, initial',
  'encounter',
  'S56422D Laceration of extensor muscle, fascia and tendon of left index finger at forearm level,',
  'subsequent encounter',
  'S56422S Laceration of extensor muscle, fascia and tendon of left index finger at forearm level, sequela',
  'S56423A Laceration of extensor muscle, fascia and tendon of right middle finger at forearm level, initial',
  'encounter',
  'S56423D Laceration of extensor muscle, fascia and tendon of right middle finger at forearm level,',
  'subsequent encounter',
  'S56423S Laceration of extensor muscle, fascia and tendon of right middle finger at forearm level,',
  'sequela',
  'S56424A Laceration of extensor muscle, fascia and tendon of left middle finger at forearm level, initial',
  'encounter',
  'S56424D Laceration of extensor muscle, fascia and tendon of left middle finger at forearm level,',
  'subsequent encounter',
  'S56424S Laceration of extensor muscle, fascia and tendon of left middle finger at forearm level, sequela',
  'S56425A Laceration of extensor muscle, fascia and tendon of right ring finger at forearm level, initial',
  'encounter',
  'S56425D Laceration of extensor muscle, fascia and tendon of right ring finger at forearm level,',
  'subsequent encounter',
  'S56425S Laceration of extensor muscle, fascia and tendon of right ring finger at forearm level, sequela',
  'S56426A Laceration of extensor muscle, fascia and tendon of left ring finger at forearm level, initial',
  'encounter',
  'S56426D Laceration of extensor muscle, fascia and tendon of left ring finger at forearm level,',
  'subsequent encounter',
  'S56426S Laceration of extensor muscle, fascia and tendon of left ring finger at forearm level, sequela',
  'S56427A Laceration of extensor muscle, fascia and tendon of right little finger at forearm level, initial',
  'encounter',
  'S56427D Laceration of extensor muscle, fascia and tendon of right little finger at forearm level,',
  'subsequent encounter',
  'S56427S Laceration of extensor muscle, fascia and tendon of right little finger at forearm level, sequela',
  'S56428A Laceration of extensor muscle, fascia and tendon of left little finger at forearm level, initial',
  'encounter',
  'S56428D Laceration of extensor muscle, fascia and tendon of left little finger at forearm level,',
  'subsequent encounter',
  'S56428S Laceration of extensor muscle, fascia and tendon of left little finger at forearm level, sequela',
  'S56429A Laceration of extensor muscle, fascia and tendon of unspecified finger at forearm level, initial',
  'encounter',
  'S56429D Laceration of extensor muscle, fascia and tendon of unspecified finger at forearm level,',
  'subsequent encounter',
  'S56429S Laceration of extensor muscle, fascia and tendon of unspecified finger at forearm level, sequela',
  'S56491A Other injury of extensor muscle, fascia and tendon of right index finger at forearm level, initial',
  'encounter',
  'S56491D Other injury of extensor muscle, fascia and tendon of right index finger at forearm level,',
  'subsequent encounter',
  'S56491S Other injury of extensor muscle, fascia and tendon of right index finger at forearm level,',
  'sequela',
  'S56492A Other injury of extensor muscle, fascia and tendon of left index finger at forearm level, initial',
  'encounter',
  'S56492D Other injury of extensor muscle, fascia and tendon of left index finger at forearm level,',
  'subsequent encounter',
  'S56492S Other injury of extensor muscle, fascia and tendon of left index finger at forearm level, sequela',
  'S56493A Other injury of extensor muscle, fascia and tendon of right middle finger at forearm level,',
  'initial encounter',
  'S56493D Other injury of extensor muscle, fascia and tendon of right middle finger at forearm level,',
  'subsequent encounter',
  'S56493S Other injury of extensor muscle, fascia and tendon of right middle finger at forearm level,',
  'sequela',
  'S56494A Other injury of extensor muscle, fascia and tendon of left middle finger at forearm level, initial',
  'encounter',
  'S56494D Other injury of extensor muscle, fascia and tendon of left middle finger at forearm level,',
  'subsequent encounter',
  'S56494S Other injury of extensor muscle, fascia and tendon of left middle finger at forearm level,',
  'sequela',
  'S56495A Other injury of extensor muscle, fascia and tendon of right ring finger at forearm level, initial',
  'encounter',
  'S56495D Other injury of extensor muscle, fascia and tendon of right ring finger at forearm level,',
  'subsequent encounter',
  'S56495S Other injury of extensor muscle, fascia and tendon of right ring finger at forearm level, sequela',
  'S56496A Other injury of extensor muscle, fascia and tendon of left ring finger at forearm level, initial',
  'encounter',
  'S56496D Other injury of extensor muscle, fascia and tendon of left ring finger at forearm level,',
  'subsequent encounter',
  'S56496S Other injury of extensor muscle, fascia and tendon of left ring finger at forearm level, sequela',
  'S56497A Other injury of extensor muscle, fascia and tendon of right little finger at forearm level, initial',
  'encounter',
  'S56497D Other injury of extensor muscle, fascia and tendon of right little finger at forearm level,',
  'subsequent encounter',
  'S56497S Other injury of extensor muscle, fascia and tendon of right little finger at forearm level, sequela',
  'S56498A Other injury of extensor muscle, fascia and tendon of left little finger at forearm level, initial',
  'encounter',
  'S56498D Other injury of extensor muscle, fascia and tendon of left little finger at forearm level,',
  'subsequent encounter',
  'S56498S Other injury of extensor muscle, fascia and tendon of left little finger at forearm level, sequela',
  'S56499A Other injury of extensor muscle, fascia and tendon of unspecified finger at forearm level, initial',
  'encounter',
  'S56499D Other injury of extensor muscle, fascia and tendon of unspecified finger at forearm level,',
  'subsequent encounter',
  'S56499S Other injury of extensor muscle, fascia and tendon of unspecified finger at forearm level,',
  'sequela',
  'S56501A Unspecified injury of other extensor muscle, fascia and tendon at forearm level, right arm,',
  'initial encounter',
  'S56501D Unspecified injury of other extensor muscle, fascia and tendon at forearm level, right arm,',
  'subsequent encounter',
  'S56501S Unspecified injury of other extensor muscle, fascia and tendon at forearm level, right arm,',
  'sequela',
  'S56502A Unspecified injury of other extensor muscle, fascia and tendon at forearm level, left arm, initial',
  'encounter',
  'S56502D Unspecified injury of other extensor muscle, fascia and tendon at forearm level, left arm,',
  'subsequent encounter',
  'S56502S Unspecified injury of other extensor muscle, fascia and tendon at forearm level, left arm,',
  'sequela',
  'S56509A Unspecified injury of other extensor muscle, fascia and tendon at forearm level, unspecified',
  'arm, initial encounter',
  'S56509D Unspecified injury of other extensor muscle, fascia and tendon at forearm level, unspecified',
  'arm, subsequent encounter',
  'S56509S Unspecified injury of other extensor muscle, fascia and tendon at forearm level, unspecified',
  'arm, sequela',
  'S56511A Strain of other extensor muscle, fascia and tendon at forearm level, right arm, initial encounter',
  'S56511D Strain of other extensor muscle, fascia and tendon at forearm level, right arm, subsequent',
  'encounter',
  'S56511S Strain of other extensor muscle, fascia and tendon at forearm level, right arm, sequela',
  'S56512A Strain of other extensor muscle, fascia and tendon at forearm level, left arm, initial encounter',
  'S56512D Strain of other extensor muscle, fascia and tendon at forearm level, left arm, subsequent',
  'encounter',
  'S56512S Strain of other extensor muscle, fascia and tendon at forearm level, left arm, sequela',
  'S56519A Strain of other extensor muscle, fascia and tendon at forearm level, unspecified arm, initial',
  'encounter',
  'S56519D Strain of other extensor muscle, fascia and tendon at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56519S Strain of other extensor muscle, fascia and tendon at forearm level, unspecified arm, sequela',
  'S56521A Laceration of other extensor muscle, fascia and tendon at forearm level, right arm, initial',
  'encounter',
  'S56521D Laceration of other extensor muscle, fascia and tendon at forearm level, right arm, subsequent',
  'encounter',
  'S56521S Laceration of other extensor muscle, fascia and tendon at forearm level, right arm, sequela',
  'S56522A Laceration of other extensor muscle, fascia and tendon at forearm level, left arm, initial',
  'encounter',
  'S56522D Laceration of other extensor muscle, fascia and tendon at forearm level, left arm, subsequent',
  'encounter',
  'S56522S Laceration of other extensor muscle, fascia and tendon at forearm level, left arm, sequela',
  'S56529A Laceration of other extensor muscle, fascia and tendon at forearm level, unspecified arm,',
  'initial encounter',
  'S56529D Laceration of other extensor muscle, fascia and tendon at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56529S Laceration of other extensor muscle, fascia and tendon at forearm level, unspecified arm,',
  'sequela',
  'S56591A Other injury of other extensor muscle, fascia and tendon at forearm level, right arm, initial',
  'encounter',
  'S56591D Other injury of other extensor muscle, fascia and tendon at forearm level, right arm,',
  'subsequent encounter',
  'S56591S Other injury of other extensor muscle, fascia and tendon at forearm level, right arm, sequela',
  'S56592A Other injury of other extensor muscle, fascia and tendon at forearm level, left arm, initial',
  'encounter',
  'S56592D Other injury of other extensor muscle, fascia and tendon at forearm level, left arm, subsequent',
  'encounter',
  'S56592S Other injury of other extensor muscle, fascia and tendon at forearm level, left arm, sequela',
  'S56599A Other injury of other extensor muscle, fascia and tendon at forearm level, unspecified arm,',
  'initial encounter',
  'S56599D Other injury of other extensor muscle, fascia and tendon at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56599S Other injury of other extensor muscle, fascia and tendon at forearm level, unspecified arm,',
  'sequela',
  'S56801A Unspecified injury of other muscles, fascia and tendons at forearm level, right arm, initial',
  'encounter',
  'S56801D Unspecified injury of other muscles, fascia and tendons at forearm level, right arm, subsequent',
  'encounter',
  'S56801S Unspecified injury of other muscles, fascia and tendons at forearm level, right arm, sequela',
  'S56802A Unspecified injury of other muscles, fascia and tendons at forearm level, left arm, initial',
  'encounter',
  'S56802D Unspecified injury of other muscles, fascia and tendons at forearm level, left arm, subsequent',
  'encounter',
  'S56802S Unspecified injury of other muscles, fascia and tendons at forearm level, left arm, sequela',
  'S56809A Unspecified injury of other muscles, fascia and tendons at forearm level, unspecified arm,',
  'initial encounter',
  'S56809D Unspecified injury of other muscles, fascia and tendons at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56809S Unspecified injury of other muscles, fascia and tendons at forearm level, unspecified arm,',
  'sequela',
  'S56811A Strain of other muscles, fascia and tendons at forearm level, right arm, initial encounter',
  'S56811D Strain of other muscles, fascia and tendons at forearm level, right arm, subsequent encounter',
  'S56811S Strain of other muscles, fascia and tendons at forearm level, right arm, sequela',
  'S56812A Strain of other muscles, fascia and tendons at forearm level, left arm, initial encounter',
  'S56812D Strain of other muscles, fascia and tendons at forearm level, left arm, subsequent encounter',
  'S56812S Strain of other muscles, fascia and tendons at forearm level, left arm, sequela',
  'S56819A Strain of other muscles, fascia and tendons at forearm level, unspecified arm, initial encounter',
  'S56819D Strain of other muscles, fascia and tendons at forearm level, unspecified arm, subsequent',
  'encounter',
  'S56819S Strain of other muscles, fascia and tendons at forearm level, unspecified arm, sequela',
  'S56821A Laceration of other muscles, fascia and tendons at forearm level, right arm, initial encounter',
  'S56821D Laceration of other muscles, fascia and tendons at forearm level, right arm, subsequent',
  'encounter',
  'S56821S Laceration of other muscles, fascia and tendons at forearm level, right arm, sequela',
  'S56822A Laceration of other muscles, fascia and tendons at forearm level, left arm, initial encounter',
  'S56822D Laceration of other muscles, fascia and tendons at forearm level, left arm, subsequent',
  'encounter',
  'S56822S Laceration of other muscles, fascia and tendons at forearm level, left arm, sequela',
  'S56829A Laceration of other muscles, fascia and tendons at forearm level, unspecified arm, initial',
  'encounter',
  'S56829D Laceration of other muscles, fascia and tendons at forearm level, unspecified arm, subsequent',
  'encounter',
  'S56829S Laceration of other muscles, fascia and tendons at forearm level, unspecified arm, sequela',
  'S56891A Other injury of other muscles, fascia and tendons at forearm level, right arm, initial encounter',
  'S56891D Other injury of other muscles, fascia and tendons at forearm level, right arm, subsequent',
  'encounter',
  'S56891S Other injury of other muscles, fascia and tendons at forearm level, right arm, sequela',
  'S56892A Other injury of other muscles, fascia and tendons at forearm level, left arm, initial encounter',
  'S56892D Other injury of other muscles, fascia and tendons at forearm level, left arm, subsequent',
  'encounter',
  'S56892S Other injury of other muscles, fascia and tendons at forearm level, left arm, sequela',
  'S56899A Other injury of other muscles, fascia and tendons at forearm level, unspecified arm, initial',
  'encounter',
  'S56899D Other injury of other muscles, fascia and tendons at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56899S Other injury of other muscles, fascia and tendons at forearm level, unspecified arm, sequela',
  'S56901A Unspecified injury of unspecified muscles, fascia and tendons at forearm level, right arm, initial',
  'encounter',
  'S56901D Unspecified injury of unspecified muscles, fascia and tendons at forearm level, right arm,',
  'subsequent encounter',
  'S56901S Unspecified injury of unspecified muscles, fascia and tendons at forearm level, right arm,',
  'sequela',
  'S56902A Unspecified injury of unspecified muscles, fascia and tendons at forearm level, left arm, initial',
  'encounter',
  'S56902D Unspecified injury of unspecified muscles, fascia and tendons at forearm level, left arm,',
  'subsequent encounter',
  'S56902S Unspecified injury of unspecified muscles, fascia and tendons at forearm level, left arm,',
  'sequela',
  'S56909A Unspecified injury of unspecified muscles, fascia and tendons at forearm level, unspecified',
  'arm, initial encounter',
  'S56909D Unspecified injury of unspecified muscles, fascia and tendons at forearm level, unspecified',
  'arm, subsequent encounter',
  'S56909S Unspecified injury of unspecified muscles, fascia and tendons at forearm level, unspecified arm,',
  'sequela',
  'S56911A Strain of unspecified muscles, fascia and tendons at forearm level, right arm, initial encounter',
  'S56911D Strain of unspecified muscles, fascia and tendons at forearm level, right arm, subsequent',
  'encounter',
  'S56911S Strain of unspecified muscles, fascia and tendons at forearm level, right arm, sequela',
  'S56912A Strain of unspecified muscles, fascia and tendons at forearm level, left arm, initial encounter',
  'S56912D Strain of unspecified muscles, fascia and tendons at forearm level, left arm, subsequent',
  'encounter',
  'S56912S Strain of unspecified muscles, fascia and tendons at forearm level, left arm, sequela',
  'S56919A Strain of unspecified muscles, fascia and tendons at forearm level, unspecified arm, initial',
  'encounter',
  'S56919D Strain of unspecified muscles, fascia and tendons at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56919S Strain of unspecified muscles, fascia and tendons at forearm level, unspecified arm, sequela',
  'S56921A Laceration of unspecified muscles, fascia and tendons at forearm level, right arm, initial',
  'encounter',
  'S56921D Laceration of unspecified muscles, fascia and tendons at forearm level, right arm, subsequent',
  'encounter',
  'S56921S Laceration of unspecified muscles, fascia and tendons at forearm level, right arm, sequela',
  'S56922A Laceration of unspecified muscles, fascia and tendons at forearm level, left arm, initial',
  'encounter',
  'S56922D Laceration of unspecified muscles, fascia and tendons at forearm level, left arm, subsequent',
  'encounter',
  'S56922S Laceration of unspecified muscles, fascia and tendons at forearm level, left arm, sequela',
  'S56929A Laceration of unspecified muscles, fascia and tendons at forearm level, unspecified arm, initial',
  'encounter',
  'S56929D Laceration of unspecified muscles, fascia and tendons at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56929S Laceration of unspecified muscles, fascia and tendons at forearm level, unspecified arm,',
  'sequela',
  'S56991A Other injury of unspecified muscles, fascia and tendons at forearm level, right arm, initial',
  'encounter',
  'S56991D Other injury of unspecified muscles, fascia and tendons at forearm level, right arm, subsequent',
  'encounter',
  'S56991S Other injury of unspecified muscles, fascia and tendons at forearm level, right arm, sequela',
  'S56992A Other injury of unspecified muscles, fascia and tendons at forearm level, left arm, initial',
  'encounter',
  'S56992D Other injury of unspecified muscles, fascia and tendons at forearm level, left arm, subsequent',
  'encounter',
  'S56992S Other injury of unspecified muscles, fascia and tendons at forearm level, left arm, sequela',
  'S56999A Other injury of unspecified muscles, fascia and tendons at forearm level, unspecified arm,',
  'initial encounter',
  'S56999D Other injury of unspecified muscles, fascia and tendons at forearm level, unspecified arm,',
  'subsequent encounter',
  'S56999S Other injury of unspecified muscles, fascia and tendons at forearm level, unspecified arm,',
  'sequela',
  'S5700XA Crushing injury of unspecified elbow, initial encounter',
  'S5700XD Crushing injury of unspecified elbow, subsequent encounter',
  'S5700XS Crushing injury of unspecified elbow, sequela',
  'S5701XA Crushing injury of right elbow, initial encounter',
  'S5701XD Crushing injury of right elbow, subsequent encounter',
  'S5701XS Crushing injury of right elbow, sequela',
  'S5702XA Crushing injury of left elbow, initial encounter',
  'S5702XD Crushing injury of left elbow, subsequent encounter',
  'S5702XS Crushing injury of left elbow, sequela',
  'S5780XA Crushing injury of unspecified forearm, initial encounter',
  'S5780XD Crushing injury of unspecified forearm, subsequent encounter',
  'S5780XS Crushing injury of unspecified forearm, sequela',
  'S5781XA Crushing injury of right forearm, initial encounter',
  'S5781XD Crushing injury of right forearm, subsequent encounter',
  'S5781XS Crushing injury of right forearm, sequela',
  'S5782XA Crushing injury of left forearm, initial encounter',
  'S5782XD Crushing injury of left forearm, subsequent encounter',
  'S5782XS Crushing injury of left forearm, sequela',
  'S58011A Complete traumatic amputation at elbow level, right arm, initial encounter',
  'S58011D Complete traumatic amputation at elbow level, right arm, subsequent encounter',
  'S58011S Complete traumatic amputation at elbow level, right arm, sequela',
  'S58012A Complete traumatic amputation at elbow level, left arm, initial encounter',
  'S58012D Complete traumatic amputation at elbow level, left arm, subsequent encounter',
  'S58012S Complete traumatic amputation at elbow level, left arm, sequela',
  'S58019A Complete traumatic amputation at elbow level, unspecified arm, initial encounter',
  'S58019D Complete traumatic amputation at elbow level, unspecified arm, subsequent encounter',
  'S58019S Complete traumatic amputation at elbow level, unspecified arm, sequela',
  'S58021A Partial traumatic amputation at elbow level, right arm, initial encounter',
  'S58021D Partial traumatic amputation at elbow level, right arm, subsequent encounter',
  'S58021S Partial traumatic amputation at elbow level, right arm, sequela',
  'S58022A Partial traumatic amputation at elbow level, left arm, initial encounter',
  'S58022D Partial traumatic amputation at elbow level, left arm, subsequent encounter',
  'S58022S Partial traumatic amputation at elbow level, left arm, sequela',
  'S58029A Partial traumatic amputation at elbow level, unspecified arm, initial encounter',
  'S58029D Partial traumatic amputation at elbow level, unspecified arm, subsequent encounter',
  'S58029S Partial traumatic amputation at elbow level, unspecified arm, sequela',
  'S58111A Complete traumatic amputation at level between elbow and wrist, right arm, initial encounter',
  'S58111D Complete traumatic amputation at level between elbow and wrist, right arm, subsequent',
  'encounter',
  'S58111S Complete traumatic amputation at level between elbow and wrist, right arm, sequela',
  'S58112A Complete traumatic amputation at level between elbow and wrist, left arm, initial encounter',
  'S58112D Complete traumatic amputation at level between elbow and wrist, left arm, subsequent',
  'encounter',
  'S58112S Complete traumatic amputation at level between elbow and wrist, left arm, sequela',
  'S58119A Complete traumatic amputation at level between elbow and wrist, unspecified arm, initial',
  'encounter',
  'S58119D Complete traumatic amputation at level between elbow and wrist, unspecified arm,',
  'subsequent encounter',
  'S58119S Complete traumatic amputation at level between elbow and wrist, unspecified arm, sequela',
  'S58121A Partial traumatic amputation at level between elbow and wrist, right arm, initial encounter',
  'S58121D Partial traumatic amputation at level between elbow and wrist, right arm, subsequent',
  'encounter',
  'S58121S Partial traumatic amputation at level between elbow and wrist, right arm, sequela',
  'S58122A Partial traumatic amputation at level between elbow and wrist, left arm, initial encounter',
  'S58122D Partial traumatic amputation at level between elbow and wrist, left arm, subsequent',
  'encounter',
  'S58122S Partial traumatic amputation at level between elbow and wrist, left arm, sequela',
  'S58129A Partial traumatic amputation at level between elbow and wrist, unspecified arm, initial',
  'encounter',
  'S58129D Partial traumatic amputation at level between elbow and wrist, unspecified arm, subsequent',
  'encounter',
  'S58129S Partial traumatic amputation at level between elbow and wrist, unspecified arm, sequela',
  'S58911A Complete traumatic amputation of right forearm, level unspecified, initial encounter',
  'S58911D Complete traumatic amputation of right forearm, level unspecified, subsequent encounter',
  'S58911S Complete traumatic amputation of right forearm, level unspecified, sequela',
  'S58912A Complete traumatic amputation of left forearm, level unspecified, initial encounter',
  'S58912D Complete traumatic amputation of left forearm, level unspecified, subsequent encounter',
  'S58912S Complete traumatic amputation of left forearm, level unspecified, sequela',
  'S58919A Complete traumatic amputation of unspecified forearm, level unspecified, initial encounter',
  'S58919D Complete traumatic amputation of unspecified forearm, level unspecified, subsequent',
  'encounter',
  'S58919S Complete traumatic amputation of unspecified forearm, level unspecified, sequela',
  'S58921A Partial traumatic amputation of right forearm, level unspecified, initial encounter',
  'S58921D Partial traumatic amputation of right forearm, level unspecified, subsequent encounter',
  'S58921S Partial traumatic amputation of right forearm, level unspecified, sequela',
  'S58922A Partial traumatic amputation of left forearm, level unspecified, initial encounter',
  'S58922D Partial traumatic amputation of left forearm, level unspecified, subsequent encounter',
  'S58922S Partial traumatic amputation of left forearm, level unspecified, sequela',
  'S58929A Partial traumatic amputation of unspecified forearm, level unspecified, initial encounter',
  'S58929D Partial traumatic amputation of unspecified forearm, level unspecified, subsequent encounter',
  'S58929S Partial traumatic amputation of unspecified forearm, level unspecified, sequela',
  'S59001A Unspecified physeal fracture of lower end of ulna, right arm, initial encounter for closed',
  'fracture',
  'S59001D Unspecified physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S59001G Unspecified physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59001K Unspecified physeal fracture of lower end of ulna, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S59001P Unspecified physeal fracture of lower end of ulna, right arm, subsequent encounter for fracture',
  'with malunion',
  'S59001S Unspecified physeal fracture of lower end of ulna, right arm, sequela',
  'S59002A Unspecified physeal fracture of lower end of ulna, left arm, initial encounter for closed fracture',
  'S59002D Unspecified physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S59002G Unspecified physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S59002K Unspecified physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture',
  'with nonunion',
  'S59002P Unspecified physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture',
  'with malunion',
  'S59002S Unspecified physeal fracture of lower end of ulna, left arm, sequela',
  'S59009A Unspecified physeal fracture of lower end of ulna, unspecified arm, initial encounter for closed',
  'fracture',
  'S59009D Unspecified physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S59009G Unspecified physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59009K Unspecified physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S59009P Unspecified physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S59009S Unspecified physeal fracture of lower end of ulna, unspecified arm, sequela',
  'S59011A Salter-Harris Type I physeal fracture of lower end of ulna, right arm, initial encounter for closed',
  'fracture',
  'S59011D Salter-Harris Type I physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S59011G Salter-Harris Type I physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59011K Salter-Harris Type I physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S59011P Salter-Harris Type I physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with malunion',
  'S59011S Salter-Harris Type I physeal fracture of lower end of ulna, right arm, sequela',
  'S59012A Salter-Harris Type I physeal fracture of lower end of ulna, left arm, initial encounter for closed',
  'fracture',
  'S59012D Salter-Harris Type I physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59012G Salter-Harris Type I physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59012K Salter-Harris Type I physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59012P Salter-Harris Type I physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59012S Salter-Harris Type I physeal fracture of lower end of ulna, left arm, sequela',
  'S59019A Salter-Harris Type I physeal fracture of lower end of ulna, unspecified arm, initial encounter for',
  'closed fracture',
  'S59019D Salter-Harris Type I physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59019G Salter-Harris Type I physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59019K Salter-Harris Type I physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59019P Salter-Harris Type I physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59019S Salter-Harris Type I physeal fracture of lower end of ulna, unspecified arm, sequela',
  'S59021A Salter-Harris Type II physeal fracture of lower end of ulna, right arm, initial encounter for',
  'closed fracture',
  'S59021D Salter-Harris Type II physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S59021G Salter-Harris Type II physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59021K Salter-Harris Type II physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S59021P Salter-Harris Type II physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with malunion',
  'S59021S Salter-Harris Type II physeal fracture of lower end of ulna, right arm, sequela',
  'S59022A Salter-Harris Type II physeal fracture of lower end of ulna, left arm, initial encounter for closed',
  'fracture',
  'S59022D Salter-Harris Type II physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59022G Salter-Harris Type II physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59022K Salter-Harris Type II physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59022P Salter-Harris Type II physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59022S Salter-Harris Type II physeal fracture of lower end of ulna, left arm, sequela',
  'S59029A Salter-Harris Type II physeal fracture of lower end of ulna, unspecified arm, initial encounter',
  'for closed fracture',
  'S59029D Salter-Harris Type II physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59029G Salter-Harris Type II physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59029K Salter-Harris Type II physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59029P Salter-Harris Type II physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59029S Salter-Harris Type II physeal fracture of lower end of ulna, unspecified arm, sequela',
  'S59031A Salter-Harris Type III physeal fracture of lower end of ulna, right arm, initial encounter for',
  'closed fracture',
  'S59031D Salter-Harris Type III physeal fracture of lower end of ulna, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59031G Salter-Harris Type III physeal fracture of lower end of ulna, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59031K Salter-Harris Type III physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S59031P Salter-Harris Type III physeal fracture of lower end of ulna, right arm, subsequent encounter for',
  'fracture with malunion',
  'S59031S Salter-Harris Type III physeal fracture of lower end of ulna, right arm, sequela',
  'S59032A Salter-Harris Type III physeal fracture of lower end of ulna, left arm, initial encounter for closed',
  'fracture',
  'S59032D Salter-Harris Type III physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59032G Salter-Harris Type III physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59032K Salter-Harris Type III physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59032P Salter-Harris Type III physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59032S Salter-Harris Type III physeal fracture of lower end of ulna, left arm, sequela',
  'S59039A Salter-Harris Type III physeal fracture of lower end of ulna, unspecified arm, initial encounter',
  'for closed fracture',
  'S59039D Salter-Harris Type III physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59039G Salter-Harris Type III physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59039K Salter-Harris Type III physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59039P Salter-Harris Type III physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59039S Salter-Harris Type III physeal fracture of lower end of ulna, unspecified arm, sequela',
  'S59041A Salter-Harris Type IV physeal fracture of lower end of ulna, right arm, initial encounter for',
  'closed fracture',
  'S59041D Salter-Harris Type IV physeal fracture of lower end of ulna, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59041G Salter-Harris Type IV physeal fracture of lower end of ulna, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59041K Salter-Harris Type IV physeal fracture of lower end of ulna, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59041P Salter-Harris Type IV physeal fracture of lower end of ulna, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59041S Salter-Harris Type IV physeal fracture of lower end of ulna, right arm, sequela',
  'S59042A Salter-Harris Type IV physeal fracture of lower end of ulna, left arm, initial encounter for closed',
  'fracture',
  'S59042D Salter-Harris Type IV physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59042G Salter-Harris Type IV physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59042K Salter-Harris Type IV physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59042P Salter-Harris Type IV physeal fracture of lower end of ulna, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59042S Salter-Harris Type IV physeal fracture of lower end of ulna, left arm, sequela',
  'S59049A Salter-Harris Type IV physeal fracture of lower end of ulna, unspecified arm, initial encounter',
  'for closed fracture',
  'S59049D Salter-Harris Type IV physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59049G Salter-Harris Type IV physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59049K Salter-Harris Type IV physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59049P Salter-Harris Type IV physeal fracture of lower end of ulna, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59049S Salter-Harris Type IV physeal fracture of lower end of ulna, unspecified arm, sequela',
  'S59091A Other physeal fracture of lower end of ulna, right arm, initial encounter for closed fracture',
  'S59091D Other physeal fracture of lower end of ulna, right arm, subsequent encounter for fracture with',
  'routine healing',
  'S59091G Other physeal fracture of lower end of ulna, right arm, subsequent encounter for fracture with',
  'delayed healing',
  'S59091K Other physeal fracture of lower end of ulna, right arm, subsequent encounter for fracture with',
  'nonunion',
  'S59091P Other physeal fracture of lower end of ulna, right arm, subsequent encounter for fracture with',
  'malunion',
  'S59091S Other physeal fracture of lower end of ulna, right arm, sequela',
  'S59092A Other physeal fracture of lower end of ulna, left arm, initial encounter for closed fracture',
  'S59092D Other physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture with',
  'routine healing',
  'S59092G Other physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture with',
  'delayed healing',
  'S59092K Other physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture with',
  'nonunion',
  'S59092P Other physeal fracture of lower end of ulna, left arm, subsequent encounter for fracture with',
  'malunion',
  'S59092S Other physeal fracture of lower end of ulna, left arm, sequela',
  'S59099A Other physeal fracture of lower end of ulna, unspecified arm, initial encounter for closed',
  'fracture',
  'S59099D Other physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S59099G Other physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59099K Other physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S59099P Other physeal fracture of lower end of ulna, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S59099S Other physeal fracture of lower end of ulna, unspecified arm, sequela',
  'S59101A Unspecified physeal fracture of upper end of radius, right arm, initial encounter for closed',
  'fracture',
  'S59101D Unspecified physeal fracture of upper end of radius, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S59101G Unspecified physeal fracture of upper end of radius, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59101K Unspecified physeal fracture of upper end of radius, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S59101P Unspecified physeal fracture of upper end of radius, right arm, subsequent encounter for',
  'fracture with malunion',
  'S59101S Unspecified physeal fracture of upper end of radius, right arm, sequela',
  'S59102A Unspecified physeal fracture of upper end of radius, left arm, initial encounter for closed',
  'fracture',
  'S59102D Unspecified physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59102G Unspecified physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59102K Unspecified physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59102P Unspecified physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59102S Unspecified physeal fracture of upper end of radius, left arm, sequela',
  'S59109A Unspecified physeal fracture of upper end of radius, unspecified arm, initial encounter for',
  'closed fracture',
  'S59109D Unspecified physeal fracture of upper end of radius, unspecified arm, subsequent encounter',
  'for fracture with routine healing',
  'S59109G Unspecified physeal fracture of upper end of radius, unspecified arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59109K Unspecified physeal fracture of upper end of radius, unspecified arm, subsequent encounter',
  'for fracture with nonunion',
  'S59109P Unspecified physeal fracture of upper end of radius, unspecified arm, subsequent encounter',
  'for fracture with malunion',
  'S59109S Unspecified physeal fracture of upper end of radius, unspecified arm, sequela',
  'S59111A Salter-Harris Type I physeal fracture of upper end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59111D Salter-Harris Type I physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59111G Salter-Harris Type I physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59111K Salter-Harris Type I physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59111P Salter-Harris Type I physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59111S Salter-Harris Type I physeal fracture of upper end of radius, right arm, sequela',
  'S59112A Salter-Harris Type I physeal fracture of upper end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59112D Salter-Harris Type I physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59112G Salter-Harris Type I physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59112K Salter-Harris Type I physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59112P Salter-Harris Type I physeal fracture of upper end of radius, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59112S Salter-Harris Type I physeal fracture of upper end of radius, left arm, sequela',
  'S59119A Salter-Harris Type I physeal fracture of upper end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59119D Salter-Harris Type I physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59119G Salter-Harris Type I physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59119K Salter-Harris Type I physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59119P Salter-Harris Type I physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59119S Salter-Harris Type I physeal fracture of upper end of radius, unspecified arm, sequela',
  'S59121A Salter-Harris Type II physeal fracture of upper end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59121D Salter-Harris Type II physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59121G Salter-Harris Type II physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59121K Salter-Harris Type II physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59121P Salter-Harris Type II physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59121S Salter-Harris Type II physeal fracture of upper end of radius, right arm, sequela',
  'S59122A Salter-Harris Type II physeal fracture of upper end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59122D Salter-Harris Type II physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S59122G Salter-Harris Type II physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59122K Salter-Harris Type II physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S59122P Salter-Harris Type II physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with malunion',
  'S59122S Salter-Harris Type II physeal fracture of upper end of radius, left arm, sequela',
  'S59129A Salter-Harris Type II physeal fracture of upper end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59129D Salter-Harris Type II physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59129G Salter-Harris Type II physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59129K Salter-Harris Type II physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59129P Salter-Harris Type II physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59129S Salter-Harris Type II physeal fracture of upper end of radius, unspecified arm, sequela',
  'S59131A Salter-Harris Type III physeal fracture of upper end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59131D Salter-Harris Type III physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59131G Salter-Harris Type III physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59131K Salter-Harris Type III physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59131P Salter-Harris Type III physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59131S Salter-Harris Type III physeal fracture of upper end of radius, right arm, sequela',
  'S59132A Salter-Harris Type III physeal fracture of upper end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59132D Salter-Harris Type III physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S59132G Salter-Harris Type III physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59132K Salter-Harris Type III physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S59132P Salter-Harris Type III physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with malunion',
  'S59132S Salter-Harris Type III physeal fracture of upper end of radius, left arm, sequela',
  'S59139A Salter-Harris Type III physeal fracture of upper end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59139D Salter-Harris Type III physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59139G Salter-Harris Type III physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59139K Salter-Harris Type III physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59139P Salter-Harris Type III physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59139S Salter-Harris Type III physeal fracture of upper end of radius, unspecified arm, sequela',
  'S59141A Salter-Harris Type IV physeal fracture of upper end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59141D Salter-Harris Type IV physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59141G Salter-Harris Type IV physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59141K Salter-Harris Type IV physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59141P Salter-Harris Type IV physeal fracture of upper end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59141S Salter-Harris Type IV physeal fracture of upper end of radius, right arm, sequela',
  'S59142A Salter-Harris Type IV physeal fracture of upper end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59142D Salter-Harris Type IV physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S59142G Salter-Harris Type IV physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59142K Salter-Harris Type IV physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S59142P Salter-Harris Type IV physeal fracture of upper end of radius, left arm, subsequent encounter',
  'for fracture with malunion',
  'S59142S Salter-Harris Type IV physeal fracture of upper end of radius, left arm, sequela',
  'S59149A Salter-Harris Type IV physeal fracture of upper end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59149D Salter-Harris Type IV physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59149G Salter-Harris Type IV physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59149K Salter-Harris Type IV physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59149P Salter-Harris Type IV physeal fracture of upper end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59149S Salter-Harris Type IV physeal fracture of upper end of radius, unspecified arm, sequela',
  'S59191A Other physeal fracture of upper end of radius, right arm, initial encounter for closed fracture',
  'S59191D Other physeal fracture of upper end of radius, right arm, subsequent encounter for fracture',
  'with routine healing',
  'S59191G Other physeal fracture of upper end of radius, right arm, subsequent encounter for fracture',
  'with delayed healing',
  'S59191K Other physeal fracture of upper end of radius, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S59191P Other physeal fracture of upper end of radius, right arm, subsequent encounter for fracture',
  'with malunion',
  'S59191S Other physeal fracture of upper end of radius, right arm, sequela',
  'S59192A Other physeal fracture of upper end of radius, left arm, initial encounter for closed fracture',
  'S59192D Other physeal fracture of upper end of radius, left arm, subsequent encounter for fracture',
  'with routine healing',
  'S59192G Other physeal fracture of upper end of radius, left arm, subsequent encounter for fracture',
  'with delayed healing',
  'S59192K Other physeal fracture of upper end of radius, left arm, subsequent encounter for fracture with',
  'nonunion',
  'S59192P Other physeal fracture of upper end of radius, left arm, subsequent encounter for fracture with',
  'malunion',
  'S59192S Other physeal fracture of upper end of radius, left arm, sequela',
  'S59199A Other physeal fracture of upper end of radius, unspecified arm, initial encounter for closed',
  'fracture',
  'S59199D Other physeal fracture of upper end of radius, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S59199G Other physeal fracture of upper end of radius, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59199K Other physeal fracture of upper end of radius, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S59199P Other physeal fracture of upper end of radius, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S59199S Other physeal fracture of upper end of radius, unspecified arm, sequela',
  'S59201A Unspecified physeal fracture of lower end of radius, right arm, initial encounter for closed',
  'fracture',
  'S59201D Unspecified physeal fracture of lower end of radius, right arm, subsequent encounter for',
  'fracture with routine healing',
  'S59201G Unspecified physeal fracture of lower end of radius, right arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59201K Unspecified physeal fracture of lower end of radius, right arm, subsequent encounter for',
  'fracture with nonunion',
  'S59201P Unspecified physeal fracture of lower end of radius, right arm, subsequent encounter for',
  'fracture with malunion',
  'S59201S Unspecified physeal fracture of lower end of radius, right arm, sequela',
  'S59202A Unspecified physeal fracture of lower end of radius, left arm, initial encounter for closed',
  'fracture',
  'S59202D Unspecified physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59202G Unspecified physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59202K Unspecified physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59202P Unspecified physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59202S Unspecified physeal fracture of lower end of radius, left arm, sequela',
  'S59209A Unspecified physeal fracture of lower end of radius, unspecified arm, initial encounter for',
  'closed fracture',
  'S59209D Unspecified physeal fracture of lower end of radius, unspecified arm, subsequent encounter',
  'for fracture with routine healing',
  'S59209G Unspecified physeal fracture of lower end of radius, unspecified arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59209K Unspecified physeal fracture of lower end of radius, unspecified arm, subsequent encounter',
  'for fracture with nonunion',
  'S59209P Unspecified physeal fracture of lower end of radius, unspecified arm, subsequent encounter',
  'for fracture with malunion',
  'S59209S Unspecified physeal fracture of lower end of radius, unspecified arm, sequela',
  'S59211A Salter-Harris Type I physeal fracture of lower end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59211D Salter-Harris Type I physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59211G Salter-Harris Type I physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59211K Salter-Harris Type I physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59211P Salter-Harris Type I physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59211S Salter-Harris Type I physeal fracture of lower end of radius, right arm, sequela',
  'S59212A Salter-Harris Type I physeal fracture of lower end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59212D Salter-Harris Type I physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with routine healing',
  'S59212G Salter-Harris Type I physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59212K Salter-Harris Type I physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59212P Salter-Harris Type I physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59212S Salter-Harris Type I physeal fracture of lower end of radius, left arm, sequela',
  'S59219A Salter-Harris Type I physeal fracture of lower end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59219D Salter-Harris Type I physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59219G Salter-Harris Type I physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59219K Salter-Harris Type I physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59219P Salter-Harris Type I physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59219S Salter-Harris Type I physeal fracture of lower end of radius, unspecified arm, sequela',
  'S59221A Salter-Harris Type II physeal fracture of lower end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59221D Salter-Harris Type II physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59221G Salter-Harris Type II physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59221K Salter-Harris Type II physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59221P Salter-Harris Type II physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59221S Salter-Harris Type II physeal fracture of lower end of radius, right arm, sequela',
  'S59222A Salter-Harris Type II physeal fracture of lower end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59222D Salter-Harris Type II physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S59222G Salter-Harris Type II physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59222K Salter-Harris Type II physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with nonunion',
  'S59222P Salter-Harris Type II physeal fracture of lower end of radius, left arm, subsequent encounter for',
  'fracture with malunion',
  'S59222S Salter-Harris Type II physeal fracture of lower end of radius, left arm, sequela',
  'S59229A Salter-Harris Type II physeal fracture of lower end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59229D Salter-Harris Type II physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59229G Salter-Harris Type II physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59229K Salter-Harris Type II physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59229P Salter-Harris Type II physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59229S Salter-Harris Type II physeal fracture of lower end of radius, unspecified arm, sequela',
  'S59231A Salter-Harris Type III physeal fracture of lower end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59231D Salter-Harris Type III physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59231G Salter-Harris Type III physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59231K Salter-Harris Type III physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59231P Salter-Harris Type III physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59231S Salter-Harris Type III physeal fracture of lower end of radius, right arm, sequela',
  'S59232A Salter-Harris Type III physeal fracture of lower end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59232D Salter-Harris Type III physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S59232G Salter-Harris Type III physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59232K Salter-Harris Type III physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S59232P Salter-Harris Type III physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with malunion',
  'S59232S Salter-Harris Type III physeal fracture of lower end of radius, left arm, sequela',
  'S59239A Salter-Harris Type III physeal fracture of lower end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59239D Salter-Harris Type III physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59239G Salter-Harris Type III physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59239K Salter-Harris Type III physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59239P Salter-Harris Type III physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59239S Salter-Harris Type III physeal fracture of lower end of radius, unspecified arm, sequela',
  'S59241A Salter-Harris Type IV physeal fracture of lower end of radius, right arm, initial encounter for',
  'closed fracture',
  'S59241D Salter-Harris Type IV physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with routine healing',
  'S59241G Salter-Harris Type IV physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59241K Salter-Harris Type IV physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with nonunion',
  'S59241P Salter-Harris Type IV physeal fracture of lower end of radius, right arm, subsequent encounter',
  'for fracture with malunion',
  'S59241S Salter-Harris Type IV physeal fracture of lower end of radius, right arm, sequela',
  'S59242A Salter-Harris Type IV physeal fracture of lower end of radius, left arm, initial encounter for',
  'closed fracture',
  'S59242D Salter-Harris Type IV physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with routine healing',
  'S59242G Salter-Harris Type IV physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with delayed healing',
  'S59242K Salter-Harris Type IV physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with nonunion',
  'S59242P Salter-Harris Type IV physeal fracture of lower end of radius, left arm, subsequent encounter',
  'for fracture with malunion',
  'S59242S Salter-Harris Type IV physeal fracture of lower end of radius, left arm, sequela',
  'S59249A Salter-Harris Type IV physeal fracture of lower end of radius, unspecified arm, initial encounter',
  'for closed fracture',
  'S59249D Salter-Harris Type IV physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with routine healing',
  'S59249G Salter-Harris Type IV physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with delayed healing',
  'S59249K Salter-Harris Type IV physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with nonunion',
  'S59249P Salter-Harris Type IV physeal fracture of lower end of radius, unspecified arm, subsequent',
  'encounter for fracture with malunion',
  'S59249S Salter-Harris Type IV physeal fracture of lower end of radius, unspecified arm, sequela',
  'S59291A Other physeal fracture of lower end of radius, right arm, initial encounter for closed fracture',
  'S59291D Other physeal fracture of lower end of radius, right arm, subsequent encounter for fracture',
  'with routine healing',
  'S59291G Other physeal fracture of lower end of radius, right arm, subsequent encounter for fracture',
  'with delayed healing',
  'S59291K Other physeal fracture of lower end of radius, right arm, subsequent encounter for fracture',
  'with nonunion',
  'S59291P Other physeal fracture of lower end of radius, right arm, subsequent encounter for fracture',
  'with malunion',
  'S59291S Other physeal fracture of lower end of radius, right arm, sequela',
  'S59292A Other physeal fracture of lower end of radius, left arm, initial encounter for closed fracture',
  'S59292D Other physeal fracture of lower end of radius, left arm, subsequent encounter for fracture with',
  'routine healing',
  'S59292G Other physeal fracture of lower end of radius, left arm, subsequent encounter for fracture with',
  'delayed healing',
  'S59292K Other physeal fracture of lower end of radius, left arm, subsequent encounter for fracture with',
  'nonunion',
  'S59292P Other physeal fracture of lower end of radius, left arm, subsequent encounter for fracture with',
  'malunion',
  'S59292S Other physeal fracture of lower end of radius, left arm, sequela',
  'S59299A Other physeal fracture of lower end of radius, unspecified arm, initial encounter for closed',
  'fracture',
  'S59299D Other physeal fracture of lower end of radius, unspecified arm, subsequent encounter for',
  'fracture with routine healing',
  'S59299G Other physeal fracture of lower end of radius, unspecified arm, subsequent encounter for',
  'fracture with delayed healing',
  'S59299K Other physeal fracture of lower end of radius, unspecified arm, subsequent encounter for',
  'fracture with nonunion',
  'S59299P Other physeal fracture of lower end of radius, unspecified arm, subsequent encounter for',
  'fracture with malunion',
  'S59299S Other physeal fracture of lower end of radius, unspecified arm, sequela',
  'S59801A Other specified injuries of right elbow, initial encounter',
  'S59801D Other specified injuries of right elbow, subsequent encounter',
  'S59801S Other specified injuries of right elbow, sequela',
  'S59802A Other specified injuries of left elbow, initial encounter',
  'S59802D Other specified injuries of left elbow, subsequent encounter',
  'S59802S Other specified injuries of left elbow, sequela',
  'S59809A Other specified injuries of unspecified elbow, initial encounter',
  'S59809D Other specified injuries of unspecified elbow, subsequent encounter',
  'S59809S Other specified injuries of unspecified elbow, sequela',
  'S59811A Other specified injuries right forearm, initial encounter',
  'S59811D Other specified injuries right forearm, subsequent encounter',
  'S59811S Other specified injuries right forearm, sequela',
  'S59812A Other specified injuries left forearm, initial encounter',
  'S59812D Other specified injuries left forearm, subsequent encounter',
  'S59812S Other specified injuries left forearm, sequela',
  'S59819A Other specified injuries unspecified forearm, initial encounter',
  'S59819D Other specified injuries unspecified forearm, subsequent encounter',
  'S59819S Other specified injuries unspecified forearm, sequela',
  'S59901A Unspecified injury of right elbow, initial encounter',
  'S59901D Unspecified injury of right elbow, subsequent encounter',
  'S59901S Unspecified injury of right elbow, sequela',
  'S59902A Unspecified injury of left elbow, initial encounter',
  'S59902D Unspecified injury of left elbow, subsequent encounter',
  'S59902S Unspecified injury of left elbow, sequela',
  'S59909A Unspecified injury of unspecified elbow, initial encounter',
  'S59909D Unspecified injury of unspecified elbow, subsequent encounter',
  'S59909S Unspecified injury of unspecified elbow, sequela',
  'S59911A Unspecified injury of right forearm, initial encounter',
  'S59911D Unspecified injury of right forearm, subsequent encounter',
  'S59911S Unspecified injury of right forearm, sequela',
  'S59912A Unspecified injury of left forearm, initial encounter',
  'S59912D Unspecified injury of left forearm, subsequent encounter',
  'S59912S Unspecified injury of left forearm, sequela',
  'S59919A Unspecified injury of unspecified forearm, initial encounter',
  'S59919D Unspecified injury of unspecified forearm, subsequent encounter',
  'S59919S Unspecified injury of unspecified forearm, sequela',
  'S6000XA Contusion of unspecified finger without damage to nail, initial encounter',
  'S6000XD Contusion of unspecified finger without damage to nail, subsequent encounter',
  'S6000XS Contusion of unspecified finger without damage to nail, sequela',
  'S60011A Contusion of right thumb without damage to nail, initial encounter',
  'S60011D Contusion of right thumb without damage to nail, subsequent encounter',
  'S60011S Contusion of right thumb without damage to nail, sequela',
  'S60012A Contusion of left thumb without damage to nail, initial encounter',
  'S60012D Contusion of left thumb without damage to nail, subsequent encounter',
  'S60012S Contusion of left thumb without damage to nail, sequela',
  'S60019A Contusion of unspecified thumb without damage to nail, initial encounter',
  'S60019D Contusion of unspecified thumb without damage to nail, subsequent encounter',
  'S60019S Contusion of unspecified thumb without damage to nail, sequela',
  'S60021A Contusion of right index finger without damage to nail, initial encounter',
  'S60021D Contusion of right index finger without damage to nail, subsequent encounter',
  'S60021S Contusion of right index finger without damage to nail, sequela',
  'S60022A Contusion of left index finger without damage to nail, initial encounter',
  'S60022D Contusion of left index finger without damage to nail, subsequent encounter',
  'S60022S Contusion of left index finger without damage to nail, sequela',
  'S60029A Contusion of unspecified index finger without damage to nail, initial encounter',
  'S60029D Contusion of unspecified index finger without damage to nail, subsequent encounter',
  'S60029S Contusion of unspecified index finger without damage to nail, sequela',
  'S60031A Contusion of right middle finger without damage to nail, initial encounter',
  'S60031D Contusion of right middle finger without damage to nail, subsequent encounter',
  'S60031S Contusion of right middle finger without damage to nail, sequela',
  'S60032A Contusion of left middle finger without damage to nail, initial encounter',
  'S60032D Contusion of left middle finger without damage to nail, subsequent encounter',
  'S60032S Contusion of left middle finger without damage to nail, sequela',
  'S60039A Contusion of unspecified middle finger without damage to nail, initial encounter',
  'S60039D Contusion of unspecified middle finger without damage to nail, subsequent encounter',
  'S60039S Contusion of unspecified middle finger without damage to nail, sequela',
  'S60041A Contusion of right ring finger without damage to nail, initial encounter',
  'S60041D Contusion of right ring finger without damage to nail, subsequent encounter',
  'S60041S Contusion of right ring finger without damage to nail, sequela',
  'S60042A Contusion of left ring finger without damage to nail, initial encounter',
  'S60042D Contusion of left ring finger without damage to nail, subsequent encounter',
  'S60042S Contusion of left ring finger without damage to nail, sequela',
  'S60049A Contusion of unspecified ring finger without damage to nail, initial encounter',
  'S60049D Contusion of unspecified ring finger without damage to nail, subsequent encounter',
  'S60049S Contusion of unspecified ring finger without damage to nail, sequela',
  'S60051A Contusion of right little finger without damage to nail, initial encounter',
  'S60051D Contusion of right little finger without damage to nail, subsequent encounter',
  'S60051S Contusion of right little finger without damage to nail, sequela',
  'S60052A Contusion of left little finger without damage to nail, initial encounter',
  'S60052D Contusion of left little finger without damage to nail, subsequent encounter',
  'S60052S Contusion of left little finger without damage to nail, sequela',
  'S60059A Contusion of unspecified little finger without damage to nail, initial encounter',
  'S60059D Contusion of unspecified little finger without damage to nail, subsequent encounter',
  'S60059S Contusion of unspecified little finger without damage to nail, sequela',
  'S6010XA Contusion of unspecified finger with damage to nail, initial encounter',
  'S6010XD Contusion of unspecified finger with damage to nail, subsequent encounter',
  'S6010XS Contusion of unspecified finger with damage to nail, sequela',
  'S60111A Contusion of right thumb with damage to nail, initial encounter',
  'S60111D Contusion of right thumb with damage to nail, subsequent encounter',
  'S60111S Contusion of right thumb with damage to nail, sequela',
  'S60112A Contusion of left thumb with damage to nail, initial encounter',
  'S60112D Contusion of left thumb with damage to nail, subsequent encounter',
  'S60112S Contusion of left thumb with damage to nail, sequela',
  'S60119A Contusion of unspecified thumb with damage to nail, initial encounter',
  'S60119D Contusion of unspecified thumb with damage to nail, subsequent encounter',
  'S60119S Contusion of unspecified thumb with damage to nail, sequela',
  'S60121A Contusion of right index finger with damage to nail, initial encounter',
  'S60121D Contusion of right index finger with damage to nail, subsequent encounter',
  'S60121S Contusion of right index finger with damage to nail, sequela',
  'S60122A Contusion of left index finger with damage to nail, initial encounter',
  'S60122D Contusion of left index finger with damage to nail, subsequent encounter',
  'S60122S Contusion of left index finger with damage to nail, sequela',
  'S60129A Contusion of unspecified index finger with damage to nail, initial encounter',
  'S60129D Contusion of unspecified index finger with damage to nail, subsequent encounter',
  'S60129S Contusion of unspecified index finger with damage to nail, sequela',
  'S60131A Contusion of right middle finger with damage to nail, initial encounter',
  'S60131D Contusion of right middle finger with damage to nail, subsequent encounter',
  'S60131S Contusion of right middle finger with damage to nail, sequela',
  'S60132A Contusion of left middle finger with damage to nail, initial encounter',
  'S60132D Contusion of left middle finger with damage to nail, subsequent encounter',
  'S60132S Contusion of left middle finger with damage to nail, sequela',
  'S60139A Contusion of unspecified middle finger with damage to nail, initial encounter',
  'S60139D Contusion of unspecified middle finger with damage to nail, subsequent encounter',
  'S60139S Contusion of unspecified middle finger with damage to nail, sequela',
  'S60141A Contusion of right ring finger with damage to nail, initial encounter',
  'S60141D Contusion of right ring finger with damage to nail, subsequent encounter',
  'S60141S Contusion of right ring finger with damage to nail, sequela',
  'S60142A Contusion of left ring finger with damage to nail, initial encounter',
  'S60142D Contusion of left ring finger with damage to nail, subsequent encounter',
  'S60142S Contusion of left ring finger with damage to nail, sequela',
  'S60149A Contusion of unspecified ring finger with damage to nail, initial encounter',
  'S60149D Contusion of unspecified ring finger with damage to nail, subsequent encounter',
  'S60149S Contusion of unspecified ring finger with damage to nail, sequela',
  'S60151A Contusion of right little finger with damage to nail, initial encounter',
  'S60151D Contusion of right little finger with damage to nail, subsequent encounter',
  'S60151S Contusion of right little finger with damage to nail, sequela',
  'S60152A Contusion of left little finger with damage to nail, initial encounter',
  'S60152D Contusion of left little finger with damage to nail, subsequent encounter',
  'S60152S Contusion of left little finger with damage to nail, sequela',
  'S60159A Contusion of unspecified little finger with damage to nail, initial encounter',
  'S60159D Contusion of unspecified little finger with damage to nail, subsequent encounter',
  'S60159S Contusion of unspecified little finger with damage to nail, sequela',
  'S60211A Contusion of right wrist, initial encounter',
  'S60211D Contusion of right wrist, subsequent encounter',
  'S60211S Contusion of right wrist, sequela',
  'S60212A Contusion of left wrist, initial encounter',
  'S60212D Contusion of left wrist, subsequent encounter',
  'S60212S Contusion of left wrist, sequela',
  'S60219A Contusion of unspecified wrist, initial encounter',
  'S60219D Contusion of unspecified wrist, subsequent encounter',
  'S60219S Contusion of unspecified wrist, sequela',
  'S60221A Contusion of right hand, initial encounter',
  'S60221D Contusion of right hand, subsequent encounter',
  'S60221S Contusion of right hand, sequela',
  'S60222A Contusion of left hand, initial encounter',
  'S60222D Contusion of left hand, subsequent encounter',
  'S60222S Contusion of left hand, sequela',
  'S60229A Contusion of unspecified hand, initial encounter',
  'S60229D Contusion of unspecified hand, subsequent encounter',
  'S60229S Contusion of unspecified hand, sequela',
  'S60311A Abrasion of right thumb, initial encounter',
  'S60311D Abrasion of right thumb, subsequent encounter',
  'S60311S Abrasion of right thumb, sequela',
  'S60312A Abrasion of left thumb, initial encounter',
  'S60312D Abrasion of left thumb, subsequent encounter',
  'S60312S Abrasion of left thumb, sequela',
  'S60319A Abrasion of unspecified thumb, initial encounter',
  'S60319D Abrasion of unspecified thumb, subsequent encounter',
  'S60319S Abrasion of unspecified thumb, sequela',
  'S60321A Blister (nonthermal) of right thumb, initial encounter',
  'S60321D Blister (nonthermal) of right thumb, subsequent encounter',
  'S60321S Blister (nonthermal) of right thumb, sequela',
  'S60322A Blister (nonthermal) of left thumb, initial encounter',
  'S60322D Blister (nonthermal) of left thumb, subsequent encounter',
  'S60322S Blister (nonthermal) of left thumb, sequela',
  'S60329A Blister (nonthermal) of unspecified thumb, initial encounter',
  'S60329D Blister (nonthermal) of unspecified thumb, subsequent encounter',
  'S60329S Blister (nonthermal) of unspecified thumb, sequela',
  'S60341A External constriction of right thumb, initial encounter',
  'S60341D External constriction of right thumb, subsequent encounter',
  'S60341S External constriction of right thumb, sequela',
  'S60342A External constriction of left thumb, initial encounter',
  'S60342D External constriction of left thumb, subsequent encounter',
  'S60342S External constriction of left thumb, sequela',
  'S60349A External constriction of unspecified thumb, initial encounter',
  'S60349D External constriction of unspecified thumb, subsequent encounter',
  'S60349S External constriction of unspecified thumb, sequela',
  'S60351A Superficial foreign body of right thumb, initial encounter',
  'S60351D Superficial foreign body of right thumb, subsequent encounter',
  'S60351S Superficial foreign body of right thumb, sequela',
  'S60352A Superficial foreign body of left thumb, initial encounter',
  'S60352D Superficial foreign body of left thumb, subsequent encounter',
  'S60352S Superficial foreign body of left thumb, sequela',
  'S60359A Superficial foreign body of unspecified thumb, initial encounter',
  'S60359D Superficial foreign body of unspecified thumb, subsequent encounter',
  'S60359S Superficial foreign body of unspecified thumb, sequela',
  'S60361A Insect bite (nonvenomous) of right thumb, initial encounter',
  'S60361D Insect bite (nonvenomous) of right thumb, subsequent encounter',
  'S60361S Insect bite (nonvenomous) of right thumb, sequela',
  'S60362A Insect bite (nonvenomous) of left thumb, initial encounter',
  'S60362D Insect bite (nonvenomous) of left thumb, subsequent encounter',
  'S60362S Insect bite (nonvenomous) of left thumb, sequela',
  'S60369A Insect bite (nonvenomous) of unspecified thumb, initial encounter',
  'S60369D Insect bite (nonvenomous) of unspecified thumb, subsequent encounter',
  'S60369S Insect bite (nonvenomous) of unspecified thumb, sequela',
  'S60371A Other superficial bite of right thumb, initial encounter',
  'S60371D Other superficial bite of right thumb, subsequent encounter',
  'S60371S Other superficial bite of right thumb, sequela',
  'S60372A Other superficial bite of left thumb, initial encounter',
  'S60372D Other superficial bite of left thumb, subsequent encounter',
  'S60372S Other superficial bite of left thumb, sequela',
  'S60379A Other superficial bite of unspecified thumb, initial encounter',
  'S60379D Other superficial bite of unspecified thumb, subsequent encounter',
  'S60379S Other superficial bite of unspecified thumb, sequela',
  'S60391A Other superficial injuries of right thumb, initial encounter',
  'S60391D Other superficial injuries of right thumb, subsequent encounter',
  'S60391S Other superficial injuries of right thumb, sequela',
  'S60392A Other superficial injuries of left thumb, initial encounter',
  'S60392D Other superficial injuries of left thumb, subsequent encounter',
  'S60392S Other superficial injuries of left thumb, sequela',
  'S60399A Other superficial injuries of unspecified thumb, initial encounter',
  'S60399D Other superficial injuries of unspecified thumb, subsequent encounter',
  'S60399S Other superficial injuries of unspecified thumb, sequela',
  'S60410A Abrasion of right index finger, initial encounter',
  'S60410D Abrasion of right index finger, subsequent encounter',
  'S60410S Abrasion of right index finger, sequela',
  'S60411A Abrasion of left index finger, initial encounter',
  'S60411D Abrasion of left index finger, subsequent encounter',
  'S60411S Abrasion of left index finger, sequela',
  'S60412A Abrasion of right middle finger, initial encounter',
  'S60412D Abrasion of right middle finger, subsequent encounter',
  'S60412S Abrasion of right middle finger, sequela',
  'S60413A Abrasion of left middle finger, initial encounter',
  'S60413D Abrasion of left middle finger, subsequent encounter',
  'S60413S Abrasion of left middle finger, sequela',
  'S60414A Abrasion of right ring finger, initial encounter',
  'S60414D Abrasion of right ring finger, subsequent encounter',
  'S60414S Abrasion of right ring finger, sequela',
  'S60415A Abrasion of left ring finger, initial encounter',
  'S60415D Abrasion of left ring finger, subsequent encounter',
  'S60415S Abrasion of left ring finger, sequela',
  'S60416A Abrasion of right little finger, initial encounter',
  'S60416D Abrasion of right little finger, subsequent encounter',
  'S60416S Abrasion of right little finger, sequela',
  'S60417A Abrasion of left little finger, initial encounter',
  'S60417D Abrasion of left little finger, subsequent encounter',
  'S60417S Abrasion of left little finger, sequela',
  'S60418A Abrasion of other finger, initial encounter',
  'S60418D Abrasion of other finger, subsequent encounter',
  'S60418S Abrasion of other finger, sequela',
  'S60419A Abrasion of unspecified finger, initial encounter',
  'S60419D Abrasion of unspecified finger, subsequent encounter',
  'S60419S Abrasion of unspecified finger, sequela',
  'S60420A Blister (nonthermal) of right index finger, initial encounter',
  'S60420D Blister (nonthermal) of right index finger, subsequent encounter',
  'S60420S Blister (nonthermal) of right index finger, sequela',
  'S60421A Blister (nonthermal) of left index finger, initial encounter',
  'S60421D Blister (nonthermal) of left index finger, subsequent encounter',
  'S60421S Blister (nonthermal) of left index finger, sequela',
  'S60422A Blister (nonthermal) of right middle finger, initial encounter',
  'S60422D Blister (nonthermal) of right middle finger, subsequent encounter',
  'S60422S Blister (nonthermal) of right middle finger, sequela',
  'S60423A Blister (nonthermal) of left middle finger, initial encounter',
  'S60423D Blister (nonthermal) of left middle finger, subsequent encounter',
  'S60423S Blister (nonthermal) of left middle finger, sequela',
  'S60424A Blister (nonthermal) of right ring finger, initial encounter',
  'S60424D Blister (nonthermal) of right ring finger, subsequent encounter',
  'S60424S Blister (nonthermal) of right ring finger, sequela',
  'S60425A Blister (nonthermal) of left ring finger, initial encounter',
  'S60425D Blister (nonthermal) of left ring finger, subsequent encounter',
  'S60425S Blister (nonthermal) of left ring finger, sequela',
  'S60426A Blister (nonthermal) of right little finger, initial encounter',
  'S60426D Blister (nonthermal) of right little finger, subsequent encounter',
  'S60426S Blister (nonthermal) of right little finger, sequela',
  'S60427A Blister (nonthermal) of left little finger, initial encounter',
  'S60427D Blister (nonthermal) of left little finger, subsequent encounter',
  'S60427S Blister (nonthermal) of left little finger, sequela',
  'S60428A Blister (nonthermal) of other finger, initial encounter',
  'S60428D Blister (nonthermal) of other finger, subsequent encounter',
  'S60428S Blister (nonthermal) of other finger, sequela',
  'S60429A Blister (nonthermal) of unspecified finger, initial encounter',
  'S60429D Blister (nonthermal) of unspecified finger, subsequent encounter',
  'S60429S Blister (nonthermal) of unspecified finger, sequela',
  'S60440A External constriction of right index finger, initial encounter',
  'S60440D External constriction of right index finger, subsequent encounter',
  'S60440S External constriction of right index finger, sequela',
  'S60441A External constriction of left index finger, initial encounter',
  'S60441D External constriction of left index finger, subsequent encounter',
  'S60441S External constriction of left index finger, sequela',
  'S60442A External constriction of right middle finger, initial encounter',
  'S60442D External constriction of right middle finger, subsequent encounter',
  'S60442S External constriction of right middle finger, sequela',
  'S60443A External constriction of left middle finger, initial encounter',
  'S60443D External constriction of left middle finger, subsequent encounter',
  'S60443S External constriction of left middle finger, sequela',
  'S60444A External constriction of right ring finger, initial encounter',
  'S60444D External constriction of right ring finger, subsequent encounter',
  'S60444S External constriction of right ring finger, sequela',
  'S60445A External constriction of left ring finger, initial encounter',
  'S60445D External constriction of left ring finger, subsequent encounter',
  'S60445S External constriction of left ring finger, sequela',
  'S60446A External constriction of right little finger, initial encounter',
  'S60446D External constriction of right little finger, subsequent encounter',
  'S60446S External constriction of right little finger, sequela',
  'S60447A External constriction of left little finger, initial encounter',
  'S60447D External constriction of left little finger, subsequent encounter',
  'S60447S External constriction of left little finger, sequela',
  'S60448A External constriction of other finger, initial encounter',
  'S60448D External constriction of other finger, subsequent encounter',
  'S60448S External constriction of other finger, sequela',
  'S60449A External constriction of unspecified finger, initial encounter',
  'S60449D External constriction of unspecified finger, subsequent encounter',
  'S60449S External constriction of unspecified finger, sequela',
  'S60450A Superficial foreign body of right index finger, initial encounter',
  'S60450D Superficial foreign body of right index finger, subsequent encounter',
  'S60450S Superficial foreign body of right index finger, sequela',
  'S60451A Superficial foreign body of left index finger, initial encounter',
  'S60451D Superficial foreign body of left index finger, subsequent encounter',
  'S60451S Superficial foreign body of left index finger, sequela',
  'S60452A Superficial foreign body of right middle finger, initial encounter',
  'S60452D Superficial foreign body of right middle finger, subsequent encounter',
  'S60452S Superficial foreign body of right middle finger, sequela',
  'S60453A Superficial foreign body of left middle finger, initial encounter',
  'S60453D Superficial foreign body of left middle finger, subsequent encounter',
  'S60453S Superficial foreign body of left middle finger, sequela',
  'S60454A Superficial foreign body of right ring finger, initial encounter',
  'S60454D Superficial foreign body of right ring finger, subsequent encounter',
  'S60454S Superficial foreign body of right ring finger, sequela',
  'S60455A Superficial foreign body of left ring finger, initial encounter',
  'S60455D Superficial foreign body of left ring finger, subsequent encounter',
  'S60455S Superficial foreign body of left ring finger, sequela',
  'S60456A Superficial foreign body of right little finger, initial encounter',
  'S60456D Superficial foreign body of right little finger, subsequent encounter',
  'S60456S Superficial foreign body of right little finger, sequela',
  'S60457A Superficial foreign body of left little finger, initial encounter',
  'S60457D Superficial foreign body of left little finger, subsequent encounter',
  'S60457S Superficial foreign body of left little finger, sequela',
  'S60458A Superficial foreign body of other finger, initial encounter',
  'S60458D Superficial foreign body of other finger, subsequent encounter',
  'S60458S Superficial foreign body of other finger, sequela',
  'S60459A Superficial foreign body of unspecified finger, initial encounter',
  'S60459D Superficial foreign body of unspecified finger, subsequent encounter',
  'S60459S Superficial foreign body of unspecified finger, sequela',
  'S60460A Insect bite (nonvenomous) of right index finger, initial encounter',
  'S60460D Insect bite (nonvenomous) of right index finger, subsequent encounter',
  'S60460S Insect bite (nonvenomous) of right index finger, sequela',
  'S60461A Insect bite (nonvenomous) of left index finger, initial encounter',
  'S60461D Insect bite (nonvenomous) of left index finger, subsequent encounter',
  'S60461S Insect bite (nonvenomous) of left index finger, sequela',
  'S60462A Insect bite (nonvenomous) of right middle finger, initial encounter',
  'S60462D Insect bite (nonvenomous) of right middle finger, subsequent encounter',
  'S60462S Insect bite (nonvenomous) of right middle finger, sequela',
  'S60463A Insect bite (nonvenomous) of left middle finger, initial encounter',
  'S60463D Insect bite (nonvenomous) of left middle finger, subsequent encounter',
  'S60463S Insect bite (nonvenomous) of left middle finger, sequela',
  'S60464A Insect bite (nonvenomous) of right ring finger, initial encounter',
  'S60464D Insect bite (nonvenomous) of right ring finger, subsequent encounter',
  'S60464S Insect bite (nonvenomous) of right ring finger, sequela',
  'S60465A Insect bite (nonvenomous) of left ring finger, initial encounter',
  'S60465D Insect bite (nonvenomous) of left ring finger, subsequent encounter',
  'S60465S Insect bite (nonvenomous) of left ring finger, sequela',
  'S60466A Insect bite (nonvenomous) of right little finger, initial encounter',
  'S60466D Insect bite (nonvenomous) of right little finger, subsequent encounter',
  'S60466S Insect bite (nonvenomous) of right little finger, sequela',
  'S60467A Insect bite (nonvenomous) of left little finger, initial encounter',
  'S60467D Insect bite (nonvenomous) of left little finger, subsequent encounter',
  'S60467S Insect bite (nonvenomous) of left little finger, sequela',
  'S60468A Insect bite (nonvenomous) of other finger, initial encounter',
  'S60468D Insect bite (nonvenomous) of other finger, subsequent encounter',
  'S60468S Insect bite (nonvenomous) of other finger, sequela',
  'S60469A Insect bite (nonvenomous) of unspecified finger, initial encounter',
  'S60469D Insect bite (nonvenomous) of unspecified finger, subsequent encounter',
  'S60469S Insect bite (nonvenomous) of unspecified finger, sequela',
  'S60470A Other superficial bite of right index finger, initial encounter',
  'S60470D Other superficial bite of right index finger, subsequent encounter',
  'S60470S Other superficial bite of right index finger, sequela',
  'S60471A Other superficial bite of left index finger, initial encounter',
  'S60471D Other superficial bite of left index finger, subsequent encounter',
  'S60471S Other superficial bite of left index finger, sequela',
  'S60472A Other superficial bite of right middle finger, initial encounter',
  'S60472D Other superficial bite of right middle finger, subsequent encounter',
  'S60472S Other superficial bite of right middle finger, sequela',
  'S60473A Other superficial bite of left middle finger, initial encounter',
  'S60473D Other superficial bite of left middle finger, subsequent encounter',
  'S60473S Other superficial bite of left middle finger, sequela',
  'S60474A Other superficial bite of right ring finger, initial encounter',
  'S60474D Other superficial bite of right ring finger, subsequent encounter',
  'S60474S Other superficial bite of right ring finger, sequela',
  'S60475A Other superficial bite of left ring finger, initial encounter',
  'S60475D Other superficial bite of left ring finger, subsequent encounter',
  'S60475S Other superficial bite of left ring finger, sequela',
  'S60476A Other superficial bite of right little finger, initial encounter',
  'S60476D Other superficial bite of right little finger, subsequent encounter',
  'S60476S Other superficial bite of right little finger, sequela',
  'S60477A Other superficial bite of left little finger, initial encounter',
  'S60477D Other superficial bite of left little finger, subsequent encounter',
  'S60477S Other superficial bite of left little finger, sequela',
  'S60478A Other superficial bite of other finger, initial encounter',
  'S60478D Other superficial bite of other finger, subsequent encounter',
  'S60478S Other superficial bite of other finger, sequela',
  'S60479A Other superficial bite of unspecified finger, initial encounter',
  'S60479D Other superficial bite of unspecified finger, subsequent encounter',
  'S60479S Other superficial bite of unspecified finger, sequela',
  'S60511A Abrasion of right hand, initial encounter',
  'S60511D Abrasion of right hand, subsequent encounter',
  'S60511S Abrasion of right hand, sequela',
  'S60512A Abrasion of left hand, initial encounter',
  'S60512D Abrasion of left hand, subsequent encounter',
  'S60512S Abrasion of left hand, sequela',
  'S60519A Abrasion of unspecified hand, initial encounter',
  'S60519D Abrasion of unspecified hand, subsequent encounter',
  'S60519S Abrasion of unspecified hand, sequela',
  'S60521A Blister (nonthermal) of right hand, initial encounter',
  'S60521D Blister (nonthermal) of right hand, subsequent encounter',
  'S60521S Blister (nonthermal) of right hand, sequela',
  'S60522A Blister (nonthermal) of left hand, initial encounter',
  'S60522D Blister (nonthermal) of left hand, subsequent encounter',
  'S60522S Blister (nonthermal) of left hand, sequela',
  'S60529A Blister (nonthermal) of unspecified hand, initial encounter',
  'S60529D Blister (nonthermal) of unspecified hand, subsequent encounter',
  'S60529S Blister (nonthermal) of unspecified hand, sequela',
  'S60541A External constriction of right hand, initial encounter',
  'S60541D External constriction of right hand, subsequent encounter',
  'S60541S External constriction of right hand, sequela',
  'S60542A External constriction of left hand, initial encounter',
  'S60542D External constriction of left hand, subsequent encounter',
  'S60542S External constriction of left hand, sequela',
  'S60549A External constriction of unspecified hand, initial encounter',
  'S60549D External constriction of unspecified hand, subsequent encounter',
  'S60549S External constriction of unspecified hand, sequela',
  'S60551A Superficial foreign body of right hand, initial encounter',
  'S60551D Superficial foreign body of right hand, subsequent encounter',
  'S60551S Superficial foreign body of right hand, sequela',
  'S60552A Superficial foreign body of left hand, initial encounter',
  'S60552D Superficial foreign body of left hand, subsequent encounter',
  'S60552S Superficial foreign body of left hand, sequela',
  'S60559A Superficial foreign body of unspecified hand, initial encounter',
  'S60559D Superficial foreign body of unspecified hand, subsequent encounter',
  'S60559S Superficial foreign body of unspecified hand, sequela',
  'S60561A Insect bite (nonvenomous) of right hand, initial encounter',
  'S60561D Insect bite (nonvenomous) of right hand, subsequent encounter',
  'S60561S Insect bite (nonvenomous) of right hand, sequela',
  'S60562A Insect bite (nonvenomous) of left hand, initial encounter',
  'S60562D Insect bite (nonvenomous) of left hand, subsequent encounter',
  'S60562S Insect bite (nonvenomous) of left hand, sequela',
  'S60569A Insect bite (nonvenomous) of unspecified hand, initial encounter',
  'S60569D Insect bite (nonvenomous) of unspecified hand, subsequent encounter',
  'S60569S Insect bite (nonvenomous) of unspecified hand, sequela',
  'S60571A Other superficial bite of hand of right hand, initial encounter',
  'S60571D Other superficial bite of hand of right hand, subsequent encounter',
  'S60571S Other superficial bite of hand of right hand, sequela',
  'S60572A Other superficial bite of hand of left hand, initial encounter',
  'S60572D Other superficial bite of hand of left hand, subsequent encounter',
  'S60572S Other superficial bite of hand of left hand, sequela',
  'S60579A Other superficial bite of hand of unspecified hand, initial encounter',
  'S60579D Other superficial bite of hand of unspecified hand, subsequent encounter',
  'S60579S Other superficial bite of hand of unspecified hand, sequela',
  'S60811A Abrasion of right wrist, initial encounter',
  'S60811D Abrasion of right wrist, subsequent encounter',
  'S60811S Abrasion of right wrist, sequela',
  'S60812A Abrasion of left wrist, initial encounter',
  'S60812D Abrasion of left wrist, subsequent encounter',
  'S60812S Abrasion of left wrist, sequela',
  'S60819A Abrasion of unspecified wrist, initial encounter',
  'S60819D Abrasion of unspecified wrist, subsequent encounter',
  'S60819S Abrasion of unspecified wrist, sequela',
  'S60821A Blister (nonthermal) of right wrist, initial encounter',
  'S60821D Blister (nonthermal) of right wrist, subsequent encounter',
  'S60821S Blister (nonthermal) of right wrist, sequela',
  'S60822A Blister (nonthermal) of left wrist, initial encounter',
  'S60822D Blister (nonthermal) of left wrist, subsequent encounter',
  'S60822S Blister (nonthermal) of left wrist, sequela',
  'S60829A Blister (nonthermal) of unspecified wrist, initial encounter',
  'S60829D Blister (nonthermal) of unspecified wrist, subsequent encounter',
  'S60829S Blister (nonthermal) of unspecified wrist, sequela',
  'S60841A External constriction of right wrist, initial encounter',
  'S60841D External constriction of right wrist, subsequent encounter',
  'S60841S External constriction of right wrist, sequela',
  'S60842A External constriction of left wrist, initial encounter',
  'S60842D External constriction of left wrist, subsequent encounter',
  'S60842S External constriction of left wrist, sequela',
  'S60849A External constriction of unspecified wrist, initial encounter',
  'S60849D External constriction of unspecified wrist, subsequent encounter',
  'S60849S External constriction of unspecified wrist, sequela',
  'S60851A Superficial foreign body of right wrist, initial encounter',
  'S60851D Superficial foreign body of right wrist, subsequent encounter',
  'S60851S Superficial foreign body of right wrist, sequela',
  'S60852A Superficial foreign body of left wrist, initial encounter',
  'S60852D Superficial foreign body of left wrist, subsequent encounter',
  'S60852S Superficial foreign body of left wrist, sequela',
  'S60859A Superficial foreign body of unspecified wrist, initial encounter',
  'S60859D Superficial foreign body of unspecified wrist, subsequent encounter',
  'S60859S Superficial foreign body of unspecified wrist, sequela',
  'S60861A Insect bite (nonvenomous) of right wrist, initial encounter',
  'S60861D Insect bite (nonvenomous) of right wrist, subsequent encounter',
  'S60861S Insect bite (nonvenomous) of right wrist, sequela',
  'S60862A Insect bite (nonvenomous) of left wrist, initial encounter',
  'S60862D Insect bite (nonvenomous) of left wrist, subsequent encounter',
  'S60862S Insect bite (nonvenomous) of left wrist, sequela',
  'S60869A Insect bite (nonvenomous) of unspecified wrist, initial encounter',
  'S60869D Insect bite (nonvenomous) of unspecified wrist, subsequent encounter',
  'S60869S Insect bite (nonvenomous) of unspecified wrist, sequela',
  'S60871A Other superficial bite of right wrist, initial encounter',
  'S60871D Other superficial bite of right wrist, subsequent encounter',
  'S60871S Other superficial bite of right wrist, sequela',
  'S60872A Other superficial bite of left wrist, initial encounter',
  'S60872D Other superficial bite of left wrist, subsequent encounter',
  'S60872S Other superficial bite of left wrist, sequela',
  'S60879A Other superficial bite of unspecified wrist, initial encounter',
  'S60879D Other superficial bite of unspecified wrist, subsequent encounter',
  'S60879S Other superficial bite of unspecified wrist, sequela',
  'S60911A Unspecified superficial injury of right wrist, initial encounter',
  'S60911D Unspecified superficial injury of right wrist, subsequent encounter',
  'S60911S Unspecified superficial injury of right wrist, sequela',
  'S60912A Unspecified superficial injury of left wrist, initial encounter',
  'S60912D Unspecified superficial injury of left wrist, subsequent encounter',
  'S60912S Unspecified superficial injury of left wrist, sequela',
  'S60919A Unspecified superficial injury of unspecified wrist, initial encounter',
  'S60919D Unspecified superficial injury of unspecified wrist, subsequent encounter',
  'S60919S Unspecified superficial injury of unspecified wrist, sequela',
  'S60921A Unspecified superficial injury of right hand, initial encounter',
  'S60921D Unspecified superficial injury of right hand, subsequent encounter',
  'S60921S Unspecified superficial injury of right hand, sequela',
  'S60922A Unspecified superficial injury of left hand, initial encounter',
  'S60922D Unspecified superficial injury of left hand, subsequent encounter',
  'S60922S Unspecified superficial injury of left hand, sequela',
  'S60929A Unspecified superficial injury of unspecified hand, initial encounter',
  'S60929D Unspecified superficial injury of unspecified hand, subsequent encounter',
  'S60929S Unspecified superficial injury of unspecified hand, sequela',
  'S60931A Unspecified superficial injury of right thumb, initial encounter',
  'S60931D Unspecified superficial injury of right thumb, subsequent encounter',
  'S60931S Unspecified superficial injury of right thumb, sequela',
  'S60932A Unspecified superficial injury of left thumb, initial encounter',
  'S60932D Unspecified superficial injury of left thumb, subsequent encounter',
  'S60932S Unspecified superficial injury of left thumb, sequela',
  'S60939A Unspecified superficial injury of unspecified thumb, initial encounter',
  'S60939D Unspecified superficial injury of unspecified thumb, subsequent encounter',
  'S60939S Unspecified superficial injury of unspecified thumb, sequela',
  'S60940A Unspecified superficial injury of right index finger, initial encounter',
  'S60940D Unspecified superficial injury of right index finger, subsequent encounter',
  'S60940S Unspecified superficial injury of right index finger, sequela',
  'S60941A Unspecified superficial injury of left index finger, initial encounter',
  'S60941D Unspecified superficial injury of left index finger, subsequent encounter',
  'S60941S Unspecified superficial injury of left index finger, sequela',
  'S60942A Unspecified superficial injury of right middle finger, initial encounter',
  'S60942D Unspecified superficial injury of right middle finger, subsequent encounter',
  'S60942S Unspecified superficial injury of right middle finger, sequela',
  'S60943A Unspecified superficial injury of left middle finger, initial encounter',
  'S60943D Unspecified superficial injury of left middle finger, subsequent encounter',
  'S60943S Unspecified superficial injury of left middle finger, sequela',
  'S60944A Unspecified superficial injury of right ring finger, initial encounter',
  'S60944D Unspecified superficial injury of right ring finger, subsequent encounter',
  'S60944S Unspecified superficial injury of right ring finger, sequela',
  'S60945A Unspecified superficial injury of left ring finger, initial encounter',
  'S60945D Unspecified superficial injury of left ring finger, subsequent encounter',
  'S60945S Unspecified superficial injury of left ring finger, sequela',
  'S60946A Unspecified superficial injury of right little finger, initial encounter',
  'S60946D Unspecified superficial injury of right little finger, subsequent encounter',
  'S60946S Unspecified superficial injury of right little finger, sequela',
  'S60947A Unspecified superficial injury of left little finger, initial encounter',
  'S60947D Unspecified superficial injury of left little finger, subsequent encounter',
  'S60947S Unspecified superficial injury of left little finger, sequela',
  'S60948A Unspecified superficial injury of other finger, initial encounter',
  'S60948D Unspecified superficial injury of other finger, subsequent encounter',
  'S60948S Unspecified superficial injury of other finger, sequela',
  'S60949A Unspecified superficial injury of unspecified finger, initial encounter',
  'S60949D Unspecified superficial injury of unspecified finger, subsequent encounter',
  'S60949S Unspecified superficial injury of unspecified finger, sequela',
  'S61001A Unspecified open wound of right thumb without damage to nail, initial encounter',
  'S61001D Unspecified open wound of right thumb without damage to nail, subsequent encounter',
  'S61001S Unspecified open wound of right thumb without damage to nail, sequela',
  'S61002A Unspecified open wound of left thumb without damage to nail, initial encounter',
  'S61002D Unspecified open wound of left thumb without damage to nail, subsequent encounter',
  'S61002S Unspecified open wound of left thumb without damage to nail, sequela',
  'S61009A Unspecified open wound of unspecified thumb without damage to nail, initial encounter',
  'S61009D Unspecified open wound of unspecified thumb without damage to nail, subsequent encounter',
  'S61009S Unspecified open wound of unspecified thumb without damage to nail, sequela',
  'S61011A Laceration without foreign body of right thumb without damage to nail, initial encounter',
  'S61011D Laceration without foreign body of right thumb without damage to nail, subsequent encounter',
  'S61011S Laceration without foreign body of right thumb without damage to nail, sequela',
  'S61012A Laceration without foreign body of left thumb without damage to nail, initial encounter',
  'S61012D Laceration without foreign body of left thumb without damage to nail, subsequent encounter',
  'S61012S Laceration without foreign body of left thumb without damage to nail, sequela',
  'S61019A Laceration without foreign body of unspecified thumb without damage to nail, initial',
  'encounter',
  'S61019D Laceration without foreign body of unspecified thumb without damage to nail, subsequent',
  'encounter',
  'S61019S Laceration without foreign body of unspecified thumb without damage to nail, sequela',
  'S61021A Laceration with foreign body of right thumb without damage to nail, initial encounter',
  'S61021D Laceration with foreign body of right thumb without damage to nail, subsequent encounter',
  'S61021S Laceration with foreign body of right thumb without damage to nail, sequela',
  'S61022A Laceration with foreign body of left thumb without damage to nail, initial encounter',
  'S61022D Laceration with foreign body of left thumb without damage to nail, subsequent encounter',
  'S61022S Laceration with foreign body of left thumb without damage to nail, sequela',
  'S61029A Laceration with foreign body of unspecified thumb without damage to nail, initial encounter',
  'S61029D Laceration with foreign body of unspecified thumb without damage to nail, subsequent',
  'encounter',
  'S61029S Laceration with foreign body of unspecified thumb without damage to nail, sequela',
  'S61031A Puncture wound without foreign body of right thumb without damage to nail, initial encounter',
  'S61031D Puncture wound without foreign body of right thumb without damage to nail, subsequent',
  'encounter',
  'S61031S Puncture wound without foreign body of right thumb without damage to nail, sequela',
  'S61032A Puncture wound without foreign body of left thumb without damage to nail, initial encounter',
  'S61032D Puncture wound without foreign body of left thumb without damage to nail, subsequent',
  'encounter',
  'S61032S Puncture wound without foreign body of left thumb without damage to nail, sequela',
  'S61039A Puncture wound without foreign body of unspecified thumb without damage to nail, initial',
  'encounter',
  'S61039D Puncture wound without foreign body of unspecified thumb without damage to nail,',
  'subsequent encounter',
  'S61039S Puncture wound without foreign body of unspecified thumb without damage to nail, sequela',
  'S61041A Puncture wound with foreign body of right thumb without damage to nail, initial encounter',
  'S61041D Puncture wound with foreign body of right thumb without damage to nail, subsequent',
  'encounter',
  'S61041S Puncture wound with foreign body of right thumb without damage to nail, sequela',
  'S61042A Puncture wound with foreign body of left thumb without damage to nail, initial encounter',
  'S61042D Puncture wound with foreign body of left thumb without damage to nail, subsequent',
  'encounter',
  'S61042S Puncture wound with foreign body of left thumb without damage to nail, sequela',
  'S61049A Puncture wound with foreign body of unspecified thumb without damage to nail, initial',
  'encounter',
  'S61049D Puncture wound with foreign body of unspecified thumb without damage to nail, subsequent',
  'encounter',
  'S61049S Puncture wound with foreign body of unspecified thumb without damage to nail, sequela',
  'S61051A Open bite of right thumb without damage to nail, initial encounter',
  'S61051D Open bite of right thumb without damage to nail, subsequent encounter',
  'S61051S Open bite of right thumb without damage to nail, sequela',
  'S61052A Open bite of left thumb without damage to nail, initial encounter',
  'S61052D Open bite of left thumb without damage to nail, subsequent encounter',
  'S61052S Open bite of left thumb without damage to nail, sequela',
  'S61059A Open bite of unspecified thumb without damage to nail, initial encounter',
  'S61059D Open bite of unspecified thumb without damage to nail, subsequent encounter',
  'S61059S Open bite of unspecified thumb without damage to nail, sequela',
  'S61101A Unspecified open wound of right thumb with damage to nail, initial encounter',
  'S61101D Unspecified open wound of right thumb with damage to nail, subsequent encounter',
  'S61101S Unspecified open wound of right thumb with damage to nail, sequela',
  'S61102A Unspecified open wound of left thumb with damage to nail, initial encounter',
  'S61102D Unspecified open wound of left thumb with damage to nail, subsequent encounter',
  'S61102S Unspecified open wound of left thumb with damage to nail, sequela',
  'S61109A Unspecified open wound of unspecified thumb with damage to nail, initial encounter',
  'S61109D Unspecified open wound of unspecified thumb with damage to nail, subsequent encounter',
  'S61109S Unspecified open wound of unspecified thumb with damage to nail, sequela',
  'S61111A Laceration without foreign body of right thumb with damage to nail, initial encounter',
  'S61111D Laceration without foreign body of right thumb with damage to nail, subsequent encounter',
  'S61111S Laceration without foreign body of right thumb with damage to nail, sequela',
  'S61112A Laceration without foreign body of left thumb with damage to nail, initial encounter',
  'S61112D Laceration without foreign body of left thumb with damage to nail, subsequent encounter',
  'S61112S Laceration without foreign body of left thumb with damage to nail, sequela',
  'S61119A Laceration without foreign body of unspecified thumb with damage to nail, initial encounter',
  'S61119D Laceration without foreign body of unspecified thumb with damage to nail, subsequent',
  'encounter',
  'S61119S Laceration without foreign body of unspecified thumb with damage to nail, sequela',
  'S61121A Laceration with foreign body of right thumb with damage to nail, initial encounter',
  'S61121D Laceration with foreign body of right thumb with damage to nail, subsequent encounter',
  'S61121S Laceration with foreign body of right thumb with damage to nail, sequela',
  'S61122A Laceration with foreign body of left thumb with damage to nail, initial encounter',
  'S61122D Laceration with foreign body of left thumb with damage to nail, subsequent encounter',
  'S61122S Laceration with foreign body of left thumb with damage to nail, sequela',
  'S61129A Laceration with foreign body of unspecified thumb with damage to nail, initial encounter',
  'S61129D Laceration with foreign body of unspecified thumb with damage to nail, subsequent encounter',
  'S61129S Laceration with foreign body of unspecified thumb with damage to nail, sequela',
  'S61131A Puncture wound without foreign body of right thumb with damage to nail, initial encounter',
  'S61131D Puncture wound without foreign body of right thumb with damage to nail, subsequent',
  'encounter',
  'S61131S Puncture wound without foreign body of right thumb with damage to nail, sequela',
  'S61132A Puncture wound without foreign body of left thumb with damage to nail, initial encounter',
  'S61132D Puncture wound without foreign body of left thumb with damage to nail, subsequent',
  'encounter',
  'S61132S Puncture wound without foreign body of left thumb with damage to nail, sequela',
  'S61139A Puncture wound without foreign body of unspecified thumb with damage to nail, initial',
  'encounter',
  'S61139D Puncture wound without foreign body of unspecified thumb with damage to nail, subsequent',
  'encounter',
  'S61139S Puncture wound without foreign body of unspecified thumb with damage to nail, sequela',
  'S61141A Puncture wound with foreign body of right thumb with damage to nail, initial encounter',
  'S61141D Puncture wound with foreign body of right thumb with damage to nail, subsequent encounter',
  'S61141S Puncture wound with foreign body of right thumb with damage to nail, sequela',
  'S61142A Puncture wound with foreign body of left thumb with damage to nail, initial encounter',
  'S61142D Puncture wound with foreign body of left thumb with damage to nail, subsequent encounter',
  'S61142S Puncture wound with foreign body of left thumb with damage to nail, sequela',
  'S61149A Puncture wound with foreign body of unspecified thumb with damage to nail, initial encounter',
  'S61149D Puncture wound with foreign body of unspecified thumb with damage to nail, subsequent',
  'encounter',
  'S61149S Puncture wound with foreign body of unspecified thumb with damage to nail, sequela',
  'S61151A Open bite of right thumb with damage to nail, initial encounter',
  'S61151D Open bite of right thumb with damage to nail, subsequent encounter',
  'S61151S Open bite of right thumb with damage to nail, sequela',
  'S61152A Open bite of left thumb with damage to nail, initial encounter',
  'S61152D Open bite of left thumb with damage to nail, subsequent encounter',
  'S61152S Open bite of left thumb with damage to nail, sequela',
  'S61159A Open bite of unspecified thumb with damage to nail, initial encounter',
  'S61159D Open bite of unspecified thumb with damage to nail, subsequent encounter',
  'S61159S Open bite of unspecified thumb with damage to nail, sequela',
  'S61200A Unspecified open wound of right index finger without damage to nail, initial encounter',
  'S61200D Unspecified open wound of right index finger without damage to nail, subsequent encounter',
  'S61200S Unspecified open wound of right index finger without damage to nail, sequela',
  'S61201A Unspecified open wound of left index finger without damage to nail, initial encounter',
  'S61201D Unspecified open wound of left index finger without damage to nail, subsequent encounter',
  'S61201S Unspecified open wound of left index finger without damage to nail, sequela',
  'S61202A Unspecified open wound of right middle finger without damage to nail, initial encounter',
  'S61202D Unspecified open wound of right middle finger without damage to nail, subsequent encounter',
  'S61202S Unspecified open wound of right middle finger without damage to nail, sequela',
  'S61203A Unspecified open wound of left middle finger without damage to nail, initial encounter',
  'S61203D Unspecified open wound of left middle finger without damage to nail, subsequent encounter',
  'S61203S Unspecified open wound of left middle finger without damage to nail, sequela',
  'S61204A Unspecified open wound of right ring finger without damage to nail, initial encounter',
  'S61204D Unspecified open wound of right ring finger without damage to nail, subsequent encounter',
  'S61204S Unspecified open wound of right ring finger without damage to nail, sequela',
  'S61205A Unspecified open wound of left ring finger without damage to nail, initial encounter',
  'S61205D Unspecified open wound of left ring finger without damage to nail, subsequent encounter',
  'S61205S Unspecified open wound of left ring finger without damage to nail, sequela',
  'S61206A Unspecified open wound of right little finger without damage to nail, initial encounter',
  'S61206D Unspecified open wound of right little finger without damage to nail, subsequent encounter',
  'S61206S Unspecified open wound of right little finger without damage to nail, sequela',
  'S61207A Unspecified open wound of left little finger without damage to nail, initial encounter',
  'S61207D Unspecified open wound of left little finger without damage to nail, subsequent encounter',
  'S61207S Unspecified open wound of left little finger without damage to nail, sequela',
  'S61208A Unspecified open wound of other finger without damage to nail, initial encounter',
  'S61208D Unspecified open wound of other finger without damage to nail, subsequent encounter',
  'S61208S Unspecified open wound of other finger without damage to nail, sequela',
  'S61209A Unspecified open wound of unspecified finger without damage to nail, initial encounter',
  'S61209D Unspecified open wound of unspecified finger without damage to nail, subsequent encounter',
  'S61209S Unspecified open wound of unspecified finger without damage to nail, sequela',
  'S61210A Laceration without foreign body of right index finger without damage to nail, initial encounter',
  'S61210D Laceration without foreign body of right index finger without damage to nail, subsequent',
  'encounter',
  'S61210S Laceration without foreign body of right index finger without damage to nail, sequela',
  'S61211A Laceration without foreign body of left index finger without damage to nail, initial encounter',
  'S61211D Laceration without foreign body of left index finger without damage to nail, subsequent',
  'encounter',
  'S61211S Laceration without foreign body of left index finger without damage to nail, sequela',
  'S61212A Laceration without foreign body of right middle finger without damage to nail, initial encounter',
  'S61212D Laceration without foreign body of right middle finger without damage to nail, subsequent',
  'encounter',
  'S61212S Laceration without foreign body of right middle finger without damage to nail, sequela',
  'S61213A Laceration without foreign body of left middle finger without damage to nail, initial encounter',
  'S61213D Laceration without foreign body of left middle finger without damage to nail, subsequent',
  'encounter',
  'S61213S Laceration without foreign body of left middle finger without damage to nail, sequela',
  'S61214A Laceration without foreign body of right ring finger without damage to nail, initial encounter',
  'S61214D Laceration without foreign body of right ring finger without damage to nail, subsequent',
  'encounter',
  'S61214S Laceration without foreign body of right ring finger without damage to nail, sequela',
  'S61215A Laceration without foreign body of left ring finger without damage to nail, initial encounter',
  'S61215D Laceration without foreign body of left ring finger without damage to nail, subsequent',
  'encounter',
  'S61215S Laceration without foreign body of left ring finger without damage to nail, sequela',
  'S61216A Laceration without foreign body of right little finger without damage to nail, initial encounter',
  'S61216D Laceration without foreign body of right little finger without damage to nail, subsequent',
  'encounter',
  'S61216S Laceration without foreign body of right little finger without damage to nail, sequela',
  'S61217A Laceration without foreign body of left little finger without damage to nail, initial encounter',
  'S61217D Laceration without foreign body of left little finger without damage to nail, subsequent',
  'encounter',
  'S61217S Laceration without foreign body of left little finger without damage to nail, sequela',
  'S61218A Laceration without foreign body of other finger without damage to nail, initial encounter',
  'S61218D Laceration without foreign body of other finger without damage to nail, subsequent encounter',
  'S61218S Laceration without foreign body of other finger without damage to nail, sequela',
  'S61219A Laceration without foreign body of unspecified finger without damage to nail, initial encounter',
  'S61219D Laceration without foreign body of unspecified finger without damage to nail, subsequent',
  'encounter',
  'S61219S Laceration without foreign body of unspecified finger without damage to nail, sequela',
  'S61220A Laceration with foreign body of right index finger without damage to nail, initial encounter',
  'S61220D Laceration with foreign body of right index finger without damage to nail, subsequent',
  'encounter',
  'S61220S Laceration with foreign body of right index finger without damage to nail, sequela',
  'S61221A Laceration with foreign body of left index finger without damage to nail, initial encounter',
  'S61221D Laceration with foreign body of left index finger without damage to nail, subsequent encounter',
  'S61221S Laceration with foreign body of left index finger without damage to nail, sequela',
  'S61222A Laceration with foreign body of right middle finger without damage to nail, initial encounter',
  'S61222D Laceration with foreign body of right middle finger without damage to nail, subsequent',
  'encounter',
  'S61222S Laceration with foreign body of right middle finger without damage to nail, sequela',
  'S61223A Laceration with foreign body of left middle finger without damage to nail, initial encounter',
  'S61223D Laceration with foreign body of left middle finger without damage to nail, subsequent',
  'encounter',
  'S61223S Laceration with foreign body of left middle finger without damage to nail, sequela',
  'S61224A Laceration with foreign body of right ring finger without damage to nail, initial encounter',
  'S61224D Laceration with foreign body of right ring finger without damage to nail, subsequent encounter',
  'S61224S Laceration with foreign body of right ring finger without damage to nail, sequela',
  'S61225A Laceration with foreign body of left ring finger without damage to nail, initial encounter',
  'S61225D Laceration with foreign body of left ring finger without damage to nail, subsequent encounter',
  'S61225S Laceration with foreign body of left ring finger without damage to nail, sequela',
  'S61226A Laceration with foreign body of right little finger without damage to nail, initial encounter',
  'S61226D Laceration with foreign body of right little finger without damage to nail, subsequent',
  'encounter',
  'S61226S Laceration with foreign body of right little finger without damage to nail, sequela',
  'S61227A Laceration with foreign body of left little finger without damage to nail, initial encounter',
  'S61227D Laceration with foreign body of left little finger without damage to nail, subsequent encounter',
  'S61227S Laceration with foreign body of left little finger without damage to nail, sequela',
  'S61228A Laceration with foreign body of other finger without damage to nail, initial encounter',
  'S61228D Laceration with foreign body of other finger without damage to nail, subsequent encounter',
  'S61228S Laceration with foreign body of other finger without damage to nail, sequela',
  'S61229A Laceration with foreign body of unspecified finger without damage to nail, initial encounter',
  'S61229D Laceration with foreign body of unspecified finger without damage to nail, subsequent',
  'encounter',
  'S61229S Laceration with foreign body of unspecified finger without damage to nail, sequela',
  'S61230A Puncture wound without foreign body of right index finger without damage to nail, initial',
  'encounter',
  'S61230D Puncture wound without foreign body of right index finger without damage to nail, subsequent',
  'encounter',
  'S61230S Puncture wound without foreign body of right index finger without damage to nail, sequela',
  'S61231A Puncture wound without foreign body of left index finger without damage to nail, initial',
  'encounter',
  'S61231D Puncture wound without foreign body of left index finger without damage to nail, subsequent',
  'encounter',
  'S61231S Puncture wound without foreign body of left index finger without damage to nail, sequela',
  'S61232A Puncture wound without foreign body of right middle finger without damage to nail, initial',
  'encounter',
  'S61232D Puncture wound without foreign body of right middle finger without damage to nail,',
  'subsequent encounter',
  'S61232S Puncture wound without foreign body of right middle finger without damage to nail, sequela',
  'S61233A Puncture wound without foreign body of left middle finger without damage to nail, initial',
  'encounter',
  'S61233D Puncture wound without foreign body of left middle finger without damage to nail,',
  'subsequent encounter',
  'S61233S Puncture wound without foreign body of left middle finger without damage to nail, sequela',
  'S61234A Puncture wound without foreign body of right ring finger without damage to nail, initial',
  'encounter',
  'S61234D Puncture wound without foreign body of right ring finger without damage to nail, subsequent',
  'encounter',
  'S61234S Puncture wound without foreign body of right ring finger without damage to nail, sequela',
  'S61235A Puncture wound without foreign body of left ring finger without damage to nail, initial',
  'encounter',
  'S61235D Puncture wound without foreign body of left ring finger without damage to nail, subsequent',
  'encounter',
  'S61235S Puncture wound without foreign body of left ring finger without damage to nail, sequela',
  'S61236A Puncture wound without foreign body of right little finger without damage to nail, initial',
  'encounter',
  'S61236D Puncture wound without foreign body of right little finger without damage to nail, subsequent',
  'encounter',
  'S61236S Puncture wound without foreign body of right little finger without damage to nail, sequela',
  'S61237A Puncture wound without foreign body of left little finger without damage to nail, initial',
  'encounter',
  'S61237D Puncture wound without foreign body of left little finger without damage to nail, subsequent',
  'encounter',
  'S61237S Puncture wound without foreign body of left little finger without damage to nail, sequela',
  'S61238A Puncture wound without foreign body of other finger without damage to nail, initial encounter',
  'S61238D Puncture wound without foreign body of other finger without damage to nail, subsequent',
  'encounter',
  'S61238S Puncture wound without foreign body of other finger without damage to nail, sequela',
  'S61239A Puncture wound without foreign body of unspecified finger without damage to nail, initial',
  'encounter',
  'S61239D Puncture wound without foreign body of unspecified finger without damage to nail,',
  'subsequent encounter',
  'S61239S Puncture wound without foreign body of unspecified finger without damage to nail, sequela',
  'S61240A Puncture wound with foreign body of right index finger without damage to nail, initial',
  'encounter',
  'S61240D Puncture wound with foreign body of right index finger without damage to nail, subsequent',
  'encounter',
  'S61240S Puncture wound with foreign body of right index finger without damage to nail, sequela',
  'S61241A Puncture wound with foreign body of left index finger without damage to nail, initial encounter',
  'S61241D Puncture wound with foreign body of left index finger without damage to nail, subsequent',
  'encounter',
  'S61241S Puncture wound with foreign body of left index finger without damage to nail, sequela',
  'S61242A Puncture wound with foreign body of right middle finger without damage to nail, initial',
  'encounter',
  'S61242D Puncture wound with foreign body of right middle finger without damage to nail, subsequent',
  'encounter',
  'S61242S Puncture wound with foreign body of right middle finger without damage to nail, sequela',
  'S61243A Puncture wound with foreign body of left middle finger without damage to nail, initial',
  'encounter',
  'S61243D Puncture wound with foreign body of left middle finger without damage to nail, subsequent',
  'encounter',
  'S61243S Puncture wound with foreign body of left middle finger without damage to nail, sequela',
  'S61244A Puncture wound with foreign body of right ring finger without damage to nail, initial encounter',
  'S61244D Puncture wound with foreign body of right ring finger without damage to nail, subsequent',
  'encounter',
  'S61244S Puncture wound with foreign body of right ring finger without damage to nail, sequela',
  'S61245A Puncture wound with foreign body of left ring finger without damage to nail, initial encounter',
  'S61245D Puncture wound with foreign body of left ring finger without damage to nail, subsequent',
  'encounter',
  'S61245S Puncture wound with foreign body of left ring finger without damage to nail, sequela',
  'S61246A Puncture wound with foreign body of right little finger without damage to nail, initial',
  'encounter',
  'S61246D Puncture wound with foreign body of right little finger without damage to nail, subsequent',
  'encounter',
  'S61246S Puncture wound with foreign body of right little finger without damage to nail, sequela',
  'S61247A Puncture wound with foreign body of left little finger without damage to nail, initial encounter',
  'S61247D Puncture wound with foreign body of left little finger without damage to nail, subsequent',
  'encounter',
  'S61247S Puncture wound with foreign body of left little finger without damage to nail, sequela',
  'S61248A Puncture wound with foreign body of other finger without damage to nail, initial encounter',
  'S61248D Puncture wound with foreign body of other finger without damage to nail, subsequent',
  'encounter',
  'S61248S Puncture wound with foreign body of other finger without damage to nail, sequela',
  'S61249A Puncture wound with foreign body of unspecified finger without damage to nail, initial',
  'encounter',
  'S61249D Puncture wound with foreign body of unspecified finger without damage to nail, subsequent',
  'encounter',
  'S61249S Puncture wound with foreign body of unspecified finger without damage to nail, sequela',
  'S61250A Open bite of right index finger without damage to nail, initial encounter',
  'S61250D Open bite of right index finger without damage to nail, subsequent encounter',
  'S61250S Open bite of right index finger without damage to nail, sequela',
  'S61251A Open bite of left index finger without damage to nail, initial encounter',
  'S61251D Open bite of left index finger without damage to nail, subsequent encounter',
  'S61251S Open bite of left index finger without damage to nail, sequela',
  'S61252A Open bite of right middle finger without damage to nail, initial encounter',
  'S61252D Open bite of right middle finger without damage to nail, subsequent encounter',
  'S61252S Open bite of right middle finger without damage to nail, sequela',
  'S61253A Open bite of left middle finger without damage to nail, initial encounter',
  'S61253D Open bite of left middle finger without damage to nail, subsequent encounter',
  'S61253S Open bite of left middle finger without damage to nail, sequela',
  'S61254A Open bite of right ring finger without damage to nail, initial encounter',
  'S61254D Open bite of right ring finger without damage to nail, subsequent encounter',
  'S61254S Open bite of right ring finger without damage to nail, sequela',
  'S61255A Open bite of left ring finger without damage to nail, initial encounter',
  'S61255D Open bite of left ring finger without damage to nail, subsequent encounter',
  'S61255S Open bite of left ring finger without damage to nail, sequela',
  'S61256A Open bite of right little finger without damage to nail, initial encounter',
  'S61256D Open bite of right little finger without damage to nail, subsequent encounter',
  'S61256S Open bite of right little finger without damage to nail, sequela',
  'S61257A Open bite of left little finger without damage to nail, initial encounter',
  'S61257D Open bite of left little finger without damage to nail, subsequent encounter',
  'S61257S Open bite of left little finger without damage to nail, sequela',
  'S61258A Open bite of other finger without damage to nail, initial encounter',
  'S61258D Open bite of other finger without damage to nail, subsequent encounter',
  'S61258S Open bite of other finger without damage to nail, sequela',
  'S61259A Open bite of unspecified finger without damage to nail, initial encounter',
  'S61259D Open bite of unspecified finger without damage to nail, subsequent encounter',
  'S61259S Open bite of unspecified finger without damage to nail, sequela',
  'S61300A Unspecified open wound of right index finger with damage to nail, initial encounter',
  'S61300D Unspecified open wound of right index finger with damage to nail, subsequent encounter',
  'S61300S Unspecified open wound of right index finger with damage to nail, sequela',
  'S61301A Unspecified open wound of left index finger with damage to nail, initial encounter',
  'S61301D Unspecified open wound of left index finger with damage to nail, subsequent encounter',
  'S61301S Unspecified open wound of left index finger with damage to nail, sequela',
  'S61302A Unspecified open wound of right middle finger with damage to nail, initial encounter',
  'S61302D Unspecified open wound of right middle finger with damage to nail, subsequent encounter',
  'S61302S Unspecified open wound of right middle finger with damage to nail, sequela',
  'S61303A Unspecified open wound of left middle finger with damage to nail, initial encounter',
  'S61303D Unspecified open wound of left middle finger with damage to nail, subsequent encounter',
  'S61303S Unspecified open wound of left middle finger with damage to nail, sequela',
  'S61304A Unspecified open wound of right ring finger with damage to nail, initial encounter',
  'S61304D Unspecified open wound of right ring finger with damage to nail, subsequent encounter',
  'S61304S Unspecified open wound of right ring finger with damage to nail, sequela',
  'S61305A Unspecified open wound of left ring finger with damage to nail, initial encounter',
  'S61305D Unspecified open wound of left ring finger with damage to nail, subsequent encounter',
  'S61305S Unspecified open wound of left ring finger with damage to nail, sequela',
  'S61306A Unspecified open wound of right little finger with damage to nail, initial encounter',
  'S61306D Unspecified open wound of right little finger with damage to nail, subsequent encounter',
  'S61306S Unspecified open wound of right little finger with damage to nail, sequela',
  'S61307A Unspecified open wound of left little finger with damage to nail, initial encounter',
  'S61307D Unspecified open wound of left little finger with damage to nail, subsequent encounter',
  'S61307S Unspecified open wound of left little finger with damage to nail, sequela',
  'S61308A Unspecified open wound of other finger with damage to nail, initial encounter',
  'S61308D Unspecified open wound of other finger with damage to nail, subsequent encounter',
  'S61308S Unspecified open wound of other finger with damage to nail, sequela',
  'S61309A Unspecified open wound of unspecified finger with damage to nail, initial encounter',
  'S61309D Unspecified open wound of unspecified finger with damage to nail, subsequent encounter',
  'S61309S Unspecified open wound of unspecified finger with damage to nail, sequela',
  'S61310A Laceration without foreign body of right index finger with damage to nail, initial encounter',
  'S61310D Laceration without foreign body of right index finger with damage to nail, subsequent',
  'encounter',
  'S61310S Laceration without foreign body of right index finger with damage to nail, sequela',
  'S61311A Laceration without foreign body of left index finger with damage to nail, initial encounter',
  'S61311D Laceration without foreign body of left index finger with damage to nail, subsequent encounter',
  'S61311S Laceration without foreign body of left index finger with damage to nail, sequela',
  'S61312A Laceration without foreign body of right middle finger with damage to nail, initial encounter',
  'S61312D Laceration without foreign body of right middle finger with damage to nail, subsequent',
  'encounter',
  'S61312S Laceration without foreign body of right middle finger with damage to nail, sequela',
  'S61313A Laceration without foreign body of left middle finger with damage to nail, initial encounter',
  'S61313D Laceration without foreign body of left middle finger with damage to nail, subsequent',
  'encounter',
  'S61313S Laceration without foreign body of left middle finger with damage to nail, sequela',
  'S61314A Laceration without foreign body of right ring finger with damage to nail, initial encounter',
  'S61314D Laceration without foreign body of right ring finger with damage to nail, subsequent encounter',
  'S61314S Laceration without foreign body of right ring finger with damage to nail, sequela',
  'S61315A Laceration without foreign body of left ring finger with damage to nail, initial encounter',
  'S61315D Laceration without foreign body of left ring finger with damage to nail, subsequent encounter',
  'S61315S Laceration without foreign body of left ring finger with damage to nail, sequela',
  'S61316A Laceration without foreign body of right little finger with damage to nail, initial encounter',
  'S61316D Laceration without foreign body of right little finger with damage to nail, subsequent',
  'encounter',
  'S61316S Laceration without foreign body of right little finger with damage to nail, sequela',
  'S61317A Laceration without foreign body of left little finger with damage to nail, initial encounter',
  'S61317D Laceration without foreign body of left little finger with damage to nail, subsequent encounter',
  'S61317S Laceration without foreign body of left little finger with damage to nail, sequela',
  'S61318A Laceration without foreign body of other finger with damage to nail, initial encounter',
  'S61318D Laceration without foreign body of other finger with damage to nail, subsequent encounter',
  'S61318S Laceration without foreign body of other finger with damage to nail, sequela',
  'S61319A Laceration without foreign body of unspecified finger with damage to nail, initial encounter',
  'S61319D Laceration without foreign body of unspecified finger with damage to nail, subsequent',
  'encounter',
  'S61319S Laceration without foreign body of unspecified finger with damage to nail, sequela',
  'S61320A Laceration with foreign body of right index finger with damage to nail, initial encounter',
  'S61320D Laceration with foreign body of right index finger with damage to nail, subsequent encounter',
  'S61320S Laceration with foreign body of right index finger with damage to nail, sequela',
  'S61321A Laceration with foreign body of left index finger with damage to nail, initial encounter',
  'S61321D Laceration with foreign body of left index finger with damage to nail, subsequent encounter',
  'S61321S Laceration with foreign body of left index finger with damage to nail, sequela',
  'S61322A Laceration with foreign body of right middle finger with damage to nail, initial encounter',
  'S61322D Laceration with foreign body of right middle finger with damage to nail, subsequent encounter',
  'S61322S Laceration with foreign body of right middle finger with damage to nail, sequela',
  'S61323A Laceration with foreign body of left middle finger with damage to nail, initial encounter',
  'S61323D Laceration with foreign body of left middle finger with damage to nail, subsequent encounter',
  'S61323S Laceration with foreign body of left middle finger with damage to nail, sequela',
  'S61324A Laceration with foreign body of right ring finger with damage to nail, initial encounter',
  'S61324D Laceration with foreign body of right ring finger with damage to nail, subsequent encounter',
  'S61324S Laceration with foreign body of right ring finger with damage to nail, sequela',
  'S61325A Laceration with foreign body of left ring finger with damage to nail, initial encounter',
  'S61325D Laceration with foreign body of left ring finger with damage to nail, subsequent encounter',
  'S61325S Laceration with foreign body of left ring finger with damage to nail, sequela',
  'S61326A Laceration with foreign body of right little finger with damage to nail, initial encounter',
  'S61326D Laceration with foreign body of right little finger with damage to nail, subsequent encounter',
  'S61326S Laceration with foreign body of right little finger with damage to nail, sequela',
  'S61327A Laceration with foreign body of left little finger with damage to nail, initial encounter',
  'S61327D Laceration with foreign body of left little finger with damage to nail, subsequent encounter',
  'S61327S Laceration with foreign body of left little finger with damage to nail, sequela',
  'S61328A Laceration with foreign body of other finger with damage to nail, initial encounter',
  'S61328D Laceration with foreign body of other finger with damage to nail, subsequent encounter',
  'S61328S Laceration with foreign body of other finger with damage to nail, sequela',
  'S61329A Laceration with foreign body of unspecified finger with damage to nail, initial encounter',
  'S61329D Laceration with foreign body of unspecified finger with damage to nail, subsequent encounter',
  'S61329S Laceration with foreign body of unspecified finger with damage to nail, sequela',
  'S61330A Puncture wound without foreign body of right index finger with damage to nail, initial',
  'encounter',
  'S61330D Puncture wound without foreign body of right index finger with damage to nail, subsequent',
  'encounter',
  'S61330S Puncture wound without foreign body of right index finger with damage to nail, sequela',
  'S61331A Puncture wound without foreign body of left index finger with damage to nail, initial encounter',
  'S61331D Puncture wound without foreign body of left index finger with damage to nail, subsequent',
  'encounter',
  'S61331S Puncture wound without foreign body of left index finger with damage to nail, sequela',
  'S61332A Puncture wound without foreign body of right middle finger with damage to nail, initial',
  'encounter',
  'S61332D Puncture wound without foreign body of right middle finger with damage to nail, subsequent',
  'encounter',
  'S61332S Puncture wound without foreign body of right middle finger with damage to nail, sequela',
  'S61333A Puncture wound without foreign body of left middle finger with damage to nail, initial',
  'encounter',
  'S61333D Puncture wound without foreign body of left middle finger with damage to nail, subsequent',
  'encounter',
  'S61333S Puncture wound without foreign body of left middle finger with damage to nail, sequela',
  'S61334A Puncture wound without foreign body of right ring finger with damage to nail, initial encounter',
  'S61334D Puncture wound without foreign body of right ring finger with damage to nail, subsequent',
  'encounter',
  'S61334S Puncture wound without foreign body of right ring finger with damage to nail, sequela',
  'S61335A Puncture wound without foreign body of left ring finger with damage to nail, initial encounter',
  'S61335D Puncture wound without foreign body of left ring finger with damage to nail, subsequent',
  'encounter',
  'S61335S Puncture wound without foreign body of left ring finger with damage to nail, sequela',
  'S61336A Puncture wound without foreign body of right little finger with damage to nail, initial',
  'encounter',
  'S61336D Puncture wound without foreign body of right little finger with damage to nail, subsequent',
  'encounter',
  'S61336S Puncture wound without foreign body of right little finger with damage to nail, sequela',
  'S61337A Puncture wound without foreign body of left little finger with damage to nail, initial encounter',
  'S61337D Puncture wound without foreign body of left little finger with damage to nail, subsequent',
  'encounter',
  'S61337S Puncture wound without foreign body of left little finger with damage to nail, sequela',
  'S61338A Puncture wound without foreign body of other finger with damage to nail, initial encounter',
  'S61338D Puncture wound without foreign body of other finger with damage to nail, subsequent',
  'encounter',
  'S61338S Puncture wound without foreign body of other finger with damage to nail, sequela',
  'S61339A Puncture wound without foreign body of unspecified finger with damage to nail, initial',
  'encounter',
  'S61339D Puncture wound without foreign body of unspecified finger with damage to nail, subsequent',
  'encounter',
  'S61339S Puncture wound without foreign body of unspecified finger with damage to nail, sequela',
  'S61340A Puncture wound with foreign body of right index finger with damage to nail, initial encounter',
  'S61340D Puncture wound with foreign body of right index finger with damage to nail, subsequent',
  'encounter',
  'S61340S Puncture wound with foreign body of right index finger with damage to nail, sequela',
  'S61341A Puncture wound with foreign body of left index finger with damage to nail, initial encounter',
  'S61341D Puncture wound with foreign body of left index finger with damage to nail, subsequent',
  'encounter',
  'S61341S Puncture wound with foreign body of left index finger with damage to nail, sequela',
  'S61342A Puncture wound with foreign body of right middle finger with damage to nail, initial encounter',
  'S61342D Puncture wound with foreign body of right middle finger with damage to nail, subsequent',
  'encounter',
  'S61342S Puncture wound with foreign body of right middle finger with damage to nail, sequela',
  'S61343A Puncture wound with foreign body of left middle finger with damage to nail, initial encounter',
  'S61343D Puncture wound with foreign body of left middle finger with damage to nail, subsequent',
  'encounter',
  'S61343S Puncture wound with foreign body of left middle finger with damage to nail, sequela',
  'S61344A Puncture wound with foreign body of right ring finger with damage to nail, initial encounter',
  'S61344D Puncture wound with foreign body of right ring finger with damage to nail, subsequent',
  'encounter',
  'S61344S Puncture wound with foreign body of right ring finger with damage to nail, sequela',
  'S61345A Puncture wound with foreign body of left ring finger with damage to nail, initial encounter',
  'S61345D Puncture wound with foreign body of left ring finger with damage to nail, subsequent',
  'encounter',
  'S61345S Puncture wound with foreign body of left ring finger with damage to nail, sequela',
  'S61346A Puncture wound with foreign body of right little finger with damage to nail, initial encounter',
  'S61346D Puncture wound with foreign body of right little finger with damage to nail, subsequent',
  'encounter',
  'S61346S Puncture wound with foreign body of right little finger with damage to nail, sequela',
  'S61347A Puncture wound with foreign body of left little finger with damage to nail, initial encounter',
  'S61347D Puncture wound with foreign body of left little finger with damage to nail, subsequent',
  'encounter',
  'S61347S Puncture wound with foreign body of left little finger with damage to nail, sequela',
  'S61348A Puncture wound with foreign body of other finger with damage to nail, initial encounter',
  'S61348D Puncture wound with foreign body of other finger with damage to nail, subsequent encounter',
  'S61348S Puncture wound with foreign body of other finger with damage to nail, sequela',
  'S61349A Puncture wound with foreign body of unspecified finger with damage to nail, initial encounter',
  'S61349D Puncture wound with foreign body of unspecified finger with damage to nail, subsequent',
  'encounter',
  'S61349S Puncture wound with foreign body of unspecified finger with damage to nail, sequela',
  'S61350A Open bite of right index finger with damage to nail, initial encounter',
  'S61350D Open bite of right index finger with damage to nail, subsequent encounter',
  'S61350S Open bite of right index finger with damage to nail, sequela',
  'S61351A Open bite of left index finger with damage to nail, initial encounter',
  'S61351D Open bite of left index finger with damage to nail, subsequent encounter',
  'S61351S Open bite of left index finger with damage to nail, sequela',
  'S61352A Open bite of right middle finger with damage to nail, initial encounter',
  'S61352D Open bite of right middle finger with damage to nail, subsequent encounter',
  'S61352S Open bite of right middle finger with damage to nail, sequela',
  'S61353A Open bite of left middle finger with damage to nail, initial encounter',
  'S61353D Open bite of left middle finger with damage to nail, subsequent encounter',
  'S61353S Open bite of left middle finger with damage to nail, sequela',
  'S61354A Open bite of right ring finger with damage to nail, initial encounter',
  'S61354D Open bite of right ring finger with damage to nail, subsequent encounter',
  'S61354S Open bite of right ring finger with damage to nail, sequela',
  'S61355A Open bite of left ring finger with damage to nail, initial encounter',
  'S61355D Open bite of left ring finger with damage to nail, subsequent encounter',
  'S61355S Open bite of left ring finger with damage to nail, sequela',
  'S61356A Open bite of right little finger with damage to nail, initial encounter',
  'S61356D Open bite of right little finger with damage to nail, subsequent encounter',
  'S61356S Open bite of right little finger with damage to nail, sequela',
  'S61357A Open bite of left little finger with damage to nail, initial encounter',
  'S61357D Open bite of left little finger with damage to nail, subsequent encounter',
  'S61357S Open bite of left little finger with damage to nail, sequela',
  'S61358A Open bite of other finger with damage to nail, initial encounter',
  'S61358D Open bite of other finger with damage to nail, subsequent encounter',
  'S61358S Open bite of other finger with damage to nail, sequela',
  'S61359A Open bite of unspecified finger with damage to nail, initial encounter',
  'S61359D Open bite of unspecified finger with damage to nail, subsequent encounter',
  'S61359S Open bite of unspecified finger with damage to nail, sequela',
  'S61401A Unspecified open wound of right hand, initial encounter',
  'S61401D Unspecified open wound of right hand, subsequent encounter',
  'S61401S Unspecified open wound of right hand, sequela',
  'S61402A Unspecified open wound of left hand, initial encounter',
  'S61402D Unspecified open wound of left hand, subsequent encounter',
  'S61402S Unspecified open wound of left hand, sequela',
  'S61409A Unspecified open wound of unspecified hand, initial encounter',
  'S61409D Unspecified open wound of unspecified hand, subsequent encounter',
  'S61409S Unspecified open wound of unspecified hand, sequela',
  'S61411A Laceration without foreign body of right hand, initial encounter',
  'S61411D Laceration without foreign body of right hand, subsequent encounter',
  'S61411S Laceration without foreign body of right hand, sequela',
  'S61412A Laceration without foreign body of left hand, initial encounter',
  'S61412D Laceration without foreign body of left hand, subsequent encounter',
  'S61412S Laceration without foreign body of left hand, sequela',
  'S61419A Laceration without foreign body of unspecified hand, initial encounter',
  'S61419D Laceration without foreign body of unspecified hand, subsequent encounter',
  'S61419S Laceration without foreign body of unspecified hand, sequela',
  'S61421A Laceration with foreign body of right hand, initial encounter',
  'S61421D Laceration with foreign body of right hand, subsequent encounter',
  'S61421S Laceration with foreign body of right hand, sequela',
  'S61422A Laceration with foreign body of left hand, initial encounter',
  'S61422D Laceration with foreign body of left hand, subsequent encounter',
  'S61422S Laceration with foreign body of left hand, sequela',
  'S61429A Laceration with foreign body of unspecified hand, initial encounter',
  'S61429D Laceration with foreign body of unspecified hand, subsequent encounter',
  'S61429S Laceration with foreign body of unspecified hand, sequela',
  'S61431A Puncture wound without foreign body of right hand, initial encounter',
  'S61431D Puncture wound without foreign body of right hand, subsequent encounter',
  'S61431S Puncture wound without foreign body of right hand, sequela',
  'S61432A Puncture wound without foreign body of left hand, initial encounter',
  'S61432D Puncture wound without foreign body of left hand, subsequent encounter',
  'S61432S Puncture wound without foreign body of left hand, sequela',
  'S61439A Puncture wound without foreign body of unspecified hand, initial encounter',
  'S61439D Puncture wound without foreign body of unspecified hand, subsequent encounter',
  'S61439S Puncture wound without foreign body of unspecified hand, sequela',
  'S61441A Puncture wound with foreign body of right hand, initial encounter',
  'S61441D Puncture wound with foreign body of right hand, subsequent encounter',
  'S61441S Puncture wound with foreign body of right hand, sequela',
  'S61442A Puncture wound with foreign body of left hand, initial encounter',
  'S61442D Puncture wound with foreign body of left hand, subsequent encounter',
  'S61442S Puncture wound with foreign body of left hand, sequela',
  'S61449A Puncture wound with foreign body of unspecified hand, initial encounter',
  'S61449D Puncture wound with foreign body of unspecified hand, subsequent encounter',
  'S61449S Puncture wound with foreign body of unspecified hand, sequela',
  'S61451A Open bite of right hand, initial encounter',
  'S61451D Open bite of right hand, subsequent encounter',
  'S61451S Open bite of right hand, sequela',
  'S61452A Open bite of left hand, initial encounter',
  'S61452D Open bite of left hand, subsequent encounter',
  'S61452S Open bite of left hand, sequela',
  'S61459A Open bite of unspecified hand, initial encounter',
  'S61459D Open bite of unspecified hand, subsequent encounter',
  'S61459S Open bite of unspecified hand, sequela',
  'S61501A Unspecified open wound of right wrist, initial encounter',
  'S61501D Unspecified open wound of right wrist, subsequent encounter',
  'S61501S Unspecified open wound of right wrist, sequela',
  'S61502A Unspecified open wound of left wrist, initial encounter',
  'S61502D Unspecified open wound of left wrist, subsequent encounter',
  'S61502S Unspecified open wound of left wrist, sequela',
  'S61509A Unspecified open wound of unspecified wrist, initial encounter',
  'S61509D Unspecified open wound of unspecified wrist, subsequent encounter',
  'S61509S Unspecified open wound of unspecified wrist, sequela',
  'S61511A Laceration without foreign body of right wrist, initial encounter',
  'S61511D Laceration without foreign body of right wrist, subsequent encounter',
  'S61511S Laceration without foreign body of right wrist, sequela',
  'S61512A Laceration without foreign body of left wrist, initial encounter',
  'S61512D Laceration without foreign body of left wrist, subsequent encounter',
  'S61512S Laceration without foreign body of left wrist, sequela',
  'S61519A Laceration without foreign body of unspecified wrist, initial encounter',
  'S61519D Laceration without foreign body of unspecified wrist, subsequent encounter',
  'S61519S Laceration without foreign body of unspecified wrist, sequela',
  'S61521A Laceration with foreign body of right wrist, initial encounter',
  'S61521D Laceration with foreign body of right wrist, subsequent encounter',
  'S61521S Laceration with foreign body of right wrist, sequela',
  'S61522A Laceration with foreign body of left wrist, initial encounter',
  'S61522D Laceration with foreign body of left wrist, subsequent encounter',
  'S61522S Laceration with foreign body of left wrist, sequela',
  'S61529A Laceration with foreign body of unspecified wrist, initial encounter',
  'S61529D Laceration with foreign body of unspecified wrist, subsequent encounter',
  'S61529S Laceration with foreign body of unspecified wrist, sequela',
  'S61531A Puncture wound without foreign body of right wrist, initial encounter',
  'S61531D Puncture wound without foreign body of right wrist, subsequent encounter',
  'S61531S Puncture wound without foreign body of right wrist, sequela',
  'S61532A Puncture wound without foreign body of left wrist, initial encounter',
  'S61532D Puncture wound without foreign body of left wrist, subsequent encounter',
  'S61532S Puncture wound without foreign body of left wrist, sequela',
  'S61539A Puncture wound without foreign body of unspecified wrist, initial encounter',
  'S61539D Puncture wound without foreign body of unspecified wrist, subsequent encounter',
  'S61539S Puncture wound without foreign body of unspecified wrist, sequela',
  'S61541A Puncture wound with foreign body of right wrist, initial encounter',
  'S61541D Puncture wound with foreign body of right wrist, subsequent encounter',
  'S61541S Puncture wound with foreign body of right wrist, sequela',
  'S61542A Puncture wound with foreign body of left wrist, initial encounter',
  'S61542D Puncture wound with foreign body of left wrist, subsequent encounter',
  'S61542S Puncture wound with foreign body of left wrist, sequela',
  'S61549A Puncture wound with foreign body of unspecified wrist, initial encounter',
  'S61549D Puncture wound with foreign body of unspecified wrist, subsequent encounter',
  'S61549S Puncture wound with foreign body of unspecified wrist, sequela',
  'S61551A Open bite of right wrist, initial encounter',
  'S61551D Open bite of right wrist, subsequent encounter',
  'S61551S Open bite of right wrist, sequela',
  'S61552A Open bite of left wrist, initial encounter',
  'S61552D Open bite of left wrist, subsequent encounter',
  'S61552S Open bite of left wrist, sequela',
  'S61559A Open bite of unspecified wrist, initial encounter',
  'S61559D Open bite of unspecified wrist, subsequent encounter',
  'S61559S Open bite of unspecified wrist, sequela',
  'S62001A Unspecified fracture of navicular [scaphoid] bone of right wrist, initial encounter for closed',
  'fracture',
  'S62001B Unspecified fracture of navicular [scaphoid] bone of right wrist, initial encounter for open',
  'fracture',
  'S62001D Unspecified fracture of navicular [scaphoid] bone of right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62001G Unspecified fracture of navicular [scaphoid] bone of right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62001K Unspecified fracture of navicular [scaphoid] bone of right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62001P Unspecified fracture of navicular [scaphoid] bone of right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62001S Unspecified fracture of navicular [scaphoid] bone of right wrist, sequela',
  'S62002A Unspecified fracture of navicular [scaphoid] bone of left wrist, initial encounter for closed',
  'fracture',
  'S62002B Unspecified fracture of navicular [scaphoid] bone of left wrist, initial encounter for open',
  'fracture',
  'S62002D Unspecified fracture of navicular [scaphoid] bone of left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62002G Unspecified fracture of navicular [scaphoid] bone of left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62002K Unspecified fracture of navicular [scaphoid] bone of left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62002P Unspecified fracture of navicular [scaphoid] bone of left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62002S Unspecified fracture of navicular [scaphoid] bone of left wrist, sequela',
  'S62009A Unspecified fracture of navicular [scaphoid] bone of unspecified wrist, initial encounter for',
  'closed fracture',
  'S62009B Unspecified fracture of navicular [scaphoid] bone of unspecified wrist, initial encounter for',
  'open fracture',
  'S62009D Unspecified fracture of navicular [scaphoid] bone of unspecified wrist, subsequent encounter',
  'for fracture with routine healing',
  'S62009G Unspecified fracture of navicular [scaphoid] bone of unspecified wrist, subsequent encounter',
  'for fracture with delayed healing',
  'S62009K Unspecified fracture of navicular [scaphoid] bone of unspecified wrist, subsequent encounter',
  'for fracture with nonunion',
  'S62009P Unspecified fracture of navicular [scaphoid] bone of unspecified wrist, subsequent encounter',
  'for fracture with malunion',
  'S62009S Unspecified fracture of navicular [scaphoid] bone of unspecified wrist, sequela',
  'S62011A Displaced fracture of distal pole of navicular [scaphoid] bone of right wrist, initial encounter for',
  'closed fracture',
  'S62011B Displaced fracture of distal pole of navicular [scaphoid] bone of right wrist, initial encounter for',
  'open fracture',
  'S62011D Displaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62011G Displaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62011K Displaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62011P Displaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62011S Displaced fracture of distal pole of navicular [scaphoid] bone of right wrist, sequela',
  'S62012A Displaced fracture of distal pole of navicular [scaphoid] bone of left wrist, initial encounter for',
  'closed fracture',
  'S62012B Displaced fracture of distal pole of navicular [scaphoid] bone of left wrist, initial encounter for',
  'open fracture',
  'S62012D Displaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62012G Displaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62012K Displaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62012P Displaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62012S Displaced fracture of distal pole of navicular [scaphoid] bone of left wrist, sequela',
  'S62013A Displaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for closed fracture',
  'S62013B Displaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for open fracture',
  'S62013D Displaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62013G Displaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62013K Displaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62013P Displaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, subsequent',
  'encounter for fracture with malunion',
  'S62013S Displaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, sequela',
  'S62014A Nondisplaced fracture of distal pole of navicular [scaphoid] bone of right wrist, initial',
  'encounter for closed fracture',
  'S62014B Nondisplaced fracture of distal pole of navicular [scaphoid] bone of right wrist, initial',
  'encounter for open fracture',
  'S62014D Nondisplaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62014G Nondisplaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62014K Nondisplaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62014P Nondisplaced fracture of distal pole of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62014S Nondisplaced fracture of distal pole of navicular [scaphoid] bone of right wrist, sequela',
  'S62015A Nondisplaced fracture of distal pole of navicular [scaphoid] bone of left wrist, initial encounter',
  'for closed fracture',
  'S62015B Nondisplaced fracture of distal pole of navicular [scaphoid] bone of left wrist, initial encounter',
  'for open fracture',
  'S62015D Nondisplaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62015G Nondisplaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62015K Nondisplaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62015P Nondisplaced fracture of distal pole of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62015S Nondisplaced fracture of distal pole of navicular [scaphoid] bone of left wrist, sequela',
  'S62016A Nondisplaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for closed fracture',
  'S62016B Nondisplaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for open fracture',
  'S62016D Nondisplaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with routine healing',
  'S62016G Nondisplaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with delayed healing',
  'S62016K Nondisplaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with nonunion',
  'S62016P Nondisplaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with malunion',
  'S62016S Nondisplaced fracture of distal pole of navicular [scaphoid] bone of unspecified wrist, sequela',
  'S62021A Displaced fracture of middle third of navicular [scaphoid] bone of right wrist, initial encounter',
  'for closed fracture',
  'S62021B Displaced fracture of middle third of navicular [scaphoid] bone of right wrist, initial encounter',
  'for open fracture',
  'S62021D Displaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62021G Displaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62021K Displaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62021P Displaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62021S Displaced fracture of middle third of navicular [scaphoid] bone of right wrist, sequela',
  'S62022A Displaced fracture of middle third of navicular [scaphoid] bone of left wrist, initial encounter',
  'for closed fracture',
  'S62022B Displaced fracture of middle third of navicular [scaphoid] bone of left wrist, initial encounter',
  'for open fracture',
  'S62022D Displaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62022G Displaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62022K Displaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62022P Displaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62022S Displaced fracture of middle third of navicular [scaphoid] bone of left wrist, sequela',
  'S62023A Displaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for closed fracture',
  'S62023B Displaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for open fracture',
  'S62023D Displaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with routine healing',
  'S62023G Displaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with delayed healing',
  'S62023K Displaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with nonunion',
  'S62023P Displaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with malunion',
  'S62023S Displaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist, sequela',
  'S62024A Nondisplaced fracture of middle third of navicular [scaphoid] bone of right wrist, initial',
  'encounter for closed fracture',
  'S62024B Nondisplaced fracture of middle third of navicular [scaphoid] bone of right wrist, initial',
  'encounter for open fracture',
  'S62024D Nondisplaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62024G Nondisplaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62024K Nondisplaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62024P Nondisplaced fracture of middle third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62024S Nondisplaced fracture of middle third of navicular [scaphoid] bone of right wrist, sequela',
  'S62025A Nondisplaced fracture of middle third of navicular [scaphoid] bone of left wrist, initial',
  'encounter for closed fracture',
  'S62025B Nondisplaced fracture of middle third of navicular [scaphoid] bone of left wrist, initial',
  'encounter for open fracture',
  'S62025D Nondisplaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62025G Nondisplaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62025K Nondisplaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62025P Nondisplaced fracture of middle third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62025S Nondisplaced fracture of middle third of navicular [scaphoid] bone of left wrist, sequela',
  'S62026A Nondisplaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for closed fracture',
  'S62026B Nondisplaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for open fracture',
  'S62026D Nondisplaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with routine healing',
  'S62026G Nondisplaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with delayed healing',
  'S62026K Nondisplaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with nonunion',
  'S62026P Nondisplaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with malunion',
  'S62026S Nondisplaced fracture of middle third of navicular [scaphoid] bone of unspecified wrist, sequela',
  'S62031A Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, initial',
  'encounter for closed fracture',
  'S62031B Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, initial',
  'encounter for open fracture',
  'S62031D Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62031G Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62031K Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62031P Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62031S Displaced fracture of proximal third of navicular [scaphoid] bone of right wrist, sequela',
  'S62032A Displaced fracture of proximal third of navicular [scaphoid] bone of left wrist, initial encounter',
  'for closed fracture',
  'S62032B Displaced fracture of proximal third of navicular [scaphoid] bone of left wrist, initial encounter',
  'for open fracture',
  'S62032D Displaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62032G Displaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62032K Displaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62032P Displaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62032S Displaced fracture of proximal third of navicular [scaphoid] bone of left wrist, sequela',
  'S62033A Displaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for closed fracture',
  'S62033B Displaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for open fracture',
  'S62033D Displaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with routine healing',
  'S62033G Displaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with delayed healing',
  'S62033K Displaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with nonunion',
  'S62033P Displaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with malunion',
  'S62033S Displaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist, sequela',
  'S62034A Nondisplaced fracture of proximal third of navicular [scaphoid] bone of right wrist, initial',
  'encounter for closed fracture',
  'S62034B Nondisplaced fracture of proximal third of navicular [scaphoid] bone of right wrist, initial',
  'encounter for open fracture',
  'S62034D Nondisplaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62034G Nondisplaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62034K Nondisplaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62034P Nondisplaced fracture of proximal third of navicular [scaphoid] bone of right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62034S Nondisplaced fracture of proximal third of navicular [scaphoid] bone of right wrist, sequela',
  'S62035A Nondisplaced fracture of proximal third of navicular [scaphoid] bone of left wrist, initial',
  'encounter for closed fracture',
  'S62035B Nondisplaced fracture of proximal third of navicular [scaphoid] bone of left wrist, initial',
  'encounter for open fracture',
  'S62035D Nondisplaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62035G Nondisplaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62035K Nondisplaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62035P Nondisplaced fracture of proximal third of navicular [scaphoid] bone of left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62035S Nondisplaced fracture of proximal third of navicular [scaphoid] bone of left wrist, sequela',
  'S62036A Nondisplaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for closed fracture',
  'S62036B Nondisplaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist, initial',
  'encounter for open fracture',
  'S62036D Nondisplaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with routine healing',
  'S62036G Nondisplaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with delayed healing',
  'S62036K Nondisplaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with nonunion',
  'S62036P Nondisplaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'subsequent encounter for fracture with malunion',
  'S62036S Nondisplaced fracture of proximal third of navicular [scaphoid] bone of unspecified wrist,',
  'sequela',
  'S62101A Fracture of unspecified carpal bone, right wrist, initial encounter for closed fracture',
  'S62101B Fracture of unspecified carpal bone, right wrist, initial encounter for open fracture',
  'S62101D Fracture of unspecified carpal bone, right wrist, subsequent encounter for fracture with',
  'routine healing',
  'S62101G Fracture of unspecified carpal bone, right wrist, subsequent encounter for fracture with',
  'delayed healing',
  'S62101K Fracture of unspecified carpal bone, right wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62101P Fracture of unspecified carpal bone, right wrist, subsequent encounter for fracture with',
  'malunion',
  'S62101S Fracture of unspecified carpal bone, right wrist, sequela',
  'S62102A Fracture of unspecified carpal bone, left wrist, initial encounter for closed fracture',
  'S62102B Fracture of unspecified carpal bone, left wrist, initial encounter for open fracture',
  'S62102D Fracture of unspecified carpal bone, left wrist, subsequent encounter for fracture with routine',
  'healing',
  'S62102G Fracture of unspecified carpal bone, left wrist, subsequent encounter for fracture with delayed',
  'healing',
  'S62102K Fracture of unspecified carpal bone, left wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62102P Fracture of unspecified carpal bone, left wrist, subsequent encounter for fracture with',
  'malunion',
  'S62102S Fracture of unspecified carpal bone, left wrist, sequela',
  'S62109A Fracture of unspecified carpal bone, unspecified wrist, initial encounter for closed fracture',
  'S62109B Fracture of unspecified carpal bone, unspecified wrist, initial encounter for open fracture',
  'S62109D Fracture of unspecified carpal bone, unspecified wrist, subsequent encounter for fracture with',
  'routine healing',
  'S62109G Fracture of unspecified carpal bone, unspecified wrist, subsequent encounter for fracture with',
  'delayed healing',
  'S62109K Fracture of unspecified carpal bone, unspecified wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62109P Fracture of unspecified carpal bone, unspecified wrist, subsequent encounter for fracture with',
  'malunion',
  'S62109S Fracture of unspecified carpal bone, unspecified wrist, sequela',
  'S62111A Displaced fracture of triquetrum [cuneiform] bone, right wrist, initial encounter for closed',
  'fracture',
  'S62111B Displaced fracture of triquetrum [cuneiform] bone, right wrist, initial encounter for open',
  'fracture',
  'S62111D Displaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62111G Displaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62111K Displaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62111P Displaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62111S Displaced fracture of triquetrum [cuneiform] bone, right wrist, sequela',
  'S62112A Displaced fracture of triquetrum [cuneiform] bone, left wrist, initial encounter for closed',
  'fracture',
  'S62112B Displaced fracture of triquetrum [cuneiform] bone, left wrist, initial encounter for open',
  'fracture',
  'S62112D Displaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62112G Displaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62112K Displaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62112P Displaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62112S Displaced fracture of triquetrum [cuneiform] bone, left wrist, sequela',
  'S62113A Displaced fracture of triquetrum [cuneiform] bone, unspecified wrist, initial encounter for',
  'closed fracture',
  'S62113B Displaced fracture of triquetrum [cuneiform] bone, unspecified wrist, initial encounter for open',
  'fracture',
  'S62113D Displaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent encounter',
  'for fracture with routine healing',
  'S62113G Displaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent encounter',
  'for fracture with delayed healing',
  'S62113K Displaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent encounter',
  'for fracture with nonunion',
  'S62113P Displaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent encounter',
  'for fracture with malunion',
  'S62113S Displaced fracture of triquetrum [cuneiform] bone, unspecified wrist, sequela',
  'S62114A Nondisplaced fracture of triquetrum [cuneiform] bone, right wrist, initial encounter for closed',
  'fracture',
  'S62114B Nondisplaced fracture of triquetrum [cuneiform] bone, right wrist, initial encounter for open',
  'fracture',
  'S62114D Nondisplaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62114G Nondisplaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62114K Nondisplaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62114P Nondisplaced fracture of triquetrum [cuneiform] bone, right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62114S Nondisplaced fracture of triquetrum [cuneiform] bone, right wrist, sequela',
  'S62115A Nondisplaced fracture of triquetrum [cuneiform] bone, left wrist, initial encounter for closed',
  'fracture',
  'S62115B Nondisplaced fracture of triquetrum [cuneiform] bone, left wrist, initial encounter for open',
  'fracture',
  'S62115D Nondisplaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62115G Nondisplaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62115K Nondisplaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62115P Nondisplaced fracture of triquetrum [cuneiform] bone, left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62115S Nondisplaced fracture of triquetrum [cuneiform] bone, left wrist, sequela',
  'S62116A Nondisplaced fracture of triquetrum [cuneiform] bone, unspecified wrist, initial encounter for',
  'closed fracture',
  'S62116B Nondisplaced fracture of triquetrum [cuneiform] bone, unspecified wrist, initial encounter for',
  'open fracture',
  'S62116D Nondisplaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62116G Nondisplaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62116K Nondisplaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62116P Nondisplaced fracture of triquetrum [cuneiform] bone, unspecified wrist, subsequent',
  'encounter for fracture with malunion',
  'S62116S Nondisplaced fracture of triquetrum [cuneiform] bone, unspecified wrist, sequela',
  'S62121A Displaced fracture of lunate [semilunar], right wrist, initial encounter for closed fracture',
  'S62121B Displaced fracture of lunate [semilunar], right wrist, initial encounter for open fracture',
  'S62121D Displaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture with',
  'routine healing',
  'S62121G Displaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture with',
  'delayed healing',
  'S62121K Displaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62121P Displaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture with',
  'malunion',
  'S62121S Displaced fracture of lunate [semilunar], right wrist, sequela',
  'S62122A Displaced fracture of lunate [semilunar], left wrist, initial encounter for closed fracture',
  'S62122B Displaced fracture of lunate [semilunar], left wrist, initial encounter for open fracture',
  'S62122D Displaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'routine healing',
  'S62122G Displaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'delayed healing',
  'S62122K Displaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62122P Displaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'malunion',
  'S62122S Displaced fracture of lunate [semilunar], left wrist, sequela',
  'S62123A Displaced fracture of lunate [semilunar], unspecified wrist, initial encounter for closed fracture',
  'S62123B Displaced fracture of lunate [semilunar], unspecified wrist, initial encounter for open fracture',
  'S62123D Displaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for fracture',
  'with routine healing',
  'S62123G Displaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for fracture',
  'with delayed healing',
  'S62123K Displaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for fracture',
  'with nonunion',
  'S62123P Displaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for fracture',
  'with malunion',
  'S62123S Displaced fracture of lunate [semilunar], unspecified wrist, sequela',
  'S62124A Nondisplaced fracture of lunate [semilunar], right wrist, initial encounter for closed fracture',
  'S62124B Nondisplaced fracture of lunate [semilunar], right wrist, initial encounter for open fracture',
  'S62124D Nondisplaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture',
  'with routine healing',
  'S62124G Nondisplaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture',
  'with delayed healing',
  'S62124K Nondisplaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture',
  'with nonunion',
  'S62124P Nondisplaced fracture of lunate [semilunar], right wrist, subsequent encounter for fracture',
  'with malunion',
  'S62124S Nondisplaced fracture of lunate [semilunar], right wrist, sequela',
  'S62125A Nondisplaced fracture of lunate [semilunar], left wrist, initial encounter for closed fracture',
  'S62125B Nondisplaced fracture of lunate [semilunar], left wrist, initial encounter for open fracture',
  'S62125D Nondisplaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'routine healing',
  'S62125G Nondisplaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'delayed healing',
  'S62125K Nondisplaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62125P Nondisplaced fracture of lunate [semilunar], left wrist, subsequent encounter for fracture with',
  'malunion',
  'S62125S Nondisplaced fracture of lunate [semilunar], left wrist, sequela',
  'S62126A Nondisplaced fracture of lunate [semilunar], unspecified wrist, initial encounter for closed',
  'fracture',
  'S62126B Nondisplaced fracture of lunate [semilunar], unspecified wrist, initial encounter for open',
  'fracture',
  'S62126D Nondisplaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62126G Nondisplaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62126K Nondisplaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62126P Nondisplaced fracture of lunate [semilunar], unspecified wrist, subsequent encounter for',
  'fracture with malunion',
  'S62126S Nondisplaced fracture of lunate [semilunar], unspecified wrist, sequela',
  'S62131A Displaced fracture of capitate [os magnum] bone, right wrist, initial encounter for closed',
  'fracture',
  'S62131B Displaced fracture of capitate [os magnum] bone, right wrist, initial encounter for open',
  'fracture',
  'S62131D Displaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62131G Displaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62131K Displaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62131P Displaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62131S Displaced fracture of capitate [os magnum] bone, right wrist, sequela',
  'S62132A Displaced fracture of capitate [os magnum] bone, left wrist, initial encounter for closed',
  'fracture',
  'S62132B Displaced fracture of capitate [os magnum] bone, left wrist, initial encounter for open fracture',
  'S62132D Displaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for fracture',
  'with routine healing',
  'S62132G Displaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for fracture',
  'with delayed healing',
  'S62132K Displaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for fracture',
  'with nonunion',
  'S62132P Displaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for fracture',
  'with malunion',
  'S62132S Displaced fracture of capitate [os magnum] bone, left wrist, sequela',
  'S62133A Displaced fracture of capitate [os magnum] bone, unspecified wrist, initial encounter for closed',
  'fracture',
  'S62133B Displaced fracture of capitate [os magnum] bone, unspecified wrist, initial encounter for open',
  'fracture',
  'S62133D Displaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62133G Displaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62133K Displaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62133P Displaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter for',
  'fracture with malunion',
  'S62133S Displaced fracture of capitate [os magnum] bone, unspecified wrist, sequela',
  'S62134A Nondisplaced fracture of capitate [os magnum] bone, right wrist, initial encounter for closed',
  'fracture',
  'S62134B Nondisplaced fracture of capitate [os magnum] bone, right wrist, initial encounter for open',
  'fracture',
  'S62134D Nondisplaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62134G Nondisplaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62134K Nondisplaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62134P Nondisplaced fracture of capitate [os magnum] bone, right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62134S Nondisplaced fracture of capitate [os magnum] bone, right wrist, sequela',
  'S62135A Nondisplaced fracture of capitate [os magnum] bone, left wrist, initial encounter for closed',
  'fracture',
  'S62135B Nondisplaced fracture of capitate [os magnum] bone, left wrist, initial encounter for open',
  'fracture',
  'S62135D Nondisplaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62135G Nondisplaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62135K Nondisplaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62135P Nondisplaced fracture of capitate [os magnum] bone, left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62135S Nondisplaced fracture of capitate [os magnum] bone, left wrist, sequela',
  'S62136A Nondisplaced fracture of capitate [os magnum] bone, unspecified wrist, initial encounter for',
  'closed fracture',
  'S62136B Nondisplaced fracture of capitate [os magnum] bone, unspecified wrist, initial encounter for',
  'open fracture',
  'S62136D Nondisplaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter',
  'for fracture with routine healing',
  'S62136G Nondisplaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter',
  'for fracture with delayed healing',
  'S62136K Nondisplaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter',
  'for fracture with nonunion',
  'S62136P Nondisplaced fracture of capitate [os magnum] bone, unspecified wrist, subsequent encounter',
  'for fracture with malunion',
  'S62136S Nondisplaced fracture of capitate [os magnum] bone, unspecified wrist, sequela',
  'S62141A Displaced fracture of body of hamate [unciform] bone, right wrist, initial encounter for closed',
  'fracture',
  'S62141B Displaced fracture of body of hamate [unciform] bone, right wrist, initial encounter for open',
  'fracture',
  'S62141D Displaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62141G Displaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62141K Displaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62141P Displaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62141S Displaced fracture of body of hamate [unciform] bone, right wrist, sequela',
  'S62142A Displaced fracture of body of hamate [unciform] bone, left wrist, initial encounter for closed',
  'fracture',
  'S62142B Displaced fracture of body of hamate [unciform] bone, left wrist, initial encounter for open',
  'fracture',
  'S62142D Displaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62142G Displaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62142K Displaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62142P Displaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62142S Displaced fracture of body of hamate [unciform] bone, left wrist, sequela',
  'S62143A Displaced fracture of body of hamate [unciform] bone, unspecified wrist, initial encounter for',
  'closed fracture',
  'S62143B Displaced fracture of body of hamate [unciform] bone, unspecified wrist, initial encounter for',
  'open fracture',
  'S62143D Displaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62143G Displaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62143K Displaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62143P Displaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with malunion',
  'S62143S Displaced fracture of body of hamate [unciform] bone, unspecified wrist, sequela',
  'S62144A Nondisplaced fracture of body of hamate [unciform] bone, right wrist, initial encounter for',
  'closed fracture',
  'S62144B Nondisplaced fracture of body of hamate [unciform] bone, right wrist, initial encounter for',
  'open fracture',
  'S62144D Nondisplaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter',
  'for fracture with routine healing',
  'S62144G Nondisplaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter',
  'for fracture with delayed healing',
  'S62144K Nondisplaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter',
  'for fracture with nonunion',
  'S62144P Nondisplaced fracture of body of hamate [unciform] bone, right wrist, subsequent encounter',
  'for fracture with malunion',
  'S62144S Nondisplaced fracture of body of hamate [unciform] bone, right wrist, sequela',
  'S62145A Nondisplaced fracture of body of hamate [unciform] bone, left wrist, initial encounter for',
  'closed fracture',
  'S62145B Nondisplaced fracture of body of hamate [unciform] bone, left wrist, initial encounter for open',
  'fracture',
  'S62145D Nondisplaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62145G Nondisplaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62145K Nondisplaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62145P Nondisplaced fracture of body of hamate [unciform] bone, left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62145S Nondisplaced fracture of body of hamate [unciform] bone, left wrist, sequela',
  'S62146A Nondisplaced fracture of body of hamate [unciform] bone, unspecified wrist, initial encounter',
  'for closed fracture',
  'S62146B Nondisplaced fracture of body of hamate [unciform] bone, unspecified wrist, initial encounter',
  'for open fracture',
  'S62146D Nondisplaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62146G Nondisplaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62146K Nondisplaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62146P Nondisplaced fracture of body of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with malunion',
  'S62146S Nondisplaced fracture of body of hamate [unciform] bone, unspecified wrist, sequela',
  'S62151A Displaced fracture of hook process of hamate [unciform] bone, right wrist, initial encounter for',
  'closed fracture',
  'S62151B Displaced fracture of hook process of hamate [unciform] bone, right wrist, initial encounter for',
  'open fracture',
  'S62151D Displaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62151G Displaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62151K Displaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62151P Displaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62151S Displaced fracture of hook process of hamate [unciform] bone, right wrist, sequela',
  'S62152A Displaced fracture of hook process of hamate [unciform] bone, left wrist, initial encounter for',
  'closed fracture',
  'S62152B Displaced fracture of hook process of hamate [unciform] bone, left wrist, initial encounter for',
  'open fracture',
  'S62152D Displaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62152G Displaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62152K Displaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62152P Displaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62152S Displaced fracture of hook process of hamate [unciform] bone, left wrist, sequela',
  'S62153A Displaced fracture of hook process of hamate [unciform] bone, unspecified wrist, initial',
  'encounter for closed fracture',
  'S62153B Displaced fracture of hook process of hamate [unciform] bone, unspecified wrist, initial',
  'encounter for open fracture',
  'S62153D Displaced fracture of hook process of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62153G Displaced fracture of hook process of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62153K Displaced fracture of hook process of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62153P Displaced fracture of hook process of hamate [unciform] bone, unspecified wrist, subsequent',
  'encounter for fracture with malunion',
  'S62153S Displaced fracture of hook process of hamate [unciform] bone, unspecified wrist, sequela',
  'S62154A Nondisplaced fracture of hook process of hamate [unciform] bone, right wrist, initial encounter',
  'for closed fracture',
  'S62154B Nondisplaced fracture of hook process of hamate [unciform] bone, right wrist, initial encounter',
  'for open fracture',
  'S62154D Nondisplaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62154G Nondisplaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62154K Nondisplaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62154P Nondisplaced fracture of hook process of hamate [unciform] bone, right wrist, subsequent',
  'encounter for fracture with malunion',
  'S62154S Nondisplaced fracture of hook process of hamate [unciform] bone, right wrist, sequela',
  'S62155A Nondisplaced fracture of hook process of hamate [unciform] bone, left wrist, initial encounter',
  'for closed fracture',
  'S62155B Nondisplaced fracture of hook process of hamate [unciform] bone, left wrist, initial encounter',
  'for open fracture',
  'S62155D Nondisplaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62155G Nondisplaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62155K Nondisplaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62155P Nondisplaced fracture of hook process of hamate [unciform] bone, left wrist, subsequent',
  'encounter for fracture with malunion',
  'S62155S Nondisplaced fracture of hook process of hamate [unciform] bone, left wrist, sequela',
  'S62156A Nondisplaced fracture of hook process of hamate [unciform] bone, unspecified wrist, initial',
  'encounter for closed fracture',
  'S62156B Nondisplaced fracture of hook process of hamate [unciform] bone, unspecified wrist, initial',
  'encounter for open fracture',
  'S62156D Nondisplaced fracture of hook process of hamate [unciform] bone, unspecified wrist,',
  'subsequent encounter for fracture with routine healing',
  'S62156G Nondisplaced fracture of hook process of hamate [unciform] bone, unspecified wrist,',
  'subsequent encounter for fracture with delayed healing',
  'S62156K Nondisplaced fracture of hook process of hamate [unciform] bone, unspecified wrist,',
  'subsequent encounter for fracture with nonunion',
  'S62156P Nondisplaced fracture of hook process of hamate [unciform] bone, unspecified wrist,',
  'subsequent encounter for fracture with malunion',
  'S62156S Nondisplaced fracture of hook process of hamate [unciform] bone, unspecified wrist, sequela',
  'S62161A Displaced fracture of pisiform, right wrist, initial encounter for closed fracture',
  'S62161B Displaced fracture of pisiform, right wrist, initial encounter for open fracture',
  'S62161D Displaced fracture of pisiform, right wrist, subsequent encounter for fracture with routine',
  'healing',
  'S62161G Displaced fracture of pisiform, right wrist, subsequent encounter for fracture with delayed',
  'healing',
  'S62161K Displaced fracture of pisiform, right wrist, subsequent encounter for fracture with nonunion',
  'S62161P Displaced fracture of pisiform, right wrist, subsequent encounter for fracture with malunion',
  'S62161S Displaced fracture of pisiform, right wrist, sequela',
  'S62162A Displaced fracture of pisiform, left wrist, initial encounter for closed fracture',
  'S62162B Displaced fracture of pisiform, left wrist, initial encounter for open fracture',
  'S62162D Displaced fracture of pisiform, left wrist, subsequent encounter for fracture with routine',
  'healing',
  'S62162G Displaced fracture of pisiform, left wrist, subsequent encounter for fracture with delayed',
  'healing',
  'S62162K Displaced fracture of pisiform, left wrist, subsequent encounter for fracture with nonunion',
  'S62162P Displaced fracture of pisiform, left wrist, subsequent encounter for fracture with malunion',
  'S62162S Displaced fracture of pisiform, left wrist, sequela',
  'S62163A Displaced fracture of pisiform, unspecified wrist, initial encounter for closed fracture',
  'S62163B Displaced fracture of pisiform, unspecified wrist, initial encounter for open fracture',
  'S62163D Displaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'routine healing',
  'S62163G Displaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'delayed healing',
  'S62163K Displaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62163P Displaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'malunion',
  'S62163S Displaced fracture of pisiform, unspecified wrist, sequela',
  'S62164A Nondisplaced fracture of pisiform, right wrist, initial encounter for closed fracture',
  'S62164B Nondisplaced fracture of pisiform, right wrist, initial encounter for open fracture',
  'S62164D Nondisplaced fracture of pisiform, right wrist, subsequent encounter for fracture with routine',
  'healing',
  'S62164G Nondisplaced fracture of pisiform, right wrist, subsequent encounter for fracture with delayed',
  'healing',
  'S62164K Nondisplaced fracture of pisiform, right wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62164P Nondisplaced fracture of pisiform, right wrist, subsequent encounter for fracture with',
  'malunion',
  'S62164S Nondisplaced fracture of pisiform, right wrist, sequela',
  'S62165A Nondisplaced fracture of pisiform, left wrist, initial encounter for closed fracture',
  'S62165B Nondisplaced fracture of pisiform, left wrist, initial encounter for open fracture',
  'S62165D Nondisplaced fracture of pisiform, left wrist, subsequent encounter for fracture with routine',
  'healing',
  'S62165G Nondisplaced fracture of pisiform, left wrist, subsequent encounter for fracture with delayed',
  'healing',
  'S62165K Nondisplaced fracture of pisiform, left wrist, subsequent encounter for fracture with nonunion',
  'S62165P Nondisplaced fracture of pisiform, left wrist, subsequent encounter for fracture with malunion',
  'S62165S Nondisplaced fracture of pisiform, left wrist, sequela',
  'S62166A Nondisplaced fracture of pisiform, unspecified wrist, initial encounter for closed fracture',
  'S62166B Nondisplaced fracture of pisiform, unspecified wrist, initial encounter for open fracture',
  'S62166D Nondisplaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'routine healing',
  'S62166G Nondisplaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'delayed healing',
  'S62166K Nondisplaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'nonunion',
  'S62166P Nondisplaced fracture of pisiform, unspecified wrist, subsequent encounter for fracture with',
  'malunion',
  'S62166S Nondisplaced fracture of pisiform, unspecified wrist, sequela',
  'S62171A Displaced fracture of trapezium [larger multangular], right wrist, initial encounter for closed',
  'fracture',
  'S62171B Displaced fracture of trapezium [larger multangular], right wrist, initial encounter for open',
  'fracture',
  'S62171D Displaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62171G Displaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62171K Displaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62171P Displaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62171S Displaced fracture of trapezium [larger multangular], right wrist, sequela',
  'S62172A Displaced fracture of trapezium [larger multangular], left wrist, initial encounter for closed',
  'fracture',
  'S62172B Displaced fracture of trapezium [larger multangular], left wrist, initial encounter for open',
  'fracture',
  'S62172D Displaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62172G Displaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62172K Displaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62172P Displaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62172S Displaced fracture of trapezium [larger multangular], left wrist, sequela',
  'S62173A Displaced fracture of trapezium [larger multangular], unspecified wrist, initial encounter for',
  'closed fracture',
  'S62173B Displaced fracture of trapezium [larger multangular], unspecified wrist, initial encounter for',
  'open fracture',
  'S62173D Displaced fracture of trapezium [larger multangular], unspecified wrist, subsequent encounter',
  'for fracture with routine healing',
  'S62173G Displaced fracture of trapezium [larger multangular], unspecified wrist, subsequent encounter',
  'for fracture with delayed healing',
  'S62173K Displaced fracture of trapezium [larger multangular], unspecified wrist, subsequent encounter',
  'for fracture with nonunion',
  'S62173P Displaced fracture of trapezium [larger multangular], unspecified wrist, subsequent encounter',
  'for fracture with malunion',
  'S62173S Displaced fracture of trapezium [larger multangular], unspecified wrist, sequela',
  'S62174A Nondisplaced fracture of trapezium [larger multangular], right wrist, initial encounter for',
  'closed fracture',
  'S62174B Nondisplaced fracture of trapezium [larger multangular], right wrist, initial encounter for open',
  'fracture',
  'S62174D Nondisplaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62174G Nondisplaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62174K Nondisplaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62174P Nondisplaced fracture of trapezium [larger multangular], right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62174S Nondisplaced fracture of trapezium [larger multangular], right wrist, sequela',
  'S62175A Nondisplaced fracture of trapezium [larger multangular], left wrist, initial encounter for closed',
  'fracture',
  'S62175B Nondisplaced fracture of trapezium [larger multangular], left wrist, initial encounter for open',
  'fracture',
  'S62175D Nondisplaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62175G Nondisplaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62175K Nondisplaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62175P Nondisplaced fracture of trapezium [larger multangular], left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62175S Nondisplaced fracture of trapezium [larger multangular], left wrist, sequela',
  'S62176A Nondisplaced fracture of trapezium [larger multangular], unspecified wrist, initial encounter',
  'for closed fracture',
  'S62176B Nondisplaced fracture of trapezium [larger multangular], unspecified wrist, initial encounter',
  'for open fracture',
  'S62176D Nondisplaced fracture of trapezium [larger multangular], unspecified wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62176G Nondisplaced fracture of trapezium [larger multangular], unspecified wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62176K Nondisplaced fracture of trapezium [larger multangular], unspecified wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62176P Nondisplaced fracture of trapezium [larger multangular], unspecified wrist, subsequent',
  'encounter for fracture with malunion',
  'S62176S Nondisplaced fracture of trapezium [larger multangular], unspecified wrist, sequela',
  'S62181A Displaced fracture of trapezoid [smaller multangular], right wrist, initial encounter for closed',
  'fracture',
  'S62181B Displaced fracture of trapezoid [smaller multangular], right wrist, initial encounter for open',
  'fracture',
  'S62181D Displaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62181G Displaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62181K Displaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62181P Displaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter for',
  'fracture with malunion',
  'S62181S Displaced fracture of trapezoid [smaller multangular], right wrist, sequela',
  'S62182A Displaced fracture of trapezoid [smaller multangular], left wrist, initial encounter for closed',
  'fracture',
  'S62182B Displaced fracture of trapezoid [smaller multangular], left wrist, initial encounter for open',
  'fracture',
  'S62182D Displaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62182G Displaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62182K Displaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62182P Displaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62182S Displaced fracture of trapezoid [smaller multangular], left wrist, sequela',
  'S62183A Displaced fracture of trapezoid [smaller multangular], unspecified wrist, initial encounter for',
  'closed fracture',
  'S62183B Displaced fracture of trapezoid [smaller multangular], unspecified wrist, initial encounter for',
  'open fracture',
  'S62183D Displaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent encounter',
  'for fracture with routine healing',
  'S62183G Displaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent encounter',
  'for fracture with delayed healing',
  'S62183K Displaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent encounter',
  'for fracture with nonunion',
  'S62183P Displaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent encounter',
  'for fracture with malunion',
  'S62183S Displaced fracture of trapezoid [smaller multangular], unspecified wrist, sequela',
  'S62184A Nondisplaced fracture of trapezoid [smaller multangular], right wrist, initial encounter for',
  'closed fracture',
  'S62184B Nondisplaced fracture of trapezoid [smaller multangular], right wrist, initial encounter for open',
  'fracture',
  'S62184D Nondisplaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter',
  'for fracture with routine healing',
  'S62184G Nondisplaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter',
  'for fracture with delayed healing',
  'S62184K Nondisplaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter',
  'for fracture with nonunion',
  'S62184P Nondisplaced fracture of trapezoid [smaller multangular], right wrist, subsequent encounter',
  'for fracture with malunion',
  'S62184S Nondisplaced fracture of trapezoid [smaller multangular], right wrist, sequela',
  'S62185A Nondisplaced fracture of trapezoid [smaller multangular], left wrist, initial encounter for closed',
  'fracture',
  'S62185B Nondisplaced fracture of trapezoid [smaller multangular], left wrist, initial encounter for open',
  'fracture',
  'S62185D Nondisplaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with routine healing',
  'S62185G Nondisplaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with delayed healing',
  'S62185K Nondisplaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with nonunion',
  'S62185P Nondisplaced fracture of trapezoid [smaller multangular], left wrist, subsequent encounter for',
  'fracture with malunion',
  'S62185S Nondisplaced fracture of trapezoid [smaller multangular], left wrist, sequela',
  'S62186A Nondisplaced fracture of trapezoid [smaller multangular], unspecified wrist, initial encounter',
  'for closed fracture',
  'S62186B Nondisplaced fracture of trapezoid [smaller multangular], unspecified wrist, initial encounter',
  'for open fracture',
  'S62186D Nondisplaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent',
  'encounter for fracture with routine healing',
  'S62186G Nondisplaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent',
  'encounter for fracture with delayed healing',
  'S62186K Nondisplaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent',
  'encounter for fracture with nonunion',
  'S62186P Nondisplaced fracture of trapezoid [smaller multangular], unspecified wrist, subsequent',
  'encounter for fracture with malunion',
  'S62186S Nondisplaced fracture of trapezoid [smaller multangular], unspecified wrist, sequela',
  'S62201A Unspecified fracture of first metacarpal bone, right hand, initial encounter for closed fracture',
  'S62201B Unspecified fracture of first metacarpal bone, right hand, initial encounter for open fracture',
  'S62201D Unspecified fracture of first metacarpal bone, right hand, subsequent encounter for fracture',
  'with routine healing',
  'S62201G Unspecified fracture of first metacarpal bone, right hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62201K Unspecified fracture of first metacarpal bone, right hand, subsequent encounter for fracture',
  'with nonunion',
  'S62201P Unspecified fracture of first metacarpal bone, right hand, subsequent encounter for fracture',
  'with malunion',
  'S62201S Unspecified fracture of first metacarpal bone, right hand, sequela',
  'S62202A Unspecified fracture of first metacarpal bone, left hand, initial encounter for closed fracture',
  'S62202B Unspecified fracture of first metacarpal bone, left hand, initial encounter for open fracture',
  'S62202D Unspecified fracture of first metacarpal bone, left hand, subsequent encounter for fracture',
  'with routine healing',
  'S62202G Unspecified fracture of first metacarpal bone, left hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62202K Unspecified fracture of first metacarpal bone, left hand, subsequent encounter for fracture',
  'with nonunion',
  'S62202P Unspecified fracture of first metacarpal bone, left hand, subsequent encounter for fracture',
  'with malunion',
  'S62202S Unspecified fracture of first metacarpal bone, left hand, sequela',
  'S62209A Unspecified fracture of first metacarpal bone, unspecified hand, initial encounter for closed',
  'fracture',
  'S62209B Unspecified fracture of first metacarpal bone, unspecified hand, initial encounter for open',
  'fracture',
  'S62209D Unspecified fracture of first metacarpal bone, unspecified hand, subsequent encounter for',
  'fracture with routine healing',
  'S62209G Unspecified fracture of first metacarpal bone, unspecified hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62209K Unspecified fracture of first metacarpal bone, unspecified hand, subsequent encounter for',
  'fracture with nonunion',
  'S62209P Unspecified fracture of first metacarpal bone, unspecified hand, subsequent encounter for',
  'fracture with malunion',
  'S62209S Unspecified fracture of first metacarpal bone, unspecified hand, sequela',
  "S62211A Bennett's fracture, right hand, initial encounter for closed fracture",
  "S62211B Bennett's fracture, right hand, initial encounter for open fracture",
  "S62211D Bennett's fracture, right hand, subsequent encounter for fracture with routine healing",
  "S62211G Bennett's fracture, right hand, subsequent encounter for fracture with delayed healing",
  "S62211K Bennett's fracture, right hand, subsequent encounter for fracture with nonunion",
  "S62211P Bennett's fracture, right hand, subsequent encounter for fracture with malunion",
  "S62211S Bennett's fracture, right hand, sequela",
  "S62212A Bennett's fracture, left hand, initial encounter for closed fracture",
  "S62212B Bennett's fracture, left hand, initial encounter for open fracture",
  "S62212D Bennett's fracture, left hand, subsequent encounter for fracture with routine healing",
  "S62212G Bennett's fracture, left hand, subsequent encounter for fracture with delayed healing",
  "S62212K Bennett's fracture, left hand, subsequent encounter for fracture with nonunion",
  "S62212P Bennett's fracture, left hand, subsequent encounter for fracture with malunion",
  "S62212S Bennett's fracture, left hand, sequela",
  "S62213A Bennett's fracture, unspecified hand, initial encounter for closed fracture",
  "S62213B Bennett's fracture, unspecified hand, initial encounter for open fracture",
  "S62213D Bennett's fracture, unspecified hand, subsequent encounter for fracture with routine healing",
  "S62213G Bennett's fracture, unspecified hand, subsequent encounter for fracture with delayed healing",
  "S62213K Bennett's fracture, unspecified hand, subsequent encounter for fracture with nonunion",
  "S62213P Bennett's fracture, unspecified hand, subsequent encounter for fracture with malunion",
  "S62213S Bennett's fracture, unspecified hand, sequela",
  "S62221A Displaced Rolando's fracture, right hand, initial encounter for closed fracture",
  "S62221B Displaced Rolando's fracture, right hand, initial encounter for open fracture",
  "S62221D Displaced Rolando's fracture, right hand, subsequent encounter for fracture with routine",
  'healing',
  "S62221G Displaced Rolando's fracture, right hand, subsequent encounter for fracture with delayed",
  'healing',
  "S62221K Displaced Rolando's fracture, right hand, subsequent encounter for fracture with nonunion",
  "S62221P Displaced Rolando's fracture, right hand, subsequent encounter for fracture with malunion",
  "S62221S Displaced Rolando's fracture, right hand, sequela",
  "S62222A Displaced Rolando's fracture, left hand, initial encounter for closed fracture",
  "S62222B Displaced Rolando's fracture, left hand, initial encounter for open fracture",
  "S62222D Displaced Rolando's fracture, left hand, subsequent encounter for fracture with routine healing",
  "S62222G Displaced Rolando's fracture, left hand, subsequent encounter for fracture with delayed",
  'healing',
  "S62222K Displaced Rolando's fracture, left hand, subsequent encounter for fracture with nonunion",
  "S62222P Displaced Rolando's fracture, left hand, subsequent encounter for fracture with malunion",
  "S62222S Displaced Rolando's fracture, left hand, sequela",
  "S62223A Displaced Rolando's fracture, unspecified hand, initial encounter for closed fracture",
  "S62223B Displaced Rolando's fracture, unspecified hand, initial encounter for open fracture",
  "S62223D Displaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'routine healing',
  "S62223G Displaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'delayed healing',
  "S62223K Displaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'nonunion',
  "S62223P Displaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'malunion',
  "S62223S Displaced Rolando's fracture, unspecified hand, sequela",
  "S62224A Nondisplaced Rolando's fracture, right hand, initial encounter for closed fracture",
  "S62224B Nondisplaced Rolando's fracture, right hand, initial encounter for open fracture",
  "S62224D Nondisplaced Rolando's fracture, right hand, subsequent encounter for fracture with routine",
  'healing',
  "S62224G Nondisplaced Rolando's fracture, right hand, subsequent encounter for fracture with delayed",
  'healing',
  "S62224K Nondisplaced Rolando's fracture, right hand, subsequent encounter for fracture with nonunion",
  "S62224P Nondisplaced Rolando's fracture, right hand, subsequent encounter for fracture with malunion",
  "S62224S Nondisplaced Rolando's fracture, right hand, sequela",
  "S62225A Nondisplaced Rolando's fracture, left hand, initial encounter for closed fracture",
  "S62225B Nondisplaced Rolando's fracture, left hand, initial encounter for open fracture",
  "S62225D Nondisplaced Rolando's fracture, left hand, subsequent encounter for fracture with routine",
  'healing',
  "S62225G Nondisplaced Rolando's fracture, left hand, subsequent encounter for fracture with delayed",
  'healing',
  "S62225K Nondisplaced Rolando's fracture, left hand, subsequent encounter for fracture with nonunion",
  "S62225P Nondisplaced Rolando's fracture, left hand, subsequent encounter for fracture with malunion",
  "S62225S Nondisplaced Rolando's fracture, left hand, sequela",
  "S62226A Nondisplaced Rolando's fracture, unspecified hand, initial encounter for closed fracture",
  "S62226B Nondisplaced Rolando's fracture, unspecified hand, initial encounter for open fracture",
  "S62226D Nondisplaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'routine healing',
  "S62226G Nondisplaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'delayed healing',
  "S62226K Nondisplaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'nonunion',
  "S62226P Nondisplaced Rolando's fracture, unspecified hand, subsequent encounter for fracture with",
  'malunion',
  "S62226S Nondisplaced Rolando's fracture, unspecified hand, sequela",
  'S62231A Other displaced fracture of base of first metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62231B Other displaced fracture of base of first metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62231D Other displaced fracture of base of first metacarpal bone, right hand, subsequent encounter',
  'for fracture with routine healing',
  'S62231G Other displaced fracture of base of first metacarpal bone, right hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62231K Other displaced fracture of base of first metacarpal bone, right hand, subsequent encounter',
  'for fracture with nonunion',
  'S62231P Other displaced fracture of base of first metacarpal bone, right hand, subsequent encounter',
  'for fracture with malunion',
  'S62231S Other displaced fracture of base of first metacarpal bone, right hand, sequela',
  'S62232A Other displaced fracture of base of first metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62232B Other displaced fracture of base of first metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62232D Other displaced fracture of base of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62232G Other displaced fracture of base of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62232K Other displaced fracture of base of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62232P Other displaced fracture of base of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62232S Other displaced fracture of base of first metacarpal bone, left hand, sequela',
  'S62233A Other displaced fracture of base of first metacarpal bone, unspecified hand, initial encounter',
  'for closed fracture',
  'S62233B Other displaced fracture of base of first metacarpal bone, unspecified hand, initial encounter',
  'for open fracture',
  'S62233D Other displaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with routine healing',
  'S62233G Other displaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with delayed healing',
  'S62233K Other displaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with nonunion',
  'S62233P Other displaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with malunion',
  'S62233S Other displaced fracture of base of first metacarpal bone, unspecified hand, sequela',
  'S62234A Other nondisplaced fracture of base of first metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62234B Other nondisplaced fracture of base of first metacarpal bone, right hand, initial encounter for',
  'open fracture',
  'S62234D Other nondisplaced fracture of base of first metacarpal bone, right hand, subsequent',
  'encounter for fracture with routine healing',
  'S62234G Other nondisplaced fracture of base of first metacarpal bone, right hand, subsequent',
  'encounter for fracture with delayed healing',
  'S62234K Other nondisplaced fracture of base of first metacarpal bone, right hand, subsequent',
  'encounter for fracture with nonunion',
  'S62234P Other nondisplaced fracture of base of first metacarpal bone, right hand, subsequent',
  'encounter for fracture with malunion',
  'S62234S Other nondisplaced fracture of base of first metacarpal bone, right hand, sequela',
  'S62235A Other nondisplaced fracture of base of first metacarpal bone, left hand, initial encounter for',
  'closed fracture',
  'S62235B Other nondisplaced fracture of base of first metacarpal bone, left hand, initial encounter for',
  'open fracture',
  'S62235D Other nondisplaced fracture of base of first metacarpal bone, left hand, subsequent encounter',
  'for fracture with routine healing',
  'S62235G Other nondisplaced fracture of base of first metacarpal bone, left hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62235K Other nondisplaced fracture of base of first metacarpal bone, left hand, subsequent encounter',
  'for fracture with nonunion',
  'S62235P Other nondisplaced fracture of base of first metacarpal bone, left hand, subsequent encounter',
  'for fracture with malunion',
  'S62235S Other nondisplaced fracture of base of first metacarpal bone, left hand, sequela',
  'S62236A Other nondisplaced fracture of base of first metacarpal bone, unspecified hand, initial',
  'encounter for closed fracture',
  'S62236B Other nondisplaced fracture of base of first metacarpal bone, unspecified hand, initial',
  'encounter for open fracture',
  'S62236D Other nondisplaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with routine healing',
  'S62236G Other nondisplaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with delayed healing',
  'S62236K Other nondisplaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with nonunion',
  'S62236P Other nondisplaced fracture of base of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with malunion',
  'S62236S Other nondisplaced fracture of base of first metacarpal bone, unspecified hand, sequela',
  'S62241A Displaced fracture of shaft of first metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62241B Displaced fracture of shaft of first metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62241D Displaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62241G Displaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62241K Displaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62241P Displaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62241S Displaced fracture of shaft of first metacarpal bone, right hand, sequela',
  'S62242A Displaced fracture of shaft of first metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62242B Displaced fracture of shaft of first metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62242D Displaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62242G Displaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62242K Displaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62242P Displaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62242S Displaced fracture of shaft of first metacarpal bone, left hand, sequela',
  'S62243A Displaced fracture of shaft of first metacarpal bone, unspecified hand, initial encounter for',
  'closed fracture',
  'S62243B Displaced fracture of shaft of first metacarpal bone, unspecified hand, initial encounter for',
  'open fracture',
  'S62243D Displaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with routine healing',
  'S62243G Displaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62243K Displaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with nonunion',
  'S62243P Displaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with malunion',
  'S62243S Displaced fracture of shaft of first metacarpal bone, unspecified hand, sequela',
  'S62244A Nondisplaced fracture of shaft of first metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62244B Nondisplaced fracture of shaft of first metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62244D Nondisplaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62244G Nondisplaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62244K Nondisplaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62244P Nondisplaced fracture of shaft of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62244S Nondisplaced fracture of shaft of first metacarpal bone, right hand, sequela',
  'S62245A Nondisplaced fracture of shaft of first metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62245B Nondisplaced fracture of shaft of first metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62245D Nondisplaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62245G Nondisplaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62245K Nondisplaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62245P Nondisplaced fracture of shaft of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62245S Nondisplaced fracture of shaft of first metacarpal bone, left hand, sequela',
  'S62246A Nondisplaced fracture of shaft of first metacarpal bone, unspecified hand, initial encounter for',
  'closed fracture',
  'S62246B Nondisplaced fracture of shaft of first metacarpal bone, unspecified hand, initial encounter for',
  'open fracture',
  'S62246D Nondisplaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with routine healing',
  'S62246G Nondisplaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with delayed healing',
  'S62246K Nondisplaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with nonunion',
  'S62246P Nondisplaced fracture of shaft of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with malunion',
  'S62246S Nondisplaced fracture of shaft of first metacarpal bone, unspecified hand, sequela',
  'S62251A Displaced fracture of neck of first metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62251B Displaced fracture of neck of first metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62251D Displaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62251G Displaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62251K Displaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62251P Displaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62251S Displaced fracture of neck of first metacarpal bone, right hand, sequela',
  'S62252A Displaced fracture of neck of first metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62252B Displaced fracture of neck of first metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62252D Displaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62252G Displaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62252K Displaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62252P Displaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62252S Displaced fracture of neck of first metacarpal bone, left hand, sequela',
  'S62253A Displaced fracture of neck of first metacarpal bone, unspecified hand, initial encounter for',
  'closed fracture',
  'S62253B Displaced fracture of neck of first metacarpal bone, unspecified hand, initial encounter for',
  'open fracture',
  'S62253D Displaced fracture of neck of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with routine healing',
  'S62253G Displaced fracture of neck of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62253K Displaced fracture of neck of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with nonunion',
  'S62253P Displaced fracture of neck of first metacarpal bone, unspecified hand, subsequent encounter',
  'for fracture with malunion',
  'S62253S Displaced fracture of neck of first metacarpal bone, unspecified hand, sequela',
  'S62254A Nondisplaced fracture of neck of first metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62254B Nondisplaced fracture of neck of first metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62254D Nondisplaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62254G Nondisplaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62254K Nondisplaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62254P Nondisplaced fracture of neck of first metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62254S Nondisplaced fracture of neck of first metacarpal bone, right hand, sequela',
  'S62255A Nondisplaced fracture of neck of first metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62255B Nondisplaced fracture of neck of first metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62255D Nondisplaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62255G Nondisplaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62255K Nondisplaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62255P Nondisplaced fracture of neck of first metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62255S Nondisplaced fracture of neck of first metacarpal bone, left hand, sequela',
  'S62256A Nondisplaced fracture of neck of first metacarpal bone, unspecified hand, initial encounter for',
  'closed fracture',
  'S62256B Nondisplaced fracture of neck of first metacarpal bone, unspecified hand, initial encounter for',
  'open fracture',
  'S62256D Nondisplaced fracture of neck of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with routine healing',
  'S62256G Nondisplaced fracture of neck of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with delayed healing',
  'S62256K Nondisplaced fracture of neck of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with nonunion',
  'S62256P Nondisplaced fracture of neck of first metacarpal bone, unspecified hand, subsequent',
  'encounter for fracture with malunion',
  'S62256S Nondisplaced fracture of neck of first metacarpal bone, unspecified hand, sequela',
  'S62291A Other fracture of first metacarpal bone, right hand, initial encounter for closed fracture',
  'S62291B Other fracture of first metacarpal bone, right hand, initial encounter for open fracture',
  'S62291D Other fracture of first metacarpal bone, right hand, subsequent encounter for fracture with',
  'routine healing',
  'S62291G Other fracture of first metacarpal bone, right hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62291K Other fracture of first metacarpal bone, right hand, subsequent encounter for fracture with',
  'nonunion',
  'S62291P Other fracture of first metacarpal bone, right hand, subsequent encounter for fracture with',
  'malunion',
  'S62291S Other fracture of first metacarpal bone, right hand, sequela',
  'S62292A Other fracture of first metacarpal bone, left hand, initial encounter for closed fracture',
  'S62292B Other fracture of first metacarpal bone, left hand, initial encounter for open fracture',
  'S62292D Other fracture of first metacarpal bone, left hand, subsequent encounter for fracture with',
  'routine healing',
  'S62292G Other fracture of first metacarpal bone, left hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62292K Other fracture of first metacarpal bone, left hand, subsequent encounter for fracture with',
  'nonunion',
  'S62292P Other fracture of first metacarpal bone, left hand, subsequent encounter for fracture with',
  'malunion',
  'S62292S Other fracture of first metacarpal bone, left hand, sequela',
  'S62299A Other fracture of first metacarpal bone, unspecified hand, initial encounter for closed fracture',
  'S62299B Other fracture of first metacarpal bone, unspecified hand, initial encounter for open fracture',
  'S62299D Other fracture of first metacarpal bone, unspecified hand, subsequent encounter for fracture',
  'with routine healing',
  'S62299G Other fracture of first metacarpal bone, unspecified hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62299K Other fracture of first metacarpal bone, unspecified hand, subsequent encounter for fracture',
  'with nonunion',
  'S62299P Other fracture of first metacarpal bone, unspecified hand, subsequent encounter for fracture',
  'with malunion',
  'S62299S Other fracture of first metacarpal bone, unspecified hand, sequela',
  'S62300A Unspecified fracture of second metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62300B Unspecified fracture of second metacarpal bone, right hand, initial encounter for open fracture',
  'S62300D Unspecified fracture of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62300G Unspecified fracture of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62300K Unspecified fracture of second metacarpal bone, right hand, subsequent encounter for fracture',
  'with nonunion',
  'S62300P Unspecified fracture of second metacarpal bone, right hand, subsequent encounter for fracture',
  'with malunion',
  'S62300S Unspecified fracture of second metacarpal bone, right hand, sequela',
  'S62301A Unspecified fracture of second metacarpal bone, left hand, initial encounter for closed fracture',
  'S62301B Unspecified fracture of second metacarpal bone, left hand, initial encounter for open fracture',
  'S62301D Unspecified fracture of second metacarpal bone, left hand, subsequent encounter for fracture',
  'with routine healing',
  'S62301G Unspecified fracture of second metacarpal bone, left hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62301K Unspecified fracture of second metacarpal bone, left hand, subsequent encounter for fracture',
  'with nonunion',
  'S62301P Unspecified fracture of second metacarpal bone, left hand, subsequent encounter for fracture',
  'with malunion',
  'S62301S Unspecified fracture of second metacarpal bone, left hand, sequela',
  'S62302A Unspecified fracture of third metacarpal bone, right hand, initial encounter for closed fracture',
  'S62302B Unspecified fracture of third metacarpal bone, right hand, initial encounter for open fracture',
  'S62302D Unspecified fracture of third metacarpal bone, right hand, subsequent encounter for fracture',
  'with routine healing',
  'S62302G Unspecified fracture of third metacarpal bone, right hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62302K Unspecified fracture of third metacarpal bone, right hand, subsequent encounter for fracture',
  'with nonunion',
  'S62302P Unspecified fracture of third metacarpal bone, right hand, subsequent encounter for fracture',
  'with malunion',
  'S62302S Unspecified fracture of third metacarpal bone, right hand, sequela',
  'S62303A Unspecified fracture of third metacarpal bone, left hand, initial encounter for closed fracture',
  'S62303B Unspecified fracture of third metacarpal bone, left hand, initial encounter for open fracture',
  'S62303D Unspecified fracture of third metacarpal bone, left hand, subsequent encounter for fracture',
  'with routine healing',
  'S62303G Unspecified fracture of third metacarpal bone, left hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62303K Unspecified fracture of third metacarpal bone, left hand, subsequent encounter for fracture',
  'with nonunion',
  'S62303P Unspecified fracture of third metacarpal bone, left hand, subsequent encounter for fracture',
  'with malunion',
  'S62303S Unspecified fracture of third metacarpal bone, left hand, sequela',
  'S62304A Unspecified fracture of fourth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62304B Unspecified fracture of fourth metacarpal bone, right hand, initial encounter for open fracture',
  'S62304D Unspecified fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture',
  'with routine healing',
  'S62304G Unspecified fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62304K Unspecified fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture',
  'with nonunion',
  'S62304P Unspecified fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture',
  'with malunion',
  'S62304S Unspecified fracture of fourth metacarpal bone, right hand, sequela',
  'S62305A Unspecified fracture of fourth metacarpal bone, left hand, initial encounter for closed fracture',
  'S62305B Unspecified fracture of fourth metacarpal bone, left hand, initial encounter for open fracture',
  'S62305D Unspecified fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture',
  'with routine healing',
  'S62305G Unspecified fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62305K Unspecified fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture',
  'with nonunion',
  'S62305P Unspecified fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture',
  'with malunion',
  'S62305S Unspecified fracture of fourth metacarpal bone, left hand, sequela',
  'S62306A Unspecified fracture of fifth metacarpal bone, right hand, initial encounter for closed fracture',
  'S62306B Unspecified fracture of fifth metacarpal bone, right hand, initial encounter for open fracture',
  'S62306D Unspecified fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture',
  'with routine healing',
  'S62306G Unspecified fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62306K Unspecified fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture',
  'with nonunion',
  'S62306P Unspecified fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture',
  'with malunion',
  'S62306S Unspecified fracture of fifth metacarpal bone, right hand, sequela',
  'S62307A Unspecified fracture of fifth metacarpal bone, left hand, initial encounter for closed fracture',
  'S62307B Unspecified fracture of fifth metacarpal bone, left hand, initial encounter for open fracture',
  'S62307D Unspecified fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture',
  'with routine healing',
  'S62307G Unspecified fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture',
  'with delayed healing',
  'S62307K Unspecified fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture',
  'with nonunion',
  'S62307P Unspecified fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture',
  'with malunion',
  'S62307S Unspecified fracture of fifth metacarpal bone, left hand, sequela',
  'S62308A Unspecified fracture of other metacarpal bone, initial encounter for closed fracture',
  'S62308B Unspecified fracture of other metacarpal bone, initial encounter for open fracture',
  'S62308D Unspecified fracture of other metacarpal bone, subsequent encounter for fracture with routine',
  'healing',
  'S62308G Unspecified fracture of other metacarpal bone, subsequent encounter for fracture with',
  'delayed healing',
  'S62308K Unspecified fracture of other metacarpal bone, subsequent encounter for fracture with',
  'nonunion',
  'S62308P Unspecified fracture of other metacarpal bone, subsequent encounter for fracture with',
  'malunion',
  'S62308S Unspecified fracture of other metacarpal bone, sequela',
  'S62309A Unspecified fracture of unspecified metacarpal bone, initial encounter for closed fracture',
  'S62309B Unspecified fracture of unspecified metacarpal bone, initial encounter for open fracture',
  'S62309D Unspecified fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'routine healing',
  'S62309G Unspecified fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'delayed healing',
  'S62309K Unspecified fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'nonunion',
  'S62309P Unspecified fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'malunion',
  'S62309S Unspecified fracture of unspecified metacarpal bone, sequela',
  'S62310A Displaced fracture of base of second metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62310B Displaced fracture of base of second metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62310D Displaced fracture of base of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62310G Displaced fracture of base of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62310K Displaced fracture of base of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62310P Displaced fracture of base of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62310S Displaced fracture of base of second metacarpal bone, right hand, sequela',
  'S62311A Displaced fracture of base of second metacarpal bone. left hand, initial encounter for closed',
  'fracture',
  'S62311B Displaced fracture of base of second metacarpal bone. left hand, initial encounter for open',
  'fracture',
  'S62311D Displaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62311G Displaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62311K Displaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62311P Displaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with malunion',
  'S62311S Displaced fracture of base of second metacarpal bone. left hand, sequela',
  'S62312A Displaced fracture of base of third metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62312B Displaced fracture of base of third metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62312D Displaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62312G Displaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62312K Displaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62312P Displaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62312S Displaced fracture of base of third metacarpal bone, right hand, sequela',
  'S62313A Displaced fracture of base of third metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62313B Displaced fracture of base of third metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62313D Displaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62313G Displaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62313K Displaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62313P Displaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62313S Displaced fracture of base of third metacarpal bone, left hand, sequela',
  'S62314A Displaced fracture of base of fourth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62314B Displaced fracture of base of fourth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62314D Displaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62314G Displaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62314K Displaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62314P Displaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62314S Displaced fracture of base of fourth metacarpal bone, right hand, sequela',
  'S62315A Displaced fracture of base of fourth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62315B Displaced fracture of base of fourth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62315D Displaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62315G Displaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62315K Displaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62315P Displaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62315S Displaced fracture of base of fourth metacarpal bone, left hand, sequela',
  'S62316A Displaced fracture of base of fifth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62316B Displaced fracture of base of fifth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62316D Displaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62316G Displaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62316K Displaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62316P Displaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62316S Displaced fracture of base of fifth metacarpal bone, right hand, sequela',
  'S62317A Displaced fracture of base of fifth metacarpal bone. left hand, initial encounter for closed',
  'fracture',
  'S62317B Displaced fracture of base of fifth metacarpal bone. left hand, initial encounter for open',
  'fracture',
  'S62317D Displaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62317G Displaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62317K Displaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62317P Displaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with malunion',
  'S62317S Displaced fracture of base of fifth metacarpal bone. left hand, sequela',
  'S62318A Displaced fracture of base of other metacarpal bone, initial encounter for closed fracture',
  'S62318B Displaced fracture of base of other metacarpal bone, initial encounter for open fracture',
  'S62318D Displaced fracture of base of other metacarpal bone, subsequent encounter for fracture with',
  'routine healing',
  'S62318G Displaced fracture of base of other metacarpal bone, subsequent encounter for fracture with',
  'delayed healing',
  'S62318K Displaced fracture of base of other metacarpal bone, subsequent encounter for fracture with',
  'nonunion',
  'S62318P Displaced fracture of base of other metacarpal bone, subsequent encounter for fracture with',
  'malunion',
  'S62318S Displaced fracture of base of other metacarpal bone, sequela',
  'S62319A Displaced fracture of base of unspecified metacarpal bone, initial encounter for closed fracture',
  'S62319B Displaced fracture of base of unspecified metacarpal bone, initial encounter for open fracture',
  'S62319D Displaced fracture of base of unspecified metacarpal bone, subsequent encounter for fracture',
  'with routine healing',
  'S62319G Displaced fracture of base of unspecified metacarpal bone, subsequent encounter for fracture',
  'with delayed healing',
  'S62319K Displaced fracture of base of unspecified metacarpal bone, subsequent encounter for fracture',
  'with nonunion',
  'S62319P Displaced fracture of base of unspecified metacarpal bone, subsequent encounter for fracture',
  'with malunion',
  'S62319S Displaced fracture of base of unspecified metacarpal bone, sequela',
  'S62320A Displaced fracture of shaft of second metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62320B Displaced fracture of shaft of second metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62320D Displaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62320G Displaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62320K Displaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62320P Displaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62320S Displaced fracture of shaft of second metacarpal bone, right hand, sequela',
  'S62321A Displaced fracture of shaft of second metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62321B Displaced fracture of shaft of second metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62321D Displaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62321G Displaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62321K Displaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62321P Displaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62321S Displaced fracture of shaft of second metacarpal bone, left hand, sequela',
  'S62322A Displaced fracture of shaft of third metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62322B Displaced fracture of shaft of third metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62322D Displaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62322G Displaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62322K Displaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62322P Displaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62322S Displaced fracture of shaft of third metacarpal bone, right hand, sequela',
  'S62323A Displaced fracture of shaft of third metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62323B Displaced fracture of shaft of third metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62323D Displaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62323G Displaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62323K Displaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62323P Displaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62323S Displaced fracture of shaft of third metacarpal bone, left hand, sequela',
  'S62324A Displaced fracture of shaft of fourth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62324B Displaced fracture of shaft of fourth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62324D Displaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62324G Displaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62324K Displaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62324P Displaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62324S Displaced fracture of shaft of fourth metacarpal bone, right hand, sequela',
  'S62325A Displaced fracture of shaft of fourth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62325B Displaced fracture of shaft of fourth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62325D Displaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62325G Displaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62325K Displaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62325P Displaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62325S Displaced fracture of shaft of fourth metacarpal bone, left hand, sequela',
  'S62326A Displaced fracture of shaft of fifth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62326B Displaced fracture of shaft of fifth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62326D Displaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62326G Displaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62326K Displaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62326P Displaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62326S Displaced fracture of shaft of fifth metacarpal bone, right hand, sequela',
  'S62327A Displaced fracture of shaft of fifth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62327B Displaced fracture of shaft of fifth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62327D Displaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62327G Displaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62327K Displaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62327P Displaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62327S Displaced fracture of shaft of fifth metacarpal bone, left hand, sequela',
  'S62328A Displaced fracture of shaft of other metacarpal bone, initial encounter for closed fracture',
  'S62328B Displaced fracture of shaft of other metacarpal bone, initial encounter for open fracture',
  'S62328D Displaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture with',
  'routine healing',
  'S62328G Displaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture with',
  'delayed healing',
  'S62328K Displaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture with',
  'nonunion',
  'S62328P Displaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture with',
  'malunion',
  'S62328S Displaced fracture of shaft of other metacarpal bone, sequela',
  'S62329A Displaced fracture of shaft of unspecified metacarpal bone, initial encounter for closed fracture',
  'S62329B Displaced fracture of shaft of unspecified metacarpal bone, initial encounter for open fracture',
  'S62329D Displaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for fracture',
  'with routine healing',
  'S62329G Displaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for fracture',
  'with delayed healing',
  'S62329K Displaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for fracture',
  'with nonunion',
  'S62329P Displaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for fracture',
  'with malunion',
  'S62329S Displaced fracture of shaft of unspecified metacarpal bone, sequela',
  'S62330A Displaced fracture of neck of second metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62330B Displaced fracture of neck of second metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62330D Displaced fracture of neck of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62330G Displaced fracture of neck of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62330K Displaced fracture of neck of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62330P Displaced fracture of neck of second metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62330S Displaced fracture of neck of second metacarpal bone, right hand, sequela',
  'S62331A Displaced fracture of neck of second metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62331B Displaced fracture of neck of second metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62331D Displaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62331G Displaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62331K Displaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62331P Displaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62331S Displaced fracture of neck of second metacarpal bone, left hand, sequela',
  'S62332A Displaced fracture of neck of third metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62332B Displaced fracture of neck of third metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62332D Displaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62332G Displaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62332K Displaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62332P Displaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62332S Displaced fracture of neck of third metacarpal bone, right hand, sequela',
  'S62333A Displaced fracture of neck of third metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62333B Displaced fracture of neck of third metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62333D Displaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62333G Displaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62333K Displaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62333P Displaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62333S Displaced fracture of neck of third metacarpal bone, left hand, sequela',
  'S62334A Displaced fracture of neck of fourth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62334B Displaced fracture of neck of fourth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62334D Displaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62334G Displaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62334K Displaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62334P Displaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62334S Displaced fracture of neck of fourth metacarpal bone, right hand, sequela',
  'S62335A Displaced fracture of neck of fourth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62335B Displaced fracture of neck of fourth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62335D Displaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62335G Displaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62335K Displaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62335P Displaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62335S Displaced fracture of neck of fourth metacarpal bone, left hand, sequela',
  'S62336A Displaced fracture of neck of fifth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62336B Displaced fracture of neck of fifth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62336D Displaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62336G Displaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62336K Displaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62336P Displaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62336S Displaced fracture of neck of fifth metacarpal bone, right hand, sequela',
  'S62337A Displaced fracture of neck of fifth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62337B Displaced fracture of neck of fifth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62337D Displaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62337G Displaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62337K Displaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62337P Displaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62337S Displaced fracture of neck of fifth metacarpal bone, left hand, sequela',
  'S62338A Displaced fracture of neck of other metacarpal bone, initial encounter for closed fracture',
  'S62338B Displaced fracture of neck of other metacarpal bone, initial encounter for open fracture',
  'S62338D Displaced fracture of neck of other metacarpal bone, subsequent encounter for fracture with',
  'routine healing',
  'S62338G Displaced fracture of neck of other metacarpal bone, subsequent encounter for fracture with',
  'delayed healing',
  'S62338K Displaced fracture of neck of other metacarpal bone, subsequent encounter for fracture with',
  'nonunion',
  'S62338P Displaced fracture of neck of other metacarpal bone, subsequent encounter for fracture with',
  'malunion',
  'S62338S Displaced fracture of neck of other metacarpal bone, sequela',
  'S62339A Displaced fracture of neck of unspecified metacarpal bone, initial encounter for closed fracture',
  'S62339B Displaced fracture of neck of unspecified metacarpal bone, initial encounter for open fracture',
  'S62339D Displaced fracture of neck of unspecified metacarpal bone, subsequent encounter for fracture',
  'with routine healing',
  'S62339G Displaced fracture of neck of unspecified metacarpal bone, subsequent encounter for fracture',
  'with delayed healing',
  'S62339K Displaced fracture of neck of unspecified metacarpal bone, subsequent encounter for fracture',
  'with nonunion',
  'S62339P Displaced fracture of neck of unspecified metacarpal bone, subsequent encounter for fracture',
  'with malunion',
  'S62339S Displaced fracture of neck of unspecified metacarpal bone, sequela',
  'S62340A Nondisplaced fracture of base of second metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62340B Nondisplaced fracture of base of second metacarpal bone, right hand, initial encounter for',
  'open fracture',
  'S62340D Nondisplaced fracture of base of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with routine healing',
  'S62340G Nondisplaced fracture of base of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62340K Nondisplaced fracture of base of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with nonunion',
  'S62340P Nondisplaced fracture of base of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with malunion',
  'S62340S Nondisplaced fracture of base of second metacarpal bone, right hand, sequela',
  'S62341A Nondisplaced fracture of base of second metacarpal bone. left hand, initial encounter for',
  'closed fracture',
  'S62341B Nondisplaced fracture of base of second metacarpal bone. left hand, initial encounter for open',
  'fracture',
  'S62341D Nondisplaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62341G Nondisplaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62341K Nondisplaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62341P Nondisplaced fracture of base of second metacarpal bone. left hand, subsequent encounter for',
  'fracture with malunion',
  'S62341S Nondisplaced fracture of base of second metacarpal bone. left hand, sequela',
  'S62342A Nondisplaced fracture of base of third metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62342B Nondisplaced fracture of base of third metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62342D Nondisplaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62342G Nondisplaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62342K Nondisplaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62342P Nondisplaced fracture of base of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62342S Nondisplaced fracture of base of third metacarpal bone, right hand, sequela',
  'S62343A Nondisplaced fracture of base of third metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62343B Nondisplaced fracture of base of third metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62343D Nondisplaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62343G Nondisplaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62343K Nondisplaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62343P Nondisplaced fracture of base of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62343S Nondisplaced fracture of base of third metacarpal bone, left hand, sequela',
  'S62344A Nondisplaced fracture of base of fourth metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62344B Nondisplaced fracture of base of fourth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62344D Nondisplaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with routine healing',
  'S62344G Nondisplaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62344K Nondisplaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with nonunion',
  'S62344P Nondisplaced fracture of base of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with malunion',
  'S62344S Nondisplaced fracture of base of fourth metacarpal bone, right hand, sequela',
  'S62345A Nondisplaced fracture of base of fourth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62345B Nondisplaced fracture of base of fourth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62345D Nondisplaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62345G Nondisplaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62345K Nondisplaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62345P Nondisplaced fracture of base of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62345S Nondisplaced fracture of base of fourth metacarpal bone, left hand, sequela',
  'S62346A Nondisplaced fracture of base of fifth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62346B Nondisplaced fracture of base of fifth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62346D Nondisplaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62346G Nondisplaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62346K Nondisplaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62346P Nondisplaced fracture of base of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62346S Nondisplaced fracture of base of fifth metacarpal bone, right hand, sequela',
  'S62347A Nondisplaced fracture of base of fifth metacarpal bone. left hand, initial encounter for closed',
  'fracture',
  'S62347B Nondisplaced fracture of base of fifth metacarpal bone. left hand, initial encounter for open',
  'fracture',
  'S62347D Nondisplaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62347G Nondisplaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62347K Nondisplaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62347P Nondisplaced fracture of base of fifth metacarpal bone. left hand, subsequent encounter for',
  'fracture with malunion',
  'S62347S Nondisplaced fracture of base of fifth metacarpal bone. left hand, sequela',
  'S62348A Nondisplaced fracture of base of other metacarpal bone, initial encounter for closed fracture',
  'S62348B Nondisplaced fracture of base of other metacarpal bone, initial encounter for open fracture',
  'S62348D Nondisplaced fracture of base of other metacarpal bone, subsequent encounter for fracture',
  'with routine healing',
  'S62348G Nondisplaced fracture of base of other metacarpal bone, subsequent encounter for fracture',
  'with delayed healing',
  'S62348K Nondisplaced fracture of base of other metacarpal bone, subsequent encounter for fracture',
  'with nonunion',
  'S62348P Nondisplaced fracture of base of other metacarpal bone, subsequent encounter for fracture',
  'with malunion',
  'S62348S Nondisplaced fracture of base of other metacarpal bone, sequela',
  'S62349A Nondisplaced fracture of base of unspecified metacarpal bone, initial encounter for closed',
  'fracture',
  'S62349B Nondisplaced fracture of base of unspecified metacarpal bone, initial encounter for open',
  'fracture',
  'S62349D Nondisplaced fracture of base of unspecified metacarpal bone, subsequent encounter for',
  'fracture with routine healing',
  'S62349G Nondisplaced fracture of base of unspecified metacarpal bone, subsequent encounter for',
  'fracture with delayed healing',
  'S62349K Nondisplaced fracture of base of unspecified metacarpal bone, subsequent encounter for',
  'fracture with nonunion',
  'S62349P Nondisplaced fracture of base of unspecified metacarpal bone, subsequent encounter for',
  'fracture with malunion',
  'S62349S Nondisplaced fracture of base of unspecified metacarpal bone, sequela',
  'S62350A Nondisplaced fracture of shaft of second metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62350B Nondisplaced fracture of shaft of second metacarpal bone, right hand, initial encounter for',
  'open fracture',
  'S62350D Nondisplaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with routine healing',
  'S62350G Nondisplaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62350K Nondisplaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with nonunion',
  'S62350P Nondisplaced fracture of shaft of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with malunion',
  'S62350S Nondisplaced fracture of shaft of second metacarpal bone, right hand, sequela',
  'S62351A Nondisplaced fracture of shaft of second metacarpal bone, left hand, initial encounter for',
  'closed fracture',
  'S62351B Nondisplaced fracture of shaft of second metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62351D Nondisplaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter',
  'for fracture with routine healing',
  'S62351G Nondisplaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62351K Nondisplaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter',
  'for fracture with nonunion',
  'S62351P Nondisplaced fracture of shaft of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62351S Nondisplaced fracture of shaft of second metacarpal bone, left hand, sequela',
  'S62352A Nondisplaced fracture of shaft of third metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62352B Nondisplaced fracture of shaft of third metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62352D Nondisplaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62352G Nondisplaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62352K Nondisplaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62352P Nondisplaced fracture of shaft of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62352S Nondisplaced fracture of shaft of third metacarpal bone, right hand, sequela',
  'S62353A Nondisplaced fracture of shaft of third metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62353B Nondisplaced fracture of shaft of third metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62353D Nondisplaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62353G Nondisplaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62353K Nondisplaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62353P Nondisplaced fracture of shaft of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62353S Nondisplaced fracture of shaft of third metacarpal bone, left hand, sequela',
  'S62354A Nondisplaced fracture of shaft of fourth metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62354B Nondisplaced fracture of shaft of fourth metacarpal bone, right hand, initial encounter for',
  'open fracture',
  'S62354D Nondisplaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with routine healing',
  'S62354G Nondisplaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62354K Nondisplaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with nonunion',
  'S62354P Nondisplaced fracture of shaft of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with malunion',
  'S62354S Nondisplaced fracture of shaft of fourth metacarpal bone, right hand, sequela',
  'S62355A Nondisplaced fracture of shaft of fourth metacarpal bone, left hand, initial encounter for',
  'closed fracture',
  'S62355B Nondisplaced fracture of shaft of fourth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62355D Nondisplaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62355G Nondisplaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62355K Nondisplaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62355P Nondisplaced fracture of shaft of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62355S Nondisplaced fracture of shaft of fourth metacarpal bone, left hand, sequela',
  'S62356A Nondisplaced fracture of shaft of fifth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62356B Nondisplaced fracture of shaft of fifth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62356D Nondisplaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62356G Nondisplaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62356K Nondisplaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62356P Nondisplaced fracture of shaft of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62356S Nondisplaced fracture of shaft of fifth metacarpal bone, right hand, sequela',
  'S62357A Nondisplaced fracture of shaft of fifth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62357B Nondisplaced fracture of shaft of fifth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62357D Nondisplaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62357G Nondisplaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62357K Nondisplaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62357P Nondisplaced fracture of shaft of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62357S Nondisplaced fracture of shaft of fifth metacarpal bone, left hand, sequela',
  'S62358A Nondisplaced fracture of shaft of other metacarpal bone, initial encounter for closed fracture',
  'S62358B Nondisplaced fracture of shaft of other metacarpal bone, initial encounter for open fracture',
  'S62358D Nondisplaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture',
  'with routine healing',
  'S62358G Nondisplaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture',
  'with delayed healing',
  'S62358K Nondisplaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture',
  'with nonunion',
  'S62358P Nondisplaced fracture of shaft of other metacarpal bone, subsequent encounter for fracture',
  'with malunion',
  'S62358S Nondisplaced fracture of shaft of other metacarpal bone, sequela',
  'S62359A Nondisplaced fracture of shaft of unspecified metacarpal bone, initial encounter for closed',
  'fracture',
  'S62359B Nondisplaced fracture of shaft of unspecified metacarpal bone, initial encounter for open',
  'fracture',
  'S62359D Nondisplaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for',
  'fracture with routine healing',
  'S62359G Nondisplaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for',
  'fracture with delayed healing',
  'S62359K Nondisplaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for',
  'fracture with nonunion',
  'S62359P Nondisplaced fracture of shaft of unspecified metacarpal bone, subsequent encounter for',
  'fracture with malunion',
  'S62359S Nondisplaced fracture of shaft of unspecified metacarpal bone, sequela',
  'S62360A Nondisplaced fracture of neck of second metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62360B Nondisplaced fracture of neck of second metacarpal bone, right hand, initial encounter for',
  'open fracture',
  'S62360D Nondisplaced fracture of neck of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with routine healing',
  'S62360G Nondisplaced fracture of neck of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62360K Nondisplaced fracture of neck of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with nonunion',
  'S62360P Nondisplaced fracture of neck of second metacarpal bone, right hand, subsequent encounter',
  'for fracture with malunion',
  'S62360S Nondisplaced fracture of neck of second metacarpal bone, right hand, sequela',
  'S62361A Nondisplaced fracture of neck of second metacarpal bone, left hand, initial encounter for',
  'closed fracture',
  'S62361B Nondisplaced fracture of neck of second metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62361D Nondisplaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62361G Nondisplaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62361K Nondisplaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62361P Nondisplaced fracture of neck of second metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62361S Nondisplaced fracture of neck of second metacarpal bone, left hand, sequela',
  'S62362A Nondisplaced fracture of neck of third metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62362B Nondisplaced fracture of neck of third metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62362D Nondisplaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62362G Nondisplaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62362K Nondisplaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62362P Nondisplaced fracture of neck of third metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62362S Nondisplaced fracture of neck of third metacarpal bone, right hand, sequela',
  'S62363A Nondisplaced fracture of neck of third metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62363B Nondisplaced fracture of neck of third metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62363D Nondisplaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62363G Nondisplaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62363K Nondisplaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62363P Nondisplaced fracture of neck of third metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62363S Nondisplaced fracture of neck of third metacarpal bone, left hand, sequela',
  'S62364A Nondisplaced fracture of neck of fourth metacarpal bone, right hand, initial encounter for',
  'closed fracture',
  'S62364B Nondisplaced fracture of neck of fourth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62364D Nondisplaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with routine healing',
  'S62364G Nondisplaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with delayed healing',
  'S62364K Nondisplaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with nonunion',
  'S62364P Nondisplaced fracture of neck of fourth metacarpal bone, right hand, subsequent encounter',
  'for fracture with malunion',
  'S62364S Nondisplaced fracture of neck of fourth metacarpal bone, right hand, sequela',
  'S62365A Nondisplaced fracture of neck of fourth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62365B Nondisplaced fracture of neck of fourth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62365D Nondisplaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62365G Nondisplaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62365K Nondisplaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62365P Nondisplaced fracture of neck of fourth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62365S Nondisplaced fracture of neck of fourth metacarpal bone, left hand, sequela',
  'S62366A Nondisplaced fracture of neck of fifth metacarpal bone, right hand, initial encounter for closed',
  'fracture',
  'S62366B Nondisplaced fracture of neck of fifth metacarpal bone, right hand, initial encounter for open',
  'fracture',
  'S62366D Nondisplaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with routine healing',
  'S62366G Nondisplaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62366K Nondisplaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with nonunion',
  'S62366P Nondisplaced fracture of neck of fifth metacarpal bone, right hand, subsequent encounter for',
  'fracture with malunion',
  'S62366S Nondisplaced fracture of neck of fifth metacarpal bone, right hand, sequela',
  'S62367A Nondisplaced fracture of neck of fifth metacarpal bone, left hand, initial encounter for closed',
  'fracture',
  'S62367B Nondisplaced fracture of neck of fifth metacarpal bone, left hand, initial encounter for open',
  'fracture',
  'S62367D Nondisplaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with routine healing',
  'S62367G Nondisplaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with delayed healing',
  'S62367K Nondisplaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with nonunion',
  'S62367P Nondisplaced fracture of neck of fifth metacarpal bone, left hand, subsequent encounter for',
  'fracture with malunion',
  'S62367S Nondisplaced fracture of neck of fifth metacarpal bone, left hand, sequela',
  'S62368A Nondisplaced fracture of neck of other metacarpal bone, initial encounter for closed fracture',
  'S62368B Nondisplaced fracture of neck of other metacarpal bone, initial encounter for open fracture',
  'S62368D Nondisplaced fracture of neck of other metacarpal bone, subsequent encounter for fracture',
  'with routine healing',
  'S62368G Nondisplaced fracture of neck of other metacarpal bone, subsequent encounter for fracture',
  'with delayed healing',
  'S62368K Nondisplaced fracture of neck of other metacarpal bone, subsequent encounter for fracture',
  'with nonunion',
  'S62368P Nondisplaced fracture of neck of other metacarpal bone, subsequent encounter for fracture',
  'with malunion',
  'S62368S Nondisplaced fracture of neck of other metacarpal bone, sequela',
  'S62369A Nondisplaced fracture of neck of unspecified metacarpal bone, initial encounter for closed',
  'fracture',
  'S62369B Nondisplaced fracture of neck of unspecified metacarpal bone, initial encounter for open',
  'fracture',
  'S62369D Nondisplaced fracture of neck of unspecified metacarpal bone, subsequent encounter for',
  'fracture with routine healing',
  'S62369G Nondisplaced fracture of neck of unspecified metacarpal bone, subsequent encounter for',
  'fracture with delayed healing',
  'S62369K Nondisplaced fracture of neck of unspecified metacarpal bone, subsequent encounter for',
  'fracture with nonunion',
  'S62369P Nondisplaced fracture of neck of unspecified metacarpal bone, subsequent encounter for',
  'fracture with malunion',
  'S62369S Nondisplaced fracture of neck of unspecified metacarpal bone, sequela',
  'S62390A Other fracture of second metacarpal bone, right hand, initial encounter for closed fracture',
  'S62390B Other fracture of second metacarpal bone, right hand, initial encounter for open fracture',
  'S62390D Other fracture of second metacarpal bone, right hand, subsequent encounter for fracture with',
  'routine healing',
  'S62390G Other fracture of second metacarpal bone, right hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62390K Other fracture of second metacarpal bone, right hand, subsequent encounter for fracture with',
  'nonunion',
  'S62390P Other fracture of second metacarpal bone, right hand, subsequent encounter for fracture with',
  'malunion',
  'S62390S Other fracture of second metacarpal bone, right hand, sequela',
  'S62391A Other fracture of second metacarpal bone, left hand, initial encounter for closed fracture',
  'S62391B Other fracture of second metacarpal bone, left hand, initial encounter for open fracture',
  'S62391D Other fracture of second metacarpal bone, left hand, subsequent encounter for fracture with',
  'routine healing',
  'S62391G Other fracture of second metacarpal bone, left hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62391K Other fracture of second metacarpal bone, left hand, subsequent encounter for fracture with',
  'nonunion',
  'S62391P Other fracture of second metacarpal bone, left hand, subsequent encounter for fracture with',
  'malunion',
  'S62391S Other fracture of second metacarpal bone, left hand, sequela',
  'S62392A Other fracture of third metacarpal bone, right hand, initial encounter for closed fracture',
  'S62392B Other fracture of third metacarpal bone, right hand, initial encounter for open fracture',
  'S62392D Other fracture of third metacarpal bone, right hand, subsequent encounter for fracture with',
  'routine healing',
  'S62392G Other fracture of third metacarpal bone, right hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62392K Other fracture of third metacarpal bone, right hand, subsequent encounter for fracture with',
  'nonunion',
  'S62392P Other fracture of third metacarpal bone, right hand, subsequent encounter for fracture with',
  'malunion',
  'S62392S Other fracture of third metacarpal bone, right hand, sequela',
  'S62393A Other fracture of third metacarpal bone, left hand, initial encounter for closed fracture',
  'S62393B Other fracture of third metacarpal bone, left hand, initial encounter for open fracture',
  'S62393D Other fracture of third metacarpal bone, left hand, subsequent encounter for fracture with',
  'routine healing',
  'S62393G Other fracture of third metacarpal bone, left hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62393K Other fracture of third metacarpal bone, left hand, subsequent encounter for fracture with',
  'nonunion',
  'S62393P Other fracture of third metacarpal bone, left hand, subsequent encounter for fracture with',
  'malunion',
  'S62393S Other fracture of third metacarpal bone, left hand, sequela',
  'S62394A Other fracture of fourth metacarpal bone, right hand, initial encounter for closed fracture',
  'S62394B Other fracture of fourth metacarpal bone, right hand, initial encounter for open fracture',
  'S62394D Other fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture with',
  'routine healing',
  'S62394G Other fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62394K Other fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture with',
  'nonunion',
  'S62394P Other fracture of fourth metacarpal bone, right hand, subsequent encounter for fracture with',
  'malunion',
  'S62394S Other fracture of fourth metacarpal bone, right hand, sequela',
  'S62395A Other fracture of fourth metacarpal bone, left hand, initial encounter for closed fracture',
  'S62395B Other fracture of fourth metacarpal bone, left hand, initial encounter for open fracture',
  'S62395D Other fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture with',
  'routine healing',
  'S62395G Other fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62395K Other fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture with',
  'nonunion',
  'S62395P Other fracture of fourth metacarpal bone, left hand, subsequent encounter for fracture with',
  'malunion',
  'S62395S Other fracture of fourth metacarpal bone, left hand, sequela',
  'S62396A Other fracture of fifth metacarpal bone, right hand, initial encounter for closed fracture',
  'S62396B Other fracture of fifth metacarpal bone, right hand, initial encounter for open fracture',
  'S62396D Other fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture with',
  'routine healing',
  'S62396G Other fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62396K Other fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture with',
  'nonunion',
  'S62396P Other fracture of fifth metacarpal bone, right hand, subsequent encounter for fracture with',
  'malunion',
  'S62396S Other fracture of fifth metacarpal bone, right hand, sequela',
  'S62397A Other fracture of fifth metacarpal bone, left hand, initial encounter for closed fracture',
  'S62397B Other fracture of fifth metacarpal bone, left hand, initial encounter for open fracture',
  'S62397D Other fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture with',
  'routine healing',
  'S62397G Other fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture with',
  'delayed healing',
  'S62397K Other fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture with',
  'nonunion',
  'S62397P Other fracture of fifth metacarpal bone, left hand, subsequent encounter for fracture with',
  'malunion',
  'S62397S Other fracture of fifth metacarpal bone, left hand, sequela',
  'S62398A Other fracture of other metacarpal bone, initial encounter for closed fracture',
  'S62398B Other fracture of other metacarpal bone, initial encounter for open fracture',
  'S62398D Other fracture of other metacarpal bone, subsequent encounter for fracture with routine',
  'healing',
  'S62398G Other fracture of other metacarpal bone, subsequent encounter for fracture with delayed',
  'healing',
  'S62398K Other fracture of other metacarpal bone, subsequent encounter for fracture with nonunion',
  'S62398P Other fracture of other metacarpal bone, subsequent encounter for fracture with malunion',
  'S62398S Other fracture of other metacarpal bone, sequela',
  'S62399A Other fracture of unspecified metacarpal bone, initial encounter for closed fracture',
  'S62399B Other fracture of unspecified metacarpal bone, initial encounter for open fracture',
  'S62399D Other fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'routine healing',
  'S62399G Other fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'delayed healing',
  'S62399K Other fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'nonunion',
  'S62399P Other fracture of unspecified metacarpal bone, subsequent encounter for fracture with',
  'malunion',
  'S62399S Other fracture of unspecified metacarpal bone, sequela',
  'S62501A Fracture of unspecified phalanx of right thumb, initial encounter for closed fracture',
  'S62501B Fracture of unspecified phalanx of right thumb, initial encounter for open fracture',
  'S62501D Fracture of unspecified phalanx of right thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62501G Fracture of unspecified phalanx of right thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62501K Fracture of unspecified phalanx of right thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62501P Fracture of unspecified phalanx of right thumb, subsequent encounter for fracture with',
  'malunion',
  'S62501S Fracture of unspecified phalanx of right thumb, sequela',
  'S62502A Fracture of unspecified phalanx of left thumb, initial encounter for closed fracture',
  'S62502B Fracture of unspecified phalanx of left thumb, initial encounter for open fracture',
  'S62502D Fracture of unspecified phalanx of left thumb, subsequent encounter for fracture with routine',
  'healing',
  'S62502G Fracture of unspecified phalanx of left thumb, subsequent encounter for fracture with delayed',
  'healing',
  'S62502K Fracture of unspecified phalanx of left thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62502P Fracture of unspecified phalanx of left thumb, subsequent encounter for fracture with',
  'malunion',
  'S62502S Fracture of unspecified phalanx of left thumb, sequela',
  'S62509A Fracture of unspecified phalanx of unspecified thumb, initial encounter for closed fracture',
  'S62509B Fracture of unspecified phalanx of unspecified thumb, initial encounter for open fracture',
  'S62509D Fracture of unspecified phalanx of unspecified thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62509G Fracture of unspecified phalanx of unspecified thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62509K Fracture of unspecified phalanx of unspecified thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62509P Fracture of unspecified phalanx of unspecified thumb, subsequent encounter for fracture with',
  'malunion',
  'S62509S Fracture of unspecified phalanx of unspecified thumb, sequela',
  'S62511A Displaced fracture of proximal phalanx of right thumb, initial encounter for closed fracture',
  'S62511B Displaced fracture of proximal phalanx of right thumb, initial encounter for open fracture',
  'S62511D Displaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62511G Displaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62511K Displaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62511P Displaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture with',
  'malunion',
  'S62511S Displaced fracture of proximal phalanx of right thumb, sequela',
  'S62512A Displaced fracture of proximal phalanx of left thumb, initial encounter for closed fracture',
  'S62512B Displaced fracture of proximal phalanx of left thumb, initial encounter for open fracture',
  'S62512D Displaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62512G Displaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62512K Displaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62512P Displaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture with',
  'malunion',
  'S62512S Displaced fracture of proximal phalanx of left thumb, sequela',
  'S62513A Displaced fracture of proximal phalanx of unspecified thumb, initial encounter for closed',
  'fracture',
  'S62513B Displaced fracture of proximal phalanx of unspecified thumb, initial encounter for open',
  'fracture',
  'S62513D Displaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with routine healing',
  'S62513G Displaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with delayed healing',
  'S62513K Displaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with nonunion',
  'S62513P Displaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with malunion',
  'S62513S Displaced fracture of proximal phalanx of unspecified thumb, sequela',
  'S62514A Nondisplaced fracture of proximal phalanx of right thumb, initial encounter for closed fracture',
  'S62514B Nondisplaced fracture of proximal phalanx of right thumb, initial encounter for open fracture',
  'S62514D Nondisplaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture',
  'with routine healing',
  'S62514G Nondisplaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture',
  'with delayed healing',
  'S62514K Nondisplaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture',
  'with nonunion',
  'S62514P Nondisplaced fracture of proximal phalanx of right thumb, subsequent encounter for fracture',
  'with malunion',
  'S62514S Nondisplaced fracture of proximal phalanx of right thumb, sequela',
  'S62515A Nondisplaced fracture of proximal phalanx of left thumb, initial encounter for closed fracture',
  'S62515B Nondisplaced fracture of proximal phalanx of left thumb, initial encounter for open fracture',
  'S62515D Nondisplaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture',
  'with routine healing',
  'S62515G Nondisplaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture',
  'with delayed healing',
  'S62515K Nondisplaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture',
  'with nonunion',
  'S62515P Nondisplaced fracture of proximal phalanx of left thumb, subsequent encounter for fracture',
  'with malunion',
  'S62515S Nondisplaced fracture of proximal phalanx of left thumb, sequela',
  'S62516A Nondisplaced fracture of proximal phalanx of unspecified thumb, initial encounter for closed',
  'fracture',
  'S62516B Nondisplaced fracture of proximal phalanx of unspecified thumb, initial encounter for open',
  'fracture',
  'S62516D Nondisplaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with routine healing',
  'S62516G Nondisplaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with delayed healing',
  'S62516K Nondisplaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with nonunion',
  'S62516P Nondisplaced fracture of proximal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with malunion',
  'S62516S Nondisplaced fracture of proximal phalanx of unspecified thumb, sequela',
  'S62521A Displaced fracture of distal phalanx of right thumb, initial encounter for closed fracture',
  'S62521B Displaced fracture of distal phalanx of right thumb, initial encounter for open fracture',
  'S62521D Displaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62521G Displaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62521K Displaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62521P Displaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'malunion',
  'S62521S Displaced fracture of distal phalanx of right thumb, sequela',
  'S62522A Displaced fracture of distal phalanx of left thumb, initial encounter for closed fracture',
  'S62522B Displaced fracture of distal phalanx of left thumb, initial encounter for open fracture',
  'S62522D Displaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62522G Displaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62522K Displaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62522P Displaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'malunion',
  'S62522S Displaced fracture of distal phalanx of left thumb, sequela',
  'S62523A Displaced fracture of distal phalanx of unspecified thumb, initial encounter for closed fracture',
  'S62523B Displaced fracture of distal phalanx of unspecified thumb, initial encounter for open fracture',
  'S62523D Displaced fracture of distal phalanx of unspecified thumb, subsequent encounter for fracture',
  'with routine healing',
  'S62523G Displaced fracture of distal phalanx of unspecified thumb, subsequent encounter for fracture',
  'with delayed healing',
  'S62523K Displaced fracture of distal phalanx of unspecified thumb, subsequent encounter for fracture',
  'with nonunion',
  'S62523P Displaced fracture of distal phalanx of unspecified thumb, subsequent encounter for fracture',
  'with malunion',
  'S62523S Displaced fracture of distal phalanx of unspecified thumb, sequela',
  'S62524A Nondisplaced fracture of distal phalanx of right thumb, initial encounter for closed fracture',
  'S62524B Nondisplaced fracture of distal phalanx of right thumb, initial encounter for open fracture',
  'S62524D Nondisplaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62524G Nondisplaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62524K Nondisplaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62524P Nondisplaced fracture of distal phalanx of right thumb, subsequent encounter for fracture with',
  'malunion',
  'S62524S Nondisplaced fracture of distal phalanx of right thumb, sequela',
  'S62525A Nondisplaced fracture of distal phalanx of left thumb, initial encounter for closed fracture',
  'S62525B Nondisplaced fracture of distal phalanx of left thumb, initial encounter for open fracture',
  'S62525D Nondisplaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'routine healing',
  'S62525G Nondisplaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'delayed healing',
  'S62525K Nondisplaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'nonunion',
  'S62525P Nondisplaced fracture of distal phalanx of left thumb, subsequent encounter for fracture with',
  'malunion',
  'S62525S Nondisplaced fracture of distal phalanx of left thumb, sequela',
  'S62526A Nondisplaced fracture of distal phalanx of unspecified thumb, initial encounter for closed',
  'fracture',
  'S62526B Nondisplaced fracture of distal phalanx of unspecified thumb, initial encounter for open',
  'fracture',
  'S62526D Nondisplaced fracture of distal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with routine healing',
  'S62526G Nondisplaced fracture of distal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with delayed healing',
  'S62526K Nondisplaced fracture of distal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with nonunion',
  'S62526P Nondisplaced fracture of distal phalanx of unspecified thumb, subsequent encounter for',
  'fracture with malunion',
  'S62526S Nondisplaced fracture of distal phalanx of unspecified thumb, sequela',
  'S62600A Fracture of unspecified phalanx of right index finger, initial encounter for closed fracture',
  'S62600B Fracture of unspecified phalanx of right index finger, initial encounter for open fracture',
  'S62600D Fracture of unspecified phalanx of right index finger, subsequent encounter for fracture with',
  'routine healing',
  'S62600G Fracture of unspecified phalanx of right index finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62600K Fracture of unspecified phalanx of right index finger, subsequent encounter for fracture with',
  'nonunion',
  'S62600P Fracture of unspecified phalanx of right index finger, subsequent encounter for fracture with',
  'malunion',
  'S62600S Fracture of unspecified phalanx of right index finger, sequela',
  'S62601A Fracture of unspecified phalanx of left index finger, initial encounter for closed fracture',
  'S62601B Fracture of unspecified phalanx of left index finger, initial encounter for open fracture',
  'S62601D Fracture of unspecified phalanx of left index finger, subsequent encounter for fracture with',
  'routine healing',
  'S62601G Fracture of unspecified phalanx of left index finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62601K Fracture of unspecified phalanx of left index finger, subsequent encounter for fracture with',
  'nonunion',
  'S62601P Fracture of unspecified phalanx of left index finger, subsequent encounter for fracture with',
  'malunion',
  'S62601S Fracture of unspecified phalanx of left index finger, sequela',
  'S62602A Fracture of unspecified phalanx of right middle finger, initial encounter for closed fracture',
  'S62602B Fracture of unspecified phalanx of right middle finger, initial encounter for open fracture',
  'S62602D Fracture of unspecified phalanx of right middle finger, subsequent encounter for fracture with',
  'routine healing',
  'S62602G Fracture of unspecified phalanx of right middle finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62602K Fracture of unspecified phalanx of right middle finger, subsequent encounter for fracture with',
  'nonunion',
  'S62602P Fracture of unspecified phalanx of right middle finger, subsequent encounter for fracture with',
  'malunion',
  'S62602S Fracture of unspecified phalanx of right middle finger, sequela',
  'S62603A Fracture of unspecified phalanx of left middle finger, initial encounter for closed fracture',
  'S62603B Fracture of unspecified phalanx of left middle finger, initial encounter for open fracture',
  'S62603D Fracture of unspecified phalanx of left middle finger, subsequent encounter for fracture with',
  'routine healing',
  'S62603G Fracture of unspecified phalanx of left middle finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62603K Fracture of unspecified phalanx of left middle finger, subsequent encounter for fracture with',
  'nonunion',
  'S62603P Fracture of unspecified phalanx of left middle finger, subsequent encounter for fracture with',
  'malunion',
  'S62603S Fracture of unspecified phalanx of left middle finger, sequela',
  'S62604A Fracture of unspecified phalanx of right ring finger, initial encounter for closed fracture',
  'S62604B Fracture of unspecified phalanx of right ring finger, initial encounter for open fracture',
  'S62604D Fracture of unspecified phalanx of right ring finger, subsequent encounter for fracture with',
  'routine healing',
  'S62604G Fracture of unspecified phalanx of right ring finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62604K Fracture of unspecified phalanx of right ring finger, subsequent encounter for fracture with',
  'nonunion',
  'S62604P Fracture of unspecified phalanx of right ring finger, subsequent encounter for fracture with',
  'malunion',
  'S62604S Fracture of unspecified phalanx of right ring finger, sequela',
  'S62605A Fracture of unspecified phalanx of left ring finger, initial encounter for closed fracture',
  'S62605B Fracture of unspecified phalanx of left ring finger, initial encounter for open fracture',
  'S62605D Fracture of unspecified phalanx of left ring finger, subsequent encounter for fracture with',
  'routine healing',
  'S62605G Fracture of unspecified phalanx of left ring finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62605K Fracture of unspecified phalanx of left ring finger, subsequent encounter for fracture with',
  'nonunion',
  'S62605P Fracture of unspecified phalanx of left ring finger, subsequent encounter for fracture with',
  'malunion',
  'S62605S Fracture of unspecified phalanx of left ring finger, sequela',
  'S62606A Fracture of unspecified phalanx of right little finger, initial encounter for closed fracture',
  'S62606B Fracture of unspecified phalanx of right little finger, initial encounter for open fracture',
  'S62606D Fracture of unspecified phalanx of right little finger, subsequent encounter for fracture with',
  'routine healing',
  'S62606G Fracture of unspecified phalanx of right little finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62606K Fracture of unspecified phalanx of right little finger, subsequent encounter for fracture with',
  'nonunion',
  'S62606P Fracture of unspecified phalanx of right little finger, subsequent encounter for fracture with',
  'malunion',
  'S62606S Fracture of unspecified phalanx of right little finger, sequela',
  'S62607A Fracture of unspecified phalanx of left little finger, initial encounter for closed fracture',
  'S62607B Fracture of unspecified phalanx of left little finger, initial encounter for open fracture',
  'S62607D Fracture of unspecified phalanx of left little finger, subsequent encounter for fracture with',
  'routine healing',
  'S62607G Fracture of unspecified phalanx of left little finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62607K Fracture of unspecified phalanx of left little finger, subsequent encounter for fracture with',
  'nonunion',
  'S62607P Fracture of unspecified phalanx of left little finger, subsequent encounter for fracture with',
  'malunion',
  'S62607S Fracture of unspecified phalanx of left little finger, sequela',
  'S62608A Fracture of unspecified phalanx of other finger, initial encounter for closed fracture',
  'S62608B Fracture of unspecified phalanx of other finger, initial encounter for open fracture',
  'S62608D Fracture of unspecified phalanx of other finger, subsequent encounter for fracture with routine',
  'healing',
  'S62608G Fracture of unspecified phalanx of other finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62608K Fracture of unspecified phalanx of other finger, subsequent encounter for fracture with',
  'nonunion',
  'S62608P Fracture of unspecified phalanx of other finger, subsequent encounter for fracture with',
  'malunion',
  'S62608S Fracture of unspecified phalanx of other finger, sequela',
  'S62609A Fracture of unspecified phalanx of unspecified finger, initial encounter for closed fracture',
  'S62609B Fracture of unspecified phalanx of unspecified finger, initial encounter for open fracture',
  'S62609D Fracture of unspecified phalanx of unspecified finger, subsequent encounter for fracture with',
  'routine healing',
  'S62609G Fracture of unspecified phalanx of unspecified finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62609K Fracture of unspecified phalanx of unspecified finger, subsequent encounter for fracture with',
  'nonunion',
  'S62609P Fracture of unspecified phalanx of unspecified finger, subsequent encounter for fracture with',
  'malunion',
  'S62609S Fracture of unspecified phalanx of unspecified finger, sequela',
  'S62610A Displaced fracture of proximal phalanx of right index finger, initial encounter for closed',
  'fracture',
  'S62610B Displaced fracture of proximal phalanx of right index finger, initial encounter for open fracture',
  'S62610D Displaced fracture of proximal phalanx of right index finger, subsequent encounter for fracture',
  'with routine healing',
  'S62610G Displaced fracture of proximal phalanx of right index finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62610K Displaced fracture of proximal phalanx of right index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62610P Displaced fracture of proximal phalanx of right index finger, subsequent encounter for fracture',
  'with malunion',
  'S62610S Displaced fracture of proximal phalanx of right index finger, sequela',
  'S62611A Displaced fracture of proximal phalanx of left index finger, initial encounter for closed fracture',
  'S62611B Displaced fracture of proximal phalanx of left index finger, initial encounter for open fracture',
  'S62611D Displaced fracture of proximal phalanx of left index finger, subsequent encounter for fracture',
  'with routine healing',
  'S62611G Displaced fracture of proximal phalanx of left index finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62611K Displaced fracture of proximal phalanx of left index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62611P Displaced fracture of proximal phalanx of left index finger, subsequent encounter for fracture',
  'with malunion',
  'S62611S Displaced fracture of proximal phalanx of left index finger, sequela',
  'S62612A Displaced fracture of proximal phalanx of right middle finger, initial encounter for closed',
  'fracture',
  'S62612B Displaced fracture of proximal phalanx of right middle finger, initial encounter for open',
  'fracture',
  'S62612D Displaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with routine healing',
  'S62612G Displaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62612K Displaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with nonunion',
  'S62612P Displaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with malunion',
  'S62612S Displaced fracture of proximal phalanx of right middle finger, sequela',
  'S62613A Displaced fracture of proximal phalanx of left middle finger, initial encounter for closed',
  'fracture',
  'S62613B Displaced fracture of proximal phalanx of left middle finger, initial encounter for open fracture',
  'S62613D Displaced fracture of proximal phalanx of left middle finger, subsequent encounter for fracture',
  'with routine healing',
  'S62613G Displaced fracture of proximal phalanx of left middle finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62613K Displaced fracture of proximal phalanx of left middle finger, subsequent encounter for fracture',
  'with nonunion',
  'S62613P Displaced fracture of proximal phalanx of left middle finger, subsequent encounter for fracture',
  'with malunion',
  'S62613S Displaced fracture of proximal phalanx of left middle finger, sequela',
  'S62614A Displaced fracture of proximal phalanx of right ring finger, initial encounter for closed fracture',
  'S62614B Displaced fracture of proximal phalanx of right ring finger, initial encounter for open fracture',
  'S62614D Displaced fracture of proximal phalanx of right ring finger, subsequent encounter for fracture',
  'with routine healing',
  'S62614G Displaced fracture of proximal phalanx of right ring finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62614K Displaced fracture of proximal phalanx of right ring finger, subsequent encounter for fracture',
  'with nonunion',
  'S62614P Displaced fracture of proximal phalanx of right ring finger, subsequent encounter for fracture',
  'with malunion',
  'S62614S Displaced fracture of proximal phalanx of right ring finger, sequela',
  'S62615A Displaced fracture of proximal phalanx of left ring finger, initial encounter for closed fracture',
  'S62615B Displaced fracture of proximal phalanx of left ring finger, initial encounter for open fracture',
  'S62615D Displaced fracture of proximal phalanx of left ring finger, subsequent encounter for fracture',
  'with routine healing',
  'S62615G Displaced fracture of proximal phalanx of left ring finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62615K Displaced fracture of proximal phalanx of left ring finger, subsequent encounter for fracture',
  'with nonunion',
  'S62615P Displaced fracture of proximal phalanx of left ring finger, subsequent encounter for fracture',
  'with malunion',
  'S62615S Displaced fracture of proximal phalanx of left ring finger, sequela',
  'S62616A Displaced fracture of proximal phalanx of right little finger, initial encounter for closed fracture',
  'S62616B Displaced fracture of proximal phalanx of right little finger, initial encounter for open fracture',
  'S62616D Displaced fracture of proximal phalanx of right little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62616G Displaced fracture of proximal phalanx of right little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62616K Displaced fracture of proximal phalanx of right little finger, subsequent encounter for fracture',
  'with nonunion',
  'S62616P Displaced fracture of proximal phalanx of right little finger, subsequent encounter for fracture',
  'with malunion',
  'S62616S Displaced fracture of proximal phalanx of right little finger, sequela',
  'S62617A Displaced fracture of proximal phalanx of left little finger, initial encounter for closed fracture',
  'S62617B Displaced fracture of proximal phalanx of left little finger, initial encounter for open fracture',
  'S62617D Displaced fracture of proximal phalanx of left little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62617G Displaced fracture of proximal phalanx of left little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62617K Displaced fracture of proximal phalanx of left little finger, subsequent encounter for fracture',
  'with nonunion',
  'S62617P Displaced fracture of proximal phalanx of left little finger, subsequent encounter for fracture',
  'with malunion',
  'S62617S Displaced fracture of proximal phalanx of left little finger, sequela',
  'S62618A Displaced fracture of proximal phalanx of other finger, initial encounter for closed fracture',
  'S62618B Displaced fracture of proximal phalanx of other finger, initial encounter for open fracture',
  'S62618D Displaced fracture of proximal phalanx of other finger, subsequent encounter for fracture with',
  'routine healing',
  'S62618G Displaced fracture of proximal phalanx of other finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62618K Displaced fracture of proximal phalanx of other finger, subsequent encounter for fracture with',
  'nonunion',
  'S62618P Displaced fracture of proximal phalanx of other finger, subsequent encounter for fracture with',
  'malunion',
  'S62618S Displaced fracture of proximal phalanx of other finger, sequela',
  'S62619A Displaced fracture of proximal phalanx of unspecified finger, initial encounter for closed',
  'fracture',
  'S62619B Displaced fracture of proximal phalanx of unspecified finger, initial encounter for open fracture',
  'S62619D Displaced fracture of proximal phalanx of unspecified finger, subsequent encounter for',
  'fracture with routine healing',
  'S62619G Displaced fracture of proximal phalanx of unspecified finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62619K Displaced fracture of proximal phalanx of unspecified finger, subsequent encounter for fracture',
  'with nonunion',
  'S62619P Displaced fracture of proximal phalanx of unspecified finger, subsequent encounter for fracture',
  'with malunion',
  'S62619S Displaced fracture of proximal phalanx of unspecified finger, sequela',
  'S62620A Displaced fracture of medial phalanx of right index finger, initial encounter for closed fracture',
  'S62620B Displaced fracture of medial phalanx of right index finger, initial encounter for open fracture',
  'S62620D Displaced fracture of medial phalanx of right index finger, subsequent encounter for fracture',
  'with routine healing',
  'S62620G Displaced fracture of medial phalanx of right index finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62620K Displaced fracture of medial phalanx of right index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62620P Displaced fracture of medial phalanx of right index finger, subsequent encounter for fracture',
  'with malunion',
  'S62620S Displaced fracture of medial phalanx of right index finger, sequela',
  'S62621A Displaced fracture of medial phalanx of left index finger, initial encounter for closed fracture',
  'S62621B Displaced fracture of medial phalanx of left index finger, initial encounter for open fracture',
  'S62621D Displaced fracture of medial phalanx of left index finger, subsequent encounter for fracture',
  'with routine healing',
  'S62621G Displaced fracture of medial phalanx of left index finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62621K Displaced fracture of medial phalanx of left index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62621P Displaced fracture of medial phalanx of left index finger, subsequent encounter for fracture',
  'with malunion',
  'S62621S Displaced fracture of medial phalanx of left index finger, sequela',
  'S62622A Displaced fracture of medial phalanx of right middle finger, initial encounter for closed fracture',
  'S62622B Displaced fracture of medial phalanx of right middle finger, initial encounter for open fracture',
  'S62622D Displaced fracture of medial phalanx of right middle finger, subsequent encounter for fracture',
  'with routine healing',
  'S62622G Displaced fracture of medial phalanx of right middle finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62622K Displaced fracture of medial phalanx of right middle finger, subsequent encounter for fracture',
  'with nonunion',
  'S62622P Displaced fracture of medial phalanx of right middle finger, subsequent encounter for fracture',
  'with malunion',
  'S62622S Displaced fracture of medial phalanx of right middle finger, sequela',
  'S62623A Displaced fracture of medial phalanx of left middle finger, initial encounter for closed fracture',
  'S62623B Displaced fracture of medial phalanx of left middle finger, initial encounter for open fracture',
  'S62623D Displaced fracture of medial phalanx of left middle finger, subsequent encounter for fracture',
  'with routine healing',
  'S62623G Displaced fracture of medial phalanx of left middle finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62623K Displaced fracture of medial phalanx of left middle finger, subsequent encounter for fracture',
  'with nonunion',
  'S62623P Displaced fracture of medial phalanx of left middle finger, subsequent encounter for fracture',
  'with malunion',
  'S62623S Displaced fracture of medial phalanx of left middle finger, sequela',
  'S62624A Displaced fracture of medial phalanx of right ring finger, initial encounter for closed fracture',
  'S62624B Displaced fracture of medial phalanx of right ring finger, initial encounter for open fracture',
  'S62624D Displaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with routine healing',
  'S62624G Displaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62624K Displaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with nonunion',
  'S62624P Displaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with malunion',
  'S62624S Displaced fracture of medial phalanx of right ring finger, sequela',
  'S62625A Displaced fracture of medial phalanx of left ring finger, initial encounter for closed fracture',
  'S62625B Displaced fracture of medial phalanx of left ring finger, initial encounter for open fracture',
  'S62625D Displaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture with',
  'routine healing',
  'S62625G Displaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62625K Displaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture with',
  'nonunion',
  'S62625P Displaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture with',
  'malunion',
  'S62625S Displaced fracture of medial phalanx of left ring finger, sequela',
  'S62626A Displaced fracture of medial phalanx of right little finger, initial encounter for closed fracture',
  'S62626B Displaced fracture of medial phalanx of right little finger, initial encounter for open fracture',
  'S62626D Displaced fracture of medial phalanx of right little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62626G Displaced fracture of medial phalanx of right little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62626K Displaced fracture of medial phalanx of right little finger, subsequent encounter for fracture',
  'with nonunion',
  'S62626P Displaced fracture of medial phalanx of right little finger, subsequent encounter for fracture',
  'with malunion',
  'S62626S Displaced fracture of medial phalanx of right little finger, sequela',
  'S62627A Displaced fracture of medial phalanx of left little finger, initial encounter for closed fracture',
  'S62627B Displaced fracture of medial phalanx of left little finger, initial encounter for open fracture',
  'S62627D Displaced fracture of medial phalanx of left little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62627G Displaced fracture of medial phalanx of left little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62627K Displaced fracture of medial phalanx of left little finger, subsequent encounter for fracture with',
  'nonunion',
  'S62627P Displaced fracture of medial phalanx of left little finger, subsequent encounter for fracture with',
  'malunion',
  'S62627S Displaced fracture of medial phalanx of left little finger, sequela',
  'S62628A Displaced fracture of medial phalanx of other finger, initial encounter for closed fracture',
  'S62628B Displaced fracture of medial phalanx of other finger, initial encounter for open fracture',
  'S62628D Displaced fracture of medial phalanx of other finger, subsequent encounter for fracture with',
  'routine healing',
  'S62628G Displaced fracture of medial phalanx of other finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62628K Displaced fracture of medial phalanx of other finger, subsequent encounter for fracture with',
  'nonunion',
  'S62628P Displaced fracture of medial phalanx of other finger, subsequent encounter for fracture with',
  'malunion',
  'S62628S Displaced fracture of medial phalanx of other finger, sequela',
  'S62629A Displaced fracture of medial phalanx of unspecified finger, initial encounter for closed fracture',
  'S62629B Displaced fracture of medial phalanx of unspecified finger, initial encounter for open fracture',
  'S62629D Displaced fracture of medial phalanx of unspecified finger, subsequent encounter for fracture',
  'with routine healing',
  'S62629G Displaced fracture of medial phalanx of unspecified finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62629K Displaced fracture of medial phalanx of unspecified finger, subsequent encounter for fracture',
  'with nonunion',
  'S62629P Displaced fracture of medial phalanx of unspecified finger, subsequent encounter for fracture',
  'with malunion',
  'S62629S Displaced fracture of medial phalanx of unspecified finger, sequela',
  'S62630A Displaced fracture of distal phalanx of right index finger, initial encounter for closed fracture',
  'S62630B Displaced fracture of distal phalanx of right index finger, initial encounter for open fracture',
  'S62630D Displaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with routine healing',
  'S62630G Displaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62630K Displaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62630P Displaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with malunion',
  'S62630S Displaced fracture of distal phalanx of right index finger, sequela',
  'S62631A Displaced fracture of distal phalanx of left index finger, initial encounter for closed fracture',
  'S62631B Displaced fracture of distal phalanx of left index finger, initial encounter for open fracture',
  'S62631D Displaced fracture of distal phalanx of left index finger, subsequent encounter for fracture with',
  'routine healing',
  'S62631G Displaced fracture of distal phalanx of left index finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62631K Displaced fracture of distal phalanx of left index finger, subsequent encounter for fracture with',
  'nonunion',
  'S62631P Displaced fracture of distal phalanx of left index finger, subsequent encounter for fracture with',
  'malunion',
  'S62631S Displaced fracture of distal phalanx of left index finger, sequela',
  'S62632A Displaced fracture of distal phalanx of right middle finger, initial encounter for closed fracture',
  'S62632B Displaced fracture of distal phalanx of right middle finger, initial encounter for open fracture',
  'S62632D Displaced fracture of distal phalanx of right middle finger, subsequent encounter for fracture',
  'with routine healing',
  'S62632G Displaced fracture of distal phalanx of right middle finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62632K Displaced fracture of distal phalanx of right middle finger, subsequent encounter for fracture',
  'with nonunion',
  'S62632P Displaced fracture of distal phalanx of right middle finger, subsequent encounter for fracture',
  'with malunion',
  'S62632S Displaced fracture of distal phalanx of right middle finger, sequela',
  'S62633A Displaced fracture of distal phalanx of left middle finger, initial encounter for closed fracture',
  'S62633B Displaced fracture of distal phalanx of left middle finger, initial encounter for open fracture',
  'S62633D Displaced fracture of distal phalanx of left middle finger, subsequent encounter for fracture',
  'with routine healing',
  'S62633G Displaced fracture of distal phalanx of left middle finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62633K Displaced fracture of distal phalanx of left middle finger, subsequent encounter for fracture',
  'with nonunion',
  'S62633P Displaced fracture of distal phalanx of left middle finger, subsequent encounter for fracture',
  'with malunion',
  'S62633S Displaced fracture of distal phalanx of left middle finger, sequela',
  'S62634A Displaced fracture of distal phalanx of right ring finger, initial encounter for closed fracture',
  'S62634B Displaced fracture of distal phalanx of right ring finger, initial encounter for open fracture',
  'S62634D Displaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture with',
  'routine healing',
  'S62634G Displaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62634K Displaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture with',
  'nonunion',
  'S62634P Displaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture with',
  'malunion',
  'S62634S Displaced fracture of distal phalanx of right ring finger, sequela',
  'S62635A Displaced fracture of distal phalanx of left ring finger, initial encounter for closed fracture',
  'S62635B Displaced fracture of distal phalanx of left ring finger, initial encounter for open fracture',
  'S62635D Displaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture with',
  'routine healing',
  'S62635G Displaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62635K Displaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture with',
  'nonunion',
  'S62635P Displaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture with',
  'malunion',
  'S62635S Displaced fracture of distal phalanx of left ring finger, sequela',
  'S62636A Displaced fracture of distal phalanx of right little finger, initial encounter for closed fracture',
  'S62636B Displaced fracture of distal phalanx of right little finger, initial encounter for open fracture',
  'S62636D Displaced fracture of distal phalanx of right little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62636G Displaced fracture of distal phalanx of right little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62636K Displaced fracture of distal phalanx of right little finger, subsequent encounter for fracture with',
  'nonunion',
  'S62636P Displaced fracture of distal phalanx of right little finger, subsequent encounter for fracture with',
  'malunion',
  'S62636S Displaced fracture of distal phalanx of right little finger, sequela',
  'S62637A Displaced fracture of distal phalanx of left little finger, initial encounter for closed fracture',
  'S62637B Displaced fracture of distal phalanx of left little finger, initial encounter for open fracture',
  'S62637D Displaced fracture of distal phalanx of left little finger, subsequent encounter for fracture with',
  'routine healing',
  'S62637G Displaced fracture of distal phalanx of left little finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62637K Displaced fracture of distal phalanx of left little finger, subsequent encounter for fracture with',
  'nonunion',
  'S62637P Displaced fracture of distal phalanx of left little finger, subsequent encounter for fracture with',
  'malunion',
  'S62637S Displaced fracture of distal phalanx of left little finger, sequela',
  'S62638A Displaced fracture of distal phalanx of other finger, initial encounter for closed fracture',
  'S62638B Displaced fracture of distal phalanx of other finger, initial encounter for open fracture',
  'S62638D Displaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'routine healing',
  'S62638G Displaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62638K Displaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'nonunion',
  'S62638P Displaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'malunion',
  'S62638S Displaced fracture of distal phalanx of other finger, sequela',
  'S62639A Displaced fracture of distal phalanx of unspecified finger, initial encounter for closed fracture',
  'S62639B Displaced fracture of distal phalanx of unspecified finger, initial encounter for open fracture',
  'S62639D Displaced fracture of distal phalanx of unspecified finger, subsequent encounter for fracture',
  'with routine healing',
  'S62639G Displaced fracture of distal phalanx of unspecified finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62639K Displaced fracture of distal phalanx of unspecified finger, subsequent encounter for fracture',
  'with nonunion',
  'S62639P Displaced fracture of distal phalanx of unspecified finger, subsequent encounter for fracture',
  'with malunion',
  'S62639S Displaced fracture of distal phalanx of unspecified finger, sequela',
  'S62640A Nondisplaced fracture of proximal phalanx of right index finger, initial encounter for closed',
  'fracture',
  'S62640B Nondisplaced fracture of proximal phalanx of right index finger, initial encounter for open',
  'fracture',
  'S62640D Nondisplaced fracture of proximal phalanx of right index finger, subsequent encounter for',
  'fracture with routine healing',
  'S62640G Nondisplaced fracture of proximal phalanx of right index finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62640K Nondisplaced fracture of proximal phalanx of right index finger, subsequent encounter for',
  'fracture with nonunion',
  'S62640P Nondisplaced fracture of proximal phalanx of right index finger, subsequent encounter for',
  'fracture with malunion',
  'S62640S Nondisplaced fracture of proximal phalanx of right index finger, sequela',
  'S62641A Nondisplaced fracture of proximal phalanx of left index finger, initial encounter for closed',
  'fracture',
  'S62641B Nondisplaced fracture of proximal phalanx of left index finger, initial encounter for open',
  'fracture',
  'S62641D Nondisplaced fracture of proximal phalanx of left index finger, subsequent encounter for',
  'fracture with routine healing',
  'S62641G Nondisplaced fracture of proximal phalanx of left index finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62641K Nondisplaced fracture of proximal phalanx of left index finger, subsequent encounter for',
  'fracture with nonunion',
  'S62641P Nondisplaced fracture of proximal phalanx of left index finger, subsequent encounter for',
  'fracture with malunion',
  'S62641S Nondisplaced fracture of proximal phalanx of left index finger, sequela',
  'S62642A Nondisplaced fracture of proximal phalanx of right middle finger, initial encounter for closed',
  'fracture',
  'S62642B Nondisplaced fracture of proximal phalanx of right middle finger, initial encounter for open',
  'fracture',
  'S62642D Nondisplaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with routine healing',
  'S62642G Nondisplaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62642K Nondisplaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with nonunion',
  'S62642P Nondisplaced fracture of proximal phalanx of right middle finger, subsequent encounter for',
  'fracture with malunion',
  'S62642S Nondisplaced fracture of proximal phalanx of right middle finger, sequela',
  'S62643A Nondisplaced fracture of proximal phalanx of left middle finger, initial encounter for closed',
  'fracture',
  'S62643B Nondisplaced fracture of proximal phalanx of left middle finger, initial encounter for open',
  'fracture',
  'S62643D Nondisplaced fracture of proximal phalanx of left middle finger, subsequent encounter for',
  'fracture with routine healing',
  'S62643G Nondisplaced fracture of proximal phalanx of left middle finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62643K Nondisplaced fracture of proximal phalanx of left middle finger, subsequent encounter for',
  'fracture with nonunion',
  'S62643P Nondisplaced fracture of proximal phalanx of left middle finger, subsequent encounter for',
  'fracture with malunion',
  'S62643S Nondisplaced fracture of proximal phalanx of left middle finger, sequela',
  'S62644A Nondisplaced fracture of proximal phalanx of right ring finger, initial encounter for closed',
  'fracture',
  'S62644B Nondisplaced fracture of proximal phalanx of right ring finger, initial encounter for open',
  'fracture',
  'S62644D Nondisplaced fracture of proximal phalanx of right ring finger, subsequent encounter for',
  'fracture with routine healing',
  'S62644G Nondisplaced fracture of proximal phalanx of right ring finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62644K Nondisplaced fracture of proximal phalanx of right ring finger, subsequent encounter for',
  'fracture with nonunion',
  'S62644P Nondisplaced fracture of proximal phalanx of right ring finger, subsequent encounter for',
  'fracture with malunion',
  'S62644S Nondisplaced fracture of proximal phalanx of right ring finger, sequela',
  'S62645A Nondisplaced fracture of proximal phalanx of left ring finger, initial encounter for closed',
  'fracture',
  'S62645B Nondisplaced fracture of proximal phalanx of left ring finger, initial encounter for open fracture',
  'S62645D Nondisplaced fracture of proximal phalanx of left ring finger, subsequent encounter for',
  'fracture with routine healing',
  'S62645G Nondisplaced fracture of proximal phalanx of left ring finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62645K Nondisplaced fracture of proximal phalanx of left ring finger, subsequent encounter for',
  'fracture with nonunion',
  'S62645P Nondisplaced fracture of proximal phalanx of left ring finger, subsequent encounter for',
  'fracture with malunion',
  'S62645S Nondisplaced fracture of proximal phalanx of left ring finger, sequela',
  'S62646A Nondisplaced fracture of proximal phalanx of right little finger, initial encounter for closed',
  'fracture',
  'S62646B Nondisplaced fracture of proximal phalanx of right little finger, initial encounter for open',
  'fracture',
  'S62646D Nondisplaced fracture of proximal phalanx of right little finger, subsequent encounter for',
  'fracture with routine healing',
  'S62646G Nondisplaced fracture of proximal phalanx of right little finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62646K Nondisplaced fracture of proximal phalanx of right little finger, subsequent encounter for',
  'fracture with nonunion',
  'S62646P Nondisplaced fracture of proximal phalanx of right little finger, subsequent encounter for',
  'fracture with malunion',
  'S62646S Nondisplaced fracture of proximal phalanx of right little finger, sequela',
  'S62647A Nondisplaced fracture of proximal phalanx of left little finger, initial encounter for closed',
  'fracture',
  'S62647B Nondisplaced fracture of proximal phalanx of left little finger, initial encounter for open',
  'fracture',
  'S62647D Nondisplaced fracture of proximal phalanx of left little finger, subsequent encounter for',
  'fracture with routine healing',
  'S62647G Nondisplaced fracture of proximal phalanx of left little finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62647K Nondisplaced fracture of proximal phalanx of left little finger, subsequent encounter for',
  'fracture with nonunion',
  'S62647P Nondisplaced fracture of proximal phalanx of left little finger, subsequent encounter for',
  'fracture with malunion',
  'S62647S Nondisplaced fracture of proximal phalanx of left little finger, sequela',
  'S62648A Nondisplaced fracture of proximal phalanx of other finger, initial encounter for closed fracture',
  'S62648B Nondisplaced fracture of proximal phalanx of other finger, initial encounter for open fracture',
  'S62648D Nondisplaced fracture of proximal phalanx of other finger, subsequent encounter for fracture',
  'with routine healing',
  'S62648G Nondisplaced fracture of proximal phalanx of other finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62648K Nondisplaced fracture of proximal phalanx of other finger, subsequent encounter for fracture',
  'with nonunion',
  'S62648P Nondisplaced fracture of proximal phalanx of other finger, subsequent encounter for fracture',
  'with malunion',
  'S62648S Nondisplaced fracture of proximal phalanx of other finger, sequela',
  'S62649A Nondisplaced fracture of proximal phalanx of unspecified finger, initial encounter for closed',
  'fracture',
  'S62649B Nondisplaced fracture of proximal phalanx of unspecified finger, initial encounter for open',
  'fracture',
  'S62649D Nondisplaced fracture of proximal phalanx of unspecified finger, subsequent encounter for',
  'fracture with routine healing',
  'S62649G Nondisplaced fracture of proximal phalanx of unspecified finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62649K Nondisplaced fracture of proximal phalanx of unspecified finger, subsequent encounter for',
  'fracture with nonunion',
  'S62649P Nondisplaced fracture of proximal phalanx of unspecified finger, subsequent encounter for',
  'fracture with malunion',
  'S62649S Nondisplaced fracture of proximal phalanx of unspecified finger, sequela',
  'S62650A Nondisplaced fracture of medial phalanx of right index finger, initial encounter for closed',
  'fracture',
  'S62650B Nondisplaced fracture of medial phalanx of right index finger, initial encounter for open',
  'fracture',
  'S62650D Nondisplaced fracture of medial phalanx of right index finger, subsequent encounter for',
  'fracture with routine healing',
  'S62650G Nondisplaced fracture of medial phalanx of right index finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62650K Nondisplaced fracture of medial phalanx of right index finger, subsequent encounter for',
  'fracture with nonunion',
  'S62650P Nondisplaced fracture of medial phalanx of right index finger, subsequent encounter for',
  'fracture with malunion',
  'S62650S Nondisplaced fracture of medial phalanx of right index finger, sequela',
  'S62651A Nondisplaced fracture of medial phalanx of left index finger, initial encounter for closed',
  'fracture',
  'S62651B Nondisplaced fracture of medial phalanx of left index finger, initial encounter for open fracture',
  'S62651D Nondisplaced fracture of medial phalanx of left index finger, subsequent encounter for',
  'fracture with routine healing',
  'S62651G Nondisplaced fracture of medial phalanx of left index finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62651K Nondisplaced fracture of medial phalanx of left index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62651P Nondisplaced fracture of medial phalanx of left index finger, subsequent encounter for fracture',
  'with malunion',
  'S62651S Nondisplaced fracture of medial phalanx of left index finger, sequela',
  'S62652A Nondisplaced fracture of medial phalanx of right middle finger, initial encounter for closed',
  'fracture',
  'S62652B Nondisplaced fracture of medial phalanx of right middle finger, initial encounter for open',
  'fracture',
  'S62652D Nondisplaced fracture of medial phalanx of right middle finger, subsequent encounter for',
  'fracture with routine healing',
  'S62652G Nondisplaced fracture of medial phalanx of right middle finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62652K Nondisplaced fracture of medial phalanx of right middle finger, subsequent encounter for',
  'fracture with nonunion',
  'S62652P Nondisplaced fracture of medial phalanx of right middle finger, subsequent encounter for',
  'fracture with malunion',
  'S62652S Nondisplaced fracture of medial phalanx of right middle finger, sequela',
  'S62653A Nondisplaced fracture of medial phalanx of left middle finger, initial encounter for closed',
  'fracture',
  'S62653B Nondisplaced fracture of medial phalanx of left middle finger, initial encounter for open',
  'fracture',
  'S62653D Nondisplaced fracture of medial phalanx of left middle finger, subsequent encounter for',
  'fracture with routine healing',
  'S62653G Nondisplaced fracture of medial phalanx of left middle finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62653K Nondisplaced fracture of medial phalanx of left middle finger, subsequent encounter for',
  'fracture with nonunion',
  'S62653P Nondisplaced fracture of medial phalanx of left middle finger, subsequent encounter for',
  'fracture with malunion',
  'S62653S Nondisplaced fracture of medial phalanx of left middle finger, sequela',
  'S62654A Nondisplaced fracture of medial phalanx of right ring finger, initial encounter for closed',
  'fracture',
  'S62654B Nondisplaced fracture of medial phalanx of right ring finger, initial encounter for open fracture',
  'S62654D Nondisplaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with routine healing',
  'S62654G Nondisplaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62654K Nondisplaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with nonunion',
  'S62654P Nondisplaced fracture of medial phalanx of right ring finger, subsequent encounter for fracture',
  'with malunion',
  'S62654S Nondisplaced fracture of medial phalanx of right ring finger, sequela',
  'S62655A Nondisplaced fracture of medial phalanx of left ring finger, initial encounter for closed fracture',
  'S62655B Nondisplaced fracture of medial phalanx of left ring finger, initial encounter for open fracture',
  'S62655D Nondisplaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture',
  'with routine healing',
  'S62655G Nondisplaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62655K Nondisplaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture',
  'with nonunion',
  'S62655P Nondisplaced fracture of medial phalanx of left ring finger, subsequent encounter for fracture',
  'with malunion',
  'S62655S Nondisplaced fracture of medial phalanx of left ring finger, sequela',
  'S62656A Nondisplaced fracture of medial phalanx of right little finger, initial encounter for closed',
  'fracture',
  'S62656B Nondisplaced fracture of medial phalanx of right little finger, initial encounter for open fracture',
  'S62656D Nondisplaced fracture of medial phalanx of right little finger, subsequent encounter for',
  'fracture with routine healing',
  'S62656G Nondisplaced fracture of medial phalanx of right little finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62656K Nondisplaced fracture of medial phalanx of right little finger, subsequent encounter for',
  'fracture with nonunion',
  'S62656P Nondisplaced fracture of medial phalanx of right little finger, subsequent encounter for',
  'fracture with malunion',
  'S62656S Nondisplaced fracture of medial phalanx of right little finger, sequela',
  'S62657A Nondisplaced fracture of medial phalanx of left little finger, initial encounter for closed fracture',
  'S62657B Nondisplaced fracture of medial phalanx of left little finger, initial encounter for open fracture',
  'S62657D Nondisplaced fracture of medial phalanx of left little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62657G Nondisplaced fracture of medial phalanx of left little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62657K Nondisplaced fracture of medial phalanx of left little finger, subsequent encounter for fracture',
  'with nonunion',
  'S62657P Nondisplaced fracture of medial phalanx of left little finger, subsequent encounter for fracture',
  'with malunion',
  'S62657S Nondisplaced fracture of medial phalanx of left little finger, sequela',
  'S62658A Nondisplaced fracture of medial phalanx of other finger, initial encounter for closed fracture',
  'S62658B Nondisplaced fracture of medial phalanx of other finger, initial encounter for open fracture',
  'S62658D Nondisplaced fracture of medial phalanx of other finger, subsequent encounter for fracture',
  'with routine healing',
  'S62658G Nondisplaced fracture of medial phalanx of other finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62658K Nondisplaced fracture of medial phalanx of other finger, subsequent encounter for fracture',
  'with nonunion',
  'S62658P Nondisplaced fracture of medial phalanx of other finger, subsequent encounter for fracture',
  'with malunion',
  'S62658S Nondisplaced fracture of medial phalanx of other finger, sequela',
  'S62659A Nondisplaced fracture of medial phalanx of unspecified finger, initial encounter for closed',
  'fracture',
  'S62659B Nondisplaced fracture of medial phalanx of unspecified finger, initial encounter for open',
  'fracture',
  'S62659D Nondisplaced fracture of medial phalanx of unspecified finger, subsequent encounter for',
  'fracture with routine healing',
  'S62659G Nondisplaced fracture of medial phalanx of unspecified finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62659K Nondisplaced fracture of medial phalanx of unspecified finger, subsequent encounter for',
  'fracture with nonunion',
  'S62659P Nondisplaced fracture of medial phalanx of unspecified finger, subsequent encounter for',
  'fracture with malunion',
  'S62659S Nondisplaced fracture of medial phalanx of unspecified finger, sequela',
  'S62660A Nondisplaced fracture of distal phalanx of right index finger, initial encounter for closed',
  'fracture',
  'S62660B Nondisplaced fracture of distal phalanx of right index finger, initial encounter for open fracture',
  'S62660D Nondisplaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with routine healing',
  'S62660G Nondisplaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62660K Nondisplaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62660P Nondisplaced fracture of distal phalanx of right index finger, subsequent encounter for fracture',
  'with malunion',
  'S62660S Nondisplaced fracture of distal phalanx of right index finger, sequela',
  'S62661A Nondisplaced fracture of distal phalanx of left index finger, initial encounter for closed fracture',
  'S62661B Nondisplaced fracture of distal phalanx of left index finger, initial encounter for open fracture',
  'S62661D Nondisplaced fracture of distal phalanx of left index finger, subsequent encounter for fracture',
  'with routine healing',
  'S62661G Nondisplaced fracture of distal phalanx of left index finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62661K Nondisplaced fracture of distal phalanx of left index finger, subsequent encounter for fracture',
  'with nonunion',
  'S62661P Nondisplaced fracture of distal phalanx of left index finger, subsequent encounter for fracture',
  'with malunion',
  'S62661S Nondisplaced fracture of distal phalanx of left index finger, sequela',
  'S62662A Nondisplaced fracture of distal phalanx of right middle finger, initial encounter for closed',
  'fracture',
  'S62662B Nondisplaced fracture of distal phalanx of right middle finger, initial encounter for open',
  'fracture',
  'S62662D Nondisplaced fracture of distal phalanx of right middle finger, subsequent encounter for',
  'fracture with routine healing',
  'S62662G Nondisplaced fracture of distal phalanx of right middle finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62662K Nondisplaced fracture of distal phalanx of right middle finger, subsequent encounter for',
  'fracture with nonunion',
  'S62662P Nondisplaced fracture of distal phalanx of right middle finger, subsequent encounter for',
  'fracture with malunion',
  'S62662S Nondisplaced fracture of distal phalanx of right middle finger, sequela',
  'S62663A Nondisplaced fracture of distal phalanx of left middle finger, initial encounter for closed',
  'fracture',
  'S62663B Nondisplaced fracture of distal phalanx of left middle finger, initial encounter for open fracture',
  'S62663D Nondisplaced fracture of distal phalanx of left middle finger, subsequent encounter for',
  'fracture with routine healing',
  'S62663G Nondisplaced fracture of distal phalanx of left middle finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62663K Nondisplaced fracture of distal phalanx of left middle finger, subsequent encounter for fracture',
  'with nonunion',
  'S62663P Nondisplaced fracture of distal phalanx of left middle finger, subsequent encounter for fracture',
  'with malunion',
  'S62663S Nondisplaced fracture of distal phalanx of left middle finger, sequela',
  'S62664A Nondisplaced fracture of distal phalanx of right ring finger, initial encounter for closed fracture',
  'S62664B Nondisplaced fracture of distal phalanx of right ring finger, initial encounter for open fracture',
  'S62664D Nondisplaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture',
  'with routine healing',
  'S62664G Nondisplaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62664K Nondisplaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture',
  'with nonunion',
  'S62664P Nondisplaced fracture of distal phalanx of right ring finger, subsequent encounter for fracture',
  'with malunion',
  'S62664S Nondisplaced fracture of distal phalanx of right ring finger, sequela',
  'S62665A Nondisplaced fracture of distal phalanx of left ring finger, initial encounter for closed fracture',
  'S62665B Nondisplaced fracture of distal phalanx of left ring finger, initial encounter for open fracture',
  'S62665D Nondisplaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture',
  'with routine healing',
  'S62665G Nondisplaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62665K Nondisplaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture',
  'with nonunion',
  'S62665P Nondisplaced fracture of distal phalanx of left ring finger, subsequent encounter for fracture',
  'with malunion',
  'S62665S Nondisplaced fracture of distal phalanx of left ring finger, sequela',
  'S62666A Nondisplaced fracture of distal phalanx of right little finger, initial encounter for closed fracture',
  'S62666B Nondisplaced fracture of distal phalanx of right little finger, initial encounter for open fracture',
  'S62666D Nondisplaced fracture of distal phalanx of right little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62666G Nondisplaced fracture of distal phalanx of right little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62666K Nondisplaced fracture of distal phalanx of right little finger, subsequent encounter for fracture',
  'with nonunion',
  'S62666P Nondisplaced fracture of distal phalanx of right little finger, subsequent encounter for fracture',
  'with malunion',
  'S62666S Nondisplaced fracture of distal phalanx of right little finger, sequela',
  'S62667A Nondisplaced fracture of distal phalanx of left little finger, initial encounter for closed fracture',
  'S62667B Nondisplaced fracture of distal phalanx of left little finger, initial encounter for open fracture',
  'S62667D Nondisplaced fracture of distal phalanx of left little finger, subsequent encounter for fracture',
  'with routine healing',
  'S62667G Nondisplaced fracture of distal phalanx of left little finger, subsequent encounter for fracture',
  'with delayed healing',
  'S62667K Nondisplaced fracture of distal phalanx of left little finger, subsequent encounter for fracture',
  'with nonunion',
  'S62667P Nondisplaced fracture of distal phalanx of left little finger, subsequent encounter for fracture',
  'with malunion',
  'S62667S Nondisplaced fracture of distal phalanx of left little finger, sequela',
  'S62668A Nondisplaced fracture of distal phalanx of other finger, initial encounter for closed fracture',
  'S62668B Nondisplaced fracture of distal phalanx of other finger, initial encounter for open fracture',
  'S62668D Nondisplaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'routine healing',
  'S62668G Nondisplaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'delayed healing',
  'S62668K Nondisplaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'nonunion',
  'S62668P Nondisplaced fracture of distal phalanx of other finger, subsequent encounter for fracture with',
  'malunion',
  'S62668S Nondisplaced fracture of distal phalanx of other finger, sequela',
  'S62669A Nondisplaced fracture of distal phalanx of unspecified finger, initial encounter for closed',
  'fracture',
  'S62669B Nondisplaced fracture of distal phalanx of unspecified finger, initial encounter for open',
  'fracture',
  'S62669D Nondisplaced fracture of distal phalanx of unspecified finger, subsequent encounter for',
  'fracture with routine healing',
  'S62669G Nondisplaced fracture of distal phalanx of unspecified finger, subsequent encounter for',
  'fracture with delayed healing',
  'S62669K Nondisplaced fracture of distal phalanx of unspecified finger, subsequent encounter for',
  'fracture with nonunion',
  'S62669P Nondisplaced fracture of distal phalanx of unspecified finger, subsequent encounter for',
  'fracture with malunion',
  'S62669S Nondisplaced fracture of distal phalanx of unspecified finger, sequela',
  'S6290XA Unspecified fracture of unspecified wrist and hand, initial encounter for closed fracture',
  'S6290XB Unspecified fracture of unspecified wrist and hand, initial encounter for open fracture',
  'S6290XD Unspecified fracture of unspecified wrist and hand, subsequent encounter for fracture with',
  'routine healing',
  'S6290XG Unspecified fracture of unspecified wrist and hand, subsequent encounter for fracture with',
  'delayed healing',
  'S6290XK Unspecified fracture of unspecified wrist and hand, subsequent encounter for fracture with',
  'nonunion',
  'S6290XP Unspecified fracture of unspecified wrist and hand, subsequent encounter for fracture with',
  'malunion',
  'S6290XS Unspecified fracture of unspecified wrist and hand, sequela',
  'S6291XA Unspecified fracture of right wrist and hand, initial encounter for closed fracture',
  'S6291XB Unspecified fracture of right wrist and hand, initial encounter for open fracture',
  'S6291XD Unspecified fracture of right wrist and hand, subsequent encounter for fracture with routine',
  'healing',
  'S6291XG Unspecified fracture of right wrist and hand, subsequent encounter for fracture with delayed',
  'healing',
  'S6291XK Unspecified fracture of right wrist and hand, subsequent encounter for fracture with nonunion',
  'S6291XP Unspecified fracture of right wrist and hand, subsequent encounter for fracture with malunion',
  'S6291XS Unspecified fracture of right wrist and hand, sequela',
  'S6292XA Unspecified fracture of left wrist and hand, initial encounter for closed fracture',
  'S6292XB Unspecified fracture of left wrist and hand, initial encounter for open fracture',
  'S6292XD Unspecified fracture of left wrist and hand, subsequent encounter for fracture with routine',
  'healing',
  'S6292XG Unspecified fracture of left wrist and hand, subsequent encounter for fracture with delayed',
  'healing',
  'S6292XK Unspecified fracture of left wrist and hand, subsequent encounter for fracture with nonunion',
  'S6292XP Unspecified fracture of left wrist and hand, subsequent encounter for fracture with malunion',
  'S6292XS Unspecified fracture of left wrist and hand, sequela',
  'S63001A Unspecified subluxation of right wrist and hand, initial encounter',
  'S63001D Unspecified subluxation of right wrist and hand, subsequent encounter',
  'S63001S Unspecified subluxation of right wrist and hand, sequela',
  'S63002A Unspecified subluxation of left wrist and hand, initial encounter',
  'S63002D Unspecified subluxation of left wrist and hand, subsequent encounter',
  'S63002S Unspecified subluxation of left wrist and hand, sequela',
  'S63003A Unspecified subluxation of unspecified wrist and hand, initial encounter',
  'S63003D Unspecified subluxation of unspecified wrist and hand, subsequent encounter',
  'S63003S Unspecified subluxation of unspecified wrist and hand, sequela',
  'S63004A Unspecified dislocation of right wrist and hand, initial encounter',
  'S63004D Unspecified dislocation of right wrist and hand, subsequent encounter',
  'S63004S Unspecified dislocation of right wrist and hand, sequela',
  'S63005A Unspecified dislocation of left wrist and hand, initial encounter',
  'S63005D Unspecified dislocation of left wrist and hand, subsequent encounter',
  'S63005S Unspecified dislocation of left wrist and hand, sequela',
  'S63006A Unspecified dislocation of unspecified wrist and hand, initial encounter',
  'S63006D Unspecified dislocation of unspecified wrist and hand, subsequent encounter',
  'S63006S Unspecified dislocation of unspecified wrist and hand, sequela',
  'S63011A Subluxation of distal radioulnar joint of right wrist, initial encounter',
  'S63011D Subluxation of distal radioulnar joint of right wrist, subsequent encounter',
  'S63011S Subluxation of distal radioulnar joint of right wrist, sequela',
  'S63012A Subluxation of distal radioulnar joint of left wrist, initial encounter',
  'S63012D Subluxation of distal radioulnar joint of left wrist, subsequent encounter',
  'S63012S Subluxation of distal radioulnar joint of left wrist, sequela',
  'S63013A Subluxation of distal radioulnar joint of unspecified wrist, initial encounter',
  'S63013D Subluxation of distal radioulnar joint of unspecified wrist, subsequent encounter',
  'S63013S Subluxation of distal radioulnar joint of unspecified wrist, sequela',
  'S63014A Dislocation of distal radioulnar joint of right wrist, initial encounter',
  'S63014D Dislocation of distal radioulnar joint of right wrist, subsequent encounter',
  'S63014S Dislocation of distal radioulnar joint of right wrist, sequela',
  'S63015A Dislocation of distal radioulnar joint of left wrist, initial encounter',
  'S63015D Dislocation of distal radioulnar joint of left wrist, subsequent encounter',
  'S63015S Dislocation of distal radioulnar joint of left wrist, sequela',
  'S63016A Dislocation of distal radioulnar joint of unspecified wrist, initial encounter',
  'S63016D Dislocation of distal radioulnar joint of unspecified wrist, subsequent encounter',
  'S63016S Dislocation of distal radioulnar joint of unspecified wrist, sequela',
  'S63021A Subluxation of radiocarpal joint of right wrist, initial encounter',
  'S63021D Subluxation of radiocarpal joint of right wrist, subsequent encounter',
  'S63021S Subluxation of radiocarpal joint of right wrist, sequela',
  'S63022A Subluxation of radiocarpal joint of left wrist, initial encounter',
  'S63022D Subluxation of radiocarpal joint of left wrist, subsequent encounter',
  'S63022S Subluxation of radiocarpal joint of left wrist, sequela',
  'S63023A Subluxation of radiocarpal joint of unspecified wrist, initial encounter',
  'S63023D Subluxation of radiocarpal joint of unspecified wrist, subsequent encounter',
  'S63023S Subluxation of radiocarpal joint of unspecified wrist, sequela',
  'S63024A Dislocation of radiocarpal joint of right wrist, initial encounter',
  'S63024D Dislocation of radiocarpal joint of right wrist, subsequent encounter',
  'S63024S Dislocation of radiocarpal joint of right wrist, sequela',
  'S63025A Dislocation of radiocarpal joint of left wrist, initial encounter',
  'S63025D Dislocation of radiocarpal joint of left wrist, subsequent encounter',
  'S63025S Dislocation of radiocarpal joint of left wrist, sequela',
  'S63026A Dislocation of radiocarpal joint of unspecified wrist, initial encounter',
  'S63026D Dislocation of radiocarpal joint of unspecified wrist, subsequent encounter',
  'S63026S Dislocation of radiocarpal joint of unspecified wrist, sequela',
  'S63031A Subluxation of midcarpal joint of right wrist, initial encounter',
  'S63031D Subluxation of midcarpal joint of right wrist, subsequent encounter',
  'S63031S Subluxation of midcarpal joint of right wrist, sequela',
  'S63032A Subluxation of midcarpal joint of left wrist, initial encounter',
  'S63032D Subluxation of midcarpal joint of left wrist, subsequent encounter',
  'S63032S Subluxation of midcarpal joint of left wrist, sequela',
  'S63033A Subluxation of midcarpal joint of unspecified wrist, initial encounter',
  'S63033D Subluxation of midcarpal joint of unspecified wrist, subsequent encounter',
  'S63033S Subluxation of midcarpal joint of unspecified wrist, sequela',
  'S63034A Dislocation of midcarpal joint of right wrist, initial encounter',
  'S63034D Dislocation of midcarpal joint of right wrist, subsequent encounter',
  'S63034S Dislocation of midcarpal joint of right wrist, sequela',
  'S63035A Dislocation of midcarpal joint of left wrist, initial encounter',
  'S63035D Dislocation of midcarpal joint of left wrist, subsequent encounter',
  'S63035S Dislocation of midcarpal joint of left wrist, sequela',
  'S63036A Dislocation of midcarpal joint of unspecified wrist, initial encounter',
  'S63036D Dislocation of midcarpal joint of unspecified wrist, subsequent encounter',
  'S63036S Dislocation of midcarpal joint of unspecified wrist, sequela',
  'S63041A Subluxation of carpometacarpal joint of right thumb, initial encounter',
  'S63041D Subluxation of carpometacarpal joint of right thumb, subsequent encounter',
  'S63041S Subluxation of carpometacarpal joint of right thumb, sequela',
  'S63042A Subluxation of carpometacarpal joint of left thumb, initial encounter',
  'S63042D Subluxation of carpometacarpal joint of left thumb, subsequent encounter',
  'S63042S Subluxation of carpometacarpal joint of left thumb, sequela',
  'S63043A Subluxation of carpometacarpal joint of unspecified thumb, initial encounter',
  'S63043D Subluxation of carpometacarpal joint of unspecified thumb, subsequent encounter',
  'S63043S Subluxation of carpometacarpal joint of unspecified thumb, sequela',
  'S63044A Dislocation of carpometacarpal joint of right thumb, initial encounter',
  'S63044D Dislocation of carpometacarpal joint of right thumb, subsequent encounter',
  'S63044S Dislocation of carpometacarpal joint of right thumb, sequela',
  'S63045A Dislocation of carpometacarpal joint of left thumb, initial encounter',
  'S63045D Dislocation of carpometacarpal joint of left thumb, subsequent encounter',
  'S63045S Dislocation of carpometacarpal joint of left thumb, sequela',
  'S63046A Dislocation of carpometacarpal joint of unspecified thumb, initial encounter',
  'S63046D Dislocation of carpometacarpal joint of unspecified thumb, subsequent encounter',
  'S63046S Dislocation of carpometacarpal joint of unspecified thumb, sequela',
  'S63051A Subluxation of other carpometacarpal joint of right hand, initial encounter',
  'S63051D Subluxation of other carpometacarpal joint of right hand, subsequent encounter',
  'S63051S Subluxation of other carpometacarpal joint of right hand, sequela',
  'S63052A Subluxation of other carpometacarpal joint of left hand, initial encounter',
  'S63052D Subluxation of other carpometacarpal joint of left hand, subsequent encounter',
  'S63052S Subluxation of other carpometacarpal joint of left hand, sequela',
  'S63053A Subluxation of other carpometacarpal joint of unspecified hand, initial encounter',
  'S63053D Subluxation of other carpometacarpal joint of unspecified hand, subsequent encounter',
  'S63053S Subluxation of other carpometacarpal joint of unspecified hand, sequela',
  'S63054A Dislocation of other carpometacarpal joint of right hand, initial encounter',
  'S63054D Dislocation of other carpometacarpal joint of right hand, subsequent encounter',
  'S63054S Dislocation of other carpometacarpal joint of right hand, sequela',
  'S63055A Dislocation of other carpometacarpal joint of left hand, initial encounter',
  'S63055D Dislocation of other carpometacarpal joint of left hand, subsequent encounter',
  'S63055S Dislocation of other carpometacarpal joint of left hand, sequela',
  'S63056A Dislocation of other carpometacarpal joint of unspecified hand, initial encounter',
  'S63056D Dislocation of other carpometacarpal joint of unspecified hand, subsequent encounter',
  'S63056S Dislocation of other carpometacarpal joint of unspecified hand, sequela',
  'S63061A Subluxation of metacarpal (bone), proximal end of right hand, initial encounter',
  'S63061D Subluxation of metacarpal (bone), proximal end of right hand, subsequent encounter',
  'S63061S Subluxation of metacarpal (bone), proximal end of right hand, sequela',
  'S63062A Subluxation of metacarpal (bone), proximal end of left hand, initial encounter',
  'S63062D Subluxation of metacarpal (bone), proximal end of left hand, subsequent encounter',
  'S63062S Subluxation of metacarpal (bone), proximal end of left hand, sequela',
  'S63063A Subluxation of metacarpal (bone), proximal end of unspecified hand, initial encounter',
  'S63063D Subluxation of metacarpal (bone), proximal end of unspecified hand, subsequent encounter',
  'S63063S Subluxation of metacarpal (bone), proximal end of unspecified hand, sequela',
  'S63064A Dislocation of metacarpal (bone), proximal end of right hand, initial encounter',
  'S63064D Dislocation of metacarpal (bone), proximal end of right hand, subsequent encounter',
  'S63064S Dislocation of metacarpal (bone), proximal end of right hand, sequela',
  'S63065A Dislocation of metacarpal (bone), proximal end of left hand, initial encounter',
  'S63065D Dislocation of metacarpal (bone), proximal end of left hand, subsequent encounter',
  'S63065S Dislocation of metacarpal (bone), proximal end of left hand, sequela',
  'S63066A Dislocation of metacarpal (bone), proximal end of unspecified hand, initial encounter',
  'S63066D Dislocation of metacarpal (bone), proximal end of unspecified hand, subsequent encounter',
  'S63066S Dislocation of metacarpal (bone), proximal end of unspecified hand, sequela',
  'S63071A Subluxation of distal end of right ulna, initial encounter',
  'S63071D Subluxation of distal end of right ulna, subsequent encounter',
  'S63071S Subluxation of distal end of right ulna, sequela',
  'S63072A Subluxation of distal end of left ulna, initial encounter',
  'S63072D Subluxation of distal end of left ulna, subsequent encounter',
  'S63072S Subluxation of distal end of left ulna, sequela',
  'S63073A Subluxation of distal end of unspecified ulna, initial encounter',
  'S63073D Subluxation of distal end of unspecified ulna, subsequent encounter',
  'S63073S Subluxation of distal end of unspecified ulna, sequela',
  'S63074A Dislocation of distal end of right ulna, initial encounter',
  'S63074D Dislocation of distal end of right ulna, subsequent encounter',
  'S63074S Dislocation of distal end of right ulna, sequela',
  'S63075A Dislocation of distal end of left ulna, initial encounter',
  'S63075D Dislocation of distal end of left ulna, subsequent encounter',
  'S63075S Dislocation of distal end of left ulna, sequela',
  'S63076A Dislocation of distal end of unspecified ulna, initial encounter',
  'S63076D Dislocation of distal end of unspecified ulna, subsequent encounter',
  'S63076S Dislocation of distal end of unspecified ulna, sequela',
  'S63091A Other subluxation of right wrist and hand, initial encounter',
  'S63091D Other subluxation of right wrist and hand, subsequent encounter',
  'S63091S Other subluxation of right wrist and hand, sequela',
  'S63092A Other subluxation of left wrist and hand, initial encounter',
  'S63092D Other subluxation of left wrist and hand, subsequent encounter',
  'S63092S Other subluxation of left wrist and hand, sequela',
  'S63093A Other subluxation of unspecified wrist and hand, initial encounter',
  'S63093D Other subluxation of unspecified wrist and hand, subsequent encounter',
  'S63093S Other subluxation of unspecified wrist and hand, sequela',
  'S63094A Other dislocation of right wrist and hand, initial encounter',
  'S63094D Other dislocation of right wrist and hand, subsequent encounter',
  'S63094S Other dislocation of right wrist and hand, sequela',
  'S63095A Other dislocation of left wrist and hand, initial encounter',
  'S63095D Other dislocation of left wrist and hand, subsequent encounter',
  'S63095S Other dislocation of left wrist and hand, sequela',
  'S63096A Other dislocation of unspecified wrist and hand, initial encounter',
  'S63096D Other dislocation of unspecified wrist and hand, subsequent encounter',
  'S63096S Other dislocation of unspecified wrist and hand, sequela',
  'S63101A Unspecified subluxation of right thumb, initial encounter',
  'S63101D Unspecified subluxation of right thumb, subsequent encounter',
  'S63101S Unspecified subluxation of right thumb, sequela',
  'S63102A Unspecified subluxation of left thumb, initial encounter',
  'S63102D Unspecified subluxation of left thumb, subsequent encounter',
  'S63102S Unspecified subluxation of left thumb, sequela',
  'S63103A Unspecified subluxation of unspecified thumb, initial encounter',
  'S63103D Unspecified subluxation of unspecified thumb, subsequent encounter',
  'S63103S Unspecified subluxation of unspecified thumb, sequela',
  'S63104A Unspecified dislocation of right thumb, initial encounter',
  'S63104D Unspecified dislocation of right thumb, subsequent encounter',
  'S63104S Unspecified dislocation of right thumb, sequela',
  'S63105A Unspecified dislocation of left thumb, initial encounter',
  'S63105D Unspecified dislocation of left thumb, subsequent encounter',
  'S63105S Unspecified dislocation of left thumb, sequela',
  'S63106A Unspecified dislocation of unspecified thumb, initial encounter',
  'S63106D Unspecified dislocation of unspecified thumb, subsequent encounter',
  'S63106S Unspecified dislocation of unspecified thumb, sequela',
  'S63111A Subluxation of metacarpophalangeal joint of right thumb, initial encounter',
  'S63111D Subluxation of metacarpophalangeal joint of right thumb, subsequent encounter',
  'S63111S Subluxation of metacarpophalangeal joint of right thumb, sequela',
  'S63112A Subluxation of metacarpophalangeal joint of left thumb, initial encounter',
  'S63112D Subluxation of metacarpophalangeal joint of left thumb, subsequent encounter',
  'S63112S Subluxation of metacarpophalangeal joint of left thumb, sequela',
  'S63113A Subluxation of metacarpophalangeal joint of unspecified thumb, initial encounter',
  'S63113D Subluxation of metacarpophalangeal joint of unspecified thumb, subsequent encounter',
  'S63113S Subluxation of metacarpophalangeal joint of unspecified thumb, sequela',
  'S63114A Dislocation of metacarpophalangeal joint of right thumb, initial encounter',
  'S63114D Dislocation of metacarpophalangeal joint of right thumb, subsequent encounter',
  'S63114S Dislocation of metacarpophalangeal joint of right thumb, sequela',
  'S63115A Dislocation of metacarpophalangeal joint of left thumb, initial encounter',
  'S63115D Dislocation of metacarpophalangeal joint of left thumb, subsequent encounter',
  'S63115S Dislocation of metacarpophalangeal joint of left thumb, sequela',
  'S63116A Dislocation of metacarpophalangeal joint of unspecified thumb, initial encounter',
  'S63116D Dislocation of metacarpophalangeal joint of unspecified thumb, subsequent encounter',
  'S63116S Dislocation of metacarpophalangeal joint of unspecified thumb, sequela',
  'S63121A Subluxation of unspecified interphalangeal joint of right thumb, initial encounter',
  'S63121D Subluxation of unspecified interphalangeal joint of right thumb, subsequent encounter',
  'S63121S Subluxation of unspecified interphalangeal joint of right thumb, sequela',
  'S63122A Subluxation of unspecified interphalangeal joint of left thumb, initial encounter',
  'S63122D Subluxation of unspecified interphalangeal joint of left thumb, subsequent encounter',
  'S63122S Subluxation of unspecified interphalangeal joint of left thumb, sequela',
  'S63123A Subluxation of unspecified interphalangeal joint of unspecified thumb, initial encounter',
  'S63123D Subluxation of unspecified interphalangeal joint of unspecified thumb, subsequent encounter',
  'S63123S Subluxation of unspecified interphalangeal joint of unspecified thumb, sequela',
  'S63124A Dislocation of unspecified interphalangeal joint of right thumb, initial encounter',
  'S63124D Dislocation of unspecified interphalangeal joint of right thumb, subsequent encounter',
  'S63124S Dislocation of unspecified interphalangeal joint of right thumb, sequela',
  'S63125A Dislocation of unspecified interphalangeal joint of left thumb, initial encounter',
  'S63125D Dislocation of unspecified interphalangeal joint of left thumb, subsequent encounter',
  'S63125S Dislocation of unspecified interphalangeal joint of left thumb, sequela',
  'S63126A Dislocation of unspecified interphalangeal joint of unspecified thumb, initial encounter',
  'S63126D Dislocation of unspecified interphalangeal joint of unspecified thumb, subsequent encounter',
  'S63126S Dislocation of unspecified interphalangeal joint of unspecified thumb, sequela',
  'S63131A Subluxation of proximal interphalangeal joint of right thumb, initial encounter',
  'S63131D Subluxation of proximal interphalangeal joint of right thumb, subsequent encounter',
  'S63131S Subluxation of proximal interphalangeal joint of right thumb, sequela',
  'S63132A Subluxation of proximal interphalangeal joint of left thumb, initial encounter',
  'S63132D Subluxation of proximal interphalangeal joint of left thumb, subsequent encounter',
  'S63132S Subluxation of proximal interphalangeal joint of left thumb, sequela',
  'S63133A Subluxation of proximal interphalangeal joint of unspecified thumb, initial encounter',
  'S63133D Subluxation of proximal interphalangeal joint of unspecified thumb, subsequent encounter',
  'S63133S Subluxation of proximal interphalangeal joint of unspecified thumb, sequela',
  'S63134A Dislocation of proximal interphalangeal joint of right thumb, initial encounter',
  'S63134D Dislocation of proximal interphalangeal joint of right thumb, subsequent encounter',
  'S63134S Dislocation of proximal interphalangeal joint of right thumb, sequela',
  'S63135A Dislocation of proximal interphalangeal joint of left thumb, initial encounter',
  'S63135D Dislocation of proximal interphalangeal joint of left thumb, subsequent encounter',
  'S63135S Dislocation of proximal interphalangeal joint of left thumb, sequela',
  'S63136A Dislocation of proximal interphalangeal joint of unspecified thumb, initial encounter',
  'S63136D Dislocation of proximal interphalangeal joint of unspecified thumb, subsequent encounter',
  'S63136S Dislocation of proximal interphalangeal joint of unspecified thumb, sequela',
  'S63141A Subluxation of distal interphalangeal joint of right thumb, initial encounter',
  'S63141D Subluxation of distal interphalangeal joint of right thumb, subsequent encounter',
  'S63141S Subluxation of distal interphalangeal joint of right thumb, sequela',
  'S63142A Subluxation of distal interphalangeal joint of left thumb, initial encounter',
  'S63142D Subluxation of distal interphalangeal joint of left thumb, subsequent encounter',
  'S63142S Subluxation of distal interphalangeal joint of left thumb, sequela',
  'S63143A Subluxation of distal interphalangeal joint of unspecified thumb, initial encounter',
  'S63143D Subluxation of distal interphalangeal joint of unspecified thumb, subsequent encounter',
  'S63143S Subluxation of distal interphalangeal joint of unspecified thumb, sequela',
  'S63144A Dislocation of distal interphalangeal joint of right thumb, initial encounter',
  'S63144D Dislocation of distal interphalangeal joint of right thumb, subsequent encounter',
  'S63144S Dislocation of distal interphalangeal joint of right thumb, sequela',
  'S63145A Dislocation of distal interphalangeal joint of left thumb, initial encounter',
  'S63145D Dislocation of distal interphalangeal joint of left thumb, subsequent encounter',
  'S63145S Dislocation of distal interphalangeal joint of left thumb, sequela',
  'S63146A Dislocation of distal interphalangeal joint of unspecified thumb, initial encounter',
  'S63146D Dislocation of distal interphalangeal joint of unspecified thumb, subsequent encounter',
  'S63146S Dislocation of distal interphalangeal joint of unspecified thumb, sequela',
  'S63200A Unspecified subluxation of right index finger, initial encounter',
  'S63200D Unspecified subluxation of right index finger, subsequent encounter',
  'S63200S Unspecified subluxation of right index finger, sequela',
  'S63201A Unspecified subluxation of left index finger, initial encounter',
  'S63201D Unspecified subluxation of left index finger, subsequent encounter',
  'S63201S Unspecified subluxation of left index finger, sequela',
  'S63202A Unspecified subluxation of right middle finger, initial encounter',
  'S63202D Unspecified subluxation of right middle finger, subsequent encounter',
  'S63202S Unspecified subluxation of right middle finger, sequela',
  'S63203A Unspecified subluxation of left middle finger, initial encounter',
  'S63203D Unspecified subluxation of left middle finger, subsequent encounter',
  'S63203S Unspecified subluxation of left middle finger, sequela',
  'S63204A Unspecified subluxation of right ring finger, initial encounter',
  'S63204D Unspecified subluxation of right ring finger, subsequent encounter',
  'S63204S Unspecified subluxation of right ring finger, sequela',
  'S63205A Unspecified subluxation of left ring finger, initial encounter',
  'S63205D Unspecified subluxation of left ring finger, subsequent encounter',
  'S63205S Unspecified subluxation of left ring finger, sequela',
  'S63206A Unspecified subluxation of right little finger, initial encounter',
  'S63206D Unspecified subluxation of right little finger, subsequent encounter',
  'S63206S Unspecified subluxation of right little finger, sequela',
  'S63207A Unspecified subluxation of left little finger, initial encounter',
  'S63207D Unspecified subluxation of left little finger, subsequent encounter',
  'S63207S Unspecified subluxation of left little finger, sequela',
  'S63208A Unspecified subluxation of other finger, initial encounter',
  'S63208D Unspecified subluxation of other finger, subsequent encounter',
  'S63208S Unspecified subluxation of other finger, sequela',
  'S63209A Unspecified subluxation of unspecified finger, initial encounter',
  'S63209D Unspecified subluxation of unspecified finger, subsequent encounter',
  'S63209S Unspecified subluxation of unspecified finger, sequela',
  'S63210A Subluxation of metacarpophalangeal joint of right index finger, initial encounter',
  'S63210D Subluxation of metacarpophalangeal joint of right index finger, subsequent encounter',
  'S63210S Subluxation of metacarpophalangeal joint of right index finger, sequela',
  'S63211A Subluxation of metacarpophalangeal joint of left index finger, initial encounter',
  'S63211D Subluxation of metacarpophalangeal joint of left index finger, subsequent encounter',
  'S63211S Subluxation of metacarpophalangeal joint of left index finger, sequela',
  'S63212A Subluxation of metacarpophalangeal joint of right middle finger, initial encounter',
  'S63212D Subluxation of metacarpophalangeal joint of right middle finger, subsequent encounter',
  'S63212S Subluxation of metacarpophalangeal joint of right middle finger, sequela',
  'S63213A Subluxation of metacarpophalangeal joint of left middle finger, initial encounter',
  'S63213D Subluxation of metacarpophalangeal joint of left middle finger, subsequent encounter',
  'S63213S Subluxation of metacarpophalangeal joint of left middle finger, sequela',
  'S63214A Subluxation of metacarpophalangeal joint of right ring finger, initial encounter',
  'S63214D Subluxation of metacarpophalangeal joint of right ring finger, subsequent encounter',
  'S63214S Subluxation of metacarpophalangeal joint of right ring finger, sequela',
  'S63215A Subluxation of metacarpophalangeal joint of left ring finger, initial encounter',
  'S63215D Subluxation of metacarpophalangeal joint of left ring finger, subsequent encounter',
  'S63215S Subluxation of metacarpophalangeal joint of left ring finger, sequela',
  'S63216A Subluxation of metacarpophalangeal joint of right little finger, initial encounter',
  'S63216D Subluxation of metacarpophalangeal joint of right little finger, subsequent encounter',
  'S63216S Subluxation of metacarpophalangeal joint of right little finger, sequela',
  'S63217A Subluxation of metacarpophalangeal joint of left little finger, initial encounter',
  'S63217D Subluxation of metacarpophalangeal joint of left little finger, subsequent encounter',
  'S63217S Subluxation of metacarpophalangeal joint of left little finger, sequela',
  'S63218A Subluxation of metacarpophalangeal joint of other finger, initial encounter',
  'S63218D Subluxation of metacarpophalangeal joint of other finger, subsequent encounter',
  'S63218S Subluxation of metacarpophalangeal joint of other finger, sequela',
  'S63219A Subluxation of metacarpophalangeal joint of unspecified finger, initial encounter',
  'S63219D Subluxation of metacarpophalangeal joint of unspecified finger, subsequent encounter',
  'S63219S Subluxation of metacarpophalangeal joint of unspecified finger, sequela',
  'S63220A Subluxation of unspecified interphalangeal joint of right index finger, initial encounter',
  'S63220D Subluxation of unspecified interphalangeal joint of right index finger, subsequent encounter',
  'S63220S Subluxation of unspecified interphalangeal joint of right index finger, sequela',
  'S63221A Subluxation of unspecified interphalangeal joint of left index finger, initial encounter',
  'S63221D Subluxation of unspecified interphalangeal joint of left index finger, subsequent encounter',
  'S63221S Subluxation of unspecified interphalangeal joint of left index finger, sequela',
  'S63222A Subluxation of unspecified interphalangeal joint of right middle finger, initial encounter',
  'S63222D Subluxation of unspecified interphalangeal joint of right middle finger, subsequent encounter',
  'S63222S Subluxation of unspecified interphalangeal joint of right middle finger, sequela',
  'S63223A Subluxation of unspecified interphalangeal joint of left middle finger, initial encounter',
  'S63223D Subluxation of unspecified interphalangeal joint of left middle finger, subsequent encounter',
  'S63223S Subluxation of unspecified interphalangeal joint of left middle finger, sequela',
  'S63224A Subluxation of unspecified interphalangeal joint of right ring finger, initial encounter',
  'S63224D Subluxation of unspecified interphalangeal joint of right ring finger, subsequent encounter',
  'S63224S Subluxation of unspecified interphalangeal joint of right ring finger, sequela',
  'S63225A Subluxation of unspecified interphalangeal joint of left ring finger, initial encounter',
  'S63225D Subluxation of unspecified interphalangeal joint of left ring finger, subsequent encounter',
  'S63225S Subluxation of unspecified interphalangeal joint of left ring finger, sequela',
  'S63226A Subluxation of unspecified interphalangeal joint of right little finger, initial encounter',
  'S63226D Subluxation of unspecified interphalangeal joint of right little finger, subsequent encounter',
  'S63226S Subluxation of unspecified interphalangeal joint of right little finger, sequela',
  'S63227A Subluxation of unspecified interphalangeal joint of left little finger, initial encounter',
  'S63227D Subluxation of unspecified interphalangeal joint of left little finger, subsequent encounter',
  'S63227S Subluxation of unspecified interphalangeal joint of left little finger, sequela',
  'S63228A Subluxation of unspecified interphalangeal joint of other finger, initial encounter',
  'S63228D Subluxation of unspecified interphalangeal joint of other finger, subsequent encounter',
  'S63228S Subluxation of unspecified interphalangeal joint of other finger, sequela',
  'S63229A Subluxation of unspecified interphalangeal joint of unspecified finger, initial encounter',
  'S63229D Subluxation of unspecified interphalangeal joint of unspecified finger, subsequent encounter',
  'S63229S Subluxation of unspecified interphalangeal joint of unspecified finger, sequela',
  'S63230A Subluxation of proximal interphalangeal joint of right index finger, initial encounter',
  'S63230D Subluxation of proximal interphalangeal joint of right index finger, subsequent encounter',
  'S63230S Subluxation of proximal interphalangeal joint of right index finger, sequela',
  'S63231A Subluxation of proximal interphalangeal joint of left index finger, initial encounter',
  'S63231D Subluxation of proximal interphalangeal joint of left index finger, subsequent encounter',
  'S63231S Subluxation of proximal interphalangeal joint of left index finger, sequela',
  'S63232A Subluxation of proximal interphalangeal joint of right middle finger, initial encounter',
  'S63232D Subluxation of proximal interphalangeal joint of right middle finger, subsequent encounter',
  'S63232S Subluxation of proximal interphalangeal joint of right middle finger, sequela',
  'S63233A Subluxation of proximal interphalangeal joint of left middle finger, initial encounter',
  'S63233D Subluxation of proximal interphalangeal joint of left middle finger, subsequent encounter',
  'S63233S Subluxation of proximal interphalangeal joint of left middle finger, sequela',
  'S63234A Subluxation of proximal interphalangeal joint of right ring finger, initial encounter',
  'S63234D Subluxation of proximal interphalangeal joint of right ring finger, subsequent encounter',
  'S63234S Subluxation of proximal interphalangeal joint of right ring finger, sequela',
  'S63235A Subluxation of proximal interphalangeal joint of left ring finger, initial encounter',
  'S63235D Subluxation of proximal interphalangeal joint of left ring finger, subsequent encounter',
  'S63235S Subluxation of proximal interphalangeal joint of left ring finger, sequela',
  'S63236A Subluxation of proximal interphalangeal joint of right little finger, initial encounter',
  'S63236D Subluxation of proximal interphalangeal joint of right little finger, subsequent encounter',
  'S63236S Subluxation of proximal interphalangeal joint of right little finger, sequela',
  'S63237A Subluxation of proximal interphalangeal joint of left little finger, initial encounter',
  'S63237D Subluxation of proximal interphalangeal joint of left little finger, subsequent encounter',
  'S63237S Subluxation of proximal interphalangeal joint of left little finger, sequela',
  'S63238A Subluxation of proximal interphalangeal joint of other finger, initial encounter',
  'S63238D Subluxation of proximal interphalangeal joint of other finger, subsequent encounter',
  'S63238S Subluxation of proximal interphalangeal joint of other finger, sequela',
  'S63239A Subluxation of proximal interphalangeal joint of unspecified finger, initial encounter',
  'S63239D Subluxation of proximal interphalangeal joint of unspecified finger, subsequent encounter',
  'S63239S Subluxation of proximal interphalangeal joint of unspecified finger, sequela',
  'S63240A Subluxation of distal interphalangeal joint of right index finger, initial encounter',
  'S63240D Subluxation of distal interphalangeal joint of right index finger, subsequent encounter',
  'S63240S Subluxation of distal interphalangeal joint of right index finger, sequela',
  'S63241A Subluxation of distal interphalangeal joint of left index finger, initial encounter',
  'S63241D Subluxation of distal interphalangeal joint of left index finger, subsequent encounter',
  'S63241S Subluxation of distal interphalangeal joint of left index finger, sequela',
  'S63242A Subluxation of distal interphalangeal joint of right middle finger, initial encounter',
  'S63242D Subluxation of distal interphalangeal joint of right middle finger, subsequent encounter',
  'S63242S Subluxation of distal interphalangeal joint of right middle finger, sequela',
  'S63243A Subluxation of distal interphalangeal joint of left middle finger, initial encounter',
  'S63243D Subluxation of distal interphalangeal joint of left middle finger, subsequent encounter',
  'S63243S Subluxation of distal interphalangeal joint of left middle finger, sequela',
  'S63244A Subluxation of distal interphalangeal joint of right ring finger, initial encounter',
  'S63244D Subluxation of distal interphalangeal joint of right ring finger, subsequent encounter',
  'S63244S Subluxation of distal interphalangeal joint of right ring finger, sequela',
  'S63245A Subluxation of distal interphalangeal joint of left ring finger, initial encounter',
  'S63245D Subluxation of distal interphalangeal joint of left ring finger, subsequent encounter',
  'S63245S Subluxation of distal interphalangeal joint of left ring finger, sequela',
  'S63246A Subluxation of distal interphalangeal joint of right little finger, initial encounter',
  'S63246D Subluxation of distal interphalangeal joint of right little finger, subsequent encounter',
  'S63246S Subluxation of distal interphalangeal joint of right little finger, sequela',
  'S63247A Subluxation of distal interphalangeal joint of left little finger, initial encounter',
  'S63247D Subluxation of distal interphalangeal joint of left little finger, subsequent encounter',
  'S63247S Subluxation of distal interphalangeal joint of left little finger, sequela',
  'S63248A Subluxation of distal interphalangeal joint of other finger, initial encounter',
  'S63248D Subluxation of distal interphalangeal joint of other finger, subsequent encounter',
  'S63248S Subluxation of distal interphalangeal joint of other finger, sequela',
  'S63249A Subluxation of distal interphalangeal joint of unspecified finger, initial encounter',
  'S63249D Subluxation of distal interphalangeal joint of unspecified finger, subsequent encounter',
  'S63249S Subluxation of distal interphalangeal joint of unspecified finger, sequela',
  'S63250A Unspecified dislocation of right index finger, initial encounter',
  'S63250D Unspecified dislocation of right index finger, subsequent encounter',
  'S63250S Unspecified dislocation of right index finger, sequela',
  'S63251A Unspecified dislocation of left index finger, initial encounter',
  'S63251D Unspecified dislocation of left index finger, subsequent encounter',
  'S63251S Unspecified dislocation of left index finger, sequela',
  'S63252A Unspecified dislocation of right middle finger, initial encounter',
  'S63252D Unspecified dislocation of right middle finger, subsequent encounter',
  'S63252S Unspecified dislocation of right middle finger, sequela',
  'S63253A Unspecified dislocation of left middle finger, initial encounter',
  'S63253D Unspecified dislocation of left middle finger, subsequent encounter',
  'S63253S Unspecified dislocation of left middle finger, sequela',
  'S63254A Unspecified dislocation of right ring finger, initial encounter',
  'S63254D Unspecified dislocation of right ring finger, subsequent encounter',
  'S63254S Unspecified dislocation of right ring finger, sequela',
  'S63255A Unspecified dislocation of left ring finger, initial encounter',
  'S63255D Unspecified dislocation of left ring finger, subsequent encounter',
  'S63255S Unspecified dislocation of left ring finger, sequela',
  'S63256A Unspecified dislocation of right little finger, initial encounter',
  'S63256D Unspecified dislocation of right little finger, subsequent encounter',
  'S63256S Unspecified dislocation of right little finger, sequela',
  'S63257A Unspecified dislocation of left little finger, initial encounter',
  'S63257D Unspecified dislocation of left little finger, subsequent encounter',
  'S63257S Unspecified dislocation of left little finger, sequela',
  'S63258A Unspecified dislocation of other finger, initial encounter',
  'S63258D Unspecified dislocation of other finger, subsequent encounter',
  'S63258S Unspecified dislocation of other finger, sequela',
  'S63259A Unspecified dislocation of unspecified finger, initial encounter',
  'S63259D Unspecified dislocation of unspecified finger, subsequent encounter',
  'S63259S Unspecified dislocation of unspecified finger, sequela',
  'S63260A Dislocation of metacarpophalangeal joint of right index finger, initial encounter',
  'S63260D Dislocation of metacarpophalangeal joint of right index finger, subsequent encounter',
  'S63260S Dislocation of metacarpophalangeal joint of right index finger, sequela',
  'S63261A Dislocation of metacarpophalangeal joint of left index finger, initial encounter',
  'S63261D Dislocation of metacarpophalangeal joint of left index finger, subsequent encounter',
  'S63261S Dislocation of metacarpophalangeal joint of left index finger, sequela',
  'S63262A Dislocation of metacarpophalangeal joint of right middle finger, initial encounter',
  'S63262D Dislocation of metacarpophalangeal joint of right middle finger, subsequent encounter',
  'S63262S Dislocation of metacarpophalangeal joint of right middle finger, sequela',
  'S63263A Dislocation of metacarpophalangeal joint of left middle finger, initial encounter',
  'S63263D Dislocation of metacarpophalangeal joint of left middle finger, subsequent encounter',
  'S63263S Dislocation of metacarpophalangeal joint of left middle finger, sequela',
  'S63264A Dislocation of metacarpophalangeal joint of right ring finger, initial encounter',
  'S63264D Dislocation of metacarpophalangeal joint of right ring finger, subsequent encounter',
  'S63264S Dislocation of metacarpophalangeal joint of right ring finger, sequela',
  'S63265A Dislocation of metacarpophalangeal joint of left ring finger, initial encounter',
  'S63265D Dislocation of metacarpophalangeal joint of left ring finger, subsequent encounter',
  'S63265S Dislocation of metacarpophalangeal joint of left ring finger, sequela',
  'S63266A Dislocation of metacarpophalangeal joint of right little finger, initial encounter',
  'S63266D Dislocation of metacarpophalangeal joint of right little finger, subsequent encounter',
  'S63266S Dislocation of metacarpophalangeal joint of right little finger, sequela',
  'S63267A Dislocation of metacarpophalangeal joint of left little finger, initial encounter',
  'S63267D Dislocation of metacarpophalangeal joint of left little finger, subsequent encounter',
  'S63267S Dislocation of metacarpophalangeal joint of left little finger, sequela',
  'S63268A Dislocation of metacarpophalangeal joint of other finger, initial encounter',
  'S63268D Dislocation of metacarpophalangeal joint of other finger, subsequent encounter',
  'S63268S Dislocation of metacarpophalangeal joint of other finger, sequela',
  'S63269A Dislocation of metacarpophalangeal joint of unspecified finger, initial encounter',
  'S63269D Dislocation of metacarpophalangeal joint of unspecified finger, subsequent encounter',
  'S63269S Dislocation of metacarpophalangeal joint of unspecified finger, sequela',
  'S63270A Dislocation of unspecified interphalangeal joint of right index finger, initial encounter',
  'S63270D Dislocation of unspecified interphalangeal joint of right index finger, subsequent encounter',
  'S63270S Dislocation of unspecified interphalangeal joint of right index finger, sequela',
  'S63271A Dislocation of unspecified interphalangeal joint of left index finger, initial encounter',
  'S63271D Dislocation of unspecified interphalangeal joint of left index finger, subsequent encounter',
  'S63271S Dislocation of unspecified interphalangeal joint of left index finger, sequela',
  'S63272A Dislocation of unspecified interphalangeal joint of right middle finger, initial encounter',
  'S63272D Dislocation of unspecified interphalangeal joint of right middle finger, subsequent encounter',
  'S63272S Dislocation of unspecified interphalangeal joint of right middle finger, sequela',
  'S63273A Dislocation of unspecified interphalangeal joint of left middle finger, initial encounter',
  'S63273D Dislocation of unspecified interphalangeal joint of left middle finger, subsequent encounter',
  'S63273S Dislocation of unspecified interphalangeal joint of left middle finger, sequela',
  'S63274A Dislocation of unspecified interphalangeal joint of right ring finger, initial encounter',
  'S63274D Dislocation of unspecified interphalangeal joint of right ring finger, subsequent encounter',
  'S63274S Dislocation of unspecified interphalangeal joint of right ring finger, sequela',
  'S63275A Dislocation of unspecified interphalangeal joint of left ring finger, initial encounter',
  'S63275D Dislocation of unspecified interphalangeal joint of left ring finger, subsequent encounter',
  'S63275S Dislocation of unspecified interphalangeal joint of left ring finger, sequela',
  'S63276A Dislocation of unspecified interphalangeal joint of right little finger, initial encounter',
  'S63276D Dislocation of unspecified interphalangeal joint of right little finger, subsequent encounter',
  'S63276S Dislocation of unspecified interphalangeal joint of right little finger, sequela',
  'S63277A Dislocation of unspecified interphalangeal joint of left little finger, initial encounter',
  'S63277D Dislocation of unspecified interphalangeal joint of left little finger, subsequent encounter',
  'S63277S Dislocation of unspecified interphalangeal joint of left little finger, sequela',
  'S63278A Dislocation of unspecified interphalangeal joint of other finger, initial encounter',
  'S63278D Dislocation of unspecified interphalangeal joint of other finger, subsequent encounter',
  'S63278S Dislocation of unspecified interphalangeal joint of other finger, sequela',
  'S63279A Dislocation of unspecified interphalangeal joint of unspecified finger, initial encounter',
  'S63279D Dislocation of unspecified interphalangeal joint of unspecified finger, subsequent encounter',
  'S63279S Dislocation of unspecified interphalangeal joint of unspecified finger, sequela',
  'S63280A Dislocation of proximal interphalangeal joint of right index finger, initial encounter',
  'S63280D Dislocation of proximal interphalangeal joint of right index finger, subsequent encounter',
  'S63280S Dislocation of proximal interphalangeal joint of right index finger, sequela',
  'S63281A Dislocation of proximal interphalangeal joint of left index finger, initial encounter',
  'S63281D Dislocation of proximal interphalangeal joint of left index finger, subsequent encounter',
  'S63281S Dislocation of proximal interphalangeal joint of left index finger, sequela',
  'S63282A Dislocation of proximal interphalangeal joint of right middle finger, initial encounter',
  'S63282D Dislocation of proximal interphalangeal joint of right middle finger, subsequent encounter',
  'S63282S Dislocation of proximal interphalangeal joint of right middle finger, sequela',
  'S63283A Dislocation of proximal interphalangeal joint of left middle finger, initial encounter',
  'S63283D Dislocation of proximal interphalangeal joint of left middle finger, subsequent encounter',
  'S63283S Dislocation of proximal interphalangeal joint of left middle finger, sequela',
  'S63284A Dislocation of proximal interphalangeal joint of right ring finger, initial encounter',
  'S63284D Dislocation of proximal interphalangeal joint of right ring finger, subsequent encounter',
  'S63284S Dislocation of proximal interphalangeal joint of right ring finger, sequela',
  'S63285A Dislocation of proximal interphalangeal joint of left ring finger, initial encounter',
  'S63285D Dislocation of proximal interphalangeal joint of left ring finger, subsequent encounter',
  'S63285S Dislocation of proximal interphalangeal joint of left ring finger, sequela',
  'S63286A Dislocation of proximal interphalangeal joint of right little finger, initial encounter',
  'S63286D Dislocation of proximal interphalangeal joint of right little finger, subsequent encounter',
  'S63286S Dislocation of proximal interphalangeal joint of right little finger, sequela',
  'S63287A Dislocation of proximal interphalangeal joint of left little finger, initial encounter',
  'S63287D Dislocation of proximal interphalangeal joint of left little finger, subsequent encounter',
  'S63287S Dislocation of proximal interphalangeal joint of left little finger, sequela',
  'S63288A Dislocation of proximal interphalangeal joint of other finger, initial encounter',
  'S63288D Dislocation of proximal interphalangeal joint of other finger, subsequent encounter',
  'S63288S Dislocation of proximal interphalangeal joint of other finger, sequela',
  'S63289A Dislocation of proximal interphalangeal joint of unspecified finger, initial encounter',
  'S63289D Dislocation of proximal interphalangeal joint of unspecified finger, subsequent encounter',
  'S63289S Dislocation of proximal interphalangeal joint of unspecified finger, sequela',
  'S63290A Dislocation of distal interphalangeal joint of right index finger, initial encounter',
  'S63290D Dislocation of distal interphalangeal joint of right index finger, subsequent encounter',
  'S63290S Dislocation of distal interphalangeal joint of right index finger, sequela',
  'S63291A Dislocation of distal interphalangeal joint of left index finger, initial encounter',
  'S63291D Dislocation of distal interphalangeal joint of left index finger, subsequent encounter',
  'S63291S Dislocation of distal interphalangeal joint of left index finger, sequela',
  'S63292A Dislocation of distal interphalangeal joint of right middle finger, initial encounter',
  'S63292D Dislocation of distal interphalangeal joint of right middle finger, subsequent encounter',
  'S63292S Dislocation of distal interphalangeal joint of right middle finger, sequela',
  'S63293A Dislocation of distal interphalangeal joint of left middle finger, initial encounter',
  'S63293D Dislocation of distal interphalangeal joint of left middle finger, subsequent encounter',
  'S63293S Dislocation of distal interphalangeal joint of left middle finger, sequela',
  'S63294A Dislocation of distal interphalangeal joint of right ring finger, initial encounter',
  'S63294D Dislocation of distal interphalangeal joint of right ring finger, subsequent encounter',
  'S63294S Dislocation of distal interphalangeal joint of right ring finger, sequela',
  'S63295A Dislocation of distal interphalangeal joint of left ring finger, initial encounter',
  'S63295D Dislocation of distal interphalangeal joint of left ring finger, subsequent encounter',
  'S63295S Dislocation of distal interphalangeal joint of left ring finger, sequela',
  'S63296A Dislocation of distal interphalangeal joint of right little finger, initial encounter',
  'S63296D Dislocation of distal interphalangeal joint of right little finger, subsequent encounter',
  'S63296S Dislocation of distal interphalangeal joint of right little finger, sequela',
  'S63297A Dislocation of distal interphalangeal joint of left little finger, initial encounter',
  'S63297D Dislocation of distal interphalangeal joint of left little finger, subsequent encounter',
  'S63297S Dislocation of distal interphalangeal joint of left little finger, sequela',
  'S63298A Dislocation of distal interphalangeal joint of other finger, initial encounter',
  'S63298D Dislocation of distal interphalangeal joint of other finger, subsequent encounter',
  'S63298S Dislocation of distal interphalangeal joint of other finger, sequela',
  'S63299A Dislocation of distal interphalangeal joint of unspecified finger, initial encounter',
  'S63299D Dislocation of distal interphalangeal joint of unspecified finger, subsequent encounter',
  'S63299S Dislocation of distal interphalangeal joint of unspecified finger, sequela',
  'S63301A Traumatic rupture of unspecified ligament of right wrist, initial encounter',
  'S63301D Traumatic rupture of unspecified ligament of right wrist, subsequent encounter',
  'S63301S Traumatic rupture of unspecified ligament of right wrist, sequela',
  'S63302A Traumatic rupture of unspecified ligament of left wrist, initial encounter',
  'S63302D Traumatic rupture of unspecified ligament of left wrist, subsequent encounter',
  'S63302S Traumatic rupture of unspecified ligament of left wrist, sequela',
  'S63309A Traumatic rupture of unspecified ligament of unspecified wrist, initial encounter',
  'S63309D Traumatic rupture of unspecified ligament of unspecified wrist, subsequent encounter',
  'S63309S Traumatic rupture of unspecified ligament of unspecified wrist, sequela',
  'S63311A Traumatic rupture of collateral ligament of right wrist, initial encounter',
  'S63311D Traumatic rupture of collateral ligament of right wrist, subsequent encounter',
  'S63311S Traumatic rupture of collateral ligament of right wrist, sequela',
  'S63312A Traumatic rupture of collateral ligament of left wrist, initial encounter',
  'S63312D Traumatic rupture of collateral ligament of left wrist, subsequent encounter',
  'S63312S Traumatic rupture of collateral ligament of left wrist, sequela',
  'S63319A Traumatic rupture of collateral ligament of unspecified wrist, initial encounter',
  'S63319D Traumatic rupture of collateral ligament of unspecified wrist, subsequent encounter',
  'S63319S Traumatic rupture of collateral ligament of unspecified wrist, sequela',
  'S63321A Traumatic rupture of right radiocarpal ligament, initial encounter',
  'S63321D Traumatic rupture of right radiocarpal ligament, subsequent encounter',
  'S63321S Traumatic rupture of right radiocarpal ligament, sequela',
  'S63322A Traumatic rupture of left radiocarpal ligament, initial encounter',
  'S63322D Traumatic rupture of left radiocarpal ligament, subsequent encounter',
  'S63322S Traumatic rupture of left radiocarpal ligament, sequela',
  'S63329A Traumatic rupture of unspecified radiocarpal ligament, initial encounter',
  'S63329D Traumatic rupture of unspecified radiocarpal ligament, subsequent encounter',
  'S63329S Traumatic rupture of unspecified radiocarpal ligament, sequela',
  'S63331A Traumatic rupture of right ulnocarpal (palmar) ligament, initial encounter',
  'S63331D Traumatic rupture of right ulnocarpal (palmar) ligament, subsequent encounter',
  'S63331S Traumatic rupture of right ulnocarpal (palmar) ligament, sequela',
  'S63332A Traumatic rupture of left ulnocarpal (palmar) ligament, initial encounter',
  'S63332D Traumatic rupture of left ulnocarpal (palmar) ligament, subsequent encounter',
  'S63332S Traumatic rupture of left ulnocarpal (palmar) ligament, sequela',
  'S63339A Traumatic rupture of unspecified ulnocarpal (palmar) ligament, initial encounter',
  'S63339D Traumatic rupture of unspecified ulnocarpal (palmar) ligament, subsequent encounter',
  'S63339S Traumatic rupture of unspecified ulnocarpal (palmar) ligament, sequela',
  'S63391A Traumatic rupture of other ligament of right wrist, initial encounter',
  'S63391D Traumatic rupture of other ligament of right wrist, subsequent encounter',
  'S63391S Traumatic rupture of other ligament of right wrist, sequela',
  'S63392A Traumatic rupture of other ligament of left wrist, initial encounter',
  'S63392D Traumatic rupture of other ligament of left wrist, subsequent encounter',
  'S63392S Traumatic rupture of other ligament of left wrist, sequela',
  'S63399A Traumatic rupture of other ligament of unspecified wrist, initial encounter',
  'S63399D Traumatic rupture of other ligament of unspecified wrist, subsequent encounter',
  'S63399S Traumatic rupture of other ligament of unspecified wrist, sequela',
  'S63400A Traumatic rupture of unspecified ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63400D Traumatic rupture of unspecified ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63400S Traumatic rupture of unspecified ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63401A Traumatic rupture of unspecified ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63401D Traumatic rupture of unspecified ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63401S Traumatic rupture of unspecified ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63402A Traumatic rupture of unspecified ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63402D Traumatic rupture of unspecified ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63402S Traumatic rupture of unspecified ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63403A Traumatic rupture of unspecified ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63403D Traumatic rupture of unspecified ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63403S Traumatic rupture of unspecified ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63404A Traumatic rupture of unspecified ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63404D Traumatic rupture of unspecified ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63404S Traumatic rupture of unspecified ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63405A Traumatic rupture of unspecified ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63405D Traumatic rupture of unspecified ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63405S Traumatic rupture of unspecified ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63406A Traumatic rupture of unspecified ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63406D Traumatic rupture of unspecified ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63406S Traumatic rupture of unspecified ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63407A Traumatic rupture of unspecified ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63407D Traumatic rupture of unspecified ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63407S Traumatic rupture of unspecified ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63408A Traumatic rupture of unspecified ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63408D Traumatic rupture of unspecified ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63408S Traumatic rupture of unspecified ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63409A Traumatic rupture of unspecified ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63409D Traumatic rupture of unspecified ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63409S Traumatic rupture of unspecified ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63410A Traumatic rupture of collateral ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63410D Traumatic rupture of collateral ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63410S Traumatic rupture of collateral ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63411A Traumatic rupture of collateral ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63411D Traumatic rupture of collateral ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63411S Traumatic rupture of collateral ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63412A Traumatic rupture of collateral ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63412D Traumatic rupture of collateral ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63412S Traumatic rupture of collateral ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63413A Traumatic rupture of collateral ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63413D Traumatic rupture of collateral ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63413S Traumatic rupture of collateral ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63414A Traumatic rupture of collateral ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63414D Traumatic rupture of collateral ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63414S Traumatic rupture of collateral ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63415A Traumatic rupture of collateral ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63415D Traumatic rupture of collateral ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63415S Traumatic rupture of collateral ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63416A Traumatic rupture of collateral ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63416D Traumatic rupture of collateral ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63416S Traumatic rupture of collateral ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63417A Traumatic rupture of collateral ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63417D Traumatic rupture of collateral ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63417S Traumatic rupture of collateral ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63418A Traumatic rupture of collateral ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63418D Traumatic rupture of collateral ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63418S Traumatic rupture of collateral ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63419A Traumatic rupture of collateral ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63419D Traumatic rupture of collateral ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63419S Traumatic rupture of collateral ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63420A Traumatic rupture of palmar ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63420D Traumatic rupture of palmar ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63420S Traumatic rupture of palmar ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63421A Traumatic rupture of palmar ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63421D Traumatic rupture of palmar ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63421S Traumatic rupture of palmar ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63422A Traumatic rupture of palmar ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63422D Traumatic rupture of palmar ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63422S Traumatic rupture of palmar ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63423A Traumatic rupture of palmar ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63423D Traumatic rupture of palmar ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63423S Traumatic rupture of palmar ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63424A Traumatic rupture of palmar ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63424D Traumatic rupture of palmar ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63424S Traumatic rupture of palmar ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63425A Traumatic rupture of palmar ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63425D Traumatic rupture of palmar ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63425S Traumatic rupture of palmar ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63426A Traumatic rupture of palmar ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63426D Traumatic rupture of palmar ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63426S Traumatic rupture of palmar ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63427A Traumatic rupture of palmar ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63427D Traumatic rupture of palmar ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63427S Traumatic rupture of palmar ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63428A Traumatic rupture of palmar ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63428D Traumatic rupture of palmar ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63428S Traumatic rupture of palmar ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63429A Traumatic rupture of palmar ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63429D Traumatic rupture of palmar ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63429S Traumatic rupture of palmar ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63430A Traumatic rupture of volar plate of right index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63430D Traumatic rupture of volar plate of right index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63430S Traumatic rupture of volar plate of right index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63431A Traumatic rupture of volar plate of left index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63431D Traumatic rupture of volar plate of left index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63431S Traumatic rupture of volar plate of left index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63432A Traumatic rupture of volar plate of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63432D Traumatic rupture of volar plate of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63432S Traumatic rupture of volar plate of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63433A Traumatic rupture of volar plate of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63433D Traumatic rupture of volar plate of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63433S Traumatic rupture of volar plate of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63434A Traumatic rupture of volar plate of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63434D Traumatic rupture of volar plate of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63434S Traumatic rupture of volar plate of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63435A Traumatic rupture of volar plate of left ring finger at metacarpophalangeal and interphalangeal',
  'joint, initial encounter',
  'S63435D Traumatic rupture of volar plate of left ring finger at metacarpophalangeal and interphalangeal',
  'joint, subsequent encounter',
  'S63435S Traumatic rupture of volar plate of left ring finger at metacarpophalangeal and interphalangeal',
  'joint, sequela',
  'S63436A Traumatic rupture of volar plate of right little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63436D Traumatic rupture of volar plate of right little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63436S Traumatic rupture of volar plate of right little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63437A Traumatic rupture of volar plate of left little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63437D Traumatic rupture of volar plate of left little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63437S Traumatic rupture of volar plate of left little finger at metacarpophalangeal and interphalangeal',
  'joint, sequela',
  'S63438A Traumatic rupture of volar plate of other finger at metacarpophalangeal and interphalangeal',
  'joint, initial encounter',
  'S63438D Traumatic rupture of volar plate of other finger at metacarpophalangeal and interphalangeal',
  'joint, subsequent encounter',
  'S63438S Traumatic rupture of volar plate of other finger at metacarpophalangeal and interphalangeal',
  'joint, sequela',
  'S63439A Traumatic rupture of volar plate of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63439D Traumatic rupture of volar plate of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63439S Traumatic rupture of volar plate of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63490A Traumatic rupture of other ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63490D Traumatic rupture of other ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63490S Traumatic rupture of other ligament of right index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63491A Traumatic rupture of other ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63491D Traumatic rupture of other ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63491S Traumatic rupture of other ligament of left index finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63492A Traumatic rupture of other ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63492D Traumatic rupture of other ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63492S Traumatic rupture of other ligament of right middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63493A Traumatic rupture of other ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63493D Traumatic rupture of other ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63493S Traumatic rupture of other ligament of left middle finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63494A Traumatic rupture of other ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63494D Traumatic rupture of other ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63494S Traumatic rupture of other ligament of right ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63495A Traumatic rupture of other ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63495D Traumatic rupture of other ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63495S Traumatic rupture of other ligament of left ring finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63496A Traumatic rupture of other ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63496D Traumatic rupture of other ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63496S Traumatic rupture of other ligament of right little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63497A Traumatic rupture of other ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63497D Traumatic rupture of other ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63497S Traumatic rupture of other ligament of left little finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63498A Traumatic rupture of other ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63498D Traumatic rupture of other ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63498S Traumatic rupture of other ligament of other finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63499A Traumatic rupture of other ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, initial encounter',
  'S63499D Traumatic rupture of other ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, subsequent encounter',
  'S63499S Traumatic rupture of other ligament of unspecified finger at metacarpophalangeal and',
  'interphalangeal joint, sequela',
  'S63501A Unspecified sprain of right wrist, initial encounter',
  'S63501D Unspecified sprain of right wrist, subsequent encounter',
  'S63501S Unspecified sprain of right wrist, sequela',
  'S63502A Unspecified sprain of left wrist, initial encounter',
  'S63502D Unspecified sprain of left wrist, subsequent encounter',
  'S63502S Unspecified sprain of left wrist, sequela',
  'S63509A Unspecified sprain of unspecified wrist, initial encounter',
  'S63509D Unspecified sprain of unspecified wrist, subsequent encounter',
  'S63509S Unspecified sprain of unspecified wrist, sequela',
  'S63511A Sprain of carpal joint of right wrist, initial encounter',
  'S63511D Sprain of carpal joint of right wrist, subsequent encounter',
  'S63511S Sprain of carpal joint of right wrist, sequela',
  'S63512A Sprain of carpal joint of left wrist, initial encounter',
  'S63512D Sprain of carpal joint of left wrist, subsequent encounter',
  'S63512S Sprain of carpal joint of left wrist, sequela',
  'S63519A Sprain of carpal joint of unspecified wrist, initial encounter',
  'S63519D Sprain of carpal joint of unspecified wrist, subsequent encounter',
  'S63519S Sprain of carpal joint of unspecified wrist, sequela',
  'S63521A Sprain of radiocarpal joint of right wrist, initial encounter',
  'S63521D Sprain of radiocarpal joint of right wrist, subsequent encounter',
  'S63521S Sprain of radiocarpal joint of right wrist, sequela',
  'S63522A Sprain of radiocarpal joint of left wrist, initial encounter',
  'S63522D Sprain of radiocarpal joint of left wrist, subsequent encounter',
  'S63522S Sprain of radiocarpal joint of left wrist, sequela',
  'S63529A Sprain of radiocarpal joint of unspecified wrist, initial encounter',
  'S63529D Sprain of radiocarpal joint of unspecified wrist, subsequent encounter',
  'S63529S Sprain of radiocarpal joint of unspecified wrist, sequela',
  'S63591A Other specified sprain of right wrist, initial encounter',
  'S63591D Other specified sprain of right wrist, subsequent encounter',
  'S63591S Other specified sprain of right wrist, sequela',
  'S63592A Other specified sprain of left wrist, initial encounter',
  'S63592D Other specified sprain of left wrist, subsequent encounter',
  'S63592S Other specified sprain of left wrist, sequela',
  'S63599A Other specified sprain of unspecified wrist, initial encounter',
  'S63599D Other specified sprain of unspecified wrist, subsequent encounter',
  'S63599S Other specified sprain of unspecified wrist, sequela',
  'S63601A Unspecified sprain of right thumb, initial encounter',
  'S63601D Unspecified sprain of right thumb, subsequent encounter',
  'S63601S Unspecified sprain of right thumb, sequela',
  'S63602A Unspecified sprain of left thumb, initial encounter',
  'S63602D Unspecified sprain of left thumb, subsequent encounter',
  'S63602S Unspecified sprain of left thumb, sequela',
  'S63609A Unspecified sprain of unspecified thumb, initial encounter',
  'S63609D Unspecified sprain of unspecified thumb, subsequent encounter',
  'S63609S Unspecified sprain of unspecified thumb, sequela',
  'S63610A Unspecified sprain of right index finger, initial encounter',
  'S63610D Unspecified sprain of right index finger, subsequent encounter',
  'S63610S Unspecified sprain of right index finger, sequela',
  'S63611A Unspecified sprain of left index finger, initial encounter',
  'S63611D Unspecified sprain of left index finger, subsequent encounter',
  'S63611S Unspecified sprain of left index finger, sequela',
  'S63612A Unspecified sprain of right middle finger, initial encounter',
  'S63612D Unspecified sprain of right middle finger, subsequent encounter',
  'S63612S Unspecified sprain of right middle finger, sequela',
  'S63613A Unspecified sprain of left middle finger, initial encounter',
  'S63613D Unspecified sprain of left middle finger, subsequent encounter',
  'S63613S Unspecified sprain of left middle finger, sequela',
  'S63614A Unspecified sprain of right ring finger, initial encounter',
  'S63614D Unspecified sprain of right ring finger, subsequent encounter',
  'S63614S Unspecified sprain of right ring finger, sequela',
  'S63615A Unspecified sprain of left ring finger, initial encounter',
  'S63615D Unspecified sprain of left ring finger, subsequent encounter',
  'S63615S Unspecified sprain of left ring finger, sequela',
  'S63616A Unspecified sprain of right little finger, initial encounter',
  'S63616D Unspecified sprain of right little finger, subsequent encounter',
  'S63616S Unspecified sprain of right little finger, sequela',
  'S63617A Unspecified sprain of left little finger, initial encounter',
  'S63617D Unspecified sprain of left little finger, subsequent encounter',
  'S63617S Unspecified sprain of left little finger, sequela',
  'S63618A Unspecified sprain of other finger, initial encounter',
  'S63618D Unspecified sprain of other finger, subsequent encounter',
  'S63618S Unspecified sprain of other finger, sequela',
  'S63619A Unspecified sprain of unspecified finger, initial encounter',
  'S63619D Unspecified sprain of unspecified finger, subsequent encounter',
  'S63619S Unspecified sprain of unspecified finger, sequela',
  'S63621A Sprain of interphalangeal joint of right thumb, initial encounter',
  'S63621D Sprain of interphalangeal joint of right thumb, subsequent encounter',
  'S63621S Sprain of interphalangeal joint of right thumb, sequela',
  'S63622A Sprain of interphalangeal joint of left thumb, initial encounter',
  'S63622D Sprain of interphalangeal joint of left thumb, subsequent encounter',
  'S63622S Sprain of interphalangeal joint of left thumb, sequela',
  'S63629A Sprain of interphalangeal joint of unspecified thumb, initial encounter',
  'S63629D Sprain of interphalangeal joint of unspecified thumb, subsequent encounter',
  'S63629S Sprain of interphalangeal joint of unspecified thumb, sequela',
  'S63630A Sprain of interphalangeal joint of right index finger, initial encounter',
  'S63630D Sprain of interphalangeal joint of right index finger, subsequent encounter',
  'S63630S Sprain of interphalangeal joint of right index finger, sequela',
  'S63631A Sprain of interphalangeal joint of left index finger, initial encounter',
  'S63631D Sprain of interphalangeal joint of left index finger, subsequent encounter',
  'S63631S Sprain of interphalangeal joint of left index finger, sequela',
  'S63632A Sprain of interphalangeal joint of right middle finger, initial encounter',
  'S63632D Sprain of interphalangeal joint of right middle finger, subsequent encounter',
  'S63632S Sprain of interphalangeal joint of right middle finger, sequela',
  'S63633A Sprain of interphalangeal joint of left middle finger, initial encounter',
  'S63633D Sprain of interphalangeal joint of left middle finger, subsequent encounter',
  'S63633S Sprain of interphalangeal joint of left middle finger, sequela',
  'S63634A Sprain of interphalangeal joint of right ring finger, initial encounter',
  'S63634D Sprain of interphalangeal joint of right ring finger, subsequent encounter',
  'S63634S Sprain of interphalangeal joint of right ring finger, sequela',
  'S63635A Sprain of interphalangeal joint of left ring finger, initial encounter',
  'S63635D Sprain of interphalangeal joint of left ring finger, subsequent encounter',
  'S63635S Sprain of interphalangeal joint of left ring finger, sequela',
  'S63636A Sprain of interphalangeal joint of right little finger, initial encounter',
  'S63636D Sprain of interphalangeal joint of right little finger, subsequent encounter',
  'S63636S Sprain of interphalangeal joint of right little finger, sequela',
  'S63637A Sprain of interphalangeal joint of left little finger, initial encounter',
  'S63637D Sprain of interphalangeal joint of left little finger, subsequent encounter',
  'S63637S Sprain of interphalangeal joint of left little finger, sequela',
  'S63638A Sprain of interphalangeal joint of other finger, initial encounter',
  'S63638D Sprain of interphalangeal joint of other finger, subsequent encounter',
  'S63638S Sprain of interphalangeal joint of other finger, sequela',
  'S63639A Sprain of interphalangeal joint of unspecified finger, initial encounter',
  'S63639D Sprain of interphalangeal joint of unspecified finger, subsequent encounter',
  'S63639S Sprain of interphalangeal joint of unspecified finger, sequela',
  'S63641A Sprain of metacarpophalangeal joint of right thumb, initial encounter',
  'S63641D Sprain of metacarpophalangeal joint of right thumb, subsequent encounter',
  'S63641S Sprain of metacarpophalangeal joint of right thumb, sequela',
  'S63642A Sprain of metacarpophalangeal joint of left thumb, initial encounter',
  'S63642D Sprain of metacarpophalangeal joint of left thumb, subsequent encounter',
  'S63642S Sprain of metacarpophalangeal joint of left thumb, sequela',
  'S63649A Sprain of metacarpophalangeal joint of unspecified thumb, initial encounter',
  'S63649D Sprain of metacarpophalangeal joint of unspecified thumb, subsequent encounter',
  'S63649S Sprain of metacarpophalangeal joint of unspecified thumb, sequela',
  'S63650A Sprain of metacarpophalangeal joint of right index finger, initial encounter',
  'S63650D Sprain of metacarpophalangeal joint of right index finger, subsequent encounter',
  'S63650S Sprain of metacarpophalangeal joint of right index finger, sequela',
  'S63651A Sprain of metacarpophalangeal joint of left index finger, initial encounter',
  'S63651D Sprain of metacarpophalangeal joint of left index finger, subsequent encounter',
  'S63651S Sprain of metacarpophalangeal joint of left index finger, sequela',
  'S63652A Sprain of metacarpophalangeal joint of right middle finger, initial encounter',
  'S63652D Sprain of metacarpophalangeal joint of right middle finger, subsequent encounter',
  'S63652S Sprain of metacarpophalangeal joint of right middle finger, sequela',
  'S63653A Sprain of metacarpophalangeal joint of left middle finger, initial encounter',
  'S63653D Sprain of metacarpophalangeal joint of left middle finger, subsequent encounter',
  'S63653S Sprain of metacarpophalangeal joint of left middle finger, sequela',
  'S63654A Sprain of metacarpophalangeal joint of right ring finger, initial encounter',
  'S63654D Sprain of metacarpophalangeal joint of right ring finger, subsequent encounter',
  'S63654S Sprain of metacarpophalangeal joint of right ring finger, sequela',
  'S63655A Sprain of metacarpophalangeal joint of left ring finger, initial encounter',
  'S63655D Sprain of metacarpophalangeal joint of left ring finger, subsequent encounter',
  'S63655S Sprain of metacarpophalangeal joint of left ring finger, sequela',
  'S63656A Sprain of metacarpophalangeal joint of right little finger, initial encounter',
  'S63656D Sprain of metacarpophalangeal joint of right little finger, subsequent encounter',
  'S63656S Sprain of metacarpophalangeal joint of right little finger, sequela',
  'S63657A Sprain of metacarpophalangeal joint of left little finger, initial encounter',
  'S63657D Sprain of metacarpophalangeal joint of left little finger, subsequent encounter',
  'S63657S Sprain of metacarpophalangeal joint of left little finger, sequela',
  'S63658A Sprain of metacarpophalangeal joint of other finger, initial encounter',
  'S63658D Sprain of metacarpophalangeal joint of other finger, subsequent encounter',
  'S63658S Sprain of metacarpophalangeal joint of other finger, sequela',
  'S63659A Sprain of metacarpophalangeal joint of unspecified finger, initial encounter',
  'S63659D Sprain of metacarpophalangeal joint of unspecified finger, subsequent encounter',
  'S63659S Sprain of metacarpophalangeal joint of unspecified finger, sequela',
  'S63681A Other sprain of right thumb, initial encounter',
  'S63681D Other sprain of right thumb, subsequent encounter',
  'S63681S Other sprain of right thumb, sequela',
  'S63682A Other sprain of left thumb, initial encounter',
  'S63682D Other sprain of left thumb, subsequent encounter',
  'S63682S Other sprain of left thumb, sequela',
  'S63689A Other sprain of unspecified thumb, initial encounter',
  'S63689D Other sprain of unspecified thumb, subsequent encounter',
  'S63689S Other sprain of unspecified thumb, sequela',
  'S63690A Other sprain of right index finger, initial encounter',
  'S63690D Other sprain of right index finger, subsequent encounter',
  'S63690S Other sprain of right index finger, sequela',
  'S63691A Other sprain of left index finger, initial encounter',
  'S63691D Other sprain of left index finger, subsequent encounter',
  'S63691S Other sprain of left index finger, sequela',
  'S63692A Other sprain of right middle finger, initial encounter',
  'S63692D Other sprain of right middle finger, subsequent encounter',
  'S63692S Other sprain of right middle finger, sequela',
  'S63693A Other sprain of left middle finger, initial encounter',
  'S63693D Other sprain of left middle finger, subsequent encounter',
  'S63693S Other sprain of left middle finger, sequela',
  'S63694A Other sprain of right ring finger, initial encounter',
  'S63694D Other sprain of right ring finger, subsequent encounter',
  'S63694S Other sprain of right ring finger, sequela',
  'S63695A Other sprain of left ring finger, initial encounter',
  'S63695D Other sprain of left ring finger, subsequent encounter',
  'S63695S Other sprain of left ring finger, sequela',
  'S63696A Other sprain of right little finger, initial encounter',
  'S63696D Other sprain of right little finger, subsequent encounter',
  'S63696S Other sprain of right little finger, sequela',
  'S63697A Other sprain of left little finger, initial encounter',
  'S63697D Other sprain of left little finger, subsequent encounter',
  'S63697S Other sprain of left little finger, sequela',
  'S63698A Other sprain of other finger, initial encounter',
  'S63698D Other sprain of other finger, subsequent encounter',
  'S63698S Other sprain of other finger, sequela',
  'S63699A Other sprain of unspecified finger, initial encounter',
  'S63699D Other sprain of unspecified finger, subsequent encounter',
  'S63699S Other sprain of unspecified finger, sequela',
  'S638X1A Sprain of other part of right wrist and hand, initial encounter',
  'S638X1D Sprain of other part of right wrist and hand, subsequent encounter',
  'S638X1S Sprain of other part of right wrist and hand, sequela',
  'S638X2A Sprain of other part of left wrist and hand, initial encounter',
  'S638X2D Sprain of other part of left wrist and hand, subsequent encounter',
  'S638X2S Sprain of other part of left wrist and hand, sequela',
  'S638X9A Sprain of other part of unspecified wrist and hand, initial encounter',
  'S638X9D Sprain of other part of unspecified wrist and hand, subsequent encounter',
  'S638X9S Sprain of other part of unspecified wrist and hand, sequela',
  'S6390XA Sprain of unspecified part of unspecified wrist and hand, initial encounter',
  'S6390XD Sprain of unspecified part of unspecified wrist and hand, subsequent encounter',
  'S6390XS Sprain of unspecified part of unspecified wrist and hand, sequela',
  'S6391XA Sprain of unspecified part of right wrist and hand, initial encounter',
  'S6391XD Sprain of unspecified part of right wrist and hand, subsequent encounter',
  'S6391XS Sprain of unspecified part of right wrist and hand, sequela',
  'S6392XA Sprain of unspecified part of left wrist and hand, initial encounter',
  'S6392XD Sprain of unspecified part of left wrist and hand, subsequent encounter',
  'S6392XS Sprain of unspecified part of left wrist and hand, sequela',
  'S6400XA Injury of ulnar nerve at wrist and hand level of unspecified arm, initial encounter',
  'S6400XD Injury of ulnar nerve at wrist and hand level of unspecified arm, subsequent encounter',
  'S6400XS Injury of ulnar nerve at wrist and hand level of unspecified arm, sequela',
  'S6401XA Injury of ulnar nerve at wrist and hand level of right arm, initial encounter',
  'S6401XD Injury of ulnar nerve at wrist and hand level of right arm, subsequent encounter',
  'S6401XS Injury of ulnar nerve at wrist and hand level of right arm, sequela',
  'S6402XA Injury of ulnar nerve at wrist and hand level of left arm, initial encounter',
  'S6402XD Injury of ulnar nerve at wrist and hand level of left arm, subsequent encounter',
  'S6402XS Injury of ulnar nerve at wrist and hand level of left arm, sequela',
  'S6410XA Injury of median nerve at wrist and hand level of unspecified arm, initial encounter',
  'S6410XD Injury of median nerve at wrist and hand level of unspecified arm, subsequent encounter',
  'S6410XS Injury of median nerve at wrist and hand level of unspecified arm, sequela',
  'S6411XA Injury of median nerve at wrist and hand level of right arm, initial encounter',
  'S6411XD Injury of median nerve at wrist and hand level of right arm, subsequent encounter',
  'S6411XS Injury of median nerve at wrist and hand level of right arm, sequela',
  'S6412XA Injury of median nerve at wrist and hand level of left arm, initial encounter',
  'S6412XD Injury of median nerve at wrist and hand level of left arm, subsequent encounter',
  'S6412XS Injury of median nerve at wrist and hand level of left arm, sequela',
  'S6420XA Injury of radial nerve at wrist and hand level of unspecified arm, initial encounter',
  'S6420XD Injury of radial nerve at wrist and hand level of unspecified arm, subsequent encounter',
  'S6420XS Injury of radial nerve at wrist and hand level of unspecified arm, sequela',
  'S6421XA Injury of radial nerve at wrist and hand level of right arm, initial encounter',
  'S6421XD Injury of radial nerve at wrist and hand level of right arm, subsequent encounter',
  'S6421XS Injury of radial nerve at wrist and hand level of right arm, sequela',
  'S6422XA Injury of radial nerve at wrist and hand level of left arm, initial encounter',
  'S6422XD Injury of radial nerve at wrist and hand level of left arm, subsequent encounter',
  'S6422XS Injury of radial nerve at wrist and hand level of left arm, sequela',
  'S6430XA Injury of digital nerve of unspecified thumb, initial encounter',
  'S6430XD Injury of digital nerve of unspecified thumb, subsequent encounter',
  'S6430XS Injury of digital nerve of unspecified thumb, sequela',
  'S6431XA Injury of digital nerve of right thumb, initial encounter',
  'S6431XD Injury of digital nerve of right thumb, subsequent encounter',
  'S6431XS Injury of digital nerve of right thumb, sequela',
  'S6432XA Injury of digital nerve of left thumb, initial encounter',
  'S6432XD Injury of digital nerve of left thumb, subsequent encounter',
  'S6432XS Injury of digital nerve of left thumb, sequela',
  'S6440XA Injury of digital nerve of unspecified finger, initial encounter',
  'S6440XD Injury of digital nerve of unspecified finger, subsequent encounter',
  'S6440XS Injury of digital nerve of unspecified finger, sequela',
  'S64490A Injury of digital nerve of right index finger, initial encounter',
  'S64490D Injury of digital nerve of right index finger, subsequent encounter',
  'S64490S Injury of digital nerve of right index finger, sequela',
  'S64491A Injury of digital nerve of left index finger, initial encounter',
  'S64491D Injury of digital nerve of left index finger, subsequent encounter',
  'S64491S Injury of digital nerve of left index finger, sequela',
  'S64492A Injury of digital nerve of right middle finger, initial encounter',
  'S64492D Injury of digital nerve of right middle finger, subsequent encounter',
  'S64492S Injury of digital nerve of right middle finger, sequela',
  'S64493A Injury of digital nerve of left middle finger, initial encounter',
  'S64493D Injury of digital nerve of left middle finger, subsequent encounter',
  'S64493S Injury of digital nerve of left middle finger, sequela',
  'S64494A Injury of digital nerve of right ring finger, initial encounter',
  'S64494D Injury of digital nerve of right ring finger, subsequent encounter',
  'S64494S Injury of digital nerve of right ring finger, sequela',
  'S64495A Injury of digital nerve of left ring finger, initial encounter',
  'S64495D Injury of digital nerve of left ring finger, subsequent encounter',
  'S64495S Injury of digital nerve of left ring finger, sequela',
  'S64496A Injury of digital nerve of right little finger, initial encounter',
  'S64496D Injury of digital nerve of right little finger, subsequent encounter',
  'S64496S Injury of digital nerve of right little finger, sequela',
  'S64497A Injury of digital nerve of left little finger, initial encounter',
  'S64497D Injury of digital nerve of left little finger, subsequent encounter',
  'S64497S Injury of digital nerve of left little finger, sequela',
  'S64498A Injury of digital nerve of other finger, initial encounter',
  'S64498D Injury of digital nerve of other finger, subsequent encounter',
  'S64498S Injury of digital nerve of other finger, sequela',
  'S648X1A Injury of other nerves at wrist and hand level of right arm, initial encounter',
  'S648X1D Injury of other nerves at wrist and hand level of right arm, subsequent encounter',
  'S648X1S Injury of other nerves at wrist and hand level of right arm, sequela',
  'S648X2A Injury of other nerves at wrist and hand level of left arm, initial encounter',
  'S648X2D Injury of other nerves at wrist and hand level of left arm, subsequent encounter',
  'S648X2S Injury of other nerves at wrist and hand level of left arm, sequela',
  'S648X9A Injury of other nerves at wrist and hand level of unspecified arm, initial encounter',
  'S648X9D Injury of other nerves at wrist and hand level of unspecified arm, subsequent encounter',
  'S648X9S Injury of other nerves at wrist and hand level of unspecified arm, sequela',
  'S6490XA Injury of unspecified nerve at wrist and hand level of unspecified arm, initial encounter',
  'S6490XD Injury of unspecified nerve at wrist and hand level of unspecified arm, subsequent encounter',
  'S6490XS Injury of unspecified nerve at wrist and hand level of unspecified arm, sequela',
  'S6491XA Injury of unspecified nerve at wrist and hand level of right arm, initial encounter',
  'S6491XD Injury of unspecified nerve at wrist and hand level of right arm, subsequent encounter',
  'S6491XS Injury of unspecified nerve at wrist and hand level of right arm, sequela',
  'S6492XA Injury of unspecified nerve at wrist and hand level of left arm, initial encounter',
  'S6492XD Injury of unspecified nerve at wrist and hand level of left arm, subsequent encounter',
  'S6492XS Injury of unspecified nerve at wrist and hand level of left arm, sequela',
  'S65001A Unspecified injury of ulnar artery at wrist and hand level of right arm, initial encounter',
  'S65001D Unspecified injury of ulnar artery at wrist and hand level of right arm, subsequent encounter',
  'S65001S Unspecified injury of ulnar artery at wrist and hand level of right arm, sequela',
  'S65002A Unspecified injury of ulnar artery at wrist and hand level of left arm, initial encounter',
  'S65002D Unspecified injury of ulnar artery at wrist and hand level of left arm, subsequent encounter',
  'S65002S Unspecified injury of ulnar artery at wrist and hand level of left arm, sequela',
  'S65009A Unspecified injury of ulnar artery at wrist and hand level of unspecified arm, initial encounter',
  'S65009D Unspecified injury of ulnar artery at wrist and hand level of unspecified arm, subsequent',
  'encounter',
  'S65009S Unspecified injury of ulnar artery at wrist and hand level of unspecified arm, sequela',
  'S65011A Laceration of ulnar artery at wrist and hand level of right arm, initial encounter',
  'S65011D Laceration of ulnar artery at wrist and hand level of right arm, subsequent encounter',
  'S65011S Laceration of ulnar artery at wrist and hand level of right arm, sequela',
  'S65012A Laceration of ulnar artery at wrist and hand level of left arm, initial encounter',
  'S65012D Laceration of ulnar artery at wrist and hand level of left arm, subsequent encounter',
  'S65012S Laceration of ulnar artery at wrist and hand level of left arm, sequela',
  'S65019A Laceration of ulnar artery at wrist and hand level of unspecified arm, initial encounter',
  'S65019D Laceration of ulnar artery at wrist and hand level of unspecified arm, subsequent encounter',
  'S65019S Laceration of ulnar artery at wrist and hand level of unspecified arm, sequela',
  'S65091A Other specified injury of ulnar artery at wrist and hand level of right arm, initial encounter',
  'S65091D Other specified injury of ulnar artery at wrist and hand level of right arm, subsequent',
  'encounter',
  'S65091S Other specified injury of ulnar artery at wrist and hand level of right arm, sequela',
  'S65092A Other specified injury of ulnar artery at wrist and hand level of left arm, initial encounter',
  'S65092D Other specified injury of ulnar artery at wrist and hand level of left arm, subsequent encounter',
  'S65092S Other specified injury of ulnar artery at wrist and hand level of left arm, sequela',
  'S65099A Other specified injury of ulnar artery at wrist and hand level of unspecified arm, initial',
  'encounter',
  'S65099D Other specified injury of ulnar artery at wrist and hand level of unspecified arm, subsequent',
  'encounter',
  'S65099S Other specified injury of ulnar artery at wrist and hand level of unspecified arm, sequela',
  'S65101A Unspecified injury of radial artery at wrist and hand level of right arm, initial encounter',
  'S65101D Unspecified injury of radial artery at wrist and hand level of right arm, subsequent encounter',
  'S65101S Unspecified injury of radial artery at wrist and hand level of right arm, sequela',
  'S65102A Unspecified injury of radial artery at wrist and hand level of left arm, initial encounter',
  'S65102D Unspecified injury of radial artery at wrist and hand level of left arm, subsequent encounter',
  'S65102S Unspecified injury of radial artery at wrist and hand level of left arm, sequela',
  'S65109A Unspecified injury of radial artery at wrist and hand level of unspecified arm, initial encounter',
  'S65109D Unspecified injury of radial artery at wrist and hand level of unspecified arm, subsequent',
  'encounter',
  'S65109S Unspecified injury of radial artery at wrist and hand level of unspecified arm, sequela',
  'S65111A Laceration of radial artery at wrist and hand level of right arm, initial encounter',
  'S65111D Laceration of radial artery at wrist and hand level of right arm, subsequent encounter',
  'S65111S Laceration of radial artery at wrist and hand level of right arm, sequela',
  'S65112A Laceration of radial artery at wrist and hand level of left arm, initial encounter',
  'S65112D Laceration of radial artery at wrist and hand level of left arm, subsequent encounter',
  'S65112S Laceration of radial artery at wrist and hand level of left arm, sequela',
  'S65119A Laceration of radial artery at wrist and hand level of unspecified arm, initial encounter',
  'S65119D Laceration of radial artery at wrist and hand level of unspecified arm, subsequent encounter',
  'S65119S Laceration of radial artery at wrist and hand level of unspecified arm, sequela',
  'S65191A Other specified injury of radial artery at wrist and hand level of right arm, initial encounter',
  'S65191D Other specified injury of radial artery at wrist and hand level of right arm, subsequent',
  'encounter',
  'S65191S Other specified injury of radial artery at wrist and hand level of right arm, sequela',
  'S65192A Other specified injury of radial artery at wrist and hand level of left arm, initial encounter',
  'S65192D Other specified injury of radial artery at wrist and hand level of left arm, subsequent encounter',
  'S65192S Other specified injury of radial artery at wrist and hand level of left arm, sequela',
  'S65199A Other specified injury of radial artery at wrist and hand level of unspecified arm, initial',
  'encounter',
  'S65199D Other specified injury of radial artery at wrist and hand level of unspecified arm, subsequent',
  'encounter',
  'S65199S Other specified injury of radial artery at wrist and hand level of unspecified arm, sequela',
  'S65201A Unspecified injury of superficial palmar arch of right hand, initial encounter',
  'S65201D Unspecified injury of superficial palmar arch of right hand, subsequent encounter',
  'S65201S Unspecified injury of superficial palmar arch of right hand, sequela',
  'S65202A Unspecified injury of superficial palmar arch of left hand, initial encounter',
  'S65202D Unspecified injury of superficial palmar arch of left hand, subsequent encounter',
  'S65202S Unspecified injury of superficial palmar arch of left hand, sequela',
  'S65209A Unspecified injury of superficial palmar arch of unspecified hand, initial encounter',
  'S65209D Unspecified injury of superficial palmar arch of unspecified hand, subsequent encounter',
  'S65209S Unspecified injury of superficial palmar arch of unspecified hand, sequela',
  'S65211A Laceration of superficial palmar arch of right hand, initial encounter',
  'S65211D Laceration of superficial palmar arch of right hand, subsequent encounter',
  'S65211S Laceration of superficial palmar arch of right hand, sequela',
  'S65212A Laceration of superficial palmar arch of left hand, initial encounter',
  'S65212D Laceration of superficial palmar arch of left hand, subsequent encounter',
  'S65212S Laceration of superficial palmar arch of left hand, sequela',
  'S65219A Laceration of superficial palmar arch of unspecified hand, initial encounter',
  'S65219D Laceration of superficial palmar arch of unspecified hand, subsequent encounter',
  'S65219S Laceration of superficial palmar arch of unspecified hand, sequela',
  'S65291A Other specified injury of superficial palmar arch of right hand, initial encounter',
  'S65291D Other specified injury of superficial palmar arch of right hand, subsequent encounter',
  'S65291S Other specified injury of superficial palmar arch of right hand, sequela',
  'S65292A Other specified injury of superficial palmar arch of left hand, initial encounter',
  'S65292D Other specified injury of superficial palmar arch of left hand, subsequent encounter',
  'S65292S Other specified injury of superficial palmar arch of left hand, sequela',
  'S65299A Other specified injury of superficial palmar arch of unspecified hand, initial encounter',
  'S65299D Other specified injury of superficial palmar arch of unspecified hand, subsequent encounter',
  'S65299S Other specified injury of superficial palmar arch of unspecified hand, sequela',
  'S65301A Unspecified injury of deep palmar arch of right hand, initial encounter',
  'S65301D Unspecified injury of deep palmar arch of right hand, subsequent encounter',
  'S65301S Unspecified injury of deep palmar arch of right hand, sequela',
  'S65302A Unspecified injury of deep palmar arch of left hand, initial encounter',
  'S65302D Unspecified injury of deep palmar arch of left hand, subsequent encounter',
  'S65302S Unspecified injury of deep palmar arch of left hand, sequela',
  'S65309A Unspecified injury of deep palmar arch of unspecified hand, initial encounter',
  'S65309D Unspecified injury of deep palmar arch of unspecified hand, subsequent encounter',
  'S65309S Unspecified injury of deep palmar arch of unspecified hand, sequela',
  'S65311A Laceration of deep palmar arch of right hand, initial encounter',
  'S65311D Laceration of deep palmar arch of right hand, subsequent encounter',
  'S65311S Laceration of deep palmar arch of right hand, sequela',
  'S65312A Laceration of deep palmar arch of left hand, initial encounter',
  'S65312D Laceration of deep palmar arch of left hand, subsequent encounter',
  'S65312S Laceration of deep palmar arch of left hand, sequela',
  'S65319A Laceration of deep palmar arch of unspecified hand, initial encounter',
  'S65319D Laceration of deep palmar arch of unspecified hand, subsequent encounter',
  'S65319S Laceration of deep palmar arch of unspecified hand, sequela',
  'S65391A Other specified injury of deep palmar arch of right hand, initial encounter',
  'S65391D Other specified injury of deep palmar arch of right hand, subsequent encounter',
  'S65391S Other specified injury of deep palmar arch of right hand, sequela',
  'S65392A Other specified injury of deep palmar arch of left hand, initial encounter',
  'S65392D Other specified injury of deep palmar arch of left hand, subsequent encounter',
  'S65392S Other specified injury of deep palmar arch of left hand, sequela',
  'S65399A Other specified injury of deep palmar arch of unspecified hand, initial encounter',
  'S65399D Other specified injury of deep palmar arch of unspecified hand, subsequent encounter',
  'S65399S Other specified injury of deep palmar arch of unspecified hand, sequela',
  'S65401A Unspecified injury of blood vessel of right thumb, initial encounter',
  'S65401D Unspecified injury of blood vessel of right thumb, subsequent encounter',
  'S65401S Unspecified injury of blood vessel of right thumb, sequela',
  'S65402A Unspecified injury of blood vessel of left thumb, initial encounter',
  'S65402D Unspecified injury of blood vessel of left thumb, subsequent encounter',
  'S65402S Unspecified injury of blood vessel of left thumb, sequela',
  'S65409A Unspecified injury of blood vessel of unspecified thumb, initial encounter',
  'S65409D Unspecified injury of blood vessel of unspecified thumb, subsequent encounter',
  'S65409S Unspecified injury of blood vessel of unspecified thumb, sequela',
  'S65411A Laceration of blood vessel of right thumb, initial encounter',
  'S65411D Laceration of blood vessel of right thumb, subsequent encounter',
  'S65411S Laceration of blood vessel of right thumb, sequela',
  'S65412A Laceration of blood vessel of left thumb, initial encounter',
  'S65412D Laceration of blood vessel of left thumb, subsequent encounter',
  'S65412S Laceration of blood vessel of left thumb, sequela',
  'S65419A Laceration of blood vessel of unspecified thumb, initial encounter',
  'S65419D Laceration of blood vessel of unspecified thumb, subsequent encounter',
  'S65419S Laceration of blood vessel of unspecified thumb, sequela',
  'S65491A Other specified injury of blood vessel of right thumb, initial encounter',
  'S65491D Other specified injury of blood vessel of right thumb, subsequent encounter',
  'S65491S Other specified injury of blood vessel of right thumb, sequela',
  'S65492A Other specified injury of blood vessel of left thumb, initial encounter',
  'S65492D Other specified injury of blood vessel of left thumb, subsequent encounter',
  'S65492S Other specified injury of blood vessel of left thumb, sequela',
  'S65499A Other specified injury of blood vessel of unspecified thumb, initial encounter',
  'S65499D Other specified injury of blood vessel of unspecified thumb, subsequent encounter',
  'S65499S Other specified injury of blood vessel of unspecified thumb, sequela',
  'S65500A Unspecified injury of blood vessel of right index finger, initial encounter',
  'S65500D Unspecified injury of blood vessel of right index finger, subsequent encounter',
  'S65500S Unspecified injury of blood vessel of right index finger, sequela',
  'S65501A Unspecified injury of blood vessel of left index finger, initial encounter',
  'S65501D Unspecified injury of blood vessel of left index finger, subsequent encounter',
  'S65501S Unspecified injury of blood vessel of left index finger, sequela',
  'S65502A Unspecified injury of blood vessel of right middle finger, initial encounter',
  'S65502D Unspecified injury of blood vessel of right middle finger, subsequent encounter',
  'S65502S Unspecified injury of blood vessel of right middle finger, sequela',
  'S65503A Unspecified injury of blood vessel of left middle finger, initial encounter',
  'S65503D Unspecified injury of blood vessel of left middle finger, subsequent encounter',
  'S65503S Unspecified injury of blood vessel of left middle finger, sequela',
  'S65504A Unspecified injury of blood vessel of right ring finger, initial encounter',
  'S65504D Unspecified injury of blood vessel of right ring finger, subsequent encounter',
  'S65504S Unspecified injury of blood vessel of right ring finger, sequela',
  'S65505A Unspecified injury of blood vessel of left ring finger, initial encounter',
  'S65505D Unspecified injury of blood vessel of left ring finger, subsequent encounter',
  'S65505S Unspecified injury of blood vessel of left ring finger, sequela',
  'S65506A Unspecified injury of blood vessel of right little finger, initial encounter',
  'S65506D Unspecified injury of blood vessel of right little finger, subsequent encounter',
  'S65506S Unspecified injury of blood vessel of right little finger, sequela',
  'S65507A Unspecified injury of blood vessel of left little finger, initial encounter',
  'S65507D Unspecified injury of blood vessel of left little finger, subsequent encounter',
  'S65507S Unspecified injury of blood vessel of left little finger, sequela',
  'S65508A Unspecified injury of blood vessel of other finger, initial encounter',
  'S65508D Unspecified injury of blood vessel of other finger, subsequent encounter',
  'S65508S Unspecified injury of blood vessel of other finger, sequela',
  'S65509A Unspecified injury of blood vessel of unspecified finger, initial encounter',
  'S65509D Unspecified injury of blood vessel of unspecified finger, subsequent encounter',
  'S65509S Unspecified injury of blood vessel of unspecified finger, sequela',
  'S65510A Laceration of blood vessel of right index finger, initial encounter',
  'S65510D Laceration of blood vessel of right index finger, subsequent encounter',
  'S65510S Laceration of blood vessel of right index finger, sequela',
  'S65511A Laceration of blood vessel of left index finger, initial encounter',
  'S65511D Laceration of blood vessel of left index finger, subsequent encounter',
  'S65511S Laceration of blood vessel of left index finger, sequela',
  'S65512A Laceration of blood vessel of right middle finger, initial encounter',
  'S65512D Laceration of blood vessel of right middle finger, subsequent encounter',
  'S65512S Laceration of blood vessel of right middle finger, sequela',
  'S65513A Laceration of blood vessel of left middle finger, initial encounter',
  'S65513D Laceration of blood vessel of left middle finger, subsequent encounter',
  'S65513S Laceration of blood vessel of left middle finger, sequela',
  'S65514A Laceration of blood vessel of right ring finger, initial encounter',
  'S65514D Laceration of blood vessel of right ring finger, subsequent encounter',
  'S65514S Laceration of blood vessel of right ring finger, sequela',
  'S65515A Laceration of blood vessel of left ring finger, initial encounter',
  'S65515D Laceration of blood vessel of left ring finger, subsequent encounter',
  'S65515S Laceration of blood vessel of left ring finger, sequela',
  'S65516A Laceration of blood vessel of right little finger, initial encounter',
  'S65516D Laceration of blood vessel of right little finger, subsequent encounter',
  'S65516S Laceration of blood vessel of right little finger, sequela',
  'S65517A Laceration of blood vessel of left little finger, initial encounter',
  'S65517D Laceration of blood vessel of left little finger, subsequent encounter',
  'S65517S Laceration of blood vessel of left little finger, sequela',
  'S65518A Laceration of blood vessel of other finger, initial encounter',
  'S65518D Laceration of blood vessel of other finger, subsequent encounter',
  'S65518S Laceration of blood vessel of other finger, sequela',
  'S65519A Laceration of blood vessel of unspecified finger, initial encounter',
  'S65519D Laceration of blood vessel of unspecified finger, subsequent encounter',
  'S65519S Laceration of blood vessel of unspecified finger, sequela',
  'S65590A Other specified injury of blood vessel of right index finger, initial encounter',
  'S65590D Other specified injury of blood vessel of right index finger, subsequent encounter',
  'S65590S Other specified injury of blood vessel of right index finger, sequela',
  'S65591A Other specified injury of blood vessel of left index finger, initial encounter',
  'S65591D Other specified injury of blood vessel of left index finger, subsequent encounter',
  'S65591S Other specified injury of blood vessel of left index finger, sequela',
  'S65592A Other specified injury of blood vessel of right middle finger, initial encounter',
  'S65592D Other specified injury of blood vessel of right middle finger, subsequent encounter',
  'S65592S Other specified injury of blood vessel of right middle finger, sequela',
  'S65593A Other specified injury of blood vessel of left middle finger, initial encounter',
  'S65593D Other specified injury of blood vessel of left middle finger, subsequent encounter',
  'S65593S Other specified injury of blood vessel of left middle finger, sequela',
  'S65594A Other specified injury of blood vessel of right ring finger, initial encounter',
  'S65594D Other specified injury of blood vessel of right ring finger, subsequent encounter',
  'S65594S Other specified injury of blood vessel of right ring finger, sequela',
  'S65595A Other specified injury of blood vessel of left ring finger, initial encounter',
  'S65595D Other specified injury of blood vessel of left ring finger, subsequent encounter',
  'S65595S Other specified injury of blood vessel of left ring finger, sequela',
  'S65596A Other specified injury of blood vessel of right little finger, initial encounter',
  'S65596D Other specified injury of blood vessel of right little finger, subsequent encounter',
  'S65596S Other specified injury of blood vessel of right little finger, sequela',
  'S65597A Other specified injury of blood vessel of left little finger, initial encounter',
  'S65597D Other specified injury of blood vessel of left little finger, subsequent encounter',
  'S65597S Other specified injury of blood vessel of left little finger, sequela',
  'S65598A Other specified injury of blood vessel of other finger, initial encounter',
  'S65598D Other specified injury of blood vessel of other finger, subsequent encounter',
  'S65598S Other specified injury of blood vessel of other finger, sequela',
  'S65599A Other specified injury of blood vessel of unspecified finger, initial encounter',
  'S65599D Other specified injury of blood vessel of unspecified finger, subsequent encounter',
  'S65599S Other specified injury of blood vessel of unspecified finger, sequela',
  'S65801A Unspecified injury of other blood vessels at wrist and hand level of right arm, initial encounter',
  'S65801D Unspecified injury of other blood vessels at wrist and hand level of right arm, subsequent',
  'encounter',
  'S65801S Unspecified injury of other blood vessels at wrist and hand level of right arm, sequela',
  'S65802A Unspecified injury of other blood vessels at wrist and hand level of left arm, initial encounter',
  'S65802D Unspecified injury of other blood vessels at wrist and hand level of left arm, subsequent',
  'encounter',
  'S65802S Unspecified injury of other blood vessels at wrist and hand level of left arm, sequela',
  'S65809A Unspecified injury of other blood vessels at wrist and hand level of unspecified arm, initial',
  'encounter',
  'S65809D Unspecified injury of other blood vessels at wrist and hand level of unspecified arm,',
  'subsequent encounter',
  'S65809S Unspecified injury of other blood vessels at wrist and hand level of unspecified arm, sequela',
  'S65811A Laceration of other blood vessels at wrist and hand level of right arm, initial encounter',
  'S65811D Laceration of other blood vessels at wrist and hand level of right arm, subsequent encounter',
  'S65811S Laceration of other blood vessels at wrist and hand level of right arm, sequela',
  'S65812A Laceration of other blood vessels at wrist and hand level of left arm, initial encounter',
  'S65812D Laceration of other blood vessels at wrist and hand level of left arm, subsequent encounter',
  'S65812S Laceration of other blood vessels at wrist and hand level of left arm, sequela',
  'S65819A Laceration of other blood vessels at wrist and hand level of unspecified arm, initial encounter',
  'S65819D Laceration of other blood vessels at wrist and hand level of unspecified arm, subsequent',
  'encounter',
  'S65819S Laceration of other blood vessels at wrist and hand level of unspecified arm, sequela',
  'S65891A Other specified injury of other blood vessels at wrist and hand level of right arm, initial',
  'encounter',
  'S65891D Other specified injury of other blood vessels at wrist and hand level of right arm, subsequent',
  'encounter',
  'S65891S Other specified injury of other blood vessels at wrist and hand level of right arm, sequela',
  'S65892A Other specified injury of other blood vessels at wrist and hand level of left arm, initial',
  'encounter',
  'S65892D Other specified injury of other blood vessels at wrist and hand level of left arm, subsequent',
  'encounter',
  'S65892S Other specified injury of other blood vessels at wrist and hand level of left arm, sequela',
  'S65899A Other specified injury of other blood vessels at wrist and hand level of unspecified arm, initial',
  'encounter',
  'S65899D Other specified injury of other blood vessels at wrist and hand level of unspecified arm,',
  'subsequent encounter',
  'S65899S Other specified injury of other blood vessels at wrist and hand level of unspecified arm, sequela',
  'S65901A Unspecified injury of unspecified blood vessel at wrist and hand level of right arm, initial',
  'encounter',
  'S65901D Unspecified injury of unspecified blood vessel at wrist and hand level of right arm, subsequent',
  'encounter',
  'S65901S Unspecified injury of unspecified blood vessel at wrist and hand level of right arm, sequela',
  'S65902A Unspecified injury of unspecified blood vessel at wrist and hand level of left arm, initial',
  'encounter',
  'S65902D Unspecified injury of unspecified blood vessel at wrist and hand level of left arm, subsequent',
  'encounter',
  'S65902S Unspecified injury of unspecified blood vessel at wrist and hand level of left arm, sequela',
  'S65909A Unspecified injury of unspecified blood vessel at wrist and hand level of unspecified arm, initial',
  'encounter',
  'S65909D Unspecified injury of unspecified blood vessel at wrist and hand level of unspecified arm,',
  'subsequent encounter',
  'S65909S Unspecified injury of unspecified blood vessel at wrist and hand level of unspecified arm,',
  'sequela',
  'S65911A Laceration of unspecified blood vessel at wrist and hand level of right arm, initial encounter',
  'S65911D Laceration of unspecified blood vessel at wrist and hand level of right arm, subsequent',
  'encounter',
  'S65911S Laceration of unspecified blood vessel at wrist and hand level of right arm, sequela',
  'S65912A Laceration of unspecified blood vessel at wrist and hand level of left arm, initial encounter',
  'S65912D Laceration of unspecified blood vessel at wrist and hand level of left arm, subsequent',
  'encounter',
  'S65912S Laceration of unspecified blood vessel at wrist and hand level of left arm, sequela',
  'S65919A Laceration of unspecified blood vessel at wrist and hand level of unspecified arm, initial',
  'encounter',
  'S65919D Laceration of unspecified blood vessel at wrist and hand level of unspecified arm, subsequent',
  'encounter',
  'S65919S Laceration of unspecified blood vessel at wrist and hand level of unspecified arm, sequela',
  'S65991A Other specified injury of unspecified blood vessel at wrist and hand of right arm, initial',
  'encounter',
  'S65991D Other specified injury of unspecified blood vessel at wrist and hand of right arm, subsequent',
  'encounter',
  'S65991S Other specified injury of unspecified blood vessel at wrist and hand of right arm, sequela',
  'S65992A Other specified injury of unspecified blood vessel at wrist and hand of left arm, initial',
  'encounter',
  'S65992D Other specified injury of unspecified blood vessel at wrist and hand of left arm, subsequent',
  'encounter',
  'S65992S Other specified injury of unspecified blood vessel at wrist and hand of left arm, sequela',
  'S65999A Other specified injury of unspecified blood vessel at wrist and hand of unspecified arm, initial',
  'encounter',
  'S65999D Other specified injury of unspecified blood vessel at wrist and hand of unspecified arm,',
  'subsequent encounter',
  'S65999S Other specified injury of unspecified blood vessel at wrist and hand of unspecified arm, sequela',
  'S66001A Unspecified injury of long flexor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, initial encounter',
  'S66001D Unspecified injury of long flexor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, subsequent encounter',
  'S66001S Unspecified injury of long flexor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, sequela',
  'S66002A Unspecified injury of long flexor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, initial encounter',
  'S66002D Unspecified injury of long flexor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, subsequent encounter',
  'S66002S Unspecified injury of long flexor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, sequela',
  'S66009A Unspecified injury of long flexor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, initial encounter',
  'S66009D Unspecified injury of long flexor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, subsequent encounter',
  'S66009S Unspecified injury of long flexor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, sequela',
  'S66011A Strain of long flexor muscle, fascia and tendon of right thumb at wrist and hand level, initial',
  'encounter',
  'S66011D Strain of long flexor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'subsequent encounter',
  'S66011S Strain of long flexor muscle, fascia and tendon of right thumb at wrist and hand level, sequela',
  'S66012A Strain of long flexor muscle, fascia and tendon of left thumb at wrist and hand level, initial',
  'encounter',
  'S66012D Strain of long flexor muscle, fascia and tendon of left thumb at wrist and hand level,',
  'subsequent encounter',
  'S66012S Strain of long flexor muscle, fascia and tendon of left thumb at wrist and hand level, sequela',
  'S66019A Strain of long flexor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'initial encounter',
  'S66019D Strain of long flexor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'subsequent encounter',
  'S66019S Strain of long flexor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'sequela',
  'S66021A Laceration of long flexor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'initial encounter',
  'S66021D Laceration of long flexor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'subsequent encounter',
  'S66021S Laceration of long flexor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'sequela',
  'S66022A Laceration of long flexor muscle, fascia and tendon of left thumb at wrist and hand level, initial',
  'encounter',
  'S66022D Laceration of long flexor muscle, fascia and tendon of left thumb at wrist and hand level,',
  'subsequent encounter',
  'S66022S Laceration of long flexor muscle, fascia and tendon of left thumb at wrist and hand level,',
  'sequela',
  'S66029A Laceration of long flexor muscle, fascia and tendon of unspecified thumb at wrist and hand',
  'level, initial encounter',
  'S66029D Laceration of long flexor muscle, fascia and tendon of unspecified thumb at wrist and hand',
  'level, subsequent encounter',
  'S66029S Laceration of long flexor muscle, fascia and tendon of unspecified thumb at wrist and hand',
  'level, sequela',
  'S66091A Other specified injury of long flexor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, initial encounter',
  'S66091D Other specified injury of long flexor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, subsequent encounter',
  'S66091S Other specified injury of long flexor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, sequela',
  'S66092A Other specified injury of long flexor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, initial encounter',
  'S66092D Other specified injury of long flexor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, subsequent encounter',
  'S66092S Other specified injury of long flexor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, sequela',
  'S66099A Other specified injury of long flexor muscle, fascia and tendon of unspecified thumb at wrist',
  'and hand level, initial encounter',
  'S66099D Other specified injury of long flexor muscle, fascia and tendon of unspecified thumb at wrist',
  'and hand level, subsequent encounter',
  'S66099S Other specified injury of long flexor muscle, fascia and tendon of unspecified thumb at wrist',
  'and hand level, sequela',
  'S66100A Unspecified injury of flexor muscle, fascia and tendon of right index finger at wrist and hand',
  'level, initial encounter',
  'S66100D Unspecified injury of flexor muscle, fascia and tendon of right index finger at wrist and hand',
  'level, subsequent encounter',
  'S66100S Unspecified injury of flexor muscle, fascia and tendon of right index finger at wrist and hand',
  'level, sequela',
  'S66101A Unspecified injury of flexor muscle, fascia and tendon of left index finger at wrist and hand',
  'level, initial encounter',
  'S66101D Unspecified injury of flexor muscle, fascia and tendon of left index finger at wrist and hand',
  'level, subsequent encounter',
  'S66101S Unspecified injury of flexor muscle, fascia and tendon of left index finger at wrist and hand',
  'level, sequela',
  'S66102A Unspecified injury of flexor muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, initial encounter',
  'S66102D Unspecified injury of flexor muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, subsequent encounter',
  'S66102S Unspecified injury of flexor muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, sequela',
  'S66103A Unspecified injury of flexor muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, initial encounter',
  'S66103D Unspecified injury of flexor muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, subsequent encounter',
  'S66103S Unspecified injury of flexor muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, sequela',
  'S66104A Unspecified injury of flexor muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, initial encounter',
  'S66104D Unspecified injury of flexor muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, subsequent encounter',
  'S66104S Unspecified injury of flexor muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, sequela',
  'S66105A Unspecified injury of flexor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'initial encounter',
  'S66105D Unspecified injury of flexor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66105S Unspecified injury of flexor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'sequela',
  'S66106A Unspecified injury of flexor muscle, fascia and tendon of right little finger at wrist and hand',
  'level, initial encounter',
  'S66106D Unspecified injury of flexor muscle, fascia and tendon of right little finger at wrist and hand',
  'level, subsequent encounter',
  'S66106S Unspecified injury of flexor muscle, fascia and tendon of right little finger at wrist and hand',
  'level, sequela',
  'S66107A Unspecified injury of flexor muscle, fascia and tendon of left little finger at wrist and hand',
  'level, initial encounter',
  'S66107D Unspecified injury of flexor muscle, fascia and tendon of left little finger at wrist and hand',
  'level, subsequent encounter',
  'S66107S Unspecified injury of flexor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'sequela',
  'S66108A Unspecified injury of flexor muscle, fascia and tendon of other finger at wrist and hand level,',
  'initial encounter',
  'S66108D Unspecified injury of flexor muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66108S Unspecified injury of flexor muscle, fascia and tendon of other finger at wrist and hand level,',
  'sequela',
  'S66109A Unspecified injury of flexor muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, initial encounter',
  'S66109D Unspecified injury of flexor muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, subsequent encounter',
  'S66109S Unspecified injury of flexor muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, sequela',
  'S66110A Strain of flexor muscle, fascia and tendon of right index finger at wrist and hand level, initial',
  'encounter',
  'S66110D Strain of flexor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66110S Strain of flexor muscle, fascia and tendon of right index finger at wrist and hand level, sequela',
  'S66111A Strain of flexor muscle, fascia and tendon of left index finger at wrist and hand level, initial',
  'encounter',
  'S66111D Strain of flexor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66111S Strain of flexor muscle, fascia and tendon of left index finger at wrist and hand level, sequela',
  'S66112A Strain of flexor muscle, fascia and tendon of right middle finger at wrist and hand level, initial',
  'encounter',
  'S66112D Strain of flexor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66112S Strain of flexor muscle, fascia and tendon of right middle finger at wrist and hand level, sequela',
  'S66113A Strain of flexor muscle, fascia and tendon of left middle finger at wrist and hand level, initial',
  'encounter',
  'S66113D Strain of flexor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66113S Strain of flexor muscle, fascia and tendon of left middle finger at wrist and hand level, sequela',
  'S66114A Strain of flexor muscle, fascia and tendon of right ring finger at wrist and hand level, initial',
  'encounter',
  'S66114D Strain of flexor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66114S Strain of flexor muscle, fascia and tendon of right ring finger at wrist and hand level, sequela',
  'S66115A Strain of flexor muscle, fascia and tendon of left ring finger at wrist and hand level, initial',
  'encounter',
  'S66115D Strain of flexor muscle, fascia and tendon of left ring finger at wrist and hand level, subsequent',
  'encounter',
  'S66115S Strain of flexor muscle, fascia and tendon of left ring finger at wrist and hand level, sequela',
  'S66116A Strain of flexor muscle, fascia and tendon of right little finger at wrist and hand level, initial',
  'encounter',
  'S66116D Strain of flexor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66116S Strain of flexor muscle, fascia and tendon of right little finger at wrist and hand level, sequela',
  'S66117A Strain of flexor muscle, fascia and tendon of left little finger at wrist and hand level, initial',
  'encounter',
  'S66117D Strain of flexor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66117S Strain of flexor muscle, fascia and tendon of left little finger at wrist and hand level, sequela',
  'S66118A Strain of flexor muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66118D Strain of flexor muscle, fascia and tendon of other finger at wrist and hand level, subsequent',
  'encounter',
  'S66118S Strain of flexor muscle, fascia and tendon of other finger at wrist and hand level, sequela',
  'S66119A Strain of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level, initial',
  'encounter',
  'S66119D Strain of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66119S Strain of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level, sequela',
  'S66120A Laceration of flexor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'initial encounter',
  'S66120D Laceration of flexor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66120S Laceration of flexor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66121A Laceration of flexor muscle, fascia and tendon of left index finger at wrist and hand level, initial',
  'encounter',
  'S66121D Laceration of flexor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66121S Laceration of flexor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'sequela',
  'S66122A Laceration of flexor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'initial encounter',
  'S66122D Laceration of flexor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66122S Laceration of flexor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'sequela',
  'S66123A Laceration of flexor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'initial encounter',
  'S66123D Laceration of flexor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66123S Laceration of flexor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66124A Laceration of flexor muscle, fascia and tendon of right ring finger at wrist and hand level, initial',
  'encounter',
  'S66124D Laceration of flexor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66124S Laceration of flexor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'sequela',
  'S66125A Laceration of flexor muscle, fascia and tendon of left ring finger at wrist and hand level, initial',
  'encounter',
  'S66125D Laceration of flexor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66125S Laceration of flexor muscle, fascia and tendon of left ring finger at wrist and hand level, sequela',
  'S66126A Laceration of flexor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'initial encounter',
  'S66126D Laceration of flexor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66126S Laceration of flexor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'sequela',
  'S66127A Laceration of flexor muscle, fascia and tendon of left little finger at wrist and hand level, initial',
  'encounter',
  'S66127D Laceration of flexor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66127S Laceration of flexor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'sequela',
  'S66128A Laceration of flexor muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66128D Laceration of flexor muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66128S Laceration of flexor muscle, fascia and tendon of other finger at wrist and hand level, sequela',
  'S66129A Laceration of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'initial encounter',
  'S66129D Laceration of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66129S Laceration of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66190A Other injury of flexor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'initial encounter',
  'S66190D Other injury of flexor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66190S Other injury of flexor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66191A Other injury of flexor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'initial encounter',
  'S66191D Other injury of flexor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66191S Other injury of flexor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'sequela',
  'S66192A Other injury of flexor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'initial encounter',
  'S66192D Other injury of flexor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66192S Other injury of flexor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'sequela',
  'S66193A Other injury of flexor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'initial encounter',
  'S66193D Other injury of flexor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66193S Other injury of flexor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66194A Other injury of flexor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'initial encounter',
  'S66194D Other injury of flexor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66194S Other injury of flexor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'sequela',
  'S66195A Other injury of flexor muscle, fascia and tendon of left ring finger at wrist and hand level, initial',
  'encounter',
  'S66195D Other injury of flexor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66195S Other injury of flexor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'sequela',
  'S66196A Other injury of flexor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'initial encounter',
  'S66196D Other injury of flexor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66196S Other injury of flexor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'sequela',
  'S66197A Other injury of flexor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'initial encounter',
  'S66197D Other injury of flexor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66197S Other injury of flexor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'sequela',
  'S66198A Other injury of flexor muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66198D Other injury of flexor muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66198S Other injury of flexor muscle, fascia and tendon of other finger at wrist and hand level, sequela',
  'S66199A Other injury of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'initial encounter',
  'S66199D Other injury of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66199S Other injury of flexor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66201A Unspecified injury of extensor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, initial encounter',
  'S66201D Unspecified injury of extensor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, subsequent encounter',
  'S66201S Unspecified injury of extensor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'sequela',
  'S66202A Unspecified injury of extensor muscle, fascia and tendon of left thumb at wrist and hand level,',
  'initial encounter',
  'S66202D Unspecified injury of extensor muscle, fascia and tendon of left thumb at wrist and hand level,',
  'subsequent encounter',
  'S66202S Unspecified injury of extensor muscle, fascia and tendon of left thumb at wrist and hand level,',
  'sequela',
  'S66209A Unspecified injury of extensor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, initial encounter',
  'S66209D Unspecified injury of extensor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, subsequent encounter',
  'S66209S Unspecified injury of extensor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, sequela',
  'S66211A Strain of extensor muscle, fascia and tendon of right thumb at wrist and hand level, initial',
  'encounter',
  'S66211D Strain of extensor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'subsequent encounter',
  'S66211S Strain of extensor muscle, fascia and tendon of right thumb at wrist and hand level, sequela',
  'S66212A Strain of extensor muscle, fascia and tendon of left thumb at wrist and hand level, initial',
  'encounter',
  'S66212D Strain of extensor muscle, fascia and tendon of left thumb at wrist and hand level, subsequent',
  'encounter',
  'S66212S Strain of extensor muscle, fascia and tendon of left thumb at wrist and hand level, sequela',
  'S66219A Strain of extensor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'initial encounter',
  'S66219D Strain of extensor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'subsequent encounter',
  'S66219S Strain of extensor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'sequela',
  'S66221A Laceration of extensor muscle, fascia and tendon of right thumb at wrist and hand level, initial',
  'encounter',
  'S66221D Laceration of extensor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'subsequent encounter',
  'S66221S Laceration of extensor muscle, fascia and tendon of right thumb at wrist and hand level,',
  'sequela',
  'S66222A Laceration of extensor muscle, fascia and tendon of left thumb at wrist and hand level, initial',
  'encounter',
  'S66222D Laceration of extensor muscle, fascia and tendon of left thumb at wrist and hand level,',
  'subsequent encounter',
  'S66222S Laceration of extensor muscle, fascia and tendon of left thumb at wrist and hand level, sequela',
  'S66229A Laceration of extensor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'initial encounter',
  'S66229D Laceration of extensor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'subsequent encounter',
  'S66229S Laceration of extensor muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'sequela',
  'S66291A Other specified injury of extensor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, initial encounter',
  'S66291D Other specified injury of extensor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, subsequent encounter',
  'S66291S Other specified injury of extensor muscle, fascia and tendon of right thumb at wrist and hand',
  'level, sequela',
  'S66292A Other specified injury of extensor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, initial encounter',
  'S66292D Other specified injury of extensor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, subsequent encounter',
  'S66292S Other specified injury of extensor muscle, fascia and tendon of left thumb at wrist and hand',
  'level, sequela',
  'S66299A Other specified injury of extensor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, initial encounter',
  'S66299D Other specified injury of extensor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, subsequent encounter',
  'S66299S Other specified injury of extensor muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, sequela',
  'S66300A Unspecified injury of extensor muscle, fascia and tendon of right index finger at wrist and hand',
  'level, initial encounter',
  'S66300D Unspecified injury of extensor muscle, fascia and tendon of right index finger at wrist and hand',
  'level, subsequent encounter',
  'S66300S Unspecified injury of extensor muscle, fascia and tendon of right index finger at wrist and hand',
  'level, sequela',
  'S66301A Unspecified injury of extensor muscle, fascia and tendon of left index finger at wrist and hand',
  'level, initial encounter',
  'S66301D Unspecified injury of extensor muscle, fascia and tendon of left index finger at wrist and hand',
  'level, subsequent encounter',
  'S66301S Unspecified injury of extensor muscle, fascia and tendon of left index finger at wrist and hand',
  'level, sequela',
  'S66302A Unspecified injury of extensor muscle, fascia and tendon of right middle finger at wrist and',
  'hand level, initial encounter',
  'S66302D Unspecified injury of extensor muscle, fascia and tendon of right middle finger at wrist and',
  'hand level, subsequent encounter',
  'S66302S Unspecified injury of extensor muscle, fascia and tendon of right middle finger at wrist and',
  'hand level, sequela',
  'S66303A Unspecified injury of extensor muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, initial encounter',
  'S66303D Unspecified injury of extensor muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, subsequent encounter',
  'S66303S Unspecified injury of extensor muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, sequela',
  'S66304A Unspecified injury of extensor muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, initial encounter',
  'S66304D Unspecified injury of extensor muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, subsequent encounter',
  'S66304S Unspecified injury of extensor muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, sequela',
  'S66305A Unspecified injury of extensor muscle, fascia and tendon of left ring finger at wrist and hand',
  'level, initial encounter',
  'S66305D Unspecified injury of extensor muscle, fascia and tendon of left ring finger at wrist and hand',
  'level, subsequent encounter',
  'S66305S Unspecified injury of extensor muscle, fascia and tendon of left ring finger at wrist and hand',
  'level, sequela',
  'S66306A Unspecified injury of extensor muscle, fascia and tendon of right little finger at wrist and hand',
  'level, initial encounter',
  'S66306D Unspecified injury of extensor muscle, fascia and tendon of right little finger at wrist and hand',
  'level, subsequent encounter',
  'S66306S Unspecified injury of extensor muscle, fascia and tendon of right little finger at wrist and hand',
  'level, sequela',
  'S66307A Unspecified injury of extensor muscle, fascia and tendon of left little finger at wrist and hand',
  'level, initial encounter',
  'S66307D Unspecified injury of extensor muscle, fascia and tendon of left little finger at wrist and hand',
  'level, subsequent encounter',
  'S66307S Unspecified injury of extensor muscle, fascia and tendon of left little finger at wrist and hand',
  'level, sequela',
  'S66308A Unspecified injury of extensor muscle, fascia and tendon of other finger at wrist and hand',
  'level, initial encounter',
  'S66308D Unspecified injury of extensor muscle, fascia and tendon of other finger at wrist and hand',
  'level, subsequent encounter',
  'S66308S Unspecified injury of extensor muscle, fascia and tendon of other finger at wrist and hand level,',
  'sequela',
  'S66309A Unspecified injury of extensor muscle, fascia and tendon of unspecified finger at wrist and',
  'hand level, initial encounter',
  'S66309D Unspecified injury of extensor muscle, fascia and tendon of unspecified finger at wrist and',
  'hand level, subsequent encounter',
  'S66309S Unspecified injury of extensor muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, sequela',
  'S66310A Strain of extensor muscle, fascia and tendon of right index finger at wrist and hand level, initial',
  'encounter',
  'S66310D Strain of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66310S Strain of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66311A Strain of extensor muscle, fascia and tendon of left index finger at wrist and hand level, initial',
  'encounter',
  'S66311D Strain of extensor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66311S Strain of extensor muscle, fascia and tendon of left index finger at wrist and hand level, sequela',
  'S66312A Strain of extensor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'initial encounter',
  'S66312D Strain of extensor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66312S Strain of extensor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'sequela',
  'S66313A Strain of extensor muscle, fascia and tendon of left middle finger at wrist and hand level, initial',
  'encounter',
  'S66313D Strain of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66313S Strain of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66314A Strain of extensor muscle, fascia and tendon of right ring finger at wrist and hand level, initial',
  'encounter',
  'S66314D Strain of extensor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66314S Strain of extensor muscle, fascia and tendon of right ring finger at wrist and hand level, sequela',
  'S66315A Strain of extensor muscle, fascia and tendon of left ring finger at wrist and hand level, initial',
  'encounter',
  'S66315D Strain of extensor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66315S Strain of extensor muscle, fascia and tendon of left ring finger at wrist and hand level, sequela',
  'S66316A Strain of extensor muscle, fascia and tendon of right little finger at wrist and hand level, initial',
  'encounter',
  'S66316D Strain of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66316S Strain of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'sequela',
  'S66317A Strain of extensor muscle, fascia and tendon of left little finger at wrist and hand level, initial',
  'encounter',
  'S66317D Strain of extensor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66317S Strain of extensor muscle, fascia and tendon of left little finger at wrist and hand level, sequela',
  'S66318A Strain of extensor muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66318D Strain of extensor muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66318S Strain of extensor muscle, fascia and tendon of other finger at wrist and hand level, sequela',
  'S66319A Strain of extensor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'initial encounter',
  'S66319D Strain of extensor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66319S Strain of extensor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66320A Laceration of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'initial encounter',
  'S66320D Laceration of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66320S Laceration of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66321A Laceration of extensor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'initial encounter',
  'S66321D Laceration of extensor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66321S Laceration of extensor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'sequela',
  'S66322A Laceration of extensor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'initial encounter',
  'S66322D Laceration of extensor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66322S Laceration of extensor muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'sequela',
  'S66323A Laceration of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'initial encounter',
  'S66323D Laceration of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66323S Laceration of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66324A Laceration of extensor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'initial encounter',
  'S66324D Laceration of extensor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66324S Laceration of extensor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'sequela',
  'S66325A Laceration of extensor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'initial encounter',
  'S66325D Laceration of extensor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66325S Laceration of extensor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'sequela',
  'S66326A Laceration of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'initial encounter',
  'S66326D Laceration of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66326S Laceration of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'sequela',
  'S66327A Laceration of extensor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'initial encounter',
  'S66327D Laceration of extensor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66327S Laceration of extensor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'sequela',
  'S66328A Laceration of extensor muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66328D Laceration of extensor muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66328S Laceration of extensor muscle, fascia and tendon of other finger at wrist and hand level,',
  'sequela',
  'S66329A Laceration of extensor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'initial encounter',
  'S66329D Laceration of extensor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66329S Laceration of extensor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66390A Other injury of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'initial encounter',
  'S66390D Other injury of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66390S Other injury of extensor muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66391A Other injury of extensor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'initial encounter',
  'S66391D Other injury of extensor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66391S Other injury of extensor muscle, fascia and tendon of left index finger at wrist and hand level,',
  'sequela',
  'S66392A Other injury of extensor muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, initial encounter',
  'S66392D Other injury of extensor muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, subsequent encounter',
  'S66392S Other injury of extensor muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, sequela',
  'S66393A Other injury of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'initial encounter',
  'S66393D Other injury of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66393S Other injury of extensor muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66394A Other injury of extensor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'initial encounter',
  'S66394D Other injury of extensor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66394S Other injury of extensor muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'sequela',
  'S66395A Other injury of extensor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'initial encounter',
  'S66395D Other injury of extensor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66395S Other injury of extensor muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'sequela',
  'S66396A Other injury of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'initial encounter',
  'S66396D Other injury of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66396S Other injury of extensor muscle, fascia and tendon of right little finger at wrist and hand level,',
  'sequela',
  'S66397A Other injury of extensor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'initial encounter',
  'S66397D Other injury of extensor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66397S Other injury of extensor muscle, fascia and tendon of left little finger at wrist and hand level,',
  'sequela',
  'S66398A Other injury of extensor muscle, fascia and tendon of other finger at wrist and hand level,',
  'initial encounter',
  'S66398D Other injury of extensor muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66398S Other injury of extensor muscle, fascia and tendon of other finger at wrist and hand level,',
  'sequela',
  'S66399A Other injury of extensor muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, initial encounter',
  'S66399D Other injury of extensor muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, subsequent encounter',
  'S66399S Other injury of extensor muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66401A Unspecified injury of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level,',
  'initial encounter',
  'S66401D Unspecified injury of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level,',
  'subsequent encounter',
  'S66401S Unspecified injury of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level,',
  'sequela',
  'S66402A Unspecified injury of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level,',
  'initial encounter',
  'S66402D Unspecified injury of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level,',
  'subsequent encounter',
  'S66402S Unspecified injury of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level,',
  'sequela',
  'S66409A Unspecified injury of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, initial encounter',
  'S66409D Unspecified injury of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, subsequent encounter',
  'S66409S Unspecified injury of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, sequela',
  'S66411A Strain of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level, initial',
  'encounter',
  'S66411D Strain of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level, subsequent',
  'encounter',
  'S66411S Strain of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level, sequela',
  'S66412A Strain of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level, initial',
  'encounter',
  'S66412D Strain of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level, subsequent',
  'encounter',
  'S66412S Strain of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level, sequela',
  'S66419A Strain of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'initial encounter',
  'S66419D Strain of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'subsequent encounter',
  'S66419S Strain of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'sequela',
  'S66421A Laceration of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level, initial',
  'encounter',
  'S66421D Laceration of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level,',
  'subsequent encounter',
  'S66421S Laceration of intrinsic muscle, fascia and tendon of right thumb at wrist and hand level, sequela',
  'S66422A Laceration of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level, initial',
  'encounter',
  'S66422D Laceration of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level,',
  'subsequent encounter',
  'S66422S Laceration of intrinsic muscle, fascia and tendon of left thumb at wrist and hand level, sequela',
  'S66429A Laceration of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'initial encounter',
  'S66429D Laceration of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'subsequent encounter',
  'S66429S Laceration of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and hand level,',
  'sequela',
  'S66491A Other specified injury of intrinsic muscle, fascia and tendon of right thumb at wrist and hand',
  'level, initial encounter',
  'S66491D Other specified injury of intrinsic muscle, fascia and tendon of right thumb at wrist and hand',
  'level, subsequent encounter',
  'S66491S Other specified injury of intrinsic muscle, fascia and tendon of right thumb at wrist and hand',
  'level, sequela',
  'S66492A Other specified injury of intrinsic muscle, fascia and tendon of left thumb at wrist and hand',
  'level, initial encounter',
  'S66492D Other specified injury of intrinsic muscle, fascia and tendon of left thumb at wrist and hand',
  'level, subsequent encounter',
  'S66492S Other specified injury of intrinsic muscle, fascia and tendon of left thumb at wrist and hand',
  'level, sequela',
  'S66499A Other specified injury of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, initial encounter',
  'S66499D Other specified injury of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, subsequent encounter',
  'S66499S Other specified injury of intrinsic muscle, fascia and tendon of unspecified thumb at wrist and',
  'hand level, sequela',
  'S66500A Unspecified injury of intrinsic muscle, fascia and tendon of right index finger at wrist and hand',
  'level, initial encounter',
  'S66500D Unspecified injury of intrinsic muscle, fascia and tendon of right index finger at wrist and hand',
  'level, subsequent encounter',
  'S66500S Unspecified injury of intrinsic muscle, fascia and tendon of right index finger at wrist and hand',
  'level, sequela',
  'S66501A Unspecified injury of intrinsic muscle, fascia and tendon of left index finger at wrist and hand',
  'level, initial encounter',
  'S66501D Unspecified injury of intrinsic muscle, fascia and tendon of left index finger at wrist and hand',
  'level, subsequent encounter',
  'S66501S Unspecified injury of intrinsic muscle, fascia and tendon of left index finger at wrist and hand',
  'level, sequela',
  'S66502A Unspecified injury of intrinsic muscle, fascia and tendon of right middle finger at wrist and',
  'hand level, initial encounter',
  'S66502D Unspecified injury of intrinsic muscle, fascia and tendon of right middle finger at wrist and',
  'hand level, subsequent encounter',
  'S66502S Unspecified injury of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, sequela',
  'S66503A Unspecified injury of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, initial encounter',
  'S66503D Unspecified injury of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, subsequent encounter',
  'S66503S Unspecified injury of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand',
  'level, sequela',
  'S66504A Unspecified injury of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, initial encounter',
  'S66504D Unspecified injury of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, subsequent encounter',
  'S66504S Unspecified injury of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand',
  'level, sequela',
  'S66505A Unspecified injury of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand',
  'level, initial encounter',
  'S66505D Unspecified injury of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand',
  'level, subsequent encounter',
  'S66505S Unspecified injury of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand',
  'level, sequela',
  'S66506A Unspecified injury of intrinsic muscle, fascia and tendon of right little finger at wrist and hand',
  'level, initial encounter',
  'S66506D Unspecified injury of intrinsic muscle, fascia and tendon of right little finger at wrist and hand',
  'level, subsequent encounter',
  'S66506S Unspecified injury of intrinsic muscle, fascia and tendon of right little finger at wrist and hand',
  'level, sequela',
  'S66507A Unspecified injury of intrinsic muscle, fascia and tendon of left little finger at wrist and hand',
  'level, initial encounter',
  'S66507D Unspecified injury of intrinsic muscle, fascia and tendon of left little finger at wrist and hand',
  'level, subsequent encounter',
  'S66507S Unspecified injury of intrinsic muscle, fascia and tendon of left little finger at wrist and hand',
  'level, sequela',
  'S66508A Unspecified injury of intrinsic muscle, fascia and tendon of other finger at wrist and hand level,',
  'initial encounter',
  'S66508D Unspecified injury of intrinsic muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66508S Unspecified injury of intrinsic muscle, fascia and tendon of other finger at wrist and hand level,',
  'sequela',
  'S66509A Unspecified injury of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, initial encounter',
  'S66509D Unspecified injury of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, subsequent encounter',
  'S66509S Unspecified injury of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand',
  'level, sequela',
  'S66510A Strain of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level, initial',
  'encounter',
  'S66510D Strain of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66510S Strain of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66511A Strain of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level, initial',
  'encounter',
  'S66511D Strain of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66511S Strain of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level, sequela',
  'S66512A Strain of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand level, initial',
  'encounter',
  'S66512D Strain of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66512S Strain of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'sequela',
  'S66513A Strain of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level, initial',
  'encounter',
  'S66513D Strain of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66513S Strain of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66514A Strain of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level, initial',
  'encounter',
  'S66514D Strain of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66514S Strain of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level, sequela',
  'S66515A Strain of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level, initial',
  'encounter',
  'S66515D Strain of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66515S Strain of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level, sequela',
  'S66516A Strain of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level, initial',
  'encounter',
  'S66516D Strain of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66516S Strain of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level, sequela',
  'S66517A Strain of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level, initial',
  'encounter',
  'S66517D Strain of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66517S Strain of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level, sequela',
  'S66518A Strain of intrinsic muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66518D Strain of intrinsic muscle, fascia and tendon of other finger at wrist and hand level, subsequent',
  'encounter',
  'S66518S Strain of intrinsic muscle, fascia and tendon of other finger at wrist and hand level, sequela',
  'S66519A Strain of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level, initial',
  'encounter',
  'S66519D Strain of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66519S Strain of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66520A Laceration of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'initial encounter',
  'S66520D Laceration of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66520S Laceration of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66521A Laceration of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level,',
  'initial encounter',
  'S66521D Laceration of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66521S Laceration of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level,',
  'sequela',
  'S66522A Laceration of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'initial encounter',
  'S66522D Laceration of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66522S Laceration of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'sequela',
  'S66523A Laceration of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'initial encounter',
  'S66523D Laceration of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66523S Laceration of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66524A Laceration of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'initial encounter',
  'S66524D Laceration of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66524S Laceration of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'sequela',
  'S66525A Laceration of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'initial encounter',
  'S66525D Laceration of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66525S Laceration of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'sequela',
  'S66526A Laceration of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level,',
  'initial encounter',
  'S66526D Laceration of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66526S Laceration of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level,',
  'sequela',
  'S66527A Laceration of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level,',
  'initial encounter',
  'S66527D Laceration of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66527S Laceration of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level,',
  'sequela',
  'S66528A Laceration of intrinsic muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66528D Laceration of intrinsic muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66528S Laceration of intrinsic muscle, fascia and tendon of other finger at wrist and hand level, sequela',
  'S66529A Laceration of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'initial encounter',
  'S66529D Laceration of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66529S Laceration of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66590A Other injury of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'initial encounter',
  'S66590D Other injury of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'subsequent encounter',
  'S66590S Other injury of intrinsic muscle, fascia and tendon of right index finger at wrist and hand level,',
  'sequela',
  'S66591A Other injury of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level,',
  'initial encounter',
  'S66591D Other injury of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level,',
  'subsequent encounter',
  'S66591S Other injury of intrinsic muscle, fascia and tendon of left index finger at wrist and hand level,',
  'sequela',
  'S66592A Other injury of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, initial encounter',
  'S66592D Other injury of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand',
  'level, subsequent encounter',
  'S66592S Other injury of intrinsic muscle, fascia and tendon of right middle finger at wrist and hand level,',
  'sequela',
  'S66593A Other injury of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'initial encounter',
  'S66593D Other injury of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'subsequent encounter',
  'S66593S Other injury of intrinsic muscle, fascia and tendon of left middle finger at wrist and hand level,',
  'sequela',
  'S66594A Other injury of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'initial encounter',
  'S66594D Other injury of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66594S Other injury of intrinsic muscle, fascia and tendon of right ring finger at wrist and hand level,',
  'sequela',
  'S66595A Other injury of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'initial encounter',
  'S66595D Other injury of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'subsequent encounter',
  'S66595S Other injury of intrinsic muscle, fascia and tendon of left ring finger at wrist and hand level,',
  'sequela',
  'S66596A Other injury of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level,',
  'initial encounter',
  'S66596D Other injury of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level,',
  'subsequent encounter',
  'S66596S Other injury of intrinsic muscle, fascia and tendon of right little finger at wrist and hand level,',
  'sequela',
  'S66597A Other injury of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level,',
  'initial encounter',
  'S66597D Other injury of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level,',
  'subsequent encounter',
  'S66597S Other injury of intrinsic muscle, fascia and tendon of left little finger at wrist and hand level,',
  'sequela',
  'S66598A Other injury of intrinsic muscle, fascia and tendon of other finger at wrist and hand level, initial',
  'encounter',
  'S66598D Other injury of intrinsic muscle, fascia and tendon of other finger at wrist and hand level,',
  'subsequent encounter',
  'S66598S Other injury of intrinsic muscle, fascia and tendon of other finger at wrist and hand level,',
  'sequela',
  'S66599A Other injury of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'initial encounter',
  'S66599D Other injury of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'subsequent encounter',
  'S66599S Other injury of intrinsic muscle, fascia and tendon of unspecified finger at wrist and hand level,',
  'sequela',
  'S66801A Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level, right',
  'hand, initial encounter',
  'S66801D Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level, right',
  'hand, subsequent encounter',
  'S66801S Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level, right',
  'hand, sequela',
  'S66802A Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level, left',
  'hand, initial encounter',
  'S66802D Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level, left',
  'hand, subsequent encounter',
  'S66802S Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level, left',
  'hand, sequela',
  'S66809A Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level,',
  'unspecified hand, initial encounter',
  'S66809D Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level,',
  'unspecified hand, subsequent encounter',
  'S66809S Unspecified injury of other specified muscles, fascia and tendons at wrist and hand level,',
  'unspecified hand, sequela',
  'S66811A Strain of other specified muscles, fascia and tendons at wrist and hand level, right hand, initial',
  'encounter',
  'S66811D Strain of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'subsequent encounter',
  'S66811S Strain of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'sequela',
  'S66812A Strain of other specified muscles, fascia and tendons at wrist and hand level, left hand, initial',
  'encounter',
  'S66812D Strain of other specified muscles, fascia and tendons at wrist and hand level, left hand,',
  'subsequent encounter',
  'S66812S Strain of other specified muscles, fascia and tendons at wrist and hand level, left hand, sequela',
  'S66819A Strain of other specified muscles, fascia and tendons at wrist and hand level, unspecified hand,',
  'initial encounter',
  'S66819D Strain of other specified muscles, fascia and tendons at wrist and hand level, unspecified hand,',
  'subsequent encounter',
  'S66819S Strain of other specified muscles, fascia and tendons at wrist and hand level, unspecified hand,',
  'sequela',
  'S66821A Laceration of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'initial encounter',
  'S66821D Laceration of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'subsequent encounter',
  'S66821S Laceration of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'sequela',
  'S66822A Laceration of other specified muscles, fascia and tendons at wrist and hand level, left hand,',
  'initial encounter',
  'S66822D Laceration of other specified muscles, fascia and tendons at wrist and hand level, left hand,',
  'subsequent encounter',
  'S66822S Laceration of other specified muscles, fascia and tendons at wrist and hand level, left hand,',
  'sequela',
  'S66829A Laceration of other specified muscles, fascia and tendons at wrist and hand level, unspecified',
  'hand, initial encounter',
  'S66829D Laceration of other specified muscles, fascia and tendons at wrist and hand level, unspecified',
  'hand, subsequent encounter',
  'S66829S Laceration of other specified muscles, fascia and tendons at wrist and hand level, unspecified',
  'hand, sequela',
  'S66891A Other injury of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'initial encounter',
  'S66891D Other injury of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'subsequent encounter',
  'S66891S Other injury of other specified muscles, fascia and tendons at wrist and hand level, right hand,',
  'sequela',
  'S66892A Other injury of other specified muscles, fascia and tendons at wrist and hand level, left hand,',
  'initial encounter',
  'S66892D Other injury of other specified muscles, fascia and tendons at wrist and hand level, left hand,',
  'subsequent encounter',
  'S66892S Other injury of other specified muscles, fascia and tendons at wrist and hand level, left hand,',
  'sequela',
  'S66899A Other injury of other specified muscles, fascia and tendons at wrist and hand level, unspecified',
  'hand, initial encounter',
  'S66899D Other injury of other specified muscles, fascia and tendons at wrist and hand level, unspecified',
  'hand, subsequent encounter',
  'S66899S Other injury of other specified muscles, fascia and tendons at wrist and hand level, unspecified',
  'hand, sequela',
  'S66901A Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, right hand,',
  'initial encounter',
  'S66901D Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, right hand,',
  'subsequent encounter',
  'S66901S Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, right hand,',
  'sequela',
  'S66902A Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, left hand,',
  'initial encounter',
  'S66902D Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, left hand,',
  'subsequent encounter',
  'S66902S Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, left hand,',
  'sequela',
  'S66909A Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, unspecified',
  'hand, initial encounter',
  'S66909D Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, unspecified',
  'hand, subsequent encounter',
  'S66909S Unspecified injury of unspecified muscle, fascia and tendon at wrist and hand level, unspecified',
  'hand, sequela',
  'S66911A Strain of unspecified muscle, fascia and tendon at wrist and hand level, right hand, initial',
  'encounter',
  'S66911D Strain of unspecified muscle, fascia and tendon at wrist and hand level, right hand, subsequent',
  'encounter',
  'S66911S Strain of unspecified muscle, fascia and tendon at wrist and hand level, right hand, sequela',
  'S66912A Strain of unspecified muscle, fascia and tendon at wrist and hand level, left hand, initial',
  'encounter',
  'S66912D Strain of unspecified muscle, fascia and tendon at wrist and hand level, left hand, subsequent',
  'encounter',
  'S66912S Strain of unspecified muscle, fascia and tendon at wrist and hand level, left hand, sequela',
  'S66919A Strain of unspecified muscle, fascia and tendon at wrist and hand level, unspecified hand, initial',
  'encounter',
  'S66919D Strain of unspecified muscle, fascia and tendon at wrist and hand level, unspecified hand,',
  'subsequent encounter',
  'S66919S Strain of unspecified muscle, fascia and tendon at wrist and hand level, unspecified hand,',
  'sequela',
  'S66921A Laceration of unspecified muscle, fascia and tendon at wrist and hand level, right hand, initial',
  'encounter',
  'S66921D Laceration of unspecified muscle, fascia and tendon at wrist and hand level, right hand,',
  'subsequent encounter',
  'S66921S Laceration of unspecified muscle, fascia and tendon at wrist and hand level, right hand, sequela',
  'S66922A Laceration of unspecified muscle, fascia and tendon at wrist and hand level, left hand, initial',
  'encounter',
  'S66922D Laceration of unspecified muscle, fascia and tendon at wrist and hand level, left hand,',
  'subsequent encounter',
  'S66922S Laceration of unspecified muscle, fascia and tendon at wrist and hand level, left hand, sequela',
  'S66929A Laceration of unspecified muscle, fascia and tendon at wrist and hand level, unspecified hand,',
  'initial encounter',
  'S66929D Laceration of unspecified muscle, fascia and tendon at wrist and hand level, unspecified hand,',
  'subsequent encounter',
  'S66929S Laceration of unspecified muscle, fascia and tendon at wrist and hand level, unspecified hand,',
  'sequela',
  'S66991A Other injury of unspecified muscle, fascia and tendon at wrist and hand level, right hand, initial',
  'encounter',
  'S66991D Other injury of unspecified muscle, fascia and tendon at wrist and hand level, right hand,',
  'subsequent encounter',
  'S66991S Other injury of unspecified muscle, fascia and tendon at wrist and hand level, right hand,',
  'sequela',
  'S66992A Other injury of unspecified muscle, fascia and tendon at wrist and hand level, left hand, initial',
  'encounter',
  'S66992D Other injury of unspecified muscle, fascia and tendon at wrist and hand level, left hand,',
  'subsequent encounter',
  'S66992S Other injury of unspecified muscle, fascia and tendon at wrist and hand level, left hand, sequela',
  'S66999A Other injury of unspecified muscle, fascia and tendon at wrist and hand level, unspecified',
  'hand, initial encounter',
  'S66999D Other injury of unspecified muscle, fascia and tendon at wrist and hand level, unspecified',
  'hand, subsequent encounter',
  'S66999S Other injury of unspecified muscle, fascia and tendon at wrist and hand level, unspecified hand,',
  'sequela',
  'S6700XA Crushing injury of unspecified thumb, initial encounter',
  'S6700XD Crushing injury of unspecified thumb, subsequent encounter',
  'S6700XS Crushing injury of unspecified thumb, sequela',
  'S6701XA Crushing injury of right thumb, initial encounter',
  'S6701XD Crushing injury of right thumb, subsequent encounter',
  'S6701XS Crushing injury of right thumb, sequela',
  'S6702XA Crushing injury of left thumb, initial encounter',
  'S6702XD Crushing injury of left thumb, subsequent encounter',
  'S6702XS Crushing injury of left thumb, sequela',
  'S6710XA Crushing injury of unspecified finger(s), initial encounter',
  'S6710XD Crushing injury of unspecified finger(s), subsequent encounter',
  'S6710XS Crushing injury of unspecified finger(s), sequela',
  'S67190A Crushing injury of right index finger, initial encounter',
  'S67190D Crushing injury of right index finger, subsequent encounter',
  'S67190S Crushing injury of right index finger, sequela',
  'S67191A Crushing injury of left index finger, initial encounter',
  'S67191D Crushing injury of left index finger, subsequent encounter',
  'S67191S Crushing injury of left index finger, sequela',
  'S67192A Crushing injury of right middle finger, initial encounter',
  'S67192D Crushing injury of right middle finger, subsequent encounter',
  'S67192S Crushing injury of right middle finger, sequela',
  'S67193A Crushing injury of left middle finger, initial encounter',
  'S67193D Crushing injury of left middle finger, subsequent encounter',
  'S67193S Crushing injury of left middle finger, sequela',
  'S67194A Crushing injury of right ring finger, initial encounter',
  'S67194D Crushing injury of right ring finger, subsequent encounter',
  'S67194S Crushing injury of right ring finger, sequela',
  'S67195A Crushing injury of left ring finger, initial encounter',
  'S67195D Crushing injury of left ring finger, subsequent encounter',
  'S67195S Crushing injury of left ring finger, sequela',
  'S67196A Crushing injury of right little finger, initial encounter',
  'S67196D Crushing injury of right little finger, subsequent encounter',
  'S67196S Crushing injury of right little finger, sequela',
  'S67197A Crushing injury of left little finger, initial encounter',
  'S67197D Crushing injury of left little finger, subsequent encounter',
  'S67197S Crushing injury of left little finger, sequela',
  'S67198A Crushing injury of other finger, initial encounter',
  'S67198D Crushing injury of other finger, subsequent encounter',
  'S67198S Crushing injury of other finger, sequela',
  'S6720XA Crushing injury of unspecified hand, initial encounter',
  'S6720XD Crushing injury of unspecified hand, subsequent encounter',
  'S6720XS Crushing injury of unspecified hand, sequela',
  'S6721XA Crushing injury of right hand, initial encounter',
  'S6721XD Crushing injury of right hand, subsequent encounter',
  'S6721XS Crushing injury of right hand, sequela',
  'S6722XA Crushing injury of left hand, initial encounter',
  'S6722XD Crushing injury of left hand, subsequent encounter',
  'S6722XS Crushing injury of left hand, sequela',
  'S6730XA Crushing injury of unspecified wrist, initial encounter',
  'S6730XD Crushing injury of unspecified wrist, subsequent encounter',
  'S6730XS Crushing injury of unspecified wrist, sequela',
  'S6731XA Crushing injury of right wrist, initial encounter',
  'S6731XD Crushing injury of right wrist, subsequent encounter',
  'S6731XS Crushing injury of right wrist, sequela',
  'S6732XA Crushing injury of left wrist, initial encounter',
  'S6732XD Crushing injury of left wrist, subsequent encounter',
  'S6732XS Crushing injury of left wrist, sequela',
  'S6740XA Crushing injury of unspecified wrist and hand, initial encounter',
  'S6740XD Crushing injury of unspecified wrist and hand, subsequent encounter',
  'S6740XS Crushing injury of unspecified wrist and hand, sequela',
  'S6741XA Crushing injury of right wrist and hand, initial encounter',
  'S6741XD Crushing injury of right wrist and hand, subsequent encounter',
  'S6741XS Crushing injury of right wrist and hand, sequela',
  'S6742XA Crushing injury of left wrist and hand, initial encounter',
  'S6742XD Crushing injury of left wrist and hand, subsequent encounter',
  'S6742XS Crushing injury of left wrist and hand, sequela',
  'S6790XA Crushing injury of unspecified part(s) of unspecified wrist, hand and fingers, initial encounter',
  'S6790XD Crushing injury of unspecified part(s) of unspecified wrist, hand and fingers, subsequent',
  'encounter',
  'S6790XS Crushing injury of unspecified part(s) of unspecified wrist, hand and fingers, sequela',
  'S6791XA Crushing injury of unspecified part(s) of right wrist, hand and fingers, initial encounter',
  'S6791XD Crushing injury of unspecified part(s) of right wrist, hand and fingers, subsequent encounter',
  'S6791XS Crushing injury of unspecified part(s) of right wrist, hand and fingers, sequela',
  'S6792XA Crushing injury of unspecified part(s) of left wrist, hand and fingers, initial encounter',
  'S6792XD Crushing injury of unspecified part(s) of left wrist, hand and fingers, subsequent encounter',
  'S6792XS Crushing injury of unspecified part(s) of left wrist, hand and fingers, sequela',
  'S68011A Complete traumatic metacarpophalangeal amputation of right thumb, initial encounter',
  'S68011D Complete traumatic metacarpophalangeal amputation of right thumb, subsequent encounter',
  'S68011S Complete traumatic metacarpophalangeal amputation of right thumb, sequela',
  'S68012A Complete traumatic metacarpophalangeal amputation of left thumb, initial encounter',
  'S68012D Complete traumatic metacarpophalangeal amputation of left thumb, subsequent encounter',
  'S68012S Complete traumatic metacarpophalangeal amputation of left thumb, sequela',
  'S68019A Complete traumatic metacarpophalangeal amputation of unspecified thumb, initial encounter',
  'S68019D Complete traumatic metacarpophalangeal amputation of unspecified thumb, subsequent',
  'encounter',
  'S68019S Complete traumatic metacarpophalangeal amputation of unspecified thumb, sequela',
  'S68021A Partial traumatic metacarpophalangeal amputation of right thumb, initial encounter',
  'S68021D Partial traumatic metacarpophalangeal amputation of right thumb, subsequent encounter',
  'S68021S Partial traumatic metacarpophalangeal amputation of right thumb, sequela',
  'S68022A Partial traumatic metacarpophalangeal amputation of left thumb, initial encounter',
  'S68022D Partial traumatic metacarpophalangeal amputation of left thumb, subsequent encounter',
  'S68022S Partial traumatic metacarpophalangeal amputation of left thumb, sequela',
  'S68029A Partial traumatic metacarpophalangeal amputation of unspecified thumb, initial encounter',
  'S68029D Partial traumatic metacarpophalangeal amputation of unspecified thumb, subsequent',
  'encounter',
  'S68029S Partial traumatic metacarpophalangeal amputation of unspecified thumb, sequela',
  'S68110A Complete traumatic metacarpophalangeal amputation of right index finger, initial encounter',
  'S68110D Complete traumatic metacarpophalangeal amputation of right index finger, subsequent',
  'encounter',
  'S68110S Complete traumatic metacarpophalangeal amputation of right index finger, sequela',
  'S68111A Complete traumatic metacarpophalangeal amputation of left index finger, initial encounter',
  'S68111D Complete traumatic metacarpophalangeal amputation of left index finger, subsequent',
  'encounter',
  'S68111S Complete traumatic metacarpophalangeal amputation of left index finger, sequela',
  'S68112A Complete traumatic metacarpophalangeal amputation of right middle finger, initial encounter',
  'S68112D Complete traumatic metacarpophalangeal amputation of right middle finger, subsequent',
  'encounter',
  'S68112S Complete traumatic metacarpophalangeal amputation of right middle finger, sequela',
  'S68113A Complete traumatic metacarpophalangeal amputation of left middle finger, initial encounter',
  'S68113D Complete traumatic metacarpophalangeal amputation of left middle finger, subsequent',
  'encounter',
  'S68113S Complete traumatic metacarpophalangeal amputation of left middle finger, sequela',
  'S68114A Complete traumatic metacarpophalangeal amputation of right ring finger, initial encounter',
  'S68114D Complete traumatic metacarpophalangeal amputation of right ring finger, subsequent',
  'encounter',
  'S68114S Complete traumatic metacarpophalangeal amputation of right ring finger, sequela',
  'S68115A Complete traumatic metacarpophalangeal amputation of left ring finger, initial encounter',
  'S68115D Complete traumatic metacarpophalangeal amputation of left ring finger, subsequent',
  'encounter',
  'S68115S Complete traumatic metacarpophalangeal amputation of left ring finger, sequela',
  'S68116A Complete traumatic metacarpophalangeal amputation of right little finger, initial encounter',
  'S68116D Complete traumatic metacarpophalangeal amputation of right little finger, subsequent',
  'encounter',
  'S68116S Complete traumatic metacarpophalangeal amputation of right little finger, sequela',
  'S68117A Complete traumatic metacarpophalangeal amputation of left little finger, initial encounter',
  'S68117D Complete traumatic metacarpophalangeal amputation of left little finger, subsequent',
  'encounter',
  'S68117S Complete traumatic metacarpophalangeal amputation of left little finger, sequela',
  'S68118A Complete traumatic metacarpophalangeal amputation of other finger, initial encounter',
  'S68118D Complete traumatic metacarpophalangeal amputation of other finger, subsequent encounter',
  'S68118S Complete traumatic metacarpophalangeal amputation of other finger, sequela',
  'S68119A Complete traumatic metacarpophalangeal amputation of unspecified finger, initial encounter',
  'S68119D Complete traumatic metacarpophalangeal amputation of unspecified finger, subsequent',
  'encounter',
  'S68119S Complete traumatic metacarpophalangeal amputation of unspecified finger, sequela',
  'S68120A Partial traumatic metacarpophalangeal amputation of right index finger, initial encounter',
  'S68120D Partial traumatic metacarpophalangeal amputation of right index finger, subsequent',
  'encounter',
  'S68120S Partial traumatic metacarpophalangeal amputation of right index finger, sequela',
  'S68121A Partial traumatic metacarpophalangeal amputation of left index finger, initial encounter',
  'S68121D Partial traumatic metacarpophalangeal amputation of left index finger, subsequent encounter',
  'S68121S Partial traumatic metacarpophalangeal amputation of left index finger, sequela',
  'S68122A Partial traumatic metacarpophalangeal amputation of right middle finger, initial encounter',
  'S68122D Partial traumatic metacarpophalangeal amputation of right middle finger, subsequent',
  'encounter',
  'S68122S Partial traumatic metacarpophalangeal amputation of right middle finger, sequela',
  'S68123A Partial traumatic metacarpophalangeal amputation of left middle finger, initial encounter',
  'S68123D Partial traumatic metacarpophalangeal amputation of left middle finger, subsequent',
  'encounter',
  'S68123S Partial traumatic metacarpophalangeal amputation of left middle finger, sequela',
  'S68124A Partial traumatic metacarpophalangeal amputation of right ring finger, initial encounter',
  'S68124D Partial traumatic metacarpophalangeal amputation of right ring finger, subsequent encounter',
  'S68124S Partial traumatic metacarpophalangeal amputation of right ring finger, sequela',
  'S68125A Partial traumatic metacarpophalangeal amputation of left ring finger, initial encounter',
  'S68125D Partial traumatic metacarpophalangeal amputation of left ring finger, subsequent encounter',
  'S68125S Partial traumatic metacarpophalangeal amputation of left ring finger, sequela',
  'S68126A Partial traumatic metacarpophalangeal amputation of right little finger, initial encounter',
  'S68126D Partial traumatic metacarpophalangeal amputation of right little finger, subsequent encounter',
  'S68126S Partial traumatic metacarpophalangeal amputation of right little finger, sequela',
  'S68127A Partial traumatic metacarpophalangeal amputation of left little finger, initial encounter',
  'S68127D Partial traumatic metacarpophalangeal amputation of left little finger, subsequent encounter',
  'S68127S Partial traumatic metacarpophalangeal amputation of left little finger, sequela',
  'S68128A Partial traumatic metacarpophalangeal amputation of other finger, initial encounter',
  'S68128D Partial traumatic metacarpophalangeal amputation of other finger, subsequent encounter',
  'S68128S Partial traumatic metacarpophalangeal amputation of other finger, sequela',
  'S68129A Partial traumatic metacarpophalangeal amputation of unspecified finger, initial encounter',
  'S68129D Partial traumatic metacarpophalangeal amputation of unspecified finger, subsequent',
  'encounter',
  'S68129S Partial traumatic metacarpophalangeal amputation of unspecified finger, sequela',
  'S68411A Complete traumatic amputation of right hand at wrist level, initial encounter',
  'S68411D Complete traumatic amputation of right hand at wrist level, subsequent encounter',
  'S68411S Complete traumatic amputation of right hand at wrist level, sequela',
  'S68412A Complete traumatic amputation of left hand at wrist level, initial encounter',
  'S68412D Complete traumatic amputation of left hand at wrist level, subsequent encounter',
  'S68412S Complete traumatic amputation of left hand at wrist level, sequela',
  'S68419A Complete traumatic amputation of unspecified hand at wrist level, initial encounter',
  'S68419D Complete traumatic amputation of unspecified hand at wrist level, subsequent encounter',
  'S68419S Complete traumatic amputation of unspecified hand at wrist level, sequela',
  'S68421A Partial traumatic amputation of right hand at wrist level, initial encounter',
  'S68421D Partial traumatic amputation of right hand at wrist level, subsequent encounter',
  'S68421S Partial traumatic amputation of right hand at wrist level, sequela',
  'S68422A Partial traumatic amputation of left hand at wrist level, initial encounter',
  'S68422D Partial traumatic amputation of left hand at wrist level, subsequent encounter',
  'S68422S Partial traumatic amputation of left hand at wrist level, sequela',
  'S68429A Partial traumatic amputation of unspecified hand at wrist level, initial encounter',
  'S68429D Partial traumatic amputation of unspecified hand at wrist level, subsequent encounter',
  'S68429S Partial traumatic amputation of unspecified hand at wrist level, sequela',
  'S68511A Complete traumatic transphalangeal amputation of right thumb, initial encounter',
  'S68511D Complete traumatic transphalangeal amputation of right thumb, subsequent encounter',
  'S68511S Complete traumatic transphalangeal amputation of right thumb, sequela',
  'S68512A Complete traumatic transphalangeal amputation of left thumb, initial encounter',
  'S68512D Complete traumatic transphalangeal amputation of left thumb, subsequent encounter',
  'S68512S Complete traumatic transphalangeal amputation of left thumb, sequela',
  'S68519A Complete traumatic transphalangeal amputation of unspecified thumb, initial encounter',
  'S68519D Complete traumatic transphalangeal amputation of unspecified thumb, subsequent encounter',
  'S68519S Complete traumatic transphalangeal amputation of unspecified thumb, sequela',
  'S68521A Partial traumatic transphalangeal amputation of right thumb, initial encounter',
  'S68521D Partial traumatic transphalangeal amputation of right thumb, subsequent encounter',
  'S68521S Partial traumatic transphalangeal amputation of right thumb, sequela',
  'S68522A Partial traumatic transphalangeal amputation of left thumb, initial encounter',
  'S68522D Partial traumatic transphalangeal amputation of left thumb, subsequent encounter',
  'S68522S Partial traumatic transphalangeal amputation of left thumb, sequela',
  'S68529A Partial traumatic transphalangeal amputation of unspecified thumb, initial encounter',
  'S68529D Partial traumatic transphalangeal amputation of unspecified thumb, subsequent encounter',
  'S68529S Partial traumatic transphalangeal amputation of unspecified thumb, sequela',
  'S68610A Complete traumatic transphalangeal amputation of right index finger, initial encounter',
  'S68610D Complete traumatic transphalangeal amputation of right index finger, subsequent encounter',
  'S68610S Complete traumatic transphalangeal amputation of right index finger, sequela',
  'S68611A Complete traumatic transphalangeal amputation of left index finger, initial encounter',
  'S68611D Complete traumatic transphalangeal amputation of left index finger, subsequent encounter',
  'S68611S Complete traumatic transphalangeal amputation of left index finger, sequela',
  'S68612A Complete traumatic transphalangeal amputation of right middle finger, initial encounter',
  'S68612D Complete traumatic transphalangeal amputation of right middle finger, subsequent encounter',
  'S68612S Complete traumatic transphalangeal amputation of right middle finger, sequela',
  'S68613A Complete traumatic transphalangeal amputation of left middle finger, initial encounter',
  'S68613D Complete traumatic transphalangeal amputation of left middle finger, subsequent encounter',
  'S68613S Complete traumatic transphalangeal amputation of left middle finger, sequela',
  'S68614A Complete traumatic transphalangeal amputation of right ring finger, initial encounter',
  'S68614D Complete traumatic transphalangeal amputation of right ring finger, subsequent encounter',
  'S68614S Complete traumatic transphalangeal amputation of right ring finger, sequela',
  'S68615A Complete traumatic transphalangeal amputation of left ring finger, initial encounter',
  'S68615D Complete traumatic transphalangeal amputation of left ring finger, subsequent encounter',
  'S68615S Complete traumatic transphalangeal amputation of left ring finger, sequela',
  'S68616A Complete traumatic transphalangeal amputation of right little finger, initial encounter',
  'S68616D Complete traumatic transphalangeal amputation of right little finger, subsequent encounter',
  'S68616S Complete traumatic transphalangeal amputation of right little finger, sequela',
  'S68617A Complete traumatic transphalangeal amputation of left little finger, initial encounter',
  'S68617D Complete traumatic transphalangeal amputation of left little finger, subsequent encounter',
  'S68617S Complete traumatic transphalangeal amputation of left little finger, sequela',
  'S68618A Complete traumatic transphalangeal amputation of other finger, initial encounter',
  'S68618D Complete traumatic transphalangeal amputation of other finger, subsequent encounter',
  'S68618S Complete traumatic transphalangeal amputation of other finger, sequela',
  'S68619A Complete traumatic transphalangeal amputation of unspecified finger, initial encounter',
  'S68619D Complete traumatic transphalangeal amputation of unspecified finger, subsequent encounter',
  'S68619S Complete traumatic transphalangeal amputation of unspecified finger, sequela',
  'S68620A Partial traumatic transphalangeal amputation of right index finger, initial encounter',
  'S68620D Partial traumatic transphalangeal amputation of right index finger, subsequent encounter',
  'S68620S Partial traumatic transphalangeal amputation of right index finger, sequela',
  'S68621A Partial traumatic transphalangeal amputation of left index finger, initial encounter',
  'S68621D Partial traumatic transphalangeal amputation of left index finger, subsequent encounter',
  'S68621S Partial traumatic transphalangeal amputation of left index finger, sequela',
  'S68622A Partial traumatic transphalangeal amputation of right middle finger, initial encounter',
  'S68622D Partial traumatic transphalangeal amputation of right middle finger, subsequent encounter',
  'S68622S Partial traumatic transphalangeal amputation of right middle finger, sequela',
  'S68623A Partial traumatic transphalangeal amputation of left middle finger, initial encounter',
  'S68623D Partial traumatic transphalangeal amputation of left middle finger, subsequent encounter',
  'S68623S Partial traumatic transphalangeal amputation of left middle finger, sequela',
  'S68624A Partial traumatic transphalangeal amputation of right ring finger, initial encounter',
  'S68624D Partial traumatic transphalangeal amputation of right ring finger, subsequent encounter',
  'S68624S Partial traumatic transphalangeal amputation of right ring finger, sequela',
  'S68625A Partial traumatic transphalangeal amputation of left ring finger, initial encounter',
  'S68625D Partial traumatic transphalangeal amputation of left ring finger, subsequent encounter',
  'S68625S Partial traumatic transphalangeal amputation of left ring finger, sequela',
  'S68626A Partial traumatic transphalangeal amputation of right little finger, initial encounter',
  'S68626D Partial traumatic transphalangeal amputation of right little finger, subsequent encounter',
  'S68626S Partial traumatic transphalangeal amputation of right little finger, sequela',
  'S68627A Partial traumatic transphalangeal amputation of left little finger, initial encounter',
  'S68627D Partial traumatic transphalangeal amputation of left little finger, subsequent encounter',
  'S68627S Partial traumatic transphalangeal amputation of left little finger, sequela',
  'S68628A Partial traumatic transphalangeal amputation of other finger, initial encounter',
  'S68628D Partial traumatic transphalangeal amputation of other finger, subsequent encounter',
  'S68628S Partial traumatic transphalangeal amputation of other finger, sequela',
  'S68629A Partial traumatic transphalangeal amputation of unspecified finger, initial encounter',
  'S68629D Partial traumatic transphalangeal amputation of unspecified finger, subsequent encounter',
  'S68629S Partial traumatic transphalangeal amputation of unspecified finger, sequela',
  'S68711A Complete traumatic transmetacarpal amputation of right hand, initial encounter',
  'S68711D Complete traumatic transmetacarpal amputation of right hand, subsequent encounter',
  'S68711S Complete traumatic transmetacarpal amputation of right hand, sequela',
  'S68712A Complete traumatic transmetacarpal amputation of left hand, initial encounter',
  'S68712D Complete traumatic transmetacarpal amputation of left hand, subsequent encounter',
  'S68712S Complete traumatic transmetacarpal amputation of left hand, sequela',
  'S68719A Complete traumatic transmetacarpal amputation of unspecified hand, initial encounter',
  'S68719D Complete traumatic transmetacarpal amputation of unspecified hand, subsequent encounter',
  'S68719S Complete traumatic transmetacarpal amputation of unspecified hand, sequela',
  'S68721A Partial traumatic transmetacarpal amputation of right hand, initial encounter',
  'S68721D Partial traumatic transmetacarpal amputation of right hand, subsequent encounter',
  'S68721S Partial traumatic transmetacarpal amputation of right hand, sequela',
  'S68722A Partial traumatic transmetacarpal amputation of left hand, initial encounter',
  'S68722D Partial traumatic transmetacarpal amputation of left hand, subsequent encounter',
  'S68722S Partial traumatic transmetacarpal amputation of left hand, sequela',
  'S68729A Partial traumatic transmetacarpal amputation of unspecified hand, initial encounter',
  'S68729D Partial traumatic transmetacarpal amputation of unspecified hand, subsequent encounter',
  'S68729S Partial traumatic transmetacarpal amputation of unspecified hand, sequela',
  'S6980XA Other specified injuries of unspecified wrist, hand and finger(s), initial encounter',
  'S6980XD Other specified injuries of unspecified wrist, hand and finger(s), subsequent encounter',
  'S6980XS Other specified injuries of unspecified wrist, hand and finger(s), sequela',
  'S6981XA Other specified injuries of right wrist, hand and finger(s), initial encounter',
  'S6981XD Other specified injuries of right wrist, hand and finger(s), subsequent encounter',
  'S6981XS Other specified injuries of right wrist, hand and finger(s), sequela',
  'S6982XA Other specified injuries of left wrist, hand and finger(s), initial encounter',
  'S6982XD Other specified injuries of left wrist, hand and finger(s), subsequent encounter',
  'S6982XS Other specified injuries of left wrist, hand and finger(s), sequela',
  'S6990XA Unspecified injury of unspecified wrist, hand and finger(s), initial encounter',
  'S6990XD Unspecified injury of unspecified wrist, hand and finger(s), subsequent encounter',
  'S6990XS Unspecified injury of unspecified wrist, hand and finger(s), sequela',
  'S6991XA Unspecified injury of right wrist, hand and finger(s), initial encounter',
  'S6991XD Unspecified injury of right wrist, hand and finger(s), subsequent encounter',
  'S6991XS Unspecified injury of right wrist, hand and finger(s), sequela',
  'S6992XA Unspecified injury of left wrist, hand and finger(s), initial encounter',
  'S6992XD Unspecified injury of left wrist, hand and finger(s), subsequent encounter',
  'S6992XS Unspecified injury of left wrist, hand and finger(s), sequela',
  'S7000XA Contusion of unspecified hip, initial encounter',
  'S7000XD Contusion of unspecified hip, subsequent encounter',
  'S7000XS Contusion of unspecified hip, sequela',
  'S7001XA Contusion of right hip, initial encounter',
  'S7001XD Contusion of right hip, subsequent encounter',
  'S7001XS Contusion of right hip, sequela',
  'S7002XA Contusion of left hip, initial encounter',
  'S7002XD Contusion of left hip, subsequent encounter',
  'S7002XS Contusion of left hip, sequela',
  'S7010XA Contusion of unspecified thigh, initial encounter',
  'S7010XD Contusion of unspecified thigh, subsequent encounter',
  'S7010XS Contusion of unspecified thigh, sequela',
  'S7011XA Contusion of right thigh, initial encounter',
  'S7011XD Contusion of right thigh, subsequent encounter',
  'S7011XS Contusion of right thigh, sequela',
  'S7012XA Contusion of left thigh, initial encounter',
  'S7012XD Contusion of left thigh, subsequent encounter',
  'S7012XS Contusion of left thigh, sequela',
  'S70211A Abrasion, right hip, initial encounter',
  'S70211D Abrasion, right hip, subsequent encounter',
  'S70211S Abrasion, right hip, sequela',
  'S70212A Abrasion, left hip, initial encounter',
  'S70212D Abrasion, left hip, subsequent encounter',
  'S70212S Abrasion, left hip, sequela',
  'S70219A Abrasion, unspecified hip, initial encounter',
  'S70219D Abrasion, unspecified hip, subsequent encounter',
  'S70219S Abrasion, unspecified hip, sequela',
  'S70221A Blister (nonthermal), right hip, initial encounter',
  'S70221D Blister (nonthermal), right hip, subsequent encounter',
  'S70221S Blister (nonthermal), right hip, sequela',
  'S70222A Blister (nonthermal), left hip, initial encounter',
  'S70222D Blister (nonthermal), left hip, subsequent encounter',
  'S70222S Blister (nonthermal), left hip, sequela',
  'S70229A Blister (nonthermal), unspecified hip, initial encounter',
  'S70229D Blister (nonthermal), unspecified hip, subsequent encounter',
  'S70229S Blister (nonthermal), unspecified hip, sequela',
  'S70241A External constriction, right hip, initial encounter',
  'S70241D External constriction, right hip, subsequent encounter',
  'S70241S External constriction, right hip, sequela',
  'S70242A External constriction, left hip, initial encounter',
  'S70242D External constriction, left hip, subsequent encounter',
  'S70242S External constriction, left hip, sequela',
  'S70249A External constriction, unspecified hip, initial encounter',
  'S70249D External constriction, unspecified hip, subsequent encounter',
  'S70249S External constriction, unspecified hip, sequela',
  'S70251A Superficial foreign body, right hip, initial encounter',
  'S70251D Superficial foreign body, right hip, subsequent encounter',
  'S70251S Superficial foreign body, right hip, sequela',
  'S70252A Superficial foreign body, left hip, initial encounter',
  'S70252D Superficial foreign body, left hip, subsequent encounter',
  'S70252S Superficial foreign body, left hip, sequela',
  'S70259A Superficial foreign body, unspecified hip, initial encounter',
  'S70259D Superficial foreign body, unspecified hip, subsequent encounter',
  'S70259S Superficial foreign body, unspecified hip, sequela',
  'S70261A Insect bite (nonvenomous), right hip, initial encounter',
  'S70261D Insect bite (nonvenomous), right hip, subsequent encounter',
  'S70261S Insect bite (nonvenomous), right hip, sequela',
  'S70262A Insect bite (nonvenomous), left hip, initial encounter',
  'S70262D Insect bite (nonvenomous), left hip, subsequent encounter',
  'S70262S Insect bite (nonvenomous), left hip, sequela',
  'S70269A Insect bite (nonvenomous), unspecified hip, initial encounter',
  'S70269D Insect bite (nonvenomous), unspecified hip, subsequent encounter',
  'S70269S Insect bite (nonvenomous), unspecified hip, sequela',
  'S70271A Other superficial bite of hip, right hip, initial encounter',
  'S70271D Other superficial bite of hip, right hip, subsequent encounter',
  'S70271S Other superficial bite of hip, right hip, sequela',
  'S70272A Other superficial bite of hip, left hip, initial encounter',
  'S70272D Other superficial bite of hip, left hip, subsequent encounter',
  'S70272S Other superficial bite of hip, left hip, sequela',
  'S70279A Other superficial bite of hip, unspecified hip, initial encounter',
  'S70279D Other superficial bite of hip, unspecified hip, subsequent encounter',
  'S70279S Other superficial bite of hip, unspecified hip, sequela',
  'S70311A Abrasion, right thigh, initial encounter',
  'S70311D Abrasion, right thigh, subsequent encounter',
  'S70311S Abrasion, right thigh, sequela',
  'S70312A Abrasion, left thigh, initial encounter',
  'S70312D Abrasion, left thigh, subsequent encounter',
  'S70312S Abrasion, left thigh, sequela',
  'S70319A Abrasion, unspecified thigh, initial encounter',
  'S70319D Abrasion, unspecified thigh, subsequent encounter',
  'S70319S Abrasion, unspecified thigh, sequela',
  'S70321A Blister (nonthermal), right thigh, initial encounter',
  'S70321D Blister (nonthermal), right thigh, subsequent encounter',
  'S70321S Blister (nonthermal), right thigh, sequela',
  'S70322A Blister (nonthermal), left thigh, initial encounter',
  'S70322D Blister (nonthermal), left thigh, subsequent encounter',
  'S70322S Blister (nonthermal), left thigh, sequela',
  'S70329A Blister (nonthermal), unspecified thigh, initial encounter',
  'S70329D Blister (nonthermal), unspecified thigh, subsequent encounter',
  'S70329S Blister (nonthermal), unspecified thigh, sequela',
  'S70341A External constriction, right thigh, initial encounter',
  'S70341D External constriction, right thigh, subsequent encounter',
  'S70341S External constriction, right thigh, sequela',
  'S70342A External constriction, left thigh, initial encounter',
  'S70342D External constriction, left thigh, subsequent encounter',
  'S70342S External constriction, left thigh, sequela',
  'S70349A External constriction, unspecified thigh, initial encounter',
  'S70349D External constriction, unspecified thigh, subsequent encounter',
  'S70349S External constriction, unspecified thigh, sequela',
  'S70351A Superficial foreign body, right thigh, initial encounter',
  'S70351D Superficial foreign body, right thigh, subsequent encounter',
  'S70351S Superficial foreign body, right thigh, sequela',
  'S70352A Superficial foreign body, left thigh, initial encounter',
  'S70352D Superficial foreign body, left thigh, subsequent encounter',
  'S70352S Superficial foreign body, left thigh, sequela',
  'S70359A Superficial foreign body, unspecified thigh, initial encounter',
  'S70359D Superficial foreign body, unspecified thigh, subsequent encounter',
  'S70359S Superficial foreign body, unspecified thigh, sequela',
  'S70361A Insect bite (nonvenomous), right thigh, initial encounter',
  'S70361D Insect bite (nonvenomous), right thigh, subsequent encounter',
  'S70361S Insect bite (nonvenomous), right thigh, sequela',
  'S70362A Insect bite (nonvenomous), left thigh, initial encounter',
  'S70362D Insect bite (nonvenomous), left thigh, subsequent encounter',
  'S70362S Insect bite (nonvenomous), left thigh, sequela',
  'S70369A Insect bite (nonvenomous), unspecified thigh, initial encounter',
  'S70369D Insect bite (nonvenomous), unspecified thigh, subsequent encounter',
  'S70369S Insect bite (nonvenomous), unspecified thigh, sequela',
  'S70371A Other superficial bite of right thigh, initial encounter',
  'S70371D Other superficial bite of right thigh, subsequent encounter',
  'S70371S Other superficial bite of right thigh, sequela',
  'S70372A Other superficial bite of left thigh, initial encounter',
  'S70372D Other superficial bite of left thigh, subsequent encounter',
  'S70372S Other superficial bite of left thigh, sequela',
  'S70379A Other superficial bite of unspecified thigh, initial encounter',
  'S70379D Other superficial bite of unspecified thigh, subsequent encounter',
  'S70379S Other superficial bite of unspecified thigh, sequela',
  'S70911A Unspecified superficial injury of right hip, initial encounter',
  'S70911D Unspecified superficial injury of right hip, subsequent encounter',
  'S70911S Unspecified superficial injury of right hip, sequela',
  'S70912A Unspecified superficial injury of left hip, initial encounter',
  'S70912D Unspecified superficial injury of left hip, subsequent encounter',
  'S70912S Unspecified superficial injury of left hip, sequela',
  'S70919A Unspecified superficial injury of unspecified hip, initial encounter',
  'S70919D Unspecified superficial injury of unspecified hip, subsequent encounter',
  'S70919S Unspecified superficial injury of unspecified hip, sequela',
  'S70921A Unspecified superficial injury of right thigh, initial encounter',
  'S70921D Unspecified superficial injury of right thigh, subsequent encounter',
  'S70921S Unspecified superficial injury of right thigh, sequela',
  'S70922A Unspecified superficial injury of left thigh, initial encounter',
  'S70922D Unspecified superficial injury of left thigh, subsequent encounter',
  'S70922S Unspecified superficial injury of left thigh, sequela',
  'S70929A Unspecified superficial injury of unspecified thigh, initial encounter',
  'S70929D Unspecified superficial injury of unspecified thigh, subsequent encounter',
  'S70929S Unspecified superficial injury of unspecified thigh, sequela',
  'S71001A Unspecified open wound, right hip, initial encounter',
  'S71001D Unspecified open wound, right hip, subsequent encounter',
  'S71001S Unspecified open wound, right hip, sequela',
  'S71002A Unspecified open wound, left hip, initial encounter',
  'S71002D Unspecified open wound, left hip, subsequent encounter',
  'S71002S Unspecified open wound, left hip, sequela',
  'S71009A Unspecified open wound, unspecified hip, initial encounter',
  'S71009D Unspecified open wound, unspecified hip, subsequent encounter',
  'S71009S Unspecified open wound, unspecified hip, sequela',
  'S71011A Laceration without foreign body, right hip, initial encounter',
  'S71011D Laceration without foreign body, right hip, subsequent encounter',
  'S71011S Laceration without foreign body, right hip, sequela',
  'S71012A Laceration without foreign body, left hip, initial encounter',
  'S71012D Laceration without foreign body, left hip, subsequent encounter',
  'S71012S Laceration without foreign body, left hip, sequela',
  'S71019A Laceration without foreign body, unspecified hip, initial encounter',
  'S71019D Laceration without foreign body, unspecified hip, subsequent encounter',
  'S71019S Laceration without foreign body, unspecified hip, sequela',
  'S71021A Laceration with foreign body, right hip, initial encounter',
  'S71021D Laceration with foreign body, right hip, subsequent encounter',
  'S71021S Laceration with foreign body, right hip, sequela',
  'S71022A Laceration with foreign body, left hip, initial encounter',
  'S71022D Laceration with foreign body, left hip, subsequent encounter',
  'S71022S Laceration with foreign body, left hip, sequela',
  'S71029A Laceration with foreign body, unspecified hip, initial encounter',
  'S71029D Laceration with foreign body, unspecified hip, subsequent encounter',
  'S71029S Laceration with foreign body, unspecified hip, sequela',
  'S71031A Puncture wound without foreign body, right hip, initial encounter',
  'S71031D Puncture wound without foreign body, right hip, subsequent encounter',
  'S71031S Puncture wound without foreign body, right hip, sequela',
  'S71032A Puncture wound without foreign body, left hip, initial encounter',
  'S71032D Puncture wound without foreign body, left hip, subsequent encounter',
  'S71032S Puncture wound without foreign body, left hip, sequela',
  'S71039A Puncture wound without foreign body, unspecified hip, initial encounter',
  'S71039D Puncture wound without foreign body, unspecified hip, subsequent encounter',
  'S71039S Puncture wound without foreign body, unspecified hip, sequela',
  'S71041A Puncture wound with foreign body, right hip, initial encounter',
  'S71041D Puncture wound with foreign body, right hip, subsequent encounter',
  'S71041S Puncture wound with foreign body, right hip, sequela',
  'S71042A Puncture wound with foreign body, left hip, initial encounter',
  'S71042D Puncture wound with foreign body, left hip, subsequent encounter',
  'S71042S Puncture wound with foreign body, left hip, sequela',
  'S71049A Puncture wound with foreign body, unspecified hip, initial encounter',
  'S71049D Puncture wound with foreign body, unspecified hip, subsequent encounter',
  'S71049S Puncture wound with foreign body, unspecified hip, sequela',
  'S71051A Open bite, right hip, initial encounter',
  'S71051D Open bite, right hip, subsequent encounter',
  'S71051S Open bite, right hip, sequela',
  'S71052A Open bite, left hip, initial encounter',
  'S71052D Open bite, left hip, subsequent encounter',
  'S71052S Open bite, left hip, sequela',
  'S71059A Open bite, unspecified hip, initial encounter',
  'S71059D Open bite, unspecified hip, subsequent encounter',
  'S71059S Open bite, unspecified hip, sequela',
  'S71101A Unspecified open wound, right thigh, initial encounter',
  'S71101D Unspecified open wound, right thigh, subsequent encounter',
  'S71101S Unspecified open wound, right thigh, sequela',
  'S71102A Unspecified open wound, left thigh, initial encounter',
  'S71102D Unspecified open wound, left thigh, subsequent encounter',
  'S71102S Unspecified open wound, left thigh, sequela',
  'S71109A Unspecified open wound, unspecified thigh, initial encounter',
  'S71109D Unspecified open wound, unspecified thigh, subsequent encounter',
  'S71109S Unspecified open wound, unspecified thigh, sequela',
  'S71111A Laceration without foreign body, right thigh, initial encounter',
  'S71111D Laceration without foreign body, right thigh, subsequent encounter',
  'S71111S Laceration without foreign body, right thigh, sequela',
  'S71112A Laceration without foreign body, left thigh, initial encounter',
  'S71112D Laceration without foreign body, left thigh, subsequent encounter',
  'S71112S Laceration without foreign body, left thigh, sequela',
  'S71119A Laceration without foreign body, unspecified thigh, initial encounter',
  'S71119D Laceration without foreign body, unspecified thigh, subsequent encounter',
  'S71119S Laceration without foreign body, unspecified thigh, sequela',
  'S71121A Laceration with foreign body, right thigh, initial encounter',
  'S71121D Laceration with foreign body, right thigh, subsequent encounter',
  'S71121S Laceration with foreign body, right thigh, sequela',
  'S71122A Laceration with foreign body, left thigh, initial encounter',
  'S71122D Laceration with foreign body, left thigh, subsequent encounter',
  'S71122S Laceration with foreign body, left thigh, sequela',
  'S71129A Laceration with foreign body, unspecified thigh, initial encounter',
  'S71129D Laceration with foreign body, unspecified thigh, subsequent encounter',
  'S71129S Laceration with foreign body, unspecified thigh, sequela',
  'S71131A Puncture wound without foreign body, right thigh, initial encounter',
  'S71131D Puncture wound without foreign body, right thigh, subsequent encounter',
  'S71131S Puncture wound without foreign body, right thigh, sequela',
  'S71132A Puncture wound without foreign body, left thigh, initial encounter',
  'S71132D Puncture wound without foreign body, left thigh, subsequent encounter',
  'S71132S Puncture wound without foreign body, left thigh, sequela',
  'S71139A Puncture wound without foreign body, unspecified thigh, initial encounter',
  'S71139D Puncture wound without foreign body, unspecified thigh, subsequent encounter',
  'S71139S Puncture wound without foreign body, unspecified thigh, sequela',
  'S71141A Puncture wound with foreign body, right thigh, initial encounter',
  'S71141D Puncture wound with foreign body, right thigh, subsequent encounter',
  'S71141S Puncture wound with foreign body, right thigh, sequela',
  'S71142A Puncture wound with foreign body, left thigh, initial encounter',
  'S71142D Puncture wound with foreign body, left thigh, subsequent encounter',
  'S71142S Puncture wound with foreign body, left thigh, sequela',
  'S71149A Puncture wound with foreign body, unspecified thigh, initial encounter',
  'S71149D Puncture wound with foreign body, unspecified thigh, subsequent encounter',
  'S71149S Puncture wound with foreign body, unspecified thigh, sequela',
  'S71151A Open bite, right thigh, initial encounter',
  'S71151D Open bite, right thigh, subsequent encounter',
  'S71151S Open bite, right thigh, sequela',
  'S71152A Open bite, left thigh, initial encounter',
  'S71152D Open bite, left thigh, subsequent encounter',
  'S71152S Open bite, left thigh, sequela',
  'S71159A Open bite, unspecified thigh, initial encounter',
  'S71159D Open bite, unspecified thigh, subsequent encounter',
  'S71159S Open bite, unspecified thigh, sequela',
  'S72001A Fracture of unspecified part of neck of right femur, initial encounter for closed fracture',
  'S72001B Fracture of unspecified part of neck of right femur, initial encounter for open fracture type I or',
  'II',
  'S72001C Fracture of unspecified part of neck of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72001D Fracture of unspecified part of neck of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72001E Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72001F Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72001G Fracture of unspecified part of neck of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72001H Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72001J Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72001K Fracture of unspecified part of neck of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72001M Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72001N Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72001P Fracture of unspecified part of neck of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72001Q Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72001R Fracture of unspecified part of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72001S Fracture of unspecified part of neck of right femur, sequela',
  'S72002A Fracture of unspecified part of neck of left femur, initial encounter for closed fracture',
  'S72002B Fracture of unspecified part of neck of left femur, initial encounter for open fracture type I or II',
  'S72002C Fracture of unspecified part of neck of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72002D Fracture of unspecified part of neck of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72002E Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72002F Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72002G Fracture of unspecified part of neck of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72002H Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72002J Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72002K Fracture of unspecified part of neck of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72002M Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72002N Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72002P Fracture of unspecified part of neck of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72002Q Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72002R Fracture of unspecified part of neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72002S Fracture of unspecified part of neck of left femur, sequela',
  'S72009A Fracture of unspecified part of neck of unspecified femur, initial encounter for closed fracture',
  'S72009B Fracture of unspecified part of neck of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72009C Fracture of unspecified part of neck of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72009D Fracture of unspecified part of neck of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72009E Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72009F Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72009G Fracture of unspecified part of neck of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72009H Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72009J Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72009K Fracture of unspecified part of neck of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72009M Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72009N Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72009P Fracture of unspecified part of neck of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72009Q Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72009R Fracture of unspecified part of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72009S Fracture of unspecified part of neck of unspecified femur, sequela',
  'S72011A Unspecified intracapsular fracture of right femur, initial encounter for closed fracture',
  'S72011B Unspecified intracapsular fracture of right femur, initial encounter for open fracture type I or II',
  'S72011C Unspecified intracapsular fracture of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72011D Unspecified intracapsular fracture of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72011E Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72011F Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72011G Unspecified intracapsular fracture of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72011H Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72011J Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72011K Unspecified intracapsular fracture of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72011M Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72011N Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72011P Unspecified intracapsular fracture of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72011Q Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72011R Unspecified intracapsular fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72011S Unspecified intracapsular fracture of right femur, sequela',
  'S72012A Unspecified intracapsular fracture of left femur, initial encounter for closed fracture',
  'S72012B Unspecified intracapsular fracture of left femur, initial encounter for open fracture type I or II',
  'S72012C Unspecified intracapsular fracture of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72012D Unspecified intracapsular fracture of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72012E Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72012F Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72012G Unspecified intracapsular fracture of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72012H Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72012J Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72012K Unspecified intracapsular fracture of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72012M Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S72012N Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72012P Unspecified intracapsular fracture of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72012Q Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72012R Unspecified intracapsular fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72012S Unspecified intracapsular fracture of left femur, sequela',
  'S72019A Unspecified intracapsular fracture of unspecified femur, initial encounter for closed fracture',
  'S72019B Unspecified intracapsular fracture of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72019C Unspecified intracapsular fracture of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72019D Unspecified intracapsular fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72019E Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72019F Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72019G Unspecified intracapsular fracture of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72019H Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72019J Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72019K Unspecified intracapsular fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72019M Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72019N Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72019P Unspecified intracapsular fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72019Q Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72019R Unspecified intracapsular fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72019S Unspecified intracapsular fracture of unspecified femur, sequela',
  'S72021A Displaced fracture of epiphysis (separation) (upper) of right femur, initial encounter for closed',
  'fracture',
  'S72021B Displaced fracture of epiphysis (separation) (upper) of right femur, initial encounter for open',
  'fracture type I or II',
  'S72021C Displaced fracture of epiphysis (separation) (upper) of right femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72021D Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72021E Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72021F Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72021G Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72021H Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72021J Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72021K Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72021M Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72021N Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72021P Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72021Q Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72021R Displaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72021S Displaced fracture of epiphysis (separation) (upper) of right femur, sequela',
  'S72022A Displaced fracture of epiphysis (separation) (upper) of left femur, initial encounter for closed',
  'fracture',
  'S72022B Displaced fracture of epiphysis (separation) (upper) of left femur, initial encounter for open',
  'fracture type I or II',
  'S72022C Displaced fracture of epiphysis (separation) (upper) of left femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72022D Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72022E Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72022F Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72022G Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72022H Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72022J Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72022K Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72022M Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72022N Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72022P Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72022Q Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72022R Displaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72022S Displaced fracture of epiphysis (separation) (upper) of left femur, sequela',
  'S72023A Displaced fracture of epiphysis (separation) (upper) of unspecified femur, initial encounter for',
  'closed fracture',
  'S72023B Displaced fracture of epiphysis (separation) (upper) of unspecified femur, initial encounter for',
  'open fracture type I or II',
  'S72023C Displaced fracture of epiphysis (separation) (upper) of unspecified femur, initial encounter for',
  'open fracture type IIIA, IIIB, or IIIC',
  'S72023D Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with routine healing',
  'S72023E Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with routine healing',
  'S72023F Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72023G Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with delayed healing',
  'S72023H Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with delayed healing',
  'S72023J Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72023K Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with nonunion',
  'S72023M Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S72023N Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72023P Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with malunion',
  'S72023Q Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with malunion',
  'S72023R Displaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72023S Displaced fracture of epiphysis (separation) (upper) of unspecified femur, sequela',
  'S72024A Nondisplaced fracture of epiphysis (separation) (upper) of right femur, initial encounter for',
  'closed fracture',
  'S72024B Nondisplaced fracture of epiphysis (separation) (upper) of right femur, initial encounter for',
  'open fracture type I or II',
  'S72024C Nondisplaced fracture of epiphysis (separation) (upper) of right femur, initial encounter for',
  'open fracture type IIIA, IIIB, or IIIC',
  'S72024D Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for closed fracture with routine healing',
  'S72024E Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type I or II with routine healing',
  'S72024F Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72024G Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for closed fracture with delayed healing',
  'S72024H Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S72024J Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72024K Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for closed fracture with nonunion',
  'S72024M Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S72024N Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72024P Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for closed fracture with malunion',
  'S72024Q Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S72024R Nondisplaced fracture of epiphysis (separation) (upper) of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72024S Nondisplaced fracture of epiphysis (separation) (upper) of right femur, sequela',
  'S72025A Nondisplaced fracture of epiphysis (separation) (upper) of left femur, initial encounter for',
  'closed fracture',
  'S72025B Nondisplaced fracture of epiphysis (separation) (upper) of left femur, initial encounter for open',
  'fracture type I or II',
  'S72025C Nondisplaced fracture of epiphysis (separation) (upper) of left femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72025D Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for closed fracture with routine healing',
  'S72025E Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for open fracture type I or II with routine healing',
  'S72025F Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72025G Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for closed fracture with delayed healing',
  'S72025H Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S72025J Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72025K Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for closed fracture with nonunion',
  'S72025M Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S72025N Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72025P Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for closed fracture with malunion',
  'S72025Q Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S72025R Nondisplaced fracture of epiphysis (separation) (upper) of left femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72025S Nondisplaced fracture of epiphysis (separation) (upper) of left femur, sequela',
  'S72026A Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, initial encounter',
  'for closed fracture',
  'S72026B Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, initial encounter',
  'for open fracture type I or II',
  'S72026C Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, initial encounter',
  'for open fracture type IIIA, IIIB, or IIIC',
  'S72026D Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with routine healing',
  'S72026E Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with routine healing',
  'S72026F Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72026G Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with delayed healing',
  'S72026H Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with delayed healing',
  'S72026J Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72026K Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with nonunion',
  'S72026M Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S72026N Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72026P Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for closed fracture with malunion',
  'S72026Q Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with malunion',
  'S72026R Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72026S Nondisplaced fracture of epiphysis (separation) (upper) of unspecified femur, sequela',
  'S72031A Displaced midcervical fracture of right femur, initial encounter for closed fracture',
  'S72031B Displaced midcervical fracture of right femur, initial encounter for open fracture type I or II',
  'S72031C Displaced midcervical fracture of right femur, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S72031D Displaced midcervical fracture of right femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72031E Displaced midcervical fracture of right femur, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S72031F Displaced midcervical fracture of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72031G Displaced midcervical fracture of right femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72031H Displaced midcervical fracture of right femur, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S72031J Displaced midcervical fracture of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72031K Displaced midcervical fracture of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72031M Displaced midcervical fracture of right femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72031N Displaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72031P Displaced midcervical fracture of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72031Q Displaced midcervical fracture of right femur, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S72031R Displaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72031S Displaced midcervical fracture of right femur, sequela',
  'S72032A Displaced midcervical fracture of left femur, initial encounter for closed fracture',
  'S72032B Displaced midcervical fracture of left femur, initial encounter for open fracture type I or II',
  'S72032C Displaced midcervical fracture of left femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72032D Displaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72032E Displaced midcervical fracture of left femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72032F Displaced midcervical fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72032G Displaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72032H Displaced midcervical fracture of left femur, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S72032J Displaced midcervical fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72032K Displaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72032M Displaced midcervical fracture of left femur, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S72032N Displaced midcervical fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72032P Displaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72032Q Displaced midcervical fracture of left femur, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S72032R Displaced midcervical fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72032S Displaced midcervical fracture of left femur, sequela',
  'S72033A Displaced midcervical fracture of unspecified femur, initial encounter for closed fracture',
  'S72033B Displaced midcervical fracture of unspecified femur, initial encounter for open fracture type I',
  'or II',
  'S72033C Displaced midcervical fracture of unspecified femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72033D Displaced midcervical fracture of unspecified femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72033E Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72033F Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72033G Displaced midcervical fracture of unspecified femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72033H Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72033J Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72033K Displaced midcervical fracture of unspecified femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72033M Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72033N Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72033P Displaced midcervical fracture of unspecified femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72033Q Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72033R Displaced midcervical fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72033S Displaced midcervical fracture of unspecified femur, sequela',
  'S72034A Nondisplaced midcervical fracture of right femur, initial encounter for closed fracture',
  'S72034B Nondisplaced midcervical fracture of right femur, initial encounter for open fracture type I or II',
  'S72034C Nondisplaced midcervical fracture of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72034D Nondisplaced midcervical fracture of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72034E Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72034F Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72034G Nondisplaced midcervical fracture of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72034H Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72034J Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72034K Nondisplaced midcervical fracture of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72034M Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72034N Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72034P Nondisplaced midcervical fracture of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72034Q Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72034R Nondisplaced midcervical fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72034S Nondisplaced midcervical fracture of right femur, sequela',
  'S72035A Nondisplaced midcervical fracture of left femur, initial encounter for closed fracture',
  'S72035B Nondisplaced midcervical fracture of left femur, initial encounter for open fracture type I or II',
  'S72035C Nondisplaced midcervical fracture of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72035D Nondisplaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72035E Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72035F Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72035G Nondisplaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72035H Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72035J Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72035K Nondisplaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72035M Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S72035N Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72035P Nondisplaced midcervical fracture of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72035Q Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72035R Nondisplaced midcervical fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72035S Nondisplaced midcervical fracture of left femur, sequela',
  'S72036A Nondisplaced midcervical fracture of unspecified femur, initial encounter for closed fracture',
  'S72036B Nondisplaced midcervical fracture of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72036C Nondisplaced midcervical fracture of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72036D Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72036E Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72036F Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72036G Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72036H Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72036J Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72036K Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72036M Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72036N Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72036P Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72036Q Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72036R Nondisplaced midcervical fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72036S Nondisplaced midcervical fracture of unspecified femur, sequela',
  'S72041A Displaced fracture of base of neck of right femur, initial encounter for closed fracture',
  'S72041B Displaced fracture of base of neck of right femur, initial encounter for open fracture type I or II',
  'S72041C Displaced fracture of base of neck of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72041D Displaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72041E Displaced fracture of base of neck of right femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72041F Displaced fracture of base of neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72041G Displaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72041H Displaced fracture of base of neck of right femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72041J Displaced fracture of base of neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72041K Displaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72041M Displaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72041N Displaced fracture of base of neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72041P Displaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72041Q Displaced fracture of base of neck of right femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72041R Displaced fracture of base of neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72041S Displaced fracture of base of neck of right femur, sequela',
  'S72042A Displaced fracture of base of neck of left femur, initial encounter for closed fracture',
  'S72042B Displaced fracture of base of neck of left femur, initial encounter for open fracture type I or II',
  'S72042C Displaced fracture of base of neck of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72042D Displaced fracture of base of neck of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72042E Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72042F Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72042G Displaced fracture of base of neck of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72042H Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72042J Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72042K Displaced fracture of base of neck of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72042M Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S72042N Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72042P Displaced fracture of base of neck of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72042Q Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72042R Displaced fracture of base of neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72042S Displaced fracture of base of neck of left femur, sequela',
  'S72043A Displaced fracture of base of neck of unspecified femur, initial encounter for closed fracture',
  'S72043B Displaced fracture of base of neck of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72043C Displaced fracture of base of neck of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72043D Displaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72043E Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72043F Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72043G Displaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72043H Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72043J Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72043K Displaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72043M Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72043N Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72043P Displaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72043Q Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72043R Displaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72043S Displaced fracture of base of neck of unspecified femur, sequela',
  'S72044A Nondisplaced fracture of base of neck of right femur, initial encounter for closed fracture',
  'S72044B Nondisplaced fracture of base of neck of right femur, initial encounter for open fracture type I',
  'or II',
  'S72044C Nondisplaced fracture of base of neck of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72044D Nondisplaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72044E Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72044F Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72044G Nondisplaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72044H Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72044J Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72044K Nondisplaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72044M Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72044N Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72044P Nondisplaced fracture of base of neck of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72044Q Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72044R Nondisplaced fracture of base of neck of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72044S Nondisplaced fracture of base of neck of right femur, sequela',
  'S72045A Nondisplaced fracture of base of neck of left femur, initial encounter for closed fracture',
  'S72045B Nondisplaced fracture of base of neck of left femur, initial encounter for open fracture type I or',
  'II',
  'S72045C Nondisplaced fracture of base of neck of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72045D Nondisplaced fracture of base of neck of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72045E Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72045F Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72045G Nondisplaced fracture of base of neck of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72045H Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72045J Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72045K Nondisplaced fracture of base of neck of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72045M Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72045N Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72045P Nondisplaced fracture of base of neck of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72045Q Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72045R Nondisplaced fracture of base of neck of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72045S Nondisplaced fracture of base of neck of left femur, sequela',
  'S72046A Nondisplaced fracture of base of neck of unspecified femur, initial encounter for closed',
  'fracture',
  'S72046B Nondisplaced fracture of base of neck of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72046C Nondisplaced fracture of base of neck of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72046D Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72046E Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72046F Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72046G Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72046H Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72046J Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72046K Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72046M Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72046N Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72046P Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72046Q Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72046R Nondisplaced fracture of base of neck of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72046S Nondisplaced fracture of base of neck of unspecified femur, sequela',
  'S72051A Unspecified fracture of head of right femur, initial encounter for closed fracture',
  'S72051B Unspecified fracture of head of right femur, initial encounter for open fracture type I or II',
  'S72051C Unspecified fracture of head of right femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72051D Unspecified fracture of head of right femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72051E Unspecified fracture of head of right femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72051F Unspecified fracture of head of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72051G Unspecified fracture of head of right femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72051H Unspecified fracture of head of right femur, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S72051J Unspecified fracture of head of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72051K Unspecified fracture of head of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72051M Unspecified fracture of head of right femur, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S72051N Unspecified fracture of head of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72051P Unspecified fracture of head of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72051Q Unspecified fracture of head of right femur, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S72051R Unspecified fracture of head of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72051S Unspecified fracture of head of right femur, sequela',
  'S72052A Unspecified fracture of head of left femur, initial encounter for closed fracture',
  'S72052B Unspecified fracture of head of left femur, initial encounter for open fracture type I or II',
  'S72052C Unspecified fracture of head of left femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72052D Unspecified fracture of head of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72052E Unspecified fracture of head of left femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72052F Unspecified fracture of head of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72052G Unspecified fracture of head of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72052H Unspecified fracture of head of left femur, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S72052J Unspecified fracture of head of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72052K Unspecified fracture of head of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72052M Unspecified fracture of head of left femur, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S72052N Unspecified fracture of head of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72052P Unspecified fracture of head of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72052Q Unspecified fracture of head of left femur, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S72052R Unspecified fracture of head of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72052S Unspecified fracture of head of left femur, sequela',
  'S72059A Unspecified fracture of head of unspecified femur, initial encounter for closed fracture',
  'S72059B Unspecified fracture of head of unspecified femur, initial encounter for open fracture type I or',
  'II',
  'S72059C Unspecified fracture of head of unspecified femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72059D Unspecified fracture of head of unspecified femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72059E Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72059F Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72059G Unspecified fracture of head of unspecified femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72059H Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72059J Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72059K Unspecified fracture of head of unspecified femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72059M Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72059N Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72059P Unspecified fracture of head of unspecified femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72059Q Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72059R Unspecified fracture of head of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72059S Unspecified fracture of head of unspecified femur, sequela',
  'S72061A Displaced articular fracture of head of right femur, initial encounter for closed fracture',
  'S72061B Displaced articular fracture of head of right femur, initial encounter for open fracture type I or',
  'II',
  'S72061C Displaced articular fracture of head of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72061D Displaced articular fracture of head of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72061E Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72061F Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72061G Displaced articular fracture of head of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72061H Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72061J Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72061K Displaced articular fracture of head of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72061M Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72061N Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72061P Displaced articular fracture of head of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72061Q Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72061R Displaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72061S Displaced articular fracture of head of right femur, sequela',
  'S72062A Displaced articular fracture of head of left femur, initial encounter for closed fracture',
  'S72062B Displaced articular fracture of head of left femur, initial encounter for open fracture type I or II',
  'S72062C Displaced articular fracture of head of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72062D Displaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72062E Displaced articular fracture of head of left femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72062F Displaced articular fracture of head of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72062G Displaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72062H Displaced articular fracture of head of left femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72062J Displaced articular fracture of head of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72062K Displaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72062M Displaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72062N Displaced articular fracture of head of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72062P Displaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72062Q Displaced articular fracture of head of left femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72062R Displaced articular fracture of head of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72062S Displaced articular fracture of head of left femur, sequela',
  'S72063A Displaced articular fracture of head of unspecified femur, initial encounter for closed fracture',
  'S72063B Displaced articular fracture of head of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72063C Displaced articular fracture of head of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72063D Displaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72063E Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72063F Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72063G Displaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72063H Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72063J Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72063K Displaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72063M Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72063N Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72063P Displaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72063Q Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72063R Displaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72063S Displaced articular fracture of head of unspecified femur, sequela',
  'S72064A Nondisplaced articular fracture of head of right femur, initial encounter for closed fracture',
  'S72064B Nondisplaced articular fracture of head of right femur, initial encounter for open fracture type I',
  'or II',
  'S72064C Nondisplaced articular fracture of head of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72064D Nondisplaced articular fracture of head of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72064E Nondisplaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72064F Nondisplaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72064G Nondisplaced articular fracture of head of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72064H Nondisplaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72064J Nondisplaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72064K Nondisplaced articular fracture of head of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72064M Nondisplaced articular fracture of head of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72064N Nondisplaced articular fracture of head of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72064P Nondisplaced articular fracture of head of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72064Q Nondisplaced articular fracture of head of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72064R Nondisplaced articular fracture of head of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72064S Nondisplaced articular fracture of head of right femur, sequela',
  'S72065A Nondisplaced articular fracture of head of left femur, initial encounter for closed fracture',
  'S72065B Nondisplaced articular fracture of head of left femur, initial encounter for open fracture type I',
  'or II',
  'S72065C Nondisplaced articular fracture of head of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72065D Nondisplaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72065E Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72065F Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72065G Nondisplaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72065H Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72065J Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72065K Nondisplaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72065M Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72065N Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72065P Nondisplaced articular fracture of head of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72065Q Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72065R Nondisplaced articular fracture of head of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72065S Nondisplaced articular fracture of head of left femur, sequela',
  'S72066A Nondisplaced articular fracture of head of unspecified femur, initial encounter for closed',
  'fracture',
  'S72066B Nondisplaced articular fracture of head of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72066C Nondisplaced articular fracture of head of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72066D Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72066E Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72066F Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72066G Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72066H Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72066J Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72066K Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72066M Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72066N Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72066P Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72066Q Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72066R Nondisplaced articular fracture of head of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72066S Nondisplaced articular fracture of head of unspecified femur, sequela',
  'S72091A Other fracture of head and neck of right femur, initial encounter for closed fracture',
  'S72091B Other fracture of head and neck of right femur, initial encounter for open fracture type I or II',
  'S72091C Other fracture of head and neck of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72091D Other fracture of head and neck of right femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72091E Other fracture of head and neck of right femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72091F Other fracture of head and neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72091G Other fracture of head and neck of right femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72091H Other fracture of head and neck of right femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72091J Other fracture of head and neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72091K Other fracture of head and neck of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72091M Other fracture of head and neck of right femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72091N Other fracture of head and neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72091P Other fracture of head and neck of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72091Q Other fracture of head and neck of right femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72091R Other fracture of head and neck of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72091S Other fracture of head and neck of right femur, sequela',
  'S72092A Other fracture of head and neck of left femur, initial encounter for closed fracture',
  'S72092B Other fracture of head and neck of left femur, initial encounter for open fracture type I or II',
  'S72092C Other fracture of head and neck of left femur, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S72092D Other fracture of head and neck of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72092E Other fracture of head and neck of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72092F Other fracture of head and neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72092G Other fracture of head and neck of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72092H Other fracture of head and neck of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72092J Other fracture of head and neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72092K Other fracture of head and neck of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72092M Other fracture of head and neck of left femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72092N Other fracture of head and neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72092P Other fracture of head and neck of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72092Q Other fracture of head and neck of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72092R Other fracture of head and neck of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72092S Other fracture of head and neck of left femur, sequela',
  'S72099A Other fracture of head and neck of unspecified femur, initial encounter for closed fracture',
  'S72099B Other fracture of head and neck of unspecified femur, initial encounter for open fracture type I',
  'or II',
  'S72099C Other fracture of head and neck of unspecified femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72099D Other fracture of head and neck of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72099E Other fracture of head and neck of unspecified femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72099F Other fracture of head and neck of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72099G Other fracture of head and neck of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72099H Other fracture of head and neck of unspecified femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72099J Other fracture of head and neck of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72099K Other fracture of head and neck of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72099M Other fracture of head and neck of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72099N Other fracture of head and neck of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72099P Other fracture of head and neck of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72099Q Other fracture of head and neck of unspecified femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72099R Other fracture of head and neck of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72099S Other fracture of head and neck of unspecified femur, sequela',
  'S72101A Unspecified trochanteric fracture of right femur, initial encounter for closed fracture',
  'S72101B Unspecified trochanteric fracture of right femur, initial encounter for open fracture type I or II',
  'S72101C Unspecified trochanteric fracture of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72101D Unspecified trochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72101E Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72101F Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72101G Unspecified trochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72101H Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72101J Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72101K Unspecified trochanteric fracture of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72101M Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S72101N Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72101P Unspecified trochanteric fracture of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72101Q Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72101R Unspecified trochanteric fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72101S Unspecified trochanteric fracture of right femur, sequela',
  'S72102A Unspecified trochanteric fracture of left femur, initial encounter for closed fracture',
  'S72102B Unspecified trochanteric fracture of left femur, initial encounter for open fracture type I or II',
  'S72102C Unspecified trochanteric fracture of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72102D Unspecified trochanteric fracture of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72102E Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72102F Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72102G Unspecified trochanteric fracture of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72102H Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72102J Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72102K Unspecified trochanteric fracture of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72102M Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72102N Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72102P Unspecified trochanteric fracture of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72102Q Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72102R Unspecified trochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72102S Unspecified trochanteric fracture of left femur, sequela',
  'S72109A Unspecified trochanteric fracture of unspecified femur, initial encounter for closed fracture',
  'S72109B Unspecified trochanteric fracture of unspecified femur, initial encounter for open fracture type',
  'I or II',
  'S72109C Unspecified trochanteric fracture of unspecified femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72109D Unspecified trochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72109E Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72109F Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72109G Unspecified trochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72109H Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72109J Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72109K Unspecified trochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72109M Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72109N Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72109P Unspecified trochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72109Q Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72109R Unspecified trochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72109S Unspecified trochanteric fracture of unspecified femur, sequela',
  'S72111A Displaced fracture of greater trochanter of right femur, initial encounter for closed fracture',
  'S72111B Displaced fracture of greater trochanter of right femur, initial encounter for open fracture type',
  'I or II',
  'S72111C Displaced fracture of greater trochanter of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72111D Displaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72111E Displaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72111F Displaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72111G Displaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72111H Displaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72111J Displaced fracture of greater trochanter of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72111K Displaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72111M Displaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72111N Displaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72111P Displaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72111Q Displaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72111R Displaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72111S Displaced fracture of greater trochanter of right femur, sequela',
  'S72112A Displaced fracture of greater trochanter of left femur, initial encounter for closed fracture',
  'S72112B Displaced fracture of greater trochanter of left femur, initial encounter for open fracture type I',
  'or II',
  'S72112C Displaced fracture of greater trochanter of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72112D Displaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72112E Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72112F Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72112G Displaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72112H Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72112J Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72112K Displaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72112M Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72112N Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72112P Displaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72112Q Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72112R Displaced fracture of greater trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72112S Displaced fracture of greater trochanter of left femur, sequela',
  'S72113A Displaced fracture of greater trochanter of unspecified femur, initial encounter for closed',
  'fracture',
  'S72113B Displaced fracture of greater trochanter of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72113C Displaced fracture of greater trochanter of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72113D Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72113E Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72113F Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72113G Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72113H Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72113J Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72113K Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72113M Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72113N Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72113P Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72113Q Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72113R Displaced fracture of greater trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72113S Displaced fracture of greater trochanter of unspecified femur, sequela',
  'S72114A Nondisplaced fracture of greater trochanter of right femur, initial encounter for closed fracture',
  'S72114B Nondisplaced fracture of greater trochanter of right femur, initial encounter for open fracture',
  'type I or II',
  'S72114C Nondisplaced fracture of greater trochanter of right femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72114D Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72114E Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72114F Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72114G Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72114H Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72114J Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72114K Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72114M Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72114N Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72114P Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72114Q Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72114R Nondisplaced fracture of greater trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72114S Nondisplaced fracture of greater trochanter of right femur, sequela',
  'S72115A Nondisplaced fracture of greater trochanter of left femur, initial encounter for closed fracture',
  'S72115B Nondisplaced fracture of greater trochanter of left femur, initial encounter for open fracture',
  'type I or II',
  'S72115C Nondisplaced fracture of greater trochanter of left femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72115D Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72115E Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72115F Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72115G Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72115H Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72115J Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72115K Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72115M Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72115N Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72115P Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72115Q Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72115R Nondisplaced fracture of greater trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72115S Nondisplaced fracture of greater trochanter of left femur, sequela',
  'S72116A Nondisplaced fracture of greater trochanter of unspecified femur, initial encounter for closed',
  'fracture',
  'S72116B Nondisplaced fracture of greater trochanter of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72116C Nondisplaced fracture of greater trochanter of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72116D Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72116E Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72116F Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72116G Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72116H Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72116J Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72116K Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72116M Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72116N Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72116P Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72116Q Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72116R Nondisplaced fracture of greater trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72116S Nondisplaced fracture of greater trochanter of unspecified femur, sequela',
  'S72121A Displaced fracture of lesser trochanter of right femur, initial encounter for closed fracture',
  'S72121B Displaced fracture of lesser trochanter of right femur, initial encounter for open fracture type I',
  'or II',
  'S72121C Displaced fracture of lesser trochanter of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72121D Displaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72121E Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72121F Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72121G Displaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72121H Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72121J Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72121K Displaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72121M Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72121N Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72121P Displaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72121Q Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72121R Displaced fracture of lesser trochanter of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72121S Displaced fracture of lesser trochanter of right femur, sequela',
  'S72122A Displaced fracture of lesser trochanter of left femur, initial encounter for closed fracture',
  'S72122B Displaced fracture of lesser trochanter of left femur, initial encounter for open fracture type I',
  'or II',
  'S72122C Displaced fracture of lesser trochanter of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72122D Displaced fracture of lesser trochanter of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72122E Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72122F Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72122G Displaced fracture of lesser trochanter of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72122H Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72122J Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72122K Displaced fracture of lesser trochanter of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72122M Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72122N Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72122P Displaced fracture of lesser trochanter of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72122Q Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72122R Displaced fracture of lesser trochanter of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72122S Displaced fracture of lesser trochanter of left femur, sequela',
  'S72123A Displaced fracture of lesser trochanter of unspecified femur, initial encounter for closed',
  'fracture',
  'S72123B Displaced fracture of lesser trochanter of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72123C Displaced fracture of lesser trochanter of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72123D Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72123E Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72123F Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72123G Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72123H Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72123J Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72123K Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72123M Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72123N Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72123P Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72123Q Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72123R Displaced fracture of lesser trochanter of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72123S Displaced fracture of lesser trochanter of unspecified femur, sequela',
  'S72124A Nondisplaced fracture of lesser trochanter of right femur, initial encounter for closed fracture',
  'S72124B Nondisplaced fracture of lesser trochanter of right femur, initial encounter for open fracture',
  'type I or II',
  'S72124C Nondisplaced fracture of lesser trochanter of right femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72124D Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72124E Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72124F Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72124G Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72124H Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72124J Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72124K Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72124M Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72124N Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72124P Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72124Q Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72124R Nondisplaced fracture of lesser trochanter of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72124S Nondisplaced fracture of lesser trochanter of right femur, sequela',
  'S72125A Nondisplaced fracture of lesser trochanter of left femur, initial encounter for closed fracture',
  'S72125B Nondisplaced fracture of lesser trochanter of left femur, initial encounter for open fracture',
  'type I or II',
  'S72125C Nondisplaced fracture of lesser trochanter of left femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72125D Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72125E Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72125F Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72125G Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72125H Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72125J Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72125K Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72125M Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72125N Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72125P Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72125Q Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72125R Nondisplaced fracture of lesser trochanter of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72125S Nondisplaced fracture of lesser trochanter of left femur, sequela',
  'S72126A Nondisplaced fracture of lesser trochanter of unspecified femur, initial encounter for closed',
  'fracture',
  'S72126B Nondisplaced fracture of lesser trochanter of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72126C Nondisplaced fracture of lesser trochanter of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72126D Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72126E Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72126F Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72126G Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72126H Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72126J Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72126K Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72126M Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72126N Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72126P Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72126Q Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72126R Nondisplaced fracture of lesser trochanter of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72126S Nondisplaced fracture of lesser trochanter of unspecified femur, sequela',
  'S72131A Displaced apophyseal fracture of right femur, initial encounter for closed fracture',
  'S72131B Displaced apophyseal fracture of right femur, initial encounter for open fracture type I or II',
  'S72131C Displaced apophyseal fracture of right femur, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S72131D Displaced apophyseal fracture of right femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72131E Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S72131F Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72131G Displaced apophyseal fracture of right femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72131H Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S72131J Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72131K Displaced apophyseal fracture of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72131M Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72131N Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72131P Displaced apophyseal fracture of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72131Q Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S72131R Displaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72131S Displaced apophyseal fracture of right femur, sequela',
  'S72132A Displaced apophyseal fracture of left femur, initial encounter for closed fracture',
  'S72132B Displaced apophyseal fracture of left femur, initial encounter for open fracture type I or II',
  'S72132C Displaced apophyseal fracture of left femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72132D Displaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72132E Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72132F Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72132G Displaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72132H Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S72132J Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72132K Displaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72132M Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S72132N Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72132P Displaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72132Q Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S72132R Displaced apophyseal fracture of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72132S Displaced apophyseal fracture of left femur, sequela',
  'S72133A Displaced apophyseal fracture of unspecified femur, initial encounter for closed fracture',
  'S72133B Displaced apophyseal fracture of unspecified femur, initial encounter for open fracture type I',
  'or II',
  'S72133C Displaced apophyseal fracture of unspecified femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72133D Displaced apophyseal fracture of unspecified femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72133E Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72133F Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72133G Displaced apophyseal fracture of unspecified femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72133H Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72133J Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72133K Displaced apophyseal fracture of unspecified femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72133M Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72133N Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72133P Displaced apophyseal fracture of unspecified femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72133Q Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72133R Displaced apophyseal fracture of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72133S Displaced apophyseal fracture of unspecified femur, sequela',
  'S72134A Nondisplaced apophyseal fracture of right femur, initial encounter for closed fracture',
  'S72134B Nondisplaced apophyseal fracture of right femur, initial encounter for open fracture type I or II',
  'S72134C Nondisplaced apophyseal fracture of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72134D Nondisplaced apophyseal fracture of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72134E Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72134F Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72134G Nondisplaced apophyseal fracture of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72134H Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72134J Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72134K Nondisplaced apophyseal fracture of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72134M Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72134N Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72134P Nondisplaced apophyseal fracture of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72134Q Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72134R Nondisplaced apophyseal fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72134S Nondisplaced apophyseal fracture of right femur, sequela',
  'S72135A Nondisplaced apophyseal fracture of left femur, initial encounter for closed fracture',
  'S72135B Nondisplaced apophyseal fracture of left femur, initial encounter for open fracture type I or II',
  'S72135C Nondisplaced apophyseal fracture of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72135D Nondisplaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72135E Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72135F Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72135G Nondisplaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72135H Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72135J Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72135K Nondisplaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72135M Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S72135N Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72135P Nondisplaced apophyseal fracture of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72135Q Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72135R Nondisplaced apophyseal fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72135S Nondisplaced apophyseal fracture of left femur, sequela',
  'S72136A Nondisplaced apophyseal fracture of unspecified femur, initial encounter for closed fracture',
  'S72136B Nondisplaced apophyseal fracture of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72136C Nondisplaced apophyseal fracture of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72136D Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72136E Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72136F Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72136G Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72136H Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72136J Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72136K Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72136M Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72136N Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72136P Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72136Q Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72136R Nondisplaced apophyseal fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72136S Nondisplaced apophyseal fracture of unspecified femur, sequela',
  'S72141A Displaced intertrochanteric fracture of right femur, initial encounter for closed fracture',
  'S72141B Displaced intertrochanteric fracture of right femur, initial encounter for open fracture type I or',
  'II',
  'S72141C Displaced intertrochanteric fracture of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72141D Displaced intertrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72141E Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72141F Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72141G Displaced intertrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72141H Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72141J Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72141K Displaced intertrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72141M Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72141N Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72141P Displaced intertrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72141Q Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72141R Displaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72141S Displaced intertrochanteric fracture of right femur, sequela',
  'S72142A Displaced intertrochanteric fracture of left femur, initial encounter for closed fracture',
  'S72142B Displaced intertrochanteric fracture of left femur, initial encounter for open fracture type I or II',
  'S72142C Displaced intertrochanteric fracture of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72142D Displaced intertrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72142E Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72142F Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72142G Displaced intertrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72142H Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72142J Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72142K Displaced intertrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72142M Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72142N Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72142P Displaced intertrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72142Q Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72142R Displaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72142S Displaced intertrochanteric fracture of left femur, sequela',
  'S72143A Displaced intertrochanteric fracture of unspecified femur, initial encounter for closed fracture',
  'S72143B Displaced intertrochanteric fracture of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72143C Displaced intertrochanteric fracture of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72143D Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72143E Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72143F Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72143G Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72143H Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72143J Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72143K Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72143M Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72143N Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72143P Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72143Q Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72143R Displaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72143S Displaced intertrochanteric fracture of unspecified femur, sequela',
  'S72144A Nondisplaced intertrochanteric fracture of right femur, initial encounter for closed fracture',
  'S72144B Nondisplaced intertrochanteric fracture of right femur, initial encounter for open fracture type',
  'I or II',
  'S72144C Nondisplaced intertrochanteric fracture of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72144D Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72144E Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72144F Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72144G Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72144H Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72144J Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72144K Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72144M Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72144N Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72144P Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72144Q Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72144R Nondisplaced intertrochanteric fracture of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72144S Nondisplaced intertrochanteric fracture of right femur, sequela',
  'S72145A Nondisplaced intertrochanteric fracture of left femur, initial encounter for closed fracture',
  'S72145B Nondisplaced intertrochanteric fracture of left femur, initial encounter for open fracture type I',
  'or II',
  'S72145C Nondisplaced intertrochanteric fracture of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72145D Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72145E Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72145F Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72145G Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72145H Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72145J Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72145K Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72145M Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72145N Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72145P Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72145Q Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72145R Nondisplaced intertrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72145S Nondisplaced intertrochanteric fracture of left femur, sequela',
  'S72146A Nondisplaced intertrochanteric fracture of unspecified femur, initial encounter for closed',
  'fracture',
  'S72146B Nondisplaced intertrochanteric fracture of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72146C Nondisplaced intertrochanteric fracture of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72146D Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72146E Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72146F Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72146G Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72146H Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72146J Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72146K Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72146M Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72146N Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72146P Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72146Q Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72146R Nondisplaced intertrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72146S Nondisplaced intertrochanteric fracture of unspecified femur, sequela',
  'S7221XA Displaced subtrochanteric fracture of right femur, initial encounter for closed fracture',
  'S7221XB Displaced subtrochanteric fracture of right femur, initial encounter for open fracture type I or II',
  'S7221XC Displaced subtrochanteric fracture of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S7221XD Displaced subtrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S7221XE Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S7221XF Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S7221XG Displaced subtrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S7221XH Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S7221XJ Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S7221XK Displaced subtrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S7221XM Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S7221XN Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S7221XP Displaced subtrochanteric fracture of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S7221XQ Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S7221XR Displaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S7221XS Displaced subtrochanteric fracture of right femur, sequela',
  'S7222XA Displaced subtrochanteric fracture of left femur, initial encounter for closed fracture',
  'S7222XB Displaced subtrochanteric fracture of left femur, initial encounter for open fracture type I or II',
  'S7222XC Displaced subtrochanteric fracture of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S7222XD Displaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S7222XE Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S7222XF Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S7222XG Displaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S7222XH Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S7222XJ Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S7222XK Displaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S7222XM Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S7222XN Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S7222XP Displaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S7222XQ Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S7222XR Displaced subtrochanteric fracture of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S7222XS Displaced subtrochanteric fracture of left femur, sequela',
  'S7223XA Displaced subtrochanteric fracture of unspecified femur, initial encounter for closed fracture',
  'S7223XB Displaced subtrochanteric fracture of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S7223XC Displaced subtrochanteric fracture of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S7223XD Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S7223XE Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S7223XF Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S7223XG Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S7223XH Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S7223XJ Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S7223XK Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S7223XM Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S7223XN Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S7223XP Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S7223XQ Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S7223XR Displaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S7223XS Displaced subtrochanteric fracture of unspecified femur, sequela',
  'S7224XA Nondisplaced subtrochanteric fracture of right femur, initial encounter for closed fracture',
  'S7224XB Nondisplaced subtrochanteric fracture of right femur, initial encounter for open fracture type I',
  'or II',
  'S7224XC Nondisplaced subtrochanteric fracture of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S7224XD Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S7224XE Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S7224XF Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S7224XG Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S7224XH Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S7224XJ Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S7224XK Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S7224XM Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S7224XN Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S7224XP Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S7224XQ Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S7224XR Nondisplaced subtrochanteric fracture of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S7224XS Nondisplaced subtrochanteric fracture of right femur, sequela',
  'S7225XA Nondisplaced subtrochanteric fracture of left femur, initial encounter for closed fracture',
  'S7225XB Nondisplaced subtrochanteric fracture of left femur, initial encounter for open fracture type I',
  'or II',
  'S7225XC Nondisplaced subtrochanteric fracture of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S7225XD Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S7225XE Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S7225XF Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S7225XG Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S7225XH Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S7225XJ Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S7225XK Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S7225XM Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S7225XN Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S7225XP Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S7225XQ Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S7225XR Nondisplaced subtrochanteric fracture of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S7225XS Nondisplaced subtrochanteric fracture of left femur, sequela',
  'S7226XA Nondisplaced subtrochanteric fracture of unspecified femur, initial encounter for closed',
  'fracture',
  'S7226XB Nondisplaced subtrochanteric fracture of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S7226XC Nondisplaced subtrochanteric fracture of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S7226XD Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S7226XE Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S7226XF Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S7226XG Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S7226XH Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S7226XJ Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S7226XK Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S7226XM Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S7226XN Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S7226XP Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S7226XQ Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S7226XR Nondisplaced subtrochanteric fracture of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S7226XS Nondisplaced subtrochanteric fracture of unspecified femur, sequela',
  'S72301A Unspecified fracture of shaft of right femur, initial encounter for closed fracture',
  'S72301B Unspecified fracture of shaft of right femur, initial encounter for open fracture type I or II',
  'S72301C Unspecified fracture of shaft of right femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72301D Unspecified fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72301E Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72301F Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72301G Unspecified fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72301H Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S72301J Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72301K Unspecified fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72301M Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S72301N Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72301P Unspecified fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72301Q Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S72301R Unspecified fracture of shaft of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72301S Unspecified fracture of shaft of right femur, sequela',
  'S72302A Unspecified fracture of shaft of left femur, initial encounter for closed fracture',
  'S72302B Unspecified fracture of shaft of left femur, initial encounter for open fracture type I or II',
  'S72302C Unspecified fracture of shaft of left femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72302D Unspecified fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72302E Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72302F Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72302G Unspecified fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72302H Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S72302J Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72302K Unspecified fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72302M Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S72302N Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72302P Unspecified fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72302Q Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S72302R Unspecified fracture of shaft of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72302S Unspecified fracture of shaft of left femur, sequela',
  'S72309A Unspecified fracture of shaft of unspecified femur, initial encounter for closed fracture',
  'S72309B Unspecified fracture of shaft of unspecified femur, initial encounter for open fracture type I or',
  'II',
  'S72309C Unspecified fracture of shaft of unspecified femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72309D Unspecified fracture of shaft of unspecified femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72309E Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72309F Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72309G Unspecified fracture of shaft of unspecified femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72309H Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72309J Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72309K Unspecified fracture of shaft of unspecified femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72309M Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72309N Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72309P Unspecified fracture of shaft of unspecified femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72309Q Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72309R Unspecified fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72309S Unspecified fracture of shaft of unspecified femur, sequela',
  'S72321A Displaced transverse fracture of shaft of right femur, initial encounter for closed fracture',
  'S72321B Displaced transverse fracture of shaft of right femur, initial encounter for open fracture type I',
  'or II',
  'S72321C Displaced transverse fracture of shaft of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72321D Displaced transverse fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72321E Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72321F Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72321G Displaced transverse fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72321H Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72321J Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72321K Displaced transverse fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72321M Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72321N Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72321P Displaced transverse fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72321Q Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72321R Displaced transverse fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72321S Displaced transverse fracture of shaft of right femur, sequela',
  'S72322A Displaced transverse fracture of shaft of left femur, initial encounter for closed fracture',
  'S72322B Displaced transverse fracture of shaft of left femur, initial encounter for open fracture type I or',
  'II',
  'S72322C Displaced transverse fracture of shaft of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72322D Displaced transverse fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72322E Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72322F Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72322G Displaced transverse fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72322H Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72322J Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72322K Displaced transverse fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72322M Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72322N Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72322P Displaced transverse fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72322Q Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72322R Displaced transverse fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72322S Displaced transverse fracture of shaft of left femur, sequela',
  'S72323A Displaced transverse fracture of shaft of unspecified femur, initial encounter for closed',
  'fracture',
  'S72323B Displaced transverse fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72323C Displaced transverse fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72323D Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72323E Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72323F Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72323G Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72323H Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72323J Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72323K Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72323M Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72323N Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72323P Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72323Q Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72323R Displaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72323S Displaced transverse fracture of shaft of unspecified femur, sequela',
  'S72324A Nondisplaced transverse fracture of shaft of right femur, initial encounter for closed fracture',
  'S72324B Nondisplaced transverse fracture of shaft of right femur, initial encounter for open fracture',
  'type I or II',
  'S72324C Nondisplaced transverse fracture of shaft of right femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72324D Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72324E Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72324F Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72324G Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72324H Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72324J Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72324K Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72324M Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72324N Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72324P Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72324Q Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72324R Nondisplaced transverse fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72324S Nondisplaced transverse fracture of shaft of right femur, sequela',
  'S72325A Nondisplaced transverse fracture of shaft of left femur, initial encounter for closed fracture',
  'S72325B Nondisplaced transverse fracture of shaft of left femur, initial encounter for open fracture type',
  'I or II',
  'S72325C Nondisplaced transverse fracture of shaft of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72325D Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72325E Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72325F Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72325G Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72325H Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72325J Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72325K Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72325M Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72325N Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72325P Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72325Q Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72325R Nondisplaced transverse fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72325S Nondisplaced transverse fracture of shaft of left femur, sequela',
  'S72326A Nondisplaced transverse fracture of shaft of unspecified femur, initial encounter for closed',
  'fracture',
  'S72326B Nondisplaced transverse fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72326C Nondisplaced transverse fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72326D Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72326E Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72326F Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72326G Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72326H Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72326J Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72326K Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72326M Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72326N Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72326P Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72326Q Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72326R Nondisplaced transverse fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72326S Nondisplaced transverse fracture of shaft of unspecified femur, sequela',
  'S72331A Displaced oblique fracture of shaft of right femur, initial encounter for closed fracture',
  'S72331B Displaced oblique fracture of shaft of right femur, initial encounter for open fracture type I or II',
  'S72331C Displaced oblique fracture of shaft of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72331D Displaced oblique fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72331E Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72331F Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72331G Displaced oblique fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72331H Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72331J Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72331K Displaced oblique fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72331M Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72331N Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72331P Displaced oblique fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72331Q Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72331R Displaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72331S Displaced oblique fracture of shaft of right femur, sequela',
  'S72332A Displaced oblique fracture of shaft of left femur, initial encounter for closed fracture',
  'S72332B Displaced oblique fracture of shaft of left femur, initial encounter for open fracture type I or II',
  'S72332C Displaced oblique fracture of shaft of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72332D Displaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72332E Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72332F Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72332G Displaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72332H Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72332J Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72332K Displaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72332M Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S72332N Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72332P Displaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72332Q Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72332R Displaced oblique fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72332S Displaced oblique fracture of shaft of left femur, sequela',
  'S72333A Displaced oblique fracture of shaft of unspecified femur, initial encounter for closed fracture',
  'S72333B Displaced oblique fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72333C Displaced oblique fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72333D Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72333E Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72333F Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72333G Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72333H Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72333J Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72333K Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72333M Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72333N Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72333P Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72333Q Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72333R Displaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72333S Displaced oblique fracture of shaft of unspecified femur, sequela',
  'S72334A Nondisplaced oblique fracture of shaft of right femur, initial encounter for closed fracture',
  'S72334B Nondisplaced oblique fracture of shaft of right femur, initial encounter for open fracture type I',
  'or II',
  'S72334C Nondisplaced oblique fracture of shaft of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72334D Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72334E Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72334F Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72334G Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72334H Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72334J Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72334K Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72334M Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72334N Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72334P Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72334Q Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72334R Nondisplaced oblique fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72334S Nondisplaced oblique fracture of shaft of right femur, sequela',
  'S72335A Nondisplaced oblique fracture of shaft of left femur, initial encounter for closed fracture',
  'S72335B Nondisplaced oblique fracture of shaft of left femur, initial encounter for open fracture type I',
  'or II',
  'S72335C Nondisplaced oblique fracture of shaft of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72335D Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72335E Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72335F Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72335G Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72335H Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72335J Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72335K Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72335M Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72335N Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72335P Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72335Q Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72335R Nondisplaced oblique fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72335S Nondisplaced oblique fracture of shaft of left femur, sequela',
  'S72336A Nondisplaced oblique fracture of shaft of unspecified femur, initial encounter for closed',
  'fracture',
  'S72336B Nondisplaced oblique fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72336C Nondisplaced oblique fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72336D Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72336E Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72336F Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72336G Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72336H Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72336J Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72336K Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72336M Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72336N Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72336P Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72336Q Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72336R Nondisplaced oblique fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72336S Nondisplaced oblique fracture of shaft of unspecified femur, sequela',
  'S72341A Displaced spiral fracture of shaft of right femur, initial encounter for closed fracture',
  'S72341B Displaced spiral fracture of shaft of right femur, initial encounter for open fracture type I or II',
  'S72341C Displaced spiral fracture of shaft of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72341D Displaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72341E Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72341F Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72341G Displaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72341H Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72341J Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72341K Displaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72341M Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72341N Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72341P Displaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72341Q Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72341R Displaced spiral fracture of shaft of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72341S Displaced spiral fracture of shaft of right femur, sequela',
  'S72342A Displaced spiral fracture of shaft of left femur, initial encounter for closed fracture',
  'S72342B Displaced spiral fracture of shaft of left femur, initial encounter for open fracture type I or II',
  'S72342C Displaced spiral fracture of shaft of left femur, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S72342D Displaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72342E Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72342F Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72342G Displaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72342H Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72342J Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72342K Displaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72342M Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72342N Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72342P Displaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72342Q Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72342R Displaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72342S Displaced spiral fracture of shaft of left femur, sequela',
  'S72343A Displaced spiral fracture of shaft of unspecified femur, initial encounter for closed fracture',
  'S72343B Displaced spiral fracture of shaft of unspecified femur, initial encounter for open fracture type I',
  'or II',
  'S72343C Displaced spiral fracture of shaft of unspecified femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72343D Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72343E Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72343F Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72343G Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72343H Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72343J Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72343K Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72343M Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72343N Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72343P Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72343Q Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72343R Displaced spiral fracture of shaft of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72343S Displaced spiral fracture of shaft of unspecified femur, sequela',
  'S72344A Nondisplaced spiral fracture of shaft of right femur, initial encounter for closed fracture',
  'S72344B Nondisplaced spiral fracture of shaft of right femur, initial encounter for open fracture type I or',
  'II',
  'S72344C Nondisplaced spiral fracture of shaft of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72344D Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72344E Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72344F Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72344G Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72344H Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72344J Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72344K Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72344M Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72344N Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72344P Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72344Q Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72344R Nondisplaced spiral fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72344S Nondisplaced spiral fracture of shaft of right femur, sequela',
  'S72345A Nondisplaced spiral fracture of shaft of left femur, initial encounter for closed fracture',
  'S72345B Nondisplaced spiral fracture of shaft of left femur, initial encounter for open fracture type I or',
  'II',
  'S72345C Nondisplaced spiral fracture of shaft of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72345D Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72345E Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72345F Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72345G Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72345H Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72345J Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72345K Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72345M Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72345N Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72345P Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72345Q Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72345R Nondisplaced spiral fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72345S Nondisplaced spiral fracture of shaft of left femur, sequela',
  'S72346A Nondisplaced spiral fracture of shaft of unspecified femur, initial encounter for closed fracture',
  'S72346B Nondisplaced spiral fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72346C Nondisplaced spiral fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72346D Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72346E Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72346F Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72346G Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72346H Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72346J Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72346K Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72346M Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72346N Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72346P Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72346Q Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72346R Nondisplaced spiral fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72346S Nondisplaced spiral fracture of shaft of unspecified femur, sequela',
  'S72351A Displaced comminuted fracture of shaft of right femur, initial encounter for closed fracture',
  'S72351B Displaced comminuted fracture of shaft of right femur, initial encounter for open fracture type',
  'I or II',
  'S72351C Displaced comminuted fracture of shaft of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72351D Displaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72351E Displaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72351F Displaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72351G Displaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72351H Displaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72351J Displaced comminuted fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72351K Displaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72351M Displaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72351N Displaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72351P Displaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72351Q Displaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72351R Displaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72351S Displaced comminuted fracture of shaft of right femur, sequela',
  'S72352A Displaced comminuted fracture of shaft of left femur, initial encounter for closed fracture',
  'S72352B Displaced comminuted fracture of shaft of left femur, initial encounter for open fracture type I',
  'or II',
  'S72352C Displaced comminuted fracture of shaft of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72352D Displaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72352E Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72352F Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72352G Displaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72352H Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72352J Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72352K Displaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72352M Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72352N Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72352P Displaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72352Q Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72352R Displaced comminuted fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72352S Displaced comminuted fracture of shaft of left femur, sequela',
  'S72353A Displaced comminuted fracture of shaft of unspecified femur, initial encounter for closed',
  'fracture',
  'S72353B Displaced comminuted fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72353C Displaced comminuted fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72353D Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72353E Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72353F Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72353G Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72353H Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72353J Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72353K Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72353M Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72353N Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72353P Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72353Q Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72353R Displaced comminuted fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72353S Displaced comminuted fracture of shaft of unspecified femur, sequela',
  'S72354A Nondisplaced comminuted fracture of shaft of right femur, initial encounter for closed fracture',
  'S72354B Nondisplaced comminuted fracture of shaft of right femur, initial encounter for open fracture',
  'type I or II',
  'S72354C Nondisplaced comminuted fracture of shaft of right femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72354D Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72354E Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72354F Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72354G Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72354H Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72354J Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72354K Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72354M Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72354N Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72354P Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72354Q Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72354R Nondisplaced comminuted fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72354S Nondisplaced comminuted fracture of shaft of right femur, sequela',
  'S72355A Nondisplaced comminuted fracture of shaft of left femur, initial encounter for closed fracture',
  'S72355B Nondisplaced comminuted fracture of shaft of left femur, initial encounter for open fracture',
  'type I or II',
  'S72355C Nondisplaced comminuted fracture of shaft of left femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72355D Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72355E Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72355F Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72355G Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72355H Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72355J Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72355K Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72355M Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72355N Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72355P Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72355Q Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72355R Nondisplaced comminuted fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72355S Nondisplaced comminuted fracture of shaft of left femur, sequela',
  'S72356A Nondisplaced comminuted fracture of shaft of unspecified femur, initial encounter for closed',
  'fracture',
  'S72356B Nondisplaced comminuted fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72356C Nondisplaced comminuted fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72356D Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72356E Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72356F Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72356G Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72356H Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72356J Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72356K Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72356M Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72356N Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72356P Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72356Q Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72356R Nondisplaced comminuted fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72356S Nondisplaced comminuted fracture of shaft of unspecified femur, sequela',
  'S72361A Displaced segmental fracture of shaft of right femur, initial encounter for closed fracture',
  'S72361B Displaced segmental fracture of shaft of right femur, initial encounter for open fracture type I',
  'or II',
  'S72361C Displaced segmental fracture of shaft of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72361D Displaced segmental fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72361E Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72361F Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72361G Displaced segmental fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72361H Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72361J Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72361K Displaced segmental fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72361M Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72361N Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72361P Displaced segmental fracture of shaft of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72361Q Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72361R Displaced segmental fracture of shaft of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72361S Displaced segmental fracture of shaft of right femur, sequela',
  'S72362A Displaced segmental fracture of shaft of left femur, initial encounter for closed fracture',
  'S72362B Displaced segmental fracture of shaft of left femur, initial encounter for open fracture type I or',
  'II',
  'S72362C Displaced segmental fracture of shaft of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72362D Displaced segmental fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72362E Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72362F Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72362G Displaced segmental fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72362H Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72362J Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72362K Displaced segmental fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72362M Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72362N Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72362P Displaced segmental fracture of shaft of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72362Q Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72362R Displaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72362S Displaced segmental fracture of shaft of left femur, sequela',
  'S72363A Displaced segmental fracture of shaft of unspecified femur, initial encounter for closed fracture',
  'S72363B Displaced segmental fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72363C Displaced segmental fracture of shaft of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72363D Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72363E Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72363F Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72363G Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72363H Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72363J Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72363K Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72363M Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72363N Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72363P Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72363Q Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72363R Displaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72363S Displaced segmental fracture of shaft of unspecified femur, sequela',
  'S72364A Nondisplaced segmental fracture of shaft of right femur, initial encounter for closed fracture',
  'S72364B Nondisplaced segmental fracture of shaft of right femur, initial encounter for open fracture',
  'type I or II',
  'S72364C Nondisplaced segmental fracture of shaft of right femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72364D Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72364E Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72364F Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72364G Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72364H Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72364J Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72364K Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72364M Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72364N Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72364P Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72364Q Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72364R Nondisplaced segmental fracture of shaft of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72364S Nondisplaced segmental fracture of shaft of right femur, sequela',
  'S72365A Nondisplaced segmental fracture of shaft of left femur, initial encounter for closed fracture',
  'S72365B Nondisplaced segmental fracture of shaft of left femur, initial encounter for open fracture type',
  'I or II',
  'S72365C Nondisplaced segmental fracture of shaft of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72365D Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72365E Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72365F Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72365G Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72365H Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72365J Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72365K Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72365M Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72365N Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72365P Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72365Q Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72365R Nondisplaced segmental fracture of shaft of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72365S Nondisplaced segmental fracture of shaft of left femur, sequela',
  'S72366A Nondisplaced segmental fracture of shaft of unspecified femur, initial encounter for closed',
  'fracture',
  'S72366B Nondisplaced segmental fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72366C Nondisplaced segmental fracture of shaft of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72366D Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72366E Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72366F Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72366G Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72366H Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72366J Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72366K Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72366M Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72366N Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72366P Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72366Q Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72366R Nondisplaced segmental fracture of shaft of unspecified femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72366S Nondisplaced segmental fracture of shaft of unspecified femur, sequela',
  'S72391A Other fracture of shaft of right femur, initial encounter for closed fracture',
  'S72391B Other fracture of shaft of right femur, initial encounter for open fracture type I or II',
  'S72391C Other fracture of shaft of right femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72391D Other fracture of shaft of right femur, subsequent encounter for closed fracture with routine',
  'healing',
  'S72391E Other fracture of shaft of right femur, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S72391F Other fracture of shaft of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S72391G Other fracture of shaft of right femur, subsequent encounter for closed fracture with delayed',
  'healing',
  'S72391H Other fracture of shaft of right femur, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S72391J Other fracture of shaft of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S72391K Other fracture of shaft of right femur, subsequent encounter for closed fracture with nonunion',
  'S72391M Other fracture of shaft of right femur, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S72391N Other fracture of shaft of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S72391P Other fracture of shaft of right femur, subsequent encounter for closed fracture with malunion',
  'S72391Q Other fracture of shaft of right femur, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S72391R Other fracture of shaft of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S72391S Other fracture of shaft of right femur, sequela',
  'S72392A Other fracture of shaft of left femur, initial encounter for closed fracture',
  'S72392B Other fracture of shaft of left femur, initial encounter for open fracture type I or II',
  'S72392C Other fracture of shaft of left femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72392D Other fracture of shaft of left femur, subsequent encounter for closed fracture with routine',
  'healing',
  'S72392E Other fracture of shaft of left femur, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S72392F Other fracture of shaft of left femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S72392G Other fracture of shaft of left femur, subsequent encounter for closed fracture with delayed',
  'healing',
  'S72392H Other fracture of shaft of left femur, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S72392J Other fracture of shaft of left femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S72392K Other fracture of shaft of left femur, subsequent encounter for closed fracture with nonunion',
  'S72392M Other fracture of shaft of left femur, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S72392N Other fracture of shaft of left femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S72392P Other fracture of shaft of left femur, subsequent encounter for closed fracture with malunion',
  'S72392Q Other fracture of shaft of left femur, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S72392R Other fracture of shaft of left femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S72392S Other fracture of shaft of left femur, sequela',
  'S72399A Other fracture of shaft of unspecified femur, initial encounter for closed fracture',
  'S72399B Other fracture of shaft of unspecified femur, initial encounter for open fracture type I or II',
  'S72399C Other fracture of shaft of unspecified femur, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S72399D Other fracture of shaft of unspecified femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72399E Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S72399F Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72399G Other fracture of shaft of unspecified femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72399H Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S72399J Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72399K Other fracture of shaft of unspecified femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72399M Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S72399N Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72399P Other fracture of shaft of unspecified femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72399Q Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S72399R Other fracture of shaft of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72399S Other fracture of shaft of unspecified femur, sequela',
  'S72401A Unspecified fracture of lower end of right femur, initial encounter for closed fracture',
  'S72401B Unspecified fracture of lower end of right femur, initial encounter for open fracture type I or II',
  'S72401C Unspecified fracture of lower end of right femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72401D Unspecified fracture of lower end of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72401E Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72401F Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72401G Unspecified fracture of lower end of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72401H Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S72401J Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72401K Unspecified fracture of lower end of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72401M Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S72401N Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72401P Unspecified fracture of lower end of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72401Q Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S72401R Unspecified fracture of lower end of right femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72401S Unspecified fracture of lower end of right femur, sequela',
  'S72402A Unspecified fracture of lower end of left femur, initial encounter for closed fracture',
  'S72402B Unspecified fracture of lower end of left femur, initial encounter for open fracture type I or II',
  'S72402C Unspecified fracture of lower end of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72402D Unspecified fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72402E Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S72402F Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72402G Unspecified fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72402H Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S72402J Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72402K Unspecified fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72402M Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S72402N Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S72402P Unspecified fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72402Q Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S72402R Unspecified fracture of lower end of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72402S Unspecified fracture of lower end of left femur, sequela',
  'S72409A Unspecified fracture of lower end of unspecified femur, initial encounter for closed fracture',
  'S72409B Unspecified fracture of lower end of unspecified femur, initial encounter for open fracture type',
  'I or II',
  'S72409C Unspecified fracture of lower end of unspecified femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72409D Unspecified fracture of lower end of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72409E Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72409F Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72409G Unspecified fracture of lower end of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72409H Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72409J Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72409K Unspecified fracture of lower end of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72409M Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72409N Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72409P Unspecified fracture of lower end of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72409Q Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72409R Unspecified fracture of lower end of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72409S Unspecified fracture of lower end of unspecified femur, sequela',
  'S72411A Displaced unspecified condyle fracture of lower end of right femur, initial encounter for closed',
  'fracture',
  'S72411B Displaced unspecified condyle fracture of lower end of right femur, initial encounter for open',
  'fracture type I or II',
  'S72411C Displaced unspecified condyle fracture of lower end of right femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72411D Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72411E Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72411F Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72411G Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72411H Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72411J Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72411K Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72411M Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72411N Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72411P Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72411Q Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72411R Displaced unspecified condyle fracture of lower end of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72411S Displaced unspecified condyle fracture of lower end of right femur, sequela',
  'S72412A Displaced unspecified condyle fracture of lower end of left femur, initial encounter for closed',
  'fracture',
  'S72412B Displaced unspecified condyle fracture of lower end of left femur, initial encounter for open',
  'fracture type I or II',
  'S72412C Displaced unspecified condyle fracture of lower end of left femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72412D Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72412E Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72412F Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72412G Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72412H Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72412J Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72412K Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72412M Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72412N Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72412P Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72412Q Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72412R Displaced unspecified condyle fracture of lower end of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72412S Displaced unspecified condyle fracture of lower end of left femur, sequela',
  'S72413A Displaced unspecified condyle fracture of lower end of unspecified femur, initial encounter for',
  'closed fracture',
  'S72413B Displaced unspecified condyle fracture of lower end of unspecified femur, initial encounter for',
  'open fracture type I or II',
  'S72413C Displaced unspecified condyle fracture of lower end of unspecified femur, initial encounter for',
  'open fracture type IIIA, IIIB, or IIIC',
  'S72413D Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with routine healing',
  'S72413E Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with routine healing',
  'S72413F Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72413G Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with delayed healing',
  'S72413H Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with delayed healing',
  'S72413J Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72413K Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with nonunion',
  'S72413M Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S72413N Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72413P Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with malunion',
  'S72413Q Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with malunion',
  'S72413R Displaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72413S Displaced unspecified condyle fracture of lower end of unspecified femur, sequela',
  'S72414A Nondisplaced unspecified condyle fracture of lower end of right femur, initial encounter for',
  'closed fracture',
  'S72414B Nondisplaced unspecified condyle fracture of lower end of right femur, initial encounter for',
  'open fracture type I or II',
  'S72414C Nondisplaced unspecified condyle fracture of lower end of right femur, initial encounter for',
  'open fracture type IIIA, IIIB, or IIIC',
  'S72414D Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for closed fracture with routine healing',
  'S72414E Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type I or II with routine healing',
  'S72414F Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72414G Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for closed fracture with delayed healing',
  'S72414H Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S72414J Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72414K Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for closed fracture with nonunion',
  'S72414M Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S72414N Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72414P Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for closed fracture with malunion',
  'S72414Q Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S72414R Nondisplaced unspecified condyle fracture of lower end of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72414S Nondisplaced unspecified condyle fracture of lower end of right femur, sequela',
  'S72415A Nondisplaced unspecified condyle fracture of lower end of left femur, initial encounter for',
  'closed fracture',
  'S72415B Nondisplaced unspecified condyle fracture of lower end of left femur, initial encounter for',
  'open fracture type I or II',
  'S72415C Nondisplaced unspecified condyle fracture of lower end of left femur, initial encounter for',
  'open fracture type IIIA, IIIB, or IIIC',
  'S72415D Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for closed fracture with routine healing',
  'S72415E Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type I or II with routine healing',
  'S72415F Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72415G Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for closed fracture with delayed healing',
  'S72415H Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S72415J Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72415K Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for closed fracture with nonunion',
  'S72415M Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S72415N Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72415P Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for closed fracture with malunion',
  'S72415Q Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S72415R Nondisplaced unspecified condyle fracture of lower end of left femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72415S Nondisplaced unspecified condyle fracture of lower end of left femur, sequela',
  'S72416A Nondisplaced unspecified condyle fracture of lower end of unspecified femur, initial encounter',
  'for closed fracture',
  'S72416B Nondisplaced unspecified condyle fracture of lower end of unspecified femur, initial encounter',
  'for open fracture type I or II',
  'S72416C Nondisplaced unspecified condyle fracture of lower end of unspecified femur, initial encounter',
  'for open fracture type IIIA, IIIB, or IIIC',
  'S72416D Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with routine healing',
  'S72416E Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with routine healing',
  'S72416F Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72416G Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with delayed healing',
  'S72416H Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with delayed healing',
  'S72416J Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72416K Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with nonunion',
  'S72416M Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S72416N Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72416P Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for closed fracture with malunion',
  'S72416Q Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type I or II with malunion',
  'S72416R Nondisplaced unspecified condyle fracture of lower end of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72416S Nondisplaced unspecified condyle fracture of lower end of unspecified femur, sequela',
  'S72421A Displaced fracture of lateral condyle of right femur, initial encounter for closed fracture',
  'S72421B Displaced fracture of lateral condyle of right femur, initial encounter for open fracture type I or',
  'II',
  'S72421C Displaced fracture of lateral condyle of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72421D Displaced fracture of lateral condyle of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72421E Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72421F Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72421G Displaced fracture of lateral condyle of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72421H Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72421J Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72421K Displaced fracture of lateral condyle of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72421M Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72421N Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72421P Displaced fracture of lateral condyle of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72421Q Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72421R Displaced fracture of lateral condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72421S Displaced fracture of lateral condyle of right femur, sequela',
  'S72422A Displaced fracture of lateral condyle of left femur, initial encounter for closed fracture',
  'S72422B Displaced fracture of lateral condyle of left femur, initial encounter for open fracture type I or II',
  'S72422C Displaced fracture of lateral condyle of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72422D Displaced fracture of lateral condyle of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72422E Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72422F Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72422G Displaced fracture of lateral condyle of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72422H Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72422J Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72422K Displaced fracture of lateral condyle of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72422M Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72422N Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72422P Displaced fracture of lateral condyle of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72422Q Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72422R Displaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72422S Displaced fracture of lateral condyle of left femur, sequela',
  'S72423A Displaced fracture of lateral condyle of unspecified femur, initial encounter for closed fracture',
  'S72423B Displaced fracture of lateral condyle of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72423C Displaced fracture of lateral condyle of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72423D Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72423E Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72423F Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72423G Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72423H Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72423J Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72423K Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72423M Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72423N Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72423P Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72423Q Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72423R Displaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72423S Displaced fracture of lateral condyle of unspecified femur, sequela',
  'S72424A Nondisplaced fracture of lateral condyle of right femur, initial encounter for closed fracture',
  'S72424B Nondisplaced fracture of lateral condyle of right femur, initial encounter for open fracture type',
  'I or II',
  'S72424C Nondisplaced fracture of lateral condyle of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72424D Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72424E Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72424F Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72424G Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72424H Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72424J Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72424K Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72424M Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72424N Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72424P Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72424Q Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72424R Nondisplaced fracture of lateral condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72424S Nondisplaced fracture of lateral condyle of right femur, sequela',
  'S72425A Nondisplaced fracture of lateral condyle of left femur, initial encounter for closed fracture',
  'S72425B Nondisplaced fracture of lateral condyle of left femur, initial encounter for open fracture type I',
  'or II',
  'S72425C Nondisplaced fracture of lateral condyle of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72425D Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72425E Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72425F Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72425G Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72425H Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72425J Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72425K Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72425M Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72425N Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72425P Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72425Q Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72425R Nondisplaced fracture of lateral condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72425S Nondisplaced fracture of lateral condyle of left femur, sequela',
  'S72426A Nondisplaced fracture of lateral condyle of unspecified femur, initial encounter for closed',
  'fracture',
  'S72426B Nondisplaced fracture of lateral condyle of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72426C Nondisplaced fracture of lateral condyle of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72426D Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72426E Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72426F Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72426G Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72426H Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72426J Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72426K Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72426M Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72426N Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72426P Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72426Q Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72426R Nondisplaced fracture of lateral condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72426S Nondisplaced fracture of lateral condyle of unspecified femur, sequela',
  'S72431A Displaced fracture of medial condyle of right femur, initial encounter for closed fracture',
  'S72431B Displaced fracture of medial condyle of right femur, initial encounter for open fracture type I or',
  'II',
  'S72431C Displaced fracture of medial condyle of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72431D Displaced fracture of medial condyle of right femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72431E Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72431F Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72431G Displaced fracture of medial condyle of right femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72431H Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72431J Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72431K Displaced fracture of medial condyle of right femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72431M Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72431N Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72431P Displaced fracture of medial condyle of right femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72431Q Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72431R Displaced fracture of medial condyle of right femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72431S Displaced fracture of medial condyle of right femur, sequela',
  'S72432A Displaced fracture of medial condyle of left femur, initial encounter for closed fracture',
  'S72432B Displaced fracture of medial condyle of left femur, initial encounter for open fracture type I or',
  'II',
  'S72432C Displaced fracture of medial condyle of left femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72432D Displaced fracture of medial condyle of left femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72432E Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72432F Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72432G Displaced fracture of medial condyle of left femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72432H Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72432J Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72432K Displaced fracture of medial condyle of left femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72432M Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72432N Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72432P Displaced fracture of medial condyle of left femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72432Q Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72432R Displaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72432S Displaced fracture of medial condyle of left femur, sequela',
  'S72433A Displaced fracture of medial condyle of unspecified femur, initial encounter for closed fracture',
  'S72433B Displaced fracture of medial condyle of unspecified femur, initial encounter for open fracture',
  'type I or II',
  'S72433C Displaced fracture of medial condyle of unspecified femur, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S72433D Displaced fracture of medial condyle of unspecified femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72433E Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72433F Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72433G Displaced fracture of medial condyle of unspecified femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72433H Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72433J Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72433K Displaced fracture of medial condyle of unspecified femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72433M Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72433N Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72433P Displaced fracture of medial condyle of unspecified femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72433Q Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72433R Displaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72433S Displaced fracture of medial condyle of unspecified femur, sequela',
  'S72434A Nondisplaced fracture of medial condyle of right femur, initial encounter for closed fracture',
  'S72434B Nondisplaced fracture of medial condyle of right femur, initial encounter for open fracture type',
  'I or II',
  'S72434C Nondisplaced fracture of medial condyle of right femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72434D Nondisplaced fracture of medial condyle of right femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72434E Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72434F Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72434G Nondisplaced fracture of medial condyle of right femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72434H Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72434J Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72434K Nondisplaced fracture of medial condyle of right femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72434M Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72434N Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72434P Nondisplaced fracture of medial condyle of right femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72434Q Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72434R Nondisplaced fracture of medial condyle of right femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72434S Nondisplaced fracture of medial condyle of right femur, sequela',
  'S72435A Nondisplaced fracture of medial condyle of left femur, initial encounter for closed fracture',
  'S72435B Nondisplaced fracture of medial condyle of left femur, initial encounter for open fracture type I',
  'or II',
  'S72435C Nondisplaced fracture of medial condyle of left femur, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S72435D Nondisplaced fracture of medial condyle of left femur, subsequent encounter for closed',
  'fracture with routine healing',
  'S72435E Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S72435F Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S72435G Nondisplaced fracture of medial condyle of left femur, subsequent encounter for closed',
  'fracture with delayed healing',
  'S72435H Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72435J Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S72435K Nondisplaced fracture of medial condyle of left femur, subsequent encounter for closed',
  'fracture with nonunion',
  'S72435M Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S72435N Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72435P Nondisplaced fracture of medial condyle of left femur, subsequent encounter for closed',
  'fracture with malunion',
  'S72435Q Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72435R Nondisplaced fracture of medial condyle of left femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S72435S Nondisplaced fracture of medial condyle of left femur, sequela',
  'S72436A Nondisplaced fracture of medial condyle of unspecified femur, initial encounter for closed',
  'fracture',
  'S72436B Nondisplaced fracture of medial condyle of unspecified femur, initial encounter for open',
  'fracture type I or II',
  'S72436C Nondisplaced fracture of medial condyle of unspecified femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72436D Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72436E Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S72436F Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72436G Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72436H Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S72436J Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72436K Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72436M Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72436N Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72436P Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72436Q Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S72436R Nondisplaced fracture of medial condyle of unspecified femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S72436S Nondisplaced fracture of medial condyle of unspecified femur, sequela',
  'S72441A Displaced fracture of lower epiphysis (separation) of right femur, initial encounter for closed',
  'fracture',
  'S72441B Displaced fracture of lower epiphysis (separation) of right femur, initial encounter for open',
  'fracture type I or II',
  'S72441C Displaced fracture of lower epiphysis (separation) of right femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72441D Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72441E Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72441F Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72441G Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72441H Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72441J Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72441K Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72441M Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72441N Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72441P Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72441Q Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72441R Displaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72441S Displaced fracture of lower epiphysis (separation) of right femur, sequela',
  'S72442A Displaced fracture of lower epiphysis (separation) of left femur, initial encounter for closed',
  'fracture',
  'S72442B Displaced fracture of lower epiphysis (separation) of left femur, initial encounter for open',
  'fracture type I or II',
  'S72442C Displaced fracture of lower epiphysis (separation) of left femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72442D Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72442E Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72442F Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72442G Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72442H Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72442J Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72442K Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72442M Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72442N Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72442P Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72442Q Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72442R Displaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72442S Displaced fracture of lower epiphysis (separation) of left femur, sequela',
  'S72443A Displaced fracture of lower epiphysis (separation) of unspecified femur, initial encounter for',
  'closed fracture',
  'S72443B Displaced fracture of lower epiphysis (separation) of unspecified femur, initial encounter for',
  'open fracture type I or II',
  'S72443C Displaced fracture of lower epiphysis (separation) of unspecified femur, initial encounter for',
  'open fracture type IIIA, IIIB, or IIIC',
  'S72443D Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for closed fracture with routine healing',
  'S72443E Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for open fracture type I or II with routine healing',
  'S72443F Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72443G Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for closed fracture with delayed healing',
  'S72443H Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S72443J Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72443K Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for closed fracture with nonunion',
  'S72443M Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S72443N Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72443P Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for closed fracture with malunion',
  'S72443Q Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S72443R Displaced fracture of lower epiphysis (separation) of unspecified femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72443S Displaced fracture of lower epiphysis (separation) of unspecified femur, sequela',
  'S72444A Nondisplaced fracture of lower epiphysis (separation) of right femur, initial encounter for',
  'closed fracture',
  'S72444B Nondisplaced fracture of lower epiphysis (separation) of right femur, initial encounter for open',
  'fracture type I or II',
  'S72444C Nondisplaced fracture of lower epiphysis (separation) of right femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72444D Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter',
  'for closed fracture with routine healing',
  'S72444E Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72444F Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72444G Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter',
  'for closed fracture with delayed healing',
  'S72444H Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter',
  'for open fracture type I or II with delayed healing',
  'S72444J Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72444K Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72444M Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter',
  'for open fracture type I or II with nonunion',
  'S72444N Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter',
  'for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72444P Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72444Q Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter',
  'for open fracture type I or II with malunion',
  'S72444R Nondisplaced fracture of lower epiphysis (separation) of right femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72444S Nondisplaced fracture of lower epiphysis (separation) of right femur, sequela',
  'S72445A Nondisplaced fracture of lower epiphysis (separation) of left femur, initial encounter for closed',
  'fracture',
  'S72445B Nondisplaced fracture of lower epiphysis (separation) of left femur, initial encounter for open',
  'fracture type I or II',
  'S72445C Nondisplaced fracture of lower epiphysis (separation) of left femur, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S72445D Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with routine healing',
  'S72445E Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S72445F Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72445G Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with delayed healing',
  'S72445H Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S72445J Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72445K Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with nonunion',
  'S72445M Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S72445N Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72445P Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'closed fracture with malunion',
  'S72445Q Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S72445R Nondisplaced fracture of lower epiphysis (separation) of left femur, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72445S Nondisplaced fracture of lower epiphysis (separation) of left femur, sequela',
  'S72446A Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, initial encounter',
  'for closed fracture',
  'S72446B Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, initial encounter',
  'for open fracture type I or II',
  'S72446C Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, initial encounter',
  'for open fracture type IIIA, IIIB, or IIIC',
  'S72446D Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for closed fracture with routine healing',
  'S72446E Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with routine healing',
  'S72446F Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72446G Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for closed fracture with delayed healing',
  'S72446H Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with delayed healing',
  'S72446J Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72446K Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for closed fracture with nonunion',
  'S72446M Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with nonunion',
  'S72446N Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72446P Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for closed fracture with malunion',
  'S72446Q Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type I or II with malunion',
  'S72446R Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, subsequent',
  'encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72446S Nondisplaced fracture of lower epiphysis (separation) of unspecified femur, sequela',
  'S72451A Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'initial encounter for closed fracture',
  'S72451B Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'initial encounter for open fracture type I or II',
  'S72451C Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72451D Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with routine healing',
  'S72451E Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S72451F Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72451G Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with delayed healing',
  'S72451H Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S72451J Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72451K Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with nonunion',
  'S72451M Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S72451N Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72451P Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with malunion',
  'S72451Q Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S72451R Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72451S Displaced supracondylar fracture without intracondylar extension of lower end of right femur,',
  'sequela',
  'S72452A Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'initial encounter for closed fracture',
  'S72452B Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'initial encounter for open fracture type I or II',
  'S72452C Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72452D Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with routine healing',
  'S72452E Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S72452F Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72452G Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with delayed healing',
  'S72452H Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S72452J Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72452K Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with nonunion',
  'S72452M Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S72452N Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72452P Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with malunion',
  'S72452Q Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S72452R Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72452S Displaced supracondylar fracture without intracondylar extension of lower end of left femur,',
  'sequela',
  'S72453A Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, initial encounter for closed fracture',
  'S72453B Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, initial encounter for open fracture type I or II',
  'S72453C Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72453D Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with routine healing',
  'S72453E Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with routine healing',
  'S72453F Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72453G Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with delayed healing',
  'S72453H Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with delayed healing',
  'S72453J Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72453K Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with nonunion',
  'S72453M Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with nonunion',
  'S72453N Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72453P Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with malunion',
  'S72453Q Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with malunion',
  'S72453R Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72453S Displaced supracondylar fracture without intracondylar extension of lower end of unspecified',
  'femur, sequela',
  'S72454A Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, initial encounter for closed fracture',
  'S72454B Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, initial encounter for open fracture type I or II',
  'S72454C Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72454D Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for closed fracture with routine healing',
  'S72454E Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type I or II with routine healing',
  'S72454F Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72454G Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for closed fracture with delayed healing',
  'S72454H Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type I or II with delayed healing',
  'S72454J Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72454K Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for closed fracture with nonunion',
  'S72454M Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type I or II with nonunion',
  'S72454N Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72454P Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for closed fracture with malunion',
  'S72454Q Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type I or II with malunion',
  'S72454R Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72454S Nondisplaced supracondylar fracture without intracondylar extension of lower end of right',
  'femur, sequela',
  'S72455A Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, initial encounter for closed fracture',
  'S72455B Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, initial encounter for open fracture type I or II',
  'S72455C Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72455D Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for closed fracture with routine healing',
  'S72455E Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type I or II with routine healing',
  'S72455F Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72455G Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for closed fracture with delayed healing',
  'S72455H Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type I or II with delayed healing',
  'S72455J Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72455K Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for closed fracture with nonunion',
  'S72455M Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type I or II with nonunion',
  'S72455N Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72455P Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for closed fracture with malunion',
  'S72455Q Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type I or II with malunion',
  'S72455R Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72455S Nondisplaced supracondylar fracture without intracondylar extension of lower end of left',
  'femur, sequela',
  'S72456A Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, initial encounter for closed fracture',
  'S72456B Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, initial encounter for open fracture type I or II',
  'S72456C Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72456D Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for closed fracture with routine healing',
  'S72456E Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type I or II with routine healing',
  'S72456F Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72456G Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for closed fracture with delayed healing',
  'S72456H Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type I or II with delayed healing',
  'S72456J Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72456K Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for closed fracture with nonunion',
  'S72456M Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type I or II with nonunion',
  'S72456N Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72456P Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for closed fracture with malunion',
  'S72456Q Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type I or II with malunion',
  'S72456R Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72456S Nondisplaced supracondylar fracture without intracondylar extension of lower end of',
  'unspecified femur, sequela',
  'S72461A Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'initial encounter for closed fracture',
  'S72461B Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'initial encounter for open fracture type I or II',
  'S72461C Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72461D Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with routine healing',
  'S72461E Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S72461F Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72461G Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with delayed healing',
  'S72461H Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S72461J Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72461K Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with nonunion',
  'S72461M Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S72461N Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72461P Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with malunion',
  'S72461Q Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S72461R Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72461S Displaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'sequela',
  'S72462A Displaced supracondylar fracture with intracondylar extension of lower end of left femur, initial',
  'encounter for closed fracture',
  'S72462B Displaced supracondylar fracture with intracondylar extension of lower end of left femur, initial',
  'encounter for open fracture type I or II',
  'S72462C Displaced supracondylar fracture with intracondylar extension of lower end of left femur, initial',
  'encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72462D Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with routine healing',
  'S72462E Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S72462F Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72462G Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with delayed healing',
  'S72462H Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S72462J Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72462K Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with nonunion',
  'S72462M Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S72462N Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72462P Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with malunion',
  'S72462Q Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S72462R Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72462S Displaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'sequela',
  'S72463A Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, initial encounter for closed fracture',
  'S72463B Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, initial encounter for open fracture type I or II',
  'S72463C Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72463D Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with routine healing',
  'S72463E Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with routine healing',
  'S72463F Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72463G Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with delayed healing',
  'S72463H Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with delayed healing',
  'S72463J Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72463K Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with nonunion',
  'S72463M Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with nonunion',
  'S72463N Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72463P Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with malunion',
  'S72463Q Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with malunion',
  'S72463R Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72463S Displaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, sequela',
  'S72464A Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'initial encounter for closed fracture',
  'S72464B Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'initial encounter for open fracture type I or II',
  'S72464C Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72464D Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with routine healing',
  'S72464E Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S72464F Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72464G Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with delayed healing',
  'S72464H Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S72464J Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72464K Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with nonunion',
  'S72464M Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S72464N Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72464P Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for closed fracture with malunion',
  'S72464Q Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S72464R Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72464S Nondisplaced supracondylar fracture with intracondylar extension of lower end of right femur,',
  'sequela',
  'S72465A Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'initial encounter for closed fracture',
  'S72465B Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'initial encounter for open fracture type I or II',
  'S72465C Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72465D Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with routine healing',
  'S72465E Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with routine healing',
  'S72465F Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72465G Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with delayed healing',
  'S72465H Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with delayed healing',
  'S72465J Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72465K Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with nonunion',
  'S72465M Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with nonunion',
  'S72465N Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72465P Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for closed fracture with malunion',
  'S72465Q Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type I or II with malunion',
  'S72465R Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72465S Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur,',
  'sequela',
  'S72466A Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, initial encounter for closed fracture',
  'S72466B Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, initial encounter for open fracture type I or II',
  'S72466C Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S72466D Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with routine healing',
  'S72466E Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with routine healing',
  'S72466F Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S72466G Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with delayed healing',
  'S72466H Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with delayed healing',
  'S72466J Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S72466K Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with nonunion',
  'S72466M Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with nonunion',
  'S72466N Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S72466P Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for closed fracture with malunion',
  'S72466Q Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type I or II with malunion',
  'S72466R Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with malunion',
  'S72466S Nondisplaced supracondylar fracture with intracondylar extension of lower end of unspecified',
  'femur, sequela',
  'S72471A Torus fracture of lower end of right femur, initial encounter for closed fracture',
  'S72471D Torus fracture of lower end of right femur, subsequent encounter for fracture with routine',
  'healing',
  'S72471G Torus fracture of lower end of right femur, subsequent encounter for fracture with delayed',
  'healing',
  'S72471K Torus fracture of lower end of right femur, subsequent encounter for fracture with nonunion',
  'S72471P Torus fracture of lower end of right femur, subsequent encounter for fracture with malunion',
  'S72471S Torus fracture of lower end of right femur, sequela',
  'S72472A Torus fracture of lower end of left femur, initial encounter for closed fracture',
  'S72472D Torus fracture of lower end of left femur, subsequent encounter for fracture with routine',
  'healing',
  'S72472G Torus fracture of lower end of left femur, subsequent encounter for fracture with delayed',
  'healing',
  'S72472K Torus fracture of lower end of left femur, subsequent encounter for fracture with nonunion',
  'S72472P Torus fracture of lower end of left femur, subsequent encounter for fracture with malunion',
  'S72472S Torus fracture of lower end of left femur, sequela',
  'S72479A Torus fracture of lower end of unspecified femur, initial encounter for closed fracture',
  'S72479D Torus fracture of lower end of unspecified femur, subsequent encounter for fracture with',
  'routine healing',
  'S72479G Torus fracture of lower end of unspecified femur, subsequent encounter for fracture with',
  'delayed healing',
  'S72479K Torus fracture of lower end of unspecified femur, subsequent encounter for fracture with',
  'nonunion',
  'S72479P Torus fracture of lower end of unspecified femur, subsequent encounter for fracture with',
  'malunion',
  'S72479S Torus fracture of lower end of unspecified femur, sequela',
  'S72491A Other fracture of lower end of right femur, initial encounter for closed fracture',
  'S72491B Other fracture of lower end of right femur, initial encounter for open fracture type I or II',
  'S72491C Other fracture of lower end of right femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72491D Other fracture of lower end of right femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72491E Other fracture of lower end of right femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72491F Other fracture of lower end of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72491G Other fracture of lower end of right femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72491H Other fracture of lower end of right femur, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S72491J Other fracture of lower end of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72491K Other fracture of lower end of right femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72491M Other fracture of lower end of right femur, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S72491N Other fracture of lower end of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72491P Other fracture of lower end of right femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72491Q Other fracture of lower end of right femur, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S72491R Other fracture of lower end of right femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72491S Other fracture of lower end of right femur, sequela',
  'S72492A Other fracture of lower end of left femur, initial encounter for closed fracture',
  'S72492B Other fracture of lower end of left femur, initial encounter for open fracture type I or II',
  'S72492C Other fracture of lower end of left femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S72492D Other fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S72492E Other fracture of lower end of left femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S72492F Other fracture of lower end of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S72492G Other fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S72492H Other fracture of lower end of left femur, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S72492J Other fracture of lower end of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S72492K Other fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S72492M Other fracture of lower end of left femur, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S72492N Other fracture of lower end of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S72492P Other fracture of lower end of left femur, subsequent encounter for closed fracture with',
  'malunion',
  'S72492Q Other fracture of lower end of left femur, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S72492R Other fracture of lower end of left femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S72492S Other fracture of lower end of left femur, sequela',
  'S72499A Other fracture of lower end of unspecified femur, initial encounter for closed fracture',
  'S72499B Other fracture of lower end of unspecified femur, initial encounter for open fracture type I or II',
  'S72499C Other fracture of lower end of unspecified femur, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S72499D Other fracture of lower end of unspecified femur, subsequent encounter for closed fracture',
  'with routine healing',
  'S72499E Other fracture of lower end of unspecified femur, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S72499F Other fracture of lower end of unspecified femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S72499G Other fracture of lower end of unspecified femur, subsequent encounter for closed fracture',
  'with delayed healing',
  'S72499H Other fracture of lower end of unspecified femur, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S72499J Other fracture of lower end of unspecified femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S72499K Other fracture of lower end of unspecified femur, subsequent encounter for closed fracture',
  'with nonunion',
  'S72499M Other fracture of lower end of unspecified femur, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S72499N Other fracture of lower end of unspecified femur, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S72499P Other fracture of lower end of unspecified femur, subsequent encounter for closed fracture',
  'with malunion',
  'S72499Q Other fracture of lower end of unspecified femur, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S72499R Other fracture of lower end of unspecified femur, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S72499S Other fracture of lower end of unspecified femur, sequela',
  'S728X1A Other fracture of right femur, initial encounter for closed fracture',
  'S728X1B Other fracture of right femur, initial encounter for open fracture type I or II',
  'S728X1C Other fracture of right femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S728X1D Other fracture of right femur, subsequent encounter for closed fracture with routine healing',
  'S728X1E Other fracture of right femur, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S728X1F Other fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with routine healing',
  'S728X1G Other fracture of right femur, subsequent encounter for closed fracture with delayed healing',
  'S728X1H Other fracture of right femur, subsequent encounter for open fracture type I or II with delayed',
  'healing',
  'S728X1J Other fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'delayed healing',
  'S728X1K Other fracture of right femur, subsequent encounter for closed fracture with nonunion',
  'S728X1M Other fracture of right femur, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S728X1N Other fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with nonunion',
  'S728X1P Other fracture of right femur, subsequent encounter for closed fracture with malunion',
  'S728X1Q Other fracture of right femur, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S728X1R Other fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with malunion',
  'S728X1S Other fracture of right femur, sequela',
  'S728X2A Other fracture of left femur, initial encounter for closed fracture',
  'S728X2B Other fracture of left femur, initial encounter for open fracture type I or II',
  'S728X2C Other fracture of left femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S728X2D Other fracture of left femur, subsequent encounter for closed fracture with routine healing',
  'S728X2E Other fracture of left femur, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S728X2F Other fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'routine healing',
  'S728X2G Other fracture of left femur, subsequent encounter for closed fracture with delayed healing',
  'S728X2H Other fracture of left femur, subsequent encounter for open fracture type I or II with delayed',
  'healing',
  'S728X2J Other fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'delayed healing',
  'S728X2K Other fracture of left femur, subsequent encounter for closed fracture with nonunion',
  'S728X2M Other fracture of left femur, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S728X2N Other fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'nonunion',
  'S728X2P Other fracture of left femur, subsequent encounter for closed fracture with malunion',
  'S728X2Q Other fracture of left femur, subsequent encounter for open fracture type I or II with malunion',
  'S728X2R Other fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'malunion',
  'S728X2S Other fracture of left femur, sequela',
  'S728X9A Other fracture of unspecified femur, initial encounter for closed fracture',
  'S728X9B Other fracture of unspecified femur, initial encounter for open fracture type I or II',
  'S728X9C Other fracture of unspecified femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S728X9D Other fracture of unspecified femur, subsequent encounter for closed fracture with routine',
  'healing',
  'S728X9E Other fracture of unspecified femur, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S728X9F Other fracture of unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S728X9G Other fracture of unspecified femur, subsequent encounter for closed fracture with delayed',
  'healing',
  'S728X9H Other fracture of unspecified femur, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S728X9J Other fracture of unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S728X9K Other fracture of unspecified femur, subsequent encounter for closed fracture with nonunion',
  'S728X9M Other fracture of unspecified femur, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S728X9N Other fracture of unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S728X9P Other fracture of unspecified femur, subsequent encounter for closed fracture with malunion',
  'S728X9Q Other fracture of unspecified femur, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S728X9R Other fracture of unspecified femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S728X9S Other fracture of unspecified femur, sequela',
  'S7290XA Unspecified fracture of unspecified femur, initial encounter for closed fracture',
  'S7290XB Unspecified fracture of unspecified femur, initial encounter for open fracture type I or II',
  'S7290XC Unspecified fracture of unspecified femur, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S7290XD Unspecified fracture of unspecified femur, subsequent encounter for closed fracture with',
  'routine healing',
  'S7290XE Unspecified fracture of unspecified femur, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S7290XF Unspecified fracture of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S7290XG Unspecified fracture of unspecified femur, subsequent encounter for closed fracture with',
  'delayed healing',
  'S7290XH Unspecified fracture of unspecified femur, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S7290XJ Unspecified fracture of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S7290XK Unspecified fracture of unspecified femur, subsequent encounter for closed fracture with',
  'nonunion',
  'S7290XM Unspecified fracture of unspecified femur, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S7290XN Unspecified fracture of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S7290XP Unspecified fracture of unspecified femur, subsequent encounter for closed fracture with',
  'malunion',
  'S7290XQ Unspecified fracture of unspecified femur, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S7290XR Unspecified fracture of unspecified femur, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S7290XS Unspecified fracture of unspecified femur, sequela',
  'S7291XA Unspecified fracture of right femur, initial encounter for closed fracture',
  'S7291XB Unspecified fracture of right femur, initial encounter for open fracture type I or II',
  'S7291XC Unspecified fracture of right femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S7291XD Unspecified fracture of right femur, subsequent encounter for closed fracture with routine',
  'healing',
  'S7291XE Unspecified fracture of right femur, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S7291XF Unspecified fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S7291XG Unspecified fracture of right femur, subsequent encounter for closed fracture with delayed',
  'healing',
  'S7291XH Unspecified fracture of right femur, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S7291XJ Unspecified fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S7291XK Unspecified fracture of right femur, subsequent encounter for closed fracture with nonunion',
  'S7291XM Unspecified fracture of right femur, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S7291XN Unspecified fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S7291XP Unspecified fracture of right femur, subsequent encounter for closed fracture with malunion',
  'S7291XQ Unspecified fracture of right femur, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S7291XR Unspecified fracture of right femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S7291XS Unspecified fracture of right femur, sequela',
  'S7292XA Unspecified fracture of left femur, initial encounter for closed fracture',
  'S7292XB Unspecified fracture of left femur, initial encounter for open fracture type I or II',
  'S7292XC Unspecified fracture of left femur, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S7292XD Unspecified fracture of left femur, subsequent encounter for closed fracture with routine',
  'healing',
  'S7292XE Unspecified fracture of left femur, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S7292XF Unspecified fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with routine healing',
  'S7292XG Unspecified fracture of left femur, subsequent encounter for closed fracture with delayed',
  'healing',
  'S7292XH Unspecified fracture of left femur, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S7292XJ Unspecified fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S7292XK Unspecified fracture of left femur, subsequent encounter for closed fracture with nonunion',
  'S7292XM Unspecified fracture of left femur, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S7292XN Unspecified fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S7292XP Unspecified fracture of left femur, subsequent encounter for closed fracture with malunion',
  'S7292XQ Unspecified fracture of left femur, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S7292XR Unspecified fracture of left femur, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with malunion',
  'S7292XS Unspecified fracture of left femur, sequela',
  'S73001A Unspecified subluxation of right hip, initial encounter',
  'S73001D Unspecified subluxation of right hip, subsequent encounter',
  'S73001S Unspecified subluxation of right hip, sequela',
  'S73002A Unspecified subluxation of left hip, initial encounter',
  'S73002D Unspecified subluxation of left hip, subsequent encounter',
  'S73002S Unspecified subluxation of left hip, sequela',
  'S73003A Unspecified subluxation of unspecified hip, initial encounter',
  'S73003D Unspecified subluxation of unspecified hip, subsequent encounter',
  'S73003S Unspecified subluxation of unspecified hip, sequela',
  'S73004A Unspecified dislocation of right hip, initial encounter',
  'S73004D Unspecified dislocation of right hip, subsequent encounter',
  'S73004S Unspecified dislocation of right hip, sequela',
  'S73005A Unspecified dislocation of left hip, initial encounter',
  'S73005D Unspecified dislocation of left hip, subsequent encounter',
  'S73005S Unspecified dislocation of left hip, sequela',
  'S73006A Unspecified dislocation of unspecified hip, initial encounter',
  'S73006D Unspecified dislocation of unspecified hip, subsequent encounter',
  'S73006S Unspecified dislocation of unspecified hip, sequela',
  'S73011A Posterior subluxation of right hip, initial encounter',
  'S73011D Posterior subluxation of right hip, subsequent encounter',
  'S73011S Posterior subluxation of right hip, sequela',
  'S73012A Posterior subluxation of left hip, initial encounter',
  'S73012D Posterior subluxation of left hip, subsequent encounter',
  'S73012S Posterior subluxation of left hip, sequela',
  'S73013A Posterior subluxation of unspecified hip, initial encounter',
  'S73013D Posterior subluxation of unspecified hip, subsequent encounter',
  'S73013S Posterior subluxation of unspecified hip, sequela',
  'S73014A Posterior dislocation of right hip, initial encounter',
  'S73014D Posterior dislocation of right hip, subsequent encounter',
  'S73014S Posterior dislocation of right hip, sequela',
  'S73015A Posterior dislocation of left hip, initial encounter',
  'S73015D Posterior dislocation of left hip, subsequent encounter',
  'S73015S Posterior dislocation of left hip, sequela',
  'S73016A Posterior dislocation of unspecified hip, initial encounter',
  'S73016D Posterior dislocation of unspecified hip, subsequent encounter',
  'S73016S Posterior dislocation of unspecified hip, sequela',
  'S73021A Obturator subluxation of right hip, initial encounter',
  'S73021D Obturator subluxation of right hip, subsequent encounter',
  'S73021S Obturator subluxation of right hip, sequela',
  'S73022A Obturator subluxation of left hip, initial encounter',
  'S73022D Obturator subluxation of left hip, subsequent encounter',
  'S73022S Obturator subluxation of left hip, sequela',
  'S73023A Obturator subluxation of unspecified hip, initial encounter',
  'S73023D Obturator subluxation of unspecified hip, subsequent encounter',
  'S73023S Obturator subluxation of unspecified hip, sequela',
  'S73024A Obturator dislocation of right hip, initial encounter',
  'S73024D Obturator dislocation of right hip, subsequent encounter',
  'S73024S Obturator dislocation of right hip, sequela',
  'S73025A Obturator dislocation of left hip, initial encounter',
  'S73025D Obturator dislocation of left hip, subsequent encounter',
  'S73025S Obturator dislocation of left hip, sequela',
  'S73026A Obturator dislocation of unspecified hip, initial encounter',
  'S73026D Obturator dislocation of unspecified hip, subsequent encounter',
  'S73026S Obturator dislocation of unspecified hip, sequela',
  'S73031A Other anterior subluxation of right hip, initial encounter',
  'S73031D Other anterior subluxation of right hip, subsequent encounter',
  'S73031S Other anterior subluxation of right hip, sequela',
  'S73032A Other anterior subluxation of left hip, initial encounter',
  'S73032D Other anterior subluxation of left hip, subsequent encounter',
  'S73032S Other anterior subluxation of left hip, sequela',
  'S73033A Other anterior subluxation of unspecified hip, initial encounter',
  'S73033D Other anterior subluxation of unspecified hip, subsequent encounter',
  'S73033S Other anterior subluxation of unspecified hip, sequela',
  'S73034A Other anterior dislocation of right hip, initial encounter',
  'S73034D Other anterior dislocation of right hip, subsequent encounter',
  'S73034S Other anterior dislocation of right hip, sequela',
  'S73035A Other anterior dislocation of left hip, initial encounter',
  'S73035D Other anterior dislocation of left hip, subsequent encounter',
  'S73035S Other anterior dislocation of left hip, sequela',
  'S73036A Other anterior dislocation of unspecified hip, initial encounter',
  'S73036D Other anterior dislocation of unspecified hip, subsequent encounter',
  'S73036S Other anterior dislocation of unspecified hip, sequela',
  'S73041A Central subluxation of right hip, initial encounter',
  'S73041D Central subluxation of right hip, subsequent encounter',
  'S73041S Central subluxation of right hip, sequela',
  'S73042A Central subluxation of left hip, initial encounter',
  'S73042D Central subluxation of left hip, subsequent encounter',
  'S73042S Central subluxation of left hip, sequela',
  'S73043A Central subluxation of unspecified hip, initial encounter',
  'S73043D Central subluxation of unspecified hip, subsequent encounter',
  'S73043S Central subluxation of unspecified hip, sequela',
  'S73044A Central dislocation of right hip, initial encounter',
  'S73044D Central dislocation of right hip, subsequent encounter',
  'S73044S Central dislocation of right hip, sequela',
  'S73045A Central dislocation of left hip, initial encounter',
  'S73045D Central dislocation of left hip, subsequent encounter',
  'S73045S Central dislocation of left hip, sequela',
  'S73046A Central dislocation of unspecified hip, initial encounter',
  'S73046D Central dislocation of unspecified hip, subsequent encounter',
  'S73046S Central dislocation of unspecified hip, sequela',
  'S73101A Unspecified sprain of right hip, initial encounter',
  'S73101D Unspecified sprain of right hip, subsequent encounter',
  'S73101S Unspecified sprain of right hip, sequela',
  'S73102A Unspecified sprain of left hip, initial encounter',
  'S73102D Unspecified sprain of left hip, subsequent encounter',
  'S73102S Unspecified sprain of left hip, sequela',
  'S73109A Unspecified sprain of unspecified hip, initial encounter',
  'S73109D Unspecified sprain of unspecified hip, subsequent encounter',
  'S73109S Unspecified sprain of unspecified hip, sequela',
  'S73111A Iliofemoral ligament sprain of right hip, initial encounter',
  'S73111D Iliofemoral ligament sprain of right hip, subsequent encounter',
  'S73111S Iliofemoral ligament sprain of right hip, sequela',
  'S73112A Iliofemoral ligament sprain of left hip, initial encounter',
  'S73112D Iliofemoral ligament sprain of left hip, subsequent encounter',
  'S73112S Iliofemoral ligament sprain of left hip, sequela',
  'S73119A Iliofemoral ligament sprain of unspecified hip, initial encounter',
  'S73119D Iliofemoral ligament sprain of unspecified hip, subsequent encounter',
  'S73119S Iliofemoral ligament sprain of unspecified hip, sequela',
  'S73121A Ischiocapsular ligament sprain of right hip, initial encounter',
  'S73121D Ischiocapsular ligament sprain of right hip, subsequent encounter',
  'S73121S Ischiocapsular ligament sprain of right hip, sequela',
  'S73122A Ischiocapsular ligament sprain of left hip, initial encounter',
  'S73122D Ischiocapsular ligament sprain of left hip, subsequent encounter',
  'S73122S Ischiocapsular ligament sprain of left hip, sequela',
  'S73129A Ischiocapsular ligament sprain of unspecified hip, initial encounter',
  'S73129D Ischiocapsular ligament sprain of unspecified hip, subsequent encounter',
  'S73129S Ischiocapsular ligament sprain of unspecified hip, sequela',
  'S73191A Other sprain of right hip, initial encounter',
  'S73191D Other sprain of right hip, subsequent encounter',
  'S73191S Other sprain of right hip, sequela',
  'S73192A Other sprain of left hip, initial encounter',
  'S73192D Other sprain of left hip, subsequent encounter',
  'S73192S Other sprain of left hip, sequela',
  'S73199A Other sprain of unspecified hip, initial encounter',
  'S73199D Other sprain of unspecified hip, subsequent encounter',
  'S73199S Other sprain of unspecified hip, sequela',
  'S7400XA Injury of sciatic nerve at hip and thigh level, unspecified leg, initial encounter',
  'S7400XD Injury of sciatic nerve at hip and thigh level, unspecified leg, subsequent encounter',
  'S7400XS Injury of sciatic nerve at hip and thigh level, unspecified leg, sequela',
  'S7401XA Injury of sciatic nerve at hip and thigh level, right leg, initial encounter',
  'S7401XD Injury of sciatic nerve at hip and thigh level, right leg, subsequent encounter',
  'S7401XS Injury of sciatic nerve at hip and thigh level, right leg, sequela',
  'S7402XA Injury of sciatic nerve at hip and thigh level, left leg, initial encounter',
  'S7402XD Injury of sciatic nerve at hip and thigh level, left leg, subsequent encounter',
  'S7402XS Injury of sciatic nerve at hip and thigh level, left leg, sequela',
  'S7410XA Injury of femoral nerve at hip and thigh level, unspecified leg, initial encounter',
  'S7410XD Injury of femoral nerve at hip and thigh level, unspecified leg, subsequent encounter',
  'S7410XS Injury of femoral nerve at hip and thigh level, unspecified leg, sequela',
  'S7411XA Injury of femoral nerve at hip and thigh level, right leg, initial encounter',
  'S7411XD Injury of femoral nerve at hip and thigh level, right leg, subsequent encounter',
  'S7411XS Injury of femoral nerve at hip and thigh level, right leg, sequela',
  'S7412XA Injury of femoral nerve at hip and thigh level, left leg, initial encounter',
  'S7412XD Injury of femoral nerve at hip and thigh level, left leg, subsequent encounter',
  'S7412XS Injury of femoral nerve at hip and thigh level, left leg, sequela',
  'S7420XA Injury of cutaneous sensory nerve at hip and thigh level, unspecified leg, initial encounter',
  'S7420XD Injury of cutaneous sensory nerve at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S7420XS Injury of cutaneous sensory nerve at hip and thigh level, unspecified leg, sequela',
  'S7421XA Injury of cutaneous sensory nerve at hip and high level, right leg, initial encounter',
  'S7421XD Injury of cutaneous sensory nerve at hip and high level, right leg, subsequent encounter',
  'S7421XS Injury of cutaneous sensory nerve at hip and high level, right leg, sequela',
  'S7422XA Injury of cutaneous sensory nerve at hip and thigh level, left leg, initial encounter',
  'S7422XD Injury of cutaneous sensory nerve at hip and thigh level, left leg, subsequent encounter',
  'S7422XS Injury of cutaneous sensory nerve at hip and thigh level, left leg, sequela',
  'S748X1A Injury of other nerves at hip and thigh level, right leg, initial encounter',
  'S748X1D Injury of other nerves at hip and thigh level, right leg, subsequent encounter',
  'S748X1S Injury of other nerves at hip and thigh level, right leg, sequela',
  'S748X2A Injury of other nerves at hip and thigh level, left leg, initial encounter',
  'S748X2D Injury of other nerves at hip and thigh level, left leg, subsequent encounter',
  'S748X2S Injury of other nerves at hip and thigh level, left leg, sequela',
  'S748X9A Injury of other nerves at hip and thigh level, unspecified leg, initial encounter',
  'S748X9D Injury of other nerves at hip and thigh level, unspecified leg, subsequent encounter',
  'S748X9S Injury of other nerves at hip and thigh level, unspecified leg, sequela',
  'S7490XA Injury of unspecified nerve at hip and thigh level, unspecified leg, initial encounter',
  'S7490XD Injury of unspecified nerve at hip and thigh level, unspecified leg, subsequent encounter',
  'S7490XS Injury of unspecified nerve at hip and thigh level, unspecified leg, sequela',
  'S7491XA Injury of unspecified nerve at hip and thigh level, right leg, initial encounter',
  'S7491XD Injury of unspecified nerve at hip and thigh level, right leg, subsequent encounter',
  'S7491XS Injury of unspecified nerve at hip and thigh level, right leg, sequela',
  'S7492XA Injury of unspecified nerve at hip and thigh level, left leg, initial encounter',
  'S7492XD Injury of unspecified nerve at hip and thigh level, left leg, subsequent encounter',
  'S7492XS Injury of unspecified nerve at hip and thigh level, left leg, sequela',
  'S75001A Unspecified injury of femoral artery, right leg, initial encounter',
  'S75001D Unspecified injury of femoral artery, right leg, subsequent encounter',
  'S75001S Unspecified injury of femoral artery, right leg, sequela',
  'S75002A Unspecified injury of femoral artery, left leg, initial encounter',
  'S75002D Unspecified injury of femoral artery, left leg, subsequent encounter',
  'S75002S Unspecified injury of femoral artery, left leg, sequela',
  'S75009A Unspecified injury of femoral artery, unspecified leg, initial encounter',
  'S75009D Unspecified injury of femoral artery, unspecified leg, subsequent encounter',
  'S75009S Unspecified injury of femoral artery, unspecified leg, sequela',
  'S75011A Minor laceration of femoral artery, right leg, initial encounter',
  'S75011D Minor laceration of femoral artery, right leg, subsequent encounter',
  'S75011S Minor laceration of femoral artery, right leg, sequela',
  'S75012A Minor laceration of femoral artery, left leg, initial encounter',
  'S75012D Minor laceration of femoral artery, left leg, subsequent encounter',
  'S75012S Minor laceration of femoral artery, left leg, sequela',
  'S75019A Minor laceration of femoral artery, unspecified leg, initial encounter',
  'S75019D Minor laceration of femoral artery, unspecified leg, subsequent encounter',
  'S75019S Minor laceration of femoral artery, unspecified leg, sequela',
  'S75021A Major laceration of femoral artery, right leg, initial encounter',
  'S75021D Major laceration of femoral artery, right leg, subsequent encounter',
  'S75021S Major laceration of femoral artery, right leg, sequela',
  'S75022A Major laceration of femoral artery, left leg, initial encounter',
  'S75022D Major laceration of femoral artery, left leg, subsequent encounter',
  'S75022S Major laceration of femoral artery, left leg, sequela',
  'S75029A Major laceration of femoral artery, unspecified leg, initial encounter',
  'S75029D Major laceration of femoral artery, unspecified leg, subsequent encounter',
  'S75029S Major laceration of femoral artery, unspecified leg, sequela',
  'S75091A Other specified injury of femoral artery, right leg, initial encounter',
  'S75091D Other specified injury of femoral artery, right leg, subsequent encounter',
  'S75091S Other specified injury of femoral artery, right leg, sequela',
  'S75092A Other specified injury of femoral artery, left leg, initial encounter',
  'S75092D Other specified injury of femoral artery, left leg, subsequent encounter',
  'S75092S Other specified injury of femoral artery, left leg, sequela',
  'S75099A Other specified injury of femoral artery, unspecified leg, initial encounter',
  'S75099D Other specified injury of femoral artery, unspecified leg, subsequent encounter',
  'S75099S Other specified injury of femoral artery, unspecified leg, sequela',
  'S75101A Unspecified injury of femoral vein at hip and thigh level, right leg, initial encounter',
  'S75101D Unspecified injury of femoral vein at hip and thigh level, right leg, subsequent encounter',
  'S75101S Unspecified injury of femoral vein at hip and thigh level, right leg, sequela',
  'S75102A Unspecified injury of femoral vein at hip and thigh level, left leg, initial encounter',
  'S75102D Unspecified injury of femoral vein at hip and thigh level, left leg, subsequent encounter',
  'S75102S Unspecified injury of femoral vein at hip and thigh level, left leg, sequela',
  'S75109A Unspecified injury of femoral vein at hip and thigh level, unspecified leg, initial encounter',
  'S75109D Unspecified injury of femoral vein at hip and thigh level, unspecified leg, subsequent encounter',
  'S75109S Unspecified injury of femoral vein at hip and thigh level, unspecified leg, sequela',
  'S75111A Minor laceration of femoral vein at hip and thigh level, right leg, initial encounter',
  'S75111D Minor laceration of femoral vein at hip and thigh level, right leg, subsequent encounter',
  'S75111S Minor laceration of femoral vein at hip and thigh level, right leg, sequela',
  'S75112A Minor laceration of femoral vein at hip and thigh level, left leg, initial encounter',
  'S75112D Minor laceration of femoral vein at hip and thigh level, left leg, subsequent encounter',
  'S75112S Minor laceration of femoral vein at hip and thigh level, left leg, sequela',
  'S75119A Minor laceration of femoral vein at hip and thigh level, unspecified leg, initial encounter',
  'S75119D Minor laceration of femoral vein at hip and thigh level, unspecified leg, subsequent encounter',
  'S75119S Minor laceration of femoral vein at hip and thigh level, unspecified leg, sequela',
  'S75121A Major laceration of femoral vein at hip and thigh level, right leg, initial encounter',
  'S75121D Major laceration of femoral vein at hip and thigh level, right leg, subsequent encounter',
  'S75121S Major laceration of femoral vein at hip and thigh level, right leg, sequela',
  'S75122A Major laceration of femoral vein at hip and thigh level, left leg, initial encounter',
  'S75122D Major laceration of femoral vein at hip and thigh level, left leg, subsequent encounter',
  'S75122S Major laceration of femoral vein at hip and thigh level, left leg, sequela',
  'S75129A Major laceration of femoral vein at hip and thigh level, unspecified leg, initial encounter',
  'S75129D Major laceration of femoral vein at hip and thigh level, unspecified leg, subsequent encounter',
  'S75129S Major laceration of femoral vein at hip and thigh level, unspecified leg, sequela',
  'S75191A Other specified injury of femoral vein at hip and thigh level, right leg, initial encounter',
  'S75191D Other specified injury of femoral vein at hip and thigh level, right leg, subsequent encounter',
  'S75191S Other specified injury of femoral vein at hip and thigh level, right leg, sequela',
  'S75192A Other specified injury of femoral vein at hip and thigh level, left leg, initial encounter',
  'S75192D Other specified injury of femoral vein at hip and thigh level, left leg, subsequent encounter',
  'S75192S Other specified injury of femoral vein at hip and thigh level, left leg, sequela',
  'S75199A Other specified injury of femoral vein at hip and thigh level, unspecified leg, initial encounter',
  'S75199D Other specified injury of femoral vein at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S75199S Other specified injury of femoral vein at hip and thigh level, unspecified leg, sequela',
  'S75201A Unspecified injury of greater saphenous vein at hip and thigh level, right leg, initial encounter',
  'S75201D Unspecified injury of greater saphenous vein at hip and thigh level, right leg, subsequent',
  'encounter',
  'S75201S Unspecified injury of greater saphenous vein at hip and thigh level, right leg, sequela',
  'S75202A Unspecified injury of greater saphenous vein at hip and thigh level, left leg, initial encounter',
  'S75202D Unspecified injury of greater saphenous vein at hip and thigh level, left leg, subsequent',
  'encounter',
  'S75202S Unspecified injury of greater saphenous vein at hip and thigh level, left leg, sequela',
  'S75209A Unspecified injury of greater saphenous vein at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75209D Unspecified injury of greater saphenous vein at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S75209S Unspecified injury of greater saphenous vein at hip and thigh level, unspecified leg, sequela',
  'S75211A Minor laceration of greater saphenous vein at hip and thigh level, right leg, initial encounter',
  'S75211D Minor laceration of greater saphenous vein at hip and thigh level, right leg, subsequent',
  'encounter',
  'S75211S Minor laceration of greater saphenous vein at hip and thigh level, right leg, sequela',
  'S75212A Minor laceration of greater saphenous vein at hip and thigh level, left leg, initial encounter',
  'S75212D Minor laceration of greater saphenous vein at hip and thigh level, left leg, subsequent',
  'encounter',
  'S75212S Minor laceration of greater saphenous vein at hip and thigh level, left leg, sequela',
  'S75219A Minor laceration of greater saphenous vein at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75219D Minor laceration of greater saphenous vein at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S75219S Minor laceration of greater saphenous vein at hip and thigh level, unspecified leg, sequela',
  'S75221A Major laceration of greater saphenous vein at hip and thigh level, right leg, initial encounter',
  'S75221D Major laceration of greater saphenous vein at hip and thigh level, right leg, subsequent',
  'encounter',
  'S75221S Major laceration of greater saphenous vein at hip and thigh level, right leg, sequela',
  'S75222A Major laceration of greater saphenous vein at hip and thigh level, left leg, initial encounter',
  'S75222D Major laceration of greater saphenous vein at hip and thigh level, left leg, subsequent',
  'encounter',
  'S75222S Major laceration of greater saphenous vein at hip and thigh level, left leg, sequela',
  'S75229A Major laceration of greater saphenous vein at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75229D Major laceration of greater saphenous vein at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S75229S Major laceration of greater saphenous vein at hip and thigh level, unspecified leg, sequela',
  'S75291A Other specified injury of greater saphenous vein at hip and thigh level, right leg, initial',
  'encounter',
  'S75291D Other specified injury of greater saphenous vein at hip and thigh level, right leg, subsequent',
  'encounter',
  'S75291S Other specified injury of greater saphenous vein at hip and thigh level, right leg, sequela',
  'S75292A Other specified injury of greater saphenous vein at hip and thigh level, left leg, initial',
  'encounter',
  'S75292D Other specified injury of greater saphenous vein at hip and thigh level, left leg, subsequent',
  'encounter',
  'S75292S Other specified injury of greater saphenous vein at hip and thigh level, left leg, sequela',
  'S75299A Other specified injury of greater saphenous vein at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75299D Other specified injury of greater saphenous vein at hip and thigh level, unspecified leg,',
  'subsequent encounter',
  'S75299S Other specified injury of greater saphenous vein at hip and thigh level, unspecified leg, sequela',
  'S75801A Unspecified injury of other blood vessels at hip and thigh level, right leg, initial encounter',
  'S75801D Unspecified injury of other blood vessels at hip and thigh level, right leg, subsequent encounter',
  'S75801S Unspecified injury of other blood vessels at hip and thigh level, right leg, sequela',
  'S75802A Unspecified injury of other blood vessels at hip and thigh level, left leg, initial encounter',
  'S75802D Unspecified injury of other blood vessels at hip and thigh level, left leg, subsequent encounter',
  'S75802S Unspecified injury of other blood vessels at hip and thigh level, left leg, sequela',
  'S75809A Unspecified injury of other blood vessels at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75809D Unspecified injury of other blood vessels at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S75809S Unspecified injury of other blood vessels at hip and thigh level, unspecified leg, sequela',
  'S75811A Laceration of other blood vessels at hip and thigh level, right leg, initial encounter',
  'S75811D Laceration of other blood vessels at hip and thigh level, right leg, subsequent encounter',
  'S75811S Laceration of other blood vessels at hip and thigh level, right leg, sequela',
  'S75812A Laceration of other blood vessels at hip and thigh level, left leg, initial encounter',
  'S75812D Laceration of other blood vessels at hip and thigh level, left leg, subsequent encounter',
  'S75812S Laceration of other blood vessels at hip and thigh level, left leg, sequela',
  'S75819A Laceration of other blood vessels at hip and thigh level, unspecified leg, initial encounter',
  'S75819D Laceration of other blood vessels at hip and thigh level, unspecified leg, subsequent encounter',
  'S75819S Laceration of other blood vessels at hip and thigh level, unspecified leg, sequela',
  'S75891A Other specified injury of other blood vessels at hip and thigh level, right leg, initial encounter',
  'S75891D Other specified injury of other blood vessels at hip and thigh level, right leg, subsequent',
  'encounter',
  'S75891S Other specified injury of other blood vessels at hip and thigh level, right leg, sequela',
  'S75892A Other specified injury of other blood vessels at hip and thigh level, left leg, initial encounter',
  'S75892D Other specified injury of other blood vessels at hip and thigh level, left leg, subsequent',
  'encounter',
  'S75892S Other specified injury of other blood vessels at hip and thigh level, left leg, sequela',
  'S75899A Other specified injury of other blood vessels at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75899D Other specified injury of other blood vessels at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S75899S Other specified injury of other blood vessels at hip and thigh level, unspecified leg, sequela',
  'S75901A Unspecified injury of unspecified blood vessel at hip and thigh level, right leg, initial encounter',
  'S75901D Unspecified injury of unspecified blood vessel at hip and thigh level, right leg, subsequent',
  'encounter',
  'S75901S Unspecified injury of unspecified blood vessel at hip and thigh level, right leg, sequela',
  'S75902A Unspecified injury of unspecified blood vessel at hip and thigh level, left leg, initial encounter',
  'S75902D Unspecified injury of unspecified blood vessel at hip and thigh level, left leg, subsequent',
  'encounter',
  'S75902S Unspecified injury of unspecified blood vessel at hip and thigh level, left leg, sequela',
  'S75909A Unspecified injury of unspecified blood vessel at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75909D Unspecified injury of unspecified blood vessel at hip and thigh level, unspecified leg,',
  'subsequent encounter',
  'S75909S Unspecified injury of unspecified blood vessel at hip and thigh level, unspecified leg, sequela',
  'S75911A Laceration of unspecified blood vessel at hip and thigh level, right leg, initial encounter',
  'S75911D Laceration of unspecified blood vessel at hip and thigh level, right leg, subsequent encounter',
  'S75911S Laceration of unspecified blood vessel at hip and thigh level, right leg, sequela',
  'S75912A Laceration of unspecified blood vessel at hip and thigh level, left leg, initial encounter',
  'S75912D Laceration of unspecified blood vessel at hip and thigh level, left leg, subsequent encounter',
  'S75912S Laceration of unspecified blood vessel at hip and thigh level, left leg, sequela',
  'S75919A Laceration of unspecified blood vessel at hip and thigh level, unspecified leg, initial encounter',
  'S75919D Laceration of unspecified blood vessel at hip and thigh level, unspecified leg, subsequent',
  'encounter',
  'S75919S Laceration of unspecified blood vessel at hip and thigh level, unspecified leg, sequela',
  'S75991A Other specified injury of unspecified blood vessel at hip and thigh level, right leg, initial',
  'encounter',
  'S75991D Other specified injury of unspecified blood vessel at hip and thigh level, right leg, subsequent',
  'encounter',
  'S75991S Other specified injury of unspecified blood vessel at hip and thigh level, right leg, sequela',
  'S75992A Other specified injury of unspecified blood vessel at hip and thigh level, left leg, initial',
  'encounter',
  'S75992D Other specified injury of unspecified blood vessel at hip and thigh level, left leg, subsequent',
  'encounter',
  'S75992S Other specified injury of unspecified blood vessel at hip and thigh level, left leg, sequela',
  'S75999A Other specified injury of unspecified blood vessel at hip and thigh level, unspecified leg, initial',
  'encounter',
  'S75999D Other specified injury of unspecified blood vessel at hip and thigh level, unspecified leg,',
  'subsequent encounter',
  'S75999S Other specified injury of unspecified blood vessel at hip and thigh level, unspecified leg,',
  'sequela',
  'S76001A Unspecified injury of muscle, fascia and tendon of right hip, initial encounter',
  'S76001D Unspecified injury of muscle, fascia and tendon of right hip, subsequent encounter',
  'S76001S Unspecified injury of muscle, fascia and tendon of right hip, sequela',
  'S76002A Unspecified injury of muscle, fascia and tendon of left hip, initial encounter',
  'S76002D Unspecified injury of muscle, fascia and tendon of left hip, subsequent encounter',
  'S76002S Unspecified injury of muscle, fascia and tendon of left hip, sequela',
  'S76009A Unspecified injury of muscle, fascia and tendon of unspecified hip, initial encounter',
  'S76009D Unspecified injury of muscle, fascia and tendon of unspecified hip, subsequent encounter',
  'S76009S Unspecified injury of muscle, fascia and tendon of unspecified hip, sequela',
  'S76011A Strain of muscle, fascia and tendon of right hip, initial encounter',
  'S76011D Strain of muscle, fascia and tendon of right hip, subsequent encounter',
  'S76011S Strain of muscle, fascia and tendon of right hip, sequela',
  'S76012A Strain of muscle, fascia and tendon of left hip, initial encounter',
  'S76012D Strain of muscle, fascia and tendon of left hip, subsequent encounter',
  'S76012S Strain of muscle, fascia and tendon of left hip, sequela',
  'S76019A Strain of muscle, fascia and tendon of unspecified hip, initial encounter',
  'S76019D Strain of muscle, fascia and tendon of unspecified hip, subsequent encounter',
  'S76019S Strain of muscle, fascia and tendon of unspecified hip, sequela',
  'S76021A Laceration of muscle, fascia and tendon of right hip, initial encounter',
  'S76021D Laceration of muscle, fascia and tendon of right hip, subsequent encounter',
  'S76021S Laceration of muscle, fascia and tendon of right hip, sequela',
  'S76022A Laceration of muscle, fascia and tendon of left hip, initial encounter',
  'S76022D Laceration of muscle, fascia and tendon of left hip, subsequent encounter',
  'S76022S Laceration of muscle, fascia and tendon of left hip, sequela',
  'S76029A Laceration of muscle, fascia and tendon of unspecified hip, initial encounter',
  'S76029D Laceration of muscle, fascia and tendon of unspecified hip, subsequent encounter',
  'S76029S Laceration of muscle, fascia and tendon of unspecified hip, sequela',
  'S76091A Other specified injury of muscle, fascia and tendon of right hip, initial encounter',
  'S76091D Other specified injury of muscle, fascia and tendon of right hip, subsequent encounter',
  'S76091S Other specified injury of muscle, fascia and tendon of right hip, sequela',
  'S76092A Other specified injury of muscle, fascia and tendon of left hip, initial encounter',
  'S76092D Other specified injury of muscle, fascia and tendon of left hip, subsequent encounter',
  'S76092S Other specified injury of muscle, fascia and tendon of left hip, sequela',
  'S76099A Other specified injury of muscle, fascia and tendon of unspecified hip, initial encounter',
  'S76099D Other specified injury of muscle, fascia and tendon of unspecified hip, subsequent encounter',
  'S76099S Other specified injury of muscle, fascia and tendon of unspecified hip, sequela',
  'S76101A Unspecified injury of right quadriceps muscle, fascia and tendon, initial encounter',
  'S76101D Unspecified injury of right quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76101S Unspecified injury of right quadriceps muscle, fascia and tendon, sequela',
  'S76102A Unspecified injury of left quadriceps muscle, fascia and tendon, initial encounter',
  'S76102D Unspecified injury of left quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76102S Unspecified injury of left quadriceps muscle, fascia and tendon, sequela',
  'S76109A Unspecified injury of unspecified quadriceps muscle, fascia and tendon, initial encounter',
  'S76109D Unspecified injury of unspecified quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76109S Unspecified injury of unspecified quadriceps muscle, fascia and tendon, sequela',
  'S76111A Strain of right quadriceps muscle, fascia and tendon, initial encounter',
  'S76111D Strain of right quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76111S Strain of right quadriceps muscle, fascia and tendon, sequela',
  'S76112A Strain of left quadriceps muscle, fascia and tendon, initial encounter',
  'S76112D Strain of left quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76112S Strain of left quadriceps muscle, fascia and tendon, sequela',
  'S76119A Strain of unspecified quadriceps muscle, fascia and tendon, initial encounter',
  'S76119D Strain of unspecified quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76119S Strain of unspecified quadriceps muscle, fascia and tendon, sequela',
  'S76121A Laceration of right quadriceps muscle, fascia and tendon, initial encounter',
  'S76121D Laceration of right quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76121S Laceration of right quadriceps muscle, fascia and tendon, sequela',
  'S76122A Laceration of left quadriceps muscle, fascia and tendon, initial encounter',
  'S76122D Laceration of left quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76122S Laceration of left quadriceps muscle, fascia and tendon, sequela',
  'S76129A Laceration of unspecified quadriceps muscle, fascia and tendon, initial encounter',
  'S76129D Laceration of unspecified quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76129S Laceration of unspecified quadriceps muscle, fascia and tendon, sequela',
  'S76191A Other specified injury of right quadriceps muscle, fascia and tendon, initial encounter',
  'S76191D Other specified injury of right quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76191S Other specified injury of right quadriceps muscle, fascia and tendon, sequela',
  'S76192A Other specified injury of left quadriceps muscle, fascia and tendon, initial encounter',
  'S76192D Other specified injury of left quadriceps muscle, fascia and tendon, subsequent encounter',
  'S76192S Other specified injury of left quadriceps muscle, fascia and tendon, sequela',
  'S76199A Other specified injury of unspecified quadriceps muscle, fascia and tendon, initial encounter',
  'S76199D Other specified injury of unspecified quadriceps muscle, fascia and tendon, subsequent',
  'encounter',
  'S76199S Other specified injury of unspecified quadriceps muscle, fascia and tendon, sequela',
  'S76201A Unspecified injury of adductor muscle, fascia and tendon of right thigh, initial encounter',
  'S76201D Unspecified injury of adductor muscle, fascia and tendon of right thigh, subsequent encounter',
  'S76201S Unspecified injury of adductor muscle, fascia and tendon of right thigh, sequela',
  'S76202A Unspecified injury of adductor muscle, fascia and tendon of left thigh, initial encounter',
  'S76202D Unspecified injury of adductor muscle, fascia and tendon of left thigh, subsequent encounter',
  'S76202S Unspecified injury of adductor muscle, fascia and tendon of left thigh, sequela',
  'S76209A Unspecified injury of adductor muscle, fascia and tendon of unspecified thigh, initial encounter',
  'S76209D Unspecified injury of adductor muscle, fascia and tendon of unspecified thigh, subsequent',
  'encounter',
  'S76209S Unspecified injury of adductor muscle, fascia and tendon of unspecified thigh, sequela',
  'S76211A Strain of adductor muscle, fascia and tendon of right thigh, initial encounter',
  'S76211D Strain of adductor muscle, fascia and tendon of right thigh, subsequent encounter',
  'S76211S Strain of adductor muscle, fascia and tendon of right thigh, sequela',
  'S76212A Strain of adductor muscle, fascia and tendon of left thigh, initial encounter',
  'S76212D Strain of adductor muscle, fascia and tendon of left thigh, subsequent encounter',
  'S76212S Strain of adductor muscle, fascia and tendon of left thigh, sequela',
  'S76219A Strain of adductor muscle, fascia and tendon of unspecified thigh, initial encounter',
  'S76219D Strain of adductor muscle, fascia and tendon of unspecified thigh, subsequent encounter',
  'S76219S Strain of adductor muscle, fascia and tendon of unspecified thigh, sequela',
  'S76221A Laceration of adductor muscle, fascia and tendon of right thigh, initial encounter',
  'S76221D Laceration of adductor muscle, fascia and tendon of right thigh, subsequent encounter',
  'S76221S Laceration of adductor muscle, fascia and tendon of right thigh, sequela',
  'S76222A Laceration of adductor muscle, fascia and tendon of left thigh, initial encounter',
  'S76222D Laceration of adductor muscle, fascia and tendon of left thigh, subsequent encounter',
  'S76222S Laceration of adductor muscle, fascia and tendon of left thigh, sequela',
  'S76229A Laceration of adductor muscle, fascia and tendon of unspecified thigh, initial encounter',
  'S76229D Laceration of adductor muscle, fascia and tendon of unspecified thigh, subsequent encounter',
  'S76229S Laceration of adductor muscle, fascia and tendon of unspecified thigh, sequela',
  'S76291A Other injury of adductor muscle, fascia and tendon of right thigh, initial encounter',
  'S76291D Other injury of adductor muscle, fascia and tendon of right thigh, subsequent encounter',
  'S76291S Other injury of adductor muscle, fascia and tendon of right thigh, sequela',
  'S76292A Other injury of adductor muscle, fascia and tendon of left thigh, initial encounter',
  'S76292D Other injury of adductor muscle, fascia and tendon of left thigh, subsequent encounter',
  'S76292S Other injury of adductor muscle, fascia and tendon of left thigh, sequela',
  'S76299A Other injury of adductor muscle, fascia and tendon of unspecified thigh, initial encounter',
  'S76299D Other injury of adductor muscle, fascia and tendon of unspecified thigh, subsequent encounter',
  'S76299S Other injury of adductor muscle, fascia and tendon of unspecified thigh, sequela',
  'S76301A Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'right thigh, initial encounter',
  'S76301D Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'right thigh, subsequent encounter',
  'S76301S Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'right thigh, sequela',
  'S76302A Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'left thigh, initial encounter',
  'S76302D Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'left thigh, subsequent encounter',
  'S76302S Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level, left',
  'thigh, sequela',
  'S76309A Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, initial encounter',
  'S76309D Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, subsequent encounter',
  'S76309S Unspecified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, sequela',
  'S76311A Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, right thigh,',
  'initial encounter',
  'S76311D Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, right thigh,',
  'subsequent encounter',
  'S76311S Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, right thigh,',
  'sequela',
  'S76312A Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, left thigh,',
  'initial encounter',
  'S76312D Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, left thigh,',
  'subsequent encounter',
  'S76312S Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, left thigh,',
  'sequela',
  'S76319A Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, unspecified',
  'thigh, initial encounter',
  'S76319D Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, unspecified',
  'thigh, subsequent encounter',
  'S76319S Strain of muscle, fascia and tendon of the posterior muscle group at thigh level, unspecified',
  'thigh, sequela',
  'S76321A Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level, right',
  'thigh, initial encounter',
  'S76321D Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level, right',
  'thigh, subsequent encounter',
  'S76321S Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level, right thigh,',
  'sequela',
  'S76322A Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level, left thigh,',
  'initial encounter',
  'S76322D Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level, left thigh,',
  'subsequent encounter',
  'S76322S Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level, left thigh,',
  'sequela',
  'S76329A Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, initial encounter',
  'S76329D Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, subsequent encounter',
  'S76329S Laceration of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, sequela',
  'S76391A Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'right thigh, initial encounter',
  'S76391D Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'right thigh, subsequent encounter',
  'S76391S Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'right thigh, sequela',
  'S76392A Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'left thigh, initial encounter',
  'S76392D Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'left thigh, subsequent encounter',
  'S76392S Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'left thigh, sequela',
  'S76399A Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, initial encounter',
  'S76399D Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, subsequent encounter',
  'S76399S Other specified injury of muscle, fascia and tendon of the posterior muscle group at thigh level,',
  'unspecified thigh, sequela',
  'S76801A Unspecified injury of other specified muscles, fascia and tendons at thigh level, right thigh,',
  'initial encounter',
  'S76801D Unspecified injury of other specified muscles, fascia and tendons at thigh level, right thigh,',
  'subsequent encounter',
  'S76801S Unspecified injury of other specified muscles, fascia and tendons at thigh level, right thigh,',
  'sequela',
  'S76802A Unspecified injury of other specified muscles, fascia and tendons at thigh level, left thigh, initial',
  'encounter',
  'S76802D Unspecified injury of other specified muscles, fascia and tendons at thigh level, left thigh,',
  'subsequent encounter',
  'S76802S Unspecified injury of other specified muscles, fascia and tendons at thigh level, left thigh,',
  'sequela',
  'S76809A Unspecified injury of other specified muscles, fascia and tendons at thigh level, unspecified',
  'thigh, initial encounter',
  'S76809D Unspecified injury of other specified muscles, fascia and tendons at thigh level, unspecified',
  'thigh, subsequent encounter',
  'S76809S Unspecified injury of other specified muscles, fascia and tendons at thigh level, unspecified',
  'thigh, sequela',
  'S76811A Strain of other specified muscles, fascia and tendons at thigh level, right thigh, initial encounter',
  'S76811D Strain of other specified muscles, fascia and tendons at thigh level, right thigh, subsequent',
  'encounter',
  'S76811S Strain of other specified muscles, fascia and tendons at thigh level, right thigh, sequela',
  'S76812A Strain of other specified muscles, fascia and tendons at thigh level, left thigh, initial encounter',
  'S76812D Strain of other specified muscles, fascia and tendons at thigh level, left thigh, subsequent',
  'encounter',
  'S76812S Strain of other specified muscles, fascia and tendons at thigh level, left thigh, sequela',
  'S76819A Strain of other specified muscles, fascia and tendons at thigh level, unspecified thigh, initial',
  'encounter',
  'S76819D Strain of other specified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'subsequent encounter',
  'S76819S Strain of other specified muscles, fascia and tendons at thigh level, unspecified thigh, sequela',
  'S76821A Laceration of other specified muscles, fascia and tendons at thigh level, right thigh, initial',
  'encounter',
  'S76821D Laceration of other specified muscles, fascia and tendons at thigh level, right thigh, subsequent',
  'encounter',
  'S76821S Laceration of other specified muscles, fascia and tendons at thigh level, right thigh, sequela',
  'S76822A Laceration of other specified muscles, fascia and tendons at thigh level, left thigh, initial',
  'encounter',
  'S76822D Laceration of other specified muscles, fascia and tendons at thigh level, left thigh, subsequent',
  'encounter',
  'S76822S Laceration of other specified muscles, fascia and tendons at thigh level, left thigh, sequela',
  'S76829A Laceration of other specified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'initial encounter',
  'S76829D Laceration of other specified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'subsequent encounter',
  'S76829S Laceration of other specified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'sequela',
  'S76891A Other injury of other specified muscles, fascia and tendons at thigh level, right thigh, initial',
  'encounter',
  'S76891D Other injury of other specified muscles, fascia and tendons at thigh level, right thigh,',
  'subsequent encounter',
  'S76891S Other injury of other specified muscles, fascia and tendons at thigh level, right thigh, sequela',
  'S76892A Other injury of other specified muscles, fascia and tendons at thigh level, left thigh, initial',
  'encounter',
  'S76892D Other injury of other specified muscles, fascia and tendons at thigh level, left thigh,',
  'subsequent encounter',
  'S76892S Other injury of other specified muscles, fascia and tendons at thigh level, left thigh, sequela',
  'S76899A Other injury of other specified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'initial encounter',
  'S76899D Other injury of other specified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'subsequent encounter',
  'S76899S Other injury of other specified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'sequela',
  'S76901A Unspecified injury of unspecified muscles, fascia and tendons at thigh level, right thigh, initial',
  'encounter',
  'S76901D Unspecified injury of unspecified muscles, fascia and tendons at thigh level, right thigh,',
  'subsequent encounter',
  'S76901S Unspecified injury of unspecified muscles, fascia and tendons at thigh level, right thigh, sequela',
  'S76902A Unspecified injury of unspecified muscles, fascia and tendons at thigh level, left thigh, initial',
  'encounter',
  'S76902D Unspecified injury of unspecified muscles, fascia and tendons at thigh level, left thigh,',
  'subsequent encounter',
  'S76902S Unspecified injury of unspecified muscles, fascia and tendons at thigh level, left thigh, sequela',
  'S76909A Unspecified injury of unspecified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'initial encounter',
  'S76909D Unspecified injury of unspecified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'subsequent encounter',
  'S76909S Unspecified injury of unspecified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'sequela',
  'S76911A Strain of unspecified muscles, fascia and tendons at thigh level, right thigh, initial encounter',
  'S76911D Strain of unspecified muscles, fascia and tendons at thigh level, right thigh, subsequent',
  'encounter',
  'S76911S Strain of unspecified muscles, fascia and tendons at thigh level, right thigh, sequela',
  'S76912A Strain of unspecified muscles, fascia and tendons at thigh level, left thigh, initial encounter',
  'S76912D Strain of unspecified muscles, fascia and tendons at thigh level, left thigh, subsequent',
  'encounter',
  'S76912S Strain of unspecified muscles, fascia and tendons at thigh level, left thigh, sequela',
  'S76919A Strain of unspecified muscles, fascia and tendons at thigh level, unspecified thigh, initial',
  'encounter',
  'S76919D Strain of unspecified muscles, fascia and tendons at thigh level, unspecified thigh, subsequent',
  'encounter',
  'S76919S Strain of unspecified muscles, fascia and tendons at thigh level, unspecified thigh, sequela',
  'S76921A Laceration of unspecified muscles, fascia and tendons at thigh level, right thigh, initial',
  'encounter',
  'S76921D Laceration of unspecified muscles, fascia and tendons at thigh level, right thigh, subsequent',
  'encounter',
  'S76921S Laceration of unspecified muscles, fascia and tendons at thigh level, right thigh, sequela',
  'S76922A Laceration of unspecified muscles, fascia and tendons at thigh level, left thigh, initial encounter',
  'S76922D Laceration of unspecified muscles, fascia and tendons at thigh level, left thigh, subsequent',
  'encounter',
  'S76922S Laceration of unspecified muscles, fascia and tendons at thigh level, left thigh, sequela',
  'S76929A Laceration of unspecified muscles, fascia and tendons at thigh level, unspecified thigh, initial',
  'encounter',
  'S76929D Laceration of unspecified muscles, fascia and tendons at thigh level, unspecified thigh,',
  'subsequent encounter',
  'S76929S Laceration of unspecified muscles, fascia and tendons at thigh level, unspecified thigh, sequela',
  'S76991A Other specified injury of unspecified muscles, fascia and tendons at thigh level, right thigh,',
  'initial encounter',
  'S76991D Other specified injury of unspecified muscles, fascia and tendons at thigh level, right thigh,',
  'subsequent encounter',
  'S76991S Other specified injury of unspecified muscles, fascia and tendons at thigh level, right thigh,',
  'sequela',
  'S76992A Other specified injury of unspecified muscles, fascia and tendons at thigh level, left thigh, initial',
  'encounter',
  'S76992D Other specified injury of unspecified muscles, fascia and tendons at thigh level, left thigh,',
  'subsequent encounter',
  'S76992S Other specified injury of unspecified muscles, fascia and tendons at thigh level, left thigh,',
  'sequela',
  'S76999A Other specified injury of unspecified muscles, fascia and tendons at thigh level, unspecified',
  'thigh, initial encounter',
  'S76999D Other specified injury of unspecified muscles, fascia and tendons at thigh level, unspecified',
  'thigh, subsequent encounter',
  'S76999S Other specified injury of unspecified muscles, fascia and tendons at thigh level, unspecified',
  'thigh, sequela',
  'S7700XA Crushing injury of unspecified hip, initial encounter',
  'S7700XD Crushing injury of unspecified hip, subsequent encounter',
  'S7700XS Crushing injury of unspecified hip, sequela',
  'S7701XA Crushing injury of right hip, initial encounter',
  'S7701XD Crushing injury of right hip, subsequent encounter',
  'S7701XS Crushing injury of right hip, sequela',
  'S7702XA Crushing injury of left hip, initial encounter',
  'S7702XD Crushing injury of left hip, subsequent encounter',
  'S7702XS Crushing injury of left hip, sequela',
  'S7710XA Crushing injury of unspecified thigh, initial encounter',
  'S7710XD Crushing injury of unspecified thigh, subsequent encounter',
  'S7710XS Crushing injury of unspecified thigh, sequela',
  'S7711XA Crushing injury of right thigh, initial encounter',
  'S7711XD Crushing injury of right thigh, subsequent encounter',
  'S7711XS Crushing injury of right thigh, sequela',
  'S7712XA Crushing injury of left thigh, initial encounter',
  'S7712XD Crushing injury of left thigh, subsequent encounter',
  'S7712XS Crushing injury of left thigh, sequela',
  'S7720XA Crushing injury of unspecified hip with thigh, initial encounter',
  'S7720XD Crushing injury of unspecified hip with thigh, subsequent encounter',
  'S7720XS Crushing injury of unspecified hip with thigh, sequela',
  'S7721XA Crushing injury of right hip with thigh, initial encounter',
  'S7721XD Crushing injury of right hip with thigh, subsequent encounter',
  'S7721XS Crushing injury of right hip with thigh, sequela',
  'S7722XA Crushing injury of left hip with thigh, initial encounter',
  'S7722XD Crushing injury of left hip with thigh, subsequent encounter',
  'S7722XS Crushing injury of left hip with thigh, sequela',
  'S78011A Complete traumatic amputation at right hip joint, initial encounter',
  'S78011D Complete traumatic amputation at right hip joint, subsequent encounter',
  'S78011S Complete traumatic amputation at right hip joint, sequela',
  'S78012A Complete traumatic amputation at left hip joint, initial encounter',
  'S78012D Complete traumatic amputation at left hip joint, subsequent encounter',
  'S78012S Complete traumatic amputation at left hip joint, sequela',
  'S78019A Complete traumatic amputation at unspecified hip joint, initial encounter',
  'S78019D Complete traumatic amputation at unspecified hip joint, subsequent encounter',
  'S78019S Complete traumatic amputation at unspecified hip joint, sequela',
  'S78021A Partial traumatic amputation at right hip joint, initial encounter',
  'S78021D Partial traumatic amputation at right hip joint, subsequent encounter',
  'S78021S Partial traumatic amputation at right hip joint, sequela',
  'S78022A Partial traumatic amputation at left hip joint, initial encounter',
  'S78022D Partial traumatic amputation at left hip joint, subsequent encounter',
  'S78022S Partial traumatic amputation at left hip joint, sequela',
  'S78029A Partial traumatic amputation at unspecified hip joint, initial encounter',
  'S78029D Partial traumatic amputation at unspecified hip joint, subsequent encounter',
  'S78029S Partial traumatic amputation at unspecified hip joint, sequela',
  'S78111A Complete traumatic amputation at level between right hip and knee, initial encounter',
  'S78111D Complete traumatic amputation at level between right hip and knee, subsequent encounter',
  'S78111S Complete traumatic amputation at level between right hip and knee, sequela',
  'S78112A Complete traumatic amputation at level between left hip and knee, initial encounter',
  'S78112D Complete traumatic amputation at level between left hip and knee, subsequent encounter',
  'S78112S Complete traumatic amputation at level between left hip and knee, sequela',
  'S78119A Complete traumatic amputation at level between unspecified hip and knee, initial encounter',
  'S78119D Complete traumatic amputation at level between unspecified hip and knee, subsequent',
  'encounter',
  'S78119S Complete traumatic amputation at level between unspecified hip and knee, sequela',
  'S78121A Partial traumatic amputation at level between right hip and knee, initial encounter',
  'S78121D Partial traumatic amputation at level between right hip and knee, subsequent encounter',
  'S78121S Partial traumatic amputation at level between right hip and knee, sequela',
  'S78122A Partial traumatic amputation at level between left hip and knee, initial encounter',
  'S78122D Partial traumatic amputation at level between left hip and knee, subsequent encounter',
  'S78122S Partial traumatic amputation at level between left hip and knee, sequela',
  'S78129A Partial traumatic amputation at level between unspecified hip and knee, initial encounter',
  'S78129D Partial traumatic amputation at level between unspecified hip and knee, subsequent',
  'encounter',
  'S78129S Partial traumatic amputation at level between unspecified hip and knee, sequela',
  'S78911A Complete traumatic amputation of right hip and thigh, level unspecified, initial encounter',
  'S78911D Complete traumatic amputation of right hip and thigh, level unspecified, subsequent',
  'encounter',
  'S78911S Complete traumatic amputation of right hip and thigh, level unspecified, sequela',
  'S78912A Complete traumatic amputation of left hip and thigh, level unspecified, initial encounter',
  'S78912D Complete traumatic amputation of left hip and thigh, level unspecified, subsequent encounter',
  'S78912S Complete traumatic amputation of left hip and thigh, level unspecified, sequela',
  'S78919A Complete traumatic amputation of unspecified hip and thigh, level unspecified, initial',
  'encounter',
  'S78919D Complete traumatic amputation of unspecified hip and thigh, level unspecified, subsequent',
  'encounter',
  'S78919S Complete traumatic amputation of unspecified hip and thigh, level unspecified, sequela',
  'S78921A Partial traumatic amputation of right hip and thigh, level unspecified, initial encounter',
  'S78921D Partial traumatic amputation of right hip and thigh, level unspecified, subsequent encounter',
  'S78921S Partial traumatic amputation of right hip and thigh, level unspecified, sequela',
  'S78922A Partial traumatic amputation of left hip and thigh, level unspecified, initial encounter',
  'S78922D Partial traumatic amputation of left hip and thigh, level unspecified, subsequent encounter',
  'S78922S Partial traumatic amputation of left hip and thigh, level unspecified, sequela',
  'S78929A Partial traumatic amputation of unspecified hip and thigh, level unspecified, initial encounter',
  'S78929D Partial traumatic amputation of unspecified hip and thigh, level unspecified, subsequent',
  'encounter',
  'S78929S Partial traumatic amputation of unspecified hip and thigh, level unspecified, sequela',
  'S79001A Unspecified physeal fracture of upper end of right femur, initial encounter for closed fracture',
  'S79001D Unspecified physeal fracture of upper end of right femur, subsequent encounter for fracture',
  'with routine healing',
  'S79001G Unspecified physeal fracture of upper end of right femur, subsequent encounter for fracture',
  'with delayed healing',
  'S79001K Unspecified physeal fracture of upper end of right femur, subsequent encounter for fracture',
  'with nonunion',
  'S79001P Unspecified physeal fracture of upper end of right femur, subsequent encounter for fracture',
  'with malunion',
  'S79001S Unspecified physeal fracture of upper end of right femur, sequela',
  'S79002A Unspecified physeal fracture of upper end of left femur, initial encounter for closed fracture',
  'S79002D Unspecified physeal fracture of upper end of left femur, subsequent encounter for fracture',
  'with routine healing',
  'S79002G Unspecified physeal fracture of upper end of left femur, subsequent encounter for fracture',
  'with delayed healing',
  'S79002K Unspecified physeal fracture of upper end of left femur, subsequent encounter for fracture',
  'with nonunion',
  'S79002P Unspecified physeal fracture of upper end of left femur, subsequent encounter for fracture',
  'with malunion',
  'S79002S Unspecified physeal fracture of upper end of left femur, sequela',
  'S79009A Unspecified physeal fracture of upper end of unspecified femur, initial encounter for closed',
  'fracture',
  'S79009D Unspecified physeal fracture of upper end of unspecified femur, subsequent encounter for',
  'fracture with routine healing',
  'S79009G Unspecified physeal fracture of upper end of unspecified femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79009K Unspecified physeal fracture of upper end of unspecified femur, subsequent encounter for',
  'fracture with nonunion',
  'S79009P Unspecified physeal fracture of upper end of unspecified femur, subsequent encounter for',
  'fracture with malunion',
  'S79009S Unspecified physeal fracture of upper end of unspecified femur, sequela',
  'S79011A Salter-Harris Type I physeal fracture of upper end of right femur, initial encounter for closed',
  'fracture',
  'S79011D Salter-Harris Type I physeal fracture of upper end of right femur, subsequent encounter for',
  'fracture with routine healing',
  'S79011G Salter-Harris Type I physeal fracture of upper end of right femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79011K Salter-Harris Type I physeal fracture of upper end of right femur, subsequent encounter for',
  'fracture with nonunion',
  'S79011P Salter-Harris Type I physeal fracture of upper end of right femur, subsequent encounter for',
  'fracture with malunion',
  'S79011S Salter-Harris Type I physeal fracture of upper end of right femur, sequela',
  'S79012A Salter-Harris Type I physeal fracture of upper end of left femur, initial encounter for closed',
  'fracture',
  'S79012D Salter-Harris Type I physeal fracture of upper end of left femur, subsequent encounter for',
  'fracture with routine healing',
  'S79012G Salter-Harris Type I physeal fracture of upper end of left femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79012K Salter-Harris Type I physeal fracture of upper end of left femur, subsequent encounter for',
  'fracture with nonunion',
  'S79012P Salter-Harris Type I physeal fracture of upper end of left femur, subsequent encounter for',
  'fracture with malunion',
  'S79012S Salter-Harris Type I physeal fracture of upper end of left femur, sequela',
  'S79019A Salter-Harris Type I physeal fracture of upper end of unspecified femur, initial encounter for',
  'closed fracture',
  'S79019D Salter-Harris Type I physeal fracture of upper end of unspecified femur, subsequent encounter',
  'for fracture with routine healing',
  'S79019G Salter-Harris Type I physeal fracture of upper end of unspecified femur, subsequent encounter',
  'for fracture with delayed healing',
  'S79019K Salter-Harris Type I physeal fracture of upper end of unspecified femur, subsequent encounter',
  'for fracture with nonunion',
  'S79019P Salter-Harris Type I physeal fracture of upper end of unspecified femur, subsequent encounter',
  'for fracture with malunion',
  'S79019S Salter-Harris Type I physeal fracture of upper end of unspecified femur, sequela',
  'S79091A Other physeal fracture of upper end of right femur, initial encounter for closed fracture',
  'S79091D Other physeal fracture of upper end of right femur, subsequent encounter for fracture with',
  'routine healing',
  'S79091G Other physeal fracture of upper end of right femur, subsequent encounter for fracture with',
  'delayed healing',
  'S79091K Other physeal fracture of upper end of right femur, subsequent encounter for fracture with',
  'nonunion',
  'S79091P Other physeal fracture of upper end of right femur, subsequent encounter for fracture with',
  'malunion',
  'S79091S Other physeal fracture of upper end of right femur, sequela',
  'S79092A Other physeal fracture of upper end of left femur, initial encounter for closed fracture',
  'S79092D Other physeal fracture of upper end of left femur, subsequent encounter for fracture with',
  'routine healing',
  'S79092G Other physeal fracture of upper end of left femur, subsequent encounter for fracture with',
  'delayed healing',
  'S79092K Other physeal fracture of upper end of left femur, subsequent encounter for fracture with',
  'nonunion',
  'S79092P Other physeal fracture of upper end of left femur, subsequent encounter for fracture with',
  'malunion',
  'S79092S Other physeal fracture of upper end of left femur, sequela',
  'S79099A Other physeal fracture of upper end of unspecified femur, initial encounter for closed fracture',
  'S79099D Other physeal fracture of upper end of unspecified femur, subsequent encounter for fracture',
  'with routine healing',
  'S79099G Other physeal fracture of upper end of unspecified femur, subsequent encounter for fracture',
  'with delayed healing',
  'S79099K Other physeal fracture of upper end of unspecified femur, subsequent encounter for fracture',
  'with nonunion',
  'S79099P Other physeal fracture of upper end of unspecified femur, subsequent encounter for fracture',
  'with malunion',
  'S79099S Other physeal fracture of upper end of unspecified femur, sequela',
  'S79101A Unspecified physeal fracture of lower end of right femur, initial encounter for closed fracture',
  'S79101D Unspecified physeal fracture of lower end of right femur, subsequent encounter for fracture',
  'with routine healing',
  'S79101G Unspecified physeal fracture of lower end of right femur, subsequent encounter for fracture',
  'with delayed healing',
  'S79101K Unspecified physeal fracture of lower end of right femur, subsequent encounter for fracture',
  'with nonunion',
  'S79101P Unspecified physeal fracture of lower end of right femur, subsequent encounter for fracture',
  'with malunion',
  'S79101S Unspecified physeal fracture of lower end of right femur, sequela',
  'S79102A Unspecified physeal fracture of lower end of left femur, initial encounter for closed fracture',
  'S79102D Unspecified physeal fracture of lower end of left femur, subsequent encounter for fracture',
  'with routine healing',
  'S79102G Unspecified physeal fracture of lower end of left femur, subsequent encounter for fracture',
  'with delayed healing',
  'S79102K Unspecified physeal fracture of lower end of left femur, subsequent encounter for fracture',
  'with nonunion',
  'S79102P Unspecified physeal fracture of lower end of left femur, subsequent encounter for fracture',
  'with malunion',
  'S79102S Unspecified physeal fracture of lower end of left femur, sequela',
  'S79109A Unspecified physeal fracture of lower end of unspecified femur, initial encounter for closed',
  'fracture',
  'S79109D Unspecified physeal fracture of lower end of unspecified femur, subsequent encounter for',
  'fracture with routine healing',
  'S79109G Unspecified physeal fracture of lower end of unspecified femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79109K Unspecified physeal fracture of lower end of unspecified femur, subsequent encounter for',
  'fracture with nonunion',
  'S79109P Unspecified physeal fracture of lower end of unspecified femur, subsequent encounter for',
  'fracture with malunion',
  'S79109S Unspecified physeal fracture of lower end of unspecified femur, sequela',
  'S79111A Salter-Harris Type I physeal fracture of lower end of right femur, initial encounter for closed',
  'fracture',
  'S79111D Salter-Harris Type I physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with routine healing',
  'S79111G Salter-Harris Type I physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79111K Salter-Harris Type I physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with nonunion',
  'S79111P Salter-Harris Type I physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with malunion',
  'S79111S Salter-Harris Type I physeal fracture of lower end of right femur, sequela',
  'S79112A Salter-Harris Type I physeal fracture of lower end of left femur, initial encounter for closed',
  'fracture',
  'S79112D Salter-Harris Type I physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with routine healing',
  'S79112G Salter-Harris Type I physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79112K Salter-Harris Type I physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with nonunion',
  'S79112P Salter-Harris Type I physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with malunion',
  'S79112S Salter-Harris Type I physeal fracture of lower end of left femur, sequela',
  'S79119A Salter-Harris Type I physeal fracture of lower end of unspecified femur, initial encounter for',
  'closed fracture',
  'S79119D Salter-Harris Type I physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with routine healing',
  'S79119G Salter-Harris Type I physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with delayed healing',
  'S79119K Salter-Harris Type I physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with nonunion',
  'S79119P Salter-Harris Type I physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with malunion',
  'S79119S Salter-Harris Type I physeal fracture of lower end of unspecified femur, sequela',
  'S79121A Salter-Harris Type II physeal fracture of lower end of right femur, initial encounter for closed',
  'fracture',
  'S79121D Salter-Harris Type II physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with routine healing',
  'S79121G Salter-Harris Type II physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79121K Salter-Harris Type II physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with nonunion',
  'S79121P Salter-Harris Type II physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with malunion',
  'S79121S Salter-Harris Type II physeal fracture of lower end of right femur, sequela',
  'S79122A Salter-Harris Type II physeal fracture of lower end of left femur, initial encounter for closed',
  'fracture',
  'S79122D Salter-Harris Type II physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with routine healing',
  'S79122G Salter-Harris Type II physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79122K Salter-Harris Type II physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with nonunion',
  'S79122P Salter-Harris Type II physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with malunion',
  'S79122S Salter-Harris Type II physeal fracture of lower end of left femur, sequela',
  'S79129A Salter-Harris Type II physeal fracture of lower end of unspecified femur, initial encounter for',
  'closed fracture',
  'S79129D Salter-Harris Type II physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with routine healing',
  'S79129G Salter-Harris Type II physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with delayed healing',
  'S79129K Salter-Harris Type II physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with nonunion',
  'S79129P Salter-Harris Type II physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with malunion',
  'S79129S Salter-Harris Type II physeal fracture of lower end of unspecified femur, sequela',
  'S79131A Salter-Harris Type III physeal fracture of lower end of right femur, initial encounter for closed',
  'fracture',
  'S79131D Salter-Harris Type III physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with routine healing',
  'S79131G Salter-Harris Type III physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79131K Salter-Harris Type III physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with nonunion',
  'S79131P Salter-Harris Type III physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with malunion',
  'S79131S Salter-Harris Type III physeal fracture of lower end of right femur, sequela',
  'S79132A Salter-Harris Type III physeal fracture of lower end of left femur, initial encounter for closed',
  'fracture',
  'S79132D Salter-Harris Type III physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with routine healing',
  'S79132G Salter-Harris Type III physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79132K Salter-Harris Type III physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with nonunion',
  'S79132P Salter-Harris Type III physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with malunion',
  'S79132S Salter-Harris Type III physeal fracture of lower end of left femur, sequela',
  'S79139A Salter-Harris Type III physeal fracture of lower end of unspecified femur, initial encounter for',
  'closed fracture',
  'S79139D Salter-Harris Type III physeal fracture of lower end of unspecified femur, subsequent',
  'encounter for fracture with routine healing',
  'S79139G Salter-Harris Type III physeal fracture of lower end of unspecified femur, subsequent',
  'encounter for fracture with delayed healing',
  'S79139K Salter-Harris Type III physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with nonunion',
  'S79139P Salter-Harris Type III physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with malunion',
  'S79139S Salter-Harris Type III physeal fracture of lower end of unspecified femur, sequela',
  'S79141A Salter-Harris Type IV physeal fracture of lower end of right femur, initial encounter for closed',
  'fracture',
  'S79141D Salter-Harris Type IV physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with routine healing',
  'S79141G Salter-Harris Type IV physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79141K Salter-Harris Type IV physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with nonunion',
  'S79141P Salter-Harris Type IV physeal fracture of lower end of right femur, subsequent encounter for',
  'fracture with malunion',
  'S79141S Salter-Harris Type IV physeal fracture of lower end of right femur, sequela',
  'S79142A Salter-Harris Type IV physeal fracture of lower end of left femur, initial encounter for closed',
  'fracture',
  'S79142D Salter-Harris Type IV physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with routine healing',
  'S79142G Salter-Harris Type IV physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with delayed healing',
  'S79142K Salter-Harris Type IV physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with nonunion',
  'S79142P Salter-Harris Type IV physeal fracture of lower end of left femur, subsequent encounter for',
  'fracture with malunion',
  'S79142S Salter-Harris Type IV physeal fracture of lower end of left femur, sequela',
  'S79149A Salter-Harris Type IV physeal fracture of lower end of unspecified femur, initial encounter for',
  'closed fracture',
  'S79149D Salter-Harris Type IV physeal fracture of lower end of unspecified femur, subsequent',
  'encounter for fracture with routine healing',
  'S79149G Salter-Harris Type IV physeal fracture of lower end of unspecified femur, subsequent',
  'encounter for fracture with delayed healing',
  'S79149K Salter-Harris Type IV physeal fracture of lower end of unspecified femur, subsequent',
  'encounter for fracture with nonunion',
  'S79149P Salter-Harris Type IV physeal fracture of lower end of unspecified femur, subsequent encounter',
  'for fracture with malunion',
  'S79149S Salter-Harris Type IV physeal fracture of lower end of unspecified femur, sequela',
  'S79191A Other physeal fracture of lower end of right femur, initial encounter for closed fracture',
  'S79191D Other physeal fracture of lower end of right femur, subsequent encounter for fracture with',
  'routine healing',
  'S79191G Other physeal fracture of lower end of right femur, subsequent encounter for fracture with',
  'delayed healing',
  'S79191K Other physeal fracture of lower end of right femur, subsequent encounter for fracture with',
  'nonunion',
  'S79191P Other physeal fracture of lower end of right femur, subsequent encounter for fracture with',
  'malunion',
  'S79191S Other physeal fracture of lower end of right femur, sequela',
  'S79192A Other physeal fracture of lower end of left femur, initial encounter for closed fracture',
  'S79192D Other physeal fracture of lower end of left femur, subsequent encounter for fracture with',
  'routine healing',
  'S79192G Other physeal fracture of lower end of left femur, subsequent encounter for fracture with',
  'delayed healing',
  'S79192K Other physeal fracture of lower end of left femur, subsequent encounter for fracture with',
  'nonunion',
  'S79192P Other physeal fracture of lower end of left femur, subsequent encounter for fracture with',
  'malunion',
  'S79192S Other physeal fracture of lower end of left femur, sequela',
  'S79199A Other physeal fracture of lower end of unspecified femur, initial encounter for closed fracture',
  'S79199D Other physeal fracture of lower end of unspecified femur, subsequent encounter for fracture',
  'with routine healing',
  'S79199G Other physeal fracture of lower end of unspecified femur, subsequent encounter for fracture',
  'with delayed healing',
  'S79199K Other physeal fracture of lower end of unspecified femur, subsequent encounter for fracture',
  'with nonunion',
  'S79199P Other physeal fracture of lower end of unspecified femur, subsequent encounter for fracture',
  'with malunion',
  'S79199S Other physeal fracture of lower end of unspecified femur, sequela',
  'S79811A Other specified injuries of right hip, initial encounter',
  'S79811D Other specified injuries of right hip, subsequent encounter',
  'S79811S Other specified injuries of right hip, sequela',
  'S79812A Other specified injuries of left hip, initial encounter',
  'S79812D Other specified injuries of left hip, subsequent encounter',
  'S79812S Other specified injuries of left hip, sequela',
  'S79819A Other specified injuries of unspecified hip, initial encounter',
  'S79819D Other specified injuries of unspecified hip, subsequent encounter',
  'S79819S Other specified injuries of unspecified hip, sequela',
  'S79821A Other specified injuries of right thigh, initial encounter',
  'S79821D Other specified injuries of right thigh, subsequent encounter',
  'S79821S Other specified injuries of right thigh, sequela',
  'S79822A Other specified injuries of left thigh, initial encounter',
  'S79822D Other specified injuries of left thigh, subsequent encounter',
  'S79822S Other specified injuries of left thigh, sequela',
  'S79829A Other specified injuries of unspecified thigh, initial encounter',
  'S79829D Other specified injuries of unspecified thigh, subsequent encounter',
  'S79829S Other specified injuries of unspecified thigh, sequela',
  'S79911A Unspecified injury of right hip, initial encounter',
  'S79911D Unspecified injury of right hip, subsequent encounter',
  'S79911S Unspecified injury of right hip, sequela',
  'S79912A Unspecified injury of left hip, initial encounter',
  'S79912D Unspecified injury of left hip, subsequent encounter',
  'S79912S Unspecified injury of left hip, sequela',
  'S79919A Unspecified injury of unspecified hip, initial encounter',
  'S79919D Unspecified injury of unspecified hip, subsequent encounter',
  'S79919S Unspecified injury of unspecified hip, sequela',
  'S79921A Unspecified injury of right thigh, initial encounter',
  'S79921D Unspecified injury of right thigh, subsequent encounter',
  'S79921S Unspecified injury of right thigh, sequela',
  'S79922A Unspecified injury of left thigh, initial encounter',
  'S79922D Unspecified injury of left thigh, subsequent encounter',
  'S79922S Unspecified injury of left thigh, sequela',
  'S79929A Unspecified injury of unspecified thigh, initial encounter',
  'S79929D Unspecified injury of unspecified thigh, subsequent encounter',
  'S79929S Unspecified injury of unspecified thigh, sequela',
  'S8000XA Contusion of unspecified knee, initial encounter',
  'S8000XD Contusion of unspecified knee, subsequent encounter',
  'S8000XS Contusion of unspecified knee, sequela',
  'S8001XA Contusion of right knee, initial encounter',
  'S8001XD Contusion of right knee, subsequent encounter',
  'S8001XS Contusion of right knee, sequela',
  'S8002XA Contusion of left knee, initial encounter',
  'S8002XD Contusion of left knee, subsequent encounter',
  'S8002XS Contusion of left knee, sequela',
  'S8010XA Contusion of unspecified lower leg, initial encounter',
  'S8010XD Contusion of unspecified lower leg, subsequent encounter',
  'S8010XS Contusion of unspecified lower leg, sequela',
  'S8011XA Contusion of right lower leg, initial encounter',
  'S8011XD Contusion of right lower leg, subsequent encounter',
  'S8011XS Contusion of right lower leg, sequela',
  'S8012XA Contusion of left lower leg, initial encounter',
  'S8012XD Contusion of left lower leg, subsequent encounter',
  'S8012XS Contusion of left lower leg, sequela',
  'S80211A Abrasion, right knee, initial encounter',
  'S80211D Abrasion, right knee, subsequent encounter',
  'S80211S Abrasion, right knee, sequela',
  'S80212A Abrasion, left knee, initial encounter',
  'S80212D Abrasion, left knee, subsequent encounter',
  'S80212S Abrasion, left knee, sequela',
  'S80219A Abrasion, unspecified knee, initial encounter',
  'S80219D Abrasion, unspecified knee, subsequent encounter',
  'S80219S Abrasion, unspecified knee, sequela',
  'S80221A Blister (nonthermal), right knee, initial encounter',
  'S80221D Blister (nonthermal), right knee, subsequent encounter',
  'S80221S Blister (nonthermal), right knee, sequela',
  'S80222A Blister (nonthermal), left knee, initial encounter',
  'S80222D Blister (nonthermal), left knee, subsequent encounter',
  'S80222S Blister (nonthermal), left knee, sequela',
  'S80229A Blister (nonthermal), unspecified knee, initial encounter',
  'S80229D Blister (nonthermal), unspecified knee, subsequent encounter',
  'S80229S Blister (nonthermal), unspecified knee, sequela',
  'S80241A External constriction, right knee, initial encounter',
  'S80241D External constriction, right knee, subsequent encounter',
  'S80241S External constriction, right knee, sequela',
  'S80242A External constriction, left knee, initial encounter',
  'S80242D External constriction, left knee, subsequent encounter',
  'S80242S External constriction, left knee, sequela',
  'S80249A External constriction, unspecified knee, initial encounter',
  'S80249D External constriction, unspecified knee, subsequent encounter',
  'S80249S External constriction, unspecified knee, sequela',
  'S80251A Superficial foreign body, right knee, initial encounter',
  'S80251D Superficial foreign body, right knee, subsequent encounter',
  'S80251S Superficial foreign body, right knee, sequela',
  'S80252A Superficial foreign body, left knee, initial encounter',
  'S80252D Superficial foreign body, left knee, subsequent encounter',
  'S80252S Superficial foreign body, left knee, sequela',
  'S80259A Superficial foreign body, unspecified knee, initial encounter',
  'S80259D Superficial foreign body, unspecified knee, subsequent encounter',
  'S80259S Superficial foreign body, unspecified knee, sequela',
  'S80261A Insect bite (nonvenomous), right knee, initial encounter',
  'S80261D Insect bite (nonvenomous), right knee, subsequent encounter',
  'S80261S Insect bite (nonvenomous), right knee, sequela',
  'S80262A Insect bite (nonvenomous), left knee, initial encounter',
  'S80262D Insect bite (nonvenomous), left knee, subsequent encounter',
  'S80262S Insect bite (nonvenomous), left knee, sequela',
  'S80269A Insect bite (nonvenomous), unspecified knee, initial encounter',
  'S80269D Insect bite (nonvenomous), unspecified knee, subsequent encounter',
  'S80269S Insect bite (nonvenomous), unspecified knee, sequela',
  'S80271A Other superficial bite of right knee, initial encounter',
  'S80271D Other superficial bite of right knee, subsequent encounter',
  'S80271S Other superficial bite of right knee, sequela',
  'S80272A Other superficial bite of left knee, initial encounter',
  'S80272D Other superficial bite of left knee, subsequent encounter',
  'S80272S Other superficial bite of left knee, sequela',
  'S80279A Other superficial bite of unspecified knee, initial encounter',
  'S80279D Other superficial bite of unspecified knee, subsequent encounter',
  'S80279S Other superficial bite of unspecified knee, sequela',
  'S80811A Abrasion, right lower leg, initial encounter',
  'S80811D Abrasion, right lower leg, subsequent encounter',
  'S80811S Abrasion, right lower leg, sequela',
  'S80812A Abrasion, left lower leg, initial encounter',
  'S80812D Abrasion, left lower leg, subsequent encounter',
  'S80812S Abrasion, left lower leg, sequela',
  'S80819A Abrasion, unspecified lower leg, initial encounter',
  'S80819D Abrasion, unspecified lower leg, subsequent encounter',
  'S80819S Abrasion, unspecified lower leg, sequela',
  'S80821A Blister (nonthermal), right lower leg, initial encounter',
  'S80821D Blister (nonthermal), right lower leg, subsequent encounter',
  'S80821S Blister (nonthermal), right lower leg, sequela',
  'S80822A Blister (nonthermal), left lower leg, initial encounter',
  'S80822D Blister (nonthermal), left lower leg, subsequent encounter',
  'S80822S Blister (nonthermal), left lower leg, sequela',
  'S80829A Blister (nonthermal), unspecified lower leg, initial encounter',
  'S80829D Blister (nonthermal), unspecified lower leg, subsequent encounter',
  'S80829S Blister (nonthermal), unspecified lower leg, sequela',
  'S80841A External constriction, right lower leg, initial encounter',
  'S80841D External constriction, right lower leg, subsequent encounter',
  'S80841S External constriction, right lower leg, sequela',
  'S80842A External constriction, left lower leg, initial encounter',
  'S80842D External constriction, left lower leg, subsequent encounter',
  'S80842S External constriction, left lower leg, sequela',
  'S80849A External constriction, unspecified lower leg, initial encounter',
  'S80849D External constriction, unspecified lower leg, subsequent encounter',
  'S80849S External constriction, unspecified lower leg, sequela',
  'S80851A Superficial foreign body, right lower leg, initial encounter',
  'S80851D Superficial foreign body, right lower leg, subsequent encounter',
  'S80851S Superficial foreign body, right lower leg, sequela',
  'S80852A Superficial foreign body, left lower leg, initial encounter',
  'S80852D Superficial foreign body, left lower leg, subsequent encounter',
  'S80852S Superficial foreign body, left lower leg, sequela',
  'S80859A Superficial foreign body, unspecified lower leg, initial encounter',
  'S80859D Superficial foreign body, unspecified lower leg, subsequent encounter',
  'S80859S Superficial foreign body, unspecified lower leg, sequela',
  'S80861A Insect bite (nonvenomous), right lower leg, initial encounter',
  'S80861D Insect bite (nonvenomous), right lower leg, subsequent encounter',
  'S80861S Insect bite (nonvenomous), right lower leg, sequela',
  'S80862A Insect bite (nonvenomous), left lower leg, initial encounter',
  'S80862D Insect bite (nonvenomous), left lower leg, subsequent encounter',
  'S80862S Insect bite (nonvenomous), left lower leg, sequela',
  'S80869A Insect bite (nonvenomous), unspecified lower leg, initial encounter',
  'S80869D Insect bite (nonvenomous), unspecified lower leg, subsequent encounter',
  'S80869S Insect bite (nonvenomous), unspecified lower leg, sequela',
  'S80871A Other superficial bite, right lower leg, initial encounter',
  'S80871D Other superficial bite, right lower leg, subsequent encounter',
  'S80871S Other superficial bite, right lower leg, sequela',
  'S80872A Other superficial bite, left lower leg, initial encounter',
  'S80872D Other superficial bite, left lower leg, subsequent encounter',
  'S80872S Other superficial bite, left lower leg, sequela',
  'S80879A Other superficial bite, unspecified lower leg, initial encounter',
  'S80879D Other superficial bite, unspecified lower leg, subsequent encounter',
  'S80879S Other superficial bite, unspecified lower leg, sequela',
  'S80911A Unspecified superficial injury of right knee, initial encounter',
  'S80911D Unspecified superficial injury of right knee, subsequent encounter',
  'S80911S Unspecified superficial injury of right knee, sequela',
  'S80912A Unspecified superficial injury of left knee, initial encounter',
  'S80912D Unspecified superficial injury of left knee, subsequent encounter',
  'S80912S Unspecified superficial injury of left knee, sequela',
  'S80919A Unspecified superficial injury of unspecified knee, initial encounter',
  'S80919D Unspecified superficial injury of unspecified knee, subsequent encounter',
  'S80919S Unspecified superficial injury of unspecified knee, sequela',
  'S80921A Unspecified superficial injury of right lower leg, initial encounter',
  'S80921D Unspecified superficial injury of right lower leg, subsequent encounter',
  'S80921S Unspecified superficial injury of right lower leg, sequela',
  'S80922A Unspecified superficial injury of left lower leg, initial encounter',
  'S80922D Unspecified superficial injury of left lower leg, subsequent encounter',
  'S80922S Unspecified superficial injury of left lower leg, sequela',
  'S80929A Unspecified superficial injury of unspecified lower leg, initial encounter',
  'S80929D Unspecified superficial injury of unspecified lower leg, subsequent encounter',
  'S80929S Unspecified superficial injury of unspecified lower leg, sequela',
  'S81001A Unspecified open wound, right knee, initial encounter',
  'S81001D Unspecified open wound, right knee, subsequent encounter',
  'S81001S Unspecified open wound, right knee, sequela',
  'S81002A Unspecified open wound, left knee, initial encounter',
  'S81002D Unspecified open wound, left knee, subsequent encounter',
  'S81002S Unspecified open wound, left knee, sequela',
  'S81009A Unspecified open wound, unspecified knee, initial encounter',
  'S81009D Unspecified open wound, unspecified knee, subsequent encounter',
  'S81009S Unspecified open wound, unspecified knee, sequela',
  'S81011A Laceration without foreign body, right knee, initial encounter',
  'S81011D Laceration without foreign body, right knee, subsequent encounter',
  'S81011S Laceration without foreign body, right knee, sequela',
  'S81012A Laceration without foreign body, left knee, initial encounter',
  'S81012D Laceration without foreign body, left knee, subsequent encounter',
  'S81012S Laceration without foreign body, left knee, sequela',
  'S81019A Laceration without foreign body, unspecified knee, initial encounter',
  'S81019D Laceration without foreign body, unspecified knee, subsequent encounter',
  'S81019S Laceration without foreign body, unspecified knee, sequela',
  'S81021A Laceration with foreign body, right knee, initial encounter',
  'S81021D Laceration with foreign body, right knee, subsequent encounter',
  'S81021S Laceration with foreign body, right knee, sequela',
  'S81022A Laceration with foreign body, left knee, initial encounter',
  'S81022D Laceration with foreign body, left knee, subsequent encounter',
  'S81022S Laceration with foreign body, left knee, sequela',
  'S81029A Laceration with foreign body, unspecified knee, initial encounter',
  'S81029D Laceration with foreign body, unspecified knee, subsequent encounter',
  'S81029S Laceration with foreign body, unspecified knee, sequela',
  'S81031A Puncture wound without foreign body, right knee, initial encounter',
  'S81031D Puncture wound without foreign body, right knee, subsequent encounter',
  'S81031S Puncture wound without foreign body, right knee, sequela',
  'S81032A Puncture wound without foreign body, left knee, initial encounter',
  'S81032D Puncture wound without foreign body, left knee, subsequent encounter',
  'S81032S Puncture wound without foreign body, left knee, sequela',
  'S81039A Puncture wound without foreign body, unspecified knee, initial encounter',
  'S81039D Puncture wound without foreign body, unspecified knee, subsequent encounter',
  'S81039S Puncture wound without foreign body, unspecified knee, sequela',
  'S81041A Puncture wound with foreign body, right knee, initial encounter',
  'S81041D Puncture wound with foreign body, right knee, subsequent encounter',
  'S81041S Puncture wound with foreign body, right knee, sequela',
  'S81042A Puncture wound with foreign body, left knee, initial encounter',
  'S81042D Puncture wound with foreign body, left knee, subsequent encounter',
  'S81042S Puncture wound with foreign body, left knee, sequela',
  'S81049A Puncture wound with foreign body, unspecified knee, initial encounter',
  'S81049D Puncture wound with foreign body, unspecified knee, subsequent encounter',
  'S81049S Puncture wound with foreign body, unspecified knee, sequela',
  'S81051A Open bite, right knee, initial encounter',
  'S81051D Open bite, right knee, subsequent encounter',
  'S81051S Open bite, right knee, sequela',
  'S81052A Open bite, left knee, initial encounter',
  'S81052D Open bite, left knee, subsequent encounter',
  'S81052S Open bite, left knee, sequela',
  'S81059A Open bite, unspecified knee, initial encounter',
  'S81059D Open bite, unspecified knee, subsequent encounter',
  'S81059S Open bite, unspecified knee, sequela',
  'S81801A Unspecified open wound, right lower leg, initial encounter',
  'S81801D Unspecified open wound, right lower leg, subsequent encounter',
  'S81801S Unspecified open wound, right lower leg, sequela',
  'S81802A Unspecified open wound, left lower leg, initial encounter',
  'S81802D Unspecified open wound, left lower leg, subsequent encounter',
  'S81802S Unspecified open wound, left lower leg, sequela',
  'S81809A Unspecified open wound, unspecified lower leg, initial encounter',
  'S81809D Unspecified open wound, unspecified lower leg, subsequent encounter',
  'S81809S Unspecified open wound, unspecified lower leg, sequela',
  'S81811A Laceration without foreign body, right lower leg, initial encounter',
  'S81811D Laceration without foreign body, right lower leg, subsequent encounter',
  'S81811S Laceration without foreign body, right lower leg, sequela',
  'S81812A Laceration without foreign body, left lower leg, initial encounter',
  'S81812D Laceration without foreign body, left lower leg, subsequent encounter',
  'S81812S Laceration without foreign body, left lower leg, sequela',
  'S81819A Laceration without foreign body, unspecified lower leg, initial encounter',
  'S81819D Laceration without foreign body, unspecified lower leg, subsequent encounter',
  'S81819S Laceration without foreign body, unspecified lower leg, sequela',
  'S81821A Laceration with foreign body, right lower leg, initial encounter',
  'S81821D Laceration with foreign body, right lower leg, subsequent encounter',
  'S81821S Laceration with foreign body, right lower leg, sequela',
  'S81822A Laceration with foreign body, left lower leg, initial encounter',
  'S81822D Laceration with foreign body, left lower leg, subsequent encounter',
  'S81822S Laceration with foreign body, left lower leg, sequela',
  'S81829A Laceration with foreign body, unspecified lower leg, initial encounter',
  'S81829D Laceration with foreign body, unspecified lower leg, subsequent encounter',
  'S81829S Laceration with foreign body, unspecified lower leg, sequela',
  'S81831A Puncture wound without foreign body, right lower leg, initial encounter',
  'S81831D Puncture wound without foreign body, right lower leg, subsequent encounter',
  'S81831S Puncture wound without foreign body, right lower leg, sequela',
  'S81832A Puncture wound without foreign body, left lower leg, initial encounter',
  'S81832D Puncture wound without foreign body, left lower leg, subsequent encounter',
  'S81832S Puncture wound without foreign body, left lower leg, sequela',
  'S81839A Puncture wound without foreign body, unspecified lower leg, initial encounter',
  'S81839D Puncture wound without foreign body, unspecified lower leg, subsequent encounter',
  'S81839S Puncture wound without foreign body, unspecified lower leg, sequela',
  'S81841A Puncture wound with foreign body, right lower leg, initial encounter',
  'S81841D Puncture wound with foreign body, right lower leg, subsequent encounter',
  'S81841S Puncture wound with foreign body, right lower leg, sequela',
  'S81842A Puncture wound with foreign body, left lower leg, initial encounter',
  'S81842D Puncture wound with foreign body, left lower leg, subsequent encounter',
  'S81842S Puncture wound with foreign body, left lower leg, sequela',
  'S81849A Puncture wound with foreign body, unspecified lower leg, initial encounter',
  'S81849D Puncture wound with foreign body, unspecified lower leg, subsequent encounter',
  'S81849S Puncture wound with foreign body, unspecified lower leg, sequela',
  'S81851A Open bite, right lower leg, initial encounter',
  'S81851D Open bite, right lower leg, subsequent encounter',
  'S81851S Open bite, right lower leg, sequela',
  'S81852A Open bite, left lower leg, initial encounter',
  'S81852D Open bite, left lower leg, subsequent encounter',
  'S81852S Open bite, left lower leg, sequela',
  'S81859A Open bite, unspecified lower leg, initial encounter',
  'S81859D Open bite, unspecified lower leg, subsequent encounter',
  'S81859S Open bite, unspecified lower leg, sequela',
  'S82001A Unspecified fracture of right patella, initial encounter for closed fracture',
  'S82001B Unspecified fracture of right patella, initial encounter for open fracture type I or II',
  'S82001C Unspecified fracture of right patella, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82001D Unspecified fracture of right patella, subsequent encounter for closed fracture with routine',
  'healing',
  'S82001E Unspecified fracture of right patella, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82001F Unspecified fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82001G Unspecified fracture of right patella, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82001H Unspecified fracture of right patella, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82001J Unspecified fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82001K Unspecified fracture of right patella, subsequent encounter for closed fracture with nonunion',
  'S82001M Unspecified fracture of right patella, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82001N Unspecified fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82001P Unspecified fracture of right patella, subsequent encounter for closed fracture with malunion',
  'S82001Q Unspecified fracture of right patella, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82001R Unspecified fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82001S Unspecified fracture of right patella, sequela',
  'S82002A Unspecified fracture of left patella, initial encounter for closed fracture',
  'S82002B Unspecified fracture of left patella, initial encounter for open fracture type I or II',
  'S82002C Unspecified fracture of left patella, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82002D Unspecified fracture of left patella, subsequent encounter for closed fracture with routine',
  'healing',
  'S82002E Unspecified fracture of left patella, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82002F Unspecified fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82002G Unspecified fracture of left patella, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82002H Unspecified fracture of left patella, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82002J Unspecified fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82002K Unspecified fracture of left patella, subsequent encounter for closed fracture with nonunion',
  'S82002M Unspecified fracture of left patella, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82002N Unspecified fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82002P Unspecified fracture of left patella, subsequent encounter for closed fracture with malunion',
  'S82002Q Unspecified fracture of left patella, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82002R Unspecified fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82002S Unspecified fracture of left patella, sequela',
  'S82009A Unspecified fracture of unspecified patella, initial encounter for closed fracture',
  'S82009B Unspecified fracture of unspecified patella, initial encounter for open fracture type I or II',
  'S82009C Unspecified fracture of unspecified patella, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82009D Unspecified fracture of unspecified patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82009E Unspecified fracture of unspecified patella, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82009F Unspecified fracture of unspecified patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82009G Unspecified fracture of unspecified patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82009H Unspecified fracture of unspecified patella, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82009J Unspecified fracture of unspecified patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82009K Unspecified fracture of unspecified patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82009M Unspecified fracture of unspecified patella, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82009N Unspecified fracture of unspecified patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82009P Unspecified fracture of unspecified patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82009Q Unspecified fracture of unspecified patella, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82009R Unspecified fracture of unspecified patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82009S Unspecified fracture of unspecified patella, sequela',
  'S82011A Displaced osteochondral fracture of right patella, initial encounter for closed fracture',
  'S82011B Displaced osteochondral fracture of right patella, initial encounter for open fracture type I or II',
  'S82011C Displaced osteochondral fracture of right patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82011D Displaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82011E Displaced osteochondral fracture of right patella, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82011F Displaced osteochondral fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82011G Displaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82011H Displaced osteochondral fracture of right patella, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82011J Displaced osteochondral fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82011K Displaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82011M Displaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82011N Displaced osteochondral fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82011P Displaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82011Q Displaced osteochondral fracture of right patella, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82011R Displaced osteochondral fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82011S Displaced osteochondral fracture of right patella, sequela',
  'S82012A Displaced osteochondral fracture of left patella, initial encounter for closed fracture',
  'S82012B Displaced osteochondral fracture of left patella, initial encounter for open fracture type I or II',
  'S82012C Displaced osteochondral fracture of left patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82012D Displaced osteochondral fracture of left patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82012E Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82012F Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82012G Displaced osteochondral fracture of left patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82012H Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82012J Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82012K Displaced osteochondral fracture of left patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82012M Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82012N Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82012P Displaced osteochondral fracture of left patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82012Q Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82012R Displaced osteochondral fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82012S Displaced osteochondral fracture of left patella, sequela',
  'S82013A Displaced osteochondral fracture of unspecified patella, initial encounter for closed fracture',
  'S82013B Displaced osteochondral fracture of unspecified patella, initial encounter for open fracture',
  'type I or II',
  'S82013C Displaced osteochondral fracture of unspecified patella, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82013D Displaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with routine healing',
  'S82013E Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82013F Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82013G Displaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82013H Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82013J Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82013K Displaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with nonunion',
  'S82013M Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82013N Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82013P Displaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with malunion',
  'S82013Q Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82013R Displaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82013S Displaced osteochondral fracture of unspecified patella, sequela',
  'S82014A Nondisplaced osteochondral fracture of right patella, initial encounter for closed fracture',
  'S82014B Nondisplaced osteochondral fracture of right patella, initial encounter for open fracture type I',
  'or II',
  'S82014C Nondisplaced osteochondral fracture of right patella, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82014D Nondisplaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82014E Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82014F Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82014G Nondisplaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82014H Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82014J Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82014K Nondisplaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82014M Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82014N Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82014P Nondisplaced osteochondral fracture of right patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82014Q Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82014R Nondisplaced osteochondral fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82014S Nondisplaced osteochondral fracture of right patella, sequela',
  'S82015A Nondisplaced osteochondral fracture of left patella, initial encounter for closed fracture',
  'S82015B Nondisplaced osteochondral fracture of left patella, initial encounter for open fracture type I or',
  'II',
  'S82015C Nondisplaced osteochondral fracture of left patella, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82015D Nondisplaced osteochondral fracture of left patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82015E Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82015F Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82015G Nondisplaced osteochondral fracture of left patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82015H Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82015J Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82015K Nondisplaced osteochondral fracture of left patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82015M Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82015N Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82015P Nondisplaced osteochondral fracture of left patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82015Q Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82015R Nondisplaced osteochondral fracture of left patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82015S Nondisplaced osteochondral fracture of left patella, sequela',
  'S82016A Nondisplaced osteochondral fracture of unspecified patella, initial encounter for closed',
  'fracture',
  'S82016B Nondisplaced osteochondral fracture of unspecified patella, initial encounter for open fracture',
  'type I or II',
  'S82016C Nondisplaced osteochondral fracture of unspecified patella, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82016D Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with routine healing',
  'S82016E Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82016F Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82016G Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82016H Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82016J Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82016K Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with nonunion',
  'S82016M Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82016N Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82016P Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for closed',
  'fracture with malunion',
  'S82016Q Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82016R Nondisplaced osteochondral fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82016S Nondisplaced osteochondral fracture of unspecified patella, sequela',
  'S82021A Displaced longitudinal fracture of right patella, initial encounter for closed fracture',
  'S82021B Displaced longitudinal fracture of right patella, initial encounter for open fracture type I or II',
  'S82021C Displaced longitudinal fracture of right patella, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82021D Displaced longitudinal fracture of right patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82021E Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82021F Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82021G Displaced longitudinal fracture of right patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82021H Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82021J Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82021K Displaced longitudinal fracture of right patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82021M Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82021N Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82021P Displaced longitudinal fracture of right patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82021Q Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82021R Displaced longitudinal fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82021S Displaced longitudinal fracture of right patella, sequela',
  'S82022A Displaced longitudinal fracture of left patella, initial encounter for closed fracture',
  'S82022B Displaced longitudinal fracture of left patella, initial encounter for open fracture type I or II',
  'S82022C Displaced longitudinal fracture of left patella, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82022D Displaced longitudinal fracture of left patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82022E Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82022F Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82022G Displaced longitudinal fracture of left patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82022H Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82022J Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82022K Displaced longitudinal fracture of left patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82022M Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82022N Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82022P Displaced longitudinal fracture of left patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82022Q Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82022R Displaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82022S Displaced longitudinal fracture of left patella, sequela',
  'S82023A Displaced longitudinal fracture of unspecified patella, initial encounter for closed fracture',
  'S82023B Displaced longitudinal fracture of unspecified patella, initial encounter for open fracture type I',
  'or II',
  'S82023C Displaced longitudinal fracture of unspecified patella, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82023D Displaced longitudinal fracture of unspecified patella, subsequent encounter for closed',
  'fracture with routine healing',
  'S82023E Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82023F Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82023G Displaced longitudinal fracture of unspecified patella, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82023H Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82023J Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82023K Displaced longitudinal fracture of unspecified patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82023M Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82023N Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82023P Displaced longitudinal fracture of unspecified patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82023Q Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82023R Displaced longitudinal fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82023S Displaced longitudinal fracture of unspecified patella, sequela',
  'S82024A Nondisplaced longitudinal fracture of right patella, initial encounter for closed fracture',
  'S82024B Nondisplaced longitudinal fracture of right patella, initial encounter for open fracture type I or',
  'II',
  'S82024C Nondisplaced longitudinal fracture of right patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82024D Nondisplaced longitudinal fracture of right patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82024E Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82024F Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82024G Nondisplaced longitudinal fracture of right patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82024H Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82024J Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82024K Nondisplaced longitudinal fracture of right patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82024M Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82024N Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82024P Nondisplaced longitudinal fracture of right patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82024Q Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82024R Nondisplaced longitudinal fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82024S Nondisplaced longitudinal fracture of right patella, sequela',
  'S82025A Nondisplaced longitudinal fracture of left patella, initial encounter for closed fracture',
  'S82025B Nondisplaced longitudinal fracture of left patella, initial encounter for open fracture type I or II',
  'S82025C Nondisplaced longitudinal fracture of left patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82025D Nondisplaced longitudinal fracture of left patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82025E Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82025F Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82025G Nondisplaced longitudinal fracture of left patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82025H Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82025J Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82025K Nondisplaced longitudinal fracture of left patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82025M Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82025N Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82025P Nondisplaced longitudinal fracture of left patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82025Q Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82025R Nondisplaced longitudinal fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82025S Nondisplaced longitudinal fracture of left patella, sequela',
  'S82026A Nondisplaced longitudinal fracture of unspecified patella, initial encounter for closed fracture',
  'S82026B Nondisplaced longitudinal fracture of unspecified patella, initial encounter for open fracture',
  'type I or II',
  'S82026C Nondisplaced longitudinal fracture of unspecified patella, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82026D Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for closed',
  'fracture with routine healing',
  'S82026E Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82026F Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82026G Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82026H Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82026J Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82026K Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for closed',
  'fracture with nonunion',
  'S82026M Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82026N Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82026P Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for closed',
  'fracture with malunion',
  'S82026Q Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82026R Nondisplaced longitudinal fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82026S Nondisplaced longitudinal fracture of unspecified patella, sequela',
  'S82031A Displaced transverse fracture of right patella, initial encounter for closed fracture',
  'S82031B Displaced transverse fracture of right patella, initial encounter for open fracture type I or II',
  'S82031C Displaced transverse fracture of right patella, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82031D Displaced transverse fracture of right patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82031E Displaced transverse fracture of right patella, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82031F Displaced transverse fracture of right patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82031G Displaced transverse fracture of right patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82031H Displaced transverse fracture of right patella, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82031J Displaced transverse fracture of right patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82031K Displaced transverse fracture of right patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82031M Displaced transverse fracture of right patella, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82031N Displaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82031P Displaced transverse fracture of right patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82031Q Displaced transverse fracture of right patella, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82031R Displaced transverse fracture of right patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82031S Displaced transverse fracture of right patella, sequela',
  'S82032A Displaced transverse fracture of left patella, initial encounter for closed fracture',
  'S82032B Displaced transverse fracture of left patella, initial encounter for open fracture type I or II',
  'S82032C Displaced transverse fracture of left patella, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82032D Displaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82032E Displaced transverse fracture of left patella, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82032F Displaced transverse fracture of left patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82032G Displaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82032H Displaced transverse fracture of left patella, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82032J Displaced transverse fracture of left patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82032K Displaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82032M Displaced transverse fracture of left patella, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S82032N Displaced transverse fracture of left patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82032P Displaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82032Q Displaced transverse fracture of left patella, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82032R Displaced transverse fracture of left patella, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82032S Displaced transverse fracture of left patella, sequela',
  'S82033A Displaced transverse fracture of unspecified patella, initial encounter for closed fracture',
  'S82033B Displaced transverse fracture of unspecified patella, initial encounter for open fracture type I',
  'or II',
  'S82033C Displaced transverse fracture of unspecified patella, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82033D Displaced transverse fracture of unspecified patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82033E Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82033F Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82033G Displaced transverse fracture of unspecified patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82033H Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82033J Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82033K Displaced transverse fracture of unspecified patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82033M Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82033N Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82033P Displaced transverse fracture of unspecified patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82033Q Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82033R Displaced transverse fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82033S Displaced transverse fracture of unspecified patella, sequela',
  'S82034A Nondisplaced transverse fracture of right patella, initial encounter for closed fracture',
  'S82034B Nondisplaced transverse fracture of right patella, initial encounter for open fracture type I or II',
  'S82034C Nondisplaced transverse fracture of right patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82034D Nondisplaced transverse fracture of right patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82034E Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82034F Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82034G Nondisplaced transverse fracture of right patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82034H Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82034J Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82034K Nondisplaced transverse fracture of right patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82034M Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82034N Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82034P Nondisplaced transverse fracture of right patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82034Q Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82034R Nondisplaced transverse fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82034S Nondisplaced transverse fracture of right patella, sequela',
  'S82035A Nondisplaced transverse fracture of left patella, initial encounter for closed fracture',
  'S82035B Nondisplaced transverse fracture of left patella, initial encounter for open fracture type I or II',
  'S82035C Nondisplaced transverse fracture of left patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82035D Nondisplaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82035E Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82035F Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82035G Nondisplaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82035H Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82035J Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82035K Nondisplaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82035M Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82035N Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82035P Nondisplaced transverse fracture of left patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82035Q Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82035R Nondisplaced transverse fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82035S Nondisplaced transverse fracture of left patella, sequela',
  'S82036A Nondisplaced transverse fracture of unspecified patella, initial encounter for closed fracture',
  'S82036B Nondisplaced transverse fracture of unspecified patella, initial encounter for open fracture',
  'type I or II',
  'S82036C Nondisplaced transverse fracture of unspecified patella, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82036D Nondisplaced transverse fracture of unspecified patella, subsequent encounter for closed',
  'fracture with routine healing',
  'S82036E Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82036F Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82036G Nondisplaced transverse fracture of unspecified patella, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82036H Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82036J Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82036K Nondisplaced transverse fracture of unspecified patella, subsequent encounter for closed',
  'fracture with nonunion',
  'S82036M Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82036N Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82036P Nondisplaced transverse fracture of unspecified patella, subsequent encounter for closed',
  'fracture with malunion',
  'S82036Q Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82036R Nondisplaced transverse fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82036S Nondisplaced transverse fracture of unspecified patella, sequela',
  'S82041A Displaced comminuted fracture of right patella, initial encounter for closed fracture',
  'S82041B Displaced comminuted fracture of right patella, initial encounter for open fracture type I or II',
  'S82041C Displaced comminuted fracture of right patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82041D Displaced comminuted fracture of right patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82041E Displaced comminuted fracture of right patella, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82041F Displaced comminuted fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82041G Displaced comminuted fracture of right patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82041H Displaced comminuted fracture of right patella, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82041J Displaced comminuted fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82041K Displaced comminuted fracture of right patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82041M Displaced comminuted fracture of right patella, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82041N Displaced comminuted fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82041P Displaced comminuted fracture of right patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82041Q Displaced comminuted fracture of right patella, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82041R Displaced comminuted fracture of right patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82041S Displaced comminuted fracture of right patella, sequela',
  'S82042A Displaced comminuted fracture of left patella, initial encounter for closed fracture',
  'S82042B Displaced comminuted fracture of left patella, initial encounter for open fracture type I or II',
  'S82042C Displaced comminuted fracture of left patella, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82042D Displaced comminuted fracture of left patella, subsequent encounter for closed fracture with',
  'routine healing',
  'S82042E Displaced comminuted fracture of left patella, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82042F Displaced comminuted fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82042G Displaced comminuted fracture of left patella, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82042H Displaced comminuted fracture of left patella, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82042J Displaced comminuted fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82042K Displaced comminuted fracture of left patella, subsequent encounter for closed fracture with',
  'nonunion',
  'S82042M Displaced comminuted fracture of left patella, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82042N Displaced comminuted fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82042P Displaced comminuted fracture of left patella, subsequent encounter for closed fracture with',
  'malunion',
  'S82042Q Displaced comminuted fracture of left patella, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82042R Displaced comminuted fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82042S Displaced comminuted fracture of left patella, sequela',
  'S82043A Displaced comminuted fracture of unspecified patella, initial encounter for closed fracture',
  'S82043B Displaced comminuted fracture of unspecified patella, initial encounter for open fracture type I',
  'or II',
  'S82043C Displaced comminuted fracture of unspecified patella, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82043D Displaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with routine healing',
  'S82043E Displaced comminuted fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82043F Displaced comminuted fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82043G Displaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82043H Displaced comminuted fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82043J Displaced comminuted fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82043K Displaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with nonunion',
  'S82043M Displaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82043N Displaced comminuted fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82043P Displaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with malunion',
  'S82043Q Displaced comminuted fracture of unspecified patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82043R Displaced comminuted fracture of unspecified patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82043S Displaced comminuted fracture of unspecified patella, sequela',
  'S82044A Nondisplaced comminuted fracture of right patella, initial encounter for closed fracture',
  'S82044B Nondisplaced comminuted fracture of right patella, initial encounter for open fracture type I or',
  'II',
  'S82044C Nondisplaced comminuted fracture of right patella, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82044D Nondisplaced comminuted fracture of right patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82044E Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82044F Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82044G Nondisplaced comminuted fracture of right patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82044H Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82044J Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82044K Nondisplaced comminuted fracture of right patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82044M Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82044N Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82044P Nondisplaced comminuted fracture of right patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82044Q Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82044R Nondisplaced comminuted fracture of right patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82044S Nondisplaced comminuted fracture of right patella, sequela',
  'S82045A Nondisplaced comminuted fracture of left patella, initial encounter for closed fracture',
  'S82045B Nondisplaced comminuted fracture of left patella, initial encounter for open fracture type I or',
  'II',
  'S82045C Nondisplaced comminuted fracture of left patella, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82045D Nondisplaced comminuted fracture of left patella, subsequent encounter for closed fracture',
  'with routine healing',
  'S82045E Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82045F Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82045G Nondisplaced comminuted fracture of left patella, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82045H Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82045J Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82045K Nondisplaced comminuted fracture of left patella, subsequent encounter for closed fracture',
  'with nonunion',
  'S82045M Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82045N Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82045P Nondisplaced comminuted fracture of left patella, subsequent encounter for closed fracture',
  'with malunion',
  'S82045Q Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82045R Nondisplaced comminuted fracture of left patella, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82045S Nondisplaced comminuted fracture of left patella, sequela',
  'S82046A Nondisplaced comminuted fracture of unspecified patella, initial encounter for closed fracture',
  'S82046B Nondisplaced comminuted fracture of unspecified patella, initial encounter for open fracture',
  'type I or II',
  'S82046C Nondisplaced comminuted fracture of unspecified patella, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82046D Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with routine healing',
  'S82046E Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82046F Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82046G Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82046H Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82046J Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82046K Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with nonunion',
  'S82046M Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82046N Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82046P Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for closed',
  'fracture with malunion',
  'S82046Q Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82046R Nondisplaced comminuted fracture of unspecified patella, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82046S Nondisplaced comminuted fracture of unspecified patella, sequela',
  'S82091A Other fracture of right patella, initial encounter for closed fracture',
  'S82091B Other fracture of right patella, initial encounter for open fracture type I or II',
  'S82091C Other fracture of right patella, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82091D Other fracture of right patella, subsequent encounter for closed fracture with routine healing',
  'S82091E Other fracture of right patella, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S82091F Other fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with routine healing',
  'S82091G Other fracture of right patella, subsequent encounter for closed fracture with delayed healing',
  'S82091H Other fracture of right patella, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82091J Other fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S82091K Other fracture of right patella, subsequent encounter for closed fracture with nonunion',
  'S82091M Other fracture of right patella, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82091N Other fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with nonunion',
  'S82091P Other fracture of right patella, subsequent encounter for closed fracture with malunion',
  'S82091Q Other fracture of right patella, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82091R Other fracture of right patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with malunion',
  'S82091S Other fracture of right patella, sequela',
  'S82092A Other fracture of left patella, initial encounter for closed fracture',
  'S82092B Other fracture of left patella, initial encounter for open fracture type I or II',
  'S82092C Other fracture of left patella, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82092D Other fracture of left patella, subsequent encounter for closed fracture with routine healing',
  'S82092E Other fracture of left patella, subsequent encounter for open fracture type I or II with routine',
  'healing',
  'S82092F Other fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'routine healing',
  'S82092G Other fracture of left patella, subsequent encounter for closed fracture with delayed healing',
  'S82092H Other fracture of left patella, subsequent encounter for open fracture type I or II with delayed',
  'healing',
  'S82092J Other fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'delayed healing',
  'S82092K Other fracture of left patella, subsequent encounter for closed fracture with nonunion',
  'S82092M Other fracture of left patella, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82092N Other fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'nonunion',
  'S82092P Other fracture of left patella, subsequent encounter for closed fracture with malunion',
  'S82092Q Other fracture of left patella, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82092R Other fracture of left patella, subsequent encounter for open fracture type IIIA, IIIB, or IIIC with',
  'malunion',
  'S82092S Other fracture of left patella, sequela',
  'S82099A Other fracture of unspecified patella, initial encounter for closed fracture',
  'S82099B Other fracture of unspecified patella, initial encounter for open fracture type I or II',
  'S82099C Other fracture of unspecified patella, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82099D Other fracture of unspecified patella, subsequent encounter for closed fracture with routine',
  'healing',
  'S82099E Other fracture of unspecified patella, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82099F Other fracture of unspecified patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82099G Other fracture of unspecified patella, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82099H Other fracture of unspecified patella, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82099J Other fracture of unspecified patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82099K Other fracture of unspecified patella, subsequent encounter for closed fracture with nonunion',
  'S82099M Other fracture of unspecified patella, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82099N Other fracture of unspecified patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82099P Other fracture of unspecified patella, subsequent encounter for closed fracture with malunion',
  'S82099Q Other fracture of unspecified patella, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82099R Other fracture of unspecified patella, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82099S Other fracture of unspecified patella, sequela',
  'S82101A Unspecified fracture of upper end of right tibia, initial encounter for closed fracture',
  'S82101B Unspecified fracture of upper end of right tibia, initial encounter for open fracture type I or II',
  'S82101C Unspecified fracture of upper end of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82101D Unspecified fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82101E Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82101F Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82101G Unspecified fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82101H Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82101J Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82101K Unspecified fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82101M Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82101N Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82101P Unspecified fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82101Q Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82101R Unspecified fracture of upper end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82101S Unspecified fracture of upper end of right tibia, sequela',
  'S82102A Unspecified fracture of upper end of left tibia, initial encounter for closed fracture',
  'S82102B Unspecified fracture of upper end of left tibia, initial encounter for open fracture type I or II',
  'S82102C Unspecified fracture of upper end of left tibia, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82102D Unspecified fracture of upper end of left tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82102E Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82102F Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82102G Unspecified fracture of upper end of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82102H Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82102J Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82102K Unspecified fracture of upper end of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82102M Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82102N Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82102P Unspecified fracture of upper end of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82102Q Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82102R Unspecified fracture of upper end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82102S Unspecified fracture of upper end of left tibia, sequela',
  'S82109A Unspecified fracture of upper end of unspecified tibia, initial encounter for closed fracture',
  'S82109B Unspecified fracture of upper end of unspecified tibia, initial encounter for open fracture type I',
  'or II',
  'S82109C Unspecified fracture of upper end of unspecified tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82109D Unspecified fracture of upper end of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82109E Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82109F Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82109G Unspecified fracture of upper end of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82109H Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82109J Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82109K Unspecified fracture of upper end of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82109M Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82109N Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82109P Unspecified fracture of upper end of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82109Q Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82109R Unspecified fracture of upper end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82109S Unspecified fracture of upper end of unspecified tibia, sequela',
  'S82111A Displaced fracture of right tibial spine, initial encounter for closed fracture',
  'S82111B Displaced fracture of right tibial spine, initial encounter for open fracture type I or II',
  'S82111C Displaced fracture of right tibial spine, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82111D Displaced fracture of right tibial spine, subsequent encounter for closed fracture with routine',
  'healing',
  'S82111E Displaced fracture of right tibial spine, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82111F Displaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82111G Displaced fracture of right tibial spine, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82111H Displaced fracture of right tibial spine, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82111J Displaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82111K Displaced fracture of right tibial spine, subsequent encounter for closed fracture with nonunion',
  'S82111M Displaced fracture of right tibial spine, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82111N Displaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S82111P Displaced fracture of right tibial spine, subsequent encounter for closed fracture with malunion',
  'S82111Q Displaced fracture of right tibial spine, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82111R Displaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S82111S Displaced fracture of right tibial spine, sequela',
  'S82112A Displaced fracture of left tibial spine, initial encounter for closed fracture',
  'S82112B Displaced fracture of left tibial spine, initial encounter for open fracture type I or II',
  'S82112C Displaced fracture of left tibial spine, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82112D Displaced fracture of left tibial spine, subsequent encounter for closed fracture with routine',
  'healing',
  'S82112E Displaced fracture of left tibial spine, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82112F Displaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82112G Displaced fracture of left tibial spine, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82112H Displaced fracture of left tibial spine, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82112J Displaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82112K Displaced fracture of left tibial spine, subsequent encounter for closed fracture with nonunion',
  'S82112M Displaced fracture of left tibial spine, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82112N Displaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82112P Displaced fracture of left tibial spine, subsequent encounter for closed fracture with malunion',
  'S82112Q Displaced fracture of left tibial spine, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82112R Displaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82112S Displaced fracture of left tibial spine, sequela',
  'S82113A Displaced fracture of unspecified tibial spine, initial encounter for closed fracture',
  'S82113B Displaced fracture of unspecified tibial spine, initial encounter for open fracture type I or II',
  'S82113C Displaced fracture of unspecified tibial spine, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82113D Displaced fracture of unspecified tibial spine, subsequent encounter for closed fracture with',
  'routine healing',
  'S82113E Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82113F Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82113G Displaced fracture of unspecified tibial spine, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82113H Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82113J Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82113K Displaced fracture of unspecified tibial spine, subsequent encounter for closed fracture with',
  'nonunion',
  'S82113M Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82113N Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82113P Displaced fracture of unspecified tibial spine, subsequent encounter for closed fracture with',
  'malunion',
  'S82113Q Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82113R Displaced fracture of unspecified tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82113S Displaced fracture of unspecified tibial spine, sequela',
  'S82114A Nondisplaced fracture of right tibial spine, initial encounter for closed fracture',
  'S82114B Nondisplaced fracture of right tibial spine, initial encounter for open fracture type I or II',
  'S82114C Nondisplaced fracture of right tibial spine, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82114D Nondisplaced fracture of right tibial spine, subsequent encounter for closed fracture with',
  'routine healing',
  'S82114E Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82114F Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82114G Nondisplaced fracture of right tibial spine, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82114H Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82114J Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82114K Nondisplaced fracture of right tibial spine, subsequent encounter for closed fracture with',
  'nonunion',
  'S82114M Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82114N Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82114P Nondisplaced fracture of right tibial spine, subsequent encounter for closed fracture with',
  'malunion',
  'S82114Q Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82114R Nondisplaced fracture of right tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82114S Nondisplaced fracture of right tibial spine, sequela',
  'S82115A Nondisplaced fracture of left tibial spine, initial encounter for closed fracture',
  'S82115B Nondisplaced fracture of left tibial spine, initial encounter for open fracture type I or II',
  'S82115C Nondisplaced fracture of left tibial spine, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82115D Nondisplaced fracture of left tibial spine, subsequent encounter for closed fracture with',
  'routine healing',
  'S82115E Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82115F Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S82115G Nondisplaced fracture of left tibial spine, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82115H Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82115J Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82115K Nondisplaced fracture of left tibial spine, subsequent encounter for closed fracture with',
  'nonunion',
  'S82115M Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82115N Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82115P Nondisplaced fracture of left tibial spine, subsequent encounter for closed fracture with',
  'malunion',
  'S82115Q Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82115R Nondisplaced fracture of left tibial spine, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S82115S Nondisplaced fracture of left tibial spine, sequela',
  'S82116A Nondisplaced fracture of unspecified tibial spine, initial encounter for closed fracture',
  'S82116B Nondisplaced fracture of unspecified tibial spine, initial encounter for open fracture type I or II',
  'S82116C Nondisplaced fracture of unspecified tibial spine, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82116D Nondisplaced fracture of unspecified tibial spine, subsequent encounter for closed fracture',
  'with routine healing',
  'S82116E Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82116F Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82116G Nondisplaced fracture of unspecified tibial spine, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82116H Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82116J Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82116K Nondisplaced fracture of unspecified tibial spine, subsequent encounter for closed fracture',
  'with nonunion',
  'S82116M Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82116N Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82116P Nondisplaced fracture of unspecified tibial spine, subsequent encounter for closed fracture',
  'with malunion',
  'S82116Q Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82116R Nondisplaced fracture of unspecified tibial spine, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82116S Nondisplaced fracture of unspecified tibial spine, sequela',
  'S82121A Displaced fracture of lateral condyle of right tibia, initial encounter for closed fracture',
  'S82121B Displaced fracture of lateral condyle of right tibia, initial encounter for open fracture type I or II',
  'S82121C Displaced fracture of lateral condyle of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82121D Displaced fracture of lateral condyle of right tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82121E Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82121F Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82121G Displaced fracture of lateral condyle of right tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82121H Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82121J Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82121K Displaced fracture of lateral condyle of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82121M Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82121N Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82121P Displaced fracture of lateral condyle of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82121Q Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82121R Displaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82121S Displaced fracture of lateral condyle of right tibia, sequela',
  'S82122A Displaced fracture of lateral condyle of left tibia, initial encounter for closed fracture',
  'S82122B Displaced fracture of lateral condyle of left tibia, initial encounter for open fracture type I or II',
  'S82122C Displaced fracture of lateral condyle of left tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82122D Displaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82122E Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82122F Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82122G Displaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82122H Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82122J Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82122K Displaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82122M Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82122N Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82122P Displaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82122Q Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82122R Displaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82122S Displaced fracture of lateral condyle of left tibia, sequela',
  'S82123A Displaced fracture of lateral condyle of unspecified tibia, initial encounter for closed fracture',
  'S82123B Displaced fracture of lateral condyle of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82123C Displaced fracture of lateral condyle of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82123D Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82123E Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82123F Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82123G Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82123H Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82123J Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82123K Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82123M Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82123N Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82123P Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82123Q Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82123R Displaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82123S Displaced fracture of lateral condyle of unspecified tibia, sequela',
  'S82124A Nondisplaced fracture of lateral condyle of right tibia, initial encounter for closed fracture',
  'S82124B Nondisplaced fracture of lateral condyle of right tibia, initial encounter for open fracture type I',
  'or II',
  'S82124C Nondisplaced fracture of lateral condyle of right tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82124D Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82124E Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82124F Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82124G Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82124H Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82124J Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82124K Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82124M Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82124N Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82124P Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82124Q Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82124R Nondisplaced fracture of lateral condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82124S Nondisplaced fracture of lateral condyle of right tibia, sequela',
  'S82125A Nondisplaced fracture of lateral condyle of left tibia, initial encounter for closed fracture',
  'S82125B Nondisplaced fracture of lateral condyle of left tibia, initial encounter for open fracture type I',
  'or II',
  'S82125C Nondisplaced fracture of lateral condyle of left tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82125D Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82125E Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82125F Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82125G Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82125H Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82125J Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82125K Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82125M Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82125N Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82125P Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82125Q Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82125R Nondisplaced fracture of lateral condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82125S Nondisplaced fracture of lateral condyle of left tibia, sequela',
  'S82126A Nondisplaced fracture of lateral condyle of unspecified tibia, initial encounter for closed',
  'fracture',
  'S82126B Nondisplaced fracture of lateral condyle of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82126C Nondisplaced fracture of lateral condyle of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82126D Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82126E Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82126F Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82126G Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82126H Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82126J Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82126K Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82126M Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82126N Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82126P Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82126Q Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82126R Nondisplaced fracture of lateral condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82126S Nondisplaced fracture of lateral condyle of unspecified tibia, sequela',
  'S82131A Displaced fracture of medial condyle of right tibia, initial encounter for closed fracture',
  'S82131B Displaced fracture of medial condyle of right tibia, initial encounter for open fracture type I or II',
  'S82131C Displaced fracture of medial condyle of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82131D Displaced fracture of medial condyle of right tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82131E Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82131F Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82131G Displaced fracture of medial condyle of right tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82131H Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82131J Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82131K Displaced fracture of medial condyle of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82131M Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82131N Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82131P Displaced fracture of medial condyle of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82131Q Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82131R Displaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82131S Displaced fracture of medial condyle of right tibia, sequela',
  'S82132A Displaced fracture of medial condyle of left tibia, initial encounter for closed fracture',
  'S82132B Displaced fracture of medial condyle of left tibia, initial encounter for open fracture type I or II',
  'S82132C Displaced fracture of medial condyle of left tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82132D Displaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82132E Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82132F Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82132G Displaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82132H Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82132J Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82132K Displaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82132M Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82132N Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82132P Displaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82132Q Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82132R Displaced fracture of medial condyle of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82132S Displaced fracture of medial condyle of left tibia, sequela',
  'S82133A Displaced fracture of medial condyle of unspecified tibia, initial encounter for closed fracture',
  'S82133B Displaced fracture of medial condyle of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82133C Displaced fracture of medial condyle of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82133D Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82133E Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82133F Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82133G Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82133H Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82133J Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82133K Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82133M Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82133N Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82133P Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82133Q Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82133R Displaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82133S Displaced fracture of medial condyle of unspecified tibia, sequela',
  'S82134A Nondisplaced fracture of medial condyle of right tibia, initial encounter for closed fracture',
  'S82134B Nondisplaced fracture of medial condyle of right tibia, initial encounter for open fracture type I',
  'or II',
  'S82134C Nondisplaced fracture of medial condyle of right tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82134D Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82134E Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82134F Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82134G Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82134H Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82134J Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82134K Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82134M Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82134N Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82134P Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82134Q Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82134R Nondisplaced fracture of medial condyle of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82134S Nondisplaced fracture of medial condyle of right tibia, sequela',
  'S82135A Nondisplaced fracture of medial condyle of left tibia, initial encounter for closed fracture',
  'S82135B Nondisplaced fracture of medial condyle of left tibia, initial encounter for open fracture type I',
  'or II',
  'S82135C Nondisplaced fracture of medial condyle of left tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82135D Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82135E Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82135F Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82135G Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82135H Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82135J Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82135K Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82135M Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82135N Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82135P Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82135Q Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82135R Nondisplaced fracture of medial condyle of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82135S Nondisplaced fracture of medial condyle of left tibia, sequela',
  'S82136A Nondisplaced fracture of medial condyle of unspecified tibia, initial encounter for closed',
  'fracture',
  'S82136B Nondisplaced fracture of medial condyle of unspecified tibia, initial encounter for open',
  'fracture type I or II',
  'S82136C Nondisplaced fracture of medial condyle of unspecified tibia, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82136D Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82136E Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82136F Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82136G Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82136H Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82136J Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82136K Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82136M Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82136N Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82136P Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82136Q Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82136R Nondisplaced fracture of medial condyle of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82136S Nondisplaced fracture of medial condyle of unspecified tibia, sequela',
  'S82141A Displaced bicondylar fracture of right tibia, initial encounter for closed fracture',
  'S82141B Displaced bicondylar fracture of right tibia, initial encounter for open fracture type I or II',
  'S82141C Displaced bicondylar fracture of right tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82141D Displaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82141E Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82141F Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82141G Displaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82141H Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82141J Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82141K Displaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82141M Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82141N Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82141P Displaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82141Q Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82141R Displaced bicondylar fracture of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82141S Displaced bicondylar fracture of right tibia, sequela',
  'S82142A Displaced bicondylar fracture of left tibia, initial encounter for closed fracture',
  'S82142B Displaced bicondylar fracture of left tibia, initial encounter for open fracture type I or II',
  'S82142C Displaced bicondylar fracture of left tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82142D Displaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82142E Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82142F Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82142G Displaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82142H Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82142J Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82142K Displaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82142M Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82142N Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82142P Displaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82142Q Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82142R Displaced bicondylar fracture of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82142S Displaced bicondylar fracture of left tibia, sequela',
  'S82143A Displaced bicondylar fracture of unspecified tibia, initial encounter for closed fracture',
  'S82143B Displaced bicondylar fracture of unspecified tibia, initial encounter for open fracture type I or II',
  'S82143C Displaced bicondylar fracture of unspecified tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82143D Displaced bicondylar fracture of unspecified tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82143E Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82143F Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82143G Displaced bicondylar fracture of unspecified tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82143H Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82143J Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82143K Displaced bicondylar fracture of unspecified tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82143M Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82143N Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82143P Displaced bicondylar fracture of unspecified tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82143Q Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82143R Displaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82143S Displaced bicondylar fracture of unspecified tibia, sequela',
  'S82144A Nondisplaced bicondylar fracture of right tibia, initial encounter for closed fracture',
  'S82144B Nondisplaced bicondylar fracture of right tibia, initial encounter for open fracture type I or II',
  'S82144C Nondisplaced bicondylar fracture of right tibia, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82144D Nondisplaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82144E Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82144F Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82144G Nondisplaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82144H Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82144J Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82144K Nondisplaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82144M Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82144N Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82144P Nondisplaced bicondylar fracture of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82144Q Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82144R Nondisplaced bicondylar fracture of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82144S Nondisplaced bicondylar fracture of right tibia, sequela',
  'S82145A Nondisplaced bicondylar fracture of left tibia, initial encounter for closed fracture',
  'S82145B Nondisplaced bicondylar fracture of left tibia, initial encounter for open fracture type I or II',
  'S82145C Nondisplaced bicondylar fracture of left tibia, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82145D Nondisplaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82145E Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82145F Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82145G Nondisplaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82145H Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82145J Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82145K Nondisplaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82145M Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82145N Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82145P Nondisplaced bicondylar fracture of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82145Q Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82145R Nondisplaced bicondylar fracture of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82145S Nondisplaced bicondylar fracture of left tibia, sequela',
  'S82146A Nondisplaced bicondylar fracture of unspecified tibia, initial encounter for closed fracture',
  'S82146B Nondisplaced bicondylar fracture of unspecified tibia, initial encounter for open fracture type I',
  'or II',
  'S82146C Nondisplaced bicondylar fracture of unspecified tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82146D Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82146E Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82146F Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82146G Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82146H Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82146J Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82146K Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82146M Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82146N Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82146P Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82146Q Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82146R Nondisplaced bicondylar fracture of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82146S Nondisplaced bicondylar fracture of unspecified tibia, sequela',
  'S82151A Displaced fracture of right tibial tuberosity, initial encounter for closed fracture',
  'S82151B Displaced fracture of right tibial tuberosity, initial encounter for open fracture type I or II',
  'S82151C Displaced fracture of right tibial tuberosity, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82151D Displaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'routine healing',
  'S82151E Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82151F Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82151G Displaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82151H Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82151J Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82151K Displaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'nonunion',
  'S82151M Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82151N Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82151P Displaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'malunion',
  'S82151Q Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82151R Displaced fracture of right tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82151S Displaced fracture of right tibial tuberosity, sequela',
  'S82152A Displaced fracture of left tibial tuberosity, initial encounter for closed fracture',
  'S82152B Displaced fracture of left tibial tuberosity, initial encounter for open fracture type I or II',
  'S82152C Displaced fracture of left tibial tuberosity, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82152D Displaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'routine healing',
  'S82152E Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82152F Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82152G Displaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82152H Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82152J Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82152K Displaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'nonunion',
  'S82152M Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82152N Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82152P Displaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'malunion',
  'S82152Q Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82152R Displaced fracture of left tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82152S Displaced fracture of left tibial tuberosity, sequela',
  'S82153A Displaced fracture of unspecified tibial tuberosity, initial encounter for closed fracture',
  'S82153B Displaced fracture of unspecified tibial tuberosity, initial encounter for open fracture type I or II',
  'S82153C Displaced fracture of unspecified tibial tuberosity, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82153D Displaced fracture of unspecified tibial tuberosity, subsequent encounter for closed fracture',
  'with routine healing',
  'S82153E Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82153F Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82153G Displaced fracture of unspecified tibial tuberosity, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82153H Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82153J Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82153K Displaced fracture of unspecified tibial tuberosity, subsequent encounter for closed fracture',
  'with nonunion',
  'S82153M Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82153N Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82153P Displaced fracture of unspecified tibial tuberosity, subsequent encounter for closed fracture',
  'with malunion',
  'S82153Q Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82153R Displaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82153S Displaced fracture of unspecified tibial tuberosity, sequela',
  'S82154A Nondisplaced fracture of right tibial tuberosity, initial encounter for closed fracture',
  'S82154B Nondisplaced fracture of right tibial tuberosity, initial encounter for open fracture type I or II',
  'S82154C Nondisplaced fracture of right tibial tuberosity, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82154D Nondisplaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'routine healing',
  'S82154E Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82154F Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82154G Nondisplaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82154H Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82154J Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82154K Nondisplaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'nonunion',
  'S82154M Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82154N Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82154P Nondisplaced fracture of right tibial tuberosity, subsequent encounter for closed fracture with',
  'malunion',
  'S82154Q Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82154R Nondisplaced fracture of right tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82154S Nondisplaced fracture of right tibial tuberosity, sequela',
  'S82155A Nondisplaced fracture of left tibial tuberosity, initial encounter for closed fracture',
  'S82155B Nondisplaced fracture of left tibial tuberosity, initial encounter for open fracture type I or II',
  'S82155C Nondisplaced fracture of left tibial tuberosity, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82155D Nondisplaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'routine healing',
  'S82155E Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82155F Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82155G Nondisplaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82155H Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82155J Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82155K Nondisplaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'nonunion',
  'S82155M Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82155N Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82155P Nondisplaced fracture of left tibial tuberosity, subsequent encounter for closed fracture with',
  'malunion',
  'S82155Q Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82155R Nondisplaced fracture of left tibial tuberosity, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82155S Nondisplaced fracture of left tibial tuberosity, sequela',
  'S82156A Nondisplaced fracture of unspecified tibial tuberosity, initial encounter for closed fracture',
  'S82156B Nondisplaced fracture of unspecified tibial tuberosity, initial encounter for open fracture type I',
  'or II',
  'S82156C Nondisplaced fracture of unspecified tibial tuberosity, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82156D Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for closed',
  'fracture with routine healing',
  'S82156E Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82156F Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82156G Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82156H Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82156J Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82156K Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for closed',
  'fracture with nonunion',
  'S82156M Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82156N Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82156P Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for closed',
  'fracture with malunion',
  'S82156Q Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82156R Nondisplaced fracture of unspecified tibial tuberosity, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82156S Nondisplaced fracture of unspecified tibial tuberosity, sequela',
  'S82161A Torus fracture of upper end of right tibia, initial encounter for closed fracture',
  'S82161D Torus fracture of upper end of right tibia, subsequent encounter for fracture with routine',
  'healing',
  'S82161G Torus fracture of upper end of right tibia, subsequent encounter for fracture with delayed',
  'healing',
  'S82161K Torus fracture of upper end of right tibia, subsequent encounter for fracture with nonunion',
  'S82161P Torus fracture of upper end of right tibia, subsequent encounter for fracture with malunion',
  'S82161S Torus fracture of upper end of right tibia, sequela',
  'S82162A Torus fracture of upper end of left tibia, initial encounter for closed fracture',
  'S82162D Torus fracture of upper end of left tibia, subsequent encounter for fracture with routine',
  'healing',
  'S82162G Torus fracture of upper end of left tibia, subsequent encounter for fracture with delayed',
  'healing',
  'S82162K Torus fracture of upper end of left tibia, subsequent encounter for fracture with nonunion',
  'S82162P Torus fracture of upper end of left tibia, subsequent encounter for fracture with malunion',
  'S82162S Torus fracture of upper end of left tibia, sequela',
  'S82169A Torus fracture of upper end of unspecified tibia, initial encounter for closed fracture',
  'S82169D Torus fracture of upper end of unspecified tibia, subsequent encounter for fracture with',
  'routine healing',
  'S82169G Torus fracture of upper end of unspecified tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S82169K Torus fracture of upper end of unspecified tibia, subsequent encounter for fracture with',
  'nonunion',
  'S82169P Torus fracture of upper end of unspecified tibia, subsequent encounter for fracture with',
  'malunion',
  'S82169S Torus fracture of upper end of unspecified tibia, sequela',
  'S82191A Other fracture of upper end of right tibia, initial encounter for closed fracture',
  'S82191B Other fracture of upper end of right tibia, initial encounter for open fracture type I or II',
  'S82191C Other fracture of upper end of right tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82191D Other fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82191E Other fracture of upper end of right tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82191F Other fracture of upper end of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82191G Other fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82191H Other fracture of upper end of right tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82191J Other fracture of upper end of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82191K Other fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82191M Other fracture of upper end of right tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82191N Other fracture of upper end of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82191P Other fracture of upper end of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82191Q Other fracture of upper end of right tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82191R Other fracture of upper end of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82191S Other fracture of upper end of right tibia, sequela',
  'S82192A Other fracture of upper end of left tibia, initial encounter for closed fracture',
  'S82192B Other fracture of upper end of left tibia, initial encounter for open fracture type I or II',
  'S82192C Other fracture of upper end of left tibia, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82192D Other fracture of upper end of left tibia, subsequent encounter for closed fracture with routine',
  'healing',
  'S82192E Other fracture of upper end of left tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82192F Other fracture of upper end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S82192G Other fracture of upper end of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82192H Other fracture of upper end of left tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82192J Other fracture of upper end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82192K Other fracture of upper end of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82192M Other fracture of upper end of left tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82192N Other fracture of upper end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S82192P Other fracture of upper end of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82192Q Other fracture of upper end of left tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82192R Other fracture of upper end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S82192S Other fracture of upper end of left tibia, sequela',
  'S82199A Other fracture of upper end of unspecified tibia, initial encounter for closed fracture',
  'S82199B Other fracture of upper end of unspecified tibia, initial encounter for open fracture type I or II',
  'S82199C Other fracture of upper end of unspecified tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82199D Other fracture of upper end of unspecified tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82199E Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82199F Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82199G Other fracture of upper end of unspecified tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82199H Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82199J Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82199K Other fracture of upper end of unspecified tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82199M Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82199N Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82199P Other fracture of upper end of unspecified tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82199Q Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82199R Other fracture of upper end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82199S Other fracture of upper end of unspecified tibia, sequela',
  'S82201A Unspecified fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82201B Unspecified fracture of shaft of right tibia, initial encounter for open fracture type I or II',
  'S82201C Unspecified fracture of shaft of right tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82201D Unspecified fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82201E Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82201F Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82201G Unspecified fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82201H Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82201J Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82201K Unspecified fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82201M Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82201N Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82201P Unspecified fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82201Q Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82201R Unspecified fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82201S Unspecified fracture of shaft of right tibia, sequela',
  'S82202A Unspecified fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82202B Unspecified fracture of shaft of left tibia, initial encounter for open fracture type I or II',
  'S82202C Unspecified fracture of shaft of left tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82202D Unspecified fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82202E Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82202F Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S82202G Unspecified fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82202H Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82202J Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82202K Unspecified fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82202M Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82202N Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82202P Unspecified fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82202Q Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82202R Unspecified fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S82202S Unspecified fracture of shaft of left tibia, sequela',
  'S82209A Unspecified fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82209B Unspecified fracture of shaft of unspecified tibia, initial encounter for open fracture type I or II',
  'S82209C Unspecified fracture of shaft of unspecified tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82209D Unspecified fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82209E Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82209F Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82209G Unspecified fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82209H Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82209J Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82209K Unspecified fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82209M Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82209N Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82209P Unspecified fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82209Q Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82209R Unspecified fracture of shaft of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82209S Unspecified fracture of shaft of unspecified tibia, sequela',
  'S82221A Displaced transverse fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82221B Displaced transverse fracture of shaft of right tibia, initial encounter for open fracture type I or',
  'II',
  'S82221C Displaced transverse fracture of shaft of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82221D Displaced transverse fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82221E Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82221F Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82221G Displaced transverse fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82221H Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82221J Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82221K Displaced transverse fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82221M Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82221N Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82221P Displaced transverse fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82221Q Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82221R Displaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82221S Displaced transverse fracture of shaft of right tibia, sequela',
  'S82222A Displaced transverse fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82222B Displaced transverse fracture of shaft of left tibia, initial encounter for open fracture type I or II',
  'S82222C Displaced transverse fracture of shaft of left tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82222D Displaced transverse fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82222E Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82222F Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82222G Displaced transverse fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82222H Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82222J Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82222K Displaced transverse fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82222M Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82222N Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82222P Displaced transverse fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82222Q Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82222R Displaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82222S Displaced transverse fracture of shaft of left tibia, sequela',
  'S82223A Displaced transverse fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82223B Displaced transverse fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82223C Displaced transverse fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82223D Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82223E Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82223F Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82223G Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82223H Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82223J Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82223K Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82223M Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82223N Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82223P Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82223Q Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82223R Displaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82223S Displaced transverse fracture of shaft of unspecified tibia, sequela',
  'S82224A Nondisplaced transverse fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82224B Nondisplaced transverse fracture of shaft of right tibia, initial encounter for open fracture type',
  'I or II',
  'S82224C Nondisplaced transverse fracture of shaft of right tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82224D Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82224E Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82224F Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82224G Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82224H Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82224J Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82224K Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82224M Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82224N Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82224P Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82224Q Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82224R Nondisplaced transverse fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82224S Nondisplaced transverse fracture of shaft of right tibia, sequela',
  'S82225A Nondisplaced transverse fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82225B Nondisplaced transverse fracture of shaft of left tibia, initial encounter for open fracture type I',
  'or II',
  'S82225C Nondisplaced transverse fracture of shaft of left tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82225D Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82225E Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82225F Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82225G Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82225H Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82225J Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82225K Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82225M Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82225N Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82225P Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82225Q Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82225R Nondisplaced transverse fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82225S Nondisplaced transverse fracture of shaft of left tibia, sequela',
  'S82226A Nondisplaced transverse fracture of shaft of unspecified tibia, initial encounter for closed',
  'fracture',
  'S82226B Nondisplaced transverse fracture of shaft of unspecified tibia, initial encounter for open',
  'fracture type I or II',
  'S82226C Nondisplaced transverse fracture of shaft of unspecified tibia, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82226D Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82226E Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82226F Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82226G Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82226H Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82226J Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82226K Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82226M Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82226N Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82226P Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82226Q Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82226R Nondisplaced transverse fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82226S Nondisplaced transverse fracture of shaft of unspecified tibia, sequela',
  'S82231A Displaced oblique fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82231B Displaced oblique fracture of shaft of right tibia, initial encounter for open fracture type I or II',
  'S82231C Displaced oblique fracture of shaft of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82231D Displaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82231E Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82231F Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82231G Displaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82231H Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82231J Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82231K Displaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82231M Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82231N Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82231P Displaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82231Q Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82231R Displaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82231S Displaced oblique fracture of shaft of right tibia, sequela',
  'S82232A Displaced oblique fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82232B Displaced oblique fracture of shaft of left tibia, initial encounter for open fracture type I or II',
  'S82232C Displaced oblique fracture of shaft of left tibia, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82232D Displaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82232E Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82232F Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82232G Displaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82232H Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82232J Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82232K Displaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82232M Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82232N Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82232P Displaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82232Q Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82232R Displaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82232S Displaced oblique fracture of shaft of left tibia, sequela',
  'S82233A Displaced oblique fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82233B Displaced oblique fracture of shaft of unspecified tibia, initial encounter for open fracture type',
  'I or II',
  'S82233C Displaced oblique fracture of shaft of unspecified tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82233D Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82233E Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82233F Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82233G Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82233H Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82233J Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82233K Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82233M Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82233N Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82233P Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82233Q Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82233R Displaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82233S Displaced oblique fracture of shaft of unspecified tibia, sequela',
  'S82234A Nondisplaced oblique fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82234B Nondisplaced oblique fracture of shaft of right tibia, initial encounter for open fracture type I',
  'or II',
  'S82234C Nondisplaced oblique fracture of shaft of right tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82234D Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82234E Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82234F Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82234G Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82234H Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82234J Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82234K Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82234M Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82234N Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82234P Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82234Q Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82234R Nondisplaced oblique fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82234S Nondisplaced oblique fracture of shaft of right tibia, sequela',
  'S82235A Nondisplaced oblique fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82235B Nondisplaced oblique fracture of shaft of left tibia, initial encounter for open fracture type I or',
  'II',
  'S82235C Nondisplaced oblique fracture of shaft of left tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82235D Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82235E Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82235F Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82235G Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82235H Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82235J Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82235K Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82235M Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82235N Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82235P Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82235Q Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82235R Nondisplaced oblique fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82235S Nondisplaced oblique fracture of shaft of left tibia, sequela',
  'S82236A Nondisplaced oblique fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82236B Nondisplaced oblique fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82236C Nondisplaced oblique fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82236D Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82236E Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82236F Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82236G Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82236H Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82236J Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82236K Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82236M Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82236N Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82236P Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82236Q Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82236R Nondisplaced oblique fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82236S Nondisplaced oblique fracture of shaft of unspecified tibia, sequela',
  'S82241A Displaced spiral fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82241B Displaced spiral fracture of shaft of right tibia, initial encounter for open fracture type I or II',
  'S82241C Displaced spiral fracture of shaft of right tibia, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82241D Displaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82241E Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82241F Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82241G Displaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82241H Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82241J Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82241K Displaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82241M Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82241N Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82241P Displaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82241Q Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82241R Displaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82241S Displaced spiral fracture of shaft of right tibia, sequela',
  'S82242A Displaced spiral fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82242B Displaced spiral fracture of shaft of left tibia, initial encounter for open fracture type I or II',
  'S82242C Displaced spiral fracture of shaft of left tibia, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82242D Displaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82242E Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82242F Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82242G Displaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82242H Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82242J Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82242K Displaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82242M Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S82242N Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82242P Displaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82242Q Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82242R Displaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82242S Displaced spiral fracture of shaft of left tibia, sequela',
  'S82243A Displaced spiral fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82243B Displaced spiral fracture of shaft of unspecified tibia, initial encounter for open fracture type I',
  'or II',
  'S82243C Displaced spiral fracture of shaft of unspecified tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82243D Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82243E Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82243F Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82243G Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82243H Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82243J Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82243K Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82243M Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82243N Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82243P Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82243Q Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82243R Displaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82243S Displaced spiral fracture of shaft of unspecified tibia, sequela',
  'S82244A Nondisplaced spiral fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82244B Nondisplaced spiral fracture of shaft of right tibia, initial encounter for open fracture type I or II',
  'S82244C Nondisplaced spiral fracture of shaft of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82244D Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82244E Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82244F Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82244G Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82244H Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82244J Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82244K Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82244M Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82244N Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82244P Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82244Q Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82244R Nondisplaced spiral fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82244S Nondisplaced spiral fracture of shaft of right tibia, sequela',
  'S82245A Nondisplaced spiral fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82245B Nondisplaced spiral fracture of shaft of left tibia, initial encounter for open fracture type I or II',
  'S82245C Nondisplaced spiral fracture of shaft of left tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82245D Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82245E Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82245F Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82245G Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82245H Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82245J Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82245K Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82245M Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82245N Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82245P Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82245Q Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82245R Nondisplaced spiral fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82245S Nondisplaced spiral fracture of shaft of left tibia, sequela',
  'S82246A Nondisplaced spiral fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82246B Nondisplaced spiral fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82246C Nondisplaced spiral fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82246D Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82246E Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82246F Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82246G Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82246H Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82246J Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82246K Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82246M Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82246N Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82246P Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82246Q Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82246R Nondisplaced spiral fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82246S Nondisplaced spiral fracture of shaft of unspecified tibia, sequela',
  'S82251A Displaced comminuted fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82251B Displaced comminuted fracture of shaft of right tibia, initial encounter for open fracture type I',
  'or II',
  'S82251C Displaced comminuted fracture of shaft of right tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82251D Displaced comminuted fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82251E Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82251F Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82251G Displaced comminuted fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82251H Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82251J Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82251K Displaced comminuted fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82251M Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82251N Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82251P Displaced comminuted fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82251Q Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82251R Displaced comminuted fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82251S Displaced comminuted fracture of shaft of right tibia, sequela',
  'S82252A Displaced comminuted fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82252B Displaced comminuted fracture of shaft of left tibia, initial encounter for open fracture type I',
  'or II',
  'S82252C Displaced comminuted fracture of shaft of left tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82252D Displaced comminuted fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82252E Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82252F Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82252G Displaced comminuted fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82252H Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82252J Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82252K Displaced comminuted fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82252M Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82252N Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82252P Displaced comminuted fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82252Q Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82252R Displaced comminuted fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82252S Displaced comminuted fracture of shaft of left tibia, sequela',
  'S82253A Displaced comminuted fracture of shaft of unspecified tibia, initial encounter for closed',
  'fracture',
  'S82253B Displaced comminuted fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82253C Displaced comminuted fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82253D Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82253E Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82253F Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82253G Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82253H Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82253J Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82253K Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82253M Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82253N Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82253P Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82253Q Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82253R Displaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82253S Displaced comminuted fracture of shaft of unspecified tibia, sequela',
  'S82254A Nondisplaced comminuted fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82254B Nondisplaced comminuted fracture of shaft of right tibia, initial encounter for open fracture',
  'type I or II',
  'S82254C Nondisplaced comminuted fracture of shaft of right tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82254D Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82254E Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82254F Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82254G Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82254H Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82254J Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82254K Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82254M Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82254N Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82254P Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82254Q Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82254R Nondisplaced comminuted fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82254S Nondisplaced comminuted fracture of shaft of right tibia, sequela',
  'S82255A Nondisplaced comminuted fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82255B Nondisplaced comminuted fracture of shaft of left tibia, initial encounter for open fracture',
  'type I or II',
  'S82255C Nondisplaced comminuted fracture of shaft of left tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82255D Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82255E Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82255F Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82255G Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82255H Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82255J Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82255K Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82255M Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82255N Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82255P Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82255Q Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82255R Nondisplaced comminuted fracture of shaft of left tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82255S Nondisplaced comminuted fracture of shaft of left tibia, sequela',
  'S82256A Nondisplaced comminuted fracture of shaft of unspecified tibia, initial encounter for closed',
  'fracture',
  'S82256B Nondisplaced comminuted fracture of shaft of unspecified tibia, initial encounter for open',
  'fracture type I or II',
  'S82256C Nondisplaced comminuted fracture of shaft of unspecified tibia, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82256D Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'closed fracture with routine healing',
  'S82256E Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S82256F Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82256G Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'closed fracture with delayed healing',
  'S82256H Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S82256J Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82256K Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'closed fracture with nonunion',
  'S82256M Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S82256N Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82256P Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'closed fracture with malunion',
  'S82256Q Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S82256R Nondisplaced comminuted fracture of shaft of unspecified tibia, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S82256S Nondisplaced comminuted fracture of shaft of unspecified tibia, sequela',
  'S82261A Displaced segmental fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82261B Displaced segmental fracture of shaft of right tibia, initial encounter for open fracture type I or',
  'II',
  'S82261C Displaced segmental fracture of shaft of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82261D Displaced segmental fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82261E Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82261F Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82261G Displaced segmental fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82261H Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82261J Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82261K Displaced segmental fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82261M Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82261N Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82261P Displaced segmental fracture of shaft of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82261Q Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82261R Displaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82261S Displaced segmental fracture of shaft of right tibia, sequela',
  'S82262A Displaced segmental fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82262B Displaced segmental fracture of shaft of left tibia, initial encounter for open fracture type I or II',
  'S82262C Displaced segmental fracture of shaft of left tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82262D Displaced segmental fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S82262E Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82262F Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82262G Displaced segmental fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82262H Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82262J Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82262K Displaced segmental fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S82262M Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82262N Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82262P Displaced segmental fracture of shaft of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S82262Q Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82262R Displaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82262S Displaced segmental fracture of shaft of left tibia, sequela',
  'S82263A Displaced segmental fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82263B Displaced segmental fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S82263C Displaced segmental fracture of shaft of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82263D Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82263E Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82263F Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82263G Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82263H Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82263J Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82263K Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82263M Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82263N Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82263P Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82263Q Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82263R Displaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82263S Displaced segmental fracture of shaft of unspecified tibia, sequela',
  'S82264A Nondisplaced segmental fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82264B Nondisplaced segmental fracture of shaft of right tibia, initial encounter for open fracture type',
  'I or II',
  'S82264C Nondisplaced segmental fracture of shaft of right tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82264D Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82264E Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82264F Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82264G Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82264H Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82264J Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82264K Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82264M Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82264N Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82264P Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82264Q Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82264R Nondisplaced segmental fracture of shaft of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82264S Nondisplaced segmental fracture of shaft of right tibia, sequela',
  'S82265A Nondisplaced segmental fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82265B Nondisplaced segmental fracture of shaft of left tibia, initial encounter for open fracture type I',
  'or II',
  'S82265C Nondisplaced segmental fracture of shaft of left tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82265D Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82265E Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82265F Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82265G Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82265H Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82265J Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82265K Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82265M Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82265N Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82265P Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82265Q Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82265R Nondisplaced segmental fracture of shaft of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82265S Nondisplaced segmental fracture of shaft of left tibia, sequela',
  'S82266A Nondisplaced segmental fracture of shaft of unspecified tibia, initial encounter for closed',
  'fracture',
  'S82266B Nondisplaced segmental fracture of shaft of unspecified tibia, initial encounter for open',
  'fracture type I or II',
  'S82266C Nondisplaced segmental fracture of shaft of unspecified tibia, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82266D Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82266E Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82266F Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82266G Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82266H Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82266J Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82266K Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82266M Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82266N Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82266P Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82266Q Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82266R Nondisplaced segmental fracture of shaft of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82266S Nondisplaced segmental fracture of shaft of unspecified tibia, sequela',
  'S82291A Other fracture of shaft of right tibia, initial encounter for closed fracture',
  'S82291B Other fracture of shaft of right tibia, initial encounter for open fracture type I or II',
  'S82291C Other fracture of shaft of right tibia, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82291D Other fracture of shaft of right tibia, subsequent encounter for closed fracture with routine',
  'healing',
  'S82291E Other fracture of shaft of right tibia, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82291F Other fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82291G Other fracture of shaft of right tibia, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82291H Other fracture of shaft of right tibia, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82291J Other fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82291K Other fracture of shaft of right tibia, subsequent encounter for closed fracture with nonunion',
  'S82291M Other fracture of shaft of right tibia, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82291N Other fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82291P Other fracture of shaft of right tibia, subsequent encounter for closed fracture with malunion',
  'S82291Q Other fracture of shaft of right tibia, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82291R Other fracture of shaft of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82291S Other fracture of shaft of right tibia, sequela',
  'S82292A Other fracture of shaft of left tibia, initial encounter for closed fracture',
  'S82292B Other fracture of shaft of left tibia, initial encounter for open fracture type I or II',
  'S82292C Other fracture of shaft of left tibia, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82292D Other fracture of shaft of left tibia, subsequent encounter for closed fracture with routine',
  'healing',
  'S82292E Other fracture of shaft of left tibia, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82292F Other fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82292G Other fracture of shaft of left tibia, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82292H Other fracture of shaft of left tibia, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82292J Other fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S82292K Other fracture of shaft of left tibia, subsequent encounter for closed fracture with nonunion',
  'S82292M Other fracture of shaft of left tibia, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82292N Other fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82292P Other fracture of shaft of left tibia, subsequent encounter for closed fracture with malunion',
  'S82292Q Other fracture of shaft of left tibia, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82292R Other fracture of shaft of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82292S Other fracture of shaft of left tibia, sequela',
  'S82299A Other fracture of shaft of unspecified tibia, initial encounter for closed fracture',
  'S82299B Other fracture of shaft of unspecified tibia, initial encounter for open fracture type I or II',
  'S82299C Other fracture of shaft of unspecified tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82299D Other fracture of shaft of unspecified tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82299E Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82299F Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82299G Other fracture of shaft of unspecified tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82299H Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82299J Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82299K Other fracture of shaft of unspecified tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82299M Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82299N Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82299P Other fracture of shaft of unspecified tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82299Q Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82299R Other fracture of shaft of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82299S Other fracture of shaft of unspecified tibia, sequela',
  'S82301A Unspecified fracture of lower end of right tibia, initial encounter for closed fracture',
  'S82301B Unspecified fracture of lower end of right tibia, initial encounter for open fracture type I or II',
  'S82301C Unspecified fracture of lower end of right tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82301D Unspecified fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82301E Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82301F Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82301G Unspecified fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82301H Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82301J Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82301K Unspecified fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82301M Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82301N Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82301P Unspecified fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82301Q Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82301R Unspecified fracture of lower end of right tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82301S Unspecified fracture of lower end of right tibia, sequela',
  'S82302A Unspecified fracture of lower end of left tibia, initial encounter for closed fracture',
  'S82302B Unspecified fracture of lower end of left tibia, initial encounter for open fracture type I or II',
  'S82302C Unspecified fracture of lower end of left tibia, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82302D Unspecified fracture of lower end of left tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82302E Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82302F Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82302G Unspecified fracture of lower end of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82302H Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82302J Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82302K Unspecified fracture of lower end of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82302M Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82302N Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82302P Unspecified fracture of lower end of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82302Q Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82302R Unspecified fracture of lower end of left tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82302S Unspecified fracture of lower end of left tibia, sequela',
  'S82309A Unspecified fracture of lower end of unspecified tibia, initial encounter for closed fracture',
  'S82309B Unspecified fracture of lower end of unspecified tibia, initial encounter for open fracture type I',
  'or II',
  'S82309C Unspecified fracture of lower end of unspecified tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82309D Unspecified fracture of lower end of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S82309E Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82309F Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82309G Unspecified fracture of lower end of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82309H Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82309J Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82309K Unspecified fracture of lower end of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S82309M Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82309N Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82309P Unspecified fracture of lower end of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S82309Q Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82309R Unspecified fracture of lower end of unspecified tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82309S Unspecified fracture of lower end of unspecified tibia, sequela',
  'S82311A Torus fracture of lower end of right tibia, initial encounter for closed fracture',
  'S82311D Torus fracture of lower end of right tibia, subsequent encounter for fracture with routine',
  'healing',
  'S82311G Torus fracture of lower end of right tibia, subsequent encounter for fracture with delayed',
  'healing',
  'S82311K Torus fracture of lower end of right tibia, subsequent encounter for fracture with nonunion',
  'S82311P Torus fracture of lower end of right tibia, subsequent encounter for fracture with malunion',
  'S82311S Torus fracture of lower end of right tibia, sequela',
  'S82312A Torus fracture of lower end of left tibia, initial encounter for closed fracture',
  'S82312D Torus fracture of lower end of left tibia, subsequent encounter for fracture with routine',
  'healing',
  'S82312G Torus fracture of lower end of left tibia, subsequent encounter for fracture with delayed',
  'healing',
  'S82312K Torus fracture of lower end of left tibia, subsequent encounter for fracture with nonunion',
  'S82312P Torus fracture of lower end of left tibia, subsequent encounter for fracture with malunion',
  'S82312S Torus fracture of lower end of left tibia, sequela',
  'S82319A Torus fracture of lower end of unspecified tibia, initial encounter for closed fracture',
  'S82319D Torus fracture of lower end of unspecified tibia, subsequent encounter for fracture with',
  'routine healing',
  'S82319G Torus fracture of lower end of unspecified tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S82319K Torus fracture of lower end of unspecified tibia, subsequent encounter for fracture with',
  'nonunion',
  'S82319P Torus fracture of lower end of unspecified tibia, subsequent encounter for fracture with',
  'malunion',
  'S82319S Torus fracture of lower end of unspecified tibia, sequela',
  'S82391A Other fracture of lower end of right tibia, initial encounter for closed fracture',
  'S82391B Other fracture of lower end of right tibia, initial encounter for open fracture type I or II',
  'S82391C Other fracture of lower end of right tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82391D Other fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82391E Other fracture of lower end of right tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82391F Other fracture of lower end of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82391G Other fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82391H Other fracture of lower end of right tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82391J Other fracture of lower end of right tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82391K Other fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82391M Other fracture of lower end of right tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82391N Other fracture of lower end of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82391P Other fracture of lower end of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82391Q Other fracture of lower end of right tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82391R Other fracture of lower end of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82391S Other fracture of lower end of right tibia, sequela',
  'S82392A Other fracture of lower end of left tibia, initial encounter for closed fracture',
  'S82392B Other fracture of lower end of left tibia, initial encounter for open fracture type I or II',
  'S82392C Other fracture of lower end of left tibia, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82392D Other fracture of lower end of left tibia, subsequent encounter for closed fracture with routine',
  'healing',
  'S82392E Other fracture of lower end of left tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82392F Other fracture of lower end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S82392G Other fracture of lower end of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82392H Other fracture of lower end of left tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82392J Other fracture of lower end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82392K Other fracture of lower end of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82392M Other fracture of lower end of left tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82392N Other fracture of lower end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S82392P Other fracture of lower end of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82392Q Other fracture of lower end of left tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82392R Other fracture of lower end of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S82392S Other fracture of lower end of left tibia, sequela',
  'S82399A Other fracture of lower end of unspecified tibia, initial encounter for closed fracture',
  'S82399B Other fracture of lower end of unspecified tibia, initial encounter for open fracture type I or II',
  'S82399C Other fracture of lower end of unspecified tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82399D Other fracture of lower end of unspecified tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82399E Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82399F Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82399G Other fracture of lower end of unspecified tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82399H Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82399J Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82399K Other fracture of lower end of unspecified tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82399M Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82399N Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82399P Other fracture of lower end of unspecified tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82399Q Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82399R Other fracture of lower end of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82399S Other fracture of lower end of unspecified tibia, sequela',
  'S82401A Unspecified fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82401B Unspecified fracture of shaft of right fibula, initial encounter for open fracture type I or II',
  'S82401C Unspecified fracture of shaft of right fibula, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82401D Unspecified fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'routine healing',
  'S82401E Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82401F Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82401G Unspecified fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82401H Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82401J Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82401K Unspecified fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'nonunion',
  'S82401M Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S82401N Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82401P Unspecified fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'malunion',
  'S82401Q Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82401R Unspecified fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82401S Unspecified fracture of shaft of right fibula, sequela',
  'S82402A Unspecified fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82402B Unspecified fracture of shaft of left fibula, initial encounter for open fracture type I or II',
  'S82402C Unspecified fracture of shaft of left fibula, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82402D Unspecified fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'routine healing',
  'S82402E Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82402F Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82402G Unspecified fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82402H Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82402J Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82402K Unspecified fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'nonunion',
  'S82402M Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82402N Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82402P Unspecified fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'malunion',
  'S82402Q Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82402R Unspecified fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82402S Unspecified fracture of shaft of left fibula, sequela',
  'S82409A Unspecified fracture of shaft of unspecified fibula, initial encounter for closed fracture',
  'S82409B Unspecified fracture of shaft of unspecified fibula, initial encounter for open fracture type I or',
  'II',
  'S82409C Unspecified fracture of shaft of unspecified fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82409D Unspecified fracture of shaft of unspecified fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82409E Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82409F Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82409G Unspecified fracture of shaft of unspecified fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82409H Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82409J Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82409K Unspecified fracture of shaft of unspecified fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82409M Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82409N Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82409P Unspecified fracture of shaft of unspecified fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82409Q Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82409R Unspecified fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82409S Unspecified fracture of shaft of unspecified fibula, sequela',
  'S82421A Displaced transverse fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82421B Displaced transverse fracture of shaft of right fibula, initial encounter for open fracture type I',
  'or II',
  'S82421C Displaced transverse fracture of shaft of right fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82421D Displaced transverse fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82421E Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82421F Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82421G Displaced transverse fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82421H Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82421J Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82421K Displaced transverse fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82421M Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82421N Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82421P Displaced transverse fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82421Q Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82421R Displaced transverse fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82421S Displaced transverse fracture of shaft of right fibula, sequela',
  'S82422A Displaced transverse fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82422B Displaced transverse fracture of shaft of left fibula, initial encounter for open fracture type I or',
  'II',
  'S82422C Displaced transverse fracture of shaft of left fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82422D Displaced transverse fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82422E Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82422F Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82422G Displaced transverse fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82422H Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82422J Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82422K Displaced transverse fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82422M Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82422N Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82422P Displaced transverse fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82422Q Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82422R Displaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82422S Displaced transverse fracture of shaft of left fibula, sequela',
  'S82423A Displaced transverse fracture of shaft of unspecified fibula, initial encounter for closed fracture',
  'S82423B Displaced transverse fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type I or II',
  'S82423C Displaced transverse fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82423D Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82423E Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82423F Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82423G Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82423H Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82423J Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82423K Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82423M Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82423N Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82423P Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82423Q Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82423R Displaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82423S Displaced transverse fracture of shaft of unspecified fibula, sequela',
  'S82424A Nondisplaced transverse fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82424B Nondisplaced transverse fracture of shaft of right fibula, initial encounter for open fracture',
  'type I or II',
  'S82424C Nondisplaced transverse fracture of shaft of right fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82424D Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82424E Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82424F Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82424G Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82424H Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82424J Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82424K Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82424M Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82424N Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82424P Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82424Q Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82424R Nondisplaced transverse fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82424S Nondisplaced transverse fracture of shaft of right fibula, sequela',
  'S82425A Nondisplaced transverse fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82425B Nondisplaced transverse fracture of shaft of left fibula, initial encounter for open fracture type',
  'I or II',
  'S82425C Nondisplaced transverse fracture of shaft of left fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82425D Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82425E Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82425F Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82425G Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82425H Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82425J Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82425K Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82425M Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82425N Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82425P Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82425Q Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82425R Nondisplaced transverse fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82425S Nondisplaced transverse fracture of shaft of left fibula, sequela',
  'S82426A Nondisplaced transverse fracture of shaft of unspecified fibula, initial encounter for closed',
  'fracture',
  'S82426B Nondisplaced transverse fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type I or II',
  'S82426C Nondisplaced transverse fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82426D Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with routine healing',
  'S82426E Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82426F Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82426G Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with delayed healing',
  'S82426H Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82426J Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82426K Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with nonunion',
  'S82426M Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S82426N Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82426P Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with malunion',
  'S82426Q Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S82426R Nondisplaced transverse fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82426S Nondisplaced transverse fracture of shaft of unspecified fibula, sequela',
  'S82431A Displaced oblique fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82431B Displaced oblique fracture of shaft of right fibula, initial encounter for open fracture type I or II',
  'S82431C Displaced oblique fracture of shaft of right fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82431D Displaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82431E Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82431F Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82431G Displaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82431H Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82431J Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82431K Displaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82431M Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82431N Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82431P Displaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82431Q Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82431R Displaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82431S Displaced oblique fracture of shaft of right fibula, sequela',
  'S82432A Displaced oblique fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82432B Displaced oblique fracture of shaft of left fibula, initial encounter for open fracture type I or II',
  'S82432C Displaced oblique fracture of shaft of left fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82432D Displaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'routine healing',
  'S82432E Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82432F Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82432G Displaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82432H Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82432J Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82432K Displaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'nonunion',
  'S82432M Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82432N Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82432P Displaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'malunion',
  'S82432Q Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82432R Displaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82432S Displaced oblique fracture of shaft of left fibula, sequela',
  'S82433A Displaced oblique fracture of shaft of unspecified fibula, initial encounter for closed fracture',
  'S82433B Displaced oblique fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type I or II',
  'S82433C Displaced oblique fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82433D Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82433E Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82433F Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82433G Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82433H Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82433J Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82433K Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82433M Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82433N Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82433P Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82433Q Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82433R Displaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82433S Displaced oblique fracture of shaft of unspecified fibula, sequela',
  'S82434A Nondisplaced oblique fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82434B Nondisplaced oblique fracture of shaft of right fibula, initial encounter for open fracture type I',
  'or II',
  'S82434C Nondisplaced oblique fracture of shaft of right fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82434D Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82434E Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82434F Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82434G Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82434H Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82434J Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82434K Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82434M Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82434N Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82434P Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82434Q Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82434R Nondisplaced oblique fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82434S Nondisplaced oblique fracture of shaft of right fibula, sequela',
  'S82435A Nondisplaced oblique fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82435B Nondisplaced oblique fracture of shaft of left fibula, initial encounter for open fracture type I or',
  'II',
  'S82435C Nondisplaced oblique fracture of shaft of left fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82435D Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82435E Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82435F Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82435G Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82435H Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82435J Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82435K Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82435M Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82435N Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82435P Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82435Q Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82435R Nondisplaced oblique fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82435S Nondisplaced oblique fracture of shaft of left fibula, sequela',
  'S82436A Nondisplaced oblique fracture of shaft of unspecified fibula, initial encounter for closed',
  'fracture',
  'S82436B Nondisplaced oblique fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type I or II',
  'S82436C Nondisplaced oblique fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82436D Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82436E Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82436F Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82436G Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82436H Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82436J Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82436K Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82436M Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82436N Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82436P Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82436Q Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82436R Nondisplaced oblique fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82436S Nondisplaced oblique fracture of shaft of unspecified fibula, sequela',
  'S82441A Displaced spiral fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82441B Displaced spiral fracture of shaft of right fibula, initial encounter for open fracture type I or II',
  'S82441C Displaced spiral fracture of shaft of right fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82441D Displaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'routine healing',
  'S82441E Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82441F Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82441G Displaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82441H Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82441J Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82441K Displaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'nonunion',
  'S82441M Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82441N Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82441P Displaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture with',
  'malunion',
  'S82441Q Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82441R Displaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82441S Displaced spiral fracture of shaft of right fibula, sequela',
  'S82442A Displaced spiral fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82442B Displaced spiral fracture of shaft of left fibula, initial encounter for open fracture type I or II',
  'S82442C Displaced spiral fracture of shaft of left fibula, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S82442D Displaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'routine healing',
  'S82442E Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82442F Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82442G Displaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82442H Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82442J Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82442K Displaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'nonunion',
  'S82442M Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82442N Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82442P Displaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture with',
  'malunion',
  'S82442Q Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82442R Displaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82442S Displaced spiral fracture of shaft of left fibula, sequela',
  'S82443A Displaced spiral fracture of shaft of unspecified fibula, initial encounter for closed fracture',
  'S82443B Displaced spiral fracture of shaft of unspecified fibula, initial encounter for open fracture type I',
  'or II',
  'S82443C Displaced spiral fracture of shaft of unspecified fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82443D Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82443E Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82443F Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82443G Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82443H Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82443J Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82443K Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82443M Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82443N Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82443P Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82443Q Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82443R Displaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82443S Displaced spiral fracture of shaft of unspecified fibula, sequela',
  'S82444A Nondisplaced spiral fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82444B Nondisplaced spiral fracture of shaft of right fibula, initial encounter for open fracture type I or',
  'II',
  'S82444C Nondisplaced spiral fracture of shaft of right fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82444D Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82444E Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82444F Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82444G Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82444H Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82444J Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82444K Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82444M Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82444N Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82444P Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82444Q Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82444R Nondisplaced spiral fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82444S Nondisplaced spiral fracture of shaft of right fibula, sequela',
  'S82445A Nondisplaced spiral fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82445B Nondisplaced spiral fracture of shaft of left fibula, initial encounter for open fracture type I or II',
  'S82445C Nondisplaced spiral fracture of shaft of left fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82445D Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82445E Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82445F Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82445G Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82445H Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82445J Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82445K Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82445M Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82445N Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82445P Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82445Q Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82445R Nondisplaced spiral fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82445S Nondisplaced spiral fracture of shaft of left fibula, sequela',
  'S82446A Nondisplaced spiral fracture of shaft of unspecified fibula, initial encounter for closed fracture',
  'S82446B Nondisplaced spiral fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type I or II',
  'S82446C Nondisplaced spiral fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82446D Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82446E Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82446F Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82446G Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82446H Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82446J Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82446K Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82446M Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82446N Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82446P Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82446Q Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82446R Nondisplaced spiral fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82446S Nondisplaced spiral fracture of shaft of unspecified fibula, sequela',
  'S82451A Displaced comminuted fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82451B Displaced comminuted fracture of shaft of right fibula, initial encounter for open fracture type I',
  'or II',
  'S82451C Displaced comminuted fracture of shaft of right fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82451D Displaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82451E Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82451F Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82451G Displaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82451H Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82451J Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82451K Displaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82451M Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82451N Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82451P Displaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82451Q Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82451R Displaced comminuted fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82451S Displaced comminuted fracture of shaft of right fibula, sequela',
  'S82452A Displaced comminuted fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82452B Displaced comminuted fracture of shaft of left fibula, initial encounter for open fracture type I',
  'or II',
  'S82452C Displaced comminuted fracture of shaft of left fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82452D Displaced comminuted fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82452E Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82452F Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82452G Displaced comminuted fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82452H Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82452J Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82452K Displaced comminuted fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82452M Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82452N Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82452P Displaced comminuted fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82452Q Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82452R Displaced comminuted fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82452S Displaced comminuted fracture of shaft of left fibula, sequela',
  'S82453A Displaced comminuted fracture of shaft of unspecified fibula, initial encounter for closed',
  'fracture',
  'S82453B Displaced comminuted fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type I or II',
  'S82453C Displaced comminuted fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82453D Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82453E Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82453F Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82453G Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82453H Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82453J Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82453K Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82453M Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82453N Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82453P Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82453Q Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82453R Displaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82453S Displaced comminuted fracture of shaft of unspecified fibula, sequela',
  'S82454A Nondisplaced comminuted fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82454B Nondisplaced comminuted fracture of shaft of right fibula, initial encounter for open fracture',
  'type I or II',
  'S82454C Nondisplaced comminuted fracture of shaft of right fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82454D Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82454E Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82454F Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82454G Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82454H Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82454J Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82454K Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82454M Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82454N Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82454P Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82454Q Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82454R Nondisplaced comminuted fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82454S Nondisplaced comminuted fracture of shaft of right fibula, sequela',
  'S82455A Nondisplaced comminuted fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82455B Nondisplaced comminuted fracture of shaft of left fibula, initial encounter for open fracture',
  'type I or II',
  'S82455C Nondisplaced comminuted fracture of shaft of left fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82455D Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82455E Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82455F Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82455G Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82455H Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82455J Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82455K Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82455M Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82455N Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82455P Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82455Q Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82455R Nondisplaced comminuted fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82455S Nondisplaced comminuted fracture of shaft of left fibula, sequela',
  'S82456A Nondisplaced comminuted fracture of shaft of unspecified fibula, initial encounter for closed',
  'fracture',
  'S82456B Nondisplaced comminuted fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type I or II',
  'S82456C Nondisplaced comminuted fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82456D Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with routine healing',
  'S82456E Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S82456F Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82456G Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with delayed healing',
  'S82456H Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S82456J Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82456K Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with nonunion',
  'S82456M Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S82456N Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82456P Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with malunion',
  'S82456Q Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S82456R Nondisplaced comminuted fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S82456S Nondisplaced comminuted fracture of shaft of unspecified fibula, sequela',
  'S82461A Displaced segmental fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82461B Displaced segmental fracture of shaft of right fibula, initial encounter for open fracture type I',
  'or II',
  'S82461C Displaced segmental fracture of shaft of right fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82461D Displaced segmental fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82461E Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82461F Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82461G Displaced segmental fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82461H Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82461J Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82461K Displaced segmental fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82461M Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82461N Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82461P Displaced segmental fracture of shaft of right fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82461Q Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82461R Displaced segmental fracture of shaft of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82461S Displaced segmental fracture of shaft of right fibula, sequela',
  'S82462A Displaced segmental fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82462B Displaced segmental fracture of shaft of left fibula, initial encounter for open fracture type I or',
  'II',
  'S82462C Displaced segmental fracture of shaft of left fibula, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82462D Displaced segmental fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82462E Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82462F Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82462G Displaced segmental fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82462H Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82462J Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82462K Displaced segmental fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82462M Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82462N Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82462P Displaced segmental fracture of shaft of left fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82462Q Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82462R Displaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82462S Displaced segmental fracture of shaft of left fibula, sequela',
  'S82463A Displaced segmental fracture of shaft of unspecified fibula, initial encounter for closed fracture',
  'S82463B Displaced segmental fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type I or II',
  'S82463C Displaced segmental fracture of shaft of unspecified fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82463D Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82463E Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82463F Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82463G Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82463H Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82463J Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82463K Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82463M Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82463N Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82463P Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82463Q Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82463R Displaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82463S Displaced segmental fracture of shaft of unspecified fibula, sequela',
  'S82464A Nondisplaced segmental fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82464B Nondisplaced segmental fracture of shaft of right fibula, initial encounter for open fracture',
  'type I or II',
  'S82464C Nondisplaced segmental fracture of shaft of right fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82464D Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82464E Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82464F Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82464G Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82464H Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82464J Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82464K Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82464M Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82464N Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82464P Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82464Q Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82464R Nondisplaced segmental fracture of shaft of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82464S Nondisplaced segmental fracture of shaft of right fibula, sequela',
  'S82465A Nondisplaced segmental fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82465B Nondisplaced segmental fracture of shaft of left fibula, initial encounter for open fracture type',
  'I or II',
  'S82465C Nondisplaced segmental fracture of shaft of left fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82465D Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82465E Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82465F Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82465G Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82465H Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82465J Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82465K Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82465M Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82465N Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82465P Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82465Q Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82465R Nondisplaced segmental fracture of shaft of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82465S Nondisplaced segmental fracture of shaft of left fibula, sequela',
  'S82466A Nondisplaced segmental fracture of shaft of unspecified fibula, initial encounter for closed',
  'fracture',
  'S82466B Nondisplaced segmental fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type I or II',
  'S82466C Nondisplaced segmental fracture of shaft of unspecified fibula, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S82466D Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with routine healing',
  'S82466E Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82466F Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82466G Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with delayed healing',
  'S82466H Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82466J Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82466K Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with nonunion',
  'S82466M Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S82466N Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82466P Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for',
  'closed fracture with malunion',
  'S82466Q Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82466R Nondisplaced segmental fracture of shaft of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82466S Nondisplaced segmental fracture of shaft of unspecified fibula, sequela',
  'S82491A Other fracture of shaft of right fibula, initial encounter for closed fracture',
  'S82491B Other fracture of shaft of right fibula, initial encounter for open fracture type I or II',
  'S82491C Other fracture of shaft of right fibula, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82491D Other fracture of shaft of right fibula, subsequent encounter for closed fracture with routine',
  'healing',
  'S82491E Other fracture of shaft of right fibula, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82491F Other fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82491G Other fracture of shaft of right fibula, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82491H Other fracture of shaft of right fibula, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82491J Other fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82491K Other fracture of shaft of right fibula, subsequent encounter for closed fracture with nonunion',
  'S82491M Other fracture of shaft of right fibula, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82491N Other fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82491P Other fracture of shaft of right fibula, subsequent encounter for closed fracture with malunion',
  'S82491Q Other fracture of shaft of right fibula, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82491R Other fracture of shaft of right fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82491S Other fracture of shaft of right fibula, sequela',
  'S82492A Other fracture of shaft of left fibula, initial encounter for closed fracture',
  'S82492B Other fracture of shaft of left fibula, initial encounter for open fracture type I or II',
  'S82492C Other fracture of shaft of left fibula, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82492D Other fracture of shaft of left fibula, subsequent encounter for closed fracture with routine',
  'healing',
  'S82492E Other fracture of shaft of left fibula, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82492F Other fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82492G Other fracture of shaft of left fibula, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82492H Other fracture of shaft of left fibula, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82492J Other fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82492K Other fracture of shaft of left fibula, subsequent encounter for closed fracture with nonunion',
  'S82492M Other fracture of shaft of left fibula, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82492N Other fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82492P Other fracture of shaft of left fibula, subsequent encounter for closed fracture with malunion',
  'S82492Q Other fracture of shaft of left fibula, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82492R Other fracture of shaft of left fibula, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82492S Other fracture of shaft of left fibula, sequela',
  'S82499A Other fracture of shaft of unspecified fibula, initial encounter for closed fracture',
  'S82499B Other fracture of shaft of unspecified fibula, initial encounter for open fracture type I or II',
  'S82499C Other fracture of shaft of unspecified fibula, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82499D Other fracture of shaft of unspecified fibula, subsequent encounter for closed fracture with',
  'routine healing',
  'S82499E Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82499F Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82499G Other fracture of shaft of unspecified fibula, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82499H Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82499J Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82499K Other fracture of shaft of unspecified fibula, subsequent encounter for closed fracture with',
  'nonunion',
  'S82499M Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S82499N Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82499P Other fracture of shaft of unspecified fibula, subsequent encounter for closed fracture with',
  'malunion',
  'S82499Q Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82499R Other fracture of shaft of unspecified fibula, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82499S Other fracture of shaft of unspecified fibula, sequela',
  'S8251XA Displaced fracture of medial malleolus of right tibia, initial encounter for closed fracture',
  'S8251XB Displaced fracture of medial malleolus of right tibia, initial encounter for open fracture type I',
  'or II',
  'S8251XC Displaced fracture of medial malleolus of right tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S8251XD Displaced fracture of medial malleolus of right tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S8251XE Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S8251XF Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S8251XG Displaced fracture of medial malleolus of right tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S8251XH Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S8251XJ Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S8251XK Displaced fracture of medial malleolus of right tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S8251XM Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S8251XN Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S8251XP Displaced fracture of medial malleolus of right tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S8251XQ Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S8251XR Displaced fracture of medial malleolus of right tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S8251XS Displaced fracture of medial malleolus of right tibia, sequela',
  'S8252XA Displaced fracture of medial malleolus of left tibia, initial encounter for closed fracture',
  'S8252XB Displaced fracture of medial malleolus of left tibia, initial encounter for open fracture type I or',
  'II',
  'S8252XC Displaced fracture of medial malleolus of left tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S8252XD Displaced fracture of medial malleolus of left tibia, subsequent encounter for closed fracture',
  'with routine healing',
  'S8252XE Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S8252XF Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S8252XG Displaced fracture of medial malleolus of left tibia, subsequent encounter for closed fracture',
  'with delayed healing',
  'S8252XH Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S8252XJ Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S8252XK Displaced fracture of medial malleolus of left tibia, subsequent encounter for closed fracture',
  'with nonunion',
  'S8252XM Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S8252XN Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S8252XP Displaced fracture of medial malleolus of left tibia, subsequent encounter for closed fracture',
  'with malunion',
  'S8252XQ Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S8252XR Displaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S8252XS Displaced fracture of medial malleolus of left tibia, sequela',
  'S8253XA Displaced fracture of medial malleolus of unspecified tibia, initial encounter for closed fracture',
  'S8253XB Displaced fracture of medial malleolus of unspecified tibia, initial encounter for open fracture',
  'type I or II',
  'S8253XC Displaced fracture of medial malleolus of unspecified tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S8253XD Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S8253XE Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S8253XF Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S8253XG Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S8253XH Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S8253XJ Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S8253XK Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S8253XM Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S8253XN Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8253XP Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S8253XQ Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S8253XR Displaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S8253XS Displaced fracture of medial malleolus of unspecified tibia, sequela',
  'S8254XA Nondisplaced fracture of medial malleolus of right tibia, initial encounter for closed fracture',
  'S8254XB Nondisplaced fracture of medial malleolus of right tibia, initial encounter for open fracture',
  'type I or II',
  'S8254XC Nondisplaced fracture of medial malleolus of right tibia, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S8254XD Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S8254XE Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S8254XF Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S8254XG Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S8254XH Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S8254XJ Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S8254XK Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S8254XM Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S8254XN Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8254XP Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S8254XQ Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S8254XR Nondisplaced fracture of medial malleolus of right tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S8254XS Nondisplaced fracture of medial malleolus of right tibia, sequela',
  'S8255XA Nondisplaced fracture of medial malleolus of left tibia, initial encounter for closed fracture',
  'S8255XB Nondisplaced fracture of medial malleolus of left tibia, initial encounter for open fracture type I',
  'or II',
  'S8255XC Nondisplaced fracture of medial malleolus of left tibia, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S8255XD Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for closed',
  'fracture with routine healing',
  'S8255XE Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S8255XF Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S8255XG Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for closed',
  'fracture with delayed healing',
  'S8255XH Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S8255XJ Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S8255XK Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for closed',
  'fracture with nonunion',
  'S8255XM Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S8255XN Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8255XP Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for closed',
  'fracture with malunion',
  'S8255XQ Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S8255XR Nondisplaced fracture of medial malleolus of left tibia, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S8255XS Nondisplaced fracture of medial malleolus of left tibia, sequela',
  'S8256XA Nondisplaced fracture of medial malleolus of unspecified tibia, initial encounter for closed',
  'fracture',
  'S8256XB Nondisplaced fracture of medial malleolus of unspecified tibia, initial encounter for open',
  'fracture type I or II',
  'S8256XC Nondisplaced fracture of medial malleolus of unspecified tibia, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S8256XD Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for',
  'closed fracture with routine healing',
  'S8256XE Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S8256XF Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S8256XG Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for',
  'closed fracture with delayed healing',
  'S8256XH Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S8256XJ Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S8256XK Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for',
  'closed fracture with nonunion',
  'S8256XM Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S8256XN Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8256XP Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for',
  'closed fracture with malunion',
  'S8256XQ Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S8256XR Nondisplaced fracture of medial malleolus of unspecified tibia, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S8256XS Nondisplaced fracture of medial malleolus of unspecified tibia, sequela',
  'S8261XA Displaced fracture of lateral malleolus of right fibula, initial encounter for closed fracture',
  'S8261XB Displaced fracture of lateral malleolus of right fibula, initial encounter for open fracture type I',
  'or II',
  'S8261XC Displaced fracture of lateral malleolus of right fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S8261XD Displaced fracture of lateral malleolus of right fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S8261XE Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S8261XF Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S8261XG Displaced fracture of lateral malleolus of right fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S8261XH Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S8261XJ Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S8261XK Displaced fracture of lateral malleolus of right fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S8261XM Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S8261XN Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S8261XP Displaced fracture of lateral malleolus of right fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S8261XQ Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S8261XR Displaced fracture of lateral malleolus of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S8261XS Displaced fracture of lateral malleolus of right fibula, sequela',
  'S8262XA Displaced fracture of lateral malleolus of left fibula, initial encounter for closed fracture',
  'S8262XB Displaced fracture of lateral malleolus of left fibula, initial encounter for open fracture type I or',
  'II',
  'S8262XC Displaced fracture of lateral malleolus of left fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S8262XD Displaced fracture of lateral malleolus of left fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S8262XE Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S8262XF Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S8262XG Displaced fracture of lateral malleolus of left fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S8262XH Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S8262XJ Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S8262XK Displaced fracture of lateral malleolus of left fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S8262XM Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S8262XN Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S8262XP Displaced fracture of lateral malleolus of left fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S8262XQ Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S8262XR Displaced fracture of lateral malleolus of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S8262XS Displaced fracture of lateral malleolus of left fibula, sequela',
  'S8263XA Displaced fracture of lateral malleolus of unspecified fibula, initial encounter for closed',
  'fracture',
  'S8263XB Displaced fracture of lateral malleolus of unspecified fibula, initial encounter for open fracture',
  'type I or II',
  'S8263XC Displaced fracture of lateral malleolus of unspecified fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S8263XD Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S8263XE Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S8263XF Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S8263XG Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S8263XH Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S8263XJ Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S8263XK Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S8263XM Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S8263XN Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8263XP Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S8263XQ Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S8263XR Displaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S8263XS Displaced fracture of lateral malleolus of unspecified fibula, sequela',
  'S8264XA Nondisplaced fracture of lateral malleolus of right fibula, initial encounter for closed fracture',
  'S8264XB Nondisplaced fracture of lateral malleolus of right fibula, initial encounter for open fracture',
  'type I or II',
  'S8264XC Nondisplaced fracture of lateral malleolus of right fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S8264XD Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S8264XE Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S8264XF Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S8264XG Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S8264XH Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S8264XJ Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S8264XK Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S8264XM Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S8264XN Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8264XP Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S8264XQ Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S8264XR Nondisplaced fracture of lateral malleolus of right fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S8264XS Nondisplaced fracture of lateral malleolus of right fibula, sequela',
  'S8265XA Nondisplaced fracture of lateral malleolus of left fibula, initial encounter for closed fracture',
  'S8265XB Nondisplaced fracture of lateral malleolus of left fibula, initial encounter for open fracture type',
  'I or II',
  'S8265XC Nondisplaced fracture of lateral malleolus of left fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S8265XD Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S8265XE Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S8265XF Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S8265XG Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S8265XH Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S8265XJ Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S8265XK Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S8265XM Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S8265XN Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8265XP Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S8265XQ Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S8265XR Nondisplaced fracture of lateral malleolus of left fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S8265XS Nondisplaced fracture of lateral malleolus of left fibula, sequela',
  'S8266XA Nondisplaced fracture of lateral malleolus of unspecified fibula, initial encounter for closed',
  'fracture',
  'S8266XB Nondisplaced fracture of lateral malleolus of unspecified fibula, initial encounter for open',
  'fracture type I or II',
  'S8266XC Nondisplaced fracture of lateral malleolus of unspecified fibula, initial encounter for open',
  'fracture type IIIA, IIIB, or IIIC',
  'S8266XD Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'closed fracture with routine healing',
  'S8266XE Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with routine healing',
  'S8266XF Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with routine healing',
  'S8266XG Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'closed fracture with delayed healing',
  'S8266XH Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with delayed healing',
  'S8266XJ Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S8266XK Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'closed fracture with nonunion',
  'S8266XM Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with nonunion',
  'S8266XN Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with nonunion',
  'S8266XP Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'closed fracture with malunion',
  'S8266XQ Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'open fracture type I or II with malunion',
  'S8266XR Nondisplaced fracture of lateral malleolus of unspecified fibula, subsequent encounter for',
  'open fracture type IIIA, IIIB, or IIIC with malunion',
  'S8266XS Nondisplaced fracture of lateral malleolus of unspecified fibula, sequela',
  'S82811A Torus fracture of upper end of right fibula, initial encounter for closed fracture',
  'S82811D Torus fracture of upper end of right fibula, subsequent encounter for fracture with routine',
  'healing',
  'S82811G Torus fracture of upper end of right fibula, subsequent encounter for fracture with delayed',
  'healing',
  'S82811K Torus fracture of upper end of right fibula, subsequent encounter for fracture with nonunion',
  'S82811P Torus fracture of upper end of right fibula, subsequent encounter for fracture with malunion',
  'S82811S Torus fracture of upper end of right fibula, sequela',
  'S82812A Torus fracture of upper end of left fibula, initial encounter for closed fracture',
  'S82812D Torus fracture of upper end of left fibula, subsequent encounter for fracture with routine',
  'healing',
  'S82812G Torus fracture of upper end of left fibula, subsequent encounter for fracture with delayed',
  'healing',
  'S82812K Torus fracture of upper end of left fibula, subsequent encounter for fracture with nonunion',
  'S82812P Torus fracture of upper end of left fibula, subsequent encounter for fracture with malunion',
  'S82812S Torus fracture of upper end of left fibula, sequela',
  'S82819A Torus fracture of upper end of unspecified fibula, initial encounter for closed fracture',
  'S82819D Torus fracture of upper end of unspecified fibula, subsequent encounter for fracture with',
  'routine healing',
  'S82819G Torus fracture of upper end of unspecified fibula, subsequent encounter for fracture with',
  'delayed healing',
  'S82819K Torus fracture of upper end of unspecified fibula, subsequent encounter for fracture with',
  'nonunion',
  'S82819P Torus fracture of upper end of unspecified fibula, subsequent encounter for fracture with',
  'malunion',
  'S82819S Torus fracture of upper end of unspecified fibula, sequela',
  'S82821A Torus fracture of lower end of right fibula, initial encounter for closed fracture',
  'S82821D Torus fracture of lower end of right fibula, subsequent encounter for fracture with routine',
  'healing',
  'S82821G Torus fracture of lower end of right fibula, subsequent encounter for fracture with delayed',
  'healing',
  'S82821K Torus fracture of lower end of right fibula, subsequent encounter for fracture with nonunion',
  'S82821P Torus fracture of lower end of right fibula, subsequent encounter for fracture with malunion',
  'S82821S Torus fracture of lower end of right fibula, sequela',
  'S82822A Torus fracture of lower end of left fibula, initial encounter for closed fracture',
  'S82822D Torus fracture of lower end of left fibula, subsequent encounter for fracture with routine',
  'healing',
  'S82822G Torus fracture of lower end of left fibula, subsequent encounter for fracture with delayed',
  'healing',
  'S82822K Torus fracture of lower end of left fibula, subsequent encounter for fracture with nonunion',
  'S82822P Torus fracture of lower end of left fibula, subsequent encounter for fracture with malunion',
  'S82822S Torus fracture of lower end of left fibula, sequela',
  'S82829A Torus fracture of lower end of unspecified fibula, initial encounter for closed fracture',
  'S82829D Torus fracture of lower end of unspecified fibula, subsequent encounter for fracture with',
  'routine healing',
  'S82829G Torus fracture of lower end of unspecified fibula, subsequent encounter for fracture with',
  'delayed healing',
  'S82829K Torus fracture of lower end of unspecified fibula, subsequent encounter for fracture with',
  'nonunion',
  'S82829P Torus fracture of lower end of unspecified fibula, subsequent encounter for fracture with',
  'malunion',
  'S82829S Torus fracture of lower end of unspecified fibula, sequela',
  'S82831A Other fracture of upper and lower end of right fibula, initial encounter for closed fracture',
  'S82831B Other fracture of upper and lower end of right fibula, initial encounter for open fracture type I',
  'or II',
  'S82831C Other fracture of upper and lower end of right fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82831D Other fracture of upper and lower end of right fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82831E Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82831F Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82831G Other fracture of upper and lower end of right fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82831H Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82831J Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82831K Other fracture of upper and lower end of right fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82831M Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82831N Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82831P Other fracture of upper and lower end of right fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82831Q Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82831R Other fracture of upper and lower end of right fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82831S Other fracture of upper and lower end of right fibula, sequela',
  'S82832A Other fracture of upper and lower end of left fibula, initial encounter for closed fracture',
  'S82832B Other fracture of upper and lower end of left fibula, initial encounter for open fracture type I',
  'or II',
  'S82832C Other fracture of upper and lower end of left fibula, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82832D Other fracture of upper and lower end of left fibula, subsequent encounter for closed fracture',
  'with routine healing',
  'S82832E Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82832F Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82832G Other fracture of upper and lower end of left fibula, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82832H Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82832J Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82832K Other fracture of upper and lower end of left fibula, subsequent encounter for closed fracture',
  'with nonunion',
  'S82832M Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82832N Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82832P Other fracture of upper and lower end of left fibula, subsequent encounter for closed fracture',
  'with malunion',
  'S82832Q Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82832R Other fracture of upper and lower end of left fibula, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82832S Other fracture of upper and lower end of left fibula, sequela',
  'S82839A Other fracture of upper and lower end of unspecified fibula, initial encounter for closed',
  'fracture',
  'S82839B Other fracture of upper and lower end of unspecified fibula, initial encounter for open fracture',
  'type I or II',
  'S82839C Other fracture of upper and lower end of unspecified fibula, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82839D Other fracture of upper and lower end of unspecified fibula, subsequent encounter for closed',
  'fracture with routine healing',
  'S82839E Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82839F Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82839G Other fracture of upper and lower end of unspecified fibula, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82839H Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82839J Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82839K Other fracture of upper and lower end of unspecified fibula, subsequent encounter for closed',
  'fracture with nonunion',
  'S82839M Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82839N Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82839P Other fracture of upper and lower end of unspecified fibula, subsequent encounter for closed',
  'fracture with malunion',
  'S82839Q Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82839R Other fracture of upper and lower end of unspecified fibula, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82839S Other fracture of upper and lower end of unspecified fibula, sequela',
  'S82841A Displaced bimalleolar fracture of right lower leg, initial encounter for closed fracture',
  'S82841B Displaced bimalleolar fracture of right lower leg, initial encounter for open fracture type I or II',
  'S82841C Displaced bimalleolar fracture of right lower leg, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82841D Displaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with routine healing',
  'S82841E Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82841F Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82841G Displaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82841H Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82841J Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82841K Displaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture with',
  'nonunion',
  'S82841M Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82841N Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82841P Displaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture with',
  'malunion',
  'S82841Q Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82841R Displaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82841S Displaced bimalleolar fracture of right lower leg, sequela',
  'S82842A Displaced bimalleolar fracture of left lower leg, initial encounter for closed fracture',
  'S82842B Displaced bimalleolar fracture of left lower leg, initial encounter for open fracture type I or II',
  'S82842C Displaced bimalleolar fracture of left lower leg, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82842D Displaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'routine healing',
  'S82842E Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82842F Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82842G Displaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82842H Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82842J Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82842K Displaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'nonunion',
  'S82842M Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82842N Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82842P Displaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'malunion',
  'S82842Q Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82842R Displaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82842S Displaced bimalleolar fracture of left lower leg, sequela',
  'S82843A Displaced bimalleolar fracture of unspecified lower leg, initial encounter for closed fracture',
  'S82843B Displaced bimalleolar fracture of unspecified lower leg, initial encounter for open fracture type',
  'I or II',
  'S82843C Displaced bimalleolar fracture of unspecified lower leg, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82843D Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with routine healing',
  'S82843E Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82843F Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82843G Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82843H Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82843J Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82843K Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with nonunion',
  'S82843M Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82843N Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82843P Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with malunion',
  'S82843Q Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82843R Displaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82843S Displaced bimalleolar fracture of unspecified lower leg, sequela',
  'S82844A Nondisplaced bimalleolar fracture of right lower leg, initial encounter for closed fracture',
  'S82844B Nondisplaced bimalleolar fracture of right lower leg, initial encounter for open fracture type I',
  'or II',
  'S82844C Nondisplaced bimalleolar fracture of right lower leg, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82844D Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with routine healing',
  'S82844E Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82844F Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82844G Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82844H Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82844J Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82844K Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with nonunion',
  'S82844M Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82844N Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82844P Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with malunion',
  'S82844Q Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82844R Nondisplaced bimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82844S Nondisplaced bimalleolar fracture of right lower leg, sequela',
  'S82845A Nondisplaced bimalleolar fracture of left lower leg, initial encounter for closed fracture',
  'S82845B Nondisplaced bimalleolar fracture of left lower leg, initial encounter for open fracture type I or',
  'II',
  'S82845C Nondisplaced bimalleolar fracture of left lower leg, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82845D Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with routine healing',
  'S82845E Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82845F Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82845G Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82845H Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82845J Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82845K Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with nonunion',
  'S82845M Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82845N Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82845P Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with malunion',
  'S82845Q Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82845R Nondisplaced bimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82845S Nondisplaced bimalleolar fracture of left lower leg, sequela',
  'S82846A Nondisplaced bimalleolar fracture of unspecified lower leg, initial encounter for closed fracture',
  'S82846B Nondisplaced bimalleolar fracture of unspecified lower leg, initial encounter for open fracture',
  'type I or II',
  'S82846C Nondisplaced bimalleolar fracture of unspecified lower leg, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82846D Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with routine healing',
  'S82846E Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82846F Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82846G Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82846H Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82846J Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82846K Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with nonunion',
  'S82846M Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82846N Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82846P Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with malunion',
  'S82846Q Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82846R Nondisplaced bimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82846S Nondisplaced bimalleolar fracture of unspecified lower leg, sequela',
  'S82851A Displaced trimalleolar fracture of right lower leg, initial encounter for closed fracture',
  'S82851B Displaced trimalleolar fracture of right lower leg, initial encounter for open fracture type I or II',
  'S82851C Displaced trimalleolar fracture of right lower leg, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82851D Displaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with routine healing',
  'S82851E Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'I or II with routine healing',
  'S82851F Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82851G Displaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82851H Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'I or II with delayed healing',
  'S82851J Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82851K Displaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with nonunion',
  'S82851M Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82851N Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82851P Displaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with malunion',
  'S82851Q Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'I or II with malunion',
  'S82851R Displaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82851S Displaced trimalleolar fracture of right lower leg, sequela',
  'S82852A Displaced trimalleolar fracture of left lower leg, initial encounter for closed fracture',
  'S82852B Displaced trimalleolar fracture of left lower leg, initial encounter for open fracture type I or II',
  'S82852C Displaced trimalleolar fracture of left lower leg, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82852D Displaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'routine healing',
  'S82852E Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82852F Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82852G Displaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82852H Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82852J Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82852K Displaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'nonunion',
  'S82852M Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S82852N Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82852P Displaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture with',
  'malunion',
  'S82852Q Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82852R Displaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82852S Displaced trimalleolar fracture of left lower leg, sequela',
  'S82853A Displaced trimalleolar fracture of unspecified lower leg, initial encounter for closed fracture',
  'S82853B Displaced trimalleolar fracture of unspecified lower leg, initial encounter for open fracture type',
  'I or II',
  'S82853C Displaced trimalleolar fracture of unspecified lower leg, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82853D Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with routine healing',
  'S82853E Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82853F Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82853G Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82853H Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82853J Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82853K Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with nonunion',
  'S82853M Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82853N Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82853P Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with malunion',
  'S82853Q Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82853R Displaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82853S Displaced trimalleolar fracture of unspecified lower leg, sequela',
  'S82854A Nondisplaced trimalleolar fracture of right lower leg, initial encounter for closed fracture',
  'S82854B Nondisplaced trimalleolar fracture of right lower leg, initial encounter for open fracture type I',
  'or II',
  'S82854C Nondisplaced trimalleolar fracture of right lower leg, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82854D Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with routine healing',
  'S82854E Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82854F Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82854G Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82854H Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82854J Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82854K Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with nonunion',
  'S82854M Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82854N Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82854P Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for closed fracture',
  'with malunion',
  'S82854Q Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82854R Nondisplaced trimalleolar fracture of right lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82854S Nondisplaced trimalleolar fracture of right lower leg, sequela',
  'S82855A Nondisplaced trimalleolar fracture of left lower leg, initial encounter for closed fracture',
  'S82855B Nondisplaced trimalleolar fracture of left lower leg, initial encounter for open fracture type I or',
  'II',
  'S82855C Nondisplaced trimalleolar fracture of left lower leg, initial encounter for open fracture type',
  'IIIA, IIIB, or IIIC',
  'S82855D Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with routine healing',
  'S82855E Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with routine healing',
  'S82855F Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with routine healing',
  'S82855G Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with delayed healing',
  'S82855H Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with delayed healing',
  'S82855J Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with delayed healing',
  'S82855K Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with nonunion',
  'S82855M Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with nonunion',
  'S82855N Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with nonunion',
  'S82855P Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for closed fracture',
  'with malunion',
  'S82855Q Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type I or II with malunion',
  'S82855R Nondisplaced trimalleolar fracture of left lower leg, subsequent encounter for open fracture',
  'type IIIA, IIIB, or IIIC with malunion',
  'S82855S Nondisplaced trimalleolar fracture of left lower leg, sequela',
  'S82856A Nondisplaced trimalleolar fracture of unspecified lower leg, initial encounter for closed',
  'fracture',
  'S82856B Nondisplaced trimalleolar fracture of unspecified lower leg, initial encounter for open fracture',
  'type I or II',
  'S82856C Nondisplaced trimalleolar fracture of unspecified lower leg, initial encounter for open fracture',
  'type IIIA, IIIB, or IIIC',
  'S82856D Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with routine healing',
  'S82856E Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with routine healing',
  'S82856F Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  'S82856G Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with delayed healing',
  'S82856H Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with delayed healing',
  'S82856J Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  'S82856K Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with nonunion',
  'S82856M Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with nonunion',
  'S82856N Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  'S82856P Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for closed',
  'fracture with malunion',
  'S82856Q Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type I or II with malunion',
  'S82856R Nondisplaced trimalleolar fracture of unspecified lower leg, subsequent encounter for open',
  'fracture type IIIA, IIIB, or IIIC with malunion',
  'S82856S Nondisplaced trimalleolar fracture of unspecified lower leg, sequela',
  "S82861A Displaced Maisonneuve's fracture of right leg, initial encounter for closed fracture",
  "S82861B Displaced Maisonneuve's fracture of right leg, initial encounter for open fracture type I or II",
  "S82861C Displaced Maisonneuve's fracture of right leg, initial encounter for open fracture type IIIA, IIIB,",
  'or IIIC',
  "S82861D Displaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture with",
  'routine healing',
  "S82861E Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type I or",
  'II with routine healing',
  "S82861F Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with routine healing',
  "S82861G Displaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture with",
  'delayed healing',
  "S82861H Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type I",
  'or II with delayed healing',
  "S82861J Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with delayed healing',
  "S82861K Displaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture with",
  'nonunion',
  "S82861M Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type I",
  'or II with nonunion',
  "S82861N Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with nonunion',
  "S82861P Displaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture with",
  'malunion',
  "S82861Q Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type I",
  'or II with malunion',
  "S82861R Displaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with malunion',
  "S82861S Displaced Maisonneuve's fracture of right leg, sequela",
  "S82862A Displaced Maisonneuve's fracture of left leg, initial encounter for closed fracture",
  "S82862B Displaced Maisonneuve's fracture of left leg, initial encounter for open fracture type I or II",
  "S82862C Displaced Maisonneuve's fracture of left leg, initial encounter for open fracture type IIIA, IIIB,",
  'or IIIC',
  "S82862D Displaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture with",
  'routine healing',
  "S82862E Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type I or",
  'II with routine healing',
  "S82862F Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type IIIA,",
  'IIIB, or IIIC with routine healing',
  "S82862G Displaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture with",
  'delayed healing',
  "S82862H Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type I or",
  'II with delayed healing',
  "S82862J Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type IIIA,",
  'IIIB, or IIIC with delayed healing',
  "S82862K Displaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture with",
  'nonunion',
  "S82862M Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type I or",
  'II with nonunion',
  "S82862N Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type IIIA,",
  'IIIB, or IIIC with nonunion',
  "S82862P Displaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture with",
  'malunion',
  "S82862Q Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type I or",
  'II with malunion',
  "S82862R Displaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type IIIA,",
  'IIIB, or IIIC with malunion',
  "S82862S Displaced Maisonneuve's fracture of left leg, sequela",
  "S82863A Displaced Maisonneuve's fracture of unspecified leg, initial encounter for closed fracture",
  "S82863B Displaced Maisonneuve's fracture of unspecified leg, initial encounter for open fracture type I",
  'or II',
  "S82863C Displaced Maisonneuve's fracture of unspecified leg, initial encounter for open fracture type",
  'IIIA, IIIB, or IIIC',
  "S82863D Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed fracture",
  'with routine healing',
  "S82863E Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type I or II with routine healing',
  "S82863F Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type IIIA, IIIB, or IIIC with routine healing',
  "S82863G Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed fracture",
  'with delayed healing',
  "S82863H Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type I or II with delayed healing',
  "S82863J Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type IIIA, IIIB, or IIIC with delayed healing',
  "S82863K Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed fracture",
  'with nonunion',
  "S82863M Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type I or II with nonunion',
  "S82863N Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type IIIA, IIIB, or IIIC with nonunion',
  "S82863P Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed fracture",
  'with malunion',
  "S82863Q Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type I or II with malunion',
  "S82863R Displaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open fracture",
  'type IIIA, IIIB, or IIIC with malunion',
  "S82863S Displaced Maisonneuve's fracture of unspecified leg, sequela",
  "S82864A Nondisplaced Maisonneuve's fracture of right leg, initial encounter for closed fracture",
  "S82864B Nondisplaced Maisonneuve's fracture of right leg, initial encounter for open fracture type I or II",
  "S82864C Nondisplaced Maisonneuve's fracture of right leg, initial encounter for open fracture type IIIA,",
  'IIIB, or IIIC',
  "S82864D Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture",
  'with routine healing',
  "S82864E Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture",
  'type I or II with routine healing',
  "S82864F Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with routine healing',
  "S82864G Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture",
  'with delayed healing',
  "S82864H Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture",
  'type I or II with delayed healing',
  "S82864J Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with delayed healing',
  "S82864K Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture",
  'with nonunion',
  "S82864M Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture",
  'type I or II with nonunion',
  "S82864N Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture",
  'type IIIA, IIIB, or IIIC with nonunion',
  "S82864P Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for closed fracture",
  'with malunion',
  "S82864Q Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture",
  'type I or II with malunion',
  "S82864R Nondisplaced Maisonneuve's fracture of right leg, subsequent encounter for open fracture",
  'type IIIA, IIIB, or IIIC with malunion',
  "S82864S Nondisplaced Maisonneuve's fracture of right leg, sequela",
  "S82865A Nondisplaced Maisonneuve's fracture of left leg, initial encounter for closed fracture",
  "S82865B Nondisplaced Maisonneuve's fracture of left leg, initial encounter for open fracture type I or II",
  "S82865C Nondisplaced Maisonneuve's fracture of left leg, initial encounter for open fracture type IIIA,",
  'IIIB, or IIIC',
  "S82865D Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture",
  'with routine healing',
  "S82865E Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type I",
  'or II with routine healing',
  "S82865F Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with routine healing',
  "S82865G Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture",
  'with delayed healing',
  "S82865H Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type",
  'I or II with delayed healing',
  "S82865J Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with delayed healing',
  "S82865K Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture",
  'with nonunion',
  "S82865M Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type",
  'I or II with nonunion',
  "S82865N Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with nonunion',
  "S82865P Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for closed fracture",
  'with malunion',
  "S82865Q Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type",
  'I or II with malunion',
  "S82865R Nondisplaced Maisonneuve's fracture of left leg, subsequent encounter for open fracture type",
  'IIIA, IIIB, or IIIC with malunion',
  "S82865S Nondisplaced Maisonneuve's fracture of left leg, sequela",
  "S82866A Nondisplaced Maisonneuve's fracture of unspecified leg, initial encounter for closed fracture",
  "S82866B Nondisplaced Maisonneuve's fracture of unspecified leg, initial encounter for open fracture",
  'type I or II',
  "S82866C Nondisplaced Maisonneuve's fracture of unspecified leg, initial encounter for open fracture",
  'type IIIA, IIIB, or IIIC',
  "S82866D Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed",
  'fracture with routine healing',
  "S82866E Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type I or II with routine healing',
  "S82866F Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type IIIA, IIIB, or IIIC with routine healing',
  "S82866G Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed",
  'fracture with delayed healing',
  "S82866H Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type I or II with delayed healing',
  "S82866J Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type IIIA, IIIB, or IIIC with delayed healing',
  "S82866K Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed",
  'fracture with nonunion',
  "S82866M Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type I or II with nonunion',
  "S82866N Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type IIIA, IIIB, or IIIC with nonunion',
  "S82866P Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for closed",
  'fracture with malunion',
  "S82866Q Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type I or II with malunion',
  "S82866R Nondisplaced Maisonneuve's fracture of unspecified leg, subsequent encounter for open",
  'fracture type IIIA, IIIB, or IIIC with malunion',
  "S82866S Nondisplaced Maisonneuve's fracture of unspecified leg, sequela",
  'S82871A Displaced pilon fracture of right tibia, initial encounter for closed fracture',
  'S82871B Displaced pilon fracture of right tibia, initial encounter for open fracture type I or II',
  'S82871C Displaced pilon fracture of right tibia, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82871D Displaced pilon fracture of right tibia, subsequent encounter for closed fracture with routine',
  'healing',
  'S82871E Displaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82871F Displaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82871G Displaced pilon fracture of right tibia, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82871H Displaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82871J Displaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82871K Displaced pilon fracture of right tibia, subsequent encounter for closed fracture with nonunion',
  'S82871M Displaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82871N Displaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82871P Displaced pilon fracture of right tibia, subsequent encounter for closed fracture with malunion',
  'S82871Q Displaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82871R Displaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82871S Displaced pilon fracture of right tibia, sequela',
  'S82872A Displaced pilon fracture of left tibia, initial encounter for closed fracture',
  'S82872B Displaced pilon fracture of left tibia, initial encounter for open fracture type I or II',
  'S82872C Displaced pilon fracture of left tibia, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82872D Displaced pilon fracture of left tibia, subsequent encounter for closed fracture with routine',
  'healing',
  'S82872E Displaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82872F Displaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S82872G Displaced pilon fracture of left tibia, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82872H Displaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82872J Displaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S82872K Displaced pilon fracture of left tibia, subsequent encounter for closed fracture with nonunion',
  'S82872M Displaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82872N Displaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S82872P Displaced pilon fracture of left tibia, subsequent encounter for closed fracture with malunion',
  'S82872Q Displaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82872R Displaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S82872S Displaced pilon fracture of left tibia, sequela',
  'S82873A Displaced pilon fracture of unspecified tibia, initial encounter for closed fracture',
  'S82873B Displaced pilon fracture of unspecified tibia, initial encounter for open fracture type I or II',
  'S82873C Displaced pilon fracture of unspecified tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82873D Displaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82873E Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S82873F Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82873G Displaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82873H Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S82873J Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S82873K Displaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82873M Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type I or',
  'II with nonunion',
  'S82873N Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82873P Displaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82873Q Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type I or',
  'II with malunion',
  'S82873R Displaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82873S Displaced pilon fracture of unspecified tibia, sequela',
  'S82874A Nondisplaced pilon fracture of right tibia, initial encounter for closed fracture',
  'S82874B Nondisplaced pilon fracture of right tibia, initial encounter for open fracture type I or II',
  'S82874C Nondisplaced pilon fracture of right tibia, initial encounter for open fracture type IIIA, IIIB, or',
  'IIIC',
  'S82874D Nondisplaced pilon fracture of right tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82874E Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82874F Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with routine healing',
  'S82874G Nondisplaced pilon fracture of right tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82874H Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82874J Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82874K Nondisplaced pilon fracture of right tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82874M Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82874N Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with nonunion',
  'S82874P Nondisplaced pilon fracture of right tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82874Q Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82874R Nondisplaced pilon fracture of right tibia, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with malunion',
  'S82874S Nondisplaced pilon fracture of right tibia, sequela',
  'S82875A Nondisplaced pilon fracture of left tibia, initial encounter for closed fracture',
  'S82875B Nondisplaced pilon fracture of left tibia, initial encounter for open fracture type I or II',
  'S82875C Nondisplaced pilon fracture of left tibia, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82875D Nondisplaced pilon fracture of left tibia, subsequent encounter for closed fracture with routine',
  'healing',
  'S82875E Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82875F Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S82875G Nondisplaced pilon fracture of left tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82875H Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82875J Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82875K Nondisplaced pilon fracture of left tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82875M Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82875N Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S82875P Nondisplaced pilon fracture of left tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82875Q Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82875R Nondisplaced pilon fracture of left tibia, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S82875S Nondisplaced pilon fracture of left tibia, sequela',
  'S82876A Nondisplaced pilon fracture of unspecified tibia, initial encounter for closed fracture',
  'S82876B Nondisplaced pilon fracture of unspecified tibia, initial encounter for open fracture type I or II',
  'S82876C Nondisplaced pilon fracture of unspecified tibia, initial encounter for open fracture type IIIA,',
  'IIIB, or IIIC',
  'S82876D Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'routine healing',
  'S82876E Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with routine healing',
  'S82876F Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S82876G Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'delayed healing',
  'S82876H Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with delayed healing',
  'S82876J Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with delayed healing',
  'S82876K Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'nonunion',
  'S82876M Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type',
  'I or II with nonunion',
  'S82876N Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S82876P Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for closed fracture with',
  'malunion',
  'S82876Q Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S82876R Nondisplaced pilon fracture of unspecified tibia, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S82876S Nondisplaced pilon fracture of unspecified tibia, sequela',
  'S82891A Other fracture of right lower leg, initial encounter for closed fracture',
  'S82891B Other fracture of right lower leg, initial encounter for open fracture type I or II',
  'S82891C Other fracture of right lower leg, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82891D Other fracture of right lower leg, subsequent encounter for closed fracture with routine',
  'healing',
  'S82891E Other fracture of right lower leg, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82891F Other fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with routine healing',
  'S82891G Other fracture of right lower leg, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82891H Other fracture of right lower leg, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82891J Other fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S82891K Other fracture of right lower leg, subsequent encounter for closed fracture with nonunion',
  'S82891M Other fracture of right lower leg, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82891N Other fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with nonunion',
  'S82891P Other fracture of right lower leg, subsequent encounter for closed fracture with malunion',
  'S82891Q Other fracture of right lower leg, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82891R Other fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with malunion',
  'S82891S Other fracture of right lower leg, sequela',
  'S82892A Other fracture of left lower leg, initial encounter for closed fracture',
  'S82892B Other fracture of left lower leg, initial encounter for open fracture type I or II',
  'S82892C Other fracture of left lower leg, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82892D Other fracture of left lower leg, subsequent encounter for closed fracture with routine healing',
  'S82892E Other fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S82892F Other fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with routine healing',
  'S82892G Other fracture of left lower leg, subsequent encounter for closed fracture with delayed healing',
  'S82892H Other fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S82892J Other fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with delayed healing',
  'S82892K Other fracture of left lower leg, subsequent encounter for closed fracture with nonunion',
  'S82892M Other fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S82892N Other fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with nonunion',
  'S82892P Other fracture of left lower leg, subsequent encounter for closed fracture with malunion',
  'S82892Q Other fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S82892R Other fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or IIIC',
  'with malunion',
  'S82892S Other fracture of left lower leg, sequela',
  'S82899A Other fracture of unspecified lower leg, initial encounter for closed fracture',
  'S82899B Other fracture of unspecified lower leg, initial encounter for open fracture type I or II',
  'S82899C Other fracture of unspecified lower leg, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S82899D Other fracture of unspecified lower leg, subsequent encounter for closed fracture with routine',
  'healing',
  'S82899E Other fracture of unspecified lower leg, subsequent encounter for open fracture type I or II',
  'with routine healing',
  'S82899F Other fracture of unspecified lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S82899G Other fracture of unspecified lower leg, subsequent encounter for closed fracture with delayed',
  'healing',
  'S82899H Other fracture of unspecified lower leg, subsequent encounter for open fracture type I or II',
  'with delayed healing',
  'S82899J Other fracture of unspecified lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S82899K Other fracture of unspecified lower leg, subsequent encounter for closed fracture with',
  'nonunion',
  'S82899M Other fracture of unspecified lower leg, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S82899N Other fracture of unspecified lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S82899P Other fracture of unspecified lower leg, subsequent encounter for closed fracture with',
  'malunion',
  'S82899Q Other fracture of unspecified lower leg, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S82899R Other fracture of unspecified lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S82899S Other fracture of unspecified lower leg, sequela',
  'S8290XA Unspecified fracture of unspecified lower leg, initial encounter for closed fracture',
  'S8290XB Unspecified fracture of unspecified lower leg, initial encounter for open fracture type I or II',
  'S8290XC Unspecified fracture of unspecified lower leg, initial encounter for open fracture type IIIA, IIIB,',
  'or IIIC',
  'S8290XD Unspecified fracture of unspecified lower leg, subsequent encounter for closed fracture with',
  'routine healing',
  'S8290XE Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type I or',
  'II with routine healing',
  'S8290XF Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with routine healing',
  'S8290XG Unspecified fracture of unspecified lower leg, subsequent encounter for closed fracture with',
  'delayed healing',
  'S8290XH Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type I or',
  'II with delayed healing',
  'S8290XJ Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type IIIA,',
  'IIIB, or IIIC with delayed healing',
  'S8290XK Unspecified fracture of unspecified lower leg, subsequent encounter for closed fracture with',
  'nonunion',
  'S8290XM Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type I',
  'or II with nonunion',
  'S8290XN Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with nonunion',
  'S8290XP Unspecified fracture of unspecified lower leg, subsequent encounter for closed fracture with',
  'malunion',
  'S8290XQ Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type I',
  'or II with malunion',
  'S8290XR Unspecified fracture of unspecified lower leg, subsequent encounter for open fracture type',
  'IIIA, IIIB, or IIIC with malunion',
  'S8290XS Unspecified fracture of unspecified lower leg, sequela',
  'S8291XA Unspecified fracture of right lower leg, initial encounter for closed fracture',
  'S8291XB Unspecified fracture of right lower leg, initial encounter for open fracture type I or II',
  'S8291XC Unspecified fracture of right lower leg, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S8291XD Unspecified fracture of right lower leg, subsequent encounter for closed fracture with routine',
  'healing',
  'S8291XE Unspecified fracture of right lower leg, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S8291XF Unspecified fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with routine healing',
  'S8291XG Unspecified fracture of right lower leg, subsequent encounter for closed fracture with delayed',
  'healing',
  'S8291XH Unspecified fracture of right lower leg, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S8291XJ Unspecified fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with delayed healing',
  'S8291XK Unspecified fracture of right lower leg, subsequent encounter for closed fracture with',
  'nonunion',
  'S8291XM Unspecified fracture of right lower leg, subsequent encounter for open fracture type I or II',
  'with nonunion',
  'S8291XN Unspecified fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with nonunion',
  'S8291XP Unspecified fracture of right lower leg, subsequent encounter for closed fracture with',
  'malunion',
  'S8291XQ Unspecified fracture of right lower leg, subsequent encounter for open fracture type I or II',
  'with malunion',
  'S8291XR Unspecified fracture of right lower leg, subsequent encounter for open fracture type IIIA, IIIB,',
  'or IIIC with malunion',
  'S8291XS Unspecified fracture of right lower leg, sequela',
  'S8292XA Unspecified fracture of left lower leg, initial encounter for closed fracture',
  'S8292XB Unspecified fracture of left lower leg, initial encounter for open fracture type I or II',
  'S8292XC Unspecified fracture of left lower leg, initial encounter for open fracture type IIIA, IIIB, or IIIC',
  'S8292XD Unspecified fracture of left lower leg, subsequent encounter for closed fracture with routine',
  'healing',
  'S8292XE Unspecified fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'routine healing',
  'S8292XF Unspecified fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with routine healing',
  'S8292XG Unspecified fracture of left lower leg, subsequent encounter for closed fracture with delayed',
  'healing',
  'S8292XH Unspecified fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'delayed healing',
  'S8292XJ Unspecified fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with delayed healing',
  'S8292XK Unspecified fracture of left lower leg, subsequent encounter for closed fracture with nonunion',
  'S8292XM Unspecified fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'nonunion',
  'S8292XN Unspecified fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with nonunion',
  'S8292XP Unspecified fracture of left lower leg, subsequent encounter for closed fracture with malunion',
  'S8292XQ Unspecified fracture of left lower leg, subsequent encounter for open fracture type I or II with',
  'malunion',
  'S8292XR Unspecified fracture of left lower leg, subsequent encounter for open fracture type IIIA, IIIB, or',
  'IIIC with malunion',
  'S8292XS Unspecified fracture of left lower leg, sequela',
  'S83001A Unspecified subluxation of right patella, initial encounter',
  'S83001D Unspecified subluxation of right patella, subsequent encounter',
  'S83001S Unspecified subluxation of right patella, sequela',
  'S83002A Unspecified subluxation of left patella, initial encounter',
  'S83002D Unspecified subluxation of left patella, subsequent encounter',
  'S83002S Unspecified subluxation of left patella, sequela',
  'S83003A Unspecified subluxation of unspecified patella, initial encounter',
  'S83003D Unspecified subluxation of unspecified patella, subsequent encounter',
  'S83003S Unspecified subluxation of unspecified patella, sequela',
  'S83004A Unspecified dislocation of right patella, initial encounter',
  'S83004D Unspecified dislocation of right patella, subsequent encounter',
  'S83004S Unspecified dislocation of right patella, sequela',
  'S83005A Unspecified dislocation of left patella, initial encounter',
  'S83005D Unspecified dislocation of left patella, subsequent encounter',
  'S83005S Unspecified dislocation of left patella, sequela',
  'S83006A Unspecified dislocation of unspecified patella, initial encounter',
  'S83006D Unspecified dislocation of unspecified patella, subsequent encounter',
  'S83006S Unspecified dislocation of unspecified patella, sequela',
  'S83011A Lateral subluxation of right patella, initial encounter',
  'S83011D Lateral subluxation of right patella, subsequent encounter',
  'S83011S Lateral subluxation of right patella, sequela',
  'S83012A Lateral subluxation of left patella, initial encounter',
  'S83012D Lateral subluxation of left patella, subsequent encounter',
  'S83012S Lateral subluxation of left patella, sequela',
  'S83013A Lateral subluxation of unspecified patella, initial encounter',
  'S83013D Lateral subluxation of unspecified patella, subsequent encounter',
  'S83013S Lateral subluxation of unspecified patella, sequela',
  'S83014A Lateral dislocation of right patella, initial encounter',
  'S83014D Lateral dislocation of right patella, subsequent encounter',
  'S83014S Lateral dislocation of right patella, sequela',
  'S83015A Lateral dislocation of left patella, initial encounter',
  'S83015D Lateral dislocation of left patella, subsequent encounter',
  'S83015S Lateral dislocation of left patella, sequela',
  'S83016A Lateral dislocation of unspecified patella, initial encounter',
  'S83016D Lateral dislocation of unspecified patella, subsequent encounter',
  'S83016S Lateral dislocation of unspecified patella, sequela',
  'S83091A Other subluxation of right patella, initial encounter',
  'S83091D Other subluxation of right patella, subsequent encounter',
  'S83091S Other subluxation of right patella, sequela',
  'S83092A Other subluxation of left patella, initial encounter',
  'S83092D Other subluxation of left patella, subsequent encounter',
  'S83092S Other subluxation of left patella, sequela',
  'S83093A Other subluxation of unspecified patella, initial encounter',
  'S83093D Other subluxation of unspecified patella, subsequent encounter',
  'S83093S Other subluxation of unspecified patella, sequela',
  'S83094A Other dislocation of right patella, initial encounter',
  'S83094D Other dislocation of right patella, subsequent encounter',
  'S83094S Other dislocation of right patella, sequela',
  'S83095A Other dislocation of left patella, initial encounter',
  'S83095D Other dislocation of left patella, subsequent encounter',
  'S83095S Other dislocation of left patella, sequela',
  'S83096A Other dislocation of unspecified patella, initial encounter',
  'S83096D Other dislocation of unspecified patella, subsequent encounter',
  'S83096S Other dislocation of unspecified patella, sequela',
  'S83101A Unspecified subluxation of right knee, initial encounter',
  'S83101D Unspecified subluxation of right knee, subsequent encounter',
  'S83101S Unspecified subluxation of right knee, sequela',
  'S83102A Unspecified subluxation of left knee, initial encounter',
  'S83102D Unspecified subluxation of left knee, subsequent encounter',
  'S83102S Unspecified subluxation of left knee, sequela',
  'S83103A Unspecified subluxation of unspecified knee, initial encounter',
  'S83103D Unspecified subluxation of unspecified knee, subsequent encounter',
  'S83103S Unspecified subluxation of unspecified knee, sequela',
  'S83104A Unspecified dislocation of right knee, initial encounter',
  'S83104D Unspecified dislocation of right knee, subsequent encounter',
  'S83104S Unspecified dislocation of right knee, sequela',
  'S83105A Unspecified dislocation of left knee, initial encounter',
  'S83105D Unspecified dislocation of left knee, subsequent encounter',
  'S83105S Unspecified dislocation of left knee, sequela',
  'S83106A Unspecified dislocation of unspecified knee, initial encounter',
  'S83106D Unspecified dislocation of unspecified knee, subsequent encounter',
  'S83106S Unspecified dislocation of unspecified knee, sequela',
  'S83111A Anterior subluxation of proximal end of tibia, right knee, initial encounter',
  'S83111D Anterior subluxation of proximal end of tibia, right knee, subsequent encounter',
  'S83111S Anterior subluxation of proximal end of tibia, right knee, sequela',
  'S83112A Anterior subluxation of proximal end of tibia, left knee, initial encounter',
  'S83112D Anterior subluxation of proximal end of tibia, left knee, subsequent encounter',
  'S83112S Anterior subluxation of proximal end of tibia, left knee, sequela',
  'S83113A Anterior subluxation of proximal end of tibia, unspecified knee, initial encounter',
  'S83113D Anterior subluxation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83113S Anterior subluxation of proximal end of tibia, unspecified knee, sequela',
  'S83114A Anterior dislocation of proximal end of tibia, right knee, initial encounter',
  'S83114D Anterior dislocation of proximal end of tibia, right knee, subsequent encounter',
  'S83114S Anterior dislocation of proximal end of tibia, right knee, sequela',
  'S83115A Anterior dislocation of proximal end of tibia, left knee, initial encounter',
  'S83115D Anterior dislocation of proximal end of tibia, left knee, subsequent encounter',
  'S83115S Anterior dislocation of proximal end of tibia, left knee, sequela',
  'S83116A Anterior dislocation of proximal end of tibia, unspecified knee, initial encounter',
  'S83116D Anterior dislocation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83116S Anterior dislocation of proximal end of tibia, unspecified knee, sequela',
  'S83121A Posterior subluxation of proximal end of tibia, right knee, initial encounter',
  'S83121D Posterior subluxation of proximal end of tibia, right knee, subsequent encounter',
  'S83121S Posterior subluxation of proximal end of tibia, right knee, sequela',
  'S83122A Posterior subluxation of proximal end of tibia, left knee, initial encounter',
  'S83122D Posterior subluxation of proximal end of tibia, left knee, subsequent encounter',
  'S83122S Posterior subluxation of proximal end of tibia, left knee, sequela',
  'S83123A Posterior subluxation of proximal end of tibia, unspecified knee, initial encounter',
  'S83123D Posterior subluxation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83123S Posterior subluxation of proximal end of tibia, unspecified knee, sequela',
  'S83124A Posterior dislocation of proximal end of tibia, right knee, initial encounter',
  'S83124D Posterior dislocation of proximal end of tibia, right knee, subsequent encounter',
  'S83124S Posterior dislocation of proximal end of tibia, right knee, sequela',
  'S83125A Posterior dislocation of proximal end of tibia, left knee, initial encounter',
  'S83125D Posterior dislocation of proximal end of tibia, left knee, subsequent encounter',
  'S83125S Posterior dislocation of proximal end of tibia, left knee, sequela',
  'S83126A Posterior dislocation of proximal end of tibia, unspecified knee, initial encounter',
  'S83126D Posterior dislocation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83126S Posterior dislocation of proximal end of tibia, unspecified knee, sequela',
  'S83131A Medial subluxation of proximal end of tibia, right knee, initial encounter',
  'S83131D Medial subluxation of proximal end of tibia, right knee, subsequent encounter',
  'S83131S Medial subluxation of proximal end of tibia, right knee, sequela',
  'S83132A Medial subluxation of proximal end of tibia, left knee, initial encounter',
  'S83132D Medial subluxation of proximal end of tibia, left knee, subsequent encounter',
  'S83132S Medial subluxation of proximal end of tibia, left knee, sequela',
  'S83133A Medial subluxation of proximal end of tibia, unspecified knee, initial encounter',
  'S83133D Medial subluxation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83133S Medial subluxation of proximal end of tibia, unspecified knee, sequela',
  'S83134A Medial dislocation of proximal end of tibia, right knee, initial encounter',
  'S83134D Medial dislocation of proximal end of tibia, right knee, subsequent encounter',
  'S83134S Medial dislocation of proximal end of tibia, right knee, sequela',
  'S83135A Medial dislocation of proximal end of tibia, left knee, initial encounter',
  'S83135D Medial dislocation of proximal end of tibia, left knee, subsequent encounter',
  'S83135S Medial dislocation of proximal end of tibia, left knee, sequela',
  'S83136A Medial dislocation of proximal end of tibia, unspecified knee, initial encounter',
  'S83136D Medial dislocation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83136S Medial dislocation of proximal end of tibia, unspecified knee, sequela',
  'S83141A Lateral subluxation of proximal end of tibia, right knee, initial encounter',
  'S83141D Lateral subluxation of proximal end of tibia, right knee, subsequent encounter',
  'S83141S Lateral subluxation of proximal end of tibia, right knee, sequela',
  'S83142A Lateral subluxation of proximal end of tibia, left knee, initial encounter',
  'S83142D Lateral subluxation of proximal end of tibia, left knee, subsequent encounter',
  'S83142S Lateral subluxation of proximal end of tibia, left knee, sequela',
  'S83143A Lateral subluxation of proximal end of tibia, unspecified knee, initial encounter',
  'S83143D Lateral subluxation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83143S Lateral subluxation of proximal end of tibia, unspecified knee, sequela',
  'S83144A Lateral dislocation of proximal end of tibia, right knee, initial encounter',
  'S83144D Lateral dislocation of proximal end of tibia, right knee, subsequent encounter',
  'S83144S Lateral dislocation of proximal end of tibia, right knee, sequela',
  'S83145A Lateral dislocation of proximal end of tibia, left knee, initial encounter',
  'S83145D Lateral dislocation of proximal end of tibia, left knee, subsequent encounter',
  'S83145S Lateral dislocation of proximal end of tibia, left knee, sequela',
  'S83146A Lateral dislocation of proximal end of tibia, unspecified knee, initial encounter',
  'S83146D Lateral dislocation of proximal end of tibia, unspecified knee, subsequent encounter',
  'S83146S Lateral dislocation of proximal end of tibia, unspecified knee, sequela',
  'S83191A Other subluxation of right knee, initial encounter',
  'S83191D Other subluxation of right knee, subsequent encounter',
  'S83191S Other subluxation of right knee, sequela',
  'S83192A Other subluxation of left knee, initial encounter',
  'S83192D Other subluxation of left knee, subsequent encounter',
  'S83192S Other subluxation of left knee, sequela',
  'S83193A Other subluxation of unspecified knee, initial encounter',
  'S83193D Other subluxation of unspecified knee, subsequent encounter',
  'S83193S Other subluxation of unspecified knee, sequela',
  'S83194A Other dislocation of right knee, initial encounter',
  'S83194D Other dislocation of right knee, subsequent encounter',
  'S83194S Other dislocation of right knee, sequela',
  'S83195A Other dislocation of left knee, initial encounter',
  'S83195D Other dislocation of left knee, subsequent encounter',
  'S83195S Other dislocation of left knee, sequela',
  'S83196A Other dislocation of unspecified knee, initial encounter',
  'S83196D Other dislocation of unspecified knee, subsequent encounter',
  'S83196S Other dislocation of unspecified knee, sequela',
  'S83200A Bucket-handle tear of unspecified meniscus, current injury, right knee, initial encounter',
  'S83200D Bucket-handle tear of unspecified meniscus, current injury, right knee, subsequent encounter',
  'S83200S Bucket-handle tear of unspecified meniscus, current injury, right knee, sequela',
  'S83201A Bucket-handle tear of unspecified meniscus, current injury, left knee, initial encounter',
  'S83201D Bucket-handle tear of unspecified meniscus, current injury, left knee, subsequent encounter',
  'S83201S Bucket-handle tear of unspecified meniscus, current injury, left knee, sequela',
  'S83202A Bucket-handle tear of unspecified meniscus, current injury, unspecified knee, initial encounter',
  'S83202D Bucket-handle tear of unspecified meniscus, current injury, unspecified knee, subsequent',
  'encounter',
  'S83202S Bucket-handle tear of unspecified meniscus, current injury, unspecified knee, sequela',
  'S83203A Other tear of unspecified meniscus, current injury, right knee, initial encounter',
  'S83203D Other tear of unspecified meniscus, current injury, right knee, subsequent encounter',
  'S83203S Other tear of unspecified meniscus, current injury, right knee, sequela',
  'S83204A Other tear of unspecified meniscus, current injury, left knee, initial encounter',
  'S83204D Other tear of unspecified meniscus, current injury, left knee, subsequent encounter',
  'S83204S Other tear of unspecified meniscus, current injury, left knee, sequela',
  'S83205A Other tear of unspecified meniscus, current injury, unspecified knee, initial encounter',
  'S83205D Other tear of unspecified meniscus, current injury, unspecified knee, subsequent encounter',
  'S83205S Other tear of unspecified meniscus, current injury, unspecified knee, sequela',
  'S83206A Unspecified tear of unspecified meniscus, current injury, right knee, initial encounter',
  'S83206D Unspecified tear of unspecified meniscus, current injury, right knee, subsequent encounter',
  'S83206S Unspecified tear of unspecified meniscus, current injury, right knee, sequela',
  'S83207A Unspecified tear of unspecified meniscus, current injury, left knee, initial encounter',
  'S83207D Unspecified tear of unspecified meniscus, current injury, left knee, subsequent encounter',
  'S83207S Unspecified tear of unspecified meniscus, current injury, left knee, sequela',
  'S83209A Unspecified tear of unspecified meniscus, current injury, unspecified knee, initial encounter',
  'S83209D Unspecified tear of unspecified meniscus, current injury, unspecified knee, subsequent',
  'encounter',
  'S83209S Unspecified tear of unspecified meniscus, current injury, unspecified knee, sequela',
  'S83211A Bucket-handle tear of medial meniscus, current injury, right knee, initial encounter',
  'S83211D Bucket-handle tear of medial meniscus, current injury, right knee, subsequent encounter',
  'S83211S Bucket-handle tear of medial meniscus, current injury, right knee, sequela',
  'S83212A Bucket-handle tear of medial meniscus, current injury, left knee, initial encounter',
  'S83212D Bucket-handle tear of medial meniscus, current injury, left knee, subsequent encounter',
  'S83212S Bucket-handle tear of medial meniscus, current injury, left knee, sequela',
  'S83219A Bucket-handle tear of medial meniscus, current injury, unspecified knee, initial encounter',
  'S83219D Bucket-handle tear of medial meniscus, current injury, unspecified knee, subsequent',
  'encounter',
  'S83219S Bucket-handle tear of medial meniscus, current injury, unspecified knee, sequela',
  'S83221A Peripheral tear of medial meniscus, current injury, right knee, initial encounter',
  'S83221D Peripheral tear of medial meniscus, current injury, right knee, subsequent encounter',
  'S83221S Peripheral tear of medial meniscus, current injury, right knee, sequela',
  'S83222A Peripheral tear of medial meniscus, current injury, left knee, initial encounter',
  'S83222D Peripheral tear of medial meniscus, current injury, left knee, subsequent encounter',
  'S83222S Peripheral tear of medial meniscus, current injury, left knee, sequela',
  'S83229A Peripheral tear of medial meniscus, current injury, unspecified knee, initial encounter',
  'S83229D Peripheral tear of medial meniscus, current injury, unspecified knee, subsequent encounter',
  'S83229S Peripheral tear of medial meniscus, current injury, unspecified knee, sequela',
  'S83231A Complex tear of medial meniscus, current injury, right knee, initial encounter',
  'S83231D Complex tear of medial meniscus, current injury, right knee, subsequent encounter',
  'S83231S Complex tear of medial meniscus, current injury, right knee, sequela',
  'S83232A Complex tear of medial meniscus, current injury, left knee, initial encounter',
  'S83232D Complex tear of medial meniscus, current injury, left knee, subsequent encounter',
  'S83232S Complex tear of medial meniscus, current injury, left knee, sequela',
  'S83239A Complex tear of medial meniscus, current injury, unspecified knee, initial encounter',
  'S83239D Complex tear of medial meniscus, current injury, unspecified knee, subsequent encounter',
  'S83239S Complex tear of medial meniscus, current injury, unspecified knee, sequela',
  'S83241A Other tear of medial meniscus, current injury, right knee, initial encounter',
  'S83241D Other tear of medial meniscus, current injury, right knee, subsequent encounter',
  'S83241S Other tear of medial meniscus, current injury, right knee, sequela',
  'S83242A Other tear of medial meniscus, current injury, left knee, initial encounter',
  'S83242D Other tear of medial meniscus, current injury, left knee, subsequent encounter',
  'S83242S Other tear of medial meniscus, current injury, left knee, sequela',
  'S83249A Other tear of medial meniscus, current injury, unspecified knee, initial encounter',
  'S83249D Other tear of medial meniscus, current injury, unspecified knee, subsequent encounter',
  'S83249S Other tear of medial meniscus, current injury, unspecified knee, sequela',
  'S83251A Bucket-handle tear of lateral meniscus, current injury, right knee, initial encounter',
  'S83251D Bucket-handle tear of lateral meniscus, current injury, right knee, subsequent encounter',
  'S83251S Bucket-handle tear of lateral meniscus, current injury, right knee, sequela',
  'S83252A Bucket-handle tear of lateral meniscus, current injury, left knee, initial encounter',
  'S83252D Bucket-handle tear of lateral meniscus, current injury, left knee, subsequent encounter',
  'S83252S Bucket-handle tear of lateral meniscus, current injury, left knee, sequela',
  'S83259A Bucket-handle tear of lateral meniscus, current injury, unspecified knee, initial encounter',
  'S83259D Bucket-handle tear of lateral meniscus, current injury, unspecified knee, subsequent encounter',
  'S83259S Bucket-handle tear of lateral meniscus, current injury, unspecified knee, sequela',
  'S83261A Peripheral tear of lateral meniscus, current injury, right knee, initial encounter',
  'S83261D Peripheral tear of lateral meniscus, current injury, right knee, subsequent encounter',
  'S83261S Peripheral tear of lateral meniscus, current injury, right knee, sequela',
  'S83262A Peripheral tear of lateral meniscus, current injury, left knee, initial encounter',
  'S83262D Peripheral tear of lateral meniscus, current injury, left knee, subsequent encounter',
  'S83262S Peripheral tear of lateral meniscus, current injury, left knee, sequela',
  'S83269A Peripheral tear of lateral meniscus, current injury, unspecified knee, initial encounter',
  'S83269D Peripheral tear of lateral meniscus, current injury, unspecified knee, subsequent encounter',
  'S83269S Peripheral tear of lateral meniscus, current injury, unspecified knee, sequela',
  'S83271A Complex tear of lateral meniscus, current injury, right knee, initial encounter',
  'S83271D Complex tear of lateral meniscus, current injury, right knee, subsequent encounter',
  'S83271S Complex tear of lateral meniscus, current injury, right knee, sequela',
  'S83272A Complex tear of lateral meniscus, current injury, left knee, initial encounter',
  'S83272D Complex tear of lateral meniscus, current injury, left knee, subsequent encounter',
  'S83272S Complex tear of lateral meniscus, current injury, left knee, sequela',
  'S83279A Complex tear of lateral meniscus, current injury, unspecified knee, initial encounter',
  'S83279D Complex tear of lateral meniscus, current injury, unspecified knee, subsequent encounter',
  'S83279S Complex tear of lateral meniscus, current injury, unspecified knee, sequela',
  'S83281A Other tear of lateral meniscus, current injury, right knee, initial encounter',
  'S83281D Other tear of lateral meniscus, current injury, right knee, subsequent encounter',
  'S83281S Other tear of lateral meniscus, current injury, right knee, sequela',
  'S83282A Other tear of lateral meniscus, current injury, left knee, initial encounter',
  'S83282D Other tear of lateral meniscus, current injury, left knee, subsequent encounter',
  'S83282S Other tear of lateral meniscus, current injury, left knee, sequela',
  'S83289A Other tear of lateral meniscus, current injury, unspecified knee, initial encounter',
  'S83289D Other tear of lateral meniscus, current injury, unspecified knee, subsequent encounter',
  'S83289S Other tear of lateral meniscus, current injury, unspecified knee, sequela',
  'S8330XA Tear of articular cartilage of unspecified knee, current, initial encounter',
  'S8330XD Tear of articular cartilage of unspecified knee, current, subsequent encounter',
  'S8330XS Tear of articular cartilage of unspecified knee, current, sequela',
  'S8331XA Tear of articular cartilage of right knee, current, initial encounter',
  'S8331XD Tear of articular cartilage of right knee, current, subsequent encounter',
  'S8331XS Tear of articular cartilage of right knee, current, sequela',
  'S8332XA Tear of articular cartilage of left knee, current, initial encounter',
  'S8332XD Tear of articular cartilage of left knee, current, subsequent encounter',
  'S8332XS Tear of articular cartilage of left knee, current, sequela',
  'S83401A Sprain of unspecified collateral ligament of right knee, initial encounter',
  'S83401D Sprain of unspecified collateral ligament of right knee, subsequent encounter',
  'S83401S Sprain of unspecified collateral ligament of right knee, sequela',
  'S83402A Sprain of unspecified collateral ligament of left knee, initial encounter',
  'S83402D Sprain of unspecified collateral ligament of left knee, subsequent encounter',
  'S83402S Sprain of unspecified collateral ligament of left knee, sequela',
  'S83409A Sprain of unspecified collateral ligament of unspecified knee, initial encounter',
  'S83409D Sprain of unspecified collateral ligament of unspecified knee, subsequent encounter',
  'S83409S Sprain of unspecified collateral ligament of unspecified knee, sequela',
  'S83411A Sprain of medial collateral ligament of right knee, initial encounter',
  'S83411D Sprain of medial collateral ligament of right knee, subsequent encounter',
  'S83411S Sprain of medial collateral ligament of right knee, sequela',
  'S83412A Sprain of medial collateral ligament of left knee, initial encounter',
  'S83412D Sprain of medial collateral ligament of left knee, subsequent encounter',
  'S83412S Sprain of medial collateral ligament of left knee, sequela',
  'S83419A Sprain of medial collateral ligament of unspecified knee, initial encounter',
  'S83419D Sprain of medial collateral ligament of unspecified knee, subsequent encounter',
  'S83419S Sprain of medial collateral ligament of unspecified knee, sequela',
  'S83421A Sprain of lateral collateral ligament of right knee, initial encounter',
  'S83421D Sprain of lateral collateral ligament of right knee, subsequent encounter',
  'S83421S Sprain of lateral collateral ligament of right knee, sequela',
  'S83422A Sprain of lateral collateral ligament of left knee, initial encounter',
  'S83422D Sprain of lateral collateral ligament of left knee, subsequent encounter',
  'S83422S Sprain of lateral collateral ligament of left knee, sequela',
  'S83429A Sprain of lateral collateral ligament of unspecified knee, initial encounter',
  'S83429D Sprain of lateral collateral ligament of unspecified knee, subsequent encounter',
  'S83429S Sprain of lateral collateral ligament of unspecified knee, sequela',
  'S83501A Sprain of unspecified cruciate ligament of right knee, initial encounter',
  'S83501D Sprain of unspecified cruciate ligament of right knee, subsequent encounter',
  'S83501S Sprain of unspecified cruciate ligament of right knee, sequela',
  'S83502A Sprain of unspecified cruciate ligament of left knee, initial encounter',
  'S83502D Sprain of unspecified cruciate ligament of left knee, subsequent encounter',
  'S83502S Sprain of unspecified cruciate ligament of left knee, sequela',
  'S83509A Sprain of unspecified cruciate ligament of unspecified knee, initial encounter',
  'S83509D Sprain of unspecified cruciate ligament of unspecified knee, subsequent encounter',
  'S83509S Sprain of unspecified cruciate ligament of unspecified knee, sequela',
  'S83511A Sprain of anterior cruciate ligament of right knee, initial encounter',
  'S83511D Sprain of anterior cruciate ligament of right knee, subsequent encounter',
  'S83511S Sprain of anterior cruciate ligament of right knee, sequela',
  'S83512A Sprain of anterior cruciate ligament of left knee, initial encounter',
  'S83512D Sprain of anterior cruciate ligament of left knee, subsequent encounter',
  'S83512S Sprain of anterior cruciate ligament of left knee, sequela',
  'S83519A Sprain of anterior cruciate ligament of unspecified knee, initial encounter',
  'S83519D Sprain of anterior cruciate ligament of unspecified knee, subsequent encounter',
  'S83519S Sprain of anterior cruciate ligament of unspecified knee, sequela',
  'S83521A Sprain of posterior cruciate ligament of right knee, initial encounter',
  'S83521D Sprain of posterior cruciate ligament of right knee, subsequent encounter',
  'S83521S Sprain of posterior cruciate ligament of right knee, sequela',
  'S83522A Sprain of posterior cruciate ligament of left knee, initial encounter',
  'S83522D Sprain of posterior cruciate ligament of left knee, subsequent encounter',
  'S83522S Sprain of posterior cruciate ligament of left knee, sequela',
  'S83529A Sprain of posterior cruciate ligament of unspecified knee, initial encounter',
  'S83529D Sprain of posterior cruciate ligament of unspecified knee, subsequent encounter',
  'S83529S Sprain of posterior cruciate ligament of unspecified knee, sequela',
  'S8360XA Sprain of the superior tibiofibular joint and ligament, unspecified knee, initial encounter',
  'S8360XD Sprain of the superior tibiofibular joint and ligament, unspecified knee, subsequent encounter',
  'S8360XS Sprain of the superior tibiofibular joint and ligament, unspecified knee, sequela',
  'S8361XA Sprain of the superior tibiofibular joint and ligament, right knee, initial encounter',
  'S8361XD Sprain of the superior tibiofibular joint and ligament, right knee, subsequent encounter',
  'S8361XS Sprain of the superior tibiofibular joint and ligament, right knee, sequela',
  'S8362XA Sprain of the superior tibiofibular joint and ligament, left knee, initial encounter',
  'S8362XD Sprain of the superior tibiofibular joint and ligament, left knee, subsequent encounter',
  'S8362XS Sprain of the superior tibiofibular joint and ligament, left knee, sequela',
  'S838X1A Sprain of other specified parts of right knee, initial encounter',
  'S838X1D Sprain of other specified parts of right knee, subsequent encounter',
  'S838X1S Sprain of other specified parts of right knee, sequela',
  'S838X2A Sprain of other specified parts of left knee, initial encounter',
  'S838X2D Sprain of other specified parts of left knee, subsequent encounter',
  'S838X2S Sprain of other specified parts of left knee, sequela',
  'S838X9A Sprain of other specified parts of unspecified knee, initial encounter',
  'S838X9D Sprain of other specified parts of unspecified knee, subsequent encounter',
  'S838X9S Sprain of other specified parts of unspecified knee, sequela',
  'S8390XA Sprain of unspecified site of unspecified knee, initial encounter',
  'S8390XD Sprain of unspecified site of unspecified knee, subsequent encounter',
  'S8390XS Sprain of unspecified site of unspecified knee, sequela',
  'S8391XA Sprain of unspecified site of right knee, initial encounter',
  'S8391XD Sprain of unspecified site of right knee, subsequent encounter',
  'S8391XS Sprain of unspecified site of right knee, sequela',
  'S8392XA Sprain of unspecified site of left knee, initial encounter',
  'S8392XD Sprain of unspecified site of left knee, subsequent encounter',
  'S8392XS Sprain of unspecified site of left knee, sequela',
  'S8400XA Injury of tibial nerve at lower leg level, unspecified leg, initial encounter',
  'S8400XD Injury of tibial nerve at lower leg level, unspecified leg, subsequent encounter',
  'S8400XS Injury of tibial nerve at lower leg level, unspecified leg, sequela',
  'S8401XA Injury of tibial nerve at lower leg level, right leg, initial encounter',
  'S8401XD Injury of tibial nerve at lower leg level, right leg, subsequent encounter',
  'S8401XS Injury of tibial nerve at lower leg level, right leg, sequela',
  'S8402XA Injury of tibial nerve at lower leg level, left leg, initial encounter',
  'S8402XD Injury of tibial nerve at lower leg level, left leg, subsequent encounter',
  'S8402XS Injury of tibial nerve at lower leg level, left leg, sequela',
  'S8410XA Injury of peroneal nerve at lower leg level, unspecified leg, initial encounter',
  'S8410XD Injury of peroneal nerve at lower leg level, unspecified leg, subsequent encounter',
  'S8410XS Injury of peroneal nerve at lower leg level, unspecified leg, sequela',
  'S8411XA Injury of peroneal nerve at lower leg level, right leg, initial encounter',
  'S8411XD Injury of peroneal nerve at lower leg level, right leg, subsequent encounter',
  'S8411XS Injury of peroneal nerve at lower leg level, right leg, sequela',
  'S8412XA Injury of peroneal nerve at lower leg level, left leg, initial encounter',
  'S8412XD Injury of peroneal nerve at lower leg level, left leg, subsequent encounter',
  'S8412XS Injury of peroneal nerve at lower leg level, left leg, sequela',
  'S8420XA Injury of cutaneous sensory nerve at lower leg level, unspecified leg, initial encounter',
  'S8420XD Injury of cutaneous sensory nerve at lower leg level, unspecified leg, subsequent encounter',
  'S8420XS Injury of cutaneous sensory nerve at lower leg level, unspecified leg, sequela',
  'S8421XA Injury of cutaneous sensory nerve at lower leg level, right leg, initial encounter',
  'S8421XD Injury of cutaneous sensory nerve at lower leg level, right leg, subsequent encounter',
  'S8421XS Injury of cutaneous sensory nerve at lower leg level, right leg, sequela',
  'S8422XA Injury of cutaneous sensory nerve at lower leg level, left leg, initial encounter',
  'S8422XD Injury of cutaneous sensory nerve at lower leg level, left leg, subsequent encounter',
  'S8422XS Injury of cutaneous sensory nerve at lower leg level, left leg, sequela',
  'S84801A Injury of other nerves at lower leg level, right leg, initial encounter',
  'S84801D Injury of other nerves at lower leg level, right leg, subsequent encounter',
  'S84801S Injury of other nerves at lower leg level, right leg, sequela',
  'S84802A Injury of other nerves at lower leg level, left leg, initial encounter',
  'S84802D Injury of other nerves at lower leg level, left leg, subsequent encounter',
  'S84802S Injury of other nerves at lower leg level, left leg, sequela',
  'S84809A Injury of other nerves at lower leg level, unspecified leg, initial encounter',
  'S84809D Injury of other nerves at lower leg level, unspecified leg, subsequent encounter',
  'S84809S Injury of other nerves at lower leg level, unspecified leg, sequela',
  'S8490XA Injury of unspecified nerve at lower leg level, unspecified leg, initial encounter',
  'S8490XD Injury of unspecified nerve at lower leg level, unspecified leg, subsequent encounter',
  'S8490XS Injury of unspecified nerve at lower leg level, unspecified leg, sequela',
  'S8491XA Injury of unspecified nerve at lower leg level, right leg, initial encounter',
  'S8491XD Injury of unspecified nerve at lower leg level, right leg, subsequent encounter',
  'S8491XS Injury of unspecified nerve at lower leg level, right leg, sequela',
  'S8492XA Injury of unspecified nerve at lower leg level, left leg, initial encounter',
  'S8492XD Injury of unspecified nerve at lower leg level, left leg, subsequent encounter',
  'S8492XS Injury of unspecified nerve at lower leg level, left leg, sequela',
  'S85001A Unspecified injury of popliteal artery, right leg, initial encounter',
  'S85001D Unspecified injury of popliteal artery, right leg, subsequent encounter',
  'S85001S Unspecified injury of popliteal artery, right leg, sequela',
  'S85002A Unspecified injury of popliteal artery, left leg, initial encounter',
  'S85002D Unspecified injury of popliteal artery, left leg, subsequent encounter',
  'S85002S Unspecified injury of popliteal artery, left leg, sequela',
  'S85009A Unspecified injury of popliteal artery, unspecified leg, initial encounter',
  'S85009D Unspecified injury of popliteal artery, unspecified leg, subsequent encounter',
  'S85009S Unspecified injury of popliteal artery, unspecified leg, sequela',
  'S85011A Laceration of popliteal artery, right leg, initial encounter',
  'S85011D Laceration of popliteal artery, right leg, subsequent encounter',
  'S85011S Laceration of popliteal artery, right leg, sequela',
  'S85012A Laceration of popliteal artery, left leg, initial encounter',
  'S85012D Laceration of popliteal artery, left leg, subsequent encounter',
  'S85012S Laceration of popliteal artery, left leg, sequela',
  'S85019A Laceration of popliteal artery, unspecified leg, initial encounter',
  'S85019D Laceration of popliteal artery, unspecified leg, subsequent encounter',
  'S85019S Laceration of popliteal artery, unspecified leg, sequela',
  'S85091A Other specified injury of popliteal artery, right leg, initial encounter',
  'S85091D Other specified injury of popliteal artery, right leg, subsequent encounter',
  'S85091S Other specified injury of popliteal artery, right leg, sequela',
  'S85092A Other specified injury of popliteal artery, left leg, initial encounter',
  'S85092D Other specified injury of popliteal artery, left leg, subsequent encounter',
  'S85092S Other specified injury of popliteal artery, left leg, sequela',
  'S85099A Other specified injury of popliteal artery, unspecified leg, initial encounter',
  'S85099D Other specified injury of popliteal artery, unspecified leg, subsequent encounter',
  'S85099S Other specified injury of popliteal artery, unspecified leg, sequela',
  'S85101A Unspecified injury of unspecified tibial artery, right leg, initial encounter',
  'S85101D Unspecified injury of unspecified tibial artery, right leg, subsequent encounter',
  'S85101S Unspecified injury of unspecified tibial artery, right leg, sequela',
  'S85102A Unspecified injury of unspecified tibial artery, left leg, initial encounter',
  'S85102D Unspecified injury of unspecified tibial artery, left leg, subsequent encounter',
  'S85102S Unspecified injury of unspecified tibial artery, left leg, sequela',
  'S85109A Unspecified injury of unspecified tibial artery, unspecified leg, initial encounter',
  'S85109D Unspecified injury of unspecified tibial artery, unspecified leg, subsequent encounter',
  'S85109S Unspecified injury of unspecified tibial artery, unspecified leg, sequela',
  'S85111A Laceration of unspecified tibial artery, right leg, initial encounter',
  'S85111D Laceration of unspecified tibial artery, right leg, subsequent encounter',
  'S85111S Laceration of unspecified tibial artery, right leg, sequela',
  'S85112A Laceration of unspecified tibial artery, left leg, initial encounter',
  'S85112D Laceration of unspecified tibial artery, left leg, subsequent encounter',
  'S85112S Laceration of unspecified tibial artery, left leg, sequela',
  'S85119A Laceration of unspecified tibial artery, unspecified leg, initial encounter',
  'S85119D Laceration of unspecified tibial artery, unspecified leg, subsequent encounter',
  'S85119S Laceration of unspecified tibial artery, unspecified leg, sequela',
  'S85121A Other specified injury of unspecified tibial artery, right leg, initial encounter',
  'S85121D Other specified injury of unspecified tibial artery, right leg, subsequent encounter',
  'S85121S Other specified injury of unspecified tibial artery, right leg, sequela',
  'S85122A Other specified injury of unspecified tibial artery, left leg, initial encounter',
  'S85122D Other specified injury of unspecified tibial artery, left leg, subsequent encounter',
  'S85122S Other specified injury of unspecified tibial artery, left leg, sequela',
  'S85129A Other specified injury of unspecified tibial artery, unspecified leg, initial encounter',
  'S85129D Other specified injury of unspecified tibial artery, unspecified leg, subsequent encounter',
  'S85129S Other specified injury of unspecified tibial artery, unspecified leg, sequela',
  'S85131A Unspecified injury of anterior tibial artery, right leg, initial encounter',
  'S85131D Unspecified injury of anterior tibial artery, right leg, subsequent encounter',
  'S85131S Unspecified injury of anterior tibial artery, right leg, sequela',
  'S85132A Unspecified injury of anterior tibial artery, left leg, initial encounter',
  'S85132D Unspecified injury of anterior tibial artery, left leg, subsequent encounter',
  'S85132S Unspecified injury of anterior tibial artery, left leg, sequela',
  'S85139A Unspecified injury of anterior tibial artery, unspecified leg, initial encounter',
  'S85139D Unspecified injury of anterior tibial artery, unspecified leg, subsequent encounter',
  'S85139S Unspecified injury of anterior tibial artery, unspecified leg, sequela',
  'S85141A Laceration of anterior tibial artery, right leg, initial encounter',
  'S85141D Laceration of anterior tibial artery, right leg, subsequent encounter',
  'S85141S Laceration of anterior tibial artery, right leg, sequela',
  'S85142A Laceration of anterior tibial artery, left leg, initial encounter',
  'S85142D Laceration of anterior tibial artery, left leg, subsequent encounter',
  'S85142S Laceration of anterior tibial artery, left leg, sequela',
  'S85149A Laceration of anterior tibial artery, unspecified leg, initial encounter',
  'S85149D Laceration of anterior tibial artery, unspecified leg, subsequent encounter',
  'S85149S Laceration of anterior tibial artery, unspecified leg, sequela',
  'S85151A Other specified injury of anterior tibial artery, right leg, initial encounter',
  'S85151D Other specified injury of anterior tibial artery, right leg, subsequent encounter',
  'S85151S Other specified injury of anterior tibial artery, right leg, sequela',
  'S85152A Other specified injury of anterior tibial artery, left leg, initial encounter',
  'S85152D Other specified injury of anterior tibial artery, left leg, subsequent encounter',
  'S85152S Other specified injury of anterior tibial artery, left leg, sequela',
  'S85159A Other specified injury of anterior tibial artery, unspecified leg, initial encounter',
  'S85159D Other specified injury of anterior tibial artery, unspecified leg, subsequent encounter',
  'S85159S Other specified injury of anterior tibial artery, unspecified leg, sequela',
  'S85161A Unspecified injury of posterior tibial artery, right leg, initial encounter',
  'S85161D Unspecified injury of posterior tibial artery, right leg, subsequent encounter',
  'S85161S Unspecified injury of posterior tibial artery, right leg, sequela',
  'S85162A Unspecified injury of posterior tibial artery, left leg, initial encounter',
  'S85162D Unspecified injury of posterior tibial artery, left leg, subsequent encounter',
  'S85162S Unspecified injury of posterior tibial artery, left leg, sequela',
  'S85169A Unspecified injury of posterior tibial artery, unspecified leg, initial encounter',
  'S85169D Unspecified injury of posterior tibial artery, unspecified leg, subsequent encounter',
  'S85169S Unspecified injury of posterior tibial artery, unspecified leg, sequela',
  'S85171A Laceration of posterior tibial artery, right leg, initial encounter',
  'S85171D Laceration of posterior tibial artery, right leg, subsequent encounter',
  'S85171S Laceration of posterior tibial artery, right leg, sequela',
  'S85172A Laceration of posterior tibial artery, left leg, initial encounter',
  'S85172D Laceration of posterior tibial artery, left leg, subsequent encounter',
  'S85172S Laceration of posterior tibial artery, left leg, sequela',
  'S85179A Laceration of posterior tibial artery, unspecified leg, initial encounter',
  'S85179D Laceration of posterior tibial artery, unspecified leg, subsequent encounter',
  'S85179S Laceration of posterior tibial artery, unspecified leg, sequela',
  'S85181A Other specified injury of posterior tibial artery, right leg, initial encounter',
  'S85181D Other specified injury of posterior tibial artery, right leg, subsequent encounter',
  'S85181S Other specified injury of posterior tibial artery, right leg, sequela',
  'S85182A Other specified injury of posterior tibial artery, left leg, initial encounter',
  'S85182D Other specified injury of posterior tibial artery, left leg, subsequent encounter',
  'S85182S Other specified injury of posterior tibial artery, left leg, sequela',
  'S85189A Other specified injury of posterior tibial artery, unspecified leg, initial encounter',
  'S85189D Other specified injury of posterior tibial artery, unspecified leg, subsequent encounter',
  'S85189S Other specified injury of posterior tibial artery, unspecified leg, sequela',
  'S85201A Unspecified injury of peroneal artery, right leg, initial encounter',
  'S85201D Unspecified injury of peroneal artery, right leg, subsequent encounter',
  'S85201S Unspecified injury of peroneal artery, right leg, sequela',
  'S85202A Unspecified injury of peroneal artery, left leg, initial encounter',
  'S85202D Unspecified injury of peroneal artery, left leg, subsequent encounter',
  'S85202S Unspecified injury of peroneal artery, left leg, sequela',
  'S85209A Unspecified injury of peroneal artery, unspecified leg, initial encounter',
  'S85209D Unspecified injury of peroneal artery, unspecified leg, subsequent encounter',
  'S85209S Unspecified injury of peroneal artery, unspecified leg, sequela',
  'S85211A Laceration of peroneal artery, right leg, initial encounter',
  'S85211D Laceration of peroneal artery, right leg, subsequent encounter',
  'S85211S Laceration of peroneal artery, right leg, sequela',
  'S85212A Laceration of peroneal artery, left leg, initial encounter',
  'S85212D Laceration of peroneal artery, left leg, subsequent encounter',
  'S85212S Laceration of peroneal artery, left leg, sequela',
  'S85219A Laceration of peroneal artery, unspecified leg, initial encounter',
  'S85219D Laceration of peroneal artery, unspecified leg, subsequent encounter',
  'S85219S Laceration of peroneal artery, unspecified leg, sequela',
  'S85291A Other specified injury of peroneal artery, right leg, initial encounter',
  'S85291D Other specified injury of peroneal artery, right leg, subsequent encounter',
  'S85291S Other specified injury of peroneal artery, right leg, sequela',
  'S85292A Other specified injury of peroneal artery, left leg, initial encounter',
  'S85292D Other specified injury of peroneal artery, left leg, subsequent encounter',
  'S85292S Other specified injury of peroneal artery, left leg, sequela',
  'S85299A Other specified injury of peroneal artery, unspecified leg, initial encounter',
  'S85299D Other specified injury of peroneal artery, unspecified leg, subsequent encounter',
  'S85299S Other specified injury of peroneal artery, unspecified leg, sequela',
  'S85301A Unspecified injury of greater saphenous vein at lower leg level, right leg, initial encounter',
  'S85301D Unspecified injury of greater saphenous vein at lower leg level, right leg, subsequent',
  'encounter',
  'S85301S Unspecified injury of greater saphenous vein at lower leg level, right leg, sequela',
  'S85302A Unspecified injury of greater saphenous vein at lower leg level, left leg, initial encounter',
  'S85302D Unspecified injury of greater saphenous vein at lower leg level, left leg, subsequent encounter',
  'S85302S Unspecified injury of greater saphenous vein at lower leg level, left leg, sequela',
  'S85309A Unspecified injury of greater saphenous vein at lower leg level, unspecified leg, initial',
  'encounter',
  'S85309D Unspecified injury of greater saphenous vein at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85309S Unspecified injury of greater saphenous vein at lower leg level, unspecified leg, sequela',
  'S85311A Laceration of greater saphenous vein at lower leg level, right leg, initial encounter',
  'S85311D Laceration of greater saphenous vein at lower leg level, right leg, subsequent encounter',
  'S85311S Laceration of greater saphenous vein at lower leg level, right leg, sequela',
  'S85312A Laceration of greater saphenous vein at lower leg level, left leg, initial encounter',
  'S85312D Laceration of greater saphenous vein at lower leg level, left leg, subsequent encounter',
  'S85312S Laceration of greater saphenous vein at lower leg level, left leg, sequela',
  'S85319A Laceration of greater saphenous vein at lower leg level, unspecified leg, initial encounter',
  'S85319D Laceration of greater saphenous vein at lower leg level, unspecified leg, subsequent encounter',
  'S85319S Laceration of greater saphenous vein at lower leg level, unspecified leg, sequela',
  'S85391A Other specified injury of greater saphenous vein at lower leg level, right leg, initial encounter',
  'S85391D Other specified injury of greater saphenous vein at lower leg level, right leg, subsequent',
  'encounter',
  'S85391S Other specified injury of greater saphenous vein at lower leg level, right leg, sequela',
  'S85392A Other specified injury of greater saphenous vein at lower leg level, left leg, initial encounter',
  'S85392D Other specified injury of greater saphenous vein at lower leg level, left leg, subsequent',
  'encounter',
  'S85392S Other specified injury of greater saphenous vein at lower leg level, left leg, sequela',
  'S85399A Other specified injury of greater saphenous vein at lower leg level, unspecified leg, initial',
  'encounter',
  'S85399D Other specified injury of greater saphenous vein at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85399S Other specified injury of greater saphenous vein at lower leg level, unspecified leg, sequela',
  'S85401A Unspecified injury of lesser saphenous vein at lower leg level, right leg, initial encounter',
  'S85401D Unspecified injury of lesser saphenous vein at lower leg level, right leg, subsequent encounter',
  'S85401S Unspecified injury of lesser saphenous vein at lower leg level, right leg, sequela',
  'S85402A Unspecified injury of lesser saphenous vein at lower leg level, left leg, initial encounter',
  'S85402D Unspecified injury of lesser saphenous vein at lower leg level, left leg, subsequent encounter',
  'S85402S Unspecified injury of lesser saphenous vein at lower leg level, left leg, sequela',
  'S85409A Unspecified injury of lesser saphenous vein at lower leg level, unspecified leg, initial encounter',
  'S85409D Unspecified injury of lesser saphenous vein at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85409S Unspecified injury of lesser saphenous vein at lower leg level, unspecified leg, sequela',
  'S85411A Laceration of lesser saphenous vein at lower leg level, right leg, initial encounter',
  'S85411D Laceration of lesser saphenous vein at lower leg level, right leg, subsequent encounter',
  'S85411S Laceration of lesser saphenous vein at lower leg level, right leg, sequela',
  'S85412A Laceration of lesser saphenous vein at lower leg level, left leg, initial encounter',
  'S85412D Laceration of lesser saphenous vein at lower leg level, left leg, subsequent encounter',
  'S85412S Laceration of lesser saphenous vein at lower leg level, left leg, sequela',
  'S85419A Laceration of lesser saphenous vein at lower leg level, unspecified leg, initial encounter',
  'S85419D Laceration of lesser saphenous vein at lower leg level, unspecified leg, subsequent encounter',
  'S85419S Laceration of lesser saphenous vein at lower leg level, unspecified leg, sequela',
  'S85491A Other specified injury of lesser saphenous vein at lower leg level, right leg, initial encounter',
  'S85491D Other specified injury of lesser saphenous vein at lower leg level, right leg, subsequent',
  'encounter',
  'S85491S Other specified injury of lesser saphenous vein at lower leg level, right leg, sequela',
  'S85492A Other specified injury of lesser saphenous vein at lower leg level, left leg, initial encounter',
  'S85492D Other specified injury of lesser saphenous vein at lower leg level, left leg, subsequent',
  'encounter',
  'S85492S Other specified injury of lesser saphenous vein at lower leg level, left leg, sequela',
  'S85499A Other specified injury of lesser saphenous vein at lower leg level, unspecified leg, initial',
  'encounter',
  'S85499D Other specified injury of lesser saphenous vein at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85499S Other specified injury of lesser saphenous vein at lower leg level, unspecified leg, sequela',
  'S85501A Unspecified injury of popliteal vein, right leg, initial encounter',
  'S85501D Unspecified injury of popliteal vein, right leg, subsequent encounter',
  'S85501S Unspecified injury of popliteal vein, right leg, sequela',
  'S85502A Unspecified injury of popliteal vein, left leg, initial encounter',
  'S85502D Unspecified injury of popliteal vein, left leg, subsequent encounter',
  'S85502S Unspecified injury of popliteal vein, left leg, sequela',
  'S85509A Unspecified injury of popliteal vein, unspecified leg, initial encounter',
  'S85509D Unspecified injury of popliteal vein, unspecified leg, subsequent encounter',
  'S85509S Unspecified injury of popliteal vein, unspecified leg, sequela',
  'S85511A Laceration of popliteal vein, right leg, initial encounter',
  'S85511D Laceration of popliteal vein, right leg, subsequent encounter',
  'S85511S Laceration of popliteal vein, right leg, sequela',
  'S85512A Laceration of popliteal vein, left leg, initial encounter',
  'S85512D Laceration of popliteal vein, left leg, subsequent encounter',
  'S85512S Laceration of popliteal vein, left leg, sequela',
  'S85519A Laceration of popliteal vein, unspecified leg, initial encounter',
  'S85519D Laceration of popliteal vein, unspecified leg, subsequent encounter',
  'S85519S Laceration of popliteal vein, unspecified leg, sequela',
  'S85591A Other specified injury of popliteal vein, right leg, initial encounter',
  'S85591D Other specified injury of popliteal vein, right leg, subsequent encounter',
  'S85591S Other specified injury of popliteal vein, right leg, sequela',
  'S85592A Other specified injury of popliteal vein, left leg, initial encounter',
  'S85592D Other specified injury of popliteal vein, left leg, subsequent encounter',
  'S85592S Other specified injury of popliteal vein, left leg, sequela',
  'S85599A Other specified injury of popliteal vein, unspecified leg, initial encounter',
  'S85599D Other specified injury of popliteal vein, unspecified leg, subsequent encounter',
  'S85599S Other specified injury of popliteal vein, unspecified leg, sequela',
  'S85801A Unspecified injury of other blood vessels at lower leg level, right leg, initial encounter',
  'S85801D Unspecified injury of other blood vessels at lower leg level, right leg, subsequent encounter',
  'S85801S Unspecified injury of other blood vessels at lower leg level, right leg, sequela',
  'S85802A Unspecified injury of other blood vessels at lower leg level, left leg, initial encounter',
  'S85802D Unspecified injury of other blood vessels at lower leg level, left leg, subsequent encounter',
  'S85802S Unspecified injury of other blood vessels at lower leg level, left leg, sequela',
  'S85809A Unspecified injury of other blood vessels at lower leg level, unspecified leg, initial encounter',
  'S85809D Unspecified injury of other blood vessels at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85809S Unspecified injury of other blood vessels at lower leg level, unspecified leg, sequela',
  'S85811A Laceration of other blood vessels at lower leg level, right leg, initial encounter',
  'S85811D Laceration of other blood vessels at lower leg level, right leg, subsequent encounter',
  'S85811S Laceration of other blood vessels at lower leg level, right leg, sequela',
  'S85812A Laceration of other blood vessels at lower leg level, left leg, initial encounter',
  'S85812D Laceration of other blood vessels at lower leg level, left leg, subsequent encounter',
  'S85812S Laceration of other blood vessels at lower leg level, left leg, sequela',
  'S85819A Laceration of other blood vessels at lower leg level, unspecified leg, initial encounter',
  'S85819D Laceration of other blood vessels at lower leg level, unspecified leg, subsequent encounter',
  'S85819S Laceration of other blood vessels at lower leg level, unspecified leg, sequela',
  'S85891A Other specified injury of other blood vessels at lower leg level, right leg, initial encounter',
  'S85891D Other specified injury of other blood vessels at lower leg level, right leg, subsequent encounter',
  'S85891S Other specified injury of other blood vessels at lower leg level, right leg, sequela',
  'S85892A Other specified injury of other blood vessels at lower leg level, left leg, initial encounter',
  'S85892D Other specified injury of other blood vessels at lower leg level, left leg, subsequent encounter',
  'S85892S Other specified injury of other blood vessels at lower leg level, left leg, sequela',
  'S85899A Other specified injury of other blood vessels at lower leg level, unspecified leg, initial',
  'encounter',
  'S85899D Other specified injury of other blood vessels at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85899S Other specified injury of other blood vessels at lower leg level, unspecified leg, sequela',
  'S85901A Unspecified injury of unspecified blood vessel at lower leg level, right leg, initial encounter',
  'S85901D Unspecified injury of unspecified blood vessel at lower leg level, right leg, subsequent',
  'encounter',
  'S85901S Unspecified injury of unspecified blood vessel at lower leg level, right leg, sequela',
  'S85902A Unspecified injury of unspecified blood vessel at lower leg level, left leg, initial encounter',
  'S85902D Unspecified injury of unspecified blood vessel at lower leg level, left leg, subsequent encounter',
  'S85902S Unspecified injury of unspecified blood vessel at lower leg level, left leg, sequela',
  'S85909A Unspecified injury of unspecified blood vessel at lower leg level, unspecified leg, initial',
  'encounter',
  'S85909D Unspecified injury of unspecified blood vessel at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85909S Unspecified injury of unspecified blood vessel at lower leg level, unspecified leg, sequela',
  'S85911A Laceration of unspecified blood vessel at lower leg level, right leg, initial encounter',
  'S85911D Laceration of unspecified blood vessel at lower leg level, right leg, subsequent encounter',
  'S85911S Laceration of unspecified blood vessel at lower leg level, right leg, sequela',
  'S85912A Laceration of unspecified blood vessel at lower leg level, left leg, initial encounter',
  'S85912D Laceration of unspecified blood vessel at lower leg level, left leg, subsequent encounter',
  'S85912S Laceration of unspecified blood vessel at lower leg level, left leg, sequela',
  'S85919A Laceration of unspecified blood vessel at lower leg level, unspecified leg, initial encounter',
  'S85919D Laceration of unspecified blood vessel at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S85919S Laceration of unspecified blood vessel at lower leg level, unspecified leg, sequela',
  'S85991A Other specified injury of unspecified blood vessel at lower leg level, right leg, initial encounter',
  'S85991D Other specified injury of unspecified blood vessel at lower leg level, right leg, subsequent',
  'encounter',
  'S85991S Other specified injury of unspecified blood vessel at lower leg level, right leg, sequela',
  'S85992A Other specified injury of unspecified blood vessel at lower leg level, left leg, initial encounter',
  'S85992D Other specified injury of unspecified blood vessel at lower leg level, left leg, subsequent',
  'encounter',
  'S85992S Other specified injury of unspecified blood vessel at lower leg level, left leg, sequela',
  'S85999A Other specified injury of unspecified blood vessel at lower leg level, unspecified leg, initial',
  'encounter',
  'S85999D Other specified injury of unspecified blood vessel at lower leg level, unspecified leg,',
  'subsequent encounter',
  'S85999S Other specified injury of unspecified blood vessel at lower leg level, unspecified leg, sequela',
  'S86001A Unspecified injury of right Achilles tendon, initial encounter',
  'S86001D Unspecified injury of right Achilles tendon, subsequent encounter',
  'S86001S Unspecified injury of right Achilles tendon, sequela',
  'S86002A Unspecified injury of left Achilles tendon, initial encounter',
  'S86002D Unspecified injury of left Achilles tendon, subsequent encounter',
  'S86002S Unspecified injury of left Achilles tendon, sequela',
  'S86009A Unspecified injury of unspecified Achilles tendon, initial encounter',
  'S86009D Unspecified injury of unspecified Achilles tendon, subsequent encounter',
  'S86009S Unspecified injury of unspecified Achilles tendon, sequela',
  'S86011A Strain of right Achilles tendon, initial encounter',
  'S86011D Strain of right Achilles tendon, subsequent encounter',
  'S86011S Strain of right Achilles tendon, sequela',
  'S86012A Strain of left Achilles tendon, initial encounter',
  'S86012D Strain of left Achilles tendon, subsequent encounter',
  'S86012S Strain of left Achilles tendon, sequela',
  'S86019A Strain of unspecified Achilles tendon, initial encounter',
  'S86019D Strain of unspecified Achilles tendon, subsequent encounter',
  'S86019S Strain of unspecified Achilles tendon, sequela',
  'S86021A Laceration of right Achilles tendon, initial encounter',
  'S86021D Laceration of right Achilles tendon, subsequent encounter',
  'S86021S Laceration of right Achilles tendon, sequela',
  'S86022A Laceration of left Achilles tendon, initial encounter',
  'S86022D Laceration of left Achilles tendon, subsequent encounter',
  'S86022S Laceration of left Achilles tendon, sequela',
  'S86029A Laceration of unspecified Achilles tendon, initial encounter',
  'S86029D Laceration of unspecified Achilles tendon, subsequent encounter',
  'S86029S Laceration of unspecified Achilles tendon, sequela',
  'S86091A Other specified injury of right Achilles tendon, initial encounter',
  'S86091D Other specified injury of right Achilles tendon, subsequent encounter',
  'S86091S Other specified injury of right Achilles tendon, sequela',
  'S86092A Other specified injury of left Achilles tendon, initial encounter',
  'S86092D Other specified injury of left Achilles tendon, subsequent encounter',
  'S86092S Other specified injury of left Achilles tendon, sequela',
  'S86099A Other specified injury of unspecified Achilles tendon, initial encounter',
  'S86099D Other specified injury of unspecified Achilles tendon, subsequent encounter',
  'S86099S Other specified injury of unspecified Achilles tendon, sequela',
  'S86101A Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, right leg, initial encounter',
  'S86101D Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, right leg, subsequent encounter',
  'S86101S Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, right leg, sequela',
  'S86102A Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, left leg, initial encounter',
  'S86102D Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, left leg, subsequent encounter',
  'S86102S Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, left leg, sequela',
  'S86109A Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, unspecified leg, initial encounter',
  'S86109D Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, unspecified leg, subsequent encounter',
  'S86109S Unspecified injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg',
  'level, unspecified leg, sequela',
  'S86111A Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right leg,',
  'initial encounter',
  'S86111D Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right leg,',
  'subsequent encounter',
  'S86111S Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right leg,',
  'sequela',
  'S86112A Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left leg,',
  'initial encounter',
  'S86112D Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left leg,',
  'subsequent encounter',
  'S86112S Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left leg,',
  'sequela',
  'S86119A Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, initial encounter',
  'S86119D Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, subsequent encounter',
  'S86119S Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, sequela',
  'S86121A Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right',
  'leg, initial encounter',
  'S86121D Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right',
  'leg, subsequent encounter',
  'S86121S Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right',
  'leg, sequela',
  'S86122A Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left',
  'leg, initial encounter',
  'S86122D Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left',
  'leg, subsequent encounter',
  'S86122S Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left',
  'leg, sequela',
  'S86129A Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, initial encounter',
  'S86129D Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, subsequent encounter',
  'S86129S Laceration of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, sequela',
  'S86191A Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right',
  'leg, initial encounter',
  'S86191D Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right',
  'leg, subsequent encounter',
  'S86191S Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, right',
  'leg, sequela',
  'S86192A Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left',
  'leg, initial encounter',
  'S86192D Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left',
  'leg, subsequent encounter',
  'S86192S Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left',
  'leg, sequela',
  'S86199A Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, initial encounter',
  'S86199D Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, subsequent encounter',
  'S86199S Other injury of other muscle(s) and tendon(s) of posterior muscle group at lower leg level,',
  'unspecified leg, sequela',
  'S86201A Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right',
  'leg, initial encounter',
  'S86201D Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right',
  'leg, subsequent encounter',
  'S86201S Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right',
  'leg, sequela',
  'S86202A Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left',
  'leg, initial encounter',
  'S86202D Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left',
  'leg, subsequent encounter',
  'S86202S Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left',
  'leg, sequela',
  'S86209A Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level,',
  'unspecified leg, initial encounter',
  'S86209D Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level,',
  'unspecified leg, subsequent encounter',
  'S86209S Unspecified injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level,',
  'unspecified leg, sequela',
  'S86211A Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg, initial',
  'encounter',
  'S86211D Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg,',
  'subsequent encounter',
  'S86211S Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg, sequela',
  'S86212A Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg, initial',
  'encounter',
  'S86212D Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg,',
  'subsequent encounter',
  'S86212S Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg, sequela',
  'S86219A Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified leg,',
  'initial encounter',
  'S86219D Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified leg,',
  'subsequent encounter',
  'S86219S Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified leg,',
  'sequela',
  'S86221A Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg,',
  'initial encounter',
  'S86221D Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg,',
  'subsequent encounter',
  'S86221S Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg,',
  'sequela',
  'S86222A Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg,',
  'initial encounter',
  'S86222D Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg,',
  'subsequent encounter',
  'S86222S Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg,',
  'sequela',
  'S86229A Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified',
  'leg, initial encounter',
  'S86229D Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified',
  'leg, subsequent encounter',
  'S86229S Laceration of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified',
  'leg, sequela',
  'S86291A Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg,',
  'initial encounter',
  'S86291D Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg,',
  'subsequent encounter',
  'S86291S Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, right leg,',
  'sequela',
  'S86292A Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg,',
  'initial encounter',
  'S86292D Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg,',
  'subsequent encounter',
  'S86292S Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg,',
  'sequela',
  'S86299A Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified',
  'leg, initial encounter',
  'S86299D Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified',
  'leg, subsequent encounter',
  'S86299S Other injury of muscle(s) and tendon(s) of anterior muscle group at lower leg level, unspecified',
  'leg, sequela',
  'S86301A Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right',
  'leg, initial encounter',
  'S86301D Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right',
  'leg, subsequent encounter',
  'S86301S Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right',
  'leg, sequela',
  'S86302A Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left',
  'leg, initial encounter',
  'S86302D Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left',
  'leg, subsequent encounter',
  'S86302S Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left',
  'leg, sequela',
  'S86309A Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level,',
  'unspecified leg, initial encounter',
  'S86309D Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level,',
  'unspecified leg, subsequent encounter',
  'S86309S Unspecified injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level,',
  'unspecified leg, sequela',
  'S86311A Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg, initial',
  'encounter',
  'S86311D Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg,',
  'subsequent encounter',
  'S86311S Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg, sequela',
  'S86312A Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg, initial',
  'encounter',
  'S86312D Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg,',
  'subsequent encounter',
  'S86312S Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg, sequela',
  'S86319A Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, unspecified leg,',
  'initial encounter',
  'S86319D Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, unspecified leg,',
  'subsequent encounter',
  'S86319S Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, unspecified leg,',
  'sequela',
  'S86321A Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg,',
  'initial encounter',
  'S86321D Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg,',
  'subsequent encounter',
  'S86321S Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg,',
  'sequela',
  'S86322A Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg,',
  'initial encounter',
  'S86322D Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg,',
  'subsequent encounter',
  'S86322S Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg,',
  'sequela',
  'S86329A Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, unspecified',
  'leg, initial encounter',
  'S86329D Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, unspecified',
  'leg, subsequent encounter',
  'S86329S Laceration of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, unspecified',
  'leg, sequela',
  'S86391A Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg,',
  'initial encounter',
  'S86391D Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg,',
  'subsequent encounter',
  'S86391S Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, right leg,',
  'sequela',
  'S86392A Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg,',
  'initial encounter',
  'S86392D Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg,',
  'subsequent encounter',
  'S86392S Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg,',
  'sequela',
  'S86399A Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level,',
  'unspecified leg, initial encounter',
  'S86399D Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level,',
  'unspecified leg, subsequent encounter',
  'S86399S Other injury of muscle(s) and tendon(s) of peroneal muscle group at lower leg level,',
  'unspecified leg, sequela',
  'S86801A Unspecified injury of other muscle(s) and tendon(s) at lower leg level, right leg, initial',
  'encounter',
  'S86801D Unspecified injury of other muscle(s) and tendon(s) at lower leg level, right leg, subsequent',
  'encounter',
  'S86801S Unspecified injury of other muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86802A Unspecified injury of other muscle(s) and tendon(s) at lower leg level, left leg, initial encounter',
  'S86802D Unspecified injury of other muscle(s) and tendon(s) at lower leg level, left leg, subsequent',
  'encounter',
  'S86802S Unspecified injury of other muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86809A Unspecified injury of other muscle(s) and tendon(s) at lower leg level, unspecified leg, initial',
  'encounter',
  'S86809D Unspecified injury of other muscle(s) and tendon(s) at lower leg level, unspecified leg,',
  'subsequent encounter',
  'S86809S Unspecified injury of other muscle(s) and tendon(s) at lower leg level, unspecified leg, sequela',
  'S86811A Strain of other muscle(s) and tendon(s) at lower leg level, right leg, initial encounter',
  'S86811D Strain of other muscle(s) and tendon(s) at lower leg level, right leg, subsequent encounter',
  'S86811S Strain of other muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86812A Strain of other muscle(s) and tendon(s) at lower leg level, left leg, initial encounter',
  'S86812D Strain of other muscle(s) and tendon(s) at lower leg level, left leg, subsequent encounter',
  'S86812S Strain of other muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86819A Strain of other muscle(s) and tendon(s) at lower leg level, unspecified leg, initial encounter',
  'S86819D Strain of other muscle(s) and tendon(s) at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S86819S Strain of other muscle(s) and tendon(s) at lower leg level, unspecified leg, sequela',
  'S86821A Laceration of other muscle(s) and tendon(s) at lower leg level, right leg, initial encounter',
  'S86821D Laceration of other muscle(s) and tendon(s) at lower leg level, right leg, subsequent encounter',
  'S86821S Laceration of other muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86822A Laceration of other muscle(s) and tendon(s) at lower leg level, left leg, initial encounter',
  'S86822D Laceration of other muscle(s) and tendon(s) at lower leg level, left leg, subsequent encounter',
  'S86822S Laceration of other muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86829A Laceration of other muscle(s) and tendon(s) at lower leg level, unspecified leg, initial encounter',
  'S86829D Laceration of other muscle(s) and tendon(s) at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S86829S Laceration of other muscle(s) and tendon(s) at lower leg level, unspecified leg, sequela',
  'S86891A Other injury of other muscle(s) and tendon(s) at lower leg level, right leg, initial encounter',
  'S86891D Other injury of other muscle(s) and tendon(s) at lower leg level, right leg, subsequent',
  'encounter',
  'S86891S Other injury of other muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86892A Other injury of other muscle(s) and tendon(s) at lower leg level, left leg, initial encounter',
  'S86892D Other injury of other muscle(s) and tendon(s) at lower leg level, left leg, subsequent encounter',
  'S86892S Other injury of other muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86899A Other injury of other muscle(s) and tendon(s) at lower leg level, unspecified leg, initial',
  'encounter',
  'S86899D Other injury of other muscle(s) and tendon(s) at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S86899S Other injury of other muscle(s) and tendon(s) at lower leg level, unspecified leg, sequela',
  'S86901A Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, right leg, initial',
  'encounter',
  'S86901D Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, right leg,',
  'subsequent encounter',
  'S86901S Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86902A Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, left leg, initial',
  'encounter',
  'S86902D Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, left leg,',
  'subsequent encounter',
  'S86902S Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86909A Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg,',
  'initial encounter',
  'S86909D Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg,',
  'subsequent encounter',
  'S86909S Unspecified injury of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg,',
  'sequela',
  'S86911A Strain of unspecified muscle(s) and tendon(s) at lower leg level, right leg, initial encounter',
  'S86911D Strain of unspecified muscle(s) and tendon(s) at lower leg level, right leg, subsequent',
  'encounter',
  'S86911S Strain of unspecified muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86912A Strain of unspecified muscle(s) and tendon(s) at lower leg level, left leg, initial encounter',
  'S86912D Strain of unspecified muscle(s) and tendon(s) at lower leg level, left leg, subsequent encounter',
  'S86912S Strain of unspecified muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86919A Strain of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg, initial',
  'encounter',
  'S86919D Strain of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg, subsequent',
  'encounter',
  'S86919S Strain of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg, sequela',
  'S86921A Laceration of unspecified muscle(s) and tendon(s) at lower leg level, right leg, initial encounter',
  'S86921D Laceration of unspecified muscle(s) and tendon(s) at lower leg level, right leg, subsequent',
  'encounter',
  'S86921S Laceration of unspecified muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86922A Laceration of unspecified muscle(s) and tendon(s) at lower leg level, left leg, initial encounter',
  'S86922D Laceration of unspecified muscle(s) and tendon(s) at lower leg level, left leg, subsequent',
  'encounter',
  'S86922S Laceration of unspecified muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86929A Laceration of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg, initial',
  'encounter',
  'S86929D Laceration of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg,',
  'subsequent encounter',
  'S86929S Laceration of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg, sequela',
  'S86991A Other injury of unspecified muscle(s) and tendon(s) at lower leg level, right leg, initial',
  'encounter',
  'S86991D Other injury of unspecified muscle(s) and tendon(s) at lower leg level, right leg, subsequent',
  'encounter',
  'S86991S Other injury of unspecified muscle(s) and tendon(s) at lower leg level, right leg, sequela',
  'S86992A Other injury of unspecified muscle(s) and tendon(s) at lower leg level, left leg, initial encounter',
  'S86992D Other injury of unspecified muscle(s) and tendon(s) at lower leg level, left leg, subsequent',
  'encounter',
  'S86992S Other injury of unspecified muscle(s) and tendon(s) at lower leg level, left leg, sequela',
  'S86999A Other injury of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg, initial',
  'encounter',
  'S86999D Other injury of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg,',
  'subsequent encounter',
  'S86999S Other injury of unspecified muscle(s) and tendon(s) at lower leg level, unspecified leg, sequela',
  'S8700XA Crushing injury of unspecified knee, initial encounter',
  'S8700XD Crushing injury of unspecified knee, subsequent encounter',
  'S8700XS Crushing injury of unspecified knee, sequela',
  'S8701XA Crushing injury of right knee, initial encounter',
  'S8701XD Crushing injury of right knee, subsequent encounter',
  'S8701XS Crushing injury of right knee, sequela',
  'S8702XA Crushing injury of left knee, initial encounter',
  'S8702XD Crushing injury of left knee, subsequent encounter',
  'S8702XS Crushing injury of left knee, sequela',
  'S8780XA Crushing injury of unspecified lower leg, initial encounter',
  'S8780XD Crushing injury of unspecified lower leg, subsequent encounter',
  'S8780XS Crushing injury of unspecified lower leg, sequela',
  'S8781XA Crushing injury of right lower leg, initial encounter',
  'S8781XD Crushing injury of right lower leg, subsequent encounter',
  'S8781XS Crushing injury of right lower leg, sequela',
  'S8782XA Crushing injury of left lower leg, initial encounter',
  'S8782XD Crushing injury of left lower leg, subsequent encounter',
  'S8782XS Crushing injury of left lower leg, sequela',
  'S88011A Complete traumatic amputation at knee level, right lower leg, initial encounter',
  'S88011D Complete traumatic amputation at knee level, right lower leg, subsequent encounter',
  'S88011S Complete traumatic amputation at knee level, right lower leg, sequela',
  'S88012A Complete traumatic amputation at knee level, left lower leg, initial encounter',
  'S88012D Complete traumatic amputation at knee level, left lower leg, subsequent encounter',
  'S88012S Complete traumatic amputation at knee level, left lower leg, sequela',
  'S88019A Complete traumatic amputation at knee level, unspecified lower leg, initial encounter',
  'S88019D Complete traumatic amputation at knee level, unspecified lower leg, subsequent encounter',
  'S88019S Complete traumatic amputation at knee level, unspecified lower leg, sequela',
  'S88021A Partial traumatic amputation at knee level, right lower leg, initial encounter',
  'S88021D Partial traumatic amputation at knee level, right lower leg, subsequent encounter',
  'S88021S Partial traumatic amputation at knee level, right lower leg, sequela',
  'S88022A Partial traumatic amputation at knee level, left lower leg, initial encounter',
  'S88022D Partial traumatic amputation at knee level, left lower leg, subsequent encounter',
  'S88022S Partial traumatic amputation at knee level, left lower leg, sequela',
  'S88029A Partial traumatic amputation at knee level, unspecified lower leg, initial encounter',
  'S88029D Partial traumatic amputation at knee level, unspecified lower leg, subsequent encounter',
  'S88029S Partial traumatic amputation at knee level, unspecified lower leg, sequela',
  'S88111A Complete traumatic amputation at level between knee and ankle, right lower leg, initial',
  'encounter',
  'S88111D Complete traumatic amputation at level between knee and ankle, right lower leg, subsequent',
  'encounter',
  'S88111S Complete traumatic amputation at level between knee and ankle, right lower leg, sequela',
  'S88112A Complete traumatic amputation at level between knee and ankle, left lower leg, initial',
  'encounter',
  'S88112D Complete traumatic amputation at level between knee and ankle, left lower leg, subsequent',
  'encounter',
  'S88112S Complete traumatic amputation at level between knee and ankle, left lower leg, sequela',
  'S88119A Complete traumatic amputation at level between knee and ankle, unspecified lower leg, initial',
  'encounter',
  'S88119D Complete traumatic amputation at level between knee and ankle, unspecified lower leg,',
  'subsequent encounter',
  'S88119S Complete traumatic amputation at level between knee and ankle, unspecified lower leg,',
  'sequela',
  'S88121A Partial traumatic amputation at level between knee and ankle, right lower leg, initial encounter',
  'S88121D Partial traumatic amputation at level between knee and ankle, right lower leg, subsequent',
  'encounter',
  'S88121S Partial traumatic amputation at level between knee and ankle, right lower leg, sequela',
  'S88122A Partial traumatic amputation at level between knee and ankle, left lower leg, initial encounter',
  'S88122D Partial traumatic amputation at level between knee and ankle, left lower leg, subsequent',
  'encounter',
  'S88122S Partial traumatic amputation at level between knee and ankle, left lower leg, sequela',
  'S88129A Partial traumatic amputation at level between knee and ankle, unspecified lower leg, initial',
  'encounter',
  'S88129D Partial traumatic amputation at level between knee and ankle, unspecified lower leg,',
  'subsequent encounter',
  'S88129S Partial traumatic amputation at level between knee and ankle, unspecified lower leg, sequela',
  'S88911A Complete traumatic amputation of right lower leg, level unspecified, initial encounter',
  'S88911D Complete traumatic amputation of right lower leg, level unspecified, subsequent encounter',
  'S88911S Complete traumatic amputation of right lower leg, level unspecified, sequela',
  'S88912A Complete traumatic amputation of left lower leg, level unspecified, initial encounter',
  'S88912D Complete traumatic amputation of left lower leg, level unspecified, subsequent encounter',
  'S88912S Complete traumatic amputation of left lower leg, level unspecified, sequela',
  'S88919A Complete traumatic amputation of unspecified lower leg, level unspecified, initial encounter',
  'S88919D Complete traumatic amputation of unspecified lower leg, level unspecified, subsequent',
  'encounter',
  'S88919S Complete traumatic amputation of unspecified lower leg, level unspecified, sequela',
  'S88921A Partial traumatic amputation of right lower leg, level unspecified, initial encounter',
  'S88921D Partial traumatic amputation of right lower leg, level unspecified, subsequent encounter',
  'S88921S Partial traumatic amputation of right lower leg, level unspecified, sequela',
  'S88922A Partial traumatic amputation of left lower leg, level unspecified, initial encounter',
  'S88922D Partial traumatic amputation of left lower leg, level unspecified, subsequent encounter',
  'S88922S Partial traumatic amputation of left lower leg, level unspecified, sequela',
  'S88929A Partial traumatic amputation of unspecified lower leg, level unspecified, initial encounter',
  'S88929D Partial traumatic amputation of unspecified lower leg, level unspecified, subsequent encounter',
  'S88929S Partial traumatic amputation of unspecified lower leg, level unspecified, sequela',
  'S89001A Unspecified physeal fracture of upper end of right tibia, initial encounter for closed fracture',
  'S89001D Unspecified physeal fracture of upper end of right tibia, subsequent encounter for fracture',
  'with routine healing',
  'S89001G Unspecified physeal fracture of upper end of right tibia, subsequent encounter for fracture',
  'with delayed healing',
  'S89001K Unspecified physeal fracture of upper end of right tibia, subsequent encounter for fracture',
  'with nonunion',
  'S89001P Unspecified physeal fracture of upper end of right tibia, subsequent encounter for fracture',
  'with malunion',
  'S89001S Unspecified physeal fracture of upper end of right tibia, sequela',
  'S89002A Unspecified physeal fracture of upper end of left tibia, initial encounter for closed fracture',
  'S89002D Unspecified physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'routine healing',
  'S89002G Unspecified physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S89002K Unspecified physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'nonunion',
  'S89002P Unspecified physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'malunion',
  'S89002S Unspecified physeal fracture of upper end of left tibia, sequela',
  'S89009A Unspecified physeal fracture of upper end of unspecified tibia, initial encounter for closed',
  'fracture',
  'S89009D Unspecified physeal fracture of upper end of unspecified tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89009G Unspecified physeal fracture of upper end of unspecified tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89009K Unspecified physeal fracture of upper end of unspecified tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89009P Unspecified physeal fracture of upper end of unspecified tibia, subsequent encounter for',
  'fracture with malunion',
  'S89009S Unspecified physeal fracture of upper end of unspecified tibia, sequela',
  'S89011A Salter-Harris Type I physeal fracture of upper end of right tibia, initial encounter for closed',
  'fracture',
  'S89011D Salter-Harris Type I physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89011G Salter-Harris Type I physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89011K Salter-Harris Type I physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89011P Salter-Harris Type I physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89011S Salter-Harris Type I physeal fracture of upper end of right tibia, sequela',
  'S89012A Salter-Harris Type I physeal fracture of upper end of left tibia, initial encounter for closed',
  'fracture',
  'S89012D Salter-Harris Type I physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89012G Salter-Harris Type I physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89012K Salter-Harris Type I physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89012P Salter-Harris Type I physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89012S Salter-Harris Type I physeal fracture of upper end of left tibia, sequela',
  'S89019A Salter-Harris Type I physeal fracture of upper end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89019D Salter-Harris Type I physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89019G Salter-Harris Type I physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89019K Salter-Harris Type I physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89019P Salter-Harris Type I physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89019S Salter-Harris Type I physeal fracture of upper end of unspecified tibia, sequela',
  'S89021A Salter-Harris Type II physeal fracture of upper end of right tibia, initial encounter for closed',
  'fracture',
  'S89021D Salter-Harris Type II physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89021G Salter-Harris Type II physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89021K Salter-Harris Type II physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89021P Salter-Harris Type II physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89021S Salter-Harris Type II physeal fracture of upper end of right tibia, sequela',
  'S89022A Salter-Harris Type II physeal fracture of upper end of left tibia, initial encounter for closed',
  'fracture',
  'S89022D Salter-Harris Type II physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89022G Salter-Harris Type II physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89022K Salter-Harris Type II physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89022P Salter-Harris Type II physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89022S Salter-Harris Type II physeal fracture of upper end of left tibia, sequela',
  'S89029A Salter-Harris Type II physeal fracture of upper end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89029D Salter-Harris Type II physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89029G Salter-Harris Type II physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89029K Salter-Harris Type II physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89029P Salter-Harris Type II physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89029S Salter-Harris Type II physeal fracture of upper end of unspecified tibia, sequela',
  'S89031A Salter-Harris Type III physeal fracture of upper end of right tibia, initial encounter for closed',
  'fracture',
  'S89031D Salter-Harris Type III physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89031G Salter-Harris Type III physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89031K Salter-Harris Type III physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89031P Salter-Harris Type III physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89031S Salter-Harris Type III physeal fracture of upper end of right tibia, sequela',
  'S89032A Salter-Harris Type III physeal fracture of upper end of left tibia, initial encounter for closed',
  'fracture',
  'S89032D Salter-Harris Type III physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89032G Salter-Harris Type III physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89032K Salter-Harris Type III physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89032P Salter-Harris Type III physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89032S Salter-Harris Type III physeal fracture of upper end of left tibia, sequela',
  'S89039A Salter-Harris Type III physeal fracture of upper end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89039D Salter-Harris Type III physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89039G Salter-Harris Type III physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89039K Salter-Harris Type III physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89039P Salter-Harris Type III physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89039S Salter-Harris Type III physeal fracture of upper end of unspecified tibia, sequela',
  'S89041A Salter-Harris Type IV physeal fracture of upper end of right tibia, initial encounter for closed',
  'fracture',
  'S89041D Salter-Harris Type IV physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89041G Salter-Harris Type IV physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89041K Salter-Harris Type IV physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89041P Salter-Harris Type IV physeal fracture of upper end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89041S Salter-Harris Type IV physeal fracture of upper end of right tibia, sequela',
  'S89042A Salter-Harris Type IV physeal fracture of upper end of left tibia, initial encounter for closed',
  'fracture',
  'S89042D Salter-Harris Type IV physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89042G Salter-Harris Type IV physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89042K Salter-Harris Type IV physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89042P Salter-Harris Type IV physeal fracture of upper end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89042S Salter-Harris Type IV physeal fracture of upper end of left tibia, sequela',
  'S89049A Salter-Harris Type IV physeal fracture of upper end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89049D Salter-Harris Type IV physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89049G Salter-Harris Type IV physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89049K Salter-Harris Type IV physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89049P Salter-Harris Type IV physeal fracture of upper end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89049S Salter-Harris Type IV physeal fracture of upper end of unspecified tibia, sequela',
  'S89091A Other physeal fracture of upper end of right tibia, initial encounter for closed fracture',
  'S89091D Other physeal fracture of upper end of right tibia, subsequent encounter for fracture with',
  'routine healing',
  'S89091G Other physeal fracture of upper end of right tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S89091K Other physeal fracture of upper end of right tibia, subsequent encounter for fracture with',
  'nonunion',
  'S89091P Other physeal fracture of upper end of right tibia, subsequent encounter for fracture with',
  'malunion',
  'S89091S Other physeal fracture of upper end of right tibia, sequela',
  'S89092A Other physeal fracture of upper end of left tibia, initial encounter for closed fracture',
  'S89092D Other physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'routine healing',
  'S89092G Other physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S89092K Other physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'nonunion',
  'S89092P Other physeal fracture of upper end of left tibia, subsequent encounter for fracture with',
  'malunion',
  'S89092S Other physeal fracture of upper end of left tibia, sequela',
  'S89099A Other physeal fracture of upper end of unspecified tibia, initial encounter for closed fracture',
  'S89099D Other physeal fracture of upper end of unspecified tibia, subsequent encounter for fracture',
  'with routine healing',
  'S89099G Other physeal fracture of upper end of unspecified tibia, subsequent encounter for fracture',
  'with delayed healing',
  'S89099K Other physeal fracture of upper end of unspecified tibia, subsequent encounter for fracture',
  'with nonunion',
  'S89099P Other physeal fracture of upper end of unspecified tibia, subsequent encounter for fracture',
  'with malunion',
  'S89099S Other physeal fracture of upper end of unspecified tibia, sequela',
  'S89101A Unspecified physeal fracture of lower end of right tibia, initial encounter for closed fracture',
  'S89101D Unspecified physeal fracture of lower end of right tibia, subsequent encounter for fracture',
  'with routine healing',
  'S89101G Unspecified physeal fracture of lower end of right tibia, subsequent encounter for fracture',
  'with delayed healing',
  'S89101K Unspecified physeal fracture of lower end of right tibia, subsequent encounter for fracture with',
  'nonunion',
  'S89101P Unspecified physeal fracture of lower end of right tibia, subsequent encounter for fracture with',
  'malunion',
  'S89101S Unspecified physeal fracture of lower end of right tibia, sequela',
  'S89102A Unspecified physeal fracture of lower end of left tibia, initial encounter for closed fracture',
  'S89102D Unspecified physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'routine healing',
  'S89102G Unspecified physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S89102K Unspecified physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'nonunion',
  'S89102P Unspecified physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'malunion',
  'S89102S Unspecified physeal fracture of lower end of left tibia, sequela',
  'S89109A Unspecified physeal fracture of lower end of unspecified tibia, initial encounter for closed',
  'fracture',
  'S89109D Unspecified physeal fracture of lower end of unspecified tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89109G Unspecified physeal fracture of lower end of unspecified tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89109K Unspecified physeal fracture of lower end of unspecified tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89109P Unspecified physeal fracture of lower end of unspecified tibia, subsequent encounter for',
  'fracture with malunion',
  'S89109S Unspecified physeal fracture of lower end of unspecified tibia, sequela',
  'S89111A Salter-Harris Type I physeal fracture of lower end of right tibia, initial encounter for closed',
  'fracture',
  'S89111D Salter-Harris Type I physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89111G Salter-Harris Type I physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89111K Salter-Harris Type I physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89111P Salter-Harris Type I physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89111S Salter-Harris Type I physeal fracture of lower end of right tibia, sequela',
  'S89112A Salter-Harris Type I physeal fracture of lower end of left tibia, initial encounter for closed',
  'fracture',
  'S89112D Salter-Harris Type I physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89112G Salter-Harris Type I physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89112K Salter-Harris Type I physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89112P Salter-Harris Type I physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89112S Salter-Harris Type I physeal fracture of lower end of left tibia, sequela',
  'S89119A Salter-Harris Type I physeal fracture of lower end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89119D Salter-Harris Type I physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89119G Salter-Harris Type I physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89119K Salter-Harris Type I physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89119P Salter-Harris Type I physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89119S Salter-Harris Type I physeal fracture of lower end of unspecified tibia, sequela',
  'S89121A Salter-Harris Type II physeal fracture of lower end of right tibia, initial encounter for closed',
  'fracture',
  'S89121D Salter-Harris Type II physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89121G Salter-Harris Type II physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89121K Salter-Harris Type II physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89121P Salter-Harris Type II physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89121S Salter-Harris Type II physeal fracture of lower end of right tibia, sequela',
  'S89122A Salter-Harris Type II physeal fracture of lower end of left tibia, initial encounter for closed',
  'fracture',
  'S89122D Salter-Harris Type II physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89122G Salter-Harris Type II physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89122K Salter-Harris Type II physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89122P Salter-Harris Type II physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89122S Salter-Harris Type II physeal fracture of lower end of left tibia, sequela',
  'S89129A Salter-Harris Type II physeal fracture of lower end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89129D Salter-Harris Type II physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89129G Salter-Harris Type II physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89129K Salter-Harris Type II physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89129P Salter-Harris Type II physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89129S Salter-Harris Type II physeal fracture of lower end of unspecified tibia, sequela',
  'S89131A Salter-Harris Type III physeal fracture of lower end of right tibia, initial encounter for closed',
  'fracture',
  'S89131D Salter-Harris Type III physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89131G Salter-Harris Type III physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89131K Salter-Harris Type III physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89131P Salter-Harris Type III physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89131S Salter-Harris Type III physeal fracture of lower end of right tibia, sequela',
  'S89132A Salter-Harris Type III physeal fracture of lower end of left tibia, initial encounter for closed',
  'fracture',
  'S89132D Salter-Harris Type III physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89132G Salter-Harris Type III physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89132K Salter-Harris Type III physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89132P Salter-Harris Type III physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89132S Salter-Harris Type III physeal fracture of lower end of left tibia, sequela',
  'S89139A Salter-Harris Type III physeal fracture of lower end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89139D Salter-Harris Type III physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89139G Salter-Harris Type III physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89139K Salter-Harris Type III physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89139P Salter-Harris Type III physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89139S Salter-Harris Type III physeal fracture of lower end of unspecified tibia, sequela',
  'S89141A Salter-Harris Type IV physeal fracture of lower end of right tibia, initial encounter for closed',
  'fracture',
  'S89141D Salter-Harris Type IV physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89141G Salter-Harris Type IV physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89141K Salter-Harris Type IV physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89141P Salter-Harris Type IV physeal fracture of lower end of right tibia, subsequent encounter for',
  'fracture with malunion',
  'S89141S Salter-Harris Type IV physeal fracture of lower end of right tibia, sequela',
  'S89142A Salter-Harris Type IV physeal fracture of lower end of left tibia, initial encounter for closed',
  'fracture',
  'S89142D Salter-Harris Type IV physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with routine healing',
  'S89142G Salter-Harris Type IV physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with delayed healing',
  'S89142K Salter-Harris Type IV physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with nonunion',
  'S89142P Salter-Harris Type IV physeal fracture of lower end of left tibia, subsequent encounter for',
  'fracture with malunion',
  'S89142S Salter-Harris Type IV physeal fracture of lower end of left tibia, sequela',
  'S89149A Salter-Harris Type IV physeal fracture of lower end of unspecified tibia, initial encounter for',
  'closed fracture',
  'S89149D Salter-Harris Type IV physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with routine healing',
  'S89149G Salter-Harris Type IV physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with delayed healing',
  'S89149K Salter-Harris Type IV physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with nonunion',
  'S89149P Salter-Harris Type IV physeal fracture of lower end of unspecified tibia, subsequent encounter',
  'for fracture with malunion',
  'S89149S Salter-Harris Type IV physeal fracture of lower end of unspecified tibia, sequela',
  'S89191A Other physeal fracture of lower end of right tibia, initial encounter for closed fracture',
  'S89191D Other physeal fracture of lower end of right tibia, subsequent encounter for fracture with',
  'routine healing',
  'S89191G Other physeal fracture of lower end of right tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S89191K Other physeal fracture of lower end of right tibia, subsequent encounter for fracture with',
  'nonunion',
  'S89191P Other physeal fracture of lower end of right tibia, subsequent encounter for fracture with',
  'malunion',
  'S89191S Other physeal fracture of lower end of right tibia, sequela',
  'S89192A Other physeal fracture of lower end of left tibia, initial encounter for closed fracture',
  'S89192D Other physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'routine healing',
  'S89192G Other physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'delayed healing',
  'S89192K Other physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'nonunion',
  'S89192P Other physeal fracture of lower end of left tibia, subsequent encounter for fracture with',
  'malunion',
  'S89192S Other physeal fracture of lower end of left tibia, sequela',
  'S89199A Other physeal fracture of lower end of unspecified tibia, initial encounter for closed fracture',
  'S89199D Other physeal fracture of lower end of unspecified tibia, subsequent encounter for fracture',
  'with routine healing',
  'S89199G Other physeal fracture of lower end of unspecified tibia, subsequent encounter for fracture',
  'with delayed healing',
  'S89199K Other physeal fracture of lower end of unspecified tibia, subsequent encounter for fracture',
  'with nonunion',
  'S89199P Other physeal fracture of lower end of unspecified tibia, subsequent encounter for fracture',
  'with malunion',
  'S89199S Other physeal fracture of lower end of unspecified tibia, sequela',
  'S89201A Unspecified physeal fracture of upper end of right fibula, initial encounter for closed fracture',
  'S89201D Unspecified physeal fracture of upper end of right fibula, subsequent encounter for fracture',
  'with routine healing',
  'S89201G Unspecified physeal fracture of upper end of right fibula, subsequent encounter for fracture',
  'with delayed healing',
  'S89201K Unspecified physeal fracture of upper end of right fibula, subsequent encounter for fracture',
  'with nonunion',
  'S89201P Unspecified physeal fracture of upper end of right fibula, subsequent encounter for fracture',
  'with malunion',
  'S89201S Unspecified physeal fracture of upper end of right fibula, sequela',
  'S89202A Unspecified physeal fracture of upper end of left fibula, initial encounter for closed fracture',
  'S89202D Unspecified physeal fracture of upper end of left fibula, subsequent encounter for fracture',
  'with routine healing',
  'S89202G Unspecified physeal fracture of upper end of left fibula, subsequent encounter for fracture',
  'with delayed healing',
  'S89202K Unspecified physeal fracture of upper end of left fibula, subsequent encounter for fracture',
  'with nonunion',
  'S89202P Unspecified physeal fracture of upper end of left fibula, subsequent encounter for fracture',
  'with malunion',
  'S89202S Unspecified physeal fracture of upper end of left fibula, sequela',
  'S89209A Unspecified physeal fracture of upper end of unspecified fibula, initial encounter for closed',
  'fracture',
  'S89209D Unspecified physeal fracture of upper end of unspecified fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89209G Unspecified physeal fracture of upper end of unspecified fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89209K Unspecified physeal fracture of upper end of unspecified fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89209P Unspecified physeal fracture of upper end of unspecified fibula, subsequent encounter for',
  'fracture with malunion',
  'S89209S Unspecified physeal fracture of upper end of unspecified fibula, sequela',
  'S89211A Salter-Harris Type I physeal fracture of upper end of right fibula, initial encounter for closed',
  'fracture',
  'S89211D Salter-Harris Type I physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89211G Salter-Harris Type I physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89211K Salter-Harris Type I physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89211P Salter-Harris Type I physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with malunion',
  'S89211S Salter-Harris Type I physeal fracture of upper end of right fibula, sequela',
  'S89212A Salter-Harris Type I physeal fracture of upper end of left fibula, initial encounter for closed',
  'fracture',
  'S89212D Salter-Harris Type I physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89212G Salter-Harris Type I physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89212K Salter-Harris Type I physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89212P Salter-Harris Type I physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with malunion',
  'S89212S Salter-Harris Type I physeal fracture of upper end of left fibula, sequela',
  'S89219A Salter-Harris Type I physeal fracture of upper end of unspecified fibula, initial encounter for',
  'closed fracture',
  'S89219D Salter-Harris Type I physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with routine healing',
  'S89219G Salter-Harris Type I physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with delayed healing',
  'S89219K Salter-Harris Type I physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with nonunion',
  'S89219P Salter-Harris Type I physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with malunion',
  'S89219S Salter-Harris Type I physeal fracture of upper end of unspecified fibula, sequela',
  'S89221A Salter-Harris Type II physeal fracture of upper end of right fibula, initial encounter for closed',
  'fracture',
  'S89221D Salter-Harris Type II physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89221G Salter-Harris Type II physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89221K Salter-Harris Type II physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89221P Salter-Harris Type II physeal fracture of upper end of right fibula, subsequent encounter for',
  'fracture with malunion',
  'S89221S Salter-Harris Type II physeal fracture of upper end of right fibula, sequela',
  'S89222A Salter-Harris Type II physeal fracture of upper end of left fibula, initial encounter for closed',
  'fracture',
  'S89222D Salter-Harris Type II physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89222G Salter-Harris Type II physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89222K Salter-Harris Type II physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89222P Salter-Harris Type II physeal fracture of upper end of left fibula, subsequent encounter for',
  'fracture with malunion',
  'S89222S Salter-Harris Type II physeal fracture of upper end of left fibula, sequela',
  'S89229A Salter-Harris Type II physeal fracture of upper end of unspecified fibula, initial encounter for',
  'closed fracture',
  'S89229D Salter-Harris Type II physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with routine healing',
  'S89229G Salter-Harris Type II physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with delayed healing',
  'S89229K Salter-Harris Type II physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with nonunion',
  'S89229P Salter-Harris Type II physeal fracture of upper end of unspecified fibula, subsequent encounter',
  'for fracture with malunion',
  'S89229S Salter-Harris Type II physeal fracture of upper end of unspecified fibula, sequela',
  'S89291A Other physeal fracture of upper end of right fibula, initial encounter for closed fracture',
  'S89291D Other physeal fracture of upper end of right fibula, subsequent encounter for fracture with',
  'routine healing',
  'S89291G Other physeal fracture of upper end of right fibula, subsequent encounter for fracture with',
  'delayed healing',
  'S89291K Other physeal fracture of upper end of right fibula, subsequent encounter for fracture with',
  'nonunion',
  'S89291P Other physeal fracture of upper end of right fibula, subsequent encounter for fracture with',
  'malunion',
  'S89291S Other physeal fracture of upper end of right fibula, sequela',
  'S89292A Other physeal fracture of upper end of left fibula, initial encounter for closed fracture',
  'S89292D Other physeal fracture of upper end of left fibula, subsequent encounter for fracture with',
  'routine healing',
  'S89292G Other physeal fracture of upper end of left fibula, subsequent encounter for fracture with',
  'delayed healing',
  'S89292K Other physeal fracture of upper end of left fibula, subsequent encounter for fracture with',
  'nonunion',
  'S89292P Other physeal fracture of upper end of left fibula, subsequent encounter for fracture with',
  'malunion',
  'S89292S Other physeal fracture of upper end of left fibula, sequela',
  'S89299A Other physeal fracture of upper end of unspecified fibula, initial encounter for closed fracture',
  'S89299D Other physeal fracture of upper end of unspecified fibula, subsequent encounter for fracture',
  'with routine healing',
  'S89299G Other physeal fracture of upper end of unspecified fibula, subsequent encounter for fracture',
  'with delayed healing',
  'S89299K Other physeal fracture of upper end of unspecified fibula, subsequent encounter for fracture',
  'with nonunion',
  'S89299P Other physeal fracture of upper end of unspecified fibula, subsequent encounter for fracture',
  'with malunion',
  'S89299S Other physeal fracture of upper end of unspecified fibula, sequela',
  'S89301A Unspecified physeal fracture of lower end of right fibula, initial encounter for closed fracture',
  'S89301D Unspecified physeal fracture of lower end of right fibula, subsequent encounter for fracture',
  'with routine healing',
  'S89301G Unspecified physeal fracture of lower end of right fibula, subsequent encounter for fracture',
  'with delayed healing',
  'S89301K Unspecified physeal fracture of lower end of right fibula, subsequent encounter for fracture',
  'with nonunion',
  'S89301P Unspecified physeal fracture of lower end of right fibula, subsequent encounter for fracture',
  'with malunion',
  'S89301S Unspecified physeal fracture of lower end of right fibula, sequela',
  'S89302A Unspecified physeal fracture of lower end of left fibula, initial encounter for closed fracture',
  'S89302D Unspecified physeal fracture of lower end of left fibula, subsequent encounter for fracture',
  'with routine healing',
  'S89302G Unspecified physeal fracture of lower end of left fibula, subsequent encounter for fracture',
  'with delayed healing',
  'S89302K Unspecified physeal fracture of lower end of left fibula, subsequent encounter for fracture with',
  'nonunion',
  'S89302P Unspecified physeal fracture of lower end of left fibula, subsequent encounter for fracture with',
  'malunion',
  'S89302S Unspecified physeal fracture of lower end of left fibula, sequela',
  'S89309A Unspecified physeal fracture of lower end of unspecified fibula, initial encounter for closed',
  'fracture',
  'S89309D Unspecified physeal fracture of lower end of unspecified fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89309G Unspecified physeal fracture of lower end of unspecified fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89309K Unspecified physeal fracture of lower end of unspecified fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89309P Unspecified physeal fracture of lower end of unspecified fibula, subsequent encounter for',
  'fracture with malunion',
  'S89309S Unspecified physeal fracture of lower end of unspecified fibula, sequela',
  'S89311A Salter-Harris Type I physeal fracture of lower end of right fibula, initial encounter for closed',
  'fracture',
  'S89311D Salter-Harris Type I physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89311G Salter-Harris Type I physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89311K Salter-Harris Type I physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89311P Salter-Harris Type I physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with malunion',
  'S89311S Salter-Harris Type I physeal fracture of lower end of right fibula, sequela',
  'S89312A Salter-Harris Type I physeal fracture of lower end of left fibula, initial encounter for closed',
  'fracture',
  'S89312D Salter-Harris Type I physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89312G Salter-Harris Type I physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89312K Salter-Harris Type I physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89312P Salter-Harris Type I physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with malunion',
  'S89312S Salter-Harris Type I physeal fracture of lower end of left fibula, sequela',
  'S89319A Salter-Harris Type I physeal fracture of lower end of unspecified fibula, initial encounter for',
  'closed fracture',
  'S89319D Salter-Harris Type I physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with routine healing',
  'S89319G Salter-Harris Type I physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with delayed healing',
  'S89319K Salter-Harris Type I physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with nonunion',
  'S89319P Salter-Harris Type I physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with malunion',
  'S89319S Salter-Harris Type I physeal fracture of lower end of unspecified fibula, sequela',
  'S89321A Salter-Harris Type II physeal fracture of lower end of right fibula, initial encounter for closed',
  'fracture',
  'S89321D Salter-Harris Type II physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89321G Salter-Harris Type II physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89321K Salter-Harris Type II physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89321P Salter-Harris Type II physeal fracture of lower end of right fibula, subsequent encounter for',
  'fracture with malunion',
  'S89321S Salter-Harris Type II physeal fracture of lower end of right fibula, sequela',
  'S89322A Salter-Harris Type II physeal fracture of lower end of left fibula, initial encounter for closed',
  'fracture',
  'S89322D Salter-Harris Type II physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with routine healing',
  'S89322G Salter-Harris Type II physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with delayed healing',
  'S89322K Salter-Harris Type II physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with nonunion',
  'S89322P Salter-Harris Type II physeal fracture of lower end of left fibula, subsequent encounter for',
  'fracture with malunion',
  'S89322S Salter-Harris Type II physeal fracture of lower end of left fibula, sequela',
  'S89329A Salter-Harris Type II physeal fracture of lower end of unspecified fibula, initial encounter for',
  'closed fracture',
  'S89329D Salter-Harris Type II physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with routine healing',
  'S89329G Salter-Harris Type II physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with delayed healing',
  'S89329K Salter-Harris Type II physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with nonunion',
  'S89329P Salter-Harris Type II physeal fracture of lower end of unspecified fibula, subsequent encounter',
  'for fracture with malunion',
  'S89329S Salter-Harris Type II physeal fracture of lower end of unspecified fibula, sequela',
  'S89391A Other physeal fracture of lower end of right fibula, initial encounter for closed fracture',
  'S89391D Other physeal fracture of lower end of right fibula, subsequent encounter for fracture with',
  'routine healing',
  'S89391G Other physeal fracture of lower end of right fibula, subsequent encounter for fracture with',
  'delayed healing',
  'S89391K Other physeal fracture of lower end of right fibula, subsequent encounter for fracture with',
  'nonunion',
  'S89391P Other physeal fracture of lower end of right fibula, subsequent encounter for fracture with',
  'malunion',
  'S89391S Other physeal fracture of lower end of right fibula, sequela',
  'S89392A Other physeal fracture of lower end of left fibula, initial encounter for closed fracture',
  'S89392D Other physeal fracture of lower end of left fibula, subsequent encounter for fracture with',
  'routine healing',
  'S89392G Other physeal fracture of lower end of left fibula, subsequent encounter for fracture with',
  'delayed healing',
  'S89392K Other physeal fracture of lower end of left fibula, subsequent encounter for fracture with',
  'nonunion',
  'S89392P Other physeal fracture of lower end of left fibula, subsequent encounter for fracture with',
  'malunion',
  'S89392S Other physeal fracture of lower end of left fibula, sequela',
  'S89399A Other physeal fracture of lower end of unspecified fibula, initial encounter for closed fracture',
  'S89399D Other physeal fracture of lower end of unspecified fibula, subsequent encounter for fracture',
  'with routine healing',
  'S89399G Other physeal fracture of lower end of unspecified fibula, subsequent encounter for fracture',
  'with delayed healing',
  'S89399K Other physeal fracture of lower end of unspecified fibula, subsequent encounter for fracture',
  'with nonunion',
  'S89399P Other physeal fracture of lower end of unspecified fibula, subsequent encounter for fracture',
  'with malunion',
  'S89399S Other physeal fracture of lower end of unspecified fibula, sequela',
  'S8980XA Other specified injuries of unspecified lower leg, initial encounter',
  'S8980XD Other specified injuries of unspecified lower leg, subsequent encounter',
  'S8980XS Other specified injuries of unspecified lower leg, sequela',
  'S8981XA Other specified injuries of right lower leg, initial encounter',
  'S8981XD Other specified injuries of right lower leg, subsequent encounter',
  'S8981XS Other specified injuries of right lower leg, sequela',
  'S8982XA Other specified injuries of left lower leg, initial encounter',
  'S8982XD Other specified injuries of left lower leg, subsequent encounter',
  'S8982XS Other specified injuries of left lower leg, sequela',
  'S8990XA Unspecified injury of unspecified lower leg, initial encounter',
  'S8990XD Unspecified injury of unspecified lower leg, subsequent encounter',
  'S8990XS Unspecified injury of unspecified lower leg, sequela',
  'S8991XA Unspecified injury of right lower leg, initial encounter',
  'S8991XD Unspecified injury of right lower leg, subsequent encounter',
  'S8991XS Unspecified injury of right lower leg, sequela',
  'S8992XA Unspecified injury of left lower leg, initial encounter',
  'S8992XD Unspecified injury of left lower leg, subsequent encounter',
  'S8992XS Unspecified injury of left lower leg, sequela',
  'S9000XA Contusion of unspecified ankle, initial encounter',
  'S9000XD Contusion of unspecified ankle, subsequent encounter',
  'S9000XS Contusion of unspecified ankle, sequela',
  'S9001XA Contusion of right ankle, initial encounter',
  'S9001XD Contusion of right ankle, subsequent encounter',
  'S9001XS Contusion of right ankle, sequela',
  'S9002XA Contusion of left ankle, initial encounter',
  'S9002XD Contusion of left ankle, subsequent encounter',
  'S9002XS Contusion of left ankle, sequela',
  'S90111A Contusion of right great toe without damage to nail, initial encounter',
  'S90111D Contusion of right great toe without damage to nail, subsequent encounter',
  'S90111S Contusion of right great toe without damage to nail, sequela',
  'S90112A Contusion of left great toe without damage to nail, initial encounter',
  'S90112D Contusion of left great toe without damage to nail, subsequent encounter',
  'S90112S Contusion of left great toe without damage to nail, sequela',
  'S90119A Contusion of unspecified great toe without damage to nail, initial encounter',
  'S90119D Contusion of unspecified great toe without damage to nail, subsequent encounter',
  'S90119S Contusion of unspecified great toe without damage to nail, sequela',
  'S90121A Contusion of right lesser toe(s) without damage to nail, initial encounter',
  'S90121D Contusion of right lesser toe(s) without damage to nail, subsequent encounter',
  'S90121S Contusion of right lesser toe(s) without damage to nail, sequela',
  'S90122A Contusion of left lesser toe(s) without damage to nail, initial encounter',
  'S90122D Contusion of left lesser toe(s) without damage to nail, subsequent encounter',
  'S90122S Contusion of left lesser toe(s) without damage to nail, sequela',
  'S90129A Contusion of unspecified lesser toe(s) without damage to nail, initial encounter',
  'S90129D Contusion of unspecified lesser toe(s) without damage to nail, subsequent encounter',
  'S90129S Contusion of unspecified lesser toe(s) without damage to nail, sequela',
  'S90211A Contusion of right great toe with damage to nail, initial encounter',
  'S90211D Contusion of right great toe with damage to nail, subsequent encounter',
  'S90211S Contusion of right great toe with damage to nail, sequela',
  'S90212A Contusion of left great toe with damage to nail, initial encounter',
  'S90212D Contusion of left great toe with damage to nail, subsequent encounter',
  'S90212S Contusion of left great toe with damage to nail, sequela',
  'S90219A Contusion of unspecified great toe with damage to nail, initial encounter',
  'S90219D Contusion of unspecified great toe with damage to nail, subsequent encounter',
  'S90219S Contusion of unspecified great toe with damage to nail, sequela',
  'S90221A Contusion of right lesser toe(s) with damage to nail, initial encounter',
  'S90221D Contusion of right lesser toe(s) with damage to nail, subsequent encounter',
  'S90221S Contusion of right lesser toe(s) with damage to nail, sequela',
  'S90222A Contusion of left lesser toe(s) with damage to nail, initial encounter',
  'S90222D Contusion of left lesser toe(s) with damage to nail, subsequent encounter',
  'S90222S Contusion of left lesser toe(s) with damage to nail, sequela',
  'S90229A Contusion of unspecified lesser toe(s) with damage to nail, initial encounter',
  'S90229D Contusion of unspecified lesser toe(s) with damage to nail, subsequent encounter',
  'S90229S Contusion of unspecified lesser toe(s) with damage to nail, sequela',
  'S9030XA Contusion of unspecified foot, initial encounter',
  'S9030XD Contusion of unspecified foot, subsequent encounter',
  'S9030XS Contusion of unspecified foot, sequela',
  'S9031XA Contusion of right foot, initial encounter',
  'S9031XD Contusion of right foot, subsequent encounter',
  'S9031XS Contusion of right foot, sequela',
  'S9032XA Contusion of left foot, initial encounter',
  'S9032XD Contusion of left foot, subsequent encounter',
  'S9032XS Contusion of left foot, sequela',
  'S90411A Abrasion, right great toe, initial encounter',
  'S90411D Abrasion, right great toe, subsequent encounter',
  'S90411S Abrasion, right great toe, sequela',
  'S90412A Abrasion, left great toe, initial encounter',
  'S90412D Abrasion, left great toe, subsequent encounter',
  'S90412S Abrasion, left great toe, sequela',
  'S90413A Abrasion, unspecified great toe, initial encounter',
  'S90413D Abrasion, unspecified great toe, subsequent encounter',
  'S90413S Abrasion, unspecified great toe, sequela',
  'S90414A Abrasion, right lesser toe(s), initial encounter',
  'S90414D Abrasion, right lesser toe(s), subsequent encounter',
  'S90414S Abrasion, right lesser toe(s), sequela',
  'S90415A Abrasion, left lesser toe(s), initial encounter',
  'S90415D Abrasion, left lesser toe(s), subsequent encounter',
  'S90415S Abrasion, left lesser toe(s), sequela',
  'S90416A Abrasion, unspecified lesser toe(s), initial encounter',
  'S90416D Abrasion, unspecified lesser toe(s), subsequent encounter',
  'S90416S Abrasion, unspecified lesser toe(s), sequela',
  'S90421A Blister (nonthermal), right great toe, initial encounter',
  'S90421D Blister (nonthermal), right great toe, subsequent encounter',
  'S90421S Blister (nonthermal), right great toe, sequela',
  'S90422A Blister (nonthermal), left great toe, initial encounter',
  'S90422D Blister (nonthermal), left great toe, subsequent encounter',
  'S90422S Blister (nonthermal), left great toe, sequela',
  'S90423A Blister (nonthermal), unspecified great toe, initial encounter',
  'S90423D Blister (nonthermal), unspecified great toe, subsequent encounter',
  'S90423S Blister (nonthermal), unspecified great toe, sequela',
  'S90424A Blister (nonthermal), right lesser toe(s), initial encounter',
  'S90424D Blister (nonthermal), right lesser toe(s), subsequent encounter',
  'S90424S Blister (nonthermal), right lesser toe(s), sequela',
  'S90425A Blister (nonthermal), left lesser toe(s), initial encounter',
  'S90425D Blister (nonthermal), left lesser toe(s), subsequent encounter',
  'S90425S Blister (nonthermal), left lesser toe(s), sequela',
  'S90426A Blister (nonthermal), unspecified lesser toe(s), initial encounter',
  'S90426D Blister (nonthermal), unspecified lesser toe(s), subsequent encounter',
  'S90426S Blister (nonthermal), unspecified lesser toe(s), sequela',
  'S90441A External constriction, right great toe, initial encounter',
  'S90441D External constriction, right great toe, subsequent encounter',
  'S90441S External constriction, right great toe, sequela',
  'S90442A External constriction, left great toe, initial encounter',
  'S90442D External constriction, left great toe, subsequent encounter',
  'S90442S External constriction, left great toe, sequela',
  'S90443A External constriction, unspecified great toe, initial encounter',
  'S90443D External constriction, unspecified great toe, subsequent encounter',
  'S90443S External constriction, unspecified great toe, sequela',
  'S90444A External constriction, right lesser toe(s), initial encounter',
  'S90444D External constriction, right lesser toe(s), subsequent encounter',
  'S90444S External constriction, right lesser toe(s), sequela',
  'S90445A External constriction, left lesser toe(s), initial encounter',
  'S90445D External constriction, left lesser toe(s), subsequent encounter',
  'S90445S External constriction, left lesser toe(s), sequela',
  'S90446A External constriction, unspecified lesser toe(s), initial encounter',
  'S90446D External constriction, unspecified lesser toe(s), subsequent encounter',
  'S90446S External constriction, unspecified lesser toe(s), sequela',
  'S90451A Superficial foreign body, right great toe, initial encounter',
  'S90451D Superficial foreign body, right great toe, subsequent encounter',
  'S90451S Superficial foreign body, right great toe, sequela',
  'S90452A Superficial foreign body, left great toe, initial encounter',
  'S90452D Superficial foreign body, left great toe, subsequent encounter',
  'S90452S Superficial foreign body, left great toe, sequela',
  'S90453A Superficial foreign body, unspecified great toe, initial encounter',
  'S90453D Superficial foreign body, unspecified great toe, subsequent encounter',
  'S90453S Superficial foreign body, unspecified great toe, sequela',
  'S90454A Superficial foreign body, right lesser toe(s), initial encounter',
  'S90454D Superficial foreign body, right lesser toe(s), subsequent encounter',
  'S90454S Superficial foreign body, right lesser toe(s), sequela',
  'S90455A Superficial foreign body, left lesser toe(s), initial encounter',
  'S90455D Superficial foreign body, left lesser toe(s), subsequent encounter',
  'S90455S Superficial foreign body, left lesser toe(s), sequela',
  'S90456A Superficial foreign body, unspecified lesser toe(s), initial encounter',
  'S90456D Superficial foreign body, unspecified lesser toe(s), subsequent encounter',
  'S90456S Superficial foreign body, unspecified lesser toe(s), sequela',
  'S90461A Insect bite (nonvenomous), right great toe, initial encounter',
  'S90461D Insect bite (nonvenomous), right great toe, subsequent encounter',
  'S90461S Insect bite (nonvenomous), right great toe, sequela',
  'S90462A Insect bite (nonvenomous), left great toe, initial encounter',
  'S90462D Insect bite (nonvenomous), left great toe, subsequent encounter',
  'S90462S Insect bite (nonvenomous), left great toe, sequela',
  'S90463A Insect bite (nonvenomous), unspecified great toe, initial encounter',
  'S90463D Insect bite (nonvenomous), unspecified great toe, subsequent encounter',
  'S90463S Insect bite (nonvenomous), unspecified great toe, sequela',
  'S90464A Insect bite (nonvenomous), right lesser toe(s), initial encounter',
  'S90464D Insect bite (nonvenomous), right lesser toe(s), subsequent encounter',
  'S90464S Insect bite (nonvenomous), right lesser toe(s), sequela',
  'S90465A Insect bite (nonvenomous), left lesser toe(s), initial encounter',
  'S90465D Insect bite (nonvenomous), left lesser toe(s), subsequent encounter',
  'S90465S Insect bite (nonvenomous), left lesser toe(s), sequela',
  'S90466A Insect bite (nonvenomous), unspecified lesser toe(s), initial encounter',
  'S90466D Insect bite (nonvenomous), unspecified lesser toe(s), subsequent encounter',
  'S90466S Insect bite (nonvenomous), unspecified lesser toe(s), sequela',
  'S90471A Other superficial bite of right great toe, initial encounter',
  'S90471D Other superficial bite of right great toe, subsequent encounter',
  'S90471S Other superficial bite of right great toe, sequela',
  'S90472A Other superficial bite of left great toe, initial encounter',
  'S90472D Other superficial bite of left great toe, subsequent encounter',
  'S90472S Other superficial bite of left great toe, sequela',
  'S90473A Other superficial bite of unspecified great toe, initial encounter',
  'S90473D Other superficial bite of unspecified great toe, subsequent encounter',
  'S90473S Other superficial bite of unspecified great toe, sequela',
  'S90474A Other superficial bite of right lesser toe(s), initial encounter',
  'S90474D Other superficial bite of right lesser toe(s), subsequent encounter',
  'S90474S Other superficial bite of right lesser toe(s), sequela',
  'S90475A Other superficial bite of left lesser toe(s), initial encounter',
  'S90475D Other superficial bite of left lesser toe(s), subsequent encounter',
  'S90475S Other superficial bite of left lesser toe(s), sequela',
  'S90476A Other superficial bite of unspecified lesser toe(s), initial encounter',
  'S90476D Other superficial bite of unspecified lesser toe(s), subsequent encounter',
  'S90476S Other superficial bite of unspecified lesser toe(s), sequela',
  'S90511A Abrasion, right ankle, initial encounter',
  'S90511D Abrasion, right ankle, subsequent encounter',
  'S90511S Abrasion, right ankle, sequela',
  'S90512A Abrasion, left ankle, initial encounter',
  'S90512D Abrasion, left ankle, subsequent encounter',
  'S90512S Abrasion, left ankle, sequela',
  'S90519A Abrasion, unspecified ankle, initial encounter',
  'S90519D Abrasion, unspecified ankle, subsequent encounter',
  'S90519S Abrasion, unspecified ankle, sequela',
  'S90521A Blister (nonthermal), right ankle, initial encounter',
  'S90521D Blister (nonthermal), right ankle, subsequent encounter',
  'S90521S Blister (nonthermal), right ankle, sequela',
  'S90522A Blister (nonthermal), left ankle, initial encounter',
  'S90522D Blister (nonthermal), left ankle, subsequent encounter',
  'S90522S Blister (nonthermal), left ankle, sequela',
  'S90529A Blister (nonthermal), unspecified ankle, initial encounter',
  'S90529D Blister (nonthermal), unspecified ankle, subsequent encounter',
  'S90529S Blister (nonthermal), unspecified ankle, sequela',
  'S90541A External constriction, right ankle, initial encounter',
  'S90541D External constriction, right ankle, subsequent encounter',
  'S90541S External constriction, right ankle, sequela',
  'S90542A External constriction, left ankle, initial encounter',
  'S90542D External constriction, left ankle, subsequent encounter',
  'S90542S External constriction, left ankle, sequela',
  'S90549A External constriction, unspecified ankle, initial encounter',
  'S90549D External constriction, unspecified ankle, subsequent encounter',
  'S90549S External constriction, unspecified ankle, sequela',
  'S90551A Superficial foreign body, right ankle, initial encounter',
  'S90551D Superficial foreign body, right ankle, subsequent encounter',
  'S90551S Superficial foreign body, right ankle, sequela',
  'S90552A Superficial foreign body, left ankle, initial encounter',
  'S90552D Superficial foreign body, left ankle, subsequent encounter',
  'S90552S Superficial foreign body, left ankle, sequela',
  'S90559A Superficial foreign body, unspecified ankle, initial encounter',
  'S90559D Superficial foreign body, unspecified ankle, subsequent encounter',
  'S90559S Superficial foreign body, unspecified ankle, sequela',
  'S90561A Insect bite (nonvenomous), right ankle, initial encounter',
  'S90561D Insect bite (nonvenomous), right ankle, subsequent encounter',
  'S90561S Insect bite (nonvenomous), right ankle, sequela',
  'S90562A Insect bite (nonvenomous), left ankle, initial encounter',
  'S90562D Insect bite (nonvenomous), left ankle, subsequent encounter',
  'S90562S Insect bite (nonvenomous), left ankle, sequela',
  'S90569A Insect bite (nonvenomous), unspecified ankle, initial encounter',
  'S90569D Insect bite (nonvenomous), unspecified ankle, subsequent encounter',
  'S90569S Insect bite (nonvenomous), unspecified ankle, sequela',
  'S90571A Other superficial bite of ankle, right ankle, initial encounter',
  'S90571D Other superficial bite of ankle, right ankle, subsequent encounter',
  'S90571S Other superficial bite of ankle, right ankle, sequela',
  'S90572A Other superficial bite of ankle, left ankle, initial encounter',
  'S90572D Other superficial bite of ankle, left ankle, subsequent encounter',
  'S90572S Other superficial bite of ankle, left ankle, sequela',
  'S90579A Other superficial bite of ankle, unspecified ankle, initial encounter',
  'S90579D Other superficial bite of ankle, unspecified ankle, subsequent encounter',
  'S90579S Other superficial bite of ankle, unspecified ankle, sequela',
  'S90811A Abrasion, right foot, initial encounter',
  'S90811D Abrasion, right foot, subsequent encounter',
  'S90811S Abrasion, right foot, sequela',
  'S90812A Abrasion, left foot, initial encounter',
  'S90812D Abrasion, left foot, subsequent encounter',
  'S90812S Abrasion, left foot, sequela',
  'S90819A Abrasion, unspecified foot, initial encounter',
  'S90819D Abrasion, unspecified foot, subsequent encounter',
  'S90819S Abrasion, unspecified foot, sequela',
  'S90821A Blister (nonthermal), right foot, initial encounter',
  'S90821D Blister (nonthermal), right foot, subsequent encounter',
  'S90821S Blister (nonthermal), right foot, sequela',
  'S90822A Blister (nonthermal), left foot, initial encounter',
  'S90822D Blister (nonthermal), left foot, subsequent encounter',
  'S90822S Blister (nonthermal), left foot, sequela',
  'S90829A Blister (nonthermal), unspecified foot, initial encounter',
  'S90829D Blister (nonthermal), unspecified foot, subsequent encounter',
  'S90829S Blister (nonthermal), unspecified foot, sequela',
  'S90841A External constriction, right foot, initial encounter',
  'S90841D External constriction, right foot, subsequent encounter',
  'S90841S External constriction, right foot, sequela',
  'S90842A External constriction, left foot, initial encounter',
  'S90842D External constriction, left foot, subsequent encounter',
  'S90842S External constriction, left foot, sequela',
  'S90849A External constriction, unspecified foot, initial encounter',
  'S90849D External constriction, unspecified foot, subsequent encounter',
  'S90849S External constriction, unspecified foot, sequela',
  'S90851A Superficial foreign body, right foot, initial encounter',
  'S90851D Superficial foreign body, right foot, subsequent encounter',
  'S90851S Superficial foreign body, right foot, sequela',
  'S90852A Superficial foreign body, left foot, initial encounter',
  'S90852D Superficial foreign body, left foot, subsequent encounter',
  'S90852S Superficial foreign body, left foot, sequela',
  'S90859A Superficial foreign body, unspecified foot, initial encounter',
  'S90859D Superficial foreign body, unspecified foot, subsequent encounter',
  'S90859S Superficial foreign body, unspecified foot, sequela',
  'S90861A Insect bite (nonvenomous), right foot, initial encounter',
  'S90861D Insect bite (nonvenomous), right foot, subsequent encounter',
  'S90861S Insect bite (nonvenomous), right foot, sequela',
  'S90862A Insect bite (nonvenomous), left foot, initial encounter',
  'S90862D Insect bite (nonvenomous), left foot, subsequent encounter',
  'S90862S Insect bite (nonvenomous), left foot, sequela',
  'S90869A Insect bite (nonvenomous), unspecified foot, initial encounter',
  'S90869D Insect bite (nonvenomous), unspecified foot, subsequent encounter',
  'S90869S Insect bite (nonvenomous), unspecified foot, sequela',
  'S90871A Other superficial bite of right foot, initial encounter',
  'S90871D Other superficial bite of right foot, subsequent encounter',
  'S90871S Other superficial bite of right foot, sequela',
  'S90872A Other superficial bite of left foot, initial encounter',
  'S90872D Other superficial bite of left foot, subsequent encounter',
  'S90872S Other superficial bite of left foot, sequela',
  'S90879A Other superficial bite of unspecified foot, initial encounter',
  'S90879D Other superficial bite of unspecified foot, subsequent encounter',
  'S90879S Other superficial bite of unspecified foot, sequela',
  'S90911A Unspecified superficial injury of right ankle, initial encounter',
  'S90911D Unspecified superficial injury of right ankle, subsequent encounter',
  'S90911S Unspecified superficial injury of right ankle, sequela',
  'S90912A Unspecified superficial injury of left ankle, initial encounter',
  'S90912D Unspecified superficial injury of left ankle, subsequent encounter',
  'S90912S Unspecified superficial injury of left ankle, sequela',
  'S90919A Unspecified superficial injury of unspecified ankle, initial encounter',
  'S90919D Unspecified superficial injury of unspecified ankle, subsequent encounter',
  'S90919S Unspecified superficial injury of unspecified ankle, sequela',
  'S90921A Unspecified superficial injury of right foot, initial encounter',
  'S90921D Unspecified superficial injury of right foot, subsequent encounter',
  'S90921S Unspecified superficial injury of right foot, sequela',
  'S90922A Unspecified superficial injury of left foot, initial encounter',
  'S90922D Unspecified superficial injury of left foot, subsequent encounter',
  'S90922S Unspecified superficial injury of left foot, sequela',
  'S90929A Unspecified superficial injury of unspecified foot, initial encounter',
  'S90929D Unspecified superficial injury of unspecified foot, subsequent encounter',
  'S90929S Unspecified superficial injury of unspecified foot, sequela',
  'S90931A Unspecified superficial injury of right great toe, initial encounter',
  'S90931D Unspecified superficial injury of right great toe, subsequent encounter',
  'S90931S Unspecified superficial injury of right great toe, sequela',
  'S90932A Unspecified superficial injury of left great toe, initial encounter',
  'S90932D Unspecified superficial injury of left great toe, subsequent encounter',
  'S90932S Unspecified superficial injury of left great toe, sequela',
  'S90933A Unspecified superficial injury of unspecified great toe, initial encounter',
  'S90933D Unspecified superficial injury of unspecified great toe, subsequent encounter',
  'S90933S Unspecified superficial injury of unspecified great toe, sequela',
  'S90934A Unspecified superficial injury of right lesser toe(s), initial encounter',
  'S90934D Unspecified superficial injury of right lesser toe(s), subsequent encounter',
  'S90934S Unspecified superficial injury of right lesser toe(s), sequela',
  'S90935A Unspecified superficial injury of left lesser toe(s), initial encounter',
  'S90935D Unspecified superficial injury of left lesser toe(s), subsequent encounter',
  'S90935S Unspecified superficial injury of left lesser toe(s), sequela',
  'S90936A Unspecified superficial injury of unspecified lesser toe(s), initial encounter',
  'S90936D Unspecified superficial injury of unspecified lesser toe(s), subsequent encounter',
  'S90936S Unspecified superficial injury of unspecified lesser toe(s), sequela',
  'S91001A Unspecified open wound, right ankle, initial encounter',
  'S91001D Unspecified open wound, right ankle, subsequent encounter',
  'S91001S Unspecified open wound, right ankle, sequela',
  'S91002A Unspecified open wound, left ankle, initial encounter',
  'S91002D Unspecified open wound, left ankle, subsequent encounter',
  'S91002S Unspecified open wound, left ankle, sequela',
  'S91009A Unspecified open wound, unspecified ankle, initial encounter',
  'S91009D Unspecified open wound, unspecified ankle, subsequent encounter',
  'S91009S Unspecified open wound, unspecified ankle, sequela',
  'S91011A Laceration without foreign body, right ankle, initial encounter',
  'S91011D Laceration without foreign body, right ankle, subsequent encounter',
  'S91011S Laceration without foreign body, right ankle, sequela',
  'S91012A Laceration without foreign body, left ankle, initial encounter',
  'S91012D Laceration without foreign body, left ankle, subsequent encounter',
  'S91012S Laceration without foreign body, left ankle, sequela',
  'S91019A Laceration without foreign body, unspecified ankle, initial encounter',
  'S91019D Laceration without foreign body, unspecified ankle, subsequent encounter',
  'S91019S Laceration without foreign body, unspecified ankle, sequela',
  'S91021A Laceration with foreign body, right ankle, initial encounter',
  'S91021D Laceration with foreign body, right ankle, subsequent encounter',
  'S91021S Laceration with foreign body, right ankle, sequela',
  'S91022A Laceration with foreign body, left ankle, initial encounter',
  'S91022D Laceration with foreign body, left ankle, subsequent encounter',
  'S91022S Laceration with foreign body, left ankle, sequela',
  'S91029A Laceration with foreign body, unspecified ankle, initial encounter',
  'S91029D Laceration with foreign body, unspecified ankle, subsequent encounter',
  'S91029S Laceration with foreign body, unspecified ankle, sequela',
  'S91031A Puncture wound without foreign body, right ankle, initial encounter',
  'S91031D Puncture wound without foreign body, right ankle, subsequent encounter',
  'S91031S Puncture wound without foreign body, right ankle, sequela',
  'S91032A Puncture wound without foreign body, left ankle, initial encounter',
  'S91032D Puncture wound without foreign body, left ankle, subsequent encounter',
  'S91032S Puncture wound without foreign body, left ankle, sequela',
  'S91039A Puncture wound without foreign body, unspecified ankle, initial encounter',
  'S91039D Puncture wound without foreign body, unspecified ankle, subsequent encounter',
  'S91039S Puncture wound without foreign body, unspecified ankle, sequela',
  'S91041A Puncture wound with foreign body, right ankle, initial encounter',
  'S91041D Puncture wound with foreign body, right ankle, subsequent encounter',
  'S91041S Puncture wound with foreign body, right ankle, sequela',
  'S91042A Puncture wound with foreign body, left ankle, initial encounter',
  'S91042D Puncture wound with foreign body, left ankle, subsequent encounter',
  'S91042S Puncture wound with foreign body, left ankle, sequela',
  'S91049A Puncture wound with foreign body, unspecified ankle, initial encounter',
  'S91049D Puncture wound with foreign body, unspecified ankle, subsequent encounter',
  'S91049S Puncture wound with foreign body, unspecified ankle, sequela',
  'S91051A Open bite, right ankle, initial encounter',
  'S91051D Open bite, right ankle, subsequent encounter',
  'S91051S Open bite, right ankle, sequela',
  'S91052A Open bite, left ankle, initial encounter',
  'S91052D Open bite, left ankle, subsequent encounter',
  'S91052S Open bite, left ankle, sequela',
  'S91059A Open bite, unspecified ankle, initial encounter',
  'S91059D Open bite, unspecified ankle, subsequent encounter',
  'S91059S Open bite, unspecified ankle, sequela',
  'S91101A Unspecified open wound of right great toe without damage to nail, initial encounter',
  'S91101D Unspecified open wound of right great toe without damage to nail, subsequent encounter',
  'S91101S Unspecified open wound of right great toe without damage to nail, sequela',
  'S91102A Unspecified open wound of left great toe without damage to nail, initial encounter',
  'S91102D Unspecified open wound of left great toe without damage to nail, subsequent encounter',
  'S91102S Unspecified open wound of left great toe without damage to nail, sequela',
  'S91103A Unspecified open wound of unspecified great toe without damage to nail, initial encounter',
  'S91103D Unspecified open wound of unspecified great toe without damage to nail, subsequent',
  'encounter',
  'S91103S Unspecified open wound of unspecified great toe without damage to nail, sequela',
  'S91104A Unspecified open wound of right lesser toe(s) without damage to nail, initial encounter',
  'S91104D Unspecified open wound of right lesser toe(s) without damage to nail, subsequent encounter',
  'S91104S Unspecified open wound of right lesser toe(s) without damage to nail, sequela',
  'S91105A Unspecified open wound of left lesser toe(s) without damage to nail, initial encounter',
  'S91105D Unspecified open wound of left lesser toe(s) without damage to nail, subsequent encounter',
  'S91105S Unspecified open wound of left lesser toe(s) without damage to nail, sequela',
  'S91106A Unspecified open wound of unspecified lesser toe(s) without damage to nail, initial encounter',
  'S91106D Unspecified open wound of unspecified lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91106S Unspecified open wound of unspecified lesser toe(s) without damage to nail, sequela',
  'S91109A Unspecified open wound of unspecified toe(s) without damage to nail, initial encounter',
  'S91109D Unspecified open wound of unspecified toe(s) without damage to nail, subsequent encounter',
  'S91109S Unspecified open wound of unspecified toe(s) without damage to nail, sequela',
  'S91111A Laceration without foreign body of right great toe without damage to nail, initial encounter',
  'S91111D Laceration without foreign body of right great toe without damage to nail, subsequent',
  'encounter',
  'S91111S Laceration without foreign body of right great toe without damage to nail, sequela',
  'S91112A Laceration without foreign body of left great toe without damage to nail, initial encounter',
  'S91112D Laceration without foreign body of left great toe without damage to nail, subsequent',
  'encounter',
  'S91112S Laceration without foreign body of left great toe without damage to nail, sequela',
  'S91113A Laceration without foreign body of unspecified great toe without damage to nail, initial',
  'encounter',
  'S91113D Laceration without foreign body of unspecified great toe without damage to nail, subsequent',
  'encounter',
  'S91113S Laceration without foreign body of unspecified great toe without damage to nail, sequela',
  'S91114A Laceration without foreign body of right lesser toe(s) without damage to nail, initial encounter',
  'S91114D Laceration without foreign body of right lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91114S Laceration without foreign body of right lesser toe(s) without damage to nail, sequela',
  'S91115A Laceration without foreign body of left lesser toe(s) without damage to nail, initial encounter',
  'S91115D Laceration without foreign body of left lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91115S Laceration without foreign body of left lesser toe(s) without damage to nail, sequela',
  'S91116A Laceration without foreign body of unspecified lesser toe(s) without damage to nail, initial',
  'encounter',
  'S91116D Laceration without foreign body of unspecified lesser toe(s) without damage to nail,',
  'subsequent encounter',
  'S91116S Laceration without foreign body of unspecified lesser toe(s) without damage to nail, sequela',
  'S91119A Laceration without foreign body of unspecified toe without damage to nail, initial encounter',
  'S91119D Laceration without foreign body of unspecified toe without damage to nail, subsequent',
  'encounter',
  'S91119S Laceration without foreign body of unspecified toe without damage to nail, sequela',
  'S91121A Laceration with foreign body of right great toe without damage to nail, initial encounter',
  'S91121D Laceration with foreign body of right great toe without damage to nail, subsequent encounter',
  'S91121S Laceration with foreign body of right great toe without damage to nail, sequela',
  'S91122A Laceration with foreign body of left great toe without damage to nail, initial encounter',
  'S91122D Laceration with foreign body of left great toe without damage to nail, subsequent encounter',
  'S91122S Laceration with foreign body of left great toe without damage to nail, sequela',
  'S91123A Laceration with foreign body of unspecified great toe without damage to nail, initial encounter',
  'S91123D Laceration with foreign body of unspecified great toe without damage to nail, subsequent',
  'encounter',
  'S91123S Laceration with foreign body of unspecified great toe without damage to nail, sequela',
  'S91124A Laceration with foreign body of right lesser toe(s) without damage to nail, initial encounter',
  'S91124D Laceration with foreign body of right lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91124S Laceration with foreign body of right lesser toe(s) without damage to nail, sequela',
  'S91125A Laceration with foreign body of left lesser toe(s) without damage to nail, initial encounter',
  'S91125D Laceration with foreign body of left lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91125S Laceration with foreign body of left lesser toe(s) without damage to nail, sequela',
  'S91126A Laceration with foreign body of unspecified lesser toe(s) without damage to nail, initial',
  'encounter',
  'S91126D Laceration with foreign body of unspecified lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91126S Laceration with foreign body of unspecified lesser toe(s) without damage to nail, sequela',
  'S91129A Laceration with foreign body of unspecified toe(s) without damage to nail, initial encounter',
  'S91129D Laceration with foreign body of unspecified toe(s) without damage to nail, subsequent',
  'encounter',
  'S91129S Laceration with foreign body of unspecified toe(s) without damage to nail, sequela',
  'S91131A Puncture wound without foreign body of right great toe without damage to nail, initial',
  'encounter',
  'S91131D Puncture wound without foreign body of right great toe without damage to nail, subsequent',
  'encounter',
  'S91131S Puncture wound without foreign body of right great toe without damage to nail, sequela',
  'S91132A Puncture wound without foreign body of left great toe without damage to nail, initial',
  'encounter',
  'S91132D Puncture wound without foreign body of left great toe without damage to nail, subsequent',
  'encounter',
  'S91132S Puncture wound without foreign body of left great toe without damage to nail, sequela',
  'S91133A Puncture wound without foreign body of unspecified great toe without damage to nail, initial',
  'encounter',
  'S91133D Puncture wound without foreign body of unspecified great toe without damage to nail,',
  'subsequent encounter',
  'S91133S Puncture wound without foreign body of unspecified great toe without damage to nail, sequela',
  'S91134A Puncture wound without foreign body of right lesser toe(s) without damage to nail, initial',
  'encounter',
  'S91134D Puncture wound without foreign body of right lesser toe(s) without damage to nail,',
  'subsequent encounter',
  'S91134S Puncture wound without foreign body of right lesser toe(s) without damage to nail, sequela',
  'S91135A Puncture wound without foreign body of left lesser toe(s) without damage to nail, initial',
  'encounter',
  'S91135D Puncture wound without foreign body of left lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91135S Puncture wound without foreign body of left lesser toe(s) without damage to nail, sequela',
  'S91136A Puncture wound without foreign body of unspecified lesser toe(s) without damage to nail,',
  'initial encounter',
  'S91136D Puncture wound without foreign body of unspecified lesser toe(s) without damage to nail,',
  'subsequent encounter',
  'S91136S Puncture wound without foreign body of unspecified lesser toe(s) without damage to nail,',
  'sequela',
  'S91139A Puncture wound without foreign body of unspecified toe(s) without damage to nail, initial',
  'encounter',
  'S91139D Puncture wound without foreign body of unspecified toe(s) without damage to nail,',
  'subsequent encounter',
  'S91139S Puncture wound without foreign body of unspecified toe(s) without damage to nail, sequela',
  'S91141A Puncture wound with foreign body of right great toe without damage to nail, initial encounter',
  'S91141D Puncture wound with foreign body of right great toe without damage to nail, subsequent',
  'encounter',
  'S91141S Puncture wound with foreign body of right great toe without damage to nail, sequela',
  'S91142A Puncture wound with foreign body of left great toe without damage to nail, initial encounter',
  'S91142D Puncture wound with foreign body of left great toe without damage to nail, subsequent',
  'encounter',
  'S91142S Puncture wound with foreign body of left great toe without damage to nail, sequela',
  'S91143A Puncture wound with foreign body of unspecified great toe without damage to nail, initial',
  'encounter',
  'S91143D Puncture wound with foreign body of unspecified great toe without damage to nail,',
  'subsequent encounter',
  'S91143S Puncture wound with foreign body of unspecified great toe without damage to nail, sequela',
  'S91144A Puncture wound with foreign body of right lesser toe(s) without damage to nail, initial',
  'encounter',
  'S91144D Puncture wound with foreign body of right lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91144S Puncture wound with foreign body of right lesser toe(s) without damage to nail, sequela',
  'S91145A Puncture wound with foreign body of left lesser toe(s) without damage to nail, initial',
  'encounter',
  'S91145D Puncture wound with foreign body of left lesser toe(s) without damage to nail, subsequent',
  'encounter',
  'S91145S Puncture wound with foreign body of left lesser toe(s) without damage to nail, sequela',
  'S91146A Puncture wound with foreign body of unspecified lesser toe(s) without damage to nail, initial',
  'encounter',
  'S91146D Puncture wound with foreign body of unspecified lesser toe(s) without damage to nail,',
  'subsequent encounter',
  'S91146S Puncture wound with foreign body of unspecified lesser toe(s) without damage to nail, sequela',
  'S91149A Puncture wound with foreign body of unspecified toe(s) without damage to nail, initial',
  'encounter',
  'S91149D Puncture wound with foreign body of unspecified toe(s) without damage to nail, subsequent',
  'encounter',
  'S91149S Puncture wound with foreign body of unspecified toe(s) without damage to nail, sequela',
  'S91151A Open bite of right great toe without damage to nail, initial encounter',
  'S91151D Open bite of right great toe without damage to nail, subsequent encounter',
  'S91151S Open bite of right great toe without damage to nail, sequela',
  'S91152A Open bite of left great toe without damage to nail, initial encounter',
  'S91152D Open bite of left great toe without damage to nail, subsequent encounter',
  'S91152S Open bite of left great toe without damage to nail, sequela',
  'S91153A Open bite of unspecified great toe without damage to nail, initial encounter',
  'S91153D Open bite of unspecified great toe without damage to nail, subsequent encounter',
  'S91153S Open bite of unspecified great toe without damage to nail, sequela',
  'S91154A Open bite of right lesser toe(s) without damage to nail, initial encounter',
  'S91154D Open bite of right lesser toe(s) without damage to nail, subsequent encounter',
  'S91154S Open bite of right lesser toe(s) without damage to nail, sequela',
  'S91155A Open bite of left lesser toe(s) without damage to nail, initial encounter',
  'S91155D Open bite of left lesser toe(s) without damage to nail, subsequent encounter',
  'S91155S Open bite of left lesser toe(s) without damage to nail, sequela',
  'S91156A Open bite of unspecified lesser toe(s) without damage to nail, initial encounter',
  'S91156D Open bite of unspecified lesser toe(s) without damage to nail, subsequent encounter',
  'S91156S Open bite of unspecified lesser toe(s) without damage to nail, sequela',
  'S91159A Open bite of unspecified toe(s) without damage to nail, initial encounter',
  'S91159D Open bite of unspecified toe(s) without damage to nail, subsequent encounter',
  'S91159S Open bite of unspecified toe(s) without damage to nail, sequela',
  'S91201A Unspecified open wound of right great toe with damage to nail, initial encounter',
  'S91201D Unspecified open wound of right great toe with damage to nail, subsequent encounter',
  'S91201S Unspecified open wound of right great toe with damage to nail, sequela',
  'S91202A Unspecified open wound of left great toe with damage to nail, initial encounter',
  'S91202D Unspecified open wound of left great toe with damage to nail, subsequent encounter',
  'S91202S Unspecified open wound of left great toe with damage to nail, sequela',
  'S91203A Unspecified open wound of unspecified great toe with damage to nail, initial encounter',
  'S91203D Unspecified open wound of unspecified great toe with damage to nail, subsequent encounter',
  'S91203S Unspecified open wound of unspecified great toe with damage to nail, sequela',
  'S91204A Unspecified open wound of right lesser toe(s) with damage to nail, initial encounter',
  'S91204D Unspecified open wound of right lesser toe(s) with damage to nail, subsequent encounter',
  'S91204S Unspecified open wound of right lesser toe(s) with damage to nail, sequela',
  'S91205A Unspecified open wound of left lesser toe(s) with damage to nail, initial encounter',
  'S91205D Unspecified open wound of left lesser toe(s) with damage to nail, subsequent encounter',
  'S91205S Unspecified open wound of left lesser toe(s) with damage to nail, sequela',
  'S91206A Unspecified open wound of unspecified lesser toe(s) with damage to nail, initial encounter',
  'S91206D Unspecified open wound of unspecified lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91206S Unspecified open wound of unspecified lesser toe(s) with damage to nail, sequela',
  'S91209A Unspecified open wound of unspecified toe(s) with damage to nail, initial encounter',
  'S91209D Unspecified open wound of unspecified toe(s) with damage to nail, subsequent encounter',
  'S91209S Unspecified open wound of unspecified toe(s) with damage to nail, sequela',
  'S91211A Laceration without foreign body of right great toe with damage to nail, initial encounter',
  'S91211D Laceration without foreign body of right great toe with damage to nail, subsequent encounter',
  'S91211S Laceration without foreign body of right great toe with damage to nail, sequela',
  'S91212A Laceration without foreign body of left great toe with damage to nail, initial encounter',
  'S91212D Laceration without foreign body of left great toe with damage to nail, subsequent encounter',
  'S91212S Laceration without foreign body of left great toe with damage to nail, sequela',
  'S91213A Laceration without foreign body of unspecified great toe with damage to nail, initial encounter',
  'S91213D Laceration without foreign body of unspecified great toe with damage to nail, subsequent',
  'encounter',
  'S91213S Laceration without foreign body of unspecified great toe with damage to nail, sequela',
  'S91214A Laceration without foreign body of right lesser toe(s) with damage to nail, initial encounter',
  'S91214D Laceration without foreign body of right lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91214S Laceration without foreign body of right lesser toe(s) with damage to nail, sequela',
  'S91215A Laceration without foreign body of left lesser toe(s) with damage to nail, initial encounter',
  'S91215D Laceration without foreign body of left lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91215S Laceration without foreign body of left lesser toe(s) with damage to nail, sequela',
  'S91216A Laceration without foreign body of unspecified lesser toe(s) with damage to nail, initial',
  'encounter',
  'S91216D Laceration without foreign body of unspecified lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91216S Laceration without foreign body of unspecified lesser toe(s) with damage to nail, sequela',
  'S91219A Laceration without foreign body of unspecified toe(s) with damage to nail, initial encounter',
  'S91219D Laceration without foreign body of unspecified toe(s) with damage to nail, subsequent',
  'encounter',
  'S91219S Laceration without foreign body of unspecified toe(s) with damage to nail, sequela',
  'S91221A Laceration with foreign body of right great toe with damage to nail, initial encounter',
  'S91221D Laceration with foreign body of right great toe with damage to nail, subsequent encounter',
  'S91221S Laceration with foreign body of right great toe with damage to nail, sequela',
  'S91222A Laceration with foreign body of left great toe with damage to nail, initial encounter',
  'S91222D Laceration with foreign body of left great toe with damage to nail, subsequent encounter',
  'S91222S Laceration with foreign body of left great toe with damage to nail, sequela',
  'S91223A Laceration with foreign body of unspecified great toe with damage to nail, initial encounter',
  'S91223D Laceration with foreign body of unspecified great toe with damage to nail, subsequent',
  'encounter',
  'S91223S Laceration with foreign body of unspecified great toe with damage to nail, sequela',
  'S91224A Laceration with foreign body of right lesser toe(s) with damage to nail, initial encounter',
  'S91224D Laceration with foreign body of right lesser toe(s) with damage to nail, subsequent encounter',
  'S91224S Laceration with foreign body of right lesser toe(s) with damage to nail, sequela',
  'S91225A Laceration with foreign body of left lesser toe(s) with damage to nail, initial encounter',
  'S91225D Laceration with foreign body of left lesser toe(s) with damage to nail, subsequent encounter',
  'S91225S Laceration with foreign body of left lesser toe(s) with damage to nail, sequela',
  'S91226A Laceration with foreign body of unspecified lesser toe(s) with damage to nail, initial encounter',
  'S91226D Laceration with foreign body of unspecified lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91226S Laceration with foreign body of unspecified lesser toe(s) with damage to nail, sequela',
  'S91229A Laceration with foreign body of unspecified toe(s) with damage to nail, initial encounter',
  'S91229D Laceration with foreign body of unspecified toe(s) with damage to nail, subsequent encounter',
  'S91229S Laceration with foreign body of unspecified toe(s) with damage to nail, sequela',
  'S91231A Puncture wound without foreign body of right great toe with damage to nail, initial encounter',
  'S91231D Puncture wound without foreign body of right great toe with damage to nail, subsequent',
  'encounter',
  'S91231S Puncture wound without foreign body of right great toe with damage to nail, sequela',
  'S91232A Puncture wound without foreign body of left great toe with damage to nail, initial encounter',
  'S91232D Puncture wound without foreign body of left great toe with damage to nail, subsequent',
  'encounter',
  'S91232S Puncture wound without foreign body of left great toe with damage to nail, sequela',
  'S91233A Puncture wound without foreign body of unspecified great toe with damage to nail, initial',
  'encounter',
  'S91233D Puncture wound without foreign body of unspecified great toe with damage to nail,',
  'subsequent encounter',
  'S91233S Puncture wound without foreign body of unspecified great toe with damage to nail, sequela',
  'S91234A Puncture wound without foreign body of right lesser toe(s) with damage to nail, initial',
  'encounter',
  'S91234D Puncture wound without foreign body of right lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91234S Puncture wound without foreign body of right lesser toe(s) with damage to nail, sequela',
  'S91235A Puncture wound without foreign body of left lesser toe(s) with damage to nail, initial',
  'encounter',
  'S91235D Puncture wound without foreign body of left lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91235S Puncture wound without foreign body of left lesser toe(s) with damage to nail, sequela',
  'S91236A Puncture wound without foreign body of unspecified lesser toe(s) with damage to nail, initial',
  'encounter',
  'S91236D Puncture wound without foreign body of unspecified lesser toe(s) with damage to nail,',
  'subsequent encounter',
  'S91236S Puncture wound without foreign body of unspecified lesser toe(s) with damage to nail, sequela',
  'S91239A Puncture wound without foreign body of unspecified toe(s) with damage to nail, initial',
  'encounter',
  'S91239D Puncture wound without foreign body of unspecified toe(s) with damage to nail, subsequent',
  'encounter',
  'S91239S Puncture wound without foreign body of unspecified toe(s) with damage to nail, sequela',
  'S91241A Puncture wound with foreign body of right great toe with damage to nail, initial encounter',
  'S91241D Puncture wound with foreign body of right great toe with damage to nail, subsequent',
  'encounter',
  'S91241S Puncture wound with foreign body of right great toe with damage to nail, sequela',
  'S91242A Puncture wound with foreign body of left great toe with damage to nail, initial encounter',
  'S91242D Puncture wound with foreign body of left great toe with damage to nail, subsequent encounter',
  'S91242S Puncture wound with foreign body of left great toe with damage to nail, sequela',
  'S91243A Puncture wound with foreign body of unspecified great toe with damage to nail, initial',
  'encounter',
  'S91243D Puncture wound with foreign body of unspecified great toe with damage to nail, subsequent',
  'encounter',
  'S91243S Puncture wound with foreign body of unspecified great toe with damage to nail, sequela',
  'S91244A Puncture wound with foreign body of right lesser toe(s) with damage to nail, initial encounter',
  'S91244D Puncture wound with foreign body of right lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91244S Puncture wound with foreign body of right lesser toe(s) with damage to nail, sequela',
  'S91245A Puncture wound with foreign body of left lesser toe(s) with damage to nail, initial encounter',
  'S91245D Puncture wound with foreign body of left lesser toe(s) with damage to nail, subsequent',
  'encounter',
  'S91245S Puncture wound with foreign body of left lesser toe(s) with damage to nail, sequela',
  'S91246A Puncture wound with foreign body of unspecified lesser toe(s) with damage to nail, initial',
  'encounter',
  'S91246D Puncture wound with foreign body of unspecified lesser toe(s) with damage to nail,',
  'subsequent encounter',
  'S91246S Puncture wound with foreign body of unspecified lesser toe(s) with damage to nail, sequela',
  'S91249A Puncture wound with foreign body of unspecified toe(s) with damage to nail, initial encounter',
  'S91249D Puncture wound with foreign body of unspecified toe(s) with damage to nail, subsequent',
  'encounter',
  'S91249S Puncture wound with foreign body of unspecified toe(s) with damage to nail, sequela',
  'S91251A Open bite of right great toe with damage to nail, initial encounter',
  'S91251D Open bite of right great toe with damage to nail, subsequent encounter',
  'S91251S Open bite of right great toe with damage to nail, sequela',
  'S91252A Open bite of left great toe with damage to nail, initial encounter',
  'S91252D Open bite of left great toe with damage to nail, subsequent encounter',
  'S91252S Open bite of left great toe with damage to nail, sequela',
  'S91253A Open bite of unspecified great toe with damage to nail, initial encounter',
  'S91253D Open bite of unspecified great toe with damage to nail, subsequent encounter',
  'S91253S Open bite of unspecified great toe with damage to nail, sequela',
  'S91254A Open bite of right lesser toe(s) with damage to nail, initial encounter',
  'S91254D Open bite of right lesser toe(s) with damage to nail, subsequent encounter',
  'S91254S Open bite of right lesser toe(s) with damage to nail, sequela',
  'S91255A Open bite of left lesser toe(s) with damage to nail, initial encounter',
  'S91255D Open bite of left lesser toe(s) with damage to nail, subsequent encounter',
  'S91255S Open bite of left lesser toe(s) with damage to nail, sequela',
  'S91256A Open bite of unspecified lesser toe(s) with damage to nail, initial encounter',
  'S91256D Open bite of unspecified lesser toe(s) with damage to nail, subsequent encounter',
  'S91256S Open bite of unspecified lesser toe(s) with damage to nail, sequela',
  'S91259A Open bite of unspecified toe(s) with damage to nail, initial encounter',
  'S91259D Open bite of unspecified toe(s) with damage to nail, subsequent encounter',
  'S91259S Open bite of unspecified toe(s) with damage to nail, sequela',
  'S91301A Unspecified open wound, right foot, initial encounter',
  'S91301D Unspecified open wound, right foot, subsequent encounter',
  'S91301S Unspecified open wound, right foot, sequela',
  'S91302A Unspecified open wound, left foot, initial encounter',
  'S91302D Unspecified open wound, left foot, subsequent encounter',
  'S91302S Unspecified open wound, left foot, sequela',
  'S91309A Unspecified open wound, unspecified foot, initial encounter',
  'S91309D Unspecified open wound, unspecified foot, subsequent encounter',
  'S91309S Unspecified open wound, unspecified foot, sequela',
  'S91311A Laceration without foreign body, right foot, initial encounter',
  'S91311D Laceration without foreign body, right foot, subsequent encounter',
  'S91311S Laceration without foreign body, right foot, sequela',
  'S91312A Laceration without foreign body, left foot, initial encounter',
  'S91312D Laceration without foreign body, left foot, subsequent encounter',
  'S91312S Laceration without foreign body, left foot, sequela',
  'S91319A Laceration without foreign body, unspecified foot, initial encounter',
  'S91319D Laceration without foreign body, unspecified foot, subsequent encounter',
  'S91319S Laceration without foreign body, unspecified foot, sequela',
  'S91321A Laceration with foreign body, right foot, initial encounter',
  'S91321D Laceration with foreign body, right foot, subsequent encounter',
  'S91321S Laceration with foreign body, right foot, sequela',
  'S91322A Laceration with foreign body, left foot, initial encounter',
  'S91322D Laceration with foreign body, left foot, subsequent encounter',
  'S91322S Laceration with foreign body, left foot, sequela',
  'S91329A Laceration with foreign body, unspecified foot, initial encounter',
  'S91329D Laceration with foreign body, unspecified foot, subsequent encounter',
  'S91329S Laceration with foreign body, unspecified foot, sequela',
  'S91331A Puncture wound without foreign body, right foot, initial encounter',
  'S91331D Puncture wound without foreign body, right foot, subsequent encounter',
  'S91331S Puncture wound without foreign body, right foot, sequela',
  'S91332A Puncture wound without foreign body, left foot, initial encounter',
  'S91332D Puncture wound without foreign body, left foot, subsequent encounter',
  'S91332S Puncture wound without foreign body, left foot, sequela',
  'S91339A Puncture wound without foreign body, unspecified foot, initial encounter',
  'S91339D Puncture wound without foreign body, unspecified foot, subsequent encounter',
  'S91339S Puncture wound without foreign body, unspecified foot, sequela',
  'S91341A Puncture wound with foreign body, right foot, initial encounter',
  'S91341D Puncture wound with foreign body, right foot, subsequent encounter',
  'S91341S Puncture wound with foreign body, right foot, sequela',
  'S91342A Puncture wound with foreign body, left foot, initial encounter',
  'S91342D Puncture wound with foreign body, left foot, subsequent encounter',
  'S91342S Puncture wound with foreign body, left foot, sequela',
  'S91349A Puncture wound with foreign body, unspecified foot, initial encounter',
  'S91349D Puncture wound with foreign body, unspecified foot, subsequent encounter',
  'S91349S Puncture wound with foreign body, unspecified foot, sequela',
  'S91351A Open bite, right foot, initial encounter',
  'S91351D Open bite, right foot, subsequent encounter',
  'S91351S Open bite, right foot, sequela',
  'S91352A Open bite, left foot, initial encounter',
  'S91352D Open bite, left foot, subsequent encounter',
  'S91352S Open bite, left foot, sequela',
  'S91359A Open bite, unspecified foot, initial encounter',
  'S91359D Open bite, unspecified foot, subsequent encounter',
  'S91359S Open bite, unspecified foot, sequela',
  'S92001A Unspecified fracture of right calcaneus, initial encounter for closed fracture',
  'S92001B Unspecified fracture of right calcaneus, initial encounter for open fracture',
  'S92001D Unspecified fracture of right calcaneus, subsequent encounter for fracture with routine healing',
  'S92001G Unspecified fracture of right calcaneus, subsequent encounter for fracture with delayed',
  'healing',
  'S92001K Unspecified fracture of right calcaneus, subsequent encounter for fracture with nonunion',
  'S92001P Unspecified fracture of right calcaneus, subsequent encounter for fracture with malunion',
  'S92001S Unspecified fracture of right calcaneus, sequela',
  'S92002A Unspecified fracture of left calcaneus, initial encounter for closed fracture',
  'S92002B Unspecified fracture of left calcaneus, initial encounter for open fracture',
  'S92002D Unspecified fracture of left calcaneus, subsequent encounter for fracture with routine healing',
  'S92002G Unspecified fracture of left calcaneus, subsequent encounter for fracture with delayed healing',
  'S92002K Unspecified fracture of left calcaneus, subsequent encounter for fracture with nonunion',
  'S92002P Unspecified fracture of left calcaneus, subsequent encounter for fracture with malunion',
  'S92002S Unspecified fracture of left calcaneus, sequela',
  'S92009A Unspecified fracture of unspecified calcaneus, initial encounter for closed fracture',
  'S92009B Unspecified fracture of unspecified calcaneus, initial encounter for open fracture',
  'S92009D Unspecified fracture of unspecified calcaneus, subsequent encounter for fracture with routine',
  'healing',
  'S92009G Unspecified fracture of unspecified calcaneus, subsequent encounter for fracture with delayed',
  'healing',
  'S92009K Unspecified fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92009P Unspecified fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92009S Unspecified fracture of unspecified calcaneus, sequela',
  'S92011A Displaced fracture of body of right calcaneus, initial encounter for closed fracture',
  'S92011B Displaced fracture of body of right calcaneus, initial encounter for open fracture',
  'S92011D Displaced fracture of body of right calcaneus, subsequent encounter for fracture with routine',
  'healing',
  'S92011G Displaced fracture of body of right calcaneus, subsequent encounter for fracture with delayed',
  'healing',
  'S92011K Displaced fracture of body of right calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92011P Displaced fracture of body of right calcaneus, subsequent encounter for fracture with malunion',
  'S92011S Displaced fracture of body of right calcaneus, sequela',
  'S92012A Displaced fracture of body of left calcaneus, initial encounter for closed fracture',
  'S92012B Displaced fracture of body of left calcaneus, initial encounter for open fracture',
  'S92012D Displaced fracture of body of left calcaneus, subsequent encounter for fracture with routine',
  'healing',
  'S92012G Displaced fracture of body of left calcaneus, subsequent encounter for fracture with delayed',
  'healing',
  'S92012K Displaced fracture of body of left calcaneus, subsequent encounter for fracture with nonunion',
  'S92012P Displaced fracture of body of left calcaneus, subsequent encounter for fracture with malunion',
  'S92012S Displaced fracture of body of left calcaneus, sequela',
  'S92013A Displaced fracture of body of unspecified calcaneus, initial encounter for closed fracture',
  'S92013B Displaced fracture of body of unspecified calcaneus, initial encounter for open fracture',
  'S92013D Displaced fracture of body of unspecified calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S92013G Displaced fracture of body of unspecified calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S92013K Displaced fracture of body of unspecified calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92013P Displaced fracture of body of unspecified calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92013S Displaced fracture of body of unspecified calcaneus, sequela',
  'S92014A Nondisplaced fracture of body of right calcaneus, initial encounter for closed fracture',
  'S92014B Nondisplaced fracture of body of right calcaneus, initial encounter for open fracture',
  'S92014D Nondisplaced fracture of body of right calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S92014G Nondisplaced fracture of body of right calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S92014K Nondisplaced fracture of body of right calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92014P Nondisplaced fracture of body of right calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92014S Nondisplaced fracture of body of right calcaneus, sequela',
  'S92015A Nondisplaced fracture of body of left calcaneus, initial encounter for closed fracture',
  'S92015B Nondisplaced fracture of body of left calcaneus, initial encounter for open fracture',
  'S92015D Nondisplaced fracture of body of left calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S92015G Nondisplaced fracture of body of left calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S92015K Nondisplaced fracture of body of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92015P Nondisplaced fracture of body of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92015S Nondisplaced fracture of body of left calcaneus, sequela',
  'S92016A Nondisplaced fracture of body of unspecified calcaneus, initial encounter for closed fracture',
  'S92016B Nondisplaced fracture of body of unspecified calcaneus, initial encounter for open fracture',
  'S92016D Nondisplaced fracture of body of unspecified calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92016G Nondisplaced fracture of body of unspecified calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92016K Nondisplaced fracture of body of unspecified calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92016P Nondisplaced fracture of body of unspecified calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92016S Nondisplaced fracture of body of unspecified calcaneus, sequela',
  'S92021A Displaced fracture of anterior process of right calcaneus, initial encounter for closed fracture',
  'S92021B Displaced fracture of anterior process of right calcaneus, initial encounter for open fracture',
  'S92021D Displaced fracture of anterior process of right calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92021G Displaced fracture of anterior process of right calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92021K Displaced fracture of anterior process of right calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92021P Displaced fracture of anterior process of right calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92021S Displaced fracture of anterior process of right calcaneus, sequela',
  'S92022A Displaced fracture of anterior process of left calcaneus, initial encounter for closed fracture',
  'S92022B Displaced fracture of anterior process of left calcaneus, initial encounter for open fracture',
  'S92022D Displaced fracture of anterior process of left calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92022G Displaced fracture of anterior process of left calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92022K Displaced fracture of anterior process of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92022P Displaced fracture of anterior process of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92022S Displaced fracture of anterior process of left calcaneus, sequela',
  'S92023A Displaced fracture of anterior process of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92023B Displaced fracture of anterior process of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92023D Displaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92023G Displaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92023K Displaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92023P Displaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92023S Displaced fracture of anterior process of unspecified calcaneus, sequela',
  'S92024A Nondisplaced fracture of anterior process of right calcaneus, initial encounter for closed',
  'fracture',
  'S92024B Nondisplaced fracture of anterior process of right calcaneus, initial encounter for open fracture',
  'S92024D Nondisplaced fracture of anterior process of right calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92024G Nondisplaced fracture of anterior process of right calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92024K Nondisplaced fracture of anterior process of right calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92024P Nondisplaced fracture of anterior process of right calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92024S Nondisplaced fracture of anterior process of right calcaneus, sequela',
  'S92025A Nondisplaced fracture of anterior process of left calcaneus, initial encounter for closed fracture',
  'S92025B Nondisplaced fracture of anterior process of left calcaneus, initial encounter for open fracture',
  'S92025D Nondisplaced fracture of anterior process of left calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92025G Nondisplaced fracture of anterior process of left calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92025K Nondisplaced fracture of anterior process of left calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92025P Nondisplaced fracture of anterior process of left calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92025S Nondisplaced fracture of anterior process of left calcaneus, sequela',
  'S92026A Nondisplaced fracture of anterior process of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92026B Nondisplaced fracture of anterior process of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92026D Nondisplaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92026G Nondisplaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92026K Nondisplaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92026P Nondisplaced fracture of anterior process of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92026S Nondisplaced fracture of anterior process of unspecified calcaneus, sequela',
  'S92031A Displaced avulsion fracture of tuberosity of right calcaneus, initial encounter for closed fracture',
  'S92031B Displaced avulsion fracture of tuberosity of right calcaneus, initial encounter for open fracture',
  'S92031D Displaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92031G Displaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92031K Displaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92031P Displaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92031S Displaced avulsion fracture of tuberosity of right calcaneus, sequela',
  'S92032A Displaced avulsion fracture of tuberosity of left calcaneus, initial encounter for closed fracture',
  'S92032B Displaced avulsion fracture of tuberosity of left calcaneus, initial encounter for open fracture',
  'S92032D Displaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92032G Displaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92032K Displaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92032P Displaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92032S Displaced avulsion fracture of tuberosity of left calcaneus, sequela',
  'S92033A Displaced avulsion fracture of tuberosity of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92033B Displaced avulsion fracture of tuberosity of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92033D Displaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92033G Displaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92033K Displaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92033P Displaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92033S Displaced avulsion fracture of tuberosity of unspecified calcaneus, sequela',
  'S92034A Nondisplaced avulsion fracture of tuberosity of right calcaneus, initial encounter for closed',
  'fracture',
  'S92034B Nondisplaced avulsion fracture of tuberosity of right calcaneus, initial encounter for open',
  'fracture',
  'S92034D Nondisplaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92034G Nondisplaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92034K Nondisplaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92034P Nondisplaced avulsion fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92034S Nondisplaced avulsion fracture of tuberosity of right calcaneus, sequela',
  'S92035A Nondisplaced avulsion fracture of tuberosity of left calcaneus, initial encounter for closed',
  'fracture',
  'S92035B Nondisplaced avulsion fracture of tuberosity of left calcaneus, initial encounter for open',
  'fracture',
  'S92035D Nondisplaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92035G Nondisplaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92035K Nondisplaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92035P Nondisplaced avulsion fracture of tuberosity of left calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92035S Nondisplaced avulsion fracture of tuberosity of left calcaneus, sequela',
  'S92036A Nondisplaced avulsion fracture of tuberosity of unspecified calcaneus, initial encounter for',
  'closed fracture',
  'S92036B Nondisplaced avulsion fracture of tuberosity of unspecified calcaneus, initial encounter for',
  'open fracture',
  'S92036D Nondisplaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter',
  'for fracture with routine healing',
  'S92036G Nondisplaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter',
  'for fracture with delayed healing',
  'S92036K Nondisplaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter',
  'for fracture with nonunion',
  'S92036P Nondisplaced avulsion fracture of tuberosity of unspecified calcaneus, subsequent encounter',
  'for fracture with malunion',
  'S92036S Nondisplaced avulsion fracture of tuberosity of unspecified calcaneus, sequela',
  'S92041A Displaced other fracture of tuberosity of right calcaneus, initial encounter for closed fracture',
  'S92041B Displaced other fracture of tuberosity of right calcaneus, initial encounter for open fracture',
  'S92041D Displaced other fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92041G Displaced other fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92041K Displaced other fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92041P Displaced other fracture of tuberosity of right calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92041S Displaced other fracture of tuberosity of right calcaneus, sequela',
  'S92042A Displaced other fracture of tuberosity of left calcaneus, initial encounter for closed fracture',
  'S92042B Displaced other fracture of tuberosity of left calcaneus, initial encounter for open fracture',
  'S92042D Displaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92042G Displaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92042K Displaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92042P Displaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92042S Displaced other fracture of tuberosity of left calcaneus, sequela',
  'S92043A Displaced other fracture of tuberosity of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92043B Displaced other fracture of tuberosity of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92043D Displaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92043G Displaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92043K Displaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92043P Displaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92043S Displaced other fracture of tuberosity of unspecified calcaneus, sequela',
  'S92044A Nondisplaced other fracture of tuberosity of right calcaneus, initial encounter for closed',
  'fracture',
  'S92044B Nondisplaced other fracture of tuberosity of right calcaneus, initial encounter for open fracture',
  'S92044D Nondisplaced other fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92044G Nondisplaced other fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92044K Nondisplaced other fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92044P Nondisplaced other fracture of tuberosity of right calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92044S Nondisplaced other fracture of tuberosity of right calcaneus, sequela',
  'S92045A Nondisplaced other fracture of tuberosity of left calcaneus, initial encounter for closed fracture',
  'S92045B Nondisplaced other fracture of tuberosity of left calcaneus, initial encounter for open fracture',
  'S92045D Nondisplaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92045G Nondisplaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92045K Nondisplaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92045P Nondisplaced other fracture of tuberosity of left calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92045S Nondisplaced other fracture of tuberosity of left calcaneus, sequela',
  'S92046A Nondisplaced other fracture of tuberosity of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92046B Nondisplaced other fracture of tuberosity of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92046D Nondisplaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92046G Nondisplaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92046K Nondisplaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92046P Nondisplaced other fracture of tuberosity of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92046S Nondisplaced other fracture of tuberosity of unspecified calcaneus, sequela',
  'S92051A Displaced other extraarticular fracture of right calcaneus, initial encounter for closed fracture',
  'S92051B Displaced other extraarticular fracture of right calcaneus, initial encounter for open fracture',
  'S92051D Displaced other extraarticular fracture of right calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92051G Displaced other extraarticular fracture of right calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92051K Displaced other extraarticular fracture of right calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92051P Displaced other extraarticular fracture of right calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92051S Displaced other extraarticular fracture of right calcaneus, sequela',
  'S92052A Displaced other extraarticular fracture of left calcaneus, initial encounter for closed fracture',
  'S92052B Displaced other extraarticular fracture of left calcaneus, initial encounter for open fracture',
  'S92052D Displaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92052G Displaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92052K Displaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92052P Displaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92052S Displaced other extraarticular fracture of left calcaneus, sequela',
  'S92053A Displaced other extraarticular fracture of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92053B Displaced other extraarticular fracture of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92053D Displaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92053G Displaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92053K Displaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92053P Displaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92053S Displaced other extraarticular fracture of unspecified calcaneus, sequela',
  'S92054A Nondisplaced other extraarticular fracture of right calcaneus, initial encounter for closed',
  'fracture',
  'S92054B Nondisplaced other extraarticular fracture of right calcaneus, initial encounter for open',
  'fracture',
  'S92054D Nondisplaced other extraarticular fracture of right calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92054G Nondisplaced other extraarticular fracture of right calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92054K Nondisplaced other extraarticular fracture of right calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92054P Nondisplaced other extraarticular fracture of right calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92054S Nondisplaced other extraarticular fracture of right calcaneus, sequela',
  'S92055A Nondisplaced other extraarticular fracture of left calcaneus, initial encounter for closed',
  'fracture',
  'S92055B Nondisplaced other extraarticular fracture of left calcaneus, initial encounter for open fracture',
  'S92055D Nondisplaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92055G Nondisplaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92055K Nondisplaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92055P Nondisplaced other extraarticular fracture of left calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92055S Nondisplaced other extraarticular fracture of left calcaneus, sequela',
  'S92056A Nondisplaced other extraarticular fracture of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92056B Nondisplaced other extraarticular fracture of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92056D Nondisplaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92056G Nondisplaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92056K Nondisplaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92056P Nondisplaced other extraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92056S Nondisplaced other extraarticular fracture of unspecified calcaneus, sequela',
  'S92061A Displaced intraarticular fracture of right calcaneus, initial encounter for closed fracture',
  'S92061B Displaced intraarticular fracture of right calcaneus, initial encounter for open fracture',
  'S92061D Displaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S92061G Displaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S92061K Displaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92061P Displaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92061S Displaced intraarticular fracture of right calcaneus, sequela',
  'S92062A Displaced intraarticular fracture of left calcaneus, initial encounter for closed fracture',
  'S92062B Displaced intraarticular fracture of left calcaneus, initial encounter for open fracture',
  'S92062D Displaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S92062G Displaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S92062K Displaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92062P Displaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92062S Displaced intraarticular fracture of left calcaneus, sequela',
  'S92063A Displaced intraarticular fracture of unspecified calcaneus, initial encounter for closed fracture',
  'S92063B Displaced intraarticular fracture of unspecified calcaneus, initial encounter for open fracture',
  'S92063D Displaced intraarticular fracture of unspecified calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S92063G Displaced intraarticular fracture of unspecified calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S92063K Displaced intraarticular fracture of unspecified calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S92063P Displaced intraarticular fracture of unspecified calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S92063S Displaced intraarticular fracture of unspecified calcaneus, sequela',
  'S92064A Nondisplaced intraarticular fracture of right calcaneus, initial encounter for closed fracture',
  'S92064B Nondisplaced intraarticular fracture of right calcaneus, initial encounter for open fracture',
  'S92064D Nondisplaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S92064G Nondisplaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S92064K Nondisplaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92064P Nondisplaced intraarticular fracture of right calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92064S Nondisplaced intraarticular fracture of right calcaneus, sequela',
  'S92065A Nondisplaced intraarticular fracture of left calcaneus, initial encounter for closed fracture',
  'S92065B Nondisplaced intraarticular fracture of left calcaneus, initial encounter for open fracture',
  'S92065D Nondisplaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S92065G Nondisplaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S92065K Nondisplaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S92065P Nondisplaced intraarticular fracture of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S92065S Nondisplaced intraarticular fracture of left calcaneus, sequela',
  'S92066A Nondisplaced intraarticular fracture of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S92066B Nondisplaced intraarticular fracture of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S92066D Nondisplaced intraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S92066G Nondisplaced intraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S92066K Nondisplaced intraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S92066P Nondisplaced intraarticular fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S92066S Nondisplaced intraarticular fracture of unspecified calcaneus, sequela',
  'S92101A Unspecified fracture of right talus, initial encounter for closed fracture',
  'S92101B Unspecified fracture of right talus, initial encounter for open fracture',
  'S92101D Unspecified fracture of right talus, subsequent encounter for fracture with routine healing',
  'S92101G Unspecified fracture of right talus, subsequent encounter for fracture with delayed healing',
  'S92101K Unspecified fracture of right talus, subsequent encounter for fracture with nonunion',
  'S92101P Unspecified fracture of right talus, subsequent encounter for fracture with malunion',
  'S92101S Unspecified fracture of right talus, sequela',
  'S92102A Unspecified fracture of left talus, initial encounter for closed fracture',
  'S92102B Unspecified fracture of left talus, initial encounter for open fracture',
  'S92102D Unspecified fracture of left talus, subsequent encounter for fracture with routine healing',
  'S92102G Unspecified fracture of left talus, subsequent encounter for fracture with delayed healing',
  'S92102K Unspecified fracture of left talus, subsequent encounter for fracture with nonunion',
  'S92102P Unspecified fracture of left talus, subsequent encounter for fracture with malunion',
  'S92102S Unspecified fracture of left talus, sequela',
  'S92109A Unspecified fracture of unspecified talus, initial encounter for closed fracture',
  'S92109B Unspecified fracture of unspecified talus, initial encounter for open fracture',
  'S92109D Unspecified fracture of unspecified talus, subsequent encounter for fracture with routine',
  'healing',
  'S92109G Unspecified fracture of unspecified talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92109K Unspecified fracture of unspecified talus, subsequent encounter for fracture with nonunion',
  'S92109P Unspecified fracture of unspecified talus, subsequent encounter for fracture with malunion',
  'S92109S Unspecified fracture of unspecified talus, sequela',
  'S92111A Displaced fracture of neck of right talus, initial encounter for closed fracture',
  'S92111B Displaced fracture of neck of right talus, initial encounter for open fracture',
  'S92111D Displaced fracture of neck of right talus, subsequent encounter for fracture with routine',
  'healing',
  'S92111G Displaced fracture of neck of right talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92111K Displaced fracture of neck of right talus, subsequent encounter for fracture with nonunion',
  'S92111P Displaced fracture of neck of right talus, subsequent encounter for fracture with malunion',
  'S92111S Displaced fracture of neck of right talus, sequela',
  'S92112A Displaced fracture of neck of left talus, initial encounter for closed fracture',
  'S92112B Displaced fracture of neck of left talus, initial encounter for open fracture',
  'S92112D Displaced fracture of neck of left talus, subsequent encounter for fracture with routine healing',
  'S92112G Displaced fracture of neck of left talus, subsequent encounter for fracture with delayed healing',
  'S92112K Displaced fracture of neck of left talus, subsequent encounter for fracture with nonunion',
  'S92112P Displaced fracture of neck of left talus, subsequent encounter for fracture with malunion',
  'S92112S Displaced fracture of neck of left talus, sequela',
  'S92113A Displaced fracture of neck of unspecified talus, initial encounter for closed fracture',
  'S92113B Displaced fracture of neck of unspecified talus, initial encounter for open fracture',
  'S92113D Displaced fracture of neck of unspecified talus, subsequent encounter for fracture with routine',
  'healing',
  'S92113G Displaced fracture of neck of unspecified talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92113K Displaced fracture of neck of unspecified talus, subsequent encounter for fracture with',
  'nonunion',
  'S92113P Displaced fracture of neck of unspecified talus, subsequent encounter for fracture with',
  'malunion',
  'S92113S Displaced fracture of neck of unspecified talus, sequela',
  'S92114A Nondisplaced fracture of neck of right talus, initial encounter for closed fracture',
  'S92114B Nondisplaced fracture of neck of right talus, initial encounter for open fracture',
  'S92114D Nondisplaced fracture of neck of right talus, subsequent encounter for fracture with routine',
  'healing',
  'S92114G Nondisplaced fracture of neck of right talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92114K Nondisplaced fracture of neck of right talus, subsequent encounter for fracture with nonunion',
  'S92114P Nondisplaced fracture of neck of right talus, subsequent encounter for fracture with malunion',
  'S92114S Nondisplaced fracture of neck of right talus, sequela',
  'S92115A Nondisplaced fracture of neck of left talus, initial encounter for closed fracture',
  'S92115B Nondisplaced fracture of neck of left talus, initial encounter for open fracture',
  'S92115D Nondisplaced fracture of neck of left talus, subsequent encounter for fracture with routine',
  'healing',
  'S92115G Nondisplaced fracture of neck of left talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92115K Nondisplaced fracture of neck of left talus, subsequent encounter for fracture with nonunion',
  'S92115P Nondisplaced fracture of neck of left talus, subsequent encounter for fracture with malunion',
  'S92115S Nondisplaced fracture of neck of left talus, sequela',
  'S92116A Nondisplaced fracture of neck of unspecified talus, initial encounter for closed fracture',
  'S92116B Nondisplaced fracture of neck of unspecified talus, initial encounter for open fracture',
  'S92116D Nondisplaced fracture of neck of unspecified talus, subsequent encounter for fracture with',
  'routine healing',
  'S92116G Nondisplaced fracture of neck of unspecified talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92116K Nondisplaced fracture of neck of unspecified talus, subsequent encounter for fracture with',
  'nonunion',
  'S92116P Nondisplaced fracture of neck of unspecified talus, subsequent encounter for fracture with',
  'malunion',
  'S92116S Nondisplaced fracture of neck of unspecified talus, sequela',
  'S92121A Displaced fracture of body of right talus, initial encounter for closed fracture',
  'S92121B Displaced fracture of body of right talus, initial encounter for open fracture',
  'S92121D Displaced fracture of body of right talus, subsequent encounter for fracture with routine',
  'healing',
  'S92121G Displaced fracture of body of right talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92121K Displaced fracture of body of right talus, subsequent encounter for fracture with nonunion',
  'S92121P Displaced fracture of body of right talus, subsequent encounter for fracture with malunion',
  'S92121S Displaced fracture of body of right talus, sequela',
  'S92122A Displaced fracture of body of left talus, initial encounter for closed fracture',
  'S92122B Displaced fracture of body of left talus, initial encounter for open fracture',
  'S92122D Displaced fracture of body of left talus, subsequent encounter for fracture with routine healing',
  'S92122G Displaced fracture of body of left talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92122K Displaced fracture of body of left talus, subsequent encounter for fracture with nonunion',
  'S92122P Displaced fracture of body of left talus, subsequent encounter for fracture with malunion',
  'S92122S Displaced fracture of body of left talus, sequela',
  'S92123A Displaced fracture of body of unspecified talus, initial encounter for closed fracture',
  'S92123B Displaced fracture of body of unspecified talus, initial encounter for open fracture',
  'S92123D Displaced fracture of body of unspecified talus, subsequent encounter for fracture with routine',
  'healing',
  'S92123G Displaced fracture of body of unspecified talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92123K Displaced fracture of body of unspecified talus, subsequent encounter for fracture with',
  'nonunion',
  'S92123P Displaced fracture of body of unspecified talus, subsequent encounter for fracture with',
  'malunion',
  'S92123S Displaced fracture of body of unspecified talus, sequela',
  'S92124A Nondisplaced fracture of body of right talus, initial encounter for closed fracture',
  'S92124B Nondisplaced fracture of body of right talus, initial encounter for open fracture',
  'S92124D Nondisplaced fracture of body of right talus, subsequent encounter for fracture with routine',
  'healing',
  'S92124G Nondisplaced fracture of body of right talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92124K Nondisplaced fracture of body of right talus, subsequent encounter for fracture with nonunion',
  'S92124P Nondisplaced fracture of body of right talus, subsequent encounter for fracture with malunion',
  'S92124S Nondisplaced fracture of body of right talus, sequela',
  'S92125A Nondisplaced fracture of body of left talus, initial encounter for closed fracture',
  'S92125B Nondisplaced fracture of body of left talus, initial encounter for open fracture',
  'S92125D Nondisplaced fracture of body of left talus, subsequent encounter for fracture with routine',
  'healing',
  'S92125G Nondisplaced fracture of body of left talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92125K Nondisplaced fracture of body of left talus, subsequent encounter for fracture with nonunion',
  'S92125P Nondisplaced fracture of body of left talus, subsequent encounter for fracture with malunion',
  'S92125S Nondisplaced fracture of body of left talus, sequela',
  'S92126A Nondisplaced fracture of body of unspecified talus, initial encounter for closed fracture',
  'S92126B Nondisplaced fracture of body of unspecified talus, initial encounter for open fracture',
  'S92126D Nondisplaced fracture of body of unspecified talus, subsequent encounter for fracture with',
  'routine healing',
  'S92126G Nondisplaced fracture of body of unspecified talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92126K Nondisplaced fracture of body of unspecified talus, subsequent encounter for fracture with',
  'nonunion',
  'S92126P Nondisplaced fracture of body of unspecified talus, subsequent encounter for fracture with',
  'malunion',
  'S92126S Nondisplaced fracture of body of unspecified talus, sequela',
  'S92131A Displaced fracture of posterior process of right talus, initial encounter for closed fracture',
  'S92131B Displaced fracture of posterior process of right talus, initial encounter for open fracture',
  'S92131D Displaced fracture of posterior process of right talus, subsequent encounter for fracture with',
  'routine healing',
  'S92131G Displaced fracture of posterior process of right talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92131K Displaced fracture of posterior process of right talus, subsequent encounter for fracture with',
  'nonunion',
  'S92131P Displaced fracture of posterior process of right talus, subsequent encounter for fracture with',
  'malunion',
  'S92131S Displaced fracture of posterior process of right talus, sequela',
  'S92132A Displaced fracture of posterior process of left talus, initial encounter for closed fracture',
  'S92132B Displaced fracture of posterior process of left talus, initial encounter for open fracture',
  'S92132D Displaced fracture of posterior process of left talus, subsequent encounter for fracture with',
  'routine healing',
  'S92132G Displaced fracture of posterior process of left talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92132K Displaced fracture of posterior process of left talus, subsequent encounter for fracture with',
  'nonunion',
  'S92132P Displaced fracture of posterior process of left talus, subsequent encounter for fracture with',
  'malunion',
  'S92132S Displaced fracture of posterior process of left talus, sequela',
  'S92133A Displaced fracture of posterior process of unspecified talus, initial encounter for closed',
  'fracture',
  'S92133B Displaced fracture of posterior process of unspecified talus, initial encounter for open fracture',
  'S92133D Displaced fracture of posterior process of unspecified talus, subsequent encounter for fracture',
  'with routine healing',
  'S92133G Displaced fracture of posterior process of unspecified talus, subsequent encounter for fracture',
  'with delayed healing',
  'S92133K Displaced fracture of posterior process of unspecified talus, subsequent encounter for fracture',
  'with nonunion',
  'S92133P Displaced fracture of posterior process of unspecified talus, subsequent encounter for fracture',
  'with malunion',
  'S92133S Displaced fracture of posterior process of unspecified talus, sequela',
  'S92134A Nondisplaced fracture of posterior process of right talus, initial encounter for closed fracture',
  'S92134B Nondisplaced fracture of posterior process of right talus, initial encounter for open fracture',
  'S92134D Nondisplaced fracture of posterior process of right talus, subsequent encounter for fracture',
  'with routine healing',
  'S92134G Nondisplaced fracture of posterior process of right talus, subsequent encounter for fracture',
  'with delayed healing',
  'S92134K Nondisplaced fracture of posterior process of right talus, subsequent encounter for fracture',
  'with nonunion',
  'S92134P Nondisplaced fracture of posterior process of right talus, subsequent encounter for fracture',
  'with malunion',
  'S92134S Nondisplaced fracture of posterior process of right talus, sequela',
  'S92135A Nondisplaced fracture of posterior process of left talus, initial encounter for closed fracture',
  'S92135B Nondisplaced fracture of posterior process of left talus, initial encounter for open fracture',
  'S92135D Nondisplaced fracture of posterior process of left talus, subsequent encounter for fracture',
  'with routine healing',
  'S92135G Nondisplaced fracture of posterior process of left talus, subsequent encounter for fracture',
  'with delayed healing',
  'S92135K Nondisplaced fracture of posterior process of left talus, subsequent encounter for fracture with',
  'nonunion',
  'S92135P Nondisplaced fracture of posterior process of left talus, subsequent encounter for fracture with',
  'malunion',
  'S92135S Nondisplaced fracture of posterior process of left talus, sequela',
  'S92136A Nondisplaced fracture of posterior process of unspecified talus, initial encounter for closed',
  'fracture',
  'S92136B Nondisplaced fracture of posterior process of unspecified talus, initial encounter for open',
  'fracture',
  'S92136D Nondisplaced fracture of posterior process of unspecified talus, subsequent encounter for',
  'fracture with routine healing',
  'S92136G Nondisplaced fracture of posterior process of unspecified talus, subsequent encounter for',
  'fracture with delayed healing',
  'S92136K Nondisplaced fracture of posterior process of unspecified talus, subsequent encounter for',
  'fracture with nonunion',
  'S92136P Nondisplaced fracture of posterior process of unspecified talus, subsequent encounter for',
  'fracture with malunion',
  'S92136S Nondisplaced fracture of posterior process of unspecified talus, sequela',
  'S92141A Displaced dome fracture of right talus, initial encounter for closed fracture',
  'S92141B Displaced dome fracture of right talus, initial encounter for open fracture',
  'S92141D Displaced dome fracture of right talus, subsequent encounter for fracture with routine healing',
  'S92141G Displaced dome fracture of right talus, subsequent encounter for fracture with delayed healing',
  'S92141K Displaced dome fracture of right talus, subsequent encounter for fracture with nonunion',
  'S92141P Displaced dome fracture of right talus, subsequent encounter for fracture with malunion',
  'S92141S Displaced dome fracture of right talus, sequela',
  'S92142A Displaced dome fracture of left talus, initial encounter for closed fracture',
  'S92142B Displaced dome fracture of left talus, initial encounter for open fracture',
  'S92142D Displaced dome fracture of left talus, subsequent encounter for fracture with routine healing',
  'S92142G Displaced dome fracture of left talus, subsequent encounter for fracture with delayed healing',
  'S92142K Displaced dome fracture of left talus, subsequent encounter for fracture with nonunion',
  'S92142P Displaced dome fracture of left talus, subsequent encounter for fracture with malunion',
  'S92142S Displaced dome fracture of left talus, sequela',
  'S92143A Displaced dome fracture of unspecified talus, initial encounter for closed fracture',
  'S92143B Displaced dome fracture of unspecified talus, initial encounter for open fracture',
  'S92143D Displaced dome fracture of unspecified talus, subsequent encounter for fracture with routine',
  'healing',
  'S92143G Displaced dome fracture of unspecified talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92143K Displaced dome fracture of unspecified talus, subsequent encounter for fracture with',
  'nonunion',
  'S92143P Displaced dome fracture of unspecified talus, subsequent encounter for fracture with malunion',
  'S92143S Displaced dome fracture of unspecified talus, sequela',
  'S92144A Nondisplaced dome fracture of right talus, initial encounter for closed fracture',
  'S92144B Nondisplaced dome fracture of right talus, initial encounter for open fracture',
  'S92144D Nondisplaced dome fracture of right talus, subsequent encounter for fracture with routine',
  'healing',
  'S92144G Nondisplaced dome fracture of right talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92144K Nondisplaced dome fracture of right talus, subsequent encounter for fracture with nonunion',
  'S92144P Nondisplaced dome fracture of right talus, subsequent encounter for fracture with malunion',
  'S92144S Nondisplaced dome fracture of right talus, sequela',
  'S92145A Nondisplaced dome fracture of left talus, initial encounter for closed fracture',
  'S92145B Nondisplaced dome fracture of left talus, initial encounter for open fracture',
  'S92145D Nondisplaced dome fracture of left talus, subsequent encounter for fracture with routine',
  'healing',
  'S92145G Nondisplaced dome fracture of left talus, subsequent encounter for fracture with delayed',
  'healing',
  'S92145K Nondisplaced dome fracture of left talus, subsequent encounter for fracture with nonunion',
  'S92145P Nondisplaced dome fracture of left talus, subsequent encounter for fracture with malunion',
  'S92145S Nondisplaced dome fracture of left talus, sequela',
  'S92146A Nondisplaced dome fracture of unspecified talus, initial encounter for closed fracture',
  'S92146B Nondisplaced dome fracture of unspecified talus, initial encounter for open fracture',
  'S92146D Nondisplaced dome fracture of unspecified talus, subsequent encounter for fracture with',
  'routine healing',
  'S92146G Nondisplaced dome fracture of unspecified talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92146K Nondisplaced dome fracture of unspecified talus, subsequent encounter for fracture with',
  'nonunion',
  'S92146P Nondisplaced dome fracture of unspecified talus, subsequent encounter for fracture with',
  'malunion',
  'S92146S Nondisplaced dome fracture of unspecified talus, sequela',
  'S92151A Displaced avulsion fracture (chip fracture) of right talus, initial encounter for closed fracture',
  'S92151B Displaced avulsion fracture (chip fracture) of right talus, initial encounter for open fracture',
  'S92151D Displaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with routine healing',
  'S92151G Displaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with delayed healing',
  'S92151K Displaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with nonunion',
  'S92151P Displaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with malunion',
  'S92151S Displaced avulsion fracture (chip fracture) of right talus, sequela',
  'S92152A Displaced avulsion fracture (chip fracture) of left talus, initial encounter for closed fracture',
  'S92152B Displaced avulsion fracture (chip fracture) of left talus, initial encounter for open fracture',
  'S92152D Displaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture with',
  'routine healing',
  'S92152G Displaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture with',
  'delayed healing',
  'S92152K Displaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture with',
  'nonunion',
  'S92152P Displaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture with',
  'malunion',
  'S92152S Displaced avulsion fracture (chip fracture) of left talus, sequela',
  'S92153A Displaced avulsion fracture (chip fracture) of unspecified talus, initial encounter for closed',
  'fracture',
  'S92153B Displaced avulsion fracture (chip fracture) of unspecified talus, initial encounter for open',
  'fracture',
  'S92153D Displaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with routine healing',
  'S92153G Displaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with delayed healing',
  'S92153K Displaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with nonunion',
  'S92153P Displaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with malunion',
  'S92153S Displaced avulsion fracture (chip fracture) of unspecified talus, sequela',
  'S92154A Nondisplaced avulsion fracture (chip fracture) of right talus, initial encounter for closed',
  'fracture',
  'S92154B Nondisplaced avulsion fracture (chip fracture) of right talus, initial encounter for open fracture',
  'S92154D Nondisplaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with routine healing',
  'S92154G Nondisplaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with delayed healing',
  'S92154K Nondisplaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with nonunion',
  'S92154P Nondisplaced avulsion fracture (chip fracture) of right talus, subsequent encounter for fracture',
  'with malunion',
  'S92154S Nondisplaced avulsion fracture (chip fracture) of right talus, sequela',
  'S92155A Nondisplaced avulsion fracture (chip fracture) of left talus, initial encounter for closed fracture',
  'S92155B Nondisplaced avulsion fracture (chip fracture) of left talus, initial encounter for open fracture',
  'S92155D Nondisplaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture',
  'with routine healing',
  'S92155G Nondisplaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture',
  'with delayed healing',
  'S92155K Nondisplaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture',
  'with nonunion',
  'S92155P Nondisplaced avulsion fracture (chip fracture) of left talus, subsequent encounter for fracture',
  'with malunion',
  'S92155S Nondisplaced avulsion fracture (chip fracture) of left talus, sequela',
  'S92156A Nondisplaced avulsion fracture (chip fracture) of unspecified talus, initial encounter for closed',
  'fracture',
  'S92156B Nondisplaced avulsion fracture (chip fracture) of unspecified talus, initial encounter for open',
  'fracture',
  'S92156D Nondisplaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with routine healing',
  'S92156G Nondisplaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with delayed healing',
  'S92156K Nondisplaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with nonunion',
  'S92156P Nondisplaced avulsion fracture (chip fracture) of unspecified talus, subsequent encounter for',
  'fracture with malunion',
  'S92156S Nondisplaced avulsion fracture (chip fracture) of unspecified talus, sequela',
  'S92191A Other fracture of right talus, initial encounter for closed fracture',
  'S92191B Other fracture of right talus, initial encounter for open fracture',
  'S92191D Other fracture of right talus, subsequent encounter for fracture with routine healing',
  'S92191G Other fracture of right talus, subsequent encounter for fracture with delayed healing',
  'S92191K Other fracture of right talus, subsequent encounter for fracture with nonunion',
  'S92191P Other fracture of right talus, subsequent encounter for fracture with malunion',
  'S92191S Other fracture of right talus, sequela',
  'S92192A Other fracture of left talus, initial encounter for closed fracture',
  'S92192B Other fracture of left talus, initial encounter for open fracture',
  'S92192D Other fracture of left talus, subsequent encounter for fracture with routine healing',
  'S92192G Other fracture of left talus, subsequent encounter for fracture with delayed healing',
  'S92192K Other fracture of left talus, subsequent encounter for fracture with nonunion',
  'S92192P Other fracture of left talus, subsequent encounter for fracture with malunion',
  'S92192S Other fracture of left talus, sequela',
  'S92199A Other fracture of unspecified talus, initial encounter for closed fracture',
  'S92199B Other fracture of unspecified talus, initial encounter for open fracture',
  'S92199D Other fracture of unspecified talus, subsequent encounter for fracture with routine healing',
  'S92199G Other fracture of unspecified talus, subsequent encounter for fracture with delayed healing',
  'S92199K Other fracture of unspecified talus, subsequent encounter for fracture with nonunion',
  'S92199P Other fracture of unspecified talus, subsequent encounter for fracture with malunion',
  'S92199S Other fracture of unspecified talus, sequela',
  'S92201A Fracture of unspecified tarsal bone(s) of right foot, initial encounter for closed fracture',
  'S92201B Fracture of unspecified tarsal bone(s) of right foot, initial encounter for open fracture',
  'S92201D Fracture of unspecified tarsal bone(s) of right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92201G Fracture of unspecified tarsal bone(s) of right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92201K Fracture of unspecified tarsal bone(s) of right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92201P Fracture of unspecified tarsal bone(s) of right foot, subsequent encounter for fracture with',
  'malunion',
  'S92201S Fracture of unspecified tarsal bone(s) of right foot, sequela',
  'S92202A Fracture of unspecified tarsal bone(s) of left foot, initial encounter for closed fracture',
  'S92202B Fracture of unspecified tarsal bone(s) of left foot, initial encounter for open fracture',
  'S92202D Fracture of unspecified tarsal bone(s) of left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92202G Fracture of unspecified tarsal bone(s) of left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92202K Fracture of unspecified tarsal bone(s) of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92202P Fracture of unspecified tarsal bone(s) of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92202S Fracture of unspecified tarsal bone(s) of left foot, sequela',
  'S92209A Fracture of unspecified tarsal bone(s) of unspecified foot, initial encounter for closed fracture',
  'S92209B Fracture of unspecified tarsal bone(s) of unspecified foot, initial encounter for open fracture',
  'S92209D Fracture of unspecified tarsal bone(s) of unspecified foot, subsequent encounter for fracture',
  'with routine healing',
  'S92209G Fracture of unspecified tarsal bone(s) of unspecified foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92209K Fracture of unspecified tarsal bone(s) of unspecified foot, subsequent encounter for fracture',
  'with nonunion',
  'S92209P Fracture of unspecified tarsal bone(s) of unspecified foot, subsequent encounter for fracture',
  'with malunion',
  'S92209S Fracture of unspecified tarsal bone(s) of unspecified foot, sequela',
  'S92211A Displaced fracture of cuboid bone of right foot, initial encounter for closed fracture',
  'S92211B Displaced fracture of cuboid bone of right foot, initial encounter for open fracture',
  'S92211D Displaced fracture of cuboid bone of right foot, subsequent encounter for fracture with routine',
  'healing',
  'S92211G Displaced fracture of cuboid bone of right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92211K Displaced fracture of cuboid bone of right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92211P Displaced fracture of cuboid bone of right foot, subsequent encounter for fracture with',
  'malunion',
  'S92211S Displaced fracture of cuboid bone of right foot, sequela',
  'S92212A Displaced fracture of cuboid bone of left foot, initial encounter for closed fracture',
  'S92212B Displaced fracture of cuboid bone of left foot, initial encounter for open fracture',
  'S92212D Displaced fracture of cuboid bone of left foot, subsequent encounter for fracture with routine',
  'healing',
  'S92212G Displaced fracture of cuboid bone of left foot, subsequent encounter for fracture with delayed',
  'healing',
  'S92212K Displaced fracture of cuboid bone of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92212P Displaced fracture of cuboid bone of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92212S Displaced fracture of cuboid bone of left foot, sequela',
  'S92213A Displaced fracture of cuboid bone of unspecified foot, initial encounter for closed fracture',
  'S92213B Displaced fracture of cuboid bone of unspecified foot, initial encounter for open fracture',
  'S92213D Displaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture with',
  'routine healing',
  'S92213G Displaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92213K Displaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture with',
  'nonunion',
  'S92213P Displaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture with',
  'malunion',
  'S92213S Displaced fracture of cuboid bone of unspecified foot, sequela',
  'S92214A Nondisplaced fracture of cuboid bone of right foot, initial encounter for closed fracture',
  'S92214B Nondisplaced fracture of cuboid bone of right foot, initial encounter for open fracture',
  'S92214D Nondisplaced fracture of cuboid bone of right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92214G Nondisplaced fracture of cuboid bone of right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92214K Nondisplaced fracture of cuboid bone of right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92214P Nondisplaced fracture of cuboid bone of right foot, subsequent encounter for fracture with',
  'malunion',
  'S92214S Nondisplaced fracture of cuboid bone of right foot, sequela',
  'S92215A Nondisplaced fracture of cuboid bone of left foot, initial encounter for closed fracture',
  'S92215B Nondisplaced fracture of cuboid bone of left foot, initial encounter for open fracture',
  'S92215D Nondisplaced fracture of cuboid bone of left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92215G Nondisplaced fracture of cuboid bone of left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92215K Nondisplaced fracture of cuboid bone of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92215P Nondisplaced fracture of cuboid bone of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92215S Nondisplaced fracture of cuboid bone of left foot, sequela',
  'S92216A Nondisplaced fracture of cuboid bone of unspecified foot, initial encounter for closed fracture',
  'S92216B Nondisplaced fracture of cuboid bone of unspecified foot, initial encounter for open fracture',
  'S92216D Nondisplaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture',
  'with routine healing',
  'S92216G Nondisplaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92216K Nondisplaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture',
  'with nonunion',
  'S92216P Nondisplaced fracture of cuboid bone of unspecified foot, subsequent encounter for fracture',
  'with malunion',
  'S92216S Nondisplaced fracture of cuboid bone of unspecified foot, sequela',
  'S92221A Displaced fracture of lateral cuneiform of right foot, initial encounter for closed fracture',
  'S92221B Displaced fracture of lateral cuneiform of right foot, initial encounter for open fracture',
  'S92221D Displaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92221G Displaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92221K Displaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92221P Displaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture with',
  'malunion',
  'S92221S Displaced fracture of lateral cuneiform of right foot, sequela',
  'S92222A Displaced fracture of lateral cuneiform of left foot, initial encounter for closed fracture',
  'S92222B Displaced fracture of lateral cuneiform of left foot, initial encounter for open fracture',
  'S92222D Displaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92222G Displaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92222K Displaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92222P Displaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92222S Displaced fracture of lateral cuneiform of left foot, sequela',
  'S92223A Displaced fracture of lateral cuneiform of unspecified foot, initial encounter for closed fracture',
  'S92223B Displaced fracture of lateral cuneiform of unspecified foot, initial encounter for open fracture',
  'S92223D Displaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for fracture',
  'with routine healing',
  'S92223G Displaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92223K Displaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for fracture',
  'with nonunion',
  'S92223P Displaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for fracture',
  'with malunion',
  'S92223S Displaced fracture of lateral cuneiform of unspecified foot, sequela',
  'S92224A Nondisplaced fracture of lateral cuneiform of right foot, initial encounter for closed fracture',
  'S92224B Nondisplaced fracture of lateral cuneiform of right foot, initial encounter for open fracture',
  'S92224D Nondisplaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92224G Nondisplaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92224K Nondisplaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92224P Nondisplaced fracture of lateral cuneiform of right foot, subsequent encounter for fracture',
  'with malunion',
  'S92224S Nondisplaced fracture of lateral cuneiform of right foot, sequela',
  'S92225A Nondisplaced fracture of lateral cuneiform of left foot, initial encounter for closed fracture',
  'S92225B Nondisplaced fracture of lateral cuneiform of left foot, initial encounter for open fracture',
  'S92225D Nondisplaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92225G Nondisplaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92225K Nondisplaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92225P Nondisplaced fracture of lateral cuneiform of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92225S Nondisplaced fracture of lateral cuneiform of left foot, sequela',
  'S92226A Nondisplaced fracture of lateral cuneiform of unspecified foot, initial encounter for closed',
  'fracture',
  'S92226B Nondisplaced fracture of lateral cuneiform of unspecified foot, initial encounter for open',
  'fracture',
  'S92226D Nondisplaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92226G Nondisplaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92226K Nondisplaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92226P Nondisplaced fracture of lateral cuneiform of unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92226S Nondisplaced fracture of lateral cuneiform of unspecified foot, sequela',
  'S92231A Displaced fracture of intermediate cuneiform of right foot, initial encounter for closed fracture',
  'S92231B Displaced fracture of intermediate cuneiform of right foot, initial encounter for open fracture',
  'S92231D Displaced fracture of intermediate cuneiform of right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92231G Displaced fracture of intermediate cuneiform of right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92231K Displaced fracture of intermediate cuneiform of right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92231P Displaced fracture of intermediate cuneiform of right foot, subsequent encounter for fracture',
  'with malunion',
  'S92231S Displaced fracture of intermediate cuneiform of right foot, sequela',
  'S92232A Displaced fracture of intermediate cuneiform of left foot, initial encounter for closed fracture',
  'S92232B Displaced fracture of intermediate cuneiform of left foot, initial encounter for open fracture',
  'S92232D Displaced fracture of intermediate cuneiform of left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92232G Displaced fracture of intermediate cuneiform of left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92232K Displaced fracture of intermediate cuneiform of left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92232P Displaced fracture of intermediate cuneiform of left foot, subsequent encounter for fracture',
  'with malunion',
  'S92232S Displaced fracture of intermediate cuneiform of left foot, sequela',
  'S92233A Displaced fracture of intermediate cuneiform of unspecified foot, initial encounter for closed',
  'fracture',
  'S92233B Displaced fracture of intermediate cuneiform of unspecified foot, initial encounter for open',
  'fracture',
  'S92233D Displaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92233G Displaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92233K Displaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92233P Displaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92233S Displaced fracture of intermediate cuneiform of unspecified foot, sequela',
  'S92234A Nondisplaced fracture of intermediate cuneiform of right foot, initial encounter for closed',
  'fracture',
  'S92234B Nondisplaced fracture of intermediate cuneiform of right foot, initial encounter for open',
  'fracture',
  'S92234D Nondisplaced fracture of intermediate cuneiform of right foot, subsequent encounter for',
  'fracture with routine healing',
  'S92234G Nondisplaced fracture of intermediate cuneiform of right foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92234K Nondisplaced fracture of intermediate cuneiform of right foot, subsequent encounter for',
  'fracture with nonunion',
  'S92234P Nondisplaced fracture of intermediate cuneiform of right foot, subsequent encounter for',
  'fracture with malunion',
  'S92234S Nondisplaced fracture of intermediate cuneiform of right foot, sequela',
  'S92235A Nondisplaced fracture of intermediate cuneiform of left foot, initial encounter for closed',
  'fracture',
  'S92235B Nondisplaced fracture of intermediate cuneiform of left foot, initial encounter for open',
  'fracture',
  'S92235D Nondisplaced fracture of intermediate cuneiform of left foot, subsequent encounter for',
  'fracture with routine healing',
  'S92235G Nondisplaced fracture of intermediate cuneiform of left foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92235K Nondisplaced fracture of intermediate cuneiform of left foot, subsequent encounter for',
  'fracture with nonunion',
  'S92235P Nondisplaced fracture of intermediate cuneiform of left foot, subsequent encounter for',
  'fracture with malunion',
  'S92235S Nondisplaced fracture of intermediate cuneiform of left foot, sequela',
  'S92236A Nondisplaced fracture of intermediate cuneiform of unspecified foot, initial encounter for',
  'closed fracture',
  'S92236B Nondisplaced fracture of intermediate cuneiform of unspecified foot, initial encounter for open',
  'fracture',
  'S92236D Nondisplaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter',
  'for fracture with routine healing',
  'S92236G Nondisplaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter',
  'for fracture with delayed healing',
  'S92236K Nondisplaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter',
  'for fracture with nonunion',
  'S92236P Nondisplaced fracture of intermediate cuneiform of unspecified foot, subsequent encounter',
  'for fracture with malunion',
  'S92236S Nondisplaced fracture of intermediate cuneiform of unspecified foot, sequela',
  'S92241A Displaced fracture of medial cuneiform of right foot, initial encounter for closed fracture',
  'S92241B Displaced fracture of medial cuneiform of right foot, initial encounter for open fracture',
  'S92241D Displaced fracture of medial cuneiform of right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92241G Displaced fracture of medial cuneiform of right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92241K Displaced fracture of medial cuneiform of right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92241P Displaced fracture of medial cuneiform of right foot, subsequent encounter for fracture with',
  'malunion',
  'S92241S Displaced fracture of medial cuneiform of right foot, sequela',
  'S92242A Displaced fracture of medial cuneiform of left foot, initial encounter for closed fracture',
  'S92242B Displaced fracture of medial cuneiform of left foot, initial encounter for open fracture',
  'S92242D Displaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92242G Displaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92242K Displaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92242P Displaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92242S Displaced fracture of medial cuneiform of left foot, sequela',
  'S92243A Displaced fracture of medial cuneiform of unspecified foot, initial encounter for closed fracture',
  'S92243B Displaced fracture of medial cuneiform of unspecified foot, initial encounter for open fracture',
  'S92243D Displaced fracture of medial cuneiform of unspecified foot, subsequent encounter for fracture',
  'with routine healing',
  'S92243G Displaced fracture of medial cuneiform of unspecified foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92243K Displaced fracture of medial cuneiform of unspecified foot, subsequent encounter for fracture',
  'with nonunion',
  'S92243P Displaced fracture of medial cuneiform of unspecified foot, subsequent encounter for fracture',
  'with malunion',
  'S92243S Displaced fracture of medial cuneiform of unspecified foot, sequela',
  'S92244A Nondisplaced fracture of medial cuneiform of right foot, initial encounter for closed fracture',
  'S92244B Nondisplaced fracture of medial cuneiform of right foot, initial encounter for open fracture',
  'S92244D Nondisplaced fracture of medial cuneiform of right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92244G Nondisplaced fracture of medial cuneiform of right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92244K Nondisplaced fracture of medial cuneiform of right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92244P Nondisplaced fracture of medial cuneiform of right foot, subsequent encounter for fracture',
  'with malunion',
  'S92244S Nondisplaced fracture of medial cuneiform of right foot, sequela',
  'S92245A Nondisplaced fracture of medial cuneiform of left foot, initial encounter for closed fracture',
  'S92245B Nondisplaced fracture of medial cuneiform of left foot, initial encounter for open fracture',
  'S92245D Nondisplaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92245G Nondisplaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92245K Nondisplaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92245P Nondisplaced fracture of medial cuneiform of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92245S Nondisplaced fracture of medial cuneiform of left foot, sequela',
  'S92246A Nondisplaced fracture of medial cuneiform of unspecified foot, initial encounter for closed',
  'fracture',
  'S92246B Nondisplaced fracture of medial cuneiform of unspecified foot, initial encounter for open',
  'fracture',
  'S92246D Nondisplaced fracture of medial cuneiform of unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92246G Nondisplaced fracture of medial cuneiform of unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92246K Nondisplaced fracture of medial cuneiform of unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92246P Nondisplaced fracture of medial cuneiform of unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92246S Nondisplaced fracture of medial cuneiform of unspecified foot, sequela',
  'S92251A Displaced fracture of navicular [scaphoid] of right foot, initial encounter for closed fracture',
  'S92251B Displaced fracture of navicular [scaphoid] of right foot, initial encounter for open fracture',
  'S92251D Displaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92251G Displaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92251K Displaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92251P Displaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture with',
  'malunion',
  'S92251S Displaced fracture of navicular [scaphoid] of right foot, sequela',
  'S92252A Displaced fracture of navicular [scaphoid] of left foot, initial encounter for closed fracture',
  'S92252B Displaced fracture of navicular [scaphoid] of left foot, initial encounter for open fracture',
  'S92252D Displaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92252G Displaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92252K Displaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92252P Displaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture with',
  'malunion',
  'S92252S Displaced fracture of navicular [scaphoid] of left foot, sequela',
  'S92253A Displaced fracture of navicular [scaphoid] of unspecified foot, initial encounter for closed',
  'fracture',
  'S92253B Displaced fracture of navicular [scaphoid] of unspecified foot, initial encounter for open',
  'fracture',
  'S92253D Displaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92253G Displaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92253K Displaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92253P Displaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92253S Displaced fracture of navicular [scaphoid] of unspecified foot, sequela',
  'S92254A Nondisplaced fracture of navicular [scaphoid] of right foot, initial encounter for closed fracture',
  'S92254B Nondisplaced fracture of navicular [scaphoid] of right foot, initial encounter for open fracture',
  'S92254D Nondisplaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92254G Nondisplaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92254K Nondisplaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92254P Nondisplaced fracture of navicular [scaphoid] of right foot, subsequent encounter for fracture',
  'with malunion',
  'S92254S Nondisplaced fracture of navicular [scaphoid] of right foot, sequela',
  'S92255A Nondisplaced fracture of navicular [scaphoid] of left foot, initial encounter for closed fracture',
  'S92255B Nondisplaced fracture of navicular [scaphoid] of left foot, initial encounter for open fracture',
  'S92255D Nondisplaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92255G Nondisplaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92255K Nondisplaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92255P Nondisplaced fracture of navicular [scaphoid] of left foot, subsequent encounter for fracture',
  'with malunion',
  'S92255S Nondisplaced fracture of navicular [scaphoid] of left foot, sequela',
  'S92256A Nondisplaced fracture of navicular [scaphoid] of unspecified foot, initial encounter for closed',
  'fracture',
  'S92256B Nondisplaced fracture of navicular [scaphoid] of unspecified foot, initial encounter for open',
  'fracture',
  'S92256D Nondisplaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92256G Nondisplaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92256K Nondisplaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92256P Nondisplaced fracture of navicular [scaphoid] of unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92256S Nondisplaced fracture of navicular [scaphoid] of unspecified foot, sequela',
  'S92301A Fracture of unspecified metatarsal bone(s), right foot, initial encounter for closed fracture',
  'S92301B Fracture of unspecified metatarsal bone(s), right foot, initial encounter for open fracture',
  'S92301D Fracture of unspecified metatarsal bone(s), right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92301G Fracture of unspecified metatarsal bone(s), right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92301K Fracture of unspecified metatarsal bone(s), right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92301P Fracture of unspecified metatarsal bone(s), right foot, subsequent encounter for fracture with',
  'malunion',
  'S92301S Fracture of unspecified metatarsal bone(s), right foot, sequela',
  'S92302A Fracture of unspecified metatarsal bone(s), left foot, initial encounter for closed fracture',
  'S92302B Fracture of unspecified metatarsal bone(s), left foot, initial encounter for open fracture',
  'S92302D Fracture of unspecified metatarsal bone(s), left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92302G Fracture of unspecified metatarsal bone(s), left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92302K Fracture of unspecified metatarsal bone(s), left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92302P Fracture of unspecified metatarsal bone(s), left foot, subsequent encounter for fracture with',
  'malunion',
  'S92302S Fracture of unspecified metatarsal bone(s), left foot, sequela',
  'S92309A Fracture of unspecified metatarsal bone(s), unspecified foot, initial encounter for closed',
  'fracture',
  'S92309B Fracture of unspecified metatarsal bone(s), unspecified foot, initial encounter for open fracture',
  'S92309D Fracture of unspecified metatarsal bone(s), unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92309G Fracture of unspecified metatarsal bone(s), unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92309K Fracture of unspecified metatarsal bone(s), unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92309P Fracture of unspecified metatarsal bone(s), unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92309S Fracture of unspecified metatarsal bone(s), unspecified foot, sequela',
  'S92311A Displaced fracture of first metatarsal bone, right foot, initial encounter for closed fracture',
  'S92311B Displaced fracture of first metatarsal bone, right foot, initial encounter for open fracture',
  'S92311D Displaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92311G Displaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92311K Displaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92311P Displaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture with',
  'malunion',
  'S92311S Displaced fracture of first metatarsal bone, right foot, sequela',
  'S92312A Displaced fracture of first metatarsal bone, left foot, initial encounter for closed fracture',
  'S92312B Displaced fracture of first metatarsal bone, left foot, initial encounter for open fracture',
  'S92312D Displaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92312G Displaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92312K Displaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92312P Displaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture with',
  'malunion',
  'S92312S Displaced fracture of first metatarsal bone, left foot, sequela',
  'S92313A Displaced fracture of first metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92313B Displaced fracture of first metatarsal bone, unspecified foot, initial encounter for open fracture',
  'S92313D Displaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92313G Displaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92313K Displaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92313P Displaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92313S Displaced fracture of first metatarsal bone, unspecified foot, sequela',
  'S92314A Nondisplaced fracture of first metatarsal bone, right foot, initial encounter for closed fracture',
  'S92314B Nondisplaced fracture of first metatarsal bone, right foot, initial encounter for open fracture',
  'S92314D Nondisplaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92314G Nondisplaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92314K Nondisplaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92314P Nondisplaced fracture of first metatarsal bone, right foot, subsequent encounter for fracture',
  'with malunion',
  'S92314S Nondisplaced fracture of first metatarsal bone, right foot, sequela',
  'S92315A Nondisplaced fracture of first metatarsal bone, left foot, initial encounter for closed fracture',
  'S92315B Nondisplaced fracture of first metatarsal bone, left foot, initial encounter for open fracture',
  'S92315D Nondisplaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92315G Nondisplaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92315K Nondisplaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92315P Nondisplaced fracture of first metatarsal bone, left foot, subsequent encounter for fracture',
  'with malunion',
  'S92315S Nondisplaced fracture of first metatarsal bone, left foot, sequela',
  'S92316A Nondisplaced fracture of first metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92316B Nondisplaced fracture of first metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92316D Nondisplaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92316G Nondisplaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92316K Nondisplaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92316P Nondisplaced fracture of first metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92316S Nondisplaced fracture of first metatarsal bone, unspecified foot, sequela',
  'S92321A Displaced fracture of second metatarsal bone, right foot, initial encounter for closed fracture',
  'S92321B Displaced fracture of second metatarsal bone, right foot, initial encounter for open fracture',
  'S92321D Displaced fracture of second metatarsal bone, right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92321G Displaced fracture of second metatarsal bone, right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92321K Displaced fracture of second metatarsal bone, right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92321P Displaced fracture of second metatarsal bone, right foot, subsequent encounter for fracture',
  'with malunion',
  'S92321S Displaced fracture of second metatarsal bone, right foot, sequela',
  'S92322A Displaced fracture of second metatarsal bone, left foot, initial encounter for closed fracture',
  'S92322B Displaced fracture of second metatarsal bone, left foot, initial encounter for open fracture',
  'S92322D Displaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92322G Displaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92322K Displaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92322P Displaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with malunion',
  'S92322S Displaced fracture of second metatarsal bone, left foot, sequela',
  'S92323A Displaced fracture of second metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92323B Displaced fracture of second metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92323D Displaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92323G Displaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92323K Displaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92323P Displaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92323S Displaced fracture of second metatarsal bone, unspecified foot, sequela',
  'S92324A Nondisplaced fracture of second metatarsal bone, right foot, initial encounter for closed',
  'fracture',
  'S92324B Nondisplaced fracture of second metatarsal bone, right foot, initial encounter for open fracture',
  'S92324D Nondisplaced fracture of second metatarsal bone, right foot, subsequent encounter for',
  'fracture with routine healing',
  'S92324G Nondisplaced fracture of second metatarsal bone, right foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92324K Nondisplaced fracture of second metatarsal bone, right foot, subsequent encounter for',
  'fracture with nonunion',
  'S92324P Nondisplaced fracture of second metatarsal bone, right foot, subsequent encounter for',
  'fracture with malunion',
  'S92324S Nondisplaced fracture of second metatarsal bone, right foot, sequela',
  'S92325A Nondisplaced fracture of second metatarsal bone, left foot, initial encounter for closed fracture',
  'S92325B Nondisplaced fracture of second metatarsal bone, left foot, initial encounter for open fracture',
  'S92325D Nondisplaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92325G Nondisplaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92325K Nondisplaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92325P Nondisplaced fracture of second metatarsal bone, left foot, subsequent encounter for fracture',
  'with malunion',
  'S92325S Nondisplaced fracture of second metatarsal bone, left foot, sequela',
  'S92326A Nondisplaced fracture of second metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92326B Nondisplaced fracture of second metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92326D Nondisplaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92326G Nondisplaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92326K Nondisplaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92326P Nondisplaced fracture of second metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92326S Nondisplaced fracture of second metatarsal bone, unspecified foot, sequela',
  'S92331A Displaced fracture of third metatarsal bone, right foot, initial encounter for closed fracture',
  'S92331B Displaced fracture of third metatarsal bone, right foot, initial encounter for open fracture',
  'S92331D Displaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92331G Displaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92331K Displaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92331P Displaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture with',
  'malunion',
  'S92331S Displaced fracture of third metatarsal bone, right foot, sequela',
  'S92332A Displaced fracture of third metatarsal bone, left foot, initial encounter for closed fracture',
  'S92332B Displaced fracture of third metatarsal bone, left foot, initial encounter for open fracture',
  'S92332D Displaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92332G Displaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92332K Displaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92332P Displaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture with',
  'malunion',
  'S92332S Displaced fracture of third metatarsal bone, left foot, sequela',
  'S92333A Displaced fracture of third metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92333B Displaced fracture of third metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92333D Displaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92333G Displaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92333K Displaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92333P Displaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92333S Displaced fracture of third metatarsal bone, unspecified foot, sequela',
  'S92334A Nondisplaced fracture of third metatarsal bone, right foot, initial encounter for closed fracture',
  'S92334B Nondisplaced fracture of third metatarsal bone, right foot, initial encounter for open fracture',
  'S92334D Nondisplaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92334G Nondisplaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92334K Nondisplaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92334P Nondisplaced fracture of third metatarsal bone, right foot, subsequent encounter for fracture',
  'with malunion',
  'S92334S Nondisplaced fracture of third metatarsal bone, right foot, sequela',
  'S92335A Nondisplaced fracture of third metatarsal bone, left foot, initial encounter for closed fracture',
  'S92335B Nondisplaced fracture of third metatarsal bone, left foot, initial encounter for open fracture',
  'S92335D Nondisplaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92335G Nondisplaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92335K Nondisplaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92335P Nondisplaced fracture of third metatarsal bone, left foot, subsequent encounter for fracture',
  'with malunion',
  'S92335S Nondisplaced fracture of third metatarsal bone, left foot, sequela',
  'S92336A Nondisplaced fracture of third metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92336B Nondisplaced fracture of third metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92336D Nondisplaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92336G Nondisplaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92336K Nondisplaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92336P Nondisplaced fracture of third metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92336S Nondisplaced fracture of third metatarsal bone, unspecified foot, sequela',
  'S92341A Displaced fracture of fourth metatarsal bone, right foot, initial encounter for closed fracture',
  'S92341B Displaced fracture of fourth metatarsal bone, right foot, initial encounter for open fracture',
  'S92341D Displaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92341G Displaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92341K Displaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92341P Displaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with malunion',
  'S92341S Displaced fracture of fourth metatarsal bone, right foot, sequela',
  'S92342A Displaced fracture of fourth metatarsal bone, left foot, initial encounter for closed fracture',
  'S92342B Displaced fracture of fourth metatarsal bone, left foot, initial encounter for open fracture',
  'S92342D Displaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92342G Displaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92342K Displaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92342P Displaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture with',
  'malunion',
  'S92342S Displaced fracture of fourth metatarsal bone, left foot, sequela',
  'S92343A Displaced fracture of fourth metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92343B Displaced fracture of fourth metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92343D Displaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92343G Displaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92343K Displaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92343P Displaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92343S Displaced fracture of fourth metatarsal bone, unspecified foot, sequela',
  'S92344A Nondisplaced fracture of fourth metatarsal bone, right foot, initial encounter for closed',
  'fracture',
  'S92344B Nondisplaced fracture of fourth metatarsal bone, right foot, initial encounter for open fracture',
  'S92344D Nondisplaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92344G Nondisplaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92344K Nondisplaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92344P Nondisplaced fracture of fourth metatarsal bone, right foot, subsequent encounter for fracture',
  'with malunion',
  'S92344S Nondisplaced fracture of fourth metatarsal bone, right foot, sequela',
  'S92345A Nondisplaced fracture of fourth metatarsal bone, left foot, initial encounter for closed fracture',
  'S92345B Nondisplaced fracture of fourth metatarsal bone, left foot, initial encounter for open fracture',
  'S92345D Nondisplaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92345G Nondisplaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92345K Nondisplaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92345P Nondisplaced fracture of fourth metatarsal bone, left foot, subsequent encounter for fracture',
  'with malunion',
  'S92345S Nondisplaced fracture of fourth metatarsal bone, left foot, sequela',
  'S92346A Nondisplaced fracture of fourth metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92346B Nondisplaced fracture of fourth metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92346D Nondisplaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92346G Nondisplaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92346K Nondisplaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92346P Nondisplaced fracture of fourth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92346S Nondisplaced fracture of fourth metatarsal bone, unspecified foot, sequela',
  'S92351A Displaced fracture of fifth metatarsal bone, right foot, initial encounter for closed fracture',
  'S92351B Displaced fracture of fifth metatarsal bone, right foot, initial encounter for open fracture',
  'S92351D Displaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture with',
  'routine healing',
  'S92351G Displaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92351K Displaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture with',
  'nonunion',
  'S92351P Displaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture with',
  'malunion',
  'S92351S Displaced fracture of fifth metatarsal bone, right foot, sequela',
  'S92352A Displaced fracture of fifth metatarsal bone, left foot, initial encounter for closed fracture',
  'S92352B Displaced fracture of fifth metatarsal bone, left foot, initial encounter for open fracture',
  'S92352D Displaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture with',
  'routine healing',
  'S92352G Displaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture with',
  'delayed healing',
  'S92352K Displaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture with',
  'nonunion',
  'S92352P Displaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture with',
  'malunion',
  'S92352S Displaced fracture of fifth metatarsal bone, left foot, sequela',
  'S92353A Displaced fracture of fifth metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92353B Displaced fracture of fifth metatarsal bone, unspecified foot, initial encounter for open fracture',
  'S92353D Displaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92353G Displaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92353K Displaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92353P Displaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92353S Displaced fracture of fifth metatarsal bone, unspecified foot, sequela',
  'S92354A Nondisplaced fracture of fifth metatarsal bone, right foot, initial encounter for closed fracture',
  'S92354B Nondisplaced fracture of fifth metatarsal bone, right foot, initial encounter for open fracture',
  'S92354D Nondisplaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture',
  'with routine healing',
  'S92354G Nondisplaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92354K Nondisplaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture',
  'with nonunion',
  'S92354P Nondisplaced fracture of fifth metatarsal bone, right foot, subsequent encounter for fracture',
  'with malunion',
  'S92354S Nondisplaced fracture of fifth metatarsal bone, right foot, sequela',
  'S92355A Nondisplaced fracture of fifth metatarsal bone, left foot, initial encounter for closed fracture',
  'S92355B Nondisplaced fracture of fifth metatarsal bone, left foot, initial encounter for open fracture',
  'S92355D Nondisplaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture',
  'with routine healing',
  'S92355G Nondisplaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture',
  'with delayed healing',
  'S92355K Nondisplaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture',
  'with nonunion',
  'S92355P Nondisplaced fracture of fifth metatarsal bone, left foot, subsequent encounter for fracture',
  'with malunion',
  'S92355S Nondisplaced fracture of fifth metatarsal bone, left foot, sequela',
  'S92356A Nondisplaced fracture of fifth metatarsal bone, unspecified foot, initial encounter for closed',
  'fracture',
  'S92356B Nondisplaced fracture of fifth metatarsal bone, unspecified foot, initial encounter for open',
  'fracture',
  'S92356D Nondisplaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with routine healing',
  'S92356G Nondisplaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with delayed healing',
  'S92356K Nondisplaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with nonunion',
  'S92356P Nondisplaced fracture of fifth metatarsal bone, unspecified foot, subsequent encounter for',
  'fracture with malunion',
  'S92356S Nondisplaced fracture of fifth metatarsal bone, unspecified foot, sequela',
  'S92401A Displaced unspecified fracture of right great toe, initial encounter for closed fracture',
  'S92401B Displaced unspecified fracture of right great toe, initial encounter for open fracture',
  'S92401D Displaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'routine healing',
  'S92401G Displaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'delayed healing',
  'S92401K Displaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'nonunion',
  'S92401P Displaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'malunion',
  'S92401S Displaced unspecified fracture of right great toe, sequela',
  'S92402A Displaced unspecified fracture of left great toe, initial encounter for closed fracture',
  'S92402B Displaced unspecified fracture of left great toe, initial encounter for open fracture',
  'S92402D Displaced unspecified fracture of left great toe, subsequent encounter for fracture with routine',
  'healing',
  'S92402G Displaced unspecified fracture of left great toe, subsequent encounter for fracture with',
  'delayed healing',
  'S92402K Displaced unspecified fracture of left great toe, subsequent encounter for fracture with',
  'nonunion',
  'S92402P Displaced unspecified fracture of left great toe, subsequent encounter for fracture with',
  'malunion',
  'S92402S Displaced unspecified fracture of left great toe, sequela',
  'S92403A Displaced unspecified fracture of unspecified great toe, initial encounter for closed fracture',
  'S92403B Displaced unspecified fracture of unspecified great toe, initial encounter for open fracture',
  'S92403D Displaced unspecified fracture of unspecified great toe, subsequent encounter for fracture',
  'with routine healing',
  'S92403G Displaced unspecified fracture of unspecified great toe, subsequent encounter for fracture',
  'with delayed healing',
  'S92403K Displaced unspecified fracture of unspecified great toe, subsequent encounter for fracture with',
  'nonunion',
  'S92403P Displaced unspecified fracture of unspecified great toe, subsequent encounter for fracture with',
  'malunion',
  'S92403S Displaced unspecified fracture of unspecified great toe, sequela',
  'S92404A Nondisplaced unspecified fracture of right great toe, initial encounter for closed fracture',
  'S92404B Nondisplaced unspecified fracture of right great toe, initial encounter for open fracture',
  'S92404D Nondisplaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'routine healing',
  'S92404G Nondisplaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'delayed healing',
  'S92404K Nondisplaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'nonunion',
  'S92404P Nondisplaced unspecified fracture of right great toe, subsequent encounter for fracture with',
  'malunion',
  'S92404S Nondisplaced unspecified fracture of right great toe, sequela',
  'S92405A Nondisplaced unspecified fracture of left great toe, initial encounter for closed fracture',
  'S92405B Nondisplaced unspecified fracture of left great toe, initial encounter for open fracture',
  'S92405D Nondisplaced unspecified fracture of left great toe, subsequent encounter for fracture with',
  'routine healing',
  'S92405G Nondisplaced unspecified fracture of left great toe, subsequent encounter for fracture with',
  'delayed healing',
  'S92405K Nondisplaced unspecified fracture of left great toe, subsequent encounter for fracture with',
  'nonunion',
  'S92405P Nondisplaced unspecified fracture of left great toe, subsequent encounter for fracture with',
  'malunion',
  'S92405S Nondisplaced unspecified fracture of left great toe, sequela',
  'S92406A Nondisplaced unspecified fracture of unspecified great toe, initial encounter for closed fracture',
  'S92406B Nondisplaced unspecified fracture of unspecified great toe, initial encounter for open fracture',
  'S92406D Nondisplaced unspecified fracture of unspecified great toe, subsequent encounter for fracture',
  'with routine healing',
  'S92406G Nondisplaced unspecified fracture of unspecified great toe, subsequent encounter for fracture',
  'with delayed healing',
  'S92406K Nondisplaced unspecified fracture of unspecified great toe, subsequent encounter for fracture',
  'with nonunion',
  'S92406P Nondisplaced unspecified fracture of unspecified great toe, subsequent encounter for fracture',
  'with malunion',
  'S92406S Nondisplaced unspecified fracture of unspecified great toe, sequela',
  'S92411A Displaced fracture of proximal phalanx of right great toe, initial encounter for closed fracture',
  'S92411B Displaced fracture of proximal phalanx of right great toe, initial encounter for open fracture',
  'S92411D Displaced fracture of proximal phalanx of right great toe, subsequent encounter for fracture',
  'with routine healing',
  'S92411G Displaced fracture of proximal phalanx of right great toe, subsequent encounter for fracture',
  'with delayed healing',
  'S92411K Displaced fracture of proximal phalanx of right great toe, subsequent encounter for fracture',
  'with nonunion',
  'S92411P Displaced fracture of proximal phalanx of right great toe, subsequent encounter for fracture',
  'with malunion',
  'S92411S Displaced fracture of proximal phalanx of right great toe, sequela',
  'S92412A Displaced fracture of proximal phalanx of left great toe, initial encounter for closed fracture',
  'S92412B Displaced fracture of proximal phalanx of left great toe, initial encounter for open fracture',
  'S92412D Displaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with routine healing',
  'S92412G Displaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with delayed healing',
  'S92412K Displaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with nonunion',
  'S92412P Displaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with malunion',
  'S92412S Displaced fracture of proximal phalanx of left great toe, sequela',
  'S92413A Displaced fracture of proximal phalanx of unspecified great toe, initial encounter for closed',
  'fracture',
  'S92413B Displaced fracture of proximal phalanx of unspecified great toe, initial encounter for open',
  'fracture',
  'S92413D Displaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with routine healing',
  'S92413G Displaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with delayed healing',
  'S92413K Displaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with nonunion',
  'S92413P Displaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with malunion',
  'S92413S Displaced fracture of proximal phalanx of unspecified great toe, sequela',
  'S92414A Nondisplaced fracture of proximal phalanx of right great toe, initial encounter for closed',
  'fracture',
  'S92414B Nondisplaced fracture of proximal phalanx of right great toe, initial encounter for open',
  'fracture',
  'S92414D Nondisplaced fracture of proximal phalanx of right great toe, subsequent encounter for',
  'fracture with routine healing',
  'S92414G Nondisplaced fracture of proximal phalanx of right great toe, subsequent encounter for',
  'fracture with delayed healing',
  'S92414K Nondisplaced fracture of proximal phalanx of right great toe, subsequent encounter for',
  'fracture with nonunion',
  'S92414P Nondisplaced fracture of proximal phalanx of right great toe, subsequent encounter for',
  'fracture with malunion',
  'S92414S Nondisplaced fracture of proximal phalanx of right great toe, sequela',
  'S92415A Nondisplaced fracture of proximal phalanx of left great toe, initial encounter for closed',
  'fracture',
  'S92415B Nondisplaced fracture of proximal phalanx of left great toe, initial encounter for open fracture',
  'S92415D Nondisplaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with routine healing',
  'S92415G Nondisplaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with delayed healing',
  'S92415K Nondisplaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with nonunion',
  'S92415P Nondisplaced fracture of proximal phalanx of left great toe, subsequent encounter for fracture',
  'with malunion',
  'S92415S Nondisplaced fracture of proximal phalanx of left great toe, sequela',
  'S92416A Nondisplaced fracture of proximal phalanx of unspecified great toe, initial encounter for closed',
  'fracture',
  'S92416B Nondisplaced fracture of proximal phalanx of unspecified great toe, initial encounter for open',
  'fracture',
  'S92416D Nondisplaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with routine healing',
  'S92416G Nondisplaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with delayed healing',
  'S92416K Nondisplaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with nonunion',
  'S92416P Nondisplaced fracture of proximal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with malunion',
  'S92416S Nondisplaced fracture of proximal phalanx of unspecified great toe, sequela',
  'S92421A Displaced fracture of distal phalanx of right great toe, initial encounter for closed fracture',
  'S92421B Displaced fracture of distal phalanx of right great toe, initial encounter for open fracture',
  'S92421D Displaced fracture of distal phalanx of right great toe, subsequent encounter for fracture with',
  'routine healing',
  'S92421G Displaced fracture of distal phalanx of right great toe, subsequent encounter for fracture with',
  'delayed healing',
  'S92421K Displaced fracture of distal phalanx of right great toe, subsequent encounter for fracture with',
  'nonunion',
  'S92421P Displaced fracture of distal phalanx of right great toe, subsequent encounter for fracture with',
  'malunion',
  'S92421S Displaced fracture of distal phalanx of right great toe, sequela',
  'S92422A Displaced fracture of distal phalanx of left great toe, initial encounter for closed fracture',
  'S92422B Displaced fracture of distal phalanx of left great toe, initial encounter for open fracture',
  'S92422D Displaced fracture of distal phalanx of left great toe, subsequent encounter for fracture with',
  'routine healing',
  'S92422G Displaced fracture of distal phalanx of left great toe, subsequent encounter for fracture with',
  'delayed healing',
  'S92422K Displaced fracture of distal phalanx of left great toe, subsequent encounter for fracture with',
  'nonunion',
  'S92422P Displaced fracture of distal phalanx of left great toe, subsequent encounter for fracture with',
  'malunion',
  'S92422S Displaced fracture of distal phalanx of left great toe, sequela',
  'S92423A Displaced fracture of distal phalanx of unspecified great toe, initial encounter for closed',
  'fracture',
  'S92423B Displaced fracture of distal phalanx of unspecified great toe, initial encounter for open fracture',
  'S92423D Displaced fracture of distal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with routine healing',
  'S92423G Displaced fracture of distal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with delayed healing',
  'S92423K Displaced fracture of distal phalanx of unspecified great toe, subsequent encounter for fracture',
  'with nonunion',
  'S92423P Displaced fracture of distal phalanx of unspecified great toe, subsequent encounter for fracture',
  'with malunion',
  'S92423S Displaced fracture of distal phalanx of unspecified great toe, sequela',
  'S92424A Nondisplaced fracture of distal phalanx of right great toe, initial encounter for closed fracture',
  'S92424B Nondisplaced fracture of distal phalanx of right great toe, initial encounter for open fracture',
  'S92424D Nondisplaced fracture of distal phalanx of right great toe, subsequent encounter for fracture',
  'with routine healing',
  'S92424G Nondisplaced fracture of distal phalanx of right great toe, subsequent encounter for fracture',
  'with delayed healing',
  'S92424K Nondisplaced fracture of distal phalanx of right great toe, subsequent encounter for fracture',
  'with nonunion',
  'S92424P Nondisplaced fracture of distal phalanx of right great toe, subsequent encounter for fracture',
  'with malunion',
  'S92424S Nondisplaced fracture of distal phalanx of right great toe, sequela',
  'S92425A Nondisplaced fracture of distal phalanx of left great toe, initial encounter for closed fracture',
  'S92425B Nondisplaced fracture of distal phalanx of left great toe, initial encounter for open fracture',
  'S92425D Nondisplaced fracture of distal phalanx of left great toe, subsequent encounter for fracture',
  'with routine healing',
  'S92425G Nondisplaced fracture of distal phalanx of left great toe, subsequent encounter for fracture',
  'with delayed healing',
  'S92425K Nondisplaced fracture of distal phalanx of left great toe, subsequent encounter for fracture',
  'with nonunion',
  'S92425P Nondisplaced fracture of distal phalanx of left great toe, subsequent encounter for fracture',
  'with malunion',
  'S92425S Nondisplaced fracture of distal phalanx of left great toe, sequela',
  'S92426A Nondisplaced fracture of distal phalanx of unspecified great toe, initial encounter for closed',
  'fracture',
  'S92426B Nondisplaced fracture of distal phalanx of unspecified great toe, initial encounter for open',
  'fracture',
  'S92426D Nondisplaced fracture of distal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with routine healing',
  'S92426G Nondisplaced fracture of distal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with delayed healing',
  'S92426K Nondisplaced fracture of distal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with nonunion',
  'S92426P Nondisplaced fracture of distal phalanx of unspecified great toe, subsequent encounter for',
  'fracture with malunion',
  'S92426S Nondisplaced fracture of distal phalanx of unspecified great toe, sequela',
  'S92491A Other fracture of right great toe, initial encounter for closed fracture',
  'S92491B Other fracture of right great toe, initial encounter for open fracture',
  'S92491D Other fracture of right great toe, subsequent encounter for fracture with routine healing',
  'S92491G Other fracture of right great toe, subsequent encounter for fracture with delayed healing',
  'S92491K Other fracture of right great toe, subsequent encounter for fracture with nonunion',
  'S92491P Other fracture of right great toe, subsequent encounter for fracture with malunion',
  'S92491S Other fracture of right great toe, sequela',
  'S92492A Other fracture of left great toe, initial encounter for closed fracture',
  'S92492B Other fracture of left great toe, initial encounter for open fracture',
  'S92492D Other fracture of left great toe, subsequent encounter for fracture with routine healing',
  'S92492G Other fracture of left great toe, subsequent encounter for fracture with delayed healing',
  'S92492K Other fracture of left great toe, subsequent encounter for fracture with nonunion',
  'S92492P Other fracture of left great toe, subsequent encounter for fracture with malunion',
  'S92492S Other fracture of left great toe, sequela',
  'S92499A Other fracture of unspecified great toe, initial encounter for closed fracture',
  'S92499B Other fracture of unspecified great toe, initial encounter for open fracture',
  'S92499D Other fracture of unspecified great toe, subsequent encounter for fracture with routine',
  'healing',
  'S92499G Other fracture of unspecified great toe, subsequent encounter for fracture with delayed',
  'healing',
  'S92499K Other fracture of unspecified great toe, subsequent encounter for fracture with nonunion',
  'S92499P Other fracture of unspecified great toe, subsequent encounter for fracture with malunion',
  'S92499S Other fracture of unspecified great toe, sequela',
  'S92501A Displaced unspecified fracture of right lesser toe(s), initial encounter for closed fracture',
  'S92501B Displaced unspecified fracture of right lesser toe(s), initial encounter for open fracture',
  'S92501D Displaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture with',
  'routine healing',
  'S92501G Displaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture with',
  'delayed healing',
  'S92501K Displaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture with',
  'nonunion',
  'S92501P Displaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture with',
  'malunion',
  'S92501S Displaced unspecified fracture of right lesser toe(s), sequela',
  'S92502A Displaced unspecified fracture of left lesser toe(s), initial encounter for closed fracture',
  'S92502B Displaced unspecified fracture of left lesser toe(s), initial encounter for open fracture',
  'S92502D Displaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'routine healing',
  'S92502G Displaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'delayed healing',
  'S92502K Displaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'nonunion',
  'S92502P Displaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'malunion',
  'S92502S Displaced unspecified fracture of left lesser toe(s), sequela',
  'S92503A Displaced unspecified fracture of unspecified lesser toe(s), initial encounter for closed fracture',
  'S92503B Displaced unspecified fracture of unspecified lesser toe(s), initial encounter for open fracture',
  'S92503D Displaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92503G Displaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92503K Displaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92503P Displaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92503S Displaced unspecified fracture of unspecified lesser toe(s), sequela',
  'S92504A Nondisplaced unspecified fracture of right lesser toe(s), initial encounter for closed fracture',
  'S92504B Nondisplaced unspecified fracture of right lesser toe(s), initial encounter for open fracture',
  'S92504D Nondisplaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92504G Nondisplaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92504K Nondisplaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92504P Nondisplaced unspecified fracture of right lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92504S Nondisplaced unspecified fracture of right lesser toe(s), sequela',
  'S92505A Nondisplaced unspecified fracture of left lesser toe(s), initial encounter for closed fracture',
  'S92505B Nondisplaced unspecified fracture of left lesser toe(s), initial encounter for open fracture',
  'S92505D Nondisplaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'routine healing',
  'S92505G Nondisplaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'delayed healing',
  'S92505K Nondisplaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'nonunion',
  'S92505P Nondisplaced unspecified fracture of left lesser toe(s), subsequent encounter for fracture with',
  'malunion',
  'S92505S Nondisplaced unspecified fracture of left lesser toe(s), sequela',
  'S92506A Nondisplaced unspecified fracture of unspecified lesser toe(s), initial encounter for closed',
  'fracture',
  'S92506B Nondisplaced unspecified fracture of unspecified lesser toe(s), initial encounter for open',
  'fracture',
  'S92506D Nondisplaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92506G Nondisplaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92506K Nondisplaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92506P Nondisplaced unspecified fracture of unspecified lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92506S Nondisplaced unspecified fracture of unspecified lesser toe(s), sequela',
  'S92511A Displaced fracture of proximal phalanx of right lesser toe(s), initial encounter for closed',
  'fracture',
  'S92511B Displaced fracture of proximal phalanx of right lesser toe(s), initial encounter for open fracture',
  'S92511D Displaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92511G Displaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92511K Displaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92511P Displaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92511S Displaced fracture of proximal phalanx of right lesser toe(s), sequela',
  'S92512A Displaced fracture of proximal phalanx of left lesser toe(s), initial encounter for closed fracture',
  'S92512B Displaced fracture of proximal phalanx of left lesser toe(s), initial encounter for open fracture',
  'S92512D Displaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92512G Displaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92512K Displaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92512P Displaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92512S Displaced fracture of proximal phalanx of left lesser toe(s), sequela',
  'S92513A Displaced fracture of proximal phalanx of unspecified lesser toe(s), initial encounter for closed',
  'fracture',
  'S92513B Displaced fracture of proximal phalanx of unspecified lesser toe(s), initial encounter for open',
  'fracture',
  'S92513D Displaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92513G Displaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92513K Displaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92513P Displaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92513S Displaced fracture of proximal phalanx of unspecified lesser toe(s), sequela',
  'S92514A Nondisplaced fracture of proximal phalanx of right lesser toe(s), initial encounter for closed',
  'fracture',
  'S92514B Nondisplaced fracture of proximal phalanx of right lesser toe(s), initial encounter for open',
  'fracture',
  'S92514D Nondisplaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92514G Nondisplaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92514K Nondisplaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92514P Nondisplaced fracture of proximal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92514S Nondisplaced fracture of proximal phalanx of right lesser toe(s), sequela',
  'S92515A Nondisplaced fracture of proximal phalanx of left lesser toe(s), initial encounter for closed',
  'fracture',
  'S92515B Nondisplaced fracture of proximal phalanx of left lesser toe(s), initial encounter for open',
  'fracture',
  'S92515D Nondisplaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92515G Nondisplaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92515K Nondisplaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92515P Nondisplaced fracture of proximal phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92515S Nondisplaced fracture of proximal phalanx of left lesser toe(s), sequela',
  'S92516A Nondisplaced fracture of proximal phalanx of unspecified lesser toe(s), initial encounter for',
  'closed fracture',
  'S92516B Nondisplaced fracture of proximal phalanx of unspecified lesser toe(s), initial encounter for',
  'open fracture',
  'S92516D Nondisplaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter',
  'for fracture with routine healing',
  'S92516G Nondisplaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter',
  'for fracture with delayed healing',
  'S92516K Nondisplaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter',
  'for fracture with nonunion',
  'S92516P Nondisplaced fracture of proximal phalanx of unspecified lesser toe(s), subsequent encounter',
  'for fracture with malunion',
  'S92516S Nondisplaced fracture of proximal phalanx of unspecified lesser toe(s), sequela',
  'S92521A Displaced fracture of medial phalanx of right lesser toe(s), initial encounter for closed fracture',
  'S92521B Displaced fracture of medial phalanx of right lesser toe(s), initial encounter for open fracture',
  'S92521D Displaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92521G Displaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92521K Displaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92521P Displaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92521S Displaced fracture of medial phalanx of right lesser toe(s), sequela',
  'S92522A Displaced fracture of medial phalanx of left lesser toe(s), initial encounter for closed fracture',
  'S92522B Displaced fracture of medial phalanx of left lesser toe(s), initial encounter for open fracture',
  'S92522D Displaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92522G Displaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92522K Displaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92522P Displaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92522S Displaced fracture of medial phalanx of left lesser toe(s), sequela',
  'S92523A Displaced fracture of medial phalanx of unspecified lesser toe(s), initial encounter for closed',
  'fracture',
  'S92523B Displaced fracture of medial phalanx of unspecified lesser toe(s), initial encounter for open',
  'fracture',
  'S92523D Displaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92523G Displaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92523K Displaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92523P Displaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92523S Displaced fracture of medial phalanx of unspecified lesser toe(s), sequela',
  'S92524A Nondisplaced fracture of medial phalanx of right lesser toe(s), initial encounter for closed',
  'fracture',
  'S92524B Nondisplaced fracture of medial phalanx of right lesser toe(s), initial encounter for open',
  'fracture',
  'S92524D Nondisplaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92524G Nondisplaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92524K Nondisplaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92524P Nondisplaced fracture of medial phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92524S Nondisplaced fracture of medial phalanx of right lesser toe(s), sequela',
  'S92525A Nondisplaced fracture of medial phalanx of left lesser toe(s), initial encounter for closed',
  'fracture',
  'S92525B Nondisplaced fracture of medial phalanx of left lesser toe(s), initial encounter for open fracture',
  'S92525D Nondisplaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92525G Nondisplaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92525K Nondisplaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92525P Nondisplaced fracture of medial phalanx of left lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92525S Nondisplaced fracture of medial phalanx of left lesser toe(s), sequela',
  'S92526A Nondisplaced fracture of medial phalanx of unspecified lesser toe(s), initial encounter for',
  'closed fracture',
  'S92526B Nondisplaced fracture of medial phalanx of unspecified lesser toe(s), initial encounter for open',
  'fracture',
  'S92526D Nondisplaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92526G Nondisplaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92526K Nondisplaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92526P Nondisplaced fracture of medial phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92526S Nondisplaced fracture of medial phalanx of unspecified lesser toe(s), sequela',
  'S92531A Displaced fracture of distal phalanx of right lesser toe(s), initial encounter for closed fracture',
  'S92531B Displaced fracture of distal phalanx of right lesser toe(s), initial encounter for open fracture',
  'S92531D Displaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92531G Displaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92531K Displaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92531P Displaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92531S Displaced fracture of distal phalanx of right lesser toe(s), sequela',
  'S92532A Displaced fracture of distal phalanx of left lesser toe(s), initial encounter for closed fracture',
  'S92532B Displaced fracture of distal phalanx of left lesser toe(s), initial encounter for open fracture',
  'S92532D Displaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture with',
  'routine healing',
  'S92532G Displaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture with',
  'delayed healing',
  'S92532K Displaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture with',
  'nonunion',
  'S92532P Displaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture with',
  'malunion',
  'S92532S Displaced fracture of distal phalanx of left lesser toe(s), sequela',
  'S92533A Displaced fracture of distal phalanx of unspecified lesser toe(s), initial encounter for closed',
  'fracture',
  'S92533B Displaced fracture of distal phalanx of unspecified lesser toe(s), initial encounter for open',
  'fracture',
  'S92533D Displaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92533G Displaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92533K Displaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92533P Displaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92533S Displaced fracture of distal phalanx of unspecified lesser toe(s), sequela',
  'S92534A Nondisplaced fracture of distal phalanx of right lesser toe(s), initial encounter for closed',
  'fracture',
  'S92534B Nondisplaced fracture of distal phalanx of right lesser toe(s), initial encounter for open fracture',
  'S92534D Nondisplaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92534G Nondisplaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92534K Nondisplaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92534P Nondisplaced fracture of distal phalanx of right lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92534S Nondisplaced fracture of distal phalanx of right lesser toe(s), sequela',
  'S92535A Nondisplaced fracture of distal phalanx of left lesser toe(s), initial encounter for closed fracture',
  'S92535B Nondisplaced fracture of distal phalanx of left lesser toe(s), initial encounter for open fracture',
  'S92535D Nondisplaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with routine healing',
  'S92535G Nondisplaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with delayed healing',
  'S92535K Nondisplaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with nonunion',
  'S92535P Nondisplaced fracture of distal phalanx of left lesser toe(s), subsequent encounter for fracture',
  'with malunion',
  'S92535S Nondisplaced fracture of distal phalanx of left lesser toe(s), sequela',
  'S92536A Nondisplaced fracture of distal phalanx of unspecified lesser toe(s), initial encounter for closed',
  'fracture',
  'S92536B Nondisplaced fracture of distal phalanx of unspecified lesser toe(s), initial encounter for open',
  'fracture',
  'S92536D Nondisplaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with routine healing',
  'S92536G Nondisplaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with delayed healing',
  'S92536K Nondisplaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with nonunion',
  'S92536P Nondisplaced fracture of distal phalanx of unspecified lesser toe(s), subsequent encounter for',
  'fracture with malunion',
  'S92536S Nondisplaced fracture of distal phalanx of unspecified lesser toe(s), sequela',
  'S92591A Other fracture of right lesser toe(s), initial encounter for closed fracture',
  'S92591B Other fracture of right lesser toe(s), initial encounter for open fracture',
  'S92591D Other fracture of right lesser toe(s), subsequent encounter for fracture with routine healing',
  'S92591G Other fracture of right lesser toe(s), subsequent encounter for fracture with delayed healing',
  'S92591K Other fracture of right lesser toe(s), subsequent encounter for fracture with nonunion',
  'S92591P Other fracture of right lesser toe(s), subsequent encounter for fracture with malunion',
  'S92591S Other fracture of right lesser toe(s), sequela',
  'S92592A Other fracture of left lesser toe(s), initial encounter for closed fracture',
  'S92592B Other fracture of left lesser toe(s), initial encounter for open fracture',
  'S92592D Other fracture of left lesser toe(s), subsequent encounter for fracture with routine healing',
  'S92592G Other fracture of left lesser toe(s), subsequent encounter for fracture with delayed healing',
  'S92592K Other fracture of left lesser toe(s), subsequent encounter for fracture with nonunion',
  'S92592P Other fracture of left lesser toe(s), subsequent encounter for fracture with malunion',
  'S92592S Other fracture of left lesser toe(s), sequela',
  'S92599A Other fracture of unspecified lesser toe(s), initial encounter for closed fracture',
  'S92599B Other fracture of unspecified lesser toe(s), initial encounter for open fracture',
  'S92599D Other fracture of unspecified lesser toe(s), subsequent encounter for fracture with routine',
  'healing',
  'S92599G Other fracture of unspecified lesser toe(s), subsequent encounter for fracture with delayed',
  'healing',
  'S92599K Other fracture of unspecified lesser toe(s), subsequent encounter for fracture with nonunion',
  'S92599P Other fracture of unspecified lesser toe(s), subsequent encounter for fracture with malunion',
  'S92599S Other fracture of unspecified lesser toe(s), sequela',
  'S92811A Other fracture of right foot, initial encounter for closed fracture',
  'S92811B Other fracture of right foot, initial encounter for open fracture',
  'S92811D Other fracture of right foot, subsequent encounter for fracture with routine healing',
  'S92811G Other fracture of right foot, subsequent encounter for fracture with delayed healing',
  'S92811K Other fracture of right foot, subsequent encounter for fracture with nonunion',
  'S92811P Other fracture of right foot, subsequent encounter for fracture with malunion',
  'S92811S Other fracture of right foot, sequela',
  'S92812A Other fracture of left foot, initial encounter for closed fracture',
  'S92812B Other fracture of left foot, initial encounter for open fracture',
  'S92812D Other fracture of left foot, subsequent encounter for fracture with routine healing',
  'S92812G Other fracture of left foot, subsequent encounter for fracture with delayed healing',
  'S92812K Other fracture of left foot, subsequent encounter for fracture with nonunion',
  'S92812P Other fracture of left foot, subsequent encounter for fracture with malunion',
  'S92812S Other fracture of left foot, sequela',
  'S92819A Other fracture of unspecified foot, initial encounter for closed fracture',
  'S92819B Other fracture of unspecified foot, initial encounter for open fracture',
  'S92819D Other fracture of unspecified foot, subsequent encounter for fracture with routine healing',
  'S92819G Other fracture of unspecified foot, subsequent encounter for fracture with delayed healing',
  'S92819K Other fracture of unspecified foot, subsequent encounter for fracture with nonunion',
  'S92819P Other fracture of unspecified foot, subsequent encounter for fracture with malunion',
  'S92819S Other fracture of unspecified foot, sequela',
  'S92901A Unspecified fracture of right foot, initial encounter for closed fracture',
  'S92901B Unspecified fracture of right foot, initial encounter for open fracture',
  'S92901D Unspecified fracture of right foot, subsequent encounter for fracture with routine healing',
  'S92901G Unspecified fracture of right foot, subsequent encounter for fracture with delayed healing',
  'S92901K Unspecified fracture of right foot, subsequent encounter for fracture with nonunion',
  'S92901P Unspecified fracture of right foot, subsequent encounter for fracture with malunion',
  'S92901S Unspecified fracture of right foot, sequela',
  'S92902A Unspecified fracture of left foot, initial encounter for closed fracture',
  'S92902B Unspecified fracture of left foot, initial encounter for open fracture',
  'S92902D Unspecified fracture of left foot, subsequent encounter for fracture with routine healing',
  'S92902G Unspecified fracture of left foot, subsequent encounter for fracture with delayed healing',
  'S92902K Unspecified fracture of left foot, subsequent encounter for fracture with nonunion',
  'S92902P Unspecified fracture of left foot, subsequent encounter for fracture with malunion',
  'S92902S Unspecified fracture of left foot, sequela',
  'S92909A Unspecified fracture of unspecified foot, initial encounter for closed fracture',
  'S92909B Unspecified fracture of unspecified foot, initial encounter for open fracture',
  'S92909D Unspecified fracture of unspecified foot, subsequent encounter for fracture with routine',
  'healing',
  'S92909G Unspecified fracture of unspecified foot, subsequent encounter for fracture with delayed',
  'healing',
  'S92909K Unspecified fracture of unspecified foot, subsequent encounter for fracture with nonunion',
  'S92909P Unspecified fracture of unspecified foot, subsequent encounter for fracture with malunion',
  'S92909S Unspecified fracture of unspecified foot, sequela',
  'S92911A Unspecified fracture of right toe(s), initial encounter for closed fracture',
  'S92911B Unspecified fracture of right toe(s), initial encounter for open fracture',
  'S92911D Unspecified fracture of right toe(s), subsequent encounter for fracture with routine healing',
  'S92911G Unspecified fracture of right toe(s), subsequent encounter for fracture with delayed healing',
  'S92911K Unspecified fracture of right toe(s), subsequent encounter for fracture with nonunion',
  'S92911P Unspecified fracture of right toe(s), subsequent encounter for fracture with malunion',
  'S92911S Unspecified fracture of right toe(s), sequela',
  'S92912A Unspecified fracture of left toe(s), initial encounter for closed fracture',
  'S92912B Unspecified fracture of left toe(s), initial encounter for open fracture',
  'S92912D Unspecified fracture of left toe(s), subsequent encounter for fracture with routine healing',
  'S92912G Unspecified fracture of left toe(s), subsequent encounter for fracture with delayed healing',
  'S92912K Unspecified fracture of left toe(s), subsequent encounter for fracture with nonunion',
  'S92912P Unspecified fracture of left toe(s), subsequent encounter for fracture with malunion',
  'S92912S Unspecified fracture of left toe(s), sequela',
  'S92919A Unspecified fracture of unspecified toe(s), initial encounter for closed fracture',
  'S92919B Unspecified fracture of unspecified toe(s), initial encounter for open fracture',
  'S92919D Unspecified fracture of unspecified toe(s), subsequent encounter for fracture with routine',
  'healing',
  'S92919G Unspecified fracture of unspecified toe(s), subsequent encounter for fracture with delayed',
  'healing',
  'S92919K Unspecified fracture of unspecified toe(s), subsequent encounter for fracture with nonunion',
  'S92919P Unspecified fracture of unspecified toe(s), subsequent encounter for fracture with malunion',
  'S92919S Unspecified fracture of unspecified toe(s), sequela',
  'S9301XA Subluxation of right ankle joint, initial encounter',
  'S9301XD Subluxation of right ankle joint, subsequent encounter',
  'S9301XS Subluxation of right ankle joint, sequela',
  'S9302XA Subluxation of left ankle joint, initial encounter',
  'S9302XD Subluxation of left ankle joint, subsequent encounter',
  'S9302XS Subluxation of left ankle joint, sequela',
  'S9303XA Subluxation of unspecified ankle joint, initial encounter',
  'S9303XD Subluxation of unspecified ankle joint, subsequent encounter',
  'S9303XS Subluxation of unspecified ankle joint, sequela',
  'S9304XA Dislocation of right ankle joint, initial encounter',
  'S9304XD Dislocation of right ankle joint, subsequent encounter',
  'S9304XS Dislocation of right ankle joint, sequela',
  'S9305XA Dislocation of left ankle joint, initial encounter',
  'S9305XD Dislocation of left ankle joint, subsequent encounter',
  'S9305XS Dislocation of left ankle joint, sequela',
  'S9306XA Dislocation of unspecified ankle joint, initial encounter',
  'S9306XD Dislocation of unspecified ankle joint, subsequent encounter',
  'S9306XS Dislocation of unspecified ankle joint, sequela',
  'S93101A Unspecified subluxation of right toe(s), initial encounter',
  'S93101D Unspecified subluxation of right toe(s), subsequent encounter',
  'S93101S Unspecified subluxation of right toe(s), sequela',
  'S93102A Unspecified subluxation of left toe(s), initial encounter',
  'S93102D Unspecified subluxation of left toe(s), subsequent encounter',
  'S93102S Unspecified subluxation of left toe(s), sequela',
  'S93103A Unspecified subluxation of unspecified toe(s), initial encounter',
  'S93103D Unspecified subluxation of unspecified toe(s), subsequent encounter',
  'S93103S Unspecified subluxation of unspecified toe(s), sequela',
  'S93104A Unspecified dislocation of right toe(s), initial encounter',
  'S93104D Unspecified dislocation of right toe(s), subsequent encounter',
  'S93104S Unspecified dislocation of right toe(s), sequela',
  'S93105A Unspecified dislocation of left toe(s), initial encounter',
  'S93105D Unspecified dislocation of left toe(s), subsequent encounter',
  'S93105S Unspecified dislocation of left toe(s), sequela',
  'S93106A Unspecified dislocation of unspecified toe(s), initial encounter',
  'S93106D Unspecified dislocation of unspecified toe(s), subsequent encounter',
  'S93106S Unspecified dislocation of unspecified toe(s), sequela',
  'S93111A Dislocation of interphalangeal joint of right great toe, initial encounter',
  'S93111D Dislocation of interphalangeal joint of right great toe, subsequent encounter',
  'S93111S Dislocation of interphalangeal joint of right great toe, sequela',
  'S93112A Dislocation of interphalangeal joint of left great toe, initial encounter',
  'S93112D Dislocation of interphalangeal joint of left great toe, subsequent encounter',
  'S93112S Dislocation of interphalangeal joint of left great toe, sequela',
  'S93113A Dislocation of interphalangeal joint of unspecified great toe, initial encounter',
  'S93113D Dislocation of interphalangeal joint of unspecified great toe, subsequent encounter',
  'S93113S Dislocation of interphalangeal joint of unspecified great toe, sequela',
  'S93114A Dislocation of interphalangeal joint of right lesser toe(s), initial encounter',
  'S93114D Dislocation of interphalangeal joint of right lesser toe(s), subsequent encounter',
  'S93114S Dislocation of interphalangeal joint of right lesser toe(s), sequela',
  'S93115A Dislocation of interphalangeal joint of left lesser toe(s), initial encounter',
  'S93115D Dislocation of interphalangeal joint of left lesser toe(s), subsequent encounter',
  'S93115S Dislocation of interphalangeal joint of left lesser toe(s), sequela',
  'S93116A Dislocation of interphalangeal joint of unspecified lesser toe(s), initial encounter',
  'S93116D Dislocation of interphalangeal joint of unspecified lesser toe(s), subsequent encounter',
  'S93116S Dislocation of interphalangeal joint of unspecified lesser toe(s), sequela',
  'S93119A Dislocation of interphalangeal joint of unspecified toe(s), initial encounter',
  'S93119D Dislocation of interphalangeal joint of unspecified toe(s), subsequent encounter',
  'S93119S Dislocation of interphalangeal joint of unspecified toe(s), sequela',
  'S93121A Dislocation of metatarsophalangeal joint of right great toe, initial encounter',
  'S93121D Dislocation of metatarsophalangeal joint of right great toe, subsequent encounter',
  'S93121S Dislocation of metatarsophalangeal joint of right great toe, sequela',
  'S93122A Dislocation of metatarsophalangeal joint of left great toe, initial encounter',
  'S93122D Dislocation of metatarsophalangeal joint of left great toe, subsequent encounter',
  'S93122S Dislocation of metatarsophalangeal joint of left great toe, sequela',
  'S93123A Dislocation of metatarsophalangeal joint of unspecified great toe, initial encounter',
  'S93123D Dislocation of metatarsophalangeal joint of unspecified great toe, subsequent encounter',
  'S93123S Dislocation of metatarsophalangeal joint of unspecified great toe, sequela',
  'S93124A Dislocation of metatarsophalangeal joint of right lesser toe(s), initial encounter',
  'S93124D Dislocation of metatarsophalangeal joint of right lesser toe(s), subsequent encounter',
  'S93124S Dislocation of metatarsophalangeal joint of right lesser toe(s), sequela',
  'S93125A Dislocation of metatarsophalangeal joint of left lesser toe(s), initial encounter',
  'S93125D Dislocation of metatarsophalangeal joint of left lesser toe(s), subsequent encounter',
  'S93125S Dislocation of metatarsophalangeal joint of left lesser toe(s), sequela',
  'S93126A Dislocation of metatarsophalangeal joint of unspecified lesser toe(s), initial encounter',
  'S93126D Dislocation of metatarsophalangeal joint of unspecified lesser toe(s), subsequent encounter',
  'S93126S Dislocation of metatarsophalangeal joint of unspecified lesser toe(s), sequela',
  'S93129A Dislocation of metatarsophalangeal joint of unspecified toe(s), initial encounter',
  'S93129D Dislocation of metatarsophalangeal joint of unspecified toe(s), subsequent encounter',
  'S93129S Dislocation of metatarsophalangeal joint of unspecified toe(s), sequela',
  'S93131A Subluxation of interphalangeal joint of right great toe, initial encounter',
  'S93131D Subluxation of interphalangeal joint of right great toe, subsequent encounter',
  'S93131S Subluxation of interphalangeal joint of right great toe, sequela',
  'S93132A Subluxation of interphalangeal joint of left great toe, initial encounter',
  'S93132D Subluxation of interphalangeal joint of left great toe, subsequent encounter',
  'S93132S Subluxation of interphalangeal joint of left great toe, sequela',
  'S93133A Subluxation of interphalangeal joint of unspecified great toe, initial encounter',
  'S93133D Subluxation of interphalangeal joint of unspecified great toe, subsequent encounter',
  'S93133S Subluxation of interphalangeal joint of unspecified great toe, sequela',
  'S93134A Subluxation of interphalangeal joint of right lesser toe(s), initial encounter',
  'S93134D Subluxation of interphalangeal joint of right lesser toe(s), subsequent encounter',
  'S93134S Subluxation of interphalangeal joint of right lesser toe(s), sequela',
  'S93135A Subluxation of interphalangeal joint of left lesser toe(s), initial encounter',
  'S93135D Subluxation of interphalangeal joint of left lesser toe(s), subsequent encounter',
  'S93135S Subluxation of interphalangeal joint of left lesser toe(s), sequela',
  'S93136A Subluxation of interphalangeal joint of unspecified lesser toe(s), initial encounter',
  'S93136D Subluxation of interphalangeal joint of unspecified lesser toe(s), subsequent encounter',
  'S93136S Subluxation of interphalangeal joint of unspecified lesser toe(s), sequela',
  'S93139A Subluxation of interphalangeal joint of unspecified toe(s), initial encounter',
  'S93139D Subluxation of interphalangeal joint of unspecified toe(s), subsequent encounter',
  'S93139S Subluxation of interphalangeal joint of unspecified toe(s), sequela',
  'S93141A Subluxation of metatarsophalangeal joint of right great toe, initial encounter',
  'S93141D Subluxation of metatarsophalangeal joint of right great toe, subsequent encounter',
  'S93141S Subluxation of metatarsophalangeal joint of right great toe, sequela',
  'S93142A Subluxation of metatarsophalangeal joint of left great toe, initial encounter',
  'S93142D Subluxation of metatarsophalangeal joint of left great toe, subsequent encounter',
  'S93142S Subluxation of metatarsophalangeal joint of left great toe, sequela',
  'S93143A Subluxation of metatarsophalangeal joint of unspecified great toe, initial encounter',
  'S93143D Subluxation of metatarsophalangeal joint of unspecified great toe, subsequent encounter',
  'S93143S Subluxation of metatarsophalangeal joint of unspecified great toe, sequela',
  'S93144A Subluxation of metatarsophalangeal joint of right lesser toe(s), initial encounter',
  'S93144D Subluxation of metatarsophalangeal joint of right lesser toe(s), subsequent encounter',
  'S93144S Subluxation of metatarsophalangeal joint of right lesser toe(s), sequela',
  'S93145A Subluxation of metatarsophalangeal joint of left lesser toe(s), initial encounter',
  'S93145D Subluxation of metatarsophalangeal joint of left lesser toe(s), subsequent encounter',
  'S93145S Subluxation of metatarsophalangeal joint of left lesser toe(s), sequela',
  'S93146A Subluxation of metatarsophalangeal joint of unspecified lesser toe(s), initial encounter',
  'S93146D Subluxation of metatarsophalangeal joint of unspecified lesser toe(s), subsequent encounter',
  'S93146S Subluxation of metatarsophalangeal joint of unspecified lesser toe(s), sequela',
  'S93149A Subluxation of metatarsophalangeal joint of unspecified toe(s), initial encounter',
  'S93149D Subluxation of metatarsophalangeal joint of unspecified toe(s), subsequent encounter',
  'S93149S Subluxation of metatarsophalangeal joint of unspecified toe(s), sequela',
  'S93301A Unspecified subluxation of right foot, initial encounter',
  'S93301D Unspecified subluxation of right foot, subsequent encounter',
  'S93301S Unspecified subluxation of right foot, sequela',
  'S93302A Unspecified subluxation of left foot, initial encounter',
  'S93302D Unspecified subluxation of left foot, subsequent encounter',
  'S93302S Unspecified subluxation of left foot, sequela',
  'S93303A Unspecified subluxation of unspecified foot, initial encounter',
  'S93303D Unspecified subluxation of unspecified foot, subsequent encounter',
  'S93303S Unspecified subluxation of unspecified foot, sequela',
  'S93304A Unspecified dislocation of right foot, initial encounter',
  'S93304D Unspecified dislocation of right foot, subsequent encounter',
  'S93304S Unspecified dislocation of right foot, sequela',
  'S93305A Unspecified dislocation of left foot, initial encounter',
  'S93305D Unspecified dislocation of left foot, subsequent encounter',
  'S93305S Unspecified dislocation of left foot, sequela',
  'S93306A Unspecified dislocation of unspecified foot, initial encounter',
  'S93306D Unspecified dislocation of unspecified foot, subsequent encounter',
  'S93306S Unspecified dislocation of unspecified foot, sequela',
  'S93311A Subluxation of tarsal joint of right foot, initial encounter',
  'S93311D Subluxation of tarsal joint of right foot, subsequent encounter',
  'S93311S Subluxation of tarsal joint of right foot, sequela',
  'S93312A Subluxation of tarsal joint of left foot, initial encounter',
  'S93312D Subluxation of tarsal joint of left foot, subsequent encounter',
  'S93312S Subluxation of tarsal joint of left foot, sequela',
  'S93313A Subluxation of tarsal joint of unspecified foot, initial encounter',
  'S93313D Subluxation of tarsal joint of unspecified foot, subsequent encounter',
  'S93313S Subluxation of tarsal joint of unspecified foot, sequela',
  'S93314A Dislocation of tarsal joint of right foot, initial encounter',
  'S93314D Dislocation of tarsal joint of right foot, subsequent encounter',
  'S93314S Dislocation of tarsal joint of right foot, sequela',
  'S93315A Dislocation of tarsal joint of left foot, initial encounter',
  'S93315D Dislocation of tarsal joint of left foot, subsequent encounter',
  'S93315S Dislocation of tarsal joint of left foot, sequela',
  'S93316A Dislocation of tarsal joint of unspecified foot, initial encounter',
  'S93316D Dislocation of tarsal joint of unspecified foot, subsequent encounter',
  'S93316S Dislocation of tarsal joint of unspecified foot, sequela',
  'S93321A Subluxation of tarsometatarsal joint of right foot, initial encounter',
  'S93321D Subluxation of tarsometatarsal joint of right foot, subsequent encounter',
  'S93321S Subluxation of tarsometatarsal joint of right foot, sequela',
  'S93322A Subluxation of tarsometatarsal joint of left foot, initial encounter',
  'S93322D Subluxation of tarsometatarsal joint of left foot, subsequent encounter',
  'S93322S Subluxation of tarsometatarsal joint of left foot, sequela',
  'S93323A Subluxation of tarsometatarsal joint of unspecified foot, initial encounter',
  'S93323D Subluxation of tarsometatarsal joint of unspecified foot, subsequent encounter',
  'S93323S Subluxation of tarsometatarsal joint of unspecified foot, sequela',
  'S93324A Dislocation of tarsometatarsal joint of right foot, initial encounter',
  'S93324D Dislocation of tarsometatarsal joint of right foot, subsequent encounter',
  'S93324S Dislocation of tarsometatarsal joint of right foot, sequela',
  'S93325A Dislocation of tarsometatarsal joint of left foot, initial encounter',
  'S93325D Dislocation of tarsometatarsal joint of left foot, subsequent encounter',
  'S93325S Dislocation of tarsometatarsal joint of left foot, sequela',
  'S93326A Dislocation of tarsometatarsal joint of unspecified foot, initial encounter',
  'S93326D Dislocation of tarsometatarsal joint of unspecified foot, subsequent encounter',
  'S93326S Dislocation of tarsometatarsal joint of unspecified foot, sequela',
  'S93331A Other subluxation of right foot, initial encounter',
  'S93331D Other subluxation of right foot, subsequent encounter',
  'S93331S Other subluxation of right foot, sequela',
  'S93332A Other subluxation of left foot, initial encounter',
  'S93332D Other subluxation of left foot, subsequent encounter',
  'S93332S Other subluxation of left foot, sequela',
  'S93333A Other subluxation of unspecified foot, initial encounter',
  'S93333D Other subluxation of unspecified foot, subsequent encounter',
  'S93333S Other subluxation of unspecified foot, sequela',
  'S93334A Other dislocation of right foot, initial encounter',
  'S93334D Other dislocation of right foot, subsequent encounter',
  'S93334S Other dislocation of right foot, sequela',
  'S93335A Other dislocation of left foot, initial encounter',
  'S93335D Other dislocation of left foot, subsequent encounter',
  'S93335S Other dislocation of left foot, sequela',
  'S93336A Other dislocation of unspecified foot, initial encounter',
  'S93336D Other dislocation of unspecified foot, subsequent encounter',
  'S93336S Other dislocation of unspecified foot, sequela',
  'S93401A Sprain of unspecified ligament of right ankle, initial encounter',
  'S93401D Sprain of unspecified ligament of right ankle, subsequent encounter',
  'S93401S Sprain of unspecified ligament of right ankle, sequela',
  'S93402A Sprain of unspecified ligament of left ankle, initial encounter',
  'S93402D Sprain of unspecified ligament of left ankle, subsequent encounter',
  'S93402S Sprain of unspecified ligament of left ankle, sequela',
  'S93409A Sprain of unspecified ligament of unspecified ankle, initial encounter',
  'S93409D Sprain of unspecified ligament of unspecified ankle, subsequent encounter',
  'S93409S Sprain of unspecified ligament of unspecified ankle, sequela',
  'S93411A Sprain of calcaneofibular ligament of right ankle, initial encounter',
  'S93411D Sprain of calcaneofibular ligament of right ankle, subsequent encounter',
  'S93411S Sprain of calcaneofibular ligament of right ankle, sequela',
  'S93412A Sprain of calcaneofibular ligament of left ankle, initial encounter',
  'S93412D Sprain of calcaneofibular ligament of left ankle, subsequent encounter',
  'S93412S Sprain of calcaneofibular ligament of left ankle, sequela',
  'S93419A Sprain of calcaneofibular ligament of unspecified ankle, initial encounter',
  'S93419D Sprain of calcaneofibular ligament of unspecified ankle, subsequent encounter',
  'S93419S Sprain of calcaneofibular ligament of unspecified ankle, sequela',
  'S93421A Sprain of deltoid ligament of right ankle, initial encounter',
  'S93421D Sprain of deltoid ligament of right ankle, subsequent encounter',
  'S93421S Sprain of deltoid ligament of right ankle, sequela',
  'S93422A Sprain of deltoid ligament of left ankle, initial encounter',
  'S93422D Sprain of deltoid ligament of left ankle, subsequent encounter',
  'S93422S Sprain of deltoid ligament of left ankle, sequela',
  'S93429A Sprain of deltoid ligament of unspecified ankle, initial encounter',
  'S93429D Sprain of deltoid ligament of unspecified ankle, subsequent encounter',
  'S93429S Sprain of deltoid ligament of unspecified ankle, sequela',
  'S93431A Sprain of tibiofibular ligament of right ankle, initial encounter',
  'S93431D Sprain of tibiofibular ligament of right ankle, subsequent encounter',
  'S93431S Sprain of tibiofibular ligament of right ankle, sequela',
  'S93432A Sprain of tibiofibular ligament of left ankle, initial encounter',
  'S93432D Sprain of tibiofibular ligament of left ankle, subsequent encounter',
  'S93432S Sprain of tibiofibular ligament of left ankle, sequela',
  'S93439A Sprain of tibiofibular ligament of unspecified ankle, initial encounter',
  'S93439D Sprain of tibiofibular ligament of unspecified ankle, subsequent encounter',
  'S93439S Sprain of tibiofibular ligament of unspecified ankle, sequela',
  'S93491A Sprain of other ligament of right ankle, initial encounter',
  'S93491D Sprain of other ligament of right ankle, subsequent encounter',
  'S93491S Sprain of other ligament of right ankle, sequela',
  'S93492A Sprain of other ligament of left ankle, initial encounter',
  'S93492D Sprain of other ligament of left ankle, subsequent encounter',
  'S93492S Sprain of other ligament of left ankle, sequela',
  'S93499A Sprain of other ligament of unspecified ankle, initial encounter',
  'S93499D Sprain of other ligament of unspecified ankle, subsequent encounter',
  'S93499S Sprain of other ligament of unspecified ankle, sequela',
  'S93501A Unspecified sprain of right great toe, initial encounter',
  'S93501D Unspecified sprain of right great toe, subsequent encounter',
  'S93501S Unspecified sprain of right great toe, sequela',
  'S93502A Unspecified sprain of left great toe, initial encounter',
  'S93502D Unspecified sprain of left great toe, subsequent encounter',
  'S93502S Unspecified sprain of left great toe, sequela',
  'S93503A Unspecified sprain of unspecified great toe, initial encounter',
  'S93503D Unspecified sprain of unspecified great toe, subsequent encounter',
  'S93503S Unspecified sprain of unspecified great toe, sequela',
  'S93504A Unspecified sprain of right lesser toe(s), initial encounter',
  'S93504D Unspecified sprain of right lesser toe(s), subsequent encounter',
  'S93504S Unspecified sprain of right lesser toe(s), sequela',
  'S93505A Unspecified sprain of left lesser toe(s), initial encounter',
  'S93505D Unspecified sprain of left lesser toe(s), subsequent encounter',
  'S93505S Unspecified sprain of left lesser toe(s), sequela',
  'S93506A Unspecified sprain of unspecified lesser toe(s), initial encounter',
  'S93506D Unspecified sprain of unspecified lesser toe(s), subsequent encounter',
  'S93506S Unspecified sprain of unspecified lesser toe(s), sequela',
  'S93509A Unspecified sprain of unspecified toe(s), initial encounter',
  'S93509D Unspecified sprain of unspecified toe(s), subsequent encounter',
  'S93509S Unspecified sprain of unspecified toe(s), sequela',
  'S93511A Sprain of interphalangeal joint of right great toe, initial encounter',
  'S93511D Sprain of interphalangeal joint of right great toe, subsequent encounter',
  'S93511S Sprain of interphalangeal joint of right great toe, sequela',
  'S93512A Sprain of interphalangeal joint of left great toe, initial encounter',
  'S93512D Sprain of interphalangeal joint of left great toe, subsequent encounter',
  'S93512S Sprain of interphalangeal joint of left great toe, sequela',
  'S93513A Sprain of interphalangeal joint of unspecified great toe, initial encounter',
  'S93513D Sprain of interphalangeal joint of unspecified great toe, subsequent encounter',
  'S93513S Sprain of interphalangeal joint of unspecified great toe, sequela',
  'S93514A Sprain of interphalangeal joint of right lesser toe(s), initial encounter',
  'S93514D Sprain of interphalangeal joint of right lesser toe(s), subsequent encounter',
  'S93514S Sprain of interphalangeal joint of right lesser toe(s), sequela',
  'S93515A Sprain of interphalangeal joint of left lesser toe(s), initial encounter',
  'S93515D Sprain of interphalangeal joint of left lesser toe(s), subsequent encounter',
  'S93515S Sprain of interphalangeal joint of left lesser toe(s), sequela',
  'S93516A Sprain of interphalangeal joint of unspecified lesser toe(s), initial encounter',
  'S93516D Sprain of interphalangeal joint of unspecified lesser toe(s), subsequent encounter',
  'S93516S Sprain of interphalangeal joint of unspecified lesser toe(s), sequela',
  'S93519A Sprain of interphalangeal joint of unspecified toe(s), initial encounter',
  'S93519D Sprain of interphalangeal joint of unspecified toe(s), subsequent encounter',
  'S93519S Sprain of interphalangeal joint of unspecified toe(s), sequela',
  'S93521A Sprain of metatarsophalangeal joint of right great toe, initial encounter',
  'S93521D Sprain of metatarsophalangeal joint of right great toe, subsequent encounter',
  'S93521S Sprain of metatarsophalangeal joint of right great toe, sequela',
  'S93522A Sprain of metatarsophalangeal joint of left great toe, initial encounter',
  'S93522D Sprain of metatarsophalangeal joint of left great toe, subsequent encounter',
  'S93522S Sprain of metatarsophalangeal joint of left great toe, sequela',
  'S93523A Sprain of metatarsophalangeal joint of unspecified great toe, initial encounter',
  'S93523D Sprain of metatarsophalangeal joint of unspecified great toe, subsequent encounter',
  'S93523S Sprain of metatarsophalangeal joint of unspecified great toe, sequela',
  'S93524A Sprain of metatarsophalangeal joint of right lesser toe(s), initial encounter',
  'S93524D Sprain of metatarsophalangeal joint of right lesser toe(s), subsequent encounter',
  'S93524S Sprain of metatarsophalangeal joint of right lesser toe(s), sequela',
  'S93525A Sprain of metatarsophalangeal joint of left lesser toe(s), initial encounter',
  'S93525D Sprain of metatarsophalangeal joint of left lesser toe(s), subsequent encounter',
  'S93525S Sprain of metatarsophalangeal joint of left lesser toe(s), sequela',
  'S93526A Sprain of metatarsophalangeal joint of unspecified lesser toe(s), initial encounter',
  'S93526D Sprain of metatarsophalangeal joint of unspecified lesser toe(s), subsequent encounter',
  'S93526S Sprain of metatarsophalangeal joint of unspecified lesser toe(s), sequela',
  'S93529A Sprain of metatarsophalangeal joint of unspecified toe(s), initial encounter',
  'S93529D Sprain of metatarsophalangeal joint of unspecified toe(s), subsequent encounter',
  'S93529S Sprain of metatarsophalangeal joint of unspecified toe(s), sequela',
  'S93601A Unspecified sprain of right foot, initial encounter',
  'S93601D Unspecified sprain of right foot, subsequent encounter',
  'S93601S Unspecified sprain of right foot, sequela',
  'S93602A Unspecified sprain of left foot, initial encounter',
  'S93602D Unspecified sprain of left foot, subsequent encounter',
  'S93602S Unspecified sprain of left foot, sequela',
  'S93609A Unspecified sprain of unspecified foot, initial encounter',
  'S93609D Unspecified sprain of unspecified foot, subsequent encounter',
  'S93609S Unspecified sprain of unspecified foot, sequela',
  'S93611A Sprain of tarsal ligament of right foot, initial encounter',
  'S93611D Sprain of tarsal ligament of right foot, subsequent encounter',
  'S93611S Sprain of tarsal ligament of right foot, sequela',
  'S93612A Sprain of tarsal ligament of left foot, initial encounter',
  'S93612D Sprain of tarsal ligament of left foot, subsequent encounter',
  'S93612S Sprain of tarsal ligament of left foot, sequela',
  'S93619A Sprain of tarsal ligament of unspecified foot, initial encounter',
  'S93619D Sprain of tarsal ligament of unspecified foot, subsequent encounter',
  'S93619S Sprain of tarsal ligament of unspecified foot, sequela',
  'S93621A Sprain of tarsometatarsal ligament of right foot, initial encounter',
  'S93621D Sprain of tarsometatarsal ligament of right foot, subsequent encounter',
  'S93621S Sprain of tarsometatarsal ligament of right foot, sequela',
  'S93622A Sprain of tarsometatarsal ligament of left foot, initial encounter',
  'S93622D Sprain of tarsometatarsal ligament of left foot, subsequent encounter',
  'S93622S Sprain of tarsometatarsal ligament of left foot, sequela',
  'S93629A Sprain of tarsometatarsal ligament of unspecified foot, initial encounter',
  'S93629D Sprain of tarsometatarsal ligament of unspecified foot, subsequent encounter',
  'S93629S Sprain of tarsometatarsal ligament of unspecified foot, sequela',
  'S93691A Other sprain of right foot, initial encounter',
  'S93691D Other sprain of right foot, subsequent encounter',
  'S93691S Other sprain of right foot, sequela',
  'S93692A Other sprain of left foot, initial encounter',
  'S93692D Other sprain of left foot, subsequent encounter',
  'S93692S Other sprain of left foot, sequela',
  'S93699A Other sprain of unspecified foot, initial encounter',
  'S93699D Other sprain of unspecified foot, subsequent encounter',
  'S93699S Other sprain of unspecified foot, sequela',
  'S9400XA Injury of lateral plantar nerve, unspecified leg, initial encounter',
  'S9400XD Injury of lateral plantar nerve, unspecified leg, subsequent encounter',
  'S9400XS Injury of lateral plantar nerve, unspecified leg, sequela',
  'S9401XA Injury of lateral plantar nerve, right leg, initial encounter',
  'S9401XD Injury of lateral plantar nerve, right leg, subsequent encounter',
  'S9401XS Injury of lateral plantar nerve, right leg, sequela',
  'S9402XA Injury of lateral plantar nerve, left leg, initial encounter',
  'S9402XD Injury of lateral plantar nerve, left leg, subsequent encounter',
  'S9402XS Injury of lateral plantar nerve, left leg, sequela',
  'S9410XA Injury of medial plantar nerve, unspecified leg, initial encounter',
  'S9410XD Injury of medial plantar nerve, unspecified leg, subsequent encounter',
  'S9410XS Injury of medial plantar nerve, unspecified leg, sequela',
  'S9411XA Injury of medial plantar nerve, right leg, initial encounter',
  'S9411XD Injury of medial plantar nerve, right leg, subsequent encounter',
  'S9411XS Injury of medial plantar nerve, right leg, sequela',
  'S9412XA Injury of medial plantar nerve, left leg, initial encounter',
  'S9412XD Injury of medial plantar nerve, left leg, subsequent encounter',
  'S9412XS Injury of medial plantar nerve, left leg, sequela',
  'S9420XA Injury of deep peroneal nerve at ankle and foot level, unspecified leg, initial encounter',
  'S9420XD Injury of deep peroneal nerve at ankle and foot level, unspecified leg, subsequent encounter',
  'S9420XS Injury of deep peroneal nerve at ankle and foot level, unspecified leg, sequela',
  'S9421XA Injury of deep peroneal nerve at ankle and foot level, right leg, initial encounter',
  'S9421XD Injury of deep peroneal nerve at ankle and foot level, right leg, subsequent encounter',
  'S9421XS Injury of deep peroneal nerve at ankle and foot level, right leg, sequela',
  'S9422XA Injury of deep peroneal nerve at ankle and foot level, left leg, initial encounter',
  'S9422XD Injury of deep peroneal nerve at ankle and foot level, left leg, subsequent encounter',
  'S9422XS Injury of deep peroneal nerve at ankle and foot level, left leg, sequela',
  'S9430XA Injury of cutaneous sensory nerve at ankle and foot level, unspecified leg, initial encounter',
  'S9430XD Injury of cutaneous sensory nerve at ankle and foot level, unspecified leg, subsequent',
  'encounter',
  'S9430XS Injury of cutaneous sensory nerve at ankle and foot level, unspecified leg, sequela',
  'S9431XA Injury of cutaneous sensory nerve at ankle and foot level, right leg, initial encounter',
  'S9431XD Injury of cutaneous sensory nerve at ankle and foot level, right leg, subsequent encounter',
  'S9431XS Injury of cutaneous sensory nerve at ankle and foot level, right leg, sequela',
  'S9432XA Injury of cutaneous sensory nerve at ankle and foot level, left leg, initial encounter',
  'S9432XD Injury of cutaneous sensory nerve at ankle and foot level, left leg, subsequent encounter',
  'S9432XS Injury of cutaneous sensory nerve at ankle and foot level, left leg, sequela',
  'S948X1A Injury of other nerves at ankle and foot level, right leg, initial encounter',
  'S948X1D Injury of other nerves at ankle and foot level, right leg, subsequent encounter',
  'S948X1S Injury of other nerves at ankle and foot level, right leg, sequela',
  'S948X2A Injury of other nerves at ankle and foot level, left leg, initial encounter',
  'S948X2D Injury of other nerves at ankle and foot level, left leg, subsequent encounter',
  'S948X2S Injury of other nerves at ankle and foot level, left leg, sequela',
  'S948X9A Injury of other nerves at ankle and foot level, unspecified leg, initial encounter',
  'S948X9D Injury of other nerves at ankle and foot level, unspecified leg, subsequent encounter',
  'S948X9S Injury of other nerves at ankle and foot level, unspecified leg, sequela',
  'S9490XA Injury of unspecified nerve at ankle and foot level, unspecified leg, initial encounter',
  'S9490XD Injury of unspecified nerve at ankle and foot level, unspecified leg, subsequent encounter',
  'S9490XS Injury of unspecified nerve at ankle and foot level, unspecified leg, sequela',
  'S9491XA Injury of unspecified nerve at ankle and foot level, right leg, initial encounter',
  'S9491XD Injury of unspecified nerve at ankle and foot level, right leg, subsequent encounter',
  'S9491XS Injury of unspecified nerve at ankle and foot level, right leg, sequela',
  'S9492XA Injury of unspecified nerve at ankle and foot level, left leg, initial encounter',
  'S9492XD Injury of unspecified nerve at ankle and foot level, left leg, subsequent encounter',
  'S9492XS Injury of unspecified nerve at ankle and foot level, left leg, sequela',
  'S95001A Unspecified injury of dorsal artery of right foot, initial encounter',
  'S95001D Unspecified injury of dorsal artery of right foot, subsequent encounter',
  'S95001S Unspecified injury of dorsal artery of right foot, sequela',
  'S95002A Unspecified injury of dorsal artery of left foot, initial encounter',
  'S95002D Unspecified injury of dorsal artery of left foot, subsequent encounter',
  'S95002S Unspecified injury of dorsal artery of left foot, sequela',
  'S95009A Unspecified injury of dorsal artery of unspecified foot, initial encounter',
  'S95009D Unspecified injury of dorsal artery of unspecified foot, subsequent encounter',
  'S95009S Unspecified injury of dorsal artery of unspecified foot, sequela',
  'S95011A Laceration of dorsal artery of right foot, initial encounter',
  'S95011D Laceration of dorsal artery of right foot, subsequent encounter',
  'S95011S Laceration of dorsal artery of right foot, sequela',
  'S95012A Laceration of dorsal artery of left foot, initial encounter',
  'S95012D Laceration of dorsal artery of left foot, subsequent encounter',
  'S95012S Laceration of dorsal artery of left foot, sequela',
  'S95019A Laceration of dorsal artery of unspecified foot, initial encounter',
  'S95019D Laceration of dorsal artery of unspecified foot, subsequent encounter',
  'S95019S Laceration of dorsal artery of unspecified foot, sequela',
  'S95091A Other specified injury of dorsal artery of right foot, initial encounter',
  'S95091D Other specified injury of dorsal artery of right foot, subsequent encounter',
  'S95091S Other specified injury of dorsal artery of right foot, sequela',
  'S95092A Other specified injury of dorsal artery of left foot, initial encounter',
  'S95092D Other specified injury of dorsal artery of left foot, subsequent encounter',
  'S95092S Other specified injury of dorsal artery of left foot, sequela',
  'S95099A Other specified injury of dorsal artery of unspecified foot, initial encounter',
  'S95099D Other specified injury of dorsal artery of unspecified foot, subsequent encounter',
  'S95099S Other specified injury of dorsal artery of unspecified foot, sequela',
  'S95101A Unspecified injury of plantar artery of right foot, initial encounter',
  'S95101D Unspecified injury of plantar artery of right foot, subsequent encounter',
  'S95101S Unspecified injury of plantar artery of right foot, sequela',
  'S95102A Unspecified injury of plantar artery of left foot, initial encounter',
  'S95102D Unspecified injury of plantar artery of left foot, subsequent encounter',
  'S95102S Unspecified injury of plantar artery of left foot, sequela',
  'S95109A Unspecified injury of plantar artery of unspecified foot, initial encounter',
  'S95109D Unspecified injury of plantar artery of unspecified foot, subsequent encounter',
  'S95109S Unspecified injury of plantar artery of unspecified foot, sequela',
  'S95111A Laceration of plantar artery of right foot, initial encounter',
  'S95111D Laceration of plantar artery of right foot, subsequent encounter',
  'S95111S Laceration of plantar artery of right foot, sequela',
  'S95112A Laceration of plantar artery of left foot, initial encounter',
  'S95112D Laceration of plantar artery of left foot, subsequent encounter',
  'S95112S Laceration of plantar artery of left foot, sequela',
  'S95119A Laceration of plantar artery of unspecified foot, initial encounter',
  'S95119D Laceration of plantar artery of unspecified foot, subsequent encounter',
  'S95119S Laceration of plantar artery of unspecified foot, sequela',
  'S95191A Other specified injury of plantar artery of right foot, initial encounter',
  'S95191D Other specified injury of plantar artery of right foot, subsequent encounter',
  'S95191S Other specified injury of plantar artery of right foot, sequela',
  'S95192A Other specified injury of plantar artery of left foot, initial encounter',
  'S95192D Other specified injury of plantar artery of left foot, subsequent encounter',
  'S95192S Other specified injury of plantar artery of left foot, sequela',
  'S95199A Other specified injury of plantar artery of unspecified foot, initial encounter',
  'S95199D Other specified injury of plantar artery of unspecified foot, subsequent encounter',
  'S95199S Other specified injury of plantar artery of unspecified foot, sequela',
  'S95201A Unspecified injury of dorsal vein of right foot, initial encounter',
  'S95201D Unspecified injury of dorsal vein of right foot, subsequent encounter',
  'S95201S Unspecified injury of dorsal vein of right foot, sequela',
  'S95202A Unspecified injury of dorsal vein of left foot, initial encounter',
  'S95202D Unspecified injury of dorsal vein of left foot, subsequent encounter',
  'S95202S Unspecified injury of dorsal vein of left foot, sequela',
  'S95209A Unspecified injury of dorsal vein of unspecified foot, initial encounter',
  'S95209D Unspecified injury of dorsal vein of unspecified foot, subsequent encounter',
  'S95209S Unspecified injury of dorsal vein of unspecified foot, sequela',
  'S95211A Laceration of dorsal vein of right foot, initial encounter',
  'S95211D Laceration of dorsal vein of right foot, subsequent encounter',
  'S95211S Laceration of dorsal vein of right foot, sequela',
  'S95212A Laceration of dorsal vein of left foot, initial encounter',
  'S95212D Laceration of dorsal vein of left foot, subsequent encounter',
  'S95212S Laceration of dorsal vein of left foot, sequela',
  'S95219A Laceration of dorsal vein of unspecified foot, initial encounter',
  'S95219D Laceration of dorsal vein of unspecified foot, subsequent encounter',
  'S95219S Laceration of dorsal vein of unspecified foot, sequela',
  'S95291A Other specified injury of dorsal vein of right foot, initial encounter',
  'S95291D Other specified injury of dorsal vein of right foot, subsequent encounter',
  'S95291S Other specified injury of dorsal vein of right foot, sequela',
  'S95292A Other specified injury of dorsal vein of left foot, initial encounter',
  'S95292D Other specified injury of dorsal vein of left foot, subsequent encounter',
  'S95292S Other specified injury of dorsal vein of left foot, sequela',
  'S95299A Other specified injury of dorsal vein of unspecified foot, initial encounter',
  'S95299D Other specified injury of dorsal vein of unspecified foot, subsequent encounter',
  'S95299S Other specified injury of dorsal vein of unspecified foot, sequela',
  'S95801A Unspecified injury of other blood vessels at ankle and foot level, right leg, initial encounter',
  'S95801D Unspecified injury of other blood vessels at ankle and foot level, right leg, subsequent',
  'encounter',
  'S95801S Unspecified injury of other blood vessels at ankle and foot level, right leg, sequela',
  'S95802A Unspecified injury of other blood vessels at ankle and foot level, left leg, initial encounter',
  'S95802D Unspecified injury of other blood vessels at ankle and foot level, left leg, subsequent encounter',
  'S95802S Unspecified injury of other blood vessels at ankle and foot level, left leg, sequela',
  'S95809A Unspecified injury of other blood vessels at ankle and foot level, unspecified leg, initial',
  'encounter',
  'S95809D Unspecified injury of other blood vessels at ankle and foot level, unspecified leg, subsequent',
  'encounter',
  'S95809S Unspecified injury of other blood vessels at ankle and foot level, unspecified leg, sequela',
  'S95811A Laceration of other blood vessels at ankle and foot level, right leg, initial encounter',
  'S95811D Laceration of other blood vessels at ankle and foot level, right leg, subsequent encounter',
  'S95811S Laceration of other blood vessels at ankle and foot level, right leg, sequela',
  'S95812A Laceration of other blood vessels at ankle and foot level, left leg, initial encounter',
  'S95812D Laceration of other blood vessels at ankle and foot level, left leg, subsequent encounter',
  'S95812S Laceration of other blood vessels at ankle and foot level, left leg, sequela',
  'S95819A Laceration of other blood vessels at ankle and foot level, unspecified leg, initial encounter',
  'S95819D Laceration of other blood vessels at ankle and foot level, unspecified leg, subsequent',
  'encounter',
  'S95819S Laceration of other blood vessels at ankle and foot level, unspecified leg, sequela',
  'S95891A Other specified injury of other blood vessels at ankle and foot level, right leg, initial encounter',
  'S95891D Other specified injury of other blood vessels at ankle and foot level, right leg, subsequent',
  'encounter',
  'S95891S Other specified injury of other blood vessels at ankle and foot level, right leg, sequela',
  'S95892A Other specified injury of other blood vessels at ankle and foot level, left leg, initial encounter',
  'S95892D Other specified injury of other blood vessels at ankle and foot level, left leg, subsequent',
  'encounter',
  'S95892S Other specified injury of other blood vessels at ankle and foot level, left leg, sequela',
  'S95899A Other specified injury of other blood vessels at ankle and foot level, unspecified leg, initial',
  'encounter',
  'S95899D Other specified injury of other blood vessels at ankle and foot level, unspecified leg,',
  'subsequent encounter',
  'S95899S Other specified injury of other blood vessels at ankle and foot level, unspecified leg, sequela',
  'S95901A Unspecified injury of unspecified blood vessel at ankle and foot level, right leg, initial',
  'encounter',
  'S95901D Unspecified injury of unspecified blood vessel at ankle and foot level, right leg, subsequent',
  'encounter',
  'S95901S Unspecified injury of unspecified blood vessel at ankle and foot level, right leg, sequela',
  'S95902A Unspecified injury of unspecified blood vessel at ankle and foot level, left leg, initial encounter',
  'S95902D Unspecified injury of unspecified blood vessel at ankle and foot level, left leg, subsequent',
  'encounter',
  'S95902S Unspecified injury of unspecified blood vessel at ankle and foot level, left leg, sequela',
  'S95909A Unspecified injury of unspecified blood vessel at ankle and foot level, unspecified leg, initial',
  'encounter',
  'S95909D Unspecified injury of unspecified blood vessel at ankle and foot level, unspecified leg,',
  'subsequent encounter',
  'S95909S Unspecified injury of unspecified blood vessel at ankle and foot level, unspecified leg, sequela',
  'S95911A Laceration of unspecified blood vessel at ankle and foot level, right leg, initial encounter',
  'S95911D Laceration of unspecified blood vessel at ankle and foot level, right leg, subsequent encounter',
  'S95911S Laceration of unspecified blood vessel at ankle and foot level, right leg, sequela',
  'S95912A Laceration of unspecified blood vessel at ankle and foot level, left leg, initial encounter',
  'S95912D Laceration of unspecified blood vessel at ankle and foot level, left leg, subsequent encounter',
  'S95912S Laceration of unspecified blood vessel at ankle and foot level, left leg, sequela',
  'S95919A Laceration of unspecified blood vessel at ankle and foot level, unspecified leg, initial encounter',
  'S95919D Laceration of unspecified blood vessel at ankle and foot level, unspecified leg, subsequent',
  'encounter',
  'S95919S Laceration of unspecified blood vessel at ankle and foot level, unspecified leg, sequela',
  'S95991A Other specified injury of unspecified blood vessel at ankle and foot level, right leg, initial',
  'encounter',
  'S95991D Other specified injury of unspecified blood vessel at ankle and foot level, right leg, subsequent',
  'encounter',
  'S95991S Other specified injury of unspecified blood vessel at ankle and foot level, right leg, sequela',
  'S95992A Other specified injury of unspecified blood vessel at ankle and foot level, left leg, initial',
  'encounter',
  'S95992D Other specified injury of unspecified blood vessel at ankle and foot level, left leg, subsequent',
  'encounter',
  'S95992S Other specified injury of unspecified blood vessel at ankle and foot level, left leg, sequela',
  'S95999A Other specified injury of unspecified blood vessel at ankle and foot level, unspecified leg, initial',
  'encounter',
  'S95999D Other specified injury of unspecified blood vessel at ankle and foot level, unspecified leg,',
  'subsequent encounter',
  'S95999S Other specified injury of unspecified blood vessel at ankle and foot level, unspecified leg,',
  'sequela',
  'S96001A Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'right foot, initial encounter',
  'S96001D Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'right foot, subsequent encounter',
  'S96001S Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'right foot, sequela',
  'S96002A Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'left foot, initial encounter',
  'S96002D Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'left foot, subsequent encounter',
  'S96002S Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level, left',
  'foot, sequela',
  'S96009A Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, initial encounter',
  'S96009D Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, subsequent encounter',
  'S96009S Unspecified injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, sequela',
  'S96011A Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, right foot,',
  'initial encounter',
  'S96011D Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96011S Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, right foot,',
  'sequela',
  'S96012A Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot, initial',
  'encounter',
  'S96012D Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96012S Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'sequela',
  'S96019A Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, unspecified',
  'foot, initial encounter',
  'S96019D Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, unspecified',
  'foot, subsequent encounter',
  'S96019S Strain of muscle and tendon of long flexor muscle of toe at ankle and foot level, unspecified',
  'foot, sequela',
  'S96021A Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level, right foot,',
  'initial encounter',
  'S96021D Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96021S Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level, right foot,',
  'sequela',
  'S96022A Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'initial encounter',
  'S96022D Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96022S Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'sequela',
  'S96029A Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, initial encounter',
  'S96029D Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, subsequent encounter',
  'S96029S Laceration of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, sequela',
  'S96091A Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level, right',
  'foot, initial encounter',
  'S96091D Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level, right',
  'foot, subsequent encounter',
  'S96091S Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level, right',
  'foot, sequela',
  'S96092A Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'initial encounter',
  'S96092D Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96092S Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level, left foot,',
  'sequela',
  'S96099A Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, initial encounter',
  'S96099D Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, subsequent encounter',
  'S96099S Other injury of muscle and tendon of long flexor muscle of toe at ankle and foot level,',
  'unspecified foot, sequela',
  'S96101A Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'right foot, initial encounter',
  'S96101D Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'right foot, subsequent encounter',
  'S96101S Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'right foot, sequela',
  'S96102A Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'left foot, initial encounter',
  'S96102D Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'left foot, subsequent encounter',
  'S96102S Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'left foot, sequela',
  'S96109A Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'unspecified foot, initial encounter',
  'S96109D Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'unspecified foot, subsequent encounter',
  'S96109S Unspecified injury of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'unspecified foot, sequela',
  'S96111A Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, right foot,',
  'initial encounter',
  'S96111D Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96111S Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, right foot,',
  'sequela',
  'S96112A Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, left foot,',
  'initial encounter',
  'S96112D Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96112S Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, left foot,',
  'sequela',
  'S96119A Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, unspecified',
  'foot, initial encounter',
  'S96119D Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, unspecified',
  'foot, subsequent encounter',
  'S96119S Strain of muscle and tendon of long extensor muscle of toe at ankle and foot level, unspecified',
  'foot, sequela',
  'S96121A Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level, right',
  'foot, initial encounter',
  'S96121D Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level, right',
  'foot, subsequent encounter',
  'S96121S Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level, right',
  'foot, sequela',
  'S96122A Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level, left',
  'foot, initial encounter',
  'S96122D Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level, left',
  'foot, subsequent encounter',
  'S96122S Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level, left',
  'foot, sequela',
  'S96129A Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'unspecified foot, initial encounter',
  'S96129D Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'unspecified foot, subsequent encounter',
  'S96129S Laceration of muscle and tendon of long extensor muscle of toe at ankle and foot level,',
  'unspecified foot, sequela',
  'S96191A Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, right foot, initial encounter',
  'S96191D Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, right foot, subsequent encounter',
  'S96191S Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, right foot, sequela',
  'S96192A Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, left foot, initial encounter',
  'S96192D Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, left foot, subsequent encounter',
  'S96192S Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, left foot, sequela',
  'S96199A Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, unspecified foot, initial encounter',
  'S96199D Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, unspecified foot, subsequent encounter',
  'S96199S Other specified injury of muscle and tendon of long extensor muscle of toe at ankle and foot',
  'level, unspecified foot, sequela',
  'S96201A Unspecified injury of intrinsic muscle and tendon at ankle and foot level, right foot, initial',
  'encounter',
  'S96201D Unspecified injury of intrinsic muscle and tendon at ankle and foot level, right foot, subsequent',
  'encounter',
  'S96201S Unspecified injury of intrinsic muscle and tendon at ankle and foot level, right foot, sequela',
  'S96202A Unspecified injury of intrinsic muscle and tendon at ankle and foot level, left foot, initial',
  'encounter',
  'S96202D Unspecified injury of intrinsic muscle and tendon at ankle and foot level, left foot, subsequent',
  'encounter',
  'S96202S Unspecified injury of intrinsic muscle and tendon at ankle and foot level, left foot, sequela',
  'S96209A Unspecified injury of intrinsic muscle and tendon at ankle and foot level, unspecified foot,',
  'initial encounter',
  'S96209D Unspecified injury of intrinsic muscle and tendon at ankle and foot level, unspecified foot,',
  'subsequent encounter',
  'S96209S Unspecified injury of intrinsic muscle and tendon at ankle and foot level, unspecified foot,',
  'sequela',
  'S96211A Strain of intrinsic muscle and tendon at ankle and foot level, right foot, initial encounter',
  'S96211D Strain of intrinsic muscle and tendon at ankle and foot level, right foot, subsequent encounter',
  'S96211S Strain of intrinsic muscle and tendon at ankle and foot level, right foot, sequela',
  'S96212A Strain of intrinsic muscle and tendon at ankle and foot level, left foot, initial encounter',
  'S96212D Strain of intrinsic muscle and tendon at ankle and foot level, left foot, subsequent encounter',
  'S96212S Strain of intrinsic muscle and tendon at ankle and foot level, left foot, sequela',
  'S96219A Strain of intrinsic muscle and tendon at ankle and foot level, unspecified foot, initial encounter',
  'S96219D Strain of intrinsic muscle and tendon at ankle and foot level, unspecified foot, subsequent',
  'encounter',
  'S96219S Strain of intrinsic muscle and tendon at ankle and foot level, unspecified foot, sequela',
  'S96221A Laceration of intrinsic muscle and tendon at ankle and foot level, right foot, initial encounter',
  'S96221D Laceration of intrinsic muscle and tendon at ankle and foot level, right foot, subsequent',
  'encounter',
  'S96221S Laceration of intrinsic muscle and tendon at ankle and foot level, right foot, sequela',
  'S96222A Laceration of intrinsic muscle and tendon at ankle and foot level, left foot, initial encounter',
  'S96222D Laceration of intrinsic muscle and tendon at ankle and foot level, left foot, subsequent',
  'encounter',
  'S96222S Laceration of intrinsic muscle and tendon at ankle and foot level, left foot, sequela',
  'S96229A Laceration of intrinsic muscle and tendon at ankle and foot level, unspecified foot, initial',
  'encounter',
  'S96229D Laceration of intrinsic muscle and tendon at ankle and foot level, unspecified foot, subsequent',
  'encounter',
  'S96229S Laceration of intrinsic muscle and tendon at ankle and foot level, unspecified foot, sequela',
  'S96291A Other specified injury of intrinsic muscle and tendon at ankle and foot level, right foot, initial',
  'encounter',
  'S96291D Other specified injury of intrinsic muscle and tendon at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96291S Other specified injury of intrinsic muscle and tendon at ankle and foot level, right foot, sequela',
  'S96292A Other specified injury of intrinsic muscle and tendon at ankle and foot level, left foot, initial',
  'encounter',
  'S96292D Other specified injury of intrinsic muscle and tendon at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96292S Other specified injury of intrinsic muscle and tendon at ankle and foot level, left foot, sequela',
  'S96299A Other specified injury of intrinsic muscle and tendon at ankle and foot level, unspecified foot,',
  'initial encounter',
  'S96299D Other specified injury of intrinsic muscle and tendon at ankle and foot level, unspecified foot,',
  'subsequent encounter',
  'S96299S Other specified injury of intrinsic muscle and tendon at ankle and foot level, unspecified foot,',
  'sequela',
  'S96801A Unspecified injury of other specified muscles and tendons at ankle and foot level, right foot,',
  'initial encounter',
  'S96801D Unspecified injury of other specified muscles and tendons at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96801S Unspecified injury of other specified muscles and tendons at ankle and foot level, right foot,',
  'sequela',
  'S96802A Unspecified injury of other specified muscles and tendons at ankle and foot level, left foot,',
  'initial encounter',
  'S96802D Unspecified injury of other specified muscles and tendons at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96802S Unspecified injury of other specified muscles and tendons at ankle and foot level, left foot,',
  'sequela',
  'S96809A Unspecified injury of other specified muscles and tendons at ankle and foot level, unspecified',
  'foot, initial encounter',
  'S96809D Unspecified injury of other specified muscles and tendons at ankle and foot level, unspecified',
  'foot, subsequent encounter',
  'S96809S Unspecified injury of other specified muscles and tendons at ankle and foot level, unspecified',
  'foot, sequela',
  'S96811A Strain of other specified muscles and tendons at ankle and foot level, right foot, initial',
  'encounter',
  'S96811D Strain of other specified muscles and tendons at ankle and foot level, right foot, subsequent',
  'encounter',
  'S96811S Strain of other specified muscles and tendons at ankle and foot level, right foot, sequela',
  'S96812A Strain of other specified muscles and tendons at ankle and foot level, left foot, initial encounter',
  'S96812D Strain of other specified muscles and tendons at ankle and foot level, left foot, subsequent',
  'encounter',
  'S96812S Strain of other specified muscles and tendons at ankle and foot level, left foot, sequela',
  'S96819A Strain of other specified muscles and tendons at ankle and foot level, unspecified foot, initial',
  'encounter',
  'S96819D Strain of other specified muscles and tendons at ankle and foot level, unspecified foot,',
  'subsequent encounter',
  'S96819S Strain of other specified muscles and tendons at ankle and foot level, unspecified foot, sequela',
  'S96821A Laceration of other specified muscles and tendons at ankle and foot level, right foot, initial',
  'encounter',
  'S96821D Laceration of other specified muscles and tendons at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96821S Laceration of other specified muscles and tendons at ankle and foot level, right foot, sequela',
  'S96822A Laceration of other specified muscles and tendons at ankle and foot level, left foot, initial',
  'encounter',
  'S96822D Laceration of other specified muscles and tendons at ankle and foot level, left foot, subsequent',
  'encounter',
  'S96822S Laceration of other specified muscles and tendons at ankle and foot level, left foot, sequela',
  'S96829A Laceration of other specified muscles and tendons at ankle and foot level, unspecified foot,',
  'initial encounter',
  'S96829D Laceration of other specified muscles and tendons at ankle and foot level, unspecified foot,',
  'subsequent encounter',
  'S96829S Laceration of other specified muscles and tendons at ankle and foot level, unspecified foot,',
  'sequela',
  'S96891A Other specified injury of other specified muscles and tendons at ankle and foot level, right foot,',
  'initial encounter',
  'S96891D Other specified injury of other specified muscles and tendons at ankle and foot level, right',
  'foot, subsequent encounter',
  'S96891S Other specified injury of other specified muscles and tendons at ankle and foot level, right foot,',
  'sequela',
  'S96892A Other specified injury of other specified muscles and tendons at ankle and foot level, left foot,',
  'initial encounter',
  'S96892D Other specified injury of other specified muscles and tendons at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96892S Other specified injury of other specified muscles and tendons at ankle and foot level, left foot,',
  'sequela',
  'S96899A Other specified injury of other specified muscles and tendons at ankle and foot level,',
  'unspecified foot, initial encounter',
  'S96899D Other specified injury of other specified muscles and tendons at ankle and foot level,',
  'unspecified foot, subsequent encounter',
  'S96899S Other specified injury of other specified muscles and tendons at ankle and foot level,',
  'unspecified foot, sequela',
  'S96901A Unspecified injury of unspecified muscle and tendon at ankle and foot level, right foot, initial',
  'encounter',
  'S96901D Unspecified injury of unspecified muscle and tendon at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96901S Unspecified injury of unspecified muscle and tendon at ankle and foot level, right foot, sequela',
  'S96902A Unspecified injury of unspecified muscle and tendon at ankle and foot level, left foot, initial',
  'encounter',
  'S96902D Unspecified injury of unspecified muscle and tendon at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96902S Unspecified injury of unspecified muscle and tendon at ankle and foot level, left foot, sequela',
  'S96909A Unspecified injury of unspecified muscle and tendon at ankle and foot level, unspecified foot,',
  'initial encounter',
  'S96909D Unspecified injury of unspecified muscle and tendon at ankle and foot level, unspecified foot,',
  'subsequent encounter',
  'S96909S Unspecified injury of unspecified muscle and tendon at ankle and foot level, unspecified foot,',
  'sequela',
  'S96911A Strain of unspecified muscle and tendon at ankle and foot level, right foot, initial encounter',
  'S96911D Strain of unspecified muscle and tendon at ankle and foot level, right foot, subsequent',
  'encounter',
  'S96911S Strain of unspecified muscle and tendon at ankle and foot level, right foot, sequela',
  'S96912A Strain of unspecified muscle and tendon at ankle and foot level, left foot, initial encounter',
  'S96912D Strain of unspecified muscle and tendon at ankle and foot level, left foot, subsequent',
  'encounter',
  'S96912S Strain of unspecified muscle and tendon at ankle and foot level, left foot, sequela',
  'S96919A Strain of unspecified muscle and tendon at ankle and foot level, unspecified foot, initial',
  'encounter',
  'S96919D Strain of unspecified muscle and tendon at ankle and foot level, unspecified foot, subsequent',
  'encounter',
  'S96919S Strain of unspecified muscle and tendon at ankle and foot level, unspecified foot, sequela',
  'S96921A Laceration of unspecified muscle and tendon at ankle and foot level, right foot, initial',
  'encounter',
  'S96921D Laceration of unspecified muscle and tendon at ankle and foot level, right foot, subsequent',
  'encounter',
  'S96921S Laceration of unspecified muscle and tendon at ankle and foot level, right foot, sequela',
  'S96922A Laceration of unspecified muscle and tendon at ankle and foot level, left foot, initial encounter',
  'S96922D Laceration of unspecified muscle and tendon at ankle and foot level, left foot, subsequent',
  'encounter',
  'S96922S Laceration of unspecified muscle and tendon at ankle and foot level, left foot, sequela',
  'S96929A Laceration of unspecified muscle and tendon at ankle and foot level, unspecified foot, initial',
  'encounter',
  'S96929D Laceration of unspecified muscle and tendon at ankle and foot level, unspecified foot,',
  'subsequent encounter',
  'S96929S Laceration of unspecified muscle and tendon at ankle and foot level, unspecified foot, sequela',
  'S96991A Other specified injury of unspecified muscle and tendon at ankle and foot level, right foot,',
  'initial encounter',
  'S96991D Other specified injury of unspecified muscle and tendon at ankle and foot level, right foot,',
  'subsequent encounter',
  'S96991S Other specified injury of unspecified muscle and tendon at ankle and foot level, right foot,',
  'sequela',
  'S96992A Other specified injury of unspecified muscle and tendon at ankle and foot level, left foot, initial',
  'encounter',
  'S96992D Other specified injury of unspecified muscle and tendon at ankle and foot level, left foot,',
  'subsequent encounter',
  'S96992S Other specified injury of unspecified muscle and tendon at ankle and foot level, left foot,',
  'sequela',
  'S96999A Other specified injury of unspecified muscle and tendon at ankle and foot level, unspecified',
  'foot, initial encounter',
  'S96999D Other specified injury of unspecified muscle and tendon at ankle and foot level, unspecified',
  'foot, subsequent encounter',
  'S96999S Other specified injury of unspecified muscle and tendon at ankle and foot level, unspecified',
  'foot, sequela',
  'S9700XA Crushing injury of unspecified ankle, initial encounter',
  'S9700XD Crushing injury of unspecified ankle, subsequent encounter',
  'S9700XS Crushing injury of unspecified ankle, sequela',
  'S9701XA Crushing injury of right ankle, initial encounter',
  'S9701XD Crushing injury of right ankle, subsequent encounter',
  'S9701XS Crushing injury of right ankle, sequela',
  'S9702XA Crushing injury of left ankle, initial encounter',
  'S9702XD Crushing injury of left ankle, subsequent encounter',
  'S9702XS Crushing injury of left ankle, sequela',
  'S97101A Crushing injury of unspecified right toe(s), initial encounter',
  'S97101D Crushing injury of unspecified right toe(s), subsequent encounter',
  'S97101S Crushing injury of unspecified right toe(s), sequela',
  'S97102A Crushing injury of unspecified left toe(s), initial encounter',
  'S97102D Crushing injury of unspecified left toe(s), subsequent encounter',
  'S97102S Crushing injury of unspecified left toe(s), sequela',
  'S97109A Crushing injury of unspecified toe(s), initial encounter',
  'S97109D Crushing injury of unspecified toe(s), subsequent encounter',
  'S97109S Crushing injury of unspecified toe(s), sequela',
  'S97111A Crushing injury of right great toe, initial encounter',
  'S97111D Crushing injury of right great toe, subsequent encounter',
  'S97111S Crushing injury of right great toe, sequela',
  'S97112A Crushing injury of left great toe, initial encounter',
  'S97112D Crushing injury of left great toe, subsequent encounter',
  'S97112S Crushing injury of left great toe, sequela',
  'S97119A Crushing injury of unspecified great toe, initial encounter',
  'S97119D Crushing injury of unspecified great toe, subsequent encounter',
  'S97119S Crushing injury of unspecified great toe, sequela',
  'S97121A Crushing injury of right lesser toe(s), initial encounter',
  'S97121D Crushing injury of right lesser toe(s), subsequent encounter',
  'S97121S Crushing injury of right lesser toe(s), sequela',
  'S97122A Crushing injury of left lesser toe(s), initial encounter',
  'S97122D Crushing injury of left lesser toe(s), subsequent encounter',
  'S97122S Crushing injury of left lesser toe(s), sequela',
  'S97129A Crushing injury of unspecified lesser toe(s), initial encounter',
  'S97129D Crushing injury of unspecified lesser toe(s), subsequent encounter',
  'S97129S Crushing injury of unspecified lesser toe(s), sequela',
  'S9780XA Crushing injury of unspecified foot, initial encounter',
  'S9780XD Crushing injury of unspecified foot, subsequent encounter',
  'S9780XS Crushing injury of unspecified foot, sequela',
  'S9781XA Crushing injury of right foot, initial encounter',
  'S9781XD Crushing injury of right foot, subsequent encounter',
  'S9781XS Crushing injury of right foot, sequela',
  'S9782XA Crushing injury of left foot, initial encounter',
  'S9782XD Crushing injury of left foot, subsequent encounter',
  'S9782XS Crushing injury of left foot, sequela',
  'S98011A Complete traumatic amputation of right foot at ankle level, initial encounter',
  'S98011D Complete traumatic amputation of right foot at ankle level, subsequent encounter',
  'S98011S Complete traumatic amputation of right foot at ankle level, sequela',
  'S98012A Complete traumatic amputation of left foot at ankle level, initial encounter',
  'S98012D Complete traumatic amputation of left foot at ankle level, subsequent encounter',
  'S98012S Complete traumatic amputation of left foot at ankle level, sequela',
  'S98019A Complete traumatic amputation of unspecified foot at ankle level, initial encounter',
  'S98019D Complete traumatic amputation of unspecified foot at ankle level, subsequent encounter',
  'S98019S Complete traumatic amputation of unspecified foot at ankle level, sequela',
  'S98021A Partial traumatic amputation of right foot at ankle level, initial encounter',
  'S98021D Partial traumatic amputation of right foot at ankle level, subsequent encounter',
  'S98021S Partial traumatic amputation of right foot at ankle level, sequela',
  'S98022A Partial traumatic amputation of left foot at ankle level, initial encounter',
  'S98022D Partial traumatic amputation of left foot at ankle level, subsequent encounter',
  'S98022S Partial traumatic amputation of left foot at ankle level, sequela',
  'S98029A Partial traumatic amputation of unspecified foot at ankle level, initial encounter',
  'S98029D Partial traumatic amputation of unspecified foot at ankle level, subsequent encounter',
  'S98029S Partial traumatic amputation of unspecified foot at ankle level, sequela',
  'S98111A Complete traumatic amputation of right great toe, initial encounter',
  'S98111D Complete traumatic amputation of right great toe, subsequent encounter',
  'S98111S Complete traumatic amputation of right great toe, sequela',
  'S98112A Complete traumatic amputation of left great toe, initial encounter',
  'S98112D Complete traumatic amputation of left great toe, subsequent encounter',
  'S98112S Complete traumatic amputation of left great toe, sequela',
  'S98119A Complete traumatic amputation of unspecified great toe, initial encounter',
  'S98119D Complete traumatic amputation of unspecified great toe, subsequent encounter',
  'S98119S Complete traumatic amputation of unspecified great toe, sequela',
  'S98121A Partial traumatic amputation of right great toe, initial encounter',
  'S98121D Partial traumatic amputation of right great toe, subsequent encounter',
  'S98121S Partial traumatic amputation of right great toe, sequela',
  'S98122A Partial traumatic amputation of left great toe, initial encounter',
  'S98122D Partial traumatic amputation of left great toe, subsequent encounter',
  'S98122S Partial traumatic amputation of left great toe, sequela',
  'S98129A Partial traumatic amputation of unspecified great toe, initial encounter',
  'S98129D Partial traumatic amputation of unspecified great toe, subsequent encounter',
  'S98129S Partial traumatic amputation of unspecified great toe, sequela',
  'S98131A Complete traumatic amputation of one right lesser toe, initial encounter',
  'S98131D Complete traumatic amputation of one right lesser toe, subsequent encounter',
  'S98131S Complete traumatic amputation of one right lesser toe, sequela',
  'S98132A Complete traumatic amputation of one left lesser toe, initial encounter',
  'S98132D Complete traumatic amputation of one left lesser toe, subsequent encounter',
  'S98132S Complete traumatic amputation of one left lesser toe, sequela',
  'S98139A Complete traumatic amputation of one unspecified lesser toe, initial encounter',
  'S98139D Complete traumatic amputation of one unspecified lesser toe, subsequent encounter',
  'S98139S Complete traumatic amputation of one unspecified lesser toe, sequela',
  'S98141A Partial traumatic amputation of one right lesser toe, initial encounter',
  'S98141D Partial traumatic amputation of one right lesser toe, subsequent encounter',
  'S98141S Partial traumatic amputation of one right lesser toe, sequela',
  'S98142A Partial traumatic amputation of one left lesser toe, initial encounter',
  'S98142D Partial traumatic amputation of one left lesser toe, subsequent encounter',
  'S98142S Partial traumatic amputation of one left lesser toe, sequela',
  'S98149A Partial traumatic amputation of one unspecified lesser toe, initial encounter',
  'S98149D Partial traumatic amputation of one unspecified lesser toe, subsequent encounter',
  'S98149S Partial traumatic amputation of one unspecified lesser toe, sequela',
  'S98211A Complete traumatic amputation of two or more right lesser toes, initial encounter',
  'S98211D Complete traumatic amputation of two or more right lesser toes, subsequent encounter',
  'S98211S Complete traumatic amputation of two or more right lesser toes, sequela',
  'S98212A Complete traumatic amputation of two or more left lesser toes, initial encounter',
  'S98212D Complete traumatic amputation of two or more left lesser toes, subsequent encounter',
  'S98212S Complete traumatic amputation of two or more left lesser toes, sequela',
  'S98219A Complete traumatic amputation of two or more unspecified lesser toes, initial encounter',
  'S98219D Complete traumatic amputation of two or more unspecified lesser toes, subsequent encounter',
  'S98219S Complete traumatic amputation of two or more unspecified lesser toes, sequela',
  'S98221A Partial traumatic amputation of two or more right lesser toes, initial encounter',
  'S98221D Partial traumatic amputation of two or more right lesser toes, subsequent encounter',
  'S98221S Partial traumatic amputation of two or more right lesser toes, sequela',
  'S98222A Partial traumatic amputation of two or more left lesser toes, initial encounter',
  'S98222D Partial traumatic amputation of two or more left lesser toes, subsequent encounter',
  'S98222S Partial traumatic amputation of two or more left lesser toes, sequela',
  'S98229A Partial traumatic amputation of two or more unspecified lesser toes, initial encounter',
  'S98229D Partial traumatic amputation of two or more unspecified lesser toes, subsequent encounter',
  'S98229S Partial traumatic amputation of two or more unspecified lesser toes, sequela',
  'S98311A Complete traumatic amputation of right midfoot, initial encounter',
  'S98311D Complete traumatic amputation of right midfoot, subsequent encounter',
  'S98311S Complete traumatic amputation of right midfoot, sequela',
  'S98312A Complete traumatic amputation of left midfoot, initial encounter',
  'S98312D Complete traumatic amputation of left midfoot, subsequent encounter',
  'S98312S Complete traumatic amputation of left midfoot, sequela',
  'S98319A Complete traumatic amputation of unspecified midfoot, initial encounter',
  'S98319D Complete traumatic amputation of unspecified midfoot, subsequent encounter',
  'S98319S Complete traumatic amputation of unspecified midfoot, sequela',
  'S98321A Partial traumatic amputation of right midfoot, initial encounter',
  'S98321D Partial traumatic amputation of right midfoot, subsequent encounter',
  'S98321S Partial traumatic amputation of right midfoot, sequela',
  'S98322A Partial traumatic amputation of left midfoot, initial encounter',
  'S98322D Partial traumatic amputation of left midfoot, subsequent encounter',
  'S98322S Partial traumatic amputation of left midfoot, sequela',
  'S98329A Partial traumatic amputation of unspecified midfoot, initial encounter',
  'S98329D Partial traumatic amputation of unspecified midfoot, subsequent encounter',
  'S98329S Partial traumatic amputation of unspecified midfoot, sequela',
  'S98911A Complete traumatic amputation of right foot, level unspecified, initial encounter',
  'S98911D Complete traumatic amputation of right foot, level unspecified, subsequent encounter',
  'S98911S Complete traumatic amputation of right foot, level unspecified, sequela',
  'S98912A Complete traumatic amputation of left foot, level unspecified, initial encounter',
  'S98912D Complete traumatic amputation of left foot, level unspecified, subsequent encounter',
  'S98912S Complete traumatic amputation of left foot, level unspecified, sequela',
  'S98919A Complete traumatic amputation of unspecified foot, level unspecified, initial encounter',
  'S98919D Complete traumatic amputation of unspecified foot, level unspecified, subsequent encounter',
  'S98919S Complete traumatic amputation of unspecified foot, level unspecified, sequela',
  'S98921A Partial traumatic amputation of right foot, level unspecified, initial encounter',
  'S98921D Partial traumatic amputation of right foot, level unspecified, subsequent encounter',
  'S98921S Partial traumatic amputation of right foot, level unspecified, sequela',
  'S98922A Partial traumatic amputation of left foot, level unspecified, initial encounter',
  'S98922D Partial traumatic amputation of left foot, level unspecified, subsequent encounter',
  'S98922S Partial traumatic amputation of left foot, level unspecified, sequela',
  'S98929A Partial traumatic amputation of unspecified foot, level unspecified, initial encounter',
  'S98929D Partial traumatic amputation of unspecified foot, level unspecified, subsequent encounter',
  'S98929S Partial traumatic amputation of unspecified foot, level unspecified, sequela',
  'S99001A Unspecified physeal fracture of right calcaneus, initial encounter for closed fracture',
  'S99001B Unspecified physeal fracture of right calcaneus, initial encounter for open fracture',
  'S99001D Unspecified physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99001G Unspecified physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99001K Unspecified physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99001P Unspecified physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99001S Unspecified physeal fracture of right calcaneus, sequela',
  'S99002A Unspecified physeal fracture of left calcaneus, initial encounter for closed fracture',
  'S99002B Unspecified physeal fracture of left calcaneus, initial encounter for open fracture',
  'S99002D Unspecified physeal fracture of left calcaneus, subsequent encounter for fracture with routine',
  'healing',
  'S99002G Unspecified physeal fracture of left calcaneus, subsequent encounter for fracture with delayed',
  'healing',
  'S99002K Unspecified physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99002P Unspecified physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99002S Unspecified physeal fracture of left calcaneus, sequela',
  'S99009A Unspecified physeal fracture of unspecified calcaneus, initial encounter for closed fracture',
  'S99009B Unspecified physeal fracture of unspecified calcaneus, initial encounter for open fracture',
  'S99009D Unspecified physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99009G Unspecified physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99009K Unspecified physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99009P Unspecified physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99009S Unspecified physeal fracture of unspecified calcaneus, sequela',
  'S99011A Salter-Harris Type I physeal fracture of right calcaneus, initial encounter for closed fracture',
  'S99011B Salter-Harris Type I physeal fracture of right calcaneus, initial encounter for open fracture',
  'S99011D Salter-Harris Type I physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99011G Salter-Harris Type I physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99011K Salter-Harris Type I physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99011P Salter-Harris Type I physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99011S Salter-Harris Type I physeal fracture of right calcaneus, sequela',
  'S99012A Salter-Harris Type I physeal fracture of left calcaneus, initial encounter for closed fracture',
  'S99012B Salter-Harris Type I physeal fracture of left calcaneus, initial encounter for open fracture',
  'S99012D Salter-Harris Type I physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99012G Salter-Harris Type I physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99012K Salter-Harris Type I physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99012P Salter-Harris Type I physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99012S Salter-Harris Type I physeal fracture of left calcaneus, sequela',
  'S99019A Salter-Harris Type I physeal fracture of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S99019B Salter-Harris Type I physeal fracture of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S99019D Salter-Harris Type I physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S99019G Salter-Harris Type I physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S99019K Salter-Harris Type I physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S99019P Salter-Harris Type I physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S99019S Salter-Harris Type I physeal fracture of unspecified calcaneus, sequela',
  'S99021A Salter-Harris Type II physeal fracture of right calcaneus, initial encounter for closed fracture',
  'S99021B Salter-Harris Type II physeal fracture of right calcaneus, initial encounter for open fracture',
  'S99021D Salter-Harris Type II physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S99021G Salter-Harris Type II physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S99021K Salter-Harris Type II physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99021P Salter-Harris Type II physeal fracture of right calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99021S Salter-Harris Type II physeal fracture of right calcaneus, sequela',
  'S99022A Salter-Harris Type II physeal fracture of left calcaneus, initial encounter for closed fracture',
  'S99022B Salter-Harris Type II physeal fracture of left calcaneus, initial encounter for open fracture',
  'S99022D Salter-Harris Type II physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99022G Salter-Harris Type II physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99022K Salter-Harris Type II physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99022P Salter-Harris Type II physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99022S Salter-Harris Type II physeal fracture of left calcaneus, sequela',
  'S99029A Salter-Harris Type II physeal fracture of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S99029B Salter-Harris Type II physeal fracture of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S99029D Salter-Harris Type II physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S99029G Salter-Harris Type II physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S99029K Salter-Harris Type II physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S99029P Salter-Harris Type II physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S99029S Salter-Harris Type II physeal fracture of unspecified calcaneus, sequela',
  'S99031A Salter-Harris Type III physeal fracture of right calcaneus, initial encounter for closed fracture',
  'S99031B Salter-Harris Type III physeal fracture of right calcaneus, initial encounter for open fracture',
  'S99031D Salter-Harris Type III physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S99031G Salter-Harris Type III physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S99031K Salter-Harris Type III physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S99031P Salter-Harris Type III physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S99031S Salter-Harris Type III physeal fracture of right calcaneus, sequela',
  'S99032A Salter-Harris Type III physeal fracture of left calcaneus, initial encounter for closed fracture',
  'S99032B Salter-Harris Type III physeal fracture of left calcaneus, initial encounter for open fracture',
  'S99032D Salter-Harris Type III physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99032G Salter-Harris Type III physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99032K Salter-Harris Type III physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99032P Salter-Harris Type III physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99032S Salter-Harris Type III physeal fracture of left calcaneus, sequela',
  'S99039A Salter-Harris Type III physeal fracture of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S99039B Salter-Harris Type III physeal fracture of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S99039D Salter-Harris Type III physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S99039G Salter-Harris Type III physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S99039K Salter-Harris Type III physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S99039P Salter-Harris Type III physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S99039S Salter-Harris Type III physeal fracture of unspecified calcaneus, sequela',
  'S99041A Salter-Harris Type IV physeal fracture of right calcaneus, initial encounter for closed fracture',
  'S99041B Salter-Harris Type IV physeal fracture of right calcaneus, initial encounter for open fracture',
  'S99041D Salter-Harris Type IV physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with routine healing',
  'S99041G Salter-Harris Type IV physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with delayed healing',
  'S99041K Salter-Harris Type IV physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with nonunion',
  'S99041P Salter-Harris Type IV physeal fracture of right calcaneus, subsequent encounter for fracture',
  'with malunion',
  'S99041S Salter-Harris Type IV physeal fracture of right calcaneus, sequela',
  'S99042A Salter-Harris Type IV physeal fracture of left calcaneus, initial encounter for closed fracture',
  'S99042B Salter-Harris Type IV physeal fracture of left calcaneus, initial encounter for open fracture',
  'S99042D Salter-Harris Type IV physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99042G Salter-Harris Type IV physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99042K Salter-Harris Type IV physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99042P Salter-Harris Type IV physeal fracture of left calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99042S Salter-Harris Type IV physeal fracture of left calcaneus, sequela',
  'S99049A Salter-Harris Type IV physeal fracture of unspecified calcaneus, initial encounter for closed',
  'fracture',
  'S99049B Salter-Harris Type IV physeal fracture of unspecified calcaneus, initial encounter for open',
  'fracture',
  'S99049D Salter-Harris Type IV physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with routine healing',
  'S99049G Salter-Harris Type IV physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with delayed healing',
  'S99049K Salter-Harris Type IV physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with nonunion',
  'S99049P Salter-Harris Type IV physeal fracture of unspecified calcaneus, subsequent encounter for',
  'fracture with malunion',
  'S99049S Salter-Harris Type IV physeal fracture of unspecified calcaneus, sequela',
  'S99091A Other physeal fracture of right calcaneus, initial encounter for closed fracture',
  'S99091B Other physeal fracture of right calcaneus, initial encounter for open fracture',
  'S99091D Other physeal fracture of right calcaneus, subsequent encounter for fracture with routine',
  'healing',
  'S99091G Other physeal fracture of right calcaneus, subsequent encounter for fracture with delayed',
  'healing',
  'S99091K Other physeal fracture of right calcaneus, subsequent encounter for fracture with nonunion',
  'S99091P Other physeal fracture of right calcaneus, subsequent encounter for fracture with malunion',
  'S99091S Other physeal fracture of right calcaneus, sequela',
  'S99092A Other physeal fracture of left calcaneus, initial encounter for closed fracture',
  'S99092B Other physeal fracture of left calcaneus, initial encounter for open fracture',
  'S99092D Other physeal fracture of left calcaneus, subsequent encounter for fracture with routine',
  'healing',
  'S99092G Other physeal fracture of left calcaneus, subsequent encounter for fracture with delayed',
  'healing',
  'S99092K Other physeal fracture of left calcaneus, subsequent encounter for fracture with nonunion',
  'S99092P Other physeal fracture of left calcaneus, subsequent encounter for fracture with malunion',
  'S99092S Other physeal fracture of left calcaneus, sequela',
  'S99099A Other physeal fracture of unspecified calcaneus, initial encounter for closed fracture',
  'S99099B Other physeal fracture of unspecified calcaneus, initial encounter for open fracture',
  'S99099D Other physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'routine healing',
  'S99099G Other physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'delayed healing',
  'S99099K Other physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'nonunion',
  'S99099P Other physeal fracture of unspecified calcaneus, subsequent encounter for fracture with',
  'malunion',
  'S99099S Other physeal fracture of unspecified calcaneus, sequela',
  'S99101A Unspecified physeal fracture of right metatarsal, initial encounter for closed fracture',
  'S99101B Unspecified physeal fracture of right metatarsal, initial encounter for open fracture',
  'S99101D Unspecified physeal fracture of right metatarsal, subsequent encounter for fracture with',
  'routine healing',
  'S99101G Unspecified physeal fracture of right metatarsal, subsequent encounter for fracture with',
  'delayed healing',
  'S99101K Unspecified physeal fracture of right metatarsal, subsequent encounter for fracture with',
  'nonunion',
  'S99101P Unspecified physeal fracture of right metatarsal, subsequent encounter for fracture with',
  'malunion',
  'S99101S Unspecified physeal fracture of right metatarsal, sequela',
  'S99102A Unspecified physeal fracture of left metatarsal, initial encounter for closed fracture',
  'S99102B Unspecified physeal fracture of left metatarsal, initial encounter for open fracture',
  'S99102D Unspecified physeal fracture of left metatarsal, subsequent encounter for fracture with routine',
  'healing',
  'S99102G Unspecified physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'delayed healing',
  'S99102K Unspecified physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'nonunion',
  'S99102P Unspecified physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'malunion',
  'S99102S Unspecified physeal fracture of left metatarsal, sequela',
  'S99109A Unspecified physeal fracture of unspecified metatarsal, initial encounter for closed fracture',
  'S99109B Unspecified physeal fracture of unspecified metatarsal, initial encounter for open fracture',
  'S99109D Unspecified physeal fracture of unspecified metatarsal, subsequent encounter for fracture',
  'with routine healing',
  'S99109G Unspecified physeal fracture of unspecified metatarsal, subsequent encounter for fracture',
  'with delayed healing',
  'S99109K Unspecified physeal fracture of unspecified metatarsal, subsequent encounter for fracture with',
  'nonunion',
  'S99109P Unspecified physeal fracture of unspecified metatarsal, subsequent encounter for fracture with',
  'malunion',
  'S99109S Unspecified physeal fracture of unspecified metatarsal, sequela',
  'S99111A Salter-Harris Type I physeal fracture of right metatarsal, initial encounter for closed fracture',
  'S99111B Salter-Harris Type I physeal fracture of right metatarsal, initial encounter for open fracture',
  'S99111D Salter-Harris Type I physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with routine healing',
  'S99111G Salter-Harris Type I physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with delayed healing',
  'S99111K Salter-Harris Type I physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with nonunion',
  'S99111P Salter-Harris Type I physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with malunion',
  'S99111S Salter-Harris Type I physeal fracture of right metatarsal, sequela',
  'S99112A Salter-Harris Type I physeal fracture of left metatarsal, initial encounter for closed fracture',
  'S99112B Salter-Harris Type I physeal fracture of left metatarsal, initial encounter for open fracture',
  'S99112D Salter-Harris Type I physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'routine healing',
  'S99112G Salter-Harris Type I physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'delayed healing',
  'S99112K Salter-Harris Type I physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'nonunion',
  'S99112P Salter-Harris Type I physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'malunion',
  'S99112S Salter-Harris Type I physeal fracture of left metatarsal, sequela',
  'S99119A Salter-Harris Type I physeal fracture of unspecified metatarsal, initial encounter for closed',
  'fracture',
  'S99119B Salter-Harris Type I physeal fracture of unspecified metatarsal, initial encounter for open',
  'fracture',
  'S99119D Salter-Harris Type I physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with routine healing',
  'S99119G Salter-Harris Type I physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with delayed healing',
  'S99119K Salter-Harris Type I physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with nonunion',
  'S99119P Salter-Harris Type I physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with malunion',
  'S99119S Salter-Harris Type I physeal fracture of unspecified metatarsal, sequela',
  'S99121A Salter-Harris Type II physeal fracture of right metatarsal, initial encounter for closed fracture',
  'S99121B Salter-Harris Type II physeal fracture of right metatarsal, initial encounter for open fracture',
  'S99121D Salter-Harris Type II physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with routine healing',
  'S99121G Salter-Harris Type II physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with delayed healing',
  'S99121K Salter-Harris Type II physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with nonunion',
  'S99121P Salter-Harris Type II physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with malunion',
  'S99121S Salter-Harris Type II physeal fracture of right metatarsal, sequela',
  'S99122A Salter-Harris Type II physeal fracture of left metatarsal, initial encounter for closed fracture',
  'S99122B Salter-Harris Type II physeal fracture of left metatarsal, initial encounter for open fracture',
  'S99122D Salter-Harris Type II physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'routine healing',
  'S99122G Salter-Harris Type II physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'delayed healing',
  'S99122K Salter-Harris Type II physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'nonunion',
  'S99122P Salter-Harris Type II physeal fracture of left metatarsal, subsequent encounter for fracture with',
  'malunion',
  'S99122S Salter-Harris Type II physeal fracture of left metatarsal, sequela',
  'S99129A Salter-Harris Type II physeal fracture of unspecified metatarsal, initial encounter for closed',
  'fracture',
  'S99129B Salter-Harris Type II physeal fracture of unspecified metatarsal, initial encounter for open',
  'fracture',
  'S99129D Salter-Harris Type II physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with routine healing',
  'S99129G Salter-Harris Type II physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with delayed healing',
  'S99129K Salter-Harris Type II physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with nonunion',
  'S99129P Salter-Harris Type II physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with malunion',
  'S99129S Salter-Harris Type II physeal fracture of unspecified metatarsal, sequela',
  'S99131A Salter-Harris Type III physeal fracture of right metatarsal, initial encounter for closed fracture',
  'S99131B Salter-Harris Type III physeal fracture of right metatarsal, initial encounter for open fracture',
  'S99131D Salter-Harris Type III physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with routine healing',
  'S99131G Salter-Harris Type III physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with delayed healing',
  'S99131K Salter-Harris Type III physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with nonunion',
  'S99131P Salter-Harris Type III physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with malunion',
  'S99131S Salter-Harris Type III physeal fracture of right metatarsal, sequela',
  'S99132A Salter-Harris Type III physeal fracture of left metatarsal, initial encounter for closed fracture',
  'S99132B Salter-Harris Type III physeal fracture of left metatarsal, initial encounter for open fracture',
  'S99132D Salter-Harris Type III physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with routine healing',
  'S99132G Salter-Harris Type III physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with delayed healing',
  'S99132K Salter-Harris Type III physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with nonunion',
  'S99132P Salter-Harris Type III physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with malunion',
  'S99132S Salter-Harris Type III physeal fracture of left metatarsal, sequela',
  'S99139A Salter-Harris Type III physeal fracture of unspecified metatarsal, initial encounter for closed',
  'fracture',
  'S99139B Salter-Harris Type III physeal fracture of unspecified metatarsal, initial encounter for open',
  'fracture',
  'S99139D Salter-Harris Type III physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with routine healing',
  'S99139G Salter-Harris Type III physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with delayed healing',
  'S99139K Salter-Harris Type III physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with nonunion',
  'S99139P Salter-Harris Type III physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with malunion',
  'S99139S Salter-Harris Type III physeal fracture of unspecified metatarsal, sequela',
  'S99141A Salter-Harris Type IV physeal fracture of right metatarsal, initial encounter for closed fracture',
  'S99141B Salter-Harris Type IV physeal fracture of right metatarsal, initial encounter for open fracture',
  'S99141D Salter-Harris Type IV physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with routine healing',
  'S99141G Salter-Harris Type IV physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with delayed healing',
  'S99141K Salter-Harris Type IV physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with nonunion',
  'S99141P Salter-Harris Type IV physeal fracture of right metatarsal, subsequent encounter for fracture',
  'with malunion',
  'S99141S Salter-Harris Type IV physeal fracture of right metatarsal, sequela',
  'S99142A Salter-Harris Type IV physeal fracture of left metatarsal, initial encounter for closed fracture',
  'S99142B Salter-Harris Type IV physeal fracture of left metatarsal, initial encounter for open fracture',
  'S99142D Salter-Harris Type IV physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with routine healing',
  'S99142G Salter-Harris Type IV physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with delayed healing',
  'S99142K Salter-Harris Type IV physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with nonunion',
  'S99142P Salter-Harris Type IV physeal fracture of left metatarsal, subsequent encounter for fracture',
  'with malunion',
  'S99142S Salter-Harris Type IV physeal fracture of left metatarsal, sequela',
  'S99149A Salter-Harris Type IV physeal fracture of unspecified metatarsal, initial encounter for closed',
  'fracture',
  'S99149B Salter-Harris Type IV physeal fracture of unspecified metatarsal, initial encounter for open',
  'fracture',
  'S99149D Salter-Harris Type IV physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with routine healing',
  'S99149G Salter-Harris Type IV physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with delayed healing',
  'S99149K Salter-Harris Type IV physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with nonunion',
  'S99149P Salter-Harris Type IV physeal fracture of unspecified metatarsal, subsequent encounter for',
  'fracture with malunion',
  'S99149S Salter-Harris Type IV physeal fracture of unspecified metatarsal, sequela',
  'S99191A Other physeal fracture of right metatarsal, initial encounter for closed fracture',
  'S99191B Other physeal fracture of right metatarsal, initial encounter for open fracture',
  'S99191D Other physeal fracture of right metatarsal, subsequent encounter for fracture with routine',
  'healing',
  'S99191G Other physeal fracture of right metatarsal, subsequent encounter for fracture with delayed',
  'healing',
  'S99191K Other physeal fracture of right metatarsal, subsequent encounter for fracture with nonunion',
  'S99191P Other physeal fracture of right metatarsal, subsequent encounter for fracture with malunion',
  'S99191S Other physeal fracture of right metatarsal, sequela',
  'S99192A Other physeal fracture of left metatarsal, initial encounter for closed fracture',
  'S99192B Other physeal fracture of left metatarsal, initial encounter for open fracture',
  'S99192D Other physeal fracture of left metatarsal, subsequent encounter for fracture with routine',
  'healing',
  'S99192G Other physeal fracture of left metatarsal, subsequent encounter for fracture with delayed',
  'healing',
  'S99192K Other physeal fracture of left metatarsal, subsequent encounter for fracture with nonunion',
  'S99192P Other physeal fracture of left metatarsal, subsequent encounter for fracture with malunion',
  'S99192S Other physeal fracture of left metatarsal, sequela',
  'S99199A Other physeal fracture of unspecified metatarsal, initial encounter for closed fracture',
  'S99199B Other physeal fracture of unspecified metatarsal, initial encounter for open fracture',
  'S99199D Other physeal fracture of unspecified metatarsal, subsequent encounter for fracture with',
  'routine healing',
  'S99199G Other physeal fracture of unspecified metatarsal, subsequent encounter for fracture with',
  'delayed healing',
  'S99199K Other physeal fracture of unspecified metatarsal, subsequent encounter for fracture with',
  'nonunion',
  'S99199P Other physeal fracture of unspecified metatarsal, subsequent encounter for fracture with',
  'malunion',
  'S99199S Other physeal fracture of unspecified metatarsal, sequela',
  'S99201A Unspecified physeal fracture of phalanx of right toe, initial encounter for closed fracture',
  'S99201B Unspecified physeal fracture of phalanx of right toe, initial encounter for open fracture',
  'S99201D Unspecified physeal fracture of phalanx of right toe, subsequent encounter for fracture with',
  'routine healing',
  'S99201G Unspecified physeal fracture of phalanx of right toe, subsequent encounter for fracture with',
  'delayed healing',
  'S99201K Unspecified physeal fracture of phalanx of right toe, subsequent encounter for fracture with',
  'nonunion',
  'S99201P Unspecified physeal fracture of phalanx of right toe, subsequent encounter for fracture with',
  'malunion',
  'S99201S Unspecified physeal fracture of phalanx of right toe, sequela',
  'S99202A Unspecified physeal fracture of phalanx of left toe, initial encounter for closed fracture',
  'S99202B Unspecified physeal fracture of phalanx of left toe, initial encounter for open fracture',
  'S99202D Unspecified physeal fracture of phalanx of left toe, subsequent encounter for fracture with',
  'routine healing',
  'S99202G Unspecified physeal fracture of phalanx of left toe, subsequent encounter for fracture with',
  'delayed healing',
  'S99202K Unspecified physeal fracture of phalanx of left toe, subsequent encounter for fracture with',
  'nonunion',
  'S99202P Unspecified physeal fracture of phalanx of left toe, subsequent encounter for fracture with',
  'malunion',
  'S99202S Unspecified physeal fracture of phalanx of left toe, sequela',
  'S99209A Unspecified physeal fracture of phalanx of unspecified toe, initial encounter for closed fracture',
  'S99209B Unspecified physeal fracture of phalanx of unspecified toe, initial encounter for open fracture',
  'S99209D Unspecified physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture',
  'with routine healing',
  'S99209G Unspecified physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture',
  'with delayed healing',
  'S99209K Unspecified physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture',
  'with nonunion',
  'S99209P Unspecified physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture',
  'with malunion',
  'S99209S Unspecified physeal fracture of phalanx of unspecified toe, sequela',
  'S99211A Salter-Harris Type I physeal fracture of phalanx of right toe, initial encounter for closed fracture',
  'S99211B Salter-Harris Type I physeal fracture of phalanx of right toe, initial encounter for open fracture',
  'S99211D Salter-Harris Type I physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with routine healing',
  'S99211G Salter-Harris Type I physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with delayed healing',
  'S99211K Salter-Harris Type I physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with nonunion',
  'S99211P Salter-Harris Type I physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with malunion',
  'S99211S Salter-Harris Type I physeal fracture of phalanx of right toe, sequela',
  'S99212A Salter-Harris Type I physeal fracture of phalanx of left toe, initial encounter for closed fracture',
  'S99212B Salter-Harris Type I physeal fracture of phalanx of left toe, initial encounter for open fracture',
  'S99212D Salter-Harris Type I physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with routine healing',
  'S99212G Salter-Harris Type I physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with delayed healing',
  'S99212K Salter-Harris Type I physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with nonunion',
  'S99212P Salter-Harris Type I physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with malunion',
  'S99212S Salter-Harris Type I physeal fracture of phalanx of left toe, sequela',
  'S99219A Salter-Harris Type I physeal fracture of phalanx of unspecified toe, initial encounter for closed',
  'fracture',
  'S99219B Salter-Harris Type I physeal fracture of phalanx of unspecified toe, initial encounter for open',
  'fracture',
  'S99219D Salter-Harris Type I physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with routine healing',
  'S99219G Salter-Harris Type I physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with delayed healing',
  'S99219K Salter-Harris Type I physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with nonunion',
  'S99219P Salter-Harris Type I physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with malunion',
  'S99219S Salter-Harris Type I physeal fracture of phalanx of unspecified toe, sequela',
  'S99221A Salter-Harris Type II physeal fracture of phalanx of right toe, initial encounter for closed',
  'fracture',
  'S99221B Salter-Harris Type II physeal fracture of phalanx of right toe, initial encounter for open fracture',
  'S99221D Salter-Harris Type II physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with routine healing',
  'S99221G Salter-Harris Type II physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with delayed healing',
  'S99221K Salter-Harris Type II physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with nonunion',
  'S99221P Salter-Harris Type II physeal fracture of phalanx of right toe, subsequent encounter for fracture',
  'with malunion',
  'S99221S Salter-Harris Type II physeal fracture of phalanx of right toe, sequela',
  'S99222A Salter-Harris Type II physeal fracture of phalanx of left toe, initial encounter for closed fracture',
  'S99222B Salter-Harris Type II physeal fracture of phalanx of left toe, initial encounter for open fracture',
  'S99222D Salter-Harris Type II physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with routine healing',
  'S99222G Salter-Harris Type II physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with delayed healing',
  'S99222K Salter-Harris Type II physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with nonunion',
  'S99222P Salter-Harris Type II physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with malunion',
  'S99222S Salter-Harris Type II physeal fracture of phalanx of left toe, sequela',
  'S99229A Salter-Harris Type II physeal fracture of phalanx of unspecified toe, initial encounter for closed',
  'fracture',
  'S99229B Salter-Harris Type II physeal fracture of phalanx of unspecified toe, initial encounter for open',
  'fracture',
  'S99229D Salter-Harris Type II physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with routine healing',
  'S99229G Salter-Harris Type II physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with delayed healing',
  'S99229K Salter-Harris Type II physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with nonunion',
  'S99229P Salter-Harris Type II physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with malunion',
  'S99229S Salter-Harris Type II physeal fracture of phalanx of unspecified toe, sequela',
  'S99231A Salter-Harris Type III physeal fracture of phalanx of right toe, initial encounter for closed',
  'fracture',
  'S99231B Salter-Harris Type III physeal fracture of phalanx of right toe, initial encounter for open fracture',
  'S99231D Salter-Harris Type III physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with routine healing',
  'S99231G Salter-Harris Type III physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with delayed healing',
  'S99231K Salter-Harris Type III physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with nonunion',
  'S99231P Salter-Harris Type III physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with malunion',
  'S99231S Salter-Harris Type III physeal fracture of phalanx of right toe, sequela',
  'S99232A Salter-Harris Type III physeal fracture of phalanx of left toe, initial encounter for closed fracture',
  'S99232B Salter-Harris Type III physeal fracture of phalanx of left toe, initial encounter for open fracture',
  'S99232D Salter-Harris Type III physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with routine healing',
  'S99232G Salter-Harris Type III physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with delayed healing',
  'S99232K Salter-Harris Type III physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with nonunion',
  'S99232P Salter-Harris Type III physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with malunion',
  'S99232S Salter-Harris Type III physeal fracture of phalanx of left toe, sequela',
  'S99239A Salter-Harris Type III physeal fracture of phalanx of unspecified toe, initial encounter for closed',
  'fracture',
  'S99239B Salter-Harris Type III physeal fracture of phalanx of unspecified toe, initial encounter for open',
  'fracture',
  'S99239D Salter-Harris Type III physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with routine healing',
  'S99239G Salter-Harris Type III physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with delayed healing',
  'S99239K Salter-Harris Type III physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with nonunion',
  'S99239P Salter-Harris Type III physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with malunion',
  'S99239S Salter-Harris Type III physeal fracture of phalanx of unspecified toe, sequela',
  'S99241A Salter-Harris Type IV physeal fracture of phalanx of right toe, initial encounter for closed',
  'fracture',
  'S99241B Salter-Harris Type IV physeal fracture of phalanx of right toe, initial encounter for open fracture',
  'S99241D Salter-Harris Type IV physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with routine healing',
  'S99241G Salter-Harris Type IV physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with delayed healing',
  'S99241K Salter-Harris Type IV physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with nonunion',
  'S99241P Salter-Harris Type IV physeal fracture of phalanx of right toe, subsequent encounter for',
  'fracture with malunion',
  'S99241S Salter-Harris Type IV physeal fracture of phalanx of right toe, sequela',
  'S99242A Salter-Harris Type IV physeal fracture of phalanx of left toe, initial encounter for closed fracture',
  'S99242B Salter-Harris Type IV physeal fracture of phalanx of left toe, initial encounter for open fracture',
  'S99242D Salter-Harris Type IV physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with routine healing',
  'S99242G Salter-Harris Type IV physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with delayed healing',
  'S99242K Salter-Harris Type IV physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with nonunion',
  'S99242P Salter-Harris Type IV physeal fracture of phalanx of left toe, subsequent encounter for fracture',
  'with malunion',
  'S99242S Salter-Harris Type IV physeal fracture of phalanx of left toe, sequela',
  'S99249A Salter-Harris Type IV physeal fracture of phalanx of unspecified toe, initial encounter for closed',
  'fracture',
  'S99249B Salter-Harris Type IV physeal fracture of phalanx of unspecified toe, initial encounter for open',
  'fracture',
  'S99249D Salter-Harris Type IV physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with routine healing',
  'S99249G Salter-Harris Type IV physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with delayed healing',
  'S99249K Salter-Harris Type IV physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with nonunion',
  'S99249P Salter-Harris Type IV physeal fracture of phalanx of unspecified toe, subsequent encounter for',
  'fracture with malunion',
  'S99249S Salter-Harris Type IV physeal fracture of phalanx of unspecified toe, sequela',
  'S99291A Other physeal fracture of phalanx of right toe, initial encounter for closed fracture',
  'S99291B Other physeal fracture of phalanx of right toe, initial encounter for open fracture',
  'S99291D Other physeal fracture of phalanx of right toe, subsequent encounter for fracture with routine',
  'healing',
  'S99291G Other physeal fracture of phalanx of right toe, subsequent encounter for fracture with delayed',
  'healing',
  'S99291K Other physeal fracture of phalanx of right toe, subsequent encounter for fracture with',
  'nonunion',
  'S99291P Other physeal fracture of phalanx of right toe, subsequent encounter for fracture with',
  'malunion',
  'S99291S Other physeal fracture of phalanx of right toe, sequela',
  'S99292A Other physeal fracture of phalanx of left toe, initial encounter for closed fracture',
  'S99292B Other physeal fracture of phalanx of left toe, initial encounter for open fracture',
  'S99292D Other physeal fracture of phalanx of left toe, subsequent encounter for fracture with routine',
  'healing',
  'S99292G Other physeal fracture of phalanx of left toe, subsequent encounter for fracture with delayed',
  'healing',
  'S99292K Other physeal fracture of phalanx of left toe, subsequent encounter for fracture with nonunion',
  'S99292P Other physeal fracture of phalanx of left toe, subsequent encounter for fracture with malunion',
  'S99292S Other physeal fracture of phalanx of left toe, sequela',
  'S99299A Other physeal fracture of phalanx of unspecified toe, initial encounter for closed fracture',
  'S99299B Other physeal fracture of phalanx of unspecified toe, initial encounter for open fracture',
  'S99299D Other physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture with',
  'routine healing',
  'S99299G Other physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture with',
  'delayed healing',
  'S99299K Other physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture with',
  'nonunion',
  'S99299P Other physeal fracture of phalanx of unspecified toe, subsequent encounter for fracture with',
  'malunion',
  'S99299S Other physeal fracture of phalanx of unspecified toe, sequela',
  'S99811A Other specified injuries of right ankle, initial encounter',
  'S99811D Other specified injuries of right ankle, subsequent encounter',
  'S99811S Other specified injuries of right ankle, sequela',
  'S99812A Other specified injuries of left ankle, initial encounter',
  'S99812D Other specified injuries of left ankle, subsequent encounter',
  'S99812S Other specified injuries of left ankle, sequela',
  'S99819A Other specified injuries of unspecified ankle, initial encounter',
  'S99819D Other specified injuries of unspecified ankle, subsequent encounter',
  'S99819S Other specified injuries of unspecified ankle, sequela',
  'S99821A Other specified injuries of right foot, initial encounter',
  'S99821D Other specified injuries of right foot, subsequent encounter',
  'S99821S Other specified injuries of right foot, sequela',
  'S99822A Other specified injuries of left foot, initial encounter',
  'S99822D Other specified injuries of left foot, subsequent encounter',
  'S99822S Other specified injuries of left foot, sequela',
  'S99829A Other specified injuries of unspecified foot, initial encounter',
  'S99829D Other specified injuries of unspecified foot, subsequent encounter',
  'S99829S Other specified injuries of unspecified foot, sequela',
  'S99911A Unspecified injury of right ankle, initial encounter',
  'S99911D Unspecified injury of right ankle, subsequent encounter',
  'S99911S Unspecified injury of right ankle, sequela',
  'S99912A Unspecified injury of left ankle, initial encounter',
  'S99912D Unspecified injury of left ankle, subsequent encounter',
  'S99912S Unspecified injury of left ankle, sequela',
  'S99919A Unspecified injury of unspecified ankle, initial encounter',
  'S99919D Unspecified injury of unspecified ankle, subsequent encounter',
  'S99919S Unspecified injury of unspecified ankle, sequela',
  'S99921A Unspecified injury of right foot, initial encounter',
  'S99921D Unspecified injury of right foot, subsequent encounter',
  'S99921S Unspecified injury of right foot, sequela',
  'S99922A Unspecified injury of left foot, initial encounter',
  'S99922D Unspecified injury of left foot, subsequent encounter',
  'S99922S Unspecified injury of left foot, sequela',
  'S99929A Unspecified injury of unspecified foot, initial encounter',
  'S99929D Unspecified injury of unspecified foot, subsequent encounter',
  'S99929S Unspecified injury of unspecified foot, sequela',
  'T07 Unspecified multiple injuries',
  'T148 Other injury of unspecified body region',
  'T1490 Injury, unspecified',
  'T1491 Suicide attempt',
  'T1500XA Foreign body in cornea, unspecified eye, initial encounter',
  'T1500XD Foreign body in cornea, unspecified eye, subsequent encounter',
  'T1500XS Foreign body in cornea, unspecified eye, sequela',
  'T1501XA Foreign body in cornea, right eye, initial encounter',
  'T1501XD Foreign body in cornea, right eye, subsequent encounter',
  'T1501XS Foreign body in cornea, right eye, sequela',
  'T1502XA Foreign body in cornea, left eye, initial encounter',
  'T1502XD Foreign body in cornea, left eye, subsequent encounter',
  'T1502XS Foreign body in cornea, left eye, sequela',
  'T1510XA Foreign body in conjunctival sac, unspecified eye, initial encounter',
  'T1510XD Foreign body in conjunctival sac, unspecified eye, subsequent encounter',
  'T1510XS Foreign body in conjunctival sac, unspecified eye, sequela',
  'T1511XA Foreign body in conjunctival sac, right eye, initial encounter',
  'T1511XD Foreign body in conjunctival sac, right eye, subsequent encounter',
  'T1511XS Foreign body in conjunctival sac, right eye, sequela',
  'T1512XA Foreign body in conjunctival sac, left eye, initial encounter',
  'T1512XD Foreign body in conjunctival sac, left eye, subsequent encounter',
  'T1512XS Foreign body in conjunctival sac, left eye, sequela',
  'T1580XA Foreign body in other and multiple parts of external eye, unspecified eye, initial encounter',
  'T1580XD Foreign body in other and multiple parts of external eye, unspecified eye, subsequent',
  'encounter',
  'T1580XS Foreign body in other and multiple parts of external eye, unspecified eye, sequela',
  'T1581XA Foreign body in other and multiple parts of external eye, right eye, initial encounter',
  'T1581XD Foreign body in other and multiple parts of external eye, right eye, subsequent encounter',
  'T1581XS Foreign body in other and multiple parts of external eye, right eye, sequela',
  'T1582XA Foreign body in other and multiple parts of external eye, left eye, initial encounter',
  'T1582XD Foreign body in other and multiple parts of external eye, left eye, subsequent encounter',
  'T1582XS Foreign body in other and multiple parts of external eye, left eye, sequela',
  'T1590XA Foreign body on external eye, part unspecified, unspecified eye, initial encounter',
  'T1590XD Foreign body on external eye, part unspecified, unspecified eye, subsequent encounter',
  'T1590XS Foreign body on external eye, part unspecified, unspecified eye, sequela',
  'T1591XA Foreign body on external eye, part unspecified, right eye, initial encounter',
  'T1591XD Foreign body on external eye, part unspecified, right eye, subsequent encounter',
  'T1591XS Foreign body on external eye, part unspecified, right eye, sequela',
  'T1592XA Foreign body on external eye, part unspecified, left eye, initial encounter',
  'T1592XD Foreign body on external eye, part unspecified, left eye, subsequent encounter',
  'T1592XS Foreign body on external eye, part unspecified, left eye, sequela',
  'T161XXA Foreign body in right ear, initial encounter',
  'T161XXD Foreign body in right ear, subsequent encounter',
  'T161XXS Foreign body in right ear, sequela',
  'T162XXA Foreign body in left ear, initial encounter',
  'T162XXD Foreign body in left ear, subsequent encounter',
  'T162XXS Foreign body in left ear, sequela',
  'T169XXA Foreign body in ear, unspecified ear, initial encounter',
  'T169XXD Foreign body in ear, unspecified ear, subsequent encounter',
  'T169XXS Foreign body in ear, unspecified ear, sequela',
  'T170XXA Foreign body in nasal sinus, initial encounter',
  'T170XXD Foreign body in nasal sinus, subsequent encounter',
  'T170XXS Foreign body in nasal sinus, sequela',
  'T171XXA Foreign body in nostril, initial encounter',
  'T171XXD Foreign body in nostril, subsequent encounter',
  'T171XXS Foreign body in nostril, sequela',
  'T17200A Unspecified foreign body in pharynx causing asphyxiation, initial encounter',
  'T17200D Unspecified foreign body in pharynx causing asphyxiation, subsequent encounter',
  'T17200S Unspecified foreign body in pharynx causing asphyxiation, sequela',
  'T17208A Unspecified foreign body in pharynx causing other injury, initial encounter',
  'T17208D Unspecified foreign body in pharynx causing other injury, subsequent encounter',
  'T17208S Unspecified foreign body in pharynx causing other injury, sequela',
  'T17210A Gastric contents in pharynx causing asphyxiation, initial encounter',
  'T17210D Gastric contents in pharynx causing asphyxiation, subsequent encounter',
  'T17210S Gastric contents in pharynx causing asphyxiation, sequela',
  'T17218A Gastric contents in pharynx causing other injury, initial encounter',
  'T17218D Gastric contents in pharynx causing other injury, subsequent encounter',
  'T17218S Gastric contents in pharynx causing other injury, sequela',
  'T17220A Food in pharynx causing asphyxiation, initial encounter',
  'T17220D Food in pharynx causing asphyxiation, subsequent encounter',
  'T17220S Food in pharynx causing asphyxiation, sequela',
  'T17228A Food in pharynx causing other injury, initial encounter',
  'T17228D Food in pharynx causing other injury, subsequent encounter',
  'T17228S Food in pharynx causing other injury, sequela',
  'T17290A Other foreign object in pharynx causing asphyxiation, initial encounter',
  'T17290D Other foreign object in pharynx causing asphyxiation, subsequent encounter',
  'T17290S Other foreign object in pharynx causing asphyxiation, sequela',
  'T17298A Other foreign object in pharynx causing other injury, initial encounter',
  'T17298D Other foreign object in pharynx causing other injury, subsequent encounter',
  'T17298S Other foreign object in pharynx causing other injury, sequela',
  'T17300A Unspecified foreign body in larynx causing asphyxiation, initial encounter',
  'T17300D Unspecified foreign body in larynx causing asphyxiation, subsequent encounter',
  'T17300S Unspecified foreign body in larynx causing asphyxiation, sequela',
  'T17308A Unspecified foreign body in larynx causing other injury, initial encounter',
  'T17308D Unspecified foreign body in larynx causing other injury, subsequent encounter',
  'T17308S Unspecified foreign body in larynx causing other injury, sequela',
  'T17310A Gastric contents in larynx causing asphyxiation, initial encounter',
  'T17310D Gastric contents in larynx causing asphyxiation, subsequent encounter',
  'T17310S Gastric contents in larynx causing asphyxiation, sequela',
  'T17318A Gastric contents in larynx causing other injury, initial encounter',
  'T17318D Gastric contents in larynx causing other injury, subsequent encounter',
  'T17318S Gastric contents in larynx causing other injury, sequela',
  'T17320A Food in larynx causing asphyxiation, initial encounter',
  'T17320D Food in larynx causing asphyxiation, subsequent encounter',
  'T17320S Food in larynx causing asphyxiation, sequela',
  'T17328A Food in larynx causing other injury, initial encounter',
  'T17328D Food in larynx causing other injury, subsequent encounter',
  'T17328S Food in larynx causing other injury, sequela',
  'T17390A Other foreign object in larynx causing asphyxiation, initial encounter',
  'T17390D Other foreign object in larynx causing asphyxiation, subsequent encounter',
  'T17390S Other foreign object in larynx causing asphyxiation, sequela',
  'T17398A Other foreign object in larynx causing other injury, initial encounter',
  'T17398D Other foreign object in larynx causing other injury, subsequent encounter',
  'T17398S Other foreign object in larynx causing other injury, sequela',
  'T17400A Unspecified foreign body in trachea causing asphyxiation, initial encounter',
  'T17400D Unspecified foreign body in trachea causing asphyxiation, subsequent encounter',
  'T17400S Unspecified foreign body in trachea causing asphyxiation, sequela',
  'T17408A Unspecified foreign body in trachea causing other injury, initial encounter',
  'T17408D Unspecified foreign body in trachea causing other injury, subsequent encounter',
  'T17408S Unspecified foreign body in trachea causing other injury, sequela',
  'T17410A Gastric contents in trachea causing asphyxiation, initial encounter',
  'T17410D Gastric contents in trachea causing asphyxiation, subsequent encounter',
  'T17410S Gastric contents in trachea causing asphyxiation, sequela',
  'T17418A Gastric contents in trachea causing other injury, initial encounter',
  'T17418D Gastric contents in trachea causing other injury, subsequent encounter',
  'T17418S Gastric contents in trachea causing other injury, sequela',
  'T17420A Food in trachea causing asphyxiation, initial encounter',
  'T17420D Food in trachea causing asphyxiation, subsequent encounter',
  'T17420S Food in trachea causing asphyxiation, sequela',
  'T17428A Food in trachea causing other injury, initial encounter',
  'T17428D Food in trachea causing other injury, subsequent encounter',
  'T17428S Food in trachea causing other injury, sequela',
  'T17490A Other foreign object in trachea causing asphyxiation, initial encounter',
  'T17490D Other foreign object in trachea causing asphyxiation, subsequent encounter',
  'T17490S Other foreign object in trachea causing asphyxiation, sequela',
  'T17498A Other foreign object in trachea causing other injury, initial encounter',
  'T17498D Other foreign object in trachea causing other injury, subsequent encounter',
  'T17498S Other foreign object in trachea causing other injury, sequela',
  'T17500A Unspecified foreign body in bronchus causing asphyxiation, initial encounter',
  'T17500D Unspecified foreign body in bronchus causing asphyxiation, subsequent encounter',
  'T17500S Unspecified foreign body in bronchus causing asphyxiation, sequela',
  'T17508A Unspecified foreign body in bronchus causing other injury, initial encounter',
  'T17508D Unspecified foreign body in bronchus causing other injury, subsequent encounter',
  'T17508S Unspecified foreign body in bronchus causing other injury, sequela',
  'T17510A Gastric contents in bronchus causing asphyxiation, initial encounter',
  'T17510D Gastric contents in bronchus causing asphyxiation, subsequent encounter',
  'T17510S Gastric contents in bronchus causing asphyxiation, sequela',
  'T17518A Gastric contents in bronchus causing other injury, initial encounter',
  'T17518D Gastric contents in bronchus causing other injury, subsequent encounter',
  'T17518S Gastric contents in bronchus causing other injury, sequela',
  'T17520A Food in bronchus causing asphyxiation, initial encounter',
  'T17520D Food in bronchus causing asphyxiation, subsequent encounter',
  'T17520S Food in bronchus causing asphyxiation, sequela',
  'T17528A Food in bronchus causing other injury, initial encounter',
  'T17528D Food in bronchus causing other injury, subsequent encounter',
  'T17528S Food in bronchus causing other injury, sequela',
  'T17590A Other foreign object in bronchus causing asphyxiation, initial encounter',
  'T17590D Other foreign object in bronchus causing asphyxiation, subsequent encounter',
  'T17590S Other foreign object in bronchus causing asphyxiation, sequela',
  'T17598A Other foreign object in bronchus causing other injury, initial encounter',
  'T17598D Other foreign object in bronchus causing other injury, subsequent encounter',
  'T17598S Other foreign object in bronchus causing other injury, sequela',
  'T17800A Unspecified foreign body in other parts of respiratory tract causing asphyxiation, initial',
  'encounter',
  'T17800D Unspecified foreign body in other parts of respiratory tract causing asphyxiation, subsequent',
  'encounter',
  'T17800S Unspecified foreign body in other parts of respiratory tract causing asphyxiation, sequela',
  'T17808A Unspecified foreign body in other parts of respiratory tract causing other injury, initial',
  'encounter',
  'T17808D Unspecified foreign body in other parts of respiratory tract causing other injury, subsequent',
  'encounter',
  'T17808S Unspecified foreign body in other parts of respiratory tract causing other injury, sequela',
  'T17810A Gastric contents in other parts of respiratory tract causing asphyxiation, initial encounter',
  'T17810D Gastric contents in other parts of respiratory tract causing asphyxiation, subsequent encounter',
  'T17810S Gastric contents in other parts of respiratory tract causing asphyxiation, sequela',
  'T17818A Gastric contents in other parts of respiratory tract causing other injury, initial encounter',
  'T17818D Gastric contents in other parts of respiratory tract causing other injury, subsequent encounter',
  'T17818S Gastric contents in other parts of respiratory tract causing other injury, sequela',
  'T17820A Food in other parts of respiratory tract causing asphyxiation, initial encounter',
  'T17820D Food in other parts of respiratory tract causing asphyxiation, subsequent encounter',
  'T17820S Food in other parts of respiratory tract causing asphyxiation, sequela',
  'T17828A Food in other parts of respiratory tract causing other injury, initial encounter',
  'T17828D Food in other parts of respiratory tract causing other injury, subsequent encounter',
  'T17828S Food in other parts of respiratory tract causing other injury, sequela',
  'T17890A Other foreign object in other parts of respiratory tract causing asphyxiation, initial encounter',
  'T17890D Other foreign object in other parts of respiratory tract causing asphyxiation, subsequent',
  'encounter',
  'T17890S Other foreign object in other parts of respiratory tract causing asphyxiation, sequela',
  'T17898A Other foreign object in other parts of respiratory tract causing other injury, initial encounter',
  'T17898D Other foreign object in other parts of respiratory tract causing other injury, subsequent',
  'encounter',
  'T17898S Other foreign object in other parts of respiratory tract causing other injury, sequela',
  'T17900A Unspecified foreign body in respiratory tract, part unspecified causing asphyxiation, initial',
  'encounter',
  'T17900D Unspecified foreign body in respiratory tract, part unspecified causing asphyxiation,',
  'subsequent encounter',
  'T17900S Unspecified foreign body in respiratory tract, part unspecified causing asphyxiation, sequela',
  'T17908A Unspecified foreign body in respiratory tract, part unspecified causing other injury, initial',
  'encounter',
  'T17908D Unspecified foreign body in respiratory tract, part unspecified causing other injury, subsequent',
  'encounter',
  'T17908S Unspecified foreign body in respiratory tract, part unspecified causing other injury, sequela',
  'T17910A Gastric contents in respiratory tract, part unspecified causing asphyxiation, initial encounter',
  'T17910D Gastric contents in respiratory tract, part unspecified causing asphyxiation, subsequent',
  'encounter',
  'T17910S Gastric contents in respiratory tract, part unspecified causing asphyxiation, sequela',
  'T17918A Gastric contents in respiratory tract, part unspecified causing other injury, initial encounter',
  'T17918D Gastric contents in respiratory tract, part unspecified causing other injury, subsequent',
  'encounter',
  'T17918S Gastric contents in respiratory tract, part unspecified causing other injury, sequela',
  'T17920A Food in respiratory tract, part unspecified causing asphyxiation, initial encounter',
  'T17920D Food in respiratory tract, part unspecified causing asphyxiation, subsequent encounter',
  'T17920S Food in respiratory tract, part unspecified causing asphyxiation, sequela',
  'T17928A Food in respiratory tract, part unspecified causing other injury, initial encounter',
  'T17928D Food in respiratory tract, part unspecified causing other injury, subsequent encounter',
  'T17928S Food in respiratory tract, part unspecified causing other injury, sequela',
  'T17990A Other foreign object in respiratory tract, part unspecified in causing asphyxiation, initial',
  'encounter',
  'T17990D Other foreign object in respiratory tract, part unspecified in causing asphyxiation, subsequent',
  'encounter',
  'T17990S Other foreign object in respiratory tract, part unspecified in causing asphyxiation, sequela',
  'T17998A Other foreign object in respiratory tract, part unspecified causing other injury, initial encounter',
  'T17998D Other foreign object in respiratory tract, part unspecified causing other injury, subsequent',
  'encounter',
  'T17998S Other foreign object in respiratory tract, part unspecified causing other injury, sequela',
  'T180XXA Foreign body in mouth, initial encounter',
  'T180XXD Foreign body in mouth, subsequent encounter',
  'T180XXS Foreign body in mouth, sequela',
  'T18100A Unspecified foreign body in esophagus causing compression of trachea, initial encounter',
  'T18100D Unspecified foreign body in esophagus causing compression of trachea, subsequent encounter',
  'T18100S Unspecified foreign body in esophagus causing compression of trachea, sequela',
  'T18108A Unspecified foreign body in esophagus causing other injury, initial encounter',
  'T18108D Unspecified foreign body in esophagus causing other injury, subsequent encounter',
  'T18108S Unspecified foreign body in esophagus causing other injury, sequela',
  'T18110A Gastric contents in esophagus causing compression of trachea, initial encounter',
  'T18110D Gastric contents in esophagus causing compression of trachea, subsequent encounter',
  'T18110S Gastric contents in esophagus causing compression of trachea, sequela',
  'T18118A Gastric contents in esophagus causing other injury, initial encounter',
  'T18118D Gastric contents in esophagus causing other injury, subsequent encounter',
  'T18118S Gastric contents in esophagus causing other injury, sequela',
  'T18120A Food in esophagus causing compression of trachea, initial encounter',
  'T18120D Food in esophagus causing compression of trachea, subsequent encounter',
  'T18120S Food in esophagus causing compression of trachea, sequela',
  'T18128A Food in esophagus causing other injury, initial encounter',
  'T18128D Food in esophagus causing other injury, subsequent encounter',
  'T18128S Food in esophagus causing other injury, sequela',
  'T18190A Other foreign object in esophagus causing compression of trachea, initial encounter',
  'T18190D Other foreign object in esophagus causing compression of trachea, subsequent encounter',
  'T18190S Other foreign object in esophagus causing compression of trachea, sequela',
  'T18198A Other foreign object in esophagus causing other injury, initial encounter',
  'T18198D Other foreign object in esophagus causing other injury, subsequent encounter',
  'T18198S Other foreign object in esophagus causing other injury, sequela',
  'T182XXA Foreign body in stomach, initial encounter',
  'T182XXD Foreign body in stomach, subsequent encounter',
  'T182XXS Foreign body in stomach, sequela',
  'T183XXA Foreign body in small intestine, initial encounter',
  'T183XXD Foreign body in small intestine, subsequent encounter',
  'T183XXS Foreign body in small intestine, sequela',
  'T184XXA Foreign body in colon, initial encounter',
  'T184XXD Foreign body in colon, subsequent encounter',
  'T184XXS Foreign body in colon, sequela',
  'T185XXA Foreign body in anus and rectum, initial encounter',
  'T185XXD Foreign body in anus and rectum, subsequent encounter',
  'T185XXS Foreign body in anus and rectum, sequela',
  'T188XXA Foreign body in other parts of alimentary tract, initial encounter',
  'T188XXD Foreign body in other parts of alimentary tract, subsequent encounter',
  'T188XXS Foreign body in other parts of alimentary tract, sequela',
  'T189XXA Foreign body of alimentary tract, part unspecified, initial encounter',
  'T189XXD Foreign body of alimentary tract, part unspecified, subsequent encounter',
  'T189XXS Foreign body of alimentary tract, part unspecified, sequela',
  'T190XXA Foreign body in urethra, initial encounter',
  'T190XXD Foreign body in urethra, subsequent encounter',
  'T190XXS Foreign body in urethra, sequela',
  'T191XXA Foreign body in bladder, initial encounter',
  'T191XXD Foreign body in bladder, subsequent encounter',
  'T191XXS Foreign body in bladder, sequela',
  'T192XXA Foreign body in vulva and vagina, initial encounter',
  'T192XXD Foreign body in vulva and vagina, subsequent encounter',
  'T192XXS Foreign body in vulva and vagina, sequela',
  'T193XXA Foreign body in uterus, initial encounter',
  'T193XXD Foreign body in uterus, subsequent encounter',
  'T193XXS Foreign body in uterus, sequela',
  'T194XXA Foreign body in penis, initial encounter',
  'T194XXD Foreign body in penis, subsequent encounter',
  'T194XXS Foreign body in penis, sequela',
  'T198XXA Foreign body in other parts of genitourinary tract, initial encounter',
  'T198XXD Foreign body in other parts of genitourinary tract, subsequent encounter',
  'T198XXS Foreign body in other parts of genitourinary tract, sequela',
  'T199XXA Foreign body in genitourinary tract, part unspecified, initial encounter',
  'T199XXD Foreign body in genitourinary tract, part unspecified, subsequent encounter',
  'T199XXS Foreign body in genitourinary tract, part unspecified, sequela',
  'T2000XA Burn of unspecified degree of head, face, and neck, unspecified site, initial encounter',
  'T2000XD Burn of unspecified degree of head, face, and neck, unspecified site, subsequent encounter',
  'T2000XS Burn of unspecified degree of head, face, and neck, unspecified site, sequela',
  'T20011A Burn of unspecified degree of right ear [any part, except ear drum], initial encounter',
  'T20011D Burn of unspecified degree of right ear [any part, except ear drum], subsequent encounter',
  'T20011S Burn of unspecified degree of right ear [any part, except ear drum], sequela',
  'T20012A Burn of unspecified degree of left ear [any part, except ear drum], initial encounter',
  'T20012D Burn of unspecified degree of left ear [any part, except ear drum], subsequent encounter',
  'T20012S Burn of unspecified degree of left ear [any part, except ear drum], sequela',
  'T20019A Burn of unspecified degree of unspecified ear [any part, except ear drum], initial encounter',
  'T20019D Burn of unspecified degree of unspecified ear [any part, except ear drum], subsequent',
  'encounter',
  'T20019S Burn of unspecified degree of unspecified ear [any part, except ear drum], sequela',
  'T2002XA Burn of unspecified degree of lip(s), initial encounter',
  'T2002XD Burn of unspecified degree of lip(s), subsequent encounter',
  'T2002XS Burn of unspecified degree of lip(s), sequela',
  'T2003XA Burn of unspecified degree of chin, initial encounter',
  'T2003XD Burn of unspecified degree of chin, subsequent encounter',
  'T2003XS Burn of unspecified degree of chin, sequela',
  'T2004XA Burn of unspecified degree of nose (septum), initial encounter',
  'T2004XD Burn of unspecified degree of nose (septum), subsequent encounter',
  'T2004XS Burn of unspecified degree of nose (septum), sequela',
  'T2005XA Burn of unspecified degree of scalp [any part], initial encounter',
  'T2005XD Burn of unspecified degree of scalp [any part], subsequent encounter',
  'T2005XS Burn of unspecified degree of scalp [any part], sequela',
  'T2006XA Burn of unspecified degree of forehead and cheek, initial encounter',
  'T2006XD Burn of unspecified degree of forehead and cheek, subsequent encounter',
  'T2006XS Burn of unspecified degree of forehead and cheek, sequela',
  'T2007XA Burn of unspecified degree of neck, initial encounter',
  'T2007XD Burn of unspecified degree of neck, subsequent encounter',
  'T2007XS Burn of unspecified degree of neck, sequela',
  'T2009XA Burn of unspecified degree of multiple sites of head, face, and neck, initial encounter',
  'T2009XD Burn of unspecified degree of multiple sites of head, face, and neck, subsequent encounter',
  'T2009XS Burn of unspecified degree of multiple sites of head, face, and neck, sequela',
  'T2010XA Burn of first degree of head, face, and neck, unspecified site, initial encounter',
  'T2010XD Burn of first degree of head, face, and neck, unspecified site, subsequent encounter',
  'T2010XS Burn of first degree of head, face, and neck, unspecified site, sequela',
  'T20111A Burn of first degree of right ear [any part, except ear drum], initial encounter',
  'T20111D Burn of first degree of right ear [any part, except ear drum], subsequent encounter',
  'T20111S Burn of first degree of right ear [any part, except ear drum], sequela',
  'T20112A Burn of first degree of left ear [any part, except ear drum], initial encounter',
  'T20112D Burn of first degree of left ear [any part, except ear drum], subsequent encounter',
  'T20112S Burn of first degree of left ear [any part, except ear drum], sequela',
  'T20119A Burn of first degree of unspecified ear [any part, except ear drum], initial encounter',
  'T20119D Burn of first degree of unspecified ear [any part, except ear drum], subsequent encounter',
  'T20119S Burn of first degree of unspecified ear [any part, except ear drum], sequela',
  'T2012XA Burn of first degree of lip(s), initial encounter',
  'T2012XD Burn of first degree of lip(s), subsequent encounter',
  'T2012XS Burn of first degree of lip(s), sequela',
  'T2013XA Burn of first degree of chin, initial encounter',
  'T2013XD Burn of first degree of chin, subsequent encounter',
  'T2013XS Burn of first degree of chin, sequela',
  'T2014XA Burn of first degree of nose (septum), initial encounter',
  'T2014XD Burn of first degree of nose (septum), subsequent encounter',
  'T2014XS Burn of first degree of nose (septum), sequela',
  'T2015XA Burn of first degree of scalp [any part], initial encounter',
  'T2015XD Burn of first degree of scalp [any part], subsequent encounter',
  'T2015XS Burn of first degree of scalp [any part], sequela',
  'T2016XA Burn of first degree of forehead and cheek, initial encounter',
  'T2016XD Burn of first degree of forehead and cheek, subsequent encounter',
  'T2016XS Burn of first degree of forehead and cheek, sequela',
  'T2017XA Burn of first degree of neck, initial encounter',
  'T2017XD Burn of first degree of neck, subsequent encounter',
  'T2017XS Burn of first degree of neck, sequela',
  'T2019XA Burn of first degree of multiple sites of head, face, and neck, initial encounter',
  'T2019XD Burn of first degree of multiple sites of head, face, and neck, subsequent encounter',
  'T2019XS Burn of first degree of multiple sites of head, face, and neck, sequela',
  'T2020XA Burn of second degree of head, face, and neck, unspecified site, initial encounter',
  'T2020XD Burn of second degree of head, face, and neck, unspecified site, subsequent encounter',
  'T2020XS Burn of second degree of head, face, and neck, unspecified site, sequela',
  'T20211A Burn of second degree of right ear [any part, except ear drum], initial encounter',
  'T20211D Burn of second degree of right ear [any part, except ear drum], subsequent encounter',
  'T20211S Burn of second degree of right ear [any part, except ear drum], sequela',
  'T20212A Burn of second degree of left ear [any part, except ear drum], initial encounter',
  'T20212D Burn of second degree of left ear [any part, except ear drum], subsequent encounter',
  'T20212S Burn of second degree of left ear [any part, except ear drum], sequela',
  'T20219A Burn of second degree of unspecified ear [any part, except ear drum], initial encounter',
  'T20219D Burn of second degree of unspecified ear [any part, except ear drum], subsequent encounter',
  'T20219S Burn of second degree of unspecified ear [any part, except ear drum], sequela',
  'T2022XA Burn of second degree of lip(s), initial encounter',
  'T2022XD Burn of second degree of lip(s), subsequent encounter',
  'T2022XS Burn of second degree of lip(s), sequela',
  'T2023XA Burn of second degree of chin, initial encounter',
  'T2023XD Burn of second degree of chin, subsequent encounter',
  'T2023XS Burn of second degree of chin, sequela',
  'T2024XA Burn of second degree of nose (septum), initial encounter',
  'T2024XD Burn of second degree of nose (septum), subsequent encounter',
  'T2024XS Burn of second degree of nose (septum), sequela',
  'T2025XA Burn of second degree of scalp [any part], initial encounter',
  'T2025XD Burn of second degree of scalp [any part], subsequent encounter',
  'T2025XS Burn of second degree of scalp [any part], sequela',
  'T2026XA Burn of second degree of forehead and cheek, initial encounter',
  'T2026XD Burn of second degree of forehead and cheek, subsequent encounter',
  'T2026XS Burn of second degree of forehead and cheek, sequela',
  'T2027XA Burn of second degree of neck, initial encounter',
  'T2027XD Burn of second degree of neck, subsequent encounter',
  'T2027XS Burn of second degree of neck, sequela',
  'T2029XA Burn of second degree of multiple sites of head, face, and neck, initial encounter',
  'T2029XD Burn of second degree of multiple sites of head, face, and neck, subsequent encounter',
  'T2029XS Burn of second degree of multiple sites of head, face, and neck, sequela',
  'T2030XA Burn of third degree of head, face, and neck, unspecified site, initial encounter',
  'T2030XD Burn of third degree of head, face, and neck, unspecified site, subsequent encounter',
  'T2030XS Burn of third degree of head, face, and neck, unspecified site, sequela',
  'T20311A Burn of third degree of right ear [any part, except ear drum], initial encounter',
  'T20311D Burn of third degree of right ear [any part, except ear drum], subsequent encounter',
  'T20311S Burn of third degree of right ear [any part, except ear drum], sequela',
  'T20312A Burn of third degree of left ear [any part, except ear drum], initial encounter',
  'T20312D Burn of third degree of left ear [any part, except ear drum], subsequent encounter',
  'T20312S Burn of third degree of left ear [any part, except ear drum], sequela',
  'T20319A Burn of third degree of unspecified ear [any part, except ear drum], initial encounter',
  'T20319D Burn of third degree of unspecified ear [any part, except ear drum], subsequent encounter',
  'T20319S Burn of third degree of unspecified ear [any part, except ear drum], sequela',
  'T2032XA Burn of third degree of lip(s), initial encounter',
  'T2032XD Burn of third degree of lip(s), subsequent encounter',
  'T2032XS Burn of third degree of lip(s), sequela',
  'T2033XA Burn of third degree of chin, initial encounter',
  'T2033XD Burn of third degree of chin, subsequent encounter',
  'T2033XS Burn of third degree of chin, sequela',
  'T2034XA Burn of third degree of nose (septum), initial encounter',
  'T2034XD Burn of third degree of nose (septum), subsequent encounter',
  'T2034XS Burn of third degree of nose (septum), sequela',
  'T2035XA Burn of third degree of scalp [any part], initial encounter',
  'T2035XD Burn of third degree of scalp [any part], subsequent encounter',
  'T2035XS Burn of third degree of scalp [any part], sequela',
  'T2036XA Burn of third degree of forehead and cheek, initial encounter',
  'T2036XD Burn of third degree of forehead and cheek, subsequent encounter',
  'T2036XS Burn of third degree of forehead and cheek, sequela',
  'T2037XA Burn of third degree of neck, initial encounter',
  'T2037XD Burn of third degree of neck, subsequent encounter',
  'T2037XS Burn of third degree of neck, sequela',
  'T2039XA Burn of third degree of multiple sites of head, face, and neck, initial encounter',
  'T2039XD Burn of third degree of multiple sites of head, face, and neck, subsequent encounter',
  'T2039XS Burn of third degree of multiple sites of head, face, and neck, sequela',
  'T2040XA Corrosion of unspecified degree of head, face, and neck, unspecified site, initial encounter',
  'T2040XD Corrosion of unspecified degree of head, face, and neck, unspecified site, subsequent',
  'encounter',
  'T2040XS Corrosion of unspecified degree of head, face, and neck, unspecified site, sequela',
  'T20411A Corrosion of unspecified degree of right ear [any part, except ear drum], initial encounter',
  'T20411D Corrosion of unspecified degree of right ear [any part, except ear drum], subsequent',
  'encounter',
  'T20411S Corrosion of unspecified degree of right ear [any part, except ear drum], sequela',
  'T20412A Corrosion of unspecified degree of left ear [any part, except ear drum], initial encounter',
  'T20412D Corrosion of unspecified degree of left ear [any part, except ear drum], subsequent encounter',
  'T20412S Corrosion of unspecified degree of left ear [any part, except ear drum], sequela',
  'T20419A Corrosion of unspecified degree of unspecified ear [any part, except ear drum], initial',
  'encounter',
  'T20419D Corrosion of unspecified degree of unspecified ear [any part, except ear drum], subsequent',
  'encounter',
  'T20419S Corrosion of unspecified degree of unspecified ear [any part, except ear drum], sequela',
  'T2042XA Corrosion of unspecified degree of lip(s), initial encounter',
  'T2042XD Corrosion of unspecified degree of lip(s), subsequent encounter',
  'T2042XS Corrosion of unspecified degree of lip(s), sequela',
  'T2043XA Corrosion of unspecified degree of chin, initial encounter',
  'T2043XD Corrosion of unspecified degree of chin, subsequent encounter',
  'T2043XS Corrosion of unspecified degree of chin, sequela',
  'T2044XA Corrosion of unspecified degree of nose (septum), initial encounter',
  'T2044XD Corrosion of unspecified degree of nose (septum), subsequent encounter',
  'T2044XS Corrosion of unspecified degree of nose (septum), sequela',
  'T2045XA Corrosion of unspecified degree of scalp [any part], initial encounter',
  'T2045XD Corrosion of unspecified degree of scalp [any part], subsequent encounter',
  'T2045XS Corrosion of unspecified degree of scalp [any part], sequela',
  'T2046XA Corrosion of unspecified degree of forehead and cheek, initial encounter',
  'T2046XD Corrosion of unspecified degree of forehead and cheek, subsequent encounter',
  'T2046XS Corrosion of unspecified degree of forehead and cheek, sequela',
  'T2047XA Corrosion of unspecified degree of neck, initial encounter',
  'T2047XD Corrosion of unspecified degree of neck, subsequent encounter',
  'T2047XS Corrosion of unspecified degree of neck, sequela',
  'T2049XA Corrosion of unspecified degree of multiple sites of head, face, and neck, initial encounter',
  'T2049XD Corrosion of unspecified degree of multiple sites of head, face, and neck, subsequent',
  'encounter',
  'T2049XS Corrosion of unspecified degree of multiple sites of head, face, and neck, sequela',
  'T2050XA Corrosion of first degree of head, face, and neck, unspecified site, initial encounter',
  'T2050XD Corrosion of first degree of head, face, and neck, unspecified site, subsequent encounter',
  'T2050XS Corrosion of first degree of head, face, and neck, unspecified site, sequela',
  'T20511A Corrosion of first degree of right ear [any part, except ear drum], initial encounter',
  'T20511D Corrosion of first degree of right ear [any part, except ear drum], subsequent encounter',
  'T20511S Corrosion of first degree of right ear [any part, except ear drum], sequela',
  'T20512A Corrosion of first degree of left ear [any part, except ear drum], initial encounter',
  'T20512D Corrosion of first degree of left ear [any part, except ear drum], subsequent encounter',
  'T20512S Corrosion of first degree of left ear [any part, except ear drum], sequela',
  'T20519A Corrosion of first degree of unspecified ear [any part, except ear drum], initial encounter',
  'T20519D Corrosion of first degree of unspecified ear [any part, except ear drum], subsequent encounter',
  'T20519S Corrosion of first degree of unspecified ear [any part, except ear drum], sequela',
  'T2052XA Corrosion of first degree of lip(s), initial encounter',
  'T2052XD Corrosion of first degree of lip(s), subsequent encounter',
  'T2052XS Corrosion of first degree of lip(s), sequela',
  'T2053XA Corrosion of first degree of chin, initial encounter',
  'T2053XD Corrosion of first degree of chin, subsequent encounter',
  'T2053XS Corrosion of first degree of chin, sequela',
  'T2054XA Corrosion of first degree of nose (septum), initial encounter',
  'T2054XD Corrosion of first degree of nose (septum), subsequent encounter',
  'T2054XS Corrosion of first degree of nose (septum), sequela',
  'T2055XA Corrosion of first degree of scalp [any part], initial encounter',
  'T2055XD Corrosion of first degree of scalp [any part], subsequent encounter',
  'T2055XS Corrosion of first degree of scalp [any part], sequela',
  'T2056XA Corrosion of first degree of forehead and cheek, initial encounter',
  'T2056XD Corrosion of first degree of forehead and cheek, subsequent encounter',
  'T2056XS Corrosion of first degree of forehead and cheek, sequela',
  'T2057XA Corrosion of first degree of neck, initial encounter',
  'T2057XD Corrosion of first degree of neck, subsequent encounter',
  'T2057XS Corrosion of first degree of neck, sequela',
  'T2059XA Corrosion of first degree of multiple sites of head, face, and neck, initial encounter',
  'T2059XD Corrosion of first degree of multiple sites of head, face, and neck, subsequent encounter',
  'T2059XS Corrosion of first degree of multiple sites of head, face, and neck, sequela',
  'T2060XA Corrosion of second degree of head, face, and neck, unspecified site, initial encounter',
  'T2060XD Corrosion of second degree of head, face, and neck, unspecified site, subsequent encounter',
  'T2060XS Corrosion of second degree of head, face, and neck, unspecified site, sequela',
  'T20611A Corrosion of second degree of right ear [any part, except ear drum], initial encounter',
  'T20611D Corrosion of second degree of right ear [any part, except ear drum], subsequent encounter',
  'T20611S Corrosion of second degree of right ear [any part, except ear drum], sequela',
  'T20612A Corrosion of second degree of left ear [any part, except ear drum], initial encounter',
  'T20612D Corrosion of second degree of left ear [any part, except ear drum], subsequent encounter',
  'T20612S Corrosion of second degree of left ear [any part, except ear drum], sequela',
  'T20619A Corrosion of second degree of unspecified ear [any part, except ear drum], initial encounter',
  'T20619D Corrosion of second degree of unspecified ear [any part, except ear drum], subsequent',
  'encounter',
  'T20619S Corrosion of second degree of unspecified ear [any part, except ear drum], sequela',
  'T2062XA Corrosion of second degree of lip(s), initial encounter',
  'T2062XD Corrosion of second degree of lip(s), subsequent encounter',
  'T2062XS Corrosion of second degree of lip(s), sequela',
  'T2063XA Corrosion of second degree of chin, initial encounter',
  'T2063XD Corrosion of second degree of chin, subsequent encounter',
  'T2063XS Corrosion of second degree of chin, sequela',
  'T2064XA Corrosion of second degree of nose (septum), initial encounter',
  'T2064XD Corrosion of second degree of nose (septum), subsequent encounter',
  'T2064XS Corrosion of second degree of nose (septum), sequela',
  'T2065XA Corrosion of second degree of scalp [any part], initial encounter',
  'T2065XD Corrosion of second degree of scalp [any part], subsequent encounter',
  'T2065XS Corrosion of second degree of scalp [any part], sequela',
  'T2066XA Corrosion of second degree of forehead and cheek, initial encounter',
  'T2066XD Corrosion of second degree of forehead and cheek, subsequent encounter',
  'T2066XS Corrosion of second degree of forehead and cheek, sequela',
  'T2067XA Corrosion of second degree of neck, initial encounter',
  'T2067XD Corrosion of second degree of neck, subsequent encounter',
  'T2067XS Corrosion of second degree of neck, sequela',
  'T2069XA Corrosion of second degree of multiple sites of head, face, and neck, initial encounter',
  'T2069XD Corrosion of second degree of multiple sites of head, face, and neck, subsequent encounter',
  'T2069XS Corrosion of second degree of multiple sites of head, face, and neck, sequela',
  'T2070XA Corrosion of third degree of head, face, and neck, unspecified site, initial encounter',
  'T2070XD Corrosion of third degree of head, face, and neck, unspecified site, subsequent encounter',
  'T2070XS Corrosion of third degree of head, face, and neck, unspecified site, sequela',
  'T20711A Corrosion of third degree of right ear [any part, except ear drum], initial encounter',
  'T20711D Corrosion of third degree of right ear [any part, except ear drum], subsequent encounter',
  'T20711S Corrosion of third degree of right ear [any part, except ear drum], sequela',
  'T20712A Corrosion of third degree of left ear [any part, except ear drum], initial encounter',
  'T20712D Corrosion of third degree of left ear [any part, except ear drum], subsequent encounter',
  'T20712S Corrosion of third degree of left ear [any part, except ear drum], sequela',
  'T20719A Corrosion of third degree of unspecified ear [any part, except ear drum], initial encounter',
  'T20719D Corrosion of third degree of unspecified ear [any part, except ear drum], subsequent',
  'encounter',
  'T20719S Corrosion of third degree of unspecified ear [any part, except ear drum], sequela',
  'T2072XA Corrosion of third degree of lip(s), initial encounter',
  'T2072XD Corrosion of third degree of lip(s), subsequent encounter',
  'T2072XS Corrosion of third degree of lip(s), sequela',
  'T2073XA Corrosion of third degree of chin, initial encounter',
  'T2073XD Corrosion of third degree of chin, subsequent encounter',
  'T2073XS Corrosion of third degree of chin, sequela',
  'T2074XA Corrosion of third degree of nose (septum), initial encounter',
  'T2074XD Corrosion of third degree of nose (septum), subsequent encounter',
  'T2074XS Corrosion of third degree of nose (septum), sequela',
  'T2075XA Corrosion of third degree of scalp [any part], initial encounter',
  'T2075XD Corrosion of third degree of scalp [any part], subsequent encounter',
  'T2075XS Corrosion of third degree of scalp [any part], sequela',
  'T2076XA Corrosion of third degree of forehead and cheek, initial encounter',
  'T2076XD Corrosion of third degree of forehead and cheek, subsequent encounter',
  'T2076XS Corrosion of third degree of forehead and cheek, sequela',
  'T2077XA Corrosion of third degree of neck, initial encounter',
  'T2077XD Corrosion of third degree of neck, subsequent encounter',
  'T2077XS Corrosion of third degree of neck, sequela',
  'T2079XA Corrosion of third degree of multiple sites of head, face, and neck, initial encounter',
  'T2079XD Corrosion of third degree of multiple sites of head, face, and neck, subsequent encounter',
  'T2079XS Corrosion of third degree of multiple sites of head, face, and neck, sequela',
  'T2100XA Burn of unspecified degree of trunk, unspecified site, initial encounter',
  'T2100XD Burn of unspecified degree of trunk, unspecified site, subsequent encounter',
  'T2100XS Burn of unspecified degree of trunk, unspecified site, sequela',
  'T2101XA Burn of unspecified degree of chest wall, initial encounter',
  'T2101XD Burn of unspecified degree of chest wall, subsequent encounter',
  'T2101XS Burn of unspecified degree of chest wall, sequela',
  'T2102XA Burn of unspecified degree of abdominal wall, initial encounter',
  'T2102XD Burn of unspecified degree of abdominal wall, subsequent encounter',
  'T2102XS Burn of unspecified degree of abdominal wall, sequela',
  'T2103XA Burn of unspecified degree of upper back, initial encounter',
  'T2103XD Burn of unspecified degree of upper back, subsequent encounter',
  'T2103XS Burn of unspecified degree of upper back, sequela',
  'T2104XA Burn of unspecified degree of lower back, initial encounter',
  'T2104XD Burn of unspecified degree of lower back, subsequent encounter',
  'T2104XS Burn of unspecified degree of lower back, sequela',
  'T2105XA Burn of unspecified degree of buttock, initial encounter',
  'T2105XD Burn of unspecified degree of buttock, subsequent encounter',
  'T2105XS Burn of unspecified degree of buttock, sequela',
  'T2106XA Burn of unspecified degree of male genital region, initial encounter',
  'T2106XD Burn of unspecified degree of male genital region, subsequent encounter',
  'T2106XS Burn of unspecified degree of male genital region, sequela',
  'T2107XA Burn of unspecified degree of female genital region, initial encounter',
  'T2107XD Burn of unspecified degree of female genital region, subsequent encounter',
  'T2107XS Burn of unspecified degree of female genital region, sequela',
  'T2109XA Burn of unspecified degree of other site of trunk, initial encounter',
  'T2109XD Burn of unspecified degree of other site of trunk, subsequent encounter',
  'T2109XS Burn of unspecified degree of other site of trunk, sequela',
  'T2110XA Burn of first degree of trunk, unspecified site, initial encounter',
  'T2110XD Burn of first degree of trunk, unspecified site, subsequent encounter',
  'T2110XS Burn of first degree of trunk, unspecified site, sequela',
  'T2111XA Burn of first degree of chest wall, initial encounter',
  'T2111XD Burn of first degree of chest wall, subsequent encounter',
  'T2111XS Burn of first degree of chest wall, sequela',
  'T2112XA Burn of first degree of abdominal wall, initial encounter',
  'T2112XD Burn of first degree of abdominal wall, subsequent encounter',
  'T2112XS Burn of first degree of abdominal wall, sequela',
  'T2113XA Burn of first degree of upper back, initial encounter',
  'T2113XD Burn of first degree of upper back, subsequent encounter',
  'T2113XS Burn of first degree of upper back, sequela',
  'T2114XA Burn of first degree of lower back, initial encounter',
  'T2114XD Burn of first degree of lower back, subsequent encounter',
  'T2114XS Burn of first degree of lower back, sequela',
  'T2115XA Burn of first degree of buttock, initial encounter',
  'T2115XD Burn of first degree of buttock, subsequent encounter',
  'T2115XS Burn of first degree of buttock, sequela',
  'T2116XA Burn of first degree of male genital region, initial encounter',
  'T2116XD Burn of first degree of male genital region, subsequent encounter',
  'T2116XS Burn of first degree of male genital region, sequela',
  'T2117XA Burn of first degree of female genital region, initial encounter',
  'T2117XD Burn of first degree of female genital region, subsequent encounter',
  'T2117XS Burn of first degree of female genital region, sequela',
  'T2119XA Burn of first degree of other site of trunk, initial encounter',
  'T2119XD Burn of first degree of other site of trunk, subsequent encounter',
  'T2119XS Burn of first degree of other site of trunk, sequela',
  'T2120XA Burn of second degree of trunk, unspecified site, initial encounter',
  'T2120XD Burn of second degree of trunk, unspecified site, subsequent encounter',
  'T2120XS Burn of second degree of trunk, unspecified site, sequela',
  'T2121XA Burn of second degree of chest wall, initial encounter',
  'T2121XD Burn of second degree of chest wall, subsequent encounter',
  'T2121XS Burn of second degree of chest wall, sequela',
  'T2122XA Burn of second degree of abdominal wall, initial encounter',
  'T2122XD Burn of second degree of abdominal wall, subsequent encounter',
  'T2122XS Burn of second degree of abdominal wall, sequela',
  'T2123XA Burn of second degree of upper back, initial encounter',
  'T2123XD Burn of second degree of upper back, subsequent encounter',
  'T2123XS Burn of second degree of upper back, sequela',
  'T2124XA Burn of second degree of lower back, initial encounter',
  'T2124XD Burn of second degree of lower back, subsequent encounter',
  'T2124XS Burn of second degree of lower back, sequela',
  'T2125XA Burn of second degree of buttock, initial encounter',
  'T2125XD Burn of second degree of buttock, subsequent encounter',
  'T2125XS Burn of second degree of buttock, sequela',
  'T2126XA Burn of second degree of male genital region, initial encounter',
  'T2126XD Burn of second degree of male genital region, subsequent encounter',
  'T2126XS Burn of second degree of male genital region, sequela',
  'T2127XA Burn of second degree of female genital region, initial encounter',
  'T2127XD Burn of second degree of female genital region, subsequent encounter',
  'T2127XS Burn of second degree of female genital region, sequela',
  'T2129XA Burn of second degree of other site of trunk, initial encounter',
  'T2129XD Burn of second degree of other site of trunk, subsequent encounter',
  'T2129XS Burn of second degree of other site of trunk, sequela',
  'T2130XA Burn of third degree of trunk, unspecified site, initial encounter',
  'T2130XD Burn of third degree of trunk, unspecified site, subsequent encounter',
  'T2130XS Burn of third degree of trunk, unspecified site, sequela',
  'T2131XA Burn of third degree of chest wall, initial encounter',
  'T2131XD Burn of third degree of chest wall, subsequent encounter',
  'T2131XS Burn of third degree of chest wall, sequela',
  'T2132XA Burn of third degree of abdominal wall, initial encounter',
  'T2132XD Burn of third degree of abdominal wall, subsequent encounter',
  'T2132XS Burn of third degree of abdominal wall, sequela',
  'T2133XA Burn of third degree of upper back, initial encounter',
  'T2133XD Burn of third degree of upper back, subsequent encounter',
  'T2133XS Burn of third degree of upper back, sequela',
  'T2134XA Burn of third degree of lower back, initial encounter',
  'T2134XD Burn of third degree of lower back, subsequent encounter',
  'T2134XS Burn of third degree of lower back, sequela',
  'T2135XA Burn of third degree of buttock, initial encounter',
  'T2135XD Burn of third degree of buttock, subsequent encounter',
  'T2135XS Burn of third degree of buttock, sequela',
  'T2136XA Burn of third degree of male genital region, initial encounter',
  'T2136XD Burn of third degree of male genital region, subsequent encounter',
  'T2136XS Burn of third degree of male genital region, sequela',
  'T2137XA Burn of third degree of female genital region, initial encounter',
  'T2137XD Burn of third degree of female genital region, subsequent encounter',
  'T2137XS Burn of third degree of female genital region, sequela',
  'T2139XA Burn of third degree of other site of trunk, initial encounter',
  'T2139XD Burn of third degree of other site of trunk, subsequent encounter',
  'T2139XS Burn of third degree of other site of trunk, sequela',
  'T2140XA Corrosion of unspecified degree of trunk, unspecified site, initial encounter',
  'T2140XD Corrosion of unspecified degree of trunk, unspecified site, subsequent encounter',
  'T2140XS Corrosion of unspecified degree of trunk, unspecified site, sequela',
  'T2141XA Corrosion of unspecified degree of chest wall, initial encounter',
  'T2141XD Corrosion of unspecified degree of chest wall, subsequent encounter',
  'T2141XS Corrosion of unspecified degree of chest wall, sequela',
  'T2142XA Corrosion of unspecified degree of abdominal wall, initial encounter',
  'T2142XD Corrosion of unspecified degree of abdominal wall, subsequent encounter',
  'T2142XS Corrosion of unspecified degree of abdominal wall, sequela',
  'T2143XA Corrosion of unspecified degree of upper back, initial encounter',
  'T2143XD Corrosion of unspecified degree of upper back, subsequent encounter',
  'T2143XS Corrosion of unspecified degree of upper back, sequela',
  'T2144XA Corrosion of unspecified degree of lower back, initial encounter',
  'T2144XD Corrosion of unspecified degree of lower back, subsequent encounter',
  'T2144XS Corrosion of unspecified degree of lower back, sequela',
  'T2145XA Corrosion of unspecified degree of buttock, initial encounter',
  'T2145XD Corrosion of unspecified degree of buttock, subsequent encounter',
  'T2145XS Corrosion of unspecified degree of buttock, sequela',
  'T2146XA Corrosion of unspecified degree of male genital region, initial encounter',
  'T2146XD Corrosion of unspecified degree of male genital region, subsequent encounter',
  'T2146XS Corrosion of unspecified degree of male genital region, sequela',
  'T2147XA Corrosion of unspecified degree of female genital region, initial encounter',
  'T2147XD Corrosion of unspecified degree of female genital region, subsequent encounter',
  'T2147XS Corrosion of unspecified degree of female genital region, sequela',
  'T2149XA Corrosion of unspecified degree of other site of trunk, initial encounter',
  'T2149XD Corrosion of unspecified degree of other site of trunk, subsequent encounter',
  'T2149XS Corrosion of unspecified degree of other site of trunk, sequela',
  'T2150XA Corrosion of first degree of trunk, unspecified site, initial encounter',
  'T2150XD Corrosion of first degree of trunk, unspecified site, subsequent encounter',
  'T2150XS Corrosion of first degree of trunk, unspecified site, sequela',
  'T2151XA Corrosion of first degree of chest wall, initial encounter',
  'T2151XD Corrosion of first degree of chest wall, subsequent encounter',
  'T2151XS Corrosion of first degree of chest wall, sequela',
  'T2152XA Corrosion of first degree of abdominal wall, initial encounter',
  'T2152XD Corrosion of first degree of abdominal wall, subsequent encounter',
  'T2152XS Corrosion of first degree of abdominal wall, sequela',
  'T2153XA Corrosion of first degree of upper back, initial encounter',
  'T2153XD Corrosion of first degree of upper back, subsequent encounter',
  'T2153XS Corrosion of first degree of upper back, sequela',
  'T2154XA Corrosion of first degree of lower back, initial encounter',
  'T2154XD Corrosion of first degree of lower back, subsequent encounter',
  'T2154XS Corrosion of first degree of lower back, sequela',
  'T2155XA Corrosion of first degree of buttock, initial encounter',
  'T2155XD Corrosion of first degree of buttock, subsequent encounter',
  'T2155XS Corrosion of first degree of buttock, sequela',
  'T2156XA Corrosion of first degree of male genital region, initial encounter',
  'T2156XD Corrosion of first degree of male genital region, subsequent encounter',
  'T2156XS Corrosion of first degree of male genital region, sequela',
  'T2157XA Corrosion of first degree of female genital region, initial encounter',
  'T2157XD Corrosion of first degree of female genital region, subsequent encounter',
  'T2157XS Corrosion of first degree of female genital region, sequela',
  'T2159XA Corrosion of first degree of other site of trunk, initial encounter',
  'T2159XD Corrosion of first degree of other site of trunk, subsequent encounter',
  'T2159XS Corrosion of first degree of other site of trunk, sequela',
  'T2160XA Corrosion of second degree of trunk, unspecified site, initial encounter',
  'T2160XD Corrosion of second degree of trunk, unspecified site, subsequent encounter',
  'T2160XS Corrosion of second degree of trunk, unspecified site, sequela',
  'T2161XA Corrosion of second degree of chest wall, initial encounter',
  'T2161XD Corrosion of second degree of chest wall, subsequent encounter',
  'T2161XS Corrosion of second degree of chest wall, sequela',
  'T2162XA Corrosion of second degree of abdominal wall, initial encounter',
  'T2162XD Corrosion of second degree of abdominal wall, subsequent encounter',
  'T2162XS Corrosion of second degree of abdominal wall, sequela',
  'T2163XA Corrosion of second degree of upper back, initial encounter',
  'T2163XD Corrosion of second degree of upper back, subsequent encounter',
  'T2163XS Corrosion of second degree of upper back, sequela',
  'T2164XA Corrosion of second degree of lower back, initial encounter',
  'T2164XD Corrosion of second degree of lower back, subsequent encounter',
  'T2164XS Corrosion of second degree of lower back, sequela',
  'T2165XA Corrosion of second degree of buttock, initial encounter',
  'T2165XD Corrosion of second degree of buttock, subsequent encounter',
  'T2165XS Corrosion of second degree of buttock, sequela',
  'T2166XA Corrosion of second degree of male genital region, initial encounter',
  'T2166XD Corrosion of second degree of male genital region, subsequent encounter',
  'T2166XS Corrosion of second degree of male genital region, sequela',
  'T2167XA Corrosion of second degree of female genital region, initial encounter',
  'T2167XD Corrosion of second degree of female genital region, subsequent encounter',
  'T2167XS Corrosion of second degree of female genital region, sequela',
  'T2169XA Corrosion of second degree of other site of trunk, initial encounter',
  'T2169XD Corrosion of second degree of other site of trunk, subsequent encounter',
  'T2169XS Corrosion of second degree of other site of trunk, sequela',
  'T2170XA Corrosion of third degree of trunk, unspecified site, initial encounter',
  'T2170XD Corrosion of third degree of trunk, unspecified site, subsequent encounter',
  'T2170XS Corrosion of third degree of trunk, unspecified site, sequela',
  'T2171XA Corrosion of third degree of chest wall, initial encounter',
  'T2171XD Corrosion of third degree of chest wall, subsequent encounter',
  'T2171XS Corrosion of third degree of chest wall, sequela',
  'T2172XA Corrosion of third degree of abdominal wall, initial encounter',
  'T2172XD Corrosion of third degree of abdominal wall, subsequent encounter',
  'T2172XS Corrosion of third degree of abdominal wall, sequela',
  'T2173XA Corrosion of third degree of upper back, initial encounter',
  'T2173XD Corrosion of third degree of upper back, subsequent encounter',
  'T2173XS Corrosion of third degree of upper back, sequela',
  'T2174XA Corrosion of third degree of lower back, initial encounter',
  'T2174XD Corrosion of third degree of lower back, subsequent encounter',
  'T2174XS Corrosion of third degree of lower back, sequela',
  'T2175XA Corrosion of third degree of buttock, initial encounter',
  'T2175XD Corrosion of third degree of buttock, subsequent encounter',
  'T2175XS Corrosion of third degree of buttock, sequela',
  'T2176XA Corrosion of third degree of male genital region, initial encounter',
  'T2176XD Corrosion of third degree of male genital region, subsequent encounter',
  'T2176XS Corrosion of third degree of male genital region, sequela',
  'T2177XA Corrosion of third degree of female genital region, initial encounter',
  'T2177XD Corrosion of third degree of female genital region, subsequent encounter',
  'T2177XS Corrosion of third degree of female genital region, sequela',
  'T2179XA Corrosion of third degree of other site of trunk, initial encounter',
  'T2179XD Corrosion of third degree of other site of trunk, subsequent encounter',
  'T2179XS Corrosion of third degree of other site of trunk, sequela',
  'T2200XA Burn of unspecified degree of shoulder and upper limb, except wrist and hand, unspecified',
  'site, initial encounter',
  'T2200XD Burn of unspecified degree of shoulder and upper limb, except wrist and hand, unspecified',
  'site, subsequent encounter',
  'T2200XS Burn of unspecified degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'sequela',
  'T22011A Burn of unspecified degree of right forearm, initial encounter',
  'T22011D Burn of unspecified degree of right forearm, subsequent encounter',
  'T22011S Burn of unspecified degree of right forearm, sequela',
  'T22012A Burn of unspecified degree of left forearm, initial encounter',
  'T22012D Burn of unspecified degree of left forearm, subsequent encounter',
  'T22012S Burn of unspecified degree of left forearm, sequela',
  'T22019A Burn of unspecified degree of unspecified forearm, initial encounter',
  'T22019D Burn of unspecified degree of unspecified forearm, subsequent encounter',
  'T22019S Burn of unspecified degree of unspecified forearm, sequela',
  'T22021A Burn of unspecified degree of right elbow, initial encounter',
  'T22021D Burn of unspecified degree of right elbow, subsequent encounter',
  'T22021S Burn of unspecified degree of right elbow, sequela',
  'T22022A Burn of unspecified degree of left elbow, initial encounter',
  'T22022D Burn of unspecified degree of left elbow, subsequent encounter',
  'T22022S Burn of unspecified degree of left elbow, sequela',
  'T22029A Burn of unspecified degree of unspecified elbow, initial encounter',
  'T22029D Burn of unspecified degree of unspecified elbow, subsequent encounter',
  'T22029S Burn of unspecified degree of unspecified elbow, sequela',
  'T22031A Burn of unspecified degree of right upper arm, initial encounter',
  'T22031D Burn of unspecified degree of right upper arm, subsequent encounter',
  'T22031S Burn of unspecified degree of right upper arm, sequela',
  'T22032A Burn of unspecified degree of left upper arm, initial encounter',
  'T22032D Burn of unspecified degree of left upper arm, subsequent encounter',
  'T22032S Burn of unspecified degree of left upper arm, sequela',
  'T22039A Burn of unspecified degree of unspecified upper arm, initial encounter',
  'T22039D Burn of unspecified degree of unspecified upper arm, subsequent encounter',
  'T22039S Burn of unspecified degree of unspecified upper arm, sequela',
  'T22041A Burn of unspecified degree of right axilla, initial encounter',
  'T22041D Burn of unspecified degree of right axilla, subsequent encounter',
  'T22041S Burn of unspecified degree of right axilla, sequela',
  'T22042A Burn of unspecified degree of left axilla, initial encounter',
  'T22042D Burn of unspecified degree of left axilla, subsequent encounter',
  'T22042S Burn of unspecified degree of left axilla, sequela',
  'T22049A Burn of unspecified degree of unspecified axilla, initial encounter',
  'T22049D Burn of unspecified degree of unspecified axilla, subsequent encounter',
  'T22049S Burn of unspecified degree of unspecified axilla, sequela',
  'T22051A Burn of unspecified degree of right shoulder, initial encounter',
  'T22051D Burn of unspecified degree of right shoulder, subsequent encounter',
  'T22051S Burn of unspecified degree of right shoulder, sequela',
  'T22052A Burn of unspecified degree of left shoulder, initial encounter',
  'T22052D Burn of unspecified degree of left shoulder, subsequent encounter',
  'T22052S Burn of unspecified degree of left shoulder, sequela',
  'T22059A Burn of unspecified degree of unspecified shoulder, initial encounter',
  'T22059D Burn of unspecified degree of unspecified shoulder, subsequent encounter',
  'T22059S Burn of unspecified degree of unspecified shoulder, sequela',
  'T22061A Burn of unspecified degree of right scapular region, initial encounter',
  'T22061D Burn of unspecified degree of right scapular region, subsequent encounter',
  'T22061S Burn of unspecified degree of right scapular region, sequela',
  'T22062A Burn of unspecified degree of left scapular region, initial encounter',
  'T22062D Burn of unspecified degree of left scapular region, subsequent encounter',
  'T22062S Burn of unspecified degree of left scapular region, sequela',
  'T22069A Burn of unspecified degree of unspecified scapular region, initial encounter',
  'T22069D Burn of unspecified degree of unspecified scapular region, subsequent encounter',
  'T22069S Burn of unspecified degree of unspecified scapular region, sequela',
  'T22091A Burn of unspecified degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22091D Burn of unspecified degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22091S Burn of unspecified degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22092A Burn of unspecified degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22092D Burn of unspecified degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22092S Burn of unspecified degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22099A Burn of unspecified degree of multiple sites of unspecified shoulder and upper limb, except',
  'wrist and hand, initial encounter',
  'T22099D Burn of unspecified degree of multiple sites of unspecified shoulder and upper limb, except',
  'wrist and hand, subsequent encounter',
  'T22099S Burn of unspecified degree of multiple sites of unspecified shoulder and upper limb, except',
  'wrist and hand, sequela',
  'T2210XA Burn of first degree of shoulder and upper limb, except wrist and hand, unspecified site, initial',
  'encounter',
  'T2210XD Burn of first degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'subsequent encounter',
  'T2210XS Burn of first degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'sequela',
  'T22111A Burn of first degree of right forearm, initial encounter',
  'T22111D Burn of first degree of right forearm, subsequent encounter',
  'T22111S Burn of first degree of right forearm, sequela',
  'T22112A Burn of first degree of left forearm, initial encounter',
  'T22112D Burn of first degree of left forearm, subsequent encounter',
  'T22112S Burn of first degree of left forearm, sequela',
  'T22119A Burn of first degree of unspecified forearm, initial encounter',
  'T22119D Burn of first degree of unspecified forearm, subsequent encounter',
  'T22119S Burn of first degree of unspecified forearm, sequela',
  'T22121A Burn of first degree of right elbow, initial encounter',
  'T22121D Burn of first degree of right elbow, subsequent encounter',
  'T22121S Burn of first degree of right elbow, sequela',
  'T22122A Burn of first degree of left elbow, initial encounter',
  'T22122D Burn of first degree of left elbow, subsequent encounter',
  'T22122S Burn of first degree of left elbow, sequela',
  'T22129A Burn of first degree of unspecified elbow, initial encounter',
  'T22129D Burn of first degree of unspecified elbow, subsequent encounter',
  'T22129S Burn of first degree of unspecified elbow, sequela',
  'T22131A Burn of first degree of right upper arm, initial encounter',
  'T22131D Burn of first degree of right upper arm, subsequent encounter',
  'T22131S Burn of first degree of right upper arm, sequela',
  'T22132A Burn of first degree of left upper arm, initial encounter',
  'T22132D Burn of first degree of left upper arm, subsequent encounter',
  'T22132S Burn of first degree of left upper arm, sequela',
  'T22139A Burn of first degree of unspecified upper arm, initial encounter',
  'T22139D Burn of first degree of unspecified upper arm, subsequent encounter',
  'T22139S Burn of first degree of unspecified upper arm, sequela',
  'T22141A Burn of first degree of right axilla, initial encounter',
  'T22141D Burn of first degree of right axilla, subsequent encounter',
  'T22141S Burn of first degree of right axilla, sequela',
  'T22142A Burn of first degree of left axilla, initial encounter',
  'T22142D Burn of first degree of left axilla, subsequent encounter',
  'T22142S Burn of first degree of left axilla, sequela',
  'T22149A Burn of first degree of unspecified axilla, initial encounter',
  'T22149D Burn of first degree of unspecified axilla, subsequent encounter',
  'T22149S Burn of first degree of unspecified axilla, sequela',
  'T22151A Burn of first degree of right shoulder, initial encounter',
  'T22151D Burn of first degree of right shoulder, subsequent encounter',
  'T22151S Burn of first degree of right shoulder, sequela',
  'T22152A Burn of first degree of left shoulder, initial encounter',
  'T22152D Burn of first degree of left shoulder, subsequent encounter',
  'T22152S Burn of first degree of left shoulder, sequela',
  'T22159A Burn of first degree of unspecified shoulder, initial encounter',
  'T22159D Burn of first degree of unspecified shoulder, subsequent encounter',
  'T22159S Burn of first degree of unspecified shoulder, sequela',
  'T22161A Burn of first degree of right scapular region, initial encounter',
  'T22161D Burn of first degree of right scapular region, subsequent encounter',
  'T22161S Burn of first degree of right scapular region, sequela',
  'T22162A Burn of first degree of left scapular region, initial encounter',
  'T22162D Burn of first degree of left scapular region, subsequent encounter',
  'T22162S Burn of first degree of left scapular region, sequela',
  'T22169A Burn of first degree of unspecified scapular region, initial encounter',
  'T22169D Burn of first degree of unspecified scapular region, subsequent encounter',
  'T22169S Burn of first degree of unspecified scapular region, sequela',
  'T22191A Burn of first degree of multiple sites of right shoulder and upper limb, except wrist and hand,',
  'initial encounter',
  'T22191D Burn of first degree of multiple sites of right shoulder and upper limb, except wrist and hand,',
  'subsequent encounter',
  'T22191S Burn of first degree of multiple sites of right shoulder and upper limb, except wrist and hand,',
  'sequela',
  'T22192A Burn of first degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'initial encounter',
  'T22192D Burn of first degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'subsequent encounter',
  'T22192S Burn of first degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'sequela',
  'T22199A Burn of first degree of multiple sites of unspecified shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22199D Burn of first degree of multiple sites of unspecified shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22199S Burn of first degree of multiple sites of unspecified shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T2220XA Burn of second degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'initial encounter',
  'T2220XD Burn of second degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'subsequent encounter',
  'T2220XS Burn of second degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'sequela',
  'T22211A Burn of second degree of right forearm, initial encounter',
  'T22211D Burn of second degree of right forearm, subsequent encounter',
  'T22211S Burn of second degree of right forearm, sequela',
  'T22212A Burn of second degree of left forearm, initial encounter',
  'T22212D Burn of second degree of left forearm, subsequent encounter',
  'T22212S Burn of second degree of left forearm, sequela',
  'T22219A Burn of second degree of unspecified forearm, initial encounter',
  'T22219D Burn of second degree of unspecified forearm, subsequent encounter',
  'T22219S Burn of second degree of unspecified forearm, sequela',
  'T22221A Burn of second degree of right elbow, initial encounter',
  'T22221D Burn of second degree of right elbow, subsequent encounter',
  'T22221S Burn of second degree of right elbow, sequela',
  'T22222A Burn of second degree of left elbow, initial encounter',
  'T22222D Burn of second degree of left elbow, subsequent encounter',
  'T22222S Burn of second degree of left elbow, sequela',
  'T22229A Burn of second degree of unspecified elbow, initial encounter',
  'T22229D Burn of second degree of unspecified elbow, subsequent encounter',
  'T22229S Burn of second degree of unspecified elbow, sequela',
  'T22231A Burn of second degree of right upper arm, initial encounter',
  'T22231D Burn of second degree of right upper arm, subsequent encounter',
  'T22231S Burn of second degree of right upper arm, sequela',
  'T22232A Burn of second degree of left upper arm, initial encounter',
  'T22232D Burn of second degree of left upper arm, subsequent encounter',
  'T22232S Burn of second degree of left upper arm, sequela',
  'T22239A Burn of second degree of unspecified upper arm, initial encounter',
  'T22239D Burn of second degree of unspecified upper arm, subsequent encounter',
  'T22239S Burn of second degree of unspecified upper arm, sequela',
  'T22241A Burn of second degree of right axilla, initial encounter',
  'T22241D Burn of second degree of right axilla, subsequent encounter',
  'T22241S Burn of second degree of right axilla, sequela',
  'T22242A Burn of second degree of left axilla, initial encounter',
  'T22242D Burn of second degree of left axilla, subsequent encounter',
  'T22242S Burn of second degree of left axilla, sequela',
  'T22249A Burn of second degree of unspecified axilla, initial encounter',
  'T22249D Burn of second degree of unspecified axilla, subsequent encounter',
  'T22249S Burn of second degree of unspecified axilla, sequela',
  'T22251A Burn of second degree of right shoulder, initial encounter',
  'T22251D Burn of second degree of right shoulder, subsequent encounter',
  'T22251S Burn of second degree of right shoulder, sequela',
  'T22252A Burn of second degree of left shoulder, initial encounter',
  'T22252D Burn of second degree of left shoulder, subsequent encounter',
  'T22252S Burn of second degree of left shoulder, sequela',
  'T22259A Burn of second degree of unspecified shoulder, initial encounter',
  'T22259D Burn of second degree of unspecified shoulder, subsequent encounter',
  'T22259S Burn of second degree of unspecified shoulder, sequela',
  'T22261A Burn of second degree of right scapular region, initial encounter',
  'T22261D Burn of second degree of right scapular region, subsequent encounter',
  'T22261S Burn of second degree of right scapular region, sequela',
  'T22262A Burn of second degree of left scapular region, initial encounter',
  'T22262D Burn of second degree of left scapular region, subsequent encounter',
  'T22262S Burn of second degree of left scapular region, sequela',
  'T22269A Burn of second degree of unspecified scapular region, initial encounter',
  'T22269D Burn of second degree of unspecified scapular region, subsequent encounter',
  'T22269S Burn of second degree of unspecified scapular region, sequela',
  'T22291A Burn of second degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22291D Burn of second degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22291S Burn of second degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22292A Burn of second degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'initial encounter',
  'T22292D Burn of second degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'subsequent encounter',
  'T22292S Burn of second degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'sequela',
  'T22299A Burn of second degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, initial encounter',
  'T22299D Burn of second degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, subsequent encounter',
  'T22299S Burn of second degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, sequela',
  'T2230XA Burn of third degree of shoulder and upper limb, except wrist and hand, unspecified site, initial',
  'encounter',
  'T2230XD Burn of third degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'subsequent encounter',
  'T2230XS Burn of third degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'sequela',
  'T22311A Burn of third degree of right forearm, initial encounter',
  'T22311D Burn of third degree of right forearm, subsequent encounter',
  'T22311S Burn of third degree of right forearm, sequela',
  'T22312A Burn of third degree of left forearm, initial encounter',
  'T22312D Burn of third degree of left forearm, subsequent encounter',
  'T22312S Burn of third degree of left forearm, sequela',
  'T22319A Burn of third degree of unspecified forearm, initial encounter',
  'T22319D Burn of third degree of unspecified forearm, subsequent encounter',
  'T22319S Burn of third degree of unspecified forearm, sequela',
  'T22321A Burn of third degree of right elbow, initial encounter',
  'T22321D Burn of third degree of right elbow, subsequent encounter',
  'T22321S Burn of third degree of right elbow, sequela',
  'T22322A Burn of third degree of left elbow, initial encounter',
  'T22322D Burn of third degree of left elbow, subsequent encounter',
  'T22322S Burn of third degree of left elbow, sequela',
  'T22329A Burn of third degree of unspecified elbow, initial encounter',
  'T22329D Burn of third degree of unspecified elbow, subsequent encounter',
  'T22329S Burn of third degree of unspecified elbow, sequela',
  'T22331A Burn of third degree of right upper arm, initial encounter',
  'T22331D Burn of third degree of right upper arm, subsequent encounter',
  'T22331S Burn of third degree of right upper arm, sequela',
  'T22332A Burn of third degree of left upper arm, initial encounter',
  'T22332D Burn of third degree of left upper arm, subsequent encounter',
  'T22332S Burn of third degree of left upper arm, sequela',
  'T22339A Burn of third degree of unspecified upper arm, initial encounter',
  'T22339D Burn of third degree of unspecified upper arm, subsequent encounter',
  'T22339S Burn of third degree of unspecified upper arm, sequela',
  'T22341A Burn of third degree of right axilla, initial encounter',
  'T22341D Burn of third degree of right axilla, subsequent encounter',
  'T22341S Burn of third degree of right axilla, sequela',
  'T22342A Burn of third degree of left axilla, initial encounter',
  'T22342D Burn of third degree of left axilla, subsequent encounter',
  'T22342S Burn of third degree of left axilla, sequela',
  'T22349A Burn of third degree of unspecified axilla, initial encounter',
  'T22349D Burn of third degree of unspecified axilla, subsequent encounter',
  'T22349S Burn of third degree of unspecified axilla, sequela',
  'T22351A Burn of third degree of right shoulder, initial encounter',
  'T22351D Burn of third degree of right shoulder, subsequent encounter',
  'T22351S Burn of third degree of right shoulder, sequela',
  'T22352A Burn of third degree of left shoulder, initial encounter',
  'T22352D Burn of third degree of left shoulder, subsequent encounter',
  'T22352S Burn of third degree of left shoulder, sequela',
  'T22359A Burn of third degree of unspecified shoulder, initial encounter',
  'T22359D Burn of third degree of unspecified shoulder, subsequent encounter',
  'T22359S Burn of third degree of unspecified shoulder, sequela',
  'T22361A Burn of third degree of right scapular region, initial encounter',
  'T22361D Burn of third degree of right scapular region, subsequent encounter',
  'T22361S Burn of third degree of right scapular region, sequela',
  'T22362A Burn of third degree of left scapular region, initial encounter',
  'T22362D Burn of third degree of left scapular region, subsequent encounter',
  'T22362S Burn of third degree of left scapular region, sequela',
  'T22369A Burn of third degree of unspecified scapular region, initial encounter',
  'T22369D Burn of third degree of unspecified scapular region, subsequent encounter',
  'T22369S Burn of third degree of unspecified scapular region, sequela',
  'T22391A Burn of third degree of multiple sites of right shoulder and upper limb, except wrist and hand,',
  'initial encounter',
  'T22391D Burn of third degree of multiple sites of right shoulder and upper limb, except wrist and hand,',
  'subsequent encounter',
  'T22391S Burn of third degree of multiple sites of right shoulder and upper limb, except wrist and hand,',
  'sequela',
  'T22392A Burn of third degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'initial encounter',
  'T22392D Burn of third degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'subsequent encounter',
  'T22392S Burn of third degree of multiple sites of left shoulder and upper limb, except wrist and hand,',
  'sequela',
  'T22399A Burn of third degree of multiple sites of unspecified shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22399D Burn of third degree of multiple sites of unspecified shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22399S Burn of third degree of multiple sites of unspecified shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T2240XA Corrosion of unspecified degree of shoulder and upper limb, except wrist and hand,',
  'unspecified site, initial encounter',
  'T2240XD Corrosion of unspecified degree of shoulder and upper limb, except wrist and hand,',
  'unspecified site, subsequent encounter',
  'T2240XS Corrosion of unspecified degree of shoulder and upper limb, except wrist and hand,',
  'unspecified site, sequela',
  'T22411A Corrosion of unspecified degree of right forearm, initial encounter',
  'T22411D Corrosion of unspecified degree of right forearm, subsequent encounter',
  'T22411S Corrosion of unspecified degree of right forearm, sequela',
  'T22412A Corrosion of unspecified degree of left forearm, initial encounter',
  'T22412D Corrosion of unspecified degree of left forearm, subsequent encounter',
  'T22412S Corrosion of unspecified degree of left forearm, sequela',
  'T22419A Corrosion of unspecified degree of unspecified forearm, initial encounter',
  'T22419D Corrosion of unspecified degree of unspecified forearm, subsequent encounter',
  'T22419S Corrosion of unspecified degree of unspecified forearm, sequela',
  'T22421A Corrosion of unspecified degree of right elbow, initial encounter',
  'T22421D Corrosion of unspecified degree of right elbow, subsequent encounter',
  'T22421S Corrosion of unspecified degree of right elbow, sequela',
  'T22422A Corrosion of unspecified degree of left elbow, initial encounter',
  'T22422D Corrosion of unspecified degree of left elbow, subsequent encounter',
  'T22422S Corrosion of unspecified degree of left elbow, sequela',
  'T22429A Corrosion of unspecified degree of unspecified elbow, initial encounter',
  'T22429D Corrosion of unspecified degree of unspecified elbow, subsequent encounter',
  'T22429S Corrosion of unspecified degree of unspecified elbow, sequela',
  'T22431A Corrosion of unspecified degree of right upper arm, initial encounter',
  'T22431D Corrosion of unspecified degree of right upper arm, subsequent encounter',
  'T22431S Corrosion of unspecified degree of right upper arm, sequela',
  'T22432A Corrosion of unspecified degree of left upper arm, initial encounter',
  'T22432D Corrosion of unspecified degree of left upper arm, subsequent encounter',
  'T22432S Corrosion of unspecified degree of left upper arm, sequela',
  'T22439A Corrosion of unspecified degree of unspecified upper arm, initial encounter',
  'T22439D Corrosion of unspecified degree of unspecified upper arm, subsequent encounter',
  'T22439S Corrosion of unspecified degree of unspecified upper arm, sequela',
  'T22441A Corrosion of unspecified degree of right axilla, initial encounter',
  'T22441D Corrosion of unspecified degree of right axilla, subsequent encounter',
  'T22441S Corrosion of unspecified degree of right axilla, sequela',
  'T22442A Corrosion of unspecified degree of left axilla, initial encounter',
  'T22442D Corrosion of unspecified degree of left axilla, subsequent encounter',
  'T22442S Corrosion of unspecified degree of left axilla, sequela',
  'T22449A Corrosion of unspecified degree of unspecified axilla, initial encounter',
  'T22449D Corrosion of unspecified degree of unspecified axilla, subsequent encounter',
  'T22449S Corrosion of unspecified degree of unspecified axilla, sequela',
  'T22451A Corrosion of unspecified degree of right shoulder, initial encounter',
  'T22451D Corrosion of unspecified degree of right shoulder, subsequent encounter',
  'T22451S Corrosion of unspecified degree of right shoulder, sequela',
  'T22452A Corrosion of unspecified degree of left shoulder, initial encounter',
  'T22452D Corrosion of unspecified degree of left shoulder, subsequent encounter',
  'T22452S Corrosion of unspecified degree of left shoulder, sequela',
  'T22459A Corrosion of unspecified degree of unspecified shoulder, initial encounter',
  'T22459D Corrosion of unspecified degree of unspecified shoulder, subsequent encounter',
  'T22459S Corrosion of unspecified degree of unspecified shoulder, sequela',
  'T22461A Corrosion of unspecified degree of right scapular region, initial encounter',
  'T22461D Corrosion of unspecified degree of right scapular region, subsequent encounter',
  'T22461S Corrosion of unspecified degree of right scapular region, sequela',
  'T22462A Corrosion of unspecified degree of left scapular region, initial encounter',
  'T22462D Corrosion of unspecified degree of left scapular region, subsequent encounter',
  'T22462S Corrosion of unspecified degree of left scapular region, sequela',
  'T22469A Corrosion of unspecified degree of unspecified scapular region, initial encounter',
  'T22469D Corrosion of unspecified degree of unspecified scapular region, subsequent encounter',
  'T22469S Corrosion of unspecified degree of unspecified scapular region, sequela',
  'T22491A Corrosion of unspecified degree of multiple sites of right shoulder and upper limb, except wrist',
  'and hand, initial encounter',
  'T22491D Corrosion of unspecified degree of multiple sites of right shoulder and upper limb, except wrist',
  'and hand, subsequent encounter',
  'T22491S Corrosion of unspecified degree of multiple sites of right shoulder and upper limb, except wrist',
  'and hand, sequela',
  'T22492A Corrosion of unspecified degree of multiple sites of left shoulder and upper limb, except wrist',
  'and hand, initial encounter',
  'T22492D Corrosion of unspecified degree of multiple sites of left shoulder and upper limb, except wrist',
  'and hand, subsequent encounter',
  'T22492S Corrosion of unspecified degree of multiple sites of left shoulder and upper limb, except wrist',
  'and hand, sequela',
  'T22499A Corrosion of unspecified degree of multiple sites of unspecified shoulder and upper limb,',
  'except wrist and hand, initial encounter',
  'T22499D Corrosion of unspecified degree of multiple sites of unspecified shoulder and upper limb,',
  'except wrist and hand, subsequent encounter',
  'T22499S Corrosion of unspecified degree of multiple sites of unspecified shoulder and upper limb,',
  'except wrist and hand, sequela',
  'T2250XA Corrosion of first degree of shoulder and upper limb, except wrist and hand unspecified site,',
  'initial encounter',
  'T2250XD Corrosion of first degree of shoulder and upper limb, except wrist and hand unspecified site,',
  'subsequent encounter',
  'T2250XS Corrosion of first degree of shoulder and upper limb, except wrist and hand unspecified site,',
  'sequela',
  'T22511A Corrosion of first degree of right forearm, initial encounter',
  'T22511D Corrosion of first degree of right forearm, subsequent encounter',
  'T22511S Corrosion of first degree of right forearm, sequela',
  'T22512A Corrosion of first degree of left forearm, initial encounter',
  'T22512D Corrosion of first degree of left forearm, subsequent encounter',
  'T22512S Corrosion of first degree of left forearm, sequela',
  'T22519A Corrosion of first degree of unspecified forearm, initial encounter',
  'T22519D Corrosion of first degree of unspecified forearm, subsequent encounter',
  'T22519S Corrosion of first degree of unspecified forearm, sequela',
  'T22521A Corrosion of first degree of right elbow, initial encounter',
  'T22521D Corrosion of first degree of right elbow, subsequent encounter',
  'T22521S Corrosion of first degree of right elbow, sequela',
  'T22522A Corrosion of first degree of left elbow, initial encounter',
  'T22522D Corrosion of first degree of left elbow, subsequent encounter',
  'T22522S Corrosion of first degree of left elbow, sequela',
  'T22529A Corrosion of first degree of unspecified elbow, initial encounter',
  'T22529D Corrosion of first degree of unspecified elbow, subsequent encounter',
  'T22529S Corrosion of first degree of unspecified elbow, sequela',
  'T22531A Corrosion of first degree of right upper arm, initial encounter',
  'T22531D Corrosion of first degree of right upper arm, subsequent encounter',
  'T22531S Corrosion of first degree of right upper arm, sequela',
  'T22532A Corrosion of first degree of left upper arm, initial encounter',
  'T22532D Corrosion of first degree of left upper arm, subsequent encounter',
  'T22532S Corrosion of first degree of left upper arm, sequela',
  'T22539A Corrosion of first degree of unspecified upper arm, initial encounter',
  'T22539D Corrosion of first degree of unspecified upper arm, subsequent encounter',
  'T22539S Corrosion of first degree of unspecified upper arm, sequela',
  'T22541A Corrosion of first degree of right axilla, initial encounter',
  'T22541D Corrosion of first degree of right axilla, subsequent encounter',
  'T22541S Corrosion of first degree of right axilla, sequela',
  'T22542A Corrosion of first degree of left axilla, initial encounter',
  'T22542D Corrosion of first degree of left axilla, subsequent encounter',
  'T22542S Corrosion of first degree of left axilla, sequela',
  'T22549A Corrosion of first degree of unspecified axilla, initial encounter',
  'T22549D Corrosion of first degree of unspecified axilla, subsequent encounter',
  'T22549S Corrosion of first degree of unspecified axilla, sequela',
  'T22551A Corrosion of first degree of right shoulder, initial encounter',
  'T22551D Corrosion of first degree of right shoulder, subsequent encounter',
  'T22551S Corrosion of first degree of right shoulder, sequela',
  'T22552A Corrosion of first degree of left shoulder, initial encounter',
  'T22552D Corrosion of first degree of left shoulder, subsequent encounter',
  'T22552S Corrosion of first degree of left shoulder, sequela',
  'T22559A Corrosion of first degree of unspecified shoulder, initial encounter',
  'T22559D Corrosion of first degree of unspecified shoulder, subsequent encounter',
  'T22559S Corrosion of first degree of unspecified shoulder, sequela',
  'T22561A Corrosion of first degree of right scapular region, initial encounter',
  'T22561D Corrosion of first degree of right scapular region, subsequent encounter',
  'T22561S Corrosion of first degree of right scapular region, sequela',
  'T22562A Corrosion of first degree of left scapular region, initial encounter',
  'T22562D Corrosion of first degree of left scapular region, subsequent encounter',
  'T22562S Corrosion of first degree of left scapular region, sequela',
  'T22569A Corrosion of first degree of unspecified scapular region, initial encounter',
  'T22569D Corrosion of first degree of unspecified scapular region, subsequent encounter',
  'T22569S Corrosion of first degree of unspecified scapular region, sequela',
  'T22591A Corrosion of first degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22591D Corrosion of first degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22591S Corrosion of first degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22592A Corrosion of first degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22592D Corrosion of first degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22592S Corrosion of first degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22599A Corrosion of first degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, initial encounter',
  'T22599D Corrosion of first degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, subsequent encounter',
  'T22599S Corrosion of first degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, sequela',
  'T2260XA Corrosion of second degree of shoulder and upper limb, except wrist and hand, unspecified',
  'site, initial encounter',
  'T2260XD Corrosion of second degree of shoulder and upper limb, except wrist and hand, unspecified',
  'site, subsequent encounter',
  'T2260XS Corrosion of second degree of shoulder and upper limb, except wrist and hand, unspecified',
  'site, sequela',
  'T22611A Corrosion of second degree of right forearm, initial encounter',
  'T22611D Corrosion of second degree of right forearm, subsequent encounter',
  'T22611S Corrosion of second degree of right forearm, sequela',
  'T22612A Corrosion of second degree of left forearm, initial encounter',
  'T22612D Corrosion of second degree of left forearm, subsequent encounter',
  'T22612S Corrosion of second degree of left forearm, sequela',
  'T22619A Corrosion of second degree of unspecified forearm, initial encounter',
  'T22619D Corrosion of second degree of unspecified forearm, subsequent encounter',
  'T22619S Corrosion of second degree of unspecified forearm, sequela',
  'T22621A Corrosion of second degree of right elbow, initial encounter',
  'T22621D Corrosion of second degree of right elbow, subsequent encounter',
  'T22621S Corrosion of second degree of right elbow, sequela',
  'T22622A Corrosion of second degree of left elbow, initial encounter',
  'T22622D Corrosion of second degree of left elbow, subsequent encounter',
  'T22622S Corrosion of second degree of left elbow, sequela',
  'T22629A Corrosion of second degree of unspecified elbow, initial encounter',
  'T22629D Corrosion of second degree of unspecified elbow, subsequent encounter',
  'T22629S Corrosion of second degree of unspecified elbow, sequela',
  'T22631A Corrosion of second degree of right upper arm, initial encounter',
  'T22631D Corrosion of second degree of right upper arm, subsequent encounter',
  'T22631S Corrosion of second degree of right upper arm, sequela',
  'T22632A Corrosion of second degree of left upper arm, initial encounter',
  'T22632D Corrosion of second degree of left upper arm, subsequent encounter',
  'T22632S Corrosion of second degree of left upper arm, sequela',
  'T22639A Corrosion of second degree of unspecified upper arm, initial encounter',
  'T22639D Corrosion of second degree of unspecified upper arm, subsequent encounter',
  'T22639S Corrosion of second degree of unspecified upper arm, sequela',
  'T22641A Corrosion of second degree of right axilla, initial encounter',
  'T22641D Corrosion of second degree of right axilla, subsequent encounter',
  'T22641S Corrosion of second degree of right axilla, sequela',
  'T22642A Corrosion of second degree of left axilla, initial encounter',
  'T22642D Corrosion of second degree of left axilla, subsequent encounter',
  'T22642S Corrosion of second degree of left axilla, sequela',
  'T22649A Corrosion of second degree of unspecified axilla, initial encounter',
  'T22649D Corrosion of second degree of unspecified axilla, subsequent encounter',
  'T22649S Corrosion of second degree of unspecified axilla, sequela',
  'T22651A Corrosion of second degree of right shoulder, initial encounter',
  'T22651D Corrosion of second degree of right shoulder, subsequent encounter',
  'T22651S Corrosion of second degree of right shoulder, sequela',
  'T22652A Corrosion of second degree of left shoulder, initial encounter',
  'T22652D Corrosion of second degree of left shoulder, subsequent encounter',
  'T22652S Corrosion of second degree of left shoulder, sequela',
  'T22659A Corrosion of second degree of unspecified shoulder, initial encounter',
  'T22659D Corrosion of second degree of unspecified shoulder, subsequent encounter',
  'T22659S Corrosion of second degree of unspecified shoulder, sequela',
  'T22661A Corrosion of second degree of right scapular region, initial encounter',
  'T22661D Corrosion of second degree of right scapular region, subsequent encounter',
  'T22661S Corrosion of second degree of right scapular region, sequela',
  'T22662A Corrosion of second degree of left scapular region, initial encounter',
  'T22662D Corrosion of second degree of left scapular region, subsequent encounter',
  'T22662S Corrosion of second degree of left scapular region, sequela',
  'T22669A Corrosion of second degree of unspecified scapular region, initial encounter',
  'T22669D Corrosion of second degree of unspecified scapular region, subsequent encounter',
  'T22669S Corrosion of second degree of unspecified scapular region, sequela',
  'T22691A Corrosion of second degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22691D Corrosion of second degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22691S Corrosion of second degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22692A Corrosion of second degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22692D Corrosion of second degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22692S Corrosion of second degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22699A Corrosion of second degree of multiple sites of unspecified shoulder and upper limb, except',
  'wrist and hand, initial encounter',
  'T22699D Corrosion of second degree of multiple sites of unspecified shoulder and upper limb, except',
  'wrist and hand, subsequent encounter',
  'T22699S Corrosion of second degree of multiple sites of unspecified shoulder and upper limb, except',
  'wrist and hand, sequela',
  'T2270XA Corrosion of third degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'initial encounter',
  'T2270XD Corrosion of third degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'subsequent encounter',
  'T2270XS Corrosion of third degree of shoulder and upper limb, except wrist and hand, unspecified site,',
  'sequela',
  'T22711A Corrosion of third degree of right forearm, initial encounter',
  'T22711D Corrosion of third degree of right forearm, subsequent encounter',
  'T22711S Corrosion of third degree of right forearm, sequela',
  'T22712A Corrosion of third degree of left forearm, initial encounter',
  'T22712D Corrosion of third degree of left forearm, subsequent encounter',
  'T22712S Corrosion of third degree of left forearm, sequela',
  'T22719A Corrosion of third degree of unspecified forearm, initial encounter',
  'T22719D Corrosion of third degree of unspecified forearm, subsequent encounter',
  'T22719S Corrosion of third degree of unspecified forearm, sequela',
  'T22721A Corrosion of third degree of right elbow, initial encounter',
  'T22721D Corrosion of third degree of right elbow, subsequent encounter',
  'T22721S Corrosion of third degree of right elbow, sequela',
  'T22722A Corrosion of third degree of left elbow, initial encounter',
  'T22722D Corrosion of third degree of left elbow, subsequent encounter',
  'T22722S Corrosion of third degree of left elbow, sequela',
  'T22729A Corrosion of third degree of unspecified elbow, initial encounter',
  'T22729D Corrosion of third degree of unspecified elbow, subsequent encounter',
  'T22729S Corrosion of third degree of unspecified elbow, sequela',
  'T22731A Corrosion of third degree of right upper arm, initial encounter',
  'T22731D Corrosion of third degree of right upper arm, subsequent encounter',
  'T22731S Corrosion of third degree of right upper arm, sequela',
  'T22732A Corrosion of third degree of left upper arm, initial encounter',
  'T22732D Corrosion of third degree of left upper arm, subsequent encounter',
  'T22732S Corrosion of third degree of left upper arm, sequela',
  'T22739A Corrosion of third degree of unspecified upper arm, initial encounter',
  'T22739D Corrosion of third degree of unspecified upper arm, subsequent encounter',
  'T22739S Corrosion of third degree of unspecified upper arm, sequela',
  'T22741A Corrosion of third degree of right axilla, initial encounter',
  'T22741D Corrosion of third degree of right axilla, subsequent encounter',
  'T22741S Corrosion of third degree of right axilla, sequela',
  'T22742A Corrosion of third degree of left axilla, initial encounter',
  'T22742D Corrosion of third degree of left axilla, subsequent encounter',
  'T22742S Corrosion of third degree of left axilla, sequela',
  'T22749A Corrosion of third degree of unspecified axilla, initial encounter',
  'T22749D Corrosion of third degree of unspecified axilla, subsequent encounter',
  'T22749S Corrosion of third degree of unspecified axilla, sequela',
  'T22751A Corrosion of third degree of right shoulder, initial encounter',
  'T22751D Corrosion of third degree of right shoulder, subsequent encounter',
  'T22751S Corrosion of third degree of right shoulder, sequela',
  'T22752A Corrosion of third degree of left shoulder, initial encounter',
  'T22752D Corrosion of third degree of left shoulder, subsequent encounter',
  'T22752S Corrosion of third degree of left shoulder, sequela',
  'T22759A Corrosion of third degree of unspecified shoulder, initial encounter',
  'T22759D Corrosion of third degree of unspecified shoulder, subsequent encounter',
  'T22759S Corrosion of third degree of unspecified shoulder, sequela',
  'T22761A Corrosion of third degree of right scapular region, initial encounter',
  'T22761D Corrosion of third degree of right scapular region, subsequent encounter',
  'T22761S Corrosion of third degree of right scapular region, sequela',
  'T22762A Corrosion of third degree of left scapular region, initial encounter',
  'T22762D Corrosion of third degree of left scapular region, subsequent encounter',
  'T22762S Corrosion of third degree of left scapular region, sequela',
  'T22769A Corrosion of third degree of unspecified scapular region, initial encounter',
  'T22769D Corrosion of third degree of unspecified scapular region, subsequent encounter',
  'T22769S Corrosion of third degree of unspecified scapular region, sequela',
  'T22791A Corrosion of third degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22791D Corrosion of third degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22791S Corrosion of third degree of multiple sites of right shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22792A Corrosion of third degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, initial encounter',
  'T22792D Corrosion of third degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, subsequent encounter',
  'T22792S Corrosion of third degree of multiple sites of left shoulder and upper limb, except wrist and',
  'hand, sequela',
  'T22799A Corrosion of third degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, initial encounter',
  'T22799D Corrosion of third degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, subsequent encounter',
  'T22799S Corrosion of third degree of multiple sites of unspecified shoulder and upper limb, except wrist',
  'and hand, sequela',
  'T23001A Burn of unspecified degree of right hand, unspecified site, initial encounter',
  'T23001D Burn of unspecified degree of right hand, unspecified site, subsequent encounter',
  'T23001S Burn of unspecified degree of right hand, unspecified site, sequela',
  'T23002A Burn of unspecified degree of left hand, unspecified site, initial encounter',
  'T23002D Burn of unspecified degree of left hand, unspecified site, subsequent encounter',
  'T23002S Burn of unspecified degree of left hand, unspecified site, sequela',
  'T23009A Burn of unspecified degree of unspecified hand, unspecified site, initial encounter',
  'T23009D Burn of unspecified degree of unspecified hand, unspecified site, subsequent encounter',
  'T23009S Burn of unspecified degree of unspecified hand, unspecified site, sequela',
  'T23011A Burn of unspecified degree of right thumb (nail), initial encounter',
  'T23011D Burn of unspecified degree of right thumb (nail), subsequent encounter',
  'T23011S Burn of unspecified degree of right thumb (nail), sequela',
  'T23012A Burn of unspecified degree of left thumb (nail), initial encounter',
  'T23012D Burn of unspecified degree of left thumb (nail), subsequent encounter',
  'T23012S Burn of unspecified degree of left thumb (nail), sequela',
  'T23019A Burn of unspecified degree of unspecified thumb (nail), initial encounter',
  'T23019D Burn of unspecified degree of unspecified thumb (nail), subsequent encounter',
  'T23019S Burn of unspecified degree of unspecified thumb (nail), sequela',
  'T23021A Burn of unspecified degree of single right finger (nail) except thumb, initial encounter',
  'T23021D Burn of unspecified degree of single right finger (nail) except thumb, subsequent encounter',
  'T23021S Burn of unspecified degree of single right finger (nail) except thumb, sequela',
  'T23022A Burn of unspecified degree of single left finger (nail) except thumb, initial encounter',
  'T23022D Burn of unspecified degree of single left finger (nail) except thumb, subsequent encounter',
  'T23022S Burn of unspecified degree of single left finger (nail) except thumb, sequela',
  'T23029A Burn of unspecified degree of unspecified single finger (nail) except thumb, initial encounter',
  'T23029D Burn of unspecified degree of unspecified single finger (nail) except thumb, subsequent',
  'encounter',
  'T23029S Burn of unspecified degree of unspecified single finger (nail) except thumb, sequela',
  'T23031A Burn of unspecified degree of multiple right fingers (nail), not including thumb, initial',
  'encounter',
  'T23031D Burn of unspecified degree of multiple right fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23031S Burn of unspecified degree of multiple right fingers (nail), not including thumb, sequela',
  'T23032A Burn of unspecified degree of multiple left fingers (nail), not including thumb, initial encounter',
  'T23032D Burn of unspecified degree of multiple left fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23032S Burn of unspecified degree of multiple left fingers (nail), not including thumb, sequela',
  'T23039A Burn of unspecified degree of unspecified multiple fingers (nail), not including thumb, initial',
  'encounter',
  'T23039D Burn of unspecified degree of unspecified multiple fingers (nail), not including thumb,',
  'subsequent encounter',
  'T23039S Burn of unspecified degree of unspecified multiple fingers (nail), not including thumb, sequela',
  'T23041A Burn of unspecified degree of multiple right fingers (nail), including thumb, initial encounter',
  'T23041D Burn of unspecified degree of multiple right fingers (nail), including thumb, subsequent',
  'encounter',
  'T23041S Burn of unspecified degree of multiple right fingers (nail), including thumb, sequela',
  'T23042A Burn of unspecified degree of multiple left fingers (nail), including thumb, initial encounter',
  'T23042D Burn of unspecified degree of multiple left fingers (nail), including thumb, subsequent',
  'encounter',
  'T23042S Burn of unspecified degree of multiple left fingers (nail), including thumb, sequela',
  'T23049A Burn of unspecified degree of unspecified multiple fingers (nail), including thumb, initial',
  'encounter',
  'T23049D Burn of unspecified degree of unspecified multiple fingers (nail), including thumb, subsequent',
  'encounter',
  'T23049S Burn of unspecified degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23051A Burn of unspecified degree of right palm, initial encounter',
  'T23051D Burn of unspecified degree of right palm, subsequent encounter',
  'T23051S Burn of unspecified degree of right palm, sequela',
  'T23052A Burn of unspecified degree of left palm, initial encounter',
  'T23052D Burn of unspecified degree of left palm, subsequent encounter',
  'T23052S Burn of unspecified degree of left palm, sequela',
  'T23059A Burn of unspecified degree of unspecified palm, initial encounter',
  'T23059D Burn of unspecified degree of unspecified palm, subsequent encounter',
  'T23059S Burn of unspecified degree of unspecified palm, sequela',
  'T23061A Burn of unspecified degree of back of right hand, initial encounter',
  'T23061D Burn of unspecified degree of back of right hand, subsequent encounter',
  'T23061S Burn of unspecified degree of back of right hand, sequela',
  'T23062A Burn of unspecified degree of back of left hand, initial encounter',
  'T23062D Burn of unspecified degree of back of left hand, subsequent encounter',
  'T23062S Burn of unspecified degree of back of left hand, sequela',
  'T23069A Burn of unspecified degree of back of unspecified hand, initial encounter',
  'T23069D Burn of unspecified degree of back of unspecified hand, subsequent encounter',
  'T23069S Burn of unspecified degree of back of unspecified hand, sequela',
  'T23071A Burn of unspecified degree of right wrist, initial encounter',
  'T23071D Burn of unspecified degree of right wrist, subsequent encounter',
  'T23071S Burn of unspecified degree of right wrist, sequela',
  'T23072A Burn of unspecified degree of left wrist, initial encounter',
  'T23072D Burn of unspecified degree of left wrist, subsequent encounter',
  'T23072S Burn of unspecified degree of left wrist, sequela',
  'T23079A Burn of unspecified degree of unspecified wrist, initial encounter',
  'T23079D Burn of unspecified degree of unspecified wrist, subsequent encounter',
  'T23079S Burn of unspecified degree of unspecified wrist, sequela',
  'T23091A Burn of unspecified degree of multiple sites of right wrist and hand, initial encounter',
  'T23091D Burn of unspecified degree of multiple sites of right wrist and hand, subsequent encounter',
  'T23091S Burn of unspecified degree of multiple sites of right wrist and hand, sequela',
  'T23092A Burn of unspecified degree of multiple sites of left wrist and hand, initial encounter',
  'T23092D Burn of unspecified degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23092S Burn of unspecified degree of multiple sites of left wrist and hand, sequela',
  'T23099A Burn of unspecified degree of multiple sites of unspecified wrist and hand, initial encounter',
  'T23099D Burn of unspecified degree of multiple sites of unspecified wrist and hand, subsequent',
  'encounter',
  'T23099S Burn of unspecified degree of multiple sites of unspecified wrist and hand, sequela',
  'T23101A Burn of first degree of right hand, unspecified site, initial encounter',
  'T23101D Burn of first degree of right hand, unspecified site, subsequent encounter',
  'T23101S Burn of first degree of right hand, unspecified site, sequela',
  'T23102A Burn of first degree of left hand, unspecified site, initial encounter',
  'T23102D Burn of first degree of left hand, unspecified site, subsequent encounter',
  'T23102S Burn of first degree of left hand, unspecified site, sequela',
  'T23109A Burn of first degree of unspecified hand, unspecified site, initial encounter',
  'T23109D Burn of first degree of unspecified hand, unspecified site, subsequent encounter',
  'T23109S Burn of first degree of unspecified hand, unspecified site, sequela',
  'T23111A Burn of first degree of right thumb (nail), initial encounter',
  'T23111D Burn of first degree of right thumb (nail), subsequent encounter',
  'T23111S Burn of first degree of right thumb (nail), sequela',
  'T23112A Burn of first degree of left thumb (nail), initial encounter',
  'T23112D Burn of first degree of left thumb (nail), subsequent encounter',
  'T23112S Burn of first degree of left thumb (nail), sequela',
  'T23119A Burn of first degree of unspecified thumb (nail), initial encounter',
  'T23119D Burn of first degree of unspecified thumb (nail), subsequent encounter',
  'T23119S Burn of first degree of unspecified thumb (nail), sequela',
  'T23121A Burn of first degree of single right finger (nail) except thumb, initial encounter',
  'T23121D Burn of first degree of single right finger (nail) except thumb, subsequent encounter',
  'T23121S Burn of first degree of single right finger (nail) except thumb, sequela',
  'T23122A Burn of first degree of single left finger (nail) except thumb, initial encounter',
  'T23122D Burn of first degree of single left finger (nail) except thumb, subsequent encounter',
  'T23122S Burn of first degree of single left finger (nail) except thumb, sequela',
  'T23129A Burn of first degree of unspecified single finger (nail) except thumb, initial encounter',
  'T23129D Burn of first degree of unspecified single finger (nail) except thumb, subsequent encounter',
  'T23129S Burn of first degree of unspecified single finger (nail) except thumb, sequela',
  'T23131A Burn of first degree of multiple right fingers (nail), not including thumb, initial encounter',
  'T23131D Burn of first degree of multiple right fingers (nail), not including thumb, subsequent encounter',
  'T23131S Burn of first degree of multiple right fingers (nail), not including thumb, sequela',
  'T23132A Burn of first degree of multiple left fingers (nail), not including thumb, initial encounter',
  'T23132D Burn of first degree of multiple left fingers (nail), not including thumb, subsequent encounter',
  'T23132S Burn of first degree of multiple left fingers (nail), not including thumb, sequela',
  'T23139A Burn of first degree of unspecified multiple fingers (nail), not including thumb, initial encounter',
  'T23139D Burn of first degree of unspecified multiple fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23139S Burn of first degree of unspecified multiple fingers (nail), not including thumb, sequela',
  'T23141A Burn of first degree of multiple right fingers (nail), including thumb, initial encounter',
  'T23141D Burn of first degree of multiple right fingers (nail), including thumb, subsequent encounter',
  'T23141S Burn of first degree of multiple right fingers (nail), including thumb, sequela',
  'T23142A Burn of first degree of multiple left fingers (nail), including thumb, initial encounter',
  'T23142D Burn of first degree of multiple left fingers (nail), including thumb, subsequent encounter',
  'T23142S Burn of first degree of multiple left fingers (nail), including thumb, sequela',
  'T23149A Burn of first degree of unspecified multiple fingers (nail), including thumb, initial encounter',
  'T23149D Burn of first degree of unspecified multiple fingers (nail), including thumb, subsequent',
  'encounter',
  'T23149S Burn of first degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23151A Burn of first degree of right palm, initial encounter',
  'T23151D Burn of first degree of right palm, subsequent encounter',
  'T23151S Burn of first degree of right palm, sequela',
  'T23152A Burn of first degree of left palm, initial encounter',
  'T23152D Burn of first degree of left palm, subsequent encounter',
  'T23152S Burn of first degree of left palm, sequela',
  'T23159A Burn of first degree of unspecified palm, initial encounter',
  'T23159D Burn of first degree of unspecified palm, subsequent encounter',
  'T23159S Burn of first degree of unspecified palm, sequela',
  'T23161A Burn of first degree of back of right hand, initial encounter',
  'T23161D Burn of first degree of back of right hand, subsequent encounter',
  'T23161S Burn of first degree of back of right hand, sequela',
  'T23162A Burn of first degree of back of left hand, initial encounter',
  'T23162D Burn of first degree of back of left hand, subsequent encounter',
  'T23162S Burn of first degree of back of left hand, sequela',
  'T23169A Burn of first degree of back of unspecified hand, initial encounter',
  'T23169D Burn of first degree of back of unspecified hand, subsequent encounter',
  'T23169S Burn of first degree of back of unspecified hand, sequela',
  'T23171A Burn of first degree of right wrist, initial encounter',
  'T23171D Burn of first degree of right wrist, subsequent encounter',
  'T23171S Burn of first degree of right wrist, sequela',
  'T23172A Burn of first degree of left wrist, initial encounter',
  'T23172D Burn of first degree of left wrist, subsequent encounter',
  'T23172S Burn of first degree of left wrist, sequela',
  'T23179A Burn of first degree of unspecified wrist, initial encounter',
  'T23179D Burn of first degree of unspecified wrist, subsequent encounter',
  'T23179S Burn of first degree of unspecified wrist, sequela',
  'T23191A Burn of first degree of multiple sites of right wrist and hand, initial encounter',
  'T23191D Burn of first degree of multiple sites of right wrist and hand, subsequent encounter',
  'T23191S Burn of first degree of multiple sites of right wrist and hand, sequela',
  'T23192A Burn of first degree of multiple sites of left wrist and hand, initial encounter',
  'T23192D Burn of first degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23192S Burn of first degree of multiple sites of left wrist and hand, sequela',
  'T23199A Burn of first degree of multiple sites of unspecified wrist and hand, initial encounter',
  'T23199D Burn of first degree of multiple sites of unspecified wrist and hand, subsequent encounter',
  'T23199S Burn of first degree of multiple sites of unspecified wrist and hand, sequela',
  'T23201A Burn of second degree of right hand, unspecified site, initial encounter',
  'T23201D Burn of second degree of right hand, unspecified site, subsequent encounter',
  'T23201S Burn of second degree of right hand, unspecified site, sequela',
  'T23202A Burn of second degree of left hand, unspecified site, initial encounter',
  'T23202D Burn of second degree of left hand, unspecified site, subsequent encounter',
  'T23202S Burn of second degree of left hand, unspecified site, sequela',
  'T23209A Burn of second degree of unspecified hand, unspecified site, initial encounter',
  'T23209D Burn of second degree of unspecified hand, unspecified site, subsequent encounter',
  'T23209S Burn of second degree of unspecified hand, unspecified site, sequela',
  'T23211A Burn of second degree of right thumb (nail), initial encounter',
  'T23211D Burn of second degree of right thumb (nail), subsequent encounter',
  'T23211S Burn of second degree of right thumb (nail), sequela',
  'T23212A Burn of second degree of left thumb (nail), initial encounter',
  'T23212D Burn of second degree of left thumb (nail), subsequent encounter',
  'T23212S Burn of second degree of left thumb (nail), sequela',
  'T23219A Burn of second degree of unspecified thumb (nail), initial encounter',
  'T23219D Burn of second degree of unspecified thumb (nail), subsequent encounter',
  'T23219S Burn of second degree of unspecified thumb (nail), sequela',
  'T23221A Burn of second degree of single right finger (nail) except thumb, initial encounter',
  'T23221D Burn of second degree of single right finger (nail) except thumb, subsequent encounter',
  'T23221S Burn of second degree of single right finger (nail) except thumb, sequela',
  'T23222A Burn of second degree of single left finger (nail) except thumb, initial encounter',
  'T23222D Burn of second degree of single left finger (nail) except thumb, subsequent encounter',
  'T23222S Burn of second degree of single left finger (nail) except thumb, sequela',
  'T23229A Burn of second degree of unspecified single finger (nail) except thumb, initial encounter',
  'T23229D Burn of second degree of unspecified single finger (nail) except thumb, subsequent encounter',
  'T23229S Burn of second degree of unspecified single finger (nail) except thumb, sequela',
  'T23231A Burn of second degree of multiple right fingers (nail), not including thumb, initial encounter',
  'T23231D Burn of second degree of multiple right fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23231S Burn of second degree of multiple right fingers (nail), not including thumb, sequela',
  'T23232A Burn of second degree of multiple left fingers (nail), not including thumb, initial encounter',
  'T23232D Burn of second degree of multiple left fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23232S Burn of second degree of multiple left fingers (nail), not including thumb, sequela',
  'T23239A Burn of second degree of unspecified multiple fingers (nail), not including thumb, initial',
  'encounter',
  'T23239D Burn of second degree of unspecified multiple fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23239S Burn of second degree of unspecified multiple fingers (nail), not including thumb, sequela',
  'T23241A Burn of second degree of multiple right fingers (nail), including thumb, initial encounter',
  'T23241D Burn of second degree of multiple right fingers (nail), including thumb, subsequent encounter',
  'T23241S Burn of second degree of multiple right fingers (nail), including thumb, sequela',
  'T23242A Burn of second degree of multiple left fingers (nail), including thumb, initial encounter',
  'T23242D Burn of second degree of multiple left fingers (nail), including thumb, subsequent encounter',
  'T23242S Burn of second degree of multiple left fingers (nail), including thumb, sequela',
  'T23249A Burn of second degree of unspecified multiple fingers (nail), including thumb, initial encounter',
  'T23249D Burn of second degree of unspecified multiple fingers (nail), including thumb, subsequent',
  'encounter',
  'T23249S Burn of second degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23251A Burn of second degree of right palm, initial encounter',
  'T23251D Burn of second degree of right palm, subsequent encounter',
  'T23251S Burn of second degree of right palm, sequela',
  'T23252A Burn of second degree of left palm, initial encounter',
  'T23252D Burn of second degree of left palm, subsequent encounter',
  'T23252S Burn of second degree of left palm, sequela',
  'T23259A Burn of second degree of unspecified palm, initial encounter',
  'T23259D Burn of second degree of unspecified palm, subsequent encounter',
  'T23259S Burn of second degree of unspecified palm, sequela',
  'T23261A Burn of second degree of back of right hand, initial encounter',
  'T23261D Burn of second degree of back of right hand, subsequent encounter',
  'T23261S Burn of second degree of back of right hand, sequela',
  'T23262A Burn of second degree of back of left hand, initial encounter',
  'T23262D Burn of second degree of back of left hand, subsequent encounter',
  'T23262S Burn of second degree of back of left hand, sequela',
  'T23269A Burn of second degree of back of unspecified hand, initial encounter',
  'T23269D Burn of second degree of back of unspecified hand, subsequent encounter',
  'T23269S Burn of second degree of back of unspecified hand, sequela',
  'T23271A Burn of second degree of right wrist, initial encounter',
  'T23271D Burn of second degree of right wrist, subsequent encounter',
  'T23271S Burn of second degree of right wrist, sequela',
  'T23272A Burn of second degree of left wrist, initial encounter',
  'T23272D Burn of second degree of left wrist, subsequent encounter',
  'T23272S Burn of second degree of left wrist, sequela',
  'T23279A Burn of second degree of unspecified wrist, initial encounter',
  'T23279D Burn of second degree of unspecified wrist, subsequent encounter',
  'T23279S Burn of second degree of unspecified wrist, sequela',
  'T23291A Burn of second degree of multiple sites of right wrist and hand, initial encounter',
  'T23291D Burn of second degree of multiple sites of right wrist and hand, subsequent encounter',
  'T23291S Burn of second degree of multiple sites of right wrist and hand, sequela',
  'T23292A Burn of second degree of multiple sites of left wrist and hand, initial encounter',
  'T23292D Burn of second degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23292S Burn of second degree of multiple sites of left wrist and hand, sequela',
  'T23299A Burn of second degree of multiple sites of unspecified wrist and hand, initial encounter',
  'T23299D Burn of second degree of multiple sites of unspecified wrist and hand, subsequent encounter',
  'T23299S Burn of second degree of multiple sites of unspecified wrist and hand, sequela',
  'T23301A Burn of third degree of right hand, unspecified site, initial encounter',
  'T23301D Burn of third degree of right hand, unspecified site, subsequent encounter',
  'T23301S Burn of third degree of right hand, unspecified site, sequela',
  'T23302A Burn of third degree of left hand, unspecified site, initial encounter',
  'T23302D Burn of third degree of left hand, unspecified site, subsequent encounter',
  'T23302S Burn of third degree of left hand, unspecified site, sequela',
  'T23309A Burn of third degree of unspecified hand, unspecified site, initial encounter',
  'T23309D Burn of third degree of unspecified hand, unspecified site, subsequent encounter',
  'T23309S Burn of third degree of unspecified hand, unspecified site, sequela',
  'T23311A Burn of third degree of right thumb (nail), initial encounter',
  'T23311D Burn of third degree of right thumb (nail), subsequent encounter',
  'T23311S Burn of third degree of right thumb (nail), sequela',
  'T23312A Burn of third degree of left thumb (nail), initial encounter',
  'T23312D Burn of third degree of left thumb (nail), subsequent encounter',
  'T23312S Burn of third degree of left thumb (nail), sequela',
  'T23319A Burn of third degree of unspecified thumb (nail), initial encounter',
  'T23319D Burn of third degree of unspecified thumb (nail), subsequent encounter',
  'T23319S Burn of third degree of unspecified thumb (nail), sequela',
  'T23321A Burn of third degree of single right finger (nail) except thumb, initial encounter',
  'T23321D Burn of third degree of single right finger (nail) except thumb, subsequent encounter',
  'T23321S Burn of third degree of single right finger (nail) except thumb, sequela',
  'T23322A Burn of third degree of single left finger (nail) except thumb, initial encounter',
  'T23322D Burn of third degree of single left finger (nail) except thumb, subsequent encounter',
  'T23322S Burn of third degree of single left finger (nail) except thumb, sequela',
  'T23329A Burn of third degree of unspecified single finger (nail) except thumb, initial encounter',
  'T23329D Burn of third degree of unspecified single finger (nail) except thumb, subsequent encounter',
  'T23329S Burn of third degree of unspecified single finger (nail) except thumb, sequela',
  'T23331A Burn of third degree of multiple right fingers (nail), not including thumb, initial encounter',
  'T23331D Burn of third degree of multiple right fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23331S Burn of third degree of multiple right fingers (nail), not including thumb, sequela',
  'T23332A Burn of third degree of multiple left fingers (nail), not including thumb, initial encounter',
  'T23332D Burn of third degree of multiple left fingers (nail), not including thumb, subsequent encounter',
  'T23332S Burn of third degree of multiple left fingers (nail), not including thumb, sequela',
  'T23339A Burn of third degree of unspecified multiple fingers (nail), not including thumb, initial',
  'encounter',
  'T23339D Burn of third degree of unspecified multiple fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23339S Burn of third degree of unspecified multiple fingers (nail), not including thumb, sequela',
  'T23341A Burn of third degree of multiple right fingers (nail), including thumb, initial encounter',
  'T23341D Burn of third degree of multiple right fingers (nail), including thumb, subsequent encounter',
  'T23341S Burn of third degree of multiple right fingers (nail), including thumb, sequela',
  'T23342A Burn of third degree of multiple left fingers (nail), including thumb, initial encounter',
  'T23342D Burn of third degree of multiple left fingers (nail), including thumb, subsequent encounter',
  'T23342S Burn of third degree of multiple left fingers (nail), including thumb, sequela',
  'T23349A Burn of third degree of unspecified multiple fingers (nail), including thumb, initial encounter',
  'T23349D Burn of third degree of unspecified multiple fingers (nail), including thumb, subsequent',
  'encounter',
  'T23349S Burn of third degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23351A Burn of third degree of right palm, initial encounter',
  'T23351D Burn of third degree of right palm, subsequent encounter',
  'T23351S Burn of third degree of right palm, sequela',
  'T23352A Burn of third degree of left palm, initial encounter',
  'T23352D Burn of third degree of left palm, subsequent encounter',
  'T23352S Burn of third degree of left palm, sequela',
  'T23359A Burn of third degree of unspecified palm, initial encounter',
  'T23359D Burn of third degree of unspecified palm, subsequent encounter',
  'T23359S Burn of third degree of unspecified palm, sequela',
  'T23361A Burn of third degree of back of right hand, initial encounter',
  'T23361D Burn of third degree of back of right hand, subsequent encounter',
  'T23361S Burn of third degree of back of right hand, sequela',
  'T23362A Burn of third degree of back of left hand, initial encounter',
  'T23362D Burn of third degree of back of left hand, subsequent encounter',
  'T23362S Burn of third degree of back of left hand, sequela',
  'T23369A Burn of third degree of back of unspecified hand, initial encounter',
  'T23369D Burn of third degree of back of unspecified hand, subsequent encounter',
  'T23369S Burn of third degree of back of unspecified hand, sequela',
  'T23371A Burn of third degree of right wrist, initial encounter',
  'T23371D Burn of third degree of right wrist, subsequent encounter',
  'T23371S Burn of third degree of right wrist, sequela',
  'T23372A Burn of third degree of left wrist, initial encounter',
  'T23372D Burn of third degree of left wrist, subsequent encounter',
  'T23372S Burn of third degree of left wrist, sequela',
  'T23379A Burn of third degree of unspecified wrist, initial encounter',
  'T23379D Burn of third degree of unspecified wrist, subsequent encounter',
  'T23379S Burn of third degree of unspecified wrist, sequela',
  'T23391A Burn of third degree of multiple sites of right wrist and hand, initial encounter',
  'T23391D Burn of third degree of multiple sites of right wrist and hand, subsequent encounter',
  'T23391S Burn of third degree of multiple sites of right wrist and hand, sequela',
  'T23392A Burn of third degree of multiple sites of left wrist and hand, initial encounter',
  'T23392D Burn of third degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23392S Burn of third degree of multiple sites of left wrist and hand, sequela',
  'T23399A Burn of third degree of multiple sites of unspecified wrist and hand, initial encounter',
  'T23399D Burn of third degree of multiple sites of unspecified wrist and hand, subsequent encounter',
  'T23399S Burn of third degree of multiple sites of unspecified wrist and hand, sequela',
  'T23401A Corrosion of unspecified degree of right hand, unspecified site, initial encounter',
  'T23401D Corrosion of unspecified degree of right hand, unspecified site, subsequent encounter',
  'T23401S Corrosion of unspecified degree of right hand, unspecified site, sequela',
  'T23402A Corrosion of unspecified degree of left hand, unspecified site, initial encounter',
  'T23402D Corrosion of unspecified degree of left hand, unspecified site, subsequent encounter',
  'T23402S Corrosion of unspecified degree of left hand, unspecified site, sequela',
  'T23409A Corrosion of unspecified degree of unspecified hand, unspecified site, initial encounter',
  'T23409D Corrosion of unspecified degree of unspecified hand, unspecified site, subsequent encounter',
  'T23409S Corrosion of unspecified degree of unspecified hand, unspecified site, sequela',
  'T23411A Corrosion of unspecified degree of right thumb (nail), initial encounter',
  'T23411D Corrosion of unspecified degree of right thumb (nail), subsequent encounter',
  'T23411S Corrosion of unspecified degree of right thumb (nail), sequela',
  'T23412A Corrosion of unspecified degree of left thumb (nail), initial encounter',
  'T23412D Corrosion of unspecified degree of left thumb (nail), subsequent encounter',
  'T23412S Corrosion of unspecified degree of left thumb (nail), sequela',
  'T23419A Corrosion of unspecified degree of unspecified thumb (nail), initial encounter',
  'T23419D Corrosion of unspecified degree of unspecified thumb (nail), subsequent encounter',
  'T23419S Corrosion of unspecified degree of unspecified thumb (nail), sequela',
  'T23421A Corrosion of unspecified degree of single right finger (nail) except thumb, initial encounter',
  'T23421D Corrosion of unspecified degree of single right finger (nail) except thumb, subsequent',
  'encounter',
  'T23421S Corrosion of unspecified degree of single right finger (nail) except thumb, sequela',
  'T23422A Corrosion of unspecified degree of single left finger (nail) except thumb, initial encounter',
  'T23422D Corrosion of unspecified degree of single left finger (nail) except thumb, subsequent encounter',
  'T23422S Corrosion of unspecified degree of single left finger (nail) except thumb, sequela',
  'T23429A Corrosion of unspecified degree of unspecified single finger (nail) except thumb, initial',
  'encounter',
  'T23429D Corrosion of unspecified degree of unspecified single finger (nail) except thumb, subsequent',
  'encounter',
  'T23429S Corrosion of unspecified degree of unspecified single finger (nail) except thumb, sequela',
  'T23431A Corrosion of unspecified degree of multiple right fingers (nail), not including thumb, initial',
  'encounter',
  'T23431D Corrosion of unspecified degree of multiple right fingers (nail), not including thumb,',
  'subsequent encounter',
  'T23431S Corrosion of unspecified degree of multiple right fingers (nail), not including thumb, sequela',
  'T23432A Corrosion of unspecified degree of multiple left fingers (nail), not including thumb, initial',
  'encounter',
  'T23432D Corrosion of unspecified degree of multiple left fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23432S Corrosion of unspecified degree of multiple left fingers (nail), not including thumb, sequela',
  'T23439A Corrosion of unspecified degree of unspecified multiple fingers (nail), not including thumb,',
  'initial encounter',
  'T23439D Corrosion of unspecified degree of unspecified multiple fingers (nail), not including thumb,',
  'subsequent encounter',
  'T23439S Corrosion of unspecified degree of unspecified multiple fingers (nail), not including thumb,',
  'sequela',
  'T23441A Corrosion of unspecified degree of multiple right fingers (nail), including thumb, initial',
  'encounter',
  'T23441D Corrosion of unspecified degree of multiple right fingers (nail), including thumb, subsequent',
  'encounter',
  'T23441S Corrosion of unspecified degree of multiple right fingers (nail), including thumb, sequela',
  'T23442A Corrosion of unspecified degree of multiple left fingers (nail), including thumb, initial',
  'encounter',
  'T23442D Corrosion of unspecified degree of multiple left fingers (nail), including thumb, subsequent',
  'encounter',
  'T23442S Corrosion of unspecified degree of multiple left fingers (nail), including thumb, sequela',
  'T23449A Corrosion of unspecified degree of unspecified multiple fingers (nail), including thumb, initial',
  'encounter',
  'T23449D Corrosion of unspecified degree of unspecified multiple fingers (nail), including thumb,',
  'subsequent encounter',
  'T23449S Corrosion of unspecified degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23451A Corrosion of unspecified degree of right palm, initial encounter',
  'T23451D Corrosion of unspecified degree of right palm, subsequent encounter',
  'T23451S Corrosion of unspecified degree of right palm, sequela',
  'T23452A Corrosion of unspecified degree of left palm, initial encounter',
  'T23452D Corrosion of unspecified degree of left palm, subsequent encounter',
  'T23452S Corrosion of unspecified degree of left palm, sequela',
  'T23459A Corrosion of unspecified degree of unspecified palm, initial encounter',
  'T23459D Corrosion of unspecified degree of unspecified palm, subsequent encounter',
  'T23459S Corrosion of unspecified degree of unspecified palm, sequela',
  'T23461A Corrosion of unspecified degree of back of right hand, initial encounter',
  'T23461D Corrosion of unspecified degree of back of right hand, subsequent encounter',
  'T23461S Corrosion of unspecified degree of back of right hand, sequela',
  'T23462A Corrosion of unspecified degree of back of left hand, initial encounter',
  'T23462D Corrosion of unspecified degree of back of left hand, subsequent encounter',
  'T23462S Corrosion of unspecified degree of back of left hand, sequela',
  'T23469A Corrosion of unspecified degree of back of unspecified hand, initial encounter',
  'T23469D Corrosion of unspecified degree of back of unspecified hand, subsequent encounter',
  'T23469S Corrosion of unspecified degree of back of unspecified hand, sequela',
  'T23471A Corrosion of unspecified degree of right wrist, initial encounter',
  'T23471D Corrosion of unspecified degree of right wrist, subsequent encounter',
  'T23471S Corrosion of unspecified degree of right wrist, sequela',
  'T23472A Corrosion of unspecified degree of left wrist, initial encounter',
  'T23472D Corrosion of unspecified degree of left wrist, subsequent encounter',
  'T23472S Corrosion of unspecified degree of left wrist, sequela',
  'T23479A Corrosion of unspecified degree of unspecified wrist, initial encounter',
  'T23479D Corrosion of unspecified degree of unspecified wrist, subsequent encounter',
  'T23479S Corrosion of unspecified degree of unspecified wrist, sequela',
  'T23491A Corrosion of unspecified degree of multiple sites of right wrist and hand, initial encounter',
  'T23491D Corrosion of unspecified degree of multiple sites of right wrist and hand, subsequent',
  'encounter',
  'T23491S Corrosion of unspecified degree of multiple sites of right wrist and hand, sequela',
  'T23492A Corrosion of unspecified degree of multiple sites of left wrist and hand, initial encounter',
  'T23492D Corrosion of unspecified degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23492S Corrosion of unspecified degree of multiple sites of left wrist and hand, sequela',
  'T23499A Corrosion of unspecified degree of multiple sites of unspecified wrist and hand, initial',
  'encounter',
  'T23499D Corrosion of unspecified degree of multiple sites of unspecified wrist and hand, subsequent',
  'encounter',
  'T23499S Corrosion of unspecified degree of multiple sites of unspecified wrist and hand, sequela',
  'T23501A Corrosion of first degree of right hand, unspecified site, initial encounter',
  'T23501D Corrosion of first degree of right hand, unspecified site, subsequent encounter',
  'T23501S Corrosion of first degree of right hand, unspecified site, sequela',
  'T23502A Corrosion of first degree of left hand, unspecified site, initial encounter',
  'T23502D Corrosion of first degree of left hand, unspecified site, subsequent encounter',
  'T23502S Corrosion of first degree of left hand, unspecified site, sequela',
  'T23509A Corrosion of first degree of unspecified hand, unspecified site, initial encounter',
  'T23509D Corrosion of first degree of unspecified hand, unspecified site, subsequent encounter',
  'T23509S Corrosion of first degree of unspecified hand, unspecified site, sequela',
  'T23511A Corrosion of first degree of right thumb (nail), initial encounter',
  'T23511D Corrosion of first degree of right thumb (nail), subsequent encounter',
  'T23511S Corrosion of first degree of right thumb (nail), sequela',
  'T23512A Corrosion of first degree of left thumb (nail), initial encounter',
  'T23512D Corrosion of first degree of left thumb (nail), subsequent encounter',
  'T23512S Corrosion of first degree of left thumb (nail), sequela',
  'T23519A Corrosion of first degree of unspecified thumb (nail), initial encounter',
  'T23519D Corrosion of first degree of unspecified thumb (nail), subsequent encounter',
  'T23519S Corrosion of first degree of unspecified thumb (nail), sequela',
  'T23521A Corrosion of first degree of single right finger (nail) except thumb, initial encounter',
  'T23521D Corrosion of first degree of single right finger (nail) except thumb, subsequent encounter',
  'T23521S Corrosion of first degree of single right finger (nail) except thumb, sequela',
  'T23522A Corrosion of first degree of single left finger (nail) except thumb, initial encounter',
  'T23522D Corrosion of first degree of single left finger (nail) except thumb, subsequent encounter',
  'T23522S Corrosion of first degree of single left finger (nail) except thumb, sequela',
  'T23529A Corrosion of first degree of unspecified single finger (nail) except thumb, initial encounter',
  'T23529D Corrosion of first degree of unspecified single finger (nail) except thumb, subsequent',
  'encounter',
  'T23529S Corrosion of first degree of unspecified single finger (nail) except thumb, sequela',
  'T23531A Corrosion of first degree of multiple right fingers (nail), not including thumb, initial encounter',
  'T23531D Corrosion of first degree of multiple right fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23531S Corrosion of first degree of multiple right fingers (nail), not including thumb, sequela',
  'T23532A Corrosion of first degree of multiple left fingers (nail), not including thumb, initial encounter',
  'T23532D Corrosion of first degree of multiple left fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23532S Corrosion of first degree of multiple left fingers (nail), not including thumb, sequela',
  'T23539A Corrosion of first degree of unspecified multiple fingers (nail), not including thumb, initial',
  'encounter',
  'T23539D Corrosion of first degree of unspecified multiple fingers (nail), not including thumb,',
  'subsequent encounter',
  'T23539S Corrosion of first degree of unspecified multiple fingers (nail), not including thumb, sequela',
  'T23541A Corrosion of first degree of multiple right fingers (nail), including thumb, initial encounter',
  'T23541D Corrosion of first degree of multiple right fingers (nail), including thumb, subsequent',
  'encounter',
  'T23541S Corrosion of first degree of multiple right fingers (nail), including thumb, sequela',
  'T23542A Corrosion of first degree of multiple left fingers (nail), including thumb, initial encounter',
  'T23542D Corrosion of first degree of multiple left fingers (nail), including thumb, subsequent encounter',
  'T23542S Corrosion of first degree of multiple left fingers (nail), including thumb, sequela',
  'T23549A Corrosion of first degree of unspecified multiple fingers (nail), including thumb, initial',
  'encounter',
  'T23549D Corrosion of first degree of unspecified multiple fingers (nail), including thumb, subsequent',
  'encounter',
  'T23549S Corrosion of first degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23551A Corrosion of first degree of right palm, initial encounter',
  'T23551D Corrosion of first degree of right palm, subsequent encounter',
  'T23551S Corrosion of first degree of right palm, sequela',
  'T23552A Corrosion of first degree of left palm, initial encounter',
  'T23552D Corrosion of first degree of left palm, subsequent encounter',
  'T23552S Corrosion of first degree of left palm, sequela',
  'T23559A Corrosion of first degree of unspecified palm, initial encounter',
  'T23559D Corrosion of first degree of unspecified palm, subsequent encounter',
  'T23559S Corrosion of first degree of unspecified palm, sequela',
  'T23561A Corrosion of first degree of back of right hand, initial encounter',
  'T23561D Corrosion of first degree of back of right hand, subsequent encounter',
  'T23561S Corrosion of first degree of back of right hand, sequela',
  'T23562A Corrosion of first degree of back of left hand, initial encounter',
  'T23562D Corrosion of first degree of back of left hand, subsequent encounter',
  'T23562S Corrosion of first degree of back of left hand, sequela',
  'T23569A Corrosion of first degree of back of unspecified hand, initial encounter',
  'T23569D Corrosion of first degree of back of unspecified hand, subsequent encounter',
  'T23569S Corrosion of first degree of back of unspecified hand, sequela',
  'T23571A Corrosion of first degree of right wrist, initial encounter',
  'T23571D Corrosion of first degree of right wrist, subsequent encounter',
  'T23571S Corrosion of first degree of right wrist, sequela',
  'T23572A Corrosion of first degree of left wrist, initial encounter',
  'T23572D Corrosion of first degree of left wrist, subsequent encounter',
  'T23572S Corrosion of first degree of left wrist, sequela',
  'T23579A Corrosion of first degree of unspecified wrist, initial encounter',
  'T23579D Corrosion of first degree of unspecified wrist, subsequent encounter',
  'T23579S Corrosion of first degree of unspecified wrist, sequela',
  'T23591A Corrosion of first degree of multiple sites of right wrist and hand, initial encounter',
  'T23591D Corrosion of first degree of multiple sites of right wrist and hand, subsequent encounter',
  'T23591S Corrosion of first degree of multiple sites of right wrist and hand, sequela',
  'T23592A Corrosion of first degree of multiple sites of left wrist and hand, initial encounter',
  'T23592D Corrosion of first degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23592S Corrosion of first degree of multiple sites of left wrist and hand, sequela',
  'T23599A Corrosion of first degree of multiple sites of unspecified wrist and hand, initial encounter',
  'T23599D Corrosion of first degree of multiple sites of unspecified wrist and hand, subsequent encounter',
  'T23599S Corrosion of first degree of multiple sites of unspecified wrist and hand, sequela',
  'T23601A Corrosion of second degree of right hand, unspecified site, initial encounter',
  'T23601D Corrosion of second degree of right hand, unspecified site, subsequent encounter',
  'T23601S Corrosion of second degree of right hand, unspecified site, sequela',
  'T23602A Corrosion of second degree of left hand, unspecified site, initial encounter',
  'T23602D Corrosion of second degree of left hand, unspecified site, subsequent encounter',
  'T23602S Corrosion of second degree of left hand, unspecified site, sequela',
  'T23609A Corrosion of second degree of unspecified hand, unspecified site, initial encounter',
  'T23609D Corrosion of second degree of unspecified hand, unspecified site, subsequent encounter',
  'T23609S Corrosion of second degree of unspecified hand, unspecified site, sequela',
  'T23611A Corrosion of second degree of right thumb (nail), initial encounter',
  'T23611D Corrosion of second degree of right thumb (nail), subsequent encounter',
  'T23611S Corrosion of second degree of right thumb (nail), sequela',
  'T23612A Corrosion of second degree of left thumb (nail), initial encounter',
  'T23612D Corrosion of second degree of left thumb (nail), subsequent encounter',
  'T23612S Corrosion of second degree of left thumb (nail), sequela',
  'T23619A Corrosion of second degree of unspecified thumb (nail), initial encounter',
  'T23619D Corrosion of second degree of unspecified thumb (nail), subsequent encounter',
  'T23619S Corrosion of second degree of unspecified thumb (nail), sequela',
  'T23621A Corrosion of second degree of single right finger (nail) except thumb, initial encounter',
  'T23621D Corrosion of second degree of single right finger (nail) except thumb, subsequent encounter',
  'T23621S Corrosion of second degree of single right finger (nail) except thumb, sequela',
  'T23622A Corrosion of second degree of single left finger (nail) except thumb, initial encounter',
  'T23622D Corrosion of second degree of single left finger (nail) except thumb, subsequent encounter',
  'T23622S Corrosion of second degree of single left finger (nail) except thumb, sequela',
  'T23629A Corrosion of second degree of unspecified single finger (nail) except thumb, initial encounter',
  'T23629D Corrosion of second degree of unspecified single finger (nail) except thumb, subsequent',
  'encounter',
  'T23629S Corrosion of second degree of unspecified single finger (nail) except thumb, sequela',
  'T23631A Corrosion of second degree of multiple right fingers (nail), not including thumb, initial',
  'encounter',
  'T23631D Corrosion of second degree of multiple right fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23631S Corrosion of second degree of multiple right fingers (nail), not including thumb, sequela',
  'T23632A Corrosion of second degree of multiple left fingers (nail), not including thumb, initial encounter',
  'T23632D Corrosion of second degree of multiple left fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23632S Corrosion of second degree of multiple left fingers (nail), not including thumb, sequela',
  'T23639A Corrosion of second degree of unspecified multiple fingers (nail), not including thumb, initial',
  'encounter',
  'T23639D Corrosion of second degree of unspecified multiple fingers (nail), not including thumb,',
  'subsequent encounter',
  'T23639S Corrosion of second degree of unspecified multiple fingers (nail), not including thumb, sequela',
  'T23641A Corrosion of second degree of multiple right fingers (nail), including thumb, initial encounter',
  'T23641D Corrosion of second degree of multiple right fingers (nail), including thumb, subsequent',
  'encounter',
  'T23641S Corrosion of second degree of multiple right fingers (nail), including thumb, sequela',
  'T23642A Corrosion of second degree of multiple left fingers (nail), including thumb, initial encounter',
  'T23642D Corrosion of second degree of multiple left fingers (nail), including thumb, subsequent',
  'encounter',
  'T23642S Corrosion of second degree of multiple left fingers (nail), including thumb, sequela',
  'T23649A Corrosion of second degree of unspecified multiple fingers (nail), including thumb, initial',
  'encounter',
  'T23649D Corrosion of second degree of unspecified multiple fingers (nail), including thumb, subsequent',
  'encounter',
  'T23649S Corrosion of second degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23651A Corrosion of second degree of right palm, initial encounter',
  'T23651D Corrosion of second degree of right palm, subsequent encounter',
  'T23651S Corrosion of second degree of right palm, sequela',
  'T23652A Corrosion of second degree of left palm, initial encounter',
  'T23652D Corrosion of second degree of left palm, subsequent encounter',
  'T23652S Corrosion of second degree of left palm, sequela',
  'T23659A Corrosion of second degree of unspecified palm, initial encounter',
  'T23659D Corrosion of second degree of unspecified palm, subsequent encounter',
  'T23659S Corrosion of second degree of unspecified palm, sequela',
  'T23661A Corrosion of second degree back of right hand, initial encounter',
  'T23661D Corrosion of second degree back of right hand, subsequent encounter',
  'T23661S Corrosion of second degree back of right hand, sequela',
  'T23662A Corrosion of second degree back of left hand, initial encounter',
  'T23662D Corrosion of second degree back of left hand, subsequent encounter',
  'T23662S Corrosion of second degree back of left hand, sequela',
  'T23669A Corrosion of second degree back of unspecified hand, initial encounter',
  'T23669D Corrosion of second degree back of unspecified hand, subsequent encounter',
  'T23669S Corrosion of second degree back of unspecified hand, sequela',
  'T23671A Corrosion of second degree of right wrist, initial encounter',
  'T23671D Corrosion of second degree of right wrist, subsequent encounter',
  'T23671S Corrosion of second degree of right wrist, sequela',
  'T23672A Corrosion of second degree of left wrist, initial encounter',
  'T23672D Corrosion of second degree of left wrist, subsequent encounter',
  'T23672S Corrosion of second degree of left wrist, sequela',
  'T23679A Corrosion of second degree of unspecified wrist, initial encounter',
  'T23679D Corrosion of second degree of unspecified wrist, subsequent encounter',
  'T23679S Corrosion of second degree of unspecified wrist, sequela',
  'T23691A Corrosion of second degree of multiple sites of right wrist and hand, initial encounter',
  'T23691D Corrosion of second degree of multiple sites of right wrist and hand, subsequent encounter',
  'T23691S Corrosion of second degree of multiple sites of right wrist and hand, sequela',
  'T23692A Corrosion of second degree of multiple sites of left wrist and hand, initial encounter',
  'T23692D Corrosion of second degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23692S Corrosion of second degree of multiple sites of left wrist and hand, sequela',
  'T23699A Corrosion of second degree of multiple sites of unspecified wrist and hand, initial encounter',
  'T23699D Corrosion of second degree of multiple sites of unspecified wrist and hand, subsequent',
  'encounter',
  'T23699S Corrosion of second degree of multiple sites of unspecified wrist and hand, sequela',
  'T23701A Corrosion of third degree of right hand, unspecified site, initial encounter',
  'T23701D Corrosion of third degree of right hand, unspecified site, subsequent encounter',
  'T23701S Corrosion of third degree of right hand, unspecified site, sequela',
  'T23702A Corrosion of third degree of left hand, unspecified site, initial encounter',
  'T23702D Corrosion of third degree of left hand, unspecified site, subsequent encounter',
  'T23702S Corrosion of third degree of left hand, unspecified site, sequela',
  'T23709A Corrosion of third degree of unspecified hand, unspecified site, initial encounter',
  'T23709D Corrosion of third degree of unspecified hand, unspecified site, subsequent encounter',
  'T23709S Corrosion of third degree of unspecified hand, unspecified site, sequela',
  'T23711A Corrosion of third degree of right thumb (nail), initial encounter',
  'T23711D Corrosion of third degree of right thumb (nail), subsequent encounter',
  'T23711S Corrosion of third degree of right thumb (nail), sequela',
  'T23712A Corrosion of third degree of left thumb (nail), initial encounter',
  'T23712D Corrosion of third degree of left thumb (nail), subsequent encounter',
  'T23712S Corrosion of third degree of left thumb (nail), sequela',
  'T23719A Corrosion of third degree of unspecified thumb (nail), initial encounter',
  'T23719D Corrosion of third degree of unspecified thumb (nail), subsequent encounter',
  'T23719S Corrosion of third degree of unspecified thumb (nail), sequela',
  'T23721A Corrosion of third degree of single right finger (nail) except thumb, initial encounter',
  'T23721D Corrosion of third degree of single right finger (nail) except thumb, subsequent encounter',
  'T23721S Corrosion of third degree of single right finger (nail) except thumb, sequela',
  'T23722A Corrosion of third degree of single left finger (nail) except thumb, initial encounter',
  'T23722D Corrosion of third degree of single left finger (nail) except thumb, subsequent encounter',
  'T23722S Corrosion of third degree of single left finger (nail) except thumb, sequela',
  'T23729A Corrosion of third degree of unspecified single finger (nail) except thumb, initial encounter',
  'T23729D Corrosion of third degree of unspecified single finger (nail) except thumb, subsequent',
  'encounter',
  'T23729S Corrosion of third degree of unspecified single finger (nail) except thumb, sequela',
  'T23731A Corrosion of third degree of multiple right fingers (nail), not including thumb, initial encounter',
  'T23731D Corrosion of third degree of multiple right fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23731S Corrosion of third degree of multiple right fingers (nail), not including thumb, sequela',
  'T23732A Corrosion of third degree of multiple left fingers (nail), not including thumb, initial encounter',
  'T23732D Corrosion of third degree of multiple left fingers (nail), not including thumb, subsequent',
  'encounter',
  'T23732S Corrosion of third degree of multiple left fingers (nail), not including thumb, sequela',
  'T23739A Corrosion of third degree of unspecified multiple fingers (nail), not including thumb, initial',
  'encounter',
  'T23739D Corrosion of third degree of unspecified multiple fingers (nail), not including thumb,',
  'subsequent encounter',
  'T23739S Corrosion of third degree of unspecified multiple fingers (nail), not including thumb, sequela',
  'T23741A Corrosion of third degree of multiple right fingers (nail), including thumb, initial encounter',
  'T23741D Corrosion of third degree of multiple right fingers (nail), including thumb, subsequent',
  'encounter',
  'T23741S Corrosion of third degree of multiple right fingers (nail), including thumb, sequela',
  'T23742A Corrosion of third degree of multiple left fingers (nail), including thumb, initial encounter',
  'T23742D Corrosion of third degree of multiple left fingers (nail), including thumb, subsequent encounter',
  'T23742S Corrosion of third degree of multiple left fingers (nail), including thumb, sequela',
  'T23749A Corrosion of third degree of unspecified multiple fingers (nail), including thumb, initial',
  'encounter',
  'T23749D Corrosion of third degree of unspecified multiple fingers (nail), including thumb, subsequent',
  'encounter',
  'T23749S Corrosion of third degree of unspecified multiple fingers (nail), including thumb, sequela',
  'T23751A Corrosion of third degree of right palm, initial encounter',
  'T23751D Corrosion of third degree of right palm, subsequent encounter',
  'T23751S Corrosion of third degree of right palm, sequela',
  'T23752A Corrosion of third degree of left palm, initial encounter',
  'T23752D Corrosion of third degree of left palm, subsequent encounter',
  'T23752S Corrosion of third degree of left palm, sequela',
  'T23759A Corrosion of third degree of unspecified palm, initial encounter',
  'T23759D Corrosion of third degree of unspecified palm, subsequent encounter',
  'T23759S Corrosion of third degree of unspecified palm, sequela',
  'T23761A Corrosion of third degree of back of right hand, initial encounter',
  'T23761D Corrosion of third degree of back of right hand, subsequent encounter',
  'T23761S Corrosion of third degree of back of right hand, sequela',
  'T23762A Corrosion of third degree of back of left hand, initial encounter',
  'T23762D Corrosion of third degree of back of left hand, subsequent encounter',
  'T23762S Corrosion of third degree of back of left hand, sequela',
  'T23769A Corrosion of third degree back of unspecified hand, initial encounter',
  'T23769D Corrosion of third degree back of unspecified hand, subsequent encounter',
  'T23769S Corrosion of third degree back of unspecified hand, sequela',
  'T23771A Corrosion of third degree of right wrist, initial encounter',
  'T23771D Corrosion of third degree of right wrist, subsequent encounter',
  'T23771S Corrosion of third degree of right wrist, sequela',
  'T23772A Corrosion of third degree of left wrist, initial encounter',
  'T23772D Corrosion of third degree of left wrist, subsequent encounter',
  'T23772S Corrosion of third degree of left wrist, sequela',
  'T23779A Corrosion of third degree of unspecified wrist, initial encounter',
  'T23779D Corrosion of third degree of unspecified wrist, subsequent encounter',
  'T23779S Corrosion of third degree of unspecified wrist, sequela',
  'T23791A Corrosion of third degree of multiple sites of right wrist and hand, initial encounter',
  'T23791D Corrosion of third degree of multiple sites of right wrist and hand, subsequent encounter',
  'T23791S Corrosion of third degree of multiple sites of right wrist and hand, sequela',
  'T23792A Corrosion of third degree of multiple sites of left wrist and hand, initial encounter',
  'T23792D Corrosion of third degree of multiple sites of left wrist and hand, subsequent encounter',
  'T23792S Corrosion of third degree of multiple sites of left wrist and hand, sequela',
  'T23799A Corrosion of third degree of multiple sites of unspecified wrist and hand, initial encounter',
  'T23799D Corrosion of third degree of multiple sites of unspecified wrist and hand, subsequent',
  'encounter',
  'T23799S Corrosion of third degree of multiple sites of unspecified wrist and hand, sequela',
  'T24001A Burn of unspecified degree of unspecified site of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24001D Burn of unspecified degree of unspecified site of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24001S Burn of unspecified degree of unspecified site of right lower limb, except ankle and foot,',
  'sequela',
  'T24002A Burn of unspecified degree of unspecified site of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24002D Burn of unspecified degree of unspecified site of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24002S Burn of unspecified degree of unspecified site of left lower limb, except ankle and foot, sequela',
  'T24009A Burn of unspecified degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24009D Burn of unspecified degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24009S Burn of unspecified degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24011A Burn of unspecified degree of right thigh, initial encounter',
  'T24011D Burn of unspecified degree of right thigh, subsequent encounter',
  'T24011S Burn of unspecified degree of right thigh, sequela',
  'T24012A Burn of unspecified degree of left thigh, initial encounter',
  'T24012D Burn of unspecified degree of left thigh, subsequent encounter',
  'T24012S Burn of unspecified degree of left thigh, sequela',
  'T24019A Burn of unspecified degree of unspecified thigh, initial encounter',
  'T24019D Burn of unspecified degree of unspecified thigh, subsequent encounter',
  'T24019S Burn of unspecified degree of unspecified thigh, sequela',
  'T24021A Burn of unspecified degree of right knee, initial encounter',
  'T24021D Burn of unspecified degree of right knee, subsequent encounter',
  'T24021S Burn of unspecified degree of right knee, sequela',
  'T24022A Burn of unspecified degree of left knee, initial encounter',
  'T24022D Burn of unspecified degree of left knee, subsequent encounter',
  'T24022S Burn of unspecified degree of left knee, sequela',
  'T24029A Burn of unspecified degree of unspecified knee, initial encounter',
  'T24029D Burn of unspecified degree of unspecified knee, subsequent encounter',
  'T24029S Burn of unspecified degree of unspecified knee, sequela',
  'T24031A Burn of unspecified degree of right lower leg, initial encounter',
  'T24031D Burn of unspecified degree of right lower leg, subsequent encounter',
  'T24031S Burn of unspecified degree of right lower leg, sequela',
  'T24032A Burn of unspecified degree of left lower leg, initial encounter',
  'T24032D Burn of unspecified degree of left lower leg, subsequent encounter',
  'T24032S Burn of unspecified degree of left lower leg, sequela',
  'T24039A Burn of unspecified degree of unspecified lower leg, initial encounter',
  'T24039D Burn of unspecified degree of unspecified lower leg, subsequent encounter',
  'T24039S Burn of unspecified degree of unspecified lower leg, sequela',
  'T24091A Burn of unspecified degree of multiple sites of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24091D Burn of unspecified degree of multiple sites of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24091S Burn of unspecified degree of multiple sites of right lower limb, except ankle and foot, sequela',
  'T24092A Burn of unspecified degree of multiple sites of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24092D Burn of unspecified degree of multiple sites of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24092S Burn of unspecified degree of multiple sites of left lower limb, except ankle and foot, sequela',
  'T24099A Burn of unspecified degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24099D Burn of unspecified degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24099S Burn of unspecified degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24101A Burn of first degree of unspecified site of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24101D Burn of first degree of unspecified site of right lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24101S Burn of first degree of unspecified site of right lower limb, except ankle and foot, sequela',
  'T24102A Burn of first degree of unspecified site of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24102D Burn of first degree of unspecified site of left lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24102S Burn of first degree of unspecified site of left lower limb, except ankle and foot, sequela',
  'T24109A Burn of first degree of unspecified site of unspecified lower limb, except ankle and foot, initial',
  'encounter',
  'T24109D Burn of first degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24109S Burn of first degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24111A Burn of first degree of right thigh, initial encounter',
  'T24111D Burn of first degree of right thigh, subsequent encounter',
  'T24111S Burn of first degree of right thigh, sequela',
  'T24112A Burn of first degree of left thigh, initial encounter',
  'T24112D Burn of first degree of left thigh, subsequent encounter',
  'T24112S Burn of first degree of left thigh, sequela',
  'T24119A Burn of first degree of unspecified thigh, initial encounter',
  'T24119D Burn of first degree of unspecified thigh, subsequent encounter',
  'T24119S Burn of first degree of unspecified thigh, sequela',
  'T24121A Burn of first degree of right knee, initial encounter',
  'T24121D Burn of first degree of right knee, subsequent encounter',
  'T24121S Burn of first degree of right knee, sequela',
  'T24122A Burn of first degree of left knee, initial encounter',
  'T24122D Burn of first degree of left knee, subsequent encounter',
  'T24122S Burn of first degree of left knee, sequela',
  'T24129A Burn of first degree of unspecified knee, initial encounter',
  'T24129D Burn of first degree of unspecified knee, subsequent encounter',
  'T24129S Burn of first degree of unspecified knee, sequela',
  'T24131A Burn of first degree of right lower leg, initial encounter',
  'T24131D Burn of first degree of right lower leg, subsequent encounter',
  'T24131S Burn of first degree of right lower leg, sequela',
  'T24132A Burn of first degree of left lower leg, initial encounter',
  'T24132D Burn of first degree of left lower leg, subsequent encounter',
  'T24132S Burn of first degree of left lower leg, sequela',
  'T24139A Burn of first degree of unspecified lower leg, initial encounter',
  'T24139D Burn of first degree of unspecified lower leg, subsequent encounter',
  'T24139S Burn of first degree of unspecified lower leg, sequela',
  'T24191A Burn of first degree of multiple sites of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24191D Burn of first degree of multiple sites of right lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24191S Burn of first degree of multiple sites of right lower limb, except ankle and foot, sequela',
  'T24192A Burn of first degree of multiple sites of left lower limb, except ankle and foot, initial encounter',
  'T24192D Burn of first degree of multiple sites of left lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24192S Burn of first degree of multiple sites of left lower limb, except ankle and foot, sequela',
  'T24199A Burn of first degree of multiple sites of unspecified lower limb, except ankle and foot, initial',
  'encounter',
  'T24199D Burn of first degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24199S Burn of first degree of multiple sites of unspecified lower limb, except ankle and foot, sequela',
  'T24201A Burn of second degree of unspecified site of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24201D Burn of second degree of unspecified site of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24201S Burn of second degree of unspecified site of right lower limb, except ankle and foot, sequela',
  'T24202A Burn of second degree of unspecified site of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24202D Burn of second degree of unspecified site of left lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24202S Burn of second degree of unspecified site of left lower limb, except ankle and foot, sequela',
  'T24209A Burn of second degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24209D Burn of second degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24209S Burn of second degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24211A Burn of second degree of right thigh, initial encounter',
  'T24211D Burn of second degree of right thigh, subsequent encounter',
  'T24211S Burn of second degree of right thigh, sequela',
  'T24212A Burn of second degree of left thigh, initial encounter',
  'T24212D Burn of second degree of left thigh, subsequent encounter',
  'T24212S Burn of second degree of left thigh, sequela',
  'T24219A Burn of second degree of unspecified thigh, initial encounter',
  'T24219D Burn of second degree of unspecified thigh, subsequent encounter',
  'T24219S Burn of second degree of unspecified thigh, sequela',
  'T24221A Burn of second degree of right knee, initial encounter',
  'T24221D Burn of second degree of right knee, subsequent encounter',
  'T24221S Burn of second degree of right knee, sequela',
  'T24222A Burn of second degree of left knee, initial encounter',
  'T24222D Burn of second degree of left knee, subsequent encounter',
  'T24222S Burn of second degree of left knee, sequela',
  'T24229A Burn of second degree of unspecified knee, initial encounter',
  'T24229D Burn of second degree of unspecified knee, subsequent encounter',
  'T24229S Burn of second degree of unspecified knee, sequela',
  'T24231A Burn of second degree of right lower leg, initial encounter',
  'T24231D Burn of second degree of right lower leg, subsequent encounter',
  'T24231S Burn of second degree of right lower leg, sequela',
  'T24232A Burn of second degree of left lower leg, initial encounter',
  'T24232D Burn of second degree of left lower leg, subsequent encounter',
  'T24232S Burn of second degree of left lower leg, sequela',
  'T24239A Burn of second degree of unspecified lower leg, initial encounter',
  'T24239D Burn of second degree of unspecified lower leg, subsequent encounter',
  'T24239S Burn of second degree of unspecified lower leg, sequela',
  'T24291A Burn of second degree of multiple sites of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24291D Burn of second degree of multiple sites of right lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24291S Burn of second degree of multiple sites of right lower limb, except ankle and foot, sequela',
  'T24292A Burn of second degree of multiple sites of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24292D Burn of second degree of multiple sites of left lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24292S Burn of second degree of multiple sites of left lower limb, except ankle and foot, sequela',
  'T24299A Burn of second degree of multiple sites of unspecified lower limb, except ankle and foot, initial',
  'encounter',
  'T24299D Burn of second degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24299S Burn of second degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24301A Burn of third degree of unspecified site of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24301D Burn of third degree of unspecified site of right lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24301S Burn of third degree of unspecified site of right lower limb, except ankle and foot, sequela',
  'T24302A Burn of third degree of unspecified site of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24302D Burn of third degree of unspecified site of left lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24302S Burn of third degree of unspecified site of left lower limb, except ankle and foot, sequela',
  'T24309A Burn of third degree of unspecified site of unspecified lower limb, except ankle and foot, initial',
  'encounter',
  'T24309D Burn of third degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24309S Burn of third degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24311A Burn of third degree of right thigh, initial encounter',
  'T24311D Burn of third degree of right thigh, subsequent encounter',
  'T24311S Burn of third degree of right thigh, sequela',
  'T24312A Burn of third degree of left thigh, initial encounter',
  'T24312D Burn of third degree of left thigh, subsequent encounter',
  'T24312S Burn of third degree of left thigh, sequela',
  'T24319A Burn of third degree of unspecified thigh, initial encounter',
  'T24319D Burn of third degree of unspecified thigh, subsequent encounter',
  'T24319S Burn of third degree of unspecified thigh, sequela',
  'T24321A Burn of third degree of right knee, initial encounter',
  'T24321D Burn of third degree of right knee, subsequent encounter',
  'T24321S Burn of third degree of right knee, sequela',
  'T24322A Burn of third degree of left knee, initial encounter',
  'T24322D Burn of third degree of left knee, subsequent encounter',
  'T24322S Burn of third degree of left knee, sequela',
  'T24329A Burn of third degree of unspecified knee, initial encounter',
  'T24329D Burn of third degree of unspecified knee, subsequent encounter',
  'T24329S Burn of third degree of unspecified knee, sequela',
  'T24331A Burn of third degree of right lower leg, initial encounter',
  'T24331D Burn of third degree of right lower leg, subsequent encounter',
  'T24331S Burn of third degree of right lower leg, sequela',
  'T24332A Burn of third degree of left lower leg, initial encounter',
  'T24332D Burn of third degree of left lower leg, subsequent encounter',
  'T24332S Burn of third degree of left lower leg, sequela',
  'T24339A Burn of third degree of unspecified lower leg, initial encounter',
  'T24339D Burn of third degree of unspecified lower leg, subsequent encounter',
  'T24339S Burn of third degree of unspecified lower leg, sequela',
  'T24391A Burn of third degree of multiple sites of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24391D Burn of third degree of multiple sites of right lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24391S Burn of third degree of multiple sites of right lower limb, except ankle and foot, sequela',
  'T24392A Burn of third degree of multiple sites of left lower limb, except ankle and foot, initial encounter',
  'T24392D Burn of third degree of multiple sites of left lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24392S Burn of third degree of multiple sites of left lower limb, except ankle and foot, sequela',
  'T24399A Burn of third degree of multiple sites of unspecified lower limb, except ankle and foot, initial',
  'encounter',
  'T24399D Burn of third degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24399S Burn of third degree of multiple sites of unspecified lower limb, except ankle and foot, sequela',
  'T24401A Corrosion of unspecified degree of unspecified site of right lower limb, except ankle and foot,',
  'initial encounter',
  'T24401D Corrosion of unspecified degree of unspecified site of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24401S Corrosion of unspecified degree of unspecified site of right lower limb, except ankle and foot,',
  'sequela',
  'T24402A Corrosion of unspecified degree of unspecified site of left lower limb, except ankle and foot,',
  'initial encounter',
  'T24402D Corrosion of unspecified degree of unspecified site of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24402S Corrosion of unspecified degree of unspecified site of left lower limb, except ankle and foot,',
  'sequela',
  'T24409A Corrosion of unspecified degree of unspecified site of unspecified lower limb, except ankle and',
  'foot, initial encounter',
  'T24409D Corrosion of unspecified degree of unspecified site of unspecified lower limb, except ankle and',
  'foot, subsequent encounter',
  'T24409S Corrosion of unspecified degree of unspecified site of unspecified lower limb, except ankle and',
  'foot, sequela',
  'T24411A Corrosion of unspecified degree of right thigh, initial encounter',
  'T24411D Corrosion of unspecified degree of right thigh, subsequent encounter',
  'T24411S Corrosion of unspecified degree of right thigh, sequela',
  'T24412A Corrosion of unspecified degree of left thigh, initial encounter',
  'T24412D Corrosion of unspecified degree of left thigh, subsequent encounter',
  'T24412S Corrosion of unspecified degree of left thigh, sequela',
  'T24419A Corrosion of unspecified degree of unspecified thigh, initial encounter',
  'T24419D Corrosion of unspecified degree of unspecified thigh, subsequent encounter',
  'T24419S Corrosion of unspecified degree of unspecified thigh, sequela',
  'T24421A Corrosion of unspecified degree of right knee, initial encounter',
  'T24421D Corrosion of unspecified degree of right knee, subsequent encounter',
  'T24421S Corrosion of unspecified degree of right knee, sequela',
  'T24422A Corrosion of unspecified degree of left knee, initial encounter',
  'T24422D Corrosion of unspecified degree of left knee, subsequent encounter',
  'T24422S Corrosion of unspecified degree of left knee, sequela',
  'T24429A Corrosion of unspecified degree of unspecified knee, initial encounter',
  'T24429D Corrosion of unspecified degree of unspecified knee, subsequent encounter',
  'T24429S Corrosion of unspecified degree of unspecified knee, sequela',
  'T24431A Corrosion of unspecified degree of right lower leg, initial encounter',
  'T24431D Corrosion of unspecified degree of right lower leg, subsequent encounter',
  'T24431S Corrosion of unspecified degree of right lower leg, sequela',
  'T24432A Corrosion of unspecified degree of left lower leg, initial encounter',
  'T24432D Corrosion of unspecified degree of left lower leg, subsequent encounter',
  'T24432S Corrosion of unspecified degree of left lower leg, sequela',
  'T24439A Corrosion of unspecified degree of unspecified lower leg, initial encounter',
  'T24439D Corrosion of unspecified degree of unspecified lower leg, subsequent encounter',
  'T24439S Corrosion of unspecified degree of unspecified lower leg, sequela',
  'T24491A Corrosion of unspecified degree of multiple sites of right lower limb, except ankle and foot,',
  'initial encounter',
  'T24491D Corrosion of unspecified degree of multiple sites of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24491S Corrosion of unspecified degree of multiple sites of right lower limb, except ankle and foot,',
  'sequela',
  'T24492A Corrosion of unspecified degree of multiple sites of left lower limb, except ankle and foot,',
  'initial encounter',
  'T24492D Corrosion of unspecified degree of multiple sites of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24492S Corrosion of unspecified degree of multiple sites of left lower limb, except ankle and foot,',
  'sequela',
  'T24499A Corrosion of unspecified degree of multiple sites of unspecified lower limb, except ankle and',
  'foot, initial encounter',
  'T24499D Corrosion of unspecified degree of multiple sites of unspecified lower limb, except ankle and',
  'foot, subsequent encounter',
  'T24499S Corrosion of unspecified degree of multiple sites of unspecified lower limb, except ankle and',
  'foot, sequela',
  'T24501A Corrosion of first degree of unspecified site of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24501D Corrosion of first degree of unspecified site of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24501S Corrosion of first degree of unspecified site of right lower limb, except ankle and foot, sequela',
  'T24502A Corrosion of first degree of unspecified site of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24502D Corrosion of first degree of unspecified site of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24502S Corrosion of first degree of unspecified site of left lower limb, except ankle and foot, sequela',
  'T24509A Corrosion of first degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24509D Corrosion of first degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24509S Corrosion of first degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24511A Corrosion of first degree of right thigh, initial encounter',
  'T24511D Corrosion of first degree of right thigh, subsequent encounter',
  'T24511S Corrosion of first degree of right thigh, sequela',
  'T24512A Corrosion of first degree of left thigh, initial encounter',
  'T24512D Corrosion of first degree of left thigh, subsequent encounter',
  'T24512S Corrosion of first degree of left thigh, sequela',
  'T24519A Corrosion of first degree of unspecified thigh, initial encounter',
  'T24519D Corrosion of first degree of unspecified thigh, subsequent encounter',
  'T24519S Corrosion of first degree of unspecified thigh, sequela',
  'T24521A Corrosion of first degree of right knee, initial encounter',
  'T24521D Corrosion of first degree of right knee, subsequent encounter',
  'T24521S Corrosion of first degree of right knee, sequela',
  'T24522A Corrosion of first degree of left knee, initial encounter',
  'T24522D Corrosion of first degree of left knee, subsequent encounter',
  'T24522S Corrosion of first degree of left knee, sequela',
  'T24529A Corrosion of first degree of unspecified knee, initial encounter',
  'T24529D Corrosion of first degree of unspecified knee, subsequent encounter',
  'T24529S Corrosion of first degree of unspecified knee, sequela',
  'T24531A Corrosion of first degree of right lower leg, initial encounter',
  'T24531D Corrosion of first degree of right lower leg, subsequent encounter',
  'T24531S Corrosion of first degree of right lower leg, sequela',
  'T24532A Corrosion of first degree of left lower leg, initial encounter',
  'T24532D Corrosion of first degree of left lower leg, subsequent encounter',
  'T24532S Corrosion of first degree of left lower leg, sequela',
  'T24539A Corrosion of first degree of unspecified lower leg, initial encounter',
  'T24539D Corrosion of first degree of unspecified lower leg, subsequent encounter',
  'T24539S Corrosion of first degree of unspecified lower leg, sequela',
  'T24591A Corrosion of first degree of multiple sites of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24591D Corrosion of first degree of multiple sites of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24591S Corrosion of first degree of multiple sites of right lower limb, except ankle and foot, sequela',
  'T24592A Corrosion of first degree of multiple sites of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24592D Corrosion of first degree of multiple sites of left lower limb, except ankle and foot, subsequent',
  'encounter',
  'T24592S Corrosion of first degree of multiple sites of left lower limb, except ankle and foot, sequela',
  'T24599A Corrosion of first degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24599D Corrosion of first degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24599S Corrosion of first degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24601A Corrosion of second degree of unspecified site of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24601D Corrosion of second degree of unspecified site of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24601S Corrosion of second degree of unspecified site of right lower limb, except ankle and foot,',
  'sequela',
  'T24602A Corrosion of second degree of unspecified site of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24602D Corrosion of second degree of unspecified site of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24602S Corrosion of second degree of unspecified site of left lower limb, except ankle and foot,',
  'sequela',
  'T24609A Corrosion of second degree of unspecified site of unspecified lower limb, except ankle and',
  'foot, initial encounter',
  'T24609D Corrosion of second degree of unspecified site of unspecified lower limb, except ankle and',
  'foot, subsequent encounter',
  'T24609S Corrosion of second degree of unspecified site of unspecified lower limb, except ankle and',
  'foot, sequela',
  'T24611A Corrosion of second degree of right thigh, initial encounter',
  'T24611D Corrosion of second degree of right thigh, subsequent encounter',
  'T24611S Corrosion of second degree of right thigh, sequela',
  'T24612A Corrosion of second degree of left thigh, initial encounter',
  'T24612D Corrosion of second degree of left thigh, subsequent encounter',
  'T24612S Corrosion of second degree of left thigh, sequela',
  'T24619A Corrosion of second degree of unspecified thigh, initial encounter',
  'T24619D Corrosion of second degree of unspecified thigh, subsequent encounter',
  'T24619S Corrosion of second degree of unspecified thigh, sequela',
  'T24621A Corrosion of second degree of right knee, initial encounter',
  'T24621D Corrosion of second degree of right knee, subsequent encounter',
  'T24621S Corrosion of second degree of right knee, sequela',
  'T24622A Corrosion of second degree of left knee, initial encounter',
  'T24622D Corrosion of second degree of left knee, subsequent encounter',
  'T24622S Corrosion of second degree of left knee, sequela',
  'T24629A Corrosion of second degree of unspecified knee, initial encounter',
  'T24629D Corrosion of second degree of unspecified knee, subsequent encounter',
  'T24629S Corrosion of second degree of unspecified knee, sequela',
  'T24631A Corrosion of second degree of right lower leg, initial encounter',
  'T24631D Corrosion of second degree of right lower leg, subsequent encounter',
  'T24631S Corrosion of second degree of right lower leg, sequela',
  'T24632A Corrosion of second degree of left lower leg, initial encounter',
  'T24632D Corrosion of second degree of left lower leg, subsequent encounter',
  'T24632S Corrosion of second degree of left lower leg, sequela',
  'T24639A Corrosion of second degree of unspecified lower leg, initial encounter',
  'T24639D Corrosion of second degree of unspecified lower leg, subsequent encounter',
  'T24639S Corrosion of second degree of unspecified lower leg, sequela',
  'T24691A Corrosion of second degree of multiple sites of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24691D Corrosion of second degree of multiple sites of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24691S Corrosion of second degree of multiple sites of right lower limb, except ankle and foot, sequela',
  'T24692A Corrosion of second degree of multiple sites of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24692D Corrosion of second degree of multiple sites of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24692S Corrosion of second degree of multiple sites of left lower limb, except ankle and foot, sequela',
  'T24699A Corrosion of second degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24699D Corrosion of second degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24699S Corrosion of second degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24701A Corrosion of third degree of unspecified site of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24701D Corrosion of third degree of unspecified site of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24701S Corrosion of third degree of unspecified site of right lower limb, except ankle and foot, sequela',
  'T24702A Corrosion of third degree of unspecified site of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24702D Corrosion of third degree of unspecified site of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24702S Corrosion of third degree of unspecified site of left lower limb, except ankle and foot, sequela',
  'T24709A Corrosion of third degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24709D Corrosion of third degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24709S Corrosion of third degree of unspecified site of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T24711A Corrosion of third degree of right thigh, initial encounter',
  'T24711D Corrosion of third degree of right thigh, subsequent encounter',
  'T24711S Corrosion of third degree of right thigh, sequela',
  'T24712A Corrosion of third degree of left thigh, initial encounter',
  'T24712D Corrosion of third degree of left thigh, subsequent encounter',
  'T24712S Corrosion of third degree of left thigh, sequela',
  'T24719A Corrosion of third degree of unspecified thigh, initial encounter',
  'T24719D Corrosion of third degree of unspecified thigh, subsequent encounter',
  'T24719S Corrosion of third degree of unspecified thigh, sequela',
  'T24721A Corrosion of third degree of right knee, initial encounter',
  'T24721D Corrosion of third degree of right knee, subsequent encounter',
  'T24721S Corrosion of third degree of right knee, sequela',
  'T24722A Corrosion of third degree of left knee, initial encounter',
  'T24722D Corrosion of third degree of left knee, subsequent encounter',
  'T24722S Corrosion of third degree of left knee, sequela',
  'T24729A Corrosion of third degree of unspecified knee, initial encounter',
  'T24729D Corrosion of third degree of unspecified knee, subsequent encounter',
  'T24729S Corrosion of third degree of unspecified knee, sequela',
  'T24731A Corrosion of third degree of right lower leg, initial encounter',
  'T24731D Corrosion of third degree of right lower leg, subsequent encounter',
  'T24731S Corrosion of third degree of right lower leg, sequela',
  'T24732A Corrosion of third degree of left lower leg, initial encounter',
  'T24732D Corrosion of third degree of left lower leg, subsequent encounter',
  'T24732S Corrosion of third degree of left lower leg, sequela',
  'T24739A Corrosion of third degree of unspecified lower leg, initial encounter',
  'T24739D Corrosion of third degree of unspecified lower leg, subsequent encounter',
  'T24739S Corrosion of third degree of unspecified lower leg, sequela',
  'T24791A Corrosion of third degree of multiple sites of right lower limb, except ankle and foot, initial',
  'encounter',
  'T24791D Corrosion of third degree of multiple sites of right lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24791S Corrosion of third degree of multiple sites of right lower limb, except ankle and foot, sequela',
  'T24792A Corrosion of third degree of multiple sites of left lower limb, except ankle and foot, initial',
  'encounter',
  'T24792D Corrosion of third degree of multiple sites of left lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24792S Corrosion of third degree of multiple sites of left lower limb, except ankle and foot, sequela',
  'T24799A Corrosion of third degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'initial encounter',
  'T24799D Corrosion of third degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'subsequent encounter',
  'T24799S Corrosion of third degree of multiple sites of unspecified lower limb, except ankle and foot,',
  'sequela',
  'T25011A Burn of unspecified degree of right ankle, initial encounter',
  'T25011D Burn of unspecified degree of right ankle, subsequent encounter',
  'T25011S Burn of unspecified degree of right ankle, sequela',
  'T25012A Burn of unspecified degree of left ankle, initial encounter',
  'T25012D Burn of unspecified degree of left ankle, subsequent encounter',
  'T25012S Burn of unspecified degree of left ankle, sequela',
  'T25019A Burn of unspecified degree of unspecified ankle, initial encounter',
  'T25019D Burn of unspecified degree of unspecified ankle, subsequent encounter',
  'T25019S Burn of unspecified degree of unspecified ankle, sequela',
  'T25021A Burn of unspecified degree of right foot, initial encounter',
  'T25021D Burn of unspecified degree of right foot, subsequent encounter',
  'T25021S Burn of unspecified degree of right foot, sequela',
  'T25022A Burn of unspecified degree of left foot, initial encounter',
  'T25022D Burn of unspecified degree of left foot, subsequent encounter',
  'T25022S Burn of unspecified degree of left foot, sequela',
  'T25029A Burn of unspecified degree of unspecified foot, initial encounter',
  'T25029D Burn of unspecified degree of unspecified foot, subsequent encounter',
  'T25029S Burn of unspecified degree of unspecified foot, sequela',
  'T25031A Burn of unspecified degree of right toe(s) (nail), initial encounter',
  'T25031D Burn of unspecified degree of right toe(s) (nail), subsequent encounter',
  'T25031S Burn of unspecified degree of right toe(s) (nail), sequela',
  'T25032A Burn of unspecified degree of left toe(s) (nail), initial encounter',
  'T25032D Burn of unspecified degree of left toe(s) (nail), subsequent encounter',
  'T25032S Burn of unspecified degree of left toe(s) (nail), sequela',
  'T25039A Burn of unspecified degree of unspecified toe(s) (nail), initial encounter',
  'T25039D Burn of unspecified degree of unspecified toe(s) (nail), subsequent encounter',
  'T25039S Burn of unspecified degree of unspecified toe(s) (nail), sequela',
  'T25091A Burn of unspecified degree of multiple sites of right ankle and foot, initial encounter',
  'T25091D Burn of unspecified degree of multiple sites of right ankle and foot, subsequent encounter',
  'T25091S Burn of unspecified degree of multiple sites of right ankle and foot, sequela',
  'T25092A Burn of unspecified degree of multiple sites of left ankle and foot, initial encounter',
  'T25092D Burn of unspecified degree of multiple sites of left ankle and foot, subsequent encounter',
  'T25092S Burn of unspecified degree of multiple sites of left ankle and foot, sequela',
  'T25099A Burn of unspecified degree of multiple sites of unspecified ankle and foot, initial encounter',
  'T25099D Burn of unspecified degree of multiple sites of unspecified ankle and foot, subsequent',
  'encounter',
  'T25099S Burn of unspecified degree of multiple sites of unspecified ankle and foot, sequela',
  'T25111A Burn of first degree of right ankle, initial encounter',
  'T25111D Burn of first degree of right ankle, subsequent encounter',
  'T25111S Burn of first degree of right ankle, sequela',
  'T25112A Burn of first degree of left ankle, initial encounter',
  'T25112D Burn of first degree of left ankle, subsequent encounter',
  'T25112S Burn of first degree of left ankle, sequela',
  'T25119A Burn of first degree of unspecified ankle, initial encounter',
  'T25119D Burn of first degree of unspecified ankle, subsequent encounter',
  'T25119S Burn of first degree of unspecified ankle, sequela',
  'T25121A Burn of first degree of right foot, initial encounter',
  'T25121D Burn of first degree of right foot, subsequent encounter',
  'T25121S Burn of first degree of right foot, sequela',
  'T25122A Burn of first degree of left foot, initial encounter',
  'T25122D Burn of first degree of left foot, subsequent encounter',
  'T25122S Burn of first degree of left foot, sequela',
  'T25129A Burn of first degree of unspecified foot, initial encounter',
  'T25129D Burn of first degree of unspecified foot, subsequent encounter',
  'T25129S Burn of first degree of unspecified foot, sequela',
  'T25131A Burn of first degree of right toe(s) (nail), initial encounter',
  'T25131D Burn of first degree of right toe(s) (nail), subsequent encounter',
  'T25131S Burn of first degree of right toe(s) (nail), sequela',
  'T25132A Burn of first degree of left toe(s) (nail), initial encounter',
  'T25132D Burn of first degree of left toe(s) (nail), subsequent encounter',
  'T25132S Burn of first degree of left toe(s) (nail), sequela',
  'T25139A Burn of first degree of unspecified toe(s) (nail), initial encounter',
  'T25139D Burn of first degree of unspecified toe(s) (nail), subsequent encounter',
  'T25139S Burn of first degree of unspecified toe(s) (nail), sequela',
  'T25191A Burn of first degree of multiple sites of right ankle and foot, initial encounter',
  'T25191D Burn of first degree of multiple sites of right ankle and foot, subsequent encounter',
  'T25191S Burn of first degree of multiple sites of right ankle and foot, sequela',
  'T25192A Burn of first degree of multiple sites of left ankle and foot, initial encounter',
  'T25192D Burn of first degree of multiple sites of left ankle and foot, subsequent encounter',
  'T25192S Burn of first degree of multiple sites of left ankle and foot, sequela',
  'T25199A Burn of first degree of multiple sites of unspecified ankle and foot, initial encounter',
  'T25199D Burn of first degree of multiple sites of unspecified ankle and foot, subsequent encounter',
  'T25199S Burn of first degree of multiple sites of unspecified ankle and foot, sequela',
  'T25211A Burn of second degree of right ankle, initial encounter',
  'T25211D Burn of second degree of right ankle, subsequent encounter',
  'T25211S Burn of second degree of right ankle, sequela',
  'T25212A Burn of second degree of left ankle, initial encounter',
  'T25212D Burn of second degree of left ankle, subsequent encounter',
  'T25212S Burn of second degree of left ankle, sequela',
  'T25219A Burn of second degree of unspecified ankle, initial encounter',
  'T25219D Burn of second degree of unspecified ankle, subsequent encounter',
  'T25219S Burn of second degree of unspecified ankle, sequela',
  'T25221A Burn of second degree of right foot, initial encounter',
  'T25221D Burn of second degree of right foot, subsequent encounter',
  'T25221S Burn of second degree of right foot, sequela',
  'T25222A Burn of second degree of left foot, initial encounter',
  'T25222D Burn of second degree of left foot, subsequent encounter',
  'T25222S Burn of second degree of left foot, sequela',
  'T25229A Burn of second degree of unspecified foot, initial encounter',
  'T25229D Burn of second degree of unspecified foot, subsequent encounter',
  'T25229S Burn of second degree of unspecified foot, sequela',
  'T25231A Burn of second degree of right toe(s) (nail), initial encounter',
  'T25231D Burn of second degree of right toe(s) (nail), subsequent encounter',
  'T25231S Burn of second degree of right toe(s) (nail), sequela',
  'T25232A Burn of second degree of left toe(s) (nail), initial encounter',
  'T25232D Burn of second degree of left toe(s) (nail), subsequent encounter',
  'T25232S Burn of second degree of left toe(s) (nail), sequela',
  'T25239A Burn of second degree of unspecified toe(s) (nail), initial encounter',
  'T25239D Burn of second degree of unspecified toe(s) (nail), subsequent encounter',
  'T25239S Burn of second degree of unspecified toe(s) (nail), sequela',
  'T25291A Burn of second degree of multiple sites of right ankle and foot, initial encounter',
  'T25291D Burn of second degree of multiple sites of right ankle and foot, subsequent encounter',
  'T25291S Burn of second degree of multiple sites of right ankle and foot, sequela',
  'T25292A Burn of second degree of multiple sites of left ankle and foot, initial encounter',
  'T25292D Burn of second degree of multiple sites of left ankle and foot, subsequent encounter',
  'T25292S Burn of second degree of multiple sites of left ankle and foot, sequela',
  'T25299A Burn of second degree of multiple sites of unspecified ankle and foot, initial encounter',
  'T25299D Burn of second degree of multiple sites of unspecified ankle and foot, subsequent encounter',
  'T25299S Burn of second degree of multiple sites of unspecified ankle and foot, sequela',
  'T25311A Burn of third degree of right ankle, initial encounter',
  'T25311D Burn of third degree of right ankle, subsequent encounter',
  'T25311S Burn of third degree of right ankle, sequela',
  'T25312A Burn of third degree of left ankle, initial encounter',
  'T25312D Burn of third degree of left ankle, subsequent encounter',
  'T25312S Burn of third degree of left ankle, sequela',
  'T25319A Burn of third degree of unspecified ankle, initial encounter',
  'T25319D Burn of third degree of unspecified ankle, subsequent encounter',
  'T25319S Burn of third degree of unspecified ankle, sequela',
  'T25321A Burn of third degree of right foot, initial encounter',
  'T25321D Burn of third degree of right foot, subsequent encounter',
  'T25321S Burn of third degree of right foot, sequela',
  'T25322A Burn of third degree of left foot, initial encounter',
  'T25322D Burn of third degree of left foot, subsequent encounter',
  'T25322S Burn of third degree of left foot, sequela',
  'T25329A Burn of third degree of unspecified foot, initial encounter',
  'T25329D Burn of third degree of unspecified foot, subsequent encounter',
  'T25329S Burn of third degree of unspecified foot, sequela',
  'T25331A Burn of third degree of right toe(s) (nail), initial encounter',
  'T25331D Burn of third degree of right toe(s) (nail), subsequent encounter',
  'T25331S Burn of third degree of right toe(s) (nail), sequela',
  'T25332A Burn of third degree of left toe(s) (nail), initial encounter',
  'T25332D Burn of third degree of left toe(s) (nail), subsequent encounter',
  'T25332S Burn of third degree of left toe(s) (nail), sequela',
  'T25339A Burn of third degree of unspecified toe(s) (nail), initial encounter',
  'T25339D Burn of third degree of unspecified toe(s) (nail), subsequent encounter',
  'T25339S Burn of third degree of unspecified toe(s) (nail), sequela',
  'T25391A Burn of third degree of multiple sites of right ankle and foot, initial encounter',
  'T25391D Burn of third degree of multiple sites of right ankle and foot, subsequent encounter',
  'T25391S Burn of third degree of multiple sites of right ankle and foot, sequela',
  'T25392A Burn of third degree of multiple sites of left ankle and foot, initial encounter',
  'T25392D Burn of third degree of multiple sites of left ankle and foot, subsequent encounter',
  'T25392S Burn of third degree of multiple sites of left ankle and foot, sequela',
  'T25399A Burn of third degree of multiple sites of unspecified ankle and foot, initial encounter',
  'T25399D Burn of third degree of multiple sites of unspecified ankle and foot, subsequent encounter',
  'T25399S Burn of third degree of multiple sites of unspecified ankle and foot, sequela',
  'T25411A Corrosion of unspecified degree of right ankle, initial encounter',
  'T25411D Corrosion of unspecified degree of right ankle, subsequent encounter',
  'T25411S Corrosion of unspecified degree of right ankle, sequela',
  'T25412A Corrosion of unspecified degree of left ankle, initial encounter',
  'T25412D Corrosion of unspecified degree of left ankle, subsequent encounter',
  'T25412S Corrosion of unspecified degree of left ankle, sequela',
  'T25419A Corrosion of unspecified degree of unspecified ankle, initial encounter',
  'T25419D Corrosion of unspecified degree of unspecified ankle, subsequent encounter',
  'T25419S Corrosion of unspecified degree of unspecified ankle, sequela',
  'T25421A Corrosion of unspecified degree of right foot, initial encounter',
  'T25421D Corrosion of unspecified degree of right foot, subsequent encounter',
  'T25421S Corrosion of unspecified degree of right foot, sequela',
  'T25422A Corrosion of unspecified degree of left foot, initial encounter',
  'T25422D Corrosion of unspecified degree of left foot, subsequent encounter',
  'T25422S Corrosion of unspecified degree of left foot, sequela',
  'T25429A Corrosion of unspecified degree of unspecified foot, initial encounter',
  'T25429D Corrosion of unspecified degree of unspecified foot, subsequent encounter',
  'T25429S Corrosion of unspecified degree of unspecified foot, sequela',
  'T25431A Corrosion of unspecified degree of right toe(s) (nail), initial encounter',
  'T25431D Corrosion of unspecified degree of right toe(s) (nail), subsequent encounter',
  'T25431S Corrosion of unspecified degree of right toe(s) (nail), sequela',
  'T25432A Corrosion of unspecified degree of left toe(s) (nail), initial encounter',
  'T25432D Corrosion of unspecified degree of left toe(s) (nail), subsequent encounter',
  'T25432S Corrosion of unspecified degree of left toe(s) (nail), sequela',
  'T25439A Corrosion of unspecified degree of unspecified toe(s) (nail), initial encounter',
  'T25439D Corrosion of unspecified degree of unspecified toe(s) (nail), subsequent encounter',
  'T25439S Corrosion of unspecified degree of unspecified toe(s) (nail), sequela',
  'T25491A Corrosion of unspecified degree of multiple sites of right ankle and foot, initial encounter',
  'T25491D Corrosion of unspecified degree of multiple sites of right ankle and foot, subsequent encounter',
  'T25491S Corrosion of unspecified degree of multiple sites of right ankle and foot, sequela',
  'T25492A Corrosion of unspecified degree of multiple sites of left ankle and foot, initial encounter',
  'T25492D Corrosion of unspecified degree of multiple sites of left ankle and foot, subsequent encounter',
  'T25492S Corrosion of unspecified degree of multiple sites of left ankle and foot, sequela',
  'T25499A Corrosion of unspecified degree of multiple sites of unspecified ankle and foot, initial',
  'encounter',
  'T25499D Corrosion of unspecified degree of multiple sites of unspecified ankle and foot, subsequent',
  'encounter',
  'T25499S Corrosion of unspecified degree of multiple sites of unspecified ankle and foot, sequela',
  'T25511A Corrosion of first degree of right ankle, initial encounter',
  'T25511D Corrosion of first degree of right ankle, subsequent encounter',
  'T25511S Corrosion of first degree of right ankle, sequela',
  'T25512A Corrosion of first degree of left ankle, initial encounter',
  'T25512D Corrosion of first degree of left ankle, subsequent encounter',
  'T25512S Corrosion of first degree of left ankle, sequela',
  'T25519A Corrosion of first degree of unspecified ankle, initial encounter',
  'T25519D Corrosion of first degree of unspecified ankle, subsequent encounter',
  'T25519S Corrosion of first degree of unspecified ankle, sequela',
  'T25521A Corrosion of first degree of right foot, initial encounter',
  'T25521D Corrosion of first degree of right foot, subsequent encounter',
  'T25521S Corrosion of first degree of right foot, sequela',
  'T25522A Corrosion of first degree of left foot, initial encounter',
  'T25522D Corrosion of first degree of left foot, subsequent encounter',
  'T25522S Corrosion of first degree of left foot, sequela',
  'T25529A Corrosion of first degree of unspecified foot, initial encounter',
  'T25529D Corrosion of first degree of unspecified foot, subsequent encounter',
  'T25529S Corrosion of first degree of unspecified foot, sequela',
  'T25531A Corrosion of first degree of right toe(s) (nail), initial encounter',
  'T25531D Corrosion of first degree of right toe(s) (nail), subsequent encounter',
  'T25531S Corrosion of first degree of right toe(s) (nail), sequela',
  'T25532A Corrosion of first degree of left toe(s) (nail), initial encounter',
  'T25532D Corrosion of first degree of left toe(s) (nail), subsequent encounter',
  'T25532S Corrosion of first degree of left toe(s) (nail), sequela',
  'T25539A Corrosion of first degree of unspecified toe(s) (nail), initial encounter',
  'T25539D Corrosion of first degree of unspecified toe(s) (nail), subsequent encounter',
  'T25539S Corrosion of first degree of unspecified toe(s) (nail), sequela',
  'T25591A Corrosion of first degree of multiple sites of right ankle and foot, initial encounter',
  'T25591D Corrosion of first degree of multiple sites of right ankle and foot, subsequent encounter',
  'T25591S Corrosion of first degree of multiple sites of right ankle and foot, sequela',
  'T25592A Corrosion of first degree of multiple sites of left ankle and foot, initial encounter',
  'T25592D Corrosion of first degree of multiple sites of left ankle and foot, subsequent encounter',
  'T25592S Corrosion of first degree of multiple sites of left ankle and foot, sequela',
  'T25599A Corrosion of first degree of multiple sites of unspecified ankle and foot, initial encounter',
  'T25599D Corrosion of first degree of multiple sites of unspecified ankle and foot, subsequent encounter',
  'T25599S Corrosion of first degree of multiple sites of unspecified ankle and foot, sequela',
  'T25611A Corrosion of second degree of right ankle, initial encounter',
  'T25611D Corrosion of second degree of right ankle, subsequent encounter',
  'T25611S Corrosion of second degree of right ankle, sequela',
  'T25612A Corrosion of second degree of left ankle, initial encounter',
  'T25612D Corrosion of second degree of left ankle, subsequent encounter',
  'T25612S Corrosion of second degree of left ankle, sequela',
  'T25619A Corrosion of second degree of unspecified ankle, initial encounter',
  'T25619D Corrosion of second degree of unspecified ankle, subsequent encounter',
  'T25619S Corrosion of second degree of unspecified ankle, sequela',
  'T25621A Corrosion of second degree of right foot, initial encounter',
  'T25621D Corrosion of second degree of right foot, subsequent encounter',
  'T25621S Corrosion of second degree of right foot, sequela',
  'T25622A Corrosion of second degree of left foot, initial encounter',
  'T25622D Corrosion of second degree of left foot, subsequent encounter',
  'T25622S Corrosion of second degree of left foot, sequela',
  'T25629A Corrosion of second degree of unspecified foot, initial encounter',
  'T25629D Corrosion of second degree of unspecified foot, subsequent encounter',
  'T25629S Corrosion of second degree of unspecified foot, sequela',
  'T25631A Corrosion of second degree of right toe(s) (nail), initial encounter',
  'T25631D Corrosion of second degree of right toe(s) (nail), subsequent encounter',
  'T25631S Corrosion of second degree of right toe(s) (nail), sequela',
  'T25632A Corrosion of second degree of left toe(s) (nail), initial encounter',
  'T25632D Corrosion of second degree of left toe(s) (nail), subsequent encounter',
  'T25632S Corrosion of second degree of left toe(s) (nail), sequela',
  'T25639A Corrosion of second degree of unspecified toe(s) (nail), initial encounter',
  'T25639D Corrosion of second degree of unspecified toe(s) (nail), subsequent encounter',
  'T25639S Corrosion of second degree of unspecified toe(s) (nail), sequela',
  'T25691A Corrosion of second degree of right ankle and foot, initial encounter',
  'T25691D Corrosion of second degree of right ankle and foot, subsequent encounter',
  'T25691S Corrosion of second degree of right ankle and foot, sequela',
  'T25692A Corrosion of second degree of left ankle and foot, initial encounter',
  'T25692D Corrosion of second degree of left ankle and foot, subsequent encounter',
  'T25692S Corrosion of second degree of left ankle and foot, sequela',
  'T25699A Corrosion of second degree of unspecified ankle and foot, initial encounter',
  'T25699D Corrosion of second degree of unspecified ankle and foot, subsequent encounter',
  'T25699S Corrosion of second degree of unspecified ankle and foot, sequela',
  'T25711A Corrosion of third degree of right ankle, initial encounter',
  'T25711D Corrosion of third degree of right ankle, subsequent encounter',
  'T25711S Corrosion of third degree of right ankle, sequela',
  'T25712A Corrosion of third degree of left ankle, initial encounter',
  'T25712D Corrosion of third degree of left ankle, subsequent encounter',
  'T25712S Corrosion of third degree of left ankle, sequela',
  'T25719A Corrosion of third degree of unspecified ankle, initial encounter',
  'T25719D Corrosion of third degree of unspecified ankle, subsequent encounter',
  'T25719S Corrosion of third degree of unspecified ankle, sequela',
  'T25721A Corrosion of third degree of right foot, initial encounter',
  'T25721D Corrosion of third degree of right foot, subsequent encounter',
  'T25721S Corrosion of third degree of right foot, sequela',
  'T25722A Corrosion of third degree of left foot, initial encounter',
  'T25722D Corrosion of third degree of left foot, subsequent encounter',
  'T25722S Corrosion of third degree of left foot, sequela',
  'T25729A Corrosion of third degree of unspecified foot, initial encounter',
  'T25729D Corrosion of third degree of unspecified foot, subsequent encounter',
  'T25729S Corrosion of third degree of unspecified foot, sequela',
  'T25731A Corrosion of third degree of right toe(s) (nail), initial encounter',
  'T25731D Corrosion of third degree of right toe(s) (nail), subsequent encounter',
  'T25731S Corrosion of third degree of right toe(s) (nail), sequela',
  'T25732A Corrosion of third degree of left toe(s) (nail), initial encounter',
  'T25732D Corrosion of third degree of left toe(s) (nail), subsequent encounter',
  'T25732S Corrosion of third degree of left toe(s) (nail), sequela',
  'T25739A Corrosion of third degree of unspecified toe(s) (nail), initial encounter',
  'T25739D Corrosion of third degree of unspecified toe(s) (nail), subsequent encounter',
  'T25739S Corrosion of third degree of unspecified toe(s) (nail), sequela',
  'T25791A Corrosion of third degree of multiple sites of right ankle and foot, initial encounter',
  'T25791D Corrosion of third degree of multiple sites of right ankle and foot, subsequent encounter',
  'T25791S Corrosion of third degree of multiple sites of right ankle and foot, sequela',
  'T25792A Corrosion of third degree of multiple sites of left ankle and foot, initial encounter',
  'T25792D Corrosion of third degree of multiple sites of left ankle and foot, subsequent encounter',
  'T25792S Corrosion of third degree of multiple sites of left ankle and foot, sequela',
  'T25799A Corrosion of third degree of multiple sites of unspecified ankle and foot, initial encounter',
  'T25799D Corrosion of third degree of multiple sites of unspecified ankle and foot, subsequent',
  'encounter',
  'T25799S Corrosion of third degree of multiple sites of unspecified ankle and foot, sequela',
  'T2600XA Burn of unspecified eyelid and periocular area, initial encounter',
  'T2600XD Burn of unspecified eyelid and periocular area, subsequent encounter',
  'T2600XS Burn of unspecified eyelid and periocular area, sequela',
  'T2601XA Burn of right eyelid and periocular area, initial encounter',
  'T2601XD Burn of right eyelid and periocular area, subsequent encounter',
  'T2601XS Burn of right eyelid and periocular area, sequela',
  'T2602XA Burn of left eyelid and periocular area, initial encounter',
  'T2602XD Burn of left eyelid and periocular area, subsequent encounter',
  'T2602XS Burn of left eyelid and periocular area, sequela',
  'T2610XA Burn of cornea and conjunctival sac, unspecified eye, initial encounter',
  'T2610XD Burn of cornea and conjunctival sac, unspecified eye, subsequent encounter',
  'T2610XS Burn of cornea and conjunctival sac, unspecified eye, sequela',
  'T2611XA Burn of cornea and conjunctival sac, right eye, initial encounter',
  'T2611XD Burn of cornea and conjunctival sac, right eye, subsequent encounter',
  'T2611XS Burn of cornea and conjunctival sac, right eye, sequela',
  'T2612XA Burn of cornea and conjunctival sac, left eye, initial encounter',
  'T2612XD Burn of cornea and conjunctival sac, left eye, subsequent encounter',
  'T2612XS Burn of cornea and conjunctival sac, left eye, sequela',
  'T2620XA Burn with resulting rupture and destruction of unspecified eyeball, initial encounter',
  'T2620XD Burn with resulting rupture and destruction of unspecified eyeball, subsequent encounter',
  'T2620XS Burn with resulting rupture and destruction of unspecified eyeball, sequela',
  'T2621XA Burn with resulting rupture and destruction of right eyeball, initial encounter',
  'T2621XD Burn with resulting rupture and destruction of right eyeball, subsequent encounter',
  'T2621XS Burn with resulting rupture and destruction of right eyeball, sequela',
  'T2622XA Burn with resulting rupture and destruction of left eyeball, initial encounter',
  'T2622XD Burn with resulting rupture and destruction of left eyeball, subsequent encounter',
  'T2622XS Burn with resulting rupture and destruction of left eyeball, sequela',
  'T2630XA Burns of other specified parts of unspecified eye and adnexa, initial encounter',
  'T2630XD Burns of other specified parts of unspecified eye and adnexa, subsequent encounter',
  'T2630XS Burns of other specified parts of unspecified eye and adnexa, sequela',
  'T2631XA Burns of other specified parts of right eye and adnexa, initial encounter',
  'T2631XD Burns of other specified parts of right eye and adnexa, subsequent encounter',
  'T2631XS Burns of other specified parts of right eye and adnexa, sequela',
  'T2632XA Burns of other specified parts of left eye and adnexa, initial encounter',
  'T2632XD Burns of other specified parts of left eye and adnexa, subsequent encounter',
  'T2632XS Burns of other specified parts of left eye and adnexa, sequela',
  'T2640XA Burn of unspecified eye and adnexa, part unspecified, initial encounter',
  'T2640XD Burn of unspecified eye and adnexa, part unspecified, subsequent encounter',
  'T2640XS Burn of unspecified eye and adnexa, part unspecified, sequela',
  'T2641XA Burn of right eye and adnexa, part unspecified, initial encounter',
  'T2641XD Burn of right eye and adnexa, part unspecified, subsequent encounter',
  'T2641XS Burn of right eye and adnexa, part unspecified, sequela',
  'T2642XA Burn of left eye and adnexa, part unspecified, initial encounter',
  'T2642XD Burn of left eye and adnexa, part unspecified, subsequent encounter',
  'T2642XS Burn of left eye and adnexa, part unspecified, sequela',
  'T2650XA Corrosion of unspecified eyelid and periocular area, initial encounter',
  'T2650XD Corrosion of unspecified eyelid and periocular area, subsequent encounter',
  'T2650XS Corrosion of unspecified eyelid and periocular area, sequela',
  'T2651XA Corrosion of right eyelid and periocular area, initial encounter',
  'T2651XD Corrosion of right eyelid and periocular area, subsequent encounter',
  'T2651XS Corrosion of right eyelid and periocular area, sequela',
  'T2652XA Corrosion of left eyelid and periocular area, initial encounter',
  'T2652XD Corrosion of left eyelid and periocular area, subsequent encounter',
  'T2652XS Corrosion of left eyelid and periocular area, sequela',
  'T2660XA Corrosion of cornea and conjunctival sac, unspecified eye, initial encounter',
  'T2660XD Corrosion of cornea and conjunctival sac, unspecified eye, subsequent encounter',
  'T2660XS Corrosion of cornea and conjunctival sac, unspecified eye, sequela',
  'T2661XA Corrosion of cornea and conjunctival sac, right eye, initial encounter',
  'T2661XD Corrosion of cornea and conjunctival sac, right eye, subsequent encounter',
  'T2661XS Corrosion of cornea and conjunctival sac, right eye, sequela',
  'T2662XA Corrosion of cornea and conjunctival sac, left eye, initial encounter',
  'T2662XD Corrosion of cornea and conjunctival sac, left eye, subsequent encounter',
  'T2662XS Corrosion of cornea and conjunctival sac, left eye, sequela',
  'T2670XA Corrosion with resulting rupture and destruction of unspecified eyeball, initial encounter',
  'T2670XD Corrosion with resulting rupture and destruction of unspecified eyeball, subsequent encounter',
  'T2670XS Corrosion with resulting rupture and destruction of unspecified eyeball, sequela',
  'T2671XA Corrosion with resulting rupture and destruction of right eyeball, initial encounter',
  'T2671XD Corrosion with resulting rupture and destruction of right eyeball, subsequent encounter',
  'T2671XS Corrosion with resulting rupture and destruction of right eyeball, sequela',
  'T2672XA Corrosion with resulting rupture and destruction of left eyeball, initial encounter',
  'T2672XD Corrosion with resulting rupture and destruction of left eyeball, subsequent encounter',
  'T2672XS Corrosion with resulting rupture and destruction of left eyeball, sequela',
  'T2680XA Corrosions of other specified parts of unspecified eye and adnexa, initial encounter',
  'T2680XD Corrosions of other specified parts of unspecified eye and adnexa, subsequent encounter',
  'T2680XS Corrosions of other specified parts of unspecified eye and adnexa, sequela',
  'T2681XA Corrosions of other specified parts of right eye and adnexa, initial encounter',
  'T2681XD Corrosions of other specified parts of right eye and adnexa, subsequent encounter',
  'T2681XS Corrosions of other specified parts of right eye and adnexa, sequela',
  'T2682XA Corrosions of other specified parts of left eye and adnexa, initial encounter',
  'T2682XD Corrosions of other specified parts of left eye and adnexa, subsequent encounter',
  'T2682XS Corrosions of other specified parts of left eye and adnexa, sequela',
  'T2690XA Corrosion of unspecified eye and adnexa, part unspecified, initial encounter',
  'T2690XD Corrosion of unspecified eye and adnexa, part unspecified, subsequent encounter',
  'T2690XS Corrosion of unspecified eye and adnexa, part unspecified, sequela',
  'T2691XA Corrosion of right eye and adnexa, part unspecified, initial encounter',
  'T2691XD Corrosion of right eye and adnexa, part unspecified, subsequent encounter',
  'T2691XS Corrosion of right eye and adnexa, part unspecified, sequela',
  'T2692XA Corrosion of left eye and adnexa, part unspecified, initial encounter',
  'T2692XD Corrosion of left eye and adnexa, part unspecified, subsequent encounter',
  'T2692XS Corrosion of left eye and adnexa, part unspecified, sequela',
  'T270XXA Burn of larynx and trachea, initial encounter',
  'T270XXD Burn of larynx and trachea, subsequent encounter',
  'T270XXS Burn of larynx and trachea, sequela',
  'T271XXA Burn involving larynx and trachea with lung, initial encounter',
  'T271XXD Burn involving larynx and trachea with lung, subsequent encounter',
  'T271XXS Burn involving larynx and trachea with lung, sequela',
  'T272XXA Burn of other parts of respiratory tract, initial encounter',
  'T272XXD Burn of other parts of respiratory tract, subsequent encounter',
  'T272XXS Burn of other parts of respiratory tract, sequela',
  'T273XXA Burn of respiratory tract, part unspecified, initial encounter',
  'T273XXD Burn of respiratory tract, part unspecified, subsequent encounter',
  'T273XXS Burn of respiratory tract, part unspecified, sequela',
  'T274XXA Corrosion of larynx and trachea, initial encounter',
  'T274XXD Corrosion of larynx and trachea, subsequent encounter',
  'T274XXS Corrosion of larynx and trachea, sequela',
  'T275XXA Corrosion involving larynx and trachea with lung, initial encounter',
  'T275XXD Corrosion involving larynx and trachea with lung, subsequent encounter',
  'T275XXS Corrosion involving larynx and trachea with lung, sequela',
  'T276XXA Corrosion of other parts of respiratory tract, initial encounter',
  'T276XXD Corrosion of other parts of respiratory tract, subsequent encounter',
  'T276XXS Corrosion of other parts of respiratory tract, sequela',
  'T277XXA Corrosion of respiratory tract, part unspecified, initial encounter',
  'T277XXD Corrosion of respiratory tract, part unspecified, subsequent encounter',
  'T277XXS Corrosion of respiratory tract, part unspecified, sequela',
  'T280XXA Burn of mouth and pharynx, initial encounter',
  'T280XXD Burn of mouth and pharynx, subsequent encounter',
  'T280XXS Burn of mouth and pharynx, sequela',
  'T281XXA Burn of esophagus, initial encounter',
  'T281XXD Burn of esophagus, subsequent encounter',
  'T281XXS Burn of esophagus, sequela',
  'T282XXA Burn of other parts of alimentary tract, initial encounter',
  'T282XXD Burn of other parts of alimentary tract, subsequent encounter',
  'T282XXS Burn of other parts of alimentary tract, sequela',
  'T283XXA Burn of internal genitourinary organs, initial encounter',
  'T283XXD Burn of internal genitourinary organs, subsequent encounter',
  'T283XXS Burn of internal genitourinary organs, sequela',
  'T2840XA Burn of unspecified internal organ, initial encounter',
  'T2840XD Burn of unspecified internal organ, subsequent encounter',
  'T2840XS Burn of unspecified internal organ, sequela',
  'T28411A Burn of right ear drum, initial encounter',
  'T28411D Burn of right ear drum, subsequent encounter',
  'T28411S Burn of right ear drum, sequela',
  'T28412A Burn of left ear drum, initial encounter',
  'T28412D Burn of left ear drum, subsequent encounter',
  'T28412S Burn of left ear drum, sequela',
  'T28419A Burn of unspecified ear drum, initial encounter',
  'T28419D Burn of unspecified ear drum, subsequent encounter',
  'T28419S Burn of unspecified ear drum, sequela',
  'T2849XA Burn of other internal organ, initial encounter',
  'T2849XD Burn of other internal organ, subsequent encounter',
  'T2849XS Burn of other internal organ, sequela',
  'T285XXA Corrosion of mouth and pharynx, initial encounter',
  'T285XXD Corrosion of mouth and pharynx, subsequent encounter',
  'T285XXS Corrosion of mouth and pharynx, sequela',
  'T286XXA Corrosion of esophagus, initial encounter',
  'T286XXD Corrosion of esophagus, subsequent encounter',
  'T286XXS Corrosion of esophagus, sequela',
  'T287XXA Corrosion of other parts of alimentary tract, initial encounter',
  'T287XXD Corrosion of other parts of alimentary tract, subsequent encounter',
  'T287XXS Corrosion of other parts of alimentary tract, sequela',
  'T288XXA Corrosion of internal genitourinary organs, initial encounter',
  'T288XXD Corrosion of internal genitourinary organs, subsequent encounter',
  'T288XXS Corrosion of internal genitourinary organs, sequela',
  'T2890XA Corrosions of unspecified internal organs, initial encounter',
  'T2890XD Corrosions of unspecified internal organs, subsequent encounter',
  'T2890XS Corrosions of unspecified internal organs, sequela',
  'T28911A Corrosions of right ear drum, initial encounter',
  'T28911D Corrosions of right ear drum, subsequent encounter',
  'T28911S Corrosions of right ear drum, sequela',
  'T28912A Corrosions of left ear drum, initial encounter',
  'T28912D Corrosions of left ear drum, subsequent encounter',
  'T28912S Corrosions of left ear drum, sequela',
  'T28919A Corrosions of unspecified ear drum, initial encounter',
  'T28919D Corrosions of unspecified ear drum, subsequent encounter',
  'T28919S Corrosions of unspecified ear drum, sequela',
  'T2899XA Corrosions of other internal organs, initial encounter',
  'T2899XD Corrosions of other internal organs, subsequent encounter',
  'T2899XS Corrosions of other internal organs, sequela',
  'T300 Burn of unspecified body region, unspecified degree',
  'T304 Corrosion of unspecified body region, unspecified degree',
  'T310 Burns involving less than 10% of body surface',
  'T3110 Burns involving 10-19% of body surface with 0% to 9% third degree burns',
  'T3111 Burns involving 10-19% of body surface with 10-19% third degree burns',
  'T3120 Burns involving 20-29% of body surface with 0% to 9% third degree burns',
  'T3121 Burns involving 20-29% of body surface with 10-19% third degree burns',
  'T3122 Burns involving 20-29% of body surface with 20-29% third degree burns',
  'T3130 Burns involving 30-39% of body surface with 0% to 9% third degree burns',
  'T3131 Burns involving 30-39% of body surface with 10-19% third degree burns',
  'T3132 Burns involving 30-39% of body surface with 20-29% third degree burns',
  'T3133 Burns involving 30-39% of body surface with 30-39% third degree burns',
  'T3140 Burns involving 40-49% of body surface with 0% to 9% third degree burns',
  'T3141 Burns involving 40-49% of body surface with 10-19% third degree burns',
  'T3142 Burns involving 40-49% of body surface with 20-29% third degree burns',
  'T3143 Burns involving 40-49% of body surface with 30-39% third degree burns',
  'T3144 Burns involving 40-49% of body surface with 40-49% third degree burns',
  'T3150 Burns involving 50-59% of body surface with 0% to 9% third degree burns',
  'T3151 Burns involving 50-59% of body surface with 10-19% third degree burns',
  'T3152 Burns involving 50-59% of body surface with 20-29% third degree burns',
  'T3153 Burns involving 50-59% of body surface with 30-39% third degree burns',
  'T3154 Burns involving 50-59% of body surface with 40-49% third degree burns',
  'T3155 Burns involving 50-59% of body surface with 50-59% third degree burns',
  'T3160 Burns involving 60-69% of body surface with 0% to 9% third degree burns',
  'T3161 Burns involving 60-69% of body surface with 10-19% third degree burns',
  'T3162 Burns involving 60-69% of body surface with 20-29% third degree burns',
  'T3163 Burns involving 60-69% of body surface with 30-39% third degree burns',
  'T3164 Burns involving 60-69% of body surface with 40-49% third degree burns',
  'T3165 Burns involving 60-69% of body surface with 50-59% third degree burns',
  'T3166 Burns involving 60-69% of body surface with 60-69% third degree burns',
  'T3170 Burns involving 70-79% of body surface with 0% to 9% third degree burns',
  'T3171 Burns involving 70-79% of body surface with 10-19% third degree burns',
  'T3172 Burns involving 70-79% of body surface with 20-29% third degree burns',
  'T3173 Burns involving 70-79% of body surface with 30-39% third degree burns',
  'T3174 Burns involving 70-79% of body surface with 40-49% third degree burns',
  'T3175 Burns involving 70-79% of body surface with 50-59% third degree burns',
  'T3176 Burns involving 70-79% of body surface with 60-69% third degree burns',
  'T3177 Burns involving 70-79% of body surface with 70-79% third degree burns',
  'T3180 Burns involving 80-89% of body surface with 0% to 9% third degree burns',
  'T3181 Burns involving 80-89% of body surface with 10-19% third degree burns',
  'T3182 Burns involving 80-89% of body surface with 20-29% third degree burns',
  'T3183 Burns involving 80-89% of body surface with 30-39% third degree burns',
  'T3184 Burns involving 80-89% of body surface with 40-49% third degree burns',
  'T3185 Burns involving 80-89% of body surface with 50-59% third degree burns',
  'T3186 Burns involving 80-89% of body surface with 60-69% third degree burns',
  'T3187 Burns involving 80-89% of body surface with 70-79% third degree burns',
  'T3188 Burns involving 80-89% of body surface with 80-89% third degree burns',
  'T3190 Burns involving 90% or more of body surface with 0% to 9% third degree burns',
  'T3191 Burns involving 90% or more of body surface with 10-19% third degree burns',
  'T3192 Burns involving 90% or more of body surface with 20-29% third degree burns',
  'T3193 Burns involving 90% or more of body surface with 30-39% third degree burns',
  'T3194 Burns involving 90% or more of body surface with 40-49% third degree burns',
  'T3195 Burns involving 90% or more of body surface with 50-59% third degree burns',
  'T3196 Burns involving 90% or more of body surface with 60-69% third degree burns',
  'T3197 Burns involving 90% or more of body surface with 70-79% third degree burns',
  'T3198 Burns involving 90% or more of body surface with 80-89% third degree burns',
  'T3199 Burns involving 90% or more of body surface with 90% or more third degree burns',
  'T320 Corrosions involving less than 10% of body surface',
  'T3210 Corrosions involving 10-19% of body surface with 0% to 9% third degree corrosion',
  'T3211 Corrosions involving 10-19% of body surface with 10-19% third degree corrosion',
  'T3220 Corrosions involving 20-29% of body surface with 0% to 9% third degree corrosion',
  'T3221 Corrosions involving 20-29% of body surface with 10-19% third degree corrosion',
  'T3222 Corrosions involving 20-29% of body surface with 20-29% third degree corrosion',
  'T3230 Corrosions involving 30-39% of body surface with 0% to 9% third degree corrosion',
  'T3231 Corrosions involving 30-39% of body surface with 10-19% third degree corrosion',
  'T3232 Corrosions involving 30-39% of body surface with 20-29% third degree corrosion',
  'T3233 Corrosions involving 30-39% of body surface with 30-39% third degree corrosion',
  'T3240 Corrosions involving 40-49% of body surface with 0% to 9% third degree corrosion',
  'T3241 Corrosions involving 40-49% of body surface with 10-19% third degree corrosion',
  'T3242 Corrosions involving 40-49% of body surface with 20-29% third degree corrosion',
  'T3243 Corrosions involving 40-49% of body surface with 30-39% third degree corrosion',
  'T3244 Corrosions involving 40-49% of body surface with 40-49% third degree corrosion',
  'T3250 Corrosions involving 50-59% of body surface with 0% to 9% third degree corrosion',
  'T3251 Corrosions involving 50-59% of body surface with 10-19% third degree corrosion',
  'T3252 Corrosions involving 50-59% of body surface with 20-29% third degree corrosion',
  'T3253 Corrosions involving 50-59% of body surface with 30-39% third degree corrosion',
  'T3254 Corrosions involving 50-59% of body surface with 40-49% third degree corrosion',
  'T3255 Corrosions involving 50-59% of body surface with 50-59% third degree corrosion',
  'T3260 Corrosions involving 60-69% of body surface with 0% to 9% third degree corrosion',
  'T3261 Corrosions involving 60-69% of body surface with 10-19% third degree corrosion',
  'T3262 Corrosions involving 60-69% of body surface with 20-29% third degree corrosion',
  'T3263 Corrosions involving 60-69% of body surface with 30-39% third degree corrosion',
  'T3264 Corrosions involving 60-69% of body surface with 40-49% third degree corrosion',
  'T3265 Corrosions involving 60-69% of body surface with 50-59% third degree corrosion',
  'T3266 Corrosions involving 60-69% of body surface with 60-69% third degree corrosion',
  'T3270 Corrosions involving 70-79% of body surface with 0% to 9% third degree corrosion',
  'T3271 Corrosions involving 70-79% of body surface with 10-19% third degree corrosion',
  'T3272 Corrosions involving 70-79% of body surface with 20-29% third degree corrosion',
  'T3273 Corrosions involving 70-79% of body surface with 30-39% third degree corrosion',
  'T3274 Corrosions involving 70-79% of body surface with 40-49% third degree corrosion',
  'T3275 Corrosions involving 70-79% of body surface with 50-59% third degree corrosion',
  'T3276 Corrosions involving 70-79% of body surface with 60-69% third degree corrosion',
  'T3277 Corrosions involving 70-79% of body surface with 70-79% third degree corrosion',
  'T3280 Corrosions involving 80-89% of body surface with 0% to 9% third degree corrosion',
  'T3281 Corrosions involving 80-89% of body surface with 10-19% third degree corrosion',
  'T3282 Corrosions involving 80-89% of body surface with 20-29% third degree corrosion',
  'T3283 Corrosions involving 80-89% of body surface with 30-39% third degree corrosion',
  'T3284 Corrosions involving 80-89% of body surface with 40-49% third degree corrosion',
  'T3285 Corrosions involving 80-89% of body surface with 50-59% third degree corrosion',
  'T3286 Corrosions involving 80-89% of body surface with 60-69% third degree corrosion',
  'T3287 Corrosions involving 80-89% of body surface with 70-79% third degree corrosion',
  'T3288 Corrosions involving 80-89% of body surface with 80-89% third degree corrosion',
  'T3290 Corrosions involving 90% or more of body surface with 0% to 9% third degree corrosion',
  'T3291 Corrosions involving 90% or more of body surface with 10-19% third degree corrosion',
  'T3292 Corrosions involving 90% or more of body surface with 20-29% third degree corrosion',
  'T3293 Corrosions involving 90% or more of body surface with 30-39% third degree corrosion',
  'T3294 Corrosions involving 90% or more of body surface with 40-49% third degree corrosion',
  'T3295 Corrosions involving 90% or more of body surface with 50-59% third degree corrosion',
  'T3296 Corrosions involving 90% or more of body surface with 60-69% third degree corrosion',
  'T3297 Corrosions involving 90% or more of body surface with 70-79% third degree corrosion',
  'T3298 Corrosions involving 90% or more of body surface with 80-89% third degree corrosion',
  'T3299 Corrosions involving 90% or more of body surface with 90% or more third degree corrosion',
  'T33011A Superficial frostbite of right ear, initial encounter',
  'T33011D Superficial frostbite of right ear, subsequent encounter',
  'T33011S Superficial frostbite of right ear, sequela',
  'T33012A Superficial frostbite of left ear, initial encounter',
  'T33012D Superficial frostbite of left ear, subsequent encounter',
  'T33012S Superficial frostbite of left ear, sequela',
  'T33019A Superficial frostbite of unspecified ear, initial encounter',
  'T33019D Superficial frostbite of unspecified ear, subsequent encounter',
  'T33019S Superficial frostbite of unspecified ear, sequela',
  'T3302XA Superficial frostbite of nose, initial encounter',
  'T3302XD Superficial frostbite of nose, subsequent encounter',
  'T3302XS Superficial frostbite of nose, sequela',
  'T3309XA Superficial frostbite of other part of head, initial encounter',
  'T3309XD Superficial frostbite of other part of head, subsequent encounter',
  'T3309XS Superficial frostbite of other part of head, sequela',
  'T331XXA Superficial frostbite of neck, initial encounter',
  'T331XXD Superficial frostbite of neck, subsequent encounter',
  'T331XXS Superficial frostbite of neck, sequela',
  'T332XXA Superficial frostbite of thorax, initial encounter',
  'T332XXD Superficial frostbite of thorax, subsequent encounter',
  'T332XXS Superficial frostbite of thorax, sequela',
  'T333XXA Superficial frostbite of abdominal wall, lower back and pelvis, initial encounter',
  'T333XXD Superficial frostbite of abdominal wall, lower back and pelvis, subsequent encounter',
  'T333XXS Superficial frostbite of abdominal wall, lower back and pelvis, sequela',
  'T3340XA Superficial frostbite of unspecified arm, initial encounter',
  'T3340XD Superficial frostbite of unspecified arm, subsequent encounter',
  'T3340XS Superficial frostbite of unspecified arm, sequela',
  'T3341XA Superficial frostbite of right arm, initial encounter',
  'T3341XD Superficial frostbite of right arm, subsequent encounter',
  'T3341XS Superficial frostbite of right arm, sequela',
  'T3342XA Superficial frostbite of left arm, initial encounter',
  'T3342XD Superficial frostbite of left arm, subsequent encounter',
  'T3342XS Superficial frostbite of left arm, sequela',
  'T33511A Superficial frostbite of right wrist, initial encounter',
  'T33511D Superficial frostbite of right wrist, subsequent encounter',
  'T33511S Superficial frostbite of right wrist, sequela',
  'T33512A Superficial frostbite of left wrist, initial encounter',
  'T33512D Superficial frostbite of left wrist, subsequent encounter',
  'T33512S Superficial frostbite of left wrist, sequela',
  'T33519A Superficial frostbite of unspecified wrist, initial encounter',
  'T33519D Superficial frostbite of unspecified wrist, subsequent encounter',
  'T33519S Superficial frostbite of unspecified wrist, sequela',
  'T33521A Superficial frostbite of right hand, initial encounter',
  'T33521D Superficial frostbite of right hand, subsequent encounter',
  'T33521S Superficial frostbite of right hand, sequela',
  'T33522A Superficial frostbite of left hand, initial encounter',
  'T33522D Superficial frostbite of left hand, subsequent encounter',
  'T33522S Superficial frostbite of left hand, sequela',
  'T33529A Superficial frostbite of unspecified hand, initial encounter',
  'T33529D Superficial frostbite of unspecified hand, subsequent encounter',
  'T33529S Superficial frostbite of unspecified hand, sequela',
  'T33531A Superficial frostbite of right finger(s), initial encounter',
  'T33531D Superficial frostbite of right finger(s), subsequent encounter',
  'T33531S Superficial frostbite of right finger(s), sequela',
  'T33532A Superficial frostbite of left finger(s), initial encounter',
  'T33532D Superficial frostbite of left finger(s), subsequent encounter',
  'T33532S Superficial frostbite of left finger(s), sequela',
  'T33539A Superficial frostbite of unspecified finger(s), initial encounter',
  'T33539D Superficial frostbite of unspecified finger(s), subsequent encounter',
  'T33539S Superficial frostbite of unspecified finger(s), sequela',
  'T3360XA Superficial frostbite of unspecified hip and thigh, initial encounter',
  'T3360XD Superficial frostbite of unspecified hip and thigh, subsequent encounter',
  'T3360XS Superficial frostbite of unspecified hip and thigh, sequela',
  'T3361XA Superficial frostbite of right hip and thigh, initial encounter',
  'T3361XD Superficial frostbite of right hip and thigh, subsequent encounter',
  'T3361XS Superficial frostbite of right hip and thigh, sequela',
  'T3362XA Superficial frostbite of left hip and thigh, initial encounter',
  'T3362XD Superficial frostbite of left hip and thigh, subsequent encounter',
  'T3362XS Superficial frostbite of left hip and thigh, sequela',
  'T3370XA Superficial frostbite of unspecified knee and lower leg, initial encounter',
  'T3370XD Superficial frostbite of unspecified knee and lower leg, subsequent encounter',
  'T3370XS Superficial frostbite of unspecified knee and lower leg, sequela',
  'T3371XA Superficial frostbite of right knee and lower leg, initial encounter',
  'T3371XD Superficial frostbite of right knee and lower leg, subsequent encounter',
  'T3371XS Superficial frostbite of right knee and lower leg, sequela',
  'T3372XA Superficial frostbite of left knee and lower leg, initial encounter',
  'T3372XD Superficial frostbite of left knee and lower leg, subsequent encounter',
  'T3372XS Superficial frostbite of left knee and lower leg, sequela',
  'T33811A Superficial frostbite of right ankle, initial encounter',
  'T33811D Superficial frostbite of right ankle, subsequent encounter',
  'T33811S Superficial frostbite of right ankle, sequela',
  'T33812A Superficial frostbite of left ankle, initial encounter',
  'T33812D Superficial frostbite of left ankle, subsequent encounter',
  'T33812S Superficial frostbite of left ankle, sequela',
  'T33819A Superficial frostbite of unspecified ankle, initial encounter',
  'T33819D Superficial frostbite of unspecified ankle, subsequent encounter',
  'T33819S Superficial frostbite of unspecified ankle, sequela',
  'T33821A Superficial frostbite of right foot, initial encounter',
  'T33821D Superficial frostbite of right foot, subsequent encounter',
  'T33821S Superficial frostbite of right foot, sequela',
  'T33822A Superficial frostbite of left foot, initial encounter',
  'T33822D Superficial frostbite of left foot, subsequent encounter',
  'T33822S Superficial frostbite of left foot, sequela',
  'T33829A Superficial frostbite of unspecified foot, initial encounter',
  'T33829D Superficial frostbite of unspecified foot, subsequent encounter',
  'T33829S Superficial frostbite of unspecified foot, sequela',
  'T33831A Superficial frostbite of right toe(s), initial encounter',
  'T33831D Superficial frostbite of right toe(s), subsequent encounter',
  'T33831S Superficial frostbite of right toe(s), sequela',
  'T33832A Superficial frostbite of left toe(s), initial encounter',
  'T33832D Superficial frostbite of left toe(s), subsequent encounter',
  'T33832S Superficial frostbite of left toe(s), sequela',
  'T33839A Superficial frostbite of unspecified toe(s), initial encounter',
  'T33839D Superficial frostbite of unspecified toe(s), subsequent encounter',
  'T33839S Superficial frostbite of unspecified toe(s), sequela',
  'T3390XA Superficial frostbite of unspecified sites, initial encounter',
  'T3390XD Superficial frostbite of unspecified sites, subsequent encounter',
  'T3390XS Superficial frostbite of unspecified sites, sequela',
  'T3399XA Superficial frostbite of other sites, initial encounter',
  'T3399XD Superficial frostbite of other sites, subsequent encounter',
  'T3399XS Superficial frostbite of other sites, sequela',
  'T34011A Frostbite with tissue necrosis of right ear, initial encounter',
  'T34011D Frostbite with tissue necrosis of right ear, subsequent encounter',
  'T34011S Frostbite with tissue necrosis of right ear, sequela',
  'T34012A Frostbite with tissue necrosis of left ear, initial encounter',
  'T34012D Frostbite with tissue necrosis of left ear, subsequent encounter',
  'T34012S Frostbite with tissue necrosis of left ear, sequela',
  'T34019A Frostbite with tissue necrosis of unspecified ear, initial encounter',
  'T34019D Frostbite with tissue necrosis of unspecified ear, subsequent encounter',
  'T34019S Frostbite with tissue necrosis of unspecified ear, sequela',
  'T3402XA Frostbite with tissue necrosis of nose, initial encounter',
  'T3402XD Frostbite with tissue necrosis of nose, subsequent encounter',
  'T3402XS Frostbite with tissue necrosis of nose, sequela',
  'T3409XA Frostbite with tissue necrosis of other part of head, initial encounter',
  'T3409XD Frostbite with tissue necrosis of other part of head, subsequent encounter',
  'T3409XS Frostbite with tissue necrosis of other part of head, sequela',
  'T341XXA Frostbite with tissue necrosis of neck, initial encounter',
  'T341XXD Frostbite with tissue necrosis of neck, subsequent encounter',
  'T341XXS Frostbite with tissue necrosis of neck, sequela',
  'T342XXA Frostbite with tissue necrosis of thorax, initial encounter',
  'T342XXD Frostbite with tissue necrosis of thorax, subsequent encounter',
  'T342XXS Frostbite with tissue necrosis of thorax, sequela',
  'T343XXA Frostbite with tissue necrosis of abdominal wall, lower back and pelvis, initial encounter',
  'T343XXD Frostbite with tissue necrosis of abdominal wall, lower back and pelvis, subsequent encounter',
  'T343XXS Frostbite with tissue necrosis of abdominal wall, lower back and pelvis, sequela',
  'T3440XA Frostbite with tissue necrosis of unspecified arm, initial encounter',
  'T3440XD Frostbite with tissue necrosis of unspecified arm, subsequent encounter',
  'T3440XS Frostbite with tissue necrosis of unspecified arm, sequela',
  'T3441XA Frostbite with tissue necrosis of right arm, initial encounter',
  'T3441XD Frostbite with tissue necrosis of right arm, subsequent encounter',
  'T3441XS Frostbite with tissue necrosis of right arm, sequela',
  'T3442XA Frostbite with tissue necrosis of left arm, initial encounter',
  'T3442XD Frostbite with tissue necrosis of left arm, subsequent encounter',
  'T3442XS Frostbite with tissue necrosis of left arm, sequela',
  'T34511A Frostbite with tissue necrosis of right wrist, initial encounter',
  'T34511D Frostbite with tissue necrosis of right wrist, subsequent encounter',
  'T34511S Frostbite with tissue necrosis of right wrist, sequela',
  'T34512A Frostbite with tissue necrosis of left wrist, initial encounter',
  'T34512D Frostbite with tissue necrosis of left wrist, subsequent encounter',
  'T34512S Frostbite with tissue necrosis of left wrist, sequela',
  'T34519A Frostbite with tissue necrosis of unspecified wrist, initial encounter',
  'T34519D Frostbite with tissue necrosis of unspecified wrist, subsequent encounter',
  'T34519S Frostbite with tissue necrosis of unspecified wrist, sequela',
  'T34521A Frostbite with tissue necrosis of right hand, initial encounter',
  'T34521D Frostbite with tissue necrosis of right hand, subsequent encounter',
  'T34521S Frostbite with tissue necrosis of right hand, sequela',
  'T34522A Frostbite with tissue necrosis of left hand, initial encounter',
  'T34522D Frostbite with tissue necrosis of left hand, subsequent encounter',
  'T34522S Frostbite with tissue necrosis of left hand, sequela',
  'T34529A Frostbite with tissue necrosis of unspecified hand, initial encounter',
  'T34529D Frostbite with tissue necrosis of unspecified hand, subsequent encounter',
  'T34529S Frostbite with tissue necrosis of unspecified hand, sequela',
  'T34531A Frostbite with tissue necrosis of right finger(s), initial encounter',
  'T34531D Frostbite with tissue necrosis of right finger(s), subsequent encounter',
  'T34531S Frostbite with tissue necrosis of right finger(s), sequela',
  'T34532A Frostbite with tissue necrosis of left finger(s), initial encounter',
  'T34532D Frostbite with tissue necrosis of left finger(s), subsequent encounter',
  'T34532S Frostbite with tissue necrosis of left finger(s), sequela',
  'T34539A Frostbite with tissue necrosis of unspecified finger(s), initial encounter',
  'T34539D Frostbite with tissue necrosis of unspecified finger(s), subsequent encounter',
  'T34539S Frostbite with tissue necrosis of unspecified finger(s), sequela',
  'T3460XA Frostbite with tissue necrosis of unspecified hip and thigh, initial encounter',
  'T3460XD Frostbite with tissue necrosis of unspecified hip and thigh, subsequent encounter',
  'T3460XS Frostbite with tissue necrosis of unspecified hip and thigh, sequela',
  'T3461XA Frostbite with tissue necrosis of right hip and thigh, initial encounter',
  'T3461XD Frostbite with tissue necrosis of right hip and thigh, subsequent encounter',
  'T3461XS Frostbite with tissue necrosis of right hip and thigh, sequela',
  'T3462XA Frostbite with tissue necrosis of left hip and thigh, initial encounter',
  'T3462XD Frostbite with tissue necrosis of left hip and thigh, subsequent encounter',
  'T3462XS Frostbite with tissue necrosis of left hip and thigh, sequela',
  'T3470XA Frostbite with tissue necrosis of unspecified knee and lower leg, initial encounter',
  'T3470XD Frostbite with tissue necrosis of unspecified knee and lower leg, subsequent encounter',
  'T3470XS Frostbite with tissue necrosis of unspecified knee and lower leg, sequela',
  'T3471XA Frostbite with tissue necrosis of right knee and lower leg, initial encounter',
  'T3471XD Frostbite with tissue necrosis of right knee and lower leg, subsequent encounter',
  'T3471XS Frostbite with tissue necrosis of right knee and lower leg, sequela',
  'T3472XA Frostbite with tissue necrosis of left knee and lower leg, initial encounter',
  'T3472XD Frostbite with tissue necrosis of left knee and lower leg, subsequent encounter',
  'T3472XS Frostbite with tissue necrosis of left knee and lower leg, sequela',
  'T34811A Frostbite with tissue necrosis of right ankle, initial encounter',
  'T34811D Frostbite with tissue necrosis of right ankle, subsequent encounter',
  'T34811S Frostbite with tissue necrosis of right ankle, sequela',
  'T34812A Frostbite with tissue necrosis of left ankle, initial encounter',
  'T34812D Frostbite with tissue necrosis of left ankle, subsequent encounter',
  'T34812S Frostbite with tissue necrosis of left ankle, sequela',
  'T34819A Frostbite with tissue necrosis of unspecified ankle, initial encounter',
  'T34819D Frostbite with tissue necrosis of unspecified ankle, subsequent encounter',
  'T34819S Frostbite with tissue necrosis of unspecified ankle, sequela',
  'T34821A Frostbite with tissue necrosis of right foot, initial encounter',
  'T34821D Frostbite with tissue necrosis of right foot, subsequent encounter',
  'T34821S Frostbite with tissue necrosis of right foot, sequela',
  'T34822A Frostbite with tissue necrosis of left foot, initial encounter',
  'T34822D Frostbite with tissue necrosis of left foot, subsequent encounter',
  'T34822S Frostbite with tissue necrosis of left foot, sequela',
  'T34829A Frostbite with tissue necrosis of unspecified foot, initial encounter',
  'T34829D Frostbite with tissue necrosis of unspecified foot, subsequent encounter',
  'T34829S Frostbite with tissue necrosis of unspecified foot, sequela',
  'T34831A Frostbite with tissue necrosis of right toe(s), initial encounter',
  'T34831D Frostbite with tissue necrosis of right toe(s), subsequent encounter',
  'T34831S Frostbite with tissue necrosis of right toe(s), sequela',
  'T34832A Frostbite with tissue necrosis of left toe(s), initial encounter',
  'T34832D Frostbite with tissue necrosis of left toe(s), subsequent encounter',
  'T34832S Frostbite with tissue necrosis of left toe(s), sequela',
  'T34839A Frostbite with tissue necrosis of unspecified toe(s), initial encounter',
  'T34839D Frostbite with tissue necrosis of unspecified toe(s), subsequent encounter',
  'T34839S Frostbite with tissue necrosis of unspecified toe(s), sequela',
  'T3490XA Frostbite with tissue necrosis of unspecified sites, initial encounter',
  'T3490XD Frostbite with tissue necrosis of unspecified sites, subsequent encounter',
  'T3490XS Frostbite with tissue necrosis of unspecified sites, sequela',
  'T3499XA Frostbite with tissue necrosis of other sites, initial encounter',
  'T3499XD Frostbite with tissue necrosis of other sites, subsequent encounter',
  'T3499XS Frostbite with tissue necrosis of other sites, sequela',
  'T360X1A Poisoning by penicillins, accidental (unintentional), initial encounter',
  'T360X1D Poisoning by penicillins, accidental (unintentional), subsequent encounter',
  'T360X1S Poisoning by penicillins, accidental (unintentional), sequela',
  'T360X2A Poisoning by penicillins, intentional self-harm, initial encounter',
  'T360X2D Poisoning by penicillins, intentional self-harm, subsequent encounter',
  'T360X2S Poisoning by penicillins, intentional self-harm, sequela',
  'T360X3A Poisoning by penicillins, assault, initial encounter',
  'T360X3D Poisoning by penicillins, assault, subsequent encounter',
  'T360X3S Poisoning by penicillins, assault, sequela',
  'T360X4A Poisoning by penicillins, undetermined, initial encounter',
  'T360X4D Poisoning by penicillins, undetermined, subsequent encounter',
  'T360X4S Poisoning by penicillins, undetermined, sequela',
  'T360X5A Adverse effect of penicillins, initial encounter',
  'T360X5D Adverse effect of penicillins, subsequent encounter',
  'T360X5S Adverse effect of penicillins, sequela',
  'T360X6A Underdosing of penicillins, initial encounter',
  'T360X6D Underdosing of penicillins, subsequent encounter',
  'T360X6S Underdosing of penicillins, sequela',
  'T361X1A Poisoning by cephalosporins and other beta-lactam antibiotics, accidental (unintentional),',
  'initial encounter',
  'T361X1D Poisoning by cephalosporins and other beta-lactam antibiotics, accidental (unintentional),',
  'subsequent encounter',
  'T361X1S Poisoning by cephalosporins and other beta-lactam antibiotics, accidental (unintentional),',
  'sequela',
  'T361X2A Poisoning by cephalosporins and other beta-lactam antibiotics, intentional self-harm, initial',
  'encounter',
  'T361X2D Poisoning by cephalosporins and other beta-lactam antibiotics, intentional self-harm,',
  'subsequent encounter',
  'T361X2S Poisoning by cephalosporins and other beta-lactam antibiotics, intentional self-harm, sequela',
  'T361X3A Poisoning by cephalosporins and other beta-lactam antibiotics, assault, initial encounter',
  'T361X3D Poisoning by cephalosporins and other beta-lactam antibiotics, assault, subsequent encounter',
  'T361X3S Poisoning by cephalosporins and other beta-lactam antibiotics, assault, sequela',
  'T361X4A Poisoning by cephalosporins and other beta-lactam antibiotics, undetermined, initial',
  'encounter',
  'T361X4D Poisoning by cephalosporins and other beta-lactam antibiotics, undetermined, subsequent',
  'encounter',
  'T361X4S Poisoning by cephalosporins and other beta-lactam antibiotics, undetermined, sequela',
  'T361X5A Adverse effect of cephalosporins and other beta-lactam antibiotics, initial encounter',
  'T361X5D Adverse effect of cephalosporins and other beta-lactam antibiotics, subsequent encounter',
  'T361X5S Adverse effect of cephalosporins and other beta-lactam antibiotics, sequela',
  'T361X6A Underdosing of cephalosporins and other beta-lactam antibiotics, initial encounter',
  'T361X6D Underdosing of cephalosporins and other beta-lactam antibiotics, subsequent encounter',
  'T361X6S Underdosing of cephalosporins and other beta-lactam antibiotics, sequela',
  'T362X1A Poisoning by chloramphenicol group, accidental (unintentional), initial encounter',
  'T362X1D Poisoning by chloramphenicol group, accidental (unintentional), subsequent encounter',
  'T362X1S Poisoning by chloramphenicol group, accidental (unintentional), sequela',
  'T362X2A Poisoning by chloramphenicol group, intentional self-harm, initial encounter',
  'T362X2D Poisoning by chloramphenicol group, intentional self-harm, subsequent encounter',
  'T362X2S Poisoning by chloramphenicol group, intentional self-harm, sequela',
  'T362X3A Poisoning by chloramphenicol group, assault, initial encounter',
  'T362X3D Poisoning by chloramphenicol group, assault, subsequent encounter',
  'T362X3S Poisoning by chloramphenicol group, assault, sequela',
  'T362X4A Poisoning by chloramphenicol group, undetermined, initial encounter',
  'T362X4D Poisoning by chloramphenicol group, undetermined, subsequent encounter',
  'T362X4S Poisoning by chloramphenicol group, undetermined, sequela',
  'T362X5A Adverse effect of chloramphenicol group, initial encounter',
  'T362X5D Adverse effect of chloramphenicol group, subsequent encounter',
  'T362X5S Adverse effect of chloramphenicol group, sequela',
  'T362X6A Underdosing of chloramphenicol group, initial encounter',
  'T362X6D Underdosing of chloramphenicol group, subsequent encounter',
  'T362X6S Underdosing of chloramphenicol group, sequela',
  'T363X1A Poisoning by macrolides, accidental (unintentional), initial encounter',
  'T363X1D Poisoning by macrolides, accidental (unintentional), subsequent encounter',
  'T363X1S Poisoning by macrolides, accidental (unintentional), sequela',
  'T363X2A Poisoning by macrolides, intentional self-harm, initial encounter',
  'T363X2D Poisoning by macrolides, intentional self-harm, subsequent encounter',
  'T363X2S Poisoning by macrolides, intentional self-harm, sequela',
  'T363X3A Poisoning by macrolides, assault, initial encounter',
  'T363X3D Poisoning by macrolides, assault, subsequent encounter',
  'T363X3S Poisoning by macrolides, assault, sequela',
  'T363X4A Poisoning by macrolides, undetermined, initial encounter',
  'T363X4D Poisoning by macrolides, undetermined, subsequent encounter',
  'T363X4S Poisoning by macrolides, undetermined, sequela',
  'T363X5A Adverse effect of macrolides, initial encounter',
  'T363X5D Adverse effect of macrolides, subsequent encounter',
  'T363X5S Adverse effect of macrolides, sequela',
  'T363X6A Underdosing of macrolides, initial encounter',
  'T363X6D Underdosing of macrolides, subsequent encounter',
  'T363X6S Underdosing of macrolides, sequela',
  'T364X1A Poisoning by tetracyclines, accidental (unintentional), initial encounter',
  'T364X1D Poisoning by tetracyclines, accidental (unintentional), subsequent encounter',
  'T364X1S Poisoning by tetracyclines, accidental (unintentional), sequela',
  'T364X2A Poisoning by tetracyclines, intentional self-harm, initial encounter',
  'T364X2D Poisoning by tetracyclines, intentional self-harm, subsequent encounter',
  'T364X2S Poisoning by tetracyclines, intentional self-harm, sequela',
  'T364X3A Poisoning by tetracyclines, assault, initial encounter',
  'T364X3D Poisoning by tetracyclines, assault, subsequent encounter',
  'T364X3S Poisoning by tetracyclines, assault, sequela',
  'T364X4A Poisoning by tetracyclines, undetermined, initial encounter',
  'T364X4D Poisoning by tetracyclines, undetermined, subsequent encounter',
  'T364X4S Poisoning by tetracyclines, undetermined, sequela',
  'T364X5A Adverse effect of tetracyclines, initial encounter',
  'T364X5D Adverse effect of tetracyclines, subsequent encounter',
  'T364X5S Adverse effect of tetracyclines, sequela',
  'T364X6A Underdosing of tetracyclines, initial encounter',
  'T364X6D Underdosing of tetracyclines, subsequent encounter',
  'T364X6S Underdosing of tetracyclines, sequela',
  'T365X1A Poisoning by aminoglycosides, accidental (unintentional), initial encounter',
  'T365X1D Poisoning by aminoglycosides, accidental (unintentional), subsequent encounter',
  'T365X1S Poisoning by aminoglycosides, accidental (unintentional), sequela',
  'T365X2A Poisoning by aminoglycosides, intentional self-harm, initial encounter',
  'T365X2D Poisoning by aminoglycosides, intentional self-harm, subsequent encounter',
  'T365X2S Poisoning by aminoglycosides, intentional self-harm, sequela',
  'T365X3A Poisoning by aminoglycosides, assault, initial encounter',
  'T365X3D Poisoning by aminoglycosides, assault, subsequent encounter',
  'T365X3S Poisoning by aminoglycosides, assault, sequela',
  'T365X4A Poisoning by aminoglycosides, undetermined, initial encounter',
  'T365X4D Poisoning by aminoglycosides, undetermined, subsequent encounter',
  'T365X4S Poisoning by aminoglycosides, undetermined, sequela',
  'T365X5A Adverse effect of aminoglycosides, initial encounter',
  'T365X5D Adverse effect of aminoglycosides, subsequent encounter',
  'T365X5S Adverse effect of aminoglycosides, sequela',
  'T365X6A Underdosing of aminoglycosides, initial encounter',
  'T365X6D Underdosing of aminoglycosides, subsequent encounter',
  'T365X6S Underdosing of aminoglycosides, sequela',
  'T366X1A Poisoning by rifampicins, accidental (unintentional), initial encounter',
  'T366X1D Poisoning by rifampicins, accidental (unintentional), subsequent encounter',
  'T366X1S Poisoning by rifampicins, accidental (unintentional), sequela',
  'T366X2A Poisoning by rifampicins, intentional self-harm, initial encounter',
  'T366X2D Poisoning by rifampicins, intentional self-harm, subsequent encounter',
  'T366X2S Poisoning by rifampicins, intentional self-harm, sequela',
  'T366X3A Poisoning by rifampicins, assault, initial encounter',
  'T366X3D Poisoning by rifampicins, assault, subsequent encounter',
  'T366X3S Poisoning by rifampicins, assault, sequela',
  'T366X4A Poisoning by rifampicins, undetermined, initial encounter',
  'T366X4D Poisoning by rifampicins, undetermined, subsequent encounter',
  'T366X4S Poisoning by rifampicins, undetermined, sequela',
  'T366X5A Adverse effect of rifampicins, initial encounter',
  'T366X5D Adverse effect of rifampicins, subsequent encounter',
  'T366X5S Adverse effect of rifampicins, sequela',
  'T366X6A Underdosing of rifampicins, initial encounter',
  'T366X6D Underdosing of rifampicins, subsequent encounter',
  'T366X6S Underdosing of rifampicins, sequela',
  'T367X1A Poisoning by antifungal antibiotics, systemically used, accidental (unintentional), initial',
  'encounter',
  'T367X1D Poisoning by antifungal antibiotics, systemically used, accidental (unintentional), subsequent',
  'encounter',
  'T367X1S Poisoning by antifungal antibiotics, systemically used, accidental (unintentional), sequela',
  'T367X2A Poisoning by antifungal antibiotics, systemically used, intentional self-harm, initial encounter',
  'T367X2D Poisoning by antifungal antibiotics, systemically used, intentional self-harm, subsequent',
  'encounter',
  'T367X2S Poisoning by antifungal antibiotics, systemically used, intentional self-harm, sequela',
  'T367X3A Poisoning by antifungal antibiotics, systemically used, assault, initial encounter',
  'T367X3D Poisoning by antifungal antibiotics, systemically used, assault, subsequent encounter',
  'T367X3S Poisoning by antifungal antibiotics, systemically used, assault, sequela',
  'T367X4A Poisoning by antifungal antibiotics, systemically used, undetermined, initial encounter',
  'T367X4D Poisoning by antifungal antibiotics, systemically used, undetermined, subsequent encounter',
  'T367X4S Poisoning by antifungal antibiotics, systemically used, undetermined, sequela',
  'T367X5A Adverse effect of antifungal antibiotics, systemically used, initial encounter',
  'T367X5D Adverse effect of antifungal antibiotics, systemically used, subsequent encounter',
  'T367X5S Adverse effect of antifungal antibiotics, systemically used, sequela',
  'T367X6A Underdosing of antifungal antibiotics, systemically used, initial encounter',
  'T367X6D Underdosing of antifungal antibiotics, systemically used, subsequent encounter',
  'T367X6S Underdosing of antifungal antibiotics, systemically used, sequela',
  'T368X1A Poisoning by other systemic antibiotics, accidental (unintentional), initial encounter',
  'T368X1D Poisoning by other systemic antibiotics, accidental (unintentional), subsequent encounter',
  'T368X1S Poisoning by other systemic antibiotics, accidental (unintentional), sequela',
  'T368X2A Poisoning by other systemic antibiotics, intentional self-harm, initial encounter',
  'T368X2D Poisoning by other systemic antibiotics, intentional self-harm, subsequent encounter',
  'T368X2S Poisoning by other systemic antibiotics, intentional self-harm, sequela',
  'T368X3A Poisoning by other systemic antibiotics, assault, initial encounter',
  'T368X3D Poisoning by other systemic antibiotics, assault, subsequent encounter',
  'T368X3S Poisoning by other systemic antibiotics, assault, sequela',
  'T368X4A Poisoning by other systemic antibiotics, undetermined, initial encounter',
  'T368X4D Poisoning by other systemic antibiotics, undetermined, subsequent encounter',
  'T368X4S Poisoning by other systemic antibiotics, undetermined, sequela',
  'T368X5A Adverse effect of other systemic antibiotics, initial encounter',
  'T368X5D Adverse effect of other systemic antibiotics, subsequent encounter',
  'T368X5S Adverse effect of other systemic antibiotics, sequela',
  'T368X6A Underdosing of other systemic antibiotics, initial encounter',
  'T368X6D Underdosing of other systemic antibiotics, subsequent encounter',
  'T368X6S Underdosing of other systemic antibiotics, sequela',
  'T3691XA Poisoning by unspecified systemic antibiotic, accidental (unintentional), initial encounter',
  'T3691XD Poisoning by unspecified systemic antibiotic, accidental (unintentional), subsequent encounter',
  'T3691XS Poisoning by unspecified systemic antibiotic, accidental (unintentional), sequela',
  'T3692XA Poisoning by unspecified systemic antibiotic, intentional self-harm, initial encounter',
  'T3692XD Poisoning by unspecified systemic antibiotic, intentional self-harm, subsequent encounter',
  'T3692XS Poisoning by unspecified systemic antibiotic, intentional self-harm, sequela',
  'T3693XA Poisoning by unspecified systemic antibiotic, assault, initial encounter',
  'T3693XD Poisoning by unspecified systemic antibiotic, assault, subsequent encounter',
  'T3693XS Poisoning by unspecified systemic antibiotic, assault, sequela',
  'T3694XA Poisoning by unspecified systemic antibiotic, undetermined, initial encounter',
  'T3694XD Poisoning by unspecified systemic antibiotic, undetermined, subsequent encounter',
  'T3694XS Poisoning by unspecified systemic antibiotic, undetermined, sequela',
  'T3695XA Adverse effect of unspecified systemic antibiotic, initial encounter',
  'T3695XD Adverse effect of unspecified systemic antibiotic, subsequent encounter',
  'T3695XS Adverse effect of unspecified systemic antibiotic, sequela',
  'T3696XA Underdosing of unspecified systemic antibiotic, initial encounter',
  'T3696XD Underdosing of unspecified systemic antibiotic, subsequent encounter',
  'T3696XS Underdosing of unspecified systemic antibiotic, sequela',
  'T370X1A Poisoning by sulfonamides, accidental (unintentional), initial encounter',
  'T370X1D Poisoning by sulfonamides, accidental (unintentional), subsequent encounter',
  'T370X1S Poisoning by sulfonamides, accidental (unintentional), sequela',
  'T370X2A Poisoning by sulfonamides, intentional self-harm, initial encounter',
  'T370X2D Poisoning by sulfonamides, intentional self-harm, subsequent encounter',
  'T370X2S Poisoning by sulfonamides, intentional self-harm, sequela',
  'T370X3A Poisoning by sulfonamides, assault, initial encounter',
  'T370X3D Poisoning by sulfonamides, assault, subsequent encounter',
  'T370X3S Poisoning by sulfonamides, assault, sequela',
  'T370X4A Poisoning by sulfonamides, undetermined, initial encounter',
  'T370X4D Poisoning by sulfonamides, undetermined, subsequent encounter',
  'T370X4S Poisoning by sulfonamides, undetermined, sequela',
  'T370X5A Adverse effect of sulfonamides, initial encounter',
  'T370X5D Adverse effect of sulfonamides, subsequent encounter',
  'T370X5S Adverse effect of sulfonamides, sequela',
  'T370X6A Underdosing of sulfonamides, initial encounter',
  'T370X6D Underdosing of sulfonamides, subsequent encounter',
  'T370X6S Underdosing of sulfonamides, sequela',
  'T371X1A Poisoning by antimycobacterial drugs, accidental (unintentional), initial encounter',
  'T371X1D Poisoning by antimycobacterial drugs, accidental (unintentional), subsequent encounter',
  'T371X1S Poisoning by antimycobacterial drugs, accidental (unintentional), sequela',
  'T371X2A Poisoning by antimycobacterial drugs, intentional self-harm, initial encounter',
  'T371X2D Poisoning by antimycobacterial drugs, intentional self-harm, subsequent encounter',
  'T371X2S Poisoning by antimycobacterial drugs, intentional self-harm, sequela',
  'T371X3A Poisoning by antimycobacterial drugs, assault, initial encounter',
  'T371X3D Poisoning by antimycobacterial drugs, assault, subsequent encounter',
  'T371X3S Poisoning by antimycobacterial drugs, assault, sequela',
  'T371X4A Poisoning by antimycobacterial drugs, undetermined, initial encounter',
  'T371X4D Poisoning by antimycobacterial drugs, undetermined, subsequent encounter',
  'T371X4S Poisoning by antimycobacterial drugs, undetermined, sequela',
  'T371X5A Adverse effect of antimycobacterial drugs, initial encounter',
  'T371X5D Adverse effect of antimycobacterial drugs, subsequent encounter',
  'T371X5S Adverse effect of antimycobacterial drugs, sequela',
  'T371X6A Underdosing of antimycobacterial drugs, initial encounter',
  'T371X6D Underdosing of antimycobacterial drugs, subsequent encounter',
  'T371X6S Underdosing of antimycobacterial drugs, sequela',
  'T372X1A Poisoning by antimalarials and drugs acting on other blood protozoa, accidental',
  '(unintentional), initial encounter',
  'T372X1D Poisoning by antimalarials and drugs acting on other blood protozoa, accidental',
  '(unintentional), subsequent encounter',
  'T372X1S Poisoning by antimalarials and drugs acting on other blood protozoa, accidental',
  '(unintentional), sequela',
  'T372X2A Poisoning by antimalarials and drugs acting on other blood protozoa, intentional self-harm,',
  'initial encounter',
  'T372X2D Poisoning by antimalarials and drugs acting on other blood protozoa, intentional self-harm,',
  'subsequent encounter',
  'T372X2S Poisoning by antimalarials and drugs acting on other blood protozoa, intentional self-harm,',
  'sequela',
  'T372X3A Poisoning by antimalarials and drugs acting on other blood protozoa, assault, initial encounter',
  'T372X3D Poisoning by antimalarials and drugs acting on other blood protozoa, assault, subsequent',
  'encounter',
  'T372X3S Poisoning by antimalarials and drugs acting on other blood protozoa, assault, sequela',
  'T372X4A Poisoning by antimalarials and drugs acting on other blood protozoa, undetermined, initial',
  'encounter',
  'T372X4D Poisoning by antimalarials and drugs acting on other blood protozoa, undetermined,',
  'subsequent encounter',
  'T372X4S Poisoning by antimalarials and drugs acting on other blood protozoa, undetermined, sequela',
  'T372X5A Adverse effect of antimalarials and drugs acting on other blood protozoa, initial encounter',
  'T372X5D Adverse effect of antimalarials and drugs acting on other blood protozoa, subsequent',
  'encounter',
  'T372X5S Adverse effect of antimalarials and drugs acting on other blood protozoa, sequela',
  'T372X6A Underdosing of antimalarials and drugs acting on other blood protozoa, initial encounter',
  'T372X6D Underdosing of antimalarials and drugs acting on other blood protozoa, subsequent encounter',
  'T372X6S Underdosing of antimalarials and drugs acting on other blood protozoa, sequela',
  'T373X1A Poisoning by other antiprotozoal drugs, accidental (unintentional), initial encounter',
  'T373X1D Poisoning by other antiprotozoal drugs, accidental (unintentional), subsequent encounter',
  'T373X1S Poisoning by other antiprotozoal drugs, accidental (unintentional), sequela',
  'T373X2A Poisoning by other antiprotozoal drugs, intentional self-harm, initial encounter',
  'T373X2D Poisoning by other antiprotozoal drugs, intentional self-harm, subsequent encounter',
  'T373X2S Poisoning by other antiprotozoal drugs, intentional self-harm, sequela',
  'T373X3A Poisoning by other antiprotozoal drugs, assault, initial encounter',
  'T373X3D Poisoning by other antiprotozoal drugs, assault, subsequent encounter',
  'T373X3S Poisoning by other antiprotozoal drugs, assault, sequela',
  'T373X4A Poisoning by other antiprotozoal drugs, undetermined, initial encounter',
  'T373X4D Poisoning by other antiprotozoal drugs, undetermined, subsequent encounter',
  'T373X4S Poisoning by other antiprotozoal drugs, undetermined, sequela',
  'T373X5A Adverse effect of other antiprotozoal drugs, initial encounter',
  'T373X5D Adverse effect of other antiprotozoal drugs, subsequent encounter',
  'T373X5S Adverse effect of other antiprotozoal drugs, sequela',
  'T373X6A Underdosing of other antiprotozoal drugs, initial encounter',
  'T373X6D Underdosing of other antiprotozoal drugs, subsequent encounter',
  'T373X6S Underdosing of other antiprotozoal drugs, sequela',
  'T374X1A Poisoning by anthelminthics, accidental (unintentional), initial encounter',
  'T374X1D Poisoning by anthelminthics, accidental (unintentional), subsequent encounter',
  'T374X1S Poisoning by anthelminthics, accidental (unintentional), sequela',
  'T374X2A Poisoning by anthelminthics, intentional self-harm, initial encounter',
  'T374X2D Poisoning by anthelminthics, intentional self-harm, subsequent encounter',
  'T374X2S Poisoning by anthelminthics, intentional self-harm, sequela',
  'T374X3A Poisoning by anthelminthics, assault, initial encounter',
  'T374X3D Poisoning by anthelminthics, assault, subsequent encounter',
  'T374X3S Poisoning by anthelminthics, assault, sequela',
  'T374X4A Poisoning by anthelminthics, undetermined, initial encounter',
  'T374X4D Poisoning by anthelminthics, undetermined, subsequent encounter',
  'T374X4S Poisoning by anthelminthics, undetermined, sequela',
  'T374X5A Adverse effect of anthelminthics, initial encounter',
  'T374X5D Adverse effect of anthelminthics, subsequent encounter',
  'T374X5S Adverse effect of anthelminthics, sequela',
  'T374X6A Underdosing of anthelminthics, initial encounter',
  'T374X6D Underdosing of anthelminthics, subsequent encounter',
  'T374X6S Underdosing of anthelminthics, sequela',
  'T375X1A Poisoning by antiviral drugs, accidental (unintentional), initial encounter',
  'T375X1D Poisoning by antiviral drugs, accidental (unintentional), subsequent encounter',
  'T375X1S Poisoning by antiviral drugs, accidental (unintentional), sequela',
  'T375X2A Poisoning by antiviral drugs, intentional self-harm, initial encounter',
  'T375X2D Poisoning by antiviral drugs, intentional self-harm, subsequent encounter',
  'T375X2S Poisoning by antiviral drugs, intentional self-harm, sequela',
  'T375X3A Poisoning by antiviral drugs, assault, initial encounter',
  'T375X3D Poisoning by antiviral drugs, assault, subsequent encounter',
  'T375X3S Poisoning by antiviral drugs, assault, sequela',
  'T375X4A Poisoning by antiviral drugs, undetermined, initial encounter',
  'T375X4D Poisoning by antiviral drugs, undetermined, subsequent encounter',
  'T375X4S Poisoning by antiviral drugs, undetermined, sequela',
  'T375X5A Adverse effect of antiviral drugs, initial encounter',
  'T375X5D Adverse effect of antiviral drugs, subsequent encounter',
  'T375X5S Adverse effect of antiviral drugs, sequela',
  'T375X6A Underdosing of antiviral drugs, initial encounter',
  'T375X6D Underdosing of antiviral drugs, subsequent encounter',
  'T375X6S Underdosing of antiviral drugs, sequela',
  'T378X1A Poisoning by other specified systemic anti-infectives and antiparasitics, accidental',
  '(unintentional), initial encounter',
  'T378X1D Poisoning by other specified systemic anti-infectives and antiparasitics, accidental',
  '(unintentional), subsequent encounter',
  'T378X1S Poisoning by other specified systemic anti-infectives and antiparasitics, accidental',
  '(unintentional), sequela',
  'T378X2A Poisoning by other specified systemic anti-infectives and antiparasitics, intentional self-harm,',
  'initial encounter',
  'T378X2D Poisoning by other specified systemic anti-infectives and antiparasitics, intentional self-harm,',
  'subsequent encounter',
  'T378X2S Poisoning by other specified systemic anti-infectives and antiparasitics, intentional self-harm,',
  'sequela',
  'T378X3A Poisoning by other specified systemic anti-infectives and antiparasitics, assault, initial',
  'encounter',
  'T378X3D Poisoning by other specified systemic anti-infectives and antiparasitics, assault, subsequent',
  'encounter',
  'T378X3S Poisoning by other specified systemic anti-infectives and antiparasitics, assault, sequela',
  'T378X4A Poisoning by other specified systemic anti-infectives and antiparasitics, undetermined, initial',
  'encounter',
  'T378X4D Poisoning by other specified systemic anti-infectives and antiparasitics, undetermined,',
  'subsequent encounter',
  'T378X4S Poisoning by other specified systemic anti-infectives and antiparasitics, undetermined, sequela',
  'T378X5A Adverse effect of other specified systemic anti-infectives and antiparasitics, initial encounter',
  'T378X5D Adverse effect of other specified systemic anti-infectives and antiparasitics, subsequent',
  'encounter',
  'T378X5S Adverse effect of other specified systemic anti-infectives and antiparasitics, sequela',
  'T378X6A Underdosing of other specified systemic anti-infectives and antiparasitics, initial encounter',
  'T378X6D Underdosing of other specified systemic anti-infectives and antiparasitics, subsequent',
  'encounter',
  'T378X6S Underdosing of other specified systemic anti-infectives and antiparasitics, sequela',
  'T3791XA Poisoning by unspecified systemic anti-infective and antiparasitics, accidental (unintentional),',
  'initial encounter',
  'T3791XD Poisoning by unspecified systemic anti-infective and antiparasitics, accidental (unintentional),',
  'subsequent encounter',
  'T3791XS Poisoning by unspecified systemic anti-infective and antiparasitics, accidental (unintentional),',
  'sequela',
  'T3792XA Poisoning by unspecified systemic anti-infective and antiparasitics, intentional self-harm, initial',
  'encounter',
  'T3792XD Poisoning by unspecified systemic anti-infective and antiparasitics, intentional self-harm,',
  'subsequent encounter',
  'T3792XS Poisoning by unspecified systemic anti-infective and antiparasitics, intentional self-harm,',
  'sequela',
  'T3793XA Poisoning by unspecified systemic anti-infective and antiparasitics, assault, initial encounter',
  'T3793XD Poisoning by unspecified systemic anti-infective and antiparasitics, assault, subsequent',
  'encounter',
  'T3793XS Poisoning by unspecified systemic anti-infective and antiparasitics, assault, sequela',
  'T3794XA Poisoning by unspecified systemic anti-infective and antiparasitics, undetermined, initial',
  'encounter',
  'T3794XD Poisoning by unspecified systemic anti-infective and antiparasitics, undetermined, subsequent',
  'encounter',
  'T3794XS Poisoning by unspecified systemic anti-infective and antiparasitics, undetermined, sequela',
  'T3795XA Adverse effect of unspecified systemic anti-infective and antiparasitic, initial encounter',
  'T3795XD Adverse effect of unspecified systemic anti-infective and antiparasitic, subsequent encounter',
  'T3795XS Adverse effect of unspecified systemic anti-infective and antiparasitic, sequela',
  'T3796XA Underdosing of unspecified systemic anti-infectives and antiparasitics, initial encounter',
  'T3796XD Underdosing of unspecified systemic anti-infectives and antiparasitics, subsequent encounter',
  'T3796XS Underdosing of unspecified systemic anti-infectives and antiparasitics, sequela',
  'T380X1A Poisoning by glucocorticoids and synthetic analogues, accidental (unintentional), initial',
  'encounter',
  'T380X1D Poisoning by glucocorticoids and synthetic analogues, accidental (unintentional), subsequent',
  'encounter',
  'T380X1S Poisoning by glucocorticoids and synthetic analogues, accidental (unintentional), sequela',
  'T380X2A Poisoning by glucocorticoids and synthetic analogues, intentional self-harm, initial encounter',
  'T380X2D Poisoning by glucocorticoids and synthetic analogues, intentional self-harm, subsequent',
  'encounter',
  'T380X2S Poisoning by glucocorticoids and synthetic analogues, intentional self-harm, sequela',
  'T380X3A Poisoning by glucocorticoids and synthetic analogues, assault, initial encounter',
  'T380X3D Poisoning by glucocorticoids and synthetic analogues, assault, subsequent encounter',
  'T380X3S Poisoning by glucocorticoids and synthetic analogues, assault, sequela',
  'T380X4A Poisoning by glucocorticoids and synthetic analogues, undetermined, initial encounter',
  'T380X4D Poisoning by glucocorticoids and synthetic analogues, undetermined, subsequent encounter',
  'T380X4S Poisoning by glucocorticoids and synthetic analogues, undetermined, sequela',
  'T380X5A Adverse effect of glucocorticoids and synthetic analogues, initial encounter',
  'T380X5D Adverse effect of glucocorticoids and synthetic analogues, subsequent encounter',
  'T380X5S Adverse effect of glucocorticoids and synthetic analogues, sequela',
  'T380X6A Underdosing of glucocorticoids and synthetic analogues, initial encounter',
  'T380X6D Underdosing of glucocorticoids and synthetic analogues, subsequent encounter',
  'T380X6S Underdosing of glucocorticoids and synthetic analogues, sequela',
  'T381X1A Poisoning by thyroid hormones and substitutes, accidental (unintentional), initial encounter',
  'T381X1D Poisoning by thyroid hormones and substitutes, accidental (unintentional), subsequent',
  'encounter',
  'T381X1S Poisoning by thyroid hormones and substitutes, accidental (unintentional), sequela',
  'T381X2A Poisoning by thyroid hormones and substitutes, intentional self-harm, initial encounter',
  'T381X2D Poisoning by thyroid hormones and substitutes, intentional self-harm, subsequent encounter',
  'T381X2S Poisoning by thyroid hormones and substitutes, intentional self-harm, sequela',
  'T381X3A Poisoning by thyroid hormones and substitutes, assault, initial encounter',
  'T381X3D Poisoning by thyroid hormones and substitutes, assault, subsequent encounter',
  'T381X3S Poisoning by thyroid hormones and substitutes, assault, sequela',
  'T381X4A Poisoning by thyroid hormones and substitutes, undetermined, initial encounter',
  'T381X4D Poisoning by thyroid hormones and substitutes, undetermined, subsequent encounter',
  'T381X4S Poisoning by thyroid hormones and substitutes, undetermined, sequela',
  'T381X5A Adverse effect of thyroid hormones and substitutes, initial encounter',
  'T381X5D Adverse effect of thyroid hormones and substitutes, subsequent encounter',
  'T381X5S Adverse effect of thyroid hormones and substitutes, sequela',
  'T381X6A Underdosing of thyroid hormones and substitutes, initial encounter',
  'T381X6D Underdosing of thyroid hormones and substitutes, subsequent encounter',
  'T381X6S Underdosing of thyroid hormones and substitutes, sequela',
  'T382X1A Poisoning by antithyroid drugs, accidental (unintentional), initial encounter',
  'T382X1D Poisoning by antithyroid drugs, accidental (unintentional), subsequent encounter',
  'T382X1S Poisoning by antithyroid drugs, accidental (unintentional), sequela',
  'T382X2A Poisoning by antithyroid drugs, intentional self-harm, initial encounter',
  'T382X2D Poisoning by antithyroid drugs, intentional self-harm, subsequent encounter',
  'T382X2S Poisoning by antithyroid drugs, intentional self-harm, sequela',
  'T382X3A Poisoning by antithyroid drugs, assault, initial encounter',
  'T382X3D Poisoning by antithyroid drugs, assault, subsequent encounter',
  'T382X3S Poisoning by antithyroid drugs, assault, sequela',
  'T382X4A Poisoning by antithyroid drugs, undetermined, initial encounter',
  'T382X4D Poisoning by antithyroid drugs, undetermined, subsequent encounter',
  'T382X4S Poisoning by antithyroid drugs, undetermined, sequela',
  'T382X5A Adverse effect of antithyroid drugs, initial encounter',
  'T382X5D Adverse effect of antithyroid drugs, subsequent encounter',
  'T382X5S Adverse effect of antithyroid drugs, sequela',
  'T382X6A Underdosing of antithyroid drugs, initial encounter',
  'T382X6D Underdosing of antithyroid drugs, subsequent encounter',
  'T382X6S Underdosing of antithyroid drugs, sequela',
  'T383X1A Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, accidental (unintentional),',
  'initial encounter',
  'T383X1D Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, accidental (unintentional),',
  'subsequent encounter',
  'T383X1S Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, accidental (unintentional),',
  'sequela',
  'T383X2A Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, intentional self-harm, initial',
  'encounter',
  'T383X2D Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, intentional self-harm,',
  'subsequent encounter',
  'T383X2S Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, intentional self-harm, sequela',
  'T383X3A Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, assault, initial encounter',
  'T383X3D Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, assault, subsequent encounter',
  'T383X3S Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, assault, sequela',
  'T383X4A Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, undetermined, initial',
  'encounter',
  'T383X4D Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, undetermined, subsequent',
  'encounter',
  'T383X4S Poisoning by insulin and oral hypoglycemic [antidiabetic] drugs, undetermined, sequela',
  'T383X5A Adverse effect of insulin and oral hypoglycemic [antidiabetic] drugs, initial encounter',
  'T383X5D Adverse effect of insulin and oral hypoglycemic [antidiabetic] drugs, subsequent encounter',
  'T383X5S Adverse effect of insulin and oral hypoglycemic [antidiabetic] drugs, sequela',
  'T383X6A Underdosing of insulin and oral hypoglycemic [antidiabetic] drugs, initial encounter',
  'T383X6D Underdosing of insulin and oral hypoglycemic [antidiabetic] drugs, subsequent encounter',
  'T383X6S Underdosing of insulin and oral hypoglycemic [antidiabetic] drugs, sequela',
  'T384X1A Poisoning by oral contraceptives, accidental (unintentional), initial encounter',
  'T384X1D Poisoning by oral contraceptives, accidental (unintentional), subsequent encounter',
  'T384X1S Poisoning by oral contraceptives, accidental (unintentional), sequela',
  'T384X2A Poisoning by oral contraceptives, intentional self-harm, initial encounter',
  'T384X2D Poisoning by oral contraceptives, intentional self-harm, subsequent encounter',
  'T384X2S Poisoning by oral contraceptives, intentional self-harm, sequela',
  'T384X3A Poisoning by oral contraceptives, assault, initial encounter',
  'T384X3D Poisoning by oral contraceptives, assault, subsequent encounter',
  'T384X3S Poisoning by oral contraceptives, assault, sequela',
  'T384X4A Poisoning by oral contraceptives, undetermined, initial encounter',
  'T384X4D Poisoning by oral contraceptives, undetermined, subsequent encounter',
  'T384X4S Poisoning by oral contraceptives, undetermined, sequela',
  'T384X5A Adverse effect of oral contraceptives, initial encounter',
  'T384X5D Adverse effect of oral contraceptives, subsequent encounter',
  'T384X5S Adverse effect of oral contraceptives, sequela',
  'T384X6A Underdosing of oral contraceptives, initial encounter',
  'T384X6D Underdosing of oral contraceptives, subsequent encounter',
  'T384X6S Underdosing of oral contraceptives, sequela',
  'T385X1A Poisoning by other estrogens and progestogens, accidental (unintentional), initial encounter',
  'T385X1D Poisoning by other estrogens and progestogens, accidental (unintentional), subsequent',
  'encounter',
  'T385X1S Poisoning by other estrogens and progestogens, accidental (unintentional), sequela',
  'T385X2A Poisoning by other estrogens and progestogens, intentional self-harm, initial encounter',
  'T385X2D Poisoning by other estrogens and progestogens, intentional self-harm, subsequent encounter',
  'T385X2S Poisoning by other estrogens and progestogens, intentional self-harm, sequela',
  'T385X3A Poisoning by other estrogens and progestogens, assault, initial encounter',
  'T385X3D Poisoning by other estrogens and progestogens, assault, subsequent encounter',
  'T385X3S Poisoning by other estrogens and progestogens, assault, sequela',
  'T385X4A Poisoning by other estrogens and progestogens, undetermined, initial encounter',
  'T385X4D Poisoning by other estrogens and progestogens, undetermined, subsequent encounter',
  'T385X4S Poisoning by other estrogens and progestogens, undetermined, sequela',
  'T385X5A Adverse effect of other estrogens and progestogens, initial encounter',
  'T385X5D Adverse effect of other estrogens and progestogens, subsequent encounter',
  'T385X5S Adverse effect of other estrogens and progestogens, sequela',
  'T385X6A Underdosing of other estrogens and progestogens, initial encounter',
  'T385X6D Underdosing of other estrogens and progestogens, subsequent encounter',
  'T385X6S Underdosing of other estrogens and progestogens, sequela',
  'T386X1A Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'accidental (unintentional), initial encounter',
  'T386X1D Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'accidental (unintentional), subsequent encounter',
  'T386X1S Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'accidental (unintentional), sequela',
  'T386X2A Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'intentional self-harm, initial encounter',
  'T386X2D Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'intentional self-harm, subsequent encounter',
  'T386X2S Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'intentional self-harm, sequela',
  'T386X3A Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'assault, initial encounter',
  'T386X3D Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'assault, subsequent encounter',
  'T386X3S Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'assault, sequela',
  'T386X4A Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'undetermined, initial encounter',
  'T386X4D Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'undetermined, subsequent encounter',
  'T386X4S Poisoning by antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'undetermined, sequela',
  'T386X5A Adverse effect of antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'initial encounter',
  'T386X5D Adverse effect of antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'subsequent encounter',
  'T386X5S Adverse effect of antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'sequela',
  'T386X6A Underdosing of antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'initial encounter',
  'T386X6D Underdosing of antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'subsequent encounter',
  'T386X6S Underdosing of antigonadotrophins, antiestrogens, antiandrogens, not elsewhere classified,',
  'sequela',
  'T387X1A Poisoning by androgens and anabolic congeners, accidental (unintentional), initial encounter',
  'T387X1D Poisoning by androgens and anabolic congeners, accidental (unintentional), subsequent',
  'encounter',
  'T387X1S Poisoning by androgens and anabolic congeners, accidental (unintentional), sequela',
  'T387X2A Poisoning by androgens and anabolic congeners, intentional self-harm, initial encounter',
  'T387X2D Poisoning by androgens and anabolic congeners, intentional self-harm, subsequent encounter',
  'T387X2S Poisoning by androgens and anabolic congeners, intentional self-harm, sequela',
  'T387X3A Poisoning by androgens and anabolic congeners, assault, initial encounter',
  'T387X3D Poisoning by androgens and anabolic congeners, assault, subsequent encounter',
  'T387X3S Poisoning by androgens and anabolic congeners, assault, sequela',
  'T387X4A Poisoning by androgens and anabolic congeners, undetermined, initial encounter',
  'T387X4D Poisoning by androgens and anabolic congeners, undetermined, subsequent encounter',
  'T387X4S Poisoning by androgens and anabolic congeners, undetermined, sequela',
  'T387X5A Adverse effect of androgens and anabolic congeners, initial encounter',
  'T387X5D Adverse effect of androgens and anabolic congeners, subsequent encounter',
  'T387X5S Adverse effect of androgens and anabolic congeners, sequela',
  'T387X6A Underdosing of androgens and anabolic congeners, initial encounter',
  'T387X6D Underdosing of androgens and anabolic congeners, subsequent encounter',
  'T387X6S Underdosing of androgens and anabolic congeners, sequela',
  'T38801A Poisoning by unspecified hormones and synthetic substitutes, accidental (unintentional), initial',
  'encounter',
  'T38801D Poisoning by unspecified hormones and synthetic substitutes, accidental (unintentional),',
  'subsequent encounter',
  'T38801S Poisoning by unspecified hormones and synthetic substitutes, accidental (unintentional),',
  'sequela',
  'T38802A Poisoning by unspecified hormones and synthetic substitutes, intentional self-harm, initial',
  'encounter',
  'T38802D Poisoning by unspecified hormones and synthetic substitutes, intentional self-harm,',
  'subsequent encounter',
  'T38802S Poisoning by unspecified hormones and synthetic substitutes, intentional self-harm, sequela',
  'T38803A Poisoning by unspecified hormones and synthetic substitutes, assault, initial encounter',
  'T38803D Poisoning by unspecified hormones and synthetic substitutes, assault, subsequent encounter',
  'T38803S Poisoning by unspecified hormones and synthetic substitutes, assault, sequela',
  'T38804A Poisoning by unspecified hormones and synthetic substitutes, undetermined, initial encounter',
  'T38804D Poisoning by unspecified hormones and synthetic substitutes, undetermined, subsequent',
  'encounter',
  'T38804S Poisoning by unspecified hormones and synthetic substitutes, undetermined, sequela',
  'T38805A Adverse effect of unspecified hormones and synthetic substitutes, initial encounter',
  'T38805D Adverse effect of unspecified hormones and synthetic substitutes, subsequent encounter',
  'T38805S Adverse effect of unspecified hormones and synthetic substitutes, sequela',
  'T38806A Underdosing of unspecified hormones and synthetic substitutes, initial encounter',
  'T38806D Underdosing of unspecified hormones and synthetic substitutes, subsequent encounter',
  'T38806S Underdosing of unspecified hormones and synthetic substitutes, sequela',
  'T38811A Poisoning by anterior pituitary [adenohypophyseal] hormones, accidental (unintentional),',
  'initial encounter',
  'T38811D Poisoning by anterior pituitary [adenohypophyseal] hormones, accidental (unintentional),',
  'subsequent encounter',
  'T38811S Poisoning by anterior pituitary [adenohypophyseal] hormones, accidental (unintentional),',
  'sequela',
  'T38812A Poisoning by anterior pituitary [adenohypophyseal] hormones, intentional self-harm, initial',
  'encounter',
  'T38812D Poisoning by anterior pituitary [adenohypophyseal] hormones, intentional self-harm,',
  'subsequent encounter',
  'T38812S Poisoning by anterior pituitary [adenohypophyseal] hormones, intentional self-harm, sequela',
  'T38813A Poisoning by anterior pituitary [adenohypophyseal] hormones, assault, initial encounter',
  'T38813D Poisoning by anterior pituitary [adenohypophyseal] hormones, assault, subsequent encounter',
  'T38813S Poisoning by anterior pituitary [adenohypophyseal] hormones, assault, sequela',
  'T38814A Poisoning by anterior pituitary [adenohypophyseal] hormones, undetermined, initial encounter',
  'T38814D Poisoning by anterior pituitary [adenohypophyseal] hormones, undetermined, subsequent',
  'encounter',
  'T38814S Poisoning by anterior pituitary [adenohypophyseal] hormones, undetermined, sequela',
  'T38815A Adverse effect of anterior pituitary [adenohypophyseal] hormones, initial encounter',
  'T38815D Adverse effect of anterior pituitary [adenohypophyseal] hormones, subsequent encounter',
  'T38815S Adverse effect of anterior pituitary [adenohypophyseal] hormones, sequela',
  'T38816A Underdosing of anterior pituitary [adenohypophyseal] hormones, initial encounter',
  'T38816D Underdosing of anterior pituitary [adenohypophyseal] hormones, subsequent encounter',
  'T38816S Underdosing of anterior pituitary [adenohypophyseal] hormones, sequela',
  'T38891A Poisoning by other hormones and synthetic substitutes, accidental (unintentional), initial',
  'encounter',
  'T38891D Poisoning by other hormones and synthetic substitutes, accidental (unintentional), subsequent',
  'encounter',
  'T38891S Poisoning by other hormones and synthetic substitutes, accidental (unintentional), sequela',
  'T38892A Poisoning by other hormones and synthetic substitutes, intentional self-harm, initial encounter',
  'T38892D Poisoning by other hormones and synthetic substitutes, intentional self-harm, subsequent',
  'encounter',
  'T38892S Poisoning by other hormones and synthetic substitutes, intentional self-harm, sequela',
  'T38893A Poisoning by other hormones and synthetic substitutes, assault, initial encounter',
  'T38893D Poisoning by other hormones and synthetic substitutes, assault, subsequent encounter',
  'T38893S Poisoning by other hormones and synthetic substitutes, assault, sequela',
  'T38894A Poisoning by other hormones and synthetic substitutes, undetermined, initial encounter',
  'T38894D Poisoning by other hormones and synthetic substitutes, undetermined, subsequent encounter',
  'T38894S Poisoning by other hormones and synthetic substitutes, undetermined, sequela',
  'T38895A Adverse effect of other hormones and synthetic substitutes, initial encounter',
  'T38895D Adverse effect of other hormones and synthetic substitutes, subsequent encounter',
  'T38895S Adverse effect of other hormones and synthetic substitutes, sequela',
  'T38896A Underdosing of other hormones and synthetic substitutes, initial encounter',
  'T38896D Underdosing of other hormones and synthetic substitutes, subsequent encounter',
  'T38896S Underdosing of other hormones and synthetic substitutes, sequela',
  'T38901A Poisoning by unspecified hormone antagonists, accidental (unintentional), initial encounter',
  'T38901D Poisoning by unspecified hormone antagonists, accidental (unintentional), subsequent',
  'encounter',
  'T38901S Poisoning by unspecified hormone antagonists, accidental (unintentional), sequela',
  'T38902A Poisoning by unspecified hormone antagonists, intentional self-harm, initial encounter',
  'T38902D Poisoning by unspecified hormone antagonists, intentional self-harm, subsequent encounter',
  'T38902S Poisoning by unspecified hormone antagonists, intentional self-harm, sequela',
  'T38903A Poisoning by unspecified hormone antagonists, assault, initial encounter',
  'T38903D Poisoning by unspecified hormone antagonists, assault, subsequent encounter',
  'T38903S Poisoning by unspecified hormone antagonists, assault, sequela',
  'T38904A Poisoning by unspecified hormone antagonists, undetermined, initial encounter',
  'T38904D Poisoning by unspecified hormone antagonists, undetermined, subsequent encounter',
  'T38904S Poisoning by unspecified hormone antagonists, undetermined, sequela',
  'T38905A Adverse effect of unspecified hormone antagonists, initial encounter',
  'T38905D Adverse effect of unspecified hormone antagonists, subsequent encounter',
  'T38905S Adverse effect of unspecified hormone antagonists, sequela',
  'T38906A Underdosing of unspecified hormone antagonists, initial encounter',
  'T38906D Underdosing of unspecified hormone antagonists, subsequent encounter',
  'T38906S Underdosing of unspecified hormone antagonists, sequela',
  'T38991A Poisoning by other hormone antagonists, accidental (unintentional), initial encounter',
  'T38991D Poisoning by other hormone antagonists, accidental (unintentional), subsequent encounter',
  'T38991S Poisoning by other hormone antagonists, accidental (unintentional), sequela',
  'T38992A Poisoning by other hormone antagonists, intentional self-harm, initial encounter',
  'T38992D Poisoning by other hormone antagonists, intentional self-harm, subsequent encounter',
  'T38992S Poisoning by other hormone antagonists, intentional self-harm, sequela',
  'T38993A Poisoning by other hormone antagonists, assault, initial encounter',
  'T38993D Poisoning by other hormone antagonists, assault, subsequent encounter',
  'T38993S Poisoning by other hormone antagonists, assault, sequela',
  'T38994A Poisoning by other hormone antagonists, undetermined, initial encounter',
  'T38994D Poisoning by other hormone antagonists, undetermined, subsequent encounter',
  'T38994S Poisoning by other hormone antagonists, undetermined, sequela',
  'T38995A Adverse effect of other hormone antagonists, initial encounter',
  'T38995D Adverse effect of other hormone antagonists, subsequent encounter',
  'T38995S Adverse effect of other hormone antagonists, sequela',
  'T38996A Underdosing of other hormone antagonists, initial encounter',
  'T38996D Underdosing of other hormone antagonists, subsequent encounter',
  'T38996S Underdosing of other hormone antagonists, sequela',
  'T39011A Poisoning by aspirin, accidental (unintentional), initial encounter',
  'T39011D Poisoning by aspirin, accidental (unintentional), subsequent encounter',
  'T39011S Poisoning by aspirin, accidental (unintentional), sequela',
  'T39012A Poisoning by aspirin, intentional self-harm, initial encounter',
  'T39012D Poisoning by aspirin, intentional self-harm, subsequent encounter',
  'T39012S Poisoning by aspirin, intentional self-harm, sequela',
  'T39013A Poisoning by aspirin, assault, initial encounter',
  'T39013D Poisoning by aspirin, assault, subsequent encounter',
  'T39013S Poisoning by aspirin, assault, sequela',
  'T39014A Poisoning by aspirin, undetermined, initial encounter',
  'T39014D Poisoning by aspirin, undetermined, subsequent encounter',
  'T39014S Poisoning by aspirin, undetermined, sequela',
  'T39015A Adverse effect of aspirin, initial encounter',
  'T39015D Adverse effect of aspirin, subsequent encounter',
  'T39015S Adverse effect of aspirin, sequela',
  'T39016A Underdosing of aspirin, initial encounter',
  'T39016D Underdosing of aspirin, subsequent encounter',
  'T39016S Underdosing of aspirin, sequela',
  'T39091A Poisoning by salicylates, accidental (unintentional), initial encounter',
  'T39091D Poisoning by salicylates, accidental (unintentional), subsequent encounter',
  'T39091S Poisoning by salicylates, accidental (unintentional), sequela',
  'T39092A Poisoning by salicylates, intentional self-harm, initial encounter',
  'T39092D Poisoning by salicylates, intentional self-harm, subsequent encounter',
  'T39092S Poisoning by salicylates, intentional self-harm, sequela',
  'T39093A Poisoning by salicylates, assault, initial encounter',
  'T39093D Poisoning by salicylates, assault, subsequent encounter',
  'T39093S Poisoning by salicylates, assault, sequela',
  'T39094A Poisoning by salicylates, undetermined, initial encounter',
  'T39094D Poisoning by salicylates, undetermined, subsequent encounter',
  'T39094S Poisoning by salicylates, undetermined, sequela',
  'T39095A Adverse effect of salicylates, initial encounter',
  'T39095D Adverse effect of salicylates, subsequent encounter',
  'T39095S Adverse effect of salicylates, sequela',
  'T39096A Underdosing of salicylates, initial encounter',
  'T39096D Underdosing of salicylates, subsequent encounter',
  'T39096S Underdosing of salicylates, sequela',
  'T391X1A Poisoning by 4-Aminophenol derivatives, accidental (unintentional), initial encounter',
  'T391X1D Poisoning by 4-Aminophenol derivatives, accidental (unintentional), subsequent encounter',
  'T391X1S Poisoning by 4-Aminophenol derivatives, accidental (unintentional), sequela',
  'T391X2A Poisoning by 4-Aminophenol derivatives, intentional self-harm, initial encounter',
  'T391X2D Poisoning by 4-Aminophenol derivatives, intentional self-harm, subsequent encounter',
  'T391X2S Poisoning by 4-Aminophenol derivatives, intentional self-harm, sequela',
  'T391X3A Poisoning by 4-Aminophenol derivatives, assault, initial encounter',
  'T391X3D Poisoning by 4-Aminophenol derivatives, assault, subsequent encounter',
  'T391X3S Poisoning by 4-Aminophenol derivatives, assault, sequela',
  'T391X4A Poisoning by 4-Aminophenol derivatives, undetermined, initial encounter',
  'T391X4D Poisoning by 4-Aminophenol derivatives, undetermined, subsequent encounter',
  'T391X4S Poisoning by 4-Aminophenol derivatives, undetermined, sequela',
  'T391X5A Adverse effect of 4-Aminophenol derivatives, initial encounter',
  'T391X5D Adverse effect of 4-Aminophenol derivatives, subsequent encounter',
  'T391X5S Adverse effect of 4-Aminophenol derivatives, sequela',
  'T391X6A Underdosing of 4-Aminophenol derivatives, initial encounter',
  'T391X6D Underdosing of 4-Aminophenol derivatives, subsequent encounter',
  'T391X6S Underdosing of 4-Aminophenol derivatives, sequela',
  'T392X1A Poisoning by pyrazolone derivatives, accidental (unintentional), initial encounter',
  'T392X1D Poisoning by pyrazolone derivatives, accidental (unintentional), subsequent encounter',
  'T392X1S Poisoning by pyrazolone derivatives, accidental (unintentional), sequela',
  'T392X2A Poisoning by pyrazolone derivatives, intentional self-harm, initial encounter',
  'T392X2D Poisoning by pyrazolone derivatives, intentional self-harm, subsequent encounter',
  'T392X2S Poisoning by pyrazolone derivatives, intentional self-harm, sequela',
  'T392X3A Poisoning by pyrazolone derivatives, assault, initial encounter',
  'T392X3D Poisoning by pyrazolone derivatives, assault, subsequent encounter',
  'T392X3S Poisoning by pyrazolone derivatives, assault, sequela',
  'T392X4A Poisoning by pyrazolone derivatives, undetermined, initial encounter',
  'T392X4D Poisoning by pyrazolone derivatives, undetermined, subsequent encounter',
  'T392X4S Poisoning by pyrazolone derivatives, undetermined, sequela',
  'T392X5A Adverse effect of pyrazolone derivatives, initial encounter',
  'T392X5D Adverse effect of pyrazolone derivatives, subsequent encounter',
  'T392X5S Adverse effect of pyrazolone derivatives, sequela',
  'T392X6A Underdosing of pyrazolone derivatives, initial encounter',
  'T392X6D Underdosing of pyrazolone derivatives, subsequent encounter',
  'T392X6S Underdosing of pyrazolone derivatives, sequela',
  'T39311A Poisoning by propionic acid derivatives, accidental (unintentional), initial encounter',
  'T39311D Poisoning by propionic acid derivatives, accidental (unintentional), subsequent encounter',
  'T39311S Poisoning by propionic acid derivatives, accidental (unintentional), sequela',
  'T39312A Poisoning by propionic acid derivatives, intentional self-harm, initial encounter',
  'T39312D Poisoning by propionic acid derivatives, intentional self-harm, subsequent encounter',
  'T39312S Poisoning by propionic acid derivatives, intentional self-harm, sequela',
  'T39313A Poisoning by propionic acid derivatives, assault, initial encounter',
  'T39313D Poisoning by propionic acid derivatives, assault, subsequent encounter',
  'T39313S Poisoning by propionic acid derivatives, assault, sequela',
  'T39314A Poisoning by propionic acid derivatives, undetermined, initial encounter',
  'T39314D Poisoning by propionic acid derivatives, undetermined, subsequent encounter',
  'T39314S Poisoning by propionic acid derivatives, undetermined, sequela',
  'T39315A Adverse effect of propionic acid derivatives, initial encounter',
  'T39315D Adverse effect of propionic acid derivatives, subsequent encounter',
  'T39315S Adverse effect of propionic acid derivatives, sequela',
  'T39316A Underdosing of propionic acid derivatives, initial encounter',
  'T39316D Underdosing of propionic acid derivatives, subsequent encounter',
  'T39316S Underdosing of propionic acid derivatives, sequela',
  'T39391A Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], accidental (unintentional),',
  'initial encounter',
  'T39391D Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], accidental (unintentional),',
  'subsequent encounter',
  'T39391S Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], accidental (unintentional),',
  'sequela',
  'T39392A Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], intentional self-harm, initial',
  'encounter',
  'T39392D Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], intentional self-harm,',
  'subsequent encounter',
  'T39392S Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], intentional self-harm,',
  'sequela',
  'T39393A Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], assault, initial encounter',
  'T39393D Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], assault, subsequent',
  'encounter',
  'T39393S Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], assault, sequela',
  'T39394A Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], undetermined, initial',
  'encounter',
  'T39394D Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], undetermined, subsequent',
  'encounter',
  'T39394S Poisoning by other nonsteroidal anti-inflammatory drugs [NSAID], undetermined, sequela',
  'T39395A Adverse effect of other nonsteroidal anti-inflammatory drugs [NSAID], initial encounter',
  'T39395D Adverse effect of other nonsteroidal anti-inflammatory drugs [NSAID], subsequent encounter',
  'T39395S Adverse effect of other nonsteroidal anti-inflammatory drugs [NSAID], sequela',
  'T39396A Underdosing of other nonsteroidal anti-inflammatory drugs [NSAID], initial encounter',
  'T39396D Underdosing of other nonsteroidal anti-inflammatory drugs [NSAID], subsequent encounter',
  'T39396S Underdosing of other nonsteroidal anti-inflammatory drugs [NSAID], sequela',
  'T394X1A Poisoning by antirheumatics, not elsewhere classified, accidental (unintentional), initial',
  'encounter',
  'T394X1D Poisoning by antirheumatics, not elsewhere classified, accidental (unintentional), subsequent',
  'encounter',
  'T394X1S Poisoning by antirheumatics, not elsewhere classified, accidental (unintentional), sequela',
  'T394X2A Poisoning by antirheumatics, not elsewhere classified, intentional self-harm, initial encounter',
  'T394X2D Poisoning by antirheumatics, not elsewhere classified, intentional self-harm, subsequent',
  'encounter',
  'T394X2S Poisoning by antirheumatics, not elsewhere classified, intentional self-harm, sequela',
  'T394X3A Poisoning by antirheumatics, not elsewhere classified, assault, initial encounter',
  'T394X3D Poisoning by antirheumatics, not elsewhere classified, assault, subsequent encounter',
  'T394X3S Poisoning by antirheumatics, not elsewhere classified, assault, sequela',
  'T394X4A Poisoning by antirheumatics, not elsewhere classified, undetermined, initial encounter',
  'T394X4D Poisoning by antirheumatics, not elsewhere classified, undetermined, subsequent encounter',
  'T394X4S Poisoning by antirheumatics, not elsewhere classified, undetermined, sequela',
  'T394X5A Adverse effect of antirheumatics, not elsewhere classified, initial encounter',
  'T394X5D Adverse effect of antirheumatics, not elsewhere classified, subsequent encounter',
  'T394X5S Adverse effect of antirheumatics, not elsewhere classified, sequela',
  'T394X6A Underdosing of antirheumatics, not elsewhere classified, initial encounter',
  'T394X6D Underdosing of antirheumatics, not elsewhere classified, subsequent encounter',
  'T394X6S Underdosing of antirheumatics, not elsewhere classified, sequela',
  'T398X1A Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, accidental',
  '(unintentional), initial encounter',
  'T398X1D Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, accidental',
  '(unintentional), subsequent encounter',
  'T398X1S Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, accidental',
  '(unintentional), sequela',
  'T398X2A Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, intentional',
  'self-harm, initial encounter',
  'T398X2D Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, intentional',
  'self-harm, subsequent encounter',
  'T398X2S Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, intentional',
  'self-harm, sequela',
  'T398X3A Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, assault,',
  'initial encounter',
  'T398X3D Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, assault,',
  'subsequent encounter',
  'T398X3S Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified, assault,',
  'sequela',
  'T398X4A Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified,',
  'undetermined, initial encounter',
  'T398X4D Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified,',
  'undetermined, subsequent encounter',
  'T398X4S Poisoning by other nonopioid analgesics and antipyretics, not elsewhere classified,',
  'undetermined, sequela',
  'T398X5A Adverse effect of other nonopioid analgesics and antipyretics, not elsewhere classified, initial',
  'encounter',
  'T398X5D Adverse effect of other nonopioid analgesics and antipyretics, not elsewhere classified,',
  'subsequent encounter',
  'T398X5S Adverse effect of other nonopioid analgesics and antipyretics, not elsewhere classified, sequela',
  'T398X6A Underdosing of other nonopioid analgesics and antipyretics, not elsewhere classified, initial',
  'encounter',
  'T398X6D Underdosing of other nonopioid analgesics and antipyretics, not elsewhere classified,',
  'subsequent encounter',
  'T398X6S Underdosing of other nonopioid analgesics and antipyretics, not elsewhere classified, sequela',
  'T3991XA Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, accidental',
  '(unintentional), initial encounter',
  'T3991XD Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, accidental',
  '(unintentional), subsequent encounter',
  'T3991XS Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, accidental',
  '(unintentional), sequela',
  'T3992XA Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, intentional selfharm, initial encounter',
  'T3992XD Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, intentional selfharm, subsequent encounter',
  'T3992XS Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, intentional selfharm, sequela',
  'T3993XA Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, assault, initial',
  'encounter',
  'T3993XD Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, assault,',
  'subsequent encounter',
  'T3993XS Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, assault, sequela',
  'T3994XA Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, undetermined,',
  'initial encounter',
  'T3994XD Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, undetermined,',
  'subsequent encounter',
  'T3994XS Poisoning by unspecified nonopioid analgesic, antipyretic and antirheumatic, undetermined,',
  'sequela',
  'T3995XA Adverse effect of unspecified nonopioid analgesic, antipyretic and antirheumatic, initial',
  'encounter',
  'T3995XD Adverse effect of unspecified nonopioid analgesic, antipyretic and antirheumatic, subsequent',
  'encounter',
  'T3995XS Adverse effect of unspecified nonopioid analgesic, antipyretic and antirheumatic, sequela',
  'T3996XA Underdosing of unspecified nonopioid analgesic, antipyretic and antirheumatic, initial',
  'encounter',
  'T3996XD Underdosing of unspecified nonopioid analgesic, antipyretic and antirheumatic, subsequent',
  'encounter',
  'T3996XS Underdosing of unspecified nonopioid analgesic, antipyretic and antirheumatic, sequela',
  'T400X1A Poisoning by opium, accidental (unintentional), initial encounter',
  'T400X1D Poisoning by opium, accidental (unintentional), subsequent encounter',
  'T400X1S Poisoning by opium, accidental (unintentional), sequela',
  'T400X2A Poisoning by opium, intentional self-harm, initial encounter',
  'T400X2D Poisoning by opium, intentional self-harm, subsequent encounter',
  'T400X2S Poisoning by opium, intentional self-harm, sequela',
  'T400X3A Poisoning by opium, assault, initial encounter',
  'T400X3D Poisoning by opium, assault, subsequent encounter',
  'T400X3S Poisoning by opium, assault, sequela',
  'T400X4A Poisoning by opium, undetermined, initial encounter',
  'T400X4D Poisoning by opium, undetermined, subsequent encounter',
  'T400X4S Poisoning by opium, undetermined, sequela',
  'T400X5A Adverse effect of opium, initial encounter',
  'T400X5D Adverse effect of opium, subsequent encounter',
  'T400X5S Adverse effect of opium, sequela',
  'T400X6A Underdosing of opium, initial encounter',
  'T400X6D Underdosing of opium, subsequent encounter',
  'T400X6S Underdosing of opium, sequela',
  'T401X1A Poisoning by heroin, accidental (unintentional), initial encounter',
  'T401X1D Poisoning by heroin, accidental (unintentional), subsequent encounter',
  'T401X1S Poisoning by heroin, accidental (unintentional), sequela',
  'T401X2A Poisoning by heroin, intentional self-harm, initial encounter',
  'T401X2D Poisoning by heroin, intentional self-harm, subsequent encounter',
  'T401X2S Poisoning by heroin, intentional self-harm, sequela',
  'T401X3A Poisoning by heroin, assault, initial encounter',
  'T401X3D Poisoning by heroin, assault, subsequent encounter',
  'T401X3S Poisoning by heroin, assault, sequela',
  'T401X4A Poisoning by heroin, undetermined, initial encounter',
  'T401X4D Poisoning by heroin, undetermined, subsequent encounter',
  'T401X4S Poisoning by heroin, undetermined, sequela',
  'T402X1A Poisoning by other opioids, accidental (unintentional), initial encounter',
  'T402X1D Poisoning by other opioids, accidental (unintentional), subsequent encounter',
  'T402X1S Poisoning by other opioids, accidental (unintentional), sequela',
  'T402X2A Poisoning by other opioids, intentional self-harm, initial encounter',
  'T402X2D Poisoning by other opioids, intentional self-harm, subsequent encounter',
  'T402X2S Poisoning by other opioids, intentional self-harm, sequela',
  'T402X3A Poisoning by other opioids, assault, initial encounter',
  'T402X3D Poisoning by other opioids, assault, subsequent encounter',
  'T402X3S Poisoning by other opioids, assault, sequela',
  'T402X4A Poisoning by other opioids, undetermined, initial encounter',
  'T402X4D Poisoning by other opioids, undetermined, subsequent encounter',
  'T402X4S Poisoning by other opioids, undetermined, sequela',
  'T402X5A Adverse effect of other opioids, initial encounter',
  'T402X5D Adverse effect of other opioids, subsequent encounter',
  'T402X5S Adverse effect of other opioids, sequela',
  'T402X6A Underdosing of other opioids, initial encounter',
  'T402X6D Underdosing of other opioids, subsequent encounter',
  'T402X6S Underdosing of other opioids, sequela',
  'T403X1A Poisoning by methadone, accidental (unintentional), initial encounter',
  'T403X1D Poisoning by methadone, accidental (unintentional), subsequent encounter',
  'T403X1S Poisoning by methadone, accidental (unintentional), sequela',
  'T403X2A Poisoning by methadone, intentional self-harm, initial encounter',
  'T403X2D Poisoning by methadone, intentional self-harm, subsequent encounter',
  'T403X2S Poisoning by methadone, intentional self-harm, sequela',
  'T403X3A Poisoning by methadone, assault, initial encounter',
  'T403X3D Poisoning by methadone, assault, subsequent encounter',
  'T403X3S Poisoning by methadone, assault, sequela',
  'T403X4A Poisoning by methadone, undetermined, initial encounter',
  'T403X4D Poisoning by methadone, undetermined, subsequent encounter',
  'T403X4S Poisoning by methadone, undetermined, sequela',
  'T403X5A Adverse effect of methadone, initial encounter',
  'T403X5D Adverse effect of methadone, subsequent encounter',
  'T403X5S Adverse effect of methadone, sequela',
  'T403X6A Underdosing of methadone, initial encounter',
  'T403X6D Underdosing of methadone, subsequent encounter',
  'T403X6S Underdosing of methadone, sequela',
  'T404X1A Poisoning by other synthetic narcotics, accidental (unintentional), initial encounter',
  'T404X1D Poisoning by other synthetic narcotics, accidental (unintentional), subsequent encounter',
  'T404X1S Poisoning by other synthetic narcotics, accidental (unintentional), sequela',
  'T404X2A Poisoning by other synthetic narcotics, intentional self-harm, initial encounter',
  'T404X2D Poisoning by other synthetic narcotics, intentional self-harm, subsequent encounter',
  'T404X2S Poisoning by other synthetic narcotics, intentional self-harm, sequela',
  'T404X3A Poisoning by other synthetic narcotics, assault, initial encounter',
  'T404X3D Poisoning by other synthetic narcotics, assault, subsequent encounter',
  'T404X3S Poisoning by other synthetic narcotics, assault, sequela',
  'T404X4A Poisoning by other synthetic narcotics, undetermined, initial encounter',
  'T404X4D Poisoning by other synthetic narcotics, undetermined, subsequent encounter',
  'T404X4S Poisoning by other synthetic narcotics, undetermined, sequela',
  'T404X5A Adverse effect of other synthetic narcotics, initial encounter',
  'T404X5D Adverse effect of other synthetic narcotics, subsequent encounter',
  'T404X5S Adverse effect of other synthetic narcotics, sequela',
  'T404X6A Underdosing of other synthetic narcotics, initial encounter',
  'T404X6D Underdosing of other synthetic narcotics, subsequent encounter',
  'T404X6S Underdosing of other synthetic narcotics, sequela',
  'T405X1A Poisoning by cocaine, accidental (unintentional), initial encounter',
  'T405X1D Poisoning by cocaine, accidental (unintentional), subsequent encounter',
  'T405X1S Poisoning by cocaine, accidental (unintentional), sequela',
  'T405X2A Poisoning by cocaine, intentional self-harm, initial encounter',
  'T405X2D Poisoning by cocaine, intentional self-harm, subsequent encounter',
  'T405X2S Poisoning by cocaine, intentional self-harm, sequela',
  'T405X3A Poisoning by cocaine, assault, initial encounter',
  'T405X3D Poisoning by cocaine, assault, subsequent encounter',
  'T405X3S Poisoning by cocaine, assault, sequela',
  'T405X4A Poisoning by cocaine, undetermined, initial encounter',
  'T405X4D Poisoning by cocaine, undetermined, subsequent encounter',
  'T405X4S Poisoning by cocaine, undetermined, sequela',
  'T405X5A Adverse effect of cocaine, initial encounter',
  'T405X5D Adverse effect of cocaine, subsequent encounter',
  'T405X5S Adverse effect of cocaine, sequela',
  'T405X6A Underdosing of cocaine, initial encounter',
  'T405X6D Underdosing of cocaine, subsequent encounter',
  'T405X6S Underdosing of cocaine, sequela',
  'T40601A Poisoning by unspecified narcotics, accidental (unintentional), initial encounter',
  'T40601D Poisoning by unspecified narcotics, accidental (unintentional), subsequent encounter',
  'T40601S Poisoning by unspecified narcotics, accidental (unintentional), sequela',
  'T40602A Poisoning by unspecified narcotics, intentional self-harm, initial encounter',
  'T40602D Poisoning by unspecified narcotics, intentional self-harm, subsequent encounter',
  'T40602S Poisoning by unspecified narcotics, intentional self-harm, sequela',
  'T40603A Poisoning by unspecified narcotics, assault, initial encounter',
  'T40603D Poisoning by unspecified narcotics, assault, subsequent encounter',
  'T40603S Poisoning by unspecified narcotics, assault, sequela',
  'T40604A Poisoning by unspecified narcotics, undetermined, initial encounter',
  'T40604D Poisoning by unspecified narcotics, undetermined, subsequent encounter',
  'T40604S Poisoning by unspecified narcotics, undetermined, sequela',
  'T40605A Adverse effect of unspecified narcotics, initial encounter',
  'T40605D Adverse effect of unspecified narcotics, subsequent encounter',
  'T40605S Adverse effect of unspecified narcotics, sequela',
  'T40606A Underdosing of unspecified narcotics, initial encounter',
  'T40606D Underdosing of unspecified narcotics, subsequent encounter',
  'T40606S Underdosing of unspecified narcotics, sequela',
  'T40691A Poisoning by other narcotics, accidental (unintentional), initial encounter',
  'T40691D Poisoning by other narcotics, accidental (unintentional), subsequent encounter',
  'T40691S Poisoning by other narcotics, accidental (unintentional), sequela',
  'T40692A Poisoning by other narcotics, intentional self-harm, initial encounter',
  'T40692D Poisoning by other narcotics, intentional self-harm, subsequent encounter',
  'T40692S Poisoning by other narcotics, intentional self-harm, sequela',
  'T40693A Poisoning by other narcotics, assault, initial encounter',
  'T40693D Poisoning by other narcotics, assault, subsequent encounter',
  'T40693S Poisoning by other narcotics, assault, sequela',
  'T40694A Poisoning by other narcotics, undetermined, initial encounter',
  'T40694D Poisoning by other narcotics, undetermined, subsequent encounter',
  'T40694S Poisoning by other narcotics, undetermined, sequela',
  'T40695A Adverse effect of other narcotics, initial encounter',
  'T40695D Adverse effect of other narcotics, subsequent encounter',
  'T40695S Adverse effect of other narcotics, sequela',
  'T40696A Underdosing of other narcotics, initial encounter',
  'T40696D Underdosing of other narcotics, subsequent encounter',
  'T40696S Underdosing of other narcotics, sequela',
  'T407X1A Poisoning by cannabis (derivatives), accidental (unintentional), initial encounter',
  'T407X1D Poisoning by cannabis (derivatives), accidental (unintentional), subsequent encounter',
  'T407X1S Poisoning by cannabis (derivatives), accidental (unintentional), sequela',
  'T407X2A Poisoning by cannabis (derivatives), intentional self-harm, initial encounter',
  'T407X2D Poisoning by cannabis (derivatives), intentional self-harm, subsequent encounter',
  'T407X2S Poisoning by cannabis (derivatives), intentional self-harm, sequela',
  'T407X3A Poisoning by cannabis (derivatives), assault, initial encounter',
  'T407X3D Poisoning by cannabis (derivatives), assault, subsequent encounter',
  'T407X3S Poisoning by cannabis (derivatives), assault, sequela',
  'T407X4A Poisoning by cannabis (derivatives), undetermined, initial encounter',
  'T407X4D Poisoning by cannabis (derivatives), undetermined, subsequent encounter',
  'T407X4S Poisoning by cannabis (derivatives), undetermined, sequela',
  'T407X5A Adverse effect of cannabis (derivatives), initial encounter',
  'T407X5D Adverse effect of cannabis (derivatives), subsequent encounter',
  'T407X5S Adverse effect of cannabis (derivatives), sequela',
  'T407X6A Underdosing of cannabis (derivatives), initial encounter',
  'T407X6D Underdosing of cannabis (derivatives), subsequent encounter',
  'T407X6S Underdosing of cannabis (derivatives), sequela',
  'T408X1A Poisoning by lysergide [LSD], accidental (unintentional), initial encounter',
  'T408X1D Poisoning by lysergide [LSD], accidental (unintentional), subsequent encounter',
  'T408X1S Poisoning by lysergide [LSD], accidental (unintentional), sequela',
  'T408X2A Poisoning by lysergide [LSD], intentional self-harm, initial encounter',
  'T408X2D Poisoning by lysergide [LSD], intentional self-harm, subsequent encounter',
  'T408X2S Poisoning by lysergide [LSD], intentional self-harm, sequela',
  'T408X3A Poisoning by lysergide [LSD], assault, initial encounter',
  'T408X3D Poisoning by lysergide [LSD], assault, subsequent encounter',
  'T408X3S Poisoning by lysergide [LSD], assault, sequela',
  'T408X4A Poisoning by lysergide [LSD], undetermined, initial encounter',
  'T408X4D Poisoning by lysergide [LSD], undetermined, subsequent encounter',
  'T408X4S Poisoning by lysergide [LSD], undetermined, sequela',
  'T40901A Poisoning by unspecified psychodysleptics [hallucinogens], accidental (unintentional), initial',
  'encounter',
  'T40901D Poisoning by unspecified psychodysleptics [hallucinogens], accidental (unintentional),',
  'subsequent encounter',
  'T40901S Poisoning by unspecified psychodysleptics [hallucinogens], accidental (unintentional), sequela',
  'T40902A Poisoning by unspecified psychodysleptics [hallucinogens], intentional self-harm, initial',
  'encounter',
  'T40902D Poisoning by unspecified psychodysleptics [hallucinogens], intentional self-harm, subsequent',
  'encounter',
  'T40902S Poisoning by unspecified psychodysleptics [hallucinogens], intentional self-harm, sequela',
  'T40903A Poisoning by unspecified psychodysleptics [hallucinogens], assault, initial encounter',
  'T40903D Poisoning by unspecified psychodysleptics [hallucinogens], assault, subsequent encounter',
  'T40903S Poisoning by unspecified psychodysleptics [hallucinogens], assault, sequela',
  'T40904A Poisoning by unspecified psychodysleptics [hallucinogens], undetermined, initial encounter',
  'T40904D Poisoning by unspecified psychodysleptics [hallucinogens], undetermined, subsequent',
  'encounter',
  'T40904S Poisoning by unspecified psychodysleptics [hallucinogens], undetermined, sequela',
  'T40905A Adverse effect of unspecified psychodysleptics [hallucinogens], initial encounter',
  'T40905D Adverse effect of unspecified psychodysleptics [hallucinogens], subsequent encounter',
  'T40905S Adverse effect of unspecified psychodysleptics [hallucinogens], sequela',
  'T40906A Underdosing of unspecified psychodysleptics, initial encounter',
  'T40906D Underdosing of unspecified psychodysleptics, subsequent encounter',
  'T40906S Underdosing of unspecified psychodysleptics, sequela',
  'T40991A Poisoning by other psychodysleptics [hallucinogens], accidental (unintentional), initial',
  'encounter',
  'T40991D Poisoning by other psychodysleptics [hallucinogens], accidental (unintentional), subsequent',
  'encounter',
  'T40991S Poisoning by other psychodysleptics [hallucinogens], accidental (unintentional), sequela',
  'T40992A Poisoning by other psychodysleptics [hallucinogens], intentional self-harm, initial encounter',
  'T40992D Poisoning by other psychodysleptics [hallucinogens], intentional self-harm, subsequent',
  'encounter',
  'T40992S Poisoning by other psychodysleptics [hallucinogens], intentional self-harm, sequela',
  'T40993A Poisoning by other psychodysleptics [hallucinogens], assault, initial encounter',
  'T40993D Poisoning by other psychodysleptics [hallucinogens], assault, subsequent encounter',
  'T40993S Poisoning by other psychodysleptics [hallucinogens], assault, sequela',
  'T40994A Poisoning by other psychodysleptics [hallucinogens], undetermined, initial encounter',
  'T40994D Poisoning by other psychodysleptics [hallucinogens], undetermined, subsequent encounter',
  'T40994S Poisoning by other psychodysleptics [hallucinogens], undetermined, sequela',
  'T40995A Adverse effect of other psychodysleptics [hallucinogens], initial encounter',
  'T40995D Adverse effect of other psychodysleptics [hallucinogens], subsequent encounter',
  'T40995S Adverse effect of other psychodysleptics [hallucinogens], sequela',
  'T40996A Underdosing of other psychodysleptics, initial encounter',
  'T40996D Underdosing of other psychodysleptics, subsequent encounter',
  'T40996S Underdosing of other psychodysleptics, sequela',
  'T410X1A Poisoning by inhaled anesthetics, accidental (unintentional), initial encounter',
  'T410X1D Poisoning by inhaled anesthetics, accidental (unintentional), subsequent encounter',
  'T410X1S Poisoning by inhaled anesthetics, accidental (unintentional), sequela',
  'T410X2A Poisoning by inhaled anesthetics, intentional self-harm, initial encounter',
  'T410X2D Poisoning by inhaled anesthetics, intentional self-harm, subsequent encounter',
  'T410X2S Poisoning by inhaled anesthetics, intentional self-harm, sequela',
  'T410X3A Poisoning by inhaled anesthetics, assault, initial encounter',
  'T410X3D Poisoning by inhaled anesthetics, assault, subsequent encounter',
  'T410X3S Poisoning by inhaled anesthetics, assault, sequela',
  'T410X4A Poisoning by inhaled anesthetics, undetermined, initial encounter',
  'T410X4D Poisoning by inhaled anesthetics, undetermined, subsequent encounter',
  'T410X4S Poisoning by inhaled anesthetics, undetermined, sequela',
  'T410X5A Adverse effect of inhaled anesthetics, initial encounter',
  'T410X5D Adverse effect of inhaled anesthetics, subsequent encounter',
  'T410X5S Adverse effect of inhaled anesthetics, sequela',
  'T410X6A Underdosing of inhaled anesthetics, initial encounter',
  'T410X6D Underdosing of inhaled anesthetics, subsequent encounter',
  'T410X6S Underdosing of inhaled anesthetics, sequela',
  'T411X1A Poisoning by intravenous anesthetics, accidental (unintentional), initial encounter',
  'T411X1D Poisoning by intravenous anesthetics, accidental (unintentional), subsequent encounter',
  'T411X1S Poisoning by intravenous anesthetics, accidental (unintentional), sequela',
  'T411X2A Poisoning by intravenous anesthetics, intentional self-harm, initial encounter',
  'T411X2D Poisoning by intravenous anesthetics, intentional self-harm, subsequent encounter',
  'T411X2S Poisoning by intravenous anesthetics, intentional self-harm, sequela',
  'T411X3A Poisoning by intravenous anesthetics, assault, initial encounter',
  'T411X3D Poisoning by intravenous anesthetics, assault, subsequent encounter',
  'T411X3S Poisoning by intravenous anesthetics, assault, sequela',
  'T411X4A Poisoning by intravenous anesthetics, undetermined, initial encounter',
  'T411X4D Poisoning by intravenous anesthetics, undetermined, subsequent encounter',
  'T411X4S Poisoning by intravenous anesthetics, undetermined, sequela',
  'T411X5A Adverse effect of intravenous anesthetics, initial encounter',
  'T411X5D Adverse effect of intravenous anesthetics, subsequent encounter',
  'T411X5S Adverse effect of intravenous anesthetics, sequela',
  'T411X6A Underdosing of intravenous anesthetics, initial encounter',
  'T411X6D Underdosing of intravenous anesthetics, subsequent encounter',
  'T411X6S Underdosing of intravenous anesthetics, sequela',
  'T41201A Poisoning by unspecified general anesthetics, accidental (unintentional), initial encounter',
  'T41201D Poisoning by unspecified general anesthetics, accidental (unintentional), subsequent',
  'encounter',
  'T41201S Poisoning by unspecified general anesthetics, accidental (unintentional), sequela',
  'T41202A Poisoning by unspecified general anesthetics, intentional self-harm, initial encounter',
  'T41202D Poisoning by unspecified general anesthetics, intentional self-harm, subsequent encounter',
  'T41202S Poisoning by unspecified general anesthetics, intentional self-harm, sequela',
  'T41203A Poisoning by unspecified general anesthetics, assault, initial encounter',
  'T41203D Poisoning by unspecified general anesthetics, assault, subsequent encounter',
  'T41203S Poisoning by unspecified general anesthetics, assault, sequela',
  'T41204A Poisoning by unspecified general anesthetics, undetermined, initial encounter',
  'T41204D Poisoning by unspecified general anesthetics, undetermined, subsequent encounter',
  'T41204S Poisoning by unspecified general anesthetics, undetermined, sequela',
  'T41205A Adverse effect of unspecified general anesthetics, initial encounter',
  'T41205D Adverse effect of unspecified general anesthetics, subsequent encounter',
  'T41205S Adverse effect of unspecified general anesthetics, sequela',
  'T41206A Underdosing of unspecified general anesthetics, initial encounter',
  'T41206D Underdosing of unspecified general anesthetics, subsequent encounter',
  'T41206S Underdosing of unspecified general anesthetics, sequela',
  'T41291A Poisoning by other general anesthetics, accidental (unintentional), initial encounter',
  'T41291D Poisoning by other general anesthetics, accidental (unintentional), subsequent encounter',
  'T41291S Poisoning by other general anesthetics, accidental (unintentional), sequela',
  'T41292A Poisoning by other general anesthetics, intentional self-harm, initial encounter',
  'T41292D Poisoning by other general anesthetics, intentional self-harm, subsequent encounter',
  'T41292S Poisoning by other general anesthetics, intentional self-harm, sequela',
  'T41293A Poisoning by other general anesthetics, assault, initial encounter',
  'T41293D Poisoning by other general anesthetics, assault, subsequent encounter',
  'T41293S Poisoning by other general anesthetics, assault, sequela',
  'T41294A Poisoning by other general anesthetics, undetermined, initial encounter',
  'T41294D Poisoning by other general anesthetics, undetermined, subsequent encounter',
  'T41294S Poisoning by other general anesthetics, undetermined, sequela',
  'T41295A Adverse effect of other general anesthetics, initial encounter',
  'T41295D Adverse effect of other general anesthetics, subsequent encounter',
  'T41295S Adverse effect of other general anesthetics, sequela',
  'T41296A Underdosing of other general anesthetics, initial encounter',
  'T41296D Underdosing of other general anesthetics, subsequent encounter',
  'T41296S Underdosing of other general anesthetics, sequela',
  'T413X1A Poisoning by local anesthetics, accidental (unintentional), initial encounter',
  'T413X1D Poisoning by local anesthetics, accidental (unintentional), subsequent encounter',
  'T413X1S Poisoning by local anesthetics, accidental (unintentional), sequela',
  'T413X2A Poisoning by local anesthetics, intentional self-harm, initial encounter',
  'T413X2D Poisoning by local anesthetics, intentional self-harm, subsequent encounter',
  'T413X2S Poisoning by local anesthetics, intentional self-harm, sequela',
  'T413X3A Poisoning by local anesthetics, assault, initial encounter',
  'T413X3D Poisoning by local anesthetics, assault, subsequent encounter',
  'T413X3S Poisoning by local anesthetics, assault, sequela',
  'T413X4A Poisoning by local anesthetics, undetermined, initial encounter',
  'T413X4D Poisoning by local anesthetics, undetermined, subsequent encounter',
  'T413X4S Poisoning by local anesthetics, undetermined, sequela',
  'T413X5A Adverse effect of local anesthetics, initial encounter',
  'T413X5D Adverse effect of local anesthetics, subsequent encounter',
  'T413X5S Adverse effect of local anesthetics, sequela',
  'T413X6A Underdosing of local anesthetics, initial encounter',
  'T413X6D Underdosing of local anesthetics, subsequent encounter',
  'T413X6S Underdosing of local anesthetics, sequela',
  'T4141XA Poisoning by unspecified anesthetic, accidental (unintentional), initial encounter',
  'T4141XD Poisoning by unspecified anesthetic, accidental (unintentional), subsequent encounter',
  'T4141XS Poisoning by unspecified anesthetic, accidental (unintentional), sequela',
  'T4142XA Poisoning by unspecified anesthetic, intentional self-harm, initial encounter',
  'T4142XD Poisoning by unspecified anesthetic, intentional self-harm, subsequent encounter',
  'T4142XS Poisoning by unspecified anesthetic, intentional self-harm, sequela',
  'T4143XA Poisoning by unspecified anesthetic, assault, initial encounter',
  'T4143XD Poisoning by unspecified anesthetic, assault, subsequent encounter',
  'T4143XS Poisoning by unspecified anesthetic, assault, sequela',
  'T4144XA Poisoning by unspecified anesthetic, undetermined, initial encounter',
  'T4144XD Poisoning by unspecified anesthetic, undetermined, subsequent encounter',
  'T4144XS Poisoning by unspecified anesthetic, undetermined, sequela',
  'T4145XA Adverse effect of unspecified anesthetic, initial encounter',
  'T4145XD Adverse effect of unspecified anesthetic, subsequent encounter',
  'T4145XS Adverse effect of unspecified anesthetic, sequela',
  'T4146XA Underdosing of unspecified anesthetics, initial encounter',
  'T4146XD Underdosing of unspecified anesthetics, subsequent encounter',
  'T4146XS Underdosing of unspecified anesthetics, sequela',
  'T415X1A Poisoning by therapeutic gases, accidental (unintentional), initial encounter',
  'T415X1D Poisoning by therapeutic gases, accidental (unintentional), subsequent encounter',
  'T415X1S Poisoning by therapeutic gases, accidental (unintentional), sequela',
  'T415X2A Poisoning by therapeutic gases, intentional self-harm, initial encounter',
  'T415X2D Poisoning by therapeutic gases, intentional self-harm, subsequent encounter',
  'T415X2S Poisoning by therapeutic gases, intentional self-harm, sequela',
  'T415X3A Poisoning by therapeutic gases, assault, initial encounter',
  'T415X3D Poisoning by therapeutic gases, assault, subsequent encounter',
  'T415X3S Poisoning by therapeutic gases, assault, sequela',
  'T415X4A Poisoning by therapeutic gases, undetermined, initial encounter',
  'T415X4D Poisoning by therapeutic gases, undetermined, subsequent encounter',
  'T415X4S Poisoning by therapeutic gases, undetermined, sequela',
  'T415X5A Adverse effect of therapeutic gases, initial encounter',
  'T415X5D Adverse effect of therapeutic gases, subsequent encounter',
  'T415X5S Adverse effect of therapeutic gases, sequela',
  'T415X6A Underdosing of therapeutic gases, initial encounter',
  'T415X6D Underdosing of therapeutic gases, subsequent encounter',
  'T415X6S Underdosing of therapeutic gases, sequela',
  'T420X1A Poisoning by hydantoin derivatives, accidental (unintentional), initial encounter',
  'T420X1D Poisoning by hydantoin derivatives, accidental (unintentional), subsequent encounter',
  'T420X1S Poisoning by hydantoin derivatives, accidental (unintentional), sequela',
  'T420X2A Poisoning by hydantoin derivatives, intentional self-harm, initial encounter',
  'T420X2D Poisoning by hydantoin derivatives, intentional self-harm, subsequent encounter',
  'T420X2S Poisoning by hydantoin derivatives, intentional self-harm, sequela',
  'T420X3A Poisoning by hydantoin derivatives, assault, initial encounter',
  'T420X3D Poisoning by hydantoin derivatives, assault, subsequent encounter',
  'T420X3S Poisoning by hydantoin derivatives, assault, sequela',
  'T420X4A Poisoning by hydantoin derivatives, undetermined, initial encounter',
  'T420X4D Poisoning by hydantoin derivatives, undetermined, subsequent encounter',
  'T420X4S Poisoning by hydantoin derivatives, undetermined, sequela',
  'T420X5A Adverse effect of hydantoin derivatives, initial encounter',
  'T420X5D Adverse effect of hydantoin derivatives, subsequent encounter',
  'T420X5S Adverse effect of hydantoin derivatives, sequela',
  'T420X6A Underdosing of hydantoin derivatives, initial encounter',
  'T420X6D Underdosing of hydantoin derivatives, subsequent encounter',
  'T420X6S Underdosing of hydantoin derivatives, sequela',
  'T421X1A Poisoning by iminostilbenes, accidental (unintentional), initial encounter',
  'T421X1D Poisoning by iminostilbenes, accidental (unintentional), subsequent encounter',
  'T421X1S Poisoning by iminostilbenes, accidental (unintentional), sequela',
  'T421X2A Poisoning by iminostilbenes, intentional self-harm, initial encounter',
  'T421X2D Poisoning by iminostilbenes, intentional self-harm, subsequent encounter',
  'T421X2S Poisoning by iminostilbenes, intentional self-harm, sequela',
  'T421X3A Poisoning by iminostilbenes, assault, initial encounter',
  'T421X3D Poisoning by iminostilbenes, assault, subsequent encounter',
  'T421X3S Poisoning by iminostilbenes, assault, sequela',
  'T421X4A Poisoning by iminostilbenes, undetermined, initial encounter',
  'T421X4D Poisoning by iminostilbenes, undetermined, subsequent encounter',
  'T421X4S Poisoning by iminostilbenes, undetermined, sequela',
  'T421X5A Adverse effect of iminostilbenes, initial encounter',
  'T421X5D Adverse effect of iminostilbenes, subsequent encounter',
  'T421X5S Adverse effect of iminostilbenes, sequela',
  'T421X6A Underdosing of iminostilbenes, initial encounter',
  'T421X6D Underdosing of iminostilbenes, subsequent encounter',
  'T421X6S Underdosing of iminostilbenes, sequela',
  'T422X1A Poisoning by succinimides and oxazolidinediones, accidental (unintentional), initial encounter',
  'T422X1D Poisoning by succinimides and oxazolidinediones, accidental (unintentional), subsequent',
  'encounter',
  'T422X1S Poisoning by succinimides and oxazolidinediones, accidental (unintentional), sequela',
  'T422X2A Poisoning by succinimides and oxazolidinediones, intentional self-harm, initial encounter',
  'T422X2D Poisoning by succinimides and oxazolidinediones, intentional self-harm, subsequent encounter',
  'T422X2S Poisoning by succinimides and oxazolidinediones, intentional self-harm, sequela',
  'T422X3A Poisoning by succinimides and oxazolidinediones, assault, initial encounter',
  'T422X3D Poisoning by succinimides and oxazolidinediones, assault, subsequent encounter',
  'T422X3S Poisoning by succinimides and oxazolidinediones, assault, sequela',
  'T422X4A Poisoning by succinimides and oxazolidinediones, undetermined, initial encounter',
  'T422X4D Poisoning by succinimides and oxazolidinediones, undetermined, subsequent encounter',
  'T422X4S Poisoning by succinimides and oxazolidinediones, undetermined, sequela',
  'T422X5A Adverse effect of succinimides and oxazolidinediones, initial encounter',
  'T422X5D Adverse effect of succinimides and oxazolidinediones, subsequent encounter',
  'T422X5S Adverse effect of succinimides and oxazolidinediones, sequela',
  'T422X6A Underdosing of succinimides and oxazolidinediones, initial encounter',
  'T422X6D Underdosing of succinimides and oxazolidinediones, subsequent encounter',
  'T422X6S Underdosing of succinimides and oxazolidinediones, sequela',
  'T423X1A Poisoning by barbiturates, accidental (unintentional), initial encounter',
  'T423X1D Poisoning by barbiturates, accidental (unintentional), subsequent encounter',
  'T423X1S Poisoning by barbiturates, accidental (unintentional), sequela',
  'T423X2A Poisoning by barbiturates, intentional self-harm, initial encounter',
  'T423X2D Poisoning by barbiturates, intentional self-harm, subsequent encounter',
  'T423X2S Poisoning by barbiturates, intentional self-harm, sequela',
  'T423X3A Poisoning by barbiturates, assault, initial encounter',
  'T423X3D Poisoning by barbiturates, assault, subsequent encounter',
  'T423X3S Poisoning by barbiturates, assault, sequela',
  'T423X4A Poisoning by barbiturates, undetermined, initial encounter',
  'T423X4D Poisoning by barbiturates, undetermined, subsequent encounter',
  'T423X4S Poisoning by barbiturates, undetermined, sequela',
  'T423X5A Adverse effect of barbiturates, initial encounter',
  'T423X5D Adverse effect of barbiturates, subsequent encounter',
  'T423X5S Adverse effect of barbiturates, sequela',
  'T423X6A Underdosing of barbiturates, initial encounter',
  'T423X6D Underdosing of barbiturates, subsequent encounter',
  'T423X6S Underdosing of barbiturates, sequela',
  'T424X1A Poisoning by benzodiazepines, accidental (unintentional), initial encounter',
  'T424X1D Poisoning by benzodiazepines, accidental (unintentional), subsequent encounter',
  'T424X1S Poisoning by benzodiazepines, accidental (unintentional), sequela',
  'T424X2A Poisoning by benzodiazepines, intentional self-harm, initial encounter',
  'T424X2D Poisoning by benzodiazepines, intentional self-harm, subsequent encounter',
  'T424X2S Poisoning by benzodiazepines, intentional self-harm, sequela',
  'T424X3A Poisoning by benzodiazepines, assault, initial encounter',
  'T424X3D Poisoning by benzodiazepines, assault, subsequent encounter',
  'T424X3S Poisoning by benzodiazepines, assault, sequela',
  'T424X4A Poisoning by benzodiazepines, undetermined, initial encounter',
  'T424X4D Poisoning by benzodiazepines, undetermined, subsequent encounter',
  'T424X4S Poisoning by benzodiazepines, undetermined, sequela',
  'T424X5A Adverse effect of benzodiazepines, initial encounter',
  'T424X5D Adverse effect of benzodiazepines, subsequent encounter',
  'T424X5S Adverse effect of benzodiazepines, sequela',
  'T424X6A Underdosing of benzodiazepines, initial encounter',
  'T424X6D Underdosing of benzodiazepines, subsequent encounter',
  'T424X6S Underdosing of benzodiazepines, sequela',
  'T425X1A Poisoning by mixed antiepileptics, accidental (unintentional), initial encounter',
  'T425X1D Poisoning by mixed antiepileptics, accidental (unintentional), subsequent encounter',
  'T425X1S Poisoning by mixed antiepileptics, accidental (unintentional), sequela',
  'T425X2A Poisoning by mixed antiepileptics, intentional self-harm, initial encounter',
  'T425X2D Poisoning by mixed antiepileptics, intentional self-harm, subsequent encounter',
  'T425X2S Poisoning by mixed antiepileptics, intentional self-harm, sequela',
  'T425X3A Poisoning by mixed antiepileptics, assault, initial encounter',
  'T425X3D Poisoning by mixed antiepileptics, assault, subsequent encounter',
  'T425X3S Poisoning by mixed antiepileptics, assault, sequela',
  'T425X4A Poisoning by mixed antiepileptics, undetermined, initial encounter',
  'T425X4D Poisoning by mixed antiepileptics, undetermined, subsequent encounter',
  'T425X4S Poisoning by mixed antiepileptics, undetermined, sequela',
  'T425X5A Adverse effect of mixed antiepileptics, initial encounter',
  'T425X5D Adverse effect of mixed antiepileptics, subsequent encounter',
  'T425X5S Adverse effect of mixed antiepileptics, sequela',
  'T425X6A Underdosing of mixed antiepileptics, initial encounter',
  'T425X6D Underdosing of mixed antiepileptics, subsequent encounter',
  'T425X6S Underdosing of mixed antiepileptics, sequela',
  'T426X1A Poisoning by other antiepileptic and sedative-hypnotic drugs, accidental (unintentional), initial',
  'encounter',
  'T426X1D Poisoning by other antiepileptic and sedative-hypnotic drugs, accidental (unintentional),',
  'subsequent encounter',
  'T426X1S Poisoning by other antiepileptic and sedative-hypnotic drugs, accidental (unintentional),',
  'sequela',
  'T426X2A Poisoning by other antiepileptic and sedative-hypnotic drugs, intentional self-harm, initial',
  'encounter',
  'T426X2D Poisoning by other antiepileptic and sedative-hypnotic drugs, intentional self-harm,',
  'subsequent encounter',
  'T426X2S Poisoning by other antiepileptic and sedative-hypnotic drugs, intentional self-harm, sequela',
  'T426X3A Poisoning by other antiepileptic and sedative-hypnotic drugs, assault, initial encounter',
  'T426X3D Poisoning by other antiepileptic and sedative-hypnotic drugs, assault, subsequent encounter',
  'T426X3S Poisoning by other antiepileptic and sedative-hypnotic drugs, assault, sequela',
  'T426X4A Poisoning by other antiepileptic and sedative-hypnotic drugs, undetermined, initial encounter',
  'T426X4D Poisoning by other antiepileptic and sedative-hypnotic drugs, undetermined, subsequent',
  'encounter',
  'T426X4S Poisoning by other antiepileptic and sedative-hypnotic drugs, undetermined, sequela',
  'T426X5A Adverse effect of other antiepileptic and sedative-hypnotic drugs, initial encounter',
  'T426X5D Adverse effect of other antiepileptic and sedative-hypnotic drugs, subsequent encounter',
  'T426X5S Adverse effect of other antiepileptic and sedative-hypnotic drugs, sequela',
  'T426X6A Underdosing of other antiepileptic and sedative-hypnotic drugs, initial encounter',
  'T426X6D Underdosing of other antiepileptic and sedative-hypnotic drugs, subsequent encounter',
  'T426X6S Underdosing of other antiepileptic and sedative-hypnotic drugs, sequela',
  'T4271XA Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, accidental (unintentional),',
  'initial encounter',
  'T4271XD Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, accidental (unintentional),',
  'subsequent encounter',
  'T4271XS Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, accidental (unintentional),',
  'sequela',
  'T4272XA Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, intentional self-harm,',
  'initial encounter',
  'T4272XD Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, intentional self-harm,',
  'subsequent encounter',
  'T4272XS Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, intentional self-harm,',
  'sequela',
  'T4273XA Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, assault, initial encounter',
  'T4273XD Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, assault, subsequent',
  'encounter',
  'T4273XS Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, assault, sequela',
  'T4274XA Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, undetermined, initial',
  'encounter',
  'T4274XD Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, undetermined, subsequent',
  'encounter',
  'T4274XS Poisoning by unspecified antiepileptic and sedative-hypnotic drugs, undetermined, sequela',
  'T4275XA Adverse effect of unspecified antiepileptic and sedative-hypnotic drugs, initial encounter',
  'T4275XD Adverse effect of unspecified antiepileptic and sedative-hypnotic drugs, subsequent encounter',
  'T4275XS Adverse effect of unspecified antiepileptic and sedative-hypnotic drugs, sequela',
  'T4276XA Underdosing of unspecified antiepileptic and sedative-hypnotic drugs, initial encounter',
  'T4276XD Underdosing of unspecified antiepileptic and sedative-hypnotic drugs, subsequent encounter',
  'T4276XS Underdosing of unspecified antiepileptic and sedative-hypnotic drugs, sequela',
  'T428X1A Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, accidental',
  '(unintentional), initial encounter',
  'T428X1D Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, accidental',
  '(unintentional), subsequent encounter',
  'T428X1S Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, accidental',
  '(unintentional), sequela',
  'T428X2A Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, intentional',
  'self-harm, initial encounter',
  'T428X2D Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, intentional',
  'self-harm, subsequent encounter',
  'T428X2S Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, intentional',
  'self-harm, sequela',
  'T428X3A Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, assault, initial',
  'encounter',
  'T428X3D Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, assault,',
  'subsequent encounter',
  'T428X3S Poisoning by antiparkinsonism drugs and other central muscle-tone depressants, assault,',
  'sequela',
  'T428X4A Poisoning by antiparkinsonism drugs and other central muscle-tone depressants,',
  'undetermined, initial encounter',
  'T428X4D Poisoning by antiparkinsonism drugs and other central muscle-tone depressants,',
  'undetermined, subsequent encounter',
  'T428X4S Poisoning by antiparkinsonism drugs and other central muscle-tone depressants,',
  'undetermined, sequela',
  'T428X5A Adverse effect of antiparkinsonism drugs and other central muscle-tone depressants, initial',
  'encounter',
  'T428X5D Adverse effect of antiparkinsonism drugs and other central muscle-tone depressants,',
  'subsequent encounter',
  'T428X5S Adverse effect of antiparkinsonism drugs and other central muscle-tone depressants, sequela',
  'T428X6A Underdosing of antiparkinsonism drugs and other central muscle-tone depressants, initial',
  'encounter',
  'T428X6D Underdosing of antiparkinsonism drugs and other central muscle-tone depressants,',
  'subsequent encounter',
  'T428X6S Underdosing of antiparkinsonism drugs and other central muscle-tone depressants, sequela',
  'T43011A Poisoning by tricyclic antidepressants, accidental (unintentional), initial encounter',
  'T43011D Poisoning by tricyclic antidepressants, accidental (unintentional), subsequent encounter',
  'T43011S Poisoning by tricyclic antidepressants, accidental (unintentional), sequela',
  'T43012A Poisoning by tricyclic antidepressants, intentional self-harm, initial encounter',
  'T43012D Poisoning by tricyclic antidepressants, intentional self-harm, subsequent encounter',
  'T43012S Poisoning by tricyclic antidepressants, intentional self-harm, sequela',
  'T43013A Poisoning by tricyclic antidepressants, assault, initial encounter',
  'T43013D Poisoning by tricyclic antidepressants, assault, subsequent encounter',
  'T43013S Poisoning by tricyclic antidepressants, assault, sequela',
  'T43014A Poisoning by tricyclic antidepressants, undetermined, initial encounter',
  'T43014D Poisoning by tricyclic antidepressants, undetermined, subsequent encounter',
  'T43014S Poisoning by tricyclic antidepressants, undetermined, sequela',
  'T43015A Adverse effect of tricyclic antidepressants, initial encounter',
  'T43015D Adverse effect of tricyclic antidepressants, subsequent encounter',
  'T43015S Adverse effect of tricyclic antidepressants, sequela',
  'T43016A Underdosing of tricyclic antidepressants, initial encounter',
  'T43016D Underdosing of tricyclic antidepressants, subsequent encounter',
  'T43016S Underdosing of tricyclic antidepressants, sequela',
  'T43021A Poisoning by tetracyclic antidepressants, accidental (unintentional), initial encounter',
  'T43021D Poisoning by tetracyclic antidepressants, accidental (unintentional), subsequent encounter',
  'T43021S Poisoning by tetracyclic antidepressants, accidental (unintentional), sequela',
  'T43022A Poisoning by tetracyclic antidepressants, intentional self-harm, initial encounter',
  'T43022D Poisoning by tetracyclic antidepressants, intentional self-harm, subsequent encounter',
  'T43022S Poisoning by tetracyclic antidepressants, intentional self-harm, sequela',
  'T43023A Poisoning by tetracyclic antidepressants, assault, initial encounter',
  'T43023D Poisoning by tetracyclic antidepressants, assault, subsequent encounter',
  'T43023S Poisoning by tetracyclic antidepressants, assault, sequela',
  'T43024A Poisoning by tetracyclic antidepressants, undetermined, initial encounter',
  'T43024D Poisoning by tetracyclic antidepressants, undetermined, subsequent encounter',
  'T43024S Poisoning by tetracyclic antidepressants, undetermined, sequela',
  'T43025A Adverse effect of tetracyclic antidepressants, initial encounter',
  'T43025D Adverse effect of tetracyclic antidepressants, subsequent encounter',
  'T43025S Adverse effect of tetracyclic antidepressants, sequela',
  'T43026A Underdosing of tetracyclic antidepressants, initial encounter',
  'T43026D Underdosing of tetracyclic antidepressants, subsequent encounter',
  'T43026S Underdosing of tetracyclic antidepressants, sequela',
  'T431X1A Poisoning by monoamine-oxidase-inhibitor antidepressants, accidental (unintentional), initial',
  'encounter',
  'T431X1D Poisoning by monoamine-oxidase-inhibitor antidepressants, accidental (unintentional),',
  'subsequent encounter',
  'T431X1S Poisoning by monoamine-oxidase-inhibitor antidepressants, accidental (unintentional), sequela',
  'T431X2A Poisoning by monoamine-oxidase-inhibitor antidepressants, intentional self-harm, initial',
  'encounter',
  'T431X2D Poisoning by monoamine-oxidase-inhibitor antidepressants, intentional self-harm, subsequent',
  'encounter',
  'T431X2S Poisoning by monoamine-oxidase-inhibitor antidepressants, intentional self-harm, sequela',
  'T431X3A Poisoning by monoamine-oxidase-inhibitor antidepressants, assault, initial encounter',
  'T431X3D Poisoning by monoamine-oxidase-inhibitor antidepressants, assault, subsequent encounter',
  'T431X3S Poisoning by monoamine-oxidase-inhibitor antidepressants, assault, sequela',
  'T431X4A Poisoning by monoamine-oxidase-inhibitor antidepressants, undetermined, initial encounter',
  'T431X4D Poisoning by monoamine-oxidase-inhibitor antidepressants, undetermined, subsequent',
  'encounter',
  'T431X4S Poisoning by monoamine-oxidase-inhibitor antidepressants, undetermined, sequela',
  'T431X5A Adverse effect of monoamine-oxidase-inhibitor antidepressants, initial encounter',
  'T431X5D Adverse effect of monoamine-oxidase-inhibitor antidepressants, subsequent encounter',
  'T431X5S Adverse effect of monoamine-oxidase-inhibitor antidepressants, sequela',
  'T431X6A Underdosing of monoamine-oxidase-inhibitor antidepressants, initial encounter',
  'T431X6D Underdosing of monoamine-oxidase-inhibitor antidepressants, subsequent encounter',
  'T431X6S Underdosing of monoamine-oxidase-inhibitor antidepressants, sequela',
  'T43201A Poisoning by unspecified antidepressants, accidental (unintentional), initial encounter',
  'T43201D Poisoning by unspecified antidepressants, accidental (unintentional), subsequent encounter',
  'T43201S Poisoning by unspecified antidepressants, accidental (unintentional), sequela',
  'T43202A Poisoning by unspecified antidepressants, intentional self-harm, initial encounter',
  'T43202D Poisoning by unspecified antidepressants, intentional self-harm, subsequent encounter',
  'T43202S Poisoning by unspecified antidepressants, intentional self-harm, sequela',
  'T43203A Poisoning by unspecified antidepressants, assault, initial encounter',
  'T43203D Poisoning by unspecified antidepressants, assault, subsequent encounter',
  'T43203S Poisoning by unspecified antidepressants, assault, sequela',
  'T43204A Poisoning by unspecified antidepressants, undetermined, initial encounter',
  'T43204D Poisoning by unspecified antidepressants, undetermined, subsequent encounter',
  'T43204S Poisoning by unspecified antidepressants, undetermined, sequela',
  'T43205A Adverse effect of unspecified antidepressants, initial encounter',
  'T43205D Adverse effect of unspecified antidepressants, subsequent encounter',
  'T43205S Adverse effect of unspecified antidepressants, sequela',
  'T43206A Underdosing of unspecified antidepressants, initial encounter',
  'T43206D Underdosing of unspecified antidepressants, subsequent encounter',
  'T43206S Underdosing of unspecified antidepressants, sequela',
  'T43211A Poisoning by selective serotonin and norepinephrine reuptake inhibitors, accidental',
  '(unintentional), initial encounter',
  'T43211D Poisoning by selective serotonin and norepinephrine reuptake inhibitors, accidental',
  '(unintentional), subsequent encounter',
  'T43211S Poisoning by selective serotonin and norepinephrine reuptake inhibitors, accidental',
  '(unintentional), sequela',
  'T43212A Poisoning by selective serotonin and norepinephrine reuptake inhibitors, intentional self-harm,',
  'initial encounter',
  'T43212D Poisoning by selective serotonin and norepinephrine reuptake inhibitors, intentional self-harm,',
  'subsequent encounter',
  'T43212S Poisoning by selective serotonin and norepinephrine reuptake inhibitors, intentional self-harm,',
  'sequela',
  'T43213A Poisoning by selective serotonin and norepinephrine reuptake inhibitors, assault, initial',
  'encounter',
  'T43213D Poisoning by selective serotonin and norepinephrine reuptake inhibitors, assault, subsequent',
  'encounter',
  'T43213S Poisoning by selective serotonin and norepinephrine reuptake inhibitors, assault, sequela',
  'T43214A Poisoning by selective serotonin and norepinephrine reuptake inhibitors, undetermined, initial',
  'encounter',
  'T43214D Poisoning by selective serotonin and norepinephrine reuptake inhibitors, undetermined,',
  'subsequent encounter',
  'T43214S Poisoning by selective serotonin and norepinephrine reuptake inhibitors, undetermined,',
  'sequela',
  'T43215A Adverse effect of selective serotonin and norepinephrine reuptake inhibitors, initial encounter',
  'T43215D Adverse effect of selective serotonin and norepinephrine reuptake inhibitors, subsequent',
  'encounter',
  'T43215S Adverse effect of selective serotonin and norepinephrine reuptake inhibitors, sequela',
  'T43216A Underdosing of selective serotonin and norepinephrine reuptake inhibitors, initial encounter',
  'T43216D Underdosing of selective serotonin and norepinephrine reuptake inhibitors, subsequent',
  'encounter',
  'T43216S Underdosing of selective serotonin and norepinephrine reuptake inhibitors, sequela',
  'T43221A Poisoning by selective serotonin reuptake inhibitors, accidental (unintentional), initial',
  'encounter',
  'T43221D Poisoning by selective serotonin reuptake inhibitors, accidental (unintentional), subsequent',
  'encounter',
  'T43221S Poisoning by selective serotonin reuptake inhibitors, accidental (unintentional), sequela',
  'T43222A Poisoning by selective serotonin reuptake inhibitors, intentional self-harm, initial encounter',
  'T43222D Poisoning by selective serotonin reuptake inhibitors, intentional self-harm, subsequent',
  'encounter',
  'T43222S Poisoning by selective serotonin reuptake inhibitors, intentional self-harm, sequela',
  'T43223A Poisoning by selective serotonin reuptake inhibitors, assault, initial encounter',
  'T43223D Poisoning by selective serotonin reuptake inhibitors, assault, subsequent encounter',
  'T43223S Poisoning by selective serotonin reuptake inhibitors, assault, sequela',
  'T43224A Poisoning by selective serotonin reuptake inhibitors, undetermined, initial encounter',
  'T43224D Poisoning by selective serotonin reuptake inhibitors, undetermined, subsequent encounter',
  'T43224S Poisoning by selective serotonin reuptake inhibitors, undetermined, sequela',
  'T43225A Adverse effect of selective serotonin reuptake inhibitors, initial encounter',
  'T43225D Adverse effect of selective serotonin reuptake inhibitors, subsequent encounter',
  'T43225S Adverse effect of selective serotonin reuptake inhibitors, sequela',
  'T43226A Underdosing of selective serotonin reuptake inhibitors, initial encounter',
  'T43226D Underdosing of selective serotonin reuptake inhibitors, subsequent encounter',
  'T43226S Underdosing of selective serotonin reuptake inhibitors, sequela',
  'T43291A Poisoning by other antidepressants, accidental (unintentional), initial encounter',
  'T43291D Poisoning by other antidepressants, accidental (unintentional), subsequent encounter',
  'T43291S Poisoning by other antidepressants, accidental (unintentional), sequela',
  'T43292A Poisoning by other antidepressants, intentional self-harm, initial encounter',
  'T43292D Poisoning by other antidepressants, intentional self-harm, subsequent encounter',
  'T43292S Poisoning by other antidepressants, intentional self-harm, sequela',
  'T43293A Poisoning by other antidepressants, assault, initial encounter',
  'T43293D Poisoning by other antidepressants, assault, subsequent encounter',
  'T43293S Poisoning by other antidepressants, assault, sequela',
  'T43294A Poisoning by other antidepressants, undetermined, initial encounter',
  'T43294D Poisoning by other antidepressants, undetermined, subsequent encounter',
  'T43294S Poisoning by other antidepressants, undetermined, sequela',
  'T43295A Adverse effect of other antidepressants, initial encounter',
  'T43295D Adverse effect of other antidepressants, subsequent encounter',
  'T43295S Adverse effect of other antidepressants, sequela',
  'T43296A Underdosing of other antidepressants, initial encounter',
  'T43296D Underdosing of other antidepressants, subsequent encounter',
  'T43296S Underdosing of other antidepressants, sequela',
  'T433X1A Poisoning by phenothiazine antipsychotics and neuroleptics, accidental (unintentional), initial',
  'encounter',
  'T433X1D Poisoning by phenothiazine antipsychotics and neuroleptics, accidental (unintentional),',
  'subsequent encounter',
  'T433X1S Poisoning by phenothiazine antipsychotics and neuroleptics, accidental (unintentional), sequela',
  'T433X2A Poisoning by phenothiazine antipsychotics and neuroleptics, intentional self-harm, initial',
  'encounter',
  'T433X2D Poisoning by phenothiazine antipsychotics and neuroleptics, intentional self-harm, subsequent',
  'encounter',
  'T433X2S Poisoning by phenothiazine antipsychotics and neuroleptics, intentional self-harm, sequela',
  'T433X3A Poisoning by phenothiazine antipsychotics and neuroleptics, assault, initial encounter',
  'T433X3D Poisoning by phenothiazine antipsychotics and neuroleptics, assault, subsequent encounter',
  'T433X3S Poisoning by phenothiazine antipsychotics and neuroleptics, assault, sequela',
  'T433X4A Poisoning by phenothiazine antipsychotics and neuroleptics, undetermined, initial encounter',
  'T433X4D Poisoning by phenothiazine antipsychotics and neuroleptics, undetermined, subsequent',
  'encounter',
  'T433X4S Poisoning by phenothiazine antipsychotics and neuroleptics, undetermined, sequela',
  'T433X5A Adverse effect of phenothiazine antipsychotics and neuroleptics, initial encounter',
  'T433X5D Adverse effect of phenothiazine antipsychotics and neuroleptics, subsequent encounter',
  'T433X5S Adverse effect of phenothiazine antipsychotics and neuroleptics, sequela',
  'T433X6A Underdosing of phenothiazine antipsychotics and neuroleptics, initial encounter',
  'T433X6D Underdosing of phenothiazine antipsychotics and neuroleptics, subsequent encounter',
  'T433X6S Underdosing of phenothiazine antipsychotics and neuroleptics, sequela',
  'T434X1A Poisoning by butyrophenone and thiothixene neuroleptics, accidental (unintentional), initial',
  'encounter',
  'T434X1D Poisoning by butyrophenone and thiothixene neuroleptics, accidental (unintentional),',
  'subsequent encounter',
  'T434X1S Poisoning by butyrophenone and thiothixene neuroleptics, accidental (unintentional), sequela',
  'T434X2A Poisoning by butyrophenone and thiothixene neuroleptics, intentional self-harm, initial',
  'encounter',
  'T434X2D Poisoning by butyrophenone and thiothixene neuroleptics, intentional self-harm, subsequent',
  'encounter',
  'T434X2S Poisoning by butyrophenone and thiothixene neuroleptics, intentional self-harm, sequela',
  'T434X3A Poisoning by butyrophenone and thiothixene neuroleptics, assault, initial encounter',
  'T434X3D Poisoning by butyrophenone and thiothixene neuroleptics, assault, subsequent encounter',
  'T434X3S Poisoning by butyrophenone and thiothixene neuroleptics, assault, sequela',
  'T434X4A Poisoning by butyrophenone and thiothixene neuroleptics, undetermined, initial encounter',
  'T434X4D Poisoning by butyrophenone and thiothixene neuroleptics, undetermined, subsequent',
  'encounter',
  'T434X4S Poisoning by butyrophenone and thiothixene neuroleptics, undetermined, sequela',
  'T434X5A Adverse effect of butyrophenone and thiothixene neuroleptics, initial encounter',
  'T434X5D Adverse effect of butyrophenone and thiothixene neuroleptics, subsequent encounter',
  'T434X5S Adverse effect of butyrophenone and thiothixene neuroleptics, sequela',
  'T434X6A Underdosing of butyrophenone and thiothixene neuroleptics, initial encounter',
  'T434X6D Underdosing of butyrophenone and thiothixene neuroleptics, subsequent encounter',
  'T434X6S Underdosing of butyrophenone and thiothixene neuroleptics, sequela',
  'T43501A Poisoning by unspecified antipsychotics and neuroleptics, accidental (unintentional), initial',
  'encounter',
  'T43501D Poisoning by unspecified antipsychotics and neuroleptics, accidental (unintentional),',
  'subsequent encounter',
  'T43501S Poisoning by unspecified antipsychotics and neuroleptics, accidental (unintentional), sequela',
  'T43502A Poisoning by unspecified antipsychotics and neuroleptics, intentional self-harm, initial',
  'encounter',
  'T43502D Poisoning by unspecified antipsychotics and neuroleptics, intentional self-harm, subsequent',
  'encounter',
  'T43502S Poisoning by unspecified antipsychotics and neuroleptics, intentional self-harm, sequela',
  'T43503A Poisoning by unspecified antipsychotics and neuroleptics, assault, initial encounter',
  'T43503D Poisoning by unspecified antipsychotics and neuroleptics, assault, subsequent encounter',
  'T43503S Poisoning by unspecified antipsychotics and neuroleptics, assault, sequela',
  'T43504A Poisoning by unspecified antipsychotics and neuroleptics, undetermined, initial encounter',
  'T43504D Poisoning by unspecified antipsychotics and neuroleptics, undetermined, subsequent',
  'encounter',
  'T43504S Poisoning by unspecified antipsychotics and neuroleptics, undetermined, sequela',
  'T43505A Adverse effect of unspecified antipsychotics and neuroleptics, initial encounter',
  'T43505D Adverse effect of unspecified antipsychotics and neuroleptics, subsequent encounter',
  'T43505S Adverse effect of unspecified antipsychotics and neuroleptics, sequela',
  'T43506A Underdosing of unspecified antipsychotics and neuroleptics, initial encounter',
  'T43506D Underdosing of unspecified antipsychotics and neuroleptics, subsequent encounter',
  'T43506S Underdosing of unspecified antipsychotics and neuroleptics, sequela',
  'T43591A Poisoning by other antipsychotics and neuroleptics, accidental (unintentional), initial',
  'encounter',
  'T43591D Poisoning by other antipsychotics and neuroleptics, accidental (unintentional), subsequent',
  'encounter',
  'T43591S Poisoning by other antipsychotics and neuroleptics, accidental (unintentional), sequela',
  'T43592A Poisoning by other antipsychotics and neuroleptics, intentional self-harm, initial encounter',
  'T43592D Poisoning by other antipsychotics and neuroleptics, intentional self-harm, subsequent',
  'encounter',
  'T43592S Poisoning by other antipsychotics and neuroleptics, intentional self-harm, sequela',
  'T43593A Poisoning by other antipsychotics and neuroleptics, assault, initial encounter',
  'T43593D Poisoning by other antipsychotics and neuroleptics, assault, subsequent encounter',
  'T43593S Poisoning by other antipsychotics and neuroleptics, assault, sequela',
  'T43594A Poisoning by other antipsychotics and neuroleptics, undetermined, initial encounter',
  'T43594D Poisoning by other antipsychotics and neuroleptics, undetermined, subsequent encounter',
  'T43594S Poisoning by other antipsychotics and neuroleptics, undetermined, sequela',
  'T43595A Adverse effect of other antipsychotics and neuroleptics, initial encounter',
  'T43595D Adverse effect of other antipsychotics and neuroleptics, subsequent encounter',
  'T43595S Adverse effect of other antipsychotics and neuroleptics, sequela',
  'T43596A Underdosing of other antipsychotics and neuroleptics, initial encounter',
  'T43596D Underdosing of other antipsychotics and neuroleptics, subsequent encounter',
  'T43596S Underdosing of other antipsychotics and neuroleptics, sequela',
  'T43601A Poisoning by unspecified psychostimulants, accidental (unintentional), initial encounter',
  'T43601D Poisoning by unspecified psychostimulants, accidental (unintentional), subsequent encounter',
  'T43601S Poisoning by unspecified psychostimulants, accidental (unintentional), sequela',
  'T43602A Poisoning by unspecified psychostimulants, intentional self-harm, initial encounter',
  'T43602D Poisoning by unspecified psychostimulants, intentional self-harm, subsequent encounter',
  'T43602S Poisoning by unspecified psychostimulants, intentional self-harm, sequela',
  'T43603A Poisoning by unspecified psychostimulants, assault, initial encounter',
  'T43603D Poisoning by unspecified psychostimulants, assault, subsequent encounter',
  'T43603S Poisoning by unspecified psychostimulants, assault, sequela',
  'T43604A Poisoning by unspecified psychostimulants, undetermined, initial encounter',
  'T43604D Poisoning by unspecified psychostimulants, undetermined, subsequent encounter',
  'T43604S Poisoning by unspecified psychostimulants, undetermined, sequela',
  'T43605A Adverse effect of unspecified psychostimulants, initial encounter',
  'T43605D Adverse effect of unspecified psychostimulants, subsequent encounter',
  'T43605S Adverse effect of unspecified psychostimulants, sequela',
  'T43606A Underdosing of unspecified psychostimulants, initial encounter',
  'T43606D Underdosing of unspecified psychostimulants, subsequent encounter',
  'T43606S Underdosing of unspecified psychostimulants, sequela',
  'T43611A Poisoning by caffeine, accidental (unintentional), initial encounter',
  'T43611D Poisoning by caffeine, accidental (unintentional), subsequent encounter',
  'T43611S Poisoning by caffeine, accidental (unintentional), sequela',
  'T43612A Poisoning by caffeine, intentional self-harm, initial encounter',
  'T43612D Poisoning by caffeine, intentional self-harm, subsequent encounter',
  'T43612S Poisoning by caffeine, intentional self-harm, sequela',
  'T43613A Poisoning by caffeine, assault, initial encounter',
  'T43613D Poisoning by caffeine, assault, subsequent encounter',
  'T43613S Poisoning by caffeine, assault, sequela',
  'T43614A Poisoning by caffeine, undetermined, initial encounter',
  'T43614D Poisoning by caffeine, undetermined, subsequent encounter',
  'T43614S Poisoning by caffeine, undetermined, sequela',
  'T43615A Adverse effect of caffeine, initial encounter',
  'T43615D Adverse effect of caffeine, subsequent encounter',
  'T43615S Adverse effect of caffeine, sequela',
  'T43616A Underdosing of caffeine, initial encounter',
  'T43616D Underdosing of caffeine, subsequent encounter',
  'T43616S Underdosing of caffeine, sequela',
  'T43621A Poisoning by amphetamines, accidental (unintentional), initial encounter',
  'T43621D Poisoning by amphetamines, accidental (unintentional), subsequent encounter',
  'T43621S Poisoning by amphetamines, accidental (unintentional), sequela',
  'T43622A Poisoning by amphetamines, intentional self-harm, initial encounter',
  'T43622D Poisoning by amphetamines, intentional self-harm, subsequent encounter',
  'T43622S Poisoning by amphetamines, intentional self-harm, sequela',
  'T43623A Poisoning by amphetamines, assault, initial encounter',
  'T43623D Poisoning by amphetamines, assault, subsequent encounter',
  'T43623S Poisoning by amphetamines, assault, sequela',
  'T43624A Poisoning by amphetamines, undetermined, initial encounter',
  'T43624D Poisoning by amphetamines, undetermined, subsequent encounter',
  'T43624S Poisoning by amphetamines, undetermined, sequela',
  'T43625A Adverse effect of amphetamines, initial encounter',
  'T43625D Adverse effect of amphetamines, subsequent encounter',
  'T43625S Adverse effect of amphetamines, sequela',
  'T43626A Underdosing of amphetamines, initial encounter',
  'T43626D Underdosing of amphetamines, subsequent encounter',
  'T43626S Underdosing of amphetamines, sequela',
  'T43631A Poisoning by methylphenidate, accidental (unintentional), initial encounter',
  'T43631D Poisoning by methylphenidate, accidental (unintentional), subsequent encounter',
  'T43631S Poisoning by methylphenidate, accidental (unintentional), sequela',
  'T43632A Poisoning by methylphenidate, intentional self-harm, initial encounter',
  'T43632D Poisoning by methylphenidate, intentional self-harm, subsequent encounter',
  'T43632S Poisoning by methylphenidate, intentional self-harm, sequela',
  'T43633A Poisoning by methylphenidate, assault, initial encounter',
  'T43633D Poisoning by methylphenidate, assault, subsequent encounter',
  'T43633S Poisoning by methylphenidate, assault, sequela',
  'T43634A Poisoning by methylphenidate, undetermined, initial encounter',
  'T43634D Poisoning by methylphenidate, undetermined, subsequent encounter',
  'T43634S Poisoning by methylphenidate, undetermined, sequela',
  'T43635A Adverse effect of methylphenidate, initial encounter',
  'T43635D Adverse effect of methylphenidate, subsequent encounter',
  'T43635S Adverse effect of methylphenidate, sequela',
  'T43636A Underdosing of methylphenidate, initial encounter',
  'T43636D Underdosing of methylphenidate, subsequent encounter',
  'T43636S Underdosing of methylphenidate, sequela',
  'T43691A Poisoning by other psychostimulants, accidental (unintentional), initial encounter',
  'T43691D Poisoning by other psychostimulants, accidental (unintentional), subsequent encounter',
  'T43691S Poisoning by other psychostimulants, accidental (unintentional), sequela',
  'T43692A Poisoning by other psychostimulants, intentional self-harm, initial encounter',
  'T43692D Poisoning by other psychostimulants, intentional self-harm, subsequent encounter',
  'T43692S Poisoning by other psychostimulants, intentional self-harm, sequela',
  'T43693A Poisoning by other psychostimulants, assault, initial encounter',
  'T43693D Poisoning by other psychostimulants, assault, subsequent encounter',
  'T43693S Poisoning by other psychostimulants, assault, sequela',
  'T43694A Poisoning by other psychostimulants, undetermined, initial encounter',
  'T43694D Poisoning by other psychostimulants, undetermined, subsequent encounter',
  'T43694S Poisoning by other psychostimulants, undetermined, sequela',
  'T43695A Adverse effect of other psychostimulants, initial encounter',
  'T43695D Adverse effect of other psychostimulants, subsequent encounter',
  'T43695S Adverse effect of other psychostimulants, sequela',
  'T43696A Underdosing of other psychostimulants, initial encounter',
  'T43696D Underdosing of other psychostimulants, subsequent encounter',
  'T43696S Underdosing of other psychostimulants, sequela',
  'T438X1A Poisoning by other psychotropic drugs, accidental (unintentional), initial encounter',
  'T438X1D Poisoning by other psychotropic drugs, accidental (unintentional), subsequent encounter',
  'T438X1S Poisoning by other psychotropic drugs, accidental (unintentional), sequela',
  'T438X2A Poisoning by other psychotropic drugs, intentional self-harm, initial encounter',
  'T438X2D Poisoning by other psychotropic drugs, intentional self-harm, subsequent encounter',
  'T438X2S Poisoning by other psychotropic drugs, intentional self-harm, sequela',
  'T438X3A Poisoning by other psychotropic drugs, assault, initial encounter',
  'T438X3D Poisoning by other psychotropic drugs, assault, subsequent encounter',
  'T438X3S Poisoning by other psychotropic drugs, assault, sequela',
  'T438X4A Poisoning by other psychotropic drugs, undetermined, initial encounter',
  'T438X4D Poisoning by other psychotropic drugs, undetermined, subsequent encounter',
  'T438X4S Poisoning by other psychotropic drugs, undetermined, sequela',
  'T438X5A Adverse effect of other psychotropic drugs, initial encounter',
  'T438X5D Adverse effect of other psychotropic drugs, subsequent encounter',
  'T438X5S Adverse effect of other psychotropic drugs, sequela',
  'T438X6A Underdosing of other psychotropic drugs, initial encounter',
  'T438X6D Underdosing of other psychotropic drugs, subsequent encounter',
  'T438X6S Underdosing of other psychotropic drugs, sequela',
  'T4391XA Poisoning by unspecified psychotropic drug, accidental (unintentional), initial encounter',
  'T4391XD Poisoning by unspecified psychotropic drug, accidental (unintentional), subsequent encounter',
  'T4391XS Poisoning by unspecified psychotropic drug, accidental (unintentional), sequela',
  'T4392XA Poisoning by unspecified psychotropic drug, intentional self-harm, initial encounter',
  'T4392XD Poisoning by unspecified psychotropic drug, intentional self-harm, subsequent encounter',
  'T4392XS Poisoning by unspecified psychotropic drug, intentional self-harm, sequela',
  'T4393XA Poisoning by unspecified psychotropic drug, assault, initial encounter',
  'T4393XD Poisoning by unspecified psychotropic drug, assault, subsequent encounter',
  'T4393XS Poisoning by unspecified psychotropic drug, assault, sequela',
  'T4394XA Poisoning by unspecified psychotropic drug, undetermined, initial encounter',
  'T4394XD Poisoning by unspecified psychotropic drug, undetermined, subsequent encounter',
  'T4394XS Poisoning by unspecified psychotropic drug, undetermined, sequela',
  'T4395XA Adverse effect of unspecified psychotropic drug, initial encounter',
  'T4395XD Adverse effect of unspecified psychotropic drug, subsequent encounter',
  'T4395XS Adverse effect of unspecified psychotropic drug, sequela',
  'T4396XA Underdosing of unspecified psychotropic drug, initial encounter',
  'T4396XD Underdosing of unspecified psychotropic drug, subsequent encounter',
  'T4396XS Underdosing of unspecified psychotropic drug, sequela',
  'T440X1A Poisoning by anticholinesterase agents, accidental (unintentional), initial encounter',
  'T440X1D Poisoning by anticholinesterase agents, accidental (unintentional), subsequent encounter',
  'T440X1S Poisoning by anticholinesterase agents, accidental (unintentional), sequela',
  'T440X2A Poisoning by anticholinesterase agents, intentional self-harm, initial encounter',
  'T440X2D Poisoning by anticholinesterase agents, intentional self-harm, subsequent encounter',
  'T440X2S Poisoning by anticholinesterase agents, intentional self-harm, sequela',
  'T440X3A Poisoning by anticholinesterase agents, assault, initial encounter',
  'T440X3D Poisoning by anticholinesterase agents, assault, subsequent encounter',
  'T440X3S Poisoning by anticholinesterase agents, assault, sequela',
  'T440X4A Poisoning by anticholinesterase agents, undetermined, initial encounter',
  'T440X4D Poisoning by anticholinesterase agents, undetermined, subsequent encounter',
  'T440X4S Poisoning by anticholinesterase agents, undetermined, sequela',
  'T440X5A Adverse effect of anticholinesterase agents, initial encounter',
  'T440X5D Adverse effect of anticholinesterase agents, subsequent encounter',
  'T440X5S Adverse effect of anticholinesterase agents, sequela',
  'T440X6A Underdosing of anticholinesterase agents, initial encounter',
  'T440X6D Underdosing of anticholinesterase agents, subsequent encounter',
  'T440X6S Underdosing of anticholinesterase agents, sequela',
  'T441X1A Poisoning by other parasympathomimetics [cholinergics], accidental (unintentional), initial',
  'encounter',
  'T441X1D Poisoning by other parasympathomimetics [cholinergics], accidental (unintentional),',
  'subsequent encounter',
  'T441X1S Poisoning by other parasympathomimetics [cholinergics], accidental (unintentional), sequela',
  'T441X2A Poisoning by other parasympathomimetics [cholinergics], intentional self-harm, initial',
  'encounter',
  'T441X2D Poisoning by other parasympathomimetics [cholinergics], intentional self-harm, subsequent',
  'encounter',
  'T441X2S Poisoning by other parasympathomimetics [cholinergics], intentional self-harm, sequela',
  'T441X3A Poisoning by other parasympathomimetics [cholinergics], assault, initial encounter',
  'T441X3D Poisoning by other parasympathomimetics [cholinergics], assault, subsequent encounter',
  'T441X3S Poisoning by other parasympathomimetics [cholinergics], assault, sequela',
  'T441X4A Poisoning by other parasympathomimetics [cholinergics], undetermined, initial encounter',
  'T441X4D Poisoning by other parasympathomimetics [cholinergics], undetermined, subsequent',
  'encounter',
  'T441X4S Poisoning by other parasympathomimetics [cholinergics], undetermined, sequela',
  'T441X5A Adverse effect of other parasympathomimetics [cholinergics], initial encounter',
  'T441X5D Adverse effect of other parasympathomimetics [cholinergics], subsequent encounter',
  'T441X5S Adverse effect of other parasympathomimetics [cholinergics], sequela',
  'T441X6A Underdosing of other parasympathomimetics, initial encounter',
  'T441X6D Underdosing of other parasympathomimetics, subsequent encounter',
  'T441X6S Underdosing of other parasympathomimetics, sequela',
  'T442X1A Poisoning by ganglionic blocking drugs, accidental (unintentional), initial encounter',
  'T442X1D Poisoning by ganglionic blocking drugs, accidental (unintentional), subsequent encounter',
  'T442X1S Poisoning by ganglionic blocking drugs, accidental (unintentional), sequela',
  'T442X2A Poisoning by ganglionic blocking drugs, intentional self-harm, initial encounter',
  'T442X2D Poisoning by ganglionic blocking drugs, intentional self-harm, subsequent encounter',
  'T442X2S Poisoning by ganglionic blocking drugs, intentional self-harm, sequela',
  'T442X3A Poisoning by ganglionic blocking drugs, assault, initial encounter',
  'T442X3D Poisoning by ganglionic blocking drugs, assault, subsequent encounter',
  'T442X3S Poisoning by ganglionic blocking drugs, assault, sequela',
  'T442X4A Poisoning by ganglionic blocking drugs, undetermined, initial encounter',
  'T442X4D Poisoning by ganglionic blocking drugs, undetermined, subsequent encounter',
  'T442X4S Poisoning by ganglionic blocking drugs, undetermined, sequela',
  'T442X5A Adverse effect of ganglionic blocking drugs, initial encounter',
  'T442X5D Adverse effect of ganglionic blocking drugs, subsequent encounter',
  'T442X5S Adverse effect of ganglionic blocking drugs, sequela',
  'T442X6A Underdosing of ganglionic blocking drugs, initial encounter',
  'T442X6D Underdosing of ganglionic blocking drugs, subsequent encounter',
  'T442X6S Underdosing of ganglionic blocking drugs, sequela',
  'T443X1A Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, accidental (unintentional), initial encounter',
  'T443X1D Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, accidental (unintentional), subsequent encounter',
  'T443X1S Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics,',
  'accidental (unintentional), sequela',
  'T443X2A Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, intentional self-harm, initial encounter',
  'T443X2D Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, intentional self-harm, subsequent encounter',
  'T443X2S Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics,',
  'intentional self-harm, sequela',
  'T443X3A Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, assault, initial encounter',
  'T443X3D Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, assault, subsequent encounter',
  'T443X3S Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics,',
  'assault, sequela',
  'T443X4A Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, undetermined, initial encounter',
  'T443X4D Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, undetermined, subsequent encounter',
  'T443X4S Poisoning by other parasympatholytics [anticholinergics and antimuscarinics] and spasmolytics,',
  'undetermined, sequela',
  'T443X5A Adverse effect of other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, initial encounter',
  'T443X5D Adverse effect of other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, subsequent encounter',
  'T443X5S Adverse effect of other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, sequela',
  'T443X6A Underdosing of other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, initial encounter',
  'T443X6D Underdosing of other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, subsequent encounter',
  'T443X6S Underdosing of other parasympatholytics [anticholinergics and antimuscarinics] and',
  'spasmolytics, sequela',
  'T444X1A Poisoning by predominantly alpha-adrenoreceptor agonists, accidental (unintentional), initial',
  'encounter',
  'T444X1D Poisoning by predominantly alpha-adrenoreceptor agonists, accidental (unintentional),',
  'subsequent encounter',
  'T444X1S Poisoning by predominantly alpha-adrenoreceptor agonists, accidental (unintentional), sequela',
  'T444X2A Poisoning by predominantly alpha-adrenoreceptor agonists, intentional self-harm, initial',
  'encounter',
  'T444X2D Poisoning by predominantly alpha-adrenoreceptor agonists, intentional self-harm, subsequent',
  'encounter',
  'T444X2S Poisoning by predominantly alpha-adrenoreceptor agonists, intentional self-harm, sequela',
  'T444X3A Poisoning by predominantly alpha-adrenoreceptor agonists, assault, initial encounter',
  'T444X3D Poisoning by predominantly alpha-adrenoreceptor agonists, assault, subsequent encounter',
  'T444X3S Poisoning by predominantly alpha-adrenoreceptor agonists, assault, sequela',
  'T444X4A Poisoning by predominantly alpha-adrenoreceptor agonists, undetermined, initial encounter',
  'T444X4D Poisoning by predominantly alpha-adrenoreceptor agonists, undetermined, subsequent',
  'encounter',
  'T444X4S Poisoning by predominantly alpha-adrenoreceptor agonists, undetermined, sequela',
  'T444X5A Adverse effect of predominantly alpha-adrenoreceptor agonists, initial encounter',
  'T444X5D Adverse effect of predominantly alpha-adrenoreceptor agonists, subsequent encounter',
  'T444X5S Adverse effect of predominantly alpha-adrenoreceptor agonists, sequela',
  'T444X6A Underdosing of predominantly alpha-adrenoreceptor agonists, initial encounter',
  'T444X6D Underdosing of predominantly alpha-adrenoreceptor agonists, subsequent encounter',
  'T444X6S Underdosing of predominantly alpha-adrenoreceptor agonists, sequela',
  'T445X1A Poisoning by predominantly beta-adrenoreceptor agonists, accidental (unintentional), initial',
  'encounter',
  'T445X1D Poisoning by predominantly beta-adrenoreceptor agonists, accidental (unintentional),',
  'subsequent encounter',
  'T445X1S Poisoning by predominantly beta-adrenoreceptor agonists, accidental (unintentional), sequela',
  'T445X2A Poisoning by predominantly beta-adrenoreceptor agonists, intentional self-harm, initial',
  'encounter',
  'T445X2D Poisoning by predominantly beta-adrenoreceptor agonists, intentional self-harm, subsequent',
  'encounter',
  'T445X2S Poisoning by predominantly beta-adrenoreceptor agonists, intentional self-harm, sequela',
  'T445X3A Poisoning by predominantly beta-adrenoreceptor agonists, assault, initial encounter',
  'T445X3D Poisoning by predominantly beta-adrenoreceptor agonists, assault, subsequent encounter',
  'T445X3S Poisoning by predominantly beta-adrenoreceptor agonists, assault, sequela',
  'T445X4A Poisoning by predominantly beta-adrenoreceptor agonists, undetermined, initial encounter',
  'T445X4D Poisoning by predominantly beta-adrenoreceptor agonists, undetermined, subsequent',
  'encounter',
  'T445X4S Poisoning by predominantly beta-adrenoreceptor agonists, undetermined, sequela',
  'T445X5A Adverse effect of predominantly beta-adrenoreceptor agonists, initial encounter',
  'T445X5D Adverse effect of predominantly beta-adrenoreceptor agonists, subsequent encounter',
  'T445X5S Adverse effect of predominantly beta-adrenoreceptor agonists, sequela',
  'T445X6A Underdosing of predominantly beta-adrenoreceptor agonists, initial encounter',
  'T445X6D Underdosing of predominantly beta-adrenoreceptor agonists, subsequent encounter',
  'T445X6S Underdosing of predominantly beta-adrenoreceptor agonists, sequela',
  'T446X1A Poisoning by alpha-adrenoreceptor antagonists, accidental (unintentional), initial encounter',
  'T446X1D Poisoning by alpha-adrenoreceptor antagonists, accidental (unintentional), subsequent',
  'encounter',
  'T446X1S Poisoning by alpha-adrenoreceptor antagonists, accidental (unintentional), sequela',
  'T446X2A Poisoning by alpha-adrenoreceptor antagonists, intentional self-harm, initial encounter',
  'T446X2D Poisoning by alpha-adrenoreceptor antagonists, intentional self-harm, subsequent encounter',
  'T446X2S Poisoning by alpha-adrenoreceptor antagonists, intentional self-harm, sequela',
  'T446X3A Poisoning by alpha-adrenoreceptor antagonists, assault, initial encounter',
  'T446X3D Poisoning by alpha-adrenoreceptor antagonists, assault, subsequent encounter',
  'T446X3S Poisoning by alpha-adrenoreceptor antagonists, assault, sequela',
  'T446X4A Poisoning by alpha-adrenoreceptor antagonists, undetermined, initial encounter',
  'T446X4D Poisoning by alpha-adrenoreceptor antagonists, undetermined, subsequent encounter',
  'T446X4S Poisoning by alpha-adrenoreceptor antagonists, undetermined, sequela',
  'T446X5A Adverse effect of alpha-adrenoreceptor antagonists, initial encounter',
  'T446X5D Adverse effect of alpha-adrenoreceptor antagonists, subsequent encounter',
  'T446X5S Adverse effect of alpha-adrenoreceptor antagonists, sequela',
  'T446X6A Underdosing of alpha-adrenoreceptor antagonists, initial encounter',
  'T446X6D Underdosing of alpha-adrenoreceptor antagonists, subsequent encounter',
  'T446X6S Underdosing of alpha-adrenoreceptor antagonists, sequela',
  'T447X1A Poisoning by beta-adrenoreceptor antagonists, accidental (unintentional), initial encounter',
  'T447X1D Poisoning by beta-adrenoreceptor antagonists, accidental (unintentional), subsequent',
  'encounter',
  'T447X1S Poisoning by beta-adrenoreceptor antagonists, accidental (unintentional), sequela',
  'T447X2A Poisoning by beta-adrenoreceptor antagonists, intentional self-harm, initial encounter',
  'T447X2D Poisoning by beta-adrenoreceptor antagonists, intentional self-harm, subsequent encounter',
  'T447X2S Poisoning by beta-adrenoreceptor antagonists, intentional self-harm, sequela',
  'T447X3A Poisoning by beta-adrenoreceptor antagonists, assault, initial encounter',
  'T447X3D Poisoning by beta-adrenoreceptor antagonists, assault, subsequent encounter',
  'T447X3S Poisoning by beta-adrenoreceptor antagonists, assault, sequela',
  'T447X4A Poisoning by beta-adrenoreceptor antagonists, undetermined, initial encounter',
  'T447X4D Poisoning by beta-adrenoreceptor antagonists, undetermined, subsequent encounter',
  'T447X4S Poisoning by beta-adrenoreceptor antagonists, undetermined, sequela',
  'T447X5A Adverse effect of beta-adrenoreceptor antagonists, initial encounter',
  'T447X5D Adverse effect of beta-adrenoreceptor antagonists, subsequent encounter',
  'T447X5S Adverse effect of beta-adrenoreceptor antagonists, sequela',
  'T447X6A Underdosing of beta-adrenoreceptor antagonists, initial encounter',
  'T447X6D Underdosing of beta-adrenoreceptor antagonists, subsequent encounter',
  'T447X6S Underdosing of beta-adrenoreceptor antagonists, sequela',
  'T448X1A Poisoning by centrally-acting and adrenergic-neuron-blocking agents, accidental',
  '(unintentional), initial encounter',
  'T448X1D Poisoning by centrally-acting and adrenergic-neuron-blocking agents, accidental',
  '(unintentional), subsequent encounter',
  'T448X1S Poisoning by centrally-acting and adrenergic-neuron-blocking agents, accidental',
  '(unintentional), sequela',
  'T448X2A Poisoning by centrally-acting and adrenergic-neuron-blocking agents, intentional self-harm,',
  'initial encounter',
  'T448X2D Poisoning by centrally-acting and adrenergic-neuron-blocking agents, intentional self-harm,',
  'subsequent encounter',
  'T448X2S Poisoning by centrally-acting and adrenergic-neuron-blocking agents, intentional self-harm,',
  'sequela',
  'T448X3A Poisoning by centrally-acting and adrenergic-neuron-blocking agents, assault, initial encounter',
  'T448X3D Poisoning by centrally-acting and adrenergic-neuron-blocking agents, assault, subsequent',
  'encounter',
  'T448X3S Poisoning by centrally-acting and adrenergic-neuron-blocking agents, assault, sequela',
  'T448X4A Poisoning by centrally-acting and adrenergic-neuron-blocking agents, undetermined, initial',
  'encounter',
  'T448X4D Poisoning by centrally-acting and adrenergic-neuron-blocking agents, undetermined,',
  'subsequent encounter',
  'T448X4S Poisoning by centrally-acting and adrenergic-neuron-blocking agents, undetermined, sequela',
  'T448X5A Adverse effect of centrally-acting and adrenergic-neuron-blocking agents, initial encounter',
  'T448X5D Adverse effect of centrally-acting and adrenergic-neuron-blocking agents, subsequent',
  'encounter',
  'T448X5S Adverse effect of centrally-acting and adrenergic-neuron-blocking agents, sequela',
  'T448X6A Underdosing of centrally-acting and adrenergic-neuron-blocking agents, initial encounter',
  'T448X6D Underdosing of centrally-acting and adrenergic-neuron-blocking agents, subsequent encounter',
  'T448X6S Underdosing of centrally-acting and adrenergic-neuron-blocking agents, sequela',
  'T44901A Poisoning by unspecified drugs primarily affecting the autonomic nervous system, accidental',
  '(unintentional), initial encounter',
  'T44901D Poisoning by unspecified drugs primarily affecting the autonomic nervous system, accidental',
  '(unintentional), subsequent encounter',
  'T44901S Poisoning by unspecified drugs primarily affecting the autonomic nervous system, accidental',
  '(unintentional), sequela',
  'T44902A Poisoning by unspecified drugs primarily affecting the autonomic nervous system, intentional',
  'self-harm, initial encounter',
  'T44902D Poisoning by unspecified drugs primarily affecting the autonomic nervous system, intentional',
  'self-harm, subsequent encounter',
  'T44902S Poisoning by unspecified drugs primarily affecting the autonomic nervous system, intentional',
  'self-harm, sequela',
  'T44903A Poisoning by unspecified drugs primarily affecting the autonomic nervous system, assault,',
  'initial encounter',
  'T44903D Poisoning by unspecified drugs primarily affecting the autonomic nervous system, assault,',
  'subsequent encounter',
  'T44903S Poisoning by unspecified drugs primarily affecting the autonomic nervous system, assault,',
  'sequela',
  'T44904A Poisoning by unspecified drugs primarily affecting the autonomic nervous system,',
  'undetermined, initial encounter',
  'T44904D Poisoning by unspecified drugs primarily affecting the autonomic nervous system,',
  'undetermined, subsequent encounter',
  'T44904S Poisoning by unspecified drugs primarily affecting the autonomic nervous system,',
  'undetermined, sequela',
  'T44905A Adverse effect of unspecified drugs primarily affecting the autonomic nervous system, initial',
  'encounter',
  'T44905D Adverse effect of unspecified drugs primarily affecting the autonomic nervous system,',
  'subsequent encounter',
  'T44905S Adverse effect of unspecified drugs primarily affecting the autonomic nervous system, sequela',
  'T44906A Underdosing of unspecified drugs primarily affecting the autonomic nervous system, initial',
  'encounter',
  'T44906D Underdosing of unspecified drugs primarily affecting the autonomic nervous system,',
  'subsequent encounter',
  'T44906S Underdosing of unspecified drugs primarily affecting the autonomic nervous system, sequela',
  'T44991A Poisoning by other drug primarily affecting the autonomic nervous system, accidental',
  '(unintentional), initial encounter',
  'T44991D Poisoning by other drug primarily affecting the autonomic nervous system, accidental',
  '(unintentional), subsequent encounter',
  'T44991S Poisoning by other drug primarily affecting the autonomic nervous system, accidental',
  '(unintentional), sequela',
  'T44992A Poisoning by other drug primarily affecting the autonomic nervous system, intentional selfharm, initial encounter',
  'T44992D Poisoning by other drug primarily affecting the autonomic nervous system, intentional selfharm, subsequent encounter',
  'T44992S Poisoning by other drug primarily affecting the autonomic nervous system, intentional selfharm, sequela',
  'T44993A Poisoning by other drug primarily affecting the autonomic nervous system, assault, initial',
  'encounter',
  'T44993D Poisoning by other drug primarily affecting the autonomic nervous system, assault, subsequent',
  'encounter',
  'T44993S Poisoning by other drug primarily affecting the autonomic nervous system, assault, sequela',
  'T44994A Poisoning by other drug primarily affecting the autonomic nervous system, undetermined,',
  'initial encounter',
  'T44994D Poisoning by other drug primarily affecting the autonomic nervous system, undetermined,',
  'subsequent encounter',
  'T44994S Poisoning by other drug primarily affecting the autonomic nervous system, undetermined,',
  'sequela',
  'T44995A Adverse effect of other drug primarily affecting the autonomic nervous system, initial',
  'encounter',
  'T44995D Adverse effect of other drug primarily affecting the autonomic nervous system, subsequent',
  'encounter',
  'T44995S Adverse effect of other drug primarily affecting the autonomic nervous system, sequela',
  'T44996A Underdosing of other drug primarily affecting the autonomic nervous system, initial encounter',
  'T44996D Underdosing of other drug primarily affecting the autonomic nervous system, subsequent',
  'encounter',
  'T44996S Underdosing of other drug primarily affecting the autonomic nervous system, sequela',
  'T450X1A Poisoning by antiallergic and antiemetic drugs, accidental (unintentional), initial encounter',
  'T450X1D Poisoning by antiallergic and antiemetic drugs, accidental (unintentional), subsequent',
  'encounter',
  'T450X1S Poisoning by antiallergic and antiemetic drugs, accidental (unintentional), sequela',
  'T450X2A Poisoning by antiallergic and antiemetic drugs, intentional self-harm, initial encounter',
  'T450X2D Poisoning by antiallergic and antiemetic drugs, intentional self-harm, subsequent encounter',
  'T450X2S Poisoning by antiallergic and antiemetic drugs, intentional self-harm, sequela',
  'T450X3A Poisoning by antiallergic and antiemetic drugs, assault, initial encounter',
  'T450X3D Poisoning by antiallergic and antiemetic drugs, assault, subsequent encounter',
  'T450X3S Poisoning by antiallergic and antiemetic drugs, assault, sequela',
  'T450X4A Poisoning by antiallergic and antiemetic drugs, undetermined, initial encounter',
  'T450X4D Poisoning by antiallergic and antiemetic drugs, undetermined, subsequent encounter',
  'T450X4S Poisoning by antiallergic and antiemetic drugs, undetermined, sequela',
  'T450X5A Adverse effect of antiallergic and antiemetic drugs, initial encounter',
  'T450X5D Adverse effect of antiallergic and antiemetic drugs, subsequent encounter',
  'T450X5S Adverse effect of antiallergic and antiemetic drugs, sequela',
  'T450X6A Underdosing of antiallergic and antiemetic drugs, initial encounter',
  'T450X6D Underdosing of antiallergic and antiemetic drugs, subsequent encounter',
  'T450X6S Underdosing of antiallergic and antiemetic drugs, sequela',
  'T451X1A Poisoning by antineoplastic and immunosuppressive drugs, accidental (unintentional), initial',
  'encounter',
  'T451X1D Poisoning by antineoplastic and immunosuppressive drugs, accidental (unintentional),',
  'subsequent encounter',
  'T451X1S Poisoning by antineoplastic and immunosuppressive drugs, accidental (unintentional), sequela',
  'T451X2A Poisoning by antineoplastic and immunosuppressive drugs, intentional self-harm, initial',
  'encounter',
  'T451X2D Poisoning by antineoplastic and immunosuppressive drugs, intentional self-harm, subsequent',
  'encounter',
  'T451X2S Poisoning by antineoplastic and immunosuppressive drugs, intentional self-harm, sequela',
  'T451X3A Poisoning by antineoplastic and immunosuppressive drugs, assault, initial encounter',
  'T451X3D Poisoning by antineoplastic and immunosuppressive drugs, assault, subsequent encounter',
  'T451X3S Poisoning by antineoplastic and immunosuppressive drugs, assault, sequela',
  'T451X4A Poisoning by antineoplastic and immunosuppressive drugs, undetermined, initial encounter',
  'T451X4D Poisoning by antineoplastic and immunosuppressive drugs, undetermined, subsequent',
  'encounter',
  'T451X4S Poisoning by antineoplastic and immunosuppressive drugs, undetermined, sequela',
  'T451X5A Adverse effect of antineoplastic and immunosuppressive drugs, initial encounter',
  'T451X5D Adverse effect of antineoplastic and immunosuppressive drugs, subsequent encounter',
  'T451X5S Adverse effect of antineoplastic and immunosuppressive drugs, sequela',
  'T451X6A Underdosing of antineoplastic and immunosuppressive drugs, initial encounter',
  'T451X6D Underdosing of antineoplastic and immunosuppressive drugs, subsequent encounter',
  'T451X6S Underdosing of antineoplastic and immunosuppressive drugs, sequela',
  'T452X1A Poisoning by vitamins, accidental (unintentional), initial encounter',
  'T452X1D Poisoning by vitamins, accidental (unintentional), subsequent encounter',
  'T452X1S Poisoning by vitamins, accidental (unintentional), sequela',
  'T452X2A Poisoning by vitamins, intentional self-harm, initial encounter',
  'T452X2D Poisoning by vitamins, intentional self-harm, subsequent encounter',
  'T452X2S Poisoning by vitamins, intentional self-harm, sequela',
  'T452X3A Poisoning by vitamins, assault, initial encounter',
  'T452X3D Poisoning by vitamins, assault, subsequent encounter',
  'T452X3S Poisoning by vitamins, assault, sequela',
  'T452X4A Poisoning by vitamins, undetermined, initial encounter',
  'T452X4D Poisoning by vitamins, undetermined, subsequent encounter',
  'T452X4S Poisoning by vitamins, undetermined, sequela',
  'T452X5A Adverse effect of vitamins, initial encounter',
  'T452X5D Adverse effect of vitamins, subsequent encounter',
  'T452X5S Adverse effect of vitamins, sequela',
  'T452X6A Underdosing of vitamins, initial encounter',
  'T452X6D Underdosing of vitamins, subsequent encounter',
  'T452X6S Underdosing of vitamins, sequela',
  'T453X1A Poisoning by enzymes, accidental (unintentional), initial encounter',
  'T453X1D Poisoning by enzymes, accidental (unintentional), subsequent encounter',
  'T453X1S Poisoning by enzymes, accidental (unintentional), sequela',
  'T453X2A Poisoning by enzymes, intentional self-harm, initial encounter',
  'T453X2D Poisoning by enzymes, intentional self-harm, subsequent encounter',
  'T453X2S Poisoning by enzymes, intentional self-harm, sequela',
  'T453X3A Poisoning by enzymes, assault, initial encounter',
  'T453X3D Poisoning by enzymes, assault, subsequent encounter',
  'T453X3S Poisoning by enzymes, assault, sequela',
  'T453X4A Poisoning by enzymes, undetermined, initial encounter',
  'T453X4D Poisoning by enzymes, undetermined, subsequent encounter',
  'T453X4S Poisoning by enzymes, undetermined, sequela',
  'T453X5A Adverse effect of enzymes, initial encounter',
  'T453X5D Adverse effect of enzymes, subsequent encounter',
  'T453X5S Adverse effect of enzymes, sequela',
  'T453X6A Underdosing of enzymes, initial encounter',
  'T453X6D Underdosing of enzymes, subsequent encounter',
  'T453X6S Underdosing of enzymes, sequela',
  'T454X1A Poisoning by iron and its compounds, accidental (unintentional), initial encounter',
  'T454X1D Poisoning by iron and its compounds, accidental (unintentional), subsequent encounter',
  'T454X1S Poisoning by iron and its compounds, accidental (unintentional), sequela',
  'T454X2A Poisoning by iron and its compounds, intentional self-harm, initial encounter',
  'T454X2D Poisoning by iron and its compounds, intentional self-harm, subsequent encounter',
  'T454X2S Poisoning by iron and its compounds, intentional self-harm, sequela',
  'T454X3A Poisoning by iron and its compounds, assault, initial encounter',
  'T454X3D Poisoning by iron and its compounds, assault, subsequent encounter',
  'T454X3S Poisoning by iron and its compounds, assault, sequela',
  'T454X4A Poisoning by iron and its compounds, undetermined, initial encounter',
  'T454X4D Poisoning by iron and its compounds, undetermined, subsequent encounter',
  'T454X4S Poisoning by iron and its compounds, undetermined, sequela',
  'T454X5A Adverse effect of iron and its compounds, initial encounter',
  'T454X5D Adverse effect of iron and its compounds, subsequent encounter',
  'T454X5S Adverse effect of iron and its compounds, sequela',
  'T454X6A Underdosing of iron and its compounds, initial encounter',
  'T454X6D Underdosing of iron and its compounds, subsequent encounter',
  'T454X6S Underdosing of iron and its compounds, sequela',
  'T45511A Poisoning by anticoagulants, accidental (unintentional), initial encounter',
  'T45511D Poisoning by anticoagulants, accidental (unintentional), subsequent encounter',
  'T45511S Poisoning by anticoagulants, accidental (unintentional), sequela',
  'T45512A Poisoning by anticoagulants, intentional self-harm, initial encounter',
  'T45512D Poisoning by anticoagulants, intentional self-harm, subsequent encounter',
  'T45512S Poisoning by anticoagulants, intentional self-harm, sequela',
  'T45513A Poisoning by anticoagulants, assault, initial encounter',
  'T45513D Poisoning by anticoagulants, assault, subsequent encounter',
  'T45513S Poisoning by anticoagulants, assault, sequela',
  'T45514A Poisoning by anticoagulants, undetermined, initial encounter',
  'T45514D Poisoning by anticoagulants, undetermined, subsequent encounter',
  'T45514S Poisoning by anticoagulants, undetermined, sequela',
  'T45515A Adverse effect of anticoagulants, initial encounter',
  'T45515D Adverse effect of anticoagulants, subsequent encounter',
  'T45515S Adverse effect of anticoagulants, sequela',
  'T45516A Underdosing of anticoagulants, initial encounter',
  'T45516D Underdosing of anticoagulants, subsequent encounter',
  'T45516S Underdosing of anticoagulants, sequela',
  'T45521A Poisoning by antithrombotic drugs, accidental (unintentional), initial encounter',
  'T45521D Poisoning by antithrombotic drugs, accidental (unintentional), subsequent encounter',
  'T45521S Poisoning by antithrombotic drugs, accidental (unintentional), sequela',
  'T45522A Poisoning by antithrombotic drugs, intentional self-harm, initial encounter',
  'T45522D Poisoning by antithrombotic drugs, intentional self-harm, subsequent encounter',
  'T45522S Poisoning by antithrombotic drugs, intentional self-harm, sequela',
  'T45523A Poisoning by antithrombotic drugs, assault, initial encounter',
  'T45523D Poisoning by antithrombotic drugs, assault, subsequent encounter',
  'T45523S Poisoning by antithrombotic drugs, assault, sequela',
  'T45524A Poisoning by antithrombotic drugs, undetermined, initial encounter',
  'T45524D Poisoning by antithrombotic drugs, undetermined, subsequent encounter',
  'T45524S Poisoning by antithrombotic drugs, undetermined, sequela',
  'T45525A Adverse effect of antithrombotic drugs, initial encounter',
  'T45525D Adverse effect of antithrombotic drugs, subsequent encounter',
  'T45525S Adverse effect of antithrombotic drugs, sequela',
  'T45526A Underdosing of antithrombotic drugs, initial encounter',
  'T45526D Underdosing of antithrombotic drugs, subsequent encounter',
  'T45526S Underdosing of antithrombotic drugs, sequela',
  'T45601A Poisoning by unspecified fibrinolysis-affecting drugs, accidental (unintentional), initial',
  'encounter',
  'T45601D Poisoning by unspecified fibrinolysis-affecting drugs, accidental (unintentional), subsequent',
  'encounter',
  'T45601S Poisoning by unspecified fibrinolysis-affecting drugs, accidental (unintentional), sequela',
  'T45602A Poisoning by unspecified fibrinolysis-affecting drugs, intentional self-harm, initial encounter',
  'T45602D Poisoning by unspecified fibrinolysis-affecting drugs, intentional self-harm, subsequent',
  'encounter',
  'T45602S Poisoning by unspecified fibrinolysis-affecting drugs, intentional self-harm, sequela',
  'T45603A Poisoning by unspecified fibrinolysis-affecting drugs, assault, initial encounter',
  'T45603D Poisoning by unspecified fibrinolysis-affecting drugs, assault, subsequent encounter',
  'T45603S Poisoning by unspecified fibrinolysis-affecting drugs, assault, sequela',
  'T45604A Poisoning by unspecified fibrinolysis-affecting drugs, undetermined, initial encounter',
  'T45604D Poisoning by unspecified fibrinolysis-affecting drugs, undetermined, subsequent encounter',
  'T45604S Poisoning by unspecified fibrinolysis-affecting drugs, undetermined, sequela',
  'T45605A Adverse effect of unspecified fibrinolysis-affecting drugs, initial encounter',
  'T45605D Adverse effect of unspecified fibrinolysis-affecting drugs, subsequent encounter',
  'T45605S Adverse effect of unspecified fibrinolysis-affecting drugs, sequela',
  'T45606A Underdosing of unspecified fibrinolysis-affecting drugs, initial encounter',
  'T45606D Underdosing of unspecified fibrinolysis-affecting drugs, subsequent encounter',
  'T45606S Underdosing of unspecified fibrinolysis-affecting drugs, sequela',
  'T45611A Poisoning by thrombolytic drug, accidental (unintentional), initial encounter',
  'T45611D Poisoning by thrombolytic drug, accidental (unintentional), subsequent encounter',
  'T45611S Poisoning by thrombolytic drug, accidental (unintentional), sequela',
  'T45612A Poisoning by thrombolytic drug, intentional self-harm, initial encounter',
  'T45612D Poisoning by thrombolytic drug, intentional self-harm, subsequent encounter',
  'T45612S Poisoning by thrombolytic drug, intentional self-harm, sequela',
  'T45613A Poisoning by thrombolytic drug, assault, initial encounter',
  'T45613D Poisoning by thrombolytic drug, assault, subsequent encounter',
  'T45613S Poisoning by thrombolytic drug, assault, sequela',
  'T45614A Poisoning by thrombolytic drug, undetermined, initial encounter',
  'T45614D Poisoning by thrombolytic drug, undetermined, subsequent encounter',
  'T45614S Poisoning by thrombolytic drug, undetermined, sequela',
  'T45615A Adverse effect of thrombolytic drugs, initial encounter',
  'T45615D Adverse effect of thrombolytic drugs, subsequent encounter',
  'T45615S Adverse effect of thrombolytic drugs, sequela',
  'T45616A Underdosing of thrombolytic drugs, initial encounter',
  'T45616D Underdosing of thrombolytic drugs, subsequent encounter',
  'T45616S Underdosing of thrombolytic drugs, sequela',
  'T45621A Poisoning by hemostatic drug, accidental (unintentional), initial encounter',
  'T45621D Poisoning by hemostatic drug, accidental (unintentional), subsequent encounter',
  'T45621S Poisoning by hemostatic drug, accidental (unintentional), sequela',
  'T45622A Poisoning by hemostatic drug, intentional self-harm, initial encounter',
  'T45622D Poisoning by hemostatic drug, intentional self-harm, subsequent encounter',
  'T45622S Poisoning by hemostatic drug, intentional self-harm, sequela',
  'T45623A Poisoning by hemostatic drug, assault, initial encounter',
  'T45623D Poisoning by hemostatic drug, assault, subsequent encounter',
  'T45623S Poisoning by hemostatic drug, assault, sequela',
  'T45624A Poisoning by hemostatic drug, undetermined, initial encounter',
  'T45624D Poisoning by hemostatic drug, undetermined, subsequent encounter',
  'T45624S Poisoning by hemostatic drug, undetermined, sequela',
  'T45625A Adverse effect of hemostatic drug, initial encounter',
  'T45625D Adverse effect of hemostatic drug, subsequent encounter',
  'T45625S Adverse effect of hemostatic drug, sequela',
  'T45626A Underdosing of hemostatic drugs, initial encounter',
  'T45626D Underdosing of hemostatic drugs, subsequent encounter',
  'T45626S Underdosing of hemostatic drugs, sequela',
  'T45691A Poisoning by other fibrinolysis-affecting drugs, accidental (unintentional), initial encounter',
  'T45691D Poisoning by other fibrinolysis-affecting drugs, accidental (unintentional), subsequent',
  'encounter',
  'T45691S Poisoning by other fibrinolysis-affecting drugs, accidental (unintentional), sequela',
  'T45692A Poisoning by other fibrinolysis-affecting drugs, intentional self-harm, initial encounter',
  'T45692D Poisoning by other fibrinolysis-affecting drugs, intentional self-harm, subsequent encounter',
  'T45692S Poisoning by other fibrinolysis-affecting drugs, intentional self-harm, sequela',
  'T45693A Poisoning by other fibrinolysis-affecting drugs, assault, initial encounter',
  'T45693D Poisoning by other fibrinolysis-affecting drugs, assault, subsequent encounter',
  'T45693S Poisoning by other fibrinolysis-affecting drugs, assault, sequela',
  'T45694A Poisoning by other fibrinolysis-affecting drugs, undetermined, initial encounter',
  'T45694D Poisoning by other fibrinolysis-affecting drugs, undetermined, subsequent encounter',
  'T45694S Poisoning by other fibrinolysis-affecting drugs, undetermined, sequela',
  'T45695A Adverse effect of other fibrinolysis-affecting drugs, initial encounter',
  'T45695D Adverse effect of other fibrinolysis-affecting drugs, subsequent encounter',
  'T45695S Adverse effect of other fibrinolysis-affecting drugs, sequela',
  'T45696A Underdosing of other fibrinolysis-affecting drugs, initial encounter',
  'T45696D Underdosing of other fibrinolysis-affecting drugs, subsequent encounter',
  'T45696S Underdosing of other fibrinolysis-affecting drugs, sequela',
  'T457X1A Poisoning by anticoagulant antagonists, vitamin K and other coagulants, accidental',
  '(unintentional), initial encounter',
  'T457X1D Poisoning by anticoagulant antagonists, vitamin K and other coagulants, accidental',
  '(unintentional), subsequent encounter',
  'T457X1S Poisoning by anticoagulant antagonists, vitamin K and other coagulants, accidental',
  '(unintentional), sequela',
  'T457X2A Poisoning by anticoagulant antagonists, vitamin K and other coagulants, intentional self-harm,',
  'initial encounter',
  'T457X2D Poisoning by anticoagulant antagonists, vitamin K and other coagulants, intentional self-harm,',
  'subsequent encounter',
  'T457X2S Poisoning by anticoagulant antagonists, vitamin K and other coagulants, intentional self-harm,',
  'sequela',
  'T457X3A Poisoning by anticoagulant antagonists, vitamin K and other coagulants, assault, initial',
  'encounter',
  'T457X3D Poisoning by anticoagulant antagonists, vitamin K and other coagulants, assault, subsequent',
  'encounter',
  'T457X3S Poisoning by anticoagulant antagonists, vitamin K and other coagulants, assault, sequela',
  'T457X4A Poisoning by anticoagulant antagonists, vitamin K and other coagulants, undetermined, initial',
  'encounter',
  'T457X4D Poisoning by anticoagulant antagonists, vitamin K and other coagulants, undetermined,',
  'subsequent encounter',
  'T457X4S Poisoning by anticoagulant antagonists, vitamin K and other coagulants, undetermined,',
  'sequela',
  'T457X5A Adverse effect of anticoagulant antagonists, vitamin K and other coagulants, initial encounter',
  'T457X5D Adverse effect of anticoagulant antagonists, vitamin K and other coagulants, subsequent',
  'encounter',
  'T457X5S Adverse effect of anticoagulant antagonists, vitamin K and other coagulants, sequela',
  'T457X6A Underdosing of anticoagulant antagonist, vitamin K and other coagulants, initial encounter',
  'T457X6D Underdosing of anticoagulant antagonist, vitamin K and other coagulants, subsequent',
  'encounter',
  'T457X6S Underdosing of anticoagulant antagonist, vitamin K and other coagulants, sequela',
  'T458X1A Poisoning by other primarily systemic and hematological agents, accidental (unintentional),',
  'initial encounter',
  'T458X1D Poisoning by other primarily systemic and hematological agents, accidental (unintentional),',
  'subsequent encounter',
  'T458X1S Poisoning by other primarily systemic and hematological agents, accidental (unintentional),',
  'sequela',
  'T458X2A Poisoning by other primarily systemic and hematological agents, intentional self-harm, initial',
  'encounter',
  'T458X2D Poisoning by other primarily systemic and hematological agents, intentional self-harm,',
  'subsequent encounter',
  'T458X2S Poisoning by other primarily systemic and hematological agents, intentional self-harm, sequela',
  'T458X3A Poisoning by other primarily systemic and hematological agents, assault, initial encounter',
  'T458X3D Poisoning by other primarily systemic and hematological agents, assault, subsequent',
  'encounter',
  'T458X3S Poisoning by other primarily systemic and hematological agents, assault, sequela',
  'T458X4A Poisoning by other primarily systemic and hematological agents, undetermined, initial',
  'encounter',
  'T458X4D Poisoning by other primarily systemic and hematological agents, undetermined, subsequent',
  'encounter',
  'T458X4S Poisoning by other primarily systemic and hematological agents, undetermined, sequela',
  'T458X5A Adverse effect of other primarily systemic and hematological agents, initial encounter',
  'T458X5D Adverse effect of other primarily systemic and hematological agents, subsequent encounter',
  'T458X5S Adverse effect of other primarily systemic and hematological agents, sequela',
  'T458X6A Underdosing of other primarily systemic and hematological agents, initial encounter',
  'T458X6D Underdosing of other primarily systemic and hematological agents, subsequent encounter',
  'T458X6S Underdosing of other primarily systemic and hematological agents, sequela',
  'T4591XA Poisoning by unspecified primarily systemic and hematological agent, accidental',
  '(unintentional), initial encounter',
  'T4591XD Poisoning by unspecified primarily systemic and hematological agent, accidental',
  '(unintentional), subsequent encounter',
  'T4591XS Poisoning by unspecified primarily systemic and hematological agent, accidental',
  '(unintentional), sequela',
  'T4592XA Poisoning by unspecified primarily systemic and hematological agent, intentional self-harm,',
  'initial encounter',
  'T4592XD Poisoning by unspecified primarily systemic and hematological agent, intentional self-harm,',
  'subsequent encounter',
  'T4592XS Poisoning by unspecified primarily systemic and hematological agent, intentional self-harm,',
  'sequela',
  'T4593XA Poisoning by unspecified primarily systemic and hematological agent, assault, initial encounter',
  'T4593XD Poisoning by unspecified primarily systemic and hematological agent, assault, subsequent',
  'encounter',
  'T4593XS Poisoning by unspecified primarily systemic and hematological agent, assault, sequela',
  'T4594XA Poisoning by unspecified primarily systemic and hematological agent, undetermined, initial',
  'encounter',
  'T4594XD Poisoning by unspecified primarily systemic and hematological agent, undetermined,',
  'subsequent encounter',
  'T4594XS Poisoning by unspecified primarily systemic and hematological agent, undetermined, sequela',
  'T4595XA Adverse effect of unspecified primarily systemic and hematological agent, initial encounter',
  'T4595XD Adverse effect of unspecified primarily systemic and hematological agent, subsequent',
  'encounter',
  'T4595XS Adverse effect of unspecified primarily systemic and hematological agent, sequela',
  'T4596XA Underdosing of unspecified primarily systemic and hematological agent, initial encounter',
  'T4596XD Underdosing of unspecified primarily systemic and hematological agent, subsequent',
  'encounter',
  'T4596XS Underdosing of unspecified primarily systemic and hematological agent, sequela',
  'T460X1A Poisoning by cardiac-stimulant glycosides and drugs of similar action, accidental',
  '(unintentional), initial encounter',
  'T460X1D Poisoning by cardiac-stimulant glycosides and drugs of similar action, accidental',
  '(unintentional), subsequent encounter',
  'T460X1S Poisoning by cardiac-stimulant glycosides and drugs of similar action, accidental',
  '(unintentional), sequela',
  'T460X2A Poisoning by cardiac-stimulant glycosides and drugs of similar action, intentional self-harm,',
  'initial encounter',
  'T460X2D Poisoning by cardiac-stimulant glycosides and drugs of similar action, intentional self-harm,',
  'subsequent encounter',
  'T460X2S Poisoning by cardiac-stimulant glycosides and drugs of similar action, intentional self-harm,',
  'sequela',
  'T460X3A Poisoning by cardiac-stimulant glycosides and drugs of similar action, assault, initial encounter',
  'T460X3D Poisoning by cardiac-stimulant glycosides and drugs of similar action, assault, subsequent',
  'encounter',
  'T460X3S Poisoning by cardiac-stimulant glycosides and drugs of similar action, assault, sequela',
  'T460X4A Poisoning by cardiac-stimulant glycosides and drugs of similar action, undetermined, initial',
  'encounter',
  'T460X4D Poisoning by cardiac-stimulant glycosides and drugs of similar action, undetermined,',
  'subsequent encounter',
  'T460X4S Poisoning by cardiac-stimulant glycosides and drugs of similar action, undetermined, sequela',
  'T460X5A Adverse effect of cardiac-stimulant glycosides and drugs of similar action, initial encounter',
  'T460X5D Adverse effect of cardiac-stimulant glycosides and drugs of similar action, subsequent',
  'encounter',
  'T460X5S Adverse effect of cardiac-stimulant glycosides and drugs of similar action, sequela',
  'T460X6A Underdosing of cardiac-stimulant glycosides and drugs of similar action, initial encounter',
  'T460X6D Underdosing of cardiac-stimulant glycosides and drugs of similar action, subsequent encounter',
  'T460X6S Underdosing of cardiac-stimulant glycosides and drugs of similar action, sequela',
  'T461X1A Poisoning by calcium-channel blockers, accidental (unintentional), initial encounter',
  'T461X1D Poisoning by calcium-channel blockers, accidental (unintentional), subsequent encounter',
  'T461X1S Poisoning by calcium-channel blockers, accidental (unintentional), sequela',
  'T461X2A Poisoning by calcium-channel blockers, intentional self-harm, initial encounter',
  'T461X2D Poisoning by calcium-channel blockers, intentional self-harm, subsequent encounter',
  'T461X2S Poisoning by calcium-channel blockers, intentional self-harm, sequela',
  'T461X3A Poisoning by calcium-channel blockers, assault, initial encounter',
  'T461X3D Poisoning by calcium-channel blockers, assault, subsequent encounter',
  'T461X3S Poisoning by calcium-channel blockers, assault, sequela',
  'T461X4A Poisoning by calcium-channel blockers, undetermined, initial encounter',
  'T461X4D Poisoning by calcium-channel blockers, undetermined, subsequent encounter',
  'T461X4S Poisoning by calcium-channel blockers, undetermined, sequela',
  'T461X5A Adverse effect of calcium-channel blockers, initial encounter',
  'T461X5D Adverse effect of calcium-channel blockers, subsequent encounter',
  'T461X5S Adverse effect of calcium-channel blockers, sequela',
  'T461X6A Underdosing of calcium-channel blockers, initial encounter',
  'T461X6D Underdosing of calcium-channel blockers, subsequent encounter',
  'T461X6S Underdosing of calcium-channel blockers, sequela',
  'T462X1A Poisoning by other antidysrhythmic drugs, accidental (unintentional), initial encounter',
  'T462X1D Poisoning by other antidysrhythmic drugs, accidental (unintentional), subsequent encounter',
  'T462X1S Poisoning by other antidysrhythmic drugs, accidental (unintentional), sequela',
  'T462X2A Poisoning by other antidysrhythmic drugs, intentional self-harm, initial encounter',
  'T462X2D Poisoning by other antidysrhythmic drugs, intentional self-harm, subsequent encounter',
  'T462X2S Poisoning by other antidysrhythmic drugs, intentional self-harm, sequela',
  'T462X3A Poisoning by other antidysrhythmic drugs, assault, initial encounter',
  'T462X3D Poisoning by other antidysrhythmic drugs, assault, subsequent encounter',
  'T462X3S Poisoning by other antidysrhythmic drugs, assault, sequela',
  'T462X4A Poisoning by other antidysrhythmic drugs, undetermined, initial encounter',
  'T462X4D Poisoning by other antidysrhythmic drugs, undetermined, subsequent encounter',
  'T462X4S Poisoning by other antidysrhythmic drugs, undetermined, sequela',
  'T462X5A Adverse effect of other antidysrhythmic drugs, initial encounter',
  'T462X5D Adverse effect of other antidysrhythmic drugs, subsequent encounter',
  'T462X5S Adverse effect of other antidysrhythmic drugs, sequela',
  'T462X6A Underdosing of other antidysrhythmic drugs, initial encounter',
  'T462X6D Underdosing of other antidysrhythmic drugs, subsequent encounter',
  'T462X6S Underdosing of other antidysrhythmic drugs, sequela',
  'T463X1A Poisoning by coronary vasodilators, accidental (unintentional), initial encounter',
  'T463X1D Poisoning by coronary vasodilators, accidental (unintentional), subsequent encounter',
  'T463X1S Poisoning by coronary vasodilators, accidental (unintentional), sequela',
  'T463X2A Poisoning by coronary vasodilators, intentional self-harm, initial encounter',
  'T463X2D Poisoning by coronary vasodilators, intentional self-harm, subsequent encounter',
  'T463X2S Poisoning by coronary vasodilators, intentional self-harm, sequela',
  'T463X3A Poisoning by coronary vasodilators, assault, initial encounter',
  'T463X3D Poisoning by coronary vasodilators, assault, subsequent encounter',
  'T463X3S Poisoning by coronary vasodilators, assault, sequela',
  'T463X4A Poisoning by coronary vasodilators, undetermined, initial encounter',
  'T463X4D Poisoning by coronary vasodilators, undetermined, subsequent encounter',
  'T463X4S Poisoning by coronary vasodilators, undetermined, sequela',
  'T463X5A Adverse effect of coronary vasodilators, initial encounter',
  'T463X5D Adverse effect of coronary vasodilators, subsequent encounter',
  'T463X5S Adverse effect of coronary vasodilators, sequela',
  'T463X6A Underdosing of coronary vasodilators, initial encounter',
  'T463X6D Underdosing of coronary vasodilators, subsequent encounter',
  'T463X6S Underdosing of coronary vasodilators, sequela',
  'T464X1A Poisoning by angiotensin-converting-enzyme inhibitors, accidental (unintentional), initial',
  'encounter',
  'T464X1D Poisoning by angiotensin-converting-enzyme inhibitors, accidental (unintentional), subsequent',
  'encounter',
  'T464X1S Poisoning by angiotensin-converting-enzyme inhibitors, accidental (unintentional), sequela',
  'T464X2A Poisoning by angiotensin-converting-enzyme inhibitors, intentional self-harm, initial encounter',
  'T464X2D Poisoning by angiotensin-converting-enzyme inhibitors, intentional self-harm, subsequent',
  'encounter',
  'T464X2S Poisoning by angiotensin-converting-enzyme inhibitors, intentional self-harm, sequela',
  'T464X3A Poisoning by angiotensin-converting-enzyme inhibitors, assault, initial encounter',
  'T464X3D Poisoning by angiotensin-converting-enzyme inhibitors, assault, subsequent encounter',
  'T464X3S Poisoning by angiotensin-converting-enzyme inhibitors, assault, sequela',
  'T464X4A Poisoning by angiotensin-converting-enzyme inhibitors, undetermined, initial encounter',
  'T464X4D Poisoning by angiotensin-converting-enzyme inhibitors, undetermined, subsequent encounter',
  'T464X4S Poisoning by angiotensin-converting-enzyme inhibitors, undetermined, sequela',
  'T464X5A Adverse effect of angiotensin-converting-enzyme inhibitors, initial encounter',
  'T464X5D Adverse effect of angiotensin-converting-enzyme inhibitors, subsequent encounter',
  'T464X5S Adverse effect of angiotensin-converting-enzyme inhibitors, sequela',
  'T464X6A Underdosing of angiotensin-converting-enzyme inhibitors, initial encounter',
  'T464X6D Underdosing of angiotensin-converting-enzyme inhibitors, subsequent encounter',
  'T464X6S Underdosing of angiotensin-converting-enzyme inhibitors, sequela',
  'T465X1A Poisoning by other antihypertensive drugs, accidental (unintentional), initial encounter',
  'T465X1D Poisoning by other antihypertensive drugs, accidental (unintentional), subsequent encounter',
  'T465X1S Poisoning by other antihypertensive drugs, accidental (unintentional), sequela',
  'T465X2A Poisoning by other antihypertensive drugs, intentional self-harm, initial encounter',
  'T465X2D Poisoning by other antihypertensive drugs, intentional self-harm, subsequent encounter',
  'T465X2S Poisoning by other antihypertensive drugs, intentional self-harm, sequela',
  'T465X3A Poisoning by other antihypertensive drugs, assault, initial encounter',
  'T465X3D Poisoning by other antihypertensive drugs, assault, subsequent encounter',
  'T465X3S Poisoning by other antihypertensive drugs, assault, sequela',
  'T465X4A Poisoning by other antihypertensive drugs, undetermined, initial encounter',
  'T465X4D Poisoning by other antihypertensive drugs, undetermined, subsequent encounter',
  'T465X4S Poisoning by other antihypertensive drugs, undetermined, sequela',
  'T465X5A Adverse effect of other antihypertensive drugs, initial encounter',
  'T465X5D Adverse effect of other antihypertensive drugs, subsequent encounter',
  'T465X5S Adverse effect of other antihypertensive drugs, sequela',
  'T465X6A Underdosing of other antihypertensive drugs, initial encounter',
  'T465X6D Underdosing of other antihypertensive drugs, subsequent encounter',
  'T465X6S Underdosing of other antihypertensive drugs, sequela',
  'T466X1A Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, accidental (unintentional),',
  'initial encounter',
  'T466X1D Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, accidental (unintentional),',
  'subsequent encounter',
  'T466X1S Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, accidental (unintentional),',
  'sequela',
  'T466X2A Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, intentional self-harm, initial',
  'encounter',
  'T466X2D Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, intentional self-harm,',
  'subsequent encounter',
  'T466X2S Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, intentional self-harm, sequela',
  'T466X3A Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, assault, initial encounter',
  'T466X3D Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, assault, subsequent encounter',
  'T466X3S Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, assault, sequela',
  'T466X4A Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, undetermined, initial encounter',
  'T466X4D Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, undetermined, subsequent',
  'encounter',
  'T466X4S Poisoning by antihyperlipidemic and antiarteriosclerotic drugs, undetermined, sequela',
  'T466X5A Adverse effect of antihyperlipidemic and antiarteriosclerotic drugs, initial encounter',
  'T466X5D Adverse effect of antihyperlipidemic and antiarteriosclerotic drugs, subsequent encounter',
  'T466X5S Adverse effect of antihyperlipidemic and antiarteriosclerotic drugs, sequela',
  'T466X6A Underdosing of antihyperlipidemic and antiarteriosclerotic drugs, initial encounter',
  'T466X6D Underdosing of antihyperlipidemic and antiarteriosclerotic drugs, subsequent encounter',
  'T466X6S Underdosing of antihyperlipidemic and antiarteriosclerotic drugs, sequela',
  'T467X1A Poisoning by peripheral vasodilators, accidental (unintentional), initial encounter',
  'T467X1D Poisoning by peripheral vasodilators, accidental (unintentional), subsequent encounter',
  'T467X1S Poisoning by peripheral vasodilators, accidental (unintentional), sequela',
  'T467X2A Poisoning by peripheral vasodilators, intentional self-harm, initial encounter',
  'T467X2D Poisoning by peripheral vasodilators, intentional self-harm, subsequent encounter',
  'T467X2S Poisoning by peripheral vasodilators, intentional self-harm, sequela',
  'T467X3A Poisoning by peripheral vasodilators, assault, initial encounter',
  'T467X3D Poisoning by peripheral vasodilators, assault, subsequent encounter',
  'T467X3S Poisoning by peripheral vasodilators, assault, sequela',
  'T467X4A Poisoning by peripheral vasodilators, undetermined, initial encounter',
  'T467X4D Poisoning by peripheral vasodilators, undetermined, subsequent encounter',
  'T467X4S Poisoning by peripheral vasodilators, undetermined, sequela',
  'T467X5A Adverse effect of peripheral vasodilators, initial encounter',
  'T467X5D Adverse effect of peripheral vasodilators, subsequent encounter',
  'T467X5S Adverse effect of peripheral vasodilators, sequela',
  'T467X6A Underdosing of peripheral vasodilators, initial encounter',
  'T467X6D Underdosing of peripheral vasodilators, subsequent encounter',
  'T467X6S Underdosing of peripheral vasodilators, sequela',
  'T468X1A Poisoning by antivaricose drugs, including sclerosing agents, accidental (unintentional), initial',
  'encounter',
  'T468X1D Poisoning by antivaricose drugs, including sclerosing agents, accidental (unintentional),',
  'subsequent encounter',
  'T468X1S Poisoning by antivaricose drugs, including sclerosing agents, accidental (unintentional), sequela',
  'T468X2A Poisoning by antivaricose drugs, including sclerosing agents, intentional self-harm, initial',
  'encounter',
  'T468X2D Poisoning by antivaricose drugs, including sclerosing agents, intentional self-harm, subsequent',
  'encounter',
  'T468X2S Poisoning by antivaricose drugs, including sclerosing agents, intentional self-harm, sequela',
  'T468X3A Poisoning by antivaricose drugs, including sclerosing agents, assault, initial encounter',
  'T468X3D Poisoning by antivaricose drugs, including sclerosing agents, assault, subsequent encounter',
  'T468X3S Poisoning by antivaricose drugs, including sclerosing agents, assault, sequela',
  'T468X4A Poisoning by antivaricose drugs, including sclerosing agents, undetermined, initial encounter',
  'T468X4D Poisoning by antivaricose drugs, including sclerosing agents, undetermined, subsequent',
  'encounter',
  'T468X4S Poisoning by antivaricose drugs, including sclerosing agents, undetermined, sequela',
  'T468X5A Adverse effect of antivaricose drugs, including sclerosing agents, initial encounter',
  'T468X5D Adverse effect of antivaricose drugs, including sclerosing agents, subsequent encounter',
  'T468X5S Adverse effect of antivaricose drugs, including sclerosing agents, sequela',
  'T468X6A Underdosing of antivaricose drugs, including sclerosing agents, initial encounter',
  'T468X6D Underdosing of antivaricose drugs, including sclerosing agents, subsequent encounter',
  'T468X6S Underdosing of antivaricose drugs, including sclerosing agents, sequela',
  'T46901A Poisoning by unspecified agents primarily affecting the cardiovascular system, accidental',
  '(unintentional), initial encounter',
  'T46901D Poisoning by unspecified agents primarily affecting the cardiovascular system, accidental',
  '(unintentional), subsequent encounter',
  'T46901S Poisoning by unspecified agents primarily affecting the cardiovascular system, accidental',
  '(unintentional), sequela',
  'T46902A Poisoning by unspecified agents primarily affecting the cardiovascular system, intentional selfharm, initial encounter',
  'T46902D Poisoning by unspecified agents primarily affecting the cardiovascular system, intentional selfharm, subsequent encounter',
  'T46902S Poisoning by unspecified agents primarily affecting the cardiovascular system, intentional selfharm, sequela',
  'T46903A Poisoning by unspecified agents primarily affecting the cardiovascular system, assault, initial',
  'encounter',
  'T46903D Poisoning by unspecified agents primarily affecting the cardiovascular system, assault,',
  'subsequent encounter',
  'T46903S Poisoning by unspecified agents primarily affecting the cardiovascular system, assault, sequela',
  'T46904A Poisoning by unspecified agents primarily affecting the cardiovascular system, undetermined,',
  'initial encounter',
  'T46904D Poisoning by unspecified agents primarily affecting the cardiovascular system, undetermined,',
  'subsequent encounter',
  'T46904S Poisoning by unspecified agents primarily affecting the cardiovascular system, undetermined,',
  'sequela',
  'T46905A Adverse effect of unspecified agents primarily affecting the cardiovascular system, initial',
  'encounter',
  'T46905D Adverse effect of unspecified agents primarily affecting the cardiovascular system, subsequent',
  'encounter',
  'T46905S Adverse effect of unspecified agents primarily affecting the cardiovascular system, sequela',
  'T46906A Underdosing of unspecified agents primarily affecting the cardiovascular system, initial',
  'encounter',
  'T46906D Underdosing of unspecified agents primarily affecting the cardiovascular system, subsequent',
  'encounter',
  'T46906S Underdosing of unspecified agents primarily affecting the cardiovascular system, sequela',
  'T46991A Poisoning by other agents primarily affecting the cardiovascular system, accidental',
  '(unintentional), initial encounter',
  'T46991D Poisoning by other agents primarily affecting the cardiovascular system, accidental',
  '(unintentional), subsequent encounter',
  'T46991S Poisoning by other agents primarily affecting the cardiovascular system, accidental',
  '(unintentional), sequela',
  'T46992A Poisoning by other agents primarily affecting the cardiovascular system, intentional self-harm,',
  'initial encounter',
  'T46992D Poisoning by other agents primarily affecting the cardiovascular system, intentional self-harm,',
  'subsequent encounter',
  'T46992S Poisoning by other agents primarily affecting the cardiovascular system, intentional self-harm,',
  'sequela',
  'T46993A Poisoning by other agents primarily affecting the cardiovascular system, assault, initial',
  'encounter',
  'T46993D Poisoning by other agents primarily affecting the cardiovascular system, assault, subsequent',
  'encounter',
  'T46993S Poisoning by other agents primarily affecting the cardiovascular system, assault, sequela',
  'T46994A Poisoning by other agents primarily affecting the cardiovascular system, undetermined, initial',
  'encounter',
  'T46994D Poisoning by other agents primarily affecting the cardiovascular system, undetermined,',
  'subsequent encounter',
  'T46994S Poisoning by other agents primarily affecting the cardiovascular system, undetermined,',
  'sequela',
  'T46995A Adverse effect of other agents primarily affecting the cardiovascular system, initial encounter',
  'T46995D Adverse effect of other agents primarily affecting the cardiovascular system, subsequent',
  'encounter',
  'T46995S Adverse effect of other agents primarily affecting the cardiovascular system, sequela',
  'T46996A Underdosing of other agents primarily affecting the cardiovascular system, initial encounter',
  'T46996D Underdosing of other agents primarily affecting the cardiovascular system, subsequent',
  'encounter',
  'T46996S Underdosing of other agents primarily affecting the cardiovascular system, sequela',
  'T470X1A Poisoning by histamine H2-receptor blockers, accidental (unintentional), initial encounter',
  'T470X1D Poisoning by histamine H2-receptor blockers, accidental (unintentional), subsequent',
  'encounter',
  'T470X1S Poisoning by histamine H2-receptor blockers, accidental (unintentional), sequela',
  'T470X2A Poisoning by histamine H2-receptor blockers, intentional self-harm, initial encounter',
  'T470X2D Poisoning by histamine H2-receptor blockers, intentional self-harm, subsequent encounter',
  'T470X2S Poisoning by histamine H2-receptor blockers, intentional self-harm, sequela',
  'T470X3A Poisoning by histamine H2-receptor blockers, assault, initial encounter',
  'T470X3D Poisoning by histamine H2-receptor blockers, assault, subsequent encounter',
  'T470X3S Poisoning by histamine H2-receptor blockers, assault, sequela',
  'T470X4A Poisoning by histamine H2-receptor blockers, undetermined, initial encounter',
  'T470X4D Poisoning by histamine H2-receptor blockers, undetermined, subsequent encounter',
  'T470X4S Poisoning by histamine H2-receptor blockers, undetermined, sequela',
  'T470X5A Adverse effect of histamine H2-receptor blockers, initial encounter',
  'T470X5D Adverse effect of histamine H2-receptor blockers, subsequent encounter',
  'T470X5S Adverse effect of histamine H2-receptor blockers, sequela',
  'T470X6A Underdosing of histamine H2-receptor blockers, initial encounter',
  'T470X6D Underdosing of histamine H2-receptor blockers, subsequent encounter',
  'T470X6S Underdosing of histamine H2-receptor blockers, sequela',
  'T471X1A Poisoning by other antacids and anti-gastric-secretion drugs, accidental (unintentional), initial',
  'encounter',
  'T471X1D Poisoning by other antacids and anti-gastric-secretion drugs, accidental (unintentional),',
  'subsequent encounter',
  'T471X1S Poisoning by other antacids and anti-gastric-secretion drugs, accidental (unintentional),',
  'sequela',
  'T471X2A Poisoning by other antacids and anti-gastric-secretion drugs, intentional self-harm, initial',
  'encounter',
  'T471X2D Poisoning by other antacids and anti-gastric-secretion drugs, intentional self-harm, subsequent',
  'encounter',
  'T471X2S Poisoning by other antacids and anti-gastric-secretion drugs, intentional self-harm, sequela',
  'T471X3A Poisoning by other antacids and anti-gastric-secretion drugs, assault, initial encounter',
  'T471X3D Poisoning by other antacids and anti-gastric-secretion drugs, assault, subsequent encounter',
  'T471X3S Poisoning by other antacids and anti-gastric-secretion drugs, assault, sequela',
  'T471X4A Poisoning by other antacids and anti-gastric-secretion drugs, undetermined, initial encounter',
  'T471X4D Poisoning by other antacids and anti-gastric-secretion drugs, undetermined, subsequent',
  'encounter',
  'T471X4S Poisoning by other antacids and anti-gastric-secretion drugs, undetermined, sequela',
  'T471X5A Adverse effect of other antacids and anti-gastric-secretion drugs, initial encounter',
  'T471X5D Adverse effect of other antacids and anti-gastric-secretion drugs, subsequent encounter',
  'T471X5S Adverse effect of other antacids and anti-gastric-secretion drugs, sequela',
  'T471X6A Underdosing of other antacids and anti-gastric-secretion drugs, initial encounter',
  'T471X6D Underdosing of other antacids and anti-gastric-secretion drugs, subsequent encounter',
  'T471X6S Underdosing of other antacids and anti-gastric-secretion drugs, sequela',
  'T472X1A Poisoning by stimulant laxatives, accidental (unintentional), initial encounter',
  'T472X1D Poisoning by stimulant laxatives, accidental (unintentional), subsequent encounter',
  'T472X1S Poisoning by stimulant laxatives, accidental (unintentional), sequela',
  'T472X2A Poisoning by stimulant laxatives, intentional self-harm, initial encounter',
  'T472X2D Poisoning by stimulant laxatives, intentional self-harm, subsequent encounter',
  'T472X2S Poisoning by stimulant laxatives, intentional self-harm, sequela',
  'T472X3A Poisoning by stimulant laxatives, assault, initial encounter',
  'T472X3D Poisoning by stimulant laxatives, assault, subsequent encounter',
  'T472X3S Poisoning by stimulant laxatives, assault, sequela',
  'T472X4A Poisoning by stimulant laxatives, undetermined, initial encounter',
  'T472X4D Poisoning by stimulant laxatives, undetermined, subsequent encounter',
  'T472X4S Poisoning by stimulant laxatives, undetermined, sequela',
  'T472X5A Adverse effect of stimulant laxatives, initial encounter',
  'T472X5D Adverse effect of stimulant laxatives, subsequent encounter',
  'T472X5S Adverse effect of stimulant laxatives, sequela',
  'T472X6A Underdosing of stimulant laxatives, initial encounter',
  'T472X6D Underdosing of stimulant laxatives, subsequent encounter',
  'T472X6S Underdosing of stimulant laxatives, sequela',
  'T473X1A Poisoning by saline and osmotic laxatives, accidental (unintentional), initial encounter',
  'T473X1D Poisoning by saline and osmotic laxatives, accidental (unintentional), subsequent encounter',
  'T473X1S Poisoning by saline and osmotic laxatives, accidental (unintentional), sequela',
  'T473X2A Poisoning by saline and osmotic laxatives, intentional self-harm, initial encounter',
  'T473X2D Poisoning by saline and osmotic laxatives, intentional self-harm, subsequent encounter',
  'T473X2S Poisoning by saline and osmotic laxatives, intentional self-harm, sequela',
  'T473X3A Poisoning by saline and osmotic laxatives, assault, initial encounter',
  'T473X3D Poisoning by saline and osmotic laxatives, assault, subsequent encounter',
  'T473X3S Poisoning by saline and osmotic laxatives, assault, sequela',
  'T473X4A Poisoning by saline and osmotic laxatives, undetermined, initial encounter',
  'T473X4D Poisoning by saline and osmotic laxatives, undetermined, subsequent encounter',
  'T473X4S Poisoning by saline and osmotic laxatives, undetermined, sequela',
  'T473X5A Adverse effect of saline and osmotic laxatives, initial encounter',
  'T473X5D Adverse effect of saline and osmotic laxatives, subsequent encounter',
  'T473X5S Adverse effect of saline and osmotic laxatives, sequela',
  'T473X6A Underdosing of saline and osmotic laxatives, initial encounter',
  'T473X6D Underdosing of saline and osmotic laxatives, subsequent encounter',
  'T473X6S Underdosing of saline and osmotic laxatives, sequela',
  'T474X1A Poisoning by other laxatives, accidental (unintentional), initial encounter',
  'T474X1D Poisoning by other laxatives, accidental (unintentional), subsequent encounter',
  'T474X1S Poisoning by other laxatives, accidental (unintentional), sequela',
  'T474X2A Poisoning by other laxatives, intentional self-harm, initial encounter',
  'T474X2D Poisoning by other laxatives, intentional self-harm, subsequent encounter',
  'T474X2S Poisoning by other laxatives, intentional self-harm, sequela',
  'T474X3A Poisoning by other laxatives, assault, initial encounter',
  'T474X3D Poisoning by other laxatives, assault, subsequent encounter',
  'T474X3S Poisoning by other laxatives, assault, sequela',
  'T474X4A Poisoning by other laxatives, undetermined, initial encounter',
  'T474X4D Poisoning by other laxatives, undetermined, subsequent encounter',
  'T474X4S Poisoning by other laxatives, undetermined, sequela',
  'T474X5A Adverse effect of other laxatives, initial encounter',
  'T474X5D Adverse effect of other laxatives, subsequent encounter',
  'T474X5S Adverse effect of other laxatives, sequela',
  'T474X6A Underdosing of other laxatives, initial encounter',
  'T474X6D Underdosing of other laxatives, subsequent encounter',
  'T474X6S Underdosing of other laxatives, sequela',
  'T475X1A Poisoning by digestants, accidental (unintentional), initial encounter',
  'T475X1D Poisoning by digestants, accidental (unintentional), subsequent encounter',
  'T475X1S Poisoning by digestants, accidental (unintentional), sequela',
  'T475X2A Poisoning by digestants, intentional self-harm, initial encounter',
  'T475X2D Poisoning by digestants, intentional self-harm, subsequent encounter',
  'T475X2S Poisoning by digestants, intentional self-harm, sequela',
  'T475X3A Poisoning by digestants, assault, initial encounter',
  'T475X3D Poisoning by digestants, assault, subsequent encounter',
  'T475X3S Poisoning by digestants, assault, sequela',
  'T475X4A Poisoning by digestants, undetermined, initial encounter',
  'T475X4D Poisoning by digestants, undetermined, subsequent encounter',
  'T475X4S Poisoning by digestants, undetermined, sequela',
  'T475X5A Adverse effect of digestants, initial encounter',
  'T475X5D Adverse effect of digestants, subsequent encounter',
  'T475X5S Adverse effect of digestants, sequela',
  'T475X6A Underdosing of digestants, initial encounter',
  'T475X6D Underdosing of digestants, subsequent encounter',
  'T475X6S Underdosing of digestants, sequela',
  'T476X1A Poisoning by antidiarrheal drugs, accidental (unintentional), initial encounter',
  'T476X1D Poisoning by antidiarrheal drugs, accidental (unintentional), subsequent encounter',
  'T476X1S Poisoning by antidiarrheal drugs, accidental (unintentional), sequela',
  'T476X2A Poisoning by antidiarrheal drugs, intentional self-harm, initial encounter',
  'T476X2D Poisoning by antidiarrheal drugs, intentional self-harm, subsequent encounter',
  'T476X2S Poisoning by antidiarrheal drugs, intentional self-harm, sequela',
  'T476X3A Poisoning by antidiarrheal drugs, assault, initial encounter',
  'T476X3D Poisoning by antidiarrheal drugs, assault, subsequent encounter',
  'T476X3S Poisoning by antidiarrheal drugs, assault, sequela',
  'T476X4A Poisoning by antidiarrheal drugs, undetermined, initial encounter',
  'T476X4D Poisoning by antidiarrheal drugs, undetermined, subsequent encounter',
  'T476X4S Poisoning by antidiarrheal drugs, undetermined, sequela',
  'T476X5A Adverse effect of antidiarrheal drugs, initial encounter',
  'T476X5D Adverse effect of antidiarrheal drugs, subsequent encounter',
  'T476X5S Adverse effect of antidiarrheal drugs, sequela',
  'T476X6A Underdosing of antidiarrheal drugs, initial encounter',
  'T476X6D Underdosing of antidiarrheal drugs, subsequent encounter',
  'T476X6S Underdosing of antidiarrheal drugs, sequela',
  'T477X1A Poisoning by emetics, accidental (unintentional), initial encounter',
  'T477X1D Poisoning by emetics, accidental (unintentional), subsequent encounter',
  'T477X1S Poisoning by emetics, accidental (unintentional), sequela',
  'T477X2A Poisoning by emetics, intentional self-harm, initial encounter',
  'T477X2D Poisoning by emetics, intentional self-harm, subsequent encounter',
  'T477X2S Poisoning by emetics, intentional self-harm, sequela',
  'T477X3A Poisoning by emetics, assault, initial encounter',
  'T477X3D Poisoning by emetics, assault, subsequent encounter',
  'T477X3S Poisoning by emetics, assault, sequela',
  'T477X4A Poisoning by emetics, undetermined, initial encounter',
  'T477X4D Poisoning by emetics, undetermined, subsequent encounter',
  'T477X4S Poisoning by emetics, undetermined, sequela',
  'T477X5A Adverse effect of emetics, initial encounter',
  'T477X5D Adverse effect of emetics, subsequent encounter',
  'T477X5S Adverse effect of emetics, sequela',
  'T477X6A Underdosing of emetics, initial encounter',
  'T477X6D Underdosing of emetics, subsequent encounter',
  'T477X6S Underdosing of emetics, sequela',
  'T478X1A Poisoning by other agents primarily affecting gastrointestinal system, accidental',
  '(unintentional), initial encounter',
  'T478X1D Poisoning by other agents primarily affecting gastrointestinal system, accidental',
  '(unintentional), subsequent encounter',
  'T478X1S Poisoning by other agents primarily affecting gastrointestinal system, accidental',
  '(unintentional), sequela',
  'T478X2A Poisoning by other agents primarily affecting gastrointestinal system, intentional self-harm,',
  'initial encounter',
  'T478X2D Poisoning by other agents primarily affecting gastrointestinal system, intentional self-harm,',
  'subsequent encounter',
  'T478X2S Poisoning by other agents primarily affecting gastrointestinal system, intentional self-harm,',
  'sequela',
  'T478X3A Poisoning by other agents primarily affecting gastrointestinal system, assault, initial encounter',
  'T478X3D Poisoning by other agents primarily affecting gastrointestinal system, assault, subsequent',
  'encounter',
  'T478X3S Poisoning by other agents primarily affecting gastrointestinal system, assault, sequela',
  'T478X4A Poisoning by other agents primarily affecting gastrointestinal system, undetermined, initial',
  'encounter',
  'T478X4D Poisoning by other agents primarily affecting gastrointestinal system, undetermined,',
  'subsequent encounter',
  'T478X4S Poisoning by other agents primarily affecting gastrointestinal system, undetermined, sequela',
  'T478X5A Adverse effect of other agents primarily affecting gastrointestinal system, initial encounter',
  'T478X5D Adverse effect of other agents primarily affecting gastrointestinal system, subsequent',
  'encounter',
  'T478X5S Adverse effect of other agents primarily affecting gastrointestinal system, sequela',
  'T478X6A Underdosing of other agents primarily affecting gastrointestinal system, initial encounter',
  'T478X6D Underdosing of other agents primarily affecting gastrointestinal system, subsequent encounter',
  'T478X6S Underdosing of other agents primarily affecting gastrointestinal system, sequela',
  'T4791XA Poisoning by unspecified agents primarily affecting the gastrointestinal system, accidental',
  '(unintentional), initial encounter',
  'T4791XD Poisoning by unspecified agents primarily affecting the gastrointestinal system, accidental',
  '(unintentional), subsequent encounter',
  'T4791XS Poisoning by unspecified agents primarily affecting the gastrointestinal system, accidental',
  '(unintentional), sequela',
  'T4792XA Poisoning by unspecified agents primarily affecting the gastrointestinal system, intentional',
  'self-harm, initial encounter',
  'T4792XD Poisoning by unspecified agents primarily affecting the gastrointestinal system, intentional',
  'self-harm, subsequent encounter',
  'T4792XS Poisoning by unspecified agents primarily affecting the gastrointestinal system, intentional selfharm, sequela',
  'T4793XA Poisoning by unspecified agents primarily affecting the gastrointestinal system, assault, initial',
  'encounter',
  'T4793XD Poisoning by unspecified agents primarily affecting the gastrointestinal system, assault,',
  'subsequent encounter',
  'T4793XS Poisoning by unspecified agents primarily affecting the gastrointestinal system, assault, sequela',
  'T4794XA Poisoning by unspecified agents primarily affecting the gastrointestinal system, undetermined,',
  'initial encounter',
  'T4794XD Poisoning by unspecified agents primarily affecting the gastrointestinal system, undetermined,',
  'subsequent encounter',
  'T4794XS Poisoning by unspecified agents primarily affecting the gastrointestinal system, undetermined,',
  'sequela',
  'T4795XA Adverse effect of unspecified agents primarily affecting the gastrointestinal system, initial',
  'encounter',
  'T4795XD Adverse effect of unspecified agents primarily affecting the gastrointestinal system,',
  'subsequent encounter',
  'T4795XS Adverse effect of unspecified agents primarily affecting the gastrointestinal system, sequela',
  'T4796XA Underdosing of unspecified agents primarily affecting the gastrointestinal system, initial',
  'encounter',
  'T4796XD Underdosing of unspecified agents primarily affecting the gastrointestinal system, subsequent',
  'encounter',
  'T4796XS Underdosing of unspecified agents primarily affecting the gastrointestinal system, sequela',
  'T480X1A Poisoning by oxytocic drugs, accidental (unintentional), initial encounter',
  'T480X1D Poisoning by oxytocic drugs, accidental (unintentional), subsequent encounter',
  'T480X1S Poisoning by oxytocic drugs, accidental (unintentional), sequela',
  'T480X2A Poisoning by oxytocic drugs, intentional self-harm, initial encounter',
  'T480X2D Poisoning by oxytocic drugs, intentional self-harm, subsequent encounter',
  'T480X2S Poisoning by oxytocic drugs, intentional self-harm, sequela',
  'T480X3A Poisoning by oxytocic drugs, assault, initial encounter',
  'T480X3D Poisoning by oxytocic drugs, assault, subsequent encounter',
  'T480X3S Poisoning by oxytocic drugs, assault, sequela',
  'T480X4A Poisoning by oxytocic drugs, undetermined, initial encounter',
  'T480X4D Poisoning by oxytocic drugs, undetermined, subsequent encounter',
  'T480X4S Poisoning by oxytocic drugs, undetermined, sequela',
  'T480X5A Adverse effect of oxytocic drugs, initial encounter',
  'T480X5D Adverse effect of oxytocic drugs, subsequent encounter',
  'T480X5S Adverse effect of oxytocic drugs, sequela',
  'T480X6A Underdosing of oxytocic drugs, initial encounter',
  'T480X6D Underdosing of oxytocic drugs, subsequent encounter',
  'T480X6S Underdosing of oxytocic drugs, sequela',
  'T481X1A Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], accidental',
  '(unintentional), initial encounter',
  'T481X1D Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], accidental',
  '(unintentional), subsequent encounter',
  'T481X1S Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], accidental',
  '(unintentional), sequela',
  'T481X2A Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], intentional self-harm,',
  'initial encounter',
  'T481X2D Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], intentional self-harm,',
  'subsequent encounter',
  'T481X2S Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], intentional self-harm,',
  'sequela',
  'T481X3A Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], assault, initial',
  'encounter',
  'T481X3D Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], assault, subsequent',
  'encounter',
  'T481X3S Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], assault, sequela',
  'T481X4A Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], undetermined, initial',
  'encounter',
  'T481X4D Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], undetermined,',
  'subsequent encounter',
  'T481X4S Poisoning by skeletal muscle relaxants [neuromuscular blocking agents], undetermined,',
  'sequela',
  'T481X5A Adverse effect of skeletal muscle relaxants [neuromuscular blocking agents], initial encounter',
  'T481X5D Adverse effect of skeletal muscle relaxants [neuromuscular blocking agents], subsequent',
  'encounter',
  'T481X5S Adverse effect of skeletal muscle relaxants [neuromuscular blocking agents], sequela',
  'T481X6A Underdosing of skeletal muscle relaxants [neuromuscular blocking agents], initial encounter',
  'T481X6D Underdosing of skeletal muscle relaxants [neuromuscular blocking agents], subsequent',
  'encounter',
  'T481X6S Underdosing of skeletal muscle relaxants [neuromuscular blocking agents], sequela',
  'T48201A Poisoning by unspecified drugs acting on muscles, accidental (unintentional), initial encounter',
  'T48201D Poisoning by unspecified drugs acting on muscles, accidental (unintentional), subsequent',
  'encounter',
  'T48201S Poisoning by unspecified drugs acting on muscles, accidental (unintentional), sequela',
  'T48202A Poisoning by unspecified drugs acting on muscles, intentional self-harm, initial encounter',
  'T48202D Poisoning by unspecified drugs acting on muscles, intentional self-harm, subsequent encounter',
  'T48202S Poisoning by unspecified drugs acting on muscles, intentional self-harm, sequela',
  'T48203A Poisoning by unspecified drugs acting on muscles, assault, initial encounter',
  'T48203D Poisoning by unspecified drugs acting on muscles, assault, subsequent encounter',
  'T48203S Poisoning by unspecified drugs acting on muscles, assault, sequela',
  'T48204A Poisoning by unspecified drugs acting on muscles, undetermined, initial encounter',
  'T48204D Poisoning by unspecified drugs acting on muscles, undetermined, subsequent encounter',
  'T48204S Poisoning by unspecified drugs acting on muscles, undetermined, sequela',
  'T48205A Adverse effect of unspecified drugs acting on muscles, initial encounter',
  'T48205D Adverse effect of unspecified drugs acting on muscles, subsequent encounter',
  'T48205S Adverse effect of unspecified drugs acting on muscles, sequela',
  'T48206A Underdosing of unspecified drugs acting on muscles, initial encounter',
  'T48206D Underdosing of unspecified drugs acting on muscles, subsequent encounter',
  'T48206S Underdosing of unspecified drugs acting on muscles, sequela',
  'T48291A Poisoning by other drugs acting on muscles, accidental (unintentional), initial encounter',
  'T48291D Poisoning by other drugs acting on muscles, accidental (unintentional), subsequent encounter',
  'T48291S Poisoning by other drugs acting on muscles, accidental (unintentional), sequela',
  'T48292A Poisoning by other drugs acting on muscles, intentional self-harm, initial encounter',
  'T48292D Poisoning by other drugs acting on muscles, intentional self-harm, subsequent encounter',
  'T48292S Poisoning by other drugs acting on muscles, intentional self-harm, sequela',
  'T48293A Poisoning by other drugs acting on muscles, assault, initial encounter',
  'T48293D Poisoning by other drugs acting on muscles, assault, subsequent encounter',
  'T48293S Poisoning by other drugs acting on muscles, assault, sequela',
  'T48294A Poisoning by other drugs acting on muscles, undetermined, initial encounter',
  'T48294D Poisoning by other drugs acting on muscles, undetermined, subsequent encounter',
  'T48294S Poisoning by other drugs acting on muscles, undetermined, sequela',
  'T48295A Adverse effect of other drugs acting on muscles, initial encounter',
  'T48295D Adverse effect of other drugs acting on muscles, subsequent encounter',
  'T48295S Adverse effect of other drugs acting on muscles, sequela',
  'T48296A Underdosing of other drugs acting on muscles, initial encounter',
  'T48296D Underdosing of other drugs acting on muscles, subsequent encounter',
  'T48296S Underdosing of other drugs acting on muscles, sequela',
  'T483X1A Poisoning by antitussives, accidental (unintentional), initial encounter',
  'T483X1D Poisoning by antitussives, accidental (unintentional), subsequent encounter',
  'T483X1S Poisoning by antitussives, accidental (unintentional), sequela',
  'T483X2A Poisoning by antitussives, intentional self-harm, initial encounter',
  'T483X2D Poisoning by antitussives, intentional self-harm, subsequent encounter',
  'T483X2S Poisoning by antitussives, intentional self-harm, sequela',
  'T483X3A Poisoning by antitussives, assault, initial encounter',
  'T483X3D Poisoning by antitussives, assault, subsequent encounter',
  'T483X3S Poisoning by antitussives, assault, sequela',
  'T483X4A Poisoning by antitussives, undetermined, initial encounter',
  'T483X4D Poisoning by antitussives, undetermined, subsequent encounter',
  'T483X4S Poisoning by antitussives, undetermined, sequela',
  'T483X5A Adverse effect of antitussives, initial encounter',
  'T483X5D Adverse effect of antitussives, subsequent encounter',
  'T483X5S Adverse effect of antitussives, sequela',
  'T483X6A Underdosing of antitussives, initial encounter',
  'T483X6D Underdosing of antitussives, subsequent encounter',
  'T483X6S Underdosing of antitussives, sequela',
  'T484X1A Poisoning by expectorants, accidental (unintentional), initial encounter',
  'T484X1D Poisoning by expectorants, accidental (unintentional), subsequent encounter',
  'T484X1S Poisoning by expectorants, accidental (unintentional), sequela',
  'T484X2A Poisoning by expectorants, intentional self-harm, initial encounter',
  'T484X2D Poisoning by expectorants, intentional self-harm, subsequent encounter',
  'T484X2S Poisoning by expectorants, intentional self-harm, sequela',
  'T484X3A Poisoning by expectorants, assault, initial encounter',
  'T484X3D Poisoning by expectorants, assault, subsequent encounter',
  'T484X3S Poisoning by expectorants, assault, sequela',
  'T484X4A Poisoning by expectorants, undetermined, initial encounter',
  'T484X4D Poisoning by expectorants, undetermined, subsequent encounter',
  'T484X4S Poisoning by expectorants, undetermined, sequela',
  'T484X5A Adverse effect of expectorants, initial encounter',
  'T484X5D Adverse effect of expectorants, subsequent encounter',
  'T484X5S Adverse effect of expectorants, sequela',
  'T484X6A Underdosing of expectorants, initial encounter',
  'T484X6D Underdosing of expectorants, subsequent encounter',
  'T484X6S Underdosing of expectorants, sequela',
  'T485X1A Poisoning by other anti-common-cold drugs, accidental (unintentional), initial encounter',
  'T485X1D Poisoning by other anti-common-cold drugs, accidental (unintentional), subsequent encounter',
  'T485X1S Poisoning by other anti-common-cold drugs, accidental (unintentional), sequela',
  'T485X2A Poisoning by other anti-common-cold drugs, intentional self-harm, initial encounter',
  'T485X2D Poisoning by other anti-common-cold drugs, intentional self-harm, subsequent encounter',
  'T485X2S Poisoning by other anti-common-cold drugs, intentional self-harm, sequela',
  'T485X3A Poisoning by other anti-common-cold drugs, assault, initial encounter',
  'T485X3D Poisoning by other anti-common-cold drugs, assault, subsequent encounter',
  'T485X3S Poisoning by other anti-common-cold drugs, assault, sequela',
  'T485X4A Poisoning by other anti-common-cold drugs, undetermined, initial encounter',
  'T485X4D Poisoning by other anti-common-cold drugs, undetermined, subsequent encounter',
  'T485X4S Poisoning by other anti-common-cold drugs, undetermined, sequela',
  'T485X5A Adverse effect of other anti-common-cold drugs, initial encounter',
  'T485X5D Adverse effect of other anti-common-cold drugs, subsequent encounter',
  'T485X5S Adverse effect of other anti-common-cold drugs, sequela',
  'T485X6A Underdosing of other anti-common-cold drugs, initial encounter',
  'T485X6D Underdosing of other anti-common-cold drugs, subsequent encounter',
  'T485X6S Underdosing of other anti-common-cold drugs, sequela',
  'T486X1A Poisoning by antiasthmatics, accidental (unintentional), initial encounter',
  'T486X1D Poisoning by antiasthmatics, accidental (unintentional), subsequent encounter',
  'T486X1S Poisoning by antiasthmatics, accidental (unintentional), sequela',
  'T486X2A Poisoning by antiasthmatics, intentional self-harm, initial encounter',
  'T486X2D Poisoning by antiasthmatics, intentional self-harm, subsequent encounter',
  'T486X2S Poisoning by antiasthmatics, intentional self-harm, sequela',
  'T486X3A Poisoning by antiasthmatics, assault, initial encounter',
  'T486X3D Poisoning by antiasthmatics, assault, subsequent encounter',
  'T486X3S Poisoning by antiasthmatics, assault, sequela',
  'T486X4A Poisoning by antiasthmatics, undetermined, initial encounter',
  'T486X4D Poisoning by antiasthmatics, undetermined, subsequent encounter',
  'T486X4S Poisoning by antiasthmatics, undetermined, sequela',
  'T486X5A Adverse effect of antiasthmatics, initial encounter',
  'T486X5D Adverse effect of antiasthmatics, subsequent encounter',
  'T486X5S Adverse effect of antiasthmatics, sequela',
  'T486X6A Underdosing of antiasthmatics, initial encounter',
  'T486X6D Underdosing of antiasthmatics, subsequent encounter',
  'T486X6S Underdosing of antiasthmatics, sequela',
  'T48901A Poisoning by unspecified agents primarily acting on the respiratory system, accidental',
  '(unintentional), initial encounter',
  'T48901D Poisoning by unspecified agents primarily acting on the respiratory system, accidental',
  '(unintentional), subsequent encounter',
  'T48901S Poisoning by unspecified agents primarily acting on the respiratory system, accidental',
  '(unintentional), sequela',
  'T48902A Poisoning by unspecified agents primarily acting on the respiratory system, intentional selfharm, initial encounter',
  'T48902D Poisoning by unspecified agents primarily acting on the respiratory system, intentional selfharm, subsequent encounter',
  'T48902S Poisoning by unspecified agents primarily acting on the respiratory system, intentional selfharm, sequela',
  'T48903A Poisoning by unspecified agents primarily acting on the respiratory system, assault, initial',
  'encounter',
  'T48903D Poisoning by unspecified agents primarily acting on the respiratory system, assault,',
  'subsequent encounter',
  'T48903S Poisoning by unspecified agents primarily acting on the respiratory system, assault, sequela',
  'T48904A Poisoning by unspecified agents primarily acting on the respiratory system, undetermined,',
  'initial encounter',
  'T48904D Poisoning by unspecified agents primarily acting on the respiratory system, undetermined,',
  'subsequent encounter',
  'T48904S Poisoning by unspecified agents primarily acting on the respiratory system, undetermined,',
  'sequela',
  'T48905A Adverse effect of unspecified agents primarily acting on the respiratory system, initial',
  'encounter',
  'T48905D Adverse effect of unspecified agents primarily acting on the respiratory system, subsequent',
  'encounter',
  'T48905S Adverse effect of unspecified agents primarily acting on the respiratory system, sequela',
  'T48906A Underdosing of unspecified agents primarily acting on the respiratory system, initial encounter',
  'T48906D Underdosing of unspecified agents primarily acting on the respiratory system, subsequent',
  'encounter',
  'T48906S Underdosing of unspecified agents primarily acting on the respiratory system, sequela',
  'T48991A Poisoning by other agents primarily acting on the respiratory system, accidental',
  '(unintentional), initial encounter',
  'T48991D Poisoning by other agents primarily acting on the respiratory system, accidental',
  '(unintentional), subsequent encounter',
  'T48991S Poisoning by other agents primarily acting on the respiratory system, accidental',
  '(unintentional), sequela',
  'T48992A Poisoning by other agents primarily acting on the respiratory system, intentional self-harm,',
  'initial encounter',
  'T48992D Poisoning by other agents primarily acting on the respiratory system, intentional self-harm,',
  'subsequent encounter',
  'T48992S Poisoning by other agents primarily acting on the respiratory system, intentional self-harm,',
  'sequela',
  'T48993A Poisoning by other agents primarily acting on the respiratory system, assault, initial encounter',
  'T48993D Poisoning by other agents primarily acting on the respiratory system, assault, subsequent',
  'encounter',
  'T48993S Poisoning by other agents primarily acting on the respiratory system, assault, sequela',
  'T48994A Poisoning by other agents primarily acting on the respiratory system, undetermined, initial',
  'encounter',
  'T48994D Poisoning by other agents primarily acting on the respiratory system, undetermined,',
  'subsequent encounter',
  'T48994S Poisoning by other agents primarily acting on the respiratory system, undetermined, sequela',
  'T48995A Adverse effect of other agents primarily acting on the respiratory system, initial encounter',
  'T48995D Adverse effect of other agents primarily acting on the respiratory system, subsequent',
  'encounter',
  'T48995S Adverse effect of other agents primarily acting on the respiratory system, sequela',
  'T48996A Underdosing of other agents primarily acting on the respiratory system, initial encounter',
  'T48996D Underdosing of other agents primarily acting on the respiratory system, subsequent encounter',
  'T48996S Underdosing of other agents primarily acting on the respiratory system, sequela',
  'T490X1A Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, accidental',
  '(unintentional), initial encounter',
  'T490X1D Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, accidental',
  '(unintentional), subsequent encounter',
  'T490X1S Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, accidental',
  '(unintentional), sequela',
  'T490X2A Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, intentional self-harm,',
  'initial encounter',
  'T490X2D Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, intentional self-harm,',
  'subsequent encounter',
  'T490X2S Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, intentional self-harm,',
  'sequela',
  'T490X3A Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, assault, initial',
  'encounter',
  'T490X3D Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, assault, subsequent',
  'encounter',
  'T490X3S Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, assault, sequela',
  'T490X4A Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, undetermined, initial',
  'encounter',
  'T490X4D Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, undetermined,',
  'subsequent encounter',
  'T490X4S Poisoning by local antifungal, anti-infective and anti-inflammatory drugs, undetermined,',
  'sequela',
  'T490X5A Adverse effect of local antifungal, anti-infective and anti-inflammatory drugs, initial encounter',
  'T490X5D Adverse effect of local antifungal, anti-infective and anti-inflammatory drugs, subsequent',
  'encounter',
  'T490X5S Adverse effect of local antifungal, anti-infective and anti-inflammatory drugs, sequela',
  'T490X6A Underdosing of local antifungal, anti-infective and anti-inflammatory drugs, initial encounter',
  'T490X6D Underdosing of local antifungal, anti-infective and anti-inflammatory drugs, subsequent',
  'encounter',
  'T490X6S Underdosing of local antifungal, anti-infective and anti-inflammatory drugs, sequela',
  'T491X1A Poisoning by antipruritics, accidental (unintentional), initial encounter',
  'T491X1D Poisoning by antipruritics, accidental (unintentional), subsequent encounter',
  'T491X1S Poisoning by antipruritics, accidental (unintentional), sequela',
  'T491X2A Poisoning by antipruritics, intentional self-harm, initial encounter',
  'T491X2D Poisoning by antipruritics, intentional self-harm, subsequent encounter',
  'T491X2S Poisoning by antipruritics, intentional self-harm, sequela',
  'T491X3A Poisoning by antipruritics, assault, initial encounter',
  'T491X3D Poisoning by antipruritics, assault, subsequent encounter',
  'T491X3S Poisoning by antipruritics, assault, sequela',
  'T491X4A Poisoning by antipruritics, undetermined, initial encounter',
  'T491X4D Poisoning by antipruritics, undetermined, subsequent encounter',
  'T491X4S Poisoning by antipruritics, undetermined, sequela',
  'T491X5A Adverse effect of antipruritics, initial encounter',
  'T491X5D Adverse effect of antipruritics, subsequent encounter',
  'T491X5S Adverse effect of antipruritics, sequela',
  'T491X6A Underdosing of antipruritics, initial encounter',
  'T491X6D Underdosing of antipruritics, subsequent encounter',
  'T491X6S Underdosing of antipruritics, sequela',
  'T492X1A Poisoning by local astringents and local detergents, accidental (unintentional), initial encounter',
  'T492X1D Poisoning by local astringents and local detergents, accidental (unintentional), subsequent',
  'encounter',
  'T492X1S Poisoning by local astringents and local detergents, accidental (unintentional), sequela',
  'T492X2A Poisoning by local astringents and local detergents, intentional self-harm, initial encounter',
  'T492X2D Poisoning by local astringents and local detergents, intentional self-harm, subsequent',
  'encounter',
  'T492X2S Poisoning by local astringents and local detergents, intentional self-harm, sequela',
  'T492X3A Poisoning by local astringents and local detergents, assault, initial encounter',
  'T492X3D Poisoning by local astringents and local detergents, assault, subsequent encounter',
  'T492X3S Poisoning by local astringents and local detergents, assault, sequela',
  'T492X4A Poisoning by local astringents and local detergents, undetermined, initial encounter',
  'T492X4D Poisoning by local astringents and local detergents, undetermined, subsequent encounter',
  'T492X4S Poisoning by local astringents and local detergents, undetermined, sequela',
  'T492X5A Adverse effect of local astringents and local detergents, initial encounter',
  'T492X5D Adverse effect of local astringents and local detergents, subsequent encounter',
  'T492X5S Adverse effect of local astringents and local detergents, sequela',
  'T492X6A Underdosing of local astringents and local detergents, initial encounter',
  'T492X6D Underdosing of local astringents and local detergents, subsequent encounter',
  'T492X6S Underdosing of local astringents and local detergents, sequela',
  'T493X1A Poisoning by emollients, demulcents and protectants, accidental (unintentional), initial',
  'encounter',
  'T493X1D Poisoning by emollients, demulcents and protectants, accidental (unintentional), subsequent',
  'encounter',
  'T493X1S Poisoning by emollients, demulcents and protectants, accidental (unintentional), sequela',
  'T493X2A Poisoning by emollients, demulcents and protectants, intentional self-harm, initial encounter',
  'T493X2D Poisoning by emollients, demulcents and protectants, intentional self-harm, subsequent',
  'encounter',
  'T493X2S Poisoning by emollients, demulcents and protectants, intentional self-harm, sequela',
  'T493X3A Poisoning by emollients, demulcents and protectants, assault, initial encounter',
  'T493X3D Poisoning by emollients, demulcents and protectants, assault, subsequent encounter',
  'T493X3S Poisoning by emollients, demulcents and protectants, assault, sequela',
  'T493X4A Poisoning by emollients, demulcents and protectants, undetermined, initial encounter',
  'T493X4D Poisoning by emollients, demulcents and protectants, undetermined, subsequent encounter',
  'T493X4S Poisoning by emollients, demulcents and protectants, undetermined, sequela',
  'T493X5A Adverse effect of emollients, demulcents and protectants, initial encounter',
  'T493X5D Adverse effect of emollients, demulcents and protectants, subsequent encounter',
  'T493X5S Adverse effect of emollients, demulcents and protectants, sequela',
  'T493X6A Underdosing of emollients, demulcents and protectants, initial encounter',
  'T493X6D Underdosing of emollients, demulcents and protectants, subsequent encounter',
  'T493X6S Underdosing of emollients, demulcents and protectants, sequela',
  'T494X1A Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'accidental (unintentional), initial encounter',
  'T494X1D Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'accidental (unintentional), subsequent encounter',
  'T494X1S Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'accidental (unintentional), sequela',
  'T494X2A Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'intentional self-harm, initial encounter',
  'T494X2D Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'intentional self-harm, subsequent encounter',
  'T494X2S Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'intentional self-harm, sequela',
  'T494X3A Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'assault, initial encounter',
  'T494X3D Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'assault, subsequent encounter',
  'T494X3S Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'assault, sequela',
  'T494X4A Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'undetermined, initial encounter',
  'T494X4D Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'undetermined, subsequent encounter',
  'T494X4S Poisoning by keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'undetermined, sequela',
  'T494X5A Adverse effect of keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'initial encounter',
  'T494X5D Adverse effect of keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'subsequent encounter',
  'T494X5S Adverse effect of keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'sequela',
  'T494X6A Underdosing of keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'initial encounter',
  'T494X6D Underdosing of keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'subsequent encounter',
  'T494X6S Underdosing of keratolytics, keratoplastics, and other hair treatment drugs and preparations,',
  'sequela',
  'T495X1A Poisoning by ophthalmological drugs and preparations, accidental (unintentional), initial',
  'encounter',
  'T495X1D Poisoning by ophthalmological drugs and preparations, accidental (unintentional), subsequent',
  'encounter',
  'T495X1S Poisoning by ophthalmological drugs and preparations, accidental (unintentional), sequela',
  'T495X2A Poisoning by ophthalmological drugs and preparations, intentional self-harm, initial encounter',
  'T495X2D Poisoning by ophthalmological drugs and preparations, intentional self-harm, subsequent',
  'encounter',
  'T495X2S Poisoning by ophthalmological drugs and preparations, intentional self-harm, sequela',
  'T495X3A Poisoning by ophthalmological drugs and preparations, assault, initial encounter',
  'T495X3D Poisoning by ophthalmological drugs and preparations, assault, subsequent encounter',
  'T495X3S Poisoning by ophthalmological drugs and preparations, assault, sequela',
  'T495X4A Poisoning by ophthalmological drugs and preparations, undetermined, initial encounter',
  'T495X4D Poisoning by ophthalmological drugs and preparations, undetermined, subsequent encounter',
  'T495X4S Poisoning by ophthalmological drugs and preparations, undetermined, sequela',
  'T495X5A Adverse effect of ophthalmological drugs and preparations, initial encounter',
  'T495X5D Adverse effect of ophthalmological drugs and preparations, subsequent encounter',
  'T495X5S Adverse effect of ophthalmological drugs and preparations, sequela',
  'T495X6A Underdosing of ophthalmological drugs and preparations, initial encounter',
  'T495X6D Underdosing of ophthalmological drugs and preparations, subsequent encounter',
  'T495X6S Underdosing of ophthalmological drugs and preparations, sequela',
  'T496X1A Poisoning by otorhinolaryngological drugs and preparations, accidental (unintentional), initial',
  'encounter',
  'T496X1D Poisoning by otorhinolaryngological drugs and preparations, accidental (unintentional),',
  'subsequent encounter',
  'T496X1S Poisoning by otorhinolaryngological drugs and preparations, accidental (unintentional), sequela',
  'T496X2A Poisoning by otorhinolaryngological drugs and preparations, intentional self-harm, initial',
  'encounter',
  'T496X2D Poisoning by otorhinolaryngological drugs and preparations, intentional self-harm, subsequent',
  'encounter',
  'T496X2S Poisoning by otorhinolaryngological drugs and preparations, intentional self-harm, sequela',
  'T496X3A Poisoning by otorhinolaryngological drugs and preparations, assault, initial encounter',
  'T496X3D Poisoning by otorhinolaryngological drugs and preparations, assault, subsequent encounter',
  'T496X3S Poisoning by otorhinolaryngological drugs and preparations, assault, sequela',
  'T496X4A Poisoning by otorhinolaryngological drugs and preparations, undetermined, initial encounter',
  'T496X4D Poisoning by otorhinolaryngological drugs and preparations, undetermined, subsequent',
  'encounter',
  'T496X4S Poisoning by otorhinolaryngological drugs and preparations, undetermined, sequela',
  'T496X5A Adverse effect of otorhinolaryngological drugs and preparations, initial encounter',
  'T496X5D Adverse effect of otorhinolaryngological drugs and preparations, subsequent encounter',
  'T496X5S Adverse effect of otorhinolaryngological drugs and preparations, sequela',
  'T496X6A Underdosing of otorhinolaryngological drugs and preparations, initial encounter',
  'T496X6D Underdosing of otorhinolaryngological drugs and preparations, subsequent encounter',
  'T496X6S Underdosing of otorhinolaryngological drugs and preparations, sequela',
  'T497X1A Poisoning by dental drugs, topically applied, accidental (unintentional), initial encounter',
  'T497X1D Poisoning by dental drugs, topically applied, accidental (unintentional), subsequent encounter',
  'T497X1S Poisoning by dental drugs, topically applied, accidental (unintentional), sequela',
  'T497X2A Poisoning by dental drugs, topically applied, intentional self-harm, initial encounter',
  'T497X2D Poisoning by dental drugs, topically applied, intentional self-harm, subsequent encounter',
  'T497X2S Poisoning by dental drugs, topically applied, intentional self-harm, sequela',
  'T497X3A Poisoning by dental drugs, topically applied, assault, initial encounter',
  'T497X3D Poisoning by dental drugs, topically applied, assault, subsequent encounter',
  'T497X3S Poisoning by dental drugs, topically applied, assault, sequela',
  'T497X4A Poisoning by dental drugs, topically applied, undetermined, initial encounter',
  'T497X4D Poisoning by dental drugs, topically applied, undetermined, subsequent encounter',
  'T497X4S Poisoning by dental drugs, topically applied, undetermined, sequela',
  'T497X5A Adverse effect of dental drugs, topically applied, initial encounter',
  'T497X5D Adverse effect of dental drugs, topically applied, subsequent encounter',
  'T497X5S Adverse effect of dental drugs, topically applied, sequela',
  'T497X6A Underdosing of dental drugs, topically applied, initial encounter',
  'T497X6D Underdosing of dental drugs, topically applied, subsequent encounter',
  'T497X6S Underdosing of dental drugs, topically applied, sequela',
  'T498X1A Poisoning by other topical agents, accidental (unintentional), initial encounter',
  'T498X1D Poisoning by other topical agents, accidental (unintentional), subsequent encounter',
  'T498X1S Poisoning by other topical agents, accidental (unintentional), sequela',
  'T498X2A Poisoning by other topical agents, intentional self-harm, initial encounter',
  'T498X2D Poisoning by other topical agents, intentional self-harm, subsequent encounter',
  'T498X2S Poisoning by other topical agents, intentional self-harm, sequela',
  'T498X3A Poisoning by other topical agents, assault, initial encounter',
  'T498X3D Poisoning by other topical agents, assault, subsequent encounter',
  'T498X3S Poisoning by other topical agents, assault, sequela',
  'T498X4A Poisoning by other topical agents, undetermined, initial encounter',
  'T498X4D Poisoning by other topical agents, undetermined, subsequent encounter',
  'T498X4S Poisoning by other topical agents, undetermined, sequela',
  'T498X5A Adverse effect of other topical agents, initial encounter',
  'T498X5D Adverse effect of other topical agents, subsequent encounter',
  'T498X5S Adverse effect of other topical agents, sequela',
  'T498X6A Underdosing of other topical agents, initial encounter',
  'T498X6D Underdosing of other topical agents, subsequent encounter',
  'T498X6S Underdosing of other topical agents, sequela',
  'T4991XA Poisoning by unspecified topical agent, accidental (unintentional), initial encounter',
  'T4991XD Poisoning by unspecified topical agent, accidental (unintentional), subsequent encounter',
  'T4991XS Poisoning by unspecified topical agent, accidental (unintentional), sequela',
  'T4992XA Poisoning by unspecified topical agent, intentional self-harm, initial encounter',
  'T4992XD Poisoning by unspecified topical agent, intentional self-harm, subsequent encounter',
  'T4992XS Poisoning by unspecified topical agent, intentional self-harm, sequela',
  'T4993XA Poisoning by unspecified topical agent, assault, initial encounter',
  'T4993XD Poisoning by unspecified topical agent, assault, subsequent encounter',
  'T4993XS Poisoning by unspecified topical agent, assault, sequela',
  'T4994XA Poisoning by unspecified topical agent, undetermined, initial encounter',
  'T4994XD Poisoning by unspecified topical agent, undetermined, subsequent encounter',
  'T4994XS Poisoning by unspecified topical agent, undetermined, sequela',
  'T4995XA Adverse effect of unspecified topical agent, initial encounter',
  'T4995XD Adverse effect of unspecified topical agent, subsequent encounter',
  'T4995XS Adverse effect of unspecified topical agent, sequela',
  'T4996XA Underdosing of unspecified topical agent, initial encounter',
  'T4996XD Underdosing of unspecified topical agent, subsequent encounter',
  'T4996XS Underdosing of unspecified topical agent, sequela',
  'T500X1A Poisoning by mineralocorticoids and their antagonists, accidental (unintentional), initial',
  'encounter',
  'T500X1D Poisoning by mineralocorticoids and their antagonists, accidental (unintentional), subsequent',
  'encounter',
  'T500X1S Poisoning by mineralocorticoids and their antagonists, accidental (unintentional), sequela',
  'T500X2A Poisoning by mineralocorticoids and their antagonists, intentional self-harm, initial encounter',
  'T500X2D Poisoning by mineralocorticoids and their antagonists, intentional self-harm, subsequent',
  'encounter',
  'T500X2S Poisoning by mineralocorticoids and their antagonists, intentional self-harm, sequela',
  'T500X3A Poisoning by mineralocorticoids and their antagonists, assault, initial encounter',
  'T500X3D Poisoning by mineralocorticoids and their antagonists, assault, subsequent encounter',
  'T500X3S Poisoning by mineralocorticoids and their antagonists, assault, sequela',
  'T500X4A Poisoning by mineralocorticoids and their antagonists, undetermined, initial encounter',
  'T500X4D Poisoning by mineralocorticoids and their antagonists, undetermined, subsequent encounter',
  'T500X4S Poisoning by mineralocorticoids and their antagonists, undetermined, sequela',
  'T500X5A Adverse effect of mineralocorticoids and their antagonists, initial encounter',
  'T500X5D Adverse effect of mineralocorticoids and their antagonists, subsequent encounter',
  'T500X5S Adverse effect of mineralocorticoids and their antagonists, sequela',
  'T500X6A Underdosing of mineralocorticoids and their antagonists, initial encounter',
  'T500X6D Underdosing of mineralocorticoids and their antagonists, subsequent encounter',
  'T500X6S Underdosing of mineralocorticoids and their antagonists, sequela',
  'T501X1A Poisoning by loop [high-ceiling] diuretics, accidental (unintentional), initial encounter',
  'T501X1D Poisoning by loop [high-ceiling] diuretics, accidental (unintentional), subsequent encounter',
  'T501X1S Poisoning by loop [high-ceiling] diuretics, accidental (unintentional), sequela',
  'T501X2A Poisoning by loop [high-ceiling] diuretics, intentional self-harm, initial encounter',
  'T501X2D Poisoning by loop [high-ceiling] diuretics, intentional self-harm, subsequent encounter',
  'T501X2S Poisoning by loop [high-ceiling] diuretics, intentional self-harm, sequela',
  'T501X3A Poisoning by loop [high-ceiling] diuretics, assault, initial encounter',
  'T501X3D Poisoning by loop [high-ceiling] diuretics, assault, subsequent encounter',
  'T501X3S Poisoning by loop [high-ceiling] diuretics, assault, sequela',
  'T501X4A Poisoning by loop [high-ceiling] diuretics, undetermined, initial encounter',
  'T501X4D Poisoning by loop [high-ceiling] diuretics, undetermined, subsequent encounter',
  'T501X4S Poisoning by loop [high-ceiling] diuretics, undetermined, sequela',
  'T501X5A Adverse effect of loop [high-ceiling] diuretics, initial encounter',
  'T501X5D Adverse effect of loop [high-ceiling] diuretics, subsequent encounter',
  'T501X5S Adverse effect of loop [high-ceiling] diuretics, sequela',
  'T501X6A Underdosing of loop [high-ceiling] diuretics, initial encounter',
  'T501X6D Underdosing of loop [high-ceiling] diuretics, subsequent encounter',
  'T501X6S Underdosing of loop [high-ceiling] diuretics, sequela',
  'T502X1A Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, accidental',
  '(unintentional), initial encounter',
  'T502X1D Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, accidental',
  '(unintentional), subsequent encounter',
  'T502X1S Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, accidental',
  '(unintentional), sequela',
  'T502X2A Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, intentional',
  'self-harm, initial encounter',
  'T502X2D Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, intentional',
  'self-harm, subsequent encounter',
  'T502X2S Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, intentional',
  'self-harm, sequela',
  'T502X3A Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, assault,',
  'initial encounter',
  'T502X3D Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, assault,',
  'subsequent encounter',
  'T502X3S Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, assault,',
  'sequela',
  'T502X4A Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics,',
  'undetermined, initial encounter',
  'T502X4D Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics,',
  'undetermined, subsequent encounter',
  'T502X4S Poisoning by carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics,',
  'undetermined, sequela',
  'T502X5A Adverse effect of carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, initial',
  'encounter',
  'T502X5D Adverse effect of carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics,',
  'subsequent encounter',
  'T502X5S Adverse effect of carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, sequela',
  'T502X6A Underdosing of carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, initial',
  'encounter',
  'T502X6D Underdosing of carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics,',
  'subsequent encounter',
  'T502X6S Underdosing of carbonic-anhydrase inhibitors, benzothiadiazides and other diuretics, sequela',
  'T503X1A Poisoning by electrolytic, caloric and water-balance agents, accidental (unintentional), initial',
  'encounter',
  'T503X1D Poisoning by electrolytic, caloric and water-balance agents, accidental (unintentional),',
  'subsequent encounter',
  'T503X1S Poisoning by electrolytic, caloric and water-balance agents, accidental (unintentional), sequela',
  'T503X2A Poisoning by electrolytic, caloric and water-balance agents, intentional self-harm, initial',
  'encounter',
  'T503X2D Poisoning by electrolytic, caloric and water-balance agents, intentional self-harm, subsequent',
  'encounter',
  'T503X2S Poisoning by electrolytic, caloric and water-balance agents, intentional self-harm, sequela',
  'T503X3A Poisoning by electrolytic, caloric and water-balance agents, assault, initial encounter',
  'T503X3D Poisoning by electrolytic, caloric and water-balance agents, assault, subsequent encounter',
  'T503X3S Poisoning by electrolytic, caloric and water-balance agents, assault, sequela',
  'T503X4A Poisoning by electrolytic, caloric and water-balance agents, undetermined, initial encounter',
  'T503X4D Poisoning by electrolytic, caloric and water-balance agents, undetermined, subsequent',
  'encounter',
  'T503X4S Poisoning by electrolytic, caloric and water-balance agents, undetermined, sequela',
  'T503X5A Adverse effect of electrolytic, caloric and water-balance agents, initial encounter',
  'T503X5D Adverse effect of electrolytic, caloric and water-balance agents, subsequent encounter',
  'T503X5S Adverse effect of electrolytic, caloric and water-balance agents, sequela',
  'T503X6A Underdosing of electrolytic, caloric and water-balance agents, initial encounter',
  'T503X6D Underdosing of electrolytic, caloric and water-balance agents, subsequent encounter',
  'T503X6S Underdosing of electrolytic, caloric and water-balance agents, sequela',
  'T504X1A Poisoning by drugs affecting uric acid metabolism, accidental (unintentional), initial encounter',
  'T504X1D Poisoning by drugs affecting uric acid metabolism, accidental (unintentional), subsequent',
  'encounter',
  'T504X1S Poisoning by drugs affecting uric acid metabolism, accidental (unintentional), sequela',
  'T504X2A Poisoning by drugs affecting uric acid metabolism, intentional self-harm, initial encounter',
  'T504X2D Poisoning by drugs affecting uric acid metabolism, intentional self-harm, subsequent',
  'encounter',
  'T504X2S Poisoning by drugs affecting uric acid metabolism, intentional self-harm, sequela',
  'T504X3A Poisoning by drugs affecting uric acid metabolism, assault, initial encounter',
  'T504X3D Poisoning by drugs affecting uric acid metabolism, assault, subsequent encounter',
  'T504X3S Poisoning by drugs affecting uric acid metabolism, assault, sequela',
  'T504X4A Poisoning by drugs affecting uric acid metabolism, undetermined, initial encounter',
  'T504X4D Poisoning by drugs affecting uric acid metabolism, undetermined, subsequent encounter',
  'T504X4S Poisoning by drugs affecting uric acid metabolism, undetermined, sequela',
  'T504X5A Adverse effect of drugs affecting uric acid metabolism, initial encounter',
  'T504X5D Adverse effect of drugs affecting uric acid metabolism, subsequent encounter',
  'T504X5S Adverse effect of drugs affecting uric acid metabolism, sequela',
  'T504X6A Underdosing of drugs affecting uric acid metabolism, initial encounter',
  'T504X6D Underdosing of drugs affecting uric acid metabolism, subsequent encounter',
  'T504X6S Underdosing of drugs affecting uric acid metabolism, sequela',
  'T505X1A Poisoning by appetite depressants, accidental (unintentional), initial encounter',
  'T505X1D Poisoning by appetite depressants, accidental (unintentional), subsequent encounter',
  'T505X1S Poisoning by appetite depressants, accidental (unintentional), sequela',
  'T505X2A Poisoning by appetite depressants, intentional self-harm, initial encounter',
  'T505X2D Poisoning by appetite depressants, intentional self-harm, subsequent encounter',
  'T505X2S Poisoning by appetite depressants, intentional self-harm, sequela',
  'T505X3A Poisoning by appetite depressants, assault, initial encounter',
  'T505X3D Poisoning by appetite depressants, assault, subsequent encounter',
  'T505X3S Poisoning by appetite depressants, assault, sequela',
  'T505X4A Poisoning by appetite depressants, undetermined, initial encounter',
  'T505X4D Poisoning by appetite depressants, undetermined, subsequent encounter',
  'T505X4S Poisoning by appetite depressants, undetermined, sequela',
  'T505X5A Adverse effect of appetite depressants, initial encounter',
  'T505X5D Adverse effect of appetite depressants, subsequent encounter',
  'T505X5S Adverse effect of appetite depressants, sequela',
  'T505X6A Underdosing of appetite depressants, initial encounter',
  'T505X6D Underdosing of appetite depressants, subsequent encounter',
  'T505X6S Underdosing of appetite depressants, sequela',
  'T506X1A Poisoning by antidotes and chelating agents, accidental (unintentional), initial encounter',
  'T506X1D Poisoning by antidotes and chelating agents, accidental (unintentional), subsequent encounter',
  'T506X1S Poisoning by antidotes and chelating agents, accidental (unintentional), sequela',
  'T506X2A Poisoning by antidotes and chelating agents, intentional self-harm, initial encounter',
  'T506X2D Poisoning by antidotes and chelating agents, intentional self-harm, subsequent encounter',
  'T506X2S Poisoning by antidotes and chelating agents, intentional self-harm, sequela',
  'T506X3A Poisoning by antidotes and chelating agents, assault, initial encounter',
  'T506X3D Poisoning by antidotes and chelating agents, assault, subsequent encounter',
  'T506X3S Poisoning by antidotes and chelating agents, assault, sequela',
  'T506X4A Poisoning by antidotes and chelating agents, undetermined, initial encounter',
  'T506X4D Poisoning by antidotes and chelating agents, undetermined, subsequent encounter',
  'T506X4S Poisoning by antidotes and chelating agents, undetermined, sequela',
  'T506X5A Adverse effect of antidotes and chelating agents, initial encounter',
  'T506X5D Adverse effect of antidotes and chelating agents, subsequent encounter',
  'T506X5S Adverse effect of antidotes and chelating agents, sequela',
  'T506X6A Underdosing of antidotes and chelating agents, initial encounter',
  'T506X6D Underdosing of antidotes and chelating agents, subsequent encounter',
  'T506X6S Underdosing of antidotes and chelating agents, sequela',
  'T507X1A Poisoning by analeptics and opioid receptor antagonists, accidental (unintentional), initial',
  'encounter',
  'T507X1D Poisoning by analeptics and opioid receptor antagonists, accidental (unintentional),',
  'subsequent encounter',
  'T507X1S Poisoning by analeptics and opioid receptor antagonists, accidental (unintentional), sequela',
  'T507X2A Poisoning by analeptics and opioid receptor antagonists, intentional self-harm, initial',
  'encounter',
  'T507X2D Poisoning by analeptics and opioid receptor antagonists, intentional self-harm, subsequent',
  'encounter',
  'T507X2S Poisoning by analeptics and opioid receptor antagonists, intentional self-harm, sequela',
  'T507X3A Poisoning by analeptics and opioid receptor antagonists, assault, initial encounter',
  'T507X3D Poisoning by analeptics and opioid receptor antagonists, assault, subsequent encounter',
  'T507X3S Poisoning by analeptics and opioid receptor antagonists, assault, sequela',
  'T507X4A Poisoning by analeptics and opioid receptor antagonists, undetermined, initial encounter',
  'T507X4D Poisoning by analeptics and opioid receptor antagonists, undetermined, subsequent encounter',
  'T507X4S Poisoning by analeptics and opioid receptor antagonists, undetermined, sequela',
  'T507X5A Adverse effect of analeptics and opioid receptor antagonists, initial encounter',
  'T507X5D Adverse effect of analeptics and opioid receptor antagonists, subsequent encounter',
  'T507X5S Adverse effect of analeptics and opioid receptor antagonists, sequela',
  'T507X6A Underdosing of analeptics and opioid receptor antagonists, initial encounter',
  'T507X6D Underdosing of analeptics and opioid receptor antagonists, subsequent encounter',
  'T507X6S Underdosing of analeptics and opioid receptor antagonists, sequela',
  'T508X1A Poisoning by diagnostic agents, accidental (unintentional), initial encounter',
  'T508X1D Poisoning by diagnostic agents, accidental (unintentional), subsequent encounter',
  'T508X1S Poisoning by diagnostic agents, accidental (unintentional), sequela',
  'T508X2A Poisoning by diagnostic agents, intentional self-harm, initial encounter',
  'T508X2D Poisoning by diagnostic agents, intentional self-harm, subsequent encounter',
  'T508X2S Poisoning by diagnostic agents, intentional self-harm, sequela',
  'T508X3A Poisoning by diagnostic agents, assault, initial encounter',
  'T508X3D Poisoning by diagnostic agents, assault, subsequent encounter',
  'T508X3S Poisoning by diagnostic agents, assault, sequela',
  'T508X4A Poisoning by diagnostic agents, undetermined, initial encounter',
  'T508X4D Poisoning by diagnostic agents, undetermined, subsequent encounter',
  'T508X4S Poisoning by diagnostic agents, undetermined, sequela',
  'T508X5A Adverse effect of diagnostic agents, initial encounter',
  'T508X5D Adverse effect of diagnostic agents, subsequent encounter',
  'T508X5S Adverse effect of diagnostic agents, sequela',
  'T508X6A Underdosing of diagnostic agents, initial encounter',
  'T508X6D Underdosing of diagnostic agents, subsequent encounter',
  'T508X6S Underdosing of diagnostic agents, sequela',
  'T50A11A Poisoning by pertussis vaccine, including combinations with a pertussis component, accidental',
  '(unintentional), initial encounter',
  'T50A11D Poisoning by pertussis vaccine, including combinations with a pertussis component, accidental',
  '(unintentional), subsequent encounter',
  'T50A11S Poisoning by pertussis vaccine, including combinations with a pertussis component, accidental',
  '(unintentional), sequela',
  'T50A12A Poisoning by pertussis vaccine, including combinations with a pertussis component, intentional',
  'self-harm, initial encounter',
  'T50A12D Poisoning by pertussis vaccine, including combinations with a pertussis component, intentional',
  'self-harm, subsequent encounter',
  'T50A12S Poisoning by pertussis vaccine, including combinations with a pertussis component, intentional',
  'self-harm, sequela',
  'T50A13A Poisoning by pertussis vaccine, including combinations with a pertussis component, assault,',
  'initial encounter',
  'T50A13D Poisoning by pertussis vaccine, including combinations with a pertussis component, assault,',
  'subsequent encounter',
  'T50A13S Poisoning by pertussis vaccine, including combinations with a pertussis component, assault,',
  'sequela',
  'T50A14A Poisoning by pertussis vaccine, including combinations with a pertussis component,',
  'undetermined, initial encounter',
  'T50A14D Poisoning by pertussis vaccine, including combinations with a pertussis component,',
  'undetermined, subsequent encounter',
  'T50A14S Poisoning by pertussis vaccine, including combinations with a pertussis component,',
  'undetermined, sequela',
  'T50A15A Adverse effect of pertussis vaccine, including combinations with a pertussis component, initial',
  'encounter',
  'T50A15D Adverse effect of pertussis vaccine, including combinations with a pertussis component,',
  'subsequent encounter',
  'T50A15S Adverse effect of pertussis vaccine, including combinations with a pertussis component,',
  'sequela',
  'T50A16A Underdosing of pertussis vaccine, including combinations with a pertussis component, initial',
  'encounter',
  'T50A16D Underdosing of pertussis vaccine, including combinations with a pertussis component,',
  'subsequent encounter',
  'T50A16S Underdosing of pertussis vaccine, including combinations with a pertussis component, sequela',
  'T50A21A Poisoning by mixed bacterial vaccines without a pertussis component, accidental',
  '(unintentional), initial encounter',
  'T50A21D Poisoning by mixed bacterial vaccines without a pertussis component, accidental',
  '(unintentional), subsequent encounter',
  'T50A21S Poisoning by mixed bacterial vaccines without a pertussis component, accidental',
  '(unintentional), sequela',
  'T50A22A Poisoning by mixed bacterial vaccines without a pertussis component, intentional self-harm,',
  'initial encounter',
  'T50A22D Poisoning by mixed bacterial vaccines without a pertussis component, intentional self-harm,',
  'subsequent encounter',
  'T50A22S Poisoning by mixed bacterial vaccines without a pertussis component, intentional self-harm,',
  'sequela',
  'T50A23A Poisoning by mixed bacterial vaccines without a pertussis component, assault, initial encounter',
  'T50A23D Poisoning by mixed bacterial vaccines without a pertussis component, assault, subsequent',
  'encounter',
  'T50A23S Poisoning by mixed bacterial vaccines without a pertussis component, assault, sequela',
  'T50A24A Poisoning by mixed bacterial vaccines without a pertussis component, undetermined, initial',
  'encounter',
  'T50A24D Poisoning by mixed bacterial vaccines without a pertussis component, undetermined,',
  'subsequent encounter',
  'T50A24S Poisoning by mixed bacterial vaccines without a pertussis component, undetermined, sequela',
  'T50A25A Adverse effect of mixed bacterial vaccines without a pertussis component, initial encounter',
  'T50A25D Adverse effect of mixed bacterial vaccines without a pertussis component, subsequent',
  'encounter',
  'T50A25S Adverse effect of mixed bacterial vaccines without a pertussis component, sequela',
  'T50A26A Underdosing of mixed bacterial vaccines without a pertussis component, initial encounter',
  'T50A26D Underdosing of mixed bacterial vaccines without a pertussis component, subsequent',
  'encounter',
  'T50A26S Underdosing of mixed bacterial vaccines without a pertussis component, sequela',
  'T50A91A Poisoning by other bacterial vaccines, accidental (unintentional), initial encounter',
  'T50A91D Poisoning by other bacterial vaccines, accidental (unintentional), subsequent encounter',
  'T50A91S Poisoning by other bacterial vaccines, accidental (unintentional), sequela',
  'T50A92A Poisoning by other bacterial vaccines, intentional self-harm, initial encounter',
  'T50A92D Poisoning by other bacterial vaccines, intentional self-harm, subsequent encounter',
  'T50A92S Poisoning by other bacterial vaccines, intentional self-harm, sequela',
  'T50A93A Poisoning by other bacterial vaccines, assault, initial encounter',
  'T50A93D Poisoning by other bacterial vaccines, assault, subsequent encounter',
  'T50A93S Poisoning by other bacterial vaccines, assault, sequela',
  'T50A94A Poisoning by other bacterial vaccines, undetermined, initial encounter',
  'T50A94D Poisoning by other bacterial vaccines, undetermined, subsequent encounter',
  'T50A94S Poisoning by other bacterial vaccines, undetermined, sequela',
  'T50A95A Adverse effect of other bacterial vaccines, initial encounter',
  'T50A95D Adverse effect of other bacterial vaccines, subsequent encounter',
  'T50A95S Adverse effect of other bacterial vaccines, sequela',
  'T50A96A Underdosing of other bacterial vaccines, initial encounter',
  'T50A96D Underdosing of other bacterial vaccines, subsequent encounter',
  'T50A96S Underdosing of other bacterial vaccines, sequela',
  'T50B11A Poisoning by smallpox vaccines, accidental (unintentional), initial encounter',
  'T50B11D Poisoning by smallpox vaccines, accidental (unintentional), subsequent encounter',
  'T50B11S Poisoning by smallpox vaccines, accidental (unintentional), sequela',
  'T50B12A Poisoning by smallpox vaccines, intentional self-harm, initial encounter',
  'T50B12D Poisoning by smallpox vaccines, intentional self-harm, subsequent encounter',
  'T50B12S Poisoning by smallpox vaccines, intentional self-harm, sequela',
  'T50B13A Poisoning by smallpox vaccines, assault, initial encounter',
  'T50B13D Poisoning by smallpox vaccines, assault, subsequent encounter',
  'T50B13S Poisoning by smallpox vaccines, assault, sequela',
  'T50B14A Poisoning by smallpox vaccines, undetermined, initial encounter',
  'T50B14D Poisoning by smallpox vaccines, undetermined, subsequent encounter',
  'T50B14S Poisoning by smallpox vaccines, undetermined, sequela',
  'T50B15A Adverse effect of smallpox vaccines, initial encounter',
  'T50B15D Adverse effect of smallpox vaccines, subsequent encounter',
  'T50B15S Adverse effect of smallpox vaccines, sequela',
  'T50B16A Underdosing of smallpox vaccines, initial encounter',
  'T50B16D Underdosing of smallpox vaccines, subsequent encounter',
  'T50B16S Underdosing of smallpox vaccines, sequela',
  'T50B91A Poisoning by other viral vaccines, accidental (unintentional), initial encounter',
  'T50B91D Poisoning by other viral vaccines, accidental (unintentional), subsequent encounter',
  'T50B91S Poisoning by other viral vaccines, accidental (unintentional), sequela',
  'T50B92A Poisoning by other viral vaccines, intentional self-harm, initial encounter',
  'T50B92D Poisoning by other viral vaccines, intentional self-harm, subsequent encounter',
  'T50B92S Poisoning by other viral vaccines, intentional self-harm, sequela',
  'T50B93A Poisoning by other viral vaccines, assault, initial encounter',
  'T50B93D Poisoning by other viral vaccines, assault, subsequent encounter',
  'T50B93S Poisoning by other viral vaccines, assault, sequela',
  'T50B94A Poisoning by other viral vaccines, undetermined, initial encounter',
  'T50B94D Poisoning by other viral vaccines, undetermined, subsequent encounter',
  'T50B94S Poisoning by other viral vaccines, undetermined, sequela',
  'T50B95A Adverse effect of other viral vaccines, initial encounter',
  'T50B95D Adverse effect of other viral vaccines, subsequent encounter',
  'T50B95S Adverse effect of other viral vaccines, sequela',
  'T50B96A Underdosing of other viral vaccines, initial encounter',
  'T50B96D Underdosing of other viral vaccines, subsequent encounter',
  'T50B96S Underdosing of other viral vaccines, sequela',
  'T50Z11A Poisoning by immunoglobulin, accidental (unintentional), initial encounter',
  'T50Z11D Poisoning by immunoglobulin, accidental (unintentional), subsequent encounter',
  'T50Z11S Poisoning by immunoglobulin, accidental (unintentional), sequela',
  'T50Z12A Poisoning by immunoglobulin, intentional self-harm, initial encounter',
  'T50Z12D Poisoning by immunoglobulin, intentional self-harm, subsequent encounter',
  'T50Z12S Poisoning by immunoglobulin, intentional self-harm, sequela',
  'T50Z13A Poisoning by immunoglobulin, assault, initial encounter',
  'T50Z13D Poisoning by immunoglobulin, assault, subsequent encounter',
  'T50Z13S Poisoning by immunoglobulin, assault, sequela',
  'T50Z14A Poisoning by immunoglobulin, undetermined, initial encounter',
  'T50Z14D Poisoning by immunoglobulin, undetermined, subsequent encounter',
  'T50Z14S Poisoning by immunoglobulin, undetermined, sequela',
  'T50Z15A Adverse effect of immunoglobulin, initial encounter',
  'T50Z15D Adverse effect of immunoglobulin, subsequent encounter',
  'T50Z15S Adverse effect of immunoglobulin, sequela',
  'T50Z16A Underdosing of immunoglobulin, initial encounter',
  'T50Z16D Underdosing of immunoglobulin, subsequent encounter',
  'T50Z16S Underdosing of immunoglobulin, sequela',
  'T50Z91A Poisoning by other vaccines and biological substances, accidental (unintentional), initial',
  'encounter',
  'T50Z91D Poisoning by other vaccines and biological substances, accidental (unintentional), subsequent',
  'encounter',
  'T50Z91S Poisoning by other vaccines and biological substances, accidental (unintentional), sequela',
  'T50Z92A Poisoning by other vaccines and biological substances, intentional self-harm, initial encounter',
  'T50Z92D Poisoning by other vaccines and biological substances, intentional self-harm, subsequent',
  'encounter',
  'T50Z92S Poisoning by other vaccines and biological substances, intentional self-harm, sequela',
  'T50Z93A Poisoning by other vaccines and biological substances, assault, initial encounter',
  'T50Z93D Poisoning by other vaccines and biological substances, assault, subsequent encounter',
  'T50Z93S Poisoning by other vaccines and biological substances, assault, sequela',
  'T50Z94A Poisoning by other vaccines and biological substances, undetermined, initial encounter',
  'T50Z94D Poisoning by other vaccines and biological substances, undetermined, subsequent encounter',
  'T50Z94S Poisoning by other vaccines and biological substances, undetermined, sequela',
  'T50Z95A Adverse effect of other vaccines and biological substances, initial encounter',
  'T50Z95D Adverse effect of other vaccines and biological substances, subsequent encounter',
  'T50Z95S Adverse effect of other vaccines and biological substances, sequela',
  'T50Z96A Underdosing of other vaccines and biological substances, initial encounter',
  'T50Z96D Underdosing of other vaccines and biological substances, subsequent encounter',
  'T50Z96S Underdosing of other vaccines and biological substances, sequela',
  'T50901A Poisoning by unspecified drugs, medicaments and biological substances, accidental',
  '(unintentional), initial encounter',
  'T50901D Poisoning by unspecified drugs, medicaments and biological substances, accidental',
  '(unintentional), subsequent encounter',
  'T50901S Poisoning by unspecified drugs, medicaments and biological substances, accidental',
  '(unintentional), sequela',
  'T50902A Poisoning by unspecified drugs, medicaments and biological substances, intentional self-harm,',
  'initial encounter',
  'T50902D Poisoning by unspecified drugs, medicaments and biological substances, intentional self-harm,',
  'subsequent encounter',
  'T50902S Poisoning by unspecified drugs, medicaments and biological substances, intentional self-harm,',
  'sequela',
  'T50903A Poisoning by unspecified drugs, medicaments and biological substances, assault, initial',
  'encounter',
  'T50903D Poisoning by unspecified drugs, medicaments and biological substances, assault, subsequent',
  'encounter',
  'T50903S Poisoning by unspecified drugs, medicaments and biological substances, assault, sequela',
  'T50904A Poisoning by unspecified drugs, medicaments and biological substances, undetermined, initial',
  'encounter',
  'T50904D Poisoning by unspecified drugs, medicaments and biological substances, undetermined,',
  'subsequent encounter',
  'T50904S Poisoning by unspecified drugs, medicaments and biological substances, undetermined,',
  'sequela',
  'T50905A Adverse effect of unspecified drugs, medicaments and biological substances, initial encounter',
  'T50905D Adverse effect of unspecified drugs, medicaments and biological substances, subsequent',
  'encounter',
  'T50905S Adverse effect of unspecified drugs, medicaments and biological substances, sequela',
  'T50906A Underdosing of unspecified drugs, medicaments and biological substances, initial encounter',
  'T50906D Underdosing of unspecified drugs, medicaments and biological substances, subsequent',
  'encounter',
  'T50906S Underdosing of unspecified drugs, medicaments and biological substances, sequela',
  'T50991A Poisoning by other drugs, medicaments and biological substances, accidental (unintentional),',
  'initial encounter',
  'T50991D Poisoning by other drugs, medicaments and biological substances, accidental (unintentional),',
  'subsequent encounter',
  'T50991S Poisoning by other drugs, medicaments and biological substances, accidental (unintentional),',
  'sequela',
  'T50992A Poisoning by other drugs, medicaments and biological substances, intentional self-harm, initial',
  'encounter',
  'T50992D Poisoning by other drugs, medicaments and biological substances, intentional self-harm,',
  'subsequent encounter',
  'T50992S Poisoning by other drugs, medicaments and biological substances, intentional self-harm,',
  'sequela',
  'T50993A Poisoning by other drugs, medicaments and biological substances, assault, initial encounter',
  'T50993D Poisoning by other drugs, medicaments and biological substances, assault, subsequent',
  'encounter',
  'T50993S Poisoning by other drugs, medicaments and biological substances, assault, sequela',
  'T50994A Poisoning by other drugs, medicaments and biological substances, undetermined, initial',
  'encounter',
  'T50994D Poisoning by other drugs, medicaments and biological substances, undetermined, subsequent',
  'encounter',
  'T50994S Poisoning by other drugs, medicaments and biological substances, undetermined, sequela',
  'T50995A Adverse effect of other drugs, medicaments and biological substances, initial encounter',
  'T50995D Adverse effect of other drugs, medicaments and biological substances, subsequent encounter',
  'T50995S Adverse effect of other drugs, medicaments and biological substances, sequela',
  'T50996A Underdosing of other drugs, medicaments and biological substances, initial encounter',
  'T50996D Underdosing of other drugs, medicaments and biological substances, subsequent encounter',
  'T50996S Underdosing of other drugs, medicaments and biological substances, sequela',
  'T510X1A Toxic effect of ethanol, accidental (unintentional), initial encounter',
  'T510X1D Toxic effect of ethanol, accidental (unintentional), subsequent encounter',
  'T510X1S Toxic effect of ethanol, accidental (unintentional), sequela',
  'T510X2A Toxic effect of ethanol, intentional self-harm, initial encounter',
  'T510X2D Toxic effect of ethanol, intentional self-harm, subsequent encounter',
  'T510X2S Toxic effect of ethanol, intentional self-harm, sequela',
  'T510X3A Toxic effect of ethanol, assault, initial encounter',
  'T510X3D Toxic effect of ethanol, assault, subsequent encounter',
  'T510X3S Toxic effect of ethanol, assault, sequela',
  'T510X4A Toxic effect of ethanol, undetermined, initial encounter',
  'T510X4D Toxic effect of ethanol, undetermined, subsequent encounter',
  'T510X4S Toxic effect of ethanol, undetermined, sequela',
  'T511X1A Toxic effect of methanol, accidental (unintentional), initial encounter',
  'T511X1D Toxic effect of methanol, accidental (unintentional), subsequent encounter',
  'T511X1S Toxic effect of methanol, accidental (unintentional), sequela',
  'T511X2A Toxic effect of methanol, intentional self-harm, initial encounter',
  'T511X2D Toxic effect of methanol, intentional self-harm, subsequent encounter',
  'T511X2S Toxic effect of methanol, intentional self-harm, sequela',
  'T511X3A Toxic effect of methanol, assault, initial encounter',
  'T511X3D Toxic effect of methanol, assault, subsequent encounter',
  'T511X3S Toxic effect of methanol, assault, sequela',
  'T511X4A Toxic effect of methanol, undetermined, initial encounter',
  'T511X4D Toxic effect of methanol, undetermined, subsequent encounter',
  'T511X4S Toxic effect of methanol, undetermined, sequela',
  'T512X1A Toxic effect of 2-Propanol, accidental (unintentional), initial encounter',
  'T512X1D Toxic effect of 2-Propanol, accidental (unintentional), subsequent encounter',
  'T512X1S Toxic effect of 2-Propanol, accidental (unintentional), sequela',
  'T512X2A Toxic effect of 2-Propanol, intentional self-harm, initial encounter',
  'T512X2D Toxic effect of 2-Propanol, intentional self-harm, subsequent encounter',
  'T512X2S Toxic effect of 2-Propanol, intentional self-harm, sequela',
  'T512X3A Toxic effect of 2-Propanol, assault, initial encounter',
  'T512X3D Toxic effect of 2-Propanol, assault, subsequent encounter',
  'T512X3S Toxic effect of 2-Propanol, assault, sequela',
  'T512X4A Toxic effect of 2-Propanol, undetermined, initial encounter',
  'T512X4D Toxic effect of 2-Propanol, undetermined, subsequent encounter',
  'T512X4S Toxic effect of 2-Propanol, undetermined, sequela',
  'T513X1A Toxic effect of fusel oil, accidental (unintentional), initial encounter',
  'T513X1D Toxic effect of fusel oil, accidental (unintentional), subsequent encounter',
  'T513X1S Toxic effect of fusel oil, accidental (unintentional), sequela',
  'T513X2A Toxic effect of fusel oil, intentional self-harm, initial encounter',
  'T513X2D Toxic effect of fusel oil, intentional self-harm, subsequent encounter',
  'T513X2S Toxic effect of fusel oil, intentional self-harm, sequela',
  'T513X3A Toxic effect of fusel oil, assault, initial encounter',
  'T513X3D Toxic effect of fusel oil, assault, subsequent encounter',
  'T513X3S Toxic effect of fusel oil, assault, sequela',
  'T513X4A Toxic effect of fusel oil, undetermined, initial encounter',
  'T513X4D Toxic effect of fusel oil, undetermined, subsequent encounter',
  'T513X4S Toxic effect of fusel oil, undetermined, sequela',
  'T518X1A Toxic effect of other alcohols, accidental (unintentional), initial encounter',
  'T518X1D Toxic effect of other alcohols, accidental (unintentional), subsequent encounter',
  'T518X1S Toxic effect of other alcohols, accidental (unintentional), sequela',
  'T518X2A Toxic effect of other alcohols, intentional self-harm, initial encounter',
  'T518X2D Toxic effect of other alcohols, intentional self-harm, subsequent encounter',
  'T518X2S Toxic effect of other alcohols, intentional self-harm, sequela',
  'T518X3A Toxic effect of other alcohols, assault, initial encounter',
  'T518X3D Toxic effect of other alcohols, assault, subsequent encounter',
  'T518X3S Toxic effect of other alcohols, assault, sequela',
  'T518X4A Toxic effect of other alcohols, undetermined, initial encounter',
  'T518X4D Toxic effect of other alcohols, undetermined, subsequent encounter',
  'T518X4S Toxic effect of other alcohols, undetermined, sequela',
  'T5191XA Toxic effect of unspecified alcohol, accidental (unintentional), initial encounter',
  'T5191XD Toxic effect of unspecified alcohol, accidental (unintentional), subsequent encounter',
  'T5191XS Toxic effect of unspecified alcohol, accidental (unintentional), sequela',
  'T5192XA Toxic effect of unspecified alcohol, intentional self-harm, initial encounter',
  'T5192XD Toxic effect of unspecified alcohol, intentional self-harm, subsequent encounter',
  'T5192XS Toxic effect of unspecified alcohol, intentional self-harm, sequela',
  'T5193XA Toxic effect of unspecified alcohol, assault, initial encounter',
  'T5193XD Toxic effect of unspecified alcohol, assault, subsequent encounter',
  'T5193XS Toxic effect of unspecified alcohol, assault, sequela',
  'T5194XA Toxic effect of unspecified alcohol, undetermined, initial encounter',
  'T5194XD Toxic effect of unspecified alcohol, undetermined, subsequent encounter',
  'T5194XS Toxic effect of unspecified alcohol, undetermined, sequela',
  'T520X1A Toxic effect of petroleum products, accidental (unintentional), initial encounter',
  'T520X1D Toxic effect of petroleum products, accidental (unintentional), subsequent encounter',
  'T520X1S Toxic effect of petroleum products, accidental (unintentional), sequela',
  'T520X2A Toxic effect of petroleum products, intentional self-harm, initial encounter',
  'T520X2D Toxic effect of petroleum products, intentional self-harm, subsequent encounter',
  'T520X2S Toxic effect of petroleum products, intentional self-harm, sequela',
  'T520X3A Toxic effect of petroleum products, assault, initial encounter',
  'T520X3D Toxic effect of petroleum products, assault, subsequent encounter',
  'T520X3S Toxic effect of petroleum products, assault, sequela',
  'T520X4A Toxic effect of petroleum products, undetermined, initial encounter',
  'T520X4D Toxic effect of petroleum products, undetermined, subsequent encounter',
  'T520X4S Toxic effect of petroleum products, undetermined, sequela',
  'T521X1A Toxic effect of benzene, accidental (unintentional), initial encounter',
  'T521X1D Toxic effect of benzene, accidental (unintentional), subsequent encounter',
  'T521X1S Toxic effect of benzene, accidental (unintentional), sequela',
  'T521X2A Toxic effect of benzene, intentional self-harm, initial encounter',
  'T521X2D Toxic effect of benzene, intentional self-harm, subsequent encounter',
  'T521X2S Toxic effect of benzene, intentional self-harm, sequela',
  'T521X3A Toxic effect of benzene, assault, initial encounter',
  'T521X3D Toxic effect of benzene, assault, subsequent encounter',
  'T521X3S Toxic effect of benzene, assault, sequela',
  'T521X4A Toxic effect of benzene, undetermined, initial encounter',
  'T521X4D Toxic effect of benzene, undetermined, subsequent encounter',
  'T521X4S Toxic effect of benzene, undetermined, sequela',
  'T522X1A Toxic effect of homologues of benzene, accidental (unintentional), initial encounter',
  'T522X1D Toxic effect of homologues of benzene, accidental (unintentional), subsequent encounter',
  'T522X1S Toxic effect of homologues of benzene, accidental (unintentional), sequela',
  'T522X2A Toxic effect of homologues of benzene, intentional self-harm, initial encounter',
  'T522X2D Toxic effect of homologues of benzene, intentional self-harm, subsequent encounter',
  'T522X2S Toxic effect of homologues of benzene, intentional self-harm, sequela',
  'T522X3A Toxic effect of homologues of benzene, assault, initial encounter',
  'T522X3D Toxic effect of homologues of benzene, assault, subsequent encounter',
  'T522X3S Toxic effect of homologues of benzene, assault, sequela',
  'T522X4A Toxic effect of homologues of benzene, undetermined, initial encounter',
  'T522X4D Toxic effect of homologues of benzene, undetermined, subsequent encounter',
  'T522X4S Toxic effect of homologues of benzene, undetermined, sequela',
  'T523X1A Toxic effect of glycols, accidental (unintentional), initial encounter',
  'T523X1D Toxic effect of glycols, accidental (unintentional), subsequent encounter',
  'T523X1S Toxic effect of glycols, accidental (unintentional), sequela',
  'T523X2A Toxic effect of glycols, intentional self-harm, initial encounter',
  'T523X2D Toxic effect of glycols, intentional self-harm, subsequent encounter',
  'T523X2S Toxic effect of glycols, intentional self-harm, sequela',
  'T523X3A Toxic effect of glycols, assault, initial encounter',
  'T523X3D Toxic effect of glycols, assault, subsequent encounter',
  'T523X3S Toxic effect of glycols, assault, sequela',
  'T523X4A Toxic effect of glycols, undetermined, initial encounter',
  'T523X4D Toxic effect of glycols, undetermined, subsequent encounter',
  'T523X4S Toxic effect of glycols, undetermined, sequela',
  'T524X1A Toxic effect of ketones, accidental (unintentional), initial encounter',
  'T524X1D Toxic effect of ketones, accidental (unintentional), subsequent encounter',
  'T524X1S Toxic effect of ketones, accidental (unintentional), sequela',
  'T524X2A Toxic effect of ketones, intentional self-harm, initial encounter',
  'T524X2D Toxic effect of ketones, intentional self-harm, subsequent encounter',
  'T524X2S Toxic effect of ketones, intentional self-harm, sequela',
  'T524X3A Toxic effect of ketones, assault, initial encounter',
  'T524X3D Toxic effect of ketones, assault, subsequent encounter',
  'T524X3S Toxic effect of ketones, assault, sequela',
  'T524X4A Toxic effect of ketones, undetermined, initial encounter',
  'T524X4D Toxic effect of ketones, undetermined, subsequent encounter',
  'T524X4S Toxic effect of ketones, undetermined, sequela',
  'T528X1A Toxic effect of other organic solvents, accidental (unintentional), initial encounter',
  'T528X1D Toxic effect of other organic solvents, accidental (unintentional), subsequent encounter',
  'T528X1S Toxic effect of other organic solvents, accidental (unintentional), sequela',
  'T528X2A Toxic effect of other organic solvents, intentional self-harm, initial encounter',
  'T528X2D Toxic effect of other organic solvents, intentional self-harm, subsequent encounter',
  'T528X2S Toxic effect of other organic solvents, intentional self-harm, sequela',
  'T528X3A Toxic effect of other organic solvents, assault, initial encounter',
  'T528X3D Toxic effect of other organic solvents, assault, subsequent encounter',
  'T528X3S Toxic effect of other organic solvents, assault, sequela',
  'T528X4A Toxic effect of other organic solvents, undetermined, initial encounter',
  'T528X4D Toxic effect of other organic solvents, undetermined, subsequent encounter',
  'T528X4S Toxic effect of other organic solvents, undetermined, sequela',
  'T5291XA Toxic effect of unspecified organic solvent, accidental (unintentional), initial encounter',
  'T5291XD Toxic effect of unspecified organic solvent, accidental (unintentional), subsequent encounter',
  'T5291XS Toxic effect of unspecified organic solvent, accidental (unintentional), sequela',
  'T5292XA Toxic effect of unspecified organic solvent, intentional self-harm, initial encounter',
  'T5292XD Toxic effect of unspecified organic solvent, intentional self-harm, subsequent encounter',
  'T5292XS Toxic effect of unspecified organic solvent, intentional self-harm, sequela',
  'T5293XA Toxic effect of unspecified organic solvent, assault, initial encounter',
  'T5293XD Toxic effect of unspecified organic solvent, assault, subsequent encounter',
  'T5293XS Toxic effect of unspecified organic solvent, assault, sequela',
  'T5294XA Toxic effect of unspecified organic solvent, undetermined, initial encounter',
  'T5294XD Toxic effect of unspecified organic solvent, undetermined, subsequent encounter',
  'T5294XS Toxic effect of unspecified organic solvent, undetermined, sequela',
  'T530X1A Toxic effect of carbon tetrachloride, accidental (unintentional), initial encounter',
  'T530X1D Toxic effect of carbon tetrachloride, accidental (unintentional), subsequent encounter',
  'T530X1S Toxic effect of carbon tetrachloride, accidental (unintentional), sequela',
  'T530X2A Toxic effect of carbon tetrachloride, intentional self-harm, initial encounter',
  'T530X2D Toxic effect of carbon tetrachloride, intentional self-harm, subsequent encounter',
  'T530X2S Toxic effect of carbon tetrachloride, intentional self-harm, sequela',
  'T530X3A Toxic effect of carbon tetrachloride, assault, initial encounter',
  'T530X3D Toxic effect of carbon tetrachloride, assault, subsequent encounter',
  'T530X3S Toxic effect of carbon tetrachloride, assault, sequela',
  'T530X4A Toxic effect of carbon tetrachloride, undetermined, initial encounter',
  'T530X4D Toxic effect of carbon tetrachloride, undetermined, subsequent encounter',
  'T530X4S Toxic effect of carbon tetrachloride, undetermined, sequela',
  'T531X1A Toxic effect of chloroform, accidental (unintentional), initial encounter',
  'T531X1D Toxic effect of chloroform, accidental (unintentional), subsequent encounter',
  'T531X1S Toxic effect of chloroform, accidental (unintentional), sequela',
  'T531X2A Toxic effect of chloroform, intentional self-harm, initial encounter',
  'T531X2D Toxic effect of chloroform, intentional self-harm, subsequent encounter',
  'T531X2S Toxic effect of chloroform, intentional self-harm, sequela',
  'T531X3A Toxic effect of chloroform, assault, initial encounter',
  'T531X3D Toxic effect of chloroform, assault, subsequent encounter',
  'T531X3S Toxic effect of chloroform, assault, sequela',
  'T531X4A Toxic effect of chloroform, undetermined, initial encounter',
  'T531X4D Toxic effect of chloroform, undetermined, subsequent encounter',
  'T531X4S Toxic effect of chloroform, undetermined, sequela',
  'T532X1A Toxic effect of trichloroethylene, accidental (unintentional), initial encounter',
  'T532X1D Toxic effect of trichloroethylene, accidental (unintentional), subsequent encounter',
  'T532X1S Toxic effect of trichloroethylene, accidental (unintentional), sequela',
  'T532X2A Toxic effect of trichloroethylene, intentional self-harm, initial encounter',
  'T532X2D Toxic effect of trichloroethylene, intentional self-harm, subsequent encounter',
  'T532X2S Toxic effect of trichloroethylene, intentional self-harm, sequela',
  'T532X3A Toxic effect of trichloroethylene, assault, initial encounter',
  'T532X3D Toxic effect of trichloroethylene, assault, subsequent encounter',
  'T532X3S Toxic effect of trichloroethylene, assault, sequela',
  'T532X4A Toxic effect of trichloroethylene, undetermined, initial encounter',
  'T532X4D Toxic effect of trichloroethylene, undetermined, subsequent encounter',
  'T532X4S Toxic effect of trichloroethylene, undetermined, sequela',
  'T533X1A Toxic effect of tetrachloroethylene, accidental (unintentional), initial encounter',
  'T533X1D Toxic effect of tetrachloroethylene, accidental (unintentional), subsequent encounter',
  'T533X1S Toxic effect of tetrachloroethylene, accidental (unintentional), sequela',
  'T533X2A Toxic effect of tetrachloroethylene, intentional self-harm, initial encounter',
  'T533X2D Toxic effect of tetrachloroethylene, intentional self-harm, subsequent encounter',
  'T533X2S Toxic effect of tetrachloroethylene, intentional self-harm, sequela',
  'T533X3A Toxic effect of tetrachloroethylene, assault, initial encounter',
  'T533X3D Toxic effect of tetrachloroethylene, assault, subsequent encounter',
  'T533X3S Toxic effect of tetrachloroethylene, assault, sequela',
  'T533X4A Toxic effect of tetrachloroethylene, undetermined, initial encounter',
  'T533X4D Toxic effect of tetrachloroethylene, undetermined, subsequent encounter',
  'T533X4S Toxic effect of tetrachloroethylene, undetermined, sequela',
  'T534X1A Toxic effect of dichloromethane, accidental (unintentional), initial encounter',
  'T534X1D Toxic effect of dichloromethane, accidental (unintentional), subsequent encounter',
  'T534X1S Toxic effect of dichloromethane, accidental (unintentional), sequela',
  'T534X2A Toxic effect of dichloromethane, intentional self-harm, initial encounter',
  'T534X2D Toxic effect of dichloromethane, intentional self-harm, subsequent encounter',
  'T534X2S Toxic effect of dichloromethane, intentional self-harm, sequela',
  'T534X3A Toxic effect of dichloromethane, assault, initial encounter',
  'T534X3D Toxic effect of dichloromethane, assault, subsequent encounter',
  'T534X3S Toxic effect of dichloromethane, assault, sequela',
  'T534X4A Toxic effect of dichloromethane, undetermined, initial encounter',
  'T534X4D Toxic effect of dichloromethane, undetermined, subsequent encounter',
  'T534X4S Toxic effect of dichloromethane, undetermined, sequela',
  'T535X1A Toxic effect of chlorofluorocarbons, accidental (unintentional), initial encounter',
  'T535X1D Toxic effect of chlorofluorocarbons, accidental (unintentional), subsequent encounter',
  'T535X1S Toxic effect of chlorofluorocarbons, accidental (unintentional), sequela',
  'T535X2A Toxic effect of chlorofluorocarbons, intentional self-harm, initial encounter',
  'T535X2D Toxic effect of chlorofluorocarbons, intentional self-harm, subsequent encounter',
  'T535X2S Toxic effect of chlorofluorocarbons, intentional self-harm, sequela',
  'T535X3A Toxic effect of chlorofluorocarbons, assault, initial encounter',
  'T535X3D Toxic effect of chlorofluorocarbons, assault, subsequent encounter',
  'T535X3S Toxic effect of chlorofluorocarbons, assault, sequela',
  'T535X4A Toxic effect of chlorofluorocarbons, undetermined, initial encounter',
  'T535X4D Toxic effect of chlorofluorocarbons, undetermined, subsequent encounter',
  'T535X4S Toxic effect of chlorofluorocarbons, undetermined, sequela',
  'T536X1A Toxic effect of other halogen derivatives of aliphatic hydrocarbons, accidental (unintentional),',
  'initial encounter',
  'T536X1D Toxic effect of other halogen derivatives of aliphatic hydrocarbons, accidental (unintentional),',
  'subsequent encounter',
  'T536X1S Toxic effect of other halogen derivatives of aliphatic hydrocarbons, accidental (unintentional),',
  'sequela',
  'T536X2A Toxic effect of other halogen derivatives of aliphatic hydrocarbons, intentional self-harm, initial',
  'encounter',
  'T536X2D Toxic effect of other halogen derivatives of aliphatic hydrocarbons, intentional self-harm,',
  'subsequent encounter',
  'T536X2S Toxic effect of other halogen derivatives of aliphatic hydrocarbons, intentional self-harm,',
  'sequela',
  'T536X3A Toxic effect of other halogen derivatives of aliphatic hydrocarbons, assault, initial encounter',
  'T536X3D Toxic effect of other halogen derivatives of aliphatic hydrocarbons, assault, subsequent',
  'encounter',
  'T536X3S Toxic effect of other halogen derivatives of aliphatic hydrocarbons, assault, sequela',
  'T536X4A Toxic effect of other halogen derivatives of aliphatic hydrocarbons, undetermined, initial',
  'encounter',
  'T536X4D Toxic effect of other halogen derivatives of aliphatic hydrocarbons, undetermined, subsequent',
  'encounter',
  'T536X4S Toxic effect of other halogen derivatives of aliphatic hydrocarbons, undetermined, sequela',
  'T537X1A Toxic effect of other halogen derivatives of aromatic hydrocarbons, accidental (unintentional),',
  'initial encounter',
  'T537X1D Toxic effect of other halogen derivatives of aromatic hydrocarbons, accidental (unintentional),',
  'subsequent encounter',
  'T537X1S Toxic effect of other halogen derivatives of aromatic hydrocarbons, accidental (unintentional),',
  'sequela',
  'T537X2A Toxic effect of other halogen derivatives of aromatic hydrocarbons, intentional self-harm,',
  'initial encounter',
  'T537X2D Toxic effect of other halogen derivatives of aromatic hydrocarbons, intentional self-harm,',
  'subsequent encounter',
  'T537X2S Toxic effect of other halogen derivatives of aromatic hydrocarbons, intentional self-harm,',
  'sequela',
  'T537X3A Toxic effect of other halogen derivatives of aromatic hydrocarbons, assault, initial encounter',
  'T537X3D Toxic effect of other halogen derivatives of aromatic hydrocarbons, assault, subsequent',
  'encounter',
  'T537X3S Toxic effect of other halogen derivatives of aromatic hydrocarbons, assault, sequela',
  'T537X4A Toxic effect of other halogen derivatives of aromatic hydrocarbons, undetermined, initial',
  'encounter',
  'T537X4D Toxic effect of other halogen derivatives of aromatic hydrocarbons, undetermined, subsequent',
  'encounter',
  'T537X4S Toxic effect of other halogen derivatives of aromatic hydrocarbons, undetermined, sequela',
  'T5391XA Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'accidental (unintentional), initial encounter',
  'T5391XD Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'accidental (unintentional), subsequent encounter',
  'T5391XS Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'accidental (unintentional), sequela',
  'T5392XA Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'intentional self-harm, initial encounter',
  'T5392XD Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'intentional self-harm, subsequent encounter',
  'T5392XS Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'intentional self-harm, sequela',
  'T5393XA Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons, assault,',
  'initial encounter',
  'T5393XD Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons, assault,',
  'subsequent encounter',
  'T5393XS Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons, assault,',
  'sequela',
  'T5394XA Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'undetermined, initial encounter',
  'T5394XD Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'undetermined, subsequent encounter',
  'T5394XS Toxic effect of unspecified halogen derivatives of aliphatic and aromatic hydrocarbons,',
  'undetermined, sequela',
  'T540X1A Toxic effect of phenol and phenol homologues, accidental (unintentional), initial encounter',
  'T540X1D Toxic effect of phenol and phenol homologues, accidental (unintentional), subsequent',
  'encounter',
  'T540X1S Toxic effect of phenol and phenol homologues, accidental (unintentional), sequela',
  'T540X2A Toxic effect of phenol and phenol homologues, intentional self-harm, initial encounter',
  'T540X2D Toxic effect of phenol and phenol homologues, intentional self-harm, subsequent encounter',
  'T540X2S Toxic effect of phenol and phenol homologues, intentional self-harm, sequela',
  'T540X3A Toxic effect of phenol and phenol homologues, assault, initial encounter',
  'T540X3D Toxic effect of phenol and phenol homologues, assault, subsequent encounter',
  'T540X3S Toxic effect of phenol and phenol homologues, assault, sequela',
  'T540X4A Toxic effect of phenol and phenol homologues, undetermined, initial encounter',
  'T540X4D Toxic effect of phenol and phenol homologues, undetermined, subsequent encounter',
  'T540X4S Toxic effect of phenol and phenol homologues, undetermined, sequela',
  'T541X1A Toxic effect of other corrosive organic compounds, accidental (unintentional), initial encounter',
  'T541X1D Toxic effect of other corrosive organic compounds, accidental (unintentional), subsequent',
  'encounter',
  'T541X1S Toxic effect of other corrosive organic compounds, accidental (unintentional), sequela',
  'T541X2A Toxic effect of other corrosive organic compounds, intentional self-harm, initial encounter',
  'T541X2D Toxic effect of other corrosive organic compounds, intentional self-harm, subsequent',
  'encounter',
  'T541X2S Toxic effect of other corrosive organic compounds, intentional self-harm, sequela',
  'T541X3A Toxic effect of other corrosive organic compounds, assault, initial encounter',
  'T541X3D Toxic effect of other corrosive organic compounds, assault, subsequent encounter',
  'T541X3S Toxic effect of other corrosive organic compounds, assault, sequela',
  'T541X4A Toxic effect of other corrosive organic compounds, undetermined, initial encounter',
  'T541X4D Toxic effect of other corrosive organic compounds, undetermined, subsequent encounter',
  'T541X4S Toxic effect of other corrosive organic compounds, undetermined, sequela',
  'T542X1A Toxic effect of corrosive acids and acid-like substances, accidental (unintentional), initial',
  'encounter',
  'T542X1D Toxic effect of corrosive acids and acid-like substances, accidental (unintentional), subsequent',
  'encounter',
  'T542X1S Toxic effect of corrosive acids and acid-like substances, accidental (unintentional), sequela',
  'T542X2A Toxic effect of corrosive acids and acid-like substances, intentional self-harm, initial encounter',
  'T542X2D Toxic effect of corrosive acids and acid-like substances, intentional self-harm, subsequent',
  'encounter',
  'T542X2S Toxic effect of corrosive acids and acid-like substances, intentional self-harm, sequela',
  'T542X3A Toxic effect of corrosive acids and acid-like substances, assault, initial encounter',
  'T542X3D Toxic effect of corrosive acids and acid-like substances, assault, subsequent encounter',
  'T542X3S Toxic effect of corrosive acids and acid-like substances, assault, sequela',
  'T542X4A Toxic effect of corrosive acids and acid-like substances, undetermined, initial encounter',
  'T542X4D Toxic effect of corrosive acids and acid-like substances, undetermined, subsequent encounter',
  'T542X4S Toxic effect of corrosive acids and acid-like substances, undetermined, sequela',
  'T543X1A Toxic effect of corrosive alkalis and alkali-like substances, accidental (unintentional), initial',
  'encounter',
  'T543X1D Toxic effect of corrosive alkalis and alkali-like substances, accidental (unintentional),',
  'subsequent encounter',
  'T543X1S Toxic effect of corrosive alkalis and alkali-like substances, accidental (unintentional), sequela',
  'T543X2A Toxic effect of corrosive alkalis and alkali-like substances, intentional self-harm, initial',
  'encounter',
  'T543X2D Toxic effect of corrosive alkalis and alkali-like substances, intentional self-harm, subsequent',
  'encounter',
  'T543X2S Toxic effect of corrosive alkalis and alkali-like substances, intentional self-harm, sequela',
  'T543X3A Toxic effect of corrosive alkalis and alkali-like substances, assault, initial encounter',
  'T543X3D Toxic effect of corrosive alkalis and alkali-like substances, assault, subsequent encounter',
  'T543X3S Toxic effect of corrosive alkalis and alkali-like substances, assault, sequela',
  'T543X4A Toxic effect of corrosive alkalis and alkali-like substances, undetermined, initial encounter',
  'T543X4D Toxic effect of corrosive alkalis and alkali-like substances, undetermined, subsequent',
  'encounter',
  'T543X4S Toxic effect of corrosive alkalis and alkali-like substances, undetermined, sequela',
  'T5491XA Toxic effect of unspecified corrosive substance, accidental (unintentional), initial encounter',
  'T5491XD Toxic effect of unspecified corrosive substance, accidental (unintentional), subsequent',
  'encounter',
  'T5491XS Toxic effect of unspecified corrosive substance, accidental (unintentional), sequela',
  'T5492XA Toxic effect of unspecified corrosive substance, intentional self-harm, initial encounter',
  'T5492XD Toxic effect of unspecified corrosive substance, intentional self-harm, subsequent encounter',
  'T5492XS Toxic effect of unspecified corrosive substance, intentional self-harm, sequela',
  'T5493XA Toxic effect of unspecified corrosive substance, assault, initial encounter',
  'T5493XD Toxic effect of unspecified corrosive substance, assault, subsequent encounter',
  'T5493XS Toxic effect of unspecified corrosive substance, assault, sequela',
  'T5494XA Toxic effect of unspecified corrosive substance, undetermined, initial encounter',
  'T5494XD Toxic effect of unspecified corrosive substance, undetermined, subsequent encounter',
  'T5494XS Toxic effect of unspecified corrosive substance, undetermined, sequela',
  'T550X1A Toxic effect of soaps, accidental (unintentional), initial encounter',
  'T550X1D Toxic effect of soaps, accidental (unintentional), subsequent encounter',
  'T550X1S Toxic effect of soaps, accidental (unintentional), sequela',
  'T550X2A Toxic effect of soaps, intentional self-harm, initial encounter',
  'T550X2D Toxic effect of soaps, intentional self-harm, subsequent encounter',
  'T550X2S Toxic effect of soaps, intentional self-harm, sequela',
  'T550X3A Toxic effect of soaps, assault, initial encounter',
  'T550X3D Toxic effect of soaps, assault, subsequent encounter',
  'T550X3S Toxic effect of soaps, assault, sequela',
  'T550X4A Toxic effect of soaps, undetermined, initial encounter',
  'T550X4D Toxic effect of soaps, undetermined, subsequent encounter',
  'T550X4S Toxic effect of soaps, undetermined, sequela',
  'T551X1A Toxic effect of detergents, accidental (unintentional), initial encounter',
  'T551X1D Toxic effect of detergents, accidental (unintentional), subsequent encounter',
  'T551X1S Toxic effect of detergents, accidental (unintentional), sequela',
  'T551X2A Toxic effect of detergents, intentional self-harm, initial encounter',
  'T551X2D Toxic effect of detergents, intentional self-harm, subsequent encounter',
  'T551X2S Toxic effect of detergents, intentional self-harm, sequela',
  'T551X3A Toxic effect of detergents, assault, initial encounter',
  'T551X3D Toxic effect of detergents, assault, subsequent encounter',
  'T551X3S Toxic effect of detergents, assault, sequela',
  'T551X4A Toxic effect of detergents, undetermined, initial encounter',
  'T551X4D Toxic effect of detergents, undetermined, subsequent encounter',
  'T551X4S Toxic effect of detergents, undetermined, sequela',
  'T560X1A Toxic effect of lead and its compounds, accidental (unintentional), initial encounter',
  'T560X1D Toxic effect of lead and its compounds, accidental (unintentional), subsequent encounter',
  'T560X1S Toxic effect of lead and its compounds, accidental (unintentional), sequela',
  'T560X2A Toxic effect of lead and its compounds, intentional self-harm, initial encounter',
  'T560X2D Toxic effect of lead and its compounds, intentional self-harm, subsequent encounter',
  'T560X2S Toxic effect of lead and its compounds, intentional self-harm, sequela',
  'T560X3A Toxic effect of lead and its compounds, assault, initial encounter',
  'T560X3D Toxic effect of lead and its compounds, assault, subsequent encounter',
  'T560X3S Toxic effect of lead and its compounds, assault, sequela',
  'T560X4A Toxic effect of lead and its compounds, undetermined, initial encounter',
  'T560X4D Toxic effect of lead and its compounds, undetermined, subsequent encounter',
  'T560X4S Toxic effect of lead and its compounds, undetermined, sequela',
  'T561X1A Toxic effect of mercury and its compounds, accidental (unintentional), initial encounter',
  'T561X1D Toxic effect of mercury and its compounds, accidental (unintentional), subsequent encounter',
  'T561X1S Toxic effect of mercury and its compounds, accidental (unintentional), sequela',
  'T561X2A Toxic effect of mercury and its compounds, intentional self-harm, initial encounter',
  'T561X2D Toxic effect of mercury and its compounds, intentional self-harm, subsequent encounter',
  'T561X2S Toxic effect of mercury and its compounds, intentional self-harm, sequela',
  'T561X3A Toxic effect of mercury and its compounds, assault, initial encounter',
  'T561X3D Toxic effect of mercury and its compounds, assault, subsequent encounter',
  'T561X3S Toxic effect of mercury and its compounds, assault, sequela',
  'T561X4A Toxic effect of mercury and its compounds, undetermined, initial encounter',
  'T561X4D Toxic effect of mercury and its compounds, undetermined, subsequent encounter',
  'T561X4S Toxic effect of mercury and its compounds, undetermined, sequela',
  'T562X1A Toxic effect of chromium and its compounds, accidental (unintentional), initial encounter',
  'T562X1D Toxic effect of chromium and its compounds, accidental (unintentional), subsequent',
  'encounter',
  'T562X1S Toxic effect of chromium and its compounds, accidental (unintentional), sequela',
  'T562X2A Toxic effect of chromium and its compounds, intentional self-harm, initial encounter',
  'T562X2D Toxic effect of chromium and its compounds, intentional self-harm, subsequent encounter',
  'T562X2S Toxic effect of chromium and its compounds, intentional self-harm, sequela',
  'T562X3A Toxic effect of chromium and its compounds, assault, initial encounter',
  'T562X3D Toxic effect of chromium and its compounds, assault, subsequent encounter',
  'T562X3S Toxic effect of chromium and its compounds, assault, sequela',
  'T562X4A Toxic effect of chromium and its compounds, undetermined, initial encounter',
  'T562X4D Toxic effect of chromium and its compounds, undetermined, subsequent encounter',
  'T562X4S Toxic effect of chromium and its compounds, undetermined, sequela',
  'T563X1A Toxic effect of cadmium and its compounds, accidental (unintentional), initial encounter',
  'T563X1D Toxic effect of cadmium and its compounds, accidental (unintentional), subsequent encounter',
  'T563X1S Toxic effect of cadmium and its compounds, accidental (unintentional), sequela',
  'T563X2A Toxic effect of cadmium and its compounds, intentional self-harm, initial encounter',
  'T563X2D Toxic effect of cadmium and its compounds, intentional self-harm, subsequent encounter',
  'T563X2S Toxic effect of cadmium and its compounds, intentional self-harm, sequela',
  'T563X3A Toxic effect of cadmium and its compounds, assault, initial encounter',
  'T563X3D Toxic effect of cadmium and its compounds, assault, subsequent encounter',
  'T563X3S Toxic effect of cadmium and its compounds, assault, sequela',
  'T563X4A Toxic effect of cadmium and its compounds, undetermined, initial encounter',
  'T563X4D Toxic effect of cadmium and its compounds, undetermined, subsequent encounter',
  'T563X4S Toxic effect of cadmium and its compounds, undetermined, sequela',
  'T564X1A Toxic effect of copper and its compounds, accidental (unintentional), initial encounter',
  'T564X1D Toxic effect of copper and its compounds, accidental (unintentional), subsequent encounter',
  'T564X1S Toxic effect of copper and its compounds, accidental (unintentional), sequela',
  'T564X2A Toxic effect of copper and its compounds, intentional self-harm, initial encounter',
  'T564X2D Toxic effect of copper and its compounds, intentional self-harm, subsequent encounter',
  'T564X2S Toxic effect of copper and its compounds, intentional self-harm, sequela',
  'T564X3A Toxic effect of copper and its compounds, assault, initial encounter',
  'T564X3D Toxic effect of copper and its compounds, assault, subsequent encounter',
  'T564X3S Toxic effect of copper and its compounds, assault, sequela',
  'T564X4A Toxic effect of copper and its compounds, undetermined, initial encounter',
  'T564X4D Toxic effect of copper and its compounds, undetermined, subsequent encounter',
  'T564X4S Toxic effect of copper and its compounds, undetermined, sequela',
  'T565X1A Toxic effect of zinc and its compounds, accidental (unintentional), initial encounter',
  'T565X1D Toxic effect of zinc and its compounds, accidental (unintentional), subsequent encounter',
  'T565X1S Toxic effect of zinc and its compounds, accidental (unintentional), sequela',
  'T565X2A Toxic effect of zinc and its compounds, intentional self-harm, initial encounter',
  'T565X2D Toxic effect of zinc and its compounds, intentional self-harm, subsequent encounter',
  'T565X2S Toxic effect of zinc and its compounds, intentional self-harm, sequela',
  'T565X3A Toxic effect of zinc and its compounds, assault, initial encounter',
  'T565X3D Toxic effect of zinc and its compounds, assault, subsequent encounter',
  'T565X3S Toxic effect of zinc and its compounds, assault, sequela',
  'T565X4A Toxic effect of zinc and its compounds, undetermined, initial encounter',
  'T565X4D Toxic effect of zinc and its compounds, undetermined, subsequent encounter',
  'T565X4S Toxic effect of zinc and its compounds, undetermined, sequela',
  'T566X1A Toxic effect of tin and its compounds, accidental (unintentional), initial encounter',
  'T566X1D Toxic effect of tin and its compounds, accidental (unintentional), subsequent encounter',
  'T566X1S Toxic effect of tin and its compounds, accidental (unintentional), sequela',
  'T566X2A Toxic effect of tin and its compounds, intentional self-harm, initial encounter',
  'T566X2D Toxic effect of tin and its compounds, intentional self-harm, subsequent encounter',
  'T566X2S Toxic effect of tin and its compounds, intentional self-harm, sequela',
  'T566X3A Toxic effect of tin and its compounds, assault, initial encounter',
  'T566X3D Toxic effect of tin and its compounds, assault, subsequent encounter',
  'T566X3S Toxic effect of tin and its compounds, assault, sequela',
  'T566X4A Toxic effect of tin and its compounds, undetermined, initial encounter',
  'T566X4D Toxic effect of tin and its compounds, undetermined, subsequent encounter',
  'T566X4S Toxic effect of tin and its compounds, undetermined, sequela',
  'T567X1A Toxic effect of beryllium and its compounds, accidental (unintentional), initial encounter',
  'T567X1D Toxic effect of beryllium and its compounds, accidental (unintentional), subsequent encounter',
  'T567X1S Toxic effect of beryllium and its compounds, accidental (unintentional), sequela',
  'T567X2A Toxic effect of beryllium and its compounds, intentional self-harm, initial encounter',
  'T567X2D Toxic effect of beryllium and its compounds, intentional self-harm, subsequent encounter',
  'T567X2S Toxic effect of beryllium and its compounds, intentional self-harm, sequela',
  'T567X3A Toxic effect of beryllium and its compounds, assault, initial encounter',
  'T567X3D Toxic effect of beryllium and its compounds, assault, subsequent encounter',
  'T567X3S Toxic effect of beryllium and its compounds, assault, sequela',
  'T567X4A Toxic effect of beryllium and its compounds, undetermined, initial encounter',
  'T567X4D Toxic effect of beryllium and its compounds, undetermined, subsequent encounter',
  'T567X4S Toxic effect of beryllium and its compounds, undetermined, sequela',
  'T56811A Toxic effect of thallium, accidental (unintentional), initial encounter',
  'T56811D Toxic effect of thallium, accidental (unintentional), subsequent encounter',
  'T56811S Toxic effect of thallium, accidental (unintentional), sequela',
  'T56812A Toxic effect of thallium, intentional self-harm, initial encounter',
  'T56812D Toxic effect of thallium, intentional self-harm, subsequent encounter',
  'T56812S Toxic effect of thallium, intentional self-harm, sequela',
  'T56813A Toxic effect of thallium, assault, initial encounter',
  'T56813D Toxic effect of thallium, assault, subsequent encounter',
  'T56813S Toxic effect of thallium, assault, sequela',
  'T56814A Toxic effect of thallium, undetermined, initial encounter',
  'T56814D Toxic effect of thallium, undetermined, subsequent encounter',
  'T56814S Toxic effect of thallium, undetermined, sequela',
  'T56891A Toxic effect of other metals, accidental (unintentional), initial encounter',
  'T56891D Toxic effect of other metals, accidental (unintentional), subsequent encounter',
  'T56891S Toxic effect of other metals, accidental (unintentional), sequela',
  'T56892A Toxic effect of other metals, intentional self-harm, initial encounter',
  'T56892D Toxic effect of other metals, intentional self-harm, subsequent encounter',
  'T56892S Toxic effect of other metals, intentional self-harm, sequela',
  'T56893A Toxic effect of other metals, assault, initial encounter',
  'T56893D Toxic effect of other metals, assault, subsequent encounter',
  'T56893S Toxic effect of other metals, assault, sequela',
  'T56894A Toxic effect of other metals, undetermined, initial encounter',
  'T56894D Toxic effect of other metals, undetermined, subsequent encounter',
  'T56894S Toxic effect of other metals, undetermined, sequela',
  'T5691XA Toxic effect of unspecified metal, accidental (unintentional), initial encounter',
  'T5691XD Toxic effect of unspecified metal, accidental (unintentional), subsequent encounter',
  'T5691XS Toxic effect of unspecified metal, accidental (unintentional), sequela',
  'T5692XA Toxic effect of unspecified metal, intentional self-harm, initial encounter',
  'T5692XD Toxic effect of unspecified metal, intentional self-harm, subsequent encounter',
  'T5692XS Toxic effect of unspecified metal, intentional self-harm, sequela',
  'T5693XA Toxic effect of unspecified metal, assault, initial encounter',
  'T5693XD Toxic effect of unspecified metal, assault, subsequent encounter',
  'T5693XS Toxic effect of unspecified metal, assault, sequela',
  'T5694XA Toxic effect of unspecified metal, undetermined, initial encounter',
  'T5694XD Toxic effect of unspecified metal, undetermined, subsequent encounter',
  'T5694XS Toxic effect of unspecified metal, undetermined, sequela',
  'T570X1A Toxic effect of arsenic and its compounds, accidental (unintentional), initial encounter',
  'T570X1D Toxic effect of arsenic and its compounds, accidental (unintentional), subsequent encounter',
  'T570X1S Toxic effect of arsenic and its compounds, accidental (unintentional), sequela',
  'T570X2A Toxic effect of arsenic and its compounds, intentional self-harm, initial encounter',
  'T570X2D Toxic effect of arsenic and its compounds, intentional self-harm, subsequent encounter',
  'T570X2S Toxic effect of arsenic and its compounds, intentional self-harm, sequela',
  'T570X3A Toxic effect of arsenic and its compounds, assault, initial encounter',
  'T570X3D Toxic effect of arsenic and its compounds, assault, subsequent encounter',
  'T570X3S Toxic effect of arsenic and its compounds, assault, sequela',
  'T570X4A Toxic effect of arsenic and its compounds, undetermined, initial encounter',
  'T570X4D Toxic effect of arsenic and its compounds, undetermined, subsequent encounter',
  'T570X4S Toxic effect of arsenic and its compounds, undetermined, sequela',
  'T571X1A Toxic effect of phosphorus and its compounds, accidental (unintentional), initial encounter',
  'T571X1D Toxic effect of phosphorus and its compounds, accidental (unintentional), subsequent',
  'encounter',
  'T571X1S Toxic effect of phosphorus and its compounds, accidental (unintentional), sequela',
  'T571X2A Toxic effect of phosphorus and its compounds, intentional self-harm, initial encounter',
  'T571X2D Toxic effect of phosphorus and its compounds, intentional self-harm, subsequent encounter',
  'T571X2S Toxic effect of phosphorus and its compounds, intentional self-harm, sequela',
  'T571X3A Toxic effect of phosphorus and its compounds, assault, initial encounter',
  'T571X3D Toxic effect of phosphorus and its compounds, assault, subsequent encounter',
  'T571X3S Toxic effect of phosphorus and its compounds, assault, sequela',
  'T571X4A Toxic effect of phosphorus and its compounds, undetermined, initial encounter',
  'T571X4D Toxic effect of phosphorus and its compounds, undetermined, subsequent encounter',
  'T571X4S Toxic effect of phosphorus and its compounds, undetermined, sequela',
  'T572X1A Toxic effect of manganese and its compounds, accidental (unintentional), initial encounter',
  'T572X1D Toxic effect of manganese and its compounds, accidental (unintentional), subsequent',
  'encounter',
  'T572X1S Toxic effect of manganese and its compounds, accidental (unintentional), sequela',
  'T572X2A Toxic effect of manganese and its compounds, intentional self-harm, initial encounter',
  'T572X2D Toxic effect of manganese and its compounds, intentional self-harm, subsequent encounter',
  'T572X2S Toxic effect of manganese and its compounds, intentional self-harm, sequela',
  'T572X3A Toxic effect of manganese and its compounds, assault, initial encounter',
  'T572X3D Toxic effect of manganese and its compounds, assault, subsequent encounter',
  'T572X3S Toxic effect of manganese and its compounds, assault, sequela',
  'T572X4A Toxic effect of manganese and its compounds, undetermined, initial encounter',
  'T572X4D Toxic effect of manganese and its compounds, undetermined, subsequent encounter',
  'T572X4S Toxic effect of manganese and its compounds, undetermined, sequela',
  'T573X1A Toxic effect of hydrogen cyanide, accidental (unintentional), initial encounter',
  'T573X1D Toxic effect of hydrogen cyanide, accidental (unintentional), subsequent encounter',
  'T573X1S Toxic effect of hydrogen cyanide, accidental (unintentional), sequela',
  'T573X2A Toxic effect of hydrogen cyanide, intentional self-harm, initial encounter',
  'T573X2D Toxic effect of hydrogen cyanide, intentional self-harm, subsequent encounter',
  'T573X2S Toxic effect of hydrogen cyanide, intentional self-harm, sequela',
  'T573X3A Toxic effect of hydrogen cyanide, assault, initial encounter',
  'T573X3D Toxic effect of hydrogen cyanide, assault, subsequent encounter',
  'T573X3S Toxic effect of hydrogen cyanide, assault, sequela',
  'T573X4A Toxic effect of hydrogen cyanide, undetermined, initial encounter',
  'T573X4D Toxic effect of hydrogen cyanide, undetermined, subsequent encounter',
  'T573X4S Toxic effect of hydrogen cyanide, undetermined, sequela',
  'T578X1A Toxic effect of other specified inorganic substances, accidental (unintentional), initial',
  'encounter',
  'T578X1D Toxic effect of other specified inorganic substances, accidental (unintentional), subsequent',
  'encounter',
  'T578X1S Toxic effect of other specified inorganic substances, accidental (unintentional), sequela',
  'T578X2A Toxic effect of other specified inorganic substances, intentional self-harm, initial encounter',
  'T578X2D Toxic effect of other specified inorganic substances, intentional self-harm, subsequent',
  'encounter',
  'T578X2S Toxic effect of other specified inorganic substances, intentional self-harm, sequela',
  'T578X3A Toxic effect of other specified inorganic substances, assault, initial encounter',
  'T578X3D Toxic effect of other specified inorganic substances, assault, subsequent encounter',
  'T578X3S Toxic effect of other specified inorganic substances, assault, sequela',
  'T578X4A Toxic effect of other specified inorganic substances, undetermined, initial encounter',
  'T578X4D Toxic effect of other specified inorganic substances, undetermined, subsequent encounter',
  'T578X4S Toxic effect of other specified inorganic substances, undetermined, sequela',
  'T5791XA Toxic effect of unspecified inorganic substance, accidental (unintentional), initial encounter',
  'T5791XD Toxic effect of unspecified inorganic substance, accidental (unintentional), subsequent',
  'encounter',
  'T5791XS Toxic effect of unspecified inorganic substance, accidental (unintentional), sequela',
  'T5792XA Toxic effect of unspecified inorganic substance, intentional self-harm, initial encounter',
  'T5792XD Toxic effect of unspecified inorganic substance, intentional self-harm, subsequent encounter',
  'T5792XS Toxic effect of unspecified inorganic substance, intentional self-harm, sequela',
  'T5793XA Toxic effect of unspecified inorganic substance, assault, initial encounter',
  'T5793XD Toxic effect of unspecified inorganic substance, assault, subsequent encounter',
  'T5793XS Toxic effect of unspecified inorganic substance, assault, sequela',
  'T5794XA Toxic effect of unspecified inorganic substance, undetermined, initial encounter',
  'T5794XD Toxic effect of unspecified inorganic substance, undetermined, subsequent encounter',
  'T5794XS Toxic effect of unspecified inorganic substance, undetermined, sequela',
  'T5801XA Toxic effect of carbon monoxide from motor vehicle exhaust, accidental (unintentional), initial',
  'encounter',
  'T5801XD Toxic effect of carbon monoxide from motor vehicle exhaust, accidental (unintentional),',
  'subsequent encounter',
  'T5801XS Toxic effect of carbon monoxide from motor vehicle exhaust, accidental (unintentional),',
  'sequela',
  'T5802XA Toxic effect of carbon monoxide from motor vehicle exhaust, intentional self-harm, initial',
  'encounter',
  'T5802XD Toxic effect of carbon monoxide from motor vehicle exhaust, intentional self-harm,',
  'subsequent encounter',
  'T5802XS Toxic effect of carbon monoxide from motor vehicle exhaust, intentional self-harm, sequela',
  'T5803XA Toxic effect of carbon monoxide from motor vehicle exhaust, assault, initial encounter',
  'T5803XD Toxic effect of carbon monoxide from motor vehicle exhaust, assault, subsequent encounter',
  'T5803XS Toxic effect of carbon monoxide from motor vehicle exhaust, assault, sequela',
  'T5804XA Toxic effect of carbon monoxide from motor vehicle exhaust, undetermined, initial encounter',
  'T5804XD Toxic effect of carbon monoxide from motor vehicle exhaust, undetermined, subsequent',
  'encounter',
  'T5804XS Toxic effect of carbon monoxide from motor vehicle exhaust, undetermined, sequela',
  'T5811XA Toxic effect of carbon monoxide from utility gas, accidental (unintentional), initial encounter',
  'T5811XD Toxic effect of carbon monoxide from utility gas, accidental (unintentional), subsequent',
  'encounter',
  'T5811XS Toxic effect of carbon monoxide from utility gas, accidental (unintentional), sequela',
  'T5812XA Toxic effect of carbon monoxide from utility gas, intentional self-harm, initial encounter',
  'T5812XD Toxic effect of carbon monoxide from utility gas, intentional self-harm, subsequent encounter',
  'T5812XS Toxic effect of carbon monoxide from utility gas, intentional self-harm, sequela',
  'T5813XA Toxic effect of carbon monoxide from utility gas, assault, initial encounter',
  'T5813XD Toxic effect of carbon monoxide from utility gas, assault, subsequent encounter',
  'T5813XS Toxic effect of carbon monoxide from utility gas, assault, sequela',
  'T5814XA Toxic effect of carbon monoxide from utility gas, undetermined, initial encounter',
  'T5814XD Toxic effect of carbon monoxide from utility gas, undetermined, subsequent encounter',
  'T5814XS Toxic effect of carbon monoxide from utility gas, undetermined, sequela',
  'T582X1A Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'accidental (unintentional), initial encounter',
  'T582X1D Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'accidental (unintentional), subsequent encounter',
  'T582X1S Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'accidental (unintentional), sequela',
  'T582X2A Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'intentional self-harm, initial encounter',
  'T582X2D Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'intentional self-harm, subsequent encounter',
  'T582X2S Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'intentional self-harm, sequela',
  'T582X3A Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels, assault,',
  'initial encounter',
  'T582X3D Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels, assault,',
  'subsequent encounter',
  'T582X3S Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels, assault,',
  'sequela',
  'T582X4A Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'undetermined, initial encounter',
  'T582X4D Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'undetermined, subsequent encounter',
  'T582X4S Toxic effect of carbon monoxide from incomplete combustion of other domestic fuels,',
  'undetermined, sequela',
  'T588X1A Toxic effect of carbon monoxide from other source, accidental (unintentional), initial',
  'encounter',
  'T588X1D Toxic effect of carbon monoxide from other source, accidental (unintentional), subsequent',
  'encounter',
  'T588X1S Toxic effect of carbon monoxide from other source, accidental (unintentional), sequela',
  'T588X2A Toxic effect of carbon monoxide from other source, intentional self-harm, initial encounter',
  'T588X2D Toxic effect of carbon monoxide from other source, intentional self-harm, subsequent',
  'encounter',
  'T588X2S Toxic effect of carbon monoxide from other source, intentional self-harm, sequela',
  'T588X3A Toxic effect of carbon monoxide from other source, assault, initial encounter',
  'T588X3D Toxic effect of carbon monoxide from other source, assault, subsequent encounter',
  'T588X3S Toxic effect of carbon monoxide from other source, assault, sequela',
  'T588X4A Toxic effect of carbon monoxide from other source, undetermined, initial encounter',
  'T588X4D Toxic effect of carbon monoxide from other source, undetermined, subsequent encounter',
  'T588X4S Toxic effect of carbon monoxide from other source, undetermined, sequela',
  'T5891XA Toxic effect of carbon monoxide from unspecified source, accidental (unintentional), initial',
  'encounter',
  'T5891XD Toxic effect of carbon monoxide from unspecified source, accidental (unintentional),',
  'subsequent encounter',
  'T5891XS Toxic effect of carbon monoxide from unspecified source, accidental (unintentional), sequela',
  'T5892XA Toxic effect of carbon monoxide from unspecified source, intentional self-harm, initial',
  'encounter',
  'T5892XD Toxic effect of carbon monoxide from unspecified source, intentional self-harm, subsequent',
  'encounter',
  'T5892XS Toxic effect of carbon monoxide from unspecified source, intentional self-harm, sequela',
  'T5893XA Toxic effect of carbon monoxide from unspecified source, assault, initial encounter',
  'T5893XD Toxic effect of carbon monoxide from unspecified source, assault, subsequent encounter',
  'T5893XS Toxic effect of carbon monoxide from unspecified source, assault, sequela',
  'T5894XA Toxic effect of carbon monoxide from unspecified source, undetermined, initial encounter',
  'T5894XD Toxic effect of carbon monoxide from unspecified source, undetermined, subsequent',
  'encounter',
  'T5894XS Toxic effect of carbon monoxide from unspecified source, undetermined, sequela',
  'T590X1A Toxic effect of nitrogen oxides, accidental (unintentional), initial encounter',
  'T590X1D Toxic effect of nitrogen oxides, accidental (unintentional), subsequent encounter',
  'T590X1S Toxic effect of nitrogen oxides, accidental (unintentional), sequela',
  'T590X2A Toxic effect of nitrogen oxides, intentional self-harm, initial encounter',
  'T590X2D Toxic effect of nitrogen oxides, intentional self-harm, subsequent encounter',
  'T590X2S Toxic effect of nitrogen oxides, intentional self-harm, sequela',
  'T590X3A Toxic effect of nitrogen oxides, assault, initial encounter',
  'T590X3D Toxic effect of nitrogen oxides, assault, subsequent encounter',
  'T590X3S Toxic effect of nitrogen oxides, assault, sequela',
  'T590X4A Toxic effect of nitrogen oxides, undetermined, initial encounter',
  'T590X4D Toxic effect of nitrogen oxides, undetermined, subsequent encounter',
  'T590X4S Toxic effect of nitrogen oxides, undetermined, sequela',
  'T591X1A Toxic effect of sulfur dioxide, accidental (unintentional), initial encounter',
  'T591X1D Toxic effect of sulfur dioxide, accidental (unintentional), subsequent encounter',
  'T591X1S Toxic effect of sulfur dioxide, accidental (unintentional), sequela',
  'T591X2A Toxic effect of sulfur dioxide, intentional self-harm, initial encounter',
  'T591X2D Toxic effect of sulfur dioxide, intentional self-harm, subsequent encounter',
  'T591X2S Toxic effect of sulfur dioxide, intentional self-harm, sequela',
  'T591X3A Toxic effect of sulfur dioxide, assault, initial encounter',
  'T591X3D Toxic effect of sulfur dioxide, assault, subsequent encounter',
  'T591X3S Toxic effect of sulfur dioxide, assault, sequela',
  'T591X4A Toxic effect of sulfur dioxide, undetermined, initial encounter',
  'T591X4D Toxic effect of sulfur dioxide, undetermined, subsequent encounter',
  'T591X4S Toxic effect of sulfur dioxide, undetermined, sequela',
  'T592X1A Toxic effect of formaldehyde, accidental (unintentional), initial encounter',
  'T592X1D Toxic effect of formaldehyde, accidental (unintentional), subsequent encounter',
  'T592X1S Toxic effect of formaldehyde, accidental (unintentional), sequela',
  'T592X2A Toxic effect of formaldehyde, intentional self-harm, initial encounter',
  'T592X2D Toxic effect of formaldehyde, intentional self-harm, subsequent encounter',
  'T592X2S Toxic effect of formaldehyde, intentional self-harm, sequela',
  'T592X3A Toxic effect of formaldehyde, assault, initial encounter',
  'T592X3D Toxic effect of formaldehyde, assault, subsequent encounter',
  'T592X3S Toxic effect of formaldehyde, assault, sequela',
  'T592X4A Toxic effect of formaldehyde, undetermined, initial encounter',
  'T592X4D Toxic effect of formaldehyde, undetermined, subsequent encounter',
  'T592X4S Toxic effect of formaldehyde, undetermined, sequela',
  'T593X1A Toxic effect of lacrimogenic gas, accidental (unintentional), initial encounter',
  'T593X1D Toxic effect of lacrimogenic gas, accidental (unintentional), subsequent encounter',
  'T593X1S Toxic effect of lacrimogenic gas, accidental (unintentional), sequela',
  'T593X2A Toxic effect of lacrimogenic gas, intentional self-harm, initial encounter',
  'T593X2D Toxic effect of lacrimogenic gas, intentional self-harm, subsequent encounter',
  'T593X2S Toxic effect of lacrimogenic gas, intentional self-harm, sequela',
  'T593X3A Toxic effect of lacrimogenic gas, assault, initial encounter',
  'T593X3D Toxic effect of lacrimogenic gas, assault, subsequent encounter',
  'T593X3S Toxic effect of lacrimogenic gas, assault, sequela',
  'T593X4A Toxic effect of lacrimogenic gas, undetermined, initial encounter',
  'T593X4D Toxic effect of lacrimogenic gas, undetermined, subsequent encounter',
  'T593X4S Toxic effect of lacrimogenic gas, undetermined, sequela',
  'T594X1A Toxic effect of chlorine gas, accidental (unintentional), initial encounter',
  'T594X1D Toxic effect of chlorine gas, accidental (unintentional), subsequent encounter',
  'T594X1S Toxic effect of chlorine gas, accidental (unintentional), sequela',
  'T594X2A Toxic effect of chlorine gas, intentional self-harm, initial encounter',
  'T594X2D Toxic effect of chlorine gas, intentional self-harm, subsequent encounter',
  'T594X2S Toxic effect of chlorine gas, intentional self-harm, sequela',
  'T594X3A Toxic effect of chlorine gas, assault, initial encounter',
  'T594X3D Toxic effect of chlorine gas, assault, subsequent encounter',
  'T594X3S Toxic effect of chlorine gas, assault, sequela',
  'T594X4A Toxic effect of chlorine gas, undetermined, initial encounter',
  'T594X4D Toxic effect of chlorine gas, undetermined, subsequent encounter',
  'T594X4S Toxic effect of chlorine gas, undetermined, sequela',
  'T595X1A Toxic effect of fluorine gas and hydrogen fluoride, accidental (unintentional), initial encounter',
  'T595X1D Toxic effect of fluorine gas and hydrogen fluoride, accidental (unintentional), subsequent',
  'encounter',
  'T595X1S Toxic effect of fluorine gas and hydrogen fluoride, accidental (unintentional), sequela',
  'T595X2A Toxic effect of fluorine gas and hydrogen fluoride, intentional self-harm, initial encounter',
  'T595X2D Toxic effect of fluorine gas and hydrogen fluoride, intentional self-harm, subsequent encounter',
  'T595X2S Toxic effect of fluorine gas and hydrogen fluoride, intentional self-harm, sequela',
  'T595X3A Toxic effect of fluorine gas and hydrogen fluoride, assault, initial encounter',
  'T595X3D Toxic effect of fluorine gas and hydrogen fluoride, assault, subsequent encounter',
  'T595X3S Toxic effect of fluorine gas and hydrogen fluoride, assault, sequela',
  'T595X4A Toxic effect of fluorine gas and hydrogen fluoride, undetermined, initial encounter',
  'T595X4D Toxic effect of fluorine gas and hydrogen fluoride, undetermined, subsequent encounter',
  'T595X4S Toxic effect of fluorine gas and hydrogen fluoride, undetermined, sequela',
  'T596X1A Toxic effect of hydrogen sulfide, accidental (unintentional), initial encounter',
  'T596X1D Toxic effect of hydrogen sulfide, accidental (unintentional), subsequent encounter',
  'T596X1S Toxic effect of hydrogen sulfide, accidental (unintentional), sequela',
  'T596X2A Toxic effect of hydrogen sulfide, intentional self-harm, initial encounter',
  'T596X2D Toxic effect of hydrogen sulfide, intentional self-harm, subsequent encounter',
  'T596X2S Toxic effect of hydrogen sulfide, intentional self-harm, sequela',
  'T596X3A Toxic effect of hydrogen sulfide, assault, initial encounter',
  'T596X3D Toxic effect of hydrogen sulfide, assault, subsequent encounter',
  'T596X3S Toxic effect of hydrogen sulfide, assault, sequela',
  'T596X4A Toxic effect of hydrogen sulfide, undetermined, initial encounter',
  'T596X4D Toxic effect of hydrogen sulfide, undetermined, subsequent encounter',
  'T596X4S Toxic effect of hydrogen sulfide, undetermined, sequela',
  'T597X1A Toxic effect of carbon dioxide, accidental (unintentional), initial encounter',
  'T597X1D Toxic effect of carbon dioxide, accidental (unintentional), subsequent encounter',
  'T597X1S Toxic effect of carbon dioxide, accidental (unintentional), sequela',
  'T597X2A Toxic effect of carbon dioxide, intentional self-harm, initial encounter',
  'T597X2D Toxic effect of carbon dioxide, intentional self-harm, subsequent encounter',
  'T597X2S Toxic effect of carbon dioxide, intentional self-harm, sequela',
  'T597X3A Toxic effect of carbon dioxide, assault, initial encounter',
  'T597X3D Toxic effect of carbon dioxide, assault, subsequent encounter',
  'T597X3S Toxic effect of carbon dioxide, assault, sequela',
  'T597X4A Toxic effect of carbon dioxide, undetermined, initial encounter',
  'T597X4D Toxic effect of carbon dioxide, undetermined, subsequent encounter',
  'T597X4S Toxic effect of carbon dioxide, undetermined, sequela',
  'T59811A Toxic effect of smoke, accidental (unintentional), initial encounter',
  'T59811D Toxic effect of smoke, accidental (unintentional), subsequent encounter',
  'T59811S Toxic effect of smoke, accidental (unintentional), sequela',
  'T59812A Toxic effect of smoke, intentional self-harm, initial encounter',
  'T59812D Toxic effect of smoke, intentional self-harm, subsequent encounter',
  'T59812S Toxic effect of smoke, intentional self-harm, sequela',
  'T59813A Toxic effect of smoke, assault, initial encounter',
  'T59813D Toxic effect of smoke, assault, subsequent encounter',
  'T59813S Toxic effect of smoke, assault, sequela',
  'T59814A Toxic effect of smoke, undetermined, initial encounter',
  'T59814D Toxic effect of smoke, undetermined, subsequent encounter',
  'T59814S Toxic effect of smoke, undetermined, sequela',
  'T59891A Toxic effect of other specified gases, fumes and vapors, accidental (unintentional), initial',
  'encounter',
  'T59891D Toxic effect of other specified gases, fumes and vapors, accidental (unintentional), subsequent',
  'encounter',
  'T59891S Toxic effect of other specified gases, fumes and vapors, accidental (unintentional), sequela',
  'T59892A Toxic effect of other specified gases, fumes and vapors, intentional self-harm, initial encounter',
  'T59892D Toxic effect of other specified gases, fumes and vapors, intentional self-harm, subsequent',
  'encounter',
  'T59892S Toxic effect of other specified gases, fumes and vapors, intentional self-harm, sequela',
  'T59893A Toxic effect of other specified gases, fumes and vapors, assault, initial encounter',
  'T59893D Toxic effect of other specified gases, fumes and vapors, assault, subsequent encounter',
  'T59893S Toxic effect of other specified gases, fumes and vapors, assault, sequela',
  'T59894A Toxic effect of other specified gases, fumes and vapors, undetermined, initial encounter',
  'T59894D Toxic effect of other specified gases, fumes and vapors, undetermined, subsequent encounter',
  'T59894S Toxic effect of other specified gases, fumes and vapors, undetermined, sequela',
  'T5991XA Toxic effect of unspecified gases, fumes and vapors, accidental (unintentional), initial',
  'encounter',
  'T5991XD Toxic effect of unspecified gases, fumes and vapors, accidental (unintentional), subsequent',
  'encounter',
  'T5991XS Toxic effect of unspecified gases, fumes and vapors, accidental (unintentional), sequela',
  'T5992XA Toxic effect of unspecified gases, fumes and vapors, intentional self-harm, initial encounter',
  'T5992XD Toxic effect of unspecified gases, fumes and vapors, intentional self-harm, subsequent',
  'encounter',
  'T5992XS Toxic effect of unspecified gases, fumes and vapors, intentional self-harm, sequela',
  'T5993XA Toxic effect of unspecified gases, fumes and vapors, assault, initial encounter',
  'T5993XD Toxic effect of unspecified gases, fumes and vapors, assault, subsequent encounter',
  'T5993XS Toxic effect of unspecified gases, fumes and vapors, assault, sequela',
  'T5994XA Toxic effect of unspecified gases, fumes and vapors, undetermined, initial encounter',
  'T5994XD Toxic effect of unspecified gases, fumes and vapors, undetermined, subsequent encounter',
  'T5994XS Toxic effect of unspecified gases, fumes and vapors, undetermined, sequela',
  'T600X1A Toxic effect of organophosphate and carbamate insecticides, accidental (unintentional), initial',
  'encounter',
  'T600X1D Toxic effect of organophosphate and carbamate insecticides, accidental (unintentional),',
  'subsequent encounter',
  'T600X1S Toxic effect of organophosphate and carbamate insecticides, accidental (unintentional),',
  'sequela',
  'T600X2A Toxic effect of organophosphate and carbamate insecticides, intentional self-harm, initial',
  'encounter',
  'T600X2D Toxic effect of organophosphate and carbamate insecticides, intentional self-harm,',
  'subsequent encounter',
  'T600X2S Toxic effect of organophosphate and carbamate insecticides, intentional self-harm, sequela',
  'T600X3A Toxic effect of organophosphate and carbamate insecticides, assault, initial encounter',
  'T600X3D Toxic effect of organophosphate and carbamate insecticides, assault, subsequent encounter',
  'T600X3S Toxic effect of organophosphate and carbamate insecticides, assault, sequela',
  'T600X4A Toxic effect of organophosphate and carbamate insecticides, undetermined, initial encounter',
  'T600X4D Toxic effect of organophosphate and carbamate insecticides, undetermined, subsequent',
  'encounter',
  'T600X4S Toxic effect of organophosphate and carbamate insecticides, undetermined, sequela',
  'T601X1A Toxic effect of halogenated insecticides, accidental (unintentional), initial encounter',
  'T601X1D Toxic effect of halogenated insecticides, accidental (unintentional), subsequent encounter',
  'T601X1S Toxic effect of halogenated insecticides, accidental (unintentional), sequela',
  'T601X2A Toxic effect of halogenated insecticides, intentional self-harm, initial encounter',
  'T601X2D Toxic effect of halogenated insecticides, intentional self-harm, subsequent encounter',
  'T601X2S Toxic effect of halogenated insecticides, intentional self-harm, sequela',
  'T601X3A Toxic effect of halogenated insecticides, assault, initial encounter',
  'T601X3D Toxic effect of halogenated insecticides, assault, subsequent encounter',
  'T601X3S Toxic effect of halogenated insecticides, assault, sequela',
  'T601X4A Toxic effect of halogenated insecticides, undetermined, initial encounter',
  'T601X4D Toxic effect of halogenated insecticides, undetermined, subsequent encounter',
  'T601X4S Toxic effect of halogenated insecticides, undetermined, sequela',
  'T602X1A Toxic effect of other insecticides, accidental (unintentional), initial encounter',
  'T602X1D Toxic effect of other insecticides, accidental (unintentional), subsequent encounter',
  'T602X1S Toxic effect of other insecticides, accidental (unintentional), sequela',
  'T602X2A Toxic effect of other insecticides, intentional self-harm, initial encounter',
  'T602X2D Toxic effect of other insecticides, intentional self-harm, subsequent encounter',
  'T602X2S Toxic effect of other insecticides, intentional self-harm, sequela',
  'T602X3A Toxic effect of other insecticides, assault, initial encounter',
  'T602X3D Toxic effect of other insecticides, assault, subsequent encounter',
  'T602X3S Toxic effect of other insecticides, assault, sequela',
  'T602X4A Toxic effect of other insecticides, undetermined, initial encounter',
  'T602X4D Toxic effect of other insecticides, undetermined, subsequent encounter',
  'T602X4S Toxic effect of other insecticides, undetermined, sequela',
  'T603X1A Toxic effect of herbicides and fungicides, accidental (unintentional), initial encounter',
  'T603X1D Toxic effect of herbicides and fungicides, accidental (unintentional), subsequent encounter',
  'T603X1S Toxic effect of herbicides and fungicides, accidental (unintentional), sequela',
  'T603X2A Toxic effect of herbicides and fungicides, intentional self-harm, initial encounter',
  'T603X2D Toxic effect of herbicides and fungicides, intentional self-harm, subsequent encounter',
  'T603X2S Toxic effect of herbicides and fungicides, intentional self-harm, sequela',
  'T603X3A Toxic effect of herbicides and fungicides, assault, initial encounter',
  'T603X3D Toxic effect of herbicides and fungicides, assault, subsequent encounter',
  'T603X3S Toxic effect of herbicides and fungicides, assault, sequela',
  'T603X4A Toxic effect of herbicides and fungicides, undetermined, initial encounter',
  'T603X4D Toxic effect of herbicides and fungicides, undetermined, subsequent encounter',
  'T603X4S Toxic effect of herbicides and fungicides, undetermined, sequela',
  'T604X1A Toxic effect of rodenticides, accidental (unintentional), initial encounter',
  'T604X1D Toxic effect of rodenticides, accidental (unintentional), subsequent encounter',
  'T604X1S Toxic effect of rodenticides, accidental (unintentional), sequela',
  'T604X2A Toxic effect of rodenticides, intentional self-harm, initial encounter',
  'T604X2D Toxic effect of rodenticides, intentional self-harm, subsequent encounter',
  'T604X2S Toxic effect of rodenticides, intentional self-harm, sequela',
  'T604X3A Toxic effect of rodenticides, assault, initial encounter',
  'T604X3D Toxic effect of rodenticides, assault, subsequent encounter',
  'T604X3S Toxic effect of rodenticides, assault, sequela',
  'T604X4A Toxic effect of rodenticides, undetermined, initial encounter',
  'T604X4D Toxic effect of rodenticides, undetermined, subsequent encounter',
  'T604X4S Toxic effect of rodenticides, undetermined, sequela',
  'T608X1A Toxic effect of other pesticides, accidental (unintentional), initial encounter',
  'T608X1D Toxic effect of other pesticides, accidental (unintentional), subsequent encounter',
  'T608X1S Toxic effect of other pesticides, accidental (unintentional), sequela',
  'T608X2A Toxic effect of other pesticides, intentional self-harm, initial encounter',
  'T608X2D Toxic effect of other pesticides, intentional self-harm, subsequent encounter',
  'T608X2S Toxic effect of other pesticides, intentional self-harm, sequela',
  'T608X3A Toxic effect of other pesticides, assault, initial encounter',
  'T608X3D Toxic effect of other pesticides, assault, subsequent encounter',
  'T608X3S Toxic effect of other pesticides, assault, sequela',
  'T608X4A Toxic effect of other pesticides, undetermined, initial encounter',
  'T608X4D Toxic effect of other pesticides, undetermined, subsequent encounter',
  'T608X4S Toxic effect of other pesticides, undetermined, sequela',
  'T6091XA Toxic effect of unspecified pesticide, accidental (unintentional), initial encounter',
  'T6091XD Toxic effect of unspecified pesticide, accidental (unintentional), subsequent encounter',
  'T6091XS Toxic effect of unspecified pesticide, accidental (unintentional), sequela',
  'T6092XA Toxic effect of unspecified pesticide, intentional self-harm, initial encounter',
  'T6092XD Toxic effect of unspecified pesticide, intentional self-harm, subsequent encounter',
  'T6092XS Toxic effect of unspecified pesticide, intentional self-harm, sequela',
  'T6093XA Toxic effect of unspecified pesticide, assault, initial encounter',
  'T6093XD Toxic effect of unspecified pesticide, assault, subsequent encounter',
  'T6093XS Toxic effect of unspecified pesticide, assault, sequela',
  'T6094XA Toxic effect of unspecified pesticide, undetermined, initial encounter',
  'T6094XD Toxic effect of unspecified pesticide, undetermined, subsequent encounter',
  'T6094XS Toxic effect of unspecified pesticide, undetermined, sequela',
  'T6101XA Ciguatera fish poisoning, accidental (unintentional), initial encounter',
  'T6101XD Ciguatera fish poisoning, accidental (unintentional), subsequent encounter',
  'T6101XS Ciguatera fish poisoning, accidental (unintentional), sequela',
  'T6102XA Ciguatera fish poisoning, intentional self-harm, initial encounter',
  'T6102XD Ciguatera fish poisoning, intentional self-harm, subsequent encounter',
  'T6102XS Ciguatera fish poisoning, intentional self-harm, sequela',
  'T6103XA Ciguatera fish poisoning, assault, initial encounter',
  'T6103XD Ciguatera fish poisoning, assault, subsequent encounter',
  'T6103XS Ciguatera fish poisoning, assault, sequela',
  'T6104XA Ciguatera fish poisoning, undetermined, initial encounter',
  'T6104XD Ciguatera fish poisoning, undetermined, subsequent encounter',
  'T6104XS Ciguatera fish poisoning, undetermined, sequela',
  'T6111XA Scombroid fish poisoning, accidental (unintentional), initial encounter',
  'T6111XD Scombroid fish poisoning, accidental (unintentional), subsequent encounter',
  'T6111XS Scombroid fish poisoning, accidental (unintentional), sequela',
  'T6112XA Scombroid fish poisoning, intentional self-harm, initial encounter',
  'T6112XD Scombroid fish poisoning, intentional self-harm, subsequent encounter',
  'T6112XS Scombroid fish poisoning, intentional self-harm, sequela',
  'T6113XA Scombroid fish poisoning, assault, initial encounter',
  'T6113XD Scombroid fish poisoning, assault, subsequent encounter',
  'T6113XS Scombroid fish poisoning, assault, sequela',
  'T6114XA Scombroid fish poisoning, undetermined, initial encounter',
  'T6114XD Scombroid fish poisoning, undetermined, subsequent encounter',
  'T6114XS Scombroid fish poisoning, undetermined, sequela',
  'T61771A Other fish poisoning, accidental (unintentional), initial encounter',
  'T61771D Other fish poisoning, accidental (unintentional), subsequent encounter',
  'T61771S Other fish poisoning, accidental (unintentional), sequela',
  'T61772A Other fish poisoning, intentional self-harm, initial encounter',
  'T61772D Other fish poisoning, intentional self-harm, subsequent encounter',
  'T61772S Other fish poisoning, intentional self-harm, sequela',
  'T61773A Other fish poisoning, assault, initial encounter',
  'T61773D Other fish poisoning, assault, subsequent encounter',
  'T61773S Other fish poisoning, assault, sequela',
  'T61774A Other fish poisoning, undetermined, initial encounter',
  'T61774D Other fish poisoning, undetermined, subsequent encounter',
  'T61774S Other fish poisoning, undetermined, sequela',
  'T61781A Other shellfish poisoning, accidental (unintentional), initial encounter',
  'T61781D Other shellfish poisoning, accidental (unintentional), subsequent encounter',
  'T61781S Other shellfish poisoning, accidental (unintentional), sequela',
  'T61782A Other shellfish poisoning, intentional self-harm, initial encounter',
  'T61782D Other shellfish poisoning, intentional self-harm, subsequent encounter',
  'T61782S Other shellfish poisoning, intentional self-harm, sequela',
  'T61783A Other shellfish poisoning, assault, initial encounter',
  'T61783D Other shellfish poisoning, assault, subsequent encounter',
  'T61783S Other shellfish poisoning, assault, sequela',
  'T61784A Other shellfish poisoning, undetermined, initial encounter',
  'T61784D Other shellfish poisoning, undetermined, subsequent encounter',
  'T61784S Other shellfish poisoning, undetermined, sequela',
  'T618X1A Toxic effect of other seafood, accidental (unintentional), initial encounter',
  'T618X1D Toxic effect of other seafood, accidental (unintentional), subsequent encounter',
  'T618X1S Toxic effect of other seafood, accidental (unintentional), sequela',
  'T618X2A Toxic effect of other seafood, intentional self-harm, initial encounter',
  'T618X2D Toxic effect of other seafood, intentional self-harm, subsequent encounter',
  'T618X2S Toxic effect of other seafood, intentional self-harm, sequela',
  'T618X3A Toxic effect of other seafood, assault, initial encounter',
  'T618X3D Toxic effect of other seafood, assault, subsequent encounter',
  'T618X3S Toxic effect of other seafood, assault, sequela',
  'T618X4A Toxic effect of other seafood, undetermined, initial encounter',
  'T618X4D Toxic effect of other seafood, undetermined, subsequent encounter',
  'T618X4S Toxic effect of other seafood, undetermined, sequela',
  'T6191XA Toxic effect of unspecified seafood, accidental (unintentional), initial encounter',
  'T6191XD Toxic effect of unspecified seafood, accidental (unintentional), subsequent encounter',
  'T6191XS Toxic effect of unspecified seafood, accidental (unintentional), sequela',
  'T6192XA Toxic effect of unspecified seafood, intentional self-harm, initial encounter',
  'T6192XD Toxic effect of unspecified seafood, intentional self-harm, subsequent encounter',
  'T6192XS Toxic effect of unspecified seafood, intentional self-harm, sequela',
  'T6193XA Toxic effect of unspecified seafood, assault, initial encounter',
  'T6193XD Toxic effect of unspecified seafood, assault, subsequent encounter',
  'T6193XS Toxic effect of unspecified seafood, assault, sequela',
  'T6194XA Toxic effect of unspecified seafood, undetermined, initial encounter',
  'T6194XD Toxic effect of unspecified seafood, undetermined, subsequent encounter',
  'T6194XS Toxic effect of unspecified seafood, undetermined, sequela',
  'T620X1A Toxic effect of ingested mushrooms, accidental (unintentional), initial encounter',
  'T620X1D Toxic effect of ingested mushrooms, accidental (unintentional), subsequent encounter',
  'T620X1S Toxic effect of ingested mushrooms, accidental (unintentional), sequela',
  'T620X2A Toxic effect of ingested mushrooms, intentional self-harm, initial encounter',
  'T620X2D Toxic effect of ingested mushrooms, intentional self-harm, subsequent encounter',
  'T620X2S Toxic effect of ingested mushrooms, intentional self-harm, sequela',
  'T620X3A Toxic effect of ingested mushrooms, assault, initial encounter',
  'T620X3D Toxic effect of ingested mushrooms, assault, subsequent encounter',
  'T620X3S Toxic effect of ingested mushrooms, assault, sequela',
  'T620X4A Toxic effect of ingested mushrooms, undetermined, initial encounter',
  'T620X4D Toxic effect of ingested mushrooms, undetermined, subsequent encounter',
  'T620X4S Toxic effect of ingested mushrooms, undetermined, sequela',
  'T621X1A Toxic effect of ingested berries, accidental (unintentional), initial encounter',
  'T621X1D Toxic effect of ingested berries, accidental (unintentional), subsequent encounter',
  'T621X1S Toxic effect of ingested berries, accidental (unintentional), sequela',
  'T621X2A Toxic effect of ingested berries, intentional self-harm, initial encounter',
  'T621X2D Toxic effect of ingested berries, intentional self-harm, subsequent encounter',
  'T621X2S Toxic effect of ingested berries, intentional self-harm, sequela',
  'T621X3A Toxic effect of ingested berries, assault, initial encounter',
  'T621X3D Toxic effect of ingested berries, assault, subsequent encounter',
  'T621X3S Toxic effect of ingested berries, assault, sequela',
  'T621X4A Toxic effect of ingested berries, undetermined, initial encounter',
  'T621X4D Toxic effect of ingested berries, undetermined, subsequent encounter',
  'T621X4S Toxic effect of ingested berries, undetermined, sequela',
  'T622X1A Toxic effect of other ingested (parts of) plant(s), accidental (unintentional), initial encounter',
  'T622X1D Toxic effect of other ingested (parts of) plant(s), accidental (unintentional), subsequent',
  'encounter',
  'T622X1S Toxic effect of other ingested (parts of) plant(s), accidental (unintentional), sequela',
  'T622X2A Toxic effect of other ingested (parts of) plant(s), intentional self-harm, initial encounter',
  'T622X2D Toxic effect of other ingested (parts of) plant(s), intentional self-harm, subsequent encounter',
  'T622X2S Toxic effect of other ingested (parts of) plant(s), intentional self-harm, sequela',
  'T622X3A Toxic effect of other ingested (parts of) plant(s), assault, initial encounter',
  'T622X3D Toxic effect of other ingested (parts of) plant(s), assault, subsequent encounter',
  'T622X3S Toxic effect of other ingested (parts of) plant(s), assault, sequela',
  'T622X4A Toxic effect of other ingested (parts of) plant(s), undetermined, initial encounter',
  'T622X4D Toxic effect of other ingested (parts of) plant(s), undetermined, subsequent encounter',
  'T622X4S Toxic effect of other ingested (parts of) plant(s), undetermined, sequela',
  'T628X1A Toxic effect of other specified noxious substances eaten as food, accidental (unintentional),',
  'initial encounter',
  'T628X1D Toxic effect of other specified noxious substances eaten as food, accidental (unintentional),',
  'subsequent encounter',
  'T628X1S Toxic effect of other specified noxious substances eaten as food, accidental (unintentional),',
  'sequela',
  'T628X2A Toxic effect of other specified noxious substances eaten as food, intentional self-harm, initial',
  'encounter',
  'T628X2D Toxic effect of other specified noxious substances eaten as food, intentional self-harm,',
  'subsequent encounter',
  'T628X2S Toxic effect of other specified noxious substances eaten as food, intentional self-harm, sequela',
  'T628X3A Toxic effect of other specified noxious substances eaten as food, assault, initial encounter',
  'T628X3D Toxic effect of other specified noxious substances eaten as food, assault, subsequent',
  'encounter',
  'T628X3S Toxic effect of other specified noxious substances eaten as food, assault, sequela',
  'T628X4A Toxic effect of other specified noxious substances eaten as food, undetermined, initial',
  'encounter',
  'T628X4D Toxic effect of other specified noxious substances eaten as food, undetermined, subsequent',
  'encounter',
  'T628X4S Toxic effect of other specified noxious substances eaten as food, undetermined, sequela',
  'T6291XA Toxic effect of unspecified noxious substance eaten as food, accidental (unintentional), initial',
  'encounter',
  'T6291XD Toxic effect of unspecified noxious substance eaten as food, accidental (unintentional),',
  'subsequent encounter',
  'T6291XS Toxic effect of unspecified noxious substance eaten as food, accidental (unintentional), sequela',
  'T6292XA Toxic effect of unspecified noxious substance eaten as food, intentional self-harm, initial',
  'encounter',
  'T6292XD Toxic effect of unspecified noxious substance eaten as food, intentional self-harm, subsequent',
  'encounter',
  'T6292XS Toxic effect of unspecified noxious substance eaten as food, intentional self-harm, sequela',
  'T6293XA Toxic effect of unspecified noxious substance eaten as food, assault, initial encounter',
  'T6293XD Toxic effect of unspecified noxious substance eaten as food, assault, subsequent encounter',
  'T6293XS Toxic effect of unspecified noxious substance eaten as food, assault, sequela',
  'T6294XA Toxic effect of unspecified noxious substance eaten as food, undetermined, initial encounter',
  'T6294XD Toxic effect of unspecified noxious substance eaten as food, undetermined, subsequent',
  'encounter',
  'T6294XS Toxic effect of unspecified noxious substance eaten as food, undetermined, sequela',
  'T63001A Toxic effect of unspecified snake venom, accidental (unintentional), initial encounter',
  'T63001D Toxic effect of unspecified snake venom, accidental (unintentional), subsequent encounter',
  'T63001S Toxic effect of unspecified snake venom, accidental (unintentional), sequela',
  'T63002A Toxic effect of unspecified snake venom, intentional self-harm, initial encounter',
  'T63002D Toxic effect of unspecified snake venom, intentional self-harm, subsequent encounter',
  'T63002S Toxic effect of unspecified snake venom, intentional self-harm, sequela',
  'T63003A Toxic effect of unspecified snake venom, assault, initial encounter',
  'T63003D Toxic effect of unspecified snake venom, assault, subsequent encounter',
  'T63003S Toxic effect of unspecified snake venom, assault, sequela',
  'T63004A Toxic effect of unspecified snake venom, undetermined, initial encounter',
  'T63004D Toxic effect of unspecified snake venom, undetermined, subsequent encounter',
  'T63004S Toxic effect of unspecified snake venom, undetermined, sequela',
  'T63011A Toxic effect of rattlesnake venom, accidental (unintentional), initial encounter',
  'T63011D Toxic effect of rattlesnake venom, accidental (unintentional), subsequent encounter',
  'T63011S Toxic effect of rattlesnake venom, accidental (unintentional), sequela',
  'T63012A Toxic effect of rattlesnake venom, intentional self-harm, initial encounter',
  'T63012D Toxic effect of rattlesnake venom, intentional self-harm, subsequent encounter',
  'T63012S Toxic effect of rattlesnake venom, intentional self-harm, sequela',
  'T63013A Toxic effect of rattlesnake venom, assault, initial encounter',
  'T63013D Toxic effect of rattlesnake venom, assault, subsequent encounter',
  'T63013S Toxic effect of rattlesnake venom, assault, sequela',
  'T63014A Toxic effect of rattlesnake venom, undetermined, initial encounter',
  'T63014D Toxic effect of rattlesnake venom, undetermined, subsequent encounter',
  'T63014S Toxic effect of rattlesnake venom, undetermined, sequela',
  'T63021A Toxic effect of coral snake venom, accidental (unintentional), initial encounter',
  'T63021D Toxic effect of coral snake venom, accidental (unintentional), subsequent encounter',
  'T63021S Toxic effect of coral snake venom, accidental (unintentional), sequela',
  'T63022A Toxic effect of coral snake venom, intentional self-harm, initial encounter',
  'T63022D Toxic effect of coral snake venom, intentional self-harm, subsequent encounter',
  'T63022S Toxic effect of coral snake venom, intentional self-harm, sequela',
  'T63023A Toxic effect of coral snake venom, assault, initial encounter',
  'T63023D Toxic effect of coral snake venom, assault, subsequent encounter',
  'T63023S Toxic effect of coral snake venom, assault, sequela',
  'T63024A Toxic effect of coral snake venom, undetermined, initial encounter',
  'T63024D Toxic effect of coral snake venom, undetermined, subsequent encounter',
  'T63024S Toxic effect of coral snake venom, undetermined, sequela',
  'T63031A Toxic effect of taipan venom, accidental (unintentional), initial encounter',
  'T63031D Toxic effect of taipan venom, accidental (unintentional), subsequent encounter',
  'T63031S Toxic effect of taipan venom, accidental (unintentional), sequela',
  'T63032A Toxic effect of taipan venom, intentional self-harm, initial encounter',
  'T63032D Toxic effect of taipan venom, intentional self-harm, subsequent encounter',
  'T63032S Toxic effect of taipan venom, intentional self-harm, sequela',
  'T63033A Toxic effect of taipan venom, assault, initial encounter',
  'T63033D Toxic effect of taipan venom, assault, subsequent encounter',
  'T63033S Toxic effect of taipan venom, assault, sequela',
  'T63034A Toxic effect of taipan venom, undetermined, initial encounter',
  'T63034D Toxic effect of taipan venom, undetermined, subsequent encounter',
  'T63034S Toxic effect of taipan venom, undetermined, sequela',
  'T63041A Toxic effect of cobra venom, accidental (unintentional), initial encounter',
  'T63041D Toxic effect of cobra venom, accidental (unintentional), subsequent encounter',
  'T63041S Toxic effect of cobra venom, accidental (unintentional), sequela',
  'T63042A Toxic effect of cobra venom, intentional self-harm, initial encounter',
  'T63042D Toxic effect of cobra venom, intentional self-harm, subsequent encounter',
  'T63042S Toxic effect of cobra venom, intentional self-harm, sequela',
  'T63043A Toxic effect of cobra venom, assault, initial encounter',
  'T63043D Toxic effect of cobra venom, assault, subsequent encounter',
  'T63043S Toxic effect of cobra venom, assault, sequela',
  'T63044A Toxic effect of cobra venom, undetermined, initial encounter',
  'T63044D Toxic effect of cobra venom, undetermined, subsequent encounter',
  'T63044S Toxic effect of cobra venom, undetermined, sequela',
  'T63061A Toxic effect of venom of other North and South American snake, accidental (unintentional),',
  'initial encounter',
  'T63061D Toxic effect of venom of other North and South American snake, accidental (unintentional),',
  'subsequent encounter',
  'T63061S Toxic effect of venom of other North and South American snake, accidental (unintentional),',
  'sequela',
  'T63062A Toxic effect of venom of other North and South American snake, intentional self-harm, initial',
  'encounter',
  'T63062D Toxic effect of venom of other North and South American snake, intentional self-harm,',
  'subsequent encounter',
  'T63062S Toxic effect of venom of other North and South American snake, intentional self-harm, sequela',
  'T63063A Toxic effect of venom of other North and South American snake, assault, initial encounter',
  'T63063D Toxic effect of venom of other North and South American snake, assault, subsequent',
  'encounter',
  'T63063S Toxic effect of venom of other North and South American snake, assault, sequela',
  'T63064A Toxic effect of venom of other North and South American snake, undetermined, initial',
  'encounter',
  'T63064D Toxic effect of venom of other North and South American snake, undetermined, subsequent',
  'encounter',
  'T63064S Toxic effect of venom of other North and South American snake, undetermined, sequela',
  'T63071A Toxic effect of venom of other Australian snake, accidental (unintentional), initial encounter',
  'T63071D Toxic effect of venom of other Australian snake, accidental (unintentional), subsequent',
  'encounter',
  'T63071S Toxic effect of venom of other Australian snake, accidental (unintentional), sequela',
  'T63072A Toxic effect of venom of other Australian snake, intentional self-harm, initial encounter',
  'T63072D Toxic effect of venom of other Australian snake, intentional self-harm, subsequent encounter',
  'T63072S Toxic effect of venom of other Australian snake, intentional self-harm, sequela',
  'T63073A Toxic effect of venom of other Australian snake, assault, initial encounter',
  'T63073D Toxic effect of venom of other Australian snake, assault, subsequent encounter',
  'T63073S Toxic effect of venom of other Australian snake, assault, sequela',
  'T63074A Toxic effect of venom of other Australian snake, undetermined, initial encounter',
  'T63074D Toxic effect of venom of other Australian snake, undetermined, subsequent encounter',
  'T63074S Toxic effect of venom of other Australian snake, undetermined, sequela',
  'T63081A Toxic effect of venom of other African and Asian snake, accidental (unintentional), initial',
  'encounter',
  'T63081D Toxic effect of venom of other African and Asian snake, accidental (unintentional), subsequent',
  'encounter',
  'T63081S Toxic effect of venom of other African and Asian snake, accidental (unintentional), sequela',
  'T63082A Toxic effect of venom of other African and Asian snake, intentional self-harm, initial encounter',
  'T63082D Toxic effect of venom of other African and Asian snake, intentional self-harm, subsequent',
  'encounter',
  'T63082S Toxic effect of venom of other African and Asian snake, intentional self-harm, sequela',
  'T63083A Toxic effect of venom of other African and Asian snake, assault, initial encounter',
  'T63083D Toxic effect of venom of other African and Asian snake, assault, subsequent encounter',
  'T63083S Toxic effect of venom of other African and Asian snake, assault, sequela',
  'T63084A Toxic effect of venom of other African and Asian snake, undetermined, initial encounter',
  'T63084D Toxic effect of venom of other African and Asian snake, undetermined, subsequent encounter',
  'T63084S Toxic effect of venom of other African and Asian snake, undetermined, sequela',
  'T63091A Toxic effect of venom of other snake, accidental (unintentional), initial encounter',
  'T63091D Toxic effect of venom of other snake, accidental (unintentional), subsequent encounter',
  'T63091S Toxic effect of venom of other snake, accidental (unintentional), sequela',
  'T63092A Toxic effect of venom of other snake, intentional self-harm, initial encounter',
  'T63092D Toxic effect of venom of other snake, intentional self-harm, subsequent encounter',
  'T63092S Toxic effect of venom of other snake, intentional self-harm, sequela',
  'T63093A Toxic effect of venom of other snake, assault, initial encounter',
  'T63093D Toxic effect of venom of other snake, assault, subsequent encounter',
  'T63093S Toxic effect of venom of other snake, assault, sequela',
  'T63094A Toxic effect of venom of other snake, undetermined, initial encounter',
  'T63094D Toxic effect of venom of other snake, undetermined, subsequent encounter',
  'T63094S Toxic effect of venom of other snake, undetermined, sequela',
  'T63111A Toxic effect of venom of gila monster, accidental (unintentional), initial encounter',
  'T63111D Toxic effect of venom of gila monster, accidental (unintentional), subsequent encounter',
  'T63111S Toxic effect of venom of gila monster, accidental (unintentional), sequela',
  'T63112A Toxic effect of venom of gila monster, intentional self-harm, initial encounter',
  'T63112D Toxic effect of venom of gila monster, intentional self-harm, subsequent encounter',
  'T63112S Toxic effect of venom of gila monster, intentional self-harm, sequela',
  'T63113A Toxic effect of venom of gila monster, assault, initial encounter',
  'T63113D Toxic effect of venom of gila monster, assault, subsequent encounter',
  'T63113S Toxic effect of venom of gila monster, assault, sequela',
  'T63114A Toxic effect of venom of gila monster, undetermined, initial encounter',
  'T63114D Toxic effect of venom of gila monster, undetermined, subsequent encounter',
  'T63114S Toxic effect of venom of gila monster, undetermined, sequela',
  'T63121A Toxic effect of venom of other venomous lizard, accidental (unintentional), initial encounter',
  'T63121D Toxic effect of venom of other venomous lizard, accidental (unintentional), subsequent',
  'encounter',
  'T63121S Toxic effect of venom of other venomous lizard, accidental (unintentional), sequela',
  'T63122A Toxic effect of venom of other venomous lizard, intentional self-harm, initial encounter',
  'T63122D Toxic effect of venom of other venomous lizard, intentional self-harm, subsequent encounter',
  'T63122S Toxic effect of venom of other venomous lizard, intentional self-harm, sequela',
  'T63123A Toxic effect of venom of other venomous lizard, assault, initial encounter',
  'T63123D Toxic effect of venom of other venomous lizard, assault, subsequent encounter',
  'T63123S Toxic effect of venom of other venomous lizard, assault, sequela',
  'T63124A Toxic effect of venom of other venomous lizard, undetermined, initial encounter',
  'T63124D Toxic effect of venom of other venomous lizard, undetermined, subsequent encounter',
  'T63124S Toxic effect of venom of other venomous lizard, undetermined, sequela',
  'T63191A Toxic effect of venom of other reptiles, accidental (unintentional), initial encounter',
  'T63191D Toxic effect of venom of other reptiles, accidental (unintentional), subsequent encounter',
  'T63191S Toxic effect of venom of other reptiles, accidental (unintentional), sequela',
  'T63192A Toxic effect of venom of other reptiles, intentional self-harm, initial encounter',
  'T63192D Toxic effect of venom of other reptiles, intentional self-harm, subsequent encounter',
  'T63192S Toxic effect of venom of other reptiles, intentional self-harm, sequela',
  'T63193A Toxic effect of venom of other reptiles, assault, initial encounter',
  'T63193D Toxic effect of venom of other reptiles, assault, subsequent encounter',
  'T63193S Toxic effect of venom of other reptiles, assault, sequela',
  'T63194A Toxic effect of venom of other reptiles, undetermined, initial encounter',
  'T63194D Toxic effect of venom of other reptiles, undetermined, subsequent encounter',
  'T63194S Toxic effect of venom of other reptiles, undetermined, sequela',
  'T632X1A Toxic effect of venom of scorpion, accidental (unintentional), initial encounter',
  'T632X1D Toxic effect of venom of scorpion, accidental (unintentional), subsequent encounter',
  'T632X1S Toxic effect of venom of scorpion, accidental (unintentional), sequela',
  'T632X2A Toxic effect of venom of scorpion, intentional self-harm, initial encounter',
  'T632X2D Toxic effect of venom of scorpion, intentional self-harm, subsequent encounter',
  'T632X2S Toxic effect of venom of scorpion, intentional self-harm, sequela',
  'T632X3A Toxic effect of venom of scorpion, assault, initial encounter',
  'T632X3D Toxic effect of venom of scorpion, assault, subsequent encounter',
  'T632X3S Toxic effect of venom of scorpion, assault, sequela',
  'T632X4A Toxic effect of venom of scorpion, undetermined, initial encounter',
  'T632X4D Toxic effect of venom of scorpion, undetermined, subsequent encounter',
  'T632X4S Toxic effect of venom of scorpion, undetermined, sequela',
  'T63301A Toxic effect of unspecified spider venom, accidental (unintentional), initial encounter',
  'T63301D Toxic effect of unspecified spider venom, accidental (unintentional), subsequent encounter',
  'T63301S Toxic effect of unspecified spider venom, accidental (unintentional), sequela',
  'T63302A Toxic effect of unspecified spider venom, intentional self-harm, initial encounter',
  'T63302D Toxic effect of unspecified spider venom, intentional self-harm, subsequent encounter',
  'T63302S Toxic effect of unspecified spider venom, intentional self-harm, sequela',
  'T63303A Toxic effect of unspecified spider venom, assault, initial encounter',
  'T63303D Toxic effect of unspecified spider venom, assault, subsequent encounter',
  'T63303S Toxic effect of unspecified spider venom, assault, sequela',
  'T63304A Toxic effect of unspecified spider venom, undetermined, initial encounter',
  'T63304D Toxic effect of unspecified spider venom, undetermined, subsequent encounter',
  'T63304S Toxic effect of unspecified spider venom, undetermined, sequela',
  'T63311A Toxic effect of venom of black widow spider, accidental (unintentional), initial encounter',
  'T63311D Toxic effect of venom of black widow spider, accidental (unintentional), subsequent encounter',
  'T63311S Toxic effect of venom of black widow spider, accidental (unintentional), sequela',
  'T63312A Toxic effect of venom of black widow spider, intentional self-harm, initial encounter',
  'T63312D Toxic effect of venom of black widow spider, intentional self-harm, subsequent encounter',
  'T63312S Toxic effect of venom of black widow spider, intentional self-harm, sequela',
  'T63313A Toxic effect of venom of black widow spider, assault, initial encounter',
  'T63313D Toxic effect of venom of black widow spider, assault, subsequent encounter',
  'T63313S Toxic effect of venom of black widow spider, assault, sequela',
  'T63314A Toxic effect of venom of black widow spider, undetermined, initial encounter',
  'T63314D Toxic effect of venom of black widow spider, undetermined, subsequent encounter',
  'T63314S Toxic effect of venom of black widow spider, undetermined, sequela',
  'T63321A Toxic effect of venom of tarantula, accidental (unintentional), initial encounter',
  'T63321D Toxic effect of venom of tarantula, accidental (unintentional), subsequent encounter',
  'T63321S Toxic effect of venom of tarantula, accidental (unintentional), sequela',
  'T63322A Toxic effect of venom of tarantula, intentional self-harm, initial encounter',
  'T63322D Toxic effect of venom of tarantula, intentional self-harm, subsequent encounter',
  'T63322S Toxic effect of venom of tarantula, intentional self-harm, sequela',
  'T63323A Toxic effect of venom of tarantula, assault, initial encounter',
  'T63323D Toxic effect of venom of tarantula, assault, subsequent encounter',
  'T63323S Toxic effect of venom of tarantula, assault, sequela',
  'T63324A Toxic effect of venom of tarantula, undetermined, initial encounter',
  'T63324D Toxic effect of venom of tarantula, undetermined, subsequent encounter',
  'T63324S Toxic effect of venom of tarantula, undetermined, sequela',
  'T63331A Toxic effect of venom of brown recluse spider, accidental (unintentional), initial encounter',
  'T63331D Toxic effect of venom of brown recluse spider, accidental (unintentional), subsequent',
  'encounter',
  'T63331S Toxic effect of venom of brown recluse spider, accidental (unintentional), sequela',
  'T63332A Toxic effect of venom of brown recluse spider, intentional self-harm, initial encounter',
  'T63332D Toxic effect of venom of brown recluse spider, intentional self-harm, subsequent encounter',
  'T63332S Toxic effect of venom of brown recluse spider, intentional self-harm, sequela',
  'T63333A Toxic effect of venom of brown recluse spider, assault, initial encounter',
  'T63333D Toxic effect of venom of brown recluse spider, assault, subsequent encounter',
  'T63333S Toxic effect of venom of brown recluse spider, assault, sequela',
  'T63334A Toxic effect of venom of brown recluse spider, undetermined, initial encounter',
  'T63334D Toxic effect of venom of brown recluse spider, undetermined, subsequent encounter',
  'T63334S Toxic effect of venom of brown recluse spider, undetermined, sequela',
  'T63391A Toxic effect of venom of other spider, accidental (unintentional), initial encounter',
  'T63391D Toxic effect of venom of other spider, accidental (unintentional), subsequent encounter',
  'T63391S Toxic effect of venom of other spider, accidental (unintentional), sequela',
  'T63392A Toxic effect of venom of other spider, intentional self-harm, initial encounter',
  'T63392D Toxic effect of venom of other spider, intentional self-harm, subsequent encounter',
  'T63392S Toxic effect of venom of other spider, intentional self-harm, sequela',
  'T63393A Toxic effect of venom of other spider, assault, initial encounter',
  'T63393D Toxic effect of venom of other spider, assault, subsequent encounter',
  'T63393S Toxic effect of venom of other spider, assault, sequela',
  'T63394A Toxic effect of venom of other spider, undetermined, initial encounter',
  'T63394D Toxic effect of venom of other spider, undetermined, subsequent encounter',
  'T63394S Toxic effect of venom of other spider, undetermined, sequela',
  'T63411A Toxic effect of venom of centipedes and venomous millipedes, accidental (unintentional),',
  'initial encounter',
  'T63411D Toxic effect of venom of centipedes and venomous millipedes, accidental (unintentional),',
  'subsequent encounter',
  'T63411S Toxic effect of venom of centipedes and venomous millipedes, accidental (unintentional),',
  'sequela',
  'T63412A Toxic effect of venom of centipedes and venomous millipedes, intentional self-harm, initial',
  'encounter',
  'T63412D Toxic effect of venom of centipedes and venomous millipedes, intentional self-harm,',
  'subsequent encounter',
  'T63412S Toxic effect of venom of centipedes and venomous millipedes, intentional self-harm, sequela',
  'T63413A Toxic effect of venom of centipedes and venomous millipedes, assault, initial encounter',
  'T63413D Toxic effect of venom of centipedes and venomous millipedes, assault, subsequent encounter',
  'T63413S Toxic effect of venom of centipedes and venomous millipedes, assault, sequela',
  'T63414A Toxic effect of venom of centipedes and venomous millipedes, undetermined, initial encounter',
  'T63414D Toxic effect of venom of centipedes and venomous millipedes, undetermined, subsequent',
  'encounter',
  'T63414S Toxic effect of venom of centipedes and venomous millipedes, undetermined, sequela',
  'T63421A Toxic effect of venom of ants, accidental (unintentional), initial encounter',
  'T63421D Toxic effect of venom of ants, accidental (unintentional), subsequent encounter',
  'T63421S Toxic effect of venom of ants, accidental (unintentional), sequela',
  'T63422A Toxic effect of venom of ants, intentional self-harm, initial encounter',
  'T63422D Toxic effect of venom of ants, intentional self-harm, subsequent encounter',
  'T63422S Toxic effect of venom of ants, intentional self-harm, sequela',
  'T63423A Toxic effect of venom of ants, assault, initial encounter',
  'T63423D Toxic effect of venom of ants, assault, subsequent encounter',
  'T63423S Toxic effect of venom of ants, assault, sequela',
  'T63424A Toxic effect of venom of ants, undetermined, initial encounter',
  'T63424D Toxic effect of venom of ants, undetermined, subsequent encounter',
  'T63424S Toxic effect of venom of ants, undetermined, sequela',
  'T63431A Toxic effect of venom of caterpillars, accidental (unintentional), initial encounter',
  'T63431D Toxic effect of venom of caterpillars, accidental (unintentional), subsequent encounter',
  'T63431S Toxic effect of venom of caterpillars, accidental (unintentional), sequela',
  'T63432A Toxic effect of venom of caterpillars, intentional self-harm, initial encounter',
  'T63432D Toxic effect of venom of caterpillars, intentional self-harm, subsequent encounter',
  'T63432S Toxic effect of venom of caterpillars, intentional self-harm, sequela',
  'T63433A Toxic effect of venom of caterpillars, assault, initial encounter',
  'T63433D Toxic effect of venom of caterpillars, assault, subsequent encounter',
  'T63433S Toxic effect of venom of caterpillars, assault, sequela',
  'T63434A Toxic effect of venom of caterpillars, undetermined, initial encounter',
  'T63434D Toxic effect of venom of caterpillars, undetermined, subsequent encounter',
  'T63434S Toxic effect of venom of caterpillars, undetermined, sequela',
  'T63441A Toxic effect of venom of bees, accidental (unintentional), initial encounter',
  'T63441D Toxic effect of venom of bees, accidental (unintentional), subsequent encounter',
  'T63441S Toxic effect of venom of bees, accidental (unintentional), sequela',
  'T63442A Toxic effect of venom of bees, intentional self-harm, initial encounter',
  'T63442D Toxic effect of venom of bees, intentional self-harm, subsequent encounter',
  'T63442S Toxic effect of venom of bees, intentional self-harm, sequela',
  'T63443A Toxic effect of venom of bees, assault, initial encounter',
  'T63443D Toxic effect of venom of bees, assault, subsequent encounter',
  'T63443S Toxic effect of venom of bees, assault, sequela',
  'T63444A Toxic effect of venom of bees, undetermined, initial encounter',
  'T63444D Toxic effect of venom of bees, undetermined, subsequent encounter',
  'T63444S Toxic effect of venom of bees, undetermined, sequela',
  'T63451A Toxic effect of venom of hornets, accidental (unintentional), initial encounter',
  'T63451D Toxic effect of venom of hornets, accidental (unintentional), subsequent encounter',
  'T63451S Toxic effect of venom of hornets, accidental (unintentional), sequela',
  'T63452A Toxic effect of venom of hornets, intentional self-harm, initial encounter',
  'T63452D Toxic effect of venom of hornets, intentional self-harm, subsequent encounter',
  'T63452S Toxic effect of venom of hornets, intentional self-harm, sequela',
  'T63453A Toxic effect of venom of hornets, assault, initial encounter',
  'T63453D Toxic effect of venom of hornets, assault, subsequent encounter',
  'T63453S Toxic effect of venom of hornets, assault, sequela',
  'T63454A Toxic effect of venom of hornets, undetermined, initial encounter',
  'T63454D Toxic effect of venom of hornets, undetermined, subsequent encounter',
  'T63454S Toxic effect of venom of hornets, undetermined, sequela',
  'T63461A Toxic effect of venom of wasps, accidental (unintentional), initial encounter',
  'T63461D Toxic effect of venom of wasps, accidental (unintentional), subsequent encounter',
  'T63461S Toxic effect of venom of wasps, accidental (unintentional), sequela',
  'T63462A Toxic effect of venom of wasps, intentional self-harm, initial encounter',
  'T63462D Toxic effect of venom of wasps, intentional self-harm, subsequent encounter',
  'T63462S Toxic effect of venom of wasps, intentional self-harm, sequela',
  'T63463A Toxic effect of venom of wasps, assault, initial encounter',
  'T63463D Toxic effect of venom of wasps, assault, subsequent encounter',
  'T63463S Toxic effect of venom of wasps, assault, sequela',
  'T63464A Toxic effect of venom of wasps, undetermined, initial encounter',
  'T63464D Toxic effect of venom of wasps, undetermined, subsequent encounter',
  'T63464S Toxic effect of venom of wasps, undetermined, sequela',
  'T63481A Toxic effect of venom of other arthropod, accidental (unintentional), initial encounter',
  'T63481D Toxic effect of venom of other arthropod, accidental (unintentional), subsequent encounter',
  'T63481S Toxic effect of venom of other arthropod, accidental (unintentional), sequela',
  'T63482A Toxic effect of venom of other arthropod, intentional self-harm, initial encounter',
  'T63482D Toxic effect of venom of other arthropod, intentional self-harm, subsequent encounter',
  'T63482S Toxic effect of venom of other arthropod, intentional self-harm, sequela',
  'T63483A Toxic effect of venom of other arthropod, assault, initial encounter',
  'T63483D Toxic effect of venom of other arthropod, assault, subsequent encounter',
  'T63483S Toxic effect of venom of other arthropod, assault, sequela',
  'T63484A Toxic effect of venom of other arthropod, undetermined, initial encounter',
  'T63484D Toxic effect of venom of other arthropod, undetermined, subsequent encounter',
  'T63484S Toxic effect of venom of other arthropod, undetermined, sequela',
  'T63511A Toxic effect of contact with stingray, accidental (unintentional), initial encounter',
  'T63511D Toxic effect of contact with stingray, accidental (unintentional), subsequent encounter',
  'T63511S Toxic effect of contact with stingray, accidental (unintentional), sequela',
  'T63512A Toxic effect of contact with stingray, intentional self-harm, initial encounter',
  'T63512D Toxic effect of contact with stingray, intentional self-harm, subsequent encounter',
  'T63512S Toxic effect of contact with stingray, intentional self-harm, sequela',
  'T63513A Toxic effect of contact with stingray, assault, initial encounter',
  'T63513D Toxic effect of contact with stingray, assault, subsequent encounter',
  'T63513S Toxic effect of contact with stingray, assault, sequela',
  'T63514A Toxic effect of contact with stingray, undetermined, initial encounter',
  'T63514D Toxic effect of contact with stingray, undetermined, subsequent encounter',
  'T63514S Toxic effect of contact with stingray, undetermined, sequela',
  'T63591A Toxic effect of contact with other venomous fish, accidental (unintentional), initial encounter',
  'T63591D Toxic effect of contact with other venomous fish, accidental (unintentional), subsequent',
  'encounter',
  'T63591S Toxic effect of contact with other venomous fish, accidental (unintentional), sequela',
  'T63592A Toxic effect of contact with other venomous fish, intentional self-harm, initial encounter',
  'T63592D Toxic effect of contact with other venomous fish, intentional self-harm, subsequent encounter',
  'T63592S Toxic effect of contact with other venomous fish, intentional self-harm, sequela',
  'T63593A Toxic effect of contact with other venomous fish, assault, initial encounter',
  'T63593D Toxic effect of contact with other venomous fish, assault, subsequent encounter',
  'T63593S Toxic effect of contact with other venomous fish, assault, sequela',
  'T63594A Toxic effect of contact with other venomous fish, undetermined, initial encounter',
  'T63594D Toxic effect of contact with other venomous fish, undetermined, subsequent encounter',
  'T63594S Toxic effect of contact with other venomous fish, undetermined, sequela',
  'T63611A Toxic effect of contact with Portugese Man-o-war, accidental (unintentional), initial encounter',
  'T63611D Toxic effect of contact with Portugese Man-o-war, accidental (unintentional), subsequent',
  'encounter',
  'T63611S Toxic effect of contact with Portugese Man-o-war, accidental (unintentional), sequela',
  'T63612A Toxic effect of contact with Portugese Man-o-war, intentional self-harm, initial encounter',
  'T63612D Toxic effect of contact with Portugese Man-o-war, intentional self-harm, subsequent',
  'encounter',
  'T63612S Toxic effect of contact with Portugese Man-o-war, intentional self-harm, sequela',
  'T63613A Toxic effect of contact with Portugese Man-o-war, assault, initial encounter',
  'T63613D Toxic effect of contact with Portugese Man-o-war, assault, subsequent encounter',
  'T63613S Toxic effect of contact with Portugese Man-o-war, assault, sequela',
  'T63614A Toxic effect of contact with Portugese Man-o-war, undetermined, initial encounter',
  'T63614D Toxic effect of contact with Portugese Man-o-war, undetermined, subsequent encounter',
  'T63614S Toxic effect of contact with Portugese Man-o-war, undetermined, sequela',
  'T63621A Toxic effect of contact with other jellyfish, accidental (unintentional), initial encounter',
  'T63621D Toxic effect of contact with other jellyfish, accidental (unintentional), subsequent encounter',
  'T63621S Toxic effect of contact with other jellyfish, accidental (unintentional), sequela',
  'T63622A Toxic effect of contact with other jellyfish, intentional self-harm, initial encounter',
  'T63622D Toxic effect of contact with other jellyfish, intentional self-harm, subsequent encounter',
  'T63622S Toxic effect of contact with other jellyfish, intentional self-harm, sequela',
  'T63623A Toxic effect of contact with other jellyfish, assault, initial encounter',
  'T63623D Toxic effect of contact with other jellyfish, assault, subsequent encounter',
  'T63623S Toxic effect of contact with other jellyfish, assault, sequela',
  'T63624A Toxic effect of contact with other jellyfish, undetermined, initial encounter',
  'T63624D Toxic effect of contact with other jellyfish, undetermined, subsequent encounter',
  'T63624S Toxic effect of contact with other jellyfish, undetermined, sequela',
  'T63631A Toxic effect of contact with sea anemone, accidental (unintentional), initial encounter',
  'T63631D Toxic effect of contact with sea anemone, accidental (unintentional), subsequent encounter',
  'T63631S Toxic effect of contact with sea anemone, accidental (unintentional), sequela',
  'T63632A Toxic effect of contact with sea anemone, intentional self-harm, initial encounter',
  'T63632D Toxic effect of contact with sea anemone, intentional self-harm, subsequent encounter',
  'T63632S Toxic effect of contact with sea anemone, intentional self-harm, sequela',
  'T63633A Toxic effect of contact with sea anemone, assault, initial encounter',
  'T63633D Toxic effect of contact with sea anemone, assault, subsequent encounter',
  'T63633S Toxic effect of contact with sea anemone, assault, sequela',
  'T63634A Toxic effect of contact with sea anemone, undetermined, initial encounter',
  'T63634D Toxic effect of contact with sea anemone, undetermined, subsequent encounter',
  'T63634S Toxic effect of contact with sea anemone, undetermined, sequela',
  'T63691A Toxic effect of contact with other venomous marine animals, accidental (unintentional), initial',
  'encounter',
  'T63691D Toxic effect of contact with other venomous marine animals, accidental (unintentional),',
  'subsequent encounter',
  'T63691S Toxic effect of contact with other venomous marine animals, accidental (unintentional),',
  'sequela',
  'T63692A Toxic effect of contact with other venomous marine animals, intentional self-harm, initial',
  'encounter',
  'T63692D Toxic effect of contact with other venomous marine animals, intentional self-harm, subsequent',
  'encounter',
  'T63692S Toxic effect of contact with other venomous marine animals, intentional self-harm, sequela',
  'T63693A Toxic effect of contact with other venomous marine animals, assault, initial encounter',
  'T63693D Toxic effect of contact with other venomous marine animals, assault, subsequent encounter',
  'T63693S Toxic effect of contact with other venomous marine animals, assault, sequela',
  'T63694A Toxic effect of contact with other venomous marine animals, undetermined, initial encounter',
  'T63694D Toxic effect of contact with other venomous marine animals, undetermined, subsequent',
  'encounter',
  'T63694S Toxic effect of contact with other venomous marine animals, undetermined, sequela',
  'T63711A Toxic effect of contact with venomous marine plant, accidental (unintentional), initial',
  'encounter',
  'T63711D Toxic effect of contact with venomous marine plant, accidental (unintentional), subsequent',
  'encounter',
  'T63711S Toxic effect of contact with venomous marine plant, accidental (unintentional), sequela',
  'T63712A Toxic effect of contact with venomous marine plant, intentional self-harm, initial encounter',
  'T63712D Toxic effect of contact with venomous marine plant, intentional self-harm, subsequent',
  'encounter',
  'T63712S Toxic effect of contact with venomous marine plant, intentional self-harm, sequela',
  'T63713A Toxic effect of contact with venomous marine plant, assault, initial encounter',
  'T63713D Toxic effect of contact with venomous marine plant, assault, subsequent encounter',
  'T63713S Toxic effect of contact with venomous marine plant, assault, sequela',
  'T63714A Toxic effect of contact with venomous marine plant, undetermined, initial encounter',
  'T63714D Toxic effect of contact with venomous marine plant, undetermined, subsequent encounter',
  'T63714S Toxic effect of contact with venomous marine plant, undetermined, sequela',
  'T63791A Toxic effect of contact with other venomous plant, accidental (unintentional), initial encounter',
  'T63791D Toxic effect of contact with other venomous plant, accidental (unintentional), subsequent',
  'encounter',
  'T63791S Toxic effect of contact with other venomous plant, accidental (unintentional), sequela',
  'T63792A Toxic effect of contact with other venomous plant, intentional self-harm, initial encounter',
  'T63792D Toxic effect of contact with other venomous plant, intentional self-harm, subsequent',
  'encounter',
  'T63792S Toxic effect of contact with other venomous plant, intentional self-harm, sequela',
  'T63793A Toxic effect of contact with other venomous plant, assault, initial encounter',
  'T63793D Toxic effect of contact with other venomous plant, assault, subsequent encounter',
  'T63793S Toxic effect of contact with other venomous plant, assault, sequela',
  'T63794A Toxic effect of contact with other venomous plant, undetermined, initial encounter',
  'T63794D Toxic effect of contact with other venomous plant, undetermined, subsequent encounter',
  'T63794S Toxic effect of contact with other venomous plant, undetermined, sequela',
  'T63811A Toxic effect of contact with venomous frog, accidental (unintentional), initial encounter',
  'T63811D Toxic effect of contact with venomous frog, accidental (unintentional), subsequent encounter',
  'T63811S Toxic effect of contact with venomous frog, accidental (unintentional), sequela',
  'T63812A Toxic effect of contact with venomous frog, intentional self-harm, initial encounter',
  'T63812D Toxic effect of contact with venomous frog, intentional self-harm, subsequent encounter',
  'T63812S Toxic effect of contact with venomous frog, intentional self-harm, sequela',
  'T63813A Toxic effect of contact with venomous frog, assault, initial encounter',
  'T63813D Toxic effect of contact with venomous frog, assault, subsequent encounter',
  'T63813S Toxic effect of contact with venomous frog, assault, sequela',
  'T63814A Toxic effect of contact with venomous frog, undetermined, initial encounter',
  'T63814D Toxic effect of contact with venomous frog, undetermined, subsequent encounter',
  'T63814S Toxic effect of contact with venomous frog, undetermined, sequela',
  'T63821A Toxic effect of contact with venomous toad, accidental (unintentional), initial encounter',
  'T63821D Toxic effect of contact with venomous toad, accidental (unintentional), subsequent encounter',
  'T63821S Toxic effect of contact with venomous toad, accidental (unintentional), sequela',
  'T63822A Toxic effect of contact with venomous toad, intentional self-harm, initial encounter',
  'T63822D Toxic effect of contact with venomous toad, intentional self-harm, subsequent encounter',
  'T63822S Toxic effect of contact with venomous toad, intentional self-harm, sequela',
  'T63823A Toxic effect of contact with venomous toad, assault, initial encounter',
  'T63823D Toxic effect of contact with venomous toad, assault, subsequent encounter',
  'T63823S Toxic effect of contact with venomous toad, assault, sequela',
  'T63824A Toxic effect of contact with venomous toad, undetermined, initial encounter',
  'T63824D Toxic effect of contact with venomous toad, undetermined, subsequent encounter',
  'T63824S Toxic effect of contact with venomous toad, undetermined, sequela',
  'T63831A Toxic effect of contact with other venomous amphibian, accidental (unintentional), initial',
  'encounter',
  'T63831D Toxic effect of contact with other venomous amphibian, accidental (unintentional),',
  'subsequent encounter',
  'T63831S Toxic effect of contact with other venomous amphibian, accidental (unintentional), sequela',
  'T63832A Toxic effect of contact with other venomous amphibian, intentional self-harm, initial encounter',
  'T63832D Toxic effect of contact with other venomous amphibian, intentional self-harm, subsequent',
  'encounter',
  'T63832S Toxic effect of contact with other venomous amphibian, intentional self-harm, sequela',
  'T63833A Toxic effect of contact with other venomous amphibian, assault, initial encounter',
  'T63833D Toxic effect of contact with other venomous amphibian, assault, subsequent encounter',
  'T63833S Toxic effect of contact with other venomous amphibian, assault, sequela',
  'T63834A Toxic effect of contact with other venomous amphibian, undetermined, initial encounter',
  'T63834D Toxic effect of contact with other venomous amphibian, undetermined, subsequent encounter',
  'T63834S Toxic effect of contact with other venomous amphibian, undetermined, sequela',
  'T63891A Toxic effect of contact with other venomous animals, accidental (unintentional), initial',
  'encounter',
  'T63891D Toxic effect of contact with other venomous animals, accidental (unintentional), subsequent',
  'encounter',
  'T63891S Toxic effect of contact with other venomous animals, accidental (unintentional), sequela',
  'T63892A Toxic effect of contact with other venomous animals, intentional self-harm, initial encounter',
  'T63892D Toxic effect of contact with other venomous animals, intentional self-harm, subsequent',
  'encounter',
  'T63892S Toxic effect of contact with other venomous animals, intentional self-harm, sequela',
  'T63893A Toxic effect of contact with other venomous animals, assault, initial encounter',
  'T63893D Toxic effect of contact with other venomous animals, assault, subsequent encounter',
  'T63893S Toxic effect of contact with other venomous animals, assault, sequela',
  'T63894A Toxic effect of contact with other venomous animals, undetermined, initial encounter',
  'T63894D Toxic effect of contact with other venomous animals, undetermined, subsequent encounter',
  'T63894S Toxic effect of contact with other venomous animals, undetermined, sequela',
  'T6391XA Toxic effect of contact with unspecified venomous animal, accidental (unintentional), initial',
  'encounter',
  'T6391XD Toxic effect of contact with unspecified venomous animal, accidental (unintentional),',
  'subsequent encounter',
  'T6391XS Toxic effect of contact with unspecified venomous animal, accidental (unintentional), sequela',
  'T6392XA Toxic effect of contact with unspecified venomous animal, intentional self-harm, initial',
  'encounter',
  'T6392XD Toxic effect of contact with unspecified venomous animal, intentional self-harm, subsequent',
  'encounter',
  'T6392XS Toxic effect of contact with unspecified venomous animal, intentional self-harm, sequela',
  'T6393XA Toxic effect of contact with unspecified venomous animal, assault, initial encounter',
  'T6393XD Toxic effect of contact with unspecified venomous animal, assault, subsequent encounter',
  'T6393XS Toxic effect of contact with unspecified venomous animal, assault, sequela',
  'T6394XA Toxic effect of contact with unspecified venomous animal, undetermined, initial encounter',
  'T6394XD Toxic effect of contact with unspecified venomous animal, undetermined, subsequent',
  'encounter',
  'T6394XS Toxic effect of contact with unspecified venomous animal, undetermined, sequela',
  'T6401XA Toxic effect of aflatoxin, accidental (unintentional), initial encounter',
  'T6401XD Toxic effect of aflatoxin, accidental (unintentional), subsequent encounter',
  'T6401XS Toxic effect of aflatoxin, accidental (unintentional), sequela',
  'T6402XA Toxic effect of aflatoxin, intentional self-harm, initial encounter',
  'T6402XD Toxic effect of aflatoxin, intentional self-harm, subsequent encounter',
  'T6402XS Toxic effect of aflatoxin, intentional self-harm, sequela',
  'T6403XA Toxic effect of aflatoxin, assault, initial encounter',
  'T6403XD Toxic effect of aflatoxin, assault, subsequent encounter',
  'T6403XS Toxic effect of aflatoxin, assault, sequela',
  'T6404XA Toxic effect of aflatoxin, undetermined, initial encounter',
  'T6404XD Toxic effect of aflatoxin, undetermined, subsequent encounter',
  'T6404XS Toxic effect of aflatoxin, undetermined, sequela',
  'T6481XA Toxic effect of other mycotoxin food contaminants, accidental (unintentional), initial encounter',
  'T6481XD Toxic effect of other mycotoxin food contaminants, accidental (unintentional), subsequent',
  'encounter',
  'T6481XS Toxic effect of other mycotoxin food contaminants, accidental (unintentional), sequela',
  'T6482XA Toxic effect of other mycotoxin food contaminants, intentional self-harm, initial encounter',
  'T6482XD Toxic effect of other mycotoxin food contaminants, intentional self-harm, subsequent',
  'encounter',
  'T6482XS Toxic effect of other mycotoxin food contaminants, intentional self-harm, sequela',
  'T6483XA Toxic effect of other mycotoxin food contaminants, assault, initial encounter',
  'T6483XD Toxic effect of other mycotoxin food contaminants, assault, subsequent encounter',
  'T6483XS Toxic effect of other mycotoxin food contaminants, assault, sequela',
  'T6484XA Toxic effect of other mycotoxin food contaminants, undetermined, initial encounter',
  'T6484XD Toxic effect of other mycotoxin food contaminants, undetermined, subsequent encounter',
  'T6484XS Toxic effect of other mycotoxin food contaminants, undetermined, sequela',
  'T650X1A Toxic effect of cyanides, accidental (unintentional), initial encounter',
  'T650X1D Toxic effect of cyanides, accidental (unintentional), subsequent encounter',
  'T650X1S Toxic effect of cyanides, accidental (unintentional), sequela',
  'T650X2A Toxic effect of cyanides, intentional self-harm, initial encounter',
  'T650X2D Toxic effect of cyanides, intentional self-harm, subsequent encounter',
  'T650X2S Toxic effect of cyanides, intentional self-harm, sequela',
  'T650X3A Toxic effect of cyanides, assault, initial encounter',
  'T650X3D Toxic effect of cyanides, assault, subsequent encounter',
  'T650X3S Toxic effect of cyanides, assault, sequela',
  'T650X4A Toxic effect of cyanides, undetermined, initial encounter',
  'T650X4D Toxic effect of cyanides, undetermined, subsequent encounter',
  'T650X4S Toxic effect of cyanides, undetermined, sequela',
  'T651X1A Toxic effect of strychnine and its salts, accidental (unintentional), initial encounter',
  'T651X1D Toxic effect of strychnine and its salts, accidental (unintentional), subsequent encounter',
  'T651X1S Toxic effect of strychnine and its salts, accidental (unintentional), sequela',
  'T651X2A Toxic effect of strychnine and its salts, intentional self-harm, initial encounter',
  'T651X2D Toxic effect of strychnine and its salts, intentional self-harm, subsequent encounter',
  'T651X2S Toxic effect of strychnine and its salts, intentional self-harm, sequela',
  'T651X3A Toxic effect of strychnine and its salts, assault, initial encounter',
  'T651X3D Toxic effect of strychnine and its salts, assault, subsequent encounter',
  'T651X3S Toxic effect of strychnine and its salts, assault, sequela',
  'T651X4A Toxic effect of strychnine and its salts, undetermined, initial encounter',
  'T651X4D Toxic effect of strychnine and its salts, undetermined, subsequent encounter',
  'T651X4S Toxic effect of strychnine and its salts, undetermined, sequela',
  'T65211A Toxic effect of chewing tobacco, accidental (unintentional), initial encounter',
  'T65211D Toxic effect of chewing tobacco, accidental (unintentional), subsequent encounter',
  'T65211S Toxic effect of chewing tobacco, accidental (unintentional), sequela',
  'T65212A Toxic effect of chewing tobacco, intentional self-harm, initial encounter',
  'T65212D Toxic effect of chewing tobacco, intentional self-harm, subsequent encounter',
  'T65212S Toxic effect of chewing tobacco, intentional self-harm, sequela',
  'T65213A Toxic effect of chewing tobacco, assault, initial encounter',
  'T65213D Toxic effect of chewing tobacco, assault, subsequent encounter',
  'T65213S Toxic effect of chewing tobacco, assault, sequela',
  'T65214A Toxic effect of chewing tobacco, undetermined, initial encounter',
  'T65214D Toxic effect of chewing tobacco, undetermined, subsequent encounter',
  'T65214S Toxic effect of chewing tobacco, undetermined, sequela',
  'T65221A Toxic effect of tobacco cigarettes, accidental (unintentional), initial encounter',
  'T65221D Toxic effect of tobacco cigarettes, accidental (unintentional), subsequent encounter',
  'T65221S Toxic effect of tobacco cigarettes, accidental (unintentional), sequela',
  'T65222A Toxic effect of tobacco cigarettes, intentional self-harm, initial encounter',
  'T65222D Toxic effect of tobacco cigarettes, intentional self-harm, subsequent encounter',
  'T65222S Toxic effect of tobacco cigarettes, intentional self-harm, sequela',
  'T65223A Toxic effect of tobacco cigarettes, assault, initial encounter',
  'T65223D Toxic effect of tobacco cigarettes, assault, subsequent encounter',
  'T65223S Toxic effect of tobacco cigarettes, assault, sequela',
  'T65224A Toxic effect of tobacco cigarettes, undetermined, initial encounter',
  'T65224D Toxic effect of tobacco cigarettes, undetermined, subsequent encounter',
  'T65224S Toxic effect of tobacco cigarettes, undetermined, sequela',
  'T65291A Toxic effect of other tobacco and nicotine, accidental (unintentional), initial encounter',
  'T65291D Toxic effect of other tobacco and nicotine, accidental (unintentional), subsequent encounter',
  'T65291S Toxic effect of other tobacco and nicotine, accidental (unintentional), sequela',
  'T65292A Toxic effect of other tobacco and nicotine, intentional self-harm, initial encounter',
  'T65292D Toxic effect of other tobacco and nicotine, intentional self-harm, subsequent encounter',
  'T65292S Toxic effect of other tobacco and nicotine, intentional self-harm, sequela',
  'T65293A Toxic effect of other tobacco and nicotine, assault, initial encounter',
  'T65293D Toxic effect of other tobacco and nicotine, assault, subsequent encounter',
  'T65293S Toxic effect of other tobacco and nicotine, assault, sequela',
  'T65294A Toxic effect of other tobacco and nicotine, undetermined, initial encounter',
  'T65294D Toxic effect of other tobacco and nicotine, undetermined, subsequent encounter',
  'T65294S Toxic effect of other tobacco and nicotine, undetermined, sequela',
  'T653X1A Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'accidental (unintentional), initial encounter',
  'T653X1D Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'accidental (unintentional), subsequent encounter',
  'T653X1S Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'accidental (unintentional), sequela',
  'T653X2A Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'intentional self-harm, initial encounter',
  'T653X2D Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'intentional self-harm, subsequent encounter',
  'T653X2S Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'intentional self-harm, sequela',
  'T653X3A Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues, assault,',
  'initial encounter',
  'T653X3D Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues, assault,',
  'subsequent encounter',
  'T653X3S Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues, assault,',
  'sequela',
  'T653X4A Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'undetermined, initial encounter',
  'T653X4D Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'undetermined, subsequent encounter',
  'T653X4S Toxic effect of nitroderivatives and aminoderivatives of benzene and its homologues,',
  'undetermined, sequela',
  'T654X1A Toxic effect of carbon disulfide, accidental (unintentional), initial encounter',
  'T654X1D Toxic effect of carbon disulfide, accidental (unintentional), subsequent encounter',
  'T654X1S Toxic effect of carbon disulfide, accidental (unintentional), sequela',
  'T654X2A Toxic effect of carbon disulfide, intentional self-harm, initial encounter',
  'T654X2D Toxic effect of carbon disulfide, intentional self-harm, subsequent encounter',
  'T654X2S Toxic effect of carbon disulfide, intentional self-harm, sequela',
  'T654X3A Toxic effect of carbon disulfide, assault, initial encounter',
  'T654X3D Toxic effect of carbon disulfide, assault, subsequent encounter',
  'T654X3S Toxic effect of carbon disulfide, assault, sequela',
  'T654X4A Toxic effect of carbon disulfide, undetermined, initial encounter',
  'T654X4D Toxic effect of carbon disulfide, undetermined, subsequent encounter',
  'T654X4S Toxic effect of carbon disulfide, undetermined, sequela',
  'T655X1A Toxic effect of nitroglycerin and other nitric acids and esters, accidental (unintentional), initial',
  'encounter',
  'T655X1D Toxic effect of nitroglycerin and other nitric acids and esters, accidental (unintentional),',
  'subsequent encounter',
  'T655X1S Toxic effect of nitroglycerin and other nitric acids and esters, accidental (unintentional),',
  'sequela',
  'T655X2A Toxic effect of nitroglycerin and other nitric acids and esters, intentional self-harm, initial',
  'encounter',
  'T655X2D Toxic effect of nitroglycerin and other nitric acids and esters, intentional self-harm, subsequent',
  'encounter',
  'T655X2S Toxic effect of nitroglycerin and other nitric acids and esters, intentional self-harm, sequela',
  'T655X3A Toxic effect of nitroglycerin and other nitric acids and esters, assault, initial encounter',
  'T655X3D Toxic effect of nitroglycerin and other nitric acids and esters, assault, subsequent encounter',
  'T655X3S Toxic effect of nitroglycerin and other nitric acids and esters, assault, sequela',
  'T655X4A Toxic effect of nitroglycerin and other nitric acids and esters, undetermined, initial encounter',
  'T655X4D Toxic effect of nitroglycerin and other nitric acids and esters, undetermined, subsequent',
  'encounter',
  'T655X4S Toxic effect of nitroglycerin and other nitric acids and esters, undetermined, sequela',
  'T656X1A Toxic effect of paints and dyes, not elsewhere classified, accidental (unintentional), initial',
  'encounter',
  'T656X1D Toxic effect of paints and dyes, not elsewhere classified, accidental (unintentional),',
  'subsequent encounter',
  'T656X1S Toxic effect of paints and dyes, not elsewhere classified, accidental (unintentional), sequela',
  'T656X2A Toxic effect of paints and dyes, not elsewhere classified, intentional self-harm, initial',
  'encounter',
  'T656X2D Toxic effect of paints and dyes, not elsewhere classified, intentional self-harm, subsequent',
  'encounter',
  'T656X2S Toxic effect of paints and dyes, not elsewhere classified, intentional self-harm, sequela',
  'T656X3A Toxic effect of paints and dyes, not elsewhere classified, assault, initial encounter',
  'T656X3D Toxic effect of paints and dyes, not elsewhere classified, assault, subsequent encounter',
  'T656X3S Toxic effect of paints and dyes, not elsewhere classified, assault, sequela',
  'T656X4A Toxic effect of paints and dyes, not elsewhere classified, undetermined, initial encounter',
  'T656X4D Toxic effect of paints and dyes, not elsewhere classified, undetermined, subsequent encounter',
  'T656X4S Toxic effect of paints and dyes, not elsewhere classified, undetermined, sequela',
  'T65811A Toxic effect of latex, accidental (unintentional), initial encounter',
  'T65811D Toxic effect of latex, accidental (unintentional), subsequent encounter',
  'T65811S Toxic effect of latex, accidental (unintentional), sequela',
  'T65812A Toxic effect of latex, intentional self-harm, initial encounter',
  'T65812D Toxic effect of latex, intentional self-harm, subsequent encounter',
  'T65812S Toxic effect of latex, intentional self-harm, sequela',
  'T65813A Toxic effect of latex, assault, initial encounter',
  'T65813D Toxic effect of latex, assault, subsequent encounter',
  'T65813S Toxic effect of latex, assault, sequela',
  'T65814A Toxic effect of latex, undetermined, initial encounter',
  'T65814D Toxic effect of latex, undetermined, subsequent encounter',
  'T65814S Toxic effect of latex, undetermined, sequela',
  'T65821A Toxic effect of harmful algae and algae toxins, accidental (unintentional), initial encounter',
  'T65821D Toxic effect of harmful algae and algae toxins, accidental (unintentional), subsequent',
  'encounter',
  'T65821S Toxic effect of harmful algae and algae toxins, accidental (unintentional), sequela',
  'T65822A Toxic effect of harmful algae and algae toxins, intentional self-harm, initial encounter',
  'T65822D Toxic effect of harmful algae and algae toxins, intentional self-harm, subsequent encounter',
  'T65822S Toxic effect of harmful algae and algae toxins, intentional self-harm, sequela',
  'T65823A Toxic effect of harmful algae and algae toxins, assault, initial encounter',
  'T65823D Toxic effect of harmful algae and algae toxins, assault, subsequent encounter',
  'T65823S Toxic effect of harmful algae and algae toxins, assault, sequela',
  'T65824A Toxic effect of harmful algae and algae toxins, undetermined, initial encounter',
  'T65824D Toxic effect of harmful algae and algae toxins, undetermined, subsequent encounter',
  'T65824S Toxic effect of harmful algae and algae toxins, undetermined, sequela',
  'T65831A Toxic effect of fiberglass, accidental (unintentional), initial encounter',
  'T65831D Toxic effect of fiberglass, accidental (unintentional), subsequent encounter',
  'T65831S Toxic effect of fiberglass, accidental (unintentional), sequela',
  'T65832A Toxic effect of fiberglass, intentional self-harm, initial encounter',
  'T65832D Toxic effect of fiberglass, intentional self-harm, subsequent encounter',
  'T65832S Toxic effect of fiberglass, intentional self-harm, sequela',
  'T65833A Toxic effect of fiberglass, assault, initial encounter',
  'T65833D Toxic effect of fiberglass, assault, subsequent encounter',
  'T65833S Toxic effect of fiberglass, assault, sequela',
  'T65834A Toxic effect of fiberglass, undetermined, initial encounter',
  'T65834D Toxic effect of fiberglass, undetermined, subsequent encounter',
  'T65834S Toxic effect of fiberglass, undetermined, sequela',
  'T65891A Toxic effect of other specified substances, accidental (unintentional), initial encounter',
  'T65891D Toxic effect of other specified substances, accidental (unintentional), subsequent encounter',
  'T65891S Toxic effect of other specified substances, accidental (unintentional), sequela',
  'T65892A Toxic effect of other specified substances, intentional self-harm, initial encounter',
  'T65892D Toxic effect of other specified substances, intentional self-harm, subsequent encounter',
  'T65892S Toxic effect of other specified substances, intentional self-harm, sequela',
  'T65893A Toxic effect of other specified substances, assault, initial encounter',
  'T65893D Toxic effect of other specified substances, assault, subsequent encounter',
  'T65893S Toxic effect of other specified substances, assault, sequela',
  'T65894A Toxic effect of other specified substances, undetermined, initial encounter',
  'T65894D Toxic effect of other specified substances, undetermined, subsequent encounter',
  'T65894S Toxic effect of other specified substances, undetermined, sequela',
  'T6591XA Toxic effect of unspecified substance, accidental (unintentional), initial encounter',
  'T6591XD Toxic effect of unspecified substance, accidental (unintentional), subsequent encounter',
  'T6591XS Toxic effect of unspecified substance, accidental (unintentional), sequela',
  'T6592XA Toxic effect of unspecified substance, intentional self-harm, initial encounter',
  'T6592XD Toxic effect of unspecified substance, intentional self-harm, subsequent encounter',
  'T6592XS Toxic effect of unspecified substance, intentional self-harm, sequela',
  'T6593XA Toxic effect of unspecified substance, assault, initial encounter',
  'T6593XD Toxic effect of unspecified substance, assault, subsequent encounter',
  'T6593XS Toxic effect of unspecified substance, assault, sequela',
  'T6594XA Toxic effect of unspecified substance, undetermined, initial encounter',
  'T6594XD Toxic effect of unspecified substance, undetermined, subsequent encounter',
  'T6594XS Toxic effect of unspecified substance, undetermined, sequela',
  'T66XXXA Radiation sickness, unspecified, initial encounter',
  'T66XXXD Radiation sickness, unspecified, subsequent encounter',
  'T66XXXS Radiation sickness, unspecified, sequela',
  'T670XXA Heatstroke and sunstroke, initial encounter',
  'T670XXD Heatstroke and sunstroke, subsequent encounter',
  'T670XXS Heatstroke and sunstroke, sequela',
  'T671XXA Heat syncope, initial encounter',
  'T671XXD Heat syncope, subsequent encounter',
  'T671XXS Heat syncope, sequela',
  'T672XXA Heat cramp, initial encounter',
  'T672XXD Heat cramp, subsequent encounter',
  'T672XXS Heat cramp, sequela',
  'T673XXA Heat exhaustion, anhydrotic, initial encounter',
  'T673XXD Heat exhaustion, anhydrotic, subsequent encounter',
  'T673XXS Heat exhaustion, anhydrotic, sequela',
  'T674XXA Heat exhaustion due to salt depletion, initial encounter',
  'T674XXD Heat exhaustion due to salt depletion, subsequent encounter',
  'T674XXS Heat exhaustion due to salt depletion, sequela',
  'T675XXA Heat exhaustion, unspecified, initial encounter',
  'T675XXD Heat exhaustion, unspecified, subsequent encounter',
  'T675XXS Heat exhaustion, unspecified, sequela',
  'T676XXA Heat fatigue, transient, initial encounter',
  'T676XXD Heat fatigue, transient, subsequent encounter',
  'T676XXS Heat fatigue, transient, sequela',
  'T677XXA Heat edema, initial encounter',
  'T677XXD Heat edema, subsequent encounter',
  'T677XXS Heat edema, sequela',
  'T678XXA Other effects of heat and light, initial encounter',
  'T678XXD Other effects of heat and light, subsequent encounter',
  'T678XXS Other effects of heat and light, sequela',
  'T679XXA Effect of heat and light, unspecified, initial encounter',
  'T679XXD Effect of heat and light, unspecified, subsequent encounter',
  'T679XXS Effect of heat and light, unspecified, sequela',
  'T68XXXA Hypothermia, initial encounter',
  'T68XXXD Hypothermia, subsequent encounter',
  'T68XXXS Hypothermia, sequela',
  'T69011A Immersion hand, right hand, initial encounter',
  'T69011D Immersion hand, right hand, subsequent encounter',
  'T69011S Immersion hand, right hand, sequela',
  'T69012A Immersion hand, left hand, initial encounter',
  'T69012D Immersion hand, left hand, subsequent encounter',
  'T69012S Immersion hand, left hand, sequela',
  'T69019A Immersion hand, unspecified hand, initial encounter',
  'T69019D Immersion hand, unspecified hand, subsequent encounter',
  'T69019S Immersion hand, unspecified hand, sequela',
  'T69021A Immersion foot, right foot, initial encounter',
  'T69021D Immersion foot, right foot, subsequent encounter',
  'T69021S Immersion foot, right foot, sequela',
  'T69022A Immersion foot, left foot, initial encounter',
  'T69022D Immersion foot, left foot, subsequent encounter',
  'T69022S Immersion foot, left foot, sequela',
  'T69029A Immersion foot, unspecified foot, initial encounter',
  'T69029D Immersion foot, unspecified foot, subsequent encounter',
  'T69029S Immersion foot, unspecified foot, sequela',
  'T691XXA Chilblains, initial encounter',
  'T691XXD Chilblains, subsequent encounter',
  'T691XXS Chilblains, sequela',
  'T698XXA Other specified effects of reduced temperature, initial encounter',
  'T698XXD Other specified effects of reduced temperature, subsequent encounter',
  'T698XXS Other specified effects of reduced temperature, sequela',
  'T699XXA Effect of reduced temperature, unspecified, initial encounter',
  'T699XXD Effect of reduced temperature, unspecified, subsequent encounter',
  'T699XXS Effect of reduced temperature, unspecified, sequela',
  'T700XXA Otitic barotrauma, initial encounter',
  'T700XXD Otitic barotrauma, subsequent encounter',
  'T700XXS Otitic barotrauma, sequela',
  'T701XXA Sinus barotrauma, initial encounter',
  'T701XXD Sinus barotrauma, subsequent encounter',
  'T701XXS Sinus barotrauma, sequela',
  'T7020XA Unspecified effects of high altitude, initial encounter',
  'T7020XD Unspecified effects of high altitude, subsequent encounter',
  'T7020XS Unspecified effects of high altitude, sequela',
  'T7029XA Other effects of high altitude, initial encounter',
  'T7029XD Other effects of high altitude, subsequent encounter',
  'T7029XS Other effects of high altitude, sequela',
  'T703XXA Caisson disease [decompression sickness], initial encounter',
  'T703XXD Caisson disease [decompression sickness], subsequent encounter',
  'T703XXS Caisson disease [decompression sickness], sequela',
  'T704XXA Effects of high-pressure fluids, initial encounter',
  'T704XXD Effects of high-pressure fluids, subsequent encounter',
  'T704XXS Effects of high-pressure fluids, sequela',
  'T708XXA Other effects of air pressure and water pressure, initial encounter',
  'T708XXD Other effects of air pressure and water pressure, subsequent encounter',
  'T708XXS Other effects of air pressure and water pressure, sequela',
  'T709XXA Effect of air pressure and water pressure, unspecified, initial encounter',
  'T709XXD Effect of air pressure and water pressure, unspecified, subsequent encounter',
  'T709XXS Effect of air pressure and water pressure, unspecified, sequela',
  'T71111A Asphyxiation due to smothering under pillow, accidental, initial encounter',
  'T71111D Asphyxiation due to smothering under pillow, accidental, subsequent encounter',
  'T71111S Asphyxiation due to smothering under pillow, accidental, sequela',
  'T71112A Asphyxiation due to smothering under pillow, intentional self-harm, initial encounter',
  'T71112D Asphyxiation due to smothering under pillow, intentional self-harm, subsequent encounter',
  'T71112S Asphyxiation due to smothering under pillow, intentional self-harm, sequela',
  'T71113A Asphyxiation due to smothering under pillow, assault, initial encounter',
  'T71113D Asphyxiation due to smothering under pillow, assault, subsequent encounter',
  'T71113S Asphyxiation due to smothering under pillow, assault, sequela',
  'T71114A Asphyxiation due to smothering under pillow, undetermined, initial encounter',
  'T71114D Asphyxiation due to smothering under pillow, undetermined, subsequent encounter',
  'T71114S Asphyxiation due to smothering under pillow, undetermined, sequela',
  'T71121A Asphyxiation due to plastic bag, accidental, initial encounter',
  'T71121D Asphyxiation due to plastic bag, accidental, subsequent encounter',
  'T71121S Asphyxiation due to plastic bag, accidental, sequela',
  'T71122A Asphyxiation due to plastic bag, intentional self-harm, initial encounter',
  'T71122D Asphyxiation due to plastic bag, intentional self-harm, subsequent encounter',
  'T71122S Asphyxiation due to plastic bag, intentional self-harm, sequela',
  'T71123A Asphyxiation due to plastic bag, assault, initial encounter',
  'T71123D Asphyxiation due to plastic bag, assault, subsequent encounter',
  'T71123S Asphyxiation due to plastic bag, assault, sequela',
  'T71124A Asphyxiation due to plastic bag, undetermined, initial encounter',
  'T71124D Asphyxiation due to plastic bag, undetermined, subsequent encounter',
  'T71124S Asphyxiation due to plastic bag, undetermined, sequela',
  'T71131A Asphyxiation due to being trapped in bed linens, accidental, initial encounter',
  'T71131D Asphyxiation due to being trapped in bed linens, accidental, subsequent encounter',
  'T71131S Asphyxiation due to being trapped in bed linens, accidental, sequela',
  'T71132A Asphyxiation due to being trapped in bed linens, intentional self-harm, initial encounter',
  'T71132D Asphyxiation due to being trapped in bed linens, intentional self-harm, subsequent encounter',
  'T71132S Asphyxiation due to being trapped in bed linens, intentional self-harm, sequela',
  'T71133A Asphyxiation due to being trapped in bed linens, assault, initial encounter',
  'T71133D Asphyxiation due to being trapped in bed linens, assault, subsequent encounter',
  'T71133S Asphyxiation due to being trapped in bed linens, assault, sequela',
  'T71134A Asphyxiation due to being trapped in bed linens, undetermined, initial encounter',
  'T71134D Asphyxiation due to being trapped in bed linens, undetermined, subsequent encounter',
  'T71134S Asphyxiation due to being trapped in bed linens, undetermined, sequela',
  "T71141A Asphyxiation due to smothering under another person's body (in bed), accidental, initial",
  'encounter',
  "T71141D Asphyxiation due to smothering under another person's body (in bed), accidental, subsequent",
  'encounter',
  "T71141S Asphyxiation due to smothering under another person's body (in bed), accidental, sequela",
  "T71143A Asphyxiation due to smothering under another person's body (in bed), assault, initial",
  'encounter',
  "T71143D Asphyxiation due to smothering under another person's body (in bed), assault, subsequent",
  'encounter',
  "T71143S Asphyxiation due to smothering under another person's body (in bed), assault, sequela",
  "T71144A Asphyxiation due to smothering under another person's body (in bed), undetermined, initial",
  'encounter',
  "T71144D Asphyxiation due to smothering under another person's body (in bed), undetermined,",
  'subsequent encounter',
  "T71144S Asphyxiation due to smothering under another person's body (in bed), undetermined, sequela",
  'T71151A Asphyxiation due to smothering in furniture, accidental, initial encounter',
  'T71151D Asphyxiation due to smothering in furniture, accidental, subsequent encounter',
  'T71151S Asphyxiation due to smothering in furniture, accidental, sequela',
  'T71152A Asphyxiation due to smothering in furniture, intentional self-harm, initial encounter',
  'T71152D Asphyxiation due to smothering in furniture, intentional self-harm, subsequent encounter',
  'T71152S Asphyxiation due to smothering in furniture, intentional self-harm, sequela',
  'T71153A Asphyxiation due to smothering in furniture, assault, initial encounter',
  'T71153D Asphyxiation due to smothering in furniture, assault, subsequent encounter',
  'T71153S Asphyxiation due to smothering in furniture, assault, sequela',
  'T71154A Asphyxiation due to smothering in furniture, undetermined, initial encounter',
  'T71154D Asphyxiation due to smothering in furniture, undetermined, subsequent encounter',
  'T71154S Asphyxiation due to smothering in furniture, undetermined, sequela',
  'T71161A Asphyxiation due to hanging, accidental, initial encounter',
  'T71161D Asphyxiation due to hanging, accidental, subsequent encounter',
  'T71161S Asphyxiation due to hanging, accidental, sequela',
  'T71162A Asphyxiation due to hanging, intentional self-harm, initial encounter',
  'T71162D Asphyxiation due to hanging, intentional self-harm, subsequent encounter',
  'T71162S Asphyxiation due to hanging, intentional self-harm, sequela',
  'T71163A Asphyxiation due to hanging, assault, initial encounter',
  'T71163D Asphyxiation due to hanging, assault, subsequent encounter',
  'T71163S Asphyxiation due to hanging, assault, sequela',
  'T71164A Asphyxiation due to hanging, undetermined, initial encounter',
  'T71164D Asphyxiation due to hanging, undetermined, subsequent encounter',
  'T71164S Asphyxiation due to hanging, undetermined, sequela',
  'T71191A Asphyxiation due to mechanical threat to breathing due to other causes, accidental, initial',
  'encounter',
  'T71191D Asphyxiation due to mechanical threat to breathing due to other causes, accidental,',
  'subsequent encounter',
  'T71191S Asphyxiation due to mechanical threat to breathing due to other causes, accidental, sequela',
  'T71192A Asphyxiation due to mechanical threat to breathing due to other causes, intentional self-harm,',
  'initial encounter',
  'T71192D Asphyxiation due to mechanical threat to breathing due to other causes, intentional self-harm,',
  'subsequent encounter',
  'T71192S Asphyxiation due to mechanical threat to breathing due to other causes, intentional self-harm,',
  'sequela',
  'T71193A Asphyxiation due to mechanical threat to breathing due to other causes, assault, initial',
  'encounter',
  'T71193D Asphyxiation due to mechanical threat to breathing due to other causes, assault, subsequent',
  'encounter',
  'T71193S Asphyxiation due to mechanical threat to breathing due to other causes, assault, sequela',
  'T71194A Asphyxiation due to mechanical threat to breathing due to other causes, undetermined, initial',
  'encounter',
  'T71194D Asphyxiation due to mechanical threat to breathing due to other causes, undetermined,',
  'subsequent encounter',
  'T71194S Asphyxiation due to mechanical threat to breathing due to other causes, undetermined,',
  'sequela',
  'T7120XA Asphyxiation due to systemic oxygen deficiency due to low oxygen content in ambient air due',
  'to unspecified cause, initial encounter',
  'T7120XD Asphyxiation due to systemic oxygen deficiency due to low oxygen content in ambient air due',
  'to unspecified cause, subsequent encounter',
  'T7120XS Asphyxiation due to systemic oxygen deficiency due to low oxygen content in ambient air due',
  'to unspecified cause, sequela',
  'T7121XA Asphyxiation due to cave-in or falling earth, initial encounter',
  'T7121XD Asphyxiation due to cave-in or falling earth, subsequent encounter',
  'T7121XS Asphyxiation due to cave-in or falling earth, sequela',
  'T71221A Asphyxiation due to being trapped in a car trunk, accidental, initial encounter',
  'T71221D Asphyxiation due to being trapped in a car trunk, accidental, subsequent encounter',
  'T71221S Asphyxiation due to being trapped in a car trunk, accidental, sequela',
  'T71222A Asphyxiation due to being trapped in a car trunk, intentional self-harm, initial encounter',
  'T71222D Asphyxiation due to being trapped in a car trunk, intentional self-harm, subsequent encounter',
  'T71222S Asphyxiation due to being trapped in a car trunk, intentional self-harm, sequela',
  'T71223A Asphyxiation due to being trapped in a car trunk, assault, initial encounter',
  'T71223D Asphyxiation due to being trapped in a car trunk, assault, subsequent encounter',
  'T71223S Asphyxiation due to being trapped in a car trunk, assault, sequela',
  'T71224A Asphyxiation due to being trapped in a car trunk, undetermined, initial encounter',
  'T71224D Asphyxiation due to being trapped in a car trunk, undetermined, subsequent encounter',
  'T71224S Asphyxiation due to being trapped in a car trunk, undetermined, sequela',
  'T71231A Asphyxiation due to being trapped in a (discarded) refrigerator, accidental, initial encounter',
  'T71231D Asphyxiation due to being trapped in a (discarded) refrigerator, accidental, subsequent',
  'encounter',
  'T71231S Asphyxiation due to being trapped in a (discarded) refrigerator, accidental, sequela',
  'T71232A Asphyxiation due to being trapped in a (discarded) refrigerator, intentional self-harm, initial',
  'encounter',
  'T71232D Asphyxiation due to being trapped in a (discarded) refrigerator, intentional self-harm,',
  'subsequent encounter',
  'T71232S Asphyxiation due to being trapped in a (discarded) refrigerator, intentional self-harm, sequela',
  'T71233A Asphyxiation due to being trapped in a (discarded) refrigerator, assault, initial encounter',
  'T71233D Asphyxiation due to being trapped in a (discarded) refrigerator, assault, subsequent encounter',
  'T71233S Asphyxiation due to being trapped in a (discarded) refrigerator, assault, sequela',
  'T71234A Asphyxiation due to being trapped in a (discarded) refrigerator, undetermined, initial',
  'encounter',
  'T71234D Asphyxiation due to being trapped in a (discarded) refrigerator, undetermined, subsequent',
  'encounter',
  'T71234S Asphyxiation due to being trapped in a (discarded) refrigerator, undetermined, sequela',
  'T7129XA Asphyxiation due to being trapped in other low oxygen environment, initial encounter',
  'T7129XD Asphyxiation due to being trapped in other low oxygen environment, subsequent encounter',
  'T7129XS Asphyxiation due to being trapped in other low oxygen environment, sequela',
  'T719XXA Asphyxiation due to unspecified cause, initial encounter',
  'T719XXD Asphyxiation due to unspecified cause, subsequent encounter',
  'T719XXS Asphyxiation due to unspecified cause, sequela',
  'T730XXA Starvation, initial encounter',
  'T730XXD Starvation, subsequent encounter',
  'T730XXS Starvation, sequela',
  'T731XXA Deprivation of water, initial encounter',
  'T731XXD Deprivation of water, subsequent encounter',
  'T731XXS Deprivation of water, sequela',
  'T732XXA Exhaustion due to exposure, initial encounter',
  'T732XXD Exhaustion due to exposure, subsequent encounter',
  'T732XXS Exhaustion due to exposure, sequela',
  'T733XXA Exhaustion due to excessive exertion, initial encounter',
  'T733XXD Exhaustion due to excessive exertion, subsequent encounter',
  'T733XXS Exhaustion due to excessive exertion, sequela',
  'T738XXA Other effects of deprivation, initial encounter',
  'T738XXD Other effects of deprivation, subsequent encounter',
  'T738XXS Other effects of deprivation, sequela',
  'T739XXA Effect of deprivation, unspecified, initial encounter',
  'T739XXD Effect of deprivation, unspecified, subsequent encounter',
  'T739XXS Effect of deprivation, unspecified, sequela',
  'T7401XA Adult neglect or abandonment, confirmed, initial encounter',
  'T7401XD Adult neglect or abandonment, confirmed, subsequent encounter',
  'T7401XS Adult neglect or abandonment, confirmed, sequela',
  'T7402XA Child neglect or abandonment, confirmed, initial encounter',
  'T7402XD Child neglect or abandonment, confirmed, subsequent encounter',
  'T7402XS Child neglect or abandonment, confirmed, sequela',
  'T7411XA Adult physical abuse, confirmed, initial encounter',
  'T7411XD Adult physical abuse, confirmed, subsequent encounter',
  'T7411XS Adult physical abuse, confirmed, sequela',
  'T7412XA Child physical abuse, confirmed, initial encounter',
  'T7412XD Child physical abuse, confirmed, subsequent encounter',
  'T7412XS Child physical abuse, confirmed, sequela',
  'T7421XA Adult sexual abuse, confirmed, initial encounter',
  'T7421XD Adult sexual abuse, confirmed, subsequent encounter',
  'T7421XS Adult sexual abuse, confirmed, sequela',
  'T7422XA Child sexual abuse, confirmed, initial encounter',
  'T7422XD Child sexual abuse, confirmed, subsequent encounter',
  'T7422XS Child sexual abuse, confirmed, sequela',
  'T7431XA Adult psychological abuse, confirmed, initial encounter',
  'T7431XD Adult psychological abuse, confirmed, subsequent encounter',
  'T7431XS Adult psychological abuse, confirmed, sequela',
  'T7432XA Child psychological abuse, confirmed, initial encounter',
  'T7432XD Child psychological abuse, confirmed, subsequent encounter',
  'T7432XS Child psychological abuse, confirmed, sequela',
  'T744XXA Shaken infant syndrome, initial encounter',
  'T744XXD Shaken infant syndrome, subsequent encounter',
  'T744XXS Shaken infant syndrome, sequela',
  'T7491XA Unspecified adult maltreatment, confirmed, initial encounter',
  'T7491XD Unspecified adult maltreatment, confirmed, subsequent encounter',
  'T7491XS Unspecified adult maltreatment, confirmed, sequela',
  'T7492XA Unspecified child maltreatment, confirmed, initial encounter',
  'T7492XD Unspecified child maltreatment, confirmed, subsequent encounter',
  'T7492XS Unspecified child maltreatment, confirmed, sequela',
  'T7500XA Unspecified effects of lightning, initial encounter',
  'T7500XD Unspecified effects of lightning, subsequent encounter',
  'T7500XS Unspecified effects of lightning, sequela',
  'T7501XA Shock due to being struck by lightning, initial encounter',
  'T7501XD Shock due to being struck by lightning, subsequent encounter',
  'T7501XS Shock due to being struck by lightning, sequela',
  'T7509XA Other effects of lightning, initial encounter',
  'T7509XD Other effects of lightning, subsequent encounter',
  'T7509XS Other effects of lightning, sequela',
  'T751XXA Unspecified effects of drowning and nonfatal submersion, initial encounter',
  'T751XXD Unspecified effects of drowning and nonfatal submersion, subsequent encounter',
  'T751XXS Unspecified effects of drowning and nonfatal submersion, sequela',
  'T7520XA Unspecified effects of vibration, initial encounter',
  'T7520XD Unspecified effects of vibration, subsequent encounter',
  'T7520XS Unspecified effects of vibration, sequela',
  'T7521XA Pneumatic hammer syndrome, initial encounter',
  'T7521XD Pneumatic hammer syndrome, subsequent encounter',
  'T7521XS Pneumatic hammer syndrome, sequela',
  'T7522XA Traumatic vasospastic syndrome, initial encounter',
  'T7522XD Traumatic vasospastic syndrome, subsequent encounter',
  'T7522XS Traumatic vasospastic syndrome, sequela',
  'T7523XA Vertigo from infrasound, initial encounter',
  'T7523XD Vertigo from infrasound, subsequent encounter',
  'T7523XS Vertigo from infrasound, sequela',
  'T7529XA Other effects of vibration, initial encounter',
  'T7529XD Other effects of vibration, subsequent encounter',
  'T7529XS Other effects of vibration, sequela',
  'T753XXA Motion sickness, initial encounter',
  'T753XXD Motion sickness, subsequent encounter',
  'T753XXS Motion sickness, sequela',
  'T754XXA Electrocution, initial encounter',
  'T754XXD Electrocution, subsequent encounter',
  'T754XXS Electrocution, sequela',
  'T7581XA Effects of abnormal gravitation [G] forces, initial encounter',
  'T7581XD Effects of abnormal gravitation [G] forces, subsequent encounter',
  'T7581XS Effects of abnormal gravitation [G] forces, sequela',
  'T7582XA Effects of weightlessness, initial encounter',
  'T7582XD Effects of weightlessness, subsequent encounter',
  'T7582XS Effects of weightlessness, sequela',
  'T7589XA Other specified effects of external causes, initial encounter',
  'T7589XD Other specified effects of external causes, subsequent encounter',
  'T7589XS Other specified effects of external causes, sequela',
  'T7601XA Adult neglect or abandonment, suspected, initial encounter',
  'T7601XD Adult neglect or abandonment, suspected, subsequent encounter',
  'T7601XS Adult neglect or abandonment, suspected, sequela',
  'T7602XA Child neglect or abandonment, suspected, initial encounter',
  'T7602XD Child neglect or abandonment, suspected, subsequent encounter',
  'T7602XS Child neglect or abandonment, suspected, sequela',
  'T7611XA Adult physical abuse, suspected, initial encounter',
  'T7611XD Adult physical abuse, suspected, subsequent encounter',
  'T7611XS Adult physical abuse, suspected, sequela',
  'T7612XA Child physical abuse, suspected, initial encounter',
  'T7612XD Child physical abuse, suspected, subsequent encounter',
  'T7612XS Child physical abuse, suspected, sequela',
  'T7621XA Adult sexual abuse, suspected, initial encounter',
  'T7621XD Adult sexual abuse, suspected, subsequent encounter',
  'T7621XS Adult sexual abuse, suspected, sequela',
  'T7622XA Child sexual abuse, suspected, initial encounter',
  'T7622XD Child sexual abuse, suspected, subsequent encounter',
  'T7622XS Child sexual abuse, suspected, sequela',
  'T7631XA Adult psychological abuse, suspected, initial encounter',
  'T7631XD Adult psychological abuse, suspected, subsequent encounter',
  'T7631XS Adult psychological abuse, suspected, sequela',
  'T7632XA Child psychological abuse, suspected, initial encounter',
  'T7632XD Child psychological abuse, suspected, subsequent encounter',
  'T7632XS Child psychological abuse, suspected, sequela',
  'T7691XA Unspecified adult maltreatment, suspected, initial encounter',
  'T7691XD Unspecified adult maltreatment, suspected, subsequent encounter',
  'T7691XS Unspecified adult maltreatment, suspected, sequela',
  'T7692XA Unspecified child maltreatment, suspected, initial encounter',
  'T7692XD Unspecified child maltreatment, suspected, subsequent encounter',
  'T7692XS Unspecified child maltreatment, suspected, sequela',
  'T7800XA Anaphylactic reaction due to unspecified food, initial encounter',
  'T7800XD Anaphylactic reaction due to unspecified food, subsequent encounter',
  'T7800XS Anaphylactic reaction due to unspecified food, sequela',
  'T7801XA Anaphylactic reaction due to peanuts, initial encounter',
  'T7801XD Anaphylactic reaction due to peanuts, subsequent encounter',
  'T7801XS Anaphylactic reaction due to peanuts, sequela',
  'T7802XA Anaphylactic reaction due to shellfish (crustaceans), initial encounter',
  'T7802XD Anaphylactic reaction due to shellfish (crustaceans), subsequent encounter',
  'T7802XS Anaphylactic reaction due to shellfish (crustaceans), sequela',
  'T7803XA Anaphylactic reaction due to other fish, initial encounter',
  'T7803XD Anaphylactic reaction due to other fish, subsequent encounter',
  'T7803XS Anaphylactic reaction due to other fish, sequela',
  'T7804XA Anaphylactic reaction due to fruits and vegetables, initial encounter',
  'T7804XD Anaphylactic reaction due to fruits and vegetables, subsequent encounter',
  'T7804XS Anaphylactic reaction due to fruits and vegetables, sequela',
  'T7805XA Anaphylactic reaction due to tree nuts and seeds, initial encounter',
  'T7805XD Anaphylactic reaction due to tree nuts and seeds, subsequent encounter',
  'T7805XS Anaphylactic reaction due to tree nuts and seeds, sequela',
  'T7806XA Anaphylactic reaction due to food additives, initial encounter',
  'T7806XD Anaphylactic reaction due to food additives, subsequent encounter',
  'T7806XS Anaphylactic reaction due to food additives, sequela',
  'T7807XA Anaphylactic reaction due to milk and dairy products, initial encounter',
  'T7807XD Anaphylactic reaction due to milk and dairy products, subsequent encounter',
  'T7807XS Anaphylactic reaction due to milk and dairy products, sequela',
  'T7808XA Anaphylactic reaction due to eggs, initial encounter',
  'T7808XD Anaphylactic reaction due to eggs, subsequent encounter',
  'T7808XS Anaphylactic reaction due to eggs, sequela',
  'T7809XA Anaphylactic reaction due to other food products, initial encounter',
  'T7809XD Anaphylactic reaction due to other food products, subsequent encounter',
  'T7809XS Anaphylactic reaction due to other food products, sequela',
  'T781XXA Other adverse food reactions, not elsewhere classified, initial encounter',
  'T781XXD Other adverse food reactions, not elsewhere classified, subsequent encounter',
  'T781XXS Other adverse food reactions, not elsewhere classified, sequela',
  'T782XXA Anaphylactic shock, unspecified, initial encounter',
  'T782XXD Anaphylactic shock, unspecified, subsequent encounter',
  'T782XXS Anaphylactic shock, unspecified, sequela',
  'T783XXA Angioneurotic edema, initial encounter',
  'T783XXD Angioneurotic edema, subsequent encounter',
  'T783XXS Angioneurotic edema, sequela',
  'T7840XA Allergy, unspecified, initial encounter',
  'T7840XD Allergy, unspecified, subsequent encounter',
  'T7840XS Allergy, unspecified, sequela',
  'T7841XA Arthus phenomenon, initial encounter',
  'T7841XD Arthus phenomenon, subsequent encounter',
  'T7841XS Arthus phenomenon, sequela',
  'T7849XA Other allergy, initial encounter',
  'T7849XD Other allergy, subsequent encounter',
  'T7849XS Other allergy, sequela',
  'T788XXA Other adverse effects, not elsewhere classified, initial encounter',
  'T788XXD Other adverse effects, not elsewhere classified, subsequent encounter',
  'T788XXS Other adverse effects, not elsewhere classified, sequela',
  'T790XXA Air embolism (traumatic), initial encounter',
  'T790XXD Air embolism (traumatic), subsequent encounter',
  'T790XXS Air embolism (traumatic), sequela',
  'T791XXA Fat embolism (traumatic), initial encounter',
  'T791XXD Fat embolism (traumatic), subsequent encounter',
  'T791XXS Fat embolism (traumatic), sequela',
  'T792XXA Traumatic secondary and recurrent hemorrhage and seroma, initial encounter',
  'T792XXD Traumatic secondary and recurrent hemorrhage and seroma, subsequent encounter',
  'T792XXS Traumatic secondary and recurrent hemorrhage and seroma, sequela',
  'T794XXA Traumatic shock, initial encounter',
  'T794XXD Traumatic shock, subsequent encounter',
  'T794XXS Traumatic shock, sequela',
  'T795XXA Traumatic anuria, initial encounter',
  'T795XXD Traumatic anuria, subsequent encounter',
  'T795XXS Traumatic anuria, sequela',
  'T796XXA Traumatic ischemia of muscle, initial encounter',
  'T796XXD Traumatic ischemia of muscle, subsequent encounter',
  'T796XXS Traumatic ischemia of muscle, sequela',
  'T797XXA Traumatic subcutaneous emphysema, initial encounter',
  'T797XXD Traumatic subcutaneous emphysema, subsequent encounter',
  'T797XXS Traumatic subcutaneous emphysema, sequela',
  'T79A0XA Compartment syndrome, unspecified, initial encounter',
  'T79A0XD Compartment syndrome, unspecified, subsequent encounter',
  'T79A0XS Compartment syndrome, unspecified, sequela',
  'T79A11A Traumatic compartment syndrome of right upper extremity, initial encounter',
  'T79A11D Traumatic compartment syndrome of right upper extremity, subsequent encounter',
  'T79A11S Traumatic compartment syndrome of right upper extremity, sequela',
  'T79A12A Traumatic compartment syndrome of left upper extremity, initial encounter',
  'T79A12D Traumatic compartment syndrome of left upper extremity, subsequent encounter',
  'T79A12S Traumatic compartment syndrome of left upper extremity, sequela',
  'T79A19A Traumatic compartment syndrome of unspecified upper extremity, initial encounter',
  'T79A19D Traumatic compartment syndrome of unspecified upper extremity, subsequent encounter',
  'T79A19S Traumatic compartment syndrome of unspecified upper extremity, sequela',
  'T79A21A Traumatic compartment syndrome of right lower extremity, initial encounter',
  'T79A21D Traumatic compartment syndrome of right lower extremity, subsequent encounter',
  'T79A21S Traumatic compartment syndrome of right lower extremity, sequela',
  'T79A22A Traumatic compartment syndrome of left lower extremity, initial encounter',
  'T79A22D Traumatic compartment syndrome of left lower extremity, subsequent encounter',
  'T79A22S Traumatic compartment syndrome of left lower extremity, sequela',
  'T79A29A Traumatic compartment syndrome of unspecified lower extremity, initial encounter',
  'T79A29D Traumatic compartment syndrome of unspecified lower extremity, subsequent encounter',
  'T79A29S Traumatic compartment syndrome of unspecified lower extremity, sequela',
  'T79A3XA Traumatic compartment syndrome of abdomen, initial encounter',
  'T79A3XD Traumatic compartment syndrome of abdomen, subsequent encounter',
  'T79A3XS Traumatic compartment syndrome of abdomen, sequela',
  'T79A9XA Traumatic compartment syndrome of other sites, initial encounter',
  'T79A9XD Traumatic compartment syndrome of other sites, subsequent encounter',
  'T79A9XS Traumatic compartment syndrome of other sites, sequela',
  'T798XXA Other early complications of trauma, initial encounter',
  'T798XXD Other early complications of trauma, subsequent encounter',
  'T798XXS Other early complications of trauma, sequela',
  'T799XXA Unspecified early complication of trauma, initial encounter',
  'T799XXD Unspecified early complication of trauma, subsequent encounter',
  'T799XXS Unspecified early complication of trauma, sequela',
  'T800XXA Air embolism following infusion, transfusion and therapeutic injection, initial encounter',
  'T800XXD Air embolism following infusion, transfusion and therapeutic injection, subsequent encounter',
  'T800XXS Air embolism following infusion, transfusion and therapeutic injection, sequela',
  'T801XXA Vascular complications following infusion, transfusion and therapeutic injection, initial',
  'encounter',
  'T801XXD Vascular complications following infusion, transfusion and therapeutic injection, subsequent',
  'encounter',
  'T801XXS Vascular complications following infusion, transfusion and therapeutic injection, sequela',
  'T80211A Bloodstream infection due to central venous catheter, initial encounter',
  'T80211D Bloodstream infection due to central venous catheter, subsequent encounter',
  'T80211S Bloodstream infection due to central venous catheter, sequela',
  'T80212A Local infection due to central venous catheter, initial encounter',
  'T80212D Local infection due to central venous catheter, subsequent encounter',
  'T80212S Local infection due to central venous catheter, sequela',
  'T80218A Other infection due to central venous catheter, initial encounter',
  'T80218D Other infection due to central venous catheter, subsequent encounter',
  'T80218S Other infection due to central venous catheter, sequela',
  'T80219A Unspecified infection due to central venous catheter, initial encounter',
  'T80219D Unspecified infection due to central venous catheter, subsequent encounter',
  'T80219S Unspecified infection due to central venous catheter, sequela',
  'T8022XA Acute infection following transfusion, infusion, or injection of blood and blood products, initial',
  'encounter',
  'T8022XD Acute infection following transfusion, infusion, or injection of blood and blood products,',
  'subsequent encounter',
  'T8022XS Acute infection following transfusion, infusion, or injection of blood and blood products,',
  'sequela',
  'T8029XA Infection following other infusion, transfusion and therapeutic injection, initial encounter',
  'T8029XD Infection following other infusion, transfusion and therapeutic injection, subsequent',
  'encounter',
  'T8029XS Infection following other infusion, transfusion and therapeutic injection, sequela',
  'T8030XA ABO incompatibility reaction due to transfusion of blood or blood products, unspecified, initial',
  'encounter',
  'T8030XD ABO incompatibility reaction due to transfusion of blood or blood products, unspecified,',
  'subsequent encounter',
  'T8030XS ABO incompatibility reaction due to transfusion of blood or blood products, unspecified,',
  'sequela',
  'T80310A ABO incompatibility with acute hemolytic transfusion reaction, initial encounter',
  'T80310D ABO incompatibility with acute hemolytic transfusion reaction, subsequent encounter',
  'T80310S ABO incompatibility with acute hemolytic transfusion reaction, sequela',
  'T80311A ABO incompatibility with delayed hemolytic transfusion reaction, initial encounter',
  'T80311D ABO incompatibility with delayed hemolytic transfusion reaction, subsequent encounter',
  'T80311S ABO incompatibility with delayed hemolytic transfusion reaction, sequela',
  'T80319A ABO incompatibility with hemolytic transfusion reaction, unspecified, initial encounter',
  'T80319D ABO incompatibility with hemolytic transfusion reaction, unspecified, subsequent encounter',
  'T80319S ABO incompatibility with hemolytic transfusion reaction, unspecified, sequela',
  'T8039XA Other ABO incompatibility reaction due to transfusion of blood or blood products, initial',
  'encounter',
  'T8039XD Other ABO incompatibility reaction due to transfusion of blood or blood products, subsequent',
  'encounter',
  'T8039XS Other ABO incompatibility reaction due to transfusion of blood or blood products, sequela',
  'T8040XA Rh incompatibility reaction due to transfusion of blood or blood products, unspecified, initial',
  'encounter',
  'T8040XD Rh incompatibility reaction due to transfusion of blood or blood products, unspecified,',
  'subsequent encounter',
  'T8040XS Rh incompatibility reaction due to transfusion of blood or blood products, unspecified, sequela',
  'T80410A Rh incompatibility with acute hemolytic transfusion reaction, initial encounter',
  'T80410D Rh incompatibility with acute hemolytic transfusion reaction, subsequent encounter',
  'T80410S Rh incompatibility with acute hemolytic transfusion reaction, sequela',
  'T80411A Rh incompatibility with delayed hemolytic transfusion reaction, initial encounter',
  'T80411D Rh incompatibility with delayed hemolytic transfusion reaction, subsequent encounter',
  'T80411S Rh incompatibility with delayed hemolytic transfusion reaction, sequela',
  'T80419A Rh incompatibility with hemolytic transfusion reaction, unspecified, initial encounter',
  'T80419D Rh incompatibility with hemolytic transfusion reaction, unspecified, subsequent encounter',
  'T80419S Rh incompatibility with hemolytic transfusion reaction, unspecified, sequela',
  'T8049XA Other Rh incompatibility reaction due to transfusion of blood or blood products, initial',
  'encounter',
  'T8049XD Other Rh incompatibility reaction due to transfusion of blood or blood products, subsequent',
  'encounter',
  'T8049XS Other Rh incompatibility reaction due to transfusion of blood or blood products, sequela',
  'T80A0XA Non-ABO incompatibility reaction due to transfusion of blood or blood products, unspecified,',
  'initial encounter',
  'T80A0XD Non-ABO incompatibility reaction due to transfusion of blood or blood products, unspecified,',
  'subsequent encounter',
  'T80A0XS Non-ABO incompatibility reaction due to transfusion of blood or blood products, unspecified,',
  'sequela',
  'T80A10A Non-ABO incompatibility with acute hemolytic transfusion reaction, initial encounter',
  'T80A10D Non-ABO incompatibility with acute hemolytic transfusion reaction, subsequent encounter',
  'T80A10S Non-ABO incompatibility with acute hemolytic transfusion reaction, sequela',
  'T80A11A Non-ABO incompatibility with delayed hemolytic transfusion reaction, initial encounter',
  'T80A11D Non-ABO incompatibility with delayed hemolytic transfusion reaction, subsequent encounter',
  'T80A11S Non-ABO incompatibility with delayed hemolytic transfusion reaction, sequela',
  'T80A19A Non-ABO incompatibility with hemolytic transfusion reaction, unspecified, initial encounter',
  'T80A19D Non-ABO incompatibility with hemolytic transfusion reaction, unspecified, subsequent',
  'encounter',
  'T80A19S Non-ABO incompatibility with hemolytic transfusion reaction, unspecified, sequela',
  'T80A9XA Other non-ABO incompatibility reaction due to transfusion of blood or blood products, initial',
  'encounter',
  'T80A9XD Other non-ABO incompatibility reaction due to transfusion of blood or blood products,',
  'subsequent encounter',
  'T80A9XS Other non-ABO incompatibility reaction due to transfusion of blood or blood products, sequela',
  'T8051XA Anaphylactic reaction due to administration of blood and blood products, initial encounter',
  'T8051XD Anaphylactic reaction due to administration of blood and blood products, subsequent',
  'encounter',
  'T8051XS Anaphylactic reaction due to administration of blood and blood products, sequela',
  'T8052XA Anaphylactic reaction due to vaccination, initial encounter',
  'T8052XD Anaphylactic reaction due to vaccination, subsequent encounter',
  'T8052XS Anaphylactic reaction due to vaccination, sequela',
  'T8059XA Anaphylactic reaction due to other serum, initial encounter',
  'T8059XD Anaphylactic reaction due to other serum, subsequent encounter',
  'T8059XS Anaphylactic reaction due to other serum, sequela',
  'T8061XA Other serum reaction due to administration of blood and blood products, initial encounter',
  'T8061XD Other serum reaction due to administration of blood and blood products, subsequent',
  'encounter',
  'T8061XS Other serum reaction due to administration of blood and blood products, sequela',
  'T8062XA Other serum reaction due to vaccination, initial encounter',
  'T8062XD Other serum reaction due to vaccination, subsequent encounter',
  'T8062XS Other serum reaction due to vaccination, sequela',
  'T8069XA Other serum reaction due to other serum, initial encounter',
  'T8069XD Other serum reaction due to other serum, subsequent encounter',
  'T8069XS Other serum reaction due to other serum, sequela',
  'T80810A Extravasation of vesicant antineoplastic chemotherapy, initial encounter',
  'T80810D Extravasation of vesicant antineoplastic chemotherapy, subsequent encounter',
  'T80810S Extravasation of vesicant antineoplastic chemotherapy, sequela',
  'T80818A Extravasation of other vesicant agent, initial encounter',
  'T80818D Extravasation of other vesicant agent, subsequent encounter',
  'T80818S Extravasation of other vesicant agent, sequela',
  'T8089XA Other complications following infusion, transfusion and therapeutic injection, initial encounter',
  'T8089XD Other complications following infusion, transfusion and therapeutic injection, subsequent',
  'encounter',
  'T8089XS Other complications following infusion, transfusion and therapeutic injection, sequela',
  'T8090XA Unspecified complication following infusion and therapeutic injection, initial encounter',
  'T8090XD Unspecified complication following infusion and therapeutic injection, subsequent encounter',
  'T8090XS Unspecified complication following infusion and therapeutic injection, sequela',
  'T80910A Acute hemolytic transfusion reaction, unspecified incompatibility, initial encounter',
  'T80910D Acute hemolytic transfusion reaction, unspecified incompatibility, subsequent encounter',
  'T80910S Acute hemolytic transfusion reaction, unspecified incompatibility, sequela',
  'T80911A Delayed hemolytic transfusion reaction, unspecified incompatibility, initial encounter',
  'T80911D Delayed hemolytic transfusion reaction, unspecified incompatibility, subsequent encounter',
  'T80911S Delayed hemolytic transfusion reaction, unspecified incompatibility, sequela',
  'T80919A Hemolytic transfusion reaction, unspecified incompatibility, unspecified as acute or delayed,',
  'initial encounter',
  'T80919D Hemolytic transfusion reaction, unspecified incompatibility, unspecified as acute or delayed,',
  'subsequent encounter',
  'T80919S Hemolytic transfusion reaction, unspecified incompatibility, unspecified as acute or delayed,',
  'sequela',
  'T8092XA Unspecified transfusion reaction, initial encounter',
  'T8092XD Unspecified transfusion reaction, subsequent encounter',
  'T8092XS Unspecified transfusion reaction, sequela',
  'T8110XA Postprocedural shock unspecified, initial encounter',
  'T8110XD Postprocedural shock unspecified, subsequent encounter',
  'T8110XS Postprocedural shock unspecified, sequela',
  'T8111XA Postprocedural cardiogenic shock, initial encounter',
  'T8111XD Postprocedural cardiogenic shock, subsequent encounter',
  'T8111XS Postprocedural cardiogenic shock, sequela',
  'T8112XA Postprocedural septic shock, initial encounter',
  'T8112XD Postprocedural septic shock, subsequent encounter',
  'T8112XS Postprocedural septic shock, sequela',
  'T8119XA Other postprocedural shock, initial encounter',
  'T8119XD Other postprocedural shock, subsequent encounter',
  'T8119XS Other postprocedural shock, sequela',
  'T8130XA Disruption of wound, unspecified, initial encounter',
  'T8130XD Disruption of wound, unspecified, subsequent encounter',
  'T8130XS Disruption of wound, unspecified, sequela',
  'T8131XA Disruption of external operation (surgical) wound, not elsewhere classified, initial encounter',
  'T8131XD Disruption of external operation (surgical) wound, not elsewhere classified, subsequent',
  'encounter',
  'T8131XS Disruption of external operation (surgical) wound, not elsewhere classified, sequela',
  'T8132XA Disruption of internal operation (surgical) wound, not elsewhere classified, initial encounter',
  'T8132XD Disruption of internal operation (surgical) wound, not elsewhere classified, subsequent',
  'encounter',
  'T8132XS Disruption of internal operation (surgical) wound, not elsewhere classified, sequela',
  'T8133XA Disruption of traumatic injury wound repair, initial encounter',
  'T8133XD Disruption of traumatic injury wound repair, subsequent encounter',
  'T8133XS Disruption of traumatic injury wound repair, sequela',
  'T814XXA Infection following a procedure, initial encounter',
  'T814XXD Infection following a procedure, subsequent encounter',
  'T814XXS Infection following a procedure, sequela',
  'T81500A Unspecified complication of foreign body accidentally left in body following surgical operation,',
  'initial encounter',
  'T81500D Unspecified complication of foreign body accidentally left in body following surgical operation,',
  'subsequent encounter',
  'T81500S Unspecified complication of foreign body accidentally left in body following surgical operation,',
  'sequela',
  'T81501A Unspecified complication of foreign body accidentally left in body following infusion or',
  'transfusion, initial encounter',
  'T81501D Unspecified complication of foreign body accidentally left in body following infusion or',
  'transfusion, subsequent encounter',
  'T81501S Unspecified complication of foreign body accidentally left in body following infusion or',
  'transfusion, sequela',
  'T81502A Unspecified complication of foreign body accidentally left in body following kidney dialysis,',
  'initial encounter',
  'T81502D Unspecified complication of foreign body accidentally left in body following kidney dialysis,',
  'subsequent encounter',
  'T81502S Unspecified complication of foreign body accidentally left in body following kidney dialysis,',
  'sequela',
  'T81503A Unspecified complication of foreign body accidentally left in body following injection or',
  'immunization, initial encounter',
  'T81503D Unspecified complication of foreign body accidentally left in body following injection or',
  'immunization, subsequent encounter',
  'T81503S Unspecified complication of foreign body accidentally left in body following injection or',
  'immunization, sequela',
  'T81504A Unspecified complication of foreign body accidentally left in body following endoscopic',
  'examination, initial encounter',
  'T81504D Unspecified complication of foreign body accidentally left in body following endoscopic',
  'examination, subsequent encounter',
  'T81504S Unspecified complication of foreign body accidentally left in body following endoscopic',
  'examination, sequela',
  'T81505A Unspecified complication of foreign body accidentally left in body following heart',
  'catheterization, initial encounter',
  'T81505D Unspecified complication of foreign body accidentally left in body following heart',
  'catheterization, subsequent encounter',
  'T81505S Unspecified complication of foreign body accidentally left in body following heart',
  'catheterization, sequela',
  'T81506A Unspecified complication of foreign body accidentally left in body following aspiration,',
  'puncture or other catheterization, initial encounter',
  'T81506D Unspecified complication of foreign body accidentally left in body following aspiration,',
  'puncture or other catheterization, subsequent encounter',
  'T81506S Unspecified complication of foreign body accidentally left in body following aspiration,',
  'puncture or other catheterization, sequela',
  'T81507A Unspecified complication of foreign body accidentally left in body following removal of',
  'catheter or packing, initial encounter',
  'T81507D Unspecified complication of foreign body accidentally left in body following removal of',
  'catheter or packing, subsequent encounter',
  'T81507S Unspecified complication of foreign body accidentally left in body following removal of',
  'catheter or packing, sequela',
  'T81508A Unspecified complication of foreign body accidentally left in body following other procedure,',
  'initial encounter',
  'T81508D Unspecified complication of foreign body accidentally left in body following other procedure,',
  'subsequent encounter',
  'T81508S Unspecified complication of foreign body accidentally left in body following other procedure,',
  'sequela',
  'T81509A Unspecified complication of foreign body accidentally left in body following unspecified',
  'procedure, initial encounter',
  'T81509D Unspecified complication of foreign body accidentally left in body following unspecified',
  'procedure, subsequent encounter',
  'T81509S Unspecified complication of foreign body accidentally left in body following unspecified',
  'procedure, sequela',
  'T81510A Adhesions due to foreign body accidentally left in body following surgical operation, initial',
  'encounter',
  'T81510D Adhesions due to foreign body accidentally left in body following surgical operation,',
  'subsequent encounter',
  'T81510S Adhesions due to foreign body accidentally left in body following surgical operation, sequela',
  'T81511A Adhesions due to foreign body accidentally left in body following infusion or transfusion, initial',
  'encounter',
  'T81511D Adhesions due to foreign body accidentally left in body following infusion or transfusion,',
  'subsequent encounter',
  'T81511S Adhesions due to foreign body accidentally left in body following infusion or transfusion,',
  'sequela',
  'T81512A Adhesions due to foreign body accidentally left in body following kidney dialysis, initial',
  'encounter',
  'T81512D Adhesions due to foreign body accidentally left in body following kidney dialysis, subsequent',
  'encounter',
  'T81512S Adhesions due to foreign body accidentally left in body following kidney dialysis, sequela',
  'T81513A Adhesions due to foreign body accidentally left in body following injection or immunization,',
  'initial encounter',
  'T81513D Adhesions due to foreign body accidentally left in body following injection or immunization,',
  'subsequent encounter',
  'T81513S Adhesions due to foreign body accidentally left in body following injection or immunization,',
  'sequela',
  'T81514A Adhesions due to foreign body accidentally left in body following endoscopic examination,',
  'initial encounter',
  'T81514D Adhesions due to foreign body accidentally left in body following endoscopic examination,',
  'subsequent encounter',
  'T81514S Adhesions due to foreign body accidentally left in body following endoscopic examination,',
  'sequela',
  'T81515A Adhesions due to foreign body accidentally left in body following heart catheterization, initial',
  'encounter',
  'T81515D Adhesions due to foreign body accidentally left in body following heart catheterization,',
  'subsequent encounter',
  'T81515S Adhesions due to foreign body accidentally left in body following heart catheterization, sequela',
  'T81516A Adhesions due to foreign body accidentally left in body following aspiration, puncture or other',
  'catheterization, initial encounter',
  'T81516D Adhesions due to foreign body accidentally left in body following aspiration, puncture or other',
  'catheterization, subsequent encounter',
  'T81516S Adhesions due to foreign body accidentally left in body following aspiration, puncture or other',
  'catheterization, sequela',
  'T81517A Adhesions due to foreign body accidentally left in body following removal of catheter or',
  'packing, initial encounter',
  'T81517D Adhesions due to foreign body accidentally left in body following removal of catheter or',
  'packing, subsequent encounter',
  'T81517S Adhesions due to foreign body accidentally left in body following removal of catheter or',
  'packing, sequela',
  'T81518A Adhesions due to foreign body accidentally left in body following other procedure, initial',
  'encounter',
  'T81518D Adhesions due to foreign body accidentally left in body following other procedure, subsequent',
  'encounter',
  'T81518S Adhesions due to foreign body accidentally left in body following other procedure, sequela',
  'T81519A Adhesions due to foreign body accidentally left in body following unspecified procedure, initial',
  'encounter',
  'T81519D Adhesions due to foreign body accidentally left in body following unspecified procedure,',
  'subsequent encounter',
  'T81519S Adhesions due to foreign body accidentally left in body following unspecified procedure,',
  'sequela',
  'T81520A Obstruction due to foreign body accidentally left in body following surgical operation, initial',
  'encounter',
  'T81520D Obstruction due to foreign body accidentally left in body following surgical operation,',
  'subsequent encounter',
  'T81520S Obstruction due to foreign body accidentally left in body following surgical operation, sequela',
  'T81521A Obstruction due to foreign body accidentally left in body following infusion or transfusion,',
  'initial encounter',
  'T81521D Obstruction due to foreign body accidentally left in body following infusion or transfusion,',
  'subsequent encounter',
  'T81521S Obstruction due to foreign body accidentally left in body following infusion or transfusion,',
  'sequela',
  'T81522A Obstruction due to foreign body accidentally left in body following kidney dialysis, initial',
  'encounter',
  'T81522D Obstruction due to foreign body accidentally left in body following kidney dialysis, subsequent',
  'encounter',
  'T81522S Obstruction due to foreign body accidentally left in body following kidney dialysis, sequela',
  'T81523A Obstruction due to foreign body accidentally left in body following injection or immunization,',
  'initial encounter',
  'T81523D Obstruction due to foreign body accidentally left in body following injection or immunization,',
  'subsequent encounter',
  'T81523S Obstruction due to foreign body accidentally left in body following injection or immunization,',
  'sequela',
  'T81524A Obstruction due to foreign body accidentally left in body following endoscopic examination,',
  'initial encounter',
  'T81524D Obstruction due to foreign body accidentally left in body following endoscopic examination,',
  'subsequent encounter',
  'T81524S Obstruction due to foreign body accidentally left in body following endoscopic examination,',
  'sequela',
  'T81525A Obstruction due to foreign body accidentally left in body following heart catheterization, initial',
  'encounter',
  'T81525D Obstruction due to foreign body accidentally left in body following heart catheterization,',
  'subsequent encounter',
  'T81525S Obstruction due to foreign body accidentally left in body following heart catheterization,',
  'sequela',
  'T81526A Obstruction due to foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, initial encounter',
  'T81526D Obstruction due to foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, subsequent encounter',
  'T81526S Obstruction due to foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, sequela',
  'T81527A Obstruction due to foreign body accidentally left in body following removal of catheter or',
  'packing, initial encounter',
  'T81527D Obstruction due to foreign body accidentally left in body following removal of catheter or',
  'packing, subsequent encounter',
  'T81527S Obstruction due to foreign body accidentally left in body following removal of catheter or',
  'packing, sequela',
  'T81528A Obstruction due to foreign body accidentally left in body following other procedure, initial',
  'encounter',
  'T81528D Obstruction due to foreign body accidentally left in body following other procedure,',
  'subsequent encounter',
  'T81528S Obstruction due to foreign body accidentally left in body following other procedure, sequela',
  'T81529A Obstruction due to foreign body accidentally left in body following unspecified procedure,',
  'initial encounter',
  'T81529D Obstruction due to foreign body accidentally left in body following unspecified procedure,',
  'subsequent encounter',
  'T81529S Obstruction due to foreign body accidentally left in body following unspecified procedure,',
  'sequela',
  'T81530A Perforation due to foreign body accidentally left in body following surgical operation, initial',
  'encounter',
  'T81530D Perforation due to foreign body accidentally left in body following surgical operation,',
  'subsequent encounter',
  'T81530S Perforation due to foreign body accidentally left in body following surgical operation, sequela',
  'T81531A Perforation due to foreign body accidentally left in body following infusion or transfusion,',
  'initial encounter',
  'T81531D Perforation due to foreign body accidentally left in body following infusion or transfusion,',
  'subsequent encounter',
  'T81531S Perforation due to foreign body accidentally left in body following infusion or transfusion,',
  'sequela',
  'T81532A Perforation due to foreign body accidentally left in body following kidney dialysis, initial',
  'encounter',
  'T81532D Perforation due to foreign body accidentally left in body following kidney dialysis, subsequent',
  'encounter',
  'T81532S Perforation due to foreign body accidentally left in body following kidney dialysis, sequela',
  'T81533A Perforation due to foreign body accidentally left in body following injection or immunization,',
  'initial encounter',
  'T81533D Perforation due to foreign body accidentally left in body following injection or immunization,',
  'subsequent encounter',
  'T81533S Perforation due to foreign body accidentally left in body following injection or immunization,',
  'sequela',
  'T81534A Perforation due to foreign body accidentally left in body following endoscopic examination,',
  'initial encounter',
  'T81534D Perforation due to foreign body accidentally left in body following endoscopic examination,',
  'subsequent encounter',
  'T81534S Perforation due to foreign body accidentally left in body following endoscopic examination,',
  'sequela',
  'T81535A Perforation due to foreign body accidentally left in body following heart catheterization, initial',
  'encounter',
  'T81535D Perforation due to foreign body accidentally left in body following heart catheterization,',
  'subsequent encounter',
  'T81535S Perforation due to foreign body accidentally left in body following heart catheterization,',
  'sequela',
  'T81536A Perforation due to foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, initial encounter',
  'T81536D Perforation due to foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, subsequent encounter',
  'T81536S Perforation due to foreign body accidentally left in body following aspiration, puncture or other',
  'catheterization, sequela',
  'T81537A Perforation due to foreign body accidentally left in body following removal of catheter or',
  'packing, initial encounter',
  'T81537D Perforation due to foreign body accidentally left in body following removal of catheter or',
  'packing, subsequent encounter',
  'T81537S Perforation due to foreign body accidentally left in body following removal of catheter or',
  'packing, sequela',
  'T81538A Perforation due to foreign body accidentally left in body following other procedure, initial',
  'encounter',
  'T81538D Perforation due to foreign body accidentally left in body following other procedure,',
  'subsequent encounter',
  'T81538S Perforation due to foreign body accidentally left in body following other procedure, sequela',
  'T81539A Perforation due to foreign body accidentally left in body following unspecified procedure,',
  'initial encounter',
  'T81539D Perforation due to foreign body accidentally left in body following unspecified procedure,',
  'subsequent encounter',
  'T81539S Perforation due to foreign body accidentally left in body following unspecified procedure,',
  'sequela',
  'T81590A Other complications of foreign body accidentally left in body following surgical operation,',
  'initial encounter',
  'T81590D Other complications of foreign body accidentally left in body following surgical operation,',
  'subsequent encounter',
  'T81590S Other complications of foreign body accidentally left in body following surgical operation,',
  'sequela',
  'T81591A Other complications of foreign body accidentally left in body following infusion or transfusion,',
  'initial encounter',
  'T81591D Other complications of foreign body accidentally left in body following infusion or transfusion,',
  'subsequent encounter',
  'T81591S Other complications of foreign body accidentally left in body following infusion or transfusion,',
  'sequela',
  'T81592A Other complications of foreign body accidentally left in body following kidney dialysis, initial',
  'encounter',
  'T81592D Other complications of foreign body accidentally left in body following kidney dialysis,',
  'subsequent encounter',
  'T81592S Other complications of foreign body accidentally left in body following kidney dialysis, sequela',
  'T81593A Other complications of foreign body accidentally left in body following injection or',
  'immunization, initial encounter',
  'T81593D Other complications of foreign body accidentally left in body following injection or',
  'immunization, subsequent encounter',
  'T81593S Other complications of foreign body accidentally left in body following injection or',
  'immunization, sequela',
  'T81594A Other complications of foreign body accidentally left in body following endoscopic',
  'examination, initial encounter',
  'T81594D Other complications of foreign body accidentally left in body following endoscopic',
  'examination, subsequent encounter',
  'T81594S Other complications of foreign body accidentally left in body following endoscopic',
  'examination, sequela',
  'T81595A Other complications of foreign body accidentally left in body following heart catheterization,',
  'initial encounter',
  'T81595D Other complications of foreign body accidentally left in body following heart catheterization,',
  'subsequent encounter',
  'T81595S Other complications of foreign body accidentally left in body following heart catheterization,',
  'sequela',
  'T81596A Other complications of foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, initial encounter',
  'T81596D Other complications of foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, subsequent encounter',
  'T81596S Other complications of foreign body accidentally left in body following aspiration, puncture or',
  'other catheterization, sequela',
  'T81597A Other complications of foreign body accidentally left in body following removal of catheter or',
  'packing, initial encounter',
  'T81597D Other complications of foreign body accidentally left in body following removal of catheter or',
  'packing, subsequent encounter',
  'T81597S Other complications of foreign body accidentally left in body following removal of catheter or',
  'packing, sequela',
  'T81598A Other complications of foreign body accidentally left in body following other procedure, initial',
  'encounter',
  'T81598D Other complications of foreign body accidentally left in body following other procedure,',
  'subsequent encounter',
  'T81598S Other complications of foreign body accidentally left in body following other procedure,',
  'sequela',
  'T81599A Other complications of foreign body accidentally left in body following unspecified procedure,',
  'initial encounter',
  'T81599D Other complications of foreign body accidentally left in body following unspecified procedure,',
  'subsequent encounter',
  'T81599S Other complications of foreign body accidentally left in body following unspecified procedure,',
  'sequela',
  'T8160XA Unspecified acute reaction to foreign substance accidentally left during a procedure, initial',
  'encounter',
  'T8160XD Unspecified acute reaction to foreign substance accidentally left during a procedure,',
  'subsequent encounter',
  'T8160XS Unspecified acute reaction to foreign substance accidentally left during a procedure, sequela',
  'T8161XA Aseptic peritonitis due to foreign substance accidentally left during a procedure, initial',
  'encounter',
  'T8161XD Aseptic peritonitis due to foreign substance accidentally left during a procedure, subsequent',
  'encounter',
  'T8161XS Aseptic peritonitis due to foreign substance accidentally left during a procedure, sequela',
  'T8169XA Other acute reaction to foreign substance accidentally left during a procedure, initial',
  'encounter',
  'T8169XD Other acute reaction to foreign substance accidentally left during a procedure, subsequent',
  'encounter',
  'T8169XS Other acute reaction to foreign substance accidentally left during a procedure, sequela',
  'T81710A Complication of mesenteric artery following a procedure, not elsewhere classified, initial',
  'encounter',
  'T81710D Complication of mesenteric artery following a procedure, not elsewhere classified, subsequent',
  'encounter',
  'T81710S Complication of mesenteric artery following a procedure, not elsewhere classified, sequela',
  'T81711A Complication of renal artery following a procedure, not elsewhere classified, initial encounter',
  'T81711D Complication of renal artery following a procedure, not elsewhere classified, subsequent',
  'encounter',
  'T81711S Complication of renal artery following a procedure, not elsewhere classified, sequela',
  'T81718A Complication of other artery following a procedure, not elsewhere classified, initial encounter',
  'T81718D Complication of other artery following a procedure, not elsewhere classified, subsequent',
  'encounter',
  'T81718S Complication of other artery following a procedure, not elsewhere classified, sequela',
  'T81719A Complication of unspecified artery following a procedure, not elsewhere classified, initial',
  'encounter',
  'T81719D Complication of unspecified artery following a procedure, not elsewhere classified, subsequent',
  'encounter',
  'T81719S Complication of unspecified artery following a procedure, not elsewhere classified, sequela',
  'T8172XA Complication of vein following a procedure, not elsewhere classified, initial encounter',
  'T8172XD Complication of vein following a procedure, not elsewhere classified, subsequent encounter',
  'T8172XS Complication of vein following a procedure, not elsewhere classified, sequela',
  'T8181XA Complication of inhalation therapy, initial encounter',
  'T8181XD Complication of inhalation therapy, subsequent encounter',
  'T8181XS Complication of inhalation therapy, sequela',
  'T8182XA Emphysema (subcutaneous) resulting from a procedure, initial encounter',
  'T8182XD Emphysema (subcutaneous) resulting from a procedure, subsequent encounter',
  'T8182XS Emphysema (subcutaneous) resulting from a procedure, sequela',
  'T8183XA Persistent postprocedural fistula, initial encounter',
  'T8183XD Persistent postprocedural fistula, subsequent encounter',
  'T8183XS Persistent postprocedural fistula, sequela',
  'T8189XA Other complications of procedures, not elsewhere classified, initial encounter',
  'T8189XD Other complications of procedures, not elsewhere classified, subsequent encounter',
  'T8189XS Other complications of procedures, not elsewhere classified, sequela',
  'T819XXA Unspecified complication of procedure, initial encounter',
  'T819XXD Unspecified complication of procedure, subsequent encounter',
  'T819XXS Unspecified complication of procedure, sequela',
  'T8201XA Breakdown (mechanical) of heart valve prosthesis, initial encounter',
  'T8201XD Breakdown (mechanical) of heart valve prosthesis, subsequent encounter',
  'T8201XS Breakdown (mechanical) of heart valve prosthesis, sequela',
  'T8202XA Displacement of heart valve prosthesis, initial encounter',
  'T8202XD Displacement of heart valve prosthesis, subsequent encounter',
  'T8202XS Displacement of heart valve prosthesis, sequela',
  'T8203XA Leakage of heart valve prosthesis, initial encounter',
  'T8203XD Leakage of heart valve prosthesis, subsequent encounter',
  'T8203XS Leakage of heart valve prosthesis, sequela',
  'T8209XA Other mechanical complication of heart valve prosthesis, initial encounter',
  'T8209XD Other mechanical complication of heart valve prosthesis, subsequent encounter',
  'T8209XS Other mechanical complication of heart valve prosthesis, sequela',
  'T82110A Breakdown (mechanical) of cardiac electrode, initial encounter',
  'T82110D Breakdown (mechanical) of cardiac electrode, subsequent encounter',
  'T82110S Breakdown (mechanical) of cardiac electrode, sequela',
  'T82111A Breakdown (mechanical) of cardiac pulse generator (battery), initial encounter',
  'T82111D Breakdown (mechanical) of cardiac pulse generator (battery), subsequent encounter',
  'T82111S Breakdown (mechanical) of cardiac pulse generator (battery), sequela',
  'T82118A Breakdown (mechanical) of other cardiac electronic device, initial encounter',
  'T82118D Breakdown (mechanical) of other cardiac electronic device, subsequent encounter',
  'T82118S Breakdown (mechanical) of other cardiac electronic device, sequela',
  'T82119A Breakdown (mechanical) of unspecified cardiac electronic device, initial encounter',
  'T82119D Breakdown (mechanical) of unspecified cardiac electronic device, subsequent encounter',
  'T82119S Breakdown (mechanical) of unspecified cardiac electronic device, sequela',
  'T82120A Displacement of cardiac electrode, initial encounter',
  'T82120D Displacement of cardiac electrode, subsequent encounter',
  'T82120S Displacement of cardiac electrode, sequela',
  'T82121A Displacement of cardiac pulse generator (battery), initial encounter',
  'T82121D Displacement of cardiac pulse generator (battery), subsequent encounter',
  'T82121S Displacement of cardiac pulse generator (battery), sequela',
  'T82128A Displacement of other cardiac electronic device, initial encounter',
  'T82128D Displacement of other cardiac electronic device, subsequent encounter',
  'T82128S Displacement of other cardiac electronic device, sequela',
  'T82129A Displacement of unspecified cardiac electronic device, initial encounter',
  'T82129D Displacement of unspecified cardiac electronic device, subsequent encounter',
  'T82129S Displacement of unspecified cardiac electronic device, sequela',
  'T82190A Other mechanical complication of cardiac electrode, initial encounter',
  'T82190D Other mechanical complication of cardiac electrode, subsequent encounter',
  'T82190S Other mechanical complication of cardiac electrode, sequela',
  'T82191A Other mechanical complication of cardiac pulse generator (battery), initial encounter',
  'T82191D Other mechanical complication of cardiac pulse generator (battery), subsequent encounter',
  'T82191S Other mechanical complication of cardiac pulse generator (battery), sequela',
  'T82198A Other mechanical complication of other cardiac electronic device, initial encounter',
  'T82198D Other mechanical complication of other cardiac electronic device, subsequent encounter',
  'T82198S Other mechanical complication of other cardiac electronic device, sequela',
  'T82199A Other mechanical complication of unspecified cardiac device, initial encounter',
  'T82199D Other mechanical complication of unspecified cardiac device, subsequent encounter',
  'T82199S Other mechanical complication of unspecified cardiac device, sequela',
  'T82211A Breakdown (mechanical) of coronary artery bypass graft, initial encounter',
  'T82211D Breakdown (mechanical) of coronary artery bypass graft, subsequent encounter',
  'T82211S Breakdown (mechanical) of coronary artery bypass graft, sequela',
  'T82212A Displacement of coronary artery bypass graft, initial encounter',
  'T82212D Displacement of coronary artery bypass graft, subsequent encounter',
  'T82212S Displacement of coronary artery bypass graft, sequela',
  'T82213A Leakage of coronary artery bypass graft, initial encounter',
  'T82213D Leakage of coronary artery bypass graft, subsequent encounter',
  'T82213S Leakage of coronary artery bypass graft, sequela',
  'T82218A Other mechanical complication of coronary artery bypass graft, initial encounter',
  'T82218D Other mechanical complication of coronary artery bypass graft, subsequent encounter',
  'T82218S Other mechanical complication of coronary artery bypass graft, sequela',
  'T82221A Breakdown (mechanical) of biological heart valve graft, initial encounter',
  'T82221D Breakdown (mechanical) of biological heart valve graft, subsequent encounter',
  'T82221S Breakdown (mechanical) of biological heart valve graft, sequela',
  'T82222A Displacement of biological heart valve graft, initial encounter',
  'T82222D Displacement of biological heart valve graft, subsequent encounter',
  'T82222S Displacement of biological heart valve graft, sequela',
  'T82223A Leakage of biological heart valve graft, initial encounter',
  'T82223D Leakage of biological heart valve graft, subsequent encounter',
  'T82223S Leakage of biological heart valve graft, sequela',
  'T82228A Other mechanical complication of biological heart valve graft, initial encounter',
  'T82228D Other mechanical complication of biological heart valve graft, subsequent encounter',
  'T82228S Other mechanical complication of biological heart valve graft, sequela',
  'T82310A Breakdown (mechanical) of aortic (bifurcation) graft (replacement), initial encounter',
  'T82310D Breakdown (mechanical) of aortic (bifurcation) graft (replacement), subsequent encounter',
  'T82310S Breakdown (mechanical) of aortic (bifurcation) graft (replacement), sequela',
  'T82311A Breakdown (mechanical) of carotid arterial graft (bypass), initial encounter',
  'T82311D Breakdown (mechanical) of carotid arterial graft (bypass), subsequent encounter',
  'T82311S Breakdown (mechanical) of carotid arterial graft (bypass), sequela',
  'T82312A Breakdown (mechanical) of femoral arterial graft (bypass), initial encounter',
  'T82312D Breakdown (mechanical) of femoral arterial graft (bypass), subsequent encounter',
  'T82312S Breakdown (mechanical) of femoral arterial graft (bypass), sequela',
  'T82318A Breakdown (mechanical) of other vascular grafts, initial encounter',
  'T82318D Breakdown (mechanical) of other vascular grafts, subsequent encounter',
  'T82318S Breakdown (mechanical) of other vascular grafts, sequela',
  'T82319A Breakdown (mechanical) of unspecified vascular grafts, initial encounter',
  'T82319D Breakdown (mechanical) of unspecified vascular grafts, subsequent encounter',
  'T82319S Breakdown (mechanical) of unspecified vascular grafts, sequela',
  'T82320A Displacement of aortic (bifurcation) graft (replacement), initial encounter',
  'T82320D Displacement of aortic (bifurcation) graft (replacement), subsequent encounter',
  'T82320S Displacement of aortic (bifurcation) graft (replacement), sequela',
  'T82321A Displacement of carotid arterial graft (bypass), initial encounter',
  'T82321D Displacement of carotid arterial graft (bypass), subsequent encounter',
  'T82321S Displacement of carotid arterial graft (bypass), sequela',
  'T82322A Displacement of femoral arterial graft (bypass), initial encounter',
  'T82322D Displacement of femoral arterial graft (bypass), subsequent encounter',
  'T82322S Displacement of femoral arterial graft (bypass), sequela',
  'T82328A Displacement of other vascular grafts, initial encounter',
  'T82328D Displacement of other vascular grafts, subsequent encounter',
  'T82328S Displacement of other vascular grafts, sequela',
  'T82329A Displacement of unspecified vascular grafts, initial encounter',
  'T82329D Displacement of unspecified vascular grafts, subsequent encounter',
  'T82329S Displacement of unspecified vascular grafts, sequela',
  'T82330A Leakage of aortic (bifurcation) graft (replacement), initial encounter',
  'T82330D Leakage of aortic (bifurcation) graft (replacement), subsequent encounter',
  'T82330S Leakage of aortic (bifurcation) graft (replacement), sequela',
  'T82331A Leakage of carotid arterial graft (bypass), initial encounter',
  'T82331D Leakage of carotid arterial graft (bypass), subsequent encounter',
  'T82331S Leakage of carotid arterial graft (bypass), sequela',
  'T82332A Leakage of femoral arterial graft (bypass), initial encounter',
  'T82332D Leakage of femoral arterial graft (bypass), subsequent encounter',
  'T82332S Leakage of femoral arterial graft (bypass), sequela',
  'T82338A Leakage of other vascular grafts, initial encounter',
  'T82338D Leakage of other vascular grafts, subsequent encounter',
  'T82338S Leakage of other vascular grafts, sequela',
  'T82339A Leakage of unspecified vascular graft, initial encounter',
  'T82339D Leakage of unspecified vascular graft, subsequent encounter',
  'T82339S Leakage of unspecified vascular graft, sequela',
  'T82390A Other mechanical complication of aortic (bifurcation) graft (replacement), initial encounter',
  'T82390D Other mechanical complication of aortic (bifurcation) graft (replacement), subsequent',
  'encounter',
  'T82390S Other mechanical complication of aortic (bifurcation) graft (replacement), sequela',
  'T82391A Other mechanical complication of carotid arterial graft (bypass), initial encounter',
  'T82391D Other mechanical complication of carotid arterial graft (bypass), subsequent encounter',
  'T82391S Other mechanical complication of carotid arterial graft (bypass), sequela',
  'T82392A Other mechanical complication of femoral arterial graft (bypass), initial encounter',
  'T82392D Other mechanical complication of femoral arterial graft (bypass), subsequent encounter',
  'T82392S Other mechanical complication of femoral arterial graft (bypass), sequela',
  'T82398A Other mechanical complication of other vascular grafts, initial encounter',
  'T82398D Other mechanical complication of other vascular grafts, subsequent encounter',
  'T82398S Other mechanical complication of other vascular grafts, sequela',
  'T82399A Other mechanical complication of unspecified vascular grafts, initial encounter',
  'T82399D Other mechanical complication of unspecified vascular grafts, subsequent encounter',
  'T82399S Other mechanical complication of unspecified vascular grafts, sequela',
  'T8241XA Breakdown (mechanical) of vascular dialysis catheter, initial encounter',
  'T8241XD Breakdown (mechanical) of vascular dialysis catheter, subsequent encounter',
  'T8241XS Breakdown (mechanical) of vascular dialysis catheter, sequela',
  'T8242XA Displacement of vascular dialysis catheter, initial encounter',
  'T8242XD Displacement of vascular dialysis catheter, subsequent encounter',
  'T8242XS Displacement of vascular dialysis catheter, sequela',
  'T8243XA Leakage of vascular dialysis catheter, initial encounter',
  'T8243XD Leakage of vascular dialysis catheter, subsequent encounter',
  'T8243XS Leakage of vascular dialysis catheter, sequela',
  'T8249XA Other complication of vascular dialysis catheter, initial encounter',
  'T8249XD Other complication of vascular dialysis catheter, subsequent encounter',
  'T8249XS Other complication of vascular dialysis catheter, sequela',
  'T82510A Breakdown (mechanical) of surgically created arteriovenous fistula, initial encounter',
  'T82510D Breakdown (mechanical) of surgically created arteriovenous fistula, subsequent encounter',
  'T82510S Breakdown (mechanical) of surgically created arteriovenous fistula, sequela',
  'T82511A Breakdown (mechanical) of surgically created arteriovenous shunt, initial encounter',
  'T82511D Breakdown (mechanical) of surgically created arteriovenous shunt, subsequent encounter',
  'T82511S Breakdown (mechanical) of surgically created arteriovenous shunt, sequela',
  'T82512A Breakdown (mechanical) of artificial heart, initial encounter',
  'T82512D Breakdown (mechanical) of artificial heart, subsequent encounter',
  'T82512S Breakdown (mechanical) of artificial heart, sequela',
  'T82513A Breakdown (mechanical) of balloon (counterpulsation) device, initial encounter',
  'T82513D Breakdown (mechanical) of balloon (counterpulsation) device, subsequent encounter',
  'T82513S Breakdown (mechanical) of balloon (counterpulsation) device, sequela',
  'T82514A Breakdown (mechanical) of infusion catheter, initial encounter',
  'T82514D Breakdown (mechanical) of infusion catheter, subsequent encounter',
  'T82514S Breakdown (mechanical) of infusion catheter, sequela',
  'T82515A Breakdown (mechanical) of umbrella device, initial encounter',
  'T82515D Breakdown (mechanical) of umbrella device, subsequent encounter',
  'T82515S Breakdown (mechanical) of umbrella device, sequela',
  'T82518A Breakdown (mechanical) of other cardiac and vascular devices and implants, initial encounter',
  'T82518D Breakdown (mechanical) of other cardiac and vascular devices and implants, subsequent',
  'encounter',
  'T82518S Breakdown (mechanical) of other cardiac and vascular devices and implants, sequela',
  'T82519A Breakdown (mechanical) of unspecified cardiac and vascular devices and implants, initial',
  'encounter',
  'T82519D Breakdown (mechanical) of unspecified cardiac and vascular devices and implants, subsequent',
  'encounter',
  'T82519S Breakdown (mechanical) of unspecified cardiac and vascular devices and implants, sequela',
  'T82520A Displacement of surgically created arteriovenous fistula, initial encounter',
  'T82520D Displacement of surgically created arteriovenous fistula, subsequent encounter',
  'T82520S Displacement of surgically created arteriovenous fistula, sequela',
  'T82521A Displacement of surgically created arteriovenous shunt, initial encounter',
  'T82521D Displacement of surgically created arteriovenous shunt, subsequent encounter',
  'T82521S Displacement of surgically created arteriovenous shunt, sequela',
  'T82522A Displacement of artificial heart, initial encounter',
  'T82522D Displacement of artificial heart, subsequent encounter',
  'T82522S Displacement of artificial heart, sequela',
  'T82523A Displacement of balloon (counterpulsation) device, initial encounter',
  'T82523D Displacement of balloon (counterpulsation) device, subsequent encounter',
  'T82523S Displacement of balloon (counterpulsation) device, sequela',
  'T82524A Displacement of infusion catheter, initial encounter',
  'T82524D Displacement of infusion catheter, subsequent encounter',
  'T82524S Displacement of infusion catheter, sequela',
  'T82525A Displacement of umbrella device, initial encounter',
  'T82525D Displacement of umbrella device, subsequent encounter',
  'T82525S Displacement of umbrella device, sequela',
  'T82528A Displacement of other cardiac and vascular devices and implants, initial encounter',
  'T82528D Displacement of other cardiac and vascular devices and implants, subsequent encounter',
  'T82528S Displacement of other cardiac and vascular devices and implants, sequela',
  'T82529A Displacement of unspecified cardiac and vascular devices and implants, initial encounter',
  'T82529D Displacement of unspecified cardiac and vascular devices and implants, subsequent encounter',
  'T82529S Displacement of unspecified cardiac and vascular devices and implants, sequela',
  'T82530A Leakage of surgically created arteriovenous fistula, initial encounter',
  'T82530D Leakage of surgically created arteriovenous fistula, subsequent encounter',
  'T82530S Leakage of surgically created arteriovenous fistula, sequela',
  'T82531A Leakage of surgically created arteriovenous shunt, initial encounter',
  'T82531D Leakage of surgically created arteriovenous shunt, subsequent encounter',
  'T82531S Leakage of surgically created arteriovenous shunt, sequela',
  'T82532A Leakage of artificial heart, initial encounter',
  'T82532D Leakage of artificial heart, subsequent encounter',
  'T82532S Leakage of artificial heart, sequela',
  'T82533A Leakage of balloon (counterpulsation) device, initial encounter',
  'T82533D Leakage of balloon (counterpulsation) device, subsequent encounter',
  'T82533S Leakage of balloon (counterpulsation) device, sequela',
  'T82534A Leakage of infusion catheter, initial encounter',
  'T82534D Leakage of infusion catheter, subsequent encounter',
  'T82534S Leakage of infusion catheter, sequela',
  'T82535A Leakage of umbrella device, initial encounter',
  'T82535D Leakage of umbrella device, subsequent encounter',
  'T82535S Leakage of umbrella device, sequela',
  'T82538A Leakage of other cardiac and vascular devices and implants, initial encounter',
  'T82538D Leakage of other cardiac and vascular devices and implants, subsequent encounter',
  'T82538S Leakage of other cardiac and vascular devices and implants, sequela',
  'T82539A Leakage of unspecified cardiac and vascular devices and implants, initial encounter',
  'T82539D Leakage of unspecified cardiac and vascular devices and implants, subsequent encounter',
  'T82539S Leakage of unspecified cardiac and vascular devices and implants, sequela',
  'T82590A Other mechanical complication of surgically created arteriovenous fistula, initial encounter',
  'T82590D Other mechanical complication of surgically created arteriovenous fistula, subsequent',
  'encounter',
  'T82590S Other mechanical complication of surgically created arteriovenous fistula, sequela',
  'T82591A Other mechanical complication of surgically created arteriovenous shunt, initial encounter',
  'T82591D Other mechanical complication of surgically created arteriovenous shunt, subsequent',
  'encounter',
  'T82591S Other mechanical complication of surgically created arteriovenous shunt, sequela',
  'T82592A Other mechanical complication of artificial heart, initial encounter',
  'T82592D Other mechanical complication of artificial heart, subsequent encounter',
  'T82592S Other mechanical complication of artificial heart, sequela',
  'T82593A Other mechanical complication of balloon (counterpulsation) device, initial encounter',
  'T82593D Other mechanical complication of balloon (counterpulsation) device, subsequent encounter',
  'T82593S Other mechanical complication of balloon (counterpulsation) device, sequela',
  'T82594A Other mechanical complication of infusion catheter, initial encounter',
  'T82594D Other mechanical complication of infusion catheter, subsequent encounter',
  'T82594S Other mechanical complication of infusion catheter, sequela',
  'T82595A Other mechanical complication of umbrella device, initial encounter',
  'T82595D Other mechanical complication of umbrella device, subsequent encounter',
  'T82595S Other mechanical complication of umbrella device, sequela',
  'T82598A Other mechanical complication of other cardiac and vascular devices and implants, initial',
  'encounter',
  'T82598D Other mechanical complication of other cardiac and vascular devices and implants, subsequent',
  'encounter',
  'T82598S Other mechanical complication of other cardiac and vascular devices and implants, sequela',
  'T82599A Other mechanical complication of unspecified cardiac and vascular devices and implants, initial',
  'encounter',
  'T82599D Other mechanical complication of unspecified cardiac and vascular devices and implants,',
  'subsequent encounter',
  'T82599S Other mechanical complication of unspecified cardiac and vascular devices and implants,',
  'sequela',
  'T826XXA Infection and inflammatory reaction due to cardiac valve prosthesis, initial encounter',
  'T826XXD Infection and inflammatory reaction due to cardiac valve prosthesis, subsequent encounter',
  'T826XXS Infection and inflammatory reaction due to cardiac valve prosthesis, sequela',
  'T827XXA Infection and inflammatory reaction due to other cardiac and vascular devices, implants and',
  'grafts, initial encounter',
  'T827XXD Infection and inflammatory reaction due to other cardiac and vascular devices, implants and',
  'grafts, subsequent encounter',
  'T827XXS Infection and inflammatory reaction due to other cardiac and vascular devices, implants and',
  'grafts, sequela',
  'T82817A Embolism due to cardiac prosthetic devices, implants and grafts, initial encounter',
  'T82817D Embolism due to cardiac prosthetic devices, implants and grafts, subsequent encounter',
  'T82817S Embolism due to cardiac prosthetic devices, implants and grafts, sequela',
  'T82818A Embolism due to vascular prosthetic devices, implants and grafts, initial encounter',
  'T82818D Embolism due to vascular prosthetic devices, implants and grafts, subsequent encounter',
  'T82818S Embolism due to vascular prosthetic devices, implants and grafts, sequela',
  'T82827A Fibrosis due to cardiac prosthetic devices, implants and grafts, initial encounter',
  'T82827D Fibrosis due to cardiac prosthetic devices, implants and grafts, subsequent encounter',
  'T82827S Fibrosis due to cardiac prosthetic devices, implants and grafts, sequela',
  'T82828A Fibrosis due to vascular prosthetic devices, implants and grafts, initial encounter',
  'T82828D Fibrosis due to vascular prosthetic devices, implants and grafts, subsequent encounter',
  'T82828S Fibrosis due to vascular prosthetic devices, implants and grafts, sequela',
  'T82837A Hemorrhage due to cardiac prosthetic devices, implants and grafts, initial encounter',
  'T82837D Hemorrhage due to cardiac prosthetic devices, implants and grafts, subsequent encounter',
  'T82837S Hemorrhage due to cardiac prosthetic devices, implants and grafts, sequela',
  'T82838A Hemorrhage due to vascular prosthetic devices, implants and grafts, initial encounter',
  'T82838D Hemorrhage due to vascular prosthetic devices, implants and grafts, subsequent encounter',
  'T82838S Hemorrhage due to vascular prosthetic devices, implants and grafts, sequela',
  'T82847A Pain due to cardiac prosthetic devices, implants and grafts, initial encounter',
  'T82847D Pain due to cardiac prosthetic devices, implants and grafts, subsequent encounter',
  'T82847S Pain due to cardiac prosthetic devices, implants and grafts, sequela',
  'T82848A Pain due to vascular prosthetic devices, implants and grafts, initial encounter',
  'T82848D Pain due to vascular prosthetic devices, implants and grafts, subsequent encounter',
  'T82848S Pain due to vascular prosthetic devices, implants and grafts, sequela',
  'T82855A Stenosis of coronary artery stent, initial encounter',
  'T82855D Stenosis of coronary artery stent, subsequent encounter',
  'T82855S Stenosis of coronary artery stent, sequela',
  'T82856A Stenosis of peripheral vascular stent, initial encounter',
  'T82856D Stenosis of peripheral vascular stent, subsequent encounter',
  'T82856S Stenosis of peripheral vascular stent, sequela',
  'T82857A Stenosis of other cardiac prosthetic devices, implants and grafts, initial encounter',
  'T82857D Stenosis of other cardiac prosthetic devices, implants and grafts, subsequent encounter',
  'T82857S Stenosis of other cardiac prosthetic devices, implants and grafts, sequela',
  'T82858A Stenosis of other vascular prosthetic devices, implants and grafts, initial encounter',
  'T82858D Stenosis of other vascular prosthetic devices, implants and grafts, subsequent encounter',
  'T82858S Stenosis of other vascular prosthetic devices, implants and grafts, sequela',
  'T82867A Thrombosis due to cardiac prosthetic devices, implants and grafts, initial encounter',
  'T82867D Thrombosis due to cardiac prosthetic devices, implants and grafts, subsequent encounter',
  'T82867S Thrombosis due to cardiac prosthetic devices, implants and grafts, sequela',
  'T82868A Thrombosis due to vascular prosthetic devices, implants and grafts, initial encounter',
  'T82868D Thrombosis due to vascular prosthetic devices, implants and grafts, subsequent encounter',
  'T82868S Thrombosis due to vascular prosthetic devices, implants and grafts, sequela',
  'T82897A Other specified complication of cardiac prosthetic devices, implants and grafts, initial',
  'encounter',
  'T82897D Other specified complication of cardiac prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T82897S Other specified complication of cardiac prosthetic devices, implants and grafts, sequela',
  'T82898A Other specified complication of vascular prosthetic devices, implants and grafts, initial',
  'encounter',
  'T82898D Other specified complication of vascular prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T82898S Other specified complication of vascular prosthetic devices, implants and grafts, sequela',
  'T829XXA Unspecified complication of cardiac and vascular prosthetic device, implant and graft, initial',
  'encounter',
  'T829XXD Unspecified complication of cardiac and vascular prosthetic device, implant and graft,',
  'subsequent encounter',
  'T829XXS Unspecified complication of cardiac and vascular prosthetic device, implant and graft, sequela',
  'T83010A Breakdown (mechanical) of cystostomy catheter, initial encounter',
  'T83010D Breakdown (mechanical) of cystostomy catheter, subsequent encounter',
  'T83010S Breakdown (mechanical) of cystostomy catheter, sequela',
  'T83011A Breakdown (mechanical) of indwelling urethral catheter, initial encounter',
  'T83011D Breakdown (mechanical) of indwelling urethral catheter, subsequent encounter',
  'T83011S Breakdown (mechanical) of indwelling urethral catheter, sequela',
  'T83012A Breakdown (mechanical) of nephrostomy catheter, initial encounter',
  'T83012D Breakdown (mechanical) of nephrostomy catheter, subsequent encounter',
  'T83012S Breakdown (mechanical) of nephrostomy catheter, sequela',
  'T83018A Breakdown (mechanical) of other urinary catheter, initial encounter',
  'T83018D Breakdown (mechanical) of other urinary catheter, subsequent encounter',
  'T83018S Breakdown (mechanical) of other urinary catheter, sequela',
  'T83020A Displacement of cystostomy catheter, initial encounter',
  'T83020D Displacement of cystostomy catheter, subsequent encounter',
  'T83020S Displacement of cystostomy catheter, sequela',
  'T83021A Displacement of indwelling urethral catheter, initial encounter',
  'T83021D Displacement of indwelling urethral catheter, subsequent encounter',
  'T83021S Displacement of indwelling urethral catheter, sequela',
  'T83022A Displacement of nephrostomy catheter, initial encounter',
  'T83022D Displacement of nephrostomy catheter, subsequent encounter',
  'T83022S Displacement of nephrostomy catheter, sequela',
  'T83028A Displacement of other urinary catheter, initial encounter',
  'T83028D Displacement of other urinary catheter, subsequent encounter',
  'T83028S Displacement of other urinary catheter, sequela',
  'T83030A Leakage of cystostomy catheter, initial encounter',
  'T83030D Leakage of cystostomy catheter, subsequent encounter',
  'T83030S Leakage of cystostomy catheter, sequela',
  'T83031A Leakage of indwelling urethral catheter, initial encounter',
  'T83031D Leakage of indwelling urethral catheter, subsequent encounter',
  'T83031S Leakage of indwelling urethral catheter, sequela',
  'T83032A Leakage of nephrostomy catheter, initial encounter',
  'T83032D Leakage of nephrostomy catheter, subsequent encounter',
  'T83032S Leakage of nephrostomy catheter, sequela',
  'T83038A Leakage of other urinary catheter, initial encounter',
  'T83038D Leakage of other urinary catheter, subsequent encounter',
  'T83038S Leakage of other urinary catheter, sequela',
  'T83090A Other mechanical complication of cystostomy catheter, initial encounter',
  'T83090D Other mechanical complication of cystostomy catheter, subsequent encounter',
  'T83090S Other mechanical complication of cystostomy catheter, sequela',
  'T83091A Other mechanical complication of indwelling urethral catheter, initial encounter',
  'T83091D Other mechanical complication of indwelling urethral catheter, subsequent encounter',
  'T83091S Other mechanical complication of indwelling urethral catheter, sequela',
  'T83092A Other mechanical complication of nephrostomy catheter, initial encounter',
  'T83092D Other mechanical complication of nephrostomy catheter, subsequent encounter',
  'T83092S Other mechanical complication of nephrostomy catheter, sequela',
  'T83098A Other mechanical complication of other urinary catheter, initial encounter',
  'T83098D Other mechanical complication of other urinary catheter, subsequent encounter',
  'T83098S Other mechanical complication of other urinary catheter, sequela',
  'T83110A Breakdown (mechanical) of urinary electronic stimulator device, initial encounter',
  'T83110D Breakdown (mechanical) of urinary electronic stimulator device, subsequent encounter',
  'T83110S Breakdown (mechanical) of urinary electronic stimulator device, sequela',
  'T83111A Breakdown (mechanical) of implanted urinary sphincter, initial encounter',
  'T83111D Breakdown (mechanical) of implanted urinary sphincter, subsequent encounter',
  'T83111S Breakdown (mechanical) of implanted urinary sphincter, sequela',
  'T83112A Breakdown (mechanical) of indwelling ureteral stent, initial encounter',
  'T83112D Breakdown (mechanical) of indwelling ureteral stent, subsequent encounter',
  'T83112S Breakdown (mechanical) of indwelling ureteral stent, sequela',
  'T83113A Breakdown (mechanical) of other urinary stents, initial encounter',
  'T83113D Breakdown (mechanical) of other urinary stents, subsequent encounter',
  'T83113S Breakdown (mechanical) of other urinary stents, sequela',
  'T83118A Breakdown (mechanical) of other urinary devices and implants, initial encounter',
  'T83118D Breakdown (mechanical) of other urinary devices and implants, subsequent encounter',
  'T83118S Breakdown (mechanical) of other urinary devices and implants, sequela',
  'T83120A Displacement of urinary electronic stimulator device, initial encounter',
  'T83120D Displacement of urinary electronic stimulator device, subsequent encounter',
  'T83120S Displacement of urinary electronic stimulator device, sequela',
  'T83121A Displacement of implanted urinary sphincter, initial encounter',
  'T83121D Displacement of implanted urinary sphincter, subsequent encounter',
  'T83121S Displacement of implanted urinary sphincter, sequela',
  'T83122A Displacement of indwelling ureteral stent, initial encounter',
  'T83122D Displacement of indwelling ureteral stent, subsequent encounter',
  'T83122S Displacement of indwelling ureteral stent, sequela',
  'T83123A Displacement of other urinary stents, initial encounter',
  'T83123D Displacement of other urinary stents, subsequent encounter',
  'T83123S Displacement of other urinary stents, sequela',
  'T83128A Displacement of other urinary devices and implants, initial encounter',
  'T83128D Displacement of other urinary devices and implants, subsequent encounter',
  'T83128S Displacement of other urinary devices and implants, sequela',
  'T83190A Other mechanical complication of urinary electronic stimulator device, initial encounter',
  'T83190D Other mechanical complication of urinary electronic stimulator device, subsequent encounter',
  'T83190S Other mechanical complication of urinary electronic stimulator device, sequela',
  'T83191A Other mechanical complication of implanted urinary sphincter, initial encounter',
  'T83191D Other mechanical complication of implanted urinary sphincter, subsequent encounter',
  'T83191S Other mechanical complication of implanted urinary sphincter, sequela',
  'T83192A Other mechanical complication of indwelling ureteral stent, initial encounter',
  'T83192D Other mechanical complication of indwelling ureteral stent, subsequent encounter',
  'T83192S Other mechanical complication of indwelling ureteral stent, sequela',
  'T83193A Other mechanical complication of other urinary stent, initial encounter',
  'T83193D Other mechanical complication of other urinary stent, subsequent encounter',
  'T83193S Other mechanical complication of other urinary stent, sequela',
  'T83198A Other mechanical complication of other urinary devices and implants, initial encounter',
  'T83198D Other mechanical complication of other urinary devices and implants, subsequent encounter',
  'T83198S Other mechanical complication of other urinary devices and implants, sequela',
  'T8321XA Breakdown (mechanical) of graft of urinary organ, initial encounter',
  'T8321XD Breakdown (mechanical) of graft of urinary organ, subsequent encounter',
  'T8321XS Breakdown (mechanical) of graft of urinary organ, sequela',
  'T8322XA Displacement of graft of urinary organ, initial encounter',
  'T8322XD Displacement of graft of urinary organ, subsequent encounter',
  'T8322XS Displacement of graft of urinary organ, sequela',
  'T8323XA Leakage of graft of urinary organ, initial encounter',
  'T8323XD Leakage of graft of urinary organ, subsequent encounter',
  'T8323XS Leakage of graft of urinary organ, sequela',
  'T8324XA Erosion of graft of urinary organ, initial encounter',
  'T8324XD Erosion of graft of urinary organ, subsequent encounter',
  'T8324XS Erosion of graft of urinary organ, sequela',
  'T8325XA Exposure of graft of urinary organ, initial encounter',
  'T8325XD Exposure of graft of urinary organ, subsequent encounter',
  'T8325XS Exposure of graft of urinary organ, sequela',
  'T8329XA Other mechanical complication of graft of urinary organ, initial encounter',
  'T8329XD Other mechanical complication of graft of urinary organ, subsequent encounter',
  'T8329XS Other mechanical complication of graft of urinary organ, sequela',
  'T8331XA Breakdown (mechanical) of intrauterine contraceptive device, initial encounter',
  'T8331XD Breakdown (mechanical) of intrauterine contraceptive device, subsequent encounter',
  'T8331XS Breakdown (mechanical) of intrauterine contraceptive device, sequela',
  'T8332XA Displacement of intrauterine contraceptive device, initial encounter',
  'T8332XD Displacement of intrauterine contraceptive device, subsequent encounter',
  'T8332XS Displacement of intrauterine contraceptive device, sequela',
  'T8339XA Other mechanical complication of intrauterine contraceptive device, initial encounter',
  'T8339XD Other mechanical complication of intrauterine contraceptive device, subsequent encounter',
  'T8339XS Other mechanical complication of intrauterine contraceptive device, sequela',
  'T83410A Breakdown (mechanical) of implanted penile prosthesis, initial encounter',
  'T83410D Breakdown (mechanical) of implanted penile prosthesis, subsequent encounter',
  'T83410S Breakdown (mechanical) of implanted penile prosthesis, sequela',
  'T83411A Breakdown (mechanical) of implanted testicular prosthesis, initial encounter',
  'T83411D Breakdown (mechanical) of implanted testicular prosthesis, subsequent encounter',
  'T83411S Breakdown (mechanical) of implanted testicular prosthesis, sequela',
  'T83418A Breakdown (mechanical) of other prosthetic devices, implants and grafts of genital tract, initial',
  'encounter',
  'T83418D Breakdown (mechanical) of other prosthetic devices, implants and grafts of genital tract,',
  'subsequent encounter',
  'T83418S Breakdown (mechanical) of other prosthetic devices, implants and grafts of genital tract,',
  'sequela',
  'T83420A Displacement of implanted penile prosthesis, initial encounter',
  'T83420D Displacement of implanted penile prosthesis, subsequent encounter',
  'T83420S Displacement of implanted penile prosthesis, sequela',
  'T83421A Displacement of implanted testicular prosthesis, initial encounter',
  'T83421D Displacement of implanted testicular prosthesis, subsequent encounter',
  'T83421S Displacement of implanted testicular prosthesis, sequela',
  'T83428A Displacement of other prosthetic devices, implants and grafts of genital tract, initial encounter',
  'T83428D Displacement of other prosthetic devices, implants and grafts of genital tract, subsequent',
  'encounter',
  'T83428S Displacement of other prosthetic devices, implants and grafts of genital tract, sequela',
  'T83490A Other mechanical complication of implanted penile prosthesis, initial encounter',
  'T83490D Other mechanical complication of implanted penile prosthesis, subsequent encounter',
  'T83490S Other mechanical complication of implanted penile prosthesis, sequela',
  'T83491A Other mechanical complication of implanted testicular prosthesis, initial encounter',
  'T83491D Other mechanical complication of implanted testicular prosthesis, subsequent encounter',
  'T83491S Other mechanical complication of implanted testicular prosthesis, sequela',
  'T83498A Other mechanical complication of other prosthetic devices, implants and grafts of genital tract,',
  'initial encounter',
  'T83498D Other mechanical complication of other prosthetic devices, implants and grafts of genital tract,',
  'subsequent encounter',
  'T83498S Other mechanical complication of other prosthetic devices, implants and grafts of genital tract,',
  'sequela',
  'T83510A Infection and inflammatory reaction due to cystostomy catheter, initial encounter',
  'T83510D Infection and inflammatory reaction due to cystostomy catheter, subsequent encounter',
  'T83510S Infection and inflammatory reaction due to cystostomy catheter, sequela',
  'T83511A Infection and inflammatory reaction due to indwelling urethral catheter, initial encounter',
  'T83511D Infection and inflammatory reaction due to indwelling urethral catheter, subsequent',
  'encounter',
  'T83511S Infection and inflammatory reaction due to indwelling urethral catheter, sequela',
  'T83512A Infection and inflammatory reaction due to nephrostomy catheter, initial encounter',
  'T83512D Infection and inflammatory reaction due to nephrostomy catheter, subsequent encounter',
  'T83512S Infection and inflammatory reaction due to nephrostomy catheter, sequela',
  'T83518A Infection and inflammatory reaction due to other urinary catheter, initial encounter',
  'T83518D Infection and inflammatory reaction due to other urinary catheter, subsequent encounter',
  'T83518S Infection and inflammatory reaction due to other urinary catheter, sequela',
  'T83590A Infection and inflammatory reaction due to implanted urinary neurostimulation device, initial',
  'encounter',
  'T83590D Infection and inflammatory reaction due to implanted urinary neurostimulation device,',
  'subsequent encounter',
  'T83590S Infection and inflammatory reaction due to implanted urinary neurostimulation device, sequela',
  'T83591A Infection and inflammatory reaction due to implanted urinary sphincter, initial encounter',
  'T83591D Infection and inflammatory reaction due to implanted urinary sphincter, subsequent',
  'encounter',
  'T83591S Infection and inflammatory reaction due to implanted urinary sphincter, sequela',
  'T83592A Infection and inflammatory reaction due to indwelling ureteral stent, initial encounter',
  'T83592D Infection and inflammatory reaction due to indwelling ureteral stent, subsequent encounter',
  'T83592S Infection and inflammatory reaction due to indwelling ureteral stent, sequela',
  'T83593A Infection and inflammatory reaction due to other urinary stents, initial encounter',
  'T83593D Infection and inflammatory reaction due to other urinary stents, subsequent encounter',
  'T83593S Infection and inflammatory reaction due to other urinary stents, sequela',
  'T83598A Infection and inflammatory reaction due to other prosthetic device, implant and graft in',
  'urinary system, initial encounter',
  'T83598D Infection and inflammatory reaction due to other prosthetic device, implant and graft in',
  'urinary system, subsequent encounter',
  'T83598S Infection and inflammatory reaction due to other prosthetic device, implant and graft in',
  'urinary system, sequela',
  'T8361XA Infection and inflammatory reaction due to implanted penile prosthesis, initial encounter',
  'T8361XD Infection and inflammatory reaction due to implanted penile prosthesis, subsequent',
  'encounter',
  'T8361XS Infection and inflammatory reaction due to implanted penile prosthesis, sequela',
  'T8362XA Infection and inflammatory reaction due to implanted testicular prosthesis, initial encounter',
  'T8362XD Infection and inflammatory reaction due to implanted testicular prosthesis, subsequent',
  'encounter',
  'T8362XS Infection and inflammatory reaction due to implanted testicular prosthesis, sequela',
  'T8369XA Infection and inflammatory reaction due to other prosthetic device, implant and graft in',
  'genital tract, initial encounter',
  'T8369XD Infection and inflammatory reaction due to other prosthetic device, implant and graft in',
  'genital tract, subsequent encounter',
  'T8369XS Infection and inflammatory reaction due to other prosthetic device, implant and graft in genital',
  'tract, sequela',
  'T83711A Erosion of implanted vaginal mesh to surrounding organ or tissue, initial encounter',
  'T83711D Erosion of implanted vaginal mesh to surrounding organ or tissue, subsequent encounter',
  'T83711S Erosion of implanted vaginal mesh to surrounding organ or tissue, sequela',
  'T83712A Erosion of implanted urethral mesh to surrounding organ or tissue, initial encounter',
  'T83712D Erosion of implanted urethral mesh to surrounding organ or tissue, subsequent encounter',
  'T83712S Erosion of implanted urethral mesh to surrounding organ or tissue, sequela',
  'T83713A Erosion of implanted urethral bulking agent to surrounding organ or tissue, initial encounter',
  'T83713D Erosion of implanted urethral bulking agent to surrounding organ or tissue, subsequent',
  'encounter',
  'T83713S Erosion of implanted urethral bulking agent to surrounding organ or tissue, sequela',
  'T83714A Erosion of implanted ureteral bulking agent to surrounding organ or tissue, initial encounter',
  'T83714D Erosion of implanted ureteral bulking agent to surrounding organ or tissue, subsequent',
  'encounter',
  'T83714S Erosion of implanted ureteral bulking agent to surrounding organ or tissue, sequela',
  'T83718A Erosion of other implanted mesh to organ or tissue, initial encounter',
  'T83718D Erosion of other implanted mesh to organ or tissue, subsequent encounter',
  'T83718S Erosion of other implanted mesh to organ or tissue, sequela',
  'T83719A Erosion of other prosthetic materials to surrounding organ or tissue, initial encounter',
  'T83719D Erosion of other prosthetic materials to surrounding organ or tissue, subsequent encounter',
  'T83719S Erosion of other prosthetic materials to surrounding organ or tissue, sequela',
  'T83721A Exposure of implanted vaginal mesh into vagina, initial encounter',
  'T83721D Exposure of implanted vaginal mesh into vagina, subsequent encounter',
  'T83721S Exposure of implanted vaginal mesh into vagina, sequela',
  'T83722A Exposure of implanted urethral mesh into urethra, initial encounter',
  'T83722D Exposure of implanted urethral mesh into urethra, subsequent encounter',
  'T83722S Exposure of implanted urethral mesh into urethra, sequela',
  'T83723A Exposure of implanted urethral bulking agent into urethra, initial encounter',
  'T83723D Exposure of implanted urethral bulking agent into urethra, subsequent encounter',
  'T83723S Exposure of implanted urethral bulking agent into urethra, sequela',
  'T83724A Exposure of implanted ureteral bulking agent into ureter, initial encounter',
  'T83724D Exposure of implanted ureteral bulking agent into ureter, subsequent encounter',
  'T83724S Exposure of implanted ureteral bulking agent into ureter, sequela',
  'T83728A Exposure of other implanted mesh into organ or tissue, initial encounter',
  'T83728D Exposure of other implanted mesh into organ or tissue, subsequent encounter',
  'T83728S Exposure of other implanted mesh into organ or tissue, sequela',
  'T83729A Exposure of other prosthetic materials into organ or tissue, initial encounter',
  'T83729D Exposure of other prosthetic materials into organ or tissue, subsequent encounter',
  'T83729S Exposure of other prosthetic materials into organ or tissue, sequela',
  'T8379XA Other specified complications due to other genitourinary prosthetic materials, initial encounter',
  'T8379XD Other specified complications due to other genitourinary prosthetic materials, subsequent',
  'encounter',
  'T8379XS Other specified complications due to other genitourinary prosthetic materials, sequela',
  'T8381XA Embolism due to genitourinary prosthetic devices, implants and grafts, initial encounter',
  'T8381XD Embolism due to genitourinary prosthetic devices, implants and grafts, subsequent encounter',
  'T8381XS Embolism due to genitourinary prosthetic devices, implants and grafts, sequela',
  'T8382XA Fibrosis due to genitourinary prosthetic devices, implants and grafts, initial encounter',
  'T8382XD Fibrosis due to genitourinary prosthetic devices, implants and grafts, subsequent encounter',
  'T8382XS Fibrosis due to genitourinary prosthetic devices, implants and grafts, sequela',
  'T8383XA Hemorrhage due to genitourinary prosthetic devices, implants and grafts, initial encounter',
  'T8383XD Hemorrhage due to genitourinary prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T8383XS Hemorrhage due to genitourinary prosthetic devices, implants and grafts, sequela',
  'T8384XA Pain due to genitourinary prosthetic devices, implants and grafts, initial encounter',
  'T8384XD Pain due to genitourinary prosthetic devices, implants and grafts, subsequent encounter',
  'T8384XS Pain due to genitourinary prosthetic devices, implants and grafts, sequela',
  'T8385XA Stenosis due to genitourinary prosthetic devices, implants and grafts, initial encounter',
  'T8385XD Stenosis due to genitourinary prosthetic devices, implants and grafts, subsequent encounter',
  'T8385XS Stenosis due to genitourinary prosthetic devices, implants and grafts, sequela',
  'T8386XA Thrombosis due to genitourinary prosthetic devices, implants and grafts, initial encounter',
  'T8386XD Thrombosis due to genitourinary prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T8386XS Thrombosis due to genitourinary prosthetic devices, implants and grafts, sequela',
  'T8389XA Other specified complication of genitourinary prosthetic devices, implants and grafts, initial',
  'encounter',
  'T8389XD Other specified complication of genitourinary prosthetic devices, implants and grafts,',
  'subsequent encounter',
  'T8389XS Other specified complication of genitourinary prosthetic devices, implants and grafts, sequela',
  'T839XXA Unspecified complication of genitourinary prosthetic device, implant and graft, initial',
  'encounter',
  'T839XXD Unspecified complication of genitourinary prosthetic device, implant and graft, subsequent',
  'encounter',
  'T839XXS Unspecified complication of genitourinary prosthetic device, implant and graft, sequela',
  'T84010A Broken internal right hip prosthesis, initial encounter',
  'T84010D Broken internal right hip prosthesis, subsequent encounter',
  'T84010S Broken internal right hip prosthesis, sequela',
  'T84011A Broken internal left hip prosthesis, initial encounter',
  'T84011D Broken internal left hip prosthesis, subsequent encounter',
  'T84011S Broken internal left hip prosthesis, sequela',
  'T84012A Broken internal right knee prosthesis, initial encounter',
  'T84012D Broken internal right knee prosthesis, subsequent encounter',
  'T84012S Broken internal right knee prosthesis, sequela',
  'T84013A Broken internal left knee prosthesis, initial encounter',
  'T84013D Broken internal left knee prosthesis, subsequent encounter',
  'T84013S Broken internal left knee prosthesis, sequela',
  'T84018A Broken internal joint prosthesis, other site, initial encounter',
  'T84018D Broken internal joint prosthesis, other site, subsequent encounter',
  'T84018S Broken internal joint prosthesis, other site, sequela',
  'T84019A Broken internal joint prosthesis, unspecified site, initial encounter',
  'T84019D Broken internal joint prosthesis, unspecified site, subsequent encounter',
  'T84019S Broken internal joint prosthesis, unspecified site, sequela',
  'T84020A Dislocation of internal right hip prosthesis, initial encounter',
  'T84020D Dislocation of internal right hip prosthesis, subsequent encounter',
  'T84020S Dislocation of internal right hip prosthesis, sequela',
  'T84021A Dislocation of internal left hip prosthesis, initial encounter',
  'T84021D Dislocation of internal left hip prosthesis, subsequent encounter',
  'T84021S Dislocation of internal left hip prosthesis, sequela',
  'T84022A Instability of internal right knee prosthesis, initial encounter',
  'T84022D Instability of internal right knee prosthesis, subsequent encounter',
  'T84022S Instability of internal right knee prosthesis, sequela',
  'T84023A Instability of internal left knee prosthesis, initial encounter',
  'T84023D Instability of internal left knee prosthesis, subsequent encounter',
  'T84023S Instability of internal left knee prosthesis, sequela',
  'T84028A Dislocation of other internal joint prosthesis, initial encounter',
  'T84028D Dislocation of other internal joint prosthesis, subsequent encounter',
  'T84028S Dislocation of other internal joint prosthesis, sequela',
  'T84029A Dislocation of unspecified internal joint prosthesis, initial encounter',
  'T84029D Dislocation of unspecified internal joint prosthesis, subsequent encounter',
  'T84029S Dislocation of unspecified internal joint prosthesis, sequela',
  'T84030A Mechanical loosening of internal right hip prosthetic joint, initial encounter',
  'T84030D Mechanical loosening of internal right hip prosthetic joint, subsequent encounter',
  'T84030S Mechanical loosening of internal right hip prosthetic joint, sequela',
  'T84031A Mechanical loosening of internal left hip prosthetic joint, initial encounter',
  'T84031D Mechanical loosening of internal left hip prosthetic joint, subsequent encounter',
  'T84031S Mechanical loosening of internal left hip prosthetic joint, sequela',
  'T84032A Mechanical loosening of internal right knee prosthetic joint, initial encounter',
  'T84032D Mechanical loosening of internal right knee prosthetic joint, subsequent encounter',
  'T84032S Mechanical loosening of internal right knee prosthetic joint, sequela',
  'T84033A Mechanical loosening of internal left knee prosthetic joint, initial encounter',
  'T84033D Mechanical loosening of internal left knee prosthetic joint, subsequent encounter',
  'T84033S Mechanical loosening of internal left knee prosthetic joint, sequela',
  'T84038A Mechanical loosening of other internal prosthetic joint, initial encounter',
  'T84038D Mechanical loosening of other internal prosthetic joint, subsequent encounter',
  'T84038S Mechanical loosening of other internal prosthetic joint, sequela',
  'T84039A Mechanical loosening of unspecified internal prosthetic joint, initial encounter',
  'T84039D Mechanical loosening of unspecified internal prosthetic joint, subsequent encounter',
  'T84039S Mechanical loosening of unspecified internal prosthetic joint, sequela',
  'T84050A Periprosthetic osteolysis of internal prosthetic right hip joint, initial encounter',
  'T84050D Periprosthetic osteolysis of internal prosthetic right hip joint, subsequent encounter',
  'T84050S Periprosthetic osteolysis of internal prosthetic right hip joint, sequela',
  'T84051A Periprosthetic osteolysis of internal prosthetic left hip joint, initial encounter',
  'T84051D Periprosthetic osteolysis of internal prosthetic left hip joint, subsequent encounter',
  'T84051S Periprosthetic osteolysis of internal prosthetic left hip joint, sequela',
  'T84052A Periprosthetic osteolysis of internal prosthetic right knee joint, initial encounter',
  'T84052D Periprosthetic osteolysis of internal prosthetic right knee joint, subsequent encounter',
  'T84052S Periprosthetic osteolysis of internal prosthetic right knee joint, sequela',
  'T84053A Periprosthetic osteolysis of internal prosthetic left knee joint, initial encounter',
  'T84053D Periprosthetic osteolysis of internal prosthetic left knee joint, subsequent encounter',
  'T84053S Periprosthetic osteolysis of internal prosthetic left knee joint, sequela',
  'T84058A Periprosthetic osteolysis of other internal prosthetic joint, initial encounter',
  'T84058D Periprosthetic osteolysis of other internal prosthetic joint, subsequent encounter',
  'T84058S Periprosthetic osteolysis of other internal prosthetic joint, sequela',
  'T84059A Periprosthetic osteolysis of unspecified internal prosthetic joint, initial encounter',
  'T84059D Periprosthetic osteolysis of unspecified internal prosthetic joint, subsequent encounter',
  'T84059S Periprosthetic osteolysis of unspecified internal prosthetic joint, sequela',
  'T84060A Wear of articular bearing surface of internal prosthetic right hip joint, initial encounter',
  'T84060D Wear of articular bearing surface of internal prosthetic right hip joint, subsequent encounter',
  'T84060S Wear of articular bearing surface of internal prosthetic right hip joint, sequela',
  'T84061A Wear of articular bearing surface of internal prosthetic left hip joint, initial encounter',
  'T84061D Wear of articular bearing surface of internal prosthetic left hip joint, subsequent encounter',
  'T84061S Wear of articular bearing surface of internal prosthetic left hip joint, sequela',
  'T84062A Wear of articular bearing surface of internal prosthetic right knee joint, initial encounter',
  'T84062D Wear of articular bearing surface of internal prosthetic right knee joint, subsequent encounter',
  'T84062S Wear of articular bearing surface of internal prosthetic right knee joint, sequela',
  'T84063A Wear of articular bearing surface of internal prosthetic left knee joint, initial encounter',
  'T84063D Wear of articular bearing surface of internal prosthetic left knee joint, subsequent encounter',
  'T84063S Wear of articular bearing surface of internal prosthetic left knee joint, sequela',
  'T84068A Wear of articular bearing surface of other internal prosthetic joint, initial encounter',
  'T84068D Wear of articular bearing surface of other internal prosthetic joint, subsequent encounter',
  'T84068S Wear of articular bearing surface of other internal prosthetic joint, sequela',
  'T84069A Wear of articular bearing surface of unspecified internal prosthetic joint, initial encounter',
  'T84069D Wear of articular bearing surface of unspecified internal prosthetic joint, subsequent',
  'encounter',
  'T84069S Wear of articular bearing surface of unspecified internal prosthetic joint, sequela',
  'T84090A Other mechanical complication of internal right hip prosthesis, initial encounter',
  'T84090D Other mechanical complication of internal right hip prosthesis, subsequent encounter',
  'T84090S Other mechanical complication of internal right hip prosthesis, sequela',
  'T84091A Other mechanical complication of internal left hip prosthesis, initial encounter',
  'T84091D Other mechanical complication of internal left hip prosthesis, subsequent encounter',
  'T84091S Other mechanical complication of internal left hip prosthesis, sequela',
  'T84092A Other mechanical complication of internal right knee prosthesis, initial encounter',
  'T84092D Other mechanical complication of internal right knee prosthesis, subsequent encounter',
  'T84092S Other mechanical complication of internal right knee prosthesis, sequela',
  'T84093A Other mechanical complication of internal left knee prosthesis, initial encounter',
  'T84093D Other mechanical complication of internal left knee prosthesis, subsequent encounter',
  'T84093S Other mechanical complication of internal left knee prosthesis, sequela',
  'T84098A Other mechanical complication of other internal joint prosthesis, initial encounter',
  'T84098D Other mechanical complication of other internal joint prosthesis, subsequent encounter',
  'T84098S Other mechanical complication of other internal joint prosthesis, sequela',
  'T84099A Other mechanical complication of unspecified internal joint prosthesis, initial encounter',
  'T84099D Other mechanical complication of unspecified internal joint prosthesis, subsequent encounter',
  'T84099S Other mechanical complication of unspecified internal joint prosthesis, sequela',
  'T84110A Breakdown (mechanical) of internal fixation device of right humerus, initial encounter',
  'T84110D Breakdown (mechanical) of internal fixation device of right humerus, subsequent encounter',
  'T84110S Breakdown (mechanical) of internal fixation device of right humerus, sequela',
  'T84111A Breakdown (mechanical) of internal fixation device of left humerus, initial encounter',
  'T84111D Breakdown (mechanical) of internal fixation device of left humerus, subsequent encounter',
  'T84111S Breakdown (mechanical) of internal fixation device of left humerus, sequela',
  'T84112A Breakdown (mechanical) of internal fixation device of bone of right forearm, initial encounter',
  'T84112D Breakdown (mechanical) of internal fixation device of bone of right forearm, subsequent',
  'encounter',
  'T84112S Breakdown (mechanical) of internal fixation device of bone of right forearm, sequela',
  'T84113A Breakdown (mechanical) of internal fixation device of bone of left forearm, initial encounter',
  'T84113D Breakdown (mechanical) of internal fixation device of bone of left forearm, subsequent',
  'encounter',
  'T84113S Breakdown (mechanical) of internal fixation device of bone of left forearm, sequela',
  'T84114A Breakdown (mechanical) of internal fixation device of right femur, initial encounter',
  'T84114D Breakdown (mechanical) of internal fixation device of right femur, subsequent encounter',
  'T84114S Breakdown (mechanical) of internal fixation device of right femur, sequela',
  'T84115A Breakdown (mechanical) of internal fixation device of left femur, initial encounter',
  'T84115D Breakdown (mechanical) of internal fixation device of left femur, subsequent encounter',
  'T84115S Breakdown (mechanical) of internal fixation device of left femur, sequela',
  'T84116A Breakdown (mechanical) of internal fixation device of bone of right lower leg, initial encounter',
  'T84116D Breakdown (mechanical) of internal fixation device of bone of right lower leg, subsequent',
  'encounter',
  'T84116S Breakdown (mechanical) of internal fixation device of bone of right lower leg, sequela',
  'T84117A Breakdown (mechanical) of internal fixation device of bone of left lower leg, initial encounter',
  'T84117D Breakdown (mechanical) of internal fixation device of bone of left lower leg, subsequent',
  'encounter',
  'T84117S Breakdown (mechanical) of internal fixation device of bone of left lower leg, sequela',
  'T84119A Breakdown (mechanical) of internal fixation device of unspecified bone of limb, initial',
  'encounter',
  'T84119D Breakdown (mechanical) of internal fixation device of unspecified bone of limb, subsequent',
  'encounter',
  'T84119S Breakdown (mechanical) of internal fixation device of unspecified bone of limb, sequela',
  'T84120A Displacement of internal fixation device of right humerus, initial encounter',
  'T84120D Displacement of internal fixation device of right humerus, subsequent encounter',
  'T84120S Displacement of internal fixation device of right humerus, sequela',
  'T84121A Displacement of internal fixation device of left humerus, initial encounter',
  'T84121D Displacement of internal fixation device of left humerus, subsequent encounter',
  'T84121S Displacement of internal fixation device of left humerus, sequela',
  'T84122A Displacement of internal fixation device of bone of right forearm, initial encounter',
  'T84122D Displacement of internal fixation device of bone of right forearm, subsequent encounter',
  'T84122S Displacement of internal fixation device of bone of right forearm, sequela',
  'T84123A Displacement of internal fixation device of bone of left forearm, initial encounter',
  'T84123D Displacement of internal fixation device of bone of left forearm, subsequent encounter',
  'T84123S Displacement of internal fixation device of bone of left forearm, sequela',
  'T84124A Displacement of internal fixation device of right femur, initial encounter',
  'T84124D Displacement of internal fixation device of right femur, subsequent encounter',
  'T84124S Displacement of internal fixation device of right femur, sequela',
  'T84125A Displacement of internal fixation device of left femur, initial encounter',
  'T84125D Displacement of internal fixation device of left femur, subsequent encounter',
  'T84125S Displacement of internal fixation device of left femur, sequela',
  'T84126A Displacement of internal fixation device of bone of right lower leg, initial encounter',
  'T84126D Displacement of internal fixation device of bone of right lower leg, subsequent encounter',
  'T84126S Displacement of internal fixation device of bone of right lower leg, sequela',
  'T84127A Displacement of internal fixation device of bone of left lower leg, initial encounter',
  'T84127D Displacement of internal fixation device of bone of left lower leg, subsequent encounter',
  'T84127S Displacement of internal fixation device of bone of left lower leg, sequela',
  'T84129A Displacement of internal fixation device of unspecified bone of limb, initial encounter',
  'T84129D Displacement of internal fixation device of unspecified bone of limb, subsequent encounter',
  'T84129S Displacement of internal fixation device of unspecified bone of limb, sequela',
  'T84190A Other mechanical complication of internal fixation device of right humerus, initial encounter',
  'T84190D Other mechanical complication of internal fixation device of right humerus, subsequent',
  'encounter',
  'T84190S Other mechanical complication of internal fixation device of right humerus, sequela',
  'T84191A Other mechanical complication of internal fixation device of left humerus, initial encounter',
  'T84191D Other mechanical complication of internal fixation device of left humerus, subsequent',
  'encounter',
  'T84191S Other mechanical complication of internal fixation device of left humerus, sequela',
  'T84192A Other mechanical complication of internal fixation device of bone of right forearm, initial',
  'encounter',
  'T84192D Other mechanical complication of internal fixation device of bone of right forearm, subsequent',
  'encounter',
  'T84192S Other mechanical complication of internal fixation device of bone of right forearm, sequela',
  'T84193A Other mechanical complication of internal fixation device of bone of left forearm, initial',
  'encounter',
  'T84193D Other mechanical complication of internal fixation device of bone of left forearm, subsequent',
  'encounter',
  'T84193S Other mechanical complication of internal fixation device of bone of left forearm, sequela',
  'T84194A Other mechanical complication of internal fixation device of right femur, initial encounter',
  'T84194D Other mechanical complication of internal fixation device of right femur, subsequent',
  'encounter',
  'T84194S Other mechanical complication of internal fixation device of right femur, sequela',
  'T84195A Other mechanical complication of internal fixation device of left femur, initial encounter',
  'T84195D Other mechanical complication of internal fixation device of left femur, subsequent encounter',
  'T84195S Other mechanical complication of internal fixation device of left femur, sequela',
  'T84196A Other mechanical complication of internal fixation device of bone of right lower leg, initial',
  'encounter',
  'T84196D Other mechanical complication of internal fixation device of bone of right lower leg,',
  'subsequent encounter',
  'T84196S Other mechanical complication of internal fixation device of bone of right lower leg, sequela',
  'T84197A Other mechanical complication of internal fixation device of bone of left lower leg, initial',
  'encounter',
  'T84197D Other mechanical complication of internal fixation device of bone of left lower leg, subsequent',
  'encounter',
  'T84197S Other mechanical complication of internal fixation device of bone of left lower leg, sequela',
  'T84199A Other mechanical complication of internal fixation device of unspecified bone of limb, initial',
  'encounter',
  'T84199D Other mechanical complication of internal fixation device of unspecified bone of limb,',
  'subsequent encounter',
  'T84199S Other mechanical complication of internal fixation device of unspecified bone of limb, sequela',
  'T84210A Breakdown (mechanical) of internal fixation device of bones of hand and fingers, initial',
  'encounter',
  'T84210D Breakdown (mechanical) of internal fixation device of bones of hand and fingers, subsequent',
  'encounter',
  'T84210S Breakdown (mechanical) of internal fixation device of bones of hand and fingers, sequela',
  'T84213A Breakdown (mechanical) of internal fixation device of bones of foot and toes, initial encounter',
  'T84213D Breakdown (mechanical) of internal fixation device of bones of foot and toes, subsequent',
  'encounter',
  'T84213S Breakdown (mechanical) of internal fixation device of bones of foot and toes, sequela',
  'T84216A Breakdown (mechanical) of internal fixation device of vertebrae, initial encounter',
  'T84216D Breakdown (mechanical) of internal fixation device of vertebrae, subsequent encounter',
  'T84216S Breakdown (mechanical) of internal fixation device of vertebrae, sequela',
  'T84218A Breakdown (mechanical) of internal fixation device of other bones, initial encounter',
  'T84218D Breakdown (mechanical) of internal fixation device of other bones, subsequent encounter',
  'T84218S Breakdown (mechanical) of internal fixation device of other bones, sequela',
  'T84220A Displacement of internal fixation device of bones of hand and fingers, initial encounter',
  'T84220D Displacement of internal fixation device of bones of hand and fingers, subsequent encounter',
  'T84220S Displacement of internal fixation device of bones of hand and fingers, sequela',
  'T84223A Displacement of internal fixation device of bones of foot and toes, initial encounter',
  'T84223D Displacement of internal fixation device of bones of foot and toes, subsequent encounter',
  'T84223S Displacement of internal fixation device of bones of foot and toes, sequela',
  'T84226A Displacement of internal fixation device of vertebrae, initial encounter',
  'T84226D Displacement of internal fixation device of vertebrae, subsequent encounter',
  'T84226S Displacement of internal fixation device of vertebrae, sequela',
  'T84228A Displacement of internal fixation device of other bones, initial encounter',
  'T84228D Displacement of internal fixation device of other bones, subsequent encounter',
  'T84228S Displacement of internal fixation device of other bones, sequela',
  'T84290A Other mechanical complication of internal fixation device of bones of hand and fingers, initial',
  'encounter',
  'T84290D Other mechanical complication of internal fixation device of bones of hand and fingers,',
  'subsequent encounter',
  'T84290S Other mechanical complication of internal fixation device of bones of hand and fingers, sequela',
  'T84293A Other mechanical complication of internal fixation device of bones of foot and toes, initial',
  'encounter',
  'T84293D Other mechanical complication of internal fixation device of bones of foot and toes,',
  'subsequent encounter',
  'T84293S Other mechanical complication of internal fixation device of bones of foot and toes, sequela',
  'T84296A Other mechanical complication of internal fixation device of vertebrae, initial encounter',
  'T84296D Other mechanical complication of internal fixation device of vertebrae, subsequent encounter',
  'T84296S Other mechanical complication of internal fixation device of vertebrae, sequela',
  'T84298A Other mechanical complication of internal fixation device of other bones, initial encounter',
  'T84298D Other mechanical complication of internal fixation device of other bones, subsequent',
  'encounter',
  'T84298S Other mechanical complication of internal fixation device of other bones, sequela',
  'T84310A Breakdown (mechanical) of electronic bone stimulator, initial encounter',
  'T84310D Breakdown (mechanical) of electronic bone stimulator, subsequent encounter',
  'T84310S Breakdown (mechanical) of electronic bone stimulator, sequela',
  'T84318A Breakdown (mechanical) of other bone devices, implants and grafts, initial encounter',
  'T84318D Breakdown (mechanical) of other bone devices, implants and grafts, subsequent encounter',
  'T84318S Breakdown (mechanical) of other bone devices, implants and grafts, sequela',
  'T84320A Displacement of electronic bone stimulator, initial encounter',
  'T84320D Displacement of electronic bone stimulator, subsequent encounter',
  'T84320S Displacement of electronic bone stimulator, sequela',
  'T84328A Displacement of other bone devices, implants and grafts, initial encounter',
  'T84328D Displacement of other bone devices, implants and grafts, subsequent encounter',
  'T84328S Displacement of other bone devices, implants and grafts, sequela',
  'T84390A Other mechanical complication of electronic bone stimulator, initial encounter',
  'T84390D Other mechanical complication of electronic bone stimulator, subsequent encounter',
  'T84390S Other mechanical complication of electronic bone stimulator, sequela',
  'T84398A Other mechanical complication of other bone devices, implants and grafts, initial encounter',
  'T84398D Other mechanical complication of other bone devices, implants and grafts, subsequent',
  'encounter',
  'T84398S Other mechanical complication of other bone devices, implants and grafts, sequela',
  'T84410A Breakdown (mechanical) of muscle and tendon graft, initial encounter',
  'T84410D Breakdown (mechanical) of muscle and tendon graft, subsequent encounter',
  'T84410S Breakdown (mechanical) of muscle and tendon graft, sequela',
  'T84418A Breakdown (mechanical) of other internal orthopedic devices, implants and grafts, initial',
  'encounter',
  'T84418D Breakdown (mechanical) of other internal orthopedic devices, implants and grafts, subsequent',
  'encounter',
  'T84418S Breakdown (mechanical) of other internal orthopedic devices, implants and grafts, sequela',
  'T84420A Displacement of muscle and tendon graft, initial encounter',
  'T84420D Displacement of muscle and tendon graft, subsequent encounter',
  'T84420S Displacement of muscle and tendon graft, sequela',
  'T84428A Displacement of other internal orthopedic devices, implants and grafts, initial encounter',
  'T84428D Displacement of other internal orthopedic devices, implants and grafts, subsequent encounter',
  'T84428S Displacement of other internal orthopedic devices, implants and grafts, sequela',
  'T84490A Other mechanical complication of muscle and tendon graft, initial encounter',
  'T84490D Other mechanical complication of muscle and tendon graft, subsequent encounter',
  'T84490S Other mechanical complication of muscle and tendon graft, sequela',
  'T84498A Other mechanical complication of other internal orthopedic devices, implants and grafts, initial',
  'encounter',
  'T84498D Other mechanical complication of other internal orthopedic devices, implants and grafts,',
  'subsequent encounter',
  'T84498S Other mechanical complication of other internal orthopedic devices, implants and grafts,',
  'sequela',
  'T8450XA Infection and inflammatory reaction due to unspecified internal joint prosthesis, initial',
  'encounter',
  'T8450XD Infection and inflammatory reaction due to unspecified internal joint prosthesis, subsequent',
  'encounter',
  'T8450XS Infection and inflammatory reaction due to unspecified internal joint prosthesis, sequela',
  'T8451XA Infection and inflammatory reaction due to internal right hip prosthesis, initial encounter',
  'T8451XD Infection and inflammatory reaction due to internal right hip prosthesis, subsequent encounter',
  'T8451XS Infection and inflammatory reaction due to internal right hip prosthesis, sequela',
  'T8452XA Infection and inflammatory reaction due to internal left hip prosthesis, initial encounter',
  'T8452XD Infection and inflammatory reaction due to internal left hip prosthesis, subsequent encounter',
  'T8452XS Infection and inflammatory reaction due to internal left hip prosthesis, sequela',
  'T8453XA Infection and inflammatory reaction due to internal right knee prosthesis, initial encounter',
  'T8453XD Infection and inflammatory reaction due to internal right knee prosthesis, subsequent',
  'encounter',
  'T8453XS Infection and inflammatory reaction due to internal right knee prosthesis, sequela',
  'T8454XA Infection and inflammatory reaction due to internal left knee prosthesis, initial encounter',
  'T8454XD Infection and inflammatory reaction due to internal left knee prosthesis, subsequent',
  'encounter',
  'T8454XS Infection and inflammatory reaction due to internal left knee prosthesis, sequela',
  'T8459XA Infection and inflammatory reaction due to other internal joint prosthesis, initial encounter',
  'T8459XD Infection and inflammatory reaction due to other internal joint prosthesis, subsequent',
  'encounter',
  'T8459XS Infection and inflammatory reaction due to other internal joint prosthesis, sequela',
  'T8460XA Infection and inflammatory reaction due to internal fixation device of unspecified site, initial',
  'encounter',
  'T8460XD Infection and inflammatory reaction due to internal fixation device of unspecified site,',
  'subsequent encounter',
  'T8460XS Infection and inflammatory reaction due to internal fixation device of unspecified site, sequela',
  'T84610A Infection and inflammatory reaction due to internal fixation device of right humerus, initial',
  'encounter',
  'T84610D Infection and inflammatory reaction due to internal fixation device of right humerus,',
  'subsequent encounter',
  'T84610S Infection and inflammatory reaction due to internal fixation device of right humerus, sequela',
  'T84611A Infection and inflammatory reaction due to internal fixation device of left humerus, initial',
  'encounter',
  'T84611D Infection and inflammatory reaction due to internal fixation device of left humerus,',
  'subsequent encounter',
  'T84611S Infection and inflammatory reaction due to internal fixation device of left humerus, sequela',
  'T84612A Infection and inflammatory reaction due to internal fixation device of right radius, initial',
  'encounter',
  'T84612D Infection and inflammatory reaction due to internal fixation device of right radius, subsequent',
  'encounter',
  'T84612S Infection and inflammatory reaction due to internal fixation device of right radius, sequela',
  'T84613A Infection and inflammatory reaction due to internal fixation device of left radius, initial',
  'encounter',
  'T84613D Infection and inflammatory reaction due to internal fixation device of left radius, subsequent',
  'encounter',
  'T84613S Infection and inflammatory reaction due to internal fixation device of left radius, sequela',
  'T84614A Infection and inflammatory reaction due to internal fixation device of right ulna, initial',
  'encounter',
  'T84614D Infection and inflammatory reaction due to internal fixation device of right ulna, subsequent',
  'encounter',
  'T84614S Infection and inflammatory reaction due to internal fixation device of right ulna, sequela',
  'T84615A Infection and inflammatory reaction due to internal fixation device of left ulna, initial',
  'encounter',
  'T84615D Infection and inflammatory reaction due to internal fixation device of left ulna, subsequent',
  'encounter',
  'T84615S Infection and inflammatory reaction due to internal fixation device of left ulna, sequela',
  'T84619A Infection and inflammatory reaction due to internal fixation device of unspecified bone of arm,',
  'initial encounter',
  'T84619D Infection and inflammatory reaction due to internal fixation device of unspecified bone of arm,',
  'subsequent encounter',
  'T84619S Infection and inflammatory reaction due to internal fixation device of unspecified bone of arm,',
  'sequela',
  'T84620A Infection and inflammatory reaction due to internal fixation device of right femur, initial',
  'encounter',
  'T84620D Infection and inflammatory reaction due to internal fixation device of right femur, subsequent',
  'encounter',
  'T84620S Infection and inflammatory reaction due to internal fixation device of right femur, sequela',
  'T84621A Infection and inflammatory reaction due to internal fixation device of left femur, initial',
  'encounter',
  'T84621D Infection and inflammatory reaction due to internal fixation device of left femur, subsequent',
  'encounter',
  'T84621S Infection and inflammatory reaction due to internal fixation device of left femur, sequela',
  'T84622A Infection and inflammatory reaction due to internal fixation device of right tibia, initial',
  'encounter',
  'T84622D Infection and inflammatory reaction due to internal fixation device of right tibia, subsequent',
  'encounter',
  'T84622S Infection and inflammatory reaction due to internal fixation device of right tibia, sequela',
  'T84623A Infection and inflammatory reaction due to internal fixation device of left tibia, initial',
  'encounter',
  'T84623D Infection and inflammatory reaction due to internal fixation device of left tibia, subsequent',
  'encounter',
  'T84623S Infection and inflammatory reaction due to internal fixation device of left tibia, sequela',
  'T84624A Infection and inflammatory reaction due to internal fixation device of right fibula, initial',
  'encounter',
  'T84624D Infection and inflammatory reaction due to internal fixation device of right fibula, subsequent',
  'encounter',
  'T84624S Infection and inflammatory reaction due to internal fixation device of right fibula, sequela',
  'T84625A Infection and inflammatory reaction due to internal fixation device of left fibula, initial',
  'encounter',
  'T84625D Infection and inflammatory reaction due to internal fixation device of left fibula, subsequent',
  'encounter',
  'T84625S Infection and inflammatory reaction due to internal fixation device of left fibula, sequela',
  'T84629A Infection and inflammatory reaction due to internal fixation device of unspecified bone of leg,',
  'initial encounter',
  'T84629D Infection and inflammatory reaction due to internal fixation device of unspecified bone of leg,',
  'subsequent encounter',
  'T84629S Infection and inflammatory reaction due to internal fixation device of unspecified bone of leg,',
  'sequela',
  'T8463XA Infection and inflammatory reaction due to internal fixation device of spine, initial encounter',
  'T8463XD Infection and inflammatory reaction due to internal fixation device of spine, subsequent',
  'encounter',
  'T8463XS Infection and inflammatory reaction due to internal fixation device of spine, sequela',
  'T8469XA Infection and inflammatory reaction due to internal fixation device of other site, initial',
  'encounter',
  'T8469XD Infection and inflammatory reaction due to internal fixation device of other site, subsequent',
  'encounter',
  'T8469XS Infection and inflammatory reaction due to internal fixation device of other site, sequela',
  'T847XXA Infection and inflammatory reaction due to other internal orthopedic prosthetic devices,',
  'implants and grafts, initial encounter',
  'T847XXD Infection and inflammatory reaction due to other internal orthopedic prosthetic devices,',
  'implants and grafts, subsequent encounter',
  'T847XXS Infection and inflammatory reaction due to other internal orthopedic prosthetic devices,',
  'implants and grafts, sequela',
  'T8481XA Embolism due to internal orthopedic prosthetic devices, implants and grafts, initial encounter',
  'T8481XD Embolism due to internal orthopedic prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T8481XS Embolism due to internal orthopedic prosthetic devices, implants and grafts, sequela',
  'T8482XA Fibrosis due to internal orthopedic prosthetic devices, implants and grafts, initial encounter',
  'T8482XD Fibrosis due to internal orthopedic prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T8482XS Fibrosis due to internal orthopedic prosthetic devices, implants and grafts, sequela',
  'T8483XA Hemorrhage due to internal orthopedic prosthetic devices, implants and grafts, initial',
  'encounter',
  'T8483XD Hemorrhage due to internal orthopedic prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T8483XS Hemorrhage due to internal orthopedic prosthetic devices, implants and grafts, sequela',
  'T8484XA Pain due to internal orthopedic prosthetic devices, implants and grafts, initial encounter',
  'T8484XD Pain due to internal orthopedic prosthetic devices, implants and grafts, subsequent encounter',
  'T8484XS Pain due to internal orthopedic prosthetic devices, implants and grafts, sequela',
  'T8485XA Stenosis due to internal orthopedic prosthetic devices, implants and grafts, initial encounter',
  'T8485XD Stenosis due to internal orthopedic prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T8485XS Stenosis due to internal orthopedic prosthetic devices, implants and grafts, sequela',
  'T8486XA Thrombosis due to internal orthopedic prosthetic devices, implants and grafts, initial',
  'encounter',
  'T8486XD Thrombosis due to internal orthopedic prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T8486XS Thrombosis due to internal orthopedic prosthetic devices, implants and grafts, sequela',
  'T8489XA Other specified complication of internal orthopedic prosthetic devices, implants and grafts,',
  'initial encounter',
  'T8489XD Other specified complication of internal orthopedic prosthetic devices, implants and grafts,',
  'subsequent encounter',
  'T8489XS Other specified complication of internal orthopedic prosthetic devices, implants and grafts,',
  'sequela',
  'T849XXA Unspecified complication of internal orthopedic prosthetic device, implant and graft, initial',
  'encounter',
  'T849XXD Unspecified complication of internal orthopedic prosthetic device, implant and graft,',
  'subsequent encounter',
  'T849XXS Unspecified complication of internal orthopedic prosthetic device, implant and graft, sequela',
  'T8501XA Breakdown (mechanical) of ventricular intracranial (communicating) shunt, initial encounter',
  'T8501XD Breakdown (mechanical) of ventricular intracranial (communicating) shunt, subsequent',
  'encounter',
  'T8501XS Breakdown (mechanical) of ventricular intracranial (communicating) shunt, sequela',
  'T8502XA Displacement of ventricular intracranial (communicating) shunt, initial encounter',
  'T8502XD Displacement of ventricular intracranial (communicating) shunt, subsequent encounter',
  'T8502XS Displacement of ventricular intracranial (communicating) shunt, sequela',
  'T8503XA Leakage of ventricular intracranial (communicating) shunt, initial encounter',
  'T8503XD Leakage of ventricular intracranial (communicating) shunt, subsequent encounter',
  'T8503XS Leakage of ventricular intracranial (communicating) shunt, sequela',
  'T8509XA Other mechanical complication of ventricular intracranial (communicating) shunt, initial',
  'encounter',
  'T8509XD Other mechanical complication of ventricular intracranial (communicating) shunt, subsequent',
  'encounter',
  'T8509XS Other mechanical complication of ventricular intracranial (communicating) shunt, sequela',
  'T85110A Breakdown (mechanical) of implanted electronic neurostimulator of brain electrode (lead),',
  'initial encounter',
  'T85110D Breakdown (mechanical) of implanted electronic neurostimulator of brain electrode (lead),',
  'subsequent encounter',
  'T85110S Breakdown (mechanical) of implanted electronic neurostimulator of brain electrode (lead),',
  'sequela',
  'T85111A Breakdown (mechanical) of implanted electronic neurostimulator of peripheral nerve',
  'electrode (lead), initial encounter',
  'T85111D Breakdown (mechanical) of implanted electronic neurostimulator of peripheral nerve',
  'electrode (lead), subsequent encounter',
  'T85111S Breakdown (mechanical) of implanted electronic neurostimulator of peripheral nerve electrode',
  '(lead), sequela',
  'T85112A Breakdown (mechanical) of implanted electronic neurostimulator of spinal cord electrode',
  '(lead), initial encounter',
  'T85112D Breakdown (mechanical) of implanted electronic neurostimulator of spinal cord electrode',
  '(lead), subsequent encounter',
  'T85112S Breakdown (mechanical) of implanted electronic neurostimulator of spinal cord electrode',
  '(lead), sequela',
  'T85113A Breakdown (mechanical) of implanted electronic neurostimulator, generator, initial encounter',
  'T85113D Breakdown (mechanical) of implanted electronic neurostimulator, generator, subsequent',
  'encounter',
  'T85113S Breakdown (mechanical) of implanted electronic neurostimulator, generator, sequela',
  'T85118A Breakdown (mechanical) of other implanted electronic stimulator of nervous system, initial',
  'encounter',
  'T85118D Breakdown (mechanical) of other implanted electronic stimulator of nervous system,',
  'subsequent encounter',
  'T85118S Breakdown (mechanical) of other implanted electronic stimulator of nervous system, sequela',
  'T85120A Displacement of implanted electronic neurostimulator of brain electrode (lead), initial',
  'encounter',
  'T85120D Displacement of implanted electronic neurostimulator of brain electrode (lead), subsequent',
  'encounter',
  'T85120S Displacement of implanted electronic neurostimulator of brain electrode (lead), sequela',
  'T85121A Displacement of implanted electronic neurostimulator of peripheral nerve electrode (lead),',
  'initial encounter',
  'T85121D Displacement of implanted electronic neurostimulator of peripheral nerve electrode (lead),',
  'subsequent encounter',
  'T85121S Displacement of implanted electronic neurostimulator of peripheral nerve electrode (lead),',
  'sequela',
  'T85122A Displacement of implanted electronic neurostimulator of spinal cord electrode (lead), initial',
  'encounter',
  'T85122D Displacement of implanted electronic neurostimulator of spinal cord electrode (lead),',
  'subsequent encounter',
  'T85122S Displacement of implanted electronic neurostimulator of spinal cord electrode (lead), sequela',
  'T85123A Displacement of implanted electronic neurostimulator, generator, initial encounter',
  'T85123D Displacement of implanted electronic neurostimulator, generator, subsequent encounter',
  'T85123S Displacement of implanted electronic neurostimulator, generator, sequela',
  'T85128A Displacement of other implanted electronic stimulator of nervous system, initial encounter',
  'T85128D Displacement of other implanted electronic stimulator of nervous system, subsequent',
  'encounter',
  'T85128S Displacement of other implanted electronic stimulator of nervous system, sequela',
  'T85190A Other mechanical complication of implanted electronic neurostimulator of brain electrode',
  '(lead), initial encounter',
  'T85190D Other mechanical complication of implanted electronic neurostimulator of brain electrode',
  '(lead), subsequent encounter',
  'T85190S Other mechanical complication of implanted electronic neurostimulator of brain electrode',
  '(lead), sequela',
  'T85191A Other mechanical complication of implanted electronic neurostimulator of peripheral nerve',
  'electrode (lead), initial encounter',
  'T85191D Other mechanical complication of implanted electronic neurostimulator of peripheral nerve',
  'electrode (lead), subsequent encounter',
  'T85191S Other mechanical complication of implanted electronic neurostimulator of peripheral nerve',
  'electrode (lead), sequela',
  'T85192A Other mechanical complication of implanted electronic neurostimulator of spinal cord',
  'electrode (lead), initial encounter',
  'T85192D Other mechanical complication of implanted electronic neurostimulator of spinal cord',
  'electrode (lead), subsequent encounter',
  'T85192S Other mechanical complication of implanted electronic neurostimulator of spinal cord',
  'electrode (lead), sequela',
  'T85193A Other mechanical complication of implanted electronic neurostimulator, generator, initial',
  'encounter',
  'T85193D Other mechanical complication of implanted electronic neurostimulator, generator,',
  'subsequent encounter',
  'T85193S Other mechanical complication of implanted electronic neurostimulator, generator, sequela',
  'T85199A Other mechanical complication of other implanted electronic stimulator of nervous system,',
  'initial encounter',
  'T85199D Other mechanical complication of other implanted electronic stimulator of nervous system,',
  'subsequent encounter',
  'T85199S Other mechanical complication of other implanted electronic stimulator of nervous system,',
  'sequela',
  'T8521XA Breakdown (mechanical) of intraocular lens, initial encounter',
  'T8521XD Breakdown (mechanical) of intraocular lens, subsequent encounter',
  'T8521XS Breakdown (mechanical) of intraocular lens, sequela',
  'T8522XA Displacement of intraocular lens, initial encounter',
  'T8522XD Displacement of intraocular lens, subsequent encounter',
  'T8522XS Displacement of intraocular lens, sequela',
  'T8529XA Other mechanical complication of intraocular lens, initial encounter',
  'T8529XD Other mechanical complication of intraocular lens, subsequent encounter',
  'T8529XS Other mechanical complication of intraocular lens, sequela',
  'T85310A Breakdown (mechanical) of prosthetic orbit of right eye, initial encounter',
  'T85310D Breakdown (mechanical) of prosthetic orbit of right eye, subsequent encounter',
  'T85310S Breakdown (mechanical) of prosthetic orbit of right eye, sequela',
  'T85311A Breakdown (mechanical) of prosthetic orbit of left eye, initial encounter',
  'T85311D Breakdown (mechanical) of prosthetic orbit of left eye, subsequent encounter',
  'T85311S Breakdown (mechanical) of prosthetic orbit of left eye, sequela',
  'T85318A Breakdown (mechanical) of other ocular prosthetic devices, implants and grafts, initial',
  'encounter',
  'T85318D Breakdown (mechanical) of other ocular prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85318S Breakdown (mechanical) of other ocular prosthetic devices, implants and grafts, sequela',
  'T85320A Displacement of prosthetic orbit of right eye, initial encounter',
  'T85320D Displacement of prosthetic orbit of right eye, subsequent encounter',
  'T85320S Displacement of prosthetic orbit of right eye, sequela',
  'T85321A Displacement of prosthetic orbit of left eye, initial encounter',
  'T85321D Displacement of prosthetic orbit of left eye, subsequent encounter',
  'T85321S Displacement of prosthetic orbit of left eye, sequela',
  'T85328A Displacement of other ocular prosthetic devices, implants and grafts, initial encounter',
  'T85328D Displacement of other ocular prosthetic devices, implants and grafts, subsequent encounter',
  'T85328S Displacement of other ocular prosthetic devices, implants and grafts, sequela',
  'T85390A Other mechanical complication of prosthetic orbit of right eye, initial encounter',
  'T85390D Other mechanical complication of prosthetic orbit of right eye, subsequent encounter',
  'T85390S Other mechanical complication of prosthetic orbit of right eye, sequela',
  'T85391A Other mechanical complication of prosthetic orbit of left eye, initial encounter',
  'T85391D Other mechanical complication of prosthetic orbit of left eye, subsequent encounter',
  'T85391S Other mechanical complication of prosthetic orbit of left eye, sequela',
  'T85398A Other mechanical complication of other ocular prosthetic devices, implants and grafts, initial',
  'encounter',
  'T85398D Other mechanical complication of other ocular prosthetic devices, implants and grafts,',
  'subsequent encounter',
  'T85398S Other mechanical complication of other ocular prosthetic devices, implants and grafts, sequela',
  'T8541XA Breakdown (mechanical) of breast prosthesis and implant, initial encounter',
  'T8541XD Breakdown (mechanical) of breast prosthesis and implant, subsequent encounter',
  'T8541XS Breakdown (mechanical) of breast prosthesis and implant, sequela',
  'T8542XA Displacement of breast prosthesis and implant, initial encounter',
  'T8542XD Displacement of breast prosthesis and implant, subsequent encounter',
  'T8542XS Displacement of breast prosthesis and implant, sequela',
  'T8543XA Leakage of breast prosthesis and implant, initial encounter',
  'T8543XD Leakage of breast prosthesis and implant, subsequent encounter',
  'T8543XS Leakage of breast prosthesis and implant, sequela',
  'T8544XA Capsular contracture of breast implant, initial encounter',
  'T8544XD Capsular contracture of breast implant, subsequent encounter',
  'T8544XS Capsular contracture of breast implant, sequela',
  'T8549XA Other mechanical complication of breast prosthesis and implant, initial encounter',
  'T8549XD Other mechanical complication of breast prosthesis and implant, subsequent encounter',
  'T8549XS Other mechanical complication of breast prosthesis and implant, sequela',
  'T85510A Breakdown (mechanical) of bile duct prosthesis, initial encounter',
  'T85510D Breakdown (mechanical) of bile duct prosthesis, subsequent encounter',
  'T85510S Breakdown (mechanical) of bile duct prosthesis, sequela',
  'T85511A Breakdown (mechanical) of esophageal anti-reflux device, initial encounter',
  'T85511D Breakdown (mechanical) of esophageal anti-reflux device, subsequent encounter',
  'T85511S Breakdown (mechanical) of esophageal anti-reflux device, sequela',
  'T85518A Breakdown (mechanical) of other gastrointestinal prosthetic devices, implants and grafts,',
  'initial encounter',
  'T85518D Breakdown (mechanical) of other gastrointestinal prosthetic devices, implants and grafts,',
  'subsequent encounter',
  'T85518S Breakdown (mechanical) of other gastrointestinal prosthetic devices, implants and grafts,',
  'sequela',
  'T85520A Displacement of bile duct prosthesis, initial encounter',
  'T85520D Displacement of bile duct prosthesis, subsequent encounter',
  'T85520S Displacement of bile duct prosthesis, sequela',
  'T85521A Displacement of esophageal anti-reflux device, initial encounter',
  'T85521D Displacement of esophageal anti-reflux device, subsequent encounter',
  'T85521S Displacement of esophageal anti-reflux device, sequela',
  'T85528A Displacement of other gastrointestinal prosthetic devices, implants and grafts, initial encounter',
  'T85528D Displacement of other gastrointestinal prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85528S Displacement of other gastrointestinal prosthetic devices, implants and grafts, sequela',
  'T85590A Other mechanical complication of bile duct prosthesis, initial encounter',
  'T85590D Other mechanical complication of bile duct prosthesis, subsequent encounter',
  'T85590S Other mechanical complication of bile duct prosthesis, sequela',
  'T85591A Other mechanical complication of esophageal anti-reflux device, initial encounter',
  'T85591D Other mechanical complication of esophageal anti-reflux device, subsequent encounter',
  'T85591S Other mechanical complication of esophageal anti-reflux device, sequela',
  'T85598A Other mechanical complication of other gastrointestinal prosthetic devices, implants and',
  'grafts, initial encounter',
  'T85598D Other mechanical complication of other gastrointestinal prosthetic devices, implants and',
  'grafts, subsequent encounter',
  'T85598S Other mechanical complication of other gastrointestinal prosthetic devices, implants and',
  'grafts, sequela',
  'T85610A Breakdown (mechanical) of cranial or spinal infusion catheter, initial encounter',
  'T85610D Breakdown (mechanical) of cranial or spinal infusion catheter, subsequent encounter',
  'T85610S Breakdown (mechanical) of cranial or spinal infusion catheter, sequela',
  'T85611A Breakdown (mechanical) of intraperitoneal dialysis catheter, initial encounter',
  'T85611D Breakdown (mechanical) of intraperitoneal dialysis catheter, subsequent encounter',
  'T85611S Breakdown (mechanical) of intraperitoneal dialysis catheter, sequela',
  'T85612A Breakdown (mechanical) of permanent sutures, initial encounter',
  'T85612D Breakdown (mechanical) of permanent sutures, subsequent encounter',
  'T85612S Breakdown (mechanical) of permanent sutures, sequela',
  'T85613A Breakdown (mechanical) of artificial skin graft and decellularized allodermis, initial encounter',
  'T85613D Breakdown (mechanical) of artificial skin graft and decellularized allodermis, subsequent',
  'encounter',
  'T85613S Breakdown (mechanical) of artificial skin graft and decellularized allodermis, sequela',
  'T85614A Breakdown (mechanical) of insulin pump, initial encounter',
  'T85614D Breakdown (mechanical) of insulin pump, subsequent encounter',
  'T85614S Breakdown (mechanical) of insulin pump, sequela',
  'T85615A Breakdown (mechanical) of other nervous system device, implant or graft, initial encounter',
  'T85615D Breakdown (mechanical) of other nervous system device, implant or graft, subsequent',
  'encounter',
  'T85615S Breakdown (mechanical) of other nervous system device, implant or graft, sequela',
  'T85618A Breakdown (mechanical) of other specified internal prosthetic devices, implants and grafts,',
  'initial encounter',
  'T85618D Breakdown (mechanical) of other specified internal prosthetic devices, implants and grafts,',
  'subsequent encounter',
  'T85618S Breakdown (mechanical) of other specified internal prosthetic devices, implants and grafts,',
  'sequela',
  'T85620A Displacement of cranial or spinal infusion catheter, initial encounter',
  'T85620D Displacement of cranial or spinal infusion catheter, subsequent encounter',
  'T85620S Displacement of cranial or spinal infusion catheter, sequela',
  'T85621A Displacement of intraperitoneal dialysis catheter, initial encounter',
  'T85621D Displacement of intraperitoneal dialysis catheter, subsequent encounter',
  'T85621S Displacement of intraperitoneal dialysis catheter, sequela',
  'T85622A Displacement of permanent sutures, initial encounter',
  'T85622D Displacement of permanent sutures, subsequent encounter',
  'T85622S Displacement of permanent sutures, sequela',
  'T85623A Displacement of artificial skin graft and decellularized allodermis, initial encounter',
  'T85623D Displacement of artificial skin graft and decellularized allodermis, subsequent encounter',
  'T85623S Displacement of artificial skin graft and decellularized allodermis, sequela',
  'T85624A Displacement of insulin pump, initial encounter',
  'T85624D Displacement of insulin pump, subsequent encounter',
  'T85624S Displacement of insulin pump, sequela',
  'T85625A Displacement of other nervous system device, implant or graft, initial encounter',
  'T85625D Displacement of other nervous system device, implant or graft, subsequent encounter',
  'T85625S Displacement of other nervous system device, implant or graft, sequela',
  'T85628A Displacement of other specified internal prosthetic devices, implants and grafts, initial',
  'encounter',
  'T85628D Displacement of other specified internal prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85628S Displacement of other specified internal prosthetic devices, implants and grafts, sequela',
  'T85630A Leakage of cranial or spinal infusion catheter, initial encounter',
  'T85630D Leakage of cranial or spinal infusion catheter, subsequent encounter',
  'T85630S Leakage of cranial or spinal infusion catheter, sequela',
  'T85631A Leakage of intraperitoneal dialysis catheter, initial encounter',
  'T85631D Leakage of intraperitoneal dialysis catheter, subsequent encounter',
  'T85631S Leakage of intraperitoneal dialysis catheter, sequela',
  'T85633A Leakage of insulin pump, initial encounter',
  'T85633D Leakage of insulin pump, subsequent encounter',
  'T85633S Leakage of insulin pump, sequela',
  'T85635A Leakage of other nervous system device, implant or graft, initial encounter',
  'T85635D Leakage of other nervous system device, implant or graft, subsequent encounter',
  'T85635S Leakage of other nervous system device, implant or graft, sequela',
  'T85638A Leakage of other specified internal prosthetic devices, implants and grafts, initial encounter',
  'T85638D Leakage of other specified internal prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85638S Leakage of other specified internal prosthetic devices, implants and grafts, sequela',
  'T85690A Other mechanical complication of cranial or spinal infusion catheter, initial encounter',
  'T85690D Other mechanical complication of cranial or spinal infusion catheter, subsequent encounter',
  'T85690S Other mechanical complication of cranial or spinal infusion catheter, sequela',
  'T85691A Other mechanical complication of intraperitoneal dialysis catheter, initial encounter',
  'T85691D Other mechanical complication of intraperitoneal dialysis catheter, subsequent encounter',
  'T85691S Other mechanical complication of intraperitoneal dialysis catheter, sequela',
  'T85692A Other mechanical complication of permanent sutures, initial encounter',
  'T85692D Other mechanical complication of permanent sutures, subsequent encounter',
  'T85692S Other mechanical complication of permanent sutures, sequela',
  'T85693A Other mechanical complication of artificial skin graft and decellularized allodermis, initial',
  'encounter',
  'T85693D Other mechanical complication of artificial skin graft and decellularized allodermis, subsequent',
  'encounter',
  'T85693S Other mechanical complication of artificial skin graft and decellularized allodermis, sequela',
  'T85694A Other mechanical complication of insulin pump, initial encounter',
  'T85694D Other mechanical complication of insulin pump, subsequent encounter',
  'T85694S Other mechanical complication of insulin pump, sequela',
  'T85695A Other mechanical complication of other nervous system device, implant or graft, initial',
  'encounter',
  'T85695D Other mechanical complication of other nervous system device, implant or graft, subsequent',
  'encounter',
  'T85695S Other mechanical complication of other nervous system device, implant or graft, sequela',
  'T85698A Other mechanical complication of other specified internal prosthetic devices, implants and',
  'grafts, initial encounter',
  'T85698D Other mechanical complication of other specified internal prosthetic devices, implants and',
  'grafts, subsequent encounter',
  'T85698S Other mechanical complication of other specified internal prosthetic devices, implants and',
  'grafts, sequela',
  'T8571XA Infection and inflammatory reaction due to peritoneal dialysis catheter, initial encounter',
  'T8571XD Infection and inflammatory reaction due to peritoneal dialysis catheter, subsequent encounter',
  'T8571XS Infection and inflammatory reaction due to peritoneal dialysis catheter, sequela',
  'T8572XA Infection and inflammatory reaction due to insulin pump, initial encounter',
  'T8572XD Infection and inflammatory reaction due to insulin pump, subsequent encounter',
  'T8572XS Infection and inflammatory reaction due to insulin pump, sequela',
  'T85730A Infection and inflammatory reaction due to ventricular intracranial (communicating) shunt,',
  'initial encounter',
  'T85730D Infection and inflammatory reaction due to ventricular intracranial (communicating) shunt,',
  'subsequent encounter',
  'T85730S Infection and inflammatory reaction due to ventricular intracranial (communicating) shunt,',
  'sequela',
  'T85731A Infection and inflammatory reaction due to implanted electronic neurostimulator of brain,',
  'electrode (lead), initial encounter',
  'T85731D Infection and inflammatory reaction due to implanted electronic neurostimulator of brain,',
  'electrode (lead), subsequent encounter',
  'T85731S Infection and inflammatory reaction due to implanted electronic neurostimulator of brain,',
  'electrode (lead), sequela',
  'T85732A Infection and inflammatory reaction due to implanted electronic neurostimulator of peripheral',
  'nerve, electrode (lead), initial encounter',
  'T85732D Infection and inflammatory reaction due to implanted electronic neurostimulator of peripheral',
  'nerve, electrode (lead), subsequent encounter',
  'T85732S Infection and inflammatory reaction due to implanted electronic neurostimulator of peripheral',
  'nerve, electrode (lead), sequela',
  'T85733A Infection and inflammatory reaction due to implanted electronic neurostimulator of spinal',
  'cord, electrode (lead), initial encounter',
  'T85733D Infection and inflammatory reaction due to implanted electronic neurostimulator of spinal',
  'cord, electrode (lead), subsequent encounter',
  'T85733S Infection and inflammatory reaction due to implanted electronic neurostimulator of spinal',
  'cord, electrode (lead), sequela',
  'T85734A Infection and inflammatory reaction due to implanted electronic neurostimulator, generator,',
  'initial encounter',
  'T85734D Infection and inflammatory reaction due to implanted electronic neurostimulator, generator,',
  'subsequent encounter',
  'T85734S Infection and inflammatory reaction due to implanted electronic neurostimulator, generator,',
  'sequela',
  'T85735A Infection and inflammatory reaction due to cranial or spinal infusion catheter, initial encounter',
  'T85735D Infection and inflammatory reaction due to cranial or spinal infusion catheter, subsequent',
  'encounter',
  'T85735S Infection and inflammatory reaction due to cranial or spinal infusion catheter, sequela',
  'T85738A Infection and inflammatory reaction due to other nervous system device, implant or graft,',
  'initial encounter',
  'T85738D Infection and inflammatory reaction due to other nervous system device, implant or graft,',
  'subsequent encounter',
  'T85738S Infection and inflammatory reaction due to other nervous system device, implant or graft,',
  'sequela',
  'T8579XA Infection and inflammatory reaction due to other internal prosthetic devices, implants and',
  'grafts, initial encounter',
  'T8579XD Infection and inflammatory reaction due to other internal prosthetic devices, implants and',
  'grafts, subsequent encounter',
  'T8579XS Infection and inflammatory reaction due to other internal prosthetic devices, implants and',
  'grafts, sequela',
  'T85810A Embolism due to nervous system prosthetic devices, implants and grafts, initial encounter',
  'T85810D Embolism due to nervous system prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85810S Embolism due to nervous system prosthetic devices, implants and grafts, sequela',
  'T85818A Embolism due to other internal prosthetic devices, implants and grafts, initial encounter',
  'T85818D Embolism due to other internal prosthetic devices, implants and grafts, subsequent encounter',
  'T85818S Embolism due to other internal prosthetic devices, implants and grafts, sequela',
  'T85820A Fibrosis due to nervous system prosthetic devices, implants and grafts, initial encounter',
  'T85820D Fibrosis due to nervous system prosthetic devices, implants and grafts, subsequent encounter',
  'T85820S Fibrosis due to nervous system prosthetic devices, implants and grafts, sequela',
  'T85828A Fibrosis due to other internal prosthetic devices, implants and grafts, initial encounter',
  'T85828D Fibrosis due to other internal prosthetic devices, implants and grafts, subsequent encounter',
  'T85828S Fibrosis due to other internal prosthetic devices, implants and grafts, sequela',
  'T85830A Hemorrhage due to nervous system prosthetic devices, implants and grafts, initial encounter',
  'T85830D Hemorrhage due to nervous system prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85830S Hemorrhage due to nervous system prosthetic devices, implants and grafts, sequela',
  'T85838A Hemorrhage due to other internal prosthetic devices, implants and grafts, initial encounter',
  'T85838D Hemorrhage due to other internal prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85838S Hemorrhage due to other internal prosthetic devices, implants and grafts, sequela',
  'T85840A Pain due to nervous system prosthetic devices, implants and grafts, initial encounter',
  'T85840D Pain due to nervous system prosthetic devices, implants and grafts, subsequent encounter',
  'T85840S Pain due to nervous system prosthetic devices, implants and grafts, sequela',
  'T85848A Pain due to other internal prosthetic devices, implants and grafts, initial encounter',
  'T85848D Pain due to other internal prosthetic devices, implants and grafts, subsequent encounter',
  'T85848S Pain due to other internal prosthetic devices, implants and grafts, sequela',
  'T85850A Stenosis due to nervous system prosthetic devices, implants and grafts, initial encounter',
  'T85850D Stenosis due to nervous system prosthetic devices, implants and grafts, subsequent encounter',
  'T85850S Stenosis due to nervous system prosthetic devices, implants and grafts, sequela',
  'T85858A Stenosis due to other internal prosthetic devices, implants and grafts, initial encounter',
  'T85858D Stenosis due to other internal prosthetic devices, implants and grafts, subsequent encounter',
  'T85858S Stenosis due to other internal prosthetic devices, implants and grafts, sequela',
  'T85860A Thrombosis due to nervous system prosthetic devices, implants and grafts, initial encounter',
  'T85860D Thrombosis due to nervous system prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85860S Thrombosis due to nervous system prosthetic devices, implants and grafts, sequela',
  'T85868A Thrombosis due to other internal prosthetic devices, implants and grafts, initial encounter',
  'T85868D Thrombosis due to other internal prosthetic devices, implants and grafts, subsequent',
  'encounter',
  'T85868S Thrombosis due to other internal prosthetic devices, implants and grafts, sequela',
  'T85890A Other specified complication of nervous system prosthetic devices, implants and grafts, initial',
  'encounter',
  'T85890D Other specified complication of nervous system prosthetic devices, implants and grafts,',
  'subsequent encounter',
  'T85890S Other specified complication of nervous system prosthetic devices, implants and grafts,',
  'sequela',
  'T85898A Other specified complication of other internal prosthetic devices, implants and grafts, initial',
  'encounter',
  'T85898D Other specified complication of other internal prosthetic devices, implants and grafts,',
  'subsequent encounter',
  'T85898S Other specified complication of other internal prosthetic devices, implants and grafts, sequela',
  'T859XXA Unspecified complication of internal prosthetic device, implant and graft, initial encounter',
  'T859XXD Unspecified complication of internal prosthetic device, implant and graft, subsequent',
  'encounter',
  'T859XXS Unspecified complication of internal prosthetic device, implant and graft, sequela',
  'T8600 Unspecified complication of bone marrow transplant',
  'T8601 Bone marrow transplant rejection',
  'T8602 Bone marrow transplant failure',
  'T8603 Bone marrow transplant infection',
  'T8609 Other complications of bone marrow transplant',
  'T8610 Unspecified complication of kidney transplant',
  'T8611 Kidney transplant rejection',
  'T8612 Kidney transplant failure',
  'T8613 Kidney transplant infection',
  'T8619 Other complication of kidney transplant',
  'T8620 Unspecified complication of heart transplant',
  'T8621 Heart transplant rejection',
  'T8622 Heart transplant failure',
  'T8623 Heart transplant infection',
  'T86290 Cardiac allograft vasculopathy',
  'T86298 Other complications of heart transplant',
  'T8630 Unspecified complication of heart-lung transplant',
  'T8631 Heart-lung transplant rejection',
  'T8632 Heart-lung transplant failure',
  'T8633 Heart-lung transplant infection',
  'T8639 Other complications of heart-lung transplant',
  'T8640 Unspecified complication of liver transplant',
  'T8641 Liver transplant rejection',
  'T8642 Liver transplant failure',
  'T8643 Liver transplant infection',
  'T8649 Other complications of liver transplant',
  'T865 Complications of stem cell transplant',
  'T86810 Lung transplant rejection',
  'T86811 Lung transplant failure',
  'T86812 Lung transplant infection',
  'T86818 Other complications of lung transplant',
  'T86819 Unspecified complication of lung transplant',
  'T86820 Skin graft (allograft) rejection',
  'T86821 Skin graft (allograft) (autograft) failure',
  'T86822 Skin graft (allograft) (autograft) infection',
  'T86828 Other complications of skin graft (allograft) (autograft)',
  'T86829 Unspecified complication of skin graft (allograft) (autograft)',
  'T86830 Bone graft rejection',
  'T86831 Bone graft failure',
  'T86832 Bone graft infection',
  'T86838 Other complications of bone graft',
  'T86839 Unspecified complication of bone graft',
  'T86840 Corneal transplant rejection',
  'T86841 Corneal transplant failure',
  'T86842 Corneal transplant infection',
  'T86848 Other complications of corneal transplant',
  'T86849 Unspecified complication of corneal transplant',
  'T86850 Intestine transplant rejection',
  'T86851 Intestine transplant failure',
  'T86852 Intestine transplant infection',
  'T86858 Other complications of intestine transplant',
  'T86859 Unspecified complication of intestine transplant',
  'T86890 Other transplanted tissue rejection',
  'T86891 Other transplanted tissue failure',
  'T86892 Other transplanted tissue infection',
  'T86898 Other complications of other transplanted tissue',
  'T86899 Unspecified complication of other transplanted tissue',
  'T8690 Unspecified complication of unspecified transplanted organ and tissue',
  'T8691 Unspecified transplanted organ and tissue rejection',
  'T8692 Unspecified transplanted organ and tissue failure',
  'T8693 Unspecified transplanted organ and tissue infection',
  'T8699 Other complications of unspecified transplanted organ and tissue',
  'T870X1 Complications of reattached (part of) right upper extremity',
  'T870X2 Complications of reattached (part of) left upper extremity',
  'T870X9 Complications of reattached (part of) unspecified upper extremity',
  'T871X1 Complications of reattached (part of) right lower extremity',
  'T871X2 Complications of reattached (part of) left lower extremity',
  'T871X9 Complications of reattached (part of) unspecified lower extremity',
  'T872 Complications of other reattached body part',
  'T8730 Neuroma of amputation stump, unspecified extremity',
  'T8731 Neuroma of amputation stump, right upper extremity',
  'T8732 Neuroma of amputation stump, left upper extremity',
  'T8733 Neuroma of amputation stump, right lower extremity',
  'T8734 Neuroma of amputation stump, left lower extremity',
  'T8740 Infection of amputation stump, unspecified extremity',
  'T8741 Infection of amputation stump, right upper extremity',
  'T8742 Infection of amputation stump, left upper extremity',
  'T8743 Infection of amputation stump, right lower extremity',
  'T8744 Infection of amputation stump, left lower extremity',
  'T8750 Necrosis of amputation stump, unspecified extremity',
  'T8751 Necrosis of amputation stump, right upper extremity',
  'T8752 Necrosis of amputation stump, left upper extremity',
  'T8753 Necrosis of amputation stump, right lower extremity',
  'T8754 Necrosis of amputation stump, left lower extremity',
  'T8781 Dehiscence of amputation stump',
  'T8789 Other complications of amputation stump',
  'T879 Unspecified complications of amputation stump',
  'T880XXA Infection following immunization, initial encounter',
  'T880XXD Infection following immunization, subsequent encounter',
  'T880XXS Infection following immunization, sequela',
  'T881XXA Other complications following immunization, not elsewhere classified, initial encounter',
  'T881XXD Other complications following immunization, not elsewhere classified, subsequent encounter',
  'T881XXS Other complications following immunization, not elsewhere classified, sequela',
  'T882XXA Shock due to anesthesia, initial encounter',
  'T882XXD Shock due to anesthesia, subsequent encounter',
  'T882XXS Shock due to anesthesia, sequela',
  'T883XXA Malignant hyperthermia due to anesthesia, initial encounter',
  'T883XXD Malignant hyperthermia due to anesthesia, subsequent encounter',
  'T883XXS Malignant hyperthermia due to anesthesia, sequela',
  'T884XXA Failed or difficult intubation, initial encounter',
  'T884XXD Failed or difficult intubation, subsequent encounter',
  'T884XXS Failed or difficult intubation, sequela',
  'T8851XA Hypothermia following anesthesia, initial encounter',
  'T8851XD Hypothermia following anesthesia, subsequent encounter',
  'T8851XS Hypothermia following anesthesia, sequela',
  'T8852XA Failed moderate sedation during procedure, initial encounter',
  'T8852XD Failed moderate sedation during procedure, subsequent encounter',
  'T8852XS Failed moderate sedation during procedure, sequela',
  'T8853XA Unintended awareness under general anesthesia during procedure, initial encounter',
  'T8853XD Unintended awareness under general anesthesia during procedure, subsequent encounter',
  'T8853XS Unintended awareness under general anesthesia during procedure, sequela',
  'T8859XA Other complications of anesthesia, initial encounter',
  'T8859XD Other complications of anesthesia, subsequent encounter',
  'T8859XS Other complications of anesthesia, sequela',
  'T886XXA Anaphylactic reaction due to adverse effect of correct drug or medicament properly',
  'administered, initial encounter',
  'T886XXD Anaphylactic reaction due to adverse effect of correct drug or medicament properly',
  'administered, subsequent encounter',
  'T886XXS Anaphylactic reaction due to adverse effect of correct drug or medicament properly',
  'administered, sequela',
  'T887XXA Unspecified adverse effect of drug or medicament, initial encounter',
  'T887XXD Unspecified adverse effect of drug or medicament, subsequent encounter',
  'T887XXS Unspecified adverse effect of drug or medicament, sequela',
  'T888XXA Other specified complications of surgical and medical care, not elsewhere classified, initial',
  'encounter',
  'T888XXD Other specified complications of surgical and medical care, not elsewhere classified,',
  'subsequent encounter',
  'T888XXS Other specified complications of surgical and medical care, not elsewhere classified, sequela',
  'T889XXA Complication of surgical and medical care, unspecified, initial encounter',
  'T889XXD Complication of surgical and medical care, unspecified, subsequent encounter',
  'T889XXS Complication of surgical and medical care, unspecified, sequela',
  'V0001XA Pedestrian on foot injured in collision with roller-skater, initial encounter',
  'V0001XD Pedestrian on foot injured in collision with roller-skater, subsequent encounter',
  'V0001XS Pedestrian on foot injured in collision with roller-skater, sequela',
  'V0002XA Pedestrian on foot injured in collision with skateboarder, initial encounter',
  'V0002XD Pedestrian on foot injured in collision with skateboarder, subsequent encounter',
  'V0002XS Pedestrian on foot injured in collision with skateboarder, sequela',
  'V0009XA Pedestrian on foot injured in collision with other pedestrian conveyance, initial encounter',
  'V0009XD Pedestrian on foot injured in collision with other pedestrian conveyance, subsequent',
  'encounter',
  'V0009XS Pedestrian on foot injured in collision with other pedestrian conveyance, sequela',
  'V00111A Fall from in-line roller-skates, initial encounter',
  'V00111D Fall from in-line roller-skates, subsequent encounter',
  'V00111S Fall from in-line roller-skates, sequela',
  'V00112A In-line roller-skater colliding with stationary object, initial encounter',
  'V00112D In-line roller-skater colliding with stationary object, subsequent encounter',
  'V00112S In-line roller-skater colliding with stationary object, sequela',
  'V00118A Other in-line roller-skate accident, initial encounter',
  'V00118D Other in-line roller-skate accident, subsequent encounter',
  'V00118S Other in-line roller-skate accident, sequela',
  'V00121A Fall from non-in-line roller-skates, initial encounter',
  'V00121D Fall from non-in-line roller-skates, subsequent encounter',
  'V00121S Fall from non-in-line roller-skates, sequela',
  'V00122A Non-in-line roller-skater colliding with stationary object, initial encounter',
  'V00122D Non-in-line roller-skater colliding with stationary object, subsequent encounter',
  'V00122S Non-in-line roller-skater colliding with stationary object, sequela',
  'V00128A Other non-in-line roller-skating accident, initial encounter',
  'V00128D Other non-in-line roller-skating accident, subsequent encounter',
  'V00128S Other non-in-line roller-skating accident, sequela',
  'V00131A Fall from skateboard, initial encounter',
  'V00131D Fall from skateboard, subsequent encounter',
  'V00131S Fall from skateboard, sequela',
  'V00132A Skateboarder colliding with stationary object, initial encounter',
  'V00132D Skateboarder colliding with stationary object, subsequent encounter',
  'V00132S Skateboarder colliding with stationary object, sequela',
  'V00138A Other skateboard accident, initial encounter',
  'V00138D Other skateboard accident, subsequent encounter',
  'V00138S Other skateboard accident, sequela',
  'V00141A Fall from scooter (nonmotorized), initial encounter',
  'V00141D Fall from scooter (nonmotorized), subsequent encounter',
  'V00141S Fall from scooter (nonmotorized), sequela',
  'V00142A Scooter (nonmotorized) colliding with stationary object, initial encounter',
  'V00142D Scooter (nonmotorized) colliding with stationary object, subsequent encounter',
  'V00142S Scooter (nonmotorized) colliding with stationary object, sequela',
  'V00148A Other scooter (nonmotorized) accident, initial encounter',
  'V00148D Other scooter (nonmotorized) accident, subsequent encounter',
  'V00148S Other scooter (nonmotorized) accident, sequela',
  'V00151A Fall from heelies, initial encounter',
  'V00151D Fall from heelies, subsequent encounter',
  'V00151S Fall from heelies, sequela',
  'V00152A Heelies colliding with stationary object, initial encounter',
  'V00152D Heelies colliding with stationary object, subsequent encounter',
  'V00152S Heelies colliding with stationary object, sequela',
  'V00158A Other heelies accident, initial encounter',
  'V00158D Other heelies accident, subsequent encounter',
  'V00158S Other heelies accident, sequela',
  'V00181A Fall from other rolling-type pedestrian conveyance, initial encounter',
  'V00181D Fall from other rolling-type pedestrian conveyance, subsequent encounter',
  'V00181S Fall from other rolling-type pedestrian conveyance, sequela',
  'V00182A Pedestrian on other rolling-type pedestrian conveyance colliding with stationary object, initial',
  'encounter',
  'V00182D Pedestrian on other rolling-type pedestrian conveyance colliding with stationary object,',
  'subsequent encounter',
  'V00182S Pedestrian on other rolling-type pedestrian conveyance colliding with stationary object,',
  'sequela',
  'V00188A Other accident on other rolling-type pedestrian conveyance, initial encounter',
  'V00188D Other accident on other rolling-type pedestrian conveyance, subsequent encounter',
  'V00188S Other accident on other rolling-type pedestrian conveyance, sequela',
  'V00211A Fall from ice-skates, initial encounter',
  'V00211D Fall from ice-skates, subsequent encounter',
  'V00211S Fall from ice-skates, sequela',
  'V00212A Ice-skater colliding with stationary object, initial encounter',
  'V00212D Ice-skater colliding with stationary object, subsequent encounter',
  'V00212S Ice-skater colliding with stationary object, sequela',
  'V00218A Other ice-skates accident, initial encounter',
  'V00218D Other ice-skates accident, subsequent encounter',
  'V00218S Other ice-skates accident, sequela',
  'V00221A Fall from sled, initial encounter',
  'V00221D Fall from sled, subsequent encounter',
  'V00221S Fall from sled, sequela',
  'V00222A Sledder colliding with stationary object, initial encounter',
  'V00222D Sledder colliding with stationary object, subsequent encounter',
  'V00222S Sledder colliding with stationary object, sequela',
  'V00228A Other sled accident, initial encounter',
  'V00228D Other sled accident, subsequent encounter',
  'V00228S Other sled accident, sequela',
  'V00281A Fall from other gliding-type pedestrian conveyance, initial encounter',
  'V00281D Fall from other gliding-type pedestrian conveyance, subsequent encounter',
  'V00281S Fall from other gliding-type pedestrian conveyance, sequela',
  'V00282A Pedestrian on other gliding-type pedestrian conveyance colliding with stationary object, initial',
  'encounter',
  'V00282D Pedestrian on other gliding-type pedestrian conveyance colliding with stationary object,',
  'subsequent encounter',
  'V00282S Pedestrian on other gliding-type pedestrian conveyance colliding with stationary object,',
  'sequela',
  'V00288A Other accident on other gliding-type pedestrian conveyance, initial encounter',
  'V00288D Other accident on other gliding-type pedestrian conveyance, subsequent encounter',
  'V00288S Other accident on other gliding-type pedestrian conveyance, sequela',
  'V00311A Fall from snowboard, initial encounter',
  'V00311D Fall from snowboard, subsequent encounter',
  'V00311S Fall from snowboard, sequela',
  'V00312A Snowboarder colliding with stationary object, initial encounter',
  'V00312D Snowboarder colliding with stationary object, subsequent encounter',
  'V00312S Snowboarder colliding with stationary object, sequela',
  'V00318A Other snowboard accident, initial encounter',
  'V00318D Other snowboard accident, subsequent encounter',
  'V00318S Other snowboard accident, sequela',
  'V00321A Fall from snow-skis, initial encounter',
  'V00321D Fall from snow-skis, subsequent encounter',
  'V00321S Fall from snow-skis, sequela',
  'V00322A Snow-skier colliding with stationary object, initial encounter',
  'V00322D Snow-skier colliding with stationary object, subsequent encounter',
  'V00322S Snow-skier colliding with stationary object, sequela',
  'V00328A Other snow-ski accident, initial encounter',
  'V00328D Other snow-ski accident, subsequent encounter',
  'V00328S Other snow-ski accident, sequela',
  'V00381A Fall from other flat-bottomed pedestrian conveyance, initial encounter',
  'V00381D Fall from other flat-bottomed pedestrian conveyance, subsequent encounter',
  'V00381S Fall from other flat-bottomed pedestrian conveyance, sequela',
  'V00382A Pedestrian on other flat-bottomed pedestrian conveyance colliding with stationary object,',
  'initial encounter',
  'V00382D Pedestrian on other flat-bottomed pedestrian conveyance colliding with stationary object,',
  'subsequent encounter',
  'V00382S Pedestrian on other flat-bottomed pedestrian conveyance colliding with stationary object,',
  'sequela',
  'V00388A Other accident on other flat-bottomed pedestrian conveyance, initial encounter',
  'V00388D Other accident on other flat-bottomed pedestrian conveyance, subsequent encounter',
  'V00388S Other accident on other flat-bottomed pedestrian conveyance, sequela',
  'V00811A Fall from moving wheelchair (powered), initial encounter',
  'V00811D Fall from moving wheelchair (powered), subsequent encounter',
  'V00811S Fall from moving wheelchair (powered), sequela',
  'V00812A Wheelchair (powered) colliding with stationary object, initial encounter',
  'V00812D Wheelchair (powered) colliding with stationary object, subsequent encounter',
  'V00812S Wheelchair (powered) colliding with stationary object, sequela',
  'V00818A Other accident with wheelchair (powered), initial encounter',
  'V00818D Other accident with wheelchair (powered), subsequent encounter',
  'V00818S Other accident with wheelchair (powered), sequela',
  'V00821A Fall from babystroller, initial encounter',
  'V00821D Fall from babystroller, subsequent encounter',
  'V00821S Fall from babystroller, sequela',
  'V00822A Babystroller colliding with stationary object, initial encounter',
  'V00822D Babystroller colliding with stationary object, subsequent encounter',
  'V00822S Babystroller colliding with stationary object, sequela',
  'V00828A Other accident with babystroller, initial encounter',
  'V00828D Other accident with babystroller, subsequent encounter',
  'V00828S Other accident with babystroller, sequela',
  'V00831A Fall from motorized mobility scooter, initial encounter',
  'V00831D Fall from motorized mobility scooter, subsequent encounter',
  'V00831S Fall from motorized mobility scooter, sequela',
  'V00832A Motorized mobility scooter colliding with stationary object, initial encounter',
  'V00832D Motorized mobility scooter colliding with stationary object, subsequent encounter',
  'V00832S Motorized mobility scooter colliding with stationary object, sequela',
  'V00838A Other accident with motorized mobility scooter, initial encounter',
  'V00838D Other accident with motorized mobility scooter, subsequent encounter',
  'V00838S Other accident with motorized mobility scooter, sequela',
  'V00891A Fall from other pedestrian conveyance, initial encounter',
  'V00891D Fall from other pedestrian conveyance, subsequent encounter',
  'V00891S Fall from other pedestrian conveyance, sequela',
  'V00892A Pedestrian on other pedestrian conveyance colliding with stationary object, initial encounter',
  'V00892D Pedestrian on other pedestrian conveyance colliding with stationary object, subsequent',
  'encounter',
  'V00892S Pedestrian on other pedestrian conveyance colliding with stationary object, sequela',
  'V00898A Other accident on other pedestrian conveyance, initial encounter',
  'V00898D Other accident on other pedestrian conveyance, subsequent encounter',
  'V00898S Other accident on other pedestrian conveyance, sequela',
  'V0100XA Pedestrian on foot injured in collision with pedal cycle in nontraffic accident, initial encounter',
  'V0100XD Pedestrian on foot injured in collision with pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V0100XS Pedestrian on foot injured in collision with pedal cycle in nontraffic accident, sequela',
  'V0101XA Pedestrian on roller-skates injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V0101XD Pedestrian on roller-skates injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V0101XS Pedestrian on roller-skates injured in collision with pedal cycle in nontraffic accident, sequela',
  'V0102XA Pedestrian on skateboard injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V0102XD Pedestrian on skateboard injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V0102XS Pedestrian on skateboard injured in collision with pedal cycle in nontraffic accident, sequela',
  'V0109XA Pedestrian with other conveyance injured in collision with pedal cycle in nontraffic accident,',
  'initial encounter',
  'V0109XD Pedestrian with other conveyance injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V0109XS Pedestrian with other conveyance injured in collision with pedal cycle in nontraffic accident,',
  'sequela',
  'V0110XA Pedestrian on foot injured in collision with pedal cycle in traffic accident, initial encounter',
  'V0110XD Pedestrian on foot injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V0110XS Pedestrian on foot injured in collision with pedal cycle in traffic accident, sequela',
  'V0111XA Pedestrian on roller-skates injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V0111XD Pedestrian on roller-skates injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V0111XS Pedestrian on roller-skates injured in collision with pedal cycle in traffic accident, sequela',
  'V0112XA Pedestrian on skateboard injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V0112XD Pedestrian on skateboard injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V0112XS Pedestrian on skateboard injured in collision with pedal cycle in traffic accident, sequela',
  'V0119XA Pedestrian with other conveyance injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V0119XD Pedestrian with other conveyance injured in collision with pedal cycle in traffic accident,',
  'subsequent encounter',
  'V0119XS Pedestrian with other conveyance injured in collision with pedal cycle in traffic accident,',
  'sequela',
  'V0190XA Pedestrian on foot injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, initial encounter',
  'V0190XD Pedestrian on foot injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, subsequent encounter',
  'V0190XS Pedestrian on foot injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, sequela',
  'V0191XA Pedestrian on roller-skates injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, initial encounter',
  'V0191XD Pedestrian on roller-skates injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, subsequent encounter',
  'V0191XS Pedestrian on roller-skates injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, sequela',
  'V0192XA Pedestrian on skateboard injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, initial encounter',
  'V0192XD Pedestrian on skateboard injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, subsequent encounter',
  'V0192XS Pedestrian on skateboard injured in collision with pedal cycle, unspecified whether traffic or',
  'nontraffic accident, sequela',
  'V0199XA Pedestrian with other conveyance injured in collision with pedal cycle, unspecified whether',
  'traffic or nontraffic accident, initial encounter',
  'V0199XD Pedestrian with other conveyance injured in collision with pedal cycle, unspecified whether',
  'traffic or nontraffic accident, subsequent encounter',
  'V0199XS Pedestrian with other conveyance injured in collision with pedal cycle, unspecified whether',
  'traffic or nontraffic accident, sequela',
  'V0200XA Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, initial encounter',
  'V0200XD Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V0200XS Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, sequela',
  'V0201XA Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V0201XD Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V0201XS Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V0202XA Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V0202XD Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V0202XS Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V0209XA Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, initial encounter',
  'V0209XD Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, subsequent encounter',
  'V0209XS Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, sequela',
  'V0210XA Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V0210XD Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V0210XS Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V0211XA Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, initial encounter',
  'V0211XD Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, subsequent encounter',
  'V0211XS Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, sequela',
  'V0212XA Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, initial encounter',
  'V0212XD Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, subsequent encounter',
  'V0212XS Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, sequela',
  'V0219XA Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, initial encounter',
  'V0219XD Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, subsequent encounter',
  'V0219XS Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, sequela',
  'V0290XA Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0290XD Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0290XS Pedestrian on foot injured in collision with two- or three-wheeled motor vehicle, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0291XA Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle,',
  'unspecified whether traffic or nontraffic accident, initial encounter',
  'V0291XD Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle,',
  'unspecified whether traffic or nontraffic accident, subsequent encounter',
  'V0291XS Pedestrian on roller-skates injured in collision with two- or three-wheeled motor vehicle,',
  'unspecified whether traffic or nontraffic accident, sequela',
  'V0292XA Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle,',
  'unspecified whether traffic or nontraffic accident, initial encounter',
  'V0292XD Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle,',
  'unspecified whether traffic or nontraffic accident, subsequent encounter',
  'V0292XS Pedestrian on skateboard injured in collision with two- or three-wheeled motor vehicle,',
  'unspecified whether traffic or nontraffic accident, sequela',
  'V0299XA Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle, unspecified whether traffic or nontraffic accident, initial encounter',
  'V0299XD Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle, unspecified whether traffic or nontraffic accident, subsequent encounter',
  'V0299XS Pedestrian with other conveyance injured in collision with two- or three-wheeled motor',
  'vehicle, unspecified whether traffic or nontraffic accident, sequela',
  'V0300XA Pedestrian on foot injured in collision with car, pick-up truck or van in nontraffic accident,',
  'initial encounter',
  'V0300XD Pedestrian on foot injured in collision with car, pick-up truck or van in nontraffic accident,',
  'subsequent encounter',
  'V0300XS Pedestrian on foot injured in collision with car, pick-up truck or van in nontraffic accident,',
  'sequela',
  'V0301XA Pedestrian on roller-skates injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V0301XD Pedestrian on roller-skates injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V0301XS Pedestrian on roller-skates injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V0302XA Pedestrian on skateboard injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V0302XD Pedestrian on skateboard injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V0302XS Pedestrian on skateboard injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V0309XA Pedestrian with other conveyance injured in collision with car, pick-up truck or van in',
  'nontraffic accident, initial encounter',
  'V0309XD Pedestrian with other conveyance injured in collision with car, pick-up truck or van in',
  'nontraffic accident, subsequent encounter',
  'V0309XS Pedestrian with other conveyance injured in collision with car, pick-up truck or van in',
  'nontraffic accident, sequela',
  'V0310XA Pedestrian on foot injured in collision with car, pick-up truck or van in traffic accident, initial',
  'encounter',
  'V0310XD Pedestrian on foot injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V0310XS Pedestrian on foot injured in collision with car, pick-up truck or van in traffic accident, sequela',
  'V0311XA Pedestrian on roller-skates injured in collision with car, pick-up truck or van in traffic accident,',
  'initial encounter',
  'V0311XD Pedestrian on roller-skates injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V0311XS Pedestrian on roller-skates injured in collision with car, pick-up truck or van in traffic accident,',
  'sequela',
  'V0312XA Pedestrian on skateboard injured in collision with car, pick-up truck or van in traffic accident,',
  'initial encounter',
  'V0312XD Pedestrian on skateboard injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V0312XS Pedestrian on skateboard injured in collision with car, pick-up truck or van in traffic accident,',
  'sequela',
  'V0319XA Pedestrian with other conveyance injured in collision with car, pick-up truck or van in traffic',
  'accident, initial encounter',
  'V0319XD Pedestrian with other conveyance injured in collision with car, pick-up truck or van in traffic',
  'accident, subsequent encounter',
  'V0319XS Pedestrian with other conveyance injured in collision with car, pick-up truck or van in traffic',
  'accident, sequela',
  'V0390XA Pedestrian on foot injured in collision with car, pick-up truck or van, unspecified whether',
  'traffic or nontraffic accident, initial encounter',
  'V0390XD Pedestrian on foot injured in collision with car, pick-up truck or van, unspecified whether',
  'traffic or nontraffic accident, subsequent encounter',
  'V0390XS Pedestrian on foot injured in collision with car, pick-up truck or van, unspecified whether traffic',
  'or nontraffic accident, sequela',
  'V0391XA Pedestrian on roller-skates injured in collision with car, pick-up truck or van, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0391XD Pedestrian on roller-skates injured in collision with car, pick-up truck or van, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0391XS Pedestrian on roller-skates injured in collision with car, pick-up truck or van, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0392XA Pedestrian on skateboard injured in collision with car, pick-up truck or van, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0392XD Pedestrian on skateboard injured in collision with car, pick-up truck or van, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0392XS Pedestrian on skateboard injured in collision with car, pick-up truck or van, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0399XA Pedestrian with other conveyance injured in collision with car, pick-up truck or van,',
  'unspecified whether traffic or nontraffic accident, initial encounter',
  'V0399XD Pedestrian with other conveyance injured in collision with car, pick-up truck or van,',
  'unspecified whether traffic or nontraffic accident, subsequent encounter',
  'V0399XS Pedestrian with other conveyance injured in collision with car, pick-up truck or van, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0400XA Pedestrian on foot injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V0400XD Pedestrian on foot injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V0400XS Pedestrian on foot injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V0401XA Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V0401XD Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V0401XS Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V0402XA Pedestrian on skateboard injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V0402XD Pedestrian on skateboard injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V0402XS Pedestrian on skateboard injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V0409XA Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, initial encounter',
  'V0409XD Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, subsequent encounter',
  'V0409XS Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, sequela',
  'V0410XA Pedestrian on foot injured in collision with heavy transport vehicle or bus in traffic accident,',
  'initial encounter',
  'V0410XD Pedestrian on foot injured in collision with heavy transport vehicle or bus in traffic accident,',
  'subsequent encounter',
  'V0410XS Pedestrian on foot injured in collision with heavy transport vehicle or bus in traffic accident,',
  'sequela',
  'V0411XA Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V0411XD Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V0411XS Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V0412XA Pedestrian on skateboard injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V0412XD Pedestrian on skateboard injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V0412XS Pedestrian on skateboard injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V0419XA Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus in',
  'traffic accident, initial encounter',
  'V0419XD Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus in',
  'traffic accident, subsequent encounter',
  'V0419XS Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus in',
  'traffic accident, sequela',
  'V0490XA Pedestrian on foot injured in collision with heavy transport vehicle or bus, unspecified whether',
  'traffic or nontraffic accident, initial encounter',
  'V0490XD Pedestrian on foot injured in collision with heavy transport vehicle or bus, unspecified whether',
  'traffic or nontraffic accident, subsequent encounter',
  'V0490XS Pedestrian on foot injured in collision with heavy transport vehicle or bus, unspecified whether',
  'traffic or nontraffic accident, sequela',
  'V0491XA Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0491XD Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0491XS Pedestrian on roller-skates injured in collision with heavy transport vehicle or bus, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0492XA Pedestrian on skateboard injured in collision with heavy transport vehicle or bus, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0492XD Pedestrian on skateboard injured in collision with heavy transport vehicle or bus, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0492XS Pedestrian on skateboard injured in collision with heavy transport vehicle or bus, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0499XA Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus,',
  'unspecified whether traffic or nontraffic accident, initial encounter',
  'V0499XD Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus,',
  'unspecified whether traffic or nontraffic accident, subsequent encounter',
  'V0499XS Pedestrian with other conveyance injured in collision with heavy transport vehicle or bus,',
  'unspecified whether traffic or nontraffic accident, sequela',
  'V0500XA Pedestrian on foot injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V0500XD Pedestrian on foot injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V0500XS Pedestrian on foot injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V0501XA Pedestrian on roller-skates injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V0501XD Pedestrian on roller-skates injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V0501XS Pedestrian on roller-skates injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V0502XA Pedestrian on skateboard injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V0502XD Pedestrian on skateboard injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V0502XS Pedestrian on skateboard injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V0509XA Pedestrian with other conveyance injured in collision with railway train or railway vehicle in',
  'nontraffic accident, initial encounter',
  'V0509XD Pedestrian with other conveyance injured in collision with railway train or railway vehicle in',
  'nontraffic accident, subsequent encounter',
  'V0509XS Pedestrian with other conveyance injured in collision with railway train or railway vehicle in',
  'nontraffic accident, sequela',
  'V0510XA Pedestrian on foot injured in collision with railway train or railway vehicle in traffic accident,',
  'initial encounter',
  'V0510XD Pedestrian on foot injured in collision with railway train or railway vehicle in traffic accident,',
  'subsequent encounter',
  'V0510XS Pedestrian on foot injured in collision with railway train or railway vehicle in traffic accident,',
  'sequela',
  'V0511XA Pedestrian on roller-skates injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V0511XD Pedestrian on roller-skates injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V0511XS Pedestrian on roller-skates injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V0512XA Pedestrian on skateboard injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V0512XD Pedestrian on skateboard injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V0512XS Pedestrian on skateboard injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V0519XA Pedestrian with other conveyance injured in collision with railway train or railway vehicle in',
  'traffic accident, initial encounter',
  'V0519XD Pedestrian with other conveyance injured in collision with railway train or railway vehicle in',
  'traffic accident, subsequent encounter',
  'V0519XS Pedestrian with other conveyance injured in collision with railway train or railway vehicle in',
  'traffic accident, sequela',
  'V0590XA Pedestrian on foot injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0590XD Pedestrian on foot injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0590XS Pedestrian on foot injured in collision with railway train or railway vehicle, unspecified whether',
  'traffic or nontraffic accident, sequela',
  'V0591XA Pedestrian on roller-skates injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0591XD Pedestrian on roller-skates injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0591XS Pedestrian on roller-skates injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0592XA Pedestrian on skateboard injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0592XD Pedestrian on skateboard injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0592XS Pedestrian on skateboard injured in collision with railway train or railway vehicle, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0599XA Pedestrian with other conveyance injured in collision with railway train or railway vehicle,',
  'unspecified whether traffic or nontraffic accident, initial encounter',
  'V0599XD Pedestrian with other conveyance injured in collision with railway train or railway vehicle,',
  'unspecified whether traffic or nontraffic accident, subsequent encounter',
  'V0599XS Pedestrian with other conveyance injured in collision with railway train or railway vehicle,',
  'unspecified whether traffic or nontraffic accident, sequela',
  'V0600XA Pedestrian on foot injured in collision with other nonmotor vehicle in nontraffic accident,',
  'initial encounter',
  'V0600XD Pedestrian on foot injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V0600XS Pedestrian on foot injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V0601XA Pedestrian on roller-skates injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V0601XD Pedestrian on roller-skates injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V0601XS Pedestrian on roller-skates injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V0602XA Pedestrian on skateboard injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V0602XD Pedestrian on skateboard injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V0602XS Pedestrian on skateboard injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V0609XA Pedestrian with other conveyance injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V0609XD Pedestrian with other conveyance injured in collision with other nonmotor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V0609XS Pedestrian with other conveyance injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V0610XA Pedestrian on foot injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V0610XD Pedestrian on foot injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V0610XS Pedestrian on foot injured in collision with other nonmotor vehicle in traffic accident, sequela',
  'V0611XA Pedestrian on roller-skates injured in collision with other nonmotor vehicle in traffic accident,',
  'initial encounter',
  'V0611XD Pedestrian on roller-skates injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V0611XS Pedestrian on roller-skates injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V0612XA Pedestrian on skateboard injured in collision with other nonmotor vehicle in traffic accident,',
  'initial encounter',
  'V0612XD Pedestrian on skateboard injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V0612XS Pedestrian on skateboard injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V0619XA Pedestrian with other conveyance injured in collision with other nonmotor vehicle in traffic',
  'accident, initial encounter',
  'V0619XD Pedestrian with other conveyance injured in collision with other nonmotor vehicle in traffic',
  'accident, subsequent encounter',
  'V0619XS Pedestrian with other conveyance injured in collision with other nonmotor vehicle in traffic',
  'accident, sequela',
  'V0690XA Pedestrian on foot injured in collision with other nonmotor vehicle, unspecified whether traffic',
  'or nontraffic accident, initial encounter',
  'V0690XD Pedestrian on foot injured in collision with other nonmotor vehicle, unspecified whether traffic',
  'or nontraffic accident, subsequent encounter',
  'V0690XS Pedestrian on foot injured in collision with other nonmotor vehicle, unspecified whether traffic',
  'or nontraffic accident, sequela',
  'V0691XA Pedestrian on roller-skates injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0691XD Pedestrian on roller-skates injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0691XS Pedestrian on roller-skates injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0692XA Pedestrian on skateboard injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0692XD Pedestrian on skateboard injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0692XS Pedestrian on skateboard injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0699XA Pedestrian with other conveyance injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, initial encounter',
  'V0699XD Pedestrian with other conveyance injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, subsequent encounter',
  'V0699XS Pedestrian with other conveyance injured in collision with other nonmotor vehicle, unspecified',
  'whether traffic or nontraffic accident, sequela',
  'V0900XA Pedestrian injured in nontraffic accident involving unspecified motor vehicles, initial encounter',
  'V0900XD Pedestrian injured in nontraffic accident involving unspecified motor vehicles, subsequent',
  'encounter',
  'V0900XS Pedestrian injured in nontraffic accident involving unspecified motor vehicles, sequela',
  'V0901XA Pedestrian injured in nontraffic accident involving military vehicle, initial encounter',
  'V0901XD Pedestrian injured in nontraffic accident involving military vehicle, subsequent encounter',
  'V0901XS Pedestrian injured in nontraffic accident involving military vehicle, sequela',
  'V0909XA Pedestrian injured in nontraffic accident involving other motor vehicles, initial encounter',
  'V0909XD Pedestrian injured in nontraffic accident involving other motor vehicles, subsequent encounter',
  'V0909XS Pedestrian injured in nontraffic accident involving other motor vehicles, sequela',
  'V091XXA Pedestrian injured in unspecified nontraffic accident, initial encounter',
  'V091XXD Pedestrian injured in unspecified nontraffic accident, subsequent encounter',
  'V091XXS Pedestrian injured in unspecified nontraffic accident, sequela',
  'V0920XA Pedestrian injured in traffic accident involving unspecified motor vehicles, initial encounter',
  'V0920XD Pedestrian injured in traffic accident involving unspecified motor vehicles, subsequent',
  'encounter',
  'V0920XS Pedestrian injured in traffic accident involving unspecified motor vehicles, sequela',
  'V0921XA Pedestrian injured in traffic accident involving military vehicle, initial encounter',
  'V0921XD Pedestrian injured in traffic accident involving military vehicle, subsequent encounter',
  'V0921XS Pedestrian injured in traffic accident involving military vehicle, sequela',
  'V0929XA Pedestrian injured in traffic accident involving other motor vehicles, initial encounter',
  'V0929XD Pedestrian injured in traffic accident involving other motor vehicles, subsequent encounter',
  'V0929XS Pedestrian injured in traffic accident involving other motor vehicles, sequela',
  'V093XXA Pedestrian injured in unspecified traffic accident, initial encounter',
  'V093XXD Pedestrian injured in unspecified traffic accident, subsequent encounter',
  'V093XXS Pedestrian injured in unspecified traffic accident, sequela',
  'V099XXA Pedestrian injured in unspecified transport accident, initial encounter',
  'V099XXD Pedestrian injured in unspecified transport accident, subsequent encounter',
  'V099XXS Pedestrian injured in unspecified transport accident, sequela',
  'V100XXA Pedal cycle driver injured in collision with pedestrian or animal in nontraffic accident, initial',
  'encounter',
  'V100XXD Pedal cycle driver injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V100XXS Pedal cycle driver injured in collision with pedestrian or animal in nontraffic accident, sequela',
  'V101XXA Pedal cycle passenger injured in collision with pedestrian or animal in nontraffic accident,',
  'initial encounter',
  'V101XXD Pedal cycle passenger injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V101XXS Pedal cycle passenger injured in collision with pedestrian or animal in nontraffic accident,',
  'sequela',
  'V102XXA Unspecified pedal cyclist injured in collision with pedestrian or animal in nontraffic accident,',
  'initial encounter',
  'V102XXD Unspecified pedal cyclist injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V102XXS Unspecified pedal cyclist injured in collision with pedestrian or animal in nontraffic accident,',
  'sequela',
  'V103XXA Person boarding or alighting a pedal cycle injured in collision with pedestrian or animal, initial',
  'encounter',
  'V103XXD Person boarding or alighting a pedal cycle injured in collision with pedestrian or animal,',
  'subsequent encounter',
  'V103XXS Person boarding or alighting a pedal cycle injured in collision with pedestrian or animal,',
  'sequela',
  'V104XXA Pedal cycle driver injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V104XXD Pedal cycle driver injured in collision with pedestrian or animal in traffic accident, subsequent',
  'encounter',
  'V104XXS Pedal cycle driver injured in collision with pedestrian or animal in traffic accident, sequela',
  'V105XXA Pedal cycle passenger injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V105XXD Pedal cycle passenger injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V105XXS Pedal cycle passenger injured in collision with pedestrian or animal in traffic accident, sequela',
  'V109XXA Unspecified pedal cyclist injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V109XXD Unspecified pedal cyclist injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V109XXS Unspecified pedal cyclist injured in collision with pedestrian or animal in traffic accident,',
  'sequela',
  'V110XXA Pedal cycle driver injured in collision with other pedal cycle in nontraffic accident, initial',
  'encounter',
  'V110XXD Pedal cycle driver injured in collision with other pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V110XXS Pedal cycle driver injured in collision with other pedal cycle in nontraffic accident, sequela',
  'V111XXA Pedal cycle passenger injured in collision with other pedal cycle in nontraffic accident, initial',
  'encounter',
  'V111XXD Pedal cycle passenger injured in collision with other pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V111XXS Pedal cycle passenger injured in collision with other pedal cycle in nontraffic accident, sequela',
  'V112XXA Unspecified pedal cyclist injured in collision with other pedal cycle in nontraffic accident, initial',
  'encounter',
  'V112XXD Unspecified pedal cyclist injured in collision with other pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V112XXS Unspecified pedal cyclist injured in collision with other pedal cycle in nontraffic accident,',
  'sequela',
  'V113XXA Person boarding or alighting a pedal cycle injured in collision with other pedal cycle, initial',
  'encounter',
  'V113XXD Person boarding or alighting a pedal cycle injured in collision with other pedal cycle,',
  'subsequent encounter',
  'V113XXS Person boarding or alighting a pedal cycle injured in collision with other pedal cycle, sequela',
  'V114XXA Pedal cycle driver injured in collision with other pedal cycle in traffic accident, initial encounter',
  'V114XXD Pedal cycle driver injured in collision with other pedal cycle in traffic accident, subsequent',
  'encounter',
  'V114XXS Pedal cycle driver injured in collision with other pedal cycle in traffic accident, sequela',
  'V115XXA Pedal cycle passenger injured in collision with other pedal cycle in traffic accident, initial',
  'encounter',
  'V115XXD Pedal cycle passenger injured in collision with other pedal cycle in traffic accident, subsequent',
  'encounter',
  'V115XXS Pedal cycle passenger injured in collision with other pedal cycle in traffic accident, sequela',
  'V119XXA Unspecified pedal cyclist injured in collision with other pedal cycle in traffic accident, initial',
  'encounter',
  'V119XXD Unspecified pedal cyclist injured in collision with other pedal cycle in traffic accident,',
  'subsequent encounter',
  'V119XXS Unspecified pedal cyclist injured in collision with other pedal cycle in traffic accident, sequela',
  'V120XXA Pedal cycle driver injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, initial encounter',
  'V120XXD Pedal cycle driver injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V120XXS Pedal cycle driver injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, sequela',
  'V121XXA Pedal cycle passenger injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V121XXD Pedal cycle passenger injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V121XXS Pedal cycle passenger injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V122XXA Unspecified pedal cyclist injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V122XXD Unspecified pedal cyclist injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V122XXS Unspecified pedal cyclist injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V123XXA Person boarding or alighting a pedal cycle injured in collision with two- or three-wheeled',
  'motor vehicle, initial encounter',
  'V123XXD Person boarding or alighting a pedal cycle injured in collision with two- or three-wheeled',
  'motor vehicle, subsequent encounter',
  'V123XXS Person boarding or alighting a pedal cycle injured in collision with two- or three-wheeled',
  'motor vehicle, sequela',
  'V124XXA Pedal cycle driver injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V124XXD Pedal cycle driver injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V124XXS Pedal cycle driver injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V125XXA Pedal cycle passenger injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V125XXD Pedal cycle passenger injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V125XXS Pedal cycle passenger injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V129XXA Unspecified pedal cyclist injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V129XXD Unspecified pedal cyclist injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V129XXS Unspecified pedal cyclist injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V130XXA Pedal cycle driver injured in collision with car, pick-up truck or van in nontraffic accident, initial',
  'encounter',
  'V130XXD Pedal cycle driver injured in collision with car, pick-up truck or van in nontraffic accident,',
  'subsequent encounter',
  'V130XXS Pedal cycle driver injured in collision with car, pick-up truck or van in nontraffic accident,',
  'sequela',
  'V131XXA Pedal cycle passenger injured in collision with car, pick-up truck or van in nontraffic accident,',
  'initial encounter',
  'V131XXD Pedal cycle passenger injured in collision with car, pick-up truck or van in nontraffic accident,',
  'subsequent encounter',
  'V131XXS Pedal cycle passenger injured in collision with car, pick-up truck or van in nontraffic accident,',
  'sequela',
  'V132XXA Unspecified pedal cyclist injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V132XXD Unspecified pedal cyclist injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V132XXS Unspecified pedal cyclist injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V133XXA Person boarding or alighting a pedal cycle injured in collision with car, pick-up truck or van,',
  'initial encounter',
  'V133XXD Person boarding or alighting a pedal cycle injured in collision with car, pick-up truck or van,',
  'subsequent encounter',
  'V133XXS Person boarding or alighting a pedal cycle injured in collision with car, pick-up truck or van,',
  'sequela',
  'V134XXA Pedal cycle driver injured in collision with car, pick-up truck or van in traffic accident, initial',
  'encounter',
  'V134XXD Pedal cycle driver injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V134XXS Pedal cycle driver injured in collision with car, pick-up truck or van in traffic accident, sequela',
  'V135XXA Pedal cycle passenger injured in collision with car, pick-up truck or van in traffic accident, initial',
  'encounter',
  'V135XXD Pedal cycle passenger injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V135XXS Pedal cycle passenger injured in collision with car, pick-up truck or van in traffic accident,',
  'sequela',
  'V139XXA Unspecified pedal cyclist injured in collision with car, pick-up truck or van in traffic accident,',
  'initial encounter',
  'V139XXD Unspecified pedal cyclist injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V139XXS Unspecified pedal cyclist injured in collision with car, pick-up truck or van in traffic accident,',
  'sequela',
  'V140XXA Pedal cycle driver injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'initial encounter',
  'V140XXD Pedal cycle driver injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'subsequent encounter',
  'V140XXS Pedal cycle driver injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'sequela',
  'V141XXA Pedal cycle passenger injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V141XXD Pedal cycle passenger injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V141XXS Pedal cycle passenger injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V142XXA Unspecified pedal cyclist injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V142XXD Unspecified pedal cyclist injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V142XXS Unspecified pedal cyclist injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V143XXA Person boarding or alighting a pedal cycle injured in collision with heavy transport vehicle or',
  'bus, initial encounter',
  'V143XXD Person boarding or alighting a pedal cycle injured in collision with heavy transport vehicle or',
  'bus, subsequent encounter',
  'V143XXS Person boarding or alighting a pedal cycle injured in collision with heavy transport vehicle or',
  'bus, sequela',
  'V144XXA Pedal cycle driver injured in collision with heavy transport vehicle or bus in traffic accident,',
  'initial encounter',
  'V144XXD Pedal cycle driver injured in collision with heavy transport vehicle or bus in traffic accident,',
  'subsequent encounter',
  'V144XXS Pedal cycle driver injured in collision with heavy transport vehicle or bus in traffic accident,',
  'sequela',
  'V145XXA Pedal cycle passenger injured in collision with heavy transport vehicle or bus in traffic accident,',
  'initial encounter',
  'V145XXD Pedal cycle passenger injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V145XXS Pedal cycle passenger injured in collision with heavy transport vehicle or bus in traffic accident,',
  'sequela',
  'V149XXA Unspecified pedal cyclist injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V149XXD Unspecified pedal cyclist injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V149XXS Unspecified pedal cyclist injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V150XXA Pedal cycle driver injured in collision with railway train or railway vehicle in nontraffic accident,',
  'initial encounter',
  'V150XXD Pedal cycle driver injured in collision with railway train or railway vehicle in nontraffic accident,',
  'subsequent encounter',
  'V150XXS Pedal cycle driver injured in collision with railway train or railway vehicle in nontraffic accident,',
  'sequela',
  'V151XXA Pedal cycle passenger injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V151XXD Pedal cycle passenger injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V151XXS Pedal cycle passenger injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V152XXA Unspecified pedal cyclist injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V152XXD Unspecified pedal cyclist injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V152XXS Unspecified pedal cyclist injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V153XXA Person boarding or alighting a pedal cycle injured in collision with railway train or railway',
  'vehicle, initial encounter',
  'V153XXD Person boarding or alighting a pedal cycle injured in collision with railway train or railway',
  'vehicle, subsequent encounter',
  'V153XXS Person boarding or alighting a pedal cycle injured in collision with railway train or railway',
  'vehicle, sequela',
  'V154XXA Pedal cycle driver injured in collision with railway train or railway vehicle in traffic accident,',
  'initial encounter',
  'V154XXD Pedal cycle driver injured in collision with railway train or railway vehicle in traffic accident,',
  'subsequent encounter',
  'V154XXS Pedal cycle driver injured in collision with railway train or railway vehicle in traffic accident,',
  'sequela',
  'V155XXA Pedal cycle passenger injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V155XXD Pedal cycle passenger injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V155XXS Pedal cycle passenger injured in collision with railway train or railway vehicle in traffic accident,',
  'sequela',
  'V159XXA Unspecified pedal cyclist injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V159XXD Unspecified pedal cyclist injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V159XXS Unspecified pedal cyclist injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V160XXA Pedal cycle driver injured in collision with other nonmotor vehicle in nontraffic accident, initial',
  'encounter',
  'V160XXD Pedal cycle driver injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V160XXS Pedal cycle driver injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V161XXA Pedal cycle passenger injured in collision with other nonmotor vehicle in nontraffic accident,',
  'initial encounter',
  'V161XXD Pedal cycle passenger injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V161XXS Pedal cycle passenger injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V162XXA Unspecified pedal cyclist injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V162XXD Unspecified pedal cyclist injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V162XXS Unspecified pedal cyclist injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V163XXA Person boarding or alighting a pedal cycle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, initial encounter',
  'V163XXD Person boarding or alighting a pedal cycle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V163XXS Person boarding or alighting a pedal cycle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, sequela',
  'V164XXA Pedal cycle driver injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V164XXD Pedal cycle driver injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V164XXS Pedal cycle driver injured in collision with other nonmotor vehicle in traffic accident, sequela',
  'V165XXA Pedal cycle passenger injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V165XXD Pedal cycle passenger injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V165XXS Pedal cycle passenger injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V169XXA Unspecified pedal cyclist injured in collision with other nonmotor vehicle in traffic accident,',
  'initial encounter',
  'V169XXD Unspecified pedal cyclist injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V169XXS Unspecified pedal cyclist injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V170XXA Pedal cycle driver injured in collision with fixed or stationary object in nontraffic accident,',
  'initial encounter',
  'V170XXD Pedal cycle driver injured in collision with fixed or stationary object in nontraffic accident,',
  'subsequent encounter',
  'V170XXS Pedal cycle driver injured in collision with fixed or stationary object in nontraffic accident,',
  'sequela',
  'V171XXA Pedal cycle passenger injured in collision with fixed or stationary object in nontraffic accident,',
  'initial encounter',
  'V171XXD Pedal cycle passenger injured in collision with fixed or stationary object in nontraffic accident,',
  'subsequent encounter',
  'V171XXS Pedal cycle passenger injured in collision with fixed or stationary object in nontraffic accident,',
  'sequela',
  'V172XXA Unspecified pedal cyclist injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V172XXD Unspecified pedal cyclist injured in collision with fixed or stationary object in nontraffic',
  'accident, subsequent encounter',
  'V172XXS Unspecified pedal cyclist injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V173XXA Person boarding or alighting a pedal cycle injured in collision with fixed or stationary object,',
  'initial encounter',
  'V173XXD Person boarding or alighting a pedal cycle injured in collision with fixed or stationary object,',
  'subsequent encounter',
  'V173XXS Person boarding or alighting a pedal cycle injured in collision with fixed or stationary object,',
  'sequela',
  'V174XXA Pedal cycle driver injured in collision with fixed or stationary object in traffic accident, initial',
  'encounter',
  'V174XXD Pedal cycle driver injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V174XXS Pedal cycle driver injured in collision with fixed or stationary object in traffic accident, sequela',
  'V175XXA Pedal cycle passenger injured in collision with fixed or stationary object in traffic accident,',
  'initial encounter',
  'V175XXD Pedal cycle passenger injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V175XXS Pedal cycle passenger injured in collision with fixed or stationary object in traffic accident,',
  'sequela',
  'V179XXA Unspecified pedal cyclist injured in collision with fixed or stationary object in traffic accident,',
  'initial encounter',
  'V179XXD Unspecified pedal cyclist injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V179XXS Unspecified pedal cyclist injured in collision with fixed or stationary object in traffic accident,',
  'sequela',
  'V180XXA Pedal cycle driver injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V180XXD Pedal cycle driver injured in noncollision transport accident in nontraffic accident, subsequent',
  'encounter',
  'V180XXS Pedal cycle driver injured in noncollision transport accident in nontraffic accident, sequela',
  'V181XXA Pedal cycle passenger injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V181XXD Pedal cycle passenger injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V181XXS Pedal cycle passenger injured in noncollision transport accident in nontraffic accident, sequela',
  'V182XXA Unspecified pedal cyclist injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V182XXD Unspecified pedal cyclist injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V182XXS Unspecified pedal cyclist injured in noncollision transport accident in nontraffic accident,',
  'sequela',
  'V183XXA Person boarding or alighting a pedal cycle injured in noncollision transport accident, initial',
  'encounter',
  'V183XXD Person boarding or alighting a pedal cycle injured in noncollision transport accident,',
  'subsequent encounter',
  'V183XXS Person boarding or alighting a pedal cycle injured in noncollision transport accident, sequela',
  'V184XXA Pedal cycle driver injured in noncollision transport accident in traffic accident, initial encounter',
  'V184XXD Pedal cycle driver injured in noncollision transport accident in traffic accident, subsequent',
  'encounter',
  'V184XXS Pedal cycle driver injured in noncollision transport accident in traffic accident, sequela',
  'V185XXA Pedal cycle passenger injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V185XXD Pedal cycle passenger injured in noncollision transport accident in traffic accident, subsequent',
  'encounter',
  'V185XXS Pedal cycle passenger injured in noncollision transport accident in traffic accident, sequela',
  'V189XXA Unspecified pedal cyclist injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V189XXD Unspecified pedal cyclist injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V189XXS Unspecified pedal cyclist injured in noncollision transport accident in traffic accident, sequela',
  'V1900XA Pedal cycle driver injured in collision with unspecified motor vehicles in nontraffic accident,',
  'initial encounter',
  'V1900XD Pedal cycle driver injured in collision with unspecified motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V1900XS Pedal cycle driver injured in collision with unspecified motor vehicles in nontraffic accident,',
  'sequela',
  'V1909XA Pedal cycle driver injured in collision with other motor vehicles in nontraffic accident, initial',
  'encounter',
  'V1909XD Pedal cycle driver injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V1909XS Pedal cycle driver injured in collision with other motor vehicles in nontraffic accident, sequela',
  'V1910XA Pedal cycle passenger injured in collision with unspecified motor vehicles in nontraffic',
  'accident, initial encounter',
  'V1910XD Pedal cycle passenger injured in collision with unspecified motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V1910XS Pedal cycle passenger injured in collision with unspecified motor vehicles in nontraffic',
  'accident, sequela',
  'V1919XA Pedal cycle passenger injured in collision with other motor vehicles in nontraffic accident,',
  'initial encounter',
  'V1919XD Pedal cycle passenger injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V1919XS Pedal cycle passenger injured in collision with other motor vehicles in nontraffic accident,',
  'sequela',
  'V1920XA Unspecified pedal cyclist injured in collision with unspecified motor vehicles in nontraffic',
  'accident, initial encounter',
  'V1920XD Unspecified pedal cyclist injured in collision with unspecified motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V1920XS Unspecified pedal cyclist injured in collision with unspecified motor vehicles in nontraffic',
  'accident, sequela',
  'V1929XA Unspecified pedal cyclist injured in collision with other motor vehicles in nontraffic accident,',
  'initial encounter',
  'V1929XD Unspecified pedal cyclist injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V1929XS Unspecified pedal cyclist injured in collision with other motor vehicles in nontraffic accident,',
  'sequela',
  'V193XXA Pedal cyclist (driver) (passenger) injured in unspecified nontraffic accident, initial encounter',
  'V193XXD Pedal cyclist (driver) (passenger) injured in unspecified nontraffic accident, subsequent',
  'encounter',
  'V193XXS Pedal cyclist (driver) (passenger) injured in unspecified nontraffic accident, sequela',
  'V1940XA Pedal cycle driver injured in collision with unspecified motor vehicles in traffic accident, initial',
  'encounter',
  'V1940XD Pedal cycle driver injured in collision with unspecified motor vehicles in traffic accident,',
  'subsequent encounter',
  'V1940XS Pedal cycle driver injured in collision with unspecified motor vehicles in traffic accident,',
  'sequela',
  'V1949XA Pedal cycle driver injured in collision with other motor vehicles in traffic accident, initial',
  'encounter',
  'V1949XD Pedal cycle driver injured in collision with other motor vehicles in traffic accident, subsequent',
  'encounter',
  'V1949XS Pedal cycle driver injured in collision with other motor vehicles in traffic accident, sequela',
  'V1950XA Pedal cycle passenger injured in collision with unspecified motor vehicles in traffic accident,',
  'initial encounter',
  'V1950XD Pedal cycle passenger injured in collision with unspecified motor vehicles in traffic accident,',
  'subsequent encounter',
  'V1950XS Pedal cycle passenger injured in collision with unspecified motor vehicles in traffic accident,',
  'sequela',
  'V1959XA Pedal cycle passenger injured in collision with other motor vehicles in traffic accident, initial',
  'encounter',
  'V1959XD Pedal cycle passenger injured in collision with other motor vehicles in traffic accident,',
  'subsequent encounter',
  'V1959XS Pedal cycle passenger injured in collision with other motor vehicles in traffic accident, sequela',
  'V1960XA Unspecified pedal cyclist injured in collision with unspecified motor vehicles in traffic accident,',
  'initial encounter',
  'V1960XD Unspecified pedal cyclist injured in collision with unspecified motor vehicles in traffic accident,',
  'subsequent encounter',
  'V1960XS Unspecified pedal cyclist injured in collision with unspecified motor vehicles in traffic accident,',
  'sequela',
  'V1969XA Unspecified pedal cyclist injured in collision with other motor vehicles in traffic accident, initial',
  'encounter',
  'V1969XD Unspecified pedal cyclist injured in collision with other motor vehicles in traffic accident,',
  'subsequent encounter',
  'V1969XS Unspecified pedal cyclist injured in collision with other motor vehicles in traffic accident,',
  'sequela',
  'V1981XA Pedal cyclist (driver) (passenger) injured in transport accident with military vehicle, initial',
  'encounter',
  'V1981XD Pedal cyclist (driver) (passenger) injured in transport accident with military vehicle,',
  'subsequent encounter',
  'V1981XS Pedal cyclist (driver) (passenger) injured in transport accident with military vehicle, sequela',
  'V1988XA Pedal cyclist (driver) (passenger) injured in other specified transport accidents, initial',
  'encounter',
  'V1988XD Pedal cyclist (driver) (passenger) injured in other specified transport accidents, subsequent',
  'encounter',
  'V1988XS Pedal cyclist (driver) (passenger) injured in other specified transport accidents, sequela',
  'V199XXA Pedal cyclist (driver) (passenger) injured in unspecified traffic accident, initial encounter',
  'V199XXD Pedal cyclist (driver) (passenger) injured in unspecified traffic accident, subsequent encounter',
  'V199XXS Pedal cyclist (driver) (passenger) injured in unspecified traffic accident, sequela',
  'V200XXA Motorcycle driver injured in collision with pedestrian or animal in nontraffic accident, initial',
  'encounter',
  'V200XXD Motorcycle driver injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V200XXS Motorcycle driver injured in collision with pedestrian or animal in nontraffic accident, sequela',
  'V201XXA Motorcycle passenger injured in collision with pedestrian or animal in nontraffic accident,',
  'initial encounter',
  'V201XXD Motorcycle passenger injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V201XXS Motorcycle passenger injured in collision with pedestrian or animal in nontraffic accident,',
  'sequela',
  'V202XXA Unspecified motorcycle rider injured in collision with pedestrian or animal in nontraffic',
  'accident, initial encounter',
  'V202XXD Unspecified motorcycle rider injured in collision with pedestrian or animal in nontraffic',
  'accident, subsequent encounter',
  'V202XXS Unspecified motorcycle rider injured in collision with pedestrian or animal in nontraffic',
  'accident, sequela',
  'V203XXA Person boarding or alighting a motorcycle injured in collision with pedestrian or animal, initial',
  'encounter',
  'V203XXD Person boarding or alighting a motorcycle injured in collision with pedestrian or animal,',
  'subsequent encounter',
  'V203XXS Person boarding or alighting a motorcycle injured in collision with pedestrian or animal,',
  'sequela',
  'V204XXA Motorcycle driver injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V204XXD Motorcycle driver injured in collision with pedestrian or animal in traffic accident, subsequent',
  'encounter',
  'V204XXS Motorcycle driver injured in collision with pedestrian or animal in traffic accident, sequela',
  'V205XXA Motorcycle passenger injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V205XXD Motorcycle passenger injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V205XXS Motorcycle passenger injured in collision with pedestrian or animal in traffic accident, sequela',
  'V209XXA Unspecified motorcycle rider injured in collision with pedestrian or animal in traffic accident,',
  'initial encounter',
  'V209XXD Unspecified motorcycle rider injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V209XXS Unspecified motorcycle rider injured in collision with pedestrian or animal in traffic accident,',
  'sequela',
  'V210XXA Motorcycle driver injured in collision with pedal cycle in nontraffic accident, initial encounter',
  'V210XXD Motorcycle driver injured in collision with pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V210XXS Motorcycle driver injured in collision with pedal cycle in nontraffic accident, sequela',
  'V211XXA Motorcycle passenger injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V211XXD Motorcycle passenger injured in collision with pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V211XXS Motorcycle passenger injured in collision with pedal cycle in nontraffic accident, sequela',
  'V212XXA Unspecified motorcycle rider injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V212XXD Unspecified motorcycle rider injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V212XXS Unspecified motorcycle rider injured in collision with pedal cycle in nontraffic accident, sequela',
  'V213XXA Person boarding or alighting a motorcycle injured in collision with pedal cycle, initial encounter',
  'V213XXD Person boarding or alighting a motorcycle injured in collision with pedal cycle, subsequent',
  'encounter',
  'V213XXS Person boarding or alighting a motorcycle injured in collision with pedal cycle, sequela',
  'V214XXA Motorcycle driver injured in collision with pedal cycle in traffic accident, initial encounter',
  'V214XXD Motorcycle driver injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V214XXS Motorcycle driver injured in collision with pedal cycle in traffic accident, sequela',
  'V215XXA Motorcycle passenger injured in collision with pedal cycle in traffic accident, initial encounter',
  'V215XXD Motorcycle passenger injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V215XXS Motorcycle passenger injured in collision with pedal cycle in traffic accident, sequela',
  'V219XXA Unspecified motorcycle rider injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V219XXD Unspecified motorcycle rider injured in collision with pedal cycle in traffic accident,',
  'subsequent encounter',
  'V219XXS Unspecified motorcycle rider injured in collision with pedal cycle in traffic accident, sequela',
  'V220XXA Motorcycle driver injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, initial encounter',
  'V220XXD Motorcycle driver injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V220XXS Motorcycle driver injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, sequela',
  'V221XXA Motorcycle passenger injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V221XXD Motorcycle passenger injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V221XXS Motorcycle passenger injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V222XXA Unspecified motorcycle rider injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V222XXD Unspecified motorcycle rider injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V222XXS Unspecified motorcycle rider injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V223XXA Person boarding or alighting a motorcycle injured in collision with two- or three-wheeled',
  'motor vehicle, initial encounter',
  'V223XXD Person boarding or alighting a motorcycle injured in collision with two- or three-wheeled',
  'motor vehicle, subsequent encounter',
  'V223XXS Person boarding or alighting a motorcycle injured in collision with two- or three-wheeled',
  'motor vehicle, sequela',
  'V224XXA Motorcycle driver injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V224XXD Motorcycle driver injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V224XXS Motorcycle driver injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V225XXA Motorcycle passenger injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V225XXD Motorcycle passenger injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V225XXS Motorcycle passenger injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V229XXA Unspecified motorcycle rider injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, initial encounter',
  'V229XXD Unspecified motorcycle rider injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, subsequent encounter',
  'V229XXS Unspecified motorcycle rider injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, sequela',
  'V230XXA Motorcycle driver injured in collision with car, pick-up truck or van in nontraffic accident, initial',
  'encounter',
  'V230XXD Motorcycle driver injured in collision with car, pick-up truck or van in nontraffic accident,',
  'subsequent encounter',
  'V230XXS Motorcycle driver injured in collision with car, pick-up truck or van in nontraffic accident,',
  'sequela',
  'V231XXA Motorcycle passenger injured in collision with car, pick-up truck or van in nontraffic accident,',
  'initial encounter',
  'V231XXD Motorcycle passenger injured in collision with car, pick-up truck or van in nontraffic accident,',
  'subsequent encounter',
  'V231XXS Motorcycle passenger injured in collision with car, pick-up truck or van in nontraffic accident,',
  'sequela',
  'V232XXA Unspecified motorcycle rider injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V232XXD Unspecified motorcycle rider injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V232XXS Unspecified motorcycle rider injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V233XXA Person boarding or alighting a motorcycle injured in collision with car, pick-up truck or van,',
  'initial encounter',
  'V233XXD Person boarding or alighting a motorcycle injured in collision with car, pick-up truck or van,',
  'subsequent encounter',
  'V233XXS Person boarding or alighting a motorcycle injured in collision with car, pick-up truck or van,',
  'sequela',
  'V234XXA Motorcycle driver injured in collision with car, pick-up truck or van in traffic accident, initial',
  'encounter',
  'V234XXD Motorcycle driver injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V234XXS Motorcycle driver injured in collision with car, pick-up truck or van in traffic accident, sequela',
  'V235XXA Motorcycle passenger injured in collision with car, pick-up truck or van in traffic accident,',
  'initial encounter',
  'V235XXD Motorcycle passenger injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V235XXS Motorcycle passenger injured in collision with car, pick-up truck or van in traffic accident,',
  'sequela',
  'V239XXA Unspecified motorcycle rider injured in collision with car, pick-up truck or van in traffic',
  'accident, initial encounter',
  'V239XXD Unspecified motorcycle rider injured in collision with car, pick-up truck or van in traffic',
  'accident, subsequent encounter',
  'V239XXS Unspecified motorcycle rider injured in collision with car, pick-up truck or van in traffic',
  'accident, sequela',
  'V240XXA Motorcycle driver injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'initial encounter',
  'V240XXD Motorcycle driver injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'subsequent encounter',
  'V240XXS Motorcycle driver injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'sequela',
  'V241XXA Motorcycle passenger injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V241XXD Motorcycle passenger injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V241XXS Motorcycle passenger injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V242XXA Unspecified motorcycle rider injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, initial encounter',
  'V242XXD Unspecified motorcycle rider injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, subsequent encounter',
  'V242XXS Unspecified motorcycle rider injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, sequela',
  'V243XXA Person boarding or alighting a motorcycle injured in collision with heavy transport vehicle or',
  'bus, initial encounter',
  'V243XXD Person boarding or alighting a motorcycle injured in collision with heavy transport vehicle or',
  'bus, subsequent encounter',
  'V243XXS Person boarding or alighting a motorcycle injured in collision with heavy transport vehicle or',
  'bus, sequela',
  'V244XXA Motorcycle driver injured in collision with heavy transport vehicle or bus in traffic accident,',
  'initial encounter',
  'V244XXD Motorcycle driver injured in collision with heavy transport vehicle or bus in traffic accident,',
  'subsequent encounter',
  'V244XXS Motorcycle driver injured in collision with heavy transport vehicle or bus in traffic accident,',
  'sequela',
  'V245XXA Motorcycle passenger injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V245XXD Motorcycle passenger injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V245XXS Motorcycle passenger injured in collision with heavy transport vehicle or bus in traffic accident,',
  'sequela',
  'V249XXA Unspecified motorcycle rider injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V249XXD Unspecified motorcycle rider injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V249XXS Unspecified motorcycle rider injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V250XXA Motorcycle driver injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V250XXD Motorcycle driver injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V250XXS Motorcycle driver injured in collision with railway train or railway vehicle in nontraffic accident,',
  'sequela',
  'V251XXA Motorcycle passenger injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V251XXD Motorcycle passenger injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V251XXS Motorcycle passenger injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V252XXA Unspecified motorcycle rider injured in collision with railway train or railway vehicle in',
  'nontraffic accident, initial encounter',
  'V252XXD Unspecified motorcycle rider injured in collision with railway train or railway vehicle in',
  'nontraffic accident, subsequent encounter',
  'V252XXS Unspecified motorcycle rider injured in collision with railway train or railway vehicle in',
  'nontraffic accident, sequela',
  'V253XXA Person boarding or alighting a motorcycle injured in collision with railway train or railway',
  'vehicle, initial encounter',
  'V253XXD Person boarding or alighting a motorcycle injured in collision with railway train or railway',
  'vehicle, subsequent encounter',
  'V253XXS Person boarding or alighting a motorcycle injured in collision with railway train or railway',
  'vehicle, sequela',
  'V254XXA Motorcycle driver injured in collision with railway train or railway vehicle in traffic accident,',
  'initial encounter',
  'V254XXD Motorcycle driver injured in collision with railway train or railway vehicle in traffic accident,',
  'subsequent encounter',
  'V254XXS Motorcycle driver injured in collision with railway train or railway vehicle in traffic accident,',
  'sequela',
  'V255XXA Motorcycle passenger injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V255XXD Motorcycle passenger injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V255XXS Motorcycle passenger injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V259XXA Unspecified motorcycle rider injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V259XXD Unspecified motorcycle rider injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V259XXS Unspecified motorcycle rider injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V260XXA Motorcycle driver injured in collision with other nonmotor vehicle in nontraffic accident, initial',
  'encounter',
  'V260XXD Motorcycle driver injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V260XXS Motorcycle driver injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V261XXA Motorcycle passenger injured in collision with other nonmotor vehicle in nontraffic accident,',
  'initial encounter',
  'V261XXD Motorcycle passenger injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V261XXS Motorcycle passenger injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V262XXA Unspecified motorcycle rider injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V262XXD Unspecified motorcycle rider injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V262XXS Unspecified motorcycle rider injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V263XXA Person boarding or alighting a motorcycle injured in collision with other nonmotor vehicle,',
  'initial encounter',
  'V263XXD Person boarding or alighting a motorcycle injured in collision with other nonmotor vehicle,',
  'subsequent encounter',
  'V263XXS Person boarding or alighting a motorcycle injured in collision with other nonmotor vehicle,',
  'sequela',
  'V264XXA Motorcycle driver injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V264XXD Motorcycle driver injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V264XXS Motorcycle driver injured in collision with other nonmotor vehicle in traffic accident, sequela',
  'V265XXA Motorcycle passenger injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V265XXD Motorcycle passenger injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V265XXS Motorcycle passenger injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V269XXA Unspecified motorcycle rider injured in collision with other nonmotor vehicle in traffic',
  'accident, initial encounter',
  'V269XXD Unspecified motorcycle rider injured in collision with other nonmotor vehicle in traffic',
  'accident, subsequent encounter',
  'V269XXS Unspecified motorcycle rider injured in collision with other nonmotor vehicle in traffic',
  'accident, sequela',
  'V270XXA Motorcycle driver injured in collision with fixed or stationary object in nontraffic accident,',
  'initial encounter',
  'V270XXD Motorcycle driver injured in collision with fixed or stationary object in nontraffic accident,',
  'subsequent encounter',
  'V270XXS Motorcycle driver injured in collision with fixed or stationary object in nontraffic accident,',
  'sequela',
  'V271XXA Motorcycle passenger injured in collision with fixed or stationary object in nontraffic accident,',
  'initial encounter',
  'V271XXD Motorcycle passenger injured in collision with fixed or stationary object in nontraffic accident,',
  'subsequent encounter',
  'V271XXS Motorcycle passenger injured in collision with fixed or stationary object in nontraffic accident,',
  'sequela',
  'V272XXA Unspecified motorcycle rider injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V272XXD Unspecified motorcycle rider injured in collision with fixed or stationary object in nontraffic',
  'accident, subsequent encounter',
  'V272XXS Unspecified motorcycle rider injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V273XXA Person boarding or alighting a motorcycle injured in collision with fixed or stationary object,',
  'initial encounter',
  'V273XXD Person boarding or alighting a motorcycle injured in collision with fixed or stationary object,',
  'subsequent encounter',
  'V273XXS Person boarding or alighting a motorcycle injured in collision with fixed or stationary object,',
  'sequela',
  'V274XXA Motorcycle driver injured in collision with fixed or stationary object in traffic accident, initial',
  'encounter',
  'V274XXD Motorcycle driver injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V274XXS Motorcycle driver injured in collision with fixed or stationary object in traffic accident, sequela',
  'V275XXA Motorcycle passenger injured in collision with fixed or stationary object in traffic accident,',
  'initial encounter',
  'V275XXD Motorcycle passenger injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V275XXS Motorcycle passenger injured in collision with fixed or stationary object in traffic accident,',
  'sequela',
  'V279XXA Unspecified motorcycle rider injured in collision with fixed or stationary object in traffic',
  'accident, initial encounter',
  'V279XXD Unspecified motorcycle rider injured in collision with fixed or stationary object in traffic',
  'accident, subsequent encounter',
  'V279XXS Unspecified motorcycle rider injured in collision with fixed or stationary object in traffic',
  'accident, sequela',
  'V280XXA Motorcycle driver injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V280XXD Motorcycle driver injured in noncollision transport accident in nontraffic accident, subsequent',
  'encounter',
  'V280XXS Motorcycle driver injured in noncollision transport accident in nontraffic accident, sequela',
  'V281XXA Motorcycle passenger injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V281XXD Motorcycle passenger injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V281XXS Motorcycle passenger injured in noncollision transport accident in nontraffic accident, sequela',
  'V282XXA Unspecified motorcycle rider injured in noncollision transport accident in nontraffic accident,',
  'initial encounter',
  'V282XXD Unspecified motorcycle rider injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V282XXS Unspecified motorcycle rider injured in noncollision transport accident in nontraffic accident,',
  'sequela',
  'V283XXA Person boarding or alighting a motorcycle injured in noncollision transport accident, initial',
  'encounter',
  'V283XXD Person boarding or alighting a motorcycle injured in noncollision transport accident,',
  'subsequent encounter',
  'V283XXS Person boarding or alighting a motorcycle injured in noncollision transport accident, sequela',
  'V284XXA Motorcycle driver injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V284XXD Motorcycle driver injured in noncollision transport accident in traffic accident, subsequent',
  'encounter',
  'V284XXS Motorcycle driver injured in noncollision transport accident in traffic accident, sequela',
  'V285XXA Motorcycle passenger injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V285XXD Motorcycle passenger injured in noncollision transport accident in traffic accident, subsequent',
  'encounter',
  'V285XXS Motorcycle passenger injured in noncollision transport accident in traffic accident, sequela',
  'V289XXA Unspecified motorcycle rider injured in noncollision transport accident in traffic accident,',
  'initial encounter',
  'V289XXD Unspecified motorcycle rider injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V289XXS Unspecified motorcycle rider injured in noncollision transport accident in traffic accident,',
  'sequela',
  'V2900XA Motorcycle driver injured in collision with unspecified motor vehicles in nontraffic accident,',
  'initial encounter',
  'V2900XD Motorcycle driver injured in collision with unspecified motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V2900XS Motorcycle driver injured in collision with unspecified motor vehicles in nontraffic accident,',
  'sequela',
  'V2909XA Motorcycle driver injured in collision with other motor vehicles in nontraffic accident, initial',
  'encounter',
  'V2909XD Motorcycle driver injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V2909XS Motorcycle driver injured in collision with other motor vehicles in nontraffic accident, sequela',
  'V2910XA Motorcycle passenger injured in collision with unspecified motor vehicles in nontraffic',
  'accident, initial encounter',
  'V2910XD Motorcycle passenger injured in collision with unspecified motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V2910XS Motorcycle passenger injured in collision with unspecified motor vehicles in nontraffic',
  'accident, sequela',
  'V2919XA Motorcycle passenger injured in collision with other motor vehicles in nontraffic accident,',
  'initial encounter',
  'V2919XD Motorcycle passenger injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V2919XS Motorcycle passenger injured in collision with other motor vehicles in nontraffic accident,',
  'sequela',
  'V2920XA Unspecified motorcycle rider injured in collision with unspecified motor vehicles in nontraffic',
  'accident, initial encounter',
  'V2920XD Unspecified motorcycle rider injured in collision with unspecified motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V2920XS Unspecified motorcycle rider injured in collision with unspecified motor vehicles in nontraffic',
  'accident, sequela',
  'V2929XA Unspecified motorcycle rider injured in collision with other motor vehicles in nontraffic',
  'accident, initial encounter',
  'V2929XD Unspecified motorcycle rider injured in collision with other motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V2929XS Unspecified motorcycle rider injured in collision with other motor vehicles in nontraffic',
  'accident, sequela',
  'V293XXA Motorcycle rider (driver) (passenger) injured in unspecified nontraffic accident, initial',
  'encounter',
  'V293XXD Motorcycle rider (driver) (passenger) injured in unspecified nontraffic accident, subsequent',
  'encounter',
  'V293XXS Motorcycle rider (driver) (passenger) injured in unspecified nontraffic accident, sequela',
  'V2940XA Motorcycle driver injured in collision with unspecified motor vehicles in traffic accident, initial',
  'encounter',
  'V2940XD Motorcycle driver injured in collision with unspecified motor vehicles in traffic accident,',
  'subsequent encounter',
  'V2940XS Motorcycle driver injured in collision with unspecified motor vehicles in traffic accident,',
  'sequela',
  'V2949XA Motorcycle driver injured in collision with other motor vehicles in traffic accident, initial',
  'encounter',
  'V2949XD Motorcycle driver injured in collision with other motor vehicles in traffic accident, subsequent',
  'encounter',
  'V2949XS Motorcycle driver injured in collision with other motor vehicles in traffic accident, sequela',
  'V2950XA Motorcycle passenger injured in collision with unspecified motor vehicles in traffic accident,',
  'initial encounter',
  'V2950XD Motorcycle passenger injured in collision with unspecified motor vehicles in traffic accident,',
  'subsequent encounter',
  'V2950XS Motorcycle passenger injured in collision with unspecified motor vehicles in traffic accident,',
  'sequela',
  'V2959XA Motorcycle passenger injured in collision with other motor vehicles in traffic accident, initial',
  'encounter',
  'V2959XD Motorcycle passenger injured in collision with other motor vehicles in traffic accident,',
  'subsequent encounter',
  'V2959XS Motorcycle passenger injured in collision with other motor vehicles in traffic accident, sequela',
  'V2960XA Unspecified motorcycle rider injured in collision with unspecified motor vehicles in traffic',
  'accident, initial encounter',
  'V2960XD Unspecified motorcycle rider injured in collision with unspecified motor vehicles in traffic',
  'accident, subsequent encounter',
  'V2960XS Unspecified motorcycle rider injured in collision with unspecified motor vehicles in traffic',
  'accident, sequela',
  'V2969XA Unspecified motorcycle rider injured in collision with other motor vehicles in traffic accident,',
  'initial encounter',
  'V2969XD Unspecified motorcycle rider injured in collision with other motor vehicles in traffic accident,',
  'subsequent encounter',
  'V2969XS Unspecified motorcycle rider injured in collision with other motor vehicles in traffic accident,',
  'sequela',
  'V2981XA Motorcycle rider (driver) (passenger) injured in transport accident with military vehicle, initial',
  'encounter',
  'V2981XD Motorcycle rider (driver) (passenger) injured in transport accident with military vehicle,',
  'subsequent encounter',
  'V2981XS Motorcycle rider (driver) (passenger) injured in transport accident with military vehicle,',
  'sequela',
  'V2988XA Motorcycle rider (driver) (passenger) injured in other specified transport accidents, initial',
  'encounter',
  'V2988XD Motorcycle rider (driver) (passenger) injured in other specified transport accidents,',
  'subsequent encounter',
  'V2988XS Motorcycle rider (driver) (passenger) injured in other specified transport accidents, sequela',
  'V299XXA Motorcycle rider (driver) (passenger) injured in unspecified traffic accident, initial encounter',
  'V299XXD Motorcycle rider (driver) (passenger) injured in unspecified traffic accident, subsequent',
  'encounter',
  'V299XXS Motorcycle rider (driver) (passenger) injured in unspecified traffic accident, sequela',
  'V300XXA Driver of three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, initial encounter',
  'V300XXD Driver of three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, subsequent encounter',
  'V300XXS Driver of three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, sequela',
  'V301XXA Passenger in three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, initial encounter',
  'V301XXD Passenger in three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, subsequent encounter',
  'V301XXS Passenger in three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, sequela',
  'V302XXA Person on outside of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in nontraffic accident, initial encounter',
  'V302XXD Person on outside of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in nontraffic accident, subsequent encounter',
  'V302XXS Person on outside of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in nontraffic accident, sequela',
  'V303XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in nontraffic accident, initial encounter',
  'V303XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in nontraffic accident, subsequent encounter',
  'V303XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in nontraffic accident, sequela',
  'V304XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with pedestrian',
  'or animal, initial encounter',
  'V304XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with pedestrian',
  'or animal, subsequent encounter',
  'V304XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with pedestrian',
  'or animal, sequela',
  'V305XXA Driver of three-wheeled motor vehicle injured in collision with pedestrian or animal in traffic',
  'accident, initial encounter',
  'V305XXD Driver of three-wheeled motor vehicle injured in collision with pedestrian or animal in traffic',
  'accident, subsequent encounter',
  'V305XXS Driver of three-wheeled motor vehicle injured in collision with pedestrian or animal in traffic',
  'accident, sequela',
  'V306XXA Passenger in three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'traffic accident, initial encounter',
  'V306XXD Passenger in three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'traffic accident, subsequent encounter',
  'V306XXS Passenger in three-wheeled motor vehicle injured in collision with pedestrian or animal in',
  'traffic accident, sequela',
  'V307XXA Person on outside of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in traffic accident, initial encounter',
  'V307XXD Person on outside of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in traffic accident, subsequent encounter',
  'V307XXS Person on outside of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in traffic accident, sequela',
  'V309XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in traffic accident, initial encounter',
  'V309XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in traffic accident, subsequent encounter',
  'V309XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with pedestrian or',
  'animal in traffic accident, sequela',
  'V310XXA Driver of three-wheeled motor vehicle injured in collision with pedal cycle in nontraffic',
  'accident, initial encounter',
  'V310XXD Driver of three-wheeled motor vehicle injured in collision with pedal cycle in nontraffic',
  'accident, subsequent encounter',
  'V310XXS Driver of three-wheeled motor vehicle injured in collision with pedal cycle in nontraffic',
  'accident, sequela',
  'V311XXA Passenger in three-wheeled motor vehicle injured in collision with pedal cycle in nontraffic',
  'accident, initial encounter',
  'V311XXD Passenger in three-wheeled motor vehicle injured in collision with pedal cycle in nontraffic',
  'accident, subsequent encounter',
  'V311XXS Passenger in three-wheeled motor vehicle injured in collision with pedal cycle in nontraffic',
  'accident, sequela',
  'V312XXA Person on outside of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'nontraffic accident, initial encounter',
  'V312XXD Person on outside of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'nontraffic accident, subsequent encounter',
  'V312XXS Person on outside of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'nontraffic accident, sequela',
  'V313XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'nontraffic accident, initial encounter',
  'V313XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'nontraffic accident, subsequent encounter',
  'V313XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'nontraffic accident, sequela',
  'V314XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with pedal',
  'cycle, initial encounter',
  'V314XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with pedal',
  'cycle, subsequent encounter',
  'V314XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with pedal',
  'cycle, sequela',
  'V315XXA Driver of three-wheeled motor vehicle injured in collision with pedal cycle in traffic accident,',
  'initial encounter',
  'V315XXD Driver of three-wheeled motor vehicle injured in collision with pedal cycle in traffic accident,',
  'subsequent encounter',
  'V315XXS Driver of three-wheeled motor vehicle injured in collision with pedal cycle in traffic accident,',
  'sequela',
  'V316XXA Passenger in three-wheeled motor vehicle injured in collision with pedal cycle in traffic',
  'accident, initial encounter',
  'V316XXD Passenger in three-wheeled motor vehicle injured in collision with pedal cycle in traffic',
  'accident, subsequent encounter',
  'V316XXS Passenger in three-wheeled motor vehicle injured in collision with pedal cycle in traffic',
  'accident, sequela',
  'V317XXA Person on outside of three-wheeled motor vehicle injured in collision with pedal cycle in traffic',
  'accident, initial encounter',
  'V317XXD Person on outside of three-wheeled motor vehicle injured in collision with pedal cycle in traffic',
  'accident, subsequent encounter',
  'V317XXS Person on outside of three-wheeled motor vehicle injured in collision with pedal cycle in traffic',
  'accident, sequela',
  'V319XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'traffic accident, initial encounter',
  'V319XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'traffic accident, subsequent encounter',
  'V319XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with pedal cycle in',
  'traffic accident, sequela',
  'V320XXA Driver of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, initial encounter',
  'V320XXD Driver of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, subsequent encounter',
  'V320XXS Driver of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, sequela',
  'V321XXA Passenger in three-wheeled motor vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, initial encounter',
  'V321XXD Passenger in three-wheeled motor vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, subsequent encounter',
  'V321XXS Passenger in three-wheeled motor vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, sequela',
  'V322XXA Person on outside of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, initial encounter',
  'V322XXD Person on outside of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, subsequent encounter',
  'V322XXS Person on outside of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, sequela',
  'V323XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, initial encounter',
  'V323XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, subsequent encounter',
  'V323XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, sequela',
  'V324XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with two- or',
  'three-wheeled motor vehicle, initial encounter',
  'V324XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with two- or',
  'three-wheeled motor vehicle, subsequent encounter',
  'V324XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with two- or',
  'three-wheeled motor vehicle, sequela',
  'V325XXA Driver of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, initial encounter',
  'V325XXD Driver of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, subsequent encounter',
  'V325XXS Driver of three-wheeled motor vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, sequela',
  'V326XXA Passenger in three-wheeled motor vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, initial encounter',
  'V326XXD Passenger in three-wheeled motor vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, subsequent encounter',
  'V326XXS Passenger in three-wheeled motor vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, sequela',
  'V327XXA Person on outside of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, initial encounter',
  'V327XXD Person on outside of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, subsequent encounter',
  'V327XXS Person on outside of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, sequela',
  'V329XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, initial encounter',
  'V329XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, subsequent encounter',
  'V329XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, sequela',
  'V330XXA Driver of three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, initial encounter',
  'V330XXD Driver of three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, subsequent encounter',
  'V330XXS Driver of three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, sequela',
  'V331XXA Passenger in three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, initial encounter',
  'V331XXD Passenger in three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, subsequent encounter',
  'V331XXS Passenger in three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, sequela',
  'V332XXA Person on outside of three-wheeled motor vehicle injured in collision with car, pick-up truck or',
  'van in nontraffic accident, initial encounter',
  'V332XXD Person on outside of three-wheeled motor vehicle injured in collision with car, pick-up truck or',
  'van in nontraffic accident, subsequent encounter',
  'V332XXS Person on outside of three-wheeled motor vehicle injured in collision with car, pick-up truck or',
  'van in nontraffic accident, sequela',
  'V333XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with car, pick-up',
  'truck or van in nontraffic accident, initial encounter',
  'V333XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with car, pick-up',
  'truck or van in nontraffic accident, subsequent encounter',
  'V333XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with car, pick-up',
  'truck or van in nontraffic accident, sequela',
  'V334XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with car, pickup truck or van, initial encounter',
  'V334XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with car, pickup truck or van, subsequent encounter',
  'V334XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with car, pickup truck or van, sequela',
  'V335XXA Driver of three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'traffic accident, initial encounter',
  'V335XXD Driver of three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'traffic accident, subsequent encounter',
  'V335XXS Driver of three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'traffic accident, sequela',
  'V336XXA Passenger in three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'traffic accident, initial encounter',
  'V336XXD Passenger in three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'traffic accident, subsequent encounter',
  'V336XXS Passenger in three-wheeled motor vehicle injured in collision with car, pick-up truck or van in',
  'traffic accident, sequela',
  'V337XXA Person on outside of three-wheeled motor vehicle injured in collision with car, pick-up truck or',
  'van in traffic accident, initial encounter',
  'V337XXD Person on outside of three-wheeled motor vehicle injured in collision with car, pick-up truck or',
  'van in traffic accident, subsequent encounter',
  'V337XXS Person on outside of three-wheeled motor vehicle injured in collision with car, pick-up truck or',
  'van in traffic accident, sequela',
  'V339XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with car, pick-up',
  'truck or van in traffic accident, initial encounter',
  'V339XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with car, pick-up',
  'truck or van in traffic accident, subsequent encounter',
  'V339XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with car, pick-up',
  'truck or van in traffic accident, sequela',
  'V340XXA Driver of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus',
  'in nontraffic accident, initial encounter',
  'V340XXD Driver of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus',
  'in nontraffic accident, subsequent encounter',
  'V340XXS Driver of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus',
  'in nontraffic accident, sequela',
  'V341XXA Passenger in three-wheeled motor vehicle injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, initial encounter',
  'V341XXD Passenger in three-wheeled motor vehicle injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, subsequent encounter',
  'V341XXS Passenger in three-wheeled motor vehicle injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, sequela',
  'V342XXA Person on outside of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, initial encounter',
  'V342XXD Person on outside of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, subsequent encounter',
  'V342XXS Person on outside of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, sequela',
  'V343XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, initial encounter',
  'V343XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, subsequent encounter',
  'V343XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, sequela',
  'V344XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with heavy',
  'transport vehicle or bus, initial encounter',
  'V344XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with heavy',
  'transport vehicle or bus, subsequent encounter',
  'V344XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with heavy',
  'transport vehicle or bus, sequela',
  'V345XXA Driver of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus',
  'in traffic accident, initial encounter',
  'V345XXD Driver of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus',
  'in traffic accident, subsequent encounter',
  'V345XXS Driver of three-wheeled motor vehicle injured in collision with heavy transport vehicle or bus',
  'in traffic accident, sequela',
  'V346XXA Passenger in three-wheeled motor vehicle injured in collision with heavy transport vehicle or',
  'bus in traffic accident, initial encounter',
  'V346XXD Passenger in three-wheeled motor vehicle injured in collision with heavy transport vehicle or',
  'bus in traffic accident, subsequent encounter',
  'V346XXS Passenger in three-wheeled motor vehicle injured in collision with heavy transport vehicle or',
  'bus in traffic accident, sequela',
  'V347XXA Person on outside of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, initial encounter',
  'V347XXD Person on outside of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, subsequent encounter',
  'V347XXS Person on outside of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, sequela',
  'V349XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, initial encounter',
  'V349XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, subsequent encounter',
  'V349XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, sequela',
  'V350XXA Driver of three-wheeled motor vehicle injured in collision with railway train or railway vehicle',
  'in nontraffic accident, initial encounter',
  'V350XXD Driver of three-wheeled motor vehicle injured in collision with railway train or railway vehicle',
  'in nontraffic accident, subsequent encounter',
  'V350XXS Driver of three-wheeled motor vehicle injured in collision with railway train or railway vehicle',
  'in nontraffic accident, sequela',
  'V351XXA Passenger in three-wheeled motor vehicle injured in collision with railway train or railway',
  'vehicle in nontraffic accident, initial encounter',
  'V351XXD Passenger in three-wheeled motor vehicle injured in collision with railway train or railway',
  'vehicle in nontraffic accident, subsequent encounter',
  'V351XXS Passenger in three-wheeled motor vehicle injured in collision with railway train or railway',
  'vehicle in nontraffic accident, sequela',
  'V352XXA Person on outside of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, initial encounter',
  'V352XXD Person on outside of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, subsequent encounter',
  'V352XXS Person on outside of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, sequela',
  'V353XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, initial encounter',
  'V353XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, subsequent encounter',
  'V353XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, sequela',
  'V354XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with railway',
  'train or railway vehicle, initial encounter',
  'V354XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with railway',
  'train or railway vehicle, subsequent encounter',
  'V354XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with railway',
  'train or railway vehicle, sequela',
  'V355XXA Driver of three-wheeled motor vehicle injured in collision with railway train or railway vehicle',
  'in traffic accident, initial encounter',
  'V355XXD Driver of three-wheeled motor vehicle injured in collision with railway train or railway vehicle',
  'in traffic accident, subsequent encounter',
  'V355XXS Driver of three-wheeled motor vehicle injured in collision with railway train or railway vehicle',
  'in traffic accident, sequela',
  'V356XXA Passenger in three-wheeled motor vehicle injured in collision with railway train or railway',
  'vehicle in traffic accident, initial encounter',
  'V356XXD Passenger in three-wheeled motor vehicle injured in collision with railway train or railway',
  'vehicle in traffic accident, subsequent encounter',
  'V356XXS Passenger in three-wheeled motor vehicle injured in collision with railway train or railway',
  'vehicle in traffic accident, sequela',
  'V357XXA Person on outside of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, initial encounter',
  'V357XXD Person on outside of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, subsequent encounter',
  'V357XXS Person on outside of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, sequela',
  'V359XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, initial encounter',
  'V359XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, subsequent encounter',
  'V359XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, sequela',
  'V360XXA Driver of three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, initial encounter',
  'V360XXD Driver of three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V360XXS Driver of three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, sequela',
  'V361XXA Passenger in three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, initial encounter',
  'V361XXD Passenger in three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V361XXS Passenger in three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, sequela',
  'V362XXA Person on outside of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, initial encounter',
  'V362XXD Person on outside of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, subsequent encounter',
  'V362XXS Person on outside of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, sequela',
  'V363XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, initial encounter',
  'V363XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, subsequent encounter',
  'V363XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, sequela',
  'V364XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with other',
  'nonmotor vehicle, initial encounter',
  'V364XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with other',
  'nonmotor vehicle, subsequent encounter',
  'V364XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with other',
  'nonmotor vehicle, sequela',
  'V365XXA Driver of three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'traffic accident, initial encounter',
  'V365XXD Driver of three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'traffic accident, subsequent encounter',
  'V365XXS Driver of three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'traffic accident, sequela',
  'V366XXA Passenger in three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'traffic accident, initial encounter',
  'V366XXD Passenger in three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'traffic accident, subsequent encounter',
  'V366XXS Passenger in three-wheeled motor vehicle injured in collision with other nonmotor vehicle in',
  'traffic accident, sequela',
  'V367XXA Person on outside of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, initial encounter',
  'V367XXD Person on outside of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, subsequent encounter',
  'V367XXS Person on outside of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, sequela',
  'V369XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, initial encounter',
  'V369XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, subsequent encounter',
  'V369XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, sequela',
  'V370XXA Driver of three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, initial encounter',
  'V370XXD Driver of three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, subsequent encounter',
  'V370XXS Driver of three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, sequela',
  'V371XXA Passenger in three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, initial encounter',
  'V371XXD Passenger in three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, subsequent encounter',
  'V371XXS Passenger in three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, sequela',
  'V372XXA Person on outside of three-wheeled motor vehicle injured in collision with fixed or stationary',
  'object in nontraffic accident, initial encounter',
  'V372XXD Person on outside of three-wheeled motor vehicle injured in collision with fixed or stationary',
  'object in nontraffic accident, subsequent encounter',
  'V372XXS Person on outside of three-wheeled motor vehicle injured in collision with fixed or stationary',
  'object in nontraffic accident, sequela',
  'V373XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object in nontraffic accident, initial encounter',
  'V373XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object in nontraffic accident, subsequent encounter',
  'V373XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object in nontraffic accident, sequela',
  'V374XXA Person boarding or alighting a three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object, initial encounter',
  'V374XXD Person boarding or alighting a three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object, subsequent encounter',
  'V374XXS Person boarding or alighting a three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object, sequela',
  'V375XXA Driver of three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'traffic accident, initial encounter',
  'V375XXD Driver of three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'traffic accident, subsequent encounter',
  'V375XXS Driver of three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'traffic accident, sequela',
  'V376XXA Passenger in three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'traffic accident, initial encounter',
  'V376XXD Passenger in three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'traffic accident, subsequent encounter',
  'V376XXS Passenger in three-wheeled motor vehicle injured in collision with fixed or stationary object in',
  'traffic accident, sequela',
  'V377XXA Person on outside of three-wheeled motor vehicle injured in collision with fixed or stationary',
  'object in traffic accident, initial encounter',
  'V377XXD Person on outside of three-wheeled motor vehicle injured in collision with fixed or stationary',
  'object in traffic accident, subsequent encounter',
  'V377XXS Person on outside of three-wheeled motor vehicle injured in collision with fixed or stationary',
  'object in traffic accident, sequela',
  'V379XXA Unspecified occupant of three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object in traffic accident, initial encounter',
  'V379XXD Unspecified occupant of three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object in traffic accident, subsequent encounter',
  'V379XXS Unspecified occupant of three-wheeled motor vehicle injured in collision with fixed or',
  'stationary object in traffic accident, sequela',
  'V380XXA Driver of three-wheeled motor vehicle injured in noncollision transport accident in nontraffic',
  'accident, initial encounter',
  'V380XXD Driver of three-wheeled motor vehicle injured in noncollision transport accident in nontraffic',
  'accident, subsequent encounter',
  'V380XXS Driver of three-wheeled motor vehicle injured in noncollision transport accident in nontraffic',
  'accident, sequela',
  'V381XXA Passenger in three-wheeled motor vehicle injured in noncollision transport accident in',
  'nontraffic accident, initial encounter',
  'V381XXD Passenger in three-wheeled motor vehicle injured in noncollision transport accident in',
  'nontraffic accident, subsequent encounter',
  'V381XXS Passenger in three-wheeled motor vehicle injured in noncollision transport accident in',
  'nontraffic accident, sequela',
  'V382XXA Person on outside of three-wheeled motor vehicle injured in noncollision transport accident in',
  'nontraffic accident, initial encounter',
  'V382XXD Person on outside of three-wheeled motor vehicle injured in noncollision transport accident in',
  'nontraffic accident, subsequent encounter',
  'V382XXS Person on outside of three-wheeled motor vehicle injured in noncollision transport accident in',
  'nontraffic accident, sequela',
  'V383XXA Unspecified occupant of three-wheeled motor vehicle injured in noncollision transport',
  'accident in nontraffic accident, initial encounter',
  'V383XXD Unspecified occupant of three-wheeled motor vehicle injured in noncollision transport',
  'accident in nontraffic accident, subsequent encounter',
  'V383XXS Unspecified occupant of three-wheeled motor vehicle injured in noncollision transport',
  'accident in nontraffic accident, sequela',
  'V384XXA Person boarding or alighting a three-wheeled motor vehicle injured in noncollision transport',
  'accident, initial encounter',
  'V384XXD Person boarding or alighting a three-wheeled motor vehicle injured in noncollision transport',
  'accident, subsequent encounter',
  'V384XXS Person boarding or alighting a three-wheeled motor vehicle injured in noncollision transport',
  'accident, sequela',
  'V385XXA Driver of three-wheeled motor vehicle injured in noncollision transport accident in traffic',
  'accident, initial encounter',
  'V385XXD Driver of three-wheeled motor vehicle injured in noncollision transport accident in traffic',
  'accident, subsequent encounter',
  'V385XXS Driver of three-wheeled motor vehicle injured in noncollision transport accident in traffic',
  'accident, sequela',
  'V386XXA Passenger in three-wheeled motor vehicle injured in noncollision transport accident in traffic',
  'accident, initial encounter',
  'V386XXD Passenger in three-wheeled motor vehicle injured in noncollision transport accident in traffic',
  'accident, subsequent encounter',
  'V386XXS Passenger in three-wheeled motor vehicle injured in noncollision transport accident in traffic',
  'accident, sequela',
  'V387XXA Person on outside of three-wheeled motor vehicle injured in noncollision transport accident in',
  'traffic accident, initial encounter',
  'V387XXD Person on outside of three-wheeled motor vehicle injured in noncollision transport accident in',
  'traffic accident, subsequent encounter',
  'V387XXS Person on outside of three-wheeled motor vehicle injured in noncollision transport accident in',
  'traffic accident, sequela',
  'V389XXA Unspecified occupant of three-wheeled motor vehicle injured in noncollision transport',
  'accident in traffic accident, initial encounter',
  'V389XXD Unspecified occupant of three-wheeled motor vehicle injured in noncollision transport',
  'accident in traffic accident, subsequent encounter',
  'V389XXS Unspecified occupant of three-wheeled motor vehicle injured in noncollision transport',
  'accident in traffic accident, sequela',
  'V3900XA Driver of three-wheeled motor vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, initial encounter',
  'V3900XD Driver of three-wheeled motor vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, subsequent encounter',
  'V3900XS Driver of three-wheeled motor vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, sequela',
  'V3909XA Driver of three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'nontraffic accident, initial encounter',
  'V3909XD Driver of three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'nontraffic accident, subsequent encounter',
  'V3909XS Driver of three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'nontraffic accident, sequela',
  'V3910XA Passenger in three-wheeled motor vehicle injured in collision with unspecified motor vehicles',
  'in nontraffic accident, initial encounter',
  'V3910XD Passenger in three-wheeled motor vehicle injured in collision with unspecified motor vehicles',
  'in nontraffic accident, subsequent encounter',
  'V3910XS Passenger in three-wheeled motor vehicle injured in collision with unspecified motor vehicles',
  'in nontraffic accident, sequela',
  'V3919XA Passenger in three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'nontraffic accident, initial encounter',
  'V3919XD Passenger in three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'nontraffic accident, subsequent encounter',
  'V3919XS Passenger in three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'nontraffic accident, sequela',
  'V3920XA Unspecified occupant of three-wheeled motor vehicle injured in collision with unspecified',
  'motor vehicles in nontraffic accident, initial encounter',
  'V3920XD Unspecified occupant of three-wheeled motor vehicle injured in collision with unspecified',
  'motor vehicles in nontraffic accident, subsequent encounter',
  'V3920XS Unspecified occupant of three-wheeled motor vehicle injured in collision with unspecified',
  'motor vehicles in nontraffic accident, sequela',
  'V3929XA Unspecified occupant of three-wheeled motor vehicle injured in collision with other motor',
  'vehicles in nontraffic accident, initial encounter',
  'V3929XD Unspecified occupant of three-wheeled motor vehicle injured in collision with other motor',
  'vehicles in nontraffic accident, subsequent encounter',
  'V3929XS Unspecified occupant of three-wheeled motor vehicle injured in collision with other motor',
  'vehicles in nontraffic accident, sequela',
  'V393XXA Occupant (driver) (passenger) of three-wheeled motor vehicle injured in unspecified nontraffic',
  'accident, initial encounter',
  'V393XXD Occupant (driver) (passenger) of three-wheeled motor vehicle injured in unspecified nontraffic',
  'accident, subsequent encounter',
  'V393XXS Occupant (driver) (passenger) of three-wheeled motor vehicle injured in unspecified nontraffic',
  'accident, sequela',
  'V3940XA Driver of three-wheeled motor vehicle injured in collision with unspecified motor vehicles in',
  'traffic accident, initial encounter',
  'V3940XD Driver of three-wheeled motor vehicle injured in collision with unspecified motor vehicles in',
  'traffic accident, subsequent encounter',
  'V3940XS Driver of three-wheeled motor vehicle injured in collision with unspecified motor vehicles in',
  'traffic accident, sequela',
  'V3949XA Driver of three-wheeled motor vehicle injured in collision with other motor vehicles in traffic',
  'accident, initial encounter',
  'V3949XD Driver of three-wheeled motor vehicle injured in collision with other motor vehicles in traffic',
  'accident, subsequent encounter',
  'V3949XS Driver of three-wheeled motor vehicle injured in collision with other motor vehicles in traffic',
  'accident, sequela',
  'V3950XA Passenger in three-wheeled motor vehicle injured in collision with unspecified motor vehicles',
  'in traffic accident, initial encounter',
  'V3950XD Passenger in three-wheeled motor vehicle injured in collision with unspecified motor vehicles',
  'in traffic accident, subsequent encounter',
  'V3950XS Passenger in three-wheeled motor vehicle injured in collision with unspecified motor vehicles',
  'in traffic accident, sequela',
  'V3959XA Passenger in three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'traffic accident, initial encounter',
  'V3959XD Passenger in three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'traffic accident, subsequent encounter',
  'V3959XS Passenger in three-wheeled motor vehicle injured in collision with other motor vehicles in',
  'traffic accident, sequela',
  'V3960XA Unspecified occupant of three-wheeled motor vehicle injured in collision with unspecified',
  'motor vehicles in traffic accident, initial encounter',
  'V3960XD Unspecified occupant of three-wheeled motor vehicle injured in collision with unspecified',
  'motor vehicles in traffic accident, subsequent encounter',
  'V3960XS Unspecified occupant of three-wheeled motor vehicle injured in collision with unspecified',
  'motor vehicles in traffic accident, sequela',
  'V3969XA Unspecified occupant of three-wheeled motor vehicle injured in collision with other motor',
  'vehicles in traffic accident, initial encounter',
  'V3969XD Unspecified occupant of three-wheeled motor vehicle injured in collision with other motor',
  'vehicles in traffic accident, subsequent encounter',
  'V3969XS Unspecified occupant of three-wheeled motor vehicle injured in collision with other motor',
  'vehicles in traffic accident, sequela',
  'V3981XA Occupant (driver) (passenger) of three-wheeled motor vehicle injured in transport accident',
  'with military vehicle, initial encounter',
  'V3981XD Occupant (driver) (passenger) of three-wheeled motor vehicle injured in transport accident',
  'with military vehicle, subsequent encounter',
  'V3981XS Occupant (driver) (passenger) of three-wheeled motor vehicle injured in transport accident',
  'with military vehicle, sequela',
  'V3989XA Occupant (driver) (passenger) of three-wheeled motor vehicle injured in other specified',
  'transport accidents, initial encounter',
  'V3989XD Occupant (driver) (passenger) of three-wheeled motor vehicle injured in other specified',
  'transport accidents, subsequent encounter',
  'V3989XS Occupant (driver) (passenger) of three-wheeled motor vehicle injured in other specified',
  'transport accidents, sequela',
  'V399XXA Occupant (driver) (passenger) of three-wheeled motor vehicle injured in unspecified traffic',
  'accident, initial encounter',
  'V399XXD Occupant (driver) (passenger) of three-wheeled motor vehicle injured in unspecified traffic',
  'accident, subsequent encounter',
  'V399XXS Occupant (driver) (passenger) of three-wheeled motor vehicle injured in unspecified traffic',
  'accident, sequela',
  'V400XXA Car driver injured in collision with pedestrian or animal in nontraffic accident, initial encounter',
  'V400XXD Car driver injured in collision with pedestrian or animal in nontraffic accident, subsequent',
  'encounter',
  'V400XXS Car driver injured in collision with pedestrian or animal in nontraffic accident, sequela',
  'V401XXA Car passenger injured in collision with pedestrian or animal in nontraffic accident, initial',
  'encounter',
  'V401XXD Car passenger injured in collision with pedestrian or animal in nontraffic accident, subsequent',
  'encounter',
  'V401XXS Car passenger injured in collision with pedestrian or animal in nontraffic accident, sequela',
  'V402XXA Person on outside of car injured in collision with pedestrian or animal in nontraffic accident,',
  'initial encounter',
  'V402XXD Person on outside of car injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V402XXS Person on outside of car injured in collision with pedestrian or animal in nontraffic accident,',
  'sequela',
  'V403XXA Unspecified car occupant injured in collision with pedestrian or animal in nontraffic accident,',
  'initial encounter',
  'V403XXD Unspecified car occupant injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V403XXS Unspecified car occupant injured in collision with pedestrian or animal in nontraffic accident,',
  'sequela',
  'V404XXA Person boarding or alighting a car injured in collision with pedestrian or animal, initial',
  'encounter',
  'V404XXD Person boarding or alighting a car injured in collision with pedestrian or animal, subsequent',
  'encounter',
  'V404XXS Person boarding or alighting a car injured in collision with pedestrian or animal, sequela',
  'V405XXA Car driver injured in collision with pedestrian or animal in traffic accident, initial encounter',
  'V405XXD Car driver injured in collision with pedestrian or animal in traffic accident, subsequent',
  'encounter',
  'V405XXS Car driver injured in collision with pedestrian or animal in traffic accident, sequela',
  'V406XXA Car passenger injured in collision with pedestrian or animal in traffic accident, initial encounter',
  'V406XXD Car passenger injured in collision with pedestrian or animal in traffic accident, subsequent',
  'encounter',
  'V406XXS Car passenger injured in collision with pedestrian or animal in traffic accident, sequela',
  'V407XXA Person on outside of car injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V407XXD Person on outside of car injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V407XXS Person on outside of car injured in collision with pedestrian or animal in traffic accident,',
  'sequela',
  'V409XXA Unspecified car occupant injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V409XXD Unspecified car occupant injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V409XXS Unspecified car occupant injured in collision with pedestrian or animal in traffic accident,',
  'sequela',
  'V410XXA Car driver injured in collision with pedal cycle in nontraffic accident, initial encounter',
  'V410XXD Car driver injured in collision with pedal cycle in nontraffic accident, subsequent encounter',
  'V410XXS Car driver injured in collision with pedal cycle in nontraffic accident, sequela',
  'V411XXA Car passenger injured in collision with pedal cycle in nontraffic accident, initial encounter',
  'V411XXD Car passenger injured in collision with pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V411XXS Car passenger injured in collision with pedal cycle in nontraffic accident, sequela',
  'V412XXA Person on outside of car injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V412XXD Person on outside of car injured in collision with pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V412XXS Person on outside of car injured in collision with pedal cycle in nontraffic accident, sequela',
  'V413XXA Unspecified car occupant injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V413XXD Unspecified car occupant injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V413XXS Unspecified car occupant injured in collision with pedal cycle in nontraffic accident, sequela',
  'V414XXA Person boarding or alighting a car injured in collision with pedal cycle, initial encounter',
  'V414XXD Person boarding or alighting a car injured in collision with pedal cycle, subsequent encounter',
  'V414XXS Person boarding or alighting a car injured in collision with pedal cycle, sequela',
  'V415XXA Car driver injured in collision with pedal cycle in traffic accident, initial encounter',
  'V415XXD Car driver injured in collision with pedal cycle in traffic accident, subsequent encounter',
  'V415XXS Car driver injured in collision with pedal cycle in traffic accident, sequela',
  'V416XXA Car passenger injured in collision with pedal cycle in traffic accident, initial encounter',
  'V416XXD Car passenger injured in collision with pedal cycle in traffic accident, subsequent encounter',
  'V416XXS Car passenger injured in collision with pedal cycle in traffic accident, sequela',
  'V417XXA Person on outside of car injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V417XXD Person on outside of car injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V417XXS Person on outside of car injured in collision with pedal cycle in traffic accident, sequela',
  'V419XXA Unspecified car occupant injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V419XXD Unspecified car occupant injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V419XXS Unspecified car occupant injured in collision with pedal cycle in traffic accident, sequela',
  'V420XXA Car driver injured in collision with two- or three-wheeled motor vehicle in nontraffic accident,',
  'initial encounter',
  'V420XXD Car driver injured in collision with two- or three-wheeled motor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V420XXS Car driver injured in collision with two- or three-wheeled motor vehicle in nontraffic accident,',
  'sequela',
  'V421XXA Car passenger injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, initial encounter',
  'V421XXD Car passenger injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V421XXS Car passenger injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, sequela',
  'V422XXA Person on outside of car injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V422XXD Person on outside of car injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V422XXS Person on outside of car injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V423XXA Unspecified car occupant injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V423XXD Unspecified car occupant injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V423XXS Unspecified car occupant injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V424XXA Person boarding or alighting a car injured in collision with two- or three-wheeled motor',
  'vehicle, initial encounter',
  'V424XXD Person boarding or alighting a car injured in collision with two- or three-wheeled motor',
  'vehicle, subsequent encounter',
  'V424XXS Person boarding or alighting a car injured in collision with two- or three-wheeled motor',
  'vehicle, sequela',
  'V425XXA Car driver injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'initial encounter',
  'V425XXD Car driver injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'subsequent encounter',
  'V425XXS Car driver injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'sequela',
  'V426XXA Car passenger injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'initial encounter',
  'V426XXD Car passenger injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'subsequent encounter',
  'V426XXS Car passenger injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'sequela',
  'V427XXA Person on outside of car injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V427XXD Person on outside of car injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V427XXS Person on outside of car injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V429XXA Unspecified car occupant injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, initial encounter',
  'V429XXD Unspecified car occupant injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, subsequent encounter',
  'V429XXS Unspecified car occupant injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, sequela',
  'V4301XA Car driver injured in collision with sport utility vehicle in nontraffic accident, initial encounter',
  'V4301XD Car driver injured in collision with sport utility vehicle in nontraffic accident, subsequent',
  'encounter',
  'V4301XS Car driver injured in collision with sport utility vehicle in nontraffic accident, sequela',
  'V4302XA Car driver injured in collision with other type car in nontraffic accident, initial encounter',
  'V4302XD Car driver injured in collision with other type car in nontraffic accident, subsequent encounter',
  'V4302XS Car driver injured in collision with other type car in nontraffic accident, sequela',
  'V4303XA Car driver injured in collision with pick-up truck in nontraffic accident, initial encounter',
  'V4303XD Car driver injured in collision with pick-up truck in nontraffic accident, subsequent encounter',
  'V4303XS Car driver injured in collision with pick-up truck in nontraffic accident, sequela',
  'V4304XA Car driver injured in collision with van in nontraffic accident, initial encounter',
  'V4304XD Car driver injured in collision with van in nontraffic accident, subsequent encounter',
  'V4304XS Car driver injured in collision with van in nontraffic accident, sequela',
  'V4311XA Car passenger injured in collision with sport utility vehicle in nontraffic accident, initial',
  'encounter',
  'V4311XD Car passenger injured in collision with sport utility vehicle in nontraffic accident, subsequent',
  'encounter',
  'V4311XS Car passenger injured in collision with sport utility vehicle in nontraffic accident, sequela',
  'V4312XA Car passenger injured in collision with other type car in nontraffic accident, initial encounter',
  'V4312XD Car passenger injured in collision with other type car in nontraffic accident, subsequent',
  'encounter',
  'V4312XS Car passenger injured in collision with other type car in nontraffic accident, sequela',
  'V4313XA Car passenger injured in collision with pick-up in nontraffic accident, initial encounter',
  'V4313XD Car passenger injured in collision with pick-up in nontraffic accident, subsequent encounter',
  'V4313XS Car passenger injured in collision with pick-up in nontraffic accident, sequela',
  'V4314XA Car passenger injured in collision with van in nontraffic accident, initial encounter',
  'V4314XD Car passenger injured in collision with van in nontraffic accident, subsequent encounter',
  'V4314XS Car passenger injured in collision with van in nontraffic accident, sequela',
  'V4321XA Person on outside of car injured in collision with sport utility vehicle in nontraffic accident,',
  'initial encounter',
  'V4321XD Person on outside of car injured in collision with sport utility vehicle in nontraffic accident,',
  'subsequent encounter',
  'V4321XS Person on outside of car injured in collision with sport utility vehicle in nontraffic accident,',
  'sequela',
  'V4322XA Person on outside of car injured in collision with other type car in nontraffic accident, initial',
  'encounter',
  'V4322XD Person on outside of car injured in collision with other type car in nontraffic accident,',
  'subsequent encounter',
  'V4322XS Person on outside of car injured in collision with other type car in nontraffic accident, sequela',
  'V4323XA Person on outside of car injured in collision with pick-up truck in nontraffic accident, initial',
  'encounter',
  'V4323XD Person on outside of car injured in collision with pick-up truck in nontraffic accident,',
  'subsequent encounter',
  'V4323XS Person on outside of car injured in collision with pick-up truck in nontraffic accident, sequela',
  'V4324XA Person on outside of car injured in collision with van in nontraffic accident, initial encounter',
  'V4324XD Person on outside of car injured in collision with van in nontraffic accident, subsequent',
  'encounter',
  'V4324XS Person on outside of car injured in collision with van in nontraffic accident, sequela',
  'V4331XA Unspecified car occupant injured in collision with sport utility vehicle in nontraffic accident,',
  'initial encounter',
  'V4331XD Unspecified car occupant injured in collision with sport utility vehicle in nontraffic accident,',
  'subsequent encounter',
  'V4331XS Unspecified car occupant injured in collision with sport utility vehicle in nontraffic accident,',
  'sequela',
  'V4332XA Unspecified car occupant injured in collision with other type car in nontraffic accident, initial',
  'encounter',
  'V4332XD Unspecified car occupant injured in collision with other type car in nontraffic accident,',
  'subsequent encounter',
  'V4332XS Unspecified car occupant injured in collision with other type car in nontraffic accident, sequela',
  'V4333XA Unspecified car occupant injured in collision with pick-up truck in nontraffic accident, initial',
  'encounter',
  'V4333XD Unspecified car occupant injured in collision with pick-up truck in nontraffic accident,',
  'subsequent encounter',
  'V4333XS Unspecified car occupant injured in collision with pick-up truck in nontraffic accident, sequela',
  'V4334XA Unspecified car occupant injured in collision with van in nontraffic accident, initial encounter',
  'V4334XD Unspecified car occupant injured in collision with van in nontraffic accident, subsequent',
  'encounter',
  'V4334XS Unspecified car occupant injured in collision with van in nontraffic accident, sequela',
  'V4341XA Person boarding or alighting a car injured in collision with sport utility vehicle, initial encounter',
  'V4341XD Person boarding or alighting a car injured in collision with sport utility vehicle, subsequent',
  'encounter',
  'V4341XS Person boarding or alighting a car injured in collision with sport utility vehicle, sequela',
  'V4342XA Person boarding or alighting a car injured in collision with other type car, initial encounter',
  'V4342XD Person boarding or alighting a car injured in collision with other type car, subsequent',
  'encounter',
  'V4342XS Person boarding or alighting a car injured in collision with other type car, sequela',
  'V4343XA Person boarding or alighting a car injured in collision with pick-up truck, initial encounter',
  'V4343XD Person boarding or alighting a car injured in collision with pick-up truck, subsequent encounter',
  'V4343XS Person boarding or alighting a car injured in collision with pick-up truck, sequela',
  'V4344XA Person boarding or alighting a car injured in collision with van, initial encounter',
  'V4344XD Person boarding or alighting a car injured in collision with van, subsequent encounter',
  'V4344XS Person boarding or alighting a car injured in collision with van, sequela',
  'V4351XA Car driver injured in collision with sport utility vehicle in traffic accident, initial encounter',
  'V4351XD Car driver injured in collision with sport utility vehicle in traffic accident, subsequent',
  'encounter',
  'V4351XS Car driver injured in collision with sport utility vehicle in traffic accident, sequela',
  'V4352XA Car driver injured in collision with other type car in traffic accident, initial encounter',
  'V4352XD Car driver injured in collision with other type car in traffic accident, subsequent encounter',
  'V4352XS Car driver injured in collision with other type car in traffic accident, sequela',
  'V4353XA Car driver injured in collision with pick-up truck in traffic accident, initial encounter',
  'V4353XD Car driver injured in collision with pick-up truck in traffic accident, subsequent encounter',
  'V4353XS Car driver injured in collision with pick-up truck in traffic accident, sequela',
  'V4354XA Car driver injured in collision with van in traffic accident, initial encounter',
  'V4354XD Car driver injured in collision with van in traffic accident, subsequent encounter',
  'V4354XS Car driver injured in collision with van in traffic accident, sequela',
  'V4361XA Car passenger injured in collision with sport utility vehicle in traffic accident, initial encounter',
  'V4361XD Car passenger injured in collision with sport utility vehicle in traffic accident, subsequent',
  'encounter',
  'V4361XS Car passenger injured in collision with sport utility vehicle in traffic accident, sequela',
  'V4362XA Car passenger injured in collision with other type car in traffic accident, initial encounter',
  'V4362XD Car passenger injured in collision with other type car in traffic accident, subsequent encounter',
  'V4362XS Car passenger injured in collision with other type car in traffic accident, sequela',
  'V4363XA Car passenger injured in collision with pick-up truck in traffic accident, initial encounter',
  'V4363XD Car passenger injured in collision with pick-up truck in traffic accident, subsequent encounter',
  'V4363XS Car passenger injured in collision with pick-up truck in traffic accident, sequela',
  'V4364XA Car passenger injured in collision with van in traffic accident, initial encounter',
  'V4364XD Car passenger injured in collision with van in traffic accident, subsequent encounter',
  'V4364XS Car passenger injured in collision with van in traffic accident, sequela',
  'V4371XA Person on outside of car injured in collision with sport utility vehicle in traffic accident, initial',
  'encounter',
  'V4371XD Person on outside of car injured in collision with sport utility vehicle in traffic accident,',
  'subsequent encounter',
  'V4371XS Person on outside of car injured in collision with sport utility vehicle in traffic accident, sequela',
  'V4372XA Person on outside of car injured in collision with other type car in traffic accident, initial',
  'encounter',
  'V4372XD Person on outside of car injured in collision with other type car in traffic accident, subsequent',
  'encounter',
  'V4372XS Person on outside of car injured in collision with other type car in traffic accident, sequela',
  'V4373XA Person on outside of car injured in collision with pick-up truck in traffic accident, initial',
  'encounter',
  'V4373XD Person on outside of car injured in collision with pick-up truck in traffic accident, subsequent',
  'encounter',
  'V4373XS Person on outside of car injured in collision with pick-up truck in traffic accident, sequela',
  'V4374XA Person on outside of car injured in collision with van in traffic accident, initial encounter',
  'V4374XD Person on outside of car injured in collision with van in traffic accident, subsequent encounter',
  'V4374XS Person on outside of car injured in collision with van in traffic accident, sequela',
  'V4391XA Unspecified car occupant injured in collision with sport utility vehicle in traffic accident, initial',
  'encounter',
  'V4391XD Unspecified car occupant injured in collision with sport utility vehicle in traffic accident,',
  'subsequent encounter',
  'V4391XS Unspecified car occupant injured in collision with sport utility vehicle in traffic accident,',
  'sequela',
  'V4392XA Unspecified car occupant injured in collision with other type car in traffic accident, initial',
  'encounter',
  'V4392XD Unspecified car occupant injured in collision with other type car in traffic accident, subsequent',
  'encounter',
  'V4392XS Unspecified car occupant injured in collision with other type car in traffic accident, sequela',
  'V4393XA Unspecified car occupant injured in collision with pick-up truck in traffic accident, initial',
  'encounter',
  'V4393XD Unspecified car occupant injured in collision with pick-up truck in traffic accident, subsequent',
  'encounter',
  'V4393XS Unspecified car occupant injured in collision with pick-up truck in traffic accident, sequela',
  'V4394XA Unspecified car occupant injured in collision with van in traffic accident, initial encounter',
  'V4394XD Unspecified car occupant injured in collision with van in traffic accident, subsequent encounter',
  'V4394XS Unspecified car occupant injured in collision with van in traffic accident, sequela',
  'V440XXA Car driver injured in collision with heavy transport vehicle or bus in nontraffic accident, initial',
  'encounter',
  'V440XXD Car driver injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'subsequent encounter',
  'V440XXS Car driver injured in collision with heavy transport vehicle or bus in nontraffic accident, sequela',
  'V441XXA Car passenger injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'initial encounter',
  'V441XXD Car passenger injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'subsequent encounter',
  'V441XXS Car passenger injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'sequela',
  'V442XXA Person on outside of car injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V442XXD Person on outside of car injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V442XXS Person on outside of car injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V443XXA Unspecified car occupant injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V443XXD Unspecified car occupant injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V443XXS Unspecified car occupant injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V444XXA Person boarding or alighting a car injured in collision with heavy transport vehicle or bus, initial',
  'encounter',
  'V444XXD Person boarding or alighting a car injured in collision with heavy transport vehicle or bus,',
  'subsequent encounter',
  'V444XXS Person boarding or alighting a car injured in collision with heavy transport vehicle or bus,',
  'sequela',
  'V445XXA Car driver injured in collision with heavy transport vehicle or bus in traffic accident, initial',
  'encounter',
  'V445XXD Car driver injured in collision with heavy transport vehicle or bus in traffic accident,',
  'subsequent encounter',
  'V445XXS Car driver injured in collision with heavy transport vehicle or bus in traffic accident, sequela',
  'V446XXA Car passenger injured in collision with heavy transport vehicle or bus in traffic accident, initial',
  'encounter',
  'V446XXD Car passenger injured in collision with heavy transport vehicle or bus in traffic accident,',
  'subsequent encounter',
  'V446XXS Car passenger injured in collision with heavy transport vehicle or bus in traffic accident,',
  'sequela',
  'V447XXA Person on outside of car injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V447XXD Person on outside of car injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V447XXS Person on outside of car injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V449XXA Unspecified car occupant injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V449XXD Unspecified car occupant injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V449XXS Unspecified car occupant injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V450XXA Car driver injured in collision with railway train or railway vehicle in nontraffic accident, initial',
  'encounter',
  'V450XXD Car driver injured in collision with railway train or railway vehicle in nontraffic accident,',
  'subsequent encounter',
  'V450XXS Car driver injured in collision with railway train or railway vehicle in nontraffic accident,',
  'sequela',
  'V451XXA Car passenger injured in collision with railway train or railway vehicle in nontraffic accident,',
  'initial encounter',
  'V451XXD Car passenger injured in collision with railway train or railway vehicle in nontraffic accident,',
  'subsequent encounter',
  'V451XXS Car passenger injured in collision with railway train or railway vehicle in nontraffic accident,',
  'sequela',
  'V452XXA Person on outside of car injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V452XXD Person on outside of car injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V452XXS Person on outside of car injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V453XXA Unspecified car occupant injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V453XXD Unspecified car occupant injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V453XXS Unspecified car occupant injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V454XXA Person boarding or alighting a car injured in collision with railway train or railway vehicle,',
  'initial encounter',
  'V454XXD Person boarding or alighting a car injured in collision with railway train or railway vehicle,',
  'subsequent encounter',
  'V454XXS Person boarding or alighting a car injured in collision with railway train or railway vehicle,',
  'sequela',
  'V455XXA Car driver injured in collision with railway train or railway vehicle in traffic accident, initial',
  'encounter',
  'V455XXD Car driver injured in collision with railway train or railway vehicle in traffic accident,',
  'subsequent encounter',
  'V455XXS Car driver injured in collision with railway train or railway vehicle in traffic accident, sequela',
  'V456XXA Car passenger injured in collision with railway train or railway vehicle in traffic accident, initial',
  'encounter',
  'V456XXD Car passenger injured in collision with railway train or railway vehicle in traffic accident,',
  'subsequent encounter',
  'V456XXS Car passenger injured in collision with railway train or railway vehicle in traffic accident,',
  'sequela',
  'V457XXA Person on outside of car injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V457XXD Person on outside of car injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V457XXS Person on outside of car injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V459XXA Unspecified car occupant injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V459XXD Unspecified car occupant injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V459XXS Unspecified car occupant injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V460XXA Car driver injured in collision with other nonmotor vehicle in nontraffic accident, initial',
  'encounter',
  'V460XXD Car driver injured in collision with other nonmotor vehicle in nontraffic accident, subsequent',
  'encounter',
  'V460XXS Car driver injured in collision with other nonmotor vehicle in nontraffic accident, sequela',
  'V461XXA Car passenger injured in collision with other nonmotor vehicle in nontraffic accident, initial',
  'encounter',
  'V461XXD Car passenger injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V461XXS Car passenger injured in collision with other nonmotor vehicle in nontraffic accident, sequela',
  'V462XXA Person on outside of car injured in collision with other nonmotor vehicle in nontraffic accident,',
  'initial encounter',
  'V462XXD Person on outside of car injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V462XXS Person on outside of car injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V463XXA Unspecified car occupant injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V463XXD Unspecified car occupant injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V463XXS Unspecified car occupant injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V464XXA Person boarding or alighting a car injured in collision with other nonmotor vehicle, initial',
  'encounter',
  'V464XXD Person boarding or alighting a car injured in collision with other nonmotor vehicle, subsequent',
  'encounter',
  'V464XXS Person boarding or alighting a car injured in collision with other nonmotor vehicle, sequela',
  'V465XXA Car driver injured in collision with other nonmotor vehicle in traffic accident, initial encounter',
  'V465XXD Car driver injured in collision with other nonmotor vehicle in traffic accident, subsequent',
  'encounter',
  'V465XXS Car driver injured in collision with other nonmotor vehicle in traffic accident, sequela',
  'V466XXA Car passenger injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V466XXD Car passenger injured in collision with other nonmotor vehicle in traffic accident, subsequent',
  'encounter',
  'V466XXS Car passenger injured in collision with other nonmotor vehicle in traffic accident, sequela',
  'V467XXA Person on outside of car injured in collision with other nonmotor vehicle in traffic accident,',
  'initial encounter',
  'V467XXD Person on outside of car injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V467XXS Person on outside of car injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V469XXA Unspecified car occupant injured in collision with other nonmotor vehicle in traffic accident,',
  'initial encounter',
  'V469XXD Unspecified car occupant injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V469XXS Unspecified car occupant injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V470XXA Car driver injured in collision with fixed or stationary object in nontraffic accident, initial',
  'encounter',
  'V470XXD Car driver injured in collision with fixed or stationary object in nontraffic accident, subsequent',
  'encounter',
  'V470XXS Car driver injured in collision with fixed or stationary object in nontraffic accident, sequela',
  'V471XXA Car passenger injured in collision with fixed or stationary object in nontraffic accident, initial',
  'encounter',
  'V471XXD Car passenger injured in collision with fixed or stationary object in nontraffic accident,',
  'subsequent encounter',
  'V471XXS Car passenger injured in collision with fixed or stationary object in nontraffic accident, sequela',
  'V472XXA Person on outside of car injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V472XXD Person on outside of car injured in collision with fixed or stationary object in nontraffic',
  'accident, subsequent encounter',
  'V472XXS Person on outside of car injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V473XXA Unspecified car occupant injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V473XXD Unspecified car occupant injured in collision with fixed or stationary object in nontraffic',
  'accident, subsequent encounter',
  'V473XXS Unspecified car occupant injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V474XXA Person boarding or alighting a car injured in collision with fixed or stationary object, initial',
  'encounter',
  'V474XXD Person boarding or alighting a car injured in collision with fixed or stationary object,',
  'subsequent encounter',
  'V474XXS Person boarding or alighting a car injured in collision with fixed or stationary object, sequela',
  'V475XXA Car driver injured in collision with fixed or stationary object in traffic accident, initial encounter',
  'V475XXD Car driver injured in collision with fixed or stationary object in traffic accident, subsequent',
  'encounter',
  'V475XXS Car driver injured in collision with fixed or stationary object in traffic accident, sequela',
  'V476XXA Car passenger injured in collision with fixed or stationary object in traffic accident, initial',
  'encounter',
  'V476XXD Car passenger injured in collision with fixed or stationary object in traffic accident, subsequent',
  'encounter',
  'V476XXS Car passenger injured in collision with fixed or stationary object in traffic accident, sequela',
  'V477XXA Person on outside of car injured in collision with fixed or stationary object in traffic accident,',
  'initial encounter',
  'V477XXD Person on outside of car injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V477XXS Person on outside of car injured in collision with fixed or stationary object in traffic accident,',
  'sequela',
  'V479XXA Unspecified car occupant injured in collision with fixed or stationary object in traffic accident,',
  'initial encounter',
  'V479XXD Unspecified car occupant injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V479XXS Unspecified car occupant injured in collision with fixed or stationary object in traffic accident,',
  'sequela',
  'V480XXA Car driver injured in noncollision transport accident in nontraffic accident, initial encounter',
  'V480XXD Car driver injured in noncollision transport accident in nontraffic accident, subsequent',
  'encounter',
  'V480XXS Car driver injured in noncollision transport accident in nontraffic accident, sequela',
  'V481XXA Car passenger injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V481XXD Car passenger injured in noncollision transport accident in nontraffic accident, subsequent',
  'encounter',
  'V481XXS Car passenger injured in noncollision transport accident in nontraffic accident, sequela',
  'V482XXA Person on outside of car injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V482XXD Person on outside of car injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V482XXS Person on outside of car injured in noncollision transport accident in nontraffic accident,',
  'sequela',
  'V483XXA Unspecified car occupant injured in noncollision transport accident in nontraffic accident,',
  'initial encounter',
  'V483XXD Unspecified car occupant injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V483XXS Unspecified car occupant injured in noncollision transport accident in nontraffic accident,',
  'sequela',
  'V484XXA Person boarding or alighting a car injured in noncollision transport accident, initial encounter',
  'V484XXD Person boarding or alighting a car injured in noncollision transport accident, subsequent',
  'encounter',
  'V484XXS Person boarding or alighting a car injured in noncollision transport accident, sequela',
  'V485XXA Car driver injured in noncollision transport accident in traffic accident, initial encounter',
  'V485XXD Car driver injured in noncollision transport accident in traffic accident, subsequent encounter',
  'V485XXS Car driver injured in noncollision transport accident in traffic accident, sequela',
  'V486XXA Car passenger injured in noncollision transport accident in traffic accident, initial encounter',
  'V486XXD Car passenger injured in noncollision transport accident in traffic accident, subsequent',
  'encounter',
  'V486XXS Car passenger injured in noncollision transport accident in traffic accident, sequela',
  'V487XXA Person on outside of car injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V487XXD Person on outside of car injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V487XXS Person on outside of car injured in noncollision transport accident in traffic accident, sequela',
  'V489XXA Unspecified car occupant injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V489XXD Unspecified car occupant injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V489XXS Unspecified car occupant injured in noncollision transport accident in traffic accident, sequela',
  'V4900XA Driver injured in collision with unspecified motor vehicles in nontraffic accident, initial',
  'encounter',
  'V4900XD Driver injured in collision with unspecified motor vehicles in nontraffic accident, subsequent',
  'encounter',
  'V4900XS Driver injured in collision with unspecified motor vehicles in nontraffic accident, sequela',
  'V4909XA Driver injured in collision with other motor vehicles in nontraffic accident, initial encounter',
  'V4909XD Driver injured in collision with other motor vehicles in nontraffic accident, subsequent',
  'encounter',
  'V4909XS Driver injured in collision with other motor vehicles in nontraffic accident, sequela',
  'V4910XA Passenger injured in collision with unspecified motor vehicles in nontraffic accident, initial',
  'encounter',
  'V4910XD Passenger injured in collision with unspecified motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V4910XS Passenger injured in collision with unspecified motor vehicles in nontraffic accident, sequela',
  'V4919XA Passenger injured in collision with other motor vehicles in nontraffic accident, initial encounter',
  'V4919XD Passenger injured in collision with other motor vehicles in nontraffic accident, subsequent',
  'encounter',
  'V4919XS Passenger injured in collision with other motor vehicles in nontraffic accident, sequela',
  'V4920XA Unspecified car occupant injured in collision with unspecified motor vehicles in nontraffic',
  'accident, initial encounter',
  'V4920XD Unspecified car occupant injured in collision with unspecified motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V4920XS Unspecified car occupant injured in collision with unspecified motor vehicles in nontraffic',
  'accident, sequela',
  'V4929XA Unspecified car occupant injured in collision with other motor vehicles in nontraffic accident,',
  'initial encounter',
  'V4929XD Unspecified car occupant injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V4929XS Unspecified car occupant injured in collision with other motor vehicles in nontraffic accident,',
  'sequela',
  'V493XXA Car occupant (driver) (passenger) injured in unspecified nontraffic accident, initial encounter',
  'V493XXD Car occupant (driver) (passenger) injured in unspecified nontraffic accident, subsequent',
  'encounter',
  'V493XXS Car occupant (driver) (passenger) injured in unspecified nontraffic accident, sequela',
  'V4940XA Driver injured in collision with unspecified motor vehicles in traffic accident, initial encounter',
  'V4940XD Driver injured in collision with unspecified motor vehicles in traffic accident, subsequent',
  'encounter',
  'V4940XS Driver injured in collision with unspecified motor vehicles in traffic accident, sequela',
  'V4949XA Driver injured in collision with other motor vehicles in traffic accident, initial encounter',
  'V4949XD Driver injured in collision with other motor vehicles in traffic accident, subsequent encounter',
  'V4949XS Driver injured in collision with other motor vehicles in traffic accident, sequela',
  'V4950XA Passenger injured in collision with unspecified motor vehicles in traffic accident, initial',
  'encounter',
  'V4950XD Passenger injured in collision with unspecified motor vehicles in traffic accident, subsequent',
  'encounter',
  'V4950XS Passenger injured in collision with unspecified motor vehicles in traffic accident, sequela',
  'V4959XA Passenger injured in collision with other motor vehicles in traffic accident, initial encounter',
  'V4959XD Passenger injured in collision with other motor vehicles in traffic accident, subsequent',
  'encounter',
  'V4959XS Passenger injured in collision with other motor vehicles in traffic accident, sequela',
  'V4960XA Unspecified car occupant injured in collision with unspecified motor vehicles in traffic accident,',
  'initial encounter',
  'V4960XD Unspecified car occupant injured in collision with unspecified motor vehicles in traffic',
  'accident, subsequent encounter',
  'V4960XS Unspecified car occupant injured in collision with unspecified motor vehicles in traffic accident,',
  'sequela',
  'V4969XA Unspecified car occupant injured in collision with other motor vehicles in traffic accident,',
  'initial encounter',
  'V4969XD Unspecified car occupant injured in collision with other motor vehicles in traffic accident,',
  'subsequent encounter',
  'V4969XS Unspecified car occupant injured in collision with other motor vehicles in traffic accident,',
  'sequela',
  'V4981XA Car occupant (driver) (passenger) injured in transport accident with military vehicle, initial',
  'encounter',
  'V4981XD Car occupant (driver) (passenger) injured in transport accident with military vehicle,',
  'subsequent encounter',
  'V4981XS Car occupant (driver) (passenger) injured in transport accident with military vehicle, sequela',
  'V4988XA Car occupant (driver) (passenger) injured in other specified transport accidents, initial',
  'encounter',
  'V4988XD Car occupant (driver) (passenger) injured in other specified transport accidents, subsequent',
  'encounter',
  'V4988XS Car occupant (driver) (passenger) injured in other specified transport accidents, sequela',
  'V499XXA Car occupant (driver) (passenger) injured in unspecified traffic accident, initial encounter',
  'V499XXD Car occupant (driver) (passenger) injured in unspecified traffic accident, subsequent encounter',
  'V499XXS Car occupant (driver) (passenger) injured in unspecified traffic accident, sequela',
  'V500XXA Driver of pick-up truck or van injured in collision with pedestrian or animal in nontraffic',
  'accident, initial encounter',
  'V500XXD Driver of pick-up truck or van injured in collision with pedestrian or animal in nontraffic',
  'accident, subsequent encounter',
  'V500XXS Driver of pick-up truck or van injured in collision with pedestrian or animal in nontraffic',
  'accident, sequela',
  'V501XXA Passenger in pick-up truck or van injured in collision with pedestrian or animal in nontraffic',
  'accident, initial encounter',
  'V501XXD Passenger in pick-up truck or van injured in collision with pedestrian or animal in nontraffic',
  'accident, subsequent encounter',
  'V501XXS Passenger in pick-up truck or van injured in collision with pedestrian or animal in nontraffic',
  'accident, sequela',
  'V502XXA Person on outside of pick-up truck or van injured in collision with pedestrian or animal in',
  'nontraffic accident, initial encounter',
  'V502XXD Person on outside of pick-up truck or van injured in collision with pedestrian or animal in',
  'nontraffic accident, subsequent encounter',
  'V502XXS Person on outside of pick-up truck or van injured in collision with pedestrian or animal in',
  'nontraffic accident, sequela',
  'V503XXA Unspecified occupant of pick-up truck or van injured in collision with pedestrian or animal in',
  'nontraffic accident, initial encounter',
  'V503XXD Unspecified occupant of pick-up truck or van injured in collision with pedestrian or animal in',
  'nontraffic accident, subsequent encounter',
  'V503XXS Unspecified occupant of pick-up truck or van injured in collision with pedestrian or animal in',
  'nontraffic accident, sequela',
  'V504XXA Person boarding or alighting a pick-up truck or van injured in collision with pedestrian or',
  'animal, initial encounter',
  'V504XXD Person boarding or alighting a pick-up truck or van injured in collision with pedestrian or',
  'animal, subsequent encounter',
  'V504XXS Person boarding or alighting a pick-up truck or van injured in collision with pedestrian or',
  'animal, sequela',
  'V505XXA Driver of pick-up truck or van injured in collision with pedestrian or animal in traffic accident,',
  'initial encounter',
  'V505XXD Driver of pick-up truck or van injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V505XXS Driver of pick-up truck or van injured in collision with pedestrian or animal in traffic accident,',
  'sequela',
  'V506XXA Passenger in pick-up truck or van injured in collision with pedestrian or animal in traffic',
  'accident, initial encounter',
  'V506XXD Passenger in pick-up truck or van injured in collision with pedestrian or animal in traffic',
  'accident, subsequent encounter',
  'V506XXS Passenger in pick-up truck or van injured in collision with pedestrian or animal in traffic',
  'accident, sequela',
  'V507XXA Person on outside of pick-up truck or van injured in collision with pedestrian or animal in',
  'traffic accident, initial encounter',
  'V507XXD Person on outside of pick-up truck or van injured in collision with pedestrian or animal in',
  'traffic accident, subsequent encounter',
  'V507XXS Person on outside of pick-up truck or van injured in collision with pedestrian or animal in traffic',
  'accident, sequela',
  'V509XXA Unspecified occupant of pick-up truck or van injured in collision with pedestrian or animal in',
  'traffic accident, initial encounter',
  'V509XXD Unspecified occupant of pick-up truck or van injured in collision with pedestrian or animal in',
  'traffic accident, subsequent encounter',
  'V509XXS Unspecified occupant of pick-up truck or van injured in collision with pedestrian or animal in',
  'traffic accident, sequela',
  'V510XXA Driver of pick-up truck or van injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V510XXD Driver of pick-up truck or van injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V510XXS Driver of pick-up truck or van injured in collision with pedal cycle in nontraffic accident,',
  'sequela',
  'V511XXA Passenger in pick-up truck or van injured in collision with pedal cycle in nontraffic accident,',
  'initial encounter',
  'V511XXD Passenger in pick-up truck or van injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V511XXS Passenger in pick-up truck or van injured in collision with pedal cycle in nontraffic accident,',
  'sequela',
  'V512XXA Person on outside of pick-up truck or van injured in collision with pedal cycle in nontraffic',
  'accident, initial encounter',
  'V512XXD Person on outside of pick-up truck or van injured in collision with pedal cycle in nontraffic',
  'accident, subsequent encounter',
  'V512XXS Person on outside of pick-up truck or van injured in collision with pedal cycle in nontraffic',
  'accident, sequela',
  'V513XXA Unspecified occupant of pick-up truck or van injured in collision with pedal cycle in nontraffic',
  'accident, initial encounter',
  'V513XXD Unspecified occupant of pick-up truck or van injured in collision with pedal cycle in nontraffic',
  'accident, subsequent encounter',
  'V513XXS Unspecified occupant of pick-up truck or van injured in collision with pedal cycle in nontraffic',
  'accident, sequela',
  'V514XXA Person boarding or alighting a pick-up truck or van injured in collision with pedal cycle, initial',
  'encounter',
  'V514XXD Person boarding or alighting a pick-up truck or van injured in collision with pedal cycle,',
  'subsequent encounter',
  'V514XXS Person boarding or alighting a pick-up truck or van injured in collision with pedal cycle, sequela',
  'V515XXA Driver of pick-up truck or van injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V515XXD Driver of pick-up truck or van injured in collision with pedal cycle in traffic accident,',
  'subsequent encounter',
  'V515XXS Driver of pick-up truck or van injured in collision with pedal cycle in traffic accident, sequela',
  'V516XXA Passenger in pick-up truck or van injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V516XXD Passenger in pick-up truck or van injured in collision with pedal cycle in traffic accident,',
  'subsequent encounter',
  'V516XXS Passenger in pick-up truck or van injured in collision with pedal cycle in traffic accident, sequela',
  'V517XXA Person on outside of pick-up truck or van injured in collision with pedal cycle in traffic',
  'accident, initial encounter',
  'V517XXD Person on outside of pick-up truck or van injured in collision with pedal cycle in traffic',
  'accident, subsequent encounter',
  'V517XXS Person on outside of pick-up truck or van injured in collision with pedal cycle in traffic accident,',
  'sequela',
  'V519XXA Unspecified occupant of pick-up truck or van injured in collision with pedal cycle in traffic',
  'accident, initial encounter',
  'V519XXD Unspecified occupant of pick-up truck or van injured in collision with pedal cycle in traffic',
  'accident, subsequent encounter',
  'V519XXS Unspecified occupant of pick-up truck or van injured in collision with pedal cycle in traffic',
  'accident, sequela',
  'V520XXA Driver of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V520XXD Driver of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V520XXS Driver of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V521XXA Passenger in pick-up truck or van injured in collision with two- or three-wheeled motor vehicle',
  'in nontraffic accident, initial encounter',
  'V521XXD Passenger in pick-up truck or van injured in collision with two- or three-wheeled motor vehicle',
  'in nontraffic accident, subsequent encounter',
  'V521XXS Passenger in pick-up truck or van injured in collision with two- or three-wheeled motor vehicle',
  'in nontraffic accident, sequela',
  'V522XXA Person on outside of pick-up truck or van injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, initial encounter',
  'V522XXD Person on outside of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, subsequent encounter',
  'V522XXS Person on outside of pick-up truck or van injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, sequela',
  'V523XXA Unspecified occupant of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, initial encounter',
  'V523XXD Unspecified occupant of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, subsequent encounter',
  'V523XXS Unspecified occupant of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, sequela',
  'V524XXA Person boarding or alighting a pick-up truck or van injured in collision with two- or threewheeled motor vehicle, initial encounter',
  'V524XXD Person boarding or alighting a pick-up truck or van injured in collision with two- or threewheeled motor vehicle, subsequent encounter',
  'V524XXS Person boarding or alighting a pick-up truck or van injured in collision with two- or threewheeled motor vehicle, sequela',
  'V525XXA Driver of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, initial encounter',
  'V525XXD Driver of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, subsequent encounter',
  'V525XXS Driver of pick-up truck or van injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, sequela',
  'V526XXA Passenger in pick-up truck or van injured in collision with two- or three-wheeled motor vehicle',
  'in traffic accident, initial encounter',
  'V526XXD Passenger in pick-up truck or van injured in collision with two- or three-wheeled motor vehicle',
  'in traffic accident, subsequent encounter',
  'V526XXS Passenger in pick-up truck or van injured in collision with two- or three-wheeled motor vehicle',
  'in traffic accident, sequela',
  'V527XXA Person on outside of pick-up truck or van injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, initial encounter',
  'V527XXD Person on outside of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, subsequent encounter',
  'V527XXS Person on outside of pick-up truck or van injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, sequela',
  'V529XXA Unspecified occupant of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, initial encounter',
  'V529XXD Unspecified occupant of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, subsequent encounter',
  'V529XXS Unspecified occupant of pick-up truck or van injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, sequela',
  'V530XXA Driver of pick-up truck or van injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V530XXD Driver of pick-up truck or van injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V530XXS Driver of pick-up truck or van injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V531XXA Passenger in pick-up truck or van injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V531XXD Passenger in pick-up truck or van injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V531XXS Passenger in pick-up truck or van injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V532XXA Person on outside of pick-up truck or van injured in collision with car, pick-up truck or van in',
  'nontraffic accident, initial encounter',
  'V532XXD Person on outside of pick-up truck or van injured in collision with car, pick-up truck or van in',
  'nontraffic accident, subsequent encounter',
  'V532XXS Person on outside of pick-up truck or van injured in collision with car, pick-up truck or van in',
  'nontraffic accident, sequela',
  'V533XXA Unspecified occupant of pick-up truck or van injured in collision with car, pick-up truck or van',
  'in nontraffic accident, initial encounter',
  'V533XXD Unspecified occupant of pick-up truck or van injured in collision with car, pick-up truck or van',
  'in nontraffic accident, subsequent encounter',
  'V533XXS Unspecified occupant of pick-up truck or van injured in collision with car, pick-up truck or van',
  'in nontraffic accident, sequela',
  'V534XXA Person boarding or alighting a pick-up truck or van injured in collision with car, pick-up truck or',
  'van, initial encounter',
  'V534XXD Person boarding or alighting a pick-up truck or van injured in collision with car, pick-up truck or',
  'van, subsequent encounter',
  'V534XXS Person boarding or alighting a pick-up truck or van injured in collision with car, pick-up truck or',
  'van, sequela',
  'V535XXA Driver of pick-up truck or van injured in collision with car, pick-up truck or van in traffic',
  'accident, initial encounter',
  'V535XXD Driver of pick-up truck or van injured in collision with car, pick-up truck or van in traffic',
  'accident, subsequent encounter',
  'V535XXS Driver of pick-up truck or van injured in collision with car, pick-up truck or van in traffic',
  'accident, sequela',
  'V536XXA Passenger in pick-up truck or van injured in collision with car, pick-up truck or van in traffic',
  'accident, initial encounter',
  'V536XXD Passenger in pick-up truck or van injured in collision with car, pick-up truck or van in traffic',
  'accident, subsequent encounter',
  'V536XXS Passenger in pick-up truck or van injured in collision with car, pick-up truck or van in traffic',
  'accident, sequela',
  'V537XXA Person on outside of pick-up truck or van injured in collision with car, pick-up truck or van in',
  'traffic accident, initial encounter',
  'V537XXD Person on outside of pick-up truck or van injured in collision with car, pick-up truck or van in',
  'traffic accident, subsequent encounter',
  'V537XXS Person on outside of pick-up truck or van injured in collision with car, pick-up truck or van in',
  'traffic accident, sequela',
  'V539XXA Unspecified occupant of pick-up truck or van injured in collision with car, pick-up truck or van',
  'in traffic accident, initial encounter',
  'V539XXD Unspecified occupant of pick-up truck or van injured in collision with car, pick-up truck or van',
  'in traffic accident, subsequent encounter',
  'V539XXS Unspecified occupant of pick-up truck or van injured in collision with car, pick-up truck or van',
  'in traffic accident, sequela',
  'V540XXA Driver of pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, initial encounter',
  'V540XXD Driver of pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, subsequent encounter',
  'V540XXS Driver of pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, sequela',
  'V541XXA Passenger in pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, initial encounter',
  'V541XXD Passenger in pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, subsequent encounter',
  'V541XXS Passenger in pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, sequela',
  'V542XXA Person on outside of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, initial encounter',
  'V542XXD Person on outside of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, subsequent encounter',
  'V542XXS Person on outside of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, sequela',
  'V543XXA Unspecified occupant of pick-up truck or van injured in collision with heavy transport vehicle',
  'or bus in nontraffic accident, initial encounter',
  'V543XXD Unspecified occupant of pick-up truck or van injured in collision with heavy transport vehicle',
  'or bus in nontraffic accident, subsequent encounter',
  'V543XXS Unspecified occupant of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, sequela',
  'V544XXA Person boarding or alighting a pick-up truck or van injured in collision with heavy transport',
  'vehicle or bus, initial encounter',
  'V544XXD Person boarding or alighting a pick-up truck or van injured in collision with heavy transport',
  'vehicle or bus, subsequent encounter',
  'V544XXS Person boarding or alighting a pick-up truck or van injured in collision with heavy transport',
  'vehicle or bus, sequela',
  'V545XXA Driver of pick-up truck or van injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V545XXD Driver of pick-up truck or van injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V545XXS Driver of pick-up truck or van injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V546XXA Passenger in pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'traffic accident, initial encounter',
  'V546XXD Passenger in pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'traffic accident, subsequent encounter',
  'V546XXS Passenger in pick-up truck or van injured in collision with heavy transport vehicle or bus in',
  'traffic accident, sequela',
  'V547XXA Person on outside of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in traffic accident, initial encounter',
  'V547XXD Person on outside of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in traffic accident, subsequent encounter',
  'V547XXS Person on outside of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in traffic accident, sequela',
  'V549XXA Unspecified occupant of pick-up truck or van injured in collision with heavy transport vehicle',
  'or bus in traffic accident, initial encounter',
  'V549XXD Unspecified occupant of pick-up truck or van injured in collision with heavy transport vehicle',
  'or bus in traffic accident, subsequent encounter',
  'V549XXS Unspecified occupant of pick-up truck or van injured in collision with heavy transport vehicle or',
  'bus in traffic accident, sequela',
  'V550XXA Driver of pick-up truck or van injured in collision with railway train or railway vehicle in',
  'nontraffic accident, initial encounter',
  'V550XXD Driver of pick-up truck or van injured in collision with railway train or railway vehicle in',
  'nontraffic accident, subsequent encounter',
  'V550XXS Driver of pick-up truck or van injured in collision with railway train or railway vehicle in',
  'nontraffic accident, sequela',
  'V551XXA Passenger in pick-up truck or van injured in collision with railway train or railway vehicle in',
  'nontraffic accident, initial encounter',
  'V551XXD Passenger in pick-up truck or van injured in collision with railway train or railway vehicle in',
  'nontraffic accident, subsequent encounter',
  'V551XXS Passenger in pick-up truck or van injured in collision with railway train or railway vehicle in',
  'nontraffic accident, sequela',
  'V552XXA Person on outside of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in nontraffic accident, initial encounter',
  'V552XXD Person on outside of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in nontraffic accident, subsequent encounter',
  'V552XXS Person on outside of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in nontraffic accident, sequela',
  'V553XXA Unspecified occupant of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in nontraffic accident, initial encounter',
  'V553XXD Unspecified occupant of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in nontraffic accident, subsequent encounter',
  'V553XXS Unspecified occupant of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in nontraffic accident, sequela',
  'V554XXA Person boarding or alighting a pick-up truck or van injured in collision with railway train or',
  'railway vehicle, initial encounter',
  'V554XXD Person boarding or alighting a pick-up truck or van injured in collision with railway train or',
  'railway vehicle, subsequent encounter',
  'V554XXS Person boarding or alighting a pick-up truck or van injured in collision with railway train or',
  'railway vehicle, sequela',
  'V555XXA Driver of pick-up truck or van injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V555XXD Driver of pick-up truck or van injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V555XXS Driver of pick-up truck or van injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V556XXA Passenger in pick-up truck or van injured in collision with railway train or railway vehicle in',
  'traffic accident, initial encounter',
  'V556XXD Passenger in pick-up truck or van injured in collision with railway train or railway vehicle in',
  'traffic accident, subsequent encounter',
  'V556XXS Passenger in pick-up truck or van injured in collision with railway train or railway vehicle in',
  'traffic accident, sequela',
  'V557XXA Person on outside of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in traffic accident, initial encounter',
  'V557XXD Person on outside of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in traffic accident, subsequent encounter',
  'V557XXS Person on outside of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in traffic accident, sequela',
  'V559XXA Unspecified occupant of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in traffic accident, initial encounter',
  'V559XXD Unspecified occupant of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in traffic accident, subsequent encounter',
  'V559XXS Unspecified occupant of pick-up truck or van injured in collision with railway train or railway',
  'vehicle in traffic accident, sequela',
  'V560XXA Driver of pick-up truck or van injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V560XXD Driver of pick-up truck or van injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V560XXS Driver of pick-up truck or van injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V561XXA Passenger in pick-up truck or van injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V561XXD Passenger in pick-up truck or van injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V561XXS Passenger in pick-up truck or van injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V562XXA Person on outside of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'nontraffic accident, initial encounter',
  'V562XXD Person on outside of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V562XXS Person on outside of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'nontraffic accident, sequela',
  'V563XXA Unspecified occupant of pick-up truck or van injured in collision with other nonmotor vehicle',
  'in nontraffic accident, initial encounter',
  'V563XXD Unspecified occupant of pick-up truck or van injured in collision with other nonmotor vehicle',
  'in nontraffic accident, subsequent encounter',
  'V563XXS Unspecified occupant of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'nontraffic accident, sequela',
  'V564XXA Person boarding or alighting a pick-up truck or van injured in collision with other nonmotor',
  'vehicle, initial encounter',
  'V564XXD Person boarding or alighting a pick-up truck or van injured in collision with other nonmotor',
  'vehicle, subsequent encounter',
  'V564XXS Person boarding or alighting a pick-up truck or van injured in collision with other nonmotor',
  'vehicle, sequela',
  'V565XXA Driver of pick-up truck or van injured in collision with other nonmotor vehicle in traffic',
  'accident, initial encounter',
  'V565XXD Driver of pick-up truck or van injured in collision with other nonmotor vehicle in traffic',
  'accident, subsequent encounter',
  'V565XXS Driver of pick-up truck or van injured in collision with other nonmotor vehicle in traffic',
  'accident, sequela',
  'V566XXA Passenger in pick-up truck or van injured in collision with other nonmotor vehicle in traffic',
  'accident, initial encounter',
  'V566XXD Passenger in pick-up truck or van injured in collision with other nonmotor vehicle in traffic',
  'accident, subsequent encounter',
  'V566XXS Passenger in pick-up truck or van injured in collision with other nonmotor vehicle in traffic',
  'accident, sequela',
  'V567XXA Person on outside of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'traffic accident, initial encounter',
  'V567XXD Person on outside of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'traffic accident, subsequent encounter',
  'V567XXS Person on outside of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'traffic accident, sequela',
  'V569XXA Unspecified occupant of pick-up truck or van injured in collision with other nonmotor vehicle',
  'in traffic accident, initial encounter',
  'V569XXD Unspecified occupant of pick-up truck or van injured in collision with other nonmotor vehicle',
  'in traffic accident, subsequent encounter',
  'V569XXS Unspecified occupant of pick-up truck or van injured in collision with other nonmotor vehicle in',
  'traffic accident, sequela',
  'V570XXA Driver of pick-up truck or van injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V570XXD Driver of pick-up truck or van injured in collision with fixed or stationary object in nontraffic',
  'accident, subsequent encounter',
  'V570XXS Driver of pick-up truck or van injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V571XXA Passenger in pick-up truck or van injured in collision with fixed or stationary object in',
  'nontraffic accident, initial encounter',
  'V571XXD Passenger in pick-up truck or van injured in collision with fixed or stationary object in',
  'nontraffic accident, subsequent encounter',
  'V571XXS Passenger in pick-up truck or van injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V572XXA Person on outside of pick-up truck or van injured in collision with fixed or stationary object in',
  'nontraffic accident, initial encounter',
  'V572XXD Person on outside of pick-up truck or van injured in collision with fixed or stationary object in',
  'nontraffic accident, subsequent encounter',
  'V572XXS Person on outside of pick-up truck or van injured in collision with fixed or stationary object in',
  'nontraffic accident, sequela',
  'V573XXA Unspecified occupant of pick-up truck or van injured in collision with fixed or stationary object',
  'in nontraffic accident, initial encounter',
  'V573XXD Unspecified occupant of pick-up truck or van injured in collision with fixed or stationary object',
  'in nontraffic accident, subsequent encounter',
  'V573XXS Unspecified occupant of pick-up truck or van injured in collision with fixed or stationary object',
  'in nontraffic accident, sequela',
  'V574XXA Person boarding or alighting a pick-up truck or van injured in collision with fixed or stationary',
  'object, initial encounter',
  'V574XXD Person boarding or alighting a pick-up truck or van injured in collision with fixed or stationary',
  'object, subsequent encounter',
  'V574XXS Person boarding or alighting a pick-up truck or van injured in collision with fixed or stationary',
  'object, sequela',
  'V575XXA Driver of pick-up truck or van injured in collision with fixed or stationary object in traffic',
  'accident, initial encounter',
  'V575XXD Driver of pick-up truck or van injured in collision with fixed or stationary object in traffic',
  'accident, subsequent encounter',
  'V575XXS Driver of pick-up truck or van injured in collision with fixed or stationary object in traffic',
  'accident, sequela',
  'V576XXA Passenger in pick-up truck or van injured in collision with fixed or stationary object in traffic',
  'accident, initial encounter',
  'V576XXD Passenger in pick-up truck or van injured in collision with fixed or stationary object in traffic',
  'accident, subsequent encounter',
  'V576XXS Passenger in pick-up truck or van injured in collision with fixed or stationary object in traffic',
  'accident, sequela',
  'V577XXA Person on outside of pick-up truck or van injured in collision with fixed or stationary object in',
  'traffic accident, initial encounter',
  'V577XXD Person on outside of pick-up truck or van injured in collision with fixed or stationary object in',
  'traffic accident, subsequent encounter',
  'V577XXS Person on outside of pick-up truck or van injured in collision with fixed or stationary object in',
  'traffic accident, sequela',
  'V579XXA Unspecified occupant of pick-up truck or van injured in collision with fixed or stationary object',
  'in traffic accident, initial encounter',
  'V579XXD Unspecified occupant of pick-up truck or van injured in collision with fixed or stationary object',
  'in traffic accident, subsequent encounter',
  'V579XXS Unspecified occupant of pick-up truck or van injured in collision with fixed or stationary object',
  'in traffic accident, sequela',
  'V580XXA Driver of pick-up truck or van injured in noncollision transport accident in nontraffic accident,',
  'initial encounter',
  'V580XXD Driver of pick-up truck or van injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V580XXS Driver of pick-up truck or van injured in noncollision transport accident in nontraffic accident,',
  'sequela',
  'V581XXA Passenger in pick-up truck or van injured in noncollision transport accident in nontraffic',
  'accident, initial encounter',
  'V581XXD Passenger in pick-up truck or van injured in noncollision transport accident in nontraffic',
  'accident, subsequent encounter',
  'V581XXS Passenger in pick-up truck or van injured in noncollision transport accident in nontraffic',
  'accident, sequela',
  'V582XXA Person on outside of pick-up truck or van injured in noncollision transport accident in',
  'nontraffic accident, initial encounter',
  'V582XXD Person on outside of pick-up truck or van injured in noncollision transport accident in',
  'nontraffic accident, subsequent encounter',
  'V582XXS Person on outside of pick-up truck or van injured in noncollision transport accident in',
  'nontraffic accident, sequela',
  'V583XXA Unspecified occupant of pick-up truck or van injured in noncollision transport accident in',
  'nontraffic accident, initial encounter',
  'V583XXD Unspecified occupant of pick-up truck or van injured in noncollision transport accident in',
  'nontraffic accident, subsequent encounter',
  'V583XXS Unspecified occupant of pick-up truck or van injured in noncollision transport accident in',
  'nontraffic accident, sequela',
  'V584XXA Person boarding or alighting a pick-up truck or van injured in noncollision transport accident,',
  'initial encounter',
  'V584XXD Person boarding or alighting a pick-up truck or van injured in noncollision transport accident,',
  'subsequent encounter',
  'V584XXS Person boarding or alighting a pick-up truck or van injured in noncollision transport accident,',
  'sequela',
  'V585XXA Driver of pick-up truck or van injured in noncollision transport accident in traffic accident,',
  'initial encounter',
  'V585XXD Driver of pick-up truck or van injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V585XXS Driver of pick-up truck or van injured in noncollision transport accident in traffic accident,',
  'sequela',
  'V586XXA Passenger in pick-up truck or van injured in noncollision transport accident in traffic accident,',
  'initial encounter',
  'V586XXD Passenger in pick-up truck or van injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V586XXS Passenger in pick-up truck or van injured in noncollision transport accident in traffic accident,',
  'sequela',
  'V587XXA Person on outside of pick-up truck or van injured in noncollision transport accident in traffic',
  'accident, initial encounter',
  'V587XXD Person on outside of pick-up truck or van injured in noncollision transport accident in traffic',
  'accident, subsequent encounter',
  'V587XXS Person on outside of pick-up truck or van injured in noncollision transport accident in traffic',
  'accident, sequela',
  'V589XXA Unspecified occupant of pick-up truck or van injured in noncollision transport accident in',
  'traffic accident, initial encounter',
  'V589XXD Unspecified occupant of pick-up truck or van injured in noncollision transport accident in',
  'traffic accident, subsequent encounter',
  'V589XXS Unspecified occupant of pick-up truck or van injured in noncollision transport accident in traffic',
  'accident, sequela',
  'V5900XA Driver of pick-up truck or van injured in collision with unspecified motor vehicles in nontraffic',
  'accident, initial encounter',
  'V5900XD Driver of pick-up truck or van injured in collision with unspecified motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V5900XS Driver of pick-up truck or van injured in collision with unspecified motor vehicles in nontraffic',
  'accident, sequela',
  'V5909XA Driver of pick-up truck or van injured in collision with other motor vehicles in nontraffic',
  'accident, initial encounter',
  'V5909XD Driver of pick-up truck or van injured in collision with other motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V5909XS Driver of pick-up truck or van injured in collision with other motor vehicles in nontraffic',
  'accident, sequela',
  'V5910XA Passenger in pick-up truck or van injured in collision with unspecified motor vehicles in',
  'nontraffic accident, initial encounter',
  'V5910XD Passenger in pick-up truck or van injured in collision with unspecified motor vehicles in',
  'nontraffic accident, subsequent encounter',
  'V5910XS Passenger in pick-up truck or van injured in collision with unspecified motor vehicles in',
  'nontraffic accident, sequela',
  'V5919XA Passenger in pick-up truck or van injured in collision with other motor vehicles in nontraffic',
  'accident, initial encounter',
  'V5919XD Passenger in pick-up truck or van injured in collision with other motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V5919XS Passenger in pick-up truck or van injured in collision with other motor vehicles in nontraffic',
  'accident, sequela',
  'V5920XA Unspecified occupant of pick-up truck or van injured in collision with unspecified motor',
  'vehicles in nontraffic accident, initial encounter',
  'V5920XD Unspecified occupant of pick-up truck or van injured in collision with unspecified motor',
  'vehicles in nontraffic accident, subsequent encounter',
  'V5920XS Unspecified occupant of pick-up truck or van injured in collision with unspecified motor',
  'vehicles in nontraffic accident, sequela',
  'V5929XA Unspecified occupant of pick-up truck or van injured in collision with other motor vehicles in',
  'nontraffic accident, initial encounter',
  'V5929XD Unspecified occupant of pick-up truck or van injured in collision with other motor vehicles in',
  'nontraffic accident, subsequent encounter',
  'V5929XS Unspecified occupant of pick-up truck or van injured in collision with other motor vehicles in',
  'nontraffic accident, sequela',
  'V593XXA Occupant (driver) (passenger) of pick-up truck or van injured in unspecified nontraffic accident,',
  'initial encounter',
  'V593XXD Occupant (driver) (passenger) of pick-up truck or van injured in unspecified nontraffic',
  'accident, subsequent encounter',
  'V593XXS Occupant (driver) (passenger) of pick-up truck or van injured in unspecified nontraffic accident,',
  'sequela',
  'V5940XA Driver of pick-up truck or van injured in collision with unspecified motor vehicles in traffic',
  'accident, initial encounter',
  'V5940XD Driver of pick-up truck or van injured in collision with unspecified motor vehicles in traffic',
  'accident, subsequent encounter',
  'V5940XS Driver of pick-up truck or van injured in collision with unspecified motor vehicles in traffic',
  'accident, sequela',
  'V5949XA Driver of pick-up truck or van injured in collision with other motor vehicles in traffic accident,',
  'initial encounter',
  'V5949XD Driver of pick-up truck or van injured in collision with other motor vehicles in traffic accident,',
  'subsequent encounter',
  'V5949XS Driver of pick-up truck or van injured in collision with other motor vehicles in traffic accident,',
  'sequela',
  'V5950XA Passenger in pick-up truck or van injured in collision with unspecified motor vehicles in traffic',
  'accident, initial encounter',
  'V5950XD Passenger in pick-up truck or van injured in collision with unspecified motor vehicles in traffic',
  'accident, subsequent encounter',
  'V5950XS Passenger in pick-up truck or van injured in collision with unspecified motor vehicles in traffic',
  'accident, sequela',
  'V5959XA Passenger in pick-up truck or van injured in collision with other motor vehicles in traffic',
  'accident, initial encounter',
  'V5959XD Passenger in pick-up truck or van injured in collision with other motor vehicles in traffic',
  'accident, subsequent encounter',
  'V5959XS Passenger in pick-up truck or van injured in collision with other motor vehicles in traffic',
  'accident, sequela',
  'V5960XA Unspecified occupant of pick-up truck or van injured in collision with unspecified motor',
  'vehicles in traffic accident, initial encounter',
  'V5960XD Unspecified occupant of pick-up truck or van injured in collision with unspecified motor',
  'vehicles in traffic accident, subsequent encounter',
  'V5960XS Unspecified occupant of pick-up truck or van injured in collision with unspecified motor',
  'vehicles in traffic accident, sequela',
  'V5969XA Unspecified occupant of pick-up truck or van injured in collision with other motor vehicles in',
  'traffic accident, initial encounter',
  'V5969XD Unspecified occupant of pick-up truck or van injured in collision with other motor vehicles in',
  'traffic accident, subsequent encounter',
  'V5969XS Unspecified occupant of pick-up truck or van injured in collision with other motor vehicles in',
  'traffic accident, sequela',
  'V5981XA Occupant (driver) (passenger) of pick-up truck or van injured in transport accident with military',
  'vehicle, initial encounter',
  'V5981XD Occupant (driver) (passenger) of pick-up truck or van injured in transport accident with',
  'military vehicle, subsequent encounter',
  'V5981XS Occupant (driver) (passenger) of pick-up truck or van injured in transport accident with military',
  'vehicle, sequela',
  'V5988XA Occupant (driver) (passenger) of pick-up truck or van injured in other specified transport',
  'accidents, initial encounter',
  'V5988XD Occupant (driver) (passenger) of pick-up truck or van injured in other specified transport',
  'accidents, subsequent encounter',
  'V5988XS Occupant (driver) (passenger) of pick-up truck or van injured in other specified transport',
  'accidents, sequela',
  'V599XXA Occupant (driver) (passenger) of pick-up truck or van injured in unspecified traffic accident,',
  'initial encounter',
  'V599XXD Occupant (driver) (passenger) of pick-up truck or van injured in unspecified traffic accident,',
  'subsequent encounter',
  'V599XXS Occupant (driver) (passenger) of pick-up truck or van injured in unspecified traffic accident,',
  'sequela',
  'V600XXA Driver of heavy transport vehicle injured in collision with pedestrian or animal in nontraffic',
  'accident, initial encounter',
  'V600XXD Driver of heavy transport vehicle injured in collision with pedestrian or animal in nontraffic',
  'accident, subsequent encounter',
  'V600XXS Driver of heavy transport vehicle injured in collision with pedestrian or animal in nontraffic',
  'accident, sequela',
  'V601XXA Passenger in heavy transport vehicle injured in collision with pedestrian or animal in nontraffic',
  'accident, initial encounter',
  'V601XXD Passenger in heavy transport vehicle injured in collision with pedestrian or animal in nontraffic',
  'accident, subsequent encounter',
  'V601XXS Passenger in heavy transport vehicle injured in collision with pedestrian or animal in nontraffic',
  'accident, sequela',
  'V602XXA Person on outside of heavy transport vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, initial encounter',
  'V602XXD Person on outside of heavy transport vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, subsequent encounter',
  'V602XXS Person on outside of heavy transport vehicle injured in collision with pedestrian or animal in',
  'nontraffic accident, sequela',
  'V603XXA Unspecified occupant of heavy transport vehicle injured in collision with pedestrian or animal',
  'in nontraffic accident, initial encounter',
  'V603XXD Unspecified occupant of heavy transport vehicle injured in collision with pedestrian or animal',
  'in nontraffic accident, subsequent encounter',
  'V603XXS Unspecified occupant of heavy transport vehicle injured in collision with pedestrian or animal',
  'in nontraffic accident, sequela',
  'V604XXA Person boarding or alighting a heavy transport vehicle injured in collision with pedestrian or',
  'animal, initial encounter',
  'V604XXD Person boarding or alighting a heavy transport vehicle injured in collision with pedestrian or',
  'animal, subsequent encounter',
  'V604XXS Person boarding or alighting a heavy transport vehicle injured in collision with pedestrian or',
  'animal, sequela',
  'V605XXA Driver of heavy transport vehicle injured in collision with pedestrian or animal in traffic',
  'accident, initial encounter',
  'V605XXD Driver of heavy transport vehicle injured in collision with pedestrian or animal in traffic',
  'accident, subsequent encounter',
  'V605XXS Driver of heavy transport vehicle injured in collision with pedestrian or animal in traffic',
  'accident, sequela',
  'V606XXA Passenger in heavy transport vehicle injured in collision with pedestrian or animal in traffic',
  'accident, initial encounter',
  'V606XXD Passenger in heavy transport vehicle injured in collision with pedestrian or animal in traffic',
  'accident, subsequent encounter',
  'V606XXS Passenger in heavy transport vehicle injured in collision with pedestrian or animal in traffic',
  'accident, sequela',
  'V607XXA Person on outside of heavy transport vehicle injured in collision with pedestrian or animal in',
  'traffic accident, initial encounter',
  'V607XXD Person on outside of heavy transport vehicle injured in collision with pedestrian or animal in',
  'traffic accident, subsequent encounter',
  'V607XXS Person on outside of heavy transport vehicle injured in collision with pedestrian or animal in',
  'traffic accident, sequela',
  'V609XXA Unspecified occupant of heavy transport vehicle injured in collision with pedestrian or animal',
  'in traffic accident, initial encounter',
  'V609XXD Unspecified occupant of heavy transport vehicle injured in collision with pedestrian or animal',
  'in traffic accident, subsequent encounter',
  'V609XXS Unspecified occupant of heavy transport vehicle injured in collision with pedestrian or animal',
  'in traffic accident, sequela',
  'V610XXA Driver of heavy transport vehicle injured in collision with pedal cycle in nontraffic accident,',
  'initial encounter',
  'V610XXD Driver of heavy transport vehicle injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V610XXS Driver of heavy transport vehicle injured in collision with pedal cycle in nontraffic accident,',
  'sequela',
  'V611XXA Passenger in heavy transport vehicle injured in collision with pedal cycle in nontraffic accident,',
  'initial encounter',
  'V611XXD Passenger in heavy transport vehicle injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V611XXS Passenger in heavy transport vehicle injured in collision with pedal cycle in nontraffic accident,',
  'sequela',
  'V612XXA Person on outside of heavy transport vehicle injured in collision with pedal cycle in nontraffic',
  'accident, initial encounter',
  'V612XXD Person on outside of heavy transport vehicle injured in collision with pedal cycle in nontraffic',
  'accident, subsequent encounter',
  'V612XXS Person on outside of heavy transport vehicle injured in collision with pedal cycle in nontraffic',
  'accident, sequela',
  'V613XXA Unspecified occupant of heavy transport vehicle injured in collision with pedal cycle in',
  'nontraffic accident, initial encounter',
  'V613XXD Unspecified occupant of heavy transport vehicle injured in collision with pedal cycle in',
  'nontraffic accident, subsequent encounter',
  'V613XXS Unspecified occupant of heavy transport vehicle injured in collision with pedal cycle in',
  'nontraffic accident, sequela',
  'V614XXA Person boarding or alighting a heavy transport vehicle injured in collision with pedal cycle',
  'while boarding or alighting, initial encounter',
  'V614XXD Person boarding or alighting a heavy transport vehicle injured in collision with pedal cycle',
  'while boarding or alighting, subsequent encounter',
  'V614XXS Person boarding or alighting a heavy transport vehicle injured in collision with pedal cycle',
  'while boarding or alighting, sequela',
  'V615XXA Driver of heavy transport vehicle injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V615XXD Driver of heavy transport vehicle injured in collision with pedal cycle in traffic accident,',
  'subsequent encounter',
  'V615XXS Driver of heavy transport vehicle injured in collision with pedal cycle in traffic accident, sequela',
  'V616XXA Passenger in heavy transport vehicle injured in collision with pedal cycle in traffic accident,',
  'initial encounter',
  'V616XXD Passenger in heavy transport vehicle injured in collision with pedal cycle in traffic accident,',
  'subsequent encounter',
  'V616XXS Passenger in heavy transport vehicle injured in collision with pedal cycle in traffic accident,',
  'sequela',
  'V617XXA Person on outside of heavy transport vehicle injured in collision with pedal cycle in traffic',
  'accident, initial encounter',
  'V617XXD Person on outside of heavy transport vehicle injured in collision with pedal cycle in traffic',
  'accident, subsequent encounter',
  'V617XXS Person on outside of heavy transport vehicle injured in collision with pedal cycle in traffic',
  'accident, sequela',
  'V619XXA Unspecified occupant of heavy transport vehicle injured in collision with pedal cycle in traffic',
  'accident, initial encounter',
  'V619XXD Unspecified occupant of heavy transport vehicle injured in collision with pedal cycle in traffic',
  'accident, subsequent encounter',
  'V619XXS Unspecified occupant of heavy transport vehicle injured in collision with pedal cycle in traffic',
  'accident, sequela',
  'V620XXA Driver of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle',
  'in nontraffic accident, initial encounter',
  'V620XXD Driver of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle',
  'in nontraffic accident, subsequent encounter',
  'V620XXS Driver of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle',
  'in nontraffic accident, sequela',
  'V621XXA Passenger in heavy transport vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, initial encounter',
  'V621XXD Passenger in heavy transport vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, subsequent encounter',
  'V621XXS Passenger in heavy transport vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in nontraffic accident, sequela',
  'V622XXA Person on outside of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, initial encounter',
  'V622XXD Person on outside of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, subsequent encounter',
  'V622XXS Person on outside of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, sequela',
  'V623XXA Unspecified occupant of heavy transport vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, initial encounter',
  'V623XXD Unspecified occupant of heavy transport vehicle injured in collision with two- or threewheeled motor vehicle in nontraffic accident, subsequent encounter',
  'V623XXS Unspecified occupant of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in nontraffic accident, sequela',
  'V624XXA Person boarding or alighting a heavy transport vehicle injured in collision with two- or threewheeled motor vehicle, initial encounter',
  'V624XXD Person boarding or alighting a heavy transport vehicle injured in collision with two- or threewheeled motor vehicle, subsequent encounter',
  'V624XXS Person boarding or alighting a heavy transport vehicle injured in collision with two- or threewheeled motor vehicle, sequela',
  'V625XXA Driver of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle',
  'in traffic accident, initial encounter',
  'V625XXD Driver of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle',
  'in traffic accident, subsequent encounter',
  'V625XXS Driver of heavy transport vehicle injured in collision with two- or three-wheeled motor vehicle',
  'in traffic accident, sequela',
  'V626XXA Passenger in heavy transport vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, initial encounter',
  'V626XXD Passenger in heavy transport vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, subsequent encounter',
  'V626XXS Passenger in heavy transport vehicle injured in collision with two- or three-wheeled motor',
  'vehicle in traffic accident, sequela',
  'V627XXA Person on outside of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, initial encounter',
  'V627XXD Person on outside of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, subsequent encounter',
  'V627XXS Person on outside of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, sequela',
  'V629XXA Unspecified occupant of heavy transport vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, initial encounter',
  'V629XXD Unspecified occupant of heavy transport vehicle injured in collision with two- or threewheeled motor vehicle in traffic accident, subsequent encounter',
  'V629XXS Unspecified occupant of heavy transport vehicle injured in collision with two- or three-wheeled',
  'motor vehicle in traffic accident, sequela',
  'V630XXA Driver of heavy transport vehicle injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V630XXD Driver of heavy transport vehicle injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V630XXS Driver of heavy transport vehicle injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V631XXA Passenger in heavy transport vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, initial encounter',
  'V631XXD Passenger in heavy transport vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, subsequent encounter',
  'V631XXS Passenger in heavy transport vehicle injured in collision with car, pick-up truck or van in',
  'nontraffic accident, sequela',
  'V632XXA Person on outside of heavy transport vehicle injured in collision with car, pick-up truck or van',
  'in nontraffic accident, initial encounter',
  'V632XXD Person on outside of heavy transport vehicle injured in collision with car, pick-up truck or van',
  'in nontraffic accident, subsequent encounter',
  'V632XXS Person on outside of heavy transport vehicle injured in collision with car, pick-up truck or van',
  'in nontraffic accident, sequela',
  'V633XXA Unspecified occupant of heavy transport vehicle injured in collision with car, pick-up truck or',
  'van in nontraffic accident, initial encounter',
  'V633XXD Unspecified occupant of heavy transport vehicle injured in collision with car, pick-up truck or',
  'van in nontraffic accident, subsequent encounter',
  'V633XXS Unspecified occupant of heavy transport vehicle injured in collision with car, pick-up truck or',
  'van in nontraffic accident, sequela',
  'V634XXA Person boarding or alighting a heavy transport vehicle injured in collision with car, pick-up',
  'truck or van, initial encounter',
  'V634XXD Person boarding or alighting a heavy transport vehicle injured in collision with car, pick-up',
  'truck or van, subsequent encounter',
  'V634XXS Person boarding or alighting a heavy transport vehicle injured in collision with car, pick-up',
  'truck or van, sequela',
  'V635XXA Driver of heavy transport vehicle injured in collision with car, pick-up truck or van in traffic',
  'accident, initial encounter',
  'V635XXD Driver of heavy transport vehicle injured in collision with car, pick-up truck or van in traffic',
  'accident, subsequent encounter',
  'V635XXS Driver of heavy transport vehicle injured in collision with car, pick-up truck or van in traffic',
  'accident, sequela',
  'V636XXA Passenger in heavy transport vehicle injured in collision with car, pick-up truck or van in traffic',
  'accident, initial encounter',
  'V636XXD Passenger in heavy transport vehicle injured in collision with car, pick-up truck or van in traffic',
  'accident, subsequent encounter',
  'V636XXS Passenger in heavy transport vehicle injured in collision with car, pick-up truck or van in traffic',
  'accident, sequela',
  'V637XXA Person on outside of heavy transport vehicle injured in collision with car, pick-up truck or van',
  'in traffic accident, initial encounter',
  'V637XXD Person on outside of heavy transport vehicle injured in collision with car, pick-up truck or van',
  'in traffic accident, subsequent encounter',
  'V637XXS Person on outside of heavy transport vehicle injured in collision with car, pick-up truck or van',
  'in traffic accident, sequela',
  'V639XXA Unspecified occupant of heavy transport vehicle injured in collision with car, pick-up truck or',
  'van in traffic accident, initial encounter',
  'V639XXD Unspecified occupant of heavy transport vehicle injured in collision with car, pick-up truck or',
  'van in traffic accident, subsequent encounter',
  'V639XXS Unspecified occupant of heavy transport vehicle injured in collision with car, pick-up truck or',
  'van in traffic accident, sequela',
  'V640XXA Driver of heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, initial encounter',
  'V640XXD Driver of heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, subsequent encounter',
  'V640XXS Driver of heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, sequela',
  'V641XXA Passenger in heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, initial encounter',
  'V641XXD Passenger in heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, subsequent encounter',
  'V641XXS Passenger in heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, sequela',
  'V642XXA Person on outside of heavy transport vehicle injured in collision with heavy transport vehicle',
  'or bus in nontraffic accident, initial encounter',
  'V642XXD Person on outside of heavy transport vehicle injured in collision with heavy transport vehicle',
  'or bus in nontraffic accident, subsequent encounter',
  'V642XXS Person on outside of heavy transport vehicle injured in collision with heavy transport vehicle or',
  'bus in nontraffic accident, sequela',
  'V643XXA Unspecified occupant of heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, initial encounter',
  'V643XXD Unspecified occupant of heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, subsequent encounter',
  'V643XXS Unspecified occupant of heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus in nontraffic accident, sequela',
  'V644XXA Person boarding or alighting a heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus while boarding or alighting, initial encounter',
  'V644XXD Person boarding or alighting a heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus while boarding or alighting, subsequent encounter',
  'V644XXS Person boarding or alighting a heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus while boarding or alighting, sequela',
  'V645XXA Driver of heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'traffic accident, initial encounter',
  'V645XXD Driver of heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'traffic accident, subsequent encounter',
  'V645XXS Driver of heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'traffic accident, sequela',
  'V646XXA Passenger in heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'traffic accident, initial encounter',
  'V646XXD Passenger in heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'traffic accident, subsequent encounter',
  'V646XXS Passenger in heavy transport vehicle injured in collision with heavy transport vehicle or bus in',
  'traffic accident, sequela',
  'V647XXA Person on outside of heavy transport vehicle injured in collision with heavy transport vehicle',
  'or bus in traffic accident, initial encounter',
  'V647XXD Person on outside of heavy transport vehicle injured in collision with heavy transport vehicle',
  'or bus in traffic accident, subsequent encounter',
  'V647XXS Person on outside of heavy transport vehicle injured in collision with heavy transport vehicle or',
  'bus in traffic accident, sequela',
  'V649XXA Unspecified occupant of heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, initial encounter',
  'V649XXD Unspecified occupant of heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, subsequent encounter',
  'V649XXS Unspecified occupant of heavy transport vehicle injured in collision with heavy transport',
  'vehicle or bus in traffic accident, sequela',
  'V650XXA Driver of heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'nontraffic accident, initial encounter',
  'V650XXD Driver of heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'nontraffic accident, subsequent encounter',
  'V650XXS Driver of heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'nontraffic accident, sequela',
  'V651XXA Passenger in heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'nontraffic accident, initial encounter',
  'V651XXD Passenger in heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'nontraffic accident, subsequent encounter',
  'V651XXS Passenger in heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'nontraffic accident, sequela',
  'V652XXA Person on outside of heavy transport vehicle injured in collision with railway train or railway',
  'vehicle in nontraffic accident, initial encounter',
  'V652XXD Person on outside of heavy transport vehicle injured in collision with railway train or railway',
  'vehicle in nontraffic accident, subsequent encounter',
  'V652XXS Person on outside of heavy transport vehicle injured in collision with railway train or railway',
  'vehicle in nontraffic accident, sequela',
  'V653XXA Unspecified occupant of heavy transport vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, initial encounter',
  'V653XXD Unspecified occupant of heavy transport vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, subsequent encounter',
  'V653XXS Unspecified occupant of heavy transport vehicle injured in collision with railway train or',
  'railway vehicle in nontraffic accident, sequela',
  'V654XXA Person boarding or alighting a heavy transport vehicle injured in collision with railway train or',
  'railway vehicle, initial encounter',
  'V654XXD Person boarding or alighting a heavy transport vehicle injured in collision with railway train or',
  'railway vehicle, subsequent encounter',
  'V654XXS Person boarding or alighting a heavy transport vehicle injured in collision with railway train or',
  'railway vehicle, sequela',
  'V655XXA Driver of heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'traffic accident, initial encounter',
  'V655XXD Driver of heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'traffic accident, subsequent encounter',
  'V655XXS Driver of heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'traffic accident, sequela',
  'V656XXA Passenger in heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'traffic accident, initial encounter',
  'V656XXD Passenger in heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'traffic accident, subsequent encounter',
  'V656XXS Passenger in heavy transport vehicle injured in collision with railway train or railway vehicle in',
  'traffic accident, sequela',
  'V657XXA Person on outside of heavy transport vehicle injured in collision with railway train or railway',
  'vehicle in traffic accident, initial encounter',
  'V657XXD Person on outside of heavy transport vehicle injured in collision with railway train or railway',
  'vehicle in traffic accident, subsequent encounter',
  'V657XXS Person on outside of heavy transport vehicle injured in collision with railway train or railway',
  'vehicle in traffic accident, sequela',
  'V659XXA Unspecified occupant of heavy transport vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, initial encounter',
  'V659XXD Unspecified occupant of heavy transport vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, subsequent encounter',
  'V659XXS Unspecified occupant of heavy transport vehicle injured in collision with railway train or',
  'railway vehicle in traffic accident, sequela',
  'V660XXA Driver of heavy transport vehicle injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V660XXD Driver of heavy transport vehicle injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V660XXS Driver of heavy transport vehicle injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V661XXA Passenger in heavy transport vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, initial encounter',
  'V661XXD Passenger in heavy transport vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V661XXS Passenger in heavy transport vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, sequela',
  'V662XXA Person on outside of heavy transport vehicle injured in collision with other nonmotor vehicle',
  'in nontraffic accident, initial encounter',
  'V662XXD Person on outside of heavy transport vehicle injured in collision with other nonmotor vehicle',
  'in nontraffic accident, subsequent encounter',
  'V662XXS Person on outside of heavy transport vehicle injured in collision with other nonmotor vehicle in',
  'nontraffic accident, sequela',
  'V663XXA Unspecified occupant of heavy transport vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, initial encounter',
  'V663XXD Unspecified occupant of heavy transport vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, subsequent encounter',
  'V663XXS Unspecified occupant of heavy transport vehicle injured in collision with other nonmotor',
  'vehicle in nontraffic accident, sequela',
  'V664XXA Person boarding or alighting a heavy transport vehicle injured in collision with other nonmotor',
  'vehicle, initial encounter',
  'V664XXD Person boarding or alighting a heavy transport vehicle injured in collision with other nonmotor',
  'vehicle, subsequent encounter',
  'V664XXS Person boarding or alighting a heavy transport vehicle injured in collision with other nonmotor',
  'vehicle, sequela',
  'V665XXA Driver of heavy transport vehicle injured in collision with other nonmotor vehicle in traffic',
  'accident, initial encounter',
  'V665XXD Driver of heavy transport vehicle injured in collision with other nonmotor vehicle in traffic',
  'accident, subsequent encounter',
  'V665XXS Driver of heavy transport vehicle injured in collision with other nonmotor vehicle in traffic',
  'accident, sequela',
  'V666XXA Passenger in heavy transport vehicle injured in collision with other nonmotor vehicle in traffic',
  'accident, initial encounter',
  'V666XXD Passenger in heavy transport vehicle injured in collision with other nonmotor vehicle in traffic',
  'accident, subsequent encounter',
  'V666XXS Passenger in heavy transport vehicle injured in collision with other nonmotor vehicle in traffic',
  'accident, sequela',
  'V667XXA Person on outside of heavy transport vehicle injured in collision with other nonmotor vehicle',
  'in traffic accident, initial encounter',
  'V667XXD Person on outside of heavy transport vehicle injured in collision with other nonmotor vehicle',
  'in traffic accident, subsequent encounter',
  'V667XXS Person on outside of heavy transport vehicle injured in collision with other nonmotor vehicle in',
  'traffic accident, sequela',
  'V669XXA Unspecified occupant of heavy transport vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, initial encounter',
  'V669XXD Unspecified occupant of heavy transport vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, subsequent encounter',
  'V669XXS Unspecified occupant of heavy transport vehicle injured in collision with other nonmotor',
  'vehicle in traffic accident, sequela',
  'V670XXA Driver of heavy transport vehicle injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V670XXD Driver of heavy transport vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, subsequent encounter',
  'V670XXS Driver of heavy transport vehicle injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V671XXA Passenger in heavy transport vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, initial encounter',
  'V671XXD Passenger in heavy transport vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, subsequent encounter',
  'V671XXS Passenger in heavy transport vehicle injured in collision with fixed or stationary object in',
  'nontraffic accident, sequela',
  'V672XXA Person on outside of heavy transport vehicle injured in collision with fixed or stationary object',
  'in nontraffic accident, initial encounter',
  'V672XXD Person on outside of heavy transport vehicle injured in collision with fixed or stationary object',
  'in nontraffic accident, subsequent encounter',
  'V672XXS Person on outside of heavy transport vehicle injured in collision with fixed or stationary object',
  'in nontraffic accident, sequela',
  'V673XXA Unspecified occupant of heavy transport vehicle injured in collision with fixed or stationary',
  'object in nontraffic accident, initial encounter',
  'V673XXD Unspecified occupant of heavy transport vehicle injured in collision with fixed or stationary',
  'object in nontraffic accident, subsequent encounter',
  'V673XXS Unspecified occupant of heavy transport vehicle injured in collision with fixed or stationary',
  'object in nontraffic accident, sequela',
  'V674XXA Person boarding or alighting a heavy transport vehicle injured in collision with fixed or',
  'stationary object, initial encounter',
  'V674XXD Person boarding or alighting a heavy transport vehicle injured in collision with fixed or',
  'stationary object, subsequent encounter',
  'V674XXS Person boarding or alighting a heavy transport vehicle injured in collision with fixed or',
  'stationary object, sequela',
  'V675XXA Driver of heavy transport vehicle injured in collision with fixed or stationary object in traffic',
  'accident, initial encounter',
  'V675XXD Driver of heavy transport vehicle injured in collision with fixed or stationary object in traffic',
  'accident, subsequent encounter',
  'V675XXS Driver of heavy transport vehicle injured in collision with fixed or stationary object in traffic',
  'accident, sequela',
  'V676XXA Passenger in heavy transport vehicle injured in collision with fixed or stationary object in traffic',
  'accident, initial encounter',
  'V676XXD Passenger in heavy transport vehicle injured in collision with fixed or stationary object in traffic',
  'accident, subsequent encounter',
  'V676XXS Passenger in heavy transport vehicle injured in collision with fixed or stationary object in traffic',
  'accident, sequela',
  'V677XXA Person on outside of heavy transport vehicle injured in collision with fixed or stationary object',
  'in traffic accident, initial encounter',
  'V677XXD Person on outside of heavy transport vehicle injured in collision with fixed or stationary object',
  'in traffic accident, subsequent encounter',
  'V677XXS Person on outside of heavy transport vehicle injured in collision with fixed or stationary object',
  'in traffic accident, sequela',
  'V679XXA Unspecified occupant of heavy transport vehicle injured in collision with fixed or stationary',
  'object in traffic accident, initial encounter',
  'V679XXD Unspecified occupant of heavy transport vehicle injured in collision with fixed or stationary',
  'object in traffic accident, subsequent encounter',
  'V679XXS Unspecified occupant of heavy transport vehicle injured in collision with fixed or stationary',
  'object in traffic accident, sequela',
  'V680XXA Driver of heavy transport vehicle injured in noncollision transport accident in nontraffic',
  'accident, initial encounter',
  'V680XXD Driver of heavy transport vehicle injured in noncollision transport accident in nontraffic',
  'accident, subsequent encounter',
  'V680XXS Driver of heavy transport vehicle injured in noncollision transport accident in nontraffic',
  'accident, sequela',
  'V681XXA Passenger in heavy transport vehicle injured in noncollision transport accident in nontraffic',
  'accident, initial encounter',
  'V681XXD Passenger in heavy transport vehicle injured in noncollision transport accident in nontraffic',
  'accident, subsequent encounter',
  'V681XXS Passenger in heavy transport vehicle injured in noncollision transport accident in nontraffic',
  'accident, sequela',
  'V682XXA Person on outside of heavy transport vehicle injured in noncollision transport accident in',
  'nontraffic accident, initial encounter',
  'V682XXD Person on outside of heavy transport vehicle injured in noncollision transport accident in',
  'nontraffic accident, subsequent encounter',
  'V682XXS Person on outside of heavy transport vehicle injured in noncollision transport accident in',
  'nontraffic accident, sequela',
  'V683XXA Unspecified occupant of heavy transport vehicle injured in noncollision transport accident in',
  'nontraffic accident, initial encounter',
  'V683XXD Unspecified occupant of heavy transport vehicle injured in noncollision transport accident in',
  'nontraffic accident, subsequent encounter',
  'V683XXS Unspecified occupant of heavy transport vehicle injured in noncollision transport accident in',
  'nontraffic accident, sequela',
  'V684XXA Person boarding or alighting a heavy transport vehicle injured in noncollision transport',
  'accident, initial encounter',
  'V684XXD Person boarding or alighting a heavy transport vehicle injured in noncollision transport',
  'accident, subsequent encounter',
  'V684XXS Person boarding or alighting a heavy transport vehicle injured in noncollision transport',
  'accident, sequela',
  'V685XXA Driver of heavy transport vehicle injured in noncollision transport accident in traffic accident,',
  'initial encounter',
  'V685XXD Driver of heavy transport vehicle injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V685XXS Driver of heavy transport vehicle injured in noncollision transport accident in traffic accident,',
  'sequela',
  'V686XXA Passenger in heavy transport vehicle injured in noncollision transport accident in traffic',
  'accident, initial encounter',
  'V686XXD Passenger in heavy transport vehicle injured in noncollision transport accident in traffic',
  'accident, subsequent encounter',
  'V686XXS Passenger in heavy transport vehicle injured in noncollision transport accident in traffic',
  'accident, sequela',
  'V687XXA Person on outside of heavy transport vehicle injured in noncollision transport accident in',
  'traffic accident, initial encounter',
  'V687XXD Person on outside of heavy transport vehicle injured in noncollision transport accident in',
  'traffic accident, subsequent encounter',
  'V687XXS Person on outside of heavy transport vehicle injured in noncollision transport accident in traffic',
  'accident, sequela',
  'V689XXA Unspecified occupant of heavy transport vehicle injured in noncollision transport accident in',
  'traffic accident, initial encounter',
  'V689XXD Unspecified occupant of heavy transport vehicle injured in noncollision transport accident in',
  'traffic accident, subsequent encounter',
  'V689XXS Unspecified occupant of heavy transport vehicle injured in noncollision transport accident in',
  'traffic accident, sequela',
  'V6900XA Driver of heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, initial encounter',
  'V6900XD Driver of heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, subsequent encounter',
  'V6900XS Driver of heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, sequela',
  'V6909XA Driver of heavy transport vehicle injured in collision with other motor vehicles in nontraffic',
  'accident, initial encounter',
  'V6909XD Driver of heavy transport vehicle injured in collision with other motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V6909XS Driver of heavy transport vehicle injured in collision with other motor vehicles in nontraffic',
  'accident, sequela',
  'V6910XA Passenger in heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, initial encounter',
  'V6910XD Passenger in heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, subsequent encounter',
  'V6910XS Passenger in heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'nontraffic accident, sequela',
  'V6919XA Passenger in heavy transport vehicle injured in collision with other motor vehicles in nontraffic',
  'accident, initial encounter',
  'V6919XD Passenger in heavy transport vehicle injured in collision with other motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V6919XS Passenger in heavy transport vehicle injured in collision with other motor vehicles in nontraffic',
  'accident, sequela',
  'V6920XA Unspecified occupant of heavy transport vehicle injured in collision with unspecified motor',
  'vehicles in nontraffic accident, initial encounter',
  'V6920XD Unspecified occupant of heavy transport vehicle injured in collision with unspecified motor',
  'vehicles in nontraffic accident, subsequent encounter',
  'V6920XS Unspecified occupant of heavy transport vehicle injured in collision with unspecified motor',
  'vehicles in nontraffic accident, sequela',
  'V6929XA Unspecified occupant of heavy transport vehicle injured in collision with other motor vehicles',
  'in nontraffic accident, initial encounter',
  'V6929XD Unspecified occupant of heavy transport vehicle injured in collision with other motor vehicles',
  'in nontraffic accident, subsequent encounter',
  'V6929XS Unspecified occupant of heavy transport vehicle injured in collision with other motor vehicles',
  'in nontraffic accident, sequela',
  'V693XXA Occupant (driver) (passenger) of heavy transport vehicle injured in unspecified nontraffic',
  'accident, initial encounter',
  'V693XXD Occupant (driver) (passenger) of heavy transport vehicle injured in unspecified nontraffic',
  'accident, subsequent encounter',
  'V693XXS Occupant (driver) (passenger) of heavy transport vehicle injured in unspecified nontraffic',
  'accident, sequela',
  'V6940XA Driver of heavy transport vehicle injured in collision with unspecified motor vehicles in traffic',
  'accident, initial encounter',
  'V6940XD Driver of heavy transport vehicle injured in collision with unspecified motor vehicles in traffic',
  'accident, subsequent encounter',
  'V6940XS Driver of heavy transport vehicle injured in collision with unspecified motor vehicles in traffic',
  'accident, sequela',
  'V6949XA Driver of heavy transport vehicle injured in collision with other motor vehicles in traffic',
  'accident, initial encounter',
  'V6949XD Driver of heavy transport vehicle injured in collision with other motor vehicles in traffic',
  'accident, subsequent encounter',
  'V6949XS Driver of heavy transport vehicle injured in collision with other motor vehicles in traffic',
  'accident, sequela',
  'V6950XA Passenger in heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'traffic accident, initial encounter',
  'V6950XD Passenger in heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'traffic accident, subsequent encounter',
  'V6950XS Passenger in heavy transport vehicle injured in collision with unspecified motor vehicles in',
  'traffic accident, sequela',
  'V6959XA Passenger in heavy transport vehicle injured in collision with other motor vehicles in traffic',
  'accident, initial encounter',
  'V6959XD Passenger in heavy transport vehicle injured in collision with other motor vehicles in traffic',
  'accident, subsequent encounter',
  'V6959XS Passenger in heavy transport vehicle injured in collision with other motor vehicles in traffic',
  'accident, sequela',
  'V6960XA Unspecified occupant of heavy transport vehicle injured in collision with unspecified motor',
  'vehicles in traffic accident, initial encounter',
  'V6960XD Unspecified occupant of heavy transport vehicle injured in collision with unspecified motor',
  'vehicles in traffic accident, subsequent encounter',
  'V6960XS Unspecified occupant of heavy transport vehicle injured in collision with unspecified motor',
  'vehicles in traffic accident, sequela',
  'V6969XA Unspecified occupant of heavy transport vehicle injured in collision with other motor vehicles',
  'in traffic accident, initial encounter',
  'V6969XD Unspecified occupant of heavy transport vehicle injured in collision with other motor vehicles',
  'in traffic accident, subsequent encounter',
  'V6969XS Unspecified occupant of heavy transport vehicle injured in collision with other motor vehicles',
  'in traffic accident, sequela',
  'V6981XA Occupant (driver) (passenger) of heavy transport vehicle injured in transport accidents with',
  'military vehicle, initial encounter',
  'V6981XD Occupant (driver) (passenger) of heavy transport vehicle injured in transport accidents with',
  'military vehicle, subsequent encounter',
  'V6981XS Occupant (driver) (passenger) of heavy transport vehicle injured in transport accidents with',
  'military vehicle, sequela',
  'V6988XA Occupant (driver) (passenger) of heavy transport vehicle injured in other specified transport',
  'accidents, initial encounter',
  'V6988XD Occupant (driver) (passenger) of heavy transport vehicle injured in other specified transport',
  'accidents, subsequent encounter',
  'V6988XS Occupant (driver) (passenger) of heavy transport vehicle injured in other specified transport',
  'accidents, sequela',
  'V699XXA Occupant (driver) (passenger) of heavy transport vehicle injured in unspecified traffic accident,',
  'initial encounter',
  'V699XXD Occupant (driver) (passenger) of heavy transport vehicle injured in unspecified traffic accident,',
  'subsequent encounter',
  'V699XXS Occupant (driver) (passenger) of heavy transport vehicle injured in unspecified traffic accident,',
  'sequela',
  'V700XXA Driver of bus injured in collision with pedestrian or animal in nontraffic accident, initial',
  'encounter',
  'V700XXD Driver of bus injured in collision with pedestrian or animal in nontraffic accident, subsequent',
  'encounter',
  'V700XXS Driver of bus injured in collision with pedestrian or animal in nontraffic accident, sequela',
  'V701XXA Passenger on bus injured in collision with pedestrian or animal in nontraffic accident, initial',
  'encounter',
  'V701XXD Passenger on bus injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V701XXS Passenger on bus injured in collision with pedestrian or animal in nontraffic accident, sequela',
  'V702XXA Person on outside of bus injured in collision with pedestrian or animal in nontraffic accident,',
  'initial encounter',
  'V702XXD Person on outside of bus injured in collision with pedestrian or animal in nontraffic accident,',
  'subsequent encounter',
  'V702XXS Person on outside of bus injured in collision with pedestrian or animal in nontraffic accident,',
  'sequela',
  'V703XXA Unspecified occupant of bus injured in collision with pedestrian or animal in nontraffic',
  'accident, initial encounter',
  'V703XXD Unspecified occupant of bus injured in collision with pedestrian or animal in nontraffic',
  'accident, subsequent encounter',
  'V703XXS Unspecified occupant of bus injured in collision with pedestrian or animal in nontraffic',
  'accident, sequela',
  'V704XXA Person boarding or alighting from bus injured in collision with pedestrian or animal, initial',
  'encounter',
  'V704XXD Person boarding or alighting from bus injured in collision with pedestrian or animal,',
  'subsequent encounter',
  'V704XXS Person boarding or alighting from bus injured in collision with pedestrian or animal, sequela',
  'V705XXA Driver of bus injured in collision with pedestrian or animal in traffic accident, initial encounter',
  'V705XXD Driver of bus injured in collision with pedestrian or animal in traffic accident, subsequent',
  'encounter',
  'V705XXS Driver of bus injured in collision with pedestrian or animal in traffic accident, sequela',
  'V706XXA Passenger on bus injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V706XXD Passenger on bus injured in collision with pedestrian or animal in traffic accident, subsequent',
  'encounter',
  'V706XXS Passenger on bus injured in collision with pedestrian or animal in traffic accident, sequela',
  'V707XXA Person on outside of bus injured in collision with pedestrian or animal in traffic accident, initial',
  'encounter',
  'V707XXD Person on outside of bus injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V707XXS Person on outside of bus injured in collision with pedestrian or animal in traffic accident,',
  'sequela',
  'V709XXA Unspecified occupant of bus injured in collision with pedestrian or animal in traffic accident,',
  'initial encounter',
  'V709XXD Unspecified occupant of bus injured in collision with pedestrian or animal in traffic accident,',
  'subsequent encounter',
  'V709XXS Unspecified occupant of bus injured in collision with pedestrian or animal in traffic accident,',
  'sequela',
  'V710XXA Driver of bus injured in collision with pedal cycle in nontraffic accident, initial encounter',
  'V710XXD Driver of bus injured in collision with pedal cycle in nontraffic accident, subsequent encounter',
  'V710XXS Driver of bus injured in collision with pedal cycle in nontraffic accident, sequela',
  'V711XXA Passenger on bus injured in collision with pedal cycle in nontraffic accident, initial encounter',
  'V711XXD Passenger on bus injured in collision with pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V711XXS Passenger on bus injured in collision with pedal cycle in nontraffic accident, sequela',
  'V712XXA Person on outside of bus injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V712XXD Person on outside of bus injured in collision with pedal cycle in nontraffic accident, subsequent',
  'encounter',
  'V712XXS Person on outside of bus injured in collision with pedal cycle in nontraffic accident, sequela',
  'V713XXA Unspecified occupant of bus injured in collision with pedal cycle in nontraffic accident, initial',
  'encounter',
  'V713XXD Unspecified occupant of bus injured in collision with pedal cycle in nontraffic accident,',
  'subsequent encounter',
  'V713XXS Unspecified occupant of bus injured in collision with pedal cycle in nontraffic accident, sequela',
  'V714XXA Person boarding or alighting from bus injured in collision with pedal cycle, initial encounter',
  'V714XXD Person boarding or alighting from bus injured in collision with pedal cycle, subsequent',
  'encounter',
  'V714XXS Person boarding or alighting from bus injured in collision with pedal cycle, sequela',
  'V715XXA Driver of bus injured in collision with pedal cycle in traffic accident, initial encounter',
  'V715XXD Driver of bus injured in collision with pedal cycle in traffic accident, subsequent encounter',
  'V715XXS Driver of bus injured in collision with pedal cycle in traffic accident, sequela',
  'V716XXA Passenger on bus injured in collision with pedal cycle in traffic accident, initial encounter',
  'V716XXD Passenger on bus injured in collision with pedal cycle in traffic accident, subsequent encounter',
  'V716XXS Passenger on bus injured in collision with pedal cycle in traffic accident, sequela',
  'V717XXA Person on outside of bus injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V717XXD Person on outside of bus injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V717XXS Person on outside of bus injured in collision with pedal cycle in traffic accident, sequela',
  'V719XXA Unspecified occupant of bus injured in collision with pedal cycle in traffic accident, initial',
  'encounter',
  'V719XXD Unspecified occupant of bus injured in collision with pedal cycle in traffic accident, subsequent',
  'encounter',
  'V719XXS Unspecified occupant of bus injured in collision with pedal cycle in traffic accident, sequela',
  'V720XXA Driver of bus injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, initial encounter',
  'V720XXD Driver of bus injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V720XXS Driver of bus injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, sequela',
  'V721XXA Passenger on bus injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, initial encounter',
  'V721XXD Passenger on bus injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V721XXS Passenger on bus injured in collision with two- or three-wheeled motor vehicle in nontraffic',
  'accident, sequela',
  'V722XXA Person on outside of bus injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V722XXD Person on outside of bus injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V722XXS Person on outside of bus injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V723XXA Unspecified occupant of bus injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, initial encounter',
  'V723XXD Unspecified occupant of bus injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, subsequent encounter',
  'V723XXS Unspecified occupant of bus injured in collision with two- or three-wheeled motor vehicle in',
  'nontraffic accident, sequela',
  'V724XXA Person boarding or alighting from bus injured in collision with two- or three-wheeled motor',
  'vehicle, initial encounter',
  'V724XXD Person boarding or alighting from bus injured in collision with two- or three-wheeled motor',
  'vehicle, subsequent encounter',
  'V724XXS Person boarding or alighting from bus injured in collision with two- or three-wheeled motor',
  'vehicle, sequela',
  'V725XXA Driver of bus injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'initial encounter',
  'V725XXD Driver of bus injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'subsequent encounter',
  'V725XXS Driver of bus injured in collision with two- or three-wheeled motor vehicle in traffic accident,',
  'sequela',
  'V726XXA Passenger on bus injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, initial encounter',
  'V726XXD Passenger on bus injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, subsequent encounter',
  'V726XXS Passenger on bus injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V727XXA Person on outside of bus injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, initial encounter',
  'V727XXD Person on outside of bus injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, subsequent encounter',
  'V727XXS Person on outside of bus injured in collision with two- or three-wheeled motor vehicle in traffic',
  'accident, sequela',
  'V729XXA Unspecified occupant of bus injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, initial encounter',
  'V729XXD Unspecified occupant of bus injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, subsequent encounter',
  'V729XXS Unspecified occupant of bus injured in collision with two- or three-wheeled motor vehicle in',
  'traffic accident, sequela',
  'V730XXA Driver of bus injured in collision with car, pick-up truck or van in nontraffic accident, initial',
  'encounter',
  'V730XXD Driver of bus injured in collision with car, pick-up truck or van in nontraffic accident,',
  'subsequent encounter',
  'V730XXS Driver of bus injured in collision with car, pick-up truck or van in nontraffic accident, sequela',
  'V731XXA Passenger on bus injured in collision with car, pick-up truck or van in nontraffic accident, initial',
  'encounter',
  'V731XXD Passenger on bus injured in collision with car, pick-up truck or van in nontraffic accident,',
  'subsequent encounter',
  'V731XXS Passenger on bus injured in collision with car, pick-up truck or van in nontraffic accident,',
  'sequela',
  'V732XXA Person on outside of bus injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V732XXD Person on outside of bus injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V732XXS Person on outside of bus injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V733XXA Unspecified occupant of bus injured in collision with car, pick-up truck or van in nontraffic',
  'accident, initial encounter',
  'V733XXD Unspecified occupant of bus injured in collision with car, pick-up truck or van in nontraffic',
  'accident, subsequent encounter',
  'V733XXS Unspecified occupant of bus injured in collision with car, pick-up truck or van in nontraffic',
  'accident, sequela',
  'V734XXA Person boarding or alighting from bus injured in collision with car, pick-up truck or van, initial',
  'encounter',
  'V734XXD Person boarding or alighting from bus injured in collision with car, pick-up truck or van,',
  'subsequent encounter',
  'V734XXS Person boarding or alighting from bus injured in collision with car, pick-up truck or van, sequela',
  'V735XXA Driver of bus injured in collision with car, pick-up truck or van in traffic accident, initial',
  'encounter',
  'V735XXD Driver of bus injured in collision with car, pick-up truck or van in traffic accident, subsequent',
  'encounter',
  'V735XXS Driver of bus injured in collision with car, pick-up truck or van in traffic accident, sequela',
  'V736XXA Passenger on bus injured in collision with car, pick-up truck or van in traffic accident, initial',
  'encounter',
  'V736XXD Passenger on bus injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V736XXS Passenger on bus injured in collision with car, pick-up truck or van in traffic accident, sequela',
  'V737XXA Person on outside of bus injured in collision with car, pick-up truck or van in traffic accident,',
  'initial encounter',
  'V737XXD Person on outside of bus injured in collision with car, pick-up truck or van in traffic accident,',
  'subsequent encounter',
  'V737XXS Person on outside of bus injured in collision with car, pick-up truck or van in traffic accident,',
  'sequela',
  'V739XXA Unspecified occupant of bus injured in collision with car, pick-up truck or van in traffic',
  'accident, initial encounter',
  'V739XXD Unspecified occupant of bus injured in collision with car, pick-up truck or van in traffic',
  'accident, subsequent encounter',
  'V739XXS Unspecified occupant of bus injured in collision with car, pick-up truck or van in traffic',
  'accident, sequela',
  'V740XXA Driver of bus injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'initial encounter',
  'V740XXD Driver of bus injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'subsequent encounter',
  'V740XXS Driver of bus injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'sequela',
  'V741XXA Passenger on bus injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'initial encounter',
  'V741XXD Passenger on bus injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'subsequent encounter',
  'V741XXS Passenger on bus injured in collision with heavy transport vehicle or bus in nontraffic accident,',
  'sequela',
  'V742XXA Person on outside of bus injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, initial encounter',
  'V742XXD Person on outside of bus injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, subsequent encounter',
  'V742XXS Person on outside of bus injured in collision with heavy transport vehicle or bus in nontraffic',
  'accident, sequela',
  'V743XXA Unspecified occupant of bus injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, initial encounter',
  'V743XXD Unspecified occupant of bus injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, subsequent encounter',
  'V743XXS Unspecified occupant of bus injured in collision with heavy transport vehicle or bus in',
  'nontraffic accident, sequela',
  'V744XXA Person boarding or alighting from bus injured in collision with heavy transport vehicle or bus,',
  'initial encounter',
  'V744XXD Person boarding or alighting from bus injured in collision with heavy transport vehicle or bus,',
  'subsequent encounter',
  'V744XXS Person boarding or alighting from bus injured in collision with heavy transport vehicle or bus,',
  'sequela',
  'V745XXA Driver of bus injured in collision with heavy transport vehicle or bus in traffic accident, initial',
  'encounter',
  'V745XXD Driver of bus injured in collision with heavy transport vehicle or bus in traffic accident,',
  'subsequent encounter',
  'V745XXS Driver of bus injured in collision with heavy transport vehicle or bus in traffic accident, sequela',
  'V746XXA Passenger on bus injured in collision with heavy transport vehicle or bus in traffic accident,',
  'initial encounter',
  'V746XXD Passenger on bus injured in collision with heavy transport vehicle or bus in traffic accident,',
  'subsequent encounter',
  'V746XXS Passenger on bus injured in collision with heavy transport vehicle or bus in traffic accident,',
  'sequela',
  'V747XXA Person on outside of bus injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V747XXD Person on outside of bus injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V747XXS Person on outside of bus injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V749XXA Unspecified occupant of bus injured in collision with heavy transport vehicle or bus in traffic',
  'accident, initial encounter',
  'V749XXD Unspecified occupant of bus injured in collision with heavy transport vehicle or bus in traffic',
  'accident, subsequent encounter',
  'V749XXS Unspecified occupant of bus injured in collision with heavy transport vehicle or bus in traffic',
  'accident, sequela',
  'V750XXA Driver of bus injured in collision with railway train or railway vehicle in nontraffic accident,',
  'initial encounter',
  'V750XXD Driver of bus injured in collision with railway train or railway vehicle in nontraffic accident,',
  'subsequent encounter',
  'V750XXS Driver of bus injured in collision with railway train or railway vehicle in nontraffic accident,',
  'sequela',
  'V751XXA Passenger on bus injured in collision with railway train or railway vehicle in nontraffic accident,',
  'initial encounter',
  'V751XXD Passenger on bus injured in collision with railway train or railway vehicle in nontraffic accident,',
  'subsequent encounter',
  'V751XXS Passenger on bus injured in collision with railway train or railway vehicle in nontraffic accident,',
  'sequela',
  'V752XXA Person on outside of bus injured in collision with railway train or railway vehicle in nontraffic',
  'accident, initial encounter',
  'V752XXD Person on outside of bus injured in collision with railway train or railway vehicle in nontraffic',
  'accident, subsequent encounter',
  'V752XXS Person on outside of bus injured in collision with railway train or railway vehicle in nontraffic',
  'accident, sequela',
  'V753XXA Unspecified occupant of bus injured in collision with railway train or railway vehicle in',
  'nontraffic accident, initial encounter',
  'V753XXD Unspecified occupant of bus injured in collision with railway train or railway vehicle in',
  'nontraffic accident, subsequent encounter',
  'V753XXS Unspecified occupant of bus injured in collision with railway train or railway vehicle in',
  'nontraffic accident, sequela',
  'V754XXA Person boarding or alighting from bus injured in collision with railway train or railway vehicle,',
  'initial encounter',
  'V754XXD Person boarding or alighting from bus injured in collision with railway train or railway vehicle,',
  'subsequent encounter',
  'V754XXS Person boarding or alighting from bus injured in collision with railway train or railway vehicle,',
  'sequela',
  'V755XXA Driver of bus injured in collision with railway train or railway vehicle in traffic accident, initial',
  'encounter',
  'V755XXD Driver of bus injured in collision with railway train or railway vehicle in traffic accident,',
  'subsequent encounter',
  'V755XXS Driver of bus injured in collision with railway train or railway vehicle in traffic accident, sequela',
  'V756XXA Passenger on bus injured in collision with railway train or railway vehicle in traffic accident,',
  'initial encounter',
  'V756XXD Passenger on bus injured in collision with railway train or railway vehicle in traffic accident,',
  'subsequent encounter',
  'V756XXS Passenger on bus injured in collision with railway train or railway vehicle in traffic accident,',
  'sequela',
  'V757XXA Person on outside of bus injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V757XXD Person on outside of bus injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V757XXS Person on outside of bus injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V759XXA Unspecified occupant of bus injured in collision with railway train or railway vehicle in traffic',
  'accident, initial encounter',
  'V759XXD Unspecified occupant of bus injured in collision with railway train or railway vehicle in traffic',
  'accident, subsequent encounter',
  'V759XXS Unspecified occupant of bus injured in collision with railway train or railway vehicle in traffic',
  'accident, sequela',
  'V760XXA Driver of bus injured in collision with other nonmotor vehicle in nontraffic accident, initial',
  'encounter',
  'V760XXD Driver of bus injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V760XXS Driver of bus injured in collision with other nonmotor vehicle in nontraffic accident, sequela',
  'V761XXA Passenger on bus injured in collision with other nonmotor vehicle in nontraffic accident, initial',
  'encounter',
  'V761XXD Passenger on bus injured in collision with other nonmotor vehicle in nontraffic accident,',
  'subsequent encounter',
  'V761XXS Passenger on bus injured in collision with other nonmotor vehicle in nontraffic accident,',
  'sequela',
  'V762XXA Person on outside of bus injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V762XXD Person on outside of bus injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V762XXS Person on outside of bus injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V763XXA Unspecified occupant of bus injured in collision with other nonmotor vehicle in nontraffic',
  'accident, initial encounter',
  'V763XXD Unspecified occupant of bus injured in collision with other nonmotor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V763XXS Unspecified occupant of bus injured in collision with other nonmotor vehicle in nontraffic',
  'accident, sequela',
  'V764XXA Person boarding or alighting from bus injured in collision with other nonmotor vehicle, initial',
  'encounter',
  'V764XXD Person boarding or alighting from bus injured in collision with other nonmotor vehicle,',
  'subsequent encounter',
  'V764XXS Person boarding or alighting from bus injured in collision with other nonmotor vehicle, sequela',
  'V765XXA Driver of bus injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V765XXD Driver of bus injured in collision with other nonmotor vehicle in traffic accident, subsequent',
  'encounter',
  'V765XXS Driver of bus injured in collision with other nonmotor vehicle in traffic accident, sequela',
  'V766XXA Passenger on bus injured in collision with other nonmotor vehicle in traffic accident, initial',
  'encounter',
  'V766XXD Passenger on bus injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V766XXS Passenger on bus injured in collision with other nonmotor vehicle in traffic accident, sequela',
  'V767XXA Person on outside of bus injured in collision with other nonmotor vehicle in traffic accident,',
  'initial encounter',
  'V767XXD Person on outside of bus injured in collision with other nonmotor vehicle in traffic accident,',
  'subsequent encounter',
  'V767XXS Person on outside of bus injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V769XXA Unspecified occupant of bus injured in collision with other nonmotor vehicle in traffic accident,',
  'initial encounter',
  'V769XXD Unspecified occupant of bus injured in collision with other nonmotor vehicle in traffic',
  'accident, subsequent encounter',
  'V769XXS Unspecified occupant of bus injured in collision with other nonmotor vehicle in traffic accident,',
  'sequela',
  'V770XXA Driver of bus injured in collision with fixed or stationary object in nontraffic accident, initial',
  'encounter',
  'V770XXD Driver of bus injured in collision with fixed or stationary object in nontraffic accident,',
  'subsequent encounter',
  'V770XXS Driver of bus injured in collision with fixed or stationary object in nontraffic accident, sequela',
  'V771XXA Passenger on bus injured in collision with fixed or stationary object in nontraffic accident,',
  'initial encounter',
  'V771XXD Passenger on bus injured in collision with fixed or stationary object in nontraffic accident,',
  'subsequent encounter',
  'V771XXS Passenger on bus injured in collision with fixed or stationary object in nontraffic accident,',
  'sequela',
  'V772XXA Person on outside of bus injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V772XXD Person on outside of bus injured in collision with fixed or stationary object in nontraffic',
  'accident, subsequent encounter',
  'V772XXS Person on outside of bus injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V773XXA Unspecified occupant of bus injured in collision with fixed or stationary object in nontraffic',
  'accident, initial encounter',
  'V773XXD Unspecified occupant of bus injured in collision with fixed or stationary object in nontraffic',
  'accident, subsequent encounter',
  'V773XXS Unspecified occupant of bus injured in collision with fixed or stationary object in nontraffic',
  'accident, sequela',
  'V774XXA Person boarding or alighting from bus injured in collision with fixed or stationary object, initial',
  'encounter',
  'V774XXD Person boarding or alighting from bus injured in collision with fixed or stationary object,',
  'subsequent encounter',
  'V774XXS Person boarding or alighting from bus injured in collision with fixed or stationary object,',
  'sequela',
  'V775XXA Driver of bus injured in collision with fixed or stationary object in traffic accident, initial',
  'encounter',
  'V775XXD Driver of bus injured in collision with fixed or stationary object in traffic accident, subsequent',
  'encounter',
  'V775XXS Driver of bus injured in collision with fixed or stationary object in traffic accident, sequela',
  'V776XXA Passenger on bus injured in collision with fixed or stationary object in traffic accident, initial',
  'encounter',
  'V776XXD Passenger on bus injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V776XXS Passenger on bus injured in collision with fixed or stationary object in traffic accident, sequela',
  'V777XXA Person on outside of bus injured in collision with fixed or stationary object in traffic accident,',
  'initial encounter',
  'V777XXD Person on outside of bus injured in collision with fixed or stationary object in traffic accident,',
  'subsequent encounter',
  'V777XXS Person on outside of bus injured in collision with fixed or stationary object in traffic accident,',
  'sequela',
  'V779XXA Unspecified occupant of bus injured in collision with fixed or stationary object in traffic',
  'accident, initial encounter',
  'V779XXD Unspecified occupant of bus injured in collision with fixed or stationary object in traffic',
  'accident, subsequent encounter',
  'V779XXS Unspecified occupant of bus injured in collision with fixed or stationary object in traffic',
  'accident, sequela',
  'V780XXA Driver of bus injured in noncollision transport accident in nontraffic accident, initial encounter',
  'V780XXD Driver of bus injured in noncollision transport accident in nontraffic accident, subsequent',
  'encounter',
  'V780XXS Driver of bus injured in noncollision transport accident in nontraffic accident, sequela',
  'V781XXA Passenger on bus injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V781XXD Passenger on bus injured in noncollision transport accident in nontraffic accident, subsequent',
  'encounter',
  'V781XXS Passenger on bus injured in noncollision transport accident in nontraffic accident, sequela',
  'V782XXA Person on outside of bus injured in noncollision transport accident in nontraffic accident, initial',
  'encounter',
  'V782XXD Person on outside of bus injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V782XXS Person on outside of bus injured in noncollision transport accident in nontraffic accident,',
  'sequela',
  'V783XXA Unspecified occupant of bus injured in noncollision transport accident in nontraffic accident,',
  'initial encounter',
  'V783XXD Unspecified occupant of bus injured in noncollision transport accident in nontraffic accident,',
  'subsequent encounter',
  'V783XXS Unspecified occupant of bus injured in noncollision transport accident in nontraffic accident,',
  'sequela',
  'V784XXA Person boarding or alighting from bus injured in noncollision transport accident, initial',
  'encounter',
  'V784XXD Person boarding or alighting from bus injured in noncollision transport accident, subsequent',
  'encounter',
  'V784XXS Person boarding or alighting from bus injured in noncollision transport accident, sequela',
  'V785XXA Driver of bus injured in noncollision transport accident in traffic accident, initial encounter',
  'V785XXD Driver of bus injured in noncollision transport accident in traffic accident, subsequent',
  'encounter',
  'V785XXS Driver of bus injured in noncollision transport accident in traffic accident, sequela',
  'V786XXA Passenger on bus injured in noncollision transport accident in traffic accident, initial encounter',
  'V786XXD Passenger on bus injured in noncollision transport accident in traffic accident, subsequent',
  'encounter',
  'V786XXS Passenger on bus injured in noncollision transport accident in traffic accident, sequela',
  'V787XXA Person on outside of bus injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V787XXD Person on outside of bus injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V787XXS Person on outside of bus injured in noncollision transport accident in traffic accident, sequela',
  'V789XXA Unspecified occupant of bus injured in noncollision transport accident in traffic accident, initial',
  'encounter',
  'V789XXD Unspecified occupant of bus injured in noncollision transport accident in traffic accident,',
  'subsequent encounter',
  'V789XXS Unspecified occupant of bus injured in noncollision transport accident in traffic accident,',
  'sequela',
  'V7900XA Driver of bus injured in collision with unspecified motor vehicles in nontraffic accident, initial',
  'encounter',
  'V7900XD Driver of bus injured in collision with unspecified motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V7900XS Driver of bus injured in collision with unspecified motor vehicles in nontraffic accident, sequela',
  'V7909XA Driver of bus injured in collision with other motor vehicles in nontraffic accident, initial',
  'encounter',
  'V7909XD Driver of bus injured in collision with other motor vehicles in nontraffic accident, subsequent',
  'encounter',
  'V7909XS Driver of bus injured in collision with other motor vehicles in nontraffic accident, sequela',
  'V7910XA Passenger on bus injured in collision with unspecified motor vehicles in nontraffic accident,',
  'initial encounter',
  'V7910XD Passenger on bus injured in collision with unspecified motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V7910XS Passenger on bus injured in collision with unspecified motor vehicles in nontraffic accident,',
  'sequela',
  'V7919XA Passenger on bus injured in collision with other motor vehicles in nontraffic accident, initial',
  'encounter',
  'V7919XD Passenger on bus injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V7919XS Passenger on bus injured in collision with other motor vehicles in nontraffic accident, sequela',
  'V7920XA Unspecified bus occupant injured in collision with unspecified motor vehicles in nontraffic',
  'accident, initial encounter',
  'V7920XD Unspecified bus occupant injured in collision with unspecified motor vehicles in nontraffic',
  'accident, subsequent encounter',
  'V7920XS Unspecified bus occupant injured in collision with unspecified motor vehicles in nontraffic',
  'accident, sequela',
  'V7929XA Unspecified bus occupant injured in collision with other motor vehicles in nontraffic accident,',
  'initial encounter',
  'V7929XD Unspecified bus occupant injured in collision with other motor vehicles in nontraffic accident,',
  'subsequent encounter',
  'V7929XS Unspecified bus occupant injured in collision with other motor vehicles in nontraffic accident,',
  'sequela',
  'V793XXA Bus occupant (driver) (passenger) injured in unspecified nontraffic accident, initial encounter',
  'V793XXD Bus occupant (driver) (passenger) injured in unspecified nontraffic accident, subsequent',
  'encounter',
  'V793XXS Bus occupant (driver) (passenger) injured in unspecified nontraffic accident, sequela',
  'V7940XA Driver of bus injured in collision with unspecified motor vehicles in traffic accident, initial',
  'encounter',
  'V7940XD Driver of bus injured in collision with unspecified motor vehicles in traffic accident, subsequent',
  'encounter',
  'V7940XS Driver of bus injured in collision with unspecified motor vehicles in traffic accident, sequela',
  'V7949XA Driver of bus injured in collision with other motor vehicles in traffic accident, initial encounter',
  'V7949XD Driver of bus injured in collision with other motor vehicles in traffic accident, subsequent',
  'encounter',
  'V7949XS Driver of bus injured in collision with other motor vehicles in traffic accident, sequela',
  'V7950XA Passenger on bus injured in collision with unspecified motor vehicles in traffic accident, initial',
  'encounter',
  'V7950XD Passenger on bus injured in collision with unspecified motor vehicles in traffic accident,',
  'subsequent encounter',
  'V7950XS Passenger on bus injured in collision with unspecified motor vehicles in traffic accident,',
  'sequela',
  'V7959XA Passenger on bus injured in collision with other motor vehicles in traffic accident, initial',
  'encounter',
  'V7959XD Passenger on bus injured in collision with other motor vehicles in traffic accident, subsequent',
  'encounter',
  'V7959XS Passenger on bus injured in collision with other motor vehicles in traffic accident, sequela',
  'V7960XA Unspecified bus occupant injured in collision with unspecified motor vehicles in traffic',
  'accident, initial encounter',
  'V7960XD Unspecified bus occupant injured in collision with unspecified motor vehicles in traffic',
  'accident, subsequent encounter',
  'V7960XS Unspecified bus occupant injured in collision with unspecified motor vehicles in traffic',
  'accident, sequela',
  'V7969XA Unspecified bus occupant injured in collision with other motor vehicles in traffic accident,',
  'initial encounter',
  'V7969XD Unspecified bus occupant injured in collision with other motor vehicles in traffic accident,',
  'subsequent encounter',
  'V7969XS Unspecified bus occupant injured in collision with other motor vehicles in traffic accident,',
  'sequela',
  'V7981XA Bus occupant (driver) (passenger) injured in transport accidents with military vehicle, initial',
  'encounter',
  'V7981XD Bus occupant (driver) (passenger) injured in transport accidents with military vehicle,',
  'subsequent encounter',
  'V7981XS Bus occupant (driver) (passenger) injured in transport accidents with military vehicle, sequela',
  'V7988XA Bus occupant (driver) (passenger) injured in other specified transport accidents, initial',
  'encounter',
  'V7988XD Bus occupant (driver) (passenger) injured in other specified transport accidents, subsequent',
  'encounter',
  'V7988XS Bus occupant (driver) (passenger) injured in other specified transport accidents, sequela',
  'V799XXA Bus occupant (driver) (passenger) injured in unspecified traffic accident, initial encounter',
  'V799XXD Bus occupant (driver) (passenger) injured in unspecified traffic accident, subsequent',
  'encounter',
  'V799XXS Bus occupant (driver) (passenger) injured in unspecified traffic accident, sequela',
  'V80010A Animal-rider injured by fall from or being thrown from horse in noncollision accident, initial',
  'encounter',
  'V80010D Animal-rider injured by fall from or being thrown from horse in noncollision accident,',
  'subsequent encounter',
  'V80010S Animal-rider injured by fall from or being thrown from horse in noncollision accident, sequela',
  'V80018A Animal-rider injured by fall from or being thrown from other animal in noncollision accident,',
  'initial encounter',
  'V80018D Animal-rider injured by fall from or being thrown from other animal in noncollision accident,',
  'subsequent encounter',
  'V80018S Animal-rider injured by fall from or being thrown from other animal in noncollision accident,',
  'sequela',
  'V8002XA Occupant of animal-drawn vehicle injured by fall from or being thrown from animal-drawn',
  'vehicle in noncollision accident, initial encounter',
  'V8002XD Occupant of animal-drawn vehicle injured by fall from or being thrown from animal-drawn',
  'vehicle in noncollision accident, subsequent encounter',
  'V8002XS Occupant of animal-drawn vehicle injured by fall from or being thrown from animal-drawn',
  'vehicle in noncollision accident, sequela',
  'V8011XA Animal-rider injured in collision with pedestrian or animal, initial encounter',
  'V8011XD Animal-rider injured in collision with pedestrian or animal, subsequent encounter',
  'V8011XS Animal-rider injured in collision with pedestrian or animal, sequela',
  'V8012XA Occupant of animal-drawn vehicle injured in collision with pedestrian or animal, initial',
  'encounter',
  'V8012XD Occupant of animal-drawn vehicle injured in collision with pedestrian or animal, subsequent',
  'encounter',
  'V8012XS Occupant of animal-drawn vehicle injured in collision with pedestrian or animal, sequela',
  'V8021XA Animal-rider injured in collision with pedal cycle, initial encounter',
  'V8021XD Animal-rider injured in collision with pedal cycle, subsequent encounter',
  'V8021XS Animal-rider injured in collision with pedal cycle, sequela',
  'V8022XA Occupant of animal-drawn vehicle injured in collision with pedal cycle, initial encounter',
  'V8022XD Occupant of animal-drawn vehicle injured in collision with pedal cycle, subsequent encounter',
  'V8022XS Occupant of animal-drawn vehicle injured in collision with pedal cycle, sequela',
  'V8031XA Animal-rider injured in collision with two- or three-wheeled motor vehicle, initial encounter',
  'V8031XD Animal-rider injured in collision with two- or three-wheeled motor vehicle, subsequent',
  'encounter',
  'V8031XS Animal-rider injured in collision with two- or three-wheeled motor vehicle, sequela',
  'V8032XA Occupant of animal-drawn vehicle injured in collision with two- or three-wheeled motor',
  'vehicle, initial encounter',
  'V8032XD Occupant of animal-drawn vehicle injured in collision with two- or three-wheeled motor',
  'vehicle, subsequent encounter',
  'V8032XS Occupant of animal-drawn vehicle injured in collision with two- or three-wheeled motor',
  'vehicle, sequela',
  'V8041XA Animal-rider injured in collision with car, pick-up truck, van, heavy transport vehicle or bus,',
  'initial encounter',
  'V8041XD Animal-rider injured in collision with car, pick-up truck, van, heavy transport vehicle or bus,',
  'subsequent encounter',
  'V8041XS Animal-rider injured in collision with car, pick-up truck, van, heavy transport vehicle or bus,',
  'sequela',
  'V8042XA Occupant of animal-drawn vehicle injured in collision with car, pick-up truck, van, heavy',
  'transport vehicle or bus, initial encounter',
  'V8042XD Occupant of animal-drawn vehicle injured in collision with car, pick-up truck, van, heavy',
  'transport vehicle or bus, subsequent encounter',
  'V8042XS Occupant of animal-drawn vehicle injured in collision with car, pick-up truck, van, heavy',
  'transport vehicle or bus, sequela',
  'V8051XA Animal-rider injured in collision with other specified motor vehicle, initial encounter',
  'V8051XD Animal-rider injured in collision with other specified motor vehicle, subsequent encounter',
  'V8051XS Animal-rider injured in collision with other specified motor vehicle, sequela',
  'V8052XA Occupant of animal-drawn vehicle injured in collision with other specified motor vehicle, initial',
  'encounter',
  'V8052XD Occupant of animal-drawn vehicle injured in collision with other specified motor vehicle,',
  'subsequent encounter',
  'V8052XS Occupant of animal-drawn vehicle injured in collision with other specified motor vehicle,',
  'sequela',
  'V8061XA Animal-rider injured in collision with railway train or railway vehicle, initial encounter',
  'V8061XD Animal-rider injured in collision with railway train or railway vehicle, subsequent encounter',
  'V8061XS Animal-rider injured in collision with railway train or railway vehicle, sequela',
  'V8062XA Occupant of animal-drawn vehicle injured in collision with railway train or railway vehicle,',
  'initial encounter',
  'V8062XD Occupant of animal-drawn vehicle injured in collision with railway train or railway vehicle,',
  'subsequent encounter',
  'V8062XS Occupant of animal-drawn vehicle injured in collision with railway train or railway vehicle,',
  'sequela',
  'V80710A Animal-rider injured in collision with other animal being ridden, initial encounter',
  'V80710D Animal-rider injured in collision with other animal being ridden, subsequent encounter',
  'V80710S Animal-rider injured in collision with other animal being ridden, sequela',
  'V80711A Occupant of animal-drawn vehicle injured in collision with animal being ridden, initial',
  'encounter',
  'V80711D Occupant of animal-drawn vehicle injured in collision with animal being ridden, subsequent',
  'encounter',
  'V80711S Occupant of animal-drawn vehicle injured in collision with animal being ridden, sequela',
  'V80720A Animal-rider injured in collision with animal-drawn vehicle, initial encounter',
  'V80720D Animal-rider injured in collision with animal-drawn vehicle, subsequent encounter',
  'V80720S Animal-rider injured in collision with animal-drawn vehicle, sequela',
  'V80721A Occupant of animal-drawn vehicle injured in collision with other animal-drawn vehicle, initial',
  'encounter',
  'V80721D Occupant of animal-drawn vehicle injured in collision with other animal-drawn vehicle,',
  'subsequent encounter',
  'V80721S Occupant of animal-drawn vehicle injured in collision with other animal-drawn vehicle, sequela',
  'V80730A Animal-rider injured in collision with streetcar, initial encounter',
  'V80730D Animal-rider injured in collision with streetcar, subsequent encounter',
  'V80730S Animal-rider injured in collision with streetcar, sequela',
  'V80731A Occupant of animal-drawn vehicle injured in collision with streetcar, initial encounter',
  'V80731D Occupant of animal-drawn vehicle injured in collision with streetcar, subsequent encounter',
  'V80731S Occupant of animal-drawn vehicle injured in collision with streetcar, sequela',
  'V80790A Animal-rider injured in collision with other nonmotor vehicles, initial encounter',
  'V80790D Animal-rider injured in collision with other nonmotor vehicles, subsequent encounter',
  'V80790S Animal-rider injured in collision with other nonmotor vehicles, sequela',
  'V80791A Occupant of animal-drawn vehicle injured in collision with other nonmotor vehicles, initial',
  'encounter',
  'V80791D Occupant of animal-drawn vehicle injured in collision with other nonmotor vehicles,',
  'subsequent encounter',
  'V80791S Occupant of animal-drawn vehicle injured in collision with other nonmotor vehicles, sequela',
  'V8081XA Animal-rider injured in collision with fixed or stationary object, initial encounter',
  'V8081XD Animal-rider injured in collision with fixed or stationary object, subsequent encounter',
  'V8081XS Animal-rider injured in collision with fixed or stationary object, sequela',
  'V8082XA Occupant of animal-drawn vehicle injured in collision with fixed or stationary object, initial',
  'encounter',
  'V8082XD Occupant of animal-drawn vehicle injured in collision with fixed or stationary object,',
  'subsequent encounter',
  'V8082XS Occupant of animal-drawn vehicle injured in collision with fixed or stationary object, sequela',
  'V80910A Animal-rider injured in transport accident with military vehicle, initial encounter',
  'V80910D Animal-rider injured in transport accident with military vehicle, subsequent encounter',
  'V80910S Animal-rider injured in transport accident with military vehicle, sequela',
  'V80918A Animal-rider injured in other transport accident, initial encounter',
  'V80918D Animal-rider injured in other transport accident, subsequent encounter',
  'V80918S Animal-rider injured in other transport accident, sequela',
  'V80919A Animal-rider injured in unspecified transport accident, initial encounter',
  'V80919D Animal-rider injured in unspecified transport accident, subsequent encounter',
  'V80919S Animal-rider injured in unspecified transport accident, sequela',
  'V80920A Occupant of animal-drawn vehicle injured in transport accident with military vehicle, initial',
  'encounter',
  'V80920D Occupant of animal-drawn vehicle injured in transport accident with military vehicle,',
  'subsequent encounter',
  'V80920S Occupant of animal-drawn vehicle injured in transport accident with military vehicle, sequela',
  'V80928A Occupant of animal-drawn vehicle injured in other transport accident, initial encounter',
  'V80928D Occupant of animal-drawn vehicle injured in other transport accident, subsequent encounter',
  'V80928S Occupant of animal-drawn vehicle injured in other transport accident, sequela',
  'V80929A Occupant of animal-drawn vehicle injured in unspecified transport accident, initial encounter',
  'V80929D Occupant of animal-drawn vehicle injured in unspecified transport accident, subsequent',
  'encounter',
  'V80929S Occupant of animal-drawn vehicle injured in unspecified transport accident, sequela',
  'V810XXA Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic',
  'accident, initial encounter',
  'V810XXD Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic',
  'accident, subsequent encounter',
  'V810XXS Occupant of railway train or railway vehicle injured in collision with motor vehicle in nontraffic',
  'accident, sequela',
  'V811XXA Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic',
  'accident, initial encounter',
  'V811XXD Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic',
  'accident, subsequent encounter',
  'V811XXS Occupant of railway train or railway vehicle injured in collision with motor vehicle in traffic',
  'accident, sequela',
  'V812XXA Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock,',
  'initial encounter',
  'V812XXD Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock,',
  'subsequent encounter',
  'V812XXS Occupant of railway train or railway vehicle injured in collision with or hit by rolling stock,',
  'sequela',
  'V813XXA Occupant of railway train or railway vehicle injured in collision with other object, initial',
  'encounter',
  'V813XXD Occupant of railway train or railway vehicle injured in collision with other object, subsequent',
  'encounter',
  'V813XXS Occupant of railway train or railway vehicle injured in collision with other object, sequela',
  'V814XXA Person injured while boarding or alighting from railway train or railway vehicle, initial',
  'encounter',
  'V814XXD Person injured while boarding or alighting from railway train or railway vehicle, subsequent',
  'encounter',
  'V814XXS Person injured while boarding or alighting from railway train or railway vehicle, sequela',
  'V815XXA Occupant of railway train or railway vehicle injured by fall in railway train or railway vehicle,',
  'initial encounter',
  'V815XXD Occupant of railway train or railway vehicle injured by fall in railway train or railway vehicle,',
  'subsequent encounter',
  'V815XXS Occupant of railway train or railway vehicle injured by fall in railway train or railway vehicle,',
  'sequela',
  'V816XXA Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle,',
  'initial encounter',
  'V816XXD Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle,',
  'subsequent encounter',
  'V816XXS Occupant of railway train or railway vehicle injured by fall from railway train or railway vehicle,',
  'sequela',
  'V817XXA Occupant of railway train or railway vehicle injured in derailment without antecedent collision,',
  'initial encounter',
  'V817XXD Occupant of railway train or railway vehicle injured in derailment without antecedent collision,',
  'subsequent encounter',
  'V817XXS Occupant of railway train or railway vehicle injured in derailment without antecedent collision,',
  'sequela',
  'V8181XA Occupant of railway train or railway vehicle injured due to explosion or fire on train, initial',
  'encounter',
  'V8181XD Occupant of railway train or railway vehicle injured due to explosion or fire on train,',
  'subsequent encounter',
  'V8181XS Occupant of railway train or railway vehicle injured due to explosion or fire on train, sequela',
  'V8182XA Occupant of railway train or railway vehicle injured due to object falling onto train, initial',
  'encounter',
  'V8182XD Occupant of railway train or railway vehicle injured due to object falling onto train, subsequent',
  'encounter',
  'V8182XS Occupant of railway train or railway vehicle injured due to object falling onto train, sequela',
  'V8183XA Occupant of railway train or railway vehicle injured due to collision with military vehicle, initial',
  'encounter',
  'V8183XD Occupant of railway train or railway vehicle injured due to collision with military vehicle,',
  'subsequent encounter',
  'V8183XS Occupant of railway train or railway vehicle injured due to collision with military vehicle,',
  'sequela',
  'V8189XA Occupant of railway train or railway vehicle injured due to other specified railway accident,',
  'initial encounter',
  'V8189XD Occupant of railway train or railway vehicle injured due to other specified railway accident,',
  'subsequent encounter',
  'V8189XS Occupant of railway train or railway vehicle injured due to other specified railway accident,',
  'sequela',
  'V819XXA Occupant of railway train or railway vehicle injured in unspecified railway accident, initial',
  'encounter',
  'V819XXD Occupant of railway train or railway vehicle injured in unspecified railway accident,',
  'subsequent encounter',
  'V819XXS Occupant of railway train or railway vehicle injured in unspecified railway accident, sequela',
  'V820XXA Occupant of streetcar injured in collision with motor vehicle in nontraffic accident, initial',
  'encounter',
  'V820XXD Occupant of streetcar injured in collision with motor vehicle in nontraffic accident, subsequent',
  'encounter',
  'V820XXS Occupant of streetcar injured in collision with motor vehicle in nontraffic accident, sequela',
  'V821XXA Occupant of streetcar injured in collision with motor vehicle in traffic accident, initial',
  'encounter',
  'V821XXD Occupant of streetcar injured in collision with motor vehicle in traffic accident, subsequent',
  'encounter',
  'V821XXS Occupant of streetcar injured in collision with motor vehicle in traffic accident, sequela',
  'V822XXA Occupant of streetcar injured in collision with or hit by rolling stock, initial encounter',
  'V822XXD Occupant of streetcar injured in collision with or hit by rolling stock, subsequent encounter',
  'V822XXS Occupant of streetcar injured in collision with or hit by rolling stock, sequela',
  'V823XXA Occupant of streetcar injured in collision with other object, initial encounter',
  'V823XXD Occupant of streetcar injured in collision with other object, subsequent encounter',
  'V823XXS Occupant of streetcar injured in collision with other object, sequela',
  'V824XXA Person injured while boarding or alighting from streetcar, initial encounter',
  'V824XXD Person injured while boarding or alighting from streetcar, subsequent encounter',
  'V824XXS Person injured while boarding or alighting from streetcar, sequela',
  'V825XXA Occupant of streetcar injured by fall in streetcar, initial encounter',
  'V825XXD Occupant of streetcar injured by fall in streetcar, subsequent encounter',
  'V825XXS Occupant of streetcar injured by fall in streetcar, sequela',
  'V826XXA Occupant of streetcar injured by fall from streetcar, initial encounter',
  'V826XXD Occupant of streetcar injured by fall from streetcar, subsequent encounter',
  'V826XXS Occupant of streetcar injured by fall from streetcar, sequela',
  'V827XXA Occupant of streetcar injured in derailment without antecedent collision, initial encounter',
  'V827XXD Occupant of streetcar injured in derailment without antecedent collision, subsequent',
  'encounter',
  'V827XXS Occupant of streetcar injured in derailment without antecedent collision, sequela',
  'V828XXA Occupant of streetcar injured in other specified transport accidents, initial encounter',
  'V828XXD Occupant of streetcar injured in other specified transport accidents, subsequent encounter',
  'V828XXS Occupant of streetcar injured in other specified transport accidents, sequela',
  'V829XXA Occupant of streetcar injured in unspecified traffic accident, initial encounter',
  'V829XXD Occupant of streetcar injured in unspecified traffic accident, subsequent encounter',
  'V829XXS Occupant of streetcar injured in unspecified traffic accident, sequela',
  'V830XXA Driver of special industrial vehicle injured in traffic accident, initial encounter',
  'V830XXD Driver of special industrial vehicle injured in traffic accident, subsequent encounter',
  'V830XXS Driver of special industrial vehicle injured in traffic accident, sequela',
  'V831XXA Passenger of special industrial vehicle injured in traffic accident, initial encounter',
  'V831XXD Passenger of special industrial vehicle injured in traffic accident, subsequent encounter',
  'V831XXS Passenger of special industrial vehicle injured in traffic accident, sequela',
  'V832XXA Person on outside of special industrial vehicle injured in traffic accident, initial encounter',
  'V832XXD Person on outside of special industrial vehicle injured in traffic accident, subsequent',
  'encounter',
  'V832XXS Person on outside of special industrial vehicle injured in traffic accident, sequela',
  'V833XXA Unspecified occupant of special industrial vehicle injured in traffic accident, initial encounter',
  'V833XXD Unspecified occupant of special industrial vehicle injured in traffic accident, subsequent',
  'encounter',
  'V833XXS Unspecified occupant of special industrial vehicle injured in traffic accident, sequela',
  'V834XXA Person injured while boarding or alighting from special industrial vehicle, initial encounter',
  'V834XXD Person injured while boarding or alighting from special industrial vehicle, subsequent',
  'encounter',
  'V834XXS Person injured while boarding or alighting from special industrial vehicle, sequela',
  'V835XXA Driver of special industrial vehicle injured in nontraffic accident, initial encounter',
  'V835XXD Driver of special industrial vehicle injured in nontraffic accident, subsequent encounter',
  'V835XXS Driver of special industrial vehicle injured in nontraffic accident, sequela',
  'V836XXA Passenger of special industrial vehicle injured in nontraffic accident, initial encounter',
  'V836XXD Passenger of special industrial vehicle injured in nontraffic accident, subsequent encounter',
  'V836XXS Passenger of special industrial vehicle injured in nontraffic accident, sequela',
  'V837XXA Person on outside of special industrial vehicle injured in nontraffic accident, initial encounter',
  'V837XXD Person on outside of special industrial vehicle injured in nontraffic accident, subsequent',
  'encounter',
  'V837XXS Person on outside of special industrial vehicle injured in nontraffic accident, sequela',
  'V839XXA Unspecified occupant of special industrial vehicle injured in nontraffic accident, initial',
  'encounter',
  'V839XXD Unspecified occupant of special industrial vehicle injured in nontraffic accident, subsequent',
  'encounter',
  'V839XXS Unspecified occupant of special industrial vehicle injured in nontraffic accident, sequela',
  'V840XXA Driver of special agricultural vehicle injured in traffic accident, initial encounter',
  'V840XXD Driver of special agricultural vehicle injured in traffic accident, subsequent encounter',
  'V840XXS Driver of special agricultural vehicle injured in traffic accident, sequela',
  'V841XXA Passenger of special agricultural vehicle injured in traffic accident, initial encounter',
  'V841XXD Passenger of special agricultural vehicle injured in traffic accident, subsequent encounter',
  'V841XXS Passenger of special agricultural vehicle injured in traffic accident, sequela',
  'V842XXA Person on outside of special agricultural vehicle injured in traffic accident, initial encounter',
  'V842XXD Person on outside of special agricultural vehicle injured in traffic accident, subsequent',
  'encounter',
  'V842XXS Person on outside of special agricultural vehicle injured in traffic accident, sequela',
  'V843XXA Unspecified occupant of special agricultural vehicle injured in traffic accident, initial encounter',
  'V843XXD Unspecified occupant of special agricultural vehicle injured in traffic accident, subsequent',
  'encounter',
  'V843XXS Unspecified occupant of special agricultural vehicle injured in traffic accident, sequela',
  'V844XXA Person injured while boarding or alighting from special agricultural vehicle, initial encounter',
  'V844XXD Person injured while boarding or alighting from special agricultural vehicle, subsequent',
  'encounter',
  'V844XXS Person injured while boarding or alighting from special agricultural vehicle, sequela',
  'V845XXA Driver of special agricultural vehicle injured in nontraffic accident, initial encounter',
  'V845XXD Driver of special agricultural vehicle injured in nontraffic accident, subsequent encounter',
  'V845XXS Driver of special agricultural vehicle injured in nontraffic accident, sequela',
  'V846XXA Passenger of special agricultural vehicle injured in nontraffic accident, initial encounter',
  'V846XXD Passenger of special agricultural vehicle injured in nontraffic accident, subsequent encounter',
  'V846XXS Passenger of special agricultural vehicle injured in nontraffic accident, sequela',
  'V847XXA Person on outside of special agricultural vehicle injured in nontraffic accident, initial encounter',
  'V847XXD Person on outside of special agricultural vehicle injured in nontraffic accident, subsequent',
  'encounter',
  'V847XXS Person on outside of special agricultural vehicle injured in nontraffic accident, sequela',
  'V849XXA Unspecified occupant of special agricultural vehicle injured in nontraffic accident, initial',
  'encounter',
  'V849XXD Unspecified occupant of special agricultural vehicle injured in nontraffic accident, subsequent',
  'encounter',
  'V849XXS Unspecified occupant of special agricultural vehicle injured in nontraffic accident, sequela',
  'V850XXA Driver of special construction vehicle injured in traffic accident, initial encounter',
  'V850XXD Driver of special construction vehicle injured in traffic accident, subsequent encounter',
  'V850XXS Driver of special construction vehicle injured in traffic accident, sequela',
  'V851XXA Passenger of special construction vehicle injured in traffic accident, initial encounter',
  'V851XXD Passenger of special construction vehicle injured in traffic accident, subsequent encounter',
  'V851XXS Passenger of special construction vehicle injured in traffic accident, sequela',
  'V852XXA Person on outside of special construction vehicle injured in traffic accident, initial encounter',
  'V852XXD Person on outside of special construction vehicle injured in traffic accident, subsequent',
  'encounter',
  'V852XXS Person on outside of special construction vehicle injured in traffic accident, sequela',
  'V853XXA Unspecified occupant of special construction vehicle injured in traffic accident, initial',
  'encounter',
  'V853XXD Unspecified occupant of special construction vehicle injured in traffic accident, subsequent',
  'encounter',
  'V853XXS Unspecified occupant of special construction vehicle injured in traffic accident, sequela',
  'V854XXA Person injured while boarding or alighting from special construction vehicle, initial encounter',
  'V854XXD Person injured while boarding or alighting from special construction vehicle, subsequent',
  'encounter',
  'V854XXS Person injured while boarding or alighting from special construction vehicle, sequela',
  'V855XXA Driver of special construction vehicle injured in nontraffic accident, initial encounter',
  'V855XXD Driver of special construction vehicle injured in nontraffic accident, subsequent encounter',
  'V855XXS Driver of special construction vehicle injured in nontraffic accident, sequela',
  'V856XXA Passenger of special construction vehicle injured in nontraffic accident, initial encounter',
  'V856XXD Passenger of special construction vehicle injured in nontraffic accident, subsequent encounter',
  'V856XXS Passenger of special construction vehicle injured in nontraffic accident, sequela',
  'V857XXA Person on outside of special construction vehicle injured in nontraffic accident, initial',
  'encounter',
  'V857XXD Person on outside of special construction vehicle injured in nontraffic accident, subsequent',
  'encounter',
  'V857XXS Person on outside of special construction vehicle injured in nontraffic accident, sequela',
  'V859XXA Unspecified occupant of special construction vehicle injured in nontraffic accident, initial',
  'encounter',
  'V859XXD Unspecified occupant of special construction vehicle injured in nontraffic accident, subsequent',
  'encounter',
  'V859XXS Unspecified occupant of special construction vehicle injured in nontraffic accident, sequela',
  'V8601XA Driver of ambulance or fire engine injured in traffic accident, initial encounter',
  'V8601XD Driver of ambulance or fire engine injured in traffic accident, subsequent encounter',
  'V8601XS Driver of ambulance or fire engine injured in traffic accident, sequela',
  'V8602XA Driver of snowmobile injured in traffic accident, initial encounter',
  'V8602XD Driver of snowmobile injured in traffic accident, subsequent encounter',
  'V8602XS Driver of snowmobile injured in traffic accident, sequela',
  'V8603XA Driver of dune buggy injured in traffic accident, initial encounter',
  'V8603XD Driver of dune buggy injured in traffic accident, subsequent encounter',
  'V8603XS Driver of dune buggy injured in traffic accident, sequela',
  'V8604XA Driver of military vehicle injured in traffic accident, initial encounter',
  'V8604XD Driver of military vehicle injured in traffic accident, subsequent encounter',
  'V8604XS Driver of military vehicle injured in traffic accident, sequela',
  'V8609XA Driver of other special all-terrain or other off-road motor vehicle injured in traffic accident,',
  'initial encounter',
  'V8609XD Driver of other special all-terrain or other off-road motor vehicle injured in traffic accident,',
  'subsequent encounter',
  'V8609XS Driver of other special all-terrain or other off-road motor vehicle injured in traffic accident,',
  'sequela',
  'V8611XA Passenger of ambulance or fire engine injured in traffic accident, initial encounter',
  'V8611XD Passenger of ambulance or fire engine injured in traffic accident, subsequent encounter',
  'V8611XS Passenger of ambulance or fire engine injured in traffic accident, sequela',
  'V8612XA Passenger of snowmobile injured in traffic accident, initial encounter',
  'V8612XD Passenger of snowmobile injured in traffic accident, subsequent encounter',
  'V8612XS Passenger of snowmobile injured in traffic accident, sequela',
  'V8613XA Passenger of dune buggy injured in traffic accident, initial encounter',
  'V8613XD Passenger of dune buggy injured in traffic accident, subsequent encounter',
  'V8613XS Passenger of dune buggy injured in traffic accident, sequela',
  'V8614XA Passenger of military vehicle injured in traffic accident, initial encounter',
  'V8614XD Passenger of military vehicle injured in traffic accident, subsequent encounter',
  'V8614XS Passenger of military vehicle injured in traffic accident, sequela',
  'V8619XA Passenger of other special all-terrain or other off-road motor vehicle injured in traffic accident,',
  'initial encounter',
  'V8619XD Passenger of other special all-terrain or other off-road motor vehicle injured in traffic accident,',
  'subsequent encounter',
  'V8619XS Passenger of other special all-terrain or other off-road motor vehicle injured in traffic accident,',
  'sequela',
  'V8621XA Person on outside of ambulance or fire engine injured in traffic accident, initial encounter',
  'V8621XD Person on outside of ambulance or fire engine injured in traffic accident, subsequent',
  'encounter',
  'V8621XS Person on outside of ambulance or fire engine injured in traffic accident, sequela',
  'V8622XA Person on outside of snowmobile injured in traffic accident, initial encounter',
  'V8622XD Person on outside of snowmobile injured in traffic accident, subsequent encounter',
  'V8622XS Person on outside of snowmobile injured in traffic accident, sequela',
  'V8623XA Person on outside of dune buggy injured in traffic accident, initial encounter',
  'V8623XD Person on outside of dune buggy injured in traffic accident, subsequent encounter',
  'V8623XS Person on outside of dune buggy injured in traffic accident, sequela',
  'V8624XA Person on outside of military vehicle injured in traffic accident, initial encounter',
  'V8624XD Person on outside of military vehicle injured in traffic accident, subsequent encounter',
  'V8624XS Person on outside of military vehicle injured in traffic accident, sequela',
  'V8629XA Person on outside of other special all-terrain or other off-road motor vehicle injured in traffic',
  'accident, initial encounter',
  'V8629XD Person on outside of other special all-terrain or other off-road motor vehicle injured in traffic',
  'accident, subsequent encounter',
  'V8629XS Person on outside of other special all-terrain or other off-road motor vehicle injured in traffic',
  'accident, sequela',
  'V8631XA Unspecified occupant of ambulance or fire engine injured in traffic accident, initial encounter',
  'V8631XD Unspecified occupant of ambulance or fire engine injured in traffic accident, subsequent',
  'encounter',
  'V8631XS Unspecified occupant of ambulance or fire engine injured in traffic accident, sequela',
  'V8632XA Unspecified occupant of snowmobile injured in traffic accident, initial encounter',
  'V8632XD Unspecified occupant of snowmobile injured in traffic accident, subsequent encounter',
  'V8632XS Unspecified occupant of snowmobile injured in traffic accident, sequela',
  'V8633XA Unspecified occupant of dune buggy injured in traffic accident, initial encounter',
  'V8633XD Unspecified occupant of dune buggy injured in traffic accident, subsequent encounter',
  'V8633XS Unspecified occupant of dune buggy injured in traffic accident, sequela',
  'V8634XA Unspecified occupant of military vehicle injured in traffic accident, initial encounter',
  'V8634XD Unspecified occupant of military vehicle injured in traffic accident, subsequent encounter',
  'V8634XS Unspecified occupant of military vehicle injured in traffic accident, sequela',
  'V8639XA Unspecified occupant of other special all-terrain or other off-road motor vehicle injured in',
  'traffic accident, initial encounter',
  'V8639XD Unspecified occupant of other special all-terrain or other off-road motor vehicle injured in',
  'traffic accident, subsequent encounter',
  'V8639XS Unspecified occupant of other special all-terrain or other off-road motor vehicle injured in',
  'traffic accident, sequela',
  'V8641XA Person injured while boarding or alighting from ambulance or fire engine, initial encounter',
  'V8641XD Person injured while boarding or alighting from ambulance or fire engine, subsequent',
  'encounter',
  'V8641XS Person injured while boarding or alighting from ambulance or fire engine, sequela',
  'V8642XA Person injured while boarding or alighting from snowmobile, initial encounter',
  'V8642XD Person injured while boarding or alighting from snowmobile, subsequent encounter',
  'V8642XS Person injured while boarding or alighting from snowmobile, sequela',
  'V8643XA Person injured while boarding or alighting from dune buggy, initial encounter',
  'V8643XD Person injured while boarding or alighting from dune buggy, subsequent encounter',
  'V8643XS Person injured while boarding or alighting from dune buggy, sequela',
  'V8644XA Person injured while boarding or alighting from military vehicle, initial encounter',
  'V8644XD Person injured while boarding or alighting from military vehicle, subsequent encounter',
  'V8644XS Person injured while boarding or alighting from military vehicle, sequela',
  'V8649XA Person injured while boarding or alighting from other special all-terrain or other off-road',
  'motor vehicle, initial encounter',
  'V8649XD Person injured while boarding or alighting from other special all-terrain or other off-road',
  'motor vehicle, subsequent encounter',
  'V8649XS Person injured while boarding or alighting from other special all-terrain or other off-road',
  'motor vehicle, sequela',
  'V8651XA Driver of ambulance or fire engine injured in nontraffic accident, initial encounter',
  'V8651XD Driver of ambulance or fire engine injured in nontraffic accident, subsequent encounter',
  'V8651XS Driver of ambulance or fire engine injured in nontraffic accident, sequela',
  'V8652XA Driver of snowmobile injured in nontraffic accident, initial encounter',
  'V8652XD Driver of snowmobile injured in nontraffic accident, subsequent encounter',
  'V8652XS Driver of snowmobile injured in nontraffic accident, sequela',
  'V8653XA Driver of dune buggy injured in nontraffic accident, initial encounter',
  'V8653XD Driver of dune buggy injured in nontraffic accident, subsequent encounter',
  'V8653XS Driver of dune buggy injured in nontraffic accident, sequela',
  'V8654XA Driver of military vehicle injured in nontraffic accident, initial encounter',
  'V8654XD Driver of military vehicle injured in nontraffic accident, subsequent encounter',
  'V8654XS Driver of military vehicle injured in nontraffic accident, sequela',
  'V8659XA Driver of other special all-terrain or other off-road motor vehicle injured in nontraffic accident,',
  'initial encounter',
  'V8659XD Driver of other special all-terrain or other off-road motor vehicle injured in nontraffic accident,',
  'subsequent encounter',
  'V8659XS Driver of other special all-terrain or other off-road motor vehicle injured in nontraffic accident,',
  'sequela',
  'V8661XA Passenger of ambulance or fire engine injured in nontraffic accident, initial encounter',
  'V8661XD Passenger of ambulance or fire engine injured in nontraffic accident, subsequent encounter',
  'V8661XS Passenger of ambulance or fire engine injured in nontraffic accident, sequela',
  'V8662XA Passenger of snowmobile injured in nontraffic accident, initial encounter',
  'V8662XD Passenger of snowmobile injured in nontraffic accident, subsequent encounter',
  'V8662XS Passenger of snowmobile injured in nontraffic accident, sequela',
  'V8663XA Passenger of dune buggy injured in nontraffic accident, initial encounter',
  'V8663XD Passenger of dune buggy injured in nontraffic accident, subsequent encounter',
  'V8663XS Passenger of dune buggy injured in nontraffic accident, sequela',
  'V8664XA Passenger of military vehicle injured in nontraffic accident, initial encounter',
  'V8664XD Passenger of military vehicle injured in nontraffic accident, subsequent encounter',
  'V8664XS Passenger of military vehicle injured in nontraffic accident, sequela',
  'V8669XA Passenger of other special all-terrain or other off-road motor vehicle injured in nontraffic',
  'accident, initial encounter',
  'V8669XD Passenger of other special all-terrain or other off-road motor vehicle injured in nontraffic',
  'accident, subsequent encounter',
  'V8669XS Passenger of other special all-terrain or other off-road motor vehicle injured in nontraffic',
  'accident, sequela',
  'V8671XA Person on outside of ambulance or fire engine injured in nontraffic accident, initial encounter',
  'V8671XD Person on outside of ambulance or fire engine injured in nontraffic accident, subsequent',
  'encounter',
  'V8671XS Person on outside of ambulance or fire engine injured in nontraffic accident, sequela',
  'V8672XA Person on outside of snowmobile injured in nontraffic accident, initial encounter',
  'V8672XD Person on outside of snowmobile injured in nontraffic accident, subsequent encounter',
  'V8672XS Person on outside of snowmobile injured in nontraffic accident, sequela',
  'V8673XA Person on outside of dune buggy injured in nontraffic accident, initial encounter',
  'V8673XD Person on outside of dune buggy injured in nontraffic accident, subsequent encounter',
  'V8673XS Person on outside of dune buggy injured in nontraffic accident, sequela',
  'V8674XA Person on outside of military vehicle injured in nontraffic accident, initial encounter',
  'V8674XD Person on outside of military vehicle injured in nontraffic accident, subsequent encounter',
  'V8674XS Person on outside of military vehicle injured in nontraffic accident, sequela',
  'V8679XA Person on outside of other special all-terrain or other off-road motor vehicles injured in',
  'nontraffic accident, initial encounter',
  'V8679XD Person on outside of other special all-terrain or other off-road motor vehicles injured in',
  'nontraffic accident, subsequent encounter',
  'V8679XS Person on outside of other special all-terrain or other off-road motor vehicles injured in',
  'nontraffic accident, sequela',
  'V8691XA Unspecified occupant of ambulance or fire engine injured in nontraffic accident, initial',
  'encounter',
  'V8691XD Unspecified occupant of ambulance or fire engine injured in nontraffic accident, subsequent',
  'encounter',
  'V8691XS Unspecified occupant of ambulance or fire engine injured in nontraffic accident, sequela',
  'V8692XA Unspecified occupant of snowmobile injured in nontraffic accident, initial encounter',
  'V8692XD Unspecified occupant of snowmobile injured in nontraffic accident, subsequent encounter',
  'V8692XS Unspecified occupant of snowmobile injured in nontraffic accident, sequela',
  'V8693XA Unspecified occupant of dune buggy injured in nontraffic accident, initial encounter',
  'V8693XD Unspecified occupant of dune buggy injured in nontraffic accident, subsequent encounter',
  'V8693XS Unspecified occupant of dune buggy injured in nontraffic accident, sequela',
  'V8694XA Unspecified occupant of military vehicle injured in nontraffic accident, initial encounter',
  'V8694XD Unspecified occupant of military vehicle injured in nontraffic accident, subsequent encounter',
  'V8694XS Unspecified occupant of military vehicle injured in nontraffic accident, sequela',
  'V8699XA Unspecified occupant of other special all-terrain or other off-road motor vehicle injured in',
  'nontraffic accident, initial encounter',
  'V8699XD Unspecified occupant of other special all-terrain or other off-road motor vehicle injured in',
  'nontraffic accident, subsequent encounter',
  'V8699XS Unspecified occupant of other special all-terrain or other off-road motor vehicle injured in',
  'nontraffic accident, sequela',
  'V870XXA Person injured in collision between car and two- or three-wheeled powered vehicle (traffic),',
  'initial encounter',
  'V870XXD Person injured in collision between car and two- or three-wheeled powered vehicle (traffic),',
  'subsequent encounter',
  'V870XXS Person injured in collision between car and two- or three-wheeled powered vehicle (traffic),',
  'sequela',
  'V871XXA Person injured in collision between other motor vehicle and two- or three-wheeled motor',
  'vehicle (traffic), initial encounter',
  'V871XXD Person injured in collision between other motor vehicle and two- or three-wheeled motor',
  'vehicle (traffic), subsequent encounter',
  'V871XXS Person injured in collision between other motor vehicle and two- or three-wheeled motor',
  'vehicle (traffic), sequela',
  'V872XXA Person injured in collision between car and pick-up truck or van (traffic), initial encounter',
  'V872XXD Person injured in collision between car and pick-up truck or van (traffic), subsequent',
  'encounter',
  'V872XXS Person injured in collision between car and pick-up truck or van (traffic), sequela',
  'V873XXA Person injured in collision between car and bus (traffic), initial encounter',
  'V873XXD Person injured in collision between car and bus (traffic), subsequent encounter',
  'V873XXS Person injured in collision between car and bus (traffic), sequela',
  'V874XXA Person injured in collision between car and heavy transport vehicle (traffic), initial encounter',
  'V874XXD Person injured in collision between car and heavy transport vehicle (traffic), subsequent',
  'encounter',
  'V874XXS Person injured in collision between car and heavy transport vehicle (traffic), sequela',
  'V875XXA Person injured in collision between heavy transport vehicle and bus (traffic), initial encounter',
  'V875XXD Person injured in collision between heavy transport vehicle and bus (traffic), subsequent',
  'encounter',
  'V875XXS Person injured in collision between heavy transport vehicle and bus (traffic), sequela',
  'V876XXA Person injured in collision between railway train or railway vehicle and car (traffic), initial',
  'encounter',
  'V876XXD Person injured in collision between railway train or railway vehicle and car (traffic),',
  'subsequent encounter',
  'V876XXS Person injured in collision between railway train or railway vehicle and car (traffic), sequela',
  'V877XXA Person injured in collision between other specified motor vehicles (traffic), initial encounter',
  'V877XXD Person injured in collision between other specified motor vehicles (traffic), subsequent',
  'encounter',
  'V877XXS Person injured in collision between other specified motor vehicles (traffic), sequela',
  'V878XXA Person injured in other specified noncollision transport accidents involving motor vehicle',
  '(traffic), initial encounter',
  'V878XXD Person injured in other specified noncollision transport accidents involving motor vehicle',
  '(traffic), subsequent encounter',
  'V878XXS Person injured in other specified noncollision transport accidents involving motor vehicle',
  '(traffic), sequela',
  'V879XXA Person injured in other specified (collision)(noncollision) transport accidents involving',
  'nonmotor vehicle (traffic), initial encounter',
  'V879XXD Person injured in other specified (collision)(noncollision) transport accidents involving',
  'nonmotor vehicle (traffic), subsequent encounter',
  'V879XXS Person injured in other specified (collision)(noncollision) transport accidents involving',
  'nonmotor vehicle (traffic), sequela',
  'V880XXA Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic,',
  'initial encounter',
  'V880XXD Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic,',
  'subsequent encounter',
  'V880XXS Person injured in collision between car and two- or three-wheeled motor vehicle, nontraffic,',
  'sequela',
  'V881XXA Person injured in collision between other motor vehicle and two- or three-wheeled motor',
  'vehicle, nontraffic, initial encounter',
  'V881XXD Person injured in collision between other motor vehicle and two- or three-wheeled motor',
  'vehicle, nontraffic, subsequent encounter',
  'V881XXS Person injured in collision between other motor vehicle and two- or three-wheeled motor',
  'vehicle, nontraffic, sequela',
  'V882XXA Person injured in collision between car and pick-up truck or van, nontraffic, initial encounter',
  'V882XXD Person injured in collision between car and pick-up truck or van, nontraffic, subsequent',
  'encounter',
  'V882XXS Person injured in collision between car and pick-up truck or van, nontraffic, sequela',
  'V883XXA Person injured in collision between car and bus, nontraffic, initial encounter',
  'V883XXD Person injured in collision between car and bus, nontraffic, subsequent encounter',
  'V883XXS Person injured in collision between car and bus, nontraffic, sequela',
  'V884XXA Person injured in collision between car and heavy transport vehicle, nontraffic, initial',
  'encounter',
  'V884XXD Person injured in collision between car and heavy transport vehicle, nontraffic, subsequent',
  'encounter',
  'V884XXS Person injured in collision between car and heavy transport vehicle, nontraffic, sequela',
  'V885XXA Person injured in collision between heavy transport vehicle and bus, nontraffic, initial',
  'encounter',
  'V885XXD Person injured in collision between heavy transport vehicle and bus, nontraffic, subsequent',
  'encounter',
  'V885XXS Person injured in collision between heavy transport vehicle and bus, nontraffic, sequela',
  'V886XXA Person injured in collision between railway train or railway vehicle and car, nontraffic, initial',
  'encounter',
  'V886XXD Person injured in collision between railway train or railway vehicle and car, nontraffic,',
  'subsequent encounter',
  'V886XXS Person injured in collision between railway train or railway vehicle and car, nontraffic, sequela',
  'V887XXA Person injured in collision between other specified motor vehicle, nontraffic, initial encounter',
  'V887XXD Person injured in collision between other specified motor vehicle, nontraffic, subsequent',
  'encounter',
  'V887XXS Person injured in collision between other specified motor vehicle, nontraffic, sequela',
  'V888XXA Person injured in other specified noncollision transport accidents involving motor vehicle,',
  'nontraffic, initial encounter',
  'V888XXD Person injured in other specified noncollision transport accidents involving motor vehicle,',
  'nontraffic, subsequent encounter',
  'V888XXS Person injured in other specified noncollision transport accidents involving motor vehicle,',
  'nontraffic, sequela',
  'V889XXA Person injured in other specified (collision)(noncollision) transport accidents involving',
  'nonmotor vehicle, nontraffic, initial encounter',
  'V889XXD Person injured in other specified (collision)(noncollision) transport accidents involving',
  'nonmotor vehicle, nontraffic, subsequent encounter',
  'V889XXS Person injured in other specified (collision)(noncollision) transport accidents involving',
  'nonmotor vehicle, nontraffic, sequela',
  'V890XXA Person injured in unspecified motor-vehicle accident, nontraffic, initial encounter',
  'V890XXD Person injured in unspecified motor-vehicle accident, nontraffic, subsequent encounter',
  'V890XXS Person injured in unspecified motor-vehicle accident, nontraffic, sequela',
  'V891XXA Person injured in unspecified nonmotor-vehicle accident, nontraffic, initial encounter',
  'V891XXD Person injured in unspecified nonmotor-vehicle accident, nontraffic, subsequent encounter',
  'V891XXS Person injured in unspecified nonmotor-vehicle accident, nontraffic, sequela',
  'V892XXA Person injured in unspecified motor-vehicle accident, traffic, initial encounter',
  'V892XXD Person injured in unspecified motor-vehicle accident, traffic, subsequent encounter',
  'V892XXS Person injured in unspecified motor-vehicle accident, traffic, sequela',
  'V893XXA Person injured in unspecified nonmotor-vehicle accident, traffic, initial encounter',
  'V893XXD Person injured in unspecified nonmotor-vehicle accident, traffic, subsequent encounter',
  'V893XXS Person injured in unspecified nonmotor-vehicle accident, traffic, sequela',
  'V899XXA Person injured in unspecified vehicle accident, initial encounter',
  'V899XXD Person injured in unspecified vehicle accident, subsequent encounter',
  'V899XXS Person injured in unspecified vehicle accident, sequela',
  'V9000XA Drowning and submersion due to merchant ship overturning, initial encounter',
  'V9000XD Drowning and submersion due to merchant ship overturning, subsequent encounter',
  'V9000XS Drowning and submersion due to merchant ship overturning, sequela',
  'V9001XA Drowning and submersion due to passenger ship overturning, initial encounter',
  'V9001XD Drowning and submersion due to passenger ship overturning, subsequent encounter',
  'V9001XS Drowning and submersion due to passenger ship overturning, sequela',
  'V9002XA Drowning and submersion due to fishing boat overturning, initial encounter',
  'V9002XD Drowning and submersion due to fishing boat overturning, subsequent encounter',
  'V9002XS Drowning and submersion due to fishing boat overturning, sequela',
  'V9003XA Drowning and submersion due to other powered watercraft overturning, initial encounter',
  'V9003XD Drowning and submersion due to other powered watercraft overturning, subsequent',
  'encounter',
  'V9003XS Drowning and submersion due to other powered watercraft overturning, sequela',
  'V9004XA Drowning and submersion due to sailboat overturning, initial encounter',
  'V9004XD Drowning and submersion due to sailboat overturning, subsequent encounter',
  'V9004XS Drowning and submersion due to sailboat overturning, sequela',
  'V9005XA Drowning and submersion due to canoe or kayak overturning, initial encounter',
  'V9005XD Drowning and submersion due to canoe or kayak overturning, subsequent encounter',
  'V9005XS Drowning and submersion due to canoe or kayak overturning, sequela',
  'V9006XA Drowning and submersion due to (nonpowered) inflatable craft overturning, initial encounter',
  'V9006XD Drowning and submersion due to (nonpowered) inflatable craft overturning, subsequent',
  'encounter',
  'V9006XS Drowning and submersion due to (nonpowered) inflatable craft overturning, sequela',
  'V9008XA Drowning and submersion due to other unpowered watercraft overturning, initial encounter',
  'V9008XD Drowning and submersion due to other unpowered watercraft overturning, subsequent',
  'encounter',
  'V9008XS Drowning and submersion due to other unpowered watercraft overturning, sequela',
  'V9009XA Drowning and submersion due to unspecified watercraft overturning, initial encounter',
  'V9009XD Drowning and submersion due to unspecified watercraft overturning, subsequent encounter',
  'V9009XS Drowning and submersion due to unspecified watercraft overturning, sequela',
  'V9010XA Drowning and submersion due to merchant ship sinking, initial encounter',
  'V9010XD Drowning and submersion due to merchant ship sinking, subsequent encounter',
  'V9010XS Drowning and submersion due to merchant ship sinking, sequela',
  'V9011XA Drowning and submersion due to passenger ship sinking, initial encounter',
  'V9011XD Drowning and submersion due to passenger ship sinking, subsequent encounter',
  'V9011XS Drowning and submersion due to passenger ship sinking, sequela',
  'V9012XA Drowning and submersion due to fishing boat sinking, initial encounter',
  'V9012XD Drowning and submersion due to fishing boat sinking, subsequent encounter',
  'V9012XS Drowning and submersion due to fishing boat sinking, sequela',
  'V9013XA Drowning and submersion due to other powered watercraft sinking, initial encounter',
  'V9013XD Drowning and submersion due to other powered watercraft sinking, subsequent encounter',
  'V9013XS Drowning and submersion due to other powered watercraft sinking, sequela',
  'V9014XA Drowning and submersion due to sailboat sinking, initial encounter',
  'V9014XD Drowning and submersion due to sailboat sinking, subsequent encounter',
  'V9014XS Drowning and submersion due to sailboat sinking, sequela',
  'V9015XA Drowning and submersion due to canoe or kayak sinking, initial encounter',
  'V9015XD Drowning and submersion due to canoe or kayak sinking, subsequent encounter',
  'V9015XS Drowning and submersion due to canoe or kayak sinking, sequela',
  'V9016XA Drowning and submersion due to (nonpowered) inflatable craft sinking, initial encounter',
  'V9016XD Drowning and submersion due to (nonpowered) inflatable craft sinking, subsequent encounter',
  'V9016XS Drowning and submersion due to (nonpowered) inflatable craft sinking, sequela',
  'V9018XA Drowning and submersion due to other unpowered watercraft sinking, initial encounter',
  'V9018XD Drowning and submersion due to other unpowered watercraft sinking, subsequent encounter',
  'V9018XS Drowning and submersion due to other unpowered watercraft sinking, sequela',
  'V9019XA Drowning and submersion due to unspecified watercraft sinking, initial encounter',
  'V9019XD Drowning and submersion due to unspecified watercraft sinking, subsequent encounter',
  'V9019XS Drowning and submersion due to unspecified watercraft sinking, sequela',
  'V9020XA Drowning and submersion due to falling or jumping from burning merchant ship, initial',
  'encounter',
  'V9020XD Drowning and submersion due to falling or jumping from burning merchant ship, subsequent',
  'encounter',
  'V9020XS Drowning and submersion due to falling or jumping from burning merchant ship, sequela',
  'V9021XA Drowning and submersion due to falling or jumping from burning passenger ship, initial',
  'encounter',
  'V9021XD Drowning and submersion due to falling or jumping from burning passenger ship, subsequent',
  'encounter',
  'V9021XS Drowning and submersion due to falling or jumping from burning passenger ship, sequela',
  'V9022XA Drowning and submersion due to falling or jumping from burning fishing boat, initial',
  'encounter',
  'V9022XD Drowning and submersion due to falling or jumping from burning fishing boat, subsequent',
  'encounter',
  'V9022XS Drowning and submersion due to falling or jumping from burning fishing boat, sequela',
  'V9023XA Drowning and submersion due to falling or jumping from other burning powered watercraft,',
  'initial encounter',
  'V9023XD Drowning and submersion due to falling or jumping from other burning powered watercraft,',
  'subsequent encounter',
  'V9023XS Drowning and submersion due to falling or jumping from other burning powered watercraft,',
  'sequela',
  'V9024XA Drowning and submersion due to falling or jumping from burning sailboat, initial encounter',
  'V9024XD Drowning and submersion due to falling or jumping from burning sailboat, subsequent',
  'encounter',
  'V9024XS Drowning and submersion due to falling or jumping from burning sailboat, sequela',
  'V9025XA Drowning and submersion due to falling or jumping from burning canoe or kayak, initial',
  'encounter',
  'V9025XD Drowning and submersion due to falling or jumping from burning canoe or kayak, subsequent',
  'encounter',
  'V9025XS Drowning and submersion due to falling or jumping from burning canoe or kayak, sequela',
  'V9026XA Drowning and submersion due to falling or jumping from burning (nonpowered) inflatable',
  'craft, initial encounter',
  'V9026XD Drowning and submersion due to falling or jumping from burning (nonpowered) inflatable',
  'craft, subsequent encounter',
  'V9026XS Drowning and submersion due to falling or jumping from burning (nonpowered) inflatable',
  'craft, sequela',
  'V9027XA Drowning and submersion due to falling or jumping from burning water-skis, initial encounter',
  'V9027XD Drowning and submersion due to falling or jumping from burning water-skis, subsequent',
  'encounter',
  'V9027XS Drowning and submersion due to falling or jumping from burning water-skis, sequela',
  'V9028XA Drowning and submersion due to falling or jumping from other burning unpowered watercraft,',
  'initial encounter',
  'V9028XD Drowning and submersion due to falling or jumping from other burning unpowered',
  'watercraft, subsequent encounter',
  'V9028XS Drowning and submersion due to falling or jumping from other burning unpowered watercraft,',
  'sequela',
  'V9029XA Drowning and submersion due to falling or jumping from unspecified burning watercraft, initial',
  'encounter',
  'V9029XD Drowning and submersion due to falling or jumping from unspecified burning watercraft,',
  'subsequent encounter',
  'V9029XS Drowning and submersion due to falling or jumping from unspecified burning watercraft,',
  'sequela',
  'V9030XA Drowning and submersion due to falling or jumping from crushed merchant ship, initial',
  'encounter',
  'V9030XD Drowning and submersion due to falling or jumping from crushed merchant ship, subsequent',
  'encounter',
  'V9030XS Drowning and submersion due to falling or jumping from crushed merchant ship, sequela',
  'V9031XA Drowning and submersion due to falling or jumping from crushed passenger ship, initial',
  'encounter',
  'V9031XD Drowning and submersion due to falling or jumping from crushed passenger ship, subsequent',
  'encounter',
  'V9031XS Drowning and submersion due to falling or jumping from crushed passenger ship, sequela',
  'V9032XA Drowning and submersion due to falling or jumping from crushed fishing boat, initial',
  'encounter',
  'V9032XD Drowning and submersion due to falling or jumping from crushed fishing boat, subsequent',
  'encounter',
  'V9032XS Drowning and submersion due to falling or jumping from crushed fishing boat, sequela',
  'V9033XA Drowning and submersion due to falling or jumping from other crushed powered watercraft,',
  'initial encounter',
  'V9033XD Drowning and submersion due to falling or jumping from other crushed powered watercraft,',
  'subsequent encounter',
  'V9033XS Drowning and submersion due to falling or jumping from other crushed powered watercraft,',
  'sequela',
  'V9034XA Drowning and submersion due to falling or jumping from crushed sailboat, initial encounter',
  'V9034XD Drowning and submersion due to falling or jumping from crushed sailboat, subsequent',
  'encounter',
  'V9034XS Drowning and submersion due to falling or jumping from crushed sailboat, sequela',
  'V9035XA Drowning and submersion due to falling or jumping from crushed canoe or kayak, initial',
  'encounter',
  'V9035XD Drowning and submersion due to falling or jumping from crushed canoe or kayak, subsequent',
  'encounter',
  'V9035XS Drowning and submersion due to falling or jumping from crushed canoe or kayak, sequela',
  'V9036XA Drowning and submersion due to falling or jumping from crushed (nonpowered) inflatable',
  'craft, initial encounter',
  'V9036XD Drowning and submersion due to falling or jumping from crushed (nonpowered) inflatable',
  'craft, subsequent encounter',
  'V9036XS Drowning and submersion due to falling or jumping from crushed (nonpowered) inflatable',
  'craft, sequela',
  'V9037XA Drowning and submersion due to falling or jumping from crushed water-skis, initial encounter',
  'V9037XD Drowning and submersion due to falling or jumping from crushed water-skis, subsequent',
  'encounter',
  'V9037XS Drowning and submersion due to falling or jumping from crushed water-skis, sequela',
  'V9038XA Drowning and submersion due to falling or jumping from other crushed unpowered',
  'watercraft, initial encounter',
  'V9038XD Drowning and submersion due to falling or jumping from other crushed unpowered',
  'watercraft, subsequent encounter',
  'V9038XS Drowning and submersion due to falling or jumping from other crushed unpowered watercraft,',
  'sequela',
  'V9039XA Drowning and submersion due to falling or jumping from crushed unspecified watercraft,',
  'initial encounter',
  'V9039XD Drowning and submersion due to falling or jumping from crushed unspecified watercraft,',
  'subsequent encounter',
  'V9039XS Drowning and submersion due to falling or jumping from crushed unspecified watercraft,',
  'sequela',
  'V9080XA Drowning and submersion due to other accident to merchant ship, initial encounter',
  'V9080XD Drowning and submersion due to other accident to merchant ship, subsequent encounter',
  'V9080XS Drowning and submersion due to other accident to merchant ship, sequela',
  'V9081XA Drowning and submersion due to other accident to passenger ship, initial encounter',
  'V9081XD Drowning and submersion due to other accident to passenger ship, subsequent encounter',
  'V9081XS Drowning and submersion due to other accident to passenger ship, sequela',
  'V9082XA Drowning and submersion due to other accident to fishing boat, initial encounter',
  'V9082XD Drowning and submersion due to other accident to fishing boat, subsequent encounter',
  'V9082XS Drowning and submersion due to other accident to fishing boat, sequela',
  'V9083XA Drowning and submersion due to other accident to other powered watercraft, initial',
  'encounter',
  'V9083XD Drowning and submersion due to other accident to other powered watercraft, subsequent',
  'encounter',
  'V9083XS Drowning and submersion due to other accident to other powered watercraft, sequela',
  'V9084XA Drowning and submersion due to other accident to sailboat, initial encounter',
  'V9084XD Drowning and submersion due to other accident to sailboat, subsequent encounter',
  'V9084XS Drowning and submersion due to other accident to sailboat, sequela',
  'V9085XA Drowning and submersion due to other accident to canoe or kayak, initial encounter',
  'V9085XD Drowning and submersion due to other accident to canoe or kayak, subsequent encounter',
  'V9085XS Drowning and submersion due to other accident to canoe or kayak, sequela',
  'V9086XA Drowning and submersion due to other accident to (nonpowered) inflatable craft, initial',
  'encounter',
  'V9086XD Drowning and submersion due to other accident to (nonpowered) inflatable craft, subsequent',
  'encounter',
  'V9086XS Drowning and submersion due to other accident to (nonpowered) inflatable craft, sequela',
  'V9087XA Drowning and submersion due to other accident to water-skis, initial encounter',
  'V9087XD Drowning and submersion due to other accident to water-skis, subsequent encounter',
  'V9087XS Drowning and submersion due to other accident to water-skis, sequela',
  'V9088XA Drowning and submersion due to other accident to other unpowered watercraft, initial',
  'encounter',
  'V9088XD Drowning and submersion due to other accident to other unpowered watercraft, subsequent',
  'encounter',
  'V9088XS Drowning and submersion due to other accident to other unpowered watercraft, sequela',
  'V9089XA Drowning and submersion due to other accident to unspecified watercraft, initial encounter',
  'V9089XD Drowning and submersion due to other accident to unspecified watercraft, subsequent',
  'encounter',
  'V9089XS Drowning and submersion due to other accident to unspecified watercraft, sequela',
  'V9100XA Burn due to merchant ship on fire, initial encounter',
  'V9100XD Burn due to merchant ship on fire, subsequent encounter',
  'V9100XS Burn due to merchant ship on fire, sequela',
  'V9101XA Burn due to passenger ship on fire, initial encounter',
  'V9101XD Burn due to passenger ship on fire, subsequent encounter',
  'V9101XS Burn due to passenger ship on fire, sequela',
  'V9102XA Burn due to fishing boat on fire, initial encounter',
  'V9102XD Burn due to fishing boat on fire, subsequent encounter',
  'V9102XS Burn due to fishing boat on fire, sequela',
  'V9103XA Burn due to other powered watercraft on fire, initial encounter',
  'V9103XD Burn due to other powered watercraft on fire, subsequent encounter',
  'V9103XS Burn due to other powered watercraft on fire, sequela',
  'V9104XA Burn due to sailboat on fire, initial encounter',
  'V9104XD Burn due to sailboat on fire, subsequent encounter',
  'V9104XS Burn due to sailboat on fire, sequela',
  'V9105XA Burn due to canoe or kayak on fire, initial encounter',
  'V9105XD Burn due to canoe or kayak on fire, subsequent encounter',
  'V9105XS Burn due to canoe or kayak on fire, sequela',
  'V9106XA Burn due to (nonpowered) inflatable craft on fire, initial encounter',
  'V9106XD Burn due to (nonpowered) inflatable craft on fire, subsequent encounter',
  'V9106XS Burn due to (nonpowered) inflatable craft on fire, sequela',
  'V9107XA Burn due to water-skis on fire, initial encounter',
  'V9107XD Burn due to water-skis on fire, subsequent encounter',
  'V9107XS Burn due to water-skis on fire, sequela',
  'V9108XA Burn due to other unpowered watercraft on fire, initial encounter',
  'V9108XD Burn due to other unpowered watercraft on fire, subsequent encounter',
  'V9108XS Burn due to other unpowered watercraft on fire, sequela',
  'V9109XA Burn due to unspecified watercraft on fire, initial encounter',
  'V9109XD Burn due to unspecified watercraft on fire, subsequent encounter',
  'V9109XS Burn due to unspecified watercraft on fire, sequela',
  'V9110XA Crushed between merchant ship and other watercraft or other object due to collision, initial',
  'encounter',
  'V9110XD Crushed between merchant ship and other watercraft or other object due to collision,',
  'subsequent encounter',
  'V9110XS Crushed between merchant ship and other watercraft or other object due to collision, sequela',
  'V9111XA Crushed between passenger ship and other watercraft or other object due to collision, initial',
  'encounter',
  'V9111XD Crushed between passenger ship and other watercraft or other object due to collision,',
  'subsequent encounter',
  'V9111XS Crushed between passenger ship and other watercraft or other object due to collision, sequela',
  'V9112XA Crushed between fishing boat and other watercraft or other object due to collision, initial',
  'encounter',
  'V9112XD Crushed between fishing boat and other watercraft or other object due to collision,',
  'subsequent encounter',
  'V9112XS Crushed between fishing boat and other watercraft or other object due to collision, sequela',
  'V9113XA Crushed between other powered watercraft and other watercraft or other object due to',
  'collision, initial encounter',
  'V9113XD Crushed between other powered watercraft and other watercraft or other object due to',
  'collision, subsequent encounter',
  'V9113XS Crushed between other powered watercraft and other watercraft or other object due to',
  'collision, sequela',
  'V9114XA Crushed between sailboat and other watercraft or other object due to collision, initial',
  'encounter',
  'V9114XD Crushed between sailboat and other watercraft or other object due to collision, subsequent',
  'encounter',
  'V9114XS Crushed between sailboat and other watercraft or other object due to collision, sequela',
  'V9115XA Crushed between canoe or kayak and other watercraft or other object due to collision, initial',
  'encounter',
  'V9115XD Crushed between canoe or kayak and other watercraft or other object due to collision,',
  'subsequent encounter',
  'V9115XS Crushed between canoe or kayak and other watercraft or other object due to collision, sequela',
  'V9116XA Crushed between (nonpowered) inflatable craft and other watercraft or other object due to',
  'collision, initial encounter',
  'V9116XD Crushed between (nonpowered) inflatable craft and other watercraft or other object due to',
  'collision, subsequent encounter',
  'V9116XS Crushed between (nonpowered) inflatable craft and other watercraft or other object due to',
  'collision, sequela',
  'V9118XA Crushed between other unpowered watercraft and other watercraft or other object due to',
  'collision, initial encounter',
  'V9118XD Crushed between other unpowered watercraft and other watercraft or other object due to',
  'collision, subsequent encounter',
  'V9118XS Crushed between other unpowered watercraft and other watercraft or other object due to',
  'collision, sequela',
  'V9119XA Crushed between unspecified watercraft and other watercraft or other object due to collision,',
  'initial encounter',
  'V9119XD Crushed between unspecified watercraft and other watercraft or other object due to collision,',
  'subsequent encounter',
  'V9119XS Crushed between unspecified watercraft and other watercraft or other object due to collision,',
  'sequela',
  'V9120XA Fall due to collision between merchant ship and other watercraft or other object, initial',
  'encounter',
  'V9120XD Fall due to collision between merchant ship and other watercraft or other object, subsequent',
  'encounter',
  'V9120XS Fall due to collision between merchant ship and other watercraft or other object, sequela',
  'V9121XA Fall due to collision between passenger ship and other watercraft or other object, initial',
  'encounter',
  'V9121XD Fall due to collision between passenger ship and other watercraft or other object, subsequent',
  'encounter',
  'V9121XS Fall due to collision between passenger ship and other watercraft or other object, sequela',
  'V9122XA Fall due to collision between fishing boat and other watercraft or other object, initial',
  'encounter',
  'V9122XD Fall due to collision between fishing boat and other watercraft or other object, subsequent',
  'encounter',
  'V9122XS Fall due to collision between fishing boat and other watercraft or other object, sequela',
  'V9123XA Fall due to collision between other powered watercraft and other watercraft or other object,',
  'initial encounter',
  'V9123XD Fall due to collision between other powered watercraft and other watercraft or other object,',
  'subsequent encounter',
  'V9123XS Fall due to collision between other powered watercraft and other watercraft or other object,',
  'sequela',
  'V9124XA Fall due to collision between sailboat and other watercraft or other object, initial encounter',
  'V9124XD Fall due to collision between sailboat and other watercraft or other object, subsequent',
  'encounter',
  'V9124XS Fall due to collision between sailboat and other watercraft or other object, sequela',
  'V9125XA Fall due to collision between canoe or kayak and other watercraft or other object, initial',
  'encounter',
  'V9125XD Fall due to collision between canoe or kayak and other watercraft or other object, subsequent',
  'encounter',
  'V9125XS Fall due to collision between canoe or kayak and other watercraft or other object, sequela',
  'V9126XA Fall due to collision between (nonpowered) inflatable craft and other watercraft or other',
  'object, initial encounter',
  'V9126XD Fall due to collision between (nonpowered) inflatable craft and other watercraft or other',
  'object, subsequent encounter',
  'V9126XS Fall due to collision between (nonpowered) inflatable craft and other watercraft or other',
  'object, sequela',
  'V9129XA Fall due to collision between unspecified watercraft and other watercraft or other object,',
  'initial encounter',
  'V9129XD Fall due to collision between unspecified watercraft and other watercraft or other object,',
  'subsequent encounter',
  'V9129XS Fall due to collision between unspecified watercraft and other watercraft or other object,',
  'sequela',
  'V9130XA Hit or struck by falling object due to accident to merchant ship, initial encounter',
  'V9130XD Hit or struck by falling object due to accident to merchant ship, subsequent encounter',
  'V9130XS Hit or struck by falling object due to accident to merchant ship, sequela',
  'V9131XA Hit or struck by falling object due to accident to passenger ship, initial encounter',
  'V9131XD Hit or struck by falling object due to accident to passenger ship, subsequent encounter',
  'V9131XS Hit or struck by falling object due to accident to passenger ship, sequela',
  'V9132XA Hit or struck by falling object due to accident to fishing boat, initial encounter',
  'V9132XD Hit or struck by falling object due to accident to fishing boat, subsequent encounter',
  'V9132XS Hit or struck by falling object due to accident to fishing boat, sequela',
  'V9133XA Hit or struck by falling object due to accident to other powered watercraft, initial encounter',
  'V9133XD Hit or struck by falling object due to accident to other powered watercraft, subsequent',
  'encounter',
  'V9133XS Hit or struck by falling object due to accident to other powered watercraft, sequela',
  'V9134XA Hit or struck by falling object due to accident to sailboat, initial encounter',
  'V9134XD Hit or struck by falling object due to accident to sailboat, subsequent encounter',
  'V9134XS Hit or struck by falling object due to accident to sailboat, sequela',
  'V9135XA Hit or struck by falling object due to accident to canoe or kayak, initial encounter',
  'V9135XD Hit or struck by falling object due to accident to canoe or kayak, subsequent encounter',
  'V9135XS Hit or struck by falling object due to accident to canoe or kayak, sequela',
  'V9136XA Hit or struck by falling object due to accident to (nonpowered) inflatable craft, initial',
  'encounter',
  'V9136XD Hit or struck by falling object due to accident to (nonpowered) inflatable craft, subsequent',
  'encounter',
  'V9136XS Hit or struck by falling object due to accident to (nonpowered) inflatable craft, sequela',
  'V9137XA Hit or struck by falling object due to accident to water-skis, initial encounter',
  'V9137XD Hit or struck by falling object due to accident to water-skis, subsequent encounter',
  'V9137XS Hit or struck by falling object due to accident to water-skis, sequela',
  'V9138XA Hit or struck by falling object due to accident to other unpowered watercraft, initial encounter',
  'V9138XD Hit or struck by falling object due to accident to other unpowered watercraft, subsequent',
  'encounter',
  'V9138XS Hit or struck by falling object due to accident to other unpowered watercraft, sequela',
  'V9139XA Hit or struck by falling object due to accident to unspecified watercraft, initial encounter',
  'V9139XD Hit or struck by falling object due to accident to unspecified watercraft, subsequent encounter',
  'V9139XS Hit or struck by falling object due to accident to unspecified watercraft, sequela',
  'V9180XA Other injury due to other accident to merchant ship, initial encounter',
  'V9180XD Other injury due to other accident to merchant ship, subsequent encounter',
  'V9180XS Other injury due to other accident to merchant ship, sequela',
  'V9181XA Other injury due to other accident to passenger ship, initial encounter',
  'V9181XD Other injury due to other accident to passenger ship, subsequent encounter',
  'V9181XS Other injury due to other accident to passenger ship, sequela',
  'V9182XA Other injury due to other accident to fishing boat, initial encounter',
  'V9182XD Other injury due to other accident to fishing boat, subsequent encounter',
  'V9182XS Other injury due to other accident to fishing boat, sequela',
  'V9183XA Other injury due to other accident to other powered watercraft, initial encounter',
  'V9183XD Other injury due to other accident to other powered watercraft, subsequent encounter',
  'V9183XS Other injury due to other accident to other powered watercraft, sequela',
  'V9184XA Other injury due to other accident to sailboat, initial encounter',
  'V9184XD Other injury due to other accident to sailboat, subsequent encounter',
  'V9184XS Other injury due to other accident to sailboat, sequela',
  'V9185XA Other injury due to other accident to canoe or kayak, initial encounter',
  'V9185XD Other injury due to other accident to canoe or kayak, subsequent encounter',
  'V9185XS Other injury due to other accident to canoe or kayak, sequela',
  'V9186XA Other injury due to other accident to (nonpowered) inflatable craft, initial encounter',
  'V9186XD Other injury due to other accident to (nonpowered) inflatable craft, subsequent encounter',
  'V9186XS Other injury due to other accident to (nonpowered) inflatable craft, sequela',
  'V9187XA Other injury due to other accident to water-skis, initial encounter',
  'V9187XD Other injury due to other accident to water-skis, subsequent encounter',
  'V9187XS Other injury due to other accident to water-skis, sequela',
  'V9188XA Other injury due to other accident to other unpowered watercraft, initial encounter',
  'V9188XD Other injury due to other accident to other unpowered watercraft, subsequent encounter',
  'V9188XS Other injury due to other accident to other unpowered watercraft, sequela',
  'V9189XA Other injury due to other accident to unspecified watercraft, initial encounter',
  'V9189XD Other injury due to other accident to unspecified watercraft, subsequent encounter',
  'V9189XS Other injury due to other accident to unspecified watercraft, sequela',
  'V9200XA Drowning and submersion due to fall off merchant ship, initial encounter',
  'V9200XD Drowning and submersion due to fall off merchant ship, subsequent encounter',
  'V9200XS Drowning and submersion due to fall off merchant ship, sequela',
  'V9201XA Drowning and submersion due to fall off passenger ship, initial encounter',
  'V9201XD Drowning and submersion due to fall off passenger ship, subsequent encounter',
  'V9201XS Drowning and submersion due to fall off passenger ship, sequela',
  'V9202XA Drowning and submersion due to fall off fishing boat, initial encounter',
  'V9202XD Drowning and submersion due to fall off fishing boat, subsequent encounter',
  'V9202XS Drowning and submersion due to fall off fishing boat, sequela',
  'V9203XA Drowning and submersion due to fall off other powered watercraft, initial encounter',
  'V9203XD Drowning and submersion due to fall off other powered watercraft, subsequent encounter',
  'V9203XS Drowning and submersion due to fall off other powered watercraft, sequela',
  'V9204XA Drowning and submersion due to fall off sailboat, initial encounter',
  'V9204XD Drowning and submersion due to fall off sailboat, subsequent encounter',
  'V9204XS Drowning and submersion due to fall off sailboat, sequela',
  'V9205XA Drowning and submersion due to fall off canoe or kayak, initial encounter',
  'V9205XD Drowning and submersion due to fall off canoe or kayak, subsequent encounter',
  'V9205XS Drowning and submersion due to fall off canoe or kayak, sequela',
  'V9206XA Drowning and submersion due to fall off (nonpowered) inflatable craft, initial encounter',
  'V9206XD Drowning and submersion due to fall off (nonpowered) inflatable craft, subsequent encounter',
  'V9206XS Drowning and submersion due to fall off (nonpowered) inflatable craft, sequela',
  'V9207XA Drowning and submersion due to fall off water-skis, initial encounter',
  'V9207XD Drowning and submersion due to fall off water-skis, subsequent encounter',
  'V9207XS Drowning and submersion due to fall off water-skis, sequela',
  'V9208XA Drowning and submersion due to fall off other unpowered watercraft, initial encounter',
  'V9208XD Drowning and submersion due to fall off other unpowered watercraft, subsequent encounter',
  'V9208XS Drowning and submersion due to fall off other unpowered watercraft, sequela',
  'V9209XA Drowning and submersion due to fall off unspecified watercraft, initial encounter',
  'V9209XD Drowning and submersion due to fall off unspecified watercraft, subsequent encounter',
  'V9209XS Drowning and submersion due to fall off unspecified watercraft, sequela',
  'V9210XA Drowning and submersion due to being thrown overboard by motion of merchant ship, initial',
  'encounter',
  'V9210XD Drowning and submersion due to being thrown overboard by motion of merchant ship,',
  'subsequent encounter',
  'V9210XS Drowning and submersion due to being thrown overboard by motion of merchant ship,',
  'sequela',
  'V9211XA Drowning and submersion due to being thrown overboard by motion of passenger ship, initial',
  'encounter',
  'V9211XD Drowning and submersion due to being thrown overboard by motion of passenger ship,',
  'subsequent encounter',
  'V9211XS Drowning and submersion due to being thrown overboard by motion of passenger ship,',
  'sequela',
  'V9212XA Drowning and submersion due to being thrown overboard by motion of fishing boat, initial',
  'encounter',
  'V9212XD Drowning and submersion due to being thrown overboard by motion of fishing boat,',
  'subsequent encounter',
  'V9212XS Drowning and submersion due to being thrown overboard by motion of fishing boat, sequela',
  'V9213XA Drowning and submersion due to being thrown overboard by motion of other powered',
  'watercraft, initial encounter',
  'V9213XD Drowning and submersion due to being thrown overboard by motion of other powered',
  'watercraft, subsequent encounter',
  'V9213XS Drowning and submersion due to being thrown overboard by motion of other powered',
  'watercraft, sequela',
  'V9214XA Drowning and submersion due to being thrown overboard by motion of sailboat, initial',
  'encounter',
  'V9214XD Drowning and submersion due to being thrown overboard by motion of sailboat, subsequent',
  'encounter',
  'V9214XS Drowning and submersion due to being thrown overboard by motion of sailboat, sequela',
  'V9215XA Drowning and submersion due to being thrown overboard by motion of canoe or kayak, initial',
  'encounter',
  'V9215XD Drowning and submersion due to being thrown overboard by motion of canoe or kayak,',
  'subsequent encounter',
  'V9215XS Drowning and submersion due to being thrown overboard by motion of canoe or kayak,',
  'sequela',
  'V9216XA Drowning and submersion due to being thrown overboard by motion of (nonpowered)',
  'inflatable craft, initial encounter',
  'V9216XD Drowning and submersion due to being thrown overboard by motion of (nonpowered)',
  'inflatable craft, subsequent encounter',
  'V9216XS Drowning and submersion due to being thrown overboard by motion of (nonpowered)',
  'inflatable craft, sequela',
  'V9219XA Drowning and submersion due to being thrown overboard by motion of unspecified',
  'watercraft, initial encounter',
  'V9219XD Drowning and submersion due to being thrown overboard by motion of unspecified',
  'watercraft, subsequent encounter',
  'V9219XS Drowning and submersion due to being thrown overboard by motion of unspecified',
  'watercraft, sequela',
  'V9220XA Drowning and submersion due to being washed overboard from merchant ship, initial',
  'encounter',
  'V9220XD Drowning and submersion due to being washed overboard from merchant ship, subsequent',
  'encounter',
  'V9220XS Drowning and submersion due to being washed overboard from merchant ship, sequela',
  'V9221XA Drowning and submersion due to being washed overboard from passenger ship, initial',
  'encounter',
  'V9221XD Drowning and submersion due to being washed overboard from passenger ship, subsequent',
  'encounter',
  'V9221XS Drowning and submersion due to being washed overboard from passenger ship, sequela',
  'V9222XA Drowning and submersion due to being washed overboard from fishing boat, initial encounter',
  'V9222XD Drowning and submersion due to being washed overboard from fishing boat, subsequent',
  'encounter',
  'V9222XS Drowning and submersion due to being washed overboard from fishing boat, sequela',
  'V9223XA Drowning and submersion due to being washed overboard from other powered watercraft,',
  'initial encounter',
  'V9223XD Drowning and submersion due to being washed overboard from other powered watercraft,',
  'subsequent encounter',
  'V9223XS Drowning and submersion due to being washed overboard from other powered watercraft,',
  'sequela',
  'V9224XA Drowning and submersion due to being washed overboard from sailboat, initial encounter',
  'V9224XD Drowning and submersion due to being washed overboard from sailboat, subsequent',
  'encounter',
  'V9224XS Drowning and submersion due to being washed overboard from sailboat, sequela',
  'V9225XA Drowning and submersion due to being washed overboard from canoe or kayak, initial',
  'encounter',
  'V9225XD Drowning and submersion due to being washed overboard from canoe or kayak, subsequent',
  'encounter',
  'V9225XS Drowning and submersion due to being washed overboard from canoe or kayak, sequela',
  'V9226XA Drowning and submersion due to being washed overboard from (nonpowered) inflatable craft,',
  'initial encounter',
  'V9226XD Drowning and submersion due to being washed overboard from (nonpowered) inflatable craft,',
  'subsequent encounter',
  'V9226XS Drowning and submersion due to being washed overboard from (nonpowered) inflatable craft,',
  'sequela',
  'V9227XA Drowning and submersion due to being washed overboard from water-skis, initial encounter',
  'V9227XD Drowning and submersion due to being washed overboard from water-skis, subsequent',
  'encounter',
  'V9227XS Drowning and submersion due to being washed overboard from water-skis, sequela',
  'V9228XA Drowning and submersion due to being washed overboard from other unpowered watercraft,',
  'initial encounter',
  'V9228XD Drowning and submersion due to being washed overboard from other unpowered watercraft,',
  'subsequent encounter',
  'V9228XS Drowning and submersion due to being washed overboard from other unpowered watercraft,',
  'sequela',
  'V9229XA Drowning and submersion due to being washed overboard from unspecified watercraft, initial',
  'encounter',
  'V9229XD Drowning and submersion due to being washed overboard from unspecified watercraft,',
  'subsequent encounter',
  'V9229XS Drowning and submersion due to being washed overboard from unspecified watercraft,',
  'sequela',
  'V9300XA Burn due to localized fire on board merchant vessel, initial encounter',
  'V9300XD Burn due to localized fire on board merchant vessel, subsequent encounter',
  'V9300XS Burn due to localized fire on board merchant vessel, sequela',
  'V9301XA Burn due to localized fire on board passenger vessel, initial encounter',
  'V9301XD Burn due to localized fire on board passenger vessel, subsequent encounter',
  'V9301XS Burn due to localized fire on board passenger vessel, sequela',
  'V9302XA Burn due to localized fire on board fishing boat, initial encounter',
  'V9302XD Burn due to localized fire on board fishing boat, subsequent encounter',
  'V9302XS Burn due to localized fire on board fishing boat, sequela',
  'V9303XA Burn due to localized fire on board other powered watercraft, initial encounter',
  'V9303XD Burn due to localized fire on board other powered watercraft, subsequent encounter',
  'V9303XS Burn due to localized fire on board other powered watercraft, sequela',
  'V9304XA Burn due to localized fire on board sailboat, initial encounter',
  'V9304XD Burn due to localized fire on board sailboat, subsequent encounter',
  'V9304XS Burn due to localized fire on board sailboat, sequela',
  'V9309XA Burn due to localized fire on board unspecified watercraft, initial encounter',
  'V9309XD Burn due to localized fire on board unspecified watercraft, subsequent encounter',
  'V9309XS Burn due to localized fire on board unspecified watercraft, sequela',
  'V9310XA Other burn on board merchant vessel, initial encounter',
  'V9310XD Other burn on board merchant vessel, subsequent encounter',
  'V9310XS Other burn on board merchant vessel, sequela',
  'V9311XA Other burn on board passenger vessel, initial encounter',
  'V9311XD Other burn on board passenger vessel, subsequent encounter',
  'V9311XS Other burn on board passenger vessel, sequela',
  'V9312XA Other burn on board fishing boat, initial encounter',
  'V9312XD Other burn on board fishing boat, subsequent encounter',
  'V9312XS Other burn on board fishing boat, sequela',
  'V9313XA Other burn on board other powered watercraft, initial encounter',
  'V9313XD Other burn on board other powered watercraft, subsequent encounter',
  'V9313XS Other burn on board other powered watercraft, sequela',
  'V9314XA Other burn on board sailboat, initial encounter',
  'V9314XD Other burn on board sailboat, subsequent encounter',
  'V9314XS Other burn on board sailboat, sequela',
  'V9319XA Other burn on board unspecified watercraft, initial encounter',
  'V9319XD Other burn on board unspecified watercraft, subsequent encounter',
  'V9319XS Other burn on board unspecified watercraft, sequela',
  'V9320XA Heat exposure on board merchant ship, initial encounter',
  'V9320XD Heat exposure on board merchant ship, subsequent encounter',
  'V9320XS Heat exposure on board merchant ship, sequela',
  'V9321XA Heat exposure on board passenger ship, initial encounter',
  'V9321XD Heat exposure on board passenger ship, subsequent encounter',
  'V9321XS Heat exposure on board passenger ship, sequela',
  'V9322XA Heat exposure on board fishing boat, initial encounter',
  'V9322XD Heat exposure on board fishing boat, subsequent encounter',
  'V9322XS Heat exposure on board fishing boat, sequela',
  'V9323XA Heat exposure on board other powered watercraft, initial encounter',
  'V9323XD Heat exposure on board other powered watercraft, subsequent encounter',
  'V9323XS Heat exposure on board other powered watercraft, sequela',
  'V9324XA Heat exposure on board sailboat, initial encounter',
  'V9324XD Heat exposure on board sailboat, subsequent encounter',
  'V9324XS Heat exposure on board sailboat, sequela',
  'V9329XA Heat exposure on board unspecified watercraft, initial encounter',
  'V9329XD Heat exposure on board unspecified watercraft, subsequent encounter',
  'V9329XS Heat exposure on board unspecified watercraft, sequela',
  'V9330XA Fall on board merchant ship, initial encounter',
  'V9330XD Fall on board merchant ship, subsequent encounter',
  'V9330XS Fall on board merchant ship, sequela',
  'V9331XA Fall on board passenger ship, initial encounter',
  'V9331XD Fall on board passenger ship, subsequent encounter',
  'V9331XS Fall on board passenger ship, sequela',
  'V9332XA Fall on board fishing boat, initial encounter',
  'V9332XD Fall on board fishing boat, subsequent encounter',
  'V9332XS Fall on board fishing boat, sequela',
  'V9333XA Fall on board other powered watercraft, initial encounter',
  'V9333XD Fall on board other powered watercraft, subsequent encounter',
  'V9333XS Fall on board other powered watercraft, sequela',
  'V9334XA Fall on board sailboat, initial encounter',
  'V9334XD Fall on board sailboat, subsequent encounter',
  'V9334XS Fall on board sailboat, sequela',
  'V9335XA Fall on board canoe or kayak, initial encounter',
  'V9335XD Fall on board canoe or kayak, subsequent encounter',
  'V9335XS Fall on board canoe or kayak, sequela',
  'V9336XA Fall on board (nonpowered) inflatable craft, initial encounter',
  'V9336XD Fall on board (nonpowered) inflatable craft, subsequent encounter',
  'V9336XS Fall on board (nonpowered) inflatable craft, sequela',
  'V9338XA Fall on board other unpowered watercraft, initial encounter',
  'V9338XD Fall on board other unpowered watercraft, subsequent encounter',
  'V9338XS Fall on board other unpowered watercraft, sequela',
  'V9339XA Fall on board unspecified watercraft, initial encounter',
  'V9339XD Fall on board unspecified watercraft, subsequent encounter',
  'V9339XS Fall on board unspecified watercraft, sequela',
  'V9340XA Struck by falling object on merchant ship, initial encounter',
  'V9340XD Struck by falling object on merchant ship, subsequent encounter',
  'V9340XS Struck by falling object on merchant ship, sequela',
  'V9341XA Struck by falling object on passenger ship, initial encounter',
  'V9341XD Struck by falling object on passenger ship, subsequent encounter',
  'V9341XS Struck by falling object on passenger ship, sequela',
  'V9342XA Struck by falling object on fishing boat, initial encounter',
  'V9342XD Struck by falling object on fishing boat, subsequent encounter',
  'V9342XS Struck by falling object on fishing boat, sequela',
  'V9343XA Struck by falling object on other powered watercraft, initial encounter',
  'V9343XD Struck by falling object on other powered watercraft, subsequent encounter',
  'V9343XS Struck by falling object on other powered watercraft, sequela',
  'V9344XA Struck by falling object on sailboat, initial encounter',
  'V9344XD Struck by falling object on sailboat, subsequent encounter',
  'V9344XS Struck by falling object on sailboat, sequela',
  'V9348XA Struck by falling object on other unpowered watercraft, initial encounter',
  'V9348XD Struck by falling object on other unpowered watercraft, subsequent encounter',
  'V9348XS Struck by falling object on other unpowered watercraft, sequela',
  'V9349XA Struck by falling object on unspecified watercraft, initial encounter',
  'V9349XD Struck by falling object on unspecified watercraft, subsequent encounter',
  'V9349XS Struck by falling object on unspecified watercraft, sequela',
  'V9350XA Explosion on board merchant ship, initial encounter',
  'V9350XD Explosion on board merchant ship, subsequent encounter',
  'V9350XS Explosion on board merchant ship, sequela',
  'V9351XA Explosion on board passenger ship, initial encounter',
  'V9351XD Explosion on board passenger ship, subsequent encounter',
  'V9351XS Explosion on board passenger ship, sequela',
  'V9352XA Explosion on board fishing boat, initial encounter',
  'V9352XD Explosion on board fishing boat, subsequent encounter',
  'V9352XS Explosion on board fishing boat, sequela',
  'V9353XA Explosion on board other powered watercraft, initial encounter',
  'V9353XD Explosion on board other powered watercraft, subsequent encounter',
  'V9353XS Explosion on board other powered watercraft, sequela',
  'V9354XA Explosion on board sailboat, initial encounter',
  'V9354XD Explosion on board sailboat, subsequent encounter',
  'V9354XS Explosion on board sailboat, sequela',
  'V9359XA Explosion on board unspecified watercraft, initial encounter',
  'V9359XD Explosion on board unspecified watercraft, subsequent encounter',
  'V9359XS Explosion on board unspecified watercraft, sequela',
  'V9360XA Machinery accident on board merchant ship, initial encounter',
  'V9360XD Machinery accident on board merchant ship, subsequent encounter',
  'V9360XS Machinery accident on board merchant ship, sequela',
  'V9361XA Machinery accident on board passenger ship, initial encounter',
  'V9361XD Machinery accident on board passenger ship, subsequent encounter',
  'V9361XS Machinery accident on board passenger ship, sequela',
  'V9362XA Machinery accident on board fishing boat, initial encounter',
  'V9362XD Machinery accident on board fishing boat, subsequent encounter',
  'V9362XS Machinery accident on board fishing boat, sequela',
  'V9363XA Machinery accident on board other powered watercraft, initial encounter',
  'V9363XD Machinery accident on board other powered watercraft, subsequent encounter',
  'V9363XS Machinery accident on board other powered watercraft, sequela',
  'V9364XA Machinery accident on board sailboat, initial encounter',
  'V9364XD Machinery accident on board sailboat, subsequent encounter',
  'V9364XS Machinery accident on board sailboat, sequela',
  'V9369XA Machinery accident on board unspecified watercraft, initial encounter',
  'V9369XD Machinery accident on board unspecified watercraft, subsequent encounter',
  'V9369XS Machinery accident on board unspecified watercraft, sequela',
  'V9380XA Other injury due to other accident on board merchant ship, initial encounter',
  'V9380XD Other injury due to other accident on board merchant ship, subsequent encounter',
  'V9380XS Other injury due to other accident on board merchant ship, sequela',
  'V9381XA Other injury due to other accident on board passenger ship, initial encounter',
  'V9381XD Other injury due to other accident on board passenger ship, subsequent encounter',
  'V9381XS Other injury due to other accident on board passenger ship, sequela',
  'V9382XA Other injury due to other accident on board fishing boat, initial encounter',
  'V9382XD Other injury due to other accident on board fishing boat, subsequent encounter',
  'V9382XS Other injury due to other accident on board fishing boat, sequela',
  'V9383XA Other injury due to other accident on board other powered watercraft, initial encounter',
  'V9383XD Other injury due to other accident on board other powered watercraft, subsequent encounter',
  'V9383XS Other injury due to other accident on board other powered watercraft, sequela',
  'V9384XA Other injury due to other accident on board sailboat, initial encounter',
  'V9384XD Other injury due to other accident on board sailboat, subsequent encounter',
  'V9384XS Other injury due to other accident on board sailboat, sequela',
  'V9385XA Other injury due to other accident on board canoe or kayak, initial encounter',
  'V9385XD Other injury due to other accident on board canoe or kayak, subsequent encounter',
  'V9385XS Other injury due to other accident on board canoe or kayak, sequela',
  'V9386XA Other injury due to other accident on board (nonpowered) inflatable craft, initial encounter',
  'V9386XD Other injury due to other accident on board (nonpowered) inflatable craft, subsequent',
  'encounter',
  'V9386XS Other injury due to other accident on board (nonpowered) inflatable craft, sequela',
  'V9387XA Other injury due to other accident on board water-skis, initial encounter',
  'V9387XD Other injury due to other accident on board water-skis, subsequent encounter',
  'V9387XS Other injury due to other accident on board water-skis, sequela',
  'V9388XA Other injury due to other accident on board other unpowered watercraft, initial encounter',
  'V9388XD Other injury due to other accident on board other unpowered watercraft, subsequent',
  'encounter',
  'V9388XS Other injury due to other accident on board other unpowered watercraft, sequela',
  'V9389XA Other injury due to other accident on board unspecified watercraft, initial encounter',
  'V9389XD Other injury due to other accident on board unspecified watercraft, subsequent encounter',
  'V9389XS Other injury due to other accident on board unspecified watercraft, sequela',
  'V940XXA Hitting object or bottom of body of water due to fall from watercraft, initial encounter',
  'V940XXD Hitting object or bottom of body of water due to fall from watercraft, subsequent encounter',
  'V940XXS Hitting object or bottom of body of water due to fall from watercraft, sequela',
  'V9411XA Bather struck by powered watercraft, initial encounter',
  'V9411XD Bather struck by powered watercraft, subsequent encounter',
  'V9411XS Bather struck by powered watercraft, sequela',
  'V9412XA Bather struck by nonpowered watercraft, initial encounter',
  'V9412XD Bather struck by nonpowered watercraft, subsequent encounter',
  'V9412XS Bather struck by nonpowered watercraft, sequela',
  'V9421XA Rider of nonpowered watercraft struck by other nonpowered watercraft, initial encounter',
  'V9421XD Rider of nonpowered watercraft struck by other nonpowered watercraft, subsequent',
  'encounter',
  'V9421XS Rider of nonpowered watercraft struck by other nonpowered watercraft, sequela',
  'V9422XA Rider of nonpowered watercraft struck by powered watercraft, initial encounter',
  'V9422XD Rider of nonpowered watercraft struck by powered watercraft, subsequent encounter',
  'V9422XS Rider of nonpowered watercraft struck by powered watercraft, sequela',
  'V9431XA Injury to rider of (inflatable) recreational watercraft being pulled behind other watercraft,',
  'initial encounter',
  'V9431XD Injury to rider of (inflatable) recreational watercraft being pulled behind other watercraft,',
  'subsequent encounter',
  'V9431XS Injury to rider of (inflatable) recreational watercraft being pulled behind other watercraft,',
  'sequela',
  'V9432XA Injury to rider of non-recreational watercraft being pulled behind other watercraft, initial',
  'encounter',
  'V9432XD Injury to rider of non-recreational watercraft being pulled behind other watercraft,',
  'subsequent encounter',
  'V9432XS Injury to rider of non-recreational watercraft being pulled behind other watercraft, sequela',
  'V944XXA Injury to barefoot water-skier, initial encounter',
  'V944XXD Injury to barefoot water-skier, subsequent encounter',
  'V944XXS Injury to barefoot water-skier, sequela',
  'V94810A Civilian watercraft involved in water transport accident with military watercraft, initial',
  'encounter',
  'V94810D Civilian watercraft involved in water transport accident with military watercraft, subsequent',
  'encounter',
  'V94810S Civilian watercraft involved in water transport accident with military watercraft, sequela',
  'V94811A Civilian in water injured by military watercraft, initial encounter',
  'V94811D Civilian in water injured by military watercraft, subsequent encounter',
  'V94811S Civilian in water injured by military watercraft, sequela',
  'V94818A Other water transport accident involving military watercraft, initial encounter',
  'V94818D Other water transport accident involving military watercraft, subsequent encounter',
  'V94818S Other water transport accident involving military watercraft, sequela',
  'V9489XA Other water transport accident, initial encounter',
  'V9489XD Other water transport accident, subsequent encounter',
  'V9489XS Other water transport accident, sequela',
  'V949XXA Unspecified water transport accident, initial encounter',
  'V949XXD Unspecified water transport accident, subsequent encounter',
  'V949XXS Unspecified water transport accident, sequela',
  'V9500XA Unspecified helicopter accident injuring occupant, initial encounter',
  'V9500XD Unspecified helicopter accident injuring occupant, subsequent encounter',
  'V9500XS Unspecified helicopter accident injuring occupant, sequela',
  'V9501XA Helicopter crash injuring occupant, initial encounter',
  'V9501XD Helicopter crash injuring occupant, subsequent encounter',
  'V9501XS Helicopter crash injuring occupant, sequela',
  'V9502XA Forced landing of helicopter injuring occupant, initial encounter',
  'V9502XD Forced landing of helicopter injuring occupant, subsequent encounter',
  'V9502XS Forced landing of helicopter injuring occupant, sequela',
  'V9503XA Helicopter collision injuring occupant, initial encounter',
  'V9503XD Helicopter collision injuring occupant, subsequent encounter',
  'V9503XS Helicopter collision injuring occupant, sequela',
  'V9504XA Helicopter fire injuring occupant, initial encounter',
  'V9504XD Helicopter fire injuring occupant, subsequent encounter',
  'V9504XS Helicopter fire injuring occupant, sequela',
  'V9505XA Helicopter explosion injuring occupant, initial encounter',
  'V9505XD Helicopter explosion injuring occupant, subsequent encounter',
  'V9505XS Helicopter explosion injuring occupant, sequela',
  'V9509XA Other helicopter accident injuring occupant, initial encounter',
  'V9509XD Other helicopter accident injuring occupant, subsequent encounter',
  'V9509XS Other helicopter accident injuring occupant, sequela',
  'V9510XA Unspecified ultralight, microlight or powered-glider accident injuring occupant, initial',
  'encounter',
  'V9510XD Unspecified ultralight, microlight or powered-glider accident injuring occupant, subsequent',
  'encounter',
  'V9510XS Unspecified ultralight, microlight or powered-glider accident injuring occupant, sequela',
  'V9511XA Ultralight, microlight or powered-glider crash injuring occupant, initial encounter',
  'V9511XD Ultralight, microlight or powered-glider crash injuring occupant, subsequent encounter',
  'V9511XS Ultralight, microlight or powered-glider crash injuring occupant, sequela',
  'V9512XA Forced landing of ultralight, microlight or powered-glider injuring occupant, initial encounter',
  'V9512XD Forced landing of ultralight, microlight or powered-glider injuring occupant, subsequent',
  'encounter',
  'V9512XS Forced landing of ultralight, microlight or powered-glider injuring occupant, sequela',
  'V9513XA Ultralight, microlight or powered-glider collision injuring occupant, initial encounter',
  'V9513XD Ultralight, microlight or powered-glider collision injuring occupant, subsequent encounter',
  'V9513XS Ultralight, microlight or powered-glider collision injuring occupant, sequela',
  'V9514XA Ultralight, microlight or powered-glider fire injuring occupant, initial encounter',
  'V9514XD Ultralight, microlight or powered-glider fire injuring occupant, subsequent encounter',
  'V9514XS Ultralight, microlight or powered-glider fire injuring occupant, sequela',
  'V9515XA Ultralight, microlight or powered-glider explosion injuring occupant, initial encounter',
  'V9515XD Ultralight, microlight or powered-glider explosion injuring occupant, subsequent encounter',
  'V9515XS Ultralight, microlight or powered-glider explosion injuring occupant, sequela',
  'V9519XA Other ultralight, microlight or powered-glider accident injuring occupant, initial encounter',
  'V9519XD Other ultralight, microlight or powered-glider accident injuring occupant, subsequent',
  'encounter',
  'V9519XS Other ultralight, microlight or powered-glider accident injuring occupant, sequela',
  'V9520XA Unspecified accident to other private fixed-wing aircraft, injuring occupant, initial encounter',
  'V9520XD Unspecified accident to other private fixed-wing aircraft, injuring occupant, subsequent',
  'encounter',
  'V9520XS Unspecified accident to other private fixed-wing aircraft, injuring occupant, sequela',
  'V9521XA Other private fixed-wing aircraft crash injuring occupant, initial encounter',
  'V9521XD Other private fixed-wing aircraft crash injuring occupant, subsequent encounter',
  'V9521XS Other private fixed-wing aircraft crash injuring occupant, sequela',
  'V9522XA Forced landing of other private fixed-wing aircraft injuring occupant, initial encounter',
  'V9522XD Forced landing of other private fixed-wing aircraft injuring occupant, subsequent encounter',
  'V9522XS Forced landing of other private fixed-wing aircraft injuring occupant, sequela',
  'V9523XA Other private fixed-wing aircraft collision injuring occupant, initial encounter',
  'V9523XD Other private fixed-wing aircraft collision injuring occupant, subsequent encounter',
  'V9523XS Other private fixed-wing aircraft collision injuring occupant, sequela',
  'V9524XA Other private fixed-wing aircraft fire injuring occupant, initial encounter',
  'V9524XD Other private fixed-wing aircraft fire injuring occupant, subsequent encounter',
  'V9524XS Other private fixed-wing aircraft fire injuring occupant, sequela',
  'V9525XA Other private fixed-wing aircraft explosion injuring occupant, initial encounter',
  'V9525XD Other private fixed-wing aircraft explosion injuring occupant, subsequent encounter',
  'V9525XS Other private fixed-wing aircraft explosion injuring occupant, sequela',
  'V9529XA Other accident to other private fixed-wing aircraft injuring occupant, initial encounter',
  'V9529XD Other accident to other private fixed-wing aircraft injuring occupant, subsequent encounter',
  'V9529XS Other accident to other private fixed-wing aircraft injuring occupant, sequela',
  'V9530XA Unspecified accident to commercial fixed-wing aircraft injuring occupant, initial encounter',
  'V9530XD Unspecified accident to commercial fixed-wing aircraft injuring occupant, subsequent',
  'encounter',
  'V9530XS Unspecified accident to commercial fixed-wing aircraft injuring occupant, sequela',
  'V9531XA Commercial fixed-wing aircraft crash injuring occupant, initial encounter',
  'V9531XD Commercial fixed-wing aircraft crash injuring occupant, subsequent encounter',
  'V9531XS Commercial fixed-wing aircraft crash injuring occupant, sequela',
  'V9532XA Forced landing of commercial fixed-wing aircraft injuring occupant, initial encounter',
  'V9532XD Forced landing of commercial fixed-wing aircraft injuring occupant, subsequent encounter',
  'V9532XS Forced landing of commercial fixed-wing aircraft injuring occupant, sequela',
  'V9533XA Commercial fixed-wing aircraft collision injuring occupant, initial encounter',
  'V9533XD Commercial fixed-wing aircraft collision injuring occupant, subsequent encounter',
  'V9533XS Commercial fixed-wing aircraft collision injuring occupant, sequela',
  'V9534XA Commercial fixed-wing aircraft fire injuring occupant, initial encounter',
  'V9534XD Commercial fixed-wing aircraft fire injuring occupant, subsequent encounter',
  'V9534XS Commercial fixed-wing aircraft fire injuring occupant, sequela',
  'V9535XA Commercial fixed-wing aircraft explosion injuring occupant, initial encounter',
  'V9535XD Commercial fixed-wing aircraft explosion injuring occupant, subsequent encounter',
  'V9535XS Commercial fixed-wing aircraft explosion injuring occupant, sequela',
  'V9539XA Other accident to commercial fixed-wing aircraft injuring occupant, initial encounter',
  'V9539XD Other accident to commercial fixed-wing aircraft injuring occupant, subsequent encounter',
  'V9539XS Other accident to commercial fixed-wing aircraft injuring occupant, sequela',
  'V9540XA Unspecified spacecraft accident injuring occupant, initial encounter',
  'V9540XD Unspecified spacecraft accident injuring occupant, subsequent encounter',
  'V9540XS Unspecified spacecraft accident injuring occupant, sequela',
  'V9541XA Spacecraft crash injuring occupant, initial encounter',
  'V9541XD Spacecraft crash injuring occupant, subsequent encounter',
  'V9541XS Spacecraft crash injuring occupant, sequela',
  'V9542XA Forced landing of spacecraft injuring occupant, initial encounter',
  'V9542XD Forced landing of spacecraft injuring occupant, subsequent encounter',
  'V9542XS Forced landing of spacecraft injuring occupant, sequela',
  'V9543XA Spacecraft collision injuring occupant, initial encounter',
  'V9543XD Spacecraft collision injuring occupant, subsequent encounter',
  'V9543XS Spacecraft collision injuring occupant, sequela',
  'V9544XA Spacecraft fire injuring occupant, initial encounter',
  'V9544XD Spacecraft fire injuring occupant, subsequent encounter',
  'V9544XS Spacecraft fire injuring occupant, sequela',
  'V9545XA Spacecraft explosion injuring occupant, initial encounter',
  'V9545XD Spacecraft explosion injuring occupant, subsequent encounter',
  'V9545XS Spacecraft explosion injuring occupant, sequela',
  'V9549XA Other spacecraft accident injuring occupant, initial encounter',
  'V9549XD Other spacecraft accident injuring occupant, subsequent encounter',
  'V9549XS Other spacecraft accident injuring occupant, sequela',
  'V958XXA Other powered aircraft accidents injuring occupant, initial encounter',
  'V958XXD Other powered aircraft accidents injuring occupant, subsequent encounter',
  'V958XXS Other powered aircraft accidents injuring occupant, sequela',
  'V959XXA Unspecified aircraft accident injuring occupant, initial encounter',
  'V959XXD Unspecified aircraft accident injuring occupant, subsequent encounter',
  'V959XXS Unspecified aircraft accident injuring occupant, sequela',
  'V9600XA Unspecified balloon accident injuring occupant, initial encounter',
  'V9600XD Unspecified balloon accident injuring occupant, subsequent encounter',
  'V9600XS Unspecified balloon accident injuring occupant, sequela',
  'V9601XA Balloon crash injuring occupant, initial encounter',
  'V9601XD Balloon crash injuring occupant, subsequent encounter',
  'V9601XS Balloon crash injuring occupant, sequela',
  'V9602XA Forced landing of balloon injuring occupant, initial encounter',
  'V9602XD Forced landing of balloon injuring occupant, subsequent encounter',
  'V9602XS Forced landing of balloon injuring occupant, sequela',
  'V9603XA Balloon collision injuring occupant, initial encounter',
  'V9603XD Balloon collision injuring occupant, subsequent encounter',
  'V9603XS Balloon collision injuring occupant, sequela',
  'V9604XA Balloon fire injuring occupant, initial encounter',
  'V9604XD Balloon fire injuring occupant, subsequent encounter',
  'V9604XS Balloon fire injuring occupant, sequela',
  'V9605XA Balloon explosion injuring occupant, initial encounter',
  'V9605XD Balloon explosion injuring occupant, subsequent encounter',
  'V9605XS Balloon explosion injuring occupant, sequela',
  'V9609XA Other balloon accident injuring occupant, initial encounter',
  'V9609XD Other balloon accident injuring occupant, subsequent encounter',
  'V9609XS Other balloon accident injuring occupant, sequela',
  'V9610XA Unspecified hang-glider accident injuring occupant, initial encounter',
  'V9610XD Unspecified hang-glider accident injuring occupant, subsequent encounter',
  'V9610XS Unspecified hang-glider accident injuring occupant, sequela',
  'V9611XA Hang-glider crash injuring occupant, initial encounter',
  'V9611XD Hang-glider crash injuring occupant, subsequent encounter',
  'V9611XS Hang-glider crash injuring occupant, sequela',
  'V9612XA Forced landing of hang-glider injuring occupant, initial encounter',
  'V9612XD Forced landing of hang-glider injuring occupant, subsequent encounter',
  'V9612XS Forced landing of hang-glider injuring occupant, sequela',
  'V9613XA Hang-glider collision injuring occupant, initial encounter',
  'V9613XD Hang-glider collision injuring occupant, subsequent encounter',
  'V9613XS Hang-glider collision injuring occupant, sequela',
  'V9614XA Hang-glider fire injuring occupant, initial encounter',
  'V9614XD Hang-glider fire injuring occupant, subsequent encounter',
  'V9614XS Hang-glider fire injuring occupant, sequela',
  'V9615XA Hang-glider explosion injuring occupant, initial encounter',
  'V9615XD Hang-glider explosion injuring occupant, subsequent encounter',
  'V9615XS Hang-glider explosion injuring occupant, sequela',
  'V9619XA Other hang-glider accident injuring occupant, initial encounter',
  'V9619XD Other hang-glider accident injuring occupant, subsequent encounter',
  'V9619XS Other hang-glider accident injuring occupant, sequela',
  'V9620XA Unspecified glider (nonpowered) accident injuring occupant, initial encounter',
  'V9620XD Unspecified glider (nonpowered) accident injuring occupant, subsequent encounter',
  'V9620XS Unspecified glider (nonpowered) accident injuring occupant, sequela',
  'V9621XA Glider (nonpowered) crash injuring occupant, initial encounter',
  'V9621XD Glider (nonpowered) crash injuring occupant, subsequent encounter',
  'V9621XS Glider (nonpowered) crash injuring occupant, sequela',
  'V9622XA Forced landing of glider (nonpowered) injuring occupant, initial encounter',
  'V9622XD Forced landing of glider (nonpowered) injuring occupant, subsequent encounter',
  'V9622XS Forced landing of glider (nonpowered) injuring occupant, sequela',
  'V9623XA Glider (nonpowered) collision injuring occupant, initial encounter',
  'V9623XD Glider (nonpowered) collision injuring occupant, subsequent encounter',
  'V9623XS Glider (nonpowered) collision injuring occupant, sequela',
  'V9624XA Glider (nonpowered) fire injuring occupant, initial encounter',
  'V9624XD Glider (nonpowered) fire injuring occupant, subsequent encounter',
  'V9624XS Glider (nonpowered) fire injuring occupant, sequela',
  'V9625XA Glider (nonpowered) explosion injuring occupant, initial encounter',
  'V9625XD Glider (nonpowered) explosion injuring occupant, subsequent encounter',
  'V9625XS Glider (nonpowered) explosion injuring occupant, sequela',
  'V9629XA Other glider (nonpowered) accident injuring occupant, initial encounter',
  'V9629XD Other glider (nonpowered) accident injuring occupant, subsequent encounter',
  'V9629XS Other glider (nonpowered) accident injuring occupant, sequela',
  'V968XXA Other nonpowered-aircraft accidents injuring occupant, initial encounter',
  'V968XXD Other nonpowered-aircraft accidents injuring occupant, subsequent encounter',
  'V968XXS Other nonpowered-aircraft accidents injuring occupant, sequela',
  'V969XXA Unspecified nonpowered-aircraft accident injuring occupant, initial encounter',
  'V969XXD Unspecified nonpowered-aircraft accident injuring occupant, subsequent encounter',
  'V969XXS Unspecified nonpowered-aircraft accident injuring occupant, sequela',
  'V970XXA Occupant of aircraft injured in other specified air transport accidents, initial encounter',
  'V970XXD Occupant of aircraft injured in other specified air transport accidents, subsequent encounter',
  'V970XXS Occupant of aircraft injured in other specified air transport accidents, sequela',
  'V971XXA Person injured while boarding or alighting from aircraft, initial encounter',
  'V971XXD Person injured while boarding or alighting from aircraft, subsequent encounter',
  'V971XXS Person injured while boarding or alighting from aircraft, sequela',
  'V9721XA Parachutist entangled in object, initial encounter',
  'V9721XD Parachutist entangled in object, subsequent encounter',
  'V9721XS Parachutist entangled in object, sequela',
  'V9722XA Parachutist injured on landing, initial encounter',
  'V9722XD Parachutist injured on landing, subsequent encounter',
  'V9722XS Parachutist injured on landing, sequela',
  'V9729XA Other parachutist accident, initial encounter',
  'V9729XD Other parachutist accident, subsequent encounter',
  'V9729XS Other parachutist accident, sequela',
  'V9731XA Hit by object falling from aircraft, initial encounter',
  'V9731XD Hit by object falling from aircraft, subsequent encounter',
  'V9731XS Hit by object falling from aircraft, sequela',
  'V9732XA Injured by rotating propeller, initial encounter',
  'V9732XD Injured by rotating propeller, subsequent encounter',
  'V9732XS Injured by rotating propeller, sequela',
  'V9733XA Sucked into jet engine, initial encounter',
  'V9733XD Sucked into jet engine, subsequent encounter',
  'V9733XS Sucked into jet engine, sequela',
  'V9739XA Other injury to person on ground due to air transport accident, initial encounter',
  'V9739XD Other injury to person on ground due to air transport accident, subsequent encounter',
  'V9739XS Other injury to person on ground due to air transport accident, sequela',
  'V97810A Civilian aircraft involved in air transport accident with military aircraft, initial encounter',
  'V97810D Civilian aircraft involved in air transport accident with military aircraft, subsequent encounter',
  'V97810S Civilian aircraft involved in air transport accident with military aircraft, sequela',
  'V97811A Civilian injured by military aircraft, initial encounter',
  'V97811D Civilian injured by military aircraft, subsequent encounter',
  'V97811S Civilian injured by military aircraft, sequela',
  'V97818A Other air transport accident involving military aircraft, initial encounter',
  'V97818D Other air transport accident involving military aircraft, subsequent encounter',
  'V97818S Other air transport accident involving military aircraft, sequela',
  'V9789XA Other air transport accidents, not elsewhere classified, initial encounter',
  'V9789XD Other air transport accidents, not elsewhere classified, subsequent encounter',
  'V9789XS Other air transport accidents, not elsewhere classified, sequela',
  'V980XXA Accident to, on or involving cable-car, not on rails, initial encounter',
  'V980XXD Accident to, on or involving cable-car, not on rails, subsequent encounter',
  'V980XXS Accident to, on or involving cable-car, not on rails, sequela',
  'V981XXA Accident to, on or involving land-yacht, initial encounter',
  'V981XXD Accident to, on or involving land-yacht, subsequent encounter',
  'V981XXS Accident to, on or involving land-yacht, sequela',
  'V982XXA Accident to, on or involving ice yacht, initial encounter',
  'V982XXD Accident to, on or involving ice yacht, subsequent encounter',
  'V982XXS Accident to, on or involving ice yacht, sequela',
  'V983XXA Accident to, on or involving ski lift, initial encounter',
  'V983XXD Accident to, on or involving ski lift, subsequent encounter',
  'V983XXS Accident to, on or involving ski lift, sequela',
  'V988XXA Other specified transport accidents, initial encounter',
  'V988XXD Other specified transport accidents, subsequent encounter',
  'V988XXS Other specified transport accidents, sequela',
  'V99XXXA Unspecified transport accident, initial encounter',
  'V99XXXD Unspecified transport accident, subsequent encounter',
  'V99XXXS Unspecified transport accident, sequela',
  'W000XXA Fall on same level due to ice and snow, initial encounter',
  'W000XXD Fall on same level due to ice and snow, subsequent encounter',
  'W000XXS Fall on same level due to ice and snow, sequela',
  'W001XXA Fall from stairs and steps due to ice and snow, initial encounter',
  'W001XXD Fall from stairs and steps due to ice and snow, subsequent encounter',
  'W001XXS Fall from stairs and steps due to ice and snow, sequela',
  'W002XXA Other fall from one level to another due to ice and snow, initial encounter',
  'W002XXD Other fall from one level to another due to ice and snow, subsequent encounter',
  'W002XXS Other fall from one level to another due to ice and snow, sequela',
  'W009XXA Unspecified fall due to ice and snow, initial encounter',
  'W009XXD Unspecified fall due to ice and snow, subsequent encounter',
  'W009XXS Unspecified fall due to ice and snow, sequela',
  'W010XXA Fall on same level from slipping, tripping and stumbling without subsequent striking against',
  'object, initial encounter',
  'W010XXD Fall on same level from slipping, tripping and stumbling without subsequent striking against',
  'object, subsequent encounter',
  'W010XXS Fall on same level from slipping, tripping and stumbling without subsequent striking against',
  'object, sequela',
  'W0110XA Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'unspecified object, initial encounter',
  'W0110XD Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'unspecified object, subsequent encounter',
  'W0110XS Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'unspecified object, sequela',
  'W01110A Fall on same level from slipping, tripping and stumbling with subsequent striking against sharp',
  'glass, initial encounter',
  'W01110D Fall on same level from slipping, tripping and stumbling with subsequent striking against sharp',
  'glass, subsequent encounter',
  'W01110S Fall on same level from slipping, tripping and stumbling with subsequent striking against sharp',
  'glass, sequela',
  'W01111A Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'power tool or machine, initial encounter',
  'W01111D Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'power tool or machine, subsequent encounter',
  'W01111S Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'power tool or machine, sequela',
  'W01118A Fall on same level from slipping, tripping and stumbling with subsequent striking against other',
  'sharp object, initial encounter',
  'W01118D Fall on same level from slipping, tripping and stumbling with subsequent striking against other',
  'sharp object, subsequent encounter',
  'W01118S Fall on same level from slipping, tripping and stumbling with subsequent striking against other',
  'sharp object, sequela',
  'W01119A Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'unspecified sharp object, initial encounter',
  'W01119D Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'unspecified sharp object, subsequent encounter',
  'W01119S Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'unspecified sharp object, sequela',
  'W01190A Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'furniture, initial encounter',
  'W01190D Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'furniture, subsequent encounter',
  'W01190S Fall on same level from slipping, tripping and stumbling with subsequent striking against',
  'furniture, sequela',
  'W01198A Fall on same level from slipping, tripping and stumbling with subsequent striking against other',
  'object, initial encounter',
  'W01198D Fall on same level from slipping, tripping and stumbling with subsequent striking against other',
  'object, subsequent encounter',
  'W01198S Fall on same level from slipping, tripping and stumbling with subsequent striking against other',
  'object, sequela',
  'W03XXXA Other fall on same level due to collision with another person, initial encounter',
  'W03XXXD Other fall on same level due to collision with another person, subsequent encounter',
  'W03XXXS Other fall on same level due to collision with another person, sequela',
  'W04XXXA Fall while being carried or supported by other persons, initial encounter',
  'W04XXXD Fall while being carried or supported by other persons, subsequent encounter',
  'W04XXXS Fall while being carried or supported by other persons, sequela',
  'W050XXA Fall from non-moving wheelchair, initial encounter',
  'W050XXD Fall from non-moving wheelchair, subsequent encounter',
  'W050XXS Fall from non-moving wheelchair, sequela',
  'W051XXA Fall from non-moving nonmotorized scooter, initial encounter',
  'W051XXD Fall from non-moving nonmotorized scooter, subsequent encounter',
  'W051XXS Fall from non-moving nonmotorized scooter, sequela',
  'W052XXA Fall from non-moving motorized mobility scooter, initial encounter',
  'W052XXD Fall from non-moving motorized mobility scooter, subsequent encounter',
  'W052XXS Fall from non-moving motorized mobility scooter, sequela',
  'W06XXXA Fall from bed, initial encounter',
  'W06XXXD Fall from bed, subsequent encounter',
  'W06XXXS Fall from bed, sequela',
  'W07XXXA Fall from chair, initial encounter',
  'W07XXXD Fall from chair, subsequent encounter',
  'W07XXXS Fall from chair, sequela',
  'W08XXXA Fall from other furniture, initial encounter',
  'W08XXXD Fall from other furniture, subsequent encounter',
  'W08XXXS Fall from other furniture, sequela',
  'W090XXA Fall on or from playground slide, initial encounter',
  'W090XXD Fall on or from playground slide, subsequent encounter',
  'W090XXS Fall on or from playground slide, sequela',
  'W091XXA Fall from playground swing, initial encounter',
  'W091XXD Fall from playground swing, subsequent encounter',
  'W091XXS Fall from playground swing, sequela',
  'W092XXA Fall on or from jungle gym, initial encounter',
  'W092XXD Fall on or from jungle gym, subsequent encounter',
  'W092XXS Fall on or from jungle gym, sequela',
  'W098XXA Fall on or from other playground equipment, initial encounter',
  'W098XXD Fall on or from other playground equipment, subsequent encounter',
  'W098XXS Fall on or from other playground equipment, sequela',
  'W100XXA Fall (on)(from) escalator, initial encounter',
  'W100XXD Fall (on)(from) escalator, subsequent encounter',
  'W100XXS Fall (on)(from) escalator, sequela',
  'W101XXA Fall (on)(from) sidewalk curb, initial encounter',
  'W101XXD Fall (on)(from) sidewalk curb, subsequent encounter',
  'W101XXS Fall (on)(from) sidewalk curb, sequela',
  'W102XXA Fall (on)(from) incline, initial encounter',
  'W102XXD Fall (on)(from) incline, subsequent encounter',
  'W102XXS Fall (on)(from) incline, sequela',
  'W108XXA Fall (on) (from) other stairs and steps, initial encounter',
  'W108XXD Fall (on) (from) other stairs and steps, subsequent encounter',
  'W108XXS Fall (on) (from) other stairs and steps, sequela',
  'W109XXA Fall (on) (from) unspecified stairs and steps, initial encounter',
  'W109XXD Fall (on) (from) unspecified stairs and steps, subsequent encounter',
  'W109XXS Fall (on) (from) unspecified stairs and steps, sequela',
  'W11XXXA Fall on and from ladder, initial encounter',
  'W11XXXD Fall on and from ladder, subsequent encounter',
  'W11XXXS Fall on and from ladder, sequela',
  'W12XXXA Fall on and from scaffolding, initial encounter',
  'W12XXXD Fall on and from scaffolding, subsequent encounter',
  'W12XXXS Fall on and from scaffolding, sequela',
  'W130XXA Fall from, out of or through balcony, initial encounter',
  'W130XXD Fall from, out of or through balcony, subsequent encounter',
  'W130XXS Fall from, out of or through balcony, sequela',
  'W131XXA Fall from, out of or through bridge, initial encounter',
  'W131XXD Fall from, out of or through bridge, subsequent encounter',
  'W131XXS Fall from, out of or through bridge, sequela',
  'W132XXA Fall from, out of or through roof, initial encounter',
  'W132XXD Fall from, out of or through roof, subsequent encounter',
  'W132XXS Fall from, out of or through roof, sequela',
  'W133XXA Fall through floor, initial encounter',
  'W133XXD Fall through floor, subsequent encounter',
  'W133XXS Fall through floor, sequela',
  'W134XXA Fall from, out of or through window, initial encounter',
  'W134XXD Fall from, out of or through window, subsequent encounter',
  'W134XXS Fall from, out of or through window, sequela',
  'W138XXA Fall from, out of or through other building or structure, initial encounter',
  'W138XXD Fall from, out of or through other building or structure, subsequent encounter',
  'W138XXS Fall from, out of or through other building or structure, sequela',
  'W139XXA Fall from, out of or through building, not otherwise specified, initial encounter',
  'W139XXD Fall from, out of or through building, not otherwise specified, subsequent encounter',
  'W139XXS Fall from, out of or through building, not otherwise specified, sequela',
  'W14XXXA Fall from tree, initial encounter',
  'W14XXXD Fall from tree, subsequent encounter',
  'W14XXXS Fall from tree, sequela',
  'W15XXXA Fall from cliff, initial encounter',
  'W15XXXD Fall from cliff, subsequent encounter',
  'W15XXXS Fall from cliff, sequela',
  'W16011A Fall into swimming pool striking water surface causing drowning and submersion, initial',
  'encounter',
  'W16011D Fall into swimming pool striking water surface causing drowning and submersion, subsequent',
  'encounter',
  'W16011S Fall into swimming pool striking water surface causing drowning and submersion, sequela',
  'W16012A Fall into swimming pool striking water surface causing other injury, initial encounter',
  'W16012D Fall into swimming pool striking water surface causing other injury, subsequent encounter',
  'W16012S Fall into swimming pool striking water surface causing other injury, sequela',
  'W16021A Fall into swimming pool striking bottom causing drowning and submersion, initial encounter',
  'W16021D Fall into swimming pool striking bottom causing drowning and submersion, subsequent',
  'encounter',
  'W16021S Fall into swimming pool striking bottom causing drowning and submersion, sequela',
  'W16022A Fall into swimming pool striking bottom causing other injury, initial encounter',
  'W16022D Fall into swimming pool striking bottom causing other injury, subsequent encounter',
  'W16022S Fall into swimming pool striking bottom causing other injury, sequela',
  'W16031A Fall into swimming pool striking wall causing drowning and submersion, initial encounter',
  'W16031D Fall into swimming pool striking wall causing drowning and submersion, subsequent',
  'encounter',
  'W16031S Fall into swimming pool striking wall causing drowning and submersion, sequela',
  'W16032A Fall into swimming pool striking wall causing other injury, initial encounter',
  'W16032D Fall into swimming pool striking wall causing other injury, subsequent encounter',
  'W16032S Fall into swimming pool striking wall causing other injury, sequela',
  'W16111A Fall into natural body of water striking water surface causing drowning and submersion, initial',
  'encounter',
  'W16111D Fall into natural body of water striking water surface causing drowning and submersion,',
  'subsequent encounter',
  'W16111S Fall into natural body of water striking water surface causing drowning and submersion,',
  'sequela',
  'W16112A Fall into natural body of water striking water surface causing other injury, initial encounter',
  'W16112D Fall into natural body of water striking water surface causing other injury, subsequent',
  'encounter',
  'W16112S Fall into natural body of water striking water surface causing other injury, sequela',
  'W16121A Fall into natural body of water striking bottom causing drowning and submersion, initial',
  'encounter',
  'W16121D Fall into natural body of water striking bottom causing drowning and submersion, subsequent',
  'encounter',
  'W16121S Fall into natural body of water striking bottom causing drowning and submersion, sequela',
  'W16122A Fall into natural body of water striking bottom causing other injury, initial encounter',
  'W16122D Fall into natural body of water striking bottom causing other injury, subsequent encounter',
  'W16122S Fall into natural body of water striking bottom causing other injury, sequela',
  'W16131A Fall into natural body of water striking side causing drowning and submersion, initial',
  'encounter',
  'W16131D Fall into natural body of water striking side causing drowning and submersion, subsequent',
  'encounter',
  'W16131S Fall into natural body of water striking side causing drowning and submersion, sequela',
  'W16132A Fall into natural body of water striking side causing other injury, initial encounter',
  'W16132D Fall into natural body of water striking side causing other injury, subsequent encounter',
  'W16132S Fall into natural body of water striking side causing other injury, sequela',
  'W16211A Fall in (into) filled bathtub causing drowning and submersion, initial encounter',
  'W16211D Fall in (into) filled bathtub causing drowning and submersion, subsequent encounter',
  'W16211S Fall in (into) filled bathtub causing drowning and submersion, sequela',
  'W16212A Fall in (into) filled bathtub causing other injury, initial encounter',
  'W16212D Fall in (into) filled bathtub causing other injury, subsequent encounter',
  'W16212S Fall in (into) filled bathtub causing other injury, sequela',
  'W16221A Fall in (into) bucket of water causing drowning and submersion, initial encounter',
  'W16221D Fall in (into) bucket of water causing drowning and submersion, subsequent encounter',
  'W16221S Fall in (into) bucket of water causing drowning and submersion, sequela',
  'W16222A Fall in (into) bucket of water causing other injury, initial encounter',
  'W16222D Fall in (into) bucket of water causing other injury, subsequent encounter',
  'W16222S Fall in (into) bucket of water causing other injury, sequela',
  'W16311A Fall into other water striking water surface causing drowning and submersion, initial',
  'encounter',
  'W16311D Fall into other water striking water surface causing drowning and submersion, subsequent',
  'encounter',
  'W16311S Fall into other water striking water surface causing drowning and submersion, sequela',
  'W16312A Fall into other water striking water surface causing other injury, initial encounter',
  'W16312D Fall into other water striking water surface causing other injury, subsequent encounter',
  'W16312S Fall into other water striking water surface causing other injury, sequela',
  'W16321A Fall into other water striking bottom causing drowning and submersion, initial encounter',
  'W16321D Fall into other water striking bottom causing drowning and submersion, subsequent',
  'encounter',
  'W16321S Fall into other water striking bottom causing drowning and submersion, sequela',
  'W16322A Fall into other water striking bottom causing other injury, initial encounter',
  'W16322D Fall into other water striking bottom causing other injury, subsequent encounter',
  'W16322S Fall into other water striking bottom causing other injury, sequela',
  'W16331A Fall into other water striking wall causing drowning and submersion, initial encounter',
  'W16331D Fall into other water striking wall causing drowning and submersion, subsequent encounter',
  'W16331S Fall into other water striking wall causing drowning and submersion, sequela',
  'W16332A Fall into other water striking wall causing other injury, initial encounter',
  'W16332D Fall into other water striking wall causing other injury, subsequent encounter',
  'W16332S Fall into other water striking wall causing other injury, sequela',
  'W1641XA Fall into unspecified water causing drowning and submersion, initial encounter',
  'W1641XD Fall into unspecified water causing drowning and submersion, subsequent encounter',
  'W1641XS Fall into unspecified water causing drowning and submersion, sequela',
  'W1642XA Fall into unspecified water causing other injury, initial encounter',
  'W1642XD Fall into unspecified water causing other injury, subsequent encounter',
  'W1642XS Fall into unspecified water causing other injury, sequela',
  'W16511A Jumping or diving into swimming pool striking water surface causing drowning and',
  'submersion, initial encounter',
  'W16511D Jumping or diving into swimming pool striking water surface causing drowning and',
  'submersion, subsequent encounter',
  'W16511S Jumping or diving into swimming pool striking water surface causing drowning and',
  'submersion, sequela',
  'W16512A Jumping or diving into swimming pool striking water surface causing other injury, initial',
  'encounter',
  'W16512D Jumping or diving into swimming pool striking water surface causing other injury, subsequent',
  'encounter',
  'W16512S Jumping or diving into swimming pool striking water surface causing other injury, sequela',
  'W16521A Jumping or diving into swimming pool striking bottom causing drowning and submersion,',
  'initial encounter',
  'W16521D Jumping or diving into swimming pool striking bottom causing drowning and submersion,',
  'subsequent encounter',
  'W16521S Jumping or diving into swimming pool striking bottom causing drowning and submersion,',
  'sequela',
  'W16522A Jumping or diving into swimming pool striking bottom causing other injury, initial encounter',
  'W16522D Jumping or diving into swimming pool striking bottom causing other injury, subsequent',
  'encounter',
  'W16522S Jumping or diving into swimming pool striking bottom causing other injury, sequela',
  'W16531A Jumping or diving into swimming pool striking wall causing drowning and submersion, initial',
  'encounter',
  'W16531D Jumping or diving into swimming pool striking wall causing drowning and submersion,',
  'subsequent encounter',
  'W16531S Jumping or diving into swimming pool striking wall causing drowning and submersion, sequela',
  'W16532A Jumping or diving into swimming pool striking wall causing other injury, initial encounter',
  'W16532D Jumping or diving into swimming pool striking wall causing other injury, subsequent',
  'encounter',
  'W16532S Jumping or diving into swimming pool striking wall causing other injury, sequela',
  'W16611A Jumping or diving into natural body of water striking water surface causing drowning and',
  'submersion, initial encounter',
  'W16611D Jumping or diving into natural body of water striking water surface causing drowning and',
  'submersion, subsequent encounter',
  'W16611S Jumping or diving into natural body of water striking water surface causing drowning and',
  'submersion, sequela',
  'W16612A Jumping or diving into natural body of water striking water surface causing other injury, initial',
  'encounter',
  'W16612D Jumping or diving into natural body of water striking water surface causing other injury,',
  'subsequent encounter',
  'W16612S Jumping or diving into natural body of water striking water surface causing other injury,',
  'sequela',
  'W16621A Jumping or diving into natural body of water striking bottom causing drowning and',
  'submersion, initial encounter',
  'W16621D Jumping or diving into natural body of water striking bottom causing drowning and',
  'submersion, subsequent encounter',
  'W16621S Jumping or diving into natural body of water striking bottom causing drowning and',
  'submersion, sequela',
  'W16622A Jumping or diving into natural body of water striking bottom causing other injury, initial',
  'encounter',
  'W16622D Jumping or diving into natural body of water striking bottom causing other injury, subsequent',
  'encounter',
  'W16622S Jumping or diving into natural body of water striking bottom causing other injury, sequela',
  'W16711A Jumping or diving from boat striking water surface causing drowning and submersion, initial',
  'encounter',
  'W16711D Jumping or diving from boat striking water surface causing drowning and submersion,',
  'subsequent encounter',
  'W16711S Jumping or diving from boat striking water surface causing drowning and submersion, sequela',
  'W16712A Jumping or diving from boat striking water surface causing other injury, initial encounter',
  'W16712D Jumping or diving from boat striking water surface causing other injury, subsequent',
  'encounter',
  'W16712S Jumping or diving from boat striking water surface causing other injury, sequela',
  'W16721A Jumping or diving from boat striking bottom causing drowning and submersion, initial',
  'encounter',
  'W16721D Jumping or diving from boat striking bottom causing drowning and submersion, subsequent',
  'encounter',
  'W16721S Jumping or diving from boat striking bottom causing drowning and submersion, sequela',
  'W16722A Jumping or diving from boat striking bottom causing other injury, initial encounter',
  'W16722D Jumping or diving from boat striking bottom causing other injury, subsequent encounter',
  'W16722S Jumping or diving from boat striking bottom causing other injury, sequela',
  'W16811A Jumping or diving into other water striking water surface causing drowning and submersion,',
  'initial encounter',
  'W16811D Jumping or diving into other water striking water surface causing drowning and submersion,',
  'subsequent encounter',
  'W16811S Jumping or diving into other water striking water surface causing drowning and submersion,',
  'sequela',
  'W16812A Jumping or diving into other water striking water surface causing other injury, initial',
  'encounter',
  'W16812D Jumping or diving into other water striking water surface causing other injury, subsequent',
  'encounter',
  'W16812S Jumping or diving into other water striking water surface causing other injury, sequela',
  'W16821A Jumping or diving into other water striking bottom causing drowning and submersion, initial',
  'encounter',
  'W16821D Jumping or diving into other water striking bottom causing drowning and submersion,',
  'subsequent encounter',
  'W16821S Jumping or diving into other water striking bottom causing drowning and submersion, sequela',
  'W16822A Jumping or diving into other water striking bottom causing other injury, initial encounter',
  'W16822D Jumping or diving into other water striking bottom causing other injury, subsequent',
  'encounter',
  'W16822S Jumping or diving into other water striking bottom causing other injury, sequela',
  'W16831A Jumping or diving into other water striking wall causing drowning and submersion, initial',
  'encounter',
  'W16831D Jumping or diving into other water striking wall causing drowning and submersion,',
  'subsequent encounter',
  'W16831S Jumping or diving into other water striking wall causing drowning and submersion, sequela',
  'W16832A Jumping or diving into other water striking wall causing other injury, initial encounter',
  'W16832D Jumping or diving into other water striking wall causing other injury, subsequent encounter',
  'W16832S Jumping or diving into other water striking wall causing other injury, sequela',
  'W1691XA Jumping or diving into unspecified water causing drowning and submersion, initial encounter',
  'W1691XD Jumping or diving into unspecified water causing drowning and submersion, subsequent',
  'encounter',
  'W1691XS Jumping or diving into unspecified water causing drowning and submersion, sequela',
  'W1692XA Jumping or diving into unspecified water causing other injury, initial encounter',
  'W1692XD Jumping or diving into unspecified water causing other injury, subsequent encounter',
  'W1692XS Jumping or diving into unspecified water causing other injury, sequela',
  'W170XXA Fall into well, initial encounter',
  'W170XXD Fall into well, subsequent encounter',
  'W170XXS Fall into well, sequela',
  'W171XXA Fall into storm drain or manhole, initial encounter',
  'W171XXD Fall into storm drain or manhole, subsequent encounter',
  'W171XXS Fall into storm drain or manhole, sequela',
  'W172XXA Fall into hole, initial encounter',
  'W172XXD Fall into hole, subsequent encounter',
  'W172XXS Fall into hole, sequela',
  'W173XXA Fall into empty swimming pool, initial encounter',
  'W173XXD Fall into empty swimming pool, subsequent encounter',
  'W173XXS Fall into empty swimming pool, sequela',
  'W174XXA Fall from dock, initial encounter',
  'W174XXD Fall from dock, subsequent encounter',
  'W174XXS Fall from dock, sequela',
  'W1781XA Fall down embankment (hill), initial encounter',
  'W1781XD Fall down embankment (hill), subsequent encounter',
  'W1781XS Fall down embankment (hill), sequela',
  'W1782XA Fall from (out of) grocery cart, initial encounter',
  'W1782XD Fall from (out of) grocery cart, subsequent encounter',
  'W1782XS Fall from (out of) grocery cart, sequela',
  'W1789XA Other fall from one level to another, initial encounter',
  'W1789XD Other fall from one level to another, subsequent encounter',
  'W1789XS Other fall from one level to another, sequela',
  'W1800XA Striking against unspecified object with subsequent fall, initial encounter',
  'W1800XD Striking against unspecified object with subsequent fall, subsequent encounter',
  'W1800XS Striking against unspecified object with subsequent fall, sequela',
  'W1801XA Striking against sports equipment with subsequent fall, initial encounter',
  'W1801XD Striking against sports equipment with subsequent fall, subsequent encounter',
  'W1801XS Striking against sports equipment with subsequent fall, sequela',
  'W1802XA Striking against glass with subsequent fall, initial encounter',
  'W1802XD Striking against glass with subsequent fall, subsequent encounter',
  'W1802XS Striking against glass with subsequent fall, sequela',
  'W1809XA Striking against other object with subsequent fall, initial encounter',
  'W1809XD Striking against other object with subsequent fall, subsequent encounter',
  'W1809XS Striking against other object with subsequent fall, sequela',
  'W1811XA Fall from or off toilet without subsequent striking against object, initial encounter',
  'W1811XD Fall from or off toilet without subsequent striking against object, subsequent encounter',
  'W1811XS Fall from or off toilet without subsequent striking against object, sequela',
  'W1812XA Fall from or off toilet with subsequent striking against object, initial encounter',
  'W1812XD Fall from or off toilet with subsequent striking against object, subsequent encounter',
  'W1812XS Fall from or off toilet with subsequent striking against object, sequela',
  'W182XXA Fall in (into) shower or empty bathtub, initial encounter',
  'W182XXD Fall in (into) shower or empty bathtub, subsequent encounter',
  'W182XXS Fall in (into) shower or empty bathtub, sequela',
  'W1830XA Fall on same level, unspecified, initial encounter',
  'W1830XD Fall on same level, unspecified, subsequent encounter',
  'W1830XS Fall on same level, unspecified, sequela',
  'W1831XA Fall on same level due to stepping on an object, initial encounter',
  'W1831XD Fall on same level due to stepping on an object, subsequent encounter',
  'W1831XS Fall on same level due to stepping on an object, sequela',
  'W1839XA Other fall on same level, initial encounter',
  'W1839XD Other fall on same level, subsequent encounter',
  'W1839XS Other fall on same level, sequela',
  'W1840XA Slipping, tripping and stumbling without falling, unspecified, initial encounter',
  'W1840XD Slipping, tripping and stumbling without falling, unspecified, subsequent encounter',
  'W1840XS Slipping, tripping and stumbling without falling, unspecified, sequela',
  'W1841XA Slipping, tripping and stumbling without falling due to stepping on object, initial encounter',
  'W1841XD Slipping, tripping and stumbling without falling due to stepping on object, subsequent',
  'encounter',
  'W1841XS Slipping, tripping and stumbling without falling due to stepping on object, sequela',
  'W1842XA Slipping, tripping and stumbling without falling due to stepping into hole or opening, initial',
  'encounter',
  'W1842XD Slipping, tripping and stumbling without falling due to stepping into hole or opening,',
  'subsequent encounter',
  'W1842XS Slipping, tripping and stumbling without falling due to stepping into hole or opening, sequela',
  'W1843XA Slipping, tripping and stumbling without falling due to stepping from one level to another,',
  'initial encounter',
  'W1843XD Slipping, tripping and stumbling without falling due to stepping from one level to another,',
  'subsequent encounter',
  'W1843XS Slipping, tripping and stumbling without falling due to stepping from one level to another,',
  'sequela',
  'W1849XA Other slipping, tripping and stumbling without falling, initial encounter',
  'W1849XD Other slipping, tripping and stumbling without falling, subsequent encounter',
  'W1849XS Other slipping, tripping and stumbling without falling, sequela',
  'W19XXXA Unspecified fall, initial encounter',
  'W19XXXD Unspecified fall, subsequent encounter',
  'W19XXXS Unspecified fall, sequela',
  'W200XXA Struck by falling object in cave-in, initial encounter',
  'W200XXD Struck by falling object in cave-in, subsequent encounter',
  'W200XXS Struck by falling object in cave-in, sequela',
  'W201XXA Struck by object due to collapse of building, initial encounter',
  'W201XXD Struck by object due to collapse of building, subsequent encounter',
  'W201XXS Struck by object due to collapse of building, sequela',
  'W208XXA Other cause of strike by thrown, projected or falling object, initial encounter',
  'W208XXD Other cause of strike by thrown, projected or falling object, subsequent encounter',
  'W208XXS Other cause of strike by thrown, projected or falling object, sequela',
  'W2100XA Struck by hit or thrown ball, unspecified type, initial encounter',
  'W2100XD Struck by hit or thrown ball, unspecified type, subsequent encounter',
  'W2100XS Struck by hit or thrown ball, unspecified type, sequela',
  'W2101XA Struck by football, initial encounter',
  'W2101XD Struck by football, subsequent encounter',
  'W2101XS Struck by football, sequela',
  'W2102XA Struck by soccer ball, initial encounter',
  'W2102XD Struck by soccer ball, subsequent encounter',
  'W2102XS Struck by soccer ball, sequela',
  'W2103XA Struck by baseball, initial encounter',
  'W2103XD Struck by baseball, subsequent encounter',
  'W2103XS Struck by baseball, sequela',
  'W2104XA Struck by golf ball, initial encounter',
  'W2104XD Struck by golf ball, subsequent encounter',
  'W2104XS Struck by golf ball, sequela',
  'W2105XA Struck by basketball, initial encounter',
  'W2105XD Struck by basketball, subsequent encounter',
  'W2105XS Struck by basketball, sequela',
  'W2106XA Struck by volleyball, initial encounter',
  'W2106XD Struck by volleyball, subsequent encounter',
  'W2106XS Struck by volleyball, sequela',
  'W2107XA Struck by softball, initial encounter',
  'W2107XD Struck by softball, subsequent encounter',
  'W2107XS Struck by softball, sequela',
  'W2109XA Struck by other hit or thrown ball, initial encounter',
  'W2109XD Struck by other hit or thrown ball, subsequent encounter',
  'W2109XS Struck by other hit or thrown ball, sequela',
  'W2111XA Struck by baseball bat, initial encounter',
  'W2111XD Struck by baseball bat, subsequent encounter',
  'W2111XS Struck by baseball bat, sequela',
  'W2112XA Struck by tennis racquet, initial encounter',
  'W2112XD Struck by tennis racquet, subsequent encounter',
  'W2112XS Struck by tennis racquet, sequela',
  'W2113XA Struck by golf club, initial encounter',
  'W2113XD Struck by golf club, subsequent encounter',
  'W2113XS Struck by golf club, sequela',
  'W2119XA Struck by other bat, racquet or club, initial encounter',
  'W2119XD Struck by other bat, racquet or club, subsequent encounter',
  'W2119XS Struck by other bat, racquet or club, sequela',
  'W21210A Struck by ice hockey stick, initial encounter',
  'W21210D Struck by ice hockey stick, subsequent encounter',
  'W21210S Struck by ice hockey stick, sequela',
  'W21211A Struck by field hockey stick, initial encounter',
  'W21211D Struck by field hockey stick, subsequent encounter',
  'W21211S Struck by field hockey stick, sequela',
  'W21220A Struck by ice hockey puck, initial encounter',
  'W21220D Struck by ice hockey puck, subsequent encounter',
  'W21220S Struck by ice hockey puck, sequela',
  'W21221A Struck by field hockey puck, initial encounter',
  'W21221D Struck by field hockey puck, subsequent encounter',
  'W21221S Struck by field hockey puck, sequela',
  'W2131XA Struck by shoe cleats, initial encounter',
  'W2131XD Struck by shoe cleats, subsequent encounter',
  'W2131XS Struck by shoe cleats, sequela',
  'W2132XA Struck by skate blades, initial encounter',
  'W2132XD Struck by skate blades, subsequent encounter',
  'W2132XS Struck by skate blades, sequela',
  'W2139XA Struck by other sports foot wear, initial encounter',
  'W2139XD Struck by other sports foot wear, subsequent encounter',
  'W2139XS Struck by other sports foot wear, sequela',
  'W214XXA Striking against diving board, initial encounter',
  'W214XXD Striking against diving board, subsequent encounter',
  'W214XXS Striking against diving board, sequela',
  'W2181XA Striking against or struck by football helmet, initial encounter',
  'W2181XD Striking against or struck by football helmet, subsequent encounter',
  'W2181XS Striking against or struck by football helmet, sequela',
  'W2189XA Striking against or struck by other sports equipment, initial encounter',
  'W2189XD Striking against or struck by other sports equipment, subsequent encounter',
  'W2189XS Striking against or struck by other sports equipment, sequela',
  'W219XXA Striking against or struck by unspecified sports equipment, initial encounter',
  'W219XXD Striking against or struck by unspecified sports equipment, subsequent encounter',
  'W219XXS Striking against or struck by unspecified sports equipment, sequela',
  'W2201XA Walked into wall, initial encounter',
  'W2201XD Walked into wall, subsequent encounter',
  'W2201XS Walked into wall, sequela',
  'W2202XA Walked into lamppost, initial encounter',
  'W2202XD Walked into lamppost, subsequent encounter',
  'W2202XS Walked into lamppost, sequela',
  'W2203XA Walked into furniture, initial encounter',
  'W2203XD Walked into furniture, subsequent encounter',
  'W2203XS Walked into furniture, sequela',
  'W22041A Striking against wall of swimming pool causing drowning and submersion, initial encounter',
  'W22041D Striking against wall of swimming pool causing drowning and submersion, subsequent',
  'encounter',
  'W22041S Striking against wall of swimming pool causing drowning and submersion, sequela',
  'W22042A Striking against wall of swimming pool causing other injury, initial encounter',
  'W22042D Striking against wall of swimming pool causing other injury, subsequent encounter',
  'W22042S Striking against wall of swimming pool causing other injury, sequela',
  'W2209XA Striking against other stationary object, initial encounter',
  'W2209XD Striking against other stationary object, subsequent encounter',
  'W2209XS Striking against other stationary object, sequela',
  'W2210XA Striking against or struck by unspecified automobile airbag, initial encounter',
  'W2210XD Striking against or struck by unspecified automobile airbag, subsequent encounter',
  'W2210XS Striking against or struck by unspecified automobile airbag, sequela',
  'W2211XA Striking against or struck by driver side automobile airbag, initial encounter',
  'W2211XD Striking against or struck by driver side automobile airbag, subsequent encounter',
  'W2211XS Striking against or struck by driver side automobile airbag, sequela',
  'W2212XA Striking against or struck by front passenger side automobile airbag, initial encounter',
  'W2212XD Striking against or struck by front passenger side automobile airbag, subsequent encounter',
  'W2212XS Striking against or struck by front passenger side automobile airbag, sequela',
  'W2219XA Striking against or struck by other automobile airbag, initial encounter',
  'W2219XD Striking against or struck by other automobile airbag, subsequent encounter',
  'W2219XS Striking against or struck by other automobile airbag, sequela',
  'W228XXA Striking against or struck by other objects, initial encounter',
  'W228XXD Striking against or struck by other objects, subsequent encounter',
  'W228XXS Striking against or struck by other objects, sequela',
  'W230XXA Caught, crushed, jammed, or pinched between moving objects, initial encounter',
  'W230XXD Caught, crushed, jammed, or pinched between moving objects, subsequent encounter',
  'W230XXS Caught, crushed, jammed, or pinched between moving objects, sequela',
  'W231XXA Caught, crushed, jammed, or pinched between stationary objects, initial encounter',
  'W231XXD Caught, crushed, jammed, or pinched between stationary objects, subsequent encounter',
  'W231XXS Caught, crushed, jammed, or pinched between stationary objects, sequela',
  'W240XXA Contact with lifting devices, not elsewhere classified, initial encounter',
  'W240XXD Contact with lifting devices, not elsewhere classified, subsequent encounter',
  'W240XXS Contact with lifting devices, not elsewhere classified, sequela',
  'W241XXA Contact with transmission devices, not elsewhere classified, initial encounter',
  'W241XXD Contact with transmission devices, not elsewhere classified, subsequent encounter',
  'W241XXS Contact with transmission devices, not elsewhere classified, sequela',
  'W25XXXA Contact with sharp glass, initial encounter',
  'W25XXXD Contact with sharp glass, subsequent encounter',
  'W25XXXS Contact with sharp glass, sequela',
  'W260XXA Contact with knife, initial encounter',
  'W260XXD Contact with knife, subsequent encounter',
  'W260XXS Contact with knife, sequela',
  'W261XXA Contact with sword or dagger, initial encounter',
  'W261XXD Contact with sword or dagger, subsequent encounter',
  'W261XXS Contact with sword or dagger, sequela',
  'W262XXA Contact with edge of stiff paper, initial encounter',
  'W262XXD Contact with edge of stiff paper, subsequent encounter',
  'W262XXS Contact with edge of stiff paper, sequela',
  'W268XXA Contact with other sharp object(s), not elsewhere classified, initial encounter',
  'W268XXD Contact with other sharp object(s), not elsewhere classified, subsequent encounter',
  'W268XXS Contact with other sharp object(s), not elsewhere classified, sequela',
  'W269XXA Contact with unspecified sharp object(s), initial encounter',
  'W269XXD Contact with unspecified sharp object(s), subsequent encounter',
  'W269XXS Contact with unspecified sharp object(s), sequela',
  'W270XXA Contact with workbench tool, initial encounter',
  'W270XXD Contact with workbench tool, subsequent encounter',
  'W270XXS Contact with workbench tool, sequela',
  'W271XXA Contact with garden tool, initial encounter',
  'W271XXD Contact with garden tool, subsequent encounter',
  'W271XXS Contact with garden tool, sequela',
  'W272XXA Contact with scissors, initial encounter',
  'W272XXD Contact with scissors, subsequent encounter',
  'W272XXS Contact with scissors, sequela',
  'W273XXA Contact with needle (sewing), initial encounter',
  'W273XXD Contact with needle (sewing), subsequent encounter',
  'W273XXS Contact with needle (sewing), sequela',
  'W274XXA Contact with kitchen utensil, initial encounter',
  'W274XXD Contact with kitchen utensil, subsequent encounter',
  'W274XXS Contact with kitchen utensil, sequela',
  'W275XXA Contact with paper-cutter, initial encounter',
  'W275XXD Contact with paper-cutter, subsequent encounter',
  'W275XXS Contact with paper-cutter, sequela',
  'W278XXA Contact with other nonpowered hand tool, initial encounter',
  'W278XXD Contact with other nonpowered hand tool, subsequent encounter',
  'W278XXS Contact with other nonpowered hand tool, sequela',
  'W28XXXA Contact with powered lawn mower, initial encounter',
  'W28XXXD Contact with powered lawn mower, subsequent encounter',
  'W28XXXS Contact with powered lawn mower, sequela',
  'W290XXA Contact with powered kitchen appliance, initial encounter',
  'W290XXD Contact with powered kitchen appliance, subsequent encounter',
  'W290XXS Contact with powered kitchen appliance, sequela',
  'W291XXA Contact with electric knife, initial encounter',
  'W291XXD Contact with electric knife, subsequent encounter',
  'W291XXS Contact with electric knife, sequela',
  'W292XXA Contact with other powered household machinery, initial encounter',
  'W292XXD Contact with other powered household machinery, subsequent encounter',
  'W292XXS Contact with other powered household machinery, sequela',
  'W293XXA Contact with powered garden and outdoor hand tools and machinery, initial encounter',
  'W293XXD Contact with powered garden and outdoor hand tools and machinery, subsequent encounter',
  'W293XXS Contact with powered garden and outdoor hand tools and machinery, sequela',
  'W294XXA Contact with nail gun, initial encounter',
  'W294XXD Contact with nail gun, subsequent encounter',
  'W294XXS Contact with nail gun, sequela',
  'W298XXA Contact with other powered powered hand tools and household machinery, initial encounter',
  'W298XXD Contact with other powered powered hand tools and household machinery, subsequent',
  'encounter',
  'W298XXS Contact with other powered powered hand tools and household machinery, sequela',
  'W300XXA Contact with combine harvester, initial encounter',
  'W300XXD Contact with combine harvester, subsequent encounter',
  'W300XXS Contact with combine harvester, sequela',
  'W301XXA Contact with power take-off devices (PTO), initial encounter',
  'W301XXD Contact with power take-off devices (PTO), subsequent encounter',
  'W301XXS Contact with power take-off devices (PTO), sequela',
  'W302XXA Contact with hay derrick, initial encounter',
  'W302XXD Contact with hay derrick, subsequent encounter',
  'W302XXS Contact with hay derrick, sequela',
  'W303XXA Contact with grain storage elevator, initial encounter',
  'W303XXD Contact with grain storage elevator, subsequent encounter',
  'W303XXS Contact with grain storage elevator, sequela',
  'W3081XA Contact with agricultural transport vehicle in stationary use, initial encounter',
  'W3081XD Contact with agricultural transport vehicle in stationary use, subsequent encounter',
  'W3081XS Contact with agricultural transport vehicle in stationary use, sequela',
  'W3089XA Contact with other specified agricultural machinery, initial encounter',
  'W3089XD Contact with other specified agricultural machinery, subsequent encounter',
  'W3089XS Contact with other specified agricultural machinery, sequela',
  'W309XXA Contact with unspecified agricultural machinery, initial encounter',
  'W309XXD Contact with unspecified agricultural machinery, subsequent encounter',
  'W309XXS Contact with unspecified agricultural machinery, sequela',
  'W310XXA Contact with mining and earth-drilling machinery, initial encounter',
  'W310XXD Contact with mining and earth-drilling machinery, subsequent encounter',
  'W310XXS Contact with mining and earth-drilling machinery, sequela',
  'W311XXA Contact with metalworking machines, initial encounter',
  'W311XXD Contact with metalworking machines, subsequent encounter',
  'W311XXS Contact with metalworking machines, sequela',
  'W312XXA Contact with powered woodworking and forming machines, initial encounter',
  'W312XXD Contact with powered woodworking and forming machines, subsequent encounter',
  'W312XXS Contact with powered woodworking and forming machines, sequela',
  'W313XXA Contact with prime movers, initial encounter',
  'W313XXD Contact with prime movers, subsequent encounter',
  'W313XXS Contact with prime movers, sequela',
  'W3181XA Contact with recreational machinery, initial encounter',
  'W3181XD Contact with recreational machinery, subsequent encounter',
  'W3181XS Contact with recreational machinery, sequela',
  'W3182XA Contact with other commercial machinery, initial encounter',
  'W3182XD Contact with other commercial machinery, subsequent encounter',
  'W3182XS Contact with other commercial machinery, sequela',
  'W3183XA Contact with special construction vehicle in stationary use, initial encounter',
  'W3183XD Contact with special construction vehicle in stationary use, subsequent encounter',
  'W3183XS Contact with special construction vehicle in stationary use, sequela',
  'W3189XA Contact with other specified machinery, initial encounter',
  'W3189XD Contact with other specified machinery, subsequent encounter',
  'W3189XS Contact with other specified machinery, sequela',
  'W319XXA Contact with unspecified machinery, initial encounter',
  'W319XXD Contact with unspecified machinery, subsequent encounter',
  'W319XXS Contact with unspecified machinery, sequela',
  'W320XXA Accidental handgun discharge, initial encounter',
  'W320XXD Accidental handgun discharge, subsequent encounter',
  'W320XXS Accidental handgun discharge, sequela',
  'W321XXA Accidental handgun malfunction, initial encounter',
  'W321XXD Accidental handgun malfunction, subsequent encounter',
  'W321XXS Accidental handgun malfunction, sequela',
  'W3300XA Accidental discharge of unspecified larger firearm, initial encounter',
  'W3300XD Accidental discharge of unspecified larger firearm, subsequent encounter',
  'W3300XS Accidental discharge of unspecified larger firearm, sequela',
  'W3301XA Accidental discharge of shotgun, initial encounter',
  'W3301XD Accidental discharge of shotgun, subsequent encounter',
  'W3301XS Accidental discharge of shotgun, sequela',
  'W3302XA Accidental discharge of hunting rifle, initial encounter',
  'W3302XD Accidental discharge of hunting rifle, subsequent encounter',
  'W3302XS Accidental discharge of hunting rifle, sequela',
  'W3303XA Accidental discharge of machine gun, initial encounter',
  'W3303XD Accidental discharge of machine gun, subsequent encounter',
  'W3303XS Accidental discharge of machine gun, sequela',
  'W3309XA Accidental discharge of other larger firearm, initial encounter',
  'W3309XD Accidental discharge of other larger firearm, subsequent encounter',
  'W3309XS Accidental discharge of other larger firearm, sequela',
  'W3310XA Accidental malfunction of unspecified larger firearm, initial encounter',
  'W3310XD Accidental malfunction of unspecified larger firearm, subsequent encounter',
  'W3310XS Accidental malfunction of unspecified larger firearm, sequela',
  'W3311XA Accidental malfunction of shotgun, initial encounter',
  'W3311XD Accidental malfunction of shotgun, subsequent encounter',
  'W3311XS Accidental malfunction of shotgun, sequela',
  'W3312XA Accidental malfunction of hunting rifle, initial encounter',
  'W3312XD Accidental malfunction of hunting rifle, subsequent encounter',
  'W3312XS Accidental malfunction of hunting rifle, sequela',
  'W3313XA Accidental malfunction of machine gun, initial encounter',
  'W3313XD Accidental malfunction of machine gun, subsequent encounter',
  'W3313XS Accidental malfunction of machine gun, sequela',
  'W3319XA Accidental malfunction of other larger firearm, initial encounter',
  'W3319XD Accidental malfunction of other larger firearm, subsequent encounter',
  'W3319XS Accidental malfunction of other larger firearm, sequela',
  'W3400XA Accidental discharge from unspecified firearms or gun, initial encounter',
  'W3400XD Accidental discharge from unspecified firearms or gun, subsequent encounter',
  'W3400XS Accidental discharge from unspecified firearms or gun, sequela',
  'W34010A Accidental discharge of airgun, initial encounter',
  'W34010D Accidental discharge of airgun, subsequent encounter',
  'W34010S Accidental discharge of airgun, sequela',
  'W34011A Accidental discharge of paintball gun, initial encounter',
  'W34011D Accidental discharge of paintball gun, subsequent encounter',
  'W34011S Accidental discharge of paintball gun, sequela',
  'W34018A Accidental discharge of other gas, air or spring-operated gun, initial encounter',
  'W34018D Accidental discharge of other gas, air or spring-operated gun, subsequent encounter',
  'W34018S Accidental discharge of other gas, air or spring-operated gun, sequela',
  'W3409XA Accidental discharge from other specified firearms, initial encounter',
  'W3409XD Accidental discharge from other specified firearms, subsequent encounter',
  'W3409XS Accidental discharge from other specified firearms, sequela',
  'W3410XA Accidental malfunction from unspecified firearms or gun, initial encounter',
  'W3410XD Accidental malfunction from unspecified firearms or gun, subsequent encounter',
  'W3410XS Accidental malfunction from unspecified firearms or gun, sequela',
  'W34110A Accidental malfunction of airgun, initial encounter',
  'W34110D Accidental malfunction of airgun, subsequent encounter',
  'W34110S Accidental malfunction of airgun, sequela',
  'W34111A Accidental malfunction of paintball gun, initial encounter',
  'W34111D Accidental malfunction of paintball gun, subsequent encounter',
  'W34111S Accidental malfunction of paintball gun, sequela',
  'W34118A Accidental malfunction of other gas, air or spring-operated gun, initial encounter',
  'W34118D Accidental malfunction of other gas, air or spring-operated gun, subsequent encounter',
  'W34118S Accidental malfunction of other gas, air or spring-operated gun, sequela',
  'W3419XA Accidental malfunction from other specified firearms, initial encounter',
  'W3419XD Accidental malfunction from other specified firearms, subsequent encounter',
  'W3419XS Accidental malfunction from other specified firearms, sequela',
  'W35XXXA Explosion and rupture of boiler, initial encounter',
  'W35XXXD Explosion and rupture of boiler, subsequent encounter',
  'W35XXXS Explosion and rupture of boiler, sequela',
  'W361XXA Explosion and rupture of aerosol can, initial encounter',
  'W361XXD Explosion and rupture of aerosol can, subsequent encounter',
  'W361XXS Explosion and rupture of aerosol can, sequela',
  'W362XXA Explosion and rupture of air tank, initial encounter',
  'W362XXD Explosion and rupture of air tank, subsequent encounter',
  'W362XXS Explosion and rupture of air tank, sequela',
  'W363XXA Explosion and rupture of pressurized-gas tank, initial encounter',
  'W363XXD Explosion and rupture of pressurized-gas tank, subsequent encounter',
  'W363XXS Explosion and rupture of pressurized-gas tank, sequela',
  'W368XXA Explosion and rupture of other gas cylinder, initial encounter',
  'W368XXD Explosion and rupture of other gas cylinder, subsequent encounter',
  'W368XXS Explosion and rupture of other gas cylinder, sequela',
  'W369XXA Explosion and rupture of unspecified gas cylinder, initial encounter',
  'W369XXD Explosion and rupture of unspecified gas cylinder, subsequent encounter',
  'W369XXS Explosion and rupture of unspecified gas cylinder, sequela',
  'W370XXA Explosion of bicycle tire, initial encounter',
  'W370XXD Explosion of bicycle tire, subsequent encounter',
  'W370XXS Explosion of bicycle tire, sequela',
  'W378XXA Explosion and rupture of other pressurized tire, pipe or hose, initial encounter',
  'W378XXD Explosion and rupture of other pressurized tire, pipe or hose, subsequent encounter',
  'W378XXS Explosion and rupture of other pressurized tire, pipe or hose, sequela',
  'W38XXXA Explosion and rupture of other specified pressurized devices, initial encounter',
  'W38XXXD Explosion and rupture of other specified pressurized devices, subsequent encounter',
  'W38XXXS Explosion and rupture of other specified pressurized devices, sequela',
  'W39XXXA Discharge of firework, initial encounter',
  'W39XXXD Discharge of firework, subsequent encounter',
  'W39XXXS Discharge of firework, sequela',
  'W400XXA Explosion of blasting material, initial encounter',
  'W400XXD Explosion of blasting material, subsequent encounter',
  'W400XXS Explosion of blasting material, sequela',
  'W401XXA Explosion of explosive gases, initial encounter',
  'W401XXD Explosion of explosive gases, subsequent encounter',
  'W401XXS Explosion of explosive gases, sequela',
  'W408XXA Explosion of other specified explosive materials, initial encounter',
  'W408XXD Explosion of other specified explosive materials, subsequent encounter',
  'W408XXS Explosion of other specified explosive materials, sequela',
  'W409XXA Explosion of unspecified explosive materials, initial encounter',
  'W409XXD Explosion of unspecified explosive materials, subsequent encounter',
  'W409XXS Explosion of unspecified explosive materials, sequela',
  'W420XXA Exposure to supersonic waves, initial encounter',
  'W420XXD Exposure to supersonic waves, subsequent encounter',
  'W420XXS Exposure to supersonic waves, sequela',
  'W429XXA Exposure to other noise, initial encounter',
  'W429XXD Exposure to other noise, subsequent encounter',
  'W429XXS Exposure to other noise, sequela',
  'W450XXA Nail entering through skin, initial encounter',
  'W450XXD Nail entering through skin, subsequent encounter',
  'W450XXS Nail entering through skin, sequela',
  'W458XXA Other foreign body or object entering through skin, initial encounter',
  'W458XXD Other foreign body or object entering through skin, subsequent encounter',
  'W458XXS Other foreign body or object entering through skin, sequela',
  'W460XXA Contact with hypodermic needle, initial encounter',
  'W460XXD Contact with hypodermic needle, subsequent encounter',
  'W460XXS Contact with hypodermic needle, sequela',
  'W461XXA Contact with contaminated hypodermic needle, initial encounter',
  'W461XXD Contact with contaminated hypodermic needle, subsequent encounter',
  'W461XXS Contact with contaminated hypodermic needle, sequela',
  'W4901XA Hair causing external constriction, initial encounter',
  'W4901XD Hair causing external constriction, subsequent encounter',
  'W4901XS Hair causing external constriction, sequela',
  'W4902XA String or thread causing external constriction, initial encounter',
  'W4902XD String or thread causing external constriction, subsequent encounter',
  'W4902XS String or thread causing external constriction, sequela',
  'W4903XA Rubber band causing external constriction, initial encounter',
  'W4903XD Rubber band causing external constriction, subsequent encounter',
  'W4903XS Rubber band causing external constriction, sequela',
  'W4904XA Ring or other jewelry causing external constriction, initial encounter',
  'W4904XD Ring or other jewelry causing external constriction, subsequent encounter',
  'W4904XS Ring or other jewelry causing external constriction, sequela',
  'W4909XA Other specified item causing external constriction, initial encounter',
  'W4909XD Other specified item causing external constriction, subsequent encounter',
  'W4909XS Other specified item causing external constriction, sequela',
  'W499XXA Exposure to other inanimate mechanical forces, initial encounter',
  'W499XXD Exposure to other inanimate mechanical forces, subsequent encounter',
  'W499XXS Exposure to other inanimate mechanical forces, sequela',
  'W500XXA Accidental hit or strike by another person, initial encounter',
  'W500XXD Accidental hit or strike by another person, subsequent encounter',
  'W500XXS Accidental hit or strike by another person, sequela',
  'W501XXA Accidental kick by another person, initial encounter',
  'W501XXD Accidental kick by another person, subsequent encounter',
  'W501XXS Accidental kick by another person, sequela',
  'W502XXA Accidental twist by another person, initial encounter',
  'W502XXD Accidental twist by another person, subsequent encounter',
  'W502XXS Accidental twist by another person, sequela',
  'W503XXA Accidental bite by another person, initial encounter',
  'W503XXD Accidental bite by another person, subsequent encounter',
  'W503XXS Accidental bite by another person, sequela',
  'W504XXA Accidental scratch by another person, initial encounter',
  'W504XXD Accidental scratch by another person, subsequent encounter',
  'W504XXS Accidental scratch by another person, sequela',
  'W51XXXA Accidental striking against or bumped into by another person, initial encounter',
  'W51XXXD Accidental striking against or bumped into by another person, subsequent encounter',
  'W51XXXS Accidental striking against or bumped into by another person, sequela',
  'W52XXXA Crushed, pushed or stepped on by crowd or human stampede, initial encounter',
  'W52XXXD Crushed, pushed or stepped on by crowd or human stampede, subsequent encounter',
  'W52XXXS Crushed, pushed or stepped on by crowd or human stampede, sequela',
  'W5301XA Bitten by mouse, initial encounter',
  'W5301XD Bitten by mouse, subsequent encounter',
  'W5301XS Bitten by mouse, sequela',
  'W5309XA Other contact with mouse, initial encounter',
  'W5309XD Other contact with mouse, subsequent encounter',
  'W5309XS Other contact with mouse, sequela',
  'W5311XA Bitten by rat, initial encounter',
  'W5311XD Bitten by rat, subsequent encounter',
  'W5311XS Bitten by rat, sequela',
  'W5319XA Other contact with rat, initial encounter',
  'W5319XD Other contact with rat, subsequent encounter',
  'W5319XS Other contact with rat, sequela',
  'W5321XA Bitten by squirrel, initial encounter',
  'W5321XD Bitten by squirrel, subsequent encounter',
  'W5321XS Bitten by squirrel, sequela',
  'W5329XA Other contact with squirrel, initial encounter',
  'W5329XD Other contact with squirrel, subsequent encounter',
  'W5329XS Other contact with squirrel, sequela',
  'W5381XA Bitten by other rodent, initial encounter',
  'W5381XD Bitten by other rodent, subsequent encounter',
  'W5381XS Bitten by other rodent, sequela',
  'W5389XA Other contact with other rodent, initial encounter',
  'W5389XD Other contact with other rodent, subsequent encounter',
  'W5389XS Other contact with other rodent, sequela',
  'W540XXA Bitten by dog, initial encounter',
  'W540XXD Bitten by dog, subsequent encounter',
  'W540XXS Bitten by dog, sequela',
  'W541XXA Struck by dog, initial encounter',
  'W541XXD Struck by dog, subsequent encounter',
  'W541XXS Struck by dog, sequela',
  'W548XXA Other contact with dog, initial encounter',
  'W548XXD Other contact with dog, subsequent encounter',
  'W548XXS Other contact with dog, sequela',
  'W5501XA Bitten by cat, initial encounter',
  'W5501XD Bitten by cat, subsequent encounter',
  'W5501XS Bitten by cat, sequela',
  'W5503XA Scratched by cat, initial encounter',
  'W5503XD Scratched by cat, subsequent encounter',
  'W5503XS Scratched by cat, sequela',
  'W5509XA Other contact with cat, initial encounter',
  'W5509XD Other contact with cat, subsequent encounter',
  'W5509XS Other contact with cat, sequela',
  'W5511XA Bitten by horse, initial encounter',
  'W5511XD Bitten by horse, subsequent encounter',
  'W5511XS Bitten by horse, sequela',
  'W5512XA Struck by horse, initial encounter',
  'W5512XD Struck by horse, subsequent encounter',
  'W5512XS Struck by horse, sequela',
  'W5519XA Other contact with horse, initial encounter',
  'W5519XD Other contact with horse, subsequent encounter',
  'W5519XS Other contact with horse, sequela',
  'W5521XA Bitten by cow, initial encounter',
  'W5521XD Bitten by cow, subsequent encounter',
  'W5521XS Bitten by cow, sequela',
  'W5522XA Struck by cow, initial encounter',
  'W5522XD Struck by cow, subsequent encounter',
  'W5522XS Struck by cow, sequela',
  'W5529XA Other contact with cow, initial encounter',
  'W5529XD Other contact with cow, subsequent encounter',
  'W5529XS Other contact with cow, sequela',
  'W5531XA Bitten by other hoof stock, initial encounter',
  'W5531XD Bitten by other hoof stock, subsequent encounter',
  'W5531XS Bitten by other hoof stock, sequela',
  'W5532XA Struck by other hoof stock, initial encounter',
  'W5532XD Struck by other hoof stock, subsequent encounter',
  'W5532XS Struck by other hoof stock, sequela',
  'W5539XA Other contact with other hoof stock, initial encounter',
  'W5539XD Other contact with other hoof stock, subsequent encounter',
  'W5539XS Other contact with other hoof stock, sequela',
  'W5541XA Bitten by pig, initial encounter',
  'W5541XD Bitten by pig, subsequent encounter',
  'W5541XS Bitten by pig, sequela',
  'W5542XA Struck by pig, initial encounter',
  'W5542XD Struck by pig, subsequent encounter',
  'W5542XS Struck by pig, sequela',
  'W5549XA Other contact with pig, initial encounter',
  'W5549XD Other contact with pig, subsequent encounter',
  'W5549XS Other contact with pig, sequela',
  'W5551XA Bitten by raccoon, initial encounter',
  'W5551XD Bitten by raccoon, subsequent encounter',
  'W5551XS Bitten by raccoon, sequela',
  'W5552XA Struck by raccoon, initial encounter',
  'W5552XD Struck by raccoon, subsequent encounter',
  'W5552XS Struck by raccoon, sequela',
  'W5559XA Other contact with raccoon, initial encounter',
  'W5559XD Other contact with raccoon, subsequent encounter',
  'W5559XS Other contact with raccoon, sequela',
  'W5581XA Bitten by other mammals, initial encounter',
  'W5581XD Bitten by other mammals, subsequent encounter',
  'W5581XS Bitten by other mammals, sequela',
  'W5582XA Struck by other mammals, initial encounter',
  'W5582XD Struck by other mammals, subsequent encounter',
  'W5582XS Struck by other mammals, sequela',
  'W5589XA Other contact with other mammals, initial encounter',
  'W5589XD Other contact with other mammals, subsequent encounter',
  'W5589XS Other contact with other mammals, sequela',
  'W5601XA Bitten by dolphin, initial encounter',
  'W5601XD Bitten by dolphin, subsequent encounter',
  'W5601XS Bitten by dolphin, sequela',
  'W5602XA Struck by dolphin, initial encounter',
  'W5602XD Struck by dolphin, subsequent encounter',
  'W5602XS Struck by dolphin, sequela',
  'W5609XA Other contact with dolphin, initial encounter',
  'W5609XD Other contact with dolphin, subsequent encounter',
  'W5609XS Other contact with dolphin, sequela',
  'W5611XA Bitten by sea lion, initial encounter',
  'W5611XD Bitten by sea lion, subsequent encounter',
  'W5611XS Bitten by sea lion, sequela',
  'W5612XA Struck by sea lion, initial encounter',
  'W5612XD Struck by sea lion, subsequent encounter',
  'W5612XS Struck by sea lion, sequela',
  'W5619XA Other contact with sea lion, initial encounter',
  'W5619XD Other contact with sea lion, subsequent encounter',
  'W5619XS Other contact with sea lion, sequela',
  'W5621XA Bitten by orca, initial encounter',
  'W5621XD Bitten by orca, subsequent encounter',
  'W5621XS Bitten by orca, sequela',
  'W5622XA Struck by orca, initial encounter',
  'W5622XD Struck by orca, subsequent encounter',
  'W5622XS Struck by orca, sequela',
  'W5629XA Other contact with orca, initial encounter',
  'W5629XD Other contact with orca, subsequent encounter',
  'W5629XS Other contact with orca, sequela',
  'W5631XA Bitten by other marine mammals, initial encounter',
  'W5631XD Bitten by other marine mammals, subsequent encounter',
  'W5631XS Bitten by other marine mammals, sequela',
  'W5632XA Struck by other marine mammals, initial encounter',
  'W5632XD Struck by other marine mammals, subsequent encounter',
  'W5632XS Struck by other marine mammals, sequela',
  'W5639XA Other contact with other marine mammals, initial encounter',
  'W5639XD Other contact with other marine mammals, subsequent encounter',
  'W5639XS Other contact with other marine mammals, sequela',
  'W5641XA Bitten by shark, initial encounter',
  'W5641XD Bitten by shark, subsequent encounter',
  'W5641XS Bitten by shark, sequela',
  'W5642XA Struck by shark, initial encounter',
  'W5642XD Struck by shark, subsequent encounter',
  'W5642XS Struck by shark, sequela',
  'W5649XA Other contact with shark, initial encounter',
  'W5649XD Other contact with shark, subsequent encounter',
  'W5649XS Other contact with shark, sequela',
  'W5651XA Bitten by other fish, initial encounter',
  'W5651XD Bitten by other fish, subsequent encounter',
  'W5651XS Bitten by other fish, sequela',
  'W5652XA Struck by other fish, initial encounter',
  'W5652XD Struck by other fish, subsequent encounter',
  'W5652XS Struck by other fish, sequela',
  'W5659XA Other contact with other fish, initial encounter',
  'W5659XD Other contact with other fish, subsequent encounter',
  'W5659XS Other contact with other fish, sequela',
  'W5681XA Bitten by other nonvenomous marine animals, initial encounter',
  'W5681XD Bitten by other nonvenomous marine animals, subsequent encounter',
  'W5681XS Bitten by other nonvenomous marine animals, sequela',
  'W5682XA Struck by other nonvenomous marine animals, initial encounter',
  'W5682XD Struck by other nonvenomous marine animals, subsequent encounter',
  'W5682XS Struck by other nonvenomous marine animals, sequela',
  'W5689XA Other contact with other nonvenomous marine animals, initial encounter',
  'W5689XD Other contact with other nonvenomous marine animals, subsequent encounter',
  'W5689XS Other contact with other nonvenomous marine animals, sequela',
  'W57XXXA Bitten or stung by nonvenomous insect and other nonvenomous arthropods, initial encounter',
  'W57XXXD Bitten or stung by nonvenomous insect and other nonvenomous arthropods, subsequent',
  'encounter',
  'W57XXXS Bitten or stung by nonvenomous insect and other nonvenomous arthropods, sequela',
  'W5801XA Bitten by alligator, initial encounter',
  'W5801XD Bitten by alligator, subsequent encounter',
  'W5801XS Bitten by alligator, sequela',
  'W5802XA Struck by alligator, initial encounter',
  'W5802XD Struck by alligator, subsequent encounter',
  'W5802XS Struck by alligator, sequela',
  'W5803XA Crushed by alligator, initial encounter',
  'W5803XD Crushed by alligator, subsequent encounter',
  'W5803XS Crushed by alligator, sequela',
  'W5809XA Other contact with alligator, initial encounter',
  'W5809XD Other contact with alligator, subsequent encounter',
  'W5809XS Other contact with alligator, sequela',
  'W5811XA Bitten by crocodile, initial encounter',
  'W5811XD Bitten by crocodile, subsequent encounter',
  'W5811XS Bitten by crocodile, sequela',
  'W5812XA Struck by crocodile, initial encounter',
  'W5812XD Struck by crocodile, subsequent encounter',
  'W5812XS Struck by crocodile, sequela',
  'W5813XA Crushed by crocodile, initial encounter',
  'W5813XD Crushed by crocodile, subsequent encounter',
  'W5813XS Crushed by crocodile, sequela',
  'W5819XA Other contact with crocodile, initial encounter',
  'W5819XD Other contact with crocodile, subsequent encounter',
  'W5819XS Other contact with crocodile, sequela',
  'W5901XA Bitten by nonvenomous lizards, initial encounter',
  'W5901XD Bitten by nonvenomous lizards, subsequent encounter',
  'W5901XS Bitten by nonvenomous lizards, sequela',
  'W5902XA Struck by nonvenomous lizards, initial encounter',
  'W5902XD Struck by nonvenomous lizards, subsequent encounter',
  'W5902XS Struck by nonvenomous lizards, sequela',
  'W5909XA Other contact with nonvenomous lizards, initial encounter',
  'W5909XD Other contact with nonvenomous lizards, subsequent encounter',
  'W5909XS Other contact with nonvenomous lizards, sequela',
  'W5911XA Bitten by nonvenomous snake, initial encounter',
  'W5911XD Bitten by nonvenomous snake, subsequent encounter',
  'W5911XS Bitten by nonvenomous snake, sequela',
  'W5912XA Struck by nonvenomous snake, initial encounter',
  'W5912XD Struck by nonvenomous snake, subsequent encounter',
  'W5912XS Struck by nonvenomous snake, sequela',
  'W5913XA Crushed by nonvenomous snake, initial encounter',
  'W5913XD Crushed by nonvenomous snake, subsequent encounter',
  'W5913XS Crushed by nonvenomous snake, sequela',
  'W5919XA Other contact with nonvenomous snake, initial encounter',
  'W5919XD Other contact with nonvenomous snake, subsequent encounter',
  'W5919XS Other contact with nonvenomous snake, sequela',
  'W5921XA Bitten by turtle, initial encounter',
  'W5921XD Bitten by turtle, subsequent encounter',
  'W5921XS Bitten by turtle, sequela',
  'W5922XA Struck by turtle, initial encounter',
  'W5922XD Struck by turtle, subsequent encounter',
  'W5922XS Struck by turtle, sequela',
  'W5929XA Other contact with turtle, initial encounter',
  'W5929XD Other contact with turtle, subsequent encounter',
  'W5929XS Other contact with turtle, sequela',
  'W5981XA Bitten by other nonvenomous reptiles, initial encounter',
  'W5981XD Bitten by other nonvenomous reptiles, subsequent encounter',
  'W5981XS Bitten by other nonvenomous reptiles, sequela',
  'W5982XA Struck by other nonvenomous reptiles, initial encounter',
  'W5982XD Struck by other nonvenomous reptiles, subsequent encounter',
  'W5982XS Struck by other nonvenomous reptiles, sequela',
  'W5983XA Crushed by other nonvenomous reptiles, initial encounter',
  'W5983XD Crushed by other nonvenomous reptiles, subsequent encounter',
  'W5983XS Crushed by other nonvenomous reptiles, sequela',
  'W5989XA Other contact with other nonvenomous reptiles, initial encounter',
  'W5989XD Other contact with other nonvenomous reptiles, subsequent encounter',
  'W5989XS Other contact with other nonvenomous reptiles, sequela',
  'W60XXXA Contact with nonvenomous plant thorns and spines and sharp leaves, initial encounter',
  'W60XXXD Contact with nonvenomous plant thorns and spines and sharp leaves, subsequent encounter',
  'W60XXXS Contact with nonvenomous plant thorns and spines and sharp leaves, sequela',
  'W6101XA Bitten by parrot, initial encounter',
  'W6101XD Bitten by parrot, subsequent encounter',
  'W6101XS Bitten by parrot, sequela',
  'W6102XA Struck by parrot, initial encounter',
  'W6102XD Struck by parrot, subsequent encounter',
  'W6102XS Struck by parrot, sequela',
  'W6109XA Other contact with parrot, initial encounter',
  'W6109XD Other contact with parrot, subsequent encounter',
  'W6109XS Other contact with parrot, sequela',
  'W6111XA Bitten by macaw, initial encounter',
  'W6111XD Bitten by macaw, subsequent encounter',
  'W6111XS Bitten by macaw, sequela',
  'W6112XA Struck by macaw, initial encounter',
  'W6112XD Struck by macaw, subsequent encounter',
  'W6112XS Struck by macaw, sequela',
  'W6119XA Other contact with macaw, initial encounter',
  'W6119XD Other contact with macaw, subsequent encounter',
  'W6119XS Other contact with macaw, sequela',
  'W6121XA Bitten by other psittacines, initial encounter',
  'W6121XD Bitten by other psittacines, subsequent encounter',
  'W6121XS Bitten by other psittacines, sequela',
  'W6122XA Struck by other psittacines, initial encounter',
  'W6122XD Struck by other psittacines, subsequent encounter',
  'W6122XS Struck by other psittacines, sequela',
  'W6129XA Other contact with other psittacines, initial encounter',
  'W6129XD Other contact with other psittacines, subsequent encounter',
  'W6129XS Other contact with other psittacines, sequela',
  'W6132XA Struck by chicken, initial encounter',
  'W6132XD Struck by chicken, subsequent encounter',
  'W6132XS Struck by chicken, sequela',
  'W6133XA Pecked by chicken, initial encounter',
  'W6133XD Pecked by chicken, subsequent encounter',
  'W6133XS Pecked by chicken, sequela',
  'W6139XA Other contact with chicken, initial encounter',
  'W6139XD Other contact with chicken, subsequent encounter',
  'W6139XS Other contact with chicken, sequela',
  'W6142XA Struck by turkey, initial encounter',
  'W6142XD Struck by turkey, subsequent encounter',
  'W6142XS Struck by turkey, sequela',
  'W6143XA Pecked by turkey, initial encounter',
  'W6143XD Pecked by turkey, subsequent encounter',
  'W6143XS Pecked by turkey, sequela',
  'W6149XA Other contact with turkey, initial encounter',
  'W6149XD Other contact with turkey, subsequent encounter',
  'W6149XS Other contact with turkey, sequela',
  'W6151XA Bitten by goose, initial encounter',
  'W6151XD Bitten by goose, subsequent encounter',
  'W6151XS Bitten by goose, sequela',
  'W6152XA Struck by goose, initial encounter',
  'W6152XD Struck by goose, subsequent encounter',
  'W6152XS Struck by goose, sequela',
  'W6159XA Other contact with goose, initial encounter',
  'W6159XD Other contact with goose, subsequent encounter',
  'W6159XS Other contact with goose, sequela',
  'W6161XA Bitten by duck, initial encounter',
  'W6161XD Bitten by duck, subsequent encounter',
  'W6161XS Bitten by duck, sequela',
  'W6162XA Struck by duck, initial encounter',
  'W6162XD Struck by duck, subsequent encounter',
  'W6162XS Struck by duck, sequela',
  'W6169XA Other contact with duck, initial encounter',
  'W6169XD Other contact with duck, subsequent encounter',
  'W6169XS Other contact with duck, sequela',
  'W6191XA Bitten by other birds, initial encounter',
  'W6191XD Bitten by other birds, subsequent encounter',
  'W6191XS Bitten by other birds, sequela',
  'W6192XA Struck by other birds, initial encounter',
  'W6192XD Struck by other birds, subsequent encounter',
  'W6192XS Struck by other birds, sequela',
  'W6199XA Other contact with other birds, initial encounter',
  'W6199XD Other contact with other birds, subsequent encounter',
  'W6199XS Other contact with other birds, sequela',
  'W620XXA Contact with nonvenomous frogs, initial encounter',
  'W620XXD Contact with nonvenomous frogs, subsequent encounter',
  'W620XXS Contact with nonvenomous frogs, sequela',
  'W621XXA Contact with nonvenomous toads, initial encounter',
  'W621XXD Contact with nonvenomous toads, subsequent encounter',
  'W621XXS Contact with nonvenomous toads, sequela',
  'W629XXA Contact with other nonvenomous amphibians, initial encounter',
  'W629XXD Contact with other nonvenomous amphibians, subsequent encounter',
  'W629XXS Contact with other nonvenomous amphibians, sequela',
  'W64XXXA Exposure to other animate mechanical forces, initial encounter',
  'W64XXXD Exposure to other animate mechanical forces, subsequent encounter',
  'W64XXXS Exposure to other animate mechanical forces, sequela',
  'W65XXXA Accidental drowning and submersion while in bath-tub, initial encounter',
  'W65XXXD Accidental drowning and submersion while in bath-tub, subsequent encounter',
  'W65XXXS Accidental drowning and submersion while in bath-tub, sequela',
  'W67XXXA Accidental drowning and submersion while in swimming-pool, initial encounter',
  'W67XXXD Accidental drowning and submersion while in swimming-pool, subsequent encounter',
  'W67XXXS Accidental drowning and submersion while in swimming-pool, sequela',
  'W69XXXA Accidental drowning and submersion while in natural water, initial encounter',
  'W69XXXD Accidental drowning and submersion while in natural water, subsequent encounter',
  'W69XXXS Accidental drowning and submersion while in natural water, sequela',
  'W73XXXA Other specified cause of accidental non-transport drowning and submersion, initial encounter',
  'W73XXXD Other specified cause of accidental non-transport drowning and submersion, subsequent',
  'encounter',
  'W73XXXS Other specified cause of accidental non-transport drowning and submersion, sequela',
  'W74XXXA Unspecified cause of accidental drowning and submersion, initial encounter',
  'W74XXXD Unspecified cause of accidental drowning and submersion, subsequent encounter',
  'W74XXXS Unspecified cause of accidental drowning and submersion, sequela',
  'W85XXXA Exposure to electric transmission lines, initial encounter',
  'W85XXXD Exposure to electric transmission lines, subsequent encounter',
  'W85XXXS Exposure to electric transmission lines, sequela',
  'W860XXA Exposure to domestic wiring and appliances, initial encounter',
  'W860XXD Exposure to domestic wiring and appliances, subsequent encounter',
  'W860XXS Exposure to domestic wiring and appliances, sequela',
  'W861XXA Exposure to industrial wiring, appliances and electrical machinery, initial encounter',
  'W861XXD Exposure to industrial wiring, appliances and electrical machinery, subsequent encounter',
  'W861XXS Exposure to industrial wiring, appliances and electrical machinery, sequela',
  'W868XXA Exposure to other electric current, initial encounter',
  'W868XXD Exposure to other electric current, subsequent encounter',
  'W868XXS Exposure to other electric current, sequela',
  'W880XXA Exposure to X-rays, initial encounter',
  'W880XXD Exposure to X-rays, subsequent encounter',
  'W880XXS Exposure to X-rays, sequela',
  'W881XXA Exposure to radioactive isotopes, initial encounter',
  'W881XXD Exposure to radioactive isotopes, subsequent encounter',
  'W881XXS Exposure to radioactive isotopes, sequela',
  'W888XXA Exposure to other ionizing radiation, initial encounter',
  'W888XXD Exposure to other ionizing radiation, subsequent encounter',
  'W888XXS Exposure to other ionizing radiation, sequela',
  'W890XXA Exposure to welding light (arc), initial encounter',
  'W890XXD Exposure to welding light (arc), subsequent encounter',
  'W890XXS Exposure to welding light (arc), sequela',
  'W891XXA Exposure to tanning bed, initial encounter',
  'W891XXD Exposure to tanning bed, subsequent encounter',
  'W891XXS Exposure to tanning bed, sequela',
  'W898XXA Exposure to other man-made visible and ultraviolet light, initial encounter',
  'W898XXD Exposure to other man-made visible and ultraviolet light, subsequent encounter',
  'W898XXS Exposure to other man-made visible and ultraviolet light, sequela',
  'W899XXA Exposure to unspecified man-made visible and ultraviolet light, initial encounter',
  'W899XXD Exposure to unspecified man-made visible and ultraviolet light, subsequent encounter',
  'W899XXS Exposure to unspecified man-made visible and ultraviolet light, sequela',
  'W900XXA Exposure to radiofrequency, initial encounter',
  'W900XXD Exposure to radiofrequency, subsequent encounter',
  'W900XXS Exposure to radiofrequency, sequela',
  'W901XXA Exposure to infrared radiation, initial encounter',
  'W901XXD Exposure to infrared radiation, subsequent encounter',
  'W901XXS Exposure to infrared radiation, sequela',
  'W902XXA Exposure to laser radiation, initial encounter',
  'W902XXD Exposure to laser radiation, subsequent encounter',
  'W902XXS Exposure to laser radiation, sequela',
  'W908XXA Exposure to other nonionizing radiation, initial encounter',
  'W908XXD Exposure to other nonionizing radiation, subsequent encounter',
  'W908XXS Exposure to other nonionizing radiation, sequela',
  'W92XXXA Exposure to excessive heat of man-made origin, initial encounter',
  'W92XXXD Exposure to excessive heat of man-made origin, subsequent encounter',
  'W92XXXS Exposure to excessive heat of man-made origin, sequela',
  'W9301XA Contact with dry ice, initial encounter',
  'W9301XD Contact with dry ice, subsequent encounter',
  'W9301XS Contact with dry ice, sequela',
  'W9302XA Inhalation of dry ice, initial encounter',
  'W9302XD Inhalation of dry ice, subsequent encounter',
  'W9302XS Inhalation of dry ice, sequela',
  'W9311XA Contact with liquid air, initial encounter',
  'W9311XD Contact with liquid air, subsequent encounter',
  'W9311XS Contact with liquid air, sequela',
  'W9312XA Inhalation of liquid air, initial encounter',
  'W9312XD Inhalation of liquid air, subsequent encounter',
  'W9312XS Inhalation of liquid air, sequela',
  'W932XXA Prolonged exposure in deep freeze unit or refrigerator, initial encounter',
  'W932XXD Prolonged exposure in deep freeze unit or refrigerator, subsequent encounter',
  'W932XXS Prolonged exposure in deep freeze unit or refrigerator, sequela',
  'W938XXA Exposure to other excessive cold of man-made origin, initial encounter',
  'W938XXD Exposure to other excessive cold of man-made origin, subsequent encounter',
  'W938XXS Exposure to other excessive cold of man-made origin, sequela',
  'W940XXA Exposure to prolonged high air pressure, initial encounter',
  'W940XXD Exposure to prolonged high air pressure, subsequent encounter',
  'W940XXS Exposure to prolonged high air pressure, sequela',
  'W9411XA Exposure to residence or prolonged visit at high altitude, initial encounter',
  'W9411XD Exposure to residence or prolonged visit at high altitude, subsequent encounter',
  'W9411XS Exposure to residence or prolonged visit at high altitude, sequela',
  'W9412XA Exposure to other prolonged low air pressure, initial encounter',
  'W9412XD Exposure to other prolonged low air pressure, subsequent encounter',
  'W9412XS Exposure to other prolonged low air pressure, sequela',
  'W9421XA Exposure to reduction in atmospheric pressure while surfacing from deep-water diving, initial',
  'encounter',
  'W9421XD Exposure to reduction in atmospheric pressure while surfacing from deep-water diving,',
  'subsequent encounter',
  'W9421XS Exposure to reduction in atmospheric pressure while surfacing from deep-water diving,',
  'sequela',
  'W9422XA Exposure to reduction in atmospheric pressure while surfacing from underground, initial',
  'encounter',
  'W9422XD Exposure to reduction in atmospheric pressure while surfacing from underground,',
  'subsequent encounter',
  'W9422XS Exposure to reduction in atmospheric pressure while surfacing from underground, sequela',
  'W9423XA Exposure to sudden change in air pressure in aircraft during ascent, initial encounter',
  'W9423XD Exposure to sudden change in air pressure in aircraft during ascent, subsequent encounter',
  'W9423XS Exposure to sudden change in air pressure in aircraft during ascent, sequela',
  'W9429XA Exposure to other rapid changes in air pressure during ascent, initial encounter',
  'W9429XD Exposure to other rapid changes in air pressure during ascent, subsequent encounter',
  'W9429XS Exposure to other rapid changes in air pressure during ascent, sequela',
  'W9431XA Exposure to sudden change in air pressure in aircraft during descent, initial encounter',
  'W9431XD Exposure to sudden change in air pressure in aircraft during descent, subsequent encounter',
  'W9431XS Exposure to sudden change in air pressure in aircraft during descent, sequela',
  'W9432XA Exposure to high air pressure from rapid descent in water, initial encounter',
  'W9432XD Exposure to high air pressure from rapid descent in water, subsequent encounter',
  'W9432XS Exposure to high air pressure from rapid descent in water, sequela',
  'W9439XA Exposure to other rapid changes in air pressure during descent, initial encounter',
  'W9439XD Exposure to other rapid changes in air pressure during descent, subsequent encounter',
  'W9439XS Exposure to other rapid changes in air pressure during descent, sequela',
  'W99XXXA Exposure to other man-made environmental factors, initial encounter',
  'W99XXXD Exposure to other man-made environmental factors, subsequent encounter',
  'W99XXXS Exposure to other man-made environmental factors, sequela',
  'X000XXA Exposure to flames in uncontrolled fire in building or structure, initial encounter',
  'X000XXD Exposure to flames in uncontrolled fire in building or structure, subsequent encounter',
  'X000XXS Exposure to flames in uncontrolled fire in building or structure, sequela',
  'X001XXA Exposure to smoke in uncontrolled fire in building or structure, initial encounter',
  'X001XXD Exposure to smoke in uncontrolled fire in building or structure, subsequent encounter',
  'X001XXS Exposure to smoke in uncontrolled fire in building or structure, sequela',
  'X002XXA Injury due to collapse of burning building or structure in uncontrolled fire, initial encounter',
  'X002XXD Injury due to collapse of burning building or structure in uncontrolled fire, subsequent',
  'encounter',
  'X002XXS Injury due to collapse of burning building or structure in uncontrolled fire, sequela',
  'X003XXA Fall from burning building or structure in uncontrolled fire, initial encounter',
  'X003XXD Fall from burning building or structure in uncontrolled fire, subsequent encounter',
  'X003XXS Fall from burning building or structure in uncontrolled fire, sequela',
  'X004XXA Hit by object from burning building or structure in uncontrolled fire, initial encounter',
  'X004XXD Hit by object from burning building or structure in uncontrolled fire, subsequent encounter',
  'X004XXS Hit by object from burning building or structure in uncontrolled fire, sequela',
  'X005XXA Jump from burning building or structure in uncontrolled fire, initial encounter',
  'X005XXD Jump from burning building or structure in uncontrolled fire, subsequent encounter',
  'X005XXS Jump from burning building or structure in uncontrolled fire, sequela',
  'X008XXA Other exposure to uncontrolled fire in building or structure, initial encounter',
  'X008XXD Other exposure to uncontrolled fire in building or structure, subsequent encounter',
  'X008XXS Other exposure to uncontrolled fire in building or structure, sequela',
  'X010XXA Exposure to flames in uncontrolled fire, not in building or structure, initial encounter',
  'X010XXD Exposure to flames in uncontrolled fire, not in building or structure, subsequent encounter',
  'X010XXS Exposure to flames in uncontrolled fire, not in building or structure, sequela',
  'X011XXA Exposure to smoke in uncontrolled fire, not in building or structure, initial encounter',
  'X011XXD Exposure to smoke in uncontrolled fire, not in building or structure, subsequent encounter',
  'X011XXS Exposure to smoke in uncontrolled fire, not in building or structure, sequela',
  'X013XXA Fall due to uncontrolled fire, not in building or structure, initial encounter',
  'X013XXD Fall due to uncontrolled fire, not in building or structure, subsequent encounter',
  'X013XXS Fall due to uncontrolled fire, not in building or structure, sequela',
  'X014XXA Hit by object due to uncontrolled fire, not in building or structure, initial encounter',
  'X014XXD Hit by object due to uncontrolled fire, not in building or structure, subsequent encounter',
  'X014XXS Hit by object due to uncontrolled fire, not in building or structure, sequela',
  'X018XXA Other exposure to uncontrolled fire, not in building or structure, initial encounter',
  'X018XXD Other exposure to uncontrolled fire, not in building or structure, subsequent encounter',
  'X018XXS Other exposure to uncontrolled fire, not in building or structure, sequela',
  'X020XXA Exposure to flames in controlled fire in building or structure, initial encounter',
  'X020XXD Exposure to flames in controlled fire in building or structure, subsequent encounter',
  'X020XXS Exposure to flames in controlled fire in building or structure, sequela',
  'X021XXA Exposure to smoke in controlled fire in building or structure, initial encounter',
  'X021XXD Exposure to smoke in controlled fire in building or structure, subsequent encounter',
  'X021XXS Exposure to smoke in controlled fire in building or structure, sequela',
  'X022XXA Injury due to collapse of burning building or structure in controlled fire, initial encounter',
  'X022XXD Injury due to collapse of burning building or structure in controlled fire, subsequent encounter',
  'X022XXS Injury due to collapse of burning building or structure in controlled fire, sequela',
  'X023XXA Fall from burning building or structure in controlled fire, initial encounter',
  'X023XXD Fall from burning building or structure in controlled fire, subsequent encounter',
  'X023XXS Fall from burning building or structure in controlled fire, sequela',
  'X024XXA Hit by object from burning building or structure in controlled fire, initial encounter',
  'X024XXD Hit by object from burning building or structure in controlled fire, subsequent encounter',
  'X024XXS Hit by object from burning building or structure in controlled fire, sequela',
  'X025XXA Jump from burning building or structure in controlled fire, initial encounter',
  'X025XXD Jump from burning building or structure in controlled fire, subsequent encounter',
  'X025XXS Jump from burning building or structure in controlled fire, sequela',
  'X028XXA Other exposure to controlled fire in building or structure, initial encounter',
  'X028XXD Other exposure to controlled fire in building or structure, subsequent encounter',
  'X028XXS Other exposure to controlled fire in building or structure, sequela',
  'X030XXA Exposure to flames in controlled fire, not in building or structure, initial encounter',
  'X030XXD Exposure to flames in controlled fire, not in building or structure, subsequent encounter',
  'X030XXS Exposure to flames in controlled fire, not in building or structure, sequela',
  'X031XXA Exposure to smoke in controlled fire, not in building or structure, initial encounter',
  'X031XXD Exposure to smoke in controlled fire, not in building or structure, subsequent encounter',
  'X031XXS Exposure to smoke in controlled fire, not in building or structure, sequela',
  'X033XXA Fall due to controlled fire, not in building or structure, initial encounter',
  'X033XXD Fall due to controlled fire, not in building or structure, subsequent encounter',
  'X033XXS Fall due to controlled fire, not in building or structure, sequela',
  'X034XXA Hit by object due to controlled fire, not in building or structure, initial encounter',
  'X034XXD Hit by object due to controlled fire, not in building or structure, subsequent encounter',
  'X034XXS Hit by object due to controlled fire, not in building or structure, sequela',
  'X038XXA Other exposure to controlled fire, not in building or structure, initial encounter',
  'X038XXD Other exposure to controlled fire, not in building or structure, subsequent encounter',
  'X038XXS Other exposure to controlled fire, not in building or structure, sequela',
  'X04XXXA Exposure to ignition of highly flammable material, initial encounter',
  'X04XXXD Exposure to ignition of highly flammable material, subsequent encounter',
  'X04XXXS Exposure to ignition of highly flammable material, sequela',
  'X05XXXA Exposure to ignition or melting of nightwear, initial encounter',
  'X05XXXD Exposure to ignition or melting of nightwear, subsequent encounter',
  'X05XXXS Exposure to ignition or melting of nightwear, sequela',
  'X060XXA Exposure to ignition of plastic jewelry, initial encounter',
  'X060XXD Exposure to ignition of plastic jewelry, subsequent encounter',
  'X060XXS Exposure to ignition of plastic jewelry, sequela',
  'X061XXA Exposure to melting of plastic jewelry, initial encounter',
  'X061XXD Exposure to melting of plastic jewelry, subsequent encounter',
  'X061XXS Exposure to melting of plastic jewelry, sequela',
  'X062XXA Exposure to ignition of other clothing and apparel, initial encounter',
  'X062XXD Exposure to ignition of other clothing and apparel, subsequent encounter',
  'X062XXS Exposure to ignition of other clothing and apparel, sequela',
  'X063XXA Exposure to melting of other clothing and apparel, initial encounter',
  'X063XXD Exposure to melting of other clothing and apparel, subsequent encounter',
  'X063XXS Exposure to melting of other clothing and apparel, sequela',
  'X0800XA Exposure to bed fire due to unspecified burning material, initial encounter',
  'X0800XD Exposure to bed fire due to unspecified burning material, subsequent encounter',
  'X0800XS Exposure to bed fire due to unspecified burning material, sequela',
  'X0801XA Exposure to bed fire due to burning cigarette, initial encounter',
  'X0801XD Exposure to bed fire due to burning cigarette, subsequent encounter',
  'X0801XS Exposure to bed fire due to burning cigarette, sequela',
  'X0809XA Exposure to bed fire due to other burning material, initial encounter',
  'X0809XD Exposure to bed fire due to other burning material, subsequent encounter',
  'X0809XS Exposure to bed fire due to other burning material, sequela',
  'X0810XA Exposure to sofa fire due to unspecified burning material, initial encounter',
  'X0810XD Exposure to sofa fire due to unspecified burning material, subsequent encounter',
  'X0810XS Exposure to sofa fire due to unspecified burning material, sequela',
  'X0811XA Exposure to sofa fire due to burning cigarette, initial encounter',
  'X0811XD Exposure to sofa fire due to burning cigarette, subsequent encounter',
  'X0811XS Exposure to sofa fire due to burning cigarette, sequela',
  'X0819XA Exposure to sofa fire due to other burning material, initial encounter',
  'X0819XD Exposure to sofa fire due to other burning material, subsequent encounter',
  'X0819XS Exposure to sofa fire due to other burning material, sequela',
  'X0820XA Exposure to other furniture fire due to unspecified burning material, initial encounter',
  'X0820XD Exposure to other furniture fire due to unspecified burning material, subsequent encounter',
  'X0820XS Exposure to other furniture fire due to unspecified burning material, sequela',
  'X0821XA Exposure to other furniture fire due to burning cigarette, initial encounter',
  'X0821XD Exposure to other furniture fire due to burning cigarette, subsequent encounter',
  'X0821XS Exposure to other furniture fire due to burning cigarette, sequela',
  'X0829XA Exposure to other furniture fire due to other burning material, initial encounter',
  'X0829XD Exposure to other furniture fire due to other burning material, subsequent encounter',
  'X0829XS Exposure to other furniture fire due to other burning material, sequela',
  'X088XXA Exposure to other specified smoke, fire and flames, initial encounter',
  'X088XXD Exposure to other specified smoke, fire and flames, subsequent encounter',
  'X088XXS Exposure to other specified smoke, fire and flames, sequela',
  'X100XXA Contact with hot drinks, initial encounter',
  'X100XXD Contact with hot drinks, subsequent encounter',
  'X100XXS Contact with hot drinks, sequela',
  'X101XXA Contact with hot food, initial encounter',
  'X101XXD Contact with hot food, subsequent encounter',
  'X101XXS Contact with hot food, sequela',
  'X102XXA Contact with fats and cooking oils, initial encounter',
  'X102XXD Contact with fats and cooking oils, subsequent encounter',
  'X102XXS Contact with fats and cooking oils, sequela',
  'X110XXA Contact with hot water in bath or tub, initial encounter',
  'X110XXD Contact with hot water in bath or tub, subsequent encounter',
  'X110XXS Contact with hot water in bath or tub, sequela',
  'X111XXA Contact with running hot water, initial encounter',
  'X111XXD Contact with running hot water, subsequent encounter',
  'X111XXS Contact with running hot water, sequela',
  'X118XXA Contact with other hot tap-water, initial encounter',
  'X118XXD Contact with other hot tap-water, subsequent encounter',
  'X118XXS Contact with other hot tap-water, sequela',
  'X12XXXA Contact with other hot fluids, initial encounter',
  'X12XXXD Contact with other hot fluids, subsequent encounter',
  'X12XXXS Contact with other hot fluids, sequela',
  'X130XXA Inhalation of steam and other hot vapors, initial encounter',
  'X130XXD Inhalation of steam and other hot vapors, subsequent encounter',
  'X130XXS Inhalation of steam and other hot vapors, sequela',
  'X131XXA Other contact with steam and other hot vapors, initial encounter',
  'X131XXD Other contact with steam and other hot vapors, subsequent encounter',
  'X131XXS Other contact with steam and other hot vapors, sequela',
  'X140XXA Inhalation of hot air and gases, initial encounter',
  'X140XXD Inhalation of hot air and gases, subsequent encounter',
  'X140XXS Inhalation of hot air and gases, sequela',
  'X141XXA Other contact with hot air and other hot gases, initial encounter',
  'X141XXD Other contact with hot air and other hot gases, subsequent encounter',
  'X141XXS Other contact with hot air and other hot gases, sequela',
  'X150XXA Contact with hot stove (kitchen), initial encounter',
  'X150XXD Contact with hot stove (kitchen), subsequent encounter',
  'X150XXS Contact with hot stove (kitchen), sequela',
  'X151XXA Contact with hot toaster, initial encounter',
  'X151XXD Contact with hot toaster, subsequent encounter',
  'X151XXS Contact with hot toaster, sequela',
  'X152XXA Contact with hotplate, initial encounter',
  'X152XXD Contact with hotplate, subsequent encounter',
  'X152XXS Contact with hotplate, sequela',
  'X153XXA Contact with hot saucepan or skillet, initial encounter',
  'X153XXD Contact with hot saucepan or skillet, subsequent encounter',
  'X153XXS Contact with hot saucepan or skillet, sequela',
  'X158XXA Contact with other hot household appliances, initial encounter',
  'X158XXD Contact with other hot household appliances, subsequent encounter',
  'X158XXS Contact with other hot household appliances, sequela',
  'X16XXXA Contact with hot heating appliances, radiators and pipes, initial encounter',
  'X16XXXD Contact with hot heating appliances, radiators and pipes, subsequent encounter',
  'X16XXXS Contact with hot heating appliances, radiators and pipes, sequela',
  'X17XXXA Contact with hot engines, machinery and tools, initial encounter',
  'X17XXXD Contact with hot engines, machinery and tools, subsequent encounter',
  'X17XXXS Contact with hot engines, machinery and tools, sequela',
  'X18XXXA Contact with other hot metals, initial encounter',
  'X18XXXD Contact with other hot metals, subsequent encounter',
  'X18XXXS Contact with other hot metals, sequela',
  'X19XXXA Contact with other heat and hot substances, initial encounter',
  'X19XXXD Contact with other heat and hot substances, subsequent encounter',
  'X19XXXS Contact with other heat and hot substances, sequela',
  'X30XXXA Exposure to excessive natural heat, initial encounter',
  'X30XXXD Exposure to excessive natural heat, subsequent encounter',
  'X30XXXS Exposure to excessive natural heat, sequela',
  'X31XXXA Exposure to excessive natural cold, initial encounter',
  'X31XXXD Exposure to excessive natural cold, subsequent encounter',
  'X31XXXS Exposure to excessive natural cold, sequela',
  'X32XXXA Exposure to sunlight, initial encounter',
  'X32XXXD Exposure to sunlight, subsequent encounter',
  'X32XXXS Exposure to sunlight, sequela',
  'X34XXXA Earthquake, initial encounter',
  'X34XXXD Earthquake, subsequent encounter',
  'X34XXXS Earthquake, sequela',
  'X35XXXA Volcanic eruption, initial encounter',
  'X35XXXD Volcanic eruption, subsequent encounter',
  'X35XXXS Volcanic eruption, sequela',
  'X360XXA Collapse of dam or man-made structure causing earth movement, initial encounter',
  'X360XXD Collapse of dam or man-made structure causing earth movement, subsequent encounter',
  'X360XXS Collapse of dam or man-made structure causing earth movement, sequela',
  'X361XXA Avalanche, landslide, or mudslide, initial encounter',
  'X361XXD Avalanche, landslide, or mudslide, subsequent encounter',
  'X361XXS Avalanche, landslide, or mudslide, sequela',
  'X370XXA Hurricane, initial encounter',
  'X370XXD Hurricane, subsequent encounter',
  'X370XXS Hurricane, sequela',
  'X371XXA Tornado, initial encounter',
  'X371XXD Tornado, subsequent encounter',
  'X371XXS Tornado, sequela',
  'X372XXA Blizzard (snow)(ice), initial encounter',
  'X372XXD Blizzard (snow)(ice), subsequent encounter',
  'X372XXS Blizzard (snow)(ice), sequela',
  'X373XXA Dust storm, initial encounter',
  'X373XXD Dust storm, subsequent encounter',
  'X373XXS Dust storm, sequela',
  'X3741XA Tidal wave due to earthquake or volcanic eruption, initial encounter',
  'X3741XD Tidal wave due to earthquake or volcanic eruption, subsequent encounter',
  'X3741XS Tidal wave due to earthquake or volcanic eruption, sequela',
  'X3742XA Tidal wave due to storm, initial encounter',
  'X3742XD Tidal wave due to storm, subsequent encounter',
  'X3742XS Tidal wave due to storm, sequela',
  'X3743XA Tidal wave due to landslide, initial encounter',
  'X3743XD Tidal wave due to landslide, subsequent encounter',
  'X3743XS Tidal wave due to landslide, sequela',
  'X378XXA Other cataclysmic storms, initial encounter',
  'X378XXD Other cataclysmic storms, subsequent encounter',
  'X378XXS Other cataclysmic storms, sequela',
  'X379XXA Unspecified cataclysmic storm, initial encounter',
  'X379XXD Unspecified cataclysmic storm, subsequent encounter',
  'X379XXS Unspecified cataclysmic storm, sequela',
  'X38XXXA Flood, initial encounter',
  'X38XXXD Flood, subsequent encounter',
  'X38XXXS Flood, sequela',
  'X3901XA Exposure to radon, initial encounter',
  'X3901XD Exposure to radon, subsequent encounter',
  'X3901XS Exposure to radon, sequela',
  'X3908XA Exposure to other natural radiation, initial encounter',
  'X3908XD Exposure to other natural radiation, subsequent encounter',
  'X3908XS Exposure to other natural radiation, sequela',
  'X398XXA Other exposure to forces of nature, initial encounter',
  'X398XXD Other exposure to forces of nature, subsequent encounter',
  'X398XXS Other exposure to forces of nature, sequela',
  'X500XXA Overexertion from strenuous movement or load, initial encounter',
  'X500XXD Overexertion from strenuous movement or load, subsequent encounter',
  'X500XXS Overexertion from strenuous movement or load, sequela',
  'X501XXA Overexertion from prolonged static or awkward postures, initial encounter',
  'X501XXD Overexertion from prolonged static or awkward postures, subsequent encounter',
  'X501XXS Overexertion from prolonged static or awkward postures, sequela',
  'X503XXA Overexertion from repetitive movements, initial encounter',
  'X503XXD Overexertion from repetitive movements, subsequent encounter',
  'X503XXS Overexertion from repetitive movements, sequela',
  'X509XXA Other and unspecified overexertion or strenuous movements or postures, initial encounter',
  'X509XXD Other and unspecified overexertion or strenuous movements or postures, subsequent',
  'encounter',
  'X509XXS Other and unspecified overexertion or strenuous movements or postures, sequela',
  'X52XXXA Prolonged stay in weightless environment, initial encounter',
  'X52XXXD Prolonged stay in weightless environment, subsequent encounter',
  'X52XXXS Prolonged stay in weightless environment, sequela',
  'X58XXXA Exposure to other specified factors, initial encounter',
  'X58XXXD Exposure to other specified factors, subsequent encounter',
  'X58XXXS Exposure to other specified factors, sequela',
  'X710XXA Intentional self-harm by drowning and submersion while in bathtub, initial encounter',
  'X710XXD Intentional self-harm by drowning and submersion while in bathtub, subsequent encounter',
  'X710XXS Intentional self-harm by drowning and submersion while in bathtub, sequela',
  'X711XXA Intentional self-harm by drowning and submersion while in swimming pool, initial encounter',
  'X711XXD Intentional self-harm by drowning and submersion while in swimming pool, subsequent',
  'encounter',
  'X711XXS Intentional self-harm by drowning and submersion while in swimming pool, sequela',
  'X712XXA Intentional self-harm by drowning and submersion after jump into swimming pool, initial',
  'encounter',
  'X712XXD Intentional self-harm by drowning and submersion after jump into swimming pool, subsequent',
  'encounter',
  'X712XXS Intentional self-harm by drowning and submersion after jump into swimming pool, sequela',
  'X713XXA Intentional self-harm by drowning and submersion in natural water, initial encounter',
  'X713XXD Intentional self-harm by drowning and submersion in natural water, subsequent encounter',
  'X713XXS Intentional self-harm by drowning and submersion in natural water, sequela',
  'X718XXA Other intentional self-harm by drowning and submersion, initial encounter',
  'X718XXD Other intentional self-harm by drowning and submersion, subsequent encounter',
  'X718XXS Other intentional self-harm by drowning and submersion, sequela',
  'X719XXA Intentional self-harm by drowning and submersion, unspecified, initial encounter',
  'X719XXD Intentional self-harm by drowning and submersion, unspecified, subsequent encounter',
  'X719XXS Intentional self-harm by drowning and submersion, unspecified, sequela',
  'X72XXXA Intentional self-harm by handgun discharge, initial encounter',
  'X72XXXD Intentional self-harm by handgun discharge, subsequent encounter',
  'X72XXXS Intentional self-harm by handgun discharge, sequela',
  'X730XXA Intentional self-harm by shotgun discharge, initial encounter',
  'X730XXD Intentional self-harm by shotgun discharge, subsequent encounter',
  'X730XXS Intentional self-harm by shotgun discharge, sequela',
  'X731XXA Intentional self-harm by hunting rifle discharge, initial encounter',
  'X731XXD Intentional self-harm by hunting rifle discharge, subsequent encounter',
  'X731XXS Intentional self-harm by hunting rifle discharge, sequela',
  'X732XXA Intentional self-harm by machine gun discharge, initial encounter',
  'X732XXD Intentional self-harm by machine gun discharge, subsequent encounter',
  'X732XXS Intentional self-harm by machine gun discharge, sequela',
  'X738XXA Intentional self-harm by other larger firearm discharge, initial encounter',
  'X738XXD Intentional self-harm by other larger firearm discharge, subsequent encounter',
  'X738XXS Intentional self-harm by other larger firearm discharge, sequela',
  'X739XXA Intentional self-harm by unspecified larger firearm discharge, initial encounter',
  'X739XXD Intentional self-harm by unspecified larger firearm discharge, subsequent encounter',
  'X739XXS Intentional self-harm by unspecified larger firearm discharge, sequela',
  'X7401XA Intentional self-harm by airgun, initial encounter',
  'X7401XD Intentional self-harm by airgun, subsequent encounter',
  'X7401XS Intentional self-harm by airgun, sequela',
  'X7402XA Intentional self-harm by paintball gun, initial encounter',
  'X7402XD Intentional self-harm by paintball gun, subsequent encounter',
  'X7402XS Intentional self-harm by paintball gun, sequela',
  'X7409XA Intentional self-harm by other gas, air or spring-operated gun, initial encounter',
  'X7409XD Intentional self-harm by other gas, air or spring-operated gun, subsequent encounter',
  'X7409XS Intentional self-harm by other gas, air or spring-operated gun, sequela',
  'X748XXA Intentional self-harm by other firearm discharge, initial encounter',
  'X748XXD Intentional self-harm by other firearm discharge, subsequent encounter',
  'X748XXS Intentional self-harm by other firearm discharge, sequela',
  'X749XXA Intentional self-harm by unspecified firearm discharge, initial encounter',
  'X749XXD Intentional self-harm by unspecified firearm discharge, subsequent encounter',
  'X749XXS Intentional self-harm by unspecified firearm discharge, sequela',
  'X75XXXA Intentional self-harm by explosive material, initial encounter',
  'X75XXXD Intentional self-harm by explosive material, subsequent encounter',
  'X75XXXS Intentional self-harm by explosive material, sequela',
  'X76XXXA Intentional self-harm by smoke, fire and flames, initial encounter',
  'X76XXXD Intentional self-harm by smoke, fire and flames, subsequent encounter',
  'X76XXXS Intentional self-harm by smoke, fire and flames, sequela',
  'X770XXA Intentional self-harm by steam or hot vapors, initial encounter',
  'X770XXD Intentional self-harm by steam or hot vapors, subsequent encounter',
  'X770XXS Intentional self-harm by steam or hot vapors, sequela',
  'X771XXA Intentional self-harm by hot tap water, initial encounter',
  'X771XXD Intentional self-harm by hot tap water, subsequent encounter',
  'X771XXS Intentional self-harm by hot tap water, sequela',
  'X772XXA Intentional self-harm by other hot fluids, initial encounter',
  'X772XXD Intentional self-harm by other hot fluids, subsequent encounter',
  'X772XXS Intentional self-harm by other hot fluids, sequela',
  'X773XXA Intentional self-harm by hot household appliances, initial encounter',
  'X773XXD Intentional self-harm by hot household appliances, subsequent encounter',
  'X773XXS Intentional self-harm by hot household appliances, sequela',
  'X778XXA Intentional self-harm by other hot objects, initial encounter',
  'X778XXD Intentional self-harm by other hot objects, subsequent encounter',
  'X778XXS Intentional self-harm by other hot objects, sequela',
  'X779XXA Intentional self-harm by unspecified hot objects, initial encounter',
  'X779XXD Intentional self-harm by unspecified hot objects, subsequent encounter',
  'X779XXS Intentional self-harm by unspecified hot objects, sequela',
  'X780XXA Intentional self-harm by sharp glass, initial encounter',
  'X780XXD Intentional self-harm by sharp glass, subsequent encounter',
  'X780XXS Intentional self-harm by sharp glass, sequela',
  'X781XXA Intentional self-harm by knife, initial encounter',
  'X781XXD Intentional self-harm by knife, subsequent encounter',
  'X781XXS Intentional self-harm by knife, sequela',
  'X782XXA Intentional self-harm by sword or dagger, initial encounter',
  'X782XXD Intentional self-harm by sword or dagger, subsequent encounter',
  'X782XXS Intentional self-harm by sword or dagger, sequela',
  'X788XXA Intentional self-harm by other sharp object, initial encounter',
  'X788XXD Intentional self-harm by other sharp object, subsequent encounter',
  'X788XXS Intentional self-harm by other sharp object, sequela',
  'X789XXA Intentional self-harm by unspecified sharp object, initial encounter',
  'X789XXD Intentional self-harm by unspecified sharp object, subsequent encounter',
  'X789XXS Intentional self-harm by unspecified sharp object, sequela',
  'X79XXXA Intentional self-harm by blunt object, initial encounter',
  'X79XXXD Intentional self-harm by blunt object, subsequent encounter',
  'X79XXXS Intentional self-harm by blunt object, sequela',
  'X80XXXA Intentional self-harm by jumping from a high place, initial encounter',
  'X80XXXD Intentional self-harm by jumping from a high place, subsequent encounter',
  'X80XXXS Intentional self-harm by jumping from a high place, sequela',
  'X810XXA Intentional self-harm by jumping or lying in front of motor vehicle, initial encounter',
  'X810XXD Intentional self-harm by jumping or lying in front of motor vehicle, subsequent encounter',
  'X810XXS Intentional self-harm by jumping or lying in front of motor vehicle, sequela',
  'X811XXA Intentional self-harm by jumping or lying in front of (subway) train, initial encounter',
  'X811XXD Intentional self-harm by jumping or lying in front of (subway) train, subsequent encounter',
  'X811XXS Intentional self-harm by jumping or lying in front of (subway) train, sequela',
  'X818XXA Intentional self-harm by jumping or lying in front of other moving object, initial encounter',
  'X818XXD Intentional self-harm by jumping or lying in front of other moving object, subsequent',
  'encounter',
  'X818XXS Intentional self-harm by jumping or lying in front of other moving object, sequela',
  'X820XXA Intentional collision of motor vehicle with other motor vehicle, initial encounter',
  'X820XXD Intentional collision of motor vehicle with other motor vehicle, subsequent encounter',
  'X820XXS Intentional collision of motor vehicle with other motor vehicle, sequela',
  'X821XXA Intentional collision of motor vehicle with train, initial encounter',
  'X821XXD Intentional collision of motor vehicle with train, subsequent encounter',
  'X821XXS Intentional collision of motor vehicle with train, sequela',
  'X822XXA Intentional collision of motor vehicle with tree, initial encounter',
  'X822XXD Intentional collision of motor vehicle with tree, subsequent encounter',
  'X822XXS Intentional collision of motor vehicle with tree, sequela',
  'X828XXA Other intentional self-harm by crashing of motor vehicle, initial encounter',
  'X828XXD Other intentional self-harm by crashing of motor vehicle, subsequent encounter',
  'X828XXS Other intentional self-harm by crashing of motor vehicle, sequela',
  'X830XXA Intentional self-harm by crashing of aircraft, initial encounter',
  'X830XXD Intentional self-harm by crashing of aircraft, subsequent encounter',
  'X830XXS Intentional self-harm by crashing of aircraft, sequela',
  'X831XXA Intentional self-harm by electrocution, initial encounter',
  'X831XXD Intentional self-harm by electrocution, subsequent encounter',
  'X831XXS Intentional self-harm by electrocution, sequela',
  'X832XXA Intentional self-harm by exposure to extremes of cold, initial encounter',
  'X832XXD Intentional self-harm by exposure to extremes of cold, subsequent encounter',
  'X832XXS Intentional self-harm by exposure to extremes of cold, sequela',
  'X838XXA Intentional self-harm by other specified means, initial encounter',
  'X838XXD Intentional self-harm by other specified means, subsequent encounter',
  'X838XXS Intentional self-harm by other specified means, sequela',
  'X920XXA Assault by drowning and submersion while in bathtub, initial encounter',
  'X920XXD Assault by drowning and submersion while in bathtub, subsequent encounter',
  'X920XXS Assault by drowning and submersion while in bathtub, sequela',
  'X921XXA Assault by drowning and submersion while in swimming pool, initial encounter',
  'X921XXD Assault by drowning and submersion while in swimming pool, subsequent encounter',
  'X921XXS Assault by drowning and submersion while in swimming pool, sequela',
  'X922XXA Assault by drowning and submersion after push into swimming pool, initial encounter',
  'X922XXD Assault by drowning and submersion after push into swimming pool, subsequent encounter',
  'X922XXS Assault by drowning and submersion after push into swimming pool, sequela',
  'X923XXA Assault by drowning and submersion in natural water, initial encounter',
  'X923XXD Assault by drowning and submersion in natural water, subsequent encounter',
  'X923XXS Assault by drowning and submersion in natural water, sequela',
  'X928XXA Other assault by drowning and submersion, initial encounter',
  'X928XXD Other assault by drowning and submersion, subsequent encounter',
  'X928XXS Other assault by drowning and submersion, sequela',
  'X929XXA Assault by drowning and submersion, unspecified, initial encounter',
  'X929XXD Assault by drowning and submersion, unspecified, subsequent encounter',
  'X929XXS Assault by drowning and submersion, unspecified, sequela',
  'X93XXXA Assault by handgun discharge, initial encounter',
  'X93XXXD Assault by handgun discharge, subsequent encounter',
  'X93XXXS Assault by handgun discharge, sequela',
  'X940XXA Assault by shotgun, initial encounter',
  'X940XXD Assault by shotgun, subsequent encounter',
  'X940XXS Assault by shotgun, sequela',
  'X941XXA Assault by hunting rifle, initial encounter',
  'X941XXD Assault by hunting rifle, subsequent encounter',
  'X941XXS Assault by hunting rifle, sequela',
  'X942XXA Assault by machine gun, initial encounter',
  'X942XXD Assault by machine gun, subsequent encounter',
  'X942XXS Assault by machine gun, sequela',
  'X948XXA Assault by other larger firearm discharge, initial encounter',
  'X948XXD Assault by other larger firearm discharge, subsequent encounter',
  'X948XXS Assault by other larger firearm discharge, sequela',
  'X949XXA Assault by unspecified larger firearm discharge, initial encounter',
  'X949XXD Assault by unspecified larger firearm discharge, subsequent encounter',
  'X949XXS Assault by unspecified larger firearm discharge, sequela',
  'X9501XA Assault by airgun discharge, initial encounter',
  'X9501XD Assault by airgun discharge, subsequent encounter',
  'X9501XS Assault by airgun discharge, sequela',
  'X9502XA Assault by paintball gun discharge, initial encounter',
  'X9502XD Assault by paintball gun discharge, subsequent encounter',
  'X9502XS Assault by paintball gun discharge, sequela',
  'X9509XA Assault by other gas, air or spring-operated gun, initial encounter',
  'X9509XD Assault by other gas, air or spring-operated gun, subsequent encounter',
  'X9509XS Assault by other gas, air or spring-operated gun, sequela',
  'X958XXA Assault by other firearm discharge, initial encounter',
  'X958XXD Assault by other firearm discharge, subsequent encounter',
  'X958XXS Assault by other firearm discharge, sequela',
  'X959XXA Assault by unspecified firearm discharge, initial encounter',
  'X959XXD Assault by unspecified firearm discharge, subsequent encounter',
  'X959XXS Assault by unspecified firearm discharge, sequela',
  'X960XXA Assault by antipersonnel bomb, initial encounter',
  'X960XXD Assault by antipersonnel bomb, subsequent encounter',
  'X960XXS Assault by antipersonnel bomb, sequela',
  'X961XXA Assault by gasoline bomb, initial encounter',
  'X961XXD Assault by gasoline bomb, subsequent encounter',
  'X961XXS Assault by gasoline bomb, sequela',
  'X962XXA Assault by letter bomb, initial encounter',
  'X962XXD Assault by letter bomb, subsequent encounter',
  'X962XXS Assault by letter bomb, sequela',
  'X963XXA Assault by fertilizer bomb, initial encounter',
  'X963XXD Assault by fertilizer bomb, subsequent encounter',
  'X963XXS Assault by fertilizer bomb, sequela',
  'X964XXA Assault by pipe bomb, initial encounter',
  'X964XXD Assault by pipe bomb, subsequent encounter',
  'X964XXS Assault by pipe bomb, sequela',
  'X968XXA Assault by other specified explosive, initial encounter',
  'X968XXD Assault by other specified explosive, subsequent encounter',
  'X968XXS Assault by other specified explosive, sequela',
  'X969XXA Assault by unspecified explosive, initial encounter',
  'X969XXD Assault by unspecified explosive, subsequent encounter',
  'X969XXS Assault by unspecified explosive, sequela',
  'X97XXXA Assault by smoke, fire and flames, initial encounter',
  'X97XXXD Assault by smoke, fire and flames, subsequent encounter',
  'X97XXXS Assault by smoke, fire and flames, sequela',
  'X980XXA Assault by steam or hot vapors, initial encounter',
  'X980XXD Assault by steam or hot vapors, subsequent encounter',
  'X980XXS Assault by steam or hot vapors, sequela',
  'X981XXA Assault by hot tap water, initial encounter',
  'X981XXD Assault by hot tap water, subsequent encounter',
  'X981XXS Assault by hot tap water, sequela',
  'X982XXA Assault by hot fluids, initial encounter',
  'X982XXD Assault by hot fluids, subsequent encounter',
  'X982XXS Assault by hot fluids, sequela',
  'X983XXA Assault by hot household appliances, initial encounter',
  'X983XXD Assault by hot household appliances, subsequent encounter',
  'X983XXS Assault by hot household appliances, sequela',
  'X988XXA Assault by other hot objects, initial encounter',
  'X988XXD Assault by other hot objects, subsequent encounter',
  'X988XXS Assault by other hot objects, sequela',
  'X989XXA Assault by unspecified hot objects, initial encounter',
  'X989XXD Assault by unspecified hot objects, subsequent encounter',
  'X989XXS Assault by unspecified hot objects, sequela',
  'X990XXA Assault by sharp glass, initial encounter',
  'X990XXD Assault by sharp glass, subsequent encounter',
  'X990XXS Assault by sharp glass, sequela',
  'X991XXA Assault by knife, initial encounter',
  'X991XXD Assault by knife, subsequent encounter',
  'X991XXS Assault by knife, sequela',
  'X992XXA Assault by sword or dagger, initial encounter',
  'X992XXD Assault by sword or dagger, subsequent encounter',
  'X992XXS Assault by sword or dagger, sequela',
  'X998XXA Assault by other sharp object, initial encounter',
  'X998XXD Assault by other sharp object, subsequent encounter',
  'X998XXS Assault by other sharp object, sequela',
  'X999XXA Assault by unspecified sharp object, initial encounter',
  'X999XXD Assault by unspecified sharp object, subsequent encounter',
  'X999XXS Assault by unspecified sharp object, sequela',
  'Y00XXXA Assault by blunt object, initial encounter',
  'Y00XXXD Assault by blunt object, subsequent encounter',
  'Y00XXXS Assault by blunt object, sequela',
  'Y01XXXA Assault by pushing from high place, initial encounter',
  'Y01XXXD Assault by pushing from high place, subsequent encounter',
  'Y01XXXS Assault by pushing from high place, sequela',
  'Y020XXA Assault by pushing or placing victim in front of motor vehicle, initial encounter',
  'Y020XXD Assault by pushing or placing victim in front of motor vehicle, subsequent encounter',
  'Y020XXS Assault by pushing or placing victim in front of motor vehicle, sequela',
  'Y021XXA Assault by pushing or placing victim in front of (subway) train, initial encounter',
  'Y021XXD Assault by pushing or placing victim in front of (subway) train, subsequent encounter',
  'Y021XXS Assault by pushing or placing victim in front of (subway) train, sequela',
  'Y028XXA Assault by pushing or placing victim in front of other moving object, initial encounter',
  'Y028XXD Assault by pushing or placing victim in front of other moving object, subsequent encounter',
  'Y028XXS Assault by pushing or placing victim in front of other moving object, sequela',
  'Y030XXA Assault by being hit or run over by motor vehicle, initial encounter',
  'Y030XXD Assault by being hit or run over by motor vehicle, subsequent encounter',
  'Y030XXS Assault by being hit or run over by motor vehicle, sequela',
  'Y038XXA Other assault by crashing of motor vehicle, initial encounter',
  'Y038XXD Other assault by crashing of motor vehicle, subsequent encounter',
  'Y038XXS Other assault by crashing of motor vehicle, sequela',
  'Y040XXA Assault by unarmed brawl or fight, initial encounter',
  'Y040XXD Assault by unarmed brawl or fight, subsequent encounter',
  'Y040XXS Assault by unarmed brawl or fight, sequela',
  'Y041XXA Assault by human bite, initial encounter',
  'Y041XXD Assault by human bite, subsequent encounter',
  'Y041XXS Assault by human bite, sequela',
  'Y042XXA Assault by strike against or bumped into by another person, initial encounter',
  'Y042XXD Assault by strike against or bumped into by another person, subsequent encounter',
  'Y042XXS Assault by strike against or bumped into by another person, sequela',
  'Y048XXA Assault by other bodily force, initial encounter',
  'Y048XXD Assault by other bodily force, subsequent encounter',
  'Y048XXS Assault by other bodily force, sequela',
  'Y0701 Husband, perpetrator of maltreatment and neglect',
  'Y0702 Wife, perpetrator of maltreatment and neglect',
  'Y0703 Male partner, perpetrator of maltreatment and neglect',
  'Y0704 Female partner, perpetrator of maltreatment and neglect',
  'Y0711 Biological father, perpetrator of maltreatment and neglect',
  'Y0712 Biological mother, perpetrator of maltreatment and neglect',
  'Y0713 Adoptive father, perpetrator of maltreatment and neglect',
  'Y0714 Adoptive mother, perpetrator of maltreatment and neglect',
  'Y07410 Brother, perpetrator of maltreatment and neglect',
  'Y07411 Sister, perpetrator of maltreatment and neglect',
  'Y07420 Foster father, perpetrator of maltreatment and neglect',
  'Y07421 Foster mother, perpetrator of maltreatment and neglect',
  'Y07430 Stepfather, perpetrator of maltreatment and neglect',
  'Y07432 Male friend of parent (co-residing in household), perpetrator of maltreatment and neglect',
  'Y07433 Stepmother, perpetrator of maltreatment and neglect',
  'Y07434 Female friend of parent (co-residing in household), perpetrator of maltreatment and neglect',
  'Y07435 Stepbrother, perpetrator or maltreatment and neglect',
  'Y07436 Stepsister, perpetrator of maltreatment and neglect',
  'Y07490 Male cousin, perpetrator of maltreatment and neglect',
  'Y07491 Female cousin, perpetrator of maltreatment and neglect',
  'Y07499 Other family member, perpetrator of maltreatment and neglect',
  'Y0750 Unspecified non-family member, perpetrator of maltreatment and neglect',
  'Y07510 At-home childcare provider, perpetrator of maltreatment and neglect',
  'Y07511 Daycare center childcare provider, perpetrator of maltreatment and neglect',
  'Y07512 At-home adultcare provider, perpetrator of maltreatment and neglect',
  'Y07513 Adultcare center provider, perpetrator of maltreatment and neglect',
  'Y07519 Unspecified daycare provider, perpetrator of maltreatment and neglect',
  'Y07521 Mental health provider, perpetrator of maltreatment and neglect',
  'Y07528 Other therapist or healthcare provider, perpetrator of maltreatment and neglect',
  'Y07529 Unspecified healthcare provider, perpetrator of maltreatment and neglect',
  'Y0753 Teacher or instructor, perpetrator of maltreatment and neglect',
  'Y0759 Other non-family member, perpetrator of maltreatment and neglect',
  'Y079 Unspecified perpetrator of maltreatment and neglect',
  'Y0801XA Assault by strike by hockey stick, initial encounter',
  'Y0801XD Assault by strike by hockey stick, subsequent encounter',
  'Y0801XS Assault by strike by hockey stick, sequela',
  'Y0802XA Assault by strike by baseball bat, initial encounter',
  'Y0802XD Assault by strike by baseball bat, subsequent encounter',
  'Y0802XS Assault by strike by baseball bat, sequela',
  'Y0809XA Assault by strike by other specified type of sport equipment, initial encounter',
  'Y0809XD Assault by strike by other specified type of sport equipment, subsequent encounter',
  'Y0809XS Assault by strike by other specified type of sport equipment, sequela',
  'Y0881XA Assault by crashing of aircraft, initial encounter',
  'Y0881XD Assault by crashing of aircraft, subsequent encounter',
  'Y0881XS Assault by crashing of aircraft, sequela',
  'Y0889XA Assault by other specified means, initial encounter',
  'Y0889XD Assault by other specified means, subsequent encounter',
  'Y0889XS Assault by other specified means, sequela',
  'Y09 Assault by unspecified means',
  'Y210XXA Drowning and submersion while in bathtub, undetermined intent, initial encounter',
  'Y210XXD Drowning and submersion while in bathtub, undetermined intent, subsequent encounter',
  'Y210XXS Drowning and submersion while in bathtub, undetermined intent, sequela',
  'Y211XXA Drowning and submersion after fall into bathtub, undetermined intent, initial encounter',
  'Y211XXD Drowning and submersion after fall into bathtub, undetermined intent, subsequent encounter',
  'Y211XXS Drowning and submersion after fall into bathtub, undetermined intent, sequela',
  'Y212XXA Drowning and submersion while in swimming pool, undetermined intent, initial encounter',
  'Y212XXD Drowning and submersion while in swimming pool, undetermined intent, subsequent',
  'encounter',
  'Y212XXS Drowning and submersion while in swimming pool, undetermined intent, sequela',
  'Y213XXA Drowning and submersion after fall into swimming pool, undetermined intent, initial',
  'encounter',
  'Y213XXD Drowning and submersion after fall into swimming pool, undetermined intent, subsequent',
  'encounter',
  'Y213XXS Drowning and submersion after fall into swimming pool, undetermined intent, sequela',
  'Y214XXA Drowning and submersion in natural water, undetermined intent, initial encounter',
  'Y214XXD Drowning and submersion in natural water, undetermined intent, subsequent encounter',
  'Y214XXS Drowning and submersion in natural water, undetermined intent, sequela',
  'Y218XXA Other drowning and submersion, undetermined intent, initial encounter',
  'Y218XXD Other drowning and submersion, undetermined intent, subsequent encounter',
  'Y218XXS Other drowning and submersion, undetermined intent, sequela',
  'Y219XXA Unspecified drowning and submersion, undetermined intent, initial encounter',
  'Y219XXD Unspecified drowning and submersion, undetermined intent, subsequent encounter',
  'Y219XXS Unspecified drowning and submersion, undetermined intent, sequela',
  'Y22XXXA Handgun discharge, undetermined intent, initial encounter',
  'Y22XXXD Handgun discharge, undetermined intent, subsequent encounter',
  'Y22XXXS Handgun discharge, undetermined intent, sequela',
  'Y230XXA Shotgun discharge, undetermined intent, initial encounter',
  'Y230XXD Shotgun discharge, undetermined intent, subsequent encounter',
  'Y230XXS Shotgun discharge, undetermined intent, sequela',
  'Y231XXA Hunting rifle discharge, undetermined intent, initial encounter',
  'Y231XXD Hunting rifle discharge, undetermined intent, subsequent encounter',
  'Y231XXS Hunting rifle discharge, undetermined intent, sequela',
  'Y232XXA Military firearm discharge, undetermined intent, initial encounter',
  'Y232XXD Military firearm discharge, undetermined intent, subsequent encounter',
  'Y232XXS Military firearm discharge, undetermined intent, sequela',
  'Y233XXA Machine gun discharge, undetermined intent, initial encounter',
  'Y233XXD Machine gun discharge, undetermined intent, subsequent encounter',
  'Y233XXS Machine gun discharge, undetermined intent, sequela',
  'Y238XXA Other larger firearm discharge, undetermined intent, initial encounter',
  'Y238XXD Other larger firearm discharge, undetermined intent, subsequent encounter',
  'Y238XXS Other larger firearm discharge, undetermined intent, sequela',
  'Y239XXA Unspecified larger firearm discharge, undetermined intent, initial encounter',
  'Y239XXD Unspecified larger firearm discharge, undetermined intent, subsequent encounter',
  'Y239XXS Unspecified larger firearm discharge, undetermined intent, sequela',
  'Y240XXA Airgun discharge, undetermined intent, initial encounter',
  'Y240XXD Airgun discharge, undetermined intent, subsequent encounter',
  'Y240XXS Airgun discharge, undetermined intent, sequela',
  'Y248XXA Other firearm discharge, undetermined intent, initial encounter',
  'Y248XXD Other firearm discharge, undetermined intent, subsequent encounter',
  'Y248XXS Other firearm discharge, undetermined intent, sequela',
  'Y249XXA Unspecified firearm discharge, undetermined intent, initial encounter',
  'Y249XXD Unspecified firearm discharge, undetermined intent, subsequent encounter',
  'Y249XXS Unspecified firearm discharge, undetermined intent, sequela',
  'Y25XXXA Contact with explosive material, undetermined intent, initial encounter',
  'Y25XXXD Contact with explosive material, undetermined intent, subsequent encounter',
  'Y25XXXS Contact with explosive material, undetermined intent, sequela',
  'Y26XXXA Exposure to smoke, fire and flames, undetermined intent, initial encounter',
  'Y26XXXD Exposure to smoke, fire and flames, undetermined intent, subsequent encounter',
  'Y26XXXS Exposure to smoke, fire and flames, undetermined intent, sequela',
  'Y270XXA Contact with steam and hot vapors, undetermined intent, initial encounter',
  'Y270XXD Contact with steam and hot vapors, undetermined intent, subsequent encounter',
  'Y270XXS Contact with steam and hot vapors, undetermined intent, sequela',
  'Y271XXA Contact with hot tap water, undetermined intent, initial encounter',
  'Y271XXD Contact with hot tap water, undetermined intent, subsequent encounter',
  'Y271XXS Contact with hot tap water, undetermined intent, sequela',
  'Y272XXA Contact with hot fluids, undetermined intent, initial encounter',
  'Y272XXD Contact with hot fluids, undetermined intent, subsequent encounter',
  'Y272XXS Contact with hot fluids, undetermined intent, sequela',
  'Y273XXA Contact with hot household appliance, undetermined intent, initial encounter',
  'Y273XXD Contact with hot household appliance, undetermined intent, subsequent encounter',
  'Y273XXS Contact with hot household appliance, undetermined intent, sequela',
  'Y278XXA Contact with other hot objects, undetermined intent, initial encounter',
  'Y278XXD Contact with other hot objects, undetermined intent, subsequent encounter',
  'Y278XXS Contact with other hot objects, undetermined intent, sequela',
  'Y279XXA Contact with unspecified hot objects, undetermined intent, initial encounter',
  'Y279XXD Contact with unspecified hot objects, undetermined intent, subsequent encounter',
  'Y279XXS Contact with unspecified hot objects, undetermined intent, sequela',
  'Y280XXA Contact with sharp glass, undetermined intent, initial encounter',
  'Y280XXD Contact with sharp glass, undetermined intent, subsequent encounter',
  'Y280XXS Contact with sharp glass, undetermined intent, sequela',
  'Y281XXA Contact with knife, undetermined intent, initial encounter',
  'Y281XXD Contact with knife, undetermined intent, subsequent encounter',
  'Y281XXS Contact with knife, undetermined intent, sequela',
  'Y282XXA Contact with sword or dagger, undetermined intent, initial encounter',
  'Y282XXD Contact with sword or dagger, undetermined intent, subsequent encounter',
  'Y282XXS Contact with sword or dagger, undetermined intent, sequela',
  'Y288XXA Contact with other sharp object, undetermined intent, initial encounter',
  'Y288XXD Contact with other sharp object, undetermined intent, subsequent encounter',
  'Y288XXS Contact with other sharp object, undetermined intent, sequela',
  'Y289XXA Contact with unspecified sharp object, undetermined intent, initial encounter',
  'Y289XXD Contact with unspecified sharp object, undetermined intent, subsequent encounter',
  'Y289XXS Contact with unspecified sharp object, undetermined intent, sequela',
  'Y29XXXA Contact with blunt object, undetermined intent, initial encounter',
  'Y29XXXD Contact with blunt object, undetermined intent, subsequent encounter',
  'Y29XXXS Contact with blunt object, undetermined intent, sequela',
  'Y30XXXA Falling, jumping or pushed from a high place, undetermined intent, initial encounter',
  'Y30XXXD Falling, jumping or pushed from a high place, undetermined intent, subsequent encounter',
  'Y30XXXS Falling, jumping or pushed from a high place, undetermined intent, sequela',
  'Y31XXXA Falling, lying or running before or into moving object, undetermined intent, initial encounter',
  'Y31XXXD Falling, lying or running before or into moving object, undetermined intent, subsequent',
  'encounter',
  'Y31XXXS Falling, lying or running before or into moving object, undetermined intent, sequela',
  'Y32XXXA Crashing of motor vehicle, undetermined intent, initial encounter',
  'Y32XXXD Crashing of motor vehicle, undetermined intent, subsequent encounter',
  'Y32XXXS Crashing of motor vehicle, undetermined intent, sequela',
  'Y33XXXA Other specified events, undetermined intent, initial encounter',
  'Y33XXXD Other specified events, undetermined intent, subsequent encounter',
  'Y33XXXS Other specified events, undetermined intent, sequela',
  'Y35001A Legal intervention involving unspecified firearm discharge, law enforcement official injured,',
  'initial encounter',
  'Y35001D Legal intervention involving unspecified firearm discharge, law enforcement official injured,',
  'subsequent encounter',
  'Y35001S Legal intervention involving unspecified firearm discharge, law enforcement official injured,',
  'sequela',
  'Y35002A Legal intervention involving unspecified firearm discharge, bystander injured, initial encounter',
  'Y35002D Legal intervention involving unspecified firearm discharge, bystander injured, subsequent',
  'encounter',
  'Y35002S Legal intervention involving unspecified firearm discharge, bystander injured, sequela',
  'Y35003A Legal intervention involving unspecified firearm discharge, suspect injured, initial encounter',
  'Y35003D Legal intervention involving unspecified firearm discharge, suspect injured, subsequent',
  'encounter',
  'Y35003S Legal intervention involving unspecified firearm discharge, suspect injured, sequela',
  'Y35011A Legal intervention involving injury by machine gun, law enforcement official injured, initial',
  'encounter',
  'Y35011D Legal intervention involving injury by machine gun, law enforcement official injured,',
  'subsequent encounter',
  'Y35011S Legal intervention involving injury by machine gun, law enforcement official injured, sequela',
  'Y35012A Legal intervention involving injury by machine gun, bystander injured, initial encounter',
  'Y35012D Legal intervention involving injury by machine gun, bystander injured, subsequent encounter',
  'Y35012S Legal intervention involving injury by machine gun, bystander injured, sequela',
  'Y35013A Legal intervention involving injury by machine gun, suspect injured, initial encounter',
  'Y35013D Legal intervention involving injury by machine gun, suspect injured, subsequent encounter',
  'Y35013S Legal intervention involving injury by machine gun, suspect injured, sequela',
  'Y35021A Legal intervention involving injury by handgun, law enforcement official injured, initial',
  'encounter',
  'Y35021D Legal intervention involving injury by handgun, law enforcement official injured, subsequent',
  'encounter',
  'Y35021S Legal intervention involving injury by handgun, law enforcement official injured, sequela',
  'Y35022A Legal intervention involving injury by handgun, bystander injured, initial encounter',
  'Y35022D Legal intervention involving injury by handgun, bystander injured, subsequent encounter',
  'Y35022S Legal intervention involving injury by handgun, bystander injured, sequela',
  'Y35023A Legal intervention involving injury by handgun, suspect injured, initial encounter',
  'Y35023D Legal intervention involving injury by handgun, suspect injured, subsequent encounter',
  'Y35023S Legal intervention involving injury by handgun, suspect injured, sequela',
  'Y35031A Legal intervention involving injury by rifle pellet, law enforcement official injured, initial',
  'encounter',
  'Y35031D Legal intervention involving injury by rifle pellet, law enforcement official injured, subsequent',
  'encounter',
  'Y35031S Legal intervention involving injury by rifle pellet, law enforcement official injured, sequela',
  'Y35032A Legal intervention involving injury by rifle pellet, bystander injured, initial encounter',
  'Y35032D Legal intervention involving injury by rifle pellet, bystander injured, subsequent encounter',
  'Y35032S Legal intervention involving injury by rifle pellet, bystander injured, sequela',
  'Y35033A Legal intervention involving injury by rifle pellet, suspect injured, initial encounter',
  'Y35033D Legal intervention involving injury by rifle pellet, suspect injured, subsequent encounter',
  'Y35033S Legal intervention involving injury by rifle pellet, suspect injured, sequela',
  'Y35041A Legal intervention involving injury by rubber bullet, law enforcement official injured, initial',
  'encounter',
  'Y35041D Legal intervention involving injury by rubber bullet, law enforcement official injured,',
  'subsequent encounter',
  'Y35041S Legal intervention involving injury by rubber bullet, law enforcement official injured, sequela',
  'Y35042A Legal intervention involving injury by rubber bullet, bystander injured, initial encounter',
  'Y35042D Legal intervention involving injury by rubber bullet, bystander injured, subsequent encounter',
  'Y35042S Legal intervention involving injury by rubber bullet, bystander injured, sequela',
  'Y35043A Legal intervention involving injury by rubber bullet, suspect injured, initial encounter',
  'Y35043D Legal intervention involving injury by rubber bullet, suspect injured, subsequent encounter',
  'Y35043S Legal intervention involving injury by rubber bullet, suspect injured, sequela',
  'Y35091A Legal intervention involving other firearm discharge, law enforcement official injured, initial',
  'encounter',
  'Y35091D Legal intervention involving other firearm discharge, law enforcement official injured,',
  'subsequent encounter',
  'Y35091S Legal intervention involving other firearm discharge, law enforcement official injured, sequela',
  'Y35092A Legal intervention involving other firearm discharge, bystander injured, initial encounter',
  'Y35092D Legal intervention involving other firearm discharge, bystander injured, subsequent encounter',
  'Y35092S Legal intervention involving other firearm discharge, bystander injured, sequela',
  'Y35093A Legal intervention involving other firearm discharge, suspect injured, initial encounter',
  'Y35093D Legal intervention involving other firearm discharge, suspect injured, subsequent encounter',
  'Y35093S Legal intervention involving other firearm discharge, suspect injured, sequela',
  'Y35101A Legal intervention involving unspecified explosives, law enforcement official injured, initial',
  'encounter',
  'Y35101D Legal intervention involving unspecified explosives, law enforcement official injured,',
  'subsequent encounter',
  'Y35101S Legal intervention involving unspecified explosives, law enforcement official injured, sequela',
  'Y35102A Legal intervention involving unspecified explosives, bystander injured, initial encounter',
  'Y35102D Legal intervention involving unspecified explosives, bystander injured, subsequent encounter',
  'Y35102S Legal intervention involving unspecified explosives, bystander injured, sequela',
  'Y35103A Legal intervention involving unspecified explosives, suspect injured, initial encounter',
  'Y35103D Legal intervention involving unspecified explosives, suspect injured, subsequent encounter',
  'Y35103S Legal intervention involving unspecified explosives, suspect injured, sequela',
  'Y35111A Legal intervention involving injury by dynamite, law enforcement official injured, initial',
  'encounter',
  'Y35111D Legal intervention involving injury by dynamite, law enforcement official injured, subsequent',
  'encounter',
  'Y35111S Legal intervention involving injury by dynamite, law enforcement official injured, sequela',
  'Y35112A Legal intervention involving injury by dynamite, bystander injured, initial encounter',
  'Y35112D Legal intervention involving injury by dynamite, bystander injured, subsequent encounter',
  'Y35112S Legal intervention involving injury by dynamite, bystander injured, sequela',
  'Y35113A Legal intervention involving injury by dynamite, suspect injured, initial encounter',
  'Y35113D Legal intervention involving injury by dynamite, suspect injured, subsequent encounter',
  'Y35113S Legal intervention involving injury by dynamite, suspect injured, sequela',
  'Y35121A Legal intervention involving injury by explosive shell, law enforcement official injured, initial',
  'encounter',
  'Y35121D Legal intervention involving injury by explosive shell, law enforcement official injured,',
  'subsequent encounter',
  'Y35121S Legal intervention involving injury by explosive shell, law enforcement official injured, sequela',
  'Y35122A Legal intervention involving injury by explosive shell, bystander injured, initial encounter',
  'Y35122D Legal intervention involving injury by explosive shell, bystander injured, subsequent encounter',
  'Y35122S Legal intervention involving injury by explosive shell, bystander injured, sequela',
  'Y35123A Legal intervention involving injury by explosive shell, suspect injured, initial encounter',
  'Y35123D Legal intervention involving injury by explosive shell, suspect injured, subsequent encounter',
  'Y35123S Legal intervention involving injury by explosive shell, suspect injured, sequela',
  'Y35191A Legal intervention involving other explosives, law enforcement official injured, initial encounter',
  'Y35191D Legal intervention involving other explosives, law enforcement official injured, subsequent',
  'encounter',
  'Y35191S Legal intervention involving other explosives, law enforcement official injured, sequela',
  'Y35192A Legal intervention involving other explosives, bystander injured, initial encounter',
  'Y35192D Legal intervention involving other explosives, bystander injured, subsequent encounter',
  'Y35192S Legal intervention involving other explosives, bystander injured, sequela',
  'Y35193A Legal intervention involving other explosives, suspect injured, initial encounter',
  'Y35193D Legal intervention involving other explosives, suspect injured, subsequent encounter',
  'Y35193S Legal intervention involving other explosives, suspect injured, sequela',
  'Y35201A Legal intervention involving unspecified gas, law enforcement official injured, initial encounter',
  'Y35201D Legal intervention involving unspecified gas, law enforcement official injured, subsequent',
  'encounter',
  'Y35201S Legal intervention involving unspecified gas, law enforcement official injured, sequela',
  'Y35202A Legal intervention involving unspecified gas, bystander injured, initial encounter',
  'Y35202D Legal intervention involving unspecified gas, bystander injured, subsequent encounter',
  'Y35202S Legal intervention involving unspecified gas, bystander injured, sequela',
  'Y35203A Legal intervention involving unspecified gas, suspect injured, initial encounter',
  'Y35203D Legal intervention involving unspecified gas, suspect injured, subsequent encounter',
  'Y35203S Legal intervention involving unspecified gas, suspect injured, sequela',
  'Y35211A Legal intervention involving injury by tear gas, law enforcement official injured, initial',
  'encounter',
  'Y35211D Legal intervention involving injury by tear gas, law enforcement official injured, subsequent',
  'encounter',
  'Y35211S Legal intervention involving injury by tear gas, law enforcement official injured, sequela',
  'Y35212A Legal intervention involving injury by tear gas, bystander injured, initial encounter',
  'Y35212D Legal intervention involving injury by tear gas, bystander injured, subsequent encounter',
  'Y35212S Legal intervention involving injury by tear gas, bystander injured, sequela',
  'Y35213A Legal intervention involving injury by tear gas, suspect injured, initial encounter',
  'Y35213D Legal intervention involving injury by tear gas, suspect injured, subsequent encounter',
  'Y35213S Legal intervention involving injury by tear gas, suspect injured, sequela',
  'Y35291A Legal intervention involving other gas, law enforcement official injured, initial encounter',
  'Y35291D Legal intervention involving other gas, law enforcement official injured, subsequent encounter',
  'Y35291S Legal intervention involving other gas, law enforcement official injured, sequela',
  'Y35292A Legal intervention involving other gas, bystander injured, initial encounter',
  'Y35292D Legal intervention involving other gas, bystander injured, subsequent encounter',
  'Y35292S Legal intervention involving other gas, bystander injured, sequela',
  'Y35293A Legal intervention involving other gas, suspect injured, initial encounter',
  'Y35293D Legal intervention involving other gas, suspect injured, subsequent encounter',
  'Y35293S Legal intervention involving other gas, suspect injured, sequela',
  'Y35301A Legal intervention involving unspecified blunt objects, law enforcement official injured, initial',
  'encounter',
  'Y35301D Legal intervention involving unspecified blunt objects, law enforcement official injured,',
  'subsequent encounter',
  'Y35301S Legal intervention involving unspecified blunt objects, law enforcement official injured, sequela',
  'Y35302A Legal intervention involving unspecified blunt objects, bystander injured, initial encounter',
  'Y35302D Legal intervention involving unspecified blunt objects, bystander injured, subsequent',
  'encounter',
  'Y35302S Legal intervention involving unspecified blunt objects, bystander injured, sequela',
  'Y35303A Legal intervention involving unspecified blunt objects, suspect injured, initial encounter',
  'Y35303D Legal intervention involving unspecified blunt objects, suspect injured, subsequent encounter',
  'Y35303S Legal intervention involving unspecified blunt objects, suspect injured, sequela',
  'Y35311A Legal intervention involving baton, law enforcement official injured, initial encounter',
  'Y35311D Legal intervention involving baton, law enforcement official injured, subsequent encounter',
  'Y35311S Legal intervention involving baton, law enforcement official injured, sequela',
  'Y35312A Legal intervention involving baton, bystander injured, initial encounter',
  'Y35312D Legal intervention involving baton, bystander injured, subsequent encounter',
  'Y35312S Legal intervention involving baton, bystander injured, sequela',
  'Y35313A Legal intervention involving baton, suspect injured, initial encounter',
  'Y35313D Legal intervention involving baton, suspect injured, subsequent encounter',
  'Y35313S Legal intervention involving baton, suspect injured, sequela',
  'Y35391A Legal intervention involving other blunt objects, law enforcement official injured, initial',
  'encounter',
  'Y35391D Legal intervention involving other blunt objects, law enforcement official injured, subsequent',
  'encounter',
  'Y35391S Legal intervention involving other blunt objects, law enforcement official injured, sequela',
  'Y35392A Legal intervention involving other blunt objects, bystander injured, initial encounter',
  'Y35392D Legal intervention involving other blunt objects, bystander injured, subsequent encounter',
  'Y35392S Legal intervention involving other blunt objects, bystander injured, sequela',
  'Y35393A Legal intervention involving other blunt objects, suspect injured, initial encounter',
  'Y35393D Legal intervention involving other blunt objects, suspect injured, subsequent encounter',
  'Y35393S Legal intervention involving other blunt objects, suspect injured, sequela',
  'Y35401A Legal intervention involving unspecified sharp objects, law enforcement official injured, initial',
  'encounter',
  'Y35401D Legal intervention involving unspecified sharp objects, law enforcement official injured,',
  'subsequent encounter',
  'Y35401S Legal intervention involving unspecified sharp objects, law enforcement official injured,',
  'sequela',
  'Y35402A Legal intervention involving unspecified sharp objects, bystander injured, initial encounter',
  'Y35402D Legal intervention involving unspecified sharp objects, bystander injured, subsequent',
  'encounter',
  'Y35402S Legal intervention involving unspecified sharp objects, bystander injured, sequela',
  'Y35403A Legal intervention involving unspecified sharp objects, suspect injured, initial encounter',
  'Y35403D Legal intervention involving unspecified sharp objects, suspect injured, subsequent encounter',
  'Y35403S Legal intervention involving unspecified sharp objects, suspect injured, sequela',
  'Y35411A Legal intervention involving bayonet, law enforcement official injured, initial encounter',
  'Y35411D Legal intervention involving bayonet, law enforcement official injured, subsequent encounter',
  'Y35411S Legal intervention involving bayonet, law enforcement official injured, sequela',
  'Y35412A Legal intervention involving bayonet, bystander injured, initial encounter',
  'Y35412D Legal intervention involving bayonet, bystander injured, subsequent encounter',
  'Y35412S Legal intervention involving bayonet, bystander injured, sequela',
  'Y35413A Legal intervention involving bayonet, suspect injured, initial encounter',
  'Y35413D Legal intervention involving bayonet, suspect injured, subsequent encounter',
  'Y35413S Legal intervention involving bayonet, suspect injured, sequela',
  'Y35491A Legal intervention involving other sharp objects, law enforcement official injured, initial',
  'encounter',
  'Y35491D Legal intervention involving other sharp objects, law enforcement official injured, subsequent',
  'encounter',
  'Y35491S Legal intervention involving other sharp objects, law enforcement official injured, sequela',
  'Y35492A Legal intervention involving other sharp objects, bystander injured, initial encounter',
  'Y35492D Legal intervention involving other sharp objects, bystander injured, subsequent encounter',
  'Y35492S Legal intervention involving other sharp objects, bystander injured, sequela',
  'Y35493A Legal intervention involving other sharp objects, suspect injured, initial encounter',
  'Y35493D Legal intervention involving other sharp objects, suspect injured, subsequent encounter',
  'Y35493S Legal intervention involving other sharp objects, suspect injured, sequela',
  'Y35811A Legal intervention involving manhandling, law enforcement official injured, initial encounter',
  'Y35811D Legal intervention involving manhandling, law enforcement official injured, subsequent',
  'encounter',
  'Y35811S Legal intervention involving manhandling, law enforcement official injured, sequela',
  'Y35812A Legal intervention involving manhandling, bystander injured, initial encounter',
  'Y35812D Legal intervention involving manhandling, bystander injured, subsequent encounter',
  'Y35812S Legal intervention involving manhandling, bystander injured, sequela',
  'Y35813A Legal intervention involving manhandling, suspect injured, initial encounter',
  'Y35813D Legal intervention involving manhandling, suspect injured, subsequent encounter',
  'Y35813S Legal intervention involving manhandling, suspect injured, sequela',
  'Y35891A Legal intervention involving other specified means, law enforcement official injured, initial',
  'encounter',
  'Y35891D Legal intervention involving other specified means, law enforcement official injured,',
  'subsequent encounter',
  'Y35891S Legal intervention involving other specified means, law enforcement official injured, sequela',
  'Y35892A Legal intervention involving other specified means, bystander injured, initial encounter',
  'Y35892D Legal intervention involving other specified means, bystander injured, subsequent encounter',
  'Y35892S Legal intervention involving other specified means, bystander injured, sequela',
  'Y35893A Legal intervention involving other specified means, suspect injured, initial encounter',
  'Y35893D Legal intervention involving other specified means, suspect injured, subsequent encounter',
  'Y35893S Legal intervention involving other specified means, suspect injured, sequela',
  'Y3591XA Legal intervention, means unspecified, law enforcement official injured, initial encounter',
  'Y3591XD Legal intervention, means unspecified, law enforcement official injured, subsequent encounter',
  'Y3591XS Legal intervention, means unspecified, law enforcement official injured, sequela',
  'Y3592XA Legal intervention, means unspecified, bystander injured, initial encounter',
  'Y3592XD Legal intervention, means unspecified, bystander injured, subsequent encounter',
  'Y3592XS Legal intervention, means unspecified, bystander injured, sequela',
  'Y3593XA Legal intervention, means unspecified, suspect injured, initial encounter',
  'Y3593XD Legal intervention, means unspecified, suspect injured, subsequent encounter',
  'Y3593XS Legal intervention, means unspecified, suspect injured, sequela',
  'Y36000A War operations involving explosion of unspecified marine weapon, military personnel, initial',
  'encounter',
  'Y36000D War operations involving explosion of unspecified marine weapon, military personnel,',
  'subsequent encounter',
  'Y36000S War operations involving explosion of unspecified marine weapon, military personnel, sequela',
  'Y36001A War operations involving explosion of unspecified marine weapon, civilian, initial encounter',
  'Y36001D War operations involving explosion of unspecified marine weapon, civilian, subsequent',
  'encounter',
  'Y36001S War operations involving explosion of unspecified marine weapon, civilian, sequela',
  'Y36010A War operations involving explosion of depth-charge, military personnel, initial encounter',
  'Y36010D War operations involving explosion of depth-charge, military personnel, subsequent encounter',
  'Y36010S War operations involving explosion of depth-charge, military personnel, sequela',
  'Y36011A War operations involving explosion of depth-charge, civilian, initial encounter',
  'Y36011D War operations involving explosion of depth-charge, civilian, subsequent encounter',
  'Y36011S War operations involving explosion of depth-charge, civilian, sequela',
  'Y36020A War operations involving explosion of marine mine, military personnel, initial encounter',
  'Y36020D War operations involving explosion of marine mine, military personnel, subsequent encounter',
  'Y36020S War operations involving explosion of marine mine, military personnel, sequela',
  'Y36021A War operations involving explosion of marine mine, civilian, initial encounter',
  'Y36021D War operations involving explosion of marine mine, civilian, subsequent encounter',
  'Y36021S War operations involving explosion of marine mine, civilian, sequela',
  'Y36030A War operations involving explosion of sea-based artillery shell, military personnel, initial',
  'encounter',
  'Y36030D War operations involving explosion of sea-based artillery shell, military personnel, subsequent',
  'encounter',
  'Y36030S War operations involving explosion of sea-based artillery shell, military personnel, sequela',
  'Y36031A War operations involving explosion of sea-based artillery shell, civilian, initial encounter',
  'Y36031D War operations involving explosion of sea-based artillery shell, civilian, subsequent encounter',
  'Y36031S War operations involving explosion of sea-based artillery shell, civilian, sequela',
  'Y36040A War operations involving explosion of torpedo, military personnel, initial encounter',
  'Y36040D War operations involving explosion of torpedo, military personnel, subsequent encounter',
  'Y36040S War operations involving explosion of torpedo, military personnel, sequela',
  'Y36041A War operations involving explosion of torpedo, civilian, initial encounter',
  'Y36041D War operations involving explosion of torpedo, civilian, subsequent encounter',
  'Y36041S War operations involving explosion of torpedo, civilian, sequela',
  'Y36050A War operations involving accidental detonation of onboard marine weapons, military',
  'personnel, initial encounter',
  'Y36050D War operations involving accidental detonation of onboard marine weapons, military',
  'personnel, subsequent encounter',
  'Y36050S War operations involving accidental detonation of onboard marine weapons, military',
  'personnel, sequela',
  'Y36051A War operations involving accidental detonation of onboard marine weapons, civilian, initial',
  'encounter',
  'Y36051D War operations involving accidental detonation of onboard marine weapons, civilian,',
  'subsequent encounter',
  'Y36051S War operations involving accidental detonation of onboard marine weapons, civilian, sequela',
  'Y36090A War operations involving explosion of other marine weapons, military personnel, initial',
  'encounter',
  'Y36090D War operations involving explosion of other marine weapons, military personnel, subsequent',
  'encounter',
  'Y36090S War operations involving explosion of other marine weapons, military personnel, sequela',
  'Y36091A War operations involving explosion of other marine weapons, civilian, initial encounter',
  'Y36091D War operations involving explosion of other marine weapons, civilian, subsequent encounter',
  'Y36091S War operations involving explosion of other marine weapons, civilian, sequela',
  'Y36100A War operations involving unspecified destruction of aircraft, military personnel, initial',
  'encounter',
  'Y36100D War operations involving unspecified destruction of aircraft, military personnel, subsequent',
  'encounter',
  'Y36100S War operations involving unspecified destruction of aircraft, military personnel, sequela',
  'Y36101A War operations involving unspecified destruction of aircraft, civilian, initial encounter',
  'Y36101D War operations involving unspecified destruction of aircraft, civilian, subsequent encounter',
  'Y36101S War operations involving unspecified destruction of aircraft, civilian, sequela',
  'Y36110A War operations involving destruction of aircraft due to enemy fire or explosives, military',
  'personnel, initial encounter',
  'Y36110D War operations involving destruction of aircraft due to enemy fire or explosives, military',
  'personnel, subsequent encounter',
  'Y36110S War operations involving destruction of aircraft due to enemy fire or explosives, military',
  'personnel, sequela',
  'Y36111A War operations involving destruction of aircraft due to enemy fire or explosives, civilian, initial',
  'encounter',
  'Y36111D War operations involving destruction of aircraft due to enemy fire or explosives, civilian,',
  'subsequent encounter',
  'Y36111S War operations involving destruction of aircraft due to enemy fire or explosives, civilian,',
  'sequela',
  'Y36120A War operations involving destruction of aircraft due to collision with other aircraft, military',
  'personnel, initial encounter',
  'Y36120D War operations involving destruction of aircraft due to collision with other aircraft, military',
  'personnel, subsequent encounter',
  'Y36120S War operations involving destruction of aircraft due to collision with other aircraft, military',
  'personnel, sequela',
  'Y36121A War operations involving destruction of aircraft due to collision with other aircraft, civilian,',
  'initial encounter',
  'Y36121D War operations involving destruction of aircraft due to collision with other aircraft, civilian,',
  'subsequent encounter',
  'Y36121S War operations involving destruction of aircraft due to collision with other aircraft, civilian,',
  'sequela',
  'Y36130A War operations involving destruction of aircraft due to onboard fire, military personnel, initial',
  'encounter',
  'Y36130D War operations involving destruction of aircraft due to onboard fire, military personnel,',
  'subsequent encounter',
  'Y36130S War operations involving destruction of aircraft due to onboard fire, military personnel,',
  'sequela',
  'Y36131A War operations involving destruction of aircraft due to onboard fire, civilian, initial encounter',
  'Y36131D War operations involving destruction of aircraft due to onboard fire, civilian, subsequent',
  'encounter',
  'Y36131S War operations involving destruction of aircraft due to onboard fire, civilian, sequela',
  'Y36140A War operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, military personnel, initial encounter',
  'Y36140D War operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, military personnel, subsequent encounter',
  'Y36140S War operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, military personnel, sequela',
  'Y36141A War operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, civilian, initial encounter',
  'Y36141D War operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, civilian, subsequent encounter',
  'Y36141S War operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, civilian, sequela',
  'Y36190A War operations involving other destruction of aircraft, military personnel, initial encounter',
  'Y36190D War operations involving other destruction of aircraft, military personnel, subsequent',
  'encounter',
  'Y36190S War operations involving other destruction of aircraft, military personnel, sequela',
  'Y36191A War operations involving other destruction of aircraft, civilian, initial encounter',
  'Y36191D War operations involving other destruction of aircraft, civilian, subsequent encounter',
  'Y36191S War operations involving other destruction of aircraft, civilian, sequela',
  'Y36200A War operations involving unspecified explosion and fragments, military personnel, initial',
  'encounter',
  'Y36200D War operations involving unspecified explosion and fragments, military personnel, subsequent',
  'encounter',
  'Y36200S War operations involving unspecified explosion and fragments, military personnel, sequela',
  'Y36201A War operations involving unspecified explosion and fragments, civilian, initial encounter',
  'Y36201D War operations involving unspecified explosion and fragments, civilian, subsequent encounter',
  'Y36201S War operations involving unspecified explosion and fragments, civilian, sequela',
  'Y36210A War operations involving explosion of aerial bomb, military personnel, initial encounter',
  'Y36210D War operations involving explosion of aerial bomb, military personnel, subsequent encounter',
  'Y36210S War operations involving explosion of aerial bomb, military personnel, sequela',
  'Y36211A War operations involving explosion of aerial bomb, civilian, initial encounter',
  'Y36211D War operations involving explosion of aerial bomb, civilian, subsequent encounter',
  'Y36211S War operations involving explosion of aerial bomb, civilian, sequela',
  'Y36220A War operations involving explosion of guided missile, military personnel, initial encounter',
  'Y36220D War operations involving explosion of guided missile, military personnel, subsequent',
  'encounter',
  'Y36220S War operations involving explosion of guided missile, military personnel, sequela',
  'Y36221A War operations involving explosion of guided missile, civilian, initial encounter',
  'Y36221D War operations involving explosion of guided missile, civilian, subsequent encounter',
  'Y36221S War operations involving explosion of guided missile, civilian, sequela',
  'Y36230A War operations involving explosion of improvised explosive device [IED], military personnel,',
  'initial encounter',
  'Y36230D War operations involving explosion of improvised explosive device [IED], military personnel,',
  'subsequent encounter',
  'Y36230S War operations involving explosion of improvised explosive device [IED], military personnel,',
  'sequela',
  'Y36231A War operations involving explosion of improvised explosive device [IED], civilian, initial',
  'encounter',
  'Y36231D War operations involving explosion of improvised explosive device [IED], civilian, subsequent',
  'encounter',
  'Y36231S War operations involving explosion of improvised explosive device [IED], civilian, sequela',
  'Y36240A War operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, military personnel, initial encounter',
  'Y36240D War operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, military personnel, subsequent encounter',
  'Y36240S War operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, military personnel, sequela',
  'Y36241A War operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, civilian, initial encounter',
  'Y36241D War operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, civilian, subsequent encounter',
  'Y36241S War operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, civilian, sequela',
  'Y36250A War operations involving fragments from munitions, military personnel, initial encounter',
  'Y36250D War operations involving fragments from munitions, military personnel, subsequent encounter',
  'Y36250S War operations involving fragments from munitions, military personnel, sequela',
  'Y36251A War operations involving fragments from munitions, civilian, initial encounter',
  'Y36251D War operations involving fragments from munitions, civilian, subsequent encounter',
  'Y36251S War operations involving fragments from munitions, civilian, sequela',
  'Y36260A War operations involving fragments of improvised explosive device [IED], military personnel,',
  'initial encounter',
  'Y36260D War operations involving fragments of improvised explosive device [IED], military personnel,',
  'subsequent encounter',
  'Y36260S War operations involving fragments of improvised explosive device [IED], military personnel,',
  'sequela',
  'Y36261A War operations involving fragments of improvised explosive device [IED], civilian, initial',
  'encounter',
  'Y36261D War operations involving fragments of improvised explosive device [IED], civilian, subsequent',
  'encounter',
  'Y36261S War operations involving fragments of improvised explosive device [IED], civilian, sequela',
  'Y36270A War operations involving fragments from weapons, military personnel, initial encounter',
  'Y36270D War operations involving fragments from weapons, military personnel, subsequent encounter',
  'Y36270S War operations involving fragments from weapons, military personnel, sequela',
  'Y36271A War operations involving fragments from weapons, civilian, initial encounter',
  'Y36271D War operations involving fragments from weapons, civilian, subsequent encounter',
  'Y36271S War operations involving fragments from weapons, civilian, sequela',
  'Y36290A War operations involving other explosions and fragments, military personnel, initial encounter',
  'Y36290D War operations involving other explosions and fragments, military personnel, subsequent',
  'encounter',
  'Y36290S War operations involving other explosions and fragments, military personnel, sequela',
  'Y36291A War operations involving other explosions and fragments, civilian, initial encounter',
  'Y36291D War operations involving other explosions and fragments, civilian, subsequent encounter',
  'Y36291S War operations involving other explosions and fragments, civilian, sequela',
  'Y36300A War operations involving unspecified fire, conflagration and hot substance, military personnel,',
  'initial encounter',
  'Y36300D War operations involving unspecified fire, conflagration and hot substance, military personnel,',
  'subsequent encounter',
  'Y36300S War operations involving unspecified fire, conflagration and hot substance, military personnel,',
  'sequela',
  'Y36301A War operations involving unspecified fire, conflagration and hot substance, civilian, initial',
  'encounter',
  'Y36301D War operations involving unspecified fire, conflagration and hot substance, civilian,',
  'subsequent encounter',
  'Y36301S War operations involving unspecified fire, conflagration and hot substance, civilian, sequela',
  'Y36310A War operations involving gasoline bomb, military personnel, initial encounter',
  'Y36310D War operations involving gasoline bomb, military personnel, subsequent encounter',
  'Y36310S War operations involving gasoline bomb, military personnel, sequela',
  'Y36311A War operations involving gasoline bomb, civilian, initial encounter',
  'Y36311D War operations involving gasoline bomb, civilian, subsequent encounter',
  'Y36311S War operations involving gasoline bomb, civilian, sequela',
  'Y36320A War operations involving incendiary bullet, military personnel, initial encounter',
  'Y36320D War operations involving incendiary bullet, military personnel, subsequent encounter',
  'Y36320S War operations involving incendiary bullet, military personnel, sequela',
  'Y36321A War operations involving incendiary bullet, civilian, initial encounter',
  'Y36321D War operations involving incendiary bullet, civilian, subsequent encounter',
  'Y36321S War operations involving incendiary bullet, civilian, sequela',
  'Y36330A War operations involving flamethrower, military personnel, initial encounter',
  'Y36330D War operations involving flamethrower, military personnel, subsequent encounter',
  'Y36330S War operations involving flamethrower, military personnel, sequela',
  'Y36331A War operations involving flamethrower, civilian, initial encounter',
  'Y36331D War operations involving flamethrower, civilian, subsequent encounter',
  'Y36331S War operations involving flamethrower, civilian, sequela',
  'Y36390A War operations involving other fires, conflagrations and hot substances, military personnel,',
  'initial encounter',
  'Y36390D War operations involving other fires, conflagrations and hot substances, military personnel,',
  'subsequent encounter',
  'Y36390S War operations involving other fires, conflagrations and hot substances, military personnel,',
  'sequela',
  'Y36391A War operations involving other fires, conflagrations and hot substances, civilian, initial',
  'encounter',
  'Y36391D War operations involving other fires, conflagrations and hot substances, civilian, subsequent',
  'encounter',
  'Y36391S War operations involving other fires, conflagrations and hot substances, civilian, sequela',
  'Y36410A War operations involving rubber bullets, military personnel, initial encounter',
  'Y36410D War operations involving rubber bullets, military personnel, subsequent encounter',
  'Y36410S War operations involving rubber bullets, military personnel, sequela',
  'Y36411A War operations involving rubber bullets, civilian, initial encounter',
  'Y36411D War operations involving rubber bullets, civilian, subsequent encounter',
  'Y36411S War operations involving rubber bullets, civilian, sequela',
  'Y36420A War operations involving firearms pellets, military personnel, initial encounter',
  'Y36420D War operations involving firearms pellets, military personnel, subsequent encounter',
  'Y36420S War operations involving firearms pellets, military personnel, sequela',
  'Y36421A War operations involving firearms pellets, civilian, initial encounter',
  'Y36421D War operations involving firearms pellets, civilian, subsequent encounter',
  'Y36421S War operations involving firearms pellets, civilian, sequela',
  'Y36430A War operations involving other firearms discharge, military personnel, initial encounter',
  'Y36430D War operations involving other firearms discharge, military personnel, subsequent encounter',
  'Y36430S War operations involving other firearms discharge, military personnel, sequela',
  'Y36431A War operations involving other firearms discharge, civilian, initial encounter',
  'Y36431D War operations involving other firearms discharge, civilian, subsequent encounter',
  'Y36431S War operations involving other firearms discharge, civilian, sequela',
  'Y36440A War operations involving unarmed hand to hand combat, military personnel, initial encounter',
  'Y36440D War operations involving unarmed hand to hand combat, military personnel, subsequent',
  'encounter',
  'Y36440S War operations involving unarmed hand to hand combat, military personnel, sequela',
  'Y36441A War operations involving unarmed hand to hand combat, civilian, initial encounter',
  'Y36441D War operations involving unarmed hand to hand combat, civilian, subsequent encounter',
  'Y36441S War operations involving unarmed hand to hand combat, civilian, sequela',
  'Y36450A War operations involving combat using blunt or piercing object, military personnel, initial',
  'encounter',
  'Y36450D War operations involving combat using blunt or piercing object, military personnel,',
  'subsequent encounter',
  'Y36450S War operations involving combat using blunt or piercing object, military personnel, sequela',
  'Y36451A War operations involving combat using blunt or piercing object, civilian, initial encounter',
  'Y36451D War operations involving combat using blunt or piercing object, civilian, subsequent encounter',
  'Y36451S War operations involving combat using blunt or piercing object, civilian, sequela',
  'Y36460A War operations involving intentional restriction of air and airway, military personnel, initial',
  'encounter',
  'Y36460D War operations involving intentional restriction of air and airway, military personnel,',
  'subsequent encounter',
  'Y36460S War operations involving intentional restriction of air and airway, military personnel, sequela',
  'Y36461A War operations involving intentional restriction of air and airway, civilian, initial encounter',
  'Y36461D War operations involving intentional restriction of air and airway, civilian, subsequent',
  'encounter',
  'Y36461S War operations involving intentional restriction of air and airway, civilian, sequela',
  'Y36470A War operations involving unintentional restriction of air and airway, military personnel, initial',
  'encounter',
  'Y36470D War operations involving unintentional restriction of air and airway, military personnel,',
  'subsequent encounter',
  'Y36470S War operations involving unintentional restriction of air and airway, military personnel,',
  'sequela',
  'Y36471A War operations involving unintentional restriction of air and airway, civilian, initial encounter',
  'Y36471D War operations involving unintentional restriction of air and airway, civilian, subsequent',
  'encounter',
  'Y36471S War operations involving unintentional restriction of air and airway, civilian, sequela',
  'Y36490A War operations involving other forms of conventional warfare, military personnel, initial',
  'encounter',
  'Y36490D War operations involving other forms of conventional warfare, military personnel, subsequent',
  'encounter',
  'Y36490S War operations involving other forms of conventional warfare, military personnel, sequela',
  'Y36491A War operations involving other forms of conventional warfare, civilian, initial encounter',
  'Y36491D War operations involving other forms of conventional warfare, civilian, subsequent encounter',
  'Y36491S War operations involving other forms of conventional warfare, civilian, sequela',
  'Y36500A War operations involving unspecified effect of nuclear weapon, military personnel, initial',
  'encounter',
  'Y36500D War operations involving unspecified effect of nuclear weapon, military personnel, subsequent',
  'encounter',
  'Y36500S War operations involving unspecified effect of nuclear weapon, military personnel, sequela',
  'Y36501A War operations involving unspecified effect of nuclear weapon, civilian, initial encounter',
  'Y36501D War operations involving unspecified effect of nuclear weapon, civilian, subsequent encounter',
  'Y36501S War operations involving unspecified effect of nuclear weapon, civilian, sequela',
  'Y36510A War operations involving direct blast effect of nuclear weapon, military personnel, initial',
  'encounter',
  'Y36510D War operations involving direct blast effect of nuclear weapon, military personnel, subsequent',
  'encounter',
  'Y36510S War operations involving direct blast effect of nuclear weapon, military personnel, sequela',
  'Y36511A War operations involving direct blast effect of nuclear weapon, civilian, initial encounter',
  'Y36511D War operations involving direct blast effect of nuclear weapon, civilian, subsequent encounter',
  'Y36511S War operations involving direct blast effect of nuclear weapon, civilian, sequela',
  'Y36520A War operations involving indirect blast effect of nuclear weapon, military personnel, initial',
  'encounter',
  'Y36520D War operations involving indirect blast effect of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y36520S War operations involving indirect blast effect of nuclear weapon, military personnel, sequela',
  'Y36521A War operations involving indirect blast effect of nuclear weapon, civilian, initial encounter',
  'Y36521D War operations involving indirect blast effect of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y36521S War operations involving indirect blast effect of nuclear weapon, civilian, sequela',
  'Y36530A War operations involving thermal radiation effect of nuclear weapon, military personnel, initial',
  'encounter',
  'Y36530D War operations involving thermal radiation effect of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y36530S War operations involving thermal radiation effect of nuclear weapon, military personnel,',
  'sequela',
  'Y36531A War operations involving thermal radiation effect of nuclear weapon, civilian, initial encounter',
  'Y36531D War operations involving thermal radiation effect of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y36531S War operations involving thermal radiation effect of nuclear weapon, civilian, sequela',
  'Y36540A War operation involving nuclear radiation effects of nuclear weapon, military personnel, initial',
  'encounter',
  'Y36540D War operation involving nuclear radiation effects of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y36540S War operation involving nuclear radiation effects of nuclear weapon, military personnel,',
  'sequela',
  'Y36541A War operation involving nuclear radiation effects of nuclear weapon, civilian, initial encounter',
  'Y36541D War operation involving nuclear radiation effects of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y36541S War operation involving nuclear radiation effects of nuclear weapon, civilian, sequela',
  'Y36590A War operation involving other effects of nuclear weapons, military personnel, initial encounter',
  'Y36590D War operation involving other effects of nuclear weapons, military personnel, subsequent',
  'encounter',
  'Y36590S War operation involving other effects of nuclear weapons, military personnel, sequela',
  'Y36591A War operation involving other effects of nuclear weapons, civilian, initial encounter',
  'Y36591D War operation involving other effects of nuclear weapons, civilian, subsequent encounter',
  'Y36591S War operation involving other effects of nuclear weapons, civilian, sequela',
  'Y366X0A War operations involving biological weapons, military personnel, initial encounter',
  'Y366X0D War operations involving biological weapons, military personnel, subsequent encounter',
  'Y366X0S War operations involving biological weapons, military personnel, sequela',
  'Y366X1A War operations involving biological weapons, civilian, initial encounter',
  'Y366X1D War operations involving biological weapons, civilian, subsequent encounter',
  'Y366X1S War operations involving biological weapons, civilian, sequela',
  'Y367X0A War operations involving chemical weapons and other forms of unconventional warfare,',
  'military personnel, initial encounter',
  'Y367X0D War operations involving chemical weapons and other forms of unconventional warfare,',
  'military personnel, subsequent encounter',
  'Y367X0S War operations involving chemical weapons and other forms of unconventional warfare,',
  'military personnel, sequela',
  'Y367X1A War operations involving chemical weapons and other forms of unconventional warfare,',
  'civilian, initial encounter',
  'Y367X1D War operations involving chemical weapons and other forms of unconventional warfare,',
  'civilian, subsequent encounter',
  'Y367X1S War operations involving chemical weapons and other forms of unconventional warfare,',
  'civilian, sequela',
  'Y36810A Explosion of mine placed during war operations but exploding after cessation of hostilities,',
  'military personnel, initial encounter',
  'Y36810D Explosion of mine placed during war operations but exploding after cessation of hostilities,',
  'military personnel, subsequent encounter',
  'Y36810S Explosion of mine placed during war operations but exploding after cessation of hostilities,',
  'military personnel, sequela',
  'Y36811A Explosion of mine placed during war operations but exploding after cessation of hostilities,',
  'civilian, initial encounter',
  'Y36811D Explosion of mine placed during war operations but exploding after cessation of hostilities,',
  'civilian, subsequent encounter',
  'Y36811S Explosion of mine placed during war operations but exploding after cessation of hostilities,',
  'civilian, sequela',
  'Y36820A Explosion of bomb placed during war operations but exploding after cessation of hostilities,',
  'military personnel, initial encounter',
  'Y36820D Explosion of bomb placed during war operations but exploding after cessation of hostilities,',
  'military personnel, subsequent encounter',
  'Y36820S Explosion of bomb placed during war operations but exploding after cessation of hostilities,',
  'military personnel, sequela',
  'Y36821A Explosion of bomb placed during war operations but exploding after cessation of hostilities,',
  'civilian, initial encounter',
  'Y36821D Explosion of bomb placed during war operations but exploding after cessation of hostilities,',
  'civilian, subsequent encounter',
  'Y36821S Explosion of bomb placed during war operations but exploding after cessation of hostilities,',
  'civilian, sequela',
  'Y36880A Other war operations occurring after cessation of hostilities, military personnel, initial',
  'encounter',
  'Y36880D Other war operations occurring after cessation of hostilities, military personnel, subsequent',
  'encounter',
  'Y36880S Other war operations occurring after cessation of hostilities, military personnel, sequela',
  'Y36881A Other war operations occurring after cessation of hostilities, civilian, initial encounter',
  'Y36881D Other war operations occurring after cessation of hostilities, civilian, subsequent encounter',
  'Y36881S Other war operations occurring after cessation of hostilities, civilian, sequela',
  'Y36890A Unspecified war operations occurring after cessation of hostilities, military personnel, initial',
  'encounter',
  'Y36890D Unspecified war operations occurring after cessation of hostilities, military personnel,',
  'subsequent encounter',
  'Y36890S Unspecified war operations occurring after cessation of hostilities, military personnel, sequela',
  'Y36891A Unspecified war operations occurring after cessation of hostilities, civilian, initial encounter',
  'Y36891D Unspecified war operations occurring after cessation of hostilities, civilian, subsequent',
  'encounter',
  'Y36891S Unspecified war operations occurring after cessation of hostilities, civilian, sequela',
  'Y3690XA War operations, unspecified, initial encounter',
  'Y3690XD War operations, unspecified, subsequent encounter',
  'Y3690XS War operations, unspecified, sequela',
  'Y3691XA War operations involving unspecified weapon of mass destruction [WMD], initial encounter',
  'Y3691XD War operations involving unspecified weapon of mass destruction [WMD], subsequent',
  'encounter',
  'Y3691XS War operations involving unspecified weapon of mass destruction [WMD], sequela',
  'Y3692XA War operations involving friendly fire, initial encounter',
  'Y3692XD War operations involving friendly fire, subsequent encounter',
  'Y3692XS War operations involving friendly fire, sequela',
  'Y37000A Military operations involving explosion of unspecified marine weapon, military personnel,',
  'initial encounter',
  'Y37000D Military operations involving explosion of unspecified marine weapon, military personnel,',
  'subsequent encounter',
  'Y37000S Military operations involving explosion of unspecified marine weapon, military personnel,',
  'sequela',
  'Y37001A Military operations involving explosion of unspecified marine weapon, civilian, initial',
  'encounter',
  'Y37001D Military operations involving explosion of unspecified marine weapon, civilian, subsequent',
  'encounter',
  'Y37001S Military operations involving explosion of unspecified marine weapon, civilian, sequela',
  'Y37010A Military operations involving explosion of depth-charge, military personnel, initial encounter',
  'Y37010D Military operations involving explosion of depth-charge, military personnel, subsequent',
  'encounter',
  'Y37010S Military operations involving explosion of depth-charge, military personnel, sequela',
  'Y37011A Military operations involving explosion of depth-charge, civilian, initial encounter',
  'Y37011D Military operations involving explosion of depth-charge, civilian, subsequent encounter',
  'Y37011S Military operations involving explosion of depth-charge, civilian, sequela',
  'Y37020A Military operations involving explosion of marine mine, military personnel, initial encounter',
  'Y37020D Military operations involving explosion of marine mine, military personnel, subsequent',
  'encounter',
  'Y37020S Military operations involving explosion of marine mine, military personnel, sequela',
  'Y37021A Military operations involving explosion of marine mine, civilian, initial encounter',
  'Y37021D Military operations involving explosion of marine mine, civilian, subsequent encounter',
  'Y37021S Military operations involving explosion of marine mine, civilian, sequela',
  'Y37030A Military operations involving explosion of sea-based artillery shell, military personnel, initial',
  'encounter',
  'Y37030D Military operations involving explosion of sea-based artillery shell, military personnel,',
  'subsequent encounter',
  'Y37030S Military operations involving explosion of sea-based artillery shell, military personnel, sequela',
  'Y37031A Military operations involving explosion of sea-based artillery shell, civilian, initial encounter',
  'Y37031D Military operations involving explosion of sea-based artillery shell, civilian, subsequent',
  'encounter',
  'Y37031S Military operations involving explosion of sea-based artillery shell, civilian, sequela',
  'Y37040A Military operations involving explosion of torpedo, military personnel, initial encounter',
  'Y37040D Military operations involving explosion of torpedo, military personnel, subsequent encounter',
  'Y37040S Military operations involving explosion of torpedo, military personnel, sequela',
  'Y37041A Military operations involving explosion of torpedo, civilian, initial encounter',
  'Y37041D Military operations involving explosion of torpedo, civilian, subsequent encounter',
  'Y37041S Military operations involving explosion of torpedo, civilian, sequela',
  'Y37050A Military operations involving accidental detonation of onboard marine weapons, military',
  'personnel, initial encounter',
  'Y37050D Military operations involving accidental detonation of onboard marine weapons, military',
  'personnel, subsequent encounter',
  'Y37050S Military operations involving accidental detonation of onboard marine weapons, military',
  'personnel, sequela',
  'Y37051A Military operations involving accidental detonation of onboard marine weapons, civilian, initial',
  'encounter',
  'Y37051D Military operations involving accidental detonation of onboard marine weapons, civilian,',
  'subsequent encounter',
  'Y37051S Military operations involving accidental detonation of onboard marine weapons, civilian,',
  'sequela',
  'Y37090A Military operations involving explosion of other marine weapons, military personnel, initial',
  'encounter',
  'Y37090D Military operations involving explosion of other marine weapons, military personnel,',
  'subsequent encounter',
  'Y37090S Military operations involving explosion of other marine weapons, military personnel, sequela',
  'Y37091A Military operations involving explosion of other marine weapons, civilian, initial encounter',
  'Y37091D Military operations involving explosion of other marine weapons, civilian, subsequent',
  'encounter',
  'Y37091S Military operations involving explosion of other marine weapons, civilian, sequela',
  'Y37100A Military operations involving unspecified destruction of aircraft, military personnel, initial',
  'encounter',
  'Y37100D Military operations involving unspecified destruction of aircraft, military personnel,',
  'subsequent encounter',
  'Y37100S Military operations involving unspecified destruction of aircraft, military personnel, sequela',
  'Y37101A Military operations involving unspecified destruction of aircraft, civilian, initial encounter',
  'Y37101D Military operations involving unspecified destruction of aircraft, civilian, subsequent encounter',
  'Y37101S Military operations involving unspecified destruction of aircraft, civilian, sequela',
  'Y37110A Military operations involving destruction of aircraft due to enemy fire or explosives, military',
  'personnel, initial encounter',
  'Y37110D Military operations involving destruction of aircraft due to enemy fire or explosives, military',
  'personnel, subsequent encounter',
  'Y37110S Military operations involving destruction of aircraft due to enemy fire or explosives, military',
  'personnel, sequela',
  'Y37111A Military operations involving destruction of aircraft due to enemy fire or explosives, civilian,',
  'initial encounter',
  'Y37111D Military operations involving destruction of aircraft due to enemy fire or explosives, civilian,',
  'subsequent encounter',
  'Y37111S Military operations involving destruction of aircraft due to enemy fire or explosives, civilian,',
  'sequela',
  'Y37120A Military operations involving destruction of aircraft due to collision with other aircraft, military',
  'personnel, initial encounter',
  'Y37120D Military operations involving destruction of aircraft due to collision with other aircraft, military',
  'personnel, subsequent encounter',
  'Y37120S Military operations involving destruction of aircraft due to collision with other aircraft, military',
  'personnel, sequela',
  'Y37121A Military operations involving destruction of aircraft due to collision with other aircraft, civilian,',
  'initial encounter',
  'Y37121D Military operations involving destruction of aircraft due to collision with other aircraft, civilian,',
  'subsequent encounter',
  'Y37121S Military operations involving destruction of aircraft due to collision with other aircraft, civilian,',
  'sequela',
  'Y37130A Military operations involving destruction of aircraft due to onboard fire, military personnel,',
  'initial encounter',
  'Y37130D Military operations involving destruction of aircraft due to onboard fire, military personnel,',
  'subsequent encounter',
  'Y37130S Military operations involving destruction of aircraft due to onboard fire, military personnel,',
  'sequela',
  'Y37131A Military operations involving destruction of aircraft due to onboard fire, civilian, initial',
  'encounter',
  'Y37131D Military operations involving destruction of aircraft due to onboard fire, civilian, subsequent',
  'encounter',
  'Y37131S Military operations involving destruction of aircraft due to onboard fire, civilian, sequela',
  'Y37140A Military operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, military personnel, initial encounter',
  'Y37140D Military operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, military personnel, subsequent encounter',
  'Y37140S Military operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, military personnel, sequela',
  'Y37141A Military operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, civilian, initial encounter',
  'Y37141D Military operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, civilian, subsequent encounter',
  'Y37141S Military operations involving destruction of aircraft due to accidental detonation of onboard',
  'munitions and explosives, civilian, sequela',
  'Y37190A Military operations involving other destruction of aircraft, military personnel, initial encounter',
  'Y37190D Military operations involving other destruction of aircraft, military personnel, subsequent',
  'encounter',
  'Y37190S Military operations involving other destruction of aircraft, military personnel, sequela',
  'Y37191A Military operations involving other destruction of aircraft, civilian, initial encounter',
  'Y37191D Military operations involving other destruction of aircraft, civilian, subsequent encounter',
  'Y37191S Military operations involving other destruction of aircraft, civilian, sequela',
  'Y37200A Military operations involving unspecified explosion and fragments, military personnel, initial',
  'encounter',
  'Y37200D Military operations involving unspecified explosion and fragments, military personnel,',
  'subsequent encounter',
  'Y37200S Military operations involving unspecified explosion and fragments, military personnel, sequela',
  'Y37201A Military operations involving unspecified explosion and fragments, civilian, initial encounter',
  'Y37201D Military operations involving unspecified explosion and fragments, civilian, subsequent',
  'encounter',
  'Y37201S Military operations involving unspecified explosion and fragments, civilian, sequela',
  'Y37210A Military operations involving explosion of aerial bomb, military personnel, initial encounter',
  'Y37210D Military operations involving explosion of aerial bomb, military personnel, subsequent',
  'encounter',
  'Y37210S Military operations involving explosion of aerial bomb, military personnel, sequela',
  'Y37211A Military operations involving explosion of aerial bomb, civilian, initial encounter',
  'Y37211D Military operations involving explosion of aerial bomb, civilian, subsequent encounter',
  'Y37211S Military operations involving explosion of aerial bomb, civilian, sequela',
  'Y37220A Military operations involving explosion of guided missile, military personnel, initial encounter',
  'Y37220D Military operations involving explosion of guided missile, military personnel, subsequent',
  'encounter',
  'Y37220S Military operations involving explosion of guided missile, military personnel, sequela',
  'Y37221A Military operations involving explosion of guided missile, civilian, initial encounter',
  'Y37221D Military operations involving explosion of guided missile, civilian, subsequent encounter',
  'Y37221S Military operations involving explosion of guided missile, civilian, sequela',
  'Y37230A Military operations involving explosion of improvised explosive device [IED], military',
  'personnel, initial encounter',
  'Y37230D Military operations involving explosion of improvised explosive device [IED], military',
  'personnel, subsequent encounter',
  'Y37230S Military operations involving explosion of improvised explosive device [IED], military personnel,',
  'sequela',
  'Y37231A Military operations involving explosion of improvised explosive device [IED], civilian, initial',
  'encounter',
  'Y37231D Military operations involving explosion of improvised explosive device [IED], civilian,',
  'subsequent encounter',
  'Y37231S Military operations involving explosion of improvised explosive device [IED], civilian, sequela',
  'Y37240A Military operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, military personnel, initial encounter',
  'Y37240D Military operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, military personnel, subsequent encounter',
  'Y37240S Military operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, military personnel, sequela',
  'Y37241A Military operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, civilian, initial encounter',
  'Y37241D Military operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, civilian, subsequent encounter',
  'Y37241S Military operations involving explosion due to accidental detonation and discharge of own',
  'munitions or munitions launch device, civilian, sequela',
  'Y37250A Military operations involving fragments from munitions, military personnel, initial encounter',
  'Y37250D Military operations involving fragments from munitions, military personnel, subsequent',
  'encounter',
  'Y37250S Military operations involving fragments from munitions, military personnel, sequela',
  'Y37251A Military operations involving fragments from munitions, civilian, initial encounter',
  'Y37251D Military operations involving fragments from munitions, civilian, subsequent encounter',
  'Y37251S Military operations involving fragments from munitions, civilian, sequela',
  'Y37260A Military operations involving fragments of improvised explosive device [IED], military',
  'personnel, initial encounter',
  'Y37260D Military operations involving fragments of improvised explosive device [IED], military',
  'personnel, subsequent encounter',
  'Y37260S Military operations involving fragments of improvised explosive device [IED], military',
  'personnel, sequela',
  'Y37261A Military operations involving fragments of improvised explosive device [IED], civilian, initial',
  'encounter',
  'Y37261D Military operations involving fragments of improvised explosive device [IED], civilian,',
  'subsequent encounter',
  'Y37261S Military operations involving fragments of improvised explosive device [IED], civilian, sequela',
  'Y37270A Military operations involving fragments from weapons, military personnel, initial encounter',
  'Y37270D Military operations involving fragments from weapons, military personnel, subsequent',
  'encounter',
  'Y37270S Military operations involving fragments from weapons, military personnel, sequela',
  'Y37271A Military operations involving fragments from weapons, civilian, initial encounter',
  'Y37271D Military operations involving fragments from weapons, civilian, subsequent encounter',
  'Y37271S Military operations involving fragments from weapons, civilian, sequela',
  'Y37290A Military operations involving other explosions and fragments, military personnel, initial',
  'encounter',
  'Y37290D Military operations involving other explosions and fragments, military personnel, subsequent',
  'encounter',
  'Y37290S Military operations involving other explosions and fragments, military personnel, sequela',
  'Y37291A Military operations involving other explosions and fragments, civilian, initial encounter',
  'Y37291D Military operations involving other explosions and fragments, civilian, subsequent encounter',
  'Y37291S Military operations involving other explosions and fragments, civilian, sequela',
  'Y37300A Military operations involving unspecified fire, conflagration and hot substance, military',
  'personnel, initial encounter',
  'Y37300D Military operations involving unspecified fire, conflagration and hot substance, military',
  'personnel, subsequent encounter',
  'Y37300S Military operations involving unspecified fire, conflagration and hot substance, military',
  'personnel, sequela',
  'Y37301A Military operations involving unspecified fire, conflagration and hot substance, civilian, initial',
  'encounter',
  'Y37301D Military operations involving unspecified fire, conflagration and hot substance, civilian,',
  'subsequent encounter',
  'Y37301S Military operations involving unspecified fire, conflagration and hot substance, civilian, sequela',
  'Y37310A Military operations involving gasoline bomb, military personnel, initial encounter',
  'Y37310D Military operations involving gasoline bomb, military personnel, subsequent encounter',
  'Y37310S Military operations involving gasoline bomb, military personnel, sequela',
  'Y37311A Military operations involving gasoline bomb, civilian, initial encounter',
  'Y37311D Military operations involving gasoline bomb, civilian, subsequent encounter',
  'Y37311S Military operations involving gasoline bomb, civilian, sequela',
  'Y37320A Military operations involving incendiary bullet, military personnel, initial encounter',
  'Y37320D Military operations involving incendiary bullet, military personnel, subsequent encounter',
  'Y37320S Military operations involving incendiary bullet, military personnel, sequela',
  'Y37321A Military operations involving incendiary bullet, civilian, initial encounter',
  'Y37321D Military operations involving incendiary bullet, civilian, subsequent encounter',
  'Y37321S Military operations involving incendiary bullet, civilian, sequela',
  'Y37330A Military operations involving flamethrower, military personnel, initial encounter',
  'Y37330D Military operations involving flamethrower, military personnel, subsequent encounter',
  'Y37330S Military operations involving flamethrower, military personnel, sequela',
  'Y37331A Military operations involving flamethrower, civilian, initial encounter',
  'Y37331D Military operations involving flamethrower, civilian, subsequent encounter',
  'Y37331S Military operations involving flamethrower, civilian, sequela',
  'Y37390A Military operations involving other fires, conflagrations and hot substances, military personnel,',
  'initial encounter',
  'Y37390D Military operations involving other fires, conflagrations and hot substances, military personnel,',
  'subsequent encounter',
  'Y37390S Military operations involving other fires, conflagrations and hot substances, military personnel,',
  'sequela',
  'Y37391A Military operations involving other fires, conflagrations and hot substances, civilian, initial',
  'encounter',
  'Y37391D Military operations involving other fires, conflagrations and hot substances, civilian,',
  'subsequent encounter',
  'Y37391S Military operations involving other fires, conflagrations and hot substances, civilian, sequela',
  'Y37410A Military operations involving rubber bullets, military personnel, initial encounter',
  'Y37410D Military operations involving rubber bullets, military personnel, subsequent encounter',
  'Y37410S Military operations involving rubber bullets, military personnel, sequela',
  'Y37411A Military operations involving rubber bullets, civilian, initial encounter',
  'Y37411D Military operations involving rubber bullets, civilian, subsequent encounter',
  'Y37411S Military operations involving rubber bullets, civilian, sequela',
  'Y37420A Military operations involving firearms pellets, military personnel, initial encounter',
  'Y37420D Military operations involving firearms pellets, military personnel, subsequent encounter',
  'Y37420S Military operations involving firearms pellets, military personnel, sequela',
  'Y37421A Military operations involving firearms pellets, civilian, initial encounter',
  'Y37421D Military operations involving firearms pellets, civilian, subsequent encounter',
  'Y37421S Military operations involving firearms pellets, civilian, sequela',
  'Y37430A Military operations involving other firearms discharge, military personnel, initial encounter',
  'Y37430D Military operations involving other firearms discharge, military personnel, subsequent',
  'encounter',
  'Y37430S Military operations involving other firearms discharge, military personnel, sequela',
  'Y37431A Military operations involving other firearms discharge, civilian, initial encounter',
  'Y37431D Military operations involving other firearms discharge, civilian, subsequent encounter',
  'Y37431S Military operations involving other firearms discharge, civilian, sequela',
  'Y37440A Military operations involving unarmed hand to hand combat, military personnel, initial',
  'encounter',
  'Y37440D Military operations involving unarmed hand to hand combat, military personnel, subsequent',
  'encounter',
  'Y37440S Military operations involving unarmed hand to hand combat, military personnel, sequela',
  'Y37441A Military operations involving unarmed hand to hand combat, civilian, initial encounter',
  'Y37441D Military operations involving unarmed hand to hand combat, civilian, subsequent encounter',
  'Y37441S Military operations involving unarmed hand to hand combat, civilian, sequela',
  'Y37450A Military operations involving combat using blunt or piercing object, military personnel, initial',
  'encounter',
  'Y37450D Military operations involving combat using blunt or piercing object, military personnel,',
  'subsequent encounter',
  'Y37450S Military operations involving combat using blunt or piercing object, military personnel, sequela',
  'Y37451A Military operations involving combat using blunt or piercing object, civilian, initial encounter',
  'Y37451D Military operations involving combat using blunt or piercing object, civilian, subsequent',
  'encounter',
  'Y37451S Military operations involving combat using blunt or piercing object, civilian, sequela',
  'Y37460A Military operations involving intentional restriction of air and airway, military personnel, initial',
  'encounter',
  'Y37460D Military operations involving intentional restriction of air and airway, military personnel,',
  'subsequent encounter',
  'Y37460S Military operations involving intentional restriction of air and airway, military personnel,',
  'sequela',
  'Y37461A Military operations involving intentional restriction of air and airway, civilian, initial encounter',
  'Y37461D Military operations involving intentional restriction of air and airway, civilian, subsequent',
  'encounter',
  'Y37461S Military operations involving intentional restriction of air and airway, civilian, sequela',
  'Y37470A Military operations involving unintentional restriction of air and airway, military personnel,',
  'initial encounter',
  'Y37470D Military operations involving unintentional restriction of air and airway, military personnel,',
  'subsequent encounter',
  'Y37470S Military operations involving unintentional restriction of air and airway, military personnel,',
  'sequela',
  'Y37471A Military operations involving unintentional restriction of air and airway, civilian, initial',
  'encounter',
  'Y37471D Military operations involving unintentional restriction of air and airway, civilian, subsequent',
  'encounter',
  'Y37471S Military operations involving unintentional restriction of air and airway, civilian, sequela',
  'Y37490A Military operations involving other forms of conventional warfare, military personnel, initial',
  'encounter',
  'Y37490D Military operations involving other forms of conventional warfare, military personnel,',
  'subsequent encounter',
  'Y37490S Military operations involving other forms of conventional warfare, military personnel, sequela',
  'Y37491A Military operations involving other forms of conventional warfare, civilian, initial encounter',
  'Y37491D Military operations involving other forms of conventional warfare, civilian, subsequent',
  'encounter',
  'Y37491S Military operations involving other forms of conventional warfare, civilian, sequela',
  'Y37500A Military operations involving unspecified effect of nuclear weapon, military personnel, initial',
  'encounter',
  'Y37500D Military operations involving unspecified effect of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y37500S Military operations involving unspecified effect of nuclear weapon, military personnel, sequela',
  'Y37501A Military operations involving unspecified effect of nuclear weapon, civilian, initial encounter',
  'Y37501D Military operations involving unspecified effect of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y37501S Military operations involving unspecified effect of nuclear weapon, civilian, sequela',
  'Y37510A Military operations involving direct blast effect of nuclear weapon, military personnel, initial',
  'encounter',
  'Y37510D Military operations involving direct blast effect of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y37510S Military operations involving direct blast effect of nuclear weapon, military personnel, sequela',
  'Y37511A Military operations involving direct blast effect of nuclear weapon, civilian, initial encounter',
  'Y37511D Military operations involving direct blast effect of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y37511S Military operations involving direct blast effect of nuclear weapon, civilian, sequela',
  'Y37520A Military operations involving indirect blast effect of nuclear weapon, military personnel, initial',
  'encounter',
  'Y37520D Military operations involving indirect blast effect of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y37520S Military operations involving indirect blast effect of nuclear weapon, military personnel,',
  'sequela',
  'Y37521A Military operations involving indirect blast effect of nuclear weapon, civilian, initial encounter',
  'Y37521D Military operations involving indirect blast effect of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y37521S Military operations involving indirect blast effect of nuclear weapon, civilian, sequela',
  'Y37530A Military operations involving thermal radiation effect of nuclear weapon, military personnel,',
  'initial encounter',
  'Y37530D Military operations involving thermal radiation effect of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y37530S Military operations involving thermal radiation effect of nuclear weapon, military personnel,',
  'sequela',
  'Y37531A Military operations involving thermal radiation effect of nuclear weapon, civilian, initial',
  'encounter',
  'Y37531D Military operations involving thermal radiation effect of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y37531S Military operations involving thermal radiation effect of nuclear weapon, civilian, sequela',
  'Y37540A Military operation involving nuclear radiation effects of nuclear weapon, military personnel,',
  'initial encounter',
  'Y37540D Military operation involving nuclear radiation effects of nuclear weapon, military personnel,',
  'subsequent encounter',
  'Y37540S Military operation involving nuclear radiation effects of nuclear weapon, military personnel,',
  'sequela',
  'Y37541A Military operation involving nuclear radiation effects of nuclear weapon, civilian, initial',
  'encounter',
  'Y37541D Military operation involving nuclear radiation effects of nuclear weapon, civilian, subsequent',
  'encounter',
  'Y37541S Military operation involving nuclear radiation effects of nuclear weapon, civilian, sequela',
  'Y37590A Military operation involving other effects of nuclear weapons, military personnel, initial',
  'encounter',
  'Y37590D Military operation involving other effects of nuclear weapons, military personnel, subsequent',
  'encounter',
  'Y37590S Military operation involving other effects of nuclear weapons, military personnel, sequela',
  'Y37591A Military operation involving other effects of nuclear weapons, civilian, initial encounter',
  'Y37591D Military operation involving other effects of nuclear weapons, civilian, subsequent encounter',
  'Y37591S Military operation involving other effects of nuclear weapons, civilian, sequela',
  'Y376X0A Military operations involving biological weapons, military personnel, initial encounter',
  'Y376X0D Military operations involving biological weapons, military personnel, subsequent encounter',
  'Y376X0S Military operations involving biological weapons, military personnel, sequela',
  'Y376X1A Military operations involving biological weapons, civilian, initial encounter',
  'Y376X1D Military operations involving biological weapons, civilian, subsequent encounter',
  'Y376X1S Military operations involving biological weapons, civilian, sequela',
  'Y377X0A Military operations involving chemical weapons and other forms of unconventional warfare,',
  'military personnel, initial encounter',
  'Y377X0D Military operations involving chemical weapons and other forms of unconventional warfare,',
  'military personnel, subsequent encounter',
  'Y377X0S Military operations involving chemical weapons and other forms of unconventional warfare,',
  'military personnel, sequela',
  'Y377X1A Military operations involving chemical weapons and other forms of unconventional warfare,',
  'civilian, initial encounter',
  'Y377X1D Military operations involving chemical weapons and other forms of unconventional warfare,',
  'civilian, subsequent encounter',
  'Y377X1S Military operations involving chemical weapons and other forms of unconventional warfare,',
  'civilian, sequela',
  'Y3790XA Military operations, unspecified, initial encounter',
  'Y3790XD Military operations, unspecified, subsequent encounter',
  'Y3790XS Military operations, unspecified, sequela',
  'Y3791XA Military operations involving unspecified weapon of mass destruction [WMD], initial encounter',
  'Y3791XD Military operations involving unspecified weapon of mass destruction [WMD], subsequent',
  'encounter',
  'Y3791XS Military operations involving unspecified weapon of mass destruction [WMD], sequela',
  'Y3792XA Military operations involving friendly fire, initial encounter',
  'Y3792XD Military operations involving friendly fire, subsequent encounter',
  'Y3792XS Military operations involving friendly fire, sequela',
  'Y380X1A Terrorism involving explosion of marine weapons, public safety official injured, initial',
  'encounter',
  'Y380X1D Terrorism involving explosion of marine weapons, public safety official injured, subsequent',
  'encounter',
  'Y380X1S Terrorism involving explosion of marine weapons, public safety official injured, sequela',
  'Y380X2A Terrorism involving explosion of marine weapons, civilian injured, initial encounter',
  'Y380X2D Terrorism involving explosion of marine weapons, civilian injured, subsequent encounter',
  'Y380X2S Terrorism involving explosion of marine weapons, civilian injured, sequela',
  'Y380X3A Terrorism involving explosion of marine weapons, terrorist injured, initial encounter',
  'Y380X3D Terrorism involving explosion of marine weapons, terrorist injured, subsequent encounter',
  'Y380X3S Terrorism involving explosion of marine weapons, terrorist injured, sequela',
  'Y381X1A Terrorism involving destruction of aircraft, public safety official injured, initial encounter',
  'Y381X1D Terrorism involving destruction of aircraft, public safety official injured, subsequent encounter',
  'Y381X1S Terrorism involving destruction of aircraft, public safety official injured, sequela',
  'Y381X2A Terrorism involving destruction of aircraft, civilian injured, initial encounter',
  'Y381X2D Terrorism involving destruction of aircraft, civilian injured, subsequent encounter',
  'Y381X2S Terrorism involving destruction of aircraft, civilian injured, sequela',
  'Y381X3A Terrorism involving destruction of aircraft, terrorist injured, initial encounter',
  'Y381X3D Terrorism involving destruction of aircraft, terrorist injured, subsequent encounter',
  'Y381X3S Terrorism involving destruction of aircraft, terrorist injured, sequela',
  'Y382X1A Terrorism involving other explosions and fragments, public safety official injured, initial',
  'encounter',
  'Y382X1D Terrorism involving other explosions and fragments, public safety official injured, subsequent',
  'encounter',
  'Y382X1S Terrorism involving other explosions and fragments, public safety official injured, sequela',
  'Y382X2A Terrorism involving other explosions and fragments, civilian injured, initial encounter',
  'Y382X2D Terrorism involving other explosions and fragments, civilian injured, subsequent encounter',
  'Y382X2S Terrorism involving other explosions and fragments, civilian injured, sequela',
  'Y382X3A Terrorism involving other explosions and fragments, terrorist injured, initial encounter',
  'Y382X3D Terrorism involving other explosions and fragments, terrorist injured, subsequent encounter',
  'Y382X3S Terrorism involving other explosions and fragments, terrorist injured, sequela',
  'Y383X1A Terrorism involving fires, conflagration and hot substances, public safety official injured, initial',
  'encounter',
  'Y383X1D Terrorism involving fires, conflagration and hot substances, public safety official injured,',
  'subsequent encounter',
  'Y383X1S Terrorism involving fires, conflagration and hot substances, public safety official injured,',
  'sequela',
  'Y383X2A Terrorism involving fires, conflagration and hot substances, civilian injured, initial encounter',
  'Y383X2D Terrorism involving fires, conflagration and hot substances, civilian injured, subsequent',
  'encounter',
  'Y383X2S Terrorism involving fires, conflagration and hot substances, civilian injured, sequela',
  'Y383X3A Terrorism involving fires, conflagration and hot substances, terrorist injured, initial encounter',
  'Y383X3D Terrorism involving fires, conflagration and hot substances, terrorist injured, subsequent',
  'encounter',
  'Y383X3S Terrorism involving fires, conflagration and hot substances, terrorist injured, sequela',
  'Y384X1A Terrorism involving firearms, public safety official injured, initial encounter',
  'Y384X1D Terrorism involving firearms, public safety official injured, subsequent encounter',
  'Y384X1S Terrorism involving firearms, public safety official injured, sequela',
  'Y384X2A Terrorism involving firearms, civilian injured, initial encounter',
  'Y384X2D Terrorism involving firearms, civilian injured, subsequent encounter',
  'Y384X2S Terrorism involving firearms, civilian injured, sequela',
  'Y384X3A Terrorism involving firearms, terrorist injured, initial encounter',
  'Y384X3D Terrorism involving firearms, terrorist injured, subsequent encounter',
  'Y384X3S Terrorism involving firearms, terrorist injured, sequela',
  'Y385X1A Terrorism involving nuclear weapons, public safety official injured, initial encounter',
  'Y385X1D Terrorism involving nuclear weapons, public safety official injured, subsequent encounter',
  'Y385X1S Terrorism involving nuclear weapons, public safety official injured, sequela',
  'Y385X2A Terrorism involving nuclear weapons, civilian injured, initial encounter',
  'Y385X2D Terrorism involving nuclear weapons, civilian injured, subsequent encounter',
  'Y385X2S Terrorism involving nuclear weapons, civilian injured, sequela',
  'Y385X3A Terrorism involving nuclear weapons, terrorist injured, initial encounter',
  'Y385X3D Terrorism involving nuclear weapons, terrorist injured, subsequent encounter',
  'Y385X3S Terrorism involving nuclear weapons, terrorist injured, sequela',
  'Y386X1A Terrorism involving biological weapons, public safety official injured, initial encounter',
  'Y386X1D Terrorism involving biological weapons, public safety official injured, subsequent encounter',
  'Y386X1S Terrorism involving biological weapons, public safety official injured, sequela',
  'Y386X2A Terrorism involving biological weapons, civilian injured, initial encounter',
  'Y386X2D Terrorism involving biological weapons, civilian injured, subsequent encounter',
  'Y386X2S Terrorism involving biological weapons, civilian injured, sequela',
  'Y386X3A Terrorism involving biological weapons, terrorist injured, initial encounter',
  'Y386X3D Terrorism involving biological weapons, terrorist injured, subsequent encounter',
  'Y386X3S Terrorism involving biological weapons, terrorist injured, sequela',
  'Y387X1A Terrorism involving chemical weapons, public safety official injured, initial encounter',
  'Y387X1D Terrorism involving chemical weapons, public safety official injured, subsequent encounter',
  'Y387X1S Terrorism involving chemical weapons, public safety official injured, sequela',
  'Y387X2A Terrorism involving chemical weapons, civilian injured, initial encounter',
  'Y387X2D Terrorism involving chemical weapons, civilian injured, subsequent encounter',
  'Y387X2S Terrorism involving chemical weapons, civilian injured, sequela',
  'Y387X3A Terrorism involving chemical weapons, terrorist injured, initial encounter',
  'Y387X3D Terrorism involving chemical weapons, terrorist injured, subsequent encounter',
  'Y387X3S Terrorism involving chemical weapons, terrorist injured, sequela',
  'Y3880XA Terrorism involving unspecified means, initial encounter',
  'Y3880XD Terrorism involving unspecified means, subsequent encounter',
  'Y3880XS Terrorism involving unspecified means, sequela',
  'Y38811A Terrorism involving suicide bomber, public safety official injured, initial encounter',
  'Y38811D Terrorism involving suicide bomber, public safety official injured, subsequent encounter',
  'Y38811S Terrorism involving suicide bomber, public safety official injured, sequela',
  'Y38812A Terrorism involving suicide bomber, civilian injured, initial encounter',
  'Y38812D Terrorism involving suicide bomber, civilian injured, subsequent encounter',
  'Y38812S Terrorism involving suicide bomber, civilian injured, sequela',
  'Y38891A Terrorism involving other means, public safety official injured, initial encounter',
  'Y38891D Terrorism involving other means, public safety official injured, subsequent encounter',
  'Y38891S Terrorism involving other means, public safety official injured, sequela',
  'Y38892A Terrorism involving other means, civilian injured, initial encounter',
  'Y38892D Terrorism involving other means, civilian injured, subsequent encounter',
  'Y38892S Terrorism involving other means, civilian injured, sequela',
  'Y38893A Terrorism involving other means, terrorist injured, initial encounter',
  'Y38893D Terrorism involving other means, terrorist injured, subsequent encounter',
  'Y38893S Terrorism involving other means, terrorist injured, sequela',
  'Y389X1A Terrorism, secondary effects, public safety official injured, initial encounter',
  'Y389X1D Terrorism, secondary effects, public safety official injured, subsequent encounter',
  'Y389X1S Terrorism, secondary effects, public safety official injured, sequela',
  'Y389X2A Terrorism, secondary effects, civilian injured, initial encounter',
  'Y389X2D Terrorism, secondary effects, civilian injured, subsequent encounter',
  'Y389X2S Terrorism, secondary effects, civilian injured, sequela',
  'Y620 Failure of sterile precautions during surgical operation',
  'Y621 Failure of sterile precautions during infusion or transfusion',
  'Y622 Failure of sterile precautions during kidney dialysis and other perfusion',
  'Y623 Failure of sterile precautions during injection or immunization',
  'Y624 Failure of sterile precautions during endoscopic examination',
  'Y625 Failure of sterile precautions during heart catheterization',
  'Y626 Failure of sterile precautions during aspiration, puncture and other catheterization',
  'Y628 Failure of sterile precautions during other surgical and medical care',
  'Y629 Failure of sterile precautions during unspecified surgical and medical care',
  'Y630 Excessive amount of blood or other fluid given during transfusion or infusion',
  'Y631 Incorrect dilution of fluid used during infusion',
  'Y632 Overdose of radiation given during therapy',
  'Y633 Inadvertent exposure of patient to radiation during medical care',
  'Y634 Failure in dosage in electroshock or insulin-shock therapy',
  'Y635 Inappropriate temperature in local application and packing',
  'Y636 Underdosing and nonadministration of necessary drug, medicament or biological substance',
  'Y638 Failure in dosage during other surgical and medical care',
  'Y639 Failure in dosage during unspecified surgical and medical care',
  'Y640 Contaminated medical or biological substance, transfused or infused',
  'Y641 Contaminated medical or biological substance, injected or used for immunization',
  'Y648 Contaminated medical or biological substance administered by other means',
  'Y649 Contaminated medical or biological substance administered by unspecified means',
  'Y650 Mismatched blood in transfusion',
  'Y651 Wrong fluid used in infusion',
  'Y652 Failure in suture or ligature during surgical operation',
  'Y653 Endotracheal tube wrongly placed during anesthetic procedure',
  'Y654 Failure to introduce or to remove other tube or instrument',
  'Y6551 Performance of wrong procedure (operation) on correct patient',
  'Y6552 Performance of procedure (operation) on patient not scheduled for surgery',
  'Y6553 Performance of correct procedure (operation) on wrong side or body part',
  'Y658 Other specified misadventures during surgical and medical care',
  'Y66 Nonadministration of surgical and medical care',
  'Y69 Unspecified misadventure during surgical and medical care',
  'Y700 Diagnostic and monitoring anesthesiology devices associated with adverse incidents',
  'Y701 Therapeutic (nonsurgical) and rehabilitative anesthesiology devices associated with adverse',
  'incidents',
  'Y702 Prosthetic and other implants, materials and accessory anesthesiology devices associated with',
  'adverse incidents',
  'Y703 Surgical instruments, materials and anesthesiology devices (including sutures) associated with',
  'adverse incidents',
  'Y708 Miscellaneous anesthesiology devices associated with adverse incidents, not elsewhere classified',
  'Y710 Diagnostic and monitoring cardiovascular devices associated with adverse incidents',
  'Y711 Therapeutic (nonsurgical) and rehabilitative cardiovascular devices associated with adverse',
  'incidents',
  'Y712 Prosthetic and other implants, materials and accessory cardiovascular devices associated with',
  'adverse incidents',
  'Y713 Surgical instruments, materials and cardiovascular devices (including sutures) associated with',
  'adverse incidents',
  'Y718 Miscellaneous cardiovascular devices associated with adverse incidents, not elsewhere classified',
  'Y720 Diagnostic and monitoring otorhinolaryngological devices associated with adverse incidents',
  'Y721 Therapeutic (nonsurgical) and rehabilitative otorhinolaryngological devices associated with',
  'adverse incidents',
  'Y722 Prosthetic and other implants, materials and accessory otorhinolaryngological devices associated',
  'with adverse incidents',
  'Y723 Surgical instruments, materials and otorhinolaryngological devices (including sutures) associated',
  'with adverse incidents',
  'Y728 Miscellaneous otorhinolaryngological devices associated with adverse incidents, not elsewhere',
  'classified',
  'Y730 Diagnostic and monitoring gastroenterology and urology devices associated with adverse',
  'incidents',
  'Y731 Therapeutic (nonsurgical) and rehabilitative gastroenterology and urology devices associated',
  'with adverse incidents',
  'Y732 Prosthetic and other implants, materials and accessory gastroenterology and urology devices',
  'associated with adverse incidents',
  'Y733 Surgical instruments, materials and gastroenterology and urology devices (including sutures)',
  'associated with adverse incidents',
  'Y738 Miscellaneous gastroenterology and urology devices associated with adverse incidents, not',
  'elsewhere classified',
  'Y740 Diagnostic and monitoring general hospital and personal-use devices associated with adverse',
  'incidents',
  'Y741 Therapeutic (nonsurgical) and rehabilitative general hospital and personal-use devices associated',
  'with adverse incidents',
  'Y742 Prosthetic and other implants, materials and accessory general hospital and personal-use devices',
  'associated with adverse incidents',
  'Y743 Surgical instruments, materials and general hospital and personal-use devices (including sutures)',
  'associated with adverse incidents',
  'Y748 Miscellaneous general hospital and personal-use devices associated with adverse incidents, not',
  'elsewhere classified',
  'Y750 Diagnostic and monitoring neurological devices associated with adverse incidents',
  'Y751 Therapeutic (nonsurgical) and rehabilitative neurological devices associated with adverse',
  'incidents',
  'Y752 Prosthetic and other implants, materials and neurological devices associated with adverse',
  'incidents',
  'Y753 Surgical instruments, materials and neurological devices (including sutures) associated with',
  'adverse incidents',
  'Y758 Miscellaneous neurological devices associated with adverse incidents, not elsewhere classified',
  'Y760 Diagnostic and monitoring obstetric and gynecological devices associated with adverse incidents',
  'Y761 Therapeutic (nonsurgical) and rehabilitative obstetric and gynecological devices associated with',
  'adverse incidents',
  'Y762 Prosthetic and other implants, materials and accessory obstetric and gynecological devices',
  'associated with adverse incidents',
  'Y763 Surgical instruments, materials and obstetric and gynecological devices (including sutures)',
  'associated with adverse incidents',
  'Y768 Miscellaneous obstetric and gynecological devices associated with adverse incidents, not',
  'elsewhere classified',
  'Y770 Diagnostic and monitoring ophthalmic devices associated with adverse incidents',
  'Y771 Therapeutic (nonsurgical) and rehabilitative ophthalmic devices associated with adverse',
  'incidents',
  'Y772 Prosthetic and other implants, materials and accessory ophthalmic devices associated with',
  'adverse incidents',
  'Y773 Surgical instruments, materials and ophthalmic devices (including sutures) associated with',
  'adverse incidents',
  'Y778 Miscellaneous ophthalmic devices associated with adverse incidents, not elsewhere classified',
  'Y780 Diagnostic and monitoring radiological devices associated with adverse incidents',
  'Y781 Therapeutic (nonsurgical) and rehabilitative radiological devices associated with adverse',
  'incidents',
  'Y782 Prosthetic and other implants, materials and accessory radiological devices associated with',
  'adverse incidents',
  'Y783 Surgical instruments, materials and radiological devices (including sutures) associated with',
  'adverse incidents',
  'Y788 Miscellaneous radiological devices associated with adverse incidents, not elsewhere classified',
  'Y790 Diagnostic and monitoring orthopedic devices associated with adverse incidents',
  'Y791 Therapeutic (nonsurgical) and rehabilitative orthopedic devices associated with adverse incidents',
  'Y792 Prosthetic and other implants, materials and accessory orthopedic devices associated with',
  'adverse incidents',
  'Y793 Surgical instruments, materials and orthopedic devices (including sutures) associated with',
  'adverse incidents',
  'Y798 Miscellaneous orthopedic devices associated with adverse incidents, not elsewhere classified',
  'Y800 Diagnostic and monitoring physical medicine devices associated with adverse incidents',
  'Y801 Therapeutic (nonsurgical) and rehabilitative physical medicine devices associated with adverse',
  'incidents',
  'Y802 Prosthetic and other implants, materials and accessory physical medicine devices associated with',
  'adverse incidents',
  'Y803 Surgical instruments, materials and physical medicine devices (including sutures) associated with',
  'adverse incidents',
  'Y808 Miscellaneous physical medicine devices associated with adverse incidents, not elsewhere',
  'classified',
  'Y810 Diagnostic and monitoring general- and plastic-surgery devices associated with adverse incidents',
  'Y811 Therapeutic (nonsurgical) and rehabilitative general- and plastic-surgery devices associated with',
  'adverse incidents',
  'Y812 Prosthetic and other implants, materials and accessory general- and plastic-surgery devices',
  'associated with adverse incidents',
  'Y813 Surgical instruments, materials and general- and plastic-surgery devices (including sutures)',
  'associated with adverse incidents',
  'Y818 Miscellaneous general- and plastic-surgery devices associated with adverse incidents, not',
  'elsewhere classified',
  'Y828 Other medical devices associated with adverse incidents',
  'Y829 Unspecified medical devices associated with adverse incidents',
  'Y830 Surgical operation with transplant of whole organ as the cause of abnormal reaction of the',
  'patient, or of later complication, without mention of misadventure at the time of the procedure',
  'Y831 Surgical operation with implant of artificial internal device as the cause of abnormal reaction of',
  'the patient, or of later complication, without mention of misadventure at the time of the procedure',
  'Y832 Surgical operation with anastomosis, bypass or graft as the cause of abnormal reaction of the',
  'patient, or of later complication, without mention of misadventure at the time of the procedure',
  'Y833 Surgical operation with formation of external stoma as the cause of abnormal reaction of the',
  'patient, or of later complication, without mention of misadventure at the time of the procedure',
  'Y834 Other reconstructive surgery as the cause of abnormal reaction of the patient, or of later',
  'complication, without mention of misadventure at the time of the procedure',
  'Y835 Amputation of limb(s) as the cause of abnormal reaction of the patient, or of later complication,',
  'without mention of misadventure at the time of the procedure',
  'Y836 Removal of other organ (partial) (total) as the cause of abnormal reaction of the patient, or of',
  'later complication, without mention of misadventure at the time of the procedure',
  'Y838 Other surgical procedures as the cause of abnormal reaction of the patient, or of later',
  'complication, without mention of misadventure at the time of the procedure',
  'Y839 Surgical procedure, unspecified as the cause of abnormal reaction of the patient, or of later',
  'complication, without mention of misadventure at the time of the procedure',
  'Y840 Cardiac catheterization as the cause of abnormal reaction of the patient, or of later complication,',
  'without mention of misadventure at the time of the procedure',
  'Y841 Kidney dialysis as the cause of abnormal reaction of the patient, or of later complication, without',
  'mention of misadventure at the time of the procedure',
  'Y842 Radiological procedure and radiotherapy as the cause of abnormal reaction of the patient, or of',
  'later complication, without mention of misadventure at the time of the procedure',
  'Y843 Shock therapy as the cause of abnormal reaction of the patient, or of later complication, without',
  'mention of misadventure at the time of the procedure',
  'Y844 Aspiration of fluid as the cause of abnormal reaction of the patient, or of later complication,',
  'without mention of misadventure at the time of the procedure',
  'Y845 Insertion of gastric or duodenal sound as the cause of abnormal reaction of the patient, or of',
  'later complication, without mention of misadventure at the time of the procedure',
  'Y846 Urinary catheterization as the cause of abnormal reaction of the patient, or of later complication,',
  'without mention of misadventure at the time of the procedure',
  'Y847 Blood-sampling as the cause of abnormal reaction of the patient, or of later complication,',
  'without mention of misadventure at the time of the procedure',
  'Y848 Other medical procedures as the cause of abnormal reaction of the patient, or of later',
  'complication, without mention of misadventure at the time of the procedure',
  'Y849 Medical procedure, unspecified as the cause of abnormal reaction of the patient, or of later',
  'complication, without mention of misadventure at the time of the procedure',
  'Y900 Blood alcohol level of less than 20 mg/100 ml',
  'Y901 Blood alcohol level of 20-39 mg/100 ml',
  'Y902 Blood alcohol level of 40-59 mg/100 ml',
  'Y903 Blood alcohol level of 60-79 mg/100 ml',
  'Y904 Blood alcohol level of 80-99 mg/100 ml',
  'Y905 Blood alcohol level of 100-119 mg/100 ml',
  'Y906 Blood alcohol level of 120-199 mg/100 ml',
  'Y907 Blood alcohol level of 200-239 mg/100 ml',
  'Y908 Blood alcohol level of 240 mg/100 ml or more',
  'Y909 Presence of alcohol in blood, level not specified',
  'Y92000 Kitchen of unspecified non-institutional (private) residence as the place of occurrence of the',
  'external cause',
  'Y92001 Dining room of unspecified non-institutional (private) residence as the place of occurrence of',
  'the external cause',
  'Y92002 Bathroom of unspecified non-institutional (private) residence single-family (private) house as',
  'the place of occurrence of the external cause',
  'Y92003 Bedroom of unspecified non-institutional (private) residence as the place of occurrence of the',
  'external cause',
  'Y92007 Garden or yard of unspecified non-institutional (private) residence as the place of occurrence of',
  'the external cause',
  'Y92008 Other place in unspecified non-institutional (private) residence as the place of occurrence of the',
  'external cause',
  'Y92009 Unspecified place in unspecified non-institutional (private) residence as the place of occurrence',
  'of the external cause',
  'Y92010 Kitchen of single-family (private) house as the place of occurrence of the external cause',
  'Y92011 Dining room of single-family (private) house as the place of occurrence of the external cause',
  'Y92012 Bathroom of single-family (private) house as the place of occurrence of the external cause',
  'Y92013 Bedroom of single-family (private) house as the place of occurrence of the external cause',
  'Y92014 Private driveway to single-family (private) house as the place of occurrence of the external',
  'cause',
  'Y92015 Private garage of single-family (private) house as the place of occurrence of the external cause',
  'Y92016 Swimming-pool in single-family (private) house or garden as the place of occurrence of the',
  'external cause',
  'Y92017 Garden or yard in single-family (private) house as the place of occurrence of the external cause',
  'Y92018 Other place in single-family (private) house as the place of occurrence of the external cause',
  'Y92019 Unspecified place in single-family (private) house as the place of occurrence of the external',
  'cause',
  'Y92020 Kitchen in mobile home as the place of occurrence of the external cause',
  'Y92021 Dining room in mobile home as the place of occurrence of the external cause',
  'Y92022 Bathroom in mobile home as the place of occurrence of the external cause',
  'Y92023 Bedroom in mobile home as the place of occurrence of the external cause',
  'Y92024 Driveway of mobile home as the place of occurrence of the external cause',
  'Y92025 Garage of mobile home as the place of occurrence of the external cause',
  'Y92026 Swimming-pool of mobile home as the place of occurrence of the external cause',
  'Y92027 Garden or yard of mobile home as the place of occurrence of the external cause',
  'Y92028 Other place in mobile home as the place of occurrence of the external cause',
  'Y92029 Unspecified place in mobile home as the place of occurrence of the external cause',
  'Y92030 Kitchen in apartment as the place of occurrence of the external cause',
  'Y92031 Bathroom in apartment as the place of occurrence of the external cause',
  'Y92032 Bedroom in apartment as the place of occurrence of the external cause',
  'Y92038 Other place in apartment as the place of occurrence of the external cause',
  'Y92039 Unspecified place in apartment as the place of occurrence of the external cause',
  'Y92040 Kitchen in boarding-house as the place of occurrence of the external cause',
  'Y92041 Bathroom in boarding-house as the place of occurrence of the external cause',
  'Y92042 Bedroom in boarding-house as the place of occurrence of the external cause',
  'Y92043 Driveway of boarding-house as the place of occurrence of the external cause',
  'Y92044 Garage of boarding-house as the place of occurrence of the external cause',
  'Y92045 Swimming-pool of boarding-house as the place of occurrence of the external cause',
  'Y92046 Garden or yard of boarding-house as the place of occurrence of the external cause',
  'Y92048 Other place in boarding-house as the place of occurrence of the external cause',
  'Y92049 Unspecified place in boarding-house as the place of occurrence of the external cause',
  'Y92090 Kitchen in other non-institutional residence as the place of occurrence of the external cause',
  'Y92091 Bathroom in other non-institutional residence as the place of occurrence of the external cause',
  'Y92092 Bedroom in other non-institutional residence as the place of occurrence of the external cause',
  'Y92093 Driveway of other non-institutional residence as the place of occurrence of the external cause',
  'Y92094 Garage of other non-institutional residence as the place of occurrence of the external cause',
  'Y92095 Swimming-pool of other non-institutional residence as the place of occurrence of the external',
  'cause',
  'Y92096 Garden or yard of other non-institutional residence as the place of occurrence of the external',
  'cause',
  'Y92098 Other place in other non-institutional residence as the place of occurrence of the external cause',
  'Y92099 Unspecified place in other non-institutional residence as the place of occurrence of the external',
  'cause',
  'Y9210 Unspecified residential institution as the place of occurrence of the external cause',
  "Y92110 Kitchen in children's home and orphanage as the place of occurrence of the external cause",
  "Y92111 Bathroom in children's home and orphanage as the place of occurrence of the external cause",
  "Y92112 Bedroom in children's home and orphanage as the place of occurrence of the external cause",
  "Y92113 Driveway of children's home and orphanage as the place of occurrence of the external cause",
  "Y92114 Garage of children's home and orphanage as the place of occurrence of the external cause",
  "Y92115 Swimming-pool of children's home and orphanage as the place of occurrence of the external",
  'cause',
  "Y92116 Garden or yard of children's home and orphanage as the place of occurrence of the external",
  'cause',
  "Y92118 Other place in children's home and orphanage as the place of occurrence of the external cause",
  "Y92119 Unspecified place in children's home and orphanage as the place of occurrence of the external",
  'cause',
  'Y92120 Kitchen in nursing home as the place of occurrence of the external cause',
  'Y92121 Bathroom in nursing home as the place of occurrence of the external cause',
  'Y92122 Bedroom in nursing home as the place of occurrence of the external cause',
  'Y92123 Driveway of nursing home as the place of occurrence of the external cause',
  'Y92124 Garage of nursing home as the place of occurrence of the external cause',
  'Y92125 Swimming-pool of nursing home as the place of occurrence of the external cause',
  'Y92126 Garden or yard of nursing home as the place of occurrence of the external cause',
  'Y92128 Other place in nursing home as the place of occurrence of the external cause',
  'Y92129 Unspecified place in nursing home as the place of occurrence of the external cause',
  'Y92130 Kitchen on military base as the place of occurrence of the external cause',
  'Y92131 Mess hall on military base as the place of occurrence of the external cause',
  'Y92133 Barracks on military base as the place of occurrence of the external cause',
  'Y92135 Garage on military base as the place of occurrence of the external cause',
  'Y92136 Swimming-pool on military base as the place of occurrence of the external cause',
  'Y92137 Garden or yard on military base as the place of occurrence of the external cause',
  'Y92138 Other place on military base as the place of occurrence of the external cause',
  'Y92139 Unspecified place military base as the place of occurrence of the external cause',
  'Y92140 Kitchen in prison as the place of occurrence of the external cause',
  'Y92141 Dining room in prison as the place of occurrence of the external cause',
  'Y92142 Bathroom in prison as the place of occurrence of the external cause',
  'Y92143 Cell of prison as the place of occurrence of the external cause',
  'Y92146 Swimming-pool of prison as the place of occurrence of the external cause',
  'Y92147 Courtyard of prison as the place of occurrence of the external cause',
  'Y92148 Other place in prison as the place of occurrence of the external cause',
  'Y92149 Unspecified place in prison as the place of occurrence of the external cause',
  'Y92150 Kitchen in reform school as the place of occurrence of the external cause',
  'Y92151 Dining room in reform school as the place of occurrence of the external cause',
  'Y92152 Bathroom in reform school as the place of occurrence of the external cause',
  'Y92153 Bedroom in reform school as the place of occurrence of the external cause',
  'Y92154 Driveway of reform school as the place of occurrence of the external cause',
  'Y92155 Garage of reform school as the place of occurrence of the external cause',
  'Y92156 Swimming-pool of reform school as the place of occurrence of the external cause',
  'Y92157 Garden or yard of reform school as the place of occurrence of the external cause',
  'Y92158 Other place in reform school as the place of occurrence of the external cause',
  'Y92159 Unspecified place in reform school as the place of occurrence of the external cause',
  'Y92160 Kitchen in school dormitory as the place of occurrence of the external cause',
  'Y92161 Dining room in school dormitory as the place of occurrence of the external cause',
  'Y92162 Bathroom in school dormitory as the place of occurrence of the external cause',
  'Y92163 Bedroom in school dormitory as the place of occurrence of the external cause',
  'Y92168 Other place in school dormitory as the place of occurrence of the external cause',
  'Y92169 Unspecified place in school dormitory as the place of occurrence of the external cause',
  'Y92190 Kitchen in other specified residential institution as the place of occurrence of the external cause',
  'Y92191 Dining room in other specified residential institution as the place of occurrence of the external',
  'cause',
  'Y92192 Bathroom in other specified residential institution as the place of occurrence of the external',
  'cause',
  'Y92193 Bedroom in other specified residential institution as the place of occurrence of the external',
  'cause',
  'Y92194 Driveway of other specified residential institution as the place of occurrence of the external',
  'cause',
  'Y92195 Garage of other specified residential institution as the place of occurrence of the external cause',
  'Y92196 Pool of other specified residential institution as the place of occurrence of the external cause',
  'Y92197 Garden or yard of other specified residential institution as the place of occurrence of the',
  'external cause',
  'Y92198 Other place in other specified residential institution as the place of occurrence of the external',
  'cause',
  'Y92199 Unspecified place in other specified residential institution as the place of occurrence of the',
  'external cause',
  'Y92210 Daycare center as the place of occurrence of the external cause',
  'Y92211 Elementary school as the place of occurrence of the external cause',
  'Y92212 Middle school as the place of occurrence of the external cause',
  'Y92213 High school as the place of occurrence of the external cause',
  'Y92214 College as the place of occurrence of the external cause',
  'Y92215 Trade school as the place of occurrence of the external cause',
  'Y92218 Other school as the place of occurrence of the external cause',
  'Y92219 Unspecified school as the place of occurrence of the external cause',
  'Y9222 Religious institution as the place of occurrence of the external cause',
  'Y92230 Patient room in hospital as the place of occurrence of the external cause',
  'Y92231 Patient bathroom in hospital as the place of occurrence of the external cause',
  'Y92232 Corridor of hospital as the place of occurrence of the external cause',
  'Y92233 Cafeteria of hospital as the place of occurrence of the external cause',
  'Y92234 Operating room of hospital as the place of occurrence of the external cause',
  'Y92238 Other place in hospital as the place of occurrence of the external cause',
  'Y92239 Unspecified place in hospital as the place of occurrence of the external cause',
  'Y92240 Courthouse as the place of occurrence of the external cause',
  'Y92241 Library as the place of occurrence of the external cause',
  'Y92242 Post office as the place of occurrence of the external cause',
  'Y92243 City hall as the place of occurrence of the external cause',
  'Y92248 Other public administrative building as the place of occurrence of the external cause',
  'Y92250 Art Gallery as the place of occurrence of the external cause',
  'Y92251 Museum as the place of occurrence of the external cause',
  'Y92252 Music hall as the place of occurrence of the external cause',
  'Y92253 Opera house as the place of occurrence of the external cause',
  'Y92254 Theater (live) as the place of occurrence of the external cause',
  'Y92258 Other cultural public building as the place of occurrence of the external cause',
  'Y9226 Movie house or cinema as the place of occurrence of the external cause',
  'Y9229 Other specified public building as the place of occurrence of the external cause',
  'Y92310 Basketball court as the place of occurrence of the external cause',
  'Y92311 Squash court as the place of occurrence of the external cause',
  'Y92312 Tennis court as the place of occurrence of the external cause',
  'Y92318 Other athletic court as the place of occurrence of the external cause',
  'Y92320 Baseball field as the place of occurrence of the external cause',
  'Y92321 Football field as the place of occurrence of the external cause',
  'Y92322 Soccer field as the place of occurrence of the external cause',
  'Y92328 Other athletic field as the place of occurrence of the external cause',
  'Y92330 Ice skating rink (indoor) (outdoor) as the place of occurrence of the external cause',
  'Y92331 Roller skating rink as the place of occurrence of the external cause',
  'Y9234 Swimming pool (public) as the place of occurrence of the external cause',
  'Y9239 Other specified sports and athletic area as the place of occurrence of the external cause',
  'Y92410 Unspecified street and highway as the place of occurrence of the external cause',
  'Y92411 Interstate highway as the place of occurrence of the external cause',
  'Y92412 Parkway as the place of occurrence of the external cause',
  'Y92413 State road as the place of occurrence of the external cause',
  'Y92414 Local residential or business street as the place of occurrence of the external cause',
  'Y92415 Exit ramp or entrance ramp of street or highway as the place of occurrence of the external',
  'cause',
  'Y92480 Sidewalk as the place of occurrence of the external cause',
  'Y92481 Parking lot as the place of occurrence of the external cause',
  'Y92482 Bike path as the place of occurrence of the external cause',
  'Y92488 Other paved roadways as the place of occurrence of the external cause',
  'Y92510 Bank as the place of occurrence of the external cause',
  'Y92511 Restaurant or cafe as the place of occurrence of the external cause',
  'Y92512 Supermarket, store or market as the place of occurrence of the external cause',
  'Y92513 Shop (commercial) as the place of occurrence of the external cause',
  'Y92520 Airport as the place of occurrence of the external cause',
  'Y92521 Bus station as the place of occurrence of the external cause',
  'Y92522 Railway station as the place of occurrence of the external cause',
  'Y92523 Highway rest stop as the place of occurrence of the external cause',
  'Y92524 Gas station as the place of occurrence of the external cause',
  'Y92530 Ambulatory surgery center as the place of occurrence of the external cause',
  'Y92531 Health care provider office as the place of occurrence of the external cause',
  'Y92532 Urgent care center as the place of occurrence of the external cause',
  'Y92538 Other ambulatory health services establishments as the place of occurrence of the external',
  'cause',
  'Y9259 Other trade areas as the place of occurrence of the external cause',
  'Y9261 Building [any] under construction as the place of occurrence of the external cause',
  'Y9262 Dock or shipyard as the place of occurrence of the external cause',
  'Y9263 Factory as the place of occurrence of the external cause',
  'Y9264 Mine or pit as the place of occurrence of the external cause',
  'Y9265 Oil rig as the place of occurrence of the external cause',
  'Y9269 Other specified industrial and construction area as the place of occurrence of the external cause',
  'Y9271 Barn as the place of occurrence of the external cause',
  'Y9272 Chicken coop as the place of occurrence of the external cause',
  'Y9273 Farm field as the place of occurrence of the external cause',
  'Y9274 Orchard as the place of occurrence of the external cause',
  'Y9279 Other farm location as the place of occurrence of the external cause',
  'Y92810 Car as the place of occurrence of the external cause',
  'Y92811 Bus as the place of occurrence of the external cause',
  'Y92812 Truck as the place of occurrence of the external cause',
  'Y92813 Airplane as the place of occurrence of the external cause',
  'Y92814 Boat as the place of occurrence of the external cause',
  'Y92815 Train as the place of occurrence of the external cause',
  'Y92816 Subway car as the place of occurrence of the external cause',
  'Y92818 Other transport vehicle as the place of occurrence of the external cause',
  'Y92820 Desert as the place of occurrence of the external cause',
  'Y92821 Forest as the place of occurrence of the external cause',
  'Y92828 Other wilderness area as the place of occurrence of the external cause',
  'Y92830 Public park as the place of occurrence of the external cause',
  'Y92831 Amusement park as the place of occurrence of the external cause',
  'Y92832 Beach as the place of occurrence of the external cause',
  'Y92833 Campsite as the place of occurrence of the external cause',
  'Y92834 Zoological garden (Zoo) as the place of occurrence of the external cause',
  'Y92838 Other recreation area as the place of occurrence of the external cause',
  'Y9284 Military training ground as the place of occurrence of the external cause',
  'Y9285 Railroad track as the place of occurrence of the external cause',
  'Y9286 Slaughter house as the place of occurrence of the external cause',
  'Y9289 Other specified places as the place of occurrence of the external cause',
  'Y929 Unspecified place or not applicable',
  'Y9301 Activity, walking, marching and hiking',
  'Y9302 Activity, running',
  'Y9311 Activity, swimming',
  'Y9312 Activity, springboard and platform diving',
  'Y9313 Activity, water polo',
  'Y9314 Activity, water aerobics and water exercise',
  'Y9315 Activity, underwater diving and snorkeling',
  'Y9316 Activity, rowing, canoeing, kayaking, rafting and tubing',
  'Y9317 Activity, water skiing and wake boarding',
  'Y9318 Activity, surfing, windsurfing and boogie boarding',
  'Y9319 Activity, other involving water and watercraft',
  'Y9321 Activity, ice skating',
  'Y9322 Activity, ice hockey',
  'Y9323 Activity, snow (alpine) (downhill) skiing, snow boarding, sledding, tobogganing and snow tubing',
  'Y9324 Activity, cross country skiing',
  'Y9329 Activity, other involving ice and snow',
  'Y9331 Activity, mountain climbing, rock climbing and wall climbing',
  'Y9332 Activity, rappelling',
  'Y9333 Activity, BASE jumping',
  'Y9334 Activity, bungee jumping',
  'Y9335 Activity, hang gliding',
  'Y9339 Activity, other involving climbing, rappelling and jumping off',
  'Y9341 Activity, dancing',
  'Y9342 Activity, yoga',
  'Y9343 Activity, gymnastics',
  'Y9344 Activity, trampolining',
  'Y9345 Activity, cheerleading',
  'Y9349 Activity, other involving dancing and other rhythmic movements',
  'Y9351 Activity, roller skating (inline) and skateboarding',
  'Y9352 Activity, horseback riding',
  'Y9353 Activity, golf',
  'Y9354 Activity, bowling',
  'Y9355 Activity, bike riding',
  'Y9356 Activity, jumping rope',
  'Y9357 Activity, non-running track and field events',
  'Y9359 Activity, other involving other sports and athletics played individually',
  'Y9361 Activity, american tackle football',
  'Y9362 Activity, american flag or touch football',
  'Y9363 Activity, rugby',
  'Y9364 Activity, baseball',
  'Y9365 Activity, lacrosse and field hockey',
  'Y9366 Activity, soccer',
  'Y9367 Activity, basketball',
  'Y9368 Activity, volleyball (beach) (court)',
  'Y936A Activity, physical games generally associated with school recess, summer camp and children',
  'Y9369 Activity, other involving other sports and athletics played as a team or group',
  'Y9371 Activity, boxing',
  'Y9372 Activity, wrestling',
  'Y9373 Activity, racquet and hand sports',
  'Y9374 Activity, frisbee',
  'Y9375 Activity, martial arts',
  'Y9379 Activity, other specified sports and athletics',
  'Y93A1 Activity, exercise machines primarily for cardiorespiratory conditioning',
  'Y93A2 Activity, calisthenics',
  'Y93A3 Activity, aerobic and step exercise',
  'Y93A4 Activity, circuit training',
  'Y93A5 Activity, obstacle course',
  'Y93A6 Activity, grass drills',
  'Y93A9 Activity, other involving cardiorespiratory exercise',
  'Y93B1 Activity, exercise machines primarily for muscle strengthening',
  'Y93B2 Activity, push-ups, pull-ups, sit-ups',
  'Y93B3 Activity, free weights',
  'Y93B4 Activity, pilates',
  'Y93B9 Activity, other involving muscle strengthening exercises',
  'Y93C1 Activity, computer keyboarding',
  'Y93C2 Activity, hand held interactive electronic device',
  'Y93C9 Activity, other involving computer technology and electronic devices',
  'Y93D1 Activity, knitting and crocheting',
  'Y93D2 Activity, sewing',
  'Y93D3 Activity, furniture building and finishing',
  'Y93D9 Activity, other involving arts and handcrafts',
  'Y93E1 Activity, personal bathing and showering',
  'Y93E2 Activity, laundry',
  'Y93E3 Activity, vacuuming',
  'Y93E4 Activity, ironing',
  'Y93E5 Activity, floor mopping and cleaning',
  'Y93E6 Activity, residential relocation',
  'Y93E8 Activity, other personal hygiene',
  'Y93E9 Activity, other interior property and clothing maintenance',
  'Y93F1 Activity, caregiving, bathing',
  'Y93F2 Activity, caregiving, lifting',
  'Y93F9 Activity, other caregiving',
  'Y93G1 Activity, food preparation and clean up',
  'Y93G2 Activity, grilling and smoking food',
  'Y93G3 Activity, cooking and baking',
  'Y93G9 Activity, other involving cooking and grilling',
  'Y93H1 Activity, digging, shoveling and raking',
  'Y93H2 Activity, gardening and landscaping',
  'Y93H3 Activity, building and construction',
  'Y93H9 Activity, other involving exterior property and land maintenance, building and construction',
  'Y93I1 Activity, roller coaster riding',
  'Y93I9 Activity, other involving external motion',
  'Y93J1 Activity, piano playing',
  'Y93J2 Activity, drum and other percussion instrument playing',
  'Y93J3 Activity, string instrument playing',
  'Y93J4 Activity, winds and brass instrument playing',
  'Y93K1 Activity, walking an animal',
  'Y93K2 Activity, milking an animal',
  'Y93K3 Activity, grooming and shearing an animal',
  'Y93K9 Activity, other involving animal care',
  'Y9381 Activity, refereeing a sports activity',
  'Y9382 Activity, spectator at an event',
  'Y9383 Activity, rough housing and horseplay',
  'Y9384 Activity, sleeping',
  'Y9385 Activity, choking game',
  'Y9389 Activity, other specified',
  'Y939 Activity, unspecified',
  'Y95 Nosocomial condition',
  'Y990 Civilian activity done for income or pay',
  'Y991 Military activity',
  'Y992 Volunteer activity',
  'Y998 Other external cause status',
  'Y999 Unspecified external cause status',
  'Z0000 Encounter for general adult medical examination without abnormal findings',
  'Z0001 Encounter for general adult medical examination with abnormal findings',
  'Z00110 Health examination for newborn under 8 days old',
  'Z00111 Health examination for newborn 8 to 28 days old',
  'Z00121 Encounter for routine child health examination with abnormal findings',
  'Z00129 Encounter for routine child health examination without abnormal findings',
  'Z002 Encounter for examination for period of rapid growth in childhood',
  'Z003 Encounter for examination for adolescent development state',
  'Z005 Encounter for examination of potential donor of organ and tissue',
  'Z006 Encounter for examination for normal comparison and control in clinical research program',
  'Z0070 Encounter for examination for period of delayed growth in childhood without abnormal findings',
  'Z0071 Encounter for examination for period of delayed growth in childhood with abnormal findings',
  'Z008 Encounter for other general examination',
  'Z0100 Encounter for examination of eyes and vision without abnormal findings',
  'Z0101 Encounter for examination of eyes and vision with abnormal findings',
  'Z0110 Encounter for examination of ears and hearing without abnormal findings',
  'Z01110 Encounter for hearing examination following failed hearing screening',
  'Z01118 Encounter for examination of ears and hearing with other abnormal findings',
  'Z0112 Encounter for hearing conservation and treatment',
  'Z0120 Encounter for dental examination and cleaning without abnormal findings',
  'Z0121 Encounter for dental examination and cleaning with abnormal findings',
  'Z0130 Encounter for examination of blood pressure without abnormal findings',
  'Z0131 Encounter for examination of blood pressure with abnormal findings',
  'Z01411 Encounter for gynecological examination (general) (routine) with abnormal findings',
  'Z01419 Encounter for gynecological examination (general) (routine) without abnormal findings',
  'Z0142 Encounter for cervical smear to confirm findings of recent normal smear following initial',
  'abnormal smear',
  'Z01810 Encounter for preprocedural cardiovascular examination',
  'Z01811 Encounter for preprocedural respiratory examination',
  'Z01812 Encounter for preprocedural laboratory examination',
  'Z01818 Encounter for other preprocedural examination',
  'Z0182 Encounter for allergy testing',
  'Z0183 Encounter for blood typing',
  'Z0184 Encounter for antibody response examination',
  'Z0189 Encounter for other specified special examinations',
  'Z020 Encounter for examination for admission to educational institution',
  'Z021 Encounter for pre-employment examination',
  'Z022 Encounter for examination for admission to residential institution',
  'Z023 Encounter for examination for recruitment to armed forces',
  'Z024 Encounter for examination for driving license',
  'Z025 Encounter for examination for participation in sport',
  'Z026 Encounter for examination for insurance purposes',
  'Z0271 Encounter for disability determination',
  'Z0279 Encounter for issue of other medical certificate',
  'Z0281 Encounter for paternity testing',
  'Z0282 Encounter for adoption services',
  'Z0283 Encounter for blood-alcohol and blood-drug test',
  'Z0289 Encounter for other administrative examinations',
  'Z029 Encounter for administrative examinations, unspecified',
  'Z036 Encounter for observation for suspected toxic effect from ingested substance ruled out',
  'Z0371 Encounter for suspected problem with amniotic cavity and membrane ruled out',
  'Z0372 Encounter for suspected placental problem ruled out',
  'Z0373 Encounter for suspected fetal anomaly ruled out',
  'Z0374 Encounter for suspected problem with fetal growth ruled out',
  'Z0375 Encounter for suspected cervical shortening ruled out',
  'Z0379 Encounter for other suspected maternal and fetal conditions ruled out',
  'Z03810 Encounter for observation for suspected exposure to anthrax ruled out',
  'Z03818 Encounter for observation for suspected exposure to other biological agents ruled out',
  'Z0389 Encounter for observation for other suspected diseases and conditions ruled out',
  'Z041 Encounter for examination and observation following transport accident',
  'Z042 Encounter for examination and observation following work accident',
  'Z043 Encounter for examination and observation following other accident',
  'Z0441 Encounter for examination and observation following alleged adult rape',
  'Z0442 Encounter for examination and observation following alleged child rape',
  'Z046 Encounter for general psychiatric examination, requested by authority',
  'Z0471 Encounter for examination and observation following alleged adult physical abuse',
  'Z0472 Encounter for examination and observation following alleged child physical abuse',
  'Z048 Encounter for examination and observation for other specified reasons',
  'Z049 Encounter for examination and observation for unspecified reason',
  'Z050 Observation and evaluation of newborn for suspected cardiac condition ruled out',
  'Z051 Observation and evaluation of newborn for suspected infectious condition ruled out',
  'Z052 Observation and evaluation of newborn for suspected neurological condition ruled out',
  'Z053 Observation and evaluation of newborn for suspected respiratory condition ruled out',
  'Z0541 Observation and evaluation of newborn for suspected genetic condition ruled out',
  'Z0542 Observation and evaluation of newborn for suspected metabolic condition ruled out',
  'Z0543 Observation and evaluation of newborn for suspected immunologic condition ruled out',
  'Z055 Observation and evaluation of newborn for suspected gastrointestinal condition ruled out',
  'Z056 Observation and evaluation of newborn for suspected genitourinary condition ruled out',
  'Z0571 Observation and evaluation of newborn for suspected skin and subcutaneous tissue condition',
  'ruled out',
  'Z0572 Observation and evaluation of newborn for suspected musculoskeletal condition ruled out',
  'Z0573 Observation and evaluation of newborn for suspected connective tissue condition ruled out',
  'Z058 Observation and evaluation of newborn for other specified suspected condition ruled out',
  'Z059 Observation and evaluation of newborn for unspecified suspected condition ruled out',
  'Z08 Encounter for follow-up examination after completed treatment for malignant neoplasm',
  'Z09 Encounter for follow-up examination after completed treatment for conditions other than',
  'malignant neoplasm',
  'Z110 Encounter for screening for intestinal infectious diseases',
  'Z111 Encounter for screening for respiratory tuberculosis',
  'Z112 Encounter for screening for other bacterial diseases',
  'Z113 Encounter for screening for infections with a predominantly sexual mode of transmission',
  'Z114 Encounter for screening for human immunodeficiency virus [HIV]',
  'Z1151 Encounter for screening for human papillomavirus (HPV)',
  'Z1159 Encounter for screening for other viral diseases',
  'Z116 Encounter for screening for other protozoal diseases and helminthiases',
  'Z118 Encounter for screening for other infectious and parasitic diseases',
  'Z119 Encounter for screening for infectious and parasitic diseases, unspecified',
  'Z120 Encounter for screening for malignant neoplasm of stomach',
  'Z1210 Encounter for screening for malignant neoplasm of intestinal tract, unspecified',
  'Z1211 Encounter for screening for malignant neoplasm of colon',
  'Z1212 Encounter for screening for malignant neoplasm of rectum',
  'Z1213 Encounter for screening for malignant neoplasm of small intestine',
  'Z122 Encounter for screening for malignant neoplasm of respiratory organs',
  'Z1231 Encounter for screening mammogram for malignant neoplasm of breast',
  'Z1239 Encounter for other screening for malignant neoplasm of breast',
  'Z124 Encounter for screening for malignant neoplasm of cervix',
  'Z125 Encounter for screening for malignant neoplasm of prostate',
  'Z126 Encounter for screening for malignant neoplasm of bladder',
  'Z1271 Encounter for screening for malignant neoplasm of testis',
  'Z1272 Encounter for screening for malignant neoplasm of vagina',
  'Z1273 Encounter for screening for malignant neoplasm of ovary',
  'Z1279 Encounter for screening for malignant neoplasm of other genitourinary organs',
  'Z1281 Encounter for screening for malignant neoplasm of oral cavity',
  'Z1282 Encounter for screening for malignant neoplasm of nervous system',
  'Z1283 Encounter for screening for malignant neoplasm of skin',
  'Z1289 Encounter for screening for malignant neoplasm of other sites',
  'Z129 Encounter for screening for malignant neoplasm, site unspecified',
  'Z130 Encounter for screening for diseases of the blood and blood-forming organs and certain disorders',
  'involving the immune mechanism',
  'Z131 Encounter for screening for diabetes mellitus',
  'Z1321 Encounter for screening for nutritional disorder',
  'Z13220 Encounter for screening for lipoid disorders',
  'Z13228 Encounter for screening for other metabolic disorders',
  'Z1329 Encounter for screening for other suspected endocrine disorder',
  'Z134 Encounter for screening for certain developmental disorders in childhood',
  'Z135 Encounter for screening for eye and ear disorders',
  'Z136 Encounter for screening for cardiovascular disorders',
  'Z1371 Encounter for nonprocreative screening for genetic disease carrier status',
  'Z1379 Encounter for other screening for genetic and chromosomal anomalies',
  'Z13810 Encounter for screening for upper gastrointestinal disorder',
  'Z13811 Encounter for screening for lower gastrointestinal disorder',
  'Z13818 Encounter for screening for other digestive system disorders',
  'Z13820 Encounter for screening for osteoporosis',
  'Z13828 Encounter for screening for other musculoskeletal disorder',
  'Z1383 Encounter for screening for respiratory disorder NEC',
  'Z1384 Encounter for screening for dental disorders',
  'Z13850 Encounter for screening for traumatic brain injury',
  'Z13858 Encounter for screening for other nervous system disorders',
  'Z1388 Encounter for screening for disorder due to exposure to contaminants',
  'Z1389 Encounter for screening for other disorder',
  'Z139 Encounter for screening, unspecified',
  'Z1401 Asymptomatic hemophilia A carrier',
  'Z1402 Symptomatic hemophilia A carrier',
  'Z141 Cystic fibrosis carrier',
  'Z148 Genetic carrier of other disease',
  'Z1501 Genetic susceptibility to malignant neoplasm of breast',
  'Z1502 Genetic susceptibility to malignant neoplasm of ovary',
  'Z1503 Genetic susceptibility to malignant neoplasm of prostate',
  'Z1504 Genetic susceptibility to malignant neoplasm of endometrium',
  'Z1509 Genetic susceptibility to other malignant neoplasm',
  'Z1581 Genetic susceptibility to multiple endocrine neoplasia [MEN]',
  'Z1589 Genetic susceptibility to other disease',
  'Z1610 Resistance to unspecified beta lactam antibiotics',
  'Z1611 Resistance to penicillins',
  'Z1612 Extended spectrum beta lactamase (ESBL) resistance',
  'Z1619 Resistance to other specified beta lactam antibiotics',
  'Z1620 Resistance to unspecified antibiotic',
  'Z1621 Resistance to vancomycin',
  'Z1622 Resistance to vancomycin related antibiotics',
  'Z1623 Resistance to quinolones and fluoroquinolones',
  'Z1624 Resistance to multiple antibiotics',
  'Z1629 Resistance to other single specified antibiotic',
  'Z1630 Resistance to unspecified antimicrobial drugs',
  'Z1631 Resistance to antiparasitic drug(s)',
  'Z1632 Resistance to antifungal drug(s)',
  'Z1633 Resistance to antiviral drug(s)',
  'Z16341 Resistance to single antimycobacterial drug',
  'Z16342 Resistance to multiple antimycobacterial drugs',
  'Z1635 Resistance to multiple antimicrobial drugs',
  'Z1639 Resistance to other specified antimicrobial drug',
  'Z170 Estrogen receptor positive status [ER+]',
  'Z171 Estrogen receptor negative status [ER-]',
  'Z1801 Retained depleted uranium fragments',
  'Z1809 Other retained radioactive fragments',
  'Z1810 Retained metal fragments, unspecified',
  'Z1811 Retained magnetic metal fragments',
  'Z1812 Retained nonmagnetic metal fragments',
  'Z182 Retained plastic fragments',
  'Z1831 Retained animal quills or spines',
  'Z1832 Retained tooth',
  'Z1833 Retained wood fragments',
  'Z1839 Other retained organic fragments',
  'Z1881 Retained glass fragments',
  'Z1883 Retained stone or crystalline fragments',
  'Z1889 Other specified retained foreign body fragments',
  'Z189 Retained foreign body fragments, unspecified material',
  'Z191 Hormone sensitive malignancy status',
  'Z192 Hormone resistant malignancy status',
  'Z2001 Contact with and (suspected) exposure to intestinal infectious diseases due to Escherichia coli (E.',
  'coli)',
  'Z2009 Contact with and (suspected) exposure to other intestinal infectious diseases',
  'Z201 Contact with and (suspected) exposure to tuberculosis',
  'Z202 Contact with and (suspected) exposure to infections with a predominantly sexual mode of',
  'transmission',
  'Z203 Contact with and (suspected) exposure to rabies',
  'Z204 Contact with and (suspected) exposure to rubella',
  'Z205 Contact with and (suspected) exposure to viral hepatitis',
  'Z206 Contact with and (suspected) exposure to human immunodeficiency virus [HIV]',
  'Z207 Contact with and (suspected) exposure to pediculosis, acariasis and other infestations',
  'Z20810 Contact with and (suspected) exposure to anthrax',
  'Z20811 Contact with and (suspected) exposure to meningococcus',
  'Z20818 Contact with and (suspected) exposure to other bacterial communicable diseases',
  'Z20820 Contact with and (suspected) exposure to varicella',
  'Z20828 Contact with and (suspected) exposure to other viral communicable diseases',
  'Z2089 Contact with and (suspected) exposure to other communicable diseases',
  'Z209 Contact with and (suspected) exposure to unspecified communicable disease',
  'Z21 Asymptomatic human immunodeficiency virus [HIV] infection status',
  'Z220 Carrier of typhoid',
  'Z221 Carrier of other intestinal infectious diseases',
  'Z222 Carrier of diphtheria',
  'Z2231 Carrier of bacterial disease due to meningococci',
  'Z22321 Carrier or suspected carrier of Methicillin susceptible Staphylococcus aureus',
  'Z22322 Carrier or suspected carrier of Methicillin resistant Staphylococcus aureus',
  'Z22330 Carrier of Group B streptococcus',
  'Z22338 Carrier of other streptococcus',
  'Z2239 Carrier of other specified bacterial diseases',
  'Z224 Carrier of infections with a predominantly sexual mode of transmission',
  'Z226 Carrier of human T-lymphotropic virus type-1 [HTLV-1] infection',
  'Z228 Carrier of other infectious diseases',
  'Z229 Carrier of infectious disease, unspecified',
  'Z23 Encounter for immunization',
  'Z2801 Immunization not carried out because of acute illness of patient',
  'Z2802 Immunization not carried out because of chronic illness or condition of patient',
  'Z2803 Immunization not carried out because of immune compromised state of patient',
  'Z2804 Immunization not carried out because of patient allergy to vaccine or component',
  'Z2809 Immunization not carried out because of other contraindication',
  'Z281 Immunization not carried out because of patient decision for reasons of belief or group pressure',
  'Z2820 Immunization not carried out because of patient decision for unspecified reason',
  'Z2821 Immunization not carried out because of patient refusal',
  'Z2829 Immunization not carried out because of patient decision for other reason',
  'Z283 Underimmunization status',
  'Z2881 Immunization not carried out due to patient having had the disease',
  'Z2882 Immunization not carried out because of caregiver refusal',
  'Z2889 Immunization not carried out for other reason',
  'Z289 Immunization not carried out for unspecified reason',
  'Z2911 Encounter for prophylactic immunotherapy for respiratory syncytial virus (RSV)',
  'Z2912 Encounter for prophylactic antivenin',
  'Z2913 Encounter for prophylactic Rho(D) immune globulin',
  'Z2914 Encounter for prophylactic rabies immune globin',
  'Z293 Encounter for prophylactic fluoride administration',
  'Z298 Encounter for other specified prophylactic measures',
  'Z299 Encounter for prophylactic measures, unspecified',
  'Z30011 Encounter for initial prescription of contraceptive pills',
  'Z30012 Encounter for prescription of emergency contraception',
  'Z30013 Encounter for initial prescription of injectable contraceptive',
  'Z30014 Encounter for initial prescription of intrauterine contraceptive device',
  'Z30015 Encounter for initial prescription of vaginal ring hormonal contraceptive',
  'Z30016 Encounter for initial prescription of transdermal patch hormonal contraceptive device',
  'Z30017 Encounter for initial prescription of implantable subdermal contraceptive',
  'Z30018 Encounter for initial prescription of other contraceptives',
  'Z30019 Encounter for initial prescription of contraceptives, unspecified',
  'Z3002 Counseling and instruction in natural family planning to avoid pregnancy',
  'Z3009 Encounter for other general counseling and advice on contraception',
  'Z302 Encounter for sterilization',
  'Z3040 Encounter for surveillance of contraceptives, unspecified',
  'Z3041 Encounter for surveillance of contraceptive pills',
  'Z3042 Encounter for surveillance of injectable contraceptive',
  'Z30430 Encounter for insertion of intrauterine contraceptive device',
  'Z30431 Encounter for routine checking of intrauterine contraceptive device',
  'Z30432 Encounter for removal of intrauterine contraceptive device',
  'Z30433 Encounter for removal and reinsertion of intrauterine contraceptive device',
  'Z3044 Encounter for surveillance of vaginal ring hormonal contraceptive device',
  'Z3045 Encounter for surveillance of transdermal patch hormonal contraceptive device',
  'Z3046 Encounter for surveillance of implantable subdermal contraceptive',
  'Z3049 Encounter for surveillance of other contraceptives',
  'Z308 Encounter for other contraceptive management',
  'Z309 Encounter for contraceptive management, unspecified',
  'Z310 Encounter for reversal of previous sterilization',
  'Z3141 Encounter for fertility testing',
  'Z3142 Aftercare following sterilization reversal',
  'Z31430 Encounter of female for testing for genetic disease carrier status for procreative management',
  'Z31438 Encounter for other genetic testing of female for procreative management',
  'Z31440 Encounter of male for testing for genetic disease carrier status for procreative management',
  'Z31441 Encounter for testing of male partner of patient with recurrent pregnancy loss',
  'Z31448 Encounter for other genetic testing of male for procreative management',
  'Z3149 Encounter for other procreative investigation and testing',
  'Z315 Encounter for genetic counseling',
  'Z3161 Procreative counseling and advice using natural family planning',
  'Z3162 Encounter for fertility preservation counseling',
  'Z3169 Encounter for other general counseling and advice on procreation',
  'Z317 Encounter for procreative management and counseling for gestational carrier',
  'Z3181 Encounter for male factor infertility in female patient',
  'Z3182 Encounter for Rh incompatibility status',
  'Z3183 Encounter for assisted reproductive fertility procedure cycle',
  'Z3184 Encounter for fertility preservation procedure',
  'Z3189 Encounter for other procreative management',
  'Z319 Encounter for procreative management, unspecified',
  'Z3200 Encounter for pregnancy test, result unknown',
  'Z3201 Encounter for pregnancy test, result positive',
  'Z3202 Encounter for pregnancy test, result negative',
  'Z322 Encounter for childbirth instruction',
  'Z323 Encounter for childcare instruction',
  'Z331 Pregnant state, incidental',
  'Z332 Encounter for elective termination of pregnancy',
  'Z333 Pregnant state, gestational carrier',
  'Z3400 Encounter for supervision of normal first pregnancy, unspecified trimester',
  'Z3401 Encounter for supervision of normal first pregnancy, first trimester',
  'Z3402 Encounter for supervision of normal first pregnancy, second trimester',
  'Z3403 Encounter for supervision of normal first pregnancy, third trimester',
  'Z3480 Encounter for supervision of other normal pregnancy, unspecified trimester',
  'Z3481 Encounter for supervision of other normal pregnancy, first trimester',
  'Z3482 Encounter for supervision of other normal pregnancy, second trimester',
  'Z3483 Encounter for supervision of other normal pregnancy, third trimester',
  'Z3490 Encounter for supervision of normal pregnancy, unspecified, unspecified trimester',
  'Z3491 Encounter for supervision of normal pregnancy, unspecified, first trimester',
  'Z3492 Encounter for supervision of normal pregnancy, unspecified, second trimester',
  'Z3493 Encounter for supervision of normal pregnancy, unspecified, third trimester',
  'Z36 Encounter for antenatal screening of mother',
  'Z3A00 Weeks of gestation of pregnancy not specified',
  'Z3A01 Less than 8 weeks gestation of pregnancy',
  'Z3A08 8 weeks gestation of pregnancy',
  'Z3A09 9 weeks gestation of pregnancy',
  'Z3A10 10 weeks gestation of pregnancy',
  'Z3A11 11 weeks gestation of pregnancy',
  'Z3A12 12 weeks gestation of pregnancy',
  'Z3A13 13 weeks gestation of pregnancy',
  'Z3A14 14 weeks gestation of pregnancy',
  'Z3A15 15 weeks gestation of pregnancy',
  'Z3A16 16 weeks gestation of pregnancy',
  'Z3A17 17 weeks gestation of pregnancy',
  'Z3A18 18 weeks gestation of pregnancy',
  'Z3A19 19 weeks gestation of pregnancy',
  'Z3A20 20 weeks gestation of pregnancy',
  'Z3A21 21 weeks gestation of pregnancy',
  'Z3A22 22 weeks gestation of pregnancy',
  'Z3A23 23 weeks gestation of pregnancy',
  'Z3A24 24 weeks gestation of pregnancy',
  'Z3A25 25 weeks gestation of pregnancy',
  'Z3A26 26 weeks gestation of pregnancy',
  'Z3A27 27 weeks gestation of pregnancy',
  'Z3A28 28 weeks gestation of pregnancy',
  'Z3A29 29 weeks gestation of pregnancy',
  'Z3A30 30 weeks gestation of pregnancy',
  'Z3A31 31 weeks gestation of pregnancy',
  'Z3A32 32 weeks gestation of pregnancy',
  'Z3A33 33 weeks gestation of pregnancy',
  'Z3A34 34 weeks gestation of pregnancy',
  'Z3A35 35 weeks gestation of pregnancy',
  'Z3A36 36 weeks gestation of pregnancy',
  'Z3A37 37 weeks gestation of pregnancy',
  'Z3A38 38 weeks gestation of pregnancy',
  'Z3A39 39 weeks gestation of pregnancy',
  'Z3A40 40 weeks gestation of pregnancy',
  'Z3A41 41 weeks gestation of pregnancy',
  'Z3A42 42 weeks gestation of pregnancy',
  'Z3A49 Greater than 42 weeks gestation of pregnancy',
  'Z370 Single live birth',
  'Z371 Single stillbirth',
  'Z372 Twins, both liveborn',
  'Z373 Twins, one liveborn and one stillborn',
  'Z374 Twins, both stillborn',
  'Z3750 Multiple births, unspecified, all liveborn',
  'Z3751 Triplets, all liveborn',
  'Z3752 Quadruplets, all liveborn',
  'Z3753 Quintuplets, all liveborn',
  'Z3754 Sextuplets, all liveborn',
  'Z3759 Other multiple births, all liveborn',
  'Z3760 Multiple births, unspecified, some liveborn',
  'Z3761 Triplets, some liveborn',
  'Z3762 Quadruplets, some liveborn',
  'Z3763 Quintuplets, some liveborn',
  'Z3764 Sextuplets, some liveborn',
  'Z3769 Other multiple births, some liveborn',
  'Z377 Other multiple births, all stillborn',
  'Z379 Outcome of delivery, unspecified',
  'Z3800 Single liveborn infant, delivered vaginally',
  'Z3801 Single liveborn infant, delivered by cesarean',
  'Z381 Single liveborn infant, born outside hospital',
  'Z382 Single liveborn infant, unspecified as to place of birth',
  'Z3830 Twin liveborn infant, delivered vaginally',
  'Z3831 Twin liveborn infant, delivered by cesarean',
  'Z384 Twin liveborn infant, born outside hospital',
  'Z385 Twin liveborn infant, unspecified as to place of birth',
  'Z3861 Triplet liveborn infant, delivered vaginally',
  'Z3862 Triplet liveborn infant, delivered by cesarean',
  'Z3863 Quadruplet liveborn infant, delivered vaginally',
  'Z3864 Quadruplet liveborn infant, delivered by cesarean',
  'Z3865 Quintuplet liveborn infant, delivered vaginally',
  'Z3866 Quintuplet liveborn infant, delivered by cesarean',
  'Z3868 Other multiple liveborn infant, delivered vaginally',
  'Z3869 Other multiple liveborn infant, delivered by cesarean',
  'Z387 Other multiple liveborn infant, born outside hospital',
  'Z388 Other multiple liveborn infant, unspecified as to place of birth',
  'Z390 Encounter for care and examination of mother immediately after delivery',
  'Z391 Encounter for care and examination of lactating mother',
  'Z392 Encounter for routine postpartum follow-up',
  'Z4000 Encounter for prophylactic removal of unspecified organ',
  'Z4001 Encounter for prophylactic removal of breast',
  'Z4002 Encounter for prophylactic removal of ovary',
  'Z4009 Encounter for prophylactic removal of other organ',
  'Z408 Encounter for other prophylactic surgery',
  'Z409 Encounter for prophylactic surgery, unspecified',
  'Z411 Encounter for cosmetic surgery',
  'Z412 Encounter for routine and ritual male circumcision',
  'Z413 Encounter for ear piercing',
  'Z418 Encounter for other procedures for purposes other than remedying health state',
  'Z419 Encounter for procedure for purposes other than remedying health state, unspecified',
  'Z421 Encounter for breast reconstruction following mastectomy',
  'Z428 Encounter for other plastic and reconstructive surgery following medical procedure or healed',
  'injury',
  'Z430 Encounter for attention to tracheostomy',
  'Z431 Encounter for attention to gastrostomy',
  'Z432 Encounter for attention to ileostomy',
  'Z433 Encounter for attention to colostomy',
  'Z434 Encounter for attention to other artificial openings of digestive tract',
  'Z435 Encounter for attention to cystostomy',
  'Z436 Encounter for attention to other artificial openings of urinary tract',
  'Z437 Encounter for attention to artificial vagina',
  'Z438 Encounter for attention to other artificial openings',
  'Z439 Encounter for attention to unspecified artificial opening',
  'Z44001 Encounter for fitting and adjustment of unspecified right artificial arm',
  'Z44002 Encounter for fitting and adjustment of unspecified left artificial arm',
  'Z44009 Encounter for fitting and adjustment of unspecified artificial arm, unspecified arm',
  'Z44011 Encounter for fitting and adjustment of complete right artificial arm',
  'Z44012 Encounter for fitting and adjustment of complete left artificial arm',
  'Z44019 Encounter for fitting and adjustment of complete artificial arm, unspecified arm',
  'Z44021 Encounter for fitting and adjustment of partial artificial right arm',
  'Z44022 Encounter for fitting and adjustment of partial artificial left arm',
  'Z44029 Encounter for fitting and adjustment of partial artificial arm, unspecified arm',
  'Z44101 Encounter for fitting and adjustment of unspecified right artificial leg',
  'Z44102 Encounter for fitting and adjustment of unspecified left artificial leg',
  'Z44109 Encounter for fitting and adjustment of unspecified artificial leg, unspecified leg',
  'Z44111 Encounter for fitting and adjustment of complete right artificial leg',
  'Z44112 Encounter for fitting and adjustment of complete left artificial leg',
  'Z44119 Encounter for fitting and adjustment of complete artificial leg, unspecified leg',
  'Z44121 Encounter for fitting and adjustment of partial artificial right leg',
  'Z44122 Encounter for fitting and adjustment of partial artificial left leg',
  'Z44129 Encounter for fitting and adjustment of partial artificial leg, unspecified leg',
  'Z4420 Encounter for fitting and adjustment of artificial eye, unspecified',
  'Z4421 Encounter for fitting and adjustment of artificial right eye',
  'Z4422 Encounter for fitting and adjustment of artificial left eye',
  'Z4430 Encounter for fitting and adjustment of external breast prosthesis, unspecified breast',
  'Z4431 Encounter for fitting and adjustment of external right breast prosthesis',
  'Z4432 Encounter for fitting and adjustment of external left breast prosthesis',
  'Z448 Encounter for fitting and adjustment of other external prosthetic devices',
  'Z449 Encounter for fitting and adjustment of unspecified external prosthetic device',
  'Z45010 Encounter for checking and testing of cardiac pacemaker pulse generator [battery]',
  'Z45018 Encounter for adjustment and management of other part of cardiac pacemaker',
  'Z4502 Encounter for adjustment and management of automatic implantable cardiac defibrillator',
  'Z4509 Encounter for adjustment and management of other cardiac device',
  'Z451 Encounter for adjustment and management of infusion pump',
  'Z452 Encounter for adjustment and management of vascular access device',
  'Z4531 Encounter for adjustment and management of implanted visual substitution device',
  'Z45320 Encounter for adjustment and management of bone conduction device',
  'Z45321 Encounter for adjustment and management of cochlear device',
  'Z45328 Encounter for adjustment and management of other implanted hearing device',
  'Z4541 Encounter for adjustment and management of cerebrospinal fluid drainage device',
  'Z4542 Encounter for adjustment and management of neuropacemaker (brain) (peripheral nerve)',
  '(spinal cord)',
  'Z4549 Encounter for adjustment and management of other implanted nervous system device',
  'Z45811 Encounter for adjustment or removal of right breast implant',
  'Z45812 Encounter for adjustment or removal of left breast implant',
  'Z45819 Encounter for adjustment or removal of unspecified breast implant',
  'Z4582 Encounter for adjustment or removal of myringotomy device (stent) (tube)',
  'Z4589 Encounter for adjustment and management of other implanted devices',
  'Z459 Encounter for adjustment and management of unspecified implanted device',
  'Z460 Encounter for fitting and adjustment of spectacles and contact lenses',
  'Z461 Encounter for fitting and adjustment of hearing aid',
  'Z462 Encounter for fitting and adjustment of other devices related to nervous system and special',
  'senses',
  'Z463 Encounter for fitting and adjustment of dental prosthetic device',
  'Z464 Encounter for fitting and adjustment of orthodontic device',
  'Z4651 Encounter for fitting and adjustment of gastric lap band',
  'Z4659 Encounter for fitting and adjustment of other gastrointestinal appliance and device',
  'Z466 Encounter for fitting and adjustment of urinary device',
  'Z4681 Encounter for fitting and adjustment of insulin pump',
  'Z4682 Encounter for fitting and adjustment of non-vascular catheter',
  'Z4689 Encounter for fitting and adjustment of other specified devices',
  'Z469 Encounter for fitting and adjustment of unspecified device',
  'Z471 Aftercare following joint replacement surgery',
  'Z472 Encounter for removal of internal fixation device',
  'Z4731 Aftercare following explantation of shoulder joint prosthesis',
  'Z4732 Aftercare following explantation of hip joint prosthesis',
  'Z4733 Aftercare following explantation of knee joint prosthesis',
  'Z4781 Encounter for orthopedic aftercare following surgical amputation',
  'Z4782 Encounter for orthopedic aftercare following scoliosis surgery',
  'Z4789 Encounter for other orthopedic aftercare',
  'Z4800 Encounter for change or removal of nonsurgical wound dressing',
  'Z4801 Encounter for change or removal of surgical wound dressing',
  'Z4802 Encounter for removal of sutures',
  'Z4803 Encounter for change or removal of drains',
  'Z481 Encounter for planned postprocedural wound closure',
  'Z4821 Encounter for aftercare following heart transplant',
  'Z4822 Encounter for aftercare following kidney transplant',
  'Z4823 Encounter for aftercare following liver transplant',
  'Z4824 Encounter for aftercare following lung transplant',
  'Z48280 Encounter for aftercare following heart-lung transplant',
  'Z48288 Encounter for aftercare following multiple organ transplant',
  'Z48290 Encounter for aftercare following bone marrow transplant',
  'Z48298 Encounter for aftercare following other organ transplant',
  'Z483 Aftercare following surgery for neoplasm',
  'Z48810 Encounter for surgical aftercare following surgery on the sense organs',
  'Z48811 Encounter for surgical aftercare following surgery on the nervous system',
  'Z48812 Encounter for surgical aftercare following surgery on the circulatory system',
  'Z48813 Encounter for surgical aftercare following surgery on the respiratory system',
  'Z48814 Encounter for surgical aftercare following surgery on the teeth or oral cavity',
  'Z48815 Encounter for surgical aftercare following surgery on the digestive system',
  'Z48816 Encounter for surgical aftercare following surgery on the genitourinary system',
  'Z48817 Encounter for surgical aftercare following surgery on the skin and subcutaneous tissue',
  'Z4889 Encounter for other specified surgical aftercare',
  'Z4901 Encounter for fitting and adjustment of extracorporeal dialysis catheter',
  'Z4902 Encounter for fitting and adjustment of peritoneal dialysis catheter',
  'Z4931 Encounter for adequacy testing for hemodialysis',
  'Z4932 Encounter for adequacy testing for peritoneal dialysis',
  'Z510 Encounter for antineoplastic radiation therapy',
  'Z5111 Encounter for antineoplastic chemotherapy',
  'Z5112 Encounter for antineoplastic immunotherapy',
  'Z515 Encounter for palliative care',
  'Z516 Encounter for desensitization to allergens',
  'Z5181 Encounter for therapeutic drug level monitoring',
  'Z5189 Encounter for other specified aftercare',
  'Z52000 Unspecified donor, whole blood',
  'Z52001 Unspecified donor, stem cells',
  'Z52008 Unspecified donor, other blood',
  'Z52010 Autologous donor, whole blood',
  'Z52011 Autologous donor, stem cells',
  'Z52018 Autologous donor, other blood',
  'Z52090 Other blood donor, whole blood',
  'Z52091 Other blood donor, stem cells',
  'Z52098 Other blood donor, other blood',
  'Z5210 Skin donor, unspecified',
  'Z5211 Skin donor, autologous',
  'Z5219 Skin donor, other',
  'Z5220 Bone donor, unspecified',
  'Z5221 Bone donor, autologous',
  'Z5229 Bone donor, other',
  'Z523 Bone marrow donor',
  'Z524 Kidney donor',
  'Z525 Cornea donor',
  'Z526 Liver donor',
  'Z52810 Egg (Oocyte) donor under age 35, anonymous recipient',
  'Z52811 Egg (Oocyte) donor under age 35, designated recipient',
  'Z52812 Egg (Oocyte) donor age 35 and over, anonymous recipient',
  'Z52813 Egg (Oocyte) donor age 35 and over, designated recipient',
  'Z52819 Egg (Oocyte) donor, unspecified',
  'Z5289 Donor of other specified organs or tissues',
  'Z529 Donor of unspecified organ or tissue',
  'Z5301 Procedure and treatment not carried out due to patient smoking',
  'Z5309 Procedure and treatment not carried out because of other contraindication',
  "Z531 Procedure and treatment not carried out because of patient's decision for reasons of belief and",
  'group pressure',
  "Z5320 Procedure and treatment not carried out because of patient's decision for unspecified reasons",
  'Z5321 Procedure and treatment not carried out due to patient leaving prior to being seen by health',
  'care provider',
  "Z5329 Procedure and treatment not carried out because of patient's decision for other reasons",
  'Z5331 Laparoscopic surgical procedure converted to open procedure',
  'Z5332 Thoracoscopic surgical procedure converted to open procedure',
  'Z5333 Arthroscopic surgical procedure converted to open procedure',
  'Z5339 Other specified procedure converted to open procedure',
  'Z538 Procedure and treatment not carried out for other reasons',
  'Z539 Procedure and treatment not carried out, unspecified reason',
  'Z550 Illiteracy and low-level literacy',
  'Z551 Schooling unavailable and unattainable',
  'Z552 Failed school examinations',
  'Z553 Underachievement in school',
  'Z554 Educational maladjustment and discord with teachers and classmates',
  'Z558 Other problems related to education and literacy',
  'Z559 Problems related to education and literacy, unspecified',
  'Z560 Unemployment, unspecified',
  'Z561 Change of job',
  'Z562 Threat of job loss',
  'Z563 Stressful work schedule',
  'Z564 Discord with boss and workmates',
  'Z565 Uncongenial work environment',
  'Z566 Other physical and mental strain related to work',
  'Z5681 Sexual harassment on the job',
  'Z5682 Military deployment status',
  'Z5689 Other problems related to employment',
  'Z569 Unspecified problems related to employment',
  'Z570 Occupational exposure to noise',
  'Z571 Occupational exposure to radiation',
  'Z572 Occupational exposure to dust',
  'Z5731 Occupational exposure to environmental tobacco smoke',
  'Z5739 Occupational exposure to other air contaminants',
  'Z574 Occupational exposure to toxic agents in agriculture',
  'Z575 Occupational exposure to toxic agents in other industries',
  'Z576 Occupational exposure to extreme temperature',
  'Z577 Occupational exposure to vibration',
  'Z578 Occupational exposure to other risk factors',
  'Z579 Occupational exposure to unspecified risk factor',
  'Z590 Homelessness',
  'Z591 Inadequate housing',
  'Z592 Discord with neighbors, lodgers and landlord',
  'Z593 Problems related to living in residential institution',
  'Z594 Lack of adequate food and safe drinking water',
  'Z595 Extreme poverty',
  'Z596 Low income',
  'Z597 Insufficient social insurance and welfare support',
  'Z598 Other problems related to housing and economic circumstances',
  'Z599 Problem related to housing and economic circumstances, unspecified',
  'Z600 Problems of adjustment to life-cycle transitions',
  'Z602 Problems related to living alone',
  'Z603 Acculturation difficulty',
  'Z604 Social exclusion and rejection',
  'Z605 Target of (perceived) adverse discrimination and persecution',
  'Z608 Other problems related to social environment',
  'Z609 Problem related to social environment, unspecified',
  'Z620 Inadequate parental supervision and control',
  'Z621 Parental overprotection',
  'Z6221 Child in welfare custody',
  'Z6222 Institutional upbringing',
  'Z6229 Other upbringing away from parents',
  'Z623 Hostility towards and scapegoating of child',
  'Z626 Inappropriate (excessive) parental pressure',
  'Z62810 Personal history of physical and sexual abuse in childhood',
  'Z62811 Personal history of psychological abuse in childhood',
  'Z62812 Personal history of neglect in childhood',
  'Z62819 Personal history of unspecified abuse in childhood',
  'Z62820 Parent-biological child conflict',
  'Z62821 Parent-adopted child conflict',
  'Z62822 Parent-foster child conflict',
  'Z62890 Parent-child estrangement NEC',
  'Z62891 Sibling rivalry',
  'Z62898 Other specified problems related to upbringing',
  'Z629 Problem related to upbringing, unspecified',
  'Z630 Problems in relationship with spouse or partner',
  'Z631 Problems in relationship with in-laws',
  'Z6331 Absence of family member due to military deployment',
  'Z6332 Other absence of family member',
  'Z634 Disappearance and death of family member',
  'Z635 Disruption of family by separation and divorce',
  'Z636 Dependent relative needing care at home',
  'Z6371 Stress on family due to return of family member from military deployment',
  'Z6372 Alcoholism and drug addiction in family',
  'Z6379 Other stressful life events affecting family and household',
  'Z638 Other specified problems related to primary support group',
  'Z639 Problem related to primary support group, unspecified',
  'Z640 Problems related to unwanted pregnancy',
  'Z641 Problems related to multiparity',
  'Z644 Discord with counselors',
  'Z650 Conviction in civil and criminal proceedings without imprisonment',
  'Z651 Imprisonment and other incarceration',
  'Z652 Problems related to release from prison',
  'Z653 Problems related to other legal circumstances',
  'Z654 Victim of crime and terrorism',
  'Z655 Exposure to disaster, war and other hostilities',
  'Z658 Other specified problems related to psychosocial circumstances',
  'Z659 Problem related to unspecified psychosocial circumstances',
  'Z66 Do not resuscitate',
  'Z6710 Type A blood, Rh positive',
  'Z6711 Type A blood, Rh negative',
  'Z6720 Type B blood, Rh positive',
  'Z6721 Type B blood, Rh negative',
  'Z6730 Type AB blood, Rh positive',
  'Z6731 Type AB blood, Rh negative',
  'Z6740 Type O blood, Rh positive',
  'Z6741 Type O blood, Rh negative',
  'Z6790 Unspecified blood type, Rh positive',
  'Z6791 Unspecified blood type, Rh negative',
  'Z681 Body mass index (BMI) 19 or less, adult',
  'Z6820 Body mass index (BMI) 20.0-20.9, adult',
  'Z6821 Body mass index (BMI) 21.0-21.9, adult',
  'Z6822 Body mass index (BMI) 22.0-22.9, adult',
  'Z6823 Body mass index (BMI) 23.0-23.9, adult',
  'Z6824 Body mass index (BMI) 24.0-24.9, adult',
  'Z6825 Body mass index (BMI) 25.0-25.9, adult',
  'Z6826 Body mass index (BMI) 26.0-26.9, adult',
  'Z6827 Body mass index (BMI) 27.0-27.9, adult',
  'Z6828 Body mass index (BMI) 28.0-28.9, adult',
  'Z6829 Body mass index (BMI) 29.0-29.9, adult',
  'Z6830 Body mass index (BMI) 30.0-30.9, adult',
  'Z6831 Body mass index (BMI) 31.0-31.9, adult',
  'Z6832 Body mass index (BMI) 32.0-32.9, adult',
  'Z6833 Body mass index (BMI) 33.0-33.9, adult',
  'Z6834 Body mass index (BMI) 34.0-34.9, adult',
  'Z6835 Body mass index (BMI) 35.0-35.9, adult',
  'Z6836 Body mass index (BMI) 36.0-36.9, adult',
  'Z6837 Body mass index (BMI) 37.0-37.9, adult',
  'Z6838 Body mass index (BMI) 38.0-38.9, adult',
  'Z6839 Body mass index (BMI) 39.0-39.9, adult',
  'Z6841 Body mass index (BMI) 40.0-44.9, adult',
  'Z6842 Body mass index (BMI) 45.0-49.9, adult',
  'Z6843 Body mass index (BMI) 50-59.9 , adult',
  'Z6844 Body mass index (BMI) 60.0-69.9, adult',
  'Z6845 Body mass index (BMI) 70 or greater, adult',
  'Z6851 Body mass index (BMI) pediatric, less than 5th percentile for age',
  'Z6852 Body mass index (BMI) pediatric, 5th percentile to less than 85th percentile for age',
  'Z6853 Body mass index (BMI) pediatric, 85th percentile to less than 95th percentile for age',
  'Z6854 Body mass index (BMI) pediatric, greater than or equal to 95th percentile for age',
  'Z69010 Encounter for mental health services for victim of parental child abuse',
  'Z69011 Encounter for mental health services for perpetrator of parental child abuse',
  'Z69020 Encounter for mental health services for victim of non-parental child abuse',
  'Z69021 Encounter for mental health services for perpetrator of non-parental child abuse',
  'Z6911 Encounter for mental health services for victim of spousal or partner abuse',
  'Z6912 Encounter for mental health services for perpetrator of spousal or partner abuse',
  'Z6981 Encounter for mental health services for victim of other abuse',
  'Z6982 Encounter for mental health services for perpetrator of other abuse',
  'Z700 Counseling related to sexual attitude',
  "Z701 Counseling related to patient's sexual behavior and orientation",
  'Z702 Counseling related to sexual behavior and orientation of third party',
  'Z703 Counseling related to combined concerns regarding sexual attitude, behavior and orientation',
  'Z708 Other sex counseling',
  'Z709 Sex counseling, unspecified',
  'Z710 Person encountering health services to consult on behalf of another person',
  'Z711 Person with feared health complaint in whom no diagnosis is made',
  'Z712 Person consulting for explanation of examination or test findings',
  'Z713 Dietary counseling and surveillance',
  'Z7141 Alcohol abuse counseling and surveillance of alcoholic',
  'Z7142 Counseling for family member of alcoholic',
  'Z7151 Drug abuse counseling and surveillance of drug abuser',
  'Z7152 Counseling for family member of drug abuser',
  'Z716 Tobacco abuse counseling',
  'Z717 Human immunodeficiency virus [HIV] counseling',
  'Z7181 Spiritual or religious counseling',
  'Z7189 Other specified counseling',
  'Z719 Counseling, unspecified',
  'Z720 Tobacco use',
  'Z723 Lack of physical exercise',
  'Z724 Inappropriate diet and eating habits',
  'Z7251 High risk heterosexual behavior',
  'Z7252 High risk homosexual behavior',
  'Z7253 High risk bisexual behavior',
  'Z726 Gambling and betting',
  'Z72810 Child and adolescent antisocial behavior',
  'Z72811 Adult antisocial behavior',
  'Z72820 Sleep deprivation',
  'Z72821 Inadequate sleep hygiene',
  'Z7289 Other problems related to lifestyle',
  'Z729 Problem related to lifestyle, unspecified',
  'Z730 Burn-out',
  'Z731 Type A behavior pattern',
  'Z732 Lack of relaxation and leisure',
  'Z733 Stress, not elsewhere classified',
  'Z734 Inadequate social skills, not elsewhere classified',
  'Z735 Social role conflict, not elsewhere classified',
  'Z736 Limitation of activities due to disability',
  'Z73810 Behavioral insomnia of childhood, sleep-onset association type',
  'Z73811 Behavioral insomnia of childhood, limit setting type',
  'Z73812 Behavioral insomnia of childhood, combined type',
  'Z73819 Behavioral insomnia of childhood, unspecified type',
  'Z7382 Dual sensory impairment',
  'Z7389 Other problems related to life management difficulty',
  'Z739 Problem related to life management difficulty, unspecified',
  'Z7401 Bed confinement status',
  'Z7409 Other reduced mobility',
  'Z741 Need for assistance with personal care',
  'Z742 Need for assistance at home and no other household member able to render care',
  'Z743 Need for continuous supervision',
  'Z748 Other problems related to care provider dependency',
  'Z749 Problem related to care provider dependency, unspecified',
  'Z750 Medical services not available in home',
  'Z751 Person awaiting admission to adequate facility elsewhere',
  'Z752 Other waiting period for investigation and treatment',
  'Z753 Unavailability and inaccessibility of health-care facilities',
  'Z754 Unavailability and inaccessibility of other helping agencies',
  'Z755 Holiday relief care',
  'Z758 Other problems related to medical facilities and other health care',
  'Z759 Unspecified problem related to medical facilities and other health care',
  'Z760 Encounter for issue of repeat prescription',
  'Z761 Encounter for health supervision and care of foundling',
  'Z762 Encounter for health supervision and care of other healthy infant and child',
  'Z763 Healthy person accompanying sick person',
  'Z764 Other boarder to healthcare facility',
  'Z765 Malingerer [conscious simulation]',
  'Z7681 Expectant parent(s) prebirth pediatrician visit',
  'Z7682 Awaiting organ transplant status',
  'Z7689 Persons encountering health services in other specified circumstances',
  'Z77010 Contact with and (suspected) exposure to arsenic',
  'Z77011 Contact with and (suspected) exposure to lead',
  'Z77012 Contact with and (suspected) exposure to uranium',
  'Z77018 Contact with and (suspected) exposure to other hazardous metals',
  'Z77020 Contact with and (suspected) exposure to aromatic amines',
  'Z77021 Contact with and (suspected) exposure to benzene',
  'Z77028 Contact with and (suspected) exposure to other hazardous aromatic compounds',
  'Z77090 Contact with and (suspected) exposure to asbestos',
  'Z77098 Contact with and (suspected) exposure to other hazardous, chiefly nonmedicinal, chemicals',
  'Z77110 Contact with and (suspected) exposure to air pollution',
  'Z77111 Contact with and (suspected) exposure to water pollution',
  'Z77112 Contact with and (suspected) exposure to soil pollution',
  'Z77118 Contact with and (suspected) exposure to other environmental pollution',
  'Z77120 Contact with and (suspected) exposure to mold (toxic)',
  'Z77121 Contact with and (suspected) exposure to harmful algae and algae toxins',
  'Z77122 Contact with and (suspected) exposure to noise',
  'Z77123 Contact with and (suspected) exposure to radon and other naturally occuring radiation',
  'Z77128 Contact with and (suspected) exposure to other hazards in the physical environment',
  'Z7721 Contact with and (suspected) exposure to potentially hazardous body fluids',
  'Z7722 Contact with and (suspected) exposure to environmental tobacco smoke (acute) (chronic)',
  'Z7729 Contact with and (suspected ) exposure to other hazardous substances',
  'Z779 Other contact with and (suspected) exposures hazardous to health',
  'Z780 Asymptomatic menopausal state',
  'Z781 Physical restraint status',
  'Z789 Other specified health status',
  'Z7901 Long term (current) use of anticoagulants',
  'Z7902 Long term (current) use of antithrombotics/antiplatelets',
  'Z791 Long term (current) use of non-steroidal anti-inflammatories (NSAID)',
  'Z792 Long term (current) use of antibiotics',
  'Z793 Long term (current) use of hormonal contraceptives',
  'Z794 Long term (current) use of insulin',
  'Z7951 Long term (current) use of inhaled steroids',
  'Z7952 Long term (current) use of systemic steroids',
  'Z79810 Long term (current) use of selective estrogen receptor modulators (SERMs)',
  'Z79811 Long term (current) use of aromatase inhibitors',
  'Z79818 Long term (current) use of other agents affecting estrogen receptors and estrogen levels',
  'Z7982 Long term (current) use of aspirin',
  'Z7983 Long term (current) use of bisphosphonates',
  'Z7984 Long term (current) use of oral hypoglycemic drugs',
  'Z79890 Hormone replacement therapy (postmenopausal)',
  'Z79891 Long term (current) use of opiate analgesic',
  'Z79899 Other long term (current) drug therapy',
  'Z800 Family history of malignant neoplasm of digestive organs',
  'Z801 Family history of malignant neoplasm of trachea, bronchus and lung',
  'Z802 Family history of malignant neoplasm of other respiratory and intrathoracic organs',
  'Z803 Family history of malignant neoplasm of breast',
  'Z8041 Family history of malignant neoplasm of ovary',
  'Z8042 Family history of malignant neoplasm of prostate',
  'Z8043 Family history of malignant neoplasm of testis',
  'Z8049 Family history of malignant neoplasm of other genital organs',
  'Z8051 Family history of malignant neoplasm of kidney',
  'Z8052 Family history of malignant neoplasm of bladder',
  'Z8059 Family history of malignant neoplasm of other urinary tract organ',
  'Z806 Family history of leukemia',
  'Z807 Family history of other malignant neoplasms of lymphoid, hematopoietic and related tissues',
  'Z808 Family history of malignant neoplasm of other organs or systems',
  'Z809 Family history of malignant neoplasm, unspecified',
  'Z810 Family history of intellectual disabilities',
  'Z811 Family history of alcohol abuse and dependence',
  'Z812 Family history of tobacco abuse and dependence',
  'Z813 Family history of other psychoactive substance abuse and dependence',
  'Z814 Family history of other substance abuse and dependence',
  'Z818 Family history of other mental and behavioral disorders',
  'Z820 Family history of epilepsy and other diseases of the nervous system',
  'Z821 Family history of blindness and visual loss',
  'Z822 Family history of deafness and hearing loss',
  'Z823 Family history of stroke',
  'Z8241 Family history of sudden cardiac death',
  'Z8249 Family history of ischemic heart disease and other diseases of the circulatory system',
  'Z825 Family history of asthma and other chronic lower respiratory diseases',
  'Z8261 Family history of arthritis',
  'Z8262 Family history of osteoporosis',
  'Z8269 Family history of other diseases of the musculoskeletal system and connective tissue',
  'Z8271 Family history of polycystic kidney',
  'Z8279 Family history of other congenital malformations, deformations and chromosomal abnormalities',
  'Z828 Family history of other disabilities and chronic diseases leading to disablement, not elsewhere',
  'classified',
  'Z830 Family history of human immunodeficiency virus [HIV] disease',
  'Z831 Family history of other infectious and parasitic diseases',
  'Z832 Family history of diseases of the blood and blood-forming organs and certain disorders involving',
  'the immune mechanism',
  'Z833 Family history of diabetes mellitus',
  'Z8341 Family history of multiple endocrine neoplasia [MEN] syndrome',
  'Z8342 Family history of familial hypercholesterolemia',
  'Z8349 Family history of other endocrine, nutritional and metabolic diseases',
  'Z83511 Family history of glaucoma',
  'Z83518 Family history of other specified eye disorder',
  'Z8352 Family history of ear disorders',
  'Z836 Family history of other diseases of the respiratory system',
  'Z8371 Family history of colonic polyps',
  'Z8379 Family history of other diseases of the digestive system',
  'Z840 Family history of diseases of the skin and subcutaneous tissue',
  'Z841 Family history of disorders of kidney and ureter',
  'Z842 Family history of other diseases of the genitourinary system',
  'Z843 Family history of consanguinity',
  'Z8481 Family history of carrier of genetic disease',
  'Z8482 Family history of sudden infant death syndrome',
  'Z8489 Family history of other specified conditions',
  'Z8500 Personal history of malignant neoplasm of unspecified digestive organ',
  'Z8501 Personal history of malignant neoplasm of esophagus',
  'Z85020 Personal history of malignant carcinoid tumor of stomach',
  'Z85028 Personal history of other malignant neoplasm of stomach',
  'Z85030 Personal history of malignant carcinoid tumor of large intestine',
  'Z85038 Personal history of other malignant neoplasm of large intestine',
  'Z85040 Personal history of malignant carcinoid tumor of rectum',
  'Z85048 Personal history of other malignant neoplasm of rectum, rectosigmoid junction, and anus',
  'Z8505 Personal history of malignant neoplasm of liver',
  'Z85060 Personal history of malignant carcinoid tumor of small intestine',
  'Z85068 Personal history of other malignant neoplasm of small intestine',
  'Z8507 Personal history of malignant neoplasm of pancreas',
  'Z8509 Personal history of malignant neoplasm of other digestive organs',
  'Z85110 Personal history of malignant carcinoid tumor of bronchus and lung',
  'Z85118 Personal history of other malignant neoplasm of bronchus and lung',
  'Z8512 Personal history of malignant neoplasm of trachea',
  'Z8520 Personal history of malignant neoplasm of unspecified respiratory organ',
  'Z8521 Personal history of malignant neoplasm of larynx',
  'Z8522 Personal history of malignant neoplasm of nasal cavities, middle ear, and accessory sinuses',
  'Z85230 Personal history of malignant carcinoid tumor of thymus',
  'Z85238 Personal history of other malignant neoplasm of thymus',
  'Z8529 Personal history of malignant neoplasm of other respiratory and intrathoracic organs',
  'Z853 Personal history of malignant neoplasm of breast',
  'Z8540 Personal history of malignant neoplasm of unspecified female genital organ',
  'Z8541 Personal history of malignant neoplasm of cervix uteri',
  'Z8542 Personal history of malignant neoplasm of other parts of uterus',
  'Z8543 Personal history of malignant neoplasm of ovary',
  'Z8544 Personal history of malignant neoplasm of other female genital organs',
  'Z8545 Personal history of malignant neoplasm of unspecified male genital organ',
  'Z8546 Personal history of malignant neoplasm of prostate',
  'Z8547 Personal history of malignant neoplasm of testis',
  'Z8548 Personal history of malignant neoplasm of epididymis',
  'Z8549 Personal history of malignant neoplasm of other male genital organs',
  'Z8550 Personal history of malignant neoplasm of unspecified urinary tract organ',
  'Z8551 Personal history of malignant neoplasm of bladder',
  'Z85520 Personal history of malignant carcinoid tumor of kidney',
  'Z85528 Personal history of other malignant neoplasm of kidney',
  'Z8553 Personal history of malignant neoplasm of renal pelvis',
  'Z8554 Personal history of malignant neoplasm of ureter',
  'Z8559 Personal history of malignant neoplasm of other urinary tract organ',
  'Z856 Personal history of leukemia',
  'Z8571 Personal history of Hodgkin lymphoma',
  'Z8572 Personal history of non-Hodgkin lymphomas',
  'Z8579 Personal history of other malignant neoplasms of lymphoid, hematopoietic and related tissues',
  'Z85810 Personal history of malignant neoplasm of tongue',
  'Z85818 Personal history of malignant neoplasm of other sites of lip, oral cavity, and pharynx',
  'Z85819 Personal history of malignant neoplasm of unspecified site of lip, oral cavity, and pharynx',
  'Z85820 Personal history of malignant melanoma of skin',
  'Z85821 Personal history of Merkel cell carcinoma',
  'Z85828 Personal history of other malignant neoplasm of skin',
  'Z85830 Personal history of malignant neoplasm of bone',
  'Z85831 Personal history of malignant neoplasm of soft tissue',
  'Z85840 Personal history of malignant neoplasm of eye',
  'Z85841 Personal history of malignant neoplasm of brain',
  'Z85848 Personal history of malignant neoplasm of other parts of nervous tissue',
  'Z85850 Personal history of malignant neoplasm of thyroid',
  'Z85858 Personal history of malignant neoplasm of other endocrine glands',
  'Z8589 Personal history of malignant neoplasm of other organs and systems',
  'Z859 Personal history of malignant neoplasm, unspecified',
  'Z86000 Personal history of in-situ neoplasm of breast',
  'Z86001 Personal history of in-situ neoplasm of cervix uteri',
  'Z86008 Personal history of in-situ neoplasm of other site',
  'Z86010 Personal history of colonic polyps',
  'Z86011 Personal history of benign neoplasm of the brain',
  'Z86012 Personal history of benign carcinoid tumor',
  'Z86018 Personal history of other benign neoplasm',
  'Z8603 Personal history of neoplasm of uncertain behavior',
  'Z8611 Personal history of tuberculosis',
  'Z8612 Personal history of poliomyelitis',
  'Z8613 Personal history of malaria',
  'Z8614 Personal history of Methicillin resistant Staphylococcus aureus infection',
  'Z8619 Personal history of other infectious and parasitic diseases',
  'Z862 Personal history of diseases of the blood and blood-forming organs and certain disorders',
  'involving the immune mechanism',
  'Z8631 Personal history of diabetic foot ulcer',
  'Z8632 Personal history of gestational diabetes',
  'Z8639 Personal history of other endocrine, nutritional and metabolic disease',
  'Z8651 Personal history of combat and operational stress reaction',
  'Z8659 Personal history of other mental and behavioral disorders',
  'Z8661 Personal history of infections of the central nervous system',
  'Z8669 Personal history of other diseases of the nervous system and sense organs',
  'Z86711 Personal history of pulmonary embolism',
  'Z86718 Personal history of other venous thrombosis and embolism',
  'Z8672 Personal history of thrombophlebitis',
  'Z8673 Personal history of transient ischemic attack (TIA), and cerebral infarction without residual',
  'deficits',
  'Z8674 Personal history of sudden cardiac arrest',
  'Z8679 Personal history of other diseases of the circulatory system',
  'Z8701 Personal history of pneumonia (recurrent)',
  'Z8709 Personal history of other diseases of the respiratory system',
  'Z8711 Personal history of peptic ulcer disease',
  'Z8719 Personal history of other diseases of the digestive system',
  'Z872 Personal history of diseases of the skin and subcutaneous tissue',
  'Z87310 Personal history of (healed) osteoporosis fracture',
  'Z87311 Personal history of (healed) other pathological fracture',
  'Z87312 Personal history of (healed) stress fracture',
  'Z8739 Personal history of other diseases of the musculoskeletal system and connective tissue',
  'Z87410 Personal history of cervical dysplasia',
  'Z87411 Personal history of vaginal dysplasia',
  'Z87412 Personal history of vulvar dysplasia',
  'Z8742 Personal history of other diseases of the female genital tract',
  'Z87430 Personal history of prostatic dysplasia',
  'Z87438 Personal history of other diseases of male genital organs',
  'Z87440 Personal history of urinary (tract) infections',
  'Z87441 Personal history of nephrotic syndrome',
  'Z87442 Personal history of urinary calculi',
  'Z87448 Personal history of other diseases of urinary system',
  'Z8751 Personal history of pre-term labor',
  'Z8759 Personal history of other complications of pregnancy, childbirth and the puerperium',
  'Z87710 Personal history of (corrected) hypospadias',
  'Z87718 Personal history of other specified (corrected) congenital malformations of genitourinary',
  'system',
  'Z87720 Personal history of (corrected) congenital malformations of eye',
  'Z87721 Personal history of (corrected) congenital malformations of ear',
  'Z87728 Personal history of other specified (corrected) congenital malformations of nervous system and',
  'sense organs',
  'Z87730 Personal history of (corrected) cleft lip and palate',
  'Z87738 Personal history of other specified (corrected) congenital malformations of digestive system',
  'Z8774 Personal history of (corrected) congenital malformations of heart and circulatory system',
  'Z8775 Personal history of (corrected) congenital malformations of respiratory system',
  'Z8776 Personal history of (corrected) congenital malformations of integument, limbs and',
  'musculoskeletal system',
  'Z87790 Personal history of (corrected) congenital malformations of face and neck',
  'Z87798 Personal history of other (corrected) congenital malformations',
  'Z8781 Personal history of (healed) traumatic fracture',
  'Z87820 Personal history of traumatic brain injury',
  'Z87821 Personal history of retained foreign body fully removed',
  'Z87828 Personal history of other (healed) physical injury and trauma',
  'Z87890 Personal history of sex reassignment',
  'Z87891 Personal history of nicotine dependence',
  'Z87892 Personal history of anaphylaxis',
  'Z87898 Personal history of other specified conditions',
  'Z880 Allergy status to penicillin',
  'Z881 Allergy status to other antibiotic agents status',
  'Z882 Allergy status to sulfonamides status',
  'Z883 Allergy status to other anti-infective agents status',
  'Z884 Allergy status to anesthetic agent status',
  'Z885 Allergy status to narcotic agent status',
  'Z886 Allergy status to analgesic agent status',
  'Z887 Allergy status to serum and vaccine status',
  'Z888 Allergy status to other drugs, medicaments and biological substances status',
  'Z889 Allergy status to unspecified drugs, medicaments and biological substances status',
  'Z89011 Acquired absence of right thumb',
  'Z89012 Acquired absence of left thumb',
  'Z89019 Acquired absence of unspecified thumb',
  'Z89021 Acquired absence of right finger(s)',
  'Z89022 Acquired absence of left finger(s)',
  'Z89029 Acquired absence of unspecified finger(s)',
  'Z89111 Acquired absence of right hand',
  'Z89112 Acquired absence of left hand',
  'Z89119 Acquired absence of unspecified hand',
  'Z89121 Acquired absence of right wrist',
  'Z89122 Acquired absence of left wrist',
  'Z89129 Acquired absence of unspecified wrist',
  'Z89201 Acquired absence of right upper limb, unspecified level',
  'Z89202 Acquired absence of left upper limb, unspecified level',
  'Z89209 Acquired absence of unspecified upper limb, unspecified level',
  'Z89211 Acquired absence of right upper limb below elbow',
  'Z89212 Acquired absence of left upper limb below elbow',
  'Z89219 Acquired absence of unspecified upper limb below elbow',
  'Z89221 Acquired absence of right upper limb above elbow',
  'Z89222 Acquired absence of left upper limb above elbow',
  'Z89229 Acquired absence of unspecified upper limb above elbow',
  'Z89231 Acquired absence of right shoulder',
  'Z89232 Acquired absence of left shoulder',
  'Z89239 Acquired absence of unspecified shoulder',
  'Z89411 Acquired absence of right great toe',
  'Z89412 Acquired absence of left great toe',
  'Z89419 Acquired absence of unspecified great toe',
  'Z89421 Acquired absence of other right toe(s)',
  'Z89422 Acquired absence of other left toe(s)',
  'Z89429 Acquired absence of other toe(s), unspecified side',
  'Z89431 Acquired absence of right foot',
  'Z89432 Acquired absence of left foot',
  'Z89439 Acquired absence of unspecified foot',
  'Z89441 Acquired absence of right ankle',
  'Z89442 Acquired absence of left ankle',
  'Z89449 Acquired absence of unspecified ankle',
  'Z89511 Acquired absence of right leg below knee',
  'Z89512 Acquired absence of left leg below knee',
  'Z89519 Acquired absence of unspecified leg below knee',
  'Z89521 Acquired absence of right knee',
  'Z89522 Acquired absence of left knee',
  'Z89529 Acquired absence of unspecified knee',
  'Z89611 Acquired absence of right leg above knee',
  'Z89612 Acquired absence of left leg above knee',
  'Z89619 Acquired absence of unspecified leg above knee',
  'Z89621 Acquired absence of right hip joint',
  'Z89622 Acquired absence of left hip joint',
  'Z89629 Acquired absence of unspecified hip joint',
  'Z899 Acquired absence of limb, unspecified',
  'Z9001 Acquired absence of eye',
  'Z9002 Acquired absence of larynx',
  'Z9009 Acquired absence of other part of head and neck',
  'Z9010 Acquired absence of unspecified breast and nipple',
  'Z9011 Acquired absence of right breast and nipple',
  'Z9012 Acquired absence of left breast and nipple',
  'Z9013 Acquired absence of bilateral breasts and nipples',
  'Z902 Acquired absence of lung [part of]',
  'Z903 Acquired absence of stomach [part of]',
  'Z90410 Acquired total absence of pancreas',
  'Z90411 Acquired partial absence of pancreas',
  'Z9049 Acquired absence of other specified parts of digestive tract',
  'Z905 Acquired absence of kidney',
  'Z906 Acquired absence of other parts of urinary tract',
  'Z90710 Acquired absence of both cervix and uterus',
  'Z90711 Acquired absence of uterus with remaining cervical stump',
  'Z90712 Acquired absence of cervix with remaining uterus',
  'Z90721 Acquired absence of ovaries, unilateral',
  'Z90722 Acquired absence of ovaries, bilateral',
  'Z9079 Acquired absence of other genital organ(s)',
  'Z9081 Acquired absence of spleen',
  'Z9089 Acquired absence of other organs',
  'Z91010 Allergy to peanuts',
  'Z91011 Allergy to milk products',
  'Z91012 Allergy to eggs',
  'Z91013 Allergy to seafood',
  'Z91018 Allergy to other foods',
  'Z9102 Food additives allergy status',
  'Z91030 Bee allergy status',
  'Z91038 Other insect allergy status',
  'Z91040 Latex allergy status',
  'Z91041 Radiographic dye allergy status',
  'Z91048 Other nonmedicinal substance allergy status',
  'Z9109 Other allergy status, other than to drugs and biological substances',
  "Z9111 Patient's noncompliance with dietary regimen",
  "Z91120 Patient's intentional underdosing of medication regimen due to financial hardship",
  "Z91128 Patient's intentional underdosing of medication regimen for other reason",
  "Z91130 Patient's unintentional underdosing of medication regimen due to age-related debility",
  "Z91138 Patient's unintentional underdosing of medication regimen for other reason",
  "Z9114 Patient's other noncompliance with medication regimen",
  "Z9115 Patient's noncompliance with renal dialysis",
  "Z9119 Patient's noncompliance with other medical treatment and regimen",
  'Z91410 Personal history of adult physical and sexual abuse',
  'Z91411 Personal history of adult psychological abuse',
  'Z91412 Personal history of adult neglect',
  'Z91419 Personal history of unspecified adult abuse',
  'Z9149 Other personal history of psychological trauma, not elsewhere classified',
  'Z915 Personal history of self-harm',
  'Z9181 History of falling',
  'Z9182 Personal history of military deployment',
  'Z9183 Wandering in diseases classified elsewhere',
  'Z9189 Other specified personal risk factors, not elsewhere classified',
  'Z920 Personal history of contraception',
  'Z9221 Personal history of antineoplastic chemotherapy',
  'Z9222 Personal history of monoclonal drug therapy',
  'Z9223 Personal history of estrogen therapy',
  'Z92240 Personal history of inhaled steroid therapy',
  'Z92241 Personal history of systemic steroid therapy',
  'Z9225 Personal history of immunosupression therapy',
  'Z9229 Personal history of other drug therapy',
  'Z923 Personal history of irradiation',
  'Z9281 Personal history of extracorporeal membrane oxygenation (ECMO)',
  'Z9282 Status post administration of tPA (rtPA) in a different facility within the last 24 hours prior to',
  'admission to current facility',
  'Z9283 Personal history of failed moderate sedation',
  'Z9284 Personal history of unintended awareness under general anesthesia',
  'Z9289 Personal history of other medical treatment',
  'Z930 Tracheostomy status',
  'Z931 Gastrostomy status',
  'Z932 Ileostomy status',
  'Z933 Colostomy status',
  'Z934 Other artificial openings of gastrointestinal tract status',
  'Z9350 Unspecified cystostomy status',
  'Z9351 Cutaneous-vesicostomy status',
  'Z9352 Appendico-vesicostomy status',
  'Z9359 Other cystostomy status',
  'Z936 Other artificial openings of urinary tract status',
  'Z938 Other artificial opening status',
  'Z939 Artificial opening status, unspecified',
  'Z940 Kidney transplant status',
  'Z941 Heart transplant status',
  'Z942 Lung transplant status',
  'Z943 Heart and lungs transplant status',
  'Z944 Liver transplant status',
  'Z945 Skin transplant status',
  'Z946 Bone transplant status',
  'Z947 Corneal transplant status',
  'Z9481 Bone marrow transplant status',
  'Z9482 Intestine transplant status',
  'Z9483 Pancreas transplant status',
  'Z9484 Stem cells transplant status',
  'Z9489 Other transplanted organ and tissue status',
  'Z949 Transplanted organ and tissue status, unspecified',
  'Z950 Presence of cardiac pacemaker',
  'Z951 Presence of aortocoronary bypass graft',
  'Z952 Presence of prosthetic heart valve',
  'Z953 Presence of xenogenic heart valve',
  'Z954 Presence of other heart-valve replacement',
  'Z955 Presence of coronary angioplasty implant and graft',
  'Z95810 Presence of automatic (implantable) cardiac defibrillator',
  'Z95811 Presence of heart assist device',
  'Z95812 Presence of fully implantable artificial heart',
  'Z95818 Presence of other cardiac implants and grafts',
  'Z95820 Peripheral vascular angioplasty status with implants and grafts',
  'Z95828 Presence of other vascular implants and grafts',
  'Z959 Presence of cardiac and vascular implant and graft, unspecified',
  'Z960 Presence of urogenital implants',
  'Z961 Presence of intraocular lens',
  'Z9620 Presence of otological and audiological implant, unspecified',
  'Z9621 Cochlear implant status',
  'Z9622 Myringotomy tube(s) status',
  'Z9629 Presence of other otological and audiological implants',
  'Z963 Presence of artificial larynx',
  'Z9641 Presence of insulin pump (external) (internal)',
  'Z9649 Presence of other endocrine implants',
  'Z965 Presence of tooth-root and mandibular implants',
  'Z9660 Presence of unspecified orthopedic joint implant',
  'Z96611 Presence of right artificial shoulder joint',
  'Z96612 Presence of left artificial shoulder joint',
  'Z96619 Presence of unspecified artificial shoulder joint',
  'Z96621 Presence of right artificial elbow joint',
  'Z96622 Presence of left artificial elbow joint',
  'Z96629 Presence of unspecified artificial elbow joint',
  'Z96631 Presence of right artificial wrist joint',
  'Z96632 Presence of left artificial wrist joint',
  'Z96639 Presence of unspecified artificial wrist joint',
  'Z96641 Presence of right artificial hip joint',
  'Z96642 Presence of left artificial hip joint',
  'Z96643 Presence of artificial hip joint, bilateral',
  'Z96649 Presence of unspecified artificial hip joint',
  'Z96651 Presence of right artificial knee joint',
  'Z96652 Presence of left artificial knee joint',
  'Z96653 Presence of artificial knee joint, bilateral',
  'Z96659 Presence of unspecified artificial knee joint',
  'Z96661 Presence of right artificial ankle joint',
  'Z96662 Presence of left artificial ankle joint',
  'Z96669 Presence of unspecified artificial ankle joint',
  'Z96691 Finger-joint replacement of right hand',
  'Z96692 Finger-joint replacement of left hand',
  'Z96693 Finger-joint replacement, bilateral',
  'Z96698 Presence of other orthopedic joint implants',
  'Z967 Presence of other bone and tendon implants',
  'Z9681 Presence of artificial skin',
  'Z9689 Presence of other specified functional implants',
  'Z969 Presence of functional implant, unspecified',
  'Z970 Presence of artificial eye',
  'Z9710 Presence of artificial limb (complete) (partial), unspecified',
  'Z9711 Presence of artificial right arm (complete) (partial)',
  'Z9712 Presence of artificial left arm (complete) (partial)',
  'Z9713 Presence of artificial right leg (complete) (partial)',
  'Z9714 Presence of artificial left leg (complete) (partial)',
  'Z9715 Presence of artificial arms, bilateral (complete) (partial)',
  'Z9716 Presence of artificial legs, bilateral (complete) (partial)',
  'Z972 Presence of dental prosthetic device (complete) (partial)',
  'Z973 Presence of spectacles and contact lenses',
  'Z974 Presence of external hearing-aid',
  'Z975 Presence of (intrauterine) contraceptive device',
  'Z978 Presence of other specified devices',
  'Z980 Intestinal bypass and anastomosis status',
  'Z981 Arthrodesis status',
  'Z982 Presence of cerebrospinal fluid drainage device',
  'Z983 Post therapeutic collapse of lung status',
  'Z9841 Cataract extraction status, right eye',
  'Z9842 Cataract extraction status, left eye',
  'Z9849 Cataract extraction status, unspecified eye',
  'Z9851 Tubal ligation status',
  'Z9852 Vasectomy status',
  'Z9861 Coronary angioplasty status',
  'Z9862 Peripheral vascular angioplasty status',
  'Z98810 Dental sealant status',
  'Z98811 Dental restoration status',
  'Z98818 Other dental procedure status',
  'Z9882 Breast implant status',
  'Z9883 Filtering (vitreous) bleb after glaucoma surgery status',
  'Z9884 Bariatric surgery status',
  'Z9885 Transplanted organ removal status',
  'Z9886 Personal history of breast implant removal',
  'Z98870 Personal history of in utero procedure during pregnancy',
  'Z98871 Personal history of in utero procedure while a fetus',
  'Z98890 Other specified postprocedural states',
  'Z98891 History of uterine scar from previous surgery',
  'Z990 Dependence on aspirator',
  'Z9911 Dependence on respirator [ventilator] status',
  'Z9912 Encounter for respirator [ventilator] dependence during power failure',
  'Z992 Dependence on renal dialysis',
  'Z993 Dependence on wheelchair',
  'Z9981 Dependence on supplemental oxygen',
  'Z9989 Dependence on other enabling machines and devices',
]
