import { MemberDiagnosisStatuses } from 'constants/selectOptions'
import { MentalDiagnosisTypes } from 'constants/diagnosisTypes'

import withMemberForm from 'HOCs/withMemberForm'
import { formatDateInMMMDDYYYY } from 'utils/formatters'

import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const DEFAULT_ROW = {
  date: new Date(),
  status: '',
  type: '',
}

const FIELDS = [
  {
    path: 'date',
    label: 'Date',
    cols: 3,
    type: 'date',
    valueFormatter: formatDateInMMMDDYYYY,
  },
  {
    path: 'status',
    label: 'Status',
    cols: 3,
    componentProps: {
      select: true,
      options: MemberDiagnosisStatuses,
    },
  },
  {
    path: 'type',
    label: 'Type',
    cols: 6,
    type: 'autocomplete',
    componentProps: {
      options: MentalDiagnosisTypes,
    },
  },
]

const DiagnosisCard = props => (
  <ArrayFieldSection
    isCard
    isGrid
    path="diagnosisList"
    title="Diagnosis"
    rowTitle="Diagnosis"
    fields={FIELDS}
    defaultRow={DEFAULT_ROW}
    actionText="Update"
    {...props}
  />
)

export default withMemberForm('DiagnosisCardForm', ['diagnosisList'])(
  DiagnosisCard,
)
