import 'typeface-roboto'
import React from 'react'
import ReactDOM from 'react-dom'
import { GoogleAuthProvider } from 'providers/GoogleAuthProvider'
import { Provider as ReduxProvider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { StylesProvider, ThemeProvider } from '@material-ui/core'

import 'react-quill/dist/quill.snow.css'
import 'normalize.css'
import 'index.css'

import App from 'App'
import reportWebVitals from 'reportWebVitals'
import { theme } from 'theme/theme'
import configureStore from 'redux/configureStore'
import NotistackProvider from 'NotistackProvider'

export const store = configureStore()

if (!['development', 'test'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <GoogleAuthProvider>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <ReduxProvider store={store}>
            <NotistackProvider>
              <App />
            </NotistackProvider>
          </ReduxProvider>
        </StylesProvider>
      </ThemeProvider>
    </GoogleAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
