import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  textfield: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.distinctiveGray.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.text.primary,
      },
      '&.Mui-focused fieldset': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiInputBase-input': {
      // prettier-ignore
      fontSize: (props) => props.size === 'normal' ? 14 : props.size === 'large' ? 16 : 12,
    },
    '& label:not(.MuiInputLabel-shrink)': {
      // prettier-ignore
      fontSize: (props) => props.size === 'normal' ? 14 : props.size === 'large' ? 16 : 12,
    },
  },
}))

export default function TextInput({
  variant = 'outlined',
  size = 'normal',
  fullWidth = true,
  ...props
}) {
  const classes = useStyles({ size })

  return (
    <TextField
      className={classes.textfield}
      variant={variant}
      fullWidth={fullWidth}
      {...props}
    />
  )
}
