import { isUndefined, omitBy } from 'lodash/fp'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import usePostRequest from 'hooks/api/usePostRequest'
import { MemberTypes } from 'constants/member'
import { API_BASE_URL } from 'constants/api'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import TextInput from 'ui/TextInput'
import TextButton from 'components/Shared/TextButton'

const useStyles = makeStyles(theme => ({
  container: {
    width: 480,
    height: 100,
  },

  documentTypeSelect: {
    width: '100%',
  },

  selectBox: {
    color: 'black',
  },

  emailInput: {
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'black',
    },
  },

  uploadedFileNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  defaultColor: {
    color: '#278C79',
  },

  dialogActions: {
    padding: theme.spacing(2, 2),
  },
}))

export default function SendDocumentViaEmailDialog({
  member,
  fileID,
  onClose,
}) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const postRequest = usePostRequest()
  const [sending, setSending] = useState(false)
  const [memberType, setMemberType] = useState(MemberTypes.MEMBER_SELF.value)
  const [customEmail, setCustomEmail] = useState('')

  const handleChangeMemberType = e => {
    setMemberType(e.target.value)
  }

  const handleChangeCustomEmail = e => {
    setCustomEmail(e.target.value)
  }

  const handleSend = async () => {
    setSending(true)

    try {
      await postRequest({
        url: `${API_BASE_URL}/files/email`,
        body: omitBy(isUndefined, {
          fileID,
          memberType,
          memberID: member._id,
          email:
            memberType === MemberTypes.CUSTOM.value ? customEmail : undefined,
        }),
      })

      onClose()
    } catch (error) {
      enqueueSnackbar('File download failed', {
        variant: 'error',
      })
    } finally {
      setSending(false)
    }
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Send document via email</DialogTitle>

      <DialogContent>
        <Grid container className={classes.container}>
          {sending && <LinearProgress />}
          <Grid item xs={12}>
            <Select
              name="memberType"
              value={memberType}
              className={classes.documentTypeSelect}
              classes={{ selectMenu: classes.selectBox }}
              onChange={handleChangeMemberType}
            >
              {Object.values(MemberTypes).map(type => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              placeholder="jinwoo.s.yu@gmail.com"
              value={customEmail}
              className={classes.emailInput}
              disabled={memberType !== MemberTypes.CUSTOM.value}
              onChange={handleChangeCustomEmail}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <TextButton onClick={onClose}>Cancel</TextButton>
        <TextButton onClick={handleSend}>Send</TextButton>
      </DialogActions>
    </Dialog>
  )
}
