import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { updateMember } from 'redux/members'
import { notesLoading, addNotes, stopLoadingNotes } from 'redux/notes'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function usePostNote() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const postNote = useCallback(
    async (note, member) => {
      dispatch(notesLoading())

      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/note`,
        options: {
          method: HTTP_METHODS.POST,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(note),
        },
      })

      if (response.ok) {
        const result = await response.json()
        const mem = { ...member }
        mem.notes.push(Object.keys(result)[0])
        dispatch(updateMember(mem))
        dispatch(addNotes(result))
        dispatch(stopLoadingNotes())
      } else {
        throw new Error('Failed to post note')
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return postNote
}
