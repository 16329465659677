import Grid from '@material-ui/core/Grid'

import SectionField from '../SectionField'

const ArrayFieldGridSectionLayout = ({ fields, rows, renderCell }) => (
  <Grid container>
    {rows.map((row, index) => (
      <Grid key={index} container item xs={12} spacing={2}>
        {fields.map(field => (
          <Grid key={field.path} item xs={field.cols || true}>
            <SectionField label={field.label}>
              {renderCell(row, field)}
            </SectionField>
          </Grid>
        ))}
      </Grid>
    ))}
  </Grid>
)

export default ArrayFieldGridSectionLayout
