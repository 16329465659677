import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'
import withMemberForm from 'HOCs/withMemberForm'

const FIELD = {
  path: 'drugAllergies',
  label: 'Drug Allergies',
  componentProps: {
    multiline: true,
  },
}

const DrugAllergiesSection = props => (
  <SingleFieldSection {...props} title="Drug Allergies" field={FIELD} />
)

export default withMemberForm('DrugAllergiesSectionForm', ['drugAllergies'])(
  DrugAllergiesSection,
)
