import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  title: {
    marginBottom: spacing(1),
    fontWeight: typography.fontWeightMedium,
  },

  content: {
    padding: spacing(1),

    '&:hover': {
      backgroundColor: palette.backgroundMint.main,
    },
  },
}))

function DivSectionContent({ title, showTitle, children, onEdit }) {
  const classes = useStyles()

  return (
    <div onClick={onEdit} className={classes.content}>
      {showTitle && (
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      )}

      {children}
    </div>
  )
}

export default DivSectionContent
