import { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import BaseCard from 'components/Shared/Card/BaseCard'
import Grid from '@material-ui/core/Grid'
import BaseRow from 'components/Shared/Card/BaseRow'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import CreateEncountersDialog from 'components/Member/MemberEncounters/Dialogs/CreateEncounterDialog'

const useStyles = makeStyles(() => ({
  hover: {
    '&:hover': {
      color: '#6D9147',
    },
  },
}))

export default function CreateEncounter() {
  const theme = useTheme()
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <BaseCard>
        <BaseRow
          columns={
            <Grid
              className={classes.hover}
              style={{
                padding: '16px',
                fontSize: '14px',
                textAlign: 'center',
                color: theme.palette.secondaryNavy2.main,
              }}
              item
              xs={12}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenDialog(true)
                }}
              >
                <AddCircleOutlineOutlinedIcon
                  style={{ paddingRight: '16px' }}
                />
                <Typography component="span" variant="body1">
                  Create a new member encounter
                </Typography>
              </div>
            </Grid>
          }
        ></BaseRow>
      </BaseCard>

      {openDialog && (
        <CreateEncountersDialog
          open={openDialog}
          onClose={handleClose}
          title="Create Encounter"
        />
      )}
    </>
  )
}
