import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useLayoutStyles, useCurrentUser } from 'hooks'

import { RX_SUB_PAGES } from 'constants/rx'
import { isEligibleForDoseSpot } from 'utils/rx'

import { useTheme } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'

import RequestAccess from 'components/Member/MemberRx/Registration/RequestAccess'
import PatientNotEligible from 'components/Member/MemberRx/Registration/PatientNotEligible'
import RegisterPatient from 'components/Member/MemberRx/Registration/RegisterPatient'
import PatientRx from 'components/Member/MemberRx/PatientRx'

async function selectSubPage(currentUser, member) {
  if (!currentUser.hasOwnProperty('doseSpotClinicianID')) {
    return RX_SUB_PAGES.RequestAccess
  }

  if (member.hasOwnProperty('doseSpotPatientID')) {
    return RX_SUB_PAGES.DisplayData
  }

  if (!(await isEligibleForDoseSpot(member))) {
    return RX_SUB_PAGES.PatientNotEligible
  }

  return RX_SUB_PAGES.RegisterPatient
}

export default function MemberRx({ member }) {
  const [subPage, setSubPage] = useState(null)
  const [loading, setLoading] = useState(true)
  const currentUser = useCurrentUser()

  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)

  useEffect(() => {
    const getPage = async ({ currentUser, member }) => {
      try {
        setLoading(true)
        const page = await selectSubPage(currentUser, member)
        setSubPage(page)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }

    getPage({ currentUser, member })
  }, [currentUser, member])

  const renderSubPage = page => {
    switch (page) {
      case RX_SUB_PAGES.RequestAccess:
        return <RequestAccess />

      case RX_SUB_PAGES.RegisterPatient:
        return <RegisterPatient member={member} userID={currentUser._id} />

      case RX_SUB_PAGES.DisplayData:
        return <PatientRx />

      case RX_SUB_PAGES.PatientNotEligible:
        return <PatientNotEligible member={member} />

      default:
        return null
    }
  }

  if (loading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div
      className={clsx(
        layoutClasses.fullWidth,
        layoutClasses.grid1,
        'member-rx-card-container',
      )}
    >
      {subPage && renderSubPage(subPage)}
    </div>
  )
}
