import clsx from 'clsx'
import { useRxStyles } from 'hooks/useRxStyles'
import { useTheme } from '@material-ui/core/styles'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'

export default function RequestAccess() {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)

  return (
    <div
      className={clsx(
        rxClasses.gridItemSpan2,
        theme.palette.primary,
        rxClasses.typography,
        rxClasses.fontSize12,
        rxClasses.font500,
      )}
    >
      <BaseCard>
        <BaseCardHeader
          title={
            'Please contact your clinic admin to access DoseSpot patient information.'
          }
        />
      </BaseCard>
    </div>
  )
}
