export const UserTypes = {
  STAFF: 'staff',
  CARE_COORDINATOR: 'cc',
  PSYCHIATRIST: 'psych',
  THERAPIST: 'therapist',
}

export const USER_TITLE = {
  STAFF: 'Staff',
  CARE_COORDINATOR: 'Care Coordinator',
  PSYCHIATRIST: 'Psychiatrist',
  THERAPIST: 'Therapist',
}

export const UserTitles = {
  [UserTypes.STAFF]: 'Staff',
  [UserTypes.CARE_COORDINATOR]: 'Care Coordinator',
  [UserTypes.PSYCHIATRIST]: 'Psychiatrist',
  [UserTypes.THERAPIST]: 'Therapist',
}
