import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  autocomplete: {
    '& .MuiChip-root': {
      color: theme.palette.text.primary,
      background: theme.palette.distinctiveGray2.main,
    },
    '& fieldset': {
      borderColor: theme.palette.distinctiveGray.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '& input': {
      fontSize: theme.spacing(2),
    },
    '& label:not(.MuiInputLabel-shrink)': {
      fontSize: theme.spacing(2),
    },
  },
}))

const StyledAutocomplete = props => {
  const classes = useStyles()
  return <Autocomplete className={classes.autocomplete} {...props} />
}

export default StyledAutocomplete
