import BaseAccordion from 'components/Shared/BaseAccordion'

import DrugAllergiesSection from './Sections/DrugAllergiesSection'
import MedicationsSection from './Sections/MedicationsSection'
import RelievedBySection from './Sections/RelievedBySection'
import RemarkSection from './Sections/RemarkSection'
import SubstancesSection from './Sections/SubstancesSection'
import SymptomsSection from './Sections/SymptomsSection'
import TreatmentsSection from './Sections/TreatmentsSection'
import WorsenedBySection from './Sections/WorsenedBySection'

export default function PresentIllnessHistoryAccordion(props) {
  return (
    <BaseAccordion title="History of Present Illness">
      <SymptomsSection {...props} />
      <RemarkSection {...props} />
      <RelievedBySection {...props} />
      <WorsenedBySection {...props} />
      <MedicationsSection {...props} />
      <TreatmentsSection {...props} />
      <SubstancesSection {...props} />
      <DrugAllergiesSection {...props} />
    </BaseAccordion>
  )
}
