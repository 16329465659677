import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { sortDate } from '../../../utils/commonHelpers'
import { addIndex } from '../../../utils/notesHelpers'
import { updateMember } from 'redux/members'
import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { addNotes, stopLoadingNotes } from 'redux/notes'
import { API_BASE_URL, HTTP_METHODS } from 'constants/api'
import { useSnackbar } from 'notistack'
import EncountersList from './EncountersList'
import CreateEncounter from './CreateEncounter'
import CircularProgress from '@material-ui/core/CircularProgress'

const MemberEncounters = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { memberId } = useParams()
  const { fetchWithRefresh } = useGoogleAuth()

  const [loading, notes, member] = useSelector(state => {
    const member = state.members.byId[memberId]
    const notes = member.notes
      .map(noteID => state.notes.notes[noteID])
      .sort((a, b) => sortDate(a, b))
      .filter(Boolean)
    const indexedNotes = addIndex(notes)
    return [state.notes.isLoading, indexedNotes, member]
  })

  const fetchNotes = async () => {
    const response = await fetchWithRefresh({
      url: `${API_BASE_URL}/cp/notes/${memberId}`,
      options: {
        method: HTTP_METHODS.GET,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })

    if (response.ok) {
      return await response.json()
    }
  }

  useEffect(() => {
    Promise.resolve(fetchNotes())
      .then(response => {
        if (response) {
          return response
        } else {
          throw new Error('Failed to fetch encounter data')
        }
      })
      .then(data => {
        dispatch(addNotes(data))
        dispatch(
          updateMember({
            ...member,
            notes: Object.keys(data),
          }),
        )
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(dispatch(stopLoadingNotes()))
    // eslint-disable-next-line
  }, [memberId])

  if (loading) {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="member-encounters">
      <CreateEncounter />
      <EncountersList encounters={notes} member={member} />
    </div>
  )
}

export default MemberEncounters
