import clsx from 'clsx'
import { useLayoutStyles } from 'hooks'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  border: {
    border: props => `1px solid ${props.color}`,
  },
  snackbar: {
    fontSize: '12px',
    lineHeight: '16px',
    color: props => `${props.color}`,
    backgroundColor: props => `${props.backgroundColor}`,
    boxShadow: 'none',
  },
})

export default function PGxSnackbar(props) {
  const { type } = props
  const theme = useTheme()
  const layoutClasses = useLayoutStyles(theme)
  const classes = useStyles(props)
  return (
    <SnackbarContent
      className={classes.snackbar}
      message={
        <Typography>
          <span className={clsx(layoutClasses.pr1)}>&#11044;</span>
          Medications with <strong>{type.toLowerCase()}</strong> prescribing
          considerations
        </Typography>
      }
    />
  )
}
