// Action Types
const SET_NOTES = 'notes/SET_NOTES'
const ADD_NOTES = 'notes/ADD_NOTES'
const SET_CPTCODES = 'notes/SET_CPTCODES'
const REMOVE_NOTE = 'notes/REMOVE_NOTE'
const NOTES_LOADING = 'notes/NOTES_LOADING'
const NOTES_FAILED = 'notes/NOTES_FAILED'
const NOTES_STOP_LOADING = 'notes/NOTES_STOP_LOADING'

const initialState = {
  notes: {},
  cptCodes: [],
  activityCodes: [],
  isLoading: true,
  error: null,
}

// Reducer
const notesStore = (state = initialState, action) => {
  const newNotes = { ...state.notes }

  switch (action.type) {
    case SET_NOTES:
      return {
        ...state,
        notes: { ...action.payload },
        isLoading: false,
      }
    case ADD_NOTES:
      return {
        ...state,
        notes: { ...newNotes, ...action.payload },
        isLoading: false,
      }
    case SET_CPTCODES:
      const activityCodes = action.payload.filter(
        payload => payload.category === 'cocm',
      )
      const cptCodes = action.payload.filter(
        payload => payload.category !== 'cocm',
      )
      return {
        ...state,
        cptCodes: [...cptCodes],
        activityCodes: [...activityCodes],
        isLoading: false,
      }
    case REMOVE_NOTE:
      delete newNotes[action.payload._id]
      return {
        ...state,
        notes: newNotes,
        isLoading: false,
      }
    case NOTES_LOADING:
      return { ...state, isLoading: true }
    case NOTES_STOP_LOADING:
      return { ...state, isLoading: false }
    case NOTES_FAILED:
      return { ...state, error: action.payload, isLoading: false }
    default:
      return state
  }
}

// ActionCreators
export const setNotes = notes => ({
  type: SET_NOTES,
  payload: notes,
})

export const addNotes = notes => ({
  type: ADD_NOTES,
  payload: notes,
})

export const setCptCodes = cptCodes => ({
  type: SET_CPTCODES,
  payload: cptCodes,
})

export const notesLoading = () => ({
  type: NOTES_LOADING,
})

export const notesFailed = error => ({
  type: NOTES_FAILED,
  payload: error,
})

export const removeNote = note => ({
  type: REMOVE_NOTE,
  payload: note,
})

export const startLoadingNotes = () => ({
  type: NOTES_LOADING,
})

export const stopLoadingNotes = () => ({
  type: NOTES_STOP_LOADING,
})
export default notesStore
