import { useState, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import usePdfDownload from 'hooks/api/files/usePdfDownload'
import { API_BASE_URL } from 'constants/api'
import { triggerDownload } from 'utils/commonHelpers'
import { formatDate, formatFullName } from 'utils/formatters'
import { ENCOUNTER_STATE } from 'utils/encounters'
import { selectAllUsers } from 'redux/selectors'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import LoadingModal from 'components/Modals/LoadingModal'
import EncounterStateChip from 'components/Shared/Chips/EncounterStateChip'
import Grid from '@material-ui/core/Grid'
import AvatarChip from 'components/Shared/Chips/AvatarChip'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseRow from 'components/Shared/Card/BaseRow'
import ContextMenu from 'components/Shared/ContextMenu'
import UpdateEncounterDialog from './Dialogs/UpdateEncounterDialog'
import { useArchiveEncounter } from 'hooks/api/encounters/useArchiveEncounter'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    padding: '8px',
  },
  [ENCOUNTER_STATE.DRAFT]: {
    border: `1px solid ${palette.accentRed.main}`,
  },
  fullName: {
    color: palette.primary.main,
    padding: spacing(1, 2),
    fontSize: '14px',
    textAlign: 'left',
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
}))

export default function Encounter({ encounter, member, isMemberNameShow }) {
  const classes = useStyles()
  const pdfDownload = usePdfDownload()
  const archiveEncounter = useArchiveEncounter()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [generatingDownload, setGeneratingDownload] = useState(false)
  const [showEncounterDialog, setShowEncounterDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const openContextMenu = Boolean(anchorEl)
  const users = useSelector(selectAllUsers)
  const encounterState = encounter.finalized
    ? encounter.noShow
      ? ENCOUNTER_STATE.NO_SHOW
      : ENCOUNTER_STATE.FINALIZED
    : ENCOUNTER_STATE.DRAFT

  const dateCreated = useMemo(() => {
    try {
      return formatDate(encounter.dateCreated)
    } catch {
      return ''
    }
  }, [encounter])

  const [encounterUser] = users.filter(user => user._id === encounter.userID)
  const downloadEncounter = async encounterId => {
    try {
      setGeneratingDownload(true)

      const pdfBlob = await pdfDownload({
        url: `${API_BASE_URL}/cp/note/${encounterId}/download`,
      })

      triggerDownload(
        pdfBlob,
        `Encounter_${member.firstName}${member.lastName}`,
      )
    } catch (error) {
      enqueueSnackbar('Pdf download failed', {
        variant: 'error',
      })
    } finally {
      setGeneratingDownload(false)
    }
  }

  const handleArchiveEncounter = async encounter => {
    try {
      await archiveEncounter(encounter)
      history.push(`/members/${encounter.memberID}/Encounters`)
      enqueueSnackbar('Encounter archived succesfully', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Failed archiving encounter', {
        variant: 'error',
      })
    }
  }

  const encounterOptions = {
    [ENCOUNTER_STATE.NO_SHOW]: [
      {
        label: 'Download PDF',
        value: 'download-pdf',
        onClick: e => {
          e.stopPropagation()
          downloadEncounter(encounter._id)
          handleMenuClose(e)
        },
        icon: <CloudDownloadOutlinedIcon />,
      },
      {
        label: 'Archive',
        onClick: e => {
          e.stopPropagation()
          handleArchiveEncounter(encounter)
          handleMenuClose(e)
        },
        value: 'archive',
        icon: <FolderOpenOutlinedIcon />,
      },
    ],
    [ENCOUNTER_STATE.FINALIZED]: [
      {
        label: 'Download PDF',
        value: 'download-pdf',
        onClick: e => {
          e.stopPropagation()
          downloadEncounter(encounter._id)
          handleMenuClose(e)
        },
        icon: <CloudDownloadOutlinedIcon />,
      },
      {
        label: 'Archive',
        onClick: e => {
          e.stopPropagation()
          handleArchiveEncounter(encounter)
          handleMenuClose(e)
        },
        value: 'archive',
        icon: <FolderOpenOutlinedIcon />,
      },
    ],
    [ENCOUNTER_STATE.DRAFT]: [
      {
        label: 'Update Encounter',
        onClick: e => {
          e.stopPropagation()
          setShowEncounterDialog(true)
          handleMenuClose(e)
        },
        value: 'update-encounter',
        icon: <DescriptionOutlinedIcon />,
      },
      {
        label: 'Archive',
        onClick: e => {
          e.stopPropagation()
          handleArchiveEncounter(encounter)
          handleMenuClose(e)
        },
        value: 'archive',
        icon: <FolderOpenOutlinedIcon />,
      },
    ],
  }

  const handleMenuClick = e => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = e => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleNameClick = e => {
    e.stopPropagation()
    history.push(`/members/${encounter.memberID}/Encounters`)
  }

  return (
    <BaseCard className={clsx(classes.card, classes[encounterState])}>
      <BaseRow
        columns={
          <>
            <Grid
              style={{
                padding: '8px 16px',
                fontSize: '14px',
                textAlign: 'left',
              }}
              item
              xs={3}
            >
              {dateCreated}
            </Grid>
            {!!isMemberNameShow && (
              <Grid
                className={classes.fullName}
                item
                xs={2}
                onClick={handleNameClick}
              >
                {formatFullName(member)}
              </Grid>
            )}
            <Grid
              style={{
                padding: '8px 16px',
                fontSize: '14px',
                textAlign: 'left',
              }}
              item
              xs={3}
            >
              {`${encounter.type}, ${encounter.duration} min`}
            </Grid>
            <Grid
              style={{
                padding: '8px 16px',
                fontSize: '14px',
                textAlign: 'right',
              }}
              item
              xs={!!isMemberNameShow ? 4 : 6}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'no-wrap',
                  justifyContent: 'end',
                }}
              >
                <div style={{ paddingRight: '16px' }}>
                  <EncounterStateChip state={encounterState} />
                </div>

                <div style={{ paddingRight: '16px' }}>
                  {encounterUser && <AvatarChip user={encounterUser} />}
                </div>
                <IconButton
                  aria-label="encounter options"
                  aria-controls="insurance-coverage-menu"
                  aria-haspopup="true"
                  size="small"
                  onClick={e => {
                    e?.stopPropagation()
                    handleMenuClick(e)
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <ContextMenu
                  anchorEl={anchorEl}
                  options={encounterOptions[encounterState]}
                  open={openContextMenu}
                  onClose={handleMenuClose}
                />
              </div>
            </Grid>
          </>
        }
      ></BaseRow>
      {showEncounterDialog && (
        <UpdateEncounterDialog
          encounter={encounter}
          open={showEncounterDialog}
          onClose={() => {
            setShowEncounterDialog(false)
          }}
        />
      )}

      {generatingDownload && <LoadingModal />}
    </BaseCard>
  )
}
