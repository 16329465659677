import keyBy from 'lodash/keyBy'

// ActionTypes
const SET_MEMBERS = 'members/SET_MEMBERS'
const ADD_MEMBER = 'members/ADD_MEMBER'
const UPDATE_MEMBER = 'members/UPDATE_MEMBER'
const MEMBERS_FAILED = 'members/MEMBERS_FAILED'
const MEMBERS_LOADING = 'members/MEMBERS_LOADING'
const MEMBERS_LOADED = 'members/MEMBERS_LOADED'

const initialState = {
  isLoading: true,
  error: null,
  byId: {},
  allIds: [],
}

// Reducer
const membersStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_MEMBERS:
      const members = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        byId: keyBy(members, '_id'),
        allIds: members.map(member => member._id),
      }

    case ADD_MEMBER:
      const newMember = action.payload

      return {
        ...state,
        isLoading: false,
        byId: { ...state.byId, [newMember._id]: newMember },
        allIds: [...state.allIds, newMember._id],
      }

    case UPDATE_MEMBER:
      const updatedMember = action.payload

      return {
        ...state,
        isLoading: false,
        byId: { ...state.byId, [updatedMember._id]: updatedMember },
      }

    case MEMBERS_LOADING:
      return { ...state, isLoading: true }

    case MEMBERS_LOADED:
      return { ...state, isLoading: false }

    case MEMBERS_FAILED:
      return { ...state, isLoading: false, error: action.payload }

    default:
      return state
  }
}

// ActionCreators
export const setMembers = members => ({
  type: SET_MEMBERS,
  payload: members,
})

export const membersFailed = error => ({
  type: MEMBERS_FAILED,
  payload: error,
})

export const membersLoading = () => ({
  type: MEMBERS_LOADING,
})

export const membersLoaded = () => ({
  type: MEMBERS_LOADED,
})

export const addMember = member => ({
  type: ADD_MEMBER,
  payload: member,
})

export const updateMember = member => ({
  type: UPDATE_MEMBER,
  payload: member,
})

export default membersStore
