import { MemberDegrees } from 'constants/selectOptions'
import withMemberForm from 'HOCs/withMemberForm'
import SingleFieldSection from 'components/Shared/Sections/SingleFieldSection'

const FIELD = {
  path: 'highestDegree',
  label: 'Highest Degree',
  componentProps: {
    select: true,
    options: MemberDegrees,
  },
}

const HighestDegreeSection = props => (
  <SingleFieldSection {...props} title="Highest Degree" field={FIELD} />
)

export default withMemberForm('HighestDegreeSectionForm', ['highestDegree'])(
  HighestDegreeSection,
)
