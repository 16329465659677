import { withFormik } from 'formik'
import { withSnackbar } from 'notistack'
import flowRight from 'lodash/fp/flowRight'
import pick from 'lodash/pick'
import isArray from 'lodash/isArray'

import { DEFAULT_MEMBER } from 'constants/member'
import { deserializeMember, serializeMember } from 'utils/memberHelpers'
import { memberSchema } from 'utils/validationSchemas'

const withMemberForm = (displayName, fields) => {
  const pickFields = values => (isArray(fields) ? pick(values, fields) : values)

  return flowRight(
    withSnackbar,
    withFormik({
      displayName,
      enableReinitialize: true,

      validationSchema: () =>
        isArray(fields) ? memberSchema.pick(fields) : memberSchema,

      mapPropsToValues({ member = DEFAULT_MEMBER }) {
        return pickFields(deserializeMember(member))
      },

      async handleSubmit(
        values,
        { props: { member, successMessage, enqueueSnackbar, onSubmit } },
      ) {
        try {
          await onSubmit({
            ...member,
            ...pickFields(serializeMember(values)),
          })

          if (successMessage) {
            enqueueSnackbar(successMessage, {
              variant: 'success',
            })
          }
        } catch (e) {
          enqueueSnackbar(
            `Error in ${member ? 'updating' : 'creating'} member`,
            {
              variant: 'error',
            },
          )
        }
      },
    }),
  )
}

export default withMemberForm
