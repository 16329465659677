import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles(
  theme => ({
    dialogRoot: {
      '& .MuiDialogContent-root': {
        paddingTop: '0',
        height: 'fit-content',
        overflowY: 'visible',
      },
      '& .MuiDialog-paperWidthMd': {
        maxWidth: '675px!important',
      },
      '& .MuiDialogTitle-root': {
        padding: theme.spacing(4, 3, 0, 3),
        '& .MuiTypography-h6': {
          fontSize: 20,
          color: theme.palette.primaryNavy,
        },
      },
      '& div .MuiPaper-root': {
        width: '100%',
      },
      '& .MuiFormControl-root': {
        padding: '0',
        marginTop: 16,
      },
      '& .MuiInputBase-input': {
        lineHeight: '24px',
        letterSpacing: '0.15px',
        fontSize: 16,
      },
      '& .MuiDialogActions-root': {
        display: 'flex!important',
        padding: theme.spacing(4, 3),
      },
      '& .MuiDialogActions-spacing > :not(:first-child)': {
        marginLeft: theme.spacing(4),
      },
      '& .MuiDialogActions-spacing > :first-child:nth-last-child(n + 3)': {
        marginRight: 'auto',
      },
    },
  }),
  { index: 1 },
)

export default function BaseDialog({
  title,
  open,
  className,
  onClose,
  dialogContent,
  dialogActions,
  loading = false,
  fullWidth = false,
  maxWidth = 'sm',
}) {
  const theme = useTheme()
  const rxClasses = useStyles(theme)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={clsx(rxClasses.dialogRoot, className)}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {loading ? (
          <div className="centered">
            <CircularProgress />
          </div>
        ) : (
          dialogContent
        )}
      </DialogContent>

      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  )
}
