import { useMemo } from 'react'
import { parse } from 'date-fns'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'

import { daysBetween, formatFullName, formatDate } from 'utils/formatters'
import { useCurrentUser } from 'hooks'
import { isValidPGxRecord } from 'utils/pgx'

export const useCompletedPGx = (isMyPatientCheck, allMembers, pgxs) => {
  const currentUser = useCurrentUser()

  const filteredMembers = useMemo(
    () =>
      isMyPatientCheck
        ? allMembers.filter(
            member =>
              member.carePartner === currentUser._id ||
              member.psychiatrist === currentUser._id ||
              member.therapist === currentUser._id,
          )
        : allMembers,
    [allMembers, currentUser, isMyPatientCheck],
  )

  const sortedPGxs = useMemo(
    () =>
      groupBy(
        orderBy(
          pgxs.map(pgx => {
            const member = allMembers.find(
              member => member.kitNumber === pgx.kitNumber,
            )
            let reportedAt
            let processTime
            try {
              reportedAt = parse(pgx.reportDate, 'MMMM dd, yyyy', new Date())
              processTime = !!member?.kitDate
                ? daysBetween(reportedAt, member.kitDate)
                : -1
            } catch {}
            return {
              _id: pgx._id,
              memberId: member?._id || '',
              fullName: !!member ? formatFullName(member, true) : 'N/A',
              processTime,
              daysSinceReported: daysBetween(new Date(), reportedAt),
              reportedAt,
              kitNumber: pgx.kitNumber,
              isComplete: true,
              isValid: isValidPGxRecord(pgx, member),
            }
          }),
          ['reportedAt'],
          ['desc'],
        ),
        'kitNumber',
      ),
    [allMembers, pgxs],
  )

  const latestPGxs = useMemo(
    () => Object.keys(sortedPGxs).map(key => sortedPGxs[key][0]),
    [sortedPGxs],
  )

  const filteredPGxs = useMemo(
    () =>
      isMyPatientCheck
        ? latestPGxs.filter(
            pgx =>
              filteredMembers.findIndex(
                member => member._id === pgx.memberId,
              ) !== -1,
          )
        : latestPGxs,
    [filteredMembers, isMyPatientCheck, latestPGxs],
  )

  return filteredPGxs
}

export const usePendingPGx = (isMyPatientCheck, allMembers, pgxs) => {
  const currentUser = useCurrentUser()

  const filteredMembers = useMemo(
    () =>
      isMyPatientCheck
        ? allMembers.filter(
            member =>
              member.carePartner === currentUser._id ||
              member.psychiatrist === currentUser._id ||
              member.therapist === currentUser._id,
          )
        : allMembers,
    [allMembers, currentUser, isMyPatientCheck],
  )

  const sortedMembers = useMemo(
    () =>
      orderBy(
        filteredMembers.map(member => {
          const reportedAt = member.kitDate
            ? new Date(member.kitDate)
            : new Date()
          const processTime = daysBetween(
            formatDate(new Date(), 'yyyy-MM-dd 00:00:00'),
            formatDate(reportedAt, 'yyyy-MM-dd 00:00:00'),
          )
          return {
            _id: member._id,
            memberId: member._id,
            fullName: formatFullName(member, true),
            processTime,
            daysSinceReported: daysBetween(new Date(), reportedAt),
            reportedAt,
            kitNumber: member.kitNumber,
            isComplete: false,
          }
        }),
        ['reportedAt'],
        ['asc'],
      ),
    [filteredMembers],
  )

  const filteredPGxs = useMemo(
    () =>
      sortedMembers.filter(
        member =>
          !!member.kitNumber &&
          pgxs.findIndex(
            original => original.kitNumber === member.kitNumber,
          ) === -1,
      ),
    [pgxs, sortedMembers],
  )

  return filteredPGxs
}
