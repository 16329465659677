import {
  getParsedSessionStorageItem,
  setJsonSessionStorageItem,
} from 'utils/commonHelpers'

// ActionTypes
const SET_AUTHORIZATION = 'auth/SET_AUTHORIZATION'
const REMOVE_AUTHORIZATION = 'auth/REMOVE_AUTHORIZATION'

const initialState = { authorized: null }

// Reducer
const authStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHORIZATION:
      return {
        ...state,
        authorized: action.payload,
      }
    case REMOVE_AUTHORIZATION:
      return { ...state, authorized: null }
    default:
      return state
  }
}

// Dispatchers
export const authorize = authorization => dispatch => {
  dispatch(setAuthorization(authorization))
  setJsonSessionStorageItem('authorization', authorization)
  localStorage.setItem('signedIn', authorization)
}

export const unAuthorize = dispatch => {
  sessionStorage.removeItem('authorization')
  localStorage.removeItem('signedIn')
  dispatch(removeAuthorization())
}

// ActionCreators
export const setAuthorization = authorization => ({
  type: SET_AUTHORIZATION,
  payload: authorization,
})

export const removeAuthorization = () => ({
  type: REMOVE_AUTHORIZATION,
})

export const getAuthorizationFromStorage = () => {
  return getParsedSessionStorageItem('authorization')
}

// token refresh
export const TOKEN_REFRESH_DELAY = 300 * 1000 - Date.now() <= 0

export default authStore
