import { MemberSubstanceTypes } from 'constants/selectOptions'

import withMemberForm from 'HOCs/withMemberForm'
import ArrayFieldSection from 'components/Shared/Sections/ArrayFieldSection'

const DEFAULT_ROW = {
  type: '',
  description: '',
}

const FIELDS = [
  {
    path: 'type',
    label: 'Type of Substance',
    headerClassName: 'first-col',
    componentProps: {
      select: true,
      options: MemberSubstanceTypes,
    },
  },
  {
    path: 'description',
    label: 'Description',
  },
]

const SubstancesSection = props => (
  <ArrayFieldSection
    path="substanceList"
    title="Substance Use"
    rowTitle="Substance"
    fields={FIELDS}
    defaultRow={DEFAULT_ROW}
    {...props}
  />
)

export default withMemberForm('SubstancesSectionForm', ['substanceList'])(
  SubstancesSection,
)
