import { useEffect, useState } from 'react'
import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import PageVisibility from 'react-page-visibility'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { authorize, unAuthorize } from 'redux/auth'
import { selectAuthorization } from 'redux/selectors'
import { VISIBILITY_STATES } from 'constants/api'
import LoginPage from 'components/Login/Login'
import Dashboard from 'components/Dashboard'

function FallbackComponent() {
  return <div>An error has occured</div>
}

export default function App() {
  const authorized = useSelector(selectAuthorization)
  const dispatch = useDispatch()
  const { googleAuth, googleUser, tokenObj, shouldRefreshToken, refreshUser } =
    useGoogleAuth()
  const [loginError, setLoginError] = useState(null)

  const visibilityChange = async (isVisible, visibilityState) => {
    const nowVisibile =
      isVisible && visibilityState === VISIBILITY_STATES.VISIBLE
    if (nowVisibile) {
      const refreshing = shouldRefreshToken(tokenObj.expires_at)
      if (refreshing) {
        const refreshedUser = await refreshUser()
        dispatch(authorize(refreshedUser))
      }
    }
  }

  //handling sign in syncronously because browsers block the auth pop up otherwise
  const handleSignIn = () => {
    googleAuth.signIn().then(googleResponse => {
      if (googleResponse) {
        dispatch(authorize(true))
      } else {
        setLoginError('Login Failed')
      }
    })
  }

  useEffect(() => {
    if (googleAuth && googleUser) {
      const alreadySignedIn = googleUser.isSignedIn()
      const previouslySignedIn = localStorage.getItem('signedIn')
      if (previouslySignedIn && alreadySignedIn) {
        dispatch(authorize(true))
      }

      googleAuth.isSignedIn.listen(signedIn => {
        if (signedIn && !authorized) {
          dispatch(authorize(true))
        } else {
          unAuthorize(dispatch)
        }
      })
    }
  }, [dispatch, googleAuth, googleUser, authorized])

  return googleAuth && googleUser && authorized ? (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <PageVisibility onChange={visibilityChange}>
        <Dashboard />
      </PageVisibility>
    </Sentry.ErrorBoundary>
  ) : (
    <LoginPage onSignIn={handleSignIn} errorMessage={loginError} />
  )
}
