import { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useSnackbar } from 'notistack'
import { isEmpty } from 'lodash/fp'
import { useTheme } from '@material-ui/core/styles'
import { useRxStyles, useMemberRx } from 'hooks'
import useGetRequest from 'hooks/api/useGetRequest'
import { API_BASE_URL } from 'constants/api'

import {
  formatDateInMMDDYYYY,
  daysBetween,
  getDaysSupplyDate,
} from 'utils/formatters'
import {
  ALERT_WITHIN_DAYS,
  PHARMACIES_WITH_DELIVERY,
  PRESCRIPTION_STATUS,
} from 'constants/rx'

import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'

export default function CurrentRx({ prescriptions }) {
  const { setIsLoading, token, memberId, userId } = useMemberRx()
  const [link, setLink] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const getRequest = useGetRequest()

  const Rows = ({ data }) => {
    if (isEmpty(data)) {
      return <BaseRow />
    }
    return data.map((rx, i) => {
      const rxLastDayOfSupply = getDaysSupplyDate(rx)
      const refillDaysLeft = rxLastDayOfSupply
        ? daysBetween(rxLastDayOfSupply, new Date())
        : null
      const canDeliver = PHARMACIES_WITH_DELIVERY.includes(
        rx.pharmacy.PharmacyId,
      )
      const shouldAlert = rx.Status !== PRESCRIPTION_STATUS.PHARMACYVERIFIED

      return (
        <BaseRow
          withDivider
          key={`${rx.PrescriptionId}`}
          columns={
            <Grid
              key={`${rx.PrescriptionId}-${i}`}
              container
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.secondaryMint,
                    rxClasses.typography,
                    rxClasses.fontSize12,
                    rxClasses.fontWeight500,
                  )}
                >
                  {rx.DisplayName}
                </Typography>
                {shouldAlert && <span> &#9888;&#65039;</span>}
              </Grid>
              <Grid item xs={3} className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.typography,
                    rxClasses.fontSize12,
                    theme.typography.fontWeightRegular,
                  )}
                >
                  {rxLastDayOfSupply ? (
                    <>
                      {formatDateInMMDDYYYY(rx.WrittenDate)} ~{' '}
                      {rxLastDayOfSupply}, ({rx.DaysSupply} days, {rx.Refills}
                      refill(s))
                    </>
                  ) : (
                    <span>No DaysSupply provided</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs className={rxClasses.columnPadding}>
                <Typography
                  component="span"
                  variant="body1"
                  className={clsx(
                    rxClasses.typography,
                    rxClasses.fontSize12,
                    theme.typography.fontWeightRegular,
                  )}
                >
                  {rx.Directions}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                className={clsx(
                  rxClasses.columnPadding,
                  rxClasses.textAlignRight,
                )}
              >
                {canDeliver && (
                  <Chip
                    label="Delivery"
                    className={clsx(
                      rxClasses.typography,
                      rxClasses.chipMint,
                      theme.layout.pl1,
                    )}
                    size="small"
                  />
                )}

                {refillDaysLeft && refillDaysLeft <= ALERT_WITHIN_DAYS && (
                  <Chip
                    label={`${refillDaysLeft} Days Left`}
                    className={clsx(rxClasses.typography, rxClasses.chipRed)}
                    size="small"
                  />
                )}
              </Grid>
            </Grid>
          }
        />
      )
    })
  }

  const getSsoLink = useCallback(
    async (userId, memberId) => {
      try {
        setIsLoading(true)
        const response = await getRequest({
          url: `${API_BASE_URL}/dosespot/sso/patient/${userId}&${memberId}`,
        })
        setLink({ url: response.PatientSsoUrl })
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        enqueueSnackbar('Failed to generate app link', { variant: 'error' })
      }
    },
    [enqueueSnackbar, getRequest, setIsLoading],
  )

  useEffect(() => {
    getSsoLink(userId, memberId)
  }, [getSsoLink, token, userId, memberId])

  const title = 'Current Rx'

  return (
    <BaseCard>
      <BaseCardHeader
        title={title}
        actionOnClick={() => {
          link && window.open(link.url, '_blank')
        }}
        actionText={'Open Prescribing App'}
      />
      {prescriptions?.length ? (
        <Rows data={prescriptions} />
      ) : (
        <Grid item xs className={rxClasses.columnPadding}>
          <BaseRow emptyPlaceholder="None Added" />
        </Grid>
      )}
    </BaseCard>
  )
}
