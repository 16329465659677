import clsx from 'clsx'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  dateSelector: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e3e3e3',
      },
      '&:hover fieldset': {
        borderColor: '#daefed',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid #36bca8',
      },
    },
    '& input': {
      fontSize: 16,
    },
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.accentRed.main,
      },
    },
  },
  timePickerPadding: {
    '& .MuiOutlinedInput-root': {
      padding: '16px !important',
    },
  },
}))

export default function TimePicker({
  label,
  id,
  value,
  name,
  onChange,
  helperText,
  error,
  fullWidth = true,
  variant = 'inline',
  dialog = false,
  ...props
}) {
  const classes = useStyles()

  return (
    <div
      name={name}
      className={clsx(classes.dateSelector, {
        [classes.error]: error,
      })}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          className={!dialog ? classes.timePickerPadding : ''}
          label={label}
          placeholder="00:00 PM"
          mask="__:__ _M"
          inputVariant="outlined"
          fullWidth={fullWidth}
          variant={variant}
          value={value}
          onChange={onChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
