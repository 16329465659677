import { makeStyles } from '@material-ui/core/styles'

import DxChip from './DxChip'
import SnapshotChip from './SnapshotChip'
import MemoChip from './MemoChip'
import MessagesChip from './MessagesChip'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    gridGap: spacing(1.5),
  },
}))

const ChipMenu = ({ member }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SnapshotChip member={member} />
      <DxChip member={member} />
      <MemoChip member={member} />
      <MessagesChip member={member} />
    </div>
  )
}

export default ChipMenu
