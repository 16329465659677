import './Tooltip.css'

export default function Tooltip({ slice }) {
  const delta = n => {
    if (n === null) return ''
    if (n > 0) return <span className="red">(+{n})</span>
    return <span className="mint">({n})</span>
  }

  const rect = point => {
    let color
    if (point.serieId === 'Total') color = '#3C82EB'
    if (point.serieId === 'GAD-7') color = '#6D9147'
    if (point.serieId === 'PHQ-9') color = '#DDAF0C'
    return (
      <svg width="10" height="10">
        <rect width="10" height="10" style={{ fill: color }} />
      </svg>
    )
  }

  return (
    <div className="tooltip">
      <h5 className="date">
        <strong>{slice.points[0]?.data.xFormatted}</strong>
      </h5>
      {slice.points.map((point, i) => {
        return (
          <h5 key={i}>
            {rect(point)} {point.serieId}: {point.data.yFormatted}{' '}
            {delta(point.data.difference)}
          </h5>
        )
      })}
    </div>
  )
}
