import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const BaseProfile = ({ avatarUrl, initials, title, name, avatarClassName }) => {
  const renderAvatar = () => {
    if (avatarUrl) {
      return <Avatar src={avatarUrl} alt={name} />
    }

    return (
      <Avatar className={avatarClassName}>
        <span style={{ fontSize: '10px' }}>{initials}</span>
      </Avatar>
    )
  }

  return (
    <Box display="flex" alignItems="center">
      {renderAvatar()}

      <Box flexGrow={1} ml={2}>
        <Typography variant="caption">{title}</Typography>
        <Typography variant="body1">{name}</Typography>
      </Box>
    </Box>
  )
}

export default BaseProfile
