import {
  makeStyles,
  Box,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    padding: spacing(0, 3),
    alignItems: 'center',
    backgroundColor: 'white',
    marginBottom: spacing(3),
    borderBottom: `1px solid ${palette.distinctiveGray.main}`,
  },
  tabs: {
    color: palette.text.primary,
    fontWeight: 400,
    flexGrow: 1,
  },
  tab: {
    minWidth: '96px',
    padding: spacing(1, 0),
    textTransform: 'none',
  },
  showMyPatients: {
    margin: 0,
    '& .MuiButtonBase-root': {
      padding: spacing(0.5),
    },
    '& svg': {
      width: '16px',
      height: '16px',
    },
    '& .MuiFormControlLabel-label': {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: 12,
      paddingLeft: spacing(1),
    },
  },
}))

const PGxHeader = ({
  selected,
  handleTabChange,
  isChecked,
  handleCheckboxChange,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Tabs
        value={selected}
        onChange={(_, newValue) => handleTabChange(newValue)}
        className={classes.tabs}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Completed" className={classes.tab} />
        <Tab label="Pending" className={classes.tab} />
      </Tabs>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isChecked}
            onChange={e => handleCheckboxChange(e.target.checked)}
          />
        }
        label="Only show my patients"
        className={classes.showMyPatients}
      />
    </Box>
  )
}

export default PGxHeader
