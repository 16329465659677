import { isEmpty } from 'lodash/fp'
import clsx from 'clsx'

import { useTheme } from '@material-ui/core/styles'
import { useRxStyles, useLayoutStyles } from 'hooks'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

export default function BaseRow({
  columns,
  emptyPlaceholder = 'None Added',
  withDivider = false,
  justifyContent = 'space-evenly',
}) {
  const theme = useTheme()
  const rxClasses = useRxStyles(theme)
  const layoutClasses = useLayoutStyles(theme)

  const EmptyRow = () => {
    return (
      <Grid item xs={12} className={layoutClasses.padding1}>
        <Typography
          component="span"
          variant="body1"
          className={clsx(
            rxClasses.typography,
            rxClasses.fontSize14,
            theme.typography.fontWeightBold,
            theme.palette.primary,
          )}
        >
          {emptyPlaceholder}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={justifyContent}
      className={clsx({ [rxClasses.dividerTop]: withDivider })}
    >
      {(columns && columns !== undefined) || !isEmpty(columns) ? (
        columns
      ) : (
        <EmptyRow />
      )}
    </Grid>
  )
}
