export function parseComment(comment, delim = 'label', highlight = true) {
  const splitComment = comment
    .split(/\{\{\{(.*?)\}\}\}/g)
    .filter(mention => mention !== '')

  const newComment = splitComment.reduce((lastItem, currentItem) => {
    if (currentItem.match(delim)) {
      const withoutQuotes = currentItem.replace(/['"]+/g, '')
      const label = withoutQuotes
        .substring(currentItem.indexOf(delim) + 1)
        .trim()

      return (
        lastItem +
        (highlight
          ? `<span style="color: #278c79;">@${label}</span>`
          : `@${label}`)
      )
    }
    return lastItem + currentItem
  }, '')
  return newComment
}
