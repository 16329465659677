import { useState } from 'react'
import clsx from 'clsx'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import PGxItem from './PGxItem'
import { useRxStyles, useCompletedPGx } from 'hooks'
import { selectAllMembers } from 'redux/selectors'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    overflowY: 'scroll',
    padding: spacing(0, 3),
    flexGrow: 1,
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(4, 0),
  },
  button: {
    margin: spacing(0, 2),
    color: palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 700,
    '&:hover': {
      color: palette.text.primary,
      cursor: 'pointer',
    },
  },
}))

const CompletePGxs = ({ isMyPatientCheck }) => {
  const classes = useStyles()
  const rxClasses = useRxStyles()

  const [counts, setCounts] = useState(15)
  const allMembers = useSelector(selectAllMembers)
  const pgxs = useSelector(({ pgxs }) => pgxs.allPgxs)

  const filteredPGxs = useCompletedPGx(isMyPatientCheck, allMembers, pgxs)

  return (
    <Box className={classes.root}>
      {!filteredPGxs.length && (
        <Typography
          className={clsx(
            rxClasses.textAlignCenter,
            rxClasses.fontSize16,
            rxClasses.secondaryNavy2,
          )}
        >
          No completed orders
        </Typography>
      )}
      {filteredPGxs.slice(0, counts).map(pgx => (
        <PGxItem key={pgx._id} pgx={pgx} />
      ))}
      {filteredPGxs.length > counts && (
        <Box className={classes.buttonBox}>
          <Typography
            className={clsx(classes.button, rxClasses.fontSize14)}
            onClick={() => setCounts(counts + 15)}
          >
            Load More
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default CompletePGxs
