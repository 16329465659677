import { format } from 'date-fns'
import { useRxStyles } from 'hooks'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BaseCard from 'components/Shared/Card/BaseCard'
import BaseCardHeader from 'components/Shared/Card/BaseCardHeader'
import BaseRow from 'components/Shared/Card/BaseRow'

const useStyles = makeStyles(theme => ({
  noteHistories: {
    marginTop: theme.spacing(2),
  },
  history: {
    display: 'block',
    fontStyle: 'italic',
  },
}))

export default function EncounterHistories({ encounter }) {
  const classes = useStyles()
  const rxClasses = useRxStyles()
  const renderHistory = history => {
    // This is created with the new schema of NoteHistory which has timeStamp
    if (history.timeStamp) {
      return `${history.description} on ${format(
        new Date(history.timeStamp),
        'MMMM dd, yyyy hh:mm a',
      )}`
    } else {
      return history.description
    }
  }

  return (
    <BaseCard className={classes.noteHistories}>
      <BaseCardHeader className={rxClasses.fontSize14} bold title="History" />
      <BaseRow
        withDivider={true}
        key="encounter-history"
        columns={
          <Grid container>
            <Grid className={rxClasses.columnPadding} item xs={12}>
              {encounter.noteHistories
                ?.filter(history => !history.autosave)
                .map((history, i) => (
                  <Typography
                    key={i}
                    variant="caption"
                    className={classes.history}
                  >
                    {renderHistory(history)}
                  </Typography>
                ))}
            </Grid>
          </Grid>
        }
      />
    </BaseCard>
  )
}
