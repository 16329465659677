import _ from 'lodash'
import { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularProgress, Grid } from '@material-ui/core'

import MemberProgressItem from '../Item/MemberProgressItem'

export default function ProgressList({
  progresses,
  isLoading,
  hasMore = false,
  onLoadMore,
  onClickViewDetails,
  onClickReview,
}) {
  const sortedProgresses = useMemo(() => {
    return _.orderBy(progresses, 'timeStamp', 'desc')
  }, [progresses])

  return (
    <InfiniteScroll
      dataLength={sortedProgresses.length}
      next={onLoadMore}
      hasMore={hasMore}
      loading={isLoading}
      loader={
        <Grid item align="center" xs={12}>
          <CircularProgress />
        </Grid>
      }
    >
      <Grid container>
        {sortedProgresses.map(progress => (
          <Grid key={progress._id} item xs={12}>
            <MemberProgressItem
              progress={progress}
              onClickReview={onClickReview}
              onClickViewDetails={onClickViewDetails}
            />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  )
}
