import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { startLoadingNotes, stopLoadingNotes, removeNote } from 'redux/notes'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export const useArchiveEncounter = () => {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const archiveEncounter = useCallback(
    async encounter => {
      dispatch(startLoadingNotes())
      const archivedEncounter = { ...encounter, archived: true }
      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/cp/note/${encounter._id}`,
        options: {
          method: HTTP_METHODS.PUT,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ note: archivedEncounter }),
        },
      })

      if (response.ok) {
        dispatch(removeNote(archivedEncounter))
        dispatch(stopLoadingNotes())
      } else {
        throw new Error('Failed to archive note')
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return archiveEncounter
}
