import { Field } from 'formik'
import Grid from '@material-ui/core/Grid'

import { getFormikComponent } from 'utils/formik'

const MultiValueSectionForm = ({ fieldsList }) => (
  <Grid container spacing={2}>
    {fieldsList.map(({ fields, label: fieldsLabel }) =>
      fields.map(({ path, type, label, componentProps }, index) => {
        const component = getFormikComponent(type)

        return (
          <Grid key={path} item xs={12}>
            <Field
              name={path}
              component={component}
              label={`${label}${index === 1 ? ` (${fieldsLabel})` : ''}`}
              {...componentProps}
            />
          </Grid>
        )
      }),
    )}
  </Grid>
)

export default MultiValueSectionForm
