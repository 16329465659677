import { useGoogleAuth } from 'providers/GoogleAuthProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  memberFileUploading,
  memberFileUploadSuccess,
  memberFileUploadError,
} from 'redux/files/actions'

import { API_BASE_URL, HTTP_METHODS } from 'constants/api'

export default function useFileUpload() {
  const dispatch = useDispatch()
  const { fetchWithRefresh } = useGoogleAuth()

  const postFile = useCallback(
    async ({ file, fileTitle, memberID, documentType, onSuccess }) => {
      dispatch(memberFileUploading())

      const formData = new FormData()
      formData.append('upload', file)
      formData.append('fileTitle', fileTitle)
      formData.append('documentType', documentType)
      formData.append('memberID', memberID)

      const response = await fetchWithRefresh({
        url: `${API_BASE_URL}/files/upload`,
        options: {
          method: HTTP_METHODS.POST,
          body: formData,
        },
      })

      if (response.ok) {
        const file = await response.json()
        dispatch(memberFileUploadSuccess({ ...file, id: file._id }))
        onSuccess()
      } else {
        dispatch(memberFileUploadError('File upload failed'))
      }
    },
    [dispatch, fetchWithRefresh],
  )

  return postFile
}
